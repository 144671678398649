import * as actionTypes from "./actionTypes";
import HttpService from "../../services/httpService";
import { getOrgDetailsForLogin, doLoginSuccess } from "./authenticationAction";
import {
  setLocalStorgeForLoggedInUser,
  setLocalStorgeForOrgUnits,
  doImpersonateSetup,
  doImpersonateExitSetup,
} from "../../shared/utility/store-utiility";

export const doImpersonateLoginStart = () => {
  return {
    type: actionTypes.IMPERSONATE_LOGIN_START,
  };
};
export const doImpersonateLoginSuccess = (
  impersonateLoginResponse,
  currentLoggedIndata,
  currentToken
) => {
  return {
    type: actionTypes.IMPERSONATE_LOGIN_SUCCESS,
    impersonateLoginResponse: impersonateLoginResponse,
    parentLoginResponse: currentLoggedIndata,
    parentToken: currentToken,
  };
};
export const doImpersonateLoginFail = (error) => {
  return {
    type: actionTypes.IMPERSONATE_LOGIN_FAIL,
    error: error,
  };
};
export const doImpersonateLoginReset = () => {
  return {
    type: actionTypes.IMPERSONATE_LOGIN_RESET,
  };
};
export const doImpersonateExitStart = () => {
  return {
    type: actionTypes.EXIT_IMPERSONATE_START,
  };
};
export const doImpersonateExitSuccess = (exitImpersonateResponse) => {
  return {
    type: actionTypes.EXIT_IMPERSONATE_SUCCESS,
    exitImpersonateResponse: exitImpersonateResponse,
  };
};
export const doImpersonateExitFail = (error) => {
  return {
    type: actionTypes.EXIT_IMPERSONATE_FAIL,
    error: error,
  };
};
export const doImpersonateExitReset = () => {
  return {
    type: actionTypes.EXIT_IMPERSONATE_RESET,
  };
};

export const doImpersonateLogin = (
  memberId,
  password,
  currentLoggedIndata,
  currentToken
) => {
  return (dispatch) => {
    dispatch(doImpersonateLoginStart());
    HttpService.doImpersonateLogin(memberId, password)
      .then((response) => {
        let loggedInUserData = {
          StatusCode: response.StatusCode,
          StatusMsg: response.StatusMsg,
        };
        if (response.StatusCode === 0) {
          loggedInUserData = {
            ...loggedInUserData,
            ...response.LoginInfo,
          };
          setLocalStorgeForLoggedInUser(loggedInUserData);
          doImpersonateSetup(response, currentLoggedIndata);
          getOrgDetailsForLogin(
            response.LoginInfo.User.OrgUnitId ||
              Number(localStorage.getItem("currentOrgUnitId")),
            loggedInUserData
          ).then((orgResponse) => {
            loggedInUserData = { ...orgResponse };
            loggedInUserData.CurrentOrgUnitId =
              response.LoginInfo.User.OrgUnitId ||
              Number(localStorage.getItem("currentOrgUnitId"));
            dispatch(
              doLoginSuccess(
                response.TokenResponseInfo.TokenId,
                loggedInUserData ? loggedInUserData.UserId : null,
                loggedInUserData
              )
            );
            dispatch(
              doImpersonateLoginSuccess(
                response,
                currentLoggedIndata,
                currentToken
              )
            );
          });
        } else {
          dispatch(
            doImpersonateLoginSuccess(
              response,
              currentLoggedIndata,
              currentToken
            )
          );
        }
      })
      .catch((err) => {
        //dispatch(doImpersonateLoginFail(err));
      });
  };
};
export const doImpersonateExit = (parentToken, parentLoginResponse) => {
  return (dispatch) => {
    dispatch(doImpersonateExitStart());
    HttpService.doImpersonateExit()
      .then((response) => {
        if (response.StatusCode === 0) {
          doImpersonateExitSetup();
          setLocalStorgeForLoggedInUser(parentLoginResponse);
          setLocalStorgeForOrgUnits(parentLoginResponse);
          dispatch(
            doLoginSuccess(
              parentToken,
              parentLoginResponse ? parentLoginResponse.UserId : null,
              parentLoginResponse
            )
          );
          dispatch(doImpersonateExitSuccess(response));
        } else {
          dispatch(doImpersonateExitSuccess(response));
        }
      })
      .catch((err) => {
        //dispatch(doImpersonateExitFail(err));
      });
  };
};

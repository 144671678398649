import React, { useState, Fragment } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Draggable from "react-draggable";
import { InsuranceEntityModel } from "../../../../services/entities/view-models/settings-view-models";
import { updateObject, getErrorToastData } from "../../../../shared/utility";
import { showNotification } from "../../../../components/UI/Toaster/Toaster";
import {
  getUnitTypes,
  validationInsuranceSettingsEntity,
} from "../../settingsHelper";
import { LabelAndValuePairControl } from "../../../../components/UI/Input/label-value-pair-control";
export const UpdateInsuranceEntityModal = (props) => {
  const entity = props.selectedEntity || new InsuranceEntityModel();
  const [updateEntity, setUpdateEntity] = useState(entity);
  const [isValidateDone, setIsValidateDone] = useState(false);
  const unitTypesNormal = getUnitTypes().filter(
    (item) => item.key !== "ROLLUP"
  );
  const unitTypes = getUnitTypes();
  const handleOnChange = (event, controlName, elementType) => {
    let value = null;
    value =
      elementType === "checkbox" ? event.target.checked : event.target.value;
    let updateCtrls = updateObject(updateEntity, {
      [controlName]: value,
    });

    const fieldsImpactedForRollUpUnitFeaturs = [
      "WeeklyUnit",
      "MonthlyUnit",
      "DailyAmount",
      "DailyUnit",
    ];
    const rollUpUnitId = "ROLLUP";
    const amountUnitId = "AMOUNT";

    if (fieldsImpactedForRollUpUnitFeaturs.includes(controlName)) {
      if (updateCtrls.DailyUnit !== amountUnitId) {
        updateCtrls = updateObject(updateCtrls, {
          WeeklyUnit:
            updateCtrls.WeeklyUnit === rollUpUnitId
              ? amountUnitId
              : updateCtrls.WeeklyUnit,
          MonthlyUnit:
            updateCtrls.WeeklyUnit === rollUpUnitId
              ? amountUnitId
              : updateCtrls.WeeklyUnit,
        });
        setUpdateEntity(updateCtrls);
        return;
      }
      updateCtrls.WeeklyAmount =
        updateCtrls.WeeklyUnit === rollUpUnitId
          ? updateCtrls.DailyAmount * 7
          : updateCtrls.WeeklyAmount;
      updateCtrls.MonthlyAmount =
        updateCtrls.MonthlyUnit === rollUpUnitId
          ? updateCtrls.DailyAmount * 30
          : updateCtrls.MonthlyAmount;
    }
    setUpdateEntity(updateCtrls);
  };
  const updateData = () => {
    let msg = validationInsuranceSettingsEntity(
      updateEntity,
      props.settingsType
    );
    if (msg) {
      showNotification(getErrorToastData(msg));
      setIsValidateDone(true);
    } else {
      setIsValidateDone(false);
      props.addUpdateEntity(updateEntity, props.settingsType);
    }
  };

  const renderLabelInputPair = (
    labelCaption,
    controlName,
    value,
    isRequired = false,
    nodeElement = "input",
    dropdownItem = null,
    elementType = "",
    isDisable = false
  ) => {
    const valueOrCheckedAttribute =
      nodeElement === "checkbox" ? "checked" : "value";
    const additionalClass = nodeElement === "select" ? "pt-4" : "";
    return (
      <LabelAndValuePairControl
        elementConfig={{
          name: controlName,
          className: nodeElement === "select" ? "pl-0" : "",
          [valueOrCheckedAttribute]: value,
          type: elementType,
          disabled: isDisable,
        }}
        otherConfig={{
          caption: labelCaption,
          isRequired: isRequired,
          dropdownItem: nodeElement === "select" ? dropdownItem : [],
          value: value,
          dateFormat: "D",
          isHideCheckBoxCaption: true,
        }}
        isEditMode={true}
        onChange={handleOnChange}
        parentRowClass={`form-group row  ${
          isRequired ? "required" : ""
        } ${additionalClass}`}
        labelClasses={"col-sm-4 col-form-label font-weight-bold"}
        valueClasses={"col-sm-8"}
        nodeElement={nodeElement}
        isValidateDone={isValidateDone}
      />
    );
  };

  const renderUnitAndRatePair = (category, updateEntityObj) => {
    return (
      <Fragment>
        {renderLabelInputPair(
          category + " Unit",
          category + "Unit",
          updateEntityObj[category + "Unit"],
          category === "Daily",
          "select",
          ["Weekly", "Monthly"].includes(category)
            ? updateEntityObj.DailyUnit === "AMOUNT"
              ? unitTypes
              : unitTypesNormal
            : unitTypesNormal
        )}
        {renderLabelInputPair(
          category + " Rate",
          category + "Amount",
          updateEntityObj[category + "Amount"],
          category === "Daily",
          "",
          null,
          "number",
          ["Weekly", "Monthly"].includes(category)
            ? updateEntityObj[category + "Unit"] === "ROLLUP"
              ? true
              : false
            : false
        )}
      </Fragment>
    );
  };

  return (
    <Draggable handle=".cursor">
      <Modal
        isOpen={props.showModal}
        className="no-cursor"
        toggle={() => {
          props.closeModal();
        }}
      >
        <ModalHeader
          className="modal-header bg-primary modal-title text-white cursor"
          toggle={() => {
            props.closeModal();
          }}
        >
          <i className="fas fa-car-crash pr-2"></i>
          {updateEntity.DummyId !== null
            ? "Update Insurance Settings"
            : "Add Insurance Settings"}
        </ModalHeader>
        <ModalBody>
          <div className="col-sm-12 p-0">
            {props.settingsType !== "OTHER_CDW"
              ? renderLabelInputPair(
                  "Is Offered",
                  "IsOffered",
                  updateEntity.IsOffered,
                  false,
                  "checkbox",
                  null,
                  "checkbox"
                )
              : ""}
            {props.settingsType === "AGE_INSURANCE" ? (
              <Fragment>
                {renderLabelInputPair(
                  "Min Age",
                  "InsuranceMinAge",
                  updateEntity.InsuranceMinAge,
                  true,
                  "",
                  null,
                  "number"
                )}
                {renderLabelInputPair(
                  "Max Age",
                  "InsuranceMaxAge",
                  updateEntity.InsuranceMaxAge,
                  true,
                  "",
                  null,
                  "number"
                )}
                {renderLabelInputPair(
                  "Code",
                  "InsuranceAgeCode",
                  updateEntity.InsuranceAgeCode,
                  true
                )}
              </Fragment>
            ) : (
              ""
            )}
            {props.settingsType !== "OTHER_CDW"
              ? renderLabelInputPair(
                  "Description",
                  "Description",
                  updateEntity.Description,
                  true
                )
              : ""}
            {props.settingsType === "CDW" || props.settingsType === "OTHER_CDW"
              ? renderLabelInputPair(
                  "Deductible",
                  "Deductible",
                  updateEntity.Deductible,
                  true
                )
              : ""}

            {renderUnitAndRatePair("Hourly", updateEntity)}
            {renderUnitAndRatePair("Daily", updateEntity)}
            {renderUnitAndRatePair("Weekly", updateEntity)}
            {renderUnitAndRatePair("Monthly", updateEntity)}
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="col-sm-12 text-right">
            <button
              type="button"
              name="btnYes"
              className="btn btn-primary waves-effect waves-light btnSize ml-2"
              onClick={() => updateData()}
            >
              {updateEntity.DummyId ? "Update" : "Submit"}
            </button>
            <button
              type="button"
              name="btnCancel"
              className="btn btn-secondary waves-effect waves-light btnSize ml-2"
              onClick={() => props.closeModal()}
            >
              Cancel
            </button>
          </div>
        </ModalFooter>
      </Modal>
    </Draggable>
  );
};
export default UpdateInsuranceEntityModal;

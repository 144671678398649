import React from 'react';
import "../input-ctrl.scss";

function RadioButton(props) {
  const {
    name,
    value,
    checked,
    placeholder,
    onChange,
    disabled,
    classNames,
    title
  } = props;

  return (
    <label className={`check-box-ctrl ${classNames}`}
      title={title}
    >
      {placeholder}
      <input
        name={name}
        type="radio"
        checked={checked}
        onChange={onChange ? (e) => onChange(e) : null}
        value={value}
        disabled={disabled}
      />
      <span className="checkmark checkmark-radio"></span>
    </label>
  )
}

export default RadioButton
import React, { useEffect, useState, useRef, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactHtmlParser from "react-html-parser";
import {
  getAdditionalOptionsSettingsInfo,
  getAdditionalOptionsSettingsInfoReset,
  updateAdditionalOptionsSettingsInfo,
  updateAdditionalOptionsSettingsInfoReset,
} from "../../../store/actions/index";
import Spinner from "../../../components/UI/Spinner/Spinner";
import PermissionName from "../../../shared/Permissions";
import {
  hasPermission,
  getBasicToastResponse,
  isNullOrEmpty,
  getCamelCaseId,
  getDisplayTextOfDropdown,
} from "../../../shared/utility";
import { showNotification } from "../../../components/UI/Toaster/Toaster";
import {
  AddonsSettingsViewModel,
  AddonsModel,
} from "../../../services/entities/view-models/settings-view-models";
import LinkButton from "../../../components/UI/button/link-btn";
import AdditionalOptionsModal from "./modal/additional-option-modal";
import ConfirmModal from "../../../components/common/modal/confirm-modal";
import {
  getNewlyAddedRowInfo,
  getInheritedProperties,
  getAddonsTypes,
  getUnitCategoryTypes,
  getRateUnitTypes,
  RenderInheritedCheckBox,
} from "../settingsHelper";
import { COMMON_MSG } from "../../../shared/GlobalVar";
import InheritFromParentConfirmModal from "../modal/inherit-from-parent-confirm-modal";
import NoRecordFoundComponent from "../../../components/common/pages/no-record-found";
import { createTooltip } from "../../../shared/utility/tooltip-utility";
import CommonInputField from "../../../components/UI/Input/common-input-field";

const AdditionalOptionsSettings = (props) => {
  const loading = useSelector((state) => state.settingsReducer.loading);
  const currentOrgUnitId = useSelector(
    (state) => state.auth.loggedInUserData.CurrentOrgUnitId
  );
  const additionalOptionsInfoResponse = useSelector(
    (state) => state.settingsReducer.additionalOptionsSettingsInfoResponse
  );
  const updateAdditionalOptionsResponse = useSelector(
    (state) => state.settingsReducer.additionalOptionsSettingsInfoUpdateResponse
  );
  const dependentOrgLength = useSelector((state) =>
    state.auth.loggedInUserData.DependantOrgList
      ? state.auth.loggedInUserData.DependantOrgList.length
      : 0
  );
  const dispatch = useDispatch();
  const query = props.location
    ? new URLSearchParams(props.location.search)
    : null;

  const selectedOrgLocationId =
    props.otherData && props.otherData.selectedOrgLocationId
      ? props.otherData.selectedOrgLocationId
      : query
      ? query.get("orgId")
      : currentOrgUnitId;

  const [addonsSettingsData, setAddonsSettingsData] = useState(
    new AddonsSettingsViewModel()
  );

  const [showAddonsModal, setShowAddonsModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [
    showInheritFromParentConfirmModal,
    setShowInheritFromParentConfirmModal,
  ] = useState(false);
  const [showConfirmRemoveInheritModal, setShowConfirmRemoveInheritModal] =
    useState(false);

  const [selectedAddonsEntity, setSelectedAddonsEntity] = useState(
    new AddonsModel()
  );

  const hasEditPermission = hasPermission(
    PermissionName.EDIT_UPSELL_ORG_SETTINGS
  );
  const units = getUnitCategoryTypes();
  const amountTypes = getRateUnitTypes();
  const types = getAddonsTypes();

  const intervalRef = useRef();
  const [highlightedClass, setHighlightedClass] = useState("");
  const [selectedRow, setSelectedRow] = useState(-1);
  const [timer, setTimer] = useState(0);
  const [isInheritModalInfo, setIsInheritModalInfo] = useState(false);

  useEffect(() => {
    if (additionalOptionsInfoResponse && updateAdditionalOptionsResponse) {
      intervalRef.current = setInterval(() => {
        setTimer((prevTimer) => prevTimer + 1);
      }, 1000);
    }
    if (timer % 8 === 0) {
      applyHighlightedColor(null);
      clearInterval(intervalRef.current);
    }
  }, [timer]);

  useEffect(() => {
    getAllAdditionalOptions();
  }, [selectedOrgLocationId]);
  useEffect(() => {
    if (!additionalOptionsInfoResponse) {
      return;
    }

    if (additionalOptionsInfoResponse.StatusCode === 0) {
      if (showInheritFromParentConfirmModal) {
        return;
      }
      if (!isInheritModalInfo) {
        setAddonsSettingsData(
          new AddonsSettingsViewModel(
            additionalOptionsInfoResponse,
            currentOrgUnitId,
            parseInt(selectedOrgLocationId, 10)
          )
        );
      }
    }
  }, [additionalOptionsInfoResponse]);

  useEffect(() => {
    if (!updateAdditionalOptionsResponse) {
      return;
    }
    showNotification(getBasicToastResponse(updateAdditionalOptionsResponse));
    if (updateAdditionalOptionsResponse.StatusCode === 0) {
      setIsInheritModalInfo(false);
      closeAddonsModal();
      if (
        additionalOptionsInfoResponse &&
        updateAdditionalOptionsResponse &&
        !updateAdditionalOptionsResponse.InheritedFromParent
      ) {
        highlightNewRow(
          additionalOptionsInfoResponse.UpsellOptions,
          updateAdditionalOptionsResponse.UpsellOptions
        );
        startTimer();
      }
      dispatch(updateAdditionalOptionsSettingsInfoReset());
    }
    dispatch(updateAdditionalOptionsSettingsInfoReset());
  }, [updateAdditionalOptionsResponse]);

  const applyHighlightedColor = (selectedRowId) => {
    if (selectedRowId) {
      setSelectedRow(selectedRowId);
      setHighlightedClass("add-edit-row-highlight");
    } else {
      setSelectedRow(-1);
      setHighlightedClass("");
    }
  };

  const highlightNewRow = (oldData, newData) => {
    let newRow = getNewlyAddedRowInfo(oldData, newData, "UpsellOptionId");
    if (newRow.length > 0) {
      applyHighlightedColor(newRow[0].UpsellOptionId);
    }
  };

  const startTimer = () => {
    setTimer(1);
    intervalRef.current = setInterval(() => {
      setTimer((prevTimer) => prevTimer + 1);
    }, 1000);
  };

  const getAllAdditionalOptions = () => {
    const req = {};
    req.SelectedChildOrgUnitId = selectedOrgLocationId;
    dispatch(getAdditionalOptionsSettingsInfoReset());
    dispatch(getAdditionalOptionsSettingsInfo(req));
  };

  const getAddonsModal = (entity) => {
    if (entity) {
      setSelectedAddonsEntity(entity);
    }
    setShowAddonsModal(true);
  };
  const closeAddonsModal = () => {
    setShowAddonsModal(false);
    setShowConfirmModal(false);
    setShowConfirmRemoveInheritModal(false);
    setShowInheritFromParentConfirmModal(false);
    setSelectedAddonsEntity(new AddonsModel());
  };
  const getConfirmModal = (entity) => {
    if (entity) {
      applyHighlightedColor(entity.UpsellOptionId);
      setSelectedAddonsEntity(entity);
    }
    setShowConfirmModal(true);
  };
  const getInheritConfirmModal = (event) => {
    if (event.target.checked) {
      setShowInheritFromParentConfirmModal(true);
    } else {
      setShowConfirmRemoveInheritModal(true);
    }
  };
  const closeConfirmModal = () => {
    if (showConfirmRemoveInheritModal) {
      setShowConfirmRemoveInheritModal(false);
    } else if (showInheritFromParentConfirmModal) {
      setShowInheritFromParentConfirmModal(false);
    } else {
      setShowConfirmModal(false);
      applyHighlightedColor(null);
      setSelectedAddonsEntity(new AddonsModel());
    }
  };
  const addUpdateAddonsEntity = (updateEntity) => {
    const req = JSON.parse(JSON.stringify(addonsSettingsData));
    if (!updateEntity.UpsellOptionId) {
      req.UpsellOptions.push(updateEntity);
    } else {
      req.UpsellOptions.map((entity, index) => {
        if (entity.UpsellOptionId === updateEntity.UpsellOptionId) {
          applyHighlightedColor(entity.UpsellOptionId);
          req.UpsellOptions[index] = { ...updateEntity };
        }
      });
    }
    updateAddonsData(req);
  };
  const removeAddonsEntity = () => {
    const req = JSON.parse(JSON.stringify(addonsSettingsData));
    let entityList = [];
    entityList = [...req.UpsellOptions];

    const entityListFilter = entityList.filter(
      (x) => x.UpsellOptionId !== selectedAddonsEntity.UpsellOptionId
    );
    req.UpsellOptions = [...entityListFilter];

    updateAddonsData(req);
  };
  const doInheritFunctionality = () => {
    updateAddonsData(addonsSettingsData, !showConfirmRemoveInheritModal);
  };
  const updateAddonsData = (req, isInheritedFromParent) => {
    const reqObj = {
      ...req,
      ...getInheritedProperties(
        currentOrgUnitId,
        req.SelectedChildOrgUnitId || selectedOrgLocationId,
        !isNullOrEmpty(isInheritedFromParent)
          ? isInheritedFromParent
          : req.InheritedFromParent,
        dependentOrgLength
      ),
    };
    delete reqObj["DisplayInherited"];
    delete reqObj["InheritedFromParent"];
    dispatch(updateAdditionalOptionsSettingsInfoReset());
    dispatch(updateAdditionalOptionsSettingsInfo(reqObj));
  };

  let editOptionCls = "light-gray-color";
  let deleteOptionCs = "light-gray-color";
  let inherited = addonsSettingsData.InheritedFromParent;
  let disable =
    addonsSettingsData.InheritedFromParent ||
    !hasEditPermission ||
    props.isInheritModal
      ? true
      : false;
  if (!disable) {
    editOptionCls = "blue-color cursor-pointer";
    deleteOptionCs = "red-color cursor-pointer";
  }

  const renderCheckboxes = (name, entity) => {
    return (
      <CommonInputField
        elementConfig={{
          id: getCamelCaseId(name) + entity.UpsellOptionId,
          type: "checkbox",
          checked: entity[name],
          disabled: true,
        }}
        otherConfig={{
          caption: "",
        }}
        nodeElement="checkbox"
        onChange={null}
      />
    );
  };
  const renderColumnValue = (
    name,
    entity,
    valueClasses,
    isTooltip = false,
    dropdownList = null,
    isCheckbox = false
  ) => {
    let value = entity[name]
      ? dropdownList
        ? getDisplayTextOfDropdown(dropdownList, entity[name])
        : entity[name]
      : "--";
    return (
      <td
        className={valueClasses ? valueClasses : ""}
        id={getCamelCaseId(name) + entity.UpsellOptionId}
      >
        {isCheckbox
          ? renderCheckboxes(name, entity)
          : isTooltip
          ? generateTooltip(value)
          : value}
      </td>
    );
  };

  const renderMainLayout = () => {
    return (
      <Fragment>
        <div
          className="col-lg-12 mb-2  font-weight-bold  d-flex"
          style={{ height: "2.5rem" }}
        >
          <label className="mr-auto fs-2">Additional Options: </label>

          {hasEditPermission &&
          !addonsSettingsData.InheritedFromParent &&
          !disable ? (
            <LinkButton
              btnLabel="Add New Options"
              containerClass="pseudo-link pr-1 fs-2 font-weight-bold"
              iconClass="fas fa-plus-circle iconBeforeLink"
              clicked={getAddonsModal}
            />
          ) : (
            ""
          )}
        </div>
        <div
          className="col-lg-12 tableScroll bb-0 table-fixed-head pr-0 table-container"
          style={{ maxHeight: props.isInheritModal ? "30rem" : "60rem" }}
        >
          <table className="table table-striped" id="table-addons">
            <thead className="thead-primary">
              <tr>
                <th>Option Code</th>
                <th>Option Name</th>
                <th>Type</th>
                <th>{ReactHtmlParser("Max <br> Qty.")}</th>
                <th>Option Url</th>
                <th>Unit</th>
                <th>Rate</th>
                <th>Min</th>
                <th>Max</th>
                <th>{ReactHtmlParser("Rate <br> Unit")}</th>
                <th>{ReactHtmlParser("Auto <br> Apply")}</th>
                <th>{ReactHtmlParser("Taxes <br> Apply")}</th>
                <th>{ReactHtmlParser("Customer <br> Visible")}</th>
                <th>{ReactHtmlParser("Adjustment <br> Allowed")}</th>
                <th />
              </tr>
            </thead>
            <tbody>{renderAddonsList()}</tbody>
          </table>
        </div>
      </Fragment>
    );
  };
  const generateTooltip = (tooltipTxt) => {
    return createTooltip(tooltipTxt, tooltipTxt, {
      parentTag: "div",
      childTag: "div",
      tableId: "table-addons",
      ttcClass: "tooltipContainer-max",
    });
  };
  const renderAddonsList = () => {
    if (addonsSettingsData.UpsellOptions.length > 0) {
      return addonsSettingsData.UpsellOptions.map((entity) => {
        let commonPropsForCheckbox = [entity, "", false, null, true];
        let commonPropsWithTooltip = [entity, "text-left", true];
        let commonPropsForDropdown = [entity, "text-left", false];
        return (
          <tr
            className={
              selectedRow === entity.UpsellOptionId ? highlightedClass : ""
            }
            key={entity.UpsellOptionId}
          >
            {renderColumnValue("Value", ...commonPropsWithTooltip)}
            {renderColumnValue("DisplayText", ...commonPropsWithTooltip)}
            {renderColumnValue("Type", ...commonPropsForDropdown, types)}
            {renderColumnValue("MaxQuantity", entity)}
            {renderColumnValue("UpsellOptionUrl", ...commonPropsWithTooltip)}
            {renderColumnValue("UnitType", ...commonPropsForDropdown, units)}
            {renderColumnValue("Rate", entity)}
            {renderColumnValue("MinRate", entity)}
            {renderColumnValue("MaxRate", entity)}
            {renderColumnValue("RateUnit", entity, null, false, amountTypes)}
            {renderColumnValue("AutoApply", ...commonPropsForCheckbox)}
            {renderColumnValue("Taxable", ...commonPropsForCheckbox)}
            {renderColumnValue("CustomerVisible", ...commonPropsForCheckbox)}
            {renderColumnValue("AdjustmentAllowed", ...commonPropsForCheckbox)}
            <td className="text-center">
              {isNullOrEmpty(props.isInheritModal) ? (
                <Fragment>
                  {createTooltip(
                    <i
                      id={"edit" + entity.UpsellOptionId}
                      className={`fas fa-pencil-alt fs-3 pl-1 pr-2 ${editOptionCls}`}
                      aria-hidden="true"
                      onClick={(event) =>
                        disable ? "" : getAddonsModal(entity)
                      }
                    />,
                    hasEditPermission ? (!inherited ? "Edit" : "") : "",
                    { forceShow: true, tableId: "table-addons" }
                  )}
                  {createTooltip(
                    <i
                      id={"delete" + entity.UpsellOptionId}
                      className={`fa fa-times fs-3 ${deleteOptionCs}`}
                      aria-hidden="true"
                      onClick={(event) =>
                        disable ? "" : getConfirmModal(entity)
                      }
                    />,
                    hasEditPermission ? (!inherited ? "Delete" : "") : "",
                    { forceShow: true, tableId: "table-addons" }
                  )}
                </Fragment>
              ) : null}
            </td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colSpan="15">
            <NoRecordFoundComponent
              className="border-none"
              innerClassName="no-setting-record-found"
              searchMsg="No Record Found"
              heights="5rem"
            />
          </td>
        </tr>
      );
    }
  };

  return props.isInheritModal ? (
    renderMainLayout()
  ) : (
    <div className="col-lg-12 pt-3 pl-0">
      <div className="card">
        <div className="card-body">
          <div className="form-header row col-lg-12 p-0">
            <h4 className="text-uppercase mr-auto icon-display-none">
              <i className="fas fa-cogs"></i>
              Additional Options Settings
            </h4>
            <RenderInheritedCheckBox
              checked={addonsSettingsData.InheritedFromParent}
              onChange={(event) => getInheritConfirmModal(event)}
              disabled={!hasEditPermission}
              displayEnable={addonsSettingsData.DisplayInherited}
            />
          </div>
          {renderMainLayout()}
        </div>
        <div style={{ height: "2.5rem" }}>&nbsp;</div>
      </div>
      {loading ? <Spinner /> : ""}
      {showAddonsModal ? (
        <AdditionalOptionsModal
          showModal={showAddonsModal}
          closeModal={closeAddonsModal}
          units={units || []}
          types={types || []}
          amountTypes={amountTypes || []}
          selectedEntity={selectedAddonsEntity}
          addUpdateAddonsEntity={addUpdateAddonsEntity}
        />
      ) : (
        ""
      )}

      {showConfirmModal || showConfirmRemoveInheritModal ? (
        <ConfirmModal
          body={
            showConfirmModal
              ? COMMON_MSG.DeleteRecordMsg
              : COMMON_MSG.RemoveInheritParentConfirm
          }
          showModal={showConfirmModal || showConfirmRemoveInheritModal}
          closeModal={closeConfirmModal}
          doConfirmFunctionality={
            showConfirmModal ? removeAddonsEntity : doInheritFunctionality
          }
        />
      ) : (
        ""
      )}

      {showInheritFromParentConfirmModal ? (
        <InheritFromParentConfirmModal
          showModal={showInheritFromParentConfirmModal}
          closeModal={closeConfirmModal}
          doInheritFunctionality={doInheritFunctionality}
          currentOrgUnitId={currentOrgUnitId}
          modalWidth="110rem"
          componentName={AdditionalOptionsSettings}
        />
      ) : (
        ""
      )}
    </div>
  );
};
export default AdditionalOptionsSettings;

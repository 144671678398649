import React, { useEffect, useState } from 'react';
import InputWidgetCtrl from '../../../components/UI/Input/input-widget-ctrl';
import { showNotification } from '../../../components/UI/Toaster/Toaster';
import { FoxRequestHeader } from '../../../services/entities/fox-api/domain/fox-request-header';
import { invokeServerAPI } from '../../../services/httpService';
import { DISCOUNT } from '../../../shared/AppImages';
import { TOAST_MSG } from '../../../shared/GlobalVar';
import { getCreditDiscountCodes, getTestDiscountCodes, getTestHostNames } from '../../../shared/json/data';
import { convertMsgResponse } from '../../Reservation/fox-reservation/fox-res-helper';

function MemberDiscount(props) {
    const { customerId, closeModal, setLoader } = props;
    const [discountCode, setDiscountCode] = useState("");
    const [discountList, setDiscountList] = useState([]);
    const [widgetLoader, setWidgetLoader] = useState(false);
    const hostName = window?.location?.hostname;

    useEffect(() => {
        async function getDiscountList() {

            setWidgetLoader(true);
            const creditDiscounts = await getCreditDiscountCodes();
            setWidgetLoader(false);
            setDiscountList(hostName && getTestHostNames().includes(hostName)
                ? [...creditDiscounts, ...getTestDiscountCodes()]
                : [...creditDiscounts]);
        }
        getDiscountList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const onChange = (event) => {
        setDiscountCode(event);
    }
    const onDiscountCodeSend = async () => {
        if (!discountCode?.key) {
            showNotification({
                msg: "Please select a discount code!",
                toastType: TOAST_MSG.WARNING,
            });
            return false;
        }
        const req = {
            requestHeader: new FoxRequestHeader({ requestType: "POST" }),
            customer: {
                customerId
            },
            foxDiscountCode: discountCode.key
        };
        setLoader(true);
        const response = await invokeServerAPI("admin/sendcustomerdiscount", req, true);
        setLoader(false);
        if (
            response.message &&
            response.message.messageType === "SUCCESS"
        ) {
            closeModal();
            showNotification(convertMsgResponse(response.message));
        } else {
            showNotification(convertMsgResponse(response.message));
        }
    }
    return (
        <>
            <InputWidgetCtrl
                id="discount-code"
                name="discount-code"
                placeholder="Discount Code"
                data={discountList}
                valueField="key"
                textField="value"
                value={discountCode}
                onChange={onChange}
                imgIco={DISCOUNT}
                loader={widgetLoader}
                // defaultConfig={{
                //     groupBy: getTestHostNames().includes(hostName) ? "type" : null,

                // }}
                valueComponent={({ item }) => (
                    <span>{item.key}</span>
                )}
                itemComponent={({ item }) => (
                    <>
                        <span>{!item.key ? item?.value : item?.key}</span>
                        {/* {item?.key ? <hr /> : <br />} */}
                    </>
                )}

            />
            <div className='btn-container'>
                <button className='btn btn-outline-danger' onClick={closeModal}>Cancel</button>
                <button className='btn btn-yellow-default' onClick={onDiscountCodeSend}>Send</button>
            </div>
        </>
    )
}

export default MemberDiscount
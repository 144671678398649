import React from "react";
import Draggable from "react-draggable";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
const ApplyOfferModal = (props) => {
  return (
    <Draggable handle=".cursor">
      <Modal
        isOpen={props.showModal}
        className="no-cursor"
        style={{ minWidth: "37rem" }}
        toggle={() => {
          props.closeModal();
        }}
      >
        <ModalHeader
          className="modal-header bg-primary modal-title text-white cursor"
          toggle={() => {
            props.closeModal();
          }}
        >
          <i className="fas fa-gift pr-2"></i>
          Apply Offer
        </ModalHeader>
        <ModalBody
          style={{
            height: "auto",
          }}
        >
          <div className="col-lg-12 p-0 row">
            <label className="col-form-label font-weight-bold">
              Offer Code
            </label>
            <div className="col-sm-8">
              <input
                id="newmem-PolicyNumber"
                name="PolicyNumber"
                type="text"
                className="form-control"
                //   onChange={event =>
                //     props.inputInsuranceChangeHandler(
                //       event,
                //       "PolicyNumber",
                //       "text"
                //     )
                //   }
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            name="btnChangeAddress"
            className="btn btn-primary waves-effect waves-light btnSize ml-2"
            //onClick={event => props.applyOffer()}
          >
            Apply Offer
          </button>
          <button
            type="button"
            name="btnCancel"
            className="btn btn-secondary  waves-effect waves-light btnSize ml-2"
            onClick={(event) => props.closeModal()}
          >
            Cancel
          </button>
        </ModalFooter>
      </Modal>
    </Draggable>
  );
};
export default ApplyOfferModal;

import * as L from "leaflet";
import "leaflet/dist/leaflet.css";
import React, { useEffect, useState } from "react";
import Autocomplete from 'react-autocomplete';
import { renderToString } from "react-dom/server";
import {
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  ZoomControl,
  useMap,
} from "react-leaflet";
import Control from "react-leaflet-custom-control";
import MarkerClusterGroup from "react-leaflet-markercluster";
import "react-leaflet-markercluster/dist/styles.min.css";
import { ReverseIcon } from "../../shared/AppImages";
import { ModalType } from "../../shared/GlobalVar";
import { isNullOrEmpty } from "../../shared/utility";
import { MapType } from "../../shared/utility/enum-utility";
import { isLatitude, isLongitude } from "../../shared/utility/number-utility";
import {
  getClusterMarkup,
} from "../shuttles/shuttles-helper";
import {
  createClusterCustomIcon_landmark,
  createClusterCustomIcon_shuttle,
  createMapIcon,
} from "./icon";
import "./map-style.scss";
import RoutingMachine from "./routing-machine";
import { getZoomLevelWiseScaleValues } from "./zoom-level-wise-radius";

const MarkerMap = (props) => {
  const lat = 34.052235;
  const lng = 34.052235;
  const [zoom, setZoom] = useState(8);
  const [isPerformedZoom, setIsPerformedZoom] = useState(false);
  const zoomLevels = getZoomLevelWiseScaleValues();
  const [liveMap, setLiveMap] = useState(null);
  const { modalType, showRoute, closeRoute, rotateRoutingPath, vicinityData, customData } = props;
  const [locations, setLocations] = useState([]);
  const [travelMode, setTravelMode] = useState("car");
  const [startingPoint, setstartingPoint] = useState(customData ? customData.startLocation || null : null);
  const [destinationPoint, setDestinationPoint] = useState(customData ? customData.destinationLocation || null : null);
  useEffect(() => {
    if (vicinityData) {
      const places = [];
      vicinityData.landmarkSummaries.map((data, index) => {

        if (!isNullOrEmpty(data.latitude) && !isNullOrEmpty(data.longitude)) {
          places.push({ key: { latitude: data.latitude, longitude: data.longitude }, value: (data.landmarkCode + "," + data.formattedAddress) });
        }
      });
      vicinityData.shuttleSummaries.map((data, index) => {
        if (data.latitude && data.longitude) {
          places.push({ key: { latitude: data.latitude, longitude: data.longitude }, value: data.shuttleCode + "," + data.locationDisplayName });
        }
      });
      setLocations(places);
    }
  }, [vicinityData]);
  useEffect(() => {
    if (customData) {
      if ((!startingPoint && customData.startLocation) || (customData.startLocation && customData.startLocation.key.latitude !== startingPoint.key.latitude) ||
        (customData.startLocation && customData.startLocation.key.longitude !== startingPoint.key.longitude)
      ) {
        setstartingPoint(customData.startLocation);
      }
      if ((!destinationPoint && customData.destinationLocation) || (customData.destinationLocation && customData.destinationLocation.key.latitude !== destinationPoint.key.latitude) ||
        (customData.destinationLocation && customData.destinationLocation.key.longitude !== destinationPoint.key.longitude)) {
        setDestinationPoint(customData.destinationLocation);
      }
    }
  }, [customData]);
  useEffect(() => {
    if (!liveMap) return;
    liveMap.on("zoomend", function () {
      if (!isPerformedZoom) {
        if (liveMap._animateToZoom !== zoom) {
          setIsPerformedZoom(true);
        }
      }
    });
  }, [liveMap]);

  useEffect(() => {
    let zoomLevel = zoomLevels.reduce((obj1, obj2) =>
      Math.abs(props.vicinityRadiusInMiles - obj2.scaleValueInMiles) <=
        Math.abs(props.vicinityRadiusInMiles - obj1.scaleValueInMiles)
        ? obj2
        : obj1
    );
    setZoom(props.zoomForDetailsSection ? props.zoomForDetailsSection : zoomLevel.zoomLevel);
  }, [props.vicinityRadiusInMiles]);

  const doRotateRoutingPath = () => {
    if (startingPoint || destinationPoint) {
      setstartingPoint(destinationPoint);
      setDestinationPoint(startingPoint);
    }
    // if (startingPoint || destinationPoint) {
    //   const newData = {
    //     startLocation: destinationPoint || null,
    //     destinationLocation: startingPoint || null
    //   }
    //   rotateRoutingPath(modalType, newData);
    // }
  }
  const ChangeView = ({ center, zoom }) => {
    const map = useMap();
    if (!isPerformedZoom) {
      map.setView(center, zoom);
    }
    return null;
  };
  const getMergedClusterData = (clusterDetails) => {
    let summaryData = [];
    clusterDetails.map((markerData, index) => {
      summaryData.push(markerData.options.data.parentData);
    });
    return summaryData;
  };

  const openClusterModal = (e, map, type) => {
    const singleModalPosition = [e.latlng.lat, e.latlng.lng];
    let totalMarkersInClusters = e.layer.getAllChildMarkers().length;
    if (totalMarkersInClusters > 1) {
      const summaryData = getMergedClusterData(e.layer.getAllChildMarkers());
      let tableContent = renderToString(
        getClusterMarkup(summaryData, MapType.SHUTTLES === type)
      );
      L.popup({
        minWidth: MapType.SHUTTLES === type ? 350 : 400,
        closeButton: false,
        closeOnClick: true,
        className:
          MapType.SHUTTLES === type
            ? "table-view-shuttle"
            : "table-view-landmark",
      })
        .setLatLng(singleModalPosition)
        .setContent(tableContent)
        .openOn(map);
    }
    map.on("popupopen", function (a) {
      var deleteLink = document.querySelectorAll(".lat-lng-click");
      for (var i = 0; i < deleteLink.length; i++) {
        var lat = deleteLink[i].getAttribute("lat");
        var lng = deleteLink[i].getAttribute("lng");
        deleteLink[i].addEventListener("click", function (event) {
          //map.panTo([typeId, type]);
          //map.setView([lat, lng], 13);
          //  map.flyTo([lat, lng], 13, { duration: 2 }).once('zoomend',()=>{
          //      e.layer.zoomToBounds();
          //     map.closePopup();
          //   });
          // e.layer.zoomToBounds();
          // map.closePopup();
        });
      }
    });
  };
  const position = props.defaultPosition && isLatitude(props.defaultPosition[0]) && isLongitude(props.defaultPosition[1]) ? props.defaultPosition : [lat, lng];
  const MapComponent = () => {
    const map = useMap();
    return (
      <map>
        {props.refreshMapdata ? (<>
          <Control prepend position="topright">
            <div className="leaflet-control-zoom leaflet-bar leaflet-control leaflet-refresh-ctrl">
              <a
                className="leaflet-control-zoom-out"
                href="#"
                title="Force reload"
                role="button"
                aria-label="Force reload"
                aria-disabled="false"
              >
                <span
                  aria-hidden="true"
                  onClick={() => props.refreshMapdata(true)}
                >
                  <i
                    className="icon-refresh"
                    style={{ fontSize: "1.8rem" }}
                  ></i>
                </span>
              </a>
            </div>
          </Control>
          <Control prepend position="topright">
            <div className="leaflet-control-zoom leaflet-bar leaflet-control leaflet-direction-ctrl">
              <a
                className="leaflet-control-zoom-out"
                href="#"
                title="Direction"
                role="button"
                aria-label="Direction"
                aria-disabled="false"
              >
                <span
                  aria-hidden="true"
                  onClick={() => props.openDirectionModal({
                    type: ModalType.SHUTTLE_DIRECTION_MODAL,
                    showModal: true,
                  })}
                >
                  <i
                    className="fas fa-directions"
                    style={{ fontSize: "1.8rem" }}
                  ></i>
                </span>
              </a>
            </div>
          </Control></>
        ) : (
          ""
        )}
        {props.isVicinity ? (
          <>
            <MarkerClusterGroup
              key="shuttle-group"
              // showCoverageOnHover={false}
              // spiderfyDistanceMultiplier={2}
              onClick={(e) => openClusterModal(e, map, MapType.SHUTTLES)}
              spiderfyOnMaxZoom={true}
              zoomToBoundsOnClick={false}
              iconCreateFunction={createClusterCustomIcon_shuttle}
            >
              {props.shuttleData.map((data, index) => (
                <Marker
                  position={data.position}
                  index={data.shuttleId}
                  key={data.shuttleId}
                  icon={createMapIcon(
                    data.iconData.type,
                    data.iconData.color,
                    data.iconData.label,
                    index
                  )}
                  color={data.iconData.color || ""}
                  data={data}
                >
                  <Popup
                    key={"markerP" + index}
                    index={"markerP" + index}
                    closeOnClick={true}
                    closeButton={false}
                  >
                    {data.markupData}
                  </Popup>
                </Marker>
              ))}
            </MarkerClusterGroup>
            <MarkerClusterGroup
              //showCoverageOnHover={false}
              //spiderfyDistanceMultiplier={2}
              onClick={(e) => openClusterModal(e, map, MapType.LANDMARKS)}
              spiderfyOnMaxZoom={true}
              zoomToBoundsOnClick={false}
              iconCreateFunction={createClusterCustomIcon_landmark}
            >
              {/* {clusterList.length > 1 ? getClusterModalTemplate() : ""} */}
              {props.landmarkData.map((data, index) => (
                <Marker
                  position={data.position}
                  index={data.landmarkId}
                  key={data.landmarkId}
                  icon={createMapIcon(
                    data.iconData.type,
                    data.iconData.color,
                    data.iconData.label,
                    index
                  )}
                  color={data.iconData.color || ""}
                  data={data}
                >
                  <Popup
                    key={"markerP" + index}
                    index={"markerP" + index}
                    closeOnClick={true}
                    closeButton={false}
                  >
                    {data.markupData}
                  </Popup>
                </Marker>
              ))}
            </MarkerClusterGroup>
          </>
        ) : (
          <MarkerClusterGroup
            showCoverageOnHover={false}
            spiderfyDistanceMultiplier={2}
          >
            {props.mapData.map((data, index) => (
              <Marker
                position={data.position}
                index={"marker" + index}
                key={"marker" + index}
                icon={createMapIcon(
                  data.iconData.type,
                  data.iconData.color,
                  data.iconData.label,
                  index
                )}
              >
                <Popup
                  key={"markerP" + index}
                  index={"markerP" + index}
                  closeOnClick={true}
                  closeButton={false}
                >
                  {data.markupData}
                </Popup>
              </Marker>
            ))}
          </MarkerClusterGroup>
        )}
      </map>
    );
  };
  return (
    <div className="row col-lg-12 p-0 m-o">
      {showRoute ? <div className="col-lg-3 pl-0 ml-o">
        <div className="card">
          <div className="card-body">
            {/* <h4 className="form-header text-uppercase">
              Shuttle Position :
            </h4> */}
            <div className="travel-mode">
              {/* <i
            className="fas fa-directions"
            style={{ fontSize: "1.8rem" }}
          ></i> */}
              <i
                className="fas fa-car"
                onClick={() => setTravelMode("car")}
              ></i>
              <i
                className="fas fa-motorcycle icon-disabled"
                onClick={() => setTravelMode("bike")}
              ></i>
              <i
                className="fas fa-walking icon-disabled"
                onClick={() => setTravelMode("foot")}
              ></i>
              {/* <i
            className="fas fa-bicycle"
            style={{ fontSize: "1.8rem" }}
          ></i>
          <i
            className="fas fa-plane"
            style={{ fontSize: "1.8rem" }}
          ></i> */}
            </div>
            <div className="travel-point">
              <div className="autocomplete-wrapper">
                <Autocomplete
                  value={startingPoint ? typeof startingPoint === 'object' ? startingPoint.value : startingPoint : ''}
                  items={locations}
                  getItemValue={item => item.value}
                  shouldItemRender={(item, value) => item.value.toLowerCase().indexOf(value.toLowerCase()) !== -1}
                  renderMenu={item => (
                    <div className="dropdown">
                      {item}
                    </div>
                  )}
                  renderItem={(item, isHighlighted) =>
                    <div className={`item ${isHighlighted ? 'selected-item' : ''}`}>
                      {item.value}
                    </div>
                  }
                  onChange={(event, val) => { setstartingPoint(val); }}
                  onSelect={(event, val) => { setstartingPoint(val) }}
                />
              </div>
              <div className="autocomplete-wrapper">
                <Autocomplete
                  value={destinationPoint ? typeof destinationPoint === 'object' ? destinationPoint.value : destinationPoint : ''}
                  items={locations}
                  getItemValue={item => item.value}
                  shouldItemRender={(item, value) => item.value.toLowerCase().indexOf(value.toLowerCase()) !== -1}
                  renderMenu={item => (
                    <div className="dropdown">
                      {item}
                    </div>
                  )}
                  renderItem={(item, isHighlighted) =>
                    <div className={`item ${isHighlighted ? 'selected-item' : ''}`}>
                      {item.value}
                    </div>
                  }
                  onChange={(event, val) => { setDestinationPoint(val); }}
                  onSelect={(event, val) => { setDestinationPoint(val) }}
                />
              </div>
              <div className="rptate-icon" onClick={() => doRotateRoutingPath()}> <img width="30" height="30" src={ReverseIcon} alt="" /></div>
            </div>
            <br></br>
            <br></br>
            <h4 className="form-header text-uppercase">
              Starting Point :
            </h4>
            <div>
              {startingPoint ? startingPoint.value : "N/A"}
            </div>
            <br></br>
            <h4 className="form-header text-uppercase">
              Destination Point :
            </h4>
            <div>
              {destinationPoint ? destinationPoint.value : "N/A"}
            </div>
            <br></br>
            <br></br>
            <div className="route-btn-conatainer">
              <button
                type="button"
                className="btn btn-primary waves-effect waves-light btnSize ml-2"
                onClick={() => { setstartingPoint(null); setDestinationPoint(null); closeRoute(); }}
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-secondary  waves-effect waves-light btnSize ml-2"
                onClick={() => { setstartingPoint(null); setDestinationPoint(null); }}
              >
                Reset
              </button>
            </div>
          </div>
        </div>
      </div> : ""}
      <div className={`pr-0 mr-o ${showRoute ? 'col-lg-9' : 'col-lg-12'}`}>
        <MapContainer
          center={position}
          zoom={zoom}
          style={props.style ? props.style : { height: "70em" }}
          // scrollWheelZoom="center"
          scrollWheelZoom={true}
          zoomControl={false}
          whenCreated={setLiveMap}
        >
          <ChangeView center={position} zoom={zoom} />
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <ZoomControl position="topright" />
          <MapComponent />
          {showRoute && startingPoint && destinationPoint
            ? <RoutingMachine
              startingPoint={startingPoint.key}
              destinationPoint={destinationPoint.key}
              startingValue={startingPoint.value}
              destinationValue={destinationPoint.value}
              isMarkerRequired={false}
            /> : ""}
        </MapContainer>
      </div>
    </div>
  );
};

export default MarkerMap;

import React, { useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import Draggable from "react-draggable";
import classnames from "classnames";
import { LicenseDocType } from "../../../shared/GlobalVar";
import AttachedDocBody from "./attach-doc-body";

export const LicenseInfoModal = (props) => {
  const [activeTab, setActiveTab] = useState("1");
  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };
  return (
    <Draggable handle=".cursor">
      <Modal
        isOpen={props.showModal}
        className="no-cursor"
        toggle={() => {
          props.closeModal();
        }}
        style={{ minWidth: "1000px" }}
      >
        <ModalHeader
          className="modal-header bg-primary modal-title text-white cursor"
          toggle={() => {
            props.closeModal();
          }}
        >
          <i className="fas fa-key" /> Driver's License Report
        </ModalHeader>
        <ModalBody
          style={{ maxHeight: "600px", minHeight: "480px" }}
          className="tableScroll"
        >
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === "1",
                })}
                onClick={() => {
                  toggleTab("1");
                }}
              >
                Additional Doc Upload
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === "2",
                })}
                onClick={() => {
                  toggleTab("2");
                }}
              >
                Disapprove License
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === "3",
                })}
                onClick={() => {
                  toggleTab("3");
                }}
              >
                Additional License
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              {activeTab === "1" ? (
                <AttachedDocBody
                  closeModal={props.closeModal}
                  memberId={props.memberId}
                  licenseDocType={LicenseDocType.ATTACHED_DOC}
                  resetParentPage={props.resetParentPage}
                />
              ) : (
                ""
              )}
            </TabPane>
            <TabPane tabId="2">
              {activeTab === "2" ? (
                <AttachedDocBody
                  closeModal={props.closeModal}
                  memberId={props.memberId}
                  licenseDocType={LicenseDocType.MANUAL_DISAPPROVE}
                  resetParentPage={props.resetParentPage}
                />
              ) : (
                ""
              )}
            </TabPane>
            <TabPane tabId="3">3</TabPane>
          </TabContent>
        </ModalBody>
      </Modal>
    </Draggable>
  );
};
export default LicenseInfoModal;

import React from "react";

import "../../../sass/components/_spinner.scss";

const Spinner = () => (
  <div>
    <div className="lds-spinner">
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>
    <div className="overlay" />
  </div>
);

export default Spinner;

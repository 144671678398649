import React, { Component, useState } from "react";
import { connect } from "react-redux";
import * as actions from "../../../store/actions/index";
import { validateAddViolationPoint } from "../MemberHelper";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Draggable from "react-draggable";
import { showNotification } from "../../../components/UI/Toaster/Toaster";
import { getErrorToastData } from "../../../shared/utility";

const AddViolationPoint = (props) => {
  const [violationPoint, setViolationPoint] = useState("");
  const [description, setDescription] = useState("");
  const [isValidateDone, setIsValidateDone] = useState(false);

  const addViolationPoint = () => {
    const req = {};
    req.MemberId = props.memberId;
    req.ViolationPoint = violationPoint;
    req.ViolationDesc = description;
    let msg = "";
    if (!msg) {
      msg = validateAddViolationPoint(req);
    }
    if (msg) {
      showNotification(getErrorToastData(msg));
      setIsValidateDone(true);
    } else {
      setIsValidateDone(false);
      props.addViolationPoint(req);
    }
  };

  return (
    <div>
      <Draggable handle=".cursor">
        <Modal
          isOpen={props.showModal}
          className="no-cursor"
          toggle={props.closeModal}
        >
          <ModalHeader
            className="modal-header bg-primary modal-title text-white cursor"
            toggle={props.closeModal}
          >
            <i className="fas fa-arrows-alt" /> Add Violation Point
          </ModalHeader>
          <ModalBody>
            <div className="form-group row">
              <div className="FormRow col-sm-12">
                <div className="form-group formRow required">
                  <label className="col-sm-5 col-form-label font-weight-bold">
                    Violation Point
                  </label>
                  <div
                    className={`col-sm-6 ${
                      violationPoint
                        ? ""
                        : isValidateDone
                        ? "validation-failed"
                        : ""
                    }`}
                  >
                    <input
                      type="text"
                      name="violationPoint"
                      value={violationPoint || ""}
                      className="text"
                      onChange={(event) =>
                        setViolationPoint(event.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="form-group formRow required">
                  <label className="col-sm-5 col-form-label font-weight-bold">
                    Description
                  </label>
                  <div
                    className={`col-sm-6 ${
                      description
                        ? ""
                        : isValidateDone
                        ? "validation-failed"
                        : ""
                    }`}
                  >
                    <textarea
                      rows="20"
                      name="description"
                      value={description}
                      style={{ height: "55px", resize: "none" }}
                      onChange={(event) => setDescription(event.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button
              type="submit"
              name="btnSave"
              className="btn btn-primary waves-effect waves-light btnSize ml-2"
              onClick={addViolationPoint}
            >
              Save
            </button>
            <button
              type="button"
              name="btnCancel"
              className="btn btn-secondary  waves-effect waves-light btnSize ml-2"
              onClick={props.closeModal}
            >
              Cancel
            </button>
          </ModalFooter>
        </Modal>
      </Draggable>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    tokenId: state.auth.token,
    userId: state.auth.userId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSetAuthRedirectPath: (path) =>
      dispatch(actions.setAuthRedirectPath(path)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddViolationPoint);

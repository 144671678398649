import * as actionTypes from "../actions/actionTypes";
import { reducerUpdateObject } from "./reducer.utility";

const initialState = {
  memberAddressResponse: null,
  addAddressResponse: null,
  editAddressResponse: null,
  deleteAddressResponse: null,
  loading: false,
  error: null,
};
const getMemberAddressesesStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
const getMemberAddressesesSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    memberAddressResponse: action.memberAddressResponse,
    error: null,
    loading: false,
  });
};
const getMemberAddressesesFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false,
  });
};
const getMemberAddressesesReset = (state, action) => {
  return reducerUpdateObject(state, { memberAddressResponse: null });
};

const addMemberAddressesStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
const addMemberAddressesSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    addAddressResponse: action.addAddressResponse,
    error: null,
    loading: false,
  });
};
const addMemberAddressesFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false,
  });
};
const addMemberAddressesReset = (state, action) => {
  return reducerUpdateObject(state, { addAddressResponse: null });
};

const editMemberAddressesStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
const editMemberAddressesSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    editAddressResponse: action.editAddressResponse,
    error: null,
    loading: false,
  });
};
const editMemberAddressesFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false,
  });
};
const editMemberAddressesReset = (state, action) => {
  return reducerUpdateObject(state, { editAddressResponse: null });
};

const deleteMemberAddressesStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
const deleteMemberAddressesSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    deleteAddressResponse: action.deleteAddressResponse,
    error: null,
    loading: false,
  });
};
const deleteMemberAddressesFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false,
  });
};
const deleteMemberAddressesReset = (state, action) => {
  return reducerUpdateObject(state, { deleteAddressResponse: null });
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ADDRESS_START:
      return getMemberAddressesesStart(state, action);
    case actionTypes.GET_ADDRESS_SUCCESS:
      return getMemberAddressesesSuccess(state, action);
    case actionTypes.GET_ADDRESS_FAIL:
      return getMemberAddressesesFail(state, action);
    case actionTypes.GET_ADDRESS_RESET:
      return getMemberAddressesesReset(state, action);

    case actionTypes.ADD_ADDRESS_START:
      return addMemberAddressesStart(state, action);
    case actionTypes.ADD_ADDRESS_SUCCESS:
      return addMemberAddressesSuccess(state, action);
    case actionTypes.ADD_ADDRESS_FAIL:
      return addMemberAddressesFail(state, action);
    case actionTypes.ADD_ADDRESS_RESET:
      return addMemberAddressesReset(state, action);

    case actionTypes.EDIT_ADDRESS_START:
      return editMemberAddressesStart(state, action);
    case actionTypes.EDIT_ADDRESS_SUCCESS:
      return editMemberAddressesSuccess(state, action);
    case actionTypes.EDIT_ADDRESS_FAIL:
      return editMemberAddressesFail(state, action);
    case actionTypes.EDIT_ADDRESS_RESET:
      return editMemberAddressesReset(state, action);

    case actionTypes.DELETE_ADDRESS_START:
      return deleteMemberAddressesStart(state, action);
    case actionTypes.DELETE_ADDRESS_SUCCESS:
      return deleteMemberAddressesSuccess(state, action);
    case actionTypes.DELETE_ADDRESS_FAIL:
      return deleteMemberAddressesFail(state, action);
    case actionTypes.DELETE_ADDRESS_RESET:
      return deleteMemberAddressesReset(state, action);
    default:
      return state;
  }
};
export default reducer;

import React from 'react';
import { COMMON_MSG, ModalType } from '../../../../shared/GlobalVar';
import FoxVehicleInfoCard from '../fox-vehicle/fox-vehicle-info-card';
import { INFO_ICON, LOCATION_04, ROUND_BLUE_DOT } from '../../../../shared/AppImages';
import { getSelectedVehicleHtml } from "../../../vehicle/vehicle-helper";
import LabelWithValue from '../../../../components/UI/label/LabelWithValue';
import { convertToDateTimeString } from '../../../../shared/utility/date-time-utility';

const SummaryBasicInfoBlock = ({ reservationUiModel, showModal }) => {
  const { rentalLocationName, rentalDateTime, returnLocationName, returnDateTime, rentalLocationId, returnLocationId, timezoneString } = reservationUiModel;
  const vehicleElement = reservationUiModel?.vehicleInfo?.savedCarClass;

  return (
    <div className="res-summary-vehicle-block">
      <div className="res-summary-vehicle-info pl-4 pr-4">
        {vehicleElement && (
          <FoxVehicleInfoCard
            vehicleElement={vehicleElement}
            getSelectedVehicleHtml={getSelectedVehicleHtml}
            key={vehicleElement.classCode + "res-summary-vehicle-block"}
            isPayLater={reservationUiModel.isPayLater}
            showFeatureSection={true}
            showClassCodeUnderCar={true}
          />
        )}
      </div>
      <div className="res-summary-location-block">
        <LabelWithValue
          label="Pick-up"
          value={rentalLocationName}
          otherValue={rentalDateTime}
          inputIcon={ROUND_BLUE_DOT}
          modalIcon={rentalLocationName && INFO_ICON}
          modalParams={{
            type: ModalType.LOCATION_QUICK_MODAL,
            locationId: rentalLocationId,
          }}
          showModal={showModal}
          iconTooltipText={COMMON_MSG.PickupLoactionQuickViewTooltipMsg}
          otherValueTooltipText={convertToDateTimeString(
            rentalDateTime,
            true,
            timezoneString,
            true
          )}
          imageClass="info-icon"
        />
        <span className="vertical-line"></span>
        <LabelWithValue
          label="Return"
          value={returnLocationName}
          otherValue={returnDateTime}
          inputIcon={LOCATION_04}
          modalIcon={returnLocationName && INFO_ICON}
          modalParams={{
            type: ModalType.LOCATION_QUICK_MODAL,
            locationId: returnLocationId,
          }}
          showModal={showModal}
          iconTooltipText={COMMON_MSG.ReturnLoactionQuickViewTooltipMsg}
          otherValueTooltipText={convertToDateTimeString(
            returnDateTime,
            true,
            timezoneString,
            true
          )}
          imageClass="info-icon"
        />
      </div>
    </div>
  );
};

export default SummaryBasicInfoBlock;

import moment from 'moment';
import React from 'react';
import DateWithTimeCtrl from '../../../../components/UI/Input/date-time/date-with-time-ctrl';
import { AdminDateFromat } from '../../../../shared/utility/enum-utility';

function RentalTimeContainer(props) {
    const { primaryFormData, setPrimaryFormData, showErrorMsg } = props;

    const onPickupDateChange = (margedDateTime) => {
        if (margedDateTime) {
            const updatedControls = { ...primaryFormData };
            let endtime = updatedControls.returnDateTime
                ? moment(
                    updatedControls.returnDateTime,
                    AdminDateFromat.FOX_API_DATE_TIME
                ).toDate()
                : moment(margedDateTime).toDate();
            if (moment(margedDateTime).toDate().getTime() >= endtime.getTime()) {
                endtime = moment(margedDateTime).add(1, "days");
                updatedControls.returnDateTime = endtime.format(
                    AdminDateFromat.FOX_API_DATE_TIME
                );
            }
            updatedControls.rentalDateTime = margedDateTime;
            updatedControls.rentalOnlyDate = margedDateTime;
            updatedControls.rentalOnlyTime = margedDateTime;
            updatedControls.returnOnlyDate = endtime;
            updatedControls.returnOnlyTime = endtime;
            setPrimaryFormData(updatedControls);
        }

    }

    const onReturnDateChange = (margedDateTime) => {
        if (margedDateTime) {
            const updatedControls = { ...primaryFormData };
            const starttime = updatedControls.rentalDateTime
                ? moment(
                    updatedControls.rentalDateTime,
                    AdminDateFromat.FOX_API_DATE_TIME
                ).toDate()
                : null;
            if (moment(margedDateTime).toDate().getTime() <= starttime.getTime()) {
                const msg = "Return Time must be after Rental Time.";
                showErrorMsg(msg);
                return false;
            } else {
                updatedControls.returnDateTime = margedDateTime;
                updatedControls.returnOnlyDate = margedDateTime;
                updatedControls.returnOnlyTime = margedDateTime;
                setPrimaryFormData(updatedControls);
            }
        }

    }
    return (
        <>
            <DateWithTimeCtrl
                key="pick-up-date"
                placeholder="Pick-up Date"
                onChange={onPickupDateChange}
                dateVal={primaryFormData.rentalOnlyDate}
                timeVal={primaryFormData.rentalOnlyTime}
                value={primaryFormData.rentalDateTime}
            />
            <DateWithTimeCtrl
                key="return-date"
                placeholder="Return Date"
                onChange={onReturnDateChange}
                dateVal={primaryFormData.returnOnlyDate}
                timeVal={primaryFormData.returnOnlyTime}
                value={primaryFormData.returnDateTime}
            />
        </>
    )
}

export default RentalTimeContainer
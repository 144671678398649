import React, { useState } from 'react';
import DialogEngine from '../../../../../components/dialog/dialog-engine';
import { ModalType } from '../../../../../shared/GlobalVar';
import { formatCurrency } from '../../../../../shared/utility';

function TaxesFeesRow(props) {
    const { taxes, surcharges } = props;
    const [modalData, setModalData] = useState(null);
    const getTotalTaxesAndFees = () => {
        let totalTaxAndFees = 0;
        taxes.map((data) => {
            totalTaxAndFees = totalTaxAndFees + data?.amount;
            return totalTaxAndFees;
        });
        surcharges.map((data) => {
            totalTaxAndFees = totalTaxAndFees + data?.amount;
            return totalTaxAndFees;
        });
        return totalTaxAndFees;
    }
    const onShowTaxesFeesModal = () => {
        setModalData(
            {
                modalType: ModalType.TAXES_FEES_MODAL,
                modalProps: {
                    showModal: true,
                    isDraggable: true,
                    closeModal: onCloseModal,
                    modalTitle: "Taxes & Fees",
                    modaWrapperClass: "taxes-fees-modal"
                },
                modalBodyProps: {
                    ...props,
                    totalTaxesFees: getTotalTaxesAndFees()
                },
            }
        );
    }
    const onCloseModal = () => {
        setModalData(null);
    }
    return (
        <div className="generel-rate-row position-relative">
            <div className={`col-lg-8`}><label className='lbl-link txt-underline' onClick={onShowTaxesFeesModal}>Taxes & Fees</label></div>
            <div className={`col-lg-4 text-right font-bold`}>{formatCurrency(getTotalTaxesAndFees())}</div>
            {modalData ?
                <DialogEngine
                    {...modalData}
                />
                : ""}
        </div>
    )
}

export default TaxesFeesRow
import { isNullOrEmpty } from "../../../../shared/utility";

export const SearchFleetUtilizationForecast = function (data = {}) {
  const defaultValue = (value) => !isNullOrEmpty(value) ? value : 0;

  this.fleetUtilizationForecastId = data.fleetUtilizationForecastId || null;
  this.orgUnitFleetClassGroupId = data.orgUnitFleetClassGroupId || null;
  this.classGroupCode = data.classGroupCode || null;
  this.displayText = data.displayText || null;
  /**reserve sync data section */
  this.forecastDate = data.forecastDate || null;
  this.dropOffCount = defaultValue(data.dropOffCount);
  this.pickupCount = defaultValue(data.pickupCount);
  this.defaultPickupThresholdPercent = defaultValue(data.defaultPickupThresholdPercent);
  this.defaultBookThruThresholdPercent = defaultValue(data.defaultBookThruThresholdPercent);
  this.noShowFactor = defaultValue(data.noShowFactor);
  this.walkinsCount = defaultValue(data.walkinsCount);
  this.runningReservationsCount = defaultValue(data.runningReservationsCount);
  this.runningRentalsCount = defaultValue(data.runningRentalsCount);
  this.usableFleetCount = defaultValue(data.usableFleetCount);
  this.availableFleetCount = defaultValue(data.availableFleetCount);
  this.eodAvailableFleetCount = defaultValue(data.eodAvailableFleetCount);
  this.utilizationFactorPercent = defaultValue(data.utilizationFactorPercent);
  this.pickupStatus = data.pickupStatus || null;
  this.bookThruStatus = data.bookThruStatus || null;
  /**manual section */
  this.manualPickupThresholdPercent = defaultValue(data.manualPickupThresholdPercent);
  this.manualBookThruThresholdPercent = defaultValue(data.manualBookThruThresholdPercent);
  this.manualWalkinsCount = defaultValue(data.manualWalkinsCount);
  this.manualNoShowFactor = defaultValue(data.manualNoShowFactor);
  /**manual override reason */
  this.manualOverrideReason = data.manualOverrideReason || null;
  this.manualOverrideReasonDisplayName = data.manualOverrideReasonDisplayName || null;
  this.manualOverrideOtherReason = data.manualOverrideOtherReason || null;
  /**time stamp */
  this.lastTimestamp = data.lastTimestamp || null;
  this.lastManualOverrideTimestamp = data.lastManualOverrideTimestamp || null;
};

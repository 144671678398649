import React from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import PermissionName from "../../../shared/Permissions";
import {
  hasPermission,
  isSettingsMenuPermitted,
} from "../../../shared/utility";
import { getMemberLabelByOrgRentalType } from "../../../shared/utility/system-utility";
import NavigationItem from "./NavigationItem/NavigationItem";

const NavigationItems = (props) => {
  const orgLocationId = useSelector(
    (state) => state.auth.loggedInUserData.CurrentOrgUnitId
  );

  return (
    <ul className="nav-main">
      <NavigationItem
        link="/"
        className="dropmenu__content--link--text dashboard"
        exact
        activeClassName="active"
      >
        Dashboard
      </NavigationItem>

      <li
        className={`dropmenu ${
          hasPermission(PermissionName.VIEW_MEMBER) ||
          hasPermission(PermissionName.NEW_MEMBER)
            ? ""
            : "displayNone"
        }`}
      >
        <NavLink
          to={hasPermission(PermissionName.VIEW_MEMBER) ? "/member" : ""}
          className="dropmenu__content--link--text memberMenu"
          activeClassName="active"
        >
          {getMemberLabelByOrgRentalType()}s
        </NavLink>
        <div className="dropmenu__content dropmenu--right">
          <NavLink
            to="/newmember"
            className={`dropmenu__content--link ${
              hasPermission(PermissionName.NEW_MEMBER) ? "" : "displayNone"
            }`}
            activeClassName="active"
          >
            <span className="dropmenu__content--link--text">
              New {getMemberLabelByOrgRentalType()}
            </span>
          </NavLink>
          <NavLink
            to="/member"
            className={`dropmenu__content--link ${
              hasPermission(PermissionName.VIEW_MEMBER) ? "" : "displayNone"
            }`}
            activeClassName="active"
          >
            <span className="dropmenu__content--link--text">Search All</span>
          </NavLink>
        </div>
      </li>
      {/* <NavigationItem
        link="/vehicle"
        exact
        className={`dropmenu__content--link--text vehicleMenu ${
          hasPermission(PermissionName.VIEW_VEHICLE)
            ? ""
            : "displayNone"
        }`}
        activeClassName="active"
      >
        Vehicles
      </NavigationItem> */}
      {hasPermission(PermissionName.VIEW_VEHICLE) ? (
        <li className="dropmenu">
          <NavLink
            to="/vehicle"
            className="dropmenu__content--link--text vehicleMenu"
            activeClassName="active"
          >
            Vehicles
          </NavLink>
          <div className="dropmenu__content dropmenu--right">
            {hasPermission(PermissionName.NEW_VEHICLE) ? (
              <NavLink
                to="/newVehicle"
                className="dropmenu__content--link"
                activeClassName="active"
              >
                <span className="dropmenu__content--link--text">
                  New Vehicle
                </span>
              </NavLink>
            ) : (
              ""
            )}
            <NavLink
              to="/vehicle"
              className="dropmenu__content--link"
              activeClassName="active"
            >
              <span className="dropmenu__content--link--text">
                Existing Vehicle
              </span>
            </NavLink>
          </div>
        </li>
      ) : (
        ""
      )}
      {hasPermission(PermissionName.VIEW_RESERVATION) ? (
        <li className="dropmenu">
          <NavLink
            to="/reservation"
            className="dropmenu__content--link--text reservationMenu"
            activeClassName="active"
            id="linMainNavReservation"
          >
            Reservations
          </NavLink>
          <div className="dropmenu__content dropmenu--right">
            {hasPermission(PermissionName.NEW_RESERVATION) ? (
              <NavLink
                to="/updateReservation"
                className="dropmenu__content--link"
                activeClassName="active"
              >
                <span className="dropmenu__content--link--text">
                  New Reservation
                </span>
              </NavLink>
            ) : (
              ""
            )}
            <NavLink
              to="/reservation"
              className="dropmenu__content--link"
              activeClassName="active"
            >
              <span className="dropmenu__content--link--text">
                Existing Reservations
              </span>
            </NavLink>
          </div>
        </li>
      ) : (
        ""
      )}
      {hasPermission(PermissionName.VIEW_RENTAL) ? (
        <NavigationItem
          link="/rental"
          exact
          className="dropmenu__content--link--text rentalMenu"
          activeClassName="active"
        >
          Rentals
        </NavigationItem>
      ) : (
        ""
      )}
      {hasPermission(PermissionName.VIEW_PAYMENT) ? (
        <NavigationItem
          link="/billing"
          exact
          className="dropmenu__content--link--text billingMenu"
          activeClassName="active"
        >
          Billing
        </NavigationItem>
      ) : (
        ""
      )}
      {hasPermission(PermissionName.ROLE_PERMISSION_SEARCH) ? (
        <NavigationItem
          link="/rolesPermission"
          exact
          className="dropmenu__content--link--text rolesPermissionMenu"
          activeClassName="active"
        >
          Roles & Permissions
        </NavigationItem>
      ) : (
        ""
      )}
      {/* {hasPermission(PermissionName.VIEW_INTEGRATION_INFO) ? (
        <NavigationItem
          link="/partnerInfo"
          exact
          className="dropmenu__content--link--text partnerInfoMenu"
          activeClassName="active"
        >
          Partner Info
        </NavigationItem>
      ) : (
        ""
      )} */}
      {hasPermission(PermissionName.CC_SEARCH_SHUTTLES) ? (
        <li className="dropmenu">
          <NavLink
            to="/shuttles"
            className="dropmenu__content--link--text shuttle-menu"
            activeClassName="active"
            id="linMainNavShuttles"
          >
            Shuttles
          </NavLink>
          <div className="dropmenu__content dropmenu--right">
            {hasPermission(PermissionName.CC_ADD_SHUTTLE) ? (
              <NavLink
                to="/shuttle"
                className="dropmenu__content--link"
                activeClassName="active"
              >
                <span className="dropmenu__content--link--text">
                  New Shuttles
                </span>
              </NavLink>
            ) : (
              ""
            )}
            <NavLink
              to="/shuttles"
              className="dropmenu__content--link"
              activeClassName="active"
            >
              <span className="dropmenu__content--link--text">
                Existing Shuttles
              </span>
            </NavLink>
          </div>
        </li>
      ) : (
        ""
      )}
      {hasPermission(PermissionName.CC_SEARCH_LANDMARKS) ? (
        <li className="dropmenu">
          <NavLink
            to="/landmarks"
            className="dropmenu__content--link--text landmarksMenu"
            activeClassName="active"
            id="linMainNavLandmarks"
          >
            Landmarks
          </NavLink>
          <div className="dropmenu__content dropmenu--right">
            {hasPermission(PermissionName.CC_ADD_LANDMARK) ? (
              <NavLink
                to="/landmark"
                className="dropmenu__content--link"
                activeClassName="active"
              >
                <span className="dropmenu__content--link--text">
                  New Landmarks
                </span>
              </NavLink>
            ) : (
              ""
            )}
            <NavLink
              to="/landmarks"
              className="dropmenu__content--link"
              activeClassName="active"
            >
              <span className="dropmenu__content--link--text">
                Existing Landmarks
              </span>
            </NavLink>
          </div>
        </li>
      ) : (
        ""
      )}
      {hasPermission(PermissionName.CC_SEARCH_FLEET_FORECASTS) ? (
        <NavigationItem
          link="/reserve-sync"
          exact
          className="dropmenu__content--link--text reserveSyncMenu"
          activeClassName="active"
          id="linkNavReserveSync"
        >
          Reserve Sync.
        </NavigationItem>
      ) : (
        ""
      )}
      {/* {hasPermission(PermissionName.ROLE_PERMISSION_SEARCH) ? (
        <NavigationItem
          link="/fleet-utilization-forecast"
          exact
          className="dropmenu__content--link--text fleetForecastMenu"
          activeClassName="active"
          id="linMainNavFleetForecasting"
        >
          Fleet Forecasting
        </NavigationItem>
      ) : (
        ""
      )} */}
      {hasPermission(PermissionName.COMPLAINT_SEARCH) ? (
        <NavigationItem
          link="/complaints"
          exact
          className="dropmenu__content--link--text complaintsMenu"
          activeClassName="active"
        >
          Complaints
        </NavigationItem>
      ) : (
        ""
      )}
      {hasPermission(PermissionName.IMPLEMENTATION_SEARCH) ? (
        <NavigationItem
          link="/implementations"
          exact
          className="dropmenu__content--link--text implementationMenu"
          activeClassName="active"
        >
          Implementations
        </NavigationItem>
      ) : (
        ""
      )}
      {hasPermission(PermissionName.BIRDS_EYE_VIEW_SEARCH) ? (
        <NavigationItem
          link="/birdseyeview"
          exact
          className="dropmenu__content--link--text birdEyeViewMenu"
          activeClassName="active"
        >
          Bird's Eye View
        </NavigationItem>
      ) : (
        ""
      )}
      <li
        className={`dropmenu ${
          hasPermission(PermissionName.REPORT_SEARCH) ||
          hasPermission(PermissionName.CC_SEARCH_SHUTTLE_TRIPS)
            ? ""
            : "displayNone"
        }`}
      >
        <NavLink
          to={`/report/maintenance`}
          className="dropmenu__content--link--text reportMenu"
          activeClassName="active"
        >
          Reports
        </NavLink>
        <div className="dropmenu__content dropmenu--right">
          <NavLink
            to={
              hasPermission(PermissionName.REPORT_SEARCH)
                ? "/report/maintenance"
                : ""
            }
            className={`dropmenu__content--link ${
              hasPermission(PermissionName.REPORT_SEARCH) ? "" : "displayNone"
            }`}
            activeClassName="active"
          >
            <span className={`dropmenu__content--link--text`}>
              Service Agent
            </span>
          </NavLink>

          <NavLink
            to={`/report/mechanics`}
            className={`dropmenu__content--link ${
              hasPermission(PermissionName.REPORT_SEARCH) ? "" : "displayNone"
            }`}
            activeClassName="active"
          >
            <span
              className={`dropmenu__content--link--text ${
                hasPermission(PermissionName.REPORT_SEARCH) ? "" : "displayNone"
              }`}
            >
              Mechanics
            </span>
          </NavLink>
          {/* <NavLink
            to={`/report/readyLineAgent`}
            className={`dropmenu__content--link ${
              hasPermission(PermissionName.REPORT_SEARCH) ? "" : "displayNone"
            }`}
            activeClassName="active"
          >
            <span
              className={`dropmenu__content--link--text ${
                hasPermission(PermissionName.REPORT_SEARCH) ? "" : "displayNone"
              }`}
            >
              Ready Line Agent
            </span>
          </NavLink> */}
          <NavLink
            to={`/report/shuttleTrips`}
            className={`dropmenu__content--link ${
              hasPermission(PermissionName.CC_SEARCH_SHUTTLE_TRIPS)
                ? ""
                : "displayNone"
            }`}
            activeClassName="active"
          >
            <span
              className={`dropmenu__content--link--text ${
                hasPermission(PermissionName.CC_SEARCH_SHUTTLE_TRIPS)
                  ? ""
                  : "displayNone"
              }`}
            >
              Shuttles
            </span>
          </NavLink>
        </div>
      </li>

      {isSettingsMenuPermitted() ? (
        <li className="dropmenu">
          <NavLink
            to={
              hasPermission(PermissionName.VIEW_MAIN_ORG_SETTINGS)
                ? `/setting/main?orgId=${orgLocationId}`
                : ""
            }
            className="dropmenu__content--link--text settingMenu"
            activeClassName="active"
          >
            Settings
          </NavLink>
          <div className="dropmenu__content dropmenu--right">
            <NavLink
              to={`/setting/main?orgId=${orgLocationId}`}
              className={`dropmenu__content--link ${
                hasPermission(PermissionName.VIEW_MAIN_ORG_SETTINGS)
                  ? ""
                  : "displayNone"
              } `}
              activeClassName="active"
            >
              <span className={`dropmenu__content--link--text`}>
                Main Information
              </span>
            </NavLink>
            <NavLink
              to={`/setting/hourOfOperation?orgId=${orgLocationId}`}
              className={`dropmenu__content--link ${
                hasPermission(PermissionName.VIEW_HOURS_ORG_SETTINGS)
                  ? ""
                  : "displayNone"
              } `}
              activeClassName="active"
            >
              <span className={`dropmenu__content--link--text`}>
                Hours of Operation
              </span>
            </NavLink>
            <NavLink
              to={`/setting/taxesSurcharges?orgId=${orgLocationId}`}
              className={`dropmenu__content--link ${
                hasPermission(PermissionName.VIEW_TAX_ORG_SETTINGS)
                  ? ""
                  : "displayNone"
              } `}
              activeClassName="active"
            >
              <span className={`dropmenu__content--link--text`}>
                Taxes & Surcharges
              </span>
            </NavLink>
            <NavLink
              to={`/setting/qualifications?orgId=${orgLocationId}`}
              className={`dropmenu__content--link ${
                hasPermission(PermissionName.VIEW_QUALIFICATION_ORG_SETTINGS)
                  ? ""
                  : "displayNone"
              } `}
              activeClassName="active"
            >
              <span className={`dropmenu__content--link--text`}>
                Qualifications
              </span>
            </NavLink>
            <NavLink
              to={`/setting/resSettings?orgId=${orgLocationId}`}
              className={`dropmenu__content--link ${
                hasPermission(PermissionName.VIEW_RESERVATION_ORG_SETTINGS)
                  ? ""
                  : "displayNone"
              } `}
              activeClassName="active"
            >
              <span className={`dropmenu__content--link--text`}>
                Reservations
              </span>
            </NavLink>
            <NavLink
              to={`/setting/insurance?orgId=${orgLocationId}`}
              className={`dropmenu__content--link ${
                hasPermission(PermissionName.VIEW_INSURANCE_ORG_SETTINGS)
                  ? ""
                  : "displayNone"
              } `}
              activeClassName="active"
            >
              <span className={`dropmenu__content--link--text`}>Insurance</span>
            </NavLink>
            <NavLink
              to={`/setting/addOnsSettings?orgId=${orgLocationId}`}
              className={`dropmenu__content--link ${
                hasPermission(PermissionName.VIEW_UPSELL_ORG_SETTINGS)
                  ? ""
                  : "displayNone"
              } `}
              activeClassName="active"
            >
              <span className={`dropmenu__content--link--text`}>
                Additional Options
              </span>
            </NavLink>
            <NavLink
              to={`/setting/location?orgId=${orgLocationId}`}
              className={`dropmenu__content--link ${
                hasPermission(PermissionName.VIEW_LOCATION_DETAIL_ORG_SETTINGS)
                  ? ""
                  : "displayNone"
              } `}
              activeClassName="active"
            >
              <span className={`dropmenu__content--link--text`}>Locations</span>
            </NavLink>
            {hasPermission(PermissionName.VIEW_BLACK_LIST_ORG_SETTINGS) ? (
              <NavLink
                to={`/setting/blackListedPerson?orgId=${orgLocationId}`}
                className="dropmenu__content--link"
                activeClassName="active"
              >
                <span className={`dropmenu__content--link--text`}>
                  Do Not Rent List
                </span>
              </NavLink>
            ) : (
              ""
            )}
            <NavLink
              to={`/setting/rates?orgId=${orgLocationId}`}
              className={`dropmenu__content--link ${
                hasPermission(PermissionName.VIEW_RATES_ORG_SETTINGS)
                  ? ""
                  : "displayNone"
              } `}
              activeClassName="active"
            >
              <span className={`dropmenu__content--link--text`}>Rates</span>
            </NavLink>
            <NavLink
              to={`/setting/capacityPlanning?orgId=${orgLocationId}`}
              className={`dropmenu__content--link ${
                hasPermission(PermissionName.VIEW_CAPACITY_BUCKETS_INFO)
                  ? ""
                  : "displayNone"
              } `}
              activeClassName="active"
            >
              <span className={`dropmenu__content--link--text`}>
                Capacity Planning
              </span>
            </NavLink>
            <NavLink
              to={`/setting/shuttles?orgId=${orgLocationId}`}
              className={`dropmenu__content--link ${
                hasPermission(PermissionName.CC_VIEW_SHUTTLE_SETTINGS)
                  ? ""
                  : "displayNone"
              } `}
              activeClassName="active"
            >
              <span className={`dropmenu__content--link--text`}>Shuttles</span>
            </NavLink>
            <NavLink
              to={`/setting/vehicleSettings?orgId=${orgLocationId}`}
              className={`dropmenu__content--link ${
                hasPermission(PermissionName.VIEW_AVAILABILITY_ORG_SETTINGS)
                  ? ""
                  : "displayNone"
              } `}
              activeClassName="active"
            >
              <span className={`dropmenu__content--link--text`}>
                Vehicle Availability
              </span>
            </NavLink>
            <NavLink
              to={`/setting/roles?orgId=${orgLocationId}`}
              className={`dropmenu__content--link ${
                hasPermission(PermissionName.VIEW_ROLE) ? "" : "displayNone"
              } `}
              activeClassName="active"
            >
              <span className={`dropmenu__content--link--text`}>Roles</span>
            </NavLink>
            <NavLink
              to={`/setting/permission?orgId=${orgLocationId}`}
              className={`dropmenu__content--link ${
                hasPermission(PermissionName.VIEW_PERMISSION)
                  ? ""
                  : "displayNone"
              } `}
              activeClassName="active"
            >
              <span className={`dropmenu__content--link--text`}>
                Permission
              </span>
            </NavLink>
          </div>
        </li>
      ) : (
        ""
      )}
    </ul>
  );
};

export default NavigationItems;

import React, { useState } from 'react'
import CityCtrl from '../../../../components/address/ctrl/city-ctrl'
import CountryCtrl from '../../../../components/address/ctrl/country-ctrl'
import PostalCtrl from '../../../../components/address/ctrl/postal-ctrl'
import StateCtrl from '../../../../components/address/ctrl/state-ctrl'
import CustomAccordion from '../../../../components/UI/accordion/custom-accordion'
import InputCtrl from '../../../../components/UI/Input/input-ctrl'
import { CITY_01, CITY_03, INTERNET, ROAD_01, ZIP_02 } from '../../../../shared/AppImages'
import { CountryKeyValueType } from '../../../../shared/utility/enum-utility'

function AdditionalAddressInformation(props) {
    const { onChange, addressData, zipToAddressData, addressType, disabled, clearSecurityDepositRate, stateCityLoader } = props;
    const [isActive, setIsActive] = useState(false);
    const onAddressChange = (value, name) => {
        const tempData = { ...addressData };
        tempData[name] = value;
        onChange(tempData, "primaryAddress");
    }
    return (
        <div className='flex-column row-gap-1'>
            <CustomAccordion title="Additional Address Info" isActive={isActive} setIsActive={setIsActive} />
            {isActive ?
                <>
                    <InputCtrl
                        name="street"
                        type="text"
                        placeholder="Street 1"
                        value={addressData?.street}
                        onChange={onAddressChange}
                        disabled={disabled}
                        imgIco={ROAD_01}
                    />
                    <InputCtrl
                        name="street2"
                        type="text"
                        placeholder="Street 2"
                        value={addressData?.street2}
                        onChange={onAddressChange}
                        disabled={disabled}
                        imgIco={ROAD_01}
                    />
                    <CountryCtrl
                        name="country"
                        placeholder="Country"
                        imgIco={INTERNET}
                        keyType={CountryKeyValueType.CountryCode}
                        value={addressData?.country || "US"}
                        addressData={addressData}
                        addressType={addressType}
                        onChange={onChange}
                        disabled={true}
                    />
                    <PostalCtrl
                        name="postal"
                        placeholder="Zip/Postal Code"
                        value={addressData?.postalCode}
                        //addressData={addressData}
                        //addressType={addressType}
                        //onChange={onChange}
                        imgIco={ZIP_02}
                        disabled={true}
                    />
                    <StateCtrl
                        name="province"
                        placeholder="State"
                        value={addressData?.province}
                        countryCode={addressData?.country}
                        imgIco={CITY_01}
                        zipAddressReponse={zipToAddressData}
                        disabled={disabled}
                        addressData={addressData}
                        addressType={addressType}
                        onChange={onAddressChange}
                        stateCityLoader={stateCityLoader}
                        clearSecurityDepositRate={clearSecurityDepositRate}
                    />
                    <CityCtrl
                        name="city"
                        placeholder="City"
                        value={addressData?.city}
                        countryCode={addressData?.country}
                        stateCode={addressData?.province}
                        zipAddressReponse={zipToAddressData}
                        imgIco={CITY_03}
                        disabled={disabled}
                        addressData={addressData}
                        addressType={addressType}
                        onChange={onAddressChange}
                        stateCityLoader={stateCityLoader}
                        clearSecurityDepositRate={clearSecurityDepositRate}
                    />
                </> : ""}
        </div>
    )
}

export default AdditionalAddressInformation
import * as actionTypes from "./actionTypesSettings";
import HttpService, { invokeServerAPI } from "../../services/httpService";

//Main Settings Actions
export const getMainSettingInfoStart = () => {
  return {
    type: actionTypes.GET_MAIN_SETTING_INFO_START,
  };
};
export const getMainSettingInfoSuccess = (response) => {
  return {
    type: actionTypes.GET_MAIN_SETTING_INFO_SUCCESS,
    mainSettingInfoResponse: response,
  };
};
export const getMainSettingInfoFail = (error) => {
  return {
    type: actionTypes.GET_MAIN_SETTING_INFO_FAIL,
    error: error,
  };
};
export const getMainSettingInfoReset = () => {
  return {
    type: actionTypes.GET_MAIN_SETTING_INFO_RESET,
  };
};

export const updateMainSettingInfoStart = () => {
  return {
    type: actionTypes.UPDATE_MAIN_SETTING_INFO_START,
  };
};
export const updateMainSettingInfoSuccess = (response) => {
  return {
    type: actionTypes.UPDATE_MAIN_SETTING_INFO_SUCCESS,
    mainSettingInfoUpdateResponse: response,
  };
};
export const updateMainSettingInfoFail = (error) => {
  return {
    type: actionTypes.UPDATE_MAIN_SETTING_INFO_FAIL,
    error: error,
  };
};
export const updateMainSettingInfoReset = () => {
  return {
    type: actionTypes.UPDATE_MAIN_SETTING_INFO_RESET,
  };
};

export const getMainSettingInfo = (req) => {
  return (dispatch) => {
    dispatch(getMainSettingInfoStart());
    invokeServerAPI("getgeneralsettings", req)
      .then((response) => {
        dispatch(getMainSettingInfoSuccess(response));
      })
      .catch((err) => {
        //dispatch(getMainSettingInfoFail(err));
      });
  };
};
export const updateMainSettingInfo = (req) => {
  return (dispatch) => {
    dispatch(updateMainSettingInfoStart());
    invokeServerAPI("updategeneralsettings", req)
      .then((response) => {
        dispatch(updateMainSettingInfoSuccess(response));
      })
      .catch((err) => {
        //dispatch(updateMainSettingInfoFail(err));
      });
  };
};
//Hours Of operation Actions
export const getHourOfOpsSettingInfoStart = () => {
  return {
    type: actionTypes.GET_HOUR_OF_OPS_SETTING_INFO_START,
  };
};
export const getHourOfOpsSettingInfoSuccess = (response) => {
  return {
    type: actionTypes.GET_HOUR_OF_OPS_SETTING_INFO_SUCCESS,
    hoursOfOpsSettingInfoResponse: response,
  };
};
export const getHourOfOpsSettingInfoFail = (error) => {
  return {
    type: actionTypes.GET_HOUR_OF_OPS_SETTING_INFO_FAIL,
    error: error,
  };
};
export const getHourOfOpsSettingInfoReset = () => {
  return {
    type: actionTypes.GET_HOUR_OF_OPS_SETTING_INFO_RESET,
  };
};

export const updateHourOfOpsSettingInfoStart = () => {
  return {
    type: actionTypes.UPDATE_HOUR_OF_OPS_SETTING_INFO_START,
  };
};
export const updateHourOfOpsSettingInfoSuccess = (response) => {
  return {
    type: actionTypes.UPDATE_HOUR_OF_OPS_SETTING_INFO_SUCCESS,
    hoursOfOpsSettingUpdateResponse: response,
  };
};
export const updateHourOfOpsSettingInfoFail = (error) => {
  return {
    type: actionTypes.UPDATE_HOUR_OF_OPS_SETTING_INFO_FAIL,
    error: error,
  };
};
export const updateHourOfOpsSettingInfoReset = () => {
  return {
    type: actionTypes.UPDATE_HOUR_OF_OPS_SETTING_INFO_RESET,
  };
};

export const getHourOfOpsSettingInfo = (settingInfoObject) => {
  return (dispatch) => {
    dispatch(getHourOfOpsSettingInfoStart());
    HttpService.getHoursOfOperationData(settingInfoObject)
      .then((response) => {
        dispatch(getHourOfOpsSettingInfoSuccess(response));
      })
      .catch((err) => {
        //dispatch(getHourOfOpsSettingInfoFail(err));
      });
  };
};
export const updateHourOfOpsSettingInfo = (settingInfoObject) => {
  return (dispatch) => {
    dispatch(updateHourOfOpsSettingInfoStart());
    HttpService.saveHoursOfOperations(settingInfoObject)
      .then((response) => {
        dispatch(updateHourOfOpsSettingInfoSuccess(response));
        dispatch(getHourOfOpsSettingInfoSuccess(response));
      })
      .catch((err) => {
        //dispatch(updateHourOfOpsSettingInfoFail(err));
      });
  };
};

//Tax and surcharge Actions
export const getTaxSurchargeInfoStart = () => {
  return {
    type: actionTypes.GET_TAX_SURCHARGE_INFO_START,
  };
};
export const getTaxSurchargeInfoSuccess = (response) => {
  return {
    type: actionTypes.GET_TAX_SURCHARGE_INFO_SUCCESS,
    taxSurchargeInfoResponse: response,
  };
};
export const getTaxSurchargeInfoFail = (error) => {
  return {
    type: actionTypes.GET_TAX_SURCHARGE_INFO_FAIL,
    error: error,
  };
};
export const getTaxSurchargeInfoReset = () => {
  return {
    type: actionTypes.GET_TAX_SURCHARGE_INFO_RESET,
  };
};

export const updateTaxSurchargeInfoStart = () => {
  return {
    type: actionTypes.UPDATE_TAX_SURCHARGE_INFO_START,
  };
};
export const updateTaxSurchargeInfoSuccess = (response) => {
  return {
    type: actionTypes.UPDATE_TAX_SURCHARGE_INFO_SUCCESS,
    taxSurchargeInfoUpdateResponse: response,
  };
};
export const updateTaxSurchargeInfoFail = (error) => {
  return {
    type: actionTypes.UPDATE_TAX_SURCHARGE_INFO_FAIL,
    error: error,
  };
};
export const updateTaxSurchargeInfoReset = () => {
  return {
    type: actionTypes.UPDATE_TAX_SURCHARGE_INFO_RESET,
  };
};

export const getTaxSurchargeInfo = (settingInfoObject) => {
  return (dispatch) => {
    dispatch(getTaxSurchargeInfoStart());
    HttpService.getTaxesAndSurchargesSettingsWindow(settingInfoObject)
      .then((response) => {
        dispatch(getTaxSurchargeInfoSuccess(response));
      })
      .catch((err) => {
        //dispatch(getTaxSurchargeInfoFail(err));
      });
  };
};
export const updateTaxSurchargeInfo = (settingInfoObject) => {
  return (dispatch) => {
    dispatch(updateTaxSurchargeInfoStart());
    HttpService.updateTaxSurchargesSettings(settingInfoObject)
      .then((response) => {
        dispatch(updateTaxSurchargeInfoSuccess(response));
        dispatch(getTaxSurchargeInfoSuccess(response));
      })
      .catch((err) => {
        //dispatch(updateTaxSurchargeInfoFail(err));
      });
  };
};

//Qualification Actions
export const getQualificationInfoStart = () => {
  return {
    type: actionTypes.GET_QUALIFICATION_INFO_START,
  };
};
export const getQualificationInfoSuccess = (response) => {
  return {
    type: actionTypes.GET_QUALIFICATION_INFO_SUCCESS,
    qualificationInfoResponse: response,
  };
};
export const getQualificationInfoFail = (error) => {
  return {
    type: actionTypes.GET_QUALIFICATION_INFO_FAIL,
    error: error,
  };
};
export const getQualificationInfoReset = () => {
  return {
    type: actionTypes.GET_QUALIFICATION_INFO_RESET,
  };
};

export const updateQualificationInfoStart = () => {
  return {
    type: actionTypes.UPDATE_QUALIFICATION_INFO_START,
  };
};
export const updateQualificationInfoSuccess = (response) => {
  return {
    type: actionTypes.UPDATE_QUALIFICATION_INFO_SUCCESS,
    qualificationInfoUpdateResponse: response,
  };
};
export const updateQualificationInfoFail = (error) => {
  return {
    type: actionTypes.UPDATE_QUALIFICATION_INFO_FAIL,
    error: error,
  };
};
export const updateQualificationInfoReset = () => {
  return {
    type: actionTypes.UPDATE_QUALIFICATION_INFO_RESET,
  };
};

export const getQualificationInfo = (settingInfoObject) => {
  return (dispatch) => {
    dispatch(getQualificationInfoStart());
    HttpService.getOrgQualificationSettingsData(settingInfoObject)
      .then((response) => {
        dispatch(getQualificationInfoSuccess(response));
      })
      .catch((err) => {
        //dispatch(getQualificationInfoFail(err));
      });
  };
};
export const updateQualificationInfo = (settingInfoObject) => {
  return (dispatch) => {
    dispatch(updateQualificationInfoStart());
    HttpService.saveQualificationSettings(settingInfoObject)
      .then((response) => {
        dispatch(updateQualificationInfoSuccess(response));
        dispatch(getQualificationInfoSuccess(response));
      })
      .catch((err) => {
        //dispatch(updateQualificationInfoFail(err));
      });
  };
};

//Reservation Settings Actions
export const getReservationSettingsInfoStart = () => {
  return {
    type: actionTypes.GET_RES_SETTINGS_INFO_START,
  };
};
export const getReservationSettingsInfoSuccess = (response) => {
  return {
    type: actionTypes.GET_RES_SETTINGS_INFO_SUCCESS,
    reservationSettingsInfoResponse: response,
  };
};
export const getReservationSettingsInfoFail = (error) => {
  return {
    type: actionTypes.GET_RES_SETTINGS_INFO_FAIL,
    error: error,
  };
};
export const getReservationSettingsInfoReset = () => {
  return {
    type: actionTypes.GET_RES_SETTINGS_INFO_RESET,
  };
};

export const updateReservationSettingsInfoStart = () => {
  return {
    type: actionTypes.UPDATE_RES_SETTINGS_INFO_START,
  };
};
export const updateReservationSettingsInfoSuccess = (response) => {
  return {
    type: actionTypes.UPDATE_RES_SETTINGS_INFO_SUCCESS,
    reservationSettingsInfoUpdateResponse: response,
  };
};
export const updateReservationSettingsInfoFail = (error) => {
  return {
    type: actionTypes.UPDATE_RES_SETTINGS_INFO_FAIL,
    error: error,
  };
};
export const updateReservationSettingsInfoReset = () => {
  return {
    type: actionTypes.UPDATE_RES_SETTINGS_INFO_RESET,
  };
};

export const getReservationSettingsInfo = (settingInfoObject) => {
  return (dispatch) => {
    dispatch(getReservationSettingsInfoStart());
    HttpService.getOrgReservationSettingsData(settingInfoObject)
      .then((response) => {
        dispatch(getReservationSettingsInfoSuccess(response));
      })
      .catch((err) => {
        //dispatch(getReservationSettingsInfoFail(err));
      });
  };
};
export const updateReservationSettingsInfo = (settingInfoObject) => {
  return (dispatch) => {
    dispatch(updateReservationSettingsInfoStart());
    HttpService.saveReservationSettings(settingInfoObject)
      .then((response) => {
        dispatch(updateReservationSettingsInfoSuccess(response));
        dispatch(getReservationSettingsInfoSuccess(response));
      })
      .catch((err) => {
        //dispatch(updateReservationSettingsInfoFail(err));
      });
  };
};

//Insurance Settings Actions
export const getInsuranceSettingsInfoStart = () => {
  return {
    type: actionTypes.GET_INSURANCE_SETTINGS_INFO_START,
  };
};
export const getInsuranceSettingsInfoSuccess = (response) => {
  return {
    type: actionTypes.GET_INSURANCE_SETTINGS_INFO_SUCCESS,
    insuranceSettingsInfoResponse: response,
  };
};
export const getInsuranceSettingsInfoFail = (error) => {
  return {
    type: actionTypes.GET_INSURANCE_SETTINGS_INFO_FAIL,
    error: error,
  };
};
export const getInsuranceSettingsInfoReset = () => {
  return {
    type: actionTypes.GET_INSURANCE_SETTINGS_INFO_RESET,
  };
};

export const updateInsuranceSettingsInfoStart = () => {
  return {
    type: actionTypes.UPDATE_INSURANCE_SETTINGS_INFO_START,
  };
};
export const updateInsuranceSettingsInfoSuccess = (response) => {
  return {
    type: actionTypes.UPDATE_INSURANCE_SETTINGS_INFO_SUCCESS,
    insuranceSettingsInfoUpdateResponse: response,
  };
};
export const updateInsuranceSettingsInfoFail = (error) => {
  return {
    type: actionTypes.UPDATE_INSURANCE_SETTINGS_INFO_FAIL,
    error: error,
  };
};
export const updateInsuranceSettingsInfoReset = () => {
  return {
    type: actionTypes.UPDATE_INSURANCE_SETTINGS_INFO_RESET,
  };
};

export const getInsuranceSettingsInfo = (settingInfoObject) => {
  return (dispatch) => {
    dispatch(getInsuranceSettingsInfoStart());
    HttpService.getInsuranceSettings(settingInfoObject)
      .then((response) => {
        dispatch(getInsuranceSettingsInfoSuccess(response));
      })
      .catch((err) => {
        //dispatch(getInsuranceSettingsInfoFail(err));
      });
  };
};
export const updateInsuranceSettingsInfo = (settingInfoObject) => {
  return (dispatch) => {
    dispatch(updateInsuranceSettingsInfoStart());
    HttpService.updateInsuranceSettings(settingInfoObject)
      .then((response) => {
        dispatch(updateInsuranceSettingsInfoSuccess(response));
        dispatch(getInsuranceSettingsInfoSuccess(response));
      })
      .catch((err) => {
        //dispatch(updateInsuranceSettingsInfoFail(err));
      });
  };
};

//Additional Options Settings Actions
export const getAdditionalOptionsSettingsInfoStart = () => {
  return {
    type: actionTypes.GET_ADDONS_SETTINGS_INFO_START,
  };
};
export const getAdditionalOptionsSettingsInfoSuccess = (response) => {
  return {
    type: actionTypes.GET_ADDONS_SETTINGS_INFO_SUCCESS,
    additionalOptionsSettingsInfoResponse: response,
  };
};
export const getAdditionalOptionsSettingsInfoFail = (error) => {
  return {
    type: actionTypes.GET_ADDONS_SETTINGS_INFO_FAIL,
    error: error,
  };
};
export const getAdditionalOptionsSettingsInfoReset = () => {
  return {
    type: actionTypes.GET_ADDONS_SETTINGS_INFO_RESET,
  };
};

export const updateAdditionalOptionsSettingsInfoStart = () => {
  return {
    type: actionTypes.UPDATE_ADDONS_SETTINGS_INFO_START,
  };
};
export const updateAdditionalOptionsSettingsInfoSuccess = (response) => {
  return {
    type: actionTypes.UPDATE_ADDONS_SETTINGS_INFO_SUCCESS,
    additionalOptionsSettingsInfoUpdateResponse: response,
  };
};
export const updateAdditionalOptionsSettingsInfoFail = (error) => {
  return {
    type: actionTypes.UPDATE_ADDONS_SETTINGS_INFO_FAIL,
    error: error,
  };
};
export const updateAdditionalOptionsSettingsInfoReset = () => {
  return {
    type: actionTypes.UPDATE_ADDONS_SETTINGS_INFO_RESET,
  };
};

export const getAdditionalOptionsSettingsInfo = (settingInfoObject) => {
  return (dispatch) => {
    dispatch(getAdditionalOptionsSettingsInfoStart());
    HttpService.getAllAdditionalOptions(settingInfoObject)
      .then((response) => {
        dispatch(getAdditionalOptionsSettingsInfoSuccess(response));
      })
      .catch((err) => {
        //dispatch(getAdditionalOptionsSettingsInfoFail(err));
      });
  };
};
export const updateAdditionalOptionsSettingsInfo = (settingInfoObject) => {
  return (dispatch) => {
    dispatch(updateAdditionalOptionsSettingsInfoStart());
    HttpService.updateAdditionalOptionsSettings(settingInfoObject)
      .then((response) => {
        dispatch(updateAdditionalOptionsSettingsInfoSuccess(response));
        dispatch(getAdditionalOptionsSettingsInfoSuccess(response));
      })
      .catch((err) => {
        //dispatch(updateAdditionalOptionsSettingsInfoFail(err));
      });
  };
};

//Location Settings Actions
export const getLocationSettingsInfoStart = () => {
  return {
    type: actionTypes.GET_LOCATION_SETTINGS_INFO_START,
  };
};
export const getLocationSettingsInfoSuccess = (response) => {
  return {
    type: actionTypes.GET_LOCATION_SETTINGS_INFO_SUCCESS,
    locationSettingsInfoSearchResponse: response,
  };
};
export const getLocationSettingsInfoFail = (error) => {
  return {
    type: actionTypes.GET_LOCATION_SETTINGS_INFO_FAIL,
    error: error,
  };
};
export const getLocationSettingsInfoReset = () => {
  return {
    type: actionTypes.GET_LOCATION_SETTINGS_INFO_RESET,
  };
};

export const addLocationSettingsInfoStart = () => {
  return {
    type: actionTypes.ADD_LOCATION_SETTINGS_INFO_START,
  };
};
export const addLocationSettingsInfoSuccess = (response) => {
  return {
    type: actionTypes.ADD_LOCATION_SETTINGS_INFO_SUCCESS,
    addLocationResponse: response,
  };
};
export const addLocationSettingsInfoFail = (error) => {
  return {
    type: actionTypes.ADD_LOCATION_SETTINGS_INFO_FAIL,
    error: error,
  };
};
export const addLocationSettingsInfoReset = () => {
  return {
    type: actionTypes.ADD_LOCATION_SETTINGS_INFO_RESET,
  };
};

export const updateLocationSettingsInfoStart = () => {
  return {
    type: actionTypes.UPDATE_LOCATION_SETTINGS_INFO_START,
  };
};
export const updateLocationSettingsInfoSuccess = (response) => {
  return {
    type: actionTypes.UPDATE_LOCATION_SETTINGS_INFO_SUCCESS,
    locationSettingsInfoUpdateResponse: response,
  };
};
export const updateLocationSettingsInfoFail = (error) => {
  return {
    type: actionTypes.UPDATE_LOCATION_SETTINGS_INFO_FAIL,
    error: error,
  };
};
export const updateLocationSettingsInfoReset = () => {
  return {
    type: actionTypes.UPDATE_LOCATION_SETTINGS_INFO_RESET,
  };
};

export const getLocationSettingsInfo = (settingInfoObject) => {
  return (dispatch) => {
    dispatch(getLocationSettingsInfoStart());
    HttpService.getOrgLocationSettings(settingInfoObject)
      .then((response) => {
        dispatch(getLocationSettingsInfoSuccess(response));
      })
      .catch((err) => {
        //dispatch(getLocationSettingsInfoFail(err));
      });
  };
};
export const addLocationSettingsInfo = (settingInfoObject) => {
  return (dispatch) => {
    dispatch(addLocationSettingsInfoStart());
    HttpService.addNewOrgLocationSettings(settingInfoObject)
      .then((response) => {
        dispatch(addLocationSettingsInfoSuccess(response));
      })
      .catch((err) => {
        //dispatch(addLocationSettingsInfoFail(err));
      });
  };
};
export const updateLocationSettingsInfo = (settingInfoObject) => {
  return (dispatch) => {
    dispatch(updateLocationSettingsInfoStart());
    HttpService.updateOrgLocationSettings(settingInfoObject)
      .then((response) => {
        dispatch(updateLocationSettingsInfoSuccess(response));
        dispatch(getLocationSettingsInfoSuccess(response));
      })
      .catch((err) => {
        //dispatch(updateLocationSettingsInfoFail(err));
      });
  };
};

//Rate Settings Actions
export const getRateSettingsInfoStart = () => {
  return {
    type: actionTypes.GET_RATE_SETTINGS_INFO_START,
  };
};
export const getRateSettingsInfoSuccess = (response) => {
  return {
    type: actionTypes.GET_RATE_SETTINGS_INFO_SUCCESS,
    rateSettingsResponse: response,
  };
};
export const getRateSettingsInfoFail = (error) => {
  return {
    type: actionTypes.GET_RATE_SETTINGS_INFO_FAIL,
    error: error,
  };
};
export const getRateSettingsInfoReset = () => {
  return {
    type: actionTypes.GET_RATE_SETTINGS_INFO_RESET,
  };
};

export const updateRateSettingsInfoStart = () => {
  return {
    type: actionTypes.UPDATE_RATE_SETTINGS_INFO_START,
  };
};
export const updateRateSettingsInfoSuccess = (response) => {
  return {
    type: actionTypes.UPDATE_RATE_SETTINGS_INFO_SUCCESS,
    rateSettingsInfoUpdateResponse: response,
  };
};
export const updateRateSettingsInfoFail = (error) => {
  return {
    type: actionTypes.UPDATE_RATE_SETTINGS_INFO_FAIL,
    error: error,
  };
};
export const updateRateSettingsInfoReset = () => {
  return {
    type: actionTypes.UPDATE_RATE_SETTINGS_INFO_RESET,
  };
};

export const getRateSettingsInfo = (settingInfoObject) => {
  return (dispatch) => {
    dispatch(getRateSettingsInfoStart());
    HttpService.getOrgRateSettingsData(settingInfoObject)
      .then((response) => {
        dispatch(getRateSettingsInfoSuccess(response));
      })
      .catch((err) => {
        //dispatch(getRateSettingsInfoFail(err));
      });
  };
};
export const updateRateSettingsInfo = (settingInfoObject) => {
  return (dispatch) => {
    dispatch(updateRateSettingsInfoStart());
    HttpService.saveRateSettings(settingInfoObject)
      .then((response) => {
        dispatch(updateRateSettingsInfoSuccess(response));
        dispatch(getRateSettingsInfoSuccess(response));
      })
      .catch((err) => {
        //dispatch(updateRateSettingsInfoFail(err));
      });
  };
};

//Vehicle Availability Settings Actions
export const getVehicleAvailabilitySettingsInfoStart = () => {
  return {
    type: actionTypes.GET_VEHICLE_AVAILABILITY_SETTINGS_INFO_START,
  };
};
export const getVehicleAvailabilitySettingsInfoSuccess = (response) => {
  return {
    type: actionTypes.GET_VEHICLE_AVAILABILITY_SETTINGS_INFO_SUCCESS,
    vehicleAvailabilitySettingsResponse: response,
  };
};
export const getVehicleAvailabilitySettingsInfoFail = (error) => {
  return {
    type: actionTypes.GET_VEHICLE_AVAILABILITY_SETTINGS_INFO_FAIL,
    error: error,
  };
};
export const getVehicleAvailabilitySettingsInfoReset = () => {
  return {
    type: actionTypes.GET_VEHICLE_AVAILABILITY_SETTINGS_INFO_RESET,
  };
};

export const updateVehicleAvailabilitySettingsInfoStart = () => {
  return {
    type: actionTypes.UPDATE_VEHICLE_AVAILABILITY_SETTINGS_INFO_START,
  };
};
export const updateVehicleAvailabilitySettingsInfoSuccess = (response) => {
  return {
    type: actionTypes.UPDATE_VEHICLE_AVAILABILITY_SETTINGS_INFO_SUCCESS,
    updateVehicleAvailabilitySettingsResponse: response,
  };
};
export const updateVehicleAvailabilitySettingsInfoFail = (error) => {
  return {
    type: actionTypes.UPDATE_VEHICLE_AVAILABILITY_SETTINGS_INFO_FAIL,
    error: error,
  };
};
export const updateVehicleAvailabilitySettingsInfoReset = () => {
  return {
    type: actionTypes.UPDATE_VEHICLE_AVAILABILITY_SETTINGS_INFO_RESET,
  };
};

export const getVehicleAvailabilitySettingsInfo = (settingInfoObject) => {
  return (dispatch) => {
    dispatch(getVehicleAvailabilitySettingsInfoStart());
    HttpService.getOrgVehicleAvailabilitySettingsData(settingInfoObject)
      .then((response) => {
        dispatch(getVehicleAvailabilitySettingsInfoSuccess(response));
      })
      .catch((err) => {
        //dispatch(getVehicleAvailabilitySettingsInfoFail(err));
      });
  };
};
export const updateVehicleAvailabilitySettingsInfo = (settingInfoObject) => {
  return (dispatch) => {
    dispatch(updateVehicleAvailabilitySettingsInfoStart());
    HttpService.saveOrgVehicleAvailabilitySettingsData(settingInfoObject)
      .then((response) => {
        dispatch(updateVehicleAvailabilitySettingsInfoSuccess(response));
        dispatch(getVehicleAvailabilitySettingsInfoSuccess(response));
      })
      .catch((err) => {
        //dispatch(updateVehicleAvailabilitySettingsInfoFail(err));
      });
  };
};

//Capacity Planning
export const getCapacityBucketsStart = () => {
  return {
    type: actionTypes.GET_CAPACITY_PLANNING_DATA_START,
  };
};
export const getCapacityBucketsSuccess = (response) => {
  return {
    type: actionTypes.GET_CAPACITY_PLANNING_DATA_SUCCESS,
    capacityBucketResponse: response,
  };
};
export const getCapacityBucketsFail = (error) => {
  return {
    type: actionTypes.GET_CAPACITY_PLANNING_DATA_FAIL,
    error: error,
  };
};
export const getCapacityBucketsReset = () => {
  return {
    type: actionTypes.GET_CAPACITY_PLANNING_DATA_RESET,
  };
};

export const updateCapacityBucketsStart = () => {
  return {
    type: actionTypes.UPDATE_CAPACITY_PLANNING_DATA_START,
  };
};
export const updateCapacityBucketsSuccess = (response) => {
  return {
    type: actionTypes.UPDATE_CAPACITY_PLANNING_DATA_SUCCESS,
    capacityBucketUpdateResponse: response,
  };
};
export const updateCapacityBucketsFail = (error) => {
  return {
    type: actionTypes.UUPDATE_CAPACITY_PLANNING_DATA_FAIL,
    error: error,
  };
};
export const updateCapacityBucketsReset = () => {
  return {
    type: actionTypes.UPDATE_CAPACITY_PLANNING_DATA_RESET,
  };
};

export const getCapacityBuckets = (settingInfoObject) => {
  return (dispatch) => {
    dispatch(getCapacityBucketsStart());
    invokeServerAPI("getcapacitybuckets", settingInfoObject)
      .then((response) => {
        dispatch(getCapacityBucketsSuccess(response));
      })
      .catch((err) => {
        //dispatch(getCapacityBucketsFail(err));
      });
  };
};
export const updateCapacityBuckets = (settingInfoObject) => {
  return (dispatch) => {
    dispatch(updateCapacityBucketsStart());
    invokeServerAPI("savecapacitybuckets", settingInfoObject)
      .then((response) => {
        dispatch(updateCapacityBucketsSuccess(response));
        dispatch(getCapacityBucketsSuccess(response));
      })
      .catch((err) => {
        //dispatch(updateCapacityBucketsFail(err));
      });
  };
};

/** Common method for festh settings data*/

export const getSettingstDataByApiStart = () => {
  return {
    type: actionTypes.GET_SETTINGS_DATA_API_START,
  };
};
export const getSettingstDataByApiSuccess = (response, isInheritedData) => {
  return {
    type: actionTypes.GET_SETTINGS_DATA_API_SUCCESS,
    settingsResponse: response,
    isInheritedData: isInheritedData,
  };
};
export const getSettingstDataByApiFail = (error) => {
  return {
    type: actionTypes.GET_SETTINGS_DATA_API_FAIL,
    error: error,
  };
};
export const getSettingstDataByApiReset = (isInheritedData) => {
  return {
    type: actionTypes.GET_SETTINGS_DATA_API_RESET,
    isInheritedData: isInheritedData,
  };
};
export const getSettingstDataByApi = (
  req,
  apiName,
  isInheritedData,
  isSystemApi
) => {
  return (dispatch) => {
    dispatch(getSettingstDataByApiStart());
    invokeServerAPI(apiName, req, isSystemApi || false)
      .then((response) => {
        dispatch(getSettingstDataByApiSuccess(response, isInheritedData));
      })
      .catch((err) => {
        //dispatch(getSettingstDataByApiFail(err));
      });
  };
};

/** Common method for update settings data*/
export const updateSettingstDataByApiStart = () => {
  return {
    type: actionTypes.UPDATE_SETTINGS_DATA_API_START,
  };
};
export const updateSettingstDataByApiSuccess = (response) => {
  return {
    type: actionTypes.UPDATE_SETTINGS_DATA_API_SUCCESS,
    updateSettingsResponse: response,
  };
};
export const updateSettingstDataByApiFail = (error) => {
  return {
    type: actionTypes.UPDATE_SETTINGS_DATA_API_FAIL,
    error: error,
  };
};
export const updateSettingstDataByApiReset = () => {
  return {
    type: actionTypes.UPDATE_SETTINGS_DATA_API_RESET,
  };
};
export const updateSettingstDataByApi = (req, apiName, isSystemApi) => {
  return (dispatch) => {
    dispatch(updateSettingstDataByApiStart());
    invokeServerAPI(apiName, req, isSystemApi || false)
      .then((response) => {
        dispatch(updateSettingstDataByApiSuccess(response));
        dispatch(getSettingstDataByApiSuccess(response));
      })
      .catch((err) => {
        //dispatch(updateSettingstDataByApiFail(err));
      });
  };
};

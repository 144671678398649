import React from 'react';
import { getCustomerReservationHistoryModal, getInvoiceModal, getTransactionModal } from '../../../components/modal/modern-modal-data';
import ImageIcon from '../../../components/UI/icon/image-icon';
import { CAR_HISTORY_ICON, INVOICE, TRANSACTION } from '../../../shared/AppImages';

function ReservationIcon(props) {
    const { externalCode, customerId, dbReservationData, setModalData, setShowLoader, iconClusterType } = props;
    return (
        iconClusterType === "SUMMARY" ?
            <div className="flex-row new-res-summary-container gap-1">
                {/* <ImageIcon iconClass="fa fa-history"
                    title="Old Reservation Summary"
                    onClick={() => getOldReservationModal({ dbReservationData: dbReservationData }, setModalData, setShowLoader)} /> */}
                <ImageIcon img={INVOICE}
                    iconClass="invoice-icon"
                    title="Click to View Reservation Invoice"
                    onClick={() => getInvoiceModal({ reservationId: externalCode || 11094858795 }, setModalData, setShowLoader)} />
                <ImageIcon img={TRANSACTION}
                    iconClass="transaction-icon"
                    title="Click to View Transaction List"
                    onClick={() => getTransactionModal({ reservationId: externalCode || 11095360900 }, setModalData, setShowLoader)} />
            </div>
            : iconClusterType === "CUSTOMER" ?
                <div className="flex-row new-res-summary-container gap-1">
                    <ImageIcon img={CAR_HISTORY_ICON}
                        iconClass="car-history-icon"
                        title="Click to View Customer Reservation History"
                        onClick={() => getCustomerReservationHistoryModal({ memberId: customerId }, setModalData, setShowLoader)} />
                </div>
                : ""
    )
}

export default ReservationIcon
import React from "react";

export const WatermarkTextComponent = (props) => {
  return (
    <span
      id={props.id || ""}
      className={props.className || "watermark"}
      style={props.styles || {}}
    >
      {props.values || ""}
    </span>
  );
};

export default WatermarkTextComponent; 
import React from "react";
import NavSidebarItem from "../Navigations/NavigationItems/NavigationItem/NavSidebarItem";
const Sidebar = (props) => {
  const isImpersonateMode = localStorage.getItem("loginMode") === "IMPERSONATE";
  const getSearchParams = (params) => {
    let queryParams;
    params.map((itemObj, index) => {
      if (index === 0) {
        queryParams = "?" + itemObj.key + "=" + itemObj.value;
      } else {
        queryParams = queryParams + "&" + itemObj.key + "=" + itemObj.value;
      }
    });
    return queryParams;
  };
  const getNavigationHtml = () => {
    return props.subMenu.subMenuItems.map((menuItem, index) => {
      return (
        <NavSidebarItem
          key={index}
          menuitemkey={menuItem.itemKey}
          to={{
            pathname: "/" + props.subMenu.menuKey + "/" + menuItem.itemKey,
            search:
              menuItem.params.length > 0
                ? getSearchParams(menuItem.params)
                : "",
            state: {
              breadCrumbList: props.breadCrumbList,
              breadCrumbTitle: menuItem.itemLabel,
            },
          }}
        >
          <i className={menuItem.iconClass} />
          <span>{menuItem.itemLabel}</span>
        </NavSidebarItem>
      );
    });
  };

  return (
    <nav id="sidebar" className={`${isImpersonateMode ? "sidebar-impersonate-border" : ""} ${props.isExpandableSidebar ? "active" : ""}`}>
      <div className="sidebar-header">
        <button
          className="btn btn-round toggleBtn"
          onClick={(event) => props.toggleNavbar()}
        >
          <i
            className={
              props.isExpandableSidebar
                ? "fa fa-angle-double-right"
                : "fa fa-angle-double-left"
            }
          />
          <div className="ripple-container" />
        </button>
      </div>
      <ul className="list-unstyled components">{getNavigationHtml()}</ul>
    </nav>
  );
};
export default Sidebar;

export const ReportProblemDetailsRequest = function() {
  this.ReservationId = null;
  this.MemberId = null;
  this.AssetId = null;
  this.Category = null;
  this.SubCategory = null;
  this.ProblemType = null;
  this.ProblemSubType = null;
  this.MDate = null;
  this.Priority = null;
  this.Action = null;
  this.Status = null;
};

const EndRentalInputCtrl = {
  MemberId: null,
  BorrowerName: null,
  ReservationId: null,
  ConfirmationCode: null,
  ReservationState: null,
  MemberAssetId: null,
  VehicleName: null,
  CategoryId: null,
  UpgradedCategoryId: null,

  IntegAdjustedByName: null,
  IntegArrivedByName: null,
  IntegCompletedByName: null,
  IntegRecordedBy: null,
  IntegAdjustedBy: null,
  IntegArrivedBy: null,
  IntegCompletedBy: null,
  IntegRecordedBy: null,

  LateReservation: null,
  LateChargeableOption: null,

  PickupLocation: null,
  PickupLocationId: null,
  ReturnLocationId: null,

  PromisedStartTime: null,
  PromisedEndTime: null,
  ActualStartDate: null,
  ActualEndDate: null,
  CheckoutDate: null,
  CompletedOn: null,
  ModifiedStartTime: null,

  OdometerOut: null,
  OdometerIn: null,
  CustomerReportedOdometerIn: null,
  CustomerReportedFuelIn: null,
  FuelOut: 100,
  FuelIn: null,

  Notes: null,
  CCANotes: null,

  PrimaryImageUrl: null,

  LateChargeableOption: null,
  AppliedCreditList: [],
  AppliedChargeList: [],
  DiscountType: null,
  DiscountValue: null,
  DiscountReason: null,
  OverridePayment: false,
  OverrideEmail: false
};
export default EndRentalInputCtrl;

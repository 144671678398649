import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getContractReset,
  getContract,
  acceptTermsAndConditions,
  acceptTermsAndConditionsReset,
} from "../../../store/actions/index";
import { showNotification } from "../../../components/UI/Toaster/Toaster";
import { getPrintPopup, getBasicToastResponse } from "../../../shared/utility";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Draggable from "react-draggable";
import MailModal from "../../search/modal/mail-modal";
import { MailType } from "../../../shared/GlobalVar";

export const ContractModal = (props) => {
  const reservationId = props.reservationId;
  const dispatch = useDispatch();
  const contractResponse = useSelector(
    (state) => state.reservationReducer.contractResponse
  );
  const acceptTermsConditionsResponse = useSelector(
    (state) => state.reservationReducer.acceptTermsConditionsResponse
  );
  const [contratView, setContractView] = useState(null);
  const [showMailModal, setShowMailModal] = useState(false);
  useEffect(() => {
    const contractReq = {};
    contractReq.ReservationId = reservationId;
    contractReq.AcknowledgeConsent = true;
    dispatch(getContractReset());
    dispatch(acceptTermsAndConditionsReset());
    dispatch(getContract(contractReq));
  }, []);
  useEffect(() => {
    if (!contractResponse) {
      return;
    }
    if (contractResponse.StatusCode === 0) {
      let contractReportData = contractResponse.Contract
        ? contractResponse.Contract.replace("body", "section")
            .replace("<body", "<section id='contractDetailsSectionId'")
            .replace("</body>", "</section>")
        : "";
      setContractView(contractReportData);
    } else {
      showNotification(getBasicToastResponse(contractResponse));
      props.closeModal();
    }
    dispatch(getContractReset());
  }, [contractResponse]);

  useEffect(() => {
    if (!acceptTermsConditionsResponse) {
      return;
    }
    showNotification(getBasicToastResponse(acceptTermsConditionsResponse));
    if (acceptTermsConditionsResponse.StatusCode === 0) {
      dispatch(acceptTermsAndConditionsReset());
      props.resetParentPage();
      props.closeMailModal();
    }
    dispatch(acceptTermsAndConditionsReset());
  }, [acceptTermsConditionsResponse]);
  const closeMailModal = () => {
    setShowMailModal(false);
  };
  const getFormattedText = (data) => {
    return {
      __html: data,
    };
  };
  const onAcceptContract = () => {
    const acceptReq = {};
    acceptReq.ReservationId = props.reservationId;
    acceptReq.AcknowledgeConsent = true;
    dispatch(acceptTermsAndConditionsReset());
    dispatch(acceptTermsAndConditions(acceptReq));
  };

  return contratView ? (
    <Draggable handle=".cursor">
      <Modal
        isOpen={props.showModal}
        className="no-cursor"
        toggle={() => {
          props.closeModal();
        }}
        style={{
          minWidth: "105rem",
        }}
      >
        <ModalHeader
          className="modal-header bg-primary modal-title text-white cursor"
          toggle={() => {
            props.closeModal();
          }}
        >
          <i className="fas fa-arrows-alt" /> "Contract Details"
        </ModalHeader>
        <ModalBody>
          <div className="col-lg-12 text-right pb-3">
            <button
              type="button"
              className="btn btn-primary waves-effect waves-light btnSize ml-2"
              onClick={() => getPrintPopup("Contract")}
              disabled={contratView ? false : true}
            >
              <i className="fa fa-check-square-o" />
              Print
            </button>

            <button
              type="button"
              className="btn btn-primary waves-effect waves-light btnSize ml-2"
              onClick={() => setShowMailModal(true)}
              disabled={contratView ? false : true}
            >
              <i className="fa fa-mail" />
              Email
            </button>
            {!props.contractAccepted ? (
              <button
                type="button"
                className="btn btn-primary waves-effect waves-light btnSize ml-2"
                onClick={() => onAcceptContract()}
                disabled={contratView ? false : true}
              >
                <i className="fa fa-check-square-o" />
                Accept Contract
              </button>
            ) : (
              ""
            )}
          </div>
          <div
            className="col-lg-12 tableScroll"
            style={{
              minHeight: "53rem",
            }}
          >
            <div
              id="divPrintbody"
              className="print_popup_body col-lg-12 row p-0 mt-5"
              dangerouslySetInnerHTML={getFormattedText(
                contratView ? contratView : ""
              )}
            />
          </div>
          {showMailModal ? (
            <MailModal
              showModal={showMailModal}
              closeModal={closeMailModal}
              defaultData={{
                reservationId: props.reservationId,
                mailType: MailType.EMAIL_CONTRACT,
              }}
            />
          ) : (
            ""
          )}
        </ModalBody>
      </Modal>
    </Draggable>
  ) : (
    ""
  );
};
export default ContractModal;

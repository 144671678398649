import React, {
  Fragment, useEffect,
  useState
} from "react";
import { checkImageUrl } from "../../../shared/utility/url-utility";
const ImageContainer = (props) => {
  const [src, setSrc] = useState(null);
  useEffect(() => {
    if (props.imageUrl) {
      checkImageUrl(props.imageUrl, function () {
        setSrc(props.imageUrl);

      }, function () {
        setSrc(props.defaultImage);
      });
    }
  }, [props.defaultImage, props.imageUrl]);


  let defaultImageSize = "logo-size";
  return (src ?
    <Fragment>
      <img
        src={src}
        alt={props.altText || "No ImageData"}
        className={`${props.imageClass || defaultImageSize} ${props.backgroundImageClass || "background-image-class"
          } `}
        style={props.customImageBGCSS}
        onError={(event) => props.onErrorEvent && props.onErrorEvent(event, props.DefaultImage)}
      />
      {props.imageCaption ?
        <span
          className={props.imageCaptionClass || "logo-text"}
          title={props.imageTooltip || ""}
        >
          {props.imageCaption || ""}
        </span> : ""}
    </Fragment> : ""
  );
};

export default ImageContainer;

import * as actionTypes from "../actions/actionTypes";
import { reducerUpdateObject } from "./reducer.utility";

const initialState = {
    availabilitySummaryInFleetResponse: null,
  error: null,
  loading: false
};
const getAvailabilitySummaryInFleetStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};

const getAvailabilitySummaryInFleetSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    availabilitySummaryInFleetResponse: action.availabilitySummaryInFleetResponse,
    error: null,
    loading: false
  });
};

const getAvailabilitySummaryInFleetFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false
  });
};
const getAvailabilitySummaryInFleetReset = (state, action) => {
  return reducerUpdateObject(state, { availabilitySummaryInFleetResponse:null});
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_AVAILABILITY_SUMMARY_IN_FLEET_START:
      return getAvailabilitySummaryInFleetStart(state, action);
    case actionTypes.GET_AVAILABILITY_SUMMARY_IN_FLEET_SUCCESS:
      return getAvailabilitySummaryInFleetSuccess(state, action);
    case actionTypes.GET_AVAILABILITY_SUMMARY_IN_FLEET_FAIL:
      return getAvailabilitySummaryInFleetFail(state, action);
    case actionTypes.GET_AVAILABILITY_SUMMARY_IN_FLEET_RESET:
      return getAvailabilitySummaryInFleetReset(state, action);

    default:
      return state;
  }
};
export default reducer;

import React, { useState } from "react";
import {
  convertDateTimeString,
  getErrorToastData,
} from "../../../shared/utility";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Draggable from "react-draggable";
import { validateBlockUnblockProfile } from "../MemberHelper";
import { showNotification } from "../../../components/UI/Toaster/Toaster";

const BlockUnblockMemberModal = (props) => {
  const [notes, setNotes] = useState("");
  const [isValidateDone, setIsValidateDone] = useState(false);

  const doBlockUnblockProfile = () => {
    const blockUnblockReq = {};
    blockUnblockReq.MemberId = props.memberId;
    blockUnblockReq.Notes = notes;

    let msg = "";
    if (!msg) {
      msg = validateBlockUnblockProfile(blockUnblockReq);
    }

    if (msg) {
      showNotification(getErrorToastData(msg));
      setIsValidateDone(true);
    } else {
      props.updateBlockUnblockProfile(
        blockUnblockReq,
        props.memberData ? props.memberData.MemberStatus : ""
      );
      setIsValidateDone(false);
    }
  };

  const memberData = props.memberData;
  return (
    <div>
      <Draggable handle=".cursor">
        <Modal
          isOpen={props.showModal}
          className="no-cursor"
          toggle={props.closeModal}
        >
          <ModalHeader
            className="modal-header bg-primary modal-title text-white cursor"
            toggle={props.closeModal}
          >
            <i className="fas fa-arrows-alt" />
            {memberData.MemberStatus === "SUSPENDED"
              ? " Unblock Profile"
              : " Block Profile"}
          </ModalHeader>
          <ModalBody>
            <div className="form-group row tableScroll border-none">
              <div className="FormRow col-sm-12 pl-0">
                <div className="form-group row">
                  <label className="col-sm-5 col-form-label font-weight-bold">
                    {memberData.MemberStatus === "SUSPENDED"
                      ? "Blocked Date"
                      : "Unblocked Date"}
                  </label>
                  <label className="col-sm-7 pl-0">
                    {memberData.MemberStatus === "SUSPENDED"
                      ? memberData.BlockDate
                        ? convertDateTimeString(memberData.BlockDate)
                        : "--"
                      : memberData.UnblockDate
                      ? convertDateTimeString(memberData.UnblockDate)
                      : "--"}
                  </label>
                </div>
                <div className="form-group row">
                  <label className="col-sm-5 col-form-label font-weight-bold">
                    {memberData.MemberStatus === "SUSPENDED"
                      ? "Blocked Comments"
                      : "Unblocked Comments"}
                  </label>
                  <label className="col-sm-7 pl-0">
                    {memberData.MemberStatus === "SUSPENDED"
                      ? memberData.BlockComment || "--"
                      : memberData.UnblockComment || "--"}
                  </label>
                </div>
                <div className="form-group row required">
                  <label className="col-sm-5 col-form-label font-weight-bold">
                    {memberData.MemberStatus === "SUSPENDED"
                      ? "Unblocking Comments"
                      : "Blocking Comments"}
                  </label>
                  <div
                    className={`col-sm-7 pl-0 ${
                      notes ? "" : isValidateDone ? "validation-failed" : ""
                    }`}
                  >
                    <textarea
                      rows="20"
                      name="notes"
                      value={notes}
                      style={{ height: "55px", resize: "none" }}
                      onChange={(event) => setNotes(event.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button
              type="submit"
              name="btnSave"
              className="btn btn-primary waves-effect waves-light btnSize ml-2"
              onClick={doBlockUnblockProfile}
            >
              {memberData.MemberStatus === "SUSPENDED" ? "Unblock" : "Block"}
            </button>
            <button
              type="button"
              name="btnCancel"
              className="btn btn-secondary  waves-effect waves-light btnSize ml-2"
              onClick={props.closeModal}
            >
              Cancel
            </button>
          </ModalFooter>
        </Modal>
      </Draggable>
    </div>
  );
};

export default BlockUnblockMemberModal;

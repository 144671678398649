import React from 'react'
import ImageContainer from '../../../../../components/UI/image/image-container'
import { FlashyCar } from '../../../../../shared/AppImages'
import { loadDefaultImage } from '../../../../../shared/utility'
import CommonTitle from '../../../component/common-title'
import AttributeLabel from '../attribute-label'
import FeatureAttrContainer from './feature-attr-container'

function VehicleInfoContainer({ vehicleClass, setDialogData, showFeatureAttr = true }) {
    return (
        <div className='vehicle-info-container'>
            <div className='flex-column justify-content-center align-items-center'>
                <ImageContainer imageUrl={vehicleClass?.classImageURL}
                    imageCaption={vehicleClass?.classCode}
                    imageTooltip={vehicleClass?.classDesc}
                    imageCaptionClass=""
                    backgroundImageClass=""
                    defaultImage={FlashyCar}
                    onErrorEvent={loadDefaultImage}
                    altText={vehicleClass?.modelDesc}
                    imageClass="v-img"
                />
            </div>
            <div className='vehicle-information'>
                <CommonTitle title={vehicleClass?.modelDesc} classNames="common-title" />
                <div className='attr-container'>
                    <AttributeLabel title={vehicleClass?.classDesc} classNames="attr-lbl car-class" />
                </div>
                {showFeatureAttr && <FeatureAttrContainer vehicleClass={vehicleClass} setDialogData={setDialogData} />}
            </div>
        </div>
    )
}

export default VehicleInfoContainer
import { getSubString } from "../../../../shared/utility";
import { getLandmarkIconColor } from "../../../../shared/utility/enum-utility";
import { KeyValuePairModel } from "../../models/domain/key-value-pair-model";

export const LandmarkSearchViewModel = function (data) {
  if (!data) data = {};
  this.landmarkId = data.landmarkId || null;
  this.landmarkCode = data.landmarkCode || null;
  this.name = data.name || null;
  this.shortName = data.shortName || null;
  this.latitude = data.latitude || null;
  this.longitude = data.longitude || null;
  this.formattedAddress = data.formattedAddress || null;
  this.locationCode = data.locationCode || null;
  this.locationDisplayName = data.locationDisplayName || null;
  this.status = data.status || null;
  this.shuttleIconClassName = null;
  this.landmarkCategory = new KeyValuePairModel(data.landmarkCategory);
  this.shortNameForIcon = getSubString(data.shortName, 0, 2);
  this.iconColor = getLandmarkIconColor(data.landmarkCategory ? data.landmarkCategory.key : "");
};

import { getIconStringForExport } from "../../../../containers/Reservation/reservation-helper";
import UpsellOptionModel from "../UpsellOptionModel";

const ReservationSearchModel = function (data) {
  if (!data) data = {};
  this.ActualEndDate = data.ActualEndDate || null;
  this.AcknowledgeConsent = data.AcknowledgeConsent || null;
  this.ApprovedOn = data.ApprovedOn || null;
  this.AssetGroupType = data.AssetGroupType || null;
  this.Category = data.Category || null;
  this.UpgradeCategory = data.UpgradeCategory || null;
  this.CategoryName = data.CategoryName || null;

  this.AttendanceStatus = data.AttendanceStatus || null;

  this.BorrowerAvgResponseTime = data.BorrowerAvgResponseTime || null;
  this.BorrowerAvgResponseTimeStr = data.BorrowerAvgResponseTimeStr || null;
  this.BalanceDue = data.BalanceDue || 0;
  this.BorrowerFirstName = data.BorrowerFirstName || null;
  this.BorrowerId = data.BorrowerId || null;
  this.BorrowerEmail = data.BorrowerEmail || null;
  this.BorrowerLastName = data.BorrowerLastName || null;
  this.BorrowerName = data.BorrowerName || null;
  this.BorrowerResponseRate = data.BorrowerResponseRate || null;
  this.BorrowerResponseRateStr = data.BorrowerResponseRateStr || null;
  this.CancellationNotes = data.CancellationNotes ? data.CancellationNotes.replace(/\r\n|\n/, "") : null;
  this.CancellationReason = data.CancellationReason || null;

  this.CancelledBy = data.CancelledBy || null;
  this.CancelledOn = data.CancelledOn || null;
  this.CompletedOn = data.CompletedOn || null;
  this.ConfirmationKey = data.ConfirmationKey || null;

  this.IntegConfirmNumber = data.IntegConfirmNumber || null;
  this.ContractAccepted = data.ContractAccepted || null;
  this.ContractAcceptedDate = data.ContractAcceptedDate || null;
  this.CreatedOn = data.CreatedOn || null;
  this.TimezoneString = data.TimezoneString || null;
  this.IsLateReservation = data.IsLateReservation || null;
  this.IsReservationRequest = data.IsReservationRequest || null;
  this.KeyRequiredStatus = data.KeyRequiredStatus || null;
  this.LastModifiedDate = data.LastModifiedDate || null;
  this.LastModifiedMethod = data.LastModifiedMethod || null;
  this.LastPromisedStartTime = data.LastPromisedStartTime || null;
  this.IntegStatus = data.IntegStatus || null;


  this.Make = data.Make || null;
  this.Model = data.Model || null;
  this.OwnerAvgResponseTime = data.OwnerAvgResponseTime || false;
  this.OwnerFirstName = data.OwnerFirstName || null;
  this.OwnerId = data.OwnerId || false;
  this.OwnerLastName = data.OwnerLastName || false;
  this.OwnerName = data.OwnerName || false;
  this.OwnerResponseRate = data.OwnerResponseRate || null;
  this.OptInXpress = data.OptInXpress ? "Opted-in" : "Not Opted-in";


  this.ParticipantType = data.ParticipantType || null;
  this.PersonAssetId = data.PersonAssetId || null;
  this.PersonAssetName = data.PersonAssetName || null;

  this.PersonAssetUnitNo = data.PersonAssetUnitNo || null;

  this.PickUpLocation = data.PickUpLocation || null;

  this.PickUpLocationId = data.PickUpLocationId || null;
  this.PromisedStartDate = data.PromisedStartDate || null;
  this.PromiseEndDate = data.PromiseEndDate || null;
  this.RequestDate = data.RequestDate || null;
  this.ReservationId = data.ReservationId || null;
  this.ReservationState = data.ReservationState || null;
  this.ReservationType = data.ReservationType || null;
  this.ReturnLocation = data.ReturnLocation || null;
  this.ReturnLocationId = data.ReturnLocationId || null;

  this.Hours = data.Hours || 0;
  this.Days = data.Days || 0;
  this.Weeks = data.Weeks || 0;
  this.Months = data.Months || 0;
  this.DurationInDays = data.DurationInDays || 0;
  this.TotalDays = data.TotalDays || null;
  this.TotalDeductible = data.TotalDeductible || 0;
  this.TotalDeductibleCost = data.TotalDeductibleCost || 0;
  this.TotalEstimatedPaid = data.TotalEstimatedPaid || 0;
  this.TotalEstimatedPaidSP = data.TotalEstimatedPaidSP || 0;

  this.Days = data.Days || 0;
  this.DurationInDays = data.DurationInDays || 0;
  this.TotalHours = data.TotalHours || null;
  this.TotalMinutes = data.TotalMinutes || null;
  this.TotalOwnerEarnings = data.TotalOwnerEarnings || null;
  this.TotalPricePaid = data.TotalPricePaid || 0;
  this.TotalPricePaidSP = data.TotalPricePaidSP || 0;

  this.UpsellSelected = data.UpsellSelected || null;
  this.VehicleYear = data.VehicleYear || null;
  this.CCNotesExists = data.CCNotesExists || null;
  this.VoucherAdded = data.VoucherAdded || null;
  this.VoucherAmount = data.VoucherAmount || 0;

  this.RescheduleCount = data.RescheduleCount || 0;
  this.ReservationCreationType = data.ReservationCreationType || null;
  this.ActualStartDate = data.ActualStartDate || null;
  this.CancellationReasonDisplay = data.CancellationReasonDisplay || null;

  //Credit Type
  this.FreeCreditGranted = data.FreeCreditGranted || null;
  this.FreeCreditGrantedAmount = data.FreeCreditGrantedAmount || 0;
  this.FreeCreditApplied = data.FreeCreditApplied || null;
  this.FreeCreditAppliedAmount = data.FreeCreditAppliedAmount || 0;
  this.BorrowerEmployeeNo = data.BorrowerEmployeeNo || null;
  this.BorrowerBadgeNo = data.BorrowerBadgeNo || null;
  this.BorrowerLocationName = data.BorrowerLocationName || null;
  const upsellOptions = [];
  if (data.UpsellOptions) {
    data.UpsellOptions.map((upsellEntity) => {
      upsellOptions.push(new UpsellOptionModel(upsellEntity));
    });
  }
  this.UpsellOptions = [...upsellOptions];
  this.Icons = getIconStringForExport(data.UpsellSelected, data.ContractAccepted, data.CCNotesExists, data.ReservationCreationType === "TEST", data.VoucherAdded);
};
export default ReservationSearchModel;

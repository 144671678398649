import React, { useState } from "react";
import { DropdownList } from "react-widgets";
import "./input-ctrl.scss";

export const InputWidgetCtrl = (props) => {
    const {
        name,
        value,
        placeholder,
        onChange,
        disabled,
        externalData,
        iconClass,
        imgIco,
        data,
        valueField,
        textField,
        loader,
        itemComponent,
        valueComponent,
        groupComponent,
        onClear,
        defaultConfig,
        isRequired,
        validateFunction
    } = props;
    const [error, setError] = useState(false);
    const onHandleOnChange = (data) => {
        if (isRequired && validateFunction) {
            setError(!validateFunction(name, data[valueField], true));
        }
        onChange(data, name, externalData);
    }
    const onRemove = (e) => {
        onClear(name);
    }
    return (
        <div className='input-ctrl position-relative'>
            {imgIco ? <img
                src={imgIco}
                alt=""
                className={`${disabled ? 'filter-gray' : ""}`}
            /> : iconClass ? <i className={iconClass} aria-hidden="true"></i> : ""}
            {placeholder && <label className={`place-holder ${isRequired ? "required" : ""}`}>{placeholder}</label>}
            <DropdownList
                id={name}
                name={name}
                //placeHolder={placeholder}
                placeholder="Select"
                filter="contains"
                data={data}
                valueField={valueField}
                textField={textField}
                value={value}
                onChange={(event) =>
                    onChange ? onHandleOnChange(event) : ""
                }
                disabled={disabled && true}
                busy={loader}
                busySpinner={
                    <span className="fas fa-sync fa-spin" />
                }
                itemComponent={itemComponent ? itemComponent : null}
                valueComponent={valueComponent ? valueComponent : null}
                groupComponent={groupComponent ? groupComponent : null}
                hide
                {...defaultConfig}
                className={`${error ? "error-field" : ""}`}
            />
            {value && onClear &&
                <i className="fas fa-times-circle fs-0  clear-icon drop-clear-icon" aria-hidden="true" onClick={onRemove} title="Clear" />
            }
        </div>
    )
}
export default InputWidgetCtrl;
import React, { useEffect, useState } from "react";
import Draggable from "react-draggable";
import { useDispatch, useSelector } from "react-redux";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { LabelAndValuePairControl } from "../../../components/UI/Input/label-value-pair-control";
import { showNotification } from "../../../components/UI/Toaster/Toaster";
import { FoxRequestHeader } from "../../../services/entities/fox-api/domain/fox-request-header";
import {
    getErrorToastData,
    updateObject
} from "../../../shared/utility";
import {
    updateReservationDetails,
    updateReservationDetailsReset
} from "../../../store/actions/index";
import { convertMsgResponse } from "../fox-reservation/fox-res-helper";

export const ReservationDetailsUpdateModal = (props) => {
    const reservationData = props.defaultData;
    const dispatch = useDispatch();
    const updateReservationDetailsResponse = useSelector(
        (state) => state.reservationReducer.updateReservationDetailsResponse
    );
    const [updateReservationDetailsReq, setUpdateReservationDetailsReq] = useState({});

    const [eyeIconInputType, setEyeIconInputType] = useState("password");
    useEffect(() => {
        setUpdateReservationDetailsReq({
            reservationCode: reservationData.confirmationCode,
            productCode: reservationData.productCode,
            newProductCode: reservationData.productCode,
            productCodeFlag: true,
        });
    }, []);
    useEffect(() => {
        if (!updateReservationDetailsResponse) {
            return;
        }
        showNotification(
            convertMsgResponse(updateReservationDetailsResponse.message)
        );
        if (
            updateReservationDetailsResponse.message &&
            updateReservationDetailsResponse.message.messageType === "SUCCESS"
        ) {
            dispatch(updateReservationDetailsReset());
            props.resetParentPage();
            props.closeModal();
        }
        dispatch(updateReservationDetailsReset());
    }, [updateReservationDetailsResponse]);
    const disablePasswordBox = () => {
        if (
            updateReservationDetailsReq.productCode !==
            updateReservationDetailsReq.newProductCode
        ) {
            return false;
        }
        return true;
    };
    const handleOnChange = (event, controlName, elementType) => {
        let updateCtrls = { ...updateReservationDetailsReq };
        const value =
            elementType === "checkbox" ? event.target.checked : event.target.value;
        updateCtrls = updateObject(updateCtrls, {
            [controlName]: value,
        });
        setUpdateReservationDetailsReq(updateCtrls);
    };

    const onFormSubmit = () => {
        if (props.valueToUpdate === "productCode") {
            if (!updateReservationDetailsReq.newProductCode) {
                showNotification(getErrorToastData("Product Code is Required!"));
                return false;
            }
            const newReq = {
                requestHeader: new FoxRequestHeader({ "requestType": "POST" }),
                reservationCode: reservationData.confirmationCode,
                productCode: updateReservationDetailsReq.newProductCode,
                productCodeFlag: true,
            }
            dispatch(updateReservationDetailsReset());
            dispatch(updateReservationDetails(newReq));
        }
    };
    const renderLabelInputPair = (
        labelCaption,
        controlName,
        value,
        viewOnly = false,
        nodeElement = "input"
    ) => {
        const isCheckbox = nodeElement === "checkbox";
        const valueOrCheckedAttribute = isCheckbox ? "checked" : "value";
        const isPassword = controlName === "password";
        const disabledProps = isPassword ? { disabled: disablePasswordBox() } : {};
        return (
            <LabelAndValuePairControl
                elementConfig={{
                    name: controlName,
                    type: isPassword ? eyeIconInputType : isCheckbox ? "checkbox" : null,
                    [valueOrCheckedAttribute]: value,
                    ...disabledProps,
                }}
                otherConfig={{
                    caption: labelCaption,
                    viewOnly: viewOnly,
                    isEyeIconRequired: isPassword,
                    isHideLabel: isCheckbox,
                    checkBoxCaptionClass: isCheckbox ? "font-weight-bold" : "",
                }}
                isEditMode={true}
                onChange={handleOnChange}
                toggleInputTypeForPasswordField={isPassword ? setEyeIconInputType : ""}
                parentRowClass={`form-group row`}
                labelClasses={"col-sm-4 col-form-label font-weight-bold"}
                valueClasses={`col-sm-8`}
                nodeElement={nodeElement}
            />
        );
    };

    const renderData = () => {
        return (
            <div className="col-lg-12 p-0">
                {renderLabelInputPair(
                    "Reservation Code",
                    "confirmationCode",
                    reservationData.confirmationCode,
                    true
                )}
                {props.valueToUpdate === "productCode" ?
                    <>
                        {renderLabelInputPair(
                            "Old Product Code",
                            "productCode",
                            reservationData.productCode,
                            true
                        )}
                        {renderLabelInputPair(
                            "New Product Code",
                            "newProductCode",
                            updateReservationDetailsReq.newProductCode,
                            false
                        )}
                    </>
                    : ""}
            </div>
        );
    };
    return (
        <Draggable handle=".cursor">
            <Modal
                isOpen={props.showModal}
                className="no-cursor"
                // toggle={() => {
                //     props.closeModal();
                // }}
                style={{ minWidth: "50rem" }}
            >
                <ModalHeader
                    className="modal-header bg-primary modal-title text-white cursor"
                    toggle={() => {
                        props.closeModal();
                    }}
                >
                    <i className="fas fa-arrows-alt" /> Reservation Details Update
                </ModalHeader>
                <ModalBody>{renderData()}</ModalBody>
                <ModalFooter>
                    <div className="col-lg-12 text-right">
                        <button
                            type="button"
                            name="btnAddNewMember"
                            className="btn btn-primary waves-effect waves-light btnSize ml-2"
                            onClick={() => onFormSubmit()}
                        >
                            Update
                        </button>
                        <button
                            type="button"
                            name="btnCancel"
                            className="btn btn-secondary waves-effect waves-light btnSize ml-2"
                            onClick={props.closeModal}
                        >
                            Cancel
                        </button>
                    </div>
                </ModalFooter>
            </Modal>
        </Draggable>
    );
};
export default ReservationDetailsUpdateModal;

import React, { Fragment } from "react";
import DateTimePicker from "react-widgets/lib/DateTimePicker";
import "react-widgets/dist/css/react-widgets.css";
import "react-picky/dist/picky.css";
import "../../../sass/components/_cbDropdown.scss";
import Moment from "moment";
import EyeIcon from "../EyeIcon/eyeIcon";
import MobileAutoSuggestionInput from "./MobileAutoSuggestionInput";
import { getDateTimeFormatString, getDateTimePlaceholder } from "../../common/common-helper";
import PhoneNumberUI from "./phone-number-ui";
import { DateInputFormats } from "../../../shared/utility/date-time-utility";
const CommonInputField = (props) => {
  let inputElement = null;
  delete props.elementConfig[""];
  switch (props.nodeElement) {
    case "input":
      inputElement = (
        <Fragment>
          <input
            {...props.elementConfig}
            onChange={(event) =>
              props.onChange
                ? props.onChange(event, props.elementConfig.name)
                : ""
            }
            onMouseOver={(event) =>
              props.onMouseOver ? props.onMouseOver(event) : ""
            }
            onKeyUp={(event) => (props.onKeyUp ? props.onKeyUp(event) : "")}
            onBlur={() => (props.onBlur ? props.onBlur() : "")}
            onMouseLeave={(event) =>
              props.onMouseLeave
                ? props.onMouseLeave(event, props.elementConfig.name)
                : ""
            }
          />
          {props.otherConfig && props.otherConfig.isEyeIconRequired ? (
            <EyeIcon
              customClass={props.otherConfig.eyeIconCustomClass}
              toggleIcon={props.toggleInputTypeForPasswordField}
            />
          ) : (
            ""
          )}
        </Fragment>
      );
      break;
    case "radio":
      inputElement = (
        <div
          className={`icheck-primary ${props.valueClasses || ""}`}
          style={props.customStyle || {}}
        >
          <input
            {...props.elementConfig}
            onChange={(event) =>
              props.onChange
                ? props.onChange(
                    event,
                    props.elementConfig.name,
                    props.otherConfig.elementType || props.elementConfig.type
                  )
                : ""
            }
          />
          <label htmlFor={props.elementConfig.id}>
            <span>{props.otherConfig ? props.otherConfig.caption : ""}</span>
          </label>
        </div>
      );
      break;
    case "checkbox":
      inputElement = (
        <div className={`icheck-primary ${props.valueClasses || ""}`}>
          <input
            {...props.elementConfig}
            onChange={(event) =>
              props.onChange
                ? props.onChange(
                    event,
                    props.elementConfig.name,
                    props.otherConfig.elementType || props.elementConfig.type
                  )
                : ""
            }
          />
          <label
            htmlFor={props.elementConfig.id}
            className={props.otherConfig.checkBoxCaptionClass || ""}
          >
            <span>
              {props.otherConfig
                ? props.otherConfig.isHideCheckBoxCaption
                  ? ""
                  : props.otherConfig.caption
                : ""}
            </span>
          </label>
        </div>
      );
      break;
    case "select":
      inputElement = (
        <select
          {...props.elementConfig}
          onChange={(event) =>
            props.onChange(
              event,
              props.elementConfig.name,
              props.otherConfig && props.otherConfig.elementType
                ? props.otherConfig.elementType
                : ""
            )
          }
        >
          {props.otherConfig.defaultDropdownText ? (
            <option value="" key="0">
              {props.otherConfig.defaultDropdownText}
            </option>
          ) : (
            ""
          )}
          {props.otherConfig.dropdownItem &&
            props.otherConfig.dropdownItem.map((element, index) => (
              <option key={element.key} value={element.key}>
                {element.displayValue}
              </option>
            ))}
        </select>
      );
      break;
    case "textarea":
      inputElement = (
        <textarea
          rows="20"
          {...props.elementConfig}
          onChange={(event) => props.onChange(event, props.elementConfig.name)}
        />
      );
      break;
    case "datetime":
      inputElement = (
        <DateTimePicker
          parse={DateInputFormats}
          {...props.elementConfig}
          value={
            props.otherConfig.value
              ? Moment(props.otherConfig.value, "MM:DD:YYYY:HH:mm").toDate()
              : null
          }
          onChange={(event) =>
            props.onChange(
              event,
              props.otherConfig.dateNameForTimeField ||
                props.elementConfig.name,
              props.otherConfig.elementType || "datetimepicker"
            )
          }
          dateFormat={props.otherConfig.dateFormat || ""}
          date={
            props.otherConfig.showDateTime
              ? true
              : props.otherConfig.dateFormat
              ? true
              : false
          }
          time={
            props.otherConfig.showDateTime
              ? true
              : props.otherConfig.dateFormat
              ? false
              : true
          }
          format={
            props.otherConfig.showDateTime
            ? getDateTimeFormatString(true)
            : props.otherConfig.dateFormat
            ? getDateTimeFormatString()
            : "h:mm A"
          }
          placeholder={
            props.otherConfig.showDateTime
            ? getDateTimeFormatString(true)
            : props.otherConfig.dateFormat
            ? getDateTimePlaceholder()
            : "h:mm A"
          }
          {...props.preventMaxMinDate}
        />
      );
      break;
    case "phonenumber":
      inputElement = (
        <div className={props.otherConfig.phoneNumberParentClasses || ""}>
          <MobileAutoSuggestionInput
            {...props.elementConfig}
            renderMobileAutoSueggestion={
              props.renderMobileAutoSueggestion
                ? props.renderMobileAutoSueggestion
                : ""
            }
          />
          {"-"}
          <input
            type="text"
            name={props.elementConfig.name}
            id={props.elementConfig.id}
            value={
              props.elementConfig.value && props.elementConfig.value.Number
                ? props.elementConfig.value.Number
                : ""
            }
            onChange={(event) =>
              props.onChangePhone(
                event,
                props.elementConfig.name,
                props.otherConfig.elementType || "phone"
              )
            }
          />
        </div>
      );
      break;
    case "phonenumberwithflag":
      inputElement = (
        <div className={props.otherConfig.phoneNumberParentClasses || ""}>
          <PhoneNumberUI
                id={props.elementConfig.id}
                controlName={props.elementConfig.name}
                caption = {props.otherConfig.caption}
                required={true}
                placeholderText={props.elementConfig.placeholder || "Enter "+props.otherConfig.caption}
                value={
                  props.elementConfig.value
                    ? props.elementConfig.value
                    : ""
                }
                handleOnChange={props.onChangePhone}   
                enableLongNumbers = {true}
                showCountryFlagWithCode = {true}   
                eventDetails = {props}    
                isEditMode = {true}      
                />
        </div>
      );
      break;
    default:
      inputElement = "";
  }

  return inputElement;
};
export default CommonInputField;

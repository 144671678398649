import * as actionTypes from "./actionTypes";
import { invokeServerAPI } from "../../services/httpService";

export const getRolestoMembersStart = () => {
  return {
    type: actionTypes.GET_MEMBER_ROLES_START,
  };
};

export const getRolestoMembersSuccess = (membersRolesData) => {
  return {
    type: actionTypes.GET_MEMBER_ROlES_SUCCESS,
    membersRolesData: membersRolesData,
  };
};

export const getRolestoMembersFail = (error) => {
  return {
    type: actionTypes.GET_MEMBER_ROLES_FAIL,
    error: error,
  };
};

export const getRolestoMembers = (req) => {
  return (dispatch) => {
    dispatch(getRolestoMembersStart());
    invokeServerAPI("getmemberroles", req)
      .then((response) => {
        dispatch(getRolestoMembersSuccess(response));
      })
      .catch((err) => {
        //dispatch(getRolestoMembersFail(err));
      });
  };
};

export const getPermissionstoRolesStart = () => {
  return {
    type: actionTypes.GET_PERMISSIONS_ROLES_START,
  };
};

export const getPermissionstoRolesSuccess = (permissionsRolesData) => {
  return {
    type: actionTypes.GET_PERMISSIONS_ROLES_SUCCESS,
    permissionsRolesData: permissionsRolesData,
  };
};

export const getPermissionstoRolesFail = (error) => {
  return {
    type: actionTypes.GET_PERMISSIONS_ROLES_FAIL,
    error: error,
  };
};

export const getPermissionstoRolesReset = () => ({
  type: actionTypes.GET_PERMISSIONS_ROLES_RESET,
});

export const getPermissionstoRoles = (req) => {
  return (dispatch) => {
    dispatch(getPermissionstoRolesStart());
    invokeServerAPI("getrolepermissions", req)
      .then((response) => {
        dispatch(getPermissionstoRolesSuccess(response));
      })
      .catch((err) => {
        //dispatch(getPermissionstoRolesFail(err));
      });
  };
};
export const updatePermissionstoRolesStart = () => {
  return {
    type: actionTypes.UPDATE_PERMISSIONS_ROLES_START,
  };
};

export const updatePermissionstoRolesSuccess = (response) => {
  return {
    type: actionTypes.UPDATE_PERMISSIONS_ROLES_SUCCESS,
    permissionsRolesUpdateResponse: response,
  };
};

export const updatePermissionstoRolesFail = (error) => {
  return {
    type: actionTypes.UPDATE_PERMISSIONS_ROLES_FAIL,
    error: error,
  };
};

export const updatePermissionstoRolesReset = (error) => {
  return {
    type: actionTypes.UPDATE_PERMISSIONS_ROLES_RESET,
    error: error,
  };
};

export const updatePermissionstoRoles = (req) => {
  return (dispatch) => {
    dispatch(updatePermissionstoRolesStart());
    invokeServerAPI("assignrolepermission", req)
      .then((response) => {
        dispatch(updatePermissionstoRolesSuccess(response));
      })
      .catch((err) => {
        //dispatch(updatePermissionstoRolesFail(err));
      });
  };
};

export const assignRolesToMemberStart = () => {
  return {
    type: actionTypes.ASSIGN_ROLES_TO_MEMBER_START,
  };
};
export const assignRolesToMemberSuccess = (response) => {
  return {
    type: actionTypes.ASSIGN_ROLES_TO_MEMBER_SUCCESS,
    assignRolesToMemberResponse: response,
  };
};
export const assignRolesToMemberFail = (error) => {
  return {
    type: actionTypes.ASSIGN_ROLES_TO_MEMBER_FAIL,
    error: error,
  };
};
export const assignRolesToMemberReset = () => {
  return {
    type: actionTypes.ASSIGN_ROLES_TO_MEMBER_RESET,
  };
};

export const assignRolesToMember = (req) => {
  return (dispatch) => {
    dispatch(assignRolesToMemberStart());
    invokeServerAPI("assignrole", req)
      .then((response) => {
        dispatch(assignRolesToMemberSuccess(response));
      })
      .catch((err) => {
        //dispatch(assignRolesToMemberFail(err));
      });
  };
};

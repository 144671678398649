import React, { Fragment, useState } from "react";
import ReactHtmlParser from "react-html-parser";
import { Link } from "react-router-dom";
import {
  getFormattedVehicleName,
  getSubString,
  getFormattedPhoneNumber,
  getNoteTooltipHtml,
  hasPermission,
} from "../../../shared/utility";
import TooltipItem from "../../../components/tooltip/TooltipItem";
import IconListForReservation from "../../SearchCommonData/iconListComponent";
import PermissionName from "../../../shared/Permissions";
import { EmployeeIcon, UserProfileLinkIcon } from "../../../shared/AppImages";
import { ModalType } from "../../../shared/GlobalVar";
import ModalEngine from "../../../components/modal/modal-engine";
import ModalInfo from "../../../services/entities/models/ModalInfo";
import { isP2POrHybridOrg } from "../../../shared/utility/system-utility";
import { createTooltip } from "../../../shared/utility/tooltip-utility";

export const SearchVehicleGrid = (props) => {
  const [modalInfo, setModalInfo] = useState([new ModalInfo()]);

  const closeModal = () => {
    setModalInfo(new ModalInfo());
  };
  const showModal = (modalData) => {
    setModalInfo({
      type: modalData.type,
      showModal: true,
      memberId: modalData.memberId,
      vehicleId: modalData.vehicleId,
      vehicleName: modalData.vehicleName,
    });
  };

  const getPhoneNumberText = (post, isForTooltip) => {
    return getFormattedPhoneNumber(
      post.hasOwnProperty("OwnerMobilePhone") ? post.OwnerMobilePhone : null,
      post.hasOwnProperty("OwnerWorkPhone") ? post.OwnerWorkPhone : null,
      post.hasOwnProperty("OwnerHomePhone") ? post.OwnerHomePhone : null,
      isForTooltip ? "tooltip" : "firstnumber"
    );
  };

  let hrefLink = "#";
  let editClass = "gray-color";
  let deleteClass = "gray-color";

  if (hasPermission("EDIT_VEHICLE")) {
    editClass = "blue-color cursor-pointer";
  }
  if (hasPermission("DELETE_VEHICLE")) {
    deleteClass = "red-color cursor-pointer";
  }

  return (
    <Fragment>
      <div className="datagrid">
        <div className="column">
          <span className="column__header" />
          {isP2POrHybridOrg() ? (
            <Fragment>
              <span className="column__header">Owner Name</span>
              <span className="column__header">Phone</span>
              <span className="column__header">Email</span>
            </Fragment>
          ) : (
            ""
          )}
          <span className="column__header">Vehicle ID</span>
          <span className="column__header">Vehicle Info</span>
          <span className="column__header">Vehicle Name</span>
          <span className="column__header">Type</span>
          <span className="column__header">Category</span>
          <span className="column__header">Status</span>
          <span className="column__header">Location</span>
          <span className="column__header" />
        </div>
        {props.dataList.map((post) => {
          const formattedVehicleName = getFormattedVehicleName(
            post.VehicleId,
            post.Make,
            post.Model,
            post.Year,
            false
          );
          return (
            <div className="row" key={post.VehicleId}>
              <span className="row__column img-container">
                <IconListForReservation resData={post} pageName="vehicle" />
              </span>
              {isP2POrHybridOrg() ? (
                <Fragment>
                  <span
                    className="row__column"
                    id={"ownerNameId_" + post.VehicleId}
                  >
                    {createTooltip(
                      <a
                        href={hrefLink}
                        className={`md-trigger ${
                          hasPermission(PermissionName.VIEW_MEMBER)
                            ? ""
                            : "noAnchorLink"
                        }`}
                        data-modal="modal-1"
                        onClick={(event) =>
                          hasPermission(PermissionName.VIEW_MEMBER)
                            ? showModal({
                                type: ModalType.MEMBER_QUICK_MODAL,
                                showModal: true,
                                memberId: post.OwnerId,
                              })
                            : ""
                        }
                      >
                        {post.OwnerName}
                      </a>,
                      post.OwnerName
                    )}
                  </span>

                  <span
                    className="row__column"
                    id={"phoneId_" + post.VehicleId}
                  >
                    {createTooltip(
                      getPhoneNumberText(post, false),
                      ReactHtmlParser(getPhoneNumberText(post, true)),
                      { forceShow: true }
                    )}
                  </span>

                  <span className="row__column">
                    {createTooltip(
                      <a
                        href={"mailto:" + post.OwnerEmail}
                        id={"email_" + post.VehicleId}
                      >
                        {post.OwnerEmail}
                      </a>,
                      post.OwnerEmail
                    )}
                  </span>
                </Fragment>
              ) : (
                ""
              )}

              <span className="row__column" id={"unitNoId_" + post.VehicleId}>
                {createTooltip(
                  <a
                    href={hrefLink}
                    className={`md-trigger ${
                      hasPermission(PermissionName.VIEW_VEHICLE)
                        ? ""
                        : "noAnchorLink"
                    }`}
                    data-modal="modal-1"
                    onClick={(event) =>
                      hasPermission(PermissionName.VIEW_VEHICLE)
                        ? showModal({
                            type: ModalType.VEHICLE_QUICK_MODAL,
                            showModal: true,
                            vehicleId: post.VehicleId,
                          })
                        : ""
                    }
                  >
                    {post.UnitNo}
                  </a>,
                  post.UnitNo
                )}
              </span>

              <span className="row__column d-flex">
                <span
                  id={"vehicleInfoId_" + post.VehicleId}
                  style={{ width: "95%" }}
                >
                  {createTooltip(formattedVehicleName, formattedVehicleName)}
                </span>
                {hasPermission(PermissionName.VIEW_VEHICLE)
                  ? createTooltip(
                      <Link
                        id={"icoEmployeeType_" + post.VehicleId}
                        to={{
                          pathname: "/vehicle_details/vehicle_profile",
                          search: "?vehicleId=" + post.VehicleId,
                          state: {
                            breadCrumbList: props.breadCrumbList,
                            vehicleId: post.VehicleId,
                            isEditMode: false,
                          },
                        }}
                      >
                        <img
                          alt=""
                          src={UserProfileLinkIcon}
                          style={{ height: "12px", width: "12px" }}
                        />
                      </Link>,
                      "Click for Vehicle Details",
                      { forceShow: true, isIcon: true }
                    )
                  : ""}
              </span>

              <span className="row__column" id={"vehicleName" + post.VehicleId}>
                {createTooltip(post.Name, post.Name)}
              </span>
              <span className="row__column">{post.AssetGroupType}</span>

              <span
                className="row__column"
                id={"categoryName_" + post.VehicleId}
              >
                {createTooltip(
                  post.Category ? getSubString(post.Category, 0, 4) : "--",
                  post.Category,
                  { forceShow: true }
                )}
              </span>
              <span className="row__column">
                <a
                  href={hrefLink}
                  onClick={(event) =>
                    showModal({
                      type: ModalType.VEHICLE_AVAILABILITY_STATUS_MODAL,
                      showModal: true,
                      vehicleId: post.VehicleId,
                      vehicleName: post.Name,
                    })
                  }
                >
                  {post.AvailabilityStatus}
                </a>
              </span>

              <span
                className="row__column"
                id={"VehicleLocationId_" + post.VehicleId}
              >
                {createTooltip(post.VehicleLocation, post.VehicleLocation)}
              </span>
              <span className="row__column vertical-align-center">
                {hasPermission(PermissionName.VIEW_VEHICLE)
                  ? createTooltip(
                      <Link
                        id={"editVehicle_" + post.VehicleId}
                        to={{
                          pathname: "/vehicle_details/vehicle_profile",
                          search: "?vehicleId=" + post.VehicleId,
                          state: {
                            breadCrumbList: props.breadCrumbList,
                            vehicleId: post.VehicleId,
                            isEditMode: true,
                          },
                        }}
                      >
                        <i
                          id={"editVehicle_" + post.VehicleId}
                          className={"fa fa-pencil-alt fs-3 " + editClass}
                          aria-hidden="true"
                        />
                      </Link>,
                      "Edit",
                      { forceShow: true }
                    )
                  : ""}
                {createTooltip(
                  <i
                    id={"deleteVehicle_" + post.VehicleId}
                    className={"fa fa-times pl-2 fs-3 " + deleteClass}
                    aria-hidden="true"
                    onClick={(event) =>
                      hasPermission(PermissionName.DELETE_VEHICLE)
                        ? showModal({
                            type: ModalType.DELETE_VEHICLE_MODAL,
                            showModal: true,
                            vehicleId: post.VehicleId,
                            vehicleName: post.Name,
                            pageReload: props.pageReload,
                          })
                        : ""
                    }
                  />,
                  "Delete",
                  { forceShow: true }
                )}
              </span>
            </div>
          );
        })}
      </div>
      {modalInfo.showModal ? (
        <ModalEngine
          modalType={modalInfo.type}
          showModal={modalInfo.showModal}
          closeModal={closeModal}
          vehicleId={modalInfo.vehicleId}
          memberId={modalInfo.memberId}
          vehicleName={modalInfo.vehicleName}
          resetParentPage={props.resetParentPage}
        />
      ) : null}
    </Fragment>
  );
};
export default SearchVehicleGrid;

import React from 'react'
import AttributeLabel from '../attribute-label'

function VehicleTags({ tags }) {
    return (
        <div className='vehicle-tag-modal'>
            {tags.map((tag) => {
                return <div className='attr-container' key={tag}>
                    <AttributeLabel title={tag} classNames="attr-lbl" />
                </div>
            })}
        </div>
    )
}

export default VehicleTags
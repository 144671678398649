import { FoxRequestHeader } from "../../fox-api/domain/fox-request-header";
import PageInfo from "../../models/PageInfo";

const SearchShuttleRequest = function(data) {
  data = data || {};
  this.requestHeader = new FoxRequestHeader({requestType:"GET"});
  this.shuttleCode = data.shuttleCode || null;
  this.vin = data.vin || null;
  this.make = data.make || null;
  this.model = data.model || null;
  this.year = data.year || null;
  this.engineStatuses = data.engineStatuses || [];
  this.locationCodes =data.locationCodes || [];
  this.availabilites =data.availabilites || [];
  this.pageInfo= data.pageInfo  ? {...data.pageInfo} : new PageInfo();
};
export default SearchShuttleRequest;

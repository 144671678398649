import React, { useEffect, useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import Draggable from "react-draggable";
import {
  getFleetClassGroupInfoReset,
  getFleetClassGroupInfo,
} from "../../../store/actions";
import { getErrorToastData, hasPermission } from "../../../shared/utility";
import { showNotification } from "../../../components/UI/Toaster/Toaster";
import { validateDefaultFleetForeCastEntity } from "../fleet-utilization-forecast-helper";
import { FoxRequestHeader } from "../../../services/entities/fox-api/domain/fox-request-header";
import OrgUnitFleetClassGroup from "../../../services/entities/models/reserve-sync/org-unit-fleet-class-group";
import { convertMsgResponse } from "../../Reservation/fox-reservation/fox-res-helper";
import PermissionName from "../../../shared/Permissions";

export const OverrideDefaultReserveSyncModal = (props) => {
  const { classGroup, updateDefaultForecastByClassGroup, showModal, closeModal } = props;
  const dispatch = useDispatch();
  const fleetClassGroupReponse = useSelector(
    (state) => state.fleetUtilizationForecastReducer.fleetClassGroupReponse
  );
  const [selectedOrgUnitFleetClassGroup, setSelectedOrgUnitFleetClassGroup] = useState(new OrgUnitFleetClassGroup());
  const hasDefaultThresholdEditPermission = hasPermission(PermissionName.CC_UPDATE_ORG_UNIT_FLEET_CLASS_GROUP)
  const hasDefaultPickupThresholdEditPermission = hasPermission(PermissionName.CC_UPDATE_DEFAULT_PICKUP_THRESHOLD)
  const hasDefaultBookThruThresholdEditPermission = hasPermission(PermissionName.CC_UPDATE_DEFAULT_BOOK_THRU_THRESHOLD)
  useEffect(() => {
    if (classGroup.key) {
      const requestObj = {
        orgUnitFleetClassGroupId: classGroup.key,
        requestHeader: new FoxRequestHeader({ requestType: "GET" })
      }
      dispatch(getFleetClassGroupInfoReset());
      dispatch(getFleetClassGroupInfo(requestObj));
    }
  }, []);

  useEffect(() => {
    if (!fleetClassGroupReponse) {
      return;
    }
    if (
      fleetClassGroupReponse.message &&
      fleetClassGroupReponse.message.messageType === "SUCCESS"
    ) {
      setSelectedOrgUnitFleetClassGroup(
        new OrgUnitFleetClassGroup(fleetClassGroupReponse.orgUnitFleetClassGroup)
      );
    } else {
      showNotification(convertMsgResponse(fleetClassGroupReponse.message));
    }
    dispatch(getFleetClassGroupInfoReset());
  }, [fleetClassGroupReponse]);


  const handleOnChange = (event, controlName, elementType) => {
    let value = null;
    value =
      elementType === "checkbox" ? event.target.checked : event.target.value;
    const tempRequest = { ...selectedOrgUnitFleetClassGroup };
    if (controlName === "status") {
      tempRequest[controlName] = value;
    } else {
      tempRequest[controlName] = value;
    }
    setSelectedOrgUnitFleetClassGroup(tempRequest);
  };
  const onUpdateDefaultClassGroupValues = () => {
    let msg = validateDefaultFleetForeCastEntity(selectedOrgUnitFleetClassGroup);
    if (msg) {
      showNotification(getErrorToastData(msg));
    } else {
      const reqObj = {
        orgUnitFleetClassGroup: {
          orgUnitFleetClassGroupId: classGroup.key,
          defaultPickupThresholdPercent: selectedOrgUnitFleetClassGroup.defaultPickupThresholdPercent ? parseFloat(selectedOrgUnitFleetClassGroup.defaultPickupThresholdPercent) : null,
          defaultBookThruThresholdPercent: selectedOrgUnitFleetClassGroup.defaultBookThruThresholdPercent ? parseFloat(selectedOrgUnitFleetClassGroup.defaultBookThruThresholdPercent) : null,
          status: selectedOrgUnitFleetClassGroup.status,
        }
      };
      updateDefaultForecastByClassGroup(reqObj);
    }
  };
  return (
    <Draggable handle=".cursor">
      <Modal
        isOpen={showModal}
        className="no-cursor fleet-override-modal"
        toggle={() => {
          closeModal();
        }}
      >
        <ModalHeader
          className="modal-header modal-title cursor"
          toggle={() => {
            closeModal();
          }}
        >
          Default Override
        </ModalHeader>
        <ModalBody>
          <div className="res-sync-input-container ">
            <label htmlFor="defaultPickupThresholdPercent">Default Pickup Threshold Value</label>
            <input name="defaultPickupThresholdPercent"
              type="number"
              placeholder="Enter Default Pickup Threshold Value"
              value={selectedOrgUnitFleetClassGroup.defaultPickupThresholdPercent}
              onChange={(event) => { handleOnChange(event, "defaultPickupThresholdPercent", "number") }}
              disabled={!hasDefaultPickupThresholdEditPermission}
              title={!hasDefaultPickupThresholdEditPermission && "You do not have permission to update default pickup threshold"}
            />

          </div>
          <div className="res-sync-input-container ">
            <label htmlFor="defaultBookThruThresholdPercent">Default Book Through Threshold Value</label>
            <input name="defaultBookThruThresholdPercent"
              type="number"
              placeholder="Enter Default Book Through Threshold Value"
              value={selectedOrgUnitFleetClassGroup.defaultBookThruThresholdPercent}
              onChange={(event) => { handleOnChange(event, "defaultBookThruThresholdPercent", "number") }}
              disabled={!hasDefaultBookThruThresholdEditPermission}
              title={!hasDefaultBookThruThresholdEditPermission && "You do not have permission to update default book thru threshold"}
            />
          </div>
          {/* <div className="res-sync-input-container ">
            <label htmlFor="status">Status</label>
            <select value={selectedOrgUnitFleetClassGroup.status} onChange={(event) => { handleOnChange(event, "status") }}>
              <option value="">Select Status</option>
              <option value="ACTIVE">Active</option>
              <option value="INACTIVE">Inactive</option>
              <option value="OBSOLETE">Obsolete</option>
            </select>
          </div> */}
        </ModalBody>
        <ModalFooter>
          <div className="col-sm-12 text-center">
            <button
              type="button"
              name="btnCancel"
              className="btn btn-fox-cancel waves-effect waves-light btnSize ml-2"
              onClick={() => closeModal()}
            >
              Not Now
            </button>
            <button
              type="button"
              name="btnYes"
              className={`btn btn-fox waves-effect waves-light btnSize ml-2 ${!hasDefaultThresholdEditPermission ? "disabled" : ""}`}
              onClick={() => hasDefaultThresholdEditPermission && onUpdateDefaultClassGroupValues()}
              title={!hasDefaultThresholdEditPermission && "You do not have permission to update orgunit fleet class group"}
            >
              Update
            </button>
          </div>
        </ModalFooter>
      </Modal>
    </Draggable>
  );
};
export default OverrideDefaultReserveSyncModal;

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  updateReservationCreationTypeReset,
  updateReservationCreationType,
} from "../../../store/actions/index";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Draggable from "react-draggable";
import {
  getBasicToastResponse,
  getErrorToastData,
  getFormattedValidationMsg,
  isNullOrEmpty,
  updateObject,
} from "../../../shared/utility";
import { showNotification } from "../../../components/UI/Toaster/Toaster";
import EyeIcon from "../../../components/UI/EyeIcon/eyeIcon";

export const UpdateReservationCreationTypeModal = (props) => {
  const defaultData = props.defaultData;
  const dispatch = useDispatch();
  const reservationCreationTypeResponse = useSelector(
    (state) => state.reservationReducer.reservationCreationTypeResponse
  );
  const [reservationCreationTypeReq, setReservationCreationTypeReq] = useState({
    ReservationId: defaultData.reservationId,
    ReservationCreationType: defaultData.reservationCreationType,
    Password: null,
  });
  const [passwordInputType, setPasswordInputType] = useState("password");
  const [isValidateDone, setIsValidateDone] = useState(false);
  useEffect(() => {
    if (!reservationCreationTypeResponse) {
      return;
    }
    showNotification(getBasicToastResponse(reservationCreationTypeResponse));
    if (reservationCreationTypeResponse.StatusCode === 0) {
      dispatch(updateReservationCreationTypeReset());
      props.resetParentPage();
      props.closeModal();
    }
    dispatch(updateReservationCreationTypeReset());
  }, [reservationCreationTypeResponse]);
  const handleOnChange = ({ target }) => {
    let updateCtrls = { ...reservationCreationTypeReq };
    const value =
      target.type === "checkbox"
        ? target.checked
          ? "TEST"
          : "REGULAR"
        : target.value;
    updateCtrls = updateObject(updateCtrls, {
      [target.name]: value,
    });
    setReservationCreationTypeReq(updateCtrls);
  };
  const onUpdateCreationType = () => {
    if (isNullOrEmpty(reservationCreationTypeReq.Password)) {
      showNotification(
        getErrorToastData(getFormattedValidationMsg("Password"))
      );
      setIsValidateDone(true);
      return false;
    } else {
      setIsValidateDone(false);
    }
    dispatch(updateReservationCreationTypeReset());
    dispatch(updateReservationCreationType(reservationCreationTypeReq));
  };

  return (
    <div>
      <Draggable handle=".cursor">
        <Modal
          isOpen={props.showModal}
          className="no-cursor"
          toggle={() => {
            props.closeModal();
          }}
          style={{ minWidth: "35rem" }}
        >
          <ModalHeader
            className="modal-header bg-primary modal-title text-white cursor"
            toggle={() => {
              props.closeModal();
            }}
          >
            <i className="fas fa-arrows-alt" /> Update Reservation Creation Type
          </ModalHeader>
          <ModalBody>
            <div className="col-sm-12">
              <div className="icheck-primary col-sm-12">
                <input
                  type="checkbox"
                  name="ReservationCreationType"
                  id="reservationCreationType"
                  checked={
                    reservationCreationTypeReq.ReservationCreationType ===
                    "TEST"
                      ? true
                      : false
                  }
                  onChange={(event) => handleOnChange(event)}
                />
                <label htmlFor="reservationCreationType">
                  <span className="font-weight-bold pl-2">
                    Mark as Test Reservation
                  </span>
                </label>
              </div>
              <div className="form-group formRow mt-3 required">
                <label className="col-sm-3 col-form-label font-weight-bold">
                  Password
                </label>
                <div
                  className={
                    "col-sm-9 position--relative " +
                    (reservationCreationTypeReq.Password
                      ? ""
                      : isValidateDone
                      ? "validation-failed"
                      : "")
                  }
                >
                  <input
                    type={passwordInputType || "password"}
                    value={reservationCreationTypeReq.Password || ""}
                    name="Password"
                    placeholder="password..."
                    className="text"
                    onChange={(event) => handleOnChange(event)}
                  />
                  <EyeIcon
                    toggleIcon={(inputType) => {
                      setPasswordInputType(inputType);
                    }}
                  />
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              name="btnYes"
              className="btn btn-primary waves-effect waves-light btnSize ml-2"
              onClick={() => onUpdateCreationType()}
            >
              Update
            </button>
            <button
              type="button"
              name="btnCancel"
              className="btn btn-secondary waves-effect waves-light btnSize ml-2"
              onClick={() => props.closeModal()}
            >
              Cancel
            </button>
          </ModalFooter>
        </Modal>
      </Draggable>
    </div>
  );
};
export default UpdateReservationCreationTypeModal;

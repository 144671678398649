import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAttachedDocListReset,
  getAttachedDocList,
  doManualLicenseApproveOrDisApproveReset,
  doManualLicenseApproveOrDisApprove,
} from "../../../store/actions/index";

import {
  getBasicToastResponse,
  getDateTimeString,
  loadDefaultImage,
} from "../../../shared/utility";
import { updateObject } from "../../../shared/utility";
import Pagination from "../../../components/pagination/Pagination";
import NoRecordFoundComponent from "../../../components/common/pages/no-record-found";
import { noImage } from "../../../shared/AppImages";
import { showNotification } from "../../../components/UI/Toaster/Toaster";
import { LicenseDocType } from "../../../shared/GlobalVar";
import FileUploader from "../../../components/image-uploader/file-uploader";
import FileUploaderModel from "../../../components/image-uploader/file-uploader-ui-model";
import LicenseApproveRequest from "../../../services/entities/requests/LicenseApproveRequest";
import Viewer from "react-viewer";
import PageInfo from "../../../services/entities/models/PageInfo";
import { ImageStorageType } from "../../../shared/GlobalVar";

export const AttachedDocBody = (props) => {
  const dispatch = useDispatch();
  const attachedDocListResponse = useSelector(
    (state) => state.documentAttachReducer.attachedDocListResponse
  );
  const licenseApprovalResponse = useSelector(
    (state) => state.licenseApprovalReducer.licenseApprovalResponse
  );
  const [pageInfo, setPageInfo] = useState(new PageInfo());
  const [licenseApproveRequest, setLicenseApproveRequest] = useState(
    new LicenseApproveRequest()
  );
  const [uploaderList, setUploaderList] = useState([]);
  const [tableImagesList, setTableImagesList] = useState([{ src: null }]);
  const [showImageViewer, setShowImageViewer] = useState(false);

  useEffect(() => {
    dispatch(getAttachedDocListReset());
    dispatch(doManualLicenseApproveOrDisApproveReset());
    loadUploaderData();
    getUplloadedDocList();
  }, []);
  useEffect(() => {
    getUplloadedDocList();
  }, [pageInfo]);
  useEffect(() => {
    if (!attachedDocListResponse) {
      return;
    }
    if (attachedDocListResponse.StatusCode === 0) {
      loadImageViewerData();
    } else {
      showNotification(getBasicToastResponse(attachedDocListResponse));
      dispatch(getAttachedDocListReset());
    }
  }, [attachedDocListResponse]);
  useEffect(() => {
    if (!licenseApprovalResponse) {
      return;
    }
    showNotification(getBasicToastResponse(licenseApprovalResponse));
    if (licenseApprovalResponse.StatusCode === 0) {
      dispatch(getAttachedDocListReset());
      dispatch(doManualLicenseApproveOrDisApproveReset());
      props.resetParentPage();
      props.closeModal();
    }
    dispatch(getAttachedDocListReset());
    dispatch(doManualLicenseApproveOrDisApproveReset());
  }, [licenseApprovalResponse]);
  const loadUploaderData = () => {
    const uploaderObj = new FileUploaderModel();
    uploaderObj.fileName = "Document1";
    uploaderObj.parentDivClass = "col-sm-6";
    uploaderObj.photoCloseStyle = {
      right: "5.5rem",
      fontSize: "2.5rem",
    };
    uploaderObj.imageStyle = {
      cursor: "pointer",
      border: "1px solid #ddd",
      width: "15.5rem",
      height: "10.5rem",
      borderRadius: ".4rem",
      padding: ".5rem",
    };
    uploaderObj.uploadDivStyle = {
      cursor: "pointer",
      top: "1.7rem",
      bottom: "0",
      width: "15.5rem",
      height: "10.5rem",
    };
    const uploaderList = [];
    uploaderList.push(uploaderObj);
    const uploaderObj2 = { ...uploaderObj };
    uploaderObj2.fileName = "Document2";
    uploaderList.push(uploaderObj2);
    setUploaderList(uploaderList);
  };
  const loadImageViewerData = () => {
    if (attachedDocListResponse.TotalRecords === 0) {
      return;
    }
    const imgList = [];
    attachedDocListResponse.UploadedDocs.map((attachedDocObj) => {
      if (attachedDocObj.ImageUrl) {
        imgList.push({ src: attachedDocObj.ImageUrl, alt: "" });
      }
    });
    setTableImagesList(imgList.length > 0 ? [...imgList] : [{ src: null }]);
  };
  const getImageViewer = () => {
    setShowImageViewer(true);
  };
  const closeImageViewer = () => {
    setShowImageViewer(false);
  };
  const getUplloadedDocList = () => {
    const attachedDocListReq = {
      MemberId: props.memberId,
      DocType: null,
      PageInfo: pageInfo,
    };
    //dispatch(getAttachedDocListReset());
    dispatch(getAttachedDocList(attachedDocListReq));
  };
  const onPageChanged = (currentPage, recordsPerPage) => {
    if (currentPage && recordsPerPage) {
      setPageInfo(new PageInfo(currentPage, recordsPerPage));
    }
  };
  const renderDocInfoList = () => {
    if (attachedDocListResponse && attachedDocListResponse.UploadedDocs) {
      return attachedDocListResponse.UploadedDocs.map((attachedDocObj) => {
        return (
          <tr key={attachedDocObj.UploadedDocId}>
            <td>
              <img
                src={attachedDocObj.ImageUrl || noImage}
                onError={(event) => loadDefaultImage(event, noImage)}
                alt="Damage-img"
                width="50"
                height="30"
                onClick={() =>
                  attachedDocObj.ImageUrl ? getImageViewer() : ""
                }
                style={{ cursor: attachedDocObj.ImageUrl ? "pointer" : "" }}
              />
            </td>
            <td>{attachedDocObj.UserName || "--"}</td>
            <td>{getDateTimeString(attachedDocObj.CreatedOn)}</td>
            <td>{attachedDocObj.ViolationPoints || "--"}</td>
            <td>{attachedDocObj.Message || "--"}</td>
          </tr>
        );
      });
    }
  };
  const loadUploaderFileData = (index, base64Data, extension) => {
    let updatedControls = { ...licenseApproveRequest };
    if (index === 0) {
      updatedControls = updateObject(updatedControls, {
        Attachment1: base64Data,
        Attachment1Ext: extension,
        Attachment1Category: ImageStorageType.MISC_IMG,
      });
    } else {
      updatedControls = updateObject(updatedControls, {
        Attachment2: base64Data,
        Attachment2Ext: extension,
        Attachment2Category: ImageStorageType.MISC_IMG,
      });
    }
    setLicenseApproveRequest(updatedControls);
  };
  const inputChangedHandler = (event) => {
    let updatedControls = { ...licenseApproveRequest };
    updatedControls = updateObject(updatedControls, {
      [event.target.name]: event.target.value,
    });
    setLicenseApproveRequest(updatedControls);
  };
  const doManualLicenseApprove = () => {
    let licenseApprovalReq = { ...licenseApproveRequest };
    licenseApprovalReq.MemberId = props.memberId;
    if (props.licenseDocType === LicenseDocType.MANUAL_APPROVE) {
      licenseApprovalReq.Approved = true;
    } else {
      licenseApprovalReq.Approved = false;
    }
    dispatch(
      doManualLicenseApproveOrDisApprove(
        licenseApprovalReq,
        props.licenseDocType
      )
    );
  };

  return (
    <div className="row mt-2">
      <div className="col-lg-6 pl-0">
        <div className="form-group">
          <label
            htmlFor="input-1"
            className="col-sm-4 col-form-label font-weight-bold"
          >
            Violation Point
          </label>
          <div className="col-sm-12">
            <input
              type="text"
              className="form-control"
              name="ViolationPoints"
              onChange={(event) => inputChangedHandler(event)}
            ></input>
          </div>
        </div>
        <div className="form-group mt-2">
          <label
            htmlFor="input-1"
            className="col-sm-4 col-form-label font-weight-bold"
          >
            Comments
          </label>
          <div className="col-sm-12">
            <textarea
              type="textarea"
              className="form-control"
              name="Message"
              onChange={(event) => inputChangedHandler(event)}
            />
          </div>
        </div>
      </div>
      <div className="col-lg-6 row">
        <FileUploader
          uploaderData={uploaderList}
          isNameRequird={true}
          isOnlyImg={true}
          changeFileTitle="Change Image"
          loadUploaderFileData={loadUploaderFileData}
        />
      </div>
      <div className="col-lg-12 text-right mt-3">
        <button
          type="button"
          className="btn btn-primary waves-effect waves-light btnSize ml-2"
          onClick={() => doManualLicenseApprove()}
        >
          Submit
        </button>
        <button
          type="button"
          className="btn btn-secondary  waves-effect waves-light btnSize ml-2"
          onClick={() => props.closeModal()}
        >
          Cancel
        </button>
      </div>
      <div className="col-lg-12 ml-0 mb-2 mt-4">
        {attachedDocListResponse && attachedDocListResponse.TotalRecords > 0 ? (
          <Fragment>
            <Pagination
              totalRecords={attachedDocListResponse.TotalRecords}
              recordsPerPage={pageInfo.PerPageCount}
              currentPage={pageInfo.PageNumber}
              onPageChanged={onPageChanged}
              customClass="col-lg-12 ml-0 mr-0 pl-0 pr-0"
            />
            <table className="table table-striped table-hover image-table">
              <thead className="thead-primary">
                <tr>
                  <th style={{ width: "10%" }}>Doc Image</th>
                  <th style={{ width: "20%" }}>Uploaded By</th>
                  <th style={{ width: "15%" }}>Uploaded Date</th>
                  <th style={{ width: "10%" }}>Violation Point</th>
                  <th style={{ width: "40%" }}>Message</th>
                </tr>
              </thead>
              <tbody>{renderDocInfoList()}</tbody>
            </table>
            {tableImagesList.length > 0 ? (
              <Viewer
                zIndex={1055}
                visible={showImageViewer}
                onClose={() => {
                  closeImageViewer();
                }}
                images={tableImagesList}
              />
            ) : (
              ""
            )}
          </Fragment>
        ) : (
          <NoRecordFoundComponent heights="25rem" />
        )}
      </div>
    </div>
  );
};
export default AttachedDocBody;

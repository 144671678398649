import React from 'react'
import AccordionWrapper from '../../../components/wrapper/accordion-wrapper'
import { LOC_ICON } from '../../../shared/AppImages'
import ResItemCard from '../component/res-item-card'
import VehicleInfoContainer from '../fox-reservation/vehicle/component/vehicle-info-container'
import ClassicPricingTable from '../pricing/classic-pricing-table'
import LocationCard from '../res-profile/location-card'
import ResMemProfile from '../res-profile/res-mem-profile'

function ResQuickSummary({ primaryData, vehicleClass, customerInfo, pricing }) {
    return (
        <>
            <AccordionWrapper title="Reservation Details" isExpand={false} containerClass="accordion-common-container">
                <div className="common-res-container mt-3">
                    <VehicleInfoContainer vehicleClass={vehicleClass} />
                </div>
                <div className="common-res-container mt-3">
                    <LocationCard
                        title="Pick-up"
                        locName={primaryData?.rentalLocationName}
                        date={primaryData?.rentalDateTime}
                        locIcon="fa fa-circle"
                    />
                    <span className="vertical-line"></span>
                    <LocationCard
                        title="Return"
                        locName={primaryData?.returnLocationName}
                        date={primaryData?.returnDateTime}
                        imgIcon={LOC_ICON}
                    />
                </div>
                <div className="common-res-container mt-3">
                    <ResItemCard
                        name="Discount Code"
                        value={primaryData?.discoundCode || "--"}
                    />
                </div>
            </AccordionWrapper>
            <AccordionWrapper title="Customer Information" isExpand={false} containerClass="accordion-common-container">
                <ResMemProfile customerInfo={customerInfo} />
            </AccordionWrapper>
            <AccordionWrapper title="Pricing Details" isExpand={true} containerClass="accordion-common-container">
                <ClassicPricingTable
                    currentPayType={primaryData?.currentPayType}
                    pricingData={pricing}
                />
            </AccordionWrapper>
        </>
    )
}

export default ResQuickSummary
import React from "react";
import { Prompt } from "react-router";
import { isNullOrEmpty } from "../../../shared/utility";

const PreventTransitionPrompt = (props) => {
  const { when, message } = props;
  return (
    <Prompt
      when={when}
      message={(location, action) => {
        return action === "PUSH" ||
          (isNullOrEmpty(location.search) && action === "REPLACE")
          ? message ||
              `You have unsaved changes. Do you want to leave this page without saving the changes?`
          : true;
      }}
    />
  );
};

export default PreventTransitionPrompt;

import React from 'react';
import { formatAmountValue } from '../../../../shared/utility';
import { UnitDisplayText } from '../../../../shared/utility/enum-utility';
import CommonTitle from '../../component/common-title';

function AddonsBasicInfo(props) {
    const { addonData } = props;
    const addonRate = addonData.recurrenceUnit === "PERCENT"
        ? parseFloat(addonData.rate).toFixed(2) + " Percent"
        : formatAmountValue(addonData.rate) +
        "/" +
        UnitDisplayText[addonData.recurrenceUnit];
    const discountRate = addonData?.isDiscounted ? addonData.recurrenceUnit === "PERCENT"
        ? parseFloat(addonData.newRate).toFixed(2) + " Percent"
        : formatAmountValue(addonData.newRate) +
        "/" +
        UnitDisplayText[addonData.recurrenceUnit] : "";

    return (
        <div className='flex-space-between'>
            <CommonTitle title={addonData?.desc} classNames="common-title" />
            <div className='flex-column'>
                {addonData?.isDiscounted ? <CommonTitle
                    title={discountRate} classNames="common-title" /> : ""}
                <CommonTitle
                    title={addonRate} classNames="common-title" isStrike={addonData?.isDiscounted} />
            </div>
        </div>
    )
}

export default AddonsBasicInfo
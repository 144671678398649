const OverrideOptions = function () {
  this.TermsAndConditionsCheck = false;
  this.OverrideEmail = false;
  this.OverrideOwner = false;
  this.OverrideLicenseAndState = false;
  this.OverrideDMVCheck = false;
  this.OverrideVehicle = false;
  this.OverridePayment = false;
  this.OverrideMinimumAge = false;
  this.OverrideHoursOfOperation = false;
  this.OverrideCreditCardInformation = true;
  this.OverrideNameValidation = true;
};
export default OverrideOptions;

import { getProblemCategories } from "../../vehicle/vehicle-helper";

export const getReportProblemCtrls = () => {
  return {
    Status: {
      value: "",
    },
    ProblemType: {
      id: "ProblemType",
      elementType: "select",
      elementConfig: {
        name: "ProblemType",
        options: getProblemCategories(),
      },
      labelInput: {
        labeltext: "Problem",
      },
      value: "",
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      basicOrder: 1,
      advancedOrder: 1,
    },

    ProblemSubType: {
      id: "ProblemSubType",
      elementType: "select",
      elementConfig: {
        name: "ProblemSubType",
        options: [{ key: "", displayValue: "Select" }],
        disabled: true,
      },
      labelInput: {
        labeltext: "Problem SubType",
      },
      value: "",
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      basicOrder: 2,
      advancedOrder: 2,
    },

    MDate: {
      id: "MDate",
      elementType: "datetimepicker",
      elementConfig: {
        name: "MDate",
        type: "text",
      },
      validation: {
        required: false,
      },
      labelInput: {
        labeltext: "Date",
      },
      value: "",
      valid: false,
      touched: false,
      basicOrder: 3,
      advancedOrder: 3,
    },
    Priority: {
      id: "Priority",
      elementType: "select",
      elementConfig: {
        name: "Priority",
        options: [
          { key: "0", displayValue: "Select" },
          { key: "1", displayValue: "High" },
          { key: "2", displayValue: "Medium" },
          { key: "3", displayValue: "Low" },
        ],
      },
      labelInput: {
        labeltext: "Problem SubType",
      },
      value: "",
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      basicOrder: 4,
      advancedOrder: 4,
    },

    btnResSearch: {
      id: "btnResSearch",
      elementType: "button",
      elementConfig: {
        type: "submit",
        value: "Search",
        name: "btnResSearch",
        className: "btn btn-primary search-btn",
      },
      basicOrder: 5,
      advancedOrder: 5,
    },
  };
};

export const getReportProblemFilters = () => {
  return {
    FilterInfo: [
      {
        FilterStates: {
          OPEN: {
            id: "filterOpen",
            elementType: "filter",
            color: "#DDCC11",
            elementConfig: {
              name: "Open",
              type: "checkbox",
              value: "OPEN",
              checked: true,
            },
            labelInput: {
              labeltext: "Open",
              className: "filterlbl_Open",
            },
          },
          CLOSE: {
            id: "filterClosed",
            color: "#FFCCDD",
            elementType: "filter",
            elementConfig: {
              name: "Close",
              type: "checkbox",
              value: "CLOSE",
              checked: false,
            },
            labelInput: {
              labeltext: "Close",
              className: "filterlbl_Close",
            },
          },
        },
        Name: "Report Problem",
      },
    ],
  };
};

import React from 'react';
import { formatCurrency } from '../../../../shared/utility';

function GeneralRateRow({ title, total, contClasses = "", titleClasses = "", chargeClasses = "font-bold", isNegative = false, onClick = null }) {
    const formattedTotal = formatCurrency(total);
    return (
        <div className={`generel-rate-row ${contClasses}`}>
            <div className={`col-lg-8 text-lbl ${titleClasses}`}>
                {title}
                {onClick ? <i className="fa fa-info-circle ml-2" aria-hidden="true" onClick={onClick} /> : ""}
            </div>
            <div className={`col-lg-4 text-right text-rate ${chargeClasses}`}>
                {isNegative ? "(" + formattedTotal + ")" : formattedTotal || "--"}
            </div>
        </div>
    )
}

export default GeneralRateRow
import "../dialog/dialog.scss";
import DraggableModal from "./draggable-modal";
import ModernModal from "./modern-modal";


function DialogWrapper(props) {
    return (
        props.isDraggable ? <DraggableModal {...props} />
            : <ModernModal {...props} />
    )
}

export default DialogWrapper
import React from 'react';
import InputCtrl from '../../../components/UI/Input/input-ctrl';
import InputWidgetCtrl from '../../../components/UI/Input/input-widget-ctrl';
import TextAreaCtrl from '../../../components/UI/Input/text-area-ctrl';

function AddDiscountContainer(props) {
    const { formValue,
        discountReasonData,
        discountCodeList,
        disabled,
        widgetLoader,
        onChange,
        onClear,
        getReservationData,
    } = props;
    const getGrantedSubReasons = () => {
        if (formValue?.grantedReason && discountReasonData?.discountSubReasons?.length) {
            return discountReasonData.discountSubReasons?.filter((data) => {
                return data?.value2 === formValue?.grantedReason.key;
            });
        }
        return [];
    }

    return (
        <>
            <InputCtrl
                name="reservationCode"
                value={formValue?.reservationCode}
                type="text"
                placeholder="Reservation Code#"
                onChange={onChange}
                onBlur={getReservationData}
                iconClass={`fas fa-info-circle ${disabled ? "font-color-disabled" : "font-color-1"} font-size-2`}
                disabled={disabled}
            />
            <InputWidgetCtrl
                name="foxDiscountCode"
                placeholder="Discount Code"
                data={discountCodeList}
                valueField="key"
                textField="value"
                value={formValue?.foxDiscountCode}
                onChange={onChange}
                onClear={onClear}
                iconClass={`fas fa-award ${disabled ? "font-color-disabled" : "font-color-1"} font-size-2`}
                loader={widgetLoader}
                disabled={disabled && true}
                // defaultConfig={{
                //     groupBy: getTestHostNames().includes(hostName) ? "type" : null,

                // }}
                valueComponent={({ item }) => (
                    <span>{item.key}</span>
                )}
                itemComponent={({ item }) => (
                    <>
                        <span>{!item.key ? item?.value : item?.key}</span>
                        {/* {item?.key ? <hr /> : <br />} */}
                    </>
                )}

            />
            <InputWidgetCtrl
                name="grantedReason"
                placeholder="Reason"
                loader={widgetLoader}
                data={discountReasonData?.discountReasons}
                valueField="key"
                textField="value"
                value={formValue?.grantedReason}
                onChange={onChange}
                onClear={onClear}
                iconClass={`fas fa-info-circle ${disabled ? "font-color-disabled" : "font-color-1"} font-size-2`}
                disabled={disabled && true}
            />
            <InputWidgetCtrl
                name="grantedSubReason"
                placeholder="Select Sub Reason"
                data={getGrantedSubReasons()}
                valueField="key"
                textField="value"
                value={formValue?.grantedSubReason}
                onChange={onChange}
                onClear={onClear}
                disabled={!getGrantedSubReasons().length || disabled}
                iconClass={`fas fa-info-circle ${disabled ? "font-color-disabled" : "font-color-1"} font-size-2`}
            />
            <TextAreaCtrl
                name="grantedNotes"
                value={formValue?.grantedNotes || ""}
                onChange={onChange}
                placeholder="Note(s)"
                iconClass={`fa fa-comments ${disabled ? "font-color-disabled" : "font-color-1"} font-size-2`}
                disabled={disabled}
            />
        </>
    )
}

export default AddDiscountContainer
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Spinner from '../../../components/UI/Spinner/Spinner';
import { showNotification } from '../../../components/UI/Toaster/Toaster';
import { getGrantDiscountReasons } from '../../../services/httpService';
import { TOAST_MSG } from '../../../shared/GlobalVar';
import { getCreditDiscountCodes, getPersonwiseAllDiscount, getReservationDetails, grantDiscount, revertDiscount } from '../../../shared/json/data';
import PermissionName from '../../../shared/Permissions';
import { hasPermission } from '../../../shared/utility';
import AddRevertDiscountForm from './add-revert-discount-form';
import DiscountDetails from './discount-details';
import DiscountHistory from './discount-history';

function GrantDiscount(props) {
    const formType = {
        ADD_DISCOUNT: "ADD_DISCOUNT",
        REVERT_DISCOUNT: "REVERT_DISCOUNT"
    }
    const dependantOrgList = useSelector((state) =>
        state.auth.loggedInUserData.DependantLocations.length > 0
            ? state.auth.loggedInUserData.DependantLocations
            : []
    );
    const hasGrantAccess = hasPermission(PermissionName.CC_GRANT_PERSON_FOX_DISCOUNT);
    const hasRevetAccess = hasPermission(PermissionName.CC_REVERT_PERSON_FOX_DISCOUNT);

    const { customerId, customerName } = props;
    const [currentFormType, setCurrentFormType] = useState(hasGrantAccess ? formType.ADD_DISCOUNT : null);
    const [formValue, setFormValue] = useState(null);
    const [widgetLoader, setWidgetLoader] = useState(false);
    const [historyLoader, setHistoryLoader] = useState(false);
    const [loader, setLoader] = useState(false);
    const [discountCodeList, setDiscountCodeList] = useState([]);
    const [discountReasonData, setDiscountReasonData] = useState([]);
    const [discountHistory, setDiscountHistory] = useState([]);
    const [resevationData, setReservationData] = useState(null);
    const [selectedDiscountData, setSelectedDiscountData] = useState(null);
    //const hostName = window?.location?.hostname;
    useEffect(() => {
        async function getDiscountList() {
            setWidgetLoader(true);
            const creditDiscounts = await getCreditDiscountCodes();
            const discountReasons = await getGrantDiscountReasons();
            const allDiscounts = await getPersonwiseAllDiscount(customerId);
            setWidgetLoader(false);
            setDiscountReasonData(discountReasons);
            setDiscountHistory(allDiscounts);
            setDiscountCodeList(creditDiscounts);
        }
        getDiscountList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const getReservationData = async () => {
        if (!formValue?.reservationCode) return false;
        setLoader(true)
        const resData = await getReservationDetails({ reservationCode: formValue?.reservationCode, customerInfo: { customerId } });
        setLoader(false);
        if (resData?.customerInfo?.customerId !== customerId) {
            var toastMsg = {
                msg: "Entered reservation code is wrong for " + customerName,
                toastType: TOAST_MSG.WARNING,
            };
            showNotification(toastMsg);

        } else {
            setReservationData(resData);
        }
    }
    const refreshDiscountHistory = async () => {
        setHistoryLoader(true);
        const allDiscounts = await getPersonwiseAllDiscount(customerId);
        setDiscountHistory(allDiscounts);
        setHistoryLoader(false);
    }
    const onAddReverseDiscountCode = async () => {
        if (currentFormType === formType.ADD_DISCOUNT) {
            const req = {
                personId: customerId,
                foxDiscountCode: formValue?.foxDiscountCode?.key,
                grantedReason: formValue?.grantedReason?.key,
                grantedSubReason: formValue?.grantedSubReason?.key,
                grantedNotes: formValue?.grantedNotes,
                reservationCode: formValue?.reservationCode,
                reservationPickupLocationId: resevationData?.rentalLocation.locationId,
                reservationReturnLocationId: resevationData?.returnLocation.locationId,
                reservationStartDate: resevationData?.rentalDateTime,
                reservationEndDate: resevationData?.returnDateTime,
            }
            setLoader(true);
            const isSUccess = await grantDiscount(req);
            setLoader(false);
            if (isSUccess) {
                setFormValue(null);
                setSelectedDiscountData(null);
                setReservationData(null);
                refreshDiscountHistory();
            }
        } else if (currentFormType === formType.REVERT_DISCOUNT) {
            setLoader(true);
            const isSUccess = await revertDiscount(selectedDiscountData?.personFoxDiscountId, formValue?.revertedReason?.key, formValue?.revertedNotes);
            setLoader(false);
            if (isSUccess) {
                setFormValue(null);
                setCurrentFormType(formType.ADD_DISCOUNT);
                setSelectedDiscountData(null);
                setReservationData(null);
                refreshDiscountHistory();
            }
        }
    }
    const getDisplayValueForReason = (reasonType, reasonKey) => {
        const data = discountReasonData[reasonType]?.find((data) => { return data?.key === reasonKey });
        return data?.value;
    }

    return (
        <div className='flex-column row-gap-1 '>
            <DiscountHistory
                discountHistory={discountHistory}
                customerName={customerName}
                formType={formType}
                selectedDiscountId={selectedDiscountData?.personFoxDiscountId}
                setSelectedDiscountData={setSelectedDiscountData}
                getDisplayValueForReason={getDisplayValueForReason}
                setFormValue={setFormValue}
                currentFormType={currentFormType}
                setCurrentFormType={setCurrentFormType}
                showLoader={widgetLoader || historyLoader}
                hasRevetAccess={hasRevetAccess}
            />
            <div className='flex-space-around'>
                <DiscountDetails
                    getDisplayValueForReason={getDisplayValueForReason}
                    selectedDiscountData={selectedDiscountData}
                    resevationData={resevationData}
                    dependantOrgList={dependantOrgList}
                    customerName={customerName}
                    loader={loader}
                />
                <div className="divider"></div>
                <AddRevertDiscountForm
                    formType={formType}
                    formValue={formValue}
                    setFormValue={setFormValue}
                    currentFormType={currentFormType}
                    setCurrentFormType={setCurrentFormType}
                    discountReasonData={discountReasonData}
                    discountCodeList={discountCodeList}
                    selectedDiscountData={selectedDiscountData}
                    setSelectedDiscountData={setSelectedDiscountData}
                    setReservationData={setReservationData}
                    widgetLoader={widgetLoader}
                    getReservationData={getReservationData}
                    onAddReverseDiscountCode={onAddReverseDiscountCode}
                    discountId={selectedDiscountData?.personFoxDiscountId}
                    status={selectedDiscountData?.status}
                    hasGrantAccess={hasGrantAccess}
                    hasRevetAccess={hasRevetAccess}
                />
            </div>
            {loader ? <Spinner /> : ""}
        </div>
    )
}

export default GrantDiscount
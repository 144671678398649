import { getDateTimeFormatString } from "../../../../components/common/common-helper";
import {
  convertFoxApiDateToStr,
  createFoxResVehicleUIModel,
} from "../../../../containers/Reservation/fox-reservation/fox-res-helper";
import { AttendanceStatus } from "../../../../shared/GlobalVar";
import PermissionName from "../../../../shared/Permissions";
import {
  convertDateTimeString,
  getFullName,
  hasObjectNonEmptyProperty,
  hasPermission,
} from "../../../../shared/utility";

export const FoxResSummaryViewModel = function (data) {
  if (!data) data = {};
  this.isRental =
    Object.keys(data).length !== 0
      ? data.attendanceStatus !== AttendanceStatus.NOW_SHOW
        ? true
        : false
      : false;
  this.reservationId = data.reservationId || null;
  this.reservationCode = data.reservationCode || null;
  this.confirmationCode = data.confirmationCode || null;
  this.externalReservationCode = data.externalReservationCode || null;
  this.integResvConfNum = data.integResvConfNum || null;
  this.integConfirmNumber = data.integConfirmNumber || null;
  this.raCode =
    data.integResvConfNum && data.integConfirmNumber
      ? data.integResvConfNum.trim() !== data.integConfirmNumber.trim()
        ? data.integConfirmNumber
        : ""
      : "";
  this.xpress = data.xpress || null;
  this.xpressEnabled = data.xpressEnabled || false;
  this.productCode = data.productCode || null;

  this.customerId = data.customerInfo ? data.customerInfo.customerId : null;
  this.foxCredit = data.customerInfo ? data.customerInfo.foxCredit : null;
  this.fullName = data.customerInfo
    ? getFullName(
        data.customerInfo.firstName,
        data.customerInfo.lastName,
        data.customerInfo.middleName
      )
    : null;
  this.customerInfo = {
    customerId: data.customerInfo ? data.customerInfo.customerId : null,
    email:
      data.customerInfo && data.customerInfo.email
        ? data.customerInfo.email
        : null,
    firstName:
      data.customerInfo && data.customerInfo.firstName
        ? data.customerInfo.firstName
        : null,
    lastName:
      data.customerInfo && data.customerInfo.lastName
        ? data.customerInfo.lastName
        : null,
  };
  this.paymentFailureCount = data.paymentFailureCount || null;
  this.attendanceStatus = data.attendanceStatus || null;
  this.reservationState = data.reservationState || null;
  this.lateReservation = data.lateReservation || false;
  this.reservationStatus = data.reservationStatus || null;
  this.categoryId = data.vehicleClass ? data.vehicleClass.categoryId : null;
  this.categoryName = data.categoryName || null;
  this.assetGroupType = data.assetGroupType || null;
  this.ratesApplied =
    data.pricing && data.pricing.ratesApplied ? data.pricing.ratesApplied : [];

  this.ratePlan = data.pricing ? data.pricing.ratePlan : {};
  this.rentalDateTime = data.rentalDateTime
    ? convertFoxApiDateToStr(
        data.rentalDateTime,
        getDateTimeFormatString(true, false, true),
        "--"
      )
    : null;
  this.returnDateTime = data.returnDateTime
    ? convertFoxApiDateToStr(
        data.returnDateTime,
        getDateTimeFormatString(true, false, true),
        "--"
      )
    : null;
  this.creationDateTime = data.creationDateTime
    ? convertFoxApiDateToStr(
        data.creationDateTime,
        getDateTimeFormatString(true, false, true),
        "--"
      )
    : null;
  this.creationDateTimeDisplayText = data.creationDateTimeDisplayText || "";
  this.createdBy = data.createdBy || null;
  this.completedOn = data.completedOn
    ? data.completedOn.split("-").length > 2
      ? convertFoxApiDateToStr(
          data.completedOn,
          getDateTimeFormatString(true, false, true),
          "--"
        )
      : convertDateTimeString(data.completedOn, "--")
    : null;
  this.expectedEndDate = data.expectedEndDate
    ? data.expectedEndDate.split("-").length > 2
      ? convertFoxApiDateToStr(
          data.expectedEndDate,
          getDateTimeFormatString(true, false, true),
          "--"
        )
      : convertDateTimeString(data.expectedEndDate, "--")
    : null;
  this.actualStartDate = data.actualStartDate
    ? data.actualStartDate.split("-").length > 2
      ? convertFoxApiDateToStr(
          data.actualStartDate,
          getDateTimeFormatString(true, false, true),
          "--"
        )
      : convertDateTimeString(data.actualStartDate, "--")
    : null;
  this.reservationCreationType = data.reservationCreationType || null;
  this.enabledReservationCreationType = hasPermission(
    PermissionName.UPDATE_RESERVATION_CREATION_TYPE
  );
  this.integStatus = data.integStatus || null;
  this.enabledIntegStatus = hasPermission(
    PermissionName.VIEW_INTEGRATION_STATUS
  );

  this.vehicleInfo =
    hasObjectNonEmptyProperty(data) && createFoxResVehicleUIModel(data);
  this.rentalLocationId = data.rentalLocation
    ? data.rentalLocation.locationId
    : null;
  this.rentalLocationName = data.rentalLocation
    ? data.rentalLocation.name
    : null;
  this.rentalLocationType = data.rentalLocation
    ? data.rentalLocation.locationType
    : null;
  this.returnLocationId = data.returnLocation
    ? data.returnLocation.locationId
    : null;
  this.returnLocationName = data.returnLocation
    ? data.returnLocation.name
    : null;
  this.returnLocationType = data.returnLocation
    ? data.returnLocation.locationType
    : null;
  this.createdMethodValue = data.createdMethodValue || null;
  this.lastModifiedMethod = data.lastModifiedMethod || null;
  this.referralAgency = data.referralAgency || null;
  this.contractAcceptedText = data.contractAccepted
    ? "Contract has been accepted."
    : "Contract has not been accepted.";
  this.contractAccepted = data.contractAccepted || false;
  this.renterComments = data.renterComments || null;
  this.callCenterNotes = data.callCenterNotes || null;
  this.voucherAdded = data.voucherAdded || false;
  this.voucherAmount = data.voucherAmount || null;
  this.source = data.source || false;
  this.createdMethod = data.createdMethod
    ? data.partner && data.partner.displayName
      ? data.createdMethod + " (" + data.partner.displayName + ")"
      : data.createdMethod
    : null;

  this.cancellationNotes = data.cancellationNotes || null;
  this.cancelComment = data.cancelComment || null;
  this.cancellationFeePolicy = data.cancellationFeePolicy || null;
  this.applyCancellationFee = data.applyCancellationFee || null;
  this.cancellationFee = data.cancellationFee || null;
  this.cancellationReasons = data.cancellationReasons || [];
  this.cancelledByPersonId = data.cancelledByPersonId || null;
  this.cancelledByPersonName = data.cancelledByPersonName || null;
  this.cancelledByType = data.cancelledByType || null;
  this.cancelledOn = data.cancelledOn || null;
  this.cancelledOnDisplayText = data.cancelledOnDisplayText || "";
  this.totalCreditAmountGranted = data.totalCreditAmountGranted || null;
  this.pricing = data.pricing || {};
  this.reservationVoucher = data.reservationVoucher || {};
  this.timezoneString = data.timezoneString || null;
  this.customerEmployeeNo = data.customerEmployeeNo || null;
  this.customerLocationName = data.customerLocationName || null;
  this.integCompletedByName = data.integCompletedByName || null;
  this.integArrivedByName = data.integArrivedByName || null;
  this.integAdjustedByName = data.integAdjustedByName || null;
  this.hasLastAction =
    data.integCompletedByName ||
    data.integArrivedByName ||
    data.integAdjustedByName
      ? true
      : false;
  this.lastActionBy = data.integCompletedByName
    ? "Completed By " + data.integCompletedByName
    : data.integArrivedByName
    ? "Arrived By " + data.integArrivedByName
    : data.integAdjustedByName
    ? "Adjusted By " + data.integAdjustedByName
    : "--";
  // to enable begin rental just replace "false" with the commneted lines
  this.showBeginRentalBtn =
    this.isRental ||
    !hasPermission(PermissionName.START_RENTAL) ||
    [
      "CANCELLED",
      "MESSAGE",
      "PENDING",
      "BACKGROUND_CHECK_PENDING",
      "WEBERROR",
      "INCOMPLETE",
      "COMPLETED",
    ].includes(data.reservationState)
      ? false
      : true;
  // to enable begin rental just replace "false" with the commneted lines
  this.showEndRentalBtn =
    !this.isRental ||
    !hasPermission(PermissionName.END_RENTAL) ||
    (data.reservationStatus === "CONFIRMED" &&
      data.attendanceStatus === "COMPLETED") ||
    data.reservationStatus === "CANCELLED"
      ? false
      : true;
  this.showCancelBtn = hasPermission(PermissionName.CANCEL_RESERVATION)
    ? ["COMPLETED", "CANCELLED"].includes(data.reservationState)
      ? false
      : true
    : false;
  this.showContractBtn = data.contractAccepted || false;
  this.showAcceptDeclineBtn =
    hasPermission(PermissionName.START_RENTAL) &&
    data.reservationStatus === "PENDING"
      ? true
      : false;
  this.showConfirmBtn =
    hasPermission(PermissionName.START_RENTAL) &&
    data.reservationStatus === "BACKGROUND_CHECK_PENDING"
      ? true
      : false;
  this.showViewReservationLink =
    hasPermission(PermissionName.VIEW_RESERVATION) && data.reservationId
      ? true
      : false;
  this.enableChannelModal = data
    ? hasPermission(PermissionName.VIEW_INTEGRATION_INFO) && data.partner
      ? data.partner.partnerId != null
        ? true
        : false
      : false
    : false;
  this.enableVoucherModal = data
    ? hasPermission(PermissionName.VIEW_INTEGRATION_INFO) && data.partner
      ? [
          "GREENWAY",
          "GREENWAY_PPD",
          "GREENWAY3RDP_PPD",
          "GREENWAY3RDP",
        ].includes(data.partner.partnerCode) &&
        data.paymentOnCreation === "PREPAID"
        ? true
        : false
      : false
    : false;
  this.partner = data.partner || {};
  this.apiLicense = data.apiLicense || {};
  this.provider = data.provider || {};
  this.partnerConfirmationKey = data.partnerConfirmationKey || null;
  this.isPayLater = data.pricing ? data.pricing.type === "PayLater" : true;
  this.enableExternalParameterUpdate = data.rentalLocation
    ? data.rentalLocation.locationType === "CORPORATE"
    : false;
  this.customerPaymentUrl = data.customerPaymentUrl || null;
  this.allowAdminEdit = data.allowAdminEdit || false;
  this.allowSubmitReservation = data.allowSubmitReservation || false;
  this.allowAdminCancel = data.allowAdminCancel || false;
  this.companyNumber = data.companyNumber || null;
  this.clientReservationAllowed =
    (data.partner && data.partner.clientReservationAllowed) || false;
  this.pnr = data.pnr || null;
  this.airline = data.airline || null;
  this.flight = data.flight || null;
};

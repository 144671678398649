import * as actionTypes from "../actions/actionTypes";
import { reducerUpdateObject } from "./reducer.utility";

const initialState = {
  memberAppAccessPermissionsData: null,
  memberPermissionsData: null,
  assignPermissionsToMemberResponse: null,
  loading: false,
};
const getMemberPermissionsStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
const getMemberPermissionsSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    memberPermissionsData: action.memberPermissionsData,
    memberAppAccessPermissionsData: action.memberAppAccessPermissionsData,
    error: null,
    loading: false,
  });
};
const getMemberPermissionsFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false,
  });
};

const getAppAccessPermissionsStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
const getAppAccessPermissionsSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    memberAppAccessPermissionsData: action.memberAppAccessPermissionsData,
    error: null,
    loading: false,
  });
};
const getAppAccessPermissionsFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false,
  });
};

export const assignPermissionsToMemberStart = (state) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
export const assignPermissionsToMemberSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    assignPermissionsToMemberResponse: action.assignPermissionsToMemberResponse,
    error: null,
    loading: false,
  });
};
const assignPermissionsToMemberFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false,
  });
};
const assignPermissionsToMemberReset = (state) => {
  return reducerUpdateObject(state, {
    assignPermissionsToMemberResponse: null,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_MEMBER_PERMISSIONS_START:
      return getMemberPermissionsStart(state, action);
    case actionTypes.GET_MEMBER_PERMISSIONS_SUCCESS:
      return getMemberPermissionsSuccess(state, action);
    case actionTypes.GET_MEMBER_PERMISSIONS_FAIL:
      return getMemberPermissionsFail(state, action);
    case actionTypes.GET_MEMBER_APPACCESS_PERMISSIONS_START:
      return getAppAccessPermissionsStart(state, action);
    case actionTypes.GET_MEMBER_APPACCESS_PERMISSIONS_SUCCESS:
      return getAppAccessPermissionsSuccess(state, action);
    case actionTypes.GET_MEMBER_APPACCESS_PERMISSIONS_FAIL:
      return getAppAccessPermissionsFail(state, action);
    case actionTypes.ASSIGN_PERMISSIONS_TO_MEMBER_START:
      return assignPermissionsToMemberStart(state);
    case actionTypes.ASSIGN_PERMISSIONS_TO_MEMBER_SUCCESS:
      return assignPermissionsToMemberSuccess(state, action);
    case actionTypes.ASSIGN_PERMISSIONS_TO_MEMBER_FAIL:
      return assignPermissionsToMemberFail(state, action);
    case actionTypes.ASSIGN_PERMISSIONS_TO_MEMBER_RESET:
      return assignPermissionsToMemberReset(state);
    case actionTypes.PERMISSIONS_RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
export default reducer;

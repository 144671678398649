import React, { useState } from 'react';
import CustomAccordion from '../UI/accordion/custom-accordion';

function AccordionWrapper(props) {
    const { containerClass, title, isExpand, children } = props;
    const [isActive, setIsActive] = useState(isExpand || false);
    return (
        <div className={containerClass}>
            <CustomAccordion isActive={isActive} setIsActive={setIsActive} title={title} />
            {isActive ? children : ""}
        </div>
    )
}

export default AccordionWrapper
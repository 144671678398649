import React from "react";
import MarkerMap from "../../map/marker-map";

const LandmarkMap = (props) => {
  return (
    <div className="map-container">
      {props.latitude && props.longitude ? (
        <MarkerMap
          mapData={props.mapData || []}
          defaultPosition={[props.latitude, props.longitude]}
          style={{ height: "44rem" }}
          zoomForDetailsSection={13}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default LandmarkMap;

import PageInfo from "../models/PageInfo";

const MemberSearchRequest = function() {
  this.SearchType = null;
  this.SearchValue = null;
  this.FullName = null;
  this.FirstName = null;
  this.LastName = null;
  this.MiddleName = null;
  this.EmailAddress = null;
  this.DateOfBirth = null;
  this.StartJoinDate = null;
  this.EndJoinDate = null;
  this.DrivingLicenseNumber = null;
  this.PersonalNumber = null;
  this.Address = null;
  this.ZipCode = null;
  this.CountryId = null;
  this.StateId = null;
  this.CityId = null;
  this.ZipCodeId = null;
  this.IncludeInactive = null;
  this.OptInXpress = null;
  this.IsEmployee = null;
  this.EmployeeNo = null;
  this.EmployeeLocationId = null;
  this.BadgeNo = null;
  this.PageInfo = new PageInfo();
};
export default MemberSearchRequest;

import moment from "moment";
export const getLocalSortingClassName = (pageInfo, eventType) => {
  if (pageInfo.SortType === "ASC" && eventType === pageInfo.SortBy) {
    return "sorting_dsc";
  } else if (pageInfo.SortType === "DESC" && eventType === pageInfo.SortBy) {
    return "sorting_asc";
  } else {
    return "sorting";
  }
};
export const getSortType = (pageInfo, eventType) => {
  if (pageInfo.SortType === "ASC" && eventType === pageInfo.SortBy) {
    return "DESC";
  } else if (pageInfo.SortType === "DESC" && eventType === pageInfo.SortBy) {
    return "ASC";
  } else {
    return "DESC";
  }
};
export const doSorting = (sortingInfo, dataList) => {
  if (sortingInfo.DataType == "date") {
    doDateSorting(dataList, sortingInfo.SortType, sortingInfo.SortBy);
  } else if (sortingInfo.DataType == "number") {
    doNumericSorting(dataList, sortingInfo.SortType, sortingInfo.SortBy);
  } else {
    doStringSorting(dataList, sortingInfo.SortType, sortingInfo.SortBy);
  }
};
const doStringSorting = (data, order, columnName) => {
  data = data || [];
  if (order === "ASC") {
    data.sort(function (a, b) {
      let titleA = a[columnName]?.toLowerCase(),
        titleB = b[columnName]?.toLowerCase();
      if (titleA < titleB) return -1;
      if (titleA > titleB) return 1;
      return 0;
    });
  } else {
    data.sort(function (a, b) {
      let titleA = a[columnName]?.toLowerCase(),
        titleB = b[columnName]?.toLowerCase();
      if (titleB < titleA) return -1;
      if (titleB > titleA) return 1;
      return 0;
    });
  }
};
const doNumericSorting = (data, order, columnName) => {
  data = data || [];
  if (order === "ASC") {
    data.sort(function (a, b) {
      return a[columnName] - b[columnName];
    });
  } else {
    data.sort(function (a, b) {
      return b[columnName] - a[columnName];
    });
  }
};
const doDateSorting = (data, order, columnName) => {
  data = data || [];
  if (data.length === 0) {
    return;
  }

  if (order === "ASC") {
    data.sort(function (a, b) {
      let c = moment(a[columnName], "MM:dd:yyyy:H:mm");
      let d = moment(b[columnName], "MM:dd:yyyy:H:mm");
      if (c < d) return -1;
      if (c > d) return 1;
      return 0;
    });
  } else {
    data.sort(function (a, b) {
      let c = moment(a[columnName], "MM:dd:yyyy:H:mm");
      let d = moment(b[columnName], "MM:dd:yyyy:H:mm");
      if (d < c) return -1;
      if (d > c) return 1;
      return 0;
    });
  }
};

import {
  AddressTypeActual,
  PhoneType,
} from "../../../../shared/utility/enum-utility";

export const getCustomerViewModelForReservation = (data) => {
  const primaryAddress =
    data?.addresses &&
    data?.addresses.length &&
    data?.addresses.find((data) => data.type === AddressTypeActual.HOME);
  const homePhone =
    data?.phoneNumbers.length &&
    data?.phoneNumbers.find((data) => data.type === PhoneType.HOME);
  const mobilePhone =
    data?.phoneNumbers.length &&
    data?.phoneNumbers.find((data) => data.type === PhoneType.MOBILE);

  return {
    email: data?.email || null,
    customerId: data?.customerId || null,
    renterId: data?.renterId || null,
    firstName: data?.firstName || null,
    lastName: data?.lastName || null,
    dob: data?.dob || null,
    primaryAddress: primaryAddress || {
      street: null,
      street2: null,
      country: data?.email ? null : "US",
      province: null,
      city: null,
      postalCode: null,
      type: "HOME",
    },
    homePhone: homePhone || {
      country: "US",
      countryCode: 1,
      number: null,
      type: "Home",
    },
    mobilePhone: mobilePhone || {
      country: "US",
      countryCode: 1,
      number: null,
      type: "Home",
    },
    foxCredit: data?.foxCredit || null,
    rewardPointsBalance: data?.rewardPointsBalance || null,
    reservationCount: data?.reservationCount || null,
  };
};

import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAppAccessPermissions,
  permissionsReset,
  assignPermissionsToMemberReset,
  assignPermissionsToMember,
} from "../../../store/actions/index";
import { sortByString, getBasicToastResponse } from "../../../shared/utility";
import Spinner from "../../../components/UI/Spinner/Spinner";
import { Roles_Permissions } from "../../../shared/GlobalVar";
import PermissionsGrid from "../../../components/permission/permission-grid-component";
import { showNotification } from "../../../components/UI/Toaster/Toaster";

const AppAccesstoMember = (props) => {
  const [loading, setLoading] = useState(true);
  const memberAppAccessPermissionsData = useSelector(
    (state) => state.permissionsReducer.memberAppAccessPermissionsData
  );

  const assignPermissionsToMemberResponse = useSelector(
    (state) => state.permissionsReducer.assignPermissionsToMemberResponse
  );

  const dispatch = useDispatch();
  const isCategoryRequired = true;
  const [rolesPermisisonsData, setRolesPermisisonsData] = useState(null);

  useEffect(() => {
    dispatch(permissionsReset());
    dispatch(
      getAppAccessPermissions({
        MemberIds: props.memberIds || [],
        AppAccess: true,
      })
    );
  }, []);

  useEffect(() => {
    if (!memberAppAccessPermissionsData) {
      return;
    }
    setRolesPermisisonsData(
      doCbGridCompatibleDataForPermissionsToUsers(
        memberAppAccessPermissionsData,
        isCategoryRequired
      )
    );
    setLoading(false);
  }, [memberAppAccessPermissionsData]);

  useEffect(() => {
    if (!assignPermissionsToMemberResponse) {
      return;
    }
    showNotification(getBasicToastResponse(assignPermissionsToMemberResponse));
    if (assignPermissionsToMemberResponse.StatusCode === 0) {
      props.resetParentPage();
    }
    props.closeModal();
    dispatch(permissionsReset());
  }, [assignPermissionsToMemberResponse]);

  const doCbGridCompatibleDataForPermissionsToUsers = (
    permissionsData,
    categoryRequired
  ) => {
    let rolesPermissionsGridData = {
      rowData: {},
      columnData: {},
      totalRows: permissionsData.MemberPermissions
        ? permissionsData.MemberPermissions.length
        : 0,
      totalColumns: permissionsData.AllPermissions
        ? permissionsData.AllPermissions.length
        : 0,
    };
    permissionsData.AllPermissions.map((permissionData, index) => {
      var data = {};
      data.columnId = permissionData.PermissionId;
      data.columnName = permissionData.DisplayText;
      rolesPermissionsGridData.columnData[permissionData.PermissionId] = data;
    });
    var sortedcolumnsList = sortByString(
      permissionsData.MemberPermissions,
      "FullName"
    );
    sortedcolumnsList.map((memberData, index1) => {
      var data = {};
      data.rowId = memberData.PersonId;
      data.rowName = memberData.FullName;
      data.type = "All";
      data.columnInfo = {};
      if (data.type && !rolesPermissionsGridData.rowData[data.type]) {
        rolesPermissionsGridData.rowData[data.type] = {};
        rolesPermissionsGridData.rowData[data.type][data.rowId] = data;
      } else {
        rolesPermissionsGridData.rowData[data.type][data.rowId] = data;
      }

      memberData.Permissions.map((memPermisison, index2) => {
        var dataPer = {};
        dataPer.inherited = memPermisison.Inherited;
        dataPer.granted = memPermisison.Granted;
        if (memPermisison.Inherited && memPermisison.Granted != null) {
          dataPer.serverOverRide = true;
        }
        data.columnInfo[memPermisison.PermissionId] = dataPer;
        Object.keys(rolesPermissionsGridData.columnData).map(
          (rowData, index) => {
            if (
              rolesPermissionsGridData.columnData[memPermisison.PermissionId]
            ) {
              if (
                !rolesPermissionsGridData.columnData[
                  memPermisison.PermissionId
                ]["rowInfo"]
              ) {
                rolesPermissionsGridData.columnData[memPermisison.PermissionId][
                  "rowInfo"
                ] = {};
                rolesPermissionsGridData.columnData[memPermisison.PermissionId][
                  "rowInfo"
                ][data.rowId] = {};
                rolesPermissionsGridData.columnData[memPermisison.PermissionId][
                  "rowInfo"
                ][data.rowId] = dataPer;
              } else {
                rolesPermissionsGridData.columnData[memPermisison.PermissionId][
                  "rowInfo"
                ][data.rowId] = dataPer;
              }
            }
          }
        );
      });
      rolesPermissionsGridData.rowData[data.type][memberData.PersonId] = data;
    });
    return rolesPermissionsGridData;
  };
  const getModalSettings = () => {
    const totalColumns = rolesPermisisonsData.totalColumns;
    const totalRows = rolesPermisisonsData.totalRows;
    let tableWidth = isCategoryRequired
      ? totalColumns * 60 + 300
      : totalColumns * 60 + 200;
    let modalBodyHeight, modalWidth;
    if (totalColumns < 4) {
      modalWidth = isCategoryRequired ? 540 : 425;
    } else {
      if (tableWidth > 1060) {
        modalWidth = 1060;
      } else {
        modalWidth = isCategoryRequired ? tableWidth + 100 : tableWidth + 60;
      }
    }
    if (totalRows < 5) {
      modalBodyHeight = 260;
    } else {
      if (totalRows * 30 + 100 > 648) {
        modalBodyHeight = 648;
      } else {
        modalBodyHeight = totalRows * 30 + 100;
      }
    }
    let modalSettings = {
      modalWidth: modalWidth,
      modalBodyHeight: modalBodyHeight,
    };
    return modalSettings;
  };
  const upDateRolesPermissionsData = (rolesPermisisonsDataNew) => {
    let memberAddPermissionRequest = {};
    memberAddPermissionRequest.AssignPermissions = [];
    memberAddPermissionRequest.AssociatedPermissionList = [];
    Object.keys(rolesPermisisonsDataNew.rowData).map((type, index1) => {
      let granted = [];
      let revoked = [];
      let rowDataObj = rolesPermisisonsDataNew.rowData[type];
      let permissionObj = {};
      Object.keys(rowDataObj).map((rowKey, index2) => {
        granted = [];
        revoked = [];
        permissionObj = {};
        permissionObj.MemberId = rowKey;
        Object.keys(rowDataObj[rowKey].columnInfo).map((columnKey, index3) => {
          let rowData = rowDataObj[rowKey].columnInfo[columnKey];
          if (rowData.granted) {
            granted.push(columnKey);
          } else if (rowData.granted === false) {
            revoked.push(columnKey);
          }
        });
        permissionObj.GrantedPermissions = granted;
        permissionObj.RevokedPermissions = revoked;
        memberAddPermissionRequest.AssignPermissions.push(permissionObj);
      });
    });
    memberAppAccessPermissionsData.AllPermissions.map(
      (permissionData, index) => {
        memberAddPermissionRequest.AssociatedPermissionList.push(
          permissionData.PermissionId
        );
      }
    );
    dispatch(assignPermissionsToMemberReset());
    dispatch(assignPermissionsToMember(memberAddPermissionRequest));
    setLoading(true);
  };
  let rolesPermissionsctrl = <Spinner />;
  if (!loading) {
    if (
      memberAppAccessPermissionsData &&
      rolesPermisisonsData &&
      memberAppAccessPermissionsData.StatusCode === 0
    ) {
      let modalSettings = getModalSettings();
      rolesPermissionsctrl = (
        <Fragment>
          <PermissionsGrid
            isModalOpen={props.showModal}
            closeModal={props.closeModal}
            modalSettings={modalSettings}
            permissionsType={Roles_Permissions.AppAccessToMember}
            isCategoryRequired={isCategoryRequired}
            rolesPermisisonsData={rolesPermisisonsData}
            upDateRolesPermissionsData={upDateRolesPermissionsData}
          />
          {loading ? <Spinner /> : ""}
        </Fragment>
      );
    }
  }
  return rolesPermissionsctrl;
};
export default AppAccesstoMember;

import React, { useEffect, useState } from "react";
import Autosuggest from "react-autosuggest";
import { getMobileCountry } from "../../../shared/json/MobileCountry";
import "./autosuggetion.scss";
export const MobileAutosuggestionCtrl = (props) => {
  const languages = getMobileCountry();
  const { modelData, disabled, required, objectType, onChange } = props;
  const [suggestions, setSuggestions] = useState([]);
  const [suggestionValue, setSuggestionValue] = useState("");
  useEffect(() => {
    // if (modelData.countryCode === 1) {
    //   modelData.countryCode = "+" + modelData.countryCode;
    // }
    setSuggestionValue(modelData.countryCode ? modelData.countryCode : "");
  }, []);
  useEffect(() => {
    // if (modelData.countryCode === 1) {
    //   modelData.countryCode = "+" + modelData.countryCode;
    // }
    setSuggestionValue(modelData.countryCode ? modelData.countryCode : "");
  }, [modelData.countryCode]);
  useEffect(() => {
    if (!suggestionValue) {
      onChange({}, null, "auto-suggestions", objectType);
    }
  }, [suggestionValue]);
  const escapeRegexCharacters = (str) => {
    return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  };
  const getSuggestions = (value) => {
    let letterRegex = /^[A-Za-z]+$/;
    if (Number.isInteger(parseInt(value[0]))) {
      value = "+" + value;
    }
    const escapedValue = escapeRegexCharacters(value.trim());

    if (escapedValue === "") {
      return [];
    }

    const regex = new RegExp("^" + escapedValue, "i");

    return value.match(letterRegex)
      ? languages.filter((language) => regex.test(language.countryName))
      : languages.filter((language) => regex.test(language.value));
  };
  const getSuggestionValue = (suggestion) => {
    return suggestion.value;
  };
  const renderSuggestion = (suggestion) => {
    return <span>{suggestion.label}</span>;
  };
  const onSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(getSuggestions(value));
  };
  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };
  const onSuggestionSelected = (
    event,
    { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }
  ) => {
    onChange(
      {
        type: objectType,
        countryCode:
          suggestion.value && String(suggestion.value).includes("+", 0)
            ? suggestion.value.substring(1)
            : suggestion.value,
        countryIso: suggestion.countryIso,
        number: modelData.number,
      },
      null,
      "auto-suggestions",
      objectType
    );
  };
  const onInputChangeHandler = (event, { newValue, method }) => {
    setSuggestionValue(newValue || "");
  };
  const inputProps = {
    disabled: disabled ? true : false,
    required: required ? true : false,
    value: suggestionValue
      ? suggestionValue && String(suggestionValue).includes("+", 0)
        ? suggestionValue
        : isNaN(suggestionValue)?suggestionValue: "+" + suggestionValue
      : "",
    onChange: onInputChangeHandler,
    placeholder: 'Country',
  };
  return (
    <Autosuggest
      suggestions={suggestions}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      getSuggestionValue={getSuggestionValue}
      //shouldRenderSuggestions={true}
      renderSuggestion={renderSuggestion}
      inputProps={inputProps}
      onSuggestionSelected={onSuggestionSelected}
      highlightFirstSuggestion={true}
    />
  );
};
export default MobileAutosuggestionCtrl;

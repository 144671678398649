import React from 'react';
import GeneralRateRow from './component/general-rate-row';
import PaymentsCreditsBlock from './component/modern/payments-credits-block';
import PricingCommonWrapper from './component/modern/pricing-common-wrapper';
import PricingRateBlock from './component/modern/pricing-rate-block';
import ProtectionsExtrasBlock from './component/modern/protections-extras-block';
import TaxesFeesRow from './component/modern/taxes-fees-row';
import "./pricing.scss";

function ModernPricingTable(props) {
    const { pricingData } = props;
    const { ratesApplied = [], foxDiscount, rateDiscount = null, addOns = [], taxes = [], surcharges = [], loyalties = [] } =
        pricingData || {};
    const getCurrentFoxDiscount = () => {
        if (foxDiscount && foxDiscount.discountCode) {
            return {
                title: `Discount (${foxDiscount?.discountPercentage}%)`,
                subtotal: rateDiscount
            }
        }
    }
    return (
        <div className="common-res-container">
            <PricingCommonWrapper>
                <PricingRateBlock appliedRates={ratesApplied}
                    rentalCharge={pricingData?.ratePlusLate}
                    currentFoxDiscount={getCurrentFoxDiscount()}
                    rateDiscount={rateDiscount}
                />
                <TaxesFeesRow taxes={taxes} surcharges={surcharges} />
            </PricingCommonWrapper>
            <PricingCommonWrapper>
                <ProtectionsExtrasBlock
                    addOns={addOns}
                    loyalties={loyalties}
                    extensionFee={pricingData?.totalExtensionFee}
                    damageCharges={pricingData?.totalDamages}
                />
            </PricingCommonWrapper>
            <GeneralRateRow
                key="total-balance-due"
                contClasses="total-estimated-charge modern-total-bg fs-6 font-bold"
                title="Total"
                total={pricingData?.totalCharges}
            />
            <PaymentsCreditsBlock
                {...props}
            />
        </div >
    )
}

export default ModernPricingTable
import { FoxRequestHeader } from "../../fox-api/domain/fox-request-header";
import { ShuttleLocationSettingsModel } from "../domain/shuttle-location-settings-model";

const ShuttleLocationSettingsRequest = function(data,requestType) {
  data = data || {};
  this.requestHeader = new FoxRequestHeader({requestType:requestType || "GET"});
  this.locationCode = data.locationCode || null;
  if(data.shuttleLocationSettings){
    this.shuttleLocationSettings=new ShuttleLocationSettingsModel(data.shuttleLocationSettings);
  }
};
export default ShuttleLocationSettingsRequest;

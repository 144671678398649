import React from 'react';
import CheckBox from '../../../../components/UI/Input/checkbox-radio/check-box-ctrl';
import { ModalType } from '../../../../shared/GlobalVar';
import { PaymentType } from '../../../../shared/utility/enum-utility';
import CommonTitle from '../../component/common-title';
import ReservationConfirmModal from '../../component/reservation-confirm-modal';
import { validateRewardsInformationMatch } from '../../helper/common-res-helper';

function ReservationAction(props) {
    const { externalCode, isTestReservation, setIsTestReservation, disabled,
        onGetReservation, onCreateUpdateReservation, onCancelReservation,
        onSwitchToNewReservationMode, isChangesMade, currentPayType, setSecurityDepositError,
        confirmSecurityDepositRate, customerFormData, customerOriginalData,
        isValidCreateUpdateRequest, totalVehicleClass, modalType, setModalType
    } = props;
    const onCLoseModal = () => {
        setModalType(null);
    }
    const markAsTestReservation = () => {
        setIsTestReservation(true);
        onCLoseModal();
    };
    const onShowCreateUpdateModal = () => {
        if (currentPayType === PaymentType.PREPAID && !confirmSecurityDepositRate) {
            setSecurityDepositError(true);
            return false;
        }
        if (validateRewardsInformationMatch(customerFormData, customerOriginalData, true)) {
            setModalType(ModalType.RESERVATION_UPDATE_CONFIRM);
        }
    }
    return (
        <>
            <div className="common-res-container">
                <button
                    type="button"
                    className={"btn btn-yellow-default mt-4 fullwidth-btn"}
                    disabled={disabled || !isValidCreateUpdateRequest() || !totalVehicleClass}
                    title={!externalCode && disabled
                        ? "Location, Vehicle, and Customer information are mandatory to create reservation. Please complete these fields to proceed"
                        : externalCode && isChangesMade && disabled ? "No changes detected. Make changes to enable the button." : ""
                    }
                    onClick={onShowCreateUpdateModal}
                    style={{ width: "100% !important" }}
                >
                    {!externalCode ? "Complete Reservation" : "Update Reservation"}
                </button>
                <button
                    type="button"
                    className="btn btn-outline-danger mt-4 fullwidth-btn"
                    disabled={disabled}
                    onClick={() => setModalType(ModalType.RESERVATION_CANCEL_CHANGE_CONFIRM)}
                    style={{ width: "100% !important" }}
                >
                    Cancel Changes
                </button>
            </div>
            {!externalCode ?
                <div className="common-res-container input-ctrl">
                    <CommonTitle title="Test Reservation" classNames="common-title" />
                    <CheckBox
                        name="sameAsRentFromLoaction"
                        placeholder="I’ve confirmed that this is a test reservation."
                        checked={isTestReservation}
                        onChange={() => { isTestReservation ? setIsTestReservation(false) : setModalType(ModalType.RESERVATION_TEST_CONFIRM) }}
                        disabled={disabled}
                    />
                </div>
                : ""}
            {modalType ? (
                <ReservationConfirmModal
                    externalCode={externalCode}
                    modalType={modalType}
                    setModalType={setModalType}
                    onCLoseModal={onCLoseModal}
                    onGetReservation={onGetReservation}
                    onCreateUpdateReservation={onCreateUpdateReservation}
                    onCancelReservation={onCancelReservation}
                    markAsTestReservation={markAsTestReservation}
                    onSwitchToNewReservationMode={onSwitchToNewReservationMode}
                />) : ""}
        </>
    )
}

export default ReservationAction
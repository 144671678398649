import React, { useState, useEffect, Fragment } from "react";
import { createTooltip } from "../../shared/utility/tooltip-utility";
export const Pagination = props => {
  const [totalRecords, setTotalRecords] = useState(0);
  const [recordsPerPage, setRecordsPerPage] = useState(100);
  const [totalDisplayableBlocks, setTotalDisplayableBlocks] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  useEffect(() => {
    setTotalPages(Math.ceil(props.totalRecords / props.recordsPerPage));
  }, []);
  useEffect(() => {
    if (currentPage !== props.currentPage) {
      setCurrentPage(props.currentPage);
      setTotalPages(Math.ceil(props.totalRecords / props.recordsPerPage));
      setTotalRecords(props.totalRecords);
    }
    if (recordsPerPage !== props.recordsPerPage) {
      setRecordsPerPage(props.recordsPerPage);
      setTotalPages(Math.ceil(props.totalRecords / props.recordsPerPage));
    }
    if (totalRecords != props.totalRecords) {
      setTotalRecords(props.totalRecords);
      setTotalPages(Math.ceil(props.totalRecords / props.recordsPerPage));
    }
  }, [props.currentPage, props.recordsPerPage, props.totalRecords]);
  const getPaginationBlocks = page => {
    let dummyCurrentPage = currentPage === 0 ? 1 : currentPage;
    let startIndex = 0;
    if (dummyCurrentPage % totalDisplayableBlocks === 0) {
      startIndex = dummyCurrentPage + 1 - totalDisplayableBlocks;
    } else {
      startIndex =
        dummyCurrentPage - (dummyCurrentPage % totalDisplayableBlocks) + 1;
    }
    let showableBlocks = 0;
    let pages = [];
    if (totalPages < totalDisplayableBlocks) {
      showableBlocks = totalPages;
    } else {
      showableBlocks = startIndex + (totalDisplayableBlocks - 1);
    }

    showableBlocks = totalPages < showableBlocks ? totalPages : showableBlocks;

    if (showableBlocks > 1) {
      let j = 1;
      for (let i = startIndex; i <= showableBlocks; i++) {
        if (j === 1) {
          pages.push("FIRST");
          pages.push("PREV");
          pages.push(i);
        } else if (i === showableBlocks) {
          pages.push(i);
          pages.push("NEXT");
          pages.push("LAST");
        } else {
          pages.push(i);
        }
        j++;
      }
      return pages;
    }
  };
  const handleClick = (page, evt) => {
    evt.preventDefault();
    if (page === "RECORD_PER_PAGE") {
      props.onPageChanged(1, parseInt(evt.target.value));
    } else if (page === "FIRST") {
      if (currentPage > 1) {
        props.onPageChanged(1, recordsPerPage);
      }
    } else if (page === "PREV") {
      if (currentPage > 1) {
        props.onPageChanged(currentPage - 1, recordsPerPage);
      }
    } else if (page === "NEXT") {
      if (totalPages > currentPage) {
        props.onPageChanged(currentPage + 1, recordsPerPage);
      }
    } else if (page === "LAST") {
      if (totalPages > currentPage) {
        props.onPageChanged(totalPages, recordsPerPage);
      }
    } else {
      props.onPageChanged(page, recordsPerPage);
    }
  };
  const createPagination = () => {
    if (!props.totalRecords) return null;
    let dummyCurrentPage = currentPage === 0 ? 1 : currentPage;
    let enablePrevBtn = false;
    let enableNxtBtn = true;
    if (dummyCurrentPage > 1) {
      enablePrevBtn = true;
    }
    if (dummyCurrentPage === totalPages) {
      enableNxtBtn = false;
    }
    let pages = getPaginationBlocks();
    let endItem =
      dummyCurrentPage === 0
        ? recordsPerPage
        : dummyCurrentPage * recordsPerPage;
    let startItem = endItem - (recordsPerPage - 1);
    endItem = endItem > props.totalRecords ? props.totalRecords : endItem;
    let hrefLink = "#";
    return (
      <div
        className={
          props.customClass ? "pagination " + props.customClass : "pagination"
        }
      >
        <span className="pagination__display">
          Displaying {startItem} - {endItem} of {props.totalRecords}{" "}
        </span>
        <ul className="pagination__paging">
          {pages !== undefined &&
            pages.map((page, index) => {
              if (page === "FIRST")
                return (
                  <li key={index}>
                    <a
                      href={hrefLink}
                      className={enablePrevBtn ? "pg-btn" : "pg-btn--disabled"}
                      onClick={e => handleClick(page, e)}
                    >
                      First
                    </a>
                  </li>
                );

              if (page === "PREV")
                return (
                  <li key={index}>
                    <a
                      href={hrefLink}
                      className={enablePrevBtn ? "pg-btn" : "pg-btn--disabled"}
                      onClick={e => handleClick(page, e)}
                    >
                      Prev
                    </a>
                  </li>
                );
              if (page === "NEXT")
                return (
                  <li key={index}>
                    <a
                      href={hrefLink}
                      className={enableNxtBtn ? "pg-btn" : "pg-btn--disabled"}
                      onClick={e => handleClick(page, e)}
                    >
                      Next
                    </a>
                  </li>
                );
              if (page === "LAST")
                return (
                  <li key={index}>
                    <a
                      href={hrefLink}
                      className={enableNxtBtn ? "pg-btn" : "pg-btn--disabled"}
                      onClick={e => handleClick(page, e)}
                    >
                      Last
                    </a>
                  </li>
                );

              return (
                <li key={index}>
                  <a
                    className={`page-item${
                      dummyCurrentPage === page ? " active" : ""
                    }`}
                    href={hrefLink}
                    onClick={e => handleClick(page, e)}
                  >
                    {page}
                  </a>
                </li>
              );
            })}
        </ul>
        <div className="pagination__displayperpage">
          {props.showLiveIco ? 
            createTooltip(
              <i className={props.isLiveView ? "fa fa-table pr-2" : "fa fa-globe pr-2"} onClick={()=>props.onLiveIcoClick()}></i> ,
              props.isLiveView ? "Click to tabular view" : "Click to live view",
              { forceShow: true }
            )
          : ""}
          <span style={{ paddingRight: "5px" }}>Display</span>
          <select
            className="select"
            id="input-6"
            name="intersted"
            required=""
            aria-invalid="false"
            onChange={e => handleClick("RECORD_PER_PAGE", e)}
            value={recordsPerPage}
          >
            <option value="1">1</option>
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="30">30</option>
            <option value="40">40</option>
            <option value="50">50</option>
            <option value="60">60</option>
            <option value="70">70</option>
            <option value="80">80</option>
            <option value="90">90</option>
            <option value="100">100</option>
          </select>
          <span style={{ paddingLeft: "5px" }}>Per Page</span>
        </div>
      </div>
    );
  };
  return <Fragment>{createPagination()}</Fragment>;
};
export default Pagination;
import * as actionTypes from "../actions/actionTypes";
import { reducerUpdateObject } from "./reducer.utility";

const initialState = {
  activityLogProblemTypes: null,
  activityLogSubProblemTypes: null,
  paymentReasonTypes: null,
  reservationCancellationReasons: null,
  loading: false,
  integStatuses: null,
  revertFreeCreditReasons: null,
  billingTypes:null,
  locationTypes:null
};

const getEnumValueStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};

const getEnumValueSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    activityLogProblemTypes: action.activityLogProblemTypes,
    activityLogSubProblemTypes: action.activityLogSubProblemTypes,
    paymentReasonTypes: action.paymentReasonTypes,
    reservationCancellationReasons: action.reservationCancellationReasons,
    integStatuses: action.integStatuses,
    billingTypes: action.billingTypes,
    locationTypes: action.locationTypes,
    revertFreeCreditReasons: action.revertFreeCreditReasons,
    error: null,
    loading: false,
  });
};

const getEnumValueFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false,
  });
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ENUM_VALUE_START:
      return getEnumValueStart(state, action);
    case actionTypes.GET_ENUM_VALUE_SUCCESS:
      return getEnumValueSuccess(state, action);
    case actionTypes.GET_ENUM_VALUE_FAIL:
      return getEnumValueFail(state, action);

    default:
      return state;
  }
};
export default reducer;

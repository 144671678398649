import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  getSortingClassName,
  getSortType,
  hasPermission,
} from "../../../shared/utility";
import { COMMON_MSG } from "../../../shared/GlobalVar";
import { createTooltip } from "../../../shared/utility/tooltip-utility";
import { RoundCircleIcon } from "../../../components/UI/icon/round-circle-icon";
import { UserProfileLinkIcon } from "../../../shared/AppImages";
import PermissionName from "../../../shared/Permissions";


export const SearchLandmarksGrid = (props) => {
  const renderTableColumnHeader = (title, sortValue) => {
    return (
      <span
        className={
          sortValue
            ? getSortingClassName(props.pageInfo, sortValue)
            : "column__header"
        }
        onClick={(event) =>
          sortValue
            ? props.doSorting(getSortType(props.pageInfo, sortValue), sortValue)
            : null
        }
      >
        {title}
      </span>
    );
  };
  return (
    <Fragment>
      <div className="datagrid">
        <div className="column">
          {renderTableColumnHeader("Landmark Code", "landmarkCode")}
          {renderTableColumnHeader("Name", "name")}
          {renderTableColumnHeader("Category", "landmarkCategory")}
          {renderTableColumnHeader("Location", "location")}
          {renderTableColumnHeader("Address","formattedAddress")}
          {renderTableColumnHeader("Status", "status")}
        </div>
        {props.dataList.map((post) => {
          return (
            <div className="row" key={post.landmarkId}>
              <span
                className="row__column"
                id={"landmarkCodeId"}
              >
                {createTooltip(post.landmarkCode, post.landmarkCode)}
              </span>
              <span className="row__column" id="landmarkCodeIconId">
                {hasPermission(PermissionName.CC_VIEW_LANDMARK) ? createTooltip(
                  <Link
                    id={"landmarkNavigation_" + post.landmarkId}
                    to={{
                      pathname: "/landmark",
                      search: "?landmarkId=" + post.landmarkId,
                      state: {
                        breadCrumbList: props.breadCrumbList,
                        landmarkId: post.landmarkId
                      },
                    }}
                  >
                    <img
                      alt=""
                      src={UserProfileLinkIcon}
                      style={{ height: "12px", width: "12px" }}
                    />
                  </Link>,
                  COMMON_MSG.NavigationLinkIconTooltip,{forceShow:true}
                ) : ""}
              </span>
              <span
                className="row__column horizontal-align-center"
                id="shortCodeIcon"
              >
                  <RoundCircleIcon
                    roundIconProps={{
                      id: "iconLandmarkShortCode",
                      styles: {
                        right: "0.5rem",
                        background: post.iconColor,
                        marginTop: "0rem",
                        marginRight: "0rem",
                        fontSize:"0.8rem",
                      },
                      classes:"filled-circle-shuttle font-weight-bold",
                      iconTooltipmsg: post.shortName,
                      modalParams: null,
                      values: post.shortNameForIcon,
                      visibility: true,
                    }}
                  />
              </span>
              <span className="row__column" id={"landmarkNameId_"+post.landmarkId}>
                {createTooltip(post.name, post.name)}
              </span>
              <span
                className="row__column"
                id={"landmarkCategory_" + post.landmarkId}
              >
                {createTooltip(post.landmarkCategory.value, post.landmarkCategory.value)}
              </span>
              <span
                className="row__column"
                id={"locationCode_" + post.landmarkId}
              >
                {createTooltip(post.locationDisplayName, post.locationDisplayName)}
              </span>
              <span className="row__column" id={"formattedAddress_" + post.landmarkId}>
                {createTooltip(post.formattedAddress, post.formattedAddress)}
              </span>
              <span
                className="row__column text-center"
                id={"status_" + post.landmarkId}
              >
                {createTooltip(post.status, post.status)}
              </span>              
            </div>
          );
        })}
      </div>
    </Fragment>
  );
};
export default SearchLandmarksGrid;

import React from 'react';
import { ModalType } from '../../../../shared/GlobalVar';
import { formatCurrency } from '../../../../shared/utility';
import { MaxAddon } from '../../../../shared/utility/enum-utility';
import CommonTitle from '../../component/common-title';
import AddonsCountSelectContainer from './addons-count-select-container';

function AddonsSecondaryContainer(props) {
    const { addonData, selectedData, addonsQuantity, disabled, onAddonClick, setDialogData } = props;
    const onSelectAddon = (data, isCounter) => {
        onAddonClick(data, selectedData && true, isCounter)
    }
    const onShowModal = (e) => {
        setDialogData(
            {
                modalType: ModalType.COMMON_NOTES_MODAL,
                modalProps: {
                    showModal: true,
                    isDraggable: true,
                    closeModal: onCloseModal,
                    modalTitle: addonData?.code,
                    modaWrapperClass: "vehicle-tag-modal",
                    modalMinWidth: "370px",
                    modalMaxWidth: "370px",
                    modalPosition: { x: e.pageX - 100, y: e.pageY - 100 }
                },
                modalBodyProps: {
                    notes: addonData?.details,
                    isHtmlStr: true,
                },
            }
        );
    }
    const onCloseModal = () => {
        setDialogData(null);
    }
    const isDisabled = () => {
        return addonData?.maxQuantity > 1 && !selectedData
            ? (MaxAddon?.EQUIP_QUANTITY - addonsQuantity?.equipQuantity) > 0
                ? false : true : false;
    }
    return (
        <div className='flex-space-between margin-1'>
            <div className='flex-space-between gap-1 position-relative'>
                <CommonTitle title={addonData?.code} classNames="common-title-secondary" />
                <i className="fa fa-info-circle font-size-1-3 cursor-pointer" aria-hidden="true" onClick={(e) => { onShowModal(e) }} />
            </div>
            <div className='flex-column'>
                {addonData?.maxAmount ?
                    <CommonTitle title={`[Maximum ${formatCurrency(addonData?.maxAmount)}]`} classNames="common-title-secondary font-size-1 text-right" />
                    : ""}
                <div className='flex-space-between gap-1 align-self-end'>
                    {addonData?.maxQuantity > 1
                        ? <AddonsCountSelectContainer
                            onSelectCounter={onSelectAddon}
                            addonData={selectedData ? selectedData : addonData}
                            addonsQuantity={addonsQuantity}
                            disabled={disabled || addonData?.isDisabled || addonData.isDiscounted}
                        />
                        : ""}
                    <button
                        className={
                            `btn font-size-all addons-btn ${selectedData ? 'btn-yellow-default' : 'common-reserve-btn'}`
                        }
                        onClick={() => onSelectAddon(addonData, false)}
                        disabled={disabled || addonData?.isDisabled || addonData.isDiscounted || (!selectedData && addonsQuantity?.totalAddons >= MaxAddon?.TOTAL_ADDONS)
                            || isDisabled()
                        }
                    // title={isEditAndPayLater ? COMMON_MSG.PayLaterToPayNow : ""}
                    >
                        {selectedData ? "Remove" : "Add"}
                    </button>
                </div>
            </div>

        </div >
    )
}

export default AddonsSecondaryContainer
import PageInfo from "../../../services/entities/models/PageInfo";
import { enumToKeyValuesObject, getYearsDropDown, ShuttleAvailability, ShuttleConnectionStatus, ShuttleEngineStatus } from "../../../shared/utility/enum-utility";

export const getShuttlesSearchCtrls = () => {
  return {
    PageInfo: new PageInfo(null, null, "shuttleCode", "DESC"),
    shuttleCode: {
      id: "shuttleCode",
      elementType: "input",
      elementConfig: {
        name: "shuttleCode",
        type: "text",
      },
      validation: {
        required: false,
      },
      labelInput: {
        labeltext: "Shuttle Code",
      },
      value: "",
      valid: false,
      touched: false,
      basicOrder: 1,
      advancedOrder: 1,
    },
    vin: {
      id: "vin",
      elementType: "input",
      elementConfig: {
        name: "vin",
        type: "text",
      },
      validation: {
        required: false,
      },
      labelInput: {
        labeltext: "Vin",
      },
      value: "",
      valid: false,
      touched: false,
      basicOrder: 2,
      advancedOrder: 2,
    },
    shuttleConnectionStatuses: {
      id: "shuttleConnectionStatuses",
      elementType: "cb_dropdown",
      elementConfig: {
        name: "shuttleConnectionStatuses",
        options: enumToKeyValuesObject(ShuttleConnectionStatus,true),
        selectMultipleOption: [],
      },
      labelInput: {
        labeltext: "Status",
      },
      value: "",
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      basicOrder: 3,
      advancedOrder: 3,
    },
    locationCodes: {
      id: "locationCodes",
      elementType: "cb_dropdown",
      elementConfig: {
        name: "locationCodes",
        options: [{ key: "", displayValue: "Any Location" }],
        selectMultipleOption: [],
      },
      labelInput: {
        labeltext: "Location",
      },
      value: "",
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      basicOrder: 4,
      advancedOrder: 4,
    },
    make: {
      id: "make",
      elementType: "input",
      elementConfig: {
        name: "make",
        type: "text",
      },
      validation: {
        required: false,
      },
      labelInput: {
        labeltext: "Make",
      },
      value: "",
      valid: false,
      touched: false,
      basicOrder: 6,
      advancedOrder: 6,
    },
    model: {
      id: "model",
      elementType: "input",
      elementConfig: {
        name: "model",
        type: "text",
      },
      validation: {
        required: false,
      },
      labelInput: {
        labeltext: "Model",
      },
      value: "",
      valid: false,
      touched: false,
      basicOrder: 7,
      advancedOrder: 7,
    },
    year: {
      id: "year",
      elementType: "select",
      elementConfig: {
        name: "year",
        options:getYearsDropDown()
      },
      validation: {
        required: false,
      },
      labelInput: {
        labeltext: "Year",
      },
      value: "",
      valid: false,
      touched: false,
      basicOrder: 8,
      advancedOrder: 8,
    },
    availabilites: {
      id: "availabilites",
      elementType: "cb_dropdown",
      elementConfig: {
        name: "availabilites",
        options: enumToKeyValuesObject(ShuttleAvailability,true),
        selectMultipleOption: [],
      },
      labelInput: {
        labeltext: "Availability",
      },
      value: "",
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      basicOrder: 9,
      advancedOrder: 9,
    },
    btnResSearch: {
      id: "btnResSearch",
      elementType: "button",
      elementConfig: {
        type: "submit",
        value: "Search",
        name: "btnResSearch",
        className: "btn btn-primary search-btn",
      },
      basicOrder: 5,
      advancedOrder: 5,
    },
    btnResSearchReset: {
      id: "btnResSearchReset",
      elementType: "button",
      elementConfig: {
        type: "button",
        value: "Reset",
        name: "btnResSearchReset",
        className: "btn btn-secondary search-btn",
      },
      basicOrder: 10,
      advancedOrder: 10,
    },
  };
};

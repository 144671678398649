import React, { Fragment } from "react";

const InputClearIcon = (props) => {
  return (
    <Fragment>
      {props.inputValue ? (
        <div
          className={`input-clear-icon-position ${props.customDivClass ? props.customDivClass : ""
            }`}
          onClick={props.onClickEvent}
          title="Clear input"
          style={props.customCSS ? props.customCSS : {}}
        >
          <i className="fas fa-times fs-0"></i>
        </div>
      ) : (
        ""
      )}
    </Fragment>
  );
};
export default InputClearIcon;

import React from 'react';
import Draggable from "react-draggable";
import Dialog from './dialog';
function DraggableDialog(props) {
    const {
        showModal,
        modaWrapperClass,
        modalPosition,
        modalMinWidth = "372px",
        modalMaxWidth = "372px",
    } = props;
    return (
        <Draggable>
            <div className={`${showModal ? "custom-modal-show" : ""} ${modaWrapperClass} modal-main-container`}
                style={{
                    minWidth: modalMinWidth,
                    maxWidth: modalMaxWidth,
                    left: modalPosition?.x,
                    top: modalPosition?.y,
                }}>
                <Dialog {...props} />
            </div >
        </Draggable >
    )
}

export default DraggableDialog
import moment from "moment";
import ReactHtmlParser from "react-html-parser";
import { COMMON_MSG } from "../../../shared/GlobalVar";
import { getFormattedValidationMsg, isEmail } from "../../../shared/utility";
import { getDefaultErrMsg } from "../../../shared/utility/validation-helper";

export const validateParimaryResData = (data, isBoolean) => {
  let errorMsg = "";
  const starttime =
    data.rentalDateTime && data.rentalOnlyDate && data.rentalOnlyTime
      ? moment(data.rentalDateTime, "YYYY-MM-DDTHH:mm:ss").toDate()
      : null;
  const endtime =
    data.returnDateTime && data.returnOnlyDate && data.returnOnlyTime
      ? moment(data.returnDateTime, "YYYY-MM-DDTHH:mm:ss").toDate()
      : null;

  errorMsg = getDefaultErrMsg(
    data.rentalLocationCode,
    errorMsg,
    "Rental location"
  );
  errorMsg = getDefaultErrMsg(
    data.returnLocationCode,
    errorMsg,
    "Return location"
  );
  errorMsg = getDefaultErrMsg(starttime, errorMsg, "Pickup date");
  errorMsg = getDefaultErrMsg(endtime, errorMsg, "Return date");
  // errorMsg = hasObjectNonEmptyProperty(data.discount)? getDefaultErrMsg(data.discount.discountType,errorMsg,COMMON_MSG.DiscountType):errorMsg;
  // errorMsg = hasObjectNonEmptyProperty(data.discount)? getDefaultErrMsg(data.discount.discountCode,errorMsg,COMMON_MSG.DiscountCode):errorMsg;
  if (errorMsg) {
    return getFormattedValidationMsg(errorMsg);
  }
  if (starttime && endtime && endtime.getTime() <= starttime.getTime()) {
    errorMsg = errorMsg
      ? errorMsg + "<br>Return Time must be after Pickup Time."
      : "Return Time must be after Pickup Time.";
  }
  if (errorMsg) {
    return isBoolean ? false : ReactHtmlParser(errorMsg);
  }
  return isBoolean ? true : errorMsg;
};
export const validateCustomerBlock = (data, isBoolean) => {
  const validateAddressField = (address, type, field) => {
    // const address = addresses.find((element) => element.type === type);
    return address ? address[field] : false;
  };
  const requiredFields = [
    { field: data.firstName, message: COMMON_MSG.FirstName },
    { field: data.lastName, message: COMMON_MSG.LastName },
    { field: isEmail(data.email), message: COMMON_MSG.EmailAddress },
    {
      field: data.homePhone,
      message: COMMON_MSG.PhoneNoRequired,
    },
    {
      field: validateAddressField(data.primaryAddress, "HOME", "country"),
      message: COMMON_MSG.Country,
    },
    {
      field:
        validateAddressField(data.primaryAddress, "HOME", "postalCode") ||
        validateAddressField(data.primaryAddress, "HOME", "postalCodeText"),
      message: COMMON_MSG.ZipCode,
    },
    // { field: data.dob, message: COMMON_MSG.DOB },
  ];
  const errorMsgs = requiredFields
    .filter(({ field }) => !field)
    .map(({ message }) => message);
  if (errorMsgs.length > 0) {
    return isBoolean ? false : getFormattedValidationMsg(errorMsgs.join(", "));
  }
  return isBoolean ? true : "";
};
export const validateCustomerFields = (name, value, isBoolean) => {
  let error = "";
  if (name === "firstName") {
    if (!value) error = COMMON_MSG.FirstName;
  } else if (name === "lastName") {
    if (!value) error = COMMON_MSG.LastName;
  } else if (name === "email") {
    if (!isEmail(value)) error = COMMON_MSG.EmailAddress;
  } else if (name === "homePhone") {
    if (!value) error = COMMON_MSG.PhoneNoRequired;
  } else if (name === "country") {
    if (!value) error = COMMON_MSG.Country;
  } else if (name === "postalCode") {
    if (!value) error = COMMON_MSG.ZipCode;
  } else if (name === "postalCodeText") {
    if (!value) error = COMMON_MSG.ZipCode;
  }
  return isBoolean ? (error ? false : true) : error;
};
export const validateAlphaNumericFields = (value) => {
  return /^[a-zA-Z0-9]*$/.test(value);
};
export const validateRewardsInformationMatch = (
  customerFormData,
  renterOriginalBasicInfo,
  isBoolean
) => {
  let error = "";
  if (
    customerFormData.renterId &&
    customerFormData.renterId === renterOriginalBasicInfo.renterId
  ) {
    if (
      customerFormData.firstName !== renterOriginalBasicInfo.firstName ||
      customerFormData.lastName !== renterOriginalBasicInfo.lastName ||
      customerFormData.email !== renterOriginalBasicInfo.email
    ) {
      error = COMMON_MSG.RewardsInformationMisMatchMsg;
    }
  }
  return isBoolean ? (error ? false : true) : error;
};
export const getCustomAddonsForUI = (dbAddons, discountAddons, isDisabled) => {
  return dbAddons.map((data) => {
    const addonData = { ...data };
    addonData.isDiscounted = false;
    addonData.isDisabled = isDisabled;
    if (discountAddons && discountAddons.length > 0) {
      const disountAddon = discountAddons.find((discountData) => {
        return data?.code === discountData?.code;
      });
      if (disountAddon) {
        addonData.newRate = disountAddon?.rate;
        addonData.newAmount = disountAddon?.amount;
        addonData.isDiscounted = true;
      }
    }
    return addonData;
  });
};
export const getOnetimeDiscountList = (foxDiscounts, timeZone) => {
  if (!timeZone) return foxDiscounts;
  let nowInServer = timeZone
    ? moment().tz(timeZone)
    : moment().tz(moment.tz.guess());
  return foxDiscounts.map((data) => {
    const discountData = { ...data };
    discountData.isExpire = false;
    const expiryDate = data.expiresOn
      ? moment(data.expiresOn, "YYYY-MM-DDTHH:mm:ss").toDate()
      : null;
    if (expiryDate && expiryDate.getTime() < nowInServer.toDate().getTime()) {
      discountData.isExpire = true;
    }
    return discountData;
  });
};

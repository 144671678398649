/**
 *
 * to do: need to do code here wisely not just write zibberish code
 * never make it jungle like utility.js
 *
 */
import moment from "moment";
import momentTimezone from "moment-timezone";
import { getDateTimeFormatString } from "../../components/common/common-helper";
import { SERVER_TIMEZONE_STRING } from "../GlobalVar";
import {
  convertDateTimeString,
  getCurrentServerTimezone,
  getTimezoneAbbr,
} from "../utility";

export const getTimezomeByLocationId = (
  locationId,
  datalist,
  returnDefault
) => {
  if (datalist && datalist.length > 0 && locationId) {
    const data = datalist.find((element) => element.locationId === locationId);
    return data?.timezoneString;
  }
  return returnDefault ? getCurrentServerTimezone() : null;
};

export const gettimeZoneWiseCurrentStartDateEndate = (
  locationId,
  datalist,
  step,
  returnDefault,
  formatStr
) => {
  const timeZone = getTimezomeByLocationId(locationId, datalist, returnDefault);
  const currentStartDate = timeZone
    ? moment().tz(timeZone)
    : moment().tz(moment.tz.guess());
  const currentEndDate = timeZone
    ? moment().tz(timeZone).add(step, "days")
    : moment().tz(moment.tz.guess()).add(step, "days");
  return {
    timeZone: timeZone,
    currentStartDate: currentStartDate,
    currentEndDate: currentEndDate,
    formattedStartDate: moment(currentStartDate).format(formatStr),
    formattedEndDate: moment(currentEndDate).format(formatStr),
  };
};

export const getDateTimeByTimeZone = (timeZone, returnDeafult, steps) => {
  if (steps !== null) {
    return timeZone
      ? moment().tz(timeZone).add(steps, "days")
      : moment().tz(moment.tz.guess()).add(steps, "days");
  } else {
    return timeZone
      ? moment().tz(timeZone)
      : returnDeafult
      ? moment().tz(moment.tz.guess())
      : null;
  }
};

export const convertDateTimeWithColonToValidDate = (dateString) => {
  const parts = dateString.split(":");
  const month = parts[0];
  const day = parts[1];
  const year = parts[2];
  let hour = parseInt(parts[3]);
  const minute = parts[4];
  const second = parts[5];

  let ampm = "AM";
  if (hour >= 12) {
    ampm = "PM";
    hour -= 12;
  }
  if (hour === 0) {
    hour = 12;
  }

  return `${month}/${day}/${year} ${hour
    .toString()
    .padStart(2, "0")}:${minute}:${second} ${ampm}`;
};

export const convertToDateTimeString = (
  datetime,
  includeTime,
  timeZoneString,
  isTooltip,
  format,
  shouldConvertToLocalTime
) => {
  const defaultFormat = getDateTimeFormatString(includeTime, false, true);
  const dateFormat = format ? format : defaultFormat;
  const timestampRegex = /^\d{13}$/; //'1710980715316'
  const dateTimeWithColonRegex = /^\d{2}:\d{2}:\d{4}:\d{2}:\d{2}(:\d{2})?$/; //"10:31:2023:02:42";
  let dateTimeString = moment(datetime).format(dateFormat); //convertFoxApiDateToStr(datetime, dateFormat);
  if (dateTimeWithColonRegex.test(datetime)) {
    dateTimeString = convertDateTimeWithColonToValidDate(datetime);
  }
  if (timestampRegex.test(datetime) || shouldConvertToLocalTime) {
    dateTimeString = timeZoneString
      ? momentTimezone
          .tz(
            dateTimeString,
            dateFormat,
            shouldConvertToLocalTime
              ? SERVER_TIMEZONE_STRING
              : moment.tz.guess()
          )
          .tz(timeZoneString)
          .format(dateFormat)
      : convertDateTimeString;
  }
  return isTooltip && timeZoneString
    ? dateTimeString + " " + getTimezoneAbbr(dateTimeString, timeZoneString)
    : dateTimeString;
};

export const DateInputFormats = [
  "MMDDYYYY",
  "MM/DD/YYYY",
  "MMDDYY",
  "MM/DD/YY",
  "M/D/YYYY",
  "M/D/YY",
  "MM.DD.YYYY",
  "MM.DD.YY",
  "M.D.YY",
];
export const TimeInputFormats = [
  "HHmm",
  "HHmmA",
  "HH:mm",
  "HH:mm A",
  "hmm",
  "hmmA",
  "h:mm",
  "h:mm A",
  "hhmm",
  "hhmmA",
  "hh:mm",
  "hh:mm A",
];

import { BUILD_NUMBER } from "../../../config/config";

/** clear storage*/
export const clearCache = () => {
  if (BUILD_NUMBER !== localStorage.getItem("BUILD_NUMBER")) {
    localStorage.clear();
    localStorage.setItem("BUILD_NUMBER", BUILD_NUMBER);
    if ("caches" in window) {
      caches.keys().then((names) => {
        names.forEach((name) => {
          caches.delete(name);
        });
      });
    }
    // Makes sure the page reloads. Changes are only visible after you refresh.
    window.location.reload(true);
  }
};
export const dataStoe = {
  postalWiseAddress: [],
};

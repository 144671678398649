import React from "react";
import { EDIT_CION } from "../../../shared/AppImages";
import { formatPricingValue, isNullOrEmpty } from "../../../shared/utility";
import { createTooltip } from "../../../shared/utility/tooltip-utility";
export const RoundCircleIcon = (props) => {
  /**not satisfied need to to think later */
  const getRateTooltipHtmlRow = (labelCaption, type) => {
    const appliedRate = props.roundIconProps.ratesApplied && props.roundIconProps.ratesApplied.length > 0 ? props.roundIconProps.ratesApplied.filter(rate => rate.type === type) : 0;
    return (
      <tr>
        <td width="80px">
          <strong>{labelCaption}</strong>
        </td>
        <td width="5%" />
        <td width="80px">
          {appliedRate && appliedRate.length > 0 ? (
            <>{formatPricingValue(appliedRate[0].rate)}</>
          ) : (
            "--"
          )}
        </td>
      </tr>
    );
  };
  const getRateTooltipHtml = () => {
    return (
      <table width="100%" className="common-tooltip">
        <thead>
          <tr>
            <th colSpan="3" align="left">
              Rates
            </th>
          </tr>
        </thead>
        <tbody>
          {getRateTooltipHtmlRow("Monthly", "MONTHLY")}
          {getRateTooltipHtmlRow("Weekly", "WEEKLY")}
          {getRateTooltipHtmlRow("Daily", "DAILY")}
          {getRateTooltipHtmlRow("Weekend", "WEEKEND")}
          {getRateTooltipHtmlRow("Hourly", "HOURLY")}
        </tbody>
      </table>
    );
  };

  if (props.roundIconProps.type === "INTERNAL_MEMO" || props.roundIconProps.type === "CUSTOMER_NOTE") {
    props.roundIconProps.values = <img
      src={EDIT_CION}
      alt="History"
      className="summary__updateIcon"
      style={{ height: "1rem" }}
    ></img>
  } else if (props.roundIconProps.type === "VOUCHER_MODAL") {
    props.roundIconProps.values = <i className="fas fa-file-invoice-dollar" />
  } else if (props.roundIconProps.type === "APPLIED_RATE") {
    props.roundIconProps.iconTooltipmsg = getRateTooltipHtml();
  }
  return (
    <span
      id={props.roundIconProps.id}
      className={props.roundIconProps.classes || "filled-circle"}
      style={props.roundIconProps.styles}
      onClick={
        props.roundIconProps.onClickEvent
          ? props.roundIconProps.onClickEvent
          : props.onClick ? () => props.onClick(props.roundIconProps.modalParams) : null
      }
    >
      {createTooltip(
        props.roundIconProps.values,
        props.roundIconProps.iconTooltipmsg,
        {
          forceShow: true,
        }
      )}
    </span>
  );
};

export const getRoundCircleIconProps = (
  data,
  controlName,
  displayValue,
  tooltipValue,
  onClickEvent,
  parameters
) => {
  let isCreditIcon = controlName === "PaymentFailureCount";
  let isRateIcon = controlName === "ReservationPricingInfo";
  let isNotes = ["BorrowerNotes", "CallCenterNotes"].includes(controlName);
  let value = isNotes ? (
    <img
      src={EDIT_CION}
      alt="History"
      className="summary__updateIcon"
      style={{ height: "1rem" }}
    ></img>
  ) : !isNullOrEmpty(displayValue) ? (
    displayValue
  ) : data ? (
    data[controlName]
  ) : (
    0
  );

  let styles = {
    right: isRateIcon ? "0rem" : "0.5rem",
    background: isRateIcon
      ? "var(--color-primary)"
      : isNotes
        ? "#ccc"
        : isCreditIcon
          ? value > 0
            ? "red"
            : "#5DBD91"
          : "",
    marginTop: isRateIcon ? "0.1rem" : "0rem",
    marginRight: isRateIcon ? "0.5rem" : "0rem",
  };
  let iconTooltipmsg = !isNullOrEmpty(tooltipValue)
    ? tooltipValue
    : value > 0
      ? "Credit card has failed: " + value + " time(s)"
      : "No credit card failures";
  return {
    id: "id" + controlName,
    styles: styles,
    iconTooltipmsg: iconTooltipmsg,
    values: value,
    onClickEvent: () => (onClickEvent ? onClickEvent(parameters) : ""),
  };
};

import * as actionTypes from "./actionTypes";
import HttpService, { invokeServerAPI } from "../../services/httpService";
import { LicenseDocType } from "../../shared/GlobalVar";

export const doManualLicenseApproveOrDisApproveStart = () => {
  return {
    type: actionTypes.LICENSE_APPROVAL_START,
  };
};
export const doManualLicenseApproveOrDisApproveSuccess = (
  licenseApprovalResponse
) => {
  return {
    type: actionTypes.LICENSE_APPROVAL_SUCCESS,
    licenseApprovalResponse: licenseApprovalResponse,
  };
};
export const doManualLicenseApproveOrDisApproveFail = (error) => {
  return {
    type: actionTypes.LICENSE_APPROVAL_FAIL,
    error: error,
  };
};
export const doManualLicenseApproveOrDisApproveReset = (error) => {
  return {
    type: actionTypes.LICENSE_APPROVAL_RESET,
  };
};

export const runDMVCheckStart = () => {
  return {
    type: actionTypes.RUN_DMV_CHECK_START,
  };
};

export const runDMVCheckSuccess = (dmvCheckResponse) => {
  return {
    type: actionTypes.RUN_DMV_CHECK_SUCCESS,
    dmvCheckResponse: dmvCheckResponse,
  };
};

export const runDMVCheckFail = (error) => {
  return {
    type: actionTypes.RUN_DMV_CHECK_FAIL,
    error: error,
  };
};
export const runDMVCheckReset = (error) => {
  return {
    type: actionTypes.RUN_DMV_CHECK_RESET,
    error: error,
  };
};

export const addAdditionalLicenseStart = () => {
  return {
    type: actionTypes.ADD_ADDITIONAL_LICENSE_START,
  };
};
export const addAdditionalLicenseSuccess = (addAdditionalLicenseResponse) => {
  return {
    type: actionTypes.ADD_ADDITIONAL_LICENSE_SUCCESS,
    addAdditionalLicenseResponse: addAdditionalLicenseResponse,
  };
};
export const addAdditionalLicenseFail = (error) => {
  return {
    type: actionTypes.ADD_ADDITIONAL_LICENSE_FAIL,
    error: error,
  };
};
export const addAdditionalLicenseReset = () => {
  return {
    type: actionTypes.ADD_ADDITIONAL_LICENSE_RESET,
  };
};
export const doManualLicenseApproveOrDisApprove = (req, pageType) => {
  return (dispatch) => {
    dispatch(doManualLicenseApproveOrDisApproveStart());
    if (
      pageType === LicenseDocType.ATTACHED_DOC ||
      pageType === LicenseDocType.MANUAL_APPROVE
    ) {
      invokeServerAPI("manuallicenseapproval", req)
        .then((response) => {
          dispatch(doManualLicenseApproveOrDisApproveSuccess(response));
        })
        .catch((err) => {
          //dispatch(doManualLicenseApproveOrDisApproveFail(err));
        });
    } else {
      invokeServerAPI("manuallicensedisapproval", req)
        .then((response) => {
          dispatch(doManualLicenseApproveOrDisApproveSuccess(response));
        })
        .catch((err) => {
          //dispatch(doManualLicenseApproveOrDisApproveFail(err));
        });
    }
  };
};
export const runDMVCheck = (req) => {
  return (dispatch) => {
    dispatch(runDMVCheckStart());
    invokeServerAPI("validatedrivinglicense", req)
      .then((response) => {
        dispatch(runDMVCheckSuccess(response));
      })
      .catch((err) => {
        //dispatch(runDMVCheckFail(err));
      });
  };
};
export const addAdditionalLicense = (req) => {
  return (dispatch) => {
    dispatch(addAdditionalLicenseStart());
    invokeServerAPI("addstatelicense", req)
      .then((response) => {
        dispatch(addAdditionalLicenseSuccess(response));
      })
      .catch((err) => {
        //dispatch(addAdditionalLicenseFail(err));
      });
  };
};

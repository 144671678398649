import { createDefaultLblCtrlModel, createDefaultLblInputFieldCtrlModel ,createDefaultInputCtrlModel, getDOBDateTimeFormat, createLblInputCtrlModel, createRootContainerCtrlModel} from "../../../../components/UI/Input/input-ctrl-helper";
import { getMemberLabelByOrgRentalType } from "../../../../shared/utility/system-utility";
import { ADMIN_COMMON_CAPTION } from "../../../../shared/GlobalVar";
import { getDateTimePlaceholder } from "../../../../components/common/common-helper";

export const getSearchMemberResInputCtrl = (viewData,disabled) => {
    const resCustomerFields=[];
    viewData =viewData || {};
    resCustomerFields.push(createDefaultLblInputFieldCtrlModel(
        createDefaultLblCtrlModel(ADMIN_COMMON_CAPTION.CUSTOMER_ID),
        createDefaultInputCtrlModel("text","SearchValue",viewData.SearchValue,true,disabled)
    )); 
    resCustomerFields.push(createDefaultLblInputFieldCtrlModel(
        createDefaultLblCtrlModel("FirstName"),
        createDefaultInputCtrlModel("text","FirstName",viewData.FirstName,true,disabled)
    )); 
    resCustomerFields.push(createDefaultLblInputFieldCtrlModel(
        createDefaultLblCtrlModel("Postal / Zip Code"),
        createDefaultInputCtrlModel("text","ZipCodeId",viewData.ZipCodeId,true,disabled)
    )); 
    resCustomerFields.push(createDefaultLblInputFieldCtrlModel(
        createDefaultLblCtrlModel("Email / User Name"),
        createDefaultInputCtrlModel("email","EmailAddress",viewData.EmailAddress,true,disabled)
    )); 
    resCustomerFields.push(createDefaultLblInputFieldCtrlModel(
        createDefaultLblCtrlModel("Last Name"),
        createDefaultInputCtrlModel("text","LastName",viewData.LastName,true,disabled)
    )); 
    resCustomerFields.push(createDefaultLblInputFieldCtrlModel(
        createDefaultLblCtrlModel("Driver's License #"),
        createDefaultInputCtrlModel("text","DrivingLicenseNumber",viewData.DrivingLicenseNumber,true,disabled)
    ));
    resCustomerFields.push(createDefaultLblInputFieldCtrlModel(
        createDefaultLblCtrlModel("Phone"),
        createDefaultInputCtrlModel("number","PersonalNumber",viewData.PersonalNumber,true,disabled)
    ));
    resCustomerFields.push(createDefaultLblInputFieldCtrlModel(
        createDefaultLblCtrlModel("Date of Birth"),
        createDefaultInputCtrlModel("datetime","DateOfBirth",viewData.DateOfBirth,true,disabled,getDateTimePlaceholder(),null,null,null,null,null,getDOBDateTimeFormat())
    ));
    resCustomerFields.push(createDefaultLblInputFieldCtrlModel(
        createDefaultLblCtrlModel("Address"),
        createDefaultInputCtrlModel("text","Address",viewData.Address,true,disabled)
    ));
    return createLblInputCtrlModel (
        createRootContainerCtrlModel("div","form-group row"),resCustomerFields,"col-sm-4 col-form-label font-weight-bold","form-control pl-4",createRootContainerCtrlModel("div","col-sm-8 position-relative")
    );
}
import React, { useState, Fragment } from "react";
import { Link } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import PermissionName from "../../shared/Permissions";
import TooltipItem from "../../components/tooltip/TooltipItem";
import { emailSendIcon } from "../../shared/AppImages";
import {
  formatAmountValue,
  convertDateTimeString,
  getNoteTooltipHtml,
  hasPermission,
  getBasicToastResponse,
  isNullOrEmpty,
} from "../../shared/utility";
import { ModalType, MailType } from "../../shared/GlobalVar";
import ModalEngine from "../../components/modal/modal-engine";
import ModalInfo from "../../services/entities/models/ModalInfo";
import { showNotification } from "../../components/UI/Toaster/Toaster";
import { createTooltip } from "../../shared/utility/tooltip-utility";

export const SearchActivityGrid = (props) => {
  const [selectedActivityId, setSelectedActivityId] = useState(null);
  const [expandActivityRow, setExpandActivityRow] = useState(false);
  const [modalInfo, setModalInfo] = useState([new ModalInfo()]);
  const closeModal = () => {
    setModalInfo(new ModalInfo());
  };
  const showModal = (modalData) => {
    setModalInfo({
      type: modalData.type,
      showModal: true,
      activityId: modalData.activityId,
      memberId: modalData.memberId,
      borrowerName: modalData.borrowerName,
    });
  };

  const subCategories = [
    "PROFILE_ADD_FREE_CREDIT",
    "PROFILE_REVERT_FREE_CREDIT",
    "RESERVATION_DEBIT_ACCOUNT_CREDITS",
    "RESERVATION_REFUND_ACCOUNT_CREDITS",
    "RESERVATION_VOID_ACCOUNT_CREDITS",
  ];

  const getCopyableText = (activityEntity) => {
    let stringToCopy = "";
    if (activityEntity.SubCategory === "PROFILE_ADD_FREE_CREDIT") {
      stringToCopy = "Granted by: " + activityEntity.CreatedBy;
    } else if (activityEntity.SubCategory === "PROFILE_REVERT_FREE_CREDIT") {
      stringToCopy = "Reversed by: " + activityEntity.CreatedBy;
    } else if (
      activityEntity.SubCategory === "RESERVATION_DEBIT_ACCOUNT_CREDITS" ||
      activityEntity.SubCategory === "RESERVATION_REFUND_ACCOUNT_CREDITS" ||
      activityEntity.SubCategory === "RESERVATION_VOID_ACCOUNT_CREDITS"
    ) {
      stringToCopy =
        (activityEntity.SubCategory === "RESERVATION_DEBIT_ACCOUNT_CREDITS"
          ? "Used By"
          : activityEntity.SubCategory === "RESERVATION_REFUND_ACCOUNT_CREDITS"
          ? "Refunded To"
          : "Voided By") +
        " : " +
        (activityEntity.SubCategory === "RESERVATION_VOID_ACCOUNT_CREDITS"
          ? activityEntity.CreatedBy
          : activityEntity.BorrowerName);
    } else {
      stringToCopy = activityEntity.Comments;
      return stringToCopy;
    }
    stringToCopy =
      stringToCopy +
      "\nAmount: " +
      formatAmountValue(activityEntity.FreeCreditAmount);
    stringToCopy =
      stringToCopy +
      "\nReason: " +
      (activityEntity.ProblemTypeDisplayName
        ? activityEntity.ProblemTypeDisplayName
        : "--") +
      " / " +
      (activityEntity.SubProblemTypeDisplayName
        ? activityEntity.SubProblemTypeDisplayName
        : "--");
    stringToCopy =
      stringToCopy +
      "\nReservation: " +
      activityEntity.ConfirmationKey +
      ", " +
      convertDateTimeString(activityEntity.ReservationStartDate) +
      " to " +
      convertDateTimeString(activityEntity.ReservationEndDate);

    stringToCopy =
      stringToCopy +
      "\n" +
      getformattedActivityLogCommentsForCopy(activityEntity.Comments);

    return stringToCopy;
  };
  const getformattedActivityLogCommentsForCopy = (notes) => {
    let splitWithSpChar = notes ? notes.split("#|#") : "";
    let stringToCopy = "";
    splitWithSpChar.forEach((element) => {
      let splitWithColon = element ? element.split("#:#") : "";
      if (stringToCopy !== "") {
        stringToCopy = stringToCopy + "\n";
        stringToCopy =
          stringToCopy +
          splitWithColon[0].trim() +
          " : " +
          splitWithColon[1].trim();
      } else {
        stringToCopy =
          splitWithColon[0].trim() + " : " + splitWithColon[1].trim();
      }
    });
    return stringToCopy;
  };
  const formatActivityLogComments = (notes, activityId) => {
    let splitWithSpChar = notes ? notes.split("#|#") : "";
    let commentsDiv = [];
    if (splitWithSpChar.length > 0) {
      splitWithSpChar.forEach((element, index) => {
        let splitWithColon = element ? element.split("#:#") : "";
        commentsDiv.push(
          <div className="formRow w-100 pl-4 pb-2" key={index}>
            <span className="font-weight-bold w-35">{splitWithColon[0]}:</span>

            <span id={"note" + index + activityId} className="w-65">
              {createTooltip(splitWithColon[1], splitWithColon[1], {
                forceShow: true,
                isIcon: true,
              })}
            </span>
          </div>
        );
      });
    }
    return commentsDiv;
  };
  const copyToClipboard = (activityEntity) => {
    let tempElement = document.createElement("textarea");
    document.body.appendChild(tempElement);
    tempElement.value = getCopyableText(activityEntity);
    tempElement.select();
    document.execCommand("copy");
    document.body.removeChild(tempElement);
    setTimeout(() => {
      showNotification(
        getBasicToastResponse({ StatusMsg: "Success", StatusCode: 0 })
      );
    }, 1000);
  };
  const toggleExpand = (activityId) => {
    if (selectedActivityId === activityId) {
      setExpandActivityRow(!expandActivityRow);
    } else {
      setSelectedActivityId(activityId);
      setExpandActivityRow(true);
    }
  };

  const getNotesColumsRowHtml = (labelCaption, value, id = 0) => {
    return (
      <Fragment>
        <div className="formRow w-100 pl-4 pb-2">
          <span className="font-weight-bold w-35">{labelCaption}:</span>
          <span id={id} className="w-65">
            {createTooltip(
              labelCaption === "Reservation" ? ReactHtmlParser(value) : value,
              !isNullOrEmpty(id)
                ? ReactHtmlParser(getNoteTooltipHtml(value))
                : ""
            )}
          </span>
        </div>
      </Fragment>
    );
  };

  const getNotesColumnHtml = (activityEntity, isExpand) => {
    let subCategory = activityEntity.SubCategory;
    let reasonText = activityEntity.ProblemTypeDisplayName
      ? activityEntity.SubProblemTypeDisplayName
        ? activityEntity.ProblemTypeDisplayName +
          " / " +
          activityEntity.SubProblemTypeDisplayName
        : activityEntity.ProblemTypeDisplayName
      : "";
    let reservationText = activityEntity.ConfirmationKey
      ? activityEntity.ConfirmationKey + "<br />"
      : "";
    let dateText = activityEntity.ReservationStartDate
      ? activityEntity.ReservationEndDate
        ? convertDateTimeString(activityEntity.ReservationStartDate) +
          " to " +
          convertDateTimeString(activityEntity.ReservationEndDate)
        : convertDateTimeString(activityEntity.ReservationStartDate)
      : "";
    let reservationWithDate =
      reservationText !== "" ? reservationText + dateText : "";
    let notesData;
    if (isExpand) {
      notesData = subCategories.includes(subCategory) ? (
        <div className="formRow ml-0">
          {getNotesColumsRowHtml(
            subCategory === "PROFILE_ADD_FREE_CREDIT"
              ? "Granted by"
              : subCategory === "PROFILE_REVERT_FREE_CREDIT"
              ? "Reversed by"
              : subCategory === "RESERVATION_DEBIT_ACCOUNT_CREDITS"
              ? "Used By"
              : subCategory === "RESERVATION_REFUND_ACCOUNT_CREDITS"
              ? "Refunded To"
              : "Voided By",
            [
              "PROFILE_ADD_FREE_CREDIT",
              "PROFILE_REVERT_FREE_CREDIT",
              "PROFILE_REVERT_FREE_CREDIT",
            ].includes(subCategory)
              ? activityEntity.CreatedBy
              : activityEntity.BorrowerName
          )}
          {getNotesColumsRowHtml(
            "Amount",
            formatAmountValue(activityEntity.FreeCreditAmount)
          )}

          {["PROFILE_ADD_FREE_CREDIT", "PROFILE_REVERT_FREE_CREDIT"].includes(
            subCategory
          )
            ? getNotesColumsRowHtml("Reason", reasonText)
            : ""}
          {reservationWithDate
            ? getNotesColumsRowHtml(
                "Reservation",
                reservationWithDate,
                "reservationInfo" + activityEntity.activityId
              )
            : ""}
          {formatActivityLogComments(
            activityEntity.Comments,
            activityEntity.ActivityId
          )}
        </div>
      ) : (
        <div style={{ whiteSpace: "normal" }}>{activityEntity.Comments}</div>
      );
    } else {
      notesData = subCategories.includes(subCategory) ? (
        <div>
          {activityEntity.ProblemTypeDisplayName ? (
            <Fragment>
              {<b> Reason: </b>}
              {reasonText}
            </Fragment>
          ) : (
            <Fragment>
              {<b> Amount: </b>}
              {formatAmountValue(activityEntity.FreeCreditAmount)}
            </Fragment>
          )}
        </div>
      ) : (
        <div> {activityEntity.Comments} </div>
      );
    }

    return notesData;
  };

  const renderTableData = () => {
    let tableRows = "";
    let collapseHtml = "";
    let expandHtml = "";
    let disabledIconClass = "actExpandIco";
    let activityStatus = "";
    let grayedOutRowStatus = ["PENDING", "CANCELLED"];
    tableRows = props.dataList.map((activityEntity) => {
      activityStatus = activityEntity.Status;
      disabledIconClass = !subCategories.includes(activityEntity.SubCategory)
        ? activityEntity.Comments.length < 100
          ? "actExpandIcoDisabled"
          : "actExpandIco"
        : "actExpandIco";

      expandHtml = getNotesColumnHtml(activityEntity, true);
      collapseHtml = getNotesColumnHtml(activityEntity, false);

      return (
        <div
          className={`row ${
            grayedOutRowStatus.includes(activityStatus) ? "grayed-out-row" : ""
          }`}
          key={activityEntity.ActivityId}
          id={"rowId" + activityEntity.ActivityId}
        >
          <span className="row__column text-center">
            <ul>
              <span>
                <span
                  id={"sendMailId_" + activityEntity.ActivityId}
                  className={
                    activityEntity.SubCategory === "PROFILE_ADD_FREE_CREDIT"
                      ? ""
                      : "displayNone"
                  }
                >
                  {createTooltip(
                    <img
                      style={{
                        height: "16px",
                        width: "16px",
                        marginRight: "3px",
                        cursor: "pointer",
                        pointerEvent:
                          activityEntity.SubCategory ===
                          "PROFILE_ADD_FREE_CREDIT"
                            ? ""
                            : "none",
                      }}
                      src={emailSendIcon}
                      alt="u"
                      onClick={(event) =>
                        showModal({
                          type: ModalType.MAIL_MODAL,
                          showModal: true,
                          activityId: activityEntity.ActivityId,
                          borrowerName: activityEntity.BorrowerName,
                        })
                      }
                    />,
                    "Send Mail",
                    { forceShow: true }
                  )}
                </span>
              </span>
            </ul>
          </span>
          <span className="row__column">
            {convertDateTimeString(activityEntity.CreationDate)}
          </span>
          <span
            className="row__column"
            data-toggle="modal"
            data-target="#primarymodal"
            id={"activityId_" + activityEntity.ActivityId}
          >
            {hasPermission(PermissionName.VIEW_MEMBER)
              ? createTooltip(
                  <label
                    className="pseudo-link"
                    id={"activityId_" + activityEntity.ActivityId}
                    onClick={(event) =>
                      showModal({
                        type: ModalType.MEMBER_QUICK_MODAL,
                        showModal: true,
                        memberId: activityEntity.CreatedById,
                      })
                    }
                  >
                    {activityEntity.CreatedBy}
                  </label>,
                  activityEntity.CreatedBy
                )
              : createTooltip(
                  <label id={"activityId_" + activityEntity.ActivityId}>
                    {activityEntity.CreatedBy}
                  </label>,
                  activityEntity.CreatedBy
                )}
          </span>

          <span className="row__column d-flex">
            <span style={{ width: "95%" }}>
              <Link
                className={`${
                  hasPermission(PermissionName.VIEW_RESERVATION)
                    ? ""
                    : "noAnchorLink"
                }`}
                to={{
                  pathname: "/reservationSummary",
                  search: "?reservationId=" + activityEntity.ConfirmationKey,
                  state: { breadCrumbList: props.breadCrumbList },
                }}
              >
                {createTooltip(
                  activityEntity.ConfirmationKey || "--",
                  activityEntity.ConfirmationKey || ""
                )}
              </Link>
            </span>
            {createTooltip(
              <span
                className={`filled-circle position-relative`}
                style={{
                  right: "0rem",
                  marginRight: "0.2rem",
                }}
              >
                $
              </span>,
              "Prepaid",
              { forceShow: true, isIcon: true }
            )}
          </span>
          <span
            className={`row__column text-center resStatus ${activityEntity.ReservationStatus}`}
          >
            {activityEntity.ReservationStatus || "--"}
          </span>
          <span className="row__column">
            {activityEntity.CategoryDisplayText}
          </span>
          <span
            className="row__column"
            id={"dubCategoryDisplayText" + activityEntity.ActivityId}
          >
            {createTooltip(
              activityEntity.SubCategoryDisplayText || "--",
              activityEntity.SubCategoryDisplayText
            )}
          </span>
          <span className="row__column">
            {activityEntity.ChannelTypeDisplayText || "--"}
          </span>
          <span className="row__column">
            {activityEntity.CallCategoryDisplayText || "--"}
          </span>
          <span className="row__column text-center">
            {activityEntity.Priority || "--"}
          </span>
          <span className={`row__column d-flex`}>
            {createTooltip(
              <i
                id={"expandId_" + activityEntity.ActivityId}
                className={`${disabledIconClass}  ${
                  expandActivityRow
                    ? activityEntity.ActivityId === selectedActivityId
                      ? "fa fa-minus-square"
                      : "fa fa-plus-square"
                    : "fa fa-plus-square"
                }`}
                onClick={(event) => toggleExpand(activityEntity.ActivityId)}
              />,
              expandActivityRow
                ? activityEntity.ActivityId === selectedActivityId
                  ? "Collapse"
                  : "Expand"
                : "Expand",
              { forceShow: true, isIcon: true }
            )}
            {createTooltip(
              <i
                id={"copyToClipboardId_" + activityEntity.ActivityId}
                className="fa fa-copy actCopyIco"
                onClick={(event) => copyToClipboard(activityEntity)}
              />,
              "Copy To Clipboard",
              { forceShow: true, isIcon: true }
            )}
            {expandActivityRow
              ? activityEntity.ActivityId === selectedActivityId
                ? expandHtml
                : collapseHtml
              : collapseHtml}
          </span>
          {grayedOutRowStatus.includes(activityStatus) ? (
            <TooltipItem
              placement="bottom"
              toolTipId={"rowId" + activityEntity.ActivityId}
              renderableHtml={ReactHtmlParser(
                getNoteTooltipHtml(
                  activityStatus === "PENDING"
                    ? "The credit grant is in PENDING state"
                    : "The credit grant is in CANCELLED state"
                )
              )}
              forceShow={true}
            />
          ) : (
            ""
          )}
        </div>
      );
    });
    return (
      <Fragment>
        <div className="datagrid">
          <div className="column">
            <span className="column__header" />
            <span className="column__header">Activity Date/Time</span>
            <span className="column__header">Created by</span>
            <span className="column__header">Res. Code</span>
            <span className="column__header">Res.Status</span>
            <span className="column__header">Category</span>
            <span className="column__header">Subcategory</span>
            <span className="column__header">Call Channel</span>
            <span className="column__header">Call Type</span>
            <span className="column__header">Priority</span>
            <span className="column__header">Comments</span>
          </div>
          {tableRows}
        </div>
        {modalInfo.showModal ? (
          <ModalEngine
            modalType={modalInfo.type}
            showModal={modalInfo.showModal}
            closeModal={closeModal}
            memberId={modalInfo.memberId}
            defaultData={{
              activityId: modalInfo.activityId,
              mailType: MailType.EMAIL_ACTIVITY,
              borrowerName: modalInfo.borrowerName,
            }}
          />
        ) : null}
      </Fragment>
    );
  };
  return renderTableData();
};
export default SearchActivityGrid;

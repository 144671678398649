import React, { useState, useEffect, useCallback, Fragment } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Draggable from "react-draggable";
import {
  loadFilterCtrls,
  getDefaultFilterStates,
  getAllFilterStates,
} from "./filter-helper";
export const SearchFilterModal = (props) => {
  const [reservationStates, setReservationStates] = useState(
    props.reservationStates
  );
  const [filterCtrl, setFilterCtrl] = useState(loadFilterCtrls(props.pageName));
  useEffect(() => {
    updateSearchFilterModal();
  }, []);
  useEffect(() => {
    updateSearchFilterModal();
  }, [reservationStates]);
  const updateSearchFilterModal = () => {
    let selectAllFilterctrls = [...filterCtrl];
    let modalReservationStates = reservationStates
      ? reservationStates.split(",")
      : null;
    if (modalReservationStates) {
      selectAllFilterctrls.map((filterInfo, filterIndex) => {
        Object.keys(filterInfo.FilterStates).map((key, index) => {
          let isFound = false;
          modalReservationStates.map((item, i) => {
            if (item === key) {
              isFound = true;
              return isFound;
            }
          });
          selectAllFilterctrls[filterIndex]["FilterStates"][
            key
          ].elementConfig.checked = isFound;
        });
      });
      setFilterCtrl(selectAllFilterctrls);
    } else {
      clearAll();
    }
  };
  const selectAll = () => {
    setReservationStates(getAllFilterStates(props.url));
  };
  const clearAll = () => {
    let selectAllFilterctrls = [...filterCtrl];
    selectAllFilterctrls.map((filterInfo, filterIndex) => {
      Object.keys(filterInfo.FilterStates).map((key, index) => {
        selectAllFilterctrls[filterIndex]["FilterStates"][
          key
        ].elementConfig.checked = false;
      });
    });
    setFilterCtrl(selectAllFilterctrls);
    setReservationStates(null);
  };
  const resetAll = () => {
    setReservationStates(getDefaultFilterStates(props.url));
  };
  const applyFilterFromModal = () => {
    props.applyFilterFromModal(reservationStates);
  };
  const inputChangedHandler = (event, value) => {
    let modalReservationStates = reservationStates || null;
    if (event.target.checked) {
      if (modalReservationStates) {
        modalReservationStates = modalReservationStates + "," + value;
      } else {
        modalReservationStates = value;
      }
      setReservationStates(modalReservationStates);
    } else {
      let newResStates = "";
      if (modalReservationStates) {
        modalReservationStates.split(",").map((item, i) => {
          if (item !== value) {
            if (newResStates) {
              newResStates = newResStates + "," + item;
            } else {
              newResStates = item;
            }
          }
        });
        setReservationStates(newResStates);
      }
    }
  };
  const getFilterList = (event) => {
    const fltCtrlsList = [...filterCtrl];
    return fltCtrlsList.map((filterInfo, filterIndex) => {
      return (
        <Fragment key={"filterIndex" + filterIndex}>
          {fltCtrlsList.length > 0 ? (
            <h3>{filterInfo.Name + " Filters:"}</h3>
          ) : (
            ""
          )}
          <div className="searchFilterPopup__filter">
            {Object.keys(filterInfo.FilterStates).map((key, index) => {
              let filter = filterInfo.FilterStates[key];
              return (
                <div className="mb-2" key={filter.id + index}>
                  <div className="icheck-primary">
                    <input
                      type="checkbox"
                      id={filter.id + index}
                      name={filter.elementConfig.name}
                      checked={filter.elementConfig.checked}
                      onChange={(event) =>
                        inputChangedHandler(event, filter.elementConfig.value)
                      }
                    />
                    <label
                      htmlFor={filter.id + index}
                      className={filter.labelInput.className}
                    >
                      <span>{filter.labelInput.labeltext}</span>
                    </label>
                  </div>
                </div>
              );
            })}
          </div>
        </Fragment>
      );
    });
  };
  return (
    <Draggable handle=".cursor">
      <Modal
        isOpen={props.showModal}
        className="no-cursor"
        style={{ minWidth: "50rem" }}
        toggle={() => {
          props.closeModal();
        }}
      >
        <ModalHeader
          className="modal-header bg-primary modal-title text-white cursor"
          toggle={() => {
            props.closeModal();
          }}
        >
          <i className="fas fa-filter pr-2"></i>
          Apply Filter
        </ModalHeader>
        <ModalBody>
          <div className="searchFilterPopup p-0">
            <div className="text-center searchFilterPopup__link">
              <span
                className="pseudo-link pr-5"
                onClick={(event) => selectAll()}
              >
                Select All
              </span>
              <span
                className="pseudo-link pr-5"
                onClick={(event) => clearAll()}
              >
                Clear All
              </span>
              <span className="pseudo-link" onClick={(event) => resetAll()}>
                Reset
              </span>
            </div>
            <div className="row p-0">{getFilterList()}</div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            name="btnAddNewMember"
            className="btn btn-primary waves-effect waves-light btnSize ml-2"
            onClick={(event) => applyFilterFromModal()}
          >
            Apply
          </button>
          <button
            type="button"
            name="btnCancel"
            className={`btn btn-secondary waves-effect waves-light btnSize ml-2`}
            onClick={(event) => props.closeModal()}
          >
            Cancel
          </button>
        </ModalFooter>
      </Modal>
    </Draggable>
  );
};
export default SearchFilterModal;

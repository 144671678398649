import PageInfo from "../../../services/entities/models/PageInfo";
import {
  getAssetAvailabilityStatus,
  getAssetTypesOrGroup,
  getVehicleBuilders,
} from "../vehicle-helper";
import { getCurrentServerTimezone } from "../../../shared/utility";
export const getVehicleSearchCtrls = () => {
  const makeList = getVehicleBuilders();
  return {
    isAdvancedSearch: false,
    PageInfo: new PageInfo(null, null, "CreatedOn", "DESC"),
    UnitNumber: {
      id: "UnitNumber",
      elementType: "input",
      elementConfig: {
        name: "UnitNumber",
        type: "text",
      },
      validation: {
        required: false,
      },
      labelInput: {
        labeltext: "Vehicle Id",
      },
      value: "",
      valid: false,
      touched: false,
      basicOrder: 1,
      advancedOrder: 6,
    },
    Vin: {
      id: "Vin",
      elementType: "input",
      elementConfig: {
        name: "Vin",
        type: "text",
      },
      validation: {
        required: false,
      },
      labelInput: {
        labeltext: "Vin",
      },
      value: "",
      valid: false,
      touched: false,
      basicOrder: 2,
      advancedOrder: 14,
    },
    PersonAssetAvailabilityStatus: {
      id: "PersonAssetAvailabilityStatus",
      elementType: "select",
      elementConfig: {
        name: "PersonAssetAvailabilityStatus",
        options: getAssetAvailabilityStatus(),
      },
      labelInput: {
        labeltext: "Status",
      },
      value: "",
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      basicOrder: 3,
      advancedOrder: 3,
    },
    SelectedChildOrgUnitId: {
      id: "SelectedChildOrgUnitId",
      elementType: "select",
      elementConfig: {
        name: "SelectedChildOrgUnitId",
        options: [{ key: "", displayValue: "Any Location" }],
      },
      labelInput: {
        labeltext: "Location",
      },
      value: "",
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      basicOrder: 4,
      advancedOrder: 4,
    },
    Make: {
      id: "Make",
      elementType: "select",
      elementConfig: {
        name: "Make",
        options: makeList,
      },
      labelInput: {
        labeltext: "Make",
      },
      value: "",
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      basicOrder: 6,
      advancedOrder: 2,
    },
    ClassCode: {
      id: "ClassCode",
      elementType: "select",
      elementConfig: {
        name: "ClassCode",
        options: [{ key: "", displayValue: "All" }],
      },
      labelInput: {
        labeltext: "Vehicle Category",
      },
      value: "",
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      basicOrder: 9,
      advancedOrder: 9,
    },
    AssetTypes: {
      id: "AssetTypes",
      elementType: "select",
      elementConfig: {
        name: "AssetTypes",
        options: getAssetTypesOrGroup(),
      },
      labelInput: {
        labeltext: "Type",
      },
      value: "",
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      basicOrder: 0,
      advancedOrder: 1,
    },
    StartDate: {
      id: "StartDate",
      elementType: "dateandtimepicker",
      elementConfig: {
        name: "StartDate",
        type: "text",
      },
      timePicker: {
        id: "StartTime",
        name: "StartTime",
        value: "",
        elementType: "timepicker",
      },
      validation: {
        required: false,
      },
      labelInput: {
        labeltext: "Search Begin",
      },
      timeZoneString: getCurrentServerTimezone(),
      value: "",
      valid: false,
      touched: false,
      basicOrder: 0,
      advancedOrder: 8,
    },
    EndDate: {
      id: "EndDate",
      elementType: "dateandtimepicker",
      elementConfig: {
        name: "EndDate",
        type: "text",
      },
      timePicker: {
        id: "EndTime",
        name: "EndTime",
        value: "",
        elementType: "timepicker",
      },
      validation: {
        required: false,
      },
      labelInput: {
        labeltext: "Search End",
      },
      timeZoneString: getCurrentServerTimezone(),
      value: "",
      valid: false,
      touched: false,
      basicOrder: 0,
      advancedOrder: 13,
    },

    Model: {
      id: "Model",
      elementType: "input",
      elementConfig: {
        name: "Model",
        type: "text",
      },
      validation: {
        required: false,
      },
      labelInput: {
        labeltext: "Model",
      },
      value: "",
      valid: false,
      touched: false,
      basicOrder: 7,
      advancedOrder: 7,
    },

    VehicleYear: {
      id: "VehicleYear",
      elementType: "input",
      elementConfig: {
        name: "VehicleYear",
        type: "number",
      },
      validation: {
        required: false,
      },
      labelInput: {
        labeltext: "Year",
      },
      value: "",
      valid: false,
      touched: false,
      basicOrder: 8,
      advancedOrder: 12,
    },

    PersonAssetName: {
      id: "PersonAssetName",
      elementType: "input",
      elementConfig: {
        name: "PersonAssetName",
        type: "text",
      },
      validation: {
        required: false,
      },
      labelInput: {
        labeltext: "Vehicle Name",
      },
      value: "",
      valid: false,
      touched: false,
      basicOrder: 0,
      advancedOrder: 11,
    },
    OnlyShownInJsiMarketPlace: {
      value: false,
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      basicOrder: 0,
      advancedOrder: 0,
    },
    ShowOffline: {
      value: false,
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      basicOrder: 0,
      advancedOrder: 0,
    },
    RideLinkInstalled: {
      value: false,
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      basicOrder: 0,
      advancedOrder: 0,
    },
    btnResSearch: {
      id: "btnResSearch",
      elementType: "button",
      elementConfig: {
        type: "submit",
        value: "Search",
        name: "btnResSearch",
        className: "btn btn-primary search-btn",
      },
      basicOrder: 5,
      advancedOrder: 5,
    },
    btnResSearchReset: {
      id: "btnResSearchReset",
      elementType: "button",
      elementConfig: {
        type: "button",
        value: "Reset",
        name: "btnResSearchReset",
        className: "btn btn-secondary search-btn",
      },
      basicOrder: 10,
      advancedOrder: 10,
    },
    empty: {
      id: "empty",
      elementType: "empty",
      elementConfig: {
        name: "",
        type: "empty",
      },
      basicOrder: 0,
      advancedOrder: 15,
    },
    empty1: {
      id: "empty",
      elementType: "empty",
      elementConfig: {
        name: "",
        type: "empty",
      },
      basicOrder: 0,
      advancedOrder: 20,
    },
  };
};
export const getSearchVehicleBottomRadiolist = () => {
  return [
    {
      id: "OnlyShownInJsiMarketPlace",
      elementType: "checkbox",
      elementConfig: {
        name: "OnlyShownInJsiMarketPlace",
        type: "checkbox",
      },
      labelInput: {
        labeltext: " Listed in JustShareIt.com",
        labelFor: "OnlyShownInJsiMarketPlace",
      },
      value: "",
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
    },
    {
      id: "ShowOffline",
      elementType: "checkbox",
      elementConfig: {
        name: "ShowOffline",
        type: "checkbox",
      },
      labelInput: {
        labeltext: "Offline",
        labelFor: "ShowOffline",
      },
      value: "",
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
    },
    {
      id: "RideLinkInstalled",
      elementType: "checkbox",
      elementConfig: {
        name: "RideLinkInstalled",
        type: "checkbox",
        labelFor: "RideLinkInstalled",
      },
      labelInput: {
        labeltext: "Ridelink",
      },
      value: "",
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
    },
  ];
};

export const getHintQuestionList = () => {
  return [
    { key: "", displayValue: "Select" },
    {
      key: "What is the name of the hospital in which you were born?",
      displayValue: "What is the name of the hospital in which you were born?",
    },
    {
      key: "What is your eldest brother's middle name?",
      displayValue: "What is your eldest brother's middle name?",
    },
    {
      key: "What is your eldest child's middle name?",
      displayValue: "What is your eldest child's middle name?",
    },
    {
      key: "What is your eldest sister's middle name?",
      displayValue: "What is your eldest sister's middle name?",
    },
    {
      key: "What is your father's middle name",
      displayValue: "What is your father's middle name?",
    },
    {
      key: "What is your mother's maiden name?",
      displayValue: "What is your mother's maiden name?",
    },
    {
      key: "What is your mother's middle name?",
      displayValue: "What is your mother's middle name?",
    },
    {
      key: "What was the color of your first car?",
      displayValue: "What was the color of your first car?",
    },
    {
      key: "What was your childhood nickname?",
      displayValue: "What was your childhood nickname?",
    },
    {
      key: "What was your favorite childhood game?",
      displayValue: "What was your favorite childhood game?",
    },
    {
      key: "What was your favorite childhood toy?",
      displayValue: "What was your favorite childhood toy?",
    },
    {
      key: "What was your favorite schoolteacher's name?",
      displayValue: "What was your favorite schoolteacher's name?",
    },
    {
      key: "What was your favorite subject at school?",
      displayValue: "What was your favorite subject at school?",
    },
    {
      key: "What was your first pet's name?",
      displayValue: "What was your first pet's name?",
    },
  ];
};

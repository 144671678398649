import React, { useState } from "react";
import { JSILOGO } from "../../shared/AppImages";
import moment from "moment";
import { convertDateTimeString } from "../../shared/utility";
import ModalInfo from "../../services/entities/models/ModalInfo";
import { ModalType } from "../../shared/GlobalVar";
import ModalEngine from "../modal/modal-engine";

const Footer = (props) => {
  const [modalInfo, setModalInfo] = useState([new ModalInfo()]);
  const isImpersonateMode = localStorage.getItem("loginMode") === "IMPERSONATE";

  const closeModal = () => {
    setModalInfo(new ModalInfo());
  };

  const showModal = (modalData) => {
    setModalInfo({
      type: modalData.type,
      showModal: true,
    });
  };

  let lastIpAddress = localStorage.getItem("lastIpAddress")
    ? JSON.parse(localStorage.getItem("lastIpAddress"))
    : null;
  let lastLogin = localStorage.getItem("lastLogin")
    ? JSON.parse(localStorage.getItem("lastLogin"))
    : null;
  let totalLoginCount = localStorage.getItem("totalLoginCount")
    ? JSON.parse(localStorage.getItem("totalLoginCount"))
    : null;

  return (
    <footer className={`footer left-0 ${isImpersonateMode ? "impersonate-border" : ""}`}>
      <div className="footerBarSection">
        <ol className="footerBar pl-3" style={{ justifySelf: "start" }}>
          <span className="font-weight-bold">Powered by</span>
          <li
            style={{ cursor: "pointer", paddingLeft: "0.5rem" }}
            onClick={() =>
              showModal({
                type: ModalType.APPLICATION_BUILD_INFO_MODAL,
              })
            }
          >
            <img id="aboutJustShareIt" src={JSILOGO} alt="JustShareIt Inc" />
          </li>
        </ol>
        <ol className="footerBar">
          <li>
            <span className="font-weight-bold">Last IP -</span>
            <span>{lastIpAddress}</span>
          </li>
          <span className="verticalLineSeparator"></span>
          <li>
            <span className="font-weight-bold">Last Login -</span>
            <span>
              {moment(convertDateTimeString(lastLogin)).format(
                "ddd, DD MMM YYYY"
              ) +
                " at " +
                moment(convertDateTimeString(lastLogin)).format("LT")}
            </span>
          </li>
          <span className="verticalLineSeparator"></span>
          <li>
            <span className="font-weight-bold">Login Count -</span>
            <span>{totalLoginCount}</span>
          </li>
        </ol>
      </div>
      {modalInfo.showModal ? (
        <ModalEngine
          modalType={modalInfo.type}
          showModal={modalInfo.showModal}
          closeModal={closeModal}
        />
      ) : null}
    </footer>
  );
};

export default Footer;

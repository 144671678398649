import React, { useState, Fragment } from "react";
import ReactHtmlParser from "react-html-parser";
import { createPricingObj } from "./PricingData";
import "./pricing.scss";
import { formatCurrency } from "../../shared/utility";
const PricingTable = (props) => {
  const [discountType, setDiscountType] = useState(null);
  const discountHandler = (event, ctrlName) => {
    if (ctrlName === "DiscountType" && event.target.value === "CODE") {
      setDiscountType("CODE");
    } else if (
      ctrlName === "DiscountType" &&
      discountType === "CODE" &&
      event.target.value !== "CODE"
    ) {
      setDiscountType(null);
    }
  };
  const getRentalInfo = (pricingDataObj) => {
    let rentalData = pricingDataObj.rentalInfo.data;
    let mileageData = pricingDataObj.rentalInfo.mileage;
    let rentalInfo = Object.keys(rentalData).map((dataKey, index) => {
      let data = rentalData[dataKey];
      return renderChargeFullRow(
        data.title,
        data.unit || "\u00A0",
        data.charge ? data.charge.toFixed(2) : "0.00",
        data.total ? data.total.toFixed(2) : "0.00",
        index
      );
    });
    let rentalInfoObj = titleValuePair(
      "Vehicle Rental Subtotal",
      pricingDataObj.rentalInfo.rentalTotal
    );
    let subtotal = (
      <Fragment>
        {rentalInfo}
        {mileageData.extensionPrice && mileageData.extensionPrice.total > 0
          ? getExtensionRow(pricingDataObj)
          : ""}
        {(mileageData.excessMileage && mileageData.excessMileage.total) ||
        (mileageData.chargesForMiles && mileageData.chargesForMiles.total)
          ? getMileageInfo(pricingDataObj)
          : ""}
        {pricingDataObj.rentalInfo && pricingDataObj.rentalInfo.rentalTotal
          ? renderChargeAndTotalColumn(rentalInfoObj)
          : ""}
      </Fragment>
    );
    return subtotal;
  };
  const getExtensionRow = (pricingDataObj) => {
    let rentalData = pricingDataObj.mileage.extensionPrice;
    return (
      <div className="pricing-row">
        <div className="pricing-full-col pricingRelative">
          <span>{rentalData.title}</span>
        </div>
      </div>
    );
  };
  const getMileageInfo = (pricingDataObj) => {
    let rentalData = pricingDataObj.rentalInfo.mileage;
    let rentalInfo = Object.keys(rentalData).map((dataKey, index) => {
      let data = rentalData[dataKey];
      if (
        dataKey === "milesIn" ||
        dataKey === "milesOut" ||
        dataKey === "milesUsed"
      ) {
        return renderChargeFullRow(data.title, data.unit, "", "", index);
      }
    });
    let subtotal = (
      <Fragment>
        {rentalInfo}
        {rentalData.excessMileage
          ? renderChargeFullRow(
              rentalData.excessMileage.title,
              "",
              rentalData.excessMileage.charge.toFixed(2),
              rentalData.excessMileage.total.toFixed(2),
              0
            )
          : ""}
        {rentalData.chargesForMiles
          ? renderChargeFullRow(
              rentalData.chargesForMiles.title,
              "",
              rentalData.chargesForMiles.charge.toFixed(2),
              rentalData.chargesForMiles.total.toFixed(2),
              0
            )
          : ""}
      </Fragment>
    );
    return subtotal;
  };
  const getCommonRow = (data, index, showPercent, showNegative) => {
    return renderChargeFullRow(
      ReactHtmlParser(data.title),
      data.unit || "\u00A0",
      data.charge ? data.charge.toFixed(2) : 0,
      data.total ? data.total.toFixed(2) : 0,
      index || data.title + "key",
      showPercent,
      showNegative
    );
  };
  const getCommonSummaryRow = (data, index, showNegative) => {
    return (
      <Fragment>
        {renderChargeAndTotalColumn(
          data,
          showNegative,
          index || data.title + "key",
          ""
        )}
      </Fragment>
    );
  };
  const titleCol = "pricing-charge-col pricingRelative";
  const unitCol = "pricing-unit-col";
  const rateCol = "pricing-rate-col";
  const totalCol = "pricing-total-col";
  const col1 = "col-lg-1";
  const col2 = "col-lg-2";
  const col3 = "col-lg-3";
  const col12 = "col-lg-12";
  const renderChargeAndTotalColumn = (
    data,
    showNegative,
    index,
    parentClass = "pricingBold"
  ) => {
    return (
      <div className={`pricing-row ${parentClass}`} key={index}>
        <div className="pricing-full-charge-col pricingRelative">
          <span className="pricingLeft1">{ReactHtmlParser(data.title)}</span>
        </div>
        <div className="pricing-full-total-col">
          <span className="pricingRight">
            {showNegative || data.total < 0
              ? "(" + formatCurrency(Math.abs(data.total).toFixed(2)) + ")"
              : formatCurrency(Math.abs(data.total).toFixed(2))}
          </span>
        </div>
        {data && data.removable ? (
          <i
            className="fa fa-minus position-absolute input-search-ico-right-price"
            onClick={(event) => props.removeChargeLineItem(data)}
          />
        ) : (
          ""
        )}
        <div className="pricingClearBoth" />
      </div>
    );
  };
  const renderChargeFullRow = (
    title,
    unitValue,
    rateValue,
    totalValue,
    index,
    showPercent,
    showNegative
  ) => {
    return (
      <div className="pricing-row" key={index}>
        <div className={titleCol}>
          <span className="pricingLeft1">{title}</span>
        </div>
        <div className={unitCol}>{unitValue}</div>
        <div className={rateCol}>
          {rateValue
            ? showNegative
              ? "(" + showPercent
                ? rateValue + "%"
                : formatCurrency(rateValue) + ")"
              : showPercent
              ? rateValue + "%"
              : formatCurrency(rateValue)
            : ""}
        </div>
        <div className={totalCol}>{formatCurrency(totalValue)}</div>
        <div className="pricingClearBoth" />
      </div>
    );
  };
  const getTotalRow = (data, showNegative) => {
    return renderChargeAndTotalColumn(data, showNegative);
  };
  const titleValuePair = (title, value) => {
    return {
      title: title,
      total: value || 0,
    };
  };
  const getCreditsData = (pricingDataObj) => {
    let creditsData;
    let freCredits;
    let creditTotal = 0;
    if (pricingDataObj.credits.length > 0) {
      creditsData = pricingDataObj.credits.map((data, index) => {
        creditTotal = creditTotal + data.total;
        return data.total > 0 ? getCommonSummaryRow(data, index, true) : "";
      });
    }
    if (pricingDataObj.freeCredit && pricingDataObj.freeCredit.total > 0) {
      creditTotal = creditTotal + pricingDataObj.freeCredit.total;
      freCredits = getCommonSummaryRow(pricingDataObj.freeCredit, null, true);
    }
    let totalCredits = titleValuePair("Total Credits", creditTotal);
    return (
      <Fragment>
        {creditsData}
        {freCredits}
        {creditTotal && creditTotal > 0 ? getTotalRow(totalCredits, true) : ""}
      </Fragment>
    );
  };
  const getMiscellaneouCharges = (pricingDataObj) => {
    let miscellaneousCharges;
    let chargeTotal = 0;
    if (pricingDataObj.miscellaneousCharges.length > 0) {
      miscellaneousCharges = pricingDataObj.miscellaneousCharges.map(
        (data, index) => {
          chargeTotal = chargeTotal + data.total;
          return data.total > 0 ? getCommonSummaryRow(data, index, false) : "";
        }
      );
    }
    return <Fragment>{miscellaneousCharges}</Fragment>;
  };

  const pricingData = props.pricingData || createPricingObj();
  let taxableAddons;
  let discount;
  let taxes;
  let surCharges;
  let addOns;
  if (pricingData.discount && pricingData.discount.total > 0) {
    discount = getCommonRow(pricingData.discount, false, true);
  }
  if (pricingData.subTotalInfo.taxableAddOns.length > 0) {
    taxableAddons = pricingData.subTotalInfo.taxableAddOns.map(
      (data, index) => {
        return getCommonSummaryRow(data, index);
      }
    );
  }
  if (pricingData.taxes.length > 0) {
    taxes = pricingData.taxes.map((data, index) => {
      return getCommonRow(
        data,
        index,
        data.unittype === "PERCENT" ? true : false
      );
    });
  }
  if (pricingData.surcharges.length > 0) {
    surCharges = pricingData.surcharges.map((data, index) => {
      return getCommonRow(
        data,
        index,
        data.unittype === "PERCENT" ? true : false
      );
    });
  }
  let renderableTaxSurChargeDisount;
  if (
    pricingData.applyTaxAfterDiscount &&
    pricingData.applySurchargeAfterDiscount
  ) {
    renderableTaxSurChargeDisount = (
      <Fragment>
        {discount}
        {taxableAddons}
        {taxes}
        {surCharges}
      </Fragment>
    );
  } else if (
    pricingData.applyTaxAfterDiscount &&
    !pricingData.applySurchargeAfterDiscount
  ) {
    renderableTaxSurChargeDisount = (
      <Fragment>
        {surCharges}
        {discount}
        {taxableAddons}
        {taxes}
      </Fragment>
    );
  } else if (
    !pricingData.applyTaxAfterDiscount &&
    pricingData.applySurchargeAfterDiscount
  ) {
    renderableTaxSurChargeDisount = (
      <Fragment>
        {taxableAddons}
        {taxes}
        {discount}
        {surCharges}
      </Fragment>
    );
  } else {
    renderableTaxSurChargeDisount = (
      <Fragment>
        {taxableAddons}
        {taxes}
        {surCharges}
        {discount}
      </Fragment>
    );
  }
  if (pricingData.subTotalInfo.addOns.length > 0) {
    addOns = pricingData.subTotalInfo.addOns.map((data, index) => {
      return getCommonSummaryRow(data, index);
    });
  }
  if (props.miscellaneous && pricingData.miscellaneous.addOns.length > 0) {
    props.miscellaneous.addOns.map((data, index) => {
      return getCommonRow(data, index);
    });
  }
  const pricingTable = (
    <Fragment>
      <div className={props.isPaddingRequired ? col12 : col12 + " p-0"}>
        <div className="pricing-header" style={{ minHeight: "2.5rem" }}>
          <div className={titleCol}>Charge</div>
          <div className={unitCol}>Unit</div>
          <div className={rateCol}>Rate</div>
          <div className={totalCol}>Total</div>
        </div>
        <div className="pricing-body">
          {pricingData.rentalInfo ? getRentalInfo(pricingData) : null}
          {renderableTaxSurChargeDisount}
          {addOns}
          {pricingData.subTotalInfo.liability
            ? getCommonSummaryRow(pricingData.subTotalInfo.liability)
            : ""}
          {pricingData.subTotalInfo.CDW
            ? getCommonSummaryRow(pricingData.subTotalInfo.CDW)
            : ""}
          {pricingData.subTotalInfo.ageBasedInsurance
            ? getCommonSummaryRow(pricingData.subTotalInfo.ageBasedInsurance)
            : ""}
          {pricingData.otherChargesInfo.firstTimeProcessingFee
            ? getCommonRow(pricingData.otherChargesInfo.firstTimeProcessingFee)
            : ""}
          {pricingData.otherChargesInfo.reservationProcessingFee
            ? getCommonRow(
                pricingData.otherChargesInfo.reservationProcessingFee
              )
            : ""}
          {pricingData.otherChargesInfo.extensionFee
            ? getCommonRow(pricingData.otherChargesInfo.extensionFee)
            : ""}
          {pricingData.otherChargesInfo.charity
            ? getCommonRow(pricingData.otherChargesInfo.charity)
            : ""}
          {pricingData.otherChargesInfo.additionalDriverCharge
            ? getCommonRow(pricingData.otherChargesInfo.additionalDriverCharge)
            : ""}
          {pricingData.otherChargesInfo.damageCharge
            ? getCommonRow(pricingData.otherChargesInfo.damageCharge)
            : ""}
          {pricingData.otherChargesInfo.lateCharges
            ? getCommonRow(pricingData.otherChargesInfo.lateCharges)
            : ""}
          {getMiscellaneouCharges(pricingData)}
          {pricingData.otherChargesInfo.additionalChargeInfo
            ? getCommonRow(
                pricingData.otherChargesInfo.lateCharges,
                null,
                pricingData.otherChargesInfo.additionalChargeInfo.unittype ===
                  "PERCENT"
                  ? true
                  : false,
                false
              )
            : ""}
          {getTotalRow(pricingData.totalCharges)}
          {pricingData.cancellationChargesInfo &&
          pricingData.cancellationChargesInfo.total > 0
            ? getCommonSummaryRow(
                pricingData.cancellationChargesInfo,
                null,
                true
              )
            : ""}
          {pricingData.cancellationFeeInfo &&
          pricingData.cancellationFeeInfo.total > 0
            ? getCommonSummaryRow(pricingData.cancellationFeeInfo)
            : ""}
          {getCreditsData(pricingData)}
          {pricingData.payments && pricingData.payments.total !== 0
            ? getCommonSummaryRow(pricingData.payments, null, true)
            : ""}
          {pricingData.totalAmountCaptured &&
          pricingData.totalAmountCaptured.total > 0
            ? getCommonSummaryRow(pricingData.totalAmountCaptured, null, true)
            : ""}
          {pricingData.preAuthReleaseAmount &&
          pricingData.preAuthReleaseAmount.total > 0
            ? getCommonSummaryRow(pricingData.preAuthReleaseAmount, null, false)
            : ""}
          {pricingData.preAuthAmount && pricingData.preAuthAmount.total > 0
            ? getCommonSummaryRow(pricingData.preAuthAmount, null, true)
            : ""}
          {pricingData.refunds && pricingData.refunds.total !== 0
            ? getCommonSummaryRow(pricingData.refunds, null, false)
            : ""}
          {pricingData.voidedRefunds && pricingData.voidedRefunds.total !== 0
            ? getCommonSummaryRow(pricingData.voidedRefunds, null, false)
            : ""}
          {pricingData.voidedPayments && pricingData.voidedPayments.total !== 0
            ? getCommonSummaryRow(pricingData.voidedPayments, null, false)
            : ""}
          {pricingData.totalPaymentsWithCredits &&
          pricingData.totalPaymentsWithCredits.total !== 0
            ? getTotalRow(pricingData.totalPaymentsWithCredits, true)
            : ""}
          {pricingData.balanceDue
            ? getTotalRow(pricingData.balanceDue, false)
            : ""}
        </div>
      </div>
      {props.isChregesCreditInputRequired ? (
        <div className={col12 + " p-0"}>
          <form
            id="frmAppliedCreditChargesLineItem"
            onSubmit={props.submitMiscellaneousChargesCreditsHandler}
          >
            <h4 className="form-header text-uppercase">
              <i className="fas fa-user-circle" />
              Miscellaneous Charges/Credits
            </h4>
            <div className="form-group row">
              <div className={`${col3} pr-0`}>
                <select name="ChargeType" required>
                  <option value="CHARGE">Charge</option>
                  <option value="CREDIT">Credit</option>
                </select>
              </div>
              <div className={`${col3} pr-0`}>
                <input
                  type="text"
                  name="LineItem"
                  placeholder="Description"
                  required
                />
              </div>
              <div className={`${col3} pr-0`}>
                <select name="UpsellOptionId" required>
                  <option value="">Select</option>
                  {props.upsellOptions && props.upsellOptions.length > 0
                    ? props.upsellOptions.map((element) => (
                        <option
                          key={element.UpsellOptionId}
                          value={element.UpsellOptionId}
                        >
                          {element.DisplayText}
                        </option>
                      ))
                    : ""}
                </select>
              </div>
              <div className={`${col2} pr-0`}>
                <input
                  type="number"
                  min="0"
                  name="Amount"
                  placeholder="Amount"
                  required
                />
              </div>
              <div className={col1}>
                <button type="submit" className="crditAddIcon">
                  <i className="fas fa-plus" />
                </button>
              </div>
            </div>
          </form>
        </div>
      ) : (
        ""
      )}
      {props.isDiscountInputRequired ? (
        <div className={`${col12} pr-0`}>
          <form
            onSubmit={props.submitDiscountChargeHandler}
            id="frmDiscountLineItem"
          >
            <h4 className="form-header text-uppercase">
              <i className="fas fa-user-circle" />
              Discount
            </h4>
            <div className="form-group row">
              <div className={`${col3} pr-0`}>
                <select
                  name="DiscountType"
                  onChange={(event) => discountHandler(event, "DiscountType")}
                >
                  <option value="AMOUNT">$</option>
                  <option value="PERCENT">%</option>
                  <option value="CODE">Code</option>
                </select>
              </div>
              <div className="col-lg-6 pr-0">
                <input type="text" name="DiscountReason" placeholder="Reason" />
              </div>
              <div className={`${col2} pr-0`}>
                <input
                  type={discountType === "CODE" ? "text" : "number"}
                  name="DiscountValue"
                  placeholder={discountType === "CODE" ? "Code" : "Amount"}
                />
              </div>
              <div className={col1}>
                <button type="submit" className="crditAddIcon">
                  <i className="fas fa-plus" />
                </button>
              </div>
            </div>
          </form>
        </div>
      ) : (
        ""
      )}
    </Fragment>
  );
  return pricingTable;
};
export default PricingTable;

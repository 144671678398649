const PermissionName = {
  //Admin settings related permissions.
  ADD_PERMISSION: "ADD_PERMISSION",
  ADD_ROLE: "ADD_ROLE",
  ASSIGN_ROLE_PERMISSION: "ASSIGN_ROLE_PERMISSION",
  DELETE_ROLE: "DELETE_ROLE",
  EDIT_PERMISSION: "EDIT_PERMISSION",
  EDIT_ROLE: "EDIT_ROLE",
  VIEW_PERMISSION: "VIEW_PERMISSION",
  VIEW_ROLE: "VIEW_ROLE",
  //Authorization related permissions.
  CC_LOGIN_ACCESS: "CC_LOGIN_ACCESS",
  //Member related permissions
  ADD_ACTIVITY_LOG: "ADD_ACTIVITY_LOG",
  //ADD_ADDRESS: "ADD_ADDRESS",
  ADD_EDIT_ADDRESS: "ADD_EDIT_ADDRESS",
  ADD_FEEDBACK: "ADD_FEEDBACK",
  ADD_MESSAGE: "ADD_MESSAGE",
  ADD_STATE_LICENSE: "ADD_STATE_LICENSE",
  ALLOW_TEMP_LICENSE_UPLOAD: "ALLOW_TEMP_LICENSE_UPLOAD",
  APPLY_OFFER: "APPLY_OFFER",
  CHANGE_TEMP_PASS: "CHANGE_TEMP_PASS",
  DELETE_ADDRESS: "DELETE_ADDRESS",
  DISABLE_MEMBER: "DISABLE_MEMBER",
  REACTIVATE_MEMBER: "REACTIVATE_MEMBER",
  DISAPPROVE_LICENSE: "DISAPPROVE_LICENSE",
  //EDIT_ADDRESS : "EDIT_ADDRESS",
  EDIT_MEMBER: "EDIT_MEMBER",
  EDIT_VIOLATION_POINT: "EDIT_VIOLATION_POINT",
  LOCK_UNLOCK_PROFILE: "LOCK_UNLOCK_PROFILE",
  MANUALLY_APPROVE_LICENSE: "MANUALLY_APPROVE_LICENSE",
  NEW_MEMBER: "NEW_MEMBER",
  RESET_PASSWORD: "RESET_PASSWORD",
  UPDATE_EMPLOYEE_INFO: "UPDATE_EMPLOYEE_INFO",
  UPLOAD_DOC: "UPLOAD_DOC",
  VALIDATE_LICENSE: "VALIDATE_LICENSE",
  VERIFY_PROFILE: "VERIFY_PROFILE",
  VIEW_ACTIVITY_LOG: "VIEW_ACTIVITY_LOG",
  VIEW_ADDRESS: "VIEW_ADDRESS",
  VIEW_DRIVING_LICENSE: "VIEW_DRIVING_LICENSE",
  EDIT_DRIVING_LICENSE: "EDIT_DRIVING_LICENSE",
  VIEW_EMPLOYEE_INFO: "VIEW_EMPLOYEE_INFO",
  VIEW_FAVORITE: "VIEW_FAVORITE",
  VIEW_FEEDBACK: "VIEW_FEEDBACK",
  VIEW_MEMBER: "VIEW_MEMBER",
  VIEW_MESSAGE: "VIEW_MESSAGE",
  VIEW_PACKAGE: "VIEW_PACKAGE",
  //Org settings related permissions.
  EDIT_AVAILABILITY_ORG_SETTINGS: "EDIT_AVAILABILITY_ORG_SETTINGS",
  EDIT_BLACK_LIST_ORG_SETTINGS: "EDIT_BLACK_LIST_ORG_SETTINGS",
  EDIT_HOURS_ORG_SETTINGS: "EDIT_HOURS_ORG_SETTINGS",
  EDIT_INSURANCE_ORG_SETTINGS: "EDIT_INSURANCE_ORG_SETTINGS",
  EDIT_LOCATION_DETAIL_ORG_SETTINGS: "EDIT_LOCATION_DETAIL_ORG_SETTINGS",
  EDIT_MAIN_ORG_SETTINGS: "EDIT_MAIN_ORG_SETTINGS",
  EDIT_QUALIFICATION_ORG_SETTINGS: "EDIT_QUALIFICATION_ORG_SETTINGS",
  EDIT_RATES_ORG_SETTINGS: "EDIT_RATES_ORG_SETTINGS",
  EDIT_RESERVATION_ORG_SETTINGS: "EDIT_RESERVATION_ORG_SETTINGS",
  EDIT_TAX_ORG_SETTINGS: "EDIT_TAX_ORG_SETTINGS",
  EDIT_UPSELL_ORG_SETTINGS: "EDIT_UPSELL_ORG_SETTINGS",
  VIEW_AVAILABILITY_ORG_SETTINGS: "VIEW_AVAILABILITY_ORG_SETTINGS",
  VIEW_BLACK_LIST_ORG_SETTINGS: "VIEW_BLACK_LIST_ORG_SETTINGS",
  VIEW_HOURS_ORG_SETTINGS: "VIEW_HOURS_ORG_SETTINGS",
  VIEW_INSURANCE_ORG_SETTINGS: "VIEW_INSURANCE_ORG_SETTINGS",
  VIEW_LOCATION_DETAIL_ORG_SETTINGS: "VIEW_LOCATION_DETAIL_ORG_SETTINGS",
  VIEW_MAIN_ORG_SETTINGS: "VIEW_MAIN_ORG_SETTINGS",
  VIEW_QUALIFICATION_ORG_SETTINGS: "VIEW_QUALIFICATION_ORG_SETTINGS",
  VIEW_RATES_ORG_SETTINGS: "VIEW_RATES_ORG_SETTINGS",
  VIEW_RESERVATION_ORG_SETTINGS: "VIEW_RESERVATION_ORG_SETTINGS",
  VIEW_TAX_ORG_SETTINGS: "VIEW_TAX_ORG_SETTINGS",
  VIEW_UPSELL_ORG_SETTINGS: "VIEW_UPSELL_ORG_SETTINGS",
  VIEW_CAPACITY_BUCKETS_INFO: "VIEW_CAPACITY_BUCKETS_INFO",
  SAVE_CAPACITY_BUCKETS_INFO: "SAVE_CAPACITY_BUCKETS_INFO",
  //others permission.
  BIRDS_EYE_VIEW_SEARCH: "BIRDS_EYE_VIEW_SEARCH",
  COMPLAINT_SEARCH: "COMPLAINT_SEARCH",
  IMPLEMENTATION_SEARCH: "IMPLEMENTATION_SEARCH",
  MAINTENANCE_SEARCH: "MAINTENANCE_SEARCH",
  REPORT_SEARCH: "REPORT_SEARCH",
  //payment related permissions.
  ADD_ACCOUNT_CREDIT: "ADD_ACCOUNT_CREDIT",
  CAPTURE_PAYMENT: "CAPTURE_PAYMENT",
  PREAUTH_PAYMENT: "PREAUTH_PAYMENT",
  PROCESS_PAYMENT: "PROCESS_PAYMENT",
  REFUND_PAYMENT: "REFUND_PAYMENT",
  RELEASE_PAYMENT: "RELEASE_PAYMENT",
  REVERT_ACCOUNT_CREDIT: "REVERT_ACCOUNT_CREDIT",
  VIEW_PAYMENT: "VIEW_PAYMENT",
  //Rental and Reservation related permissions.
  EDIT_END_RENTAL: "EDIT_END_RENTAL",
  EDIT_RENTAL: "EDIT_RENTAL",
  END_RENTAL: "END_RENTAL",
  START_RENTAL: "START_RENTAL",
  VIEW_RENTAL: "VIEW_RENTAL",
  CANCEL_RESERVATION: "CANCEL_RESERVATION",
  EDIT_RESERVATION: "EDIT_RESERVATION",
  NEW_RESERVATION: "NEW_RESERVATION",
  VIEW_RESERVATION: "VIEW_RESERVATION",
  ADJUSTMENT_CREDIT_CHARGE: "ADJUSTMENT_CREDIT_CHARGE",
  ADD_DAMAGE_INFO: "ADD_DAMAGE_INFO",
  UPDATE_INTEGRATION_STATUS: "UPDATE_INTEGRATION_STATUS",
  VIEW_DAMAGE_INFO: "VIEW_DAMAGE_INFO",
  VIEW_INTEGRATION_STATUS: "VIEW_INTEGRATION_STATUS",
  //Roles and permissions
  ASSIGN_PERMISSION: "ASSIGN_PERMISSION",
  ASSIGN_ROLE: "ASSIGN_ROLE",
  EDIT_APPLICATION_ACCESS: "EDIT_APPLICATION_ACCESS",
  ROLE_PERMISSION_SEARCH: "ROLE_PERMISSION_SEARCH",
  //Vehicle related permissions.
  DELETE_VEHICLE: "DELETE_VEHICLE",
  EDIT_VEHICLE: "EDIT_VEHICLE",
  LOCATE_VEHICLE: "LOCATE_VEHICLE",
  NEW_VEHICLE: "NEW_VEHICLE",
  VEHICLE_CONTROL_COMMANDS: "VEHICLE_CONTROL_COMMANDS",
  VIEW_VEHICLE: "VIEW_VEHICLE",
  IMPERSONATE_PERMISSION: "IMPERSONATE_PERMISSION",
  CREATE_LOCATION: "CREATE_LOCATION",
  VIEW_CREDIT_CARD_NUMBER: "VIEW_CREDIT_CARD_NUMBER",

  //Integration related permission
  VIEW_INTEGRATION_INFO: "VIEW_INTEGRATION_INFO",
  UPDATE_RESERVATION_CREATION_TYPE: "UPDATE_RESERVATION_CREATION_TYPE",
  SHOW_AFFILIATE_ORGS: "SHOW_AFFILIATE_ORGS",

  // Shuttle Admin Portal Permissions
  CC_ADD_LANDMARK: "CC_ADD_LANDMARK",
  CC_EDIT_LANDMARK: "CC_EDIT_LANDMARK",
  CC_DELETE_LANDMARK: "CC_DELETE_LANDMARK",
  CC_SEARCH_LANDMARKS: "CC_SEARCH_LANDMARKS",
  CC_VIEW_LANDMARK: "CC_VIEW_LANDMARK",
  CC_VIEW_TRIP_DETAILS: "CC_VIEW_TRIP_DETAILS",
  CC_VIEW_VICINITY: "CC_VIEW_VICINITY",
  CC_ADD_SHUTTLE: "CC_ADD_SHUTTLE",
  CC_EDIT_SHUTTLE: "CC_EDIT_SHUTTLE",
  CC_DELETE_SHUTTLE: "CC_DELETE_SHUTTLE",
  CC_VIEW_SHUTTLE: "CC_VIEW_SHUTTLE",
  CC_SEARCH_SHUTTLES: "CC_SEARCH_SHUTTLES",
  CC_VIEW_SHUTTLE_SETTINGS: "CC_VIEW_SHUTTLE_SETTINGS",
  CC_EDIT_SHUTTLE_SETTINGS: "CC_EDIT_SHUTTLE_SETTINGS",
  CC_SEARCH_SHUTTLE_TRIPS: "CC_SEARCH_SHUTTLE_TRIPS",
  ADD_SHUTTLE_STATUS: "ADD_SHUTTLE_STATUS",
  VIEW_ALL_SHUTTLE_DRIVERS: "VIEW_ALL_SHUTTLE_DRIVERS",
  VIEW_ALL_SHUTTLE_ORGUNITS: "VIEW_ALL_SHUTTLE_ORGUNITS",
  VIEW_TRIP_DETAILS: "VIEW_TRIP_DETAILS",
  VIEW_TRIPS: "VIEW_TRIPS",
  VIEW_VICINITY_SHUTTLES: "VIEW_VICINITY_SHUTTLES",

  // User Permissions for Fleet Utilization Forecast
  CC_SEARCH_FLEET_FORECASTS: "CC_SEARCH_FLEET_FORECASTS",

  CC_GET_FLEET_FORECAST: "CC_GET_FLEET_FORECAST",
  CC_UPDATE_FLEET_FORECAST: "CC_UPDATE_FLEET_FORECAST",

  CC_UPDATE_DEFAULT_PICKUP_THRESHOLD: "CC_UPDATE_DEFAULT_PICKUP_THRESHOLD",
  CC_UPDATE_DEFAULT_BOOK_THRU_THRESHOLD:
    "CC_UPDATE_DEFAULT_BOOK_THRU_THRESHOLD",

  CC_GET_ORG_UNIT_FLEET_CLASS_GROUP: "CC_GET_ORG_UNIT_FLEET_CLASS_GROUP",
  CC_UPDATE_ORG_UNIT_FLEET_CLASS_GROUP: "CC_UPDATE_ORG_UNIT_FLEET_CLASS_GROUP",

  CC_UPDATE_BOOK_THRU_THRESHOLD: "CC_UPDATE_BOOK_THRU_THRESHOLD",
  CC_UPDATE_NO_SHOW_FACTOR: "CC_UPDATE_NO_SHOW_FACTOR",
  CC_UPDATE_PICKUP_THRESHOLD: "CC_UPDATE_PICKUP_THRESHOLD",
  CC_UPDATE_WALKINS_COUNT: "CC_UPDATE_WALKINS_COUNT",

  CC_SEARCH_FLEET_UTILIZATION_LOGS: "CC_SEARCH_FLEET_UTILIZATION_LOGS",
  CC_SEND_DISCOUNT_CODE: "CC_SEND_DISCOUNT_CODE",
  CC_VIEW_PERSON_FOX_DISCOUNT: "CC_VIEW_PERSON_FOX_DISCOUNT",
  CC_GRANT_PERSON_FOX_DISCOUNT: "CC_GRANT_PERSON_FOX_DISCOUNT",
  CC_REVERT_PERSON_FOX_DISCOUNT: "CC_REVERT_PERSON_FOX_DISCOUNT",
  CC_SEARCH_DISCOUNT: "CC_SEARCH_DISCOUNT",
  CC_SEARCH_PERSON_FOX_DISCOUNT: "CC_SEARCH_PERSON_FOX_DISCOUNT",
};

export default PermissionName;

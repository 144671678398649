import { getDateTimeFormatString } from "../../../../components/common/common-helper";
import {
  getCurrentServerDate,
  isNullOrEmpty,
} from "../../../../shared/utility";
import { FoxDiscountDetails } from "../domain/fox-discount-details";

export const PrimaryFormViewModel = function (data) {
  if (!data) data = {};
  this.rentalLocationCode = data.rentalLocationCode || null;
  this.sameAsRentFromLoaction = isNullOrEmpty(data.sameAsRentFromLoaction)
    ? true
    : data.sameAsRentFromLoaction || false;
  this.returnLocationCode = data.returnLocationCode || null;
  this.creationDateTime =
    data.creationDateTime ||
    getCurrentServerDate(getDateTimeFormatString(true));
  this.rentalDateTime = data.rentalDateTime || null;
  this.rentalOnlyDate =
    data.rentalOnlyDate || data.rentalDateTime ? data.rentalDateTime : "";
  this.rentalOnlyTime =
    data.rentalOnlyTime || data.rentalDateTime ? data.rentalDateTime : "";
  this.returnDateTime = data.returnDateTime || null;
  this.returnOnlyDate =
    data.returnOnlyDate || data.returnDateTime ? data.returnDateTime : "";
  this.returnOnlyTime =
    data.returnOnlyTime || data.returnDateTime ? data.returnDateTime : "";
  this.payType = data.payType || null;
  this.rentalLocationId = data.rentalLocationId || null;
  this.returnLocationId = data.returnLocationId || null;
  this.reservationCode = data.reservationCode || null;
  this.discount = data?.discount ? data.discount : new FoxDiscountDetails();
  this.freeCreditAmountApplied = data?.freeCreditAmountApplied || null;
  this.foxDiscountCode = data?.foxDiscountCode || null;
  this.timeZone = data?.timeZone || null;
};

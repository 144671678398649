import React, { Fragment, Component } from "react";
import Combobox from "react-widgets/lib/Combobox";
import {
  Utility,
  getDisplayTextOfDropdown,
  isNullOrEmpty,
  toTitleCase,
} from "../../../shared/utility";
import { TooltipItemForLongLabel } from "../../../components/tooltip/TooltipItemForLongLabel";
import { createTooltip } from "../../../shared/utility/tooltip-utility";
import { getAddressTypeList } from "../../../containers/Member/MemberHelper";
import { invokeZipCodeStackAPI } from "../../../services/httpService";
import {DropdownList} from "react-widgets";
import { getKeyValFromList } from "../../../shared/utility/list-utility";
import { getCustomEventObject } from "../common-helper";

class LandmarkAddressComponent extends Component {
  state = {
    address: null,
    [this.props.type + "_COUNTRIES"]: null,
    [this.props.type + "_STATES"]: null,
    [this.props.type + "_CITIES"]: null,
    [this.props.type + "_POSTALS"]: null,
    addressTypeList: getAddressTypeList(),
    zipToAddrData: null,
    searchByZipCode: null,
    labelIdPrefixForTooltip: "lbl_Address_",
    zipCodeList: null,
  };

  loadDataForZipToAddress = (dataList, valueToCheck, changeMethod, ctrl) => {
    let tValue = valueToCheck;
    if (dataList && dataList.length) {
      const data = dataList.find(
        (element) => element.keyValue === valueToCheck.toUpperCase()
      );
      tValue = data && data.keyValue ? data.keyValue : null;
    }
    const event = {
      target: {
        value: "",
        key: "",
      },
    };
    event.target.value = tValue;
    event.target.key = valueToCheck.toUpperCase();
    changeMethod(event, ctrl);
  };
  loadDataForZipToAddressCommon = () => {
    if (this.state.zipToAddrData) {
      this.props.onChangeMethod(
        null,
        "STATETEXT_CITYTEXT_ZIP",
        this.props.type,
        {
          state: this.state.zipToAddrData.state.toUpperCase(),
          city: this.state.zipToAddrData.city.toUpperCase(),
          postalCode: this.state.zipToAddrData.postal_code,
        }
      );
    }
  };
  loadDataForCityZipToAddressCommon = (dataList, valueToCheck) => {
    if (this.state.zipToAddrData) {
      let tValue = valueToCheck;
      if (dataList && dataList.length) {
        const data = dataList.find(
          (element) => element.keyValue === valueToCheck.toUpperCase()
        );
        tValue = data && data.keyValue ? data.keyValue : valueToCheck;
      }
      this.props.onChangeMethod(null, "CITY_ZIP", this.props.type, {
        state: tValue,
        city: this.state.zipToAddrData.city,
        postalCode: this.state.zipToAddrData.postal_code,
      });
    }
  };

  isRemoteCallRequired = (ctrl) => {
    const cDataList = this.state[this.props.type + "_COUNTRIES"];
    const cData = cDataList
      ? cDataList.find(
          (element) => element.countryCode === this.props.address.country
        )
      : {};
    return !cDataList ||
      cData.countryCode === "US" ||
      (cData.countryCode === "IN" && ctrl === "province")
      ? true
      : false;
  };

  componentDidMount = () => {
    this.loadCountries();
    if (this.props.address.country) {
      this.loadStates(this.props.address.country);
    }
    if (this.props.address.province) {
      this.loadCities(this.props.address.country, this.props.address.province);
    }
    if (this.props.address.city) {
      this.loadPostals(
        this.props.address.country,
        this.props.address.province,
        this.props.address.city
      );
    }
    this.setState({
      searchByZipCode:
        this.state.searchByZipCode || this.props.address.postalCodeText,
    });
  };
  componentDidUpdate = (prevProps) => {
    if (
      !this.props.address ||
      (this.props.address &&
        prevProps.address &&
        this.isAddressChanged(this.props.address, prevProps.address))
    ) {
      this.getActionforDataChanges(this.props.address, prevProps.address);
      this.setState({
        searchByZipCode:
          this.state.searchByZipCode || this.props.address.postalCodeText,
      });
    }
    let addressTypeListNew = [...this.state.addressTypeList];
    if (
      this.props.address.AddressType &&
      !addressTypeListNew.some(
        (item) => item.key === this.props.address.AddressType
      )
    ) {
      addressTypeListNew.push({
        key: this.props.address.AddressType,
        displayValue: toTitleCase(this.props.address.AddressType),
      });
      this.setState({
        addressTypeList: addressTypeListNew,
      });
    }
  };
  getLblHtmlCtrl = (lblName) => {
    return (
      <label className={this.props.rightLblClassName || "col-sm-4 p-0"}>
        {createTooltip(lblName || "--", lblName || "")}
      </label>
    );
  };
  isAddressChanged = (newAddress, prevAddress) => {
    if (
      prevAddress.country !== newAddress.country ||
      prevAddress.province !== newAddress.province ||
      prevAddress.city !== newAddress.city
    ) {
      return true;
    }
    return false;
  };
  getActionforDataChanges = (newAddress, prevAddress) => {
    if (newAddress.country != prevAddress.country) {
      this.loadStates(newAddress.country);
    }
    if (
      newAddress.province != prevAddress.province &&
      !this.props.isCityNotRequired
    ) {
      if (this.isRemoteCallRequired("city")) {
        this.loadCities(newAddress.country, newAddress.province);
      } else {
        this.setState({[this.props.type + "_CITIES"]: null });
      }
    }
    if (
      newAddress.city != prevAddress.city &&
      !this.props.isPostalNotRequired
    ) {
      this.loadPostals(
        newAddress.country,
        newAddress.province,
        newAddress.city
      );
    }
  };
  fetchZiptoAddress = async (event) => {
    const cDataList = this.state[this.props.type + "_COUNTRIES"];
    const cData =
      cDataList &&
      cDataList.find(
        (element) => element.countryCode === this.props.address.country
      );
    const country = cData.countryCode;
    const zipCode = event.target.value;
    const sDatalist = this.state[this.props.type + "_STATES"];

    if (
      this.state.zipToAddrData &&
      parseInt(zipCode) === parseInt(this.state.zipToAddrData.postal_code) && (country === this.state.zipToAddrData.country_code)
    ) {
      this.props.setLoading(true);
      if (this.state.zipToAddrData && this.state.zipToAddrData.state) {
        sDatalist && this.isRemoteCallRequired("city")
          ? this.loadDataForZipToAddress(
              sDatalist,
              this.state.zipToAddrData.state.toUpperCase(),
              this.onChangeState
            )
          : sDatalist && cData.countryCode === "IN"
          ? this.loadDataForCityZipToAddressCommon(
              sDatalist,
              this.state.zipToAddrData.state.toUpperCase(),
              this.state.zipToAddrData
            )
          : this.loadDataForZipToAddressCommon(this.state.zipToAddrData);
      }
    } else if (!isNullOrEmpty(zipCode)) {
      //   setSearchByZipCode(zipCode);
      this.setState({ searchByZipCode: zipCode });
      this.props.setLoading(true);
      const zipData = await invokeZipCodeStackAPI({
        codes: zipCode,
        country: country,
      });
      this.props.setLoading(false);
      if (zipData) {
        this.props.setLoading(true);
        this.setState(
          {
            zipToAddrData: zipData,
          },
          () => {
            if (this.state.zipToAddrData && this.state.zipToAddrData.state) {
              sDatalist && this.isRemoteCallRequired("city")
                ? this.loadDataForZipToAddress(
                    sDatalist,
                    this.state.zipToAddrData.state.toUpperCase(),
                    this.onChangeState
                  )
                : sDatalist && cData.countryCode === "IN"
                ? this.loadDataForCityZipToAddressCommon(
                    sDatalist,
                    this.state.zipToAddrData.state.toUpperCase(),
                    this.state.zipToAddrData
                  )
                : this.loadDataForZipToAddressCommon();
            }
            this.props.setLoading(false);
          }
        );
      } else {
        this.props.setLoading(false);
        this.setState({ zipToAddrData: null });
      }
    }
  };

  loadCountries = () => {
    this.props.setLoading(true);
    Utility.countryStateCityPostal.getCountries((countryResponse) => {
      this.props.setLoading(false);
      this.setState({
        [this.props.type + "_COUNTRIES"]: countryResponse,
        zipToAddrData: null,
      });
    });
  };
  loadStates = (countryId) => {
    if (this.isRemoteCallRequired("province")) {
      this.props.setLoading(true);
      Utility.countryStateCityPostal.getStates(
        countryId,
        true,
        (stateResponse) => {
          this.props.setLoading(false);
          this.setState({
            [this.props.type + "_STATES"]:
              stateResponse.States && stateResponse.States.length > 0
                ? stateResponse.States
                : null,
          });
        }
      );
    } else {
      this.setState({ [this.props.type + "_STATES"]: null });
    }
  };
  loadCities = (countryId, stateId) => {
    if (this.isRemoteCallRequired("city")) {
      this.props.setLoading(true);
      Utility.countryStateCityPostal.getCities(
        countryId,
        stateId,
        true,
        (cityResponse) => {
          this.props.setLoading(false);
          this.setState(
            {
              [this.props.type + "_CITIES"]:
                cityResponse.Cities && cityResponse.Cities.length > 0
                  ? cityResponse.Cities
                  : null,
            },
            () => {
              if (this.state.zipToAddrData && this.state.zipToAddrData.city) {
                if (this.isRemoteCallRequired("city")) {
                  this.loadDataForZipToAddress(
                    this.state[this.props.type + "_CITIES"],
                    this.state.zipToAddrData.city,
                    this.onChangeCity
                  );
                }
              }
            }
          );
        }
      );
    }
  };
  loadPostals = (countryId, stateId, city) => {
    if (this.isRemoteCallRequired("city")) {
      this.props.setLoading(true);
      Utility.countryStateCityPostal.getPostals(
        countryId,
        stateId,
        city,
        true,
        (postalResponse) => {
          this.props.setLoading(false);
          this.setState(
            {
              [this.props.type + "_POSTALS"]:
                postalResponse.Postals && postalResponse.Postals.length > 0
                  ? postalResponse.Postals
                  : null,
            },
            () => {
              if (
                this.state.zipToAddrData &&
                this.state.zipToAddrData.postal_code
              ) {
                if (this.isRemoteCallRequired("city")) {
                  this.loadDataForZipToAddress(
                    this.state[this.props.type + "_POSTALS"],
                    this.state.zipToAddrData.postal_code,
                    this.onChangePostal
                  );
                }
              }
            }
          );
        }
      );
    } else {
      this.setState({ [this.props.type + "_POSTALS"]: null });
    }
  };

  onChangeCountry = (event) => {
    this.props.onChangeMethod(event, "country", this.props.type);
    this.setState({
      [this.props.type + "_STATES"]: null,
      [this.props.type + "_CITIES"]: null,
      [this.props.type + "_POSTALS"]: null,
    });
    event = getCustomEventObject(event, "countryCode", "display")
    if (event.target.value) {
      this.loadStates(event.target.value);
    }
  };
  onChangeState = (event, ctrl) => {
    this.setState({
      [this.props.type + "_CITIES"]: null,
      [this.props.type + "_POSTALS"]: null,
    });
    event = getCustomEventObject(event, "keyValue", "displayValue")
    this.props.onChangeMethod(
      event,
      ctrl || "province",
      this.props.type
    );
    if (
      event.target.value &&
      !this.props.isCityNotRequired &&
      this.isRemoteCallRequired()
    ) {
      this.loadCities(this.props.address.country, event.target.value);
    }
  };
  onChangeCity = (event, ctrl) => {
    this.setState({
      [this.props.type + "_POSTALS"]: null,
    });
    event = getCustomEventObject(event, "keyValue", "displayValue")
    this.props.onChangeMethod(
      event,
      ctrl || "city",
      this.props.type
    );
    if (
      event.target.value &&
      !this.props.isPostalNotRequired &&
      this.isRemoteCallRequired()
    ) {
      this.loadPostals(
        this.props.address.country,
        this.props.address.province,
        event.target.value
      );
    }
  };
  onChangePostal = (event, ctrl) => {
    this.props.onChangeMethod(
      event,
      ctrl || "postalCode",
      this.props.type
    );
  };

  getValueClasses = (controlName) => {
    let valueClasses = this.props.inputContainerClassName || "col-sm-8";
    let values =
      this.props.address[controlName.split(",")[0]] ||
      this.props.address[controlName.split(",")[1]];
    let faliedValidationClass = !isNullOrEmpty(values)
      ? ""
      : this.props.isValidateDone
      ? this.props.isValidationRequired === false ||
        this.props.isValidationRequired === "undefined"
        ? ""
        : "validation-failed"
      : "";
    return faliedValidationClass
      ? valueClasses + " " + faliedValidationClass
      : valueClasses;
  };
  render() {
    const zipCodeList = this.state[this.props.type + "_POSTALS"];
    let isExistSearchedZipCode =
      this.props.address && this.props.address.isValidZipCode;
    if (isExistSearchedZipCode) {
      isExistSearchedZipCode =
        zipCodeList &&
        zipCodeList.length &&
        this.state.searchByZipCode &&
        zipCodeList.some(
          (item) =>
            parseInt(item.keyValue) === parseInt(this.state.searchByZipCode)
        );
    }
    return (
      <Fragment>
        {!this.props.isStreet1NotRequired ? (
          <div
            className={`form-group row  ${
              this.props.isAstericsRequired && !this.props.isNoneEditableMode
                ? "required"
                : ""
            }`}
          >
            <label
              className={
                this.props.leftLblClassName ||
                "col-sm-4 col-form-label font-weight-bold"
              }
            >
              {this.props.street1Label || "Address 1"}
            </label>
            <div className={this.getValueClasses("street")}>
              {!this.props.isNoneEditableMode ? (
                <input
                  name="street"
                  type="text"
                  className="form-control"
                  disabled={this.props.disabled || false}
                  onChange={(event) =>
                    this.props.onChangeMethod(
                      event,
                      "street",
                      this.props.type
                    )
                  }
                  value={this.props.address.street || ""}
                />
              ) : (
                this.getLblHtmlCtrl(this.props.address.street)
              )}
            </div>
          </div>
        ) : (
          ""
        )}
        {!this.props.isStreet2NotRequired ? (
          <div className="form-group row">
            <label
              className={
                this.props.leftLblClassName ||
                "col-sm-4 col-form-label font-weight-bold"
              }
            >
              {this.props.street2Label || "Address 2"}
            </label>
            <div className={this.getValueClasses("street2")}>
              {!this.props.isNoneEditableMode ? (
                <input
                  name="street2"
                  type="text"
                  className="form-control"
                  value={this.props.address.street2 || ""}
                  disabled={this.props.disabled || false}
                  onChange={(event) =>
                    this.props.onChangeMethod(
                      event,
                      "street2",
                      this.props.type
                    )
                  }
                />
              ) : (
                this.getLblHtmlCtrl(this.props.address.street2)
              )}
            </div>
          </div>
        ) : (
          ""
        )}
        <div
          className={`form-group row  ${
            this.props.isAstericsRequired && !this.props.isNoneEditableMode
              ? "required"
              : ""
          }`}
        >
          <label
            className={
              this.props.leftLblClassName ||
              "col-sm-4 col-form-label font-weight-bold"
            }
            id={
              this.state.labelIdPrefixForTooltip +
              toTitleCase(this.props.coutryLabel || "Country").replace(" ", "")
            }
          >
            {this.props.coutryLabel || "Country"}
            <TooltipItemForLongLabel
              toolTipId={
                this.state.labelIdPrefixForTooltip +
                toTitleCase(this.props.coutryLabel || "Country").replace(
                  " ",
                  ""
                )
              }
            />
          </label>
          <div className={this.getValueClasses("country")}>
            {!this.props.isNoneEditableMode ? (
              <DropdownList
              filter='contains'
              data={this.state[this.props.type + "_COUNTRIES"] &&
                this.state[this.props.type + "_COUNTRIES"].length > 0
                  ? this.state[this.props.type + "_COUNTRIES"] : []}
              valueField="countryCode"
              textField="display"
              defaultValue={{countryCode:"null", display:"Select Country"}}
              value={getKeyValFromList(this.state[this.props.type + "_COUNTRIES"], this.props.address.country, {countryCode:"null", display:"Select Country"},"countryCode")}
              onChange={(value) => this.onChangeCountry(value)}
              disabled={this.props.disabled || false}
            />
              // <select
              //   id={this.props.type + "-Country"}
              //   onChange={(event) => this.onChangeCountry(event)}
              //   disabled={this.props.disabled || false}
              //   value={this.props.address.country || ""}
              // >
              //   <option value="">Select Country</option>
              //   {this.state[this.props.type + "_COUNTRIES"] &&
              //   this.state[this.props.type + "_COUNTRIES"].length > 0
              //     ? this.state[this.props.type + "_COUNTRIES"].map(
              //         (element) => (
              //           <option
              //             key={element.countryCode}
              //             value={element.countryCode}
              //           >
              //             {element.display}
              //           </option>
              //         )
              //       )
              //     : ""}
              // </select>
            ) : (
              this.getLblHtmlCtrl(
                getDisplayTextOfDropdown(
                  this.state[this.props.type + "_COUNTRIES"],
                  this.props.address.country,
                  "countryCode",
                  "display"
                )
              )
            )}
          </div>
        </div>
        {!this.props.isPostalNotRequired ? (
          <div
            className={`form-group row  ${
              this.props.isAstericsRequired && !this.props.isNoneEditableMode
                ? "required"
                : ""
            }`}
          >
            <label
              className={
                this.props.leftLblClassName ||
                "col-sm-4 col-form-label font-weight-bold"
              }
              id={this.state.labelIdPrefixForTooltip + "ZipCodePostal"}
            >
              Zip/Postal Code
              <TooltipItemForLongLabel
                toolTipId={this.state.labelIdPrefixForTooltip + "ZipCodePostal"}
              />
            </label>
            <div
              className={`${this.getValueClasses("postalCode,postalCodeText")} ${
                (zipCodeList &&
                  zipCodeList.length &&
                  this.state.searchByZipCode &&
                  isExistSearchedZipCode === false) ||
                isExistSearchedZipCode === false
                  ? "validation-warned"
                  : ""
              }`}
            >
              {!this.props.isNoneEditableMode ? (
                <Fragment>
                  {this.state[this.props.type + "_POSTALS"] &&
                  this.state[this.props.type + "_POSTALS"].length > 0 ? (
                    <Combobox
                      id={this.props.type + "-Postals"}
                      disabled={this.props.disabled || false}
                      data={this.state[this.props.type + "_POSTALS"]}
                      dataKey="keyValue"
                      textField="displayValue"
                      defaultValue={
                        this.props.address.postalCode ||
                        this.props.address.postalCodeText
                      }
                      onChange={(event) => this.onChangePostal(event)}
                      onBlur={(event) => this.fetchZiptoAddress(event)}
                      placeholder="Enter or Select Zip/Postal Code"
                      focusFirstItem={false}
                    />
                  ) : (
                    <input
                      id={this.props.type + "-postalCodeText"}
                      type="text"
                      className="form-control"
                      disabled={this.props.disabled || false}
                      onChange={(event) =>
                        this.props.onChangeMethod(
                          event,
                          "postalCodeText",
                          this.props.type
                        )
                      }
                      onBlur={(event) => this.fetchZiptoAddress(event)}
                      value={this.props.address.postalCodeText}
                      placeholder="Enter Zip/Postal Code"
                    ></input>
                  )}
                </Fragment>
              ) : (
                this.getLblHtmlCtrl(
                  this.state[this.props.type + "_POSTALS"] &&
                    this.state[this.props.type + "_POSTALS"].length > 0
                    ? getDisplayTextOfDropdown(
                        this.state[this.props.type + "_POSTALS"],
                        this.props.address.postalCode,
                        "keyValue",
                        "displayValue"
                      )
                    : this.props.address.postalCode
                )
              )}
            </div>
          </div>
        ) : (
          ""
        )}
        <div
          className={`form-group row  ${
            this.props.isAstericsRequired && !this.props.isNoneEditableMode
              ? "required"
              : ""
          }`}
        >
          <label
            className={
              this.props.leftLblClassName ||
              "col-sm-4 col-form-label font-weight-bold"
            }
            id={
              this.state.labelIdPrefixForTooltip +
              toTitleCase(this.props.stateLabel || "State").replace(" ", "")
            }
          >
            {this.props.stateLabel || "State"}
            <TooltipItemForLongLabel
              toolTipId={
                this.state.labelIdPrefixForTooltip +
                toTitleCase(this.props.stateLabel || "State").replace(" ", "")
              }
            />
          </label>
          <div className={this.getValueClasses("province,provinceText")}>
            {!this.props.isNoneEditableMode ? (
              <Fragment>
                {this.state[this.props.type + "_STATES"] &&
                this.state[this.props.type + "_STATES"].length > 0 ? (
                  <DropdownList
                    filter='contains'
                    id={this.props.type + "-province"}
                    data={this.state[this.props.type + "_STATES"]}
                    valueField="keyValue"
                    textField="displayValue"
                    defaultValue={{keyValue:"null", displayValue:"Select State"}}
                    value={getKeyValFromList(this.state[this.props.type + "_STATES"], this.props.address.province, {keyValue:"null", displayValue:"Select State"},"keyValue")}
                    onChange={(value) => this.onChangeState(value)}
                    disabled={this.props.disabled || false}
                  />
                  // <select
                  //   id={this.props.type + "-province"}
                  //   onChange={(event) => this.onChangeState(event)}
                  //   disabled={this.props.disabled || false}
                  //   value={this.props.address.province || ""}
                  // >
                  //   <option value="">Select State</option>
                  //   {this.state[this.props.type + "_STATES"].map(
                  //     (element, index) => (
                  //       <option key={element.keyValue} value={element.keyValue}>
                  //         {element.displayValue}
                  //       </option>
                  //     )
                  //   )}
                  // </select>
                ) : (
                  <input
                    id={this.props.type + "-StateText"}
                    type="text"
                    className="form-control"
                    disabled={this.props.disabled || false}
                    onChange={(event) =>
                      this.props.onChangeMethod(
                        event,
                        "provinceText",
                        this.props.type
                      )
                    }
                    value={this.props.address.provinceText || ""}
                  ></input>
                )}
              </Fragment>
            ) : (
              this.getLblHtmlCtrl(
                this.state[this.props.type + "_STATES"] &&
                  this.state[this.props.type + "_STATES"].length > 0
                  ? getDisplayTextOfDropdown(
                      this.state[this.props.type + "_STATES"],
                      this.props.address.province,
                      "keyValue",
                      "displayValue"
                    )
                  : this.props.address.province
              )
            )}
          </div>
        </div>
        {!this.props.isCityNotRequired ? (
          <div
            className={`form-group row  ${
              this.props.isAstericsRequired && !this.props.isNoneEditableMode
                ? "required"
                : ""
            }`}
          >
            <label
              className={
                this.props.leftLblClassName ||
                "col-sm-4 col-form-label font-weight-bold"
              }
            >
              City
            </label>
            <div className={this.getValueClasses("city,cityText")}>
              {!this.props.isNoneEditableMode ? (
                <Fragment>
                  {this.state[this.props.type + "_CITIES"] &&
                  this.state[this.props.type + "_CITIES"].length > 0 ? (
                    <DropdownList
                    filter='contains'
                    data={this.state[this.props.type + "_CITIES"]}
                    valueField="keyValue"
                    textField="displayValue"
                    defaultValue={{keyValue:"null", displayValue:"Select City"}}
                    value={getKeyValFromList(this.state[this.props.type + "_CITIES"], this.props.address.city, {keyValue:"null", displayValue:"Select City"},"keyValue")}
                     onChange={(value) => this.onChangeCity(value)}
                    disabled={this.props.disabled || false}
                  />
                    // <select
                    //   id={this.props.type + "-city"}
                    //   onChange={(event) => this.onChangeCity(event)}
                    //   disabled={this.props.disabled || false}
                    //   value={this.props.address.city || ""}
                    // >
                    //   <option value="">Select City</option>
                    //   {this.state[this.props.type + "_CITIES"].map(
                    //     (element) => (
                    //       <option
                    //         key={element.keyValue}
                    //         value={element.keyValue}
                    //       >
                    //         {element.displayValue}
                    //       </option>
                    //     )
                    //   )}
                    // </select>
                  ) : (
                    <input
                      id={this.props.type + "-CityText"}
                      type="text"
                      className="form-control"
                      disabled={this.props.disabled || false}
                      onChange={(event) =>
                        this.props.onChangeMethod(
                          event,
                          "cityText",
                          this.props.type
                        )
                      }
                      value={this.props.address.cityText || ""}
                    ></input>
                  )}
                </Fragment>
              ) : (
                this.getLblHtmlCtrl(
                  this.state[this.props.type + "_CITIES"] &&
                    this.state[this.props.type + "_CITIES"].length > 0
                    ? getDisplayTextOfDropdown(
                        this.state[this.props.type + "_CITIES"],
                        this.props.address.city,
                        "keyValue",
                        "displayValue"
                      )
                    : this.props.address.city
                )
              )}
            </div>
          </div>
        ) : (
          ""
        )}
      </Fragment>
    );
  }
}
export default LandmarkAddressComponent;

import { invokeServerAPI } from "../../services/httpService";
import * as actionTypes from "./actionTypes-shuttle-app";
const getShuttleOptionStart = (actionType) => {
  return {
    type: actionType,
  };
};
const getShuttleOptionFail = (actionType, error) => {
  return {
    type: actionType,
    error: error,
  };
};
const getShuttleOptionSuccess = (response) => {
  return {
    type: actionTypes.SHUTTLE_OPTION_SUCCESS,
    shuttleOptionResponse: response,
  };
};

export const getShuttleOptionReset = () => {
  return {
    type: actionTypes.SHUTTLE_OPTION_RESET,
  };
};

export const getShuttleOption = (req) => {
  return (dispatch) => {
    dispatch(getShuttleOptionStart(actionTypes.SHUTTLE_OPTION_START));
    invokeServerAPI("shuttleapi/shuttleoptions", req, true)
      .then((response) => {
        dispatch(getShuttleOptionSuccess(response));
      })
      .catch((err) => {
        //   dispatch(getShuttleOptionFail(actionTypes.SHUTTLE_OPTION_FAIL, err));
      });
  };
};
/**static or enum type data if we fatch one time dont need to call again like we do for enum *****/
const getShuttleOptionforStaticDataSuccess = (response) => {
  return {
    type: actionTypes.SHUTTLE_OPTION_STATIC_SUCCESS,
    amenities: response.amenities,
    availabilities: response.availabilities,
    colorTypes: response.colorTypes,
    drivingTypes: response.drivingTypes,
    engineStatuses: response.engineStatuses,
    fuelTypes: response.fuelTypes,
    fuelConsumptionUnitTypes: response.fuelConsumptionUnitTypes,
    transMissionTypes: response.transMissionTypes,
    unitSystems: response.unitSystems,
  };
};

export const getShuttleOptionforStaticDataReset = () => {
  return {
    type: actionTypes.SHUTTLE_OPTION_STATIC_RESET,
  };
};
export const getShuttleOptionforStaticData = (req) => {
  return (dispatch) => {
    dispatch(getShuttleOptionStart(actionTypes.SHUTTLE_OPTION_START));
    invokeServerAPI("shuttleapi/shuttleoptions", req, true)
      .then((response) => {
        dispatch(getShuttleOptionforStaticDataSuccess(response));
      })
      .catch((err) => {
        //   dispatch(getShuttleOptionFail(actionTypes.SHUTTLE_OPTION_FAIL, err));
      });
  };
};

import React from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import Draggable from "react-draggable";
import AttachedDocBody from "./attach-doc-body";
import { LicenseDocType, ModalType } from "../../../shared/GlobalVar";
export const LicenseDocModal = (props) => {
  const getModalTitle = () => {
    if (props.modalType === LicenseDocType.ATTACHED_DOC) {
      return "Document Attach";
    } else if (props.modalType === LicenseDocType.MANUAL_APPROVE) {
      return "Manual Approve License";
    } else if (props.modalType === LicenseDocType.MANUAL_DISAPPROVE) {
      return "Manual Disapprove License";
    }
    return "Document Attach";
  };
  return (
    <Draggable handle=".cursor">
      <Modal
        isOpen={props.showModal}
        className="no-cursor"
        toggle={() => {
          props.closeModal();
        }}
        style={{ minWidth: "1000px" }}
      >
        <ModalHeader
          className="modal-header bg-primary modal-title text-white cursor"
          toggle={() => {
            props.closeModal();
          }}
        >
          <i className="fa fa-id-card pr-2" />
          {getModalTitle()}
        </ModalHeader>
        <ModalBody
          style={{ maxHeight: "480px", minHeight: "480px" }}
          className="tableScroll"
        >
          <AttachedDocBody
            closeModal={props.closeModal}
            memberId={props.memberId}
            licenseDocType={props.modalType}
            resetParentPage={props.resetParentPage}
          />
        </ModalBody>
      </Modal>
    </Draggable>
  );
};

export default LicenseDocModal;

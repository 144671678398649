import React, { useEffect, useState, Fragment, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getTaxSurchargeInfo,
  getTaxSurchargeInfoReset,
  updateTaxSurchargeInfo,
  updateTaxSurchargeInfoReset,
} from "../../../store/actions/index";
import Spinner from "../../../components/UI/Spinner/Spinner";
import PermissionName from "../../../shared/Permissions";
import {
  updateObject,
  convertDateTimeString,
  formatAmountValue,
  hasPermission,
  getBasicToastResponse,
  getCamelCaseId,
} from "../../../shared/utility";
import { showNotification } from "../../../components/UI/Toaster/Toaster";
import {
  TaxesAndSurchargesSettingsViewModel,
  TaxSurchargesModel,
} from "../../../services/entities/view-models/settings-view-models";
import LinkButton from "../../../components/UI/button/link-btn";
import { CommonLabel } from "../../../components/UI/label/commmon-label";
import TaxSurchargesModal from "./modal/taxes-surcharges-modal";
import ConfirmModal from "../../../components/common/modal/confirm-modal";
import ActionIcon from "../../../components/UI/icon/action-icon";
import {
  getUnitDisplayValue,
  getInheritedProperties,
  getNewlyAddedRowInfo,
  RenderInheritedCheckBox,
  getUnitTypeValue,
} from "../settingsHelper";
import NoRecordFoundComponent from "../../../components/common/pages/no-record-found";
import { COMMON_MSG } from "../../../shared/GlobalVar";
import InheritFromParentConfirmModal from "../modal/inherit-from-parent-confirm-modal";
import PreventTransitionPrompt from "../../../components/Navigations/prevent-navigation/prevent-transition-prompt";
import CommonInputField from "../../../components/UI/Input/common-input-field";
const TaxesSurchargesSettings = (props) => {
  const loading = useSelector((state) => state.settingsReducer.loading);
  const currentOrgUnitId = useSelector(
    (state) => state.auth.loggedInUserData.CurrentOrgUnitId
  );
  const taxSurchargeInfoResponse = useSelector(
    (state) => state.settingsReducer.taxSurchargeInfoResponse
  );
  const taxSurchargeInfoUpdateResponse = useSelector(
    (state) => state.settingsReducer.taxSurchargeInfoUpdateResponse
  );
  const dependentOrgLength = useSelector((state) =>
    state.auth.loggedInUserData.DependantOrgList
      ? state.auth.loggedInUserData.DependantOrgList.length
      : 0
  );
  const dispatch = useDispatch();
  const query = props.location
    ? new URLSearchParams(props.location.search)
    : null;
  const selectedOrgLocationId =
    props.otherData && props.otherData.selectedOrgLocationId
      ? props.otherData.selectedOrgLocationId
      : query
      ? query.get("orgId")
      : currentOrgUnitId;

  const [isEditMode, setIsEditMode] = useState(false);

  const [showAddEntityModal, setShowAddEntityModal] = useState(false);
  const [showConfirmRemoveInheritModal, setShowConfirmRemoveInheritModal] =
    useState(false);
  const [
    showInheritFromParentConfirmModal,
    setShowInheritFromParentConfirmModal,
  ] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [settingsType, setSettingsType] = useState(null);
  const [selectedEntity, setSelectedEntity] = useState(
    new TaxSurchargesModel()
  );
  const [taxesAndSurchargesSettingsData, setTaxesAndSurchargesSettingsData] =
    useState(new TaxesAndSurchargesSettingsViewModel());

  const hasEditPermission = hasPermission(PermissionName.EDIT_TAX_ORG_SETTINGS);
  const [isBlocking, setIsBlocking] = useState(false);

  const intervalRef = useRef();
  const [highlightedClass, setHighlightedClass] = useState("");
  const [selectedRow, setSelectedRow] = useState(-1);
  const [timer, setTimer] = useState(0);

  useEffect(() => {
    if (taxSurchargeInfoResponse && taxSurchargeInfoUpdateResponse) {
      intervalRef.current = setInterval(() => {
        setTimer((prevTimer) => prevTimer + 1);
      }, 1000);
    }
    if (timer % 8 === 0) {
      applyHighlightedColor(null);
      clearInterval(intervalRef.current);
    }
  }, [timer]);

  useEffect(() => {
    getTaxSurchargesData();
  }, [selectedOrgLocationId]);
  useEffect(() => {
    if (!taxSurchargeInfoResponse) {
      return;
    }
    if (taxSurchargeInfoResponse.StatusCode === 0) {
      if (showInheritFromParentConfirmModal) {
        return;
      }
      setTaxesAndSurchargesSettingsData(
        new TaxesAndSurchargesSettingsViewModel(
          taxSurchargeInfoResponse,
          currentOrgUnitId,
          parseInt(selectedOrgLocationId, 10)
        )
      );
    } else {
      showNotification(getBasicToastResponse(taxSurchargeInfoResponse));
    }
  }, [taxSurchargeInfoResponse]);

  useEffect(() => {
    if (!taxSurchargeInfoUpdateResponse) {
      return;
    }
    showNotification(getBasicToastResponse(taxSurchargeInfoUpdateResponse));

    if (taxSurchargeInfoUpdateResponse.StatusCode === 0) {
      closeAddEntityModal();
      setIsEditMode(false);
      setIsBlocking(false);
      if (
        taxSurchargeInfoResponse &&
        taxSurchargeInfoUpdateResponse &&
        !taxSurchargeInfoUpdateResponse.InheritedFromParent
      ) {
        highlightNewRow(
          taxSurchargeInfoResponse.OrgTaxList,
          taxSurchargeInfoUpdateResponse.OrgTaxList
        );
        startTimer();
      }

      dispatch(updateTaxSurchargeInfoReset());
    } else {
      dispatch(updateTaxSurchargeInfoReset());
    }
  }, [taxSurchargeInfoUpdateResponse]);

  const enableEditMode = () => {
    setIsEditMode(true);
  };
  const onCancel = () => {
    setIsEditMode(false);
    setTaxesAndSurchargesSettingsData(
      new TaxesAndSurchargesSettingsViewModel(
        taxSurchargeInfoResponse,
        currentOrgUnitId,
        parseInt(selectedOrgLocationId, 10)
      )
    );
    setIsBlocking(false);
  };

  const applyHighlightedColor = (selectedRowId) => {
    if (selectedRowId) {
      setSelectedRow(selectedRowId);
      setHighlightedClass("add-edit-row-highlight");
    } else {
      setSelectedRow(-1);
      setHighlightedClass("");
    }
  };

  const highlightNewRow = (oldData, newData) => {
    let newRow = getNewlyAddedRowInfo(oldData, newData, "OrgTaxId");
    if (newRow.length > 0) {
      applyHighlightedColor(newRow[0].OrgTaxId);
    }
  };

  const startTimer = () => {
    setTimer(1);
    intervalRef.current = setInterval(() => {
      setTimer((prevTimer) => prevTimer + 1);
    }, 1000);
  };

  const inputChangedHandler = (event, controlName, elementType) => {
    let value = null;
    if (elementType === "checkbox") {
      value = event.target.checked;
    } else {
      value = event.target.value;
    }
    let updatedControls = updateObject(taxesAndSurchargesSettingsData, {
      [controlName]: value,
    });
    setTaxesAndSurchargesSettingsData(updatedControls);
    setIsBlocking(true);
  };

  const getTaxSurchargesData = () => {
    const req = {};
    req.SelectedChildOrgUnitId = selectedOrgLocationId;
    dispatch(getTaxSurchargeInfoReset());
    dispatch(getTaxSurchargeInfo(req));
  };

  const getAddUpdateEntityModal = (entity, modalType) => {
    setSelectedEntity(entity);
    setSettingsType(modalType);
    setShowAddEntityModal(true);
    //setIsEditMode(false);
  };
  const closeAddEntityModal = () => {
    setSettingsType(null);
    setShowAddEntityModal(false);
    setShowConfirmModal(false);
    setShowConfirmRemoveInheritModal(false);
    setShowInheritFromParentConfirmModal(false);
    setSelectedEntity(new TaxSurchargesModel());
  };
  const getConfirmModal = (entity, modalType) => {
    applyHighlightedColor(entity.OrgTaxId);
    setSelectedEntity(entity);
    setSettingsType(modalType);
    setShowConfirmModal(true);
    //setIsEditMode(false);
  };
  const getInheritConfirmModal = (event) => {
    setIsEditMode(false);
    if (event.target.checked) {
      setShowInheritFromParentConfirmModal(true);
    } else {
      setShowConfirmRemoveInheritModal(true);
    }
  };
  const closeConfirmModal = () => {
    if (showConfirmRemoveInheritModal) {
      setShowConfirmRemoveInheritModal(false);
    } else if (showInheritFromParentConfirmModal) {
      setShowInheritFromParentConfirmModal(false);
    } else {
      setShowConfirmModal(false);
      applyHighlightedColor(null);
      setSelectedEntity(new TaxSurchargesModel());
    }
  };
  const addUpdateEntity = (updateEntity) => {
    const req = JSON.parse(JSON.stringify(taxesAndSurchargesSettingsData));
    if (!updateEntity.OrgTaxId) {
      req.OrgTaxList.push(updateEntity);
    } else {
      req.OrgTaxList.map((entity, index) => {
        if (entity.OrgTaxId === updateEntity.OrgTaxId) {
          req.OrgTaxList[index] = { ...updateEntity };
          applyHighlightedColor(updateEntity.OrgTaxId);
          return false;
        }
      });
    }
    updateData(req);
  };
  const removeEntity = () => {
    const req = JSON.parse(JSON.stringify(taxesAndSurchargesSettingsData));
    const entityListFilter = req.OrgTaxList.filter(
      (x) => x.OrgTaxId !== selectedEntity.OrgTaxId
    );
    req.OrgTaxList = [...entityListFilter];
    updateData(req);
  };
  const doInheritFunctionality = () => {
    updateData(
      taxesAndSurchargesSettingsData,
      showConfirmRemoveInheritModal ? false : true
    );
  };
  const updateData = (req, isInheritedFromParent) => {
    const reqObj = {
      ...req,
      ...getInheritedProperties(
        currentOrgUnitId,
        req.SelectedChildOrgUnitId || selectedOrgLocationId,
        typeof isInheritedFromParent !== "undefined"
          ? isInheritedFromParent
          : req.InheritedFromParent,
        dependentOrgLength
      ),
    };
    delete reqObj["DisplayInherited"];
    delete reqObj["InheritedFromParent"];
    dispatch(updateTaxSurchargeInfoReset());
    dispatch(updateTaxSurchargeInfo(reqObj));
  };
  const disabled =
    taxesAndSurchargesSettingsData.InheritedFromParent ||
    !hasEditPermission ||
    props.isInheritModal
      ? true
      : false;
  const renderCommmonLabel = (value, lblId, classNames) => {
    return (
      <CommonLabel
        className={
          classNames || "col-lg-12 p-0 text-truncate-block  padding-settings-5"
        }
        value={value}
        lblId={lblId}
      />
    );
  };
  const isAddButtonVisible = (type) => {
    const taxesSurchargesList =
      taxesAndSurchargesSettingsData &&
      taxesAndSurchargesSettingsData.OrgTaxList.length > 0
        ? taxesAndSurchargesSettingsData.OrgTaxList.filter(
            (data) => data.TaxType === type
          )
        : [];
    if (
      hasEditPermission &&
      !taxesAndSurchargesSettingsData.InheritedFromParent &&
      !disabled &&
      taxesSurchargesList.length < 10
    ) {
      return true;
    }
    return false;
  };
  const renderTaxesList = (type) => {
    const taxesSurchargesList =
      taxesAndSurchargesSettingsData &&
      taxesAndSurchargesSettingsData.OrgTaxList.length > 0
        ? taxesAndSurchargesSettingsData.OrgTaxList.filter(
            (data) => data.TaxType === type
          )
        : [];
    if (taxesSurchargesList.length === 0) {
      return (
        <tr>
          <td colSpan="9">
            <NoRecordFoundComponent
              className="border-none"
              innerClassName="no-setting-record-found"
              searchMsg="No Record Found"
              heights="5rem"
            />
          </td>
        </tr>
      );
    } else {
      return taxesSurchargesList.map((entity, index) => {
        return (
          <tr
            className={selectedRow === entity.OrgTaxId ? highlightedClass : ""}
            key={entity.OrgTaxId}
          >
            <td>
              {type === "TAX"
                ? "Tax " + (index + 1)
                : "Surcharge " + (index + 1)}
            </td>
            <td className="text-left">{entity.DisplayName}</td>
            <td>{getUnitDisplayValue(entity.AmountType)}</td>
            <td>{formatAmountValue(entity.Amount)}</td>
            <td>{getUnitTypeValue(entity.RecurrenceUnit)}</td>
            <td>{convertDateTimeString(entity.AsOfDate, "--")}</td>
            <td>{convertDateTimeString(entity.EndDate, "--")}</td>
            <td className="text-center">
              {props.isInheritModal ? (
                ""
              ) : (
                <Fragment>
                  <ActionIcon
                    iconId={"edit_" + type + entity.DummyId}
                    className="fas fa-pencil-alt pr-2 fs-3"
                    fontColor="text-primary"
                    actionFunction={getAddUpdateEntityModal}
                    disabled={disabled}
                    value="Edit"
                    param1={entity}
                    param2={type}
                  />
                  <ActionIcon
                    iconId={"delete_" + type + entity.DummyId}
                    className="fa fa-times fs-3"
                    fontColor="text-danger"
                    actionFunction={getConfirmModal}
                    disabled={disabled}
                    value="Delete"
                    param1={entity}
                    param2={type}
                  />
                </Fragment>
              )}
            </td>
          </tr>
        );
      });
    }
  };
  const renderCheckbox = (labelCaption, name) => {
    return (
      <div className={`form-group formRow`}>
        <CommonInputField
          elementConfig={{
            id: getCamelCaseId(name),
            type: "checkbox",
            checked: taxesAndSurchargesSettingsData[name] || false,
            name: name,
            disabled: disabled || !isEditMode,
          }}
          otherConfig={{
            caption: labelCaption,
          }}
          nodeElement="checkbox"
          onChange={inputChangedHandler}
          valueClasses={name === "ApplyTaxAfterDiscount" ? "pl-4" : ""}
        />
      </div>
    );
  };

  const renderMainLayout = () => {
    return (
      <Fragment>
        <div
          className="col-lg-12 mb-2  font-weight-bold  d-flex"
          style={{ height: "2.5rem" }}
        >
          <label className="fs-2 mr-auto">Taxes:</label>
          <label className="font-weight-bold pl-5">Tax ID : </label>
          <span className="pl-2 col-lg-1" style={{ marginTop: "-0.2rem" }}>
            {isEditMode ? (
              <input
                type="text"
                name="TaxId"
                value={taxesAndSurchargesSettingsData.TaxId || ""}
                onChange={(event) => inputChangedHandler(event, "TaxId")}
              />
            ) : (
              renderCommmonLabel(
                taxesAndSurchargesSettingsData.TaxId,
                "lblTaxId",
                "text-truncate-block padding-settings-5"
              )
            )}
          </span>
          {renderCheckbox(
            "Apply Taxes after Discount",
            "ApplyTaxAfterDiscount"
          )}
          {isAddButtonVisible("TAX") ? (
            <LinkButton
              btnLabel="Add New Tax"
              containerClass="pseudo-link pr-1 fs-2 font-weight-bold pl-5"
              iconClass="fas fa-plus-circle iconBeforeLink"
              clicked={(event) => getAddUpdateEntityModal(null, "TAX")}
            />
          ) : (
            ""
          )}
        </div>

        <div
          className="col-lg-12 tableScroll bb-0 table-fixed-head table-container"
          style={{ maxHeight: "60rem" }}
        >
          <table className="table table-striped">
            <thead className="thead-primary">
              <tr>
                <th />
                <th>Description</th>
                <th>Unit Type</th>
                <th>Rate</th>
                <th>Type</th>
                <th>Start Date</th>
                <th>End Date</th>
                <th />
              </tr>
            </thead>
            <tbody>{renderTaxesList("TAX")}</tbody>
          </table>
        </div>
        <div
          className="col-lg-12 mb-2 mt-5 font-weight-bold  d-flex"
          style={{ height: "2.5rem" }}
        >
          <label className="fs-2  mr-auto">Surcharges:</label>
          {renderCheckbox(
            "Apply Surcharge after Discount",
            "ApplySurchargeAfterDiscount"
          )}

          {isAddButtonVisible("SURCHARGE") ? (
            <LinkButton
              btnLabel="Add New Surcharges"
              containerClass="pseudo-link pr-1 fs-2 font-weight-bold  pl-5"
              iconClass="fas fa-plus-circle iconBeforeLink"
              clicked={(event) => getAddUpdateEntityModal(null, "SURCHARGE")}
            />
          ) : (
            ""
          )}
        </div>
        <div
          className="col-lg-12 tableScroll bb-0 table-fixed-head table-container"
          style={{ maxHeight: "60rem" }}
        >
          <table className="table table-striped">
            <thead className="thead-primary">
              <tr>
                <th />
                <th>Description</th>
                <th>Unit Type</th>
                <th>Rate</th>
                <th>Type</th>
                <th>Start Date</th>
                <th>End Date</th>
                <th />
              </tr>
            </thead>
            <tbody>{renderTaxesList("SURCHARGE")}</tbody>
          </table>
        </div>
      </Fragment>
    );
  };

  return props.isInheritModal ? (
    renderMainLayout()
  ) : (
    <Fragment>
      <PreventTransitionPrompt when={isBlocking} />
      <div className="col-lg-8 pt-3 pl-0" id="main-settings">
        <div className="card">
          <div className="card-body">
            <div className="form-header row col-lg-12 p-0">
              <h4 className="text-uppercase mr-auto icon-display-none">
                <i className="fas fa-money-check-alt"></i>
                Taxes & Surcharges
              </h4>
              {hasEditPermission &&
              !isEditMode &&
              !taxesAndSurchargesSettingsData.InheritedFromParent ? (
                <LinkButton
                  btnLabel="Edit Settings"
                  containerClass="pseudo-link pr-1 fs-2 font-weight-bold mr-5 d-flex align-items-center"
                  stackClass="fa-stack fs-08 vertical-align-top"
                  faIconList={[
                    "fa fa-circle fa-stack-2x iconBeforeLink",
                    "fa fa-pencil-alt fa-stack-1x whiteColor pr-1",
                  ]}
                  clicked={enableEditMode}
                />
              ) : (
                ""
              )}
              <RenderInheritedCheckBox
                checked={taxesAndSurchargesSettingsData.InheritedFromParent}
                onChange={(event) => getInheritConfirmModal(event)}
                disabled={!hasEditPermission}
                displayEnable={taxesAndSurchargesSettingsData.DisplayInherited}
              />
            </div>
            {renderMainLayout()}
            <div
              className="settingFooter  col-sm-12"
              style={{ textAlign: "center" }}
            >
              {!disabled && isEditMode ? (
                <Fragment>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => updateData(taxesAndSurchargesSettingsData)}
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    name="className"
                    className="btn btn-secondary ml-2"
                    onClick={() => onCancel()}
                  >
                    Cancel
                  </button>
                </Fragment>
              ) : (
                <div style={{ height: "2.5rem" }}>&nbsp;</div>
              )}
            </div>
          </div>
        </div>
        {loading ? <Spinner /> : ""}
        {showAddEntityModal ? (
          <TaxSurchargesModal
            showModal={showAddEntityModal}
            closeModal={closeAddEntityModal}
            selectedEntity={selectedEntity}
            addUpdateEntity={addUpdateEntity}
            settingsType={settingsType}
          />
        ) : (
          ""
        )}
        {showConfirmModal || showConfirmRemoveInheritModal ? (
          <ConfirmModal
            body={
              showConfirmModal
                ? COMMON_MSG.DeleteRecordMsg
                : COMMON_MSG.RemoveInheritParentConfirm
            }
            showModal={showConfirmModal || showConfirmRemoveInheritModal}
            closeModal={closeConfirmModal}
            doConfirmFunctionality={
              showConfirmModal ? removeEntity : doInheritFunctionality
            }
          />
        ) : (
          ""
        )}
        {showInheritFromParentConfirmModal ? (
          <InheritFromParentConfirmModal
            showModal={showInheritFromParentConfirmModal}
            closeModal={closeConfirmModal}
            doInheritFunctionality={doInheritFunctionality}
            currentOrgUnitId={currentOrgUnitId}
            modalWidth="80rem"
            componentName={TaxesSurchargesSettings}
          />
        ) : (
          ""
        )}
      </div>
    </Fragment>
  );
};

export default TaxesSurchargesSettings;

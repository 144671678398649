import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as XLSX from "xlsx";
import ConfirmModal from "../../../components/common/modal/confirm-modal";
import LinkButton from "../../../components/UI/button/link-btn";
import Spinner from "../../../components/UI/Spinner/Spinner";
import { showNotification } from "../../../components/UI/Toaster/Toaster";
import { CapacityPlanningViewModel } from "../../../services/entities/view-models/settings-view-models";
import { ADMIN_PAGE, COMMON_MSG, TOAST_MSG } from "../../../shared/GlobalVar";
import PermissionName from "../../../shared/Permissions";
import {
  getBasicToastResponse,
  hasPermission,
  isNullOrEmpty
} from "../../../shared/utility";
import {
  getSettingstDataByApi,
  getSettingstDataByApiReset,
  updateSettingstDataByApi,
  updateSettingstDataByApiReset
} from "../../../store/actions/index";
import InheritFromParentConfirmModal from "../modal/inherit-from-parent-confirm-modal";
import { RenderInheritedCheckBox, getAPiNameForSettings, getDayOfWeekBySerial, getInheritedProperties, getTimeStrObjForOneHour } from "../settingsHelper";
import CapacityPlanningImportModal from "./modal/capacity-planning-import-modal";

const CapacityPlanningSettings = (props) => {
  const loading = useSelector((state) => state.settingsReducer.loading);
  const currentOrgUnitId = useSelector(
    (state) => state.auth.loggedInUserData.CurrentOrgUnitId
  );

  const dependentOrgLength = useSelector((state) =>
    state.auth.loggedInUserData.DependantOrgList
      ? state.auth.loggedInUserData.DependantOrgList.length
      : 0
  );

  const capacityBucketResponse = useSelector(
    (state) => !props.isInheritModal ? state.settingsReducer.settingsResponse : state.settingsReducer.inheritedSettingsResponse
  );
  const capacityBucketUpdateResponse = useSelector(
    (state) => state.settingsReducer.updateSettingsResponse
  );

  const dispatch = useDispatch();

  const query = props.location
    ? new URLSearchParams(props.location.search)
    : null;
  const selectedOrgLocationId =
    props.otherData && props.otherData.selectedOrgLocationId
      ? props.otherData.selectedOrgLocationId
      : query
        ? query.get("orgId")
        : currentOrgUnitId;
  const [isEditMode, setIsEditMode] = useState(false);
  const [capacityData, setCapacityData] = useState(
    new CapacityPlanningViewModel()
  );
  const [
    showConfirmRemoveInheritModal,
    setShowConfirmRemoveInheritModal,
  ] = useState(false);
  const [
    showInheritFromParentConfirmModal,
    setShowInheritFromParentConfirmModal,
  ] = useState(false);
  const [importedXLData, setImportedXLData] = useState(null);
  const [showCapacityImportModal, setShowCapacityImportModal] = useState(false);


  const hasEditPermission = hasPermission(
    PermissionName.SAVE_CAPACITY_BUCKETS_INFO
  );
  useEffect(() => {
    getCapacityData();
  }, [selectedOrgLocationId]);
  useEffect(() => {
    if (!capacityBucketResponse) {
      return;
    }
    if (capacityBucketResponse.StatusCode === 0) {
      loadCapacityPlanningViewData(JSON.parse(JSON.stringify(capacityBucketResponse)));
      setIsEditMode(false);
      closeCapacityPlanningExcelModal();
    }
    dispatch(getSettingstDataByApiReset(props.isInheritModal));
  }, [capacityBucketResponse]);

  useEffect(() => {
    if (!capacityBucketUpdateResponse) {
      return;
    }
    showNotification(getBasicToastResponse(capacityBucketUpdateResponse));
    if (capacityBucketUpdateResponse.StatusCode === 0) {
      dispatch(updateSettingstDataByApiReset());
      setShowInheritFromParentConfirmModal(false);
      setShowConfirmRemoveInheritModal(false);
      setIsEditMode(false);
      closeCapacityPlanningExcelModal();
    } else {
      dispatch(updateSettingstDataByApiReset());
    }
  }, [capacityBucketUpdateResponse]);

  const loadCapacityPlanningViewData = (data) => {
    if (!data?.CapacityBuckets) {
      return;
    }
    setCapacityData(
      new CapacityPlanningViewModel(
        data,
        currentOrgUnitId,
        parseInt(selectedOrgLocationId, 10)
      )
    );

  };
  const inputChangedHandler = (event, data) => {
    let value = event.target.value;
    if (value && !Number.isInteger(parseInt(value))) {
      value = data.CapacityValue ? data.CapacityValue : 0;
    }
    else if (value && value < 0) {
      value = data.CapacityValue ? data.CapacityValue : 0;
    }
    let maindata = { ...capacityData };
    if (!maindata.CapacityBuckets) {
      maindata.CapacityBuckets = [];
    }
    if (!data.IsNew) {
      let spliceIndex = null;
      maindata.CapacityBuckets.map((info, i) => {
        if (info.StartTime === data.StartTime
          && info.EndTime === data.EndTime
          && info.DayOfWeek === data.DayOfWeek) {
          if (isNullOrEmpty(value)) {
            spliceIndex = i;
          } else {
            maindata.CapacityBuckets[i].CapacityValue = value;
          }
          return null;
        }
        return null;
      });
      if (Number.isInteger(spliceIndex)) {
        maindata.CapacityBuckets.splice(spliceIndex, 1);
      }
    } else if (value) {
      maindata.CapacityBuckets.push({
        StartTime: data.StartTime,
        EndTime: data.EndTime,
        CapacityValue: value,
        DayOfWeek: data.DayOfWeek,
        IsAvailable: data.IsAvailable,
      });
    }
    setCapacityData(maindata);
  };

  const onCancel = () => {
    setIsEditMode(false);
    loadCapacityPlanningViewData(JSON.parse(JSON.stringify(capacityBucketResponse)));
  };
  const enableEditMode = () => {
    setIsEditMode(true);
  };

  const getInheritConfirmModal = (event) => {
    setIsEditMode(false);
    if (event.target.checked) {
      setShowInheritFromParentConfirmModal(true);
    } else {
      setShowConfirmRemoveInheritModal(true);
    }
  };
  const closeConfirmModal = () => {
    if (showConfirmRemoveInheritModal) {
      setShowConfirmRemoveInheritModal(false);
    } else if (showInheritFromParentConfirmModal) {
      setShowInheritFromParentConfirmModal(false);
    }
  };
  const doInheritFunctionality = () => {
    updateData(capacityData, showConfirmRemoveInheritModal ? false : true);
  };
  const getCapacityData = () => {
    const req = {};
    req.SelectedChildOrgUnitId = !props.isInheritModal ? selectedOrgLocationId : currentOrgUnitId;
    dispatch(getSettingstDataByApiReset(props.isInheritModal));
    dispatch(getSettingstDataByApi(req, getAPiNameForSettings(ADMIN_PAGE.CAPACITY_SETTINGS), props.isInheritModal));
  };
  const updateData = (req, isInheritedFromParent) => {
    const reqObj = {
      ...req,
      ...getInheritedProperties(
        currentOrgUnitId,
        req.SelectedChildOrgUnitId || selectedOrgLocationId,
        typeof isInheritedFromParent !== "undefined"
          ? isInheritedFromParent
          : req.InheritedFromParent,
        dependentOrgLength
      ),
    };
    delete reqObj["DisplayInherited"];
    reqObj.InheritFromParent = isInheritedFromParent;
    /** beacuse server is not working properly for inherited feature, we manually off this feature*/
    reqObj.ApplyToAllInherited = false;
    dispatch(updateSettingstDataByApiReset());
    dispatch(updateSettingstDataByApi(reqObj, getAPiNameForSettings(ADMIN_PAGE.CAPACITY_SETTINGS, true)));
  };

  const doImportFunctionality = (capacityList) => {
    let maindata = { ...capacityData };
    const dummyList = [];
    capacityList.map((data) => {
      let isFound = false;
      maindata.CapacityBuckets.map((info, i) => {
        if (info.StartTime === data.StartTime && info.EndTime === data.EndTime && info.DayOfWeek === data.DayOfWeek) {
          dummyList.push({
            StartTime: data.StartTime,
            EndTime: data.EndTime,
            CapacityValue: data.CapacityValue,
            DayOfWeek: data.DayOfWeek.toUpperCase(),
            IsAvailable: data.IsAvailable
          });
          isFound = true;
          return 0;
        }
      });
      if (!isFound) {
        dummyList.push({
          StartTime: data.StartTime,
          EndTime: data.EndTime,
          CapacityValue: data.CapacityValue,
          DayOfWeek: data.DayOfWeek.toUpperCase(),
          IsAvailable: data.IsAvailable
        });
      }
    });
    maindata.CapacityBuckets.map((data) => {
      const dFilterList = dummyList.filter(c => (c.StartTime === data.StartTime && c.EndTime === data.EndTime && c.DayOfWeek === data.DayOfWeek));
      if (!dFilterList.length) {
        dummyList.push({
          StartTime: data.StartTime,
          EndTime: data.EndTime,
          CapacityValue: data.CapacityValue,
          DayOfWeek: data.DayOfWeek.toUpperCase(),
          IsAvailable: data.IsAvailable
        });
      }
    });
    maindata.CapacityBuckets = [...dummyList];
    updateData(maindata);
  };
  const disabled =
    capacityData.InheritedFromParent ||
      !hasEditPermission ||
      props.isInheritModal
      ? true
      : false;

  const getIuptuCtrl = (data, name, type) => {
    return (
      <input
        type={type}
        name={name}
        min="1"
        step="1"
        pattern="^[-/d]/d*$"
        value={!isNullOrEmpty(data.CapacityValue) ? data.CapacityValue : ""}
        onChange={(event) =>
          inputChangedHandler(event, data)
        }
        disabled={disabled}
      />
    );
  };
  const generateMaxCapacitybyDay = () => {
    var rows = [];
    const weekDaysCapacityData = capacityData && capacityData.CapacityBuckets ? capacityData.CapacityBuckets.filter(c => c.StartTime === "00:00" && c.EndTime === "23:59") : [];
    for (var i = 0; i < 7; i++) {
      let data = weekDaysCapacityData.length > 0
        ? weekDaysCapacityData.filter(c => c.StartTime === "00:00" && c.EndTime === "23:59" && c.DayOfWeek === getDayOfWeekBySerial(i))[0]
        : null;
      if (!data) {
        data = {};
        data.CapacityValue = null;
        data.StartTime = "00:00";
        data.EndTime = "23:59";
        data.DayOfWeek = getDayOfWeekBySerial(i);
        data.IsAvailable = true;
        data.IsNew = true;
      }
      if (i === 0) {
        rows.push(
          <Fragment key={i}>
            <td colSpan="1" className="cell-table-header">
              Max Capacity <br /> by Day
            </td>
            <td className="normal-cell">
              {isEditMode ? (
                getIuptuCtrl(data, "hour" + i, "number")
              ) : (
                <label>{!isNullOrEmpty(data.CapacityValue) ? data.CapacityValue : ""}</label>
              )}
            </td>
          </Fragment>
        );
      } else {
        rows.push(
          <td className="normal-cell" key={i}>
            {isEditMode ? (
              getIuptuCtrl(data, "hour" + i, "number")
            ) : (
              <label>{!isNullOrEmpty(data.CapacityValue) ? data.CapacityValue : ""}</label>
            )}
          </td>
        );
      }
    }
    return <tr>{rows}</tr>;
  };
  const getHourPeriodLabel = (rowIndex) => {
    const timeStrObj = getTimeStrObjForOneHour(rowIndex);
    return <label>{timeStrObj.startTime + " - " + timeStrObj.endTime}</label>;
  };
  const generateMaxCapacitybyHourRows = (rowIndex) => {
    var rows = [];
    const hourlyCapacityData = capacityData && capacityData.CapacityBuckets ? capacityData.CapacityBuckets.filter(c => (c.StartTime === "00:00" && c.EndTime === "23:59") ? false : true) : [];
    const timeStrObj = getTimeStrObjForOneHour(rowIndex);
    const dataRows = hourlyCapacityData.length > 0 ? hourlyCapacityData.filter(c => c.StartTime === timeStrObj.startTime && c.EndTime === timeStrObj.endTime) : [];

    for (var i = 0; i < 7; i++) {
      let data = dataRows.length > 0 ? dataRows.filter(c => c.DayOfWeek === getDayOfWeekBySerial(i))[0] : null;
      if (!data || !data.StartTime) {
        data = {};
        data.StartTime = timeStrObj.startTime;
        data.EndTime = timeStrObj.endTime;
        data.CapacityValue = null;
        data.DayOfWeek = getDayOfWeekBySerial(i);
        data.IsAvailable = true;
        data.IsNew = true;
      }
      if (i === 0) {
        rows.push(
          <Fragment key={i}>
            <td className="preiod-row">{getHourPeriodLabel(rowIndex)}</td>
            <td className="normal-cell">
              {isEditMode ? (
                getIuptuCtrl(data, "hour" + i, "number")
              ) : (
                <label>{!isNullOrEmpty(data.CapacityValue) ? data.CapacityValue : ""}</label>
              )}
            </td>
          </Fragment>
        );
      } else {
        rows.push(
          <td className="normal-cell" key={i}>
            {isEditMode ? (
              getIuptuCtrl(data, "hour" + i, "number")
            ) : (
              <label>{!isNullOrEmpty(data.CapacityValue) ? data.CapacityValue : ""}</label>
            )}
          </td>
        );
      }
    }
    return <tr key={rowIndex}>{rows}</tr>;
  };
  const generateMaxCapacitybyHour = () => {
    var rowList = [];
    for (var i = 0; i < 24; i++) {
      rowList.push(generateMaxCapacitybyHourRows(i));
    }
    return rowList;
  };
  /*import external data*/
  const clickFileUploaderCtrl = () => {
    document.getElementById("capacityFileUploader").click();
  };
  const validFileTypes = ["xlsx", "xlsb", "xlsm", "xls", "xml", "csv", "txt"];
  const validateFile = (fileObj) => {
    let msg = null;
    if (validFileTypes.includes(fileObj.type)) {
      msg = msg
        ? msg + " Only doc file is acceptable."
        : "Only doc file is acceptable.";
    }
    if (msg) {
      var toastMsg = {
        msg: msg,
        toastType: TOAST_MSG.ERROR,
      };
      showNotification(toastMsg);
      return false;
    }
    return true;
  };
  const loadNewFile = (event) => {
    const fileObj = event.target.files[0];
    if (validateFile(fileObj)) {
      const reader = new FileReader();
      const rABS = !!reader.readAsBinaryString;
      reader.onload = ({ target: { result } }) => {
        const wb = XLSX.read(result, { type: rABS ? "binary" : "array" });
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        /* Convert array of arrays */
        const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
        setImportedXLData(convertToJson(data));
        setShowCapacityImportModal(true);
      };
      if (rABS) reader.readAsBinaryString(fileObj);
      else reader.readAsArrayBuffer(fileObj);
      document.getElementById("capacityFileUploader").value = "";
    }
  };
  const convertToJson = (csv) => {
    var lines = csv.split("\n");

    var result = [];

    var headers = lines[0].split(",");

    for (var i = 1; i < lines.length; i++) {
      var obj = {};
      var currentline = lines[i].split(",");
      if (result.length + 2 < lines.length) {
        for (var j = 0; j < headers.length; j++) {
          obj[headers[j]] = currentline[j];
        }
        result.push(obj);
      }
    }
    return result; //JavaScript object
  };
  const closeCapacityPlanningExcelModal = () => {
    setShowCapacityImportModal(false);
  };
  const renderMainLayout = () => {
    return (
      <Fragment>
        <div
          className="col-lg-12 mb-2 mt-3 parent-price capacity-table"
          style={{ width: props.isInheritModal ? "100%" : "80%" }}
        >
          <table className="table table-striped">
            <thead className="thead-primary">
              <tr>
                <th colSpan="1" className="cell-white border-none"></th>
                <th
                  colSpan="7"
                  className="cell-white"
                  style={{ color: "#1e4e9c" }}
                >
                  <h3 className="h3-title">
                    Maximum Pickup Capacity by Day of Week
                    {!disabled && isEditMode ? (
                      <div
                        className="settingFooter  col-sm-12"
                        style={{ textAlign: "center", marginTop: "2rem" }}
                      >
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={() => updateData(capacityData)}
                        >
                          Save
                        </button>
                        <button
                          type="button"
                          name="className"
                          className="btn btn-secondary ml-2"
                          onClick={() => onCancel()}
                        >
                          Cancel
                        </button>
                      </div>
                    ) : (
                      ""
                    )}
                  </h3>
                  <hr />
                </th>
              </tr>
              <tr>
                <th className="cell-white"></th>
                <th>Sunday</th>
                <th>Monday</th>
                <th>Tuesday</th>
                <th>Wednesday</th>
                <th>Thursday</th>
                <th>Friday</th>
                <th>Saturday</th>
              </tr>
            </thead>
            <tbody>
              {generateMaxCapacitybyDay()}
              <tr>
                <td
                  colSpan="9"
                  className="cell-white"
                  style={{ height: "25px" }}
                ></td>
              </tr>
              {generateMaxCapacitybyHour()}
            </tbody>
          </table>
        </div>
      </Fragment>
    );
  };
  return capacityData !== null ? (
    <div className="col-lg-12 pt-3 pl-0" id="main-settings">
      <div className="card">
        <div className="card-body">
          <div className="form-header row col-lg-12 p-0">
            <h4 className="text-uppercase mr-auto icon-display-none">
              <i className="fas fa-building" aria-hidden="true"></i>
              Capacity Planning
            </h4>
            {hasEditPermission && !disabled ?
              <LinkButton
                btnLabel="Import"
                containerClass="pseudo-link pr-1 fs-2 font-weight-bold mr-5 d-flex align-items-center"
                stackClass="fa-stack fs-08 vertical-align-top"
                faIconList={[
                  "fa fa-circle fa-stack-2x iconBeforeLink",
                  "fa fa-upload fa-stack-1x whiteColor pr-1",
                ]}
                clicked={clickFileUploaderCtrl}
              /> : ""}
            {hasEditPermission && !disabled ? (
              <LinkButton
                btnLabel="Edit Settings"
                containerClass="pseudo-link pr-1 fs-2 font-weight-bold mr-5 d-flex align-items-center"
                stackClass="fa-stack fs-08 vertical-align-top"
                faIconList={[
                  "fa fa-circle fa-stack-2x iconBeforeLink",
                  "fa fa-pencil-alt fa-stack-1x whiteColor pr-1",
                ]}
                clicked={enableEditMode}
              />
            ) : (
              ""
            )}
            <RenderInheritedCheckBox
              checked={capacityData.InheritedFromParent}
              onChange={(event) => getInheritConfirmModal(event)}
              disabled={!hasEditPermission}
              displayEnable={capacityData.DisplayInherited}
            />
          </div>
          {renderMainLayout()}
          {!disabled && isEditMode ? (
            <div
              className="settingFooter  col-sm-12"
              style={{ textAlign: "center", marginTop: "2rem" }}
            >
              <Fragment>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => updateData(capacityData)}
                >
                  Save
                </button>
                <button
                  type="button"
                  name="className"
                  className="btn btn-secondary ml-2"
                  onClick={() => onCancel()}
                >
                  Cancel
                </button>
              </Fragment>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <input
        id="capacityFileUploader"
        type="file"
        accept={validFileTypes}
        style={{ display: "none" }}
        onChange={(event) => loadNewFile(event)}
      />
      {loading && !props.isInheritModal ? <Spinner /> : ""}
      {showConfirmRemoveInheritModal ? (
        <ConfirmModal
          body={COMMON_MSG.RemoveInheritParentConfirm}
          showModal={showConfirmRemoveInheritModal}
          closeModal={closeConfirmModal}
          doConfirmFunctionality={doInheritFunctionality}
        />
      ) : (
        ""
      )}
      {showInheritFromParentConfirmModal ? (
        <InheritFromParentConfirmModal
          showModal={showInheritFromParentConfirmModal}
          closeModal={closeConfirmModal}
          doInheritFunctionality={doInheritFunctionality}
          currentOrgUnitId={currentOrgUnitId}
          modalWidth="110rem"
          modalHeight="50rem"
          componentName={CapacityPlanningSettings}
        />
      ) : (
        ""
      )}
      {showCapacityImportModal &&
        importedXLData &&
        importedXLData.length > 0 ? (
        <CapacityPlanningImportModal
          showModal={showCapacityImportModal}
          closeModal={closeCapacityPlanningExcelModal}
          doImportFunctionality={doImportFunctionality}
          importedXLData={importedXLData}
        />
      ) : (
        ""
      )}
    </div>
  ) : (
    ""
  );
};
export default CapacityPlanningSettings;

import { invokeServerAPI } from "../../services/httpService";
import * as actionTypes from "./actionTypes-shuttle-app";
const getVicinityStart = (actionType,showLoader) => {
  return {
    type: actionType,
    showLoader:showLoader
  };
};
const getVicinityFail = (actionType, error) => {
  return {
    type: actionType,
    error: error,
  };
};
const getVicinitySuccess = (response) => {
  return {
    type: actionTypes.VICINITY_SUCCESS,
    vicinityResponse: response,
  };
};

export const getVicinityReset = () => {
  return {
    type: actionTypes.VICINITY_RESET,
  };
};

export const getVicinity = (req,showLoader) => {
  return (dispatch) => {
    dispatch(getVicinityStart(actionTypes.VICINITY_START,showLoader));
    invokeServerAPI("shuttleapi/vicinity", req, true)
      .then((response) => {
        dispatch(getVicinitySuccess(response));
      })
      .catch((err) => {
        //   dispatch(getVicinityFail(actionTypes.VICINITY_FAIL, err));
      });
  };
};

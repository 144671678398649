import { setDataToLocalStorage } from "../../../../shared/LocalStorage";

export const ShuttleOptionsViewModel = function (data, shuttleOptionsRequest) {
  if (!data) data = {};
  if(data.landmarkCategories && data.landmarkCategories.length>0){
    setDataToLocalStorage("landmarkCategories",data.landmarkCategories);
  }
  if (!shuttleOptionsRequest) shuttleOptionsRequest = {};
  this.locations = data.locations || [];
  this.landmarkCategories = data.landmarkCategories || [];
  this.engineStatuses = data.engineStatuses || [];
  this.amenities = data.amenities || [];
  this.availabilities = data.availabilities || [];
  this.allShuttles = data.allShuttles || [];
  this.locationShuttles = data.locationShuttles || [];
  this.allDrivers = data.allDrivers || [];
  this.locationDrivers = data.locationDrivers || [];
  this.shuttleCodes = shuttleOptionsRequest.locationShuttles
    ? data.locationShuttles
    : shuttleOptionsRequest.allShuttles
    ? data.allShuttles
    : [];
  this.shuttleDrivers = shuttleOptionsRequest.locationDrivers
    ? data.locationDrivers
    : shuttleOptionsRequest.allDrivers
    ? data.allDrivers
    : [];
  this.drivingTypes = data.drivingTypes || null;
  this.fuelTypes = data.fuelTypes || null;
  this.fuelConsumptionUnitTypes = data.fuelConsumptionUnitTypes || false;
  this.transMissionTypes = data.transMissionTypes || false;
  this.colorTypes = data.colorTypes || false;
  this.landmarkStatuses = data.landmarkStatuses || false;
  this.imperial = data.unitSystems ? data.unitSystems.IMPERIAL : [];
  this.metric = data.unitSystems ? data.unitSystems.METRIC : [];
  this.unitSystem = data.unitSystems ? data.unitSystems.UNIT_SYSTEM : [];
};

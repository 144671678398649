import * as actionTypes from "./actionTypes";
import HttpService from "../../services/httpService";

export const getAssetInOutSummaryStart = () => {
  return {
    type: actionTypes.GET_ASSET_IN_OUT_SUMMARY_START
  };
};
export const getAssetInOutSummarySuccess = response => {
  return {
    type: actionTypes.GET_ASSET_IN_OUT_SUMMARY_SUCCESS,
    assetInOutSummaryResponse: response
  };
};
export const getAssetInOutSummaryFail = error => {
  return {
    type: actionTypes.GET_ASSET_IN_OUT_SUMMARY_FAIL,
    error: error
  };
};
export const getAssetInOutSummaryReset = () => {
  return {
    type: actionTypes.GET_ASSET_IN_OUT_SUMMARY_RESET
  };
};
export const getAssetInOutSummary = req => {
  req = req || {};
  let personAssetInOutSummaryReqObj = {};
  personAssetInOutSummaryReqObj.StartDate = req.StartDate || null;
  personAssetInOutSummaryReqObj.EndDate = req.EndDate || null;
  personAssetInOutSummaryReqObj.InRentalEnabled = req.InRentalEnabled || true;
  personAssetInOutSummaryReqObj.OutRentalEnabled = req.OutRentalEnabled || true;
  return dispatch => {
    dispatch(getAssetInOutSummaryStart());
    HttpService.getPersonAssetInOutSummary(personAssetInOutSummaryReqObj)
      .then(response => {
        dispatch(getAssetInOutSummarySuccess(response));
      })
      .catch(err => {
        //dispatch(getAssetInOutSummaryFail(err));
      });
  };
};

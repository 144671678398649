import PageInfo from "../models/PageInfo";

export const VehicleDetailsRequest = function () {
  this.MemberId = null;
  this.MemberAssetId = null;
  this.PromisedStartTime = null;
};
export const MarkVehicleStatusInputRequset = function () {
  this.MemberId = null;
  this.MemberAssetId = null;
  this.PromisedStartTime = null;
  this.PromisedEndTime = null;
  this.Category = null;
  this.Comments = null;
  this.MaintainTicket = null;
  this.Priority = null;
};

import React, { useEffect, useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getQualificationInfo,
  getQualificationInfoReset,
  updateQualificationInfo,
  updateQualificationInfoReset,
} from "../../../store/actions/index";
import Spinner from "../../../components/UI/Spinner/Spinner";
import {
  updateObject,
  getBasicToastResponse,
  hasPermission,
} from "../../../shared/utility";
import PermissionName from "../../../shared/Permissions";
import { showNotification } from "../../../components/UI/Toaster/Toaster";
import { QualificationSettingsViewModel } from "../../../services/entities/view-models/settings-view-models";
import LinkButton from "../../../components/UI/button/link-btn";
import { CommonLabel } from "../../../components/UI/label/commmon-label";
import {
  getInheritedProperties,
  RenderInheritedCheckBox,
} from "../settingsHelper";
import { COMMON_MSG } from "../../../shared/GlobalVar";
import ConfirmModal from "../../../components/common/modal/confirm-modal";
import InheritFromParentConfirmModal from "../modal/inherit-from-parent-confirm-modal";
import PreventTransitionPrompt from "../../../components/Navigations/prevent-navigation/prevent-transition-prompt";
import CommonInputField from "../../../components/UI/Input/common-input-field";

const QualificationsSettings = (props) => {
  const loading = useSelector((state) => state.settingsReducer.loading);
  const currentOrgUnitId = useSelector(
    (state) => state.auth.loggedInUserData.CurrentOrgUnitId
  );
  const qualificationInfoResponse = useSelector(
    (state) => state.settingsReducer.qualificationInfoResponse
  );
  const qualificationInfoUpdateResponse = useSelector(
    (state) => state.settingsReducer.qualificationInfoUpdateResponse
  );

  const dependentOrgLength = useSelector((state) =>
    state.auth.loggedInUserData.DependantOrgList
      ? state.auth.loggedInUserData.DependantOrgList.length
      : 0
  );
  const dispatch = useDispatch();
  const query = props.location
    ? new URLSearchParams(props.location.search)
    : null;
  const selectedOrgLocationId =
    props.otherData && props.otherData.selectedOrgLocationId
      ? props.otherData.selectedOrgLocationId
      : query
      ? query.get("orgId")
      : currentOrgUnitId;

  const [isEditMode, setIsEditMode] = useState(false);
  const [qualificationData, setQualificationData] = useState(
    new QualificationSettingsViewModel()
  );
  const [
    showConfirmRemoveInheritModal,
    setShowConfirmRemoveInheritModal,
  ] = useState(false);
  const [
    showInheritFromParentConfirmModal,
    setShowInheritFromParentConfirmModal,
  ] = useState(false);
  const hasEditPermission = hasPermission(
    PermissionName.EDIT_QUALIFICATION_ORG_SETTINGS
  );
  const [isBlocking, setIsBlocking] = useState(false);

  useEffect(() => {
    getQualificationData();
  }, [selectedOrgLocationId]);

  useEffect(() => {
    if (!qualificationInfoResponse) {
      return;
    }
    if (qualificationInfoResponse.StatusCode === 0) {
      if (showInheritFromParentConfirmModal) {
        return;
      }
      setQualificationData(
        new QualificationSettingsViewModel(
          qualificationInfoResponse,
          currentOrgUnitId,
          parseInt(selectedOrgLocationId, 10)
        )
      );
      setIsEditMode(false);
    } else {
      showNotification(getBasicToastResponse(qualificationInfoResponse));
    }
  }, [qualificationInfoResponse]);

  useEffect(() => {
    if (!qualificationInfoUpdateResponse) {
      return;
    }
    showNotification(getBasicToastResponse(qualificationInfoUpdateResponse));
    if (qualificationInfoUpdateResponse.StatusCode === 0) {
      setShowInheritFromParentConfirmModal(false);
      setShowConfirmRemoveInheritModal(false);
      setIsEditMode(false);
      setIsBlocking(false);
      dispatch(updateQualificationInfoReset());
    } else {
      dispatch(updateQualificationInfoReset());
    }
  }, [qualificationInfoUpdateResponse]);
  const enableEditMode = () => {
    setIsEditMode(true);
  };
  const inputChangedHandler = (event, controlName, elementType) => {
    let value =
      elementType === "checkbox" ? event.target.checked : event.target.value;
    let updatedControls = updateObject(qualificationData, {
      [controlName]:
        elementType === "radio" ? !qualificationData[controlName] : value,
    });
    setQualificationData(updatedControls);
    setIsBlocking(true);
  };
  const renderCommmonLabel = (value, lblId, classNames) => {
    return (
      <CommonLabel
        className={
          classNames || "col-lg-12 p-0 text-truncate-block padding-settings-5"
        }
        value={value}
        lblId={lblId}
      />
    );
  };
  const onCancel = () => {
    setIsEditMode(false);
    setQualificationData(
      new QualificationSettingsViewModel(
        qualificationInfoResponse,
        currentOrgUnitId,
        parseInt(selectedOrgLocationId, 10)
      )
    );
    setIsBlocking(false);
  };
  const getQualificationData = () => {
    const req = {};
    req.SelectedChildOrgUnitId = selectedOrgLocationId;
    dispatch(getQualificationInfoReset());
    dispatch(getQualificationInfo(req));
  };
  const getInheritConfirmModal = (event) => {
    setIsEditMode(false);
    if (event.target.checked) {
      setShowInheritFromParentConfirmModal(true);
    } else {
      setShowConfirmRemoveInheritModal(true);
    }
  };
  const closeConfirmModal = () => {
    if (showConfirmRemoveInheritModal) {
      setShowConfirmRemoveInheritModal(false);
    } else if (showInheritFromParentConfirmModal) {
      setShowInheritFromParentConfirmModal(false);
    }
  };
  const doInheritFunctionality = () => {
    updateData(qualificationData, showConfirmRemoveInheritModal ? false : true);
  };
  const updateData = (req, isInheritedFromParent) => {
    const reqObj = {
      ...req,
      ...getInheritedProperties(
        currentOrgUnitId,
        req.SelectedChildOrgUnitId || selectedOrgLocationId,
        typeof isInheritedFromParent !== "undefined"
          ? isInheritedFromParent
          : req.InheritedFromParent,
        dependentOrgLength
      ),
    };
    delete reqObj["DisplayInherited"];
    delete reqObj["InheritedFromParent"];
    dispatch(updateQualificationInfoReset());
    dispatch(updateQualificationInfo(reqObj));
  };
  const disabled =
    qualificationData.InheritedFromParent ||
    !hasEditPermission ||
    !isEditMode ||
    props.isInheritModal
      ? true
      : false;
  const parentRowClass = "form-group row";
  const valueClasses = "col-sm-6 row";
  const labelClasses = "col-sm-4 col-form-label font-weight-bold";
  const styleMaxWidth18 = { maxWidth: "18rem" };
  const styleMinWidth18 = { minWidth: "18rem" };
  const styleMaxWidth20 = { maxWidth: "20rem" };
  const renderRadioButtons = (id, value, labelCaption, name) => {
    return (
      <CommonInputField
        elementConfig={{
          id: id,
          type: "radio",
          name: name,
          disabled: !isEditMode,
          checked: qualificationData[name] === value,
        }}
        otherConfig={{
          caption: labelCaption,
        }}
        nodeElement="radio"
        valueClasses={labelCaption === "Yes" ? "pl-5" : ""}
        onChange={inputChangedHandler}
      />
    );
  };
  const renderMainLayout = () => {
    return (
      <fieldset disabled={disabled} className="border-none">
        <div className={parentRowClass}>
          <label
            className="col-sm-12 col-form-label font-weight-bold"
            style={styleMaxWidth18}
          >
            License Settings :
          </label>
        </div>
        <div className={parentRowClass}>
          <label style={styleMinWidth18}>&nbsp;</label>
          <label className={labelClasses} style={styleMaxWidth18}>
            License Details Required
          </label>
          <div className={valueClasses}>
            {renderRadioButtons(
              "rbtnNotRequired" + String(props.isInheritModal || false),
              false,
              "No",
              "LicenseDetailsRequired"
            )}
            {renderRadioButtons(
              "rbtnRequired" + String(props.isInheritModal || false),
              true,
              "Yes",
              "LicenseDetailsRequired"
            )}
          </div>
        </div>

        <div className={parentRowClass}>
          <label style={styleMinWidth18}>&nbsp;</label>
          <label className={labelClasses} style={styleMaxWidth18}>
            Show License Info
          </label>
          <div className={valueClasses}>
            {renderRadioButtons(
              "rbtnNotLicensed" + String(props.isInheritModal || false),
              false,
              "No",
              "ShowLicenseInfo"
            )}
            {renderRadioButtons(
              "rbtnLicensed" + String(props.isInheritModal || false),
              true,
              "Yes",
              "ShowLicenseInfo"
            )}
          </div>
        </div>
        <div className={parentRowClass}>
          <label className={labelClasses} style={styleMaxWidth18}>
            Age Based Requirements :
          </label>
        </div>
        <div className={parentRowClass}>
          <label style={styleMinWidth18}>&nbsp;</label>
          <label className={labelClasses} style={styleMaxWidth18}>
            Minimum Age
          </label>
          <div className={valueClasses}>
            {isEditMode ? (
              <input
                type="Number"
                name="MinAgeRequiredForReservation"
                style={styleMaxWidth20}
                value={qualificationData.MinAgeRequiredForReservation || ""}
                onChange={(event) =>
                  inputChangedHandler(event, "MinAgeRequiredForReservation")
                }
              />
            ) : (
              renderCommmonLabel(
                qualificationData.MinAgeRequiredForReservation,
                "lblLocationCode"
              )
            )}
          </div>
        </div>
      </fieldset>
    );
  };
  return props.isInheritModal ? (
    renderMainLayout()
  ) : (
    <Fragment>
      <PreventTransitionPrompt when={isBlocking} />
      <div className="col-lg-12 pt-3 pl-0" id="main-settings">
        <div className="card">
          <div className="card-body">
            <div className="form-header row col-lg-6 p-0">
              <h4 className="text-uppercase mr-auto icon-display-none">
                <i className="fas fa-user-graduate"></i>
                Qualifications
              </h4>
              {hasEditPermission &&
              !isEditMode &&
              !qualificationData.InheritedFromParent ? (
                <LinkButton
                  btnLabel="Edit Settings"
                  containerClass="pseudo-link pr-1 fs-2 font-weight-bold mr-5 d-flex align-items-center"
                  stackClass="fa-stack fs-08 vertical-align-top"
                  faIconList={[
                    "fa fa-circle fa-stack-2x iconBeforeLink",
                    "fa fa-pencil-alt fa-stack-1x whiteColor pr-1",
                  ]}
                  clicked={enableEditMode}
                />
              ) : (
                ""
              )}
              <RenderInheritedCheckBox
                checked={qualificationData.InheritedFromParent}
                onChange={(event) => getInheritConfirmModal(event)}
                disabled={!hasEditPermission}
                displayEnable={qualificationData.DisplayInherited}
              />
            </div>
            <div className="col-lg-10 p-0">{renderMainLayout()}</div>
            <div
              className="settingFooter col-sm-6"
              style={{ textAlign: "center" }}
            >
              {!disabled ? (
                <Fragment>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => updateData(qualificationData)}
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    name="className"
                    className="btn btn-secondary ml-2"
                    onClick={() => onCancel()}
                  >
                    Cancel
                  </button>
                </Fragment>
              ) : (
                <div style={{ height: "2.5rem" }}>&nbsp;</div>
              )}
            </div>
          </div>
        </div>
        {loading ? <Spinner /> : ""}
        {showConfirmRemoveInheritModal ? (
          <ConfirmModal
            body={COMMON_MSG.RemoveInheritParentConfirm}
            showModal={showConfirmRemoveInheritModal}
            closeModal={closeConfirmModal}
            doConfirmFunctionality={doInheritFunctionality}
          />
        ) : (
          ""
        )}
        {showInheritFromParentConfirmModal ? (
          <InheritFromParentConfirmModal
            showModal={showInheritFromParentConfirmModal}
            closeModal={closeConfirmModal}
            doInheritFunctionality={doInheritFunctionality}
            currentOrgUnitId={currentOrgUnitId}
            modalWidth="80rem"
            componentName={QualificationsSettings}
          />
        ) : (
          ""
        )}
      </div>
    </Fragment>
  );
};
export default QualificationsSettings;

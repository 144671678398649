import moment from "moment";
import React, { useState } from "react";
import DateTimePicker from "react-widgets/lib/DateTimePicker";
import "../input-ctrl.scss";

export const DateTimeCtrl = (props) => {
    const {
        name,
        value,
        placeholder,
        onChange,
        disabled,
        required,
        dateFormat,
        iconClass,
        imgIco,
        date,
        time,
        parseInputFormat,
        dateTimePlaceholder,
        ...rest
    } = props;
    const [dateTimeCtrl, setDateTimeCtrl] = useState(null);
    const [isFocused, setIsFocused] = useState(false);

    const setOnClick = (e) => {
        if (dateTimeCtrl && !isFocused && e?.target?.value) {
            setDateTimeCtrl(null);
        } else {
            if (isFocused)
                setIsFocused(false);
            if (!dateTimeCtrl)
                setDateTimeCtrl(date ? "date" : time ? "time" : "date");
        }
    };
    const onToggle = (isOpen) => {
    }
    const setOnFocus = () => {
        if (!dateTimeCtrl && !isFocused) {
            setIsFocused(true);
            setDateTimeCtrl(date ? "date" : time ? "time" : "date");
        }
    };
    const resetDateTimePicker = () => {
        if (dateTimeCtrl)
            setDateTimeCtrl(null);
        if (isFocused)
            setIsFocused(false);
    };

    const onChangedHandler = (e) => {
        resetDateTimePicker();
        onChange(e, name);
    }

    return (
        <div className='input-ctrl position-relative'>
            {imgIco ? <img
                src={imgIco}
                alt=""
            /> : iconClass ? <i className={iconClass} aria-hidden="true"></i> : ""}
            {placeholder && <label className="place-holder">{placeholder}</label>}
            <DateTimePicker
                parse={parseInputFormat}
                name={name}
                value={value ? moment(value).toDate() : null}
                onFocus={() => setOnFocus()}
                onBlur={() => resetDateTimePicker()}
                onClick={(e) => setOnClick(e)}
                onToggle={onToggle}
                open={dateTimeCtrl}
                onChange={(event) => onChangedHandler(event)}
                onSelect={(event) => onChangedHandler(event)}
                placeholder={dateTimePlaceholder}
                required={required}
                date={date ? true : !time ? true : false}
                time={time || false}
                disabled={disabled || false}
                {...rest}
            />
            {time ? dateTimeCtrl ? <i className="fas fa-caret-up caret" onClick={() => setOnClick()}></i> : <i className="fas fa-caret-down caret" onClick={() => setOnClick()}></i> : ""}
        </div>
    )
}
export default DateTimeCtrl;
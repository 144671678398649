import React from "react";
import { ModalType, LicenseDocType } from "../../../shared/GlobalVar";
import LicenseApprovalModal from "./license-approval-modal";
import LicenseInfoModal from "./license-info-modal";
import LicenseDocModal from "./license-doc-modal";
import MemberQrCodeModal from "./member-qrcode-modal";
import LicenseUploaderModal from "./license-uploader-modal";
import AllAddresModal from "./all-address-modal";
import AddAddresModal from "./add-address-modal";
import AddQuickMemberModal from "./add-quick-member-modal";
import ConflictedMemberListModal from "./conflicted-member-list-modal";
import AgentModal from "../../agent/search-agent-modal";
import BlockUnblockMemberModal from "./block-unblock-member-modal";
import ChangeResetPassword from "./change-reset-password-modal";
import MemberActivation from "./member-activation-modal";
import MemberVerification from "./member-verification-modal";
import ImpersonateModal from "./impersonate-modal";
import AddViolationPoint from "./add-violation-point-modal";

export const MemberModalEngine = (props) => {
  let modalResponse = null;
  const commonProps = {
    showModal: props.showModal,
    closeModal: props.closeModal,
    memberId: props.memberId,
    modalType: props.modalType,
  };
  if (props.modalType === ModalType.LICENSE_APPROVAL_MODAL) {
    modalResponse = (
      <LicenseApprovalModal
        {...commonProps}
        memberData={props.memberData}
        approveLicensebyDMV={props.approveLicensebyDMV}
        launchModalFromParentPage={props.launchModalFromParentPage}
        resetParentPage={props.resetParentPage}
      />
    );
  } else if (props.modalType === ModalType.LICENSE_INFO_MODAL) {
    modalResponse = (
      <LicenseInfoModal
        {...commonProps}
        memberData={props.memberData}
        resetParentPage={props.resetParentPage}
      />
    );
  } else if (
    props.modalType === ModalType.LICENSE_DOC_MODAL ||
    props.modalType === LicenseDocType.ATTACHED_DOC ||
    props.modalType === LicenseDocType.MANUAL_APPROVE ||
    props.modalType === LicenseDocType.MANUAL_DISAPPROVE
  ) {
    modalResponse = (
      <LicenseDocModal
        {...commonProps}
        resetParentPage={props.resetParentPage}
      />
    );
  } else if (props.modalType === ModalType.MEMBER_QRCODE_MODAL) {
    modalResponse = (
      <MemberQrCodeModal {...commonProps} fullName={props.fullName} badgeNo={props.badgeNo}/>
    );
  } else if (
    props.modalType === ModalType.MEMBER_LICENCE_UPLOADER_MODAL ||
    props.modalType === ModalType.MEMBER_LICENCE_VIEW_MODAL
  ) {
    modalResponse = (
      <LicenseUploaderModal
        {...commonProps}
        defaultData={props.defaultData}
        resetParentPage={props.resetParentPage}
      />
    );
  } else if (props.modalType === ModalType.MEMBER_ALL_ADDRESS_MODAL) {
    modalResponse = (
      <AllAddresModal
        {...commonProps}
        resetLoader={props.resetLoader}
        resetParentPage={props.resetParentPage}
      />
    );
  } else if (props.modalType === ModalType.MEMBER_ADD_ADDRESS_MODAL) {
    modalResponse = (
      <AddAddresModal
        {...commonProps}
        resetLoader={props.resetLoader}
        resetParentPage={props.resetParentPage}
      />
    );
  } else if (props.modalType === ModalType.ADD_MEMBER_QUICK_MODAL) {
    modalResponse = (
      <AddQuickMemberModal
        {...commonProps}
        resetLoader={props.resetLoader}
        addQuickMemberInput={props.addQuickMemberInput}
        showConflictedMemberModal={props.showConflictedMemberModal}
        modalTitle={props.modalTitle}
      />
    );
  } else if (props.modalType === ModalType.CONFILICTED_MEMBER_MODAL) {
    modalResponse = (
      <ConflictedMemberListModal
        {...commonProps}
        addQuickMemberInput={props.addQuickMemberInput}
        resetParentPage={props.resetParentPage}
        addBlackListedPersons={props.addBlackListedPersons}
      />
    );
  } else if (props.modalType === ModalType.AGENT_MODAL) {
    modalResponse = (
      <AgentModal
        {...commonProps}
        dependantOrgList={props.dependantOrgList}
        agentResponse={props.agentResponse}
        getAgentList={props.getAgentList}
        IsSearchedPerform={props.isSearchedAgentPerformed}
        agentType={props.agentType}
        onAgentSelected={(agentInfo) => props.onAgentSelected(agentInfo)}
        resetAgentSearch={props.resetAgentSearch}
      />
    );
  } else if (props.modalType === ModalType.MEMBER_BLOCK_UNBLOCK_MODAL) {
    modalResponse = (
      <BlockUnblockMemberModal
        {...commonProps}
        memberData={props.memberData ? props.memberData : {}}
        updateBlockUnblockProfile={props.doPerformAction}
      />
    );
  } else if (props.modalType === ModalType.CHANGE_RESET_PASSWORD_MODAL) {
    modalResponse = (
      <ChangeResetPassword
        {...commonProps}
        popupFor={props.popupFor}
        title={props.title}
        resetOrChangePassword={props.doPerformAction}
      />
    );
  } else if (props.modalType === ModalType.MEMBER_ACTIVATION_MODAL) {
    modalResponse = (
      <MemberActivation
        {...commonProps}
        popupFor={props.popupFor}
        title={props.title}
        cancelOrReactivateMember={props.doPerformAction}
      />
    );
  } else if (props.modalType === ModalType.MEMBER_VERIFICATION_MODAL) {
    modalResponse = (
      <MemberVerification
        {...commonProps}
        verifyMemberAccount={props.doPerformAction}
      />
    );
  } else if (props.modalType === ModalType.IMPERSONATE_MODAL) {
    modalResponse = (
      <ImpersonateModal
        {...commonProps}
        fullName={props.fullName}
        onImpersonateLogin={props.doPerformAction}
      />
    );
  } else if (props.modalType === ModalType.ADD_VIOLATION_MODAL) {
    modalResponse = (
      <AddViolationPoint
        {...commonProps}
        addViolationPoint={props.doPerformAction}
      />
    );
  }
  return modalResponse;
};
export default MemberModalEngine;

import React from "react";

const FoxReservationDefaultPage = (props) => {
  const {msg, title, iconContent, customClassMessage, customerClassMessageTitle, customParentClass, customMessageIconClass} = props
  return (
    <div className={`fox-res-default-page ${customParentClass || ""}`}>
      {iconContent && <div className= {`message-icon ${customMessageIconClass || ""}`}>
        <img
          src={iconContent}
          height={40}
          width={40}
          alt={title}
        />
      </div>}
      {title && <label className={`message-title ${customerClassMessageTitle || ""}`}>{title}</label>}
      {msg && <label className={`message ${customClassMessage || ""}`}>{msg}</label>}
    
    </div>
  );
};

export default FoxReservationDefaultPage;

export const GET_MAIN_SETTING_INFO_START = "GET_MAIN_SETTING_INFO_START";
export const GET_MAIN_SETTING_INFO_SUCCESS = "GET_MAIN_SETTING_INFO_SUCCESS";
export const GET_MAIN_SETTING_INFO_FAIL = "GET_MAIN_SETTING_INFO_FAIL";
export const GET_MAIN_SETTING_INFO_RESET = "GET_MAIN_SETTING_INFO_RESET";

export const UPDATE_MAIN_SETTING_INFO_START = "UPDATE_MAIN_SETTING_INFO_START";
export const UPDATE_MAIN_SETTING_INFO_SUCCESS =
  "UPDATE_MAIN_SETTING_INFO_SUCCESS";
export const UPDATE_MAIN_SETTING_INFO_FAIL = "UPDATE_MAIN_SETTING_INFO_FAIL";
export const UPDATE_MAIN_SETTING_INFO_RESET = "UPDATE_MAIN_SETTING_INFO_RESET";

export const GET_HOUR_OF_OPS_SETTING_INFO_START =
  "GET_HOUR_OF_OPS_SETTING_INFO_START";
export const GET_HOUR_OF_OPS_SETTING_INFO_SUCCESS =
  "GET_HOUR_OF_OPS_SETTING_INFO_SUCCESS";
export const GET_HOUR_OF_OPS_SETTING_INFO_FAIL =
  "GET_HOUR_OF_OPS_SETTING_INFO_FAIL";
export const GET_HOUR_OF_OPS_SETTING_INFO_RESET =
  "GET_HOUR_OF_OPS_SETTING_INFO_RESET";

export const UPDATE_HOUR_OF_OPS_SETTING_INFO_START =
  "UPDATE_HOUR_OF_OPS_SETTING_INFO_START";
export const UPDATE_HOUR_OF_OPS_SETTING_INFO_SUCCESS =
  "UPDATE_HOUR_OF_OPS_SETTING_INFO_SUCCESS";
export const UPDATE_HOUR_OF_OPS_SETTING_INFO_FAIL =
  "UPDATE_HOUR_OF_OPS_SETTING_INFO_FAIL";
export const UPDATE_HOUR_OF_OPS_SETTING_INFO_RESET =
  "UPDATE_HOUR_OF_OPS_SETTING_INFO_RESET";

export const GET_TAX_SURCHARGE_INFO_START = "GET_TAX_SURCHARGE_INFO_START";
export const GET_TAX_SURCHARGE_INFO_SUCCESS = "GET_TAX_SURCHARGE_INFO_SUCCESS";
export const GET_TAX_SURCHARGE_INFO_FAIL = "GET_TAX_SURCHARGE_INFO_FAIL";
export const GET_TAX_SURCHARGE_INFO_RESET = "GET_TAX_SURCHARGE_INFO_RESET";

export const UPDATE_TAX_SURCHARGE_INFO_START =
  "UPDATE_TAX_SURCHARGE_INFO_START";
export const UPDATE_TAX_SURCHARGE_INFO_SUCCESS =
  "UPDATE_TAX_SURCHARGE_INFO_SUCCESS";
export const UPDATE_TAX_SURCHARGE_INFO_FAIL = "UPDATE_TAX_SURCHARGE_INFO_FAIL";
export const UPDATE_TAX_SURCHARGE_INFO_RESET =
  "UPDATE_TAX_SURCHARGE_INFO_RESET";

export const GET_QUALIFICATION_INFO_START = "GET_QUALIFICATION_INFO_START";
export const GET_QUALIFICATION_INFO_SUCCESS = "GET_QUALIFICATION_INFO_SUCCESS";
export const GET_QUALIFICATION_INFO_FAIL = "GET_QUALIFICATION_INFO_FAIL";
export const GET_QUALIFICATION_INFO_RESET = "GET_QUALIFICATION_INFO_RESET";

export const UPDATE_QUALIFICATION_INFO_START =
  "UPDATE_QUALIFICATION_INFO_START";
export const UPDATE_QUALIFICATION_INFO_SUCCESS =
  "UPDATE_QUALIFICATION_INFO_SUCCESS";
export const UPDATE_QUALIFICATION_INFO_FAIL = "UPDATE_QUALIFICATION_INFO_FAIL";
export const UPDATE_QUALIFICATION_INFO_RESET =
  "UPDATE_QUALIFICATION_INFO_RESET";

export const GET_RES_SETTINGS_INFO_START = "GET_RES_SETTINGS_INFO_START";
export const GET_RES_SETTINGS_INFO_SUCCESS = "GET_RES_SETTINGS_INFO_SUCCESS";
export const GET_RES_SETTINGS_INFO_FAIL = "GET_RES_SETTINGS_INFO_FAIL";
export const GET_RES_SETTINGS_INFO_RESET = "GET_RES_SETTINGS_INFO_RESET";

export const UPDATE_RES_SETTINGS_INFO_START = "UPDATE_RES_SETTINGS_INFO_START";
export const UPDATE_RES_SETTINGS_INFO_SUCCESS =
  "UPDATE_RES_SETTINGS_INFO_SUCCESS";
export const UPDATE_RES_SETTINGS_INFO_FAIL = "UPDATE_RES_SETTINGS_INFO_FAIL";
export const UPDATE_RES_SETTINGS_INFO_RESET = "UPDATE_RES_SETTINGS_INFO_RESET";

export const GET_INSURANCE_SETTINGS_INFO_START =
  "GET_INSURANCE_SETTINGS_INFO_START";
export const GET_INSURANCE_SETTINGS_INFO_SUCCESS =
  "GET_INSURANCE_SETTINGS_INFO_SUCCESS";
export const GET_INSURANCE_SETTINGS_INFO_FAIL =
  "GET_INSURANCE_SETTINGS_INFO_FAIL";
export const GET_INSURANCE_SETTINGS_INFO_RESET =
  "GET_INSURANCE_SETTINGS_INFO_RESET";

export const UPDATE_INSURANCE_SETTINGS_INFO_START =
  "UPDATE_INSURANCE_SETTINGS_INFO_START";
export const UPDATE_INSURANCE_SETTINGS_INFO_SUCCESS =
  "UPDATE_INSURANCE_SETTINGS_INFO_SUCCESS";
export const UPDATE_INSURANCE_SETTINGS_INFO_FAIL =
  "UPDATE_INSURANCE_SETTINGS_INFO_FAIL";
export const UPDATE_INSURANCE_SETTINGS_INFO_RESET =
  "UPDATE_INSURANCE_SETTINGS_INFO_RESET";

export const GET_ADDONS_SETTINGS_INFO_START = "GET_ADDONS_SETTINGS_INFO_START";
export const GET_ADDONS_SETTINGS_INFO_SUCCESS =
  "GET_ADDONS_SETTINGS_INFO_SUCCESS";
export const GET_ADDONS_SETTINGS_INFO_FAIL = "GET_ADDONS_SETTINGS_INFO_FAIL";
export const GET_ADDONS_SETTINGS_INFO_RESET = "GET_ADDONS_SETTINGS_INFO_RESET";

export const UPDATE_ADDONS_SETTINGS_INFO_START =
  "UPDATE_ADDONS_SETTINGS_INFO_START";
export const UPDATE_ADDONS_SETTINGS_INFO_SUCCESS =
  "UPDATE_ADDONS_SETTINGS_INFO_SUCCESS";
export const UPDATE_ADDONS_SETTINGS_INFO_FAIL =
  "UPDATE_ADDONS_SETTINGS_INFO_FAIL";
export const UPDATE_ADDONS_SETTINGS_INFO_RESET =
  "UPDATE_ADDONS_SETTINGS_INFO_RESET";

export const GET_LOCATION_SETTINGS_INFO_START =
  "GET_LOCATION_SETTINGS_INFO_START";
export const GET_LOCATION_SETTINGS_INFO_SUCCESS =
  "GET_LOCATION_SETTINGS_INFO_SUCCESS";
export const GET_LOCATION_SETTINGS_INFO_FAIL =
  "GET_LOCATION_SETTINGS_INFO_FAIL";
export const GET_LOCATION_SETTINGS_INFO_RESET =
  "GET_LOCATION_SETTINGS_INFO_RESET";

export const ADD_LOCATION_SETTINGS_INFO_START =
  "ADD_LOCATION_SETTINGS_INFO_START";
export const ADD_LOCATION_SETTINGS_INFO_SUCCESS =
  "ADD_LOCATION_SETTINGS_INFO_SUCCESS";
export const ADD_LOCATION_SETTINGS_INFO_FAIL =
  "ADD_LOCATION_SETTINGS_INFO_FAIL";
export const ADD_LOCATION_SETTINGS_INFO_RESET =
  "ADD_LOCATION_SETTINGS_INFO_RESET";

export const UPDATE_LOCATION_SETTINGS_INFO_START =
  "UPDATE_LOCATION_SETTINGS_INFO_START";
export const UPDATE_LOCATION_SETTINGS_INFO_SUCCESS =
  "UPDATE_LOCATION_SETTINGS_INFO_SUCCESS";
export const UPDATE_LOCATION_SETTINGS_INFO_FAIL =
  "UPDATE_LOCATION_SETTINGS_INFO_FAIL";
export const UPDATE_LOCATION_SETTINGS_INFO_RESET =
  "UPDATE_LOCATION_SETTINGS_INFO_RESET";

export const GET_RATE_SETTINGS_INFO_START = "GET_RATE_SETTINGS_INFO_START";
export const GET_RATE_SETTINGS_INFO_SUCCESS = "GET_RATE_SETTINGS_INFO_SUCCESS";
export const GET_RATE_SETTINGS_INFO_FAIL = "GET_RATE_SETTINGS_INFO_FAIL";
export const GET_RATE_SETTINGS_INFO_RESET = "GET_RATE_SETTINGS_INFO_RESET";

export const UPDATE_RATE_SETTINGS_INFO_START =
  "UPDATE_RATE_SETTINGS_INFO_START";
export const UPDATE_RATE_SETTINGS_INFO_SUCCESS =
  "UPDATE_RATE_SETTINGS_INFO_SUCCESS";
export const UPDATE_RATE_SETTINGS_INFO_FAIL = "UPDATE_RATE_SETTINGS_INFO_FAIL";
export const UPDATE_RATE_SETTINGS_INFO_RESET =
  "UPDATE_RATE_SETTINGS_INFO_RESET";

export const GET_VEHICLE_AVAILABILITY_SETTINGS_INFO_START =
  "GET_VEHICLE_AVAILABILITY_SETTINGS_INFO_START";
export const GET_VEHICLE_AVAILABILITY_SETTINGS_INFO_SUCCESS =
  "GET_VEHICLE_AVAILABILITY_SETTINGS_INFO_SUCCESS";
export const GET_VEHICLE_AVAILABILITY_SETTINGS_INFO_FAIL =
  "GET_VEHICLE_AVAILABILITY_SETTINGS_INFO_FAIL";
export const GET_VEHICLE_AVAILABILITY_SETTINGS_INFO_RESET =
  "GET_VEHICLE_AVAILABILITY_SETTINGS_INFO_RESET";

export const UPDATE_VEHICLE_AVAILABILITY_SETTINGS_INFO_START =
  "UPDATE_VEHICLE_AVAILABILITY_SETTINGS_INFO_START";
export const UPDATE_VEHICLE_AVAILABILITY_SETTINGS_INFO_SUCCESS =
  "UPDATE_VEHICLE_AVAILABILITY_SETTINGS_INFO_SUCCESS";
export const UPDATE_VEHICLE_AVAILABILITY_SETTINGS_INFO_FAIL =
  "UPDATE_VEHICLE_AVAILABILITY_SETTINGS_INFO_FAIL";
export const UPDATE_VEHICLE_AVAILABILITY_SETTINGS_INFO_RESET =
  "UPDATE_VEHICLE_AVAILABILITY_SETTINGS_INFO_RESET";

export const GET_CAPACITY_PLANNING_DATA_START =
  "GET_CAPACITY_PLANNING_DATA_START";
export const GET_CAPACITY_PLANNING_DATA_SUCCESS =
  "GET_CAPACITY_PLANNING_DATA_SUCCESS";
export const GET_CAPACITY_PLANNING_DATA_FAIL =
  "GET_CAPACITY_PLANNING_DATA_FAIL";
export const GET_CAPACITY_PLANNING_DATA_RESET =
  "GET_CAPACITY_PLANNING_DATA_RESET";

export const UPDATE_CAPACITY_PLANNING_DATA_START =
  "UPDATE_CAPACITY_PLANNING_DATA_START";
export const UPDATE_CAPACITY_PLANNING_DATA_SUCCESS =
  "UPDATE_CAPACITY_PLANNING_DATA_SUCCESS";
export const UUPDATE_CAPACITY_PLANNING_DATA_FAIL =
  "UUPDATE_CAPACITY_PLANNING_DATA_FAIL";
export const UPDATE_CAPACITY_PLANNING_DATA_RESET =
  "UPDATE_CAPACITY_PLANNING_DATA_RESET";

export const GET_SETTINGS_DATA_API_START =
  "GET_SETTINGS_DATA_API_START";
export const GET_SETTINGS_DATA_API_SUCCESS =
  "GET_SETTINGS_DATA_API_SUCCESS";
export const GET_SETTINGS_DATA_API_FAIL =
  "GET_SETTINGS_DATA_API_FAIL";
export const GET_SETTINGS_DATA_API_RESET =
  "GET_SETTINGS_DATA_API_RESET";

export const UPDATE_SETTINGS_DATA_API_START =
  "UPDATE_SETTINGS_DATA_API_START";
export const UPDATE_SETTINGS_DATA_API_SUCCESS =
  "UPDATE_SETTINGS_DATA_API_SUCCESS";
export const UPDATE_SETTINGS_DATA_API_FAIL =
  "UPDATE_SETTINGS_DATA_API_FAIL";
export const UPDATE_SETTINGS_DATA_API_RESET =
  "UPDATE_SETTINGS_DATA_API_RESET";
/**Reservation for fox */
export const GET_ALL_RATES_FOX_API_START =
  "GET_ALL_RATES_FOX_API_START";
export const GET_ALL_RATES_FOX_API_SUCCESS =
  "GET_ALL_RATES_FOX_API_SUCCESS";
export const GET_ALL_RATES_FOX_API_FAIL =
  "GET_ALL_RATES_FOX_API_FAIL";
export const GET_ALL_RATES_FOX_API_RESET =
  "GET_ALL_RATES_FOX_API_RESET";
export const GET_SPECIFIC_RATES_FOX_API_START =
  "GET_SPECIFIC_RATES_FOX_API_START";
export const GET_SPECIFIC_RATES_FOX_API_SUCCESS =
  "GET_SPECIFIC_RATES_FOX_API_SUCCESS";
export const GET_SPECIFIC_RATES_FOX_API_FAIL =
  "GET_SPECIFIC_RATES_FOX_API_FAIL";
export const GET_SPECIFIC_RATES_FOX_API_RESET =
  "GET_SPECIFIC_RATES_FOX_API_RESET";
export const GET_ADDONS_FOX_API_START =
  "GET_ADDONS_FOX_API_START";
export const GET_ADDONS_FOX_API_SUCCESS =
  "GET_ADDONS_FOX_API_SUCCESS";
export const GET_ADDONS_FOX_API_FAIL =
  "GET_ADDONS_FOX_API_FAIL";
export const GET_ADDONS_FOX_API_RESET =
  "GET_ADDONS_FOX_API_RESET";
export const CREATE_RESERVATION_FOX_API_START =
  "CREATE_RESERVATION_FOX_API_START";
export const CREATE_RESERVATION_FOX_API_SUCCESS =
  "CREATE_RESERVATION_FOX_API_SUCCESS";
export const CREATE_RESERVATION_FOX_API_FAIL =
  "CREATE_RESERVATION_FOX_API_FAIL";
export const CREATE_RESERVATION_FOX_API_RESET =
  "CREATE_RESERVATION_FOX_API_RESET";
export const GET_RESERVATION_FOX_API_START =
  "GET_RESERVATION_FOX_API_START";
export const GET_RESERVATION_FOX_API_SUCCESS =
  "GET_RESERVATION_FOX_API_SUCCESS";
export const GET_RESERVATION_FOX_API_FAIL =
  "GET_RESERVATION_FOX_API_FAIL";
export const GET_RESERVATION_FOX_API_RESET =
  "GET_RESERVATION_FOX_API_RESET";
export const CANCEL_RESERVATION_FOX_API_START =
  "CANCEL_RESERVATION_FOX_API_START";
export const CANCEL_RESERVATION_FOX_API_SUCCESS =
  "CANCEL_RESERVATION_FOX_API_SUCCESS";
export const CANCEL_RESERVATION_FOX_API_FAIL =
  "CANCEL_RESERVATION_FOX_API_FAIL";
export const CANCEL_RESERVATION_FOX_API_RESET =
  "CANCEL_RESERVATION_FOX_API_RESET";

import * as actionTypes from "../actions/actionTypes";
import { reducerUpdateObject } from "./reducer.utility";

const initialState = {
  searchReportProblemDetailsResponse: null,
  loading: false
};

export const searchReportProblemDetailsStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
export const searchReportProblemDetailsSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    searchReportProblemDetailsResponse:
      action.searchReportProblemDetailsResponse,
    error: null,
    loading: false
  });
};
const searchReportProblemDetailsFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false
  });
};
const searchReportProblemDetailsReset = state => {
  return reducerUpdateObject(state, {
    searchReportProblemDetailsResponse: null,
    loading: false
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SEARCH_REPORT_PROBLEM_DETAILS_START:
      return searchReportProblemDetailsStart(state, action);
    case actionTypes.SEARCH_REPORT_PROBLEM_DETAILS_SUCCESS:
      return searchReportProblemDetailsSuccess(state, action);
    case actionTypes.SEARCH_REPORT_PROBLEM_DETAILS_FAIL:
      return searchReportProblemDetailsFail(state, action);
    case actionTypes.SEARCH_REPORT_PROBLEM_DETAILS_RESET:
      return searchReportProblemDetailsReset(state);

    default:
      return state;
  }
};

export default reducer;

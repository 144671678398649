import { getDateTimeString } from "../../../../shared/utility";

export const getChannelQuickViewInfoCtrl = (viewData) => {
    viewData = viewData ? viewData : {};
    return JSON.parse(JSON.stringify([{
        info_block: 0,
        info_heading: "",
        fields: [
            {
                titleLabel: {
                    text: "Partner Confirmation Key"
                },
                detailLabel: {
                    text: viewData.partnerConfirmationKey,
                    className:"col-lg-4 underLine pl-0",
                    attr:{class:"pl-4"}
                },
            },
            {
                titleLabel: {
                    text: "Source"
                },
                detailLabel: {
                    text: viewData.source,
                    className:"col-lg-4 underLine pl-0 text-center",
                    attr:{class:"pl-4"}
                },
            },
            {
                titleLabel: {
                    text: "API License ID"                    
                },
                detailLabel: {
                    text: viewData.apiLicense && viewData.apiLicense.apiLicenseId,
                    className:"col-lg-4 underLine pl-0 text-right",
                    attr:{class:"pl-4"}
                },
            }
        ],
    }, {
        info_block: 1,
        info_heading: "Partner Information",
        fields: [
            {
                titleLabel: {
                    text: "Allowed IPs"
                },
                detailLabel: {
                    text: viewData.partner.allowedIps,
                },
            },
            {
                titleLabel: {
                    text: "Allowed Pay Type"
                },
                detailLabel: {
                    text: viewData.partner.allowedPayType,
                },
            },
            {
                titleLabel: {
                    text: "Card Required"
                },
                detailLabel: {
                    text: String(viewData.partner.cardRequired),
                },
            },
            {
                titleLabel: {
                    text: "Client Reservation Allowed"
                },
                detailLabel: {
                    text: String(viewData.partner.clientReservationAllowed),
                },
            },
            {
                titleLabel: {
                    text: viewData.partner.prepaidCompanyId? "Prepaid Company Id" : "Company ID"
                },
                detailLabel: {
                    text: viewData.partner.prepaidCompanyId || viewData.partner.companyId ,
                },
            },
            {
                titleLabel: {
                    text: "Confirmation Key Prefix"
                },
                detailLabel: {
                    text: viewData.partner.confKeyPrefix,
                },
            },
            {
                titleLabel: {
                    text: "Customer Max Reservations"
                },
                detailLabel: {
                    text: viewData.partner.customerMaxReservations,
                },
            },
            {
                titleLabel: {
                    text: "Display Name"
                },
                detailLabel: {
                    text: viewData.partner.displayName,
                },
            },
            {
                titleLabel: {
                    text: "Name"
                },
                detailLabel: {
                    text: viewData.partner.name,
                },
            },
            {
                titleLabel: {
                    text: "Short Name"
                },
                detailLabel: {
                    text: viewData.partner.shortName,
                },
            },
            {
                titleLabel: {
                    text: "Partner Code"
                },
                detailLabel: {
                    text: viewData.partner.partnerCode,
                },
            },
            {
                titleLabel: {
                    text: "Partner ID"
                },
                detailLabel: {
                    text: viewData.partner.partnerId,
                },
            },
            {
                titleLabel: {
                    text: "Restricted IPs"
                },
                detailLabel: {
                    text: viewData.partner.restrictedIps,
                },
            },
            {
                titleLabel: {
                    text: "Restricted Permissions"
                },
                detailLabel: {
                    text: viewData.partner.restrictedPermissions,
                },
            },
            {
                titleLabel: {
                    text: "Allowed Permissions"
                },
                detailLabel: {
                    text: viewData.partner.allowedPermissions,
                },
            },
            {
                titleLabel: {
                    text: "Loyalty Allowed"
                },
                detailLabel: {
                    text: String(viewData.partner.loyaltyAllowed),
                },
            },
            {
                titleLabel: {
                    text: "Subscription Allowed"
                },
                detailLabel: {
                    text: String(viewData.partner.subscriptionAllowed),
                },
            },
            {
                titleLabel: {
                    text: "Return Confirm Key Type"
                },
                detailLabel: {
                    text: viewData.partner.returnConfirmKeyType,
                },
            },
            {
                titleLabel: {
                    text: "Show Fox Confirmation Key"
                },
                detailLabel: {
                    text: String(viewData.partner.showFoxConfirmationKey),
                },
            },
            {
                titleLabel: {
                    text: "Source Code"
                },
                detailLabel: {
                    text: viewData.partner.sourceCode,
                },
            },
            {
                titleLabel: {
                    text: "Ignore Pay Type"
                },
                detailLabel: {
                    text: String(viewData.partner.ignorePayType),
                },
            },
            {
                titleLabel: {
                    text: "License Details Required"
                },
                detailLabel: {
                    text: String(viewData.partner.licenseDetailsRequired),
                },
            },
            {
                titleLabel: {
                    text: "Status"
                },
                detailLabel: {
                    text: viewData.partner.status,
                },
            },
            {
                titleLabel: {
                    text: "PNR Enabled"
                },
                detailLabel: {
                    text: String(viewData.partner.pnrEnabled),
                },
            },
            {
                titleLabel: {
                    text: "Anonymous Max Reservations"
                },
                detailLabel: {
                    text: viewData.partner.anonymousMaxReservations,
                },
            }
        ],
    }, {
        info_block: 2,
        info_heading: "Partner Contact Information",
        fields: [
            {
                titleLabel: {
                    text: "Contact Name"
                },
                detailLabel: {
                    text: viewData.partner.contactName,
                },
            },
            {
                titleLabel: {
                    text: "Contact Email"
                },
                detailLabel: {
                    text: viewData.partner.contactEmail,
                },
            }, {
                titleLabel: {
                    text: "Contact Phone"
                },
                detailLabel: {
                    text: viewData.partner.contactPhone,
                },
            }, {
                titleLabel: {
                    text: "Contact Title"
                },
                detailLabel: {
                    text: viewData.partner.contactTitle,
                },
            }, {
                titleLabel: {
                    text: "Company Address"
                },
                detailLabel: {
                    text: viewData.partner.companyAddress,
                },
            }, {
                titleLabel: {
                    text: "Company Country"
                },
                detailLabel: {
                    text: viewData.partner.companyCountry,
                },
            }, {
                titleLabel: {
                    text: "Company State"
                },
                detailLabel: {
                    text: viewData.partner.companyState,
                },
            }, {
                titleLabel: {
                    text: "Company City"
                },
                detailLabel: {
                    text: viewData.partner.companyCity,
                },
            }, {
                titleLabel: {
                    text: "Company Zip Code"
                },
                detailLabel: {
                    text: viewData.partner.companyZipCode,
                },
            }
        ]
    },
    {
        info_block: 3,
        info_heading: "Provider Information",
        fields: [
            {
                titleLabel: {
                    text: "Deployment Mode"
                },
                detailLabel: {
                    text: viewData.provider.deploymentMode,
                },
            },
            {
                titleLabel: {
                    text: "Display Order"
                },
                detailLabel: {
                    text: viewData.provider.displayOrder,
                },
            },{
                titleLabel: {
                    text: "Integration Provider ID"
                },
                detailLabel: {
                    text: viewData.provider.integProvId,
                },
            },{
                titleLabel: {
                    text: "Name"
                },
                detailLabel: {
                    text: viewData.provider.name,
                },
            },{
                titleLabel: {
                    text: "Password"
                },
                detailLabel: {
                    text: viewData.provider.password ? "*****" : "--",
                },
            },{
                titleLabel: {
                    text: "Provider Status"
                },
                detailLabel: {
                    text: viewData.provider.providerStatus,
                },
            },{
                titleLabel: {
                    text: "URL"
                },
                detailLabel: {
                    text: viewData.provider.url,
                },
            },{
                titleLabel: {
                    text: "User Name"
                },
                detailLabel: {
                    text: viewData.provider.userName,
                },
            }
        ],
    }, {
        info_block: 4,
        info_heading: "API License Information",
        fields: [
            {
                titleLabel: {
                    text: "ORG Unit ID"
                },
                detailLabel: {
                    text: viewData.apiLicense.orgUnitId,
                },
            },
            {
                titleLabel: {
                    text: "Company Address"
                },
                detailLabel: {
                    text: viewData.apiLicense.companyAddress,
                },
            },{
                titleLabel: {
                    text: "Contact Person Email"
                },
                detailLabel: {
                    text: viewData.apiLicense.contactPersonEmail,
                },
            },{
                titleLabel: {
                    text: "Contact Person Name"
                },
                detailLabel: {
                    text: viewData.apiLicense.contactPersonName,
                },
            },{
                titleLabel: {
                    text: "Contact Person Phone"
                },
                detailLabel: {
                    text: viewData.apiLicense.contactPersonPhone,
                },
            },{
                titleLabel: {
                    text: "Contact Person Title"
                },
                detailLabel: {
                    text: viewData.apiLicense.contactPersonTitle,
                },
            },{
                titleLabel: {
                    text: "Encryption Key"
                },
                detailLabel: {
                    text: viewData.apiLicense.encryptionKey,
                    hasEyeIcon:true,
                    dataId:"encryptionKey",
                    toolTipId:"encryptionKeyTooltip"
                },
            },{
                titleLabel: {
                    text: "Expiration Key On"
                },
                detailLabel: {
                    text: getDateTimeString(viewData.apiLicense.expirationKeyOn),
                },
            },{
                titleLabel: {
                    text: "Expiration On"
                },
                detailLabel: {
                    text: getDateTimeString(viewData.apiLicense.expirationOn),
                },
            },{
                titleLabel: {
                    text: "License Key"
                },
                detailLabel: {
                    text: viewData.apiLicense.licenseKey,
                    hasEyeIcon:true,
                    dataId:"licenseKey",
                    toolTipId:"licenseKeyTooltip"
                },
            },{
                titleLabel: {
                    text: "Notes"
                },
                detailLabel: {
                    text: viewData.apiLicense.notes,
                },
            },{
                titleLabel: {
                    text: "Primary Auth ID"
                },
                detailLabel: {
                    text: viewData.apiLicense.primaryAuthenticationId,
                    hasEyeIcon:true,
                    dataId:"primaryAuthenticationId",
                    toolTipId:"primaryAuthenticationIdTooltip"
                },
            },{
                titleLabel: {
                    text: "Renewed On"
                },
                detailLabel: {
                    text:  getDateTimeString(viewData.apiLicense.renewedOn),
                },
            },
        ],
    },]));
}
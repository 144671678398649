export const FleetUtilizationForecast = function (data) {
  if (!data) data = {};
  this.fleetUtilizationForecastId = data.fleetUtilizationForecastId || null;
  this.orgUnitFleetClassGroupId = data.orgUnitFleetClassGroupId || null;
  this.classGroupCode = data.classGroupCode || null;
  this.displayText = data.displayText || null;
  /**reserve sync data section */
  this.forecastDate = data.forecastDate || null;
  this.dropOffCount = data.dropOffCount || null;
  this.pickupCount = data.pickupCount || null;
  this.defaultPickupThresholdPercent =
    data.defaultPickupThresholdPercent || null;
  this.defaultBookThruThresholdPercent =
    data.defaultBookThruThresholdPercent || null;
  this.pickupStatus = data.pickupStatus || null;
  this.bookThruStatus = data.bookThruStatus || null;
  this.noShowFactor = data.noShowFactor || null;
  this.walkinsCount = data.walkinsCount || null;
  this.runningReservationsCount = data.runningReservationsCount || null;
  this.runningRentalsCount = data.runningRentalsCount || null;
  this.usableFleetCount = data.usableFleetCount || null;
  this.availableFleetCount = data.availableFleetCount || null;
  this.eodAvailableFleetCount = data.eodAvailableFleetCount || null;
  this.utilizationFactorPercent = data.utilizationFactorPercent || null;
  /**manual section */
  this.manualPickupThresholdPercent = data.manualPickupThresholdPercent || null;
  this.manualBookThruThresholdPercent =
    data.manualBookThruThresholdPercent || null;
  this.manualWalkinsCount = data.manualWalkinsCount || null;
  this.manualNoShowFactor = data.manualNoShowFactor || null;
  /**manual override reason */
  this.manualOverrideReason = data.manualOverrideReason || null;
  this.manualOverrideReasonDisplayName =
    data.manualOverrideReasonDisplayName || null;
  this.manualOverrideOtherReason = data.manualOverrideOtherReason || null;
  /**time stamp */
  this.lastTimestamp = data.lastTimestamp || null;
  this.lastManualOverrideTimestamp = data.lastManualOverrideTimestamp || null;
};

import { OrgUnit } from "../../models/domain/org-unit";

const ShuttleModel = function (data) {
  data = data || {};
  if(data.shuttleId){
    this.shuttleId = data.shuttleId || null;
  }
  if(data.amenities){
    if(!Array.isArray(data.amenities)){
      data.amenities = data.amenities.split() || [];
    }
  } 
  this.shuttleCode = data.shuttleCode || null;
  this.name = data.name || null;
  this.shortName = data.shortName || null;
  this.shuttleOrgUnit = new OrgUnit(data.shuttleOrgUnit);
  this.vin = data.vin || null;
  this.licensePlate = data.licensePlate || null;
  this.insuranceCarrier = data.insuranceCarrier || null;
  this.policyNumber = data.policyNumber || null;
  this.availability = data.availability || null;
  this.amenities = data.amenities || [];
  this.make = data.make || null;
  this.model = data.model || null;
  this.year = data.year || null;
  this.seatCapacity = data.seatCapacity || null;
  this.drivingType = data.drivingType || null;
  this.fuelType = data.fuelType || null;
  this.fuelConsumptionUnit = data.fuelConsumptionUnit || null;
  this.transmissionType = data.transmissionType || null;
  this.color = data.color || null;
  this.description = data.description || null;
  this.internalNotes = data.internalNotes || null;
};
export default ShuttleModel;

const Employee = function (data) {
  if (!data) data = {};
  this.LocationId = data.LocationId || null;
  this.LocationName = data.LocationName || null;
  this.EmployeeNo = data.EmployeeNo || null;
  this.BadgeNo = data.BadgeNo || null;
  this.Department = data.Department || null;
  this.CompanyName = data.CompanyName || null;
  this.Title = data.Title || null;
};
export default Employee;

import React from "react";
import GeneralRateRow from "./general-rate-row";

function TransactionRateBlock({
  totalCapturedAmount,
  totalPreAuthReleaseAmount,
  totalPreAuthAmount,
  totalRefunds,
  totalVoidedRefunds,
  totalVoidedPayments
}) {
  return (
    <>
      {totalCapturedAmount ? (
        <GeneralRateRow key="capture-hold" title="Capture from Hold" total={totalCapturedAmount} />
      ) : ""}
      {totalPreAuthReleaseAmount ? (
        <GeneralRateRow
          key="pre-authorization-release"
          title="Pre-authorization Release"
          total={totalPreAuthReleaseAmount}
        />
      ) : ""}
      {totalPreAuthAmount && totalPreAuthAmount > 0 && (
        <GeneralRateRow
          key="pre-authorization-hold"
          title="Pre-authorization Hold"
          total={totalPreAuthAmount}
        />
      )}
      {totalRefunds ? <GeneralRateRow key="refunds" title="Refunds" total={totalRefunds} /> : ""}
      {totalVoidedRefunds ? (
        <GeneralRateRow key="voided-refunds" title="Voided Refunds" total={totalVoidedRefunds} />
      ) : ""}
      {totalVoidedPayments ? (
        <GeneralRateRow
          key="voided-payments"
          title="Voided Payments"
          total={totalVoidedPayments}
        />
      ) : ""}
    </>
  );
}

export default TransactionRateBlock;

import React from 'react';
import { formatCurrency } from '../../../../../shared/utility';

function SubtotalRateRow({ title, rate }) {
    return (
        <div className='rate-row font-bold'>
            <div className='col-lg-8 text-right'>{title}</div>
            <div className='col-lg-4 text-right'>{formatCurrency(rate)}</div>
        </div>
    )
}

export default SubtotalRateRow
import React, { Component } from "react";
import { createTooltip } from "../../../shared/utility/tooltip-utility";

class EyeIcon extends Component {
  state = {
    passwordIcon: "fa-eye",
    inputType: "password",
    customClass: this.props.customClass || "",
    returnedValue: this.props.maskedValue,
  };

  toggleIcon = (event) => {
    this.setState(
      (prevState) => {
        return {
          passwordIcon:
            prevState.passwordIcon === "fa-eye-slash"
              ? "fa-eye"
              : "fa-eye-slash",
          inputType:
            prevState.passwordIcon === "fa-eye-slash" ? "password" : "text",
          returnedValue:
            prevState.passwordIcon === "fa-eye"
              ? this.props.originalValue
              : this.props.maskedValue,
        };
      },
      () => {
        if (this.props.maskedValue) {
          this.props.characterHideShowToggleForLabel(
            this.state.returnedValue,
            this.props.labelID
          );
        } else {
          this.props.toggleIcon(this.state.inputType);
        }
      }
    );
  };

  render() {
    return (
      <div
        className={`eyeIconPosition ${
          this.props.customClass ? this.props.customClass : ""
        } `}
        onClick={(event) => this.toggleIcon()}
      >
        {createTooltip(
          <i
            className={`fa fa-fw  field_icon toggle-password passwordHideShow ${this.state.passwordIcon}`}
          />,
          this.state.passwordIcon === "fa-eye" ? "Show" : "Hide",
          { forceShow: true }
        )}
      </div>
    );
  }
}

export default EyeIcon;

const BlackListedPersonInfo = function (data) {
  if (!data) data = {};
  this.BlackListedBy = data.BlackListedBy || null;
  this.CityId = data.CityId || null;
  this.CityText = data.CityText || null;
  this.CountryId = data.CountryId || null;
  this.CountryText = data.CountryText || null;
  this.DateOfBirth = data.DateOfBirth || null;
  this.EmailAddress = data.EmailAddress || null;
  this.EndDate = data.EndDate || null;
  this.FirstName = data.FirstName || null;
  this.LastName = data.LastName || null;
  this.LicenseCountryId = data.LicenseCountryId || null;
  this.LicenseCountryText = data.LicenseCountryText || null;
  this.LicenseNo = data.LicenseNo || null;
  this.LicenseStateId = data.LicenseStateId || null;
  this.LicenseStateText = data.LicenseStateText || null;
  this.MembershipId = data.MembershipId || null;
  this.MiddleName = data.MiddleName || null;
  this.MobilePhone = data.MobilePhone || null;
  this.OrgUnitId = data.OrgUnitId || null;
  this.PersonBlackListId = data.PersonBlackListId || null;
  this.PersonId = data.PersonId || null;
  this.Reason = data.Reason || null;
  this.StartDate = data.StartDate || null;
  this.StateId = data.StateId || null;
  this.StateText = data.StateText || null;
  this.ZipCodeId = data.ZipCodeId || null;
  this.ZipCodeText = data.ZipCodeText || null;
};
export default BlackListedPersonInfo;

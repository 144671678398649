import React, { Fragment } from "react";
import ImageContainer from "../../components/UI/image/image-container";
import AddEditVehicleRequest from "../../services/entities/requests/AddEditVehicleRequest";
import { FlashyCar } from "../../shared/AppImages";
import { ADMIN_PAGE, COMMON_MSG } from "../../shared/GlobalVar";
import {
  getFormattedValidationMsg,
  loadDefaultImage,
} from "../../shared/utility";

export const getVehicleSubPageName = (url) => {
  return url === "/vehicle_details/vehicle_profile"
    ? ADMIN_PAGE.VEHICLE_DETAILS
    : url === "/vehicle_details/vehicle_Availability"
    ? ADMIN_PAGE.VEHICLE_AVAILABILITY
    : "";
};

export const getVehicleBuilders = () => {
  return [
    {
      displayValue: "Select",
      key: "",
    },
    {
      displayValue: "Acura",
      key: "ACURA",
    },
    {
      displayValue: "Alfa Romeo",
      key: "ALFA ROMEO",
    },
    {
      displayValue: "Aston Martin",
      key: "ASTON MARTIN",
    },
    {
      displayValue: "AUDI",
      key: "AUDI",
    },
    {
      displayValue: "Bentley",
      key: "BENTLEY",
    },
    {
      displayValue: "BMW",
      key: "BMW",
    },
    {
      displayValue: "Bugatti",
      key: "BUGATTI",
    },
    {
      displayValue: "Buick",
      key: "BUICK",
    },
    {
      displayValue: "Cadillac",
      key: "CADILLAC",
    },
    {
      displayValue: "Chevrolet",
      key: "CHEVROLET",
    },
    {
      displayValue: "Chrysler",
      key: "CHRYSLER",
    },
    {
      displayValue: "Daewoo",
      key: "DAEWOO",
    },
    {
      displayValue: "Daihatsu",
      key: "DAIHATSU",
    },
    {
      displayValue: "Dodge",
      key: "DODGE",
    },
    {
      displayValue: "Ducati",
      key: "DUCATI",
    },
    {
      displayValue: "Eagle",
      key: "EAGLE",
    },
    {
      displayValue: "Ferrari",
      key: "FERRARI",
    },
    {
      displayValue: "FIAT",
      key: "FIAT",
    },
    {
      displayValue: "Ford",
      key: "FORD",
    },
    {
      displayValue: "Geo",
      key: "GEO",
    },
    {
      displayValue: "GMC",
      key: "GMC",
    },
    {
      displayValue: "Honda",
      key: "HONDA",
    },
    {
      displayValue: "Hummer",
      key: "HUMMER",
    },
    {
      displayValue: "Hyundai",
      key: "HYUNDAI",
    },
    {
      displayValue: "Infiniti",
      key: "INFINITI",
    },
    {
      displayValue: "Isuzu",
      key: "ISUZU",
    },
    {
      displayValue: "Jaguar",
      key: "JAGUAR",
    },
    {
      displayValue: "Jeep",
      key: "JEEP",
    },
    {
      displayValue: "Kawasaki",
      key: "KAWASAKI",
    },
    {
      displayValue: "Kia",
      key: "KIA",
    },
    {
      displayValue: "Lamborghini",
      key: "LAMBORGHINI",
    },
    {
      displayValue: "Land Rover",
      key: "LAND ROVER",
    },
    {
      displayValue: "Lexus",
      key: "LEXUS",
    },
    {
      displayValue: "Lincoln",
      key: "LINCOLN",
    },
    {
      displayValue: "Lotus",
      key: "LOTUS",
    },
    {
      displayValue: "Maserati",
      key: "MASERATI",
    },
    {
      displayValue: "Maybach",
      key: "MAYBACH",
    },
    {
      displayValue: "Mazda",
      key: "MAZDA",
    },
    {
      displayValue: "McLaren",
      key: "MCLAREN",
    },
    {
      displayValue: "Mercedes Benz",
      key: "MERCEDES BENZ",
    },
    {
      displayValue: "Mercury",
      key: "MERCURY",
    },
    {
      displayValue: "Mini",
      key: "MINI",
    },
    {
      displayValue: "Mitsubishi",
      key: "MITSUBISHI",
    },
    {
      displayValue: "Nissan",
      key: "NISSAN",
    },
    {
      displayValue: "Oldsmobile",
      key: "OLDSMOBILE",
    },
    {
      displayValue: "Panoz",
      key: "PANOZ",
    },
    {
      displayValue: "Plymouth",
      key: "PLYMOUTH",
    },
    {
      displayValue: "Pontiac",
      key: "PONTIAC",
    },
    {
      displayValue: "Porsche",
      key: "PORSCHE",
    },
    {
      displayValue: "Ram Truck",
      key: "RAM TRUCK",
    },
    {
      displayValue: "Rolls Royce",
      key: "ROLLS ROYCE",
    },
    {
      displayValue: "Saab",
      key: "SAAB",
    },
    {
      displayValue: "Saturn",
      key: "SATURN",
    },
    {
      displayValue: "Scion",
      key: "SCION",
    },
    {
      displayValue: "Smart",
      key: "SMART",
    },
    {
      displayValue: "Subaru",
      key: "SUBARU",
    },
    {
      displayValue: "Suzuki",
      key: "SUZUKI",
    },
    {
      displayValue: "Tesla",
      key: "TESLA",
    },
    {
      displayValue: "Toyota",
      key: "TOYOTA",
    },
    {
      displayValue: "Volkswagen",
      key: "VOLKSWAGEN",
    },
    {
      displayValue: "Volvo",
      key: "VOLVO",
    },
    {
      displayValue: "Yamaha",
      key: "YAMAHA",
    },
    {
      displayValue: "OTHER",
      key: "OTHER",
    },
  ];
};
export const getSeatingCapacityList = () => {
  return [
    {
      displayValue: "Select",
      key: "",
    },
    {
      displayValue: "1",
      key: "1",
    },
    {
      displayValue: "2",
      key: "2",
    },
    {
      displayValue: "3",
      key: "3",
    },
    {
      displayValue: "4",
      key: "4",
    },
    {
      displayValue: "5",
      key: "5",
    },
    {
      displayValue: "6",
      key: "6",
    },
    {
      displayValue: "7",
      key: "7",
    },
    {
      displayValue: "8",
      key: "8",
    },
    {
      displayValue: "9",
      key: "9",
    },
    {
      displayValue: "10",
      key: "10",
    },
    {
      displayValue: "11",
      key: "11",
    },
    {
      displayValue: "12",
      key: "12",
    },
    {
      displayValue: "13",
      key: "13",
    },
    {
      displayValue: "14",
      key: "14",
    },
  ];
};
export const getVehicleTermsConditions = () => {
  return {
    common: {
      label: "Just Checking",
      msg: "As a sharer, it is your responsibility to make sure your vehicle is in safe and legal driving condition. Citations attributed to lack of vehicle maintenance will be your responsibility. Learn more here",
      conditions: [
        {
          value: "I confirm that my vehicle is safe and street legal.",
          key: "1",
        },
        {
          value: "I confirm that my vehicle is clean.",
          key: "2",
        },
        {
          value:
            "I have read the Eligibility Requirements and agree with the Terms and Conditions of renting my vehicle.",
          key: "3",
        },
        {
          value:
            "I confirm that I have the rights to legally share/rent out this vehicle.",
          key: "4",
        },
      ],
    },
    Insurability: {
      label: "Insurability",
      msg: "In order for us to offer insurance coverage for the vehicle during bookings, we must receive the vehicle's identification info. Please confirm that what you have entered is accurate.",
      conditions: [
        {
          value:
            "I have double checked the VIN, license plate and odometer reading and they are accurate. I understand i will be charged for the vehicle background check if it is found to be fraudulent during safety inspection.",
          key: "1",
        },
        {
          value:
            "I am recognized by my insurance company to be a valid driver of this vehicle and my vehicle has an active/valid insurance policy as required in Terms of Service to be part of JustShareIt.",
          key: "2",
        },
        {
          value:
            "My vehicle operating apparatus(steering wheel, gas, pedal, shifter, etc.) have not been modified to enable handicap drivers.",
          key: "3",
        },
      ],
    },
  };
};
export const getVehicleAnnualMilesDriven = () => {
  return [
    {
      key: "",
      displayValue: "Select",
    },
    {
      key: "1000",
      displayValue: "1-1000",
    },
    {
      key: "2000",
      displayValue: "1001-2000",
    },
    {
      key: "3000",
      displayValue: "2001-3000",
    },
    {
      key: "4000",
      displayValue: "3001-4000",
    },
    {
      key: "5000",
      displayValue: "4001-5000",
    },
    {
      key: "6000",
      displayValue: "5001-6000",
    },
    {
      key: "7000",
      displayValue: "6001-7000",
    },
    {
      key: "8000",
      displayValue: "7001-8000",
    },
    {
      key: "9000",
      displayValue: "8001-9000",
    },
    {
      key: "10000",
      displayValue: "9001-10000",
    },
    {
      key: "11000",
      displayValue: "10001-11000",
    },
    {
      key: "12000",
      displayValue: "11001-12000",
    },
    {
      key: "13000",
      displayValue: "12001-13000",
    },
    {
      key: "14000",
      displayValue: "13001-14000",
    },
    {
      key: "15000",
      displayValue: "14001-15000",
    },
    {
      key: "16000",
      displayValue: "15001-16000",
    },
    {
      key: "17000",
      displayValue: "16001-17000",
    },
    {
      key: "18000",
      displayValue: "17001-18000",
    },
    {
      key: "19000",
      displayValue: "19001-20000",
    },
    {
      key: "19000",
      displayValue: "19001-20000",
    },
    {
      key: "21000",
      displayValue: "20001-21000",
    },
    {
      key: "22000",
      displayValue: "21000+",
    },
  ];
};

export const getAssetAvailabilityStatus = () => {
  return [
    { key: "", displayValue: "All" },
    { key: "IN_FLEET", displayValue: "In Fleet" },
    { key: "AVAILABLE", displayValue: "Available" },
    { key: "PARTIAL_AVAILABLE", displayValue: "Partial Available" },
    { key: "RENTED_OUT", displayValue: "Rented Out" },
    { key: "IN_MAINTENANCE", displayValue: "Maintenance" },
    { key: "SUSPENDED", displayValue: "Suspended" },
  ];
};
export const getAssetTypesOrGroup = () => {
  return [
    { key: "", displayValue: "Any Vehicle Type" },
    { key: "CAR", displayValue: "CAR" },
    { key: "SUV", displayValue: "SUV" },
    { key: "TRUCKMINI", displayValue: "TRUCKMINI" },
  ];
};

export const getProblemCategories = () => {
  return [
    { key: "", displayValue: "Select" },
    { key: "Flat Tire", displayValue: "Flat Tire" },
    { key: "Engine Problem", displayValue: "Engine Problem" },
    { key: "Interior", displayValue: "Interior" },
    { key: "Exterior", displayValue: "Exterior" },
    { key: "Lights", displayValue: "Lights" },
    { key: "Others", displayValue: "Others" },
  ];
};

export const getPriorities = () => {
  return [
    { key: "", displayValue: "Select" },
    { key: "HIGH", displayValue: "High" },
    { key: "MEDIUM", displayValue: "Medium" },
    { key: "LOW", displayValue: "Low" },
  ];
};

export const getDefaultAmenities = () => {
  return [
    {
      Active: false,
      DisplayText: "Handicap Wheelchair loading capability",
      Type: "HANDICAP",
      Value: "HANDICAP_WHEELCHAIR_LOADING",
    },
    {
      Active: false,
      DisplayText: "Handicap sign or license",
      Type: "HANDICAP",
      Value: "HANDICAP_SIGN_LICENSE",
    },
    {
      Active: false,
      DisplayText: "Pets Allowed",
      Type: "PET_HISTORY",
      Value: "PETS_ALLOWED",
    },
    {
      Active: false,
      DisplayText: "Smoking Allowed",
      Type: "SMOKING",
      Value: "SMOKING_ALLOWED",
    },
  ];
};
export const createAddEditVehicleRequest = (
  vehicleInputReq,
  managerId,
  reservationRequestBased
) => {
  // const personObj = { ...memberInputReqest };
  // const overrideOptionsMem = { ...overrideOptions };
  // let getMemberOptions;
  // if (isEdit) {
  //   getMemberOptions = new GetMemberOptions();
  //   getMemberOptions.Employee = true;
  //   getMemberOptions.License = true;
  //   getMemberOptions.Insurance = true;
  //   getMemberOptions.CreditCard = true;
  // }
  let amenitiesIds = [];
  vehicleInputReq.Amenities.map((amenityObj, index) => {
    amenitiesIds.push(amenityObj.Id);
  });
  return new AddEditVehicleRequest(
    vehicleInputReq,
    managerId,
    amenitiesIds,
    reservationRequestBased
  );
};
export const validateAddEditVehicleReq = (data) => {
  let msg = "";
  if (data) {
    if (!data.SelectedChildOrgUnitId) {
      msg = COMMON_MSG.VehicleBelogsTo;
    }
    if (!data.NickName) {
      if (msg) {
        msg += ", ";
      }
      msg += COMMON_MSG.VehicleName;
    }
    if (!data.UnitNo) {
      if (msg) {
        msg += ", ";
      }
      msg += COMMON_MSG.UnitNo;
    }
    if (!data.CategoryId) {
      if (msg) {
        msg += ", ";
      }
      msg += COMMON_MSG.Category;
    }

    if (!data.PersonAssetGroupType) {
      if (msg) {
        msg += ", ";
      }
      msg += COMMON_MSG.VehicleType;
    }
    if (!data.PersonAssetMake) {
      if (msg) {
        msg += ", ";
      }
      msg += COMMON_MSG.MadeBy;
    }
    if (!data.PersonAssetModel) {
      if (msg) {
        msg += ", ";
      }
      msg += COMMON_MSG.Model;
    }
    if (!data.PersonAssetYear) {
      if (msg) {
        msg += ", ";
      }
      msg += COMMON_MSG.Year;
    }
    if (!data.SeatingCapacity) {
      if (msg) {
        msg += ", ";
      }
      msg += COMMON_MSG.SeatingCapacity;
    }
    if (!data.AssetVin) {
      if (msg) {
        msg += ", ";
      }
      msg += COMMON_MSG.VIN;
    }
    if (!data.ActualCashValue) {
      if (msg) {
        msg += ", ";
      }
      msg += COMMON_MSG.ActualCashValue;
    }
    if (!data.AssetLicensePlate) {
      if (msg) {
        msg += ", ";
      }
      msg += COMMON_MSG.LicensePlate;
    }
    if (!data.AssetMileageAtSignUp) {
      if (msg) {
        msg += ", ";
      }
      msg += COMMON_MSG.AssetMileageAtSignUp;
    }
    if (!data.AnnualMilesDriven) {
      if (msg) {
        msg += ", ";
      }
      msg += COMMON_MSG.AnnualMilesDriven;
    }
    if (!data.InsuranceCarrier) {
      if (msg) {
        msg += ", ";
      }
      msg += COMMON_MSG.InsuranceCarrier;
    }
    if (!data.PolicyNumber) {
      if (msg) {
        msg += ", ";
      }
      msg += COMMON_MSG.PolicyNumber;
    }
    if (!data.ParkingAddressCountryId) {
      if (msg) {
        msg += ", ";
      }
      msg += COMMON_MSG.Country;
    }
    if (!data.ParkingAddressStateId && !data.ParkingAddressProvinceId) {
      if (msg) {
        msg += ", ";
      }
      msg += COMMON_MSG.State;
    }
    if (!data.ParkingAddressCityId) {
      if (msg) {
        msg += ", ";
      }
      msg += COMMON_MSG.City;
    }

    if (!data.ParkingAddressZipCodeId) {
      if (msg) {
        msg += ", ";
      }
      msg += COMMON_MSG.ZipPostal;
    }

    if (!data.ParkingAddressStreet1) {
      if (msg) {
        msg += ", ";
      }
      msg += COMMON_MSG.Address1;
    }

    if (!data.ParkingDistanceOption) {
      if (msg) {
        msg += ", ";
      }
      msg += COMMON_MSG.ParkingDistanceOption;
    }
    if (!data.ParkedText) {
      if (msg) {
        msg += ", ";
      }
      msg += COMMON_MSG.ParkedText;
    }
    if (!data.DriveType) {
      if (msg) {
        msg += ", ";
      }
      msg += COMMON_MSG.DriveType;
    }
    if (!data.FuelType) {
      if (msg) {
        msg += ", ";
      }
      msg += COMMON_MSG.FuelType;
    }
    if (!data.FuelConsumptionUnit) {
      if (msg) {
        msg += ", ";
      }
      msg += COMMON_MSG.FuelConsumptionUnit;
    }
    if (!data.TransmissionType) {
      if (msg) {
        msg += ", ";
      }
      msg += COMMON_MSG.TransmissionType;
    }
    if (!data.PersonAssetColor) {
      if (msg) {
        msg += ", ";
      }
      msg += COMMON_MSG.PersonAssetColor;
    }
  }
  if (msg) {
    return getFormattedValidationMsg(msg);
  }
  return msg ? <Fragment>{msg}</Fragment> : "";
};

export const getSelectedVehicleHtml = (element, index, vehicleItemCSSClass) => {
  return (
    <div className={vehicleItemCSSClass} key={element.classCode + index}>
      <ImageContainer
        imageUrl={element.classImageURL || FlashyCar}
        imageCaption=""
        imageTooltip={element.displayText}
        imageCaptionClass=""
        backgroundImageClass=""
        defaultImage={FlashyCar}
        onErrorEvent={loadDefaultImage}
        altText={element.displayText}
      />
    </div>
  );
};

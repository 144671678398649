import React from "react";

const StatusBar = (props) => {
  const {
    statusItems = [],
    contentParentClass = "",
    contentClass1 = "font-weight-bold",
    contentClass2 = "font-weight-bold",
    parentClass = "position-relative",
    noStatusClass,
    defaultWidth,
    defaultHeight
  } = props;

  const getStatusContent = (item, index) => {
    const isEven = index % 2 === 0;
    const labelClass =
      item.tripDurationRatioInValue <= 10
        ? `shuttle-status-bar-label ${isEven ? "even-item" : "odd-item"} ${item.statusFontColor || ""}`
        : "";

    return (
      <div className={`${contentParentClass} ${labelClass}`}>
        <span className={contentClass1} style={{ color: item.color }}>
          {item.statusDisplayValue}
        </span>
        <span className={contentClass2}>
          {item.formattedTripDuration}
        </span>
      </div>
    );
  };

  // Filter valid status items (tripDurationRatioInValue > 0)
  const validStatusItems = statusItems.filter(item => item.tripDurationRatioInValue > 0);

  // If no valid status items, display the 'no data available' message
  if (validStatusItems.length === 0) {
    return (
      <div className={noStatusClass} style={{ width: defaultWidth, minHeight: defaultHeight }}>
        No data available
      </div>
    );
  }

  // Map over valid items to render the status bar
  let lessDurationItemNo = 0;
  const statusBarHtml = validStatusItems.map((item, index) => {
    if (item.tripDurationRatioInValue <= 10) lessDurationItemNo++;
    return (
      <div
        className={`${parentClass} ${item.statusBGColor}`}
        key={index}
        style={{ width: item.tripDurationRatioInPercent }}
      >
        {getStatusContent(item, lessDurationItemNo)}
      </div>
    );
  });

  return <>{statusBarHtml}</>;
};

export default StatusBar;
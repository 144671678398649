import React, { useEffect, useState } from 'react';
import { getPostalWiseDataFromStore, invokeZipCodeStackAPI } from '../../../services/httpService';
import { Utility } from '../../../shared/utility';
import { CountryKeyValueType } from '../../../shared/utility/enum-utility';
import { getKeyValFromList } from '../../../shared/utility/list-utility';
import InputComboCtrl from '../../UI/Input/input-combo-ctrl';
import InputCtrl from '../../UI/Input/input-ctrl';

function PostalCtrl(props) {
    const { name,
        inputName,
        value,
        placeholder,
        disabled,
        imgIco,
        countryCode,
        stateCode,
        cityCode,
        defaultValue,
        addressData,
        addressType,
        onChange,
        onZipToAddressChange,
        getSecurityDepositCharges,
        clearSecurityDepositRate,
        isRequired,
        setStateCityLoader,
        validateFunction } = props;
    const [postals, setPostals] = useState([]);
    const [loader, setLoader] = useState(false);
    useEffect(() => {
        if ((countryCode === CountryKeyValueType.US || countryCode === CountryKeyValueType.IN) && stateCode && cityCode) {
            loadPostals();
        }
        if (!cityCode) {
            if (clearSecurityDepositRate)
                clearSecurityDepositRate();
            setPostals([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [countryCode, stateCode, cityCode])
    const setPostalToAddress = (postalData, postalCode) => {
        onZipToAddressChange({
            postCode: postalData?.postal_code || postalCode,
            country: countryCode,
            state: postalData?.state_en ? postalData?.state_en.toUpperCase() : null,
            stateCode: postalData?.state_code ? postalData?.state_code.toUpperCase() : null,
            city: postalData?.city_en ? postalData?.city_en.toUpperCase() : null,
            stateText: postalData?.state ? postalData?.state.toUpperCase() : null,
            cityText: postalData?.city ? postalData?.city.toUpperCase() : null,
        });
    }
    const onBlur = async (event) => {
        if (disabled) return false;
        if (event?.target?.value) {
            getSecurityDepositCharges(null, countryCode, event?.target?.value);
        }
        const isPostalFound = postals.find((data) => { return data?.keyValue === event?.target?.value });
        if (!isPostalFound && event?.target?.value?.length > 2) {
            const postalData = getPostalWiseDataFromStore(countryCode, event?.target?.value);
            if (postalData) {
                setPostalToAddress(postalData?.result, event?.target?.value);
            } else {
                setStateCityLoader(true);
                const response = await invokeZipCodeStackAPI({
                    codes: event?.target?.value,
                    country: countryCode,
                }, event?.target?.value);
                setStateCityLoader(false);
                if (response?.postal_code) {
                    setPostalToAddress(response);
                } else {
                    setPostalToAddress(response, event?.target?.value);
                }
            }
        }
    }
    /**TO DO: need to change country sotrage and data array list process */
    const loadPostals = async () => {
        try {
            setLoader(true);
            Utility.countryStateCityPostal.getPostals(
                countryCode,
                stateCode,
                cityCode,
                true,
                (postalResponse) => {
                    setPostals(postalResponse?.Postals);
                }
            );
        } catch (e) {
            setLoader(false);
        }
    }
    const onHandleChange = (data) => {
        if (disabled) return false;
        const val = typeof data === "object" ? data?.keyValue : data
        const tempData = { ...addressData };
        tempData[name] = val;
        onChange(tempData, addressType);
    }
    return (
        <>
            {postals?.length > 0 ?
                <InputComboCtrl
                    id={name}
                    name={name}
                    placeholder={placeholder}
                    data={postals}
                    valueField="keyValue"
                    textField="displayValue"
                    value={getKeyValFromList(
                        postals,
                        value,
                        defaultValue,
                        "keyValue"
                    ) || value}
                    onChange={onHandleChange}
                    onBlur={onBlur}
                    disabled={disabled}
                    busy={loader}
                    imgIco={imgIco}
                    isRequired={isRequired}
                    validateFunction={validateFunction}
                /> :
                <InputCtrl
                    name={inputName}
                    value={value}
                    type="text"
                    placeholder={placeholder}
                    onChange={onHandleChange}
                    onBlur={onBlur}
                    imgIco={imgIco}
                    disabled={disabled}
                    isRequired={isRequired}
                    validateFunction={validateFunction}
                />
            }
        </>
    )
}

export default PostalCtrl
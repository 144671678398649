import React from 'react'
import { formatCurrency } from '../../../shared/utility'

function DiscountReason({ discountData, getDisplayValueForReason }) {
    return (
        <>
            <div>
                <label>Granted Reason: </label>
                <label className="font-color-used">{getDisplayValueForReason("discountReasons", discountData?.grantedReason) + ", " + getDisplayValueForReason("discountSubReasons", discountData?.grantedSubReason)}</label>
            </div>
            {discountData?.status === "USED" ?
                <div>
                    <label>Used Res. Code: </label><label className="font-color-used">{discountData?.usedFoxReservationCode}</label><br />
                    <label>Discount Amount:</label> <label className="font-color-reverted">{formatCurrency(discountData?.usedRateDiscountAmount * (-1))}</label>
                </div>
                : discountData?.status === "REVERTED" ?
                    <div>
                        <label>Reverted Reason: </label>
                        <label className="font-color-reverted">{getDisplayValueForReason("revertDiscountReasons", discountData?.revertedReason)}</label>
                    </div> : ""}
        </>
    )
}

export default DiscountReason
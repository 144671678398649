import * as actionTypes from "../actions/actionTypesSettings";
import { reducerUpdateObject } from "./reducer.utility";

const initialState = {
  error: null,
  loading: false,
  mainSettingInfoResponse: null,
  mainSettingInfoUpdateResponse: null,
  hoursOfOpsSettingInfoResponse: null,
  hoursOfOpsSettingUpdateResponse: null,
  taxSurchargeInfoResponse: null,
  taxSurchargeInfoUpdateResponse: null,
  qualificationInfoResponse: null,
  qualificationInfoUpdateResponse: null,
  reservationSettingsInfoResponse: null,
  reservationSettingsInfoUpdateResponse: null,
  insuranceSettingsInfoResponse: null,
  insuranceSettingsInfoUpdateResponse: null,
  additionalOptionsSettingsInfoResponse: null,
  additionalOptionsSettingsInfoUpdateResponse: null,
  locationSettingsInfoSearchResponse: null,
  addLocationResponse: null,
  locationSettingsInfoUpdateResponse: null,
  rateSettingsResponse: null,
  rateSettingsInfoUpdateResponse: null,
  vehicleAvailabilitySettingsResponse: null,
  updateVehicleAvailabilitySettingsResponse: null,
  capacityBucketResponse: null,
  capacityBucketUpdateResponse: null,
  isInheritedData: false,
  inheritedSettingsResponse: null,
  settingsResponse: null,
  updateSettingsResponse: null,
};

//Main Settings Reducers
export const getMainSettingInfoStart = (state) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
export const getMainSettingInfoSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    mainSettingInfoResponse: action.mainSettingInfoResponse,
    error: null,
    loading: false,
  });
};
const getMainSettingInfoFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false,
  });
};
const getMainSettingInfoReset = (state) => {
  return reducerUpdateObject(state, {
    mainSettingInfoResponse: null,
  });
};

export const updateMainSettingInfoStart = (state) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
export const updateMainSettingInfoSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    mainSettingInfoUpdateResponse: action.mainSettingInfoUpdateResponse,
    error: null,
    loading: false,
  });
};
const updateMainSettingInfoFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false,
  });
};
const updateMainSettingInfoReset = (state) => {
  return reducerUpdateObject(state, {
    mainSettingInfoUpdateResponse: null,
  });
};

//Hours Of Operation Reducers
export const getHourOfOpsSettingInfoStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
export const getHourOfOpsSettingInfoSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    hoursOfOpsSettingInfoResponse: action.hoursOfOpsSettingInfoResponse,
    error: null,
    loading: false,
  });
};
const getHourOfOpsSettingInfoFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false,
  });
};
const getHourOfOpsSettingInfoReset = (state) => {
  return reducerUpdateObject(state, {
    hoursOfOpsSettingInfoResponse: null,
  });
};

export const updateHourOfOpsSettingInfoStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
export const updateHourOfOpsSettingInfoSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    hoursOfOpsSettingUpdateResponse: action.hoursOfOpsSettingUpdateResponse,
    error: null,
    loading: false,
  });
};
const updateHourOfOpsSettingInfoFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false,
  });
};
const updateHourOfOpsSettingInfoReset = (state) => {
  return reducerUpdateObject(state, {
    hoursOfOpsSettingUpdateResponse: null,
  });
};

//Tax and Surcharge Reducers
export const getTaxSurchargeInfoStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
export const getTaxSurchargeInfoSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    taxSurchargeInfoResponse: action.taxSurchargeInfoResponse,
    error: null,
    loading: false,
  });
};
const getTaxSurchargeInfoFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false,
  });
};
const getTaxSurchargeInfoReset = (state) => {
  return reducerUpdateObject(state, {
    taxSurchargeInfoResponse: null,
  });
};

export const updateTaxSurchargeInfoStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
export const updateTaxSurchargeInfoSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    taxSurchargeInfoUpdateResponse: action.taxSurchargeInfoUpdateResponse,
    error: null,
    loading: false,
  });
};
const updateTaxSurchargeInfoFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false,
  });
};
const updateTaxSurchargeInfoReset = (state) => {
  return reducerUpdateObject(state, {
    taxSurchargeInfoUpdateResponse: null,
  });
};

//Qualification Reducers
export const getQualificationInfoStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
export const getQualificationInfoSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    qualificationInfoResponse: action.qualificationInfoResponse,
    error: null,
    loading: false,
  });
};
const getQualificationInfoFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false,
  });
};
const getQualificationInfoReset = (state) => {
  return reducerUpdateObject(state, {
    qualificationInfoResponse: null,
  });
};

export const updateQualificationInfoStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
export const updateQualificationInfoSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    qualificationInfoUpdateResponse: action.qualificationInfoUpdateResponse,
    error: null,
    loading: false,
  });
};
const updateQualificationInfoFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false,
  });
};
const updateQualificationInfoReset = (state) => {
  return reducerUpdateObject(state, {
    qualificationInfoUpdateResponse: null,
  });
};

//Reservation Settings Reducers
export const getReservationSettingsInfoStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
export const getReservationSettingsInfoSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    reservationSettingsInfoResponse: action.reservationSettingsInfoResponse,
    error: null,
    loading: false,
  });
};
const getReservationSettingsInfoFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false,
  });
};
const getReservationSettingsInfoReset = (state) => {
  return reducerUpdateObject(state, {
    reservationSettingsInfoResponse: null,
  });
};

export const updateReservationSettingsInfoStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
export const updateReservationSettingsInfoSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    reservationSettingsInfoUpdateResponse:
      action.reservationSettingsInfoUpdateResponse,
    error: null,
    loading: false,
  });
};
const updateReservationSettingsInfoFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false,
  });
};
const updateReservationSettingsInfoReset = (state) => {
  return reducerUpdateObject(state, {
    reservationSettingsInfoUpdateResponse: null,
  });
};

//Insurance Settings Reducers
export const getInsuranceSettingsInfoStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
export const getInsuranceSettingsInfoSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    insuranceSettingsInfoResponse: action.insuranceSettingsInfoResponse,
    error: null,
    loading: false,
  });
};
const getInsuranceSettingsInfoFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false,
  });
};
const getInsuranceSettingsInfoReset = (state) => {
  return reducerUpdateObject(state, {
    insuranceSettingsInfoResponse: null,
  });
};

export const updateInsuranceSettingsInfoStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
export const updateInsuranceSettingsInfoSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    insuranceSettingsInfoUpdateResponse:
      action.insuranceSettingsInfoUpdateResponse,
    error: null,
    loading: false,
  });
};
const updateInsuranceSettingsInfoFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false,
  });
};
const updateInsuranceSettingsInfoReset = (state) => {
  return reducerUpdateObject(state, {
    insuranceSettingsInfoUpdateResponse: null,
  });
};

//Additional Options Settings Reducers
export const getAdditionalOptionsSettingsInfoStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
export const getAdditionalOptionsSettingsInfoSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    additionalOptionsSettingsInfoResponse:
      action.additionalOptionsSettingsInfoResponse,
    error: null,
    loading: false,
  });
};
const getAdditionalOptionsSettingsInfoFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false,
  });
};
const getAdditionalOptionsSettingsInfoReset = (state) => {
  return reducerUpdateObject(state, {
    additionalOptionsSettingsInfoResponse: null,
  });
};

export const updateAdditionalOptionsSettingsInfoStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
export const updateAdditionalOptionsSettingsInfoSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    additionalOptionsSettingsInfoUpdateResponse:
      action.additionalOptionsSettingsInfoUpdateResponse,
    error: null,
    loading: false,
  });
};
const updateAdditionalOptionsSettingsInfoFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false,
  });
};
const updateAdditionalOptionsSettingsInfoReset = (state) => {
  return reducerUpdateObject(state, {
    additionalOptionsSettingsInfoUpdateResponse: null,
  });
};

//Location Settings Reducers
export const getLocationSettingsInfoStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
export const getLocationSettingsInfoSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    locationSettingsInfoSearchResponse:
      action.locationSettingsInfoSearchResponse,
    error: null,
    loading: false,
  });
};
const getLocationSettingsInfoFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false,
  });
};
const getLocationSettingsInfoReset = (state) => {
  return reducerUpdateObject(state, {
    locationSettingsInfoSearchResponse: null,
  });
};

export const addLocationSettingsInfoStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
export const addLocationSettingsInfoSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    addLocationResponse: action.addLocationResponse,
    error: null,
    loading: false,
  });
};
const addLocationSettingsInfoFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false,
  });
};
const addLocationSettingsInfoReset = (state) => {
  return reducerUpdateObject(state, {
    addLocationResponse: null,
  });
};

export const updateLocationSettingsInfoStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
export const updateLocationSettingsInfoSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    locationSettingsInfoUpdateResponse:
      action.locationSettingsInfoUpdateResponse,
    error: null,
    loading: false,
  });
};
const updateLocationSettingsInfoFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false,
  });
};
const updateLocationSettingsInfoReset = (state) => {
  return reducerUpdateObject(state, {
    locationSettingsInfoUpdateResponse: null,
  });
};

//Rate Settings Reducers
export const getRateSettingsInfoStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
export const getRateSettingsInfoSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    rateSettingsResponse: action.rateSettingsResponse,
    error: null,
    loading: false,
  });
};
const getRateSettingsInfoFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false,
  });
};
const getRateSettingsInfoReset = (state) => {
  return reducerUpdateObject(state, {
    rateSettingsResponse: null,
  });
};

export const updateRateSettingsInfoStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
export const updateRateSettingsInfoSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    rateSettingsInfoUpdateResponse: action.rateSettingsInfoUpdateResponse,
    error: null,
    loading: false,
  });
};
const updateRateSettingsInfoFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false,
  });
};
const updateRateSettingsInfoReset = (state) => {
  return reducerUpdateObject(state, {
    rateSettingsInfoUpdateResponse: null,
  });
};

//Vehicle Availability Settings Reducers
export const getVehicleAvailabilitySettingsInfoStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
export const getVehicleAvailabilitySettingsInfoSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    vehicleAvailabilitySettingsResponse:
      action.vehicleAvailabilitySettingsResponse,
    error: null,
    loading: false,
  });
};
const getVehicleAvailabilitySettingsInfoFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false,
  });
};
const getVehicleAvailabilitySettingsInfoReset = (state) => {
  return reducerUpdateObject(state, {
    vehicleAvailabilitySettingsResponse: null,
  });
};

export const updateVehicleAvailabilitySettingsInfoStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
export const updateVehicleAvailabilitySettingsInfoSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    updateVehicleAvailabilitySettingsResponse:
      action.updateVehicleAvailabilitySettingsResponse,
    error: null,
    loading: false,
  });
};
const updateVehicleAvailabilitySettingsInfoFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false,
  });
};
const updateVehicleAvailabilitySettingsInfoReset = (state) => {
  return reducerUpdateObject(state, {
    updateVehicleAvailabilitySettingsResponse: null,
  });
};

//Capacity Planning Reducers
export const getCapacityBucketsStart = (state) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
export const getCapacityBucketsSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    capacityBucketResponse: action.capacityBucketResponse,
    error: null,
    loading: false,
  });
};
const getCapacityBucketsReset = (state) => {
  return reducerUpdateObject(state, {
    capacityBucketResponse: null,
  });
};

export const updateCapacityBucketsStart = (state) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
export const updateCapacityBucketsSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    capacityBucketUpdateResponse: action.capacityBucketUpdateResponse,
    error: null,
    loading: false,
  });
};
const updateCapacityBucketsReset = (state) => {
  return reducerUpdateObject(state, {
    capacityBucketUpdateResponse: null,
  });
};

/** Common method for festh settings data*/
export const getSettingstDataByApiStart = (state) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
export const getSettingstDataByApiSuccess = (state, action) => {
  return action.isInheritedData ? reducerUpdateObject(state, {
    inheritedSettingsResponse: action.settingsResponse,
    error: null,
    loading: false,
  }) : reducerUpdateObject(state, {
    settingsResponse: action.settingsResponse,
    error: null,
    loading: false,
  });
};
const getSettingstDataByApiReset = (state, action) => {
  return action.isInheritedData ? reducerUpdateObject(state, {
    inheritedSettingsResponse: null,
  }) : reducerUpdateObject(state, {
    settingsResponse: null,
  });
};
/** Common method for update settings data*/
export const updateSettingstDataByApiStart = (state) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
export const updateSettingstDataByApiSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    updateSettingsResponse: action.updateSettingsResponse,
    error: null,
    loading: false,
  });
};
const updateSettingstDataByApiReset = (state) => {
  return reducerUpdateObject(state, {
    updateSettingsResponse: null,
  });
};
/** Common method for fail*/
const doForCommonSettingsFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_MAIN_SETTING_INFO_START:
      return getMainSettingInfoStart(state, action);
    case actionTypes.GET_MAIN_SETTING_INFO_SUCCESS:
      return getMainSettingInfoSuccess(state, action);
    case actionTypes.GET_MAIN_SETTING_INFO_FAIL:
      return getMainSettingInfoFail(state, action);
    case actionTypes.GET_MAIN_SETTING_INFO_RESET:
      return getMainSettingInfoReset(state);

    case actionTypes.UPDATE_MAIN_SETTING_INFO_START:
      return updateMainSettingInfoStart(state, action);
    case actionTypes.UPDATE_MAIN_SETTING_INFO_SUCCESS:
      return updateMainSettingInfoSuccess(state, action);
    case actionTypes.UPDATE_MAIN_SETTING_INFO_FAIL:
      return updateMainSettingInfoFail(state, action);
    case actionTypes.UPDATE_MAIN_SETTING_INFO_RESET:
      return updateMainSettingInfoReset(state);

    case actionTypes.GET_HOUR_OF_OPS_SETTING_INFO_START:
      return getHourOfOpsSettingInfoStart(state, action);
    case actionTypes.GET_HOUR_OF_OPS_SETTING_INFO_SUCCESS:
      return getHourOfOpsSettingInfoSuccess(state, action);
    case actionTypes.GET_HOUR_OF_OPS_SETTING_INFO_FAIL:
      return getHourOfOpsSettingInfoFail(state, action);
    case actionTypes.GET_HOUR_OF_OPS_SETTING_INFO_RESET:
      return getHourOfOpsSettingInfoReset(state);

    case actionTypes.UPDATE_HOUR_OF_OPS_SETTING_INFO_START:
      return updateHourOfOpsSettingInfoStart(state, action);
    case actionTypes.UPDATE_HOUR_OF_OPS_SETTING_INFO_SUCCESS:
      return updateHourOfOpsSettingInfoSuccess(state, action);
    case actionTypes.UPDATE_HOUR_OF_OPS_SETTING_INFO_FAIL:
      return updateHourOfOpsSettingInfoFail(state, action);
    case actionTypes.UPDATE_HOUR_OF_OPS_SETTING_INFO_RESET:
      return updateHourOfOpsSettingInfoReset(state);

    case actionTypes.GET_TAX_SURCHARGE_INFO_START:
      return getTaxSurchargeInfoStart(state, action);
    case actionTypes.GET_TAX_SURCHARGE_INFO_SUCCESS:
      return getTaxSurchargeInfoSuccess(state, action);
    case actionTypes.GET_TAX_SURCHARGE_INFO_FAIL:
      return getTaxSurchargeInfoFail(state, action);
    case actionTypes.GET_TAX_SURCHARGE_INFO_RESET:
      return getTaxSurchargeInfoReset(state);

    case actionTypes.UPDATE_TAX_SURCHARGE_INFO_START:
      return updateTaxSurchargeInfoStart(state, action);
    case actionTypes.UPDATE_TAX_SURCHARGE_INFO_SUCCESS:
      return updateTaxSurchargeInfoSuccess(state, action);
    case actionTypes.UPDATE_TAX_SURCHARGE_INFO_FAIL:
      return updateTaxSurchargeInfoFail(state, action);
    case actionTypes.UPDATE_TAX_SURCHARGE_INFO_RESET:
      return updateTaxSurchargeInfoReset(state);

    case actionTypes.GET_QUALIFICATION_INFO_START:
      return getQualificationInfoStart(state, action);
    case actionTypes.GET_QUALIFICATION_INFO_SUCCESS:
      return getQualificationInfoSuccess(state, action);
    case actionTypes.GET_QUALIFICATION_INFO_FAIL:
      return getQualificationInfoFail(state, action);
    case actionTypes.GET_QUALIFICATION_INFO_RESET:
      return getQualificationInfoReset(state);

    case actionTypes.UPDATE_QUALIFICATION_INFO_START:
      return updateQualificationInfoStart(state, action);
    case actionTypes.UPDATE_QUALIFICATION_INFO_SUCCESS:
      return updateQualificationInfoSuccess(state, action);
    case actionTypes.UPDATE_QUALIFICATION_INFO_FAIL:
      return updateQualificationInfoFail(state, action);
    case actionTypes.UPDATE_QUALIFICATION_INFO_RESET:
      return updateQualificationInfoReset(state);

    case actionTypes.GET_RES_SETTINGS_INFO_START:
      return getReservationSettingsInfoStart(state, action);
    case actionTypes.GET_RES_SETTINGS_INFO_SUCCESS:
      return getReservationSettingsInfoSuccess(state, action);
    case actionTypes.GET_RES_SETTINGS_INFO_FAIL:
      return getReservationSettingsInfoFail(state, action);
    case actionTypes.GET_RES_SETTINGS_INFO_RESET:
      return getReservationSettingsInfoReset(state);

    case actionTypes.UPDATE_RES_SETTINGS_INFO_START:
      return updateReservationSettingsInfoStart(state, action);
    case actionTypes.UPDATE_RES_SETTINGS_INFO_SUCCESS:
      return updateReservationSettingsInfoSuccess(state, action);
    case actionTypes.UPDATE_RES_SETTINGS_INFO_FAIL:
      return updateReservationSettingsInfoFail(state, action);
    case actionTypes.UPDATE_RES_SETTINGS_INFO_RESET:
      return updateReservationSettingsInfoReset(state);

    case actionTypes.GET_INSURANCE_SETTINGS_INFO_START:
      return getInsuranceSettingsInfoStart(state, action);
    case actionTypes.GET_INSURANCE_SETTINGS_INFO_SUCCESS:
      return getInsuranceSettingsInfoSuccess(state, action);
    case actionTypes.GET_INSURANCE_SETTINGS_INFO_FAIL:
      return getInsuranceSettingsInfoFail(state, action);
    case actionTypes.GET_INSURANCE_SETTINGS_INFO_RESET:
      return getInsuranceSettingsInfoReset(state);

    case actionTypes.UPDATE_INSURANCE_SETTINGS_INFO_START:
      return updateInsuranceSettingsInfoStart(state, action);
    case actionTypes.UPDATE_INSURANCE_SETTINGS_INFO_SUCCESS:
      return updateInsuranceSettingsInfoSuccess(state, action);
    case actionTypes.UPDATE_INSURANCE_SETTINGS_INFO_FAIL:
      return updateInsuranceSettingsInfoFail(state, action);
    case actionTypes.UPDATE_INSURANCE_SETTINGS_INFO_RESET:
      return updateInsuranceSettingsInfoReset(state);

    case actionTypes.GET_ADDONS_SETTINGS_INFO_START:
      return getAdditionalOptionsSettingsInfoStart(state, action);
    case actionTypes.GET_ADDONS_SETTINGS_INFO_SUCCESS:
      return getAdditionalOptionsSettingsInfoSuccess(state, action);
    case actionTypes.GET_ADDONS_SETTINGS_INFO_FAIL:
      return getAdditionalOptionsSettingsInfoFail(state, action);
    case actionTypes.GET_ADDONS_SETTINGS_INFO_RESET:
      return getAdditionalOptionsSettingsInfoReset(state);

    case actionTypes.UPDATE_ADDONS_SETTINGS_INFO_START:
      return updateAdditionalOptionsSettingsInfoStart(state, action);
    case actionTypes.UPDATE_ADDONS_SETTINGS_INFO_SUCCESS:
      return updateAdditionalOptionsSettingsInfoSuccess(state, action);
    case actionTypes.UPDATE_ADDONS_SETTINGS_INFO_FAIL:
      return updateAdditionalOptionsSettingsInfoFail(state, action);
    case actionTypes.UPDATE_ADDONS_SETTINGS_INFO_RESET:
      return updateAdditionalOptionsSettingsInfoReset(state);

    case actionTypes.GET_LOCATION_SETTINGS_INFO_START:
      return getLocationSettingsInfoStart(state, action);
    case actionTypes.GET_LOCATION_SETTINGS_INFO_SUCCESS:
      return getLocationSettingsInfoSuccess(state, action);
    case actionTypes.GET_LOCATION_SETTINGS_INFO_FAIL:
      return getLocationSettingsInfoFail(state, action);
    case actionTypes.GET_LOCATION_SETTINGS_INFO_RESET:
      return getLocationSettingsInfoReset(state);

    case actionTypes.ADD_LOCATION_SETTINGS_INFO_START:
      return addLocationSettingsInfoStart(state, action);
    case actionTypes.ADD_LOCATION_SETTINGS_INFO_SUCCESS:
      return addLocationSettingsInfoSuccess(state, action);
    case actionTypes.ADD_LOCATION_SETTINGS_INFO_FAIL:
      return addLocationSettingsInfoFail(state, action);
    case actionTypes.ADD_LOCATION_SETTINGS_INFO_RESET:
      return addLocationSettingsInfoReset(state);

    case actionTypes.UPDATE_LOCATION_SETTINGS_INFO_START:
      return updateLocationSettingsInfoStart(state, action);
    case actionTypes.UPDATE_LOCATION_SETTINGS_INFO_SUCCESS:
      return updateLocationSettingsInfoSuccess(state, action);
    case actionTypes.UPDATE_LOCATION_SETTINGS_INFO_FAIL:
      return updateLocationSettingsInfoFail(state, action);
    case actionTypes.UPDATE_LOCATION_SETTINGS_INFO_RESET:
      return updateLocationSettingsInfoReset(state);

    case actionTypes.GET_RATE_SETTINGS_INFO_START:
      return getRateSettingsInfoStart(state, action);
    case actionTypes.GET_RATE_SETTINGS_INFO_SUCCESS:
      return getRateSettingsInfoSuccess(state, action);
    case actionTypes.GET_RATE_SETTINGS_INFO_FAIL:
      return getRateSettingsInfoFail(state, action);
    case actionTypes.GET_RATE_SETTINGS_INFO_RESET:
      return getRateSettingsInfoReset(state);

    case actionTypes.UPDATE_RATE_SETTINGS_INFO_START:
      return updateRateSettingsInfoStart(state, action);
    case actionTypes.UPDATE_RATE_SETTINGS_INFO_SUCCESS:
      return updateRateSettingsInfoSuccess(state, action);
    case actionTypes.UPDATE_RATE_SETTINGS_INFO_FAIL:
      return updateRateSettingsInfoFail(state, action);
    case actionTypes.UPDATE_RATE_SETTINGS_INFO_RESET:
      return updateRateSettingsInfoReset(state);

    case actionTypes.GET_VEHICLE_AVAILABILITY_SETTINGS_INFO_START:
      return getVehicleAvailabilitySettingsInfoStart(state, action);
    case actionTypes.GET_VEHICLE_AVAILABILITY_SETTINGS_INFO_SUCCESS:
      return getVehicleAvailabilitySettingsInfoSuccess(state, action);
    case actionTypes.GET_VEHICLE_AVAILABILITY_SETTINGS_INFO_FAIL:
      return getVehicleAvailabilitySettingsInfoFail(state, action);
    case actionTypes.GET_VEHICLE_AVAILABILITY_SETTINGS_INFO_RESET:
      return getVehicleAvailabilitySettingsInfoReset(state);

    case actionTypes.UPDATE_VEHICLE_AVAILABILITY_SETTINGS_INFO_START:
      return updateVehicleAvailabilitySettingsInfoStart(state, action);
    case actionTypes.UPDATE_VEHICLE_AVAILABILITY_SETTINGS_INFO_SUCCESS:
      return updateVehicleAvailabilitySettingsInfoSuccess(state, action);
    case actionTypes.UPDATE_VEHICLE_AVAILABILITY_SETTINGS_INFO_FAIL:
      return updateVehicleAvailabilitySettingsInfoFail(state, action);
    case actionTypes.UPDATE_VEHICLE_AVAILABILITY_SETTINGS_INFO_RESET:
      return updateVehicleAvailabilitySettingsInfoReset(state);

    case actionTypes.GET_CAPACITY_PLANNING_DATA_START:
      return getCapacityBucketsStart(state);
    case actionTypes.GET_CAPACITY_PLANNING_DATA_SUCCESS:
      return getCapacityBucketsSuccess(state, action);
    case actionTypes.GET_CAPACITY_PLANNING_DATA_FAIL:
      return doForCommonSettingsFail(state, action);
    case actionTypes.GET_CAPACITY_PLANNING_DATA_RESET:
      return getCapacityBucketsReset(state);

    case actionTypes.UPDATE_CAPACITY_PLANNING_DATA_START:
      return updateCapacityBucketsStart(state);
    case actionTypes.UPDATE_CAPACITY_PLANNING_DATA_SUCCESS:
      return updateCapacityBucketsSuccess(state, action);
    case actionTypes.UUPDATE_CAPACITY_PLANNING_DATA_FAIL:
      return doForCommonSettingsFail(state, action);
    case actionTypes.UPDATE_CAPACITY_PLANNING_DATA_RESET:
      return updateCapacityBucketsReset(state);

    case actionTypes.GET_SETTINGS_DATA_API_START:
      return getSettingstDataByApiStart(state);
    case actionTypes.GET_SETTINGS_DATA_API_SUCCESS:
      return getSettingstDataByApiSuccess(state, action);
    case actionTypes.GET_SETTINGS_DATA_API_FAIL:
      return doForCommonSettingsFail(state, action);
    case actionTypes.GET_SETTINGS_DATA_API_RESET:
      return getSettingstDataByApiReset(state, action);

    case actionTypes.UPDATE_SETTINGS_DATA_API_START:
      return updateSettingstDataByApiStart(state);
    case actionTypes.UPDATE_SETTINGS_DATA_API_SUCCESS:
      return updateSettingstDataByApiSuccess(state, action);
    case actionTypes.UPDATE_SETTINGS_DATA_API_FAIL:
      return doForCommonSettingsFail(state, action);
    case actionTypes.UPDATE_SETTINGS_DATA_API_RESET:
      return updateSettingstDataByApiReset(state);

    default:
      return state;
  }
};
export default reducer;

import * as actionTypes from "./actionTypes";
import HttpService, { invokeServerAPI } from "../../services/httpService";

export const doMemberSearchStart = () => {
  return {
    type: actionTypes.SEARCH_MEMBER_START,
  };
};
export const doMemberSearchSuccess = (response) => {
  return {
    type: actionTypes.SEARCH_MEMBER_SUCCESS,
    searchMemberResponse: response,
  };
};
export const doMemberSearchFail = (error) => {
  return {
    type: actionTypes.SEARCH_MEMBER_FAIL,
    error: error,
  };
};
export const doMemberSearchReset = () => {
  return {
    type: actionTypes.SEARCH_MEMBER_RESET,
  };
};
export const getMemberStart = () => {
  return {
    type: actionTypes.GET_MEMBER_START,
  };
};
export const getMemberSuccess = (memberData) => {
  return {
    type: actionTypes.GET_MEMBER_SUCCESS,
    memberData: memberData,
  };
};
export const getMemberFail = (error) => {
  return {
    type: actionTypes.GET_MEMBER_FAIL,
    error: error,
  };
};
export const getMemberReset = () => {
  return {
    type: actionTypes.GET_MEMBER_RESET,
  };
};
export const createMemberStart = () => {
  return {
    type: actionTypes.CREATE_MEMBER_START,
  };
};
export const createMemberSuccess = (response) => {
  return {
    type: actionTypes.CREATE_MEMBER_SUCCESS,
    createMemberResponse: response,
  };
};
export const createMemberFail = (error) => {
  return {
    type: actionTypes.CREATE_MEMBER_FAIL,
    error: error,
  };
};
export const createMemberReset = () => {
  return {
    type: actionTypes.CREATE_MEMBER_RESET,
  };
};
export const editMemberStart = () => {
  return {
    type: actionTypes.EDIT_MEMBER_START,
  };
};
export const editMemberSuccess = (response) => {
  return {
    type: actionTypes.EDIT_MEMBER_SUCCESS,
    editMemberResponse: response,
  };
};
export const editMemberFail = (error) => {
  return {
    type: actionTypes.EDIT_MEMBER_FAIL,
    error: error,
  };
};
export const editMemberReset = () => {
  return {
    type: actionTypes.EDIT_MEMBER_RESET,
  };
};
export const getConflictedMemberStart = () => {
  return {
    type: actionTypes.GET_CONFLICTED_MEMBER_START,
  };
};
export const getConflictedMemberSuccess = (conflictedMemberData) => {
  return {
    type: actionTypes.GET_CONFLICTED_MEMBER_SUCCESS,
    conlictedMemberData: conflictedMemberData,
  };
};
export const getConflictedMemberFail = (error) => {
  return {
    type: actionTypes.GET_CONFLICTED_MEMBER_FAIL,
    error: error,
  };
};
export const getConflictedMemberListReset = () => {
  return {
    type: actionTypes.GET_CONFLICTED_MEMBER_RESET,
  };
};

export const blockUnblockMemberProfileStart = () => {
  return {
    type: actionTypes.BLOCK_UNBLOCK_PROFILE_START,
  };
};
export const blockUnblockMemberProfileSuccess = (blockUnblockResponse) => {
  return {
    type: actionTypes.BLOCK_UNBLOCK_PROFILE_SUCCESS,
    blockUnblockResponse: blockUnblockResponse,
  };
};
export const blockUnblockMemberProfileFail = (error) => {
  return {
    type: actionTypes.BLOCK_UNBLOCK_PROFILE_FAIL,
    error: error,
  };
};
export const blockUnblockMemberProfileReset = () => {
  return {
    type: actionTypes.BLOCK_UNBLOCK_PROFILE_RESET,
  };
};

export const cancelMembershipStart = () => {
  return {
    type: actionTypes.CANCEL_MEMBER_START,
  };
};
export const cancelMembershipSuccess = (cancelMemberResponse) => {
  return {
    type: actionTypes.CANCEL_MEMBER_SUCCESS,
    cancelMemberResponse: cancelMemberResponse,
  };
};
export const cancelMembershipFail = (error) => {
  return {
    type: actionTypes.CANCEL_MEMBER_FAIL,
    error: error,
  };
};
export const cancelMembershipReset = () => {
  return {
    type: actionTypes.CANCEL_MEMBER_RESET,
  };
};

export const reactivateMemberStart = () => {
  return {
    type: actionTypes.ACTIVATE_MEMBER_START,
  };
};
export const reactivateMemberSuccess = (reactivateMemberResponse) => {
  return {
    type: actionTypes.ACTIVATE_MEMBER_SUCCESS,
    reactivateMemberResponse: reactivateMemberResponse,
  };
};
export const reactivateMemberFail = (error) => {
  return {
    type: actionTypes.ACTIVATE_MEMBER_FAIL,
    error: error,
  };
};
export const reactivateMemberReset = () => {
  return {
    type: actionTypes.ACTIVATE_MEMBER_RESET,
  };
};

export const changeTemporaryPasswordStart = () => {
  return {
    type: actionTypes.CHANGE_TEMPORARY_PASSWORD_START,
  };
};
export const changeTemporaryPasswordSuccess = (
  changeTemporaryPasswordResponse
) => {
  return {
    type: actionTypes.CHANGE_TEMPORARY_PASSWORD_SUCCESS,
    changeTemporaryPasswordResponse: changeTemporaryPasswordResponse,
  };
};
export const changeTemporaryPasswordFail = (error) => {
  return {
    type: actionTypes.CHANGE_TEMPORARY_PASSWORD_FAIL,
    error: error,
  };
};
export const changeTemporaryPasswordReset = () => {
  return {
    type: actionTypes.CHANGE_TEMPORARY_PASSWORD_RESET,
  };
};

export const resetPasswordStart = () => {
  return {
    type: actionTypes.RESET_PASSWORD_START,
  };
};
export const resetPasswordSuccess = (resetPasswordReponse) => {
  return {
    type: actionTypes.RESET_PASSWORD_SUCCESS,
    resetPasswordReponse: resetPasswordReponse,
  };
};
export const resetPasswordFail = (error) => {
  return {
    type: actionTypes.RESET_PASSWORD_FAIL,
    error: error,
  };
};
export const resetPasswordReset = () => {
  return {
    type: actionTypes.RESET_PASSWORD_RESET,
  };
};

export const verifyMemberAccountStart = () => {
  return {
    type: actionTypes.VERIFY_MEMBER_ACCOUNT_START,
  };
};
export const verifyMemberAccountSuccess = (verifyMemberAccountResponse) => {
  return {
    type: actionTypes.VERIFY_MEMBER_ACCOUNT_SUCCESS,
    verifyMemberAccountResponse: verifyMemberAccountResponse,
  };
};
export const verifyMemberAccountFail = (error) => {
  return {
    type: actionTypes.VERIFY_MEMBER_ACCOUNT_FAIL,
    error: error,
  };
};
export const verifyMemberAccountReset = () => {
  return {
    type: actionTypes.VERIFY_MEMBER_ACCOUNT_RESET,
  };
};

export const addViolationPointStart = () => {
  return {
    type: actionTypes.ADD_VIOLATION_POINT_START,
  };
};
export const addViolationPointSuccess = (addViolationPointResponse) => {
  return {
    type: actionTypes.ADD_VIOLATION_POINT_SUCCESS,
    addViolationPointResponse: addViolationPointResponse,
  };
};
export const addViolationPointFail = (error) => {
  return {
    type: actionTypes.ADD_VIOLATION_POINT_FAIL,
    error: error,
  };
};
export const addViolationPointReset = () => {
  return {
    type: actionTypes.ADD_VIOLATION_POINT_RESET,
  };
};

export const allowTemporaryLicenseImageUploadStart = () => {
  return {
    type: actionTypes.ALLOW_TEMP_IMG_UPLOAD_START,
  };
};
export const allowTemporaryLicenseImageUploadSuccess = (
  allowTempImgResponse
) => {
  return {
    type: actionTypes.ALLOW_TEMP_IMG_UPLOAD_SUCCESS,
    allowTempImgResponse: allowTempImgResponse,
  };
};
export const allowTemporaryLicenseImageUploadFail = (error) => {
  return {
    type: actionTypes.ALLOW_TEMP_IMG_UPLOAD_FAIL,
    error: error,
  };
};
export const allowTemporaryLicenseImageUploadReset = () => {
  return {
    type: actionTypes.ALLOW_TEMP_IMG_UPLOAD_RESET,
  };
};
export const searchMemberFavoriteAssetStart = () => {
  return {
    type: actionTypes.SEARCH_MEMBER_FAVORITE_START,
  };
};
export const searchMemberFavoriteAssetSuccess = (response) => {
  return {
    type: actionTypes.SEARCH_MEMBER_FAVORITE_SUCCESS,
    memberFavoriteAssetResponse: response,
  };
};
export const searchMemberFavoriteAssetFail = (error) => {
  return {
    type: actionTypes.SEARCH_MEMBER_FAVORITE_FAIL,
    error: error,
  };
};
export const searchMemberFavoriteAssetReset = () => {
  return {
    type: actionTypes.SEARCH_MEMBER_FAVORITE_RESET,
  };
};

export const searchMemberMessageStart = () => {
  return {
    type: actionTypes.SEARCH_MEMBER_MESSAGE_START,
  };
};
export const searchMemberMessageSuccess = (response) => {
  return {
    type: actionTypes.SEARCH_MEMBER_MESSAGE_SUCCESS,
    memberMessageResponse: response,
  };
};
export const searchMemberMessageFail = (error) => {
  return {
    type: actionTypes.SEARCH_MEMBER_MESSAGE_FAIL,
    error: error,
  };
};
export const searchMemberMessageReset = () => {
  return {
    type: actionTypes.SEARCH_MEMBER_MESSAGE_RESET,
  };
};

export const searchMemberVehicleStart = () => {
  return {
    type: actionTypes.SEARCH_MEMBER_VEHICLE_START,
  };
};
export const searchMemberVehicleSuccess = (response) => {
  return {
    type: actionTypes.SEARCH_MEMBER_VEHICLE_SUCCESS,
    memberVehicleResponse: response,
  };
};
export const searchMemberVehicleFail = (error) => {
  return {
    type: actionTypes.SEARCH_MEMBER_VEHICLE_FAIL,
    error: error,
  };
};
export const searchMemberVehicleReset = () => {
  return {
    type: actionTypes.SEARCH_MEMBER_VEHICLE_RESET,
  };
};

export const searchCreditHistoryStart = () => {
  return {
    type: actionTypes.GET_CREDIT_HISTORY_START,
  };
};
export const searchCreditHistorySuccess = (response) => {
  return {
    type: actionTypes.GET_CREDIT_HISTORY_SUCCESS,
    searchCreditHistoryResponse: response,
  };
};
export const searchCreditHistoryFail = (error) => {
  return {
    type: actionTypes.GET_CREDIT_HISTORY_FAIL,
    error: error,
  };
};
export const searchCreditHistoryReset = () => {
  return {
    type: actionTypes.GET_CREDIT_HISTORY_RESET,
  };
};

export const addFreeCreditStart = () => {
  return {
    type: actionTypes.ADD_FREE_CREDIT_START,
  };
};
export const addFreeCreditSuccess = (response) => {
  return {
    type: actionTypes.ADD_FREE_CREDIT_SUCCESS,
    addFreeCreditResponse: response,
  };
};
export const addFreeCreditFail = (error) => {
  return {
    type: actionTypes.ADD_FREE_CREDIT_FAIL,
    error: error,
  };
};
export const addFreeCreditReset = () => {
  return {
    type: actionTypes.ADD_FREE_CREDIT_RESET,
  };
};

export const reverseCreditStart = () => {
  return {
    type: actionTypes.REVERSE_CREDIT_START,
  };
};
export const reverseCreditSuccess = (response) => {
  return {
    type: actionTypes.REVERSE_CREDIT_SUCCESS,
    reverseCreditResponse: response,
  };
};
export const reverseCreditFail = (error) => {
  return {
    type: actionTypes.REVERSE_CREDIT_FAIL,
    error: error,
  };
};
export const reverseCreditReset = () => {
  return {
    type: actionTypes.REVERSE_CREDIT_RESET,
  };
};

export const doMemberSearch = (searchMemReq) => {
  return (dispatch) => {
    dispatch(doMemberSearchStart());
    HttpService.doMemberSearch(searchMemReq)
      .then((response) => {
        dispatch(doMemberSearchSuccess(response));
      })
      .catch((err) => {
        //dispatch(doMemberSearchFail(err));
      });
  };
};
export const getMember = (memberReq) => {
  return (dispatch) => {
    dispatch(getMemberStart());
    HttpService.getMember(memberReq)
      .then((response) => {
        dispatch(getMemberSuccess(response));
        // HistoryDataAdd(response.Member, "MEMBER");
      })
      .catch((err) => {
        //dispatch(getLocationBasedSettingsFail(err));
      });
  };
};
export const createMember = (addEditReq) => {
  return (dispatch) => {
    dispatch(createMemberStart());
    HttpService.doNewMemberRegistration(addEditReq)
      .then((response) => {
        dispatch(createMemberSuccess(response));
      })
      .catch((err) => {
        //dispatch(createMemberFail(err));
      });
  };
};
export const editMember = (addEditReq) => {
  return (dispatch) => {
    dispatch(editMemberStart());
    HttpService.doMemberUpdate(addEditReq)
      .then((response) => {
        dispatch(editMemberSuccess(response));
      })
      .catch((err) => {
        //dispatch(editMemberFail(err));
      });
  };
};
export const getConflictedMemberList = (conflictedMemberReq) => {
  return (dispatch) => {
    dispatch(getConflictedMemberStart());
    invokeServerAPI("searchconflictedmember", conflictedMemberReq)
      .then((response) => {
        dispatch(getConflictedMemberSuccess(response));
      })
      .catch((err) => {
        //dispatch(getConflictedMemberFail(err));
      });
  };
};

export const blockUnblockMemberProfile = (
  blockUnblockReq,
  currentMemberStatus
) => {
  return (dispatch) => {
    dispatch(blockUnblockMemberProfileStart());
    if (currentMemberStatus === "SUSPENDED") {
      HttpService.unBlockMemberProfile(blockUnblockReq)
        .then((response) => {
          dispatch(blockUnblockMemberProfileSuccess(response));
        })
        .catch((err) => {
          //dispatch(blockUnblockMemberProfileFail(err));
        });
    } else {
      HttpService.blockMemberProfile(blockUnblockReq)
        .then((response) => {
          dispatch(blockUnblockMemberProfileSuccess(response));
        })
        .catch((err) => {
          //dispatch(blockUnblockMemberProfileFail(err));
        });
    }
  };
};

export const cancelMembership = (cancelMemberReq) => {
  return (dispatch) => {
    dispatch(cancelMembershipStart());
    HttpService.cancelMembership(cancelMemberReq)
      .then((response) => {
        dispatch(cancelMembershipSuccess(response));
      })
      .catch((err) => {
        //dispatch(cancelMembershipFail(err));
      });
  };
};

export const reactivateMember = (reactivateMemberReq) => {
  return (dispatch) => {
    dispatch(reactivateMemberStart());
    HttpService.reactivateMember(reactivateMemberReq)
      .then((response) => {
        dispatch(reactivateMemberSuccess(response));
      })
      .catch((err) => {
        //dispatch(reactivateMemberFail(err));
      });
  };
};

export const changeTemporaryPassword = (changeTemporaryPasswordReq) => {
  return (dispatch) => {
    dispatch(changeTemporaryPasswordStart());
    HttpService.changeTemporaryPassword(changeTemporaryPasswordReq)
      .then((response) => {
        dispatch(changeTemporaryPasswordSuccess(response));
      })
      .catch((err) => {
        //dispatch(changeTemporaryPasswordFail(err));
      });
  };
};

export const resetPassword = (resetPasswordReq) => {
  return (dispatch) => {
    dispatch(resetPasswordStart());
    HttpService.resetPassword(resetPasswordReq)
      .then((response) => {
        dispatch(resetPasswordSuccess(response));
      })
      .catch((err) => {
        //dispatch(resetPasswordFail(err));
      });
  };
};

export const verifyMemberAccount = (verifyMemberAccountReq) => {
  return (dispatch) => {
    dispatch(verifyMemberAccountStart());
    HttpService.verifyMemberAccount(verifyMemberAccountReq)
      .then((response) => {
        dispatch(verifyMemberAccountSuccess(response));
      })
      .catch((err) => {
        //dispatch(verifyMemberAccountFail(err));
      });
  };
};

export const addViolationPoint = (addViolationPointReq) => {
  return (dispatch) => {
    dispatch(addViolationPointStart());
    HttpService.addViolationPoint(addViolationPointReq)
      .then((response) => {
        dispatch(addViolationPointSuccess(response));
      })
      .catch((err) => {
        //dispatch(addViolationPointFail(err));
      });
  };
};
export const allowTemporaryLicenseImageUpload = (
  allowTemporaryLicenseImageUploadReq
) => {
  return (dispatch) => {
    dispatch(allowTemporaryLicenseImageUploadStart());
    HttpService.allowTemporaryLicenseImageUpload(
      allowTemporaryLicenseImageUploadReq
    )
      .then((response) => {
        dispatch(allowTemporaryLicenseImageUploadSuccess(response));
      })
      .catch((err) => {
        //dispatch(allowTemporaryLicenseImageUploadFail(err));
      });
  };
};

export const searchMemberMessage = (searchMemReq) => {
  return (dispatch) => {
    dispatch(searchMemberMessageStart());
    HttpService.getMessagesList(searchMemReq)
      .then((response) => {
        dispatch(searchMemberMessageSuccess(response));
      })
      .catch((err) => {
        //dispatch(searchMemberFeedbackFail(err));
      });
  };
};

export const searchMemberFavoriteAsset = (searchMemReq) => {
  return (dispatch) => {
    dispatch(searchMemberFavoriteAssetStart());
    HttpService.getFavouriteAssetList(searchMemReq)
      .then((response) => {
        dispatch(searchMemberFavoriteAssetSuccess(response));
      })
      .catch((err) => {
        //dispatch(searchMemberFeedbackFail(err));
      });
  };
};

export const searchMemberVehicle = (searchMemReq) => {
  return (dispatch) => {
    dispatch(searchMemberVehicleStart());
    HttpService.getMemberVehicleList(searchMemReq)
      .then((response) => {
        dispatch(searchMemberVehicleSuccess(response));
      })
      .catch((err) => {
        //dispatch(searchMemberFeedbackFail(err));
      });
  };
};

export const searchCreditHistory = (searchObj) => {
  return (dispatch) => {
    dispatch(searchCreditHistoryStart());
    HttpService.doCreditHistorySearch(searchObj)
      .then((response) => {
        dispatch(searchCreditHistorySuccess(response));
      })
      .catch((err) => {
        dispatch(searchCreditHistoryFail(err));
      });
  };
};

export const addFreeCredit = (addFreeCreditObj) => {
  return (dispatch) => {
    dispatch(addFreeCreditStart());
    HttpService.addFreeCredittoMemberAccount(addFreeCreditObj)
      .then((response) => {
        dispatch(addFreeCreditSuccess(response));
      })
      .catch((err) => {
        //dispatch(addFreeCreditFail(err));
      });
  };
};

export const reverseCredit = (reverseCreditObj) => {
  return (dispatch) => {
    dispatch(reverseCreditStart());
    HttpService.reverseFreeCreditFromMemberAccount(reverseCreditObj)
      .then((response) => {
        dispatch(reverseCreditSuccess(response));
      })
      .catch((err) => {
        //dispatch(reverseCreditFail(err));
      });
  };
};

import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  sortByString,
  getBasicToastResponse,
} from "../../../../shared/utility";
import Spinner from "../../../../components/UI/Spinner/Spinner";
import { Roles_Permissions } from "../../../../shared/GlobalVar";
import PermissionsGrid from "../../../../components/permission/permission-grid-component";
import {
  getPermissionstoRolesReset,
  getPermissionstoRoles,
  updatePermissionstoRoles,
  updatePermissionstoRolesReset,
} from "../../../../store/actions";
import { showNotification } from "../../../../components/UI/Toaster/Toaster";

const PermissionstoRolesModal = (props) => {
  const stateloading = useSelector((state) => state.rolesReducer.loading);
  const isCategoryRequired = true;
  const [rolesPermisisonsInfo, setRolesPermisisonsInfo] = useState(null);
  const dispatch = useDispatch();
  const permissionsRolesDataReponse = useSelector(
    (state) => state.rolesReducer.permissionsRolesData
  );
  const permissionsRolesUpdateResponse = useSelector(
    (state) => state.rolesReducer.permissionsRolesUpdateResponse
  );

  useEffect(() => {
    dispatch(getPermissionstoRolesReset());
    dispatch(getPermissionstoRoles({ RoleIds: props.roleIds || [] }));
  }, []);

  useEffect(() => {
    if (!permissionsRolesDataReponse) {
      return;
    }
    setRolesPermisisonsInfo(
      doCbGridCompatibleDataForPermissionsToRoles(
        permissionsRolesDataReponse,
        isCategoryRequired
      )
    );
  }, [permissionsRolesDataReponse]);

  useEffect(() => {
    if (!permissionsRolesUpdateResponse) {
      return;
    }
    showNotification(getBasicToastResponse(permissionsRolesUpdateResponse));
    if (permissionsRolesUpdateResponse.StatusCode === 0) {
      props.callBack();
    }
    props.closeModal();
    dispatch(getPermissionstoRolesReset());
  }, [permissionsRolesUpdateResponse]);

  const doCbGridCompatibleDataForPermissionsToRoles = (
    permissionsData,
    cateGoryRequired
  ) => {
    let rolesPermissionsGridData = {
      rowData: {},
      columnData: {},
      totalRows: permissionsData.AllPermissions
        ? permissionsData.AllPermissions.length
        : 0,
      totalColumns: permissionsData.RolePermissions
        ? permissionsData.RolePermissions.length
        : 0,
    };
    if (permissionsData) {
      permissionsData.AllPermissions.map((permissionData, index) => {
        var data = {};
        data.rowId = permissionData.PermissionId;
        data.rowName = permissionData.DisplayText;
        data.columnInfo = {};
        data.type = cateGoryRequired
          ? permissionData.PermissionType || "Other"
          : "All";
        data.prerequisitePermissions = getCompatibleDataForRowList(
          permissionData.PrerequisitePermissions,
          permissionsData.AllPermissions
        );
        data.allPrerequisitePermissions = getCompatibleDataForRowList(
          permissionData.AllPrerequisitePermissions,
          permissionsData.AllPermissions
        );
        data.dependantPermissions = getCompatibleDataForRowList(
          permissionData.DependantPermissions,
          permissionsData.AllPermissions
        );

        if (data.type && !rolesPermissionsGridData.rowData[data.type]) {
          rolesPermissionsGridData.rowData[data.type] = {};
          rolesPermissionsGridData.rowData[data.type][data.rowId] = data;
        } else {
          rolesPermissionsGridData.rowData[data.type][data.rowId] = data;
        }
      });
    }
    var sortedcolumnsList = sortByString(
      permissionsData.RolePermissions,
      "RoleName"
    );
    sortedcolumnsList.map((roleData, index1) => {
      var data = {};
      data.columnId = roleData.RoleId;
      data.columnName = roleData.RoleName;
      data.rowInfo = {};
      if (roleData) {
        if (roleData.GrantedPermissions) {
          roleData.GrantedPermissions.map((permissionId, index2) => {
            var dataPer = {};
            dataPer.inherited = null;
            dataPer.granted = true;
            Object.keys(rolesPermissionsGridData.rowData).map(
              (type, index3) => {
                if (rolesPermissionsGridData.rowData[type][permissionId]) {
                  data.rowInfo[permissionId] = dataPer;
                  if (
                    !Object.keys(
                      rolesPermissionsGridData.rowData[type][permissionId][
                        "columnInfo"
                      ]
                    ).length
                  ) {
                    rolesPermissionsGridData.rowData[type][permissionId][
                      "columnInfo"
                    ][data.columnId] = {};
                    rolesPermissionsGridData.rowData[type][permissionId][
                      "columnInfo"
                    ][data.columnId] = dataPer;
                  } else {
                    rolesPermissionsGridData.rowData[type][permissionId][
                      "columnInfo"
                    ][data.columnId] = dataPer;
                  }
                }
              }
            );
          });
        }
      }
      rolesPermissionsGridData.columnData[roleData.RoleId] = data;
    });
    return rolesPermissionsGridData;
  };
  const getCompatibleDataForRowList = (rowList, allPermissions) => {
    if (rowList && rowList.length > 0) {
      var data = {};
      rowList.map((permissionId, index1) => {
        allPermissions.map((permissionObj, index2) => {
          if (permissionId === permissionObj.PermissionId) {
            data[permissionId] = {
              rowId: permissionId,
              rowName: permissionObj.DisplayText,
              type: permissionObj.PermissionType,
            };
            return false;
          }
        });
      });
      return data;
    }
  };
  const getModalSettings = () => {
    const totalColumns = rolesPermisisonsInfo.totalColumns;
    const totalRows = rolesPermisisonsInfo.totalRows;
    let tableWidth = isCategoryRequired
      ? totalColumns * 60 + 300
      : totalColumns * 60 + 200;
    let modalBodyHeight, modalWidth;

    if (totalColumns < 4) {
      modalWidth = isCategoryRequired ? 540 : 425;
    } else {
      if (tableWidth > 1060) {
        modalWidth = 1060;
      } else {
        modalWidth = isCategoryRequired ? tableWidth + 100 : tableWidth + 60;
      }
    }
    if (totalRows < 5) {
      modalBodyHeight = 260;
    } else {
      if (totalRows * 30 + 100 > 648) {
        modalBodyHeight = 648;
      } else {
        modalBodyHeight = totalRows * 30 + 100;
      }
    }
    let modalSettings = {
      modalWidth: modalWidth,
      modalBodyHeight: modalBodyHeight,
    };
    return modalSettings;
  };
  const upDateRolesPermissionsData = (rolesPermisisonsData) => {
    let permissionstoRole = {};
    permissionstoRole.AssignRolePermissions = [];
    Object.keys(rolesPermisisonsData.columnData).map((columnkey, index1) => {
      let granted = [];
      let columnData = rolesPermisisonsData.columnData[columnkey];
      let permissionObj = {};
      permissionObj.RoleId = columnkey;
      Object.keys(columnData.rowInfo).map((rowKey, index2) => {
        let rowData = columnData.rowInfo[rowKey];
        if (rowData.granted) {
          granted.push(rowKey);
        }
      });
      permissionObj.GrantedPermissions = granted;
      permissionstoRole.AssignRolePermissions.push(permissionObj);
    });
    dispatch(updatePermissionstoRolesReset());
    dispatch(updatePermissionstoRoles(permissionstoRole));
  };
  let rolesPermissionsctrl = <Spinner />;
  if (!stateloading) {
    if (
      permissionsRolesDataReponse &&
      rolesPermisisonsInfo &&
      permissionsRolesDataReponse.StatusCode === 0
    ) {
      let modalSettings = getModalSettings();
      rolesPermissionsctrl = (
        <Fragment>
          <PermissionsGrid
            isModalOpen={props.isModalOpen}
            closeModal={props.closeModal}
            modalSettings={modalSettings}
            permissionsType={Roles_Permissions.PermissionsToRoles}
            isDualState={true}
            isCategoryRequired={isCategoryRequired}
            rolesPermisisonsData={rolesPermisisonsInfo}
            upDateRolesPermissionsData={upDateRolesPermissionsData}
          />
        </Fragment>
      );
    }
  }
  return rolesPermissionsctrl;
};
export default PermissionstoRolesModal;

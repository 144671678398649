const BillingSearchModel = function (data) {
  if (!data) data = {};

  this.AccountNumber = data.AccountNumber || null;
  this.ActualStartDate = data.ActualStartDate || null;
  this.ActualEndDate = data.ActualEndDate || null;
  this.Amount = data.Amount || 0;
  this.AuditComment = data.AuditComment || null;
  this.BorrowerId = data.BorrowerId || null;
  this.BalanceDue = data.BalanceDue || null;
  this.BillingEmail = data.BillingEmail || null;
  this.BillingName = data.BillingName || null;
  this.BorrowerBadgeNo = data.BorrowerBadgeNo || null;
  this.BorrowerEmployeeNo = data.BorrowerEmployeeNo || null;

  this.BorrowerOrgUnitName = data.BorrowerOrgUnitName || null;

  this.CVVCode = data.CVVCode || null;
  this.CategoryName = data.CategoryName || null;
  this.Comments = data.Comments || null;
  this.CreatedById = data.CreatedById || null;
  this.CreatedByName = data.CreatedByName || null;
  this.CreatedOn = data.CreatedOn || null;
  this.CreditCardAddress = data.CreditCardAddress || null;

  this.CreditCardType = data.CreditCardType || null;
  this.ExpirationMonth = data.ExpirationMonth || null;
  this.ExpirationYear = data.ExpirationYear || null;
  this.Frequency = data.Frequency || null;
  this.IntegRefTransId = data.IntegRefTransId || null;
  this.IsReservationRequest = data.IsReservationRequest || null;
  this.InvoiceId = data.InvoiceId || null;

  this.InvoiceReference = data.InvoiceReference || null;
  this.Make = data.Make || null;
  this.Model = data.Model || null;
  this.NextPaymentId = data.NextPaymentId || null;
  this.ParticipantType = data.ParticipantType || null;
  this.PaymentDate = data.PaymentDate || null;
  this.PaymentProcessor = data.PaymentProcessor || null;
  this.PaymentRole = data.PaymentRole || null;

  this.PaymentState = data.PaymentState || null;
  this.PaymentStateDisplay = data.PaymentStateDisplay || null;
  this.TransactionTypeDisplay = data.TransactionTypeDisplay || null;
  this.PaymentProcessorDisplay = data.PaymentProcessorDisplay || null;
  this.PaymentType = data.PaymentType || null;
  this.PaymentTypeDisplay = data.PaymentTypeDisplay || null;
  this.TransactionType = data.TransactionType || null;
  this.PersonAssetId = data.PersonAssetId || null;

  this.PersonAssetName = data.PersonAssetName || null;
  this.PersonAssetOwnerId = data.PersonAssetOwnerId || null;
  this.PersonAssetOwnerName = data.PersonAssetOwnerName || null;
  this.PersonAssetUnitNumber = data.PersonAssetUnitNumber || null;
  this.PersonId = data.PersonId || null;
  this.PersonName = data.PersonName || null;
  this.TotalPreauthAmount = data.TotalPreauthAmount || 0;
  this.PreauthReleaseAmount = data.PreauthReleaseAmount || null;

  this.PreviousPaymentId = data.PreviousPaymentId || null;
  this.Processed = data.Processed || null;
  this.PromisedStartTime = data.PromisedStartTime || null;
  this.ReservationCode = data.ReservationCode || null;
  this.ReservationId = data.ReservationId || null;
  this.ReservationState = data.ReservationState || null;
  this.ReservationTotalPaid = data.ReservationTotalPaid || 0;
  this.TransactionId = data.TransactionId || null;

  this.TotalFreeCredits = data.TotalFreeCredits || 0;
  this.UpgradeCategoryId = data.UpgradeCategoryId || null;
  this.UpgradeCategoryName = data.UpgradeCategoryName || null;
  this.TotalCapturedAmount = data.TotalCapturedAmount || 0;
  this.TotalRefundAmount = data.TotalRefundAmount || 0;
  this.ShowRefund = data.ShowRefund || null;
  this.ShowCapture = data.ShowCapture || null;
  this.ShowRelease = data.ShowRelease || null;

  this.Year = data.Year || null;
  this.IsLateReservation = data.IsLateReservation || null;
  this.IntegRecordedBy = data.IntegRecordedBy || null;
  this.IntegRecordedByName = data.IntegRecordedByName || null;
  this.ReservationCreationType = data.ReservationCreationType || null;

  this.PromisedStartDate = data.PromisedStartDate || null;

  this.RefundReason = data.RefundReason || null;
  this.CancellationNotes = data.CancellationNotes ? data.CancellationNotes.replace(/\r\n|\n/, "") : null;
  this.CancellationReason = data.CancellationReason || null;
  this.CancellationReasonDisplay = data.CancellationReasonDisplay || null;
  this.CancellationReasonHint = data.CancellationReasonHint || null;
  this.CancelledByPersonId = data.CancelledByPersonId || null;
  this.CancelledByPersonName = data.CancelledByPersonName || null;
  this.CancelledOn = data.CancelledOn || null;

  this.BorrowerEmployeeNo = data.BorrowerEmployeeNo || null;
  this.BorrowerBadgeNo = data.BorrowerBadgeNo || null;
  this.BorrowerLocationName = data.BorrowerLocationName || null;
  this.TimezoneString = data.TimezoneString || null;
};
export default BillingSearchModel;

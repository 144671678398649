import React, { Fragment } from "react";
const MemberPreference = (props) => {
  const sectionClasses = "col-sm-4";
  const getPreferenceHtmlCtrl = (preferenceObject) => {
    return Object.keys(preferenceObject).map((dataKey, index) => {
      const preferenceObj = preferenceObject[dataKey];
      return (
        <div className="form-group row" key={dataKey + dataKey.name + index}>
          <label className={`${sectionClasses} font-weight-bold`}>
            {preferenceObj.labeltext}
          </label>
          <div className="col-sm-8">
            <div className="memberInput">
              <div className="icheck-primary">
                <input
                  type="checkbox"
                  id={dataKey + preferenceObj.name + "Phone"}
                  checked={preferenceObj.phoneSelected ? true : false}
                  //value={true}
                  //   onChange={event =>
                  //     props.inputMemberChangedHandler(
                  //       event,
                  //       "AcknowledDriveManualEnabled",
                  //       "radio"
                  //     )
                  //   }
                  disabled={true}
                />
                <label htmlFor={dataKey + preferenceObj.name + "Phone"}>
                  <span>Phone</span>
                </label>
              </div>
              <div className="icheck-primary">
                <input
                  type="checkbox"
                  id={dataKey + preferenceObj.name + "Email"}
                  checked={preferenceObj.emailSelected ? true : false}
                  disabled={true}
                  //value={false}
                  //   onChange={event =>
                  //     props.inputMemberChangedHandler(
                  //       event,
                  //       "AcknowledDriveManualEnabled",
                  //       "radio"
                  //     )
                  //   }
                />
                <label htmlFor={dataKey + preferenceObj.name + "Email"}>
                  <span>Email</span>
                </label>
              </div>
              <div className="icheck-primary">
                <input
                  type="checkbox"
                  id={dataKey + preferenceObj.name + "TxtMsg"}
                  checked={preferenceObj.smsSelected ? true : false}
                  disabled={true}
                  //   onChange={event =>
                  //     props.inputMemberChangedHandler(
                  //       event,
                  //       "AcknowledDriveManualEnabled",
                  //       "radio"
                  //     )
                  //   }
                />
                <label htmlFor={dataKey + preferenceObj.name + "TxtMsg"}>
                  <span>Text Message</span>
                </label>
              </div>
            </div>
          </div>
        </div>
      );
    });
  };
  return (
    <Fragment>
      <div className={sectionClasses}>
        <h4 className="form-header text-uppercase">
          <i className="fas fa-user-tie"></i>
          General
        </h4>
        {getPreferenceHtmlCtrl(props.generalPreferace)}
      </div>
      <div className={sectionClasses}>
        <h4 className="form-header text-uppercase">
          <i className="fas fa-chalkboard-teacher"></i>
          Owner - Change by Others
        </h4>
        {getPreferenceHtmlCtrl(props.ownerPreference)}
      </div>
      <div className={sectionClasses}>
        <h4 className="form-header text-uppercase">
          <i className="fas fa-user-tie"></i>
          Renter - Change by Others
        </h4>
        {getPreferenceHtmlCtrl(props.renterPreference)}
      </div>
    </Fragment>
  );
};
export default MemberPreference;

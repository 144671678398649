import React from 'react';
import { getDateTimeFormatString } from '../../../components/common/common-helper';
import { formatCurrency } from '../../../shared/utility';
import { convertFoxApiDateToStr } from '../fox-reservation/fox-res-helper';
import DiscountBadge from './discount-badge';

function DiscountDetails(props) {
    const { selectedDiscountData, dependantOrgList, resevationData, getDisplayValueForReason } = props;
    const getLocNameFromId = (locId) => {
        const locObj = dependantOrgList.find((data) => data.locationId === locId);
        return locObj?.displayName || "--";
    }
    return (
        <div className='flex-column flex-size-4 row-gap-1 overflow-auto' style={{ height: "430px" }}>
            <h3 className='p-0'>Reservation# <label className='color-primary'>{selectedDiscountData?.reservationCode || resevationData?.foxReservationCode || "--"}</label> </h3>
            <hr className='p-0 m-0' />
            <div className="flex-row gap-1">
                <label className='width-150 font-bold'>Pickup Location</label>
                <label>{selectedDiscountData?.reservationPickupLocationId
                    ? getLocNameFromId(selectedDiscountData?.reservationPickupLocationId)
                    : resevationData?.rentalLocation?.name || "--"}
                </label>
            </div>
            <div className="flex-row gap-1">
                <label className='width-150 font-bold'>Return Location</label>
                <label>{selectedDiscountData?.reservationReturnLocationId
                    ? getLocNameFromId(selectedDiscountData?.reservationReturnLocationId)
                    : resevationData?.returnLocation?.name || "--"}
                </label>
            </div>
            <div className="flex-row gap-1">
                <label className='width-150 font-bold'>Reservation Start Date</label>
                <label>{convertFoxApiDateToStr(
                    selectedDiscountData?.reservationStartDate || resevationData?.rentalDateTime,
                    getDateTimeFormatString(true, false, true),
                    "--"
                )}</label>
            </div>
            <div className="flex-row gap-1">
                <label className='width-150 font-bold'>Reservation End Date</label>
                <label>{convertFoxApiDateToStr(
                    selectedDiscountData?.reservationEndDate || resevationData?.returnDateTime,
                    getDateTimeFormatString(true, false, true),
                    "--"
                )}</label>
            </div>
            {selectedDiscountData?.personFoxDiscountId ?
                <>
                    <h3 className='p-0'>Discount#{" "}
                        <label className='color-primary'>{selectedDiscountData?.personFoxDiscountId}
                            {" "}<DiscountBadge status={selectedDiscountData?.status} /></label>
                    </h3>
                    <hr className='p-0 m-0' />
                    <div className="flex-row gap-1">
                        <label className='width-150 font-bold'>Discount Code</label>
                        <label>{selectedDiscountData?.foxDiscountCode || "--"}</label>
                    </div>
                    <div className="flex-row gap-1">
                        <label className='width-150 font-bold'>Status</label>
                        <label>{selectedDiscountData?.status || "--"}</label>
                    </div>
                    {selectedDiscountData?.status === "USED" ?
                        <>
                            <div className="flex-row gap-1">
                                <label className='width-150 font-bold'>Used Res. Code</label>
                                <label className='font-color-used'>{selectedDiscountData?.usedFoxReservationCode || "--"}</label>
                            </div>
                            <div className="flex-row gap-1">
                                <label className='width-150 font-bold'>Used Discount Amount</label>
                                <label className='font-color-reverted'>{formatCurrency(selectedDiscountData?.usedRateDiscountAmount * (-1)) || "--"}</label>
                            </div>
                        </>
                        : ""}
                    <div className="flex-row gap-1">
                        <label className='width-150 font-bold'>Granted By</label>
                        <label>{selectedDiscountData?.grantedPersonName || "--"}</label>
                    </div>
                    <div className="flex-row gap-1">
                        <label className='width-150 font-bold'>Granted Reason</label>
                        <label>{getDisplayValueForReason("discountReasons", selectedDiscountData?.grantedReason) || "--"}</label>
                    </div>
                    <div className="flex-row gap-1">
                        <label className='width-150 font-bold'>Granted Sub Reason</label>
                        <label>{getDisplayValueForReason("discountSubReasons", selectedDiscountData?.grantedSubReason) || "--"}</label>
                    </div>
                    <div className="flex-row gap-1">
                        <label className='width-150 font-bold'>Granted Notes</label>
                        <label>{selectedDiscountData?.grantedNotes || "--"}</label>
                    </div>
                    <div className="flex-row gap-1">
                        <label className='width-150 font-bold'>Granted On</label>
                        <label>{convertFoxApiDateToStr(
                            selectedDiscountData?.grantedOn,
                            getDateTimeFormatString(true, false, true),
                            "--"
                        )}</label>
                    </div>
                    <div className="flex-row gap-1">
                        <label className='width-150 font-bold'>Expires On</label>
                        <label>{convertFoxApiDateToStr(
                            selectedDiscountData?.expiresOn,
                            getDateTimeFormatString(true, false, true),
                            "--"
                        )}</label>
                    </div>
                    {selectedDiscountData?.status === "REVERTED" ?
                        <>
                            <div className="flex-row gap-1">
                                <label className='width-150 font-bold'>Revert By</label>
                                <label>{selectedDiscountData?.revertedPersonName || "--"}</label>
                            </div>

                            <div className="flex-row gap-1">
                                <label className='width-150 font-bold'>Revert On</label>
                                <label>{convertFoxApiDateToStr(
                                    selectedDiscountData?.revertedOn,
                                    getDateTimeFormatString(true, false, true),
                                    "--"
                                )}</label>
                            </div>
                            <div className="flex-row gap-1">
                                <label className='width-150 font-bold'>Revert Reason</label>
                                <label>{getDisplayValueForReason("revertDiscountReasons", selectedDiscountData?.revertedReason) || "--"}</label>
                            </div>
                            <div className="flex-row gap-1">
                                <label className='width-150 font-bold'>Revert Notes</label>
                                <label>{selectedDiscountData?.revertedNotes || "--"}</label>
                            </div> </> : ""}
                </> : ""}
        </div>
    )
}

export default DiscountDetails
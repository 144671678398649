import PageInfo from "../models/PageInfo";

const VehicleSearchRequest = function() {
  this.LocalSearchOnly = true;
  this.Make = null;
  this.Model = null;
  this.VehicleYear = null;
  this.Vin = null;
  this.ClassCode = null;
  this.PersonAssetAvailabilityStatus = null;
  this.UnitNumber = null;
  this.SelectedChildOrgUnitId = null;
  this.OnlyShownInJsiMarketPlace = null;
  this.RideLinkInstalled = null;
  this.TransactionTypes = null;
  this.PageNumber = null;
  this.PerPageCount = null;
  this.SortBy = null;
  this.SortType = null;
  this.MemberId = null;
  this.ConfirmationKey = null;
  this.PersonName = null;
  this.AssetTypes = null;
  this.QRCode = null;
  this.AddressId = null;
  this.Location = null;
  this.Distance = null;

  this.FuelType = null;
  this.StartDate = null;
  this.EndDate = null;
  this.ReservationDuration = null;
  this.TransmissionType = null;
  this.AssetParkedTextType = null;
  this.PersonAssetAvailabilityStatus = null;
  this.ScheduleFlexibilityEnabled = null;
  this.DistanceFlexibilityEnabled = null;
  this.MinHourlyRate = null;
  this.MaxHourlyRate = null;
  this.MinHalfDayRate = null;
  this.MaxHalfDayRate = null;
  this.MinDailyRate = null;
  this.MaxDailyRate = null;
  this.OwnerLastName = null;
  this.OwnerFirstName = null;
  this.PersonAssetName = null;
  this.Email = null;
  this.PhoneNo = null;
  this.VehicleStatusFilterEnabled = null;
  this.VehicleStatus = null;
  this.LocationId = null;
  this.CityAndState = null;

  this.ZipCode = null;
  this.ShowFavoriteAssetsOnly = null;
  this.IncludeSuspended = null;
  this.ShowNotBookableOnly = null;
  this.ShowOffline = null;
  this.ReservationId = null;
  this.Longitude = null;
  this.Latitude = null;
  this.UserId = null;
  this.PageInfo = new PageInfo();
};
export default VehicleSearchRequest;

import * as actionTypes from "../actions/actionTypes";
import { reducerUpdateObject } from "./reducer.utility";

const initialState = {
  invoiceInformationResponse: null,
  error: null,
  loading: false
};
const getInvoiceInformationStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};

const getInvoiceInformationSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    invoiceInformationResponse: action.invoiceInformationResponse,
    error: null,
    loading: false
  });
};
const getInvoiceInformationFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false
  });
};
const getInvoiceInformationReset = (state, action) => {
  return reducerUpdateObject(state, { invoiceInformationResponse: null });
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_INVOICE_INFORMATION_RESET:
      return getInvoiceInformationReset(state, action);
    case actionTypes.GET_INVOICE_INFORMATION_START:
      return getInvoiceInformationStart(state, action);
    case actionTypes.GET_INVOICE_INFORMATION_SUCCESS:
      return getInvoiceInformationSuccess(state, action);
    case actionTypes.GET_INVOICE_INFORMATION_FAIL:
      return getInvoiceInformationFail(state, action);
    default:
      return state;
  }
};

export default reducer;

const MyVehicleSearchModel = function (data) {
  if (!data) data = {};
  this.AssetAge = data.AssetAge || null;
  this.AssetType = data.AssetType || null;
  this.Color = data.Color || null;
  this.CurrentFuelStatusInPercentage =
    data.CurrentFuelStatusInPercentage || null;
  this.CurrentLatitude = data.CurrentLatitude || null;
  this.CurrentLongitude = data.CurrentLongitude || null;
  this.DeviceInstalled = data.DeviceInstalled || false;
  this.DriveType = data.DriveType || null;
  this.EngineCC = data.EngineCC || null;

  this.ExecutionMode = data.ExecutionMode || null;

  this.FuelConsumptionUnit = data.FuelConsumptionUnit || null;

  //LICENSE INFORMATION START HERE
  this.FuelIncluded = data.FuelIncluded || null;
  this.FuelType = data.FuelType || null;
  this.Image = data.Image || null;
  this.InstantRentalEnabled = data.InstantRentalEnabled || false;
  this.IsInReservation = data.IsInReservation || false;
  this.LastPositionUpdateTime = data.LastPositionUpdateTime || null;
  this.LastReservationId = data.LastReservationId || null;
  this.Latitude = data.Latitude || null;
  this.LicenseNo = data.LicenseNo || null;
  this.ListingsEnabled = data.ListingsEnabled || false;

  this.LockStatus = data.LockStatus || null;
  this.Longitude = data.Longitude || null;
  this.Make = data.Make || null;
  this.MaxFuelCapacity = data.MaxFuelCapacity || null;
  this.MileageAtSignup = data.MileageAtSignup || null;
  this.MilesPerGallon = data.MilesPerGallon || null;
  this.Model = data.Model || null;

  this.MovementStatus = data.MovementStatus || null;
  this.Name = data.Name || null;
  this.NextReservationId = data.NextReservationId || null;
  this.OverallRating = data.OverallRating || null;

  this.OwnerFullName = data.OwnerFullName || null;
  this.OwnerId = data.OwnerId || null;
  this.OwnerNotes = data.OwnerNotes || null;

  this.ParkedText = data.ParkedText || null;
  this.PersonAssetId = data.PersonAssetId || null;
  this.QRCode = data.QRCode || null;
  this.RatingCount = data.RatingCount || null;
  this.ReservationStatus = data.ReservationStatus || null;
  this.SeatingCapacity = data.SeatingCapacity || null;
  this.Status = data.Status || null;

  this.TotalEarningsCurrentMonth = data.TotalEarningsCurrentMonth || null;
  this.TotalEarningsLastMonth = data.TotalEarningsLastMonth || null;
  this.TotalEarningsLastYear = data.TotalEarningsLastYear || null;
  this.TotalEarningsToDate = data.TotalEarningsToDate || null;
  this.TotalFine = data.TotalFine || 0;
  this.TotalFuelLeft = data.TotalFuelLeft || null;
  this.TotalMileage = data.TotalMileage || null;

  this.TotalPendingRequest = data.TotalPendingRequest || 0;
  this.TotalPendingReservation = data.TotalPendingReservation || 0;
  this.TotalRentedHours = data.TotalRentedHours || null;
  this.TransmissionMode = data.TransmissionMode || null;
  this.Trim = data.Trim || null;
  this.Year = data.Year || null;
};
export default MyVehicleSearchModel;

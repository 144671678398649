import PageInfo from "../models/PageInfo";
const SearchReadyLineAgentRequest = function() {
  this.FromDate = null;
  this.ToDate = null;
  this.TrackerId = null;
  this.Status = null;
  this.AgentBadgeNo = null;
  this.ProfileLocationId = null;
  this.TrackerType = null;
  this.TrackerSubType = null;
  this.AgentName = null;
  this.Vin = null;
  this.VehicleId = null;
  this.FuelRequired = null;
  this.IndividuallyPerformed = null;
  this.TiresCondition = null;
  this.VinManualEntry = null;
  this.Grade = null;
  this.AssignedBy = null;
  this.AssignedTo = null;
  this.Ascending = null;
  this.CleaningType = null;
  this.Complaints = [];
  this.Causes = [];
  this.Corrections = [];
  this.TiresAndParts = [];
  this.PageInfo = new PageInfo();
};
export default SearchReadyLineAgentRequest;

import React from "react";
import DefaultComponentWithMessage from "../../../components/common/pages/default-component-with-message";
import PageTopPanel from "../../../components/common/pages/page-top-panel";
import {
  getTopMainLinkUrl,
  getPageName,
  getChildBreadCrumbList,
  getMemberProfileBreadcrumbList,
  getActualPageName,
} from "../../search/search-utility/search-utility";
import { COMMON_MSG } from "../../../shared/GlobalVar";

const MemberPackages = (props) => {
  const query = new URLSearchParams(props.location.search);
  const memberId = query.get("memberId");
  const pageName = getPageName(props.match.url);
  let breadCrumbList;
  if (memberId) {
    breadCrumbList = getMemberProfileBreadcrumbList(props.otherData, memberId);
  }

  return (
    <div>
      <PageTopPanel
        breadCrumbList={breadCrumbList}
        title="Packages"
        linkList={getTopMainLinkUrl(pageName)}
        memberId={memberId}
        showExportLink={false}
        pageName={pageName}
        linkBreadCrumb={getChildBreadCrumbList(
          props.match.url,
          props.location,
          breadCrumbList
        )}
      />
      <DefaultComponentWithMessage
        msg={
          getActualPageName(pageName) +
          " " +
          COMMON_MSG.UnderConstructionMessage
        }
      />
    </div>
  );
};

export default MemberPackages;

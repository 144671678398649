import {
  getShuttleCombineStatusLabel,
  getShuttleInfoColumn,
  ShuttleAvailability,
  ShuttleConnectionStatus,
  ShuttleEngineStatus,
  getShutlleIconColor,
  getAmenitiesDisplayValue,
} from "../../../../shared/utility/enum-utility";
import { OrgUnit } from "../../models/domain/org-unit";
import { EngineStatus } from "../domain/engine-status";

export const ShuttleViewModel = function (data) {
  if (!data) data = {};
  this.shuttleId = data.shuttleId || null;
  this.shuttleCode = data.shuttleCode || null;
  this.name = data.name || null;
  this.shortName = data.shortName || null;
  this.vin = data.vin || null;
  this.make = data.make || null;
  this.model = data.model || null;
  this.year = data.year || null;
  this.location = new OrgUnit(data.shuttleOrgUnit);
  this.availability = data.availability || null;
  this.availabilityDisplayValue = data.availability
    ? ShuttleAvailability[data.availability]
    : null;
  this.lastEngineStatus = new EngineStatus(data.lastStatus);
  this.lastEngineStatusDisplayValue = this.lastEngineStatus.engineStatus
    ? ShuttleEngineStatus[this.lastEngineStatus.engineStatus]
    : null;
  this.shuttleInfo = data.shuttleCode
    ? getShuttleInfoColumn(data.make, data.model, data.year)
    : null;
  this.shuttleConnectionStatus = data.shuttleConnectionStatus || null;
  this.shuttleCommonStatusDisplayValue = data.shuttleConnectionStatus
    ? ShuttleConnectionStatus[data.shuttleConnectionStatus]
    : null;
  this.combineStatusLabel = data.shuttleConnectionStatus
    ? getShuttleCombineStatusLabel(
        data.shuttleConnectionStatus,
        this.lastEngineStatus.engineStatus
      )
    : null;
  this.iconColor = data.shuttleConnectionStatus
    ? getShutlleIconColor(
        data.shuttleConnectionStatus,
        this.lastEngineStatus
      )
    : null;
  this.licensePlate = data.licensePlate || null;
  this.insuranceCarrier = data.insuranceCarrier || null;
  this.policyNumber = data.policyNumber || null;
  this.seatCapacity = data.seatCapacity || null;
  this.drivingType = data.drivingType || null;
  this.fuelType = data.fuelType || null;
  this.fuelConsumptionUnit = data.fuelConsumptionUnit || null;
  this.transmissionType = data.transmissionType || null;
  this.color = data.color || null;
  this.description = data.description || null;
  this.internalNotes = data.internalNotes || null;
  this.amenities = data.amenities || [];
  this.amenitiesDisplayValue =
    data.amenities && data.amenities.length > 0
      ? getAmenitiesDisplayValue(data.amenities)
      : null;
  this.latitude = data.latitude || null;
  this.longitude = data.longitude || null;
};

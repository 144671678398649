import React from "react";
import { connect } from "react-redux";
import PageTopPanel from "../../../components/common/pages/page-top-panel";
import ModalEngine from "../../../components/modal/modal-engine";
import LinkButton from "../../../components/UI/button/link-btn";
import Spinner from "../../../components/UI/Spinner/Spinner";
import { showNotification } from "../../../components/UI/Toaster/Toaster";
import ModalInfo from "../../../services/entities/models/ModalInfo";
import { ModalType, TOAST_MSG } from "../../../shared/GlobalVar";
import { getBasicToastResponse } from "../../../shared/utility";
import * as actions from "../../../store/actions/index";
import {
  getChildBreadCrumbList,
  getMemberProfileBreadcrumbList,
  getPageName,
  getTopMainLinkUrl,
} from "../../search/search-utility/search-utility";
import MemberPreference from "../member-preference/member-preference";
import {
  getGeneralPreference,
  getOwnerPreference,
  getRenterPreference,
  loadPreferences,
} from "../member-preference/member-preference-inputl";
import { createMemberReqForSummary } from "../MemberHelper";
class MemberSummary extends React.Component {
  state = {
    loading: false,
    memberId: null,
    memberDataResponse: null,
    generalPreferace: JSON.parse(JSON.stringify(getGeneralPreference())),
    ownerPreference: JSON.parse(JSON.stringify(getOwnerPreference())),
    renterPreference: JSON.parse(JSON.stringify(getRenterPreference())),
    showFreeCreditPopup: false,
    showReverseCreditPopup: false,
    showApplyOfferModal: false,
    modalInfo: [new ModalInfo()],
  };

  closeModal = () => {
    this.setState({
      modalInfo: [new ModalInfo()],
    });
  };

  componentDidMount() {
    const query = new URLSearchParams(this.props.location.search);
    const memberId = query.get("memberId");
    this.props.onGetMemberReset();
    this.setState(
      {
        memberId: memberId,
      },
      () => {
        this.resetMemberData();
      }
    );
  }
  UNSAFE_componentWillReceiveProps({ memberDataResponse }) {
    if (
      (memberDataResponse && !this.state.memberDataResponse) ||
      (this.state.memberDataResponse &&
        memberDataResponse &&
        !this.props.memberDataResponse)
    ) {
      if (memberDataResponse.StatusCode === 0) {
        this.initMemberSummaryPages(memberDataResponse);
      } else {
        showNotification(getBasicToastResponse(memberDataResponse));
        this.props.onGetMemberReset();
      }
    }
  }
  initMemberSummaryPages = (memberDataResponse) => {
    this.setState({
      memberDataResponse: memberDataResponse,
    });
    const memberData = { ...memberDataResponse.Member };
    let generalPreferace = { ...this.state.generalPreferace };
    let ownerPreference = { ...this.state.ownerPreference };
    let renterPreference = { ...this.state.renterPreference };
    if (memberData.GeneralPreference) {
      generalPreferace = loadPreferences(
        this.state.generalPreferace,
        memberData.GeneralPreference
      );
    }
    if (memberData.SharerPreference) {
      ownerPreference = loadPreferences(
        this.state.ownerPreference,
        memberData.SharerPreference
      );
    }
    if (memberData.BorrowerPreference) {
      renterPreference = loadPreferences(
        this.state.renterPreference,
        memberData.BorrowerPreference
      );
    }
    this.setState({
      generalPreferace: generalPreferace,
      ownerPreference: ownerPreference,
      renterPreference: renterPreference,
    });
  };
  resetMemberData = () => {
    this.props.onGetMemberReset();
    const getMemberReq = createMemberReqForSummary(this.state.memberId);
    this.props.onGetMember(getMemberReq);
  };
  callBackToDisplayServerMessage = (response) => {
    var toastMsg = {
      msg: response.StatusMsg,
      toastType:
        response.StatusCode === 0 ? TOAST_MSG.SUCCESS : TOAST_MSG.ERROR,
    };
    showNotification(toastMsg);
    if (response.StatusCode === 0) {
      let memberDataResponse = { ...this.state.memberDataResponse };
      memberDataResponse.Member.Courtesy = response.TotalCourtesyCount;
      memberDataResponse.Member.TotalCourtesyAmount =
        response.TotalCourtesyAmount;
      memberDataResponse.Member.FreeCredits = response.FreeCredits;
      this.setState({
        memberDataResponse: memberDataResponse,
      });
    }
  };

  showModal = (modalData) => {
    this.setState({
      modalInfo: {
        type: modalData.type,
        showModal: true,
        memberId: modalData.memberId,
        title: modalData.title,
        popupFor: modalData.popupFor,
      },
    });
  };

  openFreeCreditPopup = (memberId) => {
    this.showModal({
      type: ModalType.FREE_CREDIT_GRANT_REVERSE_MODAL,
      memberId: this.state.memberId,
      title: "Add Free Credit & Credit Grant History",
    });
  };
  openReverseCreditPopup = (memberId) => {
    this.showModal({
      type: ModalType.FREE_CREDIT_GRANT_REVERSE_MODAL,
      memberId: this.state.memberId,
      title: "Reverse Credit Grant",
      popupFor: "creditreverse",
    });
  };
  openApplyOfferModal = (memberId) => {
    this.showModal({
      type: ModalType.APPLY_OFFER_MODAL,
      memberId: this.state.memberId,
      title: "Apply Offer Code",
    });
  };

  getLblHtmlCtrl = (title, value) => {
    return (
      <div className="form-group row">
        <label className="col-sm-4 col-form-label font-weight-bold">
          {title}
        </label>
        <label className="col-sm-8 p-0">{value || "--"}</label>
      </div>
    );
  };
  render() {
    const pageName = getPageName(this.props.match.url);
    let memberCtrl = null;
    const totalLabel = "Total";
    const lastYearLabel = "Last Year";
    const lastMonthLabel = "Last Month";
    const currentYearLabel = "Current Year";
    const currentMonthLabel = "Current Month";

    if (
      this.state.memberDataResponse &&
      this.state.memberDataResponse.StatusCode === 0
    ) {
      const memberData = { ...this.state.memberDataResponse.Member };
      memberCtrl = (
        <div className="col-lg-12 p-0">
          <div className="formRow">
            <div className="col-lg-4">
              <h4 className="form-header text-uppercase">
                <i className="fas fa-coins" />
                Earnings
              </h4>
              {this.getLblHtmlCtrl(
                totalLabel,
                memberData.TotalEarning.TotalToDate
                  ? "$" + memberData.TotalEarning.TotalToDate
                  : "$0.00"
              )}
              {this.getLblHtmlCtrl(
                lastYearLabel,
                memberData.TotalEarning.TotalLastYear
                  ? "$" + memberData.TotalEarning.TotalLastYear
                  : "$0.00"
              )}
              {this.getLblHtmlCtrl(
                lastMonthLabel,
                memberData.TotalEarning.TotalLastMonth
                  ? "$" + memberData.TotalEarning.TotalLastMonth
                  : "$0.00"
              )}
              {this.getLblHtmlCtrl(
                currentYearLabel,
                memberData.TotalEarning.TotalThisYear
                  ? "$" + memberData.TotalEarning.TotalThisYear
                  : "$0.00"
              )}
              {this.getLblHtmlCtrl(
                currentMonthLabel,
                memberData.TotalEarning.TotalCurrentMonth
                  ? "$" + memberData.TotalEarning.TotalCurrentMonth
                  : "$0.00"
              )}
            </div>
            <div className="col-lg-4">
              <h4 className="form-header text-uppercase">
                <i className="fas fa-coins" />
                Payments
              </h4>
              {this.getLblHtmlCtrl(
                totalLabel,
                memberData.TotalPayment.TotalToDate
                  ? "$" + memberData.TotalPayment.TotalToDate
                  : "$0.00"
              )}
              {this.getLblHtmlCtrl(
                lastYearLabel,
                memberData.TotalPayment.TotalLastYear
                  ? "$" + memberData.TotalPayment.TotalLastYear
                  : "$0.00"
              )}
              {this.getLblHtmlCtrl(
                lastMonthLabel,
                memberData.TotalPayment.TotalLastMonth
                  ? "$" + memberData.TotalPayment.TotalLastMonth
                  : "$0.00"
              )}
              {this.getLblHtmlCtrl(
                currentYearLabel,
                memberData.TotalPayment.TotalThisYear
                  ? "$" + memberData.TotalPayment.TotalThisYear
                  : "$0.00"
              )}
              {this.getLblHtmlCtrl(
                currentMonthLabel,
                memberData.TotalPayment.TotalCurrentMonth
                  ? "$" + memberData.TotalPayment.TotalCurrentMonth
                  : "$0.00"
              )}
            </div>
            <div className="col-lg-4">
              <h4 className="form-header text-uppercase">
                <i className="fas fa-coins" />
                Fine
              </h4>
              {this.getLblHtmlCtrl(
                totalLabel,
                memberData.TotalFine.TotalToDate
                  ? "$" + memberData.TotalFine.TotalToDate
                  : "$0.00"
              )}
              {this.getLblHtmlCtrl(
                lastYearLabel,
                memberData.TotalFine.TotalLastYear
                  ? "$" + memberData.TotalFine.TotalLastYear
                  : "$0.00"
              )}
              {this.getLblHtmlCtrl(
                lastMonthLabel,
                memberData.TotalFine.TotalLastMonth
                  ? "$" + memberData.TotalFine.TotalLastMonth
                  : "$0.00"
              )}
              {this.getLblHtmlCtrl(
                currentYearLabel,
                memberData.TotalFine.TotalThisYear
                  ? "$" + memberData.TotalFine.TotalThisYear
                  : "$0.00"
              )}
              {this.getLblHtmlCtrl(
                currentMonthLabel,
                memberData.TotalFine.TotalCurrentMonth
                  ? "$" + memberData.TotalFine.TotalCurrentMonth
                  : "$0.00"
              )}
            </div>
          </div>
          <div className="formRow">
            <div className="col-lg-4">
              <h4 className="form-header text-uppercase">
                <i className="fas fa-hand-holding-heart"></i>
                Courtesy
              </h4>

              {this.getLblHtmlCtrl(
                "Courtesy Count",
                memberData.Courtesy ? memberData.Courtesy : "0"
              )}
              {this.getLblHtmlCtrl(
                "Total Courtesy Amount",
                memberData.TotalCourtesyAmount
                  ? "$" + memberData.TotalCourtesyAmount
                  : "$0.00"
              )}
            </div>
            <div className="col-lg-4">
              <h4 className="form-header text-uppercase">
                <i className="fas fa-hand-holding-usd"></i>
                Credit
              </h4>
              <div className="form-group row">
                <label className="col-sm-4 col-form-label font-weight-bold">
                  Available Credits Balance
                </label>
                <label className="col-sm-8 p-0 font-weight-bold">
                  {memberData.FreeCredits
                    ? "$" + memberData.FreeCredits
                    : "$0.00"}
                </label>
              </div>
              <div className="col-lg-12">
                <LinkButton
                  btnLabel="Add Free Credit"
                  containerClass="pseudo-link"
                  iconClass="fas fa-plus-circle pr-2"
                  clicked={(event) =>
                    this.openFreeCreditPopup(memberData.MemberId)
                  }
                />
                <span className="verticalLineSeparator ml-2 mr-2"></span>
                <LinkButton
                  btnLabel="Reverse Credit"
                  containerClass="pseudo-link text-danger"
                  iconClass="fas fa-minus-circle pr-2"
                  clicked={(event) =>
                    this.openReverseCreditPopup(memberData.MemberId)
                  }
                />
                <span className="verticalLineSeparator ml-2 mr-2"></span>
                <LinkButton
                  btnLabel="Apply Offer"
                  containerClass="pseudo-link"
                  iconClass="fa fa-gift pr-2"
                  clicked={(event) =>
                    this.openApplyOfferModal(memberData.MemberId)
                  }
                />
              </div>
            </div>
            <div />
          </div>
          <div className="col-lg-12 p-0 mt-5">
            <h4 className="form-header text-uppercase">
              <i className="fas fa-user-secret"></i>
              Privacy Preference
            </h4>
            <div className="form-group row">
              <div className="col-lg-4 row">
                <label
                  htmlFor="newres-LicenseExpirationDate"
                  className="col-sm-4 col-form-label font-weight-bold"
                >
                  Language
                </label>
                <div className="col-sm-8">
                  <select
                    name="DriveType"
                    className="form-control"
                    value={memberData.PreferredLanguage}
                    disabled={true}
                  >
                    <option value="">Language</option>
                    <option value="ENGLISH">ENGLISH</option>
                    <option value="SPANISH">SPANISH</option>
                    <option value="GERMAN">GERMAN</option>
                    <option value="HINDI">HINDI</option>
                  </select>
                </div>
              </div>
              <div className="col-lg-4 row">
                <label
                  htmlFor="newres-LicenseExpirationDate"
                  className="col-sm-4 col-form-label font-weight-bold"
                >
                  Contact Method
                </label>
                <div className="col-sm-8">
                  <select
                    name="DriveType"
                    className="form-control"
                    value={memberData.PreferredContact}
                    disabled={true}
                  >
                    <option value="">Contact Method</option>
                    <option value="PHONE">PHONE</option>
                    <option value="EMAIL">EMAIL</option>
                    <option value="CELL">CELL</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="form-group row">
              <MemberPreference
                generalPreferace={this.state.generalPreferace}
                ownerPreference={this.state.ownerPreference}
                renterPreference={this.state.renterPreference}
              />
            </div>
          </div>
        </div>
      );
    }

    let breadCrumbList = getMemberProfileBreadcrumbList(
      this.props.otherData,
      this.state.memberId
    );

    return (
      <div className="container-fluid p-0">
        <PageTopPanel
          breadCrumbList={breadCrumbList}
          title="Summary"
          linkList={getTopMainLinkUrl(pageName)}
          // showNewReservationLink={true}
          memberId={this.state.memberId}
          showExportLink={false}
          pageName={pageName}
          linkBreadCrumb={getChildBreadCrumbList(
            this.props.match.url,
            this.props.location,
            breadCrumbList
          )}
        />
        {memberCtrl}
        {this.props.loading || this.state.loading ? <Spinner /> : ""}
        {this.state.modalInfo.showModal ? (
          <ModalEngine
            modalType={this.state.modalInfo.type}
            showModal={this.state.modalInfo.showModal}
            closeModal={this.closeModal}
            popupFor={this.state.modalInfo.popupFor}
            title={this.state.modalInfo.title}
            memberId={this.state.modalInfo.memberId}
            callBackToDisplayServerMessage={(data) =>
              this.callBackToDisplayServerMessage(data)
            }
            resetParentPage={this.resetMemberData}
          />
        ) : null}
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    onGetMember: (memberReq) => {
      dispatch(actions.getMember(memberReq));
    },
    onGetMemberReset: () => dispatch(actions.getMemberReset()),
  };
};
const mapStateToProps = (state) => {
  return {
    tokenId: state.auth.token,
    userId: state.auth.userId,
    loading: state.memberReducer.loading,
    memberDataResponse: state.memberReducer.memberData,
    error: state.memberReducer.error,
    memberData: state.memberReducer.memberData,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(MemberSummary);

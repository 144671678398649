export const MemberMessageSearchRequest = function () {
  this.ReservationId = null;
  this.StartDate = null;
  this.EndDate = null;
  this.MemberId = null;
  this.PageCount = null;
  this.RecordPerPage = null;
  this.SortBy = null;
  this.SortType = null;
  this.SelectedHeaders = null;
};

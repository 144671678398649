import React, { useState } from "react";
import {
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  DropdownItem,
} from "reactstrap";
import { GetOrgInfoWithLogo } from "../header/header-helper";
import { createTooltip } from "../../shared/utility/tooltip-utility";
import InputClearIcon from "../UI/icon/input-clear-icon";
import NoRecordFoundComponent from "../common/pages/no-record-found";

const Orgdropdown = (props) => {
  const [showDropMenu, setShowDropMenu] = useState();
  const [orgList, setOrgList] = useState(props.orgUnits || []);
  const [searchValue, setSearchValue] = useState("");

  const openDropdownMenu = () => {
    if (!showDropMenu) {
      setShowDropMenu(true);
    }
  };
  const closeDropMenu = () => {
    setShowDropMenu(!showDropMenu);
  };

  const clearValue = () => {
    setSearchValue("");
    getSearchData(null, "");
  };

  const getSearchData = (event, searchInput) => {
    let textValue = event ? event.target.value : searchInput;
    setSearchValue(textValue)
    let orgListNew = props.orgUnits;
    let dataToSearch = [...orgListNew];

    if (textValue) {
      dataToSearch = dataToSearch.filter((item) =>
        `${item.Value.toLowerCase()}`.includes(
          textValue.toLowerCase()
        )
      );
      setOrgList(dataToSearch);
    } else {
      setOrgList(orgListNew);
    }
  };

  const switchOrg = (orgKey) => {
    clearValue();
    props.switchOrg(orgKey);
  }

  const renderFilterSection = () => {
    return (
        <div className="col-sm-12 pt-3" style={{minWidth:"20rem"}}>
            <InputClearIcon
              inputValue={searchValue}
              customDivClass="mt-3 light-gray-color"
              onClickEvent={clearValue}
            />
            <input
              type="text"
              style={{ height: "Auto" }}
              placeholder="Enter org name to search"
              value={searchValue}
              onChange={(event) => getSearchData(event)}
              autoFocus={true}
            />
          </div>
    );
  };

  let selectedOrgInfo = GetOrgInfoWithLogo(orgList);
  const orgData = selectedOrgInfo.map((org, index) => {
    return org.OrgKey ? (
      <span
        className={`dropmenu__content--link pseudo-link ${
          org.OrgKey === props.selectedOrg ? "active" : ""
        }`}
        key={index}
        onClick={(event) => switchOrg(org.OrgKey)}
      >
        {org.dropdownLogo ? (
          <img
            src={org.dropdownLogo}
            alt="logo"
            className="dropmenu__content--link--logo pl-2"
            style={{ width: "7rem", height: "auto" }}
          />
        ) : (
          ""
        )}
        <span className="dropmenu__content--link--text text-left">
          {org.OrgName}
        </span>
      </span>
    ) : (
      <span
        id="noOrgFound"
        className={`no-cursor`}
        key={index}
        onClick={() => clearValue()}
      >
        <NoRecordFoundComponent
          className="border-none"
          innerClassName="no-record-found-normal"
          searchMsg="No Record Found"
          heights="2rem"
        />
      </span>
    );
  });
  return (
    <UncontrolledDropdown
      isOpen={showDropMenu}
      toggle={closeDropMenu}
      onClick={(event) => {
        openDropdownMenu();
      }}
    >
      <DropdownToggle
        tag="div"
        className="user-nav__icon-box"
        id="parent-org-dropdown-id"
      >
        <span className="user-nav__icon">
          {createTooltip(
            <span className="parent-org-dropdown-caret" />,
            "Click to Change Org",
            { forceShow: true }
          )}
        </span>
      </DropdownToggle>
      {showDropMenu ? (
        <DropdownMenu right>
          {renderFilterSection()}
          <hr className="mb-0" />
          <DropdownItem
            tag="div"
            className="tableScroll"
            style={{
              maxHeight: "40rem",
              maxWidth: "20rem",
              overflow: "auto",
            }}
          >
            {orgData}
          </DropdownItem>
        </DropdownMenu>
      ) : (
        ""
      )}
    </UncontrolledDropdown>
  );
};
export default Orgdropdown;

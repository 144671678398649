import { FoxRequestHeader } from "../../fox-api/domain/fox-request-header";
import PageInfo from "../../models/PageInfo";

const ShuttleTripSearchRequest = function(data) {
  data = data || {};
  this.requestHeader = new FoxRequestHeader();
  this.startDateTime = data.startDateTime || null;
  this.endDateTime = data.endDateTime || null;
  this.shuttleCode = data.shuttleCode || null;
  this.badgeNo = data.badgeNo || null;
};
export default ShuttleTripSearchRequest;

import { getEngineStatusDisplayValue } from "../../../../containers/report/shuttle-trips/shuttle-trips-helper";
import { getHourMinSecFromMilliSecond } from "../../../../shared/utility/shuttle-app-utility";

export const ShuttleActivityViewModel = function (data) {
    if (!data) data = {};
    this.engineStatus = data.engineStatus;
    this.engineStatusDisplayName = getEngineStatusDisplayValue(data.engineStatus);
    this.activityStartDateTimeInMillis = data.activityStartDateTimeInMillis || 0;
    this.activityStartDateTime = data.activityDisplayStartDateTime;
    this.activityEndDateTimeInMillis = data.activityEndDateTimeInMilli || 0;
    this.activityEndDateTime = data.activityDisplayEndDateTime;
    this.activityDurationInMillis = data.activityDurationInMillis || 0;
    this.activityDuration = getHourMinSecFromMilliSecond(data.activityDurationInMillis);
};
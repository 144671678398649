const CancelReservationRequest = function() {
  this.ReservationId = null;
  this.CancellationReasons = null;
  this.Comments = null;
  this.CancelledBy = null;
  this.MemberId = null;
  this.CancellationFee = null;
  this.CourtesyApplied = false;
  this.CourtesyAmount = null;
  this.IsRefundAsCredits = false;
  this.RefundAppliedCredits = true;
  this.RefundCreditCardPayments = true;
  this.IntegStatus = null;
  this.OverrideEmail = false;
};
export default CancelReservationRequest;

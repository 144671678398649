import * as actionTypes from "../actions/actionTypes";
import { reducerUpdateObject } from "./reducer.utility";

const initialState = {
  searchDamageResponse: null,
  addDamageResponse: null,
  error: null,
  loading: false,
};

const searchDamageInfoStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
const searchDamageInfoSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    searchDamageResponse: action.searchDamageResponse,
    error: null,
    loading: false,
  });
};
const searchDamageInfoFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false,
  });
};
const searchDamageInfoReset = (state, action) => {
  return reducerUpdateObject(state, { ...initialState });
};

const addDamageInfoStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
const addDamageInfoSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    addDamageResponse: action.addDamageResponse,
    error: null,
    loading: false,
  });
};
const addDamageInfoFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false,
  });
};
const addDamageInfoReset = (state, action) => {
  return reducerUpdateObject(state, { addDamageResponse: null });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_DAMAGE_START:
      return searchDamageInfoStart(state, action);
    case actionTypes.GET_DAMAGE_SUCCESS:
      return searchDamageInfoSuccess(state, action);
    case actionTypes.GET_DAMAGE_FAIL:
      return searchDamageInfoFail(state, action);
    case actionTypes.GET_DAMAGE_RESET:
      return searchDamageInfoReset(state, action);

    case actionTypes.ADD_DAMAGE_START:
      return addDamageInfoStart(state, action);
    case actionTypes.ADD_DAMAGE_SUCCESS:
      return addDamageInfoSuccess(state, action);
    case actionTypes.ADD_DAMAGE_FAIL:
      return addDamageInfoFail(state, action);
    case actionTypes.ADD_DAMAGE_RESET:
      return addDamageInfoReset(state, action);

    default:
      return state;
  }
};
export default reducer;

import React, { Fragment, useState } from "react";
import ReactHtmlParser from "react-html-parser";
import { Link } from "react-router-dom";
import ModalEngine from "../../components/modal/modal-engine";
import ModalInfo from "../../services/entities/models/ModalInfo";
import { EmployeeIcon, UserProfileLinkIcon } from "../../shared/AppImages";
import { COMMON_MSG, ModalType } from "../../shared/GlobalVar";
import PermissionName from "../../shared/Permissions";
import {
  convertDateTimeStringWithTimezone,
  formatPricingValue,
  getNoteTooltipHtml,
  getResStatusWiseDateTimeTooltip,
  getSortingClassName,
  getSortType,
  getSubString,
  hasPermission,
} from "../../shared/utility";
import { isP2POrHybridOrg } from "../../shared/utility/system-utility";
import { createTooltip } from "../../shared/utility/tooltip-utility";
import { getReservationModalDefaultInfo } from "../Reservation/reservation-helper";

export const SearchBillingGrid = (props) => {
  const [modalInfo, setModalInfo] = useState([new ModalInfo()]);
  const closeModal = () => {
    setModalInfo(new ModalInfo());
  };
  const showModal = (modalData) => {
    setModalInfo({
      type: modalData.type,
      showModal: true,
      invoiceId: modalData.invoiceId,
      transactionId: modalData.transactionId,
      memberId: modalData.memberId,
      vehicleId: modalData.vehicleId,
      billingObj: modalData.billingObj,
    });
  };
  return (
    <Fragment>
      <div className="datagrid">
        <div className="column">
          <span className="column__header" />
          <span
            className={getSortingClassName(props.pageInfo, "BILL_PAYMENT_DATE")}
            onClick={(event) =>
              props.doSorting(
                getSortType(props.pageInfo, "BILL_PAYMENT_DATE"),
                "BILL_PAYMENT_DATE"
              )
            }
          >
            Billing Date/Time
          </span>
          <span
            className={getSortingClassName(props.pageInfo, "BILL_INVOICE_NO")}
            onClick={(event) =>
              props.doSorting(
                getSortType(props.pageInfo, "BILL_INVOICE_NO"),
                "BILL_INVOICE_NO"
              )
            }
          >
            Invoice ID
          </span>
          <span
            className={getSortingClassName(props.pageInfo, "BILL_VEHICLE_ID")}
            onClick={(event) =>
              props.doSorting(
                getSortType(props.pageInfo, "BILL_VEHICLE_ID"),
                "BILL_VEHICLE_ID"
              )
            }
          >
            Vehicle ID
          </span>
          <span
            className={getSortingClassName(props.pageInfo, "BILL_CATEGORY")}
            onClick={(event) =>
              props.doSorting(
                getSortType(props.pageInfo, "BILL_CATEGORY"),
                "BILL_CATEGORY"
              )
            }
          >
            Category
          </span>
          <span
            className={getSortingClassName(props.pageInfo, "BILL_BILLING_NAME")}
            onClick={(event) =>
              props.doSorting(
                getSortType(props.pageInfo, "BILL_BILLING_NAME"),
                "BILL_BILLING_NAME"
              )
            }
          >
            Billing Name
          </span>
          {isP2POrHybridOrg() ? (
            <span
              className={getSortingClassName(props.pageInfo, "BILL_OWNER_NAME")}
              onClick={(event) =>
                props.doSorting(
                  getSortType(props.pageInfo, "BILL_OWNER_NAME"),
                  "BILL_OWNER_NAME"
                )
              }
            >
              Owner Name
            </span>
          ) : (
            ""
          )}
          <span
            className={getSortingClassName(
              props.pageInfo,
              "BILL_RESERVATION_CODE"
            )}
            onClick={(event) =>
              props.doSorting(
                getSortType(props.pageInfo, "BILL_RESERVATION_CODE"),
                "BILL_RESERVATION_CODE"
              )
            }
          >
            Res. Code
          </span>
          <span
            className={getSortingClassName(
              props.pageInfo,
              "BILL_RESERVATION_STATE"
            )}
            onClick={(event) =>
              props.doSorting(
                getSortType(props.pageInfo, "BILL_RESERVATION_STATE"),
                "BILL_RESERVATION_STATE"
              )
            }
          >
            Res. Status
          </span>
          <span
            className={getSortingClassName(
              props.pageInfo,
              "BILL_PAYMENT_STATE"
            )}
            onClick={(event) =>
              props.doSorting(
                getSortType(props.pageInfo, "BILL_PAYMENT_STATE"),
                "BILL_PAYMENT_STATE"
              )
            }
          >
            Payment Status
          </span>
          <span
            className={getSortingClassName(props.pageInfo, "BILL_TOTAL_AMOUNT")}
            onClick={(event) =>
              props.doSorting(
                getSortType(props.pageInfo, "BILL_TOTAL_AMOUNT"),
                "BILL_TOTAL_AMOUNT"
              )
            }
          >
            Total Amount
          </span>
          <span
            className={getSortingClassName(props.pageInfo, "BILL_AMOUNT_PAID")}
            onClick={(event) =>
              props.doSorting(
                getSortType(props.pageInfo, "BILL_AMOUNT_PAID"),
                "BILL_AMOUNT_PAID"
              )
            }
          >
            Amount Paid
          </span>
          <span
            className={getSortingClassName(
              props.pageInfo,
              "BILL_PRE_AUTHORIZATION"
            )}
            onClick={(event) =>
              props.doSorting(
                getSortType(props.pageInfo, "BILL_PRE_AUTHORIZATION"),
                "BILL_PRE_AUTHORIZATION"
              )
            }
          >
            Pre-Auth
          </span>
          <span
            className={getSortingClassName(props.pageInfo, "BILL_FREE_CREDITS")}
            onClick={(event) =>
              props.doSorting(
                getSortType(props.pageInfo, "BILL_FREE_CREDITS"),
                "BILL_FREE_CREDITS"
              )
            }
          >
            Free Credits
          </span>
          <span
            className={getSortingClassName(props.pageInfo, "BILL_BALANCE_DUE")}
            onClick={(event) =>
              props.doSorting(
                getSortType(props.pageInfo, "BILL_BALANCE_DUE"),
                "BILL_BALANCE_DUE"
              )
            }
          >
            Balance Due
          </span>
        </div>
        {props.dataList.map((billingObj) => {
          return (
            <div className="row" key={billingObj.TransactionId}>
              <span
                className="row__column  cb-container"
                style={{ textAlign: "-webkit-center" }}
              >
                {createTooltip(
                  <div
                    id={
                      billingObj.ReservationCreationType +
                      "_" +
                      billingObj.TransactionId
                    }
                    className={`resGenreIconForRow  ${billingObj.ReservationCreationType === "TEST"
                      ? ""
                      : "displayNone"
                      }`}
                  >
                    Test
                  </div>,
                  "Test Reservation",
                  { forceShow: true }
                )}
              </span>
              <span
                className="row__column text-center"
                id={"paymentDateId_" + billingObj.TransactionId}
              >
                {createTooltip(
                  convertDateTimeStringWithTimezone(
                    billingObj.PaymentDate,
                    billingObj.TimezoneString
                  ),

                  convertDateTimeStringWithTimezone(
                    billingObj.PaymentDate,
                    billingObj.TimezoneString
                  )
                )}
              </span>
              <span className="row__column">
                {hasPermission(PermissionName.VIEW_PAYMENT) ? (
                  createTooltip(
                    <label
                      id={"invoiceId_" + billingObj.TransactionId}
                      className="pseudo-link"
                      onClick={(event) =>
                        showModal({
                          type: ModalType.INVOICE_INFO_MODAL,
                          showModal: true,
                          invoiceId: billingObj.InvoiceId,
                          transactionId: billingObj.TransactionId,
                        })
                      }
                    >
                      {billingObj.InvoiceId}
                    </label>,
                    billingObj.InvoiceId
                  )
                ) : (
                  <label>{billingObj.InvoiceId}</label>
                )}
              </span>
              <span className="row__column">
                {hasPermission(PermissionName.VIEW_VEHICLE) ? (
                  <label
                    className="pseudo-link"
                    onClick={(event) =>
                      showModal({
                        type: ModalType.VEHICLE_QUICK_MODAL,
                        showModal: true,
                        vehicleId: billingObj.PersonAssetId,
                      })
                    }
                  >
                    {billingObj.PersonAssetUnitNumber}
                  </label>
                ) : (
                  <label>{billingObj.PersonAssetUnitNumber}</label>
                )}
              </span>
              <span
                className="pseudo-link row__column text-center"
                id={"categoryName_" + billingObj.TransactionId}
                onClick={() =>
                  showModal({
                    type: ModalType.VEHICLE_CATEGORY_QUICK_MODAL,
                    billingObj: billingObj,
                  })
                }
              >
                {createTooltip(
                  billingObj.CategoryName
                    ? getSubString(billingObj.CategoryName, 0, 4)
                    : "--",
                  billingObj.CategoryName
                )}
              </span>
              <span className="row__column border-right-none">
                {hasPermission(PermissionName.VIEW_MEMBER)
                  ? createTooltip(
                    <label
                      className="pseudo-link"
                      id={"billingNameId_" + billingObj.TransactionId}
                      onClick={(event) =>
                        showModal({
                          type: ModalType.MEMBER_QUICK_MODAL,
                          showModal: true,
                          memberId: billingObj.BorrowerId,
                        })
                      }
                    >
                      {billingObj.BillingName}
                    </label>,
                    billingObj.BillingName
                  )
                  : createTooltip(
                    <label id={"billingNameId_" + billingObj.TransactionId}>
                      {billingObj.BillingName}
                    </label>,
                    billingObj.BillingName
                  )}
              </span>
              <span className="row__column  p-0 icon-right" id="icoEmployeeId">
                <span
                  id={"icoEmployeeType_" + billingObj.TransactionId}
                  className={
                    props.url === "/billing"
                      ? hasPermission(PermissionName.VIEW_MEMBER)
                        ? ""
                        : "displayNone"
                      : "displayNone"
                  }
                >
                  {createTooltip(
                    <Link
                      to={{
                        pathname: "/member/memberProfile",
                        search: "?memberId=" + billingObj.BorrowerId,
                        state: {
                          breadCrumbList: props.breadCrumbList,
                          breadCrumbTitle: billingObj.BillingName,
                        },
                      }}
                    >
                      <img
                        alt=""
                        src={
                          billingObj.BorrowerEmployeeNo
                            ? EmployeeIcon
                            : UserProfileLinkIcon
                        }
                        style={{ height: "12px", width: "12px" }}
                      />
                    </Link>,
                    ReactHtmlParser(
                      getNoteTooltipHtml(
                        billingObj.BorrowerEmployeeNo
                          ? "Employee#: " +
                          billingObj.BorrowerEmployeeNo +
                          "<br>Location: " +
                          (billingObj.BorrowerLocationName
                            ? billingObj.BorrowerLocationName
                            : "--") +
                          "<br/><br/>" +
                          COMMON_MSG.UserProfileLinkIconTooltip
                          : COMMON_MSG.UserProfileLinkIconTooltip
                      )
                    ),
                    { forceShow: true }
                  )}
                </span>
              </span>
              {isP2POrHybridOrg() ? (
                <span className="row__column">
                  {hasPermission(PermissionName.VIEW_MEMBER)
                    ? createTooltip(
                      <label
                        className="pseudo-link"
                        id={
                          "personAssetOwnerNameId_" + billingObj.TransactionId
                        }
                        onClick={(event) =>
                          showModal({
                            type: ModalType.MEMBER_QUICK_MODAL,
                            showModal: true,
                            memberId: billingObj.PersonAssetOwnerId,
                          })
                        }
                      >
                        {billingObj.PersonAssetOwnerName}
                      </label>,
                      billingObj.PersonAssetOwnerName
                    )
                    : createTooltip(
                      <label
                        id={
                          "personAssetOwnerNameId_" + billingObj.TransactionId
                        }
                      >
                        {billingObj.PersonAssetOwnerName}
                      </label>,
                      billingObj.PersonAssetOwnerName
                    )}
                </span>
              ) : (
                ""
              )}
              <span
                className="row__column"
                id={"reservationCode_" + billingObj.TransactionId}
              >
                {createTooltip(
                  <Link
                    className={`md-trigger ${hasPermission(PermissionName.VIEW_RESERVATION)
                      ? ""
                      : "noAnchorLink"
                      }`}
                    to={{
                      pathname: "/reservationSummary",
                      search: "?reservationId=" + billingObj.ReservationCode,
                      state: { breadCrumbList: props.breadCrumbList },
                    }}
                  >
                    {billingObj.ReservationCode || "--"}
                  </Link>,
                  billingObj.ReservationCode
                )}
              </span>
              <span
                id={"reservationStateId_" + billingObj.TransactionId}
                className={`row__column ${billingObj.ReservationState} ${billingObj.IsLateReservation || false
                  }`}
              >
                {createTooltip(
                  billingObj.ReservationState,
                  ReactHtmlParser(
                    getResStatusWiseDateTimeTooltip(
                      billingObj.ActualStartDate,
                      billingObj.ActualEndDate,
                      billingObj.PromisedStartTime,
                      billingObj.TimezoneString,
                      billingObj.ReservationState
                    )
                  ),
                  { forceShow: true }
                )}
              </span>
              <span
                className="row__column"
                id={"paymentState_" + billingObj.TransactionId}
              >
                {createTooltip(
                  billingObj.PaymentState,
                  billingObj.PaymentState
                )}
              </span>
              <span className="row__column text-right">
                {formatPricingValue(billingObj.ReservationTotalPaid, "$0.00")}
              </span>
              <span className="row__column img-container border-right-none">
                {createTooltip(
                  <i
                    className={
                      billingObj.PaymentRole === "MANUAL_PAYMENT" &&
                        (billingObj.PaymentProcessor === "CREDIT_CARD" ||
                          billingObj.PaymentProcessor === "DEBIT_CARD" ||
                          billingObj.CreditCardType)
                        ? "fas fa-credit-card mic-ico"
                        : "displayNone"
                    }
                    onClick={(event) =>
                      showModal({
                        type: ModalType.INVOICE_INFO_MODAL,
                        showModal: true,
                        invoiceId: billingObj.InvoiceId,
                        transactionId: billingObj.TransactionId,
                      })
                    }
                  ></i>,
                  "Click for Invoice Details",
                  { forceShow: true }
                )}
              </span>
              <span className="row__column text-right">
                {formatPricingValue(billingObj.Amount, "$0.00")}
              </span>
              <span className="row__column text-right">
                {formatPricingValue(billingObj.TotalPreauthAmount, "$0.00")}
              </span>
              <span className="row__column text-right">
                {formatPricingValue(billingObj.TotalFreeCredits, "$0.00")}
              </span>
              <span className="row__column text-right">
                {formatPricingValue(billingObj.BalanceDue, "$0.00")}
              </span>
            </div>
          );
        })}
      </div>
      {modalInfo.showModal ? (
        <ModalEngine
          modalType={modalInfo.type}
          showModal={modalInfo.showModal}
          closeModal={closeModal}
          invoiceId={modalInfo.invoiceId}
          transactionId={modalInfo.transactionId}
          memberId={modalInfo.memberId}
          vehicleId={modalInfo.vehicleId}
          defaultData={getReservationModalDefaultInfo(
            modalInfo.type,
            modalInfo.billingObj,
            null,
            true
          )}
        />
      ) : null}
    </Fragment>
  );
};
export default SearchBillingGrid;

import React from "react";
import Draggable from "react-draggable";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
const InheritFromParentConfirmModal = (props) => {
  const getBodyStyle = () => {
    return props.modalHeight
      ? { overflow: "auto", height: props.modalHeight }
      : {};
  };
  return (
    <Draggable handle=".cursor">
      <Modal
        isOpen={props.showModal}
        className="no-cursor"
        toggle={() => {
          props.closeModal();
        }}
        style={{
          minWidth: props.modalWidth ? props.modalWidth : "auto",
        }}
        id="inherit-modal"
      >
        <ModalHeader
          className="modal-header bg-primary modal-title text-white cursor"
          toggle={() => {
            props.closeModal();
          }}
        >
          <i className="fas fa-sitemap pr-2"></i>
          Inherit from Primary Location
        </ModalHeader>
        <ModalBody>
          <div className="text-center pb-2">
            <label className="mr-auto fs-3 text-center font-weight-bold">
              Primary Location Data
            </label>
          </div>
          <div
            className="input-container-with-border pt-2 pl-0"
            style={getBodyStyle()}
          >
            <props.componentName
              otherData={{ selectedOrgLocationId: props.currentOrgUnitId }}
              isInheritModal={true}
              hourFormat={props.hourFormat}
            />
          </div>
          <div className="col-lg-12 fs-2 darkred-color pt-4">
            Inheriting from Primary Location will use the above values. Current
            values will be lost and cannot be recovered. Do you want to
            continue?
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="col-sm-12 text-right">
            <button
              type="button"
              name="btnSave"
              className="btn btn-primary waves-effect waves-light btnSize ml-2"
              onClick={() => props.doInheritFunctionality()}
            >
              Save
            </button>
            <button
              type="button"
              name="btnCancel"
              className="btn btn-secondary waves-effect waves-light btnSize ml-2"
              onClick={() => props.closeModal()}
            >
              Cancel
            </button>
          </div>
        </ModalFooter>
      </Modal>
    </Draggable>
  );
};
export default InheritFromParentConfirmModal;

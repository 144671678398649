import React from "react";
import ReactHtmlParser from "react-html-parser";

const CommonButton = (props) => {
  const {
    id,
    type,
    name,
    disabled,
    onClickAction,
    title,
    buttonLabel,
    buttonClasses,
    ...rest
  } = props;
  
  const handleClick = () => {
    if (onClickAction) {
      if (rest.modalParams && rest.modalParams.type) {
        onClickAction(rest.modalParams); // Pass modalParam along with rest props
      } else {
        onClickAction();
      }
    }
  };

  return (
    <button
      id={id}
      type={type || "button"}
      name={name || ""}
      disabled={disabled}
      title={title || ""}
      className={`${buttonClasses || ""}`}
      onClick={handleClick}
      style={rest.customStyle || {}}
    >
      {ReactHtmlParser(buttonLabel)}
      {rest.imageURL && (
        <img
          className={rest.imageClass || ""}
          src={rest.imageURL}
          height={16}
          width={16}
          alt={buttonLabel}
        />
      )}
    </button>
  );
};
export default CommonButton;

import React from "react";
import { connect } from "react-redux";
import DateTimePicker from "react-widgets/lib/DateTimePicker";
import { setAuthRedirectPath } from "../../store/actions";
import { getDateTimeFormatString, getDateTimePlaceholder } from "../../components/common/common-helper";

class FavoriteBottomContainer extends React.Component {
  state = {
    modalShow: false,
    loading: true,
    totalDistance: null,
    currentOrgUnitId: localStorage.getItem("currentOrgUnitId") || "10002624203",
    endDate: null,
    startDate: null,
    location: null,
    locationReturn: null,
  };

  calculateDistance = () => {
    this.setState({
      totalDistance: "3902.02 MILES", //temp value
    });
  };

  checkAvailability = () => {};

  requestNow = () => {};

  handleOnChange = ({ target }) => {
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  };

  render() {
    let btnClass = `btn ${
      this.props.disable === "true"
        ? "btn-danger waves-effect waves-light"
        : "btn-primary"
    } `;
    return (
      <div className="form-group input-container-with-border col-lg-9 mt-4">
        <fieldset disabled={this.props.disable === "true" ? true : false}>
          <div className="col-lg-12 form-group formRow">
            <div className="col-lg-4 formRow font-weight-bold">
              <div className="col-lg-6">
                <label className="col-lg-12  font-weight-bold">Location</label>
              </div>
              <div className="col-lg-6">
                <select
                  name="location"
                  value={this.state.location}
                  onChange={(event) => this.handleOnChange(event)}
                >
                  {this.props.dependentOrg.map((element) => (
                    <option key={element.Key} value={element.Key}>
                      {element.Value}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-lg-4 formRow font-weight-bold">
              <div className="col-lg-12">
                <input
                  type="text"
                  name="locationReturn"
                  value={this.state.locationReturn}
                  onChange={(event) => this.handleOnChange(event)}
                />
              </div>
            </div>
            <div className="col-lg-2">
              <button
                type="button"
                value="Search"
                className={btnClass}
                onClick={(event) => this.calculateDistance(event)}
                id="btnCalculateDistance"
              >
                <i className="fa fa-check-square-o" />
                Calculate Distance
              </button>
            </div>
            <label className="font-weight-bold mt-1 fs-2 green-color">
              {this.state.totalDistance ? this.state.totalDistance : ""}
            </label>
          </div>
          <div className="col-lg-12 form-group formRow">
            <div className="col-lg-4 formRow font-weight-bold">
              <div className="col-lg-6">
                <label className="col-lg-12  font-weight-bold">
                  Start Date Time
                </label>
              </div>
              <div className="col-lg-6">
                <div className="dateandtimepicker">
                  <DateTimePicker
                    time={false}
                    format={getDateTimeFormatString()}
                    placeholder={getDateTimePlaceholder()}
                    value={this.state.startDate}
                    onChange={(event) => this.setState({ startDate: event })}
                    dateFormat="D"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-4 formRow font-weight-bold">
              <div className="col-lg-6">
                <label className="col-lg-12  font-weight-bold">
                  End Date Time
                </label>
              </div>
              <div className="col-lg-6">
                <div className="dateandtimepicker">
                  <DateTimePicker
                    time={false}
                    format={getDateTimeFormatString()}
                    placeholder={getDateTimePlaceholder()}
                    value={this.state.endDate}
                    disabled={this.props.endDate}
                    onChange={(event) => this.setState({ startDate: event })}
                    dateFormat="D"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-2">
              <button
                type="button"
                value="Search"
                className={btnClass}
                onClick={(event) => this.checkAvailability(event)}
                id="btnCheckAvailability"
              >
                <i className="fa fa-check-square-o" />
                Check Availability
              </button>
            </div>
            <div className="col-lg-2">
              <button
                type="button"
                value="Search"
                className={btnClass}
                onClick={(event) => this.requestNow(event)}
                id="btnRequestNow"
              >
                <i className="fa fa-check-square-o" />
                Request Now
              </button>
            </div>
          </div>
        </fieldset>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    tokenId: state.auth.token,
    userId: state.auth.userId,
    dependentOrg: state.auth.loggedInUserData.DependantOrgList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSetAuthRedirectPath: (path) => dispatch(setAuthRedirectPath(path)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FavoriteBottomContainer);

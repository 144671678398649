import React, { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  permissionsReset,
  getMemberPermissions,
  assignPermissionsToMemberReset,
  assignPermissionsToMember,
} from "../../../store/actions";
import { sortByString, getBasicToastResponse } from "../../../shared/utility";
import Spinner from "../../../components/UI/Spinner/Spinner";
import { Roles_Permissions } from "../../../shared/GlobalVar";
import PermissionsGrid from "../../../components/permission/permission-grid-component";
import { showNotification } from "../../../components/UI/Toaster/Toaster";

const PermissiontoMember = (props) => {
  const stateloading = useSelector((state) => state.permissionsReducer.loading);
  const isCategoryRequired = true;
  const [rolesPermisisonsInfo, setRolesPermisisonsInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const membersPermissionsDataResponse = useSelector(
    (state) => state.permissionsReducer.memberPermissionsData
  );
  const assignPermissionsToMemberResponse = useSelector(
    (state) => state.permissionsReducer.assignPermissionsToMemberResponse
  );

  useEffect(() => {
    dispatch(permissionsReset());
    dispatch(
      getMemberPermissions({
        MemberIds: props.memberIds || [],
      })
    );
  }, []);

  useEffect(() => {
    if (!membersPermissionsDataResponse) {
      return;
    }
    setRolesPermisisonsInfo(
      doCbGridCompatibleDataForPermissionsToUsers(
        membersPermissionsDataResponse,
        true
      )
    );
    setLoading(false);
  }, [membersPermissionsDataResponse]);

  useEffect(() => {
    if (!assignPermissionsToMemberResponse) {
      return;
    }
    showNotification(getBasicToastResponse(assignPermissionsToMemberResponse));
    props.closeModal();
    dispatch(permissionsReset());
  }, [assignPermissionsToMemberResponse]);

  const doCbGridCompatibleDataForPermissionsToUsers = (
    permissionsData,
    cateGoryRequired
  ) => {
    let rolesPermissionsGridData = {
      rowData: {},
      columnData: {},
      totalRows: permissionsData.AllPermissions
        ? permissionsData.AllPermissions.length
        : 0,
      totalColumns: permissionsData.MemberPermissions
        ? permissionsData.MemberPermissions.length
        : 0,
    };
    permissionsData.AllPermissions &&
      permissionsData.AllPermissions.map((permissionData, index) => {
        var data = {};
        data.rowId = permissionData.PermissionId;
        data.rowName = permissionData.Name;
        data.rowDesc = permissionData.DisplayText;
        data.columnInfo = {};
        data.type = cateGoryRequired
          ? permissionData.PermissionType || "Other"
          : "All";
        data.prerequisitePermissions = getCompatibleDataForRowList(
          permissionData.PrerequisitePermissions,
          permissionsData.AllPermissions
        );
        data.allPrerequisitePermissions = getCompatibleDataForRowList(
          permissionData.AllPrerequisitePermissions,
          permissionsData.AllPermissions
        );
        data.dependantPermissions = getCompatibleDataForRowList(
          permissionData.DependantPermissions,
          permissionsData.AllPermissions
        );

        if (data.type && !rolesPermissionsGridData.rowData[data.type]) {
          rolesPermissionsGridData.rowData[data.type] = {};
          rolesPermissionsGridData.rowData[data.type][data.rowId] = data;
        } else {
          rolesPermissionsGridData.rowData[data.type][data.rowId] = data;
        }
      });
    var sortedcolumnsList = sortByString(
      permissionsData.MemberPermissions,
      "FullName"
    );
    sortedcolumnsList.map((memberData, index1) => {
      var data = {};
      data.columnId = memberData.PersonId;
      data.columnName = memberData.FullName;
      data.rowInfo = {};
      memberData.Permissions.map((memPermisison, index2) => {
        var dataPer = {};
        dataPer.inherited = memPermisison.Inherited;
        dataPer.granted =
          typeof memPermisison.Granted === "undefined"
            ? null
            : memPermisison.Granted;
        if (memPermisison.Inherited && memPermisison.Granted != null) {
          dataPer.serverOverRide = true;
        } else {
          dataPer.serverOverRide = false;
        }
        data.rowInfo[memPermisison.PermissionId] = dataPer;

        Object.keys(rolesPermissionsGridData.rowData).map((type, index) => {
          if (
            rolesPermissionsGridData.rowData[type][memPermisison.PermissionId]
          ) {
            if (
              !Object.keys(
                rolesPermissionsGridData.rowData[type][
                  memPermisison.PermissionId
                ]["columnInfo"]
              ).length
            ) {
              rolesPermissionsGridData.rowData[type][
                memPermisison.PermissionId
              ]["columnInfo"][data.columnId] = {};
              rolesPermissionsGridData.rowData[type][
                memPermisison.PermissionId
              ]["columnInfo"][data.columnId] = dataPer;
            } else {
              rolesPermissionsGridData.rowData[type][
                memPermisison.PermissionId
              ]["columnInfo"][data.columnId] = dataPer;
            }
          }
        });
      });
      rolesPermissionsGridData.columnData[memberData.PersonId] = data;
    });
    return rolesPermissionsGridData;
  };
  const getCompatibleDataForRowList = (rowList, allPermissions) => {
    if (rowList && rowList.length > 0) {
      var data = {};
      rowList.map((permissionId, index1) => {
        allPermissions.map((permissionObj, index2) => {
          if (permissionId === permissionObj.PermissionId) {
            data[permissionId] = {
              rowId: permissionId,
              rowName: permissionObj.DisplayText,
              type: permissionObj.PermissionType,
            };
            return false;
          }
        });
      });
      return data;
    }
  };
  const getModalSettings = () => {
    const totalColumns = rolesPermisisonsInfo.totalColumns;
    const totalRows = rolesPermisisonsInfo.totalRows;
    let tableWidth = isCategoryRequired
      ? totalColumns * 60 + 300
      : totalColumns * 60 + 200;
    let modalBodyHeight, modalWidth;

    if (totalColumns < 4) {
      modalWidth = isCategoryRequired ? 540 : 425;
    } else {
      if (tableWidth > 1060) {
        modalWidth = 1060;
      } else {
        modalWidth = isCategoryRequired ? tableWidth + 100 : tableWidth + 60;
      }
    }
    if (totalRows < 5) {
      modalBodyHeight = 260;
    } else {
      if (totalRows * 30 + 100 > 648) {
        modalBodyHeight = 648;
      } else {
        modalBodyHeight = totalRows * 30 + 100;
      }
    }
    let modalSettings = {
      modalWidth: modalWidth,
      modalBodyHeight: modalBodyHeight,
    };
    return modalSettings;
  };
  const upDateRolesPermissionsData = (rolesPermisisonsData) => {
    let memberAddPermissionRequest = {};
    memberAddPermissionRequest.AssignPermissions = [];
    memberAddPermissionRequest.AssociatedPermissionList = [];
    Object.keys(rolesPermisisonsData.columnData).map((columnkey, index1) => {
      let granted = [];
      let revoked = [];
      let columnData = rolesPermisisonsData.columnData[columnkey];
      let permissionObj = {};
      permissionObj.MemberId = columnkey;
      Object.keys(columnData.rowInfo).map((rowKey, index2) => {
        let rowData = columnData.rowInfo[rowKey];
        if (rowData.granted) {
          granted.push(rowKey);
        } else if (rowData.granted === false) {
          revoked.push(rowKey);
        }
      });
      permissionObj.GrantedPermissions = granted;
      permissionObj.RevokedPermissions = revoked;
      memberAddPermissionRequest.AssignPermissions.push(permissionObj);
    });
    membersPermissionsDataResponse.AllPermissions.map(
      (permissionData, index) => {
        memberAddPermissionRequest.AssociatedPermissionList.push(
          permissionData.PermissionId
        );
      }
    );
    setLoading(true);
    dispatch(assignPermissionsToMemberReset());
    dispatch(assignPermissionsToMember(memberAddPermissionRequest));
  };

  let rolesPermissionsctrl = <Spinner />;
  if (!stateloading) {
    if (
      membersPermissionsDataResponse &&
      rolesPermisisonsInfo &&
      membersPermissionsDataResponse.StatusCode === 0
    ) {
      let modalSettings = getModalSettings();
      rolesPermissionsctrl = (
        <Fragment>
          <PermissionsGrid
            isModalOpen={props.showModal}
            closeModal={props.closeModal}
            modalSettings={modalSettings}
            permissionsType={Roles_Permissions.PermissionsToMember}
            isCategoryRequired={isCategoryRequired}
            rolesPermisisonsData={rolesPermisisonsInfo}
            upDateRolesPermissionsData={upDateRolesPermissionsData}
          />
          {loading ? <Spinner /> : ""}
        </Fragment>
      );
    }
  }
  return rolesPermissionsctrl;
};

export default PermissiontoMember;

export const FoxStepViewModel = function (data) {
  if (!data) data = {};
  this.executionBlock = data.executionBlock || null;
  this.prevBlock = data.prevBlock || null;
  this.nextBlock = data.nextBlock || null;
  this.currentBlock = data.currentBlock || null;
  this.iskeepChanged = data.iskeepChanged || false;
  this.wizardMsg = data.wizardMsg || null;
  /**store it with json string*/
  this.archiveData = data.archiveData || null;
  this.isError = data.isError || false;
};

import { JSILOGO, ACMELOGO, FoxLogo,FoxLogoSecondary } from "../../shared/AppImages";

export const GetOrgInfoWithLogo = (OrgUnits, selectedOrg) => {
  const orgInfo = [];
  if (OrgUnits && OrgUnits.length > 0) {
    OrgUnits.map((entity) => {
      orgInfo.push(orgLocationInfoModel(entity));
    });
  } else {
    orgInfo.push(orgLocationInfoModel(selectedOrg));
  }

  return selectedOrg && selectedOrg.Key
    ? orgInfo.filter((item) => String(item.OrgKey) === String(selectedOrg.Key))
    : orgInfo;
};

const orgLocationInfoModel = (entity) => {
  if (entity) {
    const OrgLogo =
      String(entity.Key) === "100"
        ? JSILOGO
        : String(entity.Key) === "10000775845"
        ? ACMELOGO
        : String(entity.Key) === "10002624203"
        ? FoxLogo
        : null;
    return {
      OrgKey: entity.Key,
      OrgName: entity.Value,
      OrgLogo: OrgLogo,
      dropdownLogo: String(entity.Key) === "10002624203" ? FoxLogoSecondary : OrgLogo,
    };
  } else {
    return {
      OrgKey: null,
      OrgName: null,
      OrgLogo: null,
    };
  }
};

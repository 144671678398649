import * as actionTypes from "../actions/actionTypes";
import { reducerUpdateObject } from "./reducer.utility";

const initialState = {
  agentResponse: null,
  error: null,
  loading: false
};
const getAgentStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};

const getAgentSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    agentResponse: action.agentResponse,
    error: null,
    loading: false
  });
};

const getAgentFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false
  });
};
const getAgentReset = (state, action) => {
  return reducerUpdateObject(state, { ...initialState });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_AGENT_START:
      return getAgentStart(state, action);
    case actionTypes.GET_AGENT_SUCCESS:
      return getAgentSuccess(state, action);
    case actionTypes.GET_AGENT_FAIL:
      return getAgentFail(state, action);
    case actionTypes.GET_AGENT_RESET:
      return getAgentReset(state, action);

    default:
      return state;
  }
};
export default reducer;

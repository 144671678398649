import { BUILD_VERSION_FOR, PERFORCE_BRANCH } from "../client/shared/GlobalVar";
import { isEmptyObject } from "../client/shared/utility";
import buildInfoJSON from "./application-build-info.json";
let buildInfo = !isEmptyObject(buildInfoJSON) ? buildInfoJSON.buildDetails : {};
export const BUILD_DATE = buildInfo.buildDate || "--";
export const BUILD_NUMBER = buildInfo.buildId || "--";
export const DEPLOYMENT_FOLDER = "";
export const VERSION_NUMBER =
  PERFORCE_BRANCH.ADMIN_VERSION +
  (BUILD_VERSION_FOR.DEVELOPMENT ? " - " + BUILD_VERSION_FOR.DEVELOPMENT : "");

export const RESERVE_SYNC_AUTO_REFRESH_TIME = 1200000;
//export const ZIP_CODE_STACK_API_URL = "https://api.zipcodestack.com";
export const ZIP_CODE_STACK_API_URL = "";
export const ZIP_CODE_STACK_API_KEY = "01H14FT3NF5JQJ9HZY4GEEXB2Q";

export const PARTNER_CODE = "FOXADMIN";
export const API_KEY = "C7914BF445D8855B98756D1BB3797";
export const SECRET_KEY = "mxjBz22grSSRVHxU3kJ+CA==";

export const ENVIRONMENT_MODE = "PRODUCTION";

/** Production -  Enable Following Line*/
// export const SERVER_API_URL = "enter_production_url+/services/callcenter";

/**PROD - Enable Following Line */
export const SERVER_API_URL = "/jsiws/services/callcenter";

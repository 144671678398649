import React, { Fragment, useEffect, useState } from "react";
import Draggable from "react-draggable";
import ReactHtmlParser from "react-html-parser";
import { useDispatch, useSelector } from "react-redux";
import Multiselect from "react-widgets/lib/Multiselect";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import CommonInputField from "../../../components/UI/Input/common-input-field";
import { LabelAndValuePairControl } from "../../../components/UI/Input/label-value-pair-control";
import { showNotification } from "../../../components/UI/Toaster/Toaster";
import CancelReservationRequest from "../../../services/entities/requests/CancelReservationRequest";
import { ModalType } from "../../../shared/GlobalVar";
import {
  formatCurrency,
  formatPricingValue,
  getBasicToastResponse,
  getErrorToastData,
  updateObject,
} from "../../../shared/utility";
import { createTooltip } from "../../../shared/utility/tooltip-utility";
import {
  cancelReservation,
  cancelReservationReset,
  getEnumvalues,
  updateCancellationReason,
  updateCancellationReasonReset,
} from "../../../store/actions/index";
import { validateCancelResReq } from "../reservation-helper";

export const CancelReservationModal = (props) => {
  const cancellationData = props.defaultData;
  const dispatch = useDispatch();
  const loading = useSelector(
    (state) =>
      state.reservationReducer.loading || state.enumvaluesReducer.loading
  );
  const reservationCancellationReasons = useSelector(
    (state) => state.enumvaluesReducer.reservationCancellationReasons
  );
  const cancelReservationResponse = useSelector(
    (state) => state.reservationReducer.cancelReservationResponse
  );
  const updateCancelletionResponse = useSelector(
    (state) => state.reservationReducer.updateCancelletionResponse
  );
  const [cancelReservationRequest, setCancelReservationRequest] = useState(
    new CancelReservationRequest()
  );
  const [multiSelectValues, setMultiSelectValues] = useState([]);
  const [isValidateDone, setIsValidateDone] = useState(false);
  const [
    isIntegrateWithExternalParameter,
    setIsIntegrateWithExternalParameter,
  ] = useState(cancellationData.IntegStatus === "SCHEDULED" ? true : false);
  useEffect(() => {
    if (!reservationCancellationReasons) {
      dispatch(getEnumvalues());
    } else {
      if (cancellationData.modalType === ModalType.CANCEL_REASON_UPDATE_MODAL) {
        setMultiSelectValues(
          cancellationData.cancellationReasons.map((item) => {
            return item.key;
          })
        );
      }
    }
    updateCancellationReq();
  }, [reservationCancellationReasons]);
  useEffect(() => {
    if (!cancelReservationResponse) {
      return;
    }
    showNotification(getBasicToastResponse(cancelReservationResponse));
    if (cancelReservationResponse.StatusCode === 0) {
      dispatch(cancelReservationReset());
      props.resetParentPage();
      props.closeModal();
    }
    dispatch(cancelReservationReset());
  }, [cancelReservationResponse]);

  useEffect(() => {
    if (!updateCancelletionResponse) {
      return;
    }
    showNotification(getBasicToastResponse(updateCancelletionResponse));
    if (updateCancelletionResponse.StatusCode === 0) {
      dispatch(updateCancellationReasonReset());
      props.resetParentPage();
      props.closeModal();
    }
    dispatch(updateCancellationReasonReset());
  }, [updateCancelletionResponse]);

  const updateCancellationReq = () => {
    let updateCtrls = { ...cancelReservationRequest };
    updateCtrls = updateObject(updateCtrls, {
      ReservationId: cancellationData.reservationId || null,
      CancelledBy: cancellationData.cancelledBy || null,
      CancellationFee: cancellationData.cancellationFee || null,
      CourtesyAmount: cancellationData.courtesyWeiver || null,
      Comments: cancellationData.cancellationNotes || null,
      MemberId: cancellationData.memberId || null,
    });
    dispatch(cancelReservationReset());
    setCancelReservationRequest(updateCtrls);
  };
  const getFormattedCancellationResons = () => {
    if (
      cancellationData.cancellationReasons &&
      cancellationData.cancellationReasons.length > 1
    ) {
      const reasonList = cancellationData.cancellationReasons.map(
        (reason, index) => {
          return <li>{reason.Value}</li>;
        }
      );
      return <ol>{reasonList}</ol>;
    } else if (cancellationData.cancellationReasons.length === 1) {
      return <label>{cancellationData.cancellationReasons[0].Value}</label>;
    }
    return "--";
  };
  const handleOnChange = (event, controlName, elementType) => {
    let updateCtrls = { ...cancelReservationRequest };
    const value =
      elementType === "checkbox" ? event.target.checked : event.target.value;
    if (controlName === "CourtesyApplied") {
      updateCtrls = updateObject(updateCtrls, {
        [controlName]: value,
        CourtesyAmount: "0.00",
      });
    } else if (controlName === "cbIntegStatus") {
      setIsIntegrateWithExternalParameter(value);
    } else {
      updateCtrls = updateObject(updateCtrls, {
        [controlName]: value,
      });
    }

    setCancelReservationRequest(updateCtrls);
  };
  const onReservationCancel = () => {
    let cancelResReq = { ...cancelReservationRequest };
    cancelResReq.CancellationReasons = multiSelectValues.map((element) => {
      return element.Name ? element.Name : "";
    });
    cancelResReq.CancellationFee = cancelResReq.CancellationFee
      ? parseFloat(cancelResReq.CancellationFee)
      : null;
    cancelResReq.IntegStatus = isIntegrateWithExternalParameter ? "SCHEDULED" : "SKIP_SYNC";
    const msg = validateCancelResReq(cancelResReq);
    if (!msg) {
      setIsValidateDone(false);
      dispatch(cancelReservationReset());
      dispatch(cancelReservation(cancelResReq));
    } else {
      showNotification(getErrorToastData(msg));
      setIsValidateDone(true);
    }
  };
  const updateCancellationReasons = () => {
    const updateReasonObj = {};
    updateReasonObj.ReservationId = cancelReservationRequest.ReservationId;
    updateReasonObj.CancellationReasons = multiSelectValues.map((element) => {
      return element.Name ? element.Name : "";
    });
    updateReasonObj.Comments = cancelReservationRequest.Comments;
    dispatch(updateCancellationReasonReset());
    dispatch(updateCancellationReason(updateReasonObj));
  };
  const getCancelByList = [
    { key: "", displayValue: "Select" },
    { key: "ORG_NAME", displayValue: "FOX" },
    { key: "CUSTOMER", displayValue: "CUSTOMER" },
    { key: "JSI_PERSON", displayValue: "JustShareIt" },
  ];

  const labelCaptionClass = "col-lg-4";
  const labelValueClass = "col-lg-8";
  const labelCaptionClassBold =
    labelCaptionClass + " col-form-label font-weight-bold";
  let isEditModeModal =
    cancellationData.modalType === ModalType.CANCEL_RESERVATION_MODAL;
  let parentClasses = "form-group row p-0";
  const renderCurrencyInput = (controlName, value, disable) => {
    return (
      <Fragment>
        <span className="pr-3">$</span>
        <div className="col-sm-3 p-0">
          <CommonInputField
            elementConfig={{
              type: "number",
              name: controlName,
              disabled: disable,
              value: value || "",
            }}
            nodeElement={"input"}
            onChange={handleOnChange}
          />
        </div>
      </Fragment>
    );
  };
  const renderCheckboxes = (labelCaption, id, value, hasCurrencyField) => {
    const isIntegStatus = id === "cbIntegStatus";
    const valueClasses = isIntegStatus ? "" : "col-sm-4 pl-3";
    parentClasses = isIntegStatus ? "" : parentClasses;
    return (
      <div className={parentClasses}>
        <CommonInputField
          elementConfig={{
            id: id,
            type: "checkbox",
            name: id,
            checked: value,
          }}
          otherConfig={{
            caption: labelCaption,
            isHideLabel: true,
            checkBoxCaptionClass:
              "font-weight-bold " + (isIntegStatus ? "line-height-1 fs-5" : ""),
          }}
          nodeElement="checkbox"
          onChange={handleOnChange}
          valueClasses={valueClasses}
        />
        {hasCurrencyField
          ? renderCurrencyInput(
            "CourtesyAmount",
            cancelReservationRequest.CourtesyAmount,
            !cancelReservationRequest.CourtesyApplied
          )
          : ""}
      </div>
    );
  };
  const renderLabelInputPair = (
    labelCaption,
    controlName,
    value,
    viewOnly = false,
    isRequired = false,
    nodeElement = "input",
    dropdownItem = null
  ) => {
    const isDateField = controlName === "CancelledOn";
    const disabledProps =
      controlName === "Comments"
        ? { disabled: !cancellationData.isEditable }
        : {};
    return (
      <LabelAndValuePairControl
        elementConfig={{
          name: controlName,
          value: value || "",
          ...disabledProps,
        }}
        otherConfig={{
          caption: labelCaption,
          viewOnly: viewOnly,
          isDateField: isDateField,
          isWithDateTime: true,
          isForceShow: isDateField,
          isRequired: isRequired,
          value: value || "",
          dropdownItem: nodeElement === "select" ? dropdownItem : [],
        }}
        isEditMode={true}
        onChange={handleOnChange}
        parentRowClass={`form-group row  ${isRequired ? "required" : ""}`}
        labelClasses={labelCaptionClassBold}
        valueClasses={labelValueClass}
        nodeElement={nodeElement}
        isValidateDone={isValidateDone}
      />
    );
  };
  return (
    <Draggable handle=".cursor">
      <Modal
        isOpen={props.showModal}
        className="no-cursor"
        toggle={() => {
          props.closeModal();
        }}
        style={{
          minWidth: cancellationData.isEditable ? "80rem" : "60rem",
        }}
      >
        <ModalHeader
          className="modal-header bg-primary modal-title text-white cursor"
          toggle={() => {
            props.closeModal();
          }}
        >
          <i className="fas fa-arrows-alt" /> {cancellationData.modalTitle}
        </ModalHeader>
        <ModalBody
          style={{
            maxHeight: "60rem",
          }}
        >
          <div className="col-lg-12 p-0" id="cancelled-reservation-modal">
            {isEditModeModal ? (
              renderLabelInputPair(
                "Cancelled By",
                "CancelledBy",
                cancelReservationRequest.CancelledBy,
                false,
                true,
                "select",
                getCancelByList
              )
            ) : (
              <Fragment>
                {renderLabelInputPair(
                  "Cancelled On",
                  "CancelledOn",
                  cancellationData.cancelledOnDisplayText,
                  true
                )}
                {renderLabelInputPair(
                  "Cancelled By",
                  "cancelledBy",
                  cancellationData.cancelledBy,
                  true
                )}
              </Fragment>
            )}
            <div
              className={
                isEditModeModal ? parentClasses + " required" : parentClasses
              }
            >
              <label className={labelCaptionClassBold}>
                Cancellation Reason(s)
              </label>
              <div
                className={`${labelValueClass} ${multiSelectValues.length
                    ? ""
                    : isValidateDone
                      ? "validation-failed"
                      : ""
                  }`}
              >
                {cancellationData.isEditable ? (
                  <Multiselect
                    data={reservationCancellationReasons || []}
                    valueField="Name"
                    textField="DisplayName"
                    value={multiSelectValues}
                    onChange={(value) => setMultiSelectValues(value)}
                  />
                ) : (
                  getFormattedCancellationResons()
                )}
              </div>
            </div>
            {renderLabelInputPair(
              "Comments",
              "Comments",
              cancelReservationRequest.Comments,
              false,
              isEditModeModal,
              "textarea"
            )}
            {isEditModeModal ? (
              <Fragment>
                <div className={parentClasses}>
                  <label className={labelCaptionClassBold + " position--unset"}>
                    <span id="cancellationFeePolicyId" className="pseudo-link">
                      {createTooltip(
                        "Cancellation Fee",
                        ReactHtmlParser(cancellationData.cancellationFeePolicy),
                        { forceShow: true, tooltipMaxWidth: "60rem" }
                      )}
                    </span>
                  </label>
                  {renderCurrencyInput(
                    "CancellationFee",
                    cancelReservationRequest.CancellationFee,
                    cancelReservationRequest.CourtesyApplied
                  )}
                </div>
                {renderCheckboxes(
                  "Courtesy Waiver",
                  "CourtesyApplied",
                  cancelReservationRequest.CourtesyApplied,
                  true
                )}
                {renderCheckboxes(
                  "Refunded On Credit Card (" + formatCurrency(cancellationData.RefundableAmountCC) + ")",
                  "RefundCreditCardPayments",
                  cancelReservationRequest.RefundCreditCardPayments
                )}
                {renderCheckboxes(
                  "Refund Credits (" + formatCurrency(cancellationData.RefundableCredits) + ")",
                  "RefundAppliedCredits",
                  cancelReservationRequest.RefundAppliedCredits
                )}
                {renderCheckboxes(
                  "Override Email Notification",
                  "OverrideEmail",
                  cancelReservationRequest.OverrideEmail
                )}
              </Fragment>
            ) : (
              <Fragment>
                {renderLabelInputPair(
                  "Cancellation Fee",
                  "totalCancellationFee",
                  formatPricingValue(
                    cancellationData.totalCancellationFee,
                    "$0.00"
                  ),
                  true
                )}
                {renderLabelInputPair(
                  "Courtesy Waiver",
                  "cancellationCourtesyAmount",
                  formatPricingValue(
                    cancellationData.cancellationCourtesyAmount,
                    "$0.00"
                  ),
                  true
                )}
              </Fragment>
            )}
          </div>
          {isEditModeModal ? (
            <div className="col-lg-12 text-center">
              {renderCheckboxes(
                "Integrate with External Partner",
                "cbIntegStatus",
                isIntegrateWithExternalParameter
              )}
            </div>
          ) : (
            ""
          )}
        </ModalBody>
        {cancellationData.isEditable ? (
          <ModalFooter>
            <div className="col-lg-12 text-right">
              {isEditModeModal ? (
                <button
                  type="button"
                  name="btnReservationCancel"
                  className="btn btn-primary waves-effect waves-light btnSize ml-2"
                  onClick={() => onReservationCancel()}
                >
                  Confirm
                </button>
              ) : (
                <button
                  type="button"
                  name="btnReservationCancel"
                  className="btn btn-primary waves-effect waves-light btnSize ml-2"
                  onClick={() => updateCancellationReasons()}
                >
                  Update
                </button>
              )}
              <button
                type="button"
                name="btnCancel"
                className="btn btn-secondary waves-effect waves-light btnSize ml-2"
                onClick={props.closeModal}
              >
                Cancel
              </button>
            </div>
          </ModalFooter>
        ) : (
          ""
        )}
      </Modal>
    </Draggable>
  );
};
export default CancelReservationModal;

import { gettimeZoneWiseCurrentStartDateEndate } from "../../../../shared/utility/date-time-utility";
import {
  AdminDateFromat,
  ReserveSyncDefaultVal,
} from "../../../../shared/utility/enum-utility";
import { FoxRequestHeader } from "../../fox-api/domain/fox-request-header";

const ReserveSyncSearchRequest = function (data, dataList) {
  data = data || {};
  const timeZoneData = gettimeZoneWiseCurrentStartDateEndate(
    data.locationId,
    dataList,
    ReserveSyncDefaultVal.totalDaysPerPeriod - 1,
    true,
    AdminDateFromat.FOX_API_ONLY_DATE
  );
  const pagination = {
    pageNumber: 0,
    perPageCount: 2000,
    sortBy: "forecastStartDate",
    sortType: "ASC",
  };
  this.requestHeader = new FoxRequestHeader({ requestType: "GET" });
  this.forecastStartDate = data.forecastStartDate || timeZoneData.formattedStartDate;
  this.forecastEndDate = data.forecastEndDate || timeZoneData.formattedEndDate;
  this.locationId = data.locationId || null;
  this.orgUnitFleetClassGroupId = data.orgUnitFleetClassGroupId || null;
  this.pagination = data.pagination
    ? { ...data.pagination }
    : { ...pagination };
};
export default ReserveSyncSearchRequest;

const FavouriteVehicleSearchModel = function (data) {
  if (!data) data = {};
  this.ApprovalMode = data.ApprovalMode || null;
  this.AssetGroupType = data.AssetGroupType || null;
  this.DailyRate = data.DailyRate || null;
  this.DistanceFromUser = data.DistanceFromUser || null;
  this.DistanceUnit = data.DistanceUnit || null;
  this.DrivingDuration = data.DrivingDuration || null;
  this.Favorite = data.Favorite || false;
  this.FuelIncluded = data.FuelIncluded || false;
  this.FuelType = data.FuelType || null;

  this.HalfDayRate = data.HalfDayRate || null;

  this.HourlyRate = data.HourlyRate || null;

  this.Image = data.Image || null;
  this.InstantRentalEnabled = data.InstantRentalEnabled || null;
  this.Make = data.Make || null;
  this.MemberAssetId = data.MemberAssetId || null;
  this.Model = data.Model || null;
  this.Name = data.Name || null;
  this.OwnerEmailAddress = data.OwnerEmailAddress || null;
  this.OwnerName = data.OwnerName || null;
  this.QrCode = data.QrCode || null;
  this.RateUnit = data.RateUnit || null;

  this.RideLinkInstalled = data.RideLinkInstalled || false;
  this.SharerDailyRate = data.SharerDailyRate || null;
  this.SharerHalfDayRate = data.SharerHalfDayRate || null;
  this.SharerHourlyRate = data.SharerHourlyRate || null;
  this.SharerWeekendPrice = data.SharerWeekendPrice || null;
  this.ShowOriginalPrice = data.ShowOriginalPrice || false;
  this.ShowSharerPrice = data.ShowSharerPrice || false;

  this.TransmissionType = data.TransmissionType || null;
  this.WalkingDuration = data.WalkingDuration || null;
  this.WeekendPrice = data.WeekendPrice || null;
  this.Year = data.Year || null;
};
export default FavouriteVehicleSearchModel;

import React, { Fragment, useEffect, useState } from "react";
import Draggable from "react-draggable";
import ReactHtmlParser from "react-html-parser";
import { useDispatch, useSelector } from "react-redux";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { LabelAndValuePairControl } from "../../../components/UI/Input/label-value-pair-control";
import { showNotification } from "../../../components/UI/Toaster/Toaster";
import { NoUserPhoto } from "../../../shared/AppImages";
import { ADMIN_COMMON_CAPTION } from "../../../shared/GlobalVar";
import PermissionName from "../../../shared/Permissions";
import {
  getBasicToastResponse,
  getDateOnlyString,
  getFullName,
  getPhoneNumberText,
  hasPermission,
} from "../../../shared/utility";
import { getMemberLabelByOrgRentalType } from "../../../shared/utility/system-utility";
import {
  getMember,
  getMemberAddresseses,
  getMemberAddressesesReset,
  getMemberReset,
} from "../../../store/actions/index";
import { createMemberReqForQuickModal } from "../MemberHelper";

export const MemberQuickModal = (props) => {
  const loading = useSelector(
    (state) => state.memberReducer.loading || state.addressReducer.loading
  );
  const memberDataResponse = useSelector(
    (state) => state.memberReducer.memberData
  );
  const memberAddressResponse = useSelector(
    (state) => state.addressReducer.memberAddressResponse
  );
  const dispatch = useDispatch();
  const memberId = props.memberId;
  const [memberData, setMemberData] = useState(null);
  const [addressResponse, setAddressResponse] = useState(null);
  const [showAllAddresses, setShowAllAddresses] = useState(false);
  const hasViewLicensePermission = hasPermission(PermissionName.VIEW_DRIVING_LICENSE);
  useEffect(() => {
    dispatch(getMemberReset());
    dispatch(getMemberAddressesesReset());
    dispatch(getMember(createMemberReqForQuickModal(memberId)));
    dispatch(getMemberAddresseses({ MemberId: memberId }));
  }, []);
  useEffect(() => {
    if (!memberDataResponse) {
      return;
    }
    if (memberDataResponse && memberDataResponse.StatusCode === 0) {
      setMemberData(memberDataResponse.Member);
    } else {
      showNotification(getBasicToastResponse(memberDataResponse));
      dispatch(getMemberReset());
      props.closeModal();
    }
  }, [memberDataResponse]);
  useEffect(() => {
    if (!addressResponse) {
      return;
    }
    if (addressResponse && addressResponse.StatusCode === 0) {
      setAddressResponse(addressResponse);
    } else {
      showNotification(getBasicToastResponse(addressResponse));
      dispatch(getMemberAddressesesReset());
    }
  }, [addressResponse]);
  const getAddressesbyAddressType = () => {
    const homeAddressList = [];
    const workAddressList = [];
    const otherAddressList = [];
    if (memberAddressResponse && memberAddressResponse.Addresses) {
      memberAddressResponse.Addresses.map((addressObj) => {
        if (addressObj.AddressType === "HOME") {
          homeAddressList.push(addressObj);
        } else if (addressObj.AddressType === "WORK") {
          workAddressList.push(addressObj);
        } else {
          otherAddressList.push(addressObj);
        }
      });
    }
    return {
      homeAddressList: [...homeAddressList],
      workAddressList: [...workAddressList],
      otherAddressList: [...otherAddressList],
    };
  };
  const getAddressRow = (addressData) => {
    let renderableHtml;
    if (addressData.length === 1) {
      renderableHtml = addressData.map((addressObj) => {
        return getAddressRowHtml(addressObj);
      });
      return renderableHtml;
    } else {
      renderableHtml = addressData.map((addressObj, index) => {
        return getAddressTableRowHtml(addressObj, index);
      });
      return (
        <table className="table table-striped">
          <tbody>{renderableHtml}</tbody>
        </table>
      );
    }
  };
  const getAddressTableRowHtml = (addressData, index) => {
    const renderableHtml = (
      <tr key={addressData.AddressId}>
        <td
          className="font-weight-bold"
          style={{
            borderLeft: "none",
            textAlign: "left",
            verticalAlign: "top",
            width: "1.8rem",
          }}
        >
          {index + 1}.
        </td>
        <td style={{ textAlign: "left" }}>{addressData.FormattedAddress}</td>
      </tr>
    );
    return renderableHtml;
  };
  const getAddressRowHtml = (addressData) => {
    const renderableHtml = (
      <div className="form-group row" key={addressData.AddressId}>
        <label
          htmlFor="newres-CityId"
          className="col-sm-11  col-form-label pl-2 pr-0"
        >
          {addressData.FormattedAddress}
        </label>
        <label
          htmlFor="newres-CityId"
          className="col-sm-1 col-form-label pl-0 pr-2 text-right"
          style={{ width: "5rem" }}
        ></label>
      </div>
    );
    return renderableHtml;
  };

  const renderLabelInputPair = (
    labelCaption,
    value,
    isTooltipEnabled = false,
    tooltipValue = "",
    isTooltipForceShow = false
  ) => {
    return (
      <LabelAndValuePairControl
        elementConfig={{ value: value }}
        otherConfig={{
          caption: labelCaption,
          toolTipMsg: isTooltipEnabled ? tooltipValue : "",
          isForceShow: isTooltipForceShow,
        }}
        parentRowClass="popup-row"
        labelClasses="font-weight-bold"
      />
    );
  };

  const randomAddressObj = getAddressesbyAddressType();
  const memberFullName = memberData
    ? getFullName(
      memberData.FirstName,
      memberData.LastName,
      memberData.MiddleName
    )
    : "";
  const renderLicenseSection = () => {
    return (
      <>
        <h4 className="form-header text-uppercase">
          <i className="fas fa-user-circle"></i>License
        </h4>
        {renderLabelInputPair(
          "Country",
          memberData?.DrivingLicense?.DrivingLicenseCountryText || "--"
        )}
        {renderLabelInputPair(
          "State",
          memberData?.DrivingLicense?.DrivingLicenseStateText || "--"
        )}
        {renderLabelInputPair(
          "Number",
          memberData?.DrivingLicense?.DrivingLicenseNumber || "--"
        )}
        {renderLabelInputPair(
          "Expiration Date",
          memberData.DrivingLicense
            ? getDateOnlyString(
              memberData.DrivingLicense.LicenseExpirationDate
            )
            : "--"
        )}
        {renderLabelInputPair(
          "Verification Status",
          memberData.DrivingLicense
            ? memberData.DrivingLicense.DrivingRecordChecked ||
            "NOT PERFORMED"
            : "NOT PERFORMED"
        )}
        {renderLabelInputPair(
          "Violation Point",
          memberData.DrivingLicense
            ? memberData.DrivingLicense.ViolationPoints || "0"
            : ""
        )}
      </>
    )
  }
  return memberData ? (
    <Draggable handle=".cursor">
      <Modal
        isOpen={props.showModal}
        className="no-cursor"
        style={{ minWidth: "90rem" }}
        toggle={() => {
          props.closeModal();
        }}
      >
        <ModalHeader
          className="modal-header bg-primary modal-title text-white cursor"
          toggle={() => {
            props.closeModal();
          }}
        >
          <i className="fas fa-user-tie pr-2"></i>
          {getMemberLabelByOrgRentalType()} Quick View
        </ModalHeader>
        <ModalBody
          style={{
            minHeight: "33rem",
          }}
        >
          <div
            id="member-quick-view"
            className="row w-100 tableScroll bb-0"
            style={{
              minHeight: "32rem",
            }}
          >
            <div className="w-50 pl-4 pr-4">
              <h4 className="form-header text-uppercase">
                <i className="fas fa-user-circle"></i>Personal
              </h4>
              {renderLabelInputPair(
                ADMIN_COMMON_CAPTION.CUSTOMER_ID,
                memberData.MemberId
                  ? memberData.MembershipId + " / " + memberData.MemberId
                  : "--"
              )}
              {renderLabelInputPair("Name", memberFullName, true)}
              {renderLabelInputPair("DOB", getDateOnlyString(memberData.Dob))}
              {renderLabelInputPair("Email", memberData.EmailAddress, true)}
              {renderLabelInputPair(
                "Phone",
                getPhoneNumberText(memberData, false),
                true,
                ReactHtmlParser(getPhoneNumberText(memberData, true)),
                true
              )}
              <div className="col-lg-12 p-0">
                <h4 className="form-header text-uppercase">
                  <i className="fas fa-address-book"></i>Primary Address
                </h4>
                {memberAddressResponse && memberAddressResponse.PrimaryAddress
                  ? getAddressRowHtml(memberAddressResponse.PrimaryAddress)
                  : "No primary address found."}
                <h4 className="form-header text-uppercase">
                  <i className="fas fa-address-book"></i>Credit Card Address
                </h4>
                {memberAddressResponse &&
                  memberAddressResponse.CreditCardAddress
                  ? getAddressRowHtml(memberAddressResponse.CreditCardAddress)
                  : "No credit card address found."}
                {randomAddressObj.homeAddressList.length > 0 ||
                  randomAddressObj.workAddressList.length > 0 ||
                  randomAddressObj.otherAddressList.length > 0 ? (
                  <div className="form-group text-right">
                    <label
                      className="pseudo-link show-more-link"
                      onClick={() => setShowAllAddresses(!showAllAddresses)}
                    >
                      {showAllAddresses
                        ? "« Show Less Addresses"
                        : "« Show All Address"}
                    </label>
                  </div>
                ) : (
                  ""
                )}
                {showAllAddresses ? (
                  <Fragment>
                    <h4 className="form-header text-uppercase">
                      <i className="fas fa-address-book"></i>Home Address
                    </h4>
                    {randomAddressObj.homeAddressList.length > 0
                      ? getAddressRow(randomAddressObj.homeAddressList)
                      : "No home address found."}
                    <h4 className="form-header text-uppercase">
                      <i className="fas fa-address-book"></i>Work Address
                    </h4>
                    {randomAddressObj.workAddressList.length > 0
                      ? getAddressRow(randomAddressObj.workAddressList)
                      : "No work address found"}
                    <h4 className="form-header text-uppercase">
                      <i className="fas fa-address-book"></i>Other Address(s)
                    </h4>
                    {randomAddressObj.otherAddressList.length > 0
                      ? getAddressRow(randomAddressObj.otherAddressList)
                      : "No other address(s) found."}
                  </Fragment>
                ) : (
                  ""
                )}
              </div>
            </div>
            {memberData.IsEmployee ? (
              <div className="w-50 pl-4 pr-4">
                <h4 className="form-header text-uppercase">
                  <i className="fas fa-user-circle"></i>Employee Info
                </h4>
                {renderLabelInputPair(
                  "Employee #",
                  memberData.Employee ? memberData.Employee.EmployeeNo : "--"
                )}
                {renderLabelInputPair(
                  "Badge #",
                  memberData.Employee ? memberData.Employee.BadgeNo : "--"
                )}
                {renderLabelInputPair(
                  "Title",
                  memberData.Employee ? memberData.Employee.Title : "--"
                )}
                {renderLabelInputPair(
                  "Department",
                  memberData.Employee ? memberData.Employee.Department : "--"
                )}
                {renderLabelInputPair(
                  "Location",
                  memberData.Employee ? memberData.Employee.LocationName : "--"
                )}
                {renderLabelInputPair(
                  "Company",
                  memberData.Employee ? memberData.Employee.CompanyName : "--"
                )}
                <h4 className="form-header text-uppercase">
                  <i className="fas fa-user-circle"></i>Employee Photo
                </h4>
                <div className="popup-row">
                  <div className="user-fullimage text-center">
                    <img
                      src={memberData.ProfileImageUrl || NoUserPhoto}
                      alt={getMemberLabelByOrgRentalType()}
                      className="card-img-top"
                      style={{
                        width: "10rem",
                        height: "10rem",
                        padding: "1rem",
                      }}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div className="col-lg-6">
                <h4 className="form-header text-uppercase">
                  <i className="fas fa-user-circle"></i>Insurance
                </h4>
                {renderLabelInputPair(
                  "Is Insured",
                  memberData.Insurance
                    ? memberData.Insurance.InsuranceVerified
                    : "--"
                )}
                {hasViewLicensePermission && renderLicenseSection()}
                <h4 className="form-header text-uppercase">
                  <i className="fas fa-user-circle"></i>Notes
                </h4>
                <div className="popup-row">{memberData.ProfileNotes || ""}</div>
              </div>
            )}
          </div>
          {/* {loading ? <Spinner /> : ""} */}
        </ModalBody>
      </Modal>
    </Draggable>
  ) : (
    ""
  );
};
export default MemberQuickModal;

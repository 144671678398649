import ReactHtmlParser from "react-html-parser";
import GetMemberOptions from "../../services/entities/models/GetMemberOptions";
import Person from "../../services/entities/models/Person";
import AddEditMemberRequest from "../../services/entities/requests/AddEditMemberRequest";
import { AddQuickMemberRequest } from "../../services/entities/requests/AddQuickMemberRequest";
import {
  AcceptedCardType,
  COMMON_MSG,
  ModalType,
} from "../../shared/GlobalVar";
import PermissionName from "../../shared/Permissions";
import {
  getFormattedValidationMsg,
  getFullName,
  hasPermission,
  isContainedAllowedCharacters,
  isEmail,
  isNullOrEmpty,
  specialCharsList,
  validatePhoneNo,
} from "../../shared/utility";
import { isValidateEmployeeNoBadgeNoInput } from "../Settings/settingsHelper";

const hasViewLicensePermission = hasPermission(
  PermissionName.VIEW_DRIVING_LICENSE
);
export const createMemberReqForProfile = (memId) => {
  return {
    MemberId: memId,
    Option: {
      License: hasViewLicensePermission,
      Insurance: true,
      CreditCard: true,
      Employee: true,
      CountryCodes: false,
      Assets: true,
      Contact: false,
      Preferences: false,
      Earnings: false,
      Referals: false,
      Rating: true,
      PrimaryStateCityZipList: false,
      BillingStateCityZipList: false,
      LicenseStateList: false,
    },
  };
};
export const createMemberReqForSummary = (memId) => {
  return {
    MemberId: memId,
    Option: {
      Assets: false,
      Contact: false,
      BillingStateCityZipList: false,
      PrimaryStateCityZipList: false,
      LicenseStateList: false,
      CountryCodes: false,
      CreditCard: false,
      Employee: false,
      Insurance: false,
      License: false,
      Preferences: true,
      Rating: true,
      Referals: true,
      Earnings: true,
    },
  };
};
export const createMemberReqForQuickModal = (memId) => {
  return {
    MemberId: memId,
    Option: {
      Employee: true,
      Insurance: true,
      License: hasViewLicensePermission,
      CreditCard: false,
      Assets: false,
      Contact: false,
      BillingStateCityZipList: false,
      PrimaryStateCityZipList: false,
      LicenseStateList: false,
      CountryCodes: false,
      Preferences: false,
      Rating: false,
      Referals: false,
      Earnings: false,
    },
  };
};
export const createAddEditMemberRequest = (
  memberInputReqest,
  overrideOptions,
  isEdit
) => {
  const personObj = { ...memberInputReqest };
  const overrideOptionsMem = { ...overrideOptions };
  let getMemberOptions;
  if (isEdit) {
    getMemberOptions = new GetMemberOptions();
    getMemberOptions.Employee = true;
    getMemberOptions.License = true;
    getMemberOptions.Insurance = true;
    getMemberOptions.CreditCard = true;
  }
  return new AddEditMemberRequest(
    personObj,
    overrideOptionsMem,
    getMemberOptions
  );
};
export const createAddEditQuickMemberRequest = (memberInputReqest) => {
  const quickMemberRequest = {};
  if (memberInputReqest.MemberId) {
    quickMemberRequest.MemberId = memberInputReqest.MemberId;
  }
  if (memberInputReqest.PersonBlackListId) {
    quickMemberRequest.PersonBlackListId = memberInputReqest.PersonBlackListId;
  }
  quickMemberRequest.FirstName = memberInputReqest.FirstName;
  quickMemberRequest.MiddleName = memberInputReqest.MiddleName;
  quickMemberRequest.LastName = memberInputReqest.LastName;
  quickMemberRequest.EmailAddress = memberInputReqest.EmailAddress;
  quickMemberRequest.MobilePhone = { ...memberInputReqest.MobilePhone };
  if (memberInputReqest.Reason) {
    quickMemberRequest.Reason = memberInputReqest.Reason;
  }
  if (memberInputReqest.DrivingLicense) {
    quickMemberRequest.DateOfBirth =
      memberInputReqest.DrivingLicense.DateOfBirth;
    quickMemberRequest.License =
      memberInputReqest.DrivingLicense.DrivingLicenseNumber;
    quickMemberRequest.LicenseCountryId =
      memberInputReqest.DrivingLicense.DrivingLicenseCountryId;
    quickMemberRequest.LicenseStateId =
      memberInputReqest.DrivingLicense.DrivingLicenseStateId;
    quickMemberRequest.LicenseStateText =
      memberInputReqest.DrivingLicense.LicenseStateText;
  }
  if (memberInputReqest.PrimaryAddress) {
    quickMemberRequest.CountryId = memberInputReqest.PrimaryAddress.Country;
    quickMemberRequest.StateId = memberInputReqest.PrimaryAddress.State;
    quickMemberRequest.StateText = memberInputReqest.PrimaryAddress.StateText;
    quickMemberRequest.CityId = memberInputReqest.PrimaryAddress.City;
    quickMemberRequest.CityText = memberInputReqest.PrimaryAddress.CityText;
    quickMemberRequest.ZipCodeId = memberInputReqest.PrimaryAddress.ZipCode;
    quickMemberRequest.ZipCodeText =
      memberInputReqest.PrimaryAddress.ZipCodeText;
  }
  return new AddQuickMemberRequest(quickMemberRequest);
};
export const convertDataToPersonObj = (memberData) => {
  const personObj = new Person();
};
export const validateAddEditQuickMember = (memberData) => {
  let msg = "";
  if (memberData) {
    if (!memberData.FirstName) {
      if (msg) {
        msg += ", ";
      }
      msg += COMMON_MSG.FirstName;
    }
    if (!memberData.LastName) {
      if (msg) {
        msg += ", ";
      }
      msg += COMMON_MSG.LastName;
    }
    if (memberData.DrivingLicense) {
      const drivingLicense = { ...memberData.DrivingLicense };
      if (!drivingLicense.DrivingLicenseCountryId) {
        if (msg) {
          msg += ", ";
        }
        msg += COMMON_MSG.LicenseCountry;
      }
      if (
        !drivingLicense.DrivingLicenseStateId &&
        !drivingLicense.DrivingLicenseStateText
      ) {
        if (msg) {
          msg += ", ";
        }
        msg += COMMON_MSG.LicenseState;
      }
      if (!drivingLicense.DrivingLicenseNumber) {
        if (msg) {
          msg += ", ";
        }
        msg += COMMON_MSG.LicenseNo;
      }
      if (!drivingLicense.LicenseExpirationDate) {
        if (msg) {
          msg += ", ";
        }
        msg += COMMON_MSG.LicenseExpDate;
      }
    } else {
      if (msg) {
        msg += ", ";
      }
      msg += "License Fields Required.";
    }
  }
  if (msg) {
    return getFormattedValidationMsg(msg);
  }
};
export const validateAddEditMember = (memberData) => {
  let msg = "";
  if (memberData) {
    if (!memberData.FirstName) {
      if (msg) {
        msg += ", ";
      }
      msg += COMMON_MSG.FirstName;
    }
    if (!memberData.LastName) {
      if (msg) {
        msg += ", ";
      }
      msg += COMMON_MSG.LastName;
    }
    if (!isEmail(memberData.EmailAddress)) {
      if (msg) {
        msg += ", ";
      }
      msg += COMMON_MSG.EmailAddress;
    }
    if (!memberData.AddressType) {
      if (msg) {
        msg += ", ";
      }
      msg += COMMON_MSG.AddressType;
    }
    if (!memberData.PrimaryCountry) {
      if (msg) {
        msg += ", ";
      }
      msg += COMMON_MSG.Country;
    }

    if (!memberData.PrimaryStreetAddress1) {
      if (msg) {
        msg += ", ";
      }
      msg += COMMON_MSG.Address1;
    }

    if (!memberData.PrimaryState && !memberData.PrimaryStateStr) {
      if (msg) {
        msg += ", ";
      }
      msg += COMMON_MSG.State;
    }

    if (!memberData.PrimaryCity && !memberData.PrimaryCityStr) {
      if (msg) {
        msg += ", ";
      }
      msg += COMMON_MSG.City;
    }

    if (!memberData.PrimaryZipCode && !memberData.PrimaryZipCodeStr) {
      if (msg) {
        msg += ", ";
      }
      msg += COMMON_MSG.ZipPostal;
    }
    if (!memberData.OverrideLicenseAndState) {
      if (!memberData.DrivingLicenseCountry) {
        if (msg) {
          msg += ", ";
        }
        msg += COMMON_MSG.LicenseCountry;
      }
      if (
        !memberData.DrivingLicenseState &&
        !memberData.DrivingLicenseStateText
      ) {
        if (msg) {
          msg += ", ";
        }
        msg += COMMON_MSG.LicenseState;
      }
      if (!memberData.DrivingLicenseNumber) {
        if (msg) {
          msg += ", ";
        }
        msg += COMMON_MSG.LicenseNo;
      }
      if (!memberData.DateOfBirth) {
        if (msg) {
          msg += ", ";
        }
        msg += COMMON_MSG.DOB;
      }
      if (!memberData.DrivingLicenseExpirationDate) {
        if (msg) {
          msg += ", ";
        }
        msg += COMMON_MSG.LicenseExpDate;
      }
    }
    if (!memberData.OverrideCreditCardInformation) {
      if (
        memberData.NameOnCreditCard ||
        memberData.CreditCardNumber ||
        memberData.ExpirationMonth ||
        memberData.ExpirationYear ||
        (memberData.CreditCardType !== "NONE" &&
          memberData.CreditCardType !== null) ||
        memberData.CreditCardCVV ||
        memberData.CreditCardAddressCountry
      ) {
        if (!memberData.NameOnCreditCard) {
          if (msg) {
            msg += ", ";
          }
          msg += COMMON_MSG.NameOnCreditCard;
        }
        if (!memberData.CreditCardNumber) {
          if (msg) {
            msg += ", ";
          }
          msg += COMMON_MSG.CreditCardNumber;
        }
        if (!memberData.ExpirationMonth || !memberData.ExpirationYear) {
          if (msg) {
            msg += ", ";
          }
          msg += COMMON_MSG.CreditCardExpDate;
        }
        if (!memberData.CreditCardType) {
          if (msg) {
            msg += ", ";
          }
          msg += COMMON_MSG.CreditCardType;
        }
        if (!memberData.CreditCardCVV) {
          if (msg) {
            msg += ", ";
          }
          msg += COMMON_MSG.CVV;
        }
        if (!memberData.CreditCardStreetAddress1) {
          if (msg) {
            msg += ", ";
          }
          msg += COMMON_MSG.Street;
        }
        if (!memberData.CreditCardAddressCountry) {
          if (msg) {
            msg += ", ";
          }
          msg += COMMON_MSG.CreditCardAddressCountry;
        }
        if (
          !memberData.CreditAddressState &&
          !memberData.CreditCardAddressStateStr
        ) {
          if (msg) {
            msg += ", ";
          }
          msg += COMMON_MSG.CreditCardAddressState;
        }
        if (
          !memberData.CreditCardAddressCity &&
          !memberData.CreditCardAddressCityStr
        ) {
          if (msg) {
            msg += ", ";
          }
          msg += COMMON_MSG.CreditCardAddressCity;
        }
        if (
          !memberData.CreditCardAddressZipCode &&
          !memberData.CreditCardAddressZipCodeStr
        ) {
          if (msg) {
            msg += ", ";
          }
          msg += COMMON_MSG.CreditCardAddressZip;
        }
      }
    }
    if (memberData.IsEmployee) {
      if (isNullOrEmpty(memberData.EmployeeNo)) {
        if (msg) {
          msg += ", ";
        }
        msg += COMMON_MSG.EmployeeCodeRequiredMemberProfile;
      }
      if (isNullOrEmpty(memberData.BadgeNo)) {
        if (msg) {
          msg += ", ";
        }
        msg += COMMON_MSG.EmployeeBadgeNo;
      }
      if (isNullOrEmpty(memberData.Title)) {
        if (msg) {
          msg += ", ";
        }
        msg += COMMON_MSG.EmployeeTitle;
      }
      if (isNullOrEmpty(memberData.Department)) {
        if (msg) {
          msg += ", ";
        }
        msg += COMMON_MSG.DepartmentRequiredMemberProfile;
      }
      if (isNullOrEmpty(memberData.CompanyName)) {
        if (msg) {
          msg += ", ";
        }
        msg += COMMON_MSG.EmployeeCompany;
      }
      if (isNullOrEmpty(memberData.LocationId)) {
        if (msg) {
          msg += ", ";
        }
        msg += COMMON_MSG.LocationId;
      }
    }
    if (isNullOrEmpty(memberData.PrimaryPhone)) {
      if (msg) {
        msg += ", ";
      }
      msg += COMMON_MSG.PrimaryPhoneType;
    }
    if (isNullOrEmpty(memberData.MobilePhone)) {
      if (msg) {
        msg += ", ";
      }
      msg += COMMON_MSG.MobilePhoneRequired;
    }
    if (memberData.PrimaryPhone === "WORK") {
      if (isNullOrEmpty(memberData.WorkPhone)) {
        if (msg) {
          msg += ", ";
        }
        msg += COMMON_MSG.WorkPhoneRequired;
      }
    }
    if (memberData.PrimaryPhone === "HOME") {
      if (isNullOrEmpty(memberData.HomePhone)) {
        if (msg) {
          msg += ", ";
        }
        msg += COMMON_MSG.HomePhoneRequired;
      }
    }
    if (isNullOrEmpty(memberData.AcknowledgeDriveManualEnabled)) {
      if (msg) {
        msg += ", ";
      }
      msg += COMMON_MSG.DriveManualEnabled;
    }
    if (msg) {
      return getFormattedValidationMsg(msg);
    }
    if (memberData.FirstName || memberData.LastName || memberData.MiddleName) {
      if (
        !isContainedAllowedCharacters(
          getFullName(
            memberData.FirstName,
            memberData.LastName,
            memberData.MiddleName
          )
        ) &&
        !memberData.OverrideNameValidation
      ) {
        if (msg) {
          msg += " <br />";
        }
        msg +=
          COMMON_MSG.MemberNameValidationMessage +
          " in Name: '" +
          specialCharsList +
          "'. Use 'Override Name Validation' to allow";
      }
    }
    if (memberData.MobilePhone) {
      if (!validatePhoneNo(memberData.MobilePhone)) {
        msg = COMMON_MSG.InvalidMobileNo + ".";
      }
    }
    if (memberData.WorkPhone) {
      if (!validatePhoneNo(memberData.WorkPhone)) {
        if (msg) {
          msg += " <br />";
        }
        msg += COMMON_MSG.InvalidWorkNo + ".";
      }
    }
    if (memberData.HomePhone) {
      if (!validatePhoneNo(memberData.HomePhone)) {
        if (msg) {
          msg += " <br />";
        }
        msg += COMMON_MSG.InvalidHomeNo + ".";
      }
    }
    if (memberData.IsEmployee) {
      if (!isValidateEmployeeNoBadgeNoInput(memberData.EmployeeNo)) {
        if (msg) {
          msg += " <br />";
        }
        msg +=
          "Invalid entry for Employee #. " +
          COMMON_MSG.ValidationMessageOfEmployeeNoBadgeNoInput;
      }

      if (!isValidateEmployeeNoBadgeNoInput(memberData.BadgeNo)) {
        if (msg) {
          msg += " <br />";
        }
        msg +=
          "Invalid entry for Badge #. " +
          COMMON_MSG.ValidationMessageOfEmployeeNoBadgeNoInput;
      }
    }
    if (memberData.InsuranceVerified) {
      if (
        !memberData.InsuranceCarrier ||
        !memberData.PolicyNumber ||
        !memberData.InsuranceExpirationDate
      ) {
        if (msg) {
          msg += " <br />";
        }
        msg += COMMON_MSG.InsuranceVerification + ".";
      }
    }
    if (!memberData.OverrideCreditCardInformation) {
      if (memberData.CreditCardNumber) {
        var opt = {};
        if (memberData.CreditCardType) {
          var types = AcceptedCardType[memberData.CreditCardType] || [];
          if (types.length) {
            opt.accept = types;
          }
          if (memberData.CreditCardCVV) {
            if (String(memberData.CreditCardType) === "AMEX") {
              if (memberData.CreditCardCVV.length !== 4) {
                if (msg) {
                  msg += " <br />";
                }
                msg += COMMON_MSG.CVVAmexValidation;
              }
            } else {
              if (memberData.CreditCardCVV.length !== 3) {
                if (msg) {
                  msg += " <br />";
                }
                msg += COMMON_MSG.CVVOtherValidation;
              }
            }
          }
        }
        let currentdate = new Date();
        let cmonth = currentdate.getMonth() + 1;
        let cyear = currentdate.getFullYear();

        if (
          parseInt(memberData.ExpirationMonth) < cmonth &&
          parseInt(memberData.ExpirationYear) <= cyear
        ) {
          if (msg) {
            msg += " <br />";
          }
          msg += COMMON_MSG.CreditCardExpExpiredMessage;
        }
      }
    }
  }
  return msg ? ReactHtmlParser(msg) : "";
};
export const validateUserProfile = (data) => {
  let msg = "";
  if (data) {
    if (!data.FirstName) {
      if (msg) {
        msg += ", ";
      }
      msg += COMMON_MSG.FirstName;
    }
    if (!data.EmailAddress) {
      if (msg) {
        msg += ", ";
      }
      msg += COMMON_MSG.EmailAddress;
    }
    if (!data.LastName) {
      if (msg) {
        msg += ", ";
      }
      msg += COMMON_MSG.LastName;
    }
    if (!data.DateOfBirth) {
      if (msg) {
        msg += ", ";
      }
      msg += COMMON_MSG.DOB;
    }

    if (!data.StreetAddress1) {
      if (msg) {
        msg += ", ";
      }
      msg += COMMON_MSG.Address1;
    }
    if (!data.Country) {
      if (msg) {
        msg += ", ";
      }
      msg += COMMON_MSG.Country;
    }

    if (!data.State && !data.StateStr) {
      if (msg) {
        msg += ", ";
      }
      msg += COMMON_MSG.State;
    }

    if (!data.City && !data.CityStr) {
      if (msg) {
        msg += ", ";
      }
      msg += COMMON_MSG.City;
    }

    if (!data.ZipCode && !data.ZipCodeStr) {
      if (msg) {
        msg += ", ";
      }
      msg += COMMON_MSG.ZipPostal;
    }

    if (!data.HintQuestion) {
      if (msg) {
        msg += ", ";
      }
      msg += COMMON_MSG.HintQuestion;
    }

    if (!data.HintAnswer) {
      if (msg) {
        msg += ", ";
      }
      msg += COMMON_MSG.HintAnswer;
    }

    if (isNullOrEmpty(data.MobilePhone)) {
      if (msg) {
        msg += ", ";
      }
      msg += COMMON_MSG.MobilePhoneRequired;
    }
    if (data.PrimaryPhone === "WORK") {
      if (isNullOrEmpty(data.WorkPhone)) {
        if (msg) {
          msg += ", ";
        }
        msg += COMMON_MSG.WorkPhoneRequired;
      }
    }
    if (data.PrimaryPhone === "HOME") {
      if (isNullOrEmpty(data.HomePhone)) {
        if (msg) {
          msg += ", ";
        }
        msg += COMMON_MSG.HomePhoneRequired;
      }
    }
  }
  if (msg) {
    return getFormattedValidationMsg(msg);
  } else {
    msg = "";
    if (data.MobilePhone) {
      if (!validatePhoneNo(data.MobilePhone)) {
        msg = COMMON_MSG.InvalidMobileNo + ".";
      }
    }
    if (data.WorkPhone) {
      if (!validatePhoneNo(data.WorkPhone)) {
        if (msg) {
          msg += " <br />";
        }
        msg += COMMON_MSG.InvalidWorkNo + ". ";
      }
    }
    if (data.HomePhone) {
      if (!validatePhoneNo(data.HomePhone)) {
        if (msg) {
          msg += " <br />";
        }
        msg += COMMON_MSG.InvalidHomeNo + ". ";
      }
    }
  }
  return msg ? ReactHtmlParser(msg) : "";
};
export const validateBlockUnblockProfile = (data) => {
  let msg = "";
  if (data) {
    if (!data.Notes) {
      if (msg) {
        msg += ", ";
      }
      msg += COMMON_MSG.Comments;
    }

    if (msg) {
      return getFormattedValidationMsg(msg);
    }
    return msg;
  }
};
export const validateAddViolationPoint = (data) => {
  let msg = "";
  if (data) {
    if (!data.ViolationPoint) {
      if (msg) {
        msg += ", ";
      }
      msg += COMMON_MSG.ViolationPoint;
    }

    if (!data.ViolationDesc) {
      if (msg) {
        msg += ", ";
      }
      msg += COMMON_MSG.Description;
    }

    if (msg) {
      return getFormattedValidationMsg(msg);
    }
    return msg;
  }
};
export const validateAddEditAddress = (data) => {
  let msg = "";
  if (data) {
    if (!data.AddressType) {
      if (msg) {
        msg += ", ";
      }
      msg += COMMON_MSG.AddressType;
    }

    if (!data.StreetAddress1) {
      if (msg) {
        msg += ", ";
      }
      msg += COMMON_MSG.Address1;
    }

    if (!data.Country) {
      if (msg) {
        msg += ", ";
      }
      msg += COMMON_MSG.Country;
    }

    if (!data.State && !data.StateStr) {
      if (msg) {
        msg += ", ";
      }
      msg += COMMON_MSG.State;
    }

    if (!data.City && !data.CityStr) {
      if (msg) {
        msg += ", ";
      }
      msg += COMMON_MSG.City;
    }

    if (!data.ZipCode && !data.ZipCodeStr) {
      if (msg) {
        msg += ", ";
      }
      msg += COMMON_MSG.ZipPostal;
    }

    if (msg) {
      return getFormattedValidationMsg(msg);
    }
    return msg ? ReactHtmlParser(msg) : "";
  }
};
export const validateReactivateProfile = (data, validationFor) => {
  let msg = "";
  if (data) {
    if (!data.Notes) {
      if (msg) {
        msg += ", ";
      }
      msg += COMMON_MSG.Comments;
    }

    if (!data.Password && String(validationFor) === "reactivateAccount") {
      if (msg) {
        msg += ", ";
      }
      msg += COMMON_MSG.Password;
    }

    if (msg) {
      return getFormattedValidationMsg(msg);
    }

    return msg;
  }
};
export const validateFreeCreditGrantReverse = (data, validationFor) => {
  let msg = "";
  if (validationFor !== "creditreverse") {
    if (data) {
      if (!data.Amount) {
        if (msg) {
          msg += ", ";
        }
        msg += COMMON_MSG.Amount;
      }

      if (!data.ReservationCode) {
        if (msg) {
          msg += ", ";
        }
        msg += "Reservation Code";
      }

      if (!data.PickUpLocationId) {
        if (msg) {
          msg += ", ";
        }
        msg += "PickUp Location";
      }

      if (!data.StartDate) {
        if (msg) {
          msg += ", ";
        }
        msg += "Start Date";
      }

      if (!data.EndDate) {
        if (msg) {
          msg += ", ";
        }
        msg += "End Date";
      }

      if (!data.Reason) {
        if (msg) {
          msg += ", ";
        }
        msg += "Reason Type";
      }

      if (!data.SubReason) {
        if (msg) {
          msg += ", ";
        }
        msg += "Subtype";
      }

      if (!data.Notes) {
        if (msg) {
          msg += ", ";
        }
        msg += "Notes";
      }

      if (!data.Password) {
        if (msg) {
          msg += ", ";
        }
        msg += "Password";
      }
      if (msg) {
        return getFormattedValidationMsg(msg);
      }
    }
  } else {
    if (data) {
      if (!data.Amount) {
        if (msg) {
          msg += ", ";
        }
        msg += COMMON_MSG.Amount;
      }

      if (!data.Reason) {
        if (msg) {
          msg += ", ";
        }
        msg += "Reason";
      }

      if (!data.Notes) {
        if (msg) {
          msg += ", ";
        }
        msg += "Notes";
      }

      if (!data.Password) {
        if (msg) {
          msg += ", ";
        }
        msg += "Password";
      }
      if (msg) {
        return getFormattedValidationMsg(msg);
      }
    }
  }
};
export const getInfoForLicenseUploaderModal = (memberData) => {
  return {
    memberId: memberData.MemberId || null,
    modalTitle: ModalType.MEMBER_LICENCE_UPLOADER_MODAL
      ? "Upload License Image"
      : "View License",
    drivingData: memberData.DrivingLicense || {},
  };
};
export const getDefaultInfoForMemberModal = (modalType, memberData) => {
  if (
    modalType === ModalType.MEMBER_LICENCE_UPLOADER_MODAL ||
    modalType === ModalType.MEMBER_LICENCE_VIEW_MODAL
  ) {
    return getInfoForLicenseUploaderModal(memberData);
  }
};
export const validateAddNewActivity = (data) => {
  let msg = "";
  if (data) {
    if (!data.ChannelType) {
      if (msg) {
        msg += ", ";
      }
      msg += COMMON_MSG.ChannelType;
    }

    if (!data.CallCategory) {
      if (msg) {
        msg += ", ";
      }
      msg += COMMON_MSG.CallCategory;
    }

    if (!data.Category) {
      if (msg) {
        msg += ", ";
      }
      msg += COMMON_MSG.Category;
    }

    if (!data.Comments) {
      if (msg) {
        msg += ", ";
      }
      msg += COMMON_MSG.Comments;
    }
    if (!data.Priority) {
      if (msg) {
        msg += ", ";
      }
      msg += COMMON_MSG.Priority;
    }

    if (msg) {
      return getFormattedValidationMsg(msg);
    }
    return msg;
  }
};
export const getDriveTypeList = () => {
  return [
    { key: "AUTOMATIC", displayValue: "Automatic" },
    { key: "MANUAL", displayValue: "Manual" },
    { key: "ANY", displayValue: "Any" },
  ];
};

export const getAddressTypeList = () => {
  return [
    { key: "", displayValue: "Select Address Type" },
    { key: "HOME", displayValue: "Home" },
    { key: "WORK", displayValue: "Work" },
    { key: "OTHER", displayValue: "Other" },
  ];
};

export const getCardTypeList = () => {
  return [
    { key: "", displayValue: "Select Card Type" },
    { key: "VISA", displayValue: "Visa" },
    { key: "MASTERCARD", displayValue: "Mastercard" },
    { key: "AMEX", displayValue: "American Express (Amex)" },
    { key: "DISCOVER", displayValue: "Discover" },
    { key: "NET_BANKING", displayValue: "Net Banking" },
    { key: "MAESTRO", displayValue: "Maestro" },
    { key: "DINERS", displayValue: "Diners" },
    { key: "JCB", displayValue: "JCB" },
  ];
};

export const getPaymentMethod = (isCreditInclude, isSearchPage) => {
  let paymentMethods = [
    { key: "", displayValue: isSearchPage ? "All" : "Select" },
    { key: "CREDIT_CARD", displayValue: "Credit Card" },
    { key: "DEBIT_CARD", displayValue: "Debit Card" },
    { key: "MANUAL_INVOICE", displayValue: "Manual Invoice" },
    { key: "CASH", displayValue: "Cash" },
    { key: "CHECK", displayValue: "Check" },
  ];
  return isCreditInclude
    ? paymentMethods.concat({ key: "CREDITS", displayValue: "Credits" })
    : paymentMethods;
};

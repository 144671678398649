import * as actionTypes from "../actions/actionTypes";
import { reducerUpdateObject } from "./reducer.utility";

const initialState = {
  token: null,
  userId: null,
  error: null,
  loading: false,
  loggedInUserData: null,
  currentOrgUnitId: null,
  authRedirectPath: "/",
  updateLoggedInUserInfoResponse: null,
};
const doLoginReset = (state, action) => {
  return reducerUpdateObject(state, { ...initialState });
};
const doLoginStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};

const doLoginSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    token: action.token,
    userId: action.userId,
    loggedInUserData: action.loggedInUserData,
    currentOrgUnitId: action.currentOrgUnitId,
    error: null,
    loading: false,
  });
};
const updateLoginDataWithOrgInfo = (state, action) => {
  return reducerUpdateObject(state, {
    loggedInUserData: action.loggedInUserData,
  });
};
const doLoginFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false,
  });
};

const authLogout = (state, action) => {
  return reducerUpdateObject(state, { token: null, userId: null });
};

const setAuthRedirectPath = (state, action) => {
  return reducerUpdateObject(state, { authRedirectPath: action.path });
};
const getLoggedInData = (state, action) => {
  return state.loggedInUserData;
};

export const updateLoggedInUserInfoStart = (state) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
export const updateLoggedInUserInfoSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    updateLoggedInUserInfoResponse: action.updateLoggedInUserInfoResponse,
    error: null,
    loading: false,
  });
};
const updateLoggedInUserInfoFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false,
  });
};
const updateLoggedInUserInfoReset = (state) => {
  return reducerUpdateObject(state, {
    updateLoggedInUserInfoResponse: null,
  });
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.AUTH_RESET:
      return doLoginReset(state, action);
    case actionTypes.AUTH_START:
      return doLoginStart(state, action);
    case actionTypes.AUTH_SUCCESS:
      return doLoginSuccess(state, action);
    case actionTypes.AUTH_FAIL:
      return doLoginFail(state, action);
    case actionTypes.AUTH_LOGOUT:
      return authLogout(state, action);
    case actionTypes.SET_AUTH_REDIRECT_PATH:
      return setAuthRedirectPath(state, action);
    case actionTypes.LOGGEDIN_DATA_UPDATE:
      return updateLoginDataWithOrgInfo(state, action);
    case actionTypes.GET_LOGGEDIN_DATA:
      return getLoggedInData(state, action);

    case actionTypes.UPDATE_LOGGED_IN_USER_INFO_START:
      return updateLoggedInUserInfoStart(state, action);
    case actionTypes.UPDATE_LOGGED_IN_USER_INFO_SUCCESS:
      return updateLoggedInUserInfoSuccess(state, action);
    case actionTypes.UPDATE_LOGGED_IN_USER_INFO_FAIL:
      return updateLoggedInUserInfoFail(state, action);
    case actionTypes.UPDATE_LOGGED_IN_USER_INFO_RESET:
      return updateLoggedInUserInfoReset(state);

    default:
      return state;
  }
};

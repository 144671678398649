import React, { Component, useState, useRef, useEffect, Fragment } from "react";
import ReactDom from "react-dom";
import PropTypes from "prop-types";

export const Trigger = (props) => {
  const triggerContainerRef = useRef(null);
  const [styles, setStyles] = useState({});
  const { otherOptions } = props.children.props;
  useEffect(() => {
    //triggerContainerRef.current.children[0].style.width="fit-content"
    // if(triggerContainerRef.current.children[0] && triggerContainerRef.current.children[0].style){
    //   if(!triggerContainerRef.current.children[0].style.width){
    //     triggerContainerRef.current.children[0].style.width = "fit-content";
    //   }
    // }
    //setStyles({
    //width: "fit-content",
    // height: triggerContainerRef.current
    //   ? triggerContainerRef.current.offsetHeight
    //   : 0,
    //margin:triggerContainerRef.offsetHeight,
    //});
  }, [triggerContainerRef.current]);
  const onMouseOver = (event) => {
    if (
      !(otherOptions && otherOptions.considerParent) &&
      event.target.getAttribute("name") === "tpContainer"
    ) {
      return false;
    }
    const { setVisibility, getCursorPos } = props.children.props;
    let showTooltip = false;

    if (
      event &&
      event.target &&
      event.target.scrollWidth &&
      event.target.nodename !== "A"
    ) {
      showTooltip = event.target.scrollWidth > event.target.offsetWidth;
    } else if (event.target.nodeName == "A") {
      showTooltip =
        event.target.offsetWidth >= event.target.parentNode.offsetWidth;
    } else {
      showTooltip =
        event.target.parentNode.parentNode.scrollWidth >
        event.target.parentNode.parentNode.offsetWidth;
    }
    if (!event.target.offsetWidth) {
      return false;
    }
    if (
      otherOptions &&
      otherOptions.parentClass &&
      otherOptions.parentClass.split(" ").includes("line-clamp") &&
      event.target.textContent &&
      event.target.textContent.length > (otherOptions.textLength || 140)
    ) {
      showTooltip = true;
    }
    if (showTooltip || (otherOptions && otherOptions.forceShow)) {
      if (
        otherOptions &&
        otherOptions.forceShow &&
        !otherOptions.isIcon &&
        !showTooltip
      ) {
        if (!event.target.style.width) {
          event.target.style.display = "inline";
        }
      }
      if (otherOptions && otherOptions.tableId) {
        document.getElementById(
          otherOptions.tableId
        ).offsetParent.style.overflow = "visible";
      }
      setVisibility(true);
      getCursorPos(event);
    }
  };

  const onMouseOut = () => {
    const { setVisibility } = props.children.props;
    if (otherOptions && otherOptions.tableId) {
      document.getElementById(
        otherOptions.tableId
      ).offsetParent.style.overflow = null;
    }
    setVisibility(false);
  };

  const onMouseMove = (e) => {
    // const { getCursorPos } = this.props.children.props;
    // getCursorPos(e);
  };

  const onTouchStart = (event) => {
    if (
      !(otherOptions && otherOptions.considerParent) &&
      event.target.getAttribute("name") === "tpContainer"
    ) {
      return false;
    }
    const { setVisibility } = props.children.props;
    const showTooltip = event
      ? event.target.parentNode.parentNode.scrollWidth >
        event.target.parentNode.parentNode.offsetWidth
      : false;
    if (showTooltip) {
      setVisibility(true);
    }
  };

  const onTouchEnd = () => {
    const { setVisibility } = props.children.props;
    setVisibility(false);
  };
  const pClassName =
    otherOptions && otherOptions.parentClass
      ? otherOptions.parentClass + " tt-container"
      : "tt-container";
  const pStyles =
    otherOptions && otherOptions.parentStyles
      ? otherOptions.parentStyles
      : styles;
  const parentTag = {
    tag:
      otherOptions && otherOptions.parentTag ? otherOptions.parentTag : "span",
  };
  return (
    <parentTag.tag
      onMouseOver={(event) => onMouseOver(event)}
      onMouseOut={(event) => onMouseOut(event)}
      onMouseMove={(event) => onMouseMove(event)}
      onTouchStart={(event) => onTouchStart(event)}
      onTouchEnd={(event) => onTouchEnd(event)}
      ref={triggerContainerRef}
      style={{ ...pStyles }}
      className={pClassName}
      name="tpContainer"
    >
      {props.children.props.children}
    </parentTag.tag>
  );
};
export default Trigger;

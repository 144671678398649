const Category = function (data) {
  if (!data) data = {};
  this.CategoryId = data.CategoryId || null;
  this.OrgUnitId = data.OrgUnitId || null;
  this.DisplayText = data.DisplayText || null;
  this.Label = data.Label || null;
  this.RatePerDay = data.RatePerDay || null;
  this.RatePerHour = data.RatePerHour || null;
  this.DailyMilesAllowed = data.DailyMilesAllowed || null;
  this.HourlyExcessMileageCharge = data.HourlyExcessMileageCharge || null;
  this.HourlyMilesAllowed = data.HourlyMilesAllowed || null;
  this.MonthlyMilesAllowed = data.MonthlyMilesAllowed || null;
  this.MonthlyExcessMileageCharge = data.MonthlyExcessMileageCharge || null;
  this.WeeklyMilesAllowed = data.WeeklyMilesAllowed || null;
  this.WeeklyExcessMileageCharge = data.WeeklyExcessMileageCharge || null;
  this.MileageBasedPricing = data.MileageBasedPricing || null;
  this.HourlyRentalsAllowed = data.HourlyRentalsAllowed || null;
  this.DisplayOrder = data.DisplayOrder || null;
  this.SharerHourlyPrice = data.SharerHourlyPrice || null;
  this.SharerDailyPrice = data.SharerDailyPrice || null;
  this.RatePerMonth = data.RatePerMonth || null;
  this.RatePerWeek = data.RatePerWeek || null;
  this.RatePerWeekendDay = data.RatePerWeekendDay || null;
  this.SharerWeeklyPrice = data.SharerWeeklyPrice || null;
  this.SharerMonthlyPrice = data.SharerMonthlyPrice || null;
  this.SharerWeekendDayPrice = data.SharerWeekendDayPrice || null;
  this.Value = data.Value || null;
  this.Description = data.Description || null;
  this.Status = data.Status || null;
};
export default Category;

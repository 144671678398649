import axios from "axios";
import * as actionTypes from "./actionTypes";
import HttpService from "../../services/httpService";

export const getOrgDetailsStart = () => {
  return {
    type: actionTypes.GET_ORG_DETAIL_START
  };
};
export const getOrgDetailsSuccess = orgDataResponse => {
  return {
    type: actionTypes.GET_ORG_DETAIL_SUCCESS,
    orgDataResponse: orgDataResponse
  };
};
export const getOrgDetailsFail = error => {
  return {
    type: actionTypes.GET_ORG_DETAIL_FAIL,
    error: error
  };
};
export const getOrgDetailsReset = error => {
  return {
    type: actionTypes.GET_ORG_DETAIL_RESET
  };
};
export const getOrgDetails = orgUnitId => {
  return dispatch => {
    dispatch(getOrgDetailsStart());
    HttpService.getOrgDetails(orgUnitId)
      .then(response => {
        dispatch(getOrgDetailsSuccess(response));
      })
      .catch(err => {
        //dispatch(getOrgDetailsFail(err));
      });
  };
};

import SubMenu from "../model/sub-menu";
import SubMenuItem from "../model/sub-menu-item";
import KeyValuePair from "../../../services/entities/models/KeyValuePair";
import LocationSettings from "../../../containers/Settings/location-settings/location-settings";
import PermissionSetting from "../../../containers/Settings/permissions/permission-settings";
import RolesSettings from "../../../containers/Settings/roles/role-settings";
import MainSettings from "../../../containers/Settings/main-settings/main-settings";
import HoursOfOperation from "../../../containers/Settings/hours-of-operations/hours-of-operations-settings";
import TaxesSurchargesSettings from "../../../containers/Settings/taxes-surcharges/taxes-surcharges-settings";
import QualificationsSettings from "../../../containers/Settings/qualifications/qualifications-settings";
import ReservationSettings from "../../../containers/Settings/reservation-settings/reservation-settings";
import AdditionalOptionsSettings from "../../../containers/Settings/additional-options/additional-option-settings";
import RateSettings from "../../../containers/Settings/rate/rate-settings";
import InsuranceSettings from "../../../containers/Settings/insurance/insurance-settings";
import CapacityPlanningSettings from "../../../containers/Settings/capacity-planning/capacity-planning-settings";
import VehicleAvailabilitySettings from "../../../containers/Settings/vehicle-availability/vehicle-availability-settings";
import { hasPermission } from "../../../shared/utility";
import PermissionName from "../../../shared/Permissions";
import BlackListedPersonsSettings from "../../../containers/Settings/black-listed-persons/black-listed-persons-settings";
import { ShuttleLocationSettings } from "../../../containers/Settings/shuttle-settings/shuttle-location-settings";

export const getSettingsSubMenu = (orgLocationId) => {
  const subMenu = new SubMenu();
  subMenu.menuKey = "setting";
  if (hasPermission(PermissionName.VIEW_MAIN_ORG_SETTINGS)) {
    subMenu.subMenuItems.push(
      new SubMenuItem(
        "main",
        "Main Information",
        "fas fa-cogs",
        MainSettings,
        orgLocationId,
        [new KeyValuePair("orgId", orgLocationId)]
      )
    );
  }
  if (hasPermission(PermissionName.VIEW_HOURS_ORG_SETTINGS)) {
    subMenu.subMenuItems.push(
      new SubMenuItem(
        "hourOfOperation",
        "Hours Of Operation",
        "fas fa-business-time",
        HoursOfOperation,
        orgLocationId,
        [new KeyValuePair("orgId", orgLocationId)]
      )
    );
  }
  if (hasPermission(PermissionName.VIEW_TAX_ORG_SETTINGS)) {
    subMenu.subMenuItems.push(
      new SubMenuItem(
        "taxesSurcharges",
        "Taxes & Surcharges",
        "fas fa-money-check-alt",
        TaxesSurchargesSettings,
        orgLocationId,
        [new KeyValuePair("orgId", orgLocationId)]
      )
    );
  }
  if (hasPermission(PermissionName.VIEW_QUALIFICATION_ORG_SETTINGS)) {
    subMenu.subMenuItems.push(
      new SubMenuItem(
        "qualifications",
        "Qualifications",
        "fas fa-user-graduate",
        QualificationsSettings,
        orgLocationId,
        [new KeyValuePair("orgId", orgLocationId)]
      )
    );
  }
  if (hasPermission(PermissionName.VIEW_RESERVATION_ORG_SETTINGS)) {
    subMenu.subMenuItems.push(
      new SubMenuItem(
        "resSettings",
        "Reservations",
        "fas fa-car-side",
        ReservationSettings,
        orgLocationId,
        [new KeyValuePair("orgId", orgLocationId)]
      )
    );
  }
  if (hasPermission(PermissionName.VIEW_INSURANCE_ORG_SETTINGS)) {
    subMenu.subMenuItems.push(
      new SubMenuItem(
        "insurance",
        "Insurance",
        "fas fa-car-crash",
        InsuranceSettings,
        orgLocationId,
        [new KeyValuePair("orgId", orgLocationId)]
      )
    );
  }
  if (hasPermission(PermissionName.VIEW_UPSELL_ORG_SETTINGS)) {
    subMenu.subMenuItems.push(
      new SubMenuItem(
        "addOnsSettings",
        "Additional Options",
        "fas fa-plus",
        AdditionalOptionsSettings,
        orgLocationId,
        [new KeyValuePair("orgId", orgLocationId)]
      )
    );
  }
  if (hasPermission(PermissionName.VIEW_LOCATION_DETAIL_ORG_SETTINGS)) {
    subMenu.subMenuItems.push(
      new SubMenuItem(
        "location",
        "Locations",
        "fas fa-location-arrow",
        LocationSettings,
        orgLocationId,
        [new KeyValuePair("orgId", orgLocationId)]
      )
    );
  }
  if (hasPermission(PermissionName.VIEW_BLACK_LIST_ORG_SETTINGS)) {
    subMenu.subMenuItems.push(
      new SubMenuItem(
        "blackListedPerson",
        "Do Not Rent List",
        "fas fa-ban",
        BlackListedPersonsSettings,
        orgLocationId,
        [new KeyValuePair("orgId", orgLocationId)]
      )
    );
  }
  if (hasPermission(PermissionName.VIEW_RATES_ORG_SETTINGS)) {
    subMenu.subMenuItems.push(
      new SubMenuItem(
        "rates",
        "Rates",
        "fas fa-dollar-sign",
        RateSettings,
        orgLocationId,
        [new KeyValuePair("orgId", orgLocationId)]
      )
    );
  }
  if (hasPermission(PermissionName.VIEW_CAPACITY_BUCKETS_INFO)) {
    subMenu.subMenuItems.push(
      new SubMenuItem(
        "capacityPlanning",
        "Capacity Planning",
        "fas fa-building",
        CapacityPlanningSettings,
        orgLocationId,
        [new KeyValuePair("orgId", orgLocationId)]
      )
    );
  }
  if (hasPermission(PermissionName.CC_VIEW_SHUTTLE_SETTINGS)) {
    subMenu.subMenuItems.push(
      new SubMenuItem(
        "shuttles",
        "Shuttles",
        "fas fa-bus",
        ShuttleLocationSettings,
        orgLocationId,
        [new KeyValuePair("orgId", orgLocationId)]
      )
    );
  }
  if (hasPermission(PermissionName.VIEW_AVAILABILITY_ORG_SETTINGS)) {
    subMenu.subMenuItems.push(
      new SubMenuItem(
        "vehicleSettings",
        "Vehicle Availability",
        "fas fa-car",
        VehicleAvailabilitySettings,
        orgLocationId,
        [new KeyValuePair("orgId", orgLocationId)]
      )
    );
  }
  if (hasPermission(PermissionName.VIEW_ROLE)) {
    subMenu.subMenuItems.push(
      new SubMenuItem(
        "roles",
        "Roles",
        "fas fa-user-tag",
        RolesSettings,
        orgLocationId,
        [new KeyValuePair("orgId", orgLocationId)]
      )
    );
  }
  if (hasPermission(PermissionName.VIEW_PERMISSION)) {
    subMenu.subMenuItems.push(
      new SubMenuItem(
        "permission",
        "Permission",
        "fas fa-thumbs-up",
        PermissionSetting,
        orgLocationId,
        [new KeyValuePair("orgId", orgLocationId)]
      )
    );
  }

  return subMenu;
};

import * as actionTypes from "./actionTypes";
import HttpService from "../../services/httpService";

export const getFleetUtilizationSummaryStart = () => {
  return {
    type: actionTypes.FLEET_UTILIZATION_SUMMARY_START
  };
};
export const getFleetUtilizationSummarySuccess = response => {
  return {
    type: actionTypes.FLEET_UTILIZATION_SUMMARY_SUCCESS,
    fleetUtilizationSummaryResponse: response
  };
};
export const getFleetUtilizationSummaryFail = error => {
  return {
    type: actionTypes.FLEET_UTILIZATION_SUMMARY_FAIL,
    error: error
  };
};
export const getFleetUtilizationSummaryReset = () => {
  return {
    type: actionTypes.FLEET_UTILIZATION_SUMMARY_RESET
  };
};
export const getFleetUtilizationSummary = req => {
  return dispatch => {
    dispatch(getFleetUtilizationSummaryStart());
    HttpService.getFleetUtilizationSummary(req)
      .then(response => {
        dispatch(getFleetUtilizationSummarySuccess(response));
      })
      .catch(err => {
        //dispatch(getFleetUtilizationSummaryFail(err));
      });
  };
};

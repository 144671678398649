import React from "react";
import ReactHtmlParser from "react-html-parser";
import { COPY_TO_CLIPBOARD } from "../../../shared/AppImages";
import CopyToClipboard from "../../copy-to-clipboard/copy-to-clipboard";

const InfoField = (props) => {
  const {
    label,
    value,
    inputIcon,
    customValueClass,
    isImage,
    showModal,
    modalParams,
    customValueParentClass,
    tooltip,
    hasCopyIcon,
    modalIcon,
    iconSize = { height: 20, width: 20 },
    ...rest
  } = props;

  const handleModalClick = () => {
    if (modalParams && modalParams.type && showModal) {
      showModal(modalParams);
    }
  };

  return (
    <div className={`field-value ${customValueParentClass || ""}`} title={tooltip}>
      {isImage ? (
        <img src={value} height={40} width={40} alt={label} />
      ) : (
        <>
          <label className={`${customValueClass || ""}`} onClick={handleModalClick}>
            {value ? ReactHtmlParser(value) : "--"}
          </label>
          {inputIcon && (
            <div className="input-icon">
              <img src={inputIcon} height={16} width={16} alt={label} />
            </div>
          )}
          {modalIcon && (
            <div
              className="action-icon"
              title={rest.iconTooltipText}
              onClick={modalParams && modalParams.type ? handleModalClick : undefined}
            >
              <img
                className={rest.imageClass || ""}
                src={modalIcon}
                height={iconSize.height}
                width={iconSize.width}
                alt={label}
              />
            </div>
          )}
          {hasCopyIcon && (
            <CopyToClipboard
              value={value}
              copyLinkType={"text-image"}
              linkCaption=" "
              imageClass="mt-1"
              imageURL={COPY_TO_CLIPBOARD}
              customImgCSS={{ height: "1.4rem" }}
              successMsg={`${label} has been copied to clipboard!`}
            />
          )}
        </>
      )}
    </div>
  );
};

export default InfoField;

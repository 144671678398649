import React from "react";
import LabelWithValue from "../../../../../components/UI/label/LabelWithValue";
import { getFormattedAddress } from "../../../../../shared/utility";

const LocationInfoSection = ({ locationData }) => {
  const { Name, Address, TollFreeNumber, Email } = locationData;
  const infoItems = [
    { label: "Name", value: Name },
    { label: "Address", value: Address ? getFormattedAddress(Address) : null },
    { label: "Phone", value: TollFreeNumber },
    { label: "Email", value: Email }
  ];

  return (
    <div className="location-info-section">
      <span className="sectionTitle">Location Info</span>
      <div className="location-info-items-container">
        {infoItems.map(({ label, value }) => (
          <LabelWithValue key={label} label={label} value={value || null} />
        ))}
      </div>
    </div>
  );
};

export default LocationInfoSection;

import { UnitDisplayText } from "./enum-utility";

export const getSortedBasicRates = (ratesApplied) => {
  const ratePlanTypes = [];
  const lineItemUnit = "Charge";
  let basicRate = ratesApplied.find(
    (element) => element.type === "MONTHLY" && element.amount > 0
  );
  if (basicRate && basicRate.type === "MONTHLY") {
    const modifiedBasicRate = { ...basicRate };
    modifiedBasicRate.name = "Monthly " + lineItemUnit;
    ratePlanTypes.push(modifiedBasicRate);
  }
  basicRate = ratesApplied.find(
    (element) => element.type === "WEEKLY" && element.amount > 0
  );
  if (basicRate && basicRate.type === "WEEKLY") {
    const modifiedBasicRate = { ...basicRate };
    modifiedBasicRate.name = "Weekly " + lineItemUnit;
    ratePlanTypes.push(modifiedBasicRate);
  }
  basicRate = ratesApplied.find(
    (element) => element.type === "WEEKEND" && element.amount > 0
  );
  if (basicRate && basicRate.type === "WEEKEND") {
    const modifiedBasicRate = { ...basicRate };
    modifiedBasicRate.name = "Weekend " + lineItemUnit;
    ratePlanTypes.push(modifiedBasicRate);
  }
  basicRate = ratesApplied.find(
    (element) => element.type === "DAILY" && element.amount > 0
  );
  if (basicRate && basicRate.type === "DAILY") {
    const modifiedBasicRate = { ...basicRate };
    modifiedBasicRate.name = "Daily " + lineItemUnit;
    ratePlanTypes.push(modifiedBasicRate);
  }
  basicRate = ratesApplied.find(
    (element) => element.type === "HOURLY" && element.amount > 0
  );
  if (basicRate && basicRate.type === "HOURLY") {
    const modifiedBasicRate = { ...basicRate };
    modifiedBasicRate.name = "Hourly " + lineItemUnit;
    ratePlanTypes.push(modifiedBasicRate);
  }
  return ratePlanTypes;
};

export const calculateAppliedFreeCredit = (customerFreeCredit, totalCharge) => {
  return customerFreeCredit > totalCharge ? totalCharge : customerFreeCredit;
};

/**pricing table rate */
export const getFormattedAppliedRates = (ratesApplied) => {
  const formattedRates = [];
  const arrData = ["MONTHLY", "WEEKLY", "WEEKEND", "DAILY", "HOURLY"];
  for (let i = 0; i < 5; i++) {
    let rateData = ratesApplied.find(
      (element) => element.type === arrData[i] && element.amount > 0
    );
    if (rateData) {
      formattedRates.push({
        title:
          rateData?.amount +
          " " +
          UnitDisplayText[rateData.type] +
          (rateData.amount > 1 ? "s" : ""),
        rate: rateData?.rate,
        subtotal: rateData?.rate * rateData?.amount,
      });
    }
  }
  return formattedRates;
};

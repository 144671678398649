import * as actionTypes from "../actions/actionTypes";
import { reducerUpdateObject } from "./reducer.utility";

const initialState = {
  uploadFileResponse: null,
  fetchingCounter: 0,
  loading: false
};
const uploadDocStart = (state, action) => {
  return reducerUpdateObject(state, {
    error: null,
    loading: true,
    fetchingCounter: state.fetchingCounter + 1
  });
};
const uploadDocSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    uploadFileResponse: action.uploadFileResponse,
    error: null,
    loading: state.fetchingCounter > 1 ? true : false,
    fetchingCounter: state.fetchingCounter > 0 ? state.fetchingCounter - 1 : 0
  });
};
const uploadDocFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: state.fetchingCounter > 1 ? true : false,
    fetchingCounter: state.fetchingCounter > 0 ? state.fetchingCounter - 1 : 0
  });
};
const uploadDocReset = (state, action) => {
  return reducerUpdateObject(state, { uploadFileResponse: null });
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPLOAD_DOC_START:
      return uploadDocStart(state, action);
    case actionTypes.UPLOAD_DOC_SUCCESS:
      return uploadDocSuccess(state, action);
    case actionTypes.UPLOAD_DOC_FAIL:
      return uploadDocFail(state, action);
    case actionTypes.UPLOAD_DOC_RESET:
      return uploadDocReset(state, action);
    default:
      return state;
  }
};
export default reducer;

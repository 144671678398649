import * as actionTypes from "./actionTypes";
import { invokeServerAPI } from "../../services/httpService";

export const getBlackListedPersonInfosStart = () => {
  return {
    type: actionTypes.GET_DO_NOT_RENT_LIST_START,
  };
};
export const getBlackListedPersonInfosSuccess = (response) => {
  return {
    type: actionTypes.GET_DO_NOT_RENT_LIST_SUCCESS,
    blackListedPersonsListResponse: response,
  };
};
export const getBlackListedPersonInfosFail = (error) => {
  return {
    type: actionTypes.GET_DO_NOT_RENT_LIST_FAIL,
    error: error,
  };
};
export const getBlackListedPersonInfosReset = () => {
  return {
    type: actionTypes.GET_DO_NOT_RENT_LIST_RESET,
  };
};

export const addMembertoBlackListStart = () => {
  return {
    type: actionTypes.ADD_PERSON_TO_BLACK_LIST_START,
  };
};
export const addMembertoBlackListSuccess = (response) => {
  return {
    type: actionTypes.ADD_PERSON_TO_BLACK_LIST_SUCCESS,
    blackListedPersonEntityResponse: response,
  };
};
export const addMembertoBlackListFail = (error) => {
  return {
    type: actionTypes.ADD_PERSON_TO_BLACK_LIST_FAIL,
    error: error,
  };
};
export const addMembertoBlackListReset = () => {
  return {
    type: actionTypes.ADD_PERSON_TO_BLACK_LIST_RESET,
  };
};

export const addBlackListedPersonInfosStart = () => {
  return {
    type: actionTypes.ADD_DO_NOT_RENT_LIST_START,
  };
};
export const addBlackListedPersonInfosSuccess = (response) => {
  return {
    type: actionTypes.ADD_DO_NOT_RENT_LIST_SUCCESS,
    addBlackListedPersonInfosResponse: response,
  };
};
export const addBlackListedPersonInfosFail = (error) => {
  return {
    type: actionTypes.ADD_DO_NOT_RENT_LIST_FAIL,
    error: error,
  };
};
export const addBlackListedPersonInfosReset = () => {
  return {
    type: actionTypes.ADD_DO_NOT_RENT_LIST_RESET,
  };
};

export const updateBlackListedPersonInfosStart = () => {
  return {
    type: actionTypes.UPDATE_DO_NOT_RENT_LIST_START,
  };
};
export const updateBlackListedPersonInfosSuccess = (response) => {
  return {
    type: actionTypes.UPDATE_DO_NOT_RENT_LIST_SUCCESS,
    updateBlackListedPersonInfosResponse: response,
  };
};
export const updateBlackListedPersonInfosFail = (error) => {
  return {
    type: actionTypes.UPDATE_DO_NOT_RENT_LIST_FAIL,
    error: error,
  };
};
export const updateBlackListedPersonInfosReset = () => {
  return {
    type: actionTypes.UPDATE_DO_NOT_RENT_LIST_RESET,
  };
};

export const deleteBlackListedPersonInfosStart = () => {
  return {
    type: actionTypes.DELETE_DO_NOT_RENT_LIST_START,
  };
};
export const deleteBlackListedPersonInfosSuccess = (response) => {
  return {
    type: actionTypes.DELETE_DO_NOT_RENT_LIST_SUCCESS,
    deleteBlackListedPersonInfosResponse: response,
  };
};
export const deleteBlackListedPersonInfosFail = (error) => {
  return {
    type: actionTypes.DELETE_DO_NOT_RENT_LIST_FAIL,
    error: error,
  };
};
export const deleteBlackListedPersonInfosReset = () => {
  return {
    type: actionTypes.DELETE_DO_NOT_RENT_LIST_RESET,
  };
};

export const getBlackListedPersonInfos = (req) => {
  return (dispatch) => {
    dispatch(getBlackListedPersonInfosStart());
    invokeServerAPI("getdonotrentlist", req)
      .then((response) => {
        dispatch(getBlackListedPersonInfosSuccess(response));
      })
      .catch((err) => {
        //dispatch(getBlackListedPersonInfosFail(err));
      });
  };
};
export const addMembertoBlackList = (req) => {
  return (dispatch) => {
    dispatch(addMembertoBlackListStart());
    invokeServerAPI("blacklistperson", req)
      .then((response) => {
        dispatch(addMembertoBlackListSuccess(response));
      })
      .catch((err) => {
        //dispatch(addMembertoBlackListFail(err));
      });
  };
};
export const addBlackListedPersonInfos = (req) => {
  return (dispatch) => {
    dispatch(addBlackListedPersonInfosStart());
    invokeServerAPI("addblacklistedperson", req)
      .then((response) => {
        dispatch(addBlackListedPersonInfosSuccess(response));
      })
      .catch((err) => {
        //dispatch(addDoNotRentFail(err));
      });
  };
};
export const updateBlackListedPersonInfos = (req) => {
  return (dispatch) => {
    dispatch(updateBlackListedPersonInfosStart());
    invokeServerAPI("updatedonotrentlist", req)
      .then((response) => {
        dispatch(updateBlackListedPersonInfosSuccess(response));
      })
      .catch((err) => {
        //dispatch(updateBlackListedPersonInfosFail(err));
      });
  };
};
export const deleteBlackListedPersonInfos = (req) => {
  return (dispatch) => {
    dispatch(deleteBlackListedPersonInfosStart());
    invokeServerAPI("deletedonotrentlist", req)
      .then((response) => {
        dispatch(deleteBlackListedPersonInfosSuccess(response));
      })
      .catch((err) => {
        //dispatch(deleteBlackListedPersonInfosFail(err));
      });
  };
};

import React, { useState, useEffect, Fragment } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import xmlFormat from "xml-formatter";
import {
  getIntegrationHistoryReset,
  getIntegrationHistory,
  updateIntegStatusReset,
  updateIntegStatus,
} from "../../../store/actions/index";
import {
  getBasicToastResponse,
  getDateTimeString,
  hasPermission,
  isJson,
  isNullOrEmpty,
  isValidXML,
} from "../../../shared/utility";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Draggable from "react-draggable";
import PermissionName from "../../../shared/Permissions";
import { showNotification } from "../../../components/UI/Toaster/Toaster";
import CopyToClipboard from "../../../components/copy-to-clipboard/copy-to-clipboard";
import { createTooltip } from "../../../shared/utility/tooltip-utility";
import RequestResponsePayload from "./request-response-payload";
import { IntegrationResponseType } from "../../../shared/utility/enum-utility";
import { CLIPBOARD } from "../../../shared/AppImages";

export const IntegrationHistoryModal = (props) => {
  const defaultData = props.defaultData;
  const integrationHistoryResponse = useSelector(
    (state) => state.reservationReducer.integrationHistoryResponse
  );
  const updateIntegStatusResponse = useSelector(
    (state) => state.reservationReducer.updateIntegStatusResponse
  );
  const dispatch = useDispatch();
  const [integHistoryData, setIntegHistoryData] = useState({
    enablePayloadColumn: false,
    integHistoryList: [],
  });
  const [integStatus, setIntegStatus] = useState(
    defaultData.IntegStatus === "SCHEDULED" ? true : false
  );
  const [showRequestResponsePayloadModal, setShowRequestResponsePayloadModal] =
    useState(false);
  const [selectedIntegData, setSelectedIntegData] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isOpenOnes, setIsOpenOnes] = useState(null);
  const [lastTimeFetched, setLastTimeFetched] = useState(null);
  const [value, setValue] = useState(null);
  const xmlFormatProperties = {
    indentation: "  ",
    collapseContent: true,
    lineSeparator: "\n",
  };

  useEffect(() => {
    if (value) {
      //props.setIsLoading(false);
    }
  }, [value]);

  useEffect(() => {
    let lastDataFetchTime = null;
    if (localStorage.getItem("LAST_FETCHED_TIME_INTEG_HISTORY")) {
      lastDataFetchTime = moment(
        localStorage.getItem("LAST_FETCHED_TIME_INTEG_HISTORY"),
        "MM:DD:YYYY:HH:mm:ss"
      ).toDate();
    }
    if (lastDataFetchTime != null) {
      lastDataFetchTime = moment(lastDataFetchTime).add(5, "m").toDate();
    }

    if (
      !integrationHistoryResponse ||
      !lastDataFetchTime ||
      (lastDataFetchTime != null && new Date() >= lastDataFetchTime)
    ) {
      getIntegrationHistoryData(false);
    }
  }, []);
  useEffect(() => {
    if (integHistoryData) {
      // if (isNullOrEmpty(value)) {
      // props.setIsLoading(true);
      createCopiableStringForAll(integHistoryData.integHistoryList);
      // }
    }
  }, [integHistoryData]);
  useEffect(() => {
    if (!integrationHistoryResponse) {
      return;
    }
    if (
      integrationHistoryResponse &&
      integrationHistoryResponse.StatusCode === 0
    ) {
      const enablePayloadColumn = hasPermission(
        PermissionName.VIEW_INTEGRATION_INFO
      )
        ? integrationHistoryResponse.IntegInfoList &&
          integrationHistoryResponse.IntegInfoList.map((history) => {
            return history.ResponsePayload || history.RequestPayload
              ? true
              : false;
          })
        : false;
      setIntegHistoryData({
        enablePayloadColumn: !enablePayloadColumn
          ? enablePayloadColumn
          : enablePayloadColumn.includes(true)
          ? true
          : false,
        integHistoryList: integrationHistoryResponse.IntegInfoList || [],
      });
      if (localStorage.getItem("LAST_FETCHED_TIME_INTEG_HISTORY")) {
        setLastTimeFetched(
          moment(
            localStorage.getItem("LAST_FETCHED_TIME_INTEG_HISTORY"),
            "MM:DD:YYYY:HH:mm:ss"
          ).toDate()
        );
      }
      //dispatch(getIntegrationHistoryReset());
    } else {
      showNotification(getBasicToastResponse(integrationHistoryResponse));
      dispatch(getIntegrationHistoryReset());
    }
  }, [integrationHistoryResponse]);
  useEffect(() => {
    if (!updateIntegStatusResponse) {
      return;
    }
    showNotification(getBasicToastResponse(updateIntegStatusResponse));
    if (updateIntegStatusResponse.StatusCode === 0) {
      dispatch(updateIntegStatusReset());
      dispatch(getIntegrationHistoryReset());
      props.resetParentPage();
      props.closeModal();
    }
    dispatch(updateIntegStatusReset());
  }, [updateIntegStatusResponse]);
  const getIntegrationHistoryData = () => {
    const req = {};
    req.ReservationId = defaultData.reservationId;
    dispatch(getIntegrationHistoryReset());
    dispatch(getIntegrationHistory(req));
  };
  const updateIntegrationStatus = () => {
    const req = {};
    req.ReservationId = defaultData.reservationId;
    req.IntegStatus = integStatus ? "SCHEDULED" : "SKIP_SYNC";
    dispatch(updateIntegStatusReset());
    dispatch(updateIntegStatus(req));
  };

  const openRequestResponsePayloadPopup = (rowData, index) => {
    setSelectedIntegData(rowData);
    setShowRequestResponsePayloadModal(true);
    setSelectedRow(index);
    setIsOpenOnes(isOpenOnes ? isOpenOnes : true);
  };
  const closeRequestResponsePayloadModal = () => {
    setSelectedIntegData(null);
    setShowRequestResponsePayloadModal(true);
    setSelectedRow(null);
  };

  const getCopyLinkHTML = (
    copiableData,
    payloadType,
    toolTipText,
    copyLinkType,
    successMsg
  ) => {
    return (
      <div className="text-right">
        <CopyToClipboard
          value={copiableData}
          linkCaption={payloadType}
          toolTipText={toolTipText}
          copyLinkType={copyLinkType}
          buttonClass="btn btn-outline-primary waves-effect waves-light btnSize pl-2 ml-2"
          iconClass="fa fa-copy pr-1 fs-2"
          imageClass="mt-1 pl-2"
          imageURL={CLIPBOARD}
          customImgCSS={{ height: "1.4rem" }}
          successMsg={successMsg}
        />
      </div>
    );
  };

  const prettyJson = (json) => {
    let prettyJsonData = JSON.stringify(JSON.parse(json), null, 2);
    return prettyJsonData;
  };

  const getFormattedNonXMLPayload = (data, isValidXMLData) => {
    const payloadObject = {};
    if (isValidXMLData) {
      payloadObject.XMLRequestXML = data;
      return payloadObject;
    }
    const splittedPayloadData = data ? data.split("&") : [];

    if (splittedPayloadData.length > 0) {
      const XMLRequestItems = splittedPayloadData[0].split("=\n");
      const callerCodeItem = splittedPayloadData[1];
      const passwordItem = splittedPayloadData[2];

      payloadObject.XMLRequestTitle = XMLRequestItems[0];
      payloadObject.XMLRequestXML = XMLRequestItems[1];
      payloadObject.CallerCodeItem = callerCodeItem;
      payloadObject.PasswordItem = passwordItem;
    } else {
      payloadObject.XMLRequestTitle = data;
    }
    return payloadObject;
  };

  const createCopiableStringForAll = (integData) => {
    let stringToCopy = "";
    let resType = "";
    let resTypeDescription = "";
    let resTypeError = "";
    let reqDataTime = "";
    let reqPayload = "";
    let resPayload = "";
    let isJsonReqPayload = false;
    let isJsonResPayload = false;
    integData &&
      integData.length &&
      integData.forEach((post, index) => {
        isJsonReqPayload = isJson(post.RequestPayload);
        isJsonResPayload = isJson(post.ResponsePayload);
        if (isJsonReqPayload) {
          reqPayload = prettyJson(post.RequestPayload);
        } else {
          let isValidXMLRequestPayload = isValidXML(post.RequestPayload);
          let formattedReqPayloadData = getFormattedNonXMLPayload(
            post.RequestPayload,
            isValidXMLRequestPayload
          );
          reqPayload = isValidXMLRequestPayload
            ? xmlFormat(post.RequestPayload, { ...xmlFormatProperties })
            : formattedReqPayloadData.XMLRequestXML ? xmlFormat(formattedReqPayloadData.XMLRequestXML, {
              ...xmlFormatProperties,
            }) : "";
        }
        if (isJsonResPayload) {
          resPayload = prettyJson(post.ResponsePayload);
        } else {
          let isValidXMLResponsePayload = isValidXML(post.ResponsePayload);
          let formattedResPayloadData = getFormattedNonXMLPayload(
            post.ResponsePayload,
            isValidXMLResponsePayload
          );
          resPayload = isValidXMLResponsePayload
            ? xmlFormat(xmlFormat(post.ResponsePayload), {
                ...xmlFormatProperties,
              })
            : !isNullOrEmpty(formattedResPayloadData.XMLRequestXML) ? xmlFormat(xmlFormat(formattedResPayloadData.XMLRequestXML), {
              ...xmlFormatProperties,
            }) : "";
        }
        resType = post.ResponseSummary && post.ResponseSummary.split(":")[0];
        resTypeDescription =
          post.ProviderName && ["GWY", "GCR"].includes(post.ProviderName)
            ? post.ProviderName + " " + IntegrationResponseType[resType]
            : IntegrationResponseType[resType] || post.ResponseSummary;
        resTypeError =
          post.ResponseSummary &&
          post.ResponseSummary.split(":")[1] &&
          post.ResponseSummary.split(":")[1].trim();
        reqDataTime =
          post.ResponseSummary && getDateTimeString(post.RequestTime, true);
        if (stringToCopy) {
          stringToCopy += "\n\n";
        }
        stringToCopy +=
          parseInt(index + 1) +
          ". " +
          resTypeDescription +
          (resTypeError ? "(" + resTypeError + ")" : "") +
          "_" +
          reqDataTime +
          "\n\n" +
          "Request:\n\n" +
          reqPayload +
          "\n\n" +
          "Response:\n\n" +
          resPayload;
      });
    setValue(stringToCopy);
  };
  const renderList = () => {
    let resType = "";
    let resTypeDescription = "";
    return integHistoryData.integHistoryList.map((post, index) => {
      const hasPayloadData = post.ResponsePayload || post.RequestPayload;
      resType = post.ResponseSummary && post.ResponseSummary.split(":")[0];
      resTypeDescription =
        post.ProviderName && ["GWY", "GCR"].includes(post.ProviderName)
          ? post.ProviderName + " " + IntegrationResponseType[resType]
          : IntegrationResponseType[resType] || post.ResponseSummary;
      return (
        <Fragment key={post.IntegrationId}>
          <div
            className={`
            ${
              integHistoryData.enablePayloadColumn
                ? "row payloadColumnShow"
                : "row"
            } ${selectedRow === index ? "selected-row-color mbr-2" : ""}
          `}
            key={post.IntegrationId}
          >
            <span className="row__column text-center">
              {post.IntegrationId}
            </span>
            <span className="row__column text-center">{post.IntegStatus}</span>
            <span className="row__column text-center">
              {post.ReservationStatus}
            </span>
            <span className="row__column text-center">
              {getDateTimeString(post.RequestTime,true)}
            </span>
            <span className="row__column text-center">
              {getDateTimeString(post.ResponseTime, true)}
            </span>
            <span
              className="row__column text-center"
              id={"ResponseLog" + post.IntegrationId}
            >
              {createTooltip(post.ResponseLog || "--", post.ResponseLog || "")}
            </span>

            <span
              className="row__column text-left"
              id={"responseSummary" + post.IntegrationId}
            >
              {createTooltip(
                resTypeDescription || "--",
                post.ResponseSummary ? resTypeDescription : ""
              )}
            </span>
            {integHistoryData.enablePayloadColumn ? (
              <span
                className={`${
                  hasPayloadData ? "pseudo-link" : ""
                } row__column text-center`}
                id={"payload" + post.IntegrationId}
                onClick={() =>
                  hasPayloadData
                    ? selectedRow === index
                      ? closeRequestResponsePayloadModal()
                      : openRequestResponsePayloadPopup(post, index)
                    : ""
                }
              >
                {hasPayloadData ? (
                  <Fragment>
                    <i
                      className={`fas ${
                        selectedRow === index
                          ? "fa-angle-double-up"
                          : "fa-angle-double-down"
                      } fs-3`}
                      style={{ paddingRight: "7px" }}
                    />

                    {selectedRow === index ? "Hide Payload" : "View Payload"}
                  </Fragment>
                ) : (
                  "--"
                )}
              </span>
            ) : (
              ""
            )}
          </div>
          {showRequestResponsePayloadModal &&
          selectedIntegData &&
          selectedRow === index ? (
            <div className="payload-section-outer-border">
              <div className="payload-select-item-border">
                <RequestResponsePayload
                  integData={selectedIntegData}
                  showModal={showRequestResponsePayloadModal}
                  closeModal={closeRequestResponsePayloadModal}
                  title={"Request and Response Payload"}
                  width="1100"
                  height="520"
                  getCopyLinkHTML={getCopyLinkHTML}
                  getFormattedNonXMLPayload={getFormattedNonXMLPayload}
                  xmlFormatProperties={xmlFormatProperties}
                />
              </div>
            </div>
          ) : (
            ""
          )}
        </Fragment>
      );
    });
  };
  let modalSettings = {
    modalWidth: "1200px",
    modalBodyHeight: "auto",
  };
  const getCurrentModalStyle = () => {
    if (isOpenOnes) {
      return {
        minWidth: modalSettings.modalWidth,
        position: "fixed",
        marginLeft: "-38%",
      };
    } else {
      return {
        minWidth: modalSettings.modalWidth,
      };
    }
  };
  return (
    <div className="input-container">
      <Draggable handle=".cursor">
        <Modal
          isOpen={props.showModal}
          className={
            isOpenOnes || selectedRow || selectedRow === 0
              ? "no-cursor horizontal-center-fixed"
              : "no-cursor transformX0"
          }
          toggle={() => {
            props.closeModal();
          }}
          style={{
            minWidth: modalSettings.modalWidth,
          }}
        >
          <ModalHeader
            className="modal-header bg-primary modal-title text-white cursor"
            toggle={() => {
              props.closeModal();
            }}
          >
            <i className="fas fa-arrows-alt" /> Integration Status Details
          </ModalHeader>
          <ModalBody
            style={{
              height: modalSettings.modalBodyHeight + "px",
            }}
          >
            <div
              className="form-group row"
              style={{
                height: modalSettings.modalBodyHeight - 20 + "px",
              }}
            >
              <Fragment>
                <div
                  className="col-sm-12"
                  style={{ display: "flex", justifyContent: "space-between",marginTop:'-5px' }}
                >
                  <div>
                    <span>
                      Data Last Refreshed On:{" "}
                      {lastTimeFetched
                        ? moment(lastTimeFetched).format("MM/DD/YYYY hh:mm:ss A")
                        : ""}
                    </span>
                    <span className="tt-container ml-3" name="tpContainer">
                      <button
                        className="btn btn-outline-primary waves-effect waves-light btnSize pl-2 ml-2"
                        onClick={() => getIntegrationHistoryData()}
                        style={{ border: "none",boxShadow:'none' }}
                      >
                        <i className="fa fa-sync-alt pr-1 fs-2"></i>Refresh
                      </button>
                    </span>
                  </div>
                  {integHistoryData.integHistoryList.length > 0 && integHistoryData.enablePayloadColumn
                    ? getCopyLinkHTML(
                        value,
                        "Copy All Payloads",
                        "Copy All Request and Response to Clipboard",
                        "button",
                        "All the payloads have been copied to clipboard!"
                      )
                    : ""}
                </div>
                <div className="FormRow col-sm-12">
                  <section className="grid-table" id="integrationStatusTable">
                    <div className="datagrid">
                      <div
                        className={
                          integHistoryData.enablePayloadColumn
                            ? "column payloadColumnShow"
                            : " column"
                        }
                      >
                        <span className="column__header_popup">
                          Integration Id
                        </span>
                        <span className="column__header_popup">
                          Last Integration Status
                        </span>
                        <span className="column__header_popup">
                          Res. Status
                        </span>
                        <span className="column__header_popup">
                          Request Time
                        </span>
                        <span className="column__header_popup">
                          Response Time
                        </span>
                        <span className="column__header_popup">
                          Error / Response Log
                        </span>
                        <span className="column__header_popup">
                          Error / Response Summary
                        </span>
                        {integHistoryData.enablePayloadColumn ? (
                          <span className="column__header_popup">
                            Request / Response
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                      <div
                        className="tableScroll"
                        style={{ maxHeight: "50rem" }}
                      >
                        {integHistoryData.integHistoryList.length > 0 ? (
                          <div>{renderList()}</div>
                        ) : (
                          <div
                            style={{
                              textAlign: "center",
                              marginTop: "5px",
                              border: "1px solid #DDDDDD",
                              borderTop: "none",
                            }}
                          >
                            No data available in table
                          </div>
                        )}
                      </div>
                    </div>
                  </section>
                  <div className="form-group formRow">
                    <div className="col-sm-12 icheck-primary pl-3 pt-2 text-center">
                      <input
                        type="checkbox"
                        name="IntegStatus"
                        id="cbIntegStatus"
                        checked={integStatus}
                        onChange={(event) =>
                          setIntegStatus(event.target.checked)
                        }
                      />
                      <label htmlFor="cbIntegStatus">
                        <span>Integrate with External Partner</span>
                      </label>
                    </div>
                  </div>
                </div>
              </Fragment>
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="col-lg-7 text-right">
              <button
                type="button"
                name="btnAddNewMember"
                className="btn btn-primary waves-effect waves-light btnSize ml-2"
                onClick={() => updateIntegrationStatus()}
              >
                Update
              </button>
              <button
                type="button"
                name="btnCancel"
                className="btn btn-secondary waves-effect waves-light btnSize ml-2"
                onClick={props.closeModal}
              >
                Cancel
              </button>
            </div>
          </ModalFooter>
        </Modal>
      </Draggable>
    </div>
  );
};
export default IntegrationHistoryModal;
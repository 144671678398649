import Moment from "moment";
export const MaxAddon = {
  TOTAL_ADDONS: 20,
  EQUIP_QUANTITY: 5,
};
export const ShuttleConnectionStatus = {
  ONLINE: "Online",
  OFFLINE: "Offline",
};
export const ShuttleEngineStatus = {
  ENGINE_OFF: "Engine Off",
  ENGINE_ON: "Engine On",
  MOVING: "Moving",
  TOWING: "Towing",
};
export const ShuttleAvailability = {
  // ALL: "All",
  IN_SERVICE: "In Service",
  OUT_OF_SERVICE: "Out Of Service",
};
export const ShuttleAmenities = {
  HANDICAP: "Handicap",
  WHEEL_CHAIR: "Wheel Chair",
};
export const DrivingTypes = {
  FRONT_WHEEL_DRIVE: "Front Wheel Drive",
  REAR_WHEEL_DRIVE: "Rear Wheel Drive",
  ALL_WHEEL_DRIVE: "All Wheel Drive",
};
export const FuelTypes = {
  UNSPECIFIED: "Unspecified",
  LPG_GAS: "LPG/Gas",
  HYDROGEN: "Hydrogen",
  MULTI: "Multi",
  PETROL: "Petrol",
  ETHANOL: "Ethanol",
  GAS: "Gasoline",
  DIESEL: "Diesel",
  HYBRID: "Hybrid",
  BIO_DIESEL: "Bio Diesel",
  ELECTRIC: "Electric",
};
export const FuelConsumptionUnitTypes = {
  GALLON: "Gallon",
  LITRE: "Litre",
};
export const TransMissionTypes = {
  AUTOMATIC: "Automatic",
  MANUAL: "Manual",
};
export const ColorTypes = {
  BLACK: "Black",
  DARK_BLUE: "Dark Blue",
  BLUE: "Blue",
  GOLD: "Gold",
  GREEN: "Green",
  GRAY: "Gray",
  LIGHT_BLUE: "Light Blue",
  MAGENTA: "Magenta",
  RED: "Red",
  SILVER: "Silver",
  WHITE: "White",
  PEPPER_WHITE: "Pepper White",
  YELLOW: "Yellow",
  BEIGE: "Beige",
  GLACIER: "Glacier",
  METALIC_GREY: "Metalic Grey",
  TAN: "Tan",
  BROWN: "Brown",
  ORANGE: "Orange",
  PURPLE: "Purple",
  OTHER: "Other",
};
export const LandmarkCategory = {
  GAS: "Gas",
  TERMINAL: "Terminal",
  HYDROGEN: "Hydrogen",
  FOX_LOCATION: "Fox Location",
};
export const MapType = {
  SHUTTLES: "SHUTTLES",
  LANDMARKS: "LANDMARKS",
};
export const enumToKeyValuesObject = (enumdata, noReqFirstKeyVal, type) => {
  const dataList = [];
  Object.keys(enumdata).map((key, index) => {
    if (index === 0 && !noReqFirstKeyVal) {
      dataList.push({ key: "", displayValue: "Select" });
    }
    dataList.push({ key: key, displayValue: enumdata[key] });
    return dataList;
  });
  return dataList;
};
export const getYearsDropDown = (minYear, firstKeyVal) => {
  const yearsDataList = [];
  yearsDataList.push({ key: "", displayValue: "Select" });
  let maxYear = Moment(new Date()).year();
  minYear = minYear || 1950;
  for (let i = minYear; i <= maxYear; i++) {
    yearsDataList.push({ key: i, displayValue: i });
  }
  return yearsDataList;
};
export const ShuttleTripAlerts = {
  HIGH_MAX_SPEED: "HIGH_MAX_SPEED",
  LOW_AVG_SPEED: "LOW_AVG_SPEED",
  HIGH_AVG_SPEED: "HIGH_AVG_SPEED",
  LOW_TRAVELLED_DISTANCE: "LOW_TRAVELLED_DISTANCE",
  HIGH_TRAVELLED_DISTANCE: "HIGH_TRAVELLED_DISTANCE",
  HIGH_TRIP_DURATION: "HIGH_TRIP_DURATION",
};
export const ShuttleTripAlertTooltip = {
  HIGH_MAX_SPEED: "High Max Speed",
  LOW_AVG_SPEED: "Low Average Speed",
  HIGH_AVG_SPEED: "High Average Speed",
  LOW_TRAVELLED_DISTANCE: "Low Traveled Distance",
  HIGH_TRAVELLED_DISTANCE: "High Traveled Distance",
  HIGH_TRIP_DURATION: "High Trip Duration",
};

export const ShuttleEngineStatusBgColorClass = {
  ENGINE_OFF: "status-bar-red",
  ENGINE_ON: "status-bar-yellow",
  MOVING: "status-bar-green",
  TOWING: "status-bar-blue",
};
export const ShuttleEngineStatusFontColorClass = {
  ENGINE_OFF: "status-font-color-red",
  ENGINE_ON: "status-font-color-yellow",
  MOVING: "status-font-color-green",
  TOWING: "status-font-color-blue",
};
export const SurchargeType = {
  SURCHARGE: "SURCHARGE",
  CHARGE: "CHARGE",
  CREDIT: "CREDIT",
};
/****Enum Utility Function *****/
export const getShuttleInfoColumn = (make, model, year) => {
  let shuttleInfo = "";
  if (make) {
    shuttleInfo = make;
  }
  if (model) {
    shuttleInfo = shuttleInfo ? shuttleInfo + ", " + model : model;
  }
  if (year) {
    shuttleInfo = shuttleInfo ? shuttleInfo + ", " + year : year;
  }
  return shuttleInfo;
};
export const getShuttleCombineStatusLabel = (
  shuttleCommonStatus,
  lastEngineStatus
) => {
  if (
    ShuttleConnectionStatus[shuttleCommonStatus] ===
    ShuttleConnectionStatus.OFFLINE
  ) {
    return ShuttleConnectionStatus.OFFLINE;
  } else if (
    ShuttleConnectionStatus[shuttleCommonStatus] ===
      ShuttleConnectionStatus.ONLINE &&
    ShuttleEngineStatus[lastEngineStatus] === ShuttleEngineStatus.ENGINE_ON
  ) {
    return (
      ShuttleConnectionStatus.ONLINE + " / " + ShuttleEngineStatus.ENGINE_ON
    );
  } else if (
    ShuttleConnectionStatus[shuttleCommonStatus] ===
      ShuttleConnectionStatus.ONLINE &&
    ShuttleEngineStatus[lastEngineStatus] === ShuttleEngineStatus.MOVING
  ) {
    return ShuttleConnectionStatus.ONLINE + " / " + ShuttleEngineStatus.MOVING;
  } else if (
    ShuttleConnectionStatus[shuttleCommonStatus] ===
      ShuttleConnectionStatus.ONLINE &&
    ShuttleEngineStatus[lastEngineStatus] === ShuttleEngineStatus.ENGINE_OFF
  ) {
    return (
      ShuttleConnectionStatus.ONLINE + " / " + ShuttleEngineStatus.ENGINE_OFF
    );
  } else if (
    ShuttleConnectionStatus[shuttleCommonStatus] ===
      ShuttleConnectionStatus.ONLINE &&
    ShuttleEngineStatus[lastEngineStatus] === ShuttleEngineStatus.TOWING
  ) {
    return ShuttleConnectionStatus.ONLINE + " / " + ShuttleEngineStatus.TOWING;
  } else {
    return null;
  }
};
export const CommonColor = {
  GREY: "grey",
  GREEN: "green",
  RED: "red",
  ORANGE: "orange",
  PURPLE: "orange",
  BLUE: "blue",
};
export const ColorCode = {
  SHUTTLE_GREY: "grey",
  SHUTTLE_GREEN: "#007D4B",
  SHUTTLE_RED: "#B81D13",
  SHUTTLE_ORANGE: "orange",
  LANDMARK_GREY: "grey",
  LANDMARK_GREEN: "green",
  LANDMARK_RED: "red",
  LANDMARK_BLUE: "blue",
};
export const getShutlleIconColor = (shuttleCommonStatus, lastEngineStatus) => {
  if (shuttleCommonStatus && lastEngineStatus) {
    if (
      ShuttleConnectionStatus[shuttleCommonStatus] ===
      ShuttleConnectionStatus.OFFLINE
    ) {
      return ColorCode.SHUTTLE_GREY;
    } else if (
      ShuttleConnectionStatus[shuttleCommonStatus] ===
        ShuttleConnectionStatus.ONLINE &&
      (ShuttleEngineStatus[lastEngineStatus] ===
        ShuttleEngineStatus.ENGINE_ON ||
        ShuttleEngineStatus[lastEngineStatus] === ShuttleEngineStatus.MOVING)
    ) {
      return ColorCode.SHUTTLE_GREEN;
    } else if (
      ShuttleConnectionStatus[shuttleCommonStatus] ===
        ShuttleConnectionStatus.ONLINE &&
      ShuttleEngineStatus[lastEngineStatus] === ShuttleEngineStatus.ENGINE_OFF
    ) {
      return ColorCode.SHUTTLE_RED;
    } else if (
      ShuttleConnectionStatus[shuttleCommonStatus] ===
        ShuttleConnectionStatus.ONLINE &&
      ShuttleEngineStatus[lastEngineStatus] === ShuttleEngineStatus.TOWING
    ) {
      return ColorCode.SHUTTLE_ORANGE;
    }
  } else {
    return ColorCode.SHUTTLE_GREY;
  }
};
export const getLandmarkIconColor = (landMarkCategoryKey) => {
  if (landMarkCategoryKey) {
    if (
      LandmarkCategory[landMarkCategoryKey] === LandmarkCategory.FOX_LOCATION
    ) {
      return ColorCode.LANDMARK_BLUE;
    } else if (
      LandmarkCategory[landMarkCategoryKey] === LandmarkCategory.TERMINAL
    ) {
      return ColorCode.LANDMARK_RED;
    } else if (LandmarkCategory[landMarkCategoryKey] === LandmarkCategory.GAS) {
      return ColorCode.LANDMARK_GREEN;
    }
  } else {
    return ColorCode.LANDMARK_BLUE;
  }
};
export const getAmenitiesDisplayValue = (amenitiesList) => {
  let amenitiesDisplayValue = "";
  amenitiesList.map((data) => {
    const displayVal = ShuttleAmenities[data];
    return amenitiesDisplayValue
      ? amenitiesDisplayValue + ", " + displayVal
      : displayVal;
  });
  return amenitiesDisplayValue;
};

export const IntegrationResponseType = {
  REQCAN: "TSD Cancel Reservation",
  ADDREZ: "TSD Add or Edit Reservation",
  REQRAT: "TSD Get Rates",
  REQEXT: "TSD Get Addons",
  REQLAS: "TSD Get Customer",
  REQLRS: "TSD Get Loyalty Points",
  REQLOC: "TSD Get Rental Location",
  REQREZ: "TSD Get Reservation",
  GET: "Get Reservation",
  CANCEL: "Cancel Reservation",
  ADDEDIT: "Add or Edit Reservation",
  RATES: "Get Rates",
  ADDONS: "Get Addons",
  RENTALLOCATION: "Get Rental Location",
  CARCATEGORIES: "Get Car Categories",
  // GET: "GCR Get Reservation",
  // CANCEL: "GCR Cancel Reservation",
  // ADDEDIT: "GCR Add or Edit Reservation",
  // RATES: "GCR Get Rates",
  // ADDONS: "GCR Get Addons",
  // RENTALLOCATION: "GCR Get Rental Location",
  // GET: "GWY Get Reservation",
  // CANCEL: "GWY Cancel Reservation",
  // ADDEDIT: "GWY Add or Edit Reservation",
  // RATES: "GWY Get Rates",
  // ADDONS: "GWY Get Addons",
  // RENTALLOCATION: "GWY Get Rental Location",
  // CARCATEGORIES: "GWY Get Car Categories",
  PAYMENT_CCAUTH_AUTH: "TEMPUS Authorize Payment",
  PAYMENT_CCAUTH_SALE: "TEMPUS Debit Payment",
  PAYMENT_CCBATCHDETAIL: "TEMPUS Get Batch Details",
  PAYMENT_CCREVERSE: "TEMPUS Void Payment",
  PAYMENT_CCFORCEAUTH: "TEMPUS Capture Payment",
  PAYMENT_CCCREDIT: "TEMPUS Refund Payment",
  PAYMENT_CCRECEIPTRETRIEVE: "TEMPUS Get Transaction Details",
  PAYMENT_CCTATOKENIZE: "TEMPUS Get Token Request",
  PAYMENT_VALIDATE_TOKEN_CCAUTH: "TEMPUS Pre Auth to Validate Token",
  PAYMENT_VALIDATE_TOKEN_CCREVERSE: "TEMPUS Void Pre Auth of Validate Token",
  FOXWS_EXTERNAL_V1_RATES_GET: "FOXAPI Get Rates",
  FOXWS_EXTERNAL_V1_ADDONS_GET: "FOXAPI Get Addons",
  FOXWS_EXTERNAL_V1_RESERVATION_POST: "FOXAPI Add or Edit Reservation",
  FOXWS_EXTERNAL_V1_RESERVATION_GET: "FOXAPI Get Reservation",
  FOXWS_EXTERNAL_V1_RESERVATION_DELETE: "FOXAPI Cancel Reservation",
  FOXWS_EXTERNAL_V1_RESERVATION_PATCH: "FOXAPI Sync Reservation",
  FOXWS_EXTERNAL_V1_VEHAVAILRATE: "TRAVELPORT Get All Rates",
  FOXWS_EXTERNAL_V1_VEHRATERULE: "TRAVELPORT Get Category Rate",
  FOXWS_EXTERNAL_V1_VEHRES: "TRAVELPORT Add or Sync Reservation",
  FOXWS_EXTERNAL_V1_VEHCANCEL: "TRAVELPORT Cancel Reservation",
  JSIWS_SERVICES_CALLCENTER_FOXAPI_RATES_GET: "ADMINAPI Get Rates",
  JSIWS_SERVICES_CALLCENTER_FOXAPI_ADDONS_GET: "ADMINAPI Get Addons",
  JSIWS_SERVICES_CALLCENTER_FOXAPI_RESERVATION_POST:
    "ADMINAPI Add or Edit Reservation",
  JSIWS_SERVICES_CALLCENTER_FOXAPI_RESERVATION_GET: "ADMINAPI Get Reservation",
  JSIWS_SERVICES_CALLCENTER_FOXAPI_RESERVATION_DELETE:
    "ADMINAPI Cancel Reservation",
};

export const FleetParametersDisplayValue = {
  dropOffCount: "Drop Offs",
  pickupCount: "Pick Ups",
  defaultPickupThresholdPercent: "Pick Up Threshold",
  defaultBookThruThresholdPercent: "Book Through Threshold",
  noShowFactor: "Predicted No Shows",
  walkinsCount: "Predicted Walkins",
  runningReservationsCount: "Reservations Running",
  runningRentalsCount: "Rentals Running",
  usableFleetCount: "Usable Fleet",
  availableFleetCount: "Idle Fleet Average",
  eodAvailableFleetCount: "Ending Available Fleet",
  override: "Last Override",
  utilizationFactorPercent: "Predicted Utilization",
  fleetUtilizationForecastId: "Fleet Utilization Forecast ID",
  orgUnitFleetClassGroupId: "Org Unit Fleet Class Group ID",
  lastTimestamp: "Last Time stamp",
  displayText: "Display Text",
  forecastDate: "Forecast Date",
  classGroupCode: "Class Group Code",
  manualNoShowFactor: "Manual NoShow Factor",
  manualWalkinsCount: "Manual Walkins Count",
  manualOverrideReason: "Manual Override Reason",
  manualPickupThresholdPercent: "Manual Pickup Threshold Percent",
  manualBookThruThresholdPercent: "Manual Book Through Threshold Percent",
  manualOverrideReasonDisplayName: "Manual Override Reason",
  lastManualOverrideTimestamp: "Last Manual Override Timestamp",
  pickupStatus: "Pickup Status",
  bookThruStatus: "Book Through Status",
};
export const ReserveSyncOverrideParam = {
  defaultPickupThresholdPercent: "manualPickupThresholdPercent",
  defaultBookThruThresholdPercent: "manualBookThruThresholdPercent",
  noShowFactor: "manualNoShowFactor",
  walkinsCount: "manualWalkinsCount",
};
export const FleetClassName = {
  ACCEPTING: "bg-accepting",
  NEAR_TO_CLOSE: "bg-near-to-close",
  CLOSED: "bg-close",
};
export const ReserveSyncDefaultVal = {
  totalDays: 90,
  totalDaysPerPeriod: 14,
};
/****Enum Utility Function End Here*****/

export const AdminDateFromat = {
  FOX_API_ONLY_DATE: "YYYY-MM-DD",
  FOX_API_DATE_TIME: "YYYY-MM-DDTHH:mm:ss",
  DATE_FORMAT_ONE: "ddd, DD MMM, YY",
  TOOLTIP_DATE_TIME_FORMAT: "DD MMM, YYYY, HH:mm:ss",
  RESERVE_SYNC_COLUMN_PERIOD_DATE_FORMAT: "MM-DD-YY",
  COLUMN_FORMAT: "ddd, DD MMM, YYYY",
  COLUMN_FORMAT_WITH_TIME: "DD MMM, YYYY hh:mm A",
  USA_DATE_FORMAT: "MM-DD-YYYY",
  USA_DATE_TIME_FORMAT: "MM-DD-YYYY HH:m",
};
export const ExportType = {
  CSV: "csv",
  PDF: "pdf",
  XLS: "xls",
};
export const UnitDisplayText = {
  MONTHLY: "Month",
  WEEKLY: "Week",
  WEEKEND: "Day",
  DAILY: "Day",
  HOURLY: "Hour",
  ONETIME: "Rental",
  PERCENT: "Percent",
};
export const PaymentType = {
  PREPAID: "PrePaid",
  PAYLATER: "PayLater",
  BOTH: "Both",
};
export const PaymentRateType = {
  MONTHLY: "MONTHLY",
  WEEKLY: "WEEKLY",
  DAILY: "DAILY",
  WEEKEND: "WEEKEND",
  HOURLY: "HOURLY",
};
export const FoxApiMsgType = {
  SUCCESS: "SUCCESS",
  ERROR: "ERROR",
};
export const CountryKeyValueType = {
  CountryId: "countryId",
  CountryCode: "countryCode",
  US: "US",
  IN: "IN",
};
export const AddressType = {
  PRIMARY_ADDRESS: "primaryAddress",
  HOME_ADDRESS: "homeAddress",
  WORK_ADDRESS: "workAddress",
  OTHER_ADDRESS: "otherAddress",
};
export const AddressTypeActual = {
  HOME: "HOME",
  WORK: "WORK",
  OTHER: "OTHER",
};
export const PhoneType = {
  MOBILE: "Mobile",
  HOME: "Home",
  WORK: "Work",
};
export const DefaultAddressProperty = {
  country: "country",
  state: "province",
  city: "city",
  postalCode: "postalCode",
  street: "street",
  street2: "street2",
};
export const Position = {
  LEFT: "LEFT",
  RIGHT: "RIGHT",
};
export const HostName = {
  LOCAL_HOST: "localhost",
  DEV: "admin-dev.justshareit.com",
  QA: "admin-qa.justshareit.com",
  UAT: "admin-uat.justshareit.com",
  PROD: "admin.justshareit.com",
};
export const DiscountType = {
  ONETIME: "ONETIME",
  COMPANY_CODE: "COMPANY_CODE",
  STORE_CREDIT: "STORE_CREDIT",
  OTHER: "OTHER",
};
export const DiscountStatus = {
  USED: "USED",
  GRANTED: "GRANTED",
  REVERTED: "REVERTED",
};

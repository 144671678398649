import React, { useState } from "react";
import ReactHtmlParser from "react-html-parser";
import ModalEngine from "../../../components/modal/modal-engine";
import LabelWithTooltipOnOverflowText from "../../../components/tooltip/conditional-title-tooltip";
import ModalInfo from "../../../services/entities/models/ModalInfo";
import { ModalType } from "../../../shared/GlobalVar";
import PermissionName from "../../../shared/Permissions";
import {
  convertDateTimeString,
  convertDateTimeStringWithTimezone,
  convertSecondToDayHourMinSec,
  getDateOnlyString,
  getNoteTooltipHtml,
  getSortingClassName,
  getSortType,
  hasPermission,
  isNullOrEmpty,
  toTitleCase,
} from "../../../shared/utility";
export const SearchServiceGrid = (props) => {
  const [modalInfo, setModalInfo] = useState([new ModalInfo()]);
  const hasReportViewPermission = hasPermission(PermissionName.REPORT_SEARCH);
  const hasViewMemberPermission = hasPermission(PermissionName.VIEW_MEMBER);
  const hasViewVehiclePermission = hasPermission(PermissionName.VIEW_VEHICLE);
  const closeModal = () => {
    setModalInfo(new ModalInfo());
  };
  const showModal = (modalData) => {
    setModalInfo({
      type: modalData.type,
      showModal: true,
      trackerDetails: modalData.trackerDetails,
      memberId: modalData.memberId,
    });
  };
  const getTableRows = props.dataList.map((serviceAgentData) => {
    let trackerId = serviceAgentData.TrackerId;
    return (
      <div className="row" key={serviceAgentData.TrackerId}>
        <span className="row__column cb-container">
          <ul className="icon">
            <li>
              <div
                id={serviceAgentData.Status + "_" + trackerId}
                className={`colorBox ${serviceAgentData.Status}`}
                style={{
                  height: "14px",
                  width: "14px",
                }}
                title={toTitleCase(serviceAgentData.Status)}
              />
            </li>
          </ul>
        </span>
        <span className="row__column">
          <LabelWithTooltipOnOverflowText
            id={"createdDate_" + trackerId}
            content={serviceAgentData.CreatedOn
              ? getDateOnlyString(serviceAgentData.CreatedOn)
              : "--"}
            title={convertDateTimeStringWithTimezone(
              serviceAgentData.CreatedOn,
              serviceAgentData.TimezoneString
            )}
            forceShow={true}
          />
        </span>

        <span className="row__column">
          <LabelWithTooltipOnOverflowText
            id={"processingTimeId_" + trackerId}
            content={serviceAgentData?.ProcessingTime
              ? convertSecondToDayHourMinSec(serviceAgentData.ProcessingTime)
              : "--"}
            title={ReactHtmlParser(
              getNoteTooltipHtml(
                serviceAgentData.ProcessingTime
                  ? convertSecondToDayHourMinSec(serviceAgentData.ProcessingTime)
                  : ""
              )
            )}
          />
        </span>
        <span className="row__column">
          <LabelWithTooltipOnOverflowText
            id={"trackerId_" + trackerId}
            content={trackerId || "--"}
            title={trackerId}
            classNames={hasReportViewPermission ? "pseudo-link" : ""}
            onClickAction={(event) =>
              hasReportViewPermission
                ? showModal({
                  type: ModalType.TRACKER_QUICK_MODAL,
                  showModal: true,
                  trackerDetails: serviceAgentData,
                })
                : ""
            }
          />
        </span>
        <span className="row__column">
          <LabelWithTooltipOnOverflowText
            id={"agentId_" + trackerId}
            content={serviceAgentData.AgentName || "--"}
            title={serviceAgentData.AgentName}
            classNames={hasViewMemberPermission ? "pseudo-link" : ""}
            onClickAction={(event) =>
              hasViewMemberPermission
                ? showModal({
                  type: ModalType.MEMBER_QUICK_MODAL,
                  showModal: true,
                  memberId: serviceAgentData.AgentId,
                })
                : ""
            }
          />
        </span>
        <span className="row__column">
          <LabelWithTooltipOnOverflowText
            id={"status_" + trackerId}
            content={serviceAgentData?.Status || "--"}
            title={serviceAgentData.Status}
          />
        </span>
        <span className="row__column">
          <LabelWithTooltipOnOverflowText
            id={"vehicleId_" + trackerId}
            content={serviceAgentData.VehicleId || "--"}
            title={serviceAgentData.VehicleId}
            classNames={hasViewVehiclePermission && serviceAgentData.VehicleId ? "pseudo-link" : ""}
            onClickAction={(event) =>
              hasViewVehiclePermission && serviceAgentData.VehicleId
                ? showModal({
                  type: ModalType.VEHICLE_QUICK_MODAL,
                  showModal: true,
                  vehicleId: serviceAgentData.VehicleId,
                })
                : ""
            }
          />
        </span>
        <span className="row__column">
          <LabelWithTooltipOnOverflowText
            id={"cleaningType_" + trackerId}
            content={serviceAgentData?.TrackerSubType || "--"}
            title={serviceAgentData.TrackerSubType}
          />
        </span>
        <span className="row__column text-center">
          <label>
            {!isNullOrEmpty(serviceAgentData.TiresCondition)
              ? serviceAgentData.TiresCondition
                ? "Good"
                : "Poor"
              : "--"}
          </label>
        </span>
        <span className="row__column text-center">
          <label>
            {!isNullOrEmpty(serviceAgentData.FuelRequired)
              ? serviceAgentData.FuelRequired
                ? "Yes"
                : "No"
              : "--"}
          </label>
        </span>
        <span className="row__column text-center">
          <label>
            {!isNullOrEmpty(serviceAgentData.IndividuallyPerformed)
              ? serviceAgentData.IndividuallyPerformed
                ? "Yes"
                : "No"
              : "--"}
          </label>
        </span>
        <span className="row__column text-center">
          <label>
            {!isNullOrEmpty(serviceAgentData.VinManualEntry)
              ? serviceAgentData.VinManualEntry
                ? "Yes"
                : "No"
              : "--"}
          </label>
        </span>
        <span className="row__column">
          <LabelWithTooltipOnOverflowText
            id={"startTimeId_" + trackerId}
            content={serviceAgentData.StartTime
              ? convertDateTimeString(serviceAgentData.StartTime)
              : "--"}
            title={convertDateTimeStringWithTimezone(
              serviceAgentData.StartTime,
              serviceAgentData.TimezoneString
            )}
            forceShow={true}
          />
        </span>
        <span className="row__column">
          <LabelWithTooltipOnOverflowText
            id={"startTimeId_" + trackerId}
            content={serviceAgentData.EndTime
              ? convertDateTimeString(serviceAgentData.EndTime)
              : "--"}
            title={convertDateTimeStringWithTimezone(
              serviceAgentData.EndTime,
              serviceAgentData.TimezoneString
            )}
            forceShow={true}
          />
        </span>
        <span className="row__column">
          <LabelWithTooltipOnOverflowText
            id={"employeeLocationId_" + trackerId}
            content={serviceAgentData.EmployeeLocationName || "--"}
            title={serviceAgentData.EmployeeLocationName || "--"}
          />
        </span>
      </div>
    );
  });
  return (
    <div className="datagrid">
      <div className="column">
        <span className="column__header" />
        <span
          className={getSortingClassName(props.pageInfo, "CreatedOn")}
          onClick={(event) =>
            props.doSorting(
              getSortType(props.pageInfo, "CreatedOn"),
              "CreatedOn"
            )
          }
        >
          Date Created
        </span>
        <span
          className={getSortingClassName(props.pageInfo, "ProcessingTime")}
          onClick={(event) =>
            props.doSorting(
              getSortType(props.pageInfo, "ProcessingTime"),
              "ProcessingTime"
            )
          }
        >
          Processing Time
        </span>
        <span
          className={getSortingClassName(props.pageInfo, "TrackerId")}
          onClick={(event) =>
            props.doSorting(
              getSortType(props.pageInfo, "TrackerId"),
              "TrackerId"
            )
          }
        >
          Tracker ID
        </span>
        <span
          className={getSortingClassName(props.pageInfo, "AgentName")}
          onClick={(event) =>
            props.doSorting(
              getSortType(props.pageInfo, "AgentName"),
              "AgentName"
            )
          }
        >
          Agent
        </span>
        <span
          className={getSortingClassName(props.pageInfo, "Status")}
          onClick={(event) =>
            props.doSorting(getSortType(props.pageInfo, "Status"), "Status")
          }
        >
          Status
        </span>
        <span
          className={getSortingClassName(props.pageInfo, "VehicleId")}
          onClick={(event) =>
            props.doSorting(
              getSortType(props.pageInfo, "VehicleId"),
              "VehicleId"
            )
          }
        >
          Vehicle ID
        </span>
        <span
          className={getSortingClassName(props.pageInfo, "TrackerSubType")}
          onClick={(event) =>
            props.doSorting(
              getSortType(props.pageInfo, "TrackerSubType"),
              "TrackerSubType"
            )
          }
        >
          Cleaning Type
        </span>
        <span
          className={getSortingClassName(props.pageInfo, "TiresCondition")}
          onClick={(event) =>
            props.doSorting(
              getSortType(props.pageInfo, "TiresCondition"),
              "TiresCondition"
            )
          }
        >
          Tire Condition
        </span>
        <span
          className={getSortingClassName(props.pageInfo, "FuelRequired")}
          onClick={(event) =>
            props.doSorting(
              getSortType(props.pageInfo, "FuelRequired"),
              "FuelRequired"
            )
          }
        >
          Need Fuel
        </span>
        <span
          className={getSortingClassName(
            props.pageInfo,
            "IndividuallyPerformed"
          )}
          onClick={(event) =>
            props.doSorting(
              getSortType(props.pageInfo, "IndividuallyPerformed"),
              "IndividuallyPerformed"
            )
          }
        >
          {ReactHtmlParser("Individually <br>Performed")}
        </span>
        <span
          className={getSortingClassName(props.pageInfo, "VinManualEntry")}
          onClick={(event) =>
            props.doSorting(
              getSortType(props.pageInfo, "VinManualEntry"),
              "VinManualEntry"
            )
          }
        >
          VIN Manual Entry
        </span>
        <span
          className={getSortingClassName(props.pageInfo, "StartTime")}
          onClick={(event) =>
            props.doSorting(
              getSortType(props.pageInfo, "StartTime"),
              "StartTime"
            )
          }
        >
          Start Date/Time
        </span>
        <span
          className={getSortingClassName(props.pageInfo, "EndTime")}
          onClick={(event) =>
            props.doSorting(getSortType(props.pageInfo, "EndTime"), "EndTime")
          }
        >
          End Date/Time
        </span>
        {/* <span
          className={getSortingClassName(props.pageInfo, "ProfileLocationName")}
          onClick={(event) =>
            props.doSorting(
              getSortType(props.pageInfo, "ProfileLocationName"),
              "ProfileLocationName"
            )
          }
        >
          Profile Location
        </span> */}
        <span
          className={getSortingClassName(props.pageInfo, "EmployeeLocationName")}
          onClick={(event) =>
            props.doSorting(
              getSortType(props.pageInfo, "EmployeeLocationName"),
              "EmployeeLocationName"
            )
          }
        >
          Employee Location
        </span>
      </div>
      {getTableRows}
      {modalInfo.showModal ? (
        <ModalEngine
          modalType={modalInfo.type}
          showModal={modalInfo.showModal}
          closeModal={closeModal}
          trackerDetails={modalInfo.trackerDetails}
          memberId={modalInfo.memberId}
        />
      ) : null}
    </div>
  );
};
export default SearchServiceGrid;

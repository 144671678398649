import React, { Component } from "react";
import {
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  UncontrolledDropdown,
} from "reactstrap";
import { getFullName, loadDefaultImage } from "../../shared/utility";
import { NavLink } from "react-router-dom";
import { SPRITE_IMG, NoUserPhoto } from "../../shared/AppImages";
import ImageContainer from "../UI/image/image-container";
class UserDropdown extends Component {
  state = {
    showDropMenu: false,
  };
  showDropMenu = () => {
    if (!this.state.showDropMenu) {
      this.setState({ showDropMenu: true });
    }
  };
  closeDropMenu = () => {
    this.setState({ showDropMenu: this.state.showDropMenu ? false : true });
  };
  render() {
    const userFullName = this.props.userData
      ? getFullName(
          this.props.userData.FirstName,
          this.props.userData.LastName,
          this.props.userData.MiddleName
        )
      : "";
    return (
      <UncontrolledDropdown
        isOpen={this.state.showDropMenu}
        toggle={this.closeDropMenu}
        onClick={(event) => {
          this.showDropMenu();
        }}
      >
        <DropdownToggle
          tag="div"
          className={`user-nav__user ${this.props.isImpersonateMode ? "" : "pl-3 pr-5"}`}
          title={userFullName}
        >
          <ImageContainer
            imageUrl={
              (this.props.userData && this.props.userData.ProfileImageUrl) ||
              NoUserPhoto
            }
            imageCaption={
              (this.props.userData && this.props.userData.FirstName) || ""
            }
            imageTooltip={userFullName}
            imageCaptionClass={`d-flex pl-0 ${this.props.isImpersonateMode ? "" : "whiteColor"}`}
            backgroundImageClass={"user-nav__user-photo p-0"}
            defaultImage={NoUserPhoto}
            onErrorEvent={loadDefaultImage}
            altText="User"
          />
        </DropdownToggle>
        {this.state.showDropMenu ? (
          <DropdownMenu right>
            <DropdownItem>
              {localStorage.getItem("loginMode") === "IMPERSONATE" ? (
                <div
                  style={{
                    maxHeight: "40rem",
                    minWidth: "20rem",
                    overflow: "auto",
                  }}
                >
                  <a
                    href="javascript:void(0)"
                    className="dropmenu__content--link d-flex"
                    onClick={(event) => this.props.impersonateExit()}
                    style={{alignItems:"center"}}
                  >
                    <svg className="user-nav__icon color--red">
                      <use xlinkHref={SPRITE_IMG + "#icon-log-out"} />
                    </svg>
                    <span className="dropmenu__content--link--text">
                      Exit Impersonate
                    </span>
                  </a>
                </div>
              ) : (
                <div
                  style={{
                    maxHeight: "40rem",
                    minWidth: "20rem",
                    overflow: "auto",
                  }}
                >
                  <NavLink
                    to="/userprofile"
                    className="dropmenu__content--link d-flex"
                    activeClassName="active"
                    style={{alignItems:"center"}}
                  >
                    <svg className="user-nav__icon color--green">
                      <use xlinkHref={SPRITE_IMG + "#icon-user"} />
                    </svg>
                    <span className="dropmenu__content--link--text">
                      Profile
                    </span>
                  </NavLink>
                  <NavLink
                    to="/logout"
                    className="dropmenu__content--link d-flex"
                    activeClassName="active"
                    style={{alignItems:"center"}}
                  >
                    <svg className="user-nav__icon color--red">
                      <use xlinkHref={SPRITE_IMG + "#icon-log-out"} />
                    </svg>
                    <span className="dropmenu__content--link--text">
                      Logout
                    </span>
                  </NavLink>
                </div>
              )}
            </DropdownItem>
          </DropdownMenu>
        ) : (
          ""
        )}
      </UncontrolledDropdown>

      // <div className="user-nav__user dropmenu">
      //   <img
      //     onError={event => Utility.loadDefaultImage(event, NoUserPhoto)}
      //     src={this.props.userData.ProfileImageUrl || NoUserPhoto}
      //     alt="User"
      //     className="user-nav__user-photo"
      //   />
      //   <span className="user-nav__user-name">
      //     {this.props.userData.FirstName}
      //   </span>
      //   <div className="dropmenu__content dropmenu--right">

      //   </div>
      // </div>
    );
  }
}
export default UserDropdown;

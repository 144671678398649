import React, { Fragment } from "react";

const LogoCreator = (props) => {
  let defaultLogoSize = "logo-size";
  return (
    <Fragment>
      {props.backgroundImageSource ? (
        <img
          src={props.backgroundImageSource}
          alt="no logo"
          className={`${props.logoSizeClass || defaultLogoSize} ${
            props.backgroundImageClass || "background-image-class logo"
          } `}
          style={props.customImageBGCSS}
        />
      ) : (
        <div
          className={`${props.logoSizeClass || defaultLogoSize}  ${
            props.backgroundClass || "bg-transparent"
          }`}
          style={props.customBGCSS}
        />
      )}
      <span
        className={props.logoCaptionClass || "logo-text"}
        title={props.logCaption || "FOX"}
      >
        {props.logCaption || "FOX"}
      </span>
    </Fragment>
  );
};

export default LogoCreator;

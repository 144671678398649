import React from "react";
import { Link } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";

const Breadcrumbs = (props) => {
  let breadCrumbListArr = props.breadCrumbList || [];

  const currentIndex = breadCrumbListArr.findIndex(
    (item) => item.title === props.title
  );

  //filterDuplicateLink
  breadCrumbListArr = breadCrumbListArr.reduce((acc, current) => {
    const x = acc.find((item) => item.title === current.title);
    if (!x) {
      return acc.concat([current]);
    } else {
      return acc;
    }
  }, []);

  //removeNextAllFromCurrentItem
  if (currentIndex !== -1) {
    breadCrumbListArr = breadCrumbListArr.filter((user) => {
      return user.position <= currentIndex;
    });
  }

  const breadcrumbItems = breadCrumbListArr.map((breadcrumbObj, index) => {
    let pathNameArray = breadcrumbObj.pathname.split("?");
    let pathname = pathNameArray[0];
    let pathParameter = pathNameArray[1];
    return (
      <BreadcrumbItem key={index}>
        <Link
          to={{
            pathname: pathname,
            search: pathParameter,
            state: {
              breadCrumbList: props.breadCrumbList,
              title: breadcrumbObj.title,
            },
          }}
        >
          {breadcrumbObj.title}
        </Link>
      </BreadcrumbItem>
    );
  });
  return (
    <Breadcrumb>
      {breadcrumbItems}
      {props.title ? <BreadcrumbItem>{props.title}</BreadcrumbItem> : ""}
    </Breadcrumb>
  );
};

export default Breadcrumbs;

import React from 'react';
import Draggable from "react-draggable";
import { Modal } from "reactstrap";
import CommonModal from './common-modal';
function DraggableModal(props) {
    const {
        showModal,
        closeModal,
        setData,
        contentClassName,
        modalClassName,
        modalMinWidth = "372px",
        modalMaxWidth = "372px",
        handle = ".cursor",
    } = props;
    return (
        <Draggable handle={handle}>
            <Modal
                isOpen={showModal}
                className="no-cursor modern-modal"
                modalClassName={modalClassName}
                contentClassName={contentClassName}
                toggle={() => {
                    closeModal(setData);
                }}
                style={{ minWidth: modalMinWidth, modalMaxWidth: modalMaxWidth }}
            >
                <CommonModal {...props} />
            </Modal>
        </Draggable >
    )
}

export default DraggableModal
import { isNullOrEmpty } from "../../../../shared/utility";

export const FoxDrivingLicense = function (data,noDefaultCountry,isFoxAPi) {
    if (!data) data = {};
    // this.country = data.country ? data.country
    // : !noDefaultCountry && localStorage.getItem("orgUnitCountry")
    // ? isFoxAPi ? "US" : JSON.parse(localStorage.getItem("orgUnitCountry")).Key
    // : null;
    this.country = data.country || null;
    this.province = data.province || null;
    this.provinceText = data.provinceText || data.province;
    this.expDate = data.expDate || null;
    this.number = data.number || null;
    this.image = data.image || null;
    this.licenseEditable = !isNullOrEmpty(data.licenseEditable)? data.licenseEditable: null;
    this.manualDmvVerified = !isNullOrEmpty(data.manualDmvVerified)? data.manualDmvVerified: null;
    this.tempLicenseUploadAllowed = !isNullOrEmpty(data.tempLicenseUploadAllowed)? data.tempLicenseUploadAllowed: null;
    this.licenseManuallyApproved = !isNullOrEmpty(data.licenseManuallyApproved)? data.licenseManuallyApproved: null; 
    this.drivingRecordChecked = !isNullOrEmpty(data.drivingRecordChecked)? data.drivingRecordChecked: null;
};
import React from "react";
import LabelWithValue from "../../../../../components/UI/label/LabelWithValue";
import { getFormattedAddress } from "../../../../../shared/utility";

const ProviderInfoSection = ({ locationData }) => {
    const { DependentDisplayName, DependentAddress, DependentTollFreeNumber, DependentEmail } = locationData;
    const infoItems = [
        { label: "Name", value: DependentDisplayName },
        { label: "Address", value: DependentAddress ? getFormattedAddress(DependentAddress) : null },
        { label: "Phone", value: DependentTollFreeNumber },
        { label: "Email", value: DependentEmail }
    ];

    return (
        <div className="location-info-section">
            <span className="sectionTitle">Provider Info</span>
            <div className="location-info-items-container">
                {infoItems.map(({ label, value }) => (
                    <LabelWithValue key={label} label={label} value={value || null} />
                ))}
            </div>
        </div>
    );
};

export default ProviderInfoSection;

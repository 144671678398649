import Moment from "moment";
import React from "react";
import ReactHtmlParser from "react-html-parser";
import { DropdownList } from "react-widgets";
import Combobox from "react-widgets/lib/Combobox";
import DateTimePicker from "react-widgets/lib/DateTimePicker";
import PhoneViewModel from "../../../services/entities/view-models/phone-view-model";
import { DateInputFormats } from "../../../shared/utility/date-time-utility";
import { getKeyValFromList } from "../../../shared/utility/list-utility";
import { getDateTimeFormatString } from "../../common/common-helper";
import InputClearIcon from "../icon/input-clear-icon";
import { InputCtrlModel } from "../model/input-ctrl-model";
import { LblCtrlModel } from "../model/lbl-ctrl-model";
import { LblInputCtrlModel } from "../model/lbl-input-ctrl-model";
import { LblInputFieldCtrlModel } from "../model/lbl-input-field-ctrl-model";
import { RootContainerCtrlModel } from "../model/root-container-ctrl-model";
import MobileAutosuggestionCtrl from "./MobileAutosuggestionCtrl";
import PhoneNumberUI from "./phone-number-ui";
export const renderInputField = (
  fieldObj,
  value,
  onChange,
  fetchZiptoAddress,
  onInputBlur
) => {
  const input = fieldObj.inputModel || fieldObj.input;
  const onInputChangeHandler = (event, input, controlName) => {
    onChange(
      event,
      controlName ? controlName : input.name,
      input.type,
      input.objectType
    );
  };
  const onInputBlurRender = (event, input, controlName) => {
    onInputBlur(
      event,
      controlName ? controlName : input.name,
      input.type,
      input.objectType
    );
  };
  const onPhoneInputChangeHandler = (value, data, event, formattedValue) => {
    onChange(
      event,
      input.name,
      input.type,
      input.objectType,
      null,
      null,
      value,
      data,
      formattedValue
    );
  };
  const onZipToStateCiyChangeHandler = (event) => {
    fetchZiptoAddress && fetchZiptoAddress(event, input.objectType);
  };
  switch (input.type) {
    case "datetime":
      return (
        <DateTimePicker
          parse={DateInputFormats}
          name={input.name}
          value={value ? Moment(value, input.format).toDate() : null}
          onChange={(event) =>
            onChange ? onInputChangeHandler(event, input) : ""
          }
          placeholder={input.placeHolder}
          required={input.required}
          {...input.dateTimeConfig}
          disabled={input.disabled}
        />
      );
    case "textarea":
      return (
        <textarea
          type={input.type}
          name={input.name}
          onChange={(event) =>
            onChange ? onInputChangeHandler(event, input) : ""
          }
          value={value}
          minLength={input.minlength}
          maxLength={input.maxlength}
          required={input.required}
          rows={input.rows}
          disabled={input.disabled}
        />
      );
    case "checkbox":
      return (
        <div
          className={`icheck-primary${
            input.className ? " " + input.className : ""
          }`}
        >
          <input
            id={input.id}
            type="checkbox"
            name={input.name}
            checked={input.checked}
            onChange={(event) =>
              onChange ? onInputChangeHandler(event, input) : ""
            }
            disabled={input.disabled}
          />
          <label htmlFor={input.id}>
            <span>{input.displayValue}</span>
          </label>
        </div>
      );
    case "radio":
      return (
        <div
          className={`icheck-primary ${
            input.className ? " " + input.className : ""
          }`}
        >
          <input
            id={input.id}
            type="radio"
            value={value}
            name={input.name}
            checked={input.checked}
            onChange={(event) =>
              onChange ? onInputChangeHandler(event, input) : ""
            }
          />
          <label htmlFor={input.name}>
            <span>{input.displayValue}</span>
          </label>
        </div>
      );
    case "radioList":
      return input.radioList.map((radio, i) => {
        return (
          <div className={`icheck-primary`} key={input.name + i}>
            <input
              type={radio.type}
              name={radio.name}
              value={radio.value}
              id={radio.id}
              onChange={(event) =>
                onChange ? onInputChangeHandler(event, radio) : ""
              }
            />
            <label htmlFor={radio.id}>
              <span>{radio.displayValue}</span>
            </label>
          </div>
        );
      });
    case "select":
      return (
        <>
          <select
            name={input.name}
            required={input.required}
            value={value || ""}
            onChange={(event) =>
              onChange ? onInputChangeHandler(event, input) : ""
            }
          >
            {input.options.map((option, i) => (
              <option
                value={option.keyValue}
                otherid={option.otherId}
                locationid={option.locationId}
                key={input.name + i}
              >
                {option.displayValue}
              </option>
            ))}
          </select>
        </>
      );
    case "dropdownList":
      return (
        <DropdownList
          id={input.id}
          name={input.name}
          placeHolder={input.placeHolder}
          filter="contains"
          data={input.options}
          valueField={input.valueField}
          textField={input.textField}
          value={getKeyValFromList(
            input.options,
            value,
            input.defaultValue,
            input.valueField
          )}
          onChange={(event) =>
            onChange ? onInputChangeHandler(event, input) : ""
          }
          disabled={input.disabled}
        />
      );
    case "combo":
      return (
        <Combobox
          data={input.options}
          dataKey="keyValue"
          textField="displayValue"
          defaultValue={value}
          onChange={(event) =>
            onChange ? onInputChangeHandler(event, input) : ""
          }
          onBlur={(event) => onZipToStateCiyChangeHandler(event)}
          placeholder="Enter or Select Zip/Postal Code"
          focusFirstItem={false}
          disabled={input.disabled}
        />
      );
    case "phone":
      return (
        <>
          <div className="col-sm-2 pl-0 pr-2 mobile-code">
            <MobileAutosuggestionCtrl
              objectType={input.objectType}
              required={input.required}
              modelData={
                input.modelData ? input.modelData : new PhoneViewModel()
              }
              //disabled={disabled}
              onChange={onChange ? onChange : ""}
            />
          </div>
          <input
            type="text"
            className={input.className || ""}
            name={input.objectType + ".number"}
            value={input.modelData ? input.modelData.number || "" : ""}
            placeholder="Number"
            onChange={(event) =>
              onChange ? onInputChangeHandler(event, input) : ""
            }
          />
        </>
      );
    case "phonewithflag":
      return (
        <PhoneNumberUI
          id={input.name}
          controlName={input.name}
          caption={input.caption}
          required={input.required}
          placeholderText={input.placeHolder || "Enter Phone"}
          value={input.modelData}
          handleOnChange={onPhoneInputChangeHandler}
          isFOX={true}
          enableLongNumbers={true}
          showCountryFlagWithCode={true}
          isEditMode={true}
          disabled={input.disabled}
        />
      );
    case "discount":
      return (
        <>
          <div className="col-sm-3 pl-0 pr-2 mobile-code">
            <select
              name={input.name + "Type"}
              required={input.required}
              value={
                input.modelData ? input.modelData.discountType || "" : "CODE"
              }
              onChange={(event) =>
                onChange
                  ? onInputChangeHandler(event, input, input.name + "Type")
                  : ""
              }
            >
              {input.options.map((option, i) => (
                <option
                  value={option.keyValue}
                  key={input.name + "Type" + i}
                  disabled={option.disabled}
                >
                  {option.displayValue}
                </option>
              ))}
            </select>
          </div>
          <InputClearIcon
            inputValue={input.modelData.discountCode}
            customDivClass="light-gray-color"
            onClickEvent={input.inputClearAction}
            customCSS={{ top: "-0.3rem" }}
          />
          <input
            type="text"
            className={input.className || ""}
            name={input.name + "Code"}
            value={input.modelData ? input.modelData.discountCode || "" : ""}
            onChange={(event) =>
              onChange
                ? onInputChangeHandler(event, input, input.name + "Code")
                : ""
            }
          />
        </>
      );
    case "label":
      return (
        <label htmlFor={input.id}>{ReactHtmlParser(input.value || "--")}</label>
      );
    default:
      return (
        <input
          type={input.type}
          name={input.name}
          onChange={(event) =>
            onChange ? onInputChangeHandler(event, input) : ""
          }
          value={value || ""}
          minLength={input.minlength}
          maxLength={input.maxlength}
          required={input.required}
          onBlur={(event) =>
            input.name.includes("postalCode") ||
            input.name.includes("postalCodeText")
              ? onZipToStateCiyChangeHandler(event)
              : onInputBlur
              ? onInputBlurRender(event, input, input.name + "Code")
              : null
          }
          placeholder={
            input.name.includes("postalCodeText")
              ? "Enter Zip/Postal Code"
              : input.placeHolder
          }
          disabled={input.disabled}
        />
      );
  }
};
export const createLblInputCtrlModel = (
  rootContainer,
  lblInputFields,
  defaultLblClass,
  defaultInputClass,
  inputRootContainer,
  lblRootContainer
) => {
  return new LblInputCtrlModel({
    rootContainer: rootContainer,
    lblInputFields: lblInputFields,
    defaultLblClass: defaultLblClass,
    defaultInputClass: defaultInputClass,
    inputRootContainer: inputRootContainer,
    lblRootContainer: lblRootContainer,
  });
};
export const createRootContainerCtrlModel = (root, className, style) => {
  return new RootContainerCtrlModel({
    root: root,
    className: className,
    style: style,
  });
};
export const createDefaultLblCtrlModel = (text, className, style) => {
  return new LblCtrlModel({ text: text, className: className, style: style });
};
export const createDefaultInputCtrlModel = (
  type,
  name,
  value,
  required,
  disabled,
  placeHolder,
  objectType,
  options,
  className,
  style,
  modelData,
  dateTimeConfig,
  iconData,
  inputClearAction,
  valueField,
  textField,
  defaultValue,
  caption,
  inputIcon,
  tooltipText = null
) => {
  return new InputCtrlModel({
    type: type,
    name: name,
    value: value,
    required: required,
    disabled: disabled,
    placeHolder: placeHolder,
    options: options,
    className: className,
    style: style,
    modelData: modelData,
    dateTimeConfig: dateTimeConfig,
    objectType: objectType,
    iconData: iconData,
    inputClearAction: inputClearAction,
    valueField: valueField,
    textField: textField,
    defaultValue: defaultValue,
    caption: caption,
    inputIcon: inputIcon,
    tooltipText: tooltipText,
  });
};
export const createCBInputCtrlModel = (
  type,
  name,
  id,
  checked,
  displayValue,
  required,
  disabled,
  value,
  className,
  style,
  objectType
) => {
  return new InputCtrlModel({
    type: type,
    name: name,
    id: id,
    checked: checked,
    displayValue: displayValue,
    required: required,
    disabled: disabled,
    value: value,
    className: className,
    style: style,
    objectType: objectType,
  });
};
export const createRadioListInputCtrlModel = (type, radioList) => {
  return new InputCtrlModel({ type: type, radioList: radioList });
};
export const createDefaultLblInputFieldCtrlModel = (
  lblModel,
  inputModel,
  controlType,
  position,
  defaultLblClass,
  defaultInputClass
) => {
  return new LblInputFieldCtrlModel({
    controlType: controlType,
    lblModel: lblModel,
    inputModel: inputModel,
    position: position,
    defaultLblClass: defaultLblClass,
    defaultInputClass: defaultInputClass,
  });
};
export const createDefaultAddressLicenseLblInputFieldCtrlModel = (
  fields,
  type,
  position
) => {
  return new LblInputFieldCtrlModel({
    controlType: type,
    fields: fields,
    position: position,
  });
};
export const getDOBDateTimeFormat = () => {
  return {
    format: getDateTimeFormatString(),
    date: true,
    time: false,
    max: new Date(),
    dateFormat: "D",
  };
};
export const getLicenseDateTimeFormat = () => {
  return {
    format: getDateTimeFormatString(),
    date: true,
    time: false,
    min: new Date(),
    dateFormat: "D",
  };
};
export const getInsuranceDateTimeFormat = () => {
  return {
    format: getDateTimeFormatString(),
    date: true,
    time: false,
    min: new Date(),
    dateFormat: "D",
  };
};
export const getDefaultResTimeFormat = () => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  return {
    format: getDateTimeFormatString(true),
    date: true,
    time: true,
    min: today,
    dateFormat: "D",
  };
};

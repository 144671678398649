import React, { Fragment } from "react";
import Rating from "react-rating";
import FileUploader from "../../../components/image-uploader/file-uploader";
import  {
  getDateTimeString,
  getFullName,
  hasPermission,
} from "../../../shared/utility";
import PermissionName from "../../../shared/Permissions";
import { NoUserPhoto } from "../../../shared/AppImages";
import LinkButton from "../../../components/UI/button/link-btn";
import { getMemberLabelByOrgRentalType } from "../../../shared/utility/system-utility.js";
import { ADMIN_COMMON_CAPTION, ModalType } from "../../../shared/GlobalVar";
const ProfileCardUI = (props) => {
  const memberData = { ...props.memberData };
  const memberStatus = memberData.MemberStatus;
  const memberVerified = memberData.ProfileVerified;
  return (
    <div className="profile-card-3">
      <div className="card">
        <FileUploader
          memberId={memberData.MemberId}
          uploaderType="Member-Photo"
          photoData={memberData.ProfileImageUrl || NoUserPhoto}
          uploaderData={props.userPhotoInfo}
          isUploadBtnRequired={true}
          isNameRequird={false}
          isOnlyImg={true}
          changeFileTitle="Change Image"
          loadUploaderFileData={props.loadNewMemberPhoto}
          confirmBtnClick={props.changeMemberPhoto}
          disabled={!props.isSubMenu}
        />
        <div className="card-body text-center pt-0">
          <div className="list-inline">
            <hr />
            {props.isSubMenu ? (
              <Fragment>
                {hasPermission(PermissionName.VERIFY_PROFILE) ? (
                  <LinkButton
                    title={
                      memberVerified
                        ? getMemberLabelByOrgRentalType() +
                          " Verification Complete"
                        : getMemberLabelByOrgRentalType() +
                          " Verification Incomplete"
                    }
                    containerClass={`list-inline-item btn-social profileRoundIcon btn-social-circle ${
                      memberVerified ? "btn-user-verified" : "btn-pinterest"
                    } waves-effect waves-light m-2`}
                    iconClass={memberVerified ? "fas fa-check" : "fas fa-times"}
                    clicked={() =>
                      memberVerified
                        ? ""
                        : props.launchModal({
                            type: ModalType.MEMBER_VERIFICATION_MODAL,
                            memberId: memberData.MemberId,
                            doPerformAction: props.onVerifyMemberAccount,
                          })
                    }
                  />
                ) : (
                  ""
                )}
                {hasPermission(PermissionName.LOCK_UNLOCK_PROFILE) ? (
                  <LinkButton
                    title={
                      memberStatus === "SUSPENDED"
                        ? "Click to Unblock Profile"
                        : "Click to Block Profile"
                    }
                    containerClass={`list-inline-item btn-social profileRoundIcon btn-social-circle ${
                      memberStatus === "SUSPENDED"
                        ? "btn-youtube"
                        : "btn-github"
                    } waves-effect waves-light mr-2`}
                    iconClass={
                      memberStatus === "SUSPENDED"
                        ? "fas fa-lock"
                        : "fas fa-lock-open"
                    }
                    clicked={() =>
                      props.launchModal({
                        type: ModalType.MEMBER_BLOCK_UNBLOCK_MODAL,
                        memberData: memberData,
                        memberId: memberData.MemberId,
                        doPerformAction: props.onBlockUnblockMemberProfile,
                      })
                    }
                  />
                ) : (
                  ""
                )}
                {hasPermission(PermissionName.DISABLE_MEMBER) &&
                memberStatus !== "INACTIVE" ? (
                  <LinkButton
                    title={
                      props.isAccountOwner
                        ? "You cannot Cancel your own account"
                        : "Active account - click to cancel"
                    }
                    containerClass={`list-inline-item btn-social profileRoundIcon btn-social-circle waves-effect waves-light mr-2 ${
                      props.isAccountOwner
                        ? "background-disabled cursor-default"
                        : "background-green cursor-pointer"
                    }`}
                    iconClass="fas fa-user-check"
                    clicked={() =>
                      !props.isAccountOwner && props.launchModal({
                            type: ModalType.MEMBER_ACTIVATION_MODAL,
                            memberId: memberData.MemberId,
                            doPerformAction: props.onCancelMembership,
                            popupFor: "cancelAccount",
                            title: "Cancel Account",
                          })
                    }
                  />
                ) : (
                  ""
                )}
                {hasPermission(PermissionName.REACTIVATE_MEMBER) &&
                memberStatus === "INACTIVE" ? (
                  <LinkButton
                    title="This account is inactive - click to reactivate"
                    containerClass="list-inline-item btn-social profileRoundIcon btn-social-circle waves-effect waves-light mr-2 background-red"
                    iconClass="fas fa-user-times"
                    clicked={() =>
                      props.launchModal({
                        type: ModalType.MEMBER_ACTIVATION_MODAL,
                        memberId: memberData.MemberId,
                        doPerformAction: props.onReactivateMember,
                        popupFor: "reactivateAccount",
                        title: "Reactivate Account",
                      })
                    }
                  />
                ) : (
                  ""
                )}
                {hasPermission(PermissionName.CHANGE_TEMP_PASS) &&
                memberData.TemporaryPassword ? (
                  <LinkButton
                    title="Change Temporary Password"
                    containerClass="list-inline-item btn-social profileRoundIcon btn-social-circle btn-tumblr waves-effect waves-light mr-2"
                    iconClass="fas fa-key"
                    clicked={() =>
                      props.launchModal({
                        type: ModalType.CHANGE_RESET_PASSWORD_MODAL,
                        memberId: memberData.MemberId,
                        doPerformAction: props.onChangeTemporaryPassword,
                        popupFor: "changePassword",
                        title: "Change Temporary Password",
                      })
                    }
                  />
                ) : (
                  ""
                )}
                {hasPermission(PermissionName.RESET_PASSWORD) ? (
                  <LinkButton
                  title={
                    props.isAccountOwner
                      ? "To change your own password you have to use your Profile page"
                      : "Reset Password"
                  }
                  containerClass={`list-inline-item btn-social profileRoundIcon btn-social-circle waves-effect waves-light mr-2 ${
                    props.isAccountOwner
                      ? "background-disabled cursor-default"
                      : "btn-tumblr cursor-pointer"
                  }`}
                    stackClass="fa-password-reset fa-stack fs-08"
                    faIconList={[
                      "fa fa-undo fa-stack-2x",
                      "fa fa-key fa-stack-1x",
                    ]}
                    clicked={() =>
                      !props.isAccountOwner && props.launchModal({
                        type: ModalType.CHANGE_RESET_PASSWORD_MODAL,
                        memberId: memberData.MemberId,
                        doPerformAction: props.onResetPassword,
                        popupFor: "resetPassword",
                        title: "Reset Password",
                      })
                    }
                  />
                ) : (
                  ""
                )}
                {hasPermission(PermissionName.IMPERSONATE_PERMISSION) ? (
                  <LinkButton
                  title={
                    props.isAccountOwner
                      ? "You cannot impersonate yourself"
                      : "Impersonate " + getMemberLabelByOrgRentalType()
                  }
                  containerClass={`list-inline-item btn-social profileRoundIcon btn-social-circle waves-effect waves-light mr-2 ${
                    props.isAccountOwner
                      ? "background-disabled cursor-default"
                      : "btn-pinterest cursor-pointer"
                  }`}
                    iconClass="fas fa-user-secret fs-8"
                    clicked={() =>
                      !props.isAccountOwner && props.launchModal({
                        type: ModalType.IMPERSONATE_MODAL,
                        memberId: memberData.MemberId,
                        fullName: memberData.FullName,
                        doPerformAction: props.onImpersonateLogin,
                      })
                    }
                  />
                ) : (
                  ""
                )}
              </Fragment>
            ) : (
              ""
            )}
            <hr />
            <Rating
              initialRating={
                memberData.BorrowerRating &&
                memberData.BorrowerRating.OverallRating
                  ? memberData.BorrowerRating.OverallRating
                  : 0
              }
              readonly={!props.isEditMode}
              //readonly={true}
              stop={5}
              fractions={2}
              emptySymbol={[
                "far fa-star fa-2x",
                "far fa-star fa-2x",
                "far fa-star fa-2x",
                "far fa-star fa-2x",
                "far fa-star fa-2x",
              ]}
              fullSymbol={[
                "fas fa-star fa-2x text-warning",
                "fas fa-star fa-2x text-warning",
                "fas fa-star fa-2x text-warning",
                "fas fa-star fa-2x text-warning",
                "fas fa-star fa-2x text-warning",
              ]}
            />
            <hr />
          </div>
          <div className="col-lg-12 p-0">
            <div className="form-group row">
              <label className="col-sm-12 col-form-label font-weight-bold text-left p-0">
                Call Location
              </label>
              <label className="col-sm-12 col-form-label  text-left p-0">
                {"--"}
              </label>
            </div>
            <div className="form-group row">
              <label className="col-sm-12 col-form-label font-weight-bold text-left p-0">
                {ADMIN_COMMON_CAPTION.CUSTOMER_ID}
              </label>
              <label className="col-sm-12 col-form-label  text-left p-0">
                {memberData.MemberId
                  ? memberData.MembershipId + " / " + memberData.MemberId
                  : "--"}
              </label>
            </div>
            <div className="form-group row">
              <label className="col-sm-12 col-form-label font-weight-bold text-left p-0">
                Name
              </label>
              <label className="col-sm-12 col-form-label  text-left p-0">
                {getFullName(
                  memberData.FirstName,
                  memberData.LastName,
                  memberData.MiddleName
                )}
              </label>
            </div>
            <div className="form-group row">
              <label className="col-sm-12 col-form-label font-weight-bold text-left p-0">
                Last Login App
              </label>
              <label className="col-sm-12 col-form-label  text-left p-0">
                {memberData.LastLoggedInApp || "--"}
              </label>
            </div>
            <div className="form-group row">
              <label className="col-sm-12 col-form-label font-weight-bold text-left p-0">
                Last Login Date
              </label>
              <label className="col-sm-12 col-form-label  text-left p-0">
                {memberData.LastLoginDate
                  ? getDateTimeString(memberData.LastLoginDate)
                  : "--"}
              </label>
            </div>
            <div className="form-group row">
              <label className="col-sm-12 col-form-label font-weight-bold text-left p-0">
                Login Count
              </label>
              <label className="col-sm-12 col-form-label  text-left p-0">
                {memberData.TotalCount || "--"}
              </label>
            </div>
            <div className="form-group row">
              <label className="col-sm-12 col-form-label font-weight-bold text-left p-0">
                {getMemberLabelByOrgRentalType("Since")}
              </label>
              <label className="col-sm-12 col-form-label  text-left p-0">
                {memberData.MemberSinceDate
                  ? getDateTimeString(memberData.MemberSinceDate)
                  : "--"}
              </label>
            </div>
            <div className="form-group row">
              <label className="col-sm-12 col-form-label font-weight-bold text-left p-0">
                Temporary Password
              </label>
              <label className="col-sm-12 col-form-label  text-left p-0">
                {memberData.TemporaryPassword || "--"}
              </label>
            </div>
          </div>
          <div>
            <hr />
          </div>
        </div>
      </div>
    </div>
  );
};
export default ProfileCardUI;

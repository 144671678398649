import { COMMON_MSG } from "../../shared/GlobalVar";
import { getDisplayTextOfDropdown } from "../../shared/utility";
import {
  ColorTypes,
  DrivingTypes,
  enumToKeyValuesObject,
  FuelConsumptionUnitTypes,
  FuelTypes,
  getYearsDropDown,
  TransMissionTypes,
} from "../../shared/utility/enum-utility";
import { getCompatibleDdlOrg } from "../../shared/utility/shuttle-app-utility";
import { getSeatingCapacityList, getVehicleBuilders } from "../vehicle/vehicle-helper";

export const getShuttleUICtrl = (viewData, dependantOrgList) => {
  viewData = viewData ? viewData : {};
  return JSON.parse(
    JSON.stringify([
      {
        type: "SHUTTLE_LEFTBAR",
        fields: [
          {
            editConfig: {
              id: "locationCode",
              type: "select",
              name: "locationCode",
              options: getCompatibleDdlOrg(dependantOrgList),
              required: true,
            },
            titleLabel: {
              text: "Shuttle Location",
            },
            detailLabel: {
              value: viewData.location && viewData.location.name
              ? viewData.location.name
              : "",
            },
            value:
              viewData.location && viewData.location.locationCode
                ? viewData.location.locationCode
                : "",
            order: 0,
          },
          {
            editConfig: {
              id: "shuttleCode",
              type: "text",
              name: "shuttleCode",
              options: [],
              required: true,
            },
            titleLabel: {
              text: "Shuttle Code",
            },
            detailLabel: {
              value: viewData.shuttleCode || "--",
            },
            value: viewData.shuttleCode || "",
            order: 1,
          },
          {
            editConfig: {
              id: "name",
              type: "text",
              name: "name",
              options: [],
              required: true,
            },
            titleLabel: {
              text: "Shuttle Name",
            },
            detailLabel: {
              value: viewData.name || "--",
            },
            value: viewData.name || "",
            order: 2,
          },
          {
            editConfig: {
              id: "shortName",
              type: "text",
              name: "shortName",
              options: [],
              required: true,
              maxLength: 2
            },
            titleLabel: {
              text: "Short Name",
            },
            detailLabel: {
              value: viewData.shortName || "--",
            },
            value: viewData.shortName || "",
            order: 3,
          },
          {
            editConfig: {
              id: "vin",
              type: "text",
              name: "vin",
              options: [],
              required: true,
              maxLength: 18,
              minLength: 8,
              validationMsg: COMMON_MSG.VINValidationMsg
            },
            titleLabel: {
              text: "VIN",
            },
            detailLabel: {
              value: viewData.vin || "--",
            },
            value: viewData.vin || "",
            order: 4,
          },
          {
            editConfig: {
              id: "licensePlate",
              type: "text",
              name: "licensePlate",
              options: [],
              required: true,
            },
            titleLabel: {
              text: "License Plate",
            },
            detailLabel: {
              value: viewData.licensePlate || "--",
            },
            value: viewData.licensePlate || "",
            order: 5,
          },
          {
            editConfig: {
              id: "insuranceCarrier",
              type: "text",
              name: "insuranceCarrier",
              options: [],
              required: true,
            },
            titleLabel: {
              text: "Insurance Carrier",
            },
            detailLabel: {
              value: viewData.insuranceCarrier || "--",
            },
            value: viewData.insuranceCarrier || "",
            order: 6,
          },
          {
            editConfig: {
              id: "policyNumber",
              type: "text",
              name: "policyNumber",
              options: [],
              required: true,
            },
            titleLabel: {
              text: "Policy Number",
            },
            detailLabel: {
              value: viewData.policyNumber || "--",
            },
            value: viewData.policyNumber || "",
            order: 7,
          },
          {
            editConfig: {
              id: "availability",
              type: "radiolist",
              name: "availability",
              required: true,
              options: [
                {
                  id: "IN_SERVICE",
                  key: "IN_SERVICE",
                  value: "In Service",
                },
                {
                  id: "OUT_OF_SERVICE",
                  key: "OUT_OF_SERVICE",
                  value: "Out Of Service",
                },
              ],
            },
            titleLabel: {
              text: "Availability",
            },
            detailLabel: {
              value: viewData.availabilityDisplayValue || "--",
            },
            value: viewData.availability || "IN_SERVICE",
            order: 8,
          },
          {
            editConfig: {
              id: "other",
              type: "checkboxlist",
              name: "amenities",
              required: false,
              options: [
                {
                  id: "HANDICAP",
                  key: "HANDICAP",
                  value: "Handicap",
                },
                {
                  id: "WHEEL_CHAIR",
                  key: "WHEEL_CHAIR",
                  value: "Wheel Chair",
                },
              ],
            },
            titleLabel: {
              text: "Other",
            },
            detailLabel: {
              value: viewData.amenitiesDisplayValue || "--",
            },
            value: viewData.amenities || [],
            order: 9,
          },
        ],
      },
      {
        type: "SHUTTLE_RIGHTBAR",
        fields: [
          {
            editConfig: {
              id: "make",
              type: "select",
              name: "make",
              options: getVehicleBuilders(),
              required: true,
            },
            titleLabel: {
              text: "Make",
            },
            detailLabel: {
              value: viewData.make || "--",
            },
            value: viewData.make || "",
            order: 0,
          },
          {
            editConfig: {
              id: "model",
              type: "text",
              name: "model",
              options: [],
              required: true,
            },
            titleLabel: {
              text: "Model",
            },
            detailLabel: {
              value: viewData.model || "--",
            },
            value: viewData.model || "",
            order: 1,
          },
          {
            editConfig: {
              id: "year",
              type: "select",
              name: "year",
              options: getYearsDropDown(),
              required: true,
            },
            titleLabel: {
              text: "Year",
            },
            detailLabel: {
              value: viewData.year || "--",
            },
            value: viewData.year || "",
            order: 2,
          },
          {
            editConfig: {
              id: "seatCapacity",
              type: "number",
              name: "seatCapacity",
              options: [],
              required: true,
              min:0
            },
            titleLabel: {
              text: "Seat Capacity",
            },
            detailLabel: {
              value: viewData.seatCapacity || "--",
            },
            value: viewData.seatCapacity || "",
            order: 3,
          },
          {
            editConfig: {
              id: "drivingType",
              type: "select",
              name: "drivingType",
              options: enumToKeyValuesObject(DrivingTypes),
              required: true,
            },
            titleLabel: {
              text: "Driving Type",
            },
            detailLabel: {
              value: DrivingTypes[viewData.drivingType] || "--",
            },
            value: viewData.drivingType || "",
            order: 4,
          },
          {
            editConfig: {
              id: "fuelType",
              type: "select",
              name: "fuelType",
              options: enumToKeyValuesObject(FuelTypes),
              required: true,
            },
            titleLabel: {
              text: "Fuel Type",
            },
            detailLabel: {
              value: FuelTypes[viewData.fuelType] || "--"
            },
            value: viewData.fuelType || "",
            order: 5,
          },
          {
            editConfig: {
              id: "fuelConsumptionUnit",
              type: "select",
              name: "fuelConsumptionUnit",
              options: enumToKeyValuesObject(FuelConsumptionUnitTypes),
              required: true,
            },
            titleLabel: {
              text: "Fuel Consumption Unit",
            },
            detailLabel: {
              value: FuelConsumptionUnitTypes[viewData.fuelConsumptionUnit] || "--",
            },
            value: viewData.fuelConsumptionUnit || "",
            order: 6,
          },
          {
            editConfig: {
              id: "transmissionType",
              type: "select",
              name: "transmissionType",
              options: enumToKeyValuesObject(TransMissionTypes),
              required: true,
            },
            titleLabel: {
              text: "Transmission Type",
            },
            detailLabel: {
              value: TransMissionTypes[viewData.transmissionType] || "--"
            },
            value: viewData.transmissionType || "",
            order: 7,
          },
          {
            editConfig: {
              id: "color",
              type: "select",
              name: "color",
              options: enumToKeyValuesObject(ColorTypes),
              required: true,
            },
            titleLabel: {
              text: "Shuttle Color",
            },
            detailLabel: {
              value: ColorTypes[viewData.color] || "--"
            },
            value: viewData.color || "",
            order: 8,
          },
          {
            editConfig: {
              id: "description",
              type: "textarea",
              name: "description",
              options: [],
              required: false,
            },
            titleLabel: {
              text: "Shuttle Description",
            },
            detailLabel: {
              value: viewData.description || "--",
            },
            value: viewData.description || "",
            style: {
              height: "8rem",
            },
            order: 9,
          },
          {
            editConfig: {
              id: "internalNotes",
              type: "textarea",
              name: "internalNotes",
              options: [],
              required: false,
            },
            titleLabel: {
              text: "Internal Notes",
            },
            detailLabel: {
              value: viewData.internalNotes || "--",
            },
            value: viewData.internalNotes || "",
            style: {
              height: "8rem",
            },
            order: 10,
          },
        ],
      },
    ])
  );
};

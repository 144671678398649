import React, { Fragment, useEffect, useRef, useState } from "react";
import ReactHtmlParser from "react-html-parser";
import MessageBox from "../../../../components/common/modal/message-box";
import TooltipItem from "../../../../components/tooltip/TooltipItem";
import { BRIEFCASE_05, FUEL_STATION, INFO_ICON, MORE_VERTICAL, USER_GROUP } from "../../../../shared/AppImages";
import { COMMON_MSG, MessageBoxType, ModalType } from "../../../../shared/GlobalVar";
import {
  formatAmountValue,
  getNoteTooltipHtml,
  getSubString,
  hasObjectNonEmptyProperty,
} from "../../../../shared/utility";
const VehicleInformationCard = (props) => {
  const {
    vehicleElement,
    getSelectedVehicleHtml,
    rentalOffers,
    index,
    editMode,
    savedRatesApplied,
    savedRatePlan,
    isPayLater,
    hasPayNowRate,
    setHasPayNowrate,
    onSetPayType,
    selectedClassCode,
    setFoxVehicleClassViewModel,
    foxVehicleClassViewModel,
    inputChangedHandler,
    savedTotalCharges,
    savedAddOnCharges,
    showModal,
    reservationMode,
    isAllowedPrePaidReservation,
    foxPrimaryFormViewModel,
    foxCustomerViewModel,
    getSecurityDepositChargesData,
    performGetRates,
    setPerformGetRates,
    isBlockEnabled,
    isOriginalPayLater,
    setSelectedCarClassCode,
    isPayLaterSelected
  } = props;
  const iconRef = useRef(null);

  const [showInfoModal, setShowInfoModal] = useState(false);
  const [messageBoxPosition, setMessageBoxPosition] = useState({ top: 0, left: 0 });

  const openMessageBox = () => {
    setShowInfoModal(true);
  };

  const closeModal = () => {
    setShowInfoModal(false);
  };
  useEffect(() => {
    if (foxPrimaryFormViewModel?.rentalLocationCode && foxCustomerViewModel?.primaryAddress && (vehicleElement.classCode === selectedClassCode)) {
      getSecurityDepositChargesData(foxPrimaryFormViewModel.rentalLocationCode,
        foxCustomerViewModel?.primaryAddress?.country,
        foxCustomerViewModel?.primaryAddress?.postalCode || foxCustomerViewModel?.primaryAddress?.postalCodeText, selectedClassCode);
    }
  }, [isPayLater, isPayLaterSelected, selectedClassCode]);

  useEffect(() => {
    getPayTypeWiseRate("PrePaid", selectedClassCode);
    onSetPayType && onSetPayType(hasPayNowRate ? isPayLater : true, !hasPayNowRate);
  }, [hasPayNowRate]);
  useEffect(() => {
    let newlyAppliedRate = getPayTypeWiseRate(isPayLater ? "PayLater" : "PrePaid", selectedClassCode)
    if (JSON.stringify(newlyAppliedRate.ratePlan) !== JSON.stringify(savedRatePlan)) {
      let localViewModel = JSON.parse(JSON.stringify(foxVehicleClassViewModel))
      localViewModel.isVehicleInfoBlockChanged = true;
      setFoxVehicleClassViewModel(localViewModel)
    }
  }, [rentalOffers]);
  const getPayTypeWiseRate = (payType, carClassCodeOnHover) => {
    let payTypeWiseRate = {};
    if (rentalOffers?.length === 0 && savedRatesApplied?.length === 0) {
      return payTypeWiseRate;
    }
    let selectedRates = [];
    if (rentalOffers?.length) {
      setPerformGetRates(true);
      const targetClassCode = carClassCodeOnHover || vehicleElement.classCode;
      selectedRates = rentalOffers.filter(
        (offer) => offer.vehicleClass[0].classCode === targetClassCode
      );
    }

    if (selectedRates.length > 0) {
      const firstRateProduct = selectedRates[0].rateProduct[0];

      if (firstRateProduct && firstRateProduct.pricing) {
        const appliedRates = firstRateProduct.pricing.filter(
          (item) => item.type === payType
        );

        if (appliedRates.length > 0) {
          const appliedRate = appliedRates[0];
          payTypeWiseRate.prepaidSavedAmount =
            appliedRate.prepaidSavedAmount || 0;
          payTypeWiseRate.rateInfo = appliedRate.ratesApplied || [];
          payTypeWiseRate.ratePlan = appliedRate.ratePlan || {};
          payTypeWiseRate.estimatedTotal = appliedRate.totalCharges || 0;
        }
      }
      if (payType === "PrePaid") {
        const hasPrepaid =
          (payTypeWiseRate.rateInfo && payTypeWiseRate.rateInfo.length > 0 &&
            hasObjectNonEmptyProperty(payTypeWiseRate.ratePlan)) || false;
        if (hasPayNowRate !== hasPrepaid) {
          setHasPayNowrate(hasPrepaid);
        }
      }
    } else if (savedRatesApplied && savedRatesApplied.length > 0) {
      payTypeWiseRate.rateInfo = [...savedRatesApplied];
      payTypeWiseRate.ratePlan = { ...savedRatePlan };
      payTypeWiseRate.estimatedTotal = savedTotalCharges - savedAddOnCharges;
      setHasPayNowrate(foxVehicleClassViewModel.savedPayType === "PrePaid");
      setSelectedCarClassCode(foxVehicleClassViewModel.prevVehicleClassCode);
    }
    return payTypeWiseRate;
  };

  const getAppliedRate = (payTypeWiseRate, rateType) => {
    const appliedRate = payTypeWiseRate.rateInfo.filter((rate) => rate.type === rateType) || [];
    const rateBeforeDiscount = payTypeWiseRate.ratePlan?.type === rateType
      ? payTypeWiseRate.ratePlan.rateBeforeDiscount
      : null;

    return { appliedRate, rateBeforeDiscount };
  };

  const renderRateRow = (labelCaption, rateUnitType, paymentType) => {
    const { appliedRate, rateBeforeDiscount: originalRate } = getAppliedRate(
      getPayTypeWiseRate(paymentType),
      rateUnitType
    );
    const rateValue = appliedRate?.[0]?.rate || null;
    if (rateValue) {
      return (
        <>
          <span>{`${formatAmountValue(rateValue)} / ${labelCaption}`}</span>
          {originalRate && (
            <del className="discount-amount">
              {`${formatAmountValue(originalRate)} / ${labelCaption}`}
            </del>
          )}
          <span className="dot"></span>
        </>
      );
    }
    return "";
  };


  const renderHorizontalRateSection = (paymentType) => {
    const paymentRate = getPayTypeWiseRate(paymentType);
    //if (hasObjectNonEmptyProperty(paymentRate)) {
    return (
      <Fragment>
        <div className="d-flex pr-0 flex-direction-column">
          {!isAllowedPrePaidReservation && <span className="payment-type-label">Pay Later</span>}
          <div className="d-flex">
            <span className="payment-total">
              {`Total: ${formatAmountValue(paymentRate.estimatedTotal)}`}
            </span>

            {paymentType === "PrePaid" &&
              !isNaN(paymentRate.prepaidSavedAmount) && (
                <span className="paynow-saved">{`Save ${formatAmountValue(
                  paymentRate.prepaidSavedAmount
                )}`}</span>
              )}
          </div>
          <div className="rate-items">
            {renderRateRow("M", "MONTHLY", paymentType)}
            {renderRateRow("W", "WEEKLY", paymentType)}
            {renderRateRow("D", "DAILY", paymentType)}
            {renderRateRow("W", "WEEKEND", paymentType)}
            {renderRateRow("H", "HOURLY", paymentType)}
          </div>
        </div>
      </Fragment>
    );
    //}
    //return null;
  };

  const loadImgIconWithTooltip = (imgData, imgId, tooltipData) => {
    return (
      <Fragment>
        <img src={imgData} alt="u" id={imgId} height={16} width={16} />
        <TooltipItem
          placement="bottom"
          toolTipId={imgId}
          renderableHtml={ReactHtmlParser(getNoteTooltipHtml(tooltipData))}
          forceShow={true}
        />
      </Fragment>
    );
  };
  const renderVehicleInfoRowWithIcon = (
    imgData,
    imgId,
    tooltipData,
    values
  ) => {
    return (
      <div className={"class-info-icon"}>
        {loadImgIconWithTooltip(imgData, imgId, tooltipData)}
        <span className="contents">{values || "--"}</span>
      </div>
    );
  };

  const renderMessageBox = (values) =>
    showInfoModal && (
      <MessageBox
        title={"More Info"}
        message={values}
        onClose={closeModal}
        // position={messageBoxPosition}
        iconRef={iconRef}
        messageBoxType={MessageBoxType.VEHICLE_MORE_INFO}
      />
    );

  const getMoreModalContent = (data) => {
    let otherAmenities = data[0] && data[0].split(",").sort();
    return (
      <Fragment>
        <div
          className={"section-scroll more-info"}
        >
          {otherAmenities && otherAmenities.map((item, i) => {
            return <div key={item + i} className={"more-info-item"}>{item}</div>;
          })}
        </div>
      </Fragment>
    );
  };

  const renderMoreButton = (imgData, imgId, tooltipData, data) => {
    let modalContent = getMoreModalContent(data && data.tags ? [data.tags.join(",")] : []);
    return (
      <>
        <div
          className={"more-info-icon"}
          ref={iconRef}
          onClick={() => openMessageBox()}
        >
          {loadImgIconWithTooltip(imgData, imgId, tooltipData)}
        </div>
        {renderMessageBox(modalContent)}
      </>
    );
  };

  const renderVehicleInfoDetails = (data, classNames) => {
    return (
      <div className={classNames}>
        <div className="pl-4 font-bold model-desc"><label>{data.modelDesc}</label>
          {props.showInfoIcon && <div
            className="action-icon"
            title={"Click to View Vehicle Details"}
            onClick={() =>
              showModal && showModal({
                type: ModalType.FOX_VEHICLE_DETAILS_MODAL,
              })
            }
          >
            <img
              src={INFO_ICON}
              height={16}
              width={16}
              alt={"vehicle category"}
            />
          </div>}
        </div>
        <div className="d-flex col-lg-12 p-2 pl-3 pr-4">
          <div className="pl-2 font-bold vehicle-section pr-3">
            <span className="catgory bg-light p-2 rounded rounded-pill">
              {data.tags && data.tags.length
                ? data.tags[0]
                : getSubString(data.displayText, 6)}
            </span>
          </div>
          {props.showFeatureSection && <>
            {renderVehicleInfoRowWithIcon(
              USER_GROUP,
              "passenger" + data.classCode,
              "Passenger",
              data.passengerCount
            )}
            {renderVehicleInfoRowWithIcon(
              BRIEFCASE_05,
              "luggage" + data.classCode,
              "Luggage",
              data.luggageCount
            )}
            {renderVehicleInfoRowWithIcon(
              FUEL_STATION,
              "fuel" + data.classCode,
              "Fuel",
              data.mpgCity || data.mpgHighway ? (data.mpgCity || "--") + "/" + (data.mpgHighway || "--") : null
            )}
            {renderMoreButton(
              MORE_VERTICAL, "more-info" + data.classCode, "Click for more details", data || []
            )}
          </>}
        </div>
      </div>
    );
  };
  const isEditAndPayLater = reservationMode === "EDIT" && isOriginalPayLater;
  const isEditAndPayNow = reservationMode === "EDIT" && !isOriginalPayLater;
  return (
    <>
      <div className="d-flex">
        <div className="d-flex flex-direction-column text-center">
          {getSelectedVehicleHtml(
            vehicleElement,
            index,
            "vehicle-class-item-in-card",
            "fs-0"
          )}
          {props.showClassCodeUnderCar && (
            <span className="font-weight-medium">
              {" "}
              {getSubString(vehicleElement.displayText, 0, 4)}
            </span>
          )}
        </div>

        {renderVehicleInfoDetails(
          vehicleElement,
          "vehicle-info-card-details fs-0"
        )}
      </div>
      {/* PrePaid Rate Section */}
      {isAllowedPrePaidReservation &&
        props.showRateSection &&
        ((performGetRates && hasPayNowRate) || hasPayNowRate) && !isEditAndPayLater && hasObjectNonEmptyProperty(getPayTypeWiseRate("PrePaid")) && (
          <div
            className={`card-body pr-0 border-rounded ${((!isPayLater && !isPayLaterSelected) || (!isOriginalPayLater && reservationMode === "EDIT") || (!isPayLaterSelected && reservationMode === "NEW")) &&
              (vehicleElement.classCode === selectedClassCode
                ? "selected-vehicle-class"
                : "")
              } ${isEditAndPayLater ? "edit-paylater-mode" : ""}`}
          >
            <div className="d-flex position-relative">
              {props.showReserveButtom && (
                <button
                  className={
                    "btn btn-yellow-default PrePaid vehicle-reserve-btn"
                  }
                  onClick={() => inputChangedHandler(vehicleElement, "PrePaid")}
                  disabled={!editMode || isEditAndPayLater || !isBlockEnabled}
                  title={isEditAndPayLater ? COMMON_MSG.PayLaterToPayNow : !isBlockEnabled ? COMMON_MSG.FoxPaynowDisableMsg : ""}
                >
                  {(!isPayLater || !isPayLaterSelected) && vehicleElement.classCode === selectedClassCode
                    ? "Reserved"
                    : "PayNow"}
                </button>
              )}
              {renderHorizontalRateSection("PrePaid")}
            </div>
          </div>
        )}
      {/* Pay Later Rate Section */}
      {props.showRateSection && (performGetRates || !hasPayNowRate) && !isEditAndPayNow && hasObjectNonEmptyProperty(getPayTypeWiseRate("PayLater")) && (
        <div
          className={`card-body pr-0 border-rounded  ${((isPayLater && isPayLaterSelected) || (isOriginalPayLater && reservationMode === "EDIT")) &&
            (vehicleElement.classCode === selectedClassCode
              ? " selected-vehicle-class"
              : "")
            } "`}
        >
          <div className="d-flex position-relative">
            {props.showReserveButtom && (
              <button
                className={
                  "btn btn-yellow-default PayLater vehicle-reserve-btn"
                }
                onClick={() => inputChangedHandler(vehicleElement, "PayLater")}
                disabled={!editMode || !isBlockEnabled || isEditAndPayNow}
                title={isEditAndPayNow ? COMMON_MSG.PayNowToPayLater : !isBlockEnabled ? COMMON_MSG.FoxPaynowDisableMsg : ""}
              >
                {(isPayLater && isPayLaterSelected) && vehicleElement.classCode === selectedClassCode
                  ? "Reserved"
                  : !isAllowedPrePaidReservation
                    ? "Reserve"
                    : "PayLater"}
              </button>
            )}
            {renderHorizontalRateSection("PayLater")}
          </div>
        </div>
      )}
    </>
  );
};
export default React.memo(VehicleInformationCard);
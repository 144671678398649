import BlackListedPersonInfo from "../models/BlackListedPersonInfo";

const BlackListedPersonsViewModel = function (data) {
  if (!data) data = {};
  const blackListePersonsList = [];
  if (data.BlackListedPersonData && data.BlackListedPersonData.length > 0) {
    data.BlackListedPersonData.map((entity) => {
      blackListePersonsList.push(new BlackListedPersonInfo(entity));
    });
  }
  this.BlackListedPersonData = blackListePersonsList;
};
export default BlackListedPersonsViewModel;

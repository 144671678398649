import React, { useState, useEffect } from "react";
import ReactHtmlParser from "react-html-parser";
import {
  getNoteTooltipHtml,
} from "../../../../shared/utility";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import Draggable from "react-draggable";
import TooltipItem from "../../../../components/tooltip/TooltipItem";
import EyeIcon from "../../../../components/UI/EyeIcon/eyeIcon";
import { getChannelQuickViewInfoCtrl } from "./fox-channel-quick-modal-ui-ctrl";
import { renderBesicLabelPair } from "../../../../components/UI/Input/common-info-pair";

export const FoxChannelQuickModal = (props) => {
  const channelModalData = props.defaultData;
  const [channelQuickModalState, setChannelQuickModalState] = useState({
    maskedValue: "XXXXXXXXXXXXXXXX",
    encryptionKey: "XXXXXXXXXXXXXXXX",
    licenseKey: "XXXXXXXXXXXXXXXX",
    primaryAuthenticationId: "XXXXXXXXXXXXXXXX",
    encryptionKeyTooltip: null,
    licenseKeyTooltip: null,
    primaryAuthenticationIdTooltip: null,
  });
  const [channelInfoCtrl, setChannelInfoCtrl] = useState([getChannelQuickViewInfoCtrl(channelModalData)]);
  useEffect(() => {
    setChannelInfoCtrl(getChannelQuickViewInfoCtrl(channelModalData));
  }, [channelModalData]);
  const characterHideShowToggleForLabel = (returnedValue, labelId) => {
    let updateCtrl = { ...channelQuickModalState };
    updateCtrl.encryptionKey =
      labelId === "encryptionKey" ? returnedValue : updateCtrl.encryptionKey;
    updateCtrl.licenseKey =
      labelId === "licenseKey" ? returnedValue : updateCtrl.licenseKey;
    updateCtrl.primaryAuthenticationId =
      labelId === "primaryAuthenticationId"
        ? returnedValue
        : updateCtrl.primaryAuthenticationId;

    updateCtrl.encryptionKeyTooltip =
      labelId === "encryptionKey"
        ? getTooltipTextForSensitiveField(labelId, returnedValue)
        : updateCtrl.encryptionKeyTooltip;
    updateCtrl.encryptionKeyTooltip =
      labelId === "encryptionKey"
        ? getTooltipTextForSensitiveField(labelId, returnedValue)
        : updateCtrl.encryptionKeyTooltip;
    updateCtrl.licenseKeyTooltip =
      labelId === "licenseKey"
        ? getTooltipTextForSensitiveField(labelId, returnedValue)
        : updateCtrl.licenseKeyTooltip;
    updateCtrl.primaryAuthenticationIdTooltip =
      labelId === "primaryAuthenticationId"
        ? getTooltipTextForSensitiveField(labelId, returnedValue)
        : updateCtrl.primaryAuthenticationIdTooltip;
    setChannelQuickModalState(updateCtrl);
  };
  const getTooltipTextForSensitiveField = (labelId, returnedValue) => {
    return channelQuickModalState.maskedValue !== returnedValue ? (
      <TooltipItem
        placement="left"
        toolTipId={labelId}
        renderableHtml={ReactHtmlParser(getNoteTooltipHtml(returnedValue))}
      />
    ) : (
      ""
    );
  };
  const renderLabelValueWithEyeIcon = (
    labelCaption,
    originalValue,
    modalValue,
    modalValueTooltip,
    modalMaskedValue,
    labelID
  ) => {
    return (
      <>
        <span className="font-weight-bold col-lg-12 p-01">
          {labelCaption}
          {originalValue ? (
            <EyeIcon
              characterHideShowToggleForLabel={(returnedValue, labelId) =>
                characterHideShowToggleForLabel(returnedValue, labelId)
              }
              maskedValue={modalMaskedValue}
              originalValue={originalValue}
              labelID={labelID}
              customClass="fs-3"
            />
          ) : (
            ""
          )}
        </span>
        <label className="p-01 pr-2 col-lg-7 overflowCommon" id={labelID}>
          {originalValue ? modalValue : "--"}
          {modalValueTooltip ? modalValueTooltip : ""}
        </label>
      </>
    );
  };

  const renderChannelViewSectionInfo = (blockNo) => {
    const channelQuickViewCtrl = channelInfoCtrl.filter(
      (info) => info.info_block === blockNo
    );
    const defaultConfig = {
      defaultDetailTxt: "--",
      tileCLass: "font-weight-bold",
    };
    return channelQuickViewCtrl.map((info, index) => {
      return (
        <>
          {info.info_heading || info.empty_info_heading ? (
            <h4
              className="form-header text-uppercase col-lg-12 p-01"
              key={"h" + index}
            >
              <i className="fas fa-user-circle"></i>
              {info.info_heading}
            </h4>
          ) : (
            ""
          )}
          {info.fields.map((field, i) => {
            return (
              <div className={`${field.detailLabel.className ? field.detailLabel.className : "popup-row"}`} key={"lb" + i}>
                {field.detailLabel.hasEyeIcon ? renderLabelValueWithEyeIcon(
                  field.titleLabel.text,
                  field.detailLabel.text,
                  channelQuickModalState[field.detailLabel.dataId],
                  channelQuickModalState[field.detailLabel.toolTipId],
                  channelQuickModalState.maskedValue,
                  field.detailLabel.dataId
                ) : renderBesicLabelPair(field, defaultConfig)}
              </div>
            );
          })}
        </>
      );
    });
  };

  return (
    <Draggable handle=".cursor">
      <Modal
        isOpen={props.showModal}
        className="no-cursor"
        style={{ minWidth: "120rem" }}
        toggle={() => {
          props.closeModal();
        }}
      >
        <ModalHeader
          className="modal-header bg-primary modal-title text-white cursor"
          toggle={() => {
            props.closeModal();
          }}
        >
          <i className="fas fa-globe pr-2"></i>
          Channel Quick View
        </ModalHeader>
        <ModalBody
          className=""
          style={{
            minHeight: "400px",
          }}
        >
          <div className="row col-lg-12 p-01" id="channelQuickViewModal">
            <div className="col-lg-12 row">
              {renderChannelViewSectionInfo(0)}
            </div>
            <div className="col-lg-4 partner-info-sec">
              {renderChannelViewSectionInfo(1)}
            </div>
            <div className="col-lg-4" style={{padding:'0rem 5rem 0 0rem'}}>
            {renderChannelViewSectionInfo(2)}
            {renderChannelViewSectionInfo(3)}
            </div>

            <div className="col-lg-4" style={{padding:'0rem 5rem 0 0rem'}}>              
              {renderChannelViewSectionInfo(4)}
            </div>
          </div>
        </ModalBody>
      </Modal>
    </Draggable>
  );
};
export default FoxChannelQuickModal;

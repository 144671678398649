import React from "react";
import { Link } from "react-router-dom";
import AddActivityModal from "../../activity/modal/add-activity-modal";
import { showNotification } from "../../../components/UI/Toaster/Toaster";
import AssetSummary from "../member-vehicle/modal/asset-summary";
import MemberVehicleFeature from "../member-vehicle/modal/member-vehicle-feature";
import MarkVehicleStatus from "../member-vehicle/modal/mark-vehicle-status-modal";
import UpdateVehicleMode from "../member-vehicle/modal/update-vehicle-mode-modal";
import PermissionName from "../../../shared/Permissions";
import {
  getErrorToastData,
  hasPermission,
} from "../../../shared/utility";
import { getMemberLabelByOrgRentalType } from "../../../shared/utility/system-utility";

const initialStates = {
  showAddActivityPopup: false,
  showSummaryPopup: false,
  showMemberVehicleFeaturePopup: false,
  showMarkVehicleStatusModal: false,
  showUpdateVehicleMode: false,
};

class VehicleBottomContainer extends React.Component {
  state = { ...initialStates };

  modalClose = () => {
    this.setState({
      showAddActivityPopup: false,
      showSummaryPopup: false,
      showMemberVehicleFeaturePopup: false,
      showMarkVehicleStatusModal: false,
      showUpdateVehicleMode: false,
    });
  };

  openNewActivityWindow = (event) => {
    if (this.checkRowSelected()) {
      this.setState({ showAddActivityPopup: true });
    }
  };
  openAssetSummaryWindow = (event) => {
    if (this.checkRowSelected()) {
      this.setState({ showSummaryPopup: true });
    }
  };
  openMemberVehicleFeatureWindow = (event) => {
    if (this.checkRowSelected()) {
      this.setState({ showMemberVehicleFeaturePopup: true });
    }
  };
  openMarkVehicleStatusWindow = (event) => {
    if (this.checkRowSelected()) {
      this.setState({ showMarkVehicleStatusModal: true });
    }
  };
  openUpdateVehicleModeWindow = (event) => {
    if (this.checkRowSelected()) {
      this.setState({ showUpdateVehicleMode: true });
    }
  };

  checkRowSelected = () => {
    if (this.props.isSelectAssetRow) {
      return true;
    } else {
      showNotification(getErrorToastData("Please select vehicle"));
      return false;
    }
  };

  goToReservationDetails = (resId) => {
    if (this.checkRowSelected()) {
      if (resId) {
        return true;
      } else {
        showNotification(getErrorToastData("There is no reservation!"));
        return false;
      }
    }
  };

  renderData() {
    let addNewActivityCtrl = "";
    if (this.state.showAddActivityPopup) {
      addNewActivityCtrl = (
        <AddActivityModal
          memberId={this.props.selectedAssets.OwnerId}
          showModal={this.state.showAddActivityPopup}
          closeModal={this.modalClose}
          resetParentPage={this.props.resetParentPage}
        />
      );
    } else {
      addNewActivityCtrl = null;
    }

    let assetSummaryCtrl = "";
    if (this.state.showSummaryPopup) {
      assetSummaryCtrl = (
        <AssetSummary
          assetSummaryData={this.props.selectedAssets}
          showModal={this.state.showSummaryPopup}
          closeModal={this.modalClose}
          title="Asset Details"
        />
      );
    } else {
      assetSummaryCtrl = null;
    }

    let memberVehicleFeaturesCtrl = "";
    if (this.state.showMemberVehicleFeaturePopup) {
      memberVehicleFeaturesCtrl = (
        <MemberVehicleFeature
          memberId={this.props.selectedAssets.OwnerId}
          memberAssetId={this.props.selectedAssets.PersonAssetId}
          showModal={this.state.showMemberVehicleFeaturePopup}
          closeModal={this.modalClose}
          title="Update Vehicle Features"
          height="auto"
          width="1000"
        />
      );
    } else {
      memberVehicleFeaturesCtrl = null;
    }

    let markVehicleStatusModalCtrl = "";
    if (this.state.showMarkVehicleStatusModal) {
      markVehicleStatusModalCtrl = (
        <MarkVehicleStatus
          memberId={this.props.selectedAssets.OwnerId}
          memberAssetId={this.props.selectedAssets.PersonAssetId}
          showModal={this.state.showMarkVehicleStatusModal}
          closeModal={this.modalClose}
          title="Mark Vehicle Offline"
          height="auto"
        />
      );
    } else {
      markVehicleStatusModalCtrl = null;
    }

    let updateVehicleModeCtrl = "";
    if (this.state.showUpdateVehicleMode) {
      updateVehicleModeCtrl = (
        <UpdateVehicleMode
          memberId={this.props.selectedAssets.OwnerId}
          memberAssetId={this.props.selectedAssets.PersonAssetId}
          bookingEnabled={this.props.selectedAssets.ListingsEnabled}
          reservationMode={this.props.selectedAssets.InstantRentalEnabled}
          showModal={this.state.showUpdateVehicleMode}
          closeModal={this.modalClose}
          title="Vehicle Mode"
          height="auto"
          width="300"
        />
      );
    } else {
      updateVehicleModeCtrl = null;
    }

    return (
      <div>
        {addNewActivityCtrl}
        {assetSummaryCtrl}
        {memberVehicleFeaturesCtrl}
        {markVehicleStatusModalCtrl}
        {updateVehicleModeCtrl}
      </div>
    );
  }

  render() {
    let disabledBtnClass =
      "btnVehicle waves-effect waves-light disabledLinkProp";
    let btnClass = disabledBtnClass;

    if (this.props.isSelectAssetRow) {
      btnClass = "btnVehicle btn-primary";
    }
    let lastResId = this.props.selectedAssets
      ? this.props.selectedAssets.LastReservationId
      : "";
    let nextResId = this.props.selectedAssets
      ? this.props.selectedAssets.NextReservationId
      : "";
    let memberId = this.props.selectedAssets
      ? this.props.selectedAssets.OwnerId
      : "";
    let unitNumber = this.props.selectedAssets
      ? this.props.selectedAssets.PersonAssetId
      : "";
    return (
      <div
        className="form-group mt-4"
        style={{ overflow: "auto", overflowY: "hidden", paddingTop: "2rem" }}
      >
        <span className="fs-2 text-primary">
          Select a vehicle to perform these actions
        </span>
        <div
          className="col-lg-12 memberVehicleSearchBtn"
          style={{ borderTop: "0.1rem solid #d3dce9", paddingTop: "1rem" }}
        >
          <button
            type="button"
            value="Search"
            className={btnClass}
            onClick={this.openAssetSummaryWindow}
            id="btnSummary"
          >
            Asset <br /> Summary
          </button>

          <button
            type="button"
            value="Search"
            className={disabledBtnClass}
            onClick={(event) => this.checkRowSelected(event)}
            id="btnPersonalPackage"
          >
            Personal <br />
            Package
          </button>

          {hasPermission(PermissionName.ADD_ACTIVITY_LOG) ? (
            <button
              type="button"
              value="Search"
              className={btnClass}
              onClick={this.openNewActivityWindow}
              id="btnAddActivity"
            >
              Add <br />
              Activity
            </button>
          ) : (
            ""
          )}
          {hasPermission(PermissionName.VIEW_RESERVATION) ? (
            <button
              type="button"
              value="Search"
              className={btnClass}
              onClick={(event) => this.checkRowSelected(event)}
              id="btnVehicleRes"
            >
              {unitNumber ? (
                <Link
                  className={btnClass}
                  to={{
                    pathname: "/member/memberVehicleReservation",
                    search: "?memberId=" + memberId + "&unitNumber=ZEULML", //unitNumber is not come through api, that's why used hard code value
                    state: {
                      breadCrumbList: this.props.breadCrumbList,
                      memberName:
                        getMemberLabelByOrgRentalType() +
                        " Vehicle Reservation",
                    },
                  }}
                >
                  My Vehicle <br />
                  Reservation
                </Link>
              ) : (
                <span>
                  My Vehicle <br /> Reservation{" "}
                </span>
              )}
            </button>
          ) : (
            ""
          )}
          {hasPermission(PermissionName.EDIT_VEHICLE) ? (
            <button
              type="button"
              value="Search"
              className={btnClass}
              onClick={this.openMemberVehicleFeatureWindow}
              id="btnUpdateVehicleFeatures"
            >
              Update Vehicle <br />
              Features
            </button>
          ) : (
            ""
          )}
          <button
            type="button"
            value="Search"
            className={disabledBtnClass}
            onClick={this.openMemberVehicleFeatureWindow}
            id="btnLocateVehicle"
          >
            Locate <br />
            Vehicle
          </button>
          {hasPermission(PermissionName.EDIT_VEHICLE) ? (
            <button
              type="button"
              value="Search"
              className={btnClass}
              onClick={this.openMarkVehicleStatusWindow}
              id="btnMarkVehicleOffline"
            >
              Mark Vehicle <br />
              Offline
            </button>
          ) : (
            ""
          )}
          <button
            type="button"
            value="Search"
            className={btnClass}
            onClick={(event) => this.openUpdateVehicleModeWindow(event)}
            id="btnChangeMode"
          >
            Change <br />
            Mode
          </button>
          {hasPermission(PermissionName.CANCEL_RESERVATION) ? (
            <button
              type="button"
              value="Search"
              className={btnClass}
              onClick={(event) => this.goToReservationDetails(nextResId)}
              id="btnCancelNextReservation"
            >
              {nextResId ? (
                <Link
                  className={btnClass}
                  to={{
                    pathname: "/reservationSummary",
                    search: "?reservationId=" + nextResId,
                    state: {
                      breadCrumbList: this.props.breadCrumbList,
                      memberName: getMemberLabelByOrgRentalType() + " Vehicle",
                    },
                  }}
                >
                  Cancel Next <br />
                  Reservation
                </Link>
              ) : (
                <span>
                  {" "}
                  Cancel Next <br /> Reservation{" "}
                </span>
              )}
            </button>
          ) : (
            ""
          )}
          {hasPermission(PermissionName.VIEW_RESERVATION) ? (
            <button
              type="button"
              value="Search"
              className={btnClass}
              onClick={(event) => this.goToReservationDetails(nextResId)}
              id="btnNextReservation"
            >
              {nextResId ? (
                <Link
                  className={btnClass}
                  to={{
                    pathname: "/reservationSummary",
                    search: "?reservationId=" + nextResId,
                    state: {
                      breadCrumbList: this.props.breadCrumbList,
                      memberName: getMemberLabelByOrgRentalType() + " Vehicle",
                    },
                  }}
                >
                  Next <br />
                  Reservation
                </Link>
              ) : (
                <span>
                  Next <br /> Reservation
                </span>
              )}
            </button>
          ) : (
            ""
          )}
          {hasPermission(PermissionName.VIEW_RESERVATION) ? (
            <button
              type="button"
              value="Search"
              className={btnClass}
              onClick={(event) => this.goToReservationDetails(lastResId)}
              id="btnLastReservation"
            >
              {lastResId ? (
                <Link
                  className={btnClass}
                  to={{
                    pathname: "/reservationSummary",
                    search: "?reservationId=" + lastResId,
                    state: {
                      breadCrumbList: this.props.breadCrumbList,
                      memberName: getMemberLabelByOrgRentalType() + " Vehicle",
                    },
                  }}
                >
                  Last <br />
                  Reservation
                </Link>
              ) : (
                <span>
                  Last
                  <br />
                  Reservation
                </span>
              )}
            </button>
          ) : (
            ""
          )}
          {this.props.isSelectAssetRow ? (
            <button
              type="button"
              value="Search"
              className={disabledBtnClass}
              onClick={(event) => this.checkRowSelected(event)}
              id="btnDetach"
            >
              Detach
              <br />
              Asset
            </button>
          ) : (
            ""
          )}
          {this.props.isSelectAssetRow ? (
            <button
              type="button"
              value="Search"
              className={disabledBtnClass}
              onClick={(event) => this.checkRowSelected(event)}
              id="btnLastReservation"
            >
              Manage <br />
              Device
            </button>
          ) : (
            ""
          )}
        </div>
        {this.renderData()}
      </div>
    );
  }
}

export default VehicleBottomContainer;

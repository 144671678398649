import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addMemberAddresses,
  addMemberAddressesReset,
} from "../../../store/actions/index";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Draggable from "react-draggable";
import { showNotification } from "../../../components/UI/Toaster/Toaster";
import AddressComponent from "../../../components/common/address-component/address-component";
import Address from "../../../services/entities/models/Address";
import  {
  getBasicToastResponse,
  getErrorToastData,
} from "../../../shared/utility";
import { validateAddEditAddress } from "../MemberHelper";
import { commonAddressChangeHandler } from "../../../components/common/common-helper";

export const AddAddresModal = (props) => {
  //const loading = useSelector(state => state.addressReducer.loading);
  const addAddressResponse = useSelector(
    (state) => state.addressReducer.addAddressResponse
  );
  const dispatch = useDispatch();
  const memberId = props.memberId;
  const [addAddressInputCtrl, setAddAddressInputCtrl] = useState(new Address());
  const [isValidateDone, setIsValidateDone] = useState(false);

  useEffect(() => {
    dispatch(addMemberAddressesReset());
  }, []);
  useEffect(() => {
    if (!addAddressResponse) {
      return;
    }
    showNotification(getBasicToastResponse(addAddressResponse));
    if (addAddressResponse.StatusCode === 0) {
      props.closeModal();
    }
    dispatch(addMemberAddressesReset());
  }, [addAddressResponse]);
  const inputAddressChangeHandler = (event, controlName, objectType, commonData) => {
    let addresObj = { ...addAddressInputCtrl };
        addresObj["isValidZipCode"] = true;
        addresObj = commonAddressChangeHandler(event, controlName, commonData, addresObj)
    setAddAddressInputCtrl(addresObj);
  };
  const addAddress = () => {
    const addAddressData = { ...addAddressInputCtrl };
    const addAddressReq = {};
    addAddressReq.MemberId = memberId;
    addAddressReq.AddressType = addAddressData.AddressType;
    addAddressReq.StreetAddress1 = addAddressData.Street1;
    addAddressReq.StreetAddress2 = addAddressData.Street2 || null;
    addAddressReq.Country = addAddressData.Country || null;
    addAddressReq.State = addAddressData.State || null;
    addAddressReq.StateStr = addAddressData.StateText || null;
    addAddressReq.City = addAddressData.City || null;
    addAddressReq.CityStr = addAddressData.CityText || null;
    addAddressReq.ZipCode = addAddressData.ZipCode || null;
    addAddressReq.ZipCodeStr = addAddressData.ZipCodeText || null;

    let msg = validateAddEditAddress(addAddressReq);
    if (msg) {
      showNotification(getErrorToastData(msg));
      setIsValidateDone(true);
    } else {
      setIsValidateDone(false);
      dispatch(addMemberAddressesReset());
      dispatch(addMemberAddresses(addAddressReq));
    }
  };
  return (
    <Draggable handle=".cursor">
      <Modal
        isOpen={props.showModal}
        className="no-cursor"
        toggle={() => {
          props.closeModal();
        }}
      >
        <ModalHeader
          className="modal-header bg-primary modal-title text-white cursor"
          toggle={() => {
            props.closeModal();
          }}
        >
          <i className="fas fa-edit" />
          Add Address
        </ModalHeader>
        <ModalBody
          className="tableScroll"
          style={{
            maxHeight: "500px",
            minHeight: "auto",
          }}
        >
          <div className="row">
            <div className="col-lg-12 mb-3">
              <h4 className="form-header text-uppercase">
                <i className="fas fa-user-circle"></i>Add Address
              </h4>
              <AddressComponent
                type="CREDIT-CARD"
                key="creditCard-address-component"
                onChangeMethod={inputAddressChangeHandler}
                resetLoader={props.resetLoader}
                address={{
                  ...addAddressInputCtrl,
                }}
                isAstericsRequired={true}
                adddressTypeRequired={true}
                isValidateDone={isValidateDone}
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            name="btnChangeAddress"
            className="btn btn-primary waves-effect waves-light btnSize ml-2"
            onClick={() => addAddress()}
          >
            Save
          </button>
          <button
            type="button"
            name="btnCancel"
            className="btn btn-secondary  waves-effect waves-light btnSize ml-2"
            onClick={() => props.closeModal()}
          >
            Cancel
          </button>
        </ModalFooter>
      </Modal>
    </Draggable>
  );
};
export default AddAddresModal;

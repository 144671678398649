import React from "react";

import Draggable from "react-draggable";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import DefaultComponentWithMessage from "../../../components/common/pages/default-component-with-message";
import { COMMON_MSG, OK_LBL } from "../../../shared/GlobalVar";

export const VehicleAvailabilityStatusModal = (props) => {
  return (
    <Draggable handle=".cursor">
      <Modal
        isOpen={props.showModal}
        className="no-cursor"
        toggle={() => {
          props.closeModal();
        }}
      >
        <ModalHeader
          className="modal-header bg-primary modal-title text-white cursor"
          toggle={() => {
            props.closeModal();
          }}
        >
          <i className="fas fa-arrows-alt pr-1" />
          {props.vehicleName ? props.vehicleName : "Vehicle Availability"}
        </ModalHeader>
        <ModalBody>
          <DefaultComponentWithMessage
            className="default-message-container-popup"
            innerClassName="no-search-popup"
            msg={"Vehicle Availability " + COMMON_MSG.UnderConstructionMessage}
          />
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            className="btn btn-secondary  waves-effect waves-light btnSize ml-2"
            onClick={(event) => props.closeModal()}
          >
            {OK_LBL}
          </button>
        </ModalFooter>
      </Modal>
    </Draggable>
  );
};
export default VehicleAvailabilityStatusModal;

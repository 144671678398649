import * as actionTypes from "../actions/actionTypes";
import { reducerUpdateObject } from "./reducer.utility";

const initialState = {
  blackListedPersonsListResponse: null,
  addBlackListedPersonInfosResponse: null,
  blackListedPersonEntityResponse: null,
  updateBlackListedPersonInfosResponse: null,
  deleteBlackListedPersonInfosResponse: null,
  loading: false,
  error: null,
};
const getBlackListedPersonInfosStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
const getBlackListedPersonInfosSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    blackListedPersonsListResponse: action.blackListedPersonsListResponse,
    error: null,
    loading: false,
  });
};
const getBlackListedPersonInfosFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false,
  });
};
const getBlackListedPersonInfosReset = (state, action) => {
  return reducerUpdateObject(state, { blackListedPersonsListResponse: null });
};

const addBlackListedPersonInfosStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
const addBlackListedPersonInfosSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    addBlackListedPersonInfosResponse: action.addBlackListedPersonInfosResponse,
    error: null,
    loading: false,
  });
};
const addBlackListedPersonInfosFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false,
  });
};
const addBlackListedPersonInfosReset = (state, action) => {
  return reducerUpdateObject(state, { addBlackListedPersonInfosResponse: null });
};

const addMembertoBlackListStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
const addMembertoBlackListSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    blackListedPersonEntityResponse: action.blackListedPersonEntityResponse,
    error: null,
    loading: false,
  });
};
const addMembertoBlackListFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false,
  });
};
const addMembertoBlackListReset = (state, action) => {
  return reducerUpdateObject(state, { blackListedPersonEntityResponse: null });
};

const updateBlackListedPersonInfosStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
const updateBlackListedPersonInfosSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    updateBlackListedPersonInfosResponse:
      action.updateBlackListedPersonInfosResponse,
    error: null,
    loading: false,
  });
};
const updateBlackListedPersonInfosFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false,
  });
};
const updateBlackListedPersonInfosReset = (state, action) => {
  return reducerUpdateObject(state, { updateBlackListedPersonInfosResponse: null });
};

const deleteBlackListedPersonInfosStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
const deleteBlackListedPersonInfosSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    deleteBlackListedPersonInfosResponse:
      action.deleteBlackListedPersonInfosResponse,
    error: null,
    loading: false,
  });
};
const deleteBlackListedPersonInfosFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false,
  });
};
const deleteBlackListedPersonInfosReset = (state, action) => {
  return reducerUpdateObject(state, { deleteBlackListedPersonInfosResponse: null });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_DO_NOT_RENT_LIST_START:
      return getBlackListedPersonInfosStart(state, action);
    case actionTypes.GET_DO_NOT_RENT_LIST_SUCCESS:
      return getBlackListedPersonInfosSuccess(state, action);
    case actionTypes.GET_DO_NOT_RENT_LIST_FAIL:
      return getBlackListedPersonInfosFail(state, action);
    case actionTypes.GET_DO_NOT_RENT_LIST_RESET:
      return getBlackListedPersonInfosReset(state, action);

    case actionTypes.ADD_DO_NOT_RENT_LIST_START:
      return addBlackListedPersonInfosStart(state, action);
    case actionTypes.ADD_DO_NOT_RENT_LIST_SUCCESS:
      return addBlackListedPersonInfosSuccess(state, action);
    case actionTypes.ADD_DO_NOT_RENT_LIST_FAIL:
      return addBlackListedPersonInfosFail(state, action);
    case actionTypes.ADD_DO_NOT_RENT_LIST_RESET:
      return addBlackListedPersonInfosReset(state, action);

    case actionTypes.ADD_PERSON_TO_BLACK_LIST_START:
      return addMembertoBlackListStart(state, action);
    case actionTypes.ADD_PERSON_TO_BLACK_LIST_SUCCESS:
      return addMembertoBlackListSuccess(state, action);
    case actionTypes.ADD_PERSON_TO_BLACK_LIST_FAIL:
      return addMembertoBlackListFail(state, action);
    case actionTypes.ADD_PERSON_TO_BLACK_LIST_RESET:
      return addMembertoBlackListReset(state, action);

    case actionTypes.UPDATE_DO_NOT_RENT_LIST_START:
      return updateBlackListedPersonInfosStart(state, action);
    case actionTypes.UPDATE_DO_NOT_RENT_LIST_SUCCESS:
      return updateBlackListedPersonInfosSuccess(state, action);
    case actionTypes.UPDATE_DO_NOT_RENT_LIST_FAIL:
      return updateBlackListedPersonInfosFail(state, action);
    case actionTypes.UPDATE_DO_NOT_RENT_LIST_RESET:
      return updateBlackListedPersonInfosReset(state, action);

    case actionTypes.DELETE_DO_NOT_RENT_LIST_START:
      return deleteBlackListedPersonInfosStart(state, action);
    case actionTypes.DELETE_DO_NOT_RENT_LIST_SUCCESS:
      return deleteBlackListedPersonInfosSuccess(state, action);
    case actionTypes.DELETE_DO_NOT_RENT_LIST_FAIL:
      return deleteBlackListedPersonInfosFail(state, action);
    case actionTypes.DELETE_DO_NOT_RENT_LIST_RESET:
      return deleteBlackListedPersonInfosReset(state, action);
    default:
      return state;
  }
};
export default reducer;

import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Draggable from "react-draggable";
import { getErrorToastData } from "../../../shared/utility";
import { showNotification } from "../../../components/UI/Toaster/Toaster";
import { getUpdatedOverrideManualValue, validateFleetForeCastEntity } from "../fleet-utilization-forecast-helper";
import { DropdownList } from "react-widgets";
import { FleetParametersDisplayValue, ReserveSyncOverrideParam } from "../../../shared/utility/enum-utility";

export const OverrideManualReserveSyncModal = (props) => {
  const {
    showModal,
    closeModal,
    parameter,
    manualVal,
    selectedData,
    overrideReasons,
    isReset,
    updateManualFleetutilizationForecastData
  } = props;

  const [manualOverrideReason, setManualOverrideReason] = useState({ key: '', value: 'Select Override Reason' })
  const [otherReason, setOtherReason] = useState("");
  const getOverrideResons = () => {
    return [{ key: '', value: 'Select Override Reason' }, ...overrideReasons];
  }
  const onUpdate = () => {
    const updateManualOverrideRequest = {
      fleetUtilizationForecastId: selectedData.fleetUtilizationForecastId,
      manualPickupThresholdPercent: selectedData.manualPickupThresholdPercent,
      manualBookThruThresholdPercent: selectedData.manualBookThruThresholdPercent,
      manualWalkinsCount: selectedData.manualWalkinsCount,
      manualNoShowFactor: selectedData.manualNoShowFactor,
      manualOverrideReason: manualOverrideReason.key,
    }
    if (manualOverrideReason.key === "OTHER") {
      updateManualOverrideRequest.manualOverrideOtherReason = otherReason;
    }
    let currentUpParam = ReserveSyncOverrideParam[parameter];
    updateManualOverrideRequest[currentUpParam] = isReset ? null : manualVal;
    let msg = validateFleetForeCastEntity(updateManualOverrideRequest, currentUpParam, selectedData[parameter], manualOverrideReason.key, isReset);
    if (msg) {
      showNotification(getErrorToastData(msg));
    } else {
      if(currentUpParam === ReserveSyncOverrideParam.defaultPickupThresholdPercent){
        updateManualOverrideRequest.manualPickupThresholdPercent = updateManualOverrideRequest[currentUpParam]
      }
      if(currentUpParam === ReserveSyncOverrideParam.defaultBookThruThresholdPercent){
        updateManualOverrideRequest.manualBookThruThresholdPercent = updateManualOverrideRequest[currentUpParam]
      }
      if(currentUpParam === ReserveSyncOverrideParam.manualWalkinsCount){
        updateManualOverrideRequest.manualWalkinsCount = updateManualOverrideRequest[currentUpParam]
      }
      if(currentUpParam === ReserveSyncOverrideParam.manualNoShowFactor){
        updateManualOverrideRequest.manualNoShowFactor = updateManualOverrideRequest[currentUpParam]
      }
      const mainReq = {
        fleetUtilizationForecast: { ...updateManualOverrideRequest }
      }
      updateManualFleetutilizationForecastData(mainReq);
    }
  };
  return (
    <Draggable handle=".cursor">
      <Modal
        isOpen={showModal}
        className="no-cursor fleet-override-modal"
        toggle={() => {
          closeModal();
        }}
        style={{ maxWidth: "400px" }}
      >
        <ModalHeader
          className="modal-header modal-title cursor"
          toggle={() => {
            closeModal();
          }}
        >
          {isReset ? "Rest Override" : "Override"}
        </ModalHeader>
        <ModalBody>
          <div className="col-sm-12 p-0">
            <h1 style={{ fontSize: '13px' }}>Are you sure you want to override this?</h1>
            <div className="override-prev-block">
              <h2 style={{ fontSize: '13px' }}>{FleetParametersDisplayValue[parameter]}</h2>
              <div>
                <span>Previous Value: { isReset? manualVal : getUpdatedOverrideManualValue(parameter, selectedData) ? getUpdatedOverrideManualValue(parameter, selectedData): selectedData[parameter] || "--"}</span>
                <i className="fa fa-circle"></i>
                <span>New Value: { isReset? selectedData[parameter] || "--" : manualVal}</span>
              </div>
            </div>
            <div className="res-sync-input-container">
              <label style={{ fontWeight: 600 }}>Other Reason</label>
              <DropdownList
                data={getOverrideResons()}
                dataKey='key'
                textField='value'
                value={manualOverrideReason}
                onChange={value => setManualOverrideReason(value)}
              />
            </div>
            {manualOverrideReason && manualOverrideReason.key === "OTHER" ?
              <div className="res-sync-input-container">
                <label style={{ fontWeight: 600 }}>Other Reason</label>
                <textarea
                  rows="20"
                  name="otherReason"
                  placeholder="Write override reason"
                  value={otherReason}
                  onChange={(e) => setOtherReason(e.target.value)}
                />
              </div> : ""}
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="col-sm-12 text-center">
            <button
              type="button"
              name="btnCancel"
              className="btn btn-fox-cancel waves-effect waves-light btnSize ml-2"
              onClick={() => closeModal()}
            >
              Not Now
            </button>
            <button
              type="button"
              name="btnYes"
              className="btn btn-fox waves-effect waves-light btnSize ml-2"
              onClick={() => onUpdate()}
            >
              {isReset ? "Yes, Reset Please" : "Yes, Override Please"}
            </button>
          </div>
        </ModalFooter>
      </Modal>
    </Draggable>
  );
};
export default OverrideManualReserveSyncModal;

export const getDayName = () => {
  return [
    {
      value: "0",
      Name: "Sunday",
    },
    {
      value: "1",
      Name: "Monday",
    },
    {
      value: "2",
      Name: "Tuesday",
    },
    {
      value: "3",
      Name: "Wednesday",
    },
    {
      value: "4",
      Name: "Thursday",
    },
    {
      value: "5",
      Name: "Friday",
    },
    {
      value: "6",
      Name: "Saturday",
    },
  ];
};

export const reservationMemberData = {
  foxCustomerViewModel: {
    customerId: 10459588514,
    email: "pray+test12@foxrentacar.com",
    renterId: "TI-963321",
    foxCredit: null,
    firstName: "pritam",
    middleName: "dfg",
    lastName: "tset QA3",
    dob: "2000-01-22T00:00:00",
    emailDeals: null,
    textDeals: null,
    reservationCount: null,
    externalId: null,
    xpress: null,
    payNow: null,
    primaryAddress: {
      type: "HOME",
      street: "TSEt",
      street2: null,
      city: "ABBEVILLE",
      province: "ALABAMA",
      postalCode: "36310",
      country: "US",
    },
    mobilePhone: {
      type: "Mobile",
      countryCode: 1,
      countryIso: "US",
      number: 1232323456,
    },
    homePhone: {
      type: "Mobile",
      countryCode: 880,
      countryIso: "BD",
      number: 1734844321,
    },
    workPhone: {
      type: "Mobile",
      countryCode: 91,
      countryIso: "IN",
      number: 7777888899,
    },
    license: {
      country: "ES",
      province: "Madrid",
      number: "A3454522",
      expDate: "2029-01-31T00:00:00",
      licenseEditable: true,
      manualDmvVerified: false,
      tempLicenseUploadAllowed: true,
      licenseManuallyApproved: false,
    },
    insurance: {
      insuranceVerified: null,
      insuranceExpirationDate: null,
    },
    methodType: null,
    paymentService: null,
    expDate: null,
    accountNumber: null,
    name: null,
    creditAddress: {
      type: null,
      street: null,
      street2: null,
      city: null,
      province: null,
      postalCode: null,
      country: "US",
    },
    securityCode: null,
    paymentFailureCount: 0,
    fullName: "",
    employeeNo: null,
    employeeLocationName: null,
    companyName: null,
    designation: null,
    tempId: "9a3d0b93-a647-4c41-bad7-13463f979fc0",
    isCustomerBlockChanged: true,
    stepViewModel: {
      executionBlock: null,
      prevBlock: "ADDONS_FORM",
      nextBlock: "RES_NOTES_BLOCK",
      currentBlock: "CUSTOMER_INFO",
      iskeepChanged: false,
      wizardMsg: null,
      archiveData: null,
      isError: false,
    },
  },
  foxAllRateResponse: {
    returnLocation: {
      providerName: "TSD",
      extendedLocationCode: "DENO01",
      locationCode: "DEN",
      locationId: 10002746383,
      locationType: "CORPORATE",
    },
    rentalLocation: {
      providerName: "TSD",
      extendedLocationCode: "DENO01",
      locationCode: "DEN",
      locationId: 10002746383,
      locationType: "CORPORATE",
    },
    rentalOffers: [
      {
        vehicleClass: [
          {
            transmission: "AUTOMATIC",
            advBookingHours: 15,
            largeLuggageCount: 1,
            smallLuggageCount: 2,
            airConditioning: true,
            tags: [
              "Standard",
              "2/4 door",
              "Unspecified fuel, A/C",
              "Auto drive",
            ],
            minimumAge: 18,
            maximumAge: 99,
            classCode: "SCAR",
            classImageURL:
              "https://www.foxrentacar.com/content/dam/fox-rent-a-car/en/vehicles/Corporate/42080-SCAR.png/_jcr_content/renditions/cq5dam.thumbnail.319.319.png",
            displayText: "SCAR - Standard",
            categoryId: 10004659197,
            group: "CAR_SEDAN",
            classDesc: "Standard 2/4 Door Automatic With AC",
            modelDesc: "Nissan Altima or Similar",
            luggageCount: 3,
            passengerCount: 5,
            mpgCity: 25,
            mpgHighway: 37,
          },
        ],
        rateProduct: [
          {
            prepaidAllowed: true,
            debitAllowed: true,
            type: "Retail",
            cardRequired: true,
            pricing: [
              {
                priceCode: "DTS",
                surcharges: [
                  {
                    desc: "Surcharge",
                    code: "SUR",
                    chargeType: "SURCHARGE",
                    amount: 0,
                    recurrenceUnit: "ONETIME",
                  },
                  {
                    desc: "CONSOLIDATED FACILITY",
                    code: "CFC",
                    chargeType: "SURCHARGE",
                    amount: 6,
                    recurrenceUnit: "ONETIME",
                  },
                  {
                    desc: "VEHICLE LICENSE FEE",
                    code: "VLF",
                    chargeType: "SURCHARGE",
                    amount: 0.35,
                    recurrenceUnit: "ONETIME",
                  },
                  {
                    desc: "ENERGY RECOVERY FEE",
                    code: "ERF",
                    chargeType: "SURCHARGE",
                    amount: 1.31,
                    recurrenceUnit: "ONETIME",
                  },
                  {
                    desc: "CO ROAD SAFETY FEE",
                    code: "CRSPF",
                    chargeType: "SURCHARGE",
                    amount: 2.13,
                    recurrenceUnit: "ONETIME",
                  },
                ],
                totalSurcharges: 9.79,
                taxCharges: 5.04,
                addOnCharges: 0,
                ratesApplied: [
                  {
                    freeUnits: 0,
                    type: "HOURLY",
                    amount: 0,
                    rate: 0,
                    name: "Hourly Rate",
                  },
                  {
                    freeUnits: 0,
                    type: "MONTHLY",
                    amount: 0,
                    rate: 0,
                    name: "Monthly Rate",
                  },
                  {
                    freeUnits: 0,
                    type: "DAILY",
                    amount: 1,
                    rate: 17.85,
                    name: "Weekday Rate",
                  },
                  {
                    freeUnits: 0,
                    type: "DAILY",
                    amount: 0,
                    rate: 17.85,
                    name: "Weekend Day Rate",
                  },
                  {
                    freeUnits: 0,
                    type: "WEEKEND",
                    amount: 0,
                    rate: 0,
                    name: "Weekend Rate",
                  },
                  {
                    freeUnits: 0,
                    type: "WEEKLY",
                    amount: 0,
                    rate: 0,
                    name: "Weekly Rate",
                  },
                ],
                type: "PrePaid",
                currencyCode: "USD",
                prepaidBaseDiscount: 15,
                prepaidSavedAmount: 3.97,
                freeMiles: 0,
                extraDayFreeMiles: 0,
                totalFreeMiles: 0,
                extraWeekRate: 0,
                rateVendor: "42080",
                lateCharge: 0,
                ratePlan: {
                  type: "DAILY",
                  rate: 17.85,
                },
                balanceDue: 32.68,
                mileageUnit: "MI",
                totalCharges: 32.68,
                perMileAmount: 0,
                extraDayRate: 17.85,
                taxes: [
                  {
                    taxableAmount: 19.51,
                    type: "PERCENT",
                    percent: 11.11,
                    desc: "DIA CONC. 11.11PCT",
                    amount: 2.17,
                  },
                  {
                    taxableAmount: 21.68,
                    type: "PERCENT",
                    percent: 13.25,
                    desc: "DEN RENTAL TAX13.25PCT",
                    amount: 2.87,
                  },
                ],
                rateId: "10595854578",
                rateCharge: 17.85,
                ratePlusLate: 17.85,
                rentalDays: 1,
              },
              {
                priceCode: "DTS",
                surcharges: [
                  {
                    desc: "Surcharge",
                    code: "SUR",
                    chargeType: "SURCHARGE",
                    amount: 0,
                    recurrenceUnit: "ONETIME",
                  },
                  {
                    desc: "CONSOLIDATED FACILITY",
                    code: "CFC",
                    chargeType: "SURCHARGE",
                    amount: 6,
                    recurrenceUnit: "ONETIME",
                  },
                  {
                    desc: "VEHICLE LICENSE FEE",
                    code: "VLF",
                    chargeType: "SURCHARGE",
                    amount: 0.35,
                    recurrenceUnit: "ONETIME",
                  },
                  {
                    desc: "ENERGY RECOVERY FEE",
                    code: "ERF",
                    chargeType: "SURCHARGE",
                    amount: 1.31,
                    recurrenceUnit: "ONETIME",
                  },
                  {
                    desc: "CO ROAD SAFETY FEE",
                    code: "CRSPF",
                    chargeType: "SURCHARGE",
                    amount: 2.13,
                    recurrenceUnit: "ONETIME",
                  },
                ],
                totalSurcharges: 9.79,
                taxCharges: 5.86,
                addOnCharges: 0,
                ratesApplied: [
                  {
                    freeUnits: 0,
                    type: "HOURLY",
                    amount: 0,
                    rate: 0,
                    name: "Hourly Rate",
                  },
                  {
                    freeUnits: 0,
                    type: "MONTHLY",
                    amount: 0,
                    rate: 0,
                    name: "Monthly Rate",
                  },
                  {
                    freeUnits: 0,
                    type: "DAILY",
                    amount: 1,
                    rate: 21,
                    name: "Weekday Rate",
                  },
                  {
                    freeUnits: 0,
                    type: "DAILY",
                    amount: 0,
                    rate: 21,
                    name: "Weekend Day Rate",
                  },
                  {
                    freeUnits: 0,
                    type: "WEEKEND",
                    amount: 0,
                    rate: 0,
                    name: "Weekend Rate",
                  },
                  {
                    freeUnits: 0,
                    type: "WEEKLY",
                    amount: 0,
                    rate: 0,
                    name: "Weekly Rate",
                  },
                ],
                type: "PayLater",
                currencyCode: "USD",
                freeMiles: 0,
                extraDayFreeMiles: 0,
                totalFreeMiles: 0,
                extraWeekRate: 0,
                rateVendor: "42080",
                lateCharge: 0,
                ratePlan: {
                  type: "DAILY",
                  rate: 21,
                },
                balanceDue: 36.65,
                mileageUnit: "MI",
                totalCharges: 36.65,
                perMileAmount: 0,
                extraDayRate: 21,
                taxes: [
                  {
                    taxableAmount: 22.66,
                    type: "PERCENT",
                    percent: 11.11,
                    desc: "DIA CONC. 11.11PCT",
                    amount: 2.52,
                  },
                  {
                    taxableAmount: 25.18,
                    type: "PERCENT",
                    percent: 13.25,
                    desc: "DEN RENTAL TAX13.25PCT",
                    amount: 3.34,
                  },
                ],
                rateId: "10595854579",
                rateCharge: 21,
                ratePlusLate: 21,
                rentalDays: 1,
              },
            ],
            rateStartDate: "2024-02-20T00:00:00",
            rateEndDate: "2024-02-20T00:00:00",
          },
        ],
        offerStartDate: "2024-02-20T00:00:00",
        offerEndDate: "2024-02-20T00:00:00",
      },
      {
        vehicleClass: [
          {
            transmission: "AUTOMATIC",
            advBookingHours: 15,
            largeLuggageCount: 2,
            smallLuggageCount: 2,
            airConditioning: true,
            tags: [
              "Fullsize",
              "2/4 door",
              "Unspecified fuel, A/C",
              "Auto drive",
            ],
            minimumAge: 18,
            maximumAge: 99,
            classCode: "FCAR",
            classImageURL:
              "https://www.foxrentacar.com/content/dam/fox-rent-a-car/en/vehicles/Corporate/42080-FCAR.png/_jcr_content/renditions/cq5dam.thumbnail.319.319.png",
            displayText: "FCAR - Fullsize",
            categoryId: 10004659202,
            group: "CAR_SEDAN",
            classDesc: "Fullsize 2/4 Door Automatic With AC",
            modelDesc: "Toyota Camry or Similar",
            luggageCount: 4,
            passengerCount: 5,
            mpgCity: 25,
            mpgHighway: 35,
          },
        ],
        rateProduct: [
          {
            prepaidAllowed: true,
            debitAllowed: true,
            type: "Retail",
            cardRequired: true,
            pricing: [
              {
                priceCode: "DTS",
                surcharges: [
                  {
                    desc: "Surcharge",
                    code: "SUR",
                    chargeType: "SURCHARGE",
                    amount: 0,
                    recurrenceUnit: "ONETIME",
                  },
                  {
                    desc: "CONSOLIDATED FACILITY",
                    code: "CFC",
                    chargeType: "SURCHARGE",
                    amount: 6,
                    recurrenceUnit: "ONETIME",
                  },
                  {
                    desc: "VEHICLE LICENSE FEE",
                    code: "VLF",
                    chargeType: "SURCHARGE",
                    amount: 0.35,
                    recurrenceUnit: "ONETIME",
                  },
                  {
                    desc: "ENERGY RECOVERY FEE",
                    code: "ERF",
                    chargeType: "SURCHARGE",
                    amount: 1.31,
                    recurrenceUnit: "ONETIME",
                  },
                  {
                    desc: "CO ROAD SAFETY FEE",
                    code: "CRSPF",
                    chargeType: "SURCHARGE",
                    amount: 2.13,
                    recurrenceUnit: "ONETIME",
                  },
                ],
                totalSurcharges: 9.79,
                taxCharges: 5.21,
                addOnCharges: 0,
                ratesApplied: [
                  {
                    freeUnits: 0,
                    type: "HOURLY",
                    amount: 0,
                    rate: 0,
                    name: "Hourly Rate",
                  },
                  {
                    freeUnits: 0,
                    type: "MONTHLY",
                    amount: 0,
                    rate: 0,
                    name: "Monthly Rate",
                  },
                  {
                    freeUnits: 0,
                    type: "DAILY",
                    amount: 1,
                    rate: 18.49,
                    name: "Weekday Rate",
                  },
                  {
                    freeUnits: 0,
                    type: "DAILY",
                    amount: 0,
                    rate: 18.49,
                    name: "Weekend Day Rate",
                  },
                  {
                    freeUnits: 0,
                    type: "WEEKEND",
                    amount: 0,
                    rate: 0,
                    name: "Weekend Rate",
                  },
                  {
                    freeUnits: 0,
                    type: "WEEKLY",
                    amount: 0,
                    rate: 0,
                    name: "Weekly Rate",
                  },
                ],
                type: "PrePaid",
                currencyCode: "USD",
                prepaidBaseDiscount: 15,
                prepaidSavedAmount: 4.1,
                freeMiles: 0,
                extraDayFreeMiles: 0,
                totalFreeMiles: 0,
                extraWeekRate: 0,
                rateVendor: "42080",
                lateCharge: 0,
                ratePlan: {
                  type: "DAILY",
                  rate: 18.49,
                },
                balanceDue: 33.49,
                mileageUnit: "MI",
                totalCharges: 33.49,
                perMileAmount: 0,
                extraDayRate: 18.49,
                taxes: [
                  {
                    taxableAmount: 20.15,
                    type: "PERCENT",
                    percent: 11.11,
                    desc: "DIA CONC. 11.11PCT",
                    amount: 2.24,
                  },
                  {
                    taxableAmount: 22.39,
                    type: "PERCENT",
                    percent: 13.25,
                    desc: "DEN RENTAL TAX13.25PCT",
                    amount: 2.97,
                  },
                ],
                rateId: "10595854582",
                rateCharge: 18.49,
                ratePlusLate: 18.49,
                rentalDays: 1,
              },
              {
                priceCode: "DTS",
                surcharges: [
                  {
                    desc: "Surcharge",
                    code: "SUR",
                    chargeType: "SURCHARGE",
                    amount: 0,
                    recurrenceUnit: "ONETIME",
                  },
                  {
                    desc: "CONSOLIDATED FACILITY",
                    code: "CFC",
                    chargeType: "SURCHARGE",
                    amount: 6,
                    recurrenceUnit: "ONETIME",
                  },
                  {
                    desc: "VEHICLE LICENSE FEE",
                    code: "VLF",
                    chargeType: "SURCHARGE",
                    amount: 0.35,
                    recurrenceUnit: "ONETIME",
                  },
                  {
                    desc: "ENERGY RECOVERY FEE",
                    code: "ERF",
                    chargeType: "SURCHARGE",
                    amount: 1.31,
                    recurrenceUnit: "ONETIME",
                  },
                  {
                    desc: "CO ROAD SAFETY FEE",
                    code: "CRSPF",
                    chargeType: "SURCHARGE",
                    amount: 2.13,
                    recurrenceUnit: "ONETIME",
                  },
                ],
                totalSurcharges: 9.79,
                taxCharges: 6.05,
                addOnCharges: 0,
                ratesApplied: [
                  {
                    freeUnits: 0,
                    type: "HOURLY",
                    amount: 0,
                    rate: 0,
                    name: "Hourly Rate",
                  },
                  {
                    freeUnits: 0,
                    type: "MONTHLY",
                    amount: 0,
                    rate: 0,
                    name: "Monthly Rate",
                  },
                  {
                    freeUnits: 0,
                    type: "DAILY",
                    amount: 1,
                    rate: 21.75,
                    name: "Weekday Rate",
                  },
                  {
                    freeUnits: 0,
                    type: "DAILY",
                    amount: 0,
                    rate: 21.75,
                    name: "Weekend Day Rate",
                  },
                  {
                    freeUnits: 0,
                    type: "WEEKEND",
                    amount: 0,
                    rate: 0,
                    name: "Weekend Rate",
                  },
                  {
                    freeUnits: 0,
                    type: "WEEKLY",
                    amount: 0,
                    rate: 0,
                    name: "Weekly Rate",
                  },
                ],
                type: "PayLater",
                currencyCode: "USD",
                freeMiles: 0,
                extraDayFreeMiles: 0,
                totalFreeMiles: 0,
                extraWeekRate: 0,
                rateVendor: "42080",
                lateCharge: 0,
                ratePlan: {
                  type: "DAILY",
                  rate: 21.75,
                },
                balanceDue: 37.59,
                mileageUnit: "MI",
                totalCharges: 37.59,
                perMileAmount: 0,
                extraDayRate: 21.75,
                taxes: [
                  {
                    taxableAmount: 23.41,
                    type: "PERCENT",
                    percent: 11.11,
                    desc: "DIA CONC. 11.11PCT",
                    amount: 2.6,
                  },
                  {
                    taxableAmount: 26.01,
                    type: "PERCENT",
                    percent: 13.25,
                    desc: "DEN RENTAL TAX13.25PCT",
                    amount: 3.45,
                  },
                ],
                rateId: "10595854583",
                rateCharge: 21.75,
                ratePlusLate: 21.75,
                rentalDays: 1,
              },
            ],
            rateStartDate: "2024-02-20T00:00:00",
            rateEndDate: "2024-02-20T00:00:00",
          },
        ],
        offerStartDate: "2024-02-20T00:00:00",
        offerEndDate: "2024-02-20T00:00:00",
      },
      {
        vehicleClass: [
          {
            transmission: "AUTOMATIC",
            advBookingHours: 15,
            largeLuggageCount: 2,
            smallLuggageCount: 2,
            airConditioning: true,
            tags: [
              "Special",
              "Special Offer Car",
              "Unspecified fuel, A/C",
              "Auto drive",
            ],
            minimumAge: 18,
            maximumAge: 99,
            classCode: "XZAR",
            classImageURL:
              "https://www.foxrentacar.com/content/dam/fox-rent-a-car/en/vehicles/Corporate/42080-XZAR.png/_jcr_content/renditions/cq5dam.thumbnail.319.319.png",
            displayText: "XZAR - Special",
            categoryId: 10021626479,
            group: "SPECIAL_OFFER",
            classDesc: "Special Special Offer Automatic With AC",
            modelDesc: "FULL-SIZE HOT DEAL. Our Choice Full-size...",
            luggageCount: 4,
            passengerCount: 5,
            mpgCity: 25,
            mpgHighway: 35,
          },
        ],
        rateProduct: [
          {
            prepaidAllowed: true,
            debitAllowed: true,
            type: "Retail",
            cardRequired: true,
            pricing: [
              {
                priceCode: "DTS",
                surcharges: [
                  {
                    desc: "Surcharge",
                    code: "SUR",
                    chargeType: "SURCHARGE",
                    amount: 0,
                    recurrenceUnit: "ONETIME",
                  },
                  {
                    desc: "CONSOLIDATED FACILITY",
                    code: "CFC",
                    chargeType: "SURCHARGE",
                    amount: 6,
                    recurrenceUnit: "ONETIME",
                  },
                  {
                    desc: "VEHICLE LICENSE FEE",
                    code: "VLF",
                    chargeType: "SURCHARGE",
                    amount: 0.35,
                    recurrenceUnit: "ONETIME",
                  },
                  {
                    desc: "ENERGY RECOVERY FEE",
                    code: "ERF",
                    chargeType: "SURCHARGE",
                    amount: 1.31,
                    recurrenceUnit: "ONETIME",
                  },
                  {
                    desc: "CO ROAD SAFETY FEE",
                    code: "CRSPF",
                    chargeType: "SURCHARGE",
                    amount: 2.13,
                    recurrenceUnit: "ONETIME",
                  },
                ],
                totalSurcharges: 9.79,
                taxCharges: 5.68,
                addOnCharges: 0,
                ratesApplied: [
                  {
                    freeUnits: 0,
                    type: "HOURLY",
                    amount: 0,
                    rate: 0,
                    name: "Hourly Rate",
                  },
                  {
                    freeUnits: 0,
                    type: "MONTHLY",
                    amount: 0,
                    rate: 0,
                    name: "Monthly Rate",
                  },
                  {
                    freeUnits: 0,
                    type: "DAILY",
                    amount: 1,
                    rate: 20.32,
                    name: "Weekday Rate",
                  },
                  {
                    freeUnits: 0,
                    type: "DAILY",
                    amount: 0,
                    rate: 20.32,
                    name: "Weekend Day Rate",
                  },
                  {
                    freeUnits: 0,
                    type: "WEEKEND",
                    amount: 0,
                    rate: 0,
                    name: "Weekend Rate",
                  },
                  {
                    freeUnits: 0,
                    type: "WEEKLY",
                    amount: 0,
                    rate: 0,
                    name: "Weekly Rate",
                  },
                ],
                type: "PrePaid",
                currencyCode: "USD",
                prepaidBaseDiscount: 15,
                prepaidSavedAmount: 4.51,
                freeMiles: 0,
                extraDayFreeMiles: 0,
                totalFreeMiles: 0,
                extraWeekRate: 0,
                rateVendor: "42080",
                lateCharge: 0,
                ratePlan: {
                  type: "DAILY",
                  rate: 20.32,
                },
                balanceDue: 35.79,
                mileageUnit: "MI",
                totalCharges: 35.79,
                perMileAmount: 0,
                extraDayRate: 20.32,
                taxes: [
                  {
                    taxableAmount: 21.98,
                    type: "PERCENT",
                    percent: 11.11,
                    desc: "DIA CONC. 11.11PCT",
                    amount: 2.44,
                  },
                  {
                    taxableAmount: 24.42,
                    type: "PERCENT",
                    percent: 13.25,
                    desc: "DEN RENTAL TAX13.25PCT",
                    amount: 3.24,
                  },
                ],
                rateId: "10595854584",
                rateCharge: 20.32,
                ratePlusLate: 20.32,
                rentalDays: 1,
              },
              {
                priceCode: "DTS",
                surcharges: [
                  {
                    desc: "Surcharge",
                    code: "SUR",
                    chargeType: "SURCHARGE",
                    amount: 0,
                    recurrenceUnit: "ONETIME",
                  },
                  {
                    desc: "CONSOLIDATED FACILITY",
                    code: "CFC",
                    chargeType: "SURCHARGE",
                    amount: 6,
                    recurrenceUnit: "ONETIME",
                  },
                  {
                    desc: "VEHICLE LICENSE FEE",
                    code: "VLF",
                    chargeType: "SURCHARGE",
                    amount: 0.35,
                    recurrenceUnit: "ONETIME",
                  },
                  {
                    desc: "ENERGY RECOVERY FEE",
                    code: "ERF",
                    chargeType: "SURCHARGE",
                    amount: 1.31,
                    recurrenceUnit: "ONETIME",
                  },
                  {
                    desc: "CO ROAD SAFETY FEE",
                    code: "CRSPF",
                    chargeType: "SURCHARGE",
                    amount: 2.13,
                    recurrenceUnit: "ONETIME",
                  },
                ],
                totalSurcharges: 9.79,
                taxCharges: 6.6,
                addOnCharges: 0,
                ratesApplied: [
                  {
                    freeUnits: 0,
                    type: "HOURLY",
                    amount: 0,
                    rate: 0,
                    name: "Hourly Rate",
                  },
                  {
                    freeUnits: 0,
                    type: "MONTHLY",
                    amount: 0,
                    rate: 0,
                    name: "Monthly Rate",
                  },
                  {
                    freeUnits: 0,
                    type: "DAILY",
                    amount: 1,
                    rate: 23.91,
                    name: "Weekday Rate",
                  },
                  {
                    freeUnits: 0,
                    type: "DAILY",
                    amount: 0,
                    rate: 23.91,
                    name: "Weekend Day Rate",
                  },
                  {
                    freeUnits: 0,
                    type: "WEEKEND",
                    amount: 0,
                    rate: 0,
                    name: "Weekend Rate",
                  },
                  {
                    freeUnits: 0,
                    type: "WEEKLY",
                    amount: 0,
                    rate: 0,
                    name: "Weekly Rate",
                  },
                ],
                type: "PayLater",
                currencyCode: "USD",
                freeMiles: 0,
                extraDayFreeMiles: 0,
                totalFreeMiles: 0,
                extraWeekRate: 0,
                rateVendor: "42080",
                lateCharge: 0,
                ratePlan: {
                  type: "DAILY",
                  rate: 23.91,
                },
                balanceDue: 40.3,
                mileageUnit: "MI",
                totalCharges: 40.3,
                perMileAmount: 0,
                extraDayRate: 23.91,
                taxes: [
                  {
                    taxableAmount: 25.57,
                    type: "PERCENT",
                    percent: 11.11,
                    desc: "DIA CONC. 11.11PCT",
                    amount: 2.84,
                  },
                  {
                    taxableAmount: 28.41,
                    type: "PERCENT",
                    percent: 13.25,
                    desc: "DEN RENTAL TAX13.25PCT",
                    amount: 3.76,
                  },
                ],
                rateId: "10595854585",
                rateCharge: 23.91,
                ratePlusLate: 23.91,
                rentalDays: 1,
              },
            ],
            rateStartDate: "2024-02-20T00:00:00",
            rateEndDate: "2024-02-20T00:00:00",
          },
        ],
        offerStartDate: "2024-02-20T00:00:00",
        offerEndDate: "2024-02-20T00:00:00",
      },
      {
        vehicleClass: [
          {
            transmission: "AUTOMATIC",
            advBookingHours: 15,
            largeLuggageCount: 1,
            smallLuggageCount: 2,
            airConditioning: true,
            tags: ["Special", "Sport", "Unspecified fuel, A/C", "Auto drive"],
            minimumAge: 18,
            maximumAge: 99,
            classCode: "XSAR",
            classImageURL:
              "https://www.foxrentacar.com/content/dam/fox-rent-a-car/en/vehicles/Corporate/42080-XSAR.png/_jcr_content/renditions/cq5dam.thumbnail.319.319.png",
            displayText: "XSAR - Special",
            categoryId: 10008478385,
            group: "SPORT",
            classDesc: "Special Sport Automatic With AC",
            modelDesc: "Dodge Challenger or Similar",
            luggageCount: 3,
            passengerCount: 5,
            mpgCity: 18,
            mpgHighway: 27,
          },
        ],
        rateProduct: [
          {
            prepaidAllowed: true,
            debitAllowed: true,
            type: "Retail",
            cardRequired: true,
            pricing: [
              {
                priceCode: "DTS",
                surcharges: [
                  {
                    desc: "Surcharge",
                    code: "SUR",
                    chargeType: "SURCHARGE",
                    amount: 0,
                    recurrenceUnit: "ONETIME",
                  },
                  {
                    desc: "CONSOLIDATED FACILITY",
                    code: "CFC",
                    chargeType: "SURCHARGE",
                    amount: 6,
                    recurrenceUnit: "ONETIME",
                  },
                  {
                    desc: "VEHICLE LICENSE FEE",
                    code: "VLF",
                    chargeType: "SURCHARGE",
                    amount: 0.35,
                    recurrenceUnit: "ONETIME",
                  },
                  {
                    desc: "ENERGY RECOVERY FEE",
                    code: "ERF",
                    chargeType: "SURCHARGE",
                    amount: 1.31,
                    recurrenceUnit: "ONETIME",
                  },
                  {
                    desc: "CO ROAD SAFETY FEE",
                    code: "CRSPF",
                    chargeType: "SURCHARGE",
                    amount: 2.13,
                    recurrenceUnit: "ONETIME",
                  },
                ],
                totalSurcharges: 9.79,
                taxCharges: 5.78,
                addOnCharges: 0,
                ratesApplied: [
                  {
                    freeUnits: 0,
                    type: "HOURLY",
                    amount: 0,
                    rate: 0,
                    name: "Hourly Rate",
                  },
                  {
                    freeUnits: 0,
                    type: "MONTHLY",
                    amount: 0,
                    rate: 0,
                    name: "Monthly Rate",
                  },
                  {
                    freeUnits: 0,
                    type: "DAILY",
                    amount: 1,
                    rate: 20.71,
                    name: "Weekday Rate",
                  },
                  {
                    freeUnits: 0,
                    type: "DAILY",
                    amount: 0,
                    rate: 20.71,
                    name: "Weekend Day Rate",
                  },
                  {
                    freeUnits: 0,
                    type: "WEEKEND",
                    amount: 0,
                    rate: 0,
                    name: "Weekend Rate",
                  },
                  {
                    freeUnits: 0,
                    type: "WEEKLY",
                    amount: 0,
                    rate: 0,
                    name: "Weekly Rate",
                  },
                ],
                type: "PrePaid",
                currencyCode: "USD",
                prepaidBaseDiscount: 15,
                prepaidSavedAmount: 4.59,
                freeMiles: 0,
                extraDayFreeMiles: 0,
                totalFreeMiles: 0,
                extraWeekRate: 0,
                rateVendor: "42080",
                lateCharge: 0,
                ratePlan: {
                  type: "DAILY",
                  rate: 20.71,
                },
                balanceDue: 36.28,
                mileageUnit: "MI",
                totalCharges: 36.28,
                perMileAmount: 0,
                extraDayRate: 20.71,
                taxes: [
                  {
                    taxableAmount: 22.37,
                    type: "PERCENT",
                    percent: 11.11,
                    desc: "DIA CONC. 11.11PCT",
                    amount: 2.49,
                  },
                  {
                    taxableAmount: 24.86,
                    type: "PERCENT",
                    percent: 13.25,
                    desc: "DEN RENTAL TAX13.25PCT",
                    amount: 3.29,
                  },
                ],
                rateId: "10595854588",
                rateCharge: 20.71,
                ratePlusLate: 20.71,
                rentalDays: 1,
              },
              {
                priceCode: "DTS",
                surcharges: [
                  {
                    desc: "Surcharge",
                    code: "SUR",
                    chargeType: "SURCHARGE",
                    amount: 0,
                    recurrenceUnit: "ONETIME",
                  },
                  {
                    desc: "CONSOLIDATED FACILITY",
                    code: "CFC",
                    chargeType: "SURCHARGE",
                    amount: 6,
                    recurrenceUnit: "ONETIME",
                  },
                  {
                    desc: "VEHICLE LICENSE FEE",
                    code: "VLF",
                    chargeType: "SURCHARGE",
                    amount: 0.35,
                    recurrenceUnit: "ONETIME",
                  },
                  {
                    desc: "ENERGY RECOVERY FEE",
                    code: "ERF",
                    chargeType: "SURCHARGE",
                    amount: 1.31,
                    recurrenceUnit: "ONETIME",
                  },
                  {
                    desc: "CO ROAD SAFETY FEE",
                    code: "CRSPF",
                    chargeType: "SURCHARGE",
                    amount: 2.13,
                    recurrenceUnit: "ONETIME",
                  },
                ],
                totalSurcharges: 9.79,
                taxCharges: 6.72,
                addOnCharges: 0,
                ratesApplied: [
                  {
                    freeUnits: 0,
                    type: "HOURLY",
                    amount: 0,
                    rate: 0,
                    name: "Hourly Rate",
                  },
                  {
                    freeUnits: 0,
                    type: "MONTHLY",
                    amount: 0,
                    rate: 0,
                    name: "Monthly Rate",
                  },
                  {
                    freeUnits: 0,
                    type: "DAILY",
                    amount: 1,
                    rate: 24.36,
                    name: "Weekday Rate",
                  },
                  {
                    freeUnits: 0,
                    type: "DAILY",
                    amount: 0,
                    rate: 24.36,
                    name: "Weekend Day Rate",
                  },
                  {
                    freeUnits: 0,
                    type: "WEEKEND",
                    amount: 0,
                    rate: 0,
                    name: "Weekend Rate",
                  },
                  {
                    freeUnits: 0,
                    type: "WEEKLY",
                    amount: 0,
                    rate: 0,
                    name: "Weekly Rate",
                  },
                ],
                type: "PayLater",
                currencyCode: "USD",
                freeMiles: 0,
                extraDayFreeMiles: 0,
                totalFreeMiles: 0,
                extraWeekRate: 0,
                rateVendor: "42080",
                lateCharge: 0,
                ratePlan: {
                  type: "DAILY",
                  rate: 24.36,
                },
                balanceDue: 40.87,
                mileageUnit: "MI",
                totalCharges: 40.87,
                perMileAmount: 0,
                extraDayRate: 24.36,
                taxes: [
                  {
                    taxableAmount: 26.02,
                    type: "PERCENT",
                    percent: 11.11,
                    desc: "DIA CONC. 11.11PCT",
                    amount: 2.89,
                  },
                  {
                    taxableAmount: 28.91,
                    type: "PERCENT",
                    percent: 13.25,
                    desc: "DEN RENTAL TAX13.25PCT",
                    amount: 3.83,
                  },
                ],
                rateId: "10595854589",
                rateCharge: 24.36,
                ratePlusLate: 24.36,
                rentalDays: 1,
              },
            ],
            rateStartDate: "2024-02-20T00:00:00",
            rateEndDate: "2024-02-20T00:00:00",
          },
        ],
        offerStartDate: "2024-02-20T00:00:00",
        offerEndDate: "2024-02-20T00:00:00",
      },
      {
        vehicleClass: [
          {
            transmission: "AUTOMATIC",
            advBookingHours: 15,
            largeLuggageCount: 2,
            smallLuggageCount: 3,
            airConditioning: true,
            tags: ["Luxury", "2/4 door", "Unspecified fuel, A/C", "Auto drive"],
            minimumAge: 18,
            maximumAge: 99,
            classCode: "LCAR",
            classImageURL:
              "https://www.foxrentacar.com/content/dam/fox-rent-a-car/en/vehicles/Corporate/42080-LCAR.png/_jcr_content/renditions/cq5dam.thumbnail.319.319.png",
            displayText: "LCAR - Luxury",
            categoryId: 10008474849,
            group: "CAR_SEDAN",
            classDesc: "Luxury 2/4 Door Automatic With AC",
            modelDesc: "Chrysler 300 or Similar",
            luggageCount: 5,
            passengerCount: 5,
            mpgCity: 19,
            mpgHighway: 31,
          },
        ],
        rateProduct: [
          {
            prepaidAllowed: true,
            debitAllowed: true,
            type: "Retail",
            cardRequired: true,
            pricing: [
              {
                priceCode: "DTS",
                surcharges: [
                  {
                    desc: "Surcharge",
                    code: "SUR",
                    chargeType: "SURCHARGE",
                    amount: 0,
                    recurrenceUnit: "ONETIME",
                  },
                  {
                    desc: "CONSOLIDATED FACILITY",
                    code: "CFC",
                    chargeType: "SURCHARGE",
                    amount: 6,
                    recurrenceUnit: "ONETIME",
                  },
                  {
                    desc: "VEHICLE LICENSE FEE",
                    code: "VLF",
                    chargeType: "SURCHARGE",
                    amount: 0.35,
                    recurrenceUnit: "ONETIME",
                  },
                  {
                    desc: "ENERGY RECOVERY FEE",
                    code: "ERF",
                    chargeType: "SURCHARGE",
                    amount: 1.31,
                    recurrenceUnit: "ONETIME",
                  },
                  {
                    desc: "CO ROAD SAFETY FEE",
                    code: "CRSPF",
                    chargeType: "SURCHARGE",
                    amount: 2.13,
                    recurrenceUnit: "ONETIME",
                  },
                ],
                totalSurcharges: 9.79,
                taxCharges: 6.02,
                addOnCharges: 0,
                ratesApplied: [
                  {
                    freeUnits: 0,
                    type: "HOURLY",
                    amount: 0,
                    rate: 0,
                    name: "Hourly Rate",
                  },
                  {
                    freeUnits: 0,
                    type: "MONTHLY",
                    amount: 0,
                    rate: 0,
                    name: "Monthly Rate",
                  },
                  {
                    freeUnits: 0,
                    type: "DAILY",
                    amount: 1,
                    rate: 21.63,
                    name: "Weekday Rate",
                  },
                  {
                    freeUnits: 0,
                    type: "DAILY",
                    amount: 0,
                    rate: 21.63,
                    name: "Weekend Day Rate",
                  },
                  {
                    freeUnits: 0,
                    type: "WEEKEND",
                    amount: 0,
                    rate: 0,
                    name: "Weekend Rate",
                  },
                  {
                    freeUnits: 0,
                    type: "WEEKLY",
                    amount: 0,
                    rate: 0,
                    name: "Weekly Rate",
                  },
                ],
                type: "PrePaid",
                currencyCode: "USD",
                prepaidBaseDiscount: 15,
                prepaidSavedAmount: 4.8,
                freeMiles: 0,
                extraDayFreeMiles: 0,
                totalFreeMiles: 0,
                extraWeekRate: 0,
                rateVendor: "42080",
                lateCharge: 0,
                ratePlan: {
                  type: "DAILY",
                  rate: 21.63,
                },
                balanceDue: 37.44,
                mileageUnit: "MI",
                totalCharges: 37.44,
                perMileAmount: 0,
                extraDayRate: 21.63,
                taxes: [
                  {
                    taxableAmount: 23.29,
                    type: "PERCENT",
                    percent: 11.11,
                    desc: "DIA CONC. 11.11PCT",
                    amount: 2.59,
                  },
                  {
                    taxableAmount: 25.88,
                    type: "PERCENT",
                    percent: 13.25,
                    desc: "DEN RENTAL TAX13.25PCT",
                    amount: 3.43,
                  },
                ],
                rateId: "10595854592",
                rateCharge: 21.63,
                ratePlusLate: 21.63,
                rentalDays: 1,
              },
              {
                priceCode: "DTS",
                surcharges: [
                  {
                    desc: "Surcharge",
                    code: "SUR",
                    chargeType: "SURCHARGE",
                    amount: 0,
                    recurrenceUnit: "ONETIME",
                  },
                  {
                    desc: "CONSOLIDATED FACILITY",
                    code: "CFC",
                    chargeType: "SURCHARGE",
                    amount: 6,
                    recurrenceUnit: "ONETIME",
                  },
                  {
                    desc: "VEHICLE LICENSE FEE",
                    code: "VLF",
                    chargeType: "SURCHARGE",
                    amount: 0.35,
                    recurrenceUnit: "ONETIME",
                  },
                  {
                    desc: "ENERGY RECOVERY FEE",
                    code: "ERF",
                    chargeType: "SURCHARGE",
                    amount: 1.31,
                    recurrenceUnit: "ONETIME",
                  },
                  {
                    desc: "CO ROAD SAFETY FEE",
                    code: "CRSPF",
                    chargeType: "SURCHARGE",
                    amount: 2.13,
                    recurrenceUnit: "ONETIME",
                  },
                ],
                totalSurcharges: 9.79,
                taxCharges: 7,
                addOnCharges: 0,
                ratesApplied: [
                  {
                    freeUnits: 0,
                    type: "HOURLY",
                    amount: 0,
                    rate: 0,
                    name: "Hourly Rate",
                  },
                  {
                    freeUnits: 0,
                    type: "MONTHLY",
                    amount: 0,
                    rate: 0,
                    name: "Monthly Rate",
                  },
                  {
                    freeUnits: 0,
                    type: "DAILY",
                    amount: 1,
                    rate: 25.45,
                    name: "Weekday Rate",
                  },
                  {
                    freeUnits: 0,
                    type: "DAILY",
                    amount: 0,
                    rate: 25.45,
                    name: "Weekend Day Rate",
                  },
                  {
                    freeUnits: 0,
                    type: "WEEKEND",
                    amount: 0,
                    rate: 0,
                    name: "Weekend Rate",
                  },
                  {
                    freeUnits: 0,
                    type: "WEEKLY",
                    amount: 0,
                    rate: 0,
                    name: "Weekly Rate",
                  },
                ],
                type: "PayLater",
                currencyCode: "USD",
                freeMiles: 0,
                extraDayFreeMiles: 0,
                totalFreeMiles: 0,
                extraWeekRate: 0,
                rateVendor: "42080",
                lateCharge: 0,
                ratePlan: {
                  type: "DAILY",
                  rate: 25.45,
                },
                balanceDue: 42.24,
                mileageUnit: "MI",
                totalCharges: 42.24,
                perMileAmount: 0,
                extraDayRate: 25.45,
                taxes: [
                  {
                    taxableAmount: 27.11,
                    type: "PERCENT",
                    percent: 11.11,
                    desc: "DIA CONC. 11.11PCT",
                    amount: 3.01,
                  },
                  {
                    taxableAmount: 30.12,
                    type: "PERCENT",
                    percent: 13.25,
                    desc: "DEN RENTAL TAX13.25PCT",
                    amount: 3.99,
                  },
                ],
                rateId: "10595854593",
                rateCharge: 25.45,
                ratePlusLate: 25.45,
                rentalDays: 1,
              },
            ],
            rateStartDate: "2024-02-20T00:00:00",
            rateEndDate: "2024-02-20T00:00:00",
          },
        ],
        offerStartDate: "2024-02-20T00:00:00",
        offerEndDate: "2024-02-20T00:00:00",
      },
      {
        vehicleClass: [
          {
            transmission: "AUTOMATIC",
            advBookingHours: 15,
            largeLuggageCount: 2,
            smallLuggageCount: 2,
            airConditioning: true,
            tags: [
              "Premium",
              "2/4 door",
              "Unspecified fuel, A/C",
              "Auto drive",
            ],
            minimumAge: 18,
            maximumAge: 99,
            classCode: "PCAR",
            classImageURL:
              "https://www.foxrentacar.com/content/dam/fox-rent-a-car/en/vehicles/Corporate/42080-PCAR.png/_jcr_content/renditions/cq5dam.thumbnail.319.319.png",
            displayText: "PCAR - Premium",
            categoryId: 10004659194,
            group: "CAR_SEDAN",
            classDesc: "Premium 2/4 Door Automatic With AC",
            modelDesc: "Dodge Charger or Similar",
            luggageCount: 4,
            passengerCount: 5,
            mpgCity: 19,
            mpgHighway: 31,
          },
        ],
        rateProduct: [
          {
            prepaidAllowed: true,
            debitAllowed: true,
            type: "Retail",
            cardRequired: true,
            pricing: [
              {
                priceCode: "DTS",
                surcharges: [
                  {
                    desc: "Surcharge",
                    code: "SUR",
                    chargeType: "SURCHARGE",
                    amount: 0,
                    recurrenceUnit: "ONETIME",
                  },
                  {
                    desc: "CONSOLIDATED FACILITY",
                    code: "CFC",
                    chargeType: "SURCHARGE",
                    amount: 6,
                    recurrenceUnit: "ONETIME",
                  },
                  {
                    desc: "VEHICLE LICENSE FEE",
                    code: "VLF",
                    chargeType: "SURCHARGE",
                    amount: 0.35,
                    recurrenceUnit: "ONETIME",
                  },
                  {
                    desc: "ENERGY RECOVERY FEE",
                    code: "ERF",
                    chargeType: "SURCHARGE",
                    amount: 1.31,
                    recurrenceUnit: "ONETIME",
                  },
                  {
                    desc: "CO ROAD SAFETY FEE",
                    code: "CRSPF",
                    chargeType: "SURCHARGE",
                    amount: 2.13,
                    recurrenceUnit: "ONETIME",
                  },
                ],
                totalSurcharges: 9.79,
                taxCharges: 6.96,
                addOnCharges: 0,
                ratesApplied: [
                  {
                    freeUnits: 0,
                    type: "HOURLY",
                    amount: 0,
                    rate: 0,
                    name: "Hourly Rate",
                  },
                  {
                    freeUnits: 0,
                    type: "MONTHLY",
                    amount: 0,
                    rate: 0,
                    name: "Monthly Rate",
                  },
                  {
                    freeUnits: 0,
                    type: "DAILY",
                    amount: 1,
                    rate: 25.29,
                    name: "Weekday Rate",
                  },
                  {
                    freeUnits: 0,
                    type: "DAILY",
                    amount: 0,
                    rate: 25.29,
                    name: "Weekend Day Rate",
                  },
                  {
                    freeUnits: 0,
                    type: "WEEKEND",
                    amount: 0,
                    rate: 0,
                    name: "Weekend Rate",
                  },
                  {
                    freeUnits: 0,
                    type: "WEEKLY",
                    amount: 0,
                    rate: 0,
                    name: "Weekly Rate",
                  },
                ],
                type: "PrePaid",
                currencyCode: "USD",
                prepaidBaseDiscount: 15,
                prepaidSavedAmount: 5.61,
                freeMiles: 0,
                extraDayFreeMiles: 0,
                totalFreeMiles: 0,
                extraWeekRate: 0,
                rateVendor: "42080",
                lateCharge: 0,
                ratePlan: {
                  type: "DAILY",
                  rate: 25.29,
                },
                balanceDue: 42.04,
                mileageUnit: "MI",
                totalCharges: 42.04,
                perMileAmount: 0,
                extraDayRate: 25.29,
                taxes: [
                  {
                    taxableAmount: 26.95,
                    type: "PERCENT",
                    percent: 11.11,
                    desc: "DIA CONC. 11.11PCT",
                    amount: 2.99,
                  },
                  {
                    taxableAmount: 29.94,
                    type: "PERCENT",
                    percent: 13.25,
                    desc: "DEN RENTAL TAX13.25PCT",
                    amount: 3.97,
                  },
                ],
                rateId: "10595854596",
                rateCharge: 25.29,
                ratePlusLate: 25.29,
                rentalDays: 1,
              },
              {
                priceCode: "DTS",
                surcharges: [
                  {
                    desc: "Surcharge",
                    code: "SUR",
                    chargeType: "SURCHARGE",
                    amount: 0,
                    recurrenceUnit: "ONETIME",
                  },
                  {
                    desc: "CONSOLIDATED FACILITY",
                    code: "CFC",
                    chargeType: "SURCHARGE",
                    amount: 6,
                    recurrenceUnit: "ONETIME",
                  },
                  {
                    desc: "VEHICLE LICENSE FEE",
                    code: "VLF",
                    chargeType: "SURCHARGE",
                    amount: 0.35,
                    recurrenceUnit: "ONETIME",
                  },
                  {
                    desc: "ENERGY RECOVERY FEE",
                    code: "ERF",
                    chargeType: "SURCHARGE",
                    amount: 1.31,
                    recurrenceUnit: "ONETIME",
                  },
                  {
                    desc: "CO ROAD SAFETY FEE",
                    code: "CRSPF",
                    chargeType: "SURCHARGE",
                    amount: 2.13,
                    recurrenceUnit: "ONETIME",
                  },
                ],
                totalSurcharges: 9.79,
                taxCharges: 8.11,
                addOnCharges: 0,
                ratesApplied: [
                  {
                    freeUnits: 0,
                    type: "HOURLY",
                    amount: 0,
                    rate: 0,
                    name: "Hourly Rate",
                  },
                  {
                    freeUnits: 0,
                    type: "MONTHLY",
                    amount: 0,
                    rate: 0,
                    name: "Monthly Rate",
                  },
                  {
                    freeUnits: 0,
                    type: "DAILY",
                    amount: 1,
                    rate: 29.75,
                    name: "Weekday Rate",
                  },
                  {
                    freeUnits: 0,
                    type: "DAILY",
                    amount: 0,
                    rate: 29.75,
                    name: "Weekend Day Rate",
                  },
                  {
                    freeUnits: 0,
                    type: "WEEKEND",
                    amount: 0,
                    rate: 0,
                    name: "Weekend Rate",
                  },
                  {
                    freeUnits: 0,
                    type: "WEEKLY",
                    amount: 0,
                    rate: 0,
                    name: "Weekly Rate",
                  },
                ],
                type: "PayLater",
                currencyCode: "USD",
                freeMiles: 0,
                extraDayFreeMiles: 0,
                totalFreeMiles: 0,
                extraWeekRate: 0,
                rateVendor: "42080",
                lateCharge: 0,
                ratePlan: {
                  type: "DAILY",
                  rate: 29.75,
                },
                balanceDue: 47.65,
                mileageUnit: "MI",
                totalCharges: 47.65,
                perMileAmount: 0,
                extraDayRate: 29.75,
                taxes: [
                  {
                    taxableAmount: 31.41,
                    type: "PERCENT",
                    percent: 11.11,
                    desc: "DIA CONC. 11.11PCT",
                    amount: 3.49,
                  },
                  {
                    taxableAmount: 34.9,
                    type: "PERCENT",
                    percent: 13.25,
                    desc: "DEN RENTAL TAX13.25PCT",
                    amount: 4.62,
                  },
                ],
                rateId: "10595854597",
                rateCharge: 29.75,
                ratePlusLate: 29.75,
                rentalDays: 1,
              },
            ],
            rateStartDate: "2024-02-20T00:00:00",
            rateEndDate: "2024-02-20T00:00:00",
          },
        ],
        offerStartDate: "2024-02-20T00:00:00",
        offerEndDate: "2024-02-20T00:00:00",
      },
      {
        vehicleClass: [
          {
            transmission: "AUTOMATIC",
            advBookingHours: 15,
            largeLuggageCount: 2,
            smallLuggageCount: 3,
            airConditioning: true,
            tags: [
              "Mini",
              "Passenger Van",
              "Unspecified fuel, A/C",
              "Auto drive",
            ],
            minimumAge: 18,
            maximumAge: 99,
            classCode: "MVAR",
            classImageURL:
              "https://www.foxrentacar.com/content/dam/fox-rent-a-car/en/vehicles/Corporate/42080-MVAR.png/_jcr_content/renditions/cq5dam.thumbnail.319.319.png",
            displayText: "MVAR - Mini",
            categoryId: 10008471387,
            group: "VAN",
            classDesc: "Mini Pass Van Automatic With AC",
            modelDesc: "Chrysler Pacifica or Similar",
            luggageCount: 5,
            passengerCount: 7,
            mpgCity: 17,
            mpgHighway: 25,
          },
        ],
        rateProduct: [
          {
            prepaidAllowed: true,
            debitAllowed: true,
            type: "Retail",
            cardRequired: true,
            pricing: [
              {
                priceCode: "DTS",
                surcharges: [
                  {
                    desc: "Surcharge",
                    code: "SUR",
                    chargeType: "SURCHARGE",
                    amount: 0,
                    recurrenceUnit: "ONETIME",
                  },
                  {
                    desc: "CONSOLIDATED FACILITY",
                    code: "CFC",
                    chargeType: "SURCHARGE",
                    amount: 6,
                    recurrenceUnit: "ONETIME",
                  },
                  {
                    desc: "VEHICLE LICENSE FEE",
                    code: "VLF",
                    chargeType: "SURCHARGE",
                    amount: 0.35,
                    recurrenceUnit: "ONETIME",
                  },
                  {
                    desc: "ENERGY RECOVERY FEE",
                    code: "ERF",
                    chargeType: "SURCHARGE",
                    amount: 1.31,
                    recurrenceUnit: "ONETIME",
                  },
                  {
                    desc: "CO ROAD SAFETY FEE",
                    code: "CRSPF",
                    chargeType: "SURCHARGE",
                    amount: 2.13,
                    recurrenceUnit: "ONETIME",
                  },
                ],
                totalSurcharges: 9.79,
                taxCharges: 8.88,
                addOnCharges: 0,
                ratesApplied: [
                  {
                    freeUnits: 0,
                    type: "HOURLY",
                    amount: 0,
                    rate: 0,
                    name: "Hourly Rate",
                  },
                  {
                    freeUnits: 0,
                    type: "MONTHLY",
                    amount: 0,
                    rate: 0,
                    name: "Monthly Rate",
                  },
                  {
                    freeUnits: 0,
                    type: "DAILY",
                    amount: 1,
                    rate: 32.73,
                    name: "Weekday Rate",
                  },
                  {
                    freeUnits: 0,
                    type: "DAILY",
                    amount: 0,
                    rate: 32.73,
                    name: "Weekend Day Rate",
                  },
                  {
                    freeUnits: 0,
                    type: "WEEKEND",
                    amount: 0,
                    rate: 0,
                    name: "Weekend Rate",
                  },
                  {
                    freeUnits: 0,
                    type: "WEEKLY",
                    amount: 0,
                    rate: 0,
                    name: "Weekly Rate",
                  },
                ],
                type: "PrePaid",
                currencyCode: "USD",
                prepaidBaseDiscount: 15,
                prepaidSavedAmount: 7.26,
                freeMiles: 0,
                extraDayFreeMiles: 0,
                totalFreeMiles: 0,
                extraWeekRate: 0,
                rateVendor: "42080",
                lateCharge: 0,
                ratePlan: {
                  type: "DAILY",
                  rate: 32.73,
                },
                balanceDue: 51.4,
                mileageUnit: "MI",
                totalCharges: 51.4,
                perMileAmount: 0,
                extraDayRate: 32.73,
                taxes: [
                  {
                    taxableAmount: 34.39,
                    type: "PERCENT",
                    percent: 11.11,
                    desc: "DIA CONC. 11.11PCT",
                    amount: 3.82,
                  },
                  {
                    taxableAmount: 38.21,
                    type: "PERCENT",
                    percent: 13.25,
                    desc: "DEN RENTAL TAX13.25PCT",
                    amount: 5.06,
                  },
                ],
                rateId: "10595854598",
                rateCharge: 32.73,
                ratePlusLate: 32.73,
                rentalDays: 1,
              },
              {
                priceCode: "DTS",
                surcharges: [
                  {
                    desc: "Surcharge",
                    code: "SUR",
                    chargeType: "SURCHARGE",
                    amount: 0,
                    recurrenceUnit: "ONETIME",
                  },
                  {
                    desc: "CONSOLIDATED FACILITY",
                    code: "CFC",
                    chargeType: "SURCHARGE",
                    amount: 6,
                    recurrenceUnit: "ONETIME",
                  },
                  {
                    desc: "VEHICLE LICENSE FEE",
                    code: "VLF",
                    chargeType: "SURCHARGE",
                    amount: 0.35,
                    recurrenceUnit: "ONETIME",
                  },
                  {
                    desc: "ENERGY RECOVERY FEE",
                    code: "ERF",
                    chargeType: "SURCHARGE",
                    amount: 1.31,
                    recurrenceUnit: "ONETIME",
                  },
                  {
                    desc: "CO ROAD SAFETY FEE",
                    code: "CRSPF",
                    chargeType: "SURCHARGE",
                    amount: 2.13,
                    recurrenceUnit: "ONETIME",
                  },
                ],
                totalSurcharges: 9.79,
                taxCharges: 10.37,
                addOnCharges: 0,
                ratesApplied: [
                  {
                    freeUnits: 0,
                    type: "HOURLY",
                    amount: 0,
                    rate: 0,
                    name: "Hourly Rate",
                  },
                  {
                    freeUnits: 0,
                    type: "MONTHLY",
                    amount: 0,
                    rate: 0,
                    name: "Monthly Rate",
                  },
                  {
                    freeUnits: 0,
                    type: "DAILY",
                    amount: 1,
                    rate: 38.5,
                    name: "Weekday Rate",
                  },
                  {
                    freeUnits: 0,
                    type: "DAILY",
                    amount: 0,
                    rate: 38.5,
                    name: "Weekend Day Rate",
                  },
                  {
                    freeUnits: 0,
                    type: "WEEKEND",
                    amount: 0,
                    rate: 0,
                    name: "Weekend Rate",
                  },
                  {
                    freeUnits: 0,
                    type: "WEEKLY",
                    amount: 0,
                    rate: 0,
                    name: "Weekly Rate",
                  },
                ],
                type: "PayLater",
                currencyCode: "USD",
                freeMiles: 0,
                extraDayFreeMiles: 0,
                totalFreeMiles: 0,
                extraWeekRate: 0,
                rateVendor: "42080",
                lateCharge: 0,
                ratePlan: {
                  type: "DAILY",
                  rate: 38.5,
                },
                balanceDue: 58.66,
                mileageUnit: "MI",
                totalCharges: 58.66,
                perMileAmount: 0,
                extraDayRate: 38.5,
                taxes: [
                  {
                    taxableAmount: 40.16,
                    type: "PERCENT",
                    percent: 11.11,
                    desc: "DIA CONC. 11.11PCT",
                    amount: 4.46,
                  },
                  {
                    taxableAmount: 44.62,
                    type: "PERCENT",
                    percent: 13.25,
                    desc: "DEN RENTAL TAX13.25PCT",
                    amount: 5.91,
                  },
                ],
                rateId: "10595854599",
                rateCharge: 38.5,
                ratePlusLate: 38.5,
                rentalDays: 1,
              },
            ],
            rateStartDate: "2024-02-20T00:00:00",
            rateEndDate: "2024-02-20T00:00:00",
          },
        ],
        offerStartDate: "2024-02-20T00:00:00",
        offerEndDate: "2024-02-20T00:00:00",
      },
      {
        vehicleClass: [
          {
            transmission: "AUTOMATIC",
            advBookingHours: 15,
            largeLuggageCount: 2,
            smallLuggageCount: 3,
            airConditioning: true,
            tags: ["Standard", "SUV", "Unspecified fuel, A/C", "Auto drive"],
            minimumAge: 18,
            maximumAge: 99,
            classCode: "SFAR",
            classImageURL:
              "https://www.foxrentacar.com/content/dam/fox-rent-a-car/en/vehicles/Corporate/42080-SFAR.png/_jcr_content/renditions/cq5dam.thumbnail.319.319.png",
            displayText: "SFAR - Standard",
            categoryId: 10004659195,
            group: "SUV",
            classDesc: "Standard SUV Automatic With AC",
            modelDesc: "Hyundai Santa Fe or Similar",
            luggageCount: 5,
            passengerCount: 5,
            mpgCity: 18,
            mpgHighway: 25,
          },
        ],
        rateProduct: [
          {
            prepaidAllowed: true,
            debitAllowed: true,
            type: "Retail",
            cardRequired: true,
            pricing: [
              {
                priceCode: "DTS",
                surcharges: [
                  {
                    desc: "Surcharge",
                    code: "SUR",
                    chargeType: "SURCHARGE",
                    amount: 0,
                    recurrenceUnit: "ONETIME",
                  },
                  {
                    desc: "CONSOLIDATED FACILITY",
                    code: "CFC",
                    chargeType: "SURCHARGE",
                    amount: 6,
                    recurrenceUnit: "ONETIME",
                  },
                  {
                    desc: "VEHICLE LICENSE FEE",
                    code: "VLF",
                    chargeType: "SURCHARGE",
                    amount: 0.35,
                    recurrenceUnit: "ONETIME",
                  },
                  {
                    desc: "ENERGY RECOVERY FEE",
                    code: "ERF",
                    chargeType: "SURCHARGE",
                    amount: 1.31,
                    recurrenceUnit: "ONETIME",
                  },
                  {
                    desc: "CO ROAD SAFETY FEE",
                    code: "CRSPF",
                    chargeType: "SURCHARGE",
                    amount: 2.13,
                    recurrenceUnit: "ONETIME",
                  },
                ],
                totalSurcharges: 9.79,
                taxCharges: 9,
                addOnCharges: 0,
                ratesApplied: [
                  {
                    freeUnits: 0,
                    type: "HOURLY",
                    amount: 0,
                    rate: 0,
                    name: "Hourly Rate",
                  },
                  {
                    freeUnits: 0,
                    type: "MONTHLY",
                    amount: 0,
                    rate: 0,
                    name: "Monthly Rate",
                  },
                  {
                    freeUnits: 0,
                    type: "DAILY",
                    amount: 1,
                    rate: 33.2,
                    name: "Weekday Rate",
                  },
                  {
                    freeUnits: 0,
                    type: "DAILY",
                    amount: 0,
                    rate: 33.2,
                    name: "Weekend Day Rate",
                  },
                  {
                    freeUnits: 0,
                    type: "WEEKEND",
                    amount: 0,
                    rate: 0,
                    name: "Weekend Rate",
                  },
                  {
                    freeUnits: 0,
                    type: "WEEKLY",
                    amount: 0,
                    rate: 0,
                    name: "Weekly Rate",
                  },
                ],
                type: "PrePaid",
                currencyCode: "USD",
                prepaidBaseDiscount: 15,
                prepaidSavedAmount: 7.37,
                freeMiles: 0,
                extraDayFreeMiles: 0,
                totalFreeMiles: 0,
                extraWeekRate: 0,
                rateVendor: "42080",
                lateCharge: 0,
                ratePlan: {
                  type: "DAILY",
                  rate: 33.2,
                },
                balanceDue: 51.99,
                mileageUnit: "MI",
                totalCharges: 51.99,
                perMileAmount: 0,
                extraDayRate: 33.2,
                taxes: [
                  {
                    taxableAmount: 34.86,
                    type: "PERCENT",
                    percent: 11.11,
                    desc: "DIA CONC. 11.11PCT",
                    amount: 3.87,
                  },
                  {
                    taxableAmount: 38.73,
                    type: "PERCENT",
                    percent: 13.25,
                    desc: "DEN RENTAL TAX13.25PCT",
                    amount: 5.13,
                  },
                ],
                rateId: "10595854600",
                rateCharge: 33.2,
                ratePlusLate: 33.2,
                rentalDays: 1,
              },
              {
                priceCode: "DTS",
                surcharges: [
                  {
                    desc: "Surcharge",
                    code: "SUR",
                    chargeType: "SURCHARGE",
                    amount: 0,
                    recurrenceUnit: "ONETIME",
                  },
                  {
                    desc: "CONSOLIDATED FACILITY",
                    code: "CFC",
                    chargeType: "SURCHARGE",
                    amount: 6,
                    recurrenceUnit: "ONETIME",
                  },
                  {
                    desc: "VEHICLE LICENSE FEE",
                    code: "VLF",
                    chargeType: "SURCHARGE",
                    amount: 0.35,
                    recurrenceUnit: "ONETIME",
                  },
                  {
                    desc: "ENERGY RECOVERY FEE",
                    code: "ERF",
                    chargeType: "SURCHARGE",
                    amount: 1.31,
                    recurrenceUnit: "ONETIME",
                  },
                  {
                    desc: "CO ROAD SAFETY FEE",
                    code: "CRSPF",
                    chargeType: "SURCHARGE",
                    amount: 2.13,
                    recurrenceUnit: "ONETIME",
                  },
                ],
                totalSurcharges: 9.79,
                taxCharges: 10.51,
                addOnCharges: 0,
                ratesApplied: [
                  {
                    freeUnits: 0,
                    type: "HOURLY",
                    amount: 0,
                    rate: 0,
                    name: "Hourly Rate",
                  },
                  {
                    freeUnits: 0,
                    type: "MONTHLY",
                    amount: 0,
                    rate: 0,
                    name: "Monthly Rate",
                  },
                  {
                    freeUnits: 0,
                    type: "DAILY",
                    amount: 1,
                    rate: 39.06,
                    name: "Weekday Rate",
                  },
                  {
                    freeUnits: 0,
                    type: "DAILY",
                    amount: 0,
                    rate: 39.06,
                    name: "Weekend Day Rate",
                  },
                  {
                    freeUnits: 0,
                    type: "WEEKEND",
                    amount: 0,
                    rate: 0,
                    name: "Weekend Rate",
                  },
                  {
                    freeUnits: 0,
                    type: "WEEKLY",
                    amount: 0,
                    rate: 0,
                    name: "Weekly Rate",
                  },
                ],
                type: "PayLater",
                currencyCode: "USD",
                freeMiles: 0,
                extraDayFreeMiles: 0,
                totalFreeMiles: 0,
                extraWeekRate: 0,
                rateVendor: "42080",
                lateCharge: 0,
                ratePlan: {
                  type: "DAILY",
                  rate: 39.06,
                },
                balanceDue: 59.36,
                mileageUnit: "MI",
                totalCharges: 59.36,
                perMileAmount: 0,
                extraDayRate: 39.06,
                taxes: [
                  {
                    taxableAmount: 40.72,
                    type: "PERCENT",
                    percent: 11.11,
                    desc: "DIA CONC. 11.11PCT",
                    amount: 4.52,
                  },
                  {
                    taxableAmount: 45.24,
                    type: "PERCENT",
                    percent: 13.25,
                    desc: "DEN RENTAL TAX13.25PCT",
                    amount: 5.99,
                  },
                ],
                rateId: "10595854601",
                rateCharge: 39.06,
                ratePlusLate: 39.06,
                rentalDays: 1,
              },
            ],
            rateStartDate: "2024-02-20T00:00:00",
            rateEndDate: "2024-02-20T00:00:00",
          },
        ],
        offerStartDate: "2024-02-20T00:00:00",
        offerEndDate: "2024-02-20T00:00:00",
      },
      {
        vehicleClass: [
          {
            transmission: "AUTOMATIC",
            advBookingHours: 15,
            largeLuggageCount: 1,
            smallLuggageCount: 1,
            airConditioning: true,
            tags: ["Fullsize", "SUV", "Unspecified fuel, A/C", "Auto drive"],
            minimumAge: 18,
            maximumAge: 99,
            classCode: "FFAR",
            classImageURL:
              "https://www.foxrentacar.com/content/dam/fox-rent-a-car/en/vehicles/Corporate/42080-FFAR.png/_jcr_content/renditions/cq5dam.thumbnail.319.319.png",
            displayText: "FFAR - Fullsize",
            categoryId: 10004659204,
            group: "SUV",
            classDesc: "Fullsize SUV Automatic With AC",
            modelDesc: "Toyota 4Runner or Similar",
            luggageCount: 2,
            passengerCount: 5,
            mpgCity: 16,
            mpgHighway: 19,
          },
        ],
        rateProduct: [
          {
            prepaidAllowed: true,
            debitAllowed: true,
            type: "Retail",
            cardRequired: true,
            pricing: [
              {
                priceCode: "DTS",
                surcharges: [
                  {
                    desc: "Surcharge",
                    code: "SUR",
                    chargeType: "SURCHARGE",
                    amount: 0,
                    recurrenceUnit: "ONETIME",
                  },
                  {
                    desc: "CONSOLIDATED FACILITY",
                    code: "CFC",
                    chargeType: "SURCHARGE",
                    amount: 6,
                    recurrenceUnit: "ONETIME",
                  },
                  {
                    desc: "VEHICLE LICENSE FEE",
                    code: "VLF",
                    chargeType: "SURCHARGE",
                    amount: 0.35,
                    recurrenceUnit: "ONETIME",
                  },
                  {
                    desc: "ENERGY RECOVERY FEE",
                    code: "ERF",
                    chargeType: "SURCHARGE",
                    amount: 1.31,
                    recurrenceUnit: "ONETIME",
                  },
                  {
                    desc: "CO ROAD SAFETY FEE",
                    code: "CRSPF",
                    chargeType: "SURCHARGE",
                    amount: 2.13,
                    recurrenceUnit: "ONETIME",
                  },
                ],
                totalSurcharges: 9.79,
                taxCharges: 10.84,
                addOnCharges: 0,
                ratesApplied: [
                  {
                    freeUnits: 0,
                    type: "HOURLY",
                    amount: 0,
                    rate: 0,
                    name: "Hourly Rate",
                  },
                  {
                    freeUnits: 0,
                    type: "MONTHLY",
                    amount: 0,
                    rate: 0,
                    name: "Monthly Rate",
                  },
                  {
                    freeUnits: 0,
                    type: "DAILY",
                    amount: 1,
                    rate: 40.32,
                    name: "Weekday Rate",
                  },
                  {
                    freeUnits: 0,
                    type: "DAILY",
                    amount: 0,
                    rate: 40.32,
                    name: "Weekend Day Rate",
                  },
                  {
                    freeUnits: 0,
                    type: "WEEKEND",
                    amount: 0,
                    rate: 0,
                    name: "Weekend Rate",
                  },
                  {
                    freeUnits: 0,
                    type: "WEEKLY",
                    amount: 0,
                    rate: 0,
                    name: "Weekly Rate",
                  },
                ],
                type: "PrePaid",
                currencyCode: "USD",
                prepaidBaseDiscount: 15,
                prepaidSavedAmount: 8.95,
                freeMiles: 0,
                extraDayFreeMiles: 0,
                totalFreeMiles: 0,
                extraWeekRate: 0,
                rateVendor: "42080",
                lateCharge: 0,
                ratePlan: {
                  type: "DAILY",
                  rate: 40.32,
                },
                balanceDue: 60.95,
                mileageUnit: "MI",
                totalCharges: 60.95,
                perMileAmount: 0,
                extraDayRate: 40.32,
                taxes: [
                  {
                    taxableAmount: 41.98,
                    type: "PERCENT",
                    percent: 11.11,
                    desc: "DIA CONC. 11.11PCT",
                    amount: 4.66,
                  },
                  {
                    taxableAmount: 46.64,
                    type: "PERCENT",
                    percent: 13.25,
                    desc: "DEN RENTAL TAX13.25PCT",
                    amount: 6.18,
                  },
                ],
                rateId: "10595854602",
                rateCharge: 40.32,
                ratePlusLate: 40.32,
                rentalDays: 1,
              },
              {
                priceCode: "DTS",
                surcharges: [
                  {
                    desc: "Surcharge",
                    code: "SUR",
                    chargeType: "SURCHARGE",
                    amount: 0,
                    recurrenceUnit: "ONETIME",
                  },
                  {
                    desc: "CONSOLIDATED FACILITY",
                    code: "CFC",
                    chargeType: "SURCHARGE",
                    amount: 6,
                    recurrenceUnit: "ONETIME",
                  },
                  {
                    desc: "VEHICLE LICENSE FEE",
                    code: "VLF",
                    chargeType: "SURCHARGE",
                    amount: 0.35,
                    recurrenceUnit: "ONETIME",
                  },
                  {
                    desc: "ENERGY RECOVERY FEE",
                    code: "ERF",
                    chargeType: "SURCHARGE",
                    amount: 1.31,
                    recurrenceUnit: "ONETIME",
                  },
                  {
                    desc: "CO ROAD SAFETY FEE",
                    code: "CRSPF",
                    chargeType: "SURCHARGE",
                    amount: 2.13,
                    recurrenceUnit: "ONETIME",
                  },
                ],
                totalSurcharges: 9.79,
                taxCharges: 12.68,
                addOnCharges: 0,
                ratesApplied: [
                  {
                    freeUnits: 0,
                    type: "HOURLY",
                    amount: 0,
                    rate: 0,
                    name: "Hourly Rate",
                  },
                  {
                    freeUnits: 0,
                    type: "MONTHLY",
                    amount: 0,
                    rate: 0,
                    name: "Monthly Rate",
                  },
                  {
                    freeUnits: 0,
                    type: "DAILY",
                    amount: 1,
                    rate: 47.43,
                    name: "Weekday Rate",
                  },
                  {
                    freeUnits: 0,
                    type: "DAILY",
                    amount: 0,
                    rate: 47.43,
                    name: "Weekend Day Rate",
                  },
                  {
                    freeUnits: 0,
                    type: "WEEKEND",
                    amount: 0,
                    rate: 0,
                    name: "Weekend Rate",
                  },
                  {
                    freeUnits: 0,
                    type: "WEEKLY",
                    amount: 0,
                    rate: 0,
                    name: "Weekly Rate",
                  },
                ],
                type: "PayLater",
                currencyCode: "USD",
                freeMiles: 0,
                extraDayFreeMiles: 0,
                totalFreeMiles: 0,
                extraWeekRate: 0,
                rateVendor: "42080",
                lateCharge: 0,
                ratePlan: {
                  type: "DAILY",
                  rate: 47.43,
                },
                balanceDue: 69.9,
                mileageUnit: "MI",
                totalCharges: 69.9,
                perMileAmount: 0,
                extraDayRate: 47.43,
                taxes: [
                  {
                    taxableAmount: 49.09,
                    type: "PERCENT",
                    percent: 11.11,
                    desc: "DIA CONC. 11.11PCT",
                    amount: 5.45,
                  },
                  {
                    taxableAmount: 54.54,
                    type: "PERCENT",
                    percent: 13.25,
                    desc: "DEN RENTAL TAX13.25PCT",
                    amount: 7.23,
                  },
                ],
                rateId: "10595854603",
                rateCharge: 47.43,
                ratePlusLate: 47.43,
                rentalDays: 1,
              },
            ],
            rateStartDate: "2024-02-20T00:00:00",
            rateEndDate: "2024-02-20T00:00:00",
          },
        ],
        offerStartDate: "2024-02-20T00:00:00",
        offerEndDate: "2024-02-20T00:00:00",
      },
      {
        vehicleClass: [
          {
            transmission: "AUTOMATIC",
            advBookingHours: 15,
            largeLuggageCount: 2,
            smallLuggageCount: 3,
            airConditioning: true,
            tags: ["Premium", "SUV", "Unspecified fuel, A/C", "Auto drive"],
            minimumAge: 18,
            maximumAge: 99,
            classCode: "PFAR",
            classImageURL:
              "https://www.foxrentacar.com/content/dam/fox-rent-a-car/en/vehicles/Corporate/42080-PFAR.png/_jcr_content/renditions/cq5dam.thumbnail.319.319.png",
            displayText: "PFAR - Premium",
            categoryId: 10012904611,
            group: "SUV",
            classDesc: "Premium SUV Automatic With AC",
            modelDesc: "Ford Expedition or Similar",
            luggageCount: 5,
            passengerCount: 8,
            mpgCity: 15,
            mpgHighway: 21,
          },
        ],
        rateProduct: [
          {
            prepaidAllowed: true,
            debitAllowed: true,
            type: "Retail",
            cardRequired: true,
            pricing: [
              {
                priceCode: "DTS",
                surcharges: [
                  {
                    desc: "Surcharge",
                    code: "SUR",
                    chargeType: "SURCHARGE",
                    amount: 0,
                    recurrenceUnit: "ONETIME",
                  },
                  {
                    desc: "CONSOLIDATED FACILITY",
                    code: "CFC",
                    chargeType: "SURCHARGE",
                    amount: 6,
                    recurrenceUnit: "ONETIME",
                  },
                  {
                    desc: "VEHICLE LICENSE FEE",
                    code: "VLF",
                    chargeType: "SURCHARGE",
                    amount: 0.35,
                    recurrenceUnit: "ONETIME",
                  },
                  {
                    desc: "ENERGY RECOVERY FEE",
                    code: "ERF",
                    chargeType: "SURCHARGE",
                    amount: 1.31,
                    recurrenceUnit: "ONETIME",
                  },
                  {
                    desc: "CO ROAD SAFETY FEE",
                    code: "CRSPF",
                    chargeType: "SURCHARGE",
                    amount: 2.13,
                    recurrenceUnit: "ONETIME",
                  },
                ],
                totalSurcharges: 9.79,
                taxCharges: 16.05,
                addOnCharges: 0,
                ratesApplied: [
                  {
                    freeUnits: 0,
                    type: "HOURLY",
                    amount: 0,
                    rate: 0,
                    name: "Hourly Rate",
                  },
                  {
                    freeUnits: 0,
                    type: "MONTHLY",
                    amount: 0,
                    rate: 0,
                    name: "Monthly Rate",
                  },
                  {
                    freeUnits: 0,
                    type: "DAILY",
                    amount: 1,
                    rate: 60.48,
                    name: "Weekday Rate",
                  },
                  {
                    freeUnits: 0,
                    type: "DAILY",
                    amount: 0,
                    rate: 60.48,
                    name: "Weekend Day Rate",
                  },
                  {
                    freeUnits: 0,
                    type: "WEEKEND",
                    amount: 0,
                    rate: 0,
                    name: "Weekend Rate",
                  },
                  {
                    freeUnits: 0,
                    type: "WEEKLY",
                    amount: 0,
                    rate: 0,
                    name: "Weekly Rate",
                  },
                ],
                type: "PrePaid",
                currencyCode: "USD",
                prepaidBaseDiscount: 15,
                prepaidSavedAmount: 13.43,
                freeMiles: 0,
                extraDayFreeMiles: 0,
                totalFreeMiles: 0,
                extraWeekRate: 0,
                rateVendor: "42080",
                lateCharge: 0,
                ratePlan: {
                  type: "DAILY",
                  rate: 60.48,
                },
                balanceDue: 86.32,
                mileageUnit: "MI",
                totalCharges: 86.32,
                perMileAmount: 0,
                extraDayRate: 60.48,
                taxes: [
                  {
                    taxableAmount: 62.14,
                    type: "PERCENT",
                    percent: 11.11,
                    desc: "DIA CONC. 11.11PCT",
                    amount: 6.9,
                  },
                  {
                    taxableAmount: 69.04,
                    type: "PERCENT",
                    percent: 13.25,
                    desc: "DEN RENTAL TAX13.25PCT",
                    amount: 9.15,
                  },
                ],
                rateId: "10595854604",
                rateCharge: 60.48,
                ratePlusLate: 60.48,
                rentalDays: 1,
              },
              {
                priceCode: "DTS",
                surcharges: [
                  {
                    desc: "Surcharge",
                    code: "SUR",
                    chargeType: "SURCHARGE",
                    amount: 0,
                    recurrenceUnit: "ONETIME",
                  },
                  {
                    desc: "CONSOLIDATED FACILITY",
                    code: "CFC",
                    chargeType: "SURCHARGE",
                    amount: 6,
                    recurrenceUnit: "ONETIME",
                  },
                  {
                    desc: "VEHICLE LICENSE FEE",
                    code: "VLF",
                    chargeType: "SURCHARGE",
                    amount: 0.35,
                    recurrenceUnit: "ONETIME",
                  },
                  {
                    desc: "ENERGY RECOVERY FEE",
                    code: "ERF",
                    chargeType: "SURCHARGE",
                    amount: 1.31,
                    recurrenceUnit: "ONETIME",
                  },
                  {
                    desc: "CO ROAD SAFETY FEE",
                    code: "CRSPF",
                    chargeType: "SURCHARGE",
                    amount: 2.13,
                    recurrenceUnit: "ONETIME",
                  },
                ],
                totalSurcharges: 9.79,
                taxCharges: 18.81,
                addOnCharges: 0,
                ratesApplied: [
                  {
                    freeUnits: 0,
                    type: "HOURLY",
                    amount: 0,
                    rate: 0,
                    name: "Hourly Rate",
                  },
                  {
                    freeUnits: 0,
                    type: "MONTHLY",
                    amount: 0,
                    rate: 0,
                    name: "Monthly Rate",
                  },
                  {
                    freeUnits: 0,
                    type: "DAILY",
                    amount: 1,
                    rate: 71.15,
                    name: "Weekday Rate",
                  },
                  {
                    freeUnits: 0,
                    type: "DAILY",
                    amount: 0,
                    rate: 71.15,
                    name: "Weekend Day Rate",
                  },
                  {
                    freeUnits: 0,
                    type: "WEEKEND",
                    amount: 0,
                    rate: 0,
                    name: "Weekend Rate",
                  },
                  {
                    freeUnits: 0,
                    type: "WEEKLY",
                    amount: 0,
                    rate: 0,
                    name: "Weekly Rate",
                  },
                ],
                type: "PayLater",
                currencyCode: "USD",
                freeMiles: 0,
                extraDayFreeMiles: 0,
                totalFreeMiles: 0,
                extraWeekRate: 0,
                rateVendor: "42080",
                lateCharge: 0,
                ratePlan: {
                  type: "DAILY",
                  rate: 71.15,
                },
                balanceDue: 99.75,
                mileageUnit: "MI",
                totalCharges: 99.75,
                perMileAmount: 0,
                extraDayRate: 71.15,
                taxes: [
                  {
                    taxableAmount: 72.81,
                    type: "PERCENT",
                    percent: 11.11,
                    desc: "DIA CONC. 11.11PCT",
                    amount: 8.09,
                  },
                  {
                    taxableAmount: 80.9,
                    type: "PERCENT",
                    percent: 13.25,
                    desc: "DEN RENTAL TAX13.25PCT",
                    amount: 10.72,
                  },
                ],
                rateId: "10595854605",
                rateCharge: 71.15,
                ratePlusLate: 71.15,
                rentalDays: 1,
              },
            ],
            rateStartDate: "2024-02-20T00:00:00",
            rateEndDate: "2024-02-20T00:00:00",
          },
        ],
        offerStartDate: "2024-02-20T00:00:00",
        offerEndDate: "2024-02-20T00:00:00",
      },
    ],
    message: {
      messageCode: 14000,
      messageType: "SUCCESS",
      displayMessage: "Get rates success",
      date: "2024-02-14T07:22:00",
      requestId: "31c311ab-e45e-45e0-b565-36bd0ee91a88",
      partnerCode: "FOXADMIN",
      additionalMessage: "Get Rates on: 2024-02-14T07:22:00",
    },
  },
  foxWizardInfo: {
    executionBlock: null,
    prevBlock: null,
    nextBlock: null,
    currentBlock: null,
    iskeepChanged: false,
    wizardMsg: null,
    archiveData: null,
    isError: false,
  },
  isPayLater: true,
  reservationMode: "NEW",
  editMode: false,
  breadCrumbList: null,
  reservationInfo: {
    reservationCode: null,
    externalCode: null,
  },
  isBlocking: true,
  reservationResponse: null,
  modalInfo: [
    {
      type: null,
      singleModalIdentifierId: null,
      customData: null,
      showModal: null,
      invoiceId: null,
      memberId: null,
      vehicleId: null,
      reservationId: null,
      locationId: null,
      activityId: null,
      cancellationData: null,
      notesOrMailType: null,
      agentResponse: null,
      agentType: null,
    },
  ],
  newReservationMemberId: null,
  isAutoKeepChanged: null,
};

export const foxReservationDummyResponse = {
  reservation: {
    provider: {
      URL: "https://websolutions.tsddemo.com/requests/service.svc/",
      password: "42080",
      userName: "42080",
      integProvId: 10008729759,
      displayOrder: 1,
      apiLicenseId: 10008729751,
      deploymentMode: "TEST",
      providerStatus: "ACTIVE",
      name: "TSD",
    },
    source: "ADMIN_WEB",
    integConfirmNumber: "WFX06A28A2",
    reservationState: "CONFIRMED",
    returnDateTime: "2024-04-06T10:30:00",
    confirmationCode: "FBCK-LAX-XDPKNX",
    reservationStatus: "CONFIRMED",
    freeCreditApplied: 0,
    reservationCode: "WFX06A28A2",
    rentalLocation: {
      extendedLocationCode: "LAXO01",
      phoneNumbers: [
        {
          country: "US",
          type: "Business",
          countryCode: 1,
          number: "8002254369",
        },
      ],
      providerName: "TSD",
      locationCode: "LAX",
      address: {
        street: "5500 W CENTURY BLVD",
        provinceCode: "CA",
        country: "US",
        street2: "1123123",
        province: "CALIFORNIA",
        city: "LOS ANGELES",
        postalCode: "90045",
      },
      locationId: 10002624327,
      locationType: "CORPORATE",
      xpressEnabled: false,
      name: "LOS ANGELES AIRPORT/LAX",
    },
    vehicleClass: {
      transmission: "AUTOMATIC",
      advBookingHours: 15,
      largeLuggageCount: 2,
      smallLuggageCount: 2,
      airConditioning: true,
      group: "CAR_SEDAN",
      classDesc: "Fullsize 2/4 Door Automatic With AC",
      tags: ["Fullsize", "2/4 door", "Unspecified fuel, A/C", "Auto drive"],
      classCode: "FCAR",
      displayText: "FCAR - Fullsize",
      categoryId: 10004657190,
      modelDesc: "Toyota Camry or Similar",
      classImageURL:
        "https://www.foxrentacar.com/content/dam/fox-rent-a-car/en/vehicles/Corporate/42080-FCAR.png/_jcr_content/renditions/cq5dam.thumbnail.319.319.png",
      luggageCount: 4,
      passengerCount: 5,
      mpgCity: 25,
      mpgHighway: 35,
    },
    rentalDateTime: "2024-02-21T09:30:00",
    paymentOnCreation: "POSTPAID",
    returnLocation: {
      extendedLocationCode: "LAXO01",
      phoneNumbers: [
        {
          country: "US",
          type: "Business",
          countryCode: 1,
          number: "8002254369",
        },
      ],
      providerName: "TSD",
      locationCode: "LAX",
      address: {
        street: "5500 W CENTURY BLVD",
        provinceCode: "CA",
        country: "US",
        street2: "1123123",
        province: "CALIFORNIA",
        city: "LOS ANGELES",
        postalCode: "90045",
      },
      locationId: 10002624327,
      locationType: "CORPORATE",
      xpressEnabled: false,
      name: "LOS ANGELES AIRPORT/LAX",
    },
    createdBy: "Pritam Reserve Sync",
    createdMethod: "Partner Web",
    completedOn: "",
    reservationId: 10368927138,
    reservationCreationType: "REGULAR",
    pricing: {
      priceCode: "STD14",
      totalSurcharges: 97.9,
      taxCharges: 1227.45,
      addOnCharges: 5109.99,
      ratesApplied: [
        {
          freeUnits: 0,
          type: "HOURLY",
          rate: 5.86,
          amount: 1,
          name: "Hourly Rate",
        },
        {
          freeUnits: 0,
          type: "MONTHLY",
          rate: 655.2,
          amount: 1,
          name: "Monthly Rate",
        },
        {
          freeUnits: 0,
          type: "DAILY",
          rate: 17.48,
          amount: 3,
          name: "Weekday Rate",
        },
        {
          freeUnits: 0,
          type: "DAILY",
          rate: 17.48,
          amount: 0,
          name: "Weekend Day Rate",
        },
        {
          freeUnits: 0,
          type: "WEEKEND",
          rate: 0,
          amount: 0,
          name: "Weekend Rate",
        },
        {
          freeUnits: 0,
          type: "WEEKLY",
          rate: 150.7,
          amount: 2,
          name: "Weekly Rate",
        },
      ],
      totalPaymentsAndCredits: 0,
      surcharges: [
        {
          desc: "CONSOLIDATED FACILITY",
          chargeType: "SURCHARGE",
          code: "CFC",
          amount: 45,
          recurrenceUnit: "ONETIME",
        },
        {
          desc: "Surcharge",
          chargeType: "SURCHARGE",
          code: "SUR",
          amount: 0,
          recurrenceUnit: "ONETIME",
        },
        {
          desc: "VEHICLE LICENSE FEE",
          chargeType: "SURCHARGE",
          code: "VLF",
          amount: 52.9,
          recurrenceUnit: "ONETIME",
        },
      ],
      currencyCode: "USD",
      taxes: [
        {
          taxableAmount: 5203.33,
          desc: "CA TAX LAX 9.5PCT",
          percent: 9.5,
          type: "PERCENT",
          amount: 494.32,
        },
        {
          taxableAmount: 1336.44,
          desc: "CA TOURISM 3.50PCT",
          percent: 3.5,
          type: "PERCENT",
          amount: 46.78,
        },
        {
          taxableAmount: 6177.79,
          desc: "LAX CONC 11.11PCT",
          percent: 11.11,
          type: "PERCENT",
          amount: 686.35,
        },
      ],
      rateId: "10595880580",
      rateCharge: 1009.04,
      ratePlusLate: 1014.9,
      type: "PayLater",
      balanceDue: 7450.24,
      applyTaxAfterDiscount: true,
      applySurchargeAfterDiscount: true,
      extraDayFreeMiles: 0,
      extraWeekFreeMiles: 0,
      addOns: [
        {
          includedInPrice: false,
          desc: "CANCEL FOR ANY REASON PROTECTION",
          maxQuantity: 1,
          chargeType: "CUSTOM",
          autoApply: false,
          rateUnit: "DOLLAR",
          rate: 10,
          code: "TRAVLINS",
          amount: 101.49,
          recurrenceUnit: "PERCENT",
          quantity: 1,
          includedInPriceQuantity: 0,
          unit: 1,
        },
        {
          includedInPrice: false,
          desc: "CHILD TODDLER SEAT",
          maxQuantity: 5,
          chargeType: "EQUIPMENT",
          autoApply: false,
          rateUnit: "DOLLAR",
          rate: 10.99,
          code: "TODDLER",
          amount: 1516.62,
          recurrenceUnit: "DAILY",
          quantity: 3,
          includedInPriceQuantity: 0,
          unit: 46,
        },
        {
          includedInPrice: false,
          desc: "HANDICAP CONTROLS",
          maxQuantity: 1,
          chargeType: "CUSTOM",
          autoApply: false,
          rateUnit: "DOLLAR",
          rate: 0,
          code: "HDCP",
          amount: 0,
          recurrenceUnit: "ONETIME",
          quantity: 1,
          includedInPriceQuantity: 0,
          unit: 1,
        },
        {
          includedInPrice: false,
          desc: "LOSS DAMAGE WAIVER",
          maxQuantity: 1,
          chargeType: "DAMAGE_WAIVER",
          autoApply: false,
          rateUnit: "DOLLAR",
          rate: 23.99,
          code: "LDW",
          amount: 1103.54,
          recurrenceUnit: "DAILY",
          quantity: 1,
          includedInPriceQuantity: 0,
          unit: 46,
        },
        {
          includedInPrice: false,
          desc: "NAVIGATION SYSTEM",
          maxQuantity: 1,
          chargeType: "EQUIPMENT",
          autoApply: false,
          rateUnit: "DOLLAR",
          rate: 10.99,
          code: "NAVS",
          amount: 505.54,
          recurrenceUnit: "DAILY",
          quantity: 1,
          includedInPriceQuantity: 0,
          unit: 46,
        },
        {
          includedInPrice: false,
          desc: "PAI/PEP",
          maxQuantity: 1,
          chargeType: "PAI_PEC",
          autoApply: false,
          rateUnit: "DOLLAR",
          rate: 3.34,
          code: "PAC",
          amount: 153.64,
          recurrenceUnit: "DAILY",
          quantity: 1,
          includedInPriceQuantity: 0,
          unit: 46,
        },
        {
          includedInPrice: false,
          desc: "PLATEPASS ALL INCLUSIVE",
          maxQuantity: 1,
          chargeType: "CUSTOM",
          autoApply: false,
          rateUnit: "DOLLAR",
          rate: 12.99,
          code: "ATS",
          amount: 194.85,
          recurrenceUnit: "DAILY",
          quantity: 1,
          includedInPriceQuantity: 0,
          unit: 15,
          maxAmount: 194.85,
        },
        {
          includedInPrice: false,
          desc: "PRIORITY PASS",
          maxQuantity: 1,
          chargeType: "CUSTOM",
          autoApply: false,
          rateUnit: "DOLLAR",
          rate: 19.99,
          code: "PP",
          amount: 19.99,
          recurrenceUnit: "ONETIME",
          quantity: 1,
          includedInPriceQuantity: 0,
          unit: 1,
        },
        {
          includedInPrice: false,
          desc: "RENTAL LIABILITY PROTECTION",
          maxQuantity: 1,
          chargeType: "RLP",
          autoApply: false,
          rateUnit: "DOLLAR",
          rate: 14.25,
          code: "RLP",
          amount: 655.5,
          recurrenceUnit: "DAILY",
          quantity: 1,
          includedInPriceQuantity: 0,
          unit: 46,
        },
        {
          includedInPrice: false,
          desc: "ROAD SIDE ASSIST",
          maxQuantity: 1,
          chargeType: "CUSTOM",
          autoApply: false,
          rateUnit: "DOLLAR",
          rate: 6.99,
          code: "RSA",
          amount: 321.54,
          recurrenceUnit: "DAILY",
          quantity: 1,
          includedInPriceQuantity: 0,
          unit: 46,
        },
        {
          includedInPrice: false,
          desc: "SUPPLEMENTAL LIAB",
          maxQuantity: 1,
          chargeType: "SLI",
          autoApply: false,
          rateUnit: "DOLLAR",
          rate: 11.68,
          code: "SLP",
          amount: 537.28,
          recurrenceUnit: "DAILY",
          quantity: 1,
          includedInPriceQuantity: 0,
          unit: 46,
        },
      ],
      totalCharges: 7450.24,
      ratePlan: {
        type: "MONTHLY",
        rate: 655.2,
      },
      perMileAmount: 0,
      extraDayRate: 17.48,
      totalPayments: 0,
      freeCreditAmountApplied: 0,
      discountValue: 0,
      totalCapturedAmount: 0,
      totalPreAuthReleaseAmount: 0,
      borrowerDiscount: 0,
      mileageUnit: "MI",
      freeMiles: 0,
      totalFreeMiles: 0,
      extraWeekRate: 150.7,
      rateVendor: "42080",
      lateCharge: 5.86,
      rentalDays: 45,
    },
    foxReservationCode: "FBCK-LAX-XDPKNX",
    renterComments: "test",
    attendanceStatus: "NO_SHOW",
    cancellationFee: 7450.24,
    apiLicense: {
      companyCityText: "LOS ANGELES",
      companyStateText: "CALIFORNIA",
      companyZipCodeText: "90045",
      companyCountryText: "United States",
      contactPersonEmail: "rvarma@foxrentcar.com",
      contactPersonPhone: "3236739084",
      companyName: "FOXADMIN",
      licenseKey: "C7914BF445D8855B98756D1BB3797",
      apiLicenseId: 10008729751,
      encryptionKey: "2POOnHFiEhDA0HlUkJRPbw==",
      primaryAuthenticationId: 10008729752,
      renewedOn: "",
      expirationOn: "01:01:2050:00:00:00",
      expirationKeyOn: "01:01:2050:00:00:00",
      contactPersonTitle: "VP Engineering",
      contactPersonName: "Rajiv Varma",
      companyAddress: "5500 W Century Blvd, Los Angeles, CA 90045",
    },
    partner: {
      showFoxConfirmationKey: false,
      clientReservationAllowed: false,
      showAdditionalMessage: false,
      sourceAsIata: false,
      systemCancelAllowed: false,
      alwaysPaymentRequired: false,
      displayName: "Fox Admin",
      companyState: "CA",
      companyCity: "Los Angeles",
      companyZipCode: "90045",
      partnerCode: "FOXADMIN",
      partnerId: 10008729753,
      contactEmail: "rvarma@foxrentcar.com",
      apiLicenseId: 10008729751,
      confKeyPrefix: "FBCK-",
      apiName: "EXTERNAL API",
      sourceCode: "17",
      allowedPayType: "BOTH",
      cardRequired: true,
      customerMaxReservations: 10,
      returnConfirmKeyType: "PROVIDER",
      contactName: "Rajiv Varma",
      contactPhone: "3236739084",
      contactTitle: "VP Engineering",
      companyAddress: "5500 W Century Blvd, Los Angeles, CA 90045",
      companyCountry: "US",
      name: "FoxAdmin",
    },
    fines: 0,
    termsAndConditions: false,
    tourOperator: false,
    timezoneString: "America/Los_Angeles",
    voucherAdded: false,
    customerInfo: {
      phoneNumbers: [
        {
          country: "ES",
          type: "Home",
          countryCode: 34,
          number: "654545678",
        },
        {
          country: "CA",
          type: "Work",
          countryCode: 1,
          number: "4332234567",
        },
        {
          country: "BD",
          type: "Mobile",
          countryCode: 880,
          number: "1723567890",
        },
      ],
      customerId: 10036489520,
      renterId: "TI-074450",
      foxCredit: 50,
      driversLicense: {
        licenseManuallyApproved: false,
        expDate: "2030-12-21T00:00:00",
        country: "BD",
        province: "Dhaka",
        tempLicenseUploadAllowed: true,
        number: "A3451111",
        manualDmvVerified: false,
        licenseEditable: true,
      },
      textDeals: false,
      dob: "1987-01-01T00:00:00",
      payNow: false,
      companyName: "ertert",
      emailDeals: false,
      firstName: "Pritam",
      lastName: "Reserve Sync",
      reservationCount: 4,
      insurance: {
        insuranceVerified: false,
      },
      designation: "32523",
      addresses: [
        {
          street: "Test",
          provinceCode: "NY",
          country: "US",
          type: "HOME",
          province: "NEW YORK",
          primary: true,
          city: "NEW YORK",
          postalCode: "10150",
        },
      ],
      email: "pray+reservesync@fox.com",
      xpress: false,
    },
    integStatus: "EDIT_SYNC_COMPLETE",
    categoryName: "FCAR - Fullsize",
    xpressEnabled: false,
    expectedEndDate: "04:06:2024:11:30",
    lastModifiedMethod: "PARTNER_WEB",
    contractAccepted: true,
    integResvConfNum: "WFX06A28A2",
    creationDateTime: "2024-01-15T06:48:25",
    xpress: false,
    applyCancellationFee: true,
    cancellationFeePolicy:
      "For detailed information on our cancellation policies, please visit our Rental Policies page at: https://www.foxrentacar.com/en/rental-policies.html",
    createdMethodValue: "PARTNER_WEB",
    totalCreditAmountGranted: 0,
  },
  message: {
    displayMessage: "Reservation edited successfully",
    messageCode: 12004,
    messageType: "SUCCESS",
    date: "2024-02-16T11:24:55",
    additionalMessage:
      "Reservation edited with code: WFX06A28A2, on: 2024-02-16T11:24:55",
    partnerCode: "FOXADMIN",
    requestId: "cad9a948-59cb-4c60-b95a-7bde989d20e6",
  },
};

import React, { Fragment, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
//import * as actions from "../../../store/actions/index";
import Moment from "moment";
import { useState } from "react";
import Draggable from "react-draggable";
import ReactHtmlParser from "react-html-parser";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import CommonInputField from "../../../components/UI/Input/common-input-field";
import { showNotification } from "../../../components/UI/Toaster/Toaster";
import OverrideComponent from "../../../components/UI/overrides/override-component";
import { getOverrideInputControls } from "../../../components/UI/overrides/override-input";
import TooltipItem from "../../../components/tooltip/TooltipItem";
import {
  FreeCreditModel,
  ReverseCreditModel,
} from "../../../services/entities/models/AccountCreditModels";
import { FreeCreditViewModel } from "../../../services/entities/view-models/FreeCreditViewModel";
import { EXTERNAL_CODE_LBL, ModalType } from "../../../shared/GlobalVar";
import {
  convertDateTimeString,
  dateArrayRender,
  formatAmountValue,
  getBasicToastResponse,
  getDropdownItems,
  getErrorToastData,
  getNoteTooltipHtml,
  isNullOrEmpty,
  updateObject,
} from "../../../shared/utility";
import { createTooltip } from "../../../shared/utility/tooltip-utility";
import {
  addFreeCredit,
  addFreeCreditReset,
  getEnumvalues,
  reverseCredit,
  reverseCreditReset,
  searchCreditHistory,
  searchCreditHistoryReset,
} from "../../../store/actions";
import { validateFreeCreditGrantReverse } from "../MemberHelper";

const FreeCreditGrantReverseModal = (props) => {
  const textInput = useRef("");
  const [freeCreditViewData, setFreeCreditViewData] = useState(
    new FreeCreditViewModel()
  );
  const [addFreeCreditObject, setAddFreeCreditObject] = useState(
    new FreeCreditModel()
  );
  const [reverseFreeCreditObject, setReverseFreeCreditObject] = useState(
    new ReverseCreditModel()
  );
  //const [loading, setLoading] = useState(false)
  const [expandMode, setExpandMode] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [disableField, setDisableField] = useState(false);
  const [creditGrantHistoryList, setCreditGrantHistoryList] = useState([]);
  const [locations, setLocations] = useState([]);
  const [subReasons, setSubReasons] = useState([]);
  const [inputType, setInputType] = useState("password");
  const [readOnly, setReadOnly] = useState("readOnly");
  const [disableMsg, setDisableMsg] = useState("");
  const [disabledSelectedItem, setDisabledSelectedItem] = useState("disabled");
  const [parentActivityLogId, setParentActivityLogId] = useState("");
  const [displayHeader, setDisplayHeader] = useState("block");
  const [selected, setSelected] = useState("-1");
  const [creditRowData, setCreditRowData] = useState(null);
  const [selectedActivity, setSelectedActivity] = useState(null);
  const [creditHistoryPopupObj, setCreditHistoryPopupObj] = useState(null);
  const [reservationCode, setReservationCode] = useState(null);
  const [isResSearchDone, setIsResSearchDone] = useState(false);
  const [isValidateDone, setIsValidateDone] = useState(false);
  const isCreditReverseModal = props.popupFor === "creditreverse";

  const dispatch = useDispatch();
  const dependentOrg = useSelector(
    (state) => state.auth.loggedInUserData.DependantOrgList
  );
  const activityLogProblemTypes = useSelector(
    (state) => state.enumvaluesReducer.activityLogProblemTypes
  );
  const revertFreeCreditReasons = useSelector(
    (state) => state.enumvaluesReducer.revertFreeCreditReasons
  );
  const activityLogSubProblemTypes = useSelector(
    (state) => state.enumvaluesReducer.activityLogSubProblemTypes
  );
  const creditHistoryResponse = useSelector(
    (state) => state.memberReducer.searchCreditHistoryResponse
  );
  const addFreeCreditResponse = useSelector(
    (state) => state.memberReducer.addFreeCreditResponse
  );
  const reverseCreditResponse = useSelector(
    (state) => state.memberReducer.reverseCreditResponse
  );

  const subCategories = [
    "PROFILE_ADD_FREE_CREDIT",
    "PROFILE_REVERT_FREE_CREDIT",
    "RESERVATION_DEBIT_ACCOUNT_CREDITS",
    "RESERVATION_REFUND_ACCOUNT_CREDITS",
    "RESERVATION_VOID_ACCOUNT_CREDITS",
  ];

  useEffect(() => {
    if (
      !activityLogProblemTypes ||
      !activityLogSubProblemTypes ||
      !revertFreeCreditReasons
    ) {
      dispatch(getEnumvalues());
    }
    setLocations(getDropdownItems(dependentOrg, "Key", "Value", ""));
    setShowModal(props.showModal);
    getCreditHistoryData(props.reservationCode);
  }, [props.memberId]);

  useEffect(() => {
    if (!creditHistoryResponse) {
      return;
    }
    if (creditHistoryResponse.StatusCode === 0) {
      populateData(
        creditHistoryResponse,
        creditHistoryPopupObj ? creditHistoryPopupObj.ReservationCode : ""
      );
      dispatch(searchCreditHistoryReset());
    }
  }, [creditHistoryResponse]);

  useEffect(() => {
    if (!addFreeCreditResponse) {
      return;
    }
    showNotification(getBasicToastResponse(addFreeCreditResponse));

    if (addFreeCreditResponse.StatusCode === 0) {
      setAddFreeCreditObject(new FreeCreditModel());
      props.resetParentPage();
      props.closeModal();
    }
    dispatch(addFreeCreditReset());
  }, [addFreeCreditResponse]);

  useEffect(() => {
    if (!reverseCreditResponse) {
      return;
    }
    showNotification(getBasicToastResponse(reverseCreditResponse));

    if (reverseCreditResponse.StatusCode === 0) {
      props.resetParentPage();
      props.closeModal();
    }
    dispatch(reverseCreditReset());
  }, [reverseCreditResponse]);

  const onSaveChanges = (event) => {
    event.preventDefault();
    if (props.popupFor !== "creditreverse") {
      saveGrantFreeCredit();
    } else {
      saveReverseFreeCredit();
    }
  };

  const getReservationInfo = () => {
    if (!isResSearchDone && textInput.current.value) {
      getCreditHistoryData(textInput.current.value);
      setReservationCode(textInput.current.value);
      setDisableField(false);
      setIsResSearchDone(true);
    }
  };

  const onPressEnterEventHandler = (event) => {
    if (event.key === "Enter") {
      getReservationInfo();
    }
  };

  const handleOnMouseOver = (event) => {
    event.preventDefault();
    setReadOnly("");
  };

  const inputChangedHandler = (event, controlName, elementType) => {
    let addFreeCreditObjectNew =
      controlName === "ReservationCode"
        ? new FreeCreditModel()
        : { ...addFreeCreditObject };
    let value = null;
    if (event !== "Invalid date" && elementType === "datetimepicker") {
      value = event ? Moment(event).format("MM:DD:YYYY:HH:mm") : event;
    } else if (elementType === "checkbox") {
      value = event.target.checked;
    } else {
      value = event.target.value;
    }
    const updatedControls = updateObject(
      isCreditReverseModal ? reverseFreeCreditObject : addFreeCreditObjectNew,
      {
        [controlName]: value,
      }
    );
    if (isCreditReverseModal) {
      setReverseFreeCreditObject(updatedControls);
    } else {
      setAddFreeCreditObject(updatedControls);
    }

    if (elementType === "reasonType") {
      let subreasonList = activityLogSubProblemTypes
        ? [...activityLogSubProblemTypes]
        : [];
      setSubReasons(
        subreasonList.filter((item) => item.ParentName === event.target.value)
      );
    }
    if (controlName === "ReservationCode") {
      setIsResSearchDone(false);
    }
  };

  const getCreditHistoryData = (resCode) => {
    const newObj = {};
    newObj.MemberId = props.memberId;
    newObj.ReservationCode = resCode;
    newObj.PageCount = 0;
    newObj.RecordsPerPage = 100;
    setCreditHistoryPopupObj(newObj);
    dispatch(searchCreditHistoryReset());
    dispatch(searchCreditHistory(newObj));
  };

  const populateData = (data, resCode) => {
    if (resCode) {
      addFreeCreditObject.MemberId = props.memberId;
      addFreeCreditObject.StartDate = data.StartDate;
      addFreeCreditObject.EndDate = data.EndDate;
      addFreeCreditObject.TotalCharges = data.TotalCharges;
      addFreeCreditObject.PickUpLocationId = data.PickUpLocationId;
      addFreeCreditObject.ReturnLocationId = data.ReturnLocationId;
      addFreeCreditObject.TotalCharges = data.TotalCharges;
      addFreeCreditObject.TotalCreditAmount = data.TotalCreditAmount;
    }
    let creditGrantHistoryListNew =
      props.reservationCode ||
        (!reservationCode && data.CreditGrantHistoryInfos)
        ? data.CreditGrantHistoryInfos
        : creditGrantHistoryList;

    setFreeCreditViewData(data);
    setAddFreeCreditObject(addFreeCreditObject);
    setDisableField(
      reservationCode &&
        (data.StartDate ||
          data.EndDate ||
          data.TotalCharges ||
          data.TotalCreditAmount ||
          data.TotalRefundableAmount)
        ? true
        : false
    );
    setCreditGrantHistoryList(creditGrantHistoryListNew);
    setDisplayHeader(creditGrantHistoryListNew.length ? "" : "block");
  };

  const onTableTitleClick = () => {
    setDisplayHeader(
      displayHeader === "block" || displayHeader === "" ? "none" : "block"
    );
  };

  const formatActivityLogComments = (Notes, activityLog) => {
    let splitWithSpChar = Notes ? Notes.split("#|#") : "";
    let commentsDiv = [];
    if (splitWithSpChar.length > 0) {
      splitWithSpChar.forEach((element, index) => {
        let splitWithColon = element ? element.split("#:#") : "";
        commentsDiv.push(
          <div className="row w-100 pb-2">
            <span className="font-weight-bold w-40">{splitWithColon[0]}:</span>

            <span id={"note" + index + activityLog} className="w-60">
              {createTooltip(splitWithColon[1], splitWithColon[1], {
                forceShow: true,
              })}
            </span>
          </div>
        );
      });
    }
    return commentsDiv;
  };

  const toggleExpand = (event, activityLogId) => {
    if (selectedActivity === activityLogId) {
      setExpandMode(!expandMode);
    } else {
      setSelectedActivity(activityLogId);
      setExpandMode(true);
    }
  };

  const getNotesColumsRowHtml = (labelCaption, value, id = 0) => {
    return (
      <Fragment>
        <div className="row w-100 pb-2">
          <span className="font-weight-bold w-40">{labelCaption}:</span>

          <span id={id} className="w-60">
            {createTooltip(
              labelCaption === "Reservation" ? ReactHtmlParser(value) : value,
              !isNullOrEmpty(id)
                ? ReactHtmlParser(getNoteTooltipHtml(value))
                : "",
              { forceShow: true }
            )}
          </span>
        </div>
      </Fragment>
    );
  };

  const getNotesColumnHtml = (creditHistoryEntity, isExpand) => {
    let subCategory = creditHistoryEntity.SubCategory;
    let reasonText = creditHistoryEntity.TypeDisplayText
      ? creditHistoryEntity.SubTypeDisplayText
        ? creditHistoryEntity.TypeDisplayText +
        " / " +
        creditHistoryEntity.SubTypeDisplayText
        : creditHistoryEntity.TypeDisplayText
      : "";
    let reservationText = creditHistoryEntity.ReservationCode
      ? creditHistoryEntity.ReservationCode + "<br />"
      : "";
    let dateText = creditHistoryEntity.ReservationStartDate
      ? creditHistoryEntity.ReservationEndDate
        ? convertDateTimeString(creditHistoryEntity.ReservationStartDate) +
        " to " +
        convertDateTimeString(creditHistoryEntity.ReservationEndDate)
        : convertDateTimeString(creditHistoryEntity.ReservationStartDate)
      : "";
    let reservationWithDate =
      reservationText !== "" ? reservationText + dateText : "";
    let notesData;
    if (isExpand) {
      notesData = subCategories.includes(subCategory) ? (
        <div className="row">
          {subCategory !== "PROFILE_REVERT_FREE_CREDIT"
            ? getNotesColumsRowHtml(
              subCategory === "PROFILE_ADD_FREE_CREDIT"
                ? "Granted by"
                : subCategory === "RESERVATION_DEBIT_ACCOUNT_CREDITS"
                  ? "Used By"
                  : subCategory === "RESERVATION_REFUND_ACCOUNT_CREDITS"
                    ? "Refunded To"
                    : "Voided By",
              subCategory === "RESERVATION_VOID_ACCOUNT_CREDITS" ||
                subCategory === "PROFILE_ADD_FREE_CREDIT"
                ? creditHistoryEntity.ProvidedBy
                : creditHistoryEntity.BorrowerName
            )
            : ""}

          {subCategory === "PROFILE_ADD_FREE_CREDIT" ||
            subCategory === "PROFILE_REVERT_FREE_CREDIT"
            ? getNotesColumsRowHtml("Reason", reasonText)
            : ""}
          {reservationWithDate
            ? getNotesColumsRowHtml(
              "Reservation",
              reservationWithDate,
              "reservationInfo" + creditHistoryEntity.ActivityLogId
            )
            : ""}
          {formatActivityLogComments(
            creditHistoryEntity.Notes,
            creditHistoryEntity.ActivityLogId
          )}
        </div>
      ) : (
        <div style={{ whiteSpace: "normal" }}>{creditHistoryEntity.Notes}</div>
      );
    } else {
      notesData = subCategories.includes(subCategory) ? (
        <div>
          {creditHistoryEntity.TypeDisplayText ? <b> Reason: </b> : ""}
          {reasonText}
        </div>
      ) : (
        <div> {creditHistoryEntity.Notes} </div>
      );
    }

    return notesData;
  };

  const renderCreditHistoryList = (creditGrantHistoryInfos) => {
    let collapseHtml = "";
    let expandHtml = "";
    let disabledIconClass = "actExpandIco";
    let activityStatus = "";
    let grayedOutRowStatus = ["PENDING", "CANCELLED"];
    if (creditGrantHistoryInfos && creditGrantHistoryInfos.length) {
      return creditGrantHistoryInfos.map((creditHistoryEntity) => {
        activityStatus = creditHistoryEntity.Status;
        disabledIconClass = !subCategories.includes(
          creditHistoryEntity.SubCategory
        )
          ? creditHistoryEntity.Notes.length < 100
            ? "actExpandIcoDisabled"
            : "actExpandIco"
          : "actExpandIco";

        expandHtml = getNotesColumnHtml(creditHistoryEntity, true);
        collapseHtml = getNotesColumnHtml(creditHistoryEntity, false);
        return (
          <tr
            className={`position-relative ${creditHistoryEntity.ActivityLogId === selected
              ? grayedOutRowStatus.includes(activityStatus)
                ? "revCrSelectedRow grayed-out-row-selected"
                : "revCrSelectedRow"
              : ""
              } ${grayedOutRowStatus.includes(activityStatus)
                ? "grayed-out-row"
                : ""
              }`}
            key={creditHistoryEntity.ActivityLogId}
            onClick={(event) =>
              isCreditReverseModal ? onRowClick(event, creditHistoryEntity) : ""
            }
            id={"rowId" + creditHistoryEntity.ActivityLogId}
          >
            <td className="text-nowrap text-left">
              <label id={"date" + creditHistoryEntity.ActivityLogId}>
                {createTooltip(
                  creditHistoryEntity.Date
                    ? convertDateTimeString(creditHistoryEntity.Date)
                    : "--",
                  creditHistoryEntity.Date
                    ? convertDateTimeString(creditHistoryEntity.Date)
                    : "",
                  { forceShow: true }
                )}
              </label>
            </td>
            <td className="text-nowrap text-left">
              <label
                id={
                  "subCategoryDisplayText" + creditHistoryEntity.ActivityLogId
                }
              >
                {createTooltip(
                  creditHistoryEntity.SubCategoryDisplayText || "--",
                  creditHistoryEntity.SubCategoryDisplayText || "--"
                )}
              </label>
            </td>
            <td className="text-nowrap text-right">
              <label>
                {creditHistoryEntity.Amount
                  ? formatAmountValue(creditHistoryEntity.Amount)
                  : "--"}
              </label>
            </td>
            <td className="text-nowrap text-right">
              <label>
                {creditHistoryEntity.TotalReversedAmount
                  ? formatAmountValue(creditHistoryEntity.TotalReversedAmount)
                  : "--"}
              </label>
            </td>
            <td className="text-left">
              <label id={"providedBy" + creditHistoryEntity.ActivityLogId}>
                {createTooltip(
                  creditHistoryEntity.ProvidedBy || "--",
                  creditHistoryEntity.ProvidedBy || ""
                )}
              </label>
            </td>
            <td className="text-left">
              <label id={"typeDisplayText" + creditHistoryEntity.ActivityLogId}>
                {createTooltip(
                  creditHistoryEntity.TypeDisplayText || "--",
                  creditHistoryEntity.TypeDisplayText || ""
                )}
              </label>
            </td>
            <td className="text-left">
              <label
                id={"subTypeDisplayText" + creditHistoryEntity.ActivityLogId}
              >
                {createTooltip(
                  creditHistoryEntity.SubTypeDisplayText || "--",
                  creditHistoryEntity.SubTypeDisplayText || ""
                )}
              </label>
            </td>
            <td className="text-left">
              <label id={"confirmationKey" + creditHistoryEntity.ActivityLogId}>
                {createTooltip(
                  creditHistoryEntity.ReservationCode || "--",
                  creditHistoryEntity.ReservationCode || ""
                )}
              </label>
            </td>
            <td className="text-left">
              <label
                id={"reservationStatus" + creditHistoryEntity.ActivityLogId}
              >
                {createTooltip(
                  creditHistoryEntity.ReservationStatus || "--",
                  creditHistoryEntity.ReservationStatus || ""
                )}
              </label>
            </td>
            <td
              className="text-left"
              id={"notes" + creditHistoryEntity.ActivityLogId}
            >
              {createTooltip(
                <i
                  id={"expandId_" + creditHistoryEntity.ActivityLogId}
                  className={`${disabledIconClass}  ${expandMode
                    ? creditHistoryEntity.ActivityLogId === selectedActivity
                      ? "fa fa-minus-square"
                      : "fa fa-plus-square"
                    : "fa fa-plus-square"
                    }`}
                  onClick={(event) =>
                    toggleExpand(event, creditHistoryEntity.ActivityLogId)
                  }
                />,
                expandMode
                  ? creditHistoryEntity.ActivityLogId === selectedActivity
                    ? "Collapse"
                    : "Expand"
                  : "Expand",
                { forceShow: true, isIcon: true }
              )}
              {
                <i
                  className={`fa fa-reply fa-flip-vertical actExpandIcoNormal ${creditHistoryEntity.SubCategory ===
                    "PROFILE_REVERT_FREE_CREDIT"
                    ? ""
                    : "displayNone"
                    }`}
                  style={{ color: "red" }}
                />
              }
              {expandMode
                ? creditHistoryEntity.ActivityLogId === selectedActivity
                  ? expandHtml
                  : collapseHtml
                : collapseHtml}
            </td>
            {grayedOutRowStatus.includes(activityStatus) ? (
              <TooltipItem
                placement="bottom"
                toolTipId={"rowId" + creditHistoryEntity.ActivityLogId}
                renderableHtml={ReactHtmlParser(
                  getNoteTooltipHtml(
                    activityStatus === "PENDING"
                      ? "The credit grant is in PENDING state"
                      : "The credit grant is in CANCELLED state"
                  )
                )}
                forceShow={true}
              />
            ) : (
              ""
            )}
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colSpan="10">No data available in table</td>
        </tr>
      );
    }
  };

  const renderCreditHistorySection = (creditGrantHistoryInfos) => {
    let currentBalance = freeCreditViewData
      ? freeCreditViewData.CurrentBalance
      : 0;

    let creditCount = freeCreditViewData
      ? freeCreditViewData.TotalCreditCount
      : 0;

    let creditAmount = freeCreditViewData
      ? freeCreditViewData.TotalCreditAmount
      : 0;
    let tableTitle = isCreditReverseModal
      ? "Please Select a Grant from the Credit History Table Below"
      : "";
    let toggleTableHeaderText =
      displayHeader === "block" || isNullOrEmpty(displayHeader)
        ? "Hide History Details"
        : "Display History Details";

    return (
      <Fragment>
        <div
          className="formRow col-sm-12 pr-0"
          style={{
            fontWeight: "bold",
            margin: "0rem",
            fontSize: "1.3rem",
          }}
        >
          <div className="col-sm-4 p-0">
            <span>Current Balance:</span>
            <label id="lblCreditCurrentBalance">
              {formatAmountValue(currentBalance)}
            </label>
          </div>
          <div className="col-sm-4">
            <span>Lifetime Credit Count:</span>
            <label id="lblCreditLifeTimeCount">{creditCount}</label>
          </div>
          <div className="col-sm-4 text-right">
            <span>Lifetime Credit Amount:</span>
            <label id="lblCreditLifeTimeCreditAmount">
              {formatAmountValue(creditAmount)}
            </label>
          </div>
          <br />
          <div
            className={`formRow col-sm-12 p-0 ${props.creditHistoryViewOnly ? "displayNone" : ""
              } `}
          >
            <label
              className={`col-sm-6 text-left ${isCreditReverseModal ? "" : "pseudo-link"
                }`}
              onClick={isCreditReverseModal ? null : onTableTitleClick}
            >
              {isCreditReverseModal ? tableTitle : toggleTableHeaderText}
            </label>
            {isCreditReverseModal ? (
              <label
                className="col-sm-6 pseudo-link text-right pr-0"
                onClick={onTableTitleClick}
              >
                {toggleTableHeaderText}
              </label>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="col-sm-12" style={{ display: displayHeader }}>
          <section className="grid-table" id="creditHistoryTableId">
            <div
              className="table-fixed-head tableScroll"
              style={{
                maxHeight: props.creditHistoryViewOnly ? "40rem" : "15rem",
              }}
            >
              <table
                className="table table-striped table-hover"
                style={{ tableLayout: "fixed" }}
              >
                <thead className="thead-primary">
                  <tr>
                    <th className="text-nowrap" style={{ width: "11%" }}>
                      Date
                    </th>
                    <th className="text-nowrap" style={{ width: "9%" }}>
                      {ReactHtmlParser("Grant / <br> Reversal")}
                    </th>
                    <th className="text-nowrap" style={{ width: "6%" }}>
                      Amount
                    </th>
                    <th className="text-nowrap" style={{ width: "9%" }}>
                      {ReactHtmlParser("Reversed <br> To Date")}
                    </th>
                    <th className="text-nowrap" style={{ width: "10%" }}>
                      Provided By
                    </th>
                    <th className="text-nowrap" style={{ width: "7%" }}>
                      Type
                    </th>
                    <th className="text-nowrap" style={{ width: "7%" }}>
                      Subtype
                    </th>
                    <th className="text-nowrap" style={{ width: "12%" }}>
                      Res. Code
                    </th>
                    <th className="text-nowrap" style={{ width: "8%" }}>
                      Res. Status
                    </th>
                    <th
                      className="text-nowrap"
                      style={{ width: "26%", zIndex: "1" }}
                    >
                      Notes
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {renderCreditHistoryList(creditGrantHistoryInfos)}
                </tbody>
              </table>
            </div>
          </section>
        </div>
      </Fragment>
    );
  };

  const saveGrantFreeCredit = () => {
    const saveDataObj = { ...addFreeCreditObject };
    saveDataObj.MemberId = props.memberId;
    let msg = validateFreeCreditGrantReverse(saveDataObj, props.popupFor);
    if (msg) {
      showNotification(getErrorToastData(msg));
      setIsValidateDone(true);
    } else {
      setIsValidateDone(false);
      dispatch(addFreeCreditReset());
      dispatch(addFreeCredit(saveDataObj));
    }
  };

  const getValueClasses = (defaultClass, value) => {
    let valueClasses = defaultClass;
    let controlValue = value;

    let faliedValidationClass =
      !isNullOrEmpty(controlValue) && String(controlValue) !== "Invalid date"
        ? ""
        : isValidateDone && defaultClass.split(" ").includes("required")
          ? "validation-failed"
          : "";
    return faliedValidationClass
      ? valueClasses + " " + faliedValidationClass
      : valueClasses;
  };

  const getInputElement = (elementConfig, otherConfig) => {
    return (
      <CommonInputField
        elementConfig={elementConfig}
        otherConfig={otherConfig}
        toggleInputTypeForPasswordField={setInputType}
        onChange={inputChangedHandler}
        onMouseOver={otherConfig.isOnMouseOver ? handleOnMouseOver : null}
        onKeyUp={otherConfig.isOnKeyUp ? onPressEnterEventHandler : null}
        onBlur={otherConfig.isOnBlur ? getReservationInfo : null}
        nodeElement={otherConfig.nodeElement}
      />
    );
  };

  let gridColRow = "grid-col-row";
  let gridColRowRequired = gridColRow + " position--relative required";
  let gridColRowRequiredForCol2 = gridColRowRequired + " grid-col-2 w-50";
  let labelClasses = "col-form-label font-weight-bold";

  const renderReservationSection = (addFreeCreditData) => {
    gridColRowRequired =
      gridColRow +
      " position--relative" +
      (isCreditReverseModal ? "" : " required");
    gridColRowRequiredForCol2 = gridColRowRequired + " grid-col-2 w-50";
    addFreeCreditData = addFreeCreditData || {};
    return (
      <div className="grid-container">
        <div className="grid-col-1">
          <label className="font-weight-bold">
            Reservation {isCreditReverseModal ? " Details" : " Info"}
          </label>
        </div>
        <div
          className={getValueClasses(
            gridColRowRequiredForCol2,
            addFreeCreditData.ReservationCode
          )}
        >
          <label className={labelClasses}>Reservation Code</label>
          {isCreditReverseModal ? (
            <span>
              {addFreeCreditData
                ? addFreeCreditData.ReservationCode || "--"
                : "--"}
            </span>
          ) : (
            getInputElement(
              {
                type: "text",
                name: "ReservationCode",
                value: addFreeCreditData.ReservationCode || "",
                ref: textInput,
                placeholder: EXTERNAL_CODE_LBL.FREE_CREDIT_PLACE_HOLDER,
              },
              {
                nodeElement: "input",
                isOnKeyUp: true,
                isOnBlur: true,
              }
            )
          )}
        </div>
        <div
          className={getValueClasses(
            gridColRowRequired,
            addFreeCreditData.StartDate
          )}
        >
          <label className={labelClasses}>Start Date</label>
          {isCreditReverseModal ? (
            <span>
              {addFreeCreditData
                ? addFreeCreditData.ReservationStartDate
                  ? convertDateTimeString(
                    addFreeCreditData.ReservationStartDate
                  )
                  : "--"
                : "--"}
            </span>
          ) : (
            <div className="dateandtimepicker">
              {getInputElement(
                {
                  name: "StartDate",
                  disabled: disableField,
                },
                {
                  nodeElement: "datetime",
                  value: addFreeCreditData.StartDate,
                  dateFormat: "D",
                }
              )}
              {getInputElement(
                {
                  name: "StartTime",
                  disabled: disableField,
                },
                {
                  nodeElement: "datetime",
                  value: addFreeCreditData.StartDate,
                  dateNameForTimeField: "StartDate",
                }
              )}
            </div>
          )}
        </div>
        <div
          className={getValueClasses(
            gridColRowRequired,
            addFreeCreditData.EndDate
          )}
        >
          <label className={labelClasses}>End Date</label>
          {isCreditReverseModal ? (
            <span>
              {addFreeCreditData
                ? addFreeCreditData.ReservationEndDate
                  ? convertDateTimeString(addFreeCreditData.ReservationEndDate)
                  : "--"
                : "--"}
            </span>
          ) : (
            <div className="dateandtimepicker">
              {getInputElement(
                {
                  name: "EndDate",
                  disabled: disableField,
                },
                {
                  nodeElement: "datetime",
                  value: addFreeCreditData.EndDate,
                  dateFormat: "D",
                }
              )}
              {getInputElement(
                {
                  name: "EndTime",
                  disabled: disableField,
                },
                {
                  nodeElement: "datetime",
                  value: addFreeCreditData.EndDate,
                  dateNameForTimeField: "EndDate",
                }
              )}
            </div>
          )}
        </div>
        <div
          className={getValueClasses(
            gridColRowRequired,
            addFreeCreditData.PickUpLocationId
          )}
        >
          <label className={labelClasses}>Pickup Location</label>
          {isCreditReverseModal ? (
            <span>
              {addFreeCreditData
                ? addFreeCreditData.PickUpLocationDisplayText || "--"
                : "--"}
            </span>
          ) : (
            getInputElement(
              {
                name: "PickUpLocationId",
                value: addFreeCreditData.PickUpLocationId || "",
                disabled: disableField,
              },
              {
                nodeElement: "select",
                dropdownItem: locations,
                defaultDropdownText: "Pickup Location",
                isLocation: true,
              }
            )
          )}
        </div>
        <div className={gridColRow}>
          <label className={labelClasses}>Return Location</label>
          {isCreditReverseModal ? (
            <span>
              {addFreeCreditData
                ? addFreeCreditData.ReturnLocationDisplayText || "--"
                : "--"}
            </span>
          ) : (
            getInputElement(
              {
                name: "ReturnLocationId",
                value: addFreeCreditData.ReturnLocationId || "",
                disabled: disableField,
              },
              {
                nodeElement: "select",
                dropdownItem: locations,
                defaultDropdownText: "Same as Pickup",
                isLocation: true,
              }
            )
          )}
        </div>
        <div className={gridColRow}>
          <label className={labelClasses}>Reservation Amount</label>
          <span>
            {addFreeCreditData.TotalCharges
              ? formatAmountValue(addFreeCreditData.TotalCharges || 0)
              : "--"}
          </span>
        </div>
        <div className={gridColRow}>
          <label className={labelClasses}>
            Credit granted for this reservation
          </label>
          <span>
            {addFreeCreditData.TotalCreditAmount
              ? formatAmountValue(addFreeCreditData.TotalCreditAmount || 0)
              : "--"}
          </span>
        </div>
      </div>
    );
  };
  const renderCreditGrantReverseSection = (creditData) => {
    gridColRowRequired = gridColRow + " position--relative required";
    gridColRowRequiredForCol2 = gridColRowRequired + " grid-col-2 w-50";
    creditData = creditData || {};
    const disabledCreditGrantField = isNullOrEmpty(creditData.ReservationCode);
    return (
      <div className="grid-container">
        <div className="grid-col-1">
          <label className="font-weight-bold">
            {isCreditReverseModal
              ? "Credit Reversal / Void Request"
              : "Credit Grant Info"}
          </label>
        </div>
        <div
          className={getValueClasses(gridColRowRequiredForCol2, creditData.Amount)}
        >
          <label className={labelClasses}>Amount</label>
          {isCreditReverseModal
            ? getInputElement(
              {
                type: "text",
                name: "Amount",
                readOnly: readOnly,
                disabled: disabledSelectedItem,
                value: creditData.Amount || "",
              },
              {
                nodeElement: "input",
                isOnMouseOver: true,
              }
            )
            : getInputElement(
              {
                type: "text",
                name: "Amount",
                readOnly: readOnly,
                value: creditData.Amount || "",
                disabled: disabledCreditGrantField,
              },
              {
                nodeElement: "input",
                isOnMouseOver: true,
              }
            )}
        </div>
        <div className={getValueClasses(gridColRowRequired, creditData.Reason)}>
          <label className={labelClasses}>Reason</label>
          {isCreditReverseModal
            ? getInputElement(
              {
                name: "Reason",
                value: creditData.Reason || "",
                disabled: disabledSelectedItem,
              },
              {
                nodeElement: "select",
                dropdownItem: revertFreeCreditReasons,
                defaultDropdownText: "Select Reason",
              }
            )
            : getInputElement(
              {
                name: "Reason",
                value: creditData.Reason || "",
                disabled: disabledCreditGrantField,
              },
              {
                nodeElement: "select",
                dropdownItem: activityLogProblemTypes,
                defaultDropdownText: "Select Type...",
                elementType: "reasonType",
              }
            )}
        </div>
        <div
          className={getValueClasses(gridColRowRequired, creditData.SubReason)}
        >
          {!isCreditReverseModal
            ? getInputElement(
              {
                name: "SubReason",
                value: creditData.SubReason || "",
                disabled: disabledCreditGrantField,
              },
              {
                nodeElement: "select",
                dropdownItem: subReasons,
                defaultDropdownText: "Select Subtype...",
              }
            )
            : ""}
        </div>
        <div
          className={getValueClasses(
            gridColRowRequiredForCol2,
            creditData.Notes
          )}
        >
          <label className={labelClasses}>Notes</label>
          {isCreditReverseModal
            ? getInputElement(
              {
                name: "Notes",
                value: creditData.Notes || "",
                disabled: disabledSelectedItem,
                style: { height: "5.5rem", resize: "none" },
                className: "txtbox_style",
              },
              {
                nodeElement: "textarea",
                isOnMouseOver: true,
              }
            )
            : getInputElement(
              {
                name: "Notes",
                value: creditData.Notes || "",
                style: { height: "5.5rem", resize: "none" },
                className: "txtbox_style",
                disabled: disabledCreditGrantField,
              },
              {
                nodeElement: "textarea",
              }
            )}
        </div>
        <div
          className={getValueClasses(
            gridColRowRequiredForCol2,
            creditData.Password
          )}
        >
          <label className={labelClasses}>Password</label>
          {isCreditReverseModal ? (
            <div className="position--relative">
              {getInputElement(
                {
                  type: inputType,
                  className: "text",
                  name: "Password",
                  placeholder: "password..",
                  readOnly: readOnly,
                  disabled: disabledSelectedItem,
                  value: creditData.Password || "",
                },
                {
                  nodeElement: "input",
                  isOnMouseOver: true,
                  isEyeIconRequired: true,
                  eyeIconCustomClass: "pl-2-0",
                }
              )}
            </div>
          ) : (
            <div className="position--relative">
              {getInputElement(
                {
                  type: inputType,
                  className: "text",
                  name: "Password",
                  placeholder: "password..",
                  readOnly: readOnly,
                  value: creditData.Password || "",
                },
                {
                  nodeElement: "input",
                  isOnMouseOver: true,
                  isEyeIconRequired: true,
                  eyeIconCustomClass: "pl-2-0",
                }
              )}
            </div>
          )}
        </div>
      </div>
    );
  };
  const renderGrantReversalDetailsSection = (addFreeCreditData) => {
    let gridColRowForCol2 = gridColRow + " grid-col-2 w-50";
    return (
      <div className="grid-container">
        <div className="grid-col-1">
          <label className="font-weight-bold">Grant / Reversal Details</label>
        </div>
        <div className={gridColRowForCol2}>
          <label className={labelClasses}>Amount</label>
          <span>
            {addFreeCreditData
              ? formatAmountValue(addFreeCreditData.Amount)
              : "--"}
          </span>
        </div>
        <div className={gridColRow}>
          <label className={labelClasses}>Reason</label>
          <span>
            Type:
            {addFreeCreditData ? addFreeCreditData.TypeDisplayText : "--"}
          </span>
        </div>
        <div className={gridColRow}>
          <label className={labelClasses}>Subtype</label>
          <span>
            {addFreeCreditData ? addFreeCreditData.SubTypeDisplayText : "--"}
          </span>
        </div>
        <div className={gridColRow}>
          <label className={labelClasses}>Reversed to Date</label>
          <span>
            {addFreeCreditData
              ? formatAmountValue(addFreeCreditData.TotalReversedAmount)
              : "--"}
          </span>
        </div>
        <div className={gridColRow}>
          <label className={labelClasses}> Date(s) Reversed</label>
          <span>
            {addFreeCreditData
              ? dateArrayRender(addFreeCreditData.ReversedDates)
              : "--"}
          </span>
        </div>
      </div>
    );
  };

  const renderCreditGrantArea = () => {
    if (disableField && typeof freeCreditViewData.StartDate === "undefined") {
      if (typeof freeCreditViewData.CreditGrantHistoryInfos !== "undefined") {
        let creditGrantHistoryInfos =
          freeCreditViewData.CreditGrantHistoryInfos[0];
        addFreeCreditObject.StartDate =
          creditGrantHistoryInfos.ReservationStartDate
            ? Moment(
              creditGrantHistoryInfos.ReservationStartDate,
              "MM:DD:YYYY:HH:mm"
            ).toDate()
            : null;
        addFreeCreditObject.EndDate = creditGrantHistoryInfos.ReservationEndDate
          ? Moment(
            creditGrantHistoryInfos.ReservationEndDate,
            "MM:DD:YYYY:HH:mm"
          ).toDate()
          : null;
        addFreeCreditObject.PickUpLocationId =
          creditGrantHistoryInfos.PickUpLocationId;
        addFreeCreditObject.ReturnLocationId =
          creditGrantHistoryInfos.ReturnLocationId;
        addFreeCreditObject.TotalCharges = creditGrantHistoryInfos.TotalCharges
          ? formatAmountValue(creditGrantHistoryInfos.TotalCharges || 0)
          : "--";
        addFreeCreditObject.TotalCreditAmount =
          creditGrantHistoryInfos.TotalCreditAmount
            ? formatAmountValue(creditGrantHistoryInfos.TotalCreditAmount || 0)
            : "--";
      }
    }
    return (
      <div
        id="divAddCredit"
        className="w-100 pt-3 pl-2-0"
        style={{
          borderTop: "0.1rem solid #d3dce9",
        }}
      >
        {renderReservationSection(addFreeCreditObject)}
        <fieldset>
          {renderCreditGrantReverseSection(addFreeCreditObject)}
        </fieldset>
        <div className="formRow pt-2">
          <OverrideComponent
            overrideItems={getOverrideInputControls(
              addFreeCreditObject,
              inputChangedHandler,
              ModalType.FREE_CREDIT_GRANT_REVERSE_MODAL
            )}
          />
        </div>
      </div>
    );
  };

  const saveReverseFreeCredit = () => {
    const reverseFreeCreditObj = { ...reverseFreeCreditObject };
    reverseFreeCreditObj.ParentActivityLogId = parentActivityLogId;
    let msg = validateFreeCreditGrantReverse(
      reverseFreeCreditObj,
      props.popupFor
    );
    if (msg) {
      showNotification(getErrorToastData(msg));
      setIsValidateDone(true);
    } else {
      setIsValidateDone(false);
      dispatch(reverseCreditReset());
      dispatch(reverseCredit(reverseFreeCreditObj));
    }
  };

  const onRowClick = (event, creditHistory) => {
    event.preventDefault();
    setCreditRowData(creditHistory);
    setParentActivityLogId(creditHistory.ActivityLogId);
    setSelected(creditHistory.ActivityLogId);
    if (creditHistory) {
      let disableMessage = "";
      if (!creditHistory.ShowRelease) {
        if (
          creditHistory.Status === "PENDING" ||
          creditHistory.Status === "CANCELLED"
        ) {
          disableMessage =
            "The credit grant is in " +
            creditHistory.Status +
            " state. No action is allowed in this state";
        } else if (creditHistory.SubCategory === "PROFILE_REVERT_FREE_CREDIT") {
          disableMessage = "A Credit Reversal cannot be reversed.";
        } else if (
          creditHistory.SubCategory === "RESERVATION_DEBIT_ACCOUNT_CREDITS"
        ) {
          disableMessage =
            "This Credit Grant has been fully reversed.  No further action  allowed.";
        } else if (
          creditHistory.SubCategory === "RESERVATION_REFUND_ACCOUNT_CREDITS"
        ) {
          disableMessage =
            "This Credit Refund has been voided. No further action allowed.";
        } else if (
          creditHistory.SubCategory === "RESERVATION_VOID_ACCOUNT_CREDITS"
        ) {
          disableMessage = "No action is available for a 'Credit Voided' entry";
        }
      }
      setDisableMsg(disableMessage);
      setDisabledSelectedItem(disableMessage ? "disabled" : "");
    }
  };

  const renderCreditReverseArea = () => {
    return (
      <div
        id="divReverseCredit"
        className="w-100 pt-3 pl-2-0"
        style={{
          borderTop: "0.1rem solid #d3dce9",
        }}
      >
        {renderReservationSection(creditRowData)}
        {renderGrantReversalDetailsSection(creditRowData)}
        {renderCreditGrantReverseSection(reverseFreeCreditObject)}
      </div>
    );
  };

  const getModalSettings = () => {
    return {
      modalWidth: props.width || "1150",
      modalBodyHeight: props.creditHistoryViewOnly
        ? 400
        : props.height || "auto",
    };
  };

  let modalSettings = getModalSettings();
  let creditGrantHistoryInfo = creditGrantHistoryList
    ? creditGrantHistoryList
    : [];
  if (!isNullOrEmpty(addFreeCreditObject.ReservationCode)) {
    creditGrantHistoryInfo = creditGrantHistoryInfo.filter((historyData) =>
      String(historyData.ReservationCode)
        .toUpperCase()
        .startsWith(addFreeCreditObject.ReservationCode.toUpperCase())
    );
  }
  return (
    <Fragment>
      <Draggable handle=".cursor">
        <Modal
          isOpen={showModal}
          className="no-cursor"
          toggle={() => {
            props.closeModal();
          }}
          style={{
            minWidth: modalSettings.modalWidth + "px",
          }}
        >
          <ModalHeader
            className="modal-header bg-primary modal-title text-white cursor"
            toggle={() => {
              props.closeModal();
            }}
          >
            <i className="fas fa-arrows-alt" /> {props.title}
          </ModalHeader>
          <ModalBody
            style={{
              height: modalSettings.modalBodyHeight + "px",
            }}
            className="add-reverse-credit-modal"
          >
            <div
              className="form-group-popup row"
              style={{
                height: modalSettings.modalBodyHeight - 20 + "px",
              }}
            >
              <div className="formRow col-sm-12 pr-0">
                <div className="col-lg-12 pr-0">
                  {renderCreditHistorySection(creditGrantHistoryInfo)}
                </div>
                <div
                  className="col-lg-12 text-center pt-2 pb-2 col-form-label font-weight-bold"
                  style={{ color: "red", minHeight: "2.7rem" }}
                >
                  {isCreditReverseModal ? disableMsg : ""}
                </div>

                {!props.creditHistoryViewOnly
                  ? isCreditReverseModal
                    ? renderCreditReverseArea()
                    : renderCreditGrantArea()
                  : ""}
              </div>
            </div>
          </ModalBody>
          {!props.creditHistoryViewOnly ? (
            <ModalFooter>
              <button
                type="button"
                name="btnAddFreeCredit"
                className={`btn waves-effect waves-light btnSize ml-2 ${isCreditReverseModal && disabledSelectedItem
                  ? "btn-gray"
                  : "btn-primary"
                  }`}
                onClick={(event) => onSaveChanges(event)}
                disabled={isCreditReverseModal ? disabledSelectedItem : ""}
              >
                {props.popupFor !== "creditreverse"
                  ? "Add Credit"
                  : "Reverse Credit"}
              </button>
              <button
                type="button"
                name="btnCancel"
                className="btn btn-secondary waves-effect waves-light btnSize ml-2"
                onClick={props.closeModal}
              >
                Cancel
              </button>
            </ModalFooter>
          ) : (
            ""
          )}
        </Modal>
      </Draggable>
    </Fragment>
  );
};
export default FreeCreditGrantReverseModal;

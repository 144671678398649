import axios from "axios";
import * as actionTypes from "./actionTypes";
import { invokeServerAPI } from "../../services/httpService";
export const getMemberPermissionsStart = () => {
  return {
    type: actionTypes.GET_MEMBER_PERMISSIONS_START,
  };
};
export const getMemberPermissionsSuccess = (memberPermissionsData) => {
  return {
    type: actionTypes.GET_MEMBER_PERMISSIONS_SUCCESS,
    memberPermissionsData: memberPermissionsData,
  };
};
export const getMemberPermissionsFail = (error) => {
  return {
    type: actionTypes.GET_MEMBER_PERMISSIONS_FAIL,
    error: error,
  };
};
export const getMemberPermissions = (req) => {
  return (dispatch) => {
    dispatch(getMemberPermissionsStart());
    invokeServerAPI("getmemberpermissions", req)
      .then((response) => {
        dispatch(getMemberPermissionsSuccess(response));
      })
      .catch((err) => {
        //dispatch(getMemberFail(err.response.error));
      });
  };
};

export const getAppAccessPermissionsStart = () => {
  return {
    type: actionTypes.GET_MEMBER_APPACCESS_PERMISSIONS_START,
  };
};
export const getAppAccessPermissionsSuccess = (
  memberAppAccessPermissionsData
) => {
  return {
    type: actionTypes.GET_MEMBER_APPACCESS_PERMISSIONS_SUCCESS,
    memberAppAccessPermissionsData: memberAppAccessPermissionsData,
  };
};
export const getAppAccessPermissionsFail = (error) => {
  return {
    type: actionTypes.GET_MEMBER_APPACCESS_PERMISSIONS_FAIL,
    error: error,
  };
};
export const getAppAccessPermissions = (req) => {
  return (dispatch) => {
    dispatch(getAppAccessPermissionsStart());
    invokeServerAPI("getmemberpermissions", req)
      .then((response) => {
        dispatch(getAppAccessPermissionsSuccess(response));
      })
      .catch((err) => {
        //dispatch(getAppAccessPermissionsFail(err));
      });
  };
};
export const permissionsReset = () => ({
  type: actionTypes.PERMISSIONS_RESET,
});

export const assignPermissionsToMemberStart = () => {
  return {
    type: actionTypes.ASSIGN_PERMISSIONS_TO_MEMBER_START,
  };
};
export const assignPermissionsToMemberSuccess = (response) => {
  return {
    type: actionTypes.ASSIGN_PERMISSIONS_TO_MEMBER_SUCCESS,
    assignPermissionsToMemberResponse: response,
  };
};
export const assignPermissionsToMemberFail = (error) => {
  return {
    type: actionTypes.ASSIGN_PERMISSIONS_TO_MEMBER_FAIL,
    error: error,
  };
};
export const assignPermissionsToMemberReset = () => {
  return {
    type: actionTypes.ASSIGN_PERMISSIONS_TO_MEMBER_RESET,
  };
};

export const assignPermissionsToMember = (req) => {
  return (dispatch) => {
    dispatch(assignPermissionsToMemberStart());
    invokeServerAPI("assignpermission", req)
      .then((response) => {
        dispatch(assignPermissionsToMemberSuccess(response));
      })
      .catch((err) => {
        //dispatch(assignPermissionsToMemberFail(err));
      });
  };
};

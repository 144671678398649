import React, { Fragment, useState } from "react";
import {
  getDropdownItems,
  getFullName,
  updateObject,
} from "../../shared/utility";
import Pagination from "../../components/pagination/Pagination";
import NoRecordFoundComponent from "../../components/common/pages/no-record-found";
import DefaultComponentWithMessage from "../../components/common/pages/default-component-with-message";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import Draggable from "react-draggable";
import SearchAgentInputRequest from "./search-agent-input-request";
import { LabelAndValuePairControl } from "../../components/UI/Input/label-value-pair-control";
import { ADMIN_COMMON_CAPTION } from "../../shared/GlobalVar";

const AgentModal = (props) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [recordsPerPage, setRecordPerPage] = useState(100);
  const [searchAgentInputRequest, setSearchAgentInputRequest] = useState(
    new SearchAgentInputRequest()
  );

  const handleOnChange = ({ target }) => {
    let updateCtrls = { ...searchAgentInputRequest };
    const value = target.type === "checkbox" ? target.checked : target.value;
    updateCtrls = updateObject(updateCtrls, {
      [target.name]: value,
    });
    setSearchAgentInputRequest(updateCtrls);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    getAgentList();
  };

  const resetSearch = (event) => {
    props.resetAgentSearch();
    setSearchAgentInputRequest(new SearchAgentInputRequest());
  };

  const onPageChanged = (currentPageValue, recordsPerPageValue) => {
    if (currentPageValue && recordsPerPageValue) {
      setCurrentPage(currentPageValue);
      setRecordPerPage(recordsPerPageValue);
      getAgentList();
    }
  };
  const getAgentList = () => {
    props.getAgentList(searchAgentInputRequest);
  };
  const onAgentSelected = (agentObj) => {
    const agentInfo = {
      agentType: props.agentType,
      agentId: agentObj.MemberId,
      agentName: getFullName(
        agentObj.FirstName,
        agentObj.LastName,
        agentObj.MiddleName
      ),
    };
    props.onAgentSelected(agentInfo);
    props.closeModal();
  };
  const renderLabelInputPair = (
    labelCaption,
    controlName,
    value,
    nodeElement = "input",
    dropdownItem = null
  ) => {
    return (
      <LabelAndValuePairControl
        elementConfig={{
          name: controlName,
          value: value || "",
        }}
        otherConfig={{
          caption: labelCaption,
          value: value,
          dropdownItem: nodeElement === "select" ? dropdownItem : [],
        }}
        isEditMode={true}
        onChange={handleOnChange}
        parentRowClass={`form-group formRow col-sm-4`}
        labelClasses="col-sm-5 font-weight-bold"
        valueClasses="col-sm-7"
        nodeElement={nodeElement}
      />
    );
  };
  const renderAgentList = () => {
    if (props.agentResponse && props.agentResponse.SearchResults) {
      return props.agentResponse.SearchResults.map((agentObj) => {
        return (
          <tr
            key={agentObj.MemberId}
            onClick={(event) => onAgentSelected(agentObj)}
            style={{ cursor: "pointer" }}
          >
            <td>{agentObj.MembershipId || ""}</td>
            <td className="text-left">
              <Fragment>
                {getFullName(
                  agentObj.FirstName,
                  agentObj.LastName,
                  agentObj.MiddleName
                )}
              </Fragment>
            </td>
            <td className="text-left">{agentObj.EmailAddress || "--"}</td>
            <td>{agentObj.Phone || "--"}</td>
            <td className="text-left">{agentObj.PrimaryAddress || "--"}</td>
          </tr>
        );
      });
    }
  };

  return (
    <Draggable handle=".cursor">
      <Modal
        isOpen={props.showModal}
        className="no-cursor"
        toggle={() => {
          props.closeModal();
        }}
        style={{ minWidth: "1000px" }}
      >
        <ModalHeader
          className="modal-header bg-primary modal-title text-white cursor"
          toggle={() => {
            props.closeModal();
          }}
        >
          <i className="fas fa-arrows-alt" /> Agent Search
        </ModalHeader>
        <ModalBody
          style={{ maxHeight: "600px", minHeight: "480px" }}
          className="tableScroll"
        >
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-lg-12">
                <div className="form-group formRow">
                  {renderLabelInputPair(
                    ADMIN_COMMON_CAPTION.CUSTOMER_ID,
                    "MembershipId",
                    searchAgentInputRequest.MembershipId
                  )}
                  {renderLabelInputPair(
                    "First Name",
                    "FirstName",
                    searchAgentInputRequest.FirstName
                  )}
                  {renderLabelInputPair(
                    "Postal/ZIP Code",
                    "ZipCode",
                    searchAgentInputRequest.ZipCode
                  )}
                </div>
                <div className="form-group formRow">
                  {renderLabelInputPair(
                    "Email/Username",
                    "EmailAddress",
                    searchAgentInputRequest.EmailAddress
                  )}
                  {renderLabelInputPair(
                    "Last Name",
                    "LastName",
                    searchAgentInputRequest.LastName
                  )}
                  {renderLabelInputPair(
                    "Pickup Location",
                    "LocationId",
                    searchAgentInputRequest.LocationId,
                    "select",
                    getDropdownItems(
                      props.dependantOrgList,
                      "Key",
                      "Value",
                      "Any Location"
                    )
                  )}
                </div>
                <div className="form-group formRow">
                  {renderLabelInputPair(
                    "Phone",
                    "Phone",
                    searchAgentInputRequest.Phone
                  )}
                  {renderLabelInputPair(
                    "Role",
                    "Role",
                    searchAgentInputRequest.Role
                  )}
                </div>
              </div>
              <div
                className="popupButtonRow col-lg-12"
                style={{ textAlign: "right", margin: "0px" }}
              >
                <button
                  type="submit"
                  className="btn btn-primary waves-effect waves-light btnSize ml-2"
                >
                  Search
                </button>
                <button
                  type="button"
                  className="btn btn-secondary  waves-effect waves-light btnSize ml-2"
                  onClick={(event) => resetSearch(event)}
                >
                  Reset
                </button>
              </div>
            </div>

            <div className="row col-lg-12  m-0 p-0 mt-4">
              {props.IsSearchedPerform &&
              props.agentResponse &&
              props.agentResponse.TotalRecords > 0 ? (
                <Fragment>
                  <Pagination
                    totalRecords={props.agentResponse.TotalRecords}
                    recordsPerPage={recordsPerPage}
                    currentPage={currentPage}
                    onPageChanged={onPageChanged}
                    customClass="col-lg-12 m-0 p-0 mb-4"
                  />

                  <table className="table table-striped">
                    <thead className="thead-primary">
                      <tr>
                        <th>Agent ID</th>
                        <th>Name</th>
                        <th>Email/Username</th>
                        <th>Phone</th>
                        <th>Address</th>
                      </tr>
                    </thead>
                    <tbody>{renderAgentList()}</tbody>
                  </table>
                </Fragment>
              ) : !props.IsSearchedPerform ? (
                <DefaultComponentWithMessage />
              ) : (
                <NoRecordFoundComponent />
              )}
            </div>
          </form>
        </ModalBody>
      </Modal>
    </Draggable>
  );
};
export default AgentModal;

import React from "react";
import { NavLink } from "react-router-dom";

const NavigationItem = (props) => {
  //Utility.clearActiveMenu();
  return (
    <li>
      <NavLink
        to={props.link}
        exact={props.exact}
        activeClassName="active"
        className={props.className}
      >
        {props.children}
      </NavLink>
    </li>
  );
};

export default NavigationItem;

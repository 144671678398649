const Address = function (data, noDefaultCountry) {
  if (!data) data = {};
  this.Country = data.Country
    ? data.Country
    : !noDefaultCountry && localStorage.getItem("orgUnitCountry")
    ? JSON.parse(localStorage.getItem("orgUnitCountry")).Key
    : null;
  this.AddressType = data.AddressType || null;
  this.Street1 = data.Street1 || null;
  this.Street2 = data.Street2 || null;
  this.State = data.State || null;
  this.StateText = data.StateText || null;
  this.City = data.City || null;
  this.CityText = data.CityText || null;
  this.ZipCode = data.ZipCode || null;
  this.ZipCodeText = data.ZipCodeText || null;
  this.FormattedAddress = data.FormattedAddress || null;
};
export default Address;

import { reducerUpdateObject } from "./reducer.utility";
import * as actionTypes from "./../actions/actionTypes-shuttle-app";

const initialState = {
  error: null,
  loading: false,
  shuttleOptionResponse: null,
  amenities: null,
  availabilities: null,
  colorTypes: null,
  drivingTypes: null,
  engineStatuses: null,
  fuelTypes: null,
  fuelConsumptionUnitTypes: null,
  transMissionTypes: null,
  unitSystems: null,
};

const getShuttleOptionStart = (state) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
const getShuttleOptionFail = (state, action) => {
  return reducerUpdateObject(state, { error: action.error, loading: false });
};
const getShuttleOptionSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    shuttleOptionResponse: action.shuttleOptionResponse,
    error: null,
    loading: false,
  });
};
const getShuttleOptionReset = (state) => {
  return reducerUpdateObject(state, {
    amenities: null,
    availabilities: null,
    colorTypes: null,
    drivingTypes: null,
    engineStatuses: null,
    fuelTypes: null,
    fuelConsumptionUnitTypes: null,
    transMissionTypes: null,
    unitSystems: null,
  });
};
const getShuttleOptionforStaticDataSuccess = (state, action) => {
    return reducerUpdateObject(state, {
      amenities: action.amenities,
      availabilities: action.availabilities,
      colorTypes: action.colorTypes,
      drivingTypes: action.drivingTypes,
      engineStatuses: action.engineStatuses,
      fuelTypes: action.fuelTypes,
      fuelConsumptionUnitTypes: action.fuelConsumptionUnitTypes,
      transMissionTypes: action.transMissionTypes,
      unitSystems: action.unitSystems,
      error: null,
      loading: false,
    });
};
const getShuttleOptionforStaticDataReset = (state) => {
  return reducerUpdateObject(state, {
    shuttleOptionStaticResponse: null,
  });
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SHUTTLE_OPTION_START:
      return getShuttleOptionStart(state);
    case actionTypes.SHUTTLE_OPTION_SUCCESS:
      return getShuttleOptionSuccess(state, action);
    case actionTypes.SHUTTLE_OPTION_FAIL:
      return getShuttleOptionFail(state, action);
    case actionTypes.SHUTTLE_OPTION_RESET:
      return getShuttleOptionReset(state);
    case actionTypes.SHUTTLE_OPTION_STATIC_SUCCESS:
      return getShuttleOptionforStaticDataSuccess(state, action);
    case actionTypes.SHUTTLE_OPTION_STATIC_RESET:
      return getShuttleOptionforStaticDataReset(state);
    default:
      return state;
  }
};
export default reducer;

import PageInfo from "../models/PageInfo";

const BillingSearchRequest = function() {
  this.MemberId = null;
  this.ReservationId = null;
  this.SelectedHeaders = null;
  this.PaymentId = null;
  this.ClassCode = null;
  this.InvoiceNo = null;
  this.SearchStartDate = null;
  this.SearchEndDate = null;
  this.StartTime = null;
  this.EndTime = null;
  this.PaymentType = null;
  this.TransactionTypes = null;
  this.PaymentProcessor = null;
  this.PaymentRole = null;
  this.PaymentState = null;
  this.ReservationState = null;
  this.PersonId = null;
  this.ConfirmationKeys = null;
  this.PersonName = null;
  this.UnitNo = null;
  this.CreditCardNumber = null;
  this.MinAmount = null;
  this.MaxAmount = null;
  this.SelectedChildOrgUnitId = null;
  this.OptInXpress = null;
  this.OrderByColumn = null;
  this.ReservationCreationType = null;
  this.Reason = null;
  this.PageInfo = new PageInfo();
};
export default BillingSearchRequest;

import { getShutlleIconColor } from "../../../../shared/utility/enum-utility";
import { KeyValuePairModel } from "../../models/domain/key-value-pair-model";

export const ShuttleSearchViewModel = function (data) {
  if (!data) data = {};
  this.shuttleId = data.shuttleId || null;
  this.shuttleCode = data.shuttleCode || null;
  this.name = data.name || null;
  this.shortName = data.shortName || null;
  this.vin = data.vin || null;
  this.latitude = data.latitude || null;
  this.longitude = data.longitude || null;
  this.availability = new KeyValuePairModel(data.availability);
  this.engineStatus = new KeyValuePairModel(data.engineStatus);
  this.shuttleConnectionStatus = new KeyValuePairModel(
    data.shuttleConnectionStatus
  );
  this.shuttleInfo = data.shuttleInfo || null;
  this.locationDisplayName = data.locationDisplayName || null;
  this.locationCode = data.locationCode || null;
  this.combineStatusLabel = data.engineStatus
    ? (this.shuttleConnectionStatus.value || "--") +
      " / " +
      (this.engineStatus.value || "--")
    : data.shuttleConnectionStatus
    ? data.shuttleConnectionStatus.value
    : null;
  this.iconColor = getShutlleIconColor(
    this.shuttleConnectionStatus.key
      ? data.shuttleConnectionStatus.key
      : "",
    this.engineStatus.key ? this.engineStatus.key : ""
  );
};

import * as actionTypes from "../actions/actionTypes";
import { reducerUpdateObject } from "./reducer.utility";

const initialState = {
  assetInOutSummaryResponse: null,
  loading: false,
  error: null
};
const getAssetInOutSummaryStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
const getAssetInOutSummarySuccess = (state, action) => {
  return reducerUpdateObject(state, {
    assetInOutSummaryResponse: action.assetInOutSummaryResponse,
    error: null,
    loading: false
  });
};
const getAssetInOutSummaryFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false
  });
};
const getAssetInOutSummaryReset = (state, action) => {
  return reducerUpdateObject(state, { assetInOutSummaryResponse: null });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ASSET_IN_OUT_SUMMARY_START:
      return getAssetInOutSummaryStart(state, action);
    case actionTypes.GET_ASSET_IN_OUT_SUMMARY_SUCCESS:
      return getAssetInOutSummarySuccess(state, action);
    case actionTypes.GET_ASSET_IN_OUT_SUMMARY_FAIL:
      return getAssetInOutSummaryFail(state, action);
    case actionTypes.GET_ASSET_IN_OUT_SUMMARY_RESET:
      return getAssetInOutSummaryReset(state, action);

    default:
      return state;
  }
};
export default reducer;

import React, { Component } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Draggable from "react-draggable";
import { getMemberLabelByOrgRentalType } from "../../../shared/utility/system-utility";

const MemberVerification = (props) => {
  const doVerifyMember = () => {
    const req = {};
    req.MemberId = props.memberId;
    req.IsActionProfileVal = true;
    props.verifyMemberAccount(req);
  };

  return (
    <div>
      <Draggable handle=".cursor">
        <Modal
          isOpen={props.showModal}
          className="no-cursor"
          toggle={props.closeModal}
        >
          <ModalHeader
            className="modal-header bg-primary modal-title text-white cursor"
            toggle={props.closeModal}
          >
            <i className="fas fa-key" /> {getMemberLabelByOrgRentalType()}{" "}
            Verification
          </ModalHeader>
          <ModalBody>
            <div className="form-group row">
              <div className="FormRow col-sm-12">
                <div className="FormRow">
                  <div className={`form-group formRow`}>
                    <label className="col-sm-12 col-form-label font-weight-bold">
                      Are you sure you want to verify this{" "}
                      {getMemberLabelByOrgRentalType()}
                      's profile?
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button
              type="submit"
              name="btnSave"
              className="btn btn-primary waves-effect waves-light btnSize ml-2"
              onClick={doVerifyMember}
            >
              Submit
            </button>
            <button
              type="button"
              name="btnCancel"
              className="btn btn-secondary  waves-effect waves-light btnSize ml-2"
              onClick={props.closeModal}
            >
              Cancel
            </button>
          </ModalFooter>
        </Modal>
      </Draggable>
    </div>
  );
};

export default MemberVerification;

import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import TooltipItem from "../../components/tooltip/TooltipItem";
import PermissionName from "../../shared/Permissions";
import { ModalType } from "../../shared/GlobalVar";
import ModalEngine from "../../components/modal/modal-engine";
import ModalInfo from "../../services/entities/models/ModalInfo";
import {
  hasPermission,
  getDateTimeString,
  getNoteTooltipHtml,
  convertDateTimeString,
  convertDateTimeStringWithTimezone,
  getCurrentServerTimezone,
} from "../../shared/utility";
import { createTooltip } from "../../shared/utility/tooltip-utility";
export const SearchMessageGrid = (props) => {
  const [modalInfo, setModalInfo] = useState([new ModalInfo()]);
  const closeModal = () => {
    setModalInfo(new ModalInfo());
  };
  const hasViewMemberPermission = hasPermission(PermissionName.VIEW_MEMBER);
  const hasViewReservationPermission = hasPermission(
    PermissionName.VIEW_RESERVATION
  );
  const showModal = (modalData) => {
    setModalInfo({
      type: modalData.type,
      showModal: true,
      memberId: modalData.memberId,
      vehicleId: modalData.vehicleId,
    });
  };
  return (
    <Fragment>
      <div className="datagrid">
        <div className="column">
          <span className="column__header">Date & Time</span>
          <span className="column__header">From</span>
          <span className="column__header">To</span>
          <span className="column__header">Res. Code</span>
          <span className="column__header">Message</span>
        </div>
        {props.dataList.map((post, index) => {
          return (
            <div className="row" key={index}>
              <span className="row__column">
                {createTooltip(
                  convertDateTimeString(post.MessageDate),
                  convertDateTimeStringWithTimezone(
                    post.MessageDate,
                    getCurrentServerTimezone()
                  ),
                  { forceShow: true }
                )}
              </span>
              <span className="row__column">
                <label
                  className={`${hasViewMemberPermission ? "pseudo-link" : ""}`}
                  id={"mem_" + post.MemberId}
                  onClick={() =>
                    hasViewMemberPermission
                      ? showModal({
                          type: ModalType.MEMBER_QUICK_MODAL,
                          showModal: true,
                          memberId: post.MemberId,
                        })
                      : ""
                  }
                >
                  {createTooltip(post.MemberName, post.MemberName)}
                </label>
              </span>
              <span className="row__column" id={"createdForName_" + index}>
                {createTooltip(post.CreatedForName, post.CreatedForName)}
              </span>
              <span className="row__column position-relative">
                <Link
                  id={"confirmationCode_" + index}
                  className={`${
                    hasViewReservationPermission ? "" : "noAnchorLink"
                  }`}
                  to={{
                    pathname: "/reservationSummary",
                    search: "?reservationId=" + post.ReservationId,
                    state: { breadCrumbList: props.breadCrumbList },
                  }}
                >
                  {createTooltip(post.ConfirmationCode, post.ConfirmationCode)}
                </Link>
              </span>
              <span
                className="row__column"
                id={"messageDescriptionId_" + index}
              >
                {createTooltip(
                  post.MessageDescription,
                  post.MessageDescription
                )}
              </span>{" "}
            </div>
          );
        })}
      </div>
      {modalInfo.showModal ? (
        <ModalEngine
          modalType={modalInfo.type}
          showModal={modalInfo.showModal}
          closeModal={closeModal}
          memberId={modalInfo.memberId}
        />
      ) : null}
    </Fragment>
  );
};
export default SearchMessageGrid;

import SubMenu from "../model/sub-menu";
import SubMenuItem from "../model/sub-menu-item";
import KeyValuePair from "../../../services/entities/models/KeyValuePair";
import VehicleDetails from "../../../containers/vehicle/details/vehicle-details";
import VehicleAvaillability from "../../../containers/vehicle/details/vehicle-availlability";

export const getVehicleDetailsSubMenu = (vehicleId) => {
  const subMenu = new SubMenu();
  subMenu.menuKey = "vehicle_details";
  subMenu.subMenuItems.push(
    new SubMenuItem(
      "vehicle_profile",
      "Vehicle Details",
      "fas fa-car",
      VehicleDetails,
      vehicleId,
      [new KeyValuePair("vehicleId", vehicleId)]
    )
  );
  subMenu.subMenuItems.push(
    new SubMenuItem(
      "vehicle_Availability",
      "Availability",
      "fas fa-calendar-check",
      VehicleAvaillability,
      vehicleId,
      [new KeyValuePair("vehicleId", vehicleId)]
    )
  );
  return subMenu;
};

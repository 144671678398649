import * as actionTypes from "../actions/actionTypes";
import { reducerUpdateObject } from "./reducer.utility";

const initialState = {
  fleetUtilizationSummaryResponse: null,
  loading: false,
  error: null
};
const getFleetUtilizationSummaryStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
const getFleetUtilizationSummarySuccess = (state, action) => {
  return reducerUpdateObject(state, {
    fleetUtilizationSummaryResponse: action.fleetUtilizationSummaryResponse,
    error: null,
    loading: false
  });
};
const getFleetUtilizationSummaryFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false
  });
};
const getFleetUtilizationSummaryReset = (state, action) => {
  return reducerUpdateObject(state, { fleetUtilizationSummaryResponse: null });
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FLEET_UTILIZATION_SUMMARY_START:
      return getFleetUtilizationSummaryStart(state, action);
    case actionTypes.FLEET_UTILIZATION_SUMMARY_SUCCESS:
      return getFleetUtilizationSummarySuccess(state, action);
    case actionTypes.FLEET_UTILIZATION_SUMMARY_FAIL:
      return getFleetUtilizationSummaryFail(state, action);
    case actionTypes.FLEET_UTILIZATION_SUMMARY_RESET:
      return getFleetUtilizationSummaryReset(state, action);
    default:
      return state;
  }
};
export default reducer;

import React from "react";
import DefaultComponentWithMessage from "../../../components/common/pages/default-component-with-message";
import { COMMON_MSG } from "../../../shared/GlobalVar";
import {
  getPageName,
  getActualPageName,
} from "../../search/search-utility/search-utility";

const VehicleAvaillability = (props) => {
  const pageName = getPageName(props.match.url);
  return (
    <div>
      <DefaultComponentWithMessage
        msg={
          getActualPageName(pageName) +
          " " +
          COMMON_MSG.UnderConstructionMessage
        }
      />
    </div>
  );
};

export default VehicleAvaillability;

import PageInfo from "../../../../services/entities/models/PageInfo";
import { getCurrentServerDate, getCurrentServerTimezone } from "../../../../shared/utility";
export const getShuttleTripsSearchCtrls = () => {
  return {
    // isAdvancedSearch: false,
    PageInfo: new PageInfo(null, null, "tripCode", "DESC"),
    startDateTime: {
      id: "startDateTime",
      elementType: "dateandtimepicker",
      elementConfig: {
        name: "startDateTime",
        type: "text",
      },
      timePicker: {
        id: "StartTime",
        name: "StartTime",
        value: getCurrentServerDate("MM:DD:YYYY:HH:mm"),
        elementType: "timepicker",
      },
      validation: {
        required: false,
      },
      labelInput: {
        labeltext: "From",
      },
      timeZoneString: getCurrentServerTimezone(),
      value: getCurrentServerDate("MM:DD:YYYY:HH:mm"),
      valid: false,
      touched: false,
      basicOrder: 1,
      advancedOrder: 1,
    },
    endDateTime: {
      id: "endDateTime",
      elementType: "dateandtimepicker",
      elementConfig: {
        name: "endDateTime",
        type: "text",
      },
      timePicker: {
        id: "EndTime",
        name: "EndTime",
        value: "",
        elementType: "timepicker",
      },
      labelInput: {
        labeltext: "To",
      },
      timeZoneString: getCurrentServerTimezone(),
      value: "",
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      basicOrder: 6,
      advancedOrder: 6,
    },
    locationCode: {
      id: "locationCode",
      elementType: "select",
      elementConfig: {
        name: "locationCode",
        options: [{ key: "", displayValue: "Any Location" }],
      },
      labelInput: {
        labeltext: "Location",
      },
      value: "",
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      basicOrder: 2,
      advancedOrder: 2,
    },
    shuttleCode: {
      id: "shuttleCode",
      elementType: "select",
      elementConfig: {
        name: "shuttleCode",
        options: [{ key: "", displayValue: "Select Shuttle" }],
      },
      labelInput: {
        labeltext: "Shuttle Code",
      },
      value: "",
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      basicOrder: 3,
      advancedOrder: 3,
    },
    badgeNo: {
      id: "badgeNo",
      elementType: "select",
      elementConfig: {
        name: "badgeNo",
        options: [{ key: "", displayValue: "Select Driver" }],
      },
      labelInput: {
        labeltext: "Driver",
      },
      value: "",
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      basicOrder: 4,
      advancedOrder: 4,
    },
    empty1: {
      id: "empty",
      elementType: "empty",
      elementConfig: {
        name: "",
        type: "empty",
      },
      basicOrder: 7,
      advancedOrder: 7,
    },
    empty2: {
      id: "empty",
      elementType: "empty",
      elementConfig: {
        name: "",
        type: "empty",
      },
      basicOrder: 8,
      advancedOrder: 8,
    },
    empty3: {
      id: "empty",
      elementType: "empty",
      elementConfig: {
        name: "",
        type: "empty",
      },
      basicOrder: 9,
      advancedOrder: 9,
    },
    btnResSearch: {
      id: "btnResSearch",
      elementType: "button",
      elementConfig: {
        type: "submit",
        value: "Search",
        name: "btnResSearch",
        className: "btn btn-primary search-btn",
      },
      basicOrder: 5,
      advancedOrder: 5,
    },
    btnResSearchReset: {
      id: "btnResSearchReset",
      elementType: "button",
      elementConfig: {
        type: "button",
        value: "Reset",
        name: "btnResSearchReset",
        className: "btn btn-secondary search-btn",
      },
      basicOrder: 10,
      advancedOrder: 10,
    },
  };
};

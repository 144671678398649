import * as actionTypes from "../actions/actionTypes";
import { reducerUpdateObject } from "./reducer.utility";
const initialState = {
  searchActivitiesResponse: null,
  addActivityResponse: null,
  resendCreditGrantEmailResponse: null,
  loading: false,
  error: null,
};

const searchActivitiesStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
const searchActivitiesSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    searchActivitiesResponse: action.searchActivitiesResponse,
    error: null,
    loading: false,
  });
};
const searchActivitiesFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false,
  });
};
const searchActivitiesReset = (state) => {
  return reducerUpdateObject(state, {
    searchActivitiesResponse: null,
  });
};

const addActivityStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
const addActivitySuccess = (state, action) => {
  return reducerUpdateObject(state, {
    addActivityResponse: action.addActivityResponse,
    error: null,
    loading: false,
  });
};
const addActivityFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false,
  });
};
const addActivityReset = (state) => {
  return reducerUpdateObject(state, {
    addActivityResponse: null,
  });
};

const resendCreditGrantEmailStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
const resendCreditGrantEmailSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    resendCreditGrantEmailResponse: action.resendCreditGrantEmailResponse,
    error: null,
    loading: false,
  });
};
const resendCreditGrantEmailFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false,
  });
};
const resendCreditGrantEmailReset = (state) => {
  return reducerUpdateObject(state, {
    resendCreditGrantEmailResponse: null,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SEARCH_ACTIVITIES_START:
      return searchActivitiesStart(state, action);
    case actionTypes.SEARCH_ACTIVITIES_SUCCESS:
      return searchActivitiesSuccess(state, action);
    case actionTypes.SEARCH_ACTIVITIES_FAIL:
      return searchActivitiesFail(state, action);
    case actionTypes.SEARCH_ACTIVITIES_RESET:
      return searchActivitiesReset(state);

    case actionTypes.ADD_ACTIVITY_START:
      return addActivityStart(state, action);
    case actionTypes.ADD_ACTIVITY_SUCCESS:
      return addActivitySuccess(state, action);
    case actionTypes.ADD_ACTIVITY_FAIL:
      return addActivityFail(state, action);
    case actionTypes.ADD_ACTIVITY_RESET:
      return addActivityReset(state);

    case actionTypes.RESEND_CREDIT_GRANT_EMAIL_START:
      return resendCreditGrantEmailStart(state, action);
    case actionTypes.RESEND_CREDIT_GRANT_EMAIL_SUCCESS:
      return resendCreditGrantEmailSuccess(state, action);
    case actionTypes.RESEND_CREDIT_GRANT_EMAIL_FAIL:
      return resendCreditGrantEmailFail(state, action);
    case actionTypes.RESEND_CREDIT_GRANT_EMAIL_RESET:
      return resendCreditGrantEmailReset(state);
    default:
      return state;
  }
};
export default reducer;

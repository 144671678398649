import React from 'react';
import { Modal } from "reactstrap";
import CommonModal from './common-modal';

function ModernModal(props) {
    const {
        showModal,
        closeModal,
        modalClassName,
        modalMinWidth = "372px",
        modalMaxWidth = "372px",
    } = props;
    return (
        <Modal
            isOpen={showModal}
            className="no-cursor modern-modal"
            modalClassName={modalClassName}
            toggle={() => {
                closeModal();
            }}
            style={{ minWidth: modalMinWidth, modalMaxWidth: modalMaxWidth }}
        >
            <CommonModal {...props} />
        </Modal>
    )
}

export default ModernModal
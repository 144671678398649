import React, { Component } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);

class Chart extends Component {
  componentDidMount() {
    this.populateOrgdData(
      this.props.chartData ? this.props.chartData.UtilizationSummary : null
    );
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      (this.props.chartData &&
        !prevProps.chartData &&
        this.props.chartData.UtilizationSummary) ||
      (this.props.chartData &&
        prevProps.chartData &&
        this.props.chartData.UtilizationSummary &&
        this.props.chartData.UtilizationSummary[0].Title !==
          prevProps.chartData.UtilizationSummary[0].Title)
    ) {
      this.populateOrgdData(this.props.chartData.UtilizationSummary);
    }
  }
  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }
  populateOrgdData = (data) => {
    let graphMaxY = 100;
    if (document.getElementById("chartdiv")) {
      let chart = am4core.create("chartdiv", am4charts.XYChart);
      chart.logo.disabled = true;
      chart.paddingRight = 20;
      chart.data = data;
      let maxVal = 0;
      if (data) {
        data.forEach((element) => {
          if (maxVal < element.UtilizationPercentage) {
            maxVal = element.UtilizationPercentage;
          }
        });
      }
      graphMaxY = maxVal > 100 ? 100 : maxVal + 0.5;
      let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.title.text = "Date";
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.dataFields.category = "Title";
      categoryAxis.renderer.minGridDistance = 30;
      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.title.text = "Utilization";
      valueAxis.min = 0;
      valueAxis.max = graphMaxY;
      valueAxis.strictMinMax = true;
      valueAxis.renderer.labels.template.adapter.add("text", function (text) {
        return text + "%";
      });
      valueAxis.renderer.minGridDistance = 30;
      let series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.categoryX = "Title";
      series.dataFields.valueY = "UtilizationPercentage";
      series.columns.template.tooltipText = `[bold]Date : {categoryX}[/]
    [bold]Total Vehicles: {TotalNumberOfVehicle}
    [bold]Total Rentals: {TotalReservationCount}
    [bold]Total Rented Out: {TotalRentedMinutes}
    [bold]Utilization: {UtilizationPercentage}%
    `;
      series.tooltip.pointerOrientation = "vertical";
      series.columns.template.tooltipY = 0;
      series.columns.template.strokeWidth = 2;
      series.columns.template.strokeOpacity = 1;
      series.columns.template.fillOpacity = 0.8;
      if (localStorage.getItem("loginMode")) {
        series.columns.template.stroke = am4core.color("#C52529");
        series.columns.template.fill = am4core.color("#C52529");
      } else {
        series.columns.template.stroke = am4core.color("#98CCFD");
        series.columns.template.fill = am4core.color("#98CCFD");
      }

      // as by default columns of the same series are of the same color, we add adapter which takes colors from chart.colors color set
      // series.columns.template.adapter.add("fill", function(fill, target) {
      //   return chart.colors.getIndex(target.dataItem.index);
      // });
      this.chart = chart;
    }
  };

  render() {
    return <div id="chartdiv" style={{ width: "100%", height: "30rem" }} />;
  }
}
export default Chart;

import React, { useState, useEffect, Fragment, useRef } from "react";
import { Link } from "react-router-dom";
import { getTimeOnlyString, hasPermission } from "../../../shared/utility";
import {
  getLocalSortingClassName,
  getSortType,
  doSorting,
} from "../../../shared/utility/sorting-utility";
import PermissionName from "../../../shared/Permissions";
import NoRecordFoundComponent from "../../../components/common/pages/no-record-found";
import { createTooltip } from "../../../shared/utility/tooltip-utility";
import { getMemberLabelByOrgRentalType } from "../../../shared/utility/system-utility";

export const AssetInOutSummary = (props) => {
  const summaryData = props.assetSummaryData
    ? props.summaryType === "OUT_SUMMARY"
      ? props.assetSummaryData.OutRentalSummary || []
      : props.assetSummaryData.InRentalSummary || []
    : null;
  const [sortingInfo, setSortingInfo] = useState({
    SortBy: "StartDate",
    SortType: "ASC",
    DataType: "date",
  });
  const [sortedSummaryData, setSortedSummaryData] = useState(null);

  useEffect(() => {
    if (summaryData != null && summaryData.length > 0) {
      const clonedSummaryData = JSON.parse(JSON.stringify(summaryData));
      doSorting(sortingInfo, clonedSummaryData);
      setSortedSummaryData(clonedSummaryData);
      props.setSortIndicator();
    }
  }, [summaryData]);

  useEffect(() => {
    if(sortedSummaryData != null){
      doSorting(sortingInfo, sortedSummaryData);
      props.setSortIndicator();
    }
  }, [sortingInfo.SortBy, sortingInfo.SortType, sortingInfo.DataType]);

  const prepareSorting = (sortBy, dataType) => {
    let sortingInfoDummy = { ...sortingInfo };
    sortingInfoDummy.SortBy = sortBy;
    sortingInfoDummy.DataType = dataType;
    sortingInfoDummy.SortType = getSortType(sortingInfo, sortBy);
    setSortingInfo(sortingInfoDummy);
  };

  const renderAssetInOutSummaryList = () => {
    if (sortedSummaryData && sortedSummaryData.length > 0) {
      return sortedSummaryData.map((summaryObj, index) => {
        return (
          <tr key={summaryObj.StartDate + index} className="position-relative">
            <td>
              <Link
                className={`${
                  hasPermission(PermissionName.VIEW_RESERVATION)
                    ? ""
                    : "noAnchorLink"
                }`}
                to={{
                  pathname: "/reservationSummary",
                  search: "?reservationId=" + summaryObj.ConfirmationKey,
                  state: { breadCrumbList: props.breadCrumbList },
                }}
              >
                {createTooltip(
                  summaryObj.ConfirmationKey || "--",
                  summaryObj.ConfirmationKey || ""
                )}
              </Link>
            </td>
            <td>
              {createTooltip(
                summaryObj.BorrowerName || "--",
                summaryObj.BorrowerName || ""
              )}
            </td>
            <td>
              {createTooltip(
                summaryObj.Category || "--",
                summaryObj.Category || ""
              )}
            </td>
            <td className="text-left">
              {createTooltip(
                summaryObj.PickupLocation || "--",
                summaryObj.PickupLocation || ""
              )}
            </td>

            <td>{getTimeOnlyString(summaryObj.StartDate)}</td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colSpan="5">
            <NoRecordFoundComponent
              className="border-none"
              innerClassName="no-record-found-normal"
              searchMsg="No Record Found"
              heights="2rem"
            />
          </td>
        </tr>
      );
    }
  };
  const getSummaryTitle = () => {
    let summaryTitle;
    if (props.assetSummaryData) {
      if (props.summaryType === "OUT_SUMMARY") {
        summaryTitle =
          props.assetSummaryData.OutRentalSummary &&
          props.assetSummaryData.OutRentalSummary.length > 0
            ? "Reservations Scheduled to Start Today (" +
              props.assetSummaryData.OutRentalSummary.length +
              ")"
            : "Reservations Scheduled to Start Today (None)";
      } else {
        summaryTitle =
          props.assetSummaryData.InRentalSummary &&
          props.assetSummaryData.InRentalSummary.length > 0
            ? "Rentals Returning Today (" +
              props.assetSummaryData.InRentalSummary.length +
              ")"
            : "Rentals Returning Today ( None )";
      }
    }
    return summaryTitle;
  };
  return (
    <Fragment>
      <div className="topHeader">
        <h3 className="heading-tertiary">{getSummaryTitle()}</h3>
      </div>
      <div
        className="table-fixed-head tableScroll"
        style={{
          maxHeight: "15rem",
        }}
      >
        <table
          className="table table-striped table-hover"
          style={{ tableLayout: "fixed" }}
        >
          <thead className="thead-primary">
            <tr>
              <th
                style={{ width: "20%" }}
                className={
                  getLocalSortingClassName(sortingInfo, "ConfirmationKey") +
                  " position-relative"
                }
                onClick={() => prepareSorting("ConfirmationKey", "string")}
              >
                {props.summaryType === "OUT_SUMMARY"
                  ? "Reservation Code"
                  : "Rental Code"}
              </th>
              <th
                style={{ width: "20%" }}
                className={
                  getLocalSortingClassName(sortingInfo, "BorrowerName") +
                  " position-relative"
                }
                onClick={() => prepareSorting("BorrowerName", "string")}
              >
                {getMemberLabelByOrgRentalType("Name")}
              </th>
              <th
                style={{ width: "15%" }}
                className={
                  getLocalSortingClassName(sortingInfo, "Category") +
                  " position-relative"
                }
                onClick={() => prepareSorting("Category", "string")}
              >
                Type
              </th>
              <th
                style={{ width: "20%" }}
                className={
                  getLocalSortingClassName(sortingInfo, "PickupLocation") +
                  " position-relative"
                }
                onClick={() => prepareSorting("PickupLocation", "string")}
              >
                Location
              </th>
              <th
                style={{ width: "25%" }}
                className={
                  getLocalSortingClassName(sortingInfo, "StartDate") +
                  " position-relative"
                }
                onClick={() => prepareSorting("StartDate", "date")}
              >
                {props.summaryType === "OUT_SUMMARY"
                  ? "Leaving Time"
                  : "Return Time"}
              </th>
            </tr>
          </thead>
          <tbody>{renderAssetInOutSummaryList()}</tbody>
        </table>
      </div>
    </Fragment>
  );
};
export default AssetInOutSummary;

import * as actionTypes from "./actionTypes";
import HttpService, { invokeServerAPI } from "../../services/httpService";
import { FoxRequestHeader } from "../../services/entities/fox-api/domain/fox-request-header";

export const getFleetClassGroupStart = () => {
  return {
    type: actionTypes.GET_FLEET_CLASS_GROUP_START,
  };
};

export const getFleetClassGroupSuccess = (response) => {
  return {
    type: actionTypes.GET_FLEET_CLASS_GROUP_SUCCESS,
    orgUnitFleetClassGroupsResponse: response,
  };
};

export const getFleetClassGroupFail = (error) => {
  return {
    type: actionTypes.GET_FLEET_CLASS_GROUP_FAIL,
    error: error,
  };
};

export const getFleetClassGroupReset = (error) => {
  return {
    type: actionTypes.GET_FLEET_CLASS_GROUP_RESET,
    error: error,
  };
};

export const getFleetClassGroup = (req) => {
  return (dispatch) => {
    req.requestHeader= new FoxRequestHeader({requestType:"GET"});
    dispatch(getFleetClassGroupStart());
    invokeServerAPI("/admin/options", req, true)
      .then((response) => {
        dispatch(getFleetClassGroupSuccess(response, "/admin/options"));
      })
      .catch((err) => {
        //dispatch(getFleetClassGroupFail(err));
      });
  };
};




/**Get Fleet Override Reasons */
export const getFleetOverrideReasonsStart = () => {
  return {
    type: actionTypes.GET_FLEET_OVERRIDE_REASONS_START,
  };
};

export const getFleetOverrideReasonsSuccess = (response) => {
  return {
    type: actionTypes.GET_FLEET_OVERRIDE_REASONS_SUCCESS,
    fleetOverrideReasonsResponse: response,
  };
};

export const getFleetOverrideReasonsFail = (error) => {
  return {
    type: actionTypes.GET_FLEET_OVERRIDE_REASONS_FAIL,
    error: error,
  };
};

export const getFleetOverrideReasonsReset = (error) => {
  return {
    type: actionTypes.GET_FLEET_OVERRIDE_REASONS_RESET,
    error: error,
  };
};

export const getFleetOverrideReasons = (req) => {
  return (dispatch) => {
    req.requestHeader= new FoxRequestHeader({requestType:"GET"});
    dispatch(getFleetOverrideReasonsStart());
    invokeServerAPI("/admin/options", req, true)
      .then((response) => {
        dispatch(getFleetOverrideReasonsSuccess(response, "/admin/options"));
      })
      .catch((err) => {
        //dispatch(getFleetOverrideReasonsFail(err));
      });
  };
};
import * as actionTypes from "../actions/actionTypes";
import { reducerUpdateObject } from "./reducer.utility";

const initialState = {
  securityDepositResponse: null,
  error: null,
  loading: false,
};
const getSecurityDepositChargesStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
const getSecurityDepositChargesSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    securityDepositResponse: action.securityDepositResponse,
    error: null,
    loading: false,
  });
};
const getSecurityDepositChargesFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false,
  });
};
const getSecurityDepositChargesReset = (state, action) => {
  return reducerUpdateObject(state, { securityDepositResponse: null });
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SECURITY_DEPOSIT_POLICY_START:
      return getSecurityDepositChargesStart(state, action);
    case actionTypes.SECURITY_DEPOSIT_POLICY_SUCCESS:
      return getSecurityDepositChargesSuccess(state, action);
    case actionTypes.SECURITY_DEPOSIT_POLICY_FAIL:
      return getSecurityDepositChargesFail(state, action);
    case actionTypes.SECURITY_DEPOSIT_POLICY_RESET:
      return getSecurityDepositChargesReset(state, action);

    default:
      return state;
  }
};
export default reducer;

import React from 'react';
import GeneralRateRow from '../general-rate-row';

function TaxesFeesBlock({ taxes, surcharges, extensionFee, damageCharges, totalTaxesFees }) {
    return (
        <>
            <div className='tax-body'>
                {taxes.map((data) => {
                    return data?.amount && <GeneralRateRow key={data?.desc.replace(/\s+/g, '')} title={data?.desc} rate={data?.rate} total={data?.amount} chargeClasses="font-bold" />
                })}
                {surcharges.map((data) => {
                    return <GeneralRateRow key={data?.desc} title={data?.desc.replace(/\s+/g, '')} rate={data?.rate} total={data?.amount} chargeClasses="font-bold" />
                })}
                {extensionFee && <GeneralRateRow key="extension-fee" title="Extension Fees" total={extensionFee} chargeClasses="font-bold" />}
                {damageCharges && <GeneralRateRow key="damage-charge" title="Damage Charges" total={damageCharges} chargeClasses="font-bold" />}

            </div>
            <GeneralRateRow
                key="total-balance-due"
                contClasses="total-estimated-charge modern-total-bg"
                titleClasses="font-bold fs-6"
                title="Total"
                chargeClasses="font-bold fs-6"
                total={totalTaxesFees}
            />
        </>
    )
}

export default TaxesFeesBlock
import PageInfo from "../../../services/entities/models/PageInfo";
import moment from "moment";
import {
  getCurrentServerDate,
  getCurrentServerTimezone,
} from "../../../shared/utility";
export const getFleetutilizationForecastSearchCtrls = () => {
  let timeFormat = "MM:DD:YYYY:HH:mm";
  return {
    PageInfo: new PageInfo(null, null, "forecastDate", "ASC"),
    periodicFilterInfo:{
      sortType:"ASC",
      recordsPerPeriod:14,
    },
    forecastStartDate: {
      id: "forecastStartDate",
      elementType: "datepicker",
      elementConfig: {
        name: "forecastStartDate",
        type: "text",
      },
      validation: {
        required: false,
      },
      labelInput: {
        labeltext: "Start Date",
      },
      timeZoneString: getCurrentServerTimezone(),
      value: moment(
        moment(getCurrentServerDate(timeFormat), "MM-DD-YYYY HH:mm")).format(timeFormat),
      valid: false,
      touched: false,
      basicOrder: 1,
      advancedOrder: 1,
    },
    forecastEndDate: {
      id: "forecastEndDate",
      elementType: "datepicker",
      elementConfig: {
        name: "forecastEndDate",
        type: "text",
        disabled:true,
      },
      validation: {
        required: false,
      },
      labelInput: {
        labeltext: "End Date",
      },
      timeZoneString: getCurrentServerTimezone(),
      value: moment(
        moment(getCurrentServerDate(timeFormat), "MM-DD-YYYY HH:mm").add(
          90,
          "days"
        )
      ).format(timeFormat),
      valid: false,
      touched: false,
      basicOrder: 6,
      advancedOrder: 6,
    },
    locationId: {
      id: "locationId",
      elementType: "select",
      elementConfig: {
        name: "SelectedChildOrgUnitId",
        options: [{ key: "", displayValue: "Any Location" }],
      },
      labelInput: {
        labeltext: "Location",
      },
      value: "",
      validation: {
        required: true,
      },
      valid: false,
      touched: false,
      basicOrder: 2,
      advancedOrder: 2,
    },
    orgUnitFleetClassGroupId: {
      id: "orgUnitFleetClassGroupId",
      elementType: "select",
      elementConfig: {
        name: "orgUnitFleetClassGroupId",
        options: [{ key: "", displayValue: "Any Location" }],
      },
      labelInput: {
        labeltext: "Fleet Class Group",
      },
      value: "",
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      basicOrder: 3,
      advancedOrder: 3,
    },
    pause: {
      id: "pause",
      elementType: "select",
      elementConfig: {
        name: "pause",
        disabled:true,
        options: [
          { key: "", displayValue: "Select" },
          { key: "true", displayValue: "Yes" },
          { key: "false", displayValue: "No" },
        ],
      },
      labelInput: {
        labeltext: "Pause",
      },
      value: "",
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      basicOrder: 7,
      advancedOrder: 7,
    },
    btnResSearch: {
      id: "btnResSearch",
      elementType: "button",
      elementConfig: {
        type: "submit",
        value: "Search",
        name: "btnResSearch",
        className: "btn btn-primary search-btn",
      },
      basicOrder: 4,
      advancedOrder: 4,
    },
    btnResSearchReset: {
      id: "btnResSearchReset",
      elementType: "button",
      elementConfig: {
        type: "button",
        value: "Reset",
        name: "btnResSearchReset",
        className: "btn btn-secondary search-btn",
      },
      basicOrder: 9,
      advancedOrder: 9,
    },
    searchViewType: {
      id: "searchViewType",
      elementType: "select",
      elementConfig: {
        name: "searchViewType",
        options: [
          { key: "CALENDAR_VIEW", displayValue: "Calendar View" },
          { key: "TABULAR_VIEW", displayValue: "Tabular View" },
        ],
      },
      labelInput: {
        labeltext: "Search View Type",
      },
      value: "CALENDAR_VIEW",
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      basicOrder: 7,
      advancedOrder: 7,
    },
    empty: {
      id: "empty",
      elementType: "empty",
      elementConfig: {
        name: "",
        type: "empty",
      },
      basicOrder: 5,
      advancedOrder: 5,
    },
    empty2: {
      id: "empty",
      elementType: "empty",
      elementConfig: {
        name: "",
        type: "empty",
      },
      basicOrder: 10,
      advancedOrder: 10,
    },
  };
};

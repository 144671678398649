import moment from "moment";
import { convertFoxApiDateToStr } from "../../../containers/Reservation/fox-reservation/fox-res-helper";
import { QuickHistoryType } from "../../../shared/GlobalVar";
import { getFullName, getFormattedPhoneNumber } from "../../../shared/utility";
import { getDateTimeFormatString } from "../../../components/common/common-helper";

export const QuickHistoryViewModel = function (data) {
  if (!data) data = {};
  this.Id = data.Id || null;
  this.Name = data.Name || null;
  this.AccessTime = convertFoxApiDateToStr(moment(), getDateTimeFormatString(true, false, true), "--");
  this.MembershipId = data.MembershipId || null;
  this.EmailAddress = data.EmailAddress || null;
  this.MobilePhone = data.MobilePhone || null;
  this.MobilePhoneTooltip = data.MobilePhoneTooltip || null;
  this.ReservationCode = data.ReservationCode || null;
  this.ReservationId = data.ReservationId || null;
  this.ExternalCode = data.ExternalCode || null;
  this.ReservationState = data.ReservationState || null;
  this.StartDate = data.StartDate || null;
  this.EndDate = data.EndDate || null;
  this.PickupLocation = data.PickupLocation || null;
  this.TimezoneString = data.TimezoneString || null;  
};

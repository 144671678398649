import Moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import momentLocalizer from "react-widgets-moment";
import SearchPageContainer from "../client/containers/search/search-page-container";
import VehicleDetails from "../client/containers/vehicle/details/vehicle-details";
import { clearCache } from "../client/shared/utility/cache-utility";
import MemberProfile from "./containers/Member/member-profile/member-profile";
import EndRental from "./containers/Reservation/end-rental/end-rental";
//import FoxReservation from "./containers/Reservation/fox-reservation/fox-reservation";
import FoxResSummary from "./containers/Reservation/fox-reservation/fox-summary-details/fox-res-summary-details";
import Reservation from "./containers/Reservation/fox-reservation/reservation";
import Logout from "./containers/auth/logout/Logout";
import BirdsEyeView from "./containers/birds-eye-view/birds-eye-view";
import Dashboard from "./containers/dashboard/Dashboard";
import ReserveSync from "./containers/fleet-utilization/search/reserve-sync";
import LandmarkDetailsInfo from "./containers/landmarks/details/landmark-details";
import PartnerInformation from "./containers/partner-info/partner-info";
import Shuttle from "./containers/shuttles/shuttle";
import UserProfile from "./containers/user/logged-in-user-profile";
import Layout from "./hoc/Layout/Layout";
import asyncComponent from "./hoc/asyncComponent/asyncComponent";
import MemberSubMenu from "./hoc/sub-menu/member-submenu/member-submenu";
import ReportSubMenu from "./hoc/sub-menu/report-submenu/report-submenu";
import ReservationsDetailsSubMenu from "./hoc/sub-menu/reservation-details-submenu/reservation-details-submenu";
import SettingsSubMenu from "./hoc/sub-menu/settings-submenu/settings-submenu";
import VehicleDetailsSubMenu from "./hoc/sub-menu/vehicle-details-submenu/vehicle-details-submenu";
import "./sass/main.scss";
import * as actions from "./store/actions/index";

const asyncAuth = asyncComponent(() => {
  return import("./containers/auth/Auth");
});
Moment.locale("en-US");
momentLocalizer();

class App extends Component {
  state = {
    loginMode: "",
    currentTheme: "blue",
  };
  componentDidMount() {
    clearCache();
    this.props.onTryAutoSignup();
  }
  componentDidUpdate() {
    if (this.state.loginMode !== localStorage.getItem("loginMode")) {
      this.setState({ loginMode: localStorage.getItem("loginMode") });
      if (localStorage.getItem("loginMode") === "IMPERSONATE") {
        document.body.style.setProperty("--color-primary", "#731414");
        document.body.style.setProperty("--color-primary-light", "#9d3135");
        document.body.style.setProperty("--color-primary-light-1", "#731414");
        document.body.style.setProperty("--color-topmenu-main", "#9d3135");
        document.body.style.setProperty(
          "--color-topmenu-main-light",
          "#C52529"
        );
        document.body.style.setProperty("--color-topmenu-main-dark", "#731414");

        document.body.style.setProperty("--color-mainmenu", "#9d3135");
        document.body.style.setProperty("--color-mainmenu-1", "#9d3135");
        document.body.style.setProperty("--color-mainmenu-2", "#731414");
        document.body.style.setProperty("--color-quicklink-hover-1", "#C52529");

        document.body.style.setProperty("--table-Header-1", "#9d3135");
        document.body.style.setProperty("--table--border-1", "#731414");
        document.body.style.setProperty("--random-color-1", "#820000");

        document.body.style.setProperty("--btn-cb-back-1", "#9d3135");
        document.body.style.setProperty("--btn-cb-border-1", "#9d3135");

        document.body.style.setProperty("--btn-back-1", "#731414");
        document.body.style.setProperty("--btn-border-1", "#820000");
        document.body.style.setProperty("--btn-hover-1", "#820000");
      } else {
        document.body.style.setProperty("--color-primary", "#1e4e9c");
        document.body.style.setProperty("--color-primary-light", "#6ea4fc");
        document.body.style.setProperty("--color-primary-light-1", "#486cbf");
        document.body.style.setProperty("--color-topmenu-main", "#296ddb");
        document.body.style.setProperty(
          "--color-topmenu-main-light",
          "#296ddb"
        );
        document.body.style.setProperty("--color-topmenu-main-dark", "#136aa8");

        document.body.style.setProperty("--color-mainmenu", "#7293c8");
        document.body.style.setProperty("--color-mainmenu-1", "#3b5b92");
        document.body.style.setProperty("--color-mainmenu-2", "#16486c");
        document.body.style.setProperty("--color-quicklink-hover-1", "#486cbf");

        document.body.style.setProperty("--table-Header-1", "#1e4e9c");
        document.body.style.setProperty("--table--border-1", "#0376f1");
        document.body.style.setProperty("--random-color-1", "#147bc4");

        document.body.style.setProperty("--btn-cb-back-1", "#1e4e9c");
        document.body.style.setProperty("--btn-cb-border-1", "#1e4e9c");

        document.body.style.setProperty("--btn-back-1", "#0062cc");
        document.body.style.setProperty("--btn-border-1", "#005cbf");
        document.body.style.setProperty("--btn-hover-1", "#0069d9");
      }
    }
  }
  render() {
    let routes = (
      <Switch>
        <Route path="/auth" component={asyncAuth} />
        <Redirect to="/auth" />
      </Switch>
    );
    if (this.props.isAuthenticated) {
      routes = (
        <Switch>
          <Route path="/logout" component={Logout} />
          <Route path="/auth" component={asyncAuth} />
          <Route path="/userProfile" component={UserProfile} />
          <Route path="/member/:title" component={MemberSubMenu} />
          <Route path="/setting/:title" component={SettingsSubMenu} />
          <Route path="/member" component={SearchPageContainer} />
          <Route path="/vehicle" component={SearchPageContainer} />
          <Route path="/rental" component={SearchPageContainer} />
          <Route path="/billing" component={SearchPageContainer} />
          <Route path="/reservation" component={SearchPageContainer} />
          <Route path="/rolesPermission" component={SearchPageContainer} />
          <Route path="/shuttles" component={SearchPageContainer} />
          <Route path="/landmarks" component={SearchPageContainer} />
          <Route path="/reserve-sync" component={ReserveSync} />
          <Route path="/landmark" component={LandmarkDetailsInfo} />
          <Route path="/partnerInfo" component={PartnerInformation} />
          <Route path="/report/:title" component={ReportSubMenu} />
          <Route path="/newMember" component={MemberProfile} />
          <Route path="/newVehicle" component={VehicleDetails} />
          <Route path="/complaints" component={SearchPageContainer} />
          <Route path="/implementations" component={SearchPageContainer} />
          <Route path="/reservationSummary" component={FoxResSummary} />
          <Route path="/birdseyeview" component={BirdsEyeView} />
          <Route path="/updateReservation" component={Reservation} />
          <Route path="/endRental" component={EndRental} />
          <Route path="/shuttle" component={Shuttle} />
          <Route
            path="/reservation_details/:title"
            component={ReservationsDetailsSubMenu}
          />
          <Route
            path="/vehicle_details/:title"
            component={VehicleDetailsSubMenu}
          />
          <Route path="/Dashboard" component={Dashboard} />
          <Route path="/" component={Dashboard} />
          <Redirect to="/" />
        </Switch>
      );
    }
    return <Layout>{routes}</Layout>;
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.token !== null,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onTryAutoSignup: () => dispatch(actions.authCheckState()),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));

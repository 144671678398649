import React from "react";
import { ModalType } from "../../../shared/GlobalVar";
import DamageModal from "./damage-modal";
import CancelReservationModal from "./cancel-reservation-modal";
import LateChargeConfirmModal from "../end-rental/modal/late-charge-confirm-modal";
import VehicleQuickModal from "../../vehicle/modal/vehicle-quick-modal";
import MemberQuickModal from "../../Member/modal/member-quick-modal";
export const UpdateReservationModalEngine = (props) => {
  let modalResponse = null;
  if (props.modalType === ModalType.DAMAGE_MODAL) {
    modalResponse = (
      <DamageModal
        showModal={props.showModal}
        closeModal={props.closeModal}
        defaultData={props.defaultData}
        resetParentPage={props.resetParentPage}
      />
    );
  } else if (
    props.modalType === ModalType.CANCEL_RESERVATION_MODAL ||
    props.modalType === ModalType.CANCEL_REASON_UPDATE_MODAL
  ) {
    modalResponse = (
      <CancelReservationModal
        modalTitle={props.modalTitle}
        showModal={props.showModal}
        closeModal={props.closeModal}
        defaultData={props.defaultData}
        resetParentPage={props.resetParentPage}
      />
    );
  } else if (props.modalType === ModalType.LATE_CHARGE_CONFIRM_MODAL) {
    modalResponse = (
      <LateChargeConfirmModal
        doLateChargeFunctionality={props.doLateChargeFunctionality}
        showModal={props.showModal}
        closeModal={props.closeModal}
      />
    );
  } else if (props.modalType === ModalType.MEMBER_QUICK_MODAL) {
    modalResponse = (
      <MemberQuickModal
        showModal={props.showModal}
        closeModal={props.closeModal}
        memberId={props.memberId}
      />
    );
  }
  if (props.modalType === ModalType.VEHICLE_QUICK_MODAL) {
    modalResponse = (
      <VehicleQuickModal
        vehicleId={props.vehicleId}
        showModal={props.showModal}
        closeModal={props.closeModal}
      />
    );
  }
  return modalResponse;
};
export default UpdateReservationModalEngine;

import React, { useState } from "react";
import ReactHtmlParser from "react-html-parser";
import {
  getDateTimeString,
  getNoteTooltipHtml,
} from "../../../shared/utility";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import Draggable from "react-draggable";
import EyeIcon from "../../../components/UI/EyeIcon/eyeIcon";
import TooltipItem from "../../../components/tooltip/TooltipItem";
import { LabelAndValuePairControl } from "../../../components/UI/Input/label-value-pair-control";

export const ChannelQuickModal = (props) => {
  const channelModalData = props.defaultData;
  const [channelQuickModalState, setChannelQuickModalState] = useState({
    maskedValue: "XXXXXXXXXXXXXXXX",
    encryptionKey: "XXXXXXXXXXXXXXXX",
    licenseKey: "XXXXXXXXXXXXXXXX",
    primaryAuthenticationId: "XXXXXXXXXXXXXXXX",
    encryptionKeyTooltip: null,
    licenseKeyTooltip: null,
    primaryAuthenticationIdTooltip: null,
  });
  const characterHideShowToggleForLabel = (returnedValue, labelId) => {
    let updateCtrl = { ...channelQuickModalState };
    updateCtrl.encryptionKey =
      labelId === "encryptionKey" ? returnedValue : updateCtrl.encryptionKey;
    updateCtrl.licenseKey =
      labelId === "licenseKey" ? returnedValue : updateCtrl.licenseKey;
    updateCtrl.primaryAuthenticationId =
      labelId === "primaryAuthenticationId"
        ? returnedValue
        : updateCtrl.primaryAuthenticationId;

    updateCtrl.encryptionKeyTooltip =
      labelId === "encryptionKey"
        ? getTooltipTextForSensitiveField(labelId, returnedValue)
        : updateCtrl.encryptionKeyTooltip;
    updateCtrl.encryptionKeyTooltip =
      labelId === "encryptionKey"
        ? getTooltipTextForSensitiveField(labelId, returnedValue)
        : updateCtrl.encryptionKeyTooltip;
    updateCtrl.licenseKeyTooltip =
      labelId === "licenseKey"
        ? getTooltipTextForSensitiveField(labelId, returnedValue)
        : updateCtrl.licenseKeyTooltip;
    updateCtrl.primaryAuthenticationIdTooltip =
      labelId === "primaryAuthenticationId"
        ? getTooltipTextForSensitiveField(labelId, returnedValue)
        : updateCtrl.primaryAuthenticationIdTooltip;
    setChannelQuickModalState(updateCtrl);
  };
  const getTooltipTextForSensitiveField = (labelId, returnedValue) => {
    return channelQuickModalState.maskedValue !== returnedValue ? (
      <TooltipItem
        placement="left"
        toolTipId={labelId}
        renderableHtml={ReactHtmlParser(getNoteTooltipHtml(returnedValue))}
      />
    ) : (
      ""
    );
  };
  const renderLabelInputPair = (labelCaption, value) => {
    return (
      <LabelAndValuePairControl
        elementConfig={{ value: value }}
        otherConfig={{
          caption: labelCaption,
        }}
        parentRowClass="popup-row"
        labelClasses="font-weight-bold"
      />
    );
  };
  const renderLabelValueWithEyeIcon = (
    labelCaption,
    originalValue,
    modalValue,
    modalValueTooltip,
    modalMaskedValue,
    labelID
  ) => {
    return (
      <div className="col-lg-12 p-01 row">
        <span className="font-weight-bold col-lg-5 p-01">
          {labelCaption}
          {originalValue ? (
            <EyeIcon
              characterHideShowToggleForLabel={(returnedValue, labelId) =>
                characterHideShowToggleForLabel(returnedValue, labelId)
              }
              maskedValue={modalMaskedValue}
              originalValue={originalValue}
              labelID={labelID}
              customClass="fs-3"
            />
          ) : (
            ""
          )}
        </span>
        <label className="p-01 pr-2 col-lg-7 overflowCommon" id={labelID}>
          {originalValue ? modalValue : "--"}
          {modalValueTooltip ? modalValueTooltip : ""}
        </label>
      </div>
    );
  };
  return (
    <Draggable handle=".cursor">
      <Modal
        isOpen={props.showModal}
        className="no-cursor"
        style={{ minWidth: "100rem" }}
        toggle={() => {
          props.closeModal();
        }}
      >
        <ModalHeader
          className="modal-header bg-primary modal-title text-white cursor"
          toggle={() => {
            props.closeModal();
          }}
        >
          <i className="fas fa-globe pr-2"></i>
          Channel Quick View
        </ModalHeader>
        <ModalBody
          className=""
          style={{
            minHeight: "400px",
          }}
        >
          <div className="row col-lg-12 p-01" id="channelQuickViewModal">
            <div className="col-lg-12 row">
              <div className="col-lg-4 underLine p-01">
                <span className="font-weight-bold">
                  Partner Confirmation Key
                </span>
                <span className="pl-4">
                  {channelModalData.PartnerConfirmationKey}
                </span>
              </div>
              <div className="col-lg-4 underLine p-01 text-center">
                <span className="font-weight-bold">Source</span>
                <span className="col-lg-7" id="memQuickViewEmployee">
                  {channelModalData.Source}
                </span>
              </div>
              <div className="col-lg-4 underLine p-01 text-right">
                <span className="font-weight-bold">API License ID</span>
                <span className="col-lg-7">
                  {channelModalData.ApiLicense.ApiLicenseId}
                </span>
              </div>
            </div>
            <div className="col-lg-6">
              <h4 className="form-header text-uppercase col-lg-12 p-01">
                <i className="fas fa-user-circle"></i>Partner Information
              </h4>
              {renderLabelInputPair(
                "Allowed IPs",
                channelModalData.Partner.AllowedIps
              )}
              {renderLabelInputPair(
                "Allowed Pay Type",
                channelModalData.Partner.AllowedPayType
              )}
              {renderLabelInputPair(
                "Card Required",
                String(channelModalData.Partner.CardRequired)
              )}
              {renderLabelInputPair(
                "Client Reservation Allowed",
                String(channelModalData.Partner.ClientReservationAllowed) ||
                  "--"
              )}
              {renderLabelInputPair(
                "Company ID",
                channelModalData.Partner.CompanyId
              )}
              {renderLabelInputPair(
                "Confirmation Key Prefix",
                channelModalData.Partner.ConfKeyPrefix
              )}
              {renderLabelInputPair(
                "Customer Max Reservations",
                channelModalData.Partner.CustomerMaxReservations
              )}
              {renderLabelInputPair(
                "Display Name",
                channelModalData.Partner.DisplayName
              )}
              {renderLabelInputPair("IATA", channelModalData.Partner.Iata)}
              {renderLabelInputPair("Name", channelModalData.Partner.Name)}
              {renderLabelInputPair(
                "Partner Code",
                channelModalData.Partner.PartnerCode
              )}
              {renderLabelInputPair(
                "Partner ID",
                channelModalData.Partner.PartnerId
              )}
              {renderLabelInputPair(
                "Restricted IPs",
                channelModalData.Partner.RestrictedIps
              )}
              {renderLabelInputPair(
                "Return Confirm Key Type",
                channelModalData.Partner.ReturnConfirmKeyType
              )}
              {renderLabelInputPair(
                "Show Fox Confirmation Key",
                String(channelModalData.Partner.ShowFoxConfirmationKey)
              )}
              {renderLabelInputPair(
                "Source Code",
                channelModalData.Partner.SourceCode
              )}
              <h4 className="form-header text-uppercase col-lg-12 p-01">
                <i className="fas fa-user-circle"></i>Partner Contact
                Information
              </h4>
              {renderLabelInputPair(
                "Contact Name",
                channelModalData.Partner.ContactName
              )}
              {renderLabelInputPair(
                "Contact Email",
                channelModalData.Partner.ContactEmail
              )}
              {renderLabelInputPair(
                "Contact Phone",
                channelModalData.Partner.ContactPhone
              )}
              {renderLabelInputPair(
                "Contact Title",
                channelModalData.Partner.ContactTitle
              )}
              {renderLabelInputPair(
                "Company Address",
                channelModalData.Partner.CompanyAddress
              )}
              {renderLabelInputPair(
                "Company Country",
                channelModalData.Partner.CompanyCountry
              )}
              {renderLabelInputPair(
                "Company State",
                channelModalData.Partner.CompanyState
              )}
              {renderLabelInputPair(
                "Company City",
                channelModalData.Partner.CompanyCity
              )}
              {renderLabelInputPair(
                "Company Zip Code",
                channelModalData.Partner.CompanyZipCode
              )}
            </div>

            <div className="col-lg-6">
              <h4 className="form-header text-uppercase col-lg-12 p-01">
                <i className="fas fa-user-circle"></i>Provider Information
              </h4>

              {renderLabelInputPair(
                "Deployment Mode",
                channelModalData.Provider.DeploymentMode
              )}
              {renderLabelInputPair(
                "Display Order",
                channelModalData.Provider.DisplayOrder
              )}
              {renderLabelInputPair(
                "Integration Provider ID",
                channelModalData.Provider.IntegProvId
              )}
              {renderLabelInputPair("Name", channelModalData.Provider.Name)}
              {renderLabelInputPair(
                "Password",
                channelModalData.Provider.Password ? "*****" : "--"
              )}
              {renderLabelInputPair(
                "Provider Status",
                channelModalData.Provider.ProviderStatus
              )}
              {renderLabelInputPair("URL", channelModalData.Provider.URL)}
              {renderLabelInputPair(
                "User Name",
                channelModalData.Provider.UserName
              )}

              <h4 className="form-header text-uppercase col-lg-12 p-01">
                <i className="fas fa-user-circle"></i>API License Information
              </h4>

              {renderLabelInputPair(
                "Company Address",
                channelModalData.ApiLicense.CompanyAddress
              )}
              {renderLabelInputPair(
                "Contact Person Email",
                channelModalData.ApiLicense.ContactPersonEmail
              )}
              {renderLabelInputPair(
                "Contact Person Name",
                channelModalData.ApiLicense.ContactPersonName
              )}
              {renderLabelInputPair(
                "Contact Person Phone",
                channelModalData.ApiLicense.ContactPersonPhone
              )}
              {renderLabelInputPair(
                "Contact Person Title",
                channelModalData.ApiLicense.ContactPersonTitle
              )}
              {renderLabelValueWithEyeIcon(
                "Encryption Key",
                channelModalData.ApiLicense.EncryptionKey,
                channelQuickModalState.encryptionKey,
                channelQuickModalState.encryptionKeyTooltip,
                channelQuickModalState.maskedValue,
                "encryptionKey"
              )}
              {renderLabelInputPair(
                "Expiration Key On",
                getDateTimeString(channelModalData.ApiLicense.ExpirationKeyOn)
              )}
              {renderLabelInputPair(
                "Expiration On",
                getDateTimeString(channelModalData.ApiLicense.ExpirationOn)
              )}
              {renderLabelValueWithEyeIcon(
                "License Key",
                channelModalData.ApiLicense.LicenseKey,
                channelQuickModalState.licenseKey,
                channelQuickModalState.licenseKeyTooltip,
                channelQuickModalState.maskedValue,
                "licenseKey"
              )}
              {renderLabelInputPair("Notes", channelModalData.ApiLicense.Notes)}
              {renderLabelValueWithEyeIcon(
                "Primary Authentication ID",
                channelModalData.ApiLicense.PrimaryAuthenticationId,
                channelQuickModalState.primaryAuthenticationId,
                channelQuickModalState.primaryAuthenticationIdTooltip,
                channelQuickModalState.maskedValue,
                "primaryAuthenticationId"
              )}
              {renderLabelInputPair(
                "Renewed On",
                getDateTimeString(channelModalData.ApiLicense.RenewedOn)
              )}
            </div>
          </div>
        </ModalBody>
      </Modal>
    </Draggable>
  );
};
export default ChannelQuickModal;

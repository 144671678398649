import React, { useEffect, useState } from "react";
import Draggable from "react-draggable";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, ModalBody, ModalHeader, Spinner } from "reactstrap";
import { showNotification } from "../../../components/UI/Toaster/Toaster";
import * as global from "../../../shared/GlobalVar";
import Permissions from "../../../shared/Permissions";
import {
  convertDateTimeString,
  formatAmountValue,
  getBasicToastResponse,
  hasPermission
} from "../../../shared/utility";
import {
  doCaptureReleaseRefundReset,
  getTransactionList,
  getTransactionListReset,
} from "../../../store/actions/index";
import { getDataforCaptureRefundReleaseModal } from "../billing-helper";
import CaptureReleaseRefundModal from "./capture-release-refund-modal";
export const TransactionListModal = (props) => {
  const loading = useSelector((state) => state.transactionReducer.loading);
  const transactionResponse = useSelector(
    (state) => state.transactionReducer.transactionResponse
  );
  const dispatch = useDispatch();
  const reservationId = props.reservationId;
  const [transactionList, setTransactionList] = useState([]);
  const [
    showCaptureReleaseRefundModal,
    setShowCaptureReleaseRefundModal,
  ] = useState(false);
  const [
    captureReleaseRefundModalData,
    setCaptureReleaseRefundModalData,
  ] = useState(null);

  useEffect(() => {
    initTransactionList();
  }, []);

  const initTransactionList = () => {
    dispatch(doCaptureReleaseRefundReset());
    dispatch(getTransactionListReset());
    const getTransactionListReq = {};
    getTransactionListReq.ReservationId = reservationId;
    getTransactionListReq.ReservationState = global.BILLING_RESERVATION_STATES;
    getTransactionListReq.Ascending = false;
    dispatch(getTransactionList(getTransactionListReq));
  };
  useEffect(() => {
    if (!transactionResponse) {
      return;
    }
    if (transactionResponse && transactionResponse.StatusCode === 0) {
      if (
        transactionResponse.Payments &&
        transactionResponse.Payments.length > 0
      ) {
        setTransactionList(transactionResponse.Payments || []);
      } else {
        var toastMsg = {
          msg: "No Transaction Records Found.",
          toastType: global.TOAST_MSG.WARNING,
        };
        showNotification(toastMsg);
        props.closeModal();
      }
    } else {
      showNotification(getBasicToastResponse(transactionResponse));
    }
    dispatch(getTransactionListReset());
  }, [transactionResponse]);
  const getCaptureReleaseRefundModal = (paymentInfo, newTransactionType) => {
    setCaptureReleaseRefundModalData(
      getDataforCaptureRefundReleaseModal(
        paymentInfo,
        newTransactionType,
        "RESERVATION_SUMMARY_TRANSACTIONS"
      )
    );
    setShowCaptureReleaseRefundModal(true);
  };
  const closeCaptureReleaseRefundModal = (isParentClose) => {
    setShowCaptureReleaseRefundModal(false);
    if (isParentClose) {
      props.closeModal();
    }
  };
  const renderList = () => {
    return transactionList.map((transactionObj, index) => {
      return (
        <tr
          key={
            transactionObj.InvoiceId +
            "_" +
            transactionObj.TransactionType +
            "_" +
            index
          }
        >
          <td>
            <label
              className="line-clamp text-left break-word"
              style={{ width: "6rem" }}
              title={transactionObj.InvoiceId}
            >
              {transactionObj.InvoiceId}
            </label>
          </td>
          <td>
            <label
              className="line-clamp text-left break-word"
              style={{ width: "6rem" }}
              title={convertDateTimeString(transactionObj.PaymentDate)}
            >
              {convertDateTimeString(transactionObj.PaymentDate)}
            </label>
          </td>
          <td>
            <label
              className="line-clamp text-left"
              style={{ width: "7rem" }}
              title={transactionObj.TransactionId}
            >
              {transactionObj.TransactionId}
            </label>
          </td>
          <td>
            <label
              className="line-clamp text-left  break-word"
              style={{ width: "6rem" }}
              title={transactionObj.CreatedByName}
            >
              {transactionObj.CreatedByName}
            </label>
          </td>
          <td>
            <label
              className="line-clamp text-left  break-word"
              style={{ width: "6rem" }}
              title={transactionObj.BillingName}
            >
              {transactionObj.BillingName}
            </label>
          </td>
          <td>{transactionObj.PaymentTypeDisplay}</td>
          <td className="position-relative text-left">
            <label className="text-left pr-4" title={transactionObj.TransactionTypeDisplay}>
              {transactionObj.TransactionTypeDisplay}
            </label>
            <span
              className="filled-circle cursor-default"
              title={
                transactionObj.PaymentRole === "MANUAL_PAYMENT"
                  ? "Record Payment"
                  : "Process Payment"
              }
              style={{ right: "0.25rem", background: "#b3a5a5" }}
            >
              {transactionObj.PaymentRole === "MANUAL_PAYMENT" ? "R" : "P"}
            </span>
          </td>
          <td className="text-center">
            <i
              className={
                transactionObj.PaymentRole === "MANUAL_PAYMENT" &&
                  (transactionObj.PaymentProcessor === "CREDIT_CARD" ||
                    transactionObj.PaymentProcessor === "DEBIT_CARD" ||
                    transactionObj.CreditCardType)
                  ? "fas fa-credit-card mic-ico-transaction border-right-none pr-1"
                  : "fas fa-hand-holding-usd displayNone"
              }
            />
            <span style={{ width: "5.5rem" }}>
              {transactionObj.PaymentProcessorDisplay}
            </span>
          </td>
          <td>{transactionObj.PaymentStateDisplay}</td>
          <td>
            {transactionObj.Amount &&
              transactionObj.TransactionType !== "PRE_AUTHORIZATION"
              ? formatAmountValue(transactionObj.Amount)
              : "--"}
          </td>
          <td>
            {transactionObj.Amount &&
              transactionObj.TransactionType === "PRE_AUTHORIZATION"
              ? formatAmountValue(transactionObj.Amount)
              : "--"}
          </td>
          <td>
            {transactionObj.TotalCapturedAmount
              ? formatAmountValue(transactionObj.TotalCapturedAmount)
              : "--"}
          </td>
          <td>
            {transactionObj.TotalRefundAmount
              ? formatAmountValue(transactionObj.TotalRefundAmount)
              : "--"}
          </td>
          <td>
            {transactionObj.TotalFreeCredits
              ? formatAmountValue(transactionObj.TotalFreeCredits)
              : "--"}
          </td>
          <td>
            {transactionObj.BalanceDue
              ? formatAmountValue(transactionObj.BalanceDue)
              : "--"}
          </td>
          <td>
            <label
              className="line-clamp text-left break-word"
              style={{ width: "10rem" }}
              title={transactionObj.ReasonDisplay}
            >
              {transactionObj.ReasonDisplay}{" "}
            </label>
          </td>
          <td>
            <label
              className="line-clamp text-left break-word"
              style={{ width: "10rem" }}
              title={transactionObj.Comments}
            >
              {transactionObj.Comments}
            </label>
          </td>
          <td style={{ padding: 0 }}>
            {transactionObj.ShowCapture &&
              hasPermission(Permissions.CAPTURE_PAYMENT) ? (
              <Button
                color="primary"
                type="button"
                className="m-1"
                onClick={(event) =>
                  getCaptureReleaseRefundModal(transactionObj, "CAPTURE")
                }
              >
                Charge
              </Button>
            ) : (
              ""
            )}
            {transactionObj.ShowRefund &&
              hasPermission(Permissions.REFUND_PAYMENT) ? (
              <Button
                color="primary"
                type="button"
                className="m-1"
                onClick={(event) =>
                  getCaptureReleaseRefundModal(transactionObj, "REFUND")
                }
              >
                Refund
              </Button>
            ) : (
              ""
            )}
            {transactionObj.ShowRelease &&
              hasPermission(Permissions.RELEASE_PAYMENT) ? (
              <Button
                color="primary"
                type="button"
                className="m-1"
                onClick={(event) =>
                  getCaptureReleaseRefundModal(transactionObj, "VOID")
                }
              >
                {transactionObj.TransactionType === "PRE_AUTHORIZATION"
                  ? "Release"
                  : "Void"}
              </Button>
            ) : (
              ""
            )}
          </td>
        </tr>
      );
    });
  };

  return transactionList.length > 0 ? (
    <Draggable handle=".cursor">
      <Modal
        isOpen={props.showModal}
        className="no-cursor"
        toggle={() => {
          props.closeModal();
        }}
        style={{ minWidth: "1370px" }}
      >
        <ModalHeader
          className="modal-header bg-primary modal-title text-white cursor"
          toggle={() => {
            props.closeModal();
          }}
        >
          <i className="fas fa-arrows-alt" /> Transaction List
        </ModalHeader>
        <ModalBody
          style={{ maxHeight: "600px", overflow: "auto" }}
          className="tableScroll"
        >
          <table className="table table-striped">
            <thead className="thead-primary">
              <tr>
                <th>
                  Invoice <br />
                  ID
                </th>
                <th>
                  Transaction <br /> Date
                </th>
                <th>
                  Transaction <br /> ID
                </th>
                <th>
                  Charged <br />
                  By
                </th>
                <th>
                  Billing <br />
                  Name
                </th>
                <th>
                  Payment <br />
                  Type
                </th>
                <th>
                  Transaction <br />
                  Type
                </th>
                <th>
                  Payment <br />
                  Method
                </th>
                <th>Status</th>
                <th>
                  Amount <br />
                  Paid
                </th>
                <th>
                  Pre- <br />
                  Authorization
                </th>
                <th>
                  Capture <br />
                  Amount
                </th>
                <th>
                  Refund <br />
                  Amount
                </th>
                <th>
                  Free <br />
                  Credits
                </th>
                <th>
                  Balance <br />
                  Due
                </th>
                <th>
                  Refund <br />
                  Reason
                </th>
                <th>Comments</th>
                <th />
              </tr>
            </thead>
            <tbody>{renderList()}</tbody>
          </table>
          {showCaptureReleaseRefundModal ? (
            <CaptureReleaseRefundModal
              showModal={showCaptureReleaseRefundModal}
              closeModal={closeCaptureReleaseRefundModal}
              defaultData={captureReleaseRefundModalData}
            />
          ) : (
            ""
          )}
        </ModalBody>
      </Modal>
    </Draggable>
  ) : loading ? (
    <Spinner />
  ) : (
    ""
  );
};
export default TransactionListModal;

import React, { useState, useEffect, Fragment } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Draggable from "react-draggable";
import { validateBlackListedImport } from "../../settingsHelper";
import { TOAST_MSG } from "../../../../shared/GlobalVar";
import { showNotification } from "../../../../components/UI/Toaster/Toaster";
import { AddQuickMemberRequest } from "../../../../services/entities/requests/AddQuickMemberRequest";
import ConfirmModal from "../../../../components/common/modal/confirm-modal";
import { createTooltip } from "../../../../shared/utility/tooltip-utility";
export const BlackListedExportModal = (props) => {
  const [dataList, setDataList] = useState([]);
  const [rowInfo, setRowInfo] = useState({
    totalRows: 0,
    validRows: 0,
    invalidRows: 0,
  });
  const [cbAllCalssName, setCbAllCalssName] = useState("fa-null");
  const [checkboxHeaderTooltip, setCheckboxHeaderTooltip] = useState(
    "Select All"
  );
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  useEffect(() => {
    let validRows = 0;
    let invalidRows = 0;
    const newDataList = [];
    let totalRows = props.importedXLData.length;
    props.importedXLData.map((data) => {
      const newData = { ...data };
      let isValidRow = validateBlackListedImport(data);
      if (isValidRow) {
        newData.checked = true;
        validRows = validRows + 1;
      } else {
        newData.checked = false;
        invalidRows = invalidRows + 1;
      }
      newDataList.push(newData);
    });
    setRowInfo({
      totalRows: totalRows,
      validRows: validRows,
      invalidRows: invalidRows,
    });
    if (totalRows === validRows) {
      setCbAllCalssName("fa-check");
      setCheckboxHeaderTooltip("Deselect All");
    } else if (totalRows && validRows && invalidRows) {
      setCbAllCalssName("fa-minus");
      setCheckboxHeaderTooltip("Select All");
    } else if (totalRows === invalidRows) {
      setCbAllCalssName("fa-null");
      setCheckboxHeaderTooltip("Nothing to Select");
    }
    setDataList([...newDataList]);
  }, []);
  const selectAllRows = () => {
    if (rowInfo.totalRows === rowInfo.invalidRows) {
      return false;
    }
    const newDataList = JSON.parse(JSON.stringify(dataList));
    newDataList.map((data, index) => {
      let isValidRow = validateBlackListedImport(data);
      if (isValidRow) {
        newDataList[index].checked =
          cbAllCalssName === "fa-check" || cbAllCalssName === "fa-minus"
            ? false
            : true;
      }
    });
    if ((rowInfo.totalRows)-rowInfo.invalidRows === rowInfo.validRows) {
      let cbAllCalssNameNew =
      cbAllCalssName === "fa-check" || cbAllCalssName === "fa-minus"
        ? "fa-null"
        : rowInfo.totalRows > rowInfo.validRows ? "fa-minus" :"fa-check";
      setDataList(newDataList);
      setCbAllCalssName(cbAllCalssNameNew);
      setCheckboxHeaderTooltip(
        cbAllCalssNameNew === "fa-check" ? "Deselect All" : "Select All"
      );
    } else if (rowInfo.totalRows && rowInfo.invalidRows && rowInfo.validRows) {
      setDataList(newDataList);
      setCbAllCalssName("fa-minus");
      setCheckboxHeaderTooltip("Select All");
    }
  };

  const selectSingleRows = (index, columnData) => {
    const newDataList = JSON.parse(JSON.stringify(dataList));
    let cbAllCalssNameNew = "";
    let checkedValue = 0;
    newDataList.map((data, i) => {
      if (index === i) {
        newDataList[i].checked = columnData.checked ? false : true;
        data.checked = columnData.checked ? false : true;
      }
      if (data.checked) {
        checkedValue++;
      }
    });
    if (checkedValue === 0) {
      cbAllCalssNameNew = "fa-null";
    } else if (checkedValue === rowInfo.totalRows) {
      cbAllCalssNameNew = "fa-check";
    } else {
      cbAllCalssNameNew = "fa-minus";
    }
    setDataList([...newDataList]);
    setCbAllCalssName(cbAllCalssNameNew);
    setCheckboxHeaderTooltip(
      cbAllCalssNameNew === "fa-check" ? "Deselect All" : "Select All"
    );
  };

  const getHeaderData = () => {
    const header = Object.keys(dataList[0]).map((columnHead, i) => {
      if (i === 0) {
        return (
          <Fragment>
            <th>
              <div
                className="checkBoxRolesPermissionsAll rolesAllCheckBox"
                onClick={() => selectAllRows()}
                id="rolesAllCheckBoxId"
              >
                {createTooltip(
                  <i
                    className={`fa checkBoxRolesPermisisonsFont ${cbAllCalssName}`}
                    aria-hidden="true"
                    style={{ marginRight: "0", marginTop: "0.2rem" }}
                    >&nbsp;</i>,
                  checkboxHeaderTooltip,
                  { forceShow: true,ttcClass:"tooltipContainer-max width-max-content",considerParent:true  }
                )}
              </div>
            </th>
            {columnHead != "checked" ? <th>{columnHead}</th> : ""}
          </Fragment>
        );
      } else if (columnHead != "checked") {
        return <th>{columnHead}</th>;
      }
    });
    return header;
  };
  const getCellData = () => {
    const renderableRows = dataList.map((columnData, index) => {
      let inValidCLassName = "";
      let isValidRow = validateBlackListedImport(columnData);
      if (!validateBlackListedImport(columnData)) {
        inValidCLassName = "text-danger";
      }
      return (
        <tr className={inValidCLassName}>
          {Object.keys(columnData).map((cellNme, i) => {
            if (i === 0) {
              return (
                <Fragment>
                  <td>
                    <div
                      className="icheck-primary roles-cb"
                      onChange={() => selectSingleRows(index, columnData)}
                    >
                      <input
                        type="checkbox"
                        name={"cb" + columnData[cellNme]}
                        id={"cb" + columnData[cellNme]}
                        disabled={!isValidRow}
                        checked={columnData.checked}
                      />
                      <label htmlFor={"cb" + columnData[cellNme]} />
                    </div>
                  </td>
                  {cellNme != "checked" ? <td>{columnData[cellNme]}</td> : ""}
                </Fragment>
              );
            } else if (cellNme != "checked") {
              return <td>{columnData[cellNme]}</td>;
            }
          })}
        </tr>
      );
    });
    return renderableRows;
  };
  const onImportBlackListedPersons = () => {
    const importedData = JSON.parse(JSON.stringify(dataList));
    const blackList = [];
    importedData.map((newData, index) => {
      if (validateBlackListedImport(newData)) {
        const blackListData = new AddQuickMemberRequest(newData);
        blackList.push(blackListData);
      }
    });
    if (blackList.length > 0) {
      props.doImportFunctionality(blackList);
    } else {
      var toastMsg = {
        msg: "Nothing to add.",
        toastType: TOAST_MSG.ERROR,
      };
      showNotification(toastMsg);
    }
  };
  const onCLoseModal = () => {
    if (rowInfo.validRows) {
      setShowConfirmModal(true);
    } else {
      props.closeModal();
    }
  };
  const closeConfirmModal = () => {
    setShowConfirmModal(false);
  };
  return (
    <Draggable handle=".cursor">
      <Modal
        isOpen={props.showModal}
        className="no-cursor"
        toggle={() => {
          props.closeModal();
        }}
        style={{ minWidth: "1100px" }}
      >
        <ModalHeader
          className="modal-header bg-primary modal-title text-white cursor"
          toggle={() => {
            props.closeModal();
          }}
        >
          <i className="fas fa-key" /> Black Listed Import
        </ModalHeader>
        <ModalBody>
         <div className="mb-2">
              <b>Total Imported Records: {rowInfo.totalRows}</b>
              <br />
              <b>Valid Records: {rowInfo.validRows}</b>
              <br />
              <b>Invalid Records: {rowInfo.invalidRows}</b><br />
              <b>
                Incorrect records are not selected and marked as red.
              </b>
          </div>
          <div
            className="col-lg-12 tableScroll bb-0 table-fixed-head p-0 table-container"
            style={{ maxHeight: "40rem", overflow: "auto" }}
          >
            <table className="table table-striped" id="rolesTable">
              <thead className="thead-primary">
                <tr>{dataList.length > 0 ? getHeaderData() : ""}</tr>
              </thead>
              <tbody>{dataList.length > 0 ? getCellData() : ""}</tbody>
            </table>
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            className="btn btn-primary waves-effect waves-light btnSize ml-2"
            onClick={() => onImportBlackListedPersons()}
            disabled={rowInfo.totalRows === rowInfo.invalidRows ? true : false}
          >
            Save
          </button>
          <button
            type="button"
            className="btn btn-secondary  waves-effect waves-light btnSize ml-2"
            onClick={() => onCLoseModal()}
          >
            Cancel
          </button>
        </ModalFooter>
        {showConfirmModal ? (
          <ConfirmModal
            body="All imported data will be lost. Do you want to continue?"
            showModal={showConfirmModal}
            closeModal={closeConfirmModal}
            doConfirmFunctionality={props.closeModal}
          />
        ) : (
          ""
        )}
      </Modal>
    </Draggable>
  );
};
export default BlackListedExportModal;

import React from "react";
import Draggable from "react-draggable";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import QRCode from "qrcode.react";
import { printCanvasPhoto } from "../../../shared/utility";
const MemberQrCodeModal = (props) => {
  const qrCodePrint = () => {
    window.print();
  };
  return (
    <Draggable handle=".cursor">
      <Modal
        isOpen={props.showModal}
        className="no-cursor"
        style={{ minWidth: "37rem" }}
        toggle={() => {
          props.closeModal();
        }}
      >
        <ModalHeader
          className="modal-header bg-primary modal-title text-white cursor"
          toggle={() => {
            props.closeModal();
          }}
        >
          <i className="fas fa-id-card pr-2"></i>
          Badge #
        </ModalHeader>
        <ModalBody
          style={{
            height: "auto",
          }}
        >
          <div className="col-lg-12 p-0 text-center mb-3">
            <button
              type="button"
              name="btnChangeAddress"
              className="btn btn-primary waves-effect waves-light btnSize ml-2"
              // onClick={() => qrCodePrint()}
              onClick={() => printCanvasPhoto("divPrintbody")}
            >
              print
            </button>
          </div>
          <div className="col-lg-12 p-0 text-center">
          <QRCode value={props.badgeNo +", "+ props.fullName} id="divPrintbody" />,
          </div>
        </ModalBody>
      </Modal>
    </Draggable>
  );
};

export default MemberQrCodeModal;

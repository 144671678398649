import React, { Fragment, useEffect, useState } from "react";
import Draggable from "react-draggable";
import { useDispatch, useSelector } from "react-redux";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { LabelAndValuePairControl } from "../../../components/UI/Input/label-value-pair-control";
import { showNotification } from "../../../components/UI/Toaster/Toaster";
import * as global from "../../../shared/GlobalVar";
import PermissionName from "../../../shared/Permissions";
import {
  getBasicToastResponse,
  hasPermission,
  updateObject,
} from "../../../shared/utility";
import {
  editConfirmationCode,
  editConfirmationCodeReset,
} from "../../../store/actions/index";

export const ExternalParameterUpdateModal = (props) => {
  const reservationData = props.defaultData;
  const dispatch = useDispatch();
  const [readOnly, setReadOnly] = useState(true);
  const editConfirmationCodeResponse = useSelector(
    (state) => state.reservationReducer.editConfirmationCodeResponse
  );
  const [ediConfirmCodeReq, setEdiConfirmCodeReq] = useState({});

  const [eyeIconInputType, setEyeIconInputType] = useState("password");
  useEffect(() => {
    setEdiConfirmCodeReq({
      ReservationId: reservationData.reservationId,
      OldConfirmationCode: reservationData.confirmationCode,
      NewConfirmationCode: reservationData.confirmationCode,
      OldIntegConfirmNumber: reservationData.integConfirmNumber,
      NewIntegConfirmNumber: reservationData.integConfirmNumber,
      OptInXpress: reservationData.optInXpress,
      VoucherAdded: reservationData.voucherAdded,
      VoucherAmount: reservationData.voucherAmount,
      Password: null,
    });
  }, []);
  useEffect(() => {
    if (!editConfirmationCodeResponse) {
      return;
    }
    showNotification(getBasicToastResponse(editConfirmationCodeResponse));
    if (editConfirmationCodeResponse.StatusCode === 0) {
      dispatch(editConfirmationCodeReset());
      props.resetParentPage();
      props.closeModal();
    }
    dispatch(editConfirmationCodeReset());
  }, [editConfirmationCodeResponse]);
  const disablePasswordBox = () => {
    if (
      ediConfirmCodeReq.OldIntegConfirmNumber !==
      ediConfirmCodeReq.NewIntegConfirmNumber ||
      ediConfirmCodeReq.OptInXpress !== reservationData.optInXpress
    ) {
      return false;
    }
    return true;
  };
  const handleOnChange = (event, controlName, elementType) => {
    let updateCtrls = { ...ediConfirmCodeReq };
    const value =
      elementType === "checkbox" ? event.target.checked : event.target.value;
    updateCtrls = updateObject(updateCtrls, {
      [controlName]: value,
    });
    if (controlName === "VoucherAmount" || controlName === "VoucherAdded") {
      if (controlName === "VoucherAdded") {
        if (!value) {
          updateCtrls.VoucherAmount = null;
        } else {
          updateCtrls.VoucherAmount = reservationData.balanceDue;
        }
      } else if (reservationData.VoucherAmount !== value) {
        updateCtrls.VoucherAdded = true;
        reservationData.VoucherAmount = value;
      }
    }
    if (controlName === "NewIntegConfirmNumber") {
      setReadOnly(updateCtrls.NewIntegConfirmNumber ? "" : true);
    }
    setEdiConfirmCodeReq(updateCtrls);
  };

  const onFormSubmit = () => {
    if (
      !ediConfirmCodeReq.Password &&
      (reservationData.integConfirmNumber !==
        ediConfirmCodeReq.NewIntegConfirmNumber ||
        ediConfirmCodeReq.OptInXpress !== reservationData.optInXpress)
    ) {
      var toastMsg = {
        msg: "Password is mandatory.",
        toastType: global.TOAST_MSG.ERROR,
      };
      showNotification(toastMsg);
    } else {
      dispatch(editConfirmationCodeReset());
      dispatch(editConfirmationCode(ediConfirmCodeReq));
    }
  };

  const handleOnMouseOver = (event) => {
    setReadOnly("");
  };

  const renderLabelInputPair = (
    labelCaption,
    controlName,
    value,
    viewOnly = false,
    nodeElement = "input"
  ) => {
    const isCheckbox = nodeElement === "checkbox";
    const valueOrCheckedAttribute = isCheckbox ? "checked" : "value";
    const isPassword = controlName === "Password";
    const disabledProps = isPassword ? { disabled: disablePasswordBox() } : {};
    const isRANum = controlName === "NewIntegConfirmNumber";
    return (
      <LabelAndValuePairControl
        elementConfig={{
          name: controlName,
          type: isPassword ? eyeIconInputType : isCheckbox ? "checkbox" : controlName === "VoucherAmount" ? "number" : null,
          [valueOrCheckedAttribute]: value,
          ...disabledProps,
        }}
        otherConfig={{
          caption: labelCaption,
          viewOnly: viewOnly,
          isEyeIconRequired: isPassword,
          isHideLabel: isCheckbox,
          checkBoxCaptionClass: isCheckbox ? "font-weight-bold" : "",
        }}
        isEditMode={true}
        onChange={handleOnChange}
        onMouseOver={isRANum ? handleOnMouseOver : null}
        onMouseLeave={isRANum ? handleOnChange : null}
        toggleInputTypeForPasswordField={isPassword ? setEyeIconInputType : ""}
        parentRowClass={`form-group row`}
        labelClasses={"col-sm-4 col-form-label font-weight-bold"}
        valueClasses={`col-sm-8`}
        nodeElement={nodeElement}
      />
    );
  };

  const renderData = () => {
    const hasPermissionToSeePartnerCode = reservationData
      ? hasPermission(PermissionName.VIEW_INTEGRATION_INFO) &&
        reservationData.partnerConfirmationKey
        ? true
        : false
      : false;
    return (
      <div className="col-lg-12 p-0">
        {renderLabelInputPair(
          "Reservation Code",
          "ConfirmationCode",
          reservationData.confirmationCode,
          true
        )}
        {hasPermissionToSeePartnerCode
          ? renderLabelInputPair(
            "Integrator Res. Code",
            "PartnerConfirmationKey",
            reservationData.partnerConfirmationKey,
            true
          )
          : ""}
        {renderLabelInputPair(
          global.EXTERNAL_CODE_LBL.CONFIRMATION_CODE,
          "IntegResvConfNum",
          reservationData.integResvConfNum,
          true
        )}
        {renderLabelInputPair(
          "RA#",
          "NewIntegConfirmNumber",
          ediConfirmCodeReq.NewIntegConfirmNumber,
          false
        )}
        {reservationData.xpressEnabled
          ? renderLabelInputPair(
            "Opted-in to FOXpress",
            "OptInXpress",
            ediConfirmCodeReq.OptInXpress,
            false,
            "checkbox"
          )
          : ""}
        {renderLabelInputPair(
          "Password",
          "Password",
          ediConfirmCodeReq.Password,
          false,
          "input"
        )}
        {!props.isEnableVoucherModal ? (
          <Fragment>
            {renderLabelInputPair(
              "Voucher Added",
              "VoucherAdded",
              ediConfirmCodeReq.VoucherAdded,
              false,
              "checkbox"
            )}
            {renderLabelInputPair(
              "Amount ($)",
              "VoucherAmount",
              ediConfirmCodeReq.VoucherAdded
                ? ediConfirmCodeReq.VoucherAmount
                : ""
            )}
          </Fragment>
        ) : (
          ""
        )}
      </div>
    );
  };
  return (
    <Draggable handle=".cursor">
      <Modal
        isOpen={props.showModal}
        className="no-cursor"
        toggle={() => {
          props.closeModal();
        }}
        style={{ minWidth: "50rem" }}
      >
        <ModalHeader
          className="modal-header bg-primary modal-title text-white cursor"
          toggle={() => {
            props.closeModal();
          }}
        >
          <i className="fas fa-arrows-alt" /> External Parameters Update
        </ModalHeader>
        <ModalBody>{renderData()}</ModalBody>
        <ModalFooter>
          <div className="col-lg-12 text-right">
            <button
              type="button"
              name="btnAddNewMember"
              className="btn btn-primary waves-effect waves-light btnSize ml-2"
              onClick={() => onFormSubmit()}
            >
              Update
            </button>
            <button
              type="button"
              name="btnCancel"
              className="btn btn-secondary waves-effect waves-light btnSize ml-2"
              onClick={props.closeModal}
            >
              Cancel
            </button>
          </div>
        </ModalFooter>
      </Modal>
    </Draggable>
  );
};
export default ExternalParameterUpdateModal;

import React, { useEffect, useState } from "react";
import Draggable from "react-draggable";
import { useDispatch, useSelector } from "react-redux";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { showNotification } from "../../../../components/UI/Toaster/Toaster";
import AddressComponent from "../../../../components/common/address-component/address-component";
import {
  getBasicToastResponse,
  getErrorToastData,
} from "../../../../shared/utility";
import {
  addLocationSettingsInfo,
  addLocationSettingsInfoReset,
  updateLoginUserData,
} from "../../../../store/actions/index";
import { validateLocationSettings } from "../../settingsHelper";
const AddNewLocationModal = (props) => {
  const [isValidateDone, setIsValidateDone] = useState(false);
  const addLocationResponse = useSelector(
    (state) => state.settingsReducer.addLocationResponse
  );
  const loggedInUserData = useSelector((state) => state.auth.loggedInUserData);
  const dispatch = useDispatch();
  useEffect(() => {
    props.setIsNewLocation(true);
  }, []);
  useEffect(() => {
    if (!addLocationResponse) {
      return;
    }
    showNotification(getBasicToastResponse(addLocationResponse));
    if (addLocationResponse.StatusCode === 0) {
      const locationData = { ...loggedInUserData };
      let locationList = loggedInUserData.DependantOrgList
        ? [...loggedInUserData.DependantOrgList]
        : [];
      locationList.push({
        Key: addLocationResponse.LocationInfo.LocationId,
        Value: addLocationResponse.LocationInfo.Name,
      });
      locationData.DependantOrgList = [...locationList];
      dispatch(updateLoginUserData(locationData));
      dispatch(addLocationSettingsInfoReset());
      props.closeModal();
    } else {
      dispatch(addLocationSettingsInfoReset());
    }
  }, [addLocationResponse]);
  const saveNewLocation = () => {
    let msg = "";
    const locreq = {};
    locreq.SelectedChildOrgUnitId = props.SelectedChildOrgUnitId;
    locreq.LocationInfo = { ...props.newLocaionData };
    delete locreq.LocationInfo.Address["isValidZipCode"];
    if (!msg) {
      msg = validateLocationSettings(locreq);
    }
    if (msg) {
      showNotification(getErrorToastData(msg));
      setIsValidateDone(true);
    } else {
      setIsValidateDone(false);
      dispatch(addLocationSettingsInfoReset());
      dispatch(addLocationSettingsInfo(locreq));
    }
  };
  const commonParameters = ["input", null, true, isValidateDone];
  return (
    <Draggable handle=".cursor">
      <Modal
        isOpen={props.showModal}
        className="no-cursor"
        toggle={() => {
          props.closeModal();
        }}
        style={{ minWidth: "60rem" }}
      >
        <ModalHeader
          className="modal-header bg-primary modal-title text-white cursor"
          toggle={() => {
            props.closeModal();
          }}
        >
          <i className="fas fa-plus" /> Add New Location
        </ModalHeader>
        <ModalBody>
          <div className="form-group row">
            <div className="col-sm-12 p-0">
              {props.renderLabelInputPair(
                "Location Code",
                "LocationCode",
                props.newLocaionData.LocationCode,
                true,
                ...commonParameters
              )}
              {props.renderLabelInputPair(
                "Name",
                "Name",
                props.newLocaionData.Name,
                true,
                ...commonParameters
              )}
              {props.renderLabelInputPair(
                "Display Name",
                "DisplayName",
                props.newLocaionData.DisplayName,
                true,
                ...commonParameters
              )}
              <AddressComponent
                isAstericsRequired={true}
                type="newlocationSetting"
                key="newlocationSetting-Address-Component"
                onChangeMethod={props.inputAddressChangedHandler}
                address={props.newLocaionData.Address}
                resetLoader={props.resetLoader}
                isValidateDone={isValidateDone}
              />
              {props.renderLabelInputPair(
                "Toll Free Number",
                "TollFreeNumber",
                props.newLocaionData.TollFreeNumber,
                false,
                ...commonParameters
              )}
              {props.renderLabelInputPair(
                "Time Zone",
                "TimeZone",
                props.newLocaionData.TimeZone,
                true,
                "select",
                props.timezones,
                true,
                isValidateDone
              )}
              {props.renderLabelInputPair(
                "Time Zone Delta",
                "TimeZoneDelta",
                props.newLocaionData.TimeZoneDelta,
                true,
                ...commonParameters
              )}
              {props.renderLabelInputPair(
                "Time Zone Standard Delta",
                "TimeZoneStandardDelta",
                props.newLocaionData.TimeZoneStandardDelta,
                true,
                ...commonParameters
              )}
              {props.renderLabelInputPair(
                "Email",
                "Email",
                props.newLocaionData.Email,
                false,
                ...commonParameters
              )}
              {props.renderLabelInputPair(
                "Email Bcc",
                "EmailBcc",
                props.newLocaionData.EmailBcc,
                false,
                ...commonParameters
              )}
              {props.renderLabelInputPair(
                "Renter Policy Link",
                "RenterPolicyLink",
                props.newLocaionData.RenterPolicyLink,
                false,
                ...commonParameters
              )}
              {props.renderLabelInputPair(
                "Description",
                "Description",
                props.newLocaionData.Description,
                false,
                "textarea",
                null,
                true
              )}
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="col-sm-12 text-right">
            <button
              type="button"
              name="btnAddLocation"
              className="btn btn-primary waves-effect waves-light btnSize ml-2"
              onClick={(event) => saveNewLocation()}
            >
              Save
            </button>
            <button
              type="button"
              name="btnCancel"
              className="btn btn-secondary waves-effect waves-light btnSize ml-2"
              onClick={props.closeModal}
            >
              Cancel
            </button>
          </div>
        </ModalFooter>
      </Modal>
    </Draggable>
  );
};
export default AddNewLocationModal;

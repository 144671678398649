import React, { Fragment } from "react";
import HtmlParser from "react-html-parser";
import AddressComponent from "../../../components/common/address-component/address-component";
import CommonInputField from "../../../components/UI/Input/common-input-field";
import { LabelAndValuePairControl } from "../../../components/UI/Input/label-value-pair-control";
import { getDropdownItems, getHourList } from "../../../shared/utility";
import { getMinutesIntervals } from "../../Settings/settingsHelper";
import {
  getSeatingCapacityList,
  getVehicleAnnualMilesDriven,
  getVehicleBuilders,
} from "../vehicle-helper";
const VehicleDetailsUI = (props) => {
  const isEditMode = props.isSubMenu ? props.isEditMode : true;
  const vehicleData = props.vehicleData || {};
  const suggestedPrice = props.suggestedPrice || {};
  const builderList = getVehicleBuilders() || [];
  const annualMileageList = getVehicleAnnualMilesDriven() || [];
  const seatingCapacityList = getSeatingCapacityList() || [];
  const commonParameters = ["Key", "Value", "Select"];
  const parkingDistanceOptions = getDropdownItems(
    props.vehicleFeatureOptions.ParkingDistanceOptions,
    ...commonParameters
  );
  const parkedTextOptions = getDropdownItems(
    props.vehicleFeatureOptions.ParkedTextOptions,
    ...commonParameters
  );
  const driveTypeOptions = getDropdownItems(
    props.vehicleFeatureOptions.DriveTypeOptions,
    ...commonParameters
  );
  const transmissionTypeOptions = getDropdownItems(
    props.vehicleFeatureOptions.TransmissionTypeOptions,
    ...commonParameters
  );

  const fuelTypeOptions = getDropdownItems(
    props.vehicleFeatureOptions.FuelTypeOptions,
    ...commonParameters
  );
  const fuelConsumptionUnitOptions = getDropdownItems(
    props.vehicleFeatureOptions.FuelConsumptionUnitOptions,
    ...commonParameters
  );
  const assetColorOptions = getDropdownItems(
    props.vehicleFeatureOptions.AssetColorOptions,
    ...commonParameters
  );
  const orgUnitAssetGroups = getDropdownItems(
    props.orgUnitAssetGroups,
    ...commonParameters
  );
  const subVehicleTypeOptions = getDropdownItems(
    props.subVehicleTypeOptions,
    ...commonParameters
  );
  const vehicleCategories = getDropdownItems(
    props.vehicleCategories,
    "CategoryId",
    "DisplayText",
    "Select"
  );

  const hours = getHourList(false).map((item) => {
    return {
      key: parseInt(item.value),
      displayValue:
        parseInt(item.display) > 1
          ? parseInt(item.display) + " hrs"
          : parseInt(item.display) + " hr",
    };
  });
  const minutesIntervals = getMinutesIntervals();

  const getLblHtmlCtrl = (value, customClasses) => {
    return (
      <label className={`col-sm-12 p-0 ${customClasses || ""}`} title={value}>
        {value || "--"}
      </label>
    );
  };
  const getRateDisplayValue = (unit, defaultVal, classes, minVal, maxVal) => {
    return getLblHtmlCtrl(
      defaultVal
        ? "$" + defaultVal + " (/" + unit + ")"
        : minVal
          ? "$" + minVal + " - $" + maxVal + " (/" + unit + ")"
          : "N/A (/" + unit + ")",
      classes + " d-inline-block"
    );
  };
  const renderRateInputFields = (controlName, defaultVal, unit) => {
    return (
      <Fragment>
        <CommonInputField
          elementConfig={{
            type: "number",
            name: controlName,
            className: "col-sm-5",
            value: defaultVal || "",
          }}
          nodeElement="input"
          otherConfig={{}}
          onChange={props.inputChangedHandler}
        />
        <label className="font-weight-bold pl-5">{"(/" + unit + ")"}</label>
      </Fragment>
    );
  };

  const fontBold = "font-weight-bold";
  const textDanger = "text-danger";
  const textSuccess = "text-success";
  const labelCaptionClass = "col-lg-4";
  const labelValueClass = "col-sm-8";
  const labelCaptionClassBold =
    labelCaptionClass + " font-weight-bold col-form-label";
  const renderCheckbox = (labelCaption, id, value, isDisabled) => {
    const disabledAttr = isDisabled ? { disabled: isDisabled } : {};
    return (
      <CommonInputField
        elementConfig={{
          id: id,
          type: "checkbox",
          name: id,
          checked: value || false,
          ...disabledAttr,
        }}
        otherConfig={{
          caption: labelCaption,
          isHideLabel: true,
        }}
        nodeElement="checkbox"
        onChange={props.inputChangedHandler}
      />
    );
  };
  const renderRadioButtons = (id, value, labelCaption, name) => {
    return (
      <CommonInputField
        elementConfig={{
          id: id,
          type: "radio",
          name: name,
          checked: vehicleData[name] === value,
          disabled: name === "ExecutionModeKey" ? true : !isEditMode,
        }}
        otherConfig={{
          caption: labelCaption,
        }}
        nodeElement="radio"
        onChange={props.inputChangedHandler}
      />
    );
  };
  const renderHourMinDropdown = (name, value, dropdownList, elementType) => {
    return (
      <CommonInputField
        elementConfig={{
          name: name,
          value: value || "",
          disabled: !isEditMode,
        }}
        otherConfig={{
          dropdownItem: dropdownList,
          elementType: elementType,
        }}
        nodeElement="select"
        onChange={props.inputChangedHandler}
      />
    );
  };
  const renderLabelInputPair = (
    labelCaption,
    controlName,
    value,
    isRequired = false,
    isHideLabel = false,
    nodeElement = "input",
    dropdownItem = null,
    customDisplayValue = null
  ) => {
    const isNumberField = ["VehicleYear"].includes(controlName);

    const isDisabledInfo = false;
    const isCheckbox = nodeElement === "checkbox";
    const valueOrCheckedAttr = isCheckbox ? "checked" : "value";
    const disabledAttr = isDisabledInfo ? { disabled: true } : {};
    const minValueAndStepAttr = isNumberField ? { min: "1", step: "1" } : {};
    const onChangehandler = props.inputChangedHandler;
    const displayNoneClass =
      controlName === "SubVehicleTypeKey" ? "displayNone" : "";
    return (
      <LabelAndValuePairControl
        elementConfig={{
          name: controlName,
          type: isCheckbox ? "checkbox" : isNumberField ? "number" : null,
          [valueOrCheckedAttr]: value || "",
          ...disabledAttr,
          ...minValueAndStepAttr,
        }}
        otherConfig={{
          caption: labelCaption,
          isRequired: isRequired,
          dropdownItem: nodeElement === "select" ? dropdownItem : [],
          customDisplayValue: customDisplayValue,
          isHideLabel: isHideLabel,
        }}
        isEditMode={isEditMode}
        onChange={onChangehandler}
        parentRowClass={`form-group row  ${isEditMode && isRequired ? "required" : ""
          } ${displayNoneClass}`}
        labelClasses={labelCaptionClassBold}
        valueClasses={labelValueClass}
        nodeElement={nodeElement}
        isValidateDone={props.isValidateDone}
      />
    );
  };
  const renderSuggestedPrice = () => {
    return (
      <Fragment>
        {getRateDisplayValue(
          "hour",
          null,
          textSuccess,
          suggestedPrice.MinHourOrHalfDayRate,
          suggestedPrice.MaxHourOrHalfDayRate
        )}
        {getRateDisplayValue(
          "day",
          null,
          textSuccess,
          suggestedPrice.MinDailyRate,
          suggestedPrice.MaxDailyRate
        )}
        {getRateDisplayValue(
          "weekend",
          null,
          textSuccess,
          suggestedPrice.MinWeekendRate,
          suggestedPrice.MaxWeekendRate
        )}
        {getRateDisplayValue(
          "weekly",
          null,
          textSuccess,
          suggestedPrice.MinWeeklyRate,
          suggestedPrice.MaxWeeklyRate
        )}
        {getRateDisplayValue(
          "monthly",
          null,
          textSuccess,
          suggestedPrice.MinMonthlyRate,
          suggestedPrice.MaxMonthlyRate
        )}
      </Fragment>
    );
  };
  const renderCustomPricing = () => {
    return (
      <Fragment>
        {getRateDisplayValue("hour", vehicleData.RatePerHour, fontBold)}
        {getRateDisplayValue("day", vehicleData.RatePerDay, fontBold)}

        {getRateDisplayValue(
          "weekend",
          vehicleData.RatePerWeekendDay,
          fontBold
        )}
        {getRateDisplayValue("week", vehicleData.RatePerWeek, fontBold)}
        {getRateDisplayValue("month", vehicleData.RatePerMonth, fontBold)}
      </Fragment>
    );
  };
  const renderAcutalPricing = () => {
    return (
      <Fragment>
        {getRateDisplayValue("hour", vehicleData.RatePerHour, textDanger)}
        {getRateDisplayValue("day", vehicleData.RatePerDay, textDanger)}
        {getRateDisplayValue(
          "weekend",
          vehicleData.RatePerWeekendDay,
          textDanger
        )}
        {getRateDisplayValue("week", vehicleData.RatePerWeek, textDanger)}
        {getRateDisplayValue("month", vehicleData.RatePerMonth, textDanger)}
        <h3>(Based on 0% commission fee)</h3>
      </Fragment>
    );
  };

  return (
    <Fragment>
      <div className="formRow">
        <h4 className="form-header text-uppercase col-lg-12">
          <i className="fas fa-user-circle" />
          Vehicle Basic Info and Rates
        </h4>
        <div className="col-lg-4 p-0">
          {renderLabelInputPair(
            "Vehicle Belongs to",
            "PersonAssetOrgUnitId",
            vehicleData.PersonAssetOrgUnitId,
            true,
            false,
            "select",
            getDropdownItems(props.dependentOrg, "Key", "Value", "Select")
          )}
          {renderLabelInputPair("Vehicle Name", "Name", vehicleData.Name, true)}
          {renderLabelInputPair("Unit No", "UnitNo", vehicleData.UnitNo, true)}
          {renderLabelInputPair(
            "Vin",
            "VinNumber",
            vehicleData.VinNumber,
            true
          )}
          {renderLabelInputPair(
            "License Plate",
            "LicenseNo",
            vehicleData.LicenseNo,
            true
          )}
          {renderLabelInputPair(
            "Mileage at Sign Up",
            "MileageAtSignup",
            vehicleData.MileageAtSignup,
            true
          )}
          {renderLabelInputPair(
            "Annual Miles Driven",
            "AnnualMileageDriven",
            vehicleData.AnnualMileageDriven,
            true,
            false,
            "select",
            annualMileageList
          )}
          {renderLabelInputPair(
            "Actual Cash Value",
            "ActualCashValue",
            vehicleData.ActualCashValue,
            true
          )}
          {renderLabelInputPair(
            "Insurance Carrier",
            "InsuranceCarrier",
            vehicleData.InsuranceCarrier,
            true
          )}
          {renderLabelInputPair(
            "Policy Number",
            "PolicyNumber",
            vehicleData.PolicyNumber,
            true
          )}
          <div className="form-group row">
            <label className="col-sm-4 col-form-label font-weight-bold">
              Lead Time
            </label>
            <div className="col-sm-8 row pl-0">
              <div className="col-sm-4 pr-0">
                {isEditMode
                  ? renderHourMinDropdown(
                    "LeadTimeInMinutes",
                    vehicleData.LeadTimeInMinutes
                      ? Math.floor(vehicleData.LeadTimeInMinutes / 60)
                      : 0,
                    hours,
                    "LeadTimeInHour"
                  )
                  : getLblHtmlCtrl(
                    props.leadTimeInMinutes
                      ? Math.floor(props.leadTimeInMinutes / 60) + " hr (s)"
                      : 0
                  )}
              </div>
              <div className="col-sm-4 pr-0">
                {isEditMode
                  ? renderHourMinDropdown(
                    "LeadTimeInMinutes",
                    vehicleData.LeadTimeInMinutes
                      ? Math.floor(vehicleData.LeadTimeInMinutes % 60)
                      : 0,
                    minutesIntervals,
                    "LeadTimeInMinutes"
                  )
                  : getLblHtmlCtrl(
                    props.leadTimeInMinutes
                      ? Math.floor(props.leadTimeInMinutes % 60) + " min (s)"
                      : 0
                  )}
              </div>
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-4 col-form-label font-weight-bold">
              Buffer Time
            </label>
            <div className="col-sm-8 row pl-0">
              <div className="col-sm-4 pr-0">
                {isEditMode
                  ? renderHourMinDropdown(
                    "BufferTimeInMinutes",
                    vehicleData.BufferTimeInMinutes
                      ? Math.floor(vehicleData.BufferTimeInMinutes / 60)
                      : 0,
                    hours,
                    "BufferTimeHours"
                  )
                  : getLblHtmlCtrl(
                    props.bufferTimeInMinutes
                      ? Math.floor(props.bufferTimeInMinutes / 60) + " hr (s)"
                      : 0
                  )}
              </div>
              <div className="col-sm-4 pr-0">
                {isEditMode
                  ? renderHourMinDropdown(
                    "BufferTimeInMinutes",
                    vehicleData.BufferTimeInMinutes
                      ? Math.floor(vehicleData.BufferTimeInMinutes % 60)
                      : 0,
                    minutesIntervals,
                    "BufferTimeInMinutes"
                  )
                  : getLblHtmlCtrl(
                    props.bufferTimeInMinutes
                      ? Math.floor(props.bufferTimeInMinutes % 60) +
                      " min (s)"
                      : 0
                  )}
              </div>
            </div>
          </div>
          <div className="form-group row">
            <label
              className="col-sm-4 col-form-label font-weight-bold"
              id={"vehicleSmoking"}
            >
              Bookable
            </label>
            <div className="col-sm-8">
              {isEditMode ? (
                <div className="memberInput">
                  {renderRadioButtons(
                    "rbAllowedToBookYes",
                    true,
                    "Yes",
                    "AllowedToBook"
                  )}
                  {renderRadioButtons(
                    "rbAllowedToBookNo",
                    false,
                    "No",
                    "AllowedToBook"
                  )}
                </div>
              ) : (
                getLblHtmlCtrl(
                  vehicleData && vehicleData["AllowedToBook"] === true
                    ? "Yes"
                    : "No"
                )
              )}
            </div>
          </div>
          <div className="form-group row">
            <label
              className="col-sm-4 col-form-label font-weight-bold"
              id={"vehicleSmoking"}
            >
              Key-Hand-off
            </label>
            <div className="col-sm-8">
              {renderRadioButtons(
                "executionModeKeyAllAuto",
                "DEVICE_ALL_AUTOMATIC",
                HtmlParser(
                  "<b>Install the RideLink</b>, and allow keyless entry"
                ),
                "ExecutionModeKey"
              )}
              {renderRadioButtons(
                "executionModeKeyManual",
                "DEVICE_BUT_MANUAL",
                HtmlParser(
                  "<b>Install RideLink</b>, but I want to hand off the keys myself. (Free)"
                ),
                "ExecutionModeKey"
              )}
              {renderRadioButtons(
                "executionModeKeyAllManual",
                "ALL_MANUAL",
                HtmlParser(
                  "<b>No device please</b>, I'll hand off keys myself each time."
                ),
                "ExecutionModeKey"
              )}
              {renderCheckbox(
                HtmlParser(
                  'Display in <a href="http://www.justshareit.com" target="_blank"> Justshareit.com</a> Marketplace'
                ),
                "DisplayInJsiMarketPlace",
                vehicleData.DisplayInJsiMarketPlace,
                !isEditMode
              )}
            </div>
          </div>
        </div>
        <div className="col-lg-4 p-0">
          {renderLabelInputPair(
            "Vehicle Type",
            "AssetType",
            vehicleData.AssetType,
            true,
            false,
            "select",
            orgUnitAssetGroups
          )}
          {renderLabelInputPair(
            "Category",
            "CategoryId",
            vehicleData.CategoryId,
            true,
            false,
            "select",
            vehicleCategories,
            vehicleData.Category ? vehicleData.Category.Value : ""
          )}
          {renderLabelInputPair(
            "Sub Vehicle Type",
            "SubVehicleTypeKey",
            vehicleData.SubVehicleTypeKey,
            false,
            false,
            "select",
            subVehicleTypeOptions,
            vehicleData.SubVehicleType ? vehicleData.SubVehicleType.Value : ""
          )}
          {vehicleData.Make !== "OTHER"
            ? renderLabelInputPair(
              "Made By",
              "Make",
              vehicleData.Make,
              true,
              false,
              "select",
              builderList
            )
            : renderLabelInputPair(
              "Made By",
              "OtherMake",
              vehicleData.OtherMake,
              true
            )}
          {renderLabelInputPair("Model", "Model", vehicleData.Model, true)}

          {renderLabelInputPair(
            "Year",
            "VehicleYear",
            vehicleData.VehicleYear,
            true
          )}
          {renderLabelInputPair(
            "Seating Capacity",
            "SeatingCapacity",
            vehicleData.SeatingCapacity,
            true,
            false,
            "select",
            seatingCapacityList
          )}
          {renderLabelInputPair(
            "Driving Type",
            "DriveType",
            vehicleData.DriveType,
            true,
            false,
            "select",
            driveTypeOptions
          )}
          {renderLabelInputPair(
            "Fuel Type",
            "FuelType",
            vehicleData.FuelType,
            true,
            false,
            "select",
            fuelTypeOptions
          )}
          {renderLabelInputPair(
            "Fuel Consumption Unit",
            "FuelUnit",
            vehicleData.FuelUnit,
            true,
            false,
            "select",
            fuelConsumptionUnitOptions
          )}
          {renderLabelInputPair(
            "Transmission Type",
            "TransmissionTypeKey",
            vehicleData.TransmissionTypeKey,
            true,
            false,
            "select",
            transmissionTypeOptions,
            vehicleData.TransmissionType
          )}
          {renderLabelInputPair(
            "Vehicle Color",
            "Color",
            vehicleData.Color,
            true,
            false,
            "select",
            assetColorOptions
          )}
          {renderLabelInputPair(
            "Vehicle Description",
            "OwnerNotes",
            vehicleData.OwnerNotes,
            false,
            false,
            "textarea"
          )}
          {renderLabelInputPair(
            "Disclaimer Notes",
            "DisclaimerText",
            vehicleData.DisclaimerText,
            false,
            false,
            "textarea"
          )}
          {renderLabelInputPair(
            "Internal Notes",
            "InternalNotes",
            vehicleData.InternalNotes,
            false,
            false,
            "textarea"
          )}
        </div>
        <div className="col-lg-4 p-0">
          <div className="form-group row text-danger font-weight-bold">
            *For Suggested Price Please select vehicle category, make model and
            year
          </div>
          <div className="form-group row">
            <label
              htmlFor="newres-LicenseExpirationDate"
              className="col-sm-4 col-form-label font-weight-bold"
            >
              Suggested Price
            </label>
            <div className="col-sm-8">{renderSuggestedPrice()}</div>
          </div>
          <div className="form-group row">
            <label
              htmlFor="newres-LicenseExpirationDate"
              className="col-sm-4 col-form-label font-weight-bold"
            >
              Your Price :
            </label>
            <div className="col-sm-8 row">
              {isEditMode ? (
                <Fragment>
                  {renderRateInputFields(
                    "RatePerHour",
                    vehicleData.RatePerHour,
                    "hour"
                  )}
                  {renderRateInputFields(
                    "RatePerDay",
                    vehicleData.RatePerDay,
                    "day"
                  )}
                  {renderRateInputFields(
                    "RatePerWeekendDay",
                    vehicleData.RatePerWeekendDay,
                    "weekend"
                  )}
                  {renderRateInputFields(
                    "RatePerWeek",
                    vehicleData.RatePerWeek,
                    "week"
                  )}
                  {renderRateInputFields(
                    "RatePerMonth",
                    vehicleData.RatePerMonth,
                    "month"
                  )}{" "}
                </Fragment>
              ) : (
                renderCustomPricing()
              )}
            </div>
          </div>
          <div className="form-group row">
            <label
              htmlFor="newres-LicenseExpirationDate"
              className="col-sm-4 col-form-label font-weight-bold"
            >
              You Receive :
            </label>
            <div className="col-sm-8">{renderAcutalPricing()}</div>
          </div>
        </div>
      </div>
      <div className="formRow">
        <h4 className="form-header text-uppercase  col-lg-12">
          <i className="fas fa-user-circle" />
          Parking Spot (or Area)
        </h4>
        <div className="col-lg-4 p-0">
          {renderLabelInputPair(
            "Vehicle will be located",
            "ParkingDistanceOptionKey",
            vehicleData.ParkingDistanceOptionKey,
            true,
            false,
            "select",
            parkingDistanceOptions,
            vehicleData.ParkingDistanceOption
          )}
          <AddressComponent
            isAstericsRequired={true}
            type="VEHICLE"
            key="Parking-Spot"
            onChangeMethod={props.inputAddressChangeHandler}
            address={{
              Country: vehicleData.ParkingAddressCountryId,
              Street1: vehicleData.ParkingAddressStreet1,
              Street2: vehicleData.ParkingAddressStreet2,
              State: vehicleData.ParkingAddressStateId,
              StateText: "",
              City: vehicleData.ParkingAddressCityId,
              CityText: "",
              ZipCode: vehicleData.ParkingAddressZipCodeId,
              ZipCodeText: "",
            }}
            resetLoader={props.resetLoader}
            sowAsterics={true}
            isNoneEditableMode={!isEditMode}
            isValidateDone={props.isValidateDone}
          />
        </div>
        <div className="col-lg-4 p-0">
          {renderLabelInputPair(
            "Vehicle is parked",
            "ParkedTextKey",
            vehicleData.ParkedTextKey,
            true,
            false,
            "select",
            parkedTextOptions,
            vehicleData.ParkedText
          )}
          {renderLabelInputPair(
            "Parking Notes for Renter",
            "ParkingNotes",
            vehicleData.ParkingNotes,
            false,
            false,
            "textarea"
          )}
        </div>
        <div className="col-lg-4 p-0"></div>
      </div>
    </Fragment>
  );
};
export default VehicleDetailsUI;

import React, { useEffect, useState } from 'react'
import { getCustomerViewModelForReservation } from '../../../../services/entities/fox-api/temp-models/customer-form-view-model'
import { invokeServerAPI } from '../../../../services/httpService'
import { isEmail } from '../../../../shared/utility'
import { AddressType, CountryKeyValueType } from '../../../../shared/utility/enum-utility'
import { isValidResponse } from '../../../../shared/utility/validation-helper'
import SearchMemberModal from '../../../Member/search/search-modal/search-member-modal'
import CommonTitle from '../../component/common-title'
import ReservationIcon from '../../component/reservation-icon'
import { createFoxAPIMemReq } from '../../reservation-helper'
import AdditionalAddressInformation from './additional-address-information'
import AdditionalPhoneInformation from './additional-phone-information'
import ExternalInformation from './external-information'
import RequiredInformation from "./required-information"
import RewardInformation from './reward-information'

function CustomerForm(props) {
  const { selectedMemberId, customerFormData, setCustomerFormData, customerOriginalData, setCustomerOriginalData, setDiscountList, getSecurityDepositCharges,
    clearSecurityDepositRate, otherData, setOtherData, setShowLoader, setModalData, onChangesMade } = props;
  const [lastChangedField, setLastChangedField] = useState(null);
  const [stateCityLoader, setStateCityLoader] = useState(false);
  // declare the async data fetching function
  const getMemberData = async (memberId) => {
    setShowLoader(true);
    const memReq = createFoxAPIMemReq(memberId);
    const memResponse = await invokeServerAPI("foxapi/customer", memReq, true);
    setShowLoader(false);
    if (isValidResponse(memResponse, true)) {
      const cusInfo = memResponse.customerInfo;
      const memInfo = getCustomerViewModelForReservation(cusInfo);
      setCustomerFormData(memInfo);
      setCustomerOriginalData(memInfo);
      if (cusInfo?.foxDiscounts && cusInfo?.foxDiscounts?.length > 0) {
        setDiscountList(cusInfo?.foxDiscounts);
      }
      if (memInfo?.primaryAddress?.country && memInfo?.primaryAddress?.postalCode)
        getSecurityDepositCharges(null, memInfo?.primaryAddress?.country, memInfo?.primaryAddress?.postalCode);
    }
  }
  useEffect(() => {
    if (selectedMemberId) {
      getMemberData(selectedMemberId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMemberId]);

  const loadSelectedMemberData = (selectedMemberData) => {
    if (customerOriginalData?.customerId === selectedMemberData?.PersonId) {
      //getSecurityDepositCharges(null, customerOriginalData?.primaryAddress?.country, customerOriginalData?.primaryAddress?.postalCode);
      setCustomerFormData({ ...customerOriginalData });
    } else {
      getMemberData(selectedMemberData?.PersonId);
    }
    closeMemberSearchModal();
  };
  const closeMemberSearchModal = (isCancelSearchResult) => {
    // if (isCancelSearchResult) {
    //   setCustomerFormData({
    //     ...customerFormData,
    //     renterId: customerOriginalData.renterId
    //   });
    // }
    setLastChangedField(null);
  };

  const onHandleChange = (value, name) => {
    const tempData = { ...customerFormData };
    tempData[name] = value;
    setCustomerFormData(tempData);
    onChangesMade(true);
  };
  const onBlur = (event) => {
    const targetName = event?.target?.name;
    const targetValue = event?.target?.value;
    if ((targetName === "email" && isEmail(targetValue)) || event?.target?.name === "renterId") {
      setLastChangedField(targetName);
      // if (!customerOriginalData) {
      //   setLastChangedField(targetName)
      // } else if (customerOriginalData &&
      //   ((targetName === "email" && customerOriginalData?.email !== targetValue)
      //     || (targetName === "renterId" && customerOriginalData?.renterId !== event?.target?.value))) {
      //   setLastChangedField(targetName)
      // }
    }
  };
  const onZipToAddressChange = (zipToAddrData) => {
    if (zipToAddrData?.postCode || zipToAddrData?.postalCodeText) {
      const tempData = { ...customerFormData?.primaryAddress };
      if ((zipToAddrData?.country === CountryKeyValueType.US || zipToAddrData?.country === CountryKeyValueType.IN)) {
        if (tempData?.province !== zipToAddrData?.state
          || tempData?.city !== zipToAddrData?.city) {
          if (tempData?.province !== zipToAddrData?.state) {
            tempData.province = zipToAddrData?.state;
          }
          if (tempData?.city !== zipToAddrData?.city) {
            tempData.city = zipToAddrData?.city;
          }
        }
        onHandleChange(tempData, "primaryAddress");
      } else {
        if (tempData?.province !== zipToAddrData?.stateText
          || tempData?.city !== zipToAddrData?.cityText) {
          if (tempData?.stateText !== zipToAddrData?.cityText) {
            tempData.province = zipToAddrData?.stateText;
          }
          if (tempData?.city !== zipToAddrData?.cityText) {
            tempData.city = zipToAddrData?.cityText;
          }
        }
        onHandleChange(tempData, "primaryAddress");
      }
    }
  }
  return (
    <>
      <div className="res-container-column ">
        <div className="flex-row gap-1">
          <CommonTitle title="Customer" classNames="font-size-2 font-weight-600" />
          {customerFormData?.customerId ?
            <ReservationIcon
              setModalData={setModalData}
              setShowLoader={setShowLoader}
              customerId={customerFormData?.customerId}
              iconClusterType="CUSTOMER"
            />
            : ""}
        </div>
        <div className='common-res-container'>
          <RequiredInformation
            onChange={onHandleChange}
            onBlur={onBlur}
            customerData={customerFormData}
            getSecurityDepositCharges={getSecurityDepositCharges}
            clearSecurityDepositRate={clearSecurityDepositRate}
            onZipToAddressChange={onZipToAddressChange}
            setStateCityLoader={setStateCityLoader}
          />
          <RewardInformation
            onChange={onHandleChange}
            onBlur={onBlur}
            value={customerFormData?.renterId}
          />
          <AdditionalAddressInformation
            onChange={onHandleChange}
            addressData={customerFormData?.primaryAddress || {}}
            addressType={AddressType.PRIMARY_ADDRESS}
            clearSecurityDepositRate={clearSecurityDepositRate}
            stateCityLoader={stateCityLoader}
          />
          <AdditionalPhoneInformation
            onChange={onHandleChange}
            phoneData={customerFormData?.mobilePhone}
          />
        </div>
        <div className='common-res-container'>
          <ExternalInformation
            otherData={otherData}
            setOtherData={setOtherData}
          />
        </div>
      </div>
      {lastChangedField && (customerFormData?.email || customerFormData?.renterId) ? (
        <SearchMemberModal
          customerModel={{ email: lastChangedField === "email" && customerFormData?.email, renterId: lastChangedField === "renterId" && customerFormData?.renterId }}
          showModal={lastChangedField ? true : false}
          closeModal={closeMemberSearchModal}
          loadSelectedMemberData={loadSelectedMemberData}
          enableSearchFilter={false}
        />
      ) : (
        ""
      )}
    </>
  )
}

export default CustomerForm
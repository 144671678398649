import React, { useState, useEffect } from "react";
import Moment from "moment";
import { hasPermission, toTitleCase } from "../../../shared/utility";
import { PDF_ICON, CSV_ICON, XLS_ICON } from "../../../shared/AppImages";
import ExportModal from "../../export_comp/export-modal";
import AddActivityModal from "../../../containers/activity/modal/add-activity-modal";
import Breadcrumbs from "../../breadcrumbs/breadcrumbs";
import LinkButton from "../../../components/UI/button/link-btn";
import { ADMIN_PAGE } from "../../../shared/GlobalVar";
import PermissionName from "../../../shared/Permissions";

export const PageTopPanel = (props) => {
  const [exportModalInfo, setExportModalInfo] = useState({
    showExportModal: false,
    exportType: null,
  });
  const [showAddNewActivityModal, setShowAddNewActivityModal] = useState(false);
  const showExportModal = (exportType) => {
    if (
      exportModalInfo &&
      exportModalInfo.showExportModal &&
      exportModalInfo.exportType
    ) {
      props.resetExportDoc();
      props.getExportSearchData(true);
    } else {
      setExportModalInfo({ showExportModal: true, exportType: exportType });
    }
  };
  const closeExportModal = () => {
    setExportModalInfo({ showExportModal: false, exportType: null });
    props.resetExportDoc();
  };
  useEffect(() => {
    if (
      exportModalInfo &&
      exportModalInfo.showExportModal &&
      exportModalInfo.exportType
    ) {
      props.resetExportDoc();
      props.getExportSearchData(true);
    }
  }, [exportModalInfo.showExportModal, exportModalInfo.exportType]);
  const getAddNewActivityModal = () => {
    setShowAddNewActivityModal(true);
  };
  const generateLink = () => {
    const linkListCount = props.linkList.length;
    return props.linkList.map((linkObj, index) => {
      return linkObj.isAnchorLink ? (
        <LinkButton
          isAnchorLink={true}
          btnLabel={linkObj.linkName}
          containerClass={`font-weight-bold fs-2 d-flex align-items-center  ${
            linkObj.linkName === "New Reservation" && !props.memberId
              ? "mb-3"
              : ""
          } ${
            props.showExportLink
              ? "mr-5"
              : linkListCount === index + 1
              ? ""
              : "mr-5"
          }`}
          iconClass="fas fa-plus-circle iconBeforeLink"
          pathName={linkObj.linkUrl}
          prefix={props.memberId ? "?memberId=" + props.memberId : ""}
          key={index}
          breadCrumbList={props.linkBreadCrumb}
          title={props.title}
        />
      ) : (
        <LinkButton
          btnLabel={linkObj.linkName}
          containerClass="pseudo-link pr-1 fs-2 font-weight-bold mr-5 d-flex align-items-center"
          iconClass="fas fa-plus-circle iconBeforeLink"
          clicked={
            linkObj.linkType === "ADD_ACTIVITY" ? getAddNewActivityModal : ""
          }
          key={index}
        />
      );
    });
  };
  const generateExportIcon = (iconSrc, type) => {
    return (
      <img
        src={iconSrc}
        alt={type}
        className={`replaced-svg ${
          props.isExportDisabled ? "noImageLink" : ""
        }`}
        onClick={() => (!props.isExportDisabled ? showExportModal(type) : null)}
      />
    );
  };
  return (
    <div className="row">
      {props.breadCrumbList && props.breadCrumbList.length > 0 ? (
        <div className="mr-auto">
          <Breadcrumbs
            breadCrumbList={props.breadCrumbList}
            title={props.title}
          />
        </div>
      ) : (
        ""
      )}
      {props.showNewVehicleLink && hasPermission(PermissionName.NEW_VEHICLE) ? (
        <LinkButton
          isAnchorLink={true}
          btnLabel="New Vehicle"
          containerClass="font-weight-bold fs-2 d-flex align-items-center mr-5"
          iconClass="fas fa-plus-circle iconBeforeLink"
          pathName="/newVehicle"
          prefix={props.memberId ? "?memberId=" + props.memberId : ""}
          breadCrumbList={props.breadCrumbList}
        />
      ) : (
        ""
      )}
      {props.showEditMemberLink && hasPermission(PermissionName.EDIT_MEMBER) ? (
        <LinkButton
          btnLabel="Edit Profile"
          containerClass="pseudo-link pr-1 fs-2 font-weight-bold mr-5 d-flex align-items-center"
          stackClass="fa-stack fs-08 vertical-align-top"
          faIconList={[
            "fa fa-circle fa-stack-2x iconBeforeLink",
            "fa fa-pencil-alt fa-stack-1x whiteColor m-0 mtr-01 mlr-01",
          ]}
          clicked={props.openEditPage}
        />
      ) : (
        ""
      )}
      {props.showNewReservationLink &&
      hasPermission(PermissionName.NEW_RESERVATION) ? (
        <LinkButton
          isAnchorLink={true}
          btnLabel="New Reservation"
          containerClass={`font-weight-bold fs-2 d-flex align-items-center ${
            props.showExportLink ? "mr-5" : ""
          }`}
          iconClass="fas fa-plus-circle iconBeforeLink"
          pathName="/updateReservation"
          prefix={props.memberId ? "?memberId=" + props.memberId : ""}
          breadCrumbList={props.breadCrumbList}
        />
      ) : (
        ""
      )}
      {props.pageName === ADMIN_PAGE.SERVICE_AGENT_SEARCH_PAGE ||
      props.pageName === ADMIN_PAGE.MECHANIC_APP_REPORT_SEARCH_PAGE ||
      props.pageName === ADMIN_PAGE.READY_LINE_AGENT_SEARCH_PAGE ||
      props.pageName === ADMIN_PAGE.BIRDS_EYE_VIEW_SEARCH_PAGE ? (
        <div className="form-header row mb-0" style={{ borderBottom: "none" }}>
          <h4 className="text-uppercase mr-auto">{props.title}</h4>
        </div>
      ) : (
        ""
      )}
      {props.linkList && props.linkList.length > 0 ? generateLink() : ""}
      {props.showExportLink ? (
        <div
          className={`d-flex align-items-center ${
            !props.breadCrumbList || props.breadCrumbList.length === 0
              ? "ml-auto"
              : ""
          }`}
        >
          <ol className="searchInputTop__export">
            <li>
              <h1>Export To</h1>
            </li>
            <li>{generateExportIcon(CSV_ICON, "csv")}</li>
            <span className="verticalLineSeparator"></span>
            <li>{generateExportIcon(XLS_ICON, "xls")}</li>
            <span className="verticalLineSeparator"></span>
            <li>{generateExportIcon(PDF_ICON, "pdf")}</li>
          </ol>
        </div>
      ) : (
        ""
      )}
      {exportModalInfo.showExportModal &&
      props.exportData &&
      props.exportData.length > 0 ? (
        <ExportModal
          dataSet={props.exportData || []}
          showModal={exportModalInfo.showExportModal}
          closeModal={closeExportModal}
          title="Download"
          height="auto"
          width={"auto"}
          exportType={exportModalInfo.exportType}
          pageName={props.pageName}
          fileName={
            toTitleCase(
              (props.pageName === ADMIN_PAGE.RESERVE_SYNC_PAGE &&
              props.fleetUtilizationSupportDataForExport
                ? props.fleetUtilizationSupportDataForExport.locationName.split("/")[0] + "_"
                : "") +
                props.pageName
                  .split("_")
                  .slice(0, props.pageName.split("_").length - 2)
                  .join("_")
            ) +
            "_" +
            Moment().format("YYYYMMDDhhmmss")
          }
          fleetUtilizationSupportDataForExport={
            props.fleetUtilizationSupportDataForExport
          }
        />
      ) : (
        ""
      )}
      {showAddNewActivityModal ? (
        <AddActivityModal
          memberId={props.memberId}
          showModal={showAddNewActivityModal}
          closeModal={() => setShowAddNewActivityModal(false)}
          resetParentPage={props.resetParentPage}
        />
      ) : (
        ""
      )}
    </div>
  );
};
export default PageTopPanel;

import axios from "axios";
import * as actionTypes from "./actionTypes";
import { HistoryDataAdd } from "../../shared/historyDataManagement";
import HttpService from "../../services/httpService";

export const getReservationChargesStart = () => {
  return {
    type: actionTypes.GET_RESERVATION_CHARGES_START
  };
};

export const getReservationChargesSuccess = response => {
  return {
    type: actionTypes.GET_RESERVATION_CHARGES_SUCCESS,
    reservationCharges: response
  };
};

export const getReservationChargesFail = error => {
  return {
    type: actionTypes.GET_RESERVATION_CHARGES_FAIL,
    error: error
  };
};
export const getReservationChargesReset = () => {
  return {
    type: actionTypes.GET_RESERVATION_CHARGES_RESET
  };
};

export const manageReservationChargesStart = () => {
  return {
    type: actionTypes.MANAGE_RESERVATION_CHARGES_START
  };
};
export const manageReservationChargesSuccess = response => {
  return {
    type: actionTypes.MANAGE_RESERVATION_CHARGES_SUCCESS,
    manageReservationResponse: response
  };
};
export const manageReservationChargesFail = error => {
  return {
    type: actionTypes.MANAGE_RESERVATION_CHARGES_FAIL,
    error: error
  };
};
export const manageReservationChargesReset = () => {
  return {
    type: actionTypes.MANAGE_RESERVATION_CHARGES_RESET
  };
};

export const getReservationCharges = getreservationchargesReq => {
  return dispatch => {
    dispatch(getReservationChargesStart());
    HttpService.getReservationCharges(getreservationchargesReq)
      .then(response => {
        dispatch(getReservationChargesSuccess(response));
      })
      .catch(err => {
        //dispatch(getReservationChargesFail(err));
      });
  };
};
export const manageReservation = manageReservationReq => {
  return dispatch => {
    dispatch(manageReservationChargesStart());
    HttpService.manageReservation(manageReservationReq)
      .then(response => {
        dispatch(manageReservationChargesSuccess(response));
      })
      .catch(err => {
        //dispatch(manageReservationChargesReset(err));
      });
  };
};

import React from 'react'
import GeneralRateRow from './general-rate-row'

function CancelledRateBlock({ totalCancellationCharges, totalCancellationFee, cancellationCourtesyAmount }) {
    return (
        <>
            {!isNaN(totalCancellationCharges) &&
                <GeneralRateRow
                    key="cancelled-amount"
                    title="Cancelled Amount"
                    total={totalCancellationCharges}
                    isNegative={true} />
            }
            {!isNaN(totalCancellationFee) &&
                <GeneralRateRow
                    key="cancellation-fee"
                    title="Cancellation Fee"
                    total={totalCancellationFee}
                    isNegative={false}
                />}
            {!isNaN(cancellationCourtesyAmount) &&
                <GeneralRateRow
                    key="cancellation-courtesy-amount"
                    title="Cancellation Courtesy Amount"
                    total={cancellationCourtesyAmount}
                    isNegative={true}
                />}
        </>
    )
}

export default CancelledRateBlock
import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addDamageInfoReset,
  addDamageInfo,
  searchDamageInfoReset,
  searchDamageInfo,
} from "../../../store/actions/index";
import Viewer from "react-viewer";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import Draggable from "react-draggable";
import {
  convertDateTimeString,
  formatCurrency,
  getBasicToastResponse,
  getErrorToastData,
  loadDefaultImage,
} from "../../../shared/utility";
import { updateObject } from "../../../shared/utility";
import Pagination from "../../../components/pagination/Pagination";
import NoRecordFoundComponent from "../../../components/common/pages/no-record-found";
import { noImage } from "../../../shared/AppImages";
import { showNotification } from "../../../components/UI/Toaster/Toaster";
import FileUploader from "../../../components/image-uploader/file-uploader";
import FileUploaderModel from "../../../components/image-uploader/file-uploader-ui-model";
import AddDamageInfoRequest from "../../../services/entities/requests/AddDamageInfoRequest";
import PageInfo from "../../../services/entities/models/PageInfo";
import { validateDamageModal } from "../reservation-helper";
import { LabelAndValuePairControl } from "../../../components/UI/Input/label-value-pair-control";

export const DamageModal = (props) => {
  const defaultData = props.defaultData;
  const dispatch = useDispatch();
  const searchDamageResponse = useSelector(
    (state) => state.damageReducer.searchDamageResponse
  );
  const addDamageResponse = useSelector(
    (state) => state.damageReducer.addDamageResponse
  );
  const [pageInfo, setPageInfo] = useState(new PageInfo());
  const [addDamageInfoRequest, setAddDamageInfoRequest] = useState(
    new AddDamageInfoRequest(
      defaultData.memberId,
      defaultData.reservationId,
      defaultData.memberAssetId
    )
  );
  const [uploaderList, setUploaderList] = useState([]);
  const [tableImagesList, setTableImagesList] = useState([{ src: null }]);
  const [showImageViewer, setShowImageViewer] = useState(false);
  const [selectedImageIndexToView, setSelectedImageIndexToView] = useState(0);

  useEffect(() => {
    dispatch(searchDamageInfoReset());
    dispatch(addDamageInfoReset());
    loadUploaderData();
    getDamageList();
  }, []);

  useEffect(() => {
    getDamageList();
  }, [pageInfo]);

  useEffect(() => {
    if (!searchDamageResponse) {
      return;
    }
    if (searchDamageResponse.StatusCode === 0) {
      loadImageViewerData();
    } else {
      showNotification(getBasicToastResponse(searchDamageResponse));
      dispatch(searchDamageInfoReset());
    }
  }, [searchDamageResponse]);

  useEffect(() => {
    if (!addDamageResponse) {
      return;
    }
    showNotification(getBasicToastResponse(addDamageResponse));
    if (addDamageResponse.StatusCode === 0) {
      dispatch(addDamageInfoReset());
      dispatch(searchDamageInfoReset());
      props.resetParentPage();
      props.closeModal();
    }
    dispatch(addDamageInfoReset());
  }, [addDamageResponse]);

  const loadImageViewerData = () => {
    if (searchDamageResponse.TotalRecords === 0) {
      return;
    }
    const imgList = [];
    searchDamageResponse.DamageInfoList.map((damageObj) => {
      if (damageObj.ProblemsImage) {
        imgList.push({ src: damageObj.ProblemsImage, alt: "" });
      }
    });
    setTableImagesList(imgList.length > 0 ? [...imgList] : [{ src: null }]);
  };
  const getImageViewer = (index) => {
    setShowImageViewer(true);
    setSelectedImageIndexToView(index);
  };
  const closeImageViewer = () => {
    setShowImageViewer(false);
  };
  const loadUploaderData = () => {
    const uploaderObj = new FileUploaderModel();
    uploaderObj.fileName = "Attached Image";
    uploaderObj.parentDivClass = "col-sm-6";
    uploaderObj.photoCloseStyle = {
      right: "0.5rem",
      fontSize: "2.5rem",
    };
    uploaderObj.imageStyle = {
      cursor: "pointer",
      border: "1px solid #ddd",
      width: "15.5rem",
      height: "10.5rem",
      borderRadius: ".4rem",
      padding: ".5rem",
    };
    uploaderObj.uploadDivStyle = {
      cursor: "pointer",
      top: "1.7rem",
      bottom: "0",
      width: "15.5rem",
      height: "10.5rem",
    };
    const uploaderListNew = [];
    uploaderListNew.push(uploaderObj);
    setUploaderList(uploaderListNew);
  };
  const loadUploaderFileData = (index, base64Data, extension) => {
    let updatedControls = { ...addDamageInfoRequest };
    updatedControls = updateObject(updatedControls, {
      ImageData: base64Data,
      ImageExt: extension,
    });
    setAddDamageInfoRequest(updatedControls);
  };
  const onPageChanged = (currentPage, recordsPerPage) => {
    if (currentPage && recordsPerPage) {
      setPageInfo(new PageInfo(currentPage, recordsPerPage));
    }
  };
  const getDamageList = () => {
    const searchDamageReq = {
      MemberId: defaultData.memberId,
      ReservationId: defaultData.reservationId,
      MemberAssetId: defaultData.memberAssetId,
      PageInfo: pageInfo,
    };
    dispatch(searchDamageInfo(searchDamageReq));
  };
  const renderDamageList = () => {
    if (searchDamageResponse && searchDamageResponse.DamageInfoList) {
      return searchDamageResponse.DamageInfoList.map((damageObj, index) => {
        return (
          <tr key={damageObj.ActivityId}>
            {defaultData.isReservationColumnRequired ? (
              <td>{damageObj.ReservationId || ""}</td>
            ) : (
              ""
            )}
            <td>{damageObj.Resolved ? "Resolved" : "Not Resolved"}</td>
            <td>
              <img
                onError={(event) => loadDefaultImage(event, noImage)}
                src={damageObj.ProblemsImage || noImage}
                alt="Damage-img"
                onClick={() =>
                  damageObj.ProblemsImage ? getImageViewer(index) : ""
                }
                style={{ cursor: damageObj.ProblemsImage ? "pointer" : "" }}
                width="50"
                height="30"
              />
            </td>
            <td>{damageObj.SubProblemType || "--"}</td>
            <td>{damageObj.Comments || "--"}</td>
            <td>
              {damageObj.OriginalAmount
                ? formatCurrency(damageObj.OriginalAmount, null, null, "0")
                : "--"}
            </td>
            <td>
              {damageObj.CreatedBy ? (
                <Fragment>{damageObj.CreatedBy}</Fragment>
              ) : (
                "--"
              )}
            </td>

            <td>{convertDateTimeString(damageObj.CreationDate)}</td>
            <td>{convertDateTimeString(damageObj.ResolvingDate)}</td>
          </tr>
        );
      });
    }
  };
  const handleOnChange = ({ target }) => {
    let updateCtrls = { ...addDamageInfoRequest };
    const value = target.type === "checkbox" ? target.checked : target.value;
    updateCtrls = updateObject(updateCtrls, {
      [target.name]: value,
    });
    setAddDamageInfoRequest(updateCtrls);
  };
  const onAddDamageInfo = () => {
    let msg = "";
    if (!msg) {
      msg = validateDamageModal({ ...addDamageInfoRequest });
    }

    if (msg) {
      showNotification(getErrorToastData(msg));
    } else {
      dispatch(addDamageInfo({ ...addDamageInfoRequest }));
    }
  };

  const renderLabelInputPair = (
    labelCaption,
    controlName,
    isRequired = false,
    nodeElement = "input"
  ) => {
    let addDamageInfoRequestNew = { ...addDamageInfoRequest };
    const value = addDamageInfoRequestNew[controlName];
    const isCheckbox = nodeElement === "checkbox";
    return (
      <LabelAndValuePairControl
        elementConfig={{
          name: controlName,
          value: value,
          type: isCheckbox ? "checkbox" : "",
        }}
        otherConfig={{
          caption: labelCaption,
          isRequired: isRequired,
          isHideLabel: isCheckbox,
        }}
        isEditMode={true}
        onChange={handleOnChange}
        parentRowClass={`form-group row  ${isRequired ? "required" : ""}`}
        labelClasses="col-sm-4 col-form-label font-weight-bold"
        valueClasses="col-sm-8"
        nodeElement={nodeElement}
      />
    );
  };
  return (
    <Draggable handle=".cursor">
      <Modal
        isOpen={props.showModal}
        className="no-cursor"
        toggle={() => {
          props.closeModal();
        }}
        style={{ minWidth: "1000px" }}
      >
        <ModalHeader
          className="modal-header bg-primary modal-title text-white cursor"
          toggle={() => {
            props.closeModal();
          }}
        >
          <i className="fas fa-arrows-alt" /> Damage Info
        </ModalHeader>
        <ModalBody
          style={{ maxHeight: "600px", minHeight: "480px" }}
          className="tableScroll"
        >
          <div className="row">
            <div className="col-lg-6">
              {renderLabelInputPair("Damage Location", "DamageLocation", true)}
              {renderLabelInputPair("Memo", "Memo", false, "textarea")}
              {renderLabelInputPair("Chargeable Amount", "OriginalAmount")}
              {renderLabelInputPair("Resolved", "Resolved", false, "checkbox")}
            </div>
            <div className="col-lg-5">
              <FileUploader
                uploaderData={uploaderList}
                isNameRequird={true}
                isOnlyImg={true}
                changeFileTitle="Change Image"
                loadUploaderFileData={loadUploaderFileData}
              />
            </div>
            <div className="col-lg-12 text-right mt-3">
              <button
                type="button"
                className="btn btn-primary waves-effect waves-light btnSize ml-2"
                onClick={() => onAddDamageInfo()}
              >
                Submit
              </button>
              <button
                type="button"
                className="btn btn-secondary  waves-effect waves-light btnSize ml-2"
                onClick={() => props.closeModal()}
              >
                Cancel
              </button>
            </div>
            <div className="col-lg-12 ml-0 mr-0 pl-0 pr-0 mb-2 mt-4">
              {searchDamageResponse && searchDamageResponse.TotalRecords > 0 ? (
                <Fragment>
                  <Pagination
                    totalRecords={searchDamageResponse.TotalRecords}
                    recordsPerPage={pageInfo.PerPageCount}
                    currentPage={pageInfo.PageNumber}
                    onPageChanged={onPageChanged}
                    customClass="col-lg-12 ml-0 mr-0 pl-0 pr-0"
                  />
                  <table className="table table-striped">
                    <thead className="thead-primary">
                      <tr>
                        {defaultData.isReservationColumnRequired ? (
                          <th style={{ width: "6%" }}>Reservation Id</th>
                        ) : (
                          ""
                        )}
                        <th style={{ width: "6%" }}>Is Resolved</th>
                        <th style={{ width: "8%" }}>Image</th>
                        <th style={{ width: "8%" }}>Damage Location</th>
                        <th style={{ width: "8%" }}>Memo</th>
                        <th style={{ width: "8%" }}>Charged Amount</th>
                        <th style={{ width: "8%" }}>Reported By</th>
                        <th style={{ width: "8%" }}>Report Date</th>
                        <th style={{ width: "8%" }}>Resolved Date</th>
                      </tr>
                    </thead>
                    <tbody>{renderDamageList()}</tbody>
                  </table>
                </Fragment>
              ) : (
                <NoRecordFoundComponent heights="25rem" />
              )}
            </div>
          </div>
          {tableImagesList.length > 0 ? (
            <Viewer
              zIndex={1055}
              visible={showImageViewer}
              onClose={() => {
                closeImageViewer();
              }}
              images={tableImagesList}
              activeIndex={selectedImageIndexToView}
            />
          ) : (
            ""
          )}
        </ModalBody>
      </Modal>
    </Draggable>
  );
};
export default DamageModal;

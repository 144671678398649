import React from "react";
import ReactHtmlParser from "react-html-parser";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import Draggable from "react-draggable";
import { BUILD_DATE, BUILD_NUMBER, VERSION_NUMBER } from "../../../config/config";
import { Link } from "react-router-dom";
import {
  ADMIN_COMMON_CAPTION,
  COMMON_MSG,
} from "../../shared/GlobalVar";
import { FoxLogoSecondary } from "../../shared/AppImages";

const AboutModal = (props) => {
  return (
    <Draggable handle=".cursor">
      <Modal
        isOpen={props.showModal}
        className="no-cursor"
        toggle={() => {
          props.closeModal();
        }}
        id="about-modal"
        style={{ minWidth: "80rem" }}
      >
        <ModalHeader
          className="modal-header bg-primary modal-title text-white cursor"
          toggle={() => {
            props.closeModal();
          }}
        >
          <i className="fas fa-key" /> About {COMMON_MSG.ABOUT_MODAL_TITLE}
        </ModalHeader>
        <ModalBody>
          <div className="row col-lg-12">
            <div className="col-lg-4 flex-align-self-center">
              <a href="https://www.foxrentacar.com/" target="blank">
                <img
                  id="aboutFOXRentACarInc"
                  src={FoxLogoSecondary}
                  alt="FOX Rent A Car Inc."
                />
              </a>
              <div className="pt-4 row fs-2">
                <span className="col-lg-4 pr-0 pl-0 font-weight-bold">
                  {ADMIN_COMMON_CAPTION.VERSION_TITLE}
                </span>
                <span>{VERSION_NUMBER}</span>
                <span className="displayNone">
                  <Link
                    className="font-weight-bold pseudo-link pl-2"
                    href={process.env.PUBLIC_URL +
                      "/release-notes/release-note.html"}
                  >
                    What's new
                  </Link>
                </span>
              </div>
              <div className="row fs-2">
                <span className="col-lg-4 pr-0 pl-0 font-weight-bold">
                  {ADMIN_COMMON_CAPTION.BUILD_TITLE_ID}
                </span>
                <span>{BUILD_NUMBER}</span>
              </div>
              <div className="row fs-2">
                <span className="col-lg-4 pr-0 pl-0 font-weight-bold">
                  {ADMIN_COMMON_CAPTION.BUILD_DATE_TITLE}
                </span>
                <span>{BUILD_DATE}</span>
              </div>
            </div>
            <div className="col-lg-8 row">
              <div class="form-header row w-100 p-0">
                <h1 class="text-uppercase mr-auto icon-display-none">
                  {COMMON_MSG.ABOUT_MODAL_TITLE}
                </h1>
              </div>
              <div className="col-lg-12 p-0 row">
                <div className="row col-lg-12 p-0 fs-8">
                  What is FOX Administration Portal?
                </div>
                <div className="row col-lg-12 p-0 fs-d text-justify">
                  <p className="pb-1">
                    {ReactHtmlParser(COMMON_MSG.ADMIN_PORTAL_DESCRIPTION)}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </Draggable>
  );
};

export default AboutModal;

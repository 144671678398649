import { getDefaultFilterStates } from "../../../components/filter/filter-helper";
import PageInfo from "../../../services/entities/models/PageInfo";
import { getMemberLabelByOrgRentalType } from "../../../shared/utility/system-utility";
import { ADMIN_COMMON_CAPTION } from "../../../shared/GlobalVar";
import {
  getCurrentServerTimezone,
  getCurrentServerDate,
} from "../../../shared/utility";

export const getReservationCtrls = (urlOrPageName) => {
  return {
    ReservationState: {
      value: getDefaultFilterStates(urlOrPageName),
    },
    PageInfo: new PageInfo(null, null, "RESV_CREATED_DATE", "DESC"),
    isAdvancedSearch: false,
    StartDate: {
      id: "StartDate",
      elementType: "dateandtimepicker",
      elementConfig: {
        name: "StartDate",
        type: "text",
      },
      timePicker: {
        id: "StartTime",
        name: "StartTime",
        value: getCurrentServerDate("MM:DD:YYYY:HH:mm"),
        elementType: "timepicker",
      },
      validation: {
        required: false,
      },
      labelInput: {
        labeltext: "From",
      },
      timeZoneString: getCurrentServerTimezone(),
      value: getCurrentServerDate("MM:DD:YYYY:HH:mm"),
      valid: false,
      touched: false,
      basicOrder: 1,
      advancedOrder: 1,
    },
    EndDate: {
      id: "EndDate",
      elementType: "dateandtimepicker",
      elementConfig: {
        name: "EndDate",
        type: "text",
      },
      timePicker: {
        id: "EndTime",
        name: "EndTime",
        value: "",
        elementType: "timepicker",
      },
      labelInput: {
        labeltext: "To",
      },
      timeZoneString: getCurrentServerTimezone(),
      value: "",
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      basicOrder: 6,
      advancedOrder: 6,
    },
    FullName: {
      id: "FullName",
      elementType: "input",
      elementConfig: {
        name: "FullName",
        type: "text",
      },
      labelInput: {
        labeltext: "Name",
      },
      value: "",
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      basicOrder: 2,
      advancedOrder: 0,
    },

    FirstName: {
      id: "FirstName",
      elementType: "input",
      elementConfig: {
        name: "FirstName",
        type: "text",
      },
      labelInput: {
        labeltext: "First Name",
      },
      value: "",
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      basicOrder: 0,
      advancedOrder: 2,
    },
    LastName: {
      id: "LastName",
      elementType: "input",
      elementConfig: {
        name: "LastName",
        type: "text",
      },
      labelInput: {
        labeltext: "Last Name",
      },
      value: "",
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      basicOrder: 0,
      advancedOrder: 7,
    },
    ReservationCodes: {
      id: "ReservationCode",
      elementType: "input",
      elementConfig: {
        name: "ReservationCode",
        type: "text",
        placeholder: "Enter one or more...",
      },
      labelInput: {
        labeltext: urlOrPageName === "/rental" ? "Rental Code" : "Res. Code",
      },
      value: "",
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      basicOrder: 3,
      advancedOrder: 3,
    },
    MembershipId: {
      id: "MembershipId",
      elementType: "input",
      elementConfig: {
        name: "MembershipId",
        type: "text",
        placeholder:  getMemberLabelByOrgRentalType() + ' or Loyalty ID'
      },
      labelInput: {
        labeltext: ADMIN_COMMON_CAPTION.CUSTOMER_ID,
      },
      value: "",
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      basicOrder: 0,
      advancedOrder: 12,
    },
    UnitNumber: {
      id: "UnitNumber",
      elementType: "input",
      elementConfig: {
        name: "UnitNumber",
        type: "text",
      },
      labelInput: {
        labeltext: "Vehicle ID",
      },
      value: "",
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      basicOrder: 0,
      advancedOrder: 9,
    },
    PhoneNo: {
      id: "PhoneNo",
      elementType: "input",
      elementConfig: {
        name: "PhoneNo",
        type: "text",
      },
      labelInput: {
        labeltext: "Phone",
      },
      value: "",
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      basicOrder: 0,
      advancedOrder: 16,
    },
    EmailAddress: {
      id: "EmailAddress",

      elementType: "input",
      elementConfig: {
        name: "EmailAddress",
        type: "text",
      },
      labelInput: {
        labeltext: "Email",
      },
      value: "",
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      basicOrder: 7,
      advancedOrder: 11,
    },
    SelectedChildOrgUnitId: {
      id: "SelectedChildOrgUnitId",
      elementType: "select",
      elementConfig: {
        name: "SelectedChildOrgUnitId",
        options: [{ key: "", displayValue: "Any Location" }],
      },
      labelInput: {
        labeltext: "Pickup Loc",
      },
      value: "",
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      basicOrder: 4,
      advancedOrder: 4,
    },
    OptInXpress: {
      id: "OptInXpress",
      name: "OptInXpress",
      elementType: "select",
      elementConfig: {
        name: "OptInXpress",
        options: [
          { key: "", displayValue: "All" },
          { key: "true", displayValue: "Opted" },
          { key: "false", displayValue: "Not Opted In" },
        ],
      },
      labelInput: {
        labeltext: "FOXPress",
      },
      value: "",
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      basicOrder: 8,
      advancedOrder: 8,
    },
    Voucher: {
      id: "Voucher",
      elementType: "select",
      elementConfig: {
        name: "Voucher",
        options: [
          { key: "", displayValue: "All" },
          { key: "true", displayValue: "Provided" },
          { key: "false", displayValue: "Not Provided" },
        ],
      },
      labelInput: {
        labeltext: "Voucher",
      },
      value: "",
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      basicOrder: 9,
      advancedOrder: 13,
    },
    ClassCode: {
      id: "ClassCode",
      elementType: "select",
      elementConfig: {
        name: "ClassCode",
        options: [{ key: "", displayValue: "All" }],
      },
      labelInput: {
        labeltext: "Vehicle Category",
      },
      value: "",
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      basicOrder: 0,
      advancedOrder: 14,
    },
    AddOns: {
      id: "AddOns",
      elementType: "select",
      elementConfig: {
        name: "AddOns",
        options: [
          { key: "", displayValue: "All" },
          { key: "true", displayValue: "With Add Ons" },
          { key: "false", displayValue: "Without Add Ons" },
        ],
      },
      labelInput: {
        labeltext: "Add Ons",
      },
      value: "",
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      basicOrder: 0,
      advancedOrder: 17,
    },
    ReservationCreationType: {
      id: "ReservationCreationType",
      elementType: "select",
      elementConfig: {
        name: "ReservationCreationType",
        options: [
          { key: "", displayValue: "All" },
          { key: "REGULAR", displayValue: "REGULAR" },
          { key: "TEST", displayValue: "TEST" },
        ],
      },
      labelInput: {
        labeltext: urlOrPageName === "/rental" ? "Rental Type" : "Res. Type",
      },
      value: "",
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      basicOrder: 0,
      advancedOrder: 18,
    },
    CreditType: {
      id: "CreditType",
      elementType: "select",
      elementConfig: {
        name: "CreditType",
        options: [
          { key: "", displayValue: "All" },
          { key: "GRANTED", displayValue: "Granted" },
          { key: "REDEEMED", displayValue: "Redeemed" },
          { key: "GRANTED_OR_REDEEMED", displayValue: "Granted / Redeemed" },
        ],
      },
      labelInput: {
        labeltext: "Credits",
      },
      value: "",
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      basicOrder: 0,
      advancedOrder: 21,
    },
    Reason: {
      id: "Reason",
      elementType: "select",
      elementConfig: {
        name: "Reason",
        options: [{ key: "", displayValue: "All" }],
      },
      labelInput: {
        labeltext: "Refund Reason",
      },
      value: "",
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      basicOrder: 0,
      advancedOrder: 19,
    },

    IntegStatuses: {
      id: "IntegStatuses",
      elementType: "cb_dropdown",
      elementConfig: {
        name: "IntegStatuses",
        options: [],
        selectMultipleOption: [],
      },
      labelInput: {
        labeltext: "Integ Status",
      },
      value: [],
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      basicOrder: 0,
      advancedOrder: 22,
    },
    LocationType: {
      id: "LocationType",
      name: "LocationType",
      elementType: "select",
      elementConfig: {
        name: "LocationType",
        options: [],
      },
      labelInput: {
        labeltext: "Location Type",
      },
      value: "",
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      basicOrder: 0,
      advancedOrder: 23,
    },
    BillingType: {
      id: "BillingType",
      name: "BillingType",
      elementType: "select",
      elementConfig: {
        name: "BillingType",
        options: [],
      },
      labelInput: {
        labeltext: "Billing Type",
      },
      value: "",
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      basicOrder: 0,
      advancedOrder: 24,
    },
    SearchByCreationDate: {
      elementType: "radio",
      value: "",
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      basicOrder: 0,
      advancedOrder: 0,
    },
    SearchByScheduledDate: {
      elementType: "radio",
      value: true,
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      basicOrder: 0,
      advancedOrder: 0,
    },
    SearchByCancellationDate: {
      elementType: "radio",
      value: "",
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      basicOrder: 0,
      advancedOrder: 0,
    },
    btnResSearch: {
      id: "btnResSearch",
      elementType: "button",
      elementConfig: {
        type: "submit",
        value: "Search",
        name: "btnResSearch",
        className: "btn btn-primary search-btn",
      },
      basicOrder: 5,
      advancedOrder: 5,
    },
    btnResSearchReset: {
      id: "btnResSearchReset",
      elementType: "button",
      elementConfig: {
        type: "button",
        value: "Reset",
        name: "btnResSearchReset",
        className: "btn btn-secondary search-btn",
      },
      basicOrder: 10,
      advancedOrder: 10,
    },
    empty: {
      id: "empty",
      elementType: "empty",
      elementConfig: {
        name: "",
        type: "empty",
      },
      basicOrder: 0,
      advancedOrder: 15,
    },
    empty1: {
      id: "empty",
      elementType: "empty",
      elementConfig: {
        name: "",
        type: "empty",
      },
      basicOrder: 0,
      advancedOrder: 20,
    },
    empty2: {
      id: "empty2",
      elementType: "empty",
      elementConfig: {
        name: "",
        type: "empty",
      },
      basicOrder: 0,
      advancedOrder: 25,
    },
  };
};
export const getMemberReservationCtrls = (urlOrPageName) => {
  const reservationCtrl = getReservationCtrls(urlOrPageName);
  delete reservationCtrl["MembershipId"];
  delete reservationCtrl["FullName"];
  delete reservationCtrl["FirstName"];
  delete reservationCtrl["LastName"];
  delete reservationCtrl["EmailAddress"];
  delete reservationCtrl["PhoneNo"];
  //delete reservationCtrl["empty1"];
  delete reservationCtrl["empty2"];
  reservationCtrl.ReservationCodes.basicOrder = 2;
  reservationCtrl.ReservationCodes.advancedOrder = 2;
  reservationCtrl.SelectedChildOrgUnitId.basicOrder = 3;
  reservationCtrl.SelectedChildOrgUnitId.advancedOrder = 3;
  reservationCtrl.UnitNumber.basicOrder = 4;
  reservationCtrl.UnitNumber.advancedOrder = 4;
  reservationCtrl.Voucher.basicOrder = 7;
  reservationCtrl.Voucher.advancedOrder = 7;
  reservationCtrl.OptInXpress.basicOrder = 8;
  reservationCtrl.OptInXpress.advancedOrder = 8;
  reservationCtrl.ClassCode.basicOrder = 9;
  reservationCtrl.ClassCode.advancedOrder = 9;
  reservationCtrl.AddOns.basicOrder = 0;
  reservationCtrl.AddOns.advancedOrder = 11;
  reservationCtrl.ReservationCreationType.basicOrder = 0;
  reservationCtrl.ReservationCreationType.advancedOrder = 12;
  reservationCtrl.Reason.basicOrder = 0;
  reservationCtrl.Reason.advancedOrder = 13;
  reservationCtrl.IntegStatuses.basicOrder = 0;
  reservationCtrl.IntegStatuses.advancedOrder = 14;
  reservationCtrl.empty.basicOrder = 0;
  reservationCtrl.empty.advancedOrder = 15;
  reservationCtrl.CreditType.basicOrder = 0;
  reservationCtrl.CreditType.advancedOrder = 16;
  reservationCtrl.LocationType.basicOrder = 0;
  reservationCtrl.LocationType.advancedOrder = 17;
  reservationCtrl.BillingType.basicOrder = 0;
  reservationCtrl.BillingType.advancedOrder = 18;
  return reservationCtrl;
};
export const getSearchResTopRadioBtnlist = () => {
  return [
    {
      id: "SearchByCreationDate",
      elementType: "radio",
      elementConfig: {
        name: "resSchedule",
        type: "radio",
        placeholder: "Date Created",
      },
      labelInput: {
        labeltext: "Date Created",
        labelFor: "SearchByCreationDate",
      },
      value: "",
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
    },
    {
      id: "SearchByScheduledDate",
      elementType: "radio",
      elementConfig: {
        name: "resSchedule",
        type: "radio",
        labelFor: "SearchByScheduledDate",
      },
      labelInput: {
        labeltext: "Rental Dates",
      },
      value: true,
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
    },
    {
      id: "SearchByCancellationDate",
      elementType: "radio",
      elementConfig: {
        name: "resSchedule",
        type: "radio",
        placeholder: "Date Cancelled",
      },
      labelInput: {
        labeltext: "Date Cancelled",
        labelFor: "SearchByCancellationDate",
      },
      value: "",
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
    },
  ];
};
export const getReservationFilters = () => {
  return {
    FilterInfo: [
      {
        FilterStates: {
          PENDING: {
            id: "filterRequestPending",
            elementType: "filter",
            color: "#D36C19",
            elementConfig: {
              name: "RequestPending",
              type: "checkbox",
              value: "PENDING",
              checked: true,
            },
            labelInput: {
              labeltext: "Req. Pending",
              className: "filterlbl_Pending",
            },
          },
          DECLINED: {
            id: "filterRequestDeclined",
            color: "#2299CF",
            elementType: "filter",
            elementConfig: {
              name: "Declined",
              type: "checkbox",
              value: "DECLINED",
              checked: false,
            },
            labelInput: {
              labeltext: "Req. Declined",
              className: "filterlbl_Declined",
            },
          },
          MESSAGE: {
            id: "filterMessage",
            color: "#D4BFFF",
            elementType: "filter",
            elementConfig: {
              name: "Message",
              type: "checkbox",
              value: "MESSAGE",
              checked: false,
            },
            labelInput: {
              labeltext: "Message",
              className: "filterlbl_Message",
            },
          },
          CONFIRMED: {
            id: "filterConfirmed",
            color: "#5DBD91",
            elementType: "filter",
            elementConfig: {
              name: "Confirmed",
              type: "checkbox",
              value: "CONFIRMED",
              checked: true,
            },
            labelInput: {
              labeltext: "Confirmed",
              className: "filterlbl_Confirmed",
            },
          },
          NOT_STARTED: {
            id: "filterNotStarted",
            color: "#ff0000",
            elementType: "filter",
            elementConfig: {
              name: "NotStarted",
              type: "checkbox",
              value: "NOT_STARTED",
              checked: true,
            },
            labelInput: {
              labeltext: "Not Started",
              className: "filterlbl_NotStarted",
            },
          },
          CANCELLED: {
            id: "filterCancelled",
            color: "#ff6a00",
            elementType: "filter",
            elementConfig: {
              name: "Cancelled",
              type: "checkbox",
              value: "CANCELLED",
              checked: false,
            },
            labelInput: {
              labeltext: "Cancelled",
              className: "filterlbl_Cancelled",
            },
          },
          INCOMPLETE: {
            id: "filterIncompleted",
            color: "#ffd800",
            elementType: "filter",
            elementConfig: {
              name: "Incompleted",
              type: "checkbox",
              value: "INCOMPLETE",
              checked: true,
            },
            labelInput: {
              labeltext: "Incomplete",
              className: "filterlbl_Incomplete",
            },
          },
          CONTRACT_PENDING: {
            id: "filterContractPending",
            color: "#FF00FF",
            elementType: "filter",
            elementConfig: {
              name: "ContractPending",
              type: "checkbox",
              value: "CONTRACT_PENDING",
              checked: false,
            },
            labelInput: {
              labeltext: "Contract Pending",
              className: "filterlbl_ContractPending",
            },
          },
          PAYMENT_PENDING: {
            id: "filterPaymentPending",
            color: "#6b2222",
            elementType: "filter",
            elementConfig: {
              name: "PaymentPending",
              type: "checkbox",
              value: "PAYMENT_PENDING",
              checked: false,
            },
            labelInput: {
              labeltext: "Payment Pending",
              className: "filterlbl_PaymentPending",
            },
          },
          BACKGROUND_CHECK_PENDING: {
            id: "filterBackgroundCheckPending",
            color: "#de2f13",
            elementType: "filter",
            elementConfig: {
              name: "BackgroundCheckPending",
              type: "checkbox",
              value: "BACKGROUND_CHECK_PENDING",
              checked: true,
            },
            labelInput: {
              labeltext: "Bg Check Pending",
              className: "filterlbl_BgCheckPending",
            },
          },
          WEBERROR: {
            id: "filterWebError",
            color: "darkgoldenrod",
            elementType: "filter",
            elementConfig: {
              name: "WebError",
              type: "checkbox",
              value: "WEBERROR",
              checked: false,
            },
            labelInput: {
              labeltext: "Web Error",
              className: "filterlbl_WebError",
            },
          },
        },
        Name: "Reservation",
      },
    ],
  };
};
export const getRentalFilters = () => {
  return {
    FilterInfo: [
      {
        FilterStates: {
          IN_PROGRESS: {
            id: "filterInProgress",
            color: "#EACF12",
            elementType: "filter",
            elementConfig: {
              name: "InProgress",
              type: "checkbox",
              value: "IN_PROGRESS",
              checked: true,
            },
            labelInput: {
              labeltext: "In Progress",
              className: "filterlbl_InProgress",
            },
          },
          COMPLETED: {
            id: "filterCompleted",
            color: "#8DACB0",
            elementType: "filter",
            elementConfig: {
              name: "Completed",
              type: "checkbox",
              value: "COMPLETED",
              checked: true,
            },
            labelInput: {
              labeltext: "Completed",
              className: "filterlbl_Completed",
            },
          },
          LATE: {
            id: "filterLate",
            color: "#D98880",
            elementType: "filter",
            elementConfig: {
              name: "Late",
              type: "checkbox",
              value: "LATE",
              checked: true,
            },
            labelInput: {
              labeltext: "Late",
              className: "filterlbl_Late",
            },
          },
          RETURNED_LATE: {
            id: "filterReturnedLate",
            color: "#FF00FF",
            elementType: "filter",
            elementConfig: {
              name: "ReturnedLate",
              type: "checkbox",
              value: "RETURNED_LATE",
              checked: true,
            },
            labelInput: {
              labeltext: "Returned Late",
              className: "filterlbl_ReturnedLate",
            },
          },
          CANCELLED: {
            id: "filterCancelled",
            color: "#ff6a00",
            elementType: "filter",
            elementConfig: {
              name: "Cancelled",
              type: "checkbox",
              value: "CANCELLED",
              checked: true,
            },
            labelInput: {
              labeltext: "Cancelled",
              className: "filterlbl_Cancelled",
            },
          },
        },
        Name: "Rental",
      },
    ],
  };
};
export const getMemberReservationFilters = () => {
  const reservationFilters = getReservationFilters();
  delete reservationFilters.FilterInfo[0]["FilterStates"]["CANCELLED"];
  const rentalFilters = getRentalFilters();
  return {
    FilterInfo: [
      {
        ...reservationFilters.FilterInfo[0],
      },
      {
        ...rentalFilters.FilterInfo[0],
      },
    ],
  };
};

import React, { useEffect, useState } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import Draggable from "react-draggable";
import { showNotification } from "../../../components/UI/Toaster/Toaster";
import {
  getFleetUtilizationLogsInfo,
  getFleetUtilizationLogsReset,
} from "../../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import {
  convertMsgResponse,
} from "../../Reservation/fox-reservation/fox-res-helper";
import { FleetUtilizationLogsModel } from "../../../services/entities/models/fleet-utilization/fleet-utilization-logs-model";
import moment from "moment";
import { AdminDateFromat } from "../../../shared/utility/enum-utility";

export const ReserveSyncActivityLogsModal = (props) => {
  const dispatch = useDispatch();
  const fleetUtilizationLogResponse = useSelector(
    (state) => state.fleetUtilizationForecastReducer.fleetUtilizationLogResponse
  );
  const [fleetUtilizationLogs, setFleetUtilizationLogs] = useState([]);
  useEffect(() => {
    if (props.selectedData.fleetUtilizationForecastId) {
      getFleetUtilizationLogs(props.selectedData.fleetUtilizationForecastId);
    }
  }, [props.selectedData.fleetUtilizationForecastId]);

  useEffect(() => {
    if (!fleetUtilizationLogResponse) {
      return;
    }
    if (
      fleetUtilizationLogResponse.message &&
      fleetUtilizationLogResponse.message.messageType === "SUCCESS"
    ) {
      setFleetUtilizationLogs(
        new FleetUtilizationLogsModel(fleetUtilizationLogResponse)
      );
    } else {
      showNotification(convertMsgResponse(fleetUtilizationLogResponse.message));
    }
    dispatch(getFleetUtilizationLogsReset());
  }, [fleetUtilizationLogResponse]);

  const getFleetUtilizationLogs = (fleetForecastId) => {
    const reqObj = {
      forecastId: fleetForecastId,
    };
    dispatch(getFleetUtilizationLogsReset());
    dispatch(getFleetUtilizationLogsInfo(reqObj));
  };

  const renderActivityLogContent = (element) => {
    return (
      <div className="d-flex">
        <i className="fas fa-history history-icon fs-08"></i>
        <div key={element.lastTimestamp} className="pl-2 flex-1">
          <div className="font-weight-bold fs-2">
            <span>
              {moment(element.lastTimestamp).format(AdminDateFromat.COLUMN_FORMAT)}
            </span>
          </div>
          <div className="d-flex fs-0">
            <span>{moment(element.lastTimestamp).format("hh:mm A")}</span>
            <i className="fas fa-circle pl-3 pr-3 light-gray-color flex-align-self-center" style={{fontSize:"0.5rem"}}></i>
            <span>{"Override By: " + element.personName}</span>
          </div>
          <div className="fs-2">
            <span>{element.longMessage}</span>
          </div>
          <div className="fs-0">
            <span className="font-weight-bold">{"Override Reason:"}</span>
            <span className="pl-2">{element.overrideReason}</span>
          </div>
          <hr className="mt-3" />
        </div>
      </div>
    );
  };

  const logsContentTitle = props.selectedData
    ? props.selectedData.displayText +
      " Activity Logs (" +
      moment(props.selectedData.forecastDate).format(AdminDateFromat.COLUMN_FORMAT) +
      ")"
    : "Activity Logs";
  return (
    <Draggable handle=".cursor">
      <Modal
        isOpen={props.showModal}
        className="no-cursor fleet-logs-modal"
        // toggle={() => {
        //   props.closeModal();
        // }}
        style={{ minWidth: "40rem" }}
      >
        <ModalHeader
          className="modal-header modal-title cursor"
          toggle={() => {
            props.closeModal();
          }}
        >
          Fleet Utilization Activity Logs
        </ModalHeader>
        <ModalBody>
          <div className="col-sm-12 p-0">
            <h4 className="content-title text-center">{logsContentTitle}</h4>
            <hr className="pt-2" />
            <div
              style={{
                maxHeight: "60rem",
                minHeight: "20rem",
                maxWidth: "40rem",
              }}
              className="tableScroll"
            >
              {fleetUtilizationLogs.FleetUtilizationLogs &&
                fleetUtilizationLogs.FleetUtilizationLogs.map(
                  (element, index) => renderActivityLogContent(element)
                )}
            </div>
          </div>
        </ModalBody>
      </Modal>
    </Draggable>
  );
};
export default ReserveSyncActivityLogsModal;

import React, { Fragment } from "react";
import ReactHtmlParser from "react-html-parser";
import CommonInputField from "../../../components/UI/Input/common-input-field";
import { LabelAndValuePairControl } from "../../../components/UI/Input/label-value-pair-control";
import {
  getDisplayTextOfDropdown,
  getDropdownItems,
  getFormattedPhoneNumber,
} from "../../../shared/utility";
import LandmarkAddressComponent from "../../../components/common/address-component/landmark-address-component";
import { getNormalFormattedPhoneNumber } from "../../../shared/utility/phone-utility";
const LandmarkDetailsUI = (props) => {
  const isEditMode = props.isEditMode;
  const landmarkData = props.landmarkData || {};
  const labelCaptionClass = "col-lg-4";
  const labelValueClass = "col-sm-8";
  const labelCaptionClassBold =
    labelCaptionClass + " font-weight-bold col-form-label";
  const renderRadioButtons = (id, value, labelCaption, name) => {
    return (
      <CommonInputField
        elementConfig={{
          id: id,
          type: "radio",
          name: name,
          checked: landmarkData[name] === value,
          value: value,
        }}
        otherConfig={{
          caption: labelCaption,
        }}
        nodeElement="radio"
        onChange={props.inputChangedHandler}
      />
    );
  };
  const getLblHtmlCtrl = (value, customClasses) => {
    return (
      <label className={`col-sm-12 p-0 ${customClasses || ""}`} title={value}>
        {value || "--"}
      </label>
    );
  };
  const renderLabelInputPair = (
    labelCaption,
    controlName,
    value,
    isRequired = false,
    isHideLabel = false,
    nodeElement = "input",
    dropdownItem = [],
    customDisplayValue = null
  ) => {
    const isPhoneNumber = controlName === "phone";
    const isNumberField = ["latitude", "longitude"].includes(controlName);
    const isCheckbox = nodeElement === "checkbox";
    const valueOrCheckedAttr = isCheckbox ? "checked" : "value";
    const minValueAndStepAttr = isNumberField ? { step: "1" } : {};
    const onChangehandler = props.inputChangedHandler;
    const maxLengthAttr = controlName === "shortName" ? { maxLength: 2 } : {};
    // const getNormalFormattedPhoneNumber = (phone) => {
    //   return phone && phone.FormattedNumber
    //     ? (phone.CountryCode ? "+" + phone.CountryCode : "") + " " + phone.FormattedNumber
    //     : "--";
    // };
    
    const handleOnChange = (value, data, event, formattedValue) => {
      let landmarkDataDummy = JSON.parse(JSON.stringify(landmarkData))
      if(event.type === "change"){
        props.inputPhoneChangeHandler(value, data, event, formattedValue)
      }
      if(["keydown","click"].includes(event.type)){
        if(event.target.parentElement){
          let classList = event.target.parentElement.parentElement && event.target.parentElement.parentElement.classList
          if(!classList){
            classList = event.target.parentElement.classList
          }
          if(classList.length>1){
            let nodeName = classList[1];
            landmarkDataDummy[nodeName].CountryCode = data.dialCode
            landmarkDataDummy[nodeName].CountryIso = data.countryCode.toUpperCase()
            props.setLandmarkData(landmarkDataDummy)
          }
        }
      }  
    };
    return (
      <LabelAndValuePairControl
        elementConfig={{
          name: controlName,
          type: isCheckbox ? "checkbox" : isNumberField ? "number" : null,
          [valueOrCheckedAttr]: value || "",
          ...minValueAndStepAttr,
          ...maxLengthAttr,
        }}
        otherConfig={{
          caption: labelCaption,
          isRequired: isRequired,
          dropdownItem: nodeElement === "select" ? dropdownItem : [],
          customDisplayValue: customDisplayValue,
          isHideLabel: isHideLabel,
          phoneNumberDisplayValue: isPhoneNumber
            ? getNormalFormattedPhoneNumber(value)
            : "",
          toolTipMsg: isPhoneNumber
            ? ReactHtmlParser(
                getFormattedPhoneNumber(
                  value ? value : null,
                  null,
                  null,
                  "tooltip"
                )
              )
            : "",
          isForceShow: isPhoneNumber ? true : false,
          // phoneNumberParentClasses: isPhoneNumber
          //   ? "memberInput memberInput-phone"
          //   : "",
        }}
        isEditMode={isEditMode}
        onChange={onChangehandler}
        onChangePhone={handleOnChange}
        renderMobileAutoSueggestion={props.renderMobileAutoSueggestion}
        parentRowClass={`form-group row ${
          isEditMode && isRequired ? "required" : ""
        }`}
        parentRowStyle={ nodeElement==="textarea"? {height:"8rem"}:{}}
        labelClasses={labelCaptionClassBold}
        valueClasses={labelValueClass}
        nodeElement={nodeElement}
        isValidateDone={props.isValidateDone}
      />
    );
  };
  return (
    <Fragment>
      <form id="landmark-info">
        <div
          className={`row pl-4 ${props.isEditMode ? "background-edit" : ""}`}
        >
          <div className="col-lg-6 p-0">
            {renderLabelInputPair(
              "Location",
              "orgUnit",
              landmarkData.orgUnit && landmarkData.orgUnit.locationCode
                ? isEditMode
                  ? landmarkData.orgUnit.locationCode
                  : landmarkData.orgUnit.name
                : "",
              true,
              false,
              "select",
              getDropdownItems(
                props.dependentOrgList,
                "locationCodes",
                "displayName",
                "Any Location"
              )
            )}
            {renderLabelInputPair(
              "Category",
              "landmarkCategory",
              landmarkData.landmarkCategory
                ? isEditMode
                  ? landmarkData.landmarkCategory
                  : getDisplayTextOfDropdown(
                      props.landmarkCategories,
                      landmarkData.landmarkCategory,
                      "key",
                      "displayValue"
                    )
                : "",
              true,
              false,
              "select",
              props.landmarkCategories
            )}
            {renderLabelInputPair(
              "Code",
              "landmarkCode",
              landmarkData.landmarkCode,
              true
            )}
            {renderLabelInputPair("Name", "name", landmarkData.name, true)}
            {renderLabelInputPair(
              "Short Name",
              "shortName",
              landmarkData.shortName,
              true
            )}
            {renderLabelInputPair(
              "Latitude",
              "latitude",
              landmarkData.latitude,
              true
            )}
            {renderLabelInputPair(
              "Longitude",
              "longitude",
              landmarkData.longitude,
              true
            )}

            {renderLabelInputPair(
              "Phone",
              "phone",
              landmarkData.phone,
              false,
              false,
              "phonenumberwithflag"
            )}
          </div>
          <div className="col-lg-6 p-0">
            <LandmarkAddressComponent
              key={"PRIMARY"}
              address={landmarkData.address}
              type={"PRIMARY"}
              onChangeMethod={props.inputAddressChangeHandler}
              isNoneEditableMode={!isEditMode}
              street1Label="Street 1"
              street2Label="Street 2"
              disabled={false}
              isNew={props.isNew}
              setLoading={props.setLoading}
            />

            {renderLabelInputPair(
              "Description",
              "description",
              landmarkData.description,
              false,
              false,
              "textarea"
            )}

            <div className={`form-group row ${isEditMode ? "required" : ""}`}>
              <label className={labelCaptionClassBold}>Status</label>

              <div className="col-sm-8">
                {isEditMode ? (
                  <div className="memberInput">
                    {renderRadioButtons(
                      "rbStatusEnabled",
                      "ENABLED",
                      "Enabled",
                      "landmarkStatus"
                    )}
                    {renderRadioButtons(
                      "rbStatusDisabled",
                      "DISABLED",
                      "Disabled",
                      "landmarkStatus"
                    )}
                  </div>
                ) : (
                  getLblHtmlCtrl(
                    landmarkData && landmarkData["landmarkStatus"] === "ENABLED"
                      ? "Enabled"
                      : "Disabled"
                  )
                )}
              </div>
            </div>
          </div>
        </div>
        {isEditMode ? (
          <div className="form-footer" style={{ textAlign: "center" }}>
            <button
              type="button"
              name="btnSave"
              className="btn btn-primary"
              onClick={props.doAddOrEditLandmark}
            >
              {landmarkData.landmarkId ? "Update" : "Save"}
            </button>
            <button
              type="button"
              name="btnCancel"
              className="btn btn-secondary ml-2"
              onClick={props.onCancel}
            >
              Cancel
            </button>
          </div>
        ) : (
          ""
        )}
      </form>
    </Fragment>
  );
};
export default LandmarkDetailsUI;

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteVehicle,
  deleteVehicleReset,
} from "../../../store/actions/index";
import Draggable from "react-draggable";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { getBasicToastResponse } from "../../../shared/utility";
import { showNotification } from "../../../components/UI/Toaster/Toaster";

export const DeleteVehicleModal = (props) => {
  const vehicleId = props.vehicleId;
  const deleteVehicleResponse = useSelector(
    (state) => state.vehicleReducer.deleteVehicleResponse
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (!deleteVehicleResponse) {
      return;
    }
    showNotification(getBasicToastResponse(deleteVehicleResponse));
    if (deleteVehicleResponse.StatusCode === 0) {
      props.closeModal();
      props.resetParentPage();
    }
    dispatch(deleteVehicleReset());
  }, [deleteVehicleResponse]);

  const onDeleteClick = () => {
    const deleteAddressReq = {};
    deleteAddressReq.MemberAssetId = vehicleId;
    dispatch(deleteVehicleReset());
    dispatch(deleteVehicle(deleteAddressReq));
  };

  return (
    <Draggable handle=".cursor">
      <Modal
        isOpen={props.showModal}
        className="no-cursor"
        toggle={() => {
          props.closeModal();
        }}
      >
        <ModalHeader
          className="modal-header bg-primary modal-title text-white cursor"
          toggle={() => {
            props.closeModal();
          }}
        >
          <i className="fas fa-arrows-alt" /> Delete Vehicle
        </ModalHeader>
        <ModalBody>
          <div className="input-container">
            <div className="FormRow">
              <div className={`form-group formRow`}>
                <p
                  className="col-sm-12 col-form-label font-weight-bold"
                  style={{ textAlign: "justify" }}
                >
                  Are you sure, you want to delete the Vehicle "
                  {props.vehicleName}"?
                </p>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            className="btn btn-primary  waves-effect waves-light btnSize ml-2"
            onClick={(event) => onDeleteClick()}
          >
            Yes
          </button>
          <button
            type="button"
            className="btn btn-secondary  waves-effect waves-light btnSize ml-2"
            onClick={(event) => props.closeModal()}
          >
            No
          </button>
        </ModalFooter>
      </Modal>
    </Draggable>
  );
};
export default DeleteVehicleModal;

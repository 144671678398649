import Phone from "../models/Phone";
import Address from "../models/Address";
import DrivingLicense from "../models/DrivingLicense";
import { getFullName } from "../../../shared/utility";
export const AddQuickMemberViewModel = function (data) {
  data = data || {};
  this.TempId = data.TempId || null;
  this.MemberId = data.MemberId || null;
  this.PersonBlackListId = data.PersonBlackListId || null;
  this.FirstName = data.FirstName || null;
  this.MiddleName = data.MiddleName || null;
  this.LastName = data.LastName || null;
  this.FullName = getFullName(data.FirstName, data.LastName, data.MiddleName);
  this.EmailAddress = data.EmailAddress || null;
  this.Reason = data.Reason || null;
  this.MobilePhone = new Phone(data.MobilePhone);
  this.PrimaryAddress = new Address(data.PrimaryAddress);
  this.DrivingLicense = new DrivingLicense(data.DrivingLicense);
};

import PageInfo from "../../models/PageInfo";

const FoxMemberSearchRequest = function(data) {
  if(!data) data={};
  this.SearchType = data.SearchType || null;
  this.SearchValue = data.SearchValue || null;
  this.FullName = data.FullName || null;
  this.FirstName = data.FirstName || null;
  this.LastName = data.LastName || null;
  this.MiddleName = data.MiddleName || null;
  this.EmailAddress = data.EmailAddress || null;
  this.dob = data.dob || null;
  this.DateOfBirth = data.DateOfBirth || null;
  this.StartJoinDate = data.StartJoinDate || null;
  this.EndJoinDate = data.EndJoinDate || null;
  this.DrivingLicenseNumber = data.DrivingLicenseNumber || null;
  this.PersonalNumber = data.PersonalNumber || null;
  this.Address = data.Address || null;
  this.ZipCode = data.ZipCode || null;
  this.CountryId = data.CountryId || null;
  this.StateId = data.StateId || null;
  this.CityId = data.CityId || null;
  this.ZipCodeId = data.ZipCodeId || null;
  this.IncludeInactive = data.IncludeInactive || null;
  this.OptInXpress = data.OptInXpress || null;
  this.IsEmployee = data.IsEmployee || null;
  this.EmployeeNo = data.EmployeeNo || null;
  this.EmployeeLocationId = data.EmployeeLocationId || null;
  this.BadgeNo = data.BadgeNo || null;
  this.PageInfo = new PageInfo;
};
export default FoxMemberSearchRequest;

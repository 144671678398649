const MessageSearchModel = function (data) {
  if (!data) data = {};
  this.ConfirmationCode = data.ConfirmationCode || null;
  this.CreatedForName = data.CreatedForName || null;
  this.MemberId = data.MemberId || null;
  this.MemberImage = data.MemberImage || null;
  this.MemberName = data.MemberName || null;
  this.MessageDate = data.MessageDate || null;
  this.MessageDescription = data.MessageDescription || null;
  this.Originator = data.Originator || null;
  this.ReservationId = data.ReservationId || null;
  this.Viewed = data.Viewed || null;
};
export default MessageSearchModel;

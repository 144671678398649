import * as actionTypes from "../actions/actionTypes";
import { reducerUpdateObject } from "./reducer.utility";

const initialState = {
  attachedDocListResponse: null,
  addAttachedDocResponse: null,
  loading: false
};

const getAttachedDocListStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};

const getAttachedDocListSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    attachedDocListResponse: action.attachedDocListResponse,
    error: null,
    loading: false
  });
};
const getAttachedDocListFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false
  });
};
const getAttachedDocListReset = (state, action) => {
  return reducerUpdateObject(state, {
    attachedDocListResponse: null
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ATTACHED_DOC_START:
      return getAttachedDocListStart(state, action);
    case actionTypes.GET_ATTACHED_DOC_SUCCESS:
      return getAttachedDocListSuccess(state, action);
    case actionTypes.GET_ATTACHED_DOC_FAIL:
      return getAttachedDocListFail(state, action);
    case actionTypes.GET_ATTACHED_DOC_RESET:
      return getAttachedDocListReset(state, action);

    default:
      return state;
  }
};
export default reducer;

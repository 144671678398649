import React, { useEffect, useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  doCaptureReleaseRefund,
  doCaptureReleaseRefundReset,
  getEnumvalues,
} from "../../../store/actions/index";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Draggable from "react-draggable";
import { showNotification } from "../../../components/UI/Toaster/Toaster";
import { ModalType, TOAST_MSG } from "../../../shared/GlobalVar";
import { validateManagePaymentServiceRequest } from "../billing-helper";
import {
  formatPricingValue,
  getBasicToastResponse,
  updateObject,
} from "../../../shared/utility";
import ManagePaymentServiceRequest from "../../../services/entities/requests/ManagePaymentServiceRequest";
import OverrideComponent from "../../../components/UI/overrides/override-component";
import { getOverrideInputControls } from "../../../components/UI/overrides/override-input";

export const CaptureReleaseRefundModal = (props) => {
  const paymentData = props.defaultData;
  const captureReleaseRefundResponse = useSelector(
    (state) => state.transactionReducer.captureReleaseRefundResponse
  );
  const paymentReasonTypes = useSelector(
    (state) => state.enumvaluesReducer.paymentReasonTypes
  );
  const selectedOrgInfo = useSelector((state) => ({
    selectedOrgUnitId: state.auth.loggedInUserData.OrgUnitId,
    orgUnitRentalTypes: state.auth.loggedInUserData.OrgUnitSettings.RentalType,
    orgName: state.auth.loggedInUserData.OrgUnitSettings.DisplayName,
  }));
  const [managePaymentServiceReq, setManagePaymentServiceReq] = useState(
    new ManagePaymentServiceRequest()
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(doCaptureReleaseRefundReset());
    if (!paymentReasonTypes) {
      dispatch(getEnumvalues());
    }
  }, []);
  useEffect(() => {
    if (!captureReleaseRefundResponse) {
      return;
    }
    showNotification(getBasicToastResponse(captureReleaseRefundResponse));
    if (captureReleaseRefundResponse.StatusCode === 0) {
      dispatch(doCaptureReleaseRefundReset());
      props.closeModal(true);
    }
    dispatch(doCaptureReleaseRefundReset());
  }, [captureReleaseRefundResponse]);
  const handleOnChange = ({ target }) => {
    let updateCtrls = { ...managePaymentServiceReq };
    const value = target.type === "checkbox" ? target.checked : target.value;
    updateCtrls = updateObject(updateCtrls, {
      [target.name]: value,
    });
    setManagePaymentServiceReq(updateCtrls);
  };
  const onManagePaymentRequest = () => {
    const managePaymentServiceRequest = { ...managePaymentServiceReq };
    managePaymentServiceRequest.MemberId = parseInt(paymentData.MemberId);
    managePaymentServiceRequest.ReservationId = parseInt(
      paymentData.ReservationId
    );
    managePaymentServiceRequest.TransId = paymentData.TransactionId
      ? parseInt(paymentData.TransactionId)
      : null;
    managePaymentServiceRequest.RefTransId = paymentData.IntegRefTransId
      ? parseInt(paymentData.IntegRefTransId)
      : null;
    managePaymentServiceRequest.PaymentType = "RESERVATION";
    managePaymentServiceRequest.TransactionType =
      paymentData.NewTransactionType;
    managePaymentServiceRequest.Amount = managePaymentServiceReq.Amount
      ? parseFloat(managePaymentServiceReq.Amount)
      : null;

    const msg = validateManagePaymentServiceRequest(
      managePaymentServiceRequest
    );
    if (msg) {
      var toastMsg = {
        msg: msg,
        toastType: TOAST_MSG.ERROR,
      };
      showNotification(toastMsg);
    } else {
      dispatch(doCaptureReleaseRefundReset());
      dispatch(doCaptureReleaseRefund(managePaymentServiceRequest));
    }
  };

  const getHeaderName = () => {
    let headerName = "Process Payment";
    if (paymentData.NewTransactionType === "REFUND") {
      headerName = "Refund Payment";
    } else if (paymentData.NewTransactionType === "CAPTURE") {
      headerName = "Process Payment";
    } else if (paymentData.NewTransactionType === "VOID") {
      if (paymentData.ParentTransactionType === "PRE_AUTHORIZATION") {
        headerName = "Release Pre-Authorization";
      } else if (paymentData.ParentTransactionType === "PAYMENT") {
        headerName = "Void Payment";
      } else if (paymentData.ParentTransactionType === "REFUND") {
        headerName = "Void Refund";
      }
    }
    return headerName;
  };
  return (
    <Draggable handle=".cursorSubModal">
      <Modal
        isOpen={props.showModal}
        className="no-cursorSubModal"
        toggle={() => {
          props.closeModal();
        }}
        style={{ minWidth: "42.5rem" }}
      >
        <ModalHeader
          className="modal-header bg-primary modal-title text-white cursorSubModal"
          toggle={() => {
            props.closeModal();
          }}
        >
          <i className="fas fa-arrows-alt" /> {getHeaderName()}
        </ModalHeader>
        <ModalBody>
          <div className="col-lg-12 p-0">
            {paymentData.NewTransactionType === "REFUND" ? (
              <div className="form-group row p-0">
                <label className="col-sm-4 col-form-label font-weight-bold">
                  Refund Reason
                </label>
                <div className="col-sm-8">
                  <select
                    id="voidCaptureRefund-Reason"
                    type="select"
                    className="form-control"
                    name="Reason"
                    value={managePaymentServiceReq.Reason || ""}
                    onChange={(event) => handleOnChange(event)}
                  >
                    <option value="" key="0">
                      Select
                    </option>
                    {paymentReasonTypes &&
                      paymentReasonTypes.map((element, index) => (
                        <option key={element.Name} value={element.Name}>
                          {element.DisplayName}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="form-group row p-0">
              <label className="col-sm-4 col-form-label font-weight-bold">
                Processed By
              </label>
              <div className="col-sm-8">
                <select
                  id="voidCaptureRefund-ProcessedBy"
                  type="select"
                  className="form-control"
                  name="ProcessedBy"
                  value={managePaymentServiceReq.ProcessedBy || ""}
                  onChange={(event) => handleOnChange(event)}
                >
                  <option value="">Select</option>
                  {selectedOrgInfo.selectedOrgUnitId === 100 ||
                  selectedOrgInfo.orgUnitRentalTypes === "P2P" ? (
                    <option value="OWNER">
                      {paymentData.PersonAssetOwnerName}
                    </option>
                  ) : (
                    <option value="ORG_NAME">{selectedOrgInfo.orgName}</option>
                  )}
                  <option value="CUSTOMER">{paymentData.BorrowerName}</option>
                  <option value="JSI_PERSON">JustShareIt</option>
                </select>
              </div>
            </div>
            <div className="row form-group p-0">
              <label
                htmlFor="voidCaptureRefund-Comments"
                className="col-sm-4 col-form-label font-weight-bold"
              >
                Comments
              </label>
              <div className="col-sm-8">
                <textarea
                  id="voidCaptureRefund-Comments"
                  className="form-control"
                  name="Comments"
                  value={managePaymentServiceReq.Comments || ""}
                  onChange={(event) => handleOnChange(event)}
                />
              </div>
            </div>
            {paymentData.NewTransactionType === "REFUND" ? (
              <Fragment>
                <div className="form-group row p-0">
                  <label
                    htmlFor="voidCaptureRefund-CityId"
                    className="col-sm-4 col-form-label font-weight-bold"
                  >
                    Amount Paid
                  </label>
                  <label
                    htmlFor="voidCaptureRefund-CityId"
                    className="col-sm-8 col-form-label font-weight-bold"
                  >
                    {formatPricingValue(paymentData.Amount, "$0.00")}
                  </label>
                </div>
                <div className="form-group row p-0">
                  <label
                    htmlFor="voidCaptureRefund-CityId"
                    className="col-sm-4 col-form-label font-weight-bold"
                  >
                    Amount Left
                  </label>
                  <label
                    htmlFor="voidCaptureRefund-CityId"
                    className="col-sm-8 col-form-label font-weight-bold"
                  >
                    {paymentData.TotalRefundAmount
                      ? formatPricingValue(
                          parseInt(paymentData.Amount) -
                            parseInt(paymentData.TotalRefundAmount)
                        )
                      : "$0.00"}
                  </label>
                </div>
              </Fragment>
            ) : (
              ""
            )}
            {paymentData.NewTransactionType !== "VOID" ? (
              <div className="row form-group p-0">
                <label
                  htmlFor="voidCaptureRefund-Comments"
                  className="col-sm-4 col-form-label font-weight-bold"
                >
                  {paymentData.NewTransactionType !== "REFUND"
                    ? "Refund Amount"
                    : "Charged Amount"}
                </label>
                <div className="col-sm-8">
                  <label
                    className="font-weight-bold"
                    style={{ position: "absolute", left: "0" }}
                  >
                    $
                  </label>
                  <input
                    id="voidCaptureRefund-Comments"
                    type="number"
                    step="0.01"
                    className="form-control"
                    name="Amount"
                    value={managePaymentServiceReq.Amount || ""}
                    onChange={(event) => handleOnChange(event)}
                  ></input>
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="form-group p-0">
              <OverrideComponent
                overrideItems={getOverrideInputControls(
                  managePaymentServiceReq,
                  handleOnChange,
                  ModalType.TRANSACTION_MODAL,
                  paymentData.NewTransactionType
                )}
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="col-lg-12 text-right p-0">
            <button
              type="button"
              className="btn btn-primary waves-effect waves-light btnSize"
              onClick={() => onManagePaymentRequest()}
            >
              Submit
            </button>
            <button
              type="button"
              className="btn btn-secondary  waves-effect waves-light btnSize ml-2"
              onClick={() => props.closeModal()}
            >
              Cancel
            </button>
          </div>
        </ModalFooter>
      </Modal>
    </Draggable>
  );
};
export default CaptureReleaseRefundModal;

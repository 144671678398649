import React, { Fragment } from "react";
import Rating from "react-rating";
import { getDateTimeString } from "../../../shared/utility";
const FeedbackUI = (props) => {
  const getRating = (rating, isDisabled) => {
    return (
      <Rating
        initialRating={rating || 0}
        readonly={isDisabled}
        stop={5}
        fractions={2}
        emptySymbol={[
          "far fa-star fa-2x",
          "far fa-star fa-2x",
          "far fa-star fa-2x",
          "far fa-star fa-2x",
          "far fa-star fa-2x",
        ]}
        fullSymbol={[
          "fas fa-star fa-2x text-warning",
          "fas fa-star fa-2x text-warning",
          "fas fa-star fa-2x text-warning",
          "fas fa-star fa-2x text-warning",
          "fas fa-star fa-2x text-warning",
        ]}
      />
    );
  };
  const distinguishFeedbackQuestions = () => {
    const questionObj = {};
    if (props.feedbackData.Category === "SHARER") {
      questionObj.FriendlyQuestion =
        props.feedbackQuestions.SharerRatingFriendlinessDesc;
      questionObj.ParkingQuestion =
        props.feedbackQuestions.SharerRatingParkingDesc;
      questionObj.RatingCleanSafe =
        props.feedbackQuestions.SharerRatingCleanSafeDesc;
      questionObj.Punctuality =
        props.feedbackQuestions.SharerRatingDescriptionDesc;
    } else {
      questionObj.FriendlyQuestion =
        props.feedbackQuestions.BorrowerRatingFriendlinessDesc;
      questionObj.ParkingQuestion =
        props.feedbackQuestions.BorrowerRatingParkingDesc;
      questionObj.RatingCleanSafe =
        props.feedbackQuestions.BorrowerRatingCleanSafeDesc;
      questionObj.Punctuality =
        props.feedbackQuestions.BorrowerRatingPunctualityDesc;
    }
    return questionObj;
  };
  const addFeedBack = (type) => {};

  const feedbackQuestions = distinguishFeedbackQuestions(
    props.feedbackQuestions
  );
  const feedbackObj = props.feedbackData;
  return (
    <Fragment>
      <h4 className="form-header text-uppercase">
        <i className="fas fa-user-circle" />
        {feedbackObj.Category === "BORROWER" ? "Renter Info" : "Owner Info"}
      </h4>
      <div className="form-group row">
        <label className="col-sm-3 col-form-label font-weight-bold">
          {feedbackObj.Category === "BORROWER" ? "Renter" : "Owner"}
        </label>
        <label className="col-sm-9 col-form-label">
          {feedbackObj.PersonForName || "--"}
        </label>
      </div>
      <div className="form-group row">
        <label className="col-sm-3 col-form-label font-weight-bold">
          Feedback Type
        </label>
        <label className="col-sm-9 col-form-label">
          {feedbackObj.FeedbackType || "--"}
        </label>
      </div>
      <div className="form-group row">
        <label className="col-sm-3 col-form-label font-weight-bold">Date</label>
        <label className="col-sm-9 col-form-label">
          {getDateTimeString(feedbackObj.FeedbackDate) || "--"}
        </label>
      </div>
      <h4 className="form-header text-uppercase">
        <i className="fas fa-star" />
        Rating Information
      </h4>
      <div className="form-group row">
        <label className="col-sm-4 col-form-label font-weight-bold">
          {feedbackQuestions.FriendlyQuestion}
        </label>
        <label className="col-sm-4">
          {getRating(feedbackObj.Friendliness, props.isDisabled)}
        </label>
      </div>
      <div className="form-group row">
        <label className="col-sm-4 col-form-label font-weight-bold">
          {feedbackQuestions.ParkingQuestion}
        </label>
        <label className="col-sm-4">
          {getRating(feedbackObj.Parking, props.isDisabled)}
        </label>
      </div>
      <div className="form-group row">
        <label className="col-sm-4 col-form-label font-weight-bold">
          {feedbackQuestions.RatingCleanSafe}
        </label>
        <label className="col-sm-4">
          {getRating(feedbackObj.Condition, props.isDisabled)}
        </label>
      </div>
      <div className="form-group row">
        <label className="col-sm-4 col-form-label font-weight-bold">
          {feedbackQuestions.Punctuality}
        </label>
        <label className="col-sm-4">
          {getRating(feedbackObj.Punctuality, props.isDisabled)}
        </label>
      </div>
      <div className="form-group row">
        <label className="col-sm-4 col-form-label font-weight-bold">
          Comment
        </label>
        <div className="col-sm-8">
          <textarea
            rows="4"
            cols="50"
            value={feedbackObj.Experience}
            disabled={props.isDisabled}
          />
        </div>
      </div>
      {!props.isDisabled ? (
        <div className="form-group row">
          <button
            type="button"
            name="btnAddRatingForRenter"
            className="btn btn-primary"
            onClick={(event) => this.addFeedBack("RENTER")}
          >
            <i className="fas fa-save pr-1" />
            Save
          </button>
        </div>
      ) : (
        ""
      )}
    </Fragment>
  );
};
export default FeedbackUI;

import { ADMIN_PAGE, ModalType } from "../../../shared/GlobalVar";

export const getOverrideInputControls = (
  checkBoxValues,
  changeHandler,
  pageOrModalName,
  transactionType
) => {
  let overrideCommonProps = {
    type: "checkbox",
    changed: changeHandler,
  };
  let overrideEmailCommonProps = {
    name: "OverrideEmail",
    value: checkBoxValues.OverrideEmail,
    labeltext: "Override Email Notification",
    ...overrideCommonProps,
  };
  if (pageOrModalName === ADMIN_PAGE.MEMBER_PROFILE_PAGE) {
    return {
      sectionHeaderClassName: "font-weight-bold fs-2 d-flex form-header",
      containerClassName: "overrides text-left card",
      checkBoxItems: [
        {
          idKey: "newmem-OverrideEmail",
          ...overrideEmailCommonProps,
        },
        {
          idKey: "newmem-OverrideLicenseAndState",
          name: "OverrideLicenseAndState",
          value: checkBoxValues.OverrideLicenseAndState,
          labeltext: "Override License Information",
          ...overrideCommonProps,
        },
        {
          idKey: "newmem-OverrideCreditCard",
          name: "OverrideCreditCardInformation",
          value: checkBoxValues.OverrideCreditCardInformation,
          labeltext: "Override Credit Card Information",
          ...overrideCommonProps,
        },
        {
          idKey: "newmem-OverrideNameValidation",
          name: "OverrideNameValidation",
          value: checkBoxValues.OverrideNameValidation,
          labeltext: "Override Name Validation",
          ...overrideCommonProps,
        }
      ],
    };
  } else if (pageOrModalName === ModalType.FREE_CREDIT_GRANT_REVERSE_MODAL) {
    return {
      sectionHeaderClassName: "col-lg-12 font-weight-bold pl-5 fs-2",
      containerClassName: "overrides position-relative ml-5",
      checkBoxItems: [
        {
          idKey: "cbGrantCreditOverrideEmail",
          ...overrideEmailCommonProps,
        },
        {
          idKey: "cbOverrideMaxValidation",
          name: "OverrideMaxValidation",
          value: checkBoxValues.OverrideMaxValidation,
          labeltext: "Override Reservation Maximum",
          ...overrideCommonProps,
        },
        {
          idKey: "cbOverrideLORLimit",
          name: "OverrideLORLimit",
          value: checkBoxValues.OverrideLORLimit,
          labeltext: "Override LOR Limit",
          ...overrideCommonProps,
        },
      ],
    };
  } else if (pageOrModalName === ADMIN_PAGE.END_RENTAL_PAGE) {
    return {
      sectionHeaderClassName: "font-weight-bold fs-2 d-block",
      containerClassName: "overrides text-left",
      checkBoxItems: [
        {
          idKey: "endRental-OverridePayment",
          name: "OverridePayment",
          value: checkBoxValues.OverridePayment,
          labeltext: "Override Balance Due Allowed",
          ...overrideCommonProps,
        },
        {
          idKey: "endRental-OverrideEmail",
          ...overrideEmailCommonProps,
        },
      ],
    };
  } else if (pageOrModalName === ModalType.TRANSACTION_MODAL) {
    return {
      sectionHeaderClassName: "font-weight-bold fs-2 d-block ml-4",
      containerClassName: "overrides position-relative col-md-6 ml-4",
      checkBoxItems: [
        {
          idKey: "cbOverrideEmailNotification",
          ...overrideEmailCommonProps,
        },
        transactionType === "REFUND"
          ? {
              idKey: "cbOverrideRefundCredits",
              name: "IsRefundAsCredits",
              value: checkBoxValues.IsRefundAsCredits,
              labeltext: "Refund As Credits",
              ...overrideCommonProps,
            }
          : {},
      ],
    };
  }
};

import React from 'react';
import { getFormattedAppliedRates } from '../../../../../shared/utility/pricing-utility';
import CommonTitle from '../../../component/common-title';
import RateRow from '../rate-row';
import SubtotalRateRow from './subtotal-rate-row';

function PricingRateBlock({ appliedRates, rentalCharge }) {
    const formattedRates = getFormattedAppliedRates(appliedRates);
    return (
        <>
            <div className='col-lg-12 mt-3'>
                <CommonTitle title="Rate" classNames="common-title" />
            </div>
            <div className='pricing-classic-header'>
                <div className='col-lg-4'>Qty</div>
                <div className='col-lg-4 text-right'>Rate</div>
                <div className='col-lg-4 text-right'>Subtotal</div>
            </div>
            {formattedRates.map((data) => {
                return <RateRow rateData={data} key={data?.title} showUnit={true} />;
            })}
            <SubtotalRateRow title="Rental Charges Rate" rate={rentalCharge} />
        </>
    )
}

export default PricingRateBlock
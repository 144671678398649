import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  getFeedbackQuestions,
  getFeedbackQuestionsReset,
  getFeedbackDetailsReset,
  getFeedbackDetails,
} from "../../../store/actions";
import {
  getPageName,
  setActiveMenu,
  getSearchRequestCompatibleData,
  getBreadcrumbDataforSearchPage,
  getTopMainLinkUrl,
  getChildBreadCrumbList,
  getMemberProfileBreadcrumbList,
} from "../../search/search-utility/search-utility";
import Spinner from "../../../components/UI/Spinner/Spinner";
import Pagination from "../../../components/pagination/Pagination";
import {
  getErrorToastData,
  getDateTimeString,
  hasPermission,
} from "../../../shared/utility";
import NoRecordFoundComponent from "../../../components/common/pages/no-record-found";
import DefaultComponentWithMessage from "../../../components/common/pages/default-component-with-message";
import * as global from "../../../shared/GlobalVar";
import { showNotification } from "../../../components/UI/Toaster/Toaster";
import MemberQuickModal from "../../Member/modal/member-quick-modal";
import PermissionName from "../../../shared/Permissions";
import PageTopPanel from "../../../components/common/pages/page-top-panel";
import FeedbackInfoModal from "../modal/feedback-info-modal";
import { getMemberLabelByOrgRentalType } from "../../../shared/utility/system-utility";
import { createTooltip } from "../../../shared/utility/tooltip-utility";

const SearchFeedback = (props) => {
  const loading = useSelector((state) => state.feedbackReducer.loading);
  const [isSearchPerformed, setIsSearchPerformed] = useState(false);
  const [searchData, setSearchData] = useState(null);
  const [showExportModal, setShowExportModal] = useState(false);
  const [exportData, setExportData] = useState(false);
  const [fromMemberId, setFromMemberId] = useState(null);
  const [searchForRating, setSearchForRating] = useState(false);
  const [currentFeedBackaData, setCurrentFeedBackaData] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const [recordsPerPage, setRecordsPerPage] = useState(100);
  const [showFeedbackInfoModal, setShowFeedbackInfoModal] = useState(false);
  const [showMemberQuickViewPopup, setShowMemberQuickViewPopup] =
    useState(false);
  const dispatch = useDispatch();

  const memberFeedbackResponse = useSelector(
    (state) => state.feedbackReducer.feedbackDetailsResponse
  );
  const feedbackQuestionsResponse = useSelector(
    (state) => state.feedbackReducer.feedbackQuestionsResponse
  );

  const query = props.location
    ? new URLSearchParams(props.location.search)
    : null;
  const reservationId = query ? query.get("reservationId") : null;
  const memberId = query ? query.get("memberId") : null;

  useEffect(() => {
    getSearchList();
  }, [memberId]);

  useEffect(() => {
    if (!memberFeedbackResponse) {
      return;
    }

    if (memberFeedbackResponse.StatusCode === 0) {
      laodSearchDataFromResponse(memberFeedbackResponse);
    }
    dispatch(getFeedbackDetailsReset);
  }, [memberFeedbackResponse]);

  const laodSearchDataFromResponse = (response) => {
    if (showExportModal) {
      if (response.TotalRecords) {
        setExportData(response);
      } else {
        showNotification(getErrorToastData(global.COMMON_MSG.NoRecordFound));
        setShowExportModal(false);
        setExportData(null);
        dispatch(getFeedbackDetailsReset());
      }
    } else {
      setSearchData(response);
      setExportData(null);
      setIsSearchPerformed(true);
      setShowExportModal(false);
    }
  };

  const getExportSearchData = (showExpModal) => {
    setShowExportModal(showExpModal);
    setExportData(null);
    getSearchList();
  };
  const getSearchList = () => {
    const requestObjInfo = getSearchRequestCompatibleData(
      null,
      getPageName(props.match.url)
    );

    requestObjInfo.MemberId = memberId;
    if (reservationId) {
      requestObjInfo.ReservationId = reservationId;
    }
    requestObjInfo.FeedbackCategory = searchForRating ? null : "BORROWER";
    dispatch(getFeedbackDetailsReset());
    dispatch(getFeedbackDetails(requestObjInfo));
  };

  const modalClose = () => {
    setShowMemberQuickViewPopup(false);
    setShowFeedbackInfoModal(false);
    setSearchForRating(false);
  };
  const showMemberQuickViewModal = (selectedMemberId) => {
    setFromMemberId(selectedMemberId);
    setShowMemberQuickViewPopup(true);
  };

  const openFeedbackInfoModal = (feedBackaData) => {
    if (!feedbackQuestionsResponse) {
      dispatch(getFeedbackQuestionsReset());
      dispatch(getFeedbackQuestions());
    }
    setShowFeedbackInfoModal(true);
    setCurrentFeedBackaData(feedBackaData);
  };
  const resetExportDoc = () => {
    setExportData(null);
    setShowExportModal(false);
  };

  const onPageChanged = (curPage, recPerPage) => {
    if (curPage && recPerPage) {
      setCurrentPage(curPage);
      setRecordsPerPage(recPerPage);
      getSearchList();
    }
  };

  const renderTableData = () => {
    let tableRows = "";
    let ranking = 0;
    let memberName = "";
    if (searchData && searchData.Feedbacks) {
      tableRows = searchData.Feedbacks.map((post, index) => {
        ranking =
          (post.Friendliness || 0) +
          (post.Accurate || 0) +
          (post.Parking || 0) +
          (post.Condition || 0) +
          (post.Punctuality || 0);
        ranking = parseFloat(ranking / 5);

        memberName =
          post.FeedbackType !== null && post.FeedbackType === "PROVIDED"
            ? post.PersonForName
            : post.PersonName;

        let memberNameForTooltip = createTooltip(memberName, memberName);

        let personId =
          post.FeedbackType !== null && post.FeedbackType === "PROVIDED"
            ? post.PersonForId
            : post.PersonId;

        return (
          <div className="row" key={index}>
            <span className="row__column text-center">
              <i
                className={`fas ${
                  ranking >= 3
                    ? "fa-thumbs-up text-success"
                    : "fa-thumbs-down text-danger"
                } fs-4 `}
              />
            </span>
            <span className="row__column">
              {getDateTimeString(post.FeedbackDate)}
            </span>

            <span className="row__column position-relative">
              {hasPermission(PermissionName.VIEW_RESERVATION) ? (
                <Link
                  to={{
                    pathname: "/reservationSummary",
                    search: "?reservationId=" + post.ReservationId,
                    state: { breadCrumbList: breadCrumbList },
                  }}
                >
                  {post.ReservationCode || "--"}
                </Link>
              ) : (
                post.ReservationCode || "--"
              )}
            </span>
            <span className="row__column" id={"experienceId_" + index}>
              <span
                className="pseudo-link"
                onClick={(event) => openFeedbackInfoModal(post)}
              >
                {createTooltip(post.Experience, post.Experience)}
              </span>
            </span>
            <span className="row__column">{post.FeedbackType}</span>
            <span
              className="row__column"
              data-toggle="modal"
              data-target="#primarymodal"
              id={"memberId_" + index}
            >
              {hasPermission(PermissionName.VIEW_MEMBER) ? (
                <span
                  className="pseudo-link"
                  onClick={(event) => showMemberQuickViewModal(personId)}
                >
                  {memberNameForTooltip}
                </span>
              ) : (
                { memberNameForTooltip }
              )}
            </span>
          </div>
        );
      });
    }
    return (
      <div>
        <div className="datagrid">
          <div className="column">
            <span className="column__header" />
            <span className="column__header">Date and Time</span>
            <span className="column__header">Reservation Code</span>
            <span className="column__header">Feedback</span>
            <span className="column__header">Type</span>
            <span className="column__header">
              {getMemberLabelByOrgRentalType()} Name
            </span>
          </div>
          {tableRows}
        </div>
      </div>
    );
  };

  setActiveMenu(props.match.url);
  const pageName = getPageName(props.match.url);
  let memberQuickViewCtrl = "";
  if (showMemberQuickViewPopup) {
    memberQuickViewCtrl = (
      <MemberQuickModal
        memberId={fromMemberId}
        showModal={showMemberQuickViewPopup}
        closeModal={modalClose}
      />
    );
  } else {
    memberQuickViewCtrl = null;
  }

  let breadCrumbList = getMemberProfileBreadcrumbList(
    props.otherData,
    memberId
  );

  return (
    <Fragment>
      <section className="res-search" id="memberFeedbackSearch">
        <PageTopPanel
          breadCrumbList={breadCrumbList}
          linkList={getTopMainLinkUrl(pageName)}
          title="Feedback"
          showExportLink={true}
          memberId={memberId}
          getExportSearchData={getExportSearchData}
          resetExportDoc={resetExportDoc}
          exportData={exportData ? exportData.Feedbacks : []}
          pageName={pageName}
          linkBreadCrumb={getChildBreadCrumbList(
            props.match.url,
            props.location,
            breadCrumbList
          )}
        />
      </section>
      <section className="grid-table" id="memberFeedbackSearchTable">
        {searchData && searchData.TotalRecords > 0 ? (
          <Fragment>
            <Pagination
              totalRecords={searchData.TotalRecords}
              recordsPerPage={recordsPerPage}
              currentPage={currentPage}
              onPageChanged={onPageChanged}
              key={searchData.TotalRecords}
            />
            {renderTableData()}
          </Fragment>
        ) : isSearchPerformed ? (
          <NoRecordFoundComponent searchMsg="No Record Found" />
        ) : (
          <DefaultComponentWithMessage />
        )}
      </section>
      {loading ? <Spinner /> : ""}
      {props.feedbackQuestionsResponse && showFeedbackInfoModal ? (
        <FeedbackInfoModal
          showModal={showFeedbackInfoModal}
          modalClose={modalClose}
          feedBackaData={currentFeedBackaData}
          feedbackQuestions={props.feedbackQuestionsResponse}
          isDisabled={true}
        />
      ) : (
        ""
      )}
      {memberQuickViewCtrl}
    </Fragment>
  );
};
export default SearchFeedback;

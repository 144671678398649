import Person from "../models/Person";
import OverrideOptions from "../models/OverrideOptions";
const AddEditMemberRequest = function (
  personObj,
  overrideOptions,
  getMemberOptions
) {
  if (!personObj) personObj = new Person();
  if (!overrideOptions) overrideOptions = new OverrideOptions();
  this.OrgUnitId = personObj.OrgUnitId ? Number(personObj.OrgUnitId) : null;
  this.MemberId = personObj.MemberId ? Number(personObj.MemberId) : null;
  this.OptInXpress = personObj.OptInXpress || false;
  this.FirstName = personObj.FirstName || null;
  this.LastName = personObj.LastName || null;
  this.MiddleName = personObj.MiddleName || null;
  this.EmailAddress = personObj.EmailAddress || null;
  this.VerificationCode = personObj.VerificationCode || null;
  this.PrimaryPhone = personObj.PrimaryPhone || null;
  if (personObj.MobilePhone && personObj.MobilePhone.CountryCode) {
    this.MobilePhone = { ...personObj.MobilePhone };
    this.MobilePhone.CountryCode = Number(this.MobilePhone.CountryCode);
    this.MobilePhone.Number = Number(this.MobilePhone.Number);
  } else {
    personObj.MobilePhone = null;
  }
  if (personObj.HomePhone && personObj.HomePhone.CountryCode) {
    this.HomePhone = { ...personObj.HomePhone };
    this.HomePhone.CountryCode = Number(this.HomePhone.CountryCode);
    this.HomePhone.Number = Number(this.HomePhone.Number);
  } else {
    personObj.HomePhone = null;
  }
  if (personObj.WorkPhone && personObj.WorkPhone.CountryCode) {
    this.WorkPhone = { ...personObj.WorkPhone };
    this.WorkPhone.CountryCode = Number(this.WorkPhone.CountryCode);
    this.WorkPhone.Number = Number(this.WorkPhone.Number);
  } else {
    personObj.WorkPhone = null;
  }
  if (personObj.PrimaryAddress) {
    const primaryAddressObj = { ...personObj.PrimaryAddress };
    this.AddressType = primaryAddressObj.AddressType || null;
    this.PrimaryStreetAddress1 = primaryAddressObj.Street1 || null;
    this.PrimaryStreetAddress2 = primaryAddressObj.Street2 || null;
    this.PrimaryCountry = primaryAddressObj.Country
      ? Number(primaryAddressObj.Country)
      : null;
    this.PrimaryState = primaryAddressObj.State
      ? Number(primaryAddressObj.State)
      : null;
    this.PrimaryStateStr = primaryAddressObj.StateText || null;
    this.PrimaryCity = primaryAddressObj.City
      ? Number(primaryAddressObj.City)
      : null;
    this.PrimaryCityStr = primaryAddressObj.CityText || null;
    this.PrimaryZipCode = primaryAddressObj.ZipCode
      ? Number(primaryAddressObj.ZipCode)
      : null;
    this.PrimaryZipCodeStr = primaryAddressObj.ZipCodeText || null;
  }
  this.DriveType = personObj.DriveType || null;
  this.AcknowledgeDriveManualEnabled =
    personObj.AcknowledgeDriveManualEnabled || false;
  if (personObj.DrivingLicense) {
    this.DateOfBirth = personObj.DrivingLicense.DateOfBirth || null;
    this.DrivingLicenseNumber =
      personObj.DrivingLicense.DrivingLicenseNumber || null;
    this.DrivingLicenseCountry = personObj.DrivingLicense
      .DrivingLicenseCountryId
      ? Number(personObj.DrivingLicense.DrivingLicenseCountryId)
      : null;
    this.DrivingLicenseState = personObj.DrivingLicense.DrivingLicenseStateId
      ? Number(personObj.DrivingLicense.DrivingLicenseStateId)
      : null;
    this.DrivingLicenseStateText =
      personObj.DrivingLicense.DrivingLicenseStateText || null;
    this.DrivingLicenseExpirationDate =
      personObj.DrivingLicense.LicenseExpirationDate || null;
  }
  if (personObj.Insurance) {
    this.InsuranceCarrier = personObj.Insurance.InsuranceCarrier || null;
    this.PolicyNumber = personObj.Insurance.PolicyNumber || null;
    this.InsuranceExpirationDate =
      personObj.Insurance.InsuranceExpirationDate || null;
    this.InsuranceVerified = personObj.Insurance.InsuranceVerified || false;
  }
  this.PaymentFailureCount = personObj.PaymentFailureCount
    ? Number(personObj.PaymentFailureCount)
    : null;
  if (personObj.CreditCard) {
    const creditCarobj = { ...personObj.CreditCard };
    this.NameOnCreditCard = creditCarobj.BillingName || null;
    this.CreditCardCVV = creditCarobj.CVVCode || null;
    this.CreditCardNumber = creditCarobj.CardNumber || null;
    this.CreditCardType = creditCarobj.CreditCardType || null;
    this.ExpirationMonth = creditCarobj.CardExpirationMonth || null;
    this.ExpirationYear = creditCarobj.CardExpirationYear || null;
    this.CreditCardStreetAddress1 = creditCarobj.Address
      ? creditCarobj.Address.Street1
      : null;
    this.CreditCardStreetAddress2 = creditCarobj.Address
      ? creditCarobj.Address.Street2
      : null;
    this.CreditCardAddressCountry =
      creditCarobj.Address && creditCarobj.Address.Country
        ? Number(creditCarobj.Address.Country)
        : null;
    this.CreditAddressState =
      creditCarobj.Address && creditCarobj.Address.State
        ? Number(creditCarobj.Address.State)
        : null;
    this.CreditCardAddressStateStr =
      creditCarobj.Address && creditCarobj.Address.StateText
        ? creditCarobj.Address.StateText
        : null;
    this.CreditCardAddressCity =
      creditCarobj.Address && creditCarobj.Address.City
        ? Number(creditCarobj.Address.City)
        : null;
    this.CreditCardAddressCityStr =
      creditCarobj.Address && creditCarobj.Address.CityText
        ? creditCarobj.Address.CityText
        : null;
    this.CreditCardAddressZipCode =
      creditCarobj.Address && creditCarobj.Address.ZipCode
        ? Number(creditCarobj.Address.ZipCode)
        : null;
    this.CreditCardAddressZipCodeStr =
      creditCarobj.Address && creditCarobj.Address.ZipCodeText
        ? creditCarobj.Address.ZipCodeText
        : null;
  }
  this.CompanyName = personObj.CompanyName || null;
  this.Designation = personObj.Designation || null;
  this.ReferenceLink = personObj.ReferredBy || null;
  this.SignupCode = personObj.SignupCode || null;
  this.ProfileNotes = personObj.ProfileNotes || null;
  this.IsEmployee = personObj.IsEmployee || false;
  if (personObj.Employee && personObj.IsEmployee) {
    this.LocationId = personObj.Employee.LocationId
      ? Number(personObj.Employee.LocationId)
      : null;
    this.EmployeeNo = personObj.Employee.EmployeeNo || null;
    this.BadgeNo = personObj.Employee.BadgeNo || null;
    this.Title = personObj.Employee.Title || null;
    this.Department = personObj.Employee.Department || null;
    this.companyName = personObj.Employee.companyName || null;
  }
  this.OverrideEmail = overrideOptions.OverrideEmail || false;
  this.OverrideOwner = overrideOptions.OverrideOwner || false;
  this.OverrideLicenseAndState =
    overrideOptions.OverrideLicenseAndState || false;
  this.OverrideCreditCardInformation =
    overrideOptions.OverrideCreditCardInformation || false;
  this.OverrideNameValidation = overrideOptions.OverrideNameValidation || false;
  if (getMemberOptions) {
    this.Option = { ...getMemberOptions };
  }

  this.EmailBorrowerPreference = personObj.EmailBorrowerPreference || null;
  this.EmailGeneralPreference = personObj.EmailGeneralPreference || null;
  this.EmailSharerPreference = personObj.EmailSharerPreference || null;
  this.PhoneGeneralPreference = personObj.PhoneGeneralPreference || null;
  this.PhoneSharerPreference = personObj.PhoneSharerPreference || null;
  this.PhoneBorrowerPreference = personObj.PhoneBorrowerPreference || null;
  this.TextBorrowerPreference = personObj.TextBorrowerPreference || null;
  this.TextGeneralPreference = personObj.TextGeneralPreference || null;
  this.TextSharerPreference = personObj.TextSharerPreference || null;
  this.BgVerificationCheck = personObj.BgVerificationCheck || null;
  this.CommunicationLanguage = personObj.CommunicationLanguage || null;
  this.ContactMethod = personObj.ContactMethod || null;
  this.TermsAndConditionsCheck = true;
  //this.InternalNotes = personObj.InternalNotes || null;
};
export default AddEditMemberRequest;

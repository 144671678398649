export const ManagePaymentServiceRequest = function () {
  this.RefTransId = null;
  this.TransId = null;
  this.MemberId = null;
  this.ReservationId = null;
  this.PaymentType = null;
  this.TransactionType = null;
  this.ProcessedBy = null;
  this.Comments = null;
  this.Amount = null;
  this.Reason = null;
  this.OverrideEmail = null;
  this.IsRefundAsCredits = null;
};
export default ManagePaymentServiceRequest;

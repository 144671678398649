import React from 'react';
import RadioButton from '../../../components/UI/Input/checkbox-radio/radio-button-ctrl';
import AddDiscountContainer from './add-discount-container';
import RevertDiscountContainer from './revert-discount-container';


function AddRevertDiscountForm(props) {
    const {
        formType,
        formValue,
        setFormValue,
        currentFormType,
        setCurrentFormType,
        discountReasonData,
        discountCodeList,
        selectedDiscountData,
        setSelectedDiscountData,
        setReservationData,
        widgetLoader,
        getReservationData,
        onAddReverseDiscountCode,
        discountId,
        hasGrantAccess,
        hasRevetAccess,
        status
    } = props;
    const onChange = (data, name) => {
        const tempData = { ...formValue };
        tempData[name] = data;
        if (currentFormType === formType.ADD_DISCOUNT && selectedDiscountData) {
            setSelectedDiscountData(null);
        }
        if (name === "grantedReason") {
            tempData.grantedSubReason = null;
        }
        setFormValue(tempData);
    }
    const onClear = (name) => {
        const tempData = { ...formValue };
        tempData[name] = null;
        if (name === "grantedReason") {
            tempData.grantedSubReason = null;
        }
        setFormValue(tempData);
    }
    const validateForm = () => {
        if (currentFormType === formType.REVERT_DISCOUNT) {
            if (formValue?.revertedReason && formValue?.revertedNotes && discountId && status !== "REVERTED" && status !== "USED") {
                return true
            }
            return false;
        } else if (currentFormType === formType.ADD_DISCOUNT) {
            if (formValue?.reservationCode && formValue?.foxDiscountCode
                && formValue?.grantedReason
                && formValue?.grantedSubReason
                && formValue?.grantedNotes) {
                return true
            }
            return false;
        }
    }
    const onSelectedForm = (type) => {
        if (type === formType.ADD_DISCOUNT && selectedDiscountData) {
            setSelectedDiscountData(null);
        }
        setReservationData(null);
        setCurrentFormType(type)
    }
    return (
        <div className='flex-column row-gap-1 mb-5'>
            <div className='flex-row gap-1'>
                <RadioButton
                    name="discount-group"
                    placeholder="Add Discount Code"
                    classNames={`${!hasGrantAccess ? "disabled" : ""}`}
                    checked={hasGrantAccess && currentFormType === formType.ADD_DISCOUNT}
                    onChange={() => onSelectedForm(formType.ADD_DISCOUNT)}
                    value={!hasGrantAccess ? null : formType.ADD_DISCOUNT}
                    disabled={!hasGrantAccess}
                />
                <RadioButton
                    name="discount-group"
                    placeholder="Revert Discount Code"
                    classNames={`${!hasRevetAccess || !discountId || status !== "GRANTED" ? "disabled" : ""}`}
                    checked={hasRevetAccess && currentFormType === formType.REVERT_DISCOUNT}
                    onChange={() => setCurrentFormType(formType.REVERT_DISCOUNT)}
                    value={!hasRevetAccess ? null : formType.REVERT_DISCOUNT}
                    disabled={!hasRevetAccess || !discountId || status !== "GRANTED"}
                />
            </div>
            <div className='flex-column row-gap-1'>
                {currentFormType !== formType.REVERT_DISCOUNT &&
                    <AddDiscountContainer
                        formValue={formValue}
                        discountReasonData={discountReasonData}
                        discountCodeList={discountCodeList}
                        widgetLoader={widgetLoader}
                        getReservationData={getReservationData}
                        disabled={!hasGrantAccess || selectedDiscountData}
                        onChange={onChange}
                        onClear={onClear}
                    />
                }
                {currentFormType === formType.REVERT_DISCOUNT &&
                    <RevertDiscountContainer
                        formValue={formValue}
                        discountReasonData={discountReasonData}
                        widgetLoader={widgetLoader}
                        disabled={!hasRevetAccess || !selectedDiscountData}
                        onChange={onChange}
                        onClear={onClear}
                    />
                }
                <hr />
                <div className='btn-container m-0'>
                    <button className='btn btn-outline-danger'
                        onClick={() => setFormValue(null)}
                        disabled={!currentFormType
                            || (currentFormType === formType.ADD_DISCOUNT && !hasGrantAccess)
                            || (currentFormType === formType.REVERT_DISCOUNT && !hasRevetAccess)
                        }
                    >Clear</button>
                    <button className='btn btn-yellow-default'
                        onClick={onAddReverseDiscountCode}
                        disabled={!validateForm()
                            || (currentFormType === formType.ADD_DISCOUNT && !hasGrantAccess)
                            || (currentFormType === formType.REVERT_DISCOUNT && !hasRevetAccess)
                        }
                    >{currentFormType === formType.ADD_DISCOUNT ? "Grant"
                        : currentFormType === formType.REVERT_DISCOUNT ? "Revert" : "Ok"}
                    </button>
                </div>
            </div>
        </div>
    )
}

export default AddRevertDiscountForm
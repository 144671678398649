import { reducerUpdateObject } from "./reducer.utility";
import * as actionTypes from "./../actions/actionTypes-shuttle-app";

const initialState = {
  error: null,
  loading: false,
  vicinityResponse: null,
};

const getVicinityStart = (state,action) => {
  return reducerUpdateObject(state, { error: null, loading: action.showLoader || false  });
};
const getVicinityFail = (state, action) => {
  return reducerUpdateObject(state, { error: action.error, loading: false });
};
const getVicinitySuccess = (state, action) => {
  return reducerUpdateObject(state, {
    vicinityResponse: action.vicinityResponse,
    error: null,
    loading: false,
  });
};

const getVicinityReset = (state) => {
  return reducerUpdateObject(state, {
    vicinityResponse: null,
  });
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.VICINITY_START:
      return getVicinityStart(state,action);
    case actionTypes.VICINITY_SUCCESS:
      return getVicinitySuccess(state, action);
    case actionTypes.VICINITY_FAIL:
      return getVicinityFail(state, action);
    case actionTypes.VICINITY_RESET:
      return getVicinityReset(state);
    default:
      return state;
  }
};
export default reducer;

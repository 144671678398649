import React, { useEffect, useRef, useState } from "react";
import ReactHtmlParser from 'react-html-parser';

// Reusable LabelWithTooltipOnOverflowText component
const LabelWithTooltipOnOverflowText = (props) => {
    const labelRef = useRef(null);
    const [showTitle, setShowTitle] = useState(false);

    useEffect(() => {
        // Check if the label text overflows
        if (labelRef.current) {
            const isOverflowing =
                labelRef.current.scrollWidth > labelRef.current.clientWidth;
            setShowTitle(isOverflowing);
        }
    }, [props.content]); // Re-check overflow when content changes
    return (
        <label
            id={props.id}
            title={showTitle || props.forceShow ? ReactHtmlParser((props.title || "").toString().trim()) : ""}
            ref={labelRef} // Reference to check overflow
            className={props.classNames}
            onClick={props.onClickAction && props.onClickAction}
        >
            {props.content}
        </label>
    );
};

export default LabelWithTooltipOnOverflowText;

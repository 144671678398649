import * as actionTypes from "../actions/actionTypes";
import { reducerUpdateObject } from "./reducer.utility";

const initialState = {
  searchMemberResponse: null,
  memberData: null,
  createMemberResponse: null,
  editMemberResponse: null,
  conlictedMemberData: null,
  blockUnblockResponse: null,
  reactivateMemberResponse: null,
  cancelMemberResponse: null,
  changeTemporaryPasswordResponse: null,
  resetPasswordReponse: null,
  verifyMemberAccountResponse: null,
  impersonateLoginResponse: null,
  addViolationPointResponse: null,
  allowTempImgResponse: null,
  memberMessageResponse: null,
  memberFeedbackResponse: null,
  memberFavoriteAssetResponse: null,
  memberVehicleResponse: null,
  searchCreditHistoryResponse: null,
  addFreeCreditResponse: null,
  reverseCreditResponse: null,
  loading: false,
  error: null,
};
export const doMemberSearchStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
export const doMemberSearchSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    searchMemberResponse: action.searchMemberResponse,
    error: null,
    loading: false,
  });
};
const doMemberSearchFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false,
  });
};
const doMemberSearchReset = (state) => {
  return reducerUpdateObject(state, {
    searchMemberResponse: null,
  });
};
const getMemberStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
const getMemberSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    memberData: action.memberData,
    error: null,
    loading: false,
  });
};
const getMemberFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false,
  });
};
const getMemberReset = (state) => {
  return reducerUpdateObject(state, {
    memberData: null,
  });
};

const createMemberStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
const createMemberSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    createMemberResponse: action.createMemberResponse,
    error: null,
    loading: false,
  });
};
const createMemberFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false,
  });
};
const createMemberReset = (state) => {
  return reducerUpdateObject(state, {
    createMemberResponse: null,
    editMemberResponse: null,
  });
};

const editMemberStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
const editMemberSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    editMemberResponse: action.editMemberResponse,
    error: null,
    loading: false,
  });
};
const editMemberFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false,
  });
};
const editMemberReset = (state) => {
  return reducerUpdateObject(state, {
    createMemberResponse: null,
    editMemberResponse: null,
  });
};

const conflicTedInitialState = {
  conlictedMemberData: null,
  loading: false,
};
const getConflictedMemberStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
const getConflictedMemberSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    conlictedMemberData: action.conlictedMemberData,
    error: null,
    loading: false,
  });
};
const getConflictedMemberFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false,
  });
};
const getConflictedMemberReset = (state) => {
  return reducerUpdateObject(state, {
    conlictedMemberData: null,
  });
};

const blockUnblockMemberProfileStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
const blockUnblockMemberProfileSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    blockUnblockResponse: action.blockUnblockResponse,
    error: null,
    loading: false,
  });
};
const blockUnblockMemberProfileFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false,
  });
};
const blockUnblockMemberProfileReset = (state) => {
  return reducerUpdateObject(state, {
    blockUnblockResponse: null,
  });
};

const cancelMembershipStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
const cancelMembershipSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    cancelMemberResponse: action.cancelMemberResponse,
    error: null,
    loading: false,
  });
};
const cancelMembershipFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false,
  });
};
const cancelMembershipReset = (state) => {
  return reducerUpdateObject(state, {
    cancelMemberResponse: null,
  });
};

const reactivateMemberStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
const reactivateMemberSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    reactivateMemberResponse: action.reactivateMemberResponse,
    error: null,
    loading: false,
  });
};
const reactivateMemberFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false,
  });
};
const reactivateMemberReset = (state) => {
  return reducerUpdateObject(state, {
    reactivateMemberResponse: null,
  });
};

const changeTemporaryPasswordStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
const changeTemporaryPasswordSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    changeTemporaryPasswordResponse: action.changeTemporaryPasswordResponse,
    error: null,
    loading: false,
  });
};
const changeTemporaryPasswordFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false,
  });
};
const changeTemporaryPasswordReset = (state) => {
  return reducerUpdateObject(state, {
    changeTemporaryPasswordResponse: null,
  });
};

const resetPasswordStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
const resetPasswordSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    resetPasswordReponse: action.resetPasswordReponse,
    error: null,
    loading: false,
  });
};
const resetPasswordFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false,
  });
};
const resetPasswordReset = (state) => {
  return reducerUpdateObject(state, {
    resetPasswordReponse: null,
  });
};

const verifyMemberAccountStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
const verifyMemberAccountSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    verifyMemberAccountResponse: action.verifyMemberAccountResponse,
    error: null,
    loading: false,
  });
};
const verifyMemberAccountFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false,
  });
};
const verifyMemberAccountReset = (state) => {
  return reducerUpdateObject(state, {
    verifyMemberAccountResponse: null,
  });
};

const addViolationPointStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
const addViolationPointSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    addViolationPointResponse: action.addViolationPointResponse,
    error: null,
    loading: false,
  });
};
const addViolationPointFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false,
  });
};
const addViolationPointReset = (state) => {
  return reducerUpdateObject(state, {
    addViolationPointResponse: null,
  });
};

const allowTemporaryLicenseImageUploadStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
const allowTemporaryLicenseImageUploadSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    allowTempImgResponse: action.allowTempImgResponse,
    error: null,
    loading: false,
  });
};
const allowTemporaryLicenseImageUploadFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false,
  });
};
const allowTemporaryLicenseImageUploadReset = (state) => {
  return reducerUpdateObject(state, {
    allowTempImgResponse: null,
  });
};

const searchMemberFavoriteAssetStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
const searchMemberFavoriteAssetSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    memberFavoriteAssetResponse: action.memberFavoriteAssetResponse,
    error: null,
    loading: false,
  });
};
const searchMemberFavoriteAssetFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false,
  });
};
const searchMemberFavoriteAssetReset = (state) => {
  return reducerUpdateObject(state, {
    memberFavoriteAssetResponse: null,
  });
};

const searchMemberMessageStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
const searchMemberMessageSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    memberMessageResponse: action.memberMessageResponse,
    error: null,
    loading: false,
  });
};
const searchMemberMessageFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false,
  });
};
const searchMemberMessageReset = (state) => {
  return reducerUpdateObject(state, {
    memberMessageResponse: null,
  });
};
const searchMemberVehicleStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
const searchMemberVehicleSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    memberVehicleResponse: action.memberVehicleResponse,
    error: null,
    loading: false,
  });
};
const searchMemberVehicleFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false,
  });
};
const searchMemberVehicleReset = (state) => {
  return reducerUpdateObject(state, {
    memberVehicleResponse: null,
  });
};

const searchCreditHistoryStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
const searchCreditHistorySuccess = (state, action) => {
  return reducerUpdateObject(state, {
    searchCreditHistoryResponse: action.searchCreditHistoryResponse,
    error: null,
    loading: false,
  });
};
const searchCreditHistoryFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false,
  });
};
const searchCreditHistoryReset = (state) => {
  return reducerUpdateObject(state, {
    searchCreditHistoryResponse: null,
  });
};

const addFreeCreditStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
const addFreeCreditSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    addFreeCreditResponse: action.addFreeCreditResponse,
    error: null,
    loading: false,
  });
};
const addFreeCreditFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false,
  });
};
const addFreeCreditReset = (state) => {
  return reducerUpdateObject(state, {
    addFreeCreditResponse: null,
  });
};

const reverseCreditStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
const reverseCreditSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    reverseCreditResponse: action.reverseCreditResponse,
    error: null,
    loading: false,
  });
};
const reverseCreditFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false,
  });
};
const reverseCreditReset = (state) => {
  return reducerUpdateObject(state, {
    reverseCreditResponse: null,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SEARCH_MEMBER_START:
      return doMemberSearchStart(state, action);
    case actionTypes.SEARCH_MEMBER_SUCCESS:
      return doMemberSearchSuccess(state, action);
    case actionTypes.SEARCH_MEMBER_FAIL:
      return doMemberSearchFail(state, action);
    case actionTypes.SEARCH_MEMBER_RESET:
      return doMemberSearchReset(state);

    case actionTypes.GET_MEMBER_START:
      return getMemberStart(state, action);
    case actionTypes.GET_MEMBER_SUCCESS:
      return getMemberSuccess(state, action);
    case actionTypes.GET_MEMBER_FAIL:
      return getMemberFail(state, action);
    case actionTypes.GET_MEMBER_RESET:
      return getMemberReset(state);

    case actionTypes.CREATE_MEMBER_START:
      return createMemberStart(state, action);
    case actionTypes.CREATE_MEMBER_SUCCESS:
      return createMemberSuccess(state, action);
    case actionTypes.CREATE_MEMBER_FAIL:
      return createMemberFail(state, action);
    case actionTypes.CREATE_MEMBER_RESET:
      return createMemberReset(state);

    case actionTypes.EDIT_MEMBER_START:
      return editMemberStart(state, action);
    case actionTypes.EDIT_MEMBER_SUCCESS:
      return editMemberSuccess(state, action);
    case actionTypes.EDIT_MEMBER_FAIL:
      return editMemberFail(state, action);
    case actionTypes.EDIT_MEMBER_RESET:
      return editMemberReset(state);

    case actionTypes.GET_CONFLICTED_MEMBER_START:
      return getConflictedMemberStart(conflicTedInitialState, action);
    case actionTypes.GET_CONFLICTED_MEMBER_SUCCESS:
      return getConflictedMemberSuccess(state, action);
    case actionTypes.GET_CONFLICTED_MEMBER_FAIL:
      return getConflictedMemberFail(conflicTedInitialState, action);
    case actionTypes.GET_CONFLICTED_MEMBER_RESET:
      return getConflictedMemberReset(state);

    case actionTypes.BLOCK_UNBLOCK_PROFILE_START:
      return blockUnblockMemberProfileStart(state, action);
    case actionTypes.BLOCK_UNBLOCK_PROFILE_SUCCESS:
      return blockUnblockMemberProfileSuccess(state, action);
    case actionTypes.BLOCK_UNBLOCK_PROFILE_FAIL:
      return blockUnblockMemberProfileFail(state, action);
    case actionTypes.BLOCK_UNBLOCK_PROFILE_RESET:
      return blockUnblockMemberProfileReset(state);

    case actionTypes.CANCEL_MEMBER_START:
      return cancelMembershipStart(state, action);
    case actionTypes.CANCEL_MEMBER_SUCCESS:
      return cancelMembershipSuccess(state, action);
    case actionTypes.CANCEL_MEMBER_FAIL:
      return cancelMembershipFail(state, action);
    case actionTypes.CANCEL_MEMBER_RESET:
      return cancelMembershipReset(state);

    case actionTypes.ACTIVATE_MEMBER_START:
      return reactivateMemberStart(state, action);
    case actionTypes.ACTIVATE_MEMBER_SUCCESS:
      return reactivateMemberSuccess(state, action);
    case actionTypes.ACTIVATE_MEMBER_FAIL:
      return reactivateMemberFail(state, action);
    case actionTypes.ACTIVATE_MEMBER_RESET:
      return reactivateMemberReset(state);

    case actionTypes.CHANGE_TEMPORARY_PASSWORD_START:
      return changeTemporaryPasswordStart(state, action);
    case actionTypes.CHANGE_TEMPORARY_PASSWORD_SUCCESS:
      return changeTemporaryPasswordSuccess(state, action);
    case actionTypes.CHANGE_TEMPORARY_PASSWORD_FAIL:
      return changeTemporaryPasswordFail(state, action);
    case actionTypes.CHANGE_TEMPORARY_PASSWORD_RESET:
      return changeTemporaryPasswordReset(state);

    case actionTypes.RESET_PASSWORD_START:
      return resetPasswordStart(state, action);
    case actionTypes.RESET_PASSWORD_SUCCESS:
      return resetPasswordSuccess(state, action);
    case actionTypes.RESET_PASSWORD_FAIL:
      return resetPasswordFail(state, action);
    case actionTypes.RESET_PASSWORD_RESET:
      return resetPasswordReset(state);

    case actionTypes.VERIFY_MEMBER_ACCOUNT_START:
      return verifyMemberAccountStart(state, action);
    case actionTypes.VERIFY_MEMBER_ACCOUNT_SUCCESS:
      return verifyMemberAccountSuccess(state, action);
    case actionTypes.VERIFY_MEMBER_ACCOUNT_FAIL:
      return verifyMemberAccountFail(state, action);
    case actionTypes.VERIFY_MEMBER_ACCOUNT_RESET:
      return verifyMemberAccountReset(state);

    case actionTypes.ADD_VIOLATION_POINT_START:
      return addViolationPointStart(state, action);
    case actionTypes.ADD_VIOLATION_POINT_SUCCESS:
      return addViolationPointSuccess(state, action);
    case actionTypes.ADD_VIOLATION_POINT_FAIL:
      return addViolationPointFail(state, action);
    case actionTypes.ADD_VIOLATION_POINT_RESET:
      return addViolationPointReset(state);

    case actionTypes.ALLOW_TEMP_IMG_UPLOAD_START:
      return allowTemporaryLicenseImageUploadStart(state, action);
    case actionTypes.ALLOW_TEMP_IMG_UPLOAD_SUCCESS:
      return allowTemporaryLicenseImageUploadSuccess(state, action);
    case actionTypes.ALLOW_TEMP_IMG_UPLOAD_FAIL:
      return allowTemporaryLicenseImageUploadFail(state, action);
    case actionTypes.ALLOW_TEMP_IMG_UPLOAD_RESET:
      return allowTemporaryLicenseImageUploadReset(state);

    case actionTypes.SEARCH_MEMBER_FAVORITE_START:
      return searchMemberFavoriteAssetStart(state, action);
    case actionTypes.SEARCH_MEMBER_FAVORITE_SUCCESS:
      return searchMemberFavoriteAssetSuccess(state, action);
    case actionTypes.SEARCH_MEMBER_FAVORITE_FAIL:
      return searchMemberFavoriteAssetFail(state, action);
    case actionTypes.SEARCH_MEMBER_FAVORITE_RESET:
      return searchMemberFavoriteAssetReset(state);

    case actionTypes.SEARCH_MEMBER_MESSAGE_START:
      return searchMemberMessageStart(state, action);
    case actionTypes.SEARCH_MEMBER_MESSAGE_SUCCESS:
      return searchMemberMessageSuccess(state, action);
    case actionTypes.SEARCH_MEMBER_MESSAGE_FAIL:
      return searchMemberMessageFail(state, action);
    case actionTypes.SEARCH_MEMBER_MESSAGE_RESET:
      return searchMemberMessageReset(state);

    case actionTypes.SEARCH_MEMBER_VEHICLE_START:
      return searchMemberVehicleStart(state, action);
    case actionTypes.SEARCH_MEMBER_VEHICLE_SUCCESS:
      return searchMemberVehicleSuccess(state, action);
    case actionTypes.SEARCH_MEMBER_VEHICLE_FAIL:
      return searchMemberVehicleFail(state, action);
    case actionTypes.SEARCH_MEMBER_VEHICLE_RESET:
      return searchMemberVehicleReset(state);

    case actionTypes.GET_CREDIT_HISTORY_START:
      return searchCreditHistoryStart(state, action);
    case actionTypes.GET_CREDIT_HISTORY_SUCCESS:
      return searchCreditHistorySuccess(state, action);
    case actionTypes.GET_CREDIT_HISTORY_FAIL:
      return searchCreditHistoryFail(state, action);
    case actionTypes.GET_CREDIT_HISTORY_RESET:
      return searchCreditHistoryReset(state);

    case actionTypes.ADD_FREE_CREDIT_START:
      return addFreeCreditStart(state, action);
    case actionTypes.ADD_FREE_CREDIT_SUCCESS:
      return addFreeCreditSuccess(state, action);
    case actionTypes.ADD_FREE_CREDIT_FAIL:
      return addFreeCreditFail(state, action);
    case actionTypes.ADD_FREE_CREDIT_RESET:
      return addFreeCreditReset(state);

    case actionTypes.REVERSE_CREDIT_START:
      return reverseCreditStart(state, action);
    case actionTypes.REVERSE_CREDIT_SUCCESS:
      return reverseCreditSuccess(state, action);
    case actionTypes.REVERSE_CREDIT_FAIL:
      return reverseCreditFail(state, action);
    case actionTypes.REVERSE_CREDIT_RESET:
      return reverseCreditReset(state);

    default:
      return state;
  }
};
export default reducer;

import * as actionTypes from "../actions/actionTypes";
import { reducerUpdateObject } from "./reducer.utility";

const initialState = {
  searchDataResponse: null,
  loading: false
};

const getSearchResultStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
const getSearchResultSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    searchDataResponse: action.searchDataResponse,
    error: null,
    loading: false
  });
};
const getSearchResultFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false
  });
};
const getSearchResultReset = (state, action) => {
  return reducerUpdateObject(state, { searchDataResponse: null });
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SEARCH_PAGES_START:
      return getSearchResultStart(state, action);
    case actionTypes.SEARCH_PAGES_SUCCESS:
      return getSearchResultSuccess(state, action);
    case actionTypes.SEARCH_PAGES_FAIL:
      return getSearchResultFail(state, action);
    case actionTypes.SEARCH_PAGES_RESET:
      return getSearchResultReset(state, action);

    default:
      return state;
  }
};
export default reducer;

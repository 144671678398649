import moment from "moment";
import React, { useEffect, useState } from "react";
import Draggable from "react-draggable";
import { useDispatch, useSelector } from "react-redux";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import DefaultComponentWithMessage from "../../../../components/common/pages/default-component-with-message";
import NoRecordFoundComponent from "../../../../components/common/pages/no-record-found";
import Pagination from "../../../../components/pagination/Pagination";
import { renderInputField } from "../../../../components/UI/Input/input-ctrl-helper";
import { showNotification } from "../../../../components/UI/Toaster/Toaster";
import FoxMemberSearchRequest from "../../../../services/entities/fox-api/request/fox-member-search-request";
import MemberSearchRequest from "../../../../services/entities/requests/MemberSearchRequest";
import { ADMIN_COMMON_CAPTION, TOAST_MSG } from "../../../../shared/GlobalVar";
import {
  getBasicToastResponse,
  getDateOnlyString,
  getFormattedPhoneNumber,
  getFullName,
} from "../../../../shared/utility";
import { getMemberLabelByOrgRentalType } from "../../../../shared/utility/system-utility";
import {
  getSearchResult,
  getSearchResultReset,
} from "../../../../store/actions/index";
import {
  convertFoxApiDateToStr,
  onInputChangedHandler,
} from "../../../Reservation/fox-reservation/fox-res-helper";
import { getSearchMemberResInputCtrl } from "./search-member-modal-input-ctrl";

export const SearchMemberModal = (props) => {
  const { customerModel, loadSelectedMemberData, enableSearchFilter, showModal, closeModal } =
    props;
  const convertFoxCustomerToSearchCustomerReq = (customerData) => {
    return {
      EmailAddress: customerData.email,
      FirstName: customerData.firstName,
      LastName: customerData.lastName,
      DateOfBirth: customerData.dob,
      ZipCodeId:
        customerData.primaryAddress && customerData.primaryAddress
          ? customerData.primaryAddress.postalCode
          : null,
      DrivingLicenseNumber:
        customerData.license && customerData.license
          ? customerData.license.number
          : null,
      SearchType: "MEMBER",
      SearchValue: customerData.renterId,
      PersonalNumber: customerData && customerData.mobilePhone ? customerData.mobilePhone.number : "",
    };
  };
  const [searchMemberRequest, setSearchMemberRequest] = useState(
    new FoxMemberSearchRequest(
      convertFoxCustomerToSearchCustomerReq(customerModel)
    )
  );
  const [searchData, setSearchData] = useState(null);
  const [isCancelSearchResult, setIsCancelSearchResult] = useState(false);
  const searchDataResponse = useSelector(
    (state) => state.searchPagesReducer.searchDataResponse || null
  );
  const dispatch = useDispatch();
  useEffect(() => { }, [searchMemberRequest]);
  useEffect(() => {
    initData();
  }, []);
  useEffect(() => {
    if (!searchDataResponse) {
      setSearchData(null);
      return;
    }
    if (searchDataResponse && searchDataResponse.StatusCode === 0) {
      if (enableSearchFilter && searchDataResponse.TotalRecords > 0) {
        setSearchData(searchDataResponse);
      } else if (enableSearchFilter) {
        closeModal();
      } else if (searchDataResponse.TotalRecords > 0) {
        setIsCancelSearchResult(true)
        setSearchData(searchDataResponse);
      } else {
        setSearchData(searchDataResponse);
        dispatch(getSearchResultReset());
        closeModal();
      }
    } else {
      showNotification(getBasicToastResponse(searchDataResponse));
      dispatch(getSearchResultReset());
    }
  }, [searchDataResponse]);
  // useEffect(() => {
  //   getSearchList(searchMemberRequest);
  // }, [
  //   searchMemberRequest.PageInfo.PageNumber,
  //   searchMemberRequest.PageInfo.PerPageCount,
  // ]);

  const initData = () => {
    dispatch(getSearchResultReset());
    if (
      searchMemberRequest.SearchValue ||
      searchMemberRequest.FirstName ||
      searchMemberRequest.MiddleName ||
      searchMemberRequest.LastName ||
      searchMemberRequest.EmailAddress ||
      searchMemberRequest.DrivingLicenseNumber ||
      searchMemberRequest.PersonalNumber ||
      searchMemberRequest.DateOfBirth ||
      searchMemberRequest.LastName
    ) {
      getSearchList(searchMemberRequest);
    } else {
      dispatch(getSearchResultReset());
    }
  };
  const resetSearch = () => {
    setSearchMemberRequest(new MemberSearchRequest());
  };
  const showErrorMsg = (msg) => {
    var toastMsg = {
      msg: msg,
      toastType: TOAST_MSG.ERROR,
    };
    showNotification(toastMsg);
  };
  const getSearchList = (serchMemReq) => {
    if (
      serchMemReq.SearchValue ||
      serchMemReq.FirstName ||
      serchMemReq.MiddleName ||
      serchMemReq.LastName ||
      serchMemReq.EmailAddress ||
      serchMemReq.DrivingLicenseNumber ||
      serchMemReq.PersonalNumber ||
      serchMemReq.DateOfBirth ||
      serchMemReq.LastName ||
      serchMemReq.Address ||
      serchMemReq.ZipCode
    ) {
      let reqObj = JSON.parse(JSON.stringify(serchMemReq));
      reqObj.DateOfBirth = reqObj.DateOfBirth ? convertFoxApiDateToStr(
        moment(reqObj.DateOfBirth).toDate(),
        "MM:DD:YYYY:HH:mm"
      ) : ""
      dispatch(getSearchResultReset());
      dispatch(getSearchResult("membersearch", reqObj));
    } else {
      showErrorMsg("Please select or enter an input.");
    }
  };
  const inputChangedHandler = (
    ctrlData,
    controlName,
    elementType,
    objectType
  ) => {
    onInputChangedHandler(
      ctrlData,
      controlName,
      elementType,
      objectType,
      searchMemberRequest,
      setSearchMemberRequest
    );
  };
  const onPageChanged = (currentPage, recordsPerPage) => {
    let newCtrl = { ...searchMemberRequest };
    newCtrl.PageInfo.PageNumber = currentPage;
    newCtrl.PageInfo.PerPageCount = recordsPerPage;
    setSearchMemberRequest(newCtrl);
    getSearchList(newCtrl);
  };
  const onFormSubmit = () => {
    getSearchList(searchMemberRequest);
  };
  const renderTableColumnValue = (value, isEmail) => {
    return (
      <span className="row__column">
        {isEmail ? <a href="#">{value}</a> : value || "--"}
      </span>
    );
  };
  const renderSearchInputForm = () => {
    const inputCtrl = getSearchMemberResInputCtrl(searchMemberRequest, true);
    return inputCtrl.lblInputFields.map((field, i) => {
      return (
        <div
          className="form-group formRow col-sm-4"
          key={field.inputModel.name + i}
        >
          <label
            htmlFor={field.inputModel.name}
            className="col-sm-6  font-weight-bold"
          >
            {field.lblModel.text}
          </label>
          <div className="col-sm-6">
            {renderInputField(
              field,
              field.inputModel.value,
              inputChangedHandler
            )}
          </div>
        </div>
      );
    });
  };
  const renderList = () => {
    if (searchData && searchData.SearchList && searchData.SearchList.length > 0) {
      return searchData.SearchList.map((memberObj) => {
        return (
          <div
            className="row"
            key={memberObj.PersonId}
            onClick={() => loadSelectedMemberData(memberObj)}
            style={{ cursor: "pointer" }}
          >
            {renderTableColumnValue(memberObj.MembershipId)}
            {renderTableColumnValue(
              getFullName(
                memberObj.FirstName,
                memberObj.LastName,
                memberObj.MiddleName
              )
            )}
            {renderTableColumnValue(getDateOnlyString(memberObj.DateOfBirth))}
            {renderTableColumnValue(memberObj.EmailAddress, false)}
            {/* {renderTableColumnValue(
              memberObj.DrivingLicense
                ? memberObj.DrivingLicense + "/" + memberObj.DrivingLicenseState
                : "--"
            )} */}
            {renderTableColumnValue(memberObj.FormattedPrimaryAddress)}
            {renderTableColumnValue(
              getFormattedPhoneNumber(
                null,
                null,
                memberObj.hasOwnProperty("HomePhone")
                  ? memberObj.HomePhone
                  : null,
                "firstnumber"
              )
            )}
            {renderTableColumnValue(
              getFormattedPhoneNumber(
                memberObj.hasOwnProperty("MobilePhone")
                  ? memberObj.MobilePhone
                  : null,
                memberObj.hasOwnProperty("WorkPhone")
                  ? memberObj.WorkPhone
                  : null,
                null,
                "firstnumber"
              )
            )}
          </div>
        );
      });
    } else {
      return searchData && searchData.TotalRecords === 0 ? (
        <div style={{ marginTop: "10px" }}>
          <NoRecordFoundComponent heights="29rem" />
        </div>
      ) : (
        <div style={{ marginTop: "10px" }}>
          <DefaultComponentWithMessage heights="29rem" />
        </div>
      );
    }
  };
  const closeSearchModal = () => {
    if (isCancelSearchResult) {
      closeModal(isCancelSearchResult)
    } else {
      closeModal()
    }
  }
  const renderMainModal = () => {
    return (
      <Draggable handle=".cursor">
        <Modal
          isOpen={showModal}
          className="no-cursor"
          toggle={() => {
            closeSearchModal();
          }}
          style={{
            minWidth: "1200px",
          }}
        >
          <ModalHeader
            className="modal-header bg-primary modal-title text-white cursor"
            toggle={() => {
              closeSearchModal();
            }}
          >
            <i className="fas fa-arrows-alt" />{" "}
            {getMemberLabelByOrgRentalType("Search")}
          </ModalHeader>
          <ModalBody
            style={{
              height: "auto",
            }}
          >
            {enableSearchFilter ? <>
              <div className="FormRow col-lg-12 row">{renderSearchInputForm()}</div>
              <div
                className="popupButtonRow col-lg-12"
                style={{ textAlign: "right", margin: "0px" }}
              >
                <button
                  type="button"
                  name="btnMemberSearch"
                  className="btn btn-primary waves-effect waves-light btnSize ml-2"
                  onClick={() => onFormSubmit()}
                >
                  <i className="fa fa-check-square-o" />
                  Search
                </button>
                <button
                  type="button"
                  name="btnCancel"
                  className="btn btn-secondary waves-effect waves-light btnSize ml-2"
                  onClick={() => resetSearch()}
                >
                  Reset
                </button>
              </div>
            </> : ""}
            <div
              className="input-container"
              style={{ marginTop: "10px", width: "100%" }}
            >
              {searchData ? (
                <Pagination
                  totalRecords={searchData.TotalRecords}
                  recordsPerPage={searchMemberRequest.PageInfo.PerPageCount}
                  currentPage={searchMemberRequest.PageInfo.PageNumber}
                  onPageChanged={onPageChanged}
                  key="sech-pagination-key"
                />
              ) : (
                ""
              )}

              <div className="FormRow">
                <section className="grid-table" id="memberSearchPopupTable">
                  <div className="datagrid">
                    <div className="column">
                      <span className="column__header">
                        {ADMIN_COMMON_CAPTION.CUSTOMER_ID}
                      </span>
                      <span className="column__header">Name</span>
                      <span className="column__header">Date of Birth</span>
                      <span className="column__header">Email/Username</span>
                      {/* <span className="column__header">Driver's Lic.</span> */}
                      <span className="column__header">Address</span>
                      <span className="column__header">Phone No.</span>
                      <span className="column__header">Alternate No.</span>
                    </div>
                    <div className="popupSearchResultScroll">{renderList()}</div>
                  </div>
                </section>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </Draggable>
    );
  }
  return (
    enableSearchFilter ? renderMainModal() : searchData?.TotalRecords > 0 ? renderMainModal() : ""

  );
};
export default SearchMemberModal;

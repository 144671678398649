const SpecialPricing = function (data) {
  if (!data) data = {};
  this.CategoryId = data.CategoryId || null;
  this.OrgUnitId = data.OrgUnitId || null;
  this.RateId = data.RateId || null;
  this.StartDate = data.StartDate || null;
  this.EndDate = data.EndDate || null;
  this.RatePerDay = data.RatePerDay || null;
  this.RatePerHour = data.RatePerHour || null;
  this.RatePerMonth = data.RatePerMonth || null;
  this.RatePerWeek = data.RatePerWeek || null;
  this.RatePerWeekendDay = data.RatePerWeekendDay || null;
  this.SharerHourlyPrice = data.SharerHourlyPrice || null;
  this.SharerDailyPrice = data.SharerDailyPrice || null;
  this.SharerWeeklyPrice = data.SharerWeeklyPrice || null;
  this.SharerMonthlyPrice = data.SharerMonthlyPrice || null;
  this.SharerWeekendDayPrice = data.SharerWeekendDayPrice || null;
};
export default SpecialPricing;

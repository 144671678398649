import { ADMIN_COMMON_CAPTION } from "../../../shared/GlobalVar";

export const getGlobalSearchAreas = () => {
    return [
      {
        value: "Rez #",
        key: "RESERVATION_CODE",
      },
      {
        value: "Name",
        key: "CUSTOMER_NAME",
      },
      {
        value: ADMIN_COMMON_CAPTION.CUSTOMER_ID,
        key: "CUSTOMER_ID",
      },
      {
        value: "Phone",
        key: "CUSTOMER_PHONE",
      },
      {
        value: "Email",
        key: "CUSTOMER_EMAIL",
      },
      {
        value: "Address",
        key: "CUSTOMER_ADDRESS",
      },
      {
        value: "All",
        key: "ALL",
      },
    ];
  };
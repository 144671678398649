import React, { useEffect, useState, useRef, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactHtmlParser from "react-html-parser";
import {
  getOrgPermissionsData,
  getOrgPermissionsDataReset,
  editOrgPermissionsData,
  editOrgPermissionsDataReset,
} from "../../../store/actions/index";
import Spinner from "../../../components/UI/Spinner/Spinner";
import PermissionName from "../../../shared/Permissions";
import {
  hasPermission,
  getBasicToastResponse,
  getNoteTooltipHtml,
  toTitleCase,
  convertPermissionURLToString,
} from "../../../shared/utility";
import { showNotification } from "../../../components/UI/Toaster/Toaster";
import {
  PermissionSettingsViewModel,
  PermissionModel,
} from "../../../services/entities/view-models/settings-view-models";
import PermissionUpdateModal from "./modal/permission-update-modal";
import TooltipItem from "../../../components/tooltip/TooltipItem";
import NoRecordFoundComponent from "../../../components/common/pages/no-record-found";
import { getPermissionTypes } from "../../../shared/json/permission-types";
import ActionIcon from "../../../components/UI/icon/action-icon";
import { createTooltip } from "../../../shared/utility/tooltip-utility";

const PermissionSetting = (props) => {
  const loading = useSelector((state) => state.rolesPermissionsReducer.loading);
  const currentOrgUnitId = useSelector(
    (state) => state.auth.loggedInUserData.CurrentOrgUnitId
  );
  const getOrgPermissionsDataResponse = useSelector(
    (state) => state.rolesPermissionsReducer.getOrgPermissionsDataResponse
  );
  const editOrgPermissionsDataResponse = useSelector(
    (state) => state.rolesPermissionsReducer.editOrgPermissionsDataResponse
  );

  const dispatch = useDispatch();
  const query = props.location
    ? new URLSearchParams(props.location.search)
    : null;

  const selectedOrgLocationId =
    props.otherData && props.otherData.selectedOrgLocationId
      ? props.otherData.selectedOrgLocationId
      : query
      ? query.get("orgId")
      : currentOrgUnitId;

  const [permissionSettingsData, setPermissionSettingsData] = useState(
    new PermissionSettingsViewModel()
  );
  const [showPermissionModal, setShowPermissionModal] = useState(false);

  const [selectedPermissionEntity, setSelectedPermissionEntity] = useState(
    new PermissionModel()
  );
  const hasEditPermission = hasPermission(PermissionName.EDIT_PERMISSION);

  useEffect(() => {
    getAllPermissions();
  }, [selectedOrgLocationId]);

  useEffect(() => {
    if (!getOrgPermissionsDataResponse) {
      return;
    }

    if (getOrgPermissionsDataResponse.StatusCode === 0) {
      setPermissionSettingsData(
        new PermissionSettingsViewModel(getOrgPermissionsDataResponse)
      );
    }
  }, [getOrgPermissionsDataResponse]);

  useEffect(() => {
    if (!editOrgPermissionsDataResponse) {
      return;
    }
    showNotification(getBasicToastResponse(editOrgPermissionsDataResponse));
    if (editOrgPermissionsDataResponse.StatusCode === 0) {
      getAllPermissions();
      closePermissionModal();
      dispatch(editOrgPermissionsDataReset());
    }
    dispatch(editOrgPermissionsDataReset());
  }, [editOrgPermissionsDataResponse]);

  const getAllPermissions = () => {
    const req = {};
    req.SelectedChildOrgUnitId = selectedOrgLocationId;
    dispatch(getOrgPermissionsDataReset());
    dispatch(getOrgPermissionsData(req));
  };

  const getPermissionModal = (entity) => {
    if (entity) {
      setSelectedPermissionEntity(entity);
    }
    setShowPermissionModal(true);
  };

  const closePermissionModal = () => {
    setShowPermissionModal(false);
    setSelectedPermissionEntity(new PermissionModel());
  };

  const addUpdatePermissionEntity = (updateEntity) => {
    updatePermissionData(updateEntity);
  };

  const updatePermissionData = (req) => {
    const reqObj = {};
    reqObj.Permission = {
      ...req,
    };
    dispatch(editOrgPermissionsDataReset());
    dispatch(editOrgPermissionsData(reqObj));
  };

  const renderMainLayout = () => {
    return (
      <Fragment>
        <div
          className="col-lg-12 mb-2  font-weight-bold  d-flex"
          style={{ height: "2.5rem" }}
        >
          <label className="mr-auto fs-2">Permissions: </label>
        </div>
        <div
          className="col-lg-12 tableScroll bb-0 table-fixed-head pr-0 table-container"
          style={{ maxHeight: "60rem" }}
        >
          <table className="table table-striped" id="permissionTable">
            <thead className="thead-primary">
              <tr>
                <th>{ReactHtmlParser("Permission <br> Name")}</th>
                <th>Description</th>
                <th>{ReactHtmlParser("Permission <br> Type")}</th>
                <th>{ReactHtmlParser("Error <br> Code")}</th>
                <th>Error Msg</th>
                <th>{ReactHtmlParser("Visible to <br> SuperAdmin Only")}</th>
                <th>Associated Webservice URL(s)</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>{renderPermissionList()}</tbody>
          </table>
        </div>
      </Fragment>
    );
  };

  const renderPermissionList = () => {
    if (permissionSettingsData.Permissions.length > 0) {
      return permissionSettingsData.Permissions.map((entity) => {
        return (
          <tr key={entity.PermissionId}>
            <td className="text-left" id={"name_" + entity.PermissionId}>
              {createTooltip(entity.Name || "--", entity.Name)}
            </td>
            <td className="text-left" id={"displayText_" + entity.PermissionId}>
              {createTooltip(entity.DisplayText || "--", entity.DisplayText)}
            </td>
            <td className="text-left">{entity.PermissionType || "--"}</td>
            <td>{entity.ErrorCode || "--"}</td>
            <td className="text-left" id={"errorMsg_" + entity.PermissionId}>
              {createTooltip(entity.ErrorMsg || "--", entity.ErrorMsg)}
            </td>

            <td>{toTitleCase(String(entity.VisibleToSuperAdminOnly))}</td>
            <td className="text-left">
              {entity.URIs
                ? ReactHtmlParser(convertPermissionURLToString(entity.URIs))
                : "--"}
            </td>

            <td>
              <Fragment>
                <ActionIcon
                  iconId={"editId_" + entity.PermissionId}
                  className="fas fa-pencil-alt fs-3 pl-1 pr-2"
                  fontColor="blue-color"
                  actionFunction={getPermissionModal}
                  disabled={!hasEditPermission}
                  value={
                    hasEditPermission
                      ? "Edit"
                      : "You do not have permission to Edit"
                  }
                  param1={entity}
                  forceShowTooltip={true}
                />
              </Fragment>
            </td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colSpan="15">
            <NoRecordFoundComponent
              className="border-none"
              innerClassName="no-setting-record-found"
              searchMsg="No Record Found"
              heights="5rem"
            />
          </td>
        </tr>
      );
    }
  };

  return (
    <div className="col-lg-12 pt-3 pl-0">
      <div className="card">
        <div className="card-body">
          <div className="form-header row col-lg-12 p-0">
            <h4 className="text-uppercase mr-auto icon-display-none">
              <i className="fas fa-cogs"></i>
              Permission Settings
            </h4>
          </div>
          {renderMainLayout()}
        </div>
        <div style={{ height: "2.5rem" }}>&nbsp;</div>
      </div>
      {loading ? <Spinner /> : ""}
      {showPermissionModal ? (
        <PermissionUpdateModal
          showModal={showPermissionModal}
          closeModal={closePermissionModal}
          selectedEntity={selectedPermissionEntity}
          addUpdatePermissionEntity={addUpdatePermissionEntity}
          permissionTypes={getPermissionTypes()}
          parmissionList={permissionSettingsData.PermissionList}
        />
      ) : (
        ""
      )}
    </div>
  );
};
export default PermissionSetting;

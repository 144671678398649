import React from "react";
export const NoRecordFoundComponent = (props) => {
  return (
    <div
      className={
        props.className ? props.className + " area-container" : "area-container"
      }
      style={{ height: props.heights ? props.heights : "" }}
    >
      <div
        className={
          props.innerClassName
            ? props.innerClassName + " no-record-found"
            : "no-record-found"
        }
      >
        {props.searchMsg || "No Record Found"}
      </div>
    </div>
  );
};

export default NoRecordFoundComponent;

import React from "react";
import RocksTooltip, {
  Trigger,
  Hover,
} from "../../components/tooltip/rocks-tooltip";

export const createTooltip = (triggerHtml, tooltipText, customTooltipObj) => {
  const optionsCursorTrueWithMargin = {
    followCursor: true,
    shiftX:
      customTooltipObj && customTooltipObj.shiftX
        ? customTooltipObj.shiftX
        : 20,
    shiftY:
      customTooltipObj && customTooltipObj.shiftY ? customTooltipObj.shiftY : 0,
  };
  const childTag = {
    tag:
      customTooltipObj && customTooltipObj.childTag
        ? customTooltipObj.childTag
        : "label",
  };
  const tooltipHtmlValidator =
    triggerHtml && triggerHtml.props ? (
      triggerHtml
    ) : (
      <childTag.tag>{triggerHtml}</childTag.tag>
    );
  return (tooltipText && tooltipText !== "--") ||
    (customTooltipObj && customTooltipObj.forceElementShow) ? (
    <RocksTooltip
      options={optionsCursorTrueWithMargin}
      otherOptions={customTooltipObj}
      key={tooltipText}
    >
      <Trigger type="trigger">{tooltipHtmlValidator}</Trigger>
      <Hover type="hover">
        {prepareTooltip(tooltipText, customTooltipObj)}
      </Hover>
    </RocksTooltip>
  ) : !tooltipText && customTooltipObj && customTooltipObj.defaultTxt ? (
    customTooltipObj.defaultTxt
  ) : (
    "--"
  );
};
const prepareTooltip = (tooltipText, customTooltipObj) => {
  let tooltipHtml = "";
  const containerWidth =
    customTooltipObj && customTooltipObj.tooltipWidth
      ? customTooltipObj.tooltipWidth
      : "auto";
  const containerMaxWidth =
    customTooltipObj && customTooltipObj.tooltipMaxWidth
      ? customTooltipObj.tooltipMaxWidth
      : "50em";
  if (String(tooltipText) !== "") {
    tooltipHtml = (
      <div className="common-tooltip" style={{ maxWidth: containerMaxWidth }}>
        <span style={{ width: containerWidth }}>{tooltipText}</span>
      </div>
    );
  }
  return tooltipHtml;
};

const PageInfo = function (
  pageNumber,
  recordsPerPage,
  sortBy,
  sortType,
  totalRecords
) {
  this.PageNumber = pageNumber || 1;
  this.PerPageCount = recordsPerPage || 100;
  this.SortBy = sortBy || null;
  this.SortType = sortType || null;
  if (totalRecords) {
    this.TotalRecords = totalRecords || null;
  }
};
export default PageInfo;

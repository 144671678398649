import * as actionTypes from "../actions/actionTypes";
import { reducerUpdateObject } from "./reducer.utility";

const initialState = {
  primaryCountryResponse: null,
  primaryStateResponse: null,
  primaryCityResponse: null,
  primaryPostalResponse: null,
  licenseCountryResponse: null,
  licenseStateResponse: null,
  creditCountryResponse: null,
  creditStateResponse: null,
  creditCityResponse: null,
  creditPostalResponse: null,
  error: null,
  loading:true,
  primaryCountryLoading: false,
  primaryStateLoading: false,
  primaryCityLoading: false,
  primaryPostalLoading: false,
  licenseCountryLoading: false,
  licenseStateLoading: false,
  creditCountryLoading: false,
  creditStateLoading: false,
  creditCityLoading: false,
  creditPostalLoading: false,
};

/**Countries*/
const getCurrentAddressLoader=(state)=>{
  return state.primaryCountryLoading 
  || state.primaryStateLoading 
  || state.primaryCityLoading
  || state.primaryPostalLoading 
  || state.licenseCountryLoading
  || state.licenseStateLoading 
  || state.creditCountryLoading
  || state.creditStateLoading 
  || state.creditCityLoading
  || state.creditPostalLoading
}
/**Primary Country*/
const getPrimaryCountriesStart = (state) => {
  return reducerUpdateObject(state, { error: null, primaryCountryLoading: true });
};
const getPrimaryCountriesSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    primaryCountryResponse: action.countriesResponse,
    error: null,
    primaryCountryLoading: false
  });
};
const getPrimaryCountriesReset = state => {
  return reducerUpdateObject(state, {
    primaryCountryResponse: null
  });
};
const getPrimaryCountriesFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    primaryCountryLoading: false
  });
};
/**License Country*/
const getLicenseCountriesStart = (state) => {
  return reducerUpdateObject(state, { error: null, licenseCountryLoading: true });
};
const getLicenseCountriesSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    licenseCountryResponse: action.countriesResponse,
    error: null,
    licenseCountryLoading: false
  });
};
const getLicenseCountriesReset = state => {
  return reducerUpdateObject(state, {
    licenseCountryResponse: null
  });
};
const getLicenseCountriesFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    licenseCountryLoading: false
  });
};
/**Credit Country*/
const getCreditCountriesStart = (state) => {
  return reducerUpdateObject(state, {error: null, creditCountryLoading: true });
};
const getCreditCountriesSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    creditCountryResponse: action.countriesResponse,
    error: null,
    creditCountryLoading: false
  });
};
const getCreditCountriesReset = state => {
  return reducerUpdateObject(state, {
    creditCountryResponse: null
  });
};
const getCreditCountriesFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    creditCountryLoading: false
  });
};

/**States*/
/**Primary States*/
const getPrimaryStatesStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, primaryStateLoading: true });
};
const getPrimaryStatesSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    primaryStateResponse: action.statesResponse,
    error: null,
    primaryStateLoading: false
  });
};
const getPrimaryStatesReset = state => {
  return reducerUpdateObject(state, {
    primaryStateResponse: null
  });
};
const getPrimaryStatesFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    primaryStateLoading: false
  });
};
/**License States*/
const getLicenseStatesStart = (state, action) => {
  return reducerUpdateObject(state, {error: null, licenseStateLoading: true });
};
const getLicenseStatesSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    licenseStateResponse: action.statesResponse,
    error: null,
    licenseStateLoading: false
  });
};
const getLicenseStatesReset = state => {
  return reducerUpdateObject(state, {
    licenseStateResponse: null
  });
};
const getLicenseStatesFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    licenseStateResponse: false
  });
};
/**Credit States*/
const getCreditStatesStart = (state, action) => {
  return reducerUpdateObject(state, {error: null, creditStateLoading: true });
};
const getCreditStatesSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    creditStateResponse: action.statesResponse,
    error: null,
    creditStateLoading: false
  });
};
const getCreditStatesReset = state => {
  return reducerUpdateObject(state, {
    creditStateResponse: null
  });
};
const getCreditStatesFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    creditStateLoading: false
  });
};

/**Cities*/
/**Primary Cities*/
const getPrimaryCitiesStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, primaryCityLoading: true });
};
const getPrimaryCitiesSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    primaryCityResponse: action.citiesResponse,
    error: null,
    primaryCityLoading: false
  });
};
const getPrimaryCitiesFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    primaryCityLoading: false
  });
};
const getPrimaryCitiesReset = state => {
  return reducerUpdateObject(state, {
    primaryCityResponse: null
  });
};
/**Credit Cities*/
const getCreditCitiesStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, creditCityLoading: true });
};
const getCreditCitiesSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    creditCityResponse: action.citiesResponse,
    error: null,
    creditCityLoading: false
  });
};
const getCreditCitiesFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    creditCityLoading: false
  });
};
const getCreditCitiesReset = state => {
  return reducerUpdateObject(state, {
    creditCityResponse: null
  });
};

/**Postals*/
/**Primary Postals*/
const getPrimaryPostalsStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, primaryPostalLoading: true });
};
const getPrimaryPostalsSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    primaryPostalResponse: action.postalsResponse,
    error: null,
    primaryPostalLoading: false
  });
};
const getPrimaryPostalsReset = state => {
  return reducerUpdateObject(state, {
    primaryPostalResponse: null
  });
};
const getPrimaryPostalsFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    primaryPostalLoading: false
  });
};
/**Credit Postals*/
const getCreditPostalsStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, creditPostalLoading: true });
};
const getCreditPostalsSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    creditPostalResponse: action.postalsResponse,
    error: null,
    creditPostalLoading: false
  });
};
const getCreditPostalsReset = state => {
  return reducerUpdateObject(state, {
    creditPostalResponse: null
  });
};
const getCreditPostalsFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    creditPostalLoading: false
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.PRIMARY_COUNTRIES_START:
      return getPrimaryCountriesStart(state, action);
    case actionTypes.PRIMARY_COUNTRIES_SUCCESS:
      return getPrimaryCountriesSuccess(state, action);
    case actionTypes.PRIMARY_COUNTRIES_FAIL:
      return getPrimaryCountriesFail(state, action);
    case actionTypes.PRIMARY_COUNTRIES_RESET:
      return getPrimaryCountriesReset(state);
    case actionTypes.LICENSE_COUNTRIES_START:
      return getLicenseCountriesStart(state, action);
    case actionTypes.LICENSE_COUNTRIES_SUCCESS:
      return getLicenseCountriesSuccess(state, action);
    case actionTypes.LICENSE_COUNTRIES_FAIL:
      return getLicenseCountriesFail(state, action);
    case actionTypes.LICENSE_COUNTRIES_RESET:
      return getLicenseCountriesReset(state);
    case actionTypes.CREDIT_COUNTRIES_START:
      return getCreditCountriesStart(state, action);
    case actionTypes.CREDIT_COUNTRIES_SUCCESS:
      return getCreditCountriesSuccess(state, action);
    case actionTypes.CREDIT_COUNTRIES_FAIL:
      return getCreditCountriesFail(state, action);
    case actionTypes.CREDIT_COUNTRIES_RESET:
      return getCreditCountriesReset(state);

    case actionTypes.PRIMARY_STATES_START:
      return getPrimaryStatesStart(state, action);
    case actionTypes.PRIMARY_STATES_SUCCESS:
      return getPrimaryStatesSuccess(state, action);
    case actionTypes.PRIMARY_STATES_FAIL:
      return getPrimaryStatesFail(state, action);
    case actionTypes.PRIMARY_STATES_RESET:
      return getPrimaryStatesReset(state);
    case actionTypes.LICENSE_STATES_START:
      return getLicenseStatesStart(state, action);
    case actionTypes.LICENSE_STATES_SUCCESS:
      return getLicenseStatesSuccess(state, action);
    case actionTypes.LICENSE_STATES_FAIL:
      return getLicenseStatesFail(state, action);
    case actionTypes.LICENSE_STATES_RESET:
      return getLicenseStatesReset(state);
    case actionTypes.CREDIT_STATES_START:
      return getCreditStatesStart(state, action);
    case actionTypes.CREDIT_STATES_SUCCESS:
      return getCreditStatesSuccess(state, action);
    case actionTypes.CREDIT_STATES_FAIL:
      return getCreditStatesFail(state, action);
    case actionTypes.CREDIT_STATES_RESET:
      return getCreditStatesReset(state);

    case actionTypes.PRIMARY_CITIES_START:
      return getPrimaryCitiesStart(state, action);
    case actionTypes.PRIMARY_CITIES_SUCCESS:
      return getPrimaryCitiesSuccess(state, action);
    case actionTypes.PRIMARY_CITIES_FAIL:
      return getPrimaryCitiesFail(state, action);
    case actionTypes.PRIMARY_CITIES_RESET:
      return getPrimaryCitiesReset(state);
    case actionTypes.CREDIT_CITIES_START:
      return getCreditCitiesStart(state, action);
    case actionTypes.CREDIT_CITIES_SUCCESS:
      return getCreditCitiesSuccess(state, action);
    case actionTypes.CREDIT_CITIES_FAIL:
      return getCreditCitiesFail(state, action);
    case actionTypes.CREDIT_CITIES_RESET:
      return getCreditCitiesReset(state);

    case actionTypes.PRIMARY_POSTALS_START:
      return getPrimaryPostalsStart(state, action);
    case actionTypes.PRIMARY_POSTALS_SUCCESS:
      return getPrimaryPostalsSuccess(state, action);
    case actionTypes.PRIMARY_POSTALS_FAIL:
      return getPrimaryPostalsFail(state, action);
    case actionTypes.PRIMARY_POSTALS_RESET:
      return getPrimaryPostalsReset(state);
    case actionTypes.CREDIT_POSTALS_START:
      return getCreditPostalsStart(state, action);
    case actionTypes.CREDIT_POSTALS_SUCCESS:
      return getCreditPostalsSuccess(state, action);
    case actionTypes.CREDIT_POSTALS_FAIL:
      return getCreditPostalsFail(state, action);
    case actionTypes.CREDIT_POSTALS_RESET:
      return getCreditPostalsReset(state);

    case actionTypes.GET_ADDRESS_LOADER:
        return getCurrentAddressLoader(state);

    default:
      return state;
  }
};
export default reducer;

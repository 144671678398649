import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "./../../store/actions/index";
import { withRouter, Link } from "react-router-dom";
import Moment from "moment";
import Chart from "../../components/chart/Chart";
import PermissionName from "../../shared/Permissions";
import  {
  clearActiveMenu,
  hasPermission,
  activeCurrentLink,
} from "../../shared/utility";
import { getMemberLabelByOrgRentalType } from "../../shared/utility/system-utility";
import {
  NewReservationImg,
  ExistingReservationImg,
  MembersImg,
  VehiclesImg,
  CAR_GARAGE_RED,
  CALENDAR_RED,
  USER_RED,
  CAR_RED,
} from "../../shared/AppImages";
import Spinner from "../../components/UI/Spinner/Spinner";
import AssetInOutSummary from "../Reservation/summary/asset-in-out-summary";
import { getBreadcrumbDataforSearchPage } from "../search/search-utility/search-utility";

class Dashboard extends Component {
  state = {
    ...initialState,
  };
  componentDidMount() {
    this.resetDashboardData();
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.currentOrgUnitId &&
      prevProps.currentOrgUnitId &&
      prevProps.currentOrgUnitId != this.props.currentOrgUnitId
    ) {
      this.setState({ ...initialState }, () => {
        this.resetDashboardData();
      });
    }
  }
  resetDashboardData = () => {
    this.setState({
      breadCrumbList: getBreadcrumbDataforSearchPage(
        this.props.match.url,
        this.props.location
      ),
    });
    this.resetFleetUtilizationSummary();
    this.props.onGetAssetInOutSummaryReset();
    this.props.onGetAssetInOutSummary();
  };
  UNSAFE_componentWillReceiveProps({
    assetInOutSummaryResponse,
    fleetUtilizationSummaryResponse,
  }) {
    if (
      (assetInOutSummaryResponse && !this.state.assetInOutSummaryResponse) ||
      (this.state.assetInOutSummaryResponse &&
        assetInOutSummaryResponse &&
        !this.props.assetInOutSummaryResponse)
    ) {
      this.setState({
        assetInOutSummaryResponse: assetInOutSummaryResponse,
      });
    }
    if (
      (fleetUtilizationSummaryResponse &&
        !this.state.fleetUtilizationSummaryResponse) ||
      (this.state.fleetUtilizationSummaryResponse &&
        fleetUtilizationSummaryResponse &&
        !this.props.fleetUtilizationSummaryResponse)
    ) {
      this.setState({
        fleetUtilizationSummaryResponse: fleetUtilizationSummaryResponse,
      });
    }
  }
  getFleetutilizationSummary = (direction) => {
    let startDate, endDate;
    if (direction === "prev") {
      startDate = Moment(this.state.startDate).add(-7, "days");
      endDate = Moment(this.state.endDate).add(-7, "days");
    } else if (direction === "next") {
      startDate = Moment(this.state.startDate).add(7, "days");
      endDate = Moment(this.state.endDate).add(7, "days");
    }
    this.setState({ startDate: startDate, endDate: endDate }, () => {
      this.resetFleetUtilizationSummary();
    });
  };
  resetFleetUtilizationSummary = () => {
    var endDate = this.state.endDate.clone();
    const flletUtilizationReq = {};
    flletUtilizationReq.Interval = this.state.interval;
    flletUtilizationReq.StartDate = this.state.startDate.format(
      "MM:DD:YYYY:HH:mm"
    );
    flletUtilizationReq.EndDate = endDate
      .add(1, "days")
      .format("MM:DD:YYYY:HH:mm");
    this.props.onGetFleetUtilizationSummaryReset();
    this.props.onGetFleetUtilizationSummary(flletUtilizationReq);
  };
  getDashBoardIcon = (parnetCLass, linkUrl, imgSrc, lbl, permissionName) => {
    return (
      <div
        className={`cardDashboard dashboard__quicklink--${
          parnetCLass +
          " " +
          (hasPermission(permissionName) ? "" : "noImageLink")
        }`}
      >
        <Link
          to={{
            pathname: hasPermission(permissionName) ? linkUrl : "",
            search: "",
            state: { breadCrumbList: this.state.breadCrumbList },
          }}
        >
          <img src={imgSrc} alt="Avatar" />
          <div className="quicklink__lbl">
            <h4>{lbl}</h4>
          </div>
        </Link>
      </div>
    );
  };
  setSortIndicator = ()=>{
    this.setState({isSorted: !this.state.isSorted})
  }
  render() {
    clearActiveMenu();
    activeCurrentLink("dashboard");
    return (
      <div className="container-fluid p-0">
        <div className="col-lg-12 row p-0 mt-5">
          <div className="col-lg-6 dashboard__top">
            <div className="dashboard__quicklink">
              {this.getDashBoardIcon(
                "card1",
                "/updateReservation",
                localStorage.getItem("loginMode")
                  ? CAR_GARAGE_RED
                  : NewReservationImg,
                "New Reservation",
                PermissionName.NEW_RESERVATION
              )}
              {this.getDashBoardIcon(
                "card2",
                "/reservation",
                localStorage.getItem("loginMode")
                  ? CALENDAR_RED
                  : ExistingReservationImg,
                "Existing Reservation",
                PermissionName.VIEW_RESERVATION
              )}
              {this.getDashBoardIcon(
                "card3",
                "/member",
                localStorage.getItem("loginMode") ? USER_RED : MembersImg,
                getMemberLabelByOrgRentalType() + "s",
                PermissionName.VIEW_MEMBER
              )}
              {this.getDashBoardIcon(
                "card4",
                "/vehicle",
                localStorage.getItem("loginMode") ? CAR_RED : VehiclesImg,
                "Vehicles",
                PermissionName.VIEW_VEHICLE
              )}
            </div>
          </div>
          <div className="col-lg-6 dashboard__top">
            <div className="dashboard__graph">
              <div className="dashboard-graph">
                <div className="form-group formRow col-sm-12  pr-0">
                  <div
                    className="col-sm-4"
                    style={{ textAlign: "left", cursor: "pointer" }}
                  >
                    <span
                      className=""
                      onClick={(event) =>
                        this.getFleetutilizationSummary("prev")
                      }
                    >
                      <i
                        className="fa fa-arrow-circle-left"
                        aria-hidden="true"
                      ></i>
                    </span>
                  </div>
                  <div className="col-sm-4 graphBetween">
                    {this.state.startDate
                      ? Moment(this.state.startDate).format("MMM DD, YYYY")
                      : ""}
                    {" - "}
                    {this.state.endDate
                      ? Moment(this.state.endDate).format("MMM DD, YYYY")
                      : ""}
                  </div>
                  <div
                    className="col-sm-4 p-0"
                    style={{ textAlign: "right", cursor: "pointer" }}
                  >
                    <span
                      className=""
                      onClick={(event) =>
                        this.getFleetutilizationSummary("next")
                      }
                    >
                      <i
                        className="fa fa-arrow-circle-right"
                        aria-hidden="true"
                      ></i>
                    </span>
                  </div>
                </div>
                <Chart chartData={this.state.fleetUtilizationSummaryResponse} />
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-12 row mt-5 p-0">
          <div className="row col-lg-6 dashboard__bottom">
            <div className="dashboard__reservationgrid">
              <div className="dashboard__reservationgrid--table">
                {hasPermission(PermissionName.VIEW_RESERVATION) ? (
                  <AssetInOutSummary
                    summaryType="OUT_SUMMARY"
                    assetSummaryData={this.state.assetInOutSummaryResponse}
                    key="assetInSummaryTable"
                    breadCrumbList={this.state.breadCrumbList}
                    setSortIndicator={this.setSortIndicator}
                    isSorted = {this.state.isSorted}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <div className="row col-lg-6 dashboard__bottom">
            <div className="dashboard__rentalgrid">
              <div className="dashboard__rentalgrid--table">
                {hasPermission(PermissionName.VIEW_RESERVATION) ? (
                  <AssetInOutSummary
                    summaryType="IN_SUMMARY"
                    assetSummaryData={this.state.assetInOutSummaryResponse}
                    key="assetOutSummaryTable"
                    breadCrumbList={this.state.breadCrumbList}
                    setSortIndicator={this.setSortIndicator}
                    isSorted = {this.state.isSorted}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
        {this.props.loading ? <Spinner /> : ""}
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    onGetAssetInOutSummary: (req) =>
      dispatch(actions.getAssetInOutSummary(req)),
    onGetAssetInOutSummaryReset: () =>
      dispatch(actions.getAssetInOutSummaryReset()),
    onGetFleetUtilizationSummary: (req) =>
      dispatch(actions.getFleetUtilizationSummary(req)),
    onGetFleetUtilizationSummaryReset: () =>
      dispatch(actions.getFleetUtilizationSummaryReset()),
  };
};
const initialState = {
  searchData: null,
  loading: true,
  interval: "DAILY",
  startDate: localStorage.getItem("timeZoneString")
    ? Moment()
        .tz(JSON.parse(localStorage.getItem("timeZoneString")))
        .add(-3, "days")
    : Moment().add(-3, "days"),
  endDate: localStorage.getItem("timeZoneString")
    ? Moment()
        .tz(JSON.parse(localStorage.getItem("timeZoneString")))
        .add(3, "days")
    : Moment().add(3, "days"),
  assetInOutSummaryResponse: null,
  fleetUtilizationSummaryResponse: null,
  chartData: [],
  breadCrumbList: [],
  isSorted: false
};
const mapStateToProps = (state) => {
  return {
    loading:
      state.assetInOutReducer.loading ||
      state.fleetUtilizationSummaryReducer.loading,
    fleetUtilizationSummaryResponse:
      state.fleetUtilizationSummaryReducer.fleetUtilizationSummaryResponse,
    assetInOutSummaryResponse:
      state.assetInOutReducer.assetInOutSummaryResponse,
    currentOrgUnitId: state.auth.loggedInUserData.CurrentOrgUnitId,
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Dashboard));

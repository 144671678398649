import { isNullOrEmpty } from "../utility";

export const getValueFromList = (datalist, key, defaultVal) => {
  key = isNullOrEmpty(key) ? "" : key;
  if (datalist && datalist.length > 0 && key) {
    const data = datalist.find((element) => element.key === key);
    return data?.value || defaultVal;
  }
  return defaultVal || null;
};
export const getKeyValFromList = (datalist, key, defaultObj, keyProperty) => {
  if (datalist && datalist.length > 0 && key) {
    const data = datalist.find(
      (element) => element[keyProperty || "key"] === key
    );
    return data || defaultObj;
  }
  return defaultObj || null;
};

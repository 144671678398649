import React, { useState } from 'react';
import DateTimeCtrl from '../../../../components/UI/Input/date-time/date-time-ctrl';
import InputCtrl from '../../../../components/UI/Input/input-ctrl';
import { getDOBDateTimeFormat } from '../../../../components/UI/Input/input-ctrl-helper';
import PhoneInputCtrl from '../../../../components/UI/Input/phone/phone-input-ctrl';
import CustomAccordion from '../../../../components/UI/accordion/custom-accordion';
import CountryCtrl from '../../../../components/address/ctrl/country-ctrl';
import PostalCtrl from '../../../../components/address/ctrl/postal-ctrl';
import { getDateTimeFormatString } from '../../../../components/common/common-helper';
import { CALENDAR_03, EMAIL, INTERNET, PROFILE, ZIP_02 } from '../../../../shared/AppImages';
import { AddressType, CountryKeyValueType, PhoneType } from '../../../../shared/utility/enum-utility';
import { getFormattedPhoneNumber } from '../../../../shared/utility/phone-utility';
import { validateCustomerFields } from '../../helper/common-res-helper';

function RequiredInformation(props) {
    const { onChange, onBlur, customerData, getSecurityDepositCharges, clearSecurityDepositRate, onZipToAddressChange, setStateCityLoader, disabled } = props;
    const [isActive, setIsActive] = useState(true);

    // const onAddressChange = (value, name) => {
    //     //console.log(name, value);
    //     const addressData = { ...customerData?.primaryAddress };
    //     addressData[name] = value;
    //     onChange(addressData, "primaryAddress");
    // }
    const onPhoneChange = (data) => {
        onChange(data, "homePhone");
    }
    return (
        <div className='flex-column row-gap-1'>
            <CustomAccordion isActive={isActive} setIsActive={setIsActive} title="Required Infromation" />
            {isActive ?
                <>
                    <InputCtrl
                        name="firstName"
                        value={customerData?.firstName}
                        type="text"
                        placeholder="First Name"
                        onChange={onChange}
                        disabled={disabled || false}
                        imgIco={PROFILE}
                        isRequired={true}
                        validateFunction={validateCustomerFields}
                    />
                    <InputCtrl
                        name="lastName"
                        value={customerData?.lastName}
                        type="text"
                        placeholder="Last Name"
                        onChange={onChange}
                        disabled={disabled || false}
                        imgIco={PROFILE}
                        isRequired={true}
                        validateFunction={validateCustomerFields}
                    />
                    <InputCtrl
                        name="email"
                        value={customerData?.email}
                        type="email"
                        placeholder="Email"
                        onChange={onChange}
                        disabled={disabled || false}
                        imgIco={EMAIL}
                        onBlur={onBlur}
                        isRequired={true}
                        validateFunction={validateCustomerFields}
                    />
                    <PhoneInputCtrl
                        inputProps={{
                            id: "homePhone",
                            name: "homePhone",
                            required: true,
                            value: customerData?.homePhone?.number ? getFormattedPhoneNumber(customerData?.homePhone?.number, customerData?.homePhone?.country.toUpperCase()) : ""
                        }}
                        defaultConfig={{
                            containerClass: "code-with-flag",
                            enableLongNumbers: true,
                            enableSearch: true
                        }}
                        phoneType={PhoneType.HOME}
                        phoneData={customerData?.homePhone}
                        placeholder="Phone"
                        onChange={onPhoneChange}
                        isRequired={true}
                        validateFunction={validateCustomerFields}
                    />
                    <CountryCtrl
                        name="country"
                        placeholder="Country"
                        imgIco={INTERNET}
                        disabled={disabled || false}
                        keyType={CountryKeyValueType.CountryCode}
                        value={customerData?.primaryAddress?.country || (customerData?.email ? "" : "")}
                        addressData={customerData?.primaryAddress}
                        addressType={AddressType.PRIMARY_ADDRESS}
                        clearSecurityDepositRate={clearSecurityDepositRate}
                        onChange={onChange}
                        isRequired={true}
                        validateFunction={validateCustomerFields}
                    />
                    <PostalCtrl
                        name="postalCode"
                        inputName="postalCodeText"
                        placeholder="Zip/Postal Code"
                        imgIco={ZIP_02}
                        disabled={disabled || false}
                        value={customerData?.primaryAddress?.postalCode}
                        countryCode={customerData?.primaryAddress?.country || "US"}
                        stateCode={customerData?.primaryAddress?.province || ""}
                        cityCode={customerData?.primaryAddress?.city || ""}
                        addressData={customerData?.primaryAddress}
                        addressType={AddressType.PRIMARY_ADDRESS}
                        onChange={onChange}
                        onZipToAddressChange={onZipToAddressChange}
                        getSecurityDepositCharges={getSecurityDepositCharges}
                        clearSecurityDepositRate={clearSecurityDepositRate}
                        isRequired={true}
                        validateFunction={validateCustomerFields}
                        setStateCityLoader={setStateCityLoader}
                    />
                    <DateTimeCtrl
                        name="dob"
                        value={customerData?.dob}
                        placeholder={"DOB (" + getDateTimeFormatString() + ")"}
                        onChange={onChange}
                        disabled=""
                        externalData=""
                        imgIco={CALENDAR_03}
                        dateFormat={getDOBDateTimeFormat()}
                    />
                </> : ""}
        </div>
    )
}

export default RequiredInformation
import React from "react";
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,buildStyles
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const CircularStatusBar = (props) => {
  const value = props.value;
  return (
    <div className={props.container} style={{ width: props.size, height: props.size }}>
      <CircularProgressbarWithChildren value={value} maxValue={120} circleRatio={0.75} styles={buildStyles({
              rotation: 1 / 2 + 1 / 8,
              strokeLinecap: "butt",
              trailColor: props.trailColor,
              pathColor: props.pathColor,
            })}>
        <div className="vertical-align-center flex-direction-column">
          <span className="font-weight-bold fs-5">{value.toFixed(2)}</span>
          <span>{props.unit}</span>
        </div>
      </CircularProgressbarWithChildren>
      {props.footerText?
      <div className={`text-center font-weight-bold pt-2`}>
            {props.footerText}
          </div>:""}
    </div>
  );
};
export default CircularStatusBar;

import moment from "moment";
import React, { useEffect } from "react";
import { DropdownList } from "react-widgets";
import { getDateTimeByTimeZone } from "../../shared/utility/date-time-utility";
import { AdminDateFromat, ReserveSyncDefaultVal } from "../../shared/utility/enum-utility";
import "./rocks-drop-down.scss";
export const getPeriodicList = (perPageCount, totalDays, timeZone) => {
    const getPeriodEndDate = (startDate, steps) => {
        return timeZone ? moment(startDate).tz(timeZone).add(steps || defaultPerPageCount, "days") : moment(startDate).tz(moment.tz.guess()).add(steps || defaultPerPageCount, "days");
    }
    const defaultPerPageCount = perPageCount ? perPageCount - 1 : ReserveSyncDefaultVal.totalDaysPerPeriod - 1;
    const defaultTotalSteps = ((totalDays || ReserveSyncDefaultVal.totalDays) % (perPageCount || ReserveSyncDefaultVal.totalDaysPerPeriod)) + 1;
    const currentStartDate = getDateTimeByTimeZone(timeZone, true);
    const lastEndDate = getDateTimeByTimeZone(timeZone, true, ReserveSyncDefaultVal.totalDays - 1);
    let tempStartDate = moment(currentStartDate);
    const options = [];
    for (let i = 0; i < defaultTotalSteps; i++) {
        const pStartDate = moment(tempStartDate);
        let pEndDate = getPeriodEndDate(tempStartDate);
        if (pEndDate.isAfter(lastEndDate)) {
            pEndDate = moment(lastEndDate);
        }
        tempStartDate = getPeriodEndDate(pEndDate, 1);
        const formatedStartDate = moment(pStartDate).format(AdminDateFromat.FOX_API_ONLY_DATE);
        const formatedEndDate = moment(pEndDate).format(AdminDateFromat.FOX_API_ONLY_DATE);
        options.push(
            {
                key: formatedStartDate + "-" + formatedEndDate,
                value: moment(pStartDate).format(AdminDateFromat.RESERVE_SYNC_COLUMN_PERIOD_DATE_FORMAT) + " - " + moment(pEndDate).format(AdminDateFromat.RESERVE_SYNC_COLUMN_PERIOD_DATE_FORMAT),
                forecastStartDate: formatedStartDate,
                forecastEndDate: formatedEndDate,
            }
        );
    }
    return options;
}
export const RocksPeriodicDropDown = (props) => {
    const {
        perPageCount,
        totalDays,
        timeZone,
        imgUrl,
        selectedKey,
        handleOnChange,
        isDisabled
    } = props;

    const currentStartDate = getDateTimeByTimeZone(timeZone, true);
    const currentEndDate = getDateTimeByTimeZone(timeZone, true, ReserveSyncDefaultVal.totalDaysPerPeriod - 1);

    const defaultSelectedValue = moment(currentStartDate).format(AdminDateFromat.FOX_API_ONLY_DATE) + "-" + moment(currentEndDate).format(AdminDateFromat.FOX_API_ONLY_DATE);

    const getCurrentPeriod = () => {
        const dataList = getPeriodicList(perPageCount, totalDays, timeZone);
        const selectedValue = dataList.find((element) => element.key === selectedKey);
        return selectedValue;
    }
    useEffect(() => {
    }, []);

    const onHandleOnChange = (data) => {
        handleOnChange(data, "periodicCtrl");
    }
    return (
        <div className="periodic-ctrl">
            {imgUrl ? <img
                src={imgUrl}
                alt=""
                style={{ height: "15px", width: "15px" }}
            /> : <i className="fa fa-calendar" aria-hidden="true"></i>}
            <DropdownList
                data={getPeriodicList(perPageCount, totalDays, timeZone)}
                valueField="key"
                textField="value"
                value={getCurrentPeriod() || defaultSelectedValue}
                onChange={onHandleOnChange}
                disabled={isDisabled}
            />
        </div>
    )
}
export default RocksPeriodicDropDown;
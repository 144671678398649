import { InputCtrlModel } from "./input-ctrl-model";
import { LblCtrlModel } from "./lbl-ctrl-model";
import { RootContainerCtrlModel } from "./root-container-ctrl-model";

export const LblInputFieldCtrlModel = function (data) {
    if (!data) data = {};
    this.controlType= data.controlType || "SIMPLE";
    if(data.controlType !=="ADDRESS" && data.controlType !=="LICENSE" && data.controlType !=="CREDIT"){
        this.lblModel = data.lblModel ? new LblCtrlModel(data.lblModel) : null;
        this.inputModel= data.inputModel ? new InputCtrlModel(data.inputModel): null;
        this.defaultLblClass = data.defaultLblClass || null;
        this.defaultInputClass = data.defaultInputClass || null;
    }
    this.position=data.position || null;
    if(data.controlType==="ADDRESS" || data.controlType ==="LICENSE" || data.controlType ==="INSURANCE" || data.controlType ==="CREDIT"){
        this.fields= data.fields || [];
    }
};
import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { uploadDocReset, uploadFile } from "../../../store/actions/index";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Draggable from "react-draggable";
import { showNotification } from "../../../components/UI/Toaster/Toaster";
import {
  getBasicToastResponse,
  loadDefaultImage,
} from "../../../shared/utility";
import { noImage } from "../../../shared/AppImages";
import { ImageStorageType, ModalType } from "../../../shared/GlobalVar";
import FileUploader from "../../../components/image-uploader/file-uploader";
import FileUploaderModel from "../../../components/image-uploader/file-uploader-ui-model";
import { getMemberLabelByOrgRentalType } from "../../../shared/utility/system-utility";

export const LicenseUploaderModal = (props) => {
  const uploadFileResponse = useSelector(
    (state) => state.uploadFileReducer.uploadFileResponse
  );
  const fetchingCounter = useSelector(
    (state) => state.uploadFileReducer.fetchingCounter
  );
  const dispatch = useDispatch();
  const defaultData = props.defaultData;
  const [uploaderList, setUploaderList] = useState([]);
  const [uploaderInfoList, setUploaderInfoList] = useState([]);
  useEffect(() => {
    loadUploaderData();
  }, []);
  useEffect(() => {
    if (!uploadFileResponse) {
      return;
    }
    showNotification(getBasicToastResponse(uploadFileResponse));
    dispatch(uploadDocReset());
    if (fetchingCounter === 0) {
      props.resetParentPage();
      props.closeModal();
    }
  }, [uploadFileResponse]);
  const loadUploaderData = () => {
    const uploaderObj = new FileUploaderModel();
    uploaderObj.imgUrl = defaultData.drivingData.DrivingLicenseImage1Url
      ? defaultData.drivingData.DrivingLicenseImage1Url
      : noImage;
    uploaderObj.fileName = "Front";
    uploaderObj.parentDivClass = "col-sm-6 pl-0";
    uploaderObj.photoCloseStyle = {
      // right: "2.5rem",
      fontSize: "2.5rem",
    };
    uploaderObj.imageStyle = {
      cursor: "pointer",
      border: "1px solid #ddd",
      width: "15.5rem",
      height: "11.5rem",
      borderRadius: ".4rem",
      padding: ".5rem",
    };
    uploaderObj.uploadDivStyle = {
      cursor: "pointer",
      top: "1.7rem",
      bottom: "0",
      width: "15.5rem",
      height: "11.5rem",
    };
    const uploaderObjList = [];
    uploaderObjList.push(uploaderObj);
    const uploaderObj2 = { ...uploaderObj };
    uploaderObj2.imgUrl = defaultData.drivingData.DrivingLicenseImage2Url
      ? defaultData.drivingData.DrivingLicenseImage2Url
      : noImage;
    uploaderObj2.parentDivClass = "col-sm-6  pr-0";
    uploaderObj2.fileName = "Back";
    uploaderObj2.uploadDivStyle = {
      cursor: "pointer",
      top: "1.7rem",
      bottom: "0",
      width: "15.5rem",
      height: "11.5rem",
      left: "0",
    };
    uploaderObj2.photoCloseStyle = {
      // right: "-0.5rem",
      fontSize: "2.5rem",
    };
    uploaderObjList.push(uploaderObj2);
    setUploaderInfoList(uploaderObjList);
  };
  const uploadLicenseImage = (index, base64Data, extension) => {
    let uploaderDataList = [...uploaderList];
    if (base64Data) {
      const uploadFileReq = {};
      uploadFileReq.MemberId = defaultData.memberId;
      uploadFileReq.ImageIndex = index + 1;
      uploadFileReq.ImageCategory = ImageStorageType.LICENSE_IMG;
      uploadFileReq.ImageExt = extension;
      uploadFileReq.ImageData = base64Data;
      if (uploaderDataList[index]) {
        uploaderDataList[index] = { ...uploadFileReq };
      } else {
        uploaderDataList.push(uploadFileReq);
      }
    } else {
      uploaderDataList.splice(index, 1);
    }
    setUploaderList(uploaderDataList);
  };
  const uploadLicensePhotos = () => {
    uploaderList.map((uploadDocInfo, index) => {
      dispatch(uploadDocReset());
      dispatch(uploadFile(uploadDocInfo));
    });
  };

  return (
    <Draggable handle=".cursor">
      <Modal
        isOpen={props.showModal}
        className="no-cursor"
        toggle={() => {
          props.closeModal();
        }}
        style={{ minWidth: "40rem" }}
      >
        <ModalHeader
          className="modal-header bg-primary modal-title text-white cursor"
          toggle={() => {
            props.closeModal();
          }}
        >
          <i className="fas fa-paperclip" /> {defaultData.modalTitle}
        </ModalHeader>
        <ModalBody>
          <div className="row mt-2">
            <div className="col-lg-12 row">
              {props.modalType === ModalType.MEMBER_LICENCE_VIEW_MODAL ? (
                <Fragment>
                  <div className="col-sm-6">
                    <div className="user-fullimage">
                      <h6 className="col-sm-12  p-0">Front</h6>
                      <img
                        src={
                          defaultData.drivingData.DrivingLicenseImage1Url
                            ? defaultData.drivingData.DrivingLicenseImage1Url
                            : noImage
                        }
                        alt={getMemberLabelByOrgRentalType()}
                        className="card-img-top"
                        style={
                          uploaderInfoList.length > 1
                            ? uploaderInfoList[0].imageStyle
                            : null
                        }
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="user-fullimage">
                      <h6 className="col-sm-12 p-0">Back</h6>
                      <img
                        src={
                          defaultData.drivingData.DrivingLicenseImage2Url
                            ? defaultData.drivingData.DrivingLicenseImage2Url
                            : noImage
                        }
                        onError={(event) => loadDefaultImage(event, noImage)}
                        alt={getMemberLabelByOrgRentalType()}
                        className="card-img-top"
                        style={
                          uploaderInfoList.length > 1
                            ? uploaderInfoList[1].imageStyle
                            : null
                        }
                      />
                    </div>
                  </div>
                </Fragment>
              ) : (
                <FileUploader
                  uploaderType="License-Photo"
                  uploaderData={uploaderInfoList}
                  isNameRequird={true}
                  isOnlyImg={true}
                  changeFileTitle="Change Image"
                  loadUploaderFileData={uploadLicenseImage}
                />
              )}
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="col-lg-12 text-right">
            {props.modalType === ModalType.MEMBER_LICENCE_UPLOADER_MODAL ? (
              <button
                type="button"
                name="btnBeginRental"
                className="btn btn-primary waves-effect waves-light btnSize ml-2"
                onClick={() => uploadLicensePhotos()}
              >
                Upload
              </button>
            ) : (
              ""
            )}
            <button
              type="button"
              name="btnCancel"
              className="btn btn-secondary  waves-effect waves-light btnSize ml-2"
              onClick={() => props.closeModal()}
            >
              Cancel
            </button>
          </div>
        </ModalFooter>
      </Modal>
    </Draggable>
  );
};

export default LicenseUploaderModal;

import * as actionTypes from "./actionTypes";
import { invokeServerAPI } from "../../services/httpService";

export const getTransactionListStart = () => {
  return {
    type: actionTypes.GET_TRANSACTIONLIST_START,
  };
};

export const getTransactionListSuccess = (response) => {
  return {
    type: actionTypes.GET_TRANSACTIONLIST_SUCCESS,
    transactionResponse: response,
  };
};

export const getTransactionListFail = (error) => {
  return {
    type: actionTypes.GET_TRANSACTIONLIST_FAIL,
    error: error,
  };
};
export const getTransactionListReset = () => {
  return {
    type: actionTypes.GET_TRANSACTIONLIST_RESET,
  };
};

export const doCaptureReleaseRefundStart = () => {
  return {
    type: actionTypes.DO_CAPTURE_REFUND_RELEASE_START,
  };
};

export const doCaptureReleaseRefundSuccess = (response) => {
  return {
    type: actionTypes.DO_CAPTURE_REFUND_RELEASE_SUCCESS,
    captureReleaseRefundResponse: response,
  };
};

export const doCaptureReleaseRefundFail = (error) => {
  return {
    type: actionTypes.DO_CAPTURE_REFUND_RELEASE_FAIL,
    error: error,
  };
};
export const doCaptureReleaseRefundReset = () => {
  return {
    type: actionTypes.DO_CAPTURE_REFUND_RELEASE_RESET,
  };
};

export const fetchCreditCardNumberStart = () => {
  return {
    type: actionTypes.FETCH_CREDITCARDNUMBER_START,
  };
};

export const fetchCreditCardNumberSuccess = (response) => {
  return {
    type: actionTypes.FETCH_CREDITCARDNUMBER_SUCCESS,
    creditCardNumberResponse: response,
  };
};

export const fetchCreditCardNumberFail = (error) => {
  return {
    type: actionTypes.FETCH_CREDITCARDNUMBER_FAIL,
    error: error,
  };
};
export const fetchCreditCardNumberReset = () => {
  return {
    type: actionTypes.FETCH_CREDITCARDNUMBER_RESET,
  };
};
export const getTransactionList = (req) => {
  return (dispatch) => {
    dispatch(getTransactionListStart());
    invokeServerAPI("searchtransaction", req)
      .then((response) => {
        dispatch(getTransactionListSuccess(response));
      })
      .catch((err) => {
        //dispatch(getTransactionListFail(err));
      });
  };
};
export const doCaptureReleaseRefund = (req) => {
  return (dispatch) => {
    dispatch(doCaptureReleaseRefundStart());
    invokeServerAPI("managePaymentService", req)
      .then((response) => {
        dispatch(doCaptureReleaseRefundSuccess(response));
      })
      .catch((err) => {
        //dispatch(doCaptureReleaseRefundFail(err));
      });
  };
};
export const fetchCreditCardNumber = (req) => {
  return (dispatch) => {
    dispatch(fetchCreditCardNumberStart());
    invokeServerAPI("fetchccnum", req)
      .then((response) => {
        dispatch(fetchCreditCardNumberSuccess(response));
      })
      .catch((err) => {
        //dispatch(fetchCreditCardNumberFail(err));
      });
  };
};

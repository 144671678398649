import * as actionTypes from "./actionTypes";
import HttpService, { invokeServerAPI } from "../../services/httpService";
import { FoxRequestHeader } from "../../services/entities/fox-api/domain/fox-request-header";

/** Resend Payment Link for Paynow Reservation*/
export const resendPaymentLinkForPaynowStart = () => {
    return {
      type: actionTypes.RESEND_CREDIT_GRANT_EMAIL_START,
    };
  };

export const resendPaymentLinkForPaynowSuccess = (response) => {
    return {
        type: actionTypes.RESEND_PAYMENT_LINK_FOX_API_SUCCESS,
        resendPaymentLinkResponse: response,
    };
};
export const resendPaymentLinkForPaynowReset = () => {
    return {
        type: actionTypes.RESEND_PAYMENT_LINK_FOX_API_RESET,
    };
};
export const resendPaymentLinkForPaynowFail = (error) => {
    return {
      type: actionTypes.RESEND_PAYMENT_LINK_FOX_API_FAIL,
      error: error,
    };
  };

export const resendPaymentLinkForPaynow = (req) => {
    return (dispatch) => {
      req.requestHeader= new FoxRequestHeader({requestType:"POST"});
      dispatch(resendPaymentLinkForPaynowStart());
      invokeServerAPI("/admin/resendcustomerpaymentmail", req, true)
        .then((response) => {
          dispatch(resendPaymentLinkForPaynowSuccess(response));
        })
        .catch((err) => {
          //dispatch(getFleetClassGroupFail(err));
        });
    };
  };
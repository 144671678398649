import React from 'react';
import FoxReservationDefaultPage from '../../../../components/common/pages/fox-res-default-page';
import { PUZZLE } from '../../../../shared/AppImages';
import { ReservationMsg } from '../../../../shared/GlobalVar';
import CommonTitle from '../../component/common-title';
import AddonsMainContainer from './addons-main-container';
import "./addons.scss";
function AddonsContainer(props) {
    const { allAddons, getRatesWithAddons, selectedAddons, addonsDefaultMessage, setDialogData, onChangesMade } = props;
    const onAddonClick = (addonData, selectedData, isCounter) => {
        const addOns = [];
        selectedAddons.map((data) => {
            if (selectedData && !isCounter && data.code === addonData.code) return false;
            return addOns.push({
                code: data.code,
                quantity: isCounter && data.code === addonData.code ? addonData?.quantity : data.quantity,
                maxQuantity: data.maxQuantity,
            });
        });
        if (!selectedData)
            addOns.push({
                code: addonData.code,
                quantity: addonData.quantity,
                maxQuantity: addonData.maxQuantity,
            });
        getRatesWithAddons(addOns);
        onChangesMade(true);
    }
    const getSelectedQuantity = () => {
        const addonsQuantity = {
            equipQuantity: 0,
            totalAddons: 0
        };
        selectedAddons.map((data) => {
            if (data?.chargeType === "EQUIPMENT") {
                addonsQuantity.equipQuantity = addonsQuantity.equipQuantity + data?.quantity || 0;
            }
            addonsQuantity.totalAddons = addonsQuantity.totalAddons + data?.quantity || 0;
            return addonsQuantity;
        });
        return addonsQuantity;
    }
    return (
        <div className="res-container-column">
            <CommonTitle title="Add-Ons" classNames="font-size-2 font-weight-600" />
            <div className="res-main-container overflow-auto">
                {allAddons?.length > 0 ? allAddons.map((addonData) => (
                    <AddonsMainContainer
                        key={addonData?.code}
                        selectedAddons={selectedAddons}
                        addonData={addonData}
                        addonsQuantity={getSelectedQuantity()}
                        onAddonClick={onAddonClick}
                        setDialogData={setDialogData}
                    />
                )) :
                    <FoxReservationDefaultPage
                        messageType={addonsDefaultMessage?.toastType}
                        iconContent={PUZZLE}
                        title="No Add-Ons Found"
                        msg={ReservationMsg.resPageAddonsDefaultMessage || addonsDefaultMessage}
                    />
                }
            </div>
        </div>
    )
}

export default AddonsContainer
import PageInfo from "../../services/entities/models/PageInfo";
import { getMemberLabelByOrgRentalType } from "../../shared/utility/system-utility";
import { getCurrentServerTimezone } from "../../shared/utility";
import { getPriorities } from "../vehicle/vehicle-helper";
export const getSearchActivityCtrls = () => {
  return {
    isAdvancedSearch: false,
    PageInfo: new PageInfo(null, null, "CreatedOn", "DESC"),
    SearchStartDate: {
      id: "SearchStartDate",
      elementType: "datepicker",
      elementConfig: {
        name: "SearchStartDate",
        type: "text",
      },
      validation: {
        required: false,
      },
      labelInput: {
        labeltext: "From",
      },
      timeZoneString: getCurrentServerTimezone(),
      value: "",
      valid: false,
      touched: false,
      basicOrder: 1,
      advancedOrder: 1,
    },
    SearchEndDate: {
      id: "SearchEndDate",
      elementType: "datepicker",
      elementConfig: {
        name: "SearchEndDate",
        type: "text",
      },
      labelInput: {
        labeltext: "To",
      },
      timeZoneString: getCurrentServerTimezone(),
      value: "",
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      basicOrder: 6,
      advancedOrder: 6,
    },

    ChannelType: {
      id: "ChannelType",
      elementType: "select",
      elementConfig: {
        name: "ChannelType",
        options: [
          { key: "", displayValue: "Select" },
          { key: "WEB", displayValue: "Web" },
          { key: "MOBILE", displayValue: "Mobile" },
          { key: "CALL_CENTER", displayValue: "Phone" },
          { key: "IN_PERSON", displayValue: "In Person" },
        ],
      },
      labelInput: {
        labeltext: "Call Channel",
      },
      value: "",
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      basicOrder: 2,
      advancedOrder: 2,
    },

    Category: {
      id: "Category",
      elementType: "select",
      elementConfig: {
        name: "Category",
        options: [
          { key: "", displayValue: "Select" },
          { key: "PROFILE", displayValue: getMemberLabelByOrgRentalType() },
          { key: "ASSET", displayValue: "Vehicle" },
          { key: "RESERVATION", displayValue: "Reservation" },
          { key: "RENTAL", displayValue: "Rental" },
          { key: "BILLING", displayValue: "Billing" },
          { key: "OTHER", displayValue: "Other" },
        ],
      },
      labelInput: {
        labeltext: "Category",
      },
      value: "",
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      basicOrder: 3,
      advancedOrder: 3,
    },
    ProblemType: {
      id: "ProblemType",
      elementType: "select",
      elementConfig: {
        name: "ProblemType",
        options: [{ key: "", displayValue: "Select Type" }],
      },
      labelInput: {
        labeltext: "Adjust Type",
      },
      value: "",
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      basicOrder: 4,
      advancedOrder: 4,
    },
    CallCategory: {
      id: "CallCategory",
      elementType: "select",
      elementConfig: {
        name: "CallCategory",
        options: [
          { key: "", displayValue: "Select" },
          { key: "INBOUND", displayValue: "Inbound" },
          { key: "OUTBOUND", displayValue: "Outbound" },
        ],
      },
      labelInput: {
        labeltext: "Call Type",
      },
      value: "",
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      basicOrder: 7,
      advancedOrder: 7,
    },
    Priority: {
      id: "Priority",
      elementType: "select",
      elementConfig: {
        name: "Priority",
        options: getPriorities(),
      },
      labelInput: {
        labeltext: "Priority",
      },
      value: "",
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      basicOrder: 8,
      advancedOrder: 8,
    },
    SubProblemType: {
      id: "SubProblemType",
      elementType: "select",
      elementConfig: {
        name: "SubProblemType",
        options: [{ key: "", displayValue: "Select Subtype" }],
      },
      labelInput: {
        labeltext: "Adjust Subtype",
      },
      value: "",
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      basicOrder: 9,
      advancedOrder: 9,
    },

    ReservationCodes: {
      id: "ReservationCode",
      elementType: "input",
      elementConfig: {
        name: "ReservationCode",
        type: "text",
        placeholder: "Enter one or more...",
      },
      labelInput: {
        labeltext: "Res. Code",
      },
      value: "",
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      basicOrder: 0,
      advancedOrder: 11,
    },
    Keyword: {
      id: "Keyword",
      elementType: "input",
      elementConfig: {
        name: "Keyword",
        type: "text",
      },
      labelInput: {
        labeltext: "Keyword",
      },
      value: "",
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      basicOrder: 0,
      advancedOrder: 12,
    },
    btnResSearch: {
      id: "btnResSearch",
      elementType: "button",
      elementConfig: {
        type: "submit",
        value: "Search",
        name: "btnResSearch",
        className: "btn btn-primary search-btn",
      },
      basicOrder: 5,
      advancedOrder: 5,
    },
    btnResSearchReset: {
      id: "btnResSearchReset",
      elementType: "button",
      elementConfig: {
        type: "button",
        value: "Reset",
        name: "btnResSearchReset",
        className: "btn btn-secondary search-btn",
      },
      basicOrder: 10,
      advancedOrder: 10,
    },
    empty: {
      id: "empty",
      elementType: "empty",
      elementConfig: {
        name: "",
        type: "empty",
      },
      basicOrder: 0,
      advancedOrder: 15,
    },
  };
};
export const addActivityEnum = {
  channelTypeList: [
    { key: "", displayValue: "Select" },
    { key: "WEB", displayValue: "Web" },
    { key: "MOBILE", displayValue: "Mobile" },
    { key: "CALL_CENTER", displayValue: "Phone" },
    { key: "IN_PERSON", displayValue: "In Person" },
  ],
  categories: [
    { key: "", displayValue: "Select" },
    { key: "PROFILE", displayValue: getMemberLabelByOrgRentalType() },
    { key: "ASSET", displayValue: "Vehicle" },
    { key: "RESERVATION", displayValue: "Reservation" },
    { key: "RENTAL", displayValue: "Rental" },
    { key: "BILLING", displayValue: "Billing" },
    { key: "OTHER", displayValue: "Other" },
  ],
  callCategories: [
    { key: "", displayValue: "Select" },
    { key: "INBOUND", displayValue: "Inbound" },
    { key: "OUTBOUND", displayValue: "Outbound" },
  ],
  priorities: getPriorities(),
};

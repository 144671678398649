import React, { useState } from 'react';
import CustomAccordion from '../../../../components/UI/accordion/custom-accordion';
import PhoneInputCtrl from '../../../../components/UI/Input/phone/phone-input-ctrl';
import { PhoneType } from '../../../../shared/utility/enum-utility';
import { getFormattedPhoneNumber } from '../../../../shared/utility/phone-utility';

function AdditionalPhoneInformation(props) {
    const { onChange, phoneData, disabled } = props;
    const [isActive, setIsActive] = useState(false);
    const onPhoneChange = (data) => {
        onChange(data, "mobilePhone");
    }
    return (
        <div className='flex-column row-gap-1'>
            <CustomAccordion title="Phone" isActive={isActive} setIsActive={setIsActive} />
            {isActive ?
                <PhoneInputCtrl
                    inputProps={{
                        id: "mobilePhone",
                        name: "mobilePhone",
                        required: false,
                        value: phoneData?.number ? getFormattedPhoneNumber(phoneData?.number, phoneData?.country) : ""

                    }}
                    defaultConfig={{
                        containerClass: "code-with-flag",
                        enableLongNumbers: true,
                        enableSearch: true
                    }}
                    phoneType={PhoneType.MOBILE}
                    phoneData={phoneData}
                    placeholder="Alternate Phone"
                    onChange={onPhoneChange}
                />
                : ""}
        </div>
    )
}

export default AdditionalPhoneInformation
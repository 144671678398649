import React, { useState } from "react";
import ReactHtmlParser from "react-html-parser";
import ModalEngine from "../../../components/modal/modal-engine";
import LabelWithTooltipOnOverflowText from "../../../components/tooltip/conditional-title-tooltip";
import ModalInfo from "../../../services/entities/models/ModalInfo";
import { ModalType } from "../../../shared/GlobalVar";
import PermissionName from "../../../shared/Permissions";
import {
  convertArrayDataToTitleString,
  convertDateTimeString,
  convertDateTimeStringWithTimezone,
  convertSecondToDayHourMinSec,
  getDateOnlyString,
  getNoteTooltipHtml,
  getSortingClassName,
  getSortType,
  hasPermission,
  isNullOrEmpty,
  toTitleCase
} from "../../../shared/utility";
export const SearchMechanicGrid = (props) => {
  const [modalInfo, setModalInfo] = useState([new ModalInfo()]);
  const hasReportViewPermission = hasPermission(PermissionName.REPORT_SEARCH);
  const hasViewMemberPermission = hasPermission(PermissionName.VIEW_MEMBER);
  const hasViewVehiclePermission = hasPermission(PermissionName.VIEW_VEHICLE);
  const closeModal = () => {
    setModalInfo(new ModalInfo());
  };
  const showModal = (modalData) => {
    setModalInfo({
      type: modalData.type,
      showModal: true,
      trackerDetails: modalData.trackerDetails,
      memberId: modalData.memberId,
      vehicleId: modalData.vehicleId,
    });
  };

  // const ConditionalLabel = ({ id, content, title, classNames, onClickAction }) => (
  //   <span className="row__column">
  //     <LabelWithTooltipOnOverflowText
  //       id={id}
  //       content={content || "--"}
  //       title={title || content || "--"}
  //       classNames={classNames}
  //       onClickAction={onClickAction}
  //     />
  //   </span>
  // );

  const getTableRows = props.dataList.map((mechanicData) => {
    let trackerId = mechanicData.TrackerId;
    return (
      <div className="row" key={trackerId}>
        <span className="row__column  cb-container">
          <ul className="icon">
            <li>
              <div
                id={mechanicData.Status + "_" + trackerId}
                className={`colorBox ${mechanicData.Status}`}
                style={{
                  height: "14px",
                  width: "14px",
                }}
                title={toTitleCase(mechanicData.Status)}
              />
            </li>
          </ul>
        </span>
        <span className="row__column">
          <LabelWithTooltipOnOverflowText
            id={"createdDate_" + trackerId}
            content={mechanicData.CreatedOn
              ? getDateOnlyString(mechanicData.CreatedOn)
              : "--"}
            title={convertDateTimeStringWithTimezone(
              mechanicData.CreatedOn,
              mechanicData.TimezoneString
            )}
            forceShow={true}
          />
        </span>

        <span className="row__column">
          <LabelWithTooltipOnOverflowText
            id={"processingTimeId_" + trackerId}
            content={mechanicData?.ProcessingTime
              ? convertSecondToDayHourMinSec(mechanicData.ProcessingTime)
              : "--"}
            title={ReactHtmlParser(
              getNoteTooltipHtml(
                mechanicData.ProcessingTime
                  ? convertSecondToDayHourMinSec(mechanicData.ProcessingTime)
                  : ""
              )
            )}
          />
        </span>
        <span className="row__column" id={"trackerId" + trackerId}>
          <LabelWithTooltipOnOverflowText
            id={"trackerId_" + trackerId}
            content={trackerId || "--"}
            title={trackerId}
            classNames={hasReportViewPermission ? "pseudo-link" : ""}
            onClickAction={(event) =>
              hasReportViewPermission
                ? showModal({
                  type: ModalType.TRACKER_QUICK_MODAL,
                  showModal: true,
                  trackerDetails: mechanicData,
                })
                : ""
            }
          />
        </span>
        <span className="row__column" id={"raNumber_" + trackerId}>
          <LabelWithTooltipOnOverflowText
            id={"RANum_" + trackerId}
            content={mechanicData?.RANum || "--"}
            title={mechanicData.RANum}
          />
        </span>
        <span className="row__column">
          <LabelWithTooltipOnOverflowText
            id={"agentId_" + trackerId}
            content={mechanicData.AgentName || "--"}
            title={mechanicData.AgentName}
            classNames={hasViewMemberPermission ? "pseudo-link" : ""}
            onClickAction={(event) =>
              hasViewMemberPermission
                ? showModal({
                  type: ModalType.MEMBER_QUICK_MODAL,
                  showModal: true,
                  memberId: mechanicData.AgentId,
                })
                : ""
            }
          />
        </span>
        <span className="row__column" id={"status_" + trackerId}>
          <LabelWithTooltipOnOverflowText
            id={"status_" + trackerId}
            content={mechanicData?.Status || "--"}
            title={mechanicData.Status}
          />
        </span>
        <span className="row__column">
          <LabelWithTooltipOnOverflowText
            id={"vehicleId_" + trackerId}
            content={mechanicData.VehicleId || "--"}
            title={mechanicData.VehicleId}
            classNames={hasViewVehiclePermission && mechanicData.VehicleId ? "pseudo-link" : ""}
            onClickAction={(event) =>
              hasViewVehiclePermission && mechanicData.VehicleId
                ? showModal({
                  type: ModalType.VEHICLE_QUICK_MODAL,
                  showModal: true,
                  vehicleId: mechanicData.VehicleId,
                })
                : ""
            }
          />
        </span>
        <span className="row__column text-center">
          <label>
            {!isNullOrEmpty(mechanicData.IndividuallyPerformed)
              ? mechanicData.IndividuallyPerformed
                ? "Yes"
                : "No"
              : "--"}
          </label>
        </span>
        <span className="row__column">
          <LabelWithTooltipOnOverflowText
            id={"complaintsId_" + trackerId}
            content={mechanicData?.Complaints?.length ? convertArrayDataToTitleString(mechanicData.Complaints) : "--"}
            title={convertArrayDataToTitleString(mechanicData.Complaints)}
          />
        </span>
        <span className="row__column">
          <LabelWithTooltipOnOverflowText
            id={"causesId_" + trackerId}
            content={mechanicData?.Causes?.length ? convertArrayDataToTitleString(mechanicData.Causes) : "--"}
            title={convertArrayDataToTitleString(mechanicData.Causes)}
          />
        </span>
        <span className="row__column">
          <LabelWithTooltipOnOverflowText
            id={"correctionsId_" + trackerId}
            content={mechanicData?.Corrections?.length ? convertArrayDataToTitleString(mechanicData.Corrections) : "--"}
            title={convertArrayDataToTitleString(mechanicData.Corrections)}
          />
        </span>
        <span className="row__column">
          <LabelWithTooltipOnOverflowText
            id={"tiresAndPartsId_" + trackerId}
            content={mechanicData?.TiresAndParts?.length ? convertArrayDataToTitleString(mechanicData.TiresAndParts) : "--"}
            title={convertArrayDataToTitleString(mechanicData.TiresAndParts)}
          />
        </span>
        <span className="row__column">
          <LabelWithTooltipOnOverflowText
            id={"startTimeId_" + trackerId}
            content={mechanicData.StartTime
              ? convertDateTimeString(mechanicData.StartTime)
              : "--"}
            title={convertDateTimeStringWithTimezone(
              mechanicData.StartTime,
              mechanicData.TimezoneString
            )}
            forceShow={true}
          />
        </span>
        <span className="row__column">
          <LabelWithTooltipOnOverflowText
            id={"endTimeId_" + trackerId}
            content={mechanicData.EndTime
              ? convertDateTimeString(mechanicData.EndTime)
              : "--"}
            title={convertDateTimeStringWithTimezone(
              mechanicData.EndTime,
              mechanicData.TimezoneString
            )}
            forceShow={true}
          />
        </span>
        <span className="row__column">
          <LabelWithTooltipOnOverflowText
            id={"employeeLocationId_" + trackerId}
            content={mechanicData.EmployeeLocationName || "--"}
            title={mechanicData.EmployeeLocationName || "--"}
          />
        </span>
        <span className="row__column">
          <LabelWithTooltipOnOverflowText
            id={"assignedByName_" + trackerId}
            content={mechanicData.AssignedByName || "--"}
            title={mechanicData.AssignedByName || "--"}
          />
        </span>
        <span className="row__column">
          <LabelWithTooltipOnOverflowText
            id={"assignedToName_" + trackerId}
            content={mechanicData.AssignedToName || "--"}
            title={mechanicData.AssignedToName || "--"}
          />
        </span>
      </div>
    );
  });
  return (
    <div className="datagrid">
      <div className="column">
        <span className="column__header" />
        <span
          className={getSortingClassName(props.pageInfo, "CreatedOn")}
          onClick={(event) =>
            props.doSorting(
              getSortType(props.pageInfo, "CreatedOn"),
              "CreatedOn"
            )
          }
        >
          Date Created
        </span>
        <span
          className={getSortingClassName(props.pageInfo, "ProcessingTime")}
          onClick={(event) =>
            props.doSorting(
              getSortType(props.pageInfo, "ProcessingTime"),
              "ProcessingTime"
            )
          }
        >
          Processing Time
        </span>
        <span
          className={getSortingClassName(props.pageInfo, "TrackerId")}
          onClick={(event) =>
            props.doSorting(
              getSortType(props.pageInfo, "TrackerId"),
              "TrackerId"
            )
          }
        >
          Tracker ID
        </span>
        <span
          className={getSortingClassName(props.pageInfo, "RANum")}
          onClick={(event) =>
            props.doSorting(getSortType(props.pageInfo, "RANum"), "RANum")
          }
        >
          R.A
        </span>
        <span
          className={getSortingClassName(props.pageInfo, "AgentName")}
          onClick={(event) =>
            props.doSorting(
              getSortType(props.pageInfo, "AgentName"),
              "AgentName"
            )
          }
        >
          Mechanic
        </span>
        <span
          className={getSortingClassName(props.pageInfo, "Status")}
          onClick={(event) =>
            props.doSorting(getSortType(props.pageInfo, "Status"), "Status")
          }
        >
          Status
        </span>
        <span
          className={getSortingClassName(props.pageInfo, "VehicleId")}
          onClick={(event) =>
            props.doSorting(
              getSortType(props.pageInfo, "VehicleId"),
              "VehicleId"
            )
          }
        >
          Vehicle ID
        </span>
        <span
          className={getSortingClassName(
            props.pageInfo,
            "IndividuallyPerformed"
          )}
          onClick={(event) =>
            props.doSorting(
              getSortType(props.pageInfo, "IndividuallyPerformed"),
              "IndividuallyPerformed"
            )
          }
        >
          Individually Performed
        </span>
        <span className="column__header">Complaints</span>
        <span className="column__header">Causes</span>
        <span className="column__header">Corrections</span>
        <span className="column__header">Tires and Parts</span>
        <span
          className={getSortingClassName(props.pageInfo, "StartTime")}
          onClick={(event) =>
            props.doSorting(
              getSortType(props.pageInfo, "StartTime"),
              "StartTime"
            )
          }
        >
          Start Date/Time
        </span>
        <span
          className={getSortingClassName(props.pageInfo, "EndTime")}
          onClick={(event) =>
            props.doSorting(getSortType(props.pageInfo, "EndTime"), "EndTime")
          }
        >
          End Date/Time
        </span>
        {/* <span
          className={getSortingClassName(props.pageInfo, "ProfileLocationName")}
          onClick={(event) =>
            props.doSorting(
              getSortType(props.pageInfo, "ProfileLocationName"),
              "ProfileLocationName"
            )
          }
        >
          Profile Location
        </span> */}
        <span
          className={getSortingClassName(props.pageInfo, "EmployeeLocationName")}
          onClick={(event) =>
            props.doSorting(
              getSortType(props.pageInfo, "EmployeeLocationName"),
              "EmployeeLocationName"
            )
          }
        >
          Employee Location
        </span>
        <span
          className={getSortingClassName(props.pageInfo, "AssignedToName")}
          onClick={(event) =>
            props.doSorting(
              getSortType(props.pageInfo, "AssignedToName"),
              "AssignedToName"
            )
          }
        >
          Assigned To
        </span>
        <span
          className={getSortingClassName(props.pageInfo, "AssignedByName")}
          onClick={(event) =>
            props.doSorting(
              getSortType(props.pageInfo, "AssignedByName"),
              "AssignedByName"
            )
          }
        >
          Assigned By
        </span>
      </div>
      {getTableRows}
      {modalInfo.showModal ? (
        <ModalEngine
          modalType={modalInfo.type}
          showModal={modalInfo.showModal}
          closeModal={closeModal}
          trackerDetails={modalInfo.trackerDetails}
          memberId={modalInfo.memberId}
          vehicleId={modalInfo.vehicleId}
        />
      ) : null}
    </div>
  );
};
export default SearchMechanicGrid;

import React, { useState } from 'react';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { isPhoneNumber } from "../../../../shared/utility/phone-utility";
function PhoneInputCtrl(props) {
    const {
        inputProps,
        placeholder,
        phoneType,
        phoneData,
        onChange,
        disabled,
        imgIco,
        iconClass,
        defaultConfig,
        value,
        isRequired,
        validateFunction

    } = props;
    const [error, setError] = useState(false);
    const onHandleChange = (number, data) => {
        const pData = {
            type: phoneType,
            country: data.countryCode.toUpperCase(),
            countryCode: data.dialCode,
            number: data?.dialCode === phoneData?.countryCode ? number : phoneData?.number
        }
        if (isRequired && validateFunction) {
            setError(!validateFunction(inputProps?.name, number, true));
        }
        onChange(pData, inputProps?.name);
    }
    return (
        <div className='input-ctrl'>
            {imgIco ? <img
                src={imgIco}
                alt=""
            /> : iconClass ? <i className={iconClass} aria-hidden="true"></i> : ""}
            <label className={`place-holder ${isRequired ? "required" : ""}`}>{placeholder}</label>
            <PhoneInput
                inputProps={inputProps}
                autoFormat={true}
                value={value}
                required={isRequired}
                placeholder=""
                onChange={onHandleChange}
                disabled={disabled}
                inputClass={`${error && !validateFunction(inputProps?.name, phoneData?.number, true) ? "error-field" : ""}`}
                isValid={(value, country) => {
                    if (!value || !country || !phoneData?.number) {
                        return true;
                    }
                    if (!isPhoneNumber(phoneData?.number, country.iso2.toUpperCase())) {
                        return (
                            `Invalid  
                            ${props.caption || ""} 
                         number for 
                            ${country?.name}`
                        );
                    } else {
                        return true; // Valid phone number
                    }
                }}
                country={phoneData?.country ? phoneData?.country.toLowerCase() : 'us'}
                specialLabel={phoneData?.countryCode ? "+" + phoneData?.countryCode : "+1"}
                disableCountryGuess={true}
                {...defaultConfig}
            />
        </div>
    )
}

export default PhoneInputCtrl
import React, { Fragment, Component } from "react";
import Viewer from "react-viewer";
import { connect } from "react-redux";
import * as actions from "../../../store/actions/index";
import { withRouter } from "react-router-dom";
import {
  updateObject,
  getBasicToastResponse,
  getErrorToastData,
  hasPermission,
  populateBreadCrumbData,
} from "../../../shared/utility";
import { VehicleDetailsRequest } from "../../../services/entities/requests/VehicleDetailsRequest";
import FileUploaderModel from "../../../components/image-uploader/file-uploader-ui-model";
import { showNotification } from "../../../components/UI/Toaster/Toaster";
import Spinner from "../../../components/UI/Spinner/Spinner";
import VehicleSummaryCard from "./vehicle-summary-card";
import VehicleDetailsUI from "./vehicle-details-ui";
import AmenitiesUI from "./amenities-ui";
import VehicleImagesUI from "./vehicle-images-ui";
import VehicleReviewUI from "./vehicle-review-ui";
import CommonRequest from "../../../services/entities/requests/CommonRequest";
import TermsConditionBlock from "./terms-condition-block";
import Vehicle from "../../../services/entities/models/Vehicle";
import {
  getDefaultAmenities,
  createAddEditVehicleRequest,
  validateAddEditVehicleReq,
} from "../vehicle-helper";
import * as global from "../../../shared/GlobalVar";
import LinkButton from "../../../components/UI/button/link-btn";
import PermissionName from "../../../shared/Permissions";
import PreventTransitionPrompt from "../../../components/Navigations/prevent-navigation/prevent-transition-prompt";
import { setActiveMenu } from "../../search/search-utility/search-utility";
import Breadcrumbs from "../../../components/breadcrumbs/breadcrumbs";

class VehicleDetails extends Component {
  state = { ...initialState };
  componentDidMount() {
    this.initVehicleDetailsPage();
    this.loadUploaderData();
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.match.url !== prevProps.match.url) {
      this.setState({ ...initialState }, () => {
        this.initVehicleDetailsPage();
      });
    }
    if (
      this.state.vehicleInputRequest.AssetType &&
      this.state.vehicleInputRequest.CategoryId &&
      this.state.vehicleInputRequest.Make &&
      this.state.vehicleInputRequest.Model &&
      this.state.vehicleInputRequest.VehicleYear &&
      this.state.vehicleInputRequest.VehicleYear.length === 4
    ) {
      if (
        this.state.vehicleInputRequest.AssetType !==
          prevState.vehicleInputRequest.AssetType ||
        this.state.vehicleInputRequest.CategoryId !==
          prevState.vehicleInputRequest.CategoryId ||
        this.state.vehicleInputRequest.Make !==
          prevState.vehicleInputRequest.Make ||
        this.state.vehicleInputRequest.Model !==
          prevState.vehicleInputRequest.Model ||
        this.state.vehicleInputRequest.VehicleYear !==
          prevState.vehicleInputRequest.VehicleYear
      ) {
        this.getSuggestedPrice();
      }
    }
  }
  UNSAFE_componentWillReceiveProps({
    updateVehicleResponse,
    vehicleDetailsResponse,
    vehicleGroupFeatureOptionsResponse,
    vehicleFeatureOptionsResponse,
    vehicleSuggestedPriceResponse,
  }) {
    if (updateVehicleResponse && !this.props.updateVehicleResponse) {
      if (updateVehicleResponse.StatusCode === 0) {
        this.props.history.push(
          "/vehicle_details/vehicle_profile?vehicleId=" +
            updateVehicleResponse.PersonAssetId,
          this.getBreadcrumbData()
        );
      } else {
        showNotification(getBasicToastResponse(updateVehicleResponse));
      }
    }
    if (
      (vehicleDetailsResponse &&
        !this.state.vehicleInputRequest.PersonAssetId) ||
      (this.state.vehicleInputRequest.PersonAssetId &&
        vehicleDetailsResponse &&
        !this.props.vehicleDetailsResponse)
    ) {
      if (vehicleDetailsResponse.StatusCode === 0) {
        vehicleDetailsResponse.CategoryId = vehicleDetailsResponse.Category.Key;
        vehicleDetailsResponse.SubVehicleTypeKey = vehicleDetailsResponse.SubVehicleType
          ? vehicleDetailsResponse.SubVehicleType.Key
          : null;
        vehicleDetailsResponse.BufferTimeInMinutes = this.props.bufferTimeInMinutes;
        this.setState(
          {
            vehicleInputRequest: vehicleDetailsResponse,
          },
          () => {
            this.getVehicleGroupFeatureOptions();
            this.getSuggestedPrice();
          }
        );
      } else {
        showNotification(getBasicToastResponse(vehicleDetailsResponse));
      }
    }
    if (
      (vehicleSuggestedPriceResponse &&
        !this.state.vehicleSuggestedPriceResponse) ||
      (this.state.vehicleSuggestedPriceResponse &&
        vehicleSuggestedPriceResponse &&
        !this.props.vehicleSuggestedPriceResponse)
    ) {
      if (vehicleSuggestedPriceResponse.StatusCode === 0) {
        this.setState({
          vehicleSuggestedPriceResponse: vehicleSuggestedPriceResponse,
        });
      } else {
        showNotification(getBasicToastResponse(vehicleSuggestedPriceResponse));
      }
    }
    if (
      (vehicleFeatureOptionsResponse &&
        !this.state.vehicleFeatureOptionsResponse) ||
      (this.state.vehicleFeatureOptionsResponse &&
        vehicleFeatureOptionsResponse &&
        !this.props.vehicleFeatureOptionsResponse)
    ) {
      if (vehicleFeatureOptionsResponse.StatusCode === 0) {
        this.setState({
          vehicleFeatureOptionsResponse: vehicleFeatureOptionsResponse,
        });
      } else {
        showNotification(getBasicToastResponse(vehicleFeatureOptionsResponse));
      }
    }
  }
  initVehicleDetailsPage = () => {
    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.breadCrumbList
    ) {
      let breadCrumbList = this.props.location.state.breadCrumbList || [];
      this.setState({ breadCrumbList: breadCrumbList });
    }

    this.resetVehicleData();
    this.getVehicleFeatureOptions();
    const vehicleId = this.getInfoFromParamQuery("vehicleId");

    if (vehicleId) {
      const breadCrumbList = this.props.location.hasOwnProperty("state")
        ? populateBreadCrumbData(
            this.props.location.state.breadCrumbList,
            "Vehicle",
            "/vehicle/vehicle_profile?vehicleId=" + vehicleId
          )
        : [];
      this.setState({
        isEditMode: this.props.location.state
          ? this.props.location.state.isEditMode
          : false,
        isBlocking: false,
        breadCrumbList: breadCrumbList,
        isReturnFromPopup: false,
        isSubMenu: true,
      });
    } else if (this.props.orgUnitAssetGroups.length) {
      const vehicleInputRequest = { ...this.state.vehicleInputRequest };
      vehicleInputRequest.AssetType = this.props.orgUnitAssetGroups[0].Key;
      vehicleInputRequest.LeadTimeHours = 0;
      vehicleInputRequest.LeadTimeMins = 0;
      vehicleInputRequest.BufferTimeHours = 0;
      vehicleInputRequest.BufferTimeInMins = 0;
      vehicleInputRequest.Amenities = getDefaultAmenities();
      this.setState({ vehicleInputRequest: vehicleInputRequest }, () => {
        this.getVehicleGroupFeatureOptions();
      });
    }
  };
  getInfoFromParamQuery = (paramName) => {
    const query = new URLSearchParams(this.props.location.search);
    return query.get(paramName);
  };
  getBreadcrumbData = () => {
    return this.props.location.hasOwnProperty("state")
      ? populateBreadCrumbData(
          this.props.location.state.breadCrumbList,
          "Vehicle",
          "/vehicle_details/vehicle_profile?vehicleId=" +
            this.getInfoFromParamQuery("vehicleId")
        )
      : [];
  };
  resetVehicleData = () => {
    const vehicleDetailsRequest = new VehicleDetailsRequest();
    vehicleDetailsRequest.MemberAssetId = this.getInfoFromParamQuery(
      "vehicleId"
    );
    this.props.onGetVehicleDetailsReset();
    this.props.onAddOrEditVehicleReset();
    if (vehicleDetailsRequest.MemberAssetId) {
      this.props.onGetVehicleDetails(vehicleDetailsRequest);
    }
  };
  getVehicleFeatureOptions = () => {
    const featureOptionsReq = new CommonRequest();
    featureOptionsReq.VehicleId =
      this.getInfoFromParamQuery("vehicleId") || null;
    this.props.onGetVehicleFeatureOptionsReset();
    this.props.onGetVehicleFeatureOptions(featureOptionsReq);
  };
  getVehicleGroupFeatureOptions = (assetType) => {
    const groupFeatureReq = {};
    groupFeatureReq.AssetGroupType = this.state.vehicleInputRequest.AssetType;
    this.props.onGetVehicleGroupFeatureOptionsReset();
    this.props.onGetVehicleGroupFeatureOptions(groupFeatureReq);
  };
  getSuggestedPrice = (controlName) => {
    const suggestedReq = {};
    suggestedReq.SelectedChildOrgUnitId = this.state.vehicleInputRequest.PersonAssetOrgUnitId;
    suggestedReq.PersonAssetGroupType = this.state.vehicleInputRequest.AssetType;
    suggestedReq.CategoryId = this.state.vehicleInputRequest.CategoryId;
    suggestedReq.PersonAssetMake = this.state.vehicleInputRequest.Make;
    suggestedReq.PersonAssetModel = this.state.vehicleInputRequest.Model;
    suggestedReq.PersonAssetYear = this.state.vehicleInputRequest.VehicleYear;
    this.props.onGetSuggestedPriceforVehicleReset();
    this.props.onGetSuggestedPriceforVehicle(suggestedReq);
  };
  loadUploaderData = () => {
    const uploaderObj = new FileUploaderModel();
    uploaderObj.fileName = "";
    uploaderObj.photoCloseStyle = {
      fontSize: "2.5rem",
    };
    const uploaderList = [];
    uploaderList.push(uploaderObj);
    this.setState({ userPhotoInfo: uploaderList });
  };
  loadNewVehiclePhoto = (index, base64Data, extension) => {
    this.setState({ base64VehiclePhoto: base64Data || null });
  };
  changeVehiclePhoto = (ImageUrl) => {
    let vehicleData = { ...this.state.vehicleInputRequest };
    vehicleData.Images[0] = ImageUrl;
    this.setState({ memberDataResponse: vehicleData });
  };
  resetLoader = (isLoading) => {
    this.setState({ loading: isLoading });
  };
  inputChangedHandler = (event, controlName, elementType) => {
    let value = null;
    if (elementType === "checkbox") {
      value = event.target.checked;
    } else {
      value = event.target.value;
    }
    let vehicleInputRequest = { ...this.state.vehicleInputRequest };
    let updatedControls = updateObject(vehicleInputRequest, {
      [controlName]: ["LeadTimeInHour", "BufferTimeHours"].includes(elementType)
        ? parseInt(value) * 60 + parseInt(vehicleInputRequest[controlName] % 60)
        : ["LeadTimeInMinutes", "BufferTimeInMinutes"].includes(elementType)
        ? Math.floor(vehicleInputRequest[controlName] / 60) * 60 +
          parseInt(value)
        : elementType === "radio"
        ? !vehicleInputRequest[controlName]
        : value,
    });
    this.setState(
      {
        vehicleInputRequest: updatedControls,
        isBlocking: true,
      },
      () => {
        if (
          controlName === "AssetType" ||
          controlName === "CategoryId" ||
          controlName === "Make" ||
          controlName === "Model" ||
          controlName === "VehicleYear"
        ) {
          this.props.onGetSuggestedPriceforVehicleReset();
        }
      }
    );
  };
  inputAddressChangeHandler = (event, controlName, objectType) => {
    let vehicleInputRequest = { ...this.state.vehicleInputRequest };
    if (controlName === "Country") {
      vehicleInputRequest.ParkingAddressCountryId = event.target.value;
      vehicleInputRequest.ParkingAddressStateId = null;
      vehicleInputRequest.ParkingAddressCityId = null;
      vehicleInputRequest.ParkingAddressZipCodeId = null;
      // vehicleInputRequest.StateText = null;
      // vehicleInputRequest.CityText = null;
      // vehicleInputRequest.ZipCodeText = null;
    } else if (controlName === "State") {
      vehicleInputRequest.ParkingAddressStateId = event.target.value;
      vehicleInputRequest.ParkingAddressCityId = null;
      vehicleInputRequest.ParkingAddressZipCodeId = null;
      // vehicleInputRequest.StateText = null;
      // vehicleInputRequest.CityText = null;
      // vehicleInputRequest.ZipCodeText = null;
    } else if (controlName === "City") {
      vehicleInputRequest.ParkingAddressCityId = event.target.value;
      vehicleInputRequest.ParkingAddressZipCodeId = null;
      // vehicleInputRequest.CityText = null;
      // vehicleInputRequest.ZipCodeText = null;
    } else if (controlName === "ZipCode") {
      vehicleInputRequest.ParkingAddressZipCodeId = event.target.value;
    } else if (controlName === "Street1") {
      vehicleInputRequest.ParkingAddressStreet1 = event.target.value;
    } else if (controlName === "Street2") {
      vehicleInputRequest.ParkingAddressStreet2 = event.target.value;
    }
    this.setState({
      vehicleInputRequest: vehicleInputRequest,
      isBlocking: true,
    });
  };
  inputAmenitiesChangedHandler = (event, controlName, elementObj) => {
    let vehicleInputRequest = { ...this.state.vehicleInputRequest };
    let selectedAmenities = this.state.vehicleInputRequest.Amenities
      ? [...this.state.vehicleInputRequest.Amenities]
      : [];
    if (
      controlName === "SmokingAllowed" ||
      controlName === "PetsAllowed" ||
      controlName === "HandicapWheelSignLicense" ||
      controlName === "HandicapWheelChairLoading"
    ) {
      selectedAmenities.map((amenityInfo, index) => {
        if (
          (controlName === "SmokingAllowed" &&
            amenityInfo.Type === "SMOKING") ||
          (controlName === "PetsAllowed" &&
            amenityInfo.Type === "PET_HISTORY") ||
          (controlName === "HandicapWheelSignLicense" &&
            amenityInfo.Value === "HANDICAP_SIGN_LICENSE") ||
          (controlName === "HandicapWheelChairLoading" &&
            amenityInfo.Value === "HANDICAP_WHEELCHAIR_LOADING")
        ) {
          selectedAmenities[index].Active = !selectedAmenities[index].Active;
        }
      });
      vehicleInputRequest.Amenities = [...selectedAmenities];
    } else {
      if (controlName === "Amenities" && event.target.checked) {
        selectedAmenities.push(elementObj);
        vehicleInputRequest.Amenities = [...selectedAmenities];
      } else {
        const result = selectedAmenities.filter(
          (word) => word.Id != elementObj.Id
        );
        vehicleInputRequest.Amenities = [...result];
      }
    }
    this.setState({
      vehicleInputRequest: vehicleInputRequest,
      isBlocking: true,
    });
  };
  openEditPage = () => {
    this.setState({ isEditMode: this.state.isEditMode ? false : true });
  };
  onCancel = () => {
    let pathname = this.props.location.pathname;
    if (pathname === "/newVehicle") {
      this.props.history.push("/Dashboard");
    } else {
      this.openEditPage();
    }
  };
  doAddOrEditVehicle = () => {
    const addEditVehicleReq = createAddEditVehicleRequest(
      this.state.vehicleInputRequest,
      this.props.managerId,
      this.props.reservationRequestBased
    );
    const msg = validateAddEditVehicleReq(addEditVehicleReq);
    if (msg) {
      showNotification(getErrorToastData(msg,{top:'20%'}));
      this.setState({
        isValidateDone: true,
      });
    } else {
      this.setState(
        {
          isValidateDone: false,
        },
        () => {
          this.props.onAddOrEditVehicleReset();
          this.props.onAddOrEditVehicle(
            addEditVehicleReq,
            this.state.isSubMenu
          );
        }
      );
    }
  };
  showImageViewer = () => {
    this.setState({ showImageViewer: true });
  };
  closeImageViewer = () => {
    this.setState({ showImageViewer: false });
  };
  render() {
    setActiveMenu(this.props.match.url);
    const vehicleData = this.state.vehicleInputRequest
      ? { ...this.state.vehicleInputRequest }
      : new Vehicle();
    const imgList = [];
    vehicleData.Images.map((imageUrl) => {
      imgList.push({ src: imageUrl, alt: "" });
    });

    const mainClass = this.state.isSubMenu
      ? this.state.isEditMode
        ? "col-lg-10 background-edit"
        : "col-lg-10"
      : "col-lg-10 background-edit";
    return (
      <Fragment>
        <PreventTransitionPrompt when={this.state.isBlocking} />
        {!this.state.isEditMode &&
        hasPermission(PermissionName.EDIT_VEHICLE) ? (
          <div
            className="formRow text-right"
            style={{
              marginTop: "-2.8rem",
              paddingBottom: "1.1rem",
              paddingRight: "3rem",
              justifyContent: "flex-end",
            }}
          >
            <LinkButton
              btnLabel="Edit Vehicle"
              containerClass="pseudo-link pr-1 fs-2 font-weight-bold"
              stackClass="fa-stack fs-08 vertical-align-top"
              faIconList={[
                "fa fa-circle fa-stack-2x iconBeforeLink",
                "fa fa-pencil-alt fa-stack-1x whiteColor m-0 mtr-01 mlr-01",
              ]}
              clicked={this.openEditPage}
            />
          </div>
        ) : (
          ""
        )}
        {!this.state.isSubMenu && this.props.location.state ? (
          this.props.location.state.breadCrumbList ? (
            <Breadcrumbs
              breadCrumbList={this.state.breadCrumbList}
              title="New Vehicle"
            />
          ) : (
            ""
          )
        ) : (
          <h4 className="form-header text-uppercase newMemberCaption col-lg-12">
            <i className="fas fa-user-circle" />
            New Vehicle
          </h4>
        )}

        <div className="formRow">
          <div className="col-lg-2">
            <VehicleSummaryCard
              vehicleData={vehicleData}
              isEditMode={this.state.isEditMode}
              isSubMenu={this.state.isSubMenu}
              userPhotoInfo={this.state.userPhotoInfo || []}
              // loadNewMemberPhoto={this.loadNewMemberPhoto}
              // openPopupWindow={this.openPopupWindow}
              openEditPage={this.openEditPage}
              // isAccountOwner={
              //   this.state.memberId === this.props.userId ? true : false
              // }
            />
          </div>
          <div className={mainClass} id="vehicle-details-id">
            <VehicleDetailsUI
              vehicleData={vehicleData}
              suggestedPrice={this.props.vehicleSuggestedPriceResponse || {}}
              orgUnitAssetGroups={this.props.orgUnitAssetGroups || []}
              vehicleCategories={this.props.vehicleCategories || []}
              vehicleFeatureOptions={
                this.state.vehicleFeatureOptionsResponse || {}
              }
              dependentOrg={this.props.dependentOrg || []}
              isSubMenu={this.state.isSubMenu}
              isEditMode={this.state.isEditMode}
              inputChangedHandler={this.inputChangedHandler}
              inputAddressChangeHandler={this.inputAddressChangeHandler}
              subVehicleTypeOptions={
                this.props.vehicleGroupFeatureOptionsResponse
                  ? this.props.vehicleGroupFeatureOptionsResponse
                      .TypeOfPersonAssetOptions
                  : []
              }
              leadTimeInMinutes={this.props.leadTimeInMinutes}
              bufferTimeInMinutes={this.props.bufferTimeInMinutes}
              resetLoader={this.resetLoader}
              isValidateDone={this.state.isValidateDone}
            />
            {this.props.vehicleGroupFeatureOptionsResponse ? (
              <AmenitiesUI
                selectedAmenities={
                  vehicleData ? vehicleData.Amenities || [] : []
                }
                vehicleGroupFeatureOptions={
                  this.props.vehicleGroupFeatureOptionsResponse || {}
                }
                inputAmenitiesChangedHandler={this.inputAmenitiesChangedHandler}
                isEditMode={this.state.isEditMode}
                isSubMenu={this.state.isSubMenu}
              />
            ) : (
              ""
            )}
            {this.state.isSubMenu && !this.state.isEditMode ? (
              <Fragment>
                <VehicleImagesUI
                  showImageViewer={this.showImageViewer}
                  images={vehicleData ? vehicleData.Images : []}
                />
                <VehicleReviewUI
                  reviews={vehicleData ? vehicleData.Reviews : []}
                />
                {vehicleData.Images.length > 0 ? (
                  <Viewer
                    zIndex={1055}
                    visible={this.state.showImageViewer}
                    onClose={() => {
                      this.closeImageViewer();
                    }}
                    images={imgList}
                  />
                ) : (
                  ""
                )}
              </Fragment>
            ) : (
              <TermsConditionBlock
                isEditMode={this.state.isEditMode}
                isSubMenu={this.state.isSubMenu}
              />
            )}
            {this.state.isEditMode || !this.state.isSubMenu ? (
              <div className="form-footer" style={{ textAlign: "center" }}>
                <button
                  type="button"
                  name="btnSave"
                  className="btn btn-primary"
                  onClick={this.doAddOrEditVehicle}
                >
                  <i className="fas fa-save pr-1" />
                  Save
                </button>
                <button
                  type="button"
                  name="btnCancel"
                  className="btn btn-secondary ml-2"
                  onClick={this.onCancel}
                >
                  Cancel
                </button>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        {this.props.loading || this.state.loading ? <Spinner /> : ""}
      </Fragment>
    );
  }
}
const initialState = {
  breadCrumbList: [],
  loading: false,
  isEditMode: false,
  isSubMenu: false,
  vehicleInputRequest: new Vehicle(),
  vehicleFeatureOptionsResponse: null,
  vehicleSuggestedPriceResponse: null,
  isBlocking: false,
  isValidateDone: false,
};
const mapStateToProps = (state) => {
  return {
    loading:
      state.vehicleReducer.loading ||
      state.vehicleReducer.groupFeatureLoding ||
      state.vehicleReducer.featureOptionsLoding ||
      state.vehicleReducer.suggestedPriceLoding,
    updateVehicleResponse: state.vehicleReducer.updateVehicleResponse,
    dependentOrg: state.auth.loggedInUserData.DependantOrgList,
    leadTimeInMinutes:
      state.auth.loggedInUserData.OrgUnitSettings.LeadTimeInMinutes,
    bufferTimeInMinutes:
      state.auth.loggedInUserData.OrgUnitSettings.BufferTimeInMinutes,
    reservationRequestBased:
      state.auth.loggedInUserData.OrgUnitSettings.reservationRequestBased,
    managerId: state.auth.loggedInUserData.OrgUnitSettings.ManagerId,
    orgUnitAssetGroups:
      state.auth.loggedInUserData.OrgUnitSettings.OrgUnitAssetGroups || [],
    vehicleCategories: state.auth.loggedInUserData.VehicleCategories,
    orgUnitId: state.auth.orgUnitId,
    vehicleDetailsResponse: state.vehicleReducer.vehicleDetailsResponse,
    vehicleFeatureOptionsResponse:
      state.vehicleReducer.vehicleFeatureOptionsResponse,
    vehicleSuggestedPriceResponse:
      state.vehicleReducer.vehicleSuggestedPriceResponse,
    vehicleGroupFeatureOptionsResponse:
      state.vehicleReducer.vehicleGroupFeatureOptionsResponse,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onAddOrEditVehicle: (req, mode) =>
      dispatch(actions.addOrEditVehicle(req, mode)),
    onAddOrEditVehicleReset: () => dispatch(actions.addOrEditVehicleReset()),
    onGetVehicleDetails: (req) => dispatch(actions.getVehicleDetails(req)),
    onGetVehicleDetailsReset: () => dispatch(actions.getVehicleDetailsReset()),
    onGetVehicleFeatureOptions: (req) =>
      dispatch(actions.getVehicleFeatureOptions(req)),
    onGetVehicleFeatureOptionsReset: () =>
      dispatch(actions.getVehicleFeatureOptionsReset()),
    onGetVehicleGroupFeatureOptions: (req) =>
      dispatch(actions.getVehicleGroupFeatureOptions(req)),
    onGetVehicleGroupFeatureOptionsReset: () =>
      dispatch(actions.getVehicleGroupFeatureOptionsReset()),
    onGetSuggestedPriceforVehicle: (req) =>
      dispatch(actions.getSuggestedPriceforVehicle(req)),
    onGetSuggestedPriceforVehicleReset: () =>
      dispatch(actions.getSuggestedPriceforVehicleReset()),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(VehicleDetails));

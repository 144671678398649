import React, { useEffect, useState } from "react";
import Draggable from "react-draggable";
import { useDispatch, useSelector } from "react-redux";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { renderBesicLabelPair } from "../../../../components/UI/Input/common-info-pair";
import { showNotification } from "../../../../components/UI/Toaster/Toaster";
import { getBasicToastResponse } from "../../../../shared/utility";
import {
  getReservationVoucher,
  getReservationVoucherReset,
} from "../../../../store/actions/index";
import { getVoucherInfoCtrl } from "./voucher-info-ctrl";

export const VoucherInfoModal = (props) => {
  const { reservationId } = props;
  const dispatch = useDispatch();
  const reservationVoucherResponse = useSelector(
    (state) => state.reservationReducer.reservationVoucherResponse
  );
  const [voucherInfoCtrl, setVoucherInfoCtrl] = useState([
    getVoucherInfoCtrl(),
  ]);
  useEffect(() => {
    dispatch(getReservationVoucherReset());
    dispatch(getReservationVoucher({ ReservationId: reservationId }));
  }, []);
  useEffect(() => {
    if (!reservationVoucherResponse) {
      return;
    }
    if (reservationVoucherResponse.StatusCode === 0) {
      setVoucherInfoCtrl(
        getVoucherInfoCtrl(reservationVoucherResponse.ReservationVoucher)
      );
    } else {
      showNotification(getBasicToastResponse(reservationVoucherResponse));
      dispatch(getReservationVoucherReset());
    }
  }, [reservationVoucherResponse]);

  const renderVoucherInfo = (blockNo) => {
    const voucherInfoCtrl_0 = voucherInfoCtrl.filter(
      (info) => info.info_block === blockNo
    );
    const defaultConfig = {
      defaultDetailTxt: "--",
      tileCLass: "font-weight-bold",
    };
    return voucherInfoCtrl_0.map((info, index) => {
      return (
        <>
          {info.info_heading ? (
            <h4
              className="form-header text-uppercase col-lg-12 p-01"
              key={"h" + index}
            >
              <i className="fas fa-user-circle"></i>
              {info.info_heading}
            </h4>
          ) : (
            ""
          )}
          {info.fields.map((filed, i) => {
            return (
              <div className="popup-row" key={"lb" + i}>
                {renderBesicLabelPair(filed, defaultConfig)}
              </div>
            );
          })}
        </>
      );
    });
  };
  return (
    <Draggable handle=".cursor">
      <Modal
        isOpen={props.showModal}
        className="no-cursor"
        style={{ minWidth: "85rem" }}
        toggle={() => {
          props.closeModal();
        }}
      >
        <ModalHeader
          className="modal-header bg-primary modal-title text-white cursor"
          toggle={() => {
            props.closeModal();
          }}
        >
          <i className="fas fa-globe pr-2"></i>
          Voucher Quick View
        </ModalHeader>
        <ModalBody
          className=""
          style={{
            minHeight: "45rem",
          }}
        >
          <div className="row col-lg-12 p-01" id="voucher-quick-view">
            <div className="col-lg-6">{renderVoucherInfo(0)}</div>
            <div className="col-lg-6">{renderVoucherInfo(1)}</div>
          </div>
        </ModalBody>
      </Modal>
    </Draggable>
  );
};
export default VoucherInfoModal;

import Phone from "../Phone";
import Address from "../Address";
import {
  getFullName
} from "../../../../shared/utility";
const MemberSearchModel = function (data) {
  if (!data) data = {};
  this.MembershipId = data.MembershipId || null;
  this.PersonId = data.PersonId || null;
  this.Age = data.Age || null;
  this.MemberType = data.MemberType || null;
  this.MembershipDate = data.MembershipDate || null;
  this.FirstName = data.FirstName || null;
  this.MiddleName = data.MiddleName || null;
  this.LastName = data.LastName || null;
  this.FullName =
    data.getFullName ||
    getFullName(data.FirstName, data.LastName, data.MiddleName);

  this.EmailAddress = data.EmailAddress || null;

  this.PrimaryPhone = data.PrimaryPhone || null;
  this.MobilePhone = new Phone(data.MobilePhone);
  //this.FormattedMobilePhone = getNormalFormattedPhoneNumber(this.MobilePhone);
  this.HomePhone = new Phone(data.HomePhone);
  //this.FormattedHomePhone = getNormalFormattedPhoneNumber(this.HomePhone);
  this.WorkPhone = new Phone(data.WorkPhone);
  //this.FormattedWorkPhone = getNormalFormattedPhoneNumber(this.WorkPhone);

  //LICENSE INFORMATION START HERE
  this.DateOfBirth = data.DateOfBirth || null;
  this.DrivingLicense = data.DrivingLicense || null;
  this.DrivingLicenseCountryId = data.DrivingLicenseCountryId || null;
  this.DrivingLicenseCountry = data.DrivingLicenseCountry || null;
  this.DrivingLicenseStateId = data.DrivingLicenseStateId || null;
  this.DrivingLicenseState = data.DrivingLicenseState || null;
  this.IsInternationalLicense = data.IsInternationalLicense || null;
  this.LicenseExpirationDate = data.LicenseExpirationDate || null;
  this.DrivingRecordChecked = data.DrivingRecordChecked || null;
  this.DrivingRecordExpDate = data.DrivingRecordExpDate || null;

  this.DisplayAddressCount = data.DisplayAddressCount || null;
  this.PrimaryAddress = new Address(data.PrimaryAddress);
  this.FormattedPrimaryAddress = data.FormattedPrimaryAddress || null;
  this.ZipCode = data.ZipCode || null;
  this.CreditCardAddress = data.CreditCardAddress || null;

  this.InsuranceCarrier = data.InsuranceCarrier || null;
  this.InsuranceExpirationDate = data.InsuranceExpirationDate || null;
  this.InsuranceVerified = data.InsuranceVerified || null;

  this.ProfileLocationId = data.ProfileLocationId || null;
  this.ProfileLocationName = data.ProfileLocationName || null;
  this.OptInXpress = data.OptInXpress || false;
  this.LoginEnabled = data.LoginEnabled || null;
  this.ProfileValidated = data.ProfileValidated || false;
  this.Status = data.Status || false;
  this.BlackListed = data.BlackListed || false;

  this.EmployeeNo = data.EmployeeNo || null;
  this.EmployeeLocationId = data.EmployeeLocationId || null;
  this.EmployeeLocationName = data.EmployeeLocationName || null;
  this.BadgeNo = data.BadgeNo || null;
  this.CompanyName = data.CompanyName || null;
  this.Department = data.Department || null;
  this.Title = data.Title || null;
};
export default MemberSearchModel;

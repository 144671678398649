import { create_UUID } from "../../../../containers/Reservation/fox-reservation/fox-res-helper";
import { ReservationBlocks } from "../../../../shared/GlobalVar";
import { getFullName } from "../../../../shared/utility";
import PhoneViewModel from "../../view-models/phone-view-model";
import { FoxAddress } from "../domain/fox-address";
import { FoxCustomerInsurance } from "../domain/fox-customer-insurance";
import { FoxDrivingLicense } from "../domain/fox-driving-license";
import { FoxStepViewModel } from "./fox-step-view-model";

export const FoxCustomerViewModel = function (data, isEditMode) {
  if (!data) data = {};
  else data = JSON.parse(JSON.stringify(data));
  if (!data.addresses) {
    data.addresses = [];
  }
  if (!data.phoneNumbers) {
    data.phoneNumbers = [];
  }
  data.addresses.map((addrData, index) => {
    if (addrData.primary === true) {
      data.primaryAddress = { ...addrData };
    }
  });
  data.phoneNumbers.map((phoneData, index) => {
    if (phoneData.type === "Mobile") {
      data.mobilePhone = new PhoneViewModel({
        type: phoneData.type,
        countryCode: phoneData.countryCode,
        countryIso: phoneData.country,
        number: phoneData.number,
      });
    } else if (phoneData.type === "Home") {
      data.homePhone = new PhoneViewModel({
        type: phoneData.type,
        countryCode: phoneData.countryCode,
        countryIso: phoneData.country,
        number: phoneData.number,
      });
    } else if (phoneData.type === "Work") {
      data.workPhone = new PhoneViewModel({
        type: phoneData.type,
        countryCode: phoneData.countryCode,
        countryIso: phoneData.country,
        number: phoneData.number,
      });
    }
  });
  if (data.driversLicense) {
    data.license = { ...data.driversLicense };
  }
  if (data.insurance) {
    data.insurance = { ...data.insurance };
  }
  this.customerId = data.customerId || null;
  this.profileImageUrl = data.profileImageUrl || null;
  this.email = data.email || null;
  this.renterId = data.renterId || null;
  this.foxCredit = data.foxCredit || null;
  this.firstName = data.firstName || null;
  this.middleName = data.middleName || null;
  this.lastName = data.lastName || null;
  this.dob = data.dob || null;
  this.emailDeals = data.emailDeals || null;
  this.textDeals = data.textDeals || null;
  this.reservationCount = data.reservationCount || null;
  this.externalId = data.externalId || null;
  this.xpress = data.xpress || null;
  this.payNow = data.payNow || null;
  this.primaryAddress = new FoxAddress(
    data.primaryAddress,
    isEditMode ? true : false,
    true
  );
  this.mobilePhone = new PhoneViewModel(data.mobilePhone || {});
  this.homePhone = new PhoneViewModel(data.homePhone || {});
  this.workPhone = new PhoneViewModel(data.workPhone || {});
  this.license = new FoxDrivingLicense(
    data.license,
    isEditMode ? true : false,
    true
  );
  this.insurance = new FoxCustomerInsurance(data.insurance);

  /**dont  know its the best implementation for payment later modify it*/
  this.methodType = data.methodType || null;
  this.paymentService = data.paymentService || null;
  this.expDate = data.expDate || null;
  this.accountNumber = data.accountNumber || null;
  this.name = data.name || null;
  this.creditAddress = new FoxAddress(
    data.creditAddress,
    isEditMode ? true : false,
    true
  );
  //this.paymentPhone= new PhoneViewModel(data.paymentPhone)|| null;
  this.securityCode = data.securityCode || null;
  this.paymentFailureCount = data.paymentFailureCount || null;
  this.fullName = getFullName(data.firstName, data.lastName, data.middleName);
  this.employeeNo = data.customerEmployeeNo || null;
  this.employeeLocationName = data.customerLocationName || null;
  this.companyName = data.companyName || null;
  this.designation = data.designation || null;
  this.tempId = data.tempId || create_UUID();
  this.isCustomerBlockChanged = data.isCustomerBlockChanged || false;
  this.showSendDiscount = data.showSendDiscount || false;
  //***didn't find finest solution for following property****//
  const stepData = data.stepViewModel || {};
  stepData.prevBlock = ReservationBlocks.ADDONS_FORM;
  stepData.nextBlock = ReservationBlocks.RES_NOTES_BLOCK;
  stepData.currentBlock = ReservationBlocks.CUSTOMER_INFO;
  stepData.executionBlock = stepData.executionBlock || null;
  stepData.iskeepChanged = stepData.iskeepChanged || false;
  this.stepViewModel = new FoxStepViewModel(stepData);
};

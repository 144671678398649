import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Draggable from "react-draggable";
import {
  getErrorToastData,
  getFormattedValidationMsg,
  isNullOrEmpty,
} from "../../../shared/utility";
import { showNotification } from "../../../components/UI/Toaster/Toaster";

const ChangeResetPassword = (props) => {
  const [password, setPassword] = useState("");
  const [isValidateDone, setIsValidateDone] = useState(false);

  const updatePasswordData = () => {
    const req = {};
    req.MemberId = props.memberId;
    if (props.popupFor === "changePassword") {
      req.NewTempPassword = password;
      if (isNullOrEmpty(password)) {
        showNotification(
          getErrorToastData(getFormattedValidationMsg("Password"))
        );
        setIsValidateDone(true);
        return false;
      } else {
        setIsValidateDone(false);
      }
    }
    props.resetOrChangePassword(req);
  };

  return (
    <div>
      <Draggable handle=".cursor">
        <Modal
          isOpen={props.showModal}
          className="no-cursor"
          toggle={props.closeModal}
        >
          <ModalHeader
            className="modal-header bg-primary modal-title text-white cursor"
            toggle={props.closeModal}
          >
            <i className="fas fa-key" /> {props.title}
          </ModalHeader>
          <ModalBody>
            <div className="form-group row">
              <div className="FormRow col-sm-12">
                <div className="FormRow">
                  <div
                    className={`form-group formRow required ${
                      props.popupFor === "resetPassword" ? "displayNone" : ""
                    }`}
                  >
                    <label className="col-sm-5 col-form-label font-weight-bold">
                      Temporary Password
                    </label>
                    <div
                      className={`col-sm-7 pl-0 ${
                        password
                          ? ""
                          : isValidateDone
                          ? "validation-failed"
                          : ""
                      }`}
                    >
                      <input
                        type="text"
                        name="password"
                        value={password || ""}
                        className="text"
                        onChange={(event) => setPassword(event.target.value)}
                      />
                    </div>
                  </div>
                  <div
                    className={`form-group formRow ${
                      props.popupFor === "changePassword" ? "displayNone" : ""
                    }`}
                  >
                    <label className="col-sm-12 col-form-label font-weight-bold">
                      Are you sure you want to reset this member's log-in
                      password?
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button
              type="submit"
              name="btnSave"
              className="btn btn-primary waves-effect waves-light btnSize ml-2"
              onClick={updatePasswordData}
            >
              {props.popupFor === "changePassword" ? "Submit" : "Yes"}
            </button>
            <button
              type="button"
              name="btnCancel"
              className="btn btn-secondary  waves-effect waves-light btnSize ml-2"
              onClick={props.closeModal}
            >
              Cancel
            </button>
          </ModalFooter>
        </Modal>
      </Draggable>
    </div>
  );
};

export default ChangeResetPassword;

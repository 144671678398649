import React, { useEffect, useState } from 'react';
import { Utility } from '../../../shared/utility';
import { CountryKeyValueType, DefaultAddressProperty } from '../../../shared/utility/enum-utility';
import { getKeyValFromList } from '../../../shared/utility/list-utility';
import InputWidgetCtrl from '../../UI/Input/input-widget-ctrl';
function CountryCtrl(props) {
    const { name, value, defaultValue, placeholder, disabled, imgIco, keyType, key,
        addressData,
        addressType,
        onChange,
        clearSecurityDepositRate,
        isRequired,
        validateFunction
    } = props;
    const [countries, setCountries] = useState([]);
    const [loader, setLoader] = useState(false);
    useEffect(() => {
        loadCountires();
    }, [])
    /**TO DO: need to change country sotrage and data array list process */
    const loadCountires = async () => {
        try {
            Utility.countryStateCityPostal.getCountries((countryResponse) => {
                setCountries(countryResponse);
                setLoader(false);
            });
        } catch (e) {
            setLoader(false);
        }
    }
    const onHandleChange = (event) => {
        if (disabled || !onChange) return false;
        let val = "";
        if (keyType === CountryKeyValueType.CountryCode) {
            val = event.countryCode;
        }
        if (addressData && addressType) {
            const tempData = { ...addressData };
            tempData[name] = val;
            tempData[DefaultAddressProperty?.state] = null;
            tempData[DefaultAddressProperty?.city] = null;
            tempData[DefaultAddressProperty?.postalCode] = null;
            onChange(tempData, addressType);
        }
        else {
            if (clearSecurityDepositRate)
                clearSecurityDepositRate();
            onChange(val, name);
        }
    }
    return (
        <div className='input-ctrl'>
            <InputWidgetCtrl
                id={name}
                name={name}
                key={key}
                placeholder={placeholder}
                data={countries}
                valueField={keyType}
                textField="displayValue"
                value={getKeyValFromList(
                    countries,
                    value,
                    defaultValue,
                    keyType
                )}
                onChange={(event) =>
                    onChange ? onHandleChange(event) : ""
                }
                disabled={disabled}
                busy={loader}
                imgIco={imgIco}
                isRequired={isRequired}
                validateFunction={validateFunction}
            />
        </div>
    )
}
export default CountryCtrl
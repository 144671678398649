import React from 'react'

function PricingCommonWrapper(props) {
    return (
        <div className="modern-pricing-container position-relative">
            {props.children}
        </div>
    )
}

export default PricingCommonWrapper
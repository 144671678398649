import { renderToStaticMarkup } from "react-dom/server";
import L from "leaflet";
import {
  BUS_GREY,
  BUS_GREEN,
  BUS_RED,
  BUS_ORANGE,
} from "../../shared/AppImages";
import { ColorCode, MapType } from "../../shared/utility/enum-utility";

const getShuttleIcon = (type, color, lbl, index) => {
  var LeafDivIcon = L.DivIcon.extend({
    options: {
      iconSize: [45, 45],
      shadowSize: [50, 64],
      iconAnchor: [22.5, 45],
      shadowAnchor: [25, 64],
      popupAnchor: [-3, -76],
    },
  });
  // return new LeafDivIcon({
  //   html: `<img src="${type}" />`,
  // });
  return new LeafDivIcon({
    html: renderToStaticMarkup(
      <>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          // xmlns:xlink="http://www.w3.org/1999/xlink"
          //xmlns:svgjs="http://svgjs.com/svgjs"
          width="40"
          height="40"
          x="0"
          y="0"
          viewBox="0 0 64 64"
          style={{ enableBackground: "new 0 0 40 40" }}
          //xml:space="preserve"
          className=""
        >
          <g>
            {/* <path
              xmlns="http://www.w3.org/2000/svg"
              d="M11,8H53V36l-2.733,1.367A6.007,6.007,0,0,1,47.584,38H16.416a6.007,6.007,0,0,1-2.683-.633L11,36Z"
              style={{ fill: "#ffffff" }}
              fill="#ffffff"
              data-original="#ffffff"
              className=""
            ></path> */}
            <path
              xmlns="http://www.w3.org/2000/svg"
              d="M61,12H58V10a1,1,0,0,0-1-1H54V8a7.008,7.008,0,0,0-7-7H17a7.008,7.008,0,0,0-7,7V9H7a1,1,0,0,0-1,1v2H3a1,1,0,0,0-1,1V37a2,2,0,0,0,2,2H6a2,2,0,0,0,2-2V20a1,1,0,0,0-1-1H4V14H6v2a1,1,0,0,0,1,1h3V52a5.009,5.009,0,0,0,4,4.9V61a2,2,0,0,0,2,2h6a2,2,0,0,0,2-2V57H40v4a2,2,0,0,0,2,2h6a2,2,0,0,0,2-2V56.9A5.009,5.009,0,0,0,54,52V17h3a1,1,0,0,0,1-1V14h2v5H57a1,1,0,0,0-1,1V37a2,2,0,0,0,2,2h2a2,2,0,0,0,2-2V13A1,1,0,0,0,61,12ZM6,21V37H4V21ZM38.052,47.97c-.006.01-.008.02-.013.03H25.961c-.006-.009-.007-.02-.013-.029L24.766,46H39.234ZM12,45.087l9.9.825a1,1,0,0,1,.774.481L24.233,49H12Zm29.33,1.307a1,1,0,0,1,.774-.482l9.9-.825V49H39.767ZM52,43.08l-10.062.838a3.09,3.09,0,0,0-.4.082H22.458a3.09,3.09,0,0,0-.4-.082L12,43.08V37.618l1.286.643a7.027,7.027,0,0,0,3.13.739h.049l-.3.445,1.664,1.11L18.868,39H47.584a7.027,7.027,0,0,0,3.13-.739L52,37.618Zm0-7.7-2.181,1.09A5.027,5.027,0,0,1,47.584,37H20.2l1.333-2H46V33H21a1,1,0,0,0-.832.445L17.8,37H16.416a5.027,5.027,0,0,1-2.235-.528L12,35.382V9H52ZM17,3H47a5.009,5.009,0,0,1,4.9,4H12.1A5.009,5.009,0,0,1,17,3ZM8,15V11h2v4ZM22,61H16V57h6Zm20,0V57h6v4Zm7-6H15a3,3,0,0,1-3-3V51H24.233a1.976,1.976,0,0,0,1.73-1H38.037a1.976,1.976,0,0,0,1.73,1H52v1A3,3,0,0,1,49,55Zm7-40H54V11h2Zm4,22H58V21h2Z"
              fill={color}
              data-original={color}
              className=""
            ></path>
          </g>
        </svg>
        <label className="shuttle-lbl" style={{ color: color, zIndex: "500" }}>
          {lbl}
        </label>
      </>
    ),
  });
};
const getLandmarkIcon = (color, lbl, index) => {
  var LeafDivIcon = L.DivIcon.extend({
    options: {
      iconSize: [45, 45],
      shadowSize: [50, 64],
      iconAnchor: [22.5, 45],
      shadowAnchor: [25, 64],
      popupAnchor: [-3, -76],
    },
  });
  return new LeafDivIcon({
    html: renderToStaticMarkup(
      <>
        <i
          className="fas fa-circle fa-3x"
          style={{ color: color, display: "inline", fontSize: "3.3rem" }}
        />
        <label className="landmark-lbl">{lbl}</label>
      </>
    ),
  });
};
export const createMapIcon = (type, color, label, index) => {
  if (type === MapType.LANDMARKS) {
    if (color === ColorCode.LANDMARK_RED) {
      return getLandmarkIcon(ColorCode.LANDMARK_RED, label || "T", index);
    } else if (color === ColorCode.LANDMARK_GREEN) {
      return getLandmarkIcon(ColorCode.LANDMARK_GREEN, label || "G", index);
    } else if (color === ColorCode.LANDMARK_BLUE) {
      return getLandmarkIcon(ColorCode.LANDMARK_BLUE, label || "O", index);
    }
  } else if (MapType.SHUTTLES) {
    if (color === ColorCode.SHUTTLE_RED) {
      return getShuttleIcon(BUS_RED, color, label, index);
    } else if (color === ColorCode.SHUTTLE_GREEN) {
      return getShuttleIcon(BUS_GREEN, color, label, index);
    } else if (color === ColorCode.SHUTTLE_ORANGE) {
      return getShuttleIcon(BUS_ORANGE, color, label, index);
    } else if (color === ColorCode.SHUTTLE_GREY) {
      return getShuttleIcon(BUS_GREY, color, label, index);
    } else {
      return getShuttleIcon(BUS_GREY, color, label, index);
    }
  }
};

const getClusterIconColor = (cluster, isShuttle) => {
  let clusterIconColor = {};
  clusterIconColor.color1 = isShuttle
    ? ColorCode.SHUTTLE_GREY
    : ColorCode.LANDMARK_RED;
  clusterIconColor.color2 = isShuttle
    ? ColorCode.SHUTTLE_GREEN
    : ColorCode.LANDMARK_GREEN;
  clusterIconColor.color3 = isShuttle
    ? ColorCode.SHUTTLE_RED
    : ColorCode.LANDMARK_BLUE;
  let clusterCount = cluster.getChildCount();
  let markersArray = cluster.getAllChildMarkers();
  if (clusterCount > 2) {
    clusterIconColor.color1 = markersArray[0].options.color;
    clusterIconColor.color2 = markersArray[1].options.color;
    clusterIconColor.color3 = markersArray[2].options.color;
  } else if ((clusterCount = 2)) {
    clusterIconColor.color1 = markersArray[0].options.color;
    clusterIconColor.color2 = markersArray[1].options.color;
  } else {
    clusterIconColor.color1 = markersArray[0].options.color;
  }
  return clusterIconColor;
};

export const createClusterCustomIcon_landmark = (cluster) => {
  let clusterCount = cluster.getChildCount();
  let clusterIconColor = getClusterIconColor(cluster);
  if (clusterCount == 2) {
    return L.divIcon({
      html: renderToStaticMarkup(
        <div className="position-relative">
          <div
            className="landmark-circle"
            style={{ background: clusterIconColor.color1 }}
          />
          <div
            className="landmark-circle"
            style={{ background: clusterIconColor.color2 }}
          />
          {/* <span className="landmark-count-class-2">{clusterCount}</span> */}
        </div>
      ),
      iconSize: L.point(0, 0, false),
    });
  } else if (clusterCount > 2) {
    return L.divIcon({
      html: renderToStaticMarkup(
        <div className="position-relative">
          <div
            className="landmark-circle"
            style={{ background: clusterIconColor.color1 }}
          />
          <div
            className="landmark-circle"
            style={{ background: clusterIconColor.color2 }}
          />
          <div
            className="landmark-circle"
            style={{ background: clusterIconColor.color3 }}
          />
          {/* <span
            className="landmark-count-class-3"
            style={{ left: clusterCount > 9 ? "-0.3rem" : "0.3rem" }}
          >
            {clusterCount}
          </span> */}
        </div>
      ),
      iconSize: L.point(0, 0, false),
    });
  }
};
export const getShuttleVectorImg = (color, className) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      // xmlns:xlink="http://www.w3.org/1999/xlink"
      //xmlns:svgjs="http://svgjs.com/svgjs"
      width="40"
      height="40"
      x="0"
      y="0"
      viewBox="0 0 64 64"
      // style="enable-background:new 0 0 512 512"
      //xml:space="preserve"
      className={className || ""}
    >
      <g>
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="m11 7h42a0 0 0 0 1 0 0v45a4 4 0 0 1 -4 4h-34a4 4 0 0 1 -4-4v-45a0 0 0 0 1 0 0z"
          fill="#ffffff"
          data-original="#ad1a29"
          className=""
        ></path>
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="m53 52v-44h-38v44a4 4 0 0 0 4 4h30a4 4 0 0 0 4-4z"
          fill="#ffffff"
          data-original="#d42123"
          className=""
        ></path>
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="m11 8h42v28l-2.733 1.367a6.007 6.007 0 0 1 -2.683.633h-31.168a6.007 6.007 0 0 1 -2.683-.633l-2.733-1.367z"
          fill="#ffffff"
          data-original="#6a84cc"
          className=""
        ></path>
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="m53 8a6 6 0 0 0 -6-6h-30a6 6 0 0 0 -6 6z"
          fill="#ffffff"
          data-original="#ad1a29"
          className=""
        ></path>
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="m13 8v24a2 2 0 0 0 2 2h38v-26z"
          fill="#ffffff"
          data-original="#84ade9"
          className=""
        ></path>
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="m19.407 45h25.184l-2.591 4h-20z"
          fill="#ffffff"
          data-original="#d6d8da"
          className=""
        ></path>
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="m17.832 40.555-1.664-1.11 4-6a1 1 0 0 1 .832-.445h25v2h-24.465z"
          fill="#ffffff"
          data-original="#858789"
          className=""
        ></path>
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="m53 10h4v6h-4z"
          fill="#ffffff"
          data-original="#b2b4b6"
          className=""
        ></path>
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="m61 20v17a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1-1v-17z"
          fill="#ffffff"
          data-original="#b2b4b6"
          className=""
        ></path>
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="m15 56v5a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-5z"
          fill="#ffffff"
          data-original="#4f5153"
          className=""
        ></path>
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="m23 61v-5h-6v5a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1z"
          fill="#ffffff"
          data-original="#636566"
          className=""
        ></path>
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="m41 56v5a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-5z"
          fill="#ffffff"
          data-original="#4f5153"
          className=""
        ></path>
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="m49 61v-5h-6v5a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1z"
          fill="#ffffff"
          data-original="#636566"
          className=""
        ></path>
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="m58 20v15a2 2 0 0 0 2 2h1v-17z"
          fill="#ffffff"
          data-original="#d6d8da"
          className=""
        ></path>
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="m61 38h-1v-24h-3v-2h4a1 1 0 0 1 1 1v24a1 1 0 0 1 -1 1z"
          fill="#ffffff"
          data-original="#858789"
          className=""
        ></path>
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="m53 10h2v6h-2z"
          fill="#ffffff"
          data-original="#858789"
          className=""
        ></path>
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="m23 46h19v2h-19z"
          fill="#ffffff"
          data-original="#b2b4b6"
          className=""
        ></path>
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="m53 44-10.979.915a2 2 0 0 0 -1.548.964l-1.564 2.606a1 1 0 0 0 .857 1.515h13.234z"
          fill="#ffffff"
          data-original="#daa664"
          className=""
        ></path>
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="m42.473 44.879-1.564 2.606a1 1 0 0 0 .857 1.515h11.234v-5l-10.526.877z"
          fill="#ffffff"
          data-original="#ddb774"
          className=""
        ></path>
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="m11 44 10.978.915a2 2 0 0 1 1.549.964l1.564 2.606a1 1 0 0 1 -.857 1.515h-13.234z"
          fill="#ffffff"
          data-original="#daa664"
          className=""
        ></path>
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="m21.527 44.879 1.564 2.606a1 1 0 0 1 -.857 1.515h-11.234v-5l10.526.877z"
          fill="#ffffff"
          data-original="#ddb774"
          className=""
        ></path>
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="m20.168 33.445-.368.555h24.2a1 1 0 0 0 1-1h-24a1 1 0 0 0 -.832.445z"
          fill="#ffffff"
          data-original="#d6d8da"
          className=""
        ></path>
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="m15 6v2h38a5.968 5.968 0 0 0 -1.537-4h-34.463a2 2 0 0 0 -2 2z"
          fill="#ffffff"
          data-original="#d42123"
          className=""
        ></path>
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="m7 10h4v6h-4z"
          fill="#ffffff"
          data-original="#b2b4b6"
          className=""
        ></path>
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="m3 20v17a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1v-17z"
          fill="#ffffff"
          data-original="#b2b4b6"
          className=""
        ></path>
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="m6 20v15a2 2 0 0 1 -2 2h-1v-17z"
          fill="#ffffff"
          data-original="#d6d8da"
          className=""
        ></path>
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="m3 38h1v-24h3v-2h-4a1 1 0 0 0 -1 1v24a1 1 0 0 0 1 1z"
          fill="#ffffff"
          data-original="#858789"
          className=""
        ></path>
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="m9 10h2v6h-2z"
          fill="#ffffff"
          data-original="#858789"
          className=""
        ></path>
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="m50.084 10h-15.084a1 1 0 0 0 0 2h15.084a1 1 0 0 0 0-2z"
          fill="#ffffff"
          data-original="#9dbeec"
          className=""
        ></path>
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="m61 12h-3v-2a1 1 0 0 0 -1-1h-3v-1a7.008 7.008 0 0 0 -7-7h-30a7.008 7.008 0 0 0 -7 7v1h-3a1 1 0 0 0 -1 1v2h-3a1 1 0 0 0 -1 1v24a2 2 0 0 0 2 2h2a2 2 0 0 0 2-2v-17a1 1 0 0 0 -1-1h-3v-5h2v2a1 1 0 0 0 1 1h3v35a5.009 5.009 0 0 0 4 4.9v4.1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-4h16v4a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-4.1a5.009 5.009 0 0 0 4-4.9v-35h3a1 1 0 0 0 1-1v-2h2v5h-3a1 1 0 0 0 -1 1v17a2 2 0 0 0 2 2h2a2 2 0 0 0 2-2v-24a1 1 0 0 0 -1-1zm-55 9v16h-2v-16zm32.052 26.97c-.006.01-.008.02-.013.03h-12.078c-.006-.009-.007-.02-.013-.029l-1.182-1.971h14.468zm-26.052-2.883 9.9.825a1 1 0 0 1 .774.481l1.559 2.607h-12.233zm29.33 1.307a1 1 0 0 1 .774-.482l9.9-.825v3.913h-12.237zm10.67-3.314-10.062.838a3.09 3.09 0 0 0 -.4.082h-19.08a3.09 3.09 0 0 0 -.4-.082l-10.058-.838v-5.462l1.286.643a7.027 7.027 0 0 0 3.13.739h.049l-.3.445 1.664 1.11 1.039-1.555h28.716a7.027 7.027 0 0 0 3.13-.739l1.286-.643zm0-7.7-2.181 1.09a5.027 5.027 0 0 1 -2.235.53h-27.384l1.333-2h24.467v-2h-25a1 1 0 0 0 -.832.445l-2.368 3.555h-1.384a5.027 5.027 0 0 1 -2.235-.528l-2.181-1.09v-26.382h40zm-35-32.38h30a5.009 5.009 0 0 1 4.9 4h-39.8a5.009 5.009 0 0 1 4.9-4zm-9 12v-4h2v4zm14 46h-6v-4h6zm20 0v-4h6v4zm7-6h-34a3 3 0 0 1 -3-3v-1h12.233a1.976 1.976 0 0 0 1.73-1h12.074a1.976 1.976 0 0 0 1.73 1h12.233v1a3 3 0 0 1 -3 3zm7-40h-2v-4h2zm4 22h-2v-16h2z"
          fill={color}
          data-original="#000000"
          className=""
        ></path>
      </g>
    </svg>
  );
};
export const createClusterCustomIcon_shuttle = (cluster) => {
  let clusterCount = cluster.getChildCount();
  let clusterIconColor = getClusterIconColor(cluster, true);
  if (clusterCount === 2) {
    return L.divIcon({
      html: renderToStaticMarkup(
        <div className="position-relative">
          {getShuttleVectorImg(
            clusterIconColor.color1,
            "shuttle-cluster-double"
          )}
          {getShuttleVectorImg(clusterIconColor.color2)}
          {/* <span className="shuttle-count" style={{ color: "#000" }}>
            {clusterCount}
          </span> */}
        </div>
      ),
      iconSize: L.point(0, 0, false),
    });
  } else if (clusterCount > 2) {
    return L.divIcon({
      html: renderToStaticMarkup(
        <div className="position-relative">
          {getShuttleVectorImg(
            clusterIconColor.color1,
            "shuttle-cluster-double-1"
          )}
          {getShuttleVectorImg(clusterIconColor.color2)}
          {getShuttleVectorImg(
            clusterIconColor.color3,
            "shuttle-cluster-tripple"
          )}
          {/* <span className="shuttle-count-1" style={{ color: "#000" }}>
            {clusterCount}
          </span> */}
        </div>
      ),
      iconSize: L.point(0, 0, false),
    });
  }
};

import { getLocalTimezone } from "../../../../shared/utility";
import {
  getConvertedDistance,
  getConvertedSpeed,
  getHourMinSecFromMilliSecond,
} from "../../../../shared/utility/shuttle-app-utility";
import { ShuttleActivityViewModel } from "./shuttle-activity-view-model";
import { ShuttleEngineStatusViewModel } from "./shuttle-engine-status-view-model";

export const ShuttleTripDetailsViewModel = function (data) {
  if (!data) data = {};
  let timezoneString = getLocalTimezone();
  const activityList = [];
  const statusList = [];
  if (data.activities && data.activities.length > 0) {
    data.activities.map((entity) => {
      activityList.push(new ShuttleActivityViewModel(entity, timezoneString));
    });
  }
  if (data.engineStatusDurationInMillis) {
    Object.keys(data.engineStatusDurationInMillis).map((entity, index) => {
      statusList.push(
        new ShuttleEngineStatusViewModel(data, entity, timezoneString)
      );
    });
  }

  this.engineStatusDurationInMillis = data.engineStatusDurationInMillis || {};
  this.shuttleEngineStatuses = [...statusList];
  this.tripAlerts = data.tripAlerts;
  this.activities = [...activityList];
  this.badgeNo = data.badgeNo || null;
  this.personId = data.personId || null;
  this.locationCode = data.locationCode || null;
  this.tripCode = data.tripCode || null;
  this.shuttleCode = data.shuttleCode || null;
  this.shuttleId = data.shuttleId || null;
  this.shuttleTripId = data.shuttleTripId || null;
  this.locationDisplayName = data.locationDisplayName || null;
  this.tripStartDateTimeInMillis = data.tripStartDateTimeInMillis || 0;
  this.tripDisplayStartDateTime = data.tripDisplayStartDateTime || "";
  this.tripEndDateTimeInMillis = data.tripEndDateTimeInMillis || 0;
  this.tripDisplayEndDateTime = data.tripDisplayEndDateTime || "";
  this.tripDurationInMillis = data.tripDurationInMillis;
  this.tripDuration = getHourMinSecFromMilliSecond(data.tripDurationInMillis);
  this.tripAvgSpeedInMph = data.tripAvgSpeedInMph || null;
  this.tripDisplayAvgSpeed = data.tripDisplayAvgSpeed || null;
  this.speedUnit = data.speedUnit ? data.speedUnit.toLowerCase() : "mph";
  this.averageSpeed = data.tripDisplayAvgSpeed ? data.tripDisplayAvgSpeed.toFixed(2) + " " + this.speedUnit : "--";
  this.tripMaxSpeedInMph = data.tripMaxSpeedInMph || null;
  this.tripDisplayMaxSpeed = data.tripDisplayMaxSpeed || null;
  this.maxSpeed = data.tripDisplayMaxSpeed ? data.tripDisplayMaxSpeed.toFixed(2) + " " + this.speedUnit : "--";
  this.tripDistanceInMiles = data.tripDistanceInMiles || null;
  this.tripDisplayDistance = data.tripDisplayDistance || null;
  this.distanceUnit = data.distanceUnit ? data.distanceUnit.toLowerCase() : "mi";
  this.distance = data.tripDisplayDistance ? data.tripDisplayDistance.toFixed(2) + " " + this.distanceUnit : "--";
  this.fullName = data.fullName || null;
  this.tripTimeZone = data.tripTimeZone || null;
};

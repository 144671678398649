import React, { useState } from 'react';
import RadioButton from '../../../components/UI/Input/checkbox-radio/radio-button-ctrl';
import CustomAccordion from '../../../components/UI/accordion/custom-accordion';
import MicroLoader from '../../../components/UI/loader/micro-loader';
import { getDateTimeFormatString } from '../../../components/common/common-helper';
import { DiscountStatus } from '../../../shared/utility/enum-utility';
import { convertFoxApiDateToStr } from '../fox-reservation/fox-res-helper';
import DiscountBadge from './discount-badge';
import DiscountReason from './discount-reason';
function DiscountHistory(props) {
    const { discountHistory,
        customerName,
        formType,
        getDisplayValueForReason,
        selectedDiscountId,
        setSelectedDiscountData,
        setFormValue,
        setCurrentFormType,
        showLoader,
        hasRevetAccess
    } = props;
    const [isActive, setIsActive] = useState(false);
    const onHistoryRowSelected = (data) => {
        if (data.status === DiscountStatus.GRANTED && hasRevetAccess) {
            setCurrentFormType(formType.REVERT_DISCOUNT);
        } else {
            setCurrentFormType(null);
        }
        setSelectedDiscountData(data);
        setFormValue(null);
    }
    const getActor = (data) => {
        return <>
            <b>Granted By: </b><label className="font-color-used">{data?.grantedPersonName}</label><br />
            {data?.status === DiscountStatus.USED ?
                <b>Used By: <label className="font-color-used">{customerName}</label></b>
                : ""}
            {data?.status === DiscountStatus.REVERTED ?
                <b>Reverted By: <label className="font-color-reverted">{data?.revertedPersonName}</label></b>
                : ""}
        </>;
    }
    return (
        <div className={`flex-grid ${!isActive ? "height-150" : ""}`}>
            <table className="table table-striped table-common">
                <thead className="thead-primary">
                    <th style={{ width: "50px" }} className='text-center'>
                        <CustomAccordion isActive={isActive} setIsActive={setIsActive} iconClasses="text-center" />
                    </th>
                    <th style={{ width: "130px" }}>
                        Date
                    </th>
                    <th style={{ width: "110px" }}>
                        Discount Id
                    </th>
                    <th style={{ width: "110px" }}>
                        Code
                    </th>
                    <th style={{ width: "90px" }}>
                        Status
                    </th>
                    <th style={{ width: "150px" }} className='text-left'>
                        Granted / Reverted / Used By
                    </th>
                    <th className='text-left'>
                        Grant / Revert Reason / Used Res. Code
                    </th>
                    <th style={{ width: "110px" }}>
                        Res. Code
                    </th>
                </thead>
                <tbody>
                    {discountHistory && discountHistory.length > 0 ? discountHistory.map((data) => {
                        return <tr className={`${selectedDiscountId === data.personFoxDiscountId && "bg-antiquewhite"}`}>
                            <td style={{ width: "50px" }}>
                                <RadioButton
                                    name="discount-history"
                                    title="Select discount row"
                                    checked={selectedDiscountId === data.personFoxDiscountId}
                                    onChange={() => onHistoryRowSelected(data)}
                                    value={formType.ADD_DISCOUNT}
                                />
                            </td>
                            <td>{convertFoxApiDateToStr(
                                data?.status === "GRANTED" ? data?.grantedOn
                                    : data?.status === "USED" ? data?.usedOn
                                        : data?.status === "REVERTED" ? data?.revertedOn : "",
                                getDateTimeFormatString(true, false, true),
                                "--"
                            )}</td>
                            <td>{data?.personFoxDiscountId}</td>
                            <td>{data?.foxDiscountCode}</td>
                            <td><DiscountBadge status={data?.status} /></td>
                            <td className='text-left'>{getActor(data)}</td>
                            <td className='text-left'>
                                <DiscountReason discountData={data} getDisplayValueForReason={getDisplayValueForReason} />
                            </td>
                            <td>{data?.reservationCode}</td>
                        </tr>
                    }) :
                        <tr>
                            <td colSpan={8}>
                                <div className='no-record-found'>{showLoader ? <MicroLoader /> : "No Record Found"}</div>
                            </td>
                        </tr>
                    }
                </tbody>
            </table>
        </div>
    )
}

export default DiscountHistory
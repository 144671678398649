import React from 'react';
import CommonTitle from '../../../containers/Reservation/component/common-title';

function CustomAccordion(props) {
    const { title, classNames, isActive, setIsActive, iconClasses = "text-right" } = props;
    return (
        <div onClick={() => { setIsActive(!isActive) }} className='flex-row cursor-pointer' >
            {title ? <CommonTitle title={title} classNames={classNames || "common-title"} /> : ""}
            <i

                className={` ${isActive
                    ? "fa fa-angle-up"
                    : "fa fa-angle-down"
                    }  fs-8 flex-1 ${iconClasses}`}
            ></i>
        </div>
    )
}

export default CustomAccordion
import React from 'react';
import CommonTitle from '../../../component/common-title';
import GeneralRateRow from '../general-rate-row';

function AddonsBlock({ addOns, loyalties }) {
    return (
        <>
            <div className='col-lg-12 mt-3'>
                <CommonTitle title="Add-Ons" classNames="common-title" />
            </div>
            {addOns.map((data) => {
                return <GeneralRateRow key={data?.desc.replace(/\s+/g, '')} title={data?.desc} rate={data?.rate} total={data?.amount} />
            })}
            {loyalties.map((data) => {
                return <GeneralRateRow key={data?.desc.replace(/\s+/g, '')} title={data?.desc} total={data?.amount} />
            })}
        </>
    )
}

export default AddonsBlock
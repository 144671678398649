export const getMemberLabelByOrgRentalType = (additionalStr) => {
  return (localStorage.getItem("orgRentalType") &&
    localStorage.getItem("orgRentalType") === "HYBRID") ||
    localStorage.getItem("orgRentalType") === "P2P"
    ? additionalStr
      ? "Member " + additionalStr
      : "Member"
    : additionalStr
    ? "Customer " + additionalStr
    : "Customer";
};
export const isP2POrHybridOrg = (additionalStr) => {
  return (localStorage.getItem("orgRentalType") &&
    localStorage.getItem("orgRentalType") === "HYBRID") ||
    localStorage.getItem("orgRentalType") === "P2P"
    ? true
    : false;
};

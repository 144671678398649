import React from "react";
import LabelWithValue from "../../../../components/UI/label/LabelWithValue";
import { EXTERNAL_CODE_LBL, ModalType } from "../../../../shared/GlobalVar";

const SummaryTopBar = ({ reservationUiModel, showModal, specificPricingData }) => {
  const {
    isPayLater,
    reservationState,
    confirmationCode,
    integResvConfNum,
    enableExternalParameterUpdate,
    customerPaymentUrl,
    lateReservation,
    pnr
  } = reservationUiModel;
  return (
    <div className="res-summary-top-bar">
      <LabelWithValue
        label="Reservation Code"
        value={confirmationCode}
        hasCopyIcon={true}
      />
      <LabelWithValue
        label={EXTERNAL_CODE_LBL.CONFIRMATION_CODE}
        value={integResvConfNum}
        hasCopyIcon={true}
        modalParams={{
          type: enableExternalParameterUpdate && ModalType.EXTERNAL_PARAMETER_UPDATE_MODAL
        }}
        showModal={showModal}
        enableLinkOnLabel={integResvConfNum}
        tooltipText={(integResvConfNum || "") + (integResvConfNum && !enableExternalParameterUpdate ? ". External parameter updates are not allowed for the reservations of non-corporate locations" : "")}
      />
      {pnr && <LabelWithValue
        label="PNR"
        value={pnr}
        hasCopyIcon={true}
      />}
      <LabelWithValue
        label="Payment Type"
        value={confirmationCode ? isPayLater ? "Pay Later" : "Pay Now" : ""}
      />
      <LabelWithValue
        label="Reservation Status"
        value={reservationState}
        customValueParentClass={`${reservationState} ${lateReservation || "false"}`}
        modalParams={{
          type: reservationState === "CANCELLED" && ModalType.CANCEL_REASON_UPDATE_MODAL
        }}
        showModal={showModal}
        enableLinkOnLabel={reservationState === "CANCELLED"}
        copiableValue={customerPaymentUrl}
        hasCopyIcon={false}//{!isNullOrEmpty(customerPaymentUrl)}
        toolTipTextForCopyToClipBoardIcon="Click to copy the payment link to clipboard and manually send it to the customer in case email delivery fails."
        copiableValueLabel="Customer Payment Link"
      />
      <LabelWithValue
        label="One-Time Discount Code"
        value={specificPricingData?.foxDiscount?.discountCode || specificPricingData?.discountCode || null}
        hasCopyIcon={true}
      />
      <LabelWithValue
        label="Company Number / Discount Code"
        value={reservationUiModel?.companyNumber || null}
        hasCopyIcon={true}
      />
    </div>
  );
};

export default SummaryTopBar;

import React, { useEffect, useState, Fragment, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getInsuranceSettingsInfo,
  getInsuranceSettingsInfoReset,
  updateInsuranceSettingsInfo,
  updateInsuranceSettingsInfoReset,
} from "../../../store/actions/index";
import Spinner from "../../../components/UI/Spinner/Spinner";
import PermissionName from "../../../shared/Permissions";
import {
  isNullOrEmpty,
  hasPermission,
  getBasicToastResponse,
  formatAmountValue,
} from "../../../shared/utility";
import { showNotification } from "../../../components/UI/Toaster/Toaster";
import {
  InsuranceSettingsViewModel,
  InsuranceEntityModel,
} from "../../../services/entities/view-models/settings-view-models";
import InsuranceUpdateRequest from "../../../services/entities/requests/InuranceUpdateRequest";
import { getUnitDisplayValue, getInheritedProperties } from "../settingsHelper";
import UpdateInsuranceEntityModal from "./modal/update-insurance-entity-modal";
import ConfirmModal from "../../../components/common/modal/confirm-modal";
import LinkButton from "../../../components/UI/button/link-btn";
import NoRecordFoundComponent from "../../../components/common/pages/no-record-found";
import { COMMON_MSG } from "../../../shared/GlobalVar";
import InheritFromParentConfirmModal from "../modal/inherit-from-parent-confirm-modal";
import ActionIcon from "../../../components/UI/icon/action-icon";

const InsuranceSettings = (props) => {
  const loading = useSelector((state) => state.settingsReducer.loading);
  const currentOrgUnitId = useSelector(
    (state) => state.auth.loggedInUserData.CurrentOrgUnitId
  );
  const insuranceSettingsInfoResponse = useSelector(
    (state) => state.settingsReducer.insuranceSettingsInfoResponse
  );
  const insuranceSettingsInfoUpdateResponse = useSelector(
    (state) => state.settingsReducer.insuranceSettingsInfoUpdateResponse
  );
  const dependentOrgLength = useSelector((state) =>
    state.auth.loggedInUserData.DependantOrgList
      ? state.auth.loggedInUserData.DependantOrgList.length
      : 0
  );
  const dispatch = useDispatch();
  const query = props.location
    ? new URLSearchParams(props.location.search)
    : null;

  const selectedOrgLocationId =
    props.otherData && props.otherData.selectedOrgLocationId
      ? props.otherData.selectedOrgLocationId
      : query
      ? query.get("orgId")
      : currentOrgUnitId;
  const [insuranceSettingsData, setInsuranceSettingsData] = useState(
    new InsuranceSettingsViewModel()
  );
  const [showAddEntityModal, setShowAddEntityModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [
    showConfirmRemoveInheritModal,
    setShowConfirmRemoveInheritModal,
  ] = useState(false);
  const [
    showInheritFromParentConfirmModal,
    setShowInheritFromParentConfirmModal,
  ] = useState(false);
  const [selectedEntity, setSelectedEntity] = useState(
    new InsuranceEntityModel()
  );
  const [settingsType, setSettingsType] = useState(null);
  const hasEditPermission = hasPermission(
    PermissionName.EDIT_INSURANCE_ORG_SETTINGS
  );

  const intervalRef = useRef();
  const [highlightedClass, setHighlightedClass] = useState("");
  const [selectedRow, setSelectedRow] = useState(-1);
  const [timer, setTimer] = useState(0);

  useEffect(() => {
    if (insuranceSettingsInfoResponse && insuranceSettingsInfoUpdateResponse) {
      intervalRef.current = setInterval(() => {
        setTimer((prevTimer) => prevTimer + 1);
      }, 1000);
    }
    if (timer % 8 === 0) {
      applyHighlightedColor(null);
      clearInterval(intervalRef.current);
    }
  }, [timer]);

  useEffect(() => {
    getInsuranceSettingData();
  }, [selectedOrgLocationId]);
  useEffect(() => {
    if (!insuranceSettingsInfoResponse) {
      return;
    }
    if (insuranceSettingsInfoResponse.StatusCode === 0) {
      if (showInheritFromParentConfirmModal) {
        return;
      }
      setInsuranceSettingsData(
        new InsuranceSettingsViewModel(
          insuranceSettingsInfoResponse.InsuranceSettingsInfo,
          currentOrgUnitId,
          insuranceSettingsInfoResponse.SelectedOrgUnitId,
          insuranceSettingsInfoResponse.InheritedFromParent
        )
      );
    } else {
      showNotification(getBasicToastResponse(insuranceSettingsInfoResponse));
    }
  }, [insuranceSettingsInfoResponse]);

  useEffect(() => {
    if (!insuranceSettingsInfoUpdateResponse) {
      return;
    }
    showNotification(
      getBasicToastResponse(insuranceSettingsInfoUpdateResponse)
    );
    if (insuranceSettingsInfoUpdateResponse.StatusCode === 0) {
      closeAddEntityModal();
      startTimer();
      dispatch(updateInsuranceSettingsInfoReset());
    }
    dispatch(updateInsuranceSettingsInfoReset());
  }, [insuranceSettingsInfoUpdateResponse]);

  const applyHighlightedColor = (selectedRowId) => {
    if (!isNullOrEmpty(selectedRowId)) {
      setSelectedRow(selectedRowId);
      setHighlightedClass("add-edit-row-highlight");
    } else {
      setSettingsType(null);
      setSelectedRow(-1);
      setHighlightedClass("");
    }
  };

  const startTimer = () => {
    setTimer(1);
    intervalRef.current = setInterval(() => {
      setTimer((prevTimer) => prevTimer + 1);
    }, 1000);
  };

  const getInsuranceSettingData = () => {
    const req = {};
    req.SelectedChildOrgUnitId = selectedOrgLocationId;
    dispatch(getInsuranceSettingsInfoReset());
    dispatch(getInsuranceSettingsInfo(req));
  };
  const getAddUpdateEntityModal = (entity, type) => {
    setSelectedEntity(entity || new InsuranceEntityModel());
    setSettingsType(type);
    setShowAddEntityModal(true);
  };
  const closeAddEntityModal = () => {
    setShowAddEntityModal(false);
    setShowConfirmModal(false);
    setShowConfirmRemoveInheritModal(false);
    setShowInheritFromParentConfirmModal(false);
    setSelectedEntity(new InsuranceEntityModel());
  };
  const getConfirmModal = (entity, type) => {
    if (entity) {
      applyHighlightedColor(entity.DummyId);
      setSelectedEntity(entity);
    }
    setSettingsType(type);
    setShowConfirmModal(true);
  };
  const getInheritConfirmModal = (event) => {
    if (event.target.checked) {
      setShowInheritFromParentConfirmModal(true);
    } else {
      setShowConfirmRemoveInheritModal(true);
    }
  };
  const closeConfirmModal = () => {
    if (showConfirmRemoveInheritModal) {
      setShowConfirmRemoveInheritModal(false);
    } else if (showInheritFromParentConfirmModal) {
      setShowInheritFromParentConfirmModal(false);
    } else {
      setShowConfirmModal(false);
      applyHighlightedColor(null);
      setSelectedEntity(new InsuranceEntityModel());
    }
  };
  const addUpdateInsuranceEntity = (updateEntity, settingsType) => {
    const req = JSON.parse(JSON.stringify(insuranceSettingsData));

    if (settingsType === "LIABILITY") {
      applyHighlightedColor(updateEntity.DummyId);
      req.LiabilityData = { ...updateEntity };
    } else if (settingsType === "CDW") {
      applyHighlightedColor(updateEntity.DummyId);
      req.CDWData = { ...updateEntity };
    } else if (settingsType === "OTHER_CDW") {
      if (!updateEntity.DummyId) {
        req.OtherDeductibles.push(updateEntity);
        applyHighlightedColor(req.OtherDeductibles.length);
      } else {
        req.OtherDeductibles.map((entity, index) => {
          if (entity.DummyId === updateEntity.DummyId) {
            applyHighlightedColor(updateEntity.DummyId);
            req.OtherDeductibles[index] = { ...updateEntity };
            return false;
          }
        });
      }
    } else if (settingsType === "AGE_INSURANCE") {
      if (!updateEntity.DummyId) {
        req.AgeBasedInsuranceList.push(updateEntity);
        applyHighlightedColor(req.AgeBasedInsuranceList.length);
      } else {
        req.AgeBasedInsuranceList.map((entity, index) => {
          if (entity.DummyId === updateEntity.DummyId) {
            applyHighlightedColor(updateEntity.DummyId);
            req.AgeBasedInsuranceList[index] = { ...updateEntity };
            return false;
          }
        });
      }
    }
    updateData(req);
  };
  const removeEntity = () => {
    const req = JSON.parse(JSON.stringify(insuranceSettingsData));
    if (settingsType === "OTHER_CDW") {
      const entityListFilter = req.OtherDeductibles.filter(
        (x) => x.DummyId !== selectedEntity.DummyId
      );
      req.OtherDeductibles = [...entityListFilter];
    } else if (settingsType === "AGE_INSURANCE") {
      const entityListFilter = req.AgeBasedInsuranceList.filter(
        (x) => x.DummyId !== selectedEntity.DummyId
      );
      req.AgeBasedInsuranceList = [...entityListFilter];
    }
    setShowConfirmModal(false);
    updateData(req);
  };
  const doInheritFunctionality = () => {
    updateData(
      insuranceSettingsData,
      showConfirmRemoveInheritModal ? false : true
    );
  };
  const updateData = (req, isInheritedFromParent) => {
    const reqObj = {
      ...new InsuranceUpdateRequest(req),
      ...getInheritedProperties(
        currentOrgUnitId,
        req.SelectedChildOrgUnitId || selectedOrgLocationId,
        typeof isInheritedFromParent !== "undefined"
          ? isInheritedFromParent
          : req.InheritedFromParent,
        dependentOrgLength
      ),
    };
    dispatch(updateInsuranceSettingsInfoReset());
    dispatch(updateInsuranceSettingsInfo(reqObj));
  };

  const renderOtherCDWTable = () => {
    return insuranceSettingsData.OtherDeductibles.map((entity, index) => {
      return (
        <tr
          className={
            selectedRow === entity.DummyId && settingsType === "OTHER_CDW"
              ? highlightedClass
              : ""
          }
          key={entity.DummyId}
        >
          <td></td>
          <td className="text-left">{entity.Description}</td>
          <td>{entity.Deductible}</td>
          <td>{getUnitDisplayValue(entity.HourlyUnit)}</td>
          <td>{formatAmountValue(entity.HourlyAmount)}</td>
          <td>{getUnitDisplayValue(entity.DailyUnit)}</td>
          <td>{formatAmountValue(entity.DailyAmount)}</td>
          <td>{getUnitDisplayValue(entity.WeeklyUnit)}</td>
          <td>{formatAmountValue(entity.WeeklyAmount)}</td>
          <td>{getUnitDisplayValue(entity.MonthlyUnit)}</td>
          <td>{formatAmountValue(entity.MonthlyAmount)}</td>

          <td className="text-center">
            {props.isInheritModal ? (
              ""
            ) : (
              <Fragment>
                <ActionIcon
                  iconId={"edit_other_cdw" + entity.DummyId}
                  className="fas fa-pencil-alt pr-2 fs-3"
                  fontColor="text-primary"
                  actionFunction={getAddUpdateEntityModal}
                  disabled={
                    disabled ||
                    !insuranceSettingsData.CDWData.IsOffered ||
                    !hasEditPermission
                  }
                  value="Edit"
                  param1={entity}
                  param2="OTHER_CDW"
                />
                <ActionIcon
                  iconId={"delete_other_cdw" + entity.DummyId}
                  className="fa fa-times fs-3"
                  fontColor="text-danger"
                  actionFunction={getConfirmModal}
                  disabled={
                    disabled ||
                    !insuranceSettingsData.CDWData.IsOffered ||
                    !hasEditPermission
                  }
                  value="Delete"
                  param1={entity}
                  param2="OTHER_CDW"
                />
              </Fragment>
            )}
          </td>
        </tr>
      );
    });
  };
  const renderAgeTable = () => {
    return insuranceSettingsData.AgeBasedInsuranceList.map((entity, index) => {
      return (
        <tr
          className={
            selectedRow === entity.DummyId && settingsType === "AGE_INSURANCE"
              ? highlightedClass
              : ""
          }
          key={entity.DummyId}
        >
          <td>{entity.IsOffered ? "Yes" : "No"}</td>
          <td>{entity.InsuranceMinAge}</td>
          <td>{entity.InsuranceMaxAge}</td>
          <td>{entity.InsuranceAgeCode}</td>
          <td className="text-left">{entity.Description}</td>
          <td>{getUnitDisplayValue(entity.HourlyUnit)}</td>
          <td>{formatAmountValue(entity.HourlyAmount)}</td>
          <td>{getUnitDisplayValue(entity.DailyUnit)}</td>
          <td>{formatAmountValue(entity.DailyAmount)}</td>
          <td>{getUnitDisplayValue(entity.WeeklyUnit)}</td>
          <td>{formatAmountValue(entity.WeeklyAmount)}</td>
          <td>{getUnitDisplayValue(entity.MonthlyUnit)}</td>
          <td>{formatAmountValue(entity.MonthlyAmount)}</td>

          <td className="text-center">
            {props.isInheritModal ? (
              ""
            ) : (
              <Fragment>
                <ActionIcon
                  iconId={"edit_age" + entity.DummyId}
                  className="fas fa-pencil-alt pr-2 fs-3"
                  fontColor="text-primary"
                  actionFunction={getAddUpdateEntityModal}
                  disabled={
                    disabled ||
                    !insuranceSettingsData.CDWData.IsOffered ||
                    !hasEditPermission
                  }
                  value="Edit"
                  param1={entity}
                  param2="AGE_INSURANCE"
                />
                <ActionIcon
                  iconId={"delete_age" + entity.DummyId}
                  className="fa fa-times fs-3"
                  fontColor="text-danger"
                  actionFunction={getConfirmModal}
                  disabled={disabled || !hasEditPermission}
                  value="Delete"
                  param1={entity}
                  param2="AGE_INSURANCE"
                />
              </Fragment>
            )}
          </td>
        </tr>
      );
    });
  };
  const disabled =
    insuranceSettingsData.InheritedFromParent ||
    !hasEditPermission ||
    props.isInheritModal
      ? true
      : false;
  const renderMainLayout = () => {
    return (
      <Fragment>
        <div className="col-lg-12 mb-2  font-weight-bold  d-flex">
          <label className="mr-auto fs-2">Liability:</label>
          {hasEditPermission &&
          !disabled &&
          !insuranceSettingsData.LiabilityData.Description ? (
            <LinkButton
              btnLabel="Add Liability"
              containerClass="pseudo-link pr-1 fs-2 font-weight-bold pl-5"
              iconClass="fas fa-plus-circle iconBeforeLink"
              clicked={(event) => getAddUpdateEntityModal(null, "LIABILITY")}
            />
          ) : (
            ""
          )}
        </div>
        <div
          className="col-lg-12 bb-0 table-container"
          style={{ maxHeight: "60rem" }}
        >
          <table className="table table-striped" id="liability-fee-table">
            <thead className="thead-primary">
              <tr className="table-top-header">
                <th rowSpan="2">Is Offered</th>
                <th rowSpan="2">Description</th>
                <th colSpan="2">Hourly </th>
                <th colSpan="2">Daily</th>
                <th colSpan="2">Weekly</th>
                <th colSpan="2">Monthly</th>
                <th />
              </tr>
              <tr className="table-sub-header">
                <th>Unit</th>
                <th>Rate</th>
                <th>Unit</th>
                <th>Rate</th>
                <th>Unit</th>
                <th>Rate</th>
                <th>Unit</th>
                <th>Rate</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {!insuranceSettingsData.LiabilityData.Description ? (
                <tr>
                  <td colSpan="11">
                    <NoRecordFoundComponent
                      className="border-none"
                      innerClassName="no-setting-record-found"
                      searchMsg="No Record Found"
                      heights="5rem"
                    />
                  </td>
                </tr>
              ) : (
                <tr
                  className={
                    selectedRow === insuranceSettingsData.LiabilityData.DummyId
                      ? highlightedClass
                      : ""
                  }
                >
                  <td>
                    {insuranceSettingsData.LiabilityData.IsOffered
                      ? "Yes"
                      : "No"}
                  </td>
                  <td className="text-left">
                    {insuranceSettingsData.LiabilityData.Description}
                  </td>
                  <td>
                    {getUnitDisplayValue(
                      insuranceSettingsData.LiabilityData.HourlyUnit
                    )}
                  </td>
                  <td>
                    {formatAmountValue(
                      insuranceSettingsData.LiabilityData.HourlyAmount
                    )}
                  </td>
                  <td>
                    {getUnitDisplayValue(
                      insuranceSettingsData.LiabilityData.DailyUnit
                    )}
                  </td>
                  <td>
                    {formatAmountValue(
                      insuranceSettingsData.LiabilityData.DailyAmount
                    )}
                  </td>
                  <td>
                    {getUnitDisplayValue(
                      insuranceSettingsData.LiabilityData.WeeklyUnit
                    )}
                  </td>
                  <td>
                    {formatAmountValue(
                      insuranceSettingsData.LiabilityData.WeeklyAmount
                    )}
                  </td>
                  <td>
                    {getUnitDisplayValue(
                      insuranceSettingsData.LiabilityData.MonthlyUnit
                    )}
                  </td>
                  <td>
                    {formatAmountValue(
                      insuranceSettingsData.LiabilityData.MonthlyAmount
                    )}
                  </td>

                  <td className="text-center">
                    {props.isInheritModal ? (
                      ""
                    ) : (
                      <ActionIcon
                        iconId={
                          "edit_LIABILITY" +
                          insuranceSettingsData.LiabilityData.DummyId
                        }
                        className="fas fa-pencil-alt pr-2 fs-3"
                        fontColor="text-primary"
                        actionFunction={getAddUpdateEntityModal}
                        disabled={disabled}
                        value="Edit"
                        param1={insuranceSettingsData.LiabilityData}
                        param2="LIABILITY"
                      />
                    )}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className="col-lg-12 mb-2  font-weight-bold  d-flex mt-5">
          <label className="mr-auto fs-2">CDW:</label>
          {hasEditPermission &&
          !disabled &&
          (!insuranceSettingsData.CDWData.Description ||
            !insuranceSettingsData.OtherDeductibles.length ||
            insuranceSettingsData.OtherDeductibles.length < 4) ? (
            <LinkButton
              btnLabel={
                !insuranceSettingsData.CDWData.Description
                  ? "Add CDW"
                  : "Add Other CDW"
              }
              containerClass="pseudo-link pr-1 fs-2 font-weight-bold pl-5"
              iconClass="fas fa-plus-circle iconBeforeLink"
              clicked={(event) =>
                !insuranceSettingsData.CDWData.Description
                  ? getAddUpdateEntityModal(null, "CDW")
                  : getAddUpdateEntityModal(null, "OTHER_CDW")
              }
            />
          ) : (
            ""
          )}
        </div>
        <div
          className="col-lg-12 bb-0 table-container"
          style={{ maxHeight: "60rem" }}
        >
          <table className="table table-striped" id="cdw-fee-table">
            <thead className="thead-primary">
              <tr className="table-top-header">
                <th rowSpan="2">Is Offered</th>
                <th rowSpan="2">Description</th>
                <th rowSpan="2">Deductibles</th>
                <th colSpan="2">Hourly </th>
                <th colSpan="2">Daily</th>
                <th colSpan="2">Weekly</th>
                <th colSpan="2">Monthly</th>
                <th />
              </tr>
              <tr className="table-sub-header">
                <th>Unit</th>
                <th>Rate</th>
                <th>Unit</th>
                <th>Rate</th>
                <th>Unit</th>
                <th>Rate</th>
                <th>Unit</th>
                <th>Rate</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {!insuranceSettingsData.CDWData.Description ? (
                <tr>
                  <td colSpan="12">
                    <NoRecordFoundComponent
                      className="border-none"
                      innerClassName="no-setting-record-found"
                      searchMsg="No Record Found"
                      heights="5rem"
                    />
                  </td>
                </tr>
              ) : (
                <Fragment>
                  <tr
                    className={
                      selectedRow === insuranceSettingsData.CDWData.DummyId
                        ? highlightedClass
                        : ""
                    }
                  >
                    <td>
                      {insuranceSettingsData.CDWData.IsOffered ? "Yes" : "No"}
                    </td>
                    <td className="text-left">
                      {insuranceSettingsData.CDWData.Description}
                    </td>
                    <td>{insuranceSettingsData.CDWData.Deductible}</td>
                    <td>
                      {getUnitDisplayValue(
                        insuranceSettingsData.CDWData.HourlyUnit
                      )}
                    </td>
                    <td>
                      {formatAmountValue(
                        insuranceSettingsData.CDWData.HourlyAmount
                      )}
                    </td>
                    <td>
                      {getUnitDisplayValue(
                        insuranceSettingsData.CDWData.DailyUnit
                      )}
                    </td>
                    <td>
                      {formatAmountValue(
                        insuranceSettingsData.CDWData.DailyAmount
                      )}
                    </td>
                    <td>
                      {getUnitDisplayValue(
                        insuranceSettingsData.CDWData.WeeklyUnit
                      )}
                    </td>
                    <td>
                      {formatAmountValue(
                        insuranceSettingsData.CDWData.WeeklyAmount
                      )}
                    </td>
                    <td>
                      {getUnitDisplayValue(
                        insuranceSettingsData.CDWData.MonthlyUnit
                      )}
                    </td>
                    <td>
                      {formatAmountValue(
                        insuranceSettingsData.CDWData.MonthlyAmount
                      )}
                    </td>
                    <td className="text-center">
                      {props.isInheritModal ? (
                        ""
                      ) : (
                        <ActionIcon
                          iconId={
                            "edit_CDW" + insuranceSettingsData.CDWData.DummyId
                          }
                          className="fas fa-pencil-alt pr-2 fs-3"
                          fontColor="text-primary"
                          actionFunction={getAddUpdateEntityModal}
                          disabled={disabled}
                          value="Edit"
                          param1={insuranceSettingsData.CDWData}
                          param2="CDW"
                        />
                      )}
                    </td>
                  </tr>
                  {renderOtherCDWTable()}
                </Fragment>
              )}
            </tbody>
          </table>
        </div>
        <div className="col-lg-12 mb-2  font-weight-bold  d-flex mt-5">
          <label className="mr-auto fs-2">Age-Based Fee Settings:</label>
          {hasEditPermission &&
          !disabled &&
          (!insuranceSettingsData.AgeBasedInsuranceList.length ||
            insuranceSettingsData.AgeBasedInsuranceList.length < 3) ? (
            <LinkButton
              btnLabel="Add Aged-Based Fee"
              containerClass="pseudo-link pr-1 fs-2 font-weight-bold pl-5"
              iconClass="fas fa-plus-circle iconBeforeLink"
              clicked={(event) =>
                getAddUpdateEntityModal(null, "AGE_INSURANCE")
              }
            />
          ) : (
            ""
          )}
        </div>
        <div
          className="col-lg-12 bb-0 table-container"
          style={{ maxHeight: "60rem" }}
        >
          <table className="table table-striped" id="age-based-fee-table">
            <thead className="thead-primary">
              <tr className="table-top-header">
                <th rowSpan="2">Is Offered</th>
                <th rowSpan="2">Min Age</th>
                <th rowSpan="2">Max Age</th>
                <th rowSpan="2">Code</th>
                <th rowSpan="2">Description</th>
                <th colSpan="2">Hourly </th>
                <th colSpan="2">Daily</th>
                <th colSpan="2">Weekly</th>
                <th colSpan="2">Monthly</th>
                <th />
              </tr>
              <tr className="table-sub-header">
                <th>Unit</th>
                <th>Rate</th>
                <th>Unit</th>
                <th>Rate</th>
                <th>Unit</th>
                <th>Rate</th>
                <th>Unit</th>
                <th>Rate</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {insuranceSettingsData.AgeBasedInsuranceList.length > 0 ? (
                renderAgeTable()
              ) : (
                <tr>
                  <td colSpan="14">
                    <NoRecordFoundComponent
                      className="border-none"
                      innerClassName="no-setting-record-found"
                      searchMsg="No Record Found"
                      heights="5rem"
                    />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </Fragment>
    );
  };
  return props.isInheritModal ? (
    renderMainLayout()
  ) : (
    <div className="col-lg-12 pt-3 pl-0" id="insurance-settings">
      <div className="card">
        <div className="card-body">
          <div className="form-header row col-lg-12 p-0">
            <h4 className="text-uppercase mr-auto icon-display-none">
              <i className="fas fa-car-crash"></i>
              Insurance & Age-Based Fee Settings
            </h4>
            {insuranceSettingsData.DisplayInherited ? (
              <div className="icheck-primary pl-2">
                <input
                  type="checkbox"
                  id="InheritedFromParent"
                  name="InheritedFromParent"
                  checked={insuranceSettingsData.InheritedFromParent}
                  onClick={(event) => getInheritConfirmModal(event)}
                  disabled={!hasEditPermission}
                />
                <label
                  htmlFor="InheritedFromParent"
                  className="inheritedCheckBoxLabel"
                >
                  <span>Inherit from Primary Location</span>
                </label>
              </div>
            ) : (
              ""
            )}
          </div>
          {renderMainLayout()}
        </div>
        <div style={{ height: "2.5rem" }}>&nbsp;</div>
      </div>
      {loading ? <Spinner /> : ""}
      {showAddEntityModal ? (
        <UpdateInsuranceEntityModal
          showModal={showAddEntityModal}
          closeModal={closeAddEntityModal}
          selectedEntity={selectedEntity}
          addUpdateEntity={addUpdateInsuranceEntity}
          settingsType={settingsType}
        />
      ) : (
        ""
      )}
      {showConfirmModal || showConfirmRemoveInheritModal ? (
        <ConfirmModal
          body={
            showConfirmModal
              ? COMMON_MSG.DeleteRecordMsg
              : COMMON_MSG.RemoveInheritParentConfirm
          }
          showModal={showConfirmModal || showConfirmRemoveInheritModal}
          closeModal={closeConfirmModal}
          doConfirmFunctionality={
            showConfirmModal ? removeEntity : doInheritFunctionality
          }
        />
      ) : (
        ""
      )}
      {showInheritFromParentConfirmModal ? (
        <InheritFromParentConfirmModal
          showModal={showInheritFromParentConfirmModal}
          closeModal={closeConfirmModal}
          doInheritFunctionality={doInheritFunctionality}
          currentOrgUnitId={currentOrgUnitId}
          modalWidth="85rem"
          componentName={InsuranceSettings}
        />
      ) : (
        ""
      )}
    </div>
  );
};
export default InsuranceSettings;

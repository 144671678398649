const isNullOrEmpty = (dataVal) => {
  let nullOrEmpty = false;
  if (
    dataVal === null ||
    dataVal === "" ||
    dataVal === "undefined" ||
    dataVal === undefined
  ) {
    nullOrEmpty = true;
  }
  return nullOrEmpty;
};

export const getLocalStoragePrefix = (requestName, loginMode) => {
  const currentOrgUnitId =
    localStorage.getItem("currentOrgUnitId") || "10002624203";
  const loggedInUserId = localStorage.getItem("loggedInUserId");
  loginMode = loginMode ? loginMode : localStorage.getItem("loginMode") || "";
  if (!isNullOrEmpty(loginMode)) {
    return loginMode + "_" + requestName;
  }

  return currentOrgUnitId + "_" + loggedInUserId + "_" + requestName;
};

export const storeRequest = (requestName, storeRequestObj) => {
  requestName = getLocalStoragePrefix(requestName);
  localStorage.setItem(requestName, JSON.stringify(storeRequestObj));
};

export const getDataFromStorage = (requestName) => {
  requestName = getLocalStoragePrefix(requestName);
  const retrievedObject = localStorage.getItem(requestName);
  return retrievedObject ? JSON.parse(retrievedObject) : null;
};

export const removeLocalStorageItems = (prefix, deleteAllMatchWithPrefix) => {
  if (deleteAllMatchWithPrefix) {
    for (let item in localStorage) {
      if (item.substring(0, prefix.length) === prefix) {
        localStorage.removeItem(item);
      }
    }
  } else {
    localStorage.removeItem(prefix);
  }
};

export const setDataToLocalStorage = (storeName, storeValue) => {
  localStorage.setItem(storeName, JSON.stringify(storeValue));
};
export const getLocalStorageData = (storeName) => {
  return localStorage.getItem(storeName)
    ? JSON.parse(localStorage.getItem(storeName))
    : null;
};

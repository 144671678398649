export const FreeCreditModel = function () {
  this.MemberId = null;
  this.Amount = null;
  this.ReservationCode = null;
  this.PickUpLocationId = null;
  this.ReturnLocationId = null;
  this.Reason = null;
  this.SubReason = null;
  this.Notes = null;
  this.Password = null;
  this.OverrideMaxValidation = false;
  this.OverrideLORLimit = false;
  this.OverrideEmail = false;
  this.StartDate = null;
  this.EndDate = null;
  this.TotalCharges = null;
  this.TotalCreditAmount = null;
};

export const CreditGrantHistoryInfos = function () {
  this.Type = null;
  this.SubType = null;
  this.ReservationCode = null;
  this.ReservationStartDate = null;
  this.ReservationEndDate = null;
  this.Notes = null;
  this.SubCategory = null;
  this.Amount = null;
  this.BorrowerName = null;
  this.TotalCharges = null;
  this.TotalReversedAmount = null;
  this.PickUpLocationId = null;
  this.ReturnLocationId = null;
  this.ActivityLogId = null;
  this.PickUpLocationDisplayText = null;
  this.ReturnLocationDisplayText = null;
  this.Date = null;
  this.ProvidedBy = null;
  this.SubCategoryDisplayText = null;
  this.TypeDisplayText = null;
  this.SubTypeDisplayText = null;
  this.TotalGrantedAmount = null;
  this.Status = null;
};

export const ReverseCreditModel = function () {
  this.ParentActivityLogId = null;
  this.Amount = null;
  this.Reason = null;
  this.Notes = null;
  this.Password = null;
  this.OverrideEmail = false;
};

import React from 'react';
import FoxReservationDefaultPage from '../../../components/common/pages/fox-res-default-page';
import { LICENSE } from '../../../shared/AppImages';
import VehicleInfoContainer from '../fox-reservation/vehicle/component/vehicle-info-container';
import ClassicPricingTable from './classic-pricing-table';
import ModernPricingTable from './modern-pricing-table';

function PricingBlock(props) {
    const { pricingData, currentPayType, selectedVehicleClass, customerFreeCredit, reservationStatus,
        isClassicPricing,
        //setIsClassicPricing
    } = props;
    return (
        pricingData ?
            <>
                {selectedVehicleClass ?
                    <div className="common-res-container">
                        <VehicleInfoContainer vehicleClass={selectedVehicleClass} showFeatureAttr={false} />
                    </div> : ""}
                {!isClassicPricing ?
                    <ModernPricingTable
                        currentPayType={currentPayType}
                        pricingData={pricingData}
                        customerFreeCredit={customerFreeCredit}
                        reservationStatus={reservationStatus}
                    />
                    :
                    <ClassicPricingTable
                        currentPayType={currentPayType}
                        pricingData={pricingData}
                        customerFreeCredit={customerFreeCredit}
                        reservationStatus={reservationStatus}
                    />
                }
            </>
            : <FoxReservationDefaultPage
                iconContent={LICENSE}
                title="No Summary"
                msg="Please select a vehicle to see summary"
            />

    )
}

export default PricingBlock
import { API_KEY, PARTNER_CODE } from "../../../../../config/config";
import { create_UUID } from "../../../../containers/Reservation/fox-reservation/fox-res-helper";
import { getCurrentServerDate } from "../../../../shared/utility";

export const FoxRequestHeader = function (data) {
    if (!data) data = {};
    this.requestId = create_UUID();
    this.tokenId = localStorage.getItem("token");
    this.requestDateTime =getCurrentServerDate("YYYY-MM-DDTHH:mm:ss.SSS");
    this.requestType = data.requestType || "GET";
    this.partnerCode=PARTNER_CODE;
    this.apiKey=API_KEY;
    this.remoteAddress = "";
    this.referrerUrl = "";
    this.sessionId = "";
    this.userAgent = "";
    this.deviceId = "";
    this.channelType = "WEB";
};
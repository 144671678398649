import React, { Fragment, useState } from "react";
import Moment from "moment";
import moment from "moment";
import DateTimePicker from "react-widgets/lib/DateTimePicker";
import {
  getDateOnlyString,
  formatPricingValue,
  convertDateTimeString,
} from "../../../shared/utility";
import NoRecordFoundComponent from "../../../components/common/pages/no-record-found";
import ActionIcon from "../../../components/UI/icon/action-icon";
import { useEffect } from "react";
import PreventTransitionPrompt from "../../../components/Navigations/prevent-navigation/prevent-transition-prompt";
import { getDateTimeFormatString, getDateTimePlaceholder } from "../../../components/common/common-helper";

export const SpecialRateList = (props) => {
  const {
    rateSettingsData,
    isEditMode,
    disabled,
    addUpdateEntity,
    getConfirmModal,
  } = props;
  const [isBlocking, setIsBlocking] = useState(false);
  const isMileageBasedRate = rateSettingsData.MileageBasedPricing
    ? "icon-disabled"
    : "";

  useEffect(() => {
    setIsBlocking(false);
  }, [isEditMode]);

  const handleOnChange = (event, type, entity, ctrlName) => {
    const value =
      type === "DATE_PICKER" && event !== "Invalid date"
      ? event? moment(event).format("MM:DD:YYYY:HH:mm"): event
      : event.target.value;
    if (type === "DATE_PICKER") {
      entity[ctrlName] = value;
    } else {
      entity[event.target.name] = value;
    }
    if (!props.disable) {
      addUpdateEntity(entity);
    }
    setIsBlocking(true);
  };
  const incrementRate = (entity, controlName, inCrementValue) => {
    let newVal = 0;
    const oldVal = entity[controlName] || 0;
    newVal = parseInt(oldVal, 10) + parseInt(inCrementValue, 10);
    entity[controlName] = newVal < 0 ? 0 : newVal;
    if (!props.disable) {
      addUpdateEntity(entity);
    }
    setIsBlocking(true);
  };
  const incrementDate = (entity, controlName, inCrementValue) => {
    const oldDate = getDateOnlyString(entity[controlName]);
    const newDate = Moment(oldDate).add(inCrementValue, "days").toDate();
    entity[controlName] = Moment(newDate).format("MM:DD:YYYY:hh:mm");
    if (!props.disable) {
      addUpdateEntity(entity);
    }
    setIsBlocking(true);
  };
  const renderUpDownIcon = (isDown, entity, controlName, level) => {
    let iconClass = isDown
      ? "fa-long-arrow-alt-down down"
      : "fa-long-arrow-alt-up up";
    let iconSizeClass = level == 1 ? "fs-2-5" : level == 2 ? "fs-2-0" : "fs-5";
    let incrementValue = level == 1 ? -10 : level == 2 ? -5 : -1;
    incrementValue = isDown ? incrementValue : Math.abs(incrementValue);
    return (
      <i
        class={`fa ${iconClass} ${iconSizeClass} ${isMileageBasedRate}`}
        onClick={() => {
          incrementRate(entity, controlName, incrementValue);
        }}
      ></i>
    );
  };
  const getSpecialDateCtrl = (entity, controlName) => {
    return (
      <div className="sp-input-ctl date-ctl">
        <i
          className={`fa fa-minus-circle text-danger fs-5 ${isMileageBasedRate}`}
          onClick={() => {
            incrementDate(entity, controlName, -1);
          }}
        />
        <DateTimePicker
          dropUp
          time={false}
          format={getDateTimeFormatString()}
          placeholder={getDateTimePlaceholder()}
          value={
            entity[controlName]
              ? moment(entity[controlName], "MM:DD:YYYY:HH:mm").toDate()
              : null
          }
          onChange={(event) =>
            handleOnChange(event, "DATE_PICKER", entity, controlName)
          }
          dateFormat="D"
          disabled={rateSettingsData.MileageBasedPricing}
        />
        <i
          className={`fa fa-plus-circle text-primary fs-5 ${isMileageBasedRate}`}
          onClick={() => {
            incrementDate(entity, controlName, 1);
          }}
        />
      </div>
    );
  };
  const getSpecialTbCtrl = (entity, controlName) => {
    let downArrowCommonProps = [true, entity, controlName];
    let upArrowCommonProps = [false, entity, controlName];
    return (
      <div className="sp-input-ctl">
        <div
          className="icon-div"
          disable={rateSettingsData.MileageBasedPricing}
        >
          {renderUpDownIcon(...downArrowCommonProps, 1)}
          {renderUpDownIcon(...downArrowCommonProps, 2)}
          {renderUpDownIcon(...downArrowCommonProps, 3)}
        </div>
        <input
          type="number"
          className="mr-2 ml-2"
          name={controlName}
          value={entity[controlName] || ""}
          onChange={(event) => handleOnChange(event, null, entity)}
          disabled={rateSettingsData.MileageBasedPricing}
        />
        <div className="icon-div">
          {renderUpDownIcon(...upArrowCommonProps, 3)}
          {renderUpDownIcon(...upArrowCommonProps, 2)}
          {renderUpDownIcon(...upArrowCommonProps, 1)}
        </div>
      </div>
    );
  };
  const renderAdjustPresetRate = () => {
    if (rateSettingsData.SpecialPriceList.length === 0) {
      return (
        <tr>
          <td colSpan="8">
            <NoRecordFoundComponent
              className="border-none"
              innerClassName="no-setting-record-found"
              searchMsg="No Record Found"
              heights="5rem"
            />
          </td>
        </tr>
      );
    } else {
      return rateSettingsData.SpecialPriceList.map((entity, index) => {
        return (
          <tr key={index + "SP"}>
            <td>
              {isEditMode
                ? getSpecialDateCtrl(entity, "StartDate")
                : convertDateTimeString(entity.StartDate, "--")}
            </td>
            <td>
              {isEditMode
                ? getSpecialDateCtrl(entity, "EndDate")
                : convertDateTimeString(entity.EndDate, "--")}
            </td>
            <td>
              {isEditMode
                ? getSpecialTbCtrl(entity, "RatePerHour")
                : formatPricingValue(entity.RatePerHour)}
            </td>
            <td>
              {isEditMode
                ? getSpecialTbCtrl(entity, "RatePerDay")
                : formatPricingValue(entity.RatePerDay)}
            </td>
            <td>
              {isEditMode
                ? getSpecialTbCtrl(entity, "RatePerWeekendDay")
                : formatPricingValue(entity.RatePerWeekendDay)}
            </td>
            <td>
              {isEditMode
                ? getSpecialTbCtrl(entity, "RatePerWeek")
                : formatPricingValue(entity.RatePerWeek)}
            </td>
            <td>
              {isEditMode
                ? getSpecialTbCtrl(entity, "RatePerMonth")
                : formatPricingValue(entity.RatePerMonth)}
            </td>
            <td className="text-center">
              {props.isInheritModal ? (
                ""
              ) : (
                <ActionIcon
                  iconId={"delete_sp" + index}
                  className="fa fa-times fs-3"
                  fontColor="text-danger"
                  actionFunction={getConfirmModal}
                  disabled={disabled || rateSettingsData.MileageBasedPricing}
                  value="Delete"
                  param1={entity}
                />
              )}
            </td>
          </tr>
        );
      });
    }
  };
  return (
    <Fragment>
      <PreventTransitionPrompt when={isBlocking} />
      <table className="table table-striped special-rate-table">
        <thead className="thead-primary">
          <tr>
            <th>Start Date</th>
            <th>End Date</th>
            <th>Hourly Rate</th>
            <th>Daily Rate</th>
            <th>Weekend Rate</th>
            <th>Weekly Rate</th>
            <th>Monthly Rate</th>
            <th />
          </tr>
        </thead>
        <tbody className={isEditMode ? "background-edit" : ""}>
          {renderAdjustPresetRate()}
        </tbody>
      </table>
    </Fragment>
  );
};
export default SpecialRateList;

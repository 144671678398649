import * as actionTypes from "../actions/actionTypes";
import { reducerUpdateObject } from "./reducer.utility";

const initialState = {
  fleetForecastResponse: null,
  updateFleetForecastResponse: null,
  fleetClassGroupReponse: null,
  updateFleetClassGroupReponse: null,
  fleetUtilizationLogResponse: null,
  loading: false,
  error: null,
};

const getFleetUtilizationForecastStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
const getFleetUtilizationForecastSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    fleetForecastResponse: action.fleetForecastResponse,
    updateFleetForecastResponse: null,
    error: null,
    loading: false,
  });
};
const getFleetUtilizationForecastFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false,
  });
};
const getFleetUtilizationForecastReset = (state, action) => {
  return reducerUpdateObject(state, {
    fleetForecastResponse: null,
    updateFleetForecastResponse: null,
  });
};

const updateFleetUtilizationForecastStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
const updateFleetUtilizationForecastSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    updateFleetForecastResponse: action.updateFleetForecastResponse,
    error: null,
    loading: false,
  });
};
const updateFleetUtilizationForecastFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false,
  });
};
const updateFleetUtilizationForecastReset = (state, action) => {
  return reducerUpdateObject(state, { updateFleetForecastResponse: null });
};

const getFleetClassGroupInfoStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
const getFleetClassGroupInfoSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    fleetClassGroupReponse: action.fleetClassGroupReponse,
    updateFleetForecastResponse: null,
    error: null,
    loading: false,
  });
};
const getFleetClassGroupInfoFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false,
  });
};
const getFleetClassGroupInfoReset = (state, action) => {
  return reducerUpdateObject(state, {
    fleetClassGroupReponse: null,
    updateFleetForecastResponse: null,
  });
};

const updateFleetClassGroupStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
const updateFleetClassGroupSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    updateFleetClassGroupReponse: action.updateFleetClassGroupReponse,
    error: null,
    loading: false,
  });
};
const updateFleetClassGroupFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false,
  });
};
const updateFleetClassGroupReset = (state, action) => {
  return reducerUpdateObject(state, {
    fleetClassGroupReponse: null,
    updateFleetClassGroupReponse: null,
  });
};

const getFleetUtilizationLogsStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
const getFleetUtilizationLogsSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    fleetUtilizationLogResponse: action.fleetUtilizationLogResponse,
    error: null,
    loading: false,
  });
};
const getFleetUtilizationLogsFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false,
  });
};
const getFleetUtilizationLogsReset = (state, action) => {
  return reducerUpdateObject(state, {
    fleetUtilizationLogResponse: null,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_FLEET_UTILIZATION_FORECAST_START:
      return getFleetUtilizationForecastStart(state, action);
    case actionTypes.GET_FLEET_UTILIZATION_FORECAST_SUCCESS:
      return getFleetUtilizationForecastSuccess(state, action);
    case actionTypes.GET_FLEET_UTILIZATION_FORECAST_FAIL:
      return getFleetUtilizationForecastFail(state, action);
    case actionTypes.GET_FLEET_UTILIZATION_FORECAST_RESET:
      return getFleetUtilizationForecastReset(state, action);

    case actionTypes.UPDATE_FLEET_UTILIZATION_FORECAST_START:
      return updateFleetUtilizationForecastStart(state, action);
    case actionTypes.UPDATE_FLEET_UTILIZATION_FORECAST_SUCCESS:
      return updateFleetUtilizationForecastSuccess(state, action);
    case actionTypes.UPDATE_FLEET_UTILIZATION_FORECAST_FAIL:
      return updateFleetUtilizationForecastFail(state, action);
    case actionTypes.UPDATE_FLEET_UTILIZATION_FORECAST_RESET:
      return updateFleetUtilizationForecastReset(state, action);

    case actionTypes.GET_DEFAULT_FLEET_CLASS_GROUP_START:
      return getFleetClassGroupInfoStart(state, action);
    case actionTypes.GET_DEFAULT_FLEET_CLASS_GROUP_SUCCESS:
      return getFleetClassGroupInfoSuccess(state, action);
    case actionTypes.GET_DEFAULT_FLEET_CLASS_GROUP_FAIL:
      return getFleetClassGroupInfoFail(state, action);
    case actionTypes.GET_DEFAULT_FLEET_CLASS_GROUP_RESET:
      return getFleetClassGroupInfoReset(state, action);

    case actionTypes.UPDATE_FLEET_CLASS_GROUP_START:
      return updateFleetClassGroupStart(state, action);
    case actionTypes.UPDATE_FLEET_CLASS_GROUP_SUCCESS:
      return updateFleetClassGroupSuccess(state, action);
    case actionTypes.UPDATE_FLEET_CLASS_GROUP_FAIL:
      return updateFleetClassGroupFail(state, action);
    case actionTypes.UPDATE_FLEET_CLASS_GROUP_RESET:
      return updateFleetClassGroupReset(state, action);

    case actionTypes.GET_FLEET_UTILIZATION_LOGS_START:
      return getFleetUtilizationLogsStart(state, action);
    case actionTypes.GET_FLEET_UTILIZATION_LOGS_SUCCESS:
      return getFleetUtilizationLogsSuccess(state, action);
    case actionTypes.GET_FLEET_UTILIZATION_LOGS_FAIL:
      return getFleetUtilizationLogsFail(state, action);
    case actionTypes.GET_FLEET_UTILIZATION_LOGS_RESET:
      return getFleetUtilizationLogsReset(state, action);

    default:
      return state;
  }
};
export default reducer;

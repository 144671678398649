import React from 'react'
import { NoUserPhoto } from '../../../shared/AppImages'
import { Position } from '../../../shared/utility/enum-utility'
import CommonImageTitle from '../component/common-image-title'
import CommonTitle from '../component/common-title'

function ResMemProfile() {
    return (
        <div className='common-res-container'>
            <CommonImageTitle
                title="Rokon Always Rocks"
                imgSrc=""
                defaultImage={NoUserPhoto}
                classNames="common-title"
            />
            <CommonTitle
                title="+1 123 456 7890"
                iconClass="fa fa-phone"
                iconPosition={Position.LEFT}
                withIcon={true}
            />
            <CommonTitle
                title="johndoe@gmail.com"
                iconClass="fa fa-envelope"
                iconPosition={Position.LEFT}
                withIcon={true}
            />
            <CommonTitle
                title="01/01/1990"
                iconClass="fas fa-calendar-alt"
                iconPosition={Position.LEFT}
                withIcon={true}
            />
            <CommonTitle
                title="1 World Way, Los Angeles, CA 90045, United States"
                iconClass="fa fa-map-marker-alt"
                iconPosition={Position.LEFT}
                withIcon={true}
            />
        </div>
    )
}

export default ResMemProfile
import * as actionTypes from "../actions/actionTypes";
import { reducerUpdateObject } from "./reducer.utility";

const initialState = {
  addonsData: null,
  loading: false
};

const getAddOnsStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};

const getAddOnsSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    addonsData: action.addonsData,
    error: null,
    loading: false
  });
};

const getAddOnsFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false
  });
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ADDONS_START:
      return getAddOnsStart(state, action);
    case actionTypes.GET_ADDONS_SUCCESS:
      return getAddOnsSuccess(state, action);
    case actionTypes.GET_ADDONS_FAIL:
      return getAddOnsFail(state, action);

    default:
      return state;
  }
};
export default reducer;

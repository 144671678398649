import { LandmarkSearchViewModel } from "./landmark-search-view-model";
import { ShuttleSearchViewModel } from "./shuttle-search-view-model";

const VicinityViewModel = function (data) {
  data = data || {};
  const shuttleList = [];
  if (data.shuttleSummaries) {
    data.shuttleSummaries.map((entity) => {
      shuttleList.push(new ShuttleSearchViewModel(entity));
    });
  }
  const landmarkList = [];
  if (data.landmarkSummaries) {
    data.landmarkSummaries.map((entity) => {
      landmarkList.push(new LandmarkSearchViewModel(entity));
    });
  }
  this.shuttleSummaries = shuttleList;
  this.landmarkSummaries = landmarkList;
  this.latitude=data.latitude || null;
  this.longitude=data.longitude || null;
};
export default VicinityViewModel;

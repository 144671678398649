import { getHourMinSecFromMilliSecond } from "../../../../shared/utility/shuttle-app-utility";

export const ShuttleTripViewModel = function (data) {
  if (!data) data = {};
  this.tripCode = data.tripCode || null;
  this.shuttleTripId = data.shuttleTripId || null;
  this.locationCode = data.locationCode || null;
  this.locationDisplayName = data.locationDisplayName || null;
  this.shuttleCode = data.shuttleCode || null;
  this.shuttleId = data.shuttleId || null;
  this.fullName = data.fullName || null;
  this.badgeNo = data.badgeNo || null;
  this.personId = data.personId || null;
  this.tripStartDateTimeInMillis = data.tripStartDateTimeInMillis || 0;
  this.tripDisplayStartDateTime = data.tripDisplayStartDateTime || "";
  this.tripEndDateTimeInMillis = data.tripEndDateTimeInMillis || 0;
  this.tripDisplayEndDateTime = data.tripDisplayEndDateTime || "";
  this.tripDurationInMillis = data.tripDurationInMillis;
  this.tripDuration = getHourMinSecFromMilliSecond(data.tripDurationInMillis);
  this.tripAvgSpeedInMph = data.tripAvgSpeedInMph || null;
  this.speedUnit = data.speedUnit ? data.speedUnit.toLowerCase() : "mph";
  this.averageSpeed = data.tripDisplayAvgSpeed ? data.tripDisplayAvgSpeed.toFixed(2) + " " + this.speedUnit : "--";
  this.tripMaxSpeedInMph = data.tripMaxSpeedInMph || null;
  this.maxSpeed = data.tripDisplayMaxSpeed ? data.tripDisplayMaxSpeed.toFixed(2) + " " + this.speedUnit : "--";
  this.tripDistanceInMiles = data.tripDistanceInMiles || null;
  this.distanceUnit = data.distanceUnit ? data.distanceUnit.toLowerCase() : "mi";
  this.distance = data.tripDisplayDistance ? data.tripDisplayDistance.toFixed(2) + " " + this.distanceUnit : "--";
  this.tripAlerts = data.tripAlerts
  this.tripTimeZone = data.tripTimeZone
};

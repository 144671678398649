const InuranceUpdateRequest = function (data) {
  if (!data) data = {};
  const liabilityData = data.LiabilityData || {};
  const cdwData = data.CDWData || {};
  const otherDeductibles = data.OtherDeductibles || [];
  const ageBasedInsuranceList = data.AgeBasedInsuranceList || [];

  this.SelectedChildOrgUnitId = data.SelectedChildOrgUnitId || null;

  this.OfferLiability = liabilityData.IsOffered;
  this.LiabilityDescription = liabilityData.Description;
  this.LiabilityInsuranceHourlyUnit = liabilityData.HourlyUnit;
  this.LiabilityInsuranceHourlyCost = liabilityData.HourlyAmount;
  this.LiabilityInsuranceUnit = liabilityData.DailyUnit;
  this.LiabilityInsuranceCost = liabilityData.DailyAmount;
  this.LiabilityInsuranceWeeklyUnit = liabilityData.WeeklyUnit;
  this.LiabilityInsuranceWeeklyCost = liabilityData.WeeklyAmount;
  this.LiabilityInsuranceMonthlyUnit = liabilityData.MonthlyUnit;
  this.LiabilityInsuranceMonthlyCost = liabilityData.MonthlyAmount;

  this.OfferCdw = cdwData.IsOffered;
  this.CdwDescription = cdwData.Description;
  this.Deductible = cdwData.Deductible;
  this.CdwHourlyUnit = cdwData.HourlyUnit;
  this.CdwHourlyCost = cdwData.HourlyAmount;
  this.CdwUnit = cdwData.DailyUnit;
  this.CdwCost = cdwData.DailyAmount;
  this.CdwWeeklyUnit = cdwData.WeeklyUnit;
  this.CdwWeeklyCost = cdwData.WeeklyAmount;
  this.CdwMonthlyUnit = cdwData.MonthlyUnit;
  this.CdwMonthlyCost = cdwData.MonthlyAmount;

  otherDeductibles.map((entity, index) => {
    this["DeductibleOption" + (index + 1)] = entity.Deductible;
    this["DeductibleOption" + (index + 1) + "HourlyUnit"] = entity.HourlyUnit;
    this["DeductibleOption" + (index + 1) + "HourlyPrice"] =
      entity.HourlyAmount;
    this["DeductibleOption" + (index + 1) + "Unit"] = entity.DailyUnit;
    this["DeductibleOption" + (index + 1) + "Price"] = entity.DailyAmount;
    this["DeductibleOption" + (index + 1) + "WeeklyUnit"] = entity.WeeklyUnit;
    this["DeductibleOption" + (index + 1) + "WeeklyPrice"] =
      entity.WeeklyAmount;
    this["DeductibleOption" + (index + 1) + "MonthlyUnit"] = entity.MonthlyUnit;
    this["DeductibleOption" + (index + 1) + "MonthlyPrice"] =
      entity.MonthlyAmount;
  });
  ageBasedInsuranceList.map((entity, index) => {
    this["InsuranceAge" + (index + 1) + "Offer"] = entity.IsOffered;
    this["InsuranceAge" + (index + 1) + "Min"] = entity.InsuranceMinAge;
    this["InsuranceAge" + (index + 1) + "Max"] = entity.InsuranceMaxAge;
    this["InsuranceAge" + (index + 1) + "Code"] = entity.InsuranceAgeCode;
    this["InsuranceMsg" + (index + 1)] = entity.Description;
    this["InsuranceAge" + (index + 1) + "HourlyUnit"] = entity.HourlyUnit;
    this["InsuranceAge" + (index + 1) + "HourlyFees"] = entity.HourlyAmount;
    this["InsuranceAge" + (index + 1) + "Unit"] = entity.DailyUnit;
    this["InsuranceAge" + (index + 1) + "Fees"] = entity.DailyAmount;
    this["InsuranceAge" + (index + 1) + "WeeklyUnit"] = entity.WeeklyUnit;
    this["InsuranceAge" + (index + 1) + "WeeklyFees"] = entity.WeeklyAmount;
    this["InsuranceAge" + (index + 1) + "MonthlyUnit"] = entity.MonthlyUnit;
    this["InsuranceAge" + (index + 1) + "MonthlyFees"] = entity.MonthlyAmount;
  });
};
export default InuranceUpdateRequest;

import moment from "moment";
import React, { Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { commonAddressChangeHandler } from "../../../components/common/common-helper";
import ConfirmModal from "../../../components/common/modal/confirm-modal";
import PageTopPanel from "../../../components/common/pages/page-top-panel";
import FileUploaderModel from "../../../components/image-uploader/file-uploader-ui-model";
import PreventTransitionPrompt from "../../../components/Navigations/prevent-navigation/prevent-transition-prompt";
import OverrideComponent from "../../../components/UI/overrides/override-component";
import { getOverrideInputControls } from "../../../components/UI/overrides/override-input";
import Spinner from "../../../components/UI/Spinner/Spinner";
import { showNotification } from "../../../components/UI/Toaster/Toaster";
import ModalInfo from "../../../services/entities/models/ModalInfo";
import OverrideOptions from "../../../services/entities/models/OverrideOptions";
import Person from "../../../services/entities/models/Person";
import Phone from "../../../services/entities/models/Phone";
import { QuickHistoryViewModel } from "../../../services/entities/view-models/QuickHistoryViewModel";
import * as global from "../../../shared/GlobalVar";
import { AddToQuickHistory } from "../../../shared/historyDataManagement";
import PermissionName from "../../../shared/Permissions";
import {
  getBasicToastResponse,
  getErrorToastData,
  getFormattedPhoneNumber,
  getFullName,
  hasPermission,
  populateBreadCrumbData,
  updateObject,
} from "../../../shared/utility";
import { getMemberLabelByOrgRentalType } from "../../../shared/utility/system-utility";
import * as actions from "../../../store/actions/index";
import { compatiblePersonRequestforReservationRequest } from "../../Reservation/reservation-helper";
import { setActiveMenu } from "../../search/search-utility/search-utility";
import "../_member.scss";
import {
  createAddEditMemberRequest,
  createMemberReqForProfile,
  getDefaultInfoForMemberModal,
  validateAddEditMember,
} from "../MemberHelper";
import MemberModalEngine from "../modal/member-modal-engine";
import MemberUiCtrl from "./member-ui-ctrl";
import ProfileCardUI from "./profile-card-ui-ctrl";
class MemberProfile extends React.Component {
  state = {
    isSameAsProfileNameAndAddress: false,
    customMsg: null,
    showBillingNameConfirmModal: false,
    showFailureCountConfirmModal: false,
    memberInputReqest: new Person(),
    overrideOptions: new OverrideOptions(),
    isEditMode: false,
    isSubMenu: false,
    loading: false,
    memberId: null,
    memberDataResponse: null,
    base64MemberPhoto: null,
    viewImage: false,
    viewImageUrl: null,
    breadCrumbList: [],
    userPhotoInfo: [],
    showAddViolationPointModal: false,
    modalInfo: new ModalInfo(),
    isBlocking: false,
    isValidateDone: false,
    quickHistoryData: new QuickHistoryViewModel(),
  };
  componentDidMount() {
    this.initMemberProfile();
    this.loadUploaderData();
  }
  componentDidUpdate(prevProps, prevState) {
    const query = new URLSearchParams(this.props.location.search);
    const memberId = query.get("memberId");
    if (memberId !== this.state.memberId) {
      this.initMemberProfile();
    }
    if (this.state.quickHistoryData.Id) {
      AddToQuickHistory(
        this.state.quickHistoryData,
        global.QuickHistoryType.MEMBER
      );
    }
  }
  UNSAFE_componentWillReceiveProps({
    createMemberResponse,
    editMemberResponse,
    memberDataResponse,
    dmvCheckResponse,
    impersonateLoginResponse,
    blockUnblockResponse,
    addViolationPointResponse,
    cancelMemberResponse,
    reactivateMemberResponse,
    resetPasswordReponse,
    changeTemporaryPasswordResponse,
    verifyMemberAccountResponse,
  }) {
    if (createMemberResponse && !this.props.createMemberResponse) {
      showNotification(getBasicToastResponse(createMemberResponse));
      this.props.onCreateMemberReset();
      if (createMemberResponse.StatusCode === 0) {
        this.setState(
          {
            isBlocking: false,
          },
          () => {
            this.navaigateToProfilePage(createMemberResponse.MemberId);
          }
        );
      }
    }
    if (editMemberResponse && !this.props.editMemberResponse) {
      showNotification(getBasicToastResponse(editMemberResponse));
      this.props.onEditMemberReset();
      if (editMemberResponse.StatusCode === 0) {
        this.resetMemberData();
        this.goToProfilePage();
      }
    }
    if (
      (memberDataResponse && !this.state.memberDataResponse) ||
      (this.state.memberDataResponse &&
        memberDataResponse &&
        !this.props.memberDataResponse)
    ) {
      if (memberDataResponse.StatusCode === 0) {
        this.loadMemberViewCtrl(memberDataResponse);
        const memberData = memberDataResponse.Member
          ? memberDataResponse.Member
          : {};
        this.setState({
          quickHistoryData: new QuickHistoryViewModel({
            Id: memberData.MemberId,
            Name: getFullName(
              memberData.FirstName,
              memberData.LastName,
              memberData.MiddleName
            ),
            MembershipId: memberData.MembershipId,
            EmailAddress: memberData.EmailAddress,
            MobilePhone: getFormattedPhoneNumber(
              memberData.hasOwnProperty("MobilePhone")
                ? memberData.MobilePhone
                : null,
              null,
              null,
              "firstnumber"
            ),
            MobilePhoneTooltip: getFormattedPhoneNumber(
              memberData.hasOwnProperty("MobilePhone")
                ? memberData.MobilePhone
                : null,
              null,
              null,
              "tooltip"
            ),
          }),
        });
      } else {
        showNotification(getBasicToastResponse(memberDataResponse));
        this.props.onGetMemberReset();
      }
    }
    if (dmvCheckResponse && !this.props.dmvCheckResponse) {
      showNotification(getBasicToastResponse(dmvCheckResponse));
      return false;
    }
    if (impersonateLoginResponse && !this.props.impersonateLoginResponse) {
      if (impersonateLoginResponse.StatusCode === 0) {
        this.props.history.push("/Dashboard");
      } else {
        showNotification(getBasicToastResponse(impersonateLoginResponse));
      }
    }
    if (blockUnblockResponse && !this.props.blockUnblockResponse) {
      showNotification(getBasicToastResponse(blockUnblockResponse));
      this.props.onBlockUnblockMemberProfileReset();
      if (blockUnblockResponse.StatusCode === 0) {
        this.resetMemberData();
        this.closeModal();
      }
    }
    if (addViolationPointResponse && !this.props.addViolationPointResponse) {
      showNotification(getBasicToastResponse(addViolationPointResponse));
      this.props.onAddViolationPointReset();
      if (addViolationPointResponse.StatusCode === 0) {
        this.resetMemberData();
        this.closeModal();
      }
    }

    if (cancelMemberResponse && !this.props.cancelMemberResponse) {
      showNotification(getBasicToastResponse(cancelMemberResponse));
      this.props.onCancelMembershipReset();
      if (cancelMemberResponse.StatusCode === 0) {
        this.resetMemberData();
        this.closeModal();
      }
    }
    if (reactivateMemberResponse && !this.props.reactivateMemberResponse) {
      showNotification(getBasicToastResponse(reactivateMemberResponse));
      this.props.onReactivateMemberReset();
      if (reactivateMemberResponse.StatusCode === 0) {
        this.resetMemberData();
        this.closeModal();
      }
    }

    if (resetPasswordReponse && !this.props.resetPasswordReponse) {
      showNotification(getBasicToastResponse(resetPasswordReponse));
      this.props.onResetPasswordReset();
      if (resetPasswordReponse.StatusCode === 0) {
        this.resetMemberData();
        this.closeModal();
      }
    }
    if (
      changeTemporaryPasswordResponse &&
      !this.props.changeTemporaryPasswordResponse
    ) {
      showNotification(getBasicToastResponse(changeTemporaryPasswordResponse));
      this.props.onChangeTemporaryPasswordReset();
      if (changeTemporaryPasswordResponse.StatusCode === 0) {
        this.resetMemberData();
        this.closeModal();
      }
    }
    if (
      verifyMemberAccountResponse &&
      !this.props.verifyMemberAccountResponse
    ) {
      showNotification(getBasicToastResponse(verifyMemberAccountResponse));
      this.props.onVerifyMemberAccountReset();
      if (verifyMemberAccountResponse.StatusCode === 0) {
        this.resetMemberData();
        this.closeModal();
      }
    }
  }
  initMemberProfile = () => {
    const query = new URLSearchParams(this.props.location.search);
    const memberId = query.get("memberId");
    if (
      memberId ||
      (this.props.location.state && this.props.location.state.breadCrumbList)
    ) {
      if (memberId) {
        const breadCrumbList =
          this.props.otherData &&
            this.props.otherData.breadCrumbList &&
            this.props.otherData.breadCrumbList.length
            ? populateBreadCrumbData(
              this.props.otherData.breadCrumbList,
              getMemberLabelByOrgRentalType(),
              "/member/memberProfile?memberId=" + memberId
            )
            : [];
        this.props.onGetMemberReset();
        this.setState(
          {
            memberId: memberId,
            isEditMode: false,
            isSameAsProfileNameAndAddress: false,
            breadCrumbList: breadCrumbList,
            isSubMenu: true,
            isBlocking: false,
          },
          () => {
            this.resetMemberData();
          }
        );
      } else {
        const breadCrumbList = this.props.location.hasOwnProperty("state")
          ? populateBreadCrumbData(
            this.props.location.state.breadCrumbList,
            "New " + getMemberLabelByOrgRentalType(),
            ""
          )
          : [];
        this.setState({
          breadCrumbList: breadCrumbList,
        });
      }
    }
  };
  loadMemberViewCtrl = (memberStoreResponse) => {
    let updateCtrl = new Person(
      memberStoreResponse
        ? { ...memberStoreResponse.Member }
        : this.props.memberDataResponse
          ? { ...this.props.memberDataResponse.Member }
          : {}
    );
    if (this.props.updateData) {
      this.props.updateData(updateCtrl.FullName);
    }
    updateCtrl.AcknowledgeDriveManualEnabled =
      memberStoreResponse &&
        memberStoreResponse?.Member?.DrivingLicense?.AcknowledgeDriveManualEnabled
        ? true
        : false;
    this.setState({
      memberInputReqest: updateCtrl,
      memberDataResponse: updateCtrl,
    });
  };
  onSwichOrg = (orgId) => {
    this.props.onGetOrgDetailsReset();
    this.props.onGetOrgDetails(orgId);
  };
  onImpersonateLogin = (memberId, password) => {
    this.props.onDoImpersonateLoginReset();
    this.props.onDoImpersonateLogin(
      memberId,
      password,
      this.props.loggedInUserData,
      this.props.tokenId
    );
  };
  /**Profile Function */
  resetMemberData = () => {
    this.props.onGetMemberReset();
    const getMemberReq = createMemberReqForProfile(this.state.memberId);
    this.props.onGetMember(getMemberReq);
  };

  approveLicensebyDMV = (personData) => {
    const personInfo = compatiblePersonRequestforReservationRequest(personData);
    this.props.onRunDMVCheckReset();
    this.props.onRunDMVCheck({
      MemberId: personInfo && personInfo.MemberId ? personInfo.MemberId : null,
      PersonInfo: personInfo,
    });
  };

  loadUploaderData = () => {
    const uploaderObj = new FileUploaderModel();
    uploaderObj.fileName = "";
    uploaderObj.photoCloseStyle = {
      fontSize: "2.5rem",
    };
    const uploaderList = [];
    uploaderList.push(uploaderObj);
    this.setState({ userPhotoInfo: uploaderList });
  };
  goToProfilePage = () => {
    this.setState({
      isEditMode: false,
      isSameAsProfileNameAndAddress: false,
      isBlocking: false,
    });
  };
  openEditPage = () => {
    this.setState({
      isEditMode: this.state.isEditMode ? false : true,
      overrideOptions: new OverrideOptions(),
    });
  };
  launchModal = (modalData) => {
    this.setState({
      modalInfo: {
        type: modalData.type,
        showModal: true,
        memberId: modalData.memberId,
        fullName: modalData.fullName,
        badgeNo: modalData.badgeNo,
        memberData: modalData.memberData,
        doPerformAction: modalData.doPerformAction,
        popupFor: modalData.popupFor,
        title: modalData.title,
      },
    });
  };
  closeModal = () => {
    this.setState({ modalInfo: new ModalInfo() });
  };
  callBackToDisplayServerMessage = (response) => {
    var toastMsg = {
      msg: response.StatusMsg,
      toastType:
        response.StatusCode === 0
          ? global.TOAST_MSG.SUCCESS
          : global.TOAST_MSG.ERROR,
    };
    showNotification(toastMsg);
  };
  loadNewMemberPhoto = (index, base64Data, extension) => {
    this.setState({ base64MemberPhoto: base64Data || null });
  };

  /**end of profile function */
  navaigateToProfilePage = (memberId) => {
    this.props.history.push(
      "/member/memberProfile?memberId=" + memberId,
      this.getBreadcrumbData()
    );
  };
  getBreadcrumbData = () => {
    let breadCrumbList = populateBreadCrumbData(
      [],
      getMemberLabelByOrgRentalType(),
      "/member"
    );
    let breadCrumbData = {
      breadCrumbList: breadCrumbList,
      memberName: this.state.memberInputReqest.FullName,
    };
    return breadCrumbData;
  };
  showFailureCountConfirmModal = () => {
    this.setState({
      showFailureCountConfirmModal: true,
      customMsg: "Do you really want to reset the failure count?",
    });
  };
  closeConfirmModal = () => {
    this.setState({
      showBillingNameConfirmModal: false,
      showFailureCountConfirmModal: false,
    });
  };
  inputMemberChangedHandler = (event, controlName, elementType) => {
    let value = null;
    if (event !== "Invalid date" && elementType === "datetimepicker") {
      value = event ? moment(event).format("MM:DD:YYYY:HH:mm") : event;
    } else if (elementType === "checkbox") {
      value = event.target.checked;
    } else {
      value = event.target.value;
    }
    if (
      controlName === "FirstName" ||
      controlName === "MiddleName" ||
      controlName === "LastName"
    ) {
      let updatedControls = updateObject(this.state.memberInputReqest, {
        [controlName.capitalize()]: value,
      });
      updatedControls = updateObject(updatedControls, {
        FullName: getFullName(
          updatedControls.FirstName,
          updatedControls.LastName,
          updatedControls.MiddleName
        ),
      });
      if (this.state.isSameAsProfileNameAndAddress) {
        updatedControls.CreditCard.BillingName = updatedControls.FullName;
      }
      this.setState({
        memberInputReqest: updatedControls,
        isBlocking: true,
      });
      return false;
    }
    let updatedControls = updateObject(this.state.memberInputReqest, {
      [controlName.capitalize()]:
        controlName === "AcknowledgeDriveManualEnabled"
          ? !this.state.memberInputReqest[controlName]
          : value,
    });
    if (
      controlName === "IsEmployee" &&
      value &&
      (updatedControls.CompanyName || updatedControls.Designation)
    ) {
      updatedControls.Employee.CompanyName =
        updatedControls.Employee.CompanyName || updatedControls.CompanyName;
      updatedControls.Employee.Title =
        updatedControls.Employee.Title || updatedControls.Designation;
    } else if (controlName === "OtherCompanyName") {
      updatedControls.CompanyName = value;
    } else if (controlName === "OtherDesignation") {
      updatedControls.Designation = value;
    }
    this.setState({
      memberInputReqest: updatedControls,
      isBlocking: true,
    });
  };
  inputInsuranceChangeHandler = (event, controlName, elementType) => {
    let value = null;
    if (event !== "Invalid date" && elementType === "datetimepicker") {
      value = event ? moment(event).format("MM:DD:YYYY:HH:mm") : event;
    } else if (elementType === "checkbox") {
      value = event.target.checked;
    } else {
      value = event.target.value;
    }
    let updatedControls = { ...this.state.memberInputReqest };
    updatedControls.Insurance[controlName] = value;
    this.setState({
      memberInputReqest: updatedControls,
      isBlocking: true,
    });
  };
  inputEmployeeChangeHandler = (event, controlName, elementType) => {
    const value = event.target.value;
    let updatedControls = { ...this.state.memberInputReqest };
    if (controlName === "CompanyName") {
      updatedControls.CompanyName = value;
    } else if (controlName === "Title") {
      updatedControls.Designation = value;
    }
    updatedControls.Employee[controlName] = value;
    this.setState({
      memberInputReqest: updatedControls,
      isBlocking: true,
    });
  };
  inputPhoneChangeHandlerOld = (event, controlName, elementType) => {
    let updatedControls = { ...this.state.memberInputReqest };
    updatedControls[controlName].Number = event.target.value;
    this.setState({ memberInputReqest: updatedControls, isBlocking: true });
  };
  inputPhoneChangeHandler = (value, data, event, formattedValue) => {
    let controlName = event.target.name || "MobilePhone";
    let updatedControls = { ...this.state.memberInputReqest };
    updatedControls[controlName].CountryCode = data.dialCode;
    updatedControls[controlName].CountryIso = data.countryCode.toUpperCase();
    updatedControls[controlName].FormattedNumber = formattedValue
    updatedControls[controlName].Number = value;
    this.setState({ memberInputReqest: updatedControls, isBlocking: true });
  };
  inputLicenseChangeHandler = (event, controlName, elementType) => {
    let value = null;
    if (event !== "Invalid date" && elementType === "datetimepicker") {
      value = event ? moment(event).format("MM:DD:YYYY:HH:mm") : event;
    } else {
      value = event.target.value;
    }
    let updatedControls = { ...this.state.memberInputReqest };
    if (controlName === "Country") {
      updatedControls.DrivingLicense.DrivingLicenseStateId = null;
      updatedControls.DrivingLicense.DrivingLicenseStateText = null;
      updatedControls.DrivingLicense.DrivingLicenseCountryId = value;
    } else if (controlName === "State") {
      updatedControls.DrivingLicense.DrivingLicenseStateId = value;
      updatedControls.DrivingLicense.DrivingLicenseStateText =
        event.target[event.target.selectedIndex] ? event.target[event.target.selectedIndex].text : event.target.displayText;
    } else if (controlName === "StateText") {
      updatedControls.DrivingLicense.DrivingLicenseStateText = value;
    } else {
      updatedControls.DrivingLicense[controlName] = value;
    }
    this.setState({
      memberInputReqest: updatedControls,
      isBlocking: true,
    });
  };
  inputCreditCardChangeHandler = (event, controlName, elementType) => {
    let value = null;
    if (event !== "Invalid date" && elementType === "datetimepicker") {
      value = event ? moment(event).format("MM:DD:YYYY:HH:mm") : event;
    } else {
      value = event.target.value;
    }
    let updatedControls = { ...this.state.memberInputReqest };
    updatedControls.CreditCard[controlName] = value;
    this.setState({
      memberInputReqest: updatedControls,
      isBlocking: true,
    });
  };
  inputAddressChangeHandler = (event, controlName, objectType, commonData) => {
    let addresObj =
      objectType === "MEMBER"
        ? { ...this.state.memberInputReqest.PrimaryAddress }
        : { ...this.state.memberInputReqest.CreditCard.Address };
    addresObj["isValidZipCode"] = true;
    addresObj = commonAddressChangeHandler(event, controlName, commonData, addresObj)
    let updatedControls = { ...this.state.memberInputReqest };
    if (objectType === "MEMBER") {
      updatedControls.PrimaryAddress = { ...addresObj };
      if (this.state.isSameAsProfileNameAndAddress) {
        updatedControls.CreditCard.Address = { ...addresObj };
      }
    } else if (objectType === "CREDIT_CARD") {
      updatedControls.CreditCard.Address = { ...addresObj };
    }
    this.setState({
      memberInputReqest: updatedControls,
      isBlocking: true,
    });
  };
  inputOverrideOptionsChangeHandler = (event, controlName) => {
    let value = event.target.checked;
    let updatedControls = { ...this.state.overrideOptions };
    updatedControls[controlName] = value;
    this.setState({
      overrideOptions: updatedControls,
      isBlocking: true,
    });
  };
  useSameProfileNameandAddress = (event) => {
    let memCtrl = { ...this.state.memberInputReqest };
    if (event.target.checked) {
      memCtrl.CreditCard.Address = { ...memCtrl.PrimaryAddress };
      memCtrl.CreditCard.BillingName = memCtrl.FullName;
    }
    this.setState({
      memberInputReqest: memCtrl,
      isSameAsProfileNameAndAddress: event.target.checked,
      isBlocking: true,
    });
  };
  renderMobileAutoSueggestion = (type, phoneInfo) => {
    let updatedControls = { ...this.state.memberInputReqest };
    if (!updatedControls[type]) {
      updatedControls[type] = new Phone();
    }
    updatedControls[type].CountryCode = phoneInfo ? phoneInfo.value : null;
    updatedControls[type].CountryIso = phoneInfo ? phoneInfo.countryIso : null;
    this.setState({ memberInputReqest: updatedControls, isBlocking: true });
  };
  resetFailureCount = () => {
    let updatedControls = updateObject(this.state.memberInputReqest, {
      PaymentFailureCount: "0",
    });
    this.setState({
      memberInputReqest: updatedControls,
      showFailureCountConfirmModal: false,
    });
  };
  resetLoader = (isLoading) => {
    this.setState({ loading: isLoading });
  };
  updateMemberObject = (memberObj) => {
    this.setState({ memberInputReqest: memberObj });
  };
  onCancel = () => {
    let pathname = this.props.location.pathname;
    if (pathname === "/newmember") {
      this.props.history.push("/Dashboard");
    } else {
      this.loadMemberViewCtrl();
      this.goToProfilePage();
    }
  };
  onAddEditMember = () => {
    const addEditMemReq = createAddEditMemberRequest(
      this.state.memberInputReqest,
      this.state.overrideOptions,
      this.state.isSubMenu
    );
    if (!hasPermission(PermissionName.EDIT_DRIVING_LICENSE)) {
      addEditMemReq.OverrideLicenseAndState = true;
    }
    let errorMsg = validateAddEditMember(addEditMemReq);
    if (errorMsg) {
      showNotification(getErrorToastData(errorMsg));
      this.setState({
        isValidateDone: true,
      });
      return false;
    } else {
      this.setState({
        isValidateDone: false,
      });
    }
    if (
      !this.state.isSameAsProfileNameAndAddress &&
      this.state.memberInputReqest.CreditCard &&
      this.state.memberInputReqest.CreditCard.CardNumber &&
      this.state.memberInputReqest.FullName !==
      this.state.memberInputReqest.CreditCard.BillingName &&
      !this.state.overrideOptions.OverrideCreditCardInformation
    ) {
      this.setState({
        showBillingNameConfirmModal: true,
        customMsg:
          "The name on the credit card does not match the name in the " +
          getMemberLabelByOrgRentalType() +
          " Profile. Do you want to proceed?",
      });
    } else {
      this.doAddOrEditMember();
    }
  };
  doAddOrEditMember = () => {
    if (this.state.showBillingNameConfirmModal) {
      this.setState({ showBillingNameConfirmModal: false });
    }
    let addEditMemReq = createAddEditMemberRequest(
      this.state.memberInputReqest,
      this.state.overrideOptions,
      this.state.isSubMenu
    );
    if (!hasPermission(PermissionName.EDIT_DRIVING_LICENSE)) {
      addEditMemReq.OverrideLicenseAndState = true;
    }
    addEditMemReq.OrgUnitId = this.props.orgUnitId;
    addEditMemReq.TermsAndConditionsCheck = true;
    addEditMemReq.BgVerificationCheck = true;
    this.props.onCreateMemberReset();
    this.props.onEditMemberReset();
    if (this.state.isSubMenu) {
      this.props.onEditMember(addEditMemReq);
    } else {
      this.props.onCreateMember(addEditMemReq);
    }
  };

  render() {
    setActiveMenu(this.props.match.url);
    const memberData = { ...this.state.memberInputReqest };
    return (
      <Fragment>
        <PreventTransitionPrompt when={this.state.isBlocking} />
        <PageTopPanel
          breadCrumbList={this.state.breadCrumbList}
          title={this.state.memberInputReqest.FullName}
          showEditMemberLink={
            !this.state.isEditMode && hasPermission(PermissionName.EDIT_MEMBER)
              ? this.state.memberId
                ? true
                : false
              : false
          }
          openEditPage={this.openEditPage}
          showNewReservationLink={this.state.memberId ? true : false}
          memberId={this.state.memberId}
          showExportLink={false}
        />
        <div className={this.state.isSubMenu ? "formRow" : "row"}>
          {this.state.isSubMenu ? (
            ""
          ) : (
            <h4 className="form-header text-uppercase newMemberCaption col-lg-12">
              <i className="fas fa-user-circle" />
              New {getMemberLabelByOrgRentalType()}
            </h4>
          )}
          <div className="col-lg-2">
            <ProfileCardUI
              memberData={memberData}
              isEditMode={this.state.isEditMode}
              isSubMenu={this.state.isSubMenu}
              userPhotoInfo={this.state.userPhotoInfo}
              loadNewMemberPhoto={this.loadNewMemberPhoto}
              openEditPage={this.openEditPage}
              isAccountOwner={
                String(this.state.memberId) === String(this.props.userId) ? true : false
              }
              launchModal={this.launchModal}
              onBlockUnblockMemberProfile={
                this.props.onBlockUnblockMemberProfile
              }
              onReactivateMember={this.props.onReactivateMember}
              onCancelMembership={this.props.onCancelMembership}
              onChangeTemporaryPassword={this.props.onChangeTemporaryPassword}
              onResetPassword={this.props.onResetPassword}
              onVerifyMemberAccount={this.props.onVerifyMemberAccount}
              onImpersonateLogin={this.onImpersonateLogin}
            />
            {this.state.isEditMode === this.state.isSubMenu ? (
              <div className={`${this.state.isSubMenu ? "mtr-1-0" : "pt-2-0"}`}>
                <OverrideComponent
                  overrideItems={getOverrideInputControls(
                    this.state.overrideOptions,
                    this.inputOverrideOptionsChangeHandler,
                    global.ADMIN_PAGE.MEMBER_PROFILE_PAGE
                  )}
                />
              </div>
            ) : (
              ""
            )}
          </div>

          <div className="col-lg-10">
            <MemberUiCtrl
              memberData={memberData}
              isSubMenu={this.state.isSubMenu}
              isEditMode={this.state.isEditMode}
              overrideOptions={this.state.overrideOptions}
              inputMemberChangedHandler={this.inputMemberChangedHandler}
              inputEmployeeChangeHandler={this.inputEmployeeChangeHandler}
              inputPhoneChangeHandler={this.inputPhoneChangeHandler}
              inputInsuranceChangeHandler={this.inputInsuranceChangeHandler}
              inputCreditCardChangeHandler={this.inputCreditCardChangeHandler}
              inputOverrideOptionsChangeHandler={
                this.inputOverrideOptionsChangeHandler
              }
              inputAddressChangeHandler={this.inputAddressChangeHandler}
              inputLicenseChangeHandler={this.inputLicenseChangeHandler}
              renderMobileAutoSueggestion={this.renderMobileAutoSueggestion}
              resetLoader={this.resetLoader}
              approveLicensebyDMV={this.approveLicensebyDMV}
              showModal={this.showModal}
              launchModal={this.launchModal}
              showFailureCountConfirmModal={this.showFailureCountConfirmModal}
              useSameProfileNameandAddress={this.useSameProfileNameandAddress}
              isSameAsProfileNameAndAddress={
                this.state.isSameAsProfileNameAndAddress
              }
              dependentOrg={this.props.dependentOrg}
              selectedOrgKeyValue={this.props.selectedOrgKeyValue}
              onAddEditMember={this.onAddEditMember}
              onCancel={this.onCancel}
              onAddViolationPoint={this.props.onAddViolationPoint}
              isValidateDone={this.state.isValidateDone}
              updateMemberObject={this.updateMemberObject}
            />
          </div>

          {this.state.showBillingNameConfirmModal ? (
            <ConfirmModal
              key="billingNameConfirmModal"
              body={this.state.customMsg}
              showModal={this.state.showBillingNameConfirmModal}
              closeModal={this.closeConfirmModal}
              doConfirmFunctionality={this.doAddOrEditMember}
            />
          ) : (
            ""
          )}
          {this.state.showFailureCountConfirmModal ? (
            <ConfirmModal
              key="failureCountConfirmModal"
              body={this.state.customMsg}
              showModal={this.state.showFailureCountConfirmModal}
              closeModal={this.closeConfirmModal}
              doConfirmFunctionality={this.resetFailureCount}
            />
          ) : (
            ""
          )}

          {this.state.modalInfo.showModal ? (
            <MemberModalEngine
              modalType={this.state.modalInfo.type}
              showModal={this.state.modalInfo.showModal}
              closeModal={this.closeModal}
              memberId={this.state.modalInfo.memberId}
              fullName={this.state.modalInfo.fullName}
              memberData={this.state.modalInfo.memberData}
              badgeNo={this.state.modalInfo.badgeNo}
              launchModalFromParentPage={this.launchModal}
              resetParentPage={this.resetMemberData}
              resetLoader={this.resetLoader}
              defaultData={getDefaultInfoForMemberModal(
                this.state.modalInfo.type,
                this.state.memberInputReqest
              )}
              doPerformAction={this.state.modalInfo.doPerformAction}
              popupFor={this.state.modalInfo.popupFor}
              title={this.state.modalInfo.title}
            />
          ) : (
            ""
          )}
          {this.props.loading || this.state.loading ? <Spinner /> : ""}
        </div>
      </Fragment>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    onCreateMember: (addEditReq) => {
      dispatch(actions.createMember(addEditReq));
    },
    onCreateMemberReset: (addEditReq) => {
      dispatch(actions.createMemberReset(addEditReq));
    },
    onEditMember: (addEditReq) => {
      dispatch(actions.editMember(addEditReq));
    },
    onEditMemberReset: (addEditReq) => {
      dispatch(actions.editMemberReset(addEditReq));
    },
    onGetMember: (memberReq) => {
      dispatch(actions.getMember(memberReq));
    },
    onGetMemberReset: () => dispatch(actions.getMemberReset()),

    onSwichOrg: () => dispatch(actions.authCheckState()),

    onRunDMVCheck: (runDMVReq) => dispatch(actions.runDMVCheck(runDMVReq)),
    onRunDMVCheckReset: () => dispatch(actions.runDMVCheckReset()),

    onGetMemberAddresseses: (getAddressReq) =>
      dispatch(actions.getMemberAddresseses(getAddressReq)),
    onGetMemberAddressesesReset: () =>
      dispatch(actions.getMemberAddressesesReset()),
    onAddMemberAddresses: (addAddressReq) =>
      dispatch(actions.addMemberAddresses(addAddressReq)),
    onAddMemberAddressesReset: () =>
      dispatch(actions.addMemberAddressesReset()),
    onEditMemberAddresses: (editAddressReq) =>
      dispatch(actions.editMemberAddresses(editAddressReq)),
    onEditMemberAddressesReset: () =>
      dispatch(actions.editMemberAddressesReset()),
    onDeleteMemberAddresses: (deleteAddressReq) =>
      dispatch(actions.deleteMemberAddresses(deleteAddressReq)),
    onDeleteMemberAddressesReset: () =>
      dispatch(actions.deleteMemberAddressesReset()),
    onBlockUnblockMemberProfile: (req, curStatus) =>
      dispatch(actions.blockUnblockMemberProfile(req, curStatus)),
    onBlockUnblockMemberProfileReset: () =>
      dispatch(actions.blockUnblockMemberProfileReset()),
    onCancelMembership: (req) => dispatch(actions.cancelMembership(req)),
    onCancelMembershipReset: () => dispatch(actions.cancelMembershipReset()),
    onReactivateMember: (req) => dispatch(actions.reactivateMember(req)),
    onReactivateMemberReset: () => dispatch(actions.reactivateMemberReset()),
    onChangeTemporaryPassword: (req) =>
      dispatch(actions.changeTemporaryPassword(req)),
    onChangeTemporaryPasswordReset: () =>
      dispatch(actions.changeTemporaryPasswordReset()),
    onResetPassword: (req) => dispatch(actions.resetPassword(req)),
    onResetPasswordReset: () => dispatch(actions.resetPasswordReset()),
    onVerifyMemberAccount: (req) => dispatch(actions.verifyMemberAccount(req)),
    onVerifyMemberAccountReset: () =>
      dispatch(actions.verifyMemberAccountReset()),
    onAddViolationPoint: (req) => dispatch(actions.addViolationPoint(req)),
    onAddViolationPointReset: () => dispatch(actions.addViolationPointReset()),
    onAllowTemporaryLicenseImageUpload: (req) =>
      dispatch(actions.allowTemporaryLicenseImageUpload(req)),
    onAllowTemporaryLicenseImageUploadReset: () =>
      dispatch(actions.allowTemporaryLicenseImageUploadReset()),
    onDoImpersonateLogin: (
      memberId,
      password,
      currentLoggedIndata,
      currentToken
    ) =>
      dispatch(
        actions.doImpersonateLogin(
          memberId,
          password,
          currentLoggedIndata,
          currentToken
        )
      ),
    onDoImpersonateLoginReset: () =>
      dispatch(actions.doImpersonateLoginReset()),
  };
};
const mapStateToProps = (state) => {
  return {
    userId: state.auth.userId,
    tokenId: state.auth.token,
    loading:
      state.memberReducer.loading ||
      state.licenseApprovalReducer.loading ||
      state.documentAttachReducer.loading ||
      state.uploadFileReducer.loading ||
      state.addressReducer.loading ||
      state.impersonateReducer.loading ||
      state.orgDetailReducer.loading,
    modalInfo: new ModalInfo(),
    dependentOrg: state.auth.loggedInUserData.DependantOrgList,
    selectedOrgKeyValue: state.auth.loggedInUserData.SelectedOrgKeyValue,
    orgUnitId: state.auth.loggedInUserData.OrgUnitId,
    loggedInUserData: state.auth.loggedInUserData,
    createMemberResponse: state.memberReducer.createMemberResponse,
    editMemberResponse: state.memberReducer.editMemberResponse,

    memberDataResponse: state.memberReducer.memberData,

    blockUnblockResponse: state.memberReducer.blockUnblockResponse,
    reactivateMemberResponse: state.memberReducer.reactivateMemberResponse,
    cancelMemberResponse: state.memberReducer.cancelMemberResponse,
    changeTemporaryPasswordResponse:
      state.memberReducer.changeTemporaryPasswordResponse,
    resetPasswordReponse: state.memberReducer.resetPasswordReponse,
    verifyMemberAccountResponse:
      state.memberReducer.verifyMemberAccountResponse,

    impersonateLoginResponse: state.impersonateReducer.impersonateLoginResponse,

    addViolationPointResponse: state.memberReducer.addViolationPointResponse,
    allowTempImgResponse: state.memberReducer.allowTempImgResponse,

    dmvCheckResponse: state.licenseApprovalReducer.dmvCheckResponse,
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MemberProfile));

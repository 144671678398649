import ReservationSearchInfo from "../models/ReservationSearchInfo";

const ReservationSearchRequest = function() {
  this.MemberId = null;
  this.ReservationSearchInfo = new ReservationSearchInfo();
  this.SelectedHeaders = null;
  // this.ShowReservationsAsSharer = null;
  // this.ShowReservationsAsBorrower = null;
};
export default ReservationSearchRequest;

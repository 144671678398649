import React, { Fragment } from "react";
import CommonInputField from "../../../components/UI/Input/common-input-field";
import { getVehicleTermsConditions } from "../vehicle-helper";
const TermsConditionBlock = (props) => {
  const termsConditionObj = getVehicleTermsConditions();
  const width100 = "col-lg-12";
  const renderCheckbox = (labelCaption, id, isDisabled) => {
    const disabledAttr = isDisabled ? { disabled: isDisabled } : {};
    return (
      <CommonInputField
        elementConfig={{
          id: id,
          type: "checkbox",
          ...disabledAttr,
        }}
        otherConfig={{
          caption: labelCaption,
        }}
        nodeElement="checkbox"
        onChange={props.inputMemberChangedHandler}
      />
    );
  };
  const renderTermConditionSections = (termsConditionList, idPrefix) => {
    return (
      <div className={`${width100} row`}>
        <label className={`${width100} p-0 m-0 fs-5`}>
          {termsConditionList.label + " :"}
        </label>
        {termsConditionList.msg}
        <hr className={`${width100} p-0 mt-0`} />
        {termsConditionList.conditions.map((termsObj, index) => (
          <div className={`${width100} p-0 pb-2`} key={index}>
            {renderCheckbox(termsObj.value, idPrefix + termsObj.key)}
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="formRow">
      <h4 className={`${width100} form-header text-uppercase`}>
        <i className="fas fa-user-circle" />
        Terms and Condition
      </h4>
      {renderTermConditionSections(termsConditionObj.common, "avt")}
      {renderTermConditionSections(termsConditionObj.Insurability, "bvt")}
    </div>
  );
};
export default TermsConditionBlock;

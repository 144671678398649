const DeviceInfo = function() {
  this.DeviceId = null;
  this.Model = null;
  this.IpAddress = null;
  this.Product = null;
  this.PhoneNo = null;
  this.ProductCode = null;
  this.Notes = null;
  this.IMEIId = null;
  this.SerialNumber = null;
};
export default DeviceInfo;

export const FoxAddress = function (data, noDefaultCountry,isFoxAPi) {
    if (!data) data = {};
    this.type = data.type || "HOME";
    this.street = data.street || null;
    this.street2 = data.street2 || null;
    this.city = data.city || null;
    this.cityText = data.cityText || data.city;
    this.province = data.province || null;
    this.provinceText = data.provinceText || data.province;
    this.postalCode = data.postalCode || null;
    this.postalCodeText = data.postalCodeText || data.postalCode;
    this.country = data.country ? data.country
    : !noDefaultCountry && localStorage.getItem("orgUnitCountry")
    ? isFoxAPi ? "US" : JSON.parse(localStorage.getItem("orgUnitCountry")).Key
    : null;
};



import { getEngineStatusDisplayValue } from "../../../../containers/report/shuttle-trips/shuttle-trips-helper";
import {
  ShuttleEngineStatusBgColorClass,
  ShuttleEngineStatusFontColorClass,
} from "../../../../shared/utility/enum-utility";
import { getHourMinSecFromMilliSecond } from "../../../../shared/utility/shuttle-app-utility";

export const ShuttleEngineStatusViewModel = function (
  data,
  status,
  timezoneString
) {
  if (!data) data = {};
  const engineStatuses = data.engineStatusDurationInMillis || [];
  const totalTripDurationInMillis = data.tripDurationInMillis || 1;
  this.statusKey = status;
  this.statusDisplayValue = getEngineStatusDisplayValue(status);
  this.tripDurationInMillis = engineStatuses[status];
  this.tripDurationRatioInPercent =
    (parseFloat(engineStatuses[status]) /
      parseFloat(totalTripDurationInMillis)) *
      100 +
    "%";
  this.tripDurationRatioInValue =
    (parseFloat(engineStatuses[status]) /
      parseFloat(totalTripDurationInMillis)) *
    100;
  this.formattedTripDuration = getHourMinSecFromMilliSecond(
    engineStatuses[status],
    true
  );
  this.statusBGColor = ShuttleEngineStatusBgColorClass[status];
  this.statusFontColor = ShuttleEngineStatusFontColorClass[status];
};

import SubMenu from "../model/sub-menu";
import SubMenuItem from "../model/sub-menu-item";
import KeyValuePair from "../../../services/entities/models/KeyValuePair";
import { hasPermission } from "../../../shared/utility";
import PermissionName from "../../../shared/Permissions";

import MemberProfile from "../../../containers/Member/member-profile/member-profile";
import SearchPageContainer from "../../../containers/search/search-page-container";

import SearchFeedback from "../../../containers/feedback/search/search-feedback";
import MemberSummary from "../../../containers/Member/member-summary/member-summary";
import { isP2POrHybridOrg } from "../../../shared/utility/system-utility";
import MemberPackages from "../../../containers/Member/member-package/member-packages";

export const getMemberSubMenu = (memberId) => {
  const subMenu = new SubMenu();
  subMenu.menuKey = "member";
  if (hasPermission(PermissionName.VIEW_MEMBER)) {
    subMenu.subMenuItems.push(
      new SubMenuItem(
        "memberProfile",
        "Profile",
        "fa fa-home",
        MemberProfile,
        memberId,
        [new KeyValuePair("memberId", memberId)]
      )
    );
  }
  if (isP2POrHybridOrg()) {
    subMenu.subMenuItems.push(
      new SubMenuItem(
        "memberPackages",
        "Packages",
        "fas fa-box-open",
        MemberPackages,
        memberId,
        [new KeyValuePair("memberId", memberId)]
      )
    );
  }
  if (hasPermission(PermissionName.VIEW_VEHICLE) && isP2POrHybridOrg()) {
    subMenu.subMenuItems.push(
      new SubMenuItem(
        "memberVehicle",
        "Vehicles",
        "fa fa-car-side",
        SearchPageContainer,
        memberId,
        [new KeyValuePair("memberId", memberId)]
      )
    );
  }
  if (hasPermission(PermissionName.VIEW_RESERVATION) && isP2POrHybridOrg()) {
    subMenu.subMenuItems.push(
      new SubMenuItem(
        "memberVehicleReservation",
        "My Veh. Res.",
        "fa fa-car",
        SearchPageContainer,
        memberId,
        [new KeyValuePair("memberId", memberId)]
      )
    );
  }
  if (hasPermission(PermissionName.VIEW_RESERVATION)) {
    subMenu.subMenuItems.push(
      new SubMenuItem(
        "memberReservation",
        "Reservations",
        "fa fa-car",
        SearchPageContainer,
        memberId,
        [new KeyValuePair("memberId", memberId)]
      )
    );
  }
  if (hasPermission(PermissionName.VIEW_FAVORITE)) {
    subMenu.subMenuItems.push(
      new SubMenuItem(
        "memberFavorites",
        "Favorites",
        "fa fa-star",
        SearchPageContainer,
        memberId,
        [new KeyValuePair("memberId", memberId)]
      )
    );
  }
  if (hasPermission(PermissionName.VIEW_FEEDBACK)) {
    subMenu.subMenuItems.push(
      new SubMenuItem(
        "memberFeedback",
        "Feedback",
        "fas fa-comments",
        SearchFeedback,
        memberId,
        [new KeyValuePair("memberId", memberId)]
      )
    );
  }
  if (hasPermission(PermissionName.VIEW_ACTIVITY_LOG)) {
    subMenu.subMenuItems.push(
      new SubMenuItem(
        "memberActivity",
        "Activity",
        "fa fa-snowboarding",
        SearchPageContainer,
        memberId,
        [new KeyValuePair("memberId", memberId)]
      )
    );
  }
  if (hasPermission(PermissionName.VIEW_PAYMENT)) {
    subMenu.subMenuItems.push(
      new SubMenuItem(
        "billing",
        "Billing",
        "fa fa-money-bill",
        SearchPageContainer,
        memberId,
        [new KeyValuePair("memberId", memberId)]
      )
    );
  }
  if (hasPermission(PermissionName.VIEW_MESSAGE)) {
    subMenu.subMenuItems.push(
      new SubMenuItem(
        "memberMessages",
        "Messages",
        "fas fa-envelope",
        SearchPageContainer,
        memberId,
        [new KeyValuePair("memberId", memberId)]
      )
    );
  }
  if (hasPermission(PermissionName.VIEW_MEMBER)) {
    subMenu.subMenuItems.push(
      new SubMenuItem(
        "memberSummary",
        "Summary",
        "fa fa-list-alt",
        MemberSummary,
        memberId,
        [new KeyValuePair("memberId", memberId)]
      )
    );
  }

  return subMenu;
};

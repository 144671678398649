import * as actionTypes from "./actionTypes";
import { invokeServerAPI } from "../../services/httpService";
import { getCommonSearchModel } from "../../services/entities/models/CommonSearchModel";
export const getSearchResultStart = () => {
  return {
    type: actionTypes.SEARCH_PAGES_START,
  };
};

export const getSearchResultSuccess = (response, apiName) => {
  const commonSearchModel = getCommonSearchModel(response, apiName);
  return {
    type: actionTypes.SEARCH_PAGES_SUCCESS,
    searchDataResponse: commonSearchModel,
  };
};

export const getSearchResultFail = (error) => {
  return {
    type: actionTypes.SEARCH_PAGES_FAIL,
    error: error,
  };
};
export const getSearchResultReset = () => {
  return {
    type: actionTypes.SEARCH_PAGES_RESET,
  };
};
export const getSearchResult = (apiName, req, isSystemApi) => {
  return (dispatch) => {
    dispatch(getSearchResultStart());
    invokeServerAPI(apiName, req, isSystemApi)
      .then((response) => {
        dispatch(getSearchResultSuccess(response, apiName));
      })
      .catch((err) => {
        //dispatch(getSearchResultFail(err));
      });
  };
};

import * as actionTypes from "../actions/actionTypes";
import { reducerUpdateObject } from "./reducer.utility";

const initialState = {
  licenseApprovalResponse: null,
  dmvCheckResponse: null,
  loading: false,
};

const doManualLicenseApproveOrDisApproveStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};

const doManualLicenseApproveOrDisApproveSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    licenseApprovalResponse: action.licenseApprovalResponse,
    error: null,
    loading: false,
  });
};
const doManualLicenseApproveOrDisApproveFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false,
  });
};
const doManualLicenseApproveOrDisApproveReset = (state, action) => {
  return reducerUpdateObject(state, {
    licenseApprovalResponse: null,
  });
};
const runDMVCheckStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
const runDMVCheckSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    dmvCheckResponse: action.dmvCheckResponse,
    error: null,
    loading: false,
  });
};
const runDMVCheckFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false,
  });
};
const runDMVCheckReset = (state, action) => {
  return reducerUpdateObject(state, {
    dmvCheckResponse: null,
  });
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LICENSE_APPROVAL_START:
      return doManualLicenseApproveOrDisApproveStart(state, action);
    case actionTypes.LICENSE_APPROVAL_SUCCESS:
      return doManualLicenseApproveOrDisApproveSuccess(state, action);
    case actionTypes.LICENSE_APPROVAL_FAIL:
      return doManualLicenseApproveOrDisApproveFail(state, action);
    case actionTypes.LICENSE_APPROVAL_RESET:
      return doManualLicenseApproveOrDisApproveReset(state, action);

    case actionTypes.RUN_DMV_CHECK_START:
      return runDMVCheckStart(initialState, action);
    case actionTypes.RUN_DMV_CHECK_SUCCESS:
      return runDMVCheckSuccess(state, action);
    case actionTypes.RUN_DMV_CHECK_FAIL:
      return runDMVCheckFail(initialState, action);
    case actionTypes.RUN_DMV_CHECK_RESET:
      return runDMVCheckReset(initialState, action);

    default:
      return state;
  }
};
export default reducer;

import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
const responsive = {
  0: {
    items: 1,
  },
  450: {
    items: 2,
  },
  600: {
    items: 3,
  },
  1000: {
    items: 4,
  },
};
const VehicleImagesUI = (props) => {
  return (
    <div className="formRow">
      <h4 className="form-header text-uppercase  col-lg-12">
        <i className="fas fa-user-circle" />
        Images
      </h4>
      {props.images.length > 4 ? (
        <OwlCarousel
          className={"owl-theme"}
          loop={props.images.length > 4 ? true : false}
          margin={10}
          nav={false}
          dots={true}
          autoplay={true}
          autoplayTimeout={2000}
          items={props.images.length > 4 ? 4 : props.images.length}
          //responsive={responsive}
        >
          {props.images &&
            props.images.map((imageUrl, index) => (
              <img
                src={imageUrl}
                alt="Cinque Terre"
                className="img-thumbnail"
                onClick={props.showImageViewer}
                style={{
                  width: "30.4rem",
                  height: "20.36rem",
                  cursor: "pointer",
                }}
              ></img>
            ))}
        </OwlCarousel>
      ) : (
        <div className="col-lg-12 row">
          {props.images &&
            props.images.map((imageUrl, index) => (
              <img
                src={imageUrl}
                alt="Cinque Terre"
                className="img-thumbnail"
                onClick={props.showImageViewer}
                style={{
                  width: "30.4rem",
                  height: "20.36rem",
                  cursor: "pointer",
                }}
              ></img>
            ))}
        </div>
      )}
    </div>
  );
};
export default VehicleImagesUI;
// import React from "react";
// import { Carousel } from "primereact/carousel";
// import { Button } from "primereact/button";

// import "primereact/resources/themes/nova-light/theme.css";
// import "primereact/resources/primereact.min.css";
// import "primeicons/primeicons.css";

// const getGellaryTemplate = imageUrl => {
//   return (
//     <div className="car-details">
//       <div className="p-grid p-nogutter">
//         <div className="p-col-12">
//           <img src={imageUrl} />
//         </div>
//         <div className="p-col-12 car-data">
//           {/* <div className="car-title">{car.brand}</div>
//           <div className="car-subtitle">
//             {car.year} | {car.color}
//           </div> */}

//           <div className="car-buttons">
//             <Button icon="pi pi-search" className="p-button-secondary" />
//             <Button icon="pi pi-star" className="p-button-secondary" />
//             <Button icon="pi pi-cog" className="p-button-secondary" />
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };
// const basicHeader = <h2>Basic</h2>;
// const customHeader = (
//   <h2>Circular, AutoPlay, 3 Items per Page and Scroll by 1</h2>
// );
// const responsiveSettings = [
//   {
//     breakpoint: "1024px",
//     numVisible: 3,
//     numScroll: 3
//   },
//   {
//     breakpoint: "768px",
//     numVisible: 2,
//     numScroll: 2
//   },
//   {
//     breakpoint: "560px",
//     numVisible: 1,
//     numScroll: 1
//   }
// ];

// const verticalHeader = <h2>Vertical</h2>;
// const VehicleImagesUI = props => {
//   return (
//     <div className="formRow">
//       <h4 className="form-header text-uppercase  col-lg-12">
//         <i className="fas fa-user-circle" />
//         Images
//       </h4>
//       <div className="col-lg-12 row">
//         <div className="content-section implementation">
//           {/* {props.images &&
//             props.images.map((imageUrl, index) => {
//               return ( */}
//           <Carousel
//             value={props.images}
//             itemTemplate={getGellaryTemplate}
//             numVisible={3}
//             numScroll={1}
//             className="custom-carousel"
//             header={customHeader}
//             responsive={responsiveSettings}
//             circular={true}
//             autoplayInterval={3000}
//           ></Carousel>
//           {/* );
//             })} */}
//         </div>
//       </div>
//     </div>
//   );
// };
// export default VehicleImagesUI;

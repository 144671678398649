import { getLocalStoragePrefix } from "./LocalStorage";
import { QuickHistoryType } from "./GlobalVar";
import { QuickHistoryViewModel } from "../services/entities/view-models/QuickHistoryViewModel";

export const HistoryDataAdd = (historyObj, historyType) => {
  const preFix = getLocalStoragePrefix("QuickHistory");
  let quickHistoryDataList;
  if (!localStorage.getItem(preFix)) {
    quickHistoryDataList = {
      ReservationList: [],
      RentalList: [],
      MemberList: [],
    };
    localStorage.setItem(preFix, JSON.stringify(quickHistoryDataList));
  }
  quickHistoryDataList = JSON.parse(localStorage.getItem(preFix));
  const quickHistoryObj = new QuickHistoryViewModel(historyObj, historyType);

  const getNewUpdatedItem = (historyItemList, newHistoryItem) => {
    if (historyItemList.length > 0) {
      let historyItemListExceptCurrentOne = historyItemList.filter(
        (item) => String(item.Id) !== String(newHistoryItem.Id)
      );
      historyItemListExceptCurrentOne.push(newHistoryItem);
      return historyItemListExceptCurrentOne;
    } else {
      historyItemList.push(newHistoryItem);
      return historyItemList;
    }
  };

  const quickHistoryDataUpdatedList = JSON.parse(
    JSON.stringify(quickHistoryDataList)
  );

  if (historyType === QuickHistoryType.RESERVATION) {
    const updatedReservationList = getNewUpdatedItem(
      quickHistoryDataUpdatedList.ReservationList
        ? quickHistoryDataUpdatedList.ReservationList
        : [],
      quickHistoryObj
    );
    quickHistoryDataUpdatedList.ReservationList = updatedReservationList;
  } else if (historyType === QuickHistoryType.RENTAL) {
    let reservationListExceptRental = quickHistoryDataUpdatedList.ReservationList.filter(
      (item) => String(item.Id) !== String(quickHistoryObj.Id)
    );
    quickHistoryDataUpdatedList.ReservationList = reservationListExceptRental;

    const updatedRentalList = getNewUpdatedItem(
      quickHistoryDataUpdatedList.RentalList,
      quickHistoryObj
    );
    quickHistoryDataUpdatedList.RentalList = updatedRentalList;
  } else if (historyType === QuickHistoryType.MEMBER) {
    const updatedMemberList = getNewUpdatedItem(
      quickHistoryDataUpdatedList.MemberList,
      quickHistoryObj
    );
    quickHistoryDataUpdatedList.MemberList = updatedMemberList;
  }
  localStorage.setItem(preFix, JSON.stringify(quickHistoryDataUpdatedList));
};

export const AddToQuickHistory = (quickHistoryObj, historyType) => {
  const preFix = getLocalStoragePrefix("QuickHistory");
  let quickHistoryData;
  if (!localStorage.getItem(preFix)) {
    quickHistoryData = {
      ReservationList: [],
      RentalList: [],
      MemberList: [],
    };
    localStorage.setItem(preFix, JSON.stringify(quickHistoryData));
  }
  quickHistoryData = JSON.parse(localStorage.getItem(preFix));
  const getUpdatedQuickHistoryItems = (existingItems, newItem) => {
    if (existingItems.length > 0) {
      let historyItemListExceptCurrentOne = existingItems.filter(
        (item) => String(item.Id) !== String(newItem.Id)
      );
      historyItemListExceptCurrentOne.push(newItem);
      return historyItemListExceptCurrentOne;
    } else {
      existingItems.push(newItem);
      return existingItems;
    }
  };

  const quickHistoryUpdatedItems = JSON.parse(
    JSON.stringify(quickHistoryData)
  );

  if (historyType === QuickHistoryType.RESERVATION) {
    const updatedReservationList = getUpdatedQuickHistoryItems(
      quickHistoryUpdatedItems.ReservationList
        ? quickHistoryUpdatedItems.ReservationList
        : [],
      quickHistoryObj
    );
    quickHistoryUpdatedItems.ReservationList = updatedReservationList;
  } else if (historyType === QuickHistoryType.RENTAL) {
    let reservationItemsExceptRental =
      quickHistoryUpdatedItems.ReservationList.filter(
        (item) => String(item.Id) !== String(quickHistoryObj.Id)
      );
    quickHistoryUpdatedItems.ReservationList = reservationItemsExceptRental;

    const updatedRentalList = getUpdatedQuickHistoryItems(
      quickHistoryUpdatedItems.RentalList,
      quickHistoryObj
    );
    quickHistoryUpdatedItems.RentalList = updatedRentalList;
  } else if (historyType === QuickHistoryType.MEMBER) {
    const updatedMemberList = getUpdatedQuickHistoryItems(
      quickHistoryUpdatedItems.MemberList,
      quickHistoryObj
    );
    quickHistoryUpdatedItems.MemberList = updatedMemberList;
  }
  localStorage.setItem(preFix, JSON.stringify(quickHistoryUpdatedItems));
};

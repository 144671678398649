const UpsellOptionModel = function (data) {
  if (!data) data = {};
  this.UpsellOptionId = data.UpsellOptionId || null;
  this.UpsellOptionUrl = data.UpsellOptionUrl || null;

  this.Definition = data.Definition || null;
  this.DisplayOrder = data.DisplayOrder || null;
  this.DisplayText = data.DisplayText || null;
  this.Notes = data.Notes || null;

  this.OrgUnitId = data.OrgUnitId || null;

  this.Rate = data.Rate || null;
  this.Status = data.Status || null;
  this.Type = data.Type || null;
  this.TypeDisplay = data.TypeDisplay || null;
  this.UnitType = data.UnitType || null;
  this.UnitTypeDisplay = data.UnitTypeDisplay || null;

  this.Value = data.Value || null;
  this.TotalPrice = data.TotalPrice || null;
  this.TotalUnit = data.TotalUnit || null;
  this.Taxable = data.Taxable || null;
  this.MaxQuantity = data.MaxQuantity || null;
  this.AutoApply = data.AutoApply || null;

  this.AdjustmentAllowed = data.AdjustmentAllowed || null;
  this.MinRate = data.MinRate || null;
  this.MaxRate = data.MaxRate || null;
  this.CustomerVisible = data.CustomerVisible || null;
  this.RateUnit = data.RateUnit || null;
  this.RateUnitDisplay = data.RateUnitDisplay || null;

  this.IncludedInPrice = data.IncludedInPrice || null;
  this.IncludedInPriceQuantity = data.IncludedInPriceQuantity || null;
  this.Quantity = data.Quantity || null;
};
export default UpsellOptionModel;

import React, { useState, useEffect } from "react";
import ReactHtmlParser from "react-html-parser";
import { Link } from "react-router-dom";
import {
  getSortingClassName,
  getSortType,
  hasPermission,
  getNoteTooltipHtml,
  getPhoneNumberText,
} from "../../../shared/utility";
import PermissionName from "../../../shared/Permissions";
import { EmployeeIcon, UserProfileLinkIcon } from "../../../shared/AppImages";
import ModalEngine from "../../../components/modal/modal-engine";
import { ADMIN_COMMON_CAPTION, ModalType } from "../../../shared/GlobalVar";
import ModalInfo from "../../../services/entities/models/ModalInfo";
import { COMMON_MSG } from "../../../shared/GlobalVar";
import { createTooltip } from "../../../shared/utility/tooltip-utility";

export const SearchRolesPermissionsGrid = (props) => {
  const [personData, setPersonData] = useState([...props.dataList]);
  const [allCbIcon, setAlllCbIcon] = useState("fa-null");
  const [allCbHeaderTooltip, setAllCbHeaderTooltip] = useState("Select All");
  const [tableRows, setTableRows] = useState(null);
  const [modalInfo, setModalInfo] = useState([new ModalInfo()]);
  const closeModal = () => {
    setModalInfo(new ModalInfo());
  };
  const showModal = (modalData) => {
    setModalInfo({
      type: modalData.type,
      showModal: true,
      memberId: modalData.memberId,
      memberIds: modalData.memberIds,
    });
  };
  useEffect(() => {
    getTableRows();
  }, [personData, allCbIcon, allCbHeaderTooltip]);

  useEffect(() => {
    setPersonData([...props.dataList]);
    setAlllCbIcon("fa-null");
  }, [props.dataList]);

  const openPermissionstoUserModal = (rowData) => {
    const personDetails = personData.slice();
    let memberIds = [];
    if (allCbIcon !== "fa-null") {
      personDetails.map((data, index) => {
        if (data.Checked) {
          memberIds.push(data.PersonId);
        }
      });
    } else {
      memberIds.push(rowData.PersonId);
    }
    showModal({
      type: ModalType.PERMISSION_TO_USERS,
      showModal: true,
      memberIds: memberIds,
    });
  };
  const openRolestoUsersModal = (rowData) => {
    const personDetails = personData.slice();
    let memberIds = [];
    if (allCbIcon !== "fa-null") {
      personDetails.map((data, index) => {
        if (data.Checked) {
          memberIds.push(data.PersonId);
        }
      });
    } else {
      memberIds.push(rowData.PersonId);
    }
    showModal({
      type: ModalType.ROLES_TO_USERS,
      showModal: true,
      memberIds: memberIds,
    });
  };
  const openAppAccesstoUsersModal = (rowData) => {
    const personDetails = personData.slice();
    let memberIds = [];
    if (allCbIcon !== "fa-null") {
      personDetails.map((data, index) => {
        if (data.Checked) {
          memberIds.push(data.PersonId);
        }
      });
    } else {
      memberIds.push(rowData.PersonId);
    }
    showModal({
      type: ModalType.APP_ACCESS_TO_USERS,
      showModal: true,
      memberIds: memberIds,
    });
  };
  const selectAllRows = () => {
    const personDetails = [...personData];
    personDetails.map((data, index) => {
      data.Checked = allCbIcon === "fa-check" ? false : true;
    });
    let cbAllCalssName = allCbIcon === "fa-check" ? "fa-null" : "fa-check";
    setPersonData(personDetails);
    setAlllCbIcon(cbAllCalssName);
    setAllCbHeaderTooltip(
      cbAllCalssName === "fa-check" ? "Deselect All" : "Select All"
    );
  };
  const selectSingleRows = (event, columnData) => {
    const personDetails = [...personData];
    let cbAllCalssName = "fa-null";
    let checkedValue = 0;
    personDetails.map((data, index) => {
      if (data.MembershipId === columnData.MembershipId) {
        data.Checked = event.target.checked;
      }
      if (data.Checked) {
        checkedValue++;
      }
    });
    if (checkedValue === 0) {
      cbAllCalssName = "fa-null";
    } else if (checkedValue === personDetails.length) {
      cbAllCalssName = "fa-check";
    } else {
      cbAllCalssName = "fa-minus";
    }
    setPersonData(personDetails);
    setAlllCbIcon(cbAllCalssName);
    setAllCbHeaderTooltip(
      cbAllCalssName === "fa-check" ? "Deselect All" : "Select All"
    );
  };

  const getTableRows = () => {
    let hrefLink = "#!";
    setTableRows(
      personData.map((post, index) => {
        let assignRoleCls = "gray-color",
          assignPermissionsCls = "gray-color",
          editAppAccessCls = "gray-color";
        const assignRole = hasPermission("ASSIGN_ROLE");
        const assignPermissions = hasPermission("ASSIGN_PERMISSION");
        const editAppAccess = hasPermission("EDIT_APPLICATION_ACCESS");

        if (assignRole) {
          assignRoleCls = "green-color cursor-pointer";
        }
        if (assignPermissions) {
          assignPermissionsCls = "red-color cursor-pointer";
        }
        if (editAppAccess) {
          editAppAccessCls = "green-color cursor-pointer";
        }
        if (allCbIcon === "fa-minus" && !post.Checked) {
          assignRoleCls = "gray-color";
          assignPermissionsCls = "gray-color";
          editAppAccessCls = "gray-color";
        }
        return (
          <div className="row" key={post.PersonId}>
            <span className="row__column text-center cb-container">
              <div className="icheck-primary roles-cb">
                <input
                  type="checkbox"
                  name={"cb" + post.PersonId}
                  id={"cb" + post.PersonId}
                  checked={post.Checked || false}
                  onChange={(event) => selectSingleRows(event, post)}
                />
                <label htmlFor={"cb" + post.PersonId} />
              </div>
            </span>
            <span className="row__column" id="membershipId">
              {post.MembershipId}
            </span>
            <span
              className="row__column row__column text-right"
              id="icoCcaLoginAllowed"
            >
              <span id={"icoCcaLoginAllowed_" + post.PersonId}>
                {createTooltip(
                  <i
                    className={`fa fa-flag ${
                      post.CCLoginAllowed ? "displayNone" : ""
                    }`}
                    style={{
                      color: "red",
                      fontSize: "1.1rem",
                    }}
                  />,
                  "No Call Center Application access",
                  { forceShow: true }
                )}
              </span>
            </span>
            <span
              className="row__column"
              data-toggle="modal"
              data-target="#primarymodal"
              style={{ borderRight: "none" }}
            >
              {hasPermission(PermissionName.VIEW_MEMBER)
                ? createTooltip(
                    <label
                      id={"employeeNameId" + post.PersonId}
                      className="pseudo-link"
                      onClick={(event) =>
                        showModal({
                          type: ModalType.MEMBER_QUICK_MODAL,
                          showModal: true,
                          memberId: post.PersonId,
                        })
                      }
                    >
                      {post.FullName}
                    </label>,
                    post.FullName
                  )
                : createTooltip(
                    <label id={"employeeNameId" + post.PersonId}>
                      {post.FullName}
                    </label>,
                    post.FullName
                  )}
            </span>
            <span className="row__column p-0 icon-right" id="icoEmployeeId">
              <span
                id={"icoEmployeeType_" + post.PersonId}
                className={
                  hasPermission(PermissionName.VIEW_MEMBER) ? "" : "displayNone"
                }
              >
                {createTooltip(
                  <Link
                    to={{
                      pathname: "/member/memberProfile",
                      search: "?memberId=" + post.PersonId,
                      state: {
                        breadCrumbList: props.breadCrumbList,
                        breadCrumbTitle: post.FullName,
                      },
                    }}
                  >
                    <img
                      alt=""
                      src={post.EmployeeNo ? EmployeeIcon : UserProfileLinkIcon}
                      style={{ height: "1.2rem", width: "1.2rem" }}
                    />
                  </Link>,
                  ReactHtmlParser(
                    getNoteTooltipHtml(
                      post.EmployeeNo
                        ? "Employee#: " +
                            post.EmployeeNo +
                            "<br>Location: " +
                            (post.EmployeeLocationName
                              ? post.EmployeeLocationName
                              : "--") +
                            "<br/><br/>" +
                            COMMON_MSG.UserProfileLinkIconTooltip
                        : COMMON_MSG.UserProfileLinkIconTooltip
                    )
                  ),
                  { forceShow: true }
                )}
              </span>
            </span>
            <span className="row__column" id={"title" + post.PersonId}>
              {createTooltip(
                post.EmployeeNo ? (post.Title ? post.Title : "--") : "--",
                post.EmployeeNo ? (post.Title ? post.Title : "") : ""
              )}
            </span>
            <span className="row__column" id={"department" + post.PersonId}>
              {createTooltip(
                post.EmployeeNo
                  ? post.Department
                    ? post.Department
                    : "--"
                  : "--",
                post.EmployeeNo ? (post.Department ? post.Department : "") : ""
              )}
            </span>
            <span className="row__column" id={"phoneId_" + post.PersonId}>
              {createTooltip(
                getPhoneNumberText(post, false),
                ReactHtmlParser(getPhoneNumberText(post, true)),
                { forceShow: true }
              )}
            </span>
            <span className="row__column" id={"email_" + post.PersonId}>
              {createTooltip(
                <a
                  href={"mailto:" + post.EmailAddress}
                  id={"email_" + post.PersonId}
                >
                  {post.EmailAddress}
                </a>,
                post.EmailAddress || "--"
              )}
            </span>
            <span className="row__column" id={"roleNames" + post.PersonId}>
              {createTooltip(
                post.Roles || "--",

                post.Roles ? post.Roles : ""
              )}
            </span>
            <span
              className="row__column"
              id={"employeeLocationName" + post.PersonId}
            >
              {createTooltip(
                post.EmployeeLocationName,
                post.EmployeeLocationName
              )}
            </span>
            <span
              className="row__column"
              id={"profileLocationName" + post.PersonId}
            >
              {createTooltip(
                post.ProfileLocationName,
                post.ProfileLocationName
              )}
            </span>
            <span className="row__column text-center">
              {createTooltip(
                <i
                  id={"appAccessId_" + post.PersonId}
                  className={"fa fa-user " + editAppAccessCls}
                  aria-hidden="true"
                  onClick={() =>
                    editAppAccess ? openAppAccesstoUsersModal(post) : ""
                  }
                />,
                editAppAccess
                  ? "Assign Call Center and Other Application(s) access"
                  : "You do not have permission to edit application access",
                { forceShow: true }
              )}

              {createTooltip(
                <i
                  id={"assignRolesToMemberId_" + post.PersonId}
                  className={"fa fa-users pl-2 " + assignRoleCls}
                  aria-hidden="true"
                  onClick={() =>
                    assignRole ? openRolestoUsersModal(post) : ""
                  }
                />,
                assignRole
                  ? "Assign Role"
                  : "You do not have permission to assign roles",
                { forceShow: true }
              )}

              {createTooltip(
                <i
                  id={"memberPermissionsPopupId_" + post.PersonId}
                  className={"fa fa-key pl-2 " + assignPermissionsCls}
                  aria-hidden="true"
                  onClick={() =>
                    assignPermissions ? openPermissionstoUserModal(post) : ""
                  }
                />,
                assignPermissions
                  ? "Assign Permissions"
                  : "You do not have permission to assign permissions",
                { forceShow: true }
              )}
            </span>
          </div>
        );
      })
    );
  };
  return (
    <div>
      <div className="datagrid">
        <div className="column">
          <span
            className="column__header text-center cb-container"
            id="rolesAllCheckBoxId"
          >
            <div
              className="checkBoxRolesPermissionsAll rolesAllCheckBox"
              onClick={(event) => selectAllRows()}
            >
              {createTooltip(
                <i
                  className={"fa checkBoxRolesPermisisonsFont " + allCbIcon}
                  aria-hidden="true"
                  style={{ marginRight: "0", marginTop: "0.1rem" }}
                >
                  &nbsp;
                </i>,
                allCbHeaderTooltip,
                {
                  forceShow: true,
                  ttcClass: "tooltipContainer-max width-max-content",
                  considerParent: true,
                }
              )}
            </div>
          </span>
          <span
            className={getSortingClassName(props.pageInfo, "MembershipId")}
            onClick={(event) =>
              props.doSorting(
                getSortType(props.pageInfo, "MembershipId"),
                "MembershipId"
              )
            }
          >
            {ADMIN_COMMON_CAPTION.CUSTOMER_ID}
          </span>
          <span
            className={getSortingClassName(props.pageInfo, "Name")}
            onClick={(event) =>
              props.doSorting(getSortType(props.pageInfo, "Name"), "Name")
            }
          >
            Name
          </span>
          <span
            className={getSortingClassName(props.pageInfo, "Title")}
            onClick={(event) =>
              props.doSorting(getSortType(props.pageInfo, "Title"), "Title")
            }
          >
            Title
          </span>
          <span className="column__header">Department</span>
          <span className="column__header">Phone No.</span>
          <span className="column__header">Email/Username</span>
          <span className="column__header">Role</span>
          <span
            className={getSortingClassName(props.pageInfo, "EmployeeLocation")}
            onClick={(event) =>
              props.doSorting(
                getSortType(props.pageInfo, "EmployeeLocation"),
                "EmployeeLocation"
              )
            }
          >
            Employee Location
          </span>
          <span
            className={getSortingClassName(props.pageInfo, "ProfileLocation")}
            onClick={(event) =>
              props.doSorting(
                getSortType(props.pageInfo, "ProfileLocation"),
                "ProfileLocation"
              )
            }
          >
            Profile Location
          </span>
          <span className="column__header text-center">Action</span>
        </div>
        {tableRows}
      </div>
      {modalInfo.showModal ? (
        <ModalEngine
          modalType={modalInfo.type}
          showModal={modalInfo.showModal}
          closeModal={closeModal}
          memberId={modalInfo.memberId}
          memberIds={modalInfo.memberIds}
          resetParentPage={props.resetParentPage}
        />
      ) : null}
    </div>
  );
};
export default SearchRolesPermissionsGrid;

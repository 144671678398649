const DrivingLicense = function (data) {
  if (!data) data = {};
  this.DateOfBirth = data.DateOfBirth || null;
  this.DrivingLicenseCountryId = data.DrivingLicenseCountryId
    ? data.DrivingLicenseCountryId
    : localStorage.getItem("orgUnitCountry")
    ? JSON.parse(localStorage.getItem("orgUnitCountry")).Key
    : null;
  this.DrivingLicenseStateId = data.DrivingLicenseStateId || null;
  this.DrivingLicenseStateText = data.DrivingLicenseStateText || null;
  this.DrivingLicenseNumber = data.DrivingLicenseNumber || null;
  this.LicenseExpirationDate = data.LicenseExpirationDate || null;
  this.IsInternationalLicense = data.IsInternationalLicense || null;
  this.DrivingLicenseImage1Url = data.DrivingLicenseImage1Url || null;
  this.DrivingLicenseImage2Url = data.DrivingLicenseImage2Url || null;
  this.DrivingRecordChecked = data.DrivingRecordChecked || null;
  this.DrivingLicenseDetails = data.DrivingLicenseDetails || null;
  this.DrivingRecordChDate = data.DrivingRecordChDate || null;
  this.LicenseApprovedBy = data.LicenseApprovedBy || null;
  this.LicenseManuallyApproved = data.LicenseManuallyApproved || null;
  this.ViolationPoints = data.ViolationPoints || null;
  this.ManualViolationPoints = data.ManualViolationPoints || null;
  this.ManualDmvVerified = data.ManualDmvVerified || null;
};
export default DrivingLicense;

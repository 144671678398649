import HttpService from "../../services/httpService";
import { getDropdownItems } from "../../shared/utility/store-utiility";
import * as actionTypes from "./actionTypes";

export const getEnumValueStart = () => {
  return {
    type: actionTypes.GET_ENUM_VALUE_START,
  };
};

export const getEnumValueSuccess = (response) => {
  return {
    type: actionTypes.GET_ENUM_VALUE_SUCCESS,
    activityLogProblemTypes: getDropdownItems(
      response.ActivityLogProblemTypes,
      "Name",
      "DisplayName"
    ),
    activityLogSubProblemTypes: getDropdownItems(
      response.ActivityLogSubProblemTypes,
      "Name",
      "DisplayName"
    ),
    paymentReasonTypes: response.PaymentReasonTypes,
    reservationCancellationReasons: response.ReservationCancellationReasons,
    integStatuses: response.IntegStatuses,
    billingTypes: response.BillingTypes,
    locationTypes: response.LocationTypes,
    revertFreeCreditReasons: getDropdownItems(
      response.RevertFreeCreditReason,
      "Name",
      "DisplayName"
    ),
  };
};

export const getEnumValueFail = (error) => {
  return {
    type: actionTypes.GET_ENUM_VALUE_FAIL,
    error: error,
  };
};

export const getEnumvalues = () => {
  return (dispatch) => {
    dispatch(getEnumValueStart());
    let requestBody = {
      Enums:
        "ActivityLogProblemType,ActivityLogSubProblemType,PaymentReasonType,ReservationCancellationReason,IntegStatus,RevertFreeCreditReason,LocationType,BillingType",
    };

    HttpService.getEnumvalues(requestBody)
      .then((response) => {
        dispatch(getEnumValueSuccess(response));
      })
      .catch((err) => {
        //dispatch(getConflictedMemberFail(err));
      });
  };
};

import { RootContainerCtrlModel } from "./root-container-ctrl-model";

export const LblInputCtrlModel = function (data) {
    if (!data) data = {};
    this.rootContainer= data.rootContainer ? new RootContainerCtrlModel(data.rootContainer) :null;
    this.lblRootContainer= data.lblRootContainer ? new RootContainerCtrlModel(data.lblRootContainer) :null;
    this.inputRootContainer= data.inputRootContainer ? new RootContainerCtrlModel(data.inputRootContainer) :null;
    this.defaultLblClass = data.defaultLblClass || null;
    this.defaultInputClass = data.defaultInputClass || null;
    /**list of LblInputFieldCtrlModel */
    this.lblInputFields = data.lblInputFields || [];
};
import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Draggable from "react-draggable";
import EyeIcon from "../../../components/UI/EyeIcon/eyeIcon";
import CommonInputField from "../../../components/UI/Input/common-input-field";
import {
  getErrorToastData,
  getFormattedValidationMsg,
  isNullOrEmpty,
} from "../../../shared/utility";
import { showNotification } from "../../../components/UI/Toaster/Toaster";

const ImpersonateModal = (props) => {
  const [password, setPassword] = useState("");
  const [inputType, setInputType] = useState("password");
  const [isValidateDone, setIsValidateDone] = useState(false);
  const passWordChangeHandler = (event) => {
    setPassword(event.target.value);
  };

  const doImpersonateLogin = () => {
    if (isNullOrEmpty(password)) {
      showNotification(
        getErrorToastData(getFormattedValidationMsg("Password"))
      );
      setIsValidateDone(true);
      return false;
    } else {
      setIsValidateDone(false);
    }
    props.onImpersonateLogin(props.memberId, password);
  };

  return (
    <Draggable handle=".cursorSubModal">
      <Modal
        isOpen={props.showModal}
        className="no-cursorSubModal"
        toggle={props.closeModal}
        style={{ minWidth: "425px" }}
        id="impersonate-modal-id"
      >
        <ModalHeader
          className="modal-header bg-primary modal-title text-white cursorSubModal"
          toggle={props.closeModal}
        >
          <i className="fas fa-arrows-alt" /> Impersonate
        </ModalHeader>
        <ModalBody style={{ maxHeight: "600px" }} className="tableScroll">
          <div className="input-container">
            <div className="FormRow">
              <div className={`form-group formRow`}>
                <p className="col-sm-12 col-form-label font-weight-bold text-justify">
                  Please confirm that you have authorization to 'Impersonate'{" "}
                  <span style={{ color: "#a51c30" }}>{props.fullName} </span>.
                  All actions taken in Impersonate mode are recorded and
                  available for Audit. Impersonating another user without
                  authorization can have severe consequences, up to and
                  including termination of employment.
                </p>
              </div>
              <div
                className={`form-group formRow required font-weight-bold position--relative`}
                style={{ marginRight: "0px" }}
              >
                <label className="col-sm-5 col-form-label font-weight-bold">
                  Password
                </label>
                <CommonInputField
                  elementConfig={{
                    type: inputType,
                    name: "password",
                    className:
                      "col-sm-7 " +
                      (password
                        ? ""
                        : isValidateDone
                        ? "validation-failed"
                        : ""),
                  }}
                  nodeElement="input"
                  otherConfig={{
                    isEyeIconRequired: true,
                    eyeIconCustomClass: "icon--right",
                  }}
                  onChange={passWordChangeHandler}
                  toggleInputTypeForPasswordField={setInputType}
                />
              </div>
            </div>
          </div>
          <ModalFooter>
            <button
              type="button"
              className="btn btn-primary waves-effect waves-light btnSize ml-2"
              onClick={doImpersonateLogin}
            >
              Submit
            </button>
            <button
              type="button"
              className="btn btn-secondary  waves-effect waves-light btnSize ml-2"
              onClick={props.closeModal}
            >
              Cancel
            </button>
          </ModalFooter>
        </ModalBody>
      </Modal>
    </Draggable>
  );
};
export default ImpersonateModal;

const VehicleSearchModel = function (data) {
  if (!data) data = {};
  this.AssetGroupType = data.AssetGroupType || null;
  this.AvailabilityStatus = data.AvailabilityStatus || null;
  this.Available = data.Available || null;
  this.AvailableTimeSlot = data.AvailableTimeSlot || null;
  this.Category = data.Category || null;
  this.CategoryId = data.CategoryId || null;
  this.ClassCode = data.ClassCode || null;
  this.CategoryType = data.CategoryType || null;
  this.DailyRate = data.DailyRate || null;

  this.Disclaimer = data.Disclaimer || null;

  this.DisplayDeductible = data.DisplayDeductible || null;

  //LICENSE INFORMATION START HERE
  this.DisplayInJsiMarketPlace = data.DisplayInJsiMarketPlace || null;
  this.DistanceFromUser = data.DistanceFromUser || null;
  this.DistanceUnit = data.DistanceUnit || null;
  this.DoorStatus = data.DoorStatus || null;
  this.DrivingDuration = data.DrivingDuration || null;
  this.ExecutionMode = data.ExecutionMode || null;
  this.Favorite = data.Favorite || null;
  this.FuelType = data.FuelType || null;
  this.HalfDayRate = data.HalfDayRate || null;
  this.HeaderLabel = data.HeaderLabel || null;

  this.HourlyRate = data.HourlyRate || null;
  this.InstantRentalAllowed = data.InstantRentalAllowed || false;
  this.JsiLicenseRequired = data.JsiLicenseRequired || false;
  this.KeyLessMode = data.KeyLessMode || null;
  this.Latitude = data.Latitude || null;
  this.LeadTimeDbInMinutes = data.LeadTimeDbInMinutes || null;
  this.LeadTimeInMinutes = data.LeadTimeInMinutes || null;

  this.LicenseRequired = data.LicenseRequired || null;
  this.ListingDate = data.ListingDate || null;
  this.LockStatus = data.LockStatus || null;
  this.Longitude = data.Longitude || null;

  this.Make = data.Make || null;
  this.Model = data.Model || null;
  this.MonthlyRate = data.MonthlyRate || null;

  this.Name = data.Name || null;
  this.OwnerAvgRating = data.OwnerAvgRating || null;
  this.OwnerAvgResponseTime = data.OwnerAvgResponseTime || null;
  this.OwnerEmail = data.OwnerEmail || null;
  this.OwnerHomePhone = data.OwnerHomePhone || null;
  this.OwnerHomePhoneNumber = data.OwnerHomePhoneNumber || null;
  this.OwnerHomePhoneNumberIso = data.OwnerHomePhoneNumberIso || null;

  this.OwnerId = data.OwnerId || null;
  this.OwnerMobilePhone = data.OwnerMobilePhone || null;
  this.OwnerMobilePhoneNumber = data.OwnerMobilePhoneNumber || null;
  this.OwnerMobilePhoneNumberIso = data.OwnerMobilePhoneNumberIso || null;
  this.OwnerName = data.OwnerName || null;
  this.OwnerRatingCount = data.OwnerRatingCount || null;
  this.OwnerResponseRate = data.OwnerResponseRate || null;
  this.OwnerWorkPhone = data.OwnerWorkPhone || null;

  this.OwnerWorkPhoneNumber = data.OwnerWorkPhoneNumber || null;
  this.OwnerWorkPhoneNumberIso = data.OwnerWorkPhoneNumberIso || null;
  this.ParkedText = data.ParkedText || null;
  this.PartialAvailable = data.PartialAvailable || null;
  this.PersonAssetOrgUnitDisplayName =
    data.PersonAssetOrgUnitDisplayName || null;
  this.PersonAssetOrgUnitId = data.PersonAssetOrgUnitId || null;
  this.QrCode = data.QrCode || null;

  this.RateUnit = data.RateUnit || null;
  this.RideLinkInstalled = data.RideLinkInstalled || false;
  this.SharerDailyRate = data.SharerDailyRate || null;
  this.SharerHalfDayRate = data.SharerHalfDayRate || null;
  this.SharerHourlyRate = data.SharerHourlyRate || null;
  this.SharerMonthlyRate = data.SharerMonthlyRate || null;
  this.SharerWeekendRate = data.SharerWeekendRate || null;

  this.SharerWeeklyRate = data.SharerWeeklyRate || null;
  this.ShowOriginalPrice = data.ShowOriginalPrice || null;
  this.ShowSharerPrice = data.ShowSharerPrice || null;
  this.TransmissionType = data.TransmissionType || null;
  this.UnitNo = data.UnitNo || null;
  this.VehicleId = data.VehicleId || null;
  this.VehicleLocation = data.VehicleLocation || null;

  this.VehicleStatusDesc = data.VehicleStatusDesc || null;
  this.Vin = data.Vin || null;
  this.WalkingDuration = data.WalkingDuration || null;
  this.WeekendRate = data.WeekendRate || null;
  this.WeeklyRate = data.WeeklyRate || null;
  this.Year = data.Year || null;
};
export default VehicleSearchModel;

import React, { useEffect, useState } from "react";
import Draggable from "react-draggable";
import { useDispatch, useSelector } from "react-redux";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { showNotification } from "../../../components/UI/Toaster/Toaster";
import { MailType } from "../../../shared/GlobalVar";
import {
  getBasicToastResponse,
  getPrintPopup,
} from "../../../shared/utility";
import { getInvoice, getInvoiceReset } from "../../../store/actions/index";
import MailModal from "../../search/modal/mail-modal";

export const ReservationSummaryInvoiceModal = (props) => {
  const reservationId = props.reservationId;
  const dispatch = useDispatch();
  const invoiceResponse = useSelector(
    (state) => state.reservationReducer.invoiceResponse
  );
  const [invoiceHtml, setInvoiceHtml] = useState(null);
  const [showMailModal, setShowMailModal] = useState(false);
  useEffect(() => {
    const invoiceReq = {};
    invoiceReq.Preview = false;
    invoiceReq.ReservationId = reservationId;
    dispatch(getInvoiceReset());
    dispatch(getInvoice(invoiceReq));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (!invoiceResponse) {
      return;
    }
    if (invoiceResponse.StatusCode === 0) {
      setInvoiceHtml(invoiceResponse.Invoice);
    } else {
      showNotification(getBasicToastResponse(invoiceResponse));
      dispatch(getInvoiceReset());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceResponse]);
  const closeMaillModal = () => {
    setShowMailModal(false);
  };
  const getFormattedText = (data) => {
    return {
      __html: data,
    };
  };

  return (
    <Draggable handle=".cursor">
      <Modal
        isOpen={props.showModal}
        className="no-cursor"
        toggle={() => {
          props.closeModal();
        }}
        style={{
          minWidth: "80rem",
        }}
      >
        <ModalHeader
          className="modal-header bg-primary modal-title text-white cursor"
          toggle={() => {
            props.closeModal();
          }}
        >
          <i className="fas fa-arrows-alt" /> Invoice Details
        </ModalHeader>
        <ModalBody>
          <div
            className="col-lg-12 tableScroll"
            style={{
              minHeight: "53rem",
            }}
          >
            <div className="col-lg-12 text-right">
              <button
                type="button"
                name="btnAddNewMember"
                className="btn btn-primary waves-effect waves-light btnSize ml-2"
                onClick={() => getPrintPopup("Invoice")}
                disabled={invoiceHtml ? false : true}
              >
                Print
              </button>

              <button
                type="button"
                name="btnAddNewMember"
                className="btn btn-primary waves-effect waves-light btnSize ml-2"
                onClick={() => setShowMailModal(true)}
                disabled={invoiceHtml ? false : true}
              >
                Email
              </button>
            </div>
            <div
              id="divPrintbody"
              className="print_popup_body col-lg-12 row res-invoice"
              dangerouslySetInnerHTML={getFormattedText(
                invoiceHtml ? invoiceHtml : ""
              )}
            />
          </div>
          {showMailModal ? (
            <MailModal
              showModal={showMailModal}
              closeModal={closeMaillModal}
              defaultData={{
                reservationId: props.reservationId,
                mailType: MailType.EMAIL_INVOICE,
              }}
            />
          ) : (
            ""
          )}
        </ModalBody>
      </Modal>
    </Draggable>
  );
};
export default ReservationSummaryInvoiceModal;

import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import PermissionName from "../../../shared/Permissions";
import { ModalType } from "../../../shared/GlobalVar";
import ModalEngine from "../../../components/modal/modal-engine";
import ModalInfo from "../../../services/entities/models/ModalInfo";
import {
  hasPermission,
  loadDefaultImage,
} from "../../../shared/utility";
import {
  noImage,
  NoDeviceRidelink,
  KeywithhandRidelink,
  InstalldeviceRidelink,
} from "../../../shared/AppImages";
import VehicleBottomContainer from "../../Member/member-vehicle/vehicle-bottom-container";
import IconListForReservation from "../../SearchCommonData/iconListComponent";
import { createTooltip } from "../../../shared/utility/tooltip-utility";
export const SearchMyVehicleGrid = (props) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedAssets, setSelectedAssets] = useState(null);
  const [modalInfo, setModalInfo] = useState([new ModalInfo()]);
  const closeModal = () => {
    setModalInfo(new ModalInfo());
  };
  const showModal = (modalData) => {
    setModalInfo({
      type: modalData.type,
      showModal: true,
      memberId: modalData.memberId,
      vehicleId: modalData.vehicleId,
    });
  };
  const handleOnRadioButtonChange = (event) => {
    const index = event.target.dataset.id;
    setSelectedOption("select" + index);
    setSelectedAssets(
      props.dataList ? props.dataList[event.target.dataset.id] : null
    );
  };
  const renderTableData = () => {
    let editClass = "gray-color";
    if (hasPermission("EDIT_VEHICLE")) {
      editClass = "blue-color cursor-pointer";
    }
    return props.dataList.map((post, index) => {
      let executionMode = post.ExecutionMode != null ? post.ExecutionMode : "";
      let executionModeImage =
        executionMode === "Keys Provided By Owner"
          ? NoDeviceRidelink
          : executionMode === "RideLink Installed but Keys provided by Owner"
          ? KeywithhandRidelink
          : executionMode === "RideLink Installed - Keyless Entry"
          ? InstalldeviceRidelink
          : noImage;
      return (
        <div className="row" key={index}>
          <span className="row__column text-center icheck-primary">
            <input
              value={"select" + index}
              id={"rbtnSelect" + index}
              data-id={index}
              type="radio"
              name="selectVehicle"
              checked={selectedOption === "select" + index}
              onChange={(event) => handleOnRadioButtonChange(event)}
            />
            <label htmlFor={"rbtnSelect" + index}></label>
          </span>
          <span className="row__column">
            <IconListForReservation resData={post} pageName="vehicle" />
          </span>

          <span className="row__column text-center">
            <img
              style={{ width: "2rem", height: "1.4rem" }}
              onError={(event) => loadDefaultImage(event, noImage)}
              src={post.Image || noImage}
              alt="asset-img"
              width="50"
              height="30"
            />
          </span>
          <span className="row__column">
            <label
              className={`row__column ${
                hasPermission(PermissionName.VIEW_VEHICLE) ? "pseudo-link" : ""
              }`}
              id={"mem_" + post.PersonAssetId}
              onClick={() =>
                hasPermission(PermissionName.VIEW_MEMBER)
                  ? showModal({
                      type: ModalType.VEHICLE_QUICK_MODAL,
                      showModal: true,
                      vehicleId: post.MemberAssetId,
                    })
                  : ""
              }
            >
              {createTooltip(post.Name, post.Name)}
            </label>
          </span>
          <span className="row__column text-center">
            {String(post.InstantRentalEnabled)}
          </span>
          <span className="row__column text-center">
            {String(post.ListingsEnabled)}
          </span>
          <span
            className="row__column text-center"
            id={"transmissionMode_" + index}
          >
            {createTooltip(post.TransmissionMode, post.TransmissionMode)}
          </span>
          <span className="row__column text-center">
            {createTooltip(
              <img
                style={{ width: "1.4rem", height: "1.4rem" }}
                onError={(event) => loadDefaultImage(event, noImage)}
                src={executionModeImage}
                alt="asset-img"
                width="50"
                height="30"
                id={"executionMode_" + index}
              />,
              post.ExecutionMode,
              { forceShow: true }
            )}
          </span>
          <span className="row__column text-center">
            {String(post.DeviceInstalled)}
          </span>
          <span className="row__column text-center">
            {post.OverallRating || "--"}
          </span>
          <span className="row__column text-center" id={"Status_" + index}>
            {createTooltip(
              post.Status ? post.Status.Key : "--",
              post.Status ? post.Status.Key : ""
            )}
          </span>

          <span className="row__column text-center">Check Availability</span>
          <span className="row__column text-center">
            {hasPermission(PermissionName.VIEW_VEHICLE) ? (
              <Link
                id={"editVehicle_" + post.PersonAssetId}
                to={{
                  pathname: "/vehicle_details/vehicle_profile",
                  search: "?vehicleId=" + post.PersonAssetId,
                  state: {
                    state: { breadCrumbList: props.breadCrumbList },
                    vehicleId: post.PersonAssetId,
                    isEditMode: true,
                  },
                }}
              >
                {createTooltip(
                  <i
                    className={"fa fa-pencil-alt fs-3 " + editClass}
                    aria-hidden="true"
                  />,
                  "Edit",
                  { forceShow: true }
                )}
              </Link>
            ) : (
              ""
            )}
          </span>
        </div>
      );
    });
  };
  return (
    <Fragment>
      <div className="datagrid">
        <div className="column">
          <span className="column__header">Select</span>
          <span className="column__header"></span>
          <span className="column__header">Image</span>
          <span className="column__header">Name</span>
          <span className="column__header">Rapid Res.</span>
          <span className="column__header">Displayable</span>
          <span className="column__header">Transmission</span>
          <span className="column__header">Hand Off</span>
          <span className="column__header">RideLink</span>
          <span className="column__header">Rating</span>
          <span className="column__header">Status</span>
          <span className="column__header">Availability</span>
          <span className="column__header">Action</span>
        </div>
        {renderTableData()}
      </div>
      <VehicleBottomContainer
        isSelectAssetRow={selectedAssets ? true : false}
        selectedAssets={selectedAssets}
        breadCrumbList={props.breadCrumbList}
        resetParentPage={props.resetParentPage}
      />
      {modalInfo.showModal ? (
        <ModalEngine
          modalType={modalInfo.type}
          showModal={modalInfo.showModal}
          closeModal={closeModal}
          memberId={modalInfo.memberId}
        />
      ) : null}
    </Fragment>
  );
};
export default SearchMyVehicleGrid;

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  updateVehicleMode,
  updateVehicleModeReset,
} from "../../../../store/actions/index";
import Spinner from "../../../../components/UI/Spinner/Spinner";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Draggable from "react-draggable";
import { showNotification } from "../../../../components/UI/Toaster/Toaster";
import { getBasicToastResponse } from "../../../../shared/utility";
import { useState } from "react";
import CommonInputField from "../../../../components/UI/Input/common-input-field";

const UpdateVehicleMode = (props) => {
  const loading = useSelector((state) => state.vehicleReducer.loading);
  const updateVehicleModeResponse = useSelector(
    (state) => state.vehicleReducer.updateVehicleModeResponse
  );
  const [bookingMode, setBookingMode] = useState(props.bookingEnabled);
  const [reservationMode, setReservationMode] = useState(props.reservationMode);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!updateVehicleModeResponse) {
      return;
    }
    showNotification(getBasicToastResponse(updateVehicleModeResponse));
    if (updateVehicleModeResponse.StatusCode === 0) {
      props.closeModal();
    }
    dispatch(updateVehicleModeReset());
  }, [updateVehicleModeResponse]);

  const saveData = () => {
    const req = {};
    req.MemberId = props.memberId;
    req.MemberAssetId = props.memberAssetId;
    req.BookingMode = bookingMode;
    req.ReservationMode = reservationMode;
    req.ExecutionMode = null;
    req.DeviceValue = null;
    req.ReservationId = null;
    updateVehicleModeInfo(req);
  };

  const updateVehicleModeInfo = (req) => {
    dispatch(updateVehicleModeReset());
    dispatch(updateVehicleMode(req));
  };

  const inputChangeHandler = (event, controlName) => {
    let value = event.target.checked;
    controlName === "ReservationMode"
      ? setReservationMode(value)
      : setBookingMode(value);
  };

  const renderCheckbox = (labelCaption, id, name, checkedVal) => {
    return (
      <div className={`form-group formRow`}>
        <CommonInputField
          elementConfig={{
            id: id,
            type: "checkbox",
            checked: checkedVal,
            name: name,
          }}
          otherConfig={{
            caption: labelCaption,
          }}
          nodeElement="checkbox"
          onChange={inputChangeHandler}
        />
      </div>
    );
  };

  const renderData = () => {
    return (
      <div>
        <section>
          <div className="input-container">
            {renderCheckbox(
              "Rapid Reservation",
              "reservationMode",
              "ReservationMode",
              reservationMode
            )}
            {renderCheckbox(
              "Listings Enabled",
              "bookingMode",
              "BookingMode",
              bookingMode
            )}
          </div>
        </section>
      </div>
    );
  };

  const getModalSettings = () => {
    return {
      modalWidth: props.width || "auto",
      modalBodyHeight: props.height || "auto",
    };
  };

  let modalSettings = getModalSettings();
  return (
    <div>
      <Draggable handle=".cursor">
        <Modal
          isOpen={props.showModal}
          className="no-cursor"
          toggle={() => {
            props.closeModal();
          }}
          style={{
            minWidth: modalSettings.modalWidth + "px",
          }}
        >
          <ModalHeader
            className="modal-header bg-primary modal-title text-white cursor"
            toggle={() => {
              props.closeModal();
            }}
          >
            <i className="fas fa-arrows-alt" /> {props.title}
          </ModalHeader>
          <ModalBody
            style={{
              height: modalSettings.modalBodyHeight + "px",
            }}
          >
            <div
              className="form-group row"
              style={{
                height: modalSettings.modalBodyHeight - 20 + "px",
              }}
            >
              <div className="FormRow col-sm-12">{renderData()}</div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              name="btnSubmit"
              className="btn btn-primary waves-effect waves-light btnSize ml-2"
              onClick={(event) => saveData()}
            >
              <i className="fas fa-save pr-1"></i>Submit
            </button>
            <button
              type="button"
              name="btnCancel"
              className="btn btn-secondary waves-effect waves-light btnSize ml-2"
              onClick={(event) => props.closeModal()}
            >
              <i className="fas fa-times pr-1"></i>Cancel
            </button>
          </ModalFooter>
        </Modal>
      </Draggable>
    </div>
  );
};
export default UpdateVehicleMode;

import React, { useCallback } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { showNotification } from '../../../components/UI/Toaster/Toaster';
import { getBasicToastResponse } from '../../../shared/utility';

function ResCopyClipboard(props) {
    const { value, children } = props;
    const onCopy = useCallback(() => {
        setTimeout(() => {
            showNotification(
                getBasicToastResponse({
                    StatusMsg:
                        props.successMsg || "Data has been copied to clipboard!",
                    StatusCode: 0,
                })
            );
        }, props.delayTime || 500);
    }, [props.delayTime, props.successMsg]);

    return (
        <CopyToClipboard onCopy={onCopy} text={value}>
            {children}
        </CopyToClipboard>
    )
}

export default ResCopyClipboard
import activityReducer from "./activityReducer";
import addonsReducer from "./addonsReducer";
import addressReducer from "./addressReducer";
import adminMailSendReducer from "./adminMailSendReducer";
import adminOptionReducer from "./adminOptionReducer";
import agentReducer from "./agentReducer";
import assetInOutReducer from "./assetInOutReducer";
import authReducer from "./authenticationReducer";
import availabilitySummaryFleetReducer from "./availabilitySummaryFleetReducer";
import blackListedPersonsListReducer from "./blackListedPersonsListReducer";
import besicAddrReducer from "./countryStateCityZipReducer";
import damageReducer from "./damageReducer";
import documentAttachReducer from "./documentAttachReducer";
import endRentalReducer from "./endRentalReducer";
import enumvaluesReducer from "./enumvaluesReducer";
import excelTableReducer from "./excelTableReducer";
import feedbackReducer from "./feedbackReducer";
import fleetUtilizationForecastReducer from "./fleet-utilization-forecast-reducer";
import fleetUtilizationSummaryReducer from "./fleetUtilizationSummaryReducer";
import foxAPIReducer from "./foxAPIReducer";
import impersonateReducer from "./impersonateReducer";
import invoiceInformationReducer from "./invoiceInformationReducer";
import licenseApprovalReducer from "./licenseApprovalReducer";
import locationBasedSettingsReducer from "./locationBasedSettingsReducer";
import locationReducer from "./locationReducer";
import memberReducer from "./memberReducer";
import orgDetailReducer from "./orgDetailReducer";
import permissionsReducer from "./permissionsReducer";
import reportReducer from "./reportReducer";
import reservationChargesReducer from "./reservationChargesReducer";
import reservationReducer from "./reservationReducer";
import rolesPermissionsReducer from "./rolesPermissionsReducer";
import rolesReducer from "./rolesReducer";
import searchPagesReducer from "./searchPagesReducer";
import securityDepositReducer from "./security-deposit-reducer";
import serviceTaskReducer from "./serviceTaskReducer";
import settingsReducer from "./settingsReducer";
import shuttleAPIReducer from "./shuttle-app-reducer";
import shuttleOptionReducer from "./shuttle-option-reducer";
import transactionReducer from "./transactionReducer";
import uploadFileReducer from "./uploadFileReducer";
import vehicleReducer from "./vehicleReducer";
import vicinityReducer from "./vicinityReducer";

export const RootReducer = {
  auth: authReducer,
  impersonateReducer: impersonateReducer,
  memberReducer: memberReducer,
  besicAddrReducer: besicAddrReducer,
  rolesPermissionsReducer: rolesPermissionsReducer,
  permissionsReducer: permissionsReducer,
  rolesReducer: rolesReducer,
  enumvaluesReducer: enumvaluesReducer,
  locationBasedSettingsReducer: locationBasedSettingsReducer,
  reservationChargesReducer: reservationChargesReducer,
  endRentalReducer: endRentalReducer,
  addonsReducer: addonsReducer,
  invoiceInformationReducer: invoiceInformationReducer,
  transactionReducer: transactionReducer,
  agentReducer: agentReducer,
  damageReducer: damageReducer,
  orgDetailReducer: orgDetailReducer,
  documentAttachReducer: documentAttachReducer,
  licenseApprovalReducer: licenseApprovalReducer,
  uploadFileReducer: uploadFileReducer,
  addressReducer: addressReducer,
  fleetUtilizationSummaryReducer: fleetUtilizationSummaryReducer,
  assetInOutReducer: assetInOutReducer,
  availabilitySummaryFleetReducer: availabilitySummaryFleetReducer,
  reservationReducer: reservationReducer,
  serviceTaskReducer: serviceTaskReducer,
  vehicleReducer: vehicleReducer,
  feedbackReducer: feedbackReducer,
  activityReducer: activityReducer,
  reportReducer: reportReducer,
  locationReducer: locationReducer,
  settingsReducer: settingsReducer,
  blackListedPersonsListReducer: blackListedPersonsListReducer,
  searchPagesReducer: searchPagesReducer,
  excelTableReducer: excelTableReducer,
  foxAPIReducer: foxAPIReducer,
  shuttleAPIReducer: shuttleAPIReducer,
  shuttleOptionReducer: shuttleOptionReducer,
  vicinityReducer: vicinityReducer,
  adminOptionReducer: adminOptionReducer,
  fleetUtilizationForecastReducer: fleetUtilizationForecastReducer,
  adminMailSendReducer: adminMailSendReducer,
  securityDepositReducer: securityDepositReducer,
};

import $ from "jquery";
import { camelCase } from "lodash";
import { default as Moment, default as moment } from "moment";
import momentTimezone from "moment-timezone";
import numeral from "numeral-es6";
import ReactHtmlParser from "react-html-parser";
import HttpService from "../services/httpService";
import * as global from "./GlobalVar";
import PermissionName from "./Permissions";

export const updateObject = (oldObject, updatedProperties) => {
  return {
    ...oldObject,
    ...updatedProperties,
  };
};

export const checkValidity = (value, rules) => {
  let isValid = true;
  if (!rules) {
    return true;
  }

  if (rules.required) {
    isValid = value.trim() !== "" && isValid;
  }

  if (rules.minLength) {
    isValid = value.length >= rules.minLength && isValid;
  }

  if (rules.maxLength) {
    isValid = value.length <= rules.maxLength && isValid;
  }

  if (rules.isEmail) {
    const pattern =
      /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    isValid = pattern.test(value) && isValid;
  }

  if (rules.isNumeric) {
    const pattern = /^\d+$/;
    isValid = pattern.test(value) && isValid;
  } else if (rules.isAlpha) {
    const pattern = /[ A-Za-z]/;
    isValid = pattern.test(value) && isValid;
  } else if (rules.isAlphaNumeric) {
    const pattern = /[ A-Za-z0-9]/;
    isValid = pattern.test(value) && isValid;
  } else if (rules.isNumber) {
    isValid = isNaN(value) ? false : true;
  }

  return isValid;
};

export const openURLIntoNewTab = (url) => {
  let win = window.open(url, "_blank");
  win.focus();
};

export const getFormattedAddress = (addressObj) => {
  if (!addressObj) return "";
  let formattedAddress = "";
  if (addressObj.Street1) {
    formattedAddress = addressObj.Street1;
  }
  if (addressObj.Street2) {
    formattedAddress = formattedAddress
      ? formattedAddress + ", " + addressObj.Street2
      : addressObj.Street2;
  }
  if (addressObj.Street3) {
    formattedAddress = formattedAddress
      ? formattedAddress + ", " + addressObj.Street3
      : addressObj.Street3;
  }
  if (addressObj.Street4) {
    formattedAddress = formattedAddress
      ? formattedAddress + ", " + addressObj.Street4
      : addressObj.Street4;
  }
  if (addressObj.CityText) {
    formattedAddress = formattedAddress
      ? formattedAddress + ", " + addressObj.CityText
      : addressObj.CityText;
  }
  if (addressObj.StateText) {
    formattedAddress = formattedAddress
      ? formattedAddress + ", " + addressObj.StateText
      : addressObj.StateText;
  }
  if (addressObj.ZipCodeText) {
    formattedAddress = formattedAddress
      ? formattedAddress + ", " + addressObj.ZipCodeText
      : addressObj.ZipCodeText;
  }
  if (addressObj.CountryText) {
    formattedAddress = formattedAddress
      ? formattedAddress + ", " + addressObj.CountryText
      : addressObj.CountryText;
  }
  return formattedAddress;
};
export const getSortingClassName = (pageInfo, eventType) => {
  if (pageInfo.SortType === "ASC" && eventType === pageInfo.SortBy) {
    return "column__header sorting_dsc";
  } else if (pageInfo.SortType === "DESC" && eventType === pageInfo.SortBy) {
    return "column__header sorting_asc";
  } else {
    return "column__header sorting";
  }
};
export const getSortType = (pageInfo, eventType) => {
  if (pageInfo.SortType === "ASC" && eventType === pageInfo.SortBy) {
    return "DESC";
  } else if (pageInfo.SortType === "DESC" && eventType === pageInfo.SortBy) {
    return "ASC";
  } else {
    return "DESC";
  }
};
export const getInfoFromParamQuery = (paramName, location) => {
  const query = new URLSearchParams(location);
  return query.get(paramName);
};
export const convertDateTimeString = (dateVal, defaultValue, splitValue) => {
  const splitStr = splitValue ? splitValue : "/";
  let amOrPm, formattedString;
  if (dateVal) {
    if (dateVal.split(splitStr).length > 2) {
      return dateVal;
    }
    let splitted = dateVal.split(":");
    let hour =
      splitted[3] === "12" || splitted[3] === "00"
        ? "12"
        : splitted[3] < 12
        ? splitted[3]
        : splitted[3] - 12;
    amOrPm = splitted[3] < 12 ? " AM" : " PM";
    formattedString =
      splitted[0] +
      "/" +
      splitted[1] +
      "/" +
      splitted[2] +
      " " +
      (String(hour).length === 2 ? hour : "0" + hour) +
      ":" +
      splitted[4];
  }
  return dateVal ? formattedString + amOrPm : defaultValue || "";
};
export const formatPricingValue = (number, defaultValue, currencySign) => {
  currencySign = currencySign || "$";
  if (isNaN(parseFloat(number))) {
    return defaultValue || "";
  }
  if (number < 0) {
    number = number * -1;
    number = "(" + currencySign + parseFloat(number).toFixed(2) + ")";
  } else {
    number = parseFloat(number).toFixed(2);
    number = currencySign + number;
  }
  return number;
};
export const hasPermission = (permissionName) => {
  let isPermissionFound = false;
  let userPermissions =
    localStorage.getItem("userPermissions") != null
      ? JSON.parse(localStorage.getItem("userPermissions"))
      : null;

  if (userPermissions != null) {
    userPermissions.forEach((permisison) => {
      if (permisison === permissionName) {
        isPermissionFound = true;
        return false;
      }
    });
    return isPermissionFound;
  }
};
export const getFormattedVehicleName = (
  unitno,
  make,
  model,
  year,
  showUnitId
) => {
  let vname = "";
  if (!make && !model && !year && (!showUnitId || (showUnitId && !unitno))) {
    return "--";
  }
  vname += make || "--";
  vname += ", ";
  vname += model || "--";
  vname += ", ";
  vname += year || "--";
  if (showUnitId) {
    if (unitno) {
      vname += " (" + unitno + ")";
    } else {
      vname += " (--)";
    }
  }
  return vname;
};
export const printCanvasPhoto = (canvasId) => {
  const canvas = document.getElementById(canvasId);
  const img = canvas.toDataURL();
  const docprint = window.open(
    "",
    "",
    "fullscreen = yes",
    "width =800",
    "height = 400"
  );
  docprint.document.open();
  docprint.document.write(
    '<html><head><style type="text/css" media="print"> html, body,.page { height: 100%;display: flex;justify-content: center;align-items: center;margin:0;padding:0 }</style></head><body><center>'
  );
  docprint.document.write('<img src="' + img + '"/>');
  docprint.document.write("</center></body></html>");
  docprint.document.close();
  docprint.focus();
  docprint.print();
  //docprint.close();
};
export const getPrintPopup = (requestfrom, content) => {
  let docprint = window.open(
    "",
    "",
    "fullscreen = yes",
    "width =800",
    "height = 400"
  );
  checkForPopupBlocker(docprint, function () {
    const text = content ? content : $("#divPrintbody").html();
    docprint.document.open();
    if (String(requestfrom) === "Contract") {
      docprint.document.write("<html><head><title>Contract</title>");
    } else if (String(requestfrom) === "Reservation") {
      docprint.document.write("<html><head><title>Reservation</title>");
    } else if (String(requestfrom) === "Invoice") {
      docprint.document.write("<html><head><title>Invoice</title>");
    }
    docprint.document.write(
      '<style type="text/css" media="print"> @page { size: auto;   margin: 10mm; }</style>'
    );
    docprint.document.write("</head><body><center>");
    docprint.document.write(text);
    docprint.document.write("</center></body></html>");

    docprint.document.title = "";
    docprint.document.close();
    docprint.print();
    docprint.close();
  });
};
export const checkForPopupBlocker = (popup, callback) => {
  setTimeout(function () {
    if (!popup || popup.outerHeight === 0) {
      //First Checking Condition Works For IE & Firefox
      //Second Checking Condition Works For Chrome
      //Utility.showDialog("Popup Blocker is enabled! Please add this site to your exception list.", null, Utility.dialogType.ALERT);

      var options = {
        bodyHtml:
          "Popup Blocker is enabled! Please add this site to your exception list.",
        yesBtnName: global.OK_LBL,
      };
      $(".panelWindow").foxConfirm(options);
    } else {
      //Popup Blocker Is Disabled
      if (callback && typeof callback === "function") {
        callback();
      }
    }
  }, 50);
};

export const getHourList = (hr12) => {
  let hourList = [];
  let hourListObj = {};
  let numberOfHours = hr12 ? 13 : 24;
  let startingHour = hr12 ? 1 : 0;
  for (let i = startingHour; i < numberOfHours; i++) {
    hourListObj = {};
    let hr = i < 10 ? "0" + i : i.toString();
    let hrtext = hr;
    hourListObj.value = hr;
    hourListObj.display = hrtext;
    hourList.push(hourListObj);
  }

  return hourList;
};

export const getHourAndMinuteId = (timeValue, type) => {
  let id = "";
  if (type === "hour") {
    let sHours = parseInt(timeValue / 60);
    id = sHours < 10 ? "0" + sHours : sHours.toString();
  } else if (type === "minute") {
    let sMinutes = parseInt(timeValue % 60);
    id = sMinutes < 10 ? "0" + sMinutes : sMinutes.toString();
  }
  return id;
};

export const getMinutesList = (itemList) => {
  let minuteList = [];
  let minuteListObj = {};
  for (let i = 0; i < 60; i++) {
    minuteListObj = {};
    let min = i < 10 ? "0" + i : i;
    minuteListObj.value = min;
    minuteList.push(minuteListObj);
  }
  return minuteList;
};

export const getHoursId = (hours, hourFormat) => {
  return hours === 0 || hours === 12
    ? hourFormat
      ? "12"
      : hours === 0
      ? "00"
      : "12"
    : hours < 10
    ? "0" + hours
    : hours % 12 < 10
    ? hourFormat
      ? "0" + (hours % 12)
      : String(hours)
    : hourFormat
    ? String(hours % 12)
    : String(hours);
};

export const isNullOrEmpty = (dataVal) => {
  let nullOrEmpty = false;
  if (
    dataVal === null ||
    dataVal === "" ||
    dataVal === "undefined" ||
    dataVal === undefined
  ) {
    nullOrEmpty = true;
  }
  return nullOrEmpty;
};

export const getDateOnlyString = (dateVal) => {
  let formattedString;
  if (dateVal) {
    if (dateVal.split("/").length > 2) {
      return dateVal;
    }
    let splitted = dateVal.split(":");
    formattedString = splitted[0] + "/" + splitted[1] + "/" + splitted[2];
  } else {
    formattedString = "-";
  }
  return formattedString;
};

export const getDateTimeString = (dateVal, isIncludeMilliSec, isIncludeSec) => {
  let amOrPm, formattedString;
  let splitted = [];
  if (dateVal) {
    let splitted = dateVal.split(":");
    let secondWithMilliseconds =
      splitted.length > 5 ? splitted[5].split(".") : [];
    let secondsOnly =
      secondWithMilliseconds.length > 1
        ? secondWithMilliseconds[0]
        : splitted[5];
    let hour =
      splitted[3] === "12" || splitted[3] === "00"
        ? "12"
        : splitted[3] < 12
        ? splitted[3]
        : splitted[3] - 12;
    amOrPm = splitted[3] < 12 ? " AM" : " PM";
    formattedString =
      splitted[0] +
      "/" +
      splitted[1] +
      "/" +
      splitted[2] +
      " " +
      hour +
      ":" +
      splitted[4] +
      (isIncludeMilliSec && secondWithMilliseconds.length > 1
        ? ":" + splitted[5]
        : isIncludeSec
        ? ":" + secondsOnly
        : "");
  } else {
    formattedString = "-";
  }
  return dateVal
    ? isIncludeSec
      ? formattedString + ":" + splitted[5] + amOrPm
      : formattedString + amOrPm
    : "";
};

export const getTimeOnlyString = (dateVal) => {
  let amOrPm, formattedString;
  if (dateVal) {
    let splitted = dateVal.split(":");
    let hour =
      splitted[3] === "12" || splitted[3] === "00"
        ? "12"
        : splitted[3] < 12
        ? splitted[3]
        : splitted[3] - 12;
    amOrPm = splitted[3] < 12 ? " AM" : " PM";
    formattedString = hour + ":" + splitted[4];
  } else {
    formattedString = "-";
  }
  return formattedString + amOrPm;
};

export const getCurrentServerDate = (timeFormat) => {
  timeFormat = !isNullOrEmpty(timeFormat) ? timeFormat : "MM:DD:YYYY:HH:mm";
  let currentDate = !isNullOrEmpty(getCurrentServerTimezone())
    ? Moment().tz(getCurrentServerTimezone()).format(timeFormat)
    : Moment().format(timeFormat);
  return currentDate;
};

export const getDateDifferentInDays = (startDate, endDate) => {
  const dt1 = new Date(startDate);
  const dt2 = new Date(endDate);
  return Math.floor(
    (Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) -
      Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) /
      (1000 * 60 * 60 * 24)
  );
};

export const validateEmail = (email) => {
  if (!email || !email.trim()) return true;
  let emailCheck =
    /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;
  return emailCheck.test(email.trim());
};
export const camelizeKeys = (obj) => {
  if (Array.isArray(obj)) {
    return obj.map((v) => camelizeKeys(v));
  } else if (obj != null && obj.constructor === Object) {
    return Object.keys(obj).reduce(
      (result, key) => ({
        ...result,
        [camelCase(key)]: camelizeKeys(obj[key]),
      }),
      {}
    );
  }
  return obj;
};

export const getBasicToastResponse = (response) => {
  const notifyObj = camelizeKeys(response);
  const msg = notifyObj.statusMsg
    ? notifyObj.statusMsg
    : notifyObj.statusCode > 0
    ? "Unknown server side error"
    : "Success";
  return {
    msg: msg,
    toastType:
      notifyObj.statusCode > 0
        ? global.TOAST_MSG.ERROR
        : global.TOAST_MSG.SUCCESS,
  };
};

export const getErrorToastData = (errorMsg, style) => {
  return {
    msg: errorMsg,
    toastType: global.TOAST_MSG.ERROR,
    style: style || null,
  };
};

export const getNoteTooltipHtml = (data, customWidth) => {
  let tooltipHtml = "";
  customWidth = customWidth || "auto";
  if (data) {
    tooltipHtml =
      "<div class='common-tooltip'><p style='max-width:" +
      customWidth +
      "'>" +
      data +
      "</p></div>";
  }
  return tooltipHtml;
};

export const sortByNumber = (columnsList, sortingKey) => {
  let byNumber = columnsList.slice(0);
  byNumber.sort(function (a, b) {
    return a[sortingKey] - b[sortingKey];
  });
  return byNumber;
};
export const sortArrayObjectByNumber = (objectArr, sortingKey) => {
  let sortedArrayObject = objectArr.sort(function (a, b) {
    return sortingKey
      ? b[sortingKey] - a[sortingKey]
      : parseInt(Object.values(a)[0], 10) - parseInt(Object.values(b)[0], 10);
  });
  return sortedArrayObject;
};
export const sortByString = (columnsList, sortingKey) => {
  let byName = columnsList.slice(0);
  byName.sort(function (a, b) {
    let x = a[sortingKey].toLowerCase();
    let y = b[sortingKey].toLowerCase();
    return x < y ? -1 : x > y ? 1 : 0;
  });
  return byName;
};
export const sortByStringFromObject = (columnsObj, sortingKey) => {
  let sortingList = [];
  Object.keys(columnsObj).map((key, index) => {
    return sortingList.push(columnsObj[key]);
  });
  let byName = sortingList.slice(0);
  byName.sort(function (a, b) {
    let x = a[sortingKey].toLowerCase();
    let y = b[sortingKey].toLowerCase();
    return x < y ? -1 : x > y ? 1 : 0;
  });
  return byName;
};

export const multiSort = (arr, criteria) => {
  if (criteria && criteria.length === 0) return arr;
  return arr.sort((a, b) => {
    for (let { key, order = "asc" } of criteria) {
      if (a[key] < b[key]) return order === "asc" ? -1 : 1;
      if (a[key] > b[key]) return order === "asc" ? 1 : -1;
    }
    return 0;
  });
};

export const toTitleCase = (str) => {
  if (str) {
    return str.replace(/[\\. _:-]+/g, " ").replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }
};

export const getCamelCaseId = (str) => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toLowerCase() + txt.substr(1);
  });
};

export const isSettingsMenuPermitted = () => {
  let hasOrgSettingMenuItem =
    hasPermission(PermissionName.VIEW_MAIN_ORG_SETTINGS) ||
    hasPermission(PermissionName.VIEW_HOURS_ORG_SETTINGS) ||
    hasPermission(PermissionName.VIEW_TAX_ORG_SETTINGS) ||
    hasPermission(PermissionName.VIEW_QUALIFICATION_ORG_SETTINGS) ||
    hasPermission(PermissionName.VIEW_RESERVATION_ORG_SETTINGS) ||
    hasPermission(PermissionName.VIEW_RESERVATION_ORG_SETTINGS) ||
    hasPermission(PermissionName.VIEW_UPSELL_ORG_SETTINGS) ||
    hasPermission(PermissionName.VIEW_RATES_ORG_SETTINGS) ||
    hasPermission(PermissionName.VIEW_AVAILABILITY_ORG_SETTINGS) ||
    hasPermission(PermissionName.VIEW_ROLE) ||
    hasPermission(PermissionName.VIEW_PERMISSION) ||
    hasPermission(PermissionName.VIEW_CAPACITY_BUCKETS_INFO)
      ? true
      : false;

  return hasOrgSettingMenuItem;
};

export const formatAmountValue = (number) => {
  if (isNaN(parseFloat(number))) return;
  if (number < 0) {
    number = number * -1;
    number = "($" + parseFloat(number).toFixed(2) + ")";
  } else {
    number = parseFloat(number).toFixed(2);
    number = "$" + number;
  }
  return number;
};

export const getDisplayTextOfDropdown = (
  dropddownItemsList,
  selectedId,
  valueKey,
  displayKey
) => {
  let displayText = "";
  if (!dropddownItemsList) {
    return displayText;
  }

  const selectedItemList = dropddownItemsList.filter(
    (data) => String(data[valueKey || "key"]) === String(selectedId)
  );

  displayText =
    selectedItemList && selectedItemList.length > 0
      ? selectedItemList[0][displayKey || "displayValue"]
      : displayText;

  return displayText;
};

export const convertPermissionURLToString = (arrayData) => {
  let stringData = "";
  if (arrayData) {
    arrayData.forEach((element, index) => {
      if (stringData) {
        stringData += "<br/>";
      }
      stringData = stringData + element;
    });
  }
  return stringData;
};

export const getFormattedJson = (object, separator) => {
  let html = "";
  Object.keys(object).map((key) => {
    let cardValue = object[key] || "--";
    return html
      ? html + (separator ? separator + " " : " ") + key + " : " + cardValue
      : key + " : " + cardValue;
  });
};
export const getFullName = (firstName, lastName, middleName = null) => {
  let fullName = firstName ? firstName : "";
  fullName = middleName ? fullName + " " + middleName : fullName;
  fullName = lastName ? fullName + " " + lastName : fullName;
  return fullName;
};

export const populateTimezoneDataDropdown = () => {
  let timeZoneList = [];
  let timeZonesForDropdown = [];
  let timezoneName = momentTimezone.tz.names();

  if (timezoneName.length > 0) {
    timeZoneList = timezoneName.map((element) => {
      return {
        TimeZoneOffset: momentTimezone.tz(element.trim()).format("Z"),
        TimeZoneName: element.trim(),
      };
    });
  }
  timeZoneList.sort(function (a, b) {
    if (parseInt(a.TimeZoneOffset) < parseInt(b.TimeZoneOffset)) {
      return -1;
    } else if (parseInt(a.TimeZoneOffset) > parseInt(b.TimeZoneOffset)) {
      return 1;
    } else {
      if (a.TimeZoneName < b.TimeZoneName) {
        return -1;
      } else if (a.TimeZoneName > b.TimeZoneName) {
        return 1;
      } else {
        return 0;
      }
    }
  });
  timeZonesForDropdown = timeZoneList.map((element) => {
    return {
      key: element.TimeZoneName,
      displayValue:
        "(UTC" + element.TimeZoneOffset.trim() + ") " + element.TimeZoneName,
    };
  });
  return timeZonesForDropdown;
};

export const getTimezoneAbbr = (dateTime, timezoneText) => {
  if (timezoneText) {
    return momentTimezone.tz(dateTime, timezoneText).format("z");
  } else {
    return "";
  }
};

export const convertDateTimeStringWithTimezone = (datetime, timezoneText) => {
  let dateTimeString = "";
  if (datetime) {
    let convertedDateTimeStr =
      datetime.split("/").length > 2
        ? datetime
        : convertDateTimeString(datetime);
    dateTimeString =
      convertedDateTimeStr +
      " " +
      getTimezoneAbbr(convertedDateTimeStr, timezoneText);
  }
  return dateTimeString;
};

export const getDateTimeToolTipTextWithTimezone = (datetime, timezoneText) => {
  let dateTimeStringForTooltip = "";
  if (datetime) {
    dateTimeStringForTooltip = getNoteTooltipHtml(
      convertDateTimeStringWithTimezone(datetime, timezoneText)
    );
  }
  return ReactHtmlParser(dateTimeStringForTooltip);
};

export const getResStatusWiseDateTimeTooltip = (
  actualStartDate,
  actualEndDate,
  promisedStartTime,
  timezoneText,
  reservationState
) => {
  let dateTimeStringForTooltip = "";
  if (reservationState === "IN_PROGRESS") {
    if (actualStartDate && promisedStartTime) {
      dateTimeStringForTooltip = getNoteTooltipHtml(
        "Started on: " +
          convertDateTimeStringWithTimezone(actualStartDate, timezoneText) +
          "</br>" +
          "Scheduled on: " +
          convertDateTimeStringWithTimezone(promisedStartTime, timezoneText)
      );
    }
  } else if (reservationState === "COMPLETED") {
    if (actualEndDate) {
      dateTimeStringForTooltip = getNoteTooltipHtml(
        "Completed on: " +
          convertDateTimeStringWithTimezone(actualEndDate, timezoneText)
      );
    }
  }

  return dateTimeStringForTooltip;
};

export const getDropdownItems = (
  dropdownData,
  valueKey,
  displayTextKey,
  displayTextForFirstItem,
  idForFirstItem
) => {
  let dataList = [];
  let dataObj = {};

  if (displayTextForFirstItem) {
    dataList.push({
      key: idForFirstItem || "",
      displayValue: displayTextForFirstItem,
    });
  }

  if (!dropdownData) {
    return dataList;
  }

  dropdownData.forEach((element) => {
    if (element) {
      dataObj = {};
      dataObj.key = element[valueKey] || element["key"];
      dataObj.displayValue = element[displayTextKey] || element["displayValue"];

      if (valueKey === "RoleId") {
        dataObj["isInherited"] = element["Inherited"];
        dataList.push(dataObj);
      } else {
        Object.keys(element).forEach((extraItem) => {
          if (extraItem !== valueKey && extraItem !== displayTextKey) {
            dataObj[extraItem] = element[extraItem];
          }
        });
        dataList.push(dataObj);
      }
    }
  });
  return dataList;
};

export const populateDropdownData = (
  controlName,
  dropdownData,
  searchInputCtrls,
  dropdownName
) => {
  let dropdownDataList = [];
  if (dropdownName === "location") {
    dropdownDataList = getDropdownItems(
      dropdownData,
      "Key",
      "Value",
      "Any Location"
    );
  } else if (dropdownName === "shuttleLocation") {
    dropdownDataList = getDropdownItems(dropdownData, "key", "value", "");
  } else if (dropdownName === "shuttleTrip") {
    dropdownDataList = getDropdownItems(
      dropdownData,
      "key",
      "value",
      "Any Location"
    );
  } else if (dropdownName === "landmarkLocation") {
    dropdownDataList = getDropdownItems(
      dropdownData,
      "key",
      "value",
      "Any Location"
    );
  } else if (dropdownName === "vehicleCategory") {
    dropdownDataList = getDropdownItems(
      dropdownData,
      "ClassCode",
      "DisplayText",
      "All"
    );
  } else if (dropdownName === "roles") {
    dropdownDataList = getDropdownItems(
      dropdownData,
      "RoleId",
      "Description",
      "Any Role"
    );
  } else if (dropdownName === "reason") {
    dropdownDataList = getDropdownItems(
      dropdownData,
      "Name",
      "DisplayName",
      "Select Reason"
    );
  } else if (
    dropdownName === "problemType" ||
    dropdownName === "integStatus" ||
    dropdownName === "locationTypes" ||
    dropdownName === "billingTypes"
  ) {
    dropdownDataList = getDropdownItems(
      dropdownData,
      "Name",
      "DisplayName",
      ["locationTypes", "billingTypes"].includes(dropdownName) ? "All" : null
    );
  } else if (dropdownName === "shuttleCode") {
    dropdownDataList = getDropdownItems(
      dropdownData,
      "key",
      "value",
      "Select Shuttle"
    );
  } else if (dropdownName === "shuttleDriver") {
    dropdownDataList = getDropdownItems(
      dropdownData,
      "key",
      "value",
      "Select Driver"
    );
  } else if (dropdownName === "orgUnitFleetClassGroupId") {
    dropdownDataList = getDropdownItems(
      dropdownData,
      "key",
      "value",
      "Select Class Group"
    );
  } else if (dropdownName === "landmarkCategory") {
    dropdownDataList = getDropdownItems(
      dropdownData,
      "key",
      "value",
      "Select Category"
    );
  } else if (dropdownName === "landmarkStatus") {
    dropdownDataList = getDropdownItems(dropdownData, "key", "value", "All");
  }

  return updateDdlctrlProperties(
    controlName,
    searchInputCtrls,
    dropdownDataList
  );
};
export const convertSecondToDayHourMinSec = (seconds) => {
  if (seconds) {
    let sec = parseFloat(seconds);
    let days = Math.floor(sec / 86400);
    let hours = Math.floor((sec - days * 86400) / 3600);
    let minutes = Math.floor((sec - days * 86400 - hours * 3600) / 60);
    seconds = Math.floor(sec - days * 86400 - hours * 3600 - minutes * 60);
    let dDisplay = days > 0 ? days + " d " : "";
    let hDisplay = hours > 0 ? hours + " h " : "";
    let mDisplay = minutes > 0 ? minutes + " m " : "";
    let sDisplay = seconds > 0 ? seconds + " s " : "";
    return dDisplay + hDisplay + mDisplay + sDisplay;
  } else {
    return "";
  }
};

export const convertMinutesToDayHourMin = (input) => {
  if (input) {
    var min = parseFloat(input);
    var days = Math.floor(min / 1440);
    var hours = Math.floor((min - days * 1440) / 60);
    var minutes = Math.floor(min - days * 1440 - hours * 60);
    if (days === 0) {
      return hours + " Hours " + minutes + " Minutes";
    }
    if (hours === 0) {
      return days + " Days " + minutes + " Minutes";
    }
    if (minutes === 0) {
      return days + " Days " + hours + " Hours";
    }

    return (
      days +
      " " +
      "Days" +
      " " +
      hours +
      " " +
      "Hours" +
      " " +
      minutes +
      " " +
      "Minutes"
    );
  } else {
    return "";
  }
};

export const getSubString = (strValue, startPosition, endPosition) => {
  if (strValue) {
    return strValue.substr(startPosition, endPosition);
  } else {
    return "";
  }
};
export const getNormalFormattedPhoneNumber = (phone) => {
  return phone && phone.FormattedNumber
    ? (String(phone.CountryCode).includes("+", 0) ? "" : "+") +
        phone.CountryCode +
        "-" +
        phone.FormattedNumber
    : "--";
};
export const getPhoneNumberText = (post, isForTooltip) => {
  return getFormattedPhoneNumber(
    post.hasOwnProperty("MobilePhone") ? post.MobilePhone : null,
    post.hasOwnProperty("WorkPhone") ? post.WorkPhone : null,
    post.hasOwnProperty("HomePhone") ? post.HomePhone : null,
    isForTooltip ? "tooltip" : "firstnumber"
  );
};

export const getPhoneNumberOnly = (phoneInfo) => {
  return phoneInfo.FormattedNumber
    ? phoneInfo.FormattedNumber
    : phoneInfo.Number || "--";
};

export const getFormattedPhoneNumber = (
  mobilePhoneInfo,
  workPhoneInfo,
  homePhoneInfo,
  returnCategory = null
) => {
  let phoneNumber = "";
  let phoneNumberWithIso = "";
  let firstPhoneNumber = "";
  let returnValue = "";
  let phoneNumberOnly = "";
  if (mobilePhoneInfo) {
    if (
      !isNullOrEmpty(mobilePhoneInfo.CountryCode) &&
      mobilePhoneInfo.CountryCode !== 0
    ) {
      phoneNumberOnly = getPhoneNumberOnly(mobilePhoneInfo);
      phoneNumberWithIso = `(M) ${phoneNumberOnly} [${
        mobilePhoneInfo.CountryIso || "--"
      }]<br>`;
      phoneNumber = `(M) +${mobilePhoneInfo.CountryCode} - ${phoneNumberOnly}`;
      if (isNullOrEmpty(firstPhoneNumber)) {
        firstPhoneNumber = phoneNumber;
      }
    }
  }

  if (workPhoneInfo) {
    if (
      !isNullOrEmpty(workPhoneInfo.CountryCode) &&
      workPhoneInfo.CountryCode !== 0
    ) {
      phoneNumberOnly = getPhoneNumberOnly(workPhoneInfo);
      phoneNumberWithIso += `(W) ${phoneNumberOnly} [${
        workPhoneInfo.CountryIso || "--"
      }]<br>`;
      phoneNumber = `(W) +${workPhoneInfo.CountryCode} - ${phoneNumberOnly}`;
      if (isNullOrEmpty(firstPhoneNumber)) {
        firstPhoneNumber = phoneNumber;
      }
    }
  }
  if (homePhoneInfo) {
    if (
      !isNullOrEmpty(homePhoneInfo.CountryCode) &&
      homePhoneInfo.CountryCode !== 0
    ) {
      phoneNumberOnly = getPhoneNumberOnly(homePhoneInfo);
      phoneNumberWithIso += `(H) ${phoneNumberOnly} [${
        homePhoneInfo.CountryIso || "--"
      }]<br>`;
      phoneNumber = `(H) +${homePhoneInfo.CountryCode} - ${phoneNumberOnly}`;
      if (isNullOrEmpty(firstPhoneNumber)) {
        firstPhoneNumber = phoneNumber;
      }
    }
  }
  if (returnCategory === "tooltip") {
    returnValue = phoneNumberWithIso;
  } else if (returnCategory === "firstnumber") {
    returnValue = !isNullOrEmpty(firstPhoneNumber) ? firstPhoneNumber : "--";
  } else {
    returnValue = phoneNumber;
  }
  return returnValue;
};

export const convertArrayDataToTitleString = (arrayData) => {
  let stringData = "";
  if (arrayData) {
    arrayData.forEach((element) => {
      if (stringData) {
        stringData += ", ";
      }
      stringData = stringData + toTitleCase(element);
    });
  }
  return stringData;
};

export const getCurrentServerTimezone = () => {
  let timezoneString = localStorage.getItem("timeZoneString")
    ? JSON.parse(localStorage.getItem("timeZoneString"))
    : "";

  return timezoneString;
};
export const getLocalTimezone = () => {
  let timezoneString = Moment.tz.guess();
  return timezoneString;
};

export const dateArrayRender = (arrayList) => {
  let arrayStr = "";
  if (arrayList) {
    arrayList.forEach((element) => {
      arrayStr = arrayStr
        ? arrayStr +
          ", " +
          Moment(convertDateTimeString(element)).format("DD MMMM YYYY")
        : Moment(convertDateTimeString(element)).format("DD MMMM YYYY");
    });
  }
  return arrayStr;
};
export const convertArrayDataToString = (arrayData) => {
  let stringData = "";
  if (arrayData) {
    arrayData.forEach((element) => {
      if (stringData) {
        stringData += ", ";
      }
      stringData = stringData + element;
    });
  }
  return stringData ? stringData : "--";
};

export const getExpiaryYears = (cardExpiredYear) => {
  const expiaryYears = [];
  let currentYear = Moment(new Date()).year();
  let maxYear = 2099;
  for (let i = currentYear; i <= maxYear; i++) {
    expiaryYears.push({ key: i, displayValue: i });
  }
  if (cardExpiredYear) {
    if (cardExpiredYear > maxYear) {
      expiaryYears.push({
        key: cardExpiredYear,
        displayValue: cardExpiredYear,
      });
    } else if (cardExpiredYear < currentYear) {
      expiaryYears.unshift({
        key: cardExpiredYear,
        displayValue: cardExpiredYear,
      });
    }
  }
  expiaryYears.unshift({ key: "", displayValue: "Year" });
  return expiaryYears;
};
export const getExpiaryMonths = () => {
  const expiaryMonths = [];
  expiaryMonths.push({ key: "", displayValue: "Month" });
  expiaryMonths.push({ key: 1, displayValue: "01" });
  expiaryMonths.push({ key: 2, displayValue: "02" });
  expiaryMonths.push({ key: 3, displayValue: "03" });
  expiaryMonths.push({ key: 4, displayValue: "04" });
  expiaryMonths.push({ key: 5, displayValue: "05" });
  expiaryMonths.push({ key: 6, displayValue: "06" });
  expiaryMonths.push({ key: 7, displayValue: "07" });
  expiaryMonths.push({ key: 8, displayValue: "08" });
  expiaryMonths.push({ key: 9, displayValue: "09" });
  expiaryMonths.push({ key: 10, displayValue: "10" });
  expiaryMonths.push({ key: 11, displayValue: "11" });
  expiaryMonths.push({ key: 12, displayValue: "12" });
  return expiaryMonths;
};

export const convertToServerTime = () => {
  return Moment().tz(JSON.parse(localStorage.getItem("timeZoneString")));
};

export const getFormattedLicenseNumber = (licenseObj) => {
  let formattedLicense = null;
  if (licenseObj) {
    formattedLicense = licenseObj.DrivingLicenseNumber
      ? "<b>" + licenseObj.DrivingLicenseNumber + "</b>"
      : "";

    if (licenseObj.LicenseExpirationDate) {
      formattedLicense = formattedLicense
        ? formattedLicense +
          " - " +
          getDateOnlyString(licenseObj.LicenseExpirationDate) +
          "<br>"
        : getDateOnlyString(licenseObj.LicenseExpirationDate) + "<br>";
    }
    if (licenseObj.DrivingLIcenseState) {
      formattedLicense = formattedLicense
        ? formattedLicense + licenseObj.DrivingLIcenseState
        : licenseObj.DrivingLIcenseState;
    }
    if (licenseObj.DrivingLicenseCountryText) {
      formattedLicense = formattedLicense
        ? formattedLicense + "," + licenseObj.DrivingLicenseCountryText
        : licenseObj.DrivingLicenseCountryText;
    }
  }
  return ReactHtmlParser(formattedLicense);
};

export const updateDdlctrlProperties = (
  controlName,
  inputCtrls,
  dropdownDataList
) => {
  return updateObject(inputCtrls, {
    [controlName]: updateObject(inputCtrls[controlName], {
      display: true,
      elementConfig: updateObject(inputCtrls[controlName].elementConfig, {
        name: controlName,
        options: dropdownDataList,
      }),
    }),
  });
};

export const validatePhoneNo = (phoneObj) => {
  let phcheck = /^\(?(\d{2})?\)?[- ]?[0-9]\d{1,6}[- ]?\d{2,8}/; // All
  if (phoneObj && typeof phoneObj === "object") {
    if (!phoneObj.Number || !phoneObj.CountryIso || !phoneObj.CountryCode) {
      return false;
    }
    if (phoneObj.CountryIso === "IN") {
      phcheck = /^\(?(\d{2})?\)?[- ]?[0-9]\d{3,6}[- ]?\d{5,8}/; //IN
    }
    if (phoneObj.CountryIso === "US" || phoneObj.CountryIso === "CA") {
      phcheck = /^\(?(\d{1})?\)?[- ]?\(?(\d{3})?\)?[- ]?(\d{3})[- ]?(\d{4})$/; //USA && CA
    }
    return phcheck.test(phoneObj.Number);
  } else if (!phoneObj) return false;
  return phcheck.test(phoneObj);
};

export const printDocumentBody = (content) => {
  let newWin = window.open(
    "",
    "",
    "fullscreen = yes",
    "width =800",
    "height = 400"
  );
  newWin.document.write(
    '<html><body onload="window.print()"><center>' +
      content.innerHTML +
      "</center></body></html>"
  );

  newWin.document.close();
  newWin.print();
  setTimeout(function () {
    newWin.close();
  }, 10);
};

export const getTimeFromDuration = (durationInMin) => {
  let data = {
    Month: 0,
    Week: 0,
    Day: 0,
    Hour: 0,
    Minute: 0,
  };
  if (durationInMin) {
    data.Year = Math.floor(durationInMin / (360 * 24 * 60));
    let rem = durationInMin % (360 * 24 * 60);
    if (rem) {
      data.Month = Math.floor(rem / (30 * 24 * 60));
      rem = rem % (30 * 24 * 60);
      if (rem) {
        data.Week = Math.floor(rem / (7 * 24 * 60));
        rem = rem % (7 * 24 * 60);
        if (rem) {
          data.Day = Math.floor(rem / (1 * 24 * 60));
          rem = rem % (1 * 24 * 60);
          if (rem) {
            data.Hour = Math.floor(rem / (1 * 60));
            data.Minute = rem % (1 * 60);
          }
        }
      }
    }
  }
  return data;
};

export const formatCurrency = (amount, defaultvalue, currency, format) => {
  // eslint-disable-next-line eqeqeq
  if (amount == undefined || amount == null || isNaN(amount)) {
    return defaultvalue || "";
  }

  // let f = format || Global.SelectedOrgUnit.CurrencyDecimals;
  let f = format;
  f = f || "0.00";

  // eslint-disable-next-line eqeqeq
  if (f.indexOf("#") == 0 && f.length > 1) {
    f = f.substr(1);
  }
  // let currencyIcon = currency || Global.SelectedOrgUnit.CurrencyFormat;
  let currencyIcon = currency || "$";
  let text = amount;
  try {
    text = numeral(amount).format(f);
    if (text.indexOf("e") > 0) {
      let temp = parseFloat(text) || 0;
      if (temp) {
        text = "" + temp.toExponential(2);
      }
    }
  } catch (e) {}

  return currencyIcon + text;
  //return accounting.formatMoney(amount, '<span class="currency">' + (currency || Global.SelectedOrgUnit.CurrencyFormat) + '</span>');
};

export const isDuplicate = (itemList, item) => {
  let isDuplicate = false;
  if (itemList) {
    if (itemList.length > 0) {
      for (let i = 0; i < itemList.length; i++) {
        if (itemList[i].Id === item) {
          isDuplicate = true;
          break;
        }
      }
    }
  }
  return isDuplicate;
};
export const getUniqueObjects = (objectList) => {
  const uniqueObject = objectList.filter((value, index) => {
    const _value = JSON.stringify(value);
    return (
      index ===
      objectList.findIndex((obj) => {
        return JSON.stringify(obj) === _value;
      })
    );
  });
  return uniqueObject;
};

export const getFormatedRate = (asset) => {
  let price = "";
  let hourlyRate = 0;
  let halfDayRate = 0;
  let dailyRate = 0;

  if (asset.participantType === global.ReservationConstants.SharerViewType) {
    hourlyRate = asset.SharerHourlyRate || 0;
    halfDayRate = asset.SharerHalfDayRate || 0;
    dailyRate = asset.SharerDailyRate || 0;
    if (hourlyRate > 0) {
      price += formatAmountValue(hourlyRate || 0) + "/hr ";
    } else if (halfDayRate > 0) {
      price += formatAmountValue(halfDayRate || 0) + "/half day ";
    }
    if (dailyRate > 0) {
      price += formatAmountValue(dailyRate || 0) + "/day ";
    }
    if (asset.SharerWeekendRate) {
      price += formatAmountValue(asset.SharerWeekendRate || 0) + "/weekend ";
    }
    if (asset.SharerWeeklyRate) {
      price += formatAmountValue(asset.SharerWeeklyRate || 0) + "/week ";
    }
    if (asset.SharerMonthlyRate) {
      price += formatAmountValue(asset.SharerMonthlyRate || 0) + "/month ";
    }
  } else {
    hourlyRate = asset.HourlyRate || 0;
    halfDayRate = asset.HalfDayRate || 0;
    dailyRate = asset.DailyRate || 0;

    if (hourlyRate > 0) {
      price += formatAmountValue(hourlyRate || 0) + "/hr ";
    } else if (halfDayRate > 0) {
      price += formatAmountValue(halfDayRate || 0) + "/half day ";
    }
    if (dailyRate > 0) {
      price += formatAmountValue(dailyRate || 0) + "/day ";
    }
    if (asset.WeekendRate) {
      price += formatAmountValue(asset.WeekendRate || 0) + "/weekend ";
    }
    if (asset.WeeklyRate) {
      price += formatAmountValue(asset.WeeklyRate || 0) + "/week ";
    }
    if (asset.MonthlyRate) {
      price += formatAmountValue(asset.MonthlyRate || 0) + "/month ";
    }
  }
  return price;
};
export const getNumberOnly = (str) => {
  let res = str.replace(/\D/g, "");
  return res;
};

export const getReservationDuration = (date1, date2) => {
  let result = {
    Months: 0,
    Weeks: 0,
    Days: 0,
    Hours: 0,
  };
  if (date1 && date2) {
    let duration = Moment.duration(date1.diff(date2)).asMilliseconds();
    if (duration > 0) {
      let diffInHour = Math.ceil(Math.max(0, duration / (1000 * 60 * 60)));

      if (diffInHour > 0) {
        result.Months = (diffInHour / (24 * 30)) | 0;
        diffInHour = diffInHour % (24 * 30);
        if (diffInHour > 0) {
          result.Weeks = (diffInHour / (24 * 7)) | 0;
          diffInHour = diffInHour % (24 * 7);
          if (diffInHour > 0) {
            result.Days = (diffInHour / 24) | 0;
            result.Hours = diffInHour % 24;
          }
        }
      }
    }
  }
  return result;
};
export const getDateArrayFromDateAndDayLength = (length, date, format) => {
  return Array.from({ length }, (_, i) =>
    moment(date, format).add(i, "days").format(format)
  );
};
export const populateBreadCrumbData = (breadCrubList, title, pathName) => {
  let breadList =
    breadCrubList && breadCrubList.length > 0 ? breadCrubList.slice() : [];
  const breadcrumbModel = {};
  breadcrumbModel.position = breadList.length + 1;
  breadcrumbModel.title = title;
  breadcrumbModel.pathname = pathName;
  breadList.push(breadcrumbModel);
  return breadList;
};

export const getRootBreadcurmbList = (breadCrubList, title, pathName) => {
  let breadList =
    breadCrubList && breadCrubList.length > 0 ? breadCrubList.slice() : [];
  const currentIndex = breadList.findIndex((item) => item.title === title);
  let breadCrumbListArr = breadList.filter((user) => {
    return user.position <= currentIndex + 1;
  });
  return breadCrumbListArr;
};

export const clearActiveMenu = () => {
  let element, name, arr, activeClassIndex;
  element = document.getElementsByClassName("dropmenu__content--link--text");
  for (let i = 0; i < element.length; i++) {
    name = "active";
    arr = element[i].className.split(" ");
    activeClassIndex = arr.indexOf(name);
    if (activeClassIndex > -1) {
      arr.splice(activeClassIndex, 1);
      element[i].className = arr.join(" ");
    }
  }
};

export const activeCurrentLink = (menuName) => {
  let element, name, arr;
  element = document.getElementsByClassName(menuName);
  name = "active";
  if (element.length) {
    arr = element[0].className.split(" ");
    if (arr.indexOf(name) === -1) {
      element[0].className += " " + name;
    }
  }
};

export const formatUpsellOptions = (upsellList) => {
  let upsellListStr = "";
  if (upsellList && upsellList.length > 0) {
    upsellList.forEach((element) => {
      if (upsellListStr) {
        upsellListStr = upsellListStr + ",<br>" + element.DisplayText;
      } else {
        upsellListStr = element.DisplayText;
      }
    });
  }
  return upsellListStr;
};

export const isDataArray = (data) => {
  return !!data && data.constructor === Array;
};
export const isDataObject = (data) => {
  return typeof data === "object";
};
export const isEmptyObject = (obj) => {
  return Object.keys(obj).length === 0;
};

export const loadDefaultImage = (event, defaultImg) => {
  event.target.src = defaultImg;
};

export const isEmail = (value) => {
  const pattern =
    /[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/;
  return pattern.test(value);
};

export const convertToPercentage = (input) => {
  if (input) {
    var per = parseFloat(input);
    var percentage = per * 100;
    return percentage + " %";
  } else {
    return "";
  }
};

export const isJson = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};
export const isValidXML = (XMLData) => {
  const parser = new DOMParser();
  if (XMLData) {
    const dom = parser.parseFromString(XMLData, "application/xml");
    const errorNode = dom.querySelector("parsererror");
    return errorNode === null;
  } else {
    return false;
  }
};
export const convertSpecialCharsToHtmlEntity = (data) => {
  return data
    .replace(/\n/g, "")
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/'/g, "&apos;")
    .replace(/"/g, "&quot;");
};
export const getCalenderDays = () => {
  return Array.from(Array(31), (e, index) => {
    return {
      key: index,
      displayValue: index,
    };
  });
};

export const getClockHours = () => {
  return Array.from(Array(24), (e, index) => {
    return {
      key: index,
      displayValue: index,
    };
  });
};

export const getFormattedValidationMsg = (validationMsg, isPayment = false) => {
  let formattedValidationMsg = validationMsg;
  let msgColumn1 = null;
  let msgColumn2 = null;
  let msgLength = validationMsg.split(",").length;
  let halfLength = Math.ceil(msgLength / 2);
  msgColumn1 = validationMsg
    .split(",")
    .slice(0, halfLength)
    .map((item) => {
      return "<li>" + item + "</li>";
    });
  msgColumn2 = validationMsg
    .split(",")
    .slice(halfLength)
    .map((item) => {
      return "<li>" + item + "</li>";
    });

  formattedValidationMsg =
    "<p class='font-weight-bold pb-1 fs-3'>" +
    (isPayment
      ? global.COMMON_MSG.MessageFieldsRequiredForPayment
      : global.COMMON_MSG.MessageFillRequiredFields) +
    "</p>" +
    "<p class='fs-2'>" +
    "<div class='row'>" +
    "<ul class='col ml-1'>" +
    msgColumn1.join("") +
    "</ul>" +
    "<ul class='col'>" +
    msgColumn2.join("") +
    "</ul>" +
    "</div>" +
    "</p>";
  return ReactHtmlParser(formattedValidationMsg);
};

export const hasObjectNonEmptyProperty = (obj, pageName) => {
  if (pageName === global.ADMIN_PAGE.MEMBER_PROFILE_PAGE) {
    delete obj["PaymentMethod"];
  }
  for (var key in obj) {
    if (key !== "Address" && key !== "CreditCardType") {
      if (obj[key] !== null && obj[key] !== "") return true;
    } else if (key === "CreditCardType") {
      if (obj[key] !== null && obj[key] !== "" && obj[key] !== "NONE")
        return true;
    } else return hasObjectNonEmptyProperty(obj[key]);
  }
  return false;
};

export const specialCharsList = "!@#$%^&*()";

export const isContainedAllowedCharacters = (val) => {
  return /^[a-zA-Z0-9\-_.'+=:;~`,?/\\|"}{\][\s]*$/.test(val.trim());
};

export const checkUniqueAddons = (addOnsA = [], addOnsB = []) => {
  const isSameAddons = (addOnsA, addOnsB) =>
    addOnsA.amount === addOnsB.amount &&
    addOnsA.chargeType === addOnsB.chargeType &&
    addOnsA.code === addOnsB.code &&
    addOnsA.desc === addOnsB.desc &&
    addOnsA.includedInPrice === addOnsB.includedInPrice &&
    addOnsA.includedInPriceQuantity === addOnsB.includedInPriceQuantity &&
    addOnsA.maxQuantity === addOnsB.maxQuantity &&
    addOnsA.quantity === addOnsB.quantity &&
    addOnsA.rate === addOnsB.rate &&
    addOnsA.rateUnit === addOnsB.rateUnit &&
    addOnsA.recurrenceUnit === addOnsB.recurrenceUnit &&
    addOnsA.unit === addOnsB.unit;

  const onlyInLeft = (left, right, compareFunction) =>
    left.filter(
      (leftValue) =>
        !right.some((rightValue) => compareFunction(leftValue, rightValue))
    );

  const onlyInA = onlyInLeft(addOnsA, addOnsB, isSameAddons);
  const onlyInB = onlyInLeft(addOnsB, addOnsA, isSameAddons);

  const result = [...onlyInA, ...onlyInB];
  return result.length === 0;
};

export const Utility = {
  /**remove http call near future*/
  countryStateCityPostal: {
    countries: [],
    states: [],
    cities: [],
    postals: [],
    countries_fox: [],
    states_fox: [],
    cities_fox: [],
    postals_fox: [],
    getCountries: function (callback) {
      if (Utility.countryStateCityPostal.countries.length === 0) {
        const conutryReq = HttpService.getCommonDataForRequest();
        HttpService.getCountries(conutryReq).then((response) => {
          if (Utility.countryStateCityPostal.countries.length === 0) {
            Utility.countryStateCityPostal.countries = response.Countries.map(
              (country) => ({
                Value: country.CountryId,
                display: country.CountryName,
                countryCode: country.CountryCode,
                keyValue: country.CountryCode,
                displayValue: country.CountryName,
              })
            );
          }
          if (callback && typeof callback == "function") {
            callback(Utility.countryStateCityPostal.countries);
          }
        });
      } else {
        if (callback && typeof callback == "function") {
          callback(Utility.countryStateCityPostal.countries);
        }
      }
    },
    getStates: function (countryId, isFoxAPI, callback) {
      let states = [];
      if (countryId) {
        countryId = isFoxAPI ? countryId : Number(countryId);
        if (Utility.countryStateCityPostal.states.length > 0) {
          states = Utility.countryStateCityPostal.states.filter(function (
            state
          ) {
            // eslint-disable-next-line eqeqeq
            return state.CountryId == countryId;
          });
        }
        if (states.length === 0) {
          let requestBody = isFoxAPI
            ? {
                SelectedCountryCode: countryId,
              }
            : {
                SelectedCountryId: countryId,
              };
          HttpService.getStatesCitiesPostals(requestBody).then((response) => {
            if (response.States) {
              if (response.States.length > 0) {
                states = Utility.countryStateCityPostal.states.filter(function (
                  state
                ) {
                  // eslint-disable-next-line eqeqeq
                  return state.CountryId == countryId;
                });
                if (states.length === 0) {
                  const newStates = {
                    CountryId: countryId,
                    States: isFoxAPI
                      ? response.States.map((data) => ({
                          keyValue: data.Key,
                          displayValue: data.Key,
                        }))
                      : response.States,
                  };
                  Utility.countryStateCityPostal.states.push(newStates);
                }
                if (callback && typeof callback == "function") {
                  callback(
                    Utility.countryStateCityPostal.states[
                      Utility.countryStateCityPostal.states.length - 1
                    ]
                  );
                }
              } else {
                if (callback && typeof callback == "function") {
                  callback(states);
                }
              }
            } else {
              if (callback && typeof callback == "function") {
                callback(states);
              }
            }
          });
        } else {
          if (callback && typeof callback == "function") {
            callback(states[0]);
          }
        }
      } else {
        callback({ States: [] });
      }
    },
    getCities: function (countryId, stateId, isFoxAPI, callback) {
      let cities = [];
      if (countryId && stateId) {
        countryId = isFoxAPI ? countryId : Number(countryId);
        stateId = isFoxAPI ? stateId : Number(stateId);
        if (Utility.countryStateCityPostal.cities.length > 0) {
          cities = Utility.countryStateCityPostal.cities.filter(function (
            city
          ) {
            // eslint-disable-next-line eqeqeq
            return city.StateId == stateId;
          });
        }
        if (cities.length === 0) {
          let requestBody = isFoxAPI
            ? {
                SelectedCountryCode: countryId,
                SelectedStateName: stateId,
              }
            : {
                SelectedCountryId: countryId,
                SelectedStateId: stateId,
              };
          HttpService.getStatesCitiesPostals(requestBody).then((response) => {
            if (response.Cities && response.Cities.length > 0) {
              cities = Utility.countryStateCityPostal.cities.filter(function (
                state
              ) {
                // eslint-disable-next-line eqeqeq
                return state.StateId == countryId;
              });
              if (cities.length === 0) {
                const newCities = {
                  CountryId: countryId,
                  StateId: stateId,
                  Cities: isFoxAPI
                    ? response.Cities.map((data) => ({
                        keyValue: data.Key,
                        displayValue: data.Key,
                      }))
                    : response.Cities,
                };
                Utility.countryStateCityPostal.cities.push(newCities);
              }
              if (callback && typeof callback == "function") {
                callback(
                  Utility.countryStateCityPostal.cities[
                    Utility.countryStateCityPostal.cities.length - 1
                  ]
                );
              }
            } else {
              if (callback && typeof callback == "function") {
                callback(cities);
              }
            }
          });
        } else {
          callback(cities[0]);
        }
      } else {
        callback({ Cities: [] });
      }
    },
    getPostals: function (countryId, stateId, cityId, isFoxAPI, callback) {
      let postals = [];
      if (countryId && stateId && cityId) {
        countryId = isFoxAPI ? countryId : Number(countryId);
        stateId = isFoxAPI ? stateId : Number(stateId);
        cityId = isFoxAPI ? cityId : Number(cityId);
        if (Utility.countryStateCityPostal.postals.length > 0) {
          postals = Utility.countryStateCityPostal.postals.filter(function (
            postal
          ) {
            // eslint-disable-next-line eqeqeq
            return postal.CityId == cityId;
          });
        }
        if (postals.length === 0) {
          let requestBody = isFoxAPI
            ? {
                SelectedCountryCode: countryId,
                SelectedStateName: stateId,
                SelectedCityName: cityId,
              }
            : {
                SelectedCountryId: countryId,
                SelectedStateId: stateId,
                SelectedCityId: cityId,
              };
          HttpService.getStatesCitiesPostals(requestBody).then((response) => {
            if (response.ZipCodes && response.ZipCodes.length > 0) {
              postals = Utility.countryStateCityPostal.postals.filter(function (
                postal
              ) {
                // eslint-disable-next-line eqeqeq
                return postal.CityId == cityId;
              });
              if (postals.length === 0) {
                const newPostals = {
                  CountryId: countryId,
                  StateId: stateId,
                  CityId: cityId,
                  Postals: isFoxAPI
                    ? response.ZipCodes.map((data) => ({
                        keyValue: data.Key,
                        displayValue: data.Key,
                      }))
                    : response.ZipCodes,
                };
                Utility.countryStateCityPostal.postals.push(newPostals);
              }
              if (callback && typeof callback == "function") {
                callback(
                  Utility.countryStateCityPostal.postals[
                    Utility.countryStateCityPostal.postals.length - 1
                  ]
                );
              }
            } else {
              if (callback && typeof callback == "function") {
                callback(postals);
              }
            }
          });
        } else {
          callback(postals[0]);
        }
      } else {
        callback({ Postals: [] });
      }
    },
  },
};
// eslint-disable-next-line no-extend-native
Object.defineProperty(String.prototype, "capitalize", {
  value: function () {
    return this.charAt(0).toUpperCase() + this.slice(1);
  },
  enumerable: false,
});

import { getDayName } from "../../../shared/json/dayNames";
import {
  getHourAndMinuteId,
  getHoursId,
  isNullOrEmpty,
  multiSort,
} from "../../../shared/utility";
import Address from "../models/Address";
import Role from "../models/Role";
import CategoryInfoRequestBean from "../requests/CategoryInfoRequestBean";

const dayNameList = getDayName();
export const MainSettingsViewModel = function (data) {
  if (!data) data = {};
  this.LocationId = data.LocationId || null;
  this.LocationCode = data.LocationCode || null;
  this.Name = data.Name || null;
  this.DisplayName = data.DisplayName || null;
  this.Description = data.Description || null;
  this.Email = data.Email || null;
  this.EmailBcc = data.EmailBcc || null;
  this.TimeZone = data.TimeZone || null;
  this.TimeZoneDelta = data.TimeZoneDelta || "0";
  this.TimeZoneStandardDelta = data.TimeZoneStandardDelta || "0";
  this.TollFreeNumber = data.TollFreeNumber || null;
  this.ContactPersonEmail = data.ContactPersonEmail || null;
  this.ContactPersonFirstName = data.ContactPersonFirstName || null;
  this.ContactPersonLastName = data.ContactPersonLastName || null;
  this.ContactPersonPhone = data.ContactPersonPhone || null;
  this.SupportEmails = data.SupportEmails || null;
  this.CheckoutEmails = data.CheckoutEmails || null;
  this.TwitterName = data.TwitterName || null;
  this.FacebookName = data.FacebookName || null;
  this.ChatUrl = data.ChatUrl || null;
  this.InstructionsAccessVehicleLink =
    data.InstructionsAccessVehicleLink || null;
  this.MakeApptLinkUrl = data.MakeApptLinkUrl || null;
  this.OrgWebUrl = data.OrgWebUrl || null;
  this.PickupInstructionLinkUrl = data.PickupInstructionLinkUrl || null;
  this.PrivacyPolicyLink = data.PrivacyPolicyLink || null;
  this.QuickStartGuideLinkUrl = data.QuickStartGuideLinkUrl || null;
  this.RenterPolicyLink = data.RenterPolicyLink || null;
  this.TermsOfServiceLink = data.TermsOfServiceLink || null;
  this.Address = data.Address ? new Address(data.Address) : new Address();
};

export const LocationSettingsViewModel = function (data) {
  if (!data) data = {};
  this.Description = data.Description || null;
  this.DisplayName = data.DisplayName || null;
  this.Email = data.Email || null;
  this.EmailBcc = data.EmailBcc || null;
  this.LocationId = data.LocationId || null;
  this.LocationCode = data.LocationCode || null;
  this.Name = data.Name || null;
  this.TimeZone = data.TimeZone || null;
  this.TimeZoneDelta = data.TimeZoneDelta || null;
  this.TimeZoneStandardDelta = data.TimeZoneStandardDelta || "0";
  this.TollFreeNumber = data.TollFreeNumber || null;
  this.RenterPolicyLink = data.RenterPolicyLink || null;
  this.Address = data.Address ? new Address(data.Address) : new Address();
  this.BusinessHours = new LocationBusinessHours(data.BusinessHours);
  this.LocationCodeExtended = data.LocationCodeExtended || null;
  this.CounterLocationCode = data.CounterLocationCode || null;
  this.DependentId = data.DependentId || null;
  this.DependentName = data.DependentName || null;
  this.DependentDisplayName = data.DependentDisplayName || null;
  this.DependentEmail = data.DependentEmail || null;
  this.DependentTollFreeNumber = data.DependentTollFreeNumber || null;
  this.DependentAddress = data.DependentAddress
    ? new Address(data.DependentAddress)
    : new Address();
};

export const LocationBusinessHours = function (businessHours) {
  if (!businessHours) businessHours = {};
  const convertedBusinessHours = {};
  for (const day in businessHours) {
    convertedBusinessHours[day] = businessHours[day].split(",");
  }
  return convertedBusinessHours;
};

export const HoursOfOperationSettingsViewModel = function (
  data = {},
  currentOrgUnitId,
  selectedOrgLocationId,
  hourFormat,
  sortCriteria = []
) {
  data = JSON.parse(JSON.stringify(data));
  const hasHoursOfOperations =
    data.HoursOfOperations && data.HoursOfOperations.length > 0;
  const hoursOfOperations = hasHoursOfOperations
    ? multiSort(data.HoursOfOperations, sortCriteria)
    : [];
  const workingDayList =
    hoursOfOperations.length > 0 &&
    hoursOfOperations.map((entity, rowId) => {
      const dayName = dayNameList.find(
        (name) => String(name.value) === String(entity.Day)
      );
      if (dayName) {
        entity.Name = dayName.Name;
      }
      entity.RowId = rowId;
      return new HoursOfOperationModel(entity, hourFormat);
    });
  const holidayList = (data.ExceptionWorkingDates || []).map(
    (entity, rowId) => {
      entity.RowId = rowId;
      return new HoursOfOperationModel(entity, hourFormat);
    }
  );

  this.HoursOfOperations = workingDayList;
  this.ExceptionWorkingDates = holidayList;
  this.InheritedFromParent = data.InheritedFromParent;
  this.HourFormat = isNullOrEmpty(hourFormat) ? true : hourFormat;
  this.DisplayInherited = currentOrgUnitId !== selectedOrgLocationId;
};

export const HoursOfOperationModel = function (data, hourFormat) {
  if (!data) data = {};
  let startHour = Number(getHourAndMinuteId(parseInt(data.StartTime), "hour"));
  let endHour = Number(getHourAndMinuteId(parseInt(data.EndTime), "hour"));
  let startPeriod = isNaN(startHour) ? "AM" : startHour >= 12 ? "PM" : "AM";
  let endPeriod = isNaN(endHour) ? "PM" : endHour >= 12 ? "PM" : "AM";

  this.Day = isNullOrEmpty(data.Day) ? null : data.Day;
  this.Date = data.Date || null;
  this.Name = data.Name || null;
  this.StartTime = data.StartTime || 0;
  this.EndTime = data.EndTime || 0;
  this.AddNew = data.Date || !isNullOrEmpty(data.Day) ? false : true;
  this.StartHourId = isNaN(startHour)
    ? isNullOrEmpty(hourFormat) || hourFormat
      ? "12"
      : "00"
    : getHoursId(startHour, hourFormat);
  this.StartMinuteId = isNullOrEmpty(data.StartTime)
    ? "00"
    : getHourAndMinuteId(data.StartTime, "minute");
  this.StartPeriod = startPeriod;
  this.EndHourId = isNaN(endHour)
    ? isNullOrEmpty(hourFormat) || hourFormat
      ? "11"
      : "23"
    : getHoursId(endHour, hourFormat);
  this.EndMinuteId = isNullOrEmpty(data.EndTime)
    ? "59"
    : getHourAndMinuteId(data.EndTime, "minute");
  this.EndPeriod = endPeriod;
  this.RowId = isNullOrEmpty(data.RowId) ? null : data.RowId;
};

export const TaxesAndSurchargesSettingsViewModel = function (
  data,
  currentOrgUnitId,
  selectedOrgLocationId
) {
  if (!data) data = {};
  const taxSurchargeList = [];
  if (data.OrgTaxList && data.OrgTaxList.length > 0) {
    data.OrgTaxList.map((entity) => {
      taxSurchargeList.push(new TaxSurchargesModel(entity));
    });
  }
  this.SelectedChildOrgUnitId = data.SelectedOrgUnitId || selectedOrgLocationId;
  this.OrgTaxList = [...taxSurchargeList];
  this.TaxId = data.TaxId || "";
  this.ApplyTaxAfterDiscount = data.ApplyTaxAfterDiscount;
  this.ApplySurchargeAfterDiscount = data.ApplySurchargeAfterDiscount;
  this.InheritedFromParent = data.InheritedFromParent;
  this.DisplayInherited =
    currentOrgUnitId !== selectedOrgLocationId ? true : false;
};
export const TaxSurchargesModel = function (data, taxType) {
  if (!data) data = {};
  this.OrgTaxId = data.OrgTaxId || null;
  this.TaxType = data.TaxType ? data.TaxType : taxType || null;
  this.DisplayName = data.DisplayName || null;
  this.Amount = data.Amount || null;
  this.AmountType = data.AmountType || null;
  this.RecurrenceUnit = data.RecurrenceUnit || null;
  this.AsOfDate = data.AsOfDate || null;
  this.EndDate = data.EndDate || null;
};

export const QualificationSettingsViewModel = function (
  data,
  currentOrgUnitId,
  selectedOrgLocationId
) {
  if (!data) data = {};
  this.SelectedChildOrgUnitId = data.SelectedOrgUnitId || selectedOrgLocationId;
  this.LicenseDetailsRequired = data.LicenseDetailsRequired || false;
  this.ShowLicenseInfo = data.ShowLicenseInfo || false;
  this.MinAgeRequiredForReservation = data.MinAgeRequiredForReservation || null;
  this.InheritedFromParent = data.InheritedFromParent || false;
  this.DisplayInherited =
    currentOrgUnitId !== selectedOrgLocationId ? true : false;
};

export const ReservationSettingsViewModel = function (
  data,
  currentOrgUnitId,
  selectedOrgLocationId
) {
  if (!data) data = {};
  this.SelectedChildOrgUnitId = data.SelectedOrgUnitId || selectedOrgLocationId;
  this.LeadTimeInMinutes = data.LeadTimeInMinutes || 0;
  this.BufferTimeInMinutes = data.BufferTimeInMinutes || 0;
  this.AllowReview = data.AllowReview || false;
  this.DisplayDeductible = data.DisplayDeductible || false;
  this.ShowReservationProcessingFee =
    data.ShowReservationProcessingFee || false;
  this.ShowSurchargeTax = data.ShowSurchargeTax || false;
  this.ShowTax = data.ShowTax || false;
  this.DisplayOurCarsWithJsi = data.DisplayOurCarsWithJsi || false;
  this.InheritedFromParent = data.InheritedFromParent || false;
  this.DisplayInherited =
    currentOrgUnitId !== selectedOrgLocationId ? true : false;
};

export const AddonsSettingsViewModel = function (
  data,
  currentOrgUnitId,
  selectedOrgLocationId
) {
  if (!data) data = {};
  const upsellOptions = [];
  if (data.UpsellOptions && data.UpsellOptions.length > 0) {
    data.UpsellOptions.map((entity) => {
      upsellOptions.push(new AddonsModel(entity));
    });
  }
  this.SelectedChildOrgUnitId = data.SelectedOrgUnitId || selectedOrgLocationId;
  this.UpsellOptions = [...upsellOptions];
  this.InheritedFromParent = data.InheritedFromParent;
  this.DisplayInherited = currentOrgUnitId !== selectedOrgLocationId;
};

export const AddonsModel = function (data) {
  if (!data) data = {};
  this.Value = data.Value || null;
  this.DisplayText = data.DisplayText || null;
  this.Type = data.Type ? data.Type.Key : null;
  this.MaxQuantity = data.MaxQuantity || 0;
  this.UpsellOptionUrl = data.UpsellOptionUrl || null;
  this.UnitType = data.UnitType ? data.UnitType.Key : null;
  this.Rate = data.Rate || null;
  this.MinRate = data.MinRate || null;
  this.MaxRate = data.MaxRate || null;
  this.RateUnit = data.RateUnit ? data.RateUnit.Key : "DOLLAR";
  this.AutoApply = data.AutoApply || false;
  this.CustomerVisible = data.CustomerVisible || false;
  this.AdjustmentAllowed = data.AdjustmentAllowed || false;
  this.Taxable = data.Taxable || false;
  this.Definition = data.Definition || null;
  this.UpsellOptionId = data.UpsellOptionId || null;
  this.OrgUnitId = data.OrgUnitId || null;
  this.Notes = data.Notes || null;
  this.Status = data.Status || null;
};

export const RateSettingsViewModel = function (
  data,
  currentOrgUnitId,
  selectedOrgLocationId
) {
  if (!data) data = {};
  this.Categories = data.Categories || [];
  this.SpecialPriceList = data.SpecialPriceList || [];
  this.MileageBasedPricing = data.MileageBasedPricing;
  this.PricePerMile = data.PricePerMile || null;
  this.InheritedFromParent = data.InheritedFromParent;
  this.DisplayInherited =
    currentOrgUnitId !== selectedOrgLocationId ? true : false;
};

export const VehicleAvailabilitySettingsViewModel = function (
  data,
  currentOrgUnitId,
  selectedOrgLocationId
) {
  if (!data) data = {};
  const minPeriodConstraintList = [];
  if (data.MinPeriodConstraintList && data.MinPeriodConstraintList.length > 0) {
    data.MinPeriodConstraintList.map((entity) => {
      minPeriodConstraintList.push(new VehicleAvailabilityModel(entity));
    });
  }
  const offlineConstraintList = [];
  if (data.OfflineConstraintList && data.OfflineConstraintList.length > 0) {
    data.OfflineConstraintList.map((entity) => {
      offlineConstraintList.push(new VehicleAvailabilityModel(entity));
    });
  }
  this.SelectedChildOrgUnitId = data.SelectedOrgUnitId || selectedOrgLocationId;
  this.MinPeriodConstraintList = [...minPeriodConstraintList];
  this.OfflineConstraintList = [...offlineConstraintList];
  this.OverrideConflict = data.OverrideConflict || null;
  this.InheritedFromParent = data.InheritedFromParent || false;
  this.DisplayInherited =
    currentOrgUnitId !== selectedOrgLocationId ? true : false;
};
export const VehicleAvailabilityModel = function (data) {
  if (!data) data = {};
  this.StartDate = data.StartDate || null;
  this.EndDate = data.EndDate || null;
  this.DaysOfWeek = data.DaysOfWeek || null;
  this.CategoryId = data.CategoryId || null;
  this.ConflictedReservationCount = data.ConflictedReservationCount || null;
  this.AvailabilityId = data.AvailabilityId || null;
  this.CalendarEntrySubtype = data.CalendarEntrySubtype || null;
  this.CalendarEntryType = data.CalendarEntryType || null;
  this.MinRentalPeriod = data.MinRentalPeriod || null;
};

export const RoleSettingsViewModel = function (data) {
  if (!data) data = {};
  const roles = [];
  if (data.Roles && data.Roles.length > 0) {
    data.Roles.map((entity) => {
      roles.push(new RolesModel(entity));
    });
  }
  this.Roles = [...roles];
};

export const RolesModel = function (data) {
  if (!data) data = {};
  this.Code = data.Code || null;
  this.RoleId = data.RoleId || null;
  this.LocationId = data.LocationId || null;
  this.ChannelType = data.ChannelType || null;
  this.VisibleToSuperAdminOnly = data.VisibleToSuperAdminOnly || false;
  this.Inherited = data.Inherited || false;
  this.PermissionCount = data.PermissionCount || 0;
  this.UserCount = data.UserCount || 0;
  this.Name = data.Name || null;
  this.Description = data.Description || null;
  this.Status = data.Status || null;
};
export default Role;

export const InsuranceSettingsViewModel = function (
  data,
  currentOrgUnitId,
  selectedOrgLocationId,
  inheritedFromParent
) {
  if (!data) data = {};
  const liabilityData = {
    DummyId: 5,
    Description: data.LiabilityDescription,
    HourlyUnit: data.LiabilityInsuranceHourlyUnit,
    HourlyAmount: data.LiabilityInsuranceHourlyCost,
    DailyUnit: data.LiabilityInsuranceUnit,
    DailyAmount: data.LiabilityInsuranceCost,
    WeeklyUnit: data.LiabilityInsuranceWeeklyUnit,
    WeeklyAmount: data.LiabilityInsuranceWeeklyCost,
    MonthlyUnit: data.LiabilityInsuranceMonthlyUnit,
    MonthlyAmount: data.LiabilityInsuranceMonthlyCost,
    IsOffered: data.OfferLiability,
  };
  const cdwData = {
    DummyId: 0,
    Description: data.CdwDescription,
    Deductible: data.Deductible,
    HourlyUnit: data.CdwHourlyUnit,
    HourlyAmount: data.CdwHourlyCost,
    DailyUnit: data.CdwUnit,
    DailyAmount: data.CdwCost,
    WeeklyUnit: data.CdwWeeklyUnit,
    WeeklyAmount: data.CdwWeeklyCost,
    MonthlyUnit: data.CdwMonthlyUnit,
    MonthlyAmount: data.CdwMonthlyCost,
    OfferLiability: data.OfferLiability,
    IsOffered: data.OfferCdw,
  };
  const otherDeductibleList = [];
  if (data.DeductibleOption1) {
    otherDeductibleList.push({
      DummyId: 1,
      Deductible: data.DeductibleOption1,
      HourlyUnit: data.DeductibleOption1HourlyUnit,
      HourlyAmount: data.DeductibleOption1HourlyPrice,
      DailyUnit: data.DeductibleOption1Unit,
      DailyAmount: data.DeductibleOption1Price,
      WeeklyUnit: data.DeductibleOption1WeeklyUnit,
      WeeklyAmount: data.DeductibleOption1WeeklyPrice,
      MonthlyUnit: data.DeductibleOption1MonthlyUnit,
      MonthlyAmount: data.DeductibleOption1MonthlyPrice,
    });
  }
  if (data.DeductibleOption2) {
    otherDeductibleList.push({
      DummyId: 2,
      Deductible: data.DeductibleOption2,
      HourlyUnit: data.DeductibleOption2HourlyUnit,
      HourlyAmount: data.DeductibleOption2HourlyPrice,
      DailyUnit: data.DeductibleOption2Unit,
      DailyAmount: data.DeductibleOption2Price,
      WeeklyUnit: data.DeductibleOption2WeeklyUnit,
      WeeklyAmount: data.DeductibleOption2WeeklyPrice,
      MonthlyUnit: data.DeductibleOption2MonthlyUnit,
      MonthlyAmount: data.DeductibleOption2MonthlyPrice,
    });
  }
  if (data.DeductibleOption3) {
    otherDeductibleList.push({
      DummyId: 3,
      Deductible: data.DeductibleOption3,
      HourlyUnit: data.DeductibleOption3HourlyUnit,
      HourlyAmount: data.DeductibleOption3HourlyPrice,
      DailyUnit: data.DeductibleOption3Unit,
      DailyAmount: data.DeductibleOption3Price,
      WeeklyUnit: data.DeductibleOption3WeeklyUnit,
      WeeklyAmount: data.DeductibleOption3WeeklyPrice,
      MonthlyUnit: data.DeductibleOption3MonthlyUnit,
      MonthlyAmount: data.DeductibleOption3MonthlyPrice,
    });
  }
  if (data.DeductibleOption4) {
    otherDeductibleList.push({
      DummyId: 4,
      Deductible: data.DeductibleOption4,
      HourlyUnit: data.DeductibleOption4HourlyUnit,
      HourlyAmount: data.DeductibleOption4HourlyPrice,
      DailyUnit: data.DeductibleOption4Unit,
      DailyAmount: data.DeductibleOption4Price,
      WeeklyUnit: data.DeductibleOption4WeeklyUnit,
      WeeklyAmount: data.DeductibleOption4WeeklyPrice,
      MonthlyUnit: data.DeductibleOption4MonthlyUnit,
      MonthlyAmount: data.DeductibleOption4MonthlyPrice,
    });
  }
  const ageBasedInsuranceList = [];
  if (data.InsuranceAge1Code) {
    ageBasedInsuranceList.push({
      DummyId: 1,
      InsuranceMaxAge: data.InsuranceAge1Max,
      InsuranceMinAge: data.InsuranceAge1Min,
      InsuranceAgeCode: data.InsuranceAge1Code,
      Description: data.InsuranceMsg1,
      HourlyUnit: data.InsuranceAge1Unit,
      HourlyAmount: data.InsuranceAge1HourlyFees,
      DailyUnit: data.InsuranceAge1Unit,
      DailyAmount: data.InsuranceAge1Fees,
      WeeklyUnit: data.InsuranceAge1WeeklyUnit,
      WeeklyAmount: data.InsuranceAge1WeeklyFees,
      MonthlyUnit: data.InsuranceAge1MonthlyUnit,
      MonthlyAmount: data.InsuranceAge1MonthlyFees,
      IsOffered: data.InsuranceAge1Offer,
    });
  }
  if (data.InsuranceAge2Code) {
    ageBasedInsuranceList.push({
      DummyId: 2,
      InsuranceMaxAge: data.InsuranceAge2Max,
      InsuranceMinAge: data.InsuranceAge2Min,
      InsuranceAgeCode: data.InsuranceAge2Code,
      Description: data.InsuranceMsg2,
      HourlyUnit: data.InsuranceAge2Unit,
      HourlyAmount: data.InsuranceAge2HourlyFees,
      DailyUnit: data.InsuranceAge2Unit,
      DailyAmount: data.InsuranceAge2Fees,
      WeeklyUnit: data.InsuranceAge2WeeklyUnit,
      WeeklyAmount: data.InsuranceAge2WeeklyFees,
      MonthlyUnit: data.InsuranceAge2MonthlyUnit,
      MonthlyAmount: data.InsuranceAge2MonthlyFees,
      IsOffered: data.InsuranceAge2Offer,
    });
  }
  if (data.InsuranceAge3Code) {
    ageBasedInsuranceList.push({
      DummyId: 3,
      InsuranceMaxAge: data.InsuranceAge3Max,
      InsuranceMinAge: data.InsuranceAge3Min,
      InsuranceAgeCode: data.InsuranceAge3Code,
      Description: data.InsuranceMsg3,
      HourlyUnit: data.InsuranceAge3HourlyUnit,
      HourlyAmount: data.InsuranceAge3HourlyFees,
      DailyUnit: data.InsuranceAge3Unit,
      DailyAmount: data.InsuranceAge3Fees,
      WeeklyUnit: data.InsuranceAge3WeeklyUnit,
      WeeklyAmount: data.InsuranceAge3WeeklyFees,
      MonthlyUnit: data.InsuranceAge3MonthlyUnit,
      MonthlyAmount: data.InsuranceAge3MonthlyFees,
      IsOffered: data.InsuranceAge3Offer,
    });
  }
  this.DisplayInherited = currentOrgUnitId !== selectedOrgLocationId;
  this.InheritedFromParent = inheritedFromParent || false;
  this.SelectedChildOrgUnitId = selectedOrgLocationId || null;
  this.LiabilityData = liabilityData;
  this.CDWData = cdwData;
  this.OtherDeductibles = [...otherDeductibleList];
  this.AgeBasedInsuranceList = [...ageBasedInsuranceList];
};
export const InsuranceEntityModel = function (data) {
  if (!data) data = {};
  this.DummyId = data.DummyId || null;
  this.InsuranceMinAge = data.InsuranceMinAge || null;
  this.InsuranceMaxAge = data.InsuranceMaxAge || null;
  this.InsuranceAgeCode = data.InsuranceAgeCode || null;
  this.Description = data.Description || null;
  this.Deductible = data.Deductible || null;
  this.HourlyUnit = data.HourlyUnit || null;
  this.HourlyAmount = data.HourlyAmount || null;
  this.DailyUnit = data.DailyUnit || null;
  this.DailyAmount = data.DailyAmount || null;
  this.WeeklyUnit = data.WeeklyUnit || null;
  this.WeeklyAmount = data.WeeklyAmount || null;
  this.MonthlyUnit = data.MonthlyUnit || null;
  this.MonthlyAmount = data.MonthlyAmount || null;
  this.IsOffered = data.IsOffered || false;
};
export const RateSettingsViewModel_New = function (
  data,
  currentOrgUnitId,
  selectedOrgLocationId,
  categoryData,
  specialPriceList
) {
  if (!data) data = {};
  this.DisplayInherited = currentOrgUnitId !== selectedOrgLocationId;
  this.InheritedFromParent = data.InheritedFromParent || false;
  this.SelectedChildOrgUnitId = selectedOrgLocationId || null;
  this.CategoryInfo = new CategoryInfoRequestBean(categoryData);
  this.SpecialPriceList = specialPriceList || [];
  this.MileageBasedPricing = data.MileageBasedPricing || false;
  this.PricePerMile = data.PricePerMile || null;
};
export const CapacityPlanningViewModel = function (
  data,
  currentOrgUnitId,
  selectedOrgLocationId
) {
  if (!data) data = {};
  this.DisplayInherited = currentOrgUnitId !== selectedOrgLocationId;
  this.InheritedFromParent = data.InheritFromParent || false;
  this.SelectedChildOrgUnitId = selectedOrgLocationId || null;
  this.CapacityBuckets = data.CapacityBuckets || [];
};
export const PermissionSettingsViewModel = function (data) {
  if (!data) data = {};
  const permissions = [];
  const permissionList = [];
  if (data.Permissions && data.Permissions.length > 0) {
    data.Permissions.map((entity) => {
      permissions.push(new PermissionModel(entity));
      permissionList.push({
        key: entity.PermissionId,
        displayValue: entity.DisplayText,
        type: entity.PermissionType,
      });
    });
  }
  this.Permissions = [...permissions];
  this.PermissionList = [...permissionList];
};

export const PermissionModel = function (data) {
  if (!data) data = {};

  const dependantPermissionsArray = [];
  if (data.DependantPermissions && data.DependantPermissions.length > 0) {
    data.DependantPermissions.map((entity) => {
      dependantPermissionsArray.push(entity);
    });
  }

  const prerequisitePermissionsArray = [];
  if (data.PrerequisitePermissions && data.PrerequisitePermissions.length > 0) {
    data.PrerequisitePermissions.map((entity) => {
      prerequisitePermissionsArray.push(entity);
    });
  }

  const arrayOfURLs = [];
  if (data.URIs && data.URIs.length > 0) {
    data.URIs.map((entity) => {
      arrayOfURLs.push(entity);
    });
  } else {
    arrayOfURLs.push("");
  }

  this.ChannelType = data.ChannelType || "CALL_CENTER";
  this.DependantPermissions = [...dependantPermissionsArray];
  this.DisplayText = data.DisplayText || "";
  this.ErrorCode = data.ErrorCode || "";
  this.ErrorMsg = data.ErrorMsg || "";
  this.Name = data.Name || "";
  this.PermissionId = data.PermissionId || "";
  this.PermissionType = data.PermissionType || "";
  this.PrerequisitePermissions = [...prerequisitePermissionsArray];
  this.Status = data.Status || "";
  this.URIs = [...arrayOfURLs];
  this.VisibleToSuperAdminOnly = data.VisibleToSuperAdminOnly || false;
};

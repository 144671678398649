import { FoxRequestHeader } from "../../fox-api/domain/fox-request-header";
import ShuttleModel from "../models/shuttle-model";

const ShuttleRequest = function (shuttleModel, requestType) {
  this.requestHeader = new FoxRequestHeader({
    requestType: requestType || "GET",
  });
  this.shuttle = new ShuttleModel(shuttleModel);
};
export default ShuttleRequest;

export const FleetUtilizationLogs = function (data) {
    if (!data) data = {};
    this.longMessage = data.longMessage || null;
    this.personName = data.personName || null;
    this.lastTimestamp =
      data.lastTimestamp || null;
    this.classGroupText =
      data.classGroupText || null;
    this.overrideReason = data.overrideReason || null;

  };

export const FleetUtilizationLogsModel = function(response){
    const utilizationLogs = [];
      response.fleetUtilizationLogs &&
        response.fleetUtilizationLogs.map((entity) => {
            utilizationLogs.push(new FleetUtilizationLogs(entity));
        });
        this.FleetUtilizationLogs = [...utilizationLogs];
  };
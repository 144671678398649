import React from "react";
import Draggable from "react-draggable";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
export const ConfirmModal = (props) => {
  const { body, modalTtile, yesBtnLabel, noBtnLabel, isNoBtnAllow, showModal,
    closeModal, doConfirmFunctionality, minWidth } = props;
  const toggle = () => {
    closeModal();
  };
  const closeBtn = !isNoBtnAllow ? (
    <button className="close" onClick={toggle} type="button">
      &times;
    </button>
  ) : (
    <></>
  );

  return (
    <Draggable handle=".cursor">
      <Modal
        isOpen={showModal}
        className="no-cursor"
        style={minWidth && { minWidth: minWidth }}
      // toggle={toggle}
      >
        <ModalHeader
          className="modal-header bg-primary modal-title text-white cursor pl-3"
          close={closeBtn}
          toggle={toggle}
        >
          {/* <i className="fas fa-key" />{" "} */}
          {modalTtile ? modalTtile : "Confirm"}
        </ModalHeader>
        <ModalBody>
          <label className="col-sm-12 col-form-label font-weight-bold">
            {body || "Do you want to continue?"}
          </label>
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            className="btn btn-primary waves-effect waves-light btnSize ml-2"
            onClick={() => doConfirmFunctionality()}
          >
            {yesBtnLabel || "Yes"}
          </button>
          {!isNoBtnAllow ? (
            <button
              type="button"
              className="btn btn-secondary  waves-effect waves-light btnSize ml-2"
              onClick={() => closeModal()}
            >
              {noBtnLabel || "No"}
            </button>
          ) : (
            ""
          )}
        </ModalFooter>
      </Modal>
    </Draggable>
  );
};
export default ConfirmModal;

import { FoxRequestHeader } from "../domain/fox-request-header";
import { FoxLocationRequest } from "../request/fox-location-request";
import { FoxVehicleCLassRequest } from "../request/fox-vehicle-class-req";

export const GetAddOnsRequest = function (data) {
  if (!data) data = {};
  this.requestHeader = new FoxRequestHeader();
  this.rentalLocation = new FoxLocationRequest(data.rentalLocationCode);
  this.returnLocation = new FoxLocationRequest(data.returnLocationCode);
  this.rentalDateTime = data.rentalDateTime || null;
  this.returnDateTime = data.returnDateTime || null;
  this.vehicleClass = new FoxVehicleCLassRequest(data.vehicleClassCode);
  this.rateId = data.rateId || null;
  this.payType = data.payType || null;
};

import React, { useState } from "react";
import Combobox from "react-widgets/lib/Combobox";
import "./input-ctrl.scss";

export const InputComboCtrl = (props) => {
    const {
        name,
        value,
        placeholder,
        onChange,
        disabled,
        externalData,
        iconClass,
        imgIco,
        data,
        valueField,
        textField,
        onBlur,
        loader,
        isRequired,
        validateFunction
    } = props;
    const [error, setError] = useState(false);
    const onHandleOnChange = (data) => {
        if (isRequired && validateFunction) {
            setError(!validateFunction(name, data[valueField], true));
        }
        onChange(data, name, externalData);
    }
    return (
        <div className='input-ctrl'>
            {imgIco ? <img
                src={imgIco}
                alt=""
            /> : iconClass ? <i className={iconClass} aria-hidden="true"></i> : ""}
            <label className="place-holder">{placeholder}</label>
            <Combobox
                id={name}
                name={name}
                data={data}
                placeHolder={placeholder}
                dataKey={valueField}
                textField={textField}
                value={value || ''}
                onChange={(event) =>
                    onChange ? onHandleOnChange(event) : ""
                }
                onBlur={(event) =>
                    onBlur ? onBlur(event) : ""
                }
                disabled={disabled}
                focusFirstItem={false}
                busy={loader}
                busySpinner={
                    <span className="fas fa-sync fa-spin" />
                }
                className={`${error ? "error-field" : ""}`}
            />
        </div>
    )
}
export default InputComboCtrl;
import React, { useState } from 'react'
import ReactHtmlParser from 'react-html-parser'
import { Link } from 'react-router-dom'
import ModalEngine from '../../../components/modal/modal-engine'
import ModalInfo from '../../../services/entities/models/ModalInfo'
import { EmployeeIcon, UserProfileLinkIcon } from '../../../shared/AppImages'
import { ADMIN_COMMON_CAPTION, COMMON_MSG, ModalType } from '../../../shared/GlobalVar'
import PermissionName from '../../../shared/Permissions'
import {
    getDateOnlyString,
    getFullName,
    getPhoneNumberText,
    hasPermission,
} from '../../../shared/utility'
import { createTooltip } from '../../../shared/utility/tooltip-utility'
import IconListForReservation from '../../SearchCommonData/iconListComponent'

export const SearchMemberGrid = (props) => {
    const [modalInfo, setModalInfo] = useState([new ModalInfo()])
    const [loading, setLoading] = useState(false)
    const hasViewLicensePermission = hasPermission(PermissionName.VIEW_DRIVING_LICENSE);
    const hasViewMemberPermission = hasPermission(PermissionName.VIEW_MEMBER);
    const closeModal = () => {
        setModalInfo(new ModalInfo())
    }
    const showModal = (modalData) => {
        setModalInfo({
            type: modalData.type,
            showModal: true,
            memberId: modalData.memberId,
        })
    }
    return (
        <div>
            <div className="datagrid">
                <div className={`column ${!hasViewLicensePermission ? "no-driving-license" : ""}`}>
                    <span className="column__header" />
                    <span className="column__header">
                        {ADMIN_COMMON_CAPTION.CUSTOMER_ID}
                    </span>
                    <span className="column__header">Name</span>
                    <span className="column__header">Date of Birth</span>
                    <span className="column__header">Email/Username</span>
                    {hasViewLicensePermission && <span className="column__header">Driver's Lic.</span>}
                    <span className="column__header">Address</span>
                    <span className="column__header">Phone No.</span>
                    <span className="column__header">Employee Location</span>
                    <span className="column__header">Profile Location</span>
                </div>
                {props.dataList.map((post) => {
                    const dLicenseNo = post.DrivingLicense
                        ? !post.DrivingLicenseState
                            ? post.DrivingLicense
                            : post.DrivingLicense +
                            '/' +
                            post.DrivingLicenseState
                        : '--'
                    const fullName = getFullName(
                        post.FirstName,
                        post.LastName,
                        post.MiddleName
                    )
                    return (
                        <div className={`row ${!hasViewLicensePermission ? "no-driving-license" : ""}`} key={post.MembershipId}>
                            <span className="row__column  img-container">
                                <IconListForReservation
                                    resData={post}
                                    pageName="member"
                                />
                            </span>
                            <span className="row__column">
                                <span
                                    className={`md-trigger ${hasViewMemberPermission
                                        ? 'pseudo-link'
                                        : ''
                                        }`}
                                    onClick={(event) =>
                                        hasViewMemberPermission
                                            ? showModal({
                                                type: ModalType.MEMBER_QUICK_MODAL,
                                                showModal: true,
                                                memberId: post.PersonId,
                                            })
                                            : ''
                                    }
                                >
                                    {post.MembershipId || '--'}
                                </span>
                            </span>
                            <span className="row__column" id="employeeNameId">
                                {createTooltip(
                                    <span
                                        className={`md-trigger ${hasViewMemberPermission
                                            ? 'pseudo-link'
                                            : ''
                                            }`}
                                        id={'employeeNameId_' + post.PersonId}
                                        onClick={(event) =>
                                            hasViewMemberPermission
                                                ? showModal({
                                                    type: ModalType.MEMBER_QUICK_MODAL,
                                                    showModal: true,
                                                    memberId: post.PersonId,
                                                })
                                                : ''
                                        }
                                    >
                                        {fullName}
                                    </span>,
                                    fullName
                                )}
                            </span>
                            <span
                                className="row__column  p-0 icon-right"
                                id="icoEmployeeId"
                            >
                                {hasViewMemberPermission
                                    ? createTooltip(
                                        <Link
                                            id={
                                                'icoEmployeeType_' +
                                                post.PersonId
                                            }
                                            to={{
                                                pathname:
                                                    '/member/memberProfile',
                                                search:
                                                    '?memberId=' +
                                                    post.PersonId,
                                                state: {
                                                    breadCrumbList:
                                                        props.breadCrumbList,
                                                    breadCrumbTitle: fullName,
                                                },
                                            }}
                                        >
                                            <img
                                                alt=""
                                                src={
                                                    post.EmployeeNo
                                                        ? EmployeeIcon
                                                        : UserProfileLinkIcon
                                                }
                                                style={{
                                                    height: '12px',
                                                    width: '12px',
                                                }}
                                            />
                                        </Link>,
                                        ReactHtmlParser(
                                            post.EmployeeNo
                                                ? 'Employee#: ' +
                                                post.EmployeeNo +
                                                '<br>Location: ' +
                                                (post.EmployeeLocationName
                                                    ? post.EmployeeLocationName
                                                    : '--') +
                                                '<br/><br/>' +
                                                COMMON_MSG.UserProfileLinkIconTooltip
                                                : COMMON_MSG.UserProfileLinkIconTooltip
                                        ),
                                        { forceShow: true }
                                    )
                                    : ''}
                            </span>
                            <span className="row__column">
                                {createTooltip(
                                    getDateOnlyString(post.DateOfBirth),
                                    getDateOnlyString(post.DateOfBirth)
                                )}
                            </span>
                            <span className="row__column">
                                {createTooltip(
                                    <a
                                        href={'mailto:' + post.EmailAddress}
                                        id={'email_' + post.PersonId}
                                    >
                                        {post.EmailAddress}
                                    </a>,
                                    post.EmailAddress || '--',
                                    { forceShow: true }
                                )}
                            </span>
                            {hasViewLicensePermission && <span className="row__column">
                                {createTooltip(dLicenseNo, dLicenseNo)}
                            </span>}
                            <span
                                className={`row__column ${hasViewMemberPermission &&
                                    post.DisplayAddressCount > 1
                                    ? 'pseudo-link'
                                    : ''
                                    }`}
                                id={
                                    'formattedPrimaryAddressId_' + post.PersonId
                                }
                                onClick={(event) =>
                                    hasViewMemberPermission &&
                                        post.DisplayAddressCount > 1
                                        ? showModal({
                                            type: ModalType.ALL_ADDRESS_MODAL,
                                            showModal: true,
                                            memberId: post.PersonId,
                                        })
                                        : ''
                                }
                            >
                                {createTooltip(
                                    post.FormattedPrimaryAddress,
                                    post.FormattedPrimaryAddress
                                )}
                            </span>
                            <span
                                className="row__column"
                                id={'phoneId_' + post.PersonId}
                            >
                                {createTooltip(
                                    getPhoneNumberText(post, false),
                                    ReactHtmlParser(
                                        getPhoneNumberText(post, true)
                                    ),
                                    { forceShow: true }
                                )}
                            </span>
                            <span
                                className="row__column"
                                id={'employeeLocationNameId_' + post.PersonId}
                            >
                                {createTooltip(
                                    post.EmployeeLocationName,
                                    post.EmployeeLocationName
                                )}
                            </span>
                            <span
                                className="row__column"
                                id={'profileLocationNameId_' + post.PersonId}
                            >
                                {createTooltip(
                                    post.ProfileLocationName,
                                    post.ProfileLocationName
                                )}
                            </span>
                        </div>
                    )
                })}
            </div>
            {modalInfo.showModal ? (
                <ModalEngine
                    modalType={modalInfo.type}
                    showModal={modalInfo.showModal}
                    closeModal={closeModal}
                    invoiceId={modalInfo.invoiceId}
                    memberId={modalInfo.memberId}
                    resetLoader={props.resetLoader}
                    resetParentPage={props.resetParentPage}
                />
            ) : null}
        </div>
    )
}
export default SearchMemberGrid

import React, { useState } from 'react';
import DialogEngine from '../../../../../components/dialog/dialog-engine';
import { ModalType } from '../../../../../shared/GlobalVar';
import { getCreditPolicyInfo } from '../../../../../shared/json/data';
import { PaymentType } from '../../../../../shared/utility/enum-utility';
import CommonTitle from '../../../component/common-title';
import CancelledRateBlock from '../cancelled-rate-block';
import GeneralRateRow from '../general-rate-row';
import TransactionRateBlock from '../transaction-rate-block';
import PricingCommonWrapper from './pricing-common-wrapper';

function PaymentsCreditsBlock(props) {
    const [modalData, setModalData] = useState(null);
    const { pricingData, currentPayType, customerFreeCredit, reservationStatus } = props;
    const totalCharges = pricingData?.totalCharges;
    let totalFreeCredits =
        pricingData?.freeCreditAmountApplied;
    let balanceDue = pricingData?.balanceDue;
    if (customerFreeCredit &&
        (currentPayType === PaymentType.PAYLATER ||
            reservationStatus === "PAYMENT_PENDING")
    ) {
        totalFreeCredits = customerFreeCredit > totalCharges ? totalCharges : customerFreeCredit;
        balanceDue = totalCharges - totalFreeCredits;
    }
    const hasPaymentsAndCredits =
        (!isNaN(pricingData.totalCancellationCharges) && pricingData.totalCancellationCharges !== 0) ||
        (!isNaN(pricingData.totalCancellationFee) && pricingData.totalCancellationFee !== 0) ||
        (!isNaN(pricingData.cancellationCourtesyAmount) && pricingData.cancellationCourtesyAmount !== 0) ||
        (!isNaN(totalFreeCredits) && totalFreeCredits !== 0) ||
        (!isNaN(pricingData.totalCreditsApplied) && pricingData.totalCreditsApplied !== 0) ||
        (!isNaN(pricingData.totalPayments) && pricingData.totalPayments !== 0) ||
        (!isNaN(pricingData.totalPaymentsAndCredits) && pricingData.totalPaymentsAndCredits !== 0) ||
        balanceDue !== totalCharges;
    if (!hasPaymentsAndCredits) return "";

    const showPrectionAndExtrasNotesModal = () => {
        setModalData(
            {
                modalType: ModalType.COMMON_NOTES_MODAL,
                modalProps: {
                    showModal: true,
                    isDraggable: true,
                    closeModal: onCloseModal,
                    modalTitle: "Credit Policy",
                    modaWrapperClass: "protection-addons-modal"
                },
                modalBodyProps: {
                    notes: getCreditPolicyInfo(),
                },
            }
        );
    }
    const onCloseModal = () => {
        setModalData(null);
    }
    return (
        <PricingCommonWrapper>
            <CommonTitle title="Payments & Credits" classNames="common-title" withIcon={false} />
            <CancelledRateBlock
                totalCancellationCharges={pricingData?.totalCancellationCharges}
                totalCancellationFee={pricingData?.totalCancellationFee}
                cancellationCourtesyAmount={pricingData?.cancellationCourtesyAmount}
            />
            {!isNaN(pricingData?.freeCreditAmountApplied) &&
                <GeneralRateRow
                    key="free-credit-applied"
                    title="Credit Applied From Account"
                    total={pricingData?.freeCreditAmountApplied}
                    isNegative={true}
                    onClick={showPrectionAndExtrasNotesModal}
                />}
            {modalData ?
                <DialogEngine
                    {...modalData}
                />
                : ""}
            {!isNaN(pricingData?.totalCreditsApplied) &&
                <GeneralRateRow
                    key="total-credits"
                    title="Total Credits"
                    total={pricingData?.totalCreditsApplied}
                    isNegative={true}
                />}
            {!isNaN(pricingData?.totalPayments) &&
                <GeneralRateRow
                    key="total-payments"
                    title="Payments"
                    total={pricingData?.totalPayments}
                    isNegative={true}
                />}
            <TransactionRateBlock
                totalCapturedAmount={pricingData?.totalCapturedAmount}
                totalPreAuthReleaseAmount={pricingData?.totalPreAuthReleaseAmount}
                totalPreAuthAmount={pricingData?.totalPreAuthAmount}
                totalRefunds={pricingData?.totalRefunds}
                totalVoidedRefunds={pricingData?.totalVoidedRefunds}
                totalVoidedPayments={pricingData?.totalVoidedPayments}
            />
            <hr />
            {balanceDue !== totalCharges && (
                <GeneralRateRow
                    key="total-balance-due"
                    contClasses="balance-due-row"
                    title="Your Charge"
                    total={balanceDue}
                />
            )}
        </PricingCommonWrapper>
    )
}

export default PaymentsCreditsBlock
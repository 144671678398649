import { FoxRequestHeader } from "../../fox-api/domain/fox-request-header";

const VicinityRequest = function (data) {
  data = data || {};
  this.requestHeader = new FoxRequestHeader({
    requestType: "GET",
  });
  this.vin = data.vin || null;
  this.shuttleCode = data.shuttleCode || null;
  this.make = data.make || null;
  this.model = data.model || null;
  this.year = data.year || null;
  this.engineStatus = data.engineStatus || null;
  this.shuttleConnectionStatus = data.shuttleConnectionStatus || null;
  this.availability = data.availability || null;
  this.landmarks = data.landmarks || false;
  this.shuttles = data.shuttles || false;
  this.locationCode = data.locationCode || null;
  this.latitude = data.latitude || null;
  this.longitude = data.longitude || null;
  this.radiusInMiles = data.radiusInMiles || null;
};
export default VicinityRequest;

import { FoxRequestHeader } from "../../services/entities/fox-api/domain/fox-request-header";
import { invokeServerAPI } from "../../services/httpService";
import * as actionTypes from "./actionTypes";

export const getFleetUtilizationForecastStart = () => {
    return {
      type: actionTypes.GET_FLEET_UTILIZATION_FORECAST_START,
    };
  };
  export const getFleetUtilizationForecastSuccess = (response) => {
    return {
      type: actionTypes.GET_FLEET_UTILIZATION_FORECAST_SUCCESS,
      fleetForecastResponse: response,
    };
  };
  export const getFleetUtilizationForecastFail = (error) => {
    return {
      type: actionTypes.GET_FLEET_UTILIZATION_FORECAST_FAIL,
      error: error,
    };
  };
  export const getFleetUtilizationForecastReset = (error) => {
    return {
      type: actionTypes.GET_FLEET_UTILIZATION_FORECAST_RESET,
      error: error,
    };
  };
  export const getFleetUtilizationForecastInfo = (req) => {
    return (dispatch) => {
      req.requestHeader = new FoxRequestHeader({ requestType: "GET" });
      dispatch(getFleetUtilizationForecastStart());
      invokeServerAPI("/admin/fleetutilizationforecast", req, true)
        .then((response) => {
          dispatch(getFleetUtilizationForecastSuccess(response));
        })
        .catch((err) => {
          //dispatch(getFleetUtilizationForecastFail(err));
        });
    };
  };


export const updateFleetUtilizationForecastStart = () => {
  return {
    type: actionTypes.UPDATE_FLEET_UTILIZATION_FORECAST_START,
  };
};
export const updateFleetUtilizationForecastSuccess = (response) => {
  return {
    type: actionTypes.UPDATE_FLEET_UTILIZATION_FORECAST_SUCCESS,
    updateFleetForecastResponse: response,
  };
};
export const updateFleetUtilizationForecastFail = (error) => {
  return {
    type: actionTypes.UPDATE_FLEET_UTILIZATION_FORECAST_FAIL,
    error: error,
  };
};
export const updateFleetUtilizationForecastReset = (error) => {
  return {
    type: actionTypes.UPDATE_FLEET_UTILIZATION_FORECAST_RESET,
    error: error,
  };
};
export const updateFleetUtilizationForecast = (req) => {
  return (dispatch) => {
    req.requestHeader = new FoxRequestHeader({ requestType: "POST" });
    dispatch(updateFleetUtilizationForecastStart());
    invokeServerAPI("/admin/fleetutilizationforecast", req, true)
      .then((response) => {
        dispatch(updateFleetUtilizationForecastSuccess(response));
      })
      .catch((err) => {
        //dispatch(updateFleetUtilizationForecastFail(err));
      });
  };
};



export const getFleetClassGroupInfoStart = () => {
  return {
    type: actionTypes.GET_DEFAULT_FLEET_CLASS_GROUP_START,
  };
};
export const getFleetClassGroupInfoSuccess = (response) => {
  return {
    type: actionTypes.GET_DEFAULT_FLEET_CLASS_GROUP_SUCCESS,
    fleetClassGroupReponse: response,
  };
};
export const getFleetClassGroupInfoFail = (error) => {
  return {
    type: actionTypes.GET_DEFAULT_FLEET_CLASS_GROUP_FAIL,
    error: error,
  };
};
export const getFleetClassGroupInfoReset = (error) => {
  return {
    type: actionTypes.GET_DEFAULT_FLEET_CLASS_GROUP_RESET,
    error: error,
  };
};
export const getFleetClassGroupInfo = (req) => {
  return (dispatch) => {
    dispatch(getFleetClassGroupInfoStart());
    invokeServerAPI("/admin/orgunitfleetclassgroup", req, true)
      .then((response) => {
        dispatch(getFleetClassGroupInfoSuccess(response));
      })
      .catch((err) => {
        //dispatch(getFleetClassGroupFail(err));
      });
  };
};

export const updateFleetClassGroupStart = () => {
  return {
    type: actionTypes.UPDATE_FLEET_CLASS_GROUP_START,
  };
};
export const updateFleetClassGroupSuccess = (response) => {
  return {
    type: actionTypes.UPDATE_FLEET_CLASS_GROUP_SUCCESS,
    updateFleetClassGroupReponse: response,
  };
};
export const updateFleetClassGroupFail = (error) => {
  return {
    type: actionTypes.UPDATE_FLEET_CLASS_GROUP_FAIL,
    error: error,
  };
};
export const updateFleetClassGroupReset = (error) => {
  return {
    type: actionTypes.UPDATE_FLEET_CLASS_GROUP_RESET,
    error: error,
  };
};
export const updateFleetClassGroup = (req) => {
  return (dispatch) => {
    req.requestHeader = new FoxRequestHeader({ requestType: "POST" });
    dispatch(updateFleetClassGroupStart());
    invokeServerAPI("/admin/orgunitfleetclassgroup", req, true)
      .then((response) => {
        dispatch(updateFleetClassGroupSuccess(response));
      })
      .catch((err) => {
        //dispatch(updateFleetClassGroupFail(err));
      });
  };
};


export const getFleetUtilizationLogsStart = () => {
  return {
    type: actionTypes.GET_FLEET_UTILIZATION_LOGS_START,
  };
};
export const getFleetUtilizationLogsSuccess = (response) => {
  return {
    type: actionTypes.GET_FLEET_UTILIZATION_LOGS_SUCCESS,
    fleetUtilizationLogResponse: response,
  };
};
export const getFleetUtilizationLogsFail = (error) => {
  return {
    type: actionTypes.GET_FLEET_UTILIZATION_LOGS_FAIL,
    error: error,
  };
};
export const getFleetUtilizationLogsReset = (error) => {
  return {
    type: actionTypes.GET_FLEET_UTILIZATION_LOGS_RESET,
    error: error,
  };
};
export const getFleetUtilizationLogsInfo = (req) => {
  return (dispatch) => {
    req.requestHeader = new FoxRequestHeader({ requestType: "GET" });
    dispatch(getFleetUtilizationLogsStart());
    invokeServerAPI("/admin/searchfleetutilizationlog", req, true)
      .then((response) => {
        dispatch(getFleetUtilizationLogsSuccess(response));
      })
      .catch((err) => {
        //dispatch(getFleetUtilizationLogsFail(err));
      });
  };
};
const ActivitySearchModel = function (data) {
  if (!data) data = {};
  this.ActivityId = data.ActivityId || null;
  this.AssetId = data.AssetId || null;
  this.BorrowerName = data.BorrowerName || null;
  this.CallCategory = data.CallCategory || null;
  this.CallCategoryDisplayText = data.CallCategoryDisplayText || null;
  this.Category = data.Category || null;
  this.CategoryDisplayText = data.CategoryDisplayText || null;
  this.ChannelType = data.ChannelType || null;

  this.ChannelTypeDisplayText = data.ChannelTypeDisplayText || null;

  this.Comments = data.Comments || null;

  this.ConfirmationKey = data.ConfirmationKey || null;
  this.CourtesyAmount = data.CourtesyAmount || null;
  this.CreatedBy = data.CreatedBy || null;
  this.CreatedById = data.CreatedById || null;
  this.CreationDate = data.CreationDate || null;
  this.Currency = data.Currency || null;
  this.FreeCreditAmount = data.FreeCreditAmount || null;
  this.IntegConfirmNumber = data.IntegConfirmNumber || null;
  this.IntegResvConfNum = data.IntegResvConfNum || null;
  this.OriginalAmount = data.OriginalAmount || null;

  this.PaymentId = data.PaymentId || null;
  this.Priority = data.Priority || null;
  this.PriorityDisplayText = data.PriorityDisplayText || false;
  this.ProblemType = data.ProblemType || null;
  this.ProblemTypeDisplayName = data.ProblemTypeDisplayName || false;
  this.ProblemsImage = data.ProblemsImage || false;
  this.ReservationCode = data.ReservationCode || false;

  this.ReservationEndDate = data.ReservationEndDate || null;
  this.ReservationId = data.ReservationId || null;
  this.ReservationStartDate = data.ReservationStartDate || null;
  this.ReservationStatus = data.ReservationStatus || null;

  this.Resolved = data.Resolved || null;
  this.ResolvingDate = data.ResolvingDate || null;
  this.RevertFreeCreditReason = data.RevertFreeCreditReason || null;

  this.Status = data.Status || null;
  this.SubCategory = data.SubCategory || null;
  this.SubCategoryDisplayText = data.SubCategoryDisplayText || null;
  this.SubProblemType = data.SubProblemType || null;
  this.SubProblemTypeDisplayName = data.SubProblemTypeDisplayName || null;
};
export default ActivitySearchModel;

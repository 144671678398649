import React, { Fragment, useState } from "react";
import ReactHtmlParser from "react-html-parser";
import { Link } from "react-router-dom";
import ModalEngine from "../../../components/modal/modal-engine";
import ModalInfo from "../../../services/entities/models/ModalInfo";
import {
  EmployeeIcon,
  GrantCredit,
  ReverseCredit,
  UserProfileLinkIcon,
  Voucher,
} from "../../../shared/AppImages";
import { ADMIN_PAGE, COMMON_MSG, EXTERNAL_CODE_LBL, ModalType } from "../../../shared/GlobalVar";
import PermissionName from "../../../shared/Permissions";
import {
  convertDateTimeString,
  convertDateTimeStringWithTimezone,
  formatPricingValue,
  getFormattedVehicleName,
  getResStatusWiseDateTimeTooltip,
  getSortType,
  getSortingClassName,
  getSubString,
  hasPermission,
} from "../../../shared/utility";
import {
  getMemberLabelByOrgRentalType,
  isP2POrHybridOrg,
} from "../../../shared/utility/system-utility";
import { createTooltip } from "../../../shared/utility/tooltip-utility";
import IconListForReservation from "../../SearchCommonData/iconListComponent";
export const SearchReservationGrid = (props) => {
  const [modalInfo, setModalInfo] = useState([new ModalInfo()]);
  const closeModal = () => {
    setModalInfo(new ModalInfo());
  };
  const showModal = (modalData) => {
    setModalInfo({
      type: modalData.type,
      showModal: true,
      memberId: modalData.memberId,
      vehicleId: modalData.vehicleId,
    });
  };
  let hrefLink = "#";
  const hasViewMemberPermission = hasPermission(PermissionName.VIEW_MEMBER);
  const hasViewReservationPermission = hasPermission(
    PermissionName.VIEW_RESERVATION
  );
  const hasViewVehiclePermission = hasPermission(PermissionName.VIEW_VEHICLE);

  return (
    <Fragment>
      <div className="datagrid">
        <div className="column">
          <span className="column__header" />
          <span
            className={getSortingClassName(props.pageInfo, "RESV_CREATED_DATE")}
            onClick={(event) =>
              props.doSorting(
                getSortType(props.pageInfo, "RESV_CREATED_DATE"),
                "RESV_CREATED_DATE"
              )
            }
          >
            Date Created
          </span>
          <span
            className={getSortingClassName(
              props.pageInfo,
              "RESV_RESERVATION_CODE"
            )}
            onClick={(event) =>
              props.doSorting(
                getSortType(props.pageInfo, "RESV_RESERVATION_CODE"),
                "RESV_RESERVATION_CODE"
              )
            }
          >
            {props.url === "/rental" ? "Rental Code" : "Res. Code"}
          </span>
          <span className="column__header">{EXTERNAL_CODE_LBL.CONFIRMATION_CODE}</span>
          <span
            className={getSortingClassName(props.pageInfo, "RESV_STATE")}
            onClick={(event) =>
              props.doSorting(
                getSortType(props.pageInfo, "RESV_STATE"),
                "RESV_STATE"
              )
            }
          >
            {props.url === "/rental" ? "Rental Status" : "Res. Status"}
          </span>
          {props.pageName !== ADMIN_PAGE.MEMBER_RESERVATION_SEARCH_PAGE ? (
            <span
              className={getSortingClassName(
                props.pageInfo,
                "RESV_BORROWER_NAME"
              )}
              onClick={(event) =>
                props.doSorting(
                  getSortType(props.pageInfo, "RESV_BORROWER_NAME"),
                  "RESV_BORROWER_NAME"
                )
              }
            >
              {getMemberLabelByOrgRentalType("Name")}
            </span>
          ) : (
            ""
          )}
          <span className="column__header">Vehicle ID</span>
          <span
            className={getSortingClassName(props.pageInfo, "RESV_CATEGORY")}
            onClick={(event) =>
              props.doSorting(
                getSortType(props.pageInfo, "RESV_CATEGORY"),
                "RESV_CATEGORY"
              )
            }
          >
            Category
          </span>
          <span className="column__header">Vehicle Info</span>
          {isP2POrHybridOrg() &&
            props.pageName !==
            ADMIN_PAGE.MEMBER_VEHICLE_RESERVATION_SEARCH_PAGE ? (
            <span
              className={getSortingClassName(props.pageInfo, "RESV_OWNER_NAME")}
              onClick={(event) =>
                props.doSorting(
                  getSortType(props.pageInfo, "RESV_OWNER_NAME"),
                  "RESV_OWNER_NAME"
                )
              }
            >
              Owner Name
            </span>
          ) : (
            ""
          )}
          <span
            className={getSortingClassName(props.pageInfo, "RESV_START_DATE")}
            onClick={(event) =>
              props.doSorting(
                getSortType(props.pageInfo, "RESV_START_DATE"),
                "RESV_START_DATE"
              )
            }
          >
            Start Date/Time
          </span>
          <span
            className={getSortingClassName(props.pageInfo, "RESV_END_DATE")}
            onClick={(event) =>
              props.doSorting(
                getSortType(props.pageInfo, "RESV_END_DATE"),
                "RESV_END_DATE"
              )
            }
          >
            End Date/Time
          </span>
          <span className="column__header">Pickup Location</span>
          <span className="column__header">Total Amount</span>
          <span
            className={getSortingClassName(props.pageInfo, "RESV_BALANCE")}
            onClick={(event) =>
              props.doSorting(
                getSortType(props.pageInfo, "RESV_BALANCE"),
                "RESV_BALANCE"
              )
            }
          >
            Balance
          </span>
        </div>
        {props.dataList.map((reservationObj) => {
          return (
            <div className="row" key={reservationObj.ReservationId}>
              <span className="row__column img-container">
                <IconListForReservation
                  resData={reservationObj}
                  pageName="reservation"
                />
              </span>
              <span
                className="row__column"
                id={"createdOnDateId_" + reservationObj.ReservationId}
              >
                {createTooltip(
                  convertDateTimeStringWithTimezone(
                    reservationObj.CreatedOn,
                    reservationObj.TimezoneString
                  ),
                  convertDateTimeStringWithTimezone(
                    reservationObj.CreatedOn,
                    reservationObj.TimezoneString
                  )
                )}
              </span>
              <span
                className="row__column"
                id={"confirmationKey_" + reservationObj.ReservationId}
              >
                {createTooltip(
                  <Link
                    className={`${hasViewReservationPermission ? "" : "noAnchorLink"
                      }`}
                    to={{
                      pathname: "/reservationSummary",
                      search: "?reservationId=" + reservationObj.ConfirmationKey,
                      state: { breadCrumbList: props.breadCrumbList },
                    }}
                  >
                    {reservationObj.ConfirmationKey || "--"}
                  </Link>,
                  reservationObj.ConfirmationKey
                )}
              </span>
              <span
                className="row__column text-center"
                id={"integConfirmNumber_" + reservationObj.ReservationId}
              >
                {createTooltip(
                  <Link
                    className={`${hasViewReservationPermission ? "" : "noAnchorLink"
                      }`}
                    to={{
                      pathname: "/reservationSummary",
                      search: "?reservationId=" + reservationObj.ConfirmationKey,
                      state: { breadCrumbList: props.breadCrumbList },
                    }}
                  >
                    {reservationObj.IntegConfirmNumber || "--"}
                  </Link>,
                  reservationObj.IntegConfirmNumber
                )}
              </span>
              <span
                className={`row__column ${reservationObj.ReservationState} ${reservationObj.IsLateReservation || false
                  }`}
              >
                {createTooltip(
                  reservationObj.ReservationState,
                  ReactHtmlParser(
                    getResStatusWiseDateTimeTooltip(
                      reservationObj.ActualStartDate,
                      reservationObj.ActualEndDate,
                      reservationObj.PromisedStartDate,
                      reservationObj.TimezoneString,
                      reservationObj.ReservationState
                    )
                  ),
                  { forceShow: true }
                )}
              </span>
              {props.pageName !== ADMIN_PAGE.MEMBER_RESERVATION_SEARCH_PAGE ? (
                <Fragment>
                  <span className={`row__column`} id="employeeNameId">
                    {createTooltip(
                      <label
                        className={hasViewMemberPermission ? "pseudo-link" : ""}
                        onClick={(event) =>
                          hasViewMemberPermission
                            ? showModal({
                              type: ModalType.MEMBER_QUICK_MODAL,
                              showModal: true,
                              memberId: reservationObj.BorrowerId,
                            })
                            : ""
                        }
                      >
                        {reservationObj.BorrowerName}
                      </label>,
                      reservationObj.BorrowerName
                    )}
                  </span>
                  <span
                    className="row__column p-0 icon-right"
                    id="icoEmployeeId"
                  >
                    <span
                      id={"icoEmployeeType_" + reservationObj.ReservationId}
                      className={
                        props.url === "/rental" || props.url === "/reservation"
                          ? hasViewMemberPermission
                            ? ""
                            : "displayNone"
                          : "displayNone"
                      }
                    >
                      <Link
                        to={{
                          pathname: "/member/memberProfile",
                          search: "?memberId=" + reservationObj.BorrowerId,
                          state: {
                            breadCrumbList: props.breadCrumbList,
                            breadCrumbTitle: reservationObj.BorrowerName,
                          },
                        }}
                      >
                        {createTooltip(
                          <img
                            alt=""
                            src={
                              reservationObj.BorrowerEmployeeNo
                                ? EmployeeIcon
                                : UserProfileLinkIcon
                            }
                            style={{ height: "12px", width: "12px" }}
                          />,
                          ReactHtmlParser(
                            reservationObj.BorrowerEmployeeNo
                              ? "Employee#: " +
                              reservationObj.BorrowerEmployeeNo +
                              "<br>Location: " +
                              (reservationObj.BorrowerLocationName
                                ? reservationObj.BorrowerLocationName
                                : "--") +
                              "<br/><br/>" +
                              COMMON_MSG.UserProfileLinkIconTooltip
                              : COMMON_MSG.UserProfileLinkIconTooltip
                          ),
                          { forceShow: true }
                        )}
                      </Link>
                    </span>
                  </span>
                </Fragment>
              ) : (
                ""
              )}
              <span className="row__column">
                {createTooltip(
                  <label
                    className={hasViewVehiclePermission ? "pseudo-link" : ""}
                    onClick={(event) =>
                      hasViewVehiclePermission
                        ? showModal({
                          type: ModalType.VEHICLE_QUICK_MODAL,
                          showModal: true,
                          vehicleId: reservationObj.PersonAssetId,
                        })
                        : ""
                    }
                  >
                    {reservationObj.PersonAssetUnitNo}
                  </label>,
                  reservationObj.PersonAssetUnitNo
                )}
              </span>
              <span
                className="row__column text-center"
                id={"categoryName_" + reservationObj.ReservationId}
              >
                {createTooltip(
                  reservationObj.CategoryName
                    ? getSubString(reservationObj.CategoryName, 0, 4)
                    : "--",
                  reservationObj.CategoryName,
                  { forceShow: true }
                )}
              </span>
              <span
                className="row__column"
                id={"vehicleInfoId_" + reservationObj.ReservationId}
              >
                {createTooltip(
                  <a
                    href={hrefLink}
                    className={`${hasViewVehiclePermission ? "" : "noAnchorLink"
                      }`}
                  >
                    {getFormattedVehicleName(
                      reservationObj.PersonAssetUnitNo,
                      reservationObj.Make,
                      reservationObj.Model,
                      reservationObj.VehicleYear,
                      false
                    )}
                  </a>,
                  getFormattedVehicleName(
                    reservationObj.PersonAssetUnitNo,
                    reservationObj.Make,
                    reservationObj.Model,
                    reservationObj.VehicleYear
                  ),
                  { forceShow: true }
                )}
              </span>
              {isP2POrHybridOrg() &&
                props.pageName !==
                ADMIN_PAGE.MEMBER_VEHICLE_RESERVATION_SEARCH_PAGE ? (
                <span className="row__column">
                  {createTooltip(
                    <label
                      className={hasViewMemberPermission ? "pseudo-link" : ""}
                      onClick={(event) =>
                        hasViewMemberPermission
                          ? showModal({
                            type: ModalType.MEMBER_QUICK_MODAL,
                            showModal: true,
                            memberId: reservationObj.OwnerId,
                          })
                          : ""
                      }
                    >
                      {reservationObj.OwnerName}
                    </label>,
                    reservationObj.OwnerName
                  )}
                </span>
              ) : (
                ""
              )}
              <span
                className="row__column text-center"
                id={"promisedStartDateId_" + reservationObj.ReservationId}
              >
                {createTooltip(
                  convertDateTimeString(reservationObj.PromisedStartDate),
                  convertDateTimeStringWithTimezone(
                    reservationObj.PromisedStartDate,
                    reservationObj.TimezoneString
                  ),
                  { forceShow: true }
                )}
              </span>
              <span
                className="row__column text-center"
                id={"promiseEndDateId_" + reservationObj.ReservationId}
              >
                {createTooltip(
                  convertDateTimeString(reservationObj.PromiseEndDate),
                  convertDateTimeStringWithTimezone(
                    reservationObj.PromiseEndDate,
                    reservationObj.TimezoneString
                  ),
                  { forceShow: true }
                )}
              </span>
              <span
                className="row__column"
                id={"pickUpLocationId_" + reservationObj.ReservationId}
              >
                {createTooltip(
                  reservationObj.PickUpLocation,
                  reservationObj.PickUpLocation
                )}
              </span>
              <span className="row__column p-0 pl-1" id="freeCreditGrantedId">
                {createTooltip(
                  <img
                    id={"freeCreditGrantedId_" + reservationObj.ReservationId}
                    style={{
                      height: "16px",
                      width: "16px",
                    }}
                    className={
                      reservationObj.FreeCreditGranted ? "" : "displayNone"
                    }
                    src={reservationObj.FreeCreditGranted ? GrantCredit : ""}
                    alt=""
                  />,
                  "Grant Credit: $" + reservationObj.FreeCreditGrantedAmount,
                  { forceShow: true }
                )}
              </span>
              <span className="row__column p-0" id="freeCreditAppliedamountId">
                {createTooltip(
                  <img
                    id={
                      "freeCreditAppliedamountId_" +
                      reservationObj.ReservationId
                    }
                    style={{
                      height: "16px",
                      width: "16px",
                    }}
                    className={
                      reservationObj.FreeCreditApplied
                        ? ""
                        : "displayNone"
                    }
                    src={reservationObj.FreeCreditApplied ? ReverseCredit : ""}
                    alt=""
                  />,
                  "Applied Credit: $" + reservationObj.FreeCreditAppliedAmount,
                  { forceShow: true }
                )}
              </span>
              <span className="row__column p-0" id="voucherAddedId">
                {createTooltip(
                  <img
                    id={
                      "voucherId_" +
                      reservationObj.ReservationId
                    }
                    style={{
                      height: "14px",
                      width: "14px",
                    }}
                    className={
                      reservationObj.VoucherAdded
                        ? ""
                        : "displayNone"
                    }
                    src={reservationObj.VoucherAdded ? Voucher : ""}
                    alt=""
                  />,
                  "Voucher Added: $" + reservationObj.VoucherAmount,
                  { forceShow: true }
                )}
              </span>
              <span
                className="row__column text-right pl-1"
                id={"TotalEstimatedPaidSP_" + reservationObj.ReservationId}
              >
                {createTooltip(
                  formatPricingValue(
                    reservationObj.TotalEstimatedPaidSP,
                    "$0.0"
                  ),
                  formatPricingValue(
                    reservationObj.TotalEstimatedPaidSP,
                    "$0.0"
                  )
                )}
              </span>
              <span
                className="row__column text-right"
                id={"BalanceDue_" + reservationObj.ReservationId}
              >
                {createTooltip(
                  formatPricingValue(reservationObj.BalanceDue, "$0.0"),
                  formatPricingValue(reservationObj.BalanceDue, "$0.0")
                )}
              </span>
            </div>
          );
        })}
      </div>
      {modalInfo.showModal ? (
        <ModalEngine
          modalType={modalInfo.type}
          showModal={modalInfo.showModal}
          closeModal={closeModal}
          vehicleId={modalInfo.vehicleId}
          memberId={modalInfo.memberId}
        />
      ) : null}
    </Fragment>
  );
};
export default SearchReservationGrid;

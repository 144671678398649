import { getCountryIsoByDialCode, getFormattedPhoneNumber } from "../../../shared/utility/phone-utility";

const Phone = function (data) {
  if (!data) data = {};
  this.CountryCode = data.CountryCode ? data.CountryCode : data.countryCode || null;
  this.CountryIso = data.CountryIso ? data.CountryIso : data.country ? data.country : this.CountryCode ? getCountryIsoByDialCode(this.CountryCode) : "";
  this.Ext = data.Ext || null;
  this.Number = data.Number ? data.Number : data.number || null;
  this.FormattedNumber = data.Number ? getFormattedPhoneNumber(data.Number, this.CountryIso.toUpperCase()) : getFormattedPhoneNumber(data.number || "", this.CountryIso.toUpperCase()) || "";
};
export default Phone;

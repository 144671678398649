import React from "react";
import SearchItemInput from "./search-item-input";

const SearchNormalItemsContainer = (props) => {
  let formArea = Object.keys(props.searchInputCtrls).map((ctrlName, index) => {
    if (ctrlName === "PageInfo" || ctrlName === "buttons") {
      return "";
    }
    const formElement = props.searchInputCtrls[ctrlName];
    return (
      <SearchItemInput
        key={formElement.id}
        idKey={formElement.id}
        elementType={formElement.elementType}
        elementConfig={formElement.elementConfig}
        value={formElement.value}
        invalid={!formElement.valid}
        shouldValidate={formElement.validation}
        touched={formElement.touched}
        labelInput={formElement.labelInput}
        formElement={formElement}
        changed={props.changed}
        submitHandler={props.submitHandler}
        resetHandler={props.resetHandler}
        inputContainerType="NORMAL"
      />
    );
  });
  const btns = props.searchInputCtrls.buttons.map((btnObj) => {
    return (
      <button
        {...btnObj.elementConfig}
        key={btnObj.id}
        id={btnObj.id}
        onClick={
          btnObj.elementConfig.value === "Search"
            ? props.submitHandler
            : props.resetHandler
        }
      >
        {btnObj.elementConfig.value}
      </button>
    );
  });
  return (
    <div className="search-input-container">
      <div className="search-normal-row">
        {formArea}
        <div className="search-normal-btns">{btns}</div>
      </div>
    </div>
  );
};
export default SearchNormalItemsContainer;

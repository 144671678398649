import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getShuttleReset,
  getShuttle,
} from "../../../store/actions/index";
import { showNotification } from "../../../components/UI/Toaster/Toaster";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import Draggable from "react-draggable";
import { ShuttleViewModel } from "../../../services/entities/shuttle-app/view-model/shuttle-view-model";
import { getShuttleUICtrl } from "../shuttle-ui-ctrl";
import MarkerMap from "../../map/marker-map";
import { getShuttleMapInfo } from "../shuttles-helper";
import ShuttleRequest from "../../../services/entities/shuttle-app/request/ShuttleRequest";
import { convertMsgResponse } from "../../Reservation/fox-reservation/fox-res-helper";
import Spinner from "../../../components/UI/Spinner/Spinner";

export const ShuttleQuickModal = (props) => {
  const { shuttleId } = props;
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.shuttleAPIReducer.loading);
  const shuttleResponse = useSelector(
    (state) => state.shuttleAPIReducer.shuttleResponse
  );
  const dependantOrgList = useSelector((state) =>
    state.auth.loggedInUserData.DependantLocations.length > 0
      ? state.auth.loggedInUserData.DependantLocations
      : []
  );
  const [shuttleData, setShuttleData] = useState(new ShuttleViewModel());
  useEffect(() => {
    if (shuttleId) {
      const shuttleReq = new ShuttleRequest({ shuttleId: shuttleId });
      dispatch(getShuttleReset());
      dispatch(getShuttle(shuttleReq));
    }
  }, []);
  useEffect(() => {
    if (!shuttleResponse) {
      return;
    }
    if (
      shuttleResponse.message &&
      shuttleResponse.message.messageType === "SUCCESS" &&
      shuttleResponse.shuttle
    ) {
      setShuttleData(new ShuttleViewModel(shuttleResponse.shuttle));
    } else {
      showNotification(convertMsgResponse(shuttleResponse.message));
    }
    dispatch(getShuttleReset());
  }, [shuttleResponse]);
  const renderShuttleForm = (type) => {
    const shuttleBlock = getShuttleUICtrl(shuttleData, dependantOrgList).filter(
      (info) => info.type === type
    );
    if (
      shuttleBlock[0] &&
      shuttleBlock[0].fields &&
      shuttleBlock[0].fields.length > 0
    ) {
      return shuttleBlock[0].fields.map((field, i) => {
        return (
          <div
            className={`form-group row`}
            key={field.editConfig.name + i}
            style={field.style ? field.style : {}}
          >
            <label
              htmlFor={field.editConfig.name}
              className="col-sm-5 col-form-label font-weight-bold"
            >
              {field.titleLabel.text}
            </label>
            <div className={`col-sm-7 row`}>
              <label className="">{field.detailLabel.value}</label>
            </div>
          </div>
        );
      });
    }
  };
  return (
    <Draggable handle=".cursor">
      <Modal
        isOpen={props.showModal}
        className="no-cursor"
        style={{ minWidth: "150rem" }}
        toggle={() => {
          props.closeModal();
        }}
      >
        <ModalHeader
          className="modal-header bg-primary modal-title text-white cursor"
          toggle={() => {
            props.closeModal();
          }}
        >
          <i className="fas fa-bus pr-2"></i>
          Shuttle Quick View
        </ModalHeader>
        <ModalBody
          className="tableScroll"
          style={{
            minHeight: "470px",
          }}
        >
          <div className="row col-lg-12 p-0 m-o">
            <div className="col-lg-4 pl-0 ml-o">
              <div className="card">
                <div className="card-body" style={{ height: "40rem" }}>
                  <h4 className="form-header text-uppercase">
                    Shuttle Position :
                  </h4>
                  {shuttleData && shuttleData.shuttleCode ? (
                    <MarkerMap
                      mapData={getShuttleMapInfo([{ ...shuttleData }])}
                      defaultPosition={[
                        shuttleData.latitude || 34.052235,
                        shuttleData.longitude || -118.243683,
                      ]}
                      zoom={12}
                      style={{ height: "33rem" }}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-8 pr-0 mr-o">
              <div className="card">
                <div className="card-body" style={{ height: "40rem" }}>
                  <h4 className="form-header text-uppercase">Shuttle Info :</h4>
                  <div id="shuttle-page" className="row">
                    <div
                      className="col-lg-6 p-0"
                      style={{ borderRight: "1px solid gray" }}
                    >
                      <div className="form-group row">
                        <label
                          htmlFor="locationCode"
                          className="col-sm-5 col-form-label font-weight-bold"
                        >
                          Last Engine Status
                        </label>
                        <div className="col-sm-7 row">
                          <label>{shuttleData.lastEngineStatusDisplayValue}</label>
                        </div>
                      </div>
                      {renderShuttleForm("SHUTTLE_LEFTBAR")}
                    </div>
                    <div className="col-lg-6" style={{ height: "33rem" }}>
                      {renderShuttleForm("SHUTTLE_RIGHTBAR")}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {loading ? <Spinner /> : ""}
        </ModalBody>
      </Modal>
    </Draggable>
  );
};
export default ShuttleQuickModal;

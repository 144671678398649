import React, { useEffect } from "react";
import DefaultComponentWithMessage from "../../components/common/pages/default-component-with-message";
import PageTopPanel from "../../components/common/pages/page-top-panel";
import {
  getActualPageName,
  getPageName,
  setActiveMenu,
} from "../search/search-utility/search-utility";
import { COMMON_MSG } from "../../shared/GlobalVar";
import { clearActiveMenu } from "../../shared/utility";
const BirdsEyeView = (props) => {
  const pageName = getPageName(props.match.url);

  useEffect(() => {
    clearActiveMenu();
    setActiveMenu(props.match.url);
  }, [props.match.url]);

  return (
    <div className="mt-2">
      <PageTopPanel title={getActualPageName(pageName)} pageName={pageName} />
      <DefaultComponentWithMessage
        msg={
          getActualPageName(pageName) +
          " " +
          COMMON_MSG.UnderConstructionMessage
        }
      />
    </div>
  );
};
export default BirdsEyeView;

import React, { useState } from 'react';
import CustomAccordion from '../../../../components/UI/accordion/custom-accordion';
import InputCtrl from '../../../../components/UI/Input/input-ctrl';
import { CHAMPION } from '../../../../shared/AppImages';

function RewardInformation(props) {
    const { onChange, onBlur, value, disabled } = props;
    const [isActive, setIsActive] = useState(false);
    return (
        <div className='flex-column row-gap-1'>
            <CustomAccordion title="Rewards" isActive={isActive} setIsActive={setIsActive} />
            {isActive ? <InputCtrl
                name="renterId"
                value={value}
                type="text"
                placeholder="ID/Rewards#"
                onChange={onChange}
                onBlur={onBlur}
                disabled={disabled}
                imgIco={CHAMPION}
            /> : ""}
        </div>
    )
}

export default RewardInformation
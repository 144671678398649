import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addActivity, addActivityReset } from "../../../store/actions/index";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Draggable from "react-draggable";
import { addActivityEnum } from "../search-activity-input";
import  {
  updateObject,
  getBasicToastResponse,
  getErrorToastData,
} from "../../../shared/utility";
import AddActivityRequest from "../../../services/entities/requests/AddActivityRequest";
import { showNotification } from "../../../components/UI/Toaster/Toaster";
import { validateAddNewActivity } from "../../Member/MemberHelper";
import { LabelAndValuePairControl } from "../../../components/UI/Input/label-value-pair-control";

export const AddActivityModal = (props) => {
  const dispatch = useDispatch();
  const addActivityResponse = useSelector(
    (state) => state.activityReducer.addActivityResponse
  );
  const [addActivityRequest, setAddActivityRequest] = useState(
    new AddActivityRequest(props.memberId)
  );
  const [isValidateDone, setIsValidateDone] = useState(false);

  useEffect(() => {
    if (!addActivityResponse) {
      return;
    }
    showNotification(getBasicToastResponse(addActivityResponse));
    if (addActivityResponse.StatusCode === 0) {
      dispatch(addActivityReset());
      props.resetParentPage();
      props.closeModal();
    } else {
      dispatch(addActivityReset());
    }
  }, [addActivityResponse]);
  const handleOnChange = (event, controlName, elementType) => {
    let updateCtrls = { ...addActivityRequest };
    const value = event.target.value;
    updateCtrls = updateObject(updateCtrls, {
      [controlName]: value,
    });
    setAddActivityRequest(updateCtrls);
  };
  const onAddActivity = () => {
    let msg = "";
    if (!msg) {
      msg = validateAddNewActivity(addActivityRequest);
    }
    if (msg) {
      showNotification(getErrorToastData(msg));
      setIsValidateDone(true);
    } else {
      setIsValidateDone(false);
      dispatch(addActivityReset());
      dispatch(addActivity(addActivityRequest));
    }
  };
  const renderLabelInputPair = (
    labelCaption,
    controlName,
    dropdownItem = null,
    isRequired = true,
    elementType = "select"
  ) => {
    let value = addActivityRequest[controlName];
    return (
      <LabelAndValuePairControl
        elementConfig={{
          name: controlName,
          className: elementType === "select" ? "pl-0" : "",
          value: value,
        }}
        otherConfig={{
          caption: labelCaption,
          isRequired: isRequired,
          dropdownItem: elementType === "select" ? dropdownItem : [],
        }}
        isEditMode={true}
        onChange={handleOnChange}
        parentRowClass={`form-group row  ${isRequired ? "required" : ""}`}
        labelClasses={"col-sm-4 col-form-label font-weight-bold"}
        valueClasses={"col-sm-8"}
        nodeElement={elementType}
        isValidateDone={isValidateDone}
      />
    );
  };
  return (
    <Draggable handle=".cursor">
      <Modal
        isOpen={props.showModal}
        className="no-cursor"
        toggle={() => {
          props.closeModal();
        }}
      >
        <ModalHeader
          className="modal-header bg-primary modal-title text-white cursor"
          toggle={() => {
            props.closeModal();
          }}
        >
          <i className="fas fa-tasks"></i>Add Activity
        </ModalHeader>
        <ModalBody>
          <div className="col-sm-12 p-0">
            {renderLabelInputPair(
              "Call Channel",
              "ChannelType",
              addActivityEnum.channelTypeList
            )}
            {renderLabelInputPair(
              "Call Category",
              "CallCategory",
              addActivityEnum.callCategories
            )}
            {renderLabelInputPair(
              "Category",
              "Category",
              addActivityEnum.categories
            )}
            {renderLabelInputPair(
              "Priority",
              "Priority",
              addActivityEnum.priorities
            )}
            {renderLabelInputPair(
              "Comments",
              "Comments",
              null,
              true,
              "textarea"
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="col-sm-12 text-right">
            <button
              type="button"
              name="btnYes"
              className="btn btn-primary waves-effect waves-light btnSize ml-2"
              onClick={() => onAddActivity()}
            >
              Save
            </button>
            <button
              type="button"
              name="btnCancel"
              className="btn btn-secondary waves-effect waves-light btnSize ml-2"
              onClick={() => props.closeModal()}
            >
              Cancel
            </button>
          </div>
        </ModalFooter>
      </Modal>
    </Draggable>
  );
};
export default AddActivityModal;

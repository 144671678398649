
import { removeLocalStorageItems } from "../LocalStorage";
export const setLocalStorgeForLoggedInUser = (loggedInUserData) => {
    localStorage.setItem('loggedInUserId', loggedInUserData.UserId)
    localStorage.setItem('preferredOrgUnitId', loggedInUserData.User.OrgUnitId)
    if (loggedInUserData.HintQuestion) {
        localStorage.setItem(
            'hintQuestion',
            JSON.stringify(loggedInUserData.HintQuestion)
        )
    }
    if (loggedInUserData.HintAnswer) {
        localStorage.setItem(
            'hintAnswer',
            JSON.stringify(loggedInUserData.HintAnswer)
        )
    }
    localStorage.setItem('userData', JSON.stringify(loggedInUserData.User))
    localStorage.setItem(
        'orgUnits',
        JSON.stringify(loggedInUserData.OrgUnits || null)
    )
    localStorage.setItem(
        'lastIpAddress',
        JSON.stringify(loggedInUserData.LastIpAddress)
    )
    localStorage.setItem(
        'lastLogin',
        JSON.stringify(loggedInUserData.LastLogin)
    )
    localStorage.setItem(
        'totalLoginCount',
        JSON.stringify(loggedInUserData.TotalLoginCount)
    )
}
export const setLocalStorgeForOrgUnits = (loggedInUserData) => {
    localStorage.setItem('currentOrgUnitId', loggedInUserData.CurrentOrgUnitId)
    localStorage.setItem(
        'currentOrgUnitKey',
        JSON.stringify(loggedInUserData.CurrentOrgUnitKey)
    )
    localStorage.setItem(
        'currentOrgUnitDisplayName',
        JSON.stringify(loggedInUserData.CurrentOrgUnitDisplayName)
    )
    localStorage.setItem(
        'orgUnitCountry',
        JSON.stringify(loggedInUserData.OrgUnitCountry)
    )
    localStorage.setItem(
        'dependantOrgList',
        JSON.stringify(loggedInUserData.DependantOrgList || [])
    )
    localStorage.setItem(
        'selectedOrgKeyValue',
        JSON.stringify(loggedInUserData.SelectedOrgKeyValue || [])
    )
    localStorage.setItem(
        'userPermissions',
        JSON.stringify(loggedInUserData.UserPermissions)
    )
    localStorage.setItem(
        'vehicleCategories',
        JSON.stringify(loggedInUserData.VehicleCategories)
    )
    localStorage.setItem(
        'orgUnitSettings',
        JSON.stringify(loggedInUserData.OrgUnitSettings)
    )
    localStorage.setItem('roles', JSON.stringify(loggedInUserData.Roles))
    if (localStorage.getItem("loginMode") !== "IMPERSONATE"){
        localStorage.setItem('parent_login_response', JSON.stringify(loggedInUserData))
    }  
}
export const doImpersonateSetup = (
    impersonateResponse,
    parentLoginResponse
) => {
    localStorage.setItem('loginMode', 'IMPERSONATE')
    localStorage.setItem(
        'parent_login_response',
        JSON.stringify(parentLoginResponse)
    )
    localStorage.setItem('parent_token', localStorage.getItem('token'))
    localStorage.setItem(
        'parent_currentOrgUnitId',
        localStorage.getItem('currentOrgUnitId')
    )
    localStorage.setItem(
        'parent_loggedInUserId',
        localStorage.getItem('loggedInUserId')
    )
    localStorage.setItem(
        'parent_preferredOrgUnitId',
        localStorage.getItem('preferredOrgUnitId')
    )
    localStorage.setItem(
        'parent_hintQuestion',
        localStorage.getItem('hintQuestion')
    )
    localStorage.setItem(
        'parent_hintAnswer',
        localStorage.getItem('hintAnswer')
    )
    localStorage.setItem('parent_userData', localStorage.getItem('userData'))
    localStorage.setItem('parent_orgUnits', localStorage.getItem('orgUnits'))

    localStorage.setItem('token', impersonateResponse.TokenResponseInfo.TokenId)
    localStorage.setItem(
        'loggedInUserId',
        impersonateResponse.LoginInfo.User.MemberId
    )
    localStorage.setItem(
        'impersonateUserId',
        impersonateResponse.LoginInfo.User.MemberId
    )
    localStorage.setItem(
        'preferredOrgUnitId',
        impersonateResponse.LoginInfo.PreferredOrgUnit.Key
    )
    localStorage.setItem(
        'hintQuestion',
        JSON.stringify(impersonateResponse.LoginInfo.HintQuestion)
    )
    localStorage.setItem(
        'hintAnswer',
        JSON.stringify(impersonateResponse.LoginInfo.HintAnswer)
    )
    localStorage.setItem(
        'userData',
        JSON.stringify(impersonateResponse.LoginInfo.User)
    )
    localStorage.setItem(
        'orgUnits',
        JSON.stringify(impersonateResponse.LoginInfo.OrgUnits || null)
    )
}
export const doImpersonateExitSetup = () => {
    localStorage.setItem('loginMode', '')
    removeLocalStorageItems('IMPERSONATE', true)
    localStorage.setItem(
        'currentOrgUnitId',
        localStorage.getItem('parent_currentOrgUnitId')
    )
    localStorage.setItem('token', localStorage.getItem('parent_token'))
    localStorage.setItem(
        'loggedInUserId',
        localStorage.getItem('parent_loggedInUserId')
    )
    localStorage.setItem(
        'preferredOrgUnitId',
        localStorage.getItem('parent_preferredOrgUnitId')
    )
    localStorage.setItem(
        'hintQuestion',
        localStorage.getItem('parent_hintQuestion')
    )
    localStorage.setItem(
        'hintAnswer',
        localStorage.getItem('parent_hintAnswer')
    )
    localStorage.setItem('userData', localStorage.getItem('parent_userData'))
    localStorage.setItem('orgUnits', localStorage.getItem('parent_orgUnits'))

    localStorage.setItem('parent_login_response', '')
    localStorage.setItem('parent_token', '')
    localStorage.setItem('parent_loggedInUserId', '')
    localStorage.setItem('parent_preferredOrgUnitId', '')
    localStorage.setItem('parent_hintQuestion', '')
    localStorage.setItem('parent_hintAnswer', '')
    localStorage.setItem('parent_userData', '')
    localStorage.setItem('parent_orgUnits', '')
    localStorage.setItem('parent_currentOrgUnitId', '')
}
export const getDropdownItems = (
    dropdownData,
    valueKey,
    displayTextKey,
    displayTextForFirstItem,
    idForFirstItem
  ) => {
    let dataList = [];
    let dataObj = {};
  
    if (displayTextForFirstItem) {
      dataList.push({
        key: idForFirstItem || "",
        displayValue: displayTextForFirstItem,
      });
    }
  
    if (!dropdownData) {
      return dataList;
    }
  
    dropdownData.forEach((element) => {
      if (element) {
        dataObj = {};
        dataObj.key = element[valueKey] || element["key"];
        dataObj.displayValue = element[displayTextKey] || element["displayValue"];
  
        if (valueKey === "RoleId") {
          dataObj["isInherited"] = element["Inherited"];
          dataList.push(dataObj);
        } else {
          Object.keys(element).forEach((extraItem) => {
            if (extraItem !== valueKey && extraItem !== displayTextKey) {
              dataObj[extraItem] = element[extraItem];
            }
          });
          dataList.push(dataObj);
        }
      }
    });
    return dataList;
  };
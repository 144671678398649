import React from "react";
import { Route, Switch } from "react-router-dom";
const SidebarContainer = (props) => {
  const getNavigationHtml = () => {
    return props.subMenu.subMenuItems.map((menuItem, index) => {
      //const componentName = menuItem.componentName;
      return (
        <Route
          exact
          path={"/" + props.subMenu.menuKey + "/" + menuItem.itemKey}
          render={(props1) => (
            <menuItem.componentName
              {...props1}
              updateData={props.updateData}
              otherData={props.otherData}
            />
          )}
          key={index}
        />
      );
    });
  };
  return <Switch>{getNavigationHtml()}</Switch>;
};
export default SidebarContainer;

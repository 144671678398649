export const MemberActivitySearchRequest = function() {
  this.CallCategory = null;
  this.Category = null;
  this.ChannelType = null;
  this.Keyword = null;
  this.MemberId = null;
  this.OrgUnitId = null;
  this.Priority = null;
  this.ProblemType = null;
  this.ReservationCodes = null;
  this.SearchEndDate = null;
  this.SearchStartDate = null;
  this.SubProblemType = null;
  this.SelectedHeaders = null;
  this.PageCount = 0;
};

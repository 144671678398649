import "../dialog/dialog.scss";
import CommonDialog from "./common-dialog";
import DraggableDialog from "./draggable-dialog";

function DialogWrapper(props) {
    return (
        props.isDraggable ? <DraggableDialog {...props} />
            : <CommonDialog {...props} />
    )
}

export default DialogWrapper
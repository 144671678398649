export const getVoucherInfoCtrl = (viewData) => {
    viewData = viewData ? viewData : {};
    return JSON.parse(JSON.stringify([{
        info_block: 0,
        info_heading: "",
        fields: [
            {
                titleLabel: {
                    text: "Voucher Type"
                },
                detailLabel: {
                    text: viewData.MeanOfPaymentPotentially,
                },
            },
            {
                titleLabel: {
                    text: "Customer Reference"
                },
                detailLabel: {
                    text: viewData.GenericCustomerReference,
                },
            }
        ],
    }, {
        info_block: 0,
        info_heading: "Primary Voucher",
        fields: [
            {
                titleLabel: {
                    text: "External Voucher ID"
                },
                detailLabel: {
                    text: viewData.ExternalVoucherId,
                },
            },
            {
                titleLabel: {
                    text: "Partner Voucher ID Code"
                },
                detailLabel: {
                    text: viewData.PartnerVoucherIdCode,
                },
            },
            {
                titleLabel: {
                    text: "Vehicle Category"
                },
                detailLabel: {
                    text: viewData.VehicleCategoryVoucher,
                },
            },
            {
                titleLabel: {
                    text: "Days"
                },
                detailLabel: {
                    text: viewData.VoucherDays,
                },
            },
            {
                titleLabel: {
                    text: "Amount"
                },
                detailLabel: {
                    text: viewData.VoucherAmount,
                },
            },
            {
                titleLabel: {
                    text: "Full Credit Indicator"
                },
                detailLabel: {
                    text: viewData.VoucherFullCreditIndicator,
                },
            },
            {
                titleLabel: {
                    text: "Issue Type"
                },
                detailLabel: {
                    text: viewData.VoucherIssueType,
                },
            },
            {
                titleLabel: {
                    text: "Value"
                },
                detailLabel: {
                    text: viewData.VoucherValue,
                },
            },
            {
                titleLabel: {
                    text: "Currency Code"
                },
                detailLabel: {
                    text: viewData.CurrencyCode,
                },
            }
        ],
    }, {
        info_block: 0,
        info_heading: "Business Account",
        fields: [
            {
                titleLabel: {
                    text: "Account Id"
                },
                detailLabel: {
                    text: viewData.BusinessAccountId,
                },
            },
            {
                titleLabel: {
                    text: "Account Name"
                },
                detailLabel: {
                    text: viewData.BusinessAccountName,
                },
            },
            {
                titleLabel: {
                    text: "Currency Code"
                },
                detailLabel: {
                    text: viewData.BusinessAccountCurrencyCode,
                },
            }
        ]
    },
    {
        info_block: 1,
        info_heading: "In the Books Of Org",
        fields: [
            {
                titleLabel: {
                    text: "Org ID"
                },
                detailLabel: {
                    text: viewData.OrgIdBooksOf,
                },
            },
            {
                titleLabel: {
                    text: "Legal Name"
                },
                detailLabel: {
                    text: viewData.LegalName,
                },
            },
            {
                titleLabel: {
                    text: "Address"
                },
                detailLabel: {
                    text: viewData.AddressLine1,
                },
            },
            {
                titleLabel: {
                    text: "City"
                },
                detailLabel: {
                    text: viewData.City,
                },
            },
            {
                titleLabel: {
                    text: "Country Code"
                },
                detailLabel: {
                    text: viewData.CountryCode,
                },
            },
            {
                titleLabel: {
                    text: "PostalCode"
                },
                detailLabel: {
                    text: viewData.PostalCode,
                },
            },
        ],
    }, {
        info_block: 1,
        info_heading: "Held By Org",
        fields: [
            {
                titleLabel: {
                    text: "Org ID"
                },
                detailLabel: {
                    text: viewData.HeldByOrgId,
                },
            },
            {
                titleLabel: {
                    text: "Legal Name"
                },
                detailLabel: {
                    text: viewData.HeldByOrgLegalName,
                },
            },
            {
                titleLabel: {
                    text: "Address"
                },
                detailLabel: {
                    text: viewData.HeldByOrgAddressLine1,
                },
            },
            {
                titleLabel: {
                    text: "City"
                },
                detailLabel: {
                    text: viewData.HeldByOrgCity,
                },
            },
            {
                titleLabel: {
                    text: "Country Code"
                },
                detailLabel: {
                    text: viewData.HeldByOrgCountryCode,
                },
            },
            {
                titleLabel: {
                    text: "PostalCode"
                },
                detailLabel: {
                    text: viewData.HeldByOrgPostalCode,
                },
            }, {
                titleLabel: {
                    text: "Sale Tax Number"
                },
                detailLabel: {
                    text: viewData.SaleTaxNumber,
                },
            },
        ],
    },]));
}
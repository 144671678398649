import axios from "axios";
import * as actionTypes from "./actionTypes";
import HttpService from "../../services/httpService";

export const getEndRentalChargesStart = () => {
  return {
    endRentalData: null,
    type: actionTypes.GET_END_RENTAL_CHARGES_START
  };
};
export const getEndRentalChargesSuccess = response => {
  return {
    type: actionTypes.GET_END_RENTAL_CHARGES_SUCCESS,
    endRentalPricingData: response
  };
};
export const getEndRentalChargesFail = error => {
  return {
    type: actionTypes.GET_END_RENTAL_CHARGES_FAIL,
    error: error
  };
};
export const getEndRentalChargesReset = () => {
  return {
    type: actionTypes.GET_END_RENTAL_CHARGES_RESET
  };
};
export const editEndRentalStart = response => {
  return {
    type: actionTypes.END_RENTAL_UPDATE_START,
    editEndRentalResponse: null
  };
};
export const editEndRentalSuccess = response => {
  return {
    type: actionTypes.END_RENTAL_UPDATE_SUCCESS,
    editEndRentalResponse: response
  };
};
export const editEndRentalFail = error => {
  return {
    type: actionTypes.END_RENTAL_UPDATE_FAIL,
    error: error
  };
};
export const editEndRentalReset = () => {
  return {
    type: actionTypes.END_RENTAL_UPDATE_RESET
  };
};

export const endRentalStart = response => {
  return {
    type: actionTypes.END_RENTAL_START,
    endRentalResponse: null
  };
};
export const endRentalSuccess = response => {
  return {
    type: actionTypes.END_RENTAL_SUCCESS,
    endRentalResponse: response
  };
};
export const endRentalFail = error => {
  return {
    type: actionTypes.END_RENTAL_FAIL,
    error: error
  };
};
export const endRentalReset = () => {
  return {
    type: actionTypes.END_RENTAL_RESET
  };
};
export const calculateEndRentalPricing = getEndRentalChargReq => {
  return async dispatch => {
    dispatch(getEndRentalChargesStart());
    HttpService.calculateEndRentalPricing(getEndRentalChargReq)
      .then(response => {
        dispatch(getEndRentalChargesSuccess(response));
      })
      .catch(err => {
        //dispatch(getEndRentalChargesFail(err));
      });
  };
};
export const endRental = endRentalReq => {
  return async dispatch => {
    dispatch(endRentalStart());
    HttpService.endRental(endRentalReq)
      .then(response => {
        dispatch(endRentalSuccess(response));
      })
      .catch(err => {
        //dispatch(endRentalFail(err));
      });
  };
};
export const editEndRental = editEndRentalReq => {
  return async dispatch => {
    dispatch(editEndRentalStart());
    HttpService.editEndRental(editEndRentalReq)
      .then(response => {
        dispatch(editEndRentalSuccess(response));
      })
      .catch(err => {
        //dispatch(editEndRentalFail(err));
      });
  };
};

import React, { Fragment, Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Slider from "react-rangeslider";
import * as actions from "../../../store/actions/index";
import Spinner from "../../../components/UI/Spinner/Spinner";
import {
  getBasicToastResponse,
  getDateTimeString,
} from "../../../shared/utility";
import { showNotification } from "../../../components/UI/Toaster/Toaster";
import { createPricingObj } from "../../../components/pricing/PricingData";
import PricingTable from "../../../components/pricing/PricingTable";
class ReservationDetails extends Component {
  state = {
    reservationOverviewResponse: null,
  };
  componentDidMount() {
    this.getReservationDetails();
  }
  componentDidUpdate(prevProps) {
    const query = new URLSearchParams(this.props.location.search);
    const reservationId = query.get("reservationId");
    if (this.props.match.url !== prevProps.match.url) {
      //this.getReservationDetails();
    }
  }
  UNSAFE_componentWillReceiveProps = ({ reservationOverviewResponse }) => {
    if (
      (reservationOverviewResponse &&
        !this.state.reservationOverviewResponse) ||
      (this.state.reservationOverviewResponse &&
        reservationOverviewResponse &&
        !this.props.reservationOverviewResponse)
    ) {
      if (reservationOverviewResponse.StatusCode === 0) {
        this.initiateReservationDetailProcess(reservationOverviewResponse);
      } else {
        showNotification(getBasicToastResponse(reservationOverviewResponse));
      }
    }
  };
  getInfoFromParamQuery = (paramName) => {
    const query = new URLSearchParams(this.props.location.search);
    return query.get(paramName);
  };
  getReservationDetails = () => {
    this.props.onGetReservationOverviewReset();
    this.props.onGetReservationOverview({
      ReservationId: this.getInfoFromParamQuery("reservationId"),
    });
  };
  initiateReservationDetailProcess = (response) => {
    this.setState({
      reservationOverviewResponse: response,
      pricingData: createPricingObj(
        response.Reservation.ReservationPricingInfo || {},
        "SUMMARY"
      ),
    });
  };
  render() {
    const summaryData = this.state.reservationOverviewResponse
      ? this.state.reservationOverviewResponse.Reservation
      : {};
    const pricingData = {};
    return (
      <div className="card">
        <div className="card-body">
          <div className="formRow">
            <div className="col-lg-5">
              <div className="form-group row">
                <label className="col-sm-5 col-form-label font-weight-bold">
                  Miles Travelled
                </label>
                <label className="col-sm-7 col-form-label">
                  {(summaryData.TotalMiles || "N/A") +
                    (summaryData.MilesDrivenUnit || "")}
                </label>
              </div>
              <div className="form-group row">
                <label className="col-sm-5 col-form-label font-weight-bold">
                  Fuel Utilized
                </label>
                <label className="col-sm-7 col-form-label">
                  {(summaryData.FuelConsumption || "0") +
                    " " +
                    summaryData.FuelUnit}
                </label>
              </div>
              <div className="form-group row mb-4">
                <label className="col-sm-5 col-form-label font-weight-bold">
                  Fuel Out
                </label>
                <div className="col-sm-6" style={{ position: "relative" }}>
                  <div className="slider">
                    <Slider
                      min={0}
                      max={100}
                      value={summaryData.FuelOut || 0}
                    />
                    <div className="value" />
                  </div>
                </div>
                <label className="col-sm-1 col-form-label">
                  {summaryData.FuelOut || 0}
                </label>
              </div>
              <div className="form-group row mb-4">
                <label className="col-sm-5 col-form-label font-weight-bold">
                  Fuel In
                </label>
                <div className="col-sm-6" style={{ position: "relative" }}>
                  <div className="slider">
                    <Slider min={0} max={100} value={summaryData.FuelIn || 0} />
                    <div className="value" />
                  </div>
                </div>
                <label className="col-sm-1 col-form-label">
                  {summaryData.FuelIn || 0}
                </label>
              </div>
              <div className="form-group row mb-4">
                <label className="col-sm-5 col-form-label font-weight-bold">
                  Customer Reported Fuel In
                </label>
                <div className="col-sm-6" style={{ position: "relative" }}>
                  <div className="slider">
                    <Slider
                      min={0}
                      max={100}
                      value={summaryData.CustomerReportedFuelIn || 0}
                    />
                    <div className="value" />
                  </div>
                </div>
                <label className="col-sm-1 col-form-label">
                  {summaryData.CustomerReportedFuelIn || 0}
                </label>
              </div>
              {summaryData.ReservationState === "CANCELLED" ? (
                <Fragment>
                  <div className="form-group row">
                    <label className="col-sm-5  col-form-label font-weight-bold">
                      Cancelled On
                    </label>
                    <label className="col-sm-7 col-form-label">
                      {summaryData.CancelledOn
                        ? getDateTimeString(summaryData.CancelledOn)
                        : "--"}
                    </label>
                  </div>
                  <div className="form-group row">
                    <label className="col-sm-5  col-form-label font-weight-bold">
                      Cancelled By
                    </label>
                    <label className="col-sm-7 col-form-label">
                      {summaryData.CancelledByPersonName || "N/A"}
                    </label>
                  </div>
                  <div className="form-group row">
                    <label className="col-sm-5  col-form-label font-weight-bold">
                      Cancellation Comment
                    </label>
                    <label className="col-sm-7 col-form-label">
                      {summaryData.CancellationComment || "N/A"}
                    </label>
                  </div>
                </Fragment>
              ) : (
                ""
              )}
              <div className="form-group row">
                <label className="col-sm-5  col-form-label font-weight-bold">
                  Owner Notes
                </label>
                <label className="col-sm-7 col-form-label">
                  {summaryData.OwnerNotes || "N/A"}
                </label>
              </div>
              <div className="form-group row">
                <label className="col-sm-5  col-form-label font-weight-bold">
                  Renter Notes
                </label>
                <label className="col-sm-7 col-form-label">
                  {summaryData.BorrowerNotes || "N/A"}
                </label>
              </div>
              <div className="form-group row">
                <label className="col-sm-5  col-form-label font-weight-bold">
                  Internal Notes
                </label>
                <label className="col-sm-7 col-form-label">
                  {summaryData.CCANotes || "N/A"}
                </label>
              </div>
              <div className="form-group row">
                <label className="col-sm-5  col-form-label font-weight-bold">
                  Created by
                </label>
                <label className="col-sm-7 col-form-label">
                  {summaryData.CreatedBy || "N/A"}
                </label>
              </div>
              <div className="form-group row">
                <label className="col-sm-5  col-form-label font-weight-bold">
                  Created Method
                </label>
                <label className="col-sm-7 col-form-label">
                  {summaryData.CreatedMethod || "N/A"}
                </label>
              </div>
              <div className="form-group row">
                <label className="col-sm-5  col-form-label font-weight-bold">
                  Created on
                </label>
                <label className="col-sm-7 col-form-label">
                  {summaryData.CreatedOn
                    ? getDateTimeString(summaryData.CreatedOn)
                    : "N/A"}
                </label>
              </div>
              <div className="form-group row">
                <label className="col-sm-5  col-form-label font-weight-bold">
                  Modified by
                </label>
                <label className="col-sm-7 col-form-label">
                  {summaryData.ModifiedBy || "N/A"}
                </label>
              </div>
              <div className="form-group row">
                <label className="col-sm-5  col-form-label font-weight-bold">
                  Modified Method
                </label>
                <label className="col-sm-7 col-form-label">
                  {summaryData.ModifiedMethod || "N/A"}
                </label>
              </div>
              <div className="form-group row">
                <label className="col-sm-5  col-form-label font-weight-bold">
                  Modified on
                </label>
                <label className="col-sm-7 col-form-label">
                  {summaryData.ModifiedOn
                    ? getDateTimeString(summaryData.ModifiedOn)
                    : "N/A"}
                </label>
              </div>
            </div>
            <div className="col-lg-2 text-center">
              <div className="vertical-line"></div>
            </div>
            <div className="col-lg-5">
              {this.state.pricingData ? (
                <PricingTable pricingData={this.state.pricingData || {}} />
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        {this.props.loading ? <Spinner /> : ""}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    loading: state.reservationReducer.loading,
    reservationOverviewResponse:
      state.reservationReducer.reservationOverviewResponse,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onGetEnumvalues: () => dispatch(actions.getEnumvalues()),
    onGetReservationOverview: (req) =>
      dispatch(actions.getReservationOverview(req)),
    onGetReservationOverviewReset: () =>
      dispatch(actions.getReservationOverviewReset()),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ReservationDetails));

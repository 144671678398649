const FeedbackSearchModel = function (data) {
  if (!data) data = {};
  this.Accurate = data.Accurate || null;
  this.AssetId = data.AssetId || null;
  this.AssetName = data.AssetName || null;
  this.AssetType = data.AssetType || null;
  this.AssetTypeName = data.AssetTypeName || null;
  this.Category = data.Category || null;
  this.CategoryDisplayText = data.CategoryDisplayText || null;
  this.Condition = data.Condition || null;
  this.Experience = data.Experience || null;
  this.FeedbackDate = data.FeedbackDate || null;

  this.FeedbackType = data.FeedbackType || null;
  this.Friendliness = data.Friendliness || null;
  this.Parking = data.Parking || null;
  this.PersonForId = data.PersonForId || null;
  this.PersonForMembershipId = data.PersonForMembershipId || null;
  this.PersonForName = data.PersonForName || null;
  this.PersonForProfileImage = data.PersonForProfileImage || null;
  this.PersonId = data.PersonId || null;
  this.PersonMembershipId = data.PersonMembershipId || null;
  this.PersonName = data.PersonName || null;

  this.PersonProfileImage = data.PersonProfileImage || null;
  this.Punctuality = data.Punctuality || null;
  this.ReservationCode = data.ReservationCode || null;
  this.ReservationId = data.ReservationId || null;
};
export default FeedbackSearchModel;

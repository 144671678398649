import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Draggable from "react-draggable";
import { RolesModel } from "../../../../services/entities/view-models/settings-view-models";
import { updateObject, getErrorToastData } from "../../../../shared/utility";
import { showNotification } from "../../../../components/UI/Toaster/Toaster";
import { validateRolesSettings } from "../../settingsHelper";
import { ModalType } from "../../../../shared/GlobalVar";
import { LabelAndValuePairControl } from "../../../../components/UI/Input/label-value-pair-control";

export const RoleModal = (props) => {
  const entity = props.selectedEntity || new RolesModel();
  const [roleEntity, setRolesEntity] = useState(entity);
  const [isValidateDone, setIsValidateDone] = useState(false);

  const handleOnChange = (event, controlName, elementType) => {
    let value =
      elementType === "checkbox" ? event.target.checked : event.target.value;
    const updateCtrls = updateObject(roleEntity, {
      [controlName]: value,
    });
    setRolesEntity(updateCtrls);
  };

  const updateRolesItems = () => {
    let newReq = JSON.parse(JSON.stringify(roleEntity));
    let msg = validateRolesSettings(newReq);
    if (msg) {
      showNotification(getErrorToastData(msg));
      setIsValidateDone(true);
    } else {
      setIsValidateDone(false);
      props.addUpdateRoleEntity(newReq, props.modalType);
    }
  };

  const renderLabelInputPair = (
    labelCaption,
    controlName,
    value,
    isRequired = false,
    nodeElement = "input",
    inputType = "text"
  ) => {
    const valueAttribute = inputType === "checkbox" ? "checked" : "value";
    return (
      <LabelAndValuePairControl
        elementConfig={{
          name: controlName,
          type: inputType,
          [valueAttribute]: value,
        }}
        otherConfig={{
          caption: labelCaption,
          isRequired: isRequired,
        }}
        isEditMode={true}
        onChange={handleOnChange}
        parentRowClass={`form-group row  ${isRequired ? "required" : ""}`}
        labelClasses={"col-sm-4 col-form-label font-weight-bold"}
        valueClasses={"col-sm-8"}
        nodeElement={nodeElement}
        isValidateDone={isValidateDone}
      />
    );
  };

  return (
    <Draggable handle=".cursor">
      <Modal
        isOpen={props.showModal}
        className="no-cursor"
        toggle={() => {
          props.closeModal();
        }}
        style={{
          minWidth: "60rem",
        }}
      >
        <ModalHeader
          className="modal-header bg-primary modal-title text-white cursor"
          toggle={() => {
            props.closeModal();
          }}
        >
          <i className="fas fa-cogs pr-2"></i>
          {props.modalType === ModalType.EDIT_ROLE_MODAL
            ? "Edit Role"
            : "Add/Copy Role"}
        </ModalHeader>
        <ModalBody>
          <div className="col-sm-12">
            {renderLabelInputPair("Role Name", "Name", roleEntity.Name, true)}
            {renderLabelInputPair(
              "Description",
              "Description",
              roleEntity.Description,
              true
            )}
            {renderLabelInputPair("Code", "Code", roleEntity.Code, true)}
            {renderLabelInputPair(
              "Visible To Super Admin Only",
              "VisibleToSuperAdminOnly",
              roleEntity.VisibleToSuperAdminOnly,
              false,
              "checkbox",
              "checkbox"
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="col-sm-12 text-right">
            <button
              type="button"
              name="btnYes"
              className="btn btn-primary waves-effect waves-light btnSize ml-2"
              onClick={() => updateRolesItems()}
            >
              {props.modalType === ModalType.COPY_ROLE_MODAL
                ? "Copy"
                : props.modalType === ModalType.EDIT_ROLE_MODAL
                ? "Update"
                : "Submit"}
            </button>
            <button
              type="button"
              name="btnCancel"
              className="btn btn-secondary waves-effect waves-light btnSize ml-2"
              onClick={() => props.closeModal()}
            >
              Cancel
            </button>
          </div>
        </ModalFooter>
      </Modal>
    </Draggable>
  );
};
export default RoleModal;

import * as actionTypes from "./actionTypes";
import HttpService from "../../services/httpService";

export const uploadDocStart = () => {
  return {
    type: actionTypes.UPLOAD_DOC_START
  };
};
export const uploadDocSuccess = response => {
  return {
    type: actionTypes.UPLOAD_DOC_SUCCESS,
    uploadFileResponse: response
  };
};
export const uploadDocFail = error => {
  return {
    type: actionTypes.UPLOAD_DOC_FAIL,
    error: error
  };
};
export const uploadDocReset = () => {
  return {
    type: actionTypes.UPLOAD_DOC_RESET
  };
};
export const uploadFile = uploadFileReq => {
  return dispatch => {
    dispatch(uploadDocStart());
    HttpService.uploadFile(uploadFileReq)
      .then(response => {
        dispatch(uploadDocSuccess(response));
      })
      .catch(err => {
        //dispatch(uploadDocFail(err));
      });
  };
};

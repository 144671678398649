import * as actionTypes from "./actionTypes";
import HttpService from "../../services/httpService";
export const doRolesPermissionMemberSearchStart = () => {
  return {
    type: actionTypes.SEARCH_ROLES_PERMISSIONS_START
  };
};

export const doRolesPermissionMemberSearchSuccess = response => {
  return {
    type: actionTypes.SEARCH_ROLES_PERMISSIONS_SUCCESS,
    searcRolesPermissionsMemberResponse: response
  };
};

export const doRolesPermissionMemberSearchFail = error => {
  return {
    type: actionTypes.SEARCH_ROLES_PERMISSIONS_FAIL,
    error: error
  };
};
export const doRolesPermissionMemberSearchReset = () => {
  return {
    type: actionTypes.SEARCH_ROLES_PERMISSIONS_RESET
  };
};
export const getOrgRolesDataStart = () => {
  return {
    type: actionTypes.GET_ORG_ROLES_START
  };
};

export const getOrgRolesDataSuccess = response => {
  return {
    type: actionTypes.GET_ORG_ROLES_SUCCESS,
    orgRolesDataResponse: response
  };
};

export const getOrgRolesDataFail = error => {
  return {
    type: actionTypes.GET_ORG_ROLES_FAIL,
    error: error
  };
};
export const getOrgRolesDataReset = () => {
  return {
    type: actionTypes.GET_ORG_ROLES_RESET
  };
};
export const doRolesPermissionMemberSearch = req => {
  return dispatch => {
    dispatch(doRolesPermissionMemberSearchStart());
    HttpService.doRolesPermissionMemberSearch(req)
      .then(response => {
        dispatch(doRolesPermissionMemberSearchSuccess(response));
      })
      .catch(err => {
        //dispatch(doRolesPermissionMemberSearchFail(err));
      });
  };
};
export const getOrgRolesData = req => {
  return dispatch => {
    dispatch(getOrgRolesDataStart());
    HttpService.getOrgRolesData(req)
      .then(response => {
        dispatch(getOrgRolesDataSuccess(response));
      })
      .catch(err => {
        //dispatch(getOrgRolesDataFail(err));
      });
  };
};

//Add Org Roles
export const addOrgRolesDataStart = () => {
  return {
    type: actionTypes.ADD_ORG_ROLES_START
  };
};

export const addOrgRolesDataSuccess = response => {
  return {
    type: actionTypes.ADD_ORG_ROLES_SUCCESS,
    addOrgRolesDataResponse: response
  };
};

export const addOrgRolesDataFail = error => {
  return {
    type: actionTypes.ADD_ORG_ROLES_FAIL,
    error: error
  };
};
export const addOrgRolesDataReset = () => {
  return {
    type: actionTypes.ADD_ORG_ROLES_RESET
  };
};

export const addOrgRolesData = req => {
  return dispatch => {
    dispatch(addOrgRolesDataStart());
    HttpService.addRole(req)
      .then(response => {
        dispatch(addOrgRolesDataSuccess(response));
      })
      .catch(err => {
        //dispatch(addOrgRolesDataFail(err));
      });
  };
};

//Edit Org Roles
export const editOrgRolesDataStart = () => {
  return {
    type: actionTypes.EDIT_ORG_ROLES_START
  };
};

export const editOrgRolesDataSuccess = response => {
  return {
    type: actionTypes.EDIT_ORG_ROLES_SUCCESS,
    editOrgRolesDataResponse: response
  };
};

export const editOrgRolesDataFail = error => {
  return {
    type: actionTypes.EDIT_ORG_ROLES_FAIL,
    error: error
  };
};
export const editOrgRolesDataReset = () => {
  return {
    type: actionTypes.EDIT_ORG_ROLES_RESET
  };
};

export const editOrgRolesData = req => {
  return dispatch => {
    dispatch(editOrgRolesDataStart());
    HttpService.editRole(req)
      .then(response => {
        dispatch(editOrgRolesDataSuccess(response));
      })
      .catch(err => {
        //dispatch(editOrgRolesDataFail(err));
      });
  };
};

//Delete Org Roles
export const deleteOrgRolesDataStart = () => {
  return {
    type: actionTypes.DELETE_ORG_ROLES_START
  };
};

export const deleteOrgRolesDataSuccess = response => {
  return {
    type: actionTypes.DELETE_ORG_ROLES_SUCCESS,
    deleteOrgRolesDataResponse: response
  };
};

export const deleteOrgRolesDataFail = error => {
  return {
    type: actionTypes.DELETE_ORG_ROLES_FAIL,
    error: error
  };
};
export const deleteOrgRolesDataReset = () => {
  return {
    type: actionTypes.DELETE_ORG_ROLES_RESET
  };
};

export const deleteOrgRolesData = req => {
  return dispatch => {
    dispatch(deleteOrgRolesDataStart());
    HttpService.deleteRole(req)
      .then(response => {
        dispatch(deleteOrgRolesDataSuccess(response));
      })
      .catch(err => {
        //dispatch(deleteOrgRolesDataFail(err));
      });
  };
};

//Get Org Permissions Actions
export const getOrgPermissionsDataStart = () => {
  return {
    type: actionTypes.GET_ORG_PERMISSIONS_START
  };
};

export const getOrgPermissionsDataSuccess = response => {
  return {
    type: actionTypes.GET_ORG_PERMISSIONS_SUCCESS,
    getOrgPermissionsDataResponse: response
  };
};

export const getOrgPermissionsDataFail = error => {
  return {
    type: actionTypes.GET_ORG_PERMISSIONS_FAIL,
    error: error
  };
};
export const getOrgPermissionsDataReset = () => {
  return {
    type: actionTypes.GET_ORG_PERMISSIONS_RESET
  };
};

export const getOrgPermissionsData = req => {
  return dispatch => {
    dispatch(getOrgPermissionsDataStart());
    HttpService.getOrgPermissionsSettingsData(req)
      .then(response => {
        dispatch(getOrgPermissionsDataSuccess(response));
      })
      .catch(err => {
        //dispatch(getOrgPermissionsDataFail(err));
      });
  };
};

//Edit Org Permissions
export const editOrgPermissionsDataStart = () => {
  return {
    type: actionTypes.EDIT_ORG_PERMISSIONS_START
  };
};

export const editOrgPermissionsDataSuccess = response => {
  return {
    type: actionTypes.EDIT_ORG_PERMISSIONS_SUCCESS,
    editOrgPermissionsDataResponse: response
  };
};

export const editOrgPermissionsDataFail = error => {
  return {
    type: actionTypes.EDIT_ORG_PERMISSIONS_FAIL,
    error: error
  };
};
export const editOrgPermissionsDataReset = () => {
  return {
    type: actionTypes.EDIT_ORG_PERMISSIONS_RESET
  };
};

export const editOrgPermissionsData = req => {
  return dispatch => {
    dispatch(editOrgPermissionsDataStart());
    HttpService.editPermission(req)
      .then(response => {
        dispatch(editOrgPermissionsDataSuccess(response));
      })
      .catch(err => {
        //dispatch(editOrgPermissionsDataFail(err));
      });
  };
};

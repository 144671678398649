import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { showNotification } from "../../../components/UI/Toaster/Toaster";
import ModalInfo from "../../../services/entities/models/ModalInfo";
import ShuttleLocationSettingsRequest from "../../../services/entities/shuttle-app/request/ShuttleLocationSettingsRequest";
import { UserProfileLinkIcon } from "../../../shared/AppImages";
import { ADMIN_PAGE, ModalType } from "../../../shared/GlobalVar";
import PermissionName from "../../../shared/Permissions";
import {
  getSortingClassName,
  getSortType,
  hasPermission,
} from "../../../shared/utility";
import { createTooltip } from "../../../shared/utility/tooltip-utility";
import {
  getSettingstDataByApi,
  getSettingstDataByApiReset,
} from "../../../store/actions";
import MarkerMap from "../../map/marker-map";
import { convertMsgResponse } from "../../Reservation/fox-reservation/fox-res-helper";
import { getAPiNameForSettings } from "../../Settings/settingsHelper";
import ShuttleQuickModal from "../modal/shuttle-quick-modal";
import { getLandMarkMapData, getShuttleMapData } from "../shuttles-helper";

export const SearchShuttlesGrid = (props) => {
  // const loading = useSelector(
  //   (state) =>
  //     state.settingsReducer.loading || state.shuttleOptionReducer.loading
  // );
  const settingsResponse = useSelector(
    (state) => state.settingsReducer.settingsResponse
  );
  const [modalInfo, setModalInfo] = useState([new ModalInfo()]);
  const [fetchIntervalInMillis, setFetchIntervalInMillis] = useState(null);
  const [vicinityRadiusInMiles, setVicinityRadiusInMiles] = useState(null);
  const getSingleTableHeaderRow = (title, sortValue) => {
    return (
      <span
        className={
          sortValue
            ? getSortingClassName(props.pageInfo, sortValue)
            : "column__header"
        }
        onClick={() =>
          sortValue
            ? props.doSorting(getSortType(props.pageInfo, sortValue), sortValue)
            : null
        }
      >
        {title}
      </span>
    );
  };
  const rotateRoutingPath = (modalType, customData) => {
    setModalInfo({
      type: modalType,
      showModal: true,
      singleModalIdentifierId: null,
      customData: customData
    });
  }
  const showModal = (modalData) => {
    setModalInfo({
      type: modalData.type,
      showModal: true,
      singleModalIdentifierId: modalData.shuttleId,
      customData: modalData.customData
    });
  };
  const closeModal = () => {
    setModalInfo(new ModalInfo());
  };
  const dispatch = useDispatch();
  const getSettingsData = (locCode) => {
    const req = new ShuttleLocationSettingsRequest({
      locationCode: locCode,
    });
    dispatch(getSettingstDataByApiReset(props.isInheritModal));
    dispatch(
      getSettingstDataByApi(
        req,
        getAPiNameForSettings(ADMIN_PAGE.SHUTTLES_SETTINGS),
        props.isInheritModal,
        true
      )
    );
  };
  useEffect(() => {
    if (!settingsResponse) {
      return;
    }
    if (
      settingsResponse.message &&
      settingsResponse.message.messageType === "SUCCESS" &&
      settingsResponse.shuttleLocationSettings
    ) {
      setFetchIntervalInMillis(
        settingsResponse.shuttleLocationSettings.fetchIntervalInMillis
      );
      setVicinityRadiusInMiles(
        settingsResponse.shuttleLocationSettings.vicinityRadiusInMiles
      );
    } else {
      showNotification(convertMsgResponse(settingsResponse.message));
    }
  }, [settingsResponse]);
  useEffect(() => {
    if (props.selectedLoc) {
      getSettingsData(props.selectedLoc);
    }
  }, [props.selectedLoc]);
  useEffect(() => {
    let interval;
    if (
      props.isLiveView &&
      settingsResponse &&
      settingsResponse.shuttleLocationSettings.fetchIntervalInMillis &&
      fetchIntervalInMillis ===
      settingsResponse.shuttleLocationSettings.fetchIntervalInMillis
    ) {
      interval = setInterval(() => {
        props.refreshMapdata();
      }, fetchIntervalInMillis);
      return () => clearInterval(interval);
    } else {
      closeModal();
    }
  }, [props.isLiveView, fetchIntervalInMillis]);
  return (
    <Fragment>
      {!props.isLiveView ? (
        <div className="datagrid">
          <div className="column">
            {getSingleTableHeaderRow("Shuttle Code", "shuttleCode")}
            {getSingleTableHeaderRow("VIN", "vin")}
            {getSingleTableHeaderRow("Name", "name")}
            {getSingleTableHeaderRow("Shuttle Info", "make")}
            {getSingleTableHeaderRow("Location")}
            {getSingleTableHeaderRow("Status", "status")}
            {getSingleTableHeaderRow("Availability", "availability")}
          </div>
          {props.dataList.map((post) => {
            return (
              <div className="row" key={post.shuttleId}>
                <span className="row__column" id={"shuttleCodeId"}>
                  {createTooltip(
                    <span
                      className={`md-trigger ${hasPermission(PermissionName.CC_VIEW_SHUTTLE)
                        ? "pseudo-link"
                        : ""
                        }`}
                      id={"shuttleCodeId_" + post.shuttleId}
                      onClick={() =>
                        hasPermission(PermissionName.CC_VIEW_SHUTTLE)
                          ? showModal({
                            type: ModalType.SHUTTLE_QUICK_MODAL,
                            showModal: true,
                            shuttleId: post.shuttleId,
                            customData: post,
                          })
                          : ""
                      }
                    >
                      {post.shuttleCode}
                    </span>,
                    post.shuttleCode
                  )}
                </span>
                <span className="row__column" id="shuttleCodeIconId">
                  {hasPermission(PermissionName.CC_VIEW_SHUTTLE) ? createTooltip(
                    <Link
                      id={"shuttleNavigation_" + post.shuttleId}
                      to={{
                        pathname: "/shuttle",
                        search: "?shuttleId=" + post.shuttleId,
                        state: { breadCrumbList: props.breadCrumbList },
                      }}
                    >
                      <img
                        alt=""
                        src={UserProfileLinkIcon}
                        style={{ height: "12px", width: "12px" }}
                      />
                    </Link>,
                    post.shuttleCode
                  ) : ""}
                </span>
                <span className="row__column" id={"vin_" + post.vin}>
                  {createTooltip(post.vin, post.vin)}
                </span>
                <span className="row__column" id="shuttleIconColor">
                  {createTooltip(
                    <i
                      className="fas fa-bus"
                      style={{ color: post.iconColor }}
                    ></i>,
                    post.combineStatusLabel,
                    { forceShow: true }
                  )}
                </span>
                <span className="row__column" id="shuttleName">
                  {createTooltip(post.name, post.name)}
                </span>
                <span className="row__column">
                  {createTooltip(post.shuttleInfo, post.shuttleInfo)}
                </span>
                <span
                  className="row__column"
                  id={"location_" + post.locationDisplayName}
                >
                  {createTooltip(
                    post.locationDisplayName,
                    post.locationDisplayName
                  )}
                </span>
                <span className="row__column text-center">
                  {createTooltip(
                    post.combineStatusLabel,
                    post.combineStatusLabel
                  )}
                </span>
                <span className="row__column text-center">
                  {createTooltip(
                    post.availability.value,
                    post.availability.value
                  )}
                </span>
              </div>
            );
          })}
        </div>
      ) : (
        <div style={{ padding: "10px", border: "1px solid #ccc" }}>
          <MarkerMap
            shuttleData={getShuttleMapData(props.vicinityData, modalInfo, showModal)}
            landmarkData={getLandMarkMapData(props.vicinityData, modalInfo, showModal)}
            vicinityData={props.vicinityData}
            defaultPosition={[
              props.vicinityData.latitude,
              props.vicinityData.longitude,
            ]}
            refreshMapdata={props.refreshMapdata}
            isVicinity={true}
            vicinityRadiusInMiles={vicinityRadiusInMiles}
            openDirectionModal={showModal}
            modalType={modalInfo.type}
            showRoute={modalInfo.showModal}
            closeRoute={closeModal}
            rotateRoutingPath={rotateRoutingPath}
            customData={modalInfo.customData || {}}
          />
        </div>
      )}
      {modalInfo.showModal && modalInfo.type === ModalType.SHUTTLE_QUICK_MODAL ? (
        <ShuttleQuickModal
          modalType={modalInfo.type}
          showModal={modalInfo.showModal}
          closeModal={closeModal}
          shuttleId={modalInfo.singleModalIdentifierId}
        />
      ) : null}
    </Fragment>
  );
};
export default SearchShuttlesGrid;

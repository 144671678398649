import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getVehicleDetails,
  getVehicleDetailsReset,
} from "../../../store/actions/index";
import {
  formatAmountValue,
  getBasicToastResponse,
} from "../../../shared/utility";
import { Modal, ModalHeader, ModalBody, Spinner } from "reactstrap";
import Draggable from "react-draggable";
import { showNotification } from "../../../components/UI/Toaster/Toaster";
import { createTooltip } from "../../../shared/utility/tooltip-utility";
import { LabelAndValuePairControl } from "../../../components/UI/Input/label-value-pair-control";
export const VehicleQuickModal = (props) => {
  const loading = useSelector((state) => state.vehicleReducer.loading);
  const vehicleDetailsResponse = useSelector(
    (state) => state.vehicleReducer.vehicleDetailsResponse
  );
  const dispatch = useDispatch();
  const vehicleId = props.vehicleId;
  const [vehicleData, setVehicleData] = useState({});
  useEffect(() => {
    initVehicleQuicModal();
  }, []);
  useEffect(() => {
    if (!vehicleDetailsResponse) {
      return;
    }
    if (vehicleDetailsResponse && vehicleDetailsResponse.StatusCode === 0) {
      setVehicleData(vehicleDetailsResponse);
    } else {
      showNotification(getBasicToastResponse(vehicleDetailsResponse));
      dispatch(getVehicleDetailsReset());
    }
  }, [vehicleDetailsResponse]);
  const initVehicleQuicModal = () => {
    const vehicleQuickSearchObj = {};
    vehicleQuickSearchObj.MemberId = props.MemberId || null;
    vehicleQuickSearchObj.MemberAssetId = vehicleId;
    vehicleQuickSearchObj.PromisedStartTime = props.PromisedStartTime || null;
    dispatch(getVehicleDetailsReset());
    dispatch(getVehicleDetails(vehicleQuickSearchObj));
  };
  const getDefaultAmenitiesLbl = () => {
    let amenities = "";
    vehicleData.Amenities.map((entity) => {
      return (amenities = amenities
        ? amenities + ", " + entity.DisplayText
        : entity.DisplayText);
    });
    return amenities;
  };
  const getDefaultAmenitiesLblTooltip = () => {
    return (
      <div class="common-tooltip">
        <ul>
          {vehicleData.Amenities.map((entity) => {
            return <li>{entity.DisplayText}</li>;
          })}
        </ul>
      </div>
    );
  };
  const renderLabelInputPair = (labelCaption, value, isTooltipEnabled) => {
    return (
      <LabelAndValuePairControl
        elementConfig={{ value: value }}
        otherConfig={{
          caption: labelCaption,
        }}
        parentRowClass="popup-row"
        labelClasses="font-weight-bold"
      />
    );
  };
  const renderData = () => {
    return (
      <Fragment>
        <div className="w-50 pl-4 pr-4">
          <h4 className="form-header text-uppercase">
            <i className="fas fa-car-alt" /> Basic Information
          </h4>
          {renderLabelInputPair(
            "Category",
            vehicleData.Category ? vehicleData.Category.Value : "--"
          )}
          {renderLabelInputPair("Make", vehicleData.Make)}
          {renderLabelInputPair("Year", vehicleData.VehicleYear)}
          {renderLabelInputPair("License Plate", vehicleData.LicenseNo)}
          {renderLabelInputPair("Vehicle ID", vehicleData.UnitNo, true)}
          {renderLabelInputPair(
            "VIN",
            vehicleData.VinNumber ? vehicleData.VinNumber : "--"
          )}
          {renderLabelInputPair(
            "Availability Status",
            vehicleData.AvailabilityStatus
          )}

          <div className="col-lg-12 p-0">
            <h4 className="form-header text-uppercase">
              <i className="fas fa-car-alt"></i> Rate Information
            </h4>
          </div>
          {renderLabelInputPair(
            "Hourly",
            formatAmountValue(vehicleData.SharerRatePerHour)
          )}
          {renderLabelInputPair(
            "Daily",
            formatAmountValue(vehicleData.SharerRatePerDay)
          )}
          {renderLabelInputPair(
            "Weekend",
            formatAmountValue(vehicleData.SharerRatePerWeekendDay)
          )}
          {renderLabelInputPair(
            "Weekly",
            formatAmountValue(vehicleData.SharerRatePerWeek)
          )}
          {renderLabelInputPair(
            "Monthly",
            formatAmountValue(vehicleData.SharerRatePerMonth)
          )}
        </div>
        <div className="w-50 pl-4 pr-4">
          <h4 className="form-header text-uppercase">
            <i className="fas fa-car-alt" /> Vehicle Details
          </h4>
          {renderLabelInputPair("Vehicle Name", vehicleData.Name, true)}
          {renderLabelInputPair("Drive Type", vehicleData.DriveType)}
          {renderLabelInputPair(
            "TransmissionType",
            vehicleData.TransmissionType,
            true
          )}
          {renderLabelInputPair("Fuel Type", vehicleData.FuelType)}
          {renderLabelInputPair("Color", vehicleData.Color)}
          {renderLabelInputPair(
            "Seating Capacity",
            vehicleData.SeatingCapacity
          )}
          {renderLabelInputPair(
            "Disclaimer Notes",
            vehicleData.DisclaimerText,
            true
          )}
          <div className="popup-row enabled-field-for-tooltip">
            <span className="font-weight-bold">Amenities</span>
            <span id="v-Amenities">
              {vehicleData.Amenities && vehicleData.Amenities.length > 0
                ? createTooltip(
                    getDefaultAmenitiesLbl(),
                    getDefaultAmenitiesLblTooltip()
                  )
                : "--"}
            </span>
          </div>
          <div className="col-lg-12 p-0">
            <h4 className="form-header text-uppercase">
              <i className="fas fa-car-alt"></i> Owner Information
            </h4>
          </div>
          {renderLabelInputPair("Owner", vehicleData.OwnerName, true)}
          {renderLabelInputPair("Phone", vehicleData.OwnerFormattedWorkPhone)}
        </div>
      </Fragment>
    );
  };
  return (
    <Draggable handle=".cursor">
      <Modal
        isOpen={props.showModal}
        className="no-cursor"
        toggle={() => {
          props.closeModal();
        }}
        style={{
          minWidth: "90rem",
        }}
      >
        <ModalHeader
          className="modal-header bg-primary modal-title text-white cursor"
          toggle={() => {
            props.closeModal();
          }}
        >
          <i className="fas fa-arrows-alt" /> Vehicle Quick View
        </ModalHeader>
        <ModalBody
          style={{
            minHeight: "400pxpx",
          }}
        >
          <div
            className="form-group row"
            style={{
              overflow: "auto",
            }}
          >
            <div
              id="vehicle-quick-view"
              className="row w-100 tableScroll bb-0"
              style={{
                minHeight: "33rem",
              }}
            >
              {renderData()}
            </div>
          </div>
        </ModalBody>
        {loading ? <Spinner /> : ""}
      </Modal>
    </Draggable>
  );
};
export default VehicleQuickModal;

import React, { useEffect, useState } from "react";
import Draggable from "react-draggable";
import { useDispatch, useSelector } from "react-redux";
import Multiselect from "react-widgets/lib/Multiselect";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { showNotification } from "../../../../components/UI/Toaster/Toaster";
import { EXTERNAL_CODE_LBL, ModalType, TOAST_MSG } from "../../../../shared/GlobalVar";
import { getBasicToastResponse } from "../../../../shared/utility";
import {
  cancelReservationForFox,
  cancelReservationForFoxReset,
  getEnumvalues,
  updateCancellationReason,
  updateCancellationReasonReset,
} from "../../../../store/actions/index";
import { convertMsgResponse, createCancelReservationReq, getErrMsgForCancelReservation } from "../fox-res-helper";

export const FoxCancelModal = (props) => {
  const cancellationData = props.defaultData;
  const foxCancelReservationResponse = useSelector(
    (state) => state.foxAPIReducer.foxCancelReservationResponse
  );
  const reservationCancellationReasons = useSelector(
    (state) => state.enumvaluesReducer.reservationCancellationReasons
  );
  const updateCancelletionResponse = useSelector(
    (state) => state.reservationReducer.updateCancelletionResponse
  );
  const [multiSelectValues, setMultiSelectValues] = useState([]);
  const [cancelComment, setCancelComment] = useState(null);
  const dispatch = useDispatch();
  let isCancelReservation =
    cancellationData.modalType === ModalType.CANCEL_FOX_MODAL;

  useEffect(() => {
    if (!reservationCancellationReasons) {
      dispatch(getEnumvalues());
    } else if (cancellationData.cancellationReasons.length) {
      setMultiSelectValues(
        cancellationData.cancellationReasons.map((item) => {
          return {
            Name: item.key,
            DisplayName: item.value,
            Hint: item.value2,
          };
        })
      );
      setCancelComment(cancellationData.cancellationNotes);
    }
  }, [cancellationData.reservationCode, reservationCancellationReasons]);

  useEffect(() => {
    if (!foxCancelReservationResponse) {
      return;
    }
    if (
      foxCancelReservationResponse.message &&
      foxCancelReservationResponse.message.messageType === "SUCCESS"
    ) {
      showNotification(
        convertMsgResponse(foxCancelReservationResponse.message)
      );
      dispatch(cancelReservationForFoxReset());
      props.resetParentPage();
      props.closeModal();
    } else {
      showNotification(
        convertMsgResponse(foxCancelReservationResponse.message)
      );
      dispatch(cancelReservationForFoxReset());
    }
  }, [foxCancelReservationResponse]);

  useEffect(() => {
    if (!updateCancelletionResponse) {
      return;
    }
    showNotification(getBasicToastResponse(updateCancelletionResponse));
    if (updateCancelletionResponse.StatusCode === 0) {
      dispatch(updateCancellationReasonReset());
      props.resetParentPage();
      props.closeModal();
    }
    dispatch(updateCancellationReasonReset());
  }, [updateCancelletionResponse]);


  const showErrorMsg = (errorMsg) => {
    var toastMsg = {
      msg: errorMsg,
      toastType: TOAST_MSG.ERROR,
    };
    showNotification(toastMsg);
  };

  const preprocessCancellationData = () => {
    const cancelReasons = multiSelectValues.length > 0 ? multiSelectValues.map((element) => {
      return element.Name ? element.Name : "";
    }) : null;
    const comments = cancelComment || "N/A";
    return { cancelReasons, comments };
  };

  const onReservationCancel = () => {
    const { cancelReasons, comments } = preprocessCancellationData();
    const cancelReq = createCancelReservationReq({
      customerInfo: cancellationData.customerInfo,
      reservationCode: cancellationData.reservationCode,
      cancelReasons: cancelReasons,
      cancelComment: comments,
    });
    let errorMsg = getErrMsgForCancelReservation(cancelReq);
    if (!errorMsg) {
      if (isCancelReservation) {
        dispatch(cancelReservationForFoxReset());
        dispatch(cancelReservationForFox(cancelReq));
      } else {
        const updateCancelReasonReq = {
          ReservationId: cancellationData.reservationId,
          CancellationReasons: cancelReasons,
          Comments: comments,
        };
        dispatch(updateCancellationReasonReset());
        dispatch(updateCancellationReason(updateCancelReasonReq));
      }
    } else {
      showErrorMsg(errorMsg);
    }
  };
  const getFormattedCancellationResons = () => {
    if (
      cancellationData.cancellationReasons &&
      cancellationData.cancellationReasons.length > 1
    ) {
      const reasonList = cancellationData.cancellationReasons.map(
        (reason, index) => {
          return <li>{reason.value}</li>;
        }
      );
      return <ol>{reasonList}</ol>;
    } else if (cancellationData.cancellationReasons.length === 1) {
      return <label>{cancellationData.cancellationReasons[0].value}</label>;
    }
    return "--";
  };
  const getReasons = () => {
    const data = reservationCancellationReasons && reservationCancellationReasons.length ? [...reservationCancellationReasons] : [];
    return data;
  }
  return (
    <Draggable handle=".cursor">
      <Modal
        isOpen={props.showModal}
        className="no-cursor"
        toggle={() => {
          props.closeModal();
        }}
        style={{
          minWidth: "70rem",
        }}
      >
        <ModalHeader
          className="modal-header bg-primary modal-title text-white cursor"
          toggle={() => {
            props.closeModal();
          }}
        >
          <i className="fas fa-arrows-alt" /> {cancellationData.modalTitle}
        </ModalHeader>
        <ModalBody
          style={{
            maxHeight: "60rem",
          }}
        >
          <div className="col-lg-12 p-0" id="cancelled-reservation-modal">
            {cancellationData.reservationState === "CANCELLED" && (
              <>
                <div className="form-group row">
                  <label className="col-lg-4 col-form-label font-weight-bold">
                    Cancelled On
                  </label>
                  <div className="col-lg-8">
                    <label
                      className="col-form-label"
                    >
                      {cancellationData.cancelledOnDisplayText}
                    </label>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-lg-4 col-form-label font-weight-bold">
                    Cancelled By
                  </label>
                  <div className="col-lg-8">
                    <label className="col-form-label">
                      {cancellationData.cancelledByPersonName}
                    </label>
                  </div>
                </div>
              </>
            )}
            <div className="form-group row">
              <label className="col-lg-4 col-form-label font-weight-bold">
                Reservation Code
              </label>
              <div className="col-lg-8">
                <label className="col-form-label">
                  {cancellationData.confirmationCode}
                </label>
              </div>
            </div>
            <div className="form-group row">
              <label className="col-lg-4 col-form-label font-weight-bold">
                {EXTERNAL_CODE_LBL.CONFIRMATION_CODE}
              </label>
              <div className="col-lg-8">
                <label className="col-form-label">
                  {cancellationData.reservationCode}
                </label>
              </div>
            </div>
            <div className="form-group row">
              <label className="col-lg-4 col-form-label font-weight-bold">
                Customer Name
              </label>
              <div className="col-lg-8">
                <label className="col-form-label">
                  {cancellationData.fullName +
                    " (" +
                    cancellationData.customerInfo.email +
                    ")"}
                </label>
              </div>
            </div>
            <div className="form-group row required">
              <label className="col-lg-4 col-form-label font-weight-bold">
                Cancellation Reason(s)
              </label>
              <div className={`col-lg-8`}>
                {cancellationData.isEditable ? (
                  <Multiselect
                    data={getReasons()}
                    valueField="Name"
                    textField="DisplayName"
                    value={multiSelectValues}
                    onChange={(value) => setMultiSelectValues(value)}
                    groupBy="type"
                  />
                ) : (
                  getFormattedCancellationResons()
                )}
              </div>
            </div>
            <div className="form-group row">
              <label
                htmlFor="cancelComment"
                className="col-lg-4 col-form-label font-weight-bold"
              >
                Comments
              </label>
              <div className="col-lg-8">
                <textarea
                  rows="20"
                  name="cancelComment"
                  id="cancelComment"
                  value={cancelComment}
                  onChange={(e) => setCancelComment(e.target.value)}
                  disabled={!cancellationData.isEditable}
                />
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="col-lg-12 text-right">
            {cancellationData.isEditable ? (
              <button
                type="button"
                name="btnReservationCancel"
                className="btn btn-primary waves-effect waves-light btnSize ml-2"
                onClick={() => onReservationCancel()}
              >
                {isCancelReservation ? "Confirm" : "Update"}
              </button>
            ) : (
              ""
            )}
            <button
              type="button"
              name="btnCancel"
              className="btn btn-secondary waves-effect waves-light btnSize ml-2"
              onClick={props.closeModal}
            >
              Cancel
            </button>
          </div>
        </ModalFooter>
      </Modal>
    </Draggable>
  );
};
export default FoxCancelModal;

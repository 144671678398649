import moment from "moment";
import { invokeServerAPI } from "../../services/httpService";
import { MailType } from "../../shared/GlobalVar";
import * as actionTypes from "./actionTypes";

export const doReservationSearchStart = () => {
  return {
    type: actionTypes.SEARCH_RESERVATION_START,
  };
};
export const doReservationSearchSuccess = (response) => {
  return {
    type: actionTypes.SEARCH_RESERVATION_SUCCESS,
    searchReservationResponse: response,
  };
};
export const doReservationSearchFail = (error) => {
  return {
    type: actionTypes.SEARCH_RESERVATION_FAIL,
    error: error,
  };
};
export const doReservationSearchReset = () => {
  return {
    type: actionTypes.SEARCH_RESERVATION_RESET,
  };
};

export const doRentalSearchStart = () => {
  return {
    type: actionTypes.SEARCH_RENTAL_START,
  };
};
export const doRentalSearchSuccess = (response) => {
  return {
    type: actionTypes.SEARCH_RENTAL_SUCCESS,
    searchRentalResponse: response,
  };
};
export const doRentalSearchFail = (error) => {
  return {
    type: actionTypes.SEARCH_RENTAL_FAIL,
    error: error,
  };
};
export const doRentalSearchReset = () => {
  return {
    type: actionTypes.SEARCH_RENTAL_RESET,
  };
};

export const doMemberSearchReservationStart = () => {
  return {
    type: actionTypes.SEARCH_MEMBER_RESERVATION_START,
  };
};
export const doMemberSearchReservationSuccess = (response) => {
  return {
    type: actionTypes.SEARCH_MEMBER_RESERVATION_SUCCESS,
    searchMemberReservationsResponse: response,
  };
};
export const doMemberSearchReservationFail = (error) => {
  return {
    type: actionTypes.SEARCH_MEMBER_RESERVATION_FAIL,
    error: error,
  };
};
export const doMemberSearchReservationReset = () => {
  return {
    type: actionTypes.SEARCH_MEMBER_RESERVATION_RESET,
  };
};

export const doMemberVehicleSearchReservationStart = () => {
  return {
    type: actionTypes.SEARCH_MEMBER_VEHICLE_RESERVATION_START,
  };
};
export const doMemberVehicleSearchReservationSuccess = (response) => {
  return {
    type: actionTypes.SEARCH_MEMBER_VEHICLE_RESERVATION_SUCCESS,
    searchMemberVehicleReservationsResponse: response,
  };
};
export const doMemberVehicleSearchReservationFail = (error) => {
  return {
    type: actionTypes.SEARCH_MEMBER_VEHICLE_RESERVATION_FAIL,
    error: error,
  };
};
export const doMemberVehicleSearchReservationReset = () => {
  return {
    type: actionTypes.SEARCH_MEMBER_VEHICLE_RESERVATION_RESET,
  };
};

export const getReservationOverviewStart = () => {
  return {
    type: actionTypes.GET_RESERVATION_OVERVIEW_START,
  };
};

export const getReservationOverviewSuccess = (response) => {
  return {
    type: actionTypes.GET_RESERVATION_OVERVIEW_SUCCESS,
    reservationOverviewResponse: response,
  };
};

export const getReservationOverviewFail = (error) => {
  return {
    type: actionTypes.GET_RESERVATION_OVERVIEW_FAIL,
    error: error,
  };
};
export const getReservationOverviewReset = () => {
  return {
    type: actionTypes.GET_RESERVATION_OVERVIEW_RESET,
  };
};

export const cancelReservationStart = () => {
  return {
    type: actionTypes.CANCEL_RESERVATION_START,
  };
};

export const cancelReservationSuccess = (response) => {
  return {
    type: actionTypes.CANCEL_RESERVATION_SUCCESS,
    cancelReservationResponse: response,
  };
};

export const cancelReservationFail = (error) => {
  return {
    type: actionTypes.CANCEL_RESERVATION_FAIL,
    error: error,
  };
};
export const cancelReservationReset = () => {
  return {
    type: actionTypes.CANCEL_RESERVATION_RESET,
  };
};
export const updateCancellationReasonStart = () => {
  return {
    type: actionTypes.UPDATE_CANCELLATION_REASON_START,
  };
};
export const updateCancellationReasonSuccess = (response) => {
  return {
    type: actionTypes.UPDATE_CANCELLATION_REASON_SUCCESS,
    updateCancelletionResponse: response,
  };
};

export const updateCancellationReasonFail = (error) => {
  return {
    type: actionTypes.UPDATE_CANCELLATION_REASON_FAIL,
    error: error,
  };
};
export const updateCancellationReasonReset = () => {
  return {
    type: actionTypes.UPDATE_CANCELLATION_REASON_RESET,
  };
};
export const editConfirmationCodeStart = () => {
  return {
    type: actionTypes.EDIT_CONFIRMATION_CODE_START,
  };
};

export const editConfirmationCodeSuccess = (response) => {
  return {
    type: actionTypes.EDIT_CONFIRMATION_CODE_SUCCESS,
    editConfirmationCodeResponse: response,
  };
};

export const editConfirmationCodeFail = (error) => {
  return {
    type: actionTypes.EDIT_CONFIRMATION_CODE_FAIL,
    error: error,
  };
};
export const editConfirmationCodeReset = () => {
  return {
    type: actionTypes.EDIT_CONFIRMATION_CODE_RESET,
  };
};

export const updateReservationNotesStart = () => {
  return {
    type: actionTypes.UPDATE_NOTES_START,
  };
};

export const updateReservationNotesSuccess = (response) => {
  return {
    type: actionTypes.UPDATE_NOTES_SUCCESS,
    updateNotesResponse: response,
  };
};

export const updateReservationNotesFail = (error) => {
  return {
    type: actionTypes.UPDATE_NOTES_FAIL,
    error: error,
  };
};
export const updateReservationNotesReset = () => {
  return {
    type: actionTypes.UPDATE_NOTES_RESET,
  };
};

export const getIntegrationHistoryStart = () => {
  return {
    type: actionTypes.INTEGRATION_HISTORY_START,
  };
};
export const getIntegrationHistorySuccess = (response) => {
  return {
    type: actionTypes.INTEGRATION_HISTORY_SUCCESS,
    integrationHistoryResponse: response,
  };
};
export const getIntegrationHistoryFail = (error) => {
  return {
    type: actionTypes.INTEGRATION_HISTORY_FAIL,
    error: error,
  };
};
export const getIntegrationHistoryReset = () => {
  return {
    type: actionTypes.INTEGRATION_HISTORY_RESET,
  };
};

export const updateIntegStatusStart = () => {
  return {
    type: actionTypes.UPDATE_INTEG_STATUS_START,
  };
};
export const updateIntegStatusSuccess = (response) => {
  return {
    type: actionTypes.UPDATE_INTEG_STATUS_SUCCESS,
    updateIntegStatusResponse: response,
  };
};
export const updateIntegStatusFail = (error) => {
  return {
    type: actionTypes.UPDATE_INTEG_STATUS_FAIL,
    error: error,
  };
};
export const updateIntegStatusReset = () => {
  return {
    type: actionTypes.UPDATE_INTEG_STATUS_RESET,
  };
};

export const acceptTermsAndConditionsStart = () => {
  return {
    type: actionTypes.ACCEPT_TERMS_CONDITIONS_START,
  };
};
export const acceptTermsAndConditionsSuccess = (response) => {
  return {
    type: actionTypes.ACCEPT_TERMS_CONDITIONS_SUCCESS,
    acceptTermsConditionsResponse: response,
  };
};
export const acceptTermsAndConditionsFail = (error) => {
  return {
    type: actionTypes.ACCEPT_TERMS_CONDITIONS_FAIL,
    error: error,
  };
};
export const acceptTermsAndConditionsReset = () => {
  return {
    type: actionTypes.ACCEPT_TERMS_CONDITIONS_RESET,
  };
};

export const doAcceptOrDeclineReservationStart = () => {
  return {
    type: actionTypes.ACCEPT_DECLINE_RESERVATION_START,
  };
};

export const doAcceptOrDeclineReservationSuccess = (response) => {
  return {
    type: actionTypes.ACCEPT_DECLINE_RESERVATION_SUCCESS,
    acceptDeclineResponse: response,
  };
};
export const doAcceptOrDeclineReservationFail = (error) => {
  return {
    type: actionTypes.ACCEPT_DECLINE_RESERVATION_FAIL,
    error: error,
  };
};
export const doAcceptOrDeclineReservationReset = () => {
  return {
    type: actionTypes.ACCEPT_DECLINE_RESERVATION_RESET,
  };
};

export const updateReservationCreationTypeStart = () => {
  return {
    type: actionTypes.UPDATE_RESERVATION_CREATION_TYPE_START,
  };
};
export const updateReservationCreationTypeSuccess = (response) => {
  return {
    type: actionTypes.UPDATE_RESERVATION_CREATION_TYPE_SUCCESS,
    reservationCreationTypeResponse: response,
  };
};
export const updateReservationCreationTypeFail = (error) => {
  return {
    type: actionTypes.UPDATE_RESERVATION_CREATION_TYPE_FAIL,
    error: error,
  };
};
export const updateReservationCreationTypeReset = () => {
  return {
    type: actionTypes.UPDATE_RESERVATION_CREATION_TYPE_RESET,
  };
};

export const getContractStart = () => {
  return {
    type: actionTypes.GET_CONTRACT_START,
  };
};
export const getContractSuccess = (response) => {
  return {
    type: actionTypes.GET_CONTRACT_SUCCESS,
    contractResponse: response,
  };
};
export const getContractFail = (error) => {
  return {
    type: actionTypes.GET_CONTRACT_FAIL,
    error: error,
  };
};
export const getContractReset = () => {
  return {
    type: actionTypes.GET_CONTRACT_RESET,
  };
};

export const getInvoiceStart = () => {
  return {
    type: actionTypes.GET_INVOICE_START,
  };
};
export const getInvoiceSuccess = (response) => {
  return {
    type: actionTypes.GET_INVOICE_SUCCESS,
    invoiceResponse: response,
  };
};
export const getInvoiceFail = (error) => {
  return {
    type: actionTypes.GET_INVOICE_FAIL,
    error: error,
  };
};
export const getInvoiceReset = () => {
  return {
    type: actionTypes.GET_INVOICE_RESET,
  };
};

export const getReservationSummaryStart = () => {
  return {
    type: actionTypes.GET_RESERVATION_SUMMARY_START,
  };
};
export const getReservationSummarySuccess = (response) => {
  return {
    type: actionTypes.GET_RESERVATION_SUMMARY_SUCCESS,
    reservationSummaryResponse: response,
  };
};
export const getReservationSummaryFail = (error) => {
  return {
    type: actionTypes.GET_RESERVATION_SUMMARY_FAIL,
    error: error,
  };
};
export const getReservationSummaryReset = () => {
  return {
    type: actionTypes.GET_RESERVATION_SUMMARY_RESET,
  };
};

export const sendMailStart = () => {
  return {
    type: actionTypes.SEND_MAIL_START,
  };
};
export const sendMailSuccess = (response) => {
  return {
    type: actionTypes.SEND_MAIL_SUCCESS,
    mailResponse: response,
  };
};
export const sendMailFail = (error) => {
  return {
    type: actionTypes.SEND_MAIL_FAIL,
    error: error,
  };
};
export const sendMailReset = () => {
  return {
    type: actionTypes.SEND_MAIL_RESET,
  };
};

export const updateReservationDetailsStart = () => {
  return {
    type: actionTypes.UPDATE_RESERVATION_DETAILS_START,
  };
};

export const updateReservationDetailsSuccess = (response) => {
  return {
    type: actionTypes.UPDATE_RESERVATION_DETAILS_SUCCESS,
    updateReservationDetailsResponse: response,
  };
};

export const updateReservationDetailsFail = (error) => {
  return {
    type: actionTypes.UPDATE_RESERVATION_DETAILS_FAIL,
    error: error,
  };
};
export const updateReservationDetailsReset = () => {
  return {
    type: actionTypes.UPDATE_RESERVATION_DETAILS_RESET,
  };
};

export const doReservationSearch = (req) => {
  return (dispatch) => {
    dispatch(doReservationSearchStart());
    invokeServerAPI("searchreservations", req)
      .then((response) => {
        dispatch(doReservationSearchSuccess(response));
      })
      .catch((err) => {
        //dispatch(doReservationSearchFail(err));
      });
  };
};
export const doRentalSearch = (req) => {
  return (dispatch) => {
    dispatch(doRentalSearchStart());
    invokeServerAPI("searchrentals", req)
      .then((response) => {
        dispatch(doRentalSearchSuccess(response));
      })
      .catch((err) => {
        //dispatch(doRentalSearchFail(err));
      });
  };
};
export const doMemberSearchReservation = (req) => {
  return (dispatch) => {
    dispatch(doMemberSearchReservationStart());
    invokeServerAPI("searchmemberreservations", req)
      .then((response) => {
        dispatch(doMemberSearchReservationSuccess(response));
      })
      .catch((err) => {
        //dispatch(doMemberSearchReservationFail(err));
      });
  };
};
export const doMemberVehicleSearchReservation = (req) => {
  return (dispatch) => {
    dispatch(doMemberVehicleSearchReservationStart());
    invokeServerAPI("getreservationrequestlist", req)
      .then((response) => {
        dispatch(doMemberVehicleSearchReservationSuccess(response));
      })
      .catch((err) => {
        //dispatch(doMemberVehicleSearchReservationFail(err));
      });
  };
};
export const getReservationOverview = (req) => {
  return (dispatch) => {
    dispatch(getReservationOverviewStart());
    invokeServerAPI("getreservationoverview", req)
      .then((response) => {
        dispatch(getReservationOverviewSuccess(response));
      })
      .catch((err) => {
        //dispatch(getReservationOverviewFail(err));
      });
  };
};
export const cancelReservation = (req) => {
  return (dispatch) => {
    dispatch(cancelReservationStart());
    invokeServerAPI("cancelfuturereservation", req)
      .then((response) => {
        dispatch(cancelReservationSuccess(response));
      })
      .catch((err) => {
        //dispatch(cancelReservationFail(err));
      });
  };
};
export const updateCancellationReason = (req) => {
  return (dispatch) => {
    dispatch(updateCancellationReasonStart());
    invokeServerAPI("updatecancellationreason", req)
      .then((response) => {
        dispatch(updateCancellationReasonSuccess(response));
      })
      .catch((err) => {
        //dispatch(updateCancellationReasonFail(err));
      });
  };
};
export const editConfirmationCode = (req) => {
  return (dispatch) => {
    dispatch(editConfirmationCodeStart());
    invokeServerAPI("editconfirmationcode", req)
      .then((response) => {
        dispatch(editConfirmationCodeSuccess(response));
      })
      .catch((err) => {
        //dispatch(editConfirmationCodeFail(err));
      });
  };
};
export const updateReservationNotes = (req) => {
  return (dispatch) => {
    dispatch(updateReservationNotesStart());
    invokeServerAPI("updatenotes", req)
      .then((response) => {
        dispatch(updateReservationNotesSuccess(response));
      })
      .catch((err) => {
        //dispatch(updateReservationNotesFail(err));
      });
  };
};
export const getIntegrationHistory = (req) => {
  return (dispatch) => {
    dispatch(getIntegrationHistoryStart());
    invokeServerAPI("getinteghistory", req)
      .then((response) => {
        localStorage.setItem(
          "LAST_FETCHED_TIME_INTEG_HISTORY",
          moment(new Date()).format("MM:DD:YYYY:HH:mm:ss")
        );
        dispatch(getIntegrationHistorySuccess(response));
      })
      .catch((err) => {
        //dispatch(getIntegrationHistoryFail(err));
      });
  };
};

export const updateIntegStatus = (req) => {
  return (dispatch) => {
    dispatch(updateIntegStatusStart());
    invokeServerAPI("updateintegstatus", req)
      .then((response) => {
        dispatch(updateIntegStatusSuccess(response));
      })
      .catch((err) => {
        //dispatch(updateIntegStatusFail(err));
      });
  };
};
export const doAcceptOrDeclineReservation = (req) => {
  return (dispatch) => {
    dispatch(doAcceptOrDeclineReservationStart());
    invokeServerAPI("acceptdeclinereservation", req)
      .then((response) => {
        dispatch(doAcceptOrDeclineReservationSuccess(response));
      })
      .catch((err) => {
        //dispatch(doAcceptOrDeclineReservationFail(err));
      });
  };
};
export const acceptTermsAndConditions = (req) => {
  return (dispatch) => {
    dispatch(acceptTermsAndConditionsStart());
    invokeServerAPI("accepttermsandcondition", req)
      .then((response) => {
        dispatch(acceptTermsAndConditionsSuccess(response));
      })
      .catch((err) => {
        //dispatch(acceptTermsAndConditionsFail(err));
      });
  };
};
export const updateReservationCreationType = (req) => {
  return (dispatch) => {
    dispatch(updateReservationCreationTypeStart());
    invokeServerAPI("updatereservationcreationtype", req)
      .then((response) => {
        dispatch(updateReservationCreationTypeSuccess(response));
      })
      .catch((err) => {
        //dispatch(updateReservationCreationTypeFail(err));
      });
  };
};
export const getContract = (req) => {
  return (dispatch) => {
    dispatch(getContractStart());
    invokeServerAPI("getcontract", req)
      .then((response) => {
        dispatch(getContractSuccess(response));
      })
      .catch((err) => {
        //dispatch(getContractFail(err));
      });
  };
};
export const getInvoice = (req) => {
  return (dispatch) => {
    dispatch(getInvoiceStart());
    invokeServerAPI("getinvoice", req)
      .then((response) => {
        dispatch(getInvoiceSuccess(response));
      })
      .catch((err) => {
        //dispatch(getInvoiceFail(err));
      });
  };
};
export const getReservationSummary = (req) => {
  return (dispatch) => {
    dispatch(getReservationSummaryStart());
    invokeServerAPI("getreservationsummary", req)
      .then((response) => {
        dispatch(getReservationSummarySuccess(response));
      })
      .catch((err) => {
        //dispatch(getReservationSummaryFail(err));
      });
  };
};
export const sendMail = (req, mailType) => {
  const apiName =
    mailType === MailType.EMAIL_CONTRACT
      ? "emailcontract"
      : mailType === MailType.EMAIL_INVOICE
      ? "emailinvoice"
      : mailType === MailType.EMAIL_RESERVATION_SUMMARY
      ? "emailreservationsummary"
      : mailType === MailType.EMAIL_ACTIVITY
      ? "resendcreditgrantemail"
      : "";
  return (dispatch) => {
    dispatch(sendMailStart());
    invokeServerAPI(apiName, req)
      .then((response) => {
        dispatch(sendMailSuccess(response));
      })
      .catch((err) => {
        //dispatch(sendMailFail(err));
      });
  };
};

export const updateReservationDetails = (req) => {
  return (dispatch) => {
    dispatch(updateReservationDetailsStart());
    invokeServerAPI("admin/reservationdetails", req, true)
      .then((response) => {
        dispatch(updateReservationDetailsSuccess(response));
      })
      .catch((err) => {
        //dispatch(updateReservationDetailsFail(err));
      });
  };
};

/**Reservation Voucher */
export const getReservationVoucherStart = () => {
  return {
    type: actionTypes.GET_RESERVATION_VOUCHER_START,
  };
};
export const getReservationVoucherSuccess = (response) => {
  return {
    type: actionTypes.GET_RESERVATION_VOUCHER_SUCCESS,
    reservationVoucherResponse: response,
  };
};
export const getReservationVoucherFail = (error) => {
  return {
    type: actionTypes.GET_RESERVATION_VOUCHER_FAIL,
    error: error,
  };
};
export const getReservationVoucherReset = () => {
  return {
    type: actionTypes.GET_RESERVATION_VOUCHER_RESET,
  };
};
export const getReservationVoucher = (req) => {
  return (dispatch) => {
    dispatch(getReservationVoucherStart());
    invokeServerAPI("getreservationvoucherdetails", req)
      .then((response) => {
        dispatch(getReservationVoucherSuccess(response));
      })
      .catch((err) => {
        //dispatch(getReservationVoucherFail(err));
      });
  };
};

import { getLandmarkIconColor } from "../../../../shared/utility/enum-utility";
import { FoxAddress } from "../../fox-api/domain/fox-address";
import { OrgUnit } from "../../models/domain/org-unit";
import Phone from "../../models/Phone";

export const LandmarkViewModel = function (data) {
  if (!data) data = {};
  this.landmarkId = data.landmarkId || null;
  this.shortName = data.shortName ||null;
  this.name = data.name ||null;
  this.landmarkCode = data.landmarkCode ||null;
  this.orgUnit = new OrgUnit(data.orgUnit);
  this.latitude = data.latitude || null;
  this.longitude = data.longitude || null;
  this.landmarkCategory = data.landmarkCategory ||null;
  this.landmarkStatus = data.landmarkStatus || "DISABLED";
  this.description = data.description || null;
  this.phone = new Phone(data.phone);
  this.address = new FoxAddress(data.address,false,true);
  this.iconColor = getLandmarkIconColor(data.landmarkCategory);
};

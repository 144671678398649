import React, { useState, Fragment } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Draggable from "react-draggable";
import moment from "moment";
import { VehicleAvailabilityModel } from "../../../../services/entities/view-models/settings-view-models";
import {
  updateObject,
  getErrorToastData,
  getDropdownItems,
  getCalenderDays,
  getClockHours,
} from "../../../../shared/utility";
import { showNotification } from "../../../../components/UI/Toaster/Toaster";
import {
  validateVehicleAvailabilityData,
  getDaysType,
} from "../../settingsHelper";
import { LabelAndValuePairControl } from "../../../../components/UI/Input/label-value-pair-control";

export const VehicleAvilabilityModal = (props) => {
  const entity = props.selectedEntity || new VehicleAvailabilityModel();
  const [isValidateDone, setIsValidateDone] = useState(false);
  const totalHour = entity.MinRentalPeriod
    ? Math.floor(entity.MinRentalPeriod / 60)
    : 0;
  const [dayVal, setDayVal] = useState(Math.floor(totalHour / 24));
  const [hourVal, setHourVal] = useState(totalHour % 24);
  const [vehicleAvailabilityEntity, setVehicleAvailabilityEntity] = useState(
    entity
  );
  const handleOnChange = (event, controlName, elementType) => {
    let updateCtrls = { ...vehicleAvailabilityEntity };
    let value = null;
    if (event !== "Invalid date" && elementType === "datetimepicker") {
      value = event ? moment(event).format("MM:DD:YYYY:HH:mm") : event;
    } else {
      value = event.target.value;
    }
    if (elementType === "datetimepicker") {
      updateCtrls = updateObject(updateCtrls, {
        [controlName]: value,
      });
    } else if (event.target.name !== "Days" || event.target.name !== "Hours") {
      updateCtrls = updateObject(updateCtrls, {
        [event.target.name]: value,
      });
    }
    if (elementType !== "datetimepicker" && event.target.name === "Days") {
      setDayVal(value);
      updateCtrls.MinRentalPeriod = value * 24 * 60 + hourVal * 60;
    }
    if (elementType !== "datetimepicker" && event.target.name === "Hours") {
      setHourVal(value);
      updateCtrls.MinRentalPeriod = value * 60 + dayVal * 24 * 60;
    }
    setVehicleAvailabilityEntity(updateCtrls);
  };
  const updateVehicleAvailability = () => {
    let msg = validateVehicleAvailabilityData(vehicleAvailabilityEntity);
    if (msg) {
      showNotification(getErrorToastData(msg));
      setIsValidateDone(true);
    } else {
      setIsValidateDone(true);
      delete vehicleAvailabilityEntity["Hours"];
      delete vehicleAvailabilityEntity["Days"];
      props.addUpdateAvailabilityEntity(
        vehicleAvailabilityEntity,
        props.isOffLine
      );
    }
  };
  const renderLabelInputPair = (
    labelCaption,
    controlName,
    value,
    isRequired = false,
    nodeElement = "input",
    dropdownItem = null
  ) => {
    return (
      <LabelAndValuePairControl
        elementConfig={{
          name: controlName,
          value: value,
          className: nodeElement === "select" ? "pl-0" : "",
        }}
        otherConfig={{
          caption: labelCaption,
          isRequired: isRequired,
          dropdownItem: nodeElement === "select" ? dropdownItem : [],
          value: value,
          dateFormat: "D",
        }}
        isEditMode={true}
        onChange={handleOnChange}
        parentRowClass={`form-group row  ${isRequired ? "required" : ""}`}
        labelClasses={"col-sm-4 col-form-label font-weight-bold"}
        valueClasses={"col-sm-8"}
        nodeElement={nodeElement}
        isValidateDone={isValidateDone}
      />
    );
  };
  return (
    <Draggable handle=".cursor">
      <Modal
        isOpen={props.showModal}
        className="no-cursor"
        toggle={() => {
          props.closeModal();
        }}
      >
        <ModalHeader
          className="modal-header bg-primary modal-title text-white cursor"
          toggle={() => {
            props.closeModal();
          }}
        >
          <i className="fas fa-car pr-2"></i>
          {entity.AvailabilityId
            ? "Update Vehicle Availability"
            : "Add Vehicle Availability"}
        </ModalHeader>
        <ModalBody>
          <div className="col-sm-12 p-0">
            {renderLabelInputPair(
              "Vehicle Type",
              "CategoryId",
              vehicleAvailabilityEntity.CategoryId,
              true,
              "select",
              getDropdownItems(
                props.categories,
                "CategoryId",
                "DisplayText",
                "Select"
              )
            )}
            {renderLabelInputPair(
              "Start Date",
              "StartDate",
              vehicleAvailabilityEntity.StartDate,
              true,
              "datetime"
            )}
            {renderLabelInputPair(
              "End Date",
              "EndDate",
              vehicleAvailabilityEntity.EndDate,
              true,
              "datetime"
            )}
            {renderLabelInputPair(
              "Day Type",
              "DaysOfWeek",
              vehicleAvailabilityEntity.DaysOfWeek,
              true,
              "select",
              getDaysType()
            )}
            {props.isOffLine ? (
              ""
            ) : (
              <Fragment>
                {renderLabelInputPair(
                  "Days",
                  "Days",
                  dayVal,
                  false,
                  "select",
                  getCalenderDays()
                )}
                {renderLabelInputPair(
                  "Hours",
                  "Hours",
                  hourVal,
                  false,
                  "select",
                  getClockHours()
                )}
              </Fragment>
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="col-sm-12 text-right">
            <button
              type="button"
              name="btnYes"
              className="btn btn-primary waves-effect waves-light btnSize ml-2"
              onClick={() => updateVehicleAvailability()}
            >
              {entity.AvailabilityId ? "Update" : "Submit"}
            </button>
            <button
              type="button"
              name="btnCancel"
              className="btn btn-secondary waves-effect waves-light btnSize ml-2"
              onClick={() => props.closeModal()}
            >
              Cancel
            </button>
          </div>
        </ModalFooter>
      </Modal>
    </Draggable>
  );
};
export default VehicleAvilabilityModal;

import * as actionTypes from "./actionTypes";
import HttpService from "../../services/httpService";

export const searchReportProblemDetailsStart = () => {
  return {
    type: actionTypes.SEARCH_REPORT_PROBLEM_DETAILS_START
  };
};
export const searchReportProblemDetailsSuccess = response => {
  return {
    type: actionTypes.SEARCH_REPORT_PROBLEM_DETAILS_SUCCESS,
    searchReportProblemDetailsResponse: response
  };
};
export const searchReportProblemDetailsFail = error => {
  return {
    type: actionTypes.SEARCH_REPORT_PROBLEM_DETAILS_FAIL,
    error: error
  };
};
export const searchReportProblemDetailsReset = () => {
  return {
    type: actionTypes.SEARCH_REPORT_PROBLEM_DETAILS_RESET
  };
};

export const searchReportProblemDetails = reqObject => {
  return dispatch => {
    dispatch(searchReportProblemDetailsStart());
    dispatch(
      searchReportProblemDetailsSuccess(HttpService.getComplainList(reqObject))
    );
  };
};

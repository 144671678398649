import moment from "moment";
import { convertFoxApiDateToStr } from "../../containers/Reservation/fox-reservation/fox-res-helper";
import {
  ADMIN_PAGE,
  IS_EXPORT_DISPLAYED_FLEET_UTILIZATION_DATA,
  MAX_DAYS_FLEET_UTILIZATION_RECORD_EXPORT,
  SKIPPED_ITEM_IN_FLEET_DATA_EXPORT,
} from "../../shared/GlobalVar";
import {
  convertDateTimeString,
  convertSecondToDayHourMinSec,
  getCurrentServerDate,
  getDateArrayFromDateAndDayLength,
  getDateOnlyString,
  getUniqueObjects,
  isNullOrEmpty,
  sortArrayObjectByNumber,
} from "../../shared/utility";
import {
  AdminDateFromat,
  FleetParametersDisplayValue,
} from "../../shared/utility/enum-utility";
export const getExportDataInfo = (
  dataSet,
  pageName,
  exportType,
  initialColumns,
  fleetUtilizationSupportDataForExport
) => {
  //retrive individual column from server data
  let formattedData = [];
  if (
    pageName === ADMIN_PAGE.MEMBER_SEARCH_PAGE ||
    pageName === ADMIN_PAGE.ROLES_PERMISSION_SEARCH_PAGE
  ) {
    formattedData = getFormattedMemberData(dataSet);
  }
  if (pageName === ADMIN_PAGE.VEHICLE_SEARCH_PAGE) {
    formattedData = getFormattedVehicleData(dataSet);
  }
  if (
    pageName === ADMIN_PAGE.RESERVATION_SEARCH_PAGE ||
    pageName === ADMIN_PAGE.RENTAL_SEARCH_PAGE ||
    pageName === ADMIN_PAGE.MEMBER_RESERVATION_SEARCH_PAGE ||
    pageName === ADMIN_PAGE.MEMBER_VEHICLE_RESERVATION_SEARCH_PAGE
  ) {
    formattedData = getFormattedReservationData(dataSet);
  }
  if (
    pageName === ADMIN_PAGE.BILLING_SEARCH_PAGE ||
    pageName === ADMIN_PAGE.MEMBER_BILLING_SEARCH_PAGE ||
    pageName === ADMIN_PAGE.RES_DETAILS_BILLING_SEARCH_PAGE
  ) {
    formattedData = getFormattedBilingData(dataSet);
  }
  if (
    pageName === ADMIN_PAGE.SERVICE_AGENT_SEARCH_PAGE ||
    pageName === ADMIN_PAGE.MECHANIC_APP_REPORT_SEARCH_PAGE ||
    pageName === ADMIN_PAGE.READY_LINE_AGENT_SEARCH_PAGE ||
    pageName === ADMIN_PAGE.SHUTTLE_TRIP_SEARCH_PAGE
  ) {
    formattedData = getFormattedReportData(dataSet);
  }

  if (pageName === ADMIN_PAGE.MEMBER_ACTIVITY_SEARCH_PAGE) {
    formattedData = getFormattedActivityData(dataSet);
  } else if (
    pageName === ADMIN_PAGE.MEMBER_MESSAGE_SEARCH_PAGE ||
    pageName === ADMIN_PAGE.MEMBER_FAVORITE_ASSET_SEARCH_PAGE
  ) {
    formattedData = [...dataSet];
  } else if (pageName === ADMIN_PAGE.MEMBER_FEEDBACK_SEARCH_PAGE) {
    formattedData = getFormattedFeedbackData(dataSet);
  } else if (pageName === ADMIN_PAGE.LANDMARK_SEARCH_PAGE) {
    formattedData = getFormattedLandmarkData(dataSet);
  } else if (pageName === ADMIN_PAGE.SHUTTLE_SEARCH_PAGE) {
    formattedData = getFormattedShuttleData(dataSet);
  } else if (pageName === ADMIN_PAGE.RESERVE_SYNC_PAGE) {
    formattedData = getFormattedFleetUtilizationData(
      dataSet,
      fleetUtilizationSupportDataForExport
    );
  }

  let columns = [];
  //Separate all the column header from server data
  if (formattedData && formattedData.length > 0) {
    formattedData.map((key, index) => {
      let columnHeader = Object.keys(formattedData[index]);
      columnHeader.map((key, index) => {
        columns.push(key);
      });
    });
  }
  //removed duplicate rows
  if (
    pageName === ADMIN_PAGE.MEMBER_SEARCH_PAGE ||
    pageName === ADMIN_PAGE.RESERVE_SYNC_PAGE
  ) {
    columns = columns.filter((val, id, array) => {
      return array.indexOf(val) == id;
    });
  } else {
    columns = columns
      .filter((val, id, array) => {
        return array.indexOf(val) == id;
      })
      .sort();
  }

  //Make popup data for custom selection
  let customColumListObj = {};
  let customColumList = [];
  columns.map((col) => {
    customColumListObj = {};
    if (!initialColumns) {
      customColumListObj.Checked = true;
      customColumListObj.ColumnName = col;
      customColumListObj.ColumnHeader = col;
    } else {
      customColumListObj = getColumnDaaFromStickyColumns(col, initialColumns);
    }
    customColumList.push(customColumListObj);
  });
  let multiDataSetForExcel = [];
  if (exportType === "xls") {
    multiDataSetForExcel = getMultiDataSetForExcel(
      formattedData,
      customColumList,
      exportType
    );
  }
  return {
    formattedData: formattedData,
    customColumList: customColumList,
    multiDataSetForExcel: multiDataSetForExcel,
    cbAllClassName:
      initialColumns && initialColumns.length !== customColumList.length
        ? "fa-minus"
        : "fa-check",
  };
};
const getColumnDaaFromStickyColumns = (colName, initialColumns) => {
  let stickyData = {
    Checked: false,
    ColumnName: colName,
    ColumnHeader: colName,
  };
  initialColumns.map((stickyObj) => {
    if (stickyObj.ColumnName === colName) {
      stickyData = { ...stickyObj };
      return false;
    }
  });
  return stickyData;
};
export const getMultiDataSetForExcel = (
  formattedData,
  customColumList,
  exportType
) => {
  const allTextLines = object2csv(
    formattedData,
    customColumList,
    exportType
  ).split(/\r\n|\n/);
  let columns = [];
  let multiDataSet = [];
  let dataSetObj = {};
  let data = [];
  allTextLines.forEach((row, i) => {
    let tableDataWithHeader = row.split(",");
    if (i === 0) {
      columns = tableDataWithHeader;
    } else {
      tableDataWithHeader = tableDataWithHeader.map(function (str) {
        return str.replace(/~/g, ",");
      });
      data.push(tableDataWithHeader);
    }
  });
  dataSetObj.columns = columns;
  dataSetObj.data = data;
  multiDataSet.push(dataSetObj);
  return multiDataSet;
  // this.setState({
  //   multiDataSetForExcel: dataSet
  // });
  // this.setState({
  //   loading: false
  // });
};
export const object2csv = (formattedData, customColumList, exportType) => {
  let columns = [];
  let data = [...formattedData];
  const columnList = [...customColumList];
  const csvRows = [];
  let selectedColumn = columnList.filter((data) => data.Checked === true);
  let columnHeader = selectedColumn.map((data) => {
    return data.ColumnHeader;
  });
  if (exportType === "csv" || exportType === "xls") {
    csvRows.push(columnHeader.join(","));
  } else if (exportType === "pdf") {
    csvRows.push(columnHeader.join("~"));
  }
  columns = selectedColumn.map((data) => {
    return data.ColumnName;
  });
  for (const row of data) {
    const values = columns.map((header) => {
      var rowVal =
        header === "Parameter"
          ? FleetParametersDisplayValue[row[header]] || row[header]
          : row[header];
      let escaped = ("" + rowVal)
        .replace(/"/g, '\\"')
        .replace("undefined", "")
        .replace("null", "");

      if (exportType === "csv") {
        if (escaped.indexOf(",") > -1) {
          escaped = '"' + escaped.replace(/"/g, '""') + '"';
        }
      } else if (exportType === "xls") {
        escaped = escaped.replace(/,/g, "~");
      }
      return `${escaped}`;
    });
    if (exportType === "csv" || exportType === "xls") {
      csvRows.push(values.join(","));
    } else if (exportType === "pdf") {
      csvRows.push(values.join("~"));
    }
  }
  return csvRows.join("\n");
};
export const getChunkArrays = (arr, chunkCount) => {
  const chunks = [];
  while (arr.length) {
    const chunkSize = Math.ceil(arr.length / chunkCount--);
    const chunk = arr.slice(0, chunkSize);
    chunks.push(chunk);
    arr = arr.slice(chunkSize);
  }
  return chunks;
};

const getDeepCopiedData = (data) => {
  return JSON.parse(JSON.stringify(data));
};

const getFormattedMemberData = (data) => {
  const dataSet = getDeepCopiedData(data);
  const columns = getUniqueColumn(dataSet);
  //format specific cell data
  if (dataSet != null && dataSet != undefined) {
    if (dataSet.length > 0) {
      dataSet.map((key, index1) => {
        let columnValue = key;
        columns.map((key, index2) => {
          if (key === "MembershipDate") {
            dataSet[index1][key] = columnValue[key]
              ? getDateOnlyString(columnValue[key])
              : "";
          }
          if (key === "DateOfBirth") {
            dataSet[index1][key] = columnValue[key]
              ? getDateOnlyString(columnValue[key])
              : "";
          }
          if (key === "LicenseExpirationDate") {
            dataSet[index1][key] = columnValue[key]
              ? getDateOnlyString(columnValue[key])
              : "";
          }
          if (key === "DrivingRecordExpDate") {
            dataSet[index1][key] = columnValue[key]
              ? getDateOnlyString(columnValue[key])
              : "";
          }
          if (key === "HomePhone") {
            dataSet[index1]["HomePhoneNumber"] =
              columnValue[key]["Number"] || "";
            dataSet[index1]["HomePhoneNumberIso"] =
              columnValue[key]["CountryIso"] || "";
            if (
              columnValue[key]["CountryCode"] !== null &&
              columnValue[key]["CountryCode"] !== undefined
            ) {
              dataSet[index1]["HomePhoneNumber"] =
                "+" +
                columnValue[key]["CountryCode"] +
                " " +
                columnValue[key]["FormattedNumber"];
              dataSet[index1]["HomePhoneNumberIso"] =
                columnValue[key]["CountryIso"];
              dataSet[index1]["HomePhone"] = "";
            }
            delete dataSet[index1]["HomePhone"];
          }
          if (key === "MobilePhone") {
            dataSet[index1]["MobilePhoneNumber"] =
              columnValue[key]["Number"] || "";
            dataSet[index1]["MobilePhoneNumberIso"] =
              columnValue[key]["CountryIso"] || "";
            if (
              columnValue[key]["CountryCode"] !== null &&
              columnValue[key]["CountryCode"] !== undefined
            ) {
              dataSet[index1]["MobilePhoneNumber"] =
                "+" +
                columnValue[key]["CountryCode"] +
                " " +
                columnValue[key]["FormattedNumber"];
              dataSet[index1]["MobilePhoneNumberIso"] =
                columnValue[key]["CountryIso"];
            }
            delete dataSet[index1]["MobilePhone"];
          }
          if (key === "WorkPhone") {
            dataSet[index1]["WorkPhoneNumber"] =
              columnValue[key]["Number"] || "";
            dataSet[index1]["WorkPhoneNumberIso"] =
              columnValue[key]["CountryIso"] || "";
            if (
              columnValue[key]["CountryCode"] !== null &&
              columnValue[key]["CountryCode"] !== undefined
            ) {
              dataSet[index1]["WorkPhoneNumber"] =
                "+" +
                columnValue[key]["CountryCode"] +
                " " +
                columnValue[key]["FormattedNumber"];
              dataSet[index1]["WorkPhoneNumberIso"] =
                columnValue[key]["CountryIso"];
            }
            delete dataSet[index1]["WorkPhone"];
          }
          if (key === "ZipCode") {
            delete dataSet[index1]["ZipCode"];
          }
          if (key === "PrimaryAddress") {
            delete dataSet[index1]["PrimaryAddress"];
          }
        });
      });
    }
  }
  return dataSet;
};
const getFormattedVehicleData = (data) => {
  const dataSet = getDeepCopiedData(data);
  const columns = getUniqueColumn(dataSet);
  //format specific cell data
  if (dataSet != null && dataSet != undefined) {
    if (dataSet.length > 0) {
      dataSet.map((key, index1) => {
        let columnValue = key;
        columns.map((key, index2) => {
          if (key === "ListingDate") {
            dataSet[index1][key] = columnValue[key]
              ? getDateOnlyString(columnValue[key])
              : "";
          }

          if (key === "OwnerMobilePhone" && columnValue[key]) {
            dataSet[index1]["OwnerMobilePhoneNumber"] =
              columnValue[key]["Number"] || "";
            dataSet[index1]["OwnerMobilePhoneNumberIso"] =
              columnValue[key]["CountryIso"] || "";
            if (dataSet[index1]["OwnerMobilePhone"] != null) {
              if (
                columnValue[key]["CountryCode"] !== null &&
                columnValue[key]["CountryCode"] !== undefined
              ) {
                dataSet[index1]["OwnerMobilePhoneNumber"] =
                  "+" +
                  columnValue[key]["CountryCode"] +
                  " " +
                  columnValue[key]["FormattedNumber"];
                dataSet[index1]["OwnerMobilePhoneNumberIso"] =
                  columnValue[key]["CountryIso"];
              }
              delete dataSet[index1]["OwnerMobilePhone"];
            }
          }
          if (key === "OwnerHomePhone" && columnValue[key]) {
            dataSet[index1]["OwnerHomePhoneNumber"] =
              columnValue[key]["Number"] || "";
            dataSet[index1]["OwnerHomePhoneNumberIso"] =
              columnValue[key]["CountryIso"] || "";
            if (dataSet[index1]["OwnerHomePhone"] != null) {
              if (
                columnValue[key]["CountryCode"] !== null &&
                columnValue[key]["CountryCode"] !== undefined
              ) {
                dataSet[index1]["OwnerHomePhoneNumber"] =
                  "+" +
                  columnValue[key]["CountryCode"] +
                  " " +
                  columnValue[key]["FormattedNumber"];
                dataSet[index1]["OwnerHomePhoneNumberIso"] =
                  columnValue[key]["CountryIso"];
              }
              delete dataSet[index1]["OwnerHomePhone"];
            }
          }
          if (key === "OwnerWorkPhone" && columnValue[key]) {
            dataSet[index1]["OwnerWorkPhoneNumber"] =
              columnValue[key]["Number"] || "";
            dataSet[index1]["OwnerWorkPhoneNumberIso"] =
              columnValue[key]["CountryIso"] || "";
            if (dataSet[index1]["OwnerWorkPhone"] != null) {
              if (
                columnValue[key]["CountryCode"] !== null &&
                columnValue[key]["CountryCode"] !== undefined
              ) {
                dataSet[index1]["OwnerWorkPhoneNumber"] =
                  "+" +
                  columnValue[key]["CountryCode"] +
                  " " +
                  columnValue[key]["FormattedNumber"];
                dataSet[index1]["OwnerWorkPhoneNumberIso"] =
                  columnValue[key]["CountryIso"];
              }
              delete dataSet[index1]["OwnerWorkPhone"];
            }
          }
          if (key === "PersonAssetOrgUnit") {
            if (dataSet[index1]["PersonAssetOrgUnit"] != null) {
              if (
                columnValue[key]["Key"] !== null &&
                columnValue[key]["Key"] !== undefined
              ) {
                dataSet[index1]["PersonAssetOrgUnitId"] =
                  columnValue[key]["Key"];
                dataSet[index1]["PersonAssetOrgUnitDisplayName"] =
                  columnValue[key]["Value"];
                delete dataSet[index1]["PersonAssetOrgUnit"];
              }
            }
          }
          if (key === "VehicleStatus") {
            if (dataSet[index1]["VehicleStatus"] != null) {
              if (
                columnValue[key]["Key"] !== null &&
                columnValue[key]["Key"] !== undefined
              ) {
                dataSet[index1]["VehicleStatusDesc"] = columnValue[key]["Key"];
              }
            }
            delete dataSet[index1]["VehicleStatus"];
          }
          delete dataSet[index1]["Image"];
        });
      });
    }
  }
  return dataSet;
};
const getFormattedReservationData = (data) => {
  //format specific cell data
  const dataSet = getDeepCopiedData(data);
  const columns = getUniqueColumn(dataSet);
  if (dataSet != null && dataSet != undefined) {
    if (dataSet.length > 0) {
      dataSet.map((key, index1) => {
        let columnValue = key;
        columns.map((key, index2) => {
          if (key === "ActualEndDate") {
            dataSet[index1][key] = columnValue[key]
              ? convertDateTimeString(columnValue[key])
              : "";
          }
          if (key === "ActualStartDate") {
            dataSet[index1][key] = columnValue[key]
              ? convertDateTimeString(columnValue[key])
              : "";
          }
          if (key === "CompletedOn") {
            dataSet[index1][key] = columnValue[key]
              ? convertDateTimeString(columnValue[key])
              : "";
          }
          if (key === "ContractAcceptedDate") {
            dataSet[index1][key] = columnValue[key]
              ? convertDateTimeString(columnValue[key])
              : "";
          }
          if (key === "CreatedOn") {
            dataSet[index1][key] = columnValue[key]
              ? convertDateTimeString(columnValue[key])
              : "";
          }
          if (key === "LastModifiedDate") {
            dataSet[index1][key] = columnValue[key]
              ? convertDateTimeString(columnValue[key])
              : "";
          }
          if (key === "PromiseEndDate") {
            dataSet[index1][key] = columnValue[key]
              ? convertDateTimeString(columnValue[key])
              : "";
          }
          if (key === "PromisedStartDate") {
            dataSet[index1][key] = columnValue[key]
              ? convertDateTimeString(columnValue[key])
              : "";
          }
        });
      });
    }
  }
  return dataSet;
};
const getFormattedBilingData = (data) => {
  //format specific cell data
  const dataSet = getDeepCopiedData(data);
  const columns = getUniqueColumn(dataSet);
  if (dataSet != null && dataSet != undefined) {
    if (dataSet.length > 0) {
      dataSet.map((key, index1) => {
        let columnValue = key;
        columns.map((key, index2) => {
          if (key === "CancelledOn") {
            dataSet[index1][key] = columnValue[key]
              ? convertDateTimeString(columnValue[key])
              : "";
          }
          if (key === "CreatedOn") {
            dataSet[index1][key] = columnValue[key]
              ? convertDateTimeString(columnValue[key])
              : "";
          }
          if (key === "PaymentDate") {
            dataSet[index1][key] = columnValue[key]
              ? convertDateTimeString(columnValue[key])
              : "";
          }
          if (key === "PromisedStartTime") {
            dataSet[index1][key] = columnValue[key]
              ? convertDateTimeString(columnValue[key])
              : "";
          }
          delete dataSet[index1]["CancellationReasons"];
        });
      });
    }
  }
  return dataSet;
};
const getFormattedReportData = (data) => {
  //format specific cell data
  const dataSet = getDeepCopiedData(data);
  const columns = getUniqueColumn(dataSet);
  if (dataSet != null && dataSet != undefined) {
    if (dataSet.length > 0) {
      dataSet.map((key, index1) => {
        let columnValue = key;
        columns.map((key, index2) => {
          if (key === "CreatedOn") {
            dataSet[index1][key] = columnValue[key]
              ? convertDateTimeString(columnValue[key])
              : "";
          }
          if (key === "EndTime") {
            dataSet[index1][key] = columnValue[key]
              ? convertDateTimeString(columnValue[key])
              : "";
          }
          if (key === "StartTime") {
            dataSet[index1][key] = columnValue[key]
              ? convertDateTimeString(columnValue[key])
              : "";
          }
          if (key === "ProcessingTime") {
            dataSet[index1][key] = columnValue[key]
              ? convertSecondToDayHourMinSec(columnValue[key])
              : "";
          }
          delete dataSet[index1]["Image1Url"];
        });
      });
    }
  }
  return dataSet;
};
const getFormattedActivityData = (data) => {
  //format specific cell data
  const dataSet = getDeepCopiedData(data);
  const columns = getUniqueColumn(dataSet);
  if (dataSet != null && dataSet != undefined) {
    if (dataSet.length > 0) {
      dataSet.map((key, index1) => {
        let columnValue = key;
        columns.map((key, index2) => {
          if (key === "CreationDate") {
            dataSet[index1][key] = columnValue[key]
              ? convertDateTimeString(columnValue[key])
              : "";
          }
          if (key === "ReservationEndDate") {
            dataSet[index1][key] = columnValue[key]
              ? convertDateTimeString(columnValue[key])
              : "";
          }
          if (key === "ReservationStartDate") {
            dataSet[index1][key] = columnValue[key]
              ? convertDateTimeString(columnValue[key])
              : "";
          }
          if (key === "ResolvingDate") {
            dataSet[index1][key] = columnValue[key]
              ? convertDateTimeString(columnValue[key])
              : "";
          }
        });
      });
    }
  }
  return dataSet;
};
const getFormattedFeedbackData = (data) => {
  //format specific cell data
  const dataSet = getDeepCopiedData(data);
  const columns = getUniqueColumn(dataSet);
  if (dataSet != null && dataSet != undefined) {
    if (dataSet.length > 0) {
      dataSet.map((key, index1) => {
        let columnValue = key;
        columns.map((key, index2) => {
          if (key === "FeedbackDate") {
            dataSet[index1][key] = columnValue[key]
              ? convertDateTimeString(columnValue[key])
              : "";
          }
        });
      });
    }
  }
  return dataSet;
};
const getFormattedLandmarkData = (data) => {
  //format specific cell data
  const dataSet = getDeepCopiedData(data);
  const columns = getUniqueColumn(dataSet);
  if (dataSet != null && dataSet != undefined) {
    if (dataSet.length > 0) {
      dataSet.map((key, index1) => {
        let columnValue = key;
        columns.map((key, index2) => {
          if (key === "landmarkCategory") {
            dataSet[index1]["landmarkCategory"] =
              columnValue[key]["value"] || "";
          }
          if (key === "status") {
            dataSet[index1]["landmarkStatus"] = columnValue[key] || "";
            delete dataSet[index1]["status"];
          }
        });
        delete dataSet[index1]["orgUnit"];
        delete dataSet[index1]["address"];
        delete dataSet[index1]["iconColor"];
        delete dataSet[index1]["zipCodeId"];
        delete dataSet[index1]["cityId"];
        delete dataSet[index1]["stateId"];
        delete dataSet[index1]["countryId"];
      });
    }
  }
  return dataSet;
};
const getFormattedShuttleData = (data) => {
  //format specific cell data
  const dataSet = getDeepCopiedData(data);
  const columns = getUniqueColumn(dataSet);
  if (dataSet != null && dataSet != undefined) {
    if (dataSet.length > 0) {
      dataSet.map((key, index1) => {
        let columnValue = key;
        columns.map((key, index2) => {
          if (key === "availability") {
            dataSet[index1]["availability"] = columnValue[key]["value"] || "";
          }
          if (key === "shuttleConnectionStatus") {
            dataSet[index1]["shuttleConnectionStatus"] =
              columnValue[key]["value"] || "";
          }
          if (key === "engineStatus") {
            dataSet[index1]["engineStatus"] = columnValue[key]["value"] || "";
          }
        });
        delete dataSet[index1]["iconColor"];
      });
    }
  }
  return dataSet;
};

export const getFormattedFleetUtilizationData = (
  fleetUtilizationForecasts,
  fleetUtilizationSupportDataForExport
) => {
  const data = { SearchList: [] };
  const dateFormat = "YYYY-MM-DD";
  let numberOfDaysToExport = MAX_DAYS_FLEET_UTILIZATION_RECORD_EXPORT;
  const cloneFleetUtilizationForecasts = fleetUtilizationForecasts
    ? JSON.parse(JSON.stringify(fleetUtilizationForecasts))
    : [];

  if (cloneFleetUtilizationForecasts.length === 0) return [];

  const classGroups = cloneFleetUtilizationForecasts.map(
    ({ classGroupCode, displayText }) => ({
      [classGroupCode]: displayText,
    })
  );
  const forecastSupportData =
    fleetUtilizationSupportDataForExport?.updatedControls;
  let startDate = getCurrentServerDate(dateFormat);
  let endDate = moment(startDate)
    .add(numberOfDaysToExport - 1, "days")
    .format(dateFormat);

  if (IS_EXPORT_DISPLAYED_FLEET_UTILIZATION_DATA && forecastSupportData) {
    startDate = forecastSupportData.forecastStartDate || startDate;
    endDate = forecastSupportData.forecastEndDate || endDate;
    numberOfDaysToExport = moment(endDate).diff(moment(startDate), "days") + 1;
  }
  const forecastDates = getDateArrayFromDateAndDayLength(
    numberOfDaysToExport,
    moment(startDate),
    dateFormat
  );
  const uniqueClassGroups = sortArrayObjectByNumber(
    getUniqueObjects(classGroups)
  );
  uniqueClassGroups.forEach((group) => {
    const classGroup = Object.keys(group)[0];
    const classGroupDisplayName = group[classGroup];
    const groupWiseSearchData = new convertDataToCalenderFormat(
      classGroup,
      forecastDates,
      cloneFleetUtilizationForecasts,
      classGroupDisplayName
    );
    data.SearchList.push(groupWiseSearchData);
  });
  const mergedDataSet = data.SearchList.flat();

  return mergedDataSet;
};

const convertDataToCalenderFormat = function (
  classGroupId,
  forecastDates,
  fleetData,
  classGroupDisplayName
) {
  if (!fleetData) fleetData = [];
  const clonedFleetData = JSON.parse(JSON.stringify(fleetData));
  let classWiseFleetData =
    clonedFleetData.length > 0 &&
    clonedFleetData.filter(
      (entity, index) => entity.classGroupCode === classGroupId
    );
  const rowNames = getRowNames(classWiseFleetData);
  const classGroupAndParameterRows = createClassGroupAndParameterRows(
    classGroupId,
    [...rowNames]
  );
  const exportedList = [];
  const skippedColumn = SKIPPED_ITEM_IN_FLEET_DATA_EXPORT; // which are not required to export
  const percentageColumn = [
    "manualNoShowFactor",
    "noShowFactor",
    "defaultPickupThresholdPercent",
    "defaultBookThruThresholdPercent",
    "utilizationFactorPercent",
    "",
  ];
  classGroupAndParameterRows.forEach((element) => {
    const param = element[classGroupId];
    if (!skippedColumn.includes(param)) {
      const dateWiseClassGroupData = {};
      dateWiseClassGroupData["ClassGroup"] = classGroupDisplayName;
      dateWiseClassGroupData["Parameter"] = param;
      forecastDates.forEach((date) => {
        const classGroupsData =
          clonedFleetData.length > 0 &&
          clonedFleetData.filter(
            (entity) =>
              entity.classGroupCode === classGroupId &&
              entity.forecastDate === date
          );
        let colData = classGroupsData[0]?.[param] ?? null;
        if (["lastManualOverrideTimestamp", "lastTimestamp"].includes(param)) {
          colData = convertFoxApiDateToStr(
            colData,
            AdminDateFromat.COLUMN_FORMAT_WITH_TIME,
            ""
          );
        }
        if (param === "forecastDate") {
          colData = convertFoxApiDateToStr(
            colData,
            AdminDateFromat.RESERVE_SYNC_COLUMN_PERIOD_DATE_FORMAT,
            ""
          );
        }
        if (percentageColumn.includes(param)) {
          colData = isNullOrEmpty(colData) ? "0%" : `${colData}%`;
        }
        dateWiseClassGroupData[moment(date).format("MM/DD/YYYY ddd")] = colData; //make date column with formate
      });
      exportedList.push({ ...dateWiseClassGroupData });
    }
  });
  return exportedList;
};
//get all the Parameters for the Fleet Utilization data from the response date
const getRowNames = (classWiseFleetData) => {
  let columnNameList = [];
  classWiseFleetData.forEach((element) => {
    Object.keys(element).map((key, index) => {
      if (!columnNameList.includes(key)) {
        columnNameList.push(key);
      }
    });
  });
  return columnNameList;
};

const createClassGroupAndParameterRows = (classGroupId, rowNames) => {
  const lastRow = "utilizationFactorPercent";

  // Create the list of parameter rows
  const classGroupAndParameterRowList = rowNames.map((element) => ({
    [classGroupId]: element,
  }));

  // Move "utilizationFactorPercent" to the end if it exists
  const indexToRemove = classGroupAndParameterRowList.findIndex(
    (obj) => obj[classGroupId] === lastRow
  );

  if (indexToRemove !== -1) {
    const [removedElement] = classGroupAndParameterRowList.splice(
      indexToRemove,
      1
    );
    classGroupAndParameterRowList.push(removedElement);
  }

  return classGroupAndParameterRowList;
};

const getUniqueColumn = (dataSet) => {
  let columns = [];
  //pick all column from all row data
  if (dataSet != null && dataSet != undefined) {
    if (dataSet.length > 0) {
      dataSet.map((key, index) => {
        let columnHeader = Object.keys(dataSet[index]);
        columnHeader.map((key, index) => {
          columns.push(key);
        });
      });
    }
  }
  //removed duplicate rows
  const uniqueColumns = columns.filter((val, id, array) => {
    return array.indexOf(val) == id;
  });
  return uniqueColumns;
};

import * as actionTypes from "../actions/actionTypesSettings";
import { reducerUpdateObject } from "./reducer.utility";
const initialState = {
    error: null,
    loading: false,
    rateLoading: false,
    foxAllRateResponse: null,
    foxSpecificRateResponse: null,
    foxAddOnsResponse: null,
    foxCreateReservationResponse: null,
    foxGetReservationResponse:null,
    foxCancelReservationResponse:null,
}

const doFoxAPIStart = (state) => {
    return reducerUpdateObject(state, { error: null, loading: true });
};
const doFoxAPIFail = (state, action) => {
    return reducerUpdateObject(state, { error: action.error, loading: false });
};
/** Get Rates for FOX*/
const getAllRatesForFoxStart = (state) => {
    return reducerUpdateObject(state, { error: null, rateLoading: true });
};
export const getAllRatesForFoxSuccess = (state, action) => {
    return reducerUpdateObject(state, {
        foxAllRateResponse: action.foxAllRateResponse,
        error: null,
        rateLoading: false,
    });
};
const getRatesForFoxReset = (state, action) => {
    return reducerUpdateObject(state, {
        foxAllRateResponse: null,
    });
};
/** Get Specific Rates for FOX*/
export const getSpecificRatesForFoxSuccess = (state, action) => {
    return reducerUpdateObject(state, {
        foxSpecificRateResponse: action.foxSpecificRateResponse,
        error: null,
        loading: false,
    });
};
const getSpecificRatesForFoxReset = (state, action) => {
    return reducerUpdateObject(state, {
        foxSpecificRateResponse: null,
    });
};
/** Get AddOns for FOX*/
export const getAddOnsForFoxSuccess = (state, action) => {
    return reducerUpdateObject(state, {
        foxAddOnsResponse: action.foxAddOnsResponse,
        error: null,
        loading: false,
    });
};
const getAddOnsForFoxReset = (state, action) => {
    return reducerUpdateObject(state, {
        foxAddOnsResponse: null,
    });
};
/** Create Reservation for FOX*/
export const createReservationForFoxSuccess = (state, action) => {
    return reducerUpdateObject(state, {
        foxCreateReservationResponse: action.foxCreateReservationResponse,
        error: null,
        loading: false,
    });
};
const createReservationForFoxReset = (state, action) => {
    return reducerUpdateObject(state, {
        foxCreateReservationResponse: null,
    });
};
/** Get Reservation for FOX*/
export const getReservationForFoxSuccess = (state, action) => {
    return reducerUpdateObject(state, {
        foxGetReservationResponse: action.foxGetReservationResponse,
        error: null,
        loading: false,
    });
};
export const getReservationForFoxReset = (state, action) => {
    return reducerUpdateObject(state, {
        foxGetReservationResponse: null,
    });
};
/** Cancel Reservation for FOX*/
export const cancelReservationForFoxSuccess = (state, action) => {
    return reducerUpdateObject(state, {
        foxCancelReservationResponse: action.foxCancelReservationResponse,
        error: null,
        loading: false,
    });
};
const cancelReservationForFoxReset = (state, action) => {
    return reducerUpdateObject(state, {
        foxCancelReservationResponse: null,
    });
};
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_ALL_RATES_FOX_API_START:
            return getAllRatesForFoxStart(state);
        case actionTypes.GET_ALL_RATES_FOX_API_SUCCESS:
            return getAllRatesForFoxSuccess(state, action);
        case actionTypes.GET_ALL_RATES_FOX_API_FAIL:
            return doFoxAPIFail(state, action);
        case actionTypes.GET_ALL_RATES_FOX_API_RESET:
            return getRatesForFoxReset(state);
        case actionTypes.GET_SPECIFIC_RATES_FOX_API_START:
            return doFoxAPIStart(state);
        case actionTypes.GET_SPECIFIC_RATES_FOX_API_SUCCESS:
            return getSpecificRatesForFoxSuccess(state, action);
        case actionTypes.GET_SPECIFIC_RATES_FOX_API_FAIL:
            return doFoxAPIFail(state, action);
        case actionTypes.GET_SPECIFIC_RATES_FOX_API_RESET:
            return getSpecificRatesForFoxReset(state);
        case actionTypes.GET_ADDONS_FOX_API_START:
            return doFoxAPIStart(state);
        case actionTypes.GET_ADDONS_FOX_API_SUCCESS:
            return getAddOnsForFoxSuccess(state, action);
        case actionTypes.GET_ADDONS_FOX_API_FAIL:
            return doFoxAPIFail(state, action);
        case actionTypes.GET_ADDONS_FOX_API_RESET:
            return getAddOnsForFoxReset(state);
        case actionTypes.CREATE_RESERVATION_FOX_API_START:
            return doFoxAPIStart(state);
        case actionTypes.CREATE_RESERVATION_FOX_API_SUCCESS:
            return createReservationForFoxSuccess(state, action);
        case actionTypes.CREATE_RESERVATION_FOX_API_FAIL:
            return doFoxAPIFail(state, action);
        case actionTypes.CREATE_RESERVATION_FOX_API_RESET:
            return createReservationForFoxReset(state);
        case actionTypes.GET_RESERVATION_FOX_API_START:
            return doFoxAPIStart(state);
        case actionTypes.GET_RESERVATION_FOX_API_SUCCESS:
            return getReservationForFoxSuccess(state, action);
        case actionTypes.GET_RESERVATION_FOX_API_FAIL:
            return doFoxAPIFail(state, action);
        case actionTypes.GET_RESERVATION_FOX_API_RESET:
            return getReservationForFoxReset(state);
        case actionTypes.CANCEL_RESERVATION_FOX_API_START:
            return doFoxAPIStart(state);
        case actionTypes.CANCEL_RESERVATION_FOX_API_SUCCESS:
            return cancelReservationForFoxSuccess(state, action);
        case actionTypes.CANCEL_RESERVATION_FOX_API_FAIL:
            return doFoxAPIFail(state, action);
        case actionTypes.CANCEL_RESERVATION_FOX_API_RESET:
            return cancelReservationForFoxReset(state);
        default:
            return state;
    }
}
export default reducer;
import React from 'react';
import Image from '../../../components/UI/image/image';
import { Position } from '../../../shared/utility/enum-utility';

function CommonImageTitle({ title, classNames, imgSrc, imageClass, defaultImage,
    iconPosition = Position.LEFT, onClick }) {
    return (
        <div className='title-container'>
            {iconPosition === Position.LEFT ?
                <Image
                    url={imgSrc}
                    altText={title}
                    defaultImage={defaultImage}
                    imageClass={imageClass || "image-circle "}
                /> : ""}
            <span className={classNames || 'font-bold'} onClick={onClick}>{title}</span>
            {iconPosition === Position.RIGHT ?
                <Image
                    url={imgSrc}
                    altText={title}
                    defaultImage={defaultImage}
                    imageClass={imageClass || "image-circle "}
                /> : ""}
        </div>
    )
}

export default CommonImageTitle
import PageInfo from "../../services/entities/models/PageInfo";
import { getCurrentServerTimezone } from "../../shared/utility";
export const getSearchMessageCtrls = () => {
  return {
    PageInfo: new PageInfo(null, null, null, null),
    StartDate: {
      id: "StartDate",
      elementType: "datepicker",
      elementConfig: {
        name: "StartDate",
        type: "text",
      },
      validation: {
        required: false,
      },
      labelInput: {
        labeltext: "Search Begin",
      },
      value: "",
      timeZoneString: getCurrentServerTimezone(),
      valid: false,
      touched: false,
      basicOrder: 1,
      advancedOrder: 0,
    },
    EndDate: {
      id: "EndDate",
      elementType: "datepicker",
      elementConfig: {
        name: "EndDate",
        type: "text",
      },
      labelInput: {
        labeltext: "Search End",
      },
      value: "",
      timeZoneString: getCurrentServerTimezone(),
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      basicOrder: 2,
      advancedOrder: 0,
    },
    buttons: [
      {
        id: "btnResSearch",
        elementType: "button",
        elementConfig: {
          type: "submit",
          value: "Search",
          name: "btnResSearch",
          className: "btn btn-primary search-btn",
        },
        basicOrder: 3,
        advancedOrder: 0,
      },
      {
        id: "btnResSearchReset",
        elementType: "button",
        elementConfig: {
          type: "button",
          value: "Reset",
          name: "btnResSearchReset",
          className: "btn btn-secondary search-btn",
        },
        basicOrder: 4,
        advancedOrder: 0,
      },
    ],
  };
};

import * as actionTypes from "./actionTypes";
import HttpService from "../../services/httpService";

export const getVehicleCategoryInfoStart = () => {
  return {
    type: actionTypes.GET_VEHICLE_CATEGORY_START,
  };
};
export const getVehicleCategoryInfoSuccess = (response) => {
  return {
    type: actionTypes.GET_VEHICLE_CATEGORY_SUCCESS,
    vehicleCategoryResponse: response,
  };
};
export const getVehicleCategoryInfoFail = (error) => {
  return {
    type: actionTypes.GET_VEHICLE_CATEGORY_FAIL,
    error: error,
  };
};
export const getVehicleCategoryInfoReset = () => {
  return {
    type: actionTypes.GET_VEHICLE_CATEGORY_RESET,
  };
};
export const addOrEditVehicleStart = () => {
  return {
    type: actionTypes.UPDATE_VEHICLE_START,
  };
};
export const addOrEditVehicleSuccess = (response) => {
  return {
    type: actionTypes.UPDATE_VEHICLE_SUCCESS,
    updateVehicleResponse: response,
  };
};
export const addOrEditVehicleFail = (error) => {
  return {
    type: actionTypes.UPDATE_VEHICLE_FAIL,
    error: error,
  };
};
export const addOrEditVehicleReset = () => {
  return {
    type: actionTypes.UPDATE_VEHICLE_RESET,
  };
};
export const doVehicleSearchStart = () => {
  return {
    type: actionTypes.SEARCH_VEHICLE_START,
  };
};
export const doVehicleSearchSuccess = (response) => {
  return {
    type: actionTypes.SEARCH_VEHICLE_SUCCESS,
    searchVehicleResponse: response,
  };
};
export const doVehicleSearchFail = (error) => {
  return {
    type: actionTypes.SEARCH_VEHICLE_FAIL,
    error: error,
  };
};
export const doVehicleSearchReset = () => {
  return {
    type: actionTypes.SEARCH_VEHICLE_RESET,
  };
};
export const getVehicleDetailsStart = () => {
  return {
    type: actionTypes.GET_VEHICLE_DETAILS_START,
  };
};
export const getVehicleDetailsSuccess = (response) => {
  return {
    type: actionTypes.GET_VEHICLE_DETAILS_SUCCESS,
    vehicleDetailsResponse: response,
  };
};
export const getVehicleDetailsFail = (error) => {
  return {
    type: actionTypes.GET_VEHICLE_DETAILS_FAIL,
    error: error,
  };
};
export const getVehicleDetailsReset = () => {
  return {
    type: actionTypes.GET_VEHICLE_DETAILS_RESET,
  };
};

export const getSuggestedPriceforVehicleStart = () => {
  return {
    type: actionTypes.GET_VEHICLE_SUGGESTED_PRICE_START,
  };
};
export const getSuggestedPriceforVehicleSuccess = (response) => {
  return {
    type: actionTypes.GET_VEHICLE_SUGGESTED_PRICE_SUCCESS,
    vehicleSuggestedPriceResponse: response,
  };
};
export const getSuggestedPriceforVehicleFail = (error) => {
  return {
    type: actionTypes.GET_VEHICLE_SUGGESTED_PRICE_FAIL,
    error: error,
  };
};
export const getSuggestedPriceforVehicleReset = () => {
  return {
    type: actionTypes.GET_VEHICLE_SUGGESTED_PRICE_RESET,
  };
};
export const getVehicleFeatureOptionsStart = () => {
  return {
    type: actionTypes.GET_VEHICLE_FEATURES_OPTIONS_START,
  };
};
export const getVehicleFeatureOptionsSuccess = (response) => {
  return {
    type: actionTypes.GET_VEHICLE_FEATURES_OPTIONS_SUCCESS,
    vehicleFeatureOptionsResponse: response,
  };
};
export const getVehicleFeatureOptionsFail = (error) => {
  return {
    type: actionTypes.GET_VEHICLE_FEATURES_OPTIONS_FAIL,
    error: error,
  };
};
export const getVehicleFeatureOptionsReset = () => {
  return {
    type: actionTypes.GET_VEHICLE_FEATURES_OPTIONS_RESET,
  };
};
export const getVehicleGroupFeatureOptionsStart = () => {
  return {
    type: actionTypes.GET_VEHICLE_GROUP_FEATURES_START,
  };
};
export const getVehicleGroupFeatureOptionsSuccess = (response) => {
  return {
    type: actionTypes.GET_VEHICLE_GROUP_FEATURES_SUCCESS,
    vehicleGroupFeatureOptionsResponse: response,
  };
};
export const getVehicleGroupFeatureOptionsFail = (error) => {
  return {
    type: actionTypes.GET_VEHICLE_GROUP_FEATURES_FAIL,
    error: error,
  };
};
export const getVehicleGroupFeatureOptionsReset = () => {
  return {
    type: actionTypes.GET_VEHICLE_GROUP_FEATURES_RESET,
  };
};

export const getVehicleFeatureStart = () => {
  return {
    type: actionTypes.GET_VEHICLE_FEATURES_START,
  };
};
export const getVehicleFeatureSuccess = (response) => {
  return {
    type: actionTypes.GET_VEHICLE_FEATURES_SUCCESS,
    vehicleFeatureResponse: response,
  };
};
export const getVehicleFeatureFail = (error) => {
  return {
    type: actionTypes.GET_VEHICLE_FEATURES_FAIL,
    error: error,
  };
};
export const getVehicleFeatureReset = () => {
  return {
    type: actionTypes.GET_VEHICLE_FEATURES_RESET,
  };
};

export const updateVehicleFeatureStart = () => {
  return {
    type: actionTypes.UPDATE_VEHICLE_FEATURES_START,
  };
};
export const updateVehicleFeatureSuccess = (response) => {
  return {
    type: actionTypes.UPDATE_VEHICLE_FEATURES_SUCCESS,
    updateVehicleFeatureResponse: response,
  };
};
export const updateVehicleFeatureFail = (error) => {
  return {
    type: actionTypes.UPDATE_VEHICLE_FEATURES_FAIL,
    error: error,
  };
};
export const updateVehicleFeatureReset = () => {
  return {
    type: actionTypes.UPDATE_VEHICLE_FEATURES_RESET,
  };
};

export const markedVehicleOfflineStart = () => {
  return {
    type: actionTypes.MARK_VEHICLE_OFFLINE_START,
  };
};
export const markedVehicleOfflineSuccess = (response) => {
  return {
    type: actionTypes.MARK_VEHICLE_OFFLINE_SUCCESS,
    markedVehicleOfflineResponse: response,
  };
};
export const markedVehicleOfflineFail = (error) => {
  return {
    type: actionTypes.MARK_VEHICLE_OFFLINE_FAIL,
    error: error,
  };
};
export const markedVehicleOfflineReset = () => {
  return {
    type: actionTypes.MARK_VEHICLE_OFFLINE_RESET,
  };
};

export const updateVehicleModeStart = () => {
  return {
    type: actionTypes.UPDATE_VEHICLE_MODE_START,
  };
};
export const updateVehicleModeSuccess = (response) => {
  return {
    type: actionTypes.UPDATE_VEHICLE_MODE_SUCCESS,
    updateVehicleModeResponse: response,
  };
};
export const updateVehicleModeFail = (error) => {
  return {
    type: actionTypes.UPDATE_VEHICLE_MODE_FAIL,
    error: error,
  };
};
export const updateVehicleModeReset = () => {
  return {
    type: actionTypes.UPDATE_VEHICLE_MODE_RESET,
  };
};

export const deleteVehicleStart = () => {
  return {
    type: actionTypes.DELETE_VEHICLE_START,
  };
};
export const deleteVehicleSuccess = (response) => {
  return {
    type: actionTypes.DELETE_VEHICLE_SUCCESS,
    deleteVehicleResponse: response,
  };
};
export const deleteVehicleFail = (error) => {
  return {
    type: actionTypes.DELETE_VEHICLE_FAIL,
    error: error,
  };
};
export const deleteVehicleReset = () => {
  return {
    type: actionTypes.DELETE_VEHICLE_RESET,
  };
};

export const getVehicleCategoryInfo = (req) => {
  return (dispatch) => {
    dispatch(getVehicleCategoryInfoStart());
    HttpService.getVehicleCategoryInfo(req)
      .then((response) => {
        dispatch(getVehicleCategoryInfoSuccess(response));
      })
      .catch((err) => {
        //dispatch(getVehicleCategoryInfoFail(err));
      });
  };
};
export const addOrEditVehicle = (req, isEditMode) => {
  return (dispatch) => {
    dispatch(addOrEditVehicleStart());
    if (isEditMode) {
      HttpService.updateVehicle(req)
        .then((response) => {
          dispatch(addOrEditVehicleSuccess(response));
        })
        .catch((err) => {
          //dispatch(addOrEditVehicleFail(err));
        });
    } else {
      HttpService.addVehicle(req)
        .then((response) => {
          dispatch(addOrEditVehicleSuccess(response));
        })
        .catch((err) => {
          //dispatch(addOrEditVehicleFail(err));
        });
    }
  };
};
export const doVehicleSearch = (searchVehicleReq) => {
  return (dispatch) => {
    dispatch(doVehicleSearchStart());
    HttpService.doVehicleSearch(searchVehicleReq)
      .then((response) => {
        dispatch(doVehicleSearchSuccess(response));
      })
      .catch((err) => {
        //dispatch(doVehicleSearchFail(err));
      });
  };
};
export const getVehicleDetails = (searchVehicleReq) => {
  return (dispatch) => {
    dispatch(getVehicleDetailsStart());
    HttpService.getVehicleDetails(searchVehicleReq)
      .then((response) => {
        dispatch(getVehicleDetailsSuccess(response));
      })
      .catch((err) => {
        //dispatch(getVehicleDetailsFail(err));
      });
  };
};
export const getSuggestedPriceforVehicle = (req) => {
  return (dispatch) => {
    dispatch(getSuggestedPriceforVehicleStart());
    HttpService.getSuggestedPriceforVehicle(req)
      .then((response) => {
        dispatch(getSuggestedPriceforVehicleSuccess(response));
      })
      .catch((err) => {
        //dispatch(getSuggestedPriceforVehicleFail(err));
      });
  };
};
export const getVehicleFeatureOptions = (req) => {
  return (dispatch) => {
    dispatch(getVehicleFeatureOptionsStart());
    HttpService.getVehicleFeatureOptions(req)
      .then((response) => {
        dispatch(getVehicleFeatureOptionsSuccess(response));
      })
      .catch((err) => {
        //dispatch(getVehicleFeatureOptionsFail(err));
      });
  };
};
export const getVehicleGroupFeatureOptions = (req) => {
  return (dispatch) => {
    dispatch(getVehicleGroupFeatureOptionsStart());
    HttpService.getVehicleGroupFeatureOptions(req)
      .then((response) => {
        dispatch(getVehicleGroupFeatureOptionsSuccess(response));
      })
      .catch((err) => {
        //dispatch(getVehicleGroupFeatureOptionsFail(err));
      });
  };
};
export const getVehicleFeatures = (req) => {
  return (dispatch) => {
    dispatch(getVehicleFeatureStart());
    HttpService.getVehicleFeatures(req)
      .then((response) => {
        dispatch(getVehicleFeatureSuccess(response));
      })
      .catch((err) => {
        //dispatch(getVehicleFeatureFail(err));
      });
  };
};
export const updateVehicleFeatures = (req) => {
  return (dispatch) => {
    dispatch(updateVehicleFeatureStart());
    HttpService.updateVehicleFeatures(req)
      .then((response) => {
        dispatch(updateVehicleFeatureSuccess(response));
      })
      .catch((err) => {
        //dispatch(updateVehicleFeatureFail(err));
      });
  };
};
export const markedVehicleOffline = (req) => {
  return (dispatch) => {
    dispatch(markedVehicleOfflineStart());
    HttpService.markedVehicleOffline(req)
      .then((response) => {
        dispatch(markedVehicleOfflineSuccess(response));
      })
      .catch((err) => {
        //dispatch(markedVehicleOfflineFail(err));
      });
  };
};
export const updateVehicleMode = (req) => {
  return (dispatch) => {
    dispatch(updateVehicleModeStart());
    HttpService.updateVehicleMode(req)
      .then((response) => {
        dispatch(updateVehicleModeSuccess(response));
      })
      .catch((err) => {
        //dispatch(updateVehicleModeFail(err));
      });
  };
};

export const deleteVehicle = (req) => {
  return (dispatch) => {
    dispatch(deleteVehicleStart());
    HttpService.deleteVehicle(req)
      .then((response) => {
        dispatch(deleteVehicleSuccess(response));
      })
      .catch((err) => {
        //dispatch(deleteVehicleFail(err));
      });
  };
};

import React, { Component, Fragment } from "react";
import Draggable from "react-draggable";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import FeedbackUI from "../details/feedback-ui";

const FeedbackInfoModal = props => {
  return (
    <Draggable handle=".cursor">
      <Modal
        isOpen={props.showModal}
        className="no-cursor"
        toggle={() => {
          props.modalClose();
        }}
        style={{
          minWidth: "800px"
        }}
      >
        <ModalHeader
          className="modal-header bg-primary modal-title text-white cursor"
          toggle={() => {
            props.modalClose();
          }}
        >
          <i className="fas fa-comments" aria-hidden="true" /> Feedback
          Information
        </ModalHeader>
        <ModalBody
          style={{
            height: props.height + "px"
          }}
        >
          <div className="formRow">
            <div className="col-lg-12">
              <FeedbackUI
                feedbackQuestions={props.feedbackQuestions}
                feedbackData={props.feedBackaData}
                isDisabled={props.isDisabled}
                ratingTitle={"Rating Information"}
              />
            </div>
          </div>
        </ModalBody>
      </Modal>
    </Draggable>
  );
};
export default FeedbackInfoModal;

import { invokeServerAPI } from "../../services/httpService";
import * as actionTypes from "./actionTypes";
export const getSecurityDepositChargesStart = () => {
  return {
    type: actionTypes.SECURITY_DEPOSIT_POLICY_START,
  };
};
export const getSecurityDepositChargesSuccess = (response) => {
  return {
    type: actionTypes.SECURITY_DEPOSIT_POLICY_SUCCESS,
    securityDepositResponse: response,
  };
};
export const getSecurityDepositChargesFail = (error) => {
  return {
    type: actionTypes.SECURITY_DEPOSIT_POLICY_FAIL,
    error: error,
  };
};
export const getSecurityDepositChargesReset = () => {
  return {
    type: actionTypes.SECURITY_DEPOSIT_POLICY_RESET,
  };
};
export const getSecurityDepositCharges = (req) => {
  return (dispatch) => {
    dispatch(getSecurityDepositChargesStart());
    invokeServerAPI("foxapi/securitydeposit", req, true)
      .then((response) => {
        dispatch(getSecurityDepositChargesSuccess(response));
      })
      .catch((err) => {
        //dispatch(getSecurityDepositChargesFail(err));
      });
  };
};

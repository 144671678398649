import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DropdownList } from "react-widgets";
import { RESERVE_SYNC_AUTO_REFRESH_TIME } from "../../../../config/config";
import FoxReservationDefaultPage from "../../../components/common/pages/fox-res-default-page";
import RocksPeriodicDropDown, { getPeriodicList } from "../../../components/dropdown/rocks-periodic-drop-down";
import RocksWidgetDropDown from "../../../components/dropdown/rocks-widget-drop-down";
import ExportModal from "../../../components/export_comp/export-modal";
import CommonButton from "../../../components/UI/button/common-button";
import Spinner from "../../../components/UI/Spinner/Spinner";
import { showNotification } from "../../../components/UI/Toaster/Toaster";
import { FoxRequestHeader } from "../../../services/entities/fox-api/domain/fox-request-header";
import { FleetUtilizationForecast } from "../../../services/entities/models/reserve-sync/fleet-utilization-forecast";
import ReserveSyncModal from "../../../services/entities/models/reserve-sync/reserve-sync-modal";
import ReserveSyncSearchRequest from "../../../services/entities/models/reserve-sync/reserve-sync-search-request";
import { CALENDAR_ICON, CAR_ICON, CSV_ICON, LOC_ICON, LOCATION_05_GRAY, NO_CAR_INFO, PDF_ICON, REFRESH_DARK, XLS_ICON } from "../../../shared/AppImages";
import { ADMIN_PAGE, COMMON_MSG, ModalType, TOAST_MSG } from "../../../shared/GlobalVar";
import { getDataFromStorage, storeRequest } from "../../../shared/LocalStorage";
import PermissionName from "../../../shared/Permissions";
import {
    activeCurrentLink,
    clearActiveMenu,
    getBasicToastResponse,
    getDisplayTextOfDropdown,
    getErrorToastData,
    hasPermission,
    isNullOrEmpty,
    toTitleCase,
    updateObject,
} from "../../../shared/utility";
import { getTimezomeByLocationId, gettimeZoneWiseCurrentStartDateEndate } from "../../../shared/utility/date-time-utility";
import { AdminDateFromat, FleetParametersDisplayValue, ReserveSyncDefaultVal, ReserveSyncOverrideParam } from "../../../shared/utility/enum-utility";
import { getKeyValFromList, getValueFromList } from "../../../shared/utility/list-utility";
import { doSorting } from "../../../shared/utility/sorting-utility";
import {
    getFleetClassGroup,
    getFleetClassGroupReset,
    getFleetOverrideReasons,
    getFleetOverrideReasonsReset,
    getSearchResult,
    getSearchResultReset,
    updateFleetClassGroup,
    updateFleetClassGroupReset,
    updateFleetUtilizationForecast,
    updateFleetUtilizationForecastReset,
} from "../../../store/actions";
import { convertMsgResponse } from "../../Reservation/fox-reservation/fox-res-helper";
import { getSearchAPIName } from "../../search/search-utility/search-utility";
import { getExportTypeList, getFleetCellData, getOverrideReasonsByParameter, getOverrideReasonTooltip, getParameterCellClassNames, getUpdatedOverrideManualValue, hasSpecificEditPermission, isFleetCellEditable, renderReserveSyncParameter } from "../fleet-utilization-forecast-helper";
import "../fleet-utilization.scss";
import OverrideDefaultReserveSyncModal from "../modal/override-default-reserve-sync-modal";
import OverrideManualReserveSyncModal from "../modal/override-manual-reserve-sync-modal";
import ReserveSyncActivityLogsModal from "../modal/reserve-sync-activity-logs-modal";

export const ReserveSync = (props) => {
    const intervalId = useRef(true);
    const loading = useSelector((state) => state.fleetUtilizationForecastReducer.loading || state.searchPagesReducer.loading || state.adminOptionReducer.loading);
    const dependantOrgList = useSelector((state) =>
        state.auth.loggedInUserData.DependantLocations.length > 0
            ? state.auth.loggedInUserData.DependantLocations
            : []
    );
    const searchDataResponse = useSelector(
        (state) => state.searchPagesReducer.searchDataResponse || null
    );
    const updateFleetForecastResponse = useSelector(
        (state) => state.fleetUtilizationForecastReducer.updateFleetForecastResponse
    );
    const updateFleetClassGroupReponse = useSelector(
        (state) => state.fleetUtilizationForecastReducer.updateFleetClassGroupReponse
    );
    const orgUnitFleetClassGroupsResponse = useSelector(
        (state) => state.adminOptionReducer.orgUnitFleetClassGroupsResponse || null
    );
    const fleetOverrideReasonsResponse = useSelector(
        (state) => state.adminOptionReducer.fleetOverrideReasonsResponse || null
    );
    const dispatch = useDispatch();

    const [reserveSyncSearchRequest, setReserveSyncSearchRequest] = useState(new ReserveSyncSearchRequest());
    const [reserveSyncSearchRequestForExport, setReserveSyncSearchRequestForExport] = useState(new ReserveSyncSearchRequest());
    const [searchData, setSearchData] = useState([]);
    const [exportedData, setExportedData] = useState(null);
    const [orgUnitFleetClassGroupsList, setOrgUnitFleetClassGroupsList] = useState([]);
    const [autoLocationList, setAutoLocationList] = useState([]);
    const [reserveSyncModalData, setReserveSyncModalData] = useState(
        new ReserveSyncModal()
    );
    const [cellData, setCellData] = useState({});
    const [prevSelectedInputName, setPrevSelectedInputName] = useState(null);
    const hasDefaultThresholdViewPermission = hasPermission(PermissionName.CC_GET_ORG_UNIT_FLEET_CLASS_GROUP)
    const has_ViewPermission_Fleet_Utilization_Logs = hasPermission(PermissionName.CC_SEARCH_FLEET_UTILIZATION_LOGS)
    const initiateExportInfo = {
        showExportModal: false,
        exportType: { key: '', value: "Select" },
        locationName: "",
        pageName: ADMIN_PAGE.RESERVE_SYNC_PAGE,
    };
    const [exportModalInfo, setExportModalInfo] = useState(initiateExportInfo);
    const [isExportDefaultAll, setIsExportDefaultAll] = useState(true);

    useEffect(() => {
        if (!fleetOverrideReasonsResponse) {
            getManualFleetOverrideReasons();
        }
        setAutoLocationDropdowns();
        clearActiveMenu();
        activeCurrentLink("reserveSyncMenu");
        const soredReserveSyncRequest = getDataFromStorage(ADMIN_PAGE.RESERVE_SYNC_PAGE);
        if (soredReserveSyncRequest) {
            const timeZone = getTimezomeByLocationId(reserveSyncSearchRequest.locationId, dependantOrgList, true);
            const preioDicList = getPeriodicList(ReserveSyncDefaultVal.totalDaysPerPeriod, ReserveSyncDefaultVal.totalDays, timeZone);
            if (preioDicList && preioDicList.length > 0) {
                const storeVal = soredReserveSyncRequest.forecastStartDate + "-" + soredReserveSyncRequest.forecastEndDate;
                const currentSelectedPeriod = preioDicList.find((element) => element.key === storeVal);
                if (currentSelectedPeriod === null) {
                    const perioDicData = preioDicList[0];
                    soredReserveSyncRequest.forecastStartDate = perioDicData.forecastStartDate;
                    soredReserveSyncRequest.forecastEndDate = perioDicData.forecastEndDate;
                }
                getReserveSyncDataList(soredReserveSyncRequest);
                getFleetClassGroups(soredReserveSyncRequest.locationId);
            }
        }
        resetReserveSyncData(soredReserveSyncRequest);
    }, []);
    useEffect(() => {
        if (!orgUnitFleetClassGroupsResponse) {
            return;
        }
        if (orgUnitFleetClassGroupsResponse.message && orgUnitFleetClassGroupsResponse.message.messageType === "SUCCESS") {
            setOrgUnitFleetClassGroupsList(orgUnitFleetClassGroupsResponse.orgUnitFleetClassGroups);

        } else {
            showNotification(convertMsgResponse(orgUnitFleetClassGroupsResponse.message));
            dispatch(getFleetClassGroupReset());
        }
    }, [orgUnitFleetClassGroupsResponse]);
    useEffect(() => {
        if (!searchDataResponse) {
            return;
        }
        if (
            searchDataResponse &&
            searchDataResponse.StatusCode === 0
        ) {
            if (searchDataResponse && exportModalInfo.showExportModal) {
                if (searchDataResponse.TotalRecords) {
                    setExportedData(searchDataResponse.SearchList);
                } else {
                    showNotification(getErrorToastData(COMMON_MSG.NoRecordFound));
                    dispatch(getSearchResultReset());
                    setExportedData(null);
                    setExportModalInfo(initiateExportInfo);
                }
            } else {
                if (prevSelectedInputName && reserveSyncModalData.parameter) {
                    setCellData({ ...cellData, [prevSelectedInputName]: { inputClassName: "hidden", lblClassName: "show", showEditCtrl: false } });
                    closeModal();
                }
                setSearchData(searchDataResponse.SearchList || []);
                if (exportedData || exportModalInfo.showExportModal) {
                    setExportedData(null);
                    setExportModalInfo(initiateExportInfo);
                }
                intervalId.current = setInterval(() => {
                    if (reserveSyncSearchRequest.locationId > 0) {
                        clearInterval(intervalId.current);
                        getReserveSyncDataList(reserveSyncSearchRequest, true);

                    }
                }, RESERVE_SYNC_AUTO_REFRESH_TIME);
                return () => clearInterval(intervalId.current);
            }
        } else {
            if (searchDataResponse.StatusCode > 0) {
                showNotification(getBasicToastResponse(searchDataResponse));
            }
            dispatch(getSearchResultReset());
        }
    }, [searchDataResponse]);
    useEffect(() => {
        if (searchData && searchData.length > 0) {
            setSearchData(searchData);
        }
    }, [searchData]);
    useEffect(() => {
        if (!isNullOrEmpty(exportModalInfo.exportType && exportModalInfo.exportType.key)) {
            openExportModal(exportModalInfo.exportType);
        }
    }, [exportModalInfo.exportType]);
    useEffect(() => {
        setExportModalInfo(initiateExportInfo);
    }, [isExportDefaultAll]);
    useEffect(() => {
        if (exportModalInfo.showExportModal) {
            const clonedReserveSyncSearchRequest = JSON.parse(JSON.stringify(reserveSyncSearchRequest))
            if (isExportDefaultAll) {
                const timeZoneData = gettimeZoneWiseCurrentStartDateEndate(reserveSyncSearchRequest.locationId, dependantOrgList, ReserveSyncDefaultVal.totalDays - 1, true, AdminDateFromat.FOX_API_ONLY_DATE);
                clonedReserveSyncSearchRequest.forecastStartDate = timeZoneData.formattedStartDate;
                clonedReserveSyncSearchRequest.forecastEndDate = timeZoneData.formattedEndDate;
            }
            getReserveSyncDataList(clonedReserveSyncSearchRequest);
            setReserveSyncSearchRequestForExport(clonedReserveSyncSearchRequest)
        }
    }, [exportModalInfo.showExportModal]);
    useEffect(() => {
        if (!updateFleetForecastResponse) {
            return;
        }
        showNotification(convertMsgResponse(updateFleetForecastResponse.message));
        if (updateFleetForecastResponse.message &&
            updateFleetForecastResponse.message.messageType === "SUCCESS") {
            /**TO DO: need to update celldata with updated search result need a close look */
            if (prevSelectedInputName && reserveSyncModalData.parameter) {
                setCellData({ ...cellData, [prevSelectedInputName]: { value: getUpdatedOverrideManualValue(reserveSyncModalData.parameter, updateFleetForecastResponse.fleetUtilizationForecast), inputClassName: "show", lblClassName: "hidden", showEditCtrl: true } });
            }
            // closeModal();
            clearInterval(intervalId.current);
            dispatch(updateFleetUtilizationForecastReset());
            getReserveSyncDataList(reserveSyncSearchRequest);
        }
        dispatch(updateFleetUtilizationForecastReset());
    }, [updateFleetForecastResponse]);
    useEffect(() => {
        if (!updateFleetClassGroupReponse) {
            return;
        }
        showNotification(convertMsgResponse(updateFleetClassGroupReponse.message));
        if (updateFleetClassGroupReponse.message &&
            updateFleetClassGroupReponse.message.messageType === "SUCCESS") {
            clearInterval(intervalId.current);
            closeModal();
            dispatch(updateFleetClassGroupReset());
            getReserveSyncDataList(reserveSyncSearchRequest);
        }
        dispatch(updateFleetClassGroupReset());
    }, [updateFleetClassGroupReponse]);

    /**API section */
    const getManualFleetOverrideReasons = (locationId) => {
        const req = {};
        req.fleetManualOverrideReasonTypes = true;
        dispatch(getFleetOverrideReasonsReset());
        dispatch(getFleetOverrideReasons(req));
    };
    const getFleetClassGroups = (locationId) => {
        const req = {};
        req.fleetClassGroupOrgUnitId = locationId
            ? parseInt(locationId)
            : localStorage.getItem("currentOrgUnitId");
        dispatch(getFleetClassGroupReset());
        dispatch(getFleetClassGroup(req));
    };

    const updateDefaultForecastByClassGroup = (reqObj) => {

        dispatch(updateFleetClassGroupReset());
        dispatch(updateFleetClassGroup(reqObj));
    }
    const updateManualFleetutilizationForecastData = (reqObj) => {
        dispatch(updateFleetUtilizationForecastReset());
        dispatch(updateFleetUtilizationForecast(reqObj));
    }
    const isFilterCtrlChanged = (tempSearchRequest) => {
        if (reserveSyncSearchRequest.locationId !== tempSearchRequest.locationId
            || reserveSyncSearchRequest.orgUnitFleetClassGroupId !== tempSearchRequest.orgUnitFleetClassGroupId) {
            return true;

        }
        return false;

    }
    const isFilterChanged = (tempSearchRequest) => {
        if (reserveSyncSearchRequest.locationId !== tempSearchRequest.locationId
            || reserveSyncSearchRequest.forecastStartDate !== tempSearchRequest.forecastStartDate
            || reserveSyncSearchRequest.forecastEndDate !== tempSearchRequest.forecastEndDate
            || reserveSyncSearchRequest.orgUnitFleetClassGroupId !== tempSearchRequest.orgUnitFleetClassGroupId) {
            return true;

        }
        return false;

    }
    /**handle search list */
    const handleOnChange = (data, controlName) => {
        const tempSearchRequest = { ...reserveSyncSearchRequest };
        if (controlName === "location") {
            const timeZoneData = gettimeZoneWiseCurrentStartDateEndate(
                data.key,
                dependantOrgList,
                ReserveSyncDefaultVal.totalDaysPerPeriod - 1,
                true,
                AdminDateFromat.FOX_API_ONLY_DATE
            );
            tempSearchRequest.locationId = data.key;
            tempSearchRequest.orgUnitFleetClassGroupId = null;
            tempSearchRequest.forecastStartDate = timeZoneData.formattedStartDate;
            tempSearchRequest.forecastEndDate = timeZoneData.formattedEndDate;
            clearInterval(intervalId.current);
            if (isFilterCtrlChanged(tempSearchRequest)) {
                onResetReserveSyncDataForLocation();
                getFleetClassGroups(data.key);
            }
            getReserveSyncDataList(tempSearchRequest);
            setReserveSyncSearchRequest(tempSearchRequest);
        }
        else if (controlName === "fleetCarClassGroup") {
            if (tempSearchRequest.locationId) {
                tempSearchRequest.orgUnitFleetClassGroupId = data.key || null;
                clearInterval(intervalId.current);
                getReserveSyncDataList(tempSearchRequest);
                setReserveSyncSearchRequest(tempSearchRequest);
            }
            else {
                showNotification({
                    msg: "A location is must required !!!",
                    toastType: TOAST_MSG.WARNING,
                });
            }
        }
        else if (controlName === "periodicCtrl") {
            const tempSearchRequest = { ...reserveSyncSearchRequest };
            if (reserveSyncSearchRequest.locationId) {
                clearInterval(intervalId.current);
                tempSearchRequest.forecastStartDate = data.forecastStartDate;
                tempSearchRequest.forecastEndDate = data.forecastEndDate;
                tempSearchRequest.locationId = reserveSyncSearchRequest.locationId;
                if (reserveSyncSearchRequest.orgUnitFleetClassGroupId) {
                    tempSearchRequest.orgUnitFleetClassGroupId = reserveSyncSearchRequest.orgUnitFleetClassGroupId;
                }
                if (isFilterChanged(tempSearchRequest)) {
                    clearInterval(intervalId.current);
                    getReserveSyncDataList(tempSearchRequest);
                }
                setReserveSyncSearchRequest(tempSearchRequest);
            } else {
                showNotification({
                    msg: "A location is must required !!!",
                    toastType: TOAST_MSG.WARNING,
                });
            }
        }
    };
    const getReserveSyncDataList = (request, noValidation) => {
        if (!request.locationId || !request.forecastStartDate || !request.forecastEndDate) {
            clearInterval(intervalId.current);
            if (!noValidation) {
                showNotification({
                    msg: "Required data is missing !!!",
                    toastType: TOAST_MSG.WARNING,
                });
            }
            return false;
        }
        request.requestHeader = new FoxRequestHeader({ requestType: "GET" });
        if (!exportModalInfo.showExportModal) {
            storeRequest(
                ADMIN_PAGE.RESERVE_SYNC_PAGE,
                request
            );
        }
        dispatch(getSearchResultReset());
        dispatch(
            getSearchResult(getSearchAPIName(ADMIN_PAGE.RESERVE_SYNC_PAGE), request, true)
        );
    }
    const onRefreshReserveSyncSearchData = () => {
        const tempSearchRequest = { ...reserveSyncSearchRequest };
        tempSearchRequest.locationId = reserveSyncSearchRequest.locationId;
        if (reserveSyncSearchRequest.orgUnitFleetClassGroupId) {
            tempSearchRequest.orgUnitFleetClassGroupId = reserveSyncSearchRequest.orgUnitFleetClassGroupId;
        }
        clearInterval(intervalId.current);
        getReserveSyncDataList(tempSearchRequest);
        setReserveSyncSearchRequest(tempSearchRequest);
    }
    const resetReserveSyncData = (soredReserveSyncRequest) => {
        clearInterval(intervalId.current);
        setReserveSyncSearchRequest(new ReserveSyncSearchRequest(soredReserveSyncRequest || null));
        setSearchData([]);
        setCellData({});
        setPrevSelectedInputName(null);
        setOrgUnitFleetClassGroupsList([]);
        if (!soredReserveSyncRequest) {
            dispatch(getSearchResultReset());
            dispatch(getFleetClassGroupReset());
        }
    }
    const onResetReserveSyncDataForLocation = () => {
        clearInterval(intervalId.current);
        setSearchData([]);
        setCellData({});
        setPrevSelectedInputName(null);
        setOrgUnitFleetClassGroupsList([]);
    }
    /**End API section */
    /**modal section */
    const showModal = (currentModal, currentVal, modalData, parameter, isReset) => {
        clearInterval(intervalId.current);
        setReserveSyncModalData({
            manualVal: currentVal || getUpdatedOverrideManualValue(parameter, modalData),
            selectedData: currentModal === ModalType.RESERVE_SYNC_OVERRIDE_DEFAULT_MODAL ? currentVal : new FleetUtilizationForecast(modalData),
            overrideReasons: parameter ? getOverrideReasonsByParameter(parameter, fleetOverrideReasonsResponse) : [],
            parameter: parameter,
            currentModal: currentModal,
            isReset: isReset,
        });
    }
    const closeModal = () => {
        clearInterval(intervalId.current);
        setReserveSyncModalData({
            selectedData: new FleetUtilizationForecast(),
            overrideReasons: [],
            currentModal: null,
        });
    };
    const openExportModal = (exportTypeKeyVal) => {
        const locationName =
            reserveSyncSearchRequest &&
            getDisplayTextOfDropdown(
                autoLocationList || [],
                reserveSyncSearchRequest.locationId,
                "key",
                "value"
            );
        if (
            exportModalInfo &&
            exportModalInfo.showExportModal &&
            exportModalInfo.exportType && exportModalInfo.exportType.key
        ) {
            setExportedData(null);
            setExportModalInfo(updateObject({ ...exportModalInfo }, {
                locationName: locationName,
            }))
        } else {
            setExportModalInfo(updateObject({ ...exportModalInfo }, {
                showExportModal: true,
                //exportType: {...exportTypeKeyVal},
                locationName: locationName,
            }))
        }
    };
    const closeExportModal = () => {
        setExportedData(null);
        setExportModalInfo(initiateExportInfo);
    };
    /**end modal section */
    /**UI utility */
    const setAutoLocationDropdowns = () => {
        const location = [];
        dependantOrgList.map((data, i) => {
            location.push({ key: data.key, value: data.locationCode + " - " + data.name });
        });
        doSorting({ DataType: "string", SortType: 'ASC', SortBy: "value" }, location);
        location.concat({ key: "", value: "Select a location" });
        setAutoLocationList(location);
    }
    const handleTableCellClick = (e, inputName, value) => {
        if (e.detail === 2) {
            clearInterval(intervalId.current);
            const cData = { ...cellData };
            if (prevSelectedInputName) {
                cData[prevSelectedInputName].inputClassName = "hidden";
                cData[prevSelectedInputName].lblClassName = "show";
                cData[prevSelectedInputName].showEditCtrl = false;
            }
            setPrevSelectedInputName(inputName);
            setCellData({ ...cData, [inputName]: { value: value, inputClassName: "show right", lblClassName: "hidden", showEditCtrl: true } });
        }
    };
    const onChangeCellInput = (e) => {
        clearInterval(intervalId.current);
        setCellData({ ...cellData, [e.target.name]: { value: e.target.value, inputClassName: "show", lblClassName: "hidden", showEditCtrl: true } });
    };
    const onToggleEditCtrl = (inputName, value) => {
        clearInterval(intervalId.current);
        setCellData({ ...cellData, [inputName]: { value: value, inputClassName: "hidden", lblClassName: "show", showEditCtrl: false } });
    }

    const getExportTypeImage = ({ item }) => (
        <>
            {item.key ?
                <img
                    src={item.key === "CSV" ? CSV_ICON : item.key === "XLS" ? XLS_ICON : item.key === "PDF" ? PDF_ICON : ""}
                    alt={item.value}
                    className="card-img-top"
                    style={{ height: "15px", width: "15px" }}
                /> : ""}
            <label className="ml-2"> {item.value}</label>
        </>
    );
    /**End UI utility */
    /**Main UI */
    const getDateWiseTableHeader = () => {
        const timeZoneData = gettimeZoneWiseCurrentStartDateEndate(reserveSyncSearchRequest.locationId, dependantOrgList, ReserveSyncDefaultVal.totalDaysPerPeriod, true);

        const pStartDate = reserveSyncSearchRequest.forecastStartDate ? moment(reserveSyncSearchRequest.forecastStartDate, AdminDateFromat.FOX_API_ONLY_DATE) : moment(timeZoneData.currentStartDate);
        const pEndDate = pStartDate ? moment(pStartDate).add(ReserveSyncDefaultVal.totalDaysPerPeriod, "days") : moment(timeZoneData.currentEndDate);
        const headerRows = [];
        headerRows.push(<th className="car-group-col-width text-left">Car Class Group</th>);
        headerRows.push(<th className="param-col-width text-left">Parameter</th>);
        for (var m = moment(pStartDate); m.isBefore(pEndDate); m.add(1, 'days')) {
            const selectedDIndex = m.format(AdminDateFromat.RESERVE_SYNC_COLUMN_PERIOD_DATE_FORMAT);
            headerRows.push(<th className="date-col-width text-right">{selectedDIndex}</th>);
        }
        return <tr>{headerRows}</tr>;
    }

    const getDateWiseTableRows = (classGroup, parameter) => {
        const timeZoneData = gettimeZoneWiseCurrentStartDateEndate(reserveSyncSearchRequest.locationId, dependantOrgList, ReserveSyncDefaultVal.totalDaysPerPeriod, true);
        const pStartDate = reserveSyncSearchRequest.forecastStartDate ? moment(reserveSyncSearchRequest.forecastStartDate, AdminDateFromat.FOX_API_ONLY_DATE) : moment(timeZoneData.currentStartDate);
        const pEndDate = pStartDate ? moment(pStartDate).add(ReserveSyncDefaultVal.totalDaysPerPeriod, "days") : moment(timeZoneData.currentEndDate);
        const isEditable = isFleetCellEditable(parameter);
        const bodyRows = [];
        if (parameter === "dropOffCount") {
            bodyRows.push(<td rowSpan={13} className={hasDefaultThresholdViewPermission && "param-cell left"} onClick={() => hasDefaultThresholdViewPermission && showModal(ModalType.RESERVE_SYNC_OVERRIDE_DEFAULT_MODAL, classGroup)}>
                <div>
                    {classGroup.value}<br />
                    <i className={"fa fa-edit"} aria-hidden="true" title={!hasDefaultThresholdViewPermission ? " You do not have permission to edit default class group value" : "Edit default class group value"}></i>
                </div>
            </td>);
        }
        bodyRows.push(<td className={getParameterCellClassNames(parameter)}>{renderReserveSyncParameter(parameter, FleetParametersDisplayValue[parameter])}</td>);
        let i = 0;
        for (var m = moment(pStartDate); m.isBefore(pEndDate); m.add(1, 'days')) {
            const selectedDate = m.format(AdminDateFromat.FOX_API_ONLY_DATE);
            if (searchData.length > 0) {
                const selectedValue = searchData.find((element) => element.forecastDate === selectedDate && element.orgUnitFleetClassGroupId === parseInt(classGroup.key));
                if (selectedValue) {
                    const value = getUpdatedOverrideManualValue(parameter, selectedValue) ? getUpdatedOverrideManualValue(parameter, selectedValue) : selectedValue[parameter] ? selectedValue[parameter] : 0;
                    const selectedData = getFleetCellData(parameter, selectedValue, value, isEditable);
                    const inputName = classGroup.value + parameter + selectedDate;
                    bodyRows.push(
                        <td className={isEditable && cellData[inputName] && cellData[inputName].showEditCtrl ? selectedData.classNames + " border-edit-ctrl" : selectedData.classNames}>

                            <div className={"cell-container " + selectedData.tooltipClassNames || ""}>
                                <label
                                    className={cellData[inputName] ? cellData[inputName].lblClassName : parameter === "override" && selectedValue.manualOverrideReasonDisplayName ? has_ViewPermission_Fleet_Utilization_Logs ? "lbl-override" : "show" : "show"}
                                    title={isEditable ? hasSpecificEditPermission(parameter) ? "Double click to edit" : "You do not have permission to edit" : parameter === "override" && selectedValue.manualOverrideReasonDisplayName ? has_ViewPermission_Fleet_Utilization_Logs ? "Click to get activity logs" : "You do not have permission to view fleet utilization logs" : ""}
                                    onClick={isEditable ? (e) => { hasSpecificEditPermission(parameter) && handleTableCellClick(e, inputName, value) } : parameter === "override" && selectedValue.manualOverrideReasonDisplayName ? () => { has_ViewPermission_Fleet_Utilization_Logs && showModal(ModalType.RESERVE_SYNC_ACTIVITY_LOG_MODAL, null, selectedValue, parameter) } : undefined}
                                >{selectedData.cellValue}
                                </label>
                                {isEditable && selectedValue.lastManualOverrideTimestamp && selectedValue[ReserveSyncOverrideParam[parameter]] && (!cellData[inputName] || (cellData[inputName] && !cellData[inputName].showEditCtrl)) ?
                                    <span className="tooltiptext"> {getOverrideReasonTooltip(selectedValue)}</span>
                                    : ""}

                                {isEditable && selectedValue.lastManualOverrideTimestamp && selectedValue[ReserveSyncOverrideParam[parameter]] && (!cellData[inputName] || (cellData[inputName] && !cellData[inputName].showEditCtrl)) ? <i className="fa fa-undo" title={hasSpecificEditPermission(parameter) ? "Reset Override" : "You do not have permission to reset"} onClick={() => { hasSpecificEditPermission(parameter) && showModal(ModalType.RESERVE_SYNC_OVERRIDE_MANUAL_MODAL, null, selectedValue, parameter, true) }}></i> : ""}

                                {isEditable && cellData[inputName] && cellData[inputName].showEditCtrl ?
                                    <div className="edit-ctrl">
                                        <i className="fa fa-times-circle" onClick={() => { onToggleEditCtrl(inputName, value) }}></i>
                                        <i className="fas fa-check-circle" onClick={() => { showModal(ModalType.RESERVE_SYNC_OVERRIDE_MANUAL_MODAL, cellData[inputName].value, selectedValue, parameter) }}></i>
                                    </div>
                                    : ""}
                                <input type='number'
                                    name={inputName}
                                    value={cellData[inputName] ? cellData[inputName].value : ''}
                                    className={cellData[inputName] ? cellData[inputName].inputClassName : "hidden"}
                                    onChange={e => onChangeCellInput(e, selectedData)} />
                            </div>
                        </td>
                    );
                } else {
                    bodyRows.push(<td className="right">0</td>);
                }

            } else {
                bodyRows.push(<td className="right">0</td>);
            }
            i++;
        }
        return <tr>{bodyRows}</tr>;
    }
    const generateCalenderTableRows = () => {
        const tableRows = [];
        orgUnitFleetClassGroupsResponse && orgUnitFleetClassGroupsResponse.orgUnitFleetClassGroups.map((classGroup) => {
            if (reserveSyncSearchRequest.orgUnitFleetClassGroupId && reserveSyncSearchRequest.orgUnitFleetClassGroupId !== classGroup.key) {
                return;
            }
            const data = [];
            data.push(getDateWiseTableRows(classGroup, "dropOffCount"));
            data.push(getDateWiseTableRows(classGroup, "pickupCount"));
            data.push(getDateWiseTableRows(classGroup, "defaultPickupThresholdPercent"));
            data.push(getDateWiseTableRows(classGroup, "defaultBookThruThresholdPercent"));
            data.push(getDateWiseTableRows(classGroup, "noShowFactor"));
            data.push(getDateWiseTableRows(classGroup, "walkinsCount"));
            data.push(getDateWiseTableRows(classGroup, "runningReservationsCount"));
            data.push(getDateWiseTableRows(classGroup, "runningRentalsCount"));
            data.push(getDateWiseTableRows(classGroup, "usableFleetCount"));
            data.push(getDateWiseTableRows(classGroup, "availableFleetCount"));
            data.push(getDateWiseTableRows(classGroup, "eodAvailableFleetCount"));
            data.push(getDateWiseTableRows(classGroup, "override"));
            data.push(getDateWiseTableRows(classGroup, "utilizationFactorPercent"));
            tableRows.push(data);
        });
        return tableRows;
    }
    const getSelectedPeriodicKey = () => {
        if (reserveSyncSearchRequest.forecastStartDate && reserveSyncSearchRequest.forecastEndDate) {
            return reserveSyncSearchRequest.forecastStartDate + "-" + reserveSyncSearchRequest.forecastEndDate;
        }
        return null;
    }
    const getPeriodicDispalyVal = () => {
        if (reserveSyncSearchRequest.locationId && reserveSyncSearchRequest.forecastStartDate && reserveSyncSearchRequest.forecastEndDate) {
            return moment(reserveSyncSearchRequest.forecastStartDate, AdminDateFromat.FOX_API_ONLY_DATE).format(AdminDateFromat.RESERVE_SYNC_COLUMN_PERIOD_DATE_FORMAT) + " - " + moment(reserveSyncSearchRequest.forecastEndDate, AdminDateFromat.FOX_API_ONLY_DATE).format(AdminDateFromat.RESERVE_SYNC_COLUMN_PERIOD_DATE_FORMAT);
        }
        return "--";
    }
    const renderCalendarLayout = () => {
        if (reserveSyncSearchRequest.locationId && searchData && searchData.length > 0) {
            return (
                <table className="table table-striped" id="table-fllet-forecast">
                    <thead className="thead-primary">
                        {getDateWiseTableHeader()}
                    </thead>
                    <tbody>{generateCalenderTableRows()}</tbody>
                </table>
            );
        }
    }
    const renderMainFilterLayout = () => {
        return (
            <div className="rs-top-filter">
                <span className="feature-lbl vertical-align-center">Reserve Sync</span>
                <div className="rs-top-filter-ctrl-container">
                    <RocksWidgetDropDown
                        controllerName="location"
                        palceHolder="Location"
                        iconClass="fa fa-map-marker"
                        imgUrl={LOC_ICON}
                        selectedKey={reserveSyncSearchRequest.locationId}
                        defaultKeyVal={{ key: '', value: 'Select Location' }}
                        dataList={autoLocationList || []}
                        handleOnChange={handleOnChange}
                        iconSize={{ height: "20px", width: "20px" }}
                    />
                    <RocksWidgetDropDown
                        controllerName="fleetCarClassGroup"
                        palceHolder="Car Class Group"
                        iconClass="fa fa-car"
                        imgUrl={CAR_ICON}
                        selectedKey={reserveSyncSearchRequest.orgUnitFleetClassGroupId}
                        defaultKeyVal={{ key: '', value: reserveSyncSearchRequest.locationId ? "Select Car Class Group" : "Select a Location First" }}
                        dataList={orgUnitFleetClassGroupsList && orgUnitFleetClassGroupsList.length > 0 ? [{ key: '', value: 'All' }, ...orgUnitFleetClassGroupsList] : []}
                        handleOnChange={handleOnChange}
                        iconSize={{ height: "20px", width: "20px" }}
                    />

                    <div className="cancel-changes">
                        <CommonButton
                            buttonClasses={"btn btn-outline-danger"}
                            onClickAction={resetReserveSyncData}
                            buttonLabel={"Clear Search"}
                        />
                    </div>
                </div>
            </div>
        )
    }

    const renderSearchCriteria = () => {
        return (
            <div className="search-criteria-container">
                <label className="font-bold">Search Crieteria :</label>
                <span><label className="font-bold">Location : </label><label>{getValueFromList(autoLocationList, reserveSyncSearchRequest.locationId, "--")}</label></span>
                <span className="dot"></span>
                <span><label className="font-bold">Car Class Grooup :</label> <label>{getValueFromList(orgUnitFleetClassGroupsList, reserveSyncSearchRequest.orgUnitFleetClassGroupId, reserveSyncSearchRequest.locationId ? "All" : "--")}</label></span>
                <span className="dot"></span>
                <span><label className="font-bold">Period :</label> <label>{getPeriodicDispalyVal()}</label></span>
            </div>
        )
    }
    const renderSecondaryFilter = (isDisabled) => {
        return (
            <div className="secondary-filter-container">
                <div className="secondary-filter">
                    <div className="d-flex" style={{ gap: "1.6rem" }}>
                        <div
                            className="action-button refresh-icon"
                            title={"Refresh Data"}
                            onClick={() => onRefreshReserveSyncSearchData(reserveSyncSearchRequest)}
                        >
                            <img
                                className="refresh-icon"
                                src={REFRESH_DARK}
                                height={20}
                                width={20}
                                alt={"refersh"}
                            />
                        </div>
                    </div>
                    <RocksPeriodicDropDown
                        perPageCount={ReserveSyncDefaultVal.totalDaysPerPeriod}
                        totalDays={ReserveSyncDefaultVal.totalDays}
                        imgUrl={CALENDAR_ICON}
                        selectedKey={getSelectedPeriodicKey()}
                        handleOnChange={handleOnChange}
                        timeZone={getTimezomeByLocationId(reserveSyncSearchRequest.locationId, dependantOrgList, true)}
                        isDisabled={isDisabled}
                    />

                </div>
                <div className="legend-export">
                    <div className="legend-container">
                        <div className="star-container">
                            <i className="fa fa-star" aria-hidden="true"></i>
                            <label>Editable</label>
                        </div>
                        <div className="accepting-reservation">
                            <div></div>
                            <label>Accepting Reservation</label></div>
                        <div className="review-reservation"><div></div><label>Review</label></div>
                        <div className="close-reservation"><div></div><label>Close Reservation</label></div>
                    </div>
                    <div className="exportDiv">
                        <label className="ctrl-lbl">Export: </label>
                        <div className="icheck-primary">
                            <input
                                name="cbSelectConflicted"
                                type="checkbox"
                                id="cb-all-ninty-days"
                                checked={isExportDefaultAll}
                                onChange={() => setIsExportDefaultAll(!isExportDefaultAll)}
                                disabled={isDisabled}
                            />
                            <label htmlFor="cb-all-ninty-days">
                                All 90 Days
                            </label>
                        </div>
                        <label className="ctrl-lbl">Export Type: </label>
                        <div className="export-type-container">
                            <DropdownList
                                data={getExportTypeList()}
                                valueField="key"
                                textField="value"
                                itemComponent={getExportTypeImage}
                                value={getKeyValFromList(getExportTypeList(), exportModalInfo.exportType.key, { key: "", value: "Select" })}
                                onChange={value => setExportModalInfo(updateObject({ ...exportModalInfo }, {
                                    exportType: value,
                                }))}
                                disabled={isDisabled}
                            />
                        </div>
                    </div>
                </div>

            </div>
        );
    }
    const renderNoFleetDataFound = (isNoLocationSelected) => (
        <div className="horizontal-align-center">
            <FoxReservationDefaultPage
                messageType={TOAST_MSG.WARNING}
                iconContent={isNoLocationSelected ? LOCATION_05_GRAY : NO_CAR_INFO}
                title={isNoLocationSelected ? "No Location Selected" : "No Data Found"}
                msg={isNoLocationSelected ? COMMON_MSG.ReserveSyncInitialMessage : COMMON_MSG.ReserveSyncNoRecordFoundMessage}
                customParentClass="horizontal-align-center w-17"
                customerClassMessageTitle="w-auto mbr-4"
                customClassMessage="text-center"
            />
        </div>
    );
    return (<>

        {renderMainFilterLayout()}
        <hr />
        {renderSearchCriteria()}
        {renderSecondaryFilter(!reserveSyncSearchRequest.locationId)}
        {!reserveSyncSearchRequest.locationId ? renderNoFleetDataFound(true) :
            <>
                <div div className="reserve-sync-grid-container">
                    {searchData?.length === 0 ? renderNoFleetDataFound() : renderCalendarLayout()}
                </div>

            </>
        }
        {loading ? <Spinner /> : ""}
        {
            reserveSyncModalData && reserveSyncModalData.currentModal === ModalType.RESERVE_SYNC_OVERRIDE_DEFAULT_MODAL ? (
                <OverrideDefaultReserveSyncModal
                    showModal={reserveSyncModalData.currentModal === ModalType.RESERVE_SYNC_OVERRIDE_DEFAULT_MODAL ? true : false}
                    closeModal={closeModal}
                    classGroup={reserveSyncModalData.selectedData}
                    updateDefaultForecastByClassGroup={updateDefaultForecastByClassGroup}
                />

            ) : (
                ""
            )
        }
        {
            reserveSyncModalData && reserveSyncModalData.currentModal === ModalType.RESERVE_SYNC_OVERRIDE_MANUAL_MODAL ? (
                <OverrideManualReserveSyncModal
                    showModal={reserveSyncModalData.currentModal === ModalType.RESERVE_SYNC_OVERRIDE_MANUAL_MODAL ? true : false}
                    closeModal={closeModal}
                    manualVal={reserveSyncModalData.manualVal}
                    selectedData={reserveSyncModalData.selectedData}
                    parameter={reserveSyncModalData.parameter}
                    overrideReasons={reserveSyncModalData.overrideReasons}
                    isReset={reserveSyncModalData.isReset}
                    updateManualFleetutilizationForecastData={updateManualFleetutilizationForecastData}
                />

            ) : (
                ""
            )
        }
        {
            reserveSyncModalData && reserveSyncModalData.currentModal === ModalType.RESERVE_SYNC_ACTIVITY_LOG_MODAL ? (
                <ReserveSyncActivityLogsModal
                    showModal={reserveSyncModalData.currentModal === ModalType.RESERVE_SYNC_ACTIVITY_LOG_MODAL ? true : false}
                    closeModal={closeModal}
                    selectedData={reserveSyncModalData.selectedData}
                />

            ) : (
                ""
            )
        }
        {exportModalInfo.showExportModal && exportModalInfo.exportType?.key &&
            exportedData &&
            exportedData.length > 0 ? (
            <ExportModal
                dataSet={exportedData || []}
                showModal={exportModalInfo.showExportModal}
                closeModal={closeExportModal}
                title="Download"
                height="auto"
                width={"auto"}
                exportType={exportModalInfo.exportType.key}
                pageName={exportModalInfo.pageName}
                fileName={
                    toTitleCase(
                        (exportModalInfo
                            ? exportModalInfo.locationName.split(
                                "/"
                            )[0] + "_"
                            : "") +
                        exportModalInfo.pageName
                            .split("_")
                            .slice(0, exportModalInfo.pageName.split("_").length - 1)
                            .join("_")
                    ) +
                    "_" +
                    moment().format("YYYYMMDDhhmmss")
                }
                fleetUtilizationSupportDataForExport={{
                    updatedControls: reserveSyncSearchRequestForExport || null,
                }}
            />
        ) : (
            ""
        )}
    </>
    );
};
export default ReserveSync;

import React, { useEffect, useState } from 'react';
import { Utility } from '../../../shared/utility';
import { CountryKeyValueType, DefaultAddressProperty } from '../../../shared/utility/enum-utility';
import { getKeyValFromList } from '../../../shared/utility/list-utility';
import InputCtrl from '../../UI/Input/input-ctrl';
import InputWidgetCtrl from '../../UI/Input/input-widget-ctrl';

function StateCtrl(props) {
    const { name, value, defaultValue, placeholder, disabled, zipToAddressData, imgIco, countryCode,
        addressData,
        addressType,
        stateCityLoader,
        onChange,
        clearSecurityDepositRate } = props;
    const [states, setStates] = useState([]);
    const [loader, setLoader] = useState(false);
    useEffect(() => {
        if (countryCode === CountryKeyValueType.US || countryCode === CountryKeyValueType.IN) {
            loadStates();
        } else {
            setStates([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [countryCode, zipToAddressData])

    /**TO DO: need to change country sotrage and data array list process */
    const loadStates = async (state) => {
        try {
            setLoader(true);
            Utility.countryStateCityPostal.getStates(
                countryCode,
                true,
                (stateResponse) => {
                    setStates(stateResponse.States);
                    setLoader(false);
                }
            );
        } catch (e) {
            setLoader(false);
        }
    }
    const onHandleChange = (event) => {
        if (disabled && !onChange) return false;
        const val = event.keyValue;
        if (addressData && addressType) {
            const tempData = { ...addressData };
            tempData[name] = val;
            tempData[DefaultAddressProperty?.city] = null;
            tempData[DefaultAddressProperty?.postalCode] = null;
            onChange(tempData, addressType);
        }
        else {
            if (clearSecurityDepositRate)
                clearSecurityDepositRate();
            onChange(val, name);
        }
    }
    return (
        <div>
            {states?.length > 0 ?
                <InputWidgetCtrl
                    id={name}
                    name={name}
                    placeholder={placeholder}
                    data={states}
                    valueField="keyValue"
                    textField="displayValue"
                    value={getKeyValFromList(
                        states,
                        value,
                        defaultValue,
                        "keyValue"
                    )}
                    onChange={(event) =>
                        onChange ? onHandleChange(event) : ""
                    }
                    disabled={disabled}
                    busy={loader}
                    imgIco={imgIco}
                /> :
                <InputCtrl
                    type="text"
                    name={name}
                    value={value}
                    placeholder={placeholder}
                    onChange={onChange}
                    disabled={disabled}
                    imgIco={imgIco}
                    loader={stateCityLoader}
                />
            }
        </div>
    )
}

export default StateCtrl
const SubMenuItem = function (
  itemKey,
  itemLabel,
  iconClass,
  componentName,
  isActive,
  paramsList,
  msg
) {
  this.itemKey = itemKey || null;
  this.itemLabel = itemLabel || null;
  this.iconClass = iconClass || null;
  this.componentName = componentName || null;
  this.isActive = isActive || false;
  this.params = paramsList ? [...paramsList] : [];
  this.msg = msg;
};
export default SubMenuItem;

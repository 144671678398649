import React from 'react';
import { formatCurrency } from '../../../../shared/utility';

function RateRow({ rateData, showUnit = false }) {
    const subtotal = rateData?.subtotal < 0 ? `(${formatCurrency(rateData?.subtotal * (-1))})` : formatCurrency(rateData?.subtotal);
    return (
        <div className='rate-row'>
            <div className='col-lg-4 rate-lbl'>{rateData?.title}</div>
            {showUnit ? <div className='col-lg-4 text-right'>{formatCurrency(rateData?.rate)}</div> : ""}
            <div className='col-lg-4 text-right text-rate'>{subtotal}</div>
        </div>
    )
}

export default RateRow
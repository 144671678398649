import Address from "../models/Address";
const PaymentInfoRequest = function() {
  this.SameAsProfileNameAndAddress = null;
  this.IntegRecordedBy = null;
  this.IntegRecordedByName = null;
  this.ChargeViaGateway = null;
  this.BillingName = null;
  this.CardType = null;
  this.AccountNumber = null;
  this.CVVCode = null;
  this.ExpirationMonth = null;
  this.ExpirationYear = null;
  this.BankName = null;
  this.CheckNumber = null;
  this.PaymentMethod = "CREDIT_CARD";
  this.PaymentType = "RESERVATION";
  this.TransactionType = "PAYMENT";
  this.Reason = null;
  this.Amount = null;
  this.Notes = null;
  this.IsPaylater=null;
  this.Address = new Address();
};
export default PaymentInfoRequest;

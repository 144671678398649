import * as actionTypes from "./actionTypes";
import HttpService from "../../services/httpService";

export const getLocationDataStart = () => {
  return {
    type: actionTypes.GET_LOCATION_DATA_START
  };
};
export const getLocationDataSuccess = response => {
  return {
    type: actionTypes.GET_LOCATION_DATA_SUCCESS,
    locationData: response
  };
};
export const getLocationDataFail = error => {
  return {
    type: actionTypes.GET_LOCATION_DATA_FAIL,
    error: error
  };
};
export const getLocationDataReset = () => {
  return {
    type: actionTypes.GET_LOCATION_DATA_RESET
  };
};
export const getLocationData = req => {
  return async dispatch => {
    dispatch(getLocationDataStart());
    HttpService.getOrgLocationSettings(req)
      .then(response => {
        dispatch(getLocationDataSuccess(response));
      })
      .catch(err => {
        //dispatch(getLocationDataFail(err));
      });
  };
};

import React from 'react';

function TextAreaCtrl(props) {
    const {
        name,
        value,
        placeholder,
        onChange,
        disabled,
        iconClass,
        imgIco,
        onClear,
        defaultconfig,
        parentClass = 'input-ctrl'
    } = props;
    const onHandleOnChange = (e) => {
        onChange(e?.target?.value, name, e);
    }
    const onRemove = () => {
        onClear(name);
    }
    return (
        <div className={`${parentClass}`}>
            {imgIco ? <img
                src={imgIco}
                alt=""
                className={`${disabled ? 'filter-gray' : ""}`}
            /> : iconClass && <i className={iconClass} aria-hidden="true"></i>}
            {placeholder && <label className="place-holder">{placeholder}</label>}
            <textarea
                name={name}
                value={value || ""}
                onChange={onChange ? (e) => onHandleOnChange(e) : null}
                disabled={disabled}
                {...defaultconfig}
            />
            {value && onClear ? <i className="fas fa-times-circle fs-0 clear-icon" onClick={onRemove}></i> : ""}
        </div>
    )
}

export default TextAreaCtrl
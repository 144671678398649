import PageInfo from "../../../services/entities/models/PageInfo";

export const getSearchImplementationCtrls = () => {
  return {
    PageInfo: new PageInfo(null, null, "Name", "ASC"),
    StartDate: {
      id: "StartDate",
      elementType: "datepicker",
      elementConfig: {
        name: "StartDate",
        type: "text",
      },
      timePicker: {
        id: "StartTime",
        name: "StartTime",
        value: "",
        elementType: "timepicker",
      },
      validation: {
        required: false,
      },
      labelInput: {
        labeltext: "Start Date",
      },
      value: "",
      valid: false,
      touched: false,
      basicOrder: 1,
      advancedOrder: 0,
    },
    EndDate: {
      id: "EndDate",
      elementType: "datepicker",
      elementConfig: {
        name: "EndDate",
        type: "text",
      },
      timePicker: {
        id: "EndTime",
        name: "EndTime",
        value: "",
        elementType: "timepicker",
      },
      validation: {
        required: false,
      },
      labelInput: {
        labeltext: "End Date",
      },
      value: "",
      valid: false,
      touched: false,
      basicOrder: 2,
      advancedOrder: 0,
    },
    ClientName: {
      id: "ClientName",
      elementType: "input",
      elementConfig: {
        name: "ClientName",
        type: "text",
      },
      labelInput: {
        labeltext: "Client Name",
      },
      value: "",
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      basicOrder: 3,
      advancedOrder: 0,
    },
    buttons: [
      {
        id: "btnResSearch",
        elementType: "button",
        elementConfig: {
          type: "submit",
          value: "Search",
          name: "btnResSearch",
          className: "btn btn-primary search-btn cursor-default",
          disabled: "disabled",
        },
        basicOrder: 4,
        advancedOrder: 0,
      },
      {
        id: "btnResSearchReset",
        elementType: "button",
        elementConfig: {
          type: "button",
          value: "Reset",
          name: "btnResSearchReset",
          className: "btn btn-primary search-btn cursor-default",
          disabled: "disabled",
        },
        basicOrder: 5,
        advancedOrder: 0,
      },
    ],
  };
};

import { getMemberLabelByOrgRentalType } from "./utility/system-utility";

export const SERVER_TIMEZONE_STRING = "America/Los_Angeles";
export const MAX_EXPORT_RECORD = 10000;
export const MAX_ADDONS_CHILD_SEAT = 5;
export const MAX_ADDONS_ALLOWED = 20;
export const ADDONS_LIST_IS_FOR_SEAT = ["INFANT", "BOOSTER", "TODDLER"];
export const FOX_PARTNER_CODES = ["FOXWEB", "FOXADMIN", "FOXMOBILE", "FOXTEST"];
export const MAX_DAYS_FLEET_UTILIZATION_RECORD_EXPORT = 90;
export const IS_EXPORT_DISPLAYED_FLEET_UTILIZATION_DATA = true;
export const SKIPPED_ITEM_IN_FLEET_DATA_EXPORT = [
  "displayText",
  "fleetUtilizationForecastId",
  "classGroupCode",
  "orgUnitFleetClassGroupId",
  "manualOverrideReason",
];

export const RESERVATION_STATES_ALL =
  "PENDING,DECLINED,MESSAGE,CONFIRMED,NOT_STARTED,CANCELLED,INCOMPLETE,CONTRACT_PENDING,PAYMENT_PENDING,BACKGROUND_CHECK_PENDING,WEBERROR";
export const RESERVATION_STATES_RESET =
  "PENDING,CONFIRMED,NOT_STARTED,INCOMPLETE,BACKGROUND_CHECK_PENDING";
export const RENTAL_FILTER_STATES_ALL =
  "IN_PROGRESS,COMPLETED,LATE,RETURNED_LATE,CANCELLED";
export const BILLING_RESERVATION_STATES =
  "CREDITS,CONFIRMED,IN_PROGRESS,COMPLETED,CANCELLED,INCOMPLETE,NOT_STARTED,LATE,RETURNED_LATE,PAYMENT_PENDING,BACKGROUND_CHECK_PENDING,PENDING,WEBERROR";
export const SERVICE_AGENT_STATES_ALL =
  "ACTIVE,INACTIVE,HOLD,ASSIGNED,COMPLETED,OBSOLETE";
export const READY_LINE_AGENT_STATES_ALL = "AUDIT_OK,AUDIT_FAILED,NOT_AUDITED";
export const SERVICE_AGENT_STATES_RESET = "ACTIVE,HOLD,ASSIGNED";
export const READY_LINE_AGENT_STATES_RESET =
  "AUDIT_OK,AUDIT_FAILED,NOT_AUDITED";
export const MEMBER_RESERVATION_STATES_ALL =
  "PENDING,DECLINED,MESSAGE,CONFIRMED,NOT_STARTED,INCOMPLETE,CONTRACT_PENDING,PAYMENT_PENDING,BACKGROUND_CHECK_PENDING,WEBERROR,IN_PROGRESS,COMPLETED,LATE,RETURNED_LATE,CANCELLED";
export const MEMBER_RESERVATION_STATES_RESET =
  "PENDING,CONFIRMED,NOT_STARTED,INCOMPLETE,BACKGROUND_CHECK_PENDING,IN_PROGRESS,COMPLETED,LATE,RETURNED_LATE,CANCELLED";
export const REPORT_PROBLEM_STATES_ALL = "OPEN,CLOSE";

export const R_TYPE = "json";
export const VERSION = "1.0";
export const OK_LBL = "OK";
export const ADMIN_PAGE = {
  MEMBER_SEARCH_PAGE: "MEMBER_SEARCH_PAGE",
  VEHICLE_SEARCH_PAGE: "VEHICLE_SEARCH_PAGE",
  NEW_VEHICLE_PAGE: "NEW_VEHICLE_PAGE",
  VEHICLE_DETAILS: "VEHICLE_DETAILS",
  VEHICLE_AVAILABILITY: "VEHICLE_AVAILABILITY",
  RESERVATION_SEARCH_PAGE: "RESERVATION_SEARCH_PAGE",
  EDIT_NEW_RESERVATION_PAGE: "EDIT_NEW_RESERVATION_PAGE",
  RENTAL_SEARCH_PAGE: "RENTAL_SEARCH_PAGE",
  ROLES_PERMISSION_SEARCH_PAGE: "ROLES_PERMISSION_SEARCH_PAGE",
  PARTNER_INFO_PAGE: "PARTNER_INFO_PAGE",
  MEMBER_RESERVATION_SEARCH_PAGE: "MEMBER_RESERVATION_SEARCH_PAGE",
  MEMBER_VEHICLE_RESERVATION_SEARCH_PAGE:
    "MEMBER_VEHICLE_RESERVATION_SEARCH_PAGE",
  BILLING_SEARCH_PAGE: "BILLING_SEARCH_PAGE",
  COMPLAINTS_SEARCH_PAGE: "COMPLAINTS_SEARCH_PAGE",
  BIRDS_EYE_VIEW_SEARCH_PAGE: "BIRDS_EYE_VIEW_SEARCH_PAGE",
  IMPLEMENTATION_SEARCH_PAGE: "IMPLEMENTATION_SEARCH_PAGE",
  RES_DETAILS_BILLING_SEARCH_PAGE: "RES_DETAILS_BILLING_SEARCH_PAGE",
  MEMBER_BILLING_SEARCH_PAGE: "MEMBER_BILLING_SEARCH_PAGE",
  SERVICE_AGENT_SEARCH_PAGE: "SERVICE_AGENT_SEARCH_PAGE",
  READY_LINE_AGENT_SEARCH_PAGE: "READY_LINE_AGENT_SEARCH_PAGE",
  MECHANIC_APP_REPORT_SEARCH_PAGE: "MECHANIC_APP_REPORT_SEARCH_PAGE",
  MEMBER_VEHICLE_SEARCH: "MEMBER_VEHICLE_SEARCH",
  MEMBER_ACTIVITY_SEARCH_PAGE: "MEMBER_ACTIVITY_SEARCH_PAGE",
  MEMBER_MESSAGE_SEARCH_PAGE: "MEMBER_MESSAGE_SEARCH_PAGE",
  MEMBER_FAVORITE_ASSET_SEARCH_PAGE: "MEMBER_FAVORITE_ASSET_SEARCH_PAGE",
  MEMBER_FEEDBACK_SEARCH_PAGE: "MEMBER_FEEDBACK_SEARCH_PAGE",
  REPORT_PROBLEM_DETAILS_PAGE: "REPORT_PROBLEM_DETAILS_PAGE",
  RESERVATION_MESSAGE_SEARCH_PAGE: "RESERVATION_MESSAGE_SEARCH_PAGE",
  MAIN_SETTINGS_PAGE: "MAIN_SETTINGS_PAGE",
  HOURS_OF_OPERATIONS: "HOURS_OF_OPERATIONS",
  TAX_SURCHARGE_SETTINGS: "TAX_SURCHARGE_SETTINGS",
  QUALIFICATION_SETTINGS: "QUALIFICATION_SETTINGS",
  RESERVATION_SETTINGS: "RESERVATION_SETTINGS",
  INSURANCE_SETTINGS: "INSURANCE_SETTINGS",
  ADDITIONAL_OPTIONS_SETTINGS: "ADDITIONAL_OPTIONS_SETTINGS",
  LOCATION_SETTINGS: "LOCATION_SETTINGS",
  RATE_SETTINGS: "RATE_SETTINGS",
  CAPACITY_SETTINGS: "CAPACITY_SETTINGS",
  VEHICLE_AVAILABILITY_SETTINGS: "VEHICLE_AVAILABILITY_SETTINGS",
  ROLES_SETTINGS: "ROLES_SETTINGS",
  PERMISSION_SETTINGS: "PERMISSION_SETTINGS",
  MEMBER_PACKAGE_SEARCH_PAGE: "MEMBER_PACKAGE_SEARCH_PAGE",
  MEMBER_SUMMARY_PAGE: "MEMBER_SUMMARY_PAGE",
  MEMBER_PROFILE_PAGE: "MEMBER_PROFILE_PAGE",
  DO_NOT_RENT_LIST_SETTINGS: "DO_NOT_RENT_LIST_SETTINGS",
  END_RENTAL_PAGE: "END_RENTAL_PAGE",
  RES_DETAILS_OVERVIEW_PAGE: "RES_DETAILS_OVERVIEW_PAGE",
  RES_DETAILS_VEHICLE_DETAILS_PAGE: "RES_DETAILS_VEHICLE_DETAILS_PAGE",
  RES_DETAILS_RENTER_DETAILS_PAGE: "RES_DETAILS_RENTER_DETAILS_PAGE",
  RES_DETAILS_FEEDBACK_PAGE: "RES_DETAILS_FEEDBACK_PAGE",
  //Shuttle Admin Portal Pages
  SHUTTLE_SEARCH_PAGE: "SHUTTLE_SEARCH_PAGE",
  NEW_SHUTTLE_PAGE: "NEW_SHUTTLE_PAGE",
  LANDMARK_SEARCH_PAGE: "LANDMARK_SEARCH_PAGE",
  NEW_LANDMARK_PAGE: "NEW_LANDMARK_PAGE",
  SHUTTLE_TRIP_SEARCH_PAGE: "SHUTTLE_TRIP_SEARCH_PAGE",
  SHUTTLES_SETTINGS: "SHUTTLES_SETTINGS",
  RESERVE_SYNC_PAGE: "RESERVE_SYNC_PAGE",
};

export const PAGE_GROUP_TYPE = {
  PAGE_GROUP_RESERVATION: "RESERVATION",
  PAGE_GROUP_BILLING: "BILLING",
  PAGE_GROUP_VEHICLE: "VEHICLE",
  PAGE_GROUP_MEMBER: "MEMBER",
  PAGE_GROUP_ROLES_PERMISSIONS: "ROLES_PERMISSIONS",
  PAGE_GROUP_SHUTTLE: "SHUTTLE",
  PAGE_GROUP_LANDMARK: "LANDMARK",
  PAGE_GROUP_SHUTTLE_TRIP: "SHUTTLE_TRIP",
  PAGE_GROUP_SERVICE_MECHANIC: "SERVICE_MECHANIC",
  PAGE_GROUP_ACTIVITY: "ACTIVITY",
  PAGE_GROUP_NORMAL_SEARCH: "NORMAL_SEARCH",
  PAGE_GROUP_PREIODIC_SEARCH: "PREIODIC_SEARCH",
};

export const RESERVATION_PAGE = "RESERVATION_PAGE";
export const MEMBER_RESERVATION_PAGE = "MEMBER_RESERVATION_PAGE";
export const VEHICLE_RESERVATION_PAGE = "VEHICLE_RESERVATION_PAGE";
export const RENTAL_PAGE = "RENTAL_PAGE";
export const BILLING_PAGE = "BILLING_PAGE";
export const SERVICE_AGENT_REPORT_PAGE = "SERVICE_AGENT_REPORT_PAGE";
export const COUNTRY_ADDRESS_TYPE = "COUNTRY_ADDRESSTYPE";
export const COUNTRY_License_TYPE = "COUNTRY_License_TYPE";
export const COUNTRY_CreditCard_TYPE = "COUNTRY_CreditCard_TYPE";

export const STATE_ADDRESS_TYPE = "STATE_ADDRESS_TYPE";
export const STATE_License_TYPE = "STATE_License_TYPE";
export const STATE_CreditCard_TYPE = "STATE_CreditCard_TYPE";

export const CITY_ADDRESS_TYPE = "CITY_ADDRESS_TYPE";
export const CITY_CreditCard_TYPE = "CITY_CreditCard_TYPE";

export const POSTAL_ADDRESS_TYPE = "POSTAL_ADDRESS_TYPE";
export const POSTAL_License_TYPE = "POSTAL_License_TYPE";
export const POSTAL_CreditCard_TYPE = "POSTAL_CreditCard_TYPE";
export const TOAST_MSG = {
  SUCCESS: "SUCCESS",
  ERROR: "ERROR",
  INFO: "INFO",
  WARNING: "WARNING",
};
export const CALL_CENTER_APP_ID = "10002948857";

//later we need to refactor it
export const AcceptedCardType = {
  VISA: ["visa", "visa_electron"],
  AMEX: ["amex"],
  MASTERCARD: ["mastercard"],
  DISCOVER: ["discover"],
  MAESTRO: ["maestro"],
  DINERS: ["diners_club_carte_blanche", "diners_club_international"],
  JCB: ["jcb"],
};
export const ADMIN_COMMON_CAPTION = {
  CUSTOMER_ID: "ID/Rewards#",
  VERSION_TITLE: "Version #:",
  BUILD_TITLE_ID: "Build ID:",
  BUILD_DATE_TITLE: "Build Date:",
};
export const COMMON_MSG = {
  PermitNumber: "Permit Number",
  VehicleType: "Vehicle Type",
  Comments: "Comments",
  MessageFieldsRequired: " field(s) are required",
  MessageFillRequiredFields: "Please fill out all required fields: ",
  MessageFieldsRequiredForPayment:
    "Please fill out all required fields to make payment: ",
  FirstName: "First Name",
  LastName: "Last Name",
  Address1: "Address 1",
  Address2: "Address 2",
  Street: "Street",
  Street1: "Street 1",
  Street2: "Street 2",
  Country: "Country",
  State: "State",
  City: "City",
  ZipPostal: "Zip/Postal",
  ZipCode: "Zip Code",
  ValidZipCode: "Valid ZipCode",
  AddressType: "Address Type",
  MobilePhone: "Mobile Phone",
  EmailAddress: "Valid Email Address",
  LicenseCountry: "License Country",
  LicenseState: "License State",
  LicenseNo: "Driver's Lic",
  LicenseExpDate: "License Expiration Date",
  DOB: "Date of Birth",
  HintQuestion: "Hint Question",
  HintAnswer: "Hint Answer",
  PaymentMethod: "Payment Method",
  RefundReason: "Refund Reason",
  Required: "Required",
  InsuranceVerification:
    "To set insurance is verified you need to provide all insurance information!",
  ValidCCAddress: "Please select valid credit card address details",
  ValidCCDetails: "Please enter valid credit card details",
  DOBRequired: "Date of birth is required.",
  LicExpDateRequired: "Lic. Exp. Date is required.",
  ValidFreeCreditAmount: "Free credit should be equal or lesser than 100",
  InvalidAmount: "Invalid amount.",
  InvalidInput: "Invalid input.",
  ExportFilePopupHeaderConstraints: "select at least one header name",
  ExportFileDownloadLimit:
    "Downloading first 10000 records. If want more, change search criteria",

  NoReservation: "There is no reservation!",
  SelectAsset: "Please select a vehicle",
  SelectStartDate: "Please select start date",
  SelectEndDate: "Please select end date",
  InvalidDateFormat: "Date Format is not correct",
  StartDate_EndDate_Constraints:
    "Start date and time should be less than end date and time",
  StartDate_EndDate_Same: "Start date and time and end date and time are same",
  CreditAmountRequired: "Please enter credit amount",
  NumericValueRequired: "Please enter digits",
  AcceptTermsandConditions: "Please Accept Terms and Conditions",
  Pickupdate_Returndate_Constraints: "Return Time must be after Pickup Time.",
  FieldsRequiredForPayment: "field(s) are required to make payment",
  NoDetailsFound: "No details found",
  NoRecordFound: "No record found",
  FieldsRequiredToAddDriver: "field(s) are required to add driver",
  InVaildEmailAddress: "Email address is not valid.",
  InvalidLicenseNo: "License No. is not valid",
  Password: "Password",
  MemberNameValidationMessage: "Following characters are not allowed",
  //Phone validation
  InvalidMobileNo:
    "Please provide a valid mobile phone number and country code",
  InvalidHomeNo: "Please provide a valid home phone number and country code",
  InvalidWorkNo: "Please provide a valid work phone number and country code",
  MobilePhoneRequired: "Mobile Phone",
  WorkPhoneRequired: "Work Phone",
  HomePhoneRequired: "Home Phone",
  PrimaryPhoneType: "Primary Phone Type",
  DriveManualEnabled: "Drive Manual",

  IncompleteAddress:
    "Incomplete address information. You have to provide city and zipcode information also.",
  EnterDamageLocation: "Damage Location",
  InvalidZipCodeFormat: "Zip Code is not in right format",
  EnterValidDate: "Enter valid date for processing request",
  FieldsMandatoryToApproveLicense: "are mandatory for approving license",
  PARTIAL_LICENSE_REQ_MSG:
    "Partial driving license information entered. Enter complete information or remove all license information",

  ReservationAssetInfoNotFound:
    "Asset information not available for this reservation.",

  EnterEmailAddress: "Please enter valid email address.",
  NextReservationNotAvailable: "Next reservation not available",
  EnterYourLocation: "Please enter your location",
  EnterVehicleLocation: "Please enter vehicle location!",
  EnterCallerLocation: "Please enter caller location!",
  GeoCoderFailed: "Geocoder failed due to:",
  RateShouldGreaterThanZero: "Rate should be greater than 0",
  AssetIdRequired: "Asset Id is required!",
  EditVehicleRateChangesConfirmation: "Do you want to keep the current rates ?",
  Description: "Description",
  ViolationPoint: "Violation Point",
  //MainBilling.js
  AtleastOneValueRequired:
    "Please enter the values in at least one input field",
  BillingName: "Billing Name",

  //MainBillingInfo.js
  StartDate: "Start Date",
  EndDate: "End Date",

  //OrgSettings.js
  LocationCode: "Location Code",
  DisplayName: "Display Name",
  TollFreeNumber: "Toll Free Number",
  TimeZone: "Time Zone",
  TimeZoneDelta: "Time Zone Delta",
  TimeZoneStandardDelta: "Time Zone Standard Delta",
  ContactPersonFirstName: "Contact Person First Name",
  ContactPersonLastName: "Contact Person Last Name",
  ContactPersonPhone: "Contact Person Phone",
  ContactPersonEmail: "Contact Person Email",
  InvalidOrgId_OrgSettings: "Error: invalid OrgUnit id.",
  NullOrgUnitId_OrgSettings: "Org Unit Id is null",
  InvalidGlobalCurrentOrgId_OrgSettings:
    "Client error: g_currentOrgUnitId is invalid.",
  CDW_AmountCost_OrgSettings: "CDW deductible and daily cost are required.",
  CDW_Required_OrgSettings: "You have to provide at least one option for CDW",
  UpsellRequired_OrgSettings: "Select All Required Fields for Upsell.",
  InvalidData_OrgSettings: "Please provide valid data for highlighted fields.",
  DailyRateRequired_OrgSettings: "Daily Rate Required",
  ApplyingSettings_OrgSettings: "Applying New Settings....",
  Duplicate_Deductibles: "Deductible options cannot be duplicated",
  Empty_Deductibles: "Deductible options cannot be empty",
  CDW_Deductible: "CDW deductible is required",

  RequiredDailyRateLiability: "Daily rate for liability is required",
  RequiredDeductibleAndDailyRateOption: "Please fill all required fields",
  RequiredDeductibleOption1: "Daily rate for deductible option 1 is required",
  RequiredDeductibleOption2: "Daily rate for deductible option 2 is required",
  RequiredDeductibleOption3: "Daily rate for deductible option 3 is required",
  RequiredDeductibleOption4: "Daily rate for deductible option 4 is required",
  RequiredAgedBasedDailyRate: "Daily rate for age based insurance is required",
  EnterTaxesProperly: "Please enter all required taxes fields",
  EnterSurchargesProperly: "Please enter all required surcharges fields",
  RequiredTaxandSurCharges: "Please fill all required fields",
  EmailRequired: "Valid Email",
  ValidEmail: "Email is not correct",
  ContractPersonEmailRequired: "Valid contract person email",
  ValidContactPersonEmail: "Contract person email is not correct",
  ValidEmailBcc: "Valid Email Bcc",
  ValidCheckoutEmails: "Valid Checkout Email(s)",
  ValidSupportEmails: "Valid Support Email(s)",
  TimeDurationValidation:
    "Start time cannot be greater than end time for hours of operation",
  DayValidation: "Day is required for hours of operation",
  DateDurationValidation: "Start date can not be greater than end date ",
  CurrentOrFutureDateValidation: "Please enter current or future date",
  DuplicateHolidayValidation: "Dates cannot be same for holidays",
  RoleName: "Role Name",
  RoleCode: "Code",
  PermissionName: "Permission Name",
  PermissionType: "Permission Type",
  ErrorCode: "Error Code",
  UniqueUrlValidation: "You must provide unique URL(s)",
  SpecialCharacterValidationMessage:
    "Only alphanumeric characters and underscore are allowed",
  ValidationMessageOfEmployeeNoBadgeNoInput:
    "Allowed values may include digits, and an optional prefix of 'T' or a suffix of 'S'",
  InheritedItemValidationForEdit:
    "Uncheck 'Inherit from Primary Location' to Edit ",
  InheritedItemValidationForDelete:
    "Uncheck 'Inherit from Primary Location' to Delete ",
  InheritedItemValidationForReset:
    "Uncheck 'Inherit from Primary Location' to Reset ",
  DoNotPermissionForEdit: "You do not have permission to edit ",
  DoNotPermissionForDelete: "You do not have permission to Delete ",
  DoNotPermissionForReset: "You do not have permission to Reset ",
  DeleteRecordMsg: "Are you sure you want to delete this record?",
  InheritCheckboxCaption: "Inherit from Primary Location",
  BusinessHoursMissingMessage: "Business hours unavailable for this location",
  CustomerReservationMisingMsg: "Please try again with valid search.",
  ReserveSyncInitialMessage:
    "No location has been selected. Please choose a location.",
  ReserveSyncNoRecordFoundMessage:
    "The data for the selected location is currently unavailable.",
  //JScript.js
  TimeLimit12Hours: "Time Range should be 0 to 12",
  TimeLimit24Hours: "Time Range should be 0 to 24",
  TimeFormat: "Time Format should be 00:00 or 00.00",
  PassWordChangeValidation: "New Password and Confirm Password should be same",
  ValueInBetween: "Value must be between",

  //activity
  ChannelType: "Channel Type",
  CallCategory: "Call Category",
  Category: "Category",
  Priority: "Priority",

  //Payment.js
  FullName: "Full Name",
  CreditCardNumber: "Credit Card Number",
  CVV: "CVV",
  Amount: "Amount",
  EnterComments: "Please Enter Comments",
  NameOnCreditCard: "Name On Card",
  CreditCardExpDate: "Credit Card Exp. Date",
  CreditCardExpExpiredMessage: "Credit card expired date is in past.",
  CreditCardType: "Card Type",
  CreditCardAddressCountry: "Credit Card Country",
  CreditCardAddressState: "Credit Card State",
  CreditCardAddressCity: "Credit Card City",
  CreditCardAddressZip: "Credit Card Zip/Postal Code",
  CVVAmexValidation: "CVV should be 4 digit for American Express card",
  CVVOtherValidation: "CVV should be 3 digit",

  //Damages Messages
  RequiredDamageLocation: "Damage Location is Mandatory",
  //Vehicle
  RequiredAssetIdMsg: "Assset Id is Mandatory",
  VehicleBelogsTo: "Vehicle Belongs to",
  VehicleName: "Vehicle Name",
  UnitNo: "Unit No",
  MadeBy: "Made By",
  Model: "Model",
  Year: "Year",
  SeatingCapacity: "Seating Capacity",
  VIN: "Vin",
  VINValidationMsg:
    "Vehicle Identification Number (VIN) must be exactly 8, 17 or 18 characters.",
  LicensePlate: "License Plate",
  ActualCashValue: "Actual Cash Value",
  AssetMileageAtSignUp: "Mileage at Signup",
  AnnualMilesDriven: "Annual Miles Driven",
  InsuranceCarrier: "Insurance Carrier",
  PolicyNumber: "Policy Number",
  ParkingDistanceOption: "Vehicle will be located",
  ParkedText: "Parking at",
  DriveType: "Driving Type",
  FuelType: "Fuel Type",
  FuelConsumptionUnit: "Fuel Consumption Unit",
  TransmissionType: "Transmission Type",
  PersonAssetColor: "Vehicle Color",
  //Aged Bsed Insurance
  AgedInsuranceError: "Please Enter Max and Min Value Properly",
  //Locate Asset
  //EnterCallerLocation: "Please Enter Caller Location.",
  EnterAssetLocation: "Please Enter Vehicle Location.",
  SelectSuggestedDrection: "Please Select Suggested Direction.",
  CallerToPark: "Caller To Park Direction Is Not Found.",
  ParkToCaller: "Park To Caller Direction Is Not Found.",
  CallerToVehicle: "Caller To Vehicle Direction Is Not Found.",
  VehicleToCaller: "Vehicle To Caller Direction Is Not Found.",
  ParkToVehicle: "Park To Vehicle Direction Is Not Found.",
  VehicleToPark: "Vehicle To Park Direction Is Not Found.",
  LocationNotFound: "Location Is Not Found.",
  VehicleNotFound: "No Vehicle Found.",
  //Session Expire
  SessionExpireMsg:
    "Your session has expired. You must log in again to resume your session.",
  //Login
  ReqPasswordMsg: "Password must be a minimum of 8 characters.",
  //LogOut
  LogOutSuccess: "Logout Successful.",
  //Check Is Null or Empty
  ReservationIdNullorZero: "Reservation id could not be zero or null",
  MemberIdNullorZero:
    ADMIN_COMMON_CAPTION.CUSTOMER_ID + " could not be zero or null",
  //Cancel Reservation
  ReservationCancellationReason: "Cancellation Reason",
  ReservationCancelledBy: "Cancelled By",
  ReservationCancellationComment: "Comments",
  ReservationCancellationFee: "Cancellation fee",
  ReservationCourtesyFee: "Courtesy fee",
  CourtesyFeeHigher: "Cancellation fee should be greater than courtesy fee",
  CancellationPolicyCRO:
    "<b>Renters:</b> If you cancel a reservation within 6 hours of booking, you won’t be charged a fee. If you cancel more than 6 hours after booking and more than 24 hours ahead of the scheduled reservation, you’ll be charged $25. If you cancel a reservation within 24 hours of the booking, you’ll be charged the cost of the reservation. The $1 reservation booking fee is refundable within 6 hours.",
  CancellationPolicyP2P:
    "<b>Owners:</b> Please contact us immediately at 1-855-SHARE-01 or (855) 742-7301 if the vehicle’s availability changes suddenly. We’ll have to locate another vehicle for the Renter and you’ll have to pay for the cost of the cancelled reservation as well as a $25 fee. To avoid this hassle, borrow one of your neighbor’s rides instead! If there’s an emergency, please provide us with the proper documentation and we’ll waive these fees.",
  FILE_SIZE_ERROR: "File size must be 1MB.",
  ResendPaymentLinkConfirmMessageTitle:
    "Are you sure you want to resend the payment link to the customer?",
  ResendPaymentLinkConfirmMessageSubTitlePrefix: "Link will be resent to ",
  RewardsInformationMisMatchMsg:
    "The Rewards# provided does not match the associated first name, last name, or email. Please ensure accuracy or clear the Rewards# before proceeding.",
  SaveInheritParentConfirm:
    "Inheriting from primary location will overwrite current values. " +
    "They will be lost when you click the Save button.<br/><br/>Do you want to continue?",
  RemoveInheritParentConfirm:
    "Are you sure you want to stop inheriting setting values from primary location?",
  AvailabilityCalendarDiscard:
    "Please confirm that you want to discard any changes?",
  AvailabilityCalendarEditConfirm:
    "Your appointment has not been saved. Do you want to continue?",
  AvailabilityCalendar_StartDate_EndDate_Constraints:
    "End Time must be after Start Time.",
  AvailabilityCalendarDelete: "Are you sure you want to delete this?",

  OrgUnitIdRequiredMemberProfile: "Location",
  EmployeeCodeRequiredMemberProfile: "Employee #",
  DepartmentRequiredMemberProfile: "Department",
  UserProfileLinkIconTooltip:
    "Click for " + getMemberLabelByOrgRentalType() + " Details",
  EmployeeBadgeNo: "Badge #",
  EmployeeTitle: "Title",
  EmployeeCompany: "Company",
  LocationId: "Location Id",
  UnderConstructionMessage: "is under constructions!!!!",

  // discount validation/messages
  DiscountType: "Discount Type",
  DiscountCode: "Discount Code",
  InvalidDiscountCode: "Invalid Discount Code",

  PickupLoactionQuickViewTooltipMsg: "Click to get rental location details",
  ReturnLoactionQuickViewTooltipMsg: "Click to get return location details",
  ManualApproveTooltipMsg: "Click for Manual Approval",
  ManualDisapproveTooltipMsg: "Click to Disapprove License",
  LicenseApproveTooltipMsg: "Click to Approve License",
  LicenseApprovedStatus: "Approved.",
  LicenseDisApprovedStatus: "Not Approved.",

  EditTooltipMsg: "Edit",
  RemoveTooltipMsg: "Remove",

  serverMsgTitle: "Reason (Internal): ",
  // CancelTooltipForNonFOXReservation:
  //   "Non-FOX reservations cannot be cancelled. Customer must cancel with the provider where they made it",
  EditTooltipForNonFOXReservation:
    "Non-FOX reservations cannot be edited. Customer must edit with the provider where they made it",
  EditDisableTooltipPostfixText:
    "reservations cannot be edited. Customer must edit with the provider where they made it", //"Partner Name" will be added as prefix
  DisabledDiscountFieldTooltip:
    "Partner is associated with a company number. So it will not be editable.",
  EditTooltipForPaynowReservation: "Paynow reservations cannot be edited",
  CancelTooltipForPaynowReservation: "Reservation cannot be cancelled",
  EditTooltipForCancelledReservation: "Cancel reservations cannot be edited",
  PayLaterToPayNow:
    "Pay later reservations cannot be changed to pay now. Please cancel and rebook.",
  PayNowToPayLater:
    "Pay now reservations cannot be changed to pay later. Please cancel and rebook.",
  FoxPaynowDisableMsg:
    "Editing reservations is not allowed for FOX PayNow reservations.",
  //Section Header Title
  NEW_RESERVATION_TITLE: "NEW RESERVATION",
  EDIT_RESERVATION_TITLE: "EDIT RESERVATION",
  CANCELLED_RESERVATION_TITLE: "CANCELLED RESERVATION ARE NOT EDITABLE",
  PAYNOW_RESERVATION_TITLE: "PAY NOW RESERVATION ARE NOT EDITABLE",
  NON_FOX_PARTNER_RESERVATION_TITLE: "NON-FOX RESERVATIONS CANNOT BE EDITED",
  SHUTTLE_NEW: "NEW SHUTTLE",
  SHUTTLE_EDIT: "EDIT SHUTTLE",
  SHUTTLE_DETAILS: "SHUTTLE DETAILS",
  LANDMARK_NEW: "NEW LANDMARK",
  LANDMARK_EDIT: "EDIT LANDMARK",
  LANDMARK_DETAILS: "LANDMARK DETAILS",
  IMPERSONATE_MODE_TITLE: "IMPERSONATE MODE",
  ADDONS_INSURANCE_TITLE: "AddOns: Insurance",
  ADDONS_OTHERS_TITLE: "AddOns: Other",

  // About Modal
  ADMIN_PORTAL_DESCRIPTION:
    "<b>FOX Administration portal</b> is a back office application. It allows users to view, update and make reservations, review customer information, vehicle information and provide support to customers. This portal allows users to check customer activity logs, billing information, credit information etc. It also includes reporting capabilities for mobile applications (Service Agent, Mechanics App etc.)",
  ABOUT_MODAL_TITLE: "FOX Administration Portal",

  //Landmark
  LandmarkOrgUnit: "Location",
  LandmarkCategory: "Category",
  LandmarkCode: "Code",
  LandmarkName: "Name",
  LandmarkShortName: "Short Name",
  Latitude: "Latitude",
  Longitude: "Longitude",
  InvalidPhoneNo: "Please provide a valid phone number and country code",
  PhoneNoRequired: "Phone",
  // Global Search
  ConfirmationMessageToSearchAllAreas:
    "Performing search in ALL areas is very expensive, may take a long time to get the search results. Are you sure you want to proceed with this search?",
  NavigationLinkIconTooltip: "Click to see details",
  VICINITY_MULTI_LOCATION_REQ_MSG:
    "Live View is not available for multiple Locations.<br/>Select a single Location and retry.",
  VICINITY_NO_LOCATION_REQ_MSG:
    "You must select a Location before switching to Live View.",
};

export const Roles_Permissions = {
  AppAccessToMember: "AppAccessToMember",
  PermissionsToMember: "PermissionsToMember",
  PermissionsToRoles: "PermissionsToRoles",
  RolesToMember: "RolesToMember",
};

export const JSIAdminId = 100;

export const JsiInsuranceOption = {
  JSI: "JSI_INSURANCE",
  CRO: "CRO_INSURANCE",
  NO: "NO_INSURANCE",
};

export const AttendanceStatus = {
  NOW_SHOW: "NO_SHOW",
  ARRIVED: "ARRIVED",
  COMPLETED: "COMPLETED",
};

export const ReservationState = {
  CANCELLED: "CANCELLED", // reservation is cancelled
  IN_PROGRESS: "IN_PROGRESS", // reservation is in progress (Arrived)
  CONFIRMED: "CONFIRMED", // reservation is confirmed by owner
  PENDING: "PENDING", // reservation has created but still pending. owner has not confirmed yet
  BACKGROUND_CHECK_PENDING: "BACKGROUND_CHECK_PENDING", // Background check could not be performed.
  PAYMENT_PENDING: "PAYMENT_PENDING", //payment of the reservation is pending.
  MESSAGE: "MESSAGE", // reservation is just a message holder
  COMPLETED: "COMPLETED", // reservation is completed
  NOT_STARTED: "NOT_STARTED", // reservation is a no-show
  INCOMPLETE: "INCOMPLETE",
};

export const ReservationConstants = {
  ReservationSharerRequest: "panel",
  ReservationBorrowerRequest: "R",

  RequstfromReservation: "Rs",
  BorrowerViewType: "BORROWER",
  SharerViewType: "SHARER",

  FutureReservation: "FutureRes",
  LastReservation: "LastRes",
  NextReservation: "NextRes",
  DefaultOrderByColumn: "RESV_CREATED_DATE",
};

export const ImageStorageType = {
  OWNER_IMG: "OWNER_IMG",
  LICENSE_IMG: "LICENSE_IMG",
  VEHICLE_IMG: "VEHICLE_IMG",
  BGCHK_IMG: "BGCHK_IMG",
  WISHES_IMG: "WISHES_IMG",
  PROBLEMS_IMG: "PROBLEMS_IMG",
  MISC_IMG: "MISC_IMG",
  MOBILE_OWNER_IMG: "MOBILE_OWNER_IMG",
};
export const LicenseDocType = {
  ATTACHED_DOC: "ATTACHED_DOC",
  MANUAL_APPROVE: "MANUAL_APPROVE",
  MANUAL_DISAPPROVE: "MANUAL_DISAPPROVE",
};
export const SearchAPI = {
  SEARCH_MEMBER: "membersearch",
  SEARCH_VEHICLE: "searchassets",
  SEARCH_RESERVATION: "searchreservations",
  SEARCH_RENTAL: "searchrentals",
  SEARCH_MEMBER_RESERVATION: "searchmemberreservations",
  SEARCH_MEMBER_VEHICLE_RESERVATION: "getreservationrequestlist",
  SEARCH_TRANSACTIONS: "searchtransaction",
  SEARCH_ROLES_PERMISSIONS_MEMBER: "rolespermissionsmembersearch",
  SEARCH_SERVICE_TASK: "searchtask",
  SEARCH_ACTIVITY_LOG: "searchactivitylog",
  SEARCH_MESSAGES: "getmessages",
  SEARCH_FAVOURITES: "favoritassetlist",
  SEARCH_MY_VEHICLE: "assetforowner",
  SEARCH_FEEDBACK: "getfeedbackdetails",
  SEARCH_COMPLAINTS: "searchactivity",
  SEARCH_IMPLEMENTATION: "getbillinginfo",
  SEARCH_SHUTTLE: "shuttleapi/searchshuttles",
  SEARCH_LANDMARK: "shuttleapi/searchlandmarks",
  SEARCH_SHUTTLE_TRIP: "shuttleapi/searchtrips",
  SEARCH_FLEET_UTILIZATION_FORECAST: "admin/searchfleetutilizationforecast",
};
export const ModalType = {
  VEHICLE_QUICK_MODAL: "VEHICLE_QUICK_MODAL",
  VEHICLE_CATEGORY_QUICK_MODAL: "VEHICLE_CATEGORY_QUICK_MODAL",
  MEMBER_QUICK_MODAL: "MEMBER_QUICK_MODAL",
  MEMBER_DISCOUNT_MODAL: "MEMBER_DISCOUNT_MODAL",
  GRANT_DISCOUNT_MODAL: "GRANT_DISCOUNT_MODAL",
  PERMISSION_TO_USERS: "PERMISSION_TO_USERS",
  ROLES_TO_USERS: "ROLES_TO_USERS",
  APP_ACCESS_TO_USERS: "APP_ACCESS_TO_USERS",
  ALL_ADDRESS_MODAL: "ALL_ADDRESS_MODAL",
  INVOICE_INFO_MODAL: "INVOICE_INFO_MODAL",
  LOCATION_QUICK_MODAL: "LOCATION_QUICK_MODAL",
  CANCEL_FOX_MODAL: "CANCEL_FOX_MODAL",
  RESEND_PAYMENT_LINK_MODAL: "RESEND_PAYMENT_LINK_MODAL",
  CANCEL_RESERVATION_MODAL: "CANCEL_RESERVATION_MODAL",
  CANCEL_REASON_UPDATE_MODAL: "CANCEL_REASON_UPDATE_MODAL",
  TRANSACTION_MODAL: "TRANSACTION_MODAL",
  EXTERNAL_PARAMETER_UPDATE_MODAL: "EXTERNAL_PARAMETER_UPDATE_MODAL",
  RESERVATION_DETAILS_UPDATE: "RESERVATION_DETAILS_UPDATE",
  RESERVATION_NOTES_MODAL: "RESERVATION_NOTES_MODAL",
  CHANNEL_QUICK_MODAL: "CHANNEL_QUICK_MODAL",
  FOX_CHANNEL_QUICK_MODAL: "FOX_CHANNEL_QUICK_MODAL",
  INTEGRATION_HISTORY_MODAL: "INTEGRATION_HISTORY_MODAL",
  CONTRACT_MODAL: "CONTRACT_MODAL",
  RESERVATION_SUMMARY_INVOICE_MODAL: "RESERVATION_SUMMARY_INVOICE_MODAL",
  RESERVATION_INFO_MODAL: "RESERVATION_INFO_MODAL",
  MAIL_MODAL: "MAIL_MODAL",
  ACCEPT_DECLINE_RESERVATION_MODAL: "ACCEPT_DECLINE_RESERVATION_MODAL",
  UPDATE_RESERVATION_CREATION_TYPE_MODAL:
    "UPDATE_RESERVATION_CREATION_TYPE_MODAL",
  RESERVATION_ACTION_SUMMARY_MODAL: "RESERVATION_ACTION_SUMMARY_MODAL",

  LICENSE_APPROVAL_MODAL: "LICENSE_APPROVAL_MODAL",
  LICENSE_INFO_MODAL: "LICENSE_INFO_MODAL",
  LICENSE_DOC_MODAL: "LICENSE_DOC_MODAL",
  MEMBER_QRCODE_MODAL: "MEMBER_QRCODE_MODAL",
  MEMBER_LICENCE_UPLOADER_MODAL: "MEMBER_LICENCE_UPLOADER_MODAL",
  MEMBER_LICENCE_VIEW_MODAL: "MEMBER_LICENCE_VIEW_MODAL",
  MEMBER_ALL_ADDRESS_MODAL: "MEMBER_ALL_ADDRESS_MODAL",
  MEMBER_ADD_ADDRESS_MODAL: "MEMBER_ADD_ADDRESS_MODAL",
  ADD_MEMBER_QUICK_MODAL: "ADD_MEMBER_QUICK_MODAL",
  CONFILICTED_MEMBER_MODAL: "CONFILICTED_MEMBER_MODAL",
  AGENT_MODAL: "AGENT_MODAL",

  DAMAGE_MODAL: "DAMAGE_MODAL",
  VEHICLE_AVAILABILITY_STATUS_MODAL: "VEHICLE_AVAILABILITY_STATUS_MODAL",
  ADD_ROLE_MODAL: "ADD_ROLE_MODAL",
  EDIT_ROLE_MODAL: "EDIT_ROLE_MODAL",
  COPY_ROLE_MODAL: "COPY_ROLE_MODAL",
  DELETE_ROLE_MODAL: "DELETE_ROLE_MODAL",
  ASSIGN_ROLE_PERMISSION_MODAL: "ASSIGN_ROLE_PERMISSION_MODAL",
  TRACKER_QUICK_MODAL: "TRACKER_QUICK_MODAL",
  LATE_CHARGE_CONFIRM_MODAL: "LATE_CHARGE_CONFIRM_MODAL",
  EDIT_RESERVATION_NOT_ALLOWED_MODAL: "EDIT_RESERVATION_NOT_ALLOWED_MODAL",
  APPLICATION_BUILD_INFO_MODAL: "APPLICATION_BUILD_INFO_MODAL",
  MEMBER_BLOCK_UNBLOCK_MODAL: "MEMBER_BLOCK_UNBLOCK_MODAL",
  MEMBER_ACTIVATION_MODAL: "MEMBER_ACTIVATION_MODAL",
  MEMBER_VERIFICATION_MODAL: "MEMBER_VERIFICATION_MODAL",
  CHANGE_RESET_PASSWORD_MODAL: "CHANGE_RESET_PASSWORD_MODAL",
  ADD_VIOLATION_MODAL: "ADD_VIOLATION_MODAL",
  IMPERSONATE_MODAL: "IMPERSONATE_MODAL",
  FREE_CREDIT_GRANT_REVERSE_MODAL: "FREE_CREDIT_GRANT_REVERSE_MODAL",
  VOUCHER_INFORMATION_MODAL: "VOUCHER_INFORMATION_MODAL",
  COUNT_DOWN_TIMER_MODAL: "COUNT_DOWN_TIMER_MODAL",
  APPLY_OFFER_MODAL: "APPLY_OFFER_MODAL",
  ADD_NEW_LOCATION_MODAL: "ADD_NEW_LOCATION_MODAL",
  DELETE_LANDMARK: "DELETE_LANDMARK",
  SHUTTLE_QUICK_MODAL: "SHUTTLE_QUICK_MODAL",
  SHUTTLE_DIRECTION_MODAL: "SHUTTLE_DIRECTION_MODAL",
  RESERVE_SYNC_OVERRIDE_DEFAULT_MODAL: "RESERVE_SYNC_OVERRIDE_DEFAULT_MODAL",
  RESERVE_SYNC_OVERRIDE_MANUAL_MODAL: "RESERVE_SYNC_OVERRIDE_MANUAL_MODAL",
  RESERVE_SYNC_ACTIVITY_LOG_MODAL: "RESERVE_SYNC_ACTIVITY_LOG_MODAL",
  CUSTOMER_RESERVATION_HISTORY_MODAL: "CUSTOMER_RESERVATION_HISTORY_MODAL",

  RESERVATION_TEST_CONFIRM: "RESERVATION_TEST_CONFIRM",
  RESERVATION_UPDATE_CONFIRM: "RESERVATION_UPDATE_CONFIRM",
  RESERVATION_CANCEL_CONFIRM: "RESERVATION_CANCEL_CONFIRM",
  RESERVATION_CANCEL_CHANGE_CONFIRM: "RESERVATION_CANCEL_CHANGE_CONFIRM",
  QUICK_RES_SUMMARY: "QUICK_RES_SUMMARY",
  TAXES_FEES_MODAL: "TAXES_FEES_MODAL",
  COMMON_NOTES_MODAL: "COMMON_NOTES_MODAL",
  VEHICLE_ATTR_MODAL: "VEHICLE_ATTR_MODAL",
};
export const MailType = {
  EMAIL_CONTRACT: "EMAIL_CONTRACT",
  EMAIL_INVOICE: "EMAIL_INVOICE",
  EMAIL_RESERVATION_SUMMARY: "EMAIL_RESERVATION_SUMMARY",
  EMAIL_ACTIVITY: "EMAIL_ACTIVITY",
};

export const QuickHistoryType = {
  RESERVATION: "RESERVATION",
  RENTAL: "RENTAL",
  MEMBER: "MEMBER",
};

export const ReservationBlocks = {
  BASIC_INFO: "BASIC_INFO",
  VEHICLE_CLASSES: "VEHICLE_CLASSES",
  ADDONS_FORM: "ADDONS_FORM",
  CUSTOMER_INFO: "CUSTOMER_INFO",
  ADDITIONAL_DRIVER_INFO: "ADDITIONAL_DRIVER_INFO",
  RES_NOTES_BLOCK: "RES_NOTES_BLOCK",
  PAYMENT_INFO: "PAYMENT_INFO",
};
export const BUILD_VERSION_FOR = {
  QA: "qa",
  DEVELOPMENT: "",
  RC: "rc",
  PRE_PRODUCTION: "pre-prod",
  PRODUCTION: "",
};
export const PERFORCE_BRANCH = {
  ADMIN_5_0: "5.0",
  ADMIN_5_1: "5.1",
  ADMIN_5_1_1: "5.1.1",
  ADMIN_5_2: "5.2",
  /**will fix it on paylater release */
  ADMIN_6_0: "Pay Later",
  /*should be updated by developer later no need to add variable againa nd again*/
  ADMIN_VERSION: "One Time Discount",
};
export const IS_ALLOWED_PREPAID_RESERVATION = true; // Change to "true" for PayNow reservation
export const EXTERNAL_CODE_LBL = {
  FREE_CREDIT_PLACE_HOLDER: "Res. Code / Confirmation Code / RA#",
  CONFIRMATION_CODE: "Confirmation Code",
  ADD_CONFIRMATION_CODE: "Add Confirmation Code",
};
export const MessageBoxType = {
  PAY_NOW_ADD_ON_DISCLOSURE: "PAY_NOW_ADD_ON_DISCLOSURE",
  TAXES_FEES_SUMMARY: "TAXES_FEES_SUMMARY",
  SECURITY_POLICY: "SECURITY_POLICY",
  ADDON_DETAILS: "ADDON_DETAILS",
  FREE_CREDIT_POLICY: "FREE_CREDIT_POLICY",
  VEHICLE_MORE_INFO: "VEHICLE_MORE_INFO",
};
export const ReservationMsg = {
  resPageVehicleDefaultMessage: "Please select a location & get rates",
  resPageAddonsDefaultMessage: "Please get a rate & select vehicle",
};

import React from "react";
import LabelWithValue from "../../../../components/UI/label/LabelWithValue";
import { ModalType } from "../../../../shared/GlobalVar";

const SummaryOthersInfoBlock = ({
  reservationUiModel,
  showModal,
  specificPricingData,
}) => {
  const {
    creationDateTimeDisplayText,
    contractAccepted,
    contractAcceptedText,
    integStatus,
    createdMethod,
    reservationCreationType,
    createdBy,
    cancelledOnDisplayText,
    cancelledByPersonName,
    reservationState,
    raCode,
    externalReservationCode,
    productCode,
    enableExternalParameterUpdate,
    clientReservationAllowed
  } = reservationUiModel;
  const { referralAgency } = specificPricingData;

  return (
    <div className="res-summary-other-block">
      <LabelWithValue
        label="Int. Res. Code"
        value={externalReservationCode}
        hasCopyIcon={true}
      />
      <LabelWithValue
        label="Rental Agreement#"
        value={raCode}
        hasCopyIcon={true}
        modalParams={{
          type: enableExternalParameterUpdate && ModalType.EXTERNAL_PARAMETER_UPDATE_MODAL
        }}
        showModal={showModal}
        enableLinkOnLabel={raCode}
      />
      <LabelWithValue
        label={"Product Code"}
        value={productCode ? productCode : clientReservationAllowed && "Add Code"}
        hasCopyIcon={productCode}
        modalParams={{
          type: clientReservationAllowed && ModalType.RESERVATION_DETAILS_UPDATE,
          valueToUpdate: "productCode"
        }}
        showModal={showModal}
        enableLinkOnLabel={clientReservationAllowed}
        tooltipText={clientReservationAllowed && productCode ? "Click to update the Product Code" : "Click to add a Product Code"}
      />
      <LabelWithValue
        label="Integration Status"
        value={integStatus}
        modalParams={{
          type: ModalType.INTEGRATION_HISTORY_MODAL,
        }}
        showModal={showModal}
        tooltipText="Click to View Integration Status Details"
        enableLinkOnLabel={integStatus}
      />
      <LabelWithValue
        label="Channel"
        value={createdMethod}
        modalParams={{
          type: ModalType.FOX_CHANNEL_QUICK_MODAL,
        }}
        showModal={showModal}
        enableLinkOnLabel={createdMethod}
        tooltipText="Click to View Channel Details"
      />
      <LabelWithValue
        label="Reservation Creation Type"
        value={reservationCreationType}
        customValueParentClass={
          reservationCreationType === "TEST" ? "testRes" : ""
        }
        modalParams={{
          type: ModalType.UPDATE_RESERVATION_CREATION_TYPE_MODAL,
        }}
        showModal={showModal}
        tooltipText="Click to toogle Reservation Creation Type"
        enableLinkOnLabel={reservationCreationType}
      />
      <LabelWithValue
        label="Created"
        value={creationDateTimeDisplayText}
        otherValue={createdBy ? "By - " + createdBy : null}
      />
      <LabelWithValue label="Referral Agency" value={referralAgency || null} />

      {contractAccepted && <LabelWithValue
        label="Contract Status"
        value={contractAcceptedText}
        modalParams={{
          type: ModalType.CONTRACT_MODAL,
        }}
        showModal={showModal}
        customValueParentClass={
          contractAccepted ? "text-success" : "text-danger"
        }
        tooltipText="Click to View Contract Report"
        enableLinkOnLabel={contractAcceptedText}
      />}
      {reservationState === "CANCELLED" && (
        <LabelWithValue
          label="Cancelled"
          value={cancelledOnDisplayText}
          otherValue={cancelledByPersonName ? "By - " + cancelledByPersonName : null}
        />
      )}
    </div>
  );
};

export default SummaryOthersInfoBlock;

export const getZoomLevelWiseScaleValues = () => {
    return [
      {
        zoomLevel: 0,
        scaleValueInKilometer: 10000,
        scaleValueInMiles: 5000,
      },
      {
        zoomLevel: 1,
        scaleValueInKilometer: 5000,
        scaleValueInMiles: 3000,
      },
      {
        zoomLevel: 2,
        scaleValueInKilometer: 3000,
        scaleValueInMiles: 1000,
      },
      {
        zoomLevel: 3,
        scaleValueInKilometer:1000 ,
        scaleValueInMiles:500 ,
      },
      {
        zoomLevel: 4,
        scaleValueInKilometer: 500,
        scaleValueInMiles: 300,
      },
      {
        zoomLevel: 5,
        scaleValueInKilometer: 300,
        scaleValueInMiles: 200,
      },
      {
        zoomLevel: 6,
        scaleValueInKilometer: 100,
        scaleValueInMiles: 100,
      },
      {
        zoomLevel: 7,
        scaleValueInKilometer: 50,
        scaleValueInMiles: 50,
      },
      {
        zoomLevel: 8,
        scaleValueInKilometer: 30,
        scaleValueInMiles: 30,
      },
      {
        zoomLevel: 9,
        scaleValueInKilometer:20,
        scaleValueInMiles: 10,
      },
      {
        zoomLevel: 10,
        scaleValueInKilometer: 10,
        scaleValueInMiles: 5,
      },
      {
        zoomLevel: 11,
        scaleValueInKilometer: 5,
        scaleValueInMiles: 3,
      },
      {
        zoomLevel: 12,
        scaleValueInKilometer: 3,
        scaleValueInMiles: 1,
      },
      {
        zoomLevel: 13,
        scaleValueInKilometer: 1,
        scaleValueInMiles: 0.5681818,
      },
      {
        zoomLevel: 14,
        scaleValueInKilometer: 0.5,
        scaleValueInMiles: 0.3787879,
      },
      {
        zoomLevel: 15,
        scaleValueInKilometer: 0.3,
        scaleValueInMiles: 0.189394,
      },
      {
        zoomLevel: 16,
        scaleValueInKilometer: 0.1,
        scaleValueInMiles: 0.094697,
      },
      {
        zoomLevel: 16,
        scaleValueInKilometer: 0.05,
        scaleValueInMiles: 0.0568182,
      },
      {
        zoomLevel: 17,
        scaleValueInKilometer: 0.03,
        scaleValueInMiles: 0.0189394,
      },
    ];
  };
import { FoxRequestHeader } from "../../fox-api/domain/fox-request-header";
import PageInfo from "../../models/PageInfo";

const LandmarkSearchRequest = function() {
  this.requestHeader = new FoxRequestHeader();
  this.locationCodes = "";
  this.landmarkCode = null;
  this.name = null;
  this.shortName = null;
  this.landmarkCategories = "";
  this.formattedAddress = null;
  this.status = null;
};
export default LandmarkSearchRequest;

import moment from 'moment';
import React from 'react';
import CheckBox from '../../../../components/UI/Input/checkbox-radio/check-box-ctrl';
import LocationCtrl from '../../../../components/UI/Input/location/location-ctrl';
import {
    LOCATION
} from "../../../../shared/AppImages";
import { isNullOrEmpty } from '../../../../shared/utility';
import { AdminDateFromat } from '../../../../shared/utility/enum-utility';
function LocationContainer(props) {
    const { primaryFormData, setPrimaryFormData } = props

    const onPickupLocationChange = (event) => {
        const timeZone = event.otherId;
        setLocWiseDefaultDatetime(timeZone, event.keyValue, event?.locationId, event?.displayValue);
    }

    const onReturnLocationChange = (event) => {
        if (
            isNullOrEmpty(primaryFormData?.rentalLocationCode) ||
            primaryFormData?.rentalLocationCode !== event.keyValue
        ) {
            const updatedControls = { ...primaryFormData };
            updatedControls.sameAsRentFromLoaction = false;
            updatedControls.returnLocationCode = event?.keyValue;
            updatedControls.returnLocationId = event?.locationId;
            updatedControls.rentalLocationName = event?.displayValue;
            setPrimaryFormData(updatedControls);
        }
    }

    const onCheckedRentFromDiffLoc = (event) => {
        const updatedControls = { ...primaryFormData };
        updatedControls.sameAsRentFromLoaction = event.target.checked;
        if (!event.target.checked) {
            updatedControls.returnLocationCode = primaryFormData.rentalLocationCode;
            updatedControls.returnLocationId = primaryFormData.rentalLocationId;
            updatedControls.returnLocationName = primaryFormData.rentalLocationName;
        }
        setPrimaryFormData(updatedControls);
    }

    const setLocWiseDefaultDatetime = (
        timeZone,
        locationCode,
        locationId,
        locName
    ) => {
        let nowInServer = timeZone
            ? moment().tz(timeZone)
            : moment().tz(moment.tz.guess());
        if (
            nowInServer.hour() > 8 ||
            (nowInServer.hour() === 8 && nowInServer.minutes() > 30)
        ) {
            nowInServer.add(1, "days");
        }
        nowInServer.set({ hour: 9, minute: 30, second: 0, millisecond: 0 });
        let returnTime = nowInServer.clone();
        returnTime.add(1, "days");
        nowInServer = nowInServer.format(AdminDateFromat.FOX_API_DATE_TIME);
        returnTime = returnTime.format(AdminDateFromat.FOX_API_DATE_TIME);

        const tempData = { ...primaryFormData };

        tempData.timeZone = timeZone;
        tempData.rentalLocationId = locationId;
        tempData.rentalLocationCode = locationCode;
        tempData.rentalLocationName = locName;

        tempData.returnLocationId = primaryFormData?.sameAsRentFromLoaction
            ? locationId
            : primaryFormData.returnLocationId;
        tempData.returnLocationCode = primaryFormData?.sameAsRentFromLoaction
            ? locationCode
            : primaryFormData.returnLocationCode;
        tempData.returnLocationName = primaryFormData?.sameAsRentFromLoaction
            ? locName
            : primaryFormData.returnLocationName;

        tempData.rentalDateTime = primaryFormData.rentalDateTime || nowInServer;
        tempData.rentalOnlyDate = primaryFormData.rentalOnlyDate || nowInServer;
        tempData.rentalOnlyTime = primaryFormData.rentalOnlyTime || nowInServer;

        tempData.returnDateTime = primaryFormData.returnDateTime || returnTime;
        tempData.returnOnlyDate = primaryFormData.returnOnlyDate || returnTime;
        tempData.returnOnlyTime = primaryFormData.returnOnlyTime || returnTime;
        setPrimaryFormData(tempData);
    };

    return (
        <>
            <CheckBox
                name={"sameAsRentFromLoaction"}
                placeholder="Same as Pickup Location"
                checked={primaryFormData?.sameAsRentFromLoaction}
                onChange={onCheckedRentFromDiffLoc}
            />
            <LocationCtrl
                key="rentalLocationCode"
                name="rentalLocationCode"
                placeholder="Pick-up & Return Location"
                imgIco={LOCATION}
                value={primaryFormData.rentalLocationCode}
                onChange={onPickupLocationChange}
            />
            {!primaryFormData?.sameAsRentFromLoaction ?
                <LocationCtrl
                    key="returnLocationCode"
                    name="returnLocationCode"
                    placeholder="Return Location"
                    imgIco={LOCATION}
                    value={primaryFormData.returnLocationCode}
                    onChange={onReturnLocationChange}
                /> : ""}
        </>
    )
}

export default LocationContainer
import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../../../store/actions/index";
import Rating from "react-rating";
import Spinner from "../../../components/UI/Spinner/Spinner";
import { getBasicToastResponse } from "../../../shared/utility";
import { showNotification } from "../../../components/UI/Toaster/Toaster";
import FeedbackUI from "./feedback-ui";
class FeedbackDetails extends React.Component {
  state = { feedbackQuestionsResponse: null, feedbackDetailsResponse: null };
  componentDidMount() {
    this.initFeedbackSummaryPage();
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.match.url !== prevProps.match.url) {
      this.setState(
        { feedbackQuestionsResponse: null, feedbackDetailsResponse: null },
        () => {
          this.initFeedbackSummaryPage();
        }
      );
    }
  }
  UNSAFE_componentWillReceiveProps({
    feedbackQuestionsResponse,
    feedbackDetailsResponse,
  }) {
    if (
      (feedbackQuestionsResponse && !this.state.feedbackQuestionsResponse) ||
      (this.state.feedbackQuestionsResponse &&
        feedbackQuestionsResponse &&
        !this.props.feedbackQuestionsResponse)
    ) {
      if (feedbackQuestionsResponse.StatusCode === 0) {
        this.setState({
          feedbackQuestionsResponse: feedbackQuestionsResponse,
        });
      } else {
        showNotification(getBasicToastResponse(feedbackQuestionsResponse));
      }
    }
    if (
      (feedbackDetailsResponse && !this.state.feedbackDetailsResponse) ||
      (this.state.feedbackDetailsResponse &&
        feedbackDetailsResponse &&
        !this.props.feedbackDetailsResponse)
    ) {
      if (feedbackDetailsResponse.StatusCode === 0) {
        this.setState({
          feedbackDetailsResponse: feedbackDetailsResponse,
        });
      } else {
        showNotification(getBasicToastResponse(feedbackDetailsResponse));
      }
    }
  }
  getInfoFromParamQuery = (paramName) => {
    const query = new URLSearchParams(this.props.location.search);
    return query.get(paramName);
  };
  initFeedbackSummaryPage = () => {
    this.props.onGetFeedbackQuestionsReset();
    this.props.onGetFeedbackDetailsReset();
    const feedBackDetails = {
      ReservationId: this.getInfoFromParamQuery("reservationId"),
      MemberId: this.getInfoFromParamQuery("memberId"),
    };
    this.props.onGetFeedbackQuestions();
    this.props.onGetFeedbackDetails(feedBackDetails);
  };
  getRating = (rating, isEnabled) => {
    return (
      <Rating
        initialRating={rating || 0}
        readonly={isEnabled}
        stop={5}
        fractions={2}
        emptySymbol={[
          "far fa-star fa-2x",
          "far fa-star fa-2x",
          "far fa-star fa-2x",
          "far fa-star fa-2x",
          "far fa-star fa-2x",
        ]}
        fullSymbol={[
          "fas fa-star fa-2x text-warning",
          "fas fa-star fa-2x text-warning",
          "fas fa-star fa-2x text-warning",
          "fas fa-star fa-2x text-warning",
          "fas fa-star fa-2x text-warning",
        ]}
      />
    );
  };
  distinguishRatingData = (categoryType) => {
    let feedbackDetails = { Category: categoryType };
    if (
      this.state.feedbackDetailsResponse &&
      this.state.feedbackDetailsResponse.TotalRecords
    ) {
      if (
        this.state.feedbackDetailsResponse.Feedbacks[0].Category ===
        categoryType
      ) {
        return this.state.feedbackDetailsResponse.Feedbacks[0];
      } else {
        return this.state.feedbackDetailsResponse.TotalRecords > 1
          ? this.state.feedbackDetailsResponse.Feedbacks[1]
          : {};
      }
    }
    return feedbackDetails;
  };
  isCallCenterGuyOwnerOrRenter = (type) => {
    const ownerId = this.getInfoFromParamQuery("ownerId");
    const borrowerId = this.getInfoFromParamQuery("memberId");

    if (type === "SHARER" && ownerId && this.props.userId === Number(ownerId)) {
      return false;
    }
    if (
      type === "BORROWER" &&
      borrowerId &&
      this.props.userId === Number(borrowerId)
    ) {
      return false;
    }
    return true;
  };
  addFeedBack = (type) => {};
  render() {
    const feedbackQuestions = this.state.feedbackQuestionsResponse || {};
    const renterFeedback = this.distinguishRatingData("BORROWER");
    const ownerFeedback = this.distinguishRatingData("SHARER");
    const isRenterDisabled = this.isCallCenterGuyOwnerOrRenter("BORROWER");
    const isOwnerDisabled = this.isCallCenterGuyOwnerOrRenter("SHARER");
    return (
      <div className="formRow">
        <div className="col-lg-6">
          <FeedbackUI
            feedbackQuestions={feedbackQuestions}
            feedbackData={renterFeedback}
            isDisabled={isRenterDisabled}
          />
        </div>
        {/* <div className="col-lg-2 text-center">
          <div className="vertical-line"></div>
        </div> */}
        <div className="col-lg-6">
          <FeedbackUI
            feedbackQuestions={feedbackQuestions}
            feedbackData={ownerFeedback}
            isDisabled={isOwnerDisabled}
          />
        </div>
        {this.props.loading ? <Spinner /> : ""}
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    onGetFeedbackQuestions: () => dispatch(actions.getFeedbackQuestions()),
    onGetFeedbackQuestionsReset: () =>
      dispatch(actions.getFeedbackQuestionsReset()),
    onGetFeedbackDetails: (req) => dispatch(actions.getFeedbackDetails(req)),
    onGetFeedbackDetailsReset: () =>
      dispatch(actions.getFeedbackDetailsReset()),
    onAddFeedBack: (req) => dispatch(actions.addFeedBack(req)),
    onAddFeedBackReset: () => dispatch(actions.addFeedBackReset()),
  };
};
const mapStateToProps = (state) => {
  return {
    userId: state.auth.userId,
    loading: state.feedbackReducer.loading,
    feedbackQuestionsResponse: state.feedbackReducer.feedbackQuestionsResponse,
    feedbackDetailsResponse: state.feedbackReducer.feedbackDetailsResponse,
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(FeedbackDetails));

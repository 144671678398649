import thunkMiddleware from 'redux-thunk'
import { configureStore } from '@reduxjs/toolkit';
import {RootReducer} from './reducers/RootReducer'


// if (
//     process &&
//     process.env.NODE_ENV !== 'production' &&
//     process.browser 
//     //&& window.__REDUX_DEVTOOLS_EXTENSION__
// ) {
//     devtools = window.__REDUX_DEVTOOLS_EXTENSION__()
// }

export default configureStore({
  reducer: RootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }).concat(thunkMiddleware)
})

import React, { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Breadcrumbs from "../../../components/breadcrumbs/breadcrumbs";
import DefaultComponentWithMessage from "../../../components/common/pages/default-component-with-message";
import PageTopPanel from "../../../components/common/pages/page-top-panel";
import PreventTransitionPrompt from "../../../components/Navigations/prevent-navigation/prevent-transition-prompt";
import LinkButton from "../../../components/UI/button/link-btn";
import Spinner from "../../../components/UI/Spinner/Spinner";
import LandmarkDetailsRequest from "../../../services/entities/shuttle-app/request/LandmarkDetailsRequiest";
import PermissionName from "../../../shared/Permissions";
import {
  activeCurrentLink,
  camelizeKeys,
  clearActiveMenu,
  getDropdownItems,
  getErrorToastData,
  getInfoFromParamQuery,
  hasPermission,
  populateBreadCrumbData,
  updateObject,
} from "../../../shared/utility";
import {
  deleteLandmarkInfo,
  deleteLandmarkInfoReset,
  getLandmark,
  getLandmarkReset,
  getShuttleOption,
  getShuttleOptionReset,
  updateLandmarkInfo,
  updateLandmarkInfoReset,
} from "../../../store/actions";
import LandmarkDetailsUI from "./landmark-details-ui";
import LandmarkMaps from "./landmark-maps";
import Phone from "../../../services/entities/models/Phone";
import {
  getLandmarkMapInfo,
  validateAddEditLandmark,
} from "../landmarks-helper";
import { showNotification } from "../../../components/UI/Toaster/Toaster";
import { COMMON_MSG } from "../../../shared/GlobalVar";
import WatermarkTextComponent from "../../../components/UI/label/watermark-text";
import { convertMsgResponse } from "../../Reservation/fox-reservation/fox-res-helper";
import { ShuttleOptions } from "../../../services/entities/shuttle-app/domain/shuttle-options";
import { getShuttleOptionRequest } from "../../../shared/utility/shuttle-app-utility";
import ConfirmModal from "../../../components/common/modal/confirm-modal";
import { LandmarkViewModel } from "../../../services/entities/shuttle-app/view-model/landmark-view-model";
import { foxAPIAddressChangeHandler } from "../../../components/common/common-helper";

const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};
export const LandmarkDetailsInfo = (props) => {
  const dispatch = useDispatch();
  const [landmarkData, setLandmarkData] = useState(new LandmarkViewModel());
  const [landmarkServerData, setLandmarkServerData] = useState(new LandmarkViewModel());
  const [isBlocking, setIsBlocking] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [landmarkId, setLandmarkId] = useState("");
  const [breadCrumbList, setBreadCrumbList] = useState([]);
  const [isValidateDone, setIsValidateDone] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [deleteLandmarkRequest, setDeleteLandmarkRequest] = useState({});
  const [landmarkCode, setLandmarkCode] = useState("");
  const [isNew, setIsNew] = useState(false);
  let loader = useSelector(
    (state) =>
      state.shuttleAPIReducer.loading || state.shuttleOptionReducer.loading
  );
  const [loading, setLoading] = useState(loader);
  const landmarkDeleteResponse = useSelector(
    (state) => state.shuttleAPIReducer.landmarkDeleteResponse
  );
  const landmarkResponse = useSelector(
    (state) => state.shuttleAPIReducer.landmarkResponse
  );
  const landmarkUpdateResponse = useSelector(
    (state) => state.shuttleAPIReducer.landmarkUpdateResponse
  );
  const shuttleOptionResponse = useSelector(
    (state) => state.shuttleOptionReducer.shuttleOptionResponse || null
  );
  const [landmarkCategories, setLandmarkCategories] = useState([]);
  const [dependentOrgList, setDependentOrgList] = useState([]);

  useEffect(() => {
    initLandmarkDetailsPage();
    clearActiveMenu();
    activeCurrentLink("landmarksMenu");
  }, [props.location.search]);

  useEffect(() => {
    if (!shuttleOptionResponse) {
      return;
    }
    if (
      shuttleOptionResponse.message &&
      shuttleOptionResponse.message.messageType === "SUCCESS"
    ) {
      if (
        shuttleOptionResponse.locations &&
        shuttleOptionResponse.locations.length
      ) {
        setDependentOrgList(
          getDropdownItems(shuttleOptionResponse.locations, "key", "value")
        );
      }
      if (
        shuttleOptionResponse.landmarkCategories &&
        shuttleOptionResponse.landmarkCategories.length
      ) {
        setLandmarkCategories(
          getDropdownItems(
            shuttleOptionResponse.landmarkCategories,
            "key",
            "value",
            "Select Category"
          )
        );
      }
    } else {
      showNotification(convertMsgResponse(shuttleOptionResponse.message));
      dispatch(getShuttleOptionReset());
    }
  }, [shuttleOptionResponse]);

  useEffect(() => {
    if (!landmarkUpdateResponse) {
      return;
    }
    showNotification(convertMsgResponse(landmarkUpdateResponse.message));
    if (
      landmarkUpdateResponse.message &&
      landmarkUpdateResponse.message.messageType === "SUCCESS"
    ) {
      populateLandmarData(landmarkUpdateResponse.landmark);
      setIsEditMode(false);
      setIsBlocking(false);
    }
    dispatch(updateLandmarkInfoReset());
  }, [landmarkUpdateResponse]);

  useEffect(() => {
    if (!landmarkDeleteResponse) {
      return;
    }
    showNotification(convertMsgResponse(landmarkDeleteResponse.message));
    if (
      landmarkDeleteResponse.message &&
      landmarkDeleteResponse.message.messageType === "SUCCESS"
    ) {
      closeConfirmModal();
      goToSearchPage();
    }
    dispatch(deleteLandmarkInfoReset());
  }, [landmarkDeleteResponse]);

  useEffect(() => {
    if (!landmarkResponse) {
      return;
    }
    if (
      landmarkResponse.message &&
      landmarkResponse.message.messageType === "SUCCESS"
    ) {
      populateLandmarData(landmarkResponse.landmark);
    } else {
      showNotification(convertMsgResponse(landmarkResponse.message));
    }
    dispatch(getLandmarkReset());
  }, [landmarkResponse]);

  const closeConfirmModal = () => {
    setShowConfirmModal(false);
  };

  const getDeleteOrGetLandmarkRequest = (landmarkId, isDelete) => {
    const req = new LandmarkDetailsRequest(
      { landmarkId: landmarkId },
      isDelete ? "DELETE" : "GET"
    );
    delete req.landmark["address"];
    delete req.landmark["orgUnit"];
    delete req.landmark["phone"];
    return req;
  };

  const deleteConfirmModal = () => {
    const req = getDeleteOrGetLandmarkRequest(landmarkId, true);
    setDeleteLandmarkRequest(req);
    setShowConfirmModal(true);
  };

  const getLandamarkInfo = (req) => {
    dispatch(getLandmarkReset());
    dispatch(getLandmark(req));
  };
  const doAddOrEditLandmark = () => {
    let addEditLandmarkData = JSON.parse(JSON.stringify(landmarkData))
    let addEditLandmarkReq = new LandmarkDetailsRequest(
      addEditLandmarkData,
      "POST"
    );
    let errorMsg = validateAddEditLandmark(addEditLandmarkData);
    if (errorMsg) {
      showNotification(getErrorToastData(errorMsg));
      setIsValidateDone(true);
      return false;
    } else {
      setIsValidateDone(false);
    }
    let phoneObject = { ...addEditLandmarkReq.landmark.phone };
    phoneObject["country"] = phoneObject.CountryIso;
    phoneObject["type"] =null;
    delete phoneObject["FormattedNumber"];
    delete phoneObject["CountryIso"];
    delete phoneObject["Ext"];
    phoneObject = camelizeKeys(phoneObject);
    addEditLandmarkReq.landmark.phone = { ...phoneObject };
    let addressObject = { ...addEditLandmarkReq.landmark.address };
    addressObject["province"] = addressObject.provinceText || addressObject.province;
    addressObject["city"] = addressObject.cityText || addressObject.city;
    addressObject["postalCode"] = addressObject.postalCodeText || addressObject.postalCode;
    delete addressObject["provinceText"];
    delete addressObject["cityText"];
    delete addressObject["postalCodeText"];
    addEditLandmarkReq.landmark.address = { ...addressObject };
    dispatch(updateLandmarkInfoReset());
    dispatch(updateLandmarkInfo(addEditLandmarkReq));
  };
  const deleteLandamark = () => {
    dispatch(deleteLandmarkInfoReset());
    dispatch(deleteLandmarkInfo(deleteLandmarkRequest));
  };

  const searchShuttleOptions = () => {
    let shuttleOptions = new ShuttleOptions();
    shuttleOptions.locations = true;
    shuttleOptions.landmarkCategories = true;
    let shuttleOptionReq = getShuttleOptionRequest(shuttleOptions);
    dispatch(getShuttleOptionReset());
    dispatch(getShuttleOption(shuttleOptionReq));
  };

  const inputChangedHandler = (event, controlName, elementType) => {
    let value = event.target.value;
    let updatedControls = { ...landmarkData };
    let orgUnit = { ...landmarkData.orgUnit };
    if (controlName === "orgUnit") {
      orgUnit.locationCode = value;
    }
    updatedControls = updateObject(updatedControls, {
      [controlName]: controlName === "orgUnit" ? orgUnit : value,
    });
    setLandmarkData(updatedControls);
    setIsBlocking(true);
  };
  const renderMobileAutoSueggestion = (type, phoneInfo) => {
    let updatedControls = { ...landmarkData };
    if (!updatedControls[type]) {
      updatedControls[type] = new Phone();
    }
    updatedControls[type].CountryCode = phoneInfo ? phoneInfo.value : null;
    updatedControls[type].CountryIso = phoneInfo ? phoneInfo.countryIso : null;
    setLandmarkData(updatedControls);
    setIsBlocking(true);
  };
  const inputPhoneChangeHandlerOld = (event, controlName, elementType) => {
    let updatedControls = { ...landmarkData };
    updatedControls[controlName].Number = event.target.value;
    setLandmarkData(updatedControls);
    setIsBlocking(true);
  };
  const inputPhoneChangeHandler = (value, data, event, formattedValue) => {
    let controlName = event.target.name || "MobilePhone";
    let updatedControls = { ...landmarkData };
    updatedControls[controlName].CountryCode = data.dialCode;
    updatedControls[controlName].CountryIso = data.countryCode.toUpperCase();
    updatedControls[controlName].FormattedNumber = formattedValue
    updatedControls[controlName].Number = value;
    setLandmarkData(updatedControls);
    setIsBlocking(true);
  };

  const inputAddrChangedHandler = (event, controlName, elementType, commonData) => {
    let addressObj = { ...landmarkData.address };
        addressObj["isValidZipCode"] = true;
        addressObj = foxAPIAddressChangeHandler(event, controlName, commonData, addressObj)
    // addressObj[controlName] = typeof event === 'object' ? event.target && event.target.value || event.target.key: event;
    let updatedControls = { ...landmarkData };
    updatedControls.address = { ...addressObj };
    setLandmarkData(updatedControls);
    setIsBlocking(true);
    setLoading(false);
  };
  const onCancel = () => {
    let pathname = props.location.pathname;
    if (pathname === "/landmark" && !landmarkId) {
      props.history.push("/Dashboard");
    } else {
      setLandmarkData(landmarkServerData);
      setIsEditMode(isEditMode ? false : true);
      setIsBlocking(false);
    }
  };
  const goToSearchPage = () => {
    props.history.push("/landmarks");
  };
  const initLandmarkDetailsPage = () => {
    if (
      props.location &&
      props.location.state &&
      props.location.state.breadCrumbList
    ) {
      let breadCrumbList = props.location.state.breadCrumbList || [];
      setBreadCrumbList(breadCrumbList);
    }
    if (!shuttleOptionResponse) {
      searchShuttleOptions();
    }
    let landmarkId = getInfoFromParamQuery("landmarkId", props.location.search);
    if (landmarkId) {
      setLandmarkId(landmarkId);
      let getRequest = getDeleteOrGetLandmarkRequest(landmarkId);
      getLandamarkInfo(getRequest);
    } else {
      setLandmarkData(new LandmarkViewModel());
      setIsNew(true);
      setIsEditMode(true);
      setLandmarkId(null);
    }
  };

  const populateLandmarData = (landmarkInfo) => {
    landmarkInfo = new LandmarkViewModel(landmarkInfo);
    let addressObject = { ...landmarkInfo.address };
    addressObject["provinceText"] = addressObject.province;
    addressObject["cityText"] =  addressObject.city;
    addressObject["postalCodeText"] =  addressObject.postalCode;
    landmarkInfo.address = { ...addressObject };
    setLandmarkData(landmarkInfo);
    setLandmarkServerData(landmarkInfo);
    if (landmarkInfo.landmarkCode) {
      setLandmarkCode(landmarkInfo.landmarkCode)
      setLandmarkId(landmarkInfo.landmarkId);
      const breadCrumbList = props.location.hasOwnProperty("state")
        ? populateBreadCrumbData(
            props.location.state && props.location.state.breadCrumbList,
            "Landmarks",
            "/landmark?landmarkId=" + landmarkId
          )
        : [];
      setBreadCrumbList(breadCrumbList);
    }
  };
  return (
    <Fragment>
      <PreventTransitionPrompt when={isBlocking} />
      <div
        className="col-lg-12 breadcrumb row mb-0 pb-0"
        style={{ alignItems: "baseline" }}
      >
        {!landmarkId ? (
          <h4
            className="p-0 m-0"
            style={{
              color: "var(--color-primary)",
            }}
          >
            New Landmark
          </h4>
        ) : props.location.state ? (
          props.location.state.breadCrumbList ? (
            <Breadcrumbs
              breadCrumbList={props.location.state.breadCrumbList}
              title={
                landmarkCode ? landmarkCode : ""
              }
            />
          ) : (
            ""
          )
        ) : (
          ""
        )}

        <div className="flex-1">
          <WatermarkTextComponent
            className="watermark d-block"
            values={
              !landmarkId
                ? COMMON_MSG.LANDMARK_NEW
                : isEditMode
                ? COMMON_MSG.LANDMARK_EDIT
                : COMMON_MSG.LANDMARK_DETAILS
            }
            styles={{
              color: "darkred",
              opacity: "0.45",
            }}
          />
        </div>
      </div>
      <div className="col-lg-12 p-0 summary-form-header row pb-1 pt-1 mb-3">
        <div className="col-lg-8 p-0 row">
          <div className="row">
            <span
              className="font-weight-bold"
              style={{
                color: landmarkId
                  ? "var(--color-primary)"
                  : "var(--color-grey-primary)",
              }}
            >
              Landmark Code:
            </span>
            <span className="pl-2" style={{ position: "unset" }}>
              <label style={{ display: "inline" }}>
                {landmarkCode
                  ? landmarkCode
                  : "--"}
              </label>
            </span>
          </div>
        </div>

        {landmarkId ? (
          <div
            className="col-lg-4 p-0 row"
            style={{ justifyContent: "flex-end" }}
          >
            {landmarkId && hasPermission(PermissionName.CC_EDIT_LANDMARK) && !isEditMode ? (
              <LinkButton
                btnLabel="Edit"
                containerClass="pseudo-link mr-3 fs-2 font-weight-bold d-flex align-items-center"
                stackClass="fa-stack fs-08 vertical-align-top"
                faIconList={[
                  "fa fa-circle fa-stack-2x iconBeforeLink",
                  "fa fa-pencil-alt fa-stack-1x whiteColor m-0 mtr-01 mlr-01",
                ]}
                clicked={() => setIsEditMode(true)}
              />
            ) : (
              ""
            )}
            {landmarkId && hasPermission(PermissionName.CC_DELETE_LANDMARK) ? (
              <LinkButton
                btnLabel="Delete"
                containerClass="pseudo-link fs-2 font-weight-bold d-flex align-items-center"
                stackClass="fa-stack fs-08 vertical-align-top"
                faIconList={[
                  "fa fa-circle fa-stack-2x iconBeforeLink",
                  "fas fa-power-off fa-stack-1x whiteColor m-0 mtr-01 mlr-01",
                ]}
                clicked={deleteConfirmModal}
              />
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="row col-lg-12 p-0 m-o">
        <div className="col-lg-3 pl-0 ml-o">
          <div className="card">
            <div className="card-body" style={{ height: "50rem" }}>
              <h4
                className="form-header text-uppercase"
                style={{
                  color: "var(--color-primary)",
                }}
              >
                Landmark Position :
              </h4>
              <div style={{ minHeight: "35rem" }}>
                <LandmarkMaps
                  latitude={landmarkData.latitude}
                  longitude={landmarkData.longitude}
                  mapData={getLandmarkMapInfo([{ ...landmarkData }])}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-9 pr-0 mr-o">
          <div className="card">
            <div className="card-body" style={{ height: "50rem" }}>
              <h4 className="form-header text-uppercase">Landmark Info :</h4>
              <LandmarkDetailsUI
                landmarkData={landmarkData}
                dependentOrgList={dependentOrgList}
                landmarkCategories={landmarkCategories}
                isEditMode={isEditMode}
                inputChangedHandler={inputChangedHandler}
                inputAddressChangeHandler={inputAddrChangedHandler}
                renderMobileAutoSueggestion={renderMobileAutoSueggestion}
                inputPhoneChangeHandler={inputPhoneChangeHandler}
                onCancel={onCancel}
                doAddOrEditLandmark={doAddOrEditLandmark}
                isValidateDone={isValidateDone}
                isNew={isNew}
                setLoading = {setLoading}
                setLandmarkData = {setLandmarkData}
              />
            </div>
          </div>
        </div>
        {showConfirmModal ? (
          <ConfirmModal
            body={COMMON_MSG.DeleteRecordMsg}
            showModal={showConfirmModal}
            closeModal={closeConfirmModal}
            doConfirmFunctionality={deleteLandamark}
          />
        ) : (
          ""
        )}
        {loading ? <Spinner /> : ""}
      </div>
    </Fragment>
  );
};
export default LandmarkDetailsInfo;

import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CopyToClipboard from "../../../../components/copy-to-clipboard/copy-to-clipboard";
import CommonButton from "../../../../components/UI/button/common-button";
import { showNotification } from "../../../../components/UI/Toaster/Toaster";
import { COPY_TO_CLIPBOARD, EDIT_02 } from "../../../../shared/AppImages";
import { getBasicToastResponse } from "../../../../shared/utility";
import {
  updateReservationNotes,
  updateReservationNotesReset,
} from "../../../../store/actions";

const RenterComments = ({ renterComments, callCenterNotes, setRenterComments, setCallCenterNotes, label, reservationUiModel, reservationNotesType }) => {
  const dispatch = useDispatch();
  const textRef = useRef(null);
  const [expanded, setExpanded] = useState(false);
  const [showMoreButton, setShowMoreButton] = useState(false);
  const [showMoreCallCenterNotesButton, setShowMoreCallCenterNotesButton] = useState(false);
  const [renterCommentsLocal, setRenterCommentsLocal] =
    useState(renterComments);
  const [callCenterNotesLocal, setCallCenterNotesLocal] =
    useState(callCenterNotes);
  const [isEditMode, setIsEditMode] = useState(false);
  const updateNotesResponse = useSelector(
    (state) => state.reservationReducer.updateNotesResponse
  );
  const isRentalComments = reservationNotesType === "BORROWER";

  useEffect(() => {
    isRentalComments ? setRenterCommentsLocal(renterComments) : setCallCenterNotesLocal(callCenterNotes);
    const lineHeight = parseInt(window.getComputedStyle(textRef.current).lineHeight);
    const maxHeight = lineHeight * 2; // Maximum height for two lines

    // Check if text exceeds two lines
    if (textRef.current.scrollHeight > maxHeight) {
      setShowMoreButton(true);
      setShowMoreCallCenterNotesButton(true);
    }
  }, [isRentalComments ? renterComments : callCenterNotes]);

  useEffect(() => {
    if (!updateNotesResponse) return;
    if (updateNotesResponse.StatusCode === 0) {
      if (isRentalComments) {
        setRenterCommentsLocal(updateNotesResponse.BorrowerNotes);
        setRenterComments(updateNotesResponse.BorrowerNotes);
      } else {
        setCallCenterNotesLocal(updateNotesResponse.CallCenterNotes);
        setCallCenterNotes(updateNotesResponse.CallCenterNotes);
      }
      setIsEditMode(false);
      setShowMoreButton(false);
      setShowMoreCallCenterNotesButton(false);
    }
    showNotification(getBasicToastResponse(updateNotesResponse));
    dispatch(updateReservationNotesReset());
  }, [updateNotesResponse]);

  const handleCancelChanges = () => {
    setIsEditMode(false);
    if (isRentalComments) {
      setRenterCommentsLocal(renterComments);
    } else {
      setCallCenterNotesLocal(callCenterNotes);
    }
  };

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  const handleSaveChanges = () => {
    const updateNotesReq = {
      ReservationId: reservationUiModel.reservationId,
      ReservationNotesType: reservationNotesType,
      Notes: isRentalComments ? renterCommentsLocal : callCenterNotesLocal,
    };
    dispatch(updateReservationNotes(updateNotesReq));
  };

  return (
    <div className={`${isRentalComments ? "renter-comments-section" : "callcenter-notes-section"}`}>
      <div className="renter-notes-title-actions">
        <div className="renter-notes-title">
          <label className="pr-2">{label}</label>
          <CopyToClipboard
            value={isRentalComments ? renterComments : callCenterNotes}
            copyLinkType={"text-image"}
            linkCaption=" "
            imageClass="mt-1"
            imageURL={COPY_TO_CLIPBOARD}
            customImgCSS={{ height: "1.8rem" }}
            successMsg={`${label} has been copied to clipboard!`}
            toolTipText={`Copy ${label}`}
          />
        </div>
        <div className="res-summary-actions">
          {isEditMode ? (
            <>
              <div className="summary-action-button">
                <CommonButton
                  buttonClasses="btn btn-outline-danger action-button"
                  onClickAction={handleCancelChanges}
                  buttonLabel="Discard"
                  title="Discard changes"
                  customStyle={{ padding: "0.8rem" }}
                />
              </div>
              <div className="summary-action-button">
                <CommonButton
                  buttonClasses="btn btn-yellow-default action-button"
                  onClickAction={handleSaveChanges}
                  buttonLabel="Save"
                  title="Save changes"
                  customStyle={{ padding: "0.8rem" }}
                />
              </div>
            </>
          ) : (
            <div
              className="action-icon"
              title="Click to Edit"
              onClick={() => setIsEditMode(true)}
            >
              <img
                className="edit-icon"
                src={EDIT_02}
                height={18}
                width={18}
                alt={label}
              />
            </div>
          )}
        </div>
      </div>
      <div className={`${isRentalComments ? "renterComments" : "callCenterNotes"}`}>
        {isEditMode ? (
          <textarea
            id={isRentalComments ? "renterComments" : "callCenterNotes"}
            className={`txtbox_style ${isEditMode ? "editmode" : ""}`}
            name={isRentalComments ? "renterComments" : "callCenterNotes"}
            style={{ height: "6rem" }}
            value={isRentalComments ? renterCommentsLocal : callCenterNotesLocal}
            onChange={(event) => isRentalComments ? setRenterCommentsLocal(event.target.value) : setCallCenterNotesLocal(event.target.value)}
            autoFocus={true}
          />
        ) : (
          <label
            className={`d-block pt-4 pl-2 label-with-more ${expanded ? "expanded" : ""
              }`}
            onClick={toggleExpanded}
          >
            <div
              ref={textRef}
              className={`line-clamp ${expanded ? "expanded" : ""}`}
            >
              {isRentalComments ? renterComments || "--" : callCenterNotes || "--"}
            </div>
            {(showMoreButton || showMoreCallCenterNotesButton) && (
              <span className="more">{expanded ? "Less" : "More"}</span>
            )}
          </label>
        )}
      </div>
    </div>
    // </div >
  );
};

export default RenterComments;

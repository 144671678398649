import React, { Component, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

export const Hover = (props) => {
  // static propTypes = {
  //   type: PropTypes.string,
  //   children: PropTypes.object,
  //   styles: PropTypes.object,
  //   setVisibility: PropTypes.func,
  //   getCursorPos: PropTypes.func,
  // };
  const [tooltipWidth, setTooltipWidth] = useState(0);
  const [tooltipHeight, setTooltipHeight] = useState(0);
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current) {
      setTooltipWidth(ref.current.offsetWidth);
      setTooltipHeight(ref.current.offsetHeight);
    }
  }, [props.children.props.styles.mouseXCordinate]);

  const onMouseOver = (e) => {
    const { setVisibility, getCursorPos } = props.children.props;

    //setVisibility(true);
    // getCursorPos(e);
  };

  const onMouseOut = () => {
    const { setVisibility } = props.children.props;
    setVisibility(false);
  };

  const onMouseMove = (e) => {
    // const { getCursorPos } = this.props.children.props;
    // getCursorPos(e);
  };

  const getCurrentStyles = () => {
    let win = window,
      doc = document,
      docElem = doc.documentElement,
      body = doc.getElementsByTagName("body")[0],
      modalWindow = doc.getElementsByClassName("modal-dialog");

    let isModal = !!modalWindow.length;
    let modalClientWidth = isModal ? modalWindow[0].clientWidth : 0;

    let x = win.innerWidth || docElem.clientWidth || body.clientWidth;
    let y = win.innerHeight || docElem.clientHeight || body.clientHeight;
    const currentTPStyle = { ...props.children.props.styles };

    if (
      (isModal
        ? parseFloat(currentTPStyle.left)
        : parseFloat(currentTPStyle.mouseXCordinate)) +
        parseFloat(tooltipWidth) +
        50 >
      (isModal ? parseFloat(modalClientWidth) : parseFloat(x))
    ) {
      currentTPStyle.left =
        parseFloat(currentTPStyle.left) - parseFloat(tooltipWidth);
    }
    // if(otherOptions && otherOptions.modalId){
    //   //const pWidth = document.getElementById("historyDropMenu").offsetWidth;
    //   if(document.getElementById(otherOptions.modalId)){
    //     const pTop = document.getElementById(otherOptions.modalId).getBoundingClientRect().top;
    //     const modalY= document.getElementById(otherOptions.modalId).getBoundingClientRect().height;
    //     const pBootom = document.getElementById(otherOptions.modalId).getBoundingClientRect().bottom;
    //     const pLeft = document.getElementById(otherOptions.modalId).getBoundingClientRect().left;
    //     const pRight = document.getElementById(otherOptions.modalId).getBoundingClientRect().right;
    //    // currentTPStyle.top=ref.current.offsetTop+50;
    //     currentTPStyle.left = currentTPStyle.left-pLeft;
    //   }
    // }
    return currentTPStyle;
  };
  const { otherOptions } = props.children.props;
  return (
    <div
      onMouseOver={(event) => onMouseOver(event)}
      onMouseOut={(event) => onMouseOut(event)}
      onMouseMove={(event) => onMouseMove(event)}
      style={getCurrentStyles()}
      className={
        otherOptions && otherOptions.ttcClass
          ? otherOptions.ttcClass + " tooltipContainer"
          : "tooltipContainer"
      }
      ref={ref}
    >
      {props.children.props.children}
    </div>
  );
};
export default Hover;

import { FoxRequestHeader } from "../../fox-api/domain/fox-request-header";

export const ShuttleOptionsStaticModel = function (data) {
  if (!data) data = {};
  this.locations = false;
  this.allShuttles = false;
  this.locationShuttles = false;
  this.shuttleLocationCode = null;
  this.driverLocationCode = null;
  this.allDrivers = false;
  this.locationDrivers = false;
  this.landmarkStatuses = false;
  this.landmarkCategories = false;

  /**i assume following property alwyas return static value */
  this.unitSystems=true;
  this.engineStatuses = true;
  this.amenities = true;
  this.availabilities = true;
  this.drivingTypes = true;
  this.fuelTypes = true;
  this.fuelConsumptionUnitTypes = true;
  this.transMissionTypes = true;
  this.colorTypes = true;
  this.requestHeader = new FoxRequestHeader({requestType:'GET'});
};

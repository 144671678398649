import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory, withRouter } from "react-router-dom";
import Breadcrumbs from "../../../../components/breadcrumbs/breadcrumbs";
import { getDateTimeFormatString } from "../../../../components/common/common-helper";
import ModalEngine from "../../../../components/modal/modal-engine";
import Spinner from "../../../../components/UI/Spinner/Spinner";
import { showNotification } from "../../../../components/UI/Toaster/Toaster";
import { FoxRequestHeader } from "../../../../services/entities/fox-api/domain/fox-request-header";
import { FoxCustomerViewModel } from "../../../../services/entities/fox-api/view-model/fox-customer-view-model";
import { FoxResSummaryViewModel } from "../../../../services/entities/fox-api/view-model/fox-res-summary-view-model";
import ModalInfo from "../../../../services/entities/models/ModalInfo";
import { QuickHistoryViewModel } from "../../../../services/entities/view-models/QuickHistoryViewModel";
import {
  IS_ALLOWED_PREPAID_RESERVATION,
  ModalType,
  QuickHistoryType,
} from "../../../../shared/GlobalVar";
import { AddToQuickHistory } from "../../../../shared/historyDataManagement";
import {
  activeCurrentLink,
  clearActiveMenu,
  getFullName,
  getInfoFromParamQuery,
  getRootBreadcurmbList,
  populateBreadCrumbData
} from "../../../../shared/utility";
import {
  cancelReservationForFoxReset,
  getLocationSettingsInfo,
  getLocationSettingsInfoReset,
  getReservationForFox,
  getReservationForFoxReset,
  getReservationForFoxSuccess,
  getSecurityDepositCharges,
  getSecurityDepositChargesReset
} from "../../../../store/actions/index";
import {
  getReservationModalDefaultInfo_new,
} from "../../reservation-helper";
import {
  convertFoxApiDateToStr,
  convertMsgResponse,
  createGetlReservationReq,
} from "../fox-res-helper";
import FoxPricingTable from "../pricing/fox-pricing-table-new";
import "../pricing/fox-res-pricing-table.scss";
import SecurityDeposit from "../security-deposit/security-deporit";
import SummaryActionsBlock from "./fox-res-summary-actions-block";
import SummaryBasicInfoBlock from "./fox-res-summary-basic-info-block";
import SummaryCustomerInfoBlock from "./fox-res-summary-customer-info-block";
import "./fox-res-summary-details.scss";
import SummaryOthersInfoBlock from "./fox-res-summary-others-info";
import SummaryTopBar from "./fox-res-summary-top-bar";
import CustomerNotes from "./renter-comments";
export const FoxResSummary = (props) => {
  const currentReservationCode = getInfoFromParamQuery(
    "reservationId",
    props.location.search
  );
  const loading = useSelector(
    (state) =>
      state.foxAPIReducer.loading ||
      state.enumvaluesReducer.loading ||
      state.reservationReducer.loading ||
      state.transactionReducer.loading ||
      state.memberReducer.loading ||
      state.vehicleReducer.loading ||
      state.locationReducer.loading ||
      state.adminMailSendReducer.loading ||
      state.securityDepositReducer.loading
  );
  const [isLoading, setIsLoading] = useState(false);
  const foxGetReservationResponse = useSelector(
    (state) => state.foxAPIReducer.foxGetReservationResponse
  );
  const locationSettingsResponse = useSelector(
    (state) => state.settingsReducer.locationSettingsInfoSearchResponse
  );
  const securityDepositResponse = useSelector(
    (state) => state.securityDepositReducer.securityDepositResponse
  );
  const dependentOrgList = useSelector((state) =>
    state.auth.loggedInUserData
      ? state.auth.loggedInUserData.DependantOrgList
      : []
  );
  const [breadCrumbList, setBreadCrumbList] = useState(
    props.location.hasOwnProperty("state")
      ? populateBreadCrumbData(
        props.location.state.breadCrumbList,
        currentReservationCode,
        "/reservationSummary?reservationId=" + currentReservationCode
      )
      : []
  );
  const [securityDepositRate, setSecurityDepositRate] = useState(null);
  const [redirectToEditPage, setRedirectToEditPage] = useState(null);
  const [redirectToNewPage, setRedirectToNewPage] = useState(null);
  const [renterComments, setRenterComments] = useState("");
  const [callCenterNotes, setCallCenterNotes] = useState("");
  const [modalInfo, setModalInfo] = useState([new ModalInfo()]);
  const [reservationUiModel, setReservationUiModel] = useState(
    new FoxResSummaryViewModel()
  );
  const [foxCustomerViewModel, setFoxCustomerViewModel] = useState(
    new FoxCustomerViewModel()
  );
  const [quickHistoryData, setQuickHistoryData] = useState(
    new QuickHistoryViewModel()
  );
  const [pickupLocationId, setPickupLocationId] = useState(null);
  const [customerPaymentUrl, setCustomerPaymentUrl] = useState(null);
  const isAllowedPrePaidReservation = IS_ALLOWED_PREPAID_RESERVATION;
  const clearState = (isHistory) => {
    if (!isHistory) {
      setReservationUiModel(new FoxResSummaryViewModel());
      setFoxCustomerViewModel(new FoxResSummaryViewModel());
      setModalInfo(new ModalInfo());
      setRedirectToEditPage(null);
      setSecurityDepositRate(null);
      setRedirectToNewPage(null);
      setIsLoading(false);
      setRenterComments("")
      setCallCenterNotes("")
    }
  };
  const resetAllStoreData = () => {
    dispatch(getReservationForFoxReset());
    dispatch(cancelReservationForFoxReset());
    dispatch(getSecurityDepositChargesReset());
  };
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    const unlisten = history.listen(() => {
      resetAllStoreData();
      clearState(true);
    });
    return () => {
      unlisten();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);
  useEffect(() => {
    clearState();
  }, []);
  useEffect(() => {
    if (currentReservationCode) {
      getReservationData(currentReservationCode);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentReservationCode]);
  useEffect(() => {
    getLocationInfo(pickupLocationId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pickupLocationId]);
  useEffect(() => {
    if (securityDepositResponse && securityDepositResponse?.ccDeposit) {
      setSecurityDepositRate(securityDepositResponse?.ccDeposit);
    } else {
      setSecurityDepositRate(null);
    }
  }, [securityDepositResponse]);
  useEffect(() => {
    if (!foxGetReservationResponse) {
      return;
    }
    localStorage.setItem("LAST_FETCHED_TIME_INTEG_HISTORY", '');
    if (
      foxGetReservationResponse.message &&
      foxGetReservationResponse.message.messageType === "SUCCESS"
    ) {
      setReservationUiModel(
        new FoxResSummaryViewModel(foxGetReservationResponse.reservation)
      );
      setFoxCustomerViewModel(
        new FoxCustomerViewModel(foxGetReservationResponse.reservation.customerInfo)
      )
      const resData = foxGetReservationResponse.reservation
        ? foxGetReservationResponse.reservation
        : {};
      const securityDeposit = resData?.securityDeposit;
      setSecurityDepositRate(securityDeposit)
      if (resData?.rentalLocation?.locationCode && resData?.customerInfo?.addresses.length) {
        const primaryAddr = getPrimaryAddress(resData?.customerInfo?.addresses);
        if (primaryAddr && primaryAddr.country && primaryAddr.postalCode && resData.vehicleClass.classCode && !securityDeposit && resData.reservationStatus === "PAYMENT_PENDING") {
          getSecurityDepositChargesData(resData?.rentalLocation?.locationCode, primaryAddr.country, primaryAddr.postalCode, resData.vehicleClass.classCode);
        }
      }
      setPickupLocationId(
        resData.rentalLocation ? resData.rentalLocation.locationId : ""
      );
      setCustomerPaymentUrl(resData.customerPaymentUrl || null)
      setBreadCrumbList(
        getRootBreadcurmbList(breadCrumbList, resData.confirmationCode)
      );
      setRenterComments(resData.renterComments)
      setCallCenterNotes(resData.callCenterNotes)
      setQuickHistoryData(
        new QuickHistoryViewModel({
          Id: resData.confirmationCode,
          ReservationCode: resData.confirmationCode,
          ExternalCode: resData.integResvConfNum,
          ReservationId: resData.reservationId,
          Name: getFullName(
            resData.customerInfo.firstName,
            resData.customerInfo.lastName,
            resData.customerInfo.middleName
          ),
          ReservationState: resData.reservationState,
          StartDate: convertFoxApiDateToStr(
            resData.rentalDateTime,
            getDateTimeFormatString(true, false, true),
            "--"
          ),
          EndDate: convertFoxApiDateToStr(
            resData.returnDateTime,
            getDateTimeFormatString(true, false, true),
            "--"
          ),
          PickupLocation: resData.rentalLocation.name,
          TimezoneString: resData.timezoneString,
        })
      );
      dispatch(getReservationForFoxReset());
    } else {
      showNotification(convertMsgResponse(foxGetReservationResponse.message));
      dispatch(getReservationForFoxReset());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [foxGetReservationResponse]);

  useEffect(() => {
    if (quickHistoryData.Id) {
      if (!reservationUiModel.isRental) {
        AddToQuickHistory(quickHistoryData, QuickHistoryType.RESERVATION);
      } else {
        AddToQuickHistory(quickHistoryData, QuickHistoryType.RENTAL);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quickHistoryData.Id]);
  const getPrimaryAddress = (addressList) => {
    let addr = null;
    addressList.map((address) => {
      if (address.primary) {
        addr = { ...address };
        return false;
      }
      return true;
    });
    return addr;
  }
  const getSecurityDepositChargesData = (rentalLocationCode, country, postalCode, classCode) => {
    if (rentalLocationCode &&
      country &&
      postalCode && classCode) {
      const newReq = {
        requestHeader: new FoxRequestHeader(),
        locationCode: rentalLocationCode,
        classCode: classCode,
        address: {
          country: country,
          postalCode: postalCode
        }
      }
      dispatch(getSecurityDepositChargesReset());
      dispatch(getSecurityDepositCharges(newReq));
    } else {
      setSecurityDepositRate(null);
      //dispatch(getSecurityDepositChargesReset());
    }

  };
  const getLocationInfo = (locationId) => {
    if (locationId) {
      const req = {};
      req.SelectedChildOrgUnitId = locationId;
      dispatch(getLocationSettingsInfoReset());
      dispatch(getLocationSettingsInfo(req));
    }
  };
  const getReservationData = (currentReservationCode) => {
    localStorage.setItem("LAST_FETCHED_TIME_INTEG_HISTORY", '');
    const rescode = currentReservationCode
      ? currentReservationCode
      : reservationUiModel.confirmationCode;
    const req = createGetlReservationReq(rescode);
    dispatch(getReservationForFoxReset());
    dispatch(getReservationForFox(req));
  };
  const updateReservationReposnse = (updatedResponse) => {
    dispatch(getReservationForFoxReset());
    dispatch(getReservationForFoxSuccess(updatedResponse));
  };
  const closeModal = () => {
    setModalInfo(new ModalInfo());
  };
  const showModal = (modalData) => {
    setModalInfo({
      type: modalData.type,
      showModal: true,
      memberId: reservationUiModel.memberId,
      vehicleId: reservationUiModel.vehicleId,
      reservationId: reservationUiModel.reservationId,
      locationId: modalData.locationId,
      notesOrMailType: modalData.notesOrMailType,
      creditHistoryViewOnly: modalData.creditHistoryViewOnly,
      title: modalData.title,
      valueToUpdate: modalData.valueToUpdate
    });
  };
  const goToEditReservationPage = () => {
    const isEditAllowed = dependentOrgList.some(
      (item) =>
        parseInt(item.Key, 10) ===
        parseInt(reservationUiModel.rentalLocationId, 10)
    );
    if (isEditAllowed) {
      setRedirectToEditPage(
        <Redirect
          to={{
            pathname:
              reservationUiModel &&
                reservationUiModel.reservationState === "COMPLETED"
                ? "/endRental"
                : "/updateReservation",
            search:
              "?reservationId=" +
              reservationUiModel.reservationId +
              "&externalCode=" +
              reservationUiModel.confirmationCode,
            state: {
              breadCrumbList: breadCrumbList,
              summaryReservationData: reservationUiModel || {},
              requestType: "EDIT",
            },
          }}
        />
      );
    } else {
      showModal({
        type: ModalType.EDIT_RESERVATION_NOT_ALLOWED_MODAL,
      });
    }
  };
  const goToNewReservationPage = () => {
    setRedirectToNewPage(
      <Redirect
        to={{
          pathname: "/updateReservation"
        }}
      />
    );
  };

  let hasReservationData = Object.keys(reservationUiModel).length !== 0;
  if (hasReservationData) {
    clearActiveMenu();
    !reservationUiModel.isRental
      ? activeCurrentLink("reservationMenu")
      : activeCurrentLink("rentalMenu");
  }

  // const vehicleInfo =reservationUiModel ? reservationUiModel.vehicleInfo : null
  return (
    <Fragment>
      {redirectToEditPage ? redirectToEditPage : ""}
      {redirectToNewPage ? redirectToNewPage : ""}
      {props.location.state ? (
        props.location.state.breadCrumbList ? (
          <Breadcrumbs
            breadCrumbList={props.location.state.breadCrumbList}
            title={
              reservationUiModel ? reservationUiModel.confirmationCode : ""
            }
          />
        ) : (
          ""
        )
      ) : (
        ""
      )}
      <div
        id="fox-reservation-rental-summary"
        className="justify-content-center"
      >
        <div className="res-summary-container-column  ">
          <SummaryActionsBlock
            reservationUiModel={reservationUiModel}
            isPayLater={reservationUiModel.isPayLater}
            goToEditReservationPage={goToEditReservationPage}
            goToNewReservationPage={goToNewReservationPage}
            reloadReservationData={getReservationData}
            showModal={showModal}
            isAllowedPrePaidReservation={isAllowedPrePaidReservation}
            allowSubmitReservation={reservationUiModel?.allowSubmitReservation}
            updateReservationReposnse={updateReservationReposnse}
            setIsLoading={setIsLoading}
          />
          <SummaryTopBar
            showModal={showModal}
            specificPricingData={reservationUiModel.pricing}
            reservationUiModel={reservationUiModel}
            customerPaymentUrl={customerPaymentUrl}
          />
          <div className="summary-bottom-container">
            <div className="summary-bottom-left-sub-container">
              <SummaryBasicInfoBlock
                showModal={showModal}
                reservationUiModel={reservationUiModel}
              />
            </div>
            <div className="summary-bottom-right-sub-container">
              <SummaryCustomerInfoBlock
                showModal={showModal}
                foxCustomerViewModel={foxCustomerViewModel}
                breadCrumbList={breadCrumbList}
                setLoader={setIsLoading}
              />
              <SummaryOthersInfoBlock
                showModal={showModal}
                specificPricingData={reservationUiModel.pricing}
                reservationUiModel={reservationUiModel}
              />
              <div className={"reservation-comments-section"}>
                <CustomerNotes
                  label="Rental Special Request"
                  renterComments={renterComments}
                  setRenterComments={setRenterComments}
                  reservationUiModel={reservationUiModel}
                  reservationNotesType="BORROWER"
                />
                <CustomerNotes
                  label="Internal Comments"
                  callCenterNotes={callCenterNotes}
                  setCallCenterNotes={setCallCenterNotes}
                  reservationUiModel={reservationUiModel}
                  reservationNotesType="CALL_CENTER"
                />
              </div>

            </div>
          </div>
        </div>
        <div className="res-summary-container-column  ">
          <FoxPricingTable
            specificPricingData={reservationUiModel.pricing}
            foxGetAddOnsResponse={reservationUiModel.pricing.addOns}
            foxSelectedVehicleInfo={reservationUiModel?.vehicleInfo?.savedCarClass}
            sectionHeader={"Price Details"}
            showModal={showModal}
            reservationUiModel={reservationUiModel}
            defaultPageTitle="No Price Details"
            customerFreeCredit={reservationUiModel?.foxCredit}
          />
          {!reservationUiModel.isPayLater && securityDepositRate && <SecurityDeposit securityDepositRate={securityDepositRate}
          />}
        </div>

      </div>
      {loading || isLoading ? <Spinner /> : ""}
      {modalInfo.showModal ? (
        <ModalEngine
          modalType={modalInfo.type}
          showModal={modalInfo.showModal}
          closeModal={closeModal}
          vehicleId={reservationUiModel.vehicleId}
          memberId={reservationUiModel.customerId}
          reservationId={reservationUiModel.reservationId}
          reservationCode={currentReservationCode}
          customerEmail={reservationUiModel.customerInfo ? reservationUiModel.customerInfo.email : ""}
          locationId={modalInfo.locationId}
          contractAccepted={reservationUiModel.contractAccepted || null}
          defaultData={getReservationModalDefaultInfo_new(
            modalInfo.type,
            reservationUiModel,
            modalInfo.notesOrMailType
          )}
          resetParentPage={getReservationData}
          locationData={
            locationSettingsResponse
              ? locationSettingsResponse.LocationInfo
              : {}
          }
          vehicleData={
            locationSettingsResponse
              ? locationSettingsResponse.LocationInfo
              : {}
          }
          creditHistoryViewOnly={modalInfo.creditHistoryViewOnly}
          title={modalInfo.title}
          isEnableVoucherModal={reservationUiModel.enableVoucherModal}
          setIsLoading={setIsLoading}
          valueToUpdate={modalInfo.valueToUpdate}
        />
      ) : null}
    </Fragment>
  );
};
export default withRouter(FoxResSummary);

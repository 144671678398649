import moment from "moment";
import ReactHtmlParser from "react-html-parser";
import {
  ADMIN_PAGE,
  MAX_EXPORT_RECORD,
  PAGE_GROUP_TYPE,
  SearchAPI,
} from "../../../shared/GlobalVar";
import { getDataFromStorage } from "../../../shared/LocalStorage";
import {
  activeCurrentLink,
  clearActiveMenu,
  getDateDifferentInDays,
  getDateTimeString,
  getInfoFromParamQuery,
  hasPermission,
  isNullOrEmpty,
  populateBreadCrumbData,
  populateDropdownData,
  updateObject,
} from "../../../shared/utility";

import { getSettingPageName } from "../../../containers/Settings/settingsHelper";
import { getVehicleSubPageName } from "../../../containers/vehicle/vehicle-helper";

import { getMemberSearchCtrls } from "../../../containers/Member/search/search-member-input";
import {
  getMemberReservationCtrls,
  getReservationCtrls,
} from "../../../containers/Reservation/search/search-reservation-input";
import { getSearchActivityCtrls } from "../../../containers/activity/search-activity-input";
import {
  getBillingSearchCtrls,
  getMemberBillingSearchCtrls,
} from "../../../containers/billing/search-billing-input";
import { getSearchComplaintsCtrls } from "../../../containers/complaints/search/search-complaints-input";
import { getSearchImplementationCtrls } from "../../../containers/implementation/search/search-implementation-input";
import { getReportProblemCtrls } from "../../../containers/report/details/report-problem-input";
import {
  getMechanicReportCtrls,
  getReadyLineAgentReportCtrls,
  getServiceAgentReportCtrls,
} from "../../../containers/report/search/search-service-task-input";
import { getSearchRolesPermissionCtrls } from "../../../containers/roles-permissions/search/search-roles-permissions-input";
import { getVehicleSearchCtrls } from "../../../containers/vehicle/search/search-vehicle-input";

import ReservationSearchInfo from "../../../services/entities/models/ReservationSearchInfo";
import BillingSearchRequest from "../../../services/entities/requests/BillingSearchRequest";
import { FavouriteAssetsRequest } from "../../../services/entities/requests/FavouriteAssetsRequest";
import FeedbackSearchRequest from "../../../services/entities/requests/FeedbackSearchRequest";
import { MemberActivitySearchRequest } from "../../../services/entities/requests/MemberActivitySearchRequest";
import { MemberMessageSearchRequest } from "../../../services/entities/requests/MemberMessageSearchRequest";
import MemberSearchRequest from "../../../services/entities/requests/MemberSearchRequest";
import { ReportProblemDetailsRequest } from "../../../services/entities/requests/ReportProblemDetailsRequest";
import ReservationSearchRequest from "../../../services/entities/requests/ReservationSearchRequest";
import RolesPermissionsSearchRequest from "../../../services/entities/requests/RolesPermissionsSearchRequest";
import SearchReadyLineAgentRequest from "../../../services/entities/requests/SearchReadyLineAgentRequest";
import SearchServiceTasksRequest from "../../../services/entities/requests/SearchServiceTasksRequest";
import VehicleSearchRequest from "../../../services/entities/requests/VehicleSearchRequest";
import PermissionName from "../../../shared/Permissions";
import {
  getMemberLabelByOrgRentalType,
  isP2POrHybridOrg,
} from "../../../shared/utility/system-utility";
import { getReservationDetailsPageName } from "../../Reservation/reservation-helper";
import { getSearchFavouritesCtrls } from "../../favourite/search-favourite-input";
import { getLandmarksSearchCtrls } from "../../landmarks/search/search-landmarks-input";
import { getSearchMessageCtrls } from "../../message/search-message-input";
import { getShuttlesSearchCtrls } from "../../shuttles/search/search-shuttles-input";

import PageInfo from "../../../services/entities/models/PageInfo";
import LandmarkSearchRequest from "../../../services/entities/shuttle-app/request/LandmarkSearchRequest";
import SearchShuttleRequest from "../../../services/entities/shuttle-app/request/SearchShuttleRequest";
import ShuttleTripSearchRequest from "../../../services/entities/shuttle-app/request/ShuttleTripSearchRequest";
import SearchFleetUtilizationForecastRequest from "../../../services/entities/shuttle-app/request/search-fleet-utilization-forecast-request";
import { convertFoxApiDateToStr } from "../../Reservation/fox-reservation/fox-res-helper";
import { getFleetutilizationForecastSearchCtrls } from "../../fleet-utilization/search/search-fleet-utilization-forecast-input";
import { getShuttleTripsSearchCtrls } from "../../report/shuttle-trips/search/search-shuttle-trips-input";

export const getSearchInputCtrlByUrl = (url, isModal = {}) => {
  return isModal
    ? getExactSearchInputCtrl(url)
    : getDataFromStorage(getPageName(url)) || getExactSearchInputCtrl(url);
};
export const getExactSearchInputCtrl = (urlOrPageName) => {
  return urlOrPageName === "/member/memberReservation" ||
    urlOrPageName === "/member/memberVehicleReservation"
    ? JSON.parse(JSON.stringify(getMemberReservationCtrls(urlOrPageName)))
    : urlOrPageName === "/reservation" || urlOrPageName === "/rental"
    ? JSON.parse(JSON.stringify(getReservationCtrls(urlOrPageName)))
    : urlOrPageName === "/billing" ||
      urlOrPageName === "/reservation_details/billing"
    ? JSON.parse(JSON.stringify(getBillingSearchCtrls(urlOrPageName)))
    : urlOrPageName === "/complaints"
    ? JSON.parse(JSON.stringify(getSearchComplaintsCtrls()))
    : urlOrPageName === "/implementations"
    ? JSON.parse(JSON.stringify(getSearchImplementationCtrls()))
    : urlOrPageName === "/member/billing"
    ? JSON.parse(JSON.stringify(getMemberBillingSearchCtrls(urlOrPageName)))
    : urlOrPageName === "/report/maintenance"
    ? JSON.parse(JSON.stringify(getServiceAgentReportCtrls(urlOrPageName)))
    : urlOrPageName === "/report/mechanics"
    ? JSON.parse(JSON.stringify(getMechanicReportCtrls(urlOrPageName)))
    : urlOrPageName === "/report/readyLineAgent"
    ? JSON.parse(JSON.stringify(getReadyLineAgentReportCtrls(urlOrPageName)))
    : urlOrPageName === "/report/shuttleTrips"
    ? JSON.parse(JSON.stringify(getShuttleTripsSearchCtrls()))
    : urlOrPageName === "/member"
    ? JSON.parse(JSON.stringify(getMemberSearchCtrls()))
    : urlOrPageName === "/vehicle"
    ? JSON.parse(JSON.stringify(getVehicleSearchCtrls()))
    : urlOrPageName === "/rolesPermission"
    ? JSON.parse(JSON.stringify(getSearchRolesPermissionCtrls()))
    : urlOrPageName === "/shuttles"
    ? JSON.parse(JSON.stringify(getShuttlesSearchCtrls()))
    : urlOrPageName === "/landmarks"
    ? JSON.parse(JSON.stringify(getLandmarksSearchCtrls()))
    : urlOrPageName === "/fleet-utilization-forecast"
    ? JSON.parse(JSON.stringify(getFleetutilizationForecastSearchCtrls()))
    : urlOrPageName === "/member/memberActivity"
    ? JSON.parse(JSON.stringify(getSearchActivityCtrls()))
    : urlOrPageName === "/member/memberMessages"
    ? JSON.parse(JSON.stringify(getSearchMessageCtrls()))
    : urlOrPageName === "/member/memberFavorites"
    ? JSON.parse(JSON.stringify(getSearchFavouritesCtrls()))
    : urlOrPageName === "/member/memberVehicle"
    ? JSON.parse(JSON.stringify(getSearchFavouritesCtrls()))
    : urlOrPageName === "/reservation_details/report_problem"
    ? JSON.parse(JSON.stringify(getReportProblemCtrls()))
    : "";
};
export const getPageName = (url) => {
  return url === "/member"
    ? ADMIN_PAGE.MEMBER_SEARCH_PAGE
    : url === "/vehicle"
    ? ADMIN_PAGE.VEHICLE_SEARCH_PAGE
    : url === "/newVehicle"
    ? ADMIN_PAGE.NEW_VEHICLE_PAGE
    : url === "/reservation"
    ? ADMIN_PAGE.RESERVATION_SEARCH_PAGE
    : url === "/updateReservation"
    ? ADMIN_PAGE.EDIT_NEW_RESERVATION_PAGE
    : url === "/rental"
    ? ADMIN_PAGE.RENTAL_SEARCH_PAGE
    : url === "/endRental"
    ? ADMIN_PAGE.END_RENTAL_PAGE
    : url === "/rolesPermission"
    ? ADMIN_PAGE.ROLES_PERMISSION_SEARCH_PAGE
    : url === "/partnerInfo"
    ? ADMIN_PAGE.PARTNER_INFO_PAGE
    : url === "/shuttles"
    ? ADMIN_PAGE.SHUTTLE_SEARCH_PAGE
    : url === "/landmarks"
    ? ADMIN_PAGE.LANDMARK_SEARCH_PAGE
    : url === "/fleet-utilization-forecast"
    ? ADMIN_PAGE.RESERVE_SYNC_PAGE
    : url === "/member/memberReservation"
    ? ADMIN_PAGE.MEMBER_RESERVATION_SEARCH_PAGE
    : url === "/member/memberVehicleReservation"
    ? ADMIN_PAGE.MEMBER_VEHICLE_RESERVATION_SEARCH_PAGE
    : url === "/complaints"
    ? ADMIN_PAGE.COMPLAINTS_SEARCH_PAGE
    : url === "/birdseyeview"
    ? ADMIN_PAGE.BIRDS_EYE_VIEW_SEARCH_PAGE
    : url === "/implementations"
    ? ADMIN_PAGE.IMPLEMENTATION_SEARCH_PAGE
    : url === "/billing"
    ? ADMIN_PAGE.BILLING_SEARCH_PAGE
    : url === "/member/billing"
    ? ADMIN_PAGE.MEMBER_BILLING_SEARCH_PAGE
    : url === "/reservation_details/billing"
    ? ADMIN_PAGE.RES_DETAILS_BILLING_SEARCH_PAGE
    : url === "/member/memberActivity"
    ? ADMIN_PAGE.MEMBER_ACTIVITY_SEARCH_PAGE
    : url === "/member/memberVehicle"
    ? ADMIN_PAGE.MEMBER_VEHICLE_SEARCH
    : url === "/report/maintenance"
    ? ADMIN_PAGE.SERVICE_AGENT_SEARCH_PAGE
    : url === "/report/mechanics"
    ? ADMIN_PAGE.MECHANIC_APP_REPORT_SEARCH_PAGE
    : url === "/report/readyLineAgent"
    ? ADMIN_PAGE.READY_LINE_AGENT_SEARCH_PAGE
    : url === "/report/shuttleTrips"
    ? ADMIN_PAGE.SHUTTLE_TRIP_SEARCH_PAGE
    : url === "/member/memberMessages"
    ? ADMIN_PAGE.MEMBER_MESSAGE_SEARCH_PAGE
    : url === "/member/memberFavorites"
    ? ADMIN_PAGE.MEMBER_FAVORITE_ASSET_SEARCH_PAGE
    : url === "/member/memberFeedback"
    ? ADMIN_PAGE.MEMBER_FEEDBACK_SEARCH_PAGE
    : url === "/reservation_details/report_problem"
    ? ADMIN_PAGE.REPORT_PROBLEM_DETAILS_PAGE
    : url === "/reservation_details/message"
    ? ADMIN_PAGE.RESERVATION_MESSAGE_SEARCH_PAGE
    : url === "/member/memberPackages"
    ? ADMIN_PAGE.MEMBER_PACKAGE_SEARCH_PAGE
    : url === "/member/memberSummary"
    ? ADMIN_PAGE.MEMBER_SUMMARY_PAGE
    : url === "/vehicle_details/vehicle_Availability"
    ? ADMIN_PAGE.VEHICLE_AVAILABILITY
    : url === "/reservation_details/overview"
    ? ADMIN_PAGE.RES_DETAILS_OVERVIEW_PAGE
    : url === "/reservation_details/vehicle_details"
    ? ADMIN_PAGE.RES_DETAILS_VEHICLE_DETAILS_PAGE
    : url === "/reservation_details/renter_details"
    ? ADMIN_PAGE.RES_DETAILS_RENTER_DETAILS_PAGE
    : url === "/reservation_details/feedback"
    ? ADMIN_PAGE.RES_DETAILS_FEEDBACK_PAGE
    : "";
};
export const getDefaultSearchPageInfo = (urlOrPageName) => {
  const pageName = getPageName(urlOrPageName);
  let searchPageInfo = {};
  if (
    pageName === ADMIN_PAGE.RESERVATION_SEARCH_PAGE ||
    pageName === ADMIN_PAGE.RENTAL_SEARCH_PAGE ||
    pageName === ADMIN_PAGE.MEMBER_RESERVATION_SEARCH_PAGE ||
    pageName === ADMIN_PAGE.MEMBER_VEHICLE_RESERVATION_SEARCH_PAGE
  ) {
    searchPageInfo.isFilterRequired = true;
    searchPageInfo.isSingleFilterEnabled = true;
    searchPageInfo.pageGroupType = PAGE_GROUP_TYPE.PAGE_GROUP_RESERVATION;
  } else if (
    pageName === ADMIN_PAGE.BILLING_SEARCH_PAGE ||
    pageName === ADMIN_PAGE.MEMBER_BILLING_SEARCH_PAGE
  ) {
    searchPageInfo.isFilterRequired = true;
    searchPageInfo.isSingleFilterEnabled = false;
    searchPageInfo.pageGroupType = PAGE_GROUP_TYPE.PAGE_GROUP_BILLING;
  } else if (pageName === ADMIN_PAGE.RES_DETAILS_BILLING_SEARCH_PAGE) {
    searchPageInfo.isFilterRequired = false;
    searchPageInfo.isSingleFilterEnabled = false;
    searchPageInfo.pageGroupType = PAGE_GROUP_TYPE.PAGE_GROUP_BILLING;
  } else if (pageName === ADMIN_PAGE.VEHICLE_SEARCH_PAGE) {
    searchPageInfo.isFilterRequired = false;
    searchPageInfo.isSingleFilterEnabled = false;
    searchPageInfo.pageGroupType = PAGE_GROUP_TYPE.PAGE_GROUP_VEHICLE;
  } else if (pageName === ADMIN_PAGE.MEMBER_SEARCH_PAGE) {
    searchPageInfo.isFilterRequired = false;
    searchPageInfo.isSingleFilterEnabled = false;
    searchPageInfo.pageGroupType = PAGE_GROUP_TYPE.PAGE_GROUP_MEMBER;
  } else if (pageName === ADMIN_PAGE.ROLES_PERMISSION_SEARCH_PAGE) {
    searchPageInfo.isFilterRequired = false;
    searchPageInfo.isSingleFilterEnabled = false;
    searchPageInfo.pageGroupType = PAGE_GROUP_TYPE.PAGE_GROUP_ROLES_PERMISSIONS;
  } else if (pageName === ADMIN_PAGE.SHUTTLE_SEARCH_PAGE) {
    searchPageInfo.isFilterRequired = false;
    searchPageInfo.isSingleFilterEnabled = false;
    searchPageInfo.pageGroupType = PAGE_GROUP_TYPE.PAGE_GROUP_SHUTTLE;
  } else if (pageName === ADMIN_PAGE.LANDMARK_SEARCH_PAGE) {
    searchPageInfo.isFilterRequired = false;
    searchPageInfo.isSingleFilterEnabled = false;
    searchPageInfo.pageGroupType = PAGE_GROUP_TYPE.PAGE_GROUP_LANDMARK;
  } else if (pageName === ADMIN_PAGE.SHUTTLE_TRIP_SEARCH_PAGE) {
    searchPageInfo.isFilterRequired = false;
    searchPageInfo.isSingleFilterEnabled = false;
    searchPageInfo.pageGroupType = PAGE_GROUP_TYPE.PAGE_GROUP_SHUTTLE_TRIP;
  } else if (
    pageName === ADMIN_PAGE.SERVICE_AGENT_SEARCH_PAGE ||
    pageName === ADMIN_PAGE.MECHANIC_APP_REPORT_SEARCH_PAGE ||
    pageName === ADMIN_PAGE.READY_LINE_AGENT_SEARCH_PAGE
  ) {
    searchPageInfo.isFilterRequired = true;
    searchPageInfo.isSingleFilterEnabled = false;
    searchPageInfo.pageGroupType = PAGE_GROUP_TYPE.PAGE_GROUP_SERVICE_MECHANIC;
  } else if (pageName === ADMIN_PAGE.MEMBER_ACTIVITY_SEARCH_PAGE) {
    searchPageInfo.isFilterRequired = false;
    searchPageInfo.isSingleFilterEnabled = false;
    searchPageInfo.pageGroupType = PAGE_GROUP_TYPE.PAGE_GROUP_ACTIVITY;
  } else if (pageName === ADMIN_PAGE.MEMBER_MESSAGE_SEARCH_PAGE) {
    searchPageInfo.isFilterRequired = false;
    searchPageInfo.isSingleFilterEnabled = false;
    searchPageInfo.pageGroupType = PAGE_GROUP_TYPE.PAGE_GROUP_NORMAL_SEARCH;
    searchPageInfo.searchType = "MESSAGE";
  } else if (pageName === ADMIN_PAGE.MEMBER_FAVORITE_ASSET_SEARCH_PAGE) {
    searchPageInfo.isFilterRequired = false;
    searchPageInfo.isSingleFilterEnabled = false;
    searchPageInfo.pageGroupType = PAGE_GROUP_TYPE.PAGE_GROUP_NORMAL_SEARCH;
    searchPageInfo.searchType = "FAVOURITES";
  } else if (pageName === ADMIN_PAGE.MEMBER_VEHICLE_SEARCH) {
    searchPageInfo.isFilterRequired = false;
    searchPageInfo.isSingleFilterEnabled = false;
    searchPageInfo.pageGroupType = PAGE_GROUP_TYPE.PAGE_GROUP_NORMAL_SEARCH;
    searchPageInfo.searchType = "MYVEHICLES";
  } else if (pageName === ADMIN_PAGE.COMPLAINTS_SEARCH_PAGE) {
    searchPageInfo.isFilterRequired = false;
    searchPageInfo.isSingleFilterEnabled = false;
    searchPageInfo.isExportDisabled = true;
    searchPageInfo.searchType = "COMPLAINTS";
  } else if (pageName === ADMIN_PAGE.IMPLEMENTATION_SEARCH_PAGE) {
    searchPageInfo.isFilterRequired = false;
    searchPageInfo.isSingleFilterEnabled = false;
    searchPageInfo.isExportDisabled = true;
    searchPageInfo.pageGroupType = PAGE_GROUP_TYPE.PAGE_GROUP_NORMAL_SEARCH;
    searchPageInfo.searchType = "IMPLEMENTATION";
  } else if (pageName === ADMIN_PAGE.RESERVE_SYNC_PAGE) {
    searchPageInfo.pageGroupType = PAGE_GROUP_TYPE.PAGE_GROUP_PREIODIC_SEARCH;
    searchPageInfo.searchType = "CALENDER_VIEW";
  }
  return searchPageInfo;
};
export const setActiveMenu = (url, isRental) => {
  const searchype = getPageName(url);
  const settingPage = getSettingPageName(url);
  const vehicleSubPage = getVehicleSubPageName(url);
  const reservationDetailsSubPage = getReservationDetailsPageName(url);
  clearActiveMenu(searchype);
  if (
    searchype === ADMIN_PAGE.VEHICLE_SEARCH_PAGE ||
    searchype === ADMIN_PAGE.NEW_VEHICLE_PAGE ||
    vehicleSubPage
  ) {
    activeCurrentLink("vehicleMenu");
  } else if (searchype === ADMIN_PAGE.ROLES_PERMISSION_SEARCH_PAGE) {
    activeCurrentLink("rolesPermissionMenu");
  } else if (searchype === ADMIN_PAGE.SHUTTLE_SEARCH_PAGE) {
    activeCurrentLink("shuttle-menu");
  } else if (searchype === ADMIN_PAGE.LANDMARK_SEARCH_PAGE) {
    activeCurrentLink("landmarksMenu");
  } else if (searchype === ADMIN_PAGE.PARTNER_INFO_PAGE) {
    activeCurrentLink("partnerInfoMenu");
  } else if (searchype === ADMIN_PAGE.RESERVE_SYNC_PAGE) {
    activeCurrentLink("fleetForecastMenu");
  } else if (searchype === ADMIN_PAGE.RESERVATION_SEARCH_PAGE) {
    activeCurrentLink("reservationMenu");
  } else if (searchype === ADMIN_PAGE.EDIT_NEW_RESERVATION_PAGE) {
    if (!isRental) {
      activeCurrentLink("reservationMenu");
    } else {
      activeCurrentLink("rentalMenu");
    }
  } else if (
    searchype === ADMIN_PAGE.RENTAL_SEARCH_PAGE ||
    searchype === ADMIN_PAGE.END_RENTAL_PAGE ||
    reservationDetailsSubPage
  ) {
    activeCurrentLink("rentalMenu");
  } else if (searchype === ADMIN_PAGE.BILLING_SEARCH_PAGE) {
    activeCurrentLink("billingMenu");
  } else if (
    searchype === ADMIN_PAGE.MECHANIC_APP_REPORT_SEARCH_PAGE ||
    searchype === ADMIN_PAGE.SERVICE_AGENT_SEARCH_PAGE ||
    searchype === ADMIN_PAGE.READY_LINE_AGENT_SEARCH_PAGE ||
    searchype === ADMIN_PAGE.SHUTTLE_TRIP_SEARCH_PAGE
  ) {
    activeCurrentLink("reportMenu");
  } else if (settingPage) {
    activeCurrentLink("settingMenu");
  } else if (searchype === ADMIN_PAGE.COMPLAINTS_SEARCH_PAGE) {
    activeCurrentLink("complaintsMenu");
  } else if (searchype === ADMIN_PAGE.IMPLEMENTATION_SEARCH_PAGE) {
    activeCurrentLink("implementationMenu");
  } else if (searchype === ADMIN_PAGE.BIRDS_EYE_VIEW_SEARCH_PAGE) {
    activeCurrentLink("birdEyeViewMenu");
  } else {
    activeCurrentLink("memberMenu");
  }
};
export const addCancelledRservationStates = (reservationStates) => {
  let newResStates = reservationStates;
  if (reservationStates) {
    let isCancelledFound = false;
    reservationStates.split(",").map((item, i) => {
      if (item === "CANCELLED") {
        isCancelledFound = true;
      }
    });
    if (!isCancelledFound) {
      newResStates = newResStates + ",CANCELLED";
    }
  } else {
    newResStates = "CANCELLED";
  }
  return newResStates;
};
export const toggleAdvancedBasicSearchMode = (ctrlObj) => {
  let ctrl = { ...ctrlObj };
  ctrl.isAdvancedSearch = ctrlObj.isAdvancedSearch ? false : true;
  return ctrl;
};
export const resetSearchControls = (ctrlObj) => {
  let ctrl = { ...ctrlObj };
  Object.keys(ctrl).map((key, index) => {
    let controlName = key;
    ctrl = updateObject(ctrl, {
      [controlName]: updateObject(ctrl[controlName], {
        value: "",
      }),
    });
  });
  return ctrl;
};
export const getSerachRequestObject = (pageName) => {
  if (pageName === ADMIN_PAGE.MEMBER_SEARCH_PAGE) {
    return new MemberSearchRequest();
  } else if (pageName === ADMIN_PAGE.VEHICLE_SEARCH_PAGE) {
    return new VehicleSearchRequest();
  } else if (pageName === ADMIN_PAGE.ROLES_PERMISSION_SEARCH_PAGE) {
    return new RolesPermissionsSearchRequest();
  } else if (
    pageName === ADMIN_PAGE.RESERVATION_SEARCH_PAGE ||
    pageName === ADMIN_PAGE.RENTAL_SEARCH_PAGE ||
    pageName === ADMIN_PAGE.MEMBER_RESERVATION_SEARCH_PAGE ||
    pageName === ADMIN_PAGE.MEMBER_VEHICLE_RESERVATION_SEARCH_PAGE
  ) {
    return new ReservationSearchInfo();
  } else if (
    pageName === ADMIN_PAGE.BILLING_SEARCH_PAGE ||
    pageName === ADMIN_PAGE.MEMBER_BILLING_SEARCH_PAGE ||
    pageName === ADMIN_PAGE.RES_DETAILS_BILLING_SEARCH_PAGE
  ) {
    return new BillingSearchRequest();
  } else if (
    pageName === ADMIN_PAGE.MECHANIC_APP_REPORT_SEARCH_PAGE ||
    pageName === ADMIN_PAGE.SERVICE_AGENT_SEARCH_PAGE
  ) {
    return new SearchServiceTasksRequest();
  } else if (pageName === ADMIN_PAGE.READY_LINE_AGENT_SEARCH_PAGE) {
    return new SearchReadyLineAgentRequest();
  } else if (pageName === ADMIN_PAGE.MEMBER_ACTIVITY_SEARCH_PAGE) {
    return new MemberActivitySearchRequest();
  } else if (
    pageName === ADMIN_PAGE.MEMBER_MESSAGE_SEARCH_PAGE ||
    pageName === ADMIN_PAGE.RESERVATION_MESSAGE_SEARCH_PAGE
  ) {
    return new MemberMessageSearchRequest();
  } else if (
    pageName === ADMIN_PAGE.MEMBER_FAVORITE_ASSET_SEARCH_PAGE ||
    pageName === ADMIN_PAGE.MEMBER_VEHICLE_SEARCH
  ) {
    return new FavouriteAssetsRequest();
  } else if (pageName === ADMIN_PAGE.MEMBER_FEEDBACK_SEARCH_PAGE) {
    return new FeedbackSearchRequest();
  } else if (pageName === ADMIN_PAGE.REPORT_PROBLEM_DETAILS_PAGE) {
    return new ReportProblemDetailsRequest();
  } else if (pageName === ADMIN_PAGE.LANDMARK_SEARCH_PAGE) {
    return new LandmarkSearchRequest();
  } else if (pageName === ADMIN_PAGE.SHUTTLE_SEARCH_PAGE) {
    return new SearchShuttleRequest();
  } else if (pageName === ADMIN_PAGE.RESERVE_SYNC_PAGE) {
    return new SearchFleetUtilizationForecastRequest();
  } else if (pageName === ADMIN_PAGE.SHUTTLE_TRIP_SEARCH_PAGE) {
    return new ShuttleTripSearchRequest();
  }
};

export const getSearchAPIName = (pageName) => {
  if (pageName === ADMIN_PAGE.MEMBER_SEARCH_PAGE) {
    return SearchAPI.SEARCH_MEMBER;
  } else if (pageName === ADMIN_PAGE.VEHICLE_SEARCH_PAGE) {
    return SearchAPI.SEARCH_VEHICLE;
  } else if (pageName === ADMIN_PAGE.RESERVATION_SEARCH_PAGE) {
    return SearchAPI.SEARCH_RESERVATION;
  } else if (pageName === ADMIN_PAGE.RENTAL_SEARCH_PAGE) {
    return SearchAPI.SEARCH_RENTAL;
  } else if (pageName === ADMIN_PAGE.ROLES_PERMISSION_SEARCH_PAGE) {
    return SearchAPI.SEARCH_ROLES_PERMISSIONS_MEMBER;
  } else if (pageName === ADMIN_PAGE.MEMBER_RESERVATION_SEARCH_PAGE) {
    return SearchAPI.SEARCH_MEMBER_RESERVATION;
  } else if (pageName === ADMIN_PAGE.MEMBER_VEHICLE_RESERVATION_SEARCH_PAGE) {
    return SearchAPI.SEARCH_MEMBER_VEHICLE_RESERVATION;
  } else if (
    pageName === ADMIN_PAGE.BILLING_SEARCH_PAGE ||
    pageName === ADMIN_PAGE.MEMBER_BILLING_SEARCH_PAGE ||
    pageName === ADMIN_PAGE.RES_DETAILS_BILLING_SEARCH_PAGE
  ) {
    return SearchAPI.SEARCH_TRANSACTIONS;
  } else if (
    pageName === ADMIN_PAGE.MECHANIC_APP_REPORT_SEARCH_PAGE ||
    pageName === ADMIN_PAGE.SERVICE_AGENT_SEARCH_PAGE ||
    pageName === ADMIN_PAGE.READY_LINE_AGENT_SEARCH_PAGE
  ) {
    return SearchAPI.SEARCH_SERVICE_TASK;
  } else if (pageName === ADMIN_PAGE.MEMBER_ACTIVITY_SEARCH_PAGE) {
    return SearchAPI.SEARCH_ACTIVITY_LOG;
  } else if (
    pageName === ADMIN_PAGE.MEMBER_MESSAGE_SEARCH_PAGE ||
    pageName === ADMIN_PAGE.RESERVATION_MESSAGE_SEARCH_PAGE
  ) {
    return SearchAPI.SEARCH_MESSAGES;
  } else if (pageName === ADMIN_PAGE.MEMBER_FAVORITE_ASSET_SEARCH_PAGE) {
    return SearchAPI.SEARCH_FAVOURITES;
  } else if (pageName === ADMIN_PAGE.MEMBER_VEHICLE_SEARCH) {
    return SearchAPI.SEARCH_MY_VEHICLE;
  } else if (pageName === ADMIN_PAGE.COMPLAINTS_SEARCH_PAGE) {
    return SearchAPI.SEARCH_COMPLAINTS;
  } else if (pageName === ADMIN_PAGE.IMPLEMENTATION_SEARCH_PAGE) {
    return SearchAPI.SEARCH_IMPLEMENTATION;
  } else if (pageName === ADMIN_PAGE.SHUTTLE_SEARCH_PAGE) {
    return SearchAPI.SEARCH_SHUTTLE;
  } else if (pageName === ADMIN_PAGE.LANDMARK_SEARCH_PAGE) {
    return SearchAPI.SEARCH_LANDMARK;
  } else if (pageName === ADMIN_PAGE.SHUTTLE_TRIP_SEARCH_PAGE) {
    return SearchAPI.SEARCH_SHUTTLE_TRIP;
  } else if (pageName === ADMIN_PAGE.RESERVE_SYNC_PAGE) {
    return SearchAPI.SEARCH_FLEET_UTILIZATION_FORECAST;
  }
};

const convertStringValueToArray = (stringValue) => {
  return stringValue
    ? stringValue.split(",").filter((item) => isNullOrEmpty(item) === false)
    : null;
};

export const createSearchRequest = (
  searcInputCtrl,
  pageName,
  showExportModal,
  paramSearch
) => {
  let searchReq = getSearchRequestCompatibleData(searcInputCtrl, pageName);
  if (showExportModal) {
    searchReq.PageInfo.PerPageCount = MAX_EXPORT_RECORD;
  } else if (searchReq.PageInfo.PerPageCount === MAX_EXPORT_RECORD) {
    searchReq.PageInfo.PerPageCount = new PageInfo().PerPageCount;
  }
  if (
    pageName === ADMIN_PAGE.RESERVATION_SEARCH_PAGE ||
    pageName === ADMIN_PAGE.RENTAL_SEARCH_PAGE ||
    pageName === ADMIN_PAGE.MEMBER_RESERVATION_SEARCH_PAGE ||
    pageName === ADMIN_PAGE.MEMBER_VEHICLE_RESERVATION_SEARCH_PAGE
  ) {
    searchReq.UpsellOptionInfoRequired = showExportModal ? false : true;
    if (searchReq.SearchByCancellationDate) {
      searchReq.SearchByCreationDate = false;
      searchReq.SearchByScheduledDate = false;
    }
    let reservationSearchRequestObj = new ReservationSearchRequest();
    searchReq.ReservationCodes = convertStringValueToArray(
      searchReq.ReservationCodes
    );
    reservationSearchRequestObj.ReservationSearchInfo = searchReq;
    reservationSearchRequestObj.SelectedHeaders = null;
    if (
      pageName === ADMIN_PAGE.MEMBER_RESERVATION_SEARCH_PAGE ||
      pageName === ADMIN_PAGE.MEMBER_VEHICLE_RESERVATION_SEARCH_PAGE
    ) {
      reservationSearchRequestObj.MemberId =
        parseInt(getInfoFromParamQuery("memberId", paramSearch)) || null;
      reservationSearchRequestObj.ShowReservationsAsSharer = false;
      reservationSearchRequestObj.ShowReservationsAsBorrower = true;
    }
    return reservationSearchRequestObj;
  } else if (pageName === ADMIN_PAGE.RES_DETAILS_BILLING_SEARCH_PAGE) {
    searchReq.MemberId =
      parseInt(getInfoFromParamQuery("memberId", paramSearch)) || null;
    searchReq.ReservationId =
      parseInt(getInfoFromParamQuery("reservationId", paramSearch)) || null;
  } else if (
    pageName === ADMIN_PAGE.MEMBER_BILLING_SEARCH_PAGE ||
    pageName === ADMIN_PAGE.BILLING_SEARCH_PAGE
  ) {
    searchReq.ConfirmationKeys = convertStringValueToArray(
      searchReq.ConfirmationKeys
    );
    if (pageName === ADMIN_PAGE.MEMBER_BILLING_SEARCH_PAGE) {
      searchReq.MemberId =
        parseInt(getInfoFromParamQuery("memberId", paramSearch)) || null;
    }
  } else if (pageName === ADMIN_PAGE.MEMBER_ACTIVITY_SEARCH_PAGE) {
    searchReq.MemberId =
      parseInt(getInfoFromParamQuery("memberId", paramSearch)) || null;
    searchReq.ReservationCodes = convertStringValueToArray(
      searchReq.ReservationCodes
    );
  } else if (
    pageName === ADMIN_PAGE.MEMBER_MESSAGE_SEARCH_PAGE ||
    pageName === ADMIN_PAGE.MEMBER_FAVORITE_ASSET_SEARCH_PAGE ||
    pageName === ADMIN_PAGE.MEMBER_VEHICLE_SEARCH
  ) {
    searchReq.MemberId =
      parseInt(getInfoFromParamQuery("memberId", paramSearch)) || null;
  } else if (pageName === ADMIN_PAGE.SERVICE_AGENT_SEARCH_PAGE) {
    searchReq.ReportType = "SERVICE_AGENT";
  } else if (pageName === ADMIN_PAGE.MECHANIC_APP_REPORT_SEARCH_PAGE) {
    searchReq.ReportType = "MECHANIC";
  } else if (pageName === ADMIN_PAGE.READY_LINE_AGENT_SEARCH_PAGE) {
    searchReq.ReportType = "READY_LINE";
  } else if (pageName === ADMIN_PAGE.LANDMARK_SEARCH_PAGE) {
    searchReq.locationCodes = convertStringValueToArray(
      searchReq.locationCodes
    );
    searchReq.landmarkCategories = convertStringValueToArray(
      searchReq.landmarkCategories
    );
  }
  return searchReq;
};

export const getMergedDateAndTimeValue = (dateControlId) => {
  let datetimeStr = moment(
    moment(dateControlId.value, "MM:DD:YYYY:HH:mm").format("MM:DD:YYYY") +
      ":" +
      (dateControlId.timePicker.value
        ? moment(dateControlId.timePicker.value, "MM:DD:YYYY:HH:mm").format(
            "HH:mm"
          )
        : moment(dateControlId.value, "MM:DD:YYYY:HH:mm").format("HH:mm")),
    "MM:DD:YYYY:HH:mm"
  );
  return datetimeStr.format("MM:DD:YYYY:HH:mm");
};
export const convertDateCtrlValueToFoxApiDate = (dateControlId) => {
  let mergedDateTimeValue = getMergedDateAndTimeValue(dateControlId);
  let formattedDate = convertFoxApiDateToStr(
    moment(mergedDateTimeValue, "MM:DD:YYYY:HH:mm").toDate()
  );
  return formattedDate;
};
export const convertDateCtrlValueToFoxAPIOnlyDate = (
  dateControlId,
  defaultFormat
) => {
  // let datetimeStr = moment(
  //   moment(dateControlId.value, "MM:DD:YYYY:HH:mm").format("MM:DD:YYYY"));
  let formattedDate = convertFoxApiDateToStr(
    moment(dateControlId.value, "MM:DD:YYYY").toDate(),
    defaultFormat || "YYYY-MM-DD"
  );
  return formattedDate;
};
export const getSearchRequestCompatibleData = (searcInputCtrl, pageName) => {
  const searchReqInfo = getSerachRequestObject(pageName);
  const isAdvancedSearch = searcInputCtrl
    ? searcInputCtrl.isAdvancedSearch || false
    : false;
  for (let key in searcInputCtrl) {
    if (
      searcInputCtrl[key].elementType !== "button" &&
      searcInputCtrl[key].elementType !== "empty" &&
      key !== "isAdvancedSearch"
    ) {
      searchReqInfo[key] = isAdvancedSearch
        ? searcInputCtrl[key].advancedOrder !== 0
          ? searcInputCtrl[key].value || null
          : searcInputCtrl[key].elementType === "radio"
          ? searcInputCtrl[key].value || null
          : null
        : searcInputCtrl[key].basicOrder === 0
        ? searcInputCtrl[key].elementType === "radio"
          ? searcInputCtrl[key].value || null
          : null
        : searcInputCtrl[key].value || null;
    }
  }
  if (searcInputCtrl) {
    if (pageName === ADMIN_PAGE.MEMBER_SEARCH_PAGE) {
      searchReqInfo.SearchType = searcInputCtrl.SearchValue.labelInput.value;
    } else if (
      pageName === ADMIN_PAGE.RESERVATION_SEARCH_PAGE ||
      pageName === ADMIN_PAGE.RENTAL_SEARCH_PAGE ||
      pageName === ADMIN_PAGE.MEMBER_RESERVATION_SEARCH_PAGE ||
      pageName === ADMIN_PAGE.MEMBER_VEHICLE_RESERVATION_SEARCH_PAGE ||
      pageName === ADMIN_PAGE.VEHICLE_SEARCH_PAGE ||
      pageName === ADMIN_PAGE.COMPLAINTS_SEARCH_PAGE ||
      pageName === ADMIN_PAGE.IMPLEMENTATION_SEARCH_PAGE
    ) {
      if (searcInputCtrl.StartDate.value) {
        searchReqInfo.StartDate = getMergedDateAndTimeValue(
          searcInputCtrl.StartDate
        );
      }
      if (searcInputCtrl.EndDate.value) {
        searchReqInfo.EndDate = getMergedDateAndTimeValue(
          searcInputCtrl.EndDate
        );
      }
      if (pageName === ADMIN_PAGE.VEHICLE_SEARCH_PAGE) {
        searchReqInfo.OnlyShownInJsiMarketPlace =
          searcInputCtrl.OnlyShownInJsiMarketPlace.value;
        searchReqInfo.RideLinkInstalled =
          searcInputCtrl.RideLinkInstalled.value;
        searchReqInfo.ShowOffline = searcInputCtrl.ShowOffline.value;
      }
      if (
        searchReqInfo.SearchByCancellationDate &&
        (pageName === ADMIN_PAGE.RESERVATION_SEARCH_PAGE ||
          pageName === ADMIN_PAGE.RENTAL_SEARCH_PAGE ||
          pageName === ADMIN_PAGE.MEMBER_RESERVATION_SEARCH_PAGE)
      ) {
        if (!searchReqInfo.ReservationState.split(",").includes("CANCELLED")) {
          const resStates = [...searchReqInfo.ReservationState];
          searchReqInfo.ReservationState = resStates + ",CANCELLED";
        }
      }
    } else if (
      pageName === ADMIN_PAGE.BILLING_SEARCH_PAGE ||
      pageName === ADMIN_PAGE.MEMBER_BILLING_SEARCH_PAGE
    ) {
      if (searcInputCtrl.SearchStartDate.value) {
        searchReqInfo.SearchStartDate = getMergedDateAndTimeValue(
          searcInputCtrl.SearchStartDate
        );
      }
      if (searcInputCtrl.SearchEndDate.value) {
        searchReqInfo.SearchEndDate = getMergedDateAndTimeValue(
          searcInputCtrl.SearchEndDate
        );
      }
    } else if (
      pageName === ADMIN_PAGE.MECHANIC_APP_REPORT_SEARCH_PAGE ||
      pageName === ADMIN_PAGE.SERVICE_AGENT_SEARCH_PAGE
    ) {
      if (searcInputCtrl.FromDate.value) {
        searchReqInfo.FromDate = getMergedDateAndTimeValue(
          searcInputCtrl.FromDate
        );
      }
      if (searcInputCtrl.ToDate.value) {
        searchReqInfo.ToDate = getMergedDateAndTimeValue(searcInputCtrl.ToDate);
      }
    } else if (pageName === ADMIN_PAGE.READY_LINE_AGENT_SEARCH_PAGE) {
      if (searcInputCtrl.AuditFromDate.value) {
        searchReqInfo.AuditFromDate = getMergedDateAndTimeValue(
          searcInputCtrl.AuditFromDate
        );
      }
      if (searcInputCtrl.AuditToDate.value) {
        searchReqInfo.AuditToDate = getMergedDateAndTimeValue(
          searcInputCtrl.AuditToDate
        );
      }
    } else if (pageName === ADMIN_PAGE.SHUTTLE_TRIP_SEARCH_PAGE) {
      if (searcInputCtrl.startDateTime.value) {
        searchReqInfo.startDateTime = convertDateCtrlValueToFoxApiDate(
          searcInputCtrl.startDateTime
        );
      }
      if (searcInputCtrl.endDateTime.value) {
        searchReqInfo.endDateTime = convertDateCtrlValueToFoxApiDate(
          searcInputCtrl.endDateTime
        );
      }
    } else if (pageName === ADMIN_PAGE.RESERVE_SYNC_PAGE) {
      if (searcInputCtrl.forecastStartDate.value) {
        searchReqInfo.forecastStartDate = convertDateCtrlValueToFoxAPIOnlyDate(
          searcInputCtrl.forecastStartDate
        );
      }
      if (searcInputCtrl.forecastEndDate.value) {
        searchReqInfo.forecastEndDate = convertDateCtrlValueToFoxAPIOnlyDate(
          searcInputCtrl.forecastEndDate
        );
      }
    }
    searchReqInfo.PageInfo = searcInputCtrl.PageInfo;
  }
  return searchReqInfo;
};
export const getBreadcrumbDataforSearchPage = (
  url,
  location,
  breadCrumbList1
) => {
  breadCrumbList1 = breadCrumbList1 ? breadCrumbList1 : [];
  let breadCrumbList = [];
  if (
    url === "/rental" ||
    url === "/reservation" ||
    url === "/billing" ||
    url === "/vehicle" ||
    url === "/member" ||
    url === "/rolesPermission" ||
    url === "/shuttles" ||
    url === "/landmarks" ||
    url === "/fleet-utilization-forecast" ||
    url === "/report/shuttleTrips" ||
    url === "/"
  ) {
    breadCrumbList = populateBreadCrumbData(
      [],
      url === "/rental"
        ? "Rental"
        : url === "/reservation"
        ? "Reservation"
        : url === "/billing"
        ? "Billing"
        : url === "/member"
        ? getMemberLabelByOrgRentalType()
        : url === "/rolesPermission"
        ? "Roles Permission"
        : url === "/shuttles"
        ? "Shuttles"
        : url === "/landmarks"
        ? "Landmarks"
        : url === "/fleet-utilization-forecast"
        ? "Fleet utilization forecasting"
        : url === "/report/shuttleTrips"
        ? "Shuttle Trips"
        : url === "/vehicle"
        ? "Vehicle"
        : url === "/"
        ? "Dashboard"
        : "",
      location.pathname
    );
  } else if (
    (location.hasOwnProperty("state") &&
      location.state &&
      location.state.breadCrumbList) ||
    (breadCrumbList1 && breadCrumbList1.length > 0)
  ) {
    let breadList = [];
    if (!breadCrumbList1 && location.state.breadCrumbList.length > 2) {
      breadCrumbList = [
        location.state.breadCrumbList[0],
        location.state.breadCrumbList[1],
      ];
    } else {
      breadList = [...breadCrumbList1];
    }
    breadCrumbList = getMemberSubMenuBreadCrumbData(breadList, url, location);
  }
  return breadCrumbList;
};
export const getMemberSubMenuBreadCrumbData = (
  breadCrumbList,
  url,
  location
) => {
  return populateBreadCrumbData(
    breadCrumbList,
    url === "/member/memberVehicleReservation"
      ? getMemberLabelByOrgRentalType("Vehicle Reservation")
      : url === "/member/memberReservation"
      ? getMemberLabelByOrgRentalType("Reservation")
      : url === "/member/billing"
      ? getMemberLabelByOrgRentalType("Billing")
      : url === "/member/memberFeedback"
      ? getMemberLabelByOrgRentalType("Feedback")
      : url === "/member/memberMessages"
      ? getMemberLabelByOrgRentalType("Messages")
      : url === "/member/memberActivity"
      ? getMemberLabelByOrgRentalType("Activity")
      : url === "/member/memberVehicle"
      ? getMemberLabelByOrgRentalType("Vehicle")
      : url === "/member/memberPackages"
      ? getMemberLabelByOrgRentalType("Packages")
      : url === "/member/memberSummary"
      ? getMemberLabelByOrgRentalType("Summary")
      : "",
    location.pathname + location.search
  );
};

export const getChildBreadCrumbList = (
  urlStr,
  locationStr,
  prevBreadCrumbList
) => {
  return prevBreadCrumbList && prevBreadCrumbList.length < 3
    ? getBreadcrumbDataforSearchPage(urlStr, locationStr, prevBreadCrumbList)
    : prevBreadCrumbList;
};

export const getMemberProfileBreadcrumbList = (otherData, memberId) => {
  let breadCrumbList =
    otherData && otherData.breadCrumbList
      ? otherData.breadCrumbList.length < 4
        ? populateBreadCrumbData(
            otherData.breadCrumbList,
            otherData.memberName,
            "/member/memberProfile?memberId=" + memberId || null
          )
        : [
            otherData.breadCrumbList[0],
            otherData.breadCrumbList[1],
            otherData.breadCrumbList[2],
          ]
      : [];

  return breadCrumbList;
};

export const updateSearchFilter = (searcInputCtrl, filterStates, pageName) => {
  let newCtrl = { ...searcInputCtrl };
  if (pageName === ADMIN_PAGE.READY_LINE_AGENT_SEARCH_PAGE) {
    newCtrl = updateObject(newCtrl, {
      AuditStatus: updateObject(newCtrl["AuditStatus"], {
        value: filterStates || null,
      }),
    });
  } else if (newCtrl.ReservationState) {
    newCtrl = updateObject(newCtrl, {
      ReservationState: updateObject(newCtrl["ReservationState"], {
        value: filterStates || null,
      }),
    });
  } else if (newCtrl.Status) {
    newCtrl = updateObject(newCtrl, {
      Status: updateObject(newCtrl["Status"], {
        value: filterStates || null,
      }),
    });
  }
  newCtrl.PageInfo.PageNumber = 1;
  return newCtrl;
};
export const getFilterStatesValue = (
  searcInputCtrl,
  pageGroupType,
  pageName
) => {
  if (
    pageGroupType === PAGE_GROUP_TYPE.PAGE_GROUP_RESERVATION ||
    pageGroupType === PAGE_GROUP_TYPE.PAGE_GROUP_BILLING
  ) {
    return searcInputCtrl["ReservationState"].value;
  } else if (pageGroupType === PAGE_GROUP_TYPE.PAGE_GROUP_SERVICE_MECHANIC) {
    if (pageName === ADMIN_PAGE.READY_LINE_AGENT_SEARCH_PAGE) {
      return searcInputCtrl["AuditStatus"].value;
    }
    return searcInputCtrl["Status"].value;
  }
};
export const getSearchGridId = (pageGroupType, pageName) => {
  if (pageGroupType === PAGE_GROUP_TYPE.PAGE_GROUP_MEMBER) {
    return "memberTable";
  } else if (pageGroupType === PAGE_GROUP_TYPE.PAGE_GROUP_VEHICLE) {
    return isP2POrHybridOrg() ? "vehicleTableP2P" : "vehicleTable";
  } else if (pageGroupType === PAGE_GROUP_TYPE.PAGE_GROUP_ROLES_PERMISSIONS) {
    return "rolesPermissionTable";
  } else if (pageGroupType === PAGE_GROUP_TYPE.PAGE_GROUP_SHUTTLE) {
    return "shuttleTable";
  } else if (pageGroupType === PAGE_GROUP_TYPE.PAGE_GROUP_LANDMARK) {
    return "landmarksTable";
  } else if (pageName === ADMIN_PAGE.MEMBER_RESERVATION_SEARCH_PAGE) {
    return isP2POrHybridOrg()
      ? "reservationTableP2P_MR"
      : "reservationTable_MR";
  } else if (pageName === ADMIN_PAGE.MEMBER_VEHICLE_RESERVATION_SEARCH_PAGE) {
    return "reservationTableP2P_MV";
  } else if (pageGroupType === PAGE_GROUP_TYPE.PAGE_GROUP_RESERVATION) {
    return isP2POrHybridOrg() ? "reservationTableP2P" : "reservationTable";
  } else if (pageGroupType === PAGE_GROUP_TYPE.PAGE_GROUP_BILLING) {
    return isP2POrHybridOrg() ? "billingTableP2P" : "billingTable";
  } else if (pageName === ADMIN_PAGE.MEMBER_VEHICLE_SEARCH) {
    return "memberVehicleSearchTable";
  } else if (pageName === ADMIN_PAGE.MEMBER_FAVORITE_ASSET_SEARCH_PAGE) {
    return "memberFavoriteSearchTable";
  } else if (pageName === ADMIN_PAGE.MEMBER_MESSAGE_SEARCH_PAGE) {
    return "memberMessageSearchTable";
  } else if (pageName === ADMIN_PAGE.SERVICE_AGENT_SEARCH_PAGE) {
    return "serviceAgentReportTable";
  } else if (pageName === ADMIN_PAGE.MECHANIC_APP_REPORT_SEARCH_PAGE) {
    return "mechanicReportTable";
  } else if (pageName === ADMIN_PAGE.READY_LINE_AGENT_SEARCH_PAGE) {
    return "readyLineReportTable";
  } else if (pageName === ADMIN_PAGE.SHUTTLE_TRIP_SEARCH_PAGE) {
    return "shuttleReportTable";
  } else if (pageGroupType === "ACTIVITY") {
    return "activityTable";
  }
};
export const getExportedList = (searchData, searchPageInfo) => {
  if (searchPageInfo.pageGroupType === PAGE_GROUP_TYPE.PAGE_GROUP_RESERVATION) {
    return searchData.Reservations;
  } else if (
    searchPageInfo.pageGroupType === PAGE_GROUP_TYPE.PAGE_GROUP_BILLING
  ) {
    return searchData.Payments;
  } else if (
    searchPageInfo.pageGroupType === PAGE_GROUP_TYPE.PAGE_GROUP_MEMBER ||
    searchPageInfo.pageGroupType ===
      PAGE_GROUP_TYPE.PAGE_GROUP_ROLES_PERMISSIONS
  ) {
    return searchData.PersonDetails;
  } else if (
    searchPageInfo.pageGroupType === PAGE_GROUP_TYPE.PAGE_GROUP_VEHICLE ||
    searchPageInfo.searchType === "FAVOURITES"
  ) {
    return searchData.Assets;
  } else if (
    searchPageInfo.pageGroupType === PAGE_GROUP_TYPE.PAGE_GROUP_ACTIVITY
  ) {
    return searchData.ActivityInfoList;
  } else if (
    searchPageInfo.pageGroupType === PAGE_GROUP_TYPE.PAGE_GROUP_SERVICE_MECHANIC
  ) {
    return searchData.Tasks;
  } else if (searchPageInfo.searchType === "MESSAGE") {
    return searchData.Messages;
  } else if (searchPageInfo.searchType === "LANDMARK") {
    return searchData.landmarkSummaries;
  } else if (searchPageInfo.searchType === "SHUTTLE") {
    return searchData.shuttleSummaries;
  } else if (searchPageInfo.searchType === "SHUTTLE_TRIP") {
    return searchData.tripSummaries;
  }
};
export const laodDdlCtrlFromCurrentResponse = (
  updatedControls,
  pageName,
  searchPageInfo,
  selectedOrgKeyValue,
  dependentOrg,
  vehicleCategories,
  paymentReasonTypes,
  integStatuses,
  activityLogProblemTypes,
  activityLogSubProblemTypes,
  orgRoles,
  billingTypes,
  locationTypes,
  shuttleOptionsData
) => {
  let inputCtrl = { ...updatedControls };
  inputCtrl = loadDependentOrg(
    pageName,
    searchPageInfo,
    inputCtrl,
    dependentOrg,
    selectedOrgKeyValue,
    shuttleOptionsData.locations
  );

  if (
    searchPageInfo.pageGroupType ===
      PAGE_GROUP_TYPE.PAGE_GROUP_ROLES_PERMISSIONS &&
    orgRoles
  ) {
    inputCtrl = populateDropdownData("RoleId", orgRoles, inputCtrl, "roles");
  }

  if (
    (searchPageInfo.pageGroupType === PAGE_GROUP_TYPE.PAGE_GROUP_RESERVATION ||
      searchPageInfo.pageGroupType === PAGE_GROUP_TYPE.PAGE_GROUP_BILLING ||
      searchPageInfo.pageGroupType === PAGE_GROUP_TYPE.PAGE_GROUP_VEHICLE) &&
    vehicleCategories &&
    inputCtrl &&
    inputCtrl.ClassCode.elementConfig.options.length === 1
  ) {
    inputCtrl = populateDropdownData(
      "ClassCode",
      vehicleCategories,
      inputCtrl,
      "vehicleCategory"
    );
  }
  if (
    (searchPageInfo.pageGroupType === PAGE_GROUP_TYPE.PAGE_GROUP_RESERVATION ||
      searchPageInfo.pageGroupType === PAGE_GROUP_TYPE.PAGE_GROUP_BILLING ||
      searchPageInfo.pageGroupType === PAGE_GROUP_TYPE.PAGE_GROUP_VEHICLE) &&
    paymentReasonTypes &&
    inputCtrl &&
    inputCtrl.Reason &&
    inputCtrl.Reason.elementConfig.options.length === 1
  ) {
    inputCtrl = populateDropdownData(
      "Reason",
      paymentReasonTypes,
      inputCtrl,
      "reason"
    );
  }
  if (
    integStatuses &&
    inputCtrl &&
    inputCtrl.IntegStatuses &&
    inputCtrl.IntegStatuses.elementConfig.options.length === 0
  ) {
    inputCtrl = populateDropdownData(
      "IntegStatuses",
      integStatuses,
      inputCtrl,
      "integStatus"
    );
  }
  if (
    searchPageInfo.pageGroupType === PAGE_GROUP_TYPE.PAGE_GROUP_ACTIVITY &&
    activityLogProblemTypes &&
    inputCtrl &&
    inputCtrl.ProblemType &&
    inputCtrl.ProblemType.elementConfig.options.length === 1
  ) {
    inputCtrl = populateDropdownData(
      "ProblemType",
      inputCtrl.ProblemType.elementConfig.options.concat(
        activityLogProblemTypes
      ),
      inputCtrl,
      "problemType"
    );
    const subreasonList = activityLogSubProblemTypes
      ? [activityLogSubProblemTypes]
      : [];

    const subReasons = [{ Name: "", DisplayName: "Select Subtype" }].concat(
      subreasonList[0].filter(
        (item) => item.ParentName === inputCtrl["ProblemType"].value
      )
    );
    inputCtrl = populateDropdownData(
      "SubProblemType",
      subReasons,
      inputCtrl,
      "problemType"
    );
  }
  if (
    locationTypes &&
    inputCtrl &&
    inputCtrl.LocationType &&
    inputCtrl.LocationType.elementConfig.options.length === 0
  ) {
    inputCtrl = populateDropdownData(
      "LocationType",
      locationTypes,
      inputCtrl,
      "locationTypes"
    );
  }
  if (
    billingTypes &&
    inputCtrl &&
    inputCtrl.BillingType &&
    inputCtrl.BillingType.elementConfig.options.length === 0
  ) {
    inputCtrl = populateDropdownData(
      "BillingType",
      billingTypes,
      inputCtrl,
      "billingTypes"
    );
  }
  if (
    searchPageInfo.pageGroupType === PAGE_GROUP_TYPE.PAGE_GROUP_SHUTTLE_TRIP &&
    shuttleOptionsData &&
    shuttleOptionsData.shuttleCodes &&
    inputCtrl &&
    inputCtrl.shuttleCode &&
    inputCtrl.shuttleCode.elementConfig.options.length >= 1
  ) {
    inputCtrl = populateDropdownData(
      "shuttleCode",
      shuttleOptionsData.shuttleCodes,
      inputCtrl,
      "shuttleCode"
    );
  }
  if (
    searchPageInfo.pageGroupType === PAGE_GROUP_TYPE.PAGE_GROUP_SHUTTLE_TRIP &&
    shuttleOptionsData &&
    shuttleOptionsData.shuttleDrivers &&
    inputCtrl &&
    inputCtrl.badgeNo &&
    inputCtrl.badgeNo.elementConfig.options.length >= 1
  ) {
    inputCtrl = populateDropdownData(
      "badgeNo",
      shuttleOptionsData.shuttleDrivers,
      inputCtrl,
      "shuttleDriver"
    );
  }
  if (
    searchPageInfo.pageGroupType === PAGE_GROUP_TYPE.PAGE_GROUP_LANDMARK &&
    shuttleOptionsData &&
    shuttleOptionsData.shuttleDrivers &&
    inputCtrl &&
    inputCtrl.landmarkCategories &&
    inputCtrl.landmarkCategories.elementConfig.options.length === 1
  ) {
    inputCtrl = populateDropdownData(
      "landmarkCategories",
      shuttleOptionsData.landmarkCategories,
      inputCtrl,
      "landmarkCategory"
    );
  }
  if (
    searchPageInfo.pageGroupType === PAGE_GROUP_TYPE.PAGE_GROUP_LANDMARK &&
    shuttleOptionsData &&
    shuttleOptionsData.landmarkStatuses &&
    inputCtrl &&
    inputCtrl.status &&
    inputCtrl.status.elementConfig.options.length === 1
  ) {
    inputCtrl = populateDropdownData(
      "status",
      shuttleOptionsData.landmarkStatuses,
      inputCtrl,
      "landmarkStatus"
    );
  }
  return inputCtrl;
};

const loadDependentOrg = (
  pageName,
  searchPageInfo,
  inputCtrl,
  dependentOrg,
  selectedOrgKeyValue,
  locationListWithCode
) => {
  if (
    dependentOrg.length &&
    inputCtrl &&
    ((inputCtrl.SelectedChildOrgUnitId &&
      inputCtrl.SelectedChildOrgUnitId.elementConfig.options.length === 1) ||
      (inputCtrl.locationId &&
        inputCtrl.locationId.elementConfig.options.length === 1) ||
      (inputCtrl.EmployeeLocationId &&
        inputCtrl.EmployeeLocationId.elementConfig.options.length === 1) ||
      (inputCtrl.Location &&
        inputCtrl.Location.elementConfig.options.length === 1) ||
      (inputCtrl.locationCodes &&
        (inputCtrl.locationCodes.elementConfig.options.length === 1 ||
          inputCtrl.locationCodes.elementConfig.options.length === 0)) ||
      (inputCtrl.locationCode &&
        (inputCtrl.locationCode.elementConfig.options.length === 1 ||
          inputCtrl.locationCode.elementConfig.options.length === 0)))
  ) {
    if (
      searchPageInfo.pageGroupType === PAGE_GROUP_TYPE.PAGE_GROUP_RESERVATION ||
      searchPageInfo.pageGroupType === PAGE_GROUP_TYPE.PAGE_GROUP_VEHICLE ||
      searchPageInfo.pageGroupType === PAGE_GROUP_TYPE.PAGE_GROUP_BILLING
    ) {
      inputCtrl = populateDropdownData(
        "SelectedChildOrgUnitId",
        dependentOrg,
        inputCtrl,
        "location"
      );
    } else if (pageName === ADMIN_PAGE.RESERVE_SYNC_PAGE) {
      inputCtrl = populateDropdownData(
        "locationId",
        dependentOrg,
        inputCtrl,
        "location"
      );
    } else if (
      searchPageInfo.pageGroupType === PAGE_GROUP_TYPE.PAGE_GROUP_MEMBER ||
      searchPageInfo.pageGroupType ===
        PAGE_GROUP_TYPE.PAGE_GROUP_ROLES_PERMISSIONS ||
      searchPageInfo.pageGroupType ===
        PAGE_GROUP_TYPE.PAGE_GROUP_SERVICE_MECHANIC
    ) {
      let empLocList = [];
      empLocList.push(selectedOrgKeyValue);
      empLocList = [...empLocList, ...dependentOrg];
      inputCtrl = populateDropdownData(
        "EmployeeLocationId",
        empLocList,
        inputCtrl,
        "location"
      );
    } else if (
      searchPageInfo.pageGroupType === PAGE_GROUP_TYPE.PAGE_GROUP_LANDMARK
    ) {
      inputCtrl = populateDropdownData(
        "locationCodes",
        locationListWithCode,
        inputCtrl,
        "landmarkLocation"
      );
    } else if (
      searchPageInfo.pageGroupType === PAGE_GROUP_TYPE.PAGE_GROUP_SHUTTLE
    ) {
      inputCtrl = populateDropdownData(
        "locationCodes",
        locationListWithCode,
        inputCtrl,
        "shuttleLocation"
      );
    } else if (
      searchPageInfo.pageGroupType === PAGE_GROUP_TYPE.PAGE_GROUP_SHUTTLE_TRIP
    ) {
      inputCtrl = populateDropdownData(
        "locationCode",
        locationListWithCode,
        inputCtrl,
        "shuttleTrip"
      );
    }
  }
  return inputCtrl;
};
export const getTopMainLinkUrl = (pageName, memberId) => {
  const linkList = [];
  if (
    pageName === ADMIN_PAGE.MEMBER_SEARCH_PAGE &&
    hasPermission(PermissionName.NEW_MEMBER)
  ) {
    linkList.push({
      linkUrl: "/newMember",
      linkName: "New " + getMemberLabelByOrgRentalType(),
      isAnchorLink: true,
    });
  } else if (
    pageName === ADMIN_PAGE.VEHICLE_SEARCH_PAGE &&
    hasPermission(PermissionName.NEW_VEHICLE)
  ) {
    linkList.push({
      linkUrl: "/newVehicle",
      linkName: "New Vehicle",
      isAnchorLink: true,
    });
  } else if (
    pageName === ADMIN_PAGE.SHUTTLE_SEARCH_PAGE &&
    hasPermission(PermissionName.CC_ADD_SHUTTLE)
  ) {
    linkList.push({
      linkUrl: "/shuttle",
      linkName: "New Shuttle",
      isAnchorLink: true,
    });
  } else if (
    pageName === ADMIN_PAGE.LANDMARK_SEARCH_PAGE &&
    hasPermission(PermissionName.CC_ADD_LANDMARK)
  ) {
    linkList.push({
      linkUrl: "landmark",
      linkName: "New Landmark",
      isAnchorLink: true,
    });
  } else if (
    (pageName === ADMIN_PAGE.RESERVATION_SEARCH_PAGE ||
      pageName === ADMIN_PAGE.MEMBER_RESERVATION_SEARCH_PAGE ||
      pageName === ADMIN_PAGE.MEMBER_VEHICLE_RESERVATION_SEARCH_PAGE ||
      pageName === ADMIN_PAGE.MEMBER_BILLING_SEARCH_PAGE ||
      pageName === ADMIN_PAGE.MEMBER_FEEDBACK_SEARCH_PAGE ||
      pageName === ADMIN_PAGE.MEMBER_PACKAGE_SEARCH_PAGE ||
      pageName === ADMIN_PAGE.MEMBER_SUMMARY_PAGE ||
      pageName === ADMIN_PAGE.MEMBER_MESSAGE_SEARCH_PAGE) &&
    hasPermission(PermissionName.NEW_RESERVATION)
  ) {
    linkList.push({
      linkUrl: "/updateReservation",
      linkName: "New Reservation",
      memberId: memberId,
      isAnchorLink: true,
    });
  } else if (pageName === ADMIN_PAGE.MEMBER_VEHICLE_SEARCH) {
    if (hasPermission(PermissionName.NEW_VEHICLE)) {
      linkList.push({
        linkUrl: "/newVehicle",
        linkName: "New Vehicle",
        memberId: memberId,
        isAnchorLink: true,
      });
    }
    if (hasPermission(PermissionName.NEW_RESERVATION)) {
      linkList.push({
        linkUrl: "/updateReservation",
        linkName: "New Reservation",
        memberId: memberId,
        isAnchorLink: true,
      });
    }
  } else if (pageName === ADMIN_PAGE.MEMBER_ACTIVITY_SEARCH_PAGE) {
    if (hasPermission(PermissionName.ADD_ACTIVITY_LOG)) {
      linkList.push({ linkName: "Add New Activity", linkType: "ADD_ACTIVITY" });
    }
    if (hasPermission(PermissionName.NEW_RESERVATION)) {
      linkList.push({
        linkUrl: "/updateReservation",
        linkName: "New Reservation",
        memberId: memberId,
        isAnchorLink: true,
      });
    }
  }
  return linkList;
};
export const getActualPageName = (pageName) => {
  if (pageName === ADMIN_PAGE.MEMBER_VEHICLE_RESERVATION_SEARCH_PAGE) {
    return "My Vehicle Reservation";
  } else if (pageName === ADMIN_PAGE.MEMBER_RESERVATION_SEARCH_PAGE) {
    return "Reservations";
  } else if (pageName === ADMIN_PAGE.MEMBER_VEHICLE_SEARCH) {
    return "My Vehicle";
  } else if (pageName === ADMIN_PAGE.MEMBER_FAVORITE_ASSET_SEARCH_PAGE) {
    return "Favourites";
  } else if (pageName === ADMIN_PAGE.MEMBER_FEEDBACK_SEARCH_PAGE) {
    return "Feedback";
  } else if (pageName === ADMIN_PAGE.MEMBER_ACTIVITY_SEARCH_PAGE) {
    return "Activity";
  } else if (pageName === ADMIN_PAGE.MEMBER_BILLING_SEARCH_PAGE) {
    return "Billing";
  } else if (pageName === ADMIN_PAGE.MEMBER_MESSAGE_SEARCH_PAGE) {
    return "Messsages";
  } else if (pageName === ADMIN_PAGE.SERVICE_AGENT_SEARCH_PAGE) {
    return "Service Agent Report";
  } else if (pageName === ADMIN_PAGE.MECHANIC_APP_REPORT_SEARCH_PAGE) {
    return "Mechanics Report";
  } else if (pageName === ADMIN_PAGE.READY_LINE_AGENT_SEARCH_PAGE) {
    return "Ready Line Agent Report";
  } else if (pageName === ADMIN_PAGE.COMPLAINTS_SEARCH_PAGE) {
    return "Complaint";
  } else if (pageName === ADMIN_PAGE.IMPLEMENTATION_SEARCH_PAGE) {
    return "Implementation";
  } else if (pageName === ADMIN_PAGE.BIRDS_EYE_VIEW_SEARCH_PAGE) {
    return "Bird's Eye View";
  } else if (pageName === ADMIN_PAGE.MEMBER_PACKAGE_SEARCH_PAGE) {
    return "Package";
  } else if (pageName === ADMIN_PAGE.VEHICLE_AVAILABILITY) {
    return "Vehicle Availability";
  } else if (pageName === ADMIN_PAGE.SHUTTLE_SEARCH_PAGE) {
    return "Shuttles";
  } else if (pageName === ADMIN_PAGE.LANDMARK_SEARCH_PAGE) {
    return "Landmarks";
  } else if (pageName === ADMIN_PAGE.SHUTTLE_TRIP_SEARCH_PAGE) {
    return "Shuttle Trip";
  }
};
export const validateSearchFields = (data) => {
  let msg = "";
  if (data) {
    if (!data.StartDate) {
      msg = "From date is required";
    } else if (data.EndDate) {
      let dateDiff = getDateDifferentInDays(
        getDateTimeString(data.StartDate),
        getDateTimeString(data.EndDate)
      );
      if (dateDiff < 0) {
        msg = "From date cannot be after the To date";
      }
    }
    if (data.StartDate && data.EndDate) {
      let dateDiff = getDateDifferentInDays(
        getDateTimeString(data.StartDate),
        getDateTimeString(data.EndDate)
      );
      if (dateDiff > 30) {
        msg = "The date duration (" + dateDiff + "days) cannot exceed 30 days";
      }
    }
  }
  return msg ? ReactHtmlParser(msg) : "";
};
export const validateOnChangeSearchDateFields = (startDate, endDate) => {
  let msg = "";
  if (!startDate) {
    msg = "From date is required";
  } else if (endDate) {
    let dateDiff = getDateDifferentInDays(
      getDateTimeString(startDate),
      getDateTimeString(endDate)
    );
    if (dateDiff < 0) {
      msg = "From date cannot be after the To date";
    }
  }
  if (startDate && endDate) {
    let dateDiff = getDateDifferentInDays(
      getDateTimeString(startDate),
      getDateTimeString(endDate)
    );
    if (dateDiff > 30) {
      msg = "The date duration (" + dateDiff + "days) cannot exceed 30 days ";
    }
  }
  return msg ? ReactHtmlParser(msg) : "";
};

import moment from "moment";
import React, { useEffect, useState } from "react";
import Draggable from "react-draggable";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import AddressComponent from "../../../components/common/address-component/address-component";
import { LabelAndValuePairControl } from "../../../components/UI/Input/label-value-pair-control";
import { showNotification } from "../../../components/UI/Toaster/Toaster";
import { AddQuickMemberViewModel } from "../../../services/entities/view-models/AddQuickMemberViewModel";
import { getErrorToastData, updateObject } from "../../../shared/utility";
import { validateAddEditQuickMember } from "../MemberHelper";
export const AddQuickMemberModal = (props) => {
  const [addMemberInput, setAddMemberInput] = useState(
    new AddQuickMemberViewModel()
  );
  const [isValidateDone, setIsValidateDone] = useState(false);
  useEffect(() => {
    if (!props.addQuickMemberInput && !props.addQuickMemberInput.EmailAddress) {
      setAddMemberInput(props.addQuickMemberInput);
    }
  }, []);
  const inputChangedHandler = (event, controlName, elementType) => {
    const memberReq = { ...addMemberInput };
    let value = null;
    if (event !== "Invalid date" && elementType === "datetimepicker") {
      value = event ? moment(event).format("MM:DD:YYYY:HH:mm") : event;
    } else {
      value = event.target.value;
    }
    const updatedControls = updateObject(memberReq, {
      [controlName]: value,
    });
    setAddMemberInput(updatedControls);
  };
  const inputMemberAddressChangedHandler = (
    event,
    controlName,
    elementType
  ) => {
    let updatedControls = { ...addMemberInput };
    let primaryAddress = { ...addMemberInput.PrimaryAddress };
    if (controlName === "Country") {
      primaryAddress.State = null;
      primaryAddress.City = null;
      primaryAddress.ZipCode = null;
      primaryAddress.StateText = null;
      primaryAddress.CityText = null;
      primaryAddress.ZipCodeText = null;
      updatedControls.CountryName =
        event.target[event.target.selectedIndex].text;
    } else if (controlName === "State") {
      primaryAddress.City = null;
      primaryAddress.ZipCode = null;
      primaryAddress.StateText = null;
      primaryAddress.CityText = null;
      primaryAddress.ZipCodeText = null;
      primaryAddress.StateName = event.target[event.target.selectedIndex].text;
    } else if (controlName === "City") {
      primaryAddress.ZipCode = null;
      primaryAddress.CityText = null;
      primaryAddress.ZipCodeText = null;
      primaryAddress.CityName = event.target[event.target.selectedIndex].text;
    } else if (controlName === "ZipCode") {
      primaryAddress.ZipCodeName =
        event.target[event.target.selectedIndex].text;
    }
    primaryAddress[controlName] = event.target.value;
    updatedControls = updateObject(updatedControls, {
      PrimaryAddress: { ...primaryAddress },
    });
    setAddMemberInput(updatedControls);
  };
  const inputLicenseChangeHandler = (event, controlName, elementType) => {
    let updatedControls = { ...addMemberInput };
    let value = null;
    if (event !== "Invalid date" && elementType === "datetimepicker") {
      value = event ? moment(event).format("MM:DD:YYYY:HH:mm") : event;
    } else {
      value = event.target.value;
    }
    let drivingLicense = { ...addMemberInput.DrivingLicense };
    if (controlName === "Country") {
      drivingLicense.DrivingLicenseStateId = null;
      drivingLicense.DrivingLicenseStateText = null;
      drivingLicense.DrivingLicenseCountryId = Number(value);
      drivingLicense.LicenseCountryName =
        event.target[event.target.selectedIndex].text;
    } else if (controlName === "State") {
      drivingLicense.DrivingLicenseStateId = Number(value);
      drivingLicense.LicenseStateName =
        event.target[event.target.selectedIndex].text;
    } else if (controlName === "StateText") {
      drivingLicense.DrivingLicenseStateText = value;
    } else {
      drivingLicense[controlName] = value;
    }
    updatedControls = updateObject(updatedControls, {
      DrivingLicense: { ...drivingLicense },
    });
    setAddMemberInput(updatedControls);
  };
  const renderPhoneAutoSueggestion = (type, phoneInfo) => {
    let updatedControls = { ...addMemberInput };
    updatedControls[type].CountryCode = phoneInfo ? phoneInfo.value : null;
    updatedControls[type].CountryIso = phoneInfo ? phoneInfo.countryIso : null;
    setAddMemberInput(updatedControls);
  };
  const inputPhoneChangeHandler = (event, controlName) => {
    let updatedControls = { ...addMemberInput };
    updatedControls[controlName].Number = event.target.value;
    setAddMemberInput(updatedControls);
  };
  const onShowConflictedMemberModal = () => {
    var msg = validateAddEditQuickMember(addMemberInput);
    if (msg) {
      showNotification(getErrorToastData(msg));
      setIsValidateDone(true);
    } else {
      setIsValidateDone(false);
      props.closeModal();
      props.showConflictedMemberModal(addMemberInput);
    }
  };
  const renderLabelInputPair = (
    labelCaption,
    controlName,
    value,
    isRequired = false,
    nodeElement = "input",
    dropdownItem = null,
    isLicense = false,
    isDisableAfterCurrentDate = null
  ) => {
    return (
      <LabelAndValuePairControl
        elementConfig={{
          name: controlName,
          value: value,
        }}
        otherConfig={{
          caption: labelCaption,
          isRequired: isRequired,
          dropdownItem: nodeElement === "select" ? dropdownItem : [],
          value: value,
          dateFormat: "D",
          isDisableAfterCurrentDate: isDisableAfterCurrentDate,
          phoneNumberParentClasses: ["MobilePhone"].includes(controlName)
            ? "memberInput memberInput-phone"
            : "",
        }}
        isEditMode={true}
        onChange={isLicense ? inputLicenseChangeHandler : inputChangedHandler}
        onChangePhone={inputPhoneChangeHandler}
        renderMobileAutoSueggestion={renderPhoneAutoSueggestion}
        parentRowClass={`form-group row  ${isRequired ? "required" : ""}`}
        labelClasses="col-sm-4 col-form-label font-weight-bold"
        valueClasses="col-sm-8"
        nodeElement={nodeElement}
        isValidateDone={isValidateDone}
      />
    );
  };
  return (
    <Draggable handle=".cursor">
      <Modal
        isOpen={props.showModal}
        className="no-cursor"
        toggle={() => {
          props.closeModal();
        }}
      >
        <ModalHeader
          className="modal-header bg-primary modal-title text-white cursor"
          toggle={() => {
            props.closeModal();
          }}
        >
          <i className="fas fa-arrows-alt" />
          {props.modalTitle ? props.modalTitle : "Add Driver"}
        </ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-lg-12">
              {renderLabelInputPair(
                "First Name",
                "FirstName",
                addMemberInput.FirstName,
                true
              )}
              {renderLabelInputPair(
                "Middle Name",
                "MiddleName",
                addMemberInput.MiddleName,
                false
              )}
              {renderLabelInputPair(
                "Last Name",
                "LastName",
                addMemberInput.LastName,
                true
              )}
              {renderLabelInputPair(
                "Date of Birth",
                "DateOfBirth",
                addMemberInput?.DrivingLicense?.DateOfBirth || null,
                false,
                "datetime",
                null,
                true,
                true
              )}
              <AddressComponent
                isAstericsRequired={false}
                type="additonalDriver"
                key="additonalDriver-Address-Component"
                onChangeMethod={inputMemberAddressChangedHandler}
                address={{ ...addMemberInput.PrimaryAddress }}
                resetLoader={props.resetLoader}
              />
              {renderLabelInputPair(
                "Mobile",
                "MobilePhone",
                addMemberInput.MobilePhone,
                false,
                "phonenumber"
              )}
              {renderLabelInputPair(
                "Email",
                "EmailAddress",
                addMemberInput.EmailAddress
              )}
              {renderLabelInputPair(
                "Driver's Lic.",
                "DrivingLicenseNumber",
                addMemberInput.DrivingLicense.DrivingLicenseNumber,
                true,
                "input",
                null,
                true
              )}
              <AddressComponent
                isAstericsRequired={true}
                type="additonalDriverLicense"
                key="additonalDriverLicense-address-component"
                onChangeMethod={inputLicenseChangeHandler}
                resetLoader={props.resetLoader}
                address={{
                  Country:
                    addMemberInput.DrivingLicense.DrivingLicenseCountryId,
                  State: addMemberInput.DrivingLicense.DrivingLicenseStateId,
                  StateText:
                    addMemberInput.DrivingLicense.DrivingLicenseStateText,
                }}
                isCityNotRequired={true}
                isPostalNotRequired={true}
                isStreet1NotRequired={true}
                isStreet2NotRequired={true}
                coutryLabel="Lic. Country"
                stateLabel="Lic. State"
                isValidateDone={isValidateDone}
              />
              {renderLabelInputPair(
                "License Exp. Date",
                "LicenseExpirationDate",
                addMemberInput?.DrivingLicense?.LicenseExpirationDate || null,
                true,
                "datetime",
                null,
                true,
                false
              )}
              {renderLabelInputPair(
                "Reason",
                "Reason",
                addMemberInput.Reason,
                false,
                "textarea"
              )}
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            type="buton"
            className="btn btn-primary waves-effect waves-light btnSize ml-2"
            onClick={() => onShowConflictedMemberModal()}
          >
            Submit
          </button>
          <button
            type="button"
            className="btn btn-secondary  waves-effect waves-light btnSize ml-2"
            onClick={() => props.closeModal()}
          >
            Cancel
          </button>
        </ModalFooter>
      </Modal>
    </Draggable>
  );
};
export default AddQuickMemberModal;

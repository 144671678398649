import Phone from "../models/Phone";
import Address from "../models/Address";
import DrivingLicense from "../models/DrivingLicense";
import { getFullName } from "../../../shared/utility";

export const AddQuickMemberRequest = function (data) {
  if (!data) data = {};
  if (data.MemberId) {
    this.MemberId = data.MemberId || null;
  }
  if (data.PersonBlackListId) {
    this.PersonBlackListId = data.PersonBlackListId;
  }
  this.FirstName = data.FirstName || null;
  this.MiddleName = data.MiddleName || null;
  this.LastName = data.LastName || null;
  this.EmailAddress = data.EmailAddress || null;
  this.Reason = data.Reason || null;
  this.DateOfBirth = data.DateOfBirth || null;
  this.License = data.License || null;
  if (data.CountryId) {
    this.CountryId = data.CountryId;
  } else {
    this.CountryText = data.CountryText || null;
  }
  if (data.StateId) {
    this.StateId = data.StateId;
  } else {
    this.StateText = data.StateText || null;
  }
  if (data.CityId) {
    this.CityId = data.CityId;
  } else {
    this.CityText = data.CityText || null;
  }
  if (data.ZipCodeId) {
    this.ZipCodeId = data.ZipCodeId;
  } else {
    this.ZipCodeText = data.ZipCodeText || null;
  }
  if (data.LicenseCountryId) {
    this.LicenseCountryId = data.LicenseCountryId;
  } else {
    this.LicenseCountryText = data.LicenseCountryText || null;
  }
  if (data.LicenseStateId) {
    this.LicenseStateId = data.LicenseStateId;
  } else {
    this.LicenseStateText = data.LicenseStateText || null;
  }
  //this.MobilePhone = new Phone(data.MobilePhone);
};

const AddEditVehicleRequest = function(
  vehicleInputReq,
  managerId,
  ameNitiesIds,
  isReservationRequestBased
) {
  this.MemberId = managerId || null;
  this.SelectedChildOrgUnitId = vehicleInputReq.PersonAssetOrgUnitId || null;
  this.CategoryId = vehicleInputReq.CategoryId || null;
  this.PersonAssetGroupType = vehicleInputReq.AssetType || null;
  this.PersonAssetType = vehicleInputReq.SubVehicleTypeKey || null;
  this.PersonAssetOtherType = vehicleInputReq.PersonAssetOtherType || null;
  this.MemberAssetId = vehicleInputReq.PersonAssetId || null;
  this.UnitNo = vehicleInputReq.UnitNo || null;
  this.SelectedAmenities = ameNitiesIds || [];

  this.PersonAssetMake = vehicleInputReq.Make || null;
  this.PersonAssetOtherMake = vehicleInputReq.OtherMake || null;
  this.PersonAssetModel = vehicleInputReq.Model || null;
  this.PersonAssetYear = vehicleInputReq.VehicleYear || null;
  this.SeatingCapacity = vehicleInputReq.SeatingCapacity || null;
  this.PersonAssetColor = vehicleInputReq.Color || null;

  this.ActualCashValue = vehicleInputReq.ActualCashValue || null;
  this.NickName = vehicleInputReq.Name || null;
  this.AssetVin = vehicleInputReq.VinNumber || null;
  this.AssetLicensePlate = vehicleInputReq.LicenseNo || null;
  this.AssetMileageAtSignUp = vehicleInputReq.MileageAtSignup || null;

  this.HourlyRate = vehicleInputReq.RatePerHour || null;
  this.DailyRate = vehicleInputReq.RatePerDay || null;
  this.WeeklyRate = vehicleInputReq.RatePerWeek || null;
  this.MonthlyRate = vehicleInputReq.RatePerMonth || null;
  this.WeekendRate = vehicleInputReq.RatePerWeekendDay || null;

  this.DriveType = vehicleInputReq.DriveType || null;
  this.FuelType = vehicleInputReq.FuelType || null;
  this.FuelConsumptionUnit = vehicleInputReq.FuelUnit || null;
  this.TransmissionType = vehicleInputReq.TransmissionTypeKey || null;
  this.InsuranceCarrier = vehicleInputReq.InsuranceCarrier || null;
  this.PolicyNumber = vehicleInputReq.PolicyNumber || null;
  this.AnnualMilesDriven = vehicleInputReq.AnnualMileageDriven || null;
  this.LeadTimeInMinutes = vehicleInputReq.LeadTimeInMinutes || null;
  this.BufferTimeInMinutes = vehicleInputReq.BufferTimeInMinutes || null;

  this.ParkingDistanceOption = vehicleInputReq.ParkingDistanceOptionKey || null;
  this.ParkingAddressStreet1 = vehicleInputReq.ParkingAddressStreet1 || null;
  this.ParkingAddressStreet2 = vehicleInputReq.ParkingAddressStreet2 || null;
  this.ParkingAddressStreet3 = vehicleInputReq.ParkingAddressStreet3 || null;
  this.ParkingAddressCountryId =
    vehicleInputReq.ParkingAddressCountryId || null;
  this.ParkingAddressStateId = vehicleInputReq.ParkingAddressStateId || null;
  this.ParkingAddressProvinceId =
    vehicleInputReq.ParkingAddressProvinceId || null;
  this.ParkingAddressCityId = vehicleInputReq.ParkingAddressCityId || null;
  this.ParkingAddressZipCodeId =
    vehicleInputReq.ParkingAddressZipCodeId || null;
  this.ParkedText = vehicleInputReq.ParkedTextKey || null;
  this.ParkingNotes = vehicleInputReq.ParkingNotes || null;
  this.OwnerNotes = vehicleInputReq.OwnerNotes || null;
  this.DisclaimerText = vehicleInputReq.DisclaimerText || null;
  this.NotifyMapUrl = vehicleInputReq.NotifyMapUrl || null;

  //this.KeyHandOff = vehicleInputReq.PersonAssetOrgUnitId || null;
  this.ListingEnabled = vehicleInputReq.AllowedToBook || null;
  //this.SmokingAllowed = vehicleInputReq.PersonAssetOrgUnitId || null;
  //this.PetAllowed = vehicleInputReq.PersonAssetOrgUnitId || null;
  //this.HandicapSignEnabled = vehicleInputReq.PersonAssetOrgUnitId || null;
  //this.WheelchairEnabled = vehicleInputReq.PersonAssetOrgUnitId || null;

  this.InstantRentalEnabled = isReservationRequestBased || null;
  this.GetPaidOption = vehicleInputReq.GetPaidOption || null;
  this.DisplayInJsiMarketPlace =
    vehicleInputReq.DisplayInJsiMarketPlace || null;
  this.CalcUnit = vehicleInputReq.CalcUnit || null;

  this.Longitude = vehicleInputReq.CurrentLongitude || null;
  this.Latitude = vehicleInputReq.CurrentLatitude || null;

  this.LegallyShareAck = true;
  this.TermsAndConditionsAck = true;
  this.VehicleCleanAck = true;
  this.SafeAndStreetLegalAck = true;
  this.UnmodifiedForHandicapAck = true;
  this.RecognizedDriverAck = true;
  this.DoubleCheckedVinAck = true;
};
export default AddEditVehicleRequest;

import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getVehicleCategoryInfo,
  getVehicleCategoryInfoReset,
} from "../../../store/actions/index";
import Draggable from "react-draggable";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import {
  formatAmountValue,
  getBasicToastResponse,
} from "../../../shared/utility";
import { showNotification } from "../../../components/UI/Toaster/Toaster";
import { FlashyCar } from "../../../shared/AppImages";

export const VehicleCategoryQuickModal = (props) => {
  const vehicleCategoryResponse = useSelector(
    (state) => state.vehicleReducer.vehicleCategoryResponse
  );
  const dispatch = useDispatch();
  const [vehicleCategoryData, setVehicleCategoryData] = useState({});
  useEffect(() => {
    initVehicleCategoryModal();
  }, []);
  useEffect(() => {
    if (!vehicleCategoryResponse) {
      return;
    }
    if (vehicleCategoryResponse && vehicleCategoryResponse.StatusCode === 0) {
      setVehicleCategoryData(vehicleCategoryResponse.CategoryInfo);
    } else {
      showNotification(getBasicToastResponse(vehicleCategoryResponse));
      dispatch(getVehicleCategoryInfoReset());
    }
  }, [vehicleCategoryResponse]);
  const initVehicleCategoryModal = () => {
    const dataObj = {};
    dataObj.CategoryId = props.defaultData.CategoryId || null;
    dataObj.PersonAssetId = props.defaultData.PersonAssetUnitNo || null;
    dataObj.ReservationId = props.defaultData.ReservationId || null;
    dataObj.PromisedStartTime = props.defaultData.promisedStartTime || null;
    dispatch(getVehicleCategoryInfoReset());
    dispatch(getVehicleCategoryInfo(dataObj));
  };
  const getLblHtmlCtrl = (value, customClasses) => {
    return (
      <label className={`col-sm-12  ${customClasses || ""}`}>
        {value || "--"}
      </label>
    );
  };
  const getFormattedText = (data) => {
    return (
      <Fragment>
        {data.RatePerHour
          ? getLblHtmlCtrl(
              formatAmountValue(data.RatePerHour) + " / hour",
              "text-primary d-inline-block"
            )
          : getLblHtmlCtrl("N/A / hour", "text-primary d-inline-block")}

        {data.RatePerDay
          ? getLblHtmlCtrl(
              formatAmountValue(data.RatePerDay) + " / day",
              "text-primary d-inline-block"
            )
          : getLblHtmlCtrl("N/A / weekend", "text-primary d-inline-block")}
        {data.RatePerWeekendDay
          ? getLblHtmlCtrl(
              formatAmountValue(data.RatePerWeekendDay) + " / weekend",
              "text-primary d-inline-block"
            )
          : getLblHtmlCtrl("N/A / weekend", "text-primary d-inline-block")}
        {data.RatePerWeek
          ? getLblHtmlCtrl(
              formatAmountValue(data.RatePerWeek) + " / week",
              "text-primary d-inline-block"
            )
          : getLblHtmlCtrl("N/A / week", "text-primary d-inline-block")}
        {data.RatePerMonth
          ? getLblHtmlCtrl(
              formatAmountValue(data.RatePerMonth) + " / monthly",
              "text-primary d-inline-block"
            )
          : getLblHtmlCtrl("N/A / weekend", "text-primary d-inline-block")}
      </Fragment>
    );
  };
  return (
    <Draggable handle=".cursor">
      <Modal
        isOpen={props.showModal}
        className="no-cursor"
        toggle={() => {
          props.closeModal();
        }}
      >
        <ModalHeader
          className="modal-header bg-primary modal-title text-white cursor"
          toggle={() => {
            props.closeModal();
          }}
        >
          <i className="fas fa-arrows-alt" /> Vehicle Category Info
        </ModalHeader>
        <ModalBody>
          <div className="row col-lg-12 p-0 row">
            <div className="col-lg-6 p-0">
              <img src={FlashyCar} style={{ width: "20rem" }} />
            </div>
            <div className="col-lg-6 p-0">
              <label className="col-sm-12 col-form-label font-weight-bold">
                Category Name :
              </label>
              <div className="col-sm-12 col-form-label">
                <label className="">
                  {vehicleCategoryData ? vehicleCategoryData.DisplayText : "--"}
                </label>
              </div>
              <label className="col-sm-12 col-form-label font-weight-bold">
                Rate :
              </label>
              <div className="col-sm-12">
                {getFormattedText(
                  vehicleCategoryData ? vehicleCategoryData : ""
                )}
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </Draggable>
  );
};
export default VehicleCategoryQuickModal;

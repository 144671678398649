import React, { Component, Fragment } from "react";
import ReactHtmlParser from "react-html-parser";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";
import GlobalSearchPopup from "../../containers/search/global_search/GlobalSearchPopup";
import * as actions from "../../store/actions/index";
import NavigationItems from "../Navigations/NavigationItems/NavigationItems";
import FleetUtilizationDropdown from "../dropdownmenu/fleet-utilization-dropdown";
import Orgdropdown from "../dropdownmenu/org-dropdown";
import QuickHistoryDropdown from "../dropdownmenu/quick-history-dropdown";
import UserDropdown from "../dropdownmenu/user-dropdown";

import Spinner from "../../components/UI/Spinner/Spinner";
import { showNotification } from "../../components/UI/Toaster/Toaster";
import * as global from "../../shared/GlobalVar";
import {
  getBasicToastResponse,
  getFullName,
  getNoteTooltipHtml,
  hasPermission,
  isNullOrEmpty,
  populateBreadCrumbData,
  toTitleCase,
} from "../../shared/utility";
import { getMemberLabelByOrgRentalType } from "../../shared/utility/system-utility";

import DropdownList from "react-widgets/lib/DropdownList";
import { getGlobalSearchAreas } from "../../containers/search/global_search/global-search-area";
import { getPageName } from "../../containers/search/search-utility/search-utility";
import { NoUserPhoto, SPRITE_IMG, USER_RIGHT_ARROW } from "../../shared/AppImages";
import PermissionName from "../../shared/Permissions";
import { getKeyValFromList } from "../../shared/utility/list-utility";
import { createTooltip } from "../../shared/utility/tooltip-utility";
import InputClearIcon from "../UI/icon/input-clear-icon";
import ImageContainer from "../UI/image/image-container";
import WatermarkTextComponent from "../UI/label/watermark-text";
import ConfirmModal from "../common/modal/confirm-modal";
import TooltipItem from "../tooltip/TooltipItem";
import { GetOrgInfoWithLogo } from "./header-helper";
import LogoCreator from "./logo-creator";

class TopHeader extends Component {
  state = {
    loading: false,
    selectedRowFromGlobalSearch: null,
    orgDataResponse: null,
    orgLocationLogo: null,
    orgLocationCaption: "",
    showImporsonateExitConfirmModal: false,
    showGlobalSearchModal: false,
    globalSearchAreas: null,
    isVisibleSearchArea: false,
    selectedSearchArea: null,
    searchText: null,
    isDataFound: false,
    keys: [],
    parentLoginResponse: this.props.parentLoginResponse,
    defaultSelectedArea: {},
    confirmationToSearchAllAreas: false,
    pageName: ""
  };

  componentDidMount() {
    const pageUrl = this.props.location && this.props.location.pathname;
    let selectedOrgInfo = GetOrgInfoWithLogo(
      this.props.loggedInUserData ? this.props.loggedInUserData.OrgUnits : [],
      this.props.selectedOrgKeyValue
    )[0];
    this.setState({
      orgLocationLogo: selectedOrgInfo ? selectedOrgInfo.OrgLogo : null,
      orgLocationCaption: selectedOrgInfo ? selectedOrgInfo.OrgName : "FOX",
      globalSearchAreas: getGlobalSearchAreas(),
      defaultSelectedArea: localStorage.getItem(
        "seleceted_global_search_section"
      )
        ? JSON.parse(localStorage.getItem("seleceted_global_search_section"))
        : getGlobalSearchAreas()[0],
      parentLoginResponse: this.props.parentLoginResponse
        ? this.props.parentLoginResponse
        : localStorage.getItem("parent_login_response")
          ? JSON.parse(localStorage.getItem("parent_login_response"))
          : null,
      pageName: getPageName(pageUrl)
    });
  }
  componentDidUpdate(prevProps, prevState) {
    const pageUrl = this.props.location && this.props.location.pathname;
    const pageName = getPageName(pageUrl);
    let selectedOrgInfo = GetOrgInfoWithLogo(
      this.props.loggedInUserData ? this.props.loggedInUserData.OrgUnits : [],
      this.props.selectedOrgKeyValue
    )[0];
    if (
      prevProps.loggedInUserData.CurrentOrgUnitId !==
      this.props.loggedInUserData.CurrentOrgUnitId || prevState.pageName !==
      pageName
    ) {
      this.setState({
        orgLocationLogo: selectedOrgInfo ? selectedOrgInfo.OrgLogo : null,
        orgLocationCaption: selectedOrgInfo ? selectedOrgInfo.OrgName : "FOX",
        parentLoginResponse: this.props.parentLoginResponse
          ? this.props.parentLoginResponse
          : localStorage.getItem("parent_login_response")
            ? JSON.parse(localStorage.getItem("parent_login_response"))
            : null,
        pageName: pageName
      });
    }
  }
  UNSAFE_componentWillReceiveProps(
    { orgDataResponse, loggedInUserData, exitImpersonateResponse },
    prevState
  ) {
    if (
      (orgDataResponse && !this.state.orgDataResponse) ||
      (this.state.orgDataResponse &&
        orgDataResponse &&
        !this.props.orgDataResponse)
    ) {
      this.setState(
        {
          orgDataResponse: orgDataResponse,
        },
        () => {
          this.props.onUpdateOrgdetailsWithLoginInfo(
            JSON.parse(JSON.stringify(this.props.loggedInUserData)),
            JSON.parse(JSON.stringify(orgDataResponse))
          );
        }
      );
    }
    if (
      loggedInUserData &&
      this.props.loggedInUserData &&
      String(this.props.loggedInUserData.CurrentOrgUnitId) !==
      String(loggedInUserData.CurrentOrgUnitId)
    ) {
      this.props.history.push("/Dashboard");
    }
    if (exitImpersonateResponse && !this.props.exitImpersonateResponse) {
      const impersonateMemberId = this.props.impersonateUserId;
      localStorage.setItem("impersonateUserId", "");
      if (exitImpersonateResponse.StatusCode === 0) {
        this.closeModal();
        this.props.history.push({
          pathname: "/member/memberProfile",
          search: "?memberId=" + impersonateMemberId,
          state: {
            breadCrumbList: populateBreadCrumbData(
              [],
              getMemberLabelByOrgRentalType(),
              "/member"
            ),
          },
        });
      } else {
        showNotification(getBasicToastResponse(exitImpersonateResponse));
      }
    }
  }

  openGlobalSearchModal = (event, searchType) => {
    if (event) {
      event.preventDefault();
    }
    const msg = "Please enter value you wish to search";
    var toastMsg = {
      msg: msg,
      toastType: global.TOAST_MSG.WARNING,
    };
    this.setState({
      selectedSearchArea: searchType || this.state.defaultSelectedArea,
      defaultSelectedArea: searchType || this.state.defaultSelectedArea
    }, () => {
      if (
        !this.state.confirmationToSearchAllAreas &&
        ((searchType && searchType.key === "ALL") ||
          (isNullOrEmpty(searchType) &&
            this.state.defaultSelectedArea &&
            this.state.defaultSelectedArea.key === "ALL"))
      ) {
        if (this.textInput.value.toString().trim()) {
          this.setState({ confirmationToSearchAllAreas: true });
        }
        // else {
        //   showNotification(toastMsg);
        // }
      } else {
        localStorage.setItem(
          "seleceted_global_search_section",
          JSON.stringify(this.state.selectedSearchArea)
        );
        if (this.textInput.value.toString().trim()) {
          this.setState({
            showGlobalSearchModal: true,
            isDataFound: false,
            isVisibleSearchArea: true,
          });
        }
      }
    });

  };
  openExitImpersonateConfirmModal = () => {
    this.setState({
      showImporsonateExitConfirmModal: true,
    });
  };

  closeModal = (hasSearchData) => {
    this.setState(
      {
        showImporsonateExitConfirmModal: false,
        showGlobalSearchModal: false,
        loading: false,
        isDataFound: hasSearchData || false,
        isVisibleSearchArea: false,
        confirmationToSearchAllAreas: false,
      },
      () => {
        document.getElementById("txt-global-search").blur();
      }
    );
  };

  onSwichOrg = (orgId) => {
    this.props.onGetOrgDetailsReset();
    this.props.onGetOrgDetails(orgId);
  };
  impersonateExit = () => {
    this.setState(
      {
        loading: true,
      },
      () => {
        this.props.onDoImpersonateExitReset();
        this.props.onDoImpersonateExit(
          this.props.parentToken,
          this.state.parentLoginResponse
        );
      }
    );
  };

  callBackToDisplayServerMessage = (response) => {
    var toastMsg = {
      msg: response.StatusMsg,
      toastType:
        response.StatusCode === 0
          ? global.TOAST_MSG.SUCCESS
          : global.TOAST_MSG.ERROR,
    };
    showNotification(toastMsg);
    this.closeModal();
  };

  goToSummaryPage = (searchInfo, infoType) => {
    this.closeModal(true);
    const breadCrumbList = populateBreadCrumbData(
      [],
      infoType === "reservation"
        ? "Reservation"
        : infoType === "rental"
          ? "Rental"
          : getMemberLabelByOrgRentalType(),
      infoType === "reservation"
        ? "/reservation"
        : infoType === "rental"
          ? "/rental"
          : "/member"
    );
    let pathName =
      infoType === "reservation" || infoType === "rental"
        ? "/reservationSummary"
        : infoType === "member"
          ? this.state.pageName === global.ADMIN_PAGE.EDIT_NEW_RESERVATION_PAGE
            ? "updateReservation"
            : "/member/memberProfile"
          : "";
    let searchItem =
      infoType === "reservation" || infoType === "rental"
        ? "?reservationId=" + searchInfo.ConfirmationKey
        : infoType === "member"
          ? "?memberId=" + searchInfo.PersonId
          : "";
    let selectedRowFromGlobalSearch = (
      <Redirect
        to={{
          pathname: pathName,
          search: searchItem,
          state: {
            breadCrumbList: breadCrumbList,
            memberName: infoType === "member" ? searchInfo.FirstName : "",
          },
        }}
      />
    );
    this.setState({
      selectedRowFromGlobalSearch: selectedRowFromGlobalSearch,
    });
  };

  toggleExpandWithDelayTime = () => {
    setTimeout(() => {
      this.setState({ isVisibleSearchArea: true, isDataFound: false });
    }, 300);
  };

  hideExpandedSearchArea = () => {
    setTimeout(() => {
      this.setState(
        { isVisibleSearchArea: false, selectedSearchArea: null },
        () => {
          document.getElementById("txt-global-search").blur();
        }
      );
    }, 0);
  };

  clearValue = () => {
    this.textInput.value = "";
    this.setState({ searchText: null, isVisibleSearchArea: false });
  };

  onKeyPress = (event, searchType) => {
    let keys = [...this.state.keys];
    const index = keys.indexOf(event.keyCode);
    if (index > -1) {
      keys.splice(index, 1);
    }
    if (event.keyCode === 13) {
      this.openGlobalSearchModal(null, searchType);
    }
    this.setState({ keys: keys });
  };
  onKeyDown = (event, searchType) => {
    let keys = [...this.state.keys];
    keys.push(event.keyCode);
    this.setState({ keys: keys });
    if (searchType === "ALL") {
      if (event.keyCode === 9 && keys.includes(16)) {
        if (event) {
          event.preventDefault();
        }
        document.getElementById("CUSTOMER_ADDRESS-GlobalSearch-Id").focus();
      } else if (event.keyCode === 9) {
        if (event) {
          event.preventDefault();
        }
        document.getElementById("txt-global-search").focus();
      }
    } else if (searchType === "SEARCHBOX") {
      if (event.keyCode === 9 && keys.includes(16)) {
        if (event) {
          event.preventDefault();
        }
        document.getElementById("ALL-GlobalSearch-Id").focus();
      }
    }
  };
  renderGlobalSearchBox = () => {
    return (
      <div className="col-sm-12 position--relative d-flex flex-center-content">
        <InputClearIcon
          inputValue={this.textInput ? this.textInput.value : ""}
          customDivClass="light-gray-color"
          onClickEvent={this.clearValue}
        />
        <div
          className="searchIconPosition vertical-align-center"
          onClick={this.openGlobalSearchModal}
        >
          <i className="fas fa-search"></i>
        </div>
        <div className="search-area-dropdown fs-2">
          <DropdownList
            data={this.state.globalSearchAreas || []}
            valueField="key"
            textField="value"
            defaultValue={this.state.defaultSelectedArea}
            value={getKeyValFromList(
              this.state.globalSearchAreas,
              this.state.selectedSearchArea &&
              this.state.selectedSearchArea.key,
              this.state.defaultSelectedArea
            )}
            onChange={(value) => this.openGlobalSearchModal(null, value)}
          />
        </div>
        <input
          style={{ paddingLeft: "12rem" }}
          type="text"
          className={`search__input ${this.state.isVisibleSearchArea ||
              (this.textInput && this.textInput.value)
              ? "border-radius-global-search-box"
              : ""
            }`}
          placeholder={
            this.state.defaultSelectedArea.key === "ALL" ? "Search by " +
              global.ADMIN_COMMON_CAPTION.CUSTOMER_ID +
              ", Name, Phone, Email, Address or Reservation Code" : this.state.defaultSelectedArea.key === "CUSTOMER_ID" ? "Search by Customer " + this.state.defaultSelectedArea.value : "Search by " + toTitleCase(this.state.defaultSelectedArea.key)
          }
          ref={(input) => (this.textInput = input)}
          id="txt-global-search"
          onFocus={(e) => {
            this.toggleExpandWithDelayTime();
          }}
          onBlur={(e) => {
            this.hideExpandedSearchArea();
          }}
          onChange={(event) =>
            this.setState({ searchText: event.target.value })
          }
          tabIndex={1}
          onKeyUp={(event) =>
            this.onKeyPress(event, this.state.selectedSearchArea)
          }
        />
      </div>
    );
  };
  render() {
    let hasShowAffiliateOrgsPermission = hasPermission(
      PermissionName.SHOW_AFFILIATE_ORGS
    );
    const isImpersonateMode =
      localStorage.getItem("loginMode") === "IMPERSONATE";
    const parentUserData = this.state.parentLoginResponse ? this.state.parentLoginResponse.User : null;
    const impersonatingMsg =
      parentUserData && this.props.userData
        ? getFullName(
          parentUserData.FirstName,
          parentUserData.LastName,
          parentUserData.MiddleName
        ) +
        " is impersonating " +
        getFullName(
          this.props.userData.FirstName,
          this.props.userData.LastName,
          this.props.userData.MiddleName
        )
        : "";
    return (
      <header>
        {this.state.selectedRowFromGlobalSearch
          ? this.state.selectedRowFromGlobalSearch
          : ""}
        <Fragment>
          <div className="headertop  pl-4">
            <div className={`org-logo`}>
              <div
                className={`position-relative user-nav logo-max-width ${this.state.orgLocationLogo ? "no-border-right" : ""
                  } ${hasShowAffiliateOrgsPermission ? "" : "no-org-dropdwon"} `}
              >
                {this.state.orgLocationLogo ? (
                  <img
                    src={this.state.orgLocationLogo}
                    alt="logo"
                    className={"logo-size logo"}
                  />
                ) : (
                  <LogoCreator
                    logCaption={this.state.orgLocationCaption}
                    logoCaptionClass={"logo-text"}
                    backgroundClass={"header-logo-background"}
                    logoSizeClass={"logo-size"}
                    customBGCSS={{ width: "7rem", height: "4rem", maxhight: "4rem" }}
                  />
                )}
              </div>
              {hasShowAffiliateOrgsPermission ? (
                <div className="user-nav p-0 parent-org-dropdown">
                  <Orgdropdown
                    orgUnits={this.props.loggedInUserData.OrgUnits || []}
                    switchOrg={this.onSwichOrg}
                    selectedOrg={this.props.selectedOrgKeyValue ? this.props.selectedOrgKeyValue.Key : {}}
                  />
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="common-search">
              {this.renderGlobalSearchBox()}
            </div>
            {isImpersonateMode ? (
              <div className="d-flex flex-end-content pr-0">
                <Fragment>
                  <WatermarkTextComponent values={global.COMMON_MSG.IMPERSONATE_MODE_TITLE} />
                  <span
                    id="exit-impersonate-id"
                    className="pseudo-link pl-2 vertical-align-center"
                    onClick={this.openExitImpersonateConfirmModal}
                  >
                    <svg className="user-nav__icon color--white">
                      <use xlinkHref={SPRITE_IMG + "#icon-log-out"} />
                    </svg>
                  </span>
                  <TooltipItem
                    placement="bottom"
                    toolTipId="exit-impersonate-id"
                    renderableHtml={ReactHtmlParser(
                      getNoteTooltipHtml("Click to Exit Impersonate")
                    )}
                    forceShow={true}
                  />
                </Fragment>
              </div>
            ) : (
              ""
            )}

            <div className={`user-nav p-0`}>
              <FleetUtilizationDropdown />
              <QuickHistoryDropdown />
              {isImpersonateMode ? (
                <div id="impersonate-user-container" className="col-lg-9 border-rounded horizontal-align-center p-0 h-85">
                  <div
                    className="border-impersonate-user vertical-align-center col-lg-5"
                    id="impersonating-user"
                  >
                    <ImageContainer
                      imageUrl={
                        (this.props.userData &&
                          this.props.userData.ProfileImageUrl) ||
                        NoUserPhoto
                      }
                      imageCaption={
                        (this.props.userData &&
                          this.props.userData.FirstName) ||
                        ""
                      }
                      imageTooltip={
                        this.props.userData
                          ? getFullName(
                            this.props.userData.FirstName,
                            this.props.userData.LastName,
                            this.props.userData.MiddleName
                          )
                          : ""
                      }
                      imageCaptionClass={"d-flex whiteColor pl-0"}
                      backgroundImageClass={"user-nav__user-photo p-0"}
                      altText="User"
                      defaultImage={NoUserPhoto}
                    />
                  </div>

                  {createTooltip(
                    <div
                      className="d-flex whiteColor p-2"
                      id="impersonating-icon"
                    >
                      <img
                        width="20"
                        height="20"
                        src={USER_RIGHT_ARROW}
                        alt=""
                      />
                    </div>,
                    impersonatingMsg,
                    { forceShow: true }
                  )}
                  <div
                    className={"border-impersonate-user vertical-align-center col-lg-5 pl-0"}
                    id="admin-user"
                  >
                    <UserDropdown
                      userData={
                        isImpersonateMode && parentUserData
                          ? parentUserData
                          : this.props.userData
                      }
                      impersonateExit={this.openExitImpersonateConfirmModal}
                      isImpersonateMode={isImpersonateMode}
                    />
                  </div>
                </div>
              ) : (
                <UserDropdown
                  userData={
                    isImpersonateMode && parentUserData
                      ? parentUserData
                      : this.props.userData
                  }
                  impersonateExit={this.openExitImpersonateConfirmModal}
                />
              )}
            </div>
          </div>
          <div className="mainmenu">
            <div className="mainmenu__container">
              <NavigationItems />
            </div>
          </div>
          {this.state.showImporsonateExitConfirmModal ? (
            <ConfirmModal
              key="impersonateExitConfirmModal"
              body={"Please confirm that you want to exit “Impersonate” mode."}
              showModal={this.state.showImporsonateExitConfirmModal}
              closeModal={this.closeModal}
              doConfirmFunctionality={this.impersonateExit}
            />
          ) : (
            ""
          )}
          {this.state.confirmationToSearchAllAreas ? (
            <ConfirmModal
              key="confirmationToSearchAllAreas"
              body={global.COMMON_MSG.ConfirmationMessageToSearchAllAreas}
              showModal={this.state.confirmationToSearchAllAreas}
              closeModal={this.closeModal}
              doConfirmFunctionality={() =>
                this.openGlobalSearchModal(null, {
                  key: "ALL",
                  value: "All",
                })
              }
            />
          ) : (
            ""
          )}
          {this.state.showGlobalSearchModal ? (
            <GlobalSearchPopup
              SearchKey={this.textInput.value}
              SelectedSearchArea={
                this.state.selectedSearchArea &&
                this.state.selectedSearchArea.key
              }
              show={this.state.showGlobalSearchModal}
              modalClose={this.closeModal}
              callBackToDisplayServerMessage={
                this.callBackToDisplayServerMessage
              }
              goToSummaryPage={this.goToSummaryPage}
              hideExpandedSearchArea={this.hideExpandedSearchArea}
              pageName={this.state.pageName}
            />
          ) : (
            ""
          )}
        </Fragment>
        {this.props.loading || this.state.loading ? <Spinner /> : ""}
      </header>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    loading: state.orgDetailReducer.loading,
    loggedInUserData: state.auth.loggedInUserData,
    orgDataResponse: state.orgDetailReducer.orgDataResponse,
    exitImpersonateResponse: state.impersonateReducer.exitImpersonateResponse,
    parentToken: state.impersonateReducer.parentToken,
    parentLoginResponse: state.impersonateReducer.parentLoginResponse,
    impersonateUserId:
      state.impersonateReducer.impersonateLoginResponse &&
        state.impersonateReducer.impersonateLoginResponse.LoginInfo
        ? state.impersonateReducer.impersonateLoginResponse.LoginInfo.UserId
        : localStorage.getItem("impersonateUserId"),
    selectedOrgKeyValue: state.auth.loggedInUserData ? state.auth.loggedInUserData.SelectedOrgKeyValue : {},
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onGetOrgDetails: (orgId) => dispatch(actions.getOrgDetails(orgId)),
    onGetOrgDetailsReset: () => dispatch(actions.getOrgDetailsReset()),
    onUpdateOrgdetailsWithLoginInfo: (loggedInUserData, orgResponse) =>
      dispatch(
        actions.updateOrgdetailsWithLoginInfo(loggedInUserData, orgResponse)
      ),
    onDoImpersonateExit: (parentToken, parentLoginResponse) =>
      dispatch(actions.doImpersonateExit(parentToken, parentLoginResponse)),
    onDoImpersonateExitReset: () => dispatch(actions.doImpersonateExitReset()),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(TopHeader));

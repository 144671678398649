import React, { useState, useEffect } from "react";
import { isNullOrEmpty } from "../../../shared/utility";
import { createTooltip } from "../../../shared/utility/tooltip-utility";

export const CommonLabel = (props) => {
  const [isRequired, setIsRequired] = useState(false);
  const [classesForRequiredField, setClassesForRequiredField] = useState("");
  useEffect(() => {
    let element = !isNullOrEmpty(props.lblId)
      ? document.getElementById(props.lblId)
      : null;
    setIsRequired(
      element ? element.closest("div").classList.contains("required") : false
    );
  }, [props.lblId]);

  useEffect(() => {
    setClassesForRequiredField(
      isRequired ? "col-form-label position-relative" : ""
    );
  }, [isRequired]);

  return createTooltip(
    <label
      className={
        classesForRequiredField +
        (props.lblClassName ? " " + props.lblClassName : "")
      }
      id={props.lblId}
      style={props.lblStyle}
      onClick={() =>
        props.onClick ? props.onClick(props.modalParameters) : ""
      }
    >
      {props.value ? props.value : props.defaultValue || "--"}
    </label>,
    props.toolTipMsg
      ? props.toolTipMsg
      : props.value
      ? props.value
      : props.defaultValue || "--",
    {
      forceShow: props.tooltipForceShow,
      parentTag: "label",
      parentClass: props.className,
      parentStyles: { position: "unset" },
    }
  );
};

export const CommonCheckBox = (data) => {
  return (
    <div className="icheck-primary">
      <input
        name={data.name}
        type={data.type}
        id={data.id}
        checked={
          isNullOrEmpty(data.checkedVal) || data.checkedVal === false ? false : true
        }
        onChange={() =>
          data.showModal(data.modalParams)
        }
      />
      <label htmlFor={data.id}>{data.cbTitle || ""}</label>
    </div>
  );
};

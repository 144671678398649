import { UnitSystemModel } from "../../../services/entities/models/domain/unit-system-model";
import { getUnitPlaceHolder, getUnitTextForLabel } from "../../../shared/utility/shuttle-app-utility";

export const getShuttleSettingsUICtrl = (viewData,unitSystems) => {
  const unitSystemModel = new UnitSystemModel(unitSystems);
  viewData = viewData ? viewData : {};
  let isImperialUnit = viewData.unitSystem === "IMPERIAL";
  const placeHolders = getUnitPlaceHolder(isImperialUnit);
  const unitTextForLabels = getUnitTextForLabel(isImperialUnit);
  return JSON.parse(
    JSON.stringify([
      {
        type: "SHUTTLE_LEFTBAR",
        fields: [
          {
            editConfig: {
              id: "statusUpdateIntervalInMillis",
              type: "number",
              name: "statusUpdateIntervalInMillis",
              options: [],
              required: true,
              placeholder: placeHolders.time,
            },
            titleLabel: {
              text: "Status Update Interval" + " (" + unitTextForLabels.time + ")",
            },
            detailLabel: {
              value: viewData.statusUpdateIntervalInMillis ? viewData.statusUpdateIntervalInMillis + " " + unitTextForLabels.time : "--",
            },
            value: viewData.statusUpdateIntervalInMillis || "",
            order: 1,
          },
          {
            editConfig: {
              id: "keepAliveIntervalInMillis",
              type: "number",
              name: "keepAliveIntervalInMillis",
              options: [],
              required: true,
              placeholder: placeHolders.time,
            },
            titleLabel: {
              text: "Keep Alive Interval" + " (" + unitTextForLabels.time + ")",
            },
            detailLabel: {
              value: viewData.keepAliveIntervalInMillis ? viewData.keepAliveIntervalInMillis + " " + unitTextForLabels.time : "--",
            },
            value: viewData.keepAliveIntervalInMillis || "",
            order: 2,
          },
          {
            editConfig: {
              id: "fetchIntervalInMillis",
              type: "number",
              name: "fetchIntervalInMillis",
              options: [],
              required: true,
              placeholder: placeHolders.time,
            },
            titleLabel: {
              text: "Fetch Interval" + " (" + unitTextForLabels.time + ")",
            },
            detailLabel: {
              value: viewData.fetchIntervalInMillis ? viewData.fetchIntervalInMillis + " " + unitTextForLabels.time : "--",
            },
            value: viewData.fetchIntervalInMillis || "",
            order: 3,
          },
          {
            editConfig: {
              id: "displayVicinityRadius",
              type: "number",
              name: "displayVicinityRadius",
              options: [],
              required: true,
              placeholder: placeHolders.distance,
            },
            titleLabel: {
              text: "Vicinity Radius" + " (" + unitTextForLabels.distance + ")",
            },
            detailLabel: {
              value: viewData.displayVicinityRadius ? viewData.displayVicinityRadius + " " + unitTextForLabels.distance : "--",
            },
            value: viewData.displayVicinityRadius || "",
            order: 4,
          },
          {
            editConfig: {
              id: "sensitivity",
              type: "number",
              name: "sensitivity",
              options: [],
              required: true,
            },
            titleLabel: {
              text: "Sensitivity",
            },
            detailLabel: {
              value: viewData.sensitivity || "--",
            },
            value: viewData.sensitivity || "",
            order: 5,
          },
          {
            editConfig: {
              id: "displaySpeedThreeshold",
              type: "number",
              name: "displaySpeedThreeshold",
              options: [],
              required: true,
              placeholder: placeHolders.speed,
            },
            titleLabel: {
              text: "Speed Threshold" + " (" + unitTextForLabels.speed + ")",
            },
            detailLabel: {
              value: viewData.displaySpeedThreeshold ? viewData.displaySpeedThreeshold + " " + unitTextForLabels.speed : "--",
            },
            value: viewData.displaySpeedThreeshold || "",
            order: 6,
          },
          {
            editConfig: {
              id: "displayDistanceThreeshold",
              type: "number",
              name: "displayDistanceThreeshold",
              options: [],
              required: true,
              placeholder: placeHolders.distance,
            },
            titleLabel: {
              text: "Distance Threshold" + " (" + unitTextForLabels.distance + ")",
            },
            detailLabel: {
              value: viewData.displayDistanceThreeshold ? viewData.displayDistanceThreeshold + " " + unitTextForLabels.distance : "--",
            },
            value: viewData.displayDistanceThreeshold || "",
            order: 7,
          },
        ],
      },
      {
        type: "SHUTTLE_RIGHTBAR",
        fields: [
          {
            editConfig: {
              id: "displayLowAvgSpeed",
              type: "number",
              name: "displayLowAvgSpeed",
              options: [],
              required: true,
              placeholder: placeHolders.speed,
            },
            titleLabel: {
              text: "Low Avg Speed" + " (" + unitTextForLabels.speed + ")",
            },
            detailLabel: {
              value: viewData.displayLowAvgSpeed ? viewData.displayLowAvgSpeed + " " + unitTextForLabels.speed : "--",
            },
            value: viewData.displayLowAvgSpeed || "",
            order: 0,
          },
          {
            editConfig: {
              id: "displayHighAvgSpeed",
              type: "number",
              name: "displayHighAvgSpeed",
              options: [],
              required: true,
              placeholder: placeHolders.speed,
            },
            titleLabel: {
              text: "High Avg Speed"+ " (" + unitTextForLabels.speed + ")",
            },
            detailLabel: {
              value: viewData.displayHighAvgSpeed ? viewData.displayHighAvgSpeed + " " + unitTextForLabels.speed : "--",
            },
            value: viewData.displayHighAvgSpeed || "",
            order: 1,
          },
          {
            editConfig: {
              id: "displayHighMaxSpeed",
              type: "number",
              name: "displayHighMaxSpeed",
              options: [],
              required: true,
              placeholder: placeHolders.speed,
            },
            titleLabel: {
              text: "High Max Speed"+ " (" + unitTextForLabels.speed + ")",
            },
            detailLabel: {
              value: viewData.displayHighMaxSpeed ? viewData.displayHighMaxSpeed + " " + unitTextForLabels.speed : "--",
            },
            value: viewData.displayHighMaxSpeed || "",
            order: 2,
          },
          {
            editConfig: {
              id: "displayLowTravelledDistance",
              type: "number",
              name: "displayLowTravelledDistance",
              options: [],
              required: true,
              placeholder: placeHolders.distance,
            },
            titleLabel: {
              text: "Low Travelled Distance"+ " (" + unitTextForLabels.distance + ")",
            },
            detailLabel: {
              value: viewData.displayLowTravelledDistance ? viewData.displayLowTravelledDistance + " " + unitTextForLabels.distance : "--",
            },
            value: viewData.displayLowTravelledDistance || "",
            order: 3,
          },
          {
            editConfig: {
              id: "displayHighTravelledDistance",
              type: "number",
              name: "displayHighTravelledDistance",
              options: [],
              required: true,
              placeholder: placeHolders.distance,
            },
            titleLabel: {
              text: "High Travelled Distance"+ " (" + unitTextForLabels.distance + ")",
            },
            detailLabel: {
              value: viewData.displayHighTravelledDistance ? viewData.displayHighTravelledDistance + " " + unitTextForLabels.distance : "--",
            },
            value: viewData.displayHighTravelledDistance || "",
            order: 4,
          },
          {
            editConfig: {
              id: "highTripDurationInMillis",
              type: "number",
              name: "highTripDurationInMillis",
              options: [],
              required: true,
              placeholder: placeHolders.time,
            },
            titleLabel: {
              text: "High Trip Duration"+ " (" + unitTextForLabels.time + ")",
            },
            detailLabel: {
              value: viewData.highTripDurationInMillis ? viewData.highTripDurationInMillis + " " + unitTextForLabels.time : "--",
            },
            value: viewData.highTripDurationInMillis || "",
            order: 5,
          },
          {
            editConfig: {
              id: "unitSystem",
              type: "radiolist",
              name: "unitSystem",
              required: true,
              options: [
                {
                  id: "METRIC",
                  key: "METRIC",
                  value: "Metric",
                },
                {
                  id: "IMPERIAL",
                  key: "IMPERIAL",
                  value: "Imperial",
                },
              ],
            },
            titleLabel: {
              text: "System",
            },
            detailLabel: {
              value: viewData.unitSystem === "IMPERIAL" ? "Imperial" : "Metric",
            },
            value: viewData.unitSystem || "IMPERIAL",
            order: 6,
          },
        ],
      },
    ])
  );
};

import React, { Fragment } from "react";
import ReactHtmlParser from "react-html-parser";
import TooltipItem from "../../components/tooltip/TooltipItem";
import {
  getNoteTooltipHtml,
  toTitleCase,
  formatUpsellOptions,
} from "../../shared/utility";
import * as global from "../../shared/GlobalVar";
import {
  MANUAL,
  GreenPlus,
  Note,
  Voucher,
  NotAcceptedContract,
  AcceptedContract,
  Lock,
  Unlocked,
  LoginEnabled,
  LoginDisabled,
  MemVerifProcessComplete,
  MemVerifProcessIncomplete,
  DIESEL,
  AUTOMATIC,
  jsimarketplaceIco,
  GAS,
  HYBRID,
  GASOLINE,
  BIO_DIESEL,
  ELECTRIC,
  RideLink,
} from "../../shared/AppImages";
import { getMemberLabelByOrgRentalType } from "../../shared/utility/system-utility";
import { createTooltip } from "../../shared/utility/tooltip-utility";

const IconListForReservation = (props) => {
  const loadImgIconWithTooltip = (imgData, imgId, className, tooltipData) => {
    return createTooltip(
      <img src={imgData} alt="u" id={imgId} className={className} />,
      ReactHtmlParser(tooltipData),
      { forceShow: true, isIcon: true }
    );
  };

  const getIconCount = () => {
    const resData = { ...props.resData };
    let iconCount = 0;
    iconCount = resData.UpsellSelected ? iconCount + 1 : iconCount;
    iconCount =
      resData.ContractAccepted || !resData.ContractAccepted
        ? iconCount + 1
        : iconCount;
    iconCount = resData.CCNotesExists ? iconCount + 1 : iconCount;
    iconCount = resData.VoucherAdded ? iconCount + 1 : iconCount;
    return iconCount;
  };

  const loadReservationSearchIcon = () => {
    const resData = { ...props.resData };
    const iconCount = getIconCount();
    return (
      <Fragment key={resData.ReservationId}>
        {createTooltip(
          <div
            id={resData.ReservationState + "_" + resData.ReservationId}
            className={`ml-1 colorBox ${resData.ReservationState} ${
              resData.IsLateReservation || false
            }`}
            style={{
              height: "1.4rem",
              width: "1.4rem",
            }}
          ></div>,
          toTitleCase(resData.ReservationState) +
            (resData.IsLateReservation
              ? resData.ReservationState === "IN_PROGRESS"
                ? " and Late"
                : resData.ReservationState === "COMPLETED"
                ? " and Returned Late"
                : ""
              : ""),
          { forceShow: true, isIcon: true }
        )}
        {loadResImageIcon()}
        {resData.ReservationCreationType === "TEST" ? (
          <Fragment key={resData.ReservationId}>
            {createTooltip(
              <div
                id={
                  resData.ReservationCreationType + "_" + resData.ReservationId
                }
                className="resGenreIconForRow ml-2"
              >
                Test
              </div>,
              resData.AttendanceStatus === global.AttendanceStatus.NOW_SHOW
                ? "Test Reservation"
                : "Test Rental",
              {
                forceShow: true,
                parentClass:
                  "w-200" +
                  (iconCount % 4 >= 2
                    ? " grid-column-start-1 mlr-0-25 mt-1"
                    : ""),
                isIcon: true,
              }
            )}
          </Fragment>
        ) : (
          ""
        )}
      </Fragment>
    );
  };

  const loadResImageIcon = () => {
    const resData = { ...props.resData };
    const imgHtml = [];
    if (resData.UpsellSelected) {
      imgHtml.push(
        loadImgIconWithTooltip(
          GreenPlus,
          "addonsId_" + resData.ReservationId,
          "ml-2",
          formatUpsellOptions(resData.UpsellOptions)
        )
      );
    }
    if (resData.ContractAccepted) {
      imgHtml.push(
        loadImgIconWithTooltip(
          AcceptedContract,
          "acceptedContract_" + resData.ReservationId,
          "ml-2",
          "Contract accepted"
        )
      );
    } else {
      imgHtml.push(
        loadImgIconWithTooltip(
          NotAcceptedContract,
          "notAcceptedContract_" + resData.ReservationId,
          "ml-2",
          "Contract not accepted"
        )
      );
    }
    if (resData.CCNotesExists) {
      imgHtml.push(
        loadImgIconWithTooltip(
          Note,
          "noteId_" + resData.ReservationId,
          "ml-2",
          "Internal Memo"
        )
      );
    }
    // if (resData.VoucherAdded) {
    //   imgHtml.push(
    //     loadImgIconWithTooltip(
    //       Voucher,
    //       "voucherId_" + resData.ReservationId,
    //       "ml-2",
    //       "Voucher Added"
    //     )
    //   );
    // }
    return imgHtml;
  };

  const loadVehicleSearchIcon = () => {
    const resData = { ...props.resData };
    const imgHtml = [];
    if (resData.LockStatus === "LOCKED") {
      imgHtml.push(
        loadImgIconWithTooltip(
          Lock,
          "lockId_" + resData.VehicleId,
          "ml-2",
          "Lock"
        )
      );
    } else if (resData.LockStatus === "UNLOCKED") {
      imgHtml.push(
        loadImgIconWithTooltip(
          Unlocked,
          "unlockId_" + resData.VehicleId,
          "ml-2",
          "Unlock"
        )
      );
    }
    if (resData.FuelType) {
      const imgComponent =
        resData.FuelType === "GAS"
          ? GAS
          : resData.FuelType === "DIESEL"
          ? DIESEL
          : resData.FuelType === "GASOLINE"
          ? GASOLINE
          : resData.FuelType === "ELECTRIC"
          ? ELECTRIC
          : resData.FuelType === "HYBRID"
          ? HYBRID
          : resData.FuelType === "BIO_DIESEL"
          ? BIO_DIESEL
          : "";
      imgHtml.push(
        loadImgIconWithTooltip(
          imgComponent,
          resData.FuelType + "_" + resData.VehicleId,
          "ml-2",
          resData.FuelType
        )
      );
    }
    if (resData.TransmissionType) {
      const imgComponent =
        resData.TransmissionType === "AUTOMATIC"
          ? AUTOMATIC
          : resData.TransmissionType === "MANUAL"
          ? MANUAL
          : "";
      imgHtml.push(
        loadImgIconWithTooltip(
          imgComponent,
          resData.TransmissionType + "_" + resData.VehicleId,
          "ml-2",
          resData.TransmissionType
        )
      );
    }
    if (resData.RideLinkInstalled) {
      imgHtml.push(
        loadImgIconWithTooltip(
          RideLink,
          "rideLinkInstalledId_" + resData.VehicleId,
          "ml-2",
          "Ridelink"
        )
      );
    }
    if (resData.DisplayInJsiMarketPlace) {
      imgHtml.push(
        loadImgIconWithTooltip(
          jsimarketplaceIco,
          "displayInJsiMarketPlace_" + resData.VehicleId,
          "ml-2",
          "Listed in JustShareIt.com"
        )
      );
    }
    return imgHtml;
  };

  const loadMemberSearchIcon = () => {
    const resData = { ...props.resData };
    const imgHtml = [];
    if (resData.Status === "INACTIVE") {
      imgHtml.push(
        loadImgIconWithTooltip(
          Lock,
          "inactiveId_" + resData.PersonId,
          "ml-2",
          "Inactive"
        )
      );
    } else if (resData.Status === "SUSPENDED") {
      imgHtml.push(
        loadImgIconWithTooltip(
          Lock,
          "suspendedId_" + resData.PersonId,
          "ml-2",
          "Suspended/Blocked"
        )
      );
    } else if (resData.Status === "ACTIVE") {
      imgHtml.push(
        loadImgIconWithTooltip(
          Unlocked,
          "activeId_" + resData.PersonId,
          "ml-2",
          "Active"
        )
      );
    }

    if (resData.LoginEnabled === true) {
      imgHtml.push(
        loadImgIconWithTooltip(
          LoginEnabled,
          "loginEnabledId_" + resData.PersonId,
          "ml-2",
          "Login Enabled"
        )
      );
    } else {
      imgHtml.push(
        loadImgIconWithTooltip(
          LoginDisabled,
          "loginDisabledId_" + resData.PersonId,
          "ml-2",
          "Login Disabled"
        )
      );
    }
    if (resData.ProfileValidated) {
      imgHtml.push(
        loadImgIconWithTooltip(
          MemVerifProcessComplete,
          "verifyProcessCompleteID_" + resData.PersonId,
          "ml-2",
          getMemberLabelByOrgRentalType("Verification Completed")
        )
      );
    } else {
      imgHtml.push(
        loadImgIconWithTooltip(
          MemVerifProcessIncomplete,
          "verifyProcessIncompleteId_" + resData.PersonId,
          "ml-2",
          getMemberLabelByOrgRentalType("Verification Incomplete")
        )
      );
    }
    return imgHtml;
  };

  const pageName = props.pageName;
  return (
    <Fragment>
      {pageName === "reservation"
        ? loadReservationSearchIcon()
        : pageName === "vehicle"
        ? loadVehicleSearchIcon()
        : pageName === "member"
        ? loadMemberSearchIcon()
        : ""}
    </Fragment>
  );
};
export default IconListForReservation;

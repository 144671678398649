import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Draggable from "react-draggable";
import { FleetUtilizationForecast } from "../../../services/entities/models/reserve-sync/fleet-utilization-forecast";
import { updateObject, getErrorToastData } from "../../../shared/utility";
import { showNotification } from "../../../components/UI/Toaster/Toaster";
import { validateFleetForeCastEntity } from "../fleet-utilization-forecast-helper";

export const ManualFleetUtilizationForecastModal = (props) => {
  const [fleetForecastEntity, setfleetForecastEntity] = useState(props.selectedEntity || new FleetUtilizationForecast());
  const handleOnChange = (event, controlName, elementType) => {
    let value = null;
    value =
      elementType === "checkbox" ? event.target.checked : event.target.value;
    const updatedControls = updateObject(fleetForecastEntity, {
      [controlName]: value,
    });
    setfleetForecastEntity(updatedControls);
  };
  const updateAddonsItems = () => {
    let msg = validateFleetForeCastEntity(fleetForecastEntity);
    if (msg) {
      showNotification(getErrorToastData(msg));
    } else {
      props.updateFleetutilizationForecastEntity(fleetForecastEntity);
    }
  };
  return (
    <Draggable handle=".cursor">
      <Modal
        isOpen={props.showModal}
        className="no-cursor"
        toggle={() => {
          props.closeModal();
        }}
      >
        <ModalHeader
          className="modal-header bg-primary modal-title text-white cursor"
          toggle={() => {
            props.closeModal();
          }}
        >
          <i className="fas fa-cogs pr-2"></i>
          Update Manual Fleet Utilization Forecast
        </ModalHeader>
        <ModalBody>
          <div className="col-sm-12 p-0">
            <div class="form-group row  required">
              <label for="" class="col-sm-6 col-form-label font-weight-bold">Manual Pickup Threshold Percent</label>
              <div class="col-sm-6">
                <input name="manualPickupThresholdPercent"
                  type="number"
                  value={fleetForecastEntity.manualPickupThresholdPercent}
                  onChange={(event) => { handleOnChange(event, "manualPickupThresholdPercent", "number") }}
                />
              </div>
            </div>
            <div class="form-group row  required">
              <label for="" class="col-sm-6 col-form-label font-weight-bold">Manual Book Through Threshold Percent</label>
              <div class="col-sm-6">
                <input name="manualBookThruThresholdPercent"
                  type="number"
                  value={fleetForecastEntity.manualBookThruThresholdPercent}
                  onChange={(event) => { handleOnChange(event, "manualBookThruThresholdPercent", "number") }}
                />
              </div>
            </div>
            <div class="form-group row  required">
              <label for="" class="col-sm-6 col-form-label font-weight-bold">Manual Walkins Count</label>
              <div class="col-sm-6">
                <input name="manualWalkinsCount"
                  type="number"
                  value={fleetForecastEntity.manualWalkinsCount}
                  onChange={(event) => { handleOnChange(event, "manualWalkinsCount", "number") }}
                />
              </div>
            </div>
            <div class="form-group row  required">
              <label for="" class="col-sm-6 col-form-label font-weight-bold">Manual No Show Factor</label>
              <div class="col-sm-6">
                <input name="manualNoShowFactor"
                  type="number"
                  value={fleetForecastEntity.manualNoShowFactor}
                  onChange={(event) => { handleOnChange(event, "manualNoShowFactor", "number") }}
                />
              </div>
            </div>

            <div class="form-group row  required">
              <label for="" class="col-sm-6 col-form-label font-weight-bold">Manual Override Reason</label>
              <div class="col-sm-6">
                <input name="manualOverrideReason"
                  type="text"
                  value={fleetForecastEntity.manualOverrideReason}
                  onChange={(event) => { handleOnChange(event, "manualOverrideReason", "text") }}
                />
              </div>
            </div>
            <div class="form-group row  required">
              <label for="" class="col-sm-6 col-form-label font-weight-bold">Pause</label>
              <div class="col-sm-6">
                <select name="pause" type="text" class="pl-0"
                  value={fleetForecastEntity.pause || ""}
                  onChange={(event) => { handleOnChange(event, "pause", "select") }}
                >
                  <option value="">Select</option>
                  <option value="YES">Yes</option>
                  <option value="NO">no</option>
                </select>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="col-sm-12 text-right">
            <button
              type="button"
              name="btnYes"
              className="btn btn-primary waves-effect waves-light btnSize ml-2"
              onClick={() => updateAddonsItems()}
            >
              Update
            </button>
            <button
              type="button"
              name="btnCancel"
              className="btn btn-secondary waves-effect waves-light btnSize ml-2"
              onClick={() => props.closeModal()}
            >
              Cancel
            </button>
          </div>
        </ModalFooter>
      </Modal>
    </Draggable>
  );
};
export default ManualFleetUtilizationForecastModal;

import axios from "axios";
import * as actionTypes from "./actionTypes";
import HttpService from "../../services/httpService";

export const getAddOnsStart = () => {
  return {
    type: actionTypes.GET_ADDONS_START,
  };
};

export const getAddOnsSuccess = (response) => {
  return {
    type: actionTypes.GET_ADDONS_SUCCESS,
    addonsData: response,
  };
};

export const getAddOnsFail = (error) => {
  return {
    type: actionTypes.GET_ADDONS_FAIL,
    error: error,
  };
};

export const getAdditionalOptions = (selectedOrgUnitId) => {
  return (dispatch) => {
    dispatch(getAddOnsStart());
    const getAddOnsReq = {};
    getAddOnsReq.SelectedChildOrgUnitId = selectedOrgUnitId;
    HttpService.getAllAdditionalOptions(getAddOnsReq)
      .then((response) => {
        dispatch(getAddOnsSuccess(response));
      })
      .catch((err) => {
        //dispatch(getAddOnsFail(err));
      });
  };
};

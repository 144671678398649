import PageInfo from "../models/PageInfo";

const RolesPermissionsSearchRequest = function() {
  this.LocationId = null;
  this.FirstName = null;
  this.MiddleName = null;
  this.LastName = null;
  this.FullName = null;
  this.EmailAddress = null;
  this.IncludeInactive = null;
  this.EmployeeNo = null;
  this.EmployeeLocationId = null;
  this.IsEmployee = null;
  this.MembershipId = null;
  this.RoleId = null;
  this.Phone = null;
  this.Title = null;
  this.Department = null;
  this.CCLoginAllowed = null;
  this.PageInfo = new PageInfo();
};
export default RolesPermissionsSearchRequest;

import Phone from "../Phone";
import {
  getNormalFormattedPhoneNumber,
  convertArrayDataToTitleString,
} from "../../../../shared/utility";
const RolesPermissionsSearchModel = function (data) {
  if (!data) data = {};
  this.MembershipId = data.MembershipId || null;
  this.PersonId = data.PersonId || null;

  this.FullName = data.FullName || null;

  this.EmailAddress = data.EmailAddress || null;

  this.MobilePhone = new Phone(data.MobilePhone);
  this.FormattedMobilePhone = getNormalFormattedPhoneNumber(this.MobilePhone);
  this.HomePhone = new Phone(data.HomePhone);
  this.FormattedHomePhone = getNormalFormattedPhoneNumber(this.HomePhone);
  this.WorkPhone = new Phone(data.WorkPhone);
  this.FormattedWorkPhone = getNormalFormattedPhoneNumber(this.WorkPhone);

  this.EmployeeNo = data.EmployeeNo || null;
  this.EmployeeLocationId = data.EmployeeLocationId || null;
  this.EmployeeLocationName = data.EmployeeLocationName || null;
  this.ProfileLocationName = data.ProfileLocationName || null;
  this.BadgeNo = data.BadgeNo || null;
  this.CompanyName = data.CompanyName || null;
  this.Department = data.Department || null;
  this.Title = data.Title || null;
  this.CCLoginAllowed = data.CCLoginAllowed || null;
  this.CanChangeMemberPermission = data.Title || null;
  if (data.RoleNames) {
    this.Roles = convertArrayDataToTitleString(data.RoleNames);
  }
  this.Status = data.Status || false;
  this.BlackListed = data.BlackListed || false;
};
export default RolesPermissionsSearchModel;

const OrgUnitFleetClassGroup = function (data) {
  if (!data) data = {};
  this.orgUnitId = data.orgUnitId || null;
  this.displayText = data.displayText || null;
  this.orgUnitFleetClassGroupId = data.orgUnitFleetClassGroupId || null;
  this.defaultPickupThresholdPercent =
    data.defaultPickupThresholdPercent || null;
  this.defaultBookThruThresholdPercent =
    data.defaultBookThruThresholdPercent || null;
  this.classGroupCode = data.classGroupCode || null;
  this.status = data.status || null;
};
export default OrgUnitFleetClassGroup;

import React from "react";
import { showNotification } from "../../../../components/UI/Toaster/Toaster";
import {
    TOAST_MSG
} from "../../../../shared/GlobalVar";
import CommonTitle from "../../component/common-title";
import { validateParimaryResData } from "../fox-res-helper";
import DiscountContainer from "./discount-container";
import LocationContainer from "./location-container";
import RentalTimeContainer from "./rental-time-container";

function PrimaryForm(props) {
    const {
        primaryFormData,
        setPrimaryFormData,
        discountList,
        getAllRates,
        showToasterMsg,
        storeCredit,
        disabled,
        onChangesMade,
        showDiscountLoader,
    } = props;

    const showErrorMsg = (msg) => {
        var toastMsg = {
            msg: msg,
            toastType: TOAST_MSG.ERROR,
        };
        showNotification(toastMsg);
    };

    const getBothRates = () => {
        const errMsg = validateParimaryResData(primaryFormData);
        if (!errMsg) {
            const primaryData = {
                rentalLocationCode: primaryFormData.rentalLocationCode,
                returnLocationCode: primaryFormData.returnLocationCode,
                rentalDateTime: primaryFormData.rentalDateTime,
                returnDateTime: primaryFormData.returnDateTime,
                discount: primaryFormData?.discount || [],
                foxDiscountCode: primaryFormData?.foxDiscountCode,
                freeCreditAmountApplied: primaryFormData?.freeCreditAmountApplied,
            }
            getAllRates(primaryData);
        } else {
            showToasterMsg(errMsg);
        }
        onChangesMade(true);
    };
    return (
        <div className='common-res-container'>
            <CommonTitle title="Create New Reservation" classNames="font-size-2" />
            <LocationContainer
                primaryFormData={primaryFormData}
                setPrimaryFormData={setPrimaryFormData}
            />
            <RentalTimeContainer
                primaryFormData={primaryFormData}
                setPrimaryFormData={setPrimaryFormData}
                showErrorMsg={showErrorMsg}
            />
            <DiscountContainer
                primaryFormData={primaryFormData}
                setPrimaryFormData={setPrimaryFormData}
                discountList={discountList}
                disabled={disabled}
                storeCredit={storeCredit || 0}
                showDiscountLoader={showDiscountLoader}
            />
            <button
                type="button"
                className={"btn btn-yellow-default mt-4 fullwidth-btn"}
                onClick={() => getBothRates()}
                disabled={validateParimaryResData(primaryFormData)}
                style={{ textAlign: "left" }}
            >
                Get Rates
            </button>
        </div>
    );
}

export default PrimaryForm
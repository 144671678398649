import React, { Fragment, useEffect, useState } from "react";
import $ from "jquery";

const PartnerListSection = (props) => {
  const [selectedRow, setSelectedRow] = useState("");
  const [partnerList, setPartnerList] = useState(
    props.partnerList ? props.partnerList : []
  );
  const [scrollTopVal, setScrollTopVal] = useState(0);
  const [scrollTopMaxVal, setScrollTopMax] = useState(0);
  const onSelectPartnerCode = (index, selectedPartner) => {
    setSelectedRow(index);
    localStorage.setItem(
      "ParterInfo_SelectedCode",
      selectedPartner.PartnerCode
    );
    props.setSelectedPartnerData(selectedPartner);
  };

  useEffect(() => {
    getElementInfo("partner-list-wrapper");
  });

  useEffect(() => {
    setPartnerList(props.partnerList ? props.partnerList : []);
    if (localStorage.getItem("ParterInfo_SelectedCode")) {
      var index = props.partnerList.findIndex(
        (p) => p.PartnerCode === localStorage.getItem("ParterInfo_SelectedCode")
      );
      setSelectedRow(index);
      props.setSelectedPartnerData(
        props.partnerList && props.partnerList[index]
      );
    }
  }, [props.partnerList]);

  const getElementInfo = (element) => {
    let el = document.getElementById(element);
    setScrollTopMax(el.scrollTopMax);
    return el;
  };

  const onClickScroll = (isUpScroll) => {
    let el = getElementInfo("partner-list-wrapper");
    let scrolltop = isUpScroll ? el.scrollTop + 100 : el.scrollTop - 100;
    $("#partner-list-wrapper").animate(
      {
        scrollTop: scrolltop,
      },
      100
    );

    isUpScroll
      ? setScrollTopVal(
          scrolltop >= el.scrollTopMax ? el.scrollTopMax : scrolltop
        )
      : setScrollTopVal(scrolltop <= 0 ? 0 : scrolltop);
  };

  const onScrollEvent = () => {
    let el = document.getElementById("partner-list-wrapper");
    setScrollTopVal(el.scrollTop);
  };
  return (
    <Fragment>
      <div
        className={`scroll-up-arrow pseudo-link ${
          scrollTopVal === 0 ? "disabledLinkProp" : ""
        }`}
        onClick={() => onClickScroll(false)}
      >
        <i className="fas fa-caret-up fs-8" style={{ paddingRight: "7px" }}></i>
      </div>

      <section
        className="grid-table tableScroll"
        id="partner-list-wrapper"
        onScroll={onScrollEvent}
      >
        <div className="datagrid position-relative" id="partner-list-outer">
          {partnerList.length == 0 ? (
            <span className="pl-3">
              No results matched your query <b>{props.searchValue}</b>
            </span>
          ) : null}
          {partnerList &&
            partnerList.map((post, index) => {
              return (
                <div
                  className={`row pseudo-link ${
                    selectedRow === index ? "selected-row-color" : ""
                  }`}
                  key={post.PartnerCode}
                  onClick={() => onSelectPartnerCode(index, post)}
                >
                  <span
                    className="row__column"
                    title={post.PartnerCode + " (" + post.DisplayName + ")"}
                  >
                    {post.PartnerCode + " (" + post.DisplayName + ")"}
                  </span>
                </div>
              );
            })}
        </div>
      </section>

      <div
        className={`scroll-down-arrow pseudo-link ${
          scrollTopVal === scrollTopMaxVal ? "disabledLinkProp" : ""
        }`}
        onClick={() => onClickScroll(true)}
      >
        <i
          className="fas fa-caret-down fs-8"
          style={{ paddingRight: "7px" }}
        ></i>
      </div>
    </Fragment>
  );
};

export default PartnerListSection;

export const EngineStatus = function (data) {
    if (!data) data = {};
    this.engineStatus = data.engineStatus ||null;
    this.latitude = data.latitude ||null;
    this.longitude = data.longitude ||null;
    this.speedInMph = data.speedInMph ||null;
    this.course = data.course ||null;
    this.lastTimeStampInMillis = data.lastTimeStampInMillis ||null;
    this.traveledDistanceInMiles = data.traveledDistanceInMiles ||null;
    this.clientTimeStampInMillis = data.clientTimeStampInMillis ||null;
};
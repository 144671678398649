import moment from "moment-timezone";
import { ShuttleOptionRequest } from "../../services/entities/shuttle-app/request/shuttle-option-request";

export const getCompatibleDdlOrg = (data, defaultValues = {}) => {
  const orgddlInput = [];
  data.map((obj, i) => {
    if (i === 0) {
      orgddlInput.push({
        key: defaultValues.key || "",
        keyValue: defaultValues.keyValue || "",
        displayValue: defaultValues.displayValue || "Select",
        otherId: defaultValues.otherId || "",
        locationId: defaultValues.locationId || "",
      });
    }
    if (obj.locationCode) {
      orgddlInput.push({
        key: obj.locationCode,
        keyValue: obj.locationCode + i,
        displayValue: obj.displayName,
        otherId: obj.timezoneString,
        locationId: obj.locationId,
      });
    }
    return orgddlInput;
  });
  return orgddlInput;
};

export const getShuttleOptionRequest = (shuttleOptions) => {
  return new ShuttleOptionRequest(shuttleOptions);
};

export const getFormatteddateFromMilliSecond = (
  time,
  dateTimeFomrat,
  timezoneStr
) => {
  let formattedDate = "";
  if (time) {
    var date = new Date(time);
    formattedDate =
      moment(date).format(dateTimeFomrat) +
      (timezoneStr ? " " + moment.tz(timezoneStr).format("z") : "");
  }
  return formattedDate;
};
export const getHourMinSecFromMilliSecond = (
  durationInMillis,
  isDurationForEngineStatus
) => {
  if (!durationInMillis) return "--";

  // Helper function to pad with zero
  const zeroFill = (num, size) => String(num).padStart(size, "0");

  // Calculate hours, minutes, and seconds
  const hours = Math.floor(durationInMillis / (1000 * 60 * 60));
  const minutes = Math.floor(
    (durationInMillis % (1000 * 60 * 60)) / (1000 * 60)
  );
  const seconds = Math.floor((durationInMillis % (1000 * 60)) / 1000);

  // If the flag for engine status is set, return the formatted time in HH:mm:ss format
  if (isDurationForEngineStatus) {
    return `${zeroFill(hours, 2)}:${zeroFill(minutes, 2)}:${zeroFill(
      seconds,
      2
    )}`;
  }

  // Construct formatted duration for general use with proper pluralization
  const hDisplay =
    hours >= 0 ? `${zeroFill(hours, 2)} hr${hours > 1 ? "s" : ""} ` : "";
  const mDisplay =
    minutes >= 0 ? `${zeroFill(minutes, 2)} min${minutes > 1 ? "s" : ""} ` : "";
  const sDisplay =
    seconds >= 0 ? `${zeroFill(seconds, 2)} sec${seconds > 1 ? "s" : ""} ` : "";

  // Return the combined duration string
  return `${hDisplay}${mDisplay}${sDisplay}`.trim();
};

export const getDateTimePartFromDate = (dateVal, isDate) => {
  let amOrPm = "",
    formattedString = "--:--";
  if (dateVal) {
    let splitted = dateVal.split(" ");
    if (isDate) {
      formattedString = moment(splitted[0]).format("MMM DD,YYYY");
    } else {
      formattedString = splitted[1];
      amOrPm = splitted[2];
    }
  }
  return formattedString + amOrPm;
};

export const getUnitPlaceHolder = (isImperialUnit) => {
  const placeHolder = {};
  placeHolder.time = "in milliseconds";
  placeHolder.distance = isImperialUnit ? "in miles" : "in kilometers";
  placeHolder.speed = isImperialUnit
    ? "in miles per hour"
    : "in kilometers per hour";
  return placeHolder;
};

export const getUnitTextForLabel = (isImperialUnit) => {
  const unitTextForLabel = {};
  unitTextForLabel.time = "ms";
  unitTextForLabel.distance = isImperialUnit ? "mi" : "km";
  unitTextForLabel.speed = isImperialUnit ? "mph" : "kph";
  return unitTextForLabel;
};

  export const ShuttleLocationSettingsViewModel = function (data,locationCode,selectedOrgLocationId,currentOrgUnitId) {
    if (!data) data = {};
    this.locationCode=locationCode || null;
    this.inheritedFromParent=data.inheritFromParent || false;
    this.displayInherited= currentOrgUnitId !== selectedOrgLocationId;;
    this.statusUpdateIntervalInMillis = data.statusUpdateIntervalInMillis || null;
    this.keepAliveIntervalInMillis = data.keepAliveIntervalInMillis || null;
    this.fetchIntervalInMillis = data.fetchIntervalInMillis || null;
    this.displayVicinityRadius = data.displayVicinityRadius || null;
    this.sensitivity = data.sensitivity || null;
    this.displaySpeedThreeshold = data.displaySpeedThreeshold || null;
    this.displayDistanceThreeshold = data.displayDistanceThreeshold || null;
    this.displayLowAvgSpeed = data.displayLowAvgSpeed || null;
    this.displayHighAvgSpeed = data.displayHighAvgSpeed || null;
    this.displayHighMaxSpeed = data.displayHighMaxSpeed || null;
    this.displayLowTravelledDistance = data.displayLowTravelledDistance || null;
    this.displayHighTravelledDistance = data.displayHighTravelledDistance || null;
    this.highTripDurationInMillis = data.highTripDurationInMillis || null;
    this.locationDistanceUnit = data.locationDistanceUnit || null;
    this.locationSpeedUnit = data.locationSpeedUnit || null;
    this.unitSystem = data.locationDistanceUnit && data.locationDistanceUnit === "KM" ? "METRIC" : "IMPERIAL";
  };
  
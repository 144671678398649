const ModalInfo = function () {
  this.type = null;
  this.singleModalIdentifierId = null;
  this.customData = null;
  this.showModal = null;
  this.invoiceId = null;
  this.memberId = null;
  this.vehicleId = null;
  this.reservationId = null;
  this.locationId = null;
  this.activityId = null;
  this.cancellationData = null;
  this.notesOrMailType = null;
  this.agentResponse = null;
  this.agentType = null;
};
export default ModalInfo;

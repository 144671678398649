import PageInfo from "./PageInfo";
const ReservationSearchInfo = function() {
  this.ClassCode = null;
  this.CreditType = null;
  this.EmailAddress = null;
  this.ReservationState = null;
  this.EndDate = null;
  this.FirstName = null;
  this.LastName = null;
  this.MembershipId = null;
  this.PhoneNo = null;
  this.ReservationCodes = null;
  this.SearchByCancellationDate = null;
  this.SearchByCreationDate = null;
  this.SearchByScheduledDate = null;
  this.UnitNumber = null;
  this.SelectedChildOrgUnitId = null;
  this.ShowPendingContract = null;
  this.ShowReservationsAsBorrower = null;
  this.ShowReservationsAsSharer = null;
  this.StartDate = null;
  this.FullName = null;
  this.UpsellOptionInfoRequired = null;
  this.OptInXpress = null;
  this.AddOns = null;
  this.ReservationCreationType = null;
  this.Reason = null;
  this.IntegStatuses = null;
  this.PageInfo = new PageInfo();
};
export default ReservationSearchInfo;

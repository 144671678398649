import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { isNullOrEmpty } from "../../../shared/utility";
import { getFormattedPhoneNumber, isPhoneNumber } from "../../../shared/utility/phone-utility";

export const PhoneNumberUI = (props) => {
  const [countryIso, setCountryIso] = useState("us");
  const [countryCode, setCountryCode] = useState("1");
  const [formatedPhoneNumber, setFormatedPhoneNumber] = useState("");
  useEffect(() => {
    if (props.value && props.isEditMode) {
      if (props.isFOX) {
        setFormatedPhoneNumber(getFormattedPhoneNumber(props.value.number, props.value.countryIso || "US"));
        setCountryIso(String(props.value.countryIso || "us"));
        setCountryCode(String(props.value.countryCode || "1"));
      } else {
        setFormatedPhoneNumber(getFormattedPhoneNumber(props.value.Number, props.value.CountryIso || "US"));
        setCountryIso(String(props.value.CountryIso || "us"));
        setCountryCode(String(props.value.CountryCode || "1"));
      }
    }
  }, [
    props.value.CountryCode,
    props.value.countryCode,
    props.value.number,
    props.value.Number,
  ]);

  // to show formatted value while typing; need to work more
  // useEffect(()=>{
  //   const asYouType = new AsYouType(countryIso && countryIso.toUpperCase())
  //   setFormatedPhoneNumber(asYouType.input(formatedPhoneNumber));
  // },[formatedPhoneNumber])

  return (
    <PhoneInput
      inputProps={{
        id: props.id,
        name: props.controlName,
        required: props.required,
        value: formatedPhoneNumber
      }}
      autoFormat={true}
      enableSearch={
        !isNullOrEmpty(props.enableSearch) ? props.enableSearch : true
      }
      disableSearchIcon={
        !isNullOrEmpty(props.disableSearchIcon) ? props.disableSearchIcon : true
      }
      //placeholder={props.placeholderText}
      country={countryIso && countryIso.toLowerCase()}
      containerClass={
        props.containerClass ||
        "" +
        (!isNullOrEmpty(props.showCountryFlagWithCode)
          ? "code-with-flag"
          : "")
      }
      inputClass={props.inputClass || "inputCodeClass"}
      dropdownClass={props.controlName}
      searchClass={props.searchClass || "searchClass"}
      onChange={props.handleOnChange}
      enableLongNumbers={
        !isNullOrEmpty(props.enableLongNumbers)
          ? props.enableLongNumbers
          : false
      }
      disabled={!isNullOrEmpty(props.disabled) ? props.disabled : false}
      countryCodeEditable={
        !isNullOrEmpty(props.countryCodeEditable)
          ? props.countryCodeEditable
          : true
      }
      disableCountryCode={
        !isNullOrEmpty(props.disableCountryCode)
          ? props.disableCountryCode
          : true
      }
      disableCountryGuess={
        !isNullOrEmpty(props.disableCountryGuess)
          ? props.disableCountryGuess
          : true
      }
      disableInitialCountryGuess={
        !isNullOrEmpty(props.disableInitialCountryGuess)
          ? props.disableInitialCountryGuess
          : false
      }
      specialLabel={
        !isNullOrEmpty(props.showCountryFlagWithCode)
          ? countryCode && "+" + countryCode
          : ""
      } // display country code along with flag
      isValid={(value, country) => {
        if (!value) {
          return true;
        }
        if (!isPhoneNumber(value, country.iso2.toUpperCase())) {
          return (
            "Invalid " +
            props.caption +
            " number for " +
            country.name
          );
        } else {
          return true; // Valid phone number
        }
      }}
    />
  );
};
export default PhoneNumberUI;

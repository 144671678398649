import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Draggable from "react-draggable";
import { validateReactivateProfile } from "../MemberHelper";
import EyeIcon from "../../../components/UI/EyeIcon/eyeIcon";
import { getErrorToastData } from "../../../shared/utility";
import { showNotification } from "../../../components/UI/Toaster/Toaster";

const MemberActivation = (props) => {
  const [password, setPassword] = useState("");
  const [inputType, setInputType] = useState("password");
  const [notes, setNotes] = useState("");
  const [isValidateDone, setIsValidateDone] = useState(false);

  const activateDeactivateMember = () => {
    const memberActivationObj = {};
    memberActivationObj.MemberId = props.memberId;
    memberActivationObj.Notes = notes;
    memberActivationObj.Password = password;
    let msg = "";
    if (!msg) {
      msg = validateReactivateProfile(memberActivationObj, props.popupFor);
    }

    if (msg) {
      showNotification(getErrorToastData(msg));
      setIsValidateDone(true);
    } else {
      setIsValidateDone(false);
      props.cancelOrReactivateMember(memberActivationObj);
    }
  };

  return (
    <div>
      <Draggable handle=".cursor">
        <Modal
          isOpen={props.showModal}
          className="no-cursor"
          toggle={props.closeModal}
        >
          <ModalHeader
            className="modal-header bg-primary modal-title text-white cursor"
            toggle={props.closeModal}
          >
            <i className="fas fa-key" /> {props.title}
          </ModalHeader>
          <ModalBody>
            <div className="form-group row">
              <div className="FormRow col-sm-12">
                <div className={`form-group formRow required`}>
                  <label className="col-sm-5 col-form-label font-weight-bold">
                    Comments
                  </label>
                  <div
                    className={`col-sm-7 ${
                      notes ? "" : isValidateDone ? "validation-failed" : ""
                    }`}
                  >
                    <textarea
                      rows="20"
                      className="txtbox_style"
                      name="notes"
                      value={notes}
                      style={{ height: "55px", resize: "none" }}
                      onChange={(event) => setNotes(event.target.value)}
                    />
                  </div>
                </div>
                {props.popupFor === "reactivateAccount" ? (
                  <div className="form-group formRow required">
                    <label className="col-sm-5 col-form-label font-weight-bold">
                      Password
                    </label>
                    <div className="col-sm-7 position--relative">
                      <input
                        type={inputType}
                        value={password}
                        name="password"
                        placeholder="password..."
                        className="text"
                        onChange={(event) => setPassword(event.target.value)}
                      />
                      <EyeIcon
                        toggleIcon={(inputTypeVal) => {
                          setInputType(inputTypeVal);
                        }}
                      />
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button
              type="submit"
              name="btnSave"
              className="btn btn-primary waves-effect waves-light btnSize ml-2"
              onClick={activateDeactivateMember}
            >
              Save
            </button>
            <button
              type="button"
              name="btnCancel"
              className="btn btn-secondary  waves-effect waves-light btnSize ml-2"
              onClick={props.closeModal}
            >
              Cancel
            </button>
          </ModalFooter>
        </Modal>
      </Draggable>
    </div>
  );
};
export default MemberActivation;

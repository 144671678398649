import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getSettingstDataByApi,
  getSettingstDataByApiReset,
  updateSettingstDataByApi,
  updateSettingstDataByApiReset,
} from "../../../store/actions/index";
import Spinner from "../../../components/UI/Spinner/Spinner";
import { showNotification } from "../../../components/UI/Toaster/Toaster";
import PermissionName from "../../../shared/Permissions";
import {
  hasPermission,
  getBasicToastResponse,
  formatPricingValue,
} from "../../../shared/utility";
import { RateSettingsViewModel_New } from "../../../services/entities/view-models/settings-view-models";
import { CommonLabel } from "../../../components/UI/label/commmon-label";
import LinkButton from "../../../components/UI/button/link-btn";
import SpecialRateModal from "./modal/special-rate-modal";
import ConfirmModal from "../../../components/common/modal/confirm-modal";
import {
  getAPiNameForSettings,
  getInheritedProperties,
  RenderInheritedCheckBox,
} from "../settingsHelper";
import { ADMIN_PAGE, COMMON_MSG } from "../../../shared/GlobalVar";
import InheritFromParentConfirmModal from "../modal/inherit-from-parent-confirm-modal";
import Category from "../../../services/entities/models/Category";
import SpecialPricing from "../../../services/entities/models/SpecialPricing";
import SpecialRateList from "./special-rate-list";
import PreventTransitionPrompt from "../../../components/Navigations/prevent-navigation/prevent-transition-prompt";
import CommonInputField from "../../../components/UI/Input/common-input-field";

const RateSettings = (props) => {
  const loading = useSelector((state) => state.settingsReducer.loading);
  const currentOrgUnitId = useSelector(
    (state) => state.auth.loggedInUserData.CurrentOrgUnitId
  );
  const rateSettingsResponse = useSelector((state) =>
    !props.isInheritModal
      ? state.settingsReducer.settingsResponse
      : state.settingsReducer.inheritedSettingsResponse
  );
  const rateSettingsInfoUpdateResponse = useSelector(
    (state) => state.settingsReducer.updateSettingsResponse
  );
  const dependentOrgLength = useSelector((state) =>
    state.auth.loggedInUserData.DependantOrgList
      ? state.auth.loggedInUserData.DependantOrgList.length
      : 0
  );
  const dispatch = useDispatch();

  const query = props.location
    ? new URLSearchParams(props.location.search)
    : null;
  const selectedOrgLocationId =
    props.otherData && props.otherData.selectedOrgLocationId
      ? props.otherData.selectedOrgLocationId
      : query
      ? query.get("orgId")
      : currentOrgUnitId;

  const [isEditMode, setIsEditMode] = useState(false);
  const [rateSettingsData, setRateSettingsData] = useState(
    new RateSettingsViewModel_New()
  );
  const [showSpecialPricingModal, setShowSpecialPricingModal] = useState(false);
  const [showConfirmRemoveInheritModal, setShowConfirmRemoveInheritModal] =
    useState(false);
  const [
    showInheritFromParentConfirmModal,
    setShowInheritFromParentConfirmModal,
  ] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [selectedSpecialPricingEntity, setSelectedSpecialPricingEntity] =
    useState(new SpecialPricing());

  const hasEditPermission = hasPermission(
    PermissionName.EDIT_RATES_ORG_SETTINGS
  );
  const [isBlocking, setIsBlocking] = useState(false);
  useEffect(() => {
    getRateSettingsData();
  }, [selectedOrgLocationId]);

  useEffect(() => {
    if (!rateSettingsResponse) {
      return;
    }
    if (rateSettingsResponse.StatusCode === 0) {
      loadRateSettingsViewData(rateSettingsResponse);
      closeSpecialPricingModal();
      setIsEditMode(false);
    }
    dispatch(getSettingstDataByApiReset(props.isInheritModal));
  }, [rateSettingsResponse]);

  useEffect(() => {
    if (!rateSettingsInfoUpdateResponse) {
      return;
    }
    showNotification(getBasicToastResponse(rateSettingsInfoUpdateResponse));
    if (rateSettingsInfoUpdateResponse.StatusCode === 0) {
      dispatch(updateSettingstDataByApiReset());
      setShowInheritFromParentConfirmModal(false);
      setShowConfirmRemoveInheritModal(false);
      setIsEditMode(false);
      setIsBlocking(false);
    } else {
      dispatch(updateSettingstDataByApiReset());
    }
  }, [rateSettingsInfoUpdateResponse]);

  const loadRateSettingsViewData = (data, categoryId) => {
    if (!data.Categories || !data.Categories.length) {
      setRateSettingsData(
        new RateSettingsViewModel_New(
          data,
          currentOrgUnitId,
          parseInt(selectedOrgLocationId, 10)
        )
      );
      return;
    }
    if (!categoryId) {
      categoryId = data.Categories[0].CategoryId;
    }
    const categoryData =
      data.Categories && data.Categories.length > 0
        ? data.Categories.filter(
            (d) => String(d.CategoryId) === String(categoryId)
          )[0]
        : new Category();
    const selectedPriceList =
      data.SpecialPriceList && data.SpecialPriceList.length > 0
        ? data.SpecialPriceList.filter(
            (d) => String(d.CategoryId) === String(categoryId)
          )
        : [];
    setRateSettingsData(
      new RateSettingsViewModel_New(
        data,
        currentOrgUnitId,
        parseInt(selectedOrgLocationId, 10),
        categoryData,
        selectedPriceList
      )
    );
  };
  const inputChangedHandler = (event, controlName, elementType, isCategory) => {
    let updatedControls = { ...rateSettingsData };
    let value = event.target.value;
    if (controlName === "CategoryId") {
      loadRateSettingsViewData(rateSettingsResponse, parseInt(value, 10));
      return;
    } else if (!isCategory) {
      updatedControls[controlName] =
        elementType === "radio" ? !updatedControls[controlName] : value;
    } else {
      updatedControls.CategoryInfo[controlName] = value;
    }
    setRateSettingsData(updatedControls);
    setIsBlocking(true);
  };

  const onCancel = () => {
    setIsEditMode(false);
    loadRateSettingsViewData(rateSettingsResponse);
    setIsBlocking(false);
  };
  const enableEditMode = () => {
    setIsEditMode(true);
  };
  const getAddUpdateSpecialPricingEntityModal = (entity) => {
    setSelectedSpecialPricingEntity(entity);
    setShowSpecialPricingModal(true);
    //setIsEditMode(false);
  };
  const closeSpecialPricingModal = () => {
    setShowSpecialPricingModal(false);
    setShowConfirmModal(false);
    setShowConfirmRemoveInheritModal(false);
    setSelectedSpecialPricingEntity(new SpecialPricing());
  };
  const getConfirmModal = (entity) => {
    //applyHighlightedColor(entity.OrgTaxId);
    setSelectedSpecialPricingEntity(entity);
    setShowConfirmModal(true);
    //setIsEditMode(false);
  };
  const getInheritConfirmModal = (event) => {
    setIsEditMode(false);
    if (event.target.checked) {
      setShowInheritFromParentConfirmModal(true);
    } else {
      setShowConfirmRemoveInheritModal(true);
    }
  };
  const closeConfirmModal = () => {
    if (showConfirmRemoveInheritModal) {
      setShowConfirmRemoveInheritModal(false);
    } else if (showInheritFromParentConfirmModal) {
      setShowInheritFromParentConfirmModal(false);
    } else {
      setShowConfirmModal(false);
      // applyHighlightedColor(null);
      setSelectedSpecialPricingEntity(new SpecialPricing());
    }
  };
  const addUpdateSpecialPricingEntity = (updateEntity) => {
    const req = JSON.parse(JSON.stringify(rateSettingsData));
    if (!updateEntity.RateId) {
      req.SpecialPriceList.push(updateEntity);
    } else {
      req.SpecialPriceList.map((entity, index) => {
        if (entity.RateId === updateEntity.RateId) {
          req.SpecialPriceList[index] = { ...updateEntity };
          //applyHighlightedColor(updateEntity.OrgTaxId);
          return false;
        }
      });
    }
    setShowSpecialPricingModal(false);
    if (!updateEntity.RateId) {
      updateData(req);
    } else {
      setRateSettingsData(req);
    }
  };
  const removeSpecialPricingEntity = () => {
    const req = JSON.parse(JSON.stringify(rateSettingsData));
    const entityListFilter = req.SpecialPriceList.filter(
      (x) => x.RateId !== selectedSpecialPricingEntity.RateId
    );
    req.SpecialPriceList = [...entityListFilter];
    setRateSettingsData(req);
    setShowConfirmModal(false);
    updateData(req);
  };
  const doInheritFunctionality = () => {
    updateData(rateSettingsData, showConfirmRemoveInheritModal ? false : true);
  };
  const getRateSettingsData = () => {
    const req = {};
    req.SelectedChildOrgUnitId = selectedOrgLocationId;
    dispatch(getSettingstDataByApiReset(props.isInheritModal));
    dispatch(
      getSettingstDataByApi(
        req,
        getAPiNameForSettings(ADMIN_PAGE.RATE_SETTINGS),
        props.isInheritModal
      )
    );
  };
  const updateData = (req, isInheritedFromParent) => {
    const reqObj = {
      ...req,
      ...getInheritedProperties(
        currentOrgUnitId,
        req.SelectedChildOrgUnitId || selectedOrgLocationId,
        typeof isInheritedFromParent !== "undefined"
          ? isInheritedFromParent
          : req.InheritedFromParent,
        dependentOrgLength
      ),
    };
    delete reqObj["DisplayInherited"];
    delete reqObj["InheritedFromParent"];
    /** beacuse server is not working properly for inherited feature, we manually off this feature*/
    reqObj.ApplyToAllInherited = false;
    dispatch(updateSettingstDataByApiReset());
    dispatch(
      updateSettingstDataByApi(
        reqObj,
        getAPiNameForSettings(ADMIN_PAGE.RATE_SETTINGS, true)
      )
    );
  };
  const disabled =
    rateSettingsData.InheritedFromParent ||
    !hasEditPermission ||
    props.isInheritModal
      ? true
      : false;
  const renderCommmonLabel = (value, lblId, classNames) => {
    return (
      <CommonLabel
        className={
          classNames || "col-lg-12 p-0 text-truncate-block  padding-settings-5"
        }
        value={value}
        lblId={lblId}
      />
    );
  };
  const loadPresetRates = (data) => {
    return (
      <Fragment>
        <tr>
          {renderTableCell("Rate", true)}
          {renderTableCell("RatePerHour")}
          {renderTableCell("RatePerDay")}
          {renderTableCell("RatePerWeekendDay")}
          {renderTableCell("RatePerWeek")}
          {renderTableCell("RatePerMonth")}
        </tr>
        <tr>
          {renderTableCell("Miles Allowed", true)}
          {renderTableCell("HourlyMilesAllowed")}
          {renderTableCell("DailyMilesAllowed")}
          {renderTableCell("WeekendMilesAllowed")}
          {renderTableCell("WeeklyMilesAllowed")}
          {renderTableCell("MonthlyMilesAllowed")}
        </tr>
        <tr>
          {renderTableCell("Excess Mileage Charges", true)}
          {renderTableCell("HourlyExcessMileageCharge")}
          {renderTableCell("DailyExcessMileageCharge")}
          {renderTableCell("WeekendExcessMileageCharge")}
          {renderTableCell("WeeklyExcessMileageCharge")}
          {renderTableCell("MonthlyExcessMileageCharge")}
        </tr>
      </Fragment>
    );
  };
  const getIuptuCtrl = (value, name, type, isDisabled) => {
    return (
      <input
        type={type}
        name={name}
        value={value || ""}
        onChange={(event) =>
          inputChangedHandler(
            event,
            name,
            null,
            name === "PricePerMile" ? false : true
          )
        }
        disabled={
          isDisabled ||
          ["WeekendMilesAllowed", "WeekendExcessMileageCharge"].includes(name)
        }
      />
    );
  };

  const renderTableCell = (name, isRowHeader) => {
    let entity = rateSettingsData.CategoryInfo;
    let valueProp =
      name === "WeekendMilesAllowed"
        ? "DailyMilesAllowed"
        : name === "WeekendExcessMileageCharge"
        ? "DailyExcessMileageCharge"
        : name;
    return isRowHeader ? (
      <td className="atrribute-cell">{name}</td>
    ) : (
      <td>
        {isEditMode ? (
          getIuptuCtrl(
            entity[valueProp],
            name,
            "number",
            rateSettingsData.MileageBasedPricing
          )
        ) : (
          <label>{formatPricingValue(entity[valueProp], "$0.0")}</label>
        )}
      </td>
    );
  };
  const renderRadioButtons = (id, value, labelCaption) => {
    return (
      <CommonInputField
        elementConfig={{
          id: id,
          type: "radio",
          name: "MileageBasedPricing",
          checked: rateSettingsData["MileageBasedPricing"] === value,
          disabled: !isEditMode,
        }}
        otherConfig={{
          caption: labelCaption,
        }}
        nodeElement="radio"
        onChange={inputChangedHandler}
        valueClasses={labelCaption === "Yes" ? "pl-5" : ""}
      />
    );
  };

  const renderMainLayout = () => {
    return (
      <Fragment>
        <div className="col-lg-12 mb-2">
          <div className="form-group row">
            <label
              className="col-form-label font-weight-bold p-0"
              style={{ width: "100px" }}
            >
              Vehicle Type
            </label>
            <div style={{ width: "300px" }}>
              <select
                name="CategoryId"
                value={rateSettingsData.CategoryInfo.CategoryId}
                onChange={(event) => inputChangedHandler(event, "CategoryId")}
              >
                {rateSettingsResponse &&
                  rateSettingsResponse.Categories &&
                  rateSettingsResponse.Categories.map((element) => (
                    <option key={element.CategoryId} value={element.CategoryId}>
                      {element.DisplayText}
                    </option>
                  ))}
              </select>
            </div>
          </div>
          <div className="form-group row mt-5 mb-3">
            <label className="fs-2 mr-auto font-weight-bold">
              Default Preset Rates:
            </label>
          </div>
          <div className="form-group row">
            <label
              className="col-form-label font-weight-bold"
              style={{ maxWidth: "300px" }}
            >
              Mileage-based Rate & Non Mileage-based Rate :
            </label>
            <div className="col-sm-6 row">
              {renderRadioButtons("rbtnNotRequired", false, "No")}
              {renderRadioButtons("rbtnRequired", true, "Yes")}
            </div>
          </div>
          <div className="form-group row">
            <label
              className="col-form-label font-weight-bold"
              style={{ maxWidth: "300px" }}
            >
              Price Per Miles :
            </label>
            <div className="col-lg-1">
              {isEditMode
                ? getIuptuCtrl(
                    rateSettingsData.PricePerMile,
                    "PricePerMile",
                    "text",
                    !rateSettingsData.MileageBasedPricing
                  )
                : renderCommmonLabel(
                    rateSettingsData.PricePerMile,
                    "PricePerMile"
                  )}
            </div>
          </div>
        </div>
        <div className={props.isInheritModal ? "col-lg-12 mb-2 mt-3 parent-price pr-0" : "col-lg-6 mb-2 mt-3 parent-price"}> 
          <table className="table table-striped default-rates">
            <thead className="thead-primary">
              <tr>
                <th className="atrribute-head"></th>
                <th>Hourly</th>
                <th>Daily</th>
                <th>Weekend</th>
                <th>Weekly</th>
                <th>Monthly</th>
              </tr>
            </thead>
            <tbody className={isEditMode ? "background-edit" : ""}>
              {loadPresetRates(rateSettingsData.CategoryInfo)}
            </tbody>
          </table>
        </div>
        <div className="col-lg-12 mb-2 mt-5 font-weight-bold d-flex">
          <label className="fs-2  mr-auto">Adjust Preset Rate:</label>
          {hasEditPermission &&
          !rateSettingsData.InheritedFromParent &&
          !disabled &&
          !rateSettingsData.MileageBasedPricing ? (
            <LinkButton
              btnLabel="Add Special Pricing"
              containerClass="pseudo-link pr-1 fs-2 font-weight-bold  pl-5"
              iconClass="fas fa-plus-circle iconBeforeLink"
              clicked={() =>
                getAddUpdateSpecialPricingEntityModal(
                  new SpecialPricing({
                    RatePerHour: rateSettingsData.CategoryInfo.RatePerHour,
                    RatePerDay: rateSettingsData.CategoryInfo.RatePerDay,
                    RatePerWeekendDay:
                      rateSettingsData.CategoryInfo.RatePerWeekendDay,
                    RatePerWeek: rateSettingsData.CategoryInfo.RatePerWeek,
                    RatePerMonth: rateSettingsData.CategoryInfo.RatePerMonth,
                  })
                )
              }
            />
          ) : (
            ""
          )}
        </div>

        <div className="col-lg-12 bb-0 mb-5 table-container">
          <SpecialRateList
            rateSettingsData={rateSettingsData}
            isEditMode={isEditMode}
            disabled={disabled}
            addUpdateEntity={addUpdateSpecialPricingEntity}
            getConfirmModal={getConfirmModal}
            isInheritModal={props.isInheritModal}
          />
        </div>
      </Fragment>
    );
  };
  return props.isInheritModal ? (
    renderMainLayout()
  ) : (
    <Fragment>
      <PreventTransitionPrompt when={isBlocking} />
      <div className="col-lg-12 pt-3 pl-0" id="main-settings">
        <div className="card">
          <div className="card-body">
            <div className="form-header row col-lg-12 p-0">
              <h4 className="text-uppercase mr-auto icon-display-none">
                <i className="fas fa-dollar-sign" aria-hidden="true"></i>
                Rates Settings
              </h4>
              {hasEditPermission &&
              !isEditMode &&
              !rateSettingsData.InheritedFromParent ? (
                <LinkButton
                  btnLabel="Edit Settings"
                  containerClass="pseudo-link pr-1 fs-2 font-weight-bold mr-5 d-flex align-items-center"
                  stackClass="fa-stack fs-08 vertical-align-top"
                  faIconList={[
                    "fa fa-circle fa-stack-2x iconBeforeLink",
                    "fa fa-pencil-alt fa-stack-1x whiteColor pr-1",
                  ]}
                  clicked={enableEditMode}
                />
              ) : (
                ""
              )}
              <RenderInheritedCheckBox
                checked={rateSettingsData.InheritedFromParent}
                onChange={(event) => getInheritConfirmModal(event)}
                disabled={!hasEditPermission}
                displayEnable={rateSettingsData.DisplayInherited}
              />
            </div>
            {renderMainLayout()}
            <div
              className="settingFooter  col-sm-12"
              style={{ textAlign: "center" }}
            >
              {!disabled && isEditMode ? (
                <Fragment>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => updateData(rateSettingsData)}
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    name="className"
                    className="btn btn-secondary ml-2"
                    onClick={() => onCancel()}
                  >
                    Cancel
                  </button>
                </Fragment>
              ) : (
                <div style={{ height: "2.5rem" }}>&nbsp;</div>
              )}
            </div>
          </div>
        </div>
        {loading ? <Spinner /> : ""}
        {showSpecialPricingModal ? (
          <SpecialRateModal
            showModal={showSpecialPricingModal}
            closeModal={closeSpecialPricingModal}
            selectedEntity={selectedSpecialPricingEntity}
            addUpdateEntity={addUpdateSpecialPricingEntity}
          />
        ) : (
          ""
        )}
        {showConfirmModal || showConfirmRemoveInheritModal ? (
          <ConfirmModal
            body={
              showConfirmModal
                ? COMMON_MSG.DeleteRecordMsg
                : COMMON_MSG.RemoveInheritParentConfirm
            }
            showModal={showConfirmModal || showConfirmRemoveInheritModal}
            closeModal={closeConfirmModal}
            doConfirmFunctionality={
              showConfirmModal
                ? removeSpecialPricingEntity
                : doInheritFunctionality
            }
          />
        ) : (
          ""
        )}
        {showInheritFromParentConfirmModal ? (
          <InheritFromParentConfirmModal
            showModal={showInheritFromParentConfirmModal}
            closeModal={closeConfirmModal}
            doInheritFunctionality={doInheritFunctionality}
            currentOrgUnitId={currentOrgUnitId}
            modalWidth="80rem"
            componentName={RateSettings}
          />
        ) : (
          ""
        )}
      </div>
    </Fragment>
  );
};
export default RateSettings;

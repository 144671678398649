import { invokeServerAPI } from "../../services/httpService";
import * as actionTypes from "./actionTypesSettings";

const doFoxAPIStart = (actionType) => {
  return { type: actionType };
};
// const doFoxAPIFail = (actionType, error) => {
//     return { type: actionType, error: error };
// };

/** Get Rates for FOX*/
export const getAllRatesForFoxSuccess = (response) => {
  return {
    type: actionTypes.GET_ALL_RATES_FOX_API_SUCCESS,
    foxAllRateResponse: response,
  };
};
export const getRatesForFoxReset = () => {
  return {
    type: actionTypes.GET_ALL_RATES_FOX_API_RESET,
  };
};
export const getRatesForFox = (req) => {
  return (dispatch) => {
    dispatch(doFoxAPIStart(actionTypes.GET_ALL_RATES_FOX_API_START));
    invokeServerAPI("foxapi/rates", req, true)
      .then((response) => {
        dispatch(getAllRatesForFoxSuccess(response));
      })
      .catch((err) => {
        //dispatch(doFoxAPIFail(tionTypes.GET_ALL_RATES_FOX_API_FAIL,err));
      });
  };
};
/** Get Specific Rates for FOX*/
export const getSpecificRatesForFoxSuccess = (response) => {
  return {
    type: actionTypes.GET_SPECIFIC_RATES_FOX_API_SUCCESS,
    foxSpecificRateResponse: response,
  };
};
export const getSpecificRatesForFoxReset = () => {
  return {
    type: actionTypes.GET_SPECIFIC_RATES_FOX_API_RESET,
  };
};
export const getSpecificRatesForFox = (req) => {
  return (dispatch) => {
    dispatch(doFoxAPIStart(actionTypes.GET_SPECIFIC_RATES_FOX_API_START));
    invokeServerAPI("foxapi/rates", req, true)
      .then((response) => {
        dispatch(getSpecificRatesForFoxSuccess(response));
      })
      .catch((err) => {
        //dispatch(doFoxAPIFail(tionTypes.GET_SPECIFIC_RATES_FOX_API_FAIL,err));
      });
  };
};

/** Get AddOns for FOX*/
export const getAddOnsForFoxSuccess = (response) => {
  return {
    type: actionTypes.GET_ADDONS_FOX_API_SUCCESS,
    foxAddOnsResponse: response,
  };
};
export const getAddOnsForFoxReset = () => {
  return {
    type: actionTypes.GET_ADDONS_FOX_API_RESET,
  };
};
export const getAddOnsForFox = (req, apiName) => {
  return (dispatch) => {
    dispatch(doFoxAPIStart(actionTypes.GET_ADDONS_FOX_API_START));
    invokeServerAPI("foxapi/addons", req, true)
      .then((response) => {
        dispatch(getAddOnsForFoxSuccess(response));
      })
      .catch((err) => {
        //dispatch(doFoxAPIFail(actionTypes.GET_ADDONS_FOX_API_FAIL,err));
      });
  };
};

/** Create Reservation for FOX*/
export const createReservationForFoxSuccess = (response) => {
  return {
    type: actionTypes.CREATE_RESERVATION_FOX_API_SUCCESS,
    foxCreateReservationResponse: response,
  };
};
export const createReservationForFoxReset = () => {
  return {
    type: actionTypes.CREATE_RESERVATION_FOX_API_RESET,
  };
};
export const createReservationForFox = (req) => {
  return (dispatch) => {
    dispatch(doFoxAPIStart(actionTypes.CREATE_RESERVATION_FOX_API_START));
    invokeServerAPI("foxapi/reservation", req, true)
      .then((response) => {
        dispatch(createReservationForFoxSuccess(response));
      })
      .catch((err) => {
        //dispatch(doFoxAPIFail(actionTypes.CREATE_RESERVATION_FOX_API_FAIL,err));
      });
  };
};

/** Get Reservation for FOX*/
export const getReservationForFoxSuccess = (response) => {
  return {
    type: actionTypes.GET_RESERVATION_FOX_API_SUCCESS,
    foxGetReservationResponse: response,
  };
};
export const getReservationForFoxReset = () => {
  return {
    type: actionTypes.GET_RESERVATION_FOX_API_RESET,
  };
};
export const getReservationForFox = (req) => {
  return (dispatch) => {
    dispatch(doFoxAPIStart(actionTypes.GET_RESERVATION_FOX_API_START));
    invokeServerAPI("foxapi/reservation", req, true)
      .then((response) => {
        dispatch(getReservationForFoxSuccess(response));
      })
      .catch((err) => {
        //dispatch(doFoxAPIFail(actionTypes.GET_RESERVATION_FOX_API_FAIL,err));
      });
  };
};

/** Cancel Reservation for FOX*/
export const cancelReservationForFoxSuccess = (response) => {
  return {
    type: actionTypes.CANCEL_RESERVATION_FOX_API_SUCCESS,
    foxCancelReservationResponse: response,
  };
};
export const cancelReservationForFoxReset = () => {
  return {
    type: actionTypes.CANCEL_RESERVATION_FOX_API_RESET,
  };
};
export const cancelReservationForFox = (req) => {
  return (dispatch) => {
    dispatch(doFoxAPIStart(actionTypes.CANCEL_RESERVATION_FOX_API_START));
    invokeServerAPI("foxapi/reservation", req, true)
      .then((response) => {
        dispatch(cancelReservationForFoxSuccess(response));
      })
      .catch((err) => {
        //dispatch(doFoxAPIFail(actionTypes.CANCEL_RESERVATION_FOX_API_FAIL,err));
      });
  };
};

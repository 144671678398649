import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactHtmlParser from "react-html-parser";
import { getDateTimeString, getNoteTooltipHtml } from "../../shared/utility";
import {
  getReservationOverviewReset,
  getReservationOverview,
} from "../../store/actions/index";
import EyeIcon from "../../components/UI/EyeIcon/eyeIcon";
import TooltipItem from "../../components/tooltip/TooltipItem";
import { LabelAndValuePairControl } from "../../components/UI/Input/label-value-pair-control";
import { useEffect } from "react";
import DefaultComponentWithMessage from "../../components/common/pages/default-component-with-message";

export const PartnerDetailsSection = (props) => {
  const dispatch = useDispatch();
  const [selectedPartnerData, setSelectedPartnerData] = useState(null);
  const [reservationId, setReservationId] = useState("10004001254");
  const [reservationData, setReservationData] = useState(null);
  const reservationOverviewResponse = useSelector(
    (state) => state.reservationReducer.reservationOverviewResponse
  );

  useEffect(() => {
    if (
      props.selectedPartnerData &&
      Object.keys(props.selectedPartnerData).length !== 0
    ) {
      setSelectedPartnerData(props.selectedPartnerData);
      setReservationId(props.selectedPartnerData.ResCode || "10004001254");
    }
  }, [props.selectedPartnerData, selectedPartnerData]);

  useEffect(() => {
    initSummaryPage();
  }, [reservationId]);

  const initSummaryPage = () => {
    if (reservationId) {
      dispatch(getReservationOverviewReset());
      dispatch(
        getReservationOverview({
          ReservationId: reservationId,
        })
      );
    }
  };
  useEffect(() => {
    if (!reservationOverviewResponse) {
      return;
    }
    if (reservationOverviewResponse.StatusCode === 0) {
      setReservationData(reservationOverviewResponse.Reservation);
    }
    dispatch(getReservationOverviewReset());
  }, [reservationOverviewResponse]);

  const [channelQuickModalState, setChannelQuickModalState] = useState({
    maskedValue: "XXXXXXXXXXXXXXXX",
    encryptionKey: "XXXXXXXXXXXXXXXX",
    licenseKey: "XXXXXXXXXXXXXXXX",
    primaryAuthenticationId: "XXXXXXXXXXXXXXXX",
    encryptionKeyTooltip: null,
    licenseKeyTooltip: null,
    primaryAuthenticationIdTooltip: null,
  });
  const characterHideShowToggleForLabel = (returnedValue, labelId) => {
    let updateCtrl = { ...channelQuickModalState };
    updateCtrl.encryptionKey =
      labelId === "encryptionKey" ? returnedValue : updateCtrl.encryptionKey;
    updateCtrl.licenseKey =
      labelId === "licenseKey" ? returnedValue : updateCtrl.licenseKey;
    updateCtrl.primaryAuthenticationId =
      labelId === "primaryAuthenticationId"
        ? returnedValue
        : updateCtrl.primaryAuthenticationId;

    updateCtrl.encryptionKeyTooltip =
      labelId === "encryptionKey"
        ? getTooltipTextForSensitiveField(labelId, returnedValue)
        : updateCtrl.encryptionKeyTooltip;
    updateCtrl.encryptionKeyTooltip =
      labelId === "encryptionKey"
        ? getTooltipTextForSensitiveField(labelId, returnedValue)
        : updateCtrl.encryptionKeyTooltip;
    updateCtrl.licenseKeyTooltip =
      labelId === "licenseKey"
        ? getTooltipTextForSensitiveField(labelId, returnedValue)
        : updateCtrl.licenseKeyTooltip;
    updateCtrl.primaryAuthenticationIdTooltip =
      labelId === "primaryAuthenticationId"
        ? getTooltipTextForSensitiveField(labelId, returnedValue)
        : updateCtrl.primaryAuthenticationIdTooltip;
    setChannelQuickModalState(updateCtrl);
  };
  const getTooltipTextForSensitiveField = (labelId, returnedValue) => {
    return channelQuickModalState.maskedValue !== returnedValue ? (
      <TooltipItem
        placement="left"
        toolTipId={labelId}
        renderableHtml={ReactHtmlParser(getNoteTooltipHtml(returnedValue))}
      />
    ) : (
      ""
    );
  };
  const renderLabelInputPair = (
    labelCaption,
    value,
    parentRowClass,
    isTopRow
  ) => {
    return (
      <LabelAndValuePairControl
        elementConfig={{ value: value }}
        otherConfig={{
          caption: labelCaption,
        }}
        parentRowClass={
          isTopRow ? "col-lg-4 " + parentRowClass : "form-group row"
        }
        labelClasses={
          isTopRow
            ? "font-weight-bold"
            : "col-sm-5 col-form-label font-weight-bold"
        }
        valueClasses={isTopRow ? "pl-4" : "col-sm-7 pl-0"}
      />
    );
  };
  const renderLabelValueWithEyeIcon = (
    labelCaption,
    originalValue,
    modalValue,
    modalValueTooltip,
    modalMaskedValue,
    labelID
  ) => {
    return (
      <div className="col-lg-12 p-01 row">
        <span className="font-weight-bold col-lg-5">
          {labelCaption}
          {originalValue ? (
            <EyeIcon
              characterHideShowToggleForLabel={(returnedValue, labelId) =>
                characterHideShowToggleForLabel(returnedValue, labelId)
              }
              maskedValue={modalMaskedValue}
              originalValue={originalValue}
              labelID={labelID}
              customClass="fs-3"
            />
          ) : (
            ""
          )}
        </span>
        <label className="p-01 pr-2 col-lg-7 overflowCommon" id={labelID}>
          {originalValue ? modalValue : "--"}
          {modalValueTooltip ? modalValueTooltip : ""}
        </label>
      </div>
    );
  };

  return !selectedPartnerData || !reservationData ? (
    <DefaultComponentWithMessage
      msg="Select Partner Code to display"
      heights="70rem"
    />
  ) : (
    <div className="row col-lg-12 p-01" id="partner-info-details">
      <div className="col-lg-12 p-0 fs-5 text-center">
        <h4 className="form-header text-uppercase mr-auto">
          Partner Information Details
        </h4>
      </div>
      <div className="col-lg-12 row pb-2">
        {renderLabelInputPair(
          "Partner Confirmation Key",
          reservationData.PartnerConfirmationKey,
          "horizontal-align-left p-01",
          true
        )}
        {renderLabelInputPair(
          "Source",
          reservationData.Source,
          "horizontal-align-center",
          true
        )}
        {renderLabelInputPair(
          "API License ID",
          reservationData.ApiLicense.ApiLicenseId,
          "horizontal-align-right",
          true
        )}
      </div>
      <div className="col-lg-6">
        <h4 className="form-header text-uppercase col-lg-12 p-01">
          <i className="fas fa-user-circle"></i>Partner Information
        </h4>
        {renderLabelInputPair(
          "Allowed IPs",
          reservationData.Partner.AllowedIps
        )}
        {renderLabelInputPair(
          "Allowed Pay Type",
          reservationData.Partner.AllowedPayType
        )}
        {renderLabelInputPair(
          "Card Required",
          String(reservationData.Partner.CardRequired)
        )}
        {renderLabelInputPair(
          "Client Reservation Allowed",
          String(reservationData.Partner.ClientReservationAllowed) || "--"
        )}
        {renderLabelInputPair("Company ID", reservationData.Partner.CompanyId)}
        {renderLabelInputPair(
          "Confirmation Key Prefix",
          reservationData.Partner.ConfKeyPrefix
        )}
        {renderLabelInputPair(
          "Customer Max Reservations",
          reservationData.Partner.CustomerMaxReservations
        )}
        {renderLabelInputPair(
          "Display Name",
          reservationData.Partner.DisplayName
        )}
        {renderLabelInputPair("IATA", reservationData.Partner.Iata)}
        {renderLabelInputPair("Name", reservationData.Partner.Name)}
        {renderLabelInputPair(
          "Partner Code",
          reservationData.Partner.PartnerCode
        )}
        {renderLabelInputPair("Partner ID", reservationData.Partner.PartnerId)}
        {renderLabelInputPair(
          "Restricted IPs",
          reservationData.Partner.RestrictedIps
        )}
        {renderLabelInputPair(
          "Return Confirm Key Type",
          reservationData.Partner.ReturnConfirmKeyType
        )}
        {renderLabelInputPair(
          "Show Fox Confirmation Key",
          String(reservationData.Partner.ShowFoxConfirmationKey)
        )}
        {renderLabelInputPair(
          "Source Code",
          reservationData.Partner.SourceCode
        )}
        <h4 className="form-header text-uppercase col-lg-12 p-01">
          <i className="fas fa-user-circle"></i>Partner Contact Information
        </h4>
        {renderLabelInputPair(
          "Contact Name",
          reservationData.Partner.ContactName
        )}
        {renderLabelInputPair(
          "Contact Email",
          reservationData.Partner.ContactEmail
        )}
        {renderLabelInputPair(
          "Contact Phone",
          reservationData.Partner.ContactPhone
        )}
        {renderLabelInputPair(
          "Contact Title",
          reservationData.Partner.ContactTitle
        )}
        {renderLabelInputPair(
          "Company Address",
          reservationData.Partner.CompanyAddress
        )}
        {renderLabelInputPair(
          "Company Country",
          reservationData.Partner.CompanyCountry
        )}
        {renderLabelInputPair(
          "Company State",
          reservationData.Partner.CompanyState
        )}
        {renderLabelInputPair(
          "Company City",
          reservationData.Partner.CompanyCity
        )}
        {renderLabelInputPair(
          "Company Zip Code",
          reservationData.Partner.CompanyZipCode
        )}
      </div>
      <div className="col-lg-6">
        <h4 className="form-header text-uppercase col-lg-12 p-01">
          Provider Information
        </h4>

        {renderLabelInputPair(
          "Deployment Mode",
          reservationData.Provider.DeploymentMode
        )}
        {renderLabelInputPair(
          "Display Order",
          reservationData.Provider.DisplayOrder
        )}
        {renderLabelInputPair(
          "Integration Provider ID",
          reservationData.Provider.IntegProvId
        )}
        {renderLabelInputPair("Name", reservationData.Provider.Name)}
        {renderLabelInputPair(
          "Password",
          reservationData.Provider.Password ? "*****" : "--"
        )}
        {renderLabelInputPair(
          "Provider Status",
          reservationData.Provider.ProviderStatus
        )}
        {renderLabelInputPair("URL", reservationData.Provider.URL)}
        {renderLabelInputPair("User Name", reservationData.Provider.UserName)}

        <h4 className="form-header text-uppercase col-lg-12 p-01">
          <i className="fas fa-user-circle"></i>API License Information
        </h4>

        {renderLabelInputPair(
          "Company Address",
          reservationData.ApiLicense.CompanyAddress
        )}
        {renderLabelInputPair(
          "Contact Person Email",
          reservationData.ApiLicense.ContactPersonEmail
        )}
        {renderLabelInputPair(
          "Contact Person Name",
          reservationData.ApiLicense.ContactPersonName
        )}
        {renderLabelInputPair(
          "Contact Person Phone",
          reservationData.ApiLicense.ContactPersonPhone
        )}
        {renderLabelInputPair(
          "Contact Person Title",
          reservationData.ApiLicense.ContactPersonTitle
        )}
        {renderLabelValueWithEyeIcon(
          "Encryption Key",
          reservationData.ApiLicense.EncryptionKey,
          channelQuickModalState.encryptionKey,
          channelQuickModalState.encryptionKeyTooltip,
          channelQuickModalState.maskedValue,
          "encryptionKey"
        )}
        {renderLabelInputPair(
          "Expiration Key On",
          getDateTimeString(reservationData.ApiLicense.ExpirationKeyOn)
        )}
        {renderLabelInputPair(
          "Expiration On",
          getDateTimeString(reservationData.ApiLicense.ExpirationOn)
        )}
        {renderLabelValueWithEyeIcon(
          "License Key",
          reservationData.ApiLicense.LicenseKey,
          channelQuickModalState.licenseKey,
          channelQuickModalState.licenseKeyTooltip,
          channelQuickModalState.maskedValue,
          "licenseKey"
        )}
        {renderLabelInputPair("Notes", reservationData.ApiLicense.Notes)}
        {renderLabelValueWithEyeIcon(
          "Primary Authentication ID",
          reservationData.ApiLicense.PrimaryAuthenticationId,
          channelQuickModalState.primaryAuthenticationId,
          channelQuickModalState.primaryAuthenticationIdTooltip,
          channelQuickModalState.maskedValue,
          "primaryAuthenticationId"
        )}
        {renderLabelInputPair(
          "Renewed On",
          getDateTimeString(reservationData.ApiLicense.RenewedOn)
        )}
      </div>
    </div>
  );
};
export default PartnerDetailsSection;

import React from "react";

const DefaultComponentWithMessage = (props) => {
  return (
    <div
      className={
        props.className ? props.className + " area-container" : "area-container"
      }
      style={{ height: props.heights ? props.heights : "" }}
    >
      <div
        className={
          props.innerClassName
            ? props.innerClassName + " no-search"
            : "no-search"
        }
      >
        {props.msg ||
          "Make your selections and Click on Search to View Results"}
      </div>
    </div>
  );
};

export default DefaultComponentWithMessage;

import SearchPageContainer from "../../../containers/search/search-page-container";
import SubMenu from "../model/sub-menu";
import SubMenuItem from "../model/sub-menu-item";

export const getReportsSubMenu = () => {
  const subMenu = new SubMenu();
  subMenu.menuKey = "report";
  subMenu.subMenuItems.push(
    new SubMenuItem(
      "maintenance",
      "Service Agent",
      "fa fa-users-cog",
      SearchPageContainer,
      null,
      []
    )
  );
  subMenu.subMenuItems.push(
    new SubMenuItem(
      "mechanics",
      "Mechanics",
      "fa fa-wrench",
      SearchPageContainer,
      null,
      []
    )
  );
  // subMenu.subMenuItems.push(
  //   new SubMenuItem(
  //     "readyLineAgent",
  //     "Ready Line Agent",
  //     "fa fa-users-cog",
  //     SearchPageContainer,
  //     null,
  //     []
  //   )
  // );
  subMenu.subMenuItems.push(
    new SubMenuItem(
      "shuttleTrips",
      "Shuttles",
      "fa fa-bus",
      SearchPageContainer,
      null,
      []
    )
  );
  return subMenu;
};

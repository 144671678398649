import { FoxRequestHeader } from "../../fox-api/domain/fox-request-header";
import { LandmarkModel } from "../models/landmark-model";

const LandmarkDetailsRequest = function(data, requestType) {
  this.requestHeader = new FoxRequestHeader({
    requestType: requestType || "GET",
  });
  this.landmark = new LandmarkModel(data);
};
export default LandmarkDetailsRequest;

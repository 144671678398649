import React, { Fragment } from 'react';
import FoxReservationDefaultPage from '../../../../../components/common/pages/fox-res-default-page';
import { CLOCK_01 } from '../../../../../shared/AppImages';
import { COMMON_MSG, TOAST_MSG } from '../../../../../shared/GlobalVar';
import { hasObjectNonEmptyProperty } from '../../../../../shared/utility';

const BusinessHourSection = ({ businessHours }) => {
    const isBusinessHoursEmpty = !businessHours || !hasObjectNonEmptyProperty(businessHours);
    const renderTimeItems = (times) => (
        times.length > 0 && times.map((timeRange, index) => (
            <Fragment key={index}>
                <span className='valueColumn'>{timeRange}</span>
                {index < times.length - 1 && <span className="dot"></span>}
            </Fragment>
        ))
    );
    const renderBusinessHours = () => (
        Object.entries(businessHours).map(([day, times], index) => (
            <div key={day + index} className='day-row-container tableScrollX' style={{ maxWidth: "65rem" }}>
                <span className='dayColumn'>{day}</span>
                <span className="vertical-line"></span>
                {renderTimeItems(times)}
            </div>
        ))
    );
    const renderNoBusinessHoursFound = () => (
        <FoxReservationDefaultPage
            messageType={TOAST_MSG.WARNING}
            iconContent={CLOCK_01}
            title="No Business Hours"
            msg={COMMON_MSG.BusinessHoursMissingMessage}
            customParentClass="horizontal-align-center w-100"
            customerClassMessageTitle="w-auto"
        />
    );

    const renderBusinessHoursContent = () => (
        <>
            <div className="columnTitle">
                <span className="columnName">Day</span>
                <span className="vertical-line"></span>
                <span className="columnName">Time</span>
            </div>
            {renderBusinessHours()}
        </>
    );
    return (
        <div className="business-hour-section">
            <span className="sectionTitle">Business Hours</span>
            <div className="business-hour-items-container">
                {isBusinessHoursEmpty ? renderNoBusinessHoursFound() : renderBusinessHoursContent()}
            </div>
        </div>
    );

};

export default BusinessHourSection;

import PageInfo from "../../../services/entities/models/PageInfo";
import { getMemberLabelByOrgRentalType } from "../../../shared/utility/system-utility";
import { getCurrentServerTimezone } from "../../../shared/utility";
import { ADMIN_COMMON_CAPTION } from "../../../shared/GlobalVar";

export const getMemberSearchCtrls = () => {
  return {
    isAdvancedSearch: false,
    PageInfo: new PageInfo(null, null, "Name", "ASC"),
    SearchValue: {
      id: "ddlSearchValue",
      elementType: "input",
      elementConfig: {
        name: "SearchValue",
        type: "text",
        placeholder:  getMemberLabelByOrgRentalType() + ' or Loyalty ID'
      },
      validation: {
        required: false,
      },
      labelInput: {
        labeltext: "",
        type: "select",
        Name: "SearchType",
        value: "MEMBER",
        options: [
          { key: "MEMBER", displayValue: ADMIN_COMMON_CAPTION.CUSTOMER_ID },
          { key: "RESERVATION", displayValue: "Res. Code" },
        ],
      },
      value: "",
      valid: false,
      touched: false,
      basicOrder: 1,
      advancedOrder: 1,
    },
    FirstName: {
      id: "tbFirstName",
      elementType: "input",
      elementConfig: {
        name: "FirstName",
        type: "text",
      },
      validation: {
        required: false,
      },
      labelInput: {
        labeltext: "First Name",
      },
      value: "",
      valid: false,
      touched: false,
      basicOrder: 0,
      advancedOrder: 2,
    },
    FullName: {
      id: "tbFullName",
      elementType: "input",
      elementConfig: {
        name: "FullName",
        type: "text",
      },
      validation: {
        required: false,
      },
      labelInput: {
        labeltext: "Name",
      },
      value: "",
      valid: false,
      touched: false,
      basicOrder: 2,
      advancedOrder: 0,
    },
    DateOfBirth: {
      id: "tbDateOfBirth",
      elementType: "datepicker",
      elementConfig: {
        name: "DateOfBirth",
        type: "text",
      },
      validation: {
        required: false,
      },
      labelInput: {
        labeltext: "Date Of Birth",
      },
      value: "",
      valid: false,
      touched: false,
      basicOrder: 7,
      advancedOrder: 3,
    },
    StartJoinDate: {
      id: "tbStartJoinDate",
      elementType: "datepicker",
      elementConfig: {
        name: "StartJoinDate",
        type: "text",
      },
      validation: {
        required: false,
      },
      labelInput: {
        labeltext: "From",
      },
      timeZoneString: getCurrentServerTimezone(),
      value: null,
      valid: false,
      touched: false,
      basicOrder: 0,
      advancedOrder: 6,
    },
    EndJoinDate: {
      id: "tbEndJoinDate",
      elementType: "datepicker",
      elementConfig: {
        name: "EndJoinDate",
        type: "text",
      },
      validation: {
        required: false,
      },
      labelInput: {
        labeltext: "To",
      },
      timeZoneString: getCurrentServerTimezone(),
      value: null,
      valid: false,
      touched: false,
      basicOrder: 0,
      advancedOrder: 11,
    },
    Address: {
      id: "tbAddress",
      elementType: "input",
      elementConfig: {
        name: "Address",
        type: "text",
      },
      validation: {
        required: false,
      },
      labelInput: {
        labeltext: "Address",
      },
      value: "",
      valid: false,
      touched: false,
      basicOrder: 0,
      advancedOrder: 4,
    },

    LastName: {
      id: "tbLastName",
      elementType: "input",
      elementConfig: {
        name: "LastName",
        type: "text",
      },
      validation: {
        required: false,
      },
      labelInput: {
        labeltext: "Last Name",
      },
      value: "",
      valid: false,
      touched: false,
      basicOrder: 0,
      advancedOrder: 7,
    },
    PersonalNumber: {
      id: "tbPersonalNumber",
      elementType: "input",
      elementConfig: {
        name: "PersonalNumber",
        type: "text",
      },
      validation: {
        required: false,
      },
      labelInput: {
        labeltext: "Phone Number",
      },
      value: "",
      valid: false,
      touched: false,
      basicOrder: 6,
      advancedOrder: 12,
    },
    DrivingLicenseNumber: {
      id: "tbDrivingLicenseNumber",
      elementType: "input",
      elementConfig: {
        name: "DrivingLicenseNumber",
        type: "text",
      },
      validation: {
        required: false,
      },
      labelInput: {
        labeltext: "Driver's License",
      },
      value: "",
      valid: false,
      touched: false,
      basicOrder: 3,
      advancedOrder: 8,
    },
    ZipCode: {
      id: "tbZipCode",
      elementType: "input",
      elementConfig: {
        name: "ZipCode",
        type: "text",
      },
      validation: {
        required: false,
      },
      labelInput: {
        labeltext: "Postal/ZIP Code",
      },
      value: "",
      valid: false,
      touched: false,
      basicOrder: 0,
      advancedOrder: 9,
    },

    EmailAddress: {
      id: "tbEmailAddress",
      elementType: "input",
      elementConfig: {
        name: "EmailAddress",
        type: "text",
      },
      validation: {
        required: false,
      },
      labelInput: {
        labeltext: "Email/ Username",
      },
      value: "",
      valid: false,
      touched: false,
      basicOrder: 4,
      advancedOrder: 13,
    },
    EmployeeLocationId: {
      id: "ddlEmployeeLocationId",
      elementType: "select",
      elementConfig: {
        name: "EmployeeLocationId",
        options: [{ key: "", displayValue: "Any Location" }],
      },
      labelInput: {
        labeltext: "Employee Loc",
      },
      value: "",
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      basicOrder: 0,
      advancedOrder: 19,
    },
    EmployeeNo: {
      id: "tbEmployeeNo",
      elementType: "input",
      elementConfig: {
        name: "EmployeeNo",
        type: "text",
      },
      validation: {
        required: false,
      },
      labelInput: {
        labeltext: "Employee #",
      },
      value: "",
      valid: false,
      touched: false,
      basicOrder: 0,
      advancedOrder: 18,
    },
    Department: {
      id: "Department",
      elementType: "input",
      elementConfig: {
        name: "Department",
        type: "text",
      },
      validation: {
        required: false,
      },
      labelInput: {
        labeltext: "Department",
      },
      value: "",
      valid: false,
      touched: false,
      basicOrder: 0,
      advancedOrder: 17,
    },
    IsEmployee: {
      id: "ddlIsEmployee",
      elementType: "select",
      elementConfig: {
        name: "IsEmployee",
        options: [
          { key: "", displayValue: "All" },
          { key: "true", displayValue: "Employee" },
          { key: "false", displayValue: "Not Employee" },
        ],
      },
      labelInput: {
        labeltext: getMemberLabelByOrgRentalType("Type"),
      },
      value: "",
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      basicOrder: 0,
      advancedOrder: 16,
    },
    BadgeNo: {
      id: "tbBadgeNo",
      elementType: "input",
      elementConfig: {
        name: "BadgeNo",
        type: "text",
      },
      validation: {
        required: false,
      },
      labelInput: {
        labeltext: "Badge #",
      },
      value: "",
      valid: false,
      touched: false,
      basicOrder: 0,
      advancedOrder: 21,
    },
    OptInXpress: {
      id: "ddlOptInXpress",
      name: "OptInXpress",
      elementType: "select",
      elementConfig: {
        name: "OptInXpress",
        options: [
          { key: "", displayValue: "All" },
          { key: "true", displayValue: "Opted" },
          { key: "false", displayValue: "Not Opted In" },
        ],
      },
      labelInput: {
        labeltext: "FOXPress",
      },
      value: "",
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      basicOrder: 8,
      advancedOrder: 14,
    },
    LocationType: {
      id: "LocationType",
      name: "LocationType",
      elementType: "select",
      elementConfig: {
        name: "LocationType",
        options: [],
      },
      labelInput: {
        labeltext: "Location Type",
      },
      value: "",
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      basicOrder: 0,
      advancedOrder: 22,
    },

    IncludeInactive: {
      id: "cbIncludeInactive",
      elementType: "input",
      elementConfig: {
        name: "IncludeInactive",
        type: "checkbox",
        placeholder: "Show Inactive",
      },
      labelInput: {
        labeltext: "Show Inactive",
        labelFor: "IncludeInactive",
      },
      value: "",
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      basicOrder: 8,
      advancedOrder: 23,
    },
    btnResSearch: {
      id: "btnResSearch",
      elementType: "button",
      elementConfig: {
        type: "submit",
        value: "Search",
        name: "btnResSearch",
        className: "btn btn-primary search-btn",
      },
      basicOrder: 5,
      advancedOrder: 5,
    },
    btnResSearchReset: {
      id: "btnResSearchReset",
      elementType: "button",
      elementConfig: {
        type: "button",
        value: "Reset",
        name: "btnResSearchReset",
        className: "btn btn-secondary search-btn",
      },
      basicOrder: 10,
      advancedOrder: 10,
    },
    empty: {
      id: "empty",
      elementType: "empty",
      elementConfig: {
        name: "",
        type: "empty",
      },
      basicOrder: 0,
      advancedOrder: 15,
    },
    empty1: {
      id: "empty",
      elementType: "empty",
      elementConfig: {
        name: "",
        type: "empty",
      },
      basicOrder: 0,
      advancedOrder: 20,
    },
    empty2: {
      id: "empty",
      elementType: "empty",
      elementConfig: {
        name: "",
        type: "empty",
      },
      basicOrder: 0,
      advancedOrder: 25,
    },
  };
};

import AmenitiesInfo from "./AmenitiesInfo";
import KeyValuePair from "./KeyValuePair";
import DeviceInfo from "./DeviceInfo";
import Address from "./Address";
const Vehicle = function() {
  this.AcknowledgeCleanEnabled = null;
  this.AcknowledgeCleanEnabled = null;
  this.AcknowledgeDoubleCheckDetailsEnabled = null;
  this.AcknowledgeInsuranceSupportedEnabled = null;
  this.AcknowledgeReadEligibilityEnabled = null;
  this.AcknowledgeReadEligibilityEnabled = null;
  this.AcknowledgeSafeEnabled = null;
  this.AcknowledgeSafeEnabled = [];
  this.AcknowledgeUnmodifiedForHandicapEnabled = null;
  this.ActualCashValue = null;
  this.Address = new Address();
  this.Amenities = [];
  this.AnnualMileageDriven = null;
  this.AssetCost = null;
  this.AssetStatus = null;
  this.AssetStatus = null;
  this.AssetType = null;
  this.AssetTypeName = null;
  this.AvailabilityStatus = null;
  this.BufferTimeDbInMinutes = null;
  this.BufferTimeInMinutes = null;
  this.Category = new KeyValuePair();
  this.Color = null;
  this.CommissionRate = null;
  this.CurrentLatitude = null;
  this.CurrentLongitude = null;
  this.Deductibles = new KeyValuePair();
  this.Device = new DeviceInfo();
  this.DeviceInstalled = null;
  this.DisclaimerText = null;
  this.DoorsFeatureEnabled = null;
  this.DisplayInJsiMarketPlace = null;
  this.DriveType = null;
  this.EngineCC = null;
  this.EventStatusLastUpdateTime = null;
  this.ExecutionMode = null;
  this.ExecutionModeKey = null;

  this.FlickerLightsHornFeatureEnabled = null;
  this.FuelIncluded = null;
  this.FuelMeterFeatureEnabled = null;
  this.FuelType = null;
  this.FuelUnit = null;
  this.GetPaidOption = null;
  this.HoodFeatureEnabled = null;
  this.IgnitionFeatureEnabled = null;
  this.Images = [];
  this.ImmobilizationFeatureEnabled = null;
  this.ImmobilizationStatus = null;
  this.InReservation = null;
  this.InstantRentalAllowed = null;
  this.InsuranceCarrier = null;
  this.InsuranceIncluded = null;
  this.JsiLicenseRequired = null;
  this.LastPositionUpdateTime = null;
  this.LastReservationActualEndTime = null;
  this.LastReservationBorrowerId = null;
  this.LastReservationBorrowerId = null;

  this.LastReservationBorrowerName = null;
  this.LastReservationCode = null;
  this.LastReservationId = null;
  this.LastReservationPromisedEndTime = null;
  this.LastReservationPromisedStartTime = null;
  this.LastReservationTotalPaid = null;
  this.DoubleCheckedVinAck = null;

  this.Latitude = null;
  this.LeadTimeDbInMinutes = null;
  this.LeadTimeInMinutes = null;
  this.LicenseNo = null;

  this.LicenseRequired = null;
  this.LicenseValidatedByState = null;

  this.LockFeatureEnabled = null;
  this.LockStatus = null;
  this.Longitude = null;
  this.Make = null;

  this.MaxFuelCapacity = null;
  this.MileageAtSignup = null;

  this.MilesPerGallon = null;
  this.MinimumDeductible = null;
  this.MinimumDeductibleCost = null;
  this.Model = null;
  this.Name = null;
  this.NotifyMapUrl = null;
  this.OverallRating = null;
  this.OwnerEmail = null;
  this.OwnerFormattedHomePhone = null;
  this.OwnerFormattedMobilePhone = null;
  this.OwnerFormattedWorkPhone = null;
  this.OwnerId = null;
  this.OwnerName = null;
  this.OwnerNotes = null;
  this.PanicFeatureEnabled = null;
  this.ParkedText = null;
  this.ParkedTextKey = null;

  this.ParkingAddressCityId = null;
  this.ParkingAddressCountryId = null;
  this.ParkingAddressStateId = null;
  this.ParkingAddressStreet1 = null;
  this.ParkingAddressZipCodeId = null;
  this.ParkingDistanceOption = null;
  this.ParkingDistanceOptionKey = null;
  this.ParkingNotes = null;
  this.PersonAssetId = null;
  this.PersonAssetOrgUnitId = null;
  this.PolicyNumber = null;
  this.RatePerAdditionalDay = null;

  this.RatePerDay = null;
  this.RatePerHalfDay = null;
  this.RatePerHour = null;
  this.RatePerMonth = null;
  this.RatePerWeek = null;
  this.RateType = null;
  this.RatingCount = null;
  this.RemoteStartFeatureEnabled = null;
  this.RemoteStartStatus = null;
  this.RemoteStopFeatureEnabled = null;
  this.RemoteStartStatus = null;
  this.RemoteStopFeatureEnabled = null;
  this.ReservationStatus = null;
  this.Reviews = [];
  this.RoadsideAssistance = null;
  this.SeatBeltsFeatureEnabled = null;
  this.SeatingCapacity = null;
  this.SharerRatePerAdditionalDay = null;
  this.SharerRatePerDay = null;
  this.SharerRatePerHalfDay = null;

  this.SharerRatePerHour = null;
  this.SharerRatePerMonth = null;
  this.SharerRatePerWeek = null;
  this.StarterKillFeatureEnabled = null;
  this.StarterKillStatus = null;
  this.StatusCode = null;
  this.StatusMsg = null;

  this.SubVehicleType = new KeyValuePair();
  this.TachometerFeatureEnabled = null;
  this.TachometerFeatureEnabled = null;
  this.TotalEarningsToDate = null;

  this.TotalOwnerFines = null;
  this.TotalPendingRequest = null;

  this.TotalPendingReservation = null;
  this.TotalRentedHours = null;
  this.TransmissionType = null;
  this.TransmissionTypeKey = null;

  this.Trim = null;
  this.TrunkFeatureEnabled = null;

  this.URL = null;
  this.UnitNo = null;
  this.UnlockFeatureEnabled = null;
  this.VehicleAddedOn = null;

  this.VehicleYear = null;
  this.VinNumber = null;
};
export default Vehicle;

import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Draggable from "react-draggable";
import { formatAmountValue } from "../../../../shared/utility";
import { LabelAndValuePairControl } from "../../../../components/UI/Input/label-value-pair-control";

const AssetSummary = (props) => {
  const assetSummaryData = props.assetSummaryData;

  const renderLabelInputPair = (labelCaption, value, isCurreny) => {
    value = isCurreny ? (value ? formatAmountValue(value) : "--") : value;
    return (
      <LabelAndValuePairControl
        elementConfig={{ value: value }}
        otherConfig={{
          caption: labelCaption,
        }}
        parentRowClass="popup-row"
        labelClasses="font-weight-bold"
      />
    );
  };

  const renderData = () => {
    return (
      <div className="input-container">
        {renderLabelInputPair(
          "Current Position",
          assetSummaryData.CurrentLatitude && assetSummaryData.CurrentLongitude
            ? "Latitude: " +
                assetSummaryData.CurrentLatitude +
                " , Longitude: " +
                assetSummaryData.CurrentLongitude
            : "--"
        )}
        {renderLabelInputPair("Make", assetSummaryData.Make)}
        {renderLabelInputPair("Model", assetSummaryData.Model)}
        {renderLabelInputPair(
          "Reservation Pending",
          assetSummaryData.TotalPendingReservation
        )}
        {renderLabelInputPair(
          "Request Pending",
          assetSummaryData.TotalPendingRequest
        )}
        {renderLabelInputPair(
          "Current Fuel Status (Percent)",
          assetSummaryData.CurrentFuelStatusInPercentage || 0 + "%"
        )}
        {renderLabelInputPair(
          "Total Fuel",
          assetSummaryData.TotalFuelLeft
            ? assetSummaryData.TotalFuelLeft +
                " " +
                assetSummaryData.FuelConsumptionUnit
            : "--"
        )}
        {renderLabelInputPair(
          "Total Max Fuel Capacity",
          assetSummaryData.MaxFuelCapacity
            ? assetSummaryData.MaxFuelCapacity +
                " " +
                assetSummaryData.FuelConsumptionUnit
            : "--"
        )}
        {renderLabelInputPair("Total Mileage", assetSummaryData.TotalMileage)}
        {renderLabelInputPair(
          "Total Earning Last Year",
          assetSummaryData.TotalEarningsLastYear,
          true
        )}
        {renderLabelInputPair(
          "Total Earning Last Month",
          assetSummaryData.TotalEarningsLastMonth,
          true
        )}
        {renderLabelInputPair(
          " Total Earning Current Month",
          assetSummaryData.TotalEarningsCurrentMonth,
          true
        )}
        {renderLabelInputPair(
          "Total Earning To Date",
          assetSummaryData.TotalEarningsToDate,
          true
        )}
        {renderLabelInputPair("Total Fine", assetSummaryData.TotalFine, true)}
        {renderLabelInputPair(
          "Total Rented Hours",
          assetSummaryData.TotalRentedHours
        )}
      </div>
    );
  };

  return (
    <div>
      <Draggable handle=".cursor">
        <Modal
          isOpen={props.showModal}
          className="no-cursor"
          toggle={() => {
            props.closeModal();
          }}
        >
          <ModalHeader
            className="modal-header bg-primary modal-title text-white cursor"
            toggle={() => {
              props.closeModal();
            }}
          >
            <i className="fas fa-arrows-alt" /> {props.title}
          </ModalHeader>
          <ModalBody>
            <div
              className="form-group row"
              style={{
                overflow: "auto",
              }}
            >
              <div className="FormRow col-sm-12">{renderData()}</div>
            </div>
          </ModalBody>
        </Modal>
      </Draggable>
    </div>
  );
};

export default AssetSummary;

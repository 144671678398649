import * as actionTypes from "./actionTypes";
import {Utility} from "../../shared/utility";
import HttpService from "../../services/httpService";

/**get current address loader */
export const getCurrentAddressLoader = () => {
  return {
    type: actionTypes.GET_ADDRESS_LOADER,
  };
};
/**GET COUNTRIES */
export const getAddressStart = (type) => {
  return {
    type: type+"_START",
  };
};
export const getAddressFail = (type, error) => {
  return {
    type: type+"_FAIL",
    error: error
  };
};
export const getAddressReset = (type) => {
  return {
    type: type+"_RESET",
  };
};
/**GET COUNTRIES */
export const getCountriesSuccess = (type, countriesResponse, isFromHttp, isFoxAPI) => {
  const countryData = {
    statusCode: 0,
    statusMsg: null,
    countries:[]
  };
  if (isFromHttp) {
    if (countriesResponse.StatusCode === 0) {
      Utility.countryStateCityPostal.countries_fox = countriesResponse.Countries.map(
        country => ({
          keyValue: isFoxAPI ? country.CountryCode : country.CountryId,
          displayValue: country.CountryName
        })
      );
      countryData.countries = Utility.countryStateCityPostal.countries_fox.slice();
    } else {
      countryData.statusCode = countriesResponse.StatusCode;
      countryData.statusMsg = countriesResponse.StatusMsg;
    }
  } else {
     countryData.countries = Utility.countryStateCityPostal.countries_fox.slice();
  }
  return {
    type: type+"_SUCCESS",
    countriesResponse: countryData
  };
};
export const getCountries = (type, isFoxAPI) => {
  return dispatch => {
    dispatch(getAddressStart(type));
    if (Utility.countryStateCityPostal.countries_fox.length > 0) {
      dispatch(getCountriesSuccess(type));
    } else {
      const conutryReq = HttpService.getCommonDataForRequest();
      HttpService.getCountries(conutryReq)
        .then(response => {
          dispatch(getCountriesSuccess(type, response, true,isFoxAPI));
        })
        .catch(err => {
          //dispatch(getAddressFail(type,err));
        });
    }
  };
};
/**GET STATES */
export const getStatesSuccess = (
  type,
  statesResponse,
  country,
  isFromHttp,
  isFoxAPI
) => {
  const stateData = {
    states: [],
    statusCode: 0,
    statusMsg: null,
    country: isFoxAPI ? country : Number(country),
  };
  if (isFromHttp) {
    if (statesResponse.StatusCode === 0) {
      Utility.countryStateCityPostal.states_fox = statesResponse.States.map(
        data => ({
          keyValue: isFoxAPI ? data.Key : data.Value,
          displayValue: data.Key
        }));
      stateData.states = Utility.countryStateCityPostal.states_fox.slice();
    } else {
      stateData.statusCode = statesResponse.StatusCode;
      stateData.statusMsg = statesResponse.StatusMsg;
    }
  } else {
    stateData.states =  statesResponse.slice();
  }
  return {
    type: type+"_SUCCESS",
    statesResponse: stateData
  };
};
export const getStates = (type, country, isFoxAPI) => {
  return dispatch => {
    dispatch(getAddressStart(type));
    const states = Utility.countryStateCityPostal.states_fox.filter((state)=> {
      return state.country === isFoxAPI ? country : Number(country);
    });
    if (states.length > 0) {
      dispatch(getStatesSuccess(type, states, country));
    } else {
      const req = HttpService.getCommonDataForRequest();
      if(isFoxAPI){
        req.SelectedCountryCode = country;
      }else{
        req.SelectedCountryId = Number(country);
      }
      HttpService.getStatesCitiesPostals(req)
        .then(response => {
          dispatch(getStatesSuccess(type, response, country, true, isFoxAPI));
        })
        .catch(err => {
        //dispatch(getAddressFail(type,err));
        });
    }
  };
};
/**GET CITIES */
export const getCitiesSuccess = (
  type,
  citiesResponse,
  country,
  state,
  isFromHttp,
  isFoxAPI
) => {
  const cityData = {
    cities: [],
    statusCode: 0,
    statusMsg: null,
    country: isFoxAPI ? country : Number(country),
    state: isFoxAPI ? state : Number(state),
  };
  if (isFromHttp) {
    if (citiesResponse.StatusCode === 0 && citiesResponse.Cities) {
      Utility.countryStateCityPostal.cities_fox = citiesResponse.Cities.map(
        data => ({
          keyValue: isFoxAPI ? data.Key : data.Value,
          displayValue: data.Key,
        }));
        cityData.cities = Utility.countryStateCityPostal.cities_fox.slice();
    } else {
      cityData.statusCode = citiesResponse.StatusCode;
      cityData.statusMsg = citiesResponse.StatusMsg;
    }
  } else {
    cityData.cities = citiesResponse.slice();
  }
  return {
    type: type+"_SUCCESS",
    citiesResponse: cityData ? cityData : null
  };
};
export const getCities = (type, country, state, isFoxAPI) => {
  return dispatch => {
    dispatch(getAddressStart(type));
    const cities = Utility.countryStateCityPostal.cities_fox.filter(function(city) {
      return city.state === isFoxAPI ? state : Number(state);
    });
    if (cities.length > 0) {
      dispatch(getCitiesSuccess(type, cities, country, state));
    } else {
      const req = HttpService.getCommonDataForRequest();
      if(isFoxAPI){
        req.SelectedCountryCode = country;
        req.SelectedStateName = state;
      }else{
        req.SelectedCountryId = Number(country);
        req.SelectedStateId = Number(state);
      }
      HttpService.getStatesCitiesPostals(req)
        .then(response => {
          dispatch(getCitiesSuccess(type, response, country, state, true, isFoxAPI));
        })
        .catch(err => {
        //dispatch(getCountriesFail(type,err));
        });
    }
  };
};
/**GET POSTALS */
export const getPostalsSuccess = (
  type,
  postalsResponse,
  country,
  state,
  city,
  isFromHttp,
  isFoxAPI
) => {
  const postalData = {
    postals: [],
    statusCode: 0,
    statusMsg: null,
    country: isFoxAPI ? country : Number(country),
    state: isFoxAPI ? state : Number(state),
    city: isFoxAPI ? city : Number(city)
  };
  if (isFromHttp) {
    if (postalsResponse.StatusCode === 0 && postalsResponse.ZipCodes) {
      Utility.countryStateCityPostal.postals_fox = postalsResponse.ZipCodes.map(
        data => ({
          keyValue: isFoxAPI ? data.Key : data.Value,
          displayValue: data.Key
        }));
        postalData.postals = Utility.countryStateCityPostal.postals_fox.slice();
    } else {
      postalData.statusCode = postalsResponse.StatusCode;
      postalData.statusMsg = postalsResponse.StatusMsg;
    }
  } else {
    postalData.postals = postalsResponse.slice();
  }
  return {
    type: type+"_SUCCESS",
    postalsResponse: postalData ? postalData : null
  };
};
export const getPostals = (type, country, state, city, isFoxAPI) => {
  return dispatch => {
    dispatch(getAddressStart(type));
    const postals = Utility.countryStateCityPostal.postals_fox.filter(function(
      postal
    ) {
      return postal.city == isFoxAPI ? city : Number(city);
    });
    if (postals.length > 0) {
      dispatch(getPostalsSuccess(type, postals, country, state, city));
    } else {
      const req = HttpService.getCommonDataForRequest();
      if(isFoxAPI){
        req.SelectedCountryCode = country;
        req.SelectedStateName = state;
        req.SelectedCityName = city;
      }else{
        req.SelectedCountryId = Number(country);
        req.SelectedStateId = Number(state);
        req.SelectedCityId = Number(city);
      }
      HttpService.getStatesCitiesPostals(req)
        .then(response => {
          dispatch(
            getPostalsSuccess(type, response, country, state, city, true, isFoxAPI)
          );
        })
        .catch(err => {
          //dispatch(getAddressFail(type,err));
        });
    }
  };
};


import React, { useState } from "react";
import ReactHtmlParser from "react-html-parser";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Draggable from "react-draggable";
import moment from "moment";
import DateTimePicker from "react-widgets/lib/DateTimePicker";
import { updateObject, getErrorToastData } from "../../../../shared/utility";
import { showNotification } from "../../../../components/UI/Toaster/Toaster";
import SpecialPricing from "../../../../services/entities/models/SpecialPricing";
import { isNullOrEmpty } from "../../../../shared/utility";
import { LabelAndValuePairControl } from "../../../../components/UI/Input/label-value-pair-control";
import { validationRateSettings } from "../../settingsHelper";

export const SpecialRateModal = (props) => {
  const entity =
    props.selectedEntity || new SpecialPricing(props.specialPricingData);
  const [updateEntity, setUpdateEntity] = useState(entity);
  const [isValidateDone, setIsValidateDone] = useState(false);
  const handleOnChange = (event, controlName, elementType) => {
    let value = null;
    if (event !== "Invalid date" && elementType === "datetimepicker") {
      value = event ? moment(event).format("MM:DD:YYYY:HH:mm") : event;
    } else {
      value = event.target.value;
    }
    let updateCtrls = updateObject(updateEntity, {
      [controlName]: value,
    });
    setUpdateEntity(updateCtrls);
  };
  const updateData = () => {
    let msg = validationRateSettings(updateEntity);
    if (msg) {
      showNotification(getErrorToastData(msg));
      setIsValidateDone(true);
    } else {
      setIsValidateDone(false);
      props.addUpdateEntity(updateEntity);
    }
  };
  const renderLabelInputPair = (
    labelCaption,
    controlName,
    isRequired = false,
    nodeElement = "input",
    dropdownItem = null
  ) => {
    let updateEntityNew = { ...updateEntity };
    const value = updateEntityNew[controlName];
    const isNumberField = [
      "RatePerHour",
      "RatePerDay",
      "RatePerWeekendDay",
      "RatePerWeek",
      "RatePerMonth",
    ].includes(controlName);
    const minValueAndStepAttr = isNumberField ? { min: "0", step: "1" } : {};
    return (
      <LabelAndValuePairControl
        elementConfig={{
          name: controlName,
          value: value,
          type: isNumberField ? "number" : null,
          ...minValueAndStepAttr,
        }}
        otherConfig={{
          caption: labelCaption,
          isRequired: isRequired,
          dropdownItem: nodeElement === "select" ? dropdownItem : [],
          value: value,
          dateFormat: "D",
        }}
        isEditMode={true}
        onChange={handleOnChange}
        parentRowClass={`form-group row  ${isRequired ? "required" : ""}`}
        labelClasses="col-sm-4 col-form-label font-weight-bold"
        valueClasses="col-sm-8"
        nodeElement={nodeElement}
        isValidateDone={isValidateDone}
      />
    );
  };
  return (
    <Draggable handle=".cursor">
      <Modal
        isOpen={props.showModal}
        className="no-cursor"
        toggle={() => {
          props.closeModal();
        }}
      >
        <ModalHeader
          className="modal-header bg-primary modal-title text-white cursor"
          toggle={() => {
            props.closeModal();
          }}
        >
          <i className="fas fa-car-crash pr-2"></i>
          Add / Update Special Rate
        </ModalHeader>
        <ModalBody>
          <div className="col-sm-12 p-0">
            {renderLabelInputPair(
              "Start Date",
              "StartDate",
              true,
              "datetime",
              null
            )}
            {renderLabelInputPair(
              "End Date",
              "EndDate",
              true,
              "datetime",
              null
            )}
            {renderLabelInputPair("Hourly Rate", "RatePerHour")}
            {renderLabelInputPair("Daily Rate", "RatePerDay")}
            {renderLabelInputPair("Weekend Rate", "RatePerWeekendDay")}
            {renderLabelInputPair("Weekly Rate", "RatePerWeek")}{" "}
            {renderLabelInputPair("Monthly Rate", "RatePerMonth")}
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="col-sm-12 text-right">
            <button
              type="button"
              name="btnYes"
              className="btn btn-primary waves-effect waves-light btnSize ml-2"
              onClick={() => updateData()}
            >
              {updateEntity.OrgTaxId ? "Update" : "Submit"}
            </button>
            <button
              type="button"
              name="btnCancel"
              className="btn btn-secondary waves-effect waves-light btnSize ml-2"
              onClick={() => props.closeModal()}
            >
              Cancel
            </button>
          </div>
        </ModalFooter>
      </Modal>
    </Draggable>
  );
};
export default SpecialRateModal;

import React from 'react';
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import "./modern-modal.scss";

function CommonModal(props) {
    const {
        closeModal,
        actionLbl,
        modalTitle,
        onAction,
        setData,
        //modaWrapperClass,
        children
    } = props;

    return (
        <>
            <ModalHeader
                className="modal-header modal-title cursor"
                toggle={() => {
                    closeModal(setData);
                }}
            >
                {modalTitle}
            </ModalHeader>
            <ModalBody>
                {/* <div className={modaWrapperClass}> */}
                {children}
                {/* </div> */}
            </ModalBody>
            {onAction ?
                <ModalFooter>
                    <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={closeModal}
                    >
                        Cancel
                    </button>
                    <button
                        type="button"
                        className="btn btn-primary waves-effect"
                        onClick={onAction}
                    >
                        {actionLbl}
                    </button>
                </ModalFooter>
                : ""}
        </>
    )
}

export default CommonModal
import * as actionTypes from "./actionTypes";
import HttpService, { invokeServerAPI } from "../../services/httpService";

export const getFeedbackQuestionsStart = () => {
  return {
    type: actionTypes.GET_FEEDBACK_QUESTIONS_START,
  };
};
export const getFeedbackQuestionsSuccess = (response) => {
  return {
    type: actionTypes.GET_FEEDBACK_QUESTIONS_SUCCESS,
    feedbackQuestionsResponse: response,
  };
};
export const getFeedbackQuestionsFail = (error) => {
  return {
    type: actionTypes.GET_FEEDBACK_QUESTIONS_FAIL,
    error: error,
  };
};
export const getFeedbackQuestionsReset = () => {
  return {
    type: actionTypes.GET_FEEDBACK_QUESTIONS_RESET,
  };
};
export const getFeedbackDetailsStart = () => {
  return {
    type: actionTypes.GET_FEEDBACK_DETAILS_START,
  };
};
export const getFeedbackDetailsSuccess = (response) => {
  return {
    type: actionTypes.GET_FEEDBACK_DETAILS_SUCCESS,
    feedbackDetailsResponse: response,
  };
};
export const getFeedbackDetailsFail = (error) => {
  return {
    type: actionTypes.GET_FEEDBACK_DETAILS_FAIL,
    error: error,
  };
};
export const getFeedbackDetailsReset = () => {
  return {
    type: actionTypes.GET_FEEDBACK_DETAILS_RESET,
  };
};

export const addFeedBackStart = () => {
  return {
    type: actionTypes.ADD_FEEDBACK_START,
  };
};
export const addFeedBackSuccess = (response) => {
  return {
    type: actionTypes.ADD_FEEDBACK_SUCCESS,
    addFeedbackResponse: response,
  };
};
export const addFeedBackFail = (error) => {
  return {
    type: actionTypes.ADD_FEEDBACK_FAIL,
    error: error,
  };
};
export const addFeedBackReset = () => {
  return {
    type: actionTypes.ADD_FEEDBACK_RESET,
  };
};

export const getFeedbackQuestions = () => {
  return (dispatch) => {
    dispatch(getFeedbackQuestionsStart());
    invokeServerAPI("getFeedbackQuestions")
      .then((response) => {
        dispatch(getFeedbackQuestionsSuccess(response));
      })
      .catch((err) => {
        //dispatch(getFeedbackQuestionsFail(err));
      });
  };
};
export const getFeedbackDetails = (req) => {
  return (dispatch) => {
    dispatch(getFeedbackDetailsStart());
    invokeServerAPI("getfeedbackdetails", req)
      .then((response) => {
        dispatch(getFeedbackDetailsSuccess(response));
      })
      .catch((err) => {
        //dispatch(getFeedbackDetailsFail(err));
      });
  };
};
export const addFeedBack = (req) => {
  return (dispatch) => {
    dispatch(addFeedBackStart());
    invokeServerAPI("addfeedback", req)
      .then((response) => {
        dispatch(addFeedBackSuccess(response));
      })
      .catch((err) => {
        //dispatch(addFeedBackFail(err));
      });
  };
};

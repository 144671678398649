import React, { useEffect, useState } from 'react';
import { Utility } from '../../../shared/utility';
import { CountryKeyValueType, DefaultAddressProperty } from '../../../shared/utility/enum-utility';
import { getKeyValFromList } from '../../../shared/utility/list-utility';
import InputCtrl from '../../UI/Input/input-ctrl';
import InputWidgetCtrl from '../../UI/Input/input-widget-ctrl';

function CityCtrl(props) {
    const { name, value, defaultValue, placeholder, disabled, imgIco, countryCode, stateCode,
        addressData,
        addressType,
        stateCityLoader,
        onChange,
        clearSecurityDepositRate } = props;
    const [cities, setCities] = useState([]);
    const [loader, setLoader] = useState(false);
    useEffect(() => {
        if ((countryCode === CountryKeyValueType.US || countryCode === CountryKeyValueType.IN) && stateCode) {
            loadCities();
        }
        if (!stateCode) {
            setCities([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [countryCode, stateCode])
    const onHandleChange = (event) => {
        if (disabled || !onChange) return false;
        const val = event.keyValue;
        if (addressData && addressType) {
            const tempData = { ...addressData };
            tempData[name] = val;
            tempData[DefaultAddressProperty?.postalCode] = null;
            onChange(tempData, addressType);
        }
        else {
            if (clearSecurityDepositRate)
                clearSecurityDepositRate();
            onChange(val, name);
        }
    }
    /**TO DO: need to change country sotrage and data array list process */
    const loadCities = async () => {
        try {
            setLoader(true);
            Utility.countryStateCityPostal.getCities(
                countryCode,
                stateCode,
                true,
                (cityResponse) => {
                    setCities(cityResponse?.Cities);
                }
            );
        } catch (e) {
            setLoader(false);
        }
    }
    return (
        <div>
            {cities?.length > 0 ?
                <InputWidgetCtrl
                    id={name}
                    name={name}
                    placeholder={placeholder}
                    data={cities}
                    valueField="keyValue"
                    textField="displayValue"
                    value={getKeyValFromList(
                        cities,
                        value,
                        defaultValue,
                        "keyValue"
                    )}
                    onChange={(event) => onHandleChange(event)}
                    disabled={disabled}
                    busy={loader}
                    imgIco={imgIco}
                /> :
                <InputCtrl
                    type="text"
                    name={name}
                    value={value}
                    placeholder={placeholder}
                    onChange={onChange}
                    imgIco={imgIco}
                    disabled={disabled}
                    loader={stateCityLoader}
                />
            }
        </div>
    )
}

export default CityCtrl
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as XLSX from "xlsx";
import {
  getBlackListedPersonInfos,
  addBlackListedPersonInfos,
  updateBlackListedPersonInfos,
  deleteBlackListedPersonInfos,
  getBlackListedPersonInfosReset,
  addBlackListedPersonInfosReset,
  updateBlackListedPersonInfosReset,
  deleteBlackListedPersonInfosReset,
} from "../../../store/actions/index";
import Spinner from "../../../components/UI/Spinner/Spinner";
import {
  getBasicToastResponse,
  getFormattedAddress,
  getNormalFormattedPhoneNumber,
} from "../../../shared/utility";
import { showNotification } from "../../../components/UI/Toaster/Toaster";
import BlackListedPersonInfosViewModel from "../../../services/entities/view-models/black-listed-person-list-view-model";
import LinkButton from "../../../components/UI/button/link-btn";
import ConfirmModal from "../../../components/common/modal/confirm-modal";
import NoRecordFoundComponent from "../../../components/common/pages/no-record-found";
import { COMMON_MSG, ModalType, TOAST_MSG } from "../../../shared/GlobalVar";
import {
  getFullName,
  getDateOnlyString,
} from "../../../shared/utility";
import MemberModalEngine from "../../Member/modal/member-modal-engine";
import ModalInfo from "../../../services/entities/models/ModalInfo";
import { AddQuickMemberViewModel } from "../../../services/entities/view-models/AddQuickMemberViewModel";
import BlackListedExportModal from "./modal/black-listed-import-modal";
import { createTooltip } from "../../../shared/utility/tooltip-utility";
const BlackListedPersonsSettings = (props) => {
  const currentOrgUnitId = useSelector(
    (state) => state.auth.loggedInUserData.CurrentOrgUnitId
  );
  const query = new URLSearchParams(props.location.search);
  const selectedOrgLocationId =
    props.otherData && props.otherData.selectedOrgLocationId
      ? props.otherData.selectedOrgLocationId
      : query.get("orgId") || currentOrgUnitId;

  const loading = useSelector(
    (state) => state.blackListedPersonsListReducer.loading
  );
  const blackListedPersonsListResponse = useSelector(
    (state) =>
      state.blackListedPersonsListReducer.blackListedPersonsListResponse
  );
  const addBlackListedPersonInfosResponse = useSelector(
    (state) =>
      state.blackListedPersonsListReducer.addBlackListedPersonInfosResponse
  );
  const updateBlackListedPersonInfosResponse = useSelector(
    (state) =>
      state.blackListedPersonsListReducer.updateBlackListedPersonInfosResponse
  );
  const deleteBlackListedPersonInfosResponse = useSelector(
    (state) =>
      state.blackListedPersonsListReducer.deleteBlackListedPersonInfosResponse
  );

  const dispatch = useDispatch();
  const [customLoading, setCustomLoading] = useState(false);
  const [blackListedPersonsList, setBlackListedPersonsList] = useState(
    new BlackListedPersonInfosViewModel()
  );
  const [modalInfo, setModaInfo] = useState(new ModalInfo());
  const [selectedBlackListedPersonEntity, setSelectedBlackListedPersonEntity] =
    useState(new BlackListedPersonInfosViewModel());
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [importedXLData, setImportedXLData] = useState(null);
  const [showBlackListedImportModal, setShowBlackListedImportModal] =
    useState(false);

  useEffect(() => {
    getBlackListedPersonList();
  }, [selectedOrgLocationId]);
  useEffect(() => {
    if (!blackListedPersonsListResponse) {
      return;
    }
    if (blackListedPersonsListResponse.StatusCode === 0) {
      setBlackListedPersonsList(
        new BlackListedPersonInfosViewModel(blackListedPersonsListResponse)
      );
    }
  }, [blackListedPersonsListResponse]);
  useEffect(() => {
    if (!addBlackListedPersonInfosResponse) {
      return;
    }
    showNotification(getBasicToastResponse(addBlackListedPersonInfosResponse));
    if (addBlackListedPersonInfosResponse.StatusCode === 0) {
      dispatch(addBlackListedPersonInfosReset());
      getBlackListedPersonList();
      closeBlackListedExcelModal();
    }
    dispatch(addBlackListedPersonInfosReset());
  }, [addBlackListedPersonInfosResponse]);
  useEffect(() => {
    if (!deleteBlackListedPersonInfosResponse) {
      return;
    }
    showNotification(
      getBasicToastResponse(deleteBlackListedPersonInfosResponse)
    );
    if (deleteBlackListedPersonInfosResponse.StatusCode === 0) {
      closeConfirmModal();
      getBlackListedPersonList();
    }
    dispatch(deleteBlackListedPersonInfosReset());
  }, [deleteBlackListedPersonInfosResponse]);
  const getConfirmModal = (entity) => {
    if (entity) {
      setSelectedBlackListedPersonEntity(entity);
    }
    setShowConfirmModal(true);
  };
  const closeConfirmModal = () => {
    setShowConfirmModal(false);
  };
  const showModal = (modalData) => {
    setModaInfo({
      type: modalData.type,
      showModal: true,
      memberId: modalData.memberId || null,
      addQuickMemberInput:
        modalData.addQuickMemberInput || new AddQuickMemberViewModel(),
    });
  };
  const closeModal = () => {
    setModaInfo(new ModalInfo());
  };
  const showConflictedMemberModal = (addQuickMemberReq) => {
    closeModal();
    showModal({
      type: ModalType.CONFILICTED_MEMBER_MODAL,
      addQuickMemberInput: { ...addQuickMemberReq },
    });
  };
  const getBlackListedPersonList = () => {
    const req = {
      SelectedChildOrgUnitId: selectedOrgLocationId || currentOrgUnitId,
      PageCount: 0,
      RecordPerPage: 100,
    };
    dispatch(getBlackListedPersonInfosReset());
    dispatch(getBlackListedPersonInfos(req));
  };
  const showNewBlackListModal = () => {
    showModal({
      type: ModalType.ADD_MEMBER_QUICK_MODAL,
      addQuickMemberInput: new AddQuickMemberViewModel(),
    });
  };
  const addBlackListedPersons = (blackList) => {
    const req = {
      BlackList: blackList,
      SelectedChildOrgUnitId: selectedOrgLocationId || currentOrgUnitId,
    };
    dispatch(addBlackListedPersonInfosReset());
    dispatch(addBlackListedPersonInfos(req));
  };
  const updateBlackListedPersonEntity = () => {
    const req = {
      SelectedChildOrgUnitId: selectedOrgLocationId || currentOrgUnitId,
    };
    dispatch(updateBlackListedPersonInfosReset());
    dispatch(updateBlackListedPersonInfos(req));
  };
  const deleteBlackListedPersonEntity = () => {
    const req = {
      PersonBlackListId: selectedBlackListedPersonEntity.PersonBlackListId,
    };
    dispatch(deleteBlackListedPersonInfosReset());
    dispatch(deleteBlackListedPersonInfos(req));
  };
  const resetLoader = (isLoading) => {
    setCustomLoading(isLoading);
  };
  const clickFileUploaderCtrl = () => {
    document.getElementById("blackListedFileUploader").click();
  };
  const validFileTypes = ["xlsx", "xlsb", "xlsm", "xls", "xml", "csv", "txt"];
  const validateFile = (fileObj) => {
    let msg = null;
    if (validFileTypes.includes(fileObj.type)) {
      msg = msg
        ? msg + " Only doc file is acceptable."
        : "Only doc file is acceptable.";
    }
    if (msg) {
      var toastMsg = {
        msg: msg,
        toastType: TOAST_MSG.ERROR,
      };
      showNotification(toastMsg);
      return false;
    }
    return true;
  };
  const loadNewFile = (event) => {
    const fileObj = event.target.files[0];
    if (validateFile(fileObj)) {
      const reader = new FileReader();
      const rABS = !!reader.readAsBinaryString;
      reader.onload = ({ target: { result } }) => {
        const wb = XLSX.read(result, { type: rABS ? "binary" : "array" });
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        /* Convert array of arrays */
        const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
        setImportedXLData(convertToJson(data));
        setBlackListedExcelModal();
      };
      if (rABS) reader.readAsBinaryString(fileObj);
      else reader.readAsArrayBuffer(fileObj);
      document.getElementById("blackListedFileUploader").value = "";
    }
  };
  const convertToJson = (csv) => {
    var lines = csv.split("\n");

    var result = [];

    var headers = lines[0].split(",");

    for (var i = 1; i < lines.length; i++) {
      var obj = {};
      var currentline = lines[i].split(",");
      if (result.length + 2 < lines.length) {
        for (var j = 0; j < headers.length; j++) {
          obj[headers[j]] = currentline[j];
        }
        result.push(obj);
      }
    }
    return result; //JavaScript object
  };
  const setBlackListedExcelModal = () => {
    setShowBlackListedImportModal(true);
  };
  const closeBlackListedExcelModal = () => {
    setShowBlackListedImportModal(false);
  };
  return (
    <div className="col-lg-12 pt-3 pl-0" id="main-settings">
      <div className="card">
        <div className="card-body">
          <div className="form-header row col-lg-12 p-0">
            <h4 className="text-uppercase mr-auto icon-display-none">
              <i className="fas fa-car"></i>
              Do Not Rent List
            </h4>
            <LinkButton
              btnLabel="Add New"
              containerClass="pseudo-link pr-1 fs-2 font-weight-bold mr-5 d-flex align-items-center"
              iconClass="fas fa-plus-circle iconBeforeLink"
              clicked={showNewBlackListModal}
            />
            <LinkButton
              btnLabel="Import"
              containerClass="pseudo-link pr-1 fs-2 font-weight-bold mr-5 d-flex align-items-center"
              stackClass="fa-stack fs-08 vertical-align-top"
              faIconList={[
                "fa fa-circle fa-stack-2x iconBeforeLink",
                "fa fa-upload fa-stack-1x whiteColor pr-1",
              ]}
              clicked={clickFileUploaderCtrl}
            />
          </div>
          <div className="col-lg-12 tableScroll bb-0">
            <table className="table table-striped">
              <thead className="thead-primary">
                <tr>
                  <th>Member Id</th>
                  <th>Name</th>
                  <th>Date of Birth</th>
                  <th>Email / User Name</th>
                  <th>Driver's Lice.</th>
                  <th>Address</th>
                  <th>Phone</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {blackListedPersonsList.BlackListedPersonData.length === 0 ? (
                  <tr>
                    <td colSpan="8">
                      <NoRecordFoundComponent
                        className="border-none"
                        innerClassName="no-setting-record-found"
                        searchMsg="No Record Found"
                        heights="5rem"
                      />
                    </td>
                  </tr>
                ) : (
                  blackListedPersonsList.BlackListedPersonData.map((entity) => {
                    return (
                      <tr key={entity.MembershipId}>
                        <td>{entity.MembershipId}</td>
                        <td>
                          {getFullName(
                            entity.FirstName,
                            entity.LastName,
                            entity.MiddleName
                          )}
                        </td>
                        <td>{getDateOnlyString(entity.DateOfBirth, "--")}</td>
                        <td>
                          {createTooltip(
                            <a
                              href={"mailto:" + entity.EmailAddress}
                              id={"email_" + entity.PersonId}
                            >
                              {entity.EmailAddress}
                            </a>,
                            entity.EmailAddress || "--"
                          )}
                        </td>
                        <td>{entity.LicenseNo || "--"}</td>
                        <td>
                          {getFormattedAddress({
                            CountryText: entity.CountryText,
                            StateText: entity.StateText,
                            CityText: entity.CityText,
                            ZipCodeText: entity.ZipCodeText,
                          })}
                        </td>
                        <td>
                          {getNormalFormattedPhoneNumber(entity.MobilePhone)}
                        </td>
                        <td className="text-center">
                          <i
                            className="resDriverDel fa fa-times add-driver-remove "
                            aria-hidden="true"
                            onClick={() => getConfirmModal(entity)}
                          />
                          {/* <i
                            className="resDriverDel fa fa-times add-driver-remove "
                            aria-hidden="true"
                            onClick={() =>
                              showModal({
                                type: ModalType.ADD_MEMBER_QUICK_MODAL,
                                memberId: entity.MemberId,
                                addQuickMemberInput: { ...entity },
                              })
                            }
                          /> */}
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
        </div>
        <div style={{ height: "2.5rem" }}>&nbsp;</div>
      </div>
      <input
        id="blackListedFileUploader"
        type="file"
        accept={validFileTypes}
        style={{ display: "none" }}
        onChange={(event) => loadNewFile(event)}
      />
      {loading || customLoading ? <Spinner /> : ""}
      {showConfirmModal ? (
        <ConfirmModal
          body={COMMON_MSG.DeleteRecordMsg}
          showModal={showConfirmModal}
          closeModal={closeConfirmModal}
          doConfirmFunctionality={deleteBlackListedPersonEntity}
        />
      ) : (
        ""
      )}
      {showBlackListedImportModal &&
      importedXLData &&
      importedXLData.length > 0 ? (
        <BlackListedExportModal
          showModal={showBlackListedImportModal}
          closeModal={closeBlackListedExcelModal}
          doImportFunctionality={addBlackListedPersons}
          importedXLData={importedXLData}
        />
      ) : (
        ""
      )}
      {modalInfo.showModal ? (
        <MemberModalEngine
          modalType={modalInfo.type}
          modalTitle={
            modalInfo.type === ModalType.ADD_MEMBER_QUICK_MODAL
              ? "Add Person To Black List"
              : ""
          }
          showModal={modalInfo.showModal}
          closeModal={closeModal}
          memberId={modalInfo.memberId}
          memberData={modalInfo.memberData}
          addQuickMemberInput={
            modalInfo.addQuickMemberInput || new AddQuickMemberViewModel()
          }
          showConflictedMemberModal={showConflictedMemberModal}
          resetParentPage={getBlackListedPersonList}
          resetLoader={resetLoader}
          addBlackListedPersons={addBlackListedPersons}
        />
      ) : (
        ""
      )}
    </div>
  );
};
export default BlackListedPersonsSettings;

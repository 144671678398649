import React, {
  useState,
  useEffect,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  updateReservationNotesReset,
  updateReservationNotes,
} from "../../../store/actions/index";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Draggable from "react-draggable";
import {
  getBasicToastResponse,
  updateObject,
} from "../../../shared/utility";
import * as global from "../../../shared/GlobalVar";
import { showNotification } from "../../../components/UI/Toaster/Toaster";

export const ReserVationNotesModal = (props) => {
  const notesData = props.defaultData;
  const dispatch = useDispatch();
  const updateNotesResponse = useSelector(
    (state) => state.reservationReducer.updateNotesResponse
  );
  const [updateNotesReq, setUpdateNotesReq] = useState({});

  useEffect(() => {
    setUpdateNotesReq({
      ReservationId: notesData.reservationId,
      ReservationNotesType: notesData.notesType,
      Notes: notesData.notes,
    });
  }, []);
  useEffect(() => {
    if (!updateNotesResponse) {
      return;
    }
    showNotification(getBasicToastResponse(updateNotesResponse));
    if (updateNotesResponse.StatusCode === 0) {
      dispatch(updateReservationNotesReset());
      props.resetParentPage();
      props.closeModal();
    }
    dispatch(updateReservationNotesReset());
  }, [updateNotesResponse]);
  const handleOnChange = ({ target }) => {
    let updateCtrls = { ...updateNotesReq };
    updateCtrls = updateObject(updateCtrls, {
      [target.name]: target.value,
    });
    setUpdateNotesReq(updateCtrls);
  };
  const onFormSubmit = () => {
    if (!updateNotesReq.Notes) {
      var toastMsg = {
        msg: "Note is empty.",
        toastType: global.TOAST_MSG.ERROR,
      };
      showNotification(toastMsg);
    } else {
      dispatch(updateReservationNotesReset());
      dispatch(updateReservationNotes(updateNotesReq));
    }
  };
  return (
    <Draggable handle=".cursor">
      <Modal
        isOpen={props.showModal}
        className="no-cursor"
        toggle={() => {
          props.closeModal();
        }}
        style={{ minWidth: "40rem" }}
      >
        <ModalHeader
          className="modal-header bg-primary modal-title text-white cursor"
          toggle={() => {
            props.closeModal();
          }}
        >
          <i className="fas fa-arrows-alt" /> {notesData.modalTitle}
        </ModalHeader>
        <ModalBody>
          <div className="col-lg-12">
            <label className="col-sm-12 fs-3 font-weight-bold  p-0">
              Notes
            </label>
            <textarea
              className="txtbox_style requiredText"
              name="Notes"
              value={updateNotesReq.Notes}
              onChange={(event) => handleOnChange(event)}
              style={{ height: "12rem" }}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="col-lg-12 text-right">
            <button
              type="button"
              name="btnAddNewMember"
              className="btn btn-primary waves-effect waves-light btnSize ml-2"
              onClick={() => onFormSubmit()}
            >
              Update
            </button>
            <button
              type="button"
              name="btnCancel"
              className="btn btn-secondary waves-effect waves-light btnSize ml-2"
              onClick={props.closeModal}
            >
              Cancel
            </button>
          </div>
        </ModalFooter>
      </Modal>
    </Draggable>
  );
};
export default ReserVationNotesModal;

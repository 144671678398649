import React, { Fragment, useState } from 'react';
import DialogEngine from '../../../../../components/dialog/dialog-engine';
import { ModalType } from '../../../../../shared/GlobalVar';
import { getPayNowAddOnDisclosureInfo } from '../../../../../shared/json/data';
import CommonTitle from '../../../component/common-title';
import GeneralRateRow from '../general-rate-row';

function ProtectionsExtrasBlock({ addOns, loyalties, extensionFee, damageCharges }) {
    const [modalData, setModalData] = useState(null);
    const showPrectionAndExtrasNotesModal = () => {
        setModalData(
            {
                modalType: ModalType.COMMON_NOTES_MODAL,
                modalProps: {
                    showModal: true,
                    isDraggable: true,
                    closeModal: onCloseModal,
                    modalTitle: "Pay Now Add-On Disclosure",
                    modaWrapperClass: "protection-addons-modal"
                },
                modalBodyProps: {
                    notes: getPayNowAddOnDisclosureInfo(),
                },
            }
        );
    }
    const onCloseModal = () => {
        setModalData(null);
    }
    return (
        <>
            <CommonTitle title="Protections & Extras" classNames="common-title" withIcon={true} iconClass="fa fa-info-circle" onClick={showPrectionAndExtrasNotesModal} />
            {modalData ?
                <DialogEngine
                    {...modalData}
                />
                : ""}
            {addOns.map((data, i) => {
                return (
                    <Fragment key={data?.code}>
                        {i > 0 ? <hr className='m-0 p-0' /> : ""}
                        <GeneralRateRow
                            key={data?.desc.replace(/\s+/g, '')}
                            title={data?.maxQuantity > 1 ? data?.desc + " X " + data?.quantity : data?.desc} rate={data?.rate}
                            total={data?.amount} />
                    </Fragment>)
            })}
            {extensionFee && <GeneralRateRow key="extension-fee" title="Extension Fees" total={extensionFee} />}
            {damageCharges && <GeneralRateRow key="damage-charge" title="Damage Charges" total={damageCharges} />}
            {loyalties.map((data) => {
                return <GeneralRateRow key={data?.desc.replace(/\s+/g, '')} title={data?.desc} total={data?.amount} />
            })}
        </>
    )
}

export default ProtectionsExtrasBlock
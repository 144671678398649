export const checkImageUrl = (imageSrc, onValidUrl, onBrokenUrl) => {
  var img = new Image();
  img.onload = onValidUrl;
  img.onerror = onBrokenUrl;
  img.src = imageSrc;
};
export const GetFilename = (url) => {
  if (url) {
    var m = url.toString().match(/.*\/(.+?)\./);
    if (m && m.length > 1) {
      return m[1];
    }
  }
  return "";
};

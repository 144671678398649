export const GET_LANDMARK_START = "GET_LANDMARK_START";
export const GET_LANDMARK_SUCCESS = "GET_LANDMARK_SUCCESS";
export const GET_LANDMARK_FAIL = "GET_LANDMARK_FAIL";
export const GET_LANDMARK_RESET = "GET_LANDMARK_RESET";

export const UPDATE_LANDMARK_INFO_START = "UPDATE_LANDMARK_INFO_START";
export const UPDATE_LANDMARK_INFO_SUCCESS = "UPDATE_LANDMARK_INFO_SUCCESS";
export const UPDATE_LANDMARK_INFO_FAIL = "UPDATE_LANDMARK_INFO_FAIL";
export const UPDATE_LANDMARK_INFO_RESET = "UPDATE_LANDMARK_INFO_RESET";

export const DELETE_LANDMARK_INFO_START = "DELETE_LANDMARK_INFO_START";
export const DELETE_LANDMARK_INFO_SUCCESS = "DELETE_LANDMARK_INFO_SUCCESS";
export const DELETE_LANDMARK_INFO_FAIL = "DELETE_LANDMARK_INFO_FAIL";
export const DELETE_LANDMARK_INFO_RESET = "DELETE_LANDMARK_INFO_RESET";

export const SHUTTLE_START = "SHUTTLE_START";
export const SHUTTLE_FAIL = "SHUTTLE_FAIL";
export const GET_SHUTTLE_SUCCESS = "GET_SHUTTLE_SUCCESS";
export const GET_SHUTTLE_RESET = "GET_SHUTTLE_RESET";
export const SHUTTLE_CRUD_SUCCESS = "SHUTTLE_CRUD_SUCCESS";
export const SHUTTLE_CRUD_RESET = "SHUTTLE_CRUD_RESET";

export const SHUTTLE_OPTION_START = "SHUTTLE_OPTION_START";
export const SHUTTLE_OPTION_SUCCESS = "SHUTTLE_OPTION_SUCCESS";
export const SHUTTLE_OPTION_FAIL = "SHUTTLE_OPTION_FAIL";
export const SHUTTLE_OPTION_RESET = "SHUTTLE_OPTION_RESET";
export const SHUTTLE_OPTION_STATIC_SUCCESS="SHUTTLE_OPTION_STATIC_SUCCESS";
export const SHUTTLE_OPTION_STATIC_RESET="SHUTTLE_OPTION_STATIC_SUCCESS";

export const GET_SHUTTLE_TRIP_DETAILS_START = "GET_SHUTTLE_TRIP_DETAILS_START";
export const GET_SHUTTLE_TRIP_DETAILS_SUCCESS = "GET_SHUTTLE_TRIP_DETAILS_SUCCESS";
export const GET_SHUTTLE_TRIP_DETAILS_FAIL = "GET_SHUTTLE_TRIP_DETAILS_FAIL";
export const GET_SHUTTLE_TRIP_DETAILS_RESET = "GET_SHUTTLE_TRIP_DETAILS_RESET";

export const VICINITY_START = "VICINITY_START";
export const VICINITY_SUCCESS = "VICINITY_SUCCESS";
export const VICINITY_FAIL = "VICINITY_FAIL";
export const VICINITY_RESET = "VICINITY_RESET";

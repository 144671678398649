import moment from 'moment';
import React from 'react';
import { getMergedDateAndTimeValue } from '../../../../containers/Reservation/fox-reservation/fox-res-ui-helper';
import { CALENDAR_03, TIME } from '../../../../shared/AppImages';
import { DateInputFormats, TimeInputFormats } from '../../../../shared/utility/date-time-utility';
import { AdminDateFromat } from '../../../../shared/utility/enum-utility';
import { getDateTimeFormatString } from '../../../common/common-helper';
import DateTimeCtrl from './date-time-ctrl';

function DateWithTimeCtrl(props) {
    const { dateCtrlName, timeCtrlName, value, placeholder, onChange, dateVal, timeVal } = props;
    const onInputChange = (event, name) => {
        if (event) {
            let dateTimeVal = event ? moment(event).format(AdminDateFromat.FOX_API_DATE_TIME) : null;
            if (name === (dateCtrlName || "date")) {
                onChange(getMergedDateAndTimeValue(
                    dateTimeVal,
                    timeVal,
                    "DATE"
                ));
            } else if (name === timeCtrlName || "time") {
                onChange(getMergedDateAndTimeValue(
                    dateTimeVal,
                    dateVal,
                    "TIME"
                ));
            }
        }
    }
    return (
        <div className='res-datetime-container'>
            <DateTimeCtrl
                name={dateCtrlName || "date"}
                key="date"
                value={value}
                placeholder={placeholder}
                dateTimePlaceholder="MM/DD/YYYY"
                parseInputFormat={DateInputFormats}
                format={getDateTimeFormatString(false)}
                onChange={onInputChange}
                disabled=""
                externalData=""
                imgIco={CALENDAR_03}
                date={true}
                dateFormat="D"
            />
            <span className='separator'></span>
            <DateTimeCtrl
                name={timeCtrlName || "time"}
                key="time"
                value={value}
                placeholder="Time"
                dateTimePlaceholder="h:mm A"
                parseInputFormat={TimeInputFormats}
                onChange={onInputChange}
                disabled=""
                externalData=""
                imgIco={TIME}
                date={false}
                time={true}
                format="h:mm A"
            />
        </div>
    )
}

export default DateWithTimeCtrl
import * as actionTypes from "./actionTypes";
import HttpService from "../../services/httpService";
export const getAvailabilitySummaryInFleetStart = () => {
  return {
    type: actionTypes.GET_AVAILABILITY_SUMMARY_IN_FLEET_START
  };
};

export const getAvailabilitySummaryInFleetSuccess = response => {
  return {
    type: actionTypes.GET_AVAILABILITY_SUMMARY_IN_FLEET_SUCCESS,
    availabilitySummaryInFleetResponse: response
  };
};

export const getAvailabilitySummaryInFleetFail = error => {
  return {
    type: actionTypes.GET_AVAILABILITY_SUMMARY_IN_FLEET_FAIL,
    error: error
  };
};
export const getAvailabilitySummaryInFleetReset = () => {
  return {
    type: actionTypes.GET_AVAILABILITY_SUMMARY_IN_FLEET_RESET
  };
};

export const getAvailibilitySummaryInFleet = () => {
  return dispatch => {
    dispatch(getAvailabilitySummaryInFleetStart());
    HttpService.getAvailibilitySummaryInFleet()
      .then(response => {
        dispatch(getAvailabilitySummaryInFleetSuccess(response));
      })
    .catch(err => {
        //dispatch(getAvailabilitySummaryInFleetFail(err));
      });
  };
};

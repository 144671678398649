import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getInvoiceInformationReset,
  getInvoiceInformation,
  doCaptureReleaseRefundReset,
  fetchCreditCardNumber,
  fetchCreditCardNumberReset,
} from "../../../store/actions/index";
import ReactHtmlParser from "react-html-parser";
import TooltipItem from "../../../components/tooltip/TooltipItem";
import {
  getFormattedVehicleName,
  convertDateTimeString,
  getBasicToastResponse,
  getNoteTooltipHtml,
  formatAmountValue,
  hasPermission,
  getDateTimeToolTipTextWithTimezone,
  convertDateTimeStringWithTimezone,
} from "../../../shared/utility";
import PricingTable from "../../../components/pricing/PricingTable";
import { createPricingObj } from "../../../components/pricing/PricingData";
import { Modal, ModalHeader, ModalBody, Button } from "reactstrap";
import Draggable from "react-draggable";
import Permissions from "../../../shared/Permissions";
import { getDataforCaptureRefundReleaseModal } from "../billing-helper";
import CaptureReleaseRefundModal from "./capture-release-refund-modal";
import EyeIcon from "../../../components/UI/EyeIcon/eyeIcon";
import { showNotification } from "../../../components/UI/Toaster/Toaster";
import { createTooltip } from "../../../shared/utility/tooltip-utility";

export const InvoiceInfoModal = (props) => {
  const loading = useSelector(
    (state) =>
      state.enumvaluesReducer.loading ||
      state.invoiceInformationReducer.loading ||
      state.transactionReducer.loading
  );

  const invoiceInformationResponse = useSelector(
    (state) => state.invoiceInformationReducer.invoiceInformationResponse
  );
  const creditCardNumberResponse = useSelector(
    (state) => state.transactionReducer.creditCardNumberResponse
  );
  const dispatch = useDispatch();
  const invoiceId = props.invoiceId;
  const [invoiceInformationList, setInvoiceInformationList] = useState([]);
  const [pricingData, setPricingData] = useState(null);
  const [selectedTransactionInfo, setSelectedTransactionInfo] = useState(null);
  const [selectedRow, setSelectedRow] = useState("");
  const [showInvoiceSummary, setShowInvoiceSummary] = useState(false);
  const [eyeIconInputType, setEyeIconInputType] = useState("password");
  const [userPassword, setUserPassword] = useState(null);
  const [showUserPassword, setShowUserPassword] = useState(false);
  const [
    showCaptureReleaseRefundModal,
    setShowCaptureReleaseRefundModal,
  ] = useState(false);
  const [
    captureReleaseRefundModalData,
    setCaptureReleaseRefundModalData,
  ] = useState(null);
  useEffect(() => {
    initInvoiceInformation();
  }, []);

  useEffect(() => {
    return;
  }, [showInvoiceSummary]);

  useEffect(() => {
    if (!invoiceInformationResponse) {
      return;
    }
    if (
      invoiceInformationResponse &&
      invoiceInformationResponse.StatusCode === 0
    ) {
      setInvoiceInformationList(
        invoiceInformationResponse.PaymentInfoList || []
      );
      if (
        invoiceInformationResponse.PaymentInfoList &&
        invoiceInformationResponse.PaymentInfoList.length > 0
      ) {
        invoiceInformationResponse.PaymentInfoList.map((invoiceInfoObj) => {
          if (invoiceInfoObj.TransactionId === props.transactionId) {
            setSelectedTransactionInfo(invoiceInfoObj);
            return false;
          }
        });
      }
      setPricingData(
        createPricingObj(
          invoiceInformationResponse.ReservationPricingInfo,
          "SUMMARY"
        )
      );
    } else {
      showNotification(getBasicToastResponse(invoiceInformationResponse));
      dispatch(getInvoiceInformationReset());
    }
  }, [invoiceInformationResponse]);
  useEffect(() => {
    if (!creditCardNumberResponse) {
      return;
    }
    if (creditCardNumberResponse && creditCardNumberResponse.StatusCode === 0) {
      setShowUserPassword(false);
      //dispatch(fetchCreditCardNumberReset());
    } else {
      showNotification(getBasicToastResponse(creditCardNumberResponse));
      dispatch(fetchCreditCardNumberReset());
    }
  }, [creditCardNumberResponse]);
  const initInvoiceInformation = () => {
    dispatch(doCaptureReleaseRefundReset());
    dispatch(getInvoiceInformationReset());
    dispatch(fetchCreditCardNumberReset());
    dispatch(getInvoiceInformation(invoiceId));
  };
  const getCaptureReleaseRefundModal = (paymentInfo, newTransactionType) => {
    setCaptureReleaseRefundModalData(
      getDataforCaptureRefundReleaseModal(
        paymentInfo,
        newTransactionType,
        "RESERVATION_SUMMARY_TRANSACTIONS"
      )
    );
    setShowCaptureReleaseRefundModal(true);
  };
  const closeCaptureReleaseRefundModal = (isParentClose) => {
    setShowCaptureReleaseRefundModal(false);
    if (isParentClose) {
      props.closeModal();
    }
  };
  const showSelectedTransactionInfo = (invoiceInfoObj) => {
    setSelectedRow(invoiceInfoObj.TransactionId);
    if (
      selectedTransactionInfo.TransactionId !== invoiceInfoObj.TransactionId
    ) {
      setSelectedTransactionInfo(invoiceInfoObj);
      setShowInvoiceSummary(true);
    } else {
      setShowInvoiceSummary(!showInvoiceSummary);
    }
  };
  const renderTransactionList = () => {
    return invoiceInformationList.map((invoiceInfoObj) => {
      const isSelected =
        selectedRow === invoiceInfoObj.TransactionId && showInvoiceSummary;
      const isCardIconVisible =
        invoiceInfoObj.PaymentRole === "MANUAL_PAYMENT" &&
        (invoiceInfoObj.PaymentProcessor === "CREDIT_CARD" ||
          invoiceInfoObj.PaymentProcessor === "DEBIT_CARD" ||
          invoiceInfoObj.CreditCardType);
      return (
        <Fragment key={invoiceInfoObj.TransactionId}>
          <tr
            key={invoiceInfoObj.TransactionId}
            className={
              isSelected
                ? "selected-row-color cursor-pointer"
                : "cursor-pointer"
            }
            onClick={(event) => {
              showSelectedTransactionInfo(invoiceInfoObj);
            }}
          >
            <td id={"paymentDateId" + invoiceInfoObj.TransactionId}>
              {createTooltip(
                convertDateTimeString(invoiceInfoObj.CreatedOn),
                convertDateTimeStringWithTimezone(
                  invoiceInfoObj.CreatedOn,
                  invoiceInfoObj.TimezoneString
                ),
                { forceShow: true }
              )}
            </td>
            <td>
              <span id={"transactionId_" + invoiceInfoObj.TransactionId}>
                {createTooltip(
                  invoiceInfoObj.TransactionId,
                  invoiceInfoObj.TransactionId
                )}
              </span>
            </td>
            <td>
              <span className="line-clamp">
                {invoiceInfoObj.CreatedByName}{" "}
              </span>{" "}
            </td>
            <td>
              <span className="line-clamp">{invoiceInfoObj.BillingName}</span>
            </td>
            <td>
              <span className="line-clamp">
                {invoiceInfoObj.PaymentTypeDisplay}
              </span>
            </td>
            <td>
              <span className="line-clamp">
                {invoiceInfoObj.TransactionTypeDisplay}
              </span>
            </td>
            <td
              className={`vertical-align-center ${
                isCardIconVisible ? "cardIcon" : ""
              } `}
            >
              {createTooltip(
                <i
                  className={
                    isCardIconVisible
                      ? "fas fa-credit-card mic-ico-invoice"
                      : "fas visibility-hidden"
                  }
                ></i>,
                invoiceInfoObj.PaymentProcessor === "DEBIT_CARD"
                  ? "This payment processed by debit card."
                  : "This payment processed by credit card.",
                { forceShow: false }
              )}
              {invoiceInfoObj.PaymentProcessorDisplay}
            </td>
            <td>{invoiceInfoObj.PaymentStateDisplay}</td>
            <td>
              {invoiceInfoObj.ReservationTotalPaid
                ? formatAmountValue(invoiceInfoObj.ReservationTotalPaid)
                : "--"}
            </td>
            <td>
              {invoiceInfoObj.Amount &&
              invoiceInfoObj.TransactionType !== "PRE_AUTHORIZATION"
                ? formatAmountValue(invoiceInfoObj.Amount)
                : "--"}
            </td>
            <td>
              {invoiceInfoObj.Amount &&
              invoiceInfoObj.TransactionType === "PRE_AUTHORIZATION"
                ? formatAmountValue(invoiceInfoObj.Amount)
                : "--"}
            </td>
            <td>
              {invoiceInfoObj.TotalCapturedAmount
                ? formatAmountValue(invoiceInfoObj.TotalCapturedAmount)
                : "--"}
            </td>
            <td>
              {invoiceInfoObj.TotalRefundAmount
                ? formatAmountValue(invoiceInfoObj.TotalRefundAmount)
                : "--"}
            </td>
            <td>
              {invoiceInfoObj.TotalFreeCredits
                ? formatAmountValue(invoiceInfoObj.TotalFreeCredits)
                : "--"}
            </td>
            <td>
              {invoiceInfoObj.BalanceDue
                ? formatAmountValue(invoiceInfoObj.BalanceDue)
                : "--"}
            </td>
            <td>
              <i
                className={`fas ${
                  selectedRow === invoiceInfoObj.TransactionId &&
                  showInvoiceSummary
                    ? "fa-angle-double-up"
                    : "fa-angle-double-down"
                } fs-3`}
                style={{ paddingRight: "7px" }}
              />
              <span className="pseudo-link fs-2 font-weight-bold">
                {selectedRow === invoiceInfoObj.TransactionId &&
                showInvoiceSummary
                  ? "Hide"
                  : "View"}
              </span>
            </td>
          </tr>
          {showInvoiceSummary &&
          invoiceInfoObj.TransactionId === selectedRow ? (
            <tr>
              <td colSpan="16" className="text-left expand-bg-color">
                {getInvoiceSummaryInfo()}
              </td>
            </tr>
          ) : (
            <tr></tr>
          )}
        </Fragment>
      );
    });
  };
  const getInvoiceSummaryInfo = () => {
    return (
      <Fragment>
        <div className="row col-lg-12 mt-2 p-0">
          <div className="col-lg-12 text-right">
            {paymentInfo.ShowCapture &&
            hasPermission(Permissions.CAPTURE_PAYMENT) ? (
              <Button
                color="primary"
                type="button"
                className="mr-1"
                onClick={(event) =>
                  getCaptureReleaseRefundModal(paymentInfo, "CAPTURE")
                }
              >
                Charge
              </Button>
            ) : (
              ""
            )}
            {paymentInfo.ShowRefund &&
            hasPermission(Permissions.REFUND_PAYMENT) ? (
              <Button
                color="primary"
                type="button"
                className="ml-1"
                onClick={(event) =>
                  getCaptureReleaseRefundModal(paymentInfo, "REFUND")
                }
              >
                Refund
              </Button>
            ) : (
              ""
            )}
            {paymentInfo.ShowRelease &&
            hasPermission(Permissions.RELEASE_PAYMENT) ? (
              <Button
                color="primary"
                type="button"
                className="ml-1"
                onClick={(event) =>
                  getCaptureReleaseRefundModal(paymentInfo, "VOID")
                }
              >
                {paymentInfo.TransactionType === "PRE_AUTHORIZATION"
                  ? "Release"
                  : "Void"}
              </Button>
            ) : (
              ""
            )}
          </div>
          <div className="col-lg-6  p-0">
            <div className="form-group row">
              <label
                htmlFor="newres-CityId"
                className="col-sm-4  col-form-label font-weight-bold"
              >
                Date
              </label>
              <label
                id="lblTransactionDate"
                className="col-sm-8 col-form-label"
              >
                {createTooltip(
                  convertDateTimeString(paymentInfo.PaymentDate),
                  convertDateTimeStringWithTimezone(
                    paymentInfo.CreatedOn,
                    paymentInfo.TimezoneString
                  ),
                  { forceShow: true }
                )}
              </label>
            </div>
            <div className="form-group row">
              <label
                htmlFor="newres-CityId"
                className="col-sm-4  col-form-label font-weight-bold"
              >
                Billing Name
              </label>
              <label
                htmlFor="newres-CityId"
                className="col-sm-8 col-form-label"
              >
                {paymentInfo.BillingName}
              </label>
            </div>
            <div className="form-group row">
              <label
                htmlFor="newres-CityId"
                className="col-sm-4  col-form-label font-weight-bold"
              >
                Type
              </label>
              <label
                htmlFor="newres-CityId"
                className="col-sm-8 col-form-label"
              >
                {paymentInfo.PaymentTypeDisplay}
              </label>
            </div>
            <div className="form-group row">
              <label
                htmlFor="newres-CityId"
                className="col-sm-4  col-form-label font-weight-bold"
              >
                Processed By
              </label>
              <label
                htmlFor="newres-CityId"
                className="col-sm-8 col-form-label"
              >
                {paymentInfo.CreatedByName}
              </label>
            </div>
            <div className="form-group row">
              <label
                htmlFor="newres-CityId"
                className="col-sm-4  col-form-label font-weight-bold"
              >
                Status
              </label>
              <label
                htmlFor="newres-CityId"
                className="col-sm-8 col-form-label pseudo-link"
                id="lblInvoicePaymentStatus"
              >
                {createTooltip(
                  paymentInfo.Processed !== null && paymentInfo.Processed !== ""
                    ? paymentInfo.Processed
                      ? "SUCCESS"
                      : "FAIL"
                    : paymentInfo.PaymentStateDisplay
                    ? paymentInfo.PaymentStateDisplay
                    : "--",
                  paymentInfo.PaymentEventList &&
                    paymentInfo.PaymentEventList[0].LongMessage
                    ? paymentInfo.PaymentEventList[0].LongMessage
                    : "No Message Found",
                  { forceShow: true }
                )}
              </label>
            </div>
            <div className="form-group row">
              <label
                htmlFor="newres-CityId"
                className="col-sm-4  col-form-label font-weight-bold"
              >
                Billing Address
              </label>
              <label
                htmlFor="newres-CityId"
                className="col-sm-8 col-form-label"
              >
                {paymentInfo.CreditCardAddress}
              </label>
            </div>
          </div>
          <div className="col-lg-6  p-0">
            <div className="form-group row">
              <label
                htmlFor="newres-CityId"
                className="col-sm-4  col-form-label font-weight-bold"
              >
                Payment Role
              </label>
              <label
                htmlFor="newres-CityId"
                className="col-sm-8 col-form-label"
              >
                {paymentInfo.PaymentRole}
              </label>
            </div>
            <div className="form-group row">
              <label
                htmlFor="newres-CityId"
                className="col-sm-4  col-form-label font-weight-bold"
              >
                Payment Method
              </label>
              <label
                htmlFor="newres-CityId"
                className="col-sm-8 col-form-label"
              >
                {paymentInfo.PaymentProcessorDisplay}
              </label>
            </div>
            <div className="form-group row">
              <label
                htmlFor="newres-CityId"
                className="col-sm-4  col-form-label font-weight-bold"
              >
                Transaction Type
              </label>
              <label
                htmlFor="newres-CityId"
                className="col-sm-8 col-form-label"
              >
                {paymentInfo.TransactionTypeDisplay}
              </label>
            </div>
            <div className="form-group row">
              <label
                htmlFor="newres-CityId"
                className="col-sm-4  col-form-label font-weight-bold"
              >
                {paymentInfo.TransactionType === "REFUND"
                  ? "Refunded By"
                  : "Recorded By"}
              </label>
              <label
                htmlFor="newres-CityId"
                className="col-sm-8 col-form-label"
              >
                {paymentInfo.IntegRecordedByName
                  ? paymentInfo.IntegRecordedByName
                  : paymentInfo.PaymentRole === "AUTO_PAYMENT"
                  ? "External Processor"
                  : "--"}
              </label>
            </div>
            <div className="form-group row">
              <label
                htmlFor="newres-CityId"
                className="col-sm-4  col-form-label font-weight-bold"
              >
                {paymentInfo.TransactionType === "REFUND"
                  ? "Amount Refunded"
                  : "Amount Charged"}
              </label>
              <label
                htmlFor="newres-CityId"
                className="col-sm-8 col-form-label"
              >
                {paymentInfo.Amount ? "$" + paymentInfo.Amount : "--"}
              </label>
            </div>
            {paymentInfo.Amount ? (
              <div className="form-group row">
                <label
                  htmlFor="newres-CityId"
                  className="col-sm-4  col-form-label font-weight-bold"
                >
                  Refund Reason
                </label>
                <label
                  htmlFor="newres-CityId"
                  className="col-sm-8 col-form-label"
                >
                  {paymentInfo.RefundReason || "--"}
                </label>
              </div>
            ) : (
              ""
            )}
            {paymentInfo.PaymentProcessor === "CREDIT_CARD" ||
            paymentInfo.PaymentProcessor === "DEBIT_CARD" ||
            paymentInfo.CreditCardType ? (
              <Fragment>
                <div className="form-group row">
                  <label
                    htmlFor="newres-CityId"
                    className="col-sm-4  col-form-label font-weight-bold"
                  >
                    Card Type
                  </label>
                  <label
                    htmlFor="newres-CityId"
                    className="col-sm-8 col-form-label"
                  >
                    {paymentInfo.CreditCardType || "--"}
                  </label>
                </div>
                <div className="form-group row">
                  <label
                    htmlFor="newres-CityId"
                    className="col-sm-4  col-form-label font-weight-bold"
                  >
                    Card Number
                  </label>

                  {creditCardNumberResponse &&
                  creditCardNumberResponse.CreditCardNumber ? (
                    <label
                      htmlFor="newres-CityId"
                      className="col-sm-8 col-form-label text-primary"
                    >
                      {creditCardNumberResponse.CreditCardNumber}
                    </label>
                  ) : (
                    <label
                      htmlFor="newres-CityId"
                      className="col-sm-8 col-form-label pseudo-link"
                      onClick={() => setShowUserPassword(true)}
                    >
                      {paymentInfo.AccountNumber || "--"}
                    </label>
                  )}
                </div>
                {showUserPassword &&
                hasPermission(Permissions.VIEW_CREDIT_CARD_NUMBER) ? (
                  <div className="form-group row">
                    <label
                      htmlFor="newres-CityId"
                      className="col-sm-4  col-form-label font-weight-bold"
                    >
                      &nbsp;
                    </label>
                    <div className="col-sm-5 position-relative  pr-0">
                      <input
                        type={eyeIconInputType || "password"}
                        className="col-sm-12"
                        value={userPassword}
                        onChange={(event) =>
                          setUserPassword(event.target.value)
                        }
                      />
                      <EyeIcon
                        toggleIcon={(inputType) => {
                          setEyeIconInputType(inputType);
                        }}
                        customClass="icon--right"
                      />
                    </div>
                    <button
                      type="button"
                      className="btn btn-primary ml-2"
                      onClick={() =>
                        dispatch(
                          fetchCreditCardNumber({
                            MemberId: paymentInfo.BorrowerId,
                            ReservationId: paymentInfo.ReservationId,
                            Password: userPassword,
                          })
                        )
                      }
                    >
                      Confirm
                    </button>
                  </div>
                ) : (
                  ""
                )}
                <div className="form-group row">
                  <label
                    htmlFor="newres-CityId"
                    className="col-sm-4  col-form-label font-weight-bold"
                  >
                    Expiration Date
                  </label>
                  <label
                    htmlFor="newres-CityId"
                    className="col-sm-8 col-form-label"
                  >
                    {paymentInfo.ExpirationMonth && paymentInfo.ExpirationYear
                      ? paymentInfo.ExpirationMonth +
                        "/" +
                        paymentInfo.ExpirationYear
                      : "--"}
                  </label>
                </div>
              </Fragment>
            ) : (
              ""
            )}
            {paymentInfo.PaymentProcessor === "CHECK" ? (
              <Fragment>
                <div className="form-group row">
                  <label
                    htmlFor="newres-CityId"
                    className="col-sm-4  col-form-label"
                  >
                    Bank Name
                  </label>
                  <label
                    htmlFor="newres-CityId"
                    className="col-sm-8 col-form-label"
                  >
                    {paymentInfo.BankName || "--"}
                  </label>
                </div>
                <div className="form-group row">
                  <label
                    htmlFor="newres-CityId"
                    className="col-sm-4  col-form-label"
                  >
                    Check No
                  </label>
                  <label
                    htmlFor="newres-CityId"
                    className="col-sm-8 col-form-label"
                  >
                    {paymentInfo.CheckNo || "--"}
                  </label>
                </div>{" "}
              </Fragment>
            ) : (
              ""
            )}
            <div className="form-group row">
              <h4 className="form-header text-uppercase col-lg-12">
                <i className="fas fa-user-circle" />
                Comments
              </h4>
              <label
                htmlFor="newres-CityId"
                className="col-sm-12 col-form-label"
              >
                {paymentInfo.Comments || "--"}
              </label>
            </div>
          </div>
        </div>
      </Fragment>
    );
  };

  const paymentInfo = selectedTransactionInfo
    ? { ...selectedTransactionInfo }
    : null;
  return paymentInfo ? (
    <Draggable handle=".cursor">
      <Modal
        isOpen={props.showModal}
        className="no-cursor"
        toggle={() => {
          props.closeModal();
        }}
        style={{ minWidth: "1080px" }}
        id="invoice-modal-id"
      >
        <ModalHeader
          className="modal-header bg-primary modal-title text-white cursor"
          toggle={() => {
            props.closeModal();
          }}
        >
          <i className="fas fa-arrows-alt" />{" "}
          {"Invoice  # " + paymentInfo.InvoiceId}
        </ModalHeader>
        <ModalBody style={{ minHeight: "60rem" }}>
          <div
            className="col-lg-12 tableScroll bb-0"
            style={{ minHeight: "60rem" }}
          >
            <table className="table table-striped">
              <thead className="thead-primary">
                <tr>
                  <th style={{ width: "6%" }}>
                    Transaction <br />
                    Date
                  </th>
                  <th style={{ width: "6%" }}>
                    Transaction
                    <br /> ID
                  </th>
                  <th style={{ width: "8%" }}>
                    Processed <br />
                    By
                  </th>
                  <th style={{ width: "8%" }}>
                    Billing
                    <br /> Name
                  </th>
                  <th style={{ width: "8%" }}>
                    Payment
                    <br />
                    Type
                  </th>
                  <th style={{ width: "8%" }}>
                    Transaction <br />
                    Type
                  </th>
                  <th style={{ width: "8%" }}>
                    Payment
                    <br /> Method
                  </th>
                  <th style={{ width: "8%" }}>Status</th>
                  <th style={{ width: "8%" }}>
                    Total <br />
                    Amount
                  </th>
                  <th style={{ width: "8%" }}>
                    Amount
                    <br /> Paid
                  </th>
                  <th style={{ width: "6%" }}>
                    Pre- <br />
                    Authorization
                  </th>
                  <th style={{ width: "8%" }}>
                    Capture <br />
                    Amount
                  </th>
                  <th style={{ width: "6%" }}>
                    Refund <br />
                    Amount
                  </th>
                  <th>Free Credits</th>
                  <th>Balance Due</th>
                  <th>Transaction Details</th>
                </tr>
              </thead>
              <tbody>{renderTransactionList()}</tbody>
            </table>
            <h4 className="form-header text-uppercase col-lg-12 p-0 bt-1 mt-5-0">
              <i className="fas fa-user-circle" />
              Reservation Info
              <b> # {paymentInfo.ReservationCode}</b>
            </h4>
            <div
              className="row col-lg-12 pt-2 pr-0"
              // style={{ border: "1px solid #E0E0E0" }}
            >
              <div className="col-lg-6 pl-0">
                <div className="form-group row">
                  <label className="col-sm-4 font-weight-bold fs-3 pl-0">
                    Start Date
                  </label>
                  <label className="col-sm-8 fs-3 pl-0">
                    {convertDateTimeString(
                      invoiceInformationResponse &&
                        invoiceInformationResponse.ReservationPricingInfo
                        ? invoiceInformationResponse.ReservationPricingInfo
                            .StartDate
                        : null
                    )}
                  </label>
                </div>
                <div className="form-group row">
                  <label className="col-sm-4 font-weight-bold fs-3 pl-0">
                    End Date
                  </label>
                  <label className="col-sm-8 fs-3 pl-0">
                    {convertDateTimeString(
                      invoiceInformationResponse &&
                        invoiceInformationResponse.ReservationPricingInfo
                        ? invoiceInformationResponse.ReservationPricingInfo
                            .EndDate
                        : null
                    )}
                  </label>
                </div>
                <div className="form-group row">
                  <h4 className="form-header text-uppercase col-lg-12 pl-0">
                    <i className="fas fa-user-circle" />
                    Vehicle ID
                  </h4>
                  <label
                    htmlFor="newres-CityId"
                    className="col-sm-12 col-form-label"
                  >
                    {paymentInfo.PersonAssetUnitNumber || "--"}
                  </label>
                </div>
                <div className="form-group row">
                  <h4 className="form-header text-uppercase col-lg-12 pl-0">
                    <i className="fas fa-user-circle" />
                    Category
                  </h4>
                  <label
                    htmlFor="newres-CityId"
                    className="col-sm-12 col-form-label"
                  >
                    {paymentInfo.CategoryName || "--"}
                  </label>
                </div>
                <div className="form-group row">
                  <h4 className="form-header text-uppercase col-lg-12 pl-0">
                    <i className="fas fa-user-circle" />
                    Vehicle Name
                  </h4>
                  <label
                    htmlFor="newres-CityId"
                    className="col-sm-12 col-form-label"
                  >
                    {getFormattedVehicleName(
                      paymentInfo.PersonAssetUnitNumber,
                      paymentInfo.Make,
                      paymentInfo.Model,
                      paymentInfo.Year
                    )}
                  </label>
                </div>
                <div className="form-group row">
                  <h4 className="form-header text-uppercase col-lg-12 pl-0">
                    <i className="fas fa-user-circle" />
                    Owner
                  </h4>
                  <label
                    htmlFor="newres-CityId"
                    className="col-sm-12 col-form-label"
                  >
                    {paymentInfo.PersonAssetOwnerName || "--"}
                  </label>
                </div>
                <div className="form-group row">
                  <h4 className="form-header text-uppercase col-lg-12 pl-0">
                    <i className="fas fa-user-circle" />
                    Renter
                  </h4>
                  <label
                    htmlFor="newres-CityId"
                    className="col-sm-12 col-form-label"
                  >
                    {paymentInfo.BorrowerOrgUnitName || "--"} -{" "}
                    {paymentInfo.BorrowerName || "--"}
                  </label>
                </div>
              </div>
              <div className="col-lg-6">
                <PricingTable
                  pricingData={pricingData}
                  isChregesCreditInputReqiored={false}
                  isDiscountInputReqiored={false}
                />
              </div>
            </div>
            {showCaptureReleaseRefundModal ? (
              <CaptureReleaseRefundModal
                showModal={showCaptureReleaseRefundModal}
                closeModal={closeCaptureReleaseRefundModal}
                defaultData={captureReleaseRefundModalData}
              />
            ) : (
              ""
            )}
          </div>
        </ModalBody>
      </Modal>
    </Draggable>
  ) : (
    ""
  );
};
export default InvoiceInfoModal;

import React from 'react';
import InputWidgetCtrl from '../../../components/UI/Input/input-widget-ctrl';
import TextAreaCtrl from '../../../components/UI/Input/text-area-ctrl';

function RevertDiscountContainer(props) {
    const {
        formValue,
        discountReasonData,
        widgetLoader,
        disabled,
        onChange,
        onClear
    } = props;
    return (
        <>
            <InputWidgetCtrl
                name="revertedReason"
                placeholder="Revert Reason"
                loader={widgetLoader}
                data={discountReasonData?.revertDiscountReasons}
                valueField="key"
                textField="value"
                value={formValue?.revertedReason}
                onChange={onChange}
                onClear={onClear}
                iconClass={`fas fa-info-circle ${disabled ? "font-color-disabled" : "font-color-1"} font-size-2`}
                disabled={disabled}
            />
            <TextAreaCtrl
                name="revertedNotes"
                value={formValue?.revertedNotes || ""}
                onChange={onChange}
                placeholder="Note(s)"
                iconClass={`fa fa-comments ${disabled ? "font-color-disabled" : "font-color-1"} font-size-2`}
                disabled={disabled}
            />
        </>
    )
}

export default RevertDiscountContainer
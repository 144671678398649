import moment from "moment";
import React, { useEffect, useState } from "react";
import Draggable from "react-draggable";
import { useDispatch, useSelector } from "react-redux";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import InputClearIcon from "../../../../../components/UI/icon/input-clear-icon";
import { showNotification } from "../../../../../components/UI/Toaster/Toaster";
import { getCommonSearchModel } from "../../../../../services/entities/models/CommonSearchModel";
import ReservationSearchRequest from "../../../../../services/entities/requests/ReservationSearchRequest";
import { REFRESH_DARK } from "../../../../../shared/AppImages";
import { SearchAPI, TOAST_MSG } from "../../../../../shared/GlobalVar";
import { getBasicToastResponse, getDateTimeString, toTitleCase } from "../../../../../shared/utility";
import { doSorting, getSortType } from "../../../../../shared/utility/sorting-utility";
import { doMemberSearchReservation, doMemberSearchReservationReset } from "../../../../../store/actions";
import FoxCustomerReservationHistory from "./fox-customer-reservation-history";

export const FoxCustomerReservationHistoryModal = (props) => {
    const dispatch = useDispatch();
    const searchMemberReservationsResponse = useSelector(
        (state) => state.reservationReducer.searchMemberReservationsResponse || null
    );
    const [searchData, setSearchData] = useState(null);
    const [lastTimeFetched, setLastTimeFetched] = useState(null);
    const [searchValue, setSearchValue] = useState("");
    const [filterOrSearchData, setFilterOrSearchData] = useState([]);
    const [sortingInfo, setSortingInfo] = useState({ DataType: "date", SortType: "DESC", SortBy: "CreatedOn" });

    useEffect(() => {
        let lastDataFetchTime = null;
        if (localStorage.getItem("LAST_FETCHED_CUSTOMER_RES_HISTORY")) {
            lastDataFetchTime = moment(
                localStorage.getItem("LAST_FETCHED_CUSTOMER_RES_HISTORY"),
                "MM:DD:YYYY:HH:mm:ss"
            ).toDate();
        }
        if (lastDataFetchTime != null) {
            lastDataFetchTime = moment(lastDataFetchTime).add(5, "m").toDate();
        }

        if (
            !searchMemberReservationsResponse ||
            !lastDataFetchTime ||
            (lastDataFetchTime != null && new Date() >= lastDataFetchTime)
        ) {
            setFilterOrSearchData([])
            getSearchList();
        }
    }, []);

    useEffect(() => {
        if (searchData?.SearchList?.length) {
            setFilterOrSearchData(searchData?.SearchList)
        } else {
            setFilterOrSearchData([])
        }
    }, [searchData])

    useEffect(() => {
        if (!searchMemberReservationsResponse) {
            setSearchData(null);
            return;
        }
        if (searchMemberReservationsResponse && searchMemberReservationsResponse.StatusCode === 0) {
            if (searchMemberReservationsResponse.TotalRecords > 0) {
                let searchResult = getCommonSearchModel(searchMemberReservationsResponse, SearchAPI.SEARCH_MEMBER_RESERVATION);
                let cloneSearchList = JSON.parse(JSON.stringify(searchResult.SearchList));
                setSearchData(searchResult);
                setFilterOrSearchData(cloneSearchList);
            }
            if (localStorage.getItem("LAST_FETCHED_CUSTOMER_RES_HISTORY")) {
                setLastTimeFetched(
                    moment(
                        localStorage.getItem("LAST_FETCHED_CUSTOMER_RES_HISTORY"),
                        "MM:DD:YYYY:HH:mm:ss"
                    ).toDate()
                );
            }
            // dispatch(doMemberSearchReservationReset());
        } else {
            showNotification(getBasicToastResponse(searchMemberReservationsResponse));
            dispatch(doMemberSearchReservationReset());
        }
    }, [searchMemberReservationsResponse]);
    useEffect(() => {
        doSorting(
            sortingInfo,
            filterOrSearchData
        );
    }, [sortingInfo]);
    const showErrorMsg = (msg) => {
        var toastMsg = {
            msg: msg,
            toastType: TOAST_MSG.ERROR,
        };
        showNotification(toastMsg);
    };

    const getSearchList = () => {
        if (
            props.memberId
        ) {
            let reservationSearchRequestObj = new ReservationSearchRequest();
            let reqNew = JSON.parse(JSON.stringify(reservationSearchRequestObj))
            reqNew.MemberId = props.memberId || null;
            reqNew.ShowReservationsAsSharer = false;
            reqNew.ShowReservationsAsBorrower = true;
            reqNew.ReservationSearchInfo.UpsellOptionInfoRequired = true;
            reqNew.ReservationSearchInfo.PageInfo.SortBy = "RESV_CREATED_DATE";
            reqNew.ReservationSearchInfo.PageInfo.SortType = "DESC";
            localStorage.setItem(
                "LAST_FETCHED_CUSTOMER_RES_HISTORY",
                moment(new Date()).format("MM:DD:YYYY:HH:mm:ss")
            );
            dispatch(doMemberSearchReservationReset());
            dispatch(doMemberSearchReservation(reqNew));
        } else {
            showErrorMsg("Please select the Customer First");
        }
        setFilterOrSearchData([])
    };
    const clearValue = () => {
        setSearchValue("");
        getFilterOrSearchData(null, "");
    };
    const getFilterOrSearchData = (event, searchInput) => {
        let textValue = event
            ? event.target.value
            : searchInput
        setSearchValue(textValue)
        let cloneSearchList = JSON.parse(JSON.stringify(searchData.SearchList));
        let dataToSearch = cloneSearchList;
        setFilterOrSearchData(dataToSearch);
        if (textValue) {
            if (textValue) {
                dataToSearch = dataToSearch.filter((item) =>
                    `${item?.ConfirmationKey?.toLowerCase()}
                    ${toTitleCase(item?.ConfirmationKey)?.toLowerCase()}
                    ${item?.Category?.toLowerCase()}
                    ${item?.CategoryName?.toLowerCase()}
                    ${item?.BorrowerName?.toLowerCase()}
                    ${item?.ReturnLocation?.toLowerCase()}
                    ${item?.IntegConfirmNumber?.toLowerCase()}
                    ${item?.IntegStatus?.toLowerCase()}
                    ${item?.PickUpLocation?.toLowerCase()}
                    ${toTitleCase(item?.ReservationState)?.toLowerCase()}
                    ${item?.ReservationState?.toLowerCase()}
                    ${item?.ReservationCreationType?.toLowerCase()}
                    ${item?.BorrowerBadgeNo?.toLowerCase()}
                    ${getDateTimeString(item?.CreatedOn)?.toLowerCase()}
                    ${getDateTimeString(item?.PromisedStartDate)?.toLowerCase()}
                    ${getDateTimeString(item?.PromiseEndDate)?.toLowerCase()}
                    ${item?.TotalEstimatedPaidSP}
                    ${item?.BalanceDue}
                    ${item?.UpsellOptions.map((element, index) => `${element.DisplayText}`)
                            .join(",").toLowerCase()}`.includes(
                                textValue.toLowerCase()
                            )
                );
                setFilterOrSearchData(dataToSearch);
            }
        } else {
            setFilterOrSearchData(cloneSearchList);
        }
    };
    const renderFilterSection = () => {
        return (
            <div className="search-box" style={{ height: "4rem" }}>
                <InputClearIcon
                    inputValue={searchValue}
                    customDivClass="mt-3 light-gray-color"
                    onClickEvent={clearValue}
                />
                <input
                    type="text"
                    style={{ height: "4rem" }}
                    placeholder="Search by Res Code, Date or Time, Location, Category, etc."
                    value={searchValue}
                    onChange={(event) => getFilterOrSearchData(event, true)}
                />
            </div>
        );
    };
    const updateSortInfo = (sortEventType) => {
        let sortInfo = { ...sortingInfo };
        sortInfo.SortBy = sortEventType;
        sortInfo.SortType = getSortType(sortInfo, sortEventType);
        sortInfo.DataType = ["CreatedOn", "PromiseEndDate", "PromisedStartDate"].includes(
            sortEventType
        )
            ? "date"
            : ["BalanceDue"].includes(sortEventType)
                ? "number"
                : "string";
        setSortingInfo(sortInfo);
    };

    return (
        <div className="input-container">
            <Draggable handle=".cursor">
                <Modal
                    id="customer-reservation-history"
                    isOpen={props.showModal}
                    className={"no-cursor fox-modal"}
                    style={{
                        minWidth: "90%",
                    }}
                >
                    <ModalHeader
                        className="bg-white modal-title cursor"
                        toggle={() => {
                            props.closeModal();
                        }}
                    >
                        Customer Reservation History
                    </ModalHeader>
                    <ModalBody
                    >
                        <div className="history-container" style={{ minHeight: "45rem" }}>
                            <div
                                className="col-sm-12"
                                style={{ display: "flex", justifyContent: "space-between" }}
                            >
                                <div className="title-section">
                                    <span className="common-title">
                                        All Reservations
                                    </span>
                                </div>
                                <div className="d-flex" style={{ gap: "1.6rem" }}>
                                    <div
                                        className="action-button refresh-icon"
                                        title={"Click to get updated data. (Data Last Refreshed On:" + (lastTimeFetched
                                            ? moment(lastTimeFetched).format("MM/DD/YYYY hh:mm:ss A")
                                            : "") + ")"}
                                        onClick={() => getSearchList()}
                                    >
                                        <img
                                            className="refresh-icon"
                                            src={REFRESH_DARK}
                                            height={20}
                                            width={20}
                                            alt={"refersh"}
                                        />
                                    </div>
                                    {renderFilterSection()}
                                </div>

                            </div>
                            {<FoxCustomerReservationHistory sortingInfo={sortingInfo} updateSortInfo={updateSortInfo} resData={filterOrSearchData} key={filterOrSearchData} />}
                        </div>
                        <hr />
                        <div className="col-lg-12 text-right mt-3">
                            <p>Total Reservations Displayed : {filterOrSearchData.length} </p>
                        </div>
                    </ModalBody>
                </Modal>
            </Draggable>
        </div>
    );
};
export default FoxCustomerReservationHistoryModal;

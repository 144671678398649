import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getReservationSettingsInfo,
  getReservationSettingsInfoReset,
  updateReservationSettingsInfo,
  updateReservationSettingsInfoReset,
} from "../../../store/actions/index";
import Spinner from "../../../components/UI/Spinner/Spinner";
import { showNotification } from "../../../components/UI/Toaster/Toaster";
import PermissionName from "../../../shared/Permissions";
import {
  updateObject,
  hasPermission,
  getBasicToastResponse,
  getHourList,
  openURLIntoNewTab,
  getDropdownItems,
} from "../../../shared/utility";
import { ReservationSettingsViewModel } from "../../../services/entities/view-models/settings-view-models";
import LinkButton from "../../../components/UI/button/link-btn";
import { CommonLabel } from "../../../components/UI/label/commmon-label";
import {
  getInheritedProperties,
  getMinutes,
  getMinutesIntervals,
} from "../settingsHelper";
import { COMMON_MSG } from "../../../shared/GlobalVar";
import ConfirmModal from "../../../components/common/modal/confirm-modal";
import InheritFromParentConfirmModal from "../modal/inherit-from-parent-confirm-modal";
import PreventTransitionPrompt from "../../../components/Navigations/prevent-navigation/prevent-transition-prompt";
import CommonInputField from "../../../components/UI/Input/common-input-field";

const ReservationSettings = (props) => {
  const loading = useSelector((state) => state.settingsReducer.loading);
  const currentOrgUnitId = useSelector(
    (state) => state.auth.loggedInUserData.CurrentOrgUnitId
  );
  const reservationSettingsInfoResponse = useSelector(
    (state) => state.settingsReducer.reservationSettingsInfoResponse
  );
  const reservationSettingsInfoUpdateResponse = useSelector(
    (state) => state.settingsReducer.reservationSettingsInfoUpdateResponse
  );
  const dependentOrgLength = useSelector((state) =>
    state.auth.loggedInUserData.DependantOrgList
      ? state.auth.loggedInUserData.DependantOrgList.length
      : 0
  );
  const dispatch = useDispatch();
  const query = props.location
    ? new URLSearchParams(props.location.search)
    : null;
  const selectedOrgLocationId =
    props.otherData && props.otherData.selectedOrgLocationId
      ? props.otherData.selectedOrgLocationId
      : query
      ? query.get("orgId")
      : currentOrgUnitId;

  const [isEditMode, setIsEditMode] = useState(false);
  const [reservationSettingsData, setReservationSettingsData] = useState(
    new ReservationSettingsViewModel()
  );
  const [
    showConfirmRemoveInheritModal,
    setShowConfirmRemoveInheritModal,
  ] = useState(false);
  const [
    showInheritFromParentConfirmModal,
    setShowInheritFromParentConfirmModal,
  ] = useState(false);
  const hasEditPermission = hasPermission(
    PermissionName.EDIT_RESERVATION_ORG_SETTINGS
  );
  const hours = getHourList(false).map((item) => {
    return {
      key: parseInt(item.value),
      displayValue:
        parseInt(item.display) > 1
          ? parseInt(item.display) + " hrs"
          : parseInt(item.display) + " hr",
    };
  });
  const minutesIntervals = getMinutesIntervals();

  const [isBlocking, setIsBlocking] = useState(false);

  useEffect(() => {
    getReservationSettingsData();
  }, [selectedOrgLocationId]);
  useEffect(() => {
    if (!reservationSettingsInfoResponse) {
      return;
    }
    if (reservationSettingsInfoResponse.StatusCode === 0) {
      if (showInheritFromParentConfirmModal) {
        return;
      }
      setReservationSettingsData(
        new ReservationSettingsViewModel(
          reservationSettingsInfoResponse,
          currentOrgUnitId,
          parseInt(selectedOrgLocationId, 10)
        )
      );
      setIsEditMode(false);
    } else {
      showNotification(getBasicToastResponse(reservationSettingsInfoResponse));
    }
  }, [reservationSettingsInfoResponse]);

  useEffect(() => {
    if (!reservationSettingsInfoUpdateResponse) {
      return;
    }
    showNotification(
      getBasicToastResponse(reservationSettingsInfoUpdateResponse)
    );
    if (reservationSettingsInfoUpdateResponse.StatusCode === 0) {
      setShowInheritFromParentConfirmModal(false);
      setShowConfirmRemoveInheritModal(false);
      setIsEditMode(false);
      setIsBlocking(false);
      dispatch(updateReservationSettingsInfoReset());
    } else {
      dispatch(updateReservationSettingsInfoReset());
    }
  }, [reservationSettingsInfoUpdateResponse]);
  const enableEditMode = () => {
    setIsEditMode(true);
  };
  const renderCommmonLabel = (value, lblId, classNames) => {
    return (
      <CommonLabel
        className={
          classNames || "col-lg-12 p-0 text-truncate-block padding-settings-5"
        }
        value={value}
        lblId={lblId}
      />
    );
  };
  const onCancel = () => {
    setIsEditMode(false);
    setReservationSettingsData(
      new ReservationSettingsViewModel(
        reservationSettingsInfoResponse,
        currentOrgUnitId,
        parseInt(selectedOrgLocationId, 10)
      )
    );
    setIsBlocking(false);
  };
  const inputChangedHandler = (event, controlName, elementType) => {
    let value = null;
    if (elementType === "radio" || elementType === "checkbox") {
      value = event.target.checked;
    } else {
      value = event.target.value;
    }
    let updatedControls = updateObject(reservationSettingsData, {
      [controlName]: ["LeadTimeInHour", "BufferTimeInHours"].includes(
        elementType
      )
        ? parseInt(value) * 60 +
          parseInt(reservationSettingsData[controlName] % 60)
        : ["LeadTimeInMinutes", "BufferTimeInMinutes"].includes(elementType)
        ? Math.floor(reservationSettingsData[controlName] / 60) * 60 +
          parseInt(value)
        : elementType === "radio"
        ? !reservationSettingsData[controlName]
        : value,
    });
    setReservationSettingsData(updatedControls);
    setIsBlocking(true);
  };

  const getReservationSettingsData = () => {
    const req = {};
    req.SelectedChildOrgUnitId = selectedOrgLocationId;
    dispatch(getReservationSettingsInfoReset());
    dispatch(getReservationSettingsInfo(req));
  };
  const getInheritConfirmModal = (event) => {
    setIsEditMode(false);
    if (event.target.checked) {
      setShowInheritFromParentConfirmModal(true);
    } else {
      setShowConfirmRemoveInheritModal(true);
    }
  };
  const closeConfirmModal = () => {
    if (showConfirmRemoveInheritModal) {
      setShowConfirmRemoveInheritModal(false);
    } else if (showInheritFromParentConfirmModal) {
      setShowInheritFromParentConfirmModal(false);
    }
  };
  const doInheritFunctionality = () => {
    updateData(
      reservationSettingsData,
      showConfirmRemoveInheritModal ? false : true
    );
  };
  const updateData = (req, isInheritedFromParent) => {
    const reqObj = {
      ...req,
      ...getInheritedProperties(
        currentOrgUnitId,
        req.SelectedChildOrgUnitId || selectedOrgLocationId,
        typeof isInheritedFromParent !== "undefined"
          ? isInheritedFromParent
          : req.InheritedFromParent,
        dependentOrgLength
      ),
    };
    delete reqObj["DisplayInherited"];
    delete reqObj["InheritedFromParent"];
    dispatch(updateReservationSettingsInfoReset());
    dispatch(updateReservationSettingsInfo(reqObj));
  };

  const labelClasses = "col-sm-4 col-form-label font-weight-bold";
  const styleMaxWidth23 = { maxWidth: "23rem" };
  const styleMaxWidth9 = { maxWidth: "9rem" };
  const styleMaxWidth8 = { maxWidth: "8rem" };
  const styleMaxWidth7 = { maxWidth: "7rem" };

  const renderRadioButtonsWithLabel = (labelCaption, name, isUrl = false) => {
    return (
      <div className="form-group row">
        {
          <label className={labelClasses} style={styleMaxWidth23}>
            {labelCaption}
            {isUrl ? (
              <span
                className="pseudo-link pl-2"
                onClick={() => openURLIntoNewTab("http://www.justshareit.com")}
              >
                Justshareit.com
              </span>
            ) : (
              ""
            )}
          </label>
        }
        {renderRadioButton("rbtnNon" + name, name, "No")}
        {renderRadioButton("rbtn" + name, name, "Yes")}
      </div>
    );
  };
  const renderRadioButton = (id, controlName, radioCaption) => {
    let radioBtnName = props.isInheritModal
      ? controlName + String(props.isInheritModal)
      : controlName;
    return (
      <CommonInputField
        elementConfig={{
          id: id,
          type: "radio",
          name: radioBtnName,
          checked:
            radioCaption === "No"
              ? !reservationSettingsData[controlName]
              : reservationSettingsData[controlName],
          disabled: !isEditMode,
        }}
        otherConfig={{
          caption: radioCaption,
        }}
        nodeElement="radio"
        valueClasses={`col-sm-1 pl-4`}
        onChange={inputChangedHandler}
        customStyle={radioCaption === "No" ? styleMaxWidth8 : {}}
      />
    );
  };
  const renderDropdownList = (name, value, dropdownList, elementType) => {
    return (
      <CommonInputField
        elementConfig={{
          name: name,
          value: value,
          disabled: !isEditMode,
        }}
        otherConfig={{
          dropdownItem: dropdownList,
          elementType: elementType,
        }}
        nodeElement="select"
        onChange={inputChangedHandler}
      />
    );
  };
  const disabled =
    reservationSettingsData.InheritedFromParent ||
    !hasEditPermission ||
    !isEditMode ||
    props.isInheritModal
      ? true
      : false;
  const renderMainLayout = () => {
    return (
      <fieldset disabled={disabled} className="border-none">
        <div className={`form-group row  ${isEditMode ? "required" : ""}`}>
          <label className={labelClasses} style={styleMaxWidth23}>
            Lead Time
          </label>
          <div className="col-sm-1" style={styleMaxWidth9}>
            {isEditMode
              ? renderDropdownList(
                  "LeadTimeInMinutes",
                  reservationSettingsData.LeadTimeInMinutes
                    ? Math.floor(reservationSettingsData.LeadTimeInMinutes / 60)
                    : 0,
                  hours,
                  "LeadTimeInHour"
                )
              : renderCommmonLabel(
                  reservationSettingsData.LeadTimeInMinutes
                    ? Math.floor(
                        reservationSettingsData.LeadTimeInMinutes / 60
                      ) + " hr (s)"
                    : 0,
                  "lblLeadTimeInHour"
                )}
          </div>
          <div className="col-sm-1 p-0" style={styleMaxWidth7}>
            {isEditMode
              ? renderDropdownList(
                  "LeadTimeInMinutes",
                  reservationSettingsData.LeadTimeInMinutes
                    ? Math.floor(reservationSettingsData.LeadTimeInMinutes % 60)
                    : 0,
                  minutesIntervals,
                  "LeadTimeInMinutes"
                )
              : renderCommmonLabel(
                  reservationSettingsData.LeadTimeInMinutes
                    ? Math.floor(
                        reservationSettingsData.LeadTimeInMinutes % 60
                      ) + " min (s)"
                    : 0,
                  "lblLeadTimeInMinutes"
                )}
          </div>
        </div>

        <div className={`form-group row  ${isEditMode ? "required" : ""}`}>
          <label className={labelClasses} style={styleMaxWidth23}>
            Buffer Time
          </label>
          <div className="col-sm-1" style={styleMaxWidth9}>
            {isEditMode
              ? renderDropdownList(
                  "BufferTimeInMinutes",
                  reservationSettingsData.BufferTimeInMinutes
                    ? Math.floor(
                        reservationSettingsData.BufferTimeInMinutes / 60
                      )
                    : 0,
                  hours,
                  "BufferTimeInHours"
                )
              : renderCommmonLabel(
                  reservationSettingsData.BufferTimeInMinutes
                    ? Math.floor(
                        reservationSettingsData.BufferTimeInMinutes / 60
                      ) + " hr (s)"
                    : 0,
                  "lblBufferTimeInHours"
                )}
          </div>
          <div className="col-sm-1 p-0" style={styleMaxWidth7}>
            {isEditMode
              ? renderDropdownList(
                  "BufferTimeInMinutes",
                  reservationSettingsData.BufferTimeInMinutes
                    ? Math.floor(
                        reservationSettingsData.BufferTimeInMinutes % 60
                      )
                    : 0,
                  minutesIntervals,
                  "BufferTimeInMinutes"
                )
              : renderCommmonLabel(
                  reservationSettingsData.BufferTimeInMinutes
                    ? Math.floor(
                        reservationSettingsData.BufferTimeInMinutes % 60
                      ) + " min (s)"
                    : 0,
                  "lblBufferTimeInMinutes"
                )}
          </div>
        </div>

        {renderRadioButtonsWithLabel("Display Deductible", "DisplayDeductible")}
        {renderRadioButtonsWithLabel("Show Tax", "ShowTax")}
        {renderRadioButtonsWithLabel(
          "Show Reservation Processing Fee",
          "ShowReservationProcessingFee"
        )}
        {renderRadioButtonsWithLabel("Show Surcharge", "ShowSurchargeTax")}
        {renderRadioButtonsWithLabel("Allow Feedback", "AllowReview")}
        {renderRadioButtonsWithLabel(
          "Display Vehicles in",
          "DisplayOurCarsWithJsi",
          true
        )}
      </fieldset>
    );
  };
  return props.isInheritModal ? (
    renderMainLayout()
  ) : (
    <Fragment>
      <PreventTransitionPrompt when={isBlocking} />
      <div className="col-lg-12 pt-3 pl-0" id="main-settings">
        <div className="card">
          <div className="card-body">
            <div className="form-header row col-lg-6 p-0">
              <h4 className="text-uppercase mr-auto icon-display-none">
                <i className="fas fa-car-side"></i>
                Reservation Settings
              </h4>
              {hasEditPermission &&
              !isEditMode &&
              !reservationSettingsData.InheritedFromParent ? (
                <LinkButton
                  btnLabel="Edit Settings"
                  containerClass="pseudo-link pr-1 fs-2 font-weight-bold mr-5 d-flex align-items-center"
                  stackClass="fa-stack fs-08 vertical-align-top"
                  faIconList={[
                    "fa fa-circle fa-stack-2x iconBeforeLink",
                    "fa fa-pencil-alt fa-stack-1x whiteColor pr-1",
                  ]}
                  clicked={enableEditMode}
                />
              ) : (
                ""
              )}
              {reservationSettingsData.DisplayInherited ? (
                <div className="icheck-primary pl-2">
                  <input
                    type="checkbox"
                    id="InheritedFromParent"
                    name="InheritedFromParent"
                    checked={reservationSettingsData.InheritedFromParent}
                    onClick={(event) => getInheritConfirmModal(event)}
                    disabled={!hasEditPermission}
                  />
                  <label
                    htmlFor="InheritedFromParent"
                    className="inheritedCheckBoxLabel"
                  >
                    <span>Inherit from Primary Location</span>
                  </label>
                </div>
              ) : (
                ""
              )}
            </div>{" "}
            <div className="col-lg-10 p-0">{renderMainLayout()}</div>
            <div
              className="settingFooter col-sm-6"
              style={{ textAlign: "center" }}
            >
              {!disabled ? (
                <Fragment>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => updateData(reservationSettingsData)}
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    name="className"
                    className="btn btn-secondary ml-2"
                    onClick={() => onCancel()}
                  >
                    Cancel
                  </button>
                </Fragment>
              ) : (
                <div style={{ height: "2.5rem" }}>&nbsp;</div>
              )}
            </div>
          </div>
        </div>
        {loading ? <Spinner /> : ""}
        {showConfirmRemoveInheritModal ? (
          <ConfirmModal
            body={COMMON_MSG.RemoveInheritParentConfirm}
            showModal={showConfirmRemoveInheritModal}
            closeModal={closeConfirmModal}
            doConfirmFunctionality={doInheritFunctionality}
          />
        ) : (
          ""
        )}
        {showInheritFromParentConfirmModal ? (
          <InheritFromParentConfirmModal
            showModal={showInheritFromParentConfirmModal}
            closeModal={closeConfirmModal}
            doInheritFunctionality={doInheritFunctionality}
            currentOrgUnitId={currentOrgUnitId}
            modalWidth="80rem"
            componentName={ReservationSettings}
          />
        ) : (
          ""
        )}
      </div>
    </Fragment>
  );
};

export default ReservationSettings;

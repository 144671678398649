import React from "react";
import { getDateTimeString } from "../../../shared/utility";
const ReservationDetailsSummaryCard = (props) => {
  const summaryData = props.summaryReservationData;
  return (
    <div className="card">
      <div className="card-body">
        <div className="formRow">
          <div className="col-lg-2">
            <div className="form-group row">
              <label className="col-sm-6 col-form-label font-weight-bold">
                Reservation Code
              </label>
              <label className="col-sm-6 col-form-label">
                {summaryData.ConfirmationCode || "N/A"}
              </label>
            </div>
            <div className="form-group row">
              <label className="col-sm-6  col-form-label font-weight-bold">
                Renter Name
              </label>
              <label className="col-sm-6 col-form-label">
                {summaryData.BorrowerName || "N/A"}
              </label>
            </div>
          </div>
          <div className="col-lg-2">
            <div className="form-group row">
              <label className="col-sm-6  col-form-label font-weight-bold">
                Start Date
              </label>
              <label className="col-sm-6 col-form-label">
                {summaryData.ReservationPricingInfo &&
                summaryData.ReservationPricingInfo.StartDate
                  ? getDateTimeString(
                      summaryData.ReservationPricingInfo.StartDate
                    )
                  : "N/A"}
              </label>
            </div>
            <div className="form-group row">
              <label className="col-sm-6  col-form-label font-weight-bold">
                End Date
              </label>
              <label className="col-sm-6  col-form-label">
                {summaryData.ReservationPricingInfo &&
                summaryData.ReservationPricingInfo.EndDate
                  ? getDateTimeString(
                      summaryData.ReservationPricingInfo.EndDate
                    )
                  : "N/A"}
              </label>
            </div>
          </div>
          <div className="col-lg-2">
            <div className="form-group row">
              <label className="col-sm-6  col-form-label font-weight-bold">
                Actual Start Date
              </label>
              <label className="col-sm-6  col-form-label">
                {summaryData.ReservationPricingInfo &&
                summaryData.ReservationPricingInfo.ActualStartDate
                  ? getDateTimeString(
                      summaryData.ReservationPricingInfo.ActualStartDate
                    )
                  : "N/A"}
              </label>
            </div>
            <div className="form-group row">
              <label className="col-sm-6  col-form-label font-weight-bold">
                Actual End Date
              </label>
              <label className="col-sm-6  col-form-label">
                {summaryData.ReservationPricingInfo &&
                summaryData.ReservationPricingInfo.ActualEndDate
                  ? getDateTimeString(
                      summaryData.ReservationPricingInfo.ActualEndDate
                    )
                  : "N/A"}
              </label>
            </div>
          </div>
          <div className="col-lg-2">
            <div className="form-group row">
              <label className="col-sm-6  col-form-label font-weight-bold">
                Expected End Date
              </label>
              <label className="col-sm-6  col-form-label">
                {summaryData.ReservationPricingInfo &&
                summaryData.ReservationPricingInfo.ExpectedEndDate
                  ? getDateTimeString(
                      summaryData.ReservationPricingInfo.ExpectedEndDate
                    )
                  : "N/A"}
              </label>
            </div>
            <div className="form-group row">
              <label className="col-sm-6  col-form-label font-weight-bold">
                Completed On
              </label>
              <label className="col-sm-6  col-form-label">
                {summaryData.ReservationPricingInfo &&
                summaryData.ReservationPricingInfo.CompletedOn
                  ? getDateTimeString(
                      summaryData.ReservationPricingInfo.CompletedOn
                    )
                  : "N/A"}
              </label>
            </div>
          </div>
          <div className="col-lg-2">
            <div className="form-group row">
              <label className="col-sm-6  col-form-label font-weight-bold">
                Reservation Status
              </label>
              <label className="col-sm-6  col-form-label">
                {summaryData.ReservationStatus || "N/A"}
              </label>
            </div>
            <div className="form-group row">
              <label className="col-sm-6  col-form-label font-weight-bold">
                Attendance Status
              </label>
              <label className="col-sm-6  col-form-label">
                {summaryData.AttendanceStatus || "N/A"}
              </label>
            </div>
          </div>
          <div className="col-lg-2">
            <div className="form-group row">
              <label className="col-sm-6  col-form-label font-weight-bold">
                Remaining Time
              </label>
              <label className="col-sm-6  col-form-label">
                {summaryData.RemainingCheckInTimeInMins || "N/A"}
              </label>
            </div>
            <div className="form-group row">
              <label className="col-sm-6  col-form-label font-weight-bold">
                Vehicle Type
              </label>
              <label className="col-sm-6  col-form-label">
                {summaryData.AssetGroupType || "N/A"}
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ReservationDetailsSummaryCard;

import React, { useState, Fragment, useEffect, useRef } from "react";
import { getMemberSubMenu } from "./member-submenu-helper";
import Sidebar from "../../../components/Sidebar/sidebar.jsx";
import SidebarContainer from "../../../components/Sidebar/sidebar-container";
import { getInfoFromParamQuery } from "../../../shared/utility";
function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}
const MemberSubMenu = (props) => {
  let prevProps = usePrevious(props.match.url);
  const [isExpandableSidebar, setIsExpandableSidebar] = useState(true);
  const [memberId, setMemberId] = useState(
    getInfoFromParamQuery("memberId", props.location.search)
  );
  const [breadCrumbList, setBreadCrumbList] = useState(
    props.location.state
      ? props.location.state.breadCrumbList
        ? props.location.state.breadCrumbList.length > 2
          ? [
              props.location.state.breadCrumbList[0],
              props.location.state.breadCrumbList[1],
              props.location.state.breadCrumbList[2],
            ]
          : props.location.state.breadCrumbList
        : []
      : []
  );
  const [memberName, setMeberName] = useState(
    props.location.state
      ? props.location.state.breadCrumbTitle
        ? props.location.state.breadCrumbTitle
        : null
      : null
  );
  const [breadCrumbTitle, setBreadCrumbTitle] = useState(memberName);
  useEffect(() => {
    if (
      (prevProps && prevProps !== props.match.url) ||
      memberId !== getInfoFromParamQuery("memberId", props.location.search)
    ) {
      setBreadCrumbTitle(
        props.location.state
          ? props.location.state.breadCrumbTitle
            ? props.location.state.breadCrumbTitle
            : []
          : []
      );
      const fetchedMemberId = getInfoFromParamQuery(
        "memberId",
        props.location.search
      );
      if (fetchedMemberId !== memberId) {
        setBreadCrumbList(
          props.location.state
            ? props.location.state.breadCrumbList
              ? props.location.state.breadCrumbList
              : []
            : []
        );
        setMeberName(breadCrumbTitle);
        setMemberId(fetchedMemberId);
      }
    }
  });
  const toggleNavbar = () => {
    setIsExpandableSidebar(!isExpandableSidebar);
  };
  const updateBreadcrumData = (memberName) => {
    setMeberName(memberName);
  };

  return (
    <Fragment>
      <Sidebar
        subMenu={getMemberSubMenu(memberId || null)}
        isExpandableSidebar={isExpandableSidebar}
        toggleNavbar={toggleNavbar}
      />
      <div
        className={
          isExpandableSidebar ? "active content-wrapper1" : "content-wrapper1"
        }
      >
        <SidebarContainer
          subMenu={getMemberSubMenu(memberId || null)}
          isExpandableSidebar={isExpandableSidebar}
          toggleNavbar={toggleNavbar}
          updateData={updateBreadcrumData}
          otherData={{ memberName: memberName, breadCrumbList: breadCrumbList }}
        />
      </div>
    </Fragment>
  );
};
export default MemberSubMenu;

import * as actionTypes from "../actions/actionTypes";
import { reducerUpdateObject } from "./reducer.utility";

const initialState = {
  locationBasedData: null,
  loading: false
};

const getLocationBasedSettingsStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};

const getLocationBasedSettingsSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    locationBasedData: action.locationBasedData,
    error: null,
    loading: false
  });
};

const getLocationBasedSettingsFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false
  });
};
const getLocationBasedSettingsReset = (state, action) => {
  return reducerUpdateObject(state, {
    locationBasedData: null
  });
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_LOCATIONBASEDSETTINGS_START:
      return getLocationBasedSettingsStart(state, action);
    case actionTypes.GET_LOCATIONBASEDSETTINGS_SUCCESS:
      return getLocationBasedSettingsSuccess(state, action);
    case actionTypes.GET_LOCATIONBASEDSETTINGS_FAIL:
      return getLocationBasedSettingsFail(state, action);
    case actionTypes.GET_LOCATIONBASEDSETTINGS_RESET:
      return getLocationBasedSettingsReset(state, action);

    default:
      return state;
  }
};
export default reducer;

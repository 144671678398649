import React, { useEffect, useState, useRef, Fragment } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  getHourOfOpsSettingInfo,
  getHourOfOpsSettingInfoReset,
  updateHourOfOpsSettingInfo,
  updateHourOfOpsSettingInfoReset,
} from "../../../store/actions/index";
import Spinner from "../../../components/UI/Spinner/Spinner";
import PermissionName from "../../../shared/Permissions";
import {
  updateObject,
  convertDateTimeString,
  isNullOrEmpty,
  hasPermission,
  getBasicToastResponse,
} from "../../../shared/utility";
import { showNotification } from "../../../components/UI/Toaster/Toaster";
import {
  HoursOfOperationSettingsViewModel,
  HoursOfOperationModel,
} from "../../../services/entities/view-models/settings-view-models";
import LinkButton from "../../../components/UI/button/link-btn";
import ConfirmModal from "../../../components/common/modal/confirm-modal";
import HourOfOperationModal from "./modal/hours-of-operation-modal";
import { COMMON_MSG } from "../../../shared/GlobalVar";
import {
  getNewlyAddedRowInfo,
  getInheritedProperties,
} from "../settingsHelper";
import NoRecordFoundComponent from "../../../components/common/pages/no-record-found";
import InheritFromParentConfirmModal from "../modal/inherit-from-parent-confirm-modal";
import { createTooltip } from "../../../shared/utility/tooltip-utility";
import { AdminDateFromat } from "../../../shared/utility/enum-utility";

const HourOfOperationSettings = (props) => {
  const loading = useSelector((state) => state.settingsReducer.loading);
  const currentOrgUnitId = useSelector(
    (state) => state.auth.loggedInUserData.CurrentOrgUnitId
  );
  const hoursOfOpsSettingInfoResponse = useSelector(
    (state) => state.settingsReducer.hoursOfOpsSettingInfoResponse
  );
  const updateHoursOfOpsSettingResponse = useSelector(
    (state) => state.settingsReducer.hoursOfOpsSettingUpdateResponse
  );
  const intervalRef = useRef();
  const dependentOrgLength = useSelector((state) =>
    state.auth.loggedInUserData.DependantOrgList
      ? state.auth.loggedInUserData.DependantOrgList.length
      : 0
  );
  const dispatch = useDispatch();
  const query = props.location
    ? new URLSearchParams(props.location.search)
    : null;

  const selectedOrgLocationId =
    props.otherData && props.otherData.selectedOrgLocationId
      ? props.otherData.selectedOrgLocationId
      : query
      ? query.get("orgId")
      : currentOrgUnitId;
  const [hoursOfOperationSettingsData, setHoursOfOperationSettingsData] =
    useState(new HoursOfOperationSettingsViewModel());
  const [showHoursOfOperationModal, setShowHoursOfOperationModal] =
    useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [
    showInheritFromParentConfirmModal,
    setShowInheritFromParentConfirmModal,
  ] = useState(false);
  const [showConfirmRemoveInheritModal, setShowConfirmRemoveInheritModal] =
    useState(false);
  const [selectedHoursOfOperationEntity, setSelectedHoursOfOperationEntity] =
    useState(new HoursOfOperationModel());
  const [isHoliday, setIsHoliday] = useState(false);
  const [isHoursOfOperation, setIsHoursOfOperation] = useState(false);
  const [highlightedClass, setHighlightedClass] = useState("");
  const [selectedWorkDayRowId, setSelectedWorkDayRowId] = useState(-1);
  const [selectedHolidayRowId, setSelectedHolidayRowId] = useState(-1);
  const [sortTypeAsc, setSortTypeAsc] = useState();
  const [timer, setTimer] = useState(0);
  const [isDelete, setIsDelete] = useState(false);

  const hasEditPermission = hasPermission(
    PermissionName.EDIT_AVAILABILITY_ORG_SETTINGS
  );
  const getSortCriteria = (isAscending) => [
    { key: "Day", order: "asc" },
    { key: "StartTime", order: isAscending ? "asc" : "desc" },
  ];

  useEffect(() => {
    if (hoursOfOpsSettingInfoResponse && updateHoursOfOpsSettingResponse) {
      intervalRef.current = setInterval(() => {
        setTimer((prevTimer) => prevTimer + 1);
      }, 1000);
    }
    if (timer % 8 === 0) {
      applyHighlightedColor(null, isHoliday);
      clearInterval(intervalRef.current);
    }
  }, [timer]);

  useEffect(() => {
    setHoursOfOperationSettingsData(new HoursOfOperationSettingsViewModel());
    getHoursOfOperationData();
  }, [selectedOrgLocationId]);

  useEffect(() => {    
    if (!isNullOrEmpty(sortTypeAsc)) {
      const sortCriteria = getSortCriteria(sortTypeAsc);
      setHoursOfOperationSettingsData(
        new HoursOfOperationSettingsViewModel(
          hoursOfOpsSettingInfoResponse,
          currentOrgUnitId,
          parseInt(selectedOrgLocationId, 10),
          hoursOfOperationSettingsData.HourFormat,
          sortCriteria
        )
      );
    }
  }, [sortTypeAsc]);

  useEffect(() => {
    if (!hoursOfOpsSettingInfoResponse) {
      return;
    }

    if (hoursOfOpsSettingInfoResponse.StatusCode === 0) {
      if (showInheritFromParentConfirmModal) {
        return;
      }
      setIsDelete(false);
      setHoursOfOperationSettingsData(
        new HoursOfOperationSettingsViewModel(
          hoursOfOpsSettingInfoResponse,
          currentOrgUnitId,
          parseInt(selectedOrgLocationId, 10),
          hoursOfOperationSettingsData.HourFormat
        )
      );
    }
  }, [hoursOfOpsSettingInfoResponse]);

  useEffect(() => {
    if (!updateHoursOfOpsSettingResponse) {
      return;
    }
    showNotification(getBasicToastResponse(updateHoursOfOpsSettingResponse));
    if (updateHoursOfOpsSettingResponse.StatusCode === 0) {
      closeHoursOfOperationModal();
      if (
        hoursOfOpsSettingInfoResponse &&
        updateHoursOfOpsSettingResponse &&
        !updateHoursOfOpsSettingResponse.InheritedFromParent
      ) {
        isHoliday &&
          highlightNewRow(
            hoursOfOpsSettingInfoResponse.ExceptionWorkingDates,
            updateHoursOfOpsSettingResponse.ExceptionWorkingDates
          );
      }
      setIsDelete(false);
      startTimer();

      dispatch(updateHourOfOpsSettingInfoReset());
    }
    dispatch(updateHourOfOpsSettingInfoReset());
  }, [updateHoursOfOpsSettingResponse]);

  const startTimer = () => {
    setTimer(1);
    intervalRef.current = setInterval(() => {
      setTimer((prevTimer) => prevTimer + 1);
    }, 1000);
  };

  const highlightNewRow = (oldData, newData) => {
    if (isHoliday) {
      let newRow = getNewlyAddedRowInfo(oldData, newData, "Date");
      if (newRow.length > 0) {
        applyHighlightedColor(newRow[0].Date, isHoliday);
      }
    }
  };

  const applyHighlightedColor = (selectedRowId, isHoliday) => {
    if (!isNullOrEmpty(selectedRowId)) {
      if (isHoliday) {
        setSelectedHolidayRowId(selectedRowId);
        setHighlightedClass("add-edit-row-highlight");
      } else {
        setSelectedWorkDayRowId(selectedRowId);
        setHighlightedClass("add-edit-row-highlight");
      }
    } else {
      setSelectedHolidayRowId(-1);
      setSelectedWorkDayRowId(-1);
      setHighlightedClass("");
    }
  };

  const inputChangedHandler = (event, controlName, elementType) => {
    let value = null;
    if (elementType === "radio" || elementType === "checkbox") {
      value = event.target.checked;
    }
    let updatedControls = updateObject(hoursOfOperationSettingsData, {
      [controlName]: value,
    });
    if (elementType === "radio") {
      updatedControls = updateObject(hoursOfOperationSettingsData, {
        [controlName]: !hoursOfOperationSettingsData[controlName],
      });
    }
    setHoursOfOperationSettingsData(updatedControls);
  };
  const getHoursOfOperationData = () => {
    const req = {};
    req.SelectedChildOrgUnitId = selectedOrgLocationId;
    dispatch(getHourOfOpsSettingInfoReset());
    dispatch(getHourOfOpsSettingInfo(req));
  };

  const getHoursOfOperationModal = (entity, isHoliday, isHoursOfOperation) => {
    if (entity) {
      setSelectedHoursOfOperationEntity(entity);
    }
    setIsHoliday(isHoliday || false);
    setIsHoursOfOperation(isHoursOfOperation || false);
    setShowHoursOfOperationModal(true);
  };

  const closeHoursOfOperationModal = () => {
    setShowConfirmRemoveInheritModal(false);
    setShowConfirmModal(false);
    setShowInheritFromParentConfirmModal(false);
    setShowHoursOfOperationModal(false);
    setSelectedHoursOfOperationEntity(new HoursOfOperationModel());
  };

  const getConfirmModal = (entity, isHoliday, isHoursOfOperation, isDelete) => {
    if (entity) {
      setSelectedHoursOfOperationEntity(entity);
      applyHighlightedColor(isHoliday ? entity.Date : entity.RowId, isHoliday);
    }
    setIsDelete(isDelete || false);
    setIsHoliday(isHoliday || false);
    setIsHoursOfOperation(isHoursOfOperation || false);
    setShowConfirmModal(true);
  };

  const getInheritConfirmModal = (event) => {
    if (event.target.checked) {
      setShowInheritFromParentConfirmModal(true);
    } else {
      setShowConfirmRemoveInheritModal(true);
    }
  };

  const doInheritFunctionality = () => {
    updateHoursOfOperationData(
      hoursOfOperationSettingsData,
      !showConfirmRemoveInheritModal
    );
  };

  const closeConfirmModal = (cancelReset) => {
    if (showConfirmRemoveInheritModal) {
      setShowConfirmRemoveInheritModal(false);
    } else if (showInheritFromParentConfirmModal) {
      setShowInheritFromParentConfirmModal(false);
    } else {
      setShowConfirmModal(false);
      applyHighlightedColor(null, isHoliday);
      setSelectedHoursOfOperationEntity(new HoursOfOperationModel());
    }
  };
  const resetHoursOfOperationData = () => {
    let updatedEntity = new HoursOfOperationModel();
    updatedEntity.Day = selectedHoursOfOperationEntity.Day;
    updatedEntity.Name = selectedHoursOfOperationEntity.Name;
    updatedEntity.RowId = selectedHoursOfOperationEntity.RowId;
    closeConfirmModal();
    addUpdateHoursOfOperationEntity(updatedEntity, false, true);
  };

  const addUpdateHoursOfOperationEntity = (
    updateEntity,
    isHoliday,
    isHoursOfOperation
  ) => {
    const req = JSON.parse(JSON.stringify(hoursOfOperationSettingsData));
    if (isHoliday) {
      if (isNullOrEmpty(updateEntity.RowId)) {
        req.ExceptionWorkingDates &&
          req.ExceptionWorkingDates.push(updateEntity);
      } else {
        req.ExceptionWorkingDates &&
          req.ExceptionWorkingDates.length > 0 &&
          req.ExceptionWorkingDates.map((entity, index) => {
            if (entity.RowId === updateEntity.RowId) {
              applyHighlightedColor(updateEntity.Date, true);
              req.ExceptionWorkingDates[index] = { ...updateEntity };
            }
          });
      }
    } else {
      if (isHoursOfOperation) {
        if (isNullOrEmpty(updateEntity.RowId)) {
          req.HoursOfOperations && req.HoursOfOperations.push(updateEntity);
        } else if (!isNullOrEmpty(updateEntity.Day)) {
          req.HoursOfOperations &&
            req.HoursOfOperations.length > 0 &&
            req.HoursOfOperations.map((entity, index) => {
              if (
                entity.Day === updateEntity.Day &&
                entity.RowId === updateEntity.RowId
              ) {
                applyHighlightedColor(entity.RowId, false);
                req.HoursOfOperations[index] = { ...updateEntity };
              }
            });
        }
      }
    }
    updateHoursOfOperationData(req);
  };
  const removeHolidayEntity = () => {
    const req = JSON.parse(JSON.stringify(hoursOfOperationSettingsData));
    let entityList = [];
    if (isHoliday) {
      entityList = [...req.ExceptionWorkingDates];
    }
    const entityListFilter = entityList.filter(
      (x) => x.RowId !== selectedHoursOfOperationEntity.RowId
    );
    if (isHoliday) {
      req.ExceptionWorkingDates = [...entityListFilter];
    }
    updateHoursOfOperationData(req);
    closeConfirmModal();
  };
  const removeHoursEntity = () => {
    const req = JSON.parse(JSON.stringify(hoursOfOperationSettingsData));
    let entityList = [];
    if (isHoursOfOperation) {
      entityList = [...req.HoursOfOperations];
    }
    const entityListFilter = entityList.filter(
      (x) => x.RowId !== selectedHoursOfOperationEntity.RowId
    );
    if (isHoursOfOperation) {
      req.HoursOfOperations = [...entityListFilter];
    }
    updateHoursOfOperationData(req);
    closeConfirmModal();
  };

  const deleteItems = (data) => {
    let entity = { ...data };
    delete entity["AddNew"];
    delete entity["StartHourId"];
    delete entity["StartMinuteId"];
    delete entity["StartPeriod"];
    delete entity["EndHourId"];
    delete entity["EndMinuteId"];
    delete entity["EndPeriod"];
    delete entity["RowId"];
    return entity;
  };

  const removeExtraItems = (req) => {
    const newReq = JSON.parse(JSON.stringify(req));
    const workingDayList = [];
    if (newReq.HoursOfOperations && newReq.HoursOfOperations.length > 0) {
      newReq.HoursOfOperations.map((entity) => {
        entity = deleteItems(entity);
        workingDayList.push(entity);
      });
    }
    const holidayList = [];
    if (
      newReq.ExceptionWorkingDates &&
      newReq.ExceptionWorkingDates.length > 0
    ) {
      newReq.ExceptionWorkingDates.map((entity) => {
        entity = deleteItems(entity);
        holidayList.push(entity);
      });
    }

    newReq.HoursOfOperations = [...workingDayList];
    newReq.ExceptionWorkingDates = [...holidayList];
    return newReq;
  };

  const updateHoursOfOperationData = (req, isInheritedFromParent) => {
    let updatedReq = removeExtraItems(req);
    updatedReq.SelectedChildOrgUnitId = selectedOrgLocationId;

    const newReq = {
      ...updatedReq,
      ...getInheritedProperties(
        currentOrgUnitId,
        selectedOrgLocationId,
        !isNullOrEmpty(isInheritedFromParent)
          ? isInheritedFromParent
          : updatedReq.InheritedFromParent,
        dependentOrgLength
      ),
    };

    delete newReq["DisplayInherited"];
    delete newReq["InheritedFromParent"];
    dispatch(updateHourOfOpsSettingInfoReset());
    dispatch(updateHourOfOpsSettingInfo(newReq));
  };

  let editOptionCls = "light-gray-color";
  let deleteOptionCls = "light-gray-color";
  let resetOptionCls = "light-gray-color";
  let inherited = hoursOfOperationSettingsData.InheritedFromParent;
  let disable =
    hoursOfOperationSettingsData.InheritedFromParent ||
    !hasEditPermission ||
    props.isInheritModal
      ? true
      : false;
  if (!disable) {
    editOptionCls = "blue-color cursor-pointer";
    deleteOptionCls = "red-color cursor-pointer";
    resetOptionCls = "blue-color cursor-pointer";
  }
  const hourFormat = isNullOrEmpty(props.hourFormat)
    ? hoursOfOperationSettingsData.HourFormat
    : props.hourFormat;

  const renderWorkdayList = () => {
    if (hoursOfOperationSettingsData.HoursOfOperations.length > 0) {
      return hoursOfOperationSettingsData.HoursOfOperations.map(
        (times, index) => {
          let entity = new HoursOfOperationModel(times, hourFormat);
          return (
            <tr
              className={`${
                selectedWorkDayRowId === entity.RowId ? highlightedClass : ""
              }`}
              key={entity.Day + entity.RowId}
            >
              <td className="text-left">{entity.Name}</td>
              <td>
                {hourFormat
                  ? entity.StartHourId +
                    ":" +
                    entity.StartMinuteId +
                    " " +
                    entity.StartPeriod
                  : entity.StartHourId + ":" + entity.StartMinuteId}
              </td>
              <td>
                {hourFormat
                  ? entity.EndHourId +
                    ":" +
                    entity.EndMinuteId +
                    " " +
                    entity.EndPeriod
                  : entity.EndHourId + ":" + entity.EndMinuteId}
              </td>
              <td className="text-center">
                {isNullOrEmpty(props.isInheritModal) ? (
                  <Fragment>
                    {createTooltip(
                      <i
                        id={"editWorkHour" + index}
                        className={`fas fa-pencil-alt fs-3 pl-1 pr-2 ${editOptionCls}`}
                        aria-hidden="true"
                        onClick={(event) =>
                          disable
                            ? ""
                            : getHoursOfOperationModal(entity, false, true)
                        }
                      />,
                      hasEditPermission
                        ? inherited
                          ? COMMON_MSG.InheritedItemValidationForEdit
                          : "Edit"
                        : COMMON_MSG.DoNotPermissionForEdit +
                            "hours of operation",
                      { forceShow: true }
                    )}

                    {createTooltip(
                      <i
                        id={"resetWorkhours" + index}
                        className={`fa fa-undo fs-2 pl-2 pr-3 ${resetOptionCls}`}
                        aria-hidden="true"
                        onClick={(event) =>
                          disable ? "" : getConfirmModal(entity, false, true)
                        }
                      />,
                      hasEditPermission
                        ? inherited
                          ? COMMON_MSG.InheritedItemValidationForReset
                          : "Reset"
                        : COMMON_MSG.DoNotPermissionForReset +
                            "hours of operation",
                      { forceShow: true }
                    )}

                    {createTooltip(
                      <i
                        id={"deleteHours" + index}
                        className={`fa fa-times fs-3 ${deleteOptionCls}`}
                        aria-hidden="true"
                        onClick={(event) =>
                          disable ? "" : getConfirmModal(entity, false, true, true)
                        }
                      />,
                      hasEditPermission
                        ? inherited
                          ? COMMON_MSG.InheritedItemValidationForDelete
                          : "Delete"
                        : COMMON_MSG.DoNotPermissionForDelete +
                            "hours of operation",
                      { forceShow: true }
                    )}
                  </Fragment>
                ) : null}
              </td>
            </tr>
          );
        }
      );
    } else {
      return (
        <tr>
          <td colSpan="4">
            <NoRecordFoundComponent
              className="border-none"
              innerClassName="no-setting-record-found"
              searchMsg="No Record Found"
              heights="5rem"
            />
          </td>
        </tr>
      );
    }
  };
  const renderHolidayList = () => {
    if (hoursOfOperationSettingsData.ExceptionWorkingDates.length > 0) {
      return hoursOfOperationSettingsData.ExceptionWorkingDates.map(
        (holidays, index) => {
          let entity = new HoursOfOperationModel(holidays, hourFormat);
          return (
            <tr
              className={`${
                String(selectedHolidayRowId) === String(entity.Date)
                  ? highlightedClass
                  : ""
              }`}
              key={index}
            >
              <td className="text-left">
                {moment(convertDateTimeString(entity.Date, "--")).format(
                  AdminDateFromat.COLUMN_FORMAT
                )}
              </td>
              <td>
                {hourFormat
                  ? entity.StartHourId +
                    ":" +
                    entity.StartMinuteId +
                    " " +
                    entity.StartPeriod
                  : entity.StartHourId + ":" + entity.StartMinuteId}
              </td>
              <td>
                {hourFormat
                  ? entity.EndHourId +
                    ":" +
                    entity.EndMinuteId +
                    " " +
                    entity.EndPeriod
                  : entity.EndHourId + ":" + entity.EndMinuteId}
              </td>
              <td className="text-center">
                {isNullOrEmpty(props.isInheritModal) ? (
                  <Fragment>
                    {createTooltip(
                      <i
                        id={"editHoliday" + index}
                        className={`fas fa-pencil-alt fs-3 pl-2 pr-3 ${editOptionCls}`}
                        aria-hidden="true"
                        onClick={(event) =>
                          disable ? "" : getHoursOfOperationModal(entity, true)
                        }
                      />,
                      hasEditPermission
                        ? inherited
                          ? COMMON_MSG.InheritedItemValidationForEdit
                          : "Edit"
                        : COMMON_MSG.DoNotPermissionForEdit + "holiday",
                      { forceShow: true }
                    )}
                    {createTooltip(
                      <i
                        id={"deleteHoliday" + index}
                        className={`fa fa-times fs-3 ${deleteOptionCls}`}
                        aria-hidden="true"
                        onClick={(event) =>
                          disable ? "" : getConfirmModal(entity, true, false, true)
                        }
                      />,
                      hasEditPermission
                        ? inherited
                          ? COMMON_MSG.InheritedItemValidationForDelete
                          : "Delete"
                        : COMMON_MSG.DoNotPermissionForDelete + "holiday",
                      { forceShow: true }
                    )}
                  </Fragment>
                ) : null}
              </td>
            </tr>
          );
        }
      );
    } else {
      return (
        <tr>
          <td colSpan="4">
            <NoRecordFoundComponent
              className="border-none"
              innerClassName="no-setting-record-found"
              searchMsg="No Record Found"
              heights="5rem"
            />
          </td>
        </tr>
      );
    }
  };

  const renderMainLayout = () => {
    return (
      <Fragment>
        <div
          className="w-100 pl-4 pr-4 font-weight-bold d-flex"
          style={{ height: "2.5rem" }}
        >
          <label className="mr-auto fs-2">Hours of Operation: </label>
          {hasEditPermission &&
          !hoursOfOperationSettingsData.InheritedFromParent &&
          !disable ? (
            <LinkButton
              btnLabel="Add New Hours"
              containerClass="pseudo-link pr-1 fs-2 font-weight-bold"
              iconClass="fas fa-plus-circle iconBeforeLink"
              clicked={(event) => getHoursOfOperationModal(null, false, true)}
            />
          ) : (
            ""
          )}
        </div>
        <div
          className="d-flex w-100 pl-4 pr-4 bb-0 table-container position--relative"
          style={{ maxHeight: props.isInheritModal ? "20rem" : "60rem" }}
        >
          <table className="table table-striped">
            <thead className="thead-primary">
              <tr>
                <th>Day</th>
                <th
                  className={`position-relative ${
                    isNullOrEmpty(sortTypeAsc)
                      ? "sorting"
                      : sortTypeAsc
                      ? "sorting_dsc"
                      : "sorting_asc"
                  }`}
                  onClick={() => setSortTypeAsc(!sortTypeAsc)}
                >
                  Start Time
                </th>
                <th>End Time</th>
                <th />
              </tr>
            </thead>
            <tbody>{renderWorkdayList()}</tbody>
          </table>
        </div>

        <div
          className="w-100 pl-4 pr-4 mt-4 font-weight-bold d-flex"
          style={{ height: "2.5rem" }}
        >
          <label className="mr-auto fs-2">Holiday Schedule:</label>
          {hasEditPermission &&
          !hoursOfOperationSettingsData.InheritedFromParent &&
          !disable ? (
            <LinkButton
              btnLabel="Add Holiday"
              containerClass="pseudo-link pr-1 fs-2 font-weight-bold"
              iconClass="fas fa-plus-circle iconBeforeLink"
              clicked={(event) => getHoursOfOperationModal(null, true, false)}
            />
          ) : (
            ""
          )}
        </div>
        <div className="position--relative">
          <div
            className="d-flex w-100 pl-4 pr-4 tableScroll bb-0 table-fixed-head table-container position--relative"
            style={{ maxHeight: props.isInheritModal ? "15rem" : "60rem" }}
          >
            <table className="table table-striped">
              <thead className="thead-primary">
                <tr>
                  <th>Date</th>
                  <th>Start Time</th>
                  <th>End Time</th>
                  <th />
                </tr>
              </thead>
              <tbody>{renderHolidayList()}</tbody>
            </table>
          </div>
        </div>
      </Fragment>
    );
  };

  return props.isInheritModal ? (
    renderMainLayout()
  ) : (
    <div className="w-50 pt-3 pl-0" id="hours-of-operation">
      <div className="card">
        <div className="card-body">
          <div className="form-header row w-100 p-0">
            <h4 className="text-uppercase mr-auto icon-display-none">
              <i className="fas fa-business-time"></i>
              Hours of Operation Settings
            </h4>

            {/* {hoursOfOperationSettingsData.DisplayInherited ? (
              <div className="icheck-primary pl-2">
                <input
                  type="checkbox"
                  id="InheritedFromParent"
                  name="InheritedFromParent"
                  disabled={!hasEditPermission}
                  checked={hoursOfOperationSettingsData.InheritedFromParent}
                  onClick={(event) => getInheritConfirmModal(event)}
                  onChange={() => {}}
                />
                <label
                  htmlFor="InheritedFromParent"
                  className="inheritedCheckBoxLabel"
                >
                  <span>Inherit from Primary Location</span>
                </label>
              </div>
            ) : (
              ""
            )} */}
          </div>
          <div className="vertical-align-center">
            <div className="d-flex fs-1 pb-1 w-40 underLine">
              <label className="w-50 col-form-label font-weight-bold pr-4 text-center">
                Display Clock
              </label>
              <div className="w-50 mlr-4 d-flex">
                <div className="w-50">
                  <div className="icheck-primary">
                    <input
                      type="radio"
                      name="HourFormat"
                      id="rbtnTwelve"
                      onChange={(event) =>
                        inputChangedHandler(event, "HourFormat", "radio")
                      }
                      checked={hoursOfOperationSettingsData.HourFormat}
                    />
                    <label htmlFor="rbtnTwelve">
                      <span>12-hour</span>
                    </label>
                  </div>
                </div>

                <div className="w-50">
                  <div className="icheck-primary">
                    <input
                      type="radio"
                      name="HourFormat"
                      id="rbtnNonTwelve"
                      onChange={(event) =>
                        inputChangedHandler(event, "HourFormat", "radio")
                      }
                      checked={!hoursOfOperationSettingsData.HourFormat}
                    />
                    <label htmlFor="rbtnNonTwelve">
                      <span>24-hour</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {renderMainLayout()}
        </div>
        <div style={{ height: "2.5rem" }}>&nbsp;</div>
      </div>
      {loading ? <Spinner /> : ""}
      {showHoursOfOperationModal ? (
        <HourOfOperationModal
          showModal={showHoursOfOperationModal}
          closeModal={closeHoursOfOperationModal}
          hoursOfOperationData={hoursOfOperationSettingsData}
          hourFormat={hoursOfOperationSettingsData.HourFormat}
          isHoliday={isHoliday}
          isHoursOfOperation={isHoursOfOperation}
          selectedEntity={selectedHoursOfOperationEntity}
          addUpdateHoursOfOperationEntity={addUpdateHoursOfOperationEntity}
        />
      ) : (
        ""
      )}
  
      {showConfirmModal ? (
        <ConfirmModal
          body={
              isDelete
              ? COMMON_MSG.DeleteRecordMsg
              : isHoursOfOperation && "Are you sure you want to reset the hours of operation?"
          }
          showModal={showConfirmModal}
          closeModal={closeConfirmModal}
          doConfirmFunctionality={
            isDelete
              ? isHoliday ? removeHolidayEntity : removeHoursEntity
              : isHoursOfOperation && resetHoursOfOperationData
          }
        />
      ) : (
        ""
      )}

      {showConfirmRemoveInheritModal ? (
        <ConfirmModal
          body={
            showConfirmModal
              ? isHoliday
                ? COMMON_MSG.DeleteRecordMsg
                : "Are you sure you want to reset the hours of operation?"
              : COMMON_MSG.RemoveInheritParentConfirm
          }
          showModal={showConfirmModal || showConfirmRemoveInheritModal}
          closeModal={closeConfirmModal}
          doConfirmFunctionality={
            showConfirmModal
              ? isHoliday
                ? removeHolidayEntity
                : resetHoursOfOperationData
              : doInheritFunctionality
          }
        />
      ) : (
        ""
      )}

      {showInheritFromParentConfirmModal ? (
        <InheritFromParentConfirmModal
          showModal={showInheritFromParentConfirmModal}
          closeModal={closeConfirmModal}
          doInheritFunctionality={doInheritFunctionality}
          currentOrgUnitId={currentOrgUnitId}
          modalWidth="60rem"
          componentName={HourOfOperationSettings}
          hourFormat={hoursOfOperationSettingsData.HourFormat}
        />
      ) : (
        ""
      )}
    </div>
  );
};
export default React.memo(HourOfOperationSettings);

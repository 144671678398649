import React from "react";
import Draggable from "react-draggable";
import { Modal, ModalHeader, ModalBody } from "reactstrap";

export const ReservationActionSummaryModal = (props) => {
  const defaultData = props.defaultData;
  return (
    <Draggable handle=".cursor">
      <Modal
        isOpen={props.showModal}
        className="no-cursor"
        toggle={() => {
          props.closeModal();
        }}
        style={{
          minWidth: "40rem",
        }}
      >
        <ModalHeader
          className="modal-header bg-primary modal-title text-white cursor"
          toggle={() => {
            props.closeModal();
          }}
        >
          <i className="fas fa-arrows-alt" /> Action Summary
        </ModalHeader>
        <ModalBody>
          <div className="col-lg-12 p-0">
            <div className="form-group row p-0">
              <label className="col-sm-4 col-form-label font-weight-bold">
                Adjusted By
              </label>
              <label className="col-sm-8">
                {defaultData.adjustedBy || "--"}
              </label>
            </div>
            <div className="form-group row p-0">
              <label className="col-sm-4 col-form-label font-weight-bold">
                Arrived By
              </label>
              <label className="col-sm-8">
                {defaultData.arrivedBy || "--"}
              </label>
            </div>
            <div className="form-group row p-0">
              <label className="col-sm-4 col-form-label font-weight-bold">
                Completed By
              </label>
              <label className="col-sm-8">
                {defaultData.completedBy || "--"}
              </label>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </Draggable>
  );
};
export default ReservationActionSummaryModal;

import React from 'react';
import MemberDiscount from '../../containers/Member/discount/member-discount';
import GrantDiscount from '../../containers/Reservation/discount/grant-discount';
import LocationInfo from '../../containers/Reservation/fox-reservation/fox-modal/location-modal/location-info';
import { ModalType } from '../../shared/GlobalVar';
import CustomerReservationHistory from '../reservation/customer-reservation-history';
import InvoiceComponent from '../reservation/invoice-component';
import TransactionComponent from '../reservation/transaction-component';
import ModalWrapper from './modal-wrapper';

function ModernModalEngine(props) {
    const { modalType, modalProps, modalBodyProps } = props;
    return (
        <ModalWrapper
            {...modalProps}
        >
            {modalType === ModalType.MEMBER_DISCOUNT_MODAL ?
                <MemberDiscount
                    {...modalBodyProps}
                />
                : ""}
            {modalType === ModalType.GRANT_DISCOUNT_MODAL ?
                <GrantDiscount
                    {...modalBodyProps}
                />
                : ""}
            {modalType === ModalType.LOCATION_QUICK_MODAL ?
                <LocationInfo
                    {...modalBodyProps}
                />
                : ""}
            {modalType === ModalType.INVOICE_INFO_MODAL ?
                <InvoiceComponent
                    {...modalBodyProps}
                />
                : ""}
            {modalType === ModalType.TRANSACTION_MODAL ?
                <TransactionComponent
                    {...modalBodyProps}
                />
                : ""}
            {modalType === ModalType.CUSTOMER_RESERVATION_HISTORY_MODAL ?
                <CustomerReservationHistory
                    {...modalBodyProps}
                />
                : ""}
        </ModalWrapper>
    )
}

export default ModernModalEngine
import {
  isPossiblePhoneNumber,
  parsePhoneNumberFromString as parsePhoneNumber,
} from "libphonenumber-js";
import { getMobileCountry } from "../json/MobileCountry";

export const getFormattedPhoneNumber = (phoneNumber, countryIso) => {
  let formattedPhoneNumber = "";
  if (phoneNumber && countryIso) {
    try {
      if (String(phoneNumber).length > 1) {
        formattedPhoneNumber =
          countryIso && phoneNumber
            ? parsePhoneNumber(
                String(phoneNumber),
                countryIso.toUpperCase()
              ).formatNational()
            : "";
      } else {
        formattedPhoneNumber = String(phoneNumber);
      }
    } catch (error) {
      console.error("Error parsing phone number:", error.message);
    }
  }

  // removing automatically added leading zero
  if (formattedPhoneNumber.startsWith("0")) {
    formattedPhoneNumber = formattedPhoneNumber.slice(1);
  }
  return formattedPhoneNumber;
};

export const isPhoneNumber = (phoneNumber, countryIso) => {
  return isPossiblePhoneNumber(phoneNumber, countryIso) === true;
};

export const getNormalFormattedPhoneNumber = (phone) => {
  let clonedPhone = { ...phone };
  return clonedPhone && clonedPhone.FormattedNumber
    ? "+" + clonedPhone.CountryCode + " " + clonedPhone.FormattedNumber
    : "--";
};

const hasPlusSign = (dialCode) => {
  return /^\+/.test(dialCode);
};

export const getCountryIsoByDialCode = (dialCode) => {
  const countries = getMobileCountry();
  dialCode = hasPlusSign(dialCode) ? dialCode : "+" + dialCode;
  const foundCountry = countries.find((item) => item.value === dialCode);
  return foundCountry ? foundCountry.countryIso : null;
};

import React, { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import TooltipItem from "../../components/tooltip/TooltipItem";
import {
  getNoteTooltipHtml,
  hasPermission,
  convertDateTimeString,  
  populateBreadCrumbData,
} from "../../shared/utility";
import { getMemberLabelByOrgRentalType } from "../../shared/utility/system-utility";
import PermissionName from "../../shared/Permissions";
import {
  CAR_GARAGE_COLOR,
  USER_RED,
  CAR_RENTED_OUT,
  CLOCK_STROKE_WHITE,
} from "../../shared/AppImages";
import {
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  UncontrolledDropdown,
} from "reactstrap";
import { getLocalStoragePrefix } from "../../shared/LocalStorage";
import { createTooltip } from "../../shared/utility/tooltip-utility";

const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

const QuickHistoryDropdown = (props) => {
  const preFix = getLocalStoragePrefix("QuickHistory");
  const quickHistoryData = localStorage.getItem(preFix)
    ? JSON.parse(localStorage.getItem(preFix))
    : [];
  const previousHistoryData = usePrevious(quickHistoryData);
  const [currentHistoryData, setCurrentHistoryData] = useState([]);

  const [showDropMenu, setShowDropMenu] = useState(false);
  const [memberList, setMemberList] = useState([]);

  const [reservationList, setReservationList] = useState([]);
  const [rentalList, setRentalList] = useState([]);
  const [totalHistory, setTotalHistory] = useState(0);

  useEffect(() => {
    if (previousHistoryData !== currentHistoryData) {
      setMemberList(quickHistoryData.MemberList || []);
      setReservationList(quickHistoryData.ReservationList || []);
      setRentalList(quickHistoryData.RentalList || []);
      let memberCount = quickHistoryData.MemberList
        ? quickHistoryData.MemberList.length
        : 0;
      let resCount = quickHistoryData.ReservationList
        ? quickHistoryData.ReservationList.length
        : 0;
      let rentalCount = quickHistoryData.RentalList
        ? quickHistoryData.RentalList.length
        : 0;
      setTotalHistory(memberCount + resCount + rentalCount);

      setCurrentHistoryData(quickHistoryData);
    }
  });
  const openDropMenu = () => {
    if (!showDropMenu) {
      setShowDropMenu(true);
    }
  };
  const closeDropMenu = () => {
    setShowDropMenu(showDropMenu ? false : true);
  };
  const renderColumnCell = (value, tooltipValue, forceShow, id) => {
    return (
      <span className="row__column" id={id}>
        {createTooltip(value, tooltipValue ? tooltipValue : value, {
          forceShow: forceShow ? forceShow : false,
          modalId: "historyDropMenu",
        })}
      </span>
    );
  };
  const renderMemberData = () => {
    let renderableData = null;
    if (memberList.length > 0) {
      const breadCrumbList = populateBreadCrumbData(
        [],
        getMemberLabelByOrgRentalType(),
        "/member"
      );
      const tableData = memberList.map((post) => {
        return (
          <div className="row" key={post.Id}>
            {renderColumnCell(
              <Link
                className={`${
                  hasPermission(PermissionName.VIEW_MEMBER)
                    ? ""
                    : "noAnchorLink"
                }`}
                to={{
                  pathname: "/member/memberProfile",
                  search: "memberId=" + post.Id,
                  state: {
                    breadCrumbList: breadCrumbList,
                    breadCrumbTitle: post.Name,
                  },
                }}
              >
                {post.MembershipId || "--"}
              </Link>,
              post.MembershipId
            )}
            {renderColumnCell(post.Name)}
            {renderColumnCell(post.EmailAddress)}
            {renderColumnCell(post.MobilePhone, post.MobilePhoneTooltip)}
            {renderColumnCell(
              post.AccessTime ? convertDateTimeString(post.AccessTime) : "--",
              null,
              true
            )}
          </div>
        );
      });
      renderableData = (
        <div className="datagrid">
          <div className="column">
            <span className="column__header_dropmenu">
              {getMemberLabelByOrgRentalType()} ID
            </span>
            <span className="column__header_dropmenu">Name</span>
            <span className="column__header_dropmenu">Email Address</span>
            <span className="column__header_dropmenu">Phone</span>
            <span className="column__header_dropmenu">Access Time</span>
          </div>
          <div className="tableScroll">{tableData}</div>
        </div>
      );
    }
    return !renderableData ? (
      <h4 className="dropmenu_no_record">
        You have not viewed any {getMemberLabelByOrgRentalType()} profile yet
      </h4>
    ) : (
      renderableData
    );
  };
  const renderResevationRentalData = (isRental) => {
    let renderableData = null;
    const reservationRentalList = isRental
      ? [...rentalList]
      : [...reservationList];
    if (reservationRentalList.length > 0) {
      const breadCrumbList = populateBreadCrumbData(
        [],
        isRental ? "Rental" : "Reservation",
        isRental ? "/rental" : "/reservation"
      );
      const tableData = reservationRentalList.map((post) => {
        return (
          <div className="row" key={post.Id}>
            {renderColumnCell(
              <Link
                className={`${
                  hasPermission(PermissionName.VIEW_RESERVATION)
                    ? ""
                    : "noAnchorLink"
                }`}
                to={{
                  pathname: "/reservationSummary",
                  search: "?reservationId=" + post.Id,
                  state: { breadCrumbList: breadCrumbList },
                }}
              >
                {post.ReservationCode || "--"}
              </Link>,
              post.ReservationCode
            )}
            {renderColumnCell(
              post.ReservationState,
              null,
              null,
              isRental
                ? "rentalReservationState"
                : "reservationReservationState" + post.Id
            )}
            {renderColumnCell(post.Name)}
            {renderColumnCell(
              post.StartDate ? convertDateTimeString(post.StartDate) : "--",
              null,
              true
            )}
            {renderColumnCell(
              post.EndDate ? convertDateTimeString(post.EndDate) : "--",
              null,
              true
            )}

            {renderColumnCell(
              post.PickupLocation,
              null,
              null,
              isRental
                ? "rentalPickupLocation"
                : "reservationPickupLocation" + post.Id
            )}
            {renderColumnCell(
              post.AccessTime ? convertDateTimeString(post.AccessTime) : "--",
              null,
              true
            )}
          </div>
        );
      });
      renderableData = (
        <div className="datagrid">
          <div className="column">
            <span className="column__header_dropmenu">
              {isRental ? "Rental Code" : "Res. Code"}
            </span>
            <span className="column__header_dropmenu">
              {isRental ? "Rental Status" : "Res. Status"}
            </span>
            <span className="column__header_dropmenu">Name</span>
            <span className="column__header_dropmenu">Start Date/Time</span>
            <span className="column__header_dropmenu">End Date/Time</span>
            <span className="column__header_dropmenu">Pickup Location</span>
            <span className="column__header_dropmenu">Access Time</span>
          </div>
          <div className="tableScroll">{tableData}</div>
        </div>
      );
    }
    return !renderableData ? (
      <h4 className="dropmenu_no_record">
        You have not viewed any {isRental ? "rental" : "reservation"} yet
      </h4>
    ) : (
      renderableData
    );
  };
  return (
    <UncontrolledDropdown
      isOpen={showDropMenu}
      toggle={closeDropMenu}
      onClick={(event) => {
        openDropMenu();
      }}
      className="historyDropDown"
    >
      <DropdownToggle
        tag="div"
        className="user-nav__icon-box vertical-align-center"
        id="quick-history-dropdown-id"
      >
        <img src={CLOCK_STROKE_WHITE} alt="history" style={{ height: "2.4rem" }} />
        <span className="user-nav__notification">{totalHistory}</span>
        <TooltipItem
          placement="bottom"
          toolTipId="quick-history-dropdown-id"
          renderableHtml={ReactHtmlParser(
            getNoteTooltipHtml("Click to show history")
          )}
          forceShow={true}
        />
      </DropdownToggle>
      {showDropMenu ? (
        <DropdownMenu right className="drop-menu-border">
          <DropdownItem>
            <div id="historyDropMenu" className="pb-5">
              <h4 className="form-header text-uppercase">
                <div className="pl-2  mt-3">
                  <img width="50" height="50" src={USER_RED} alt="" />
                  {getMemberLabelByOrgRentalType()} History
                </div>
              </h4>
              <div
                className="grid-table col-lg-12"
                id="MemberHistroyTable"
                style={{ width: "85rem" }}
              >
                {renderMemberData()}
              </div>
              <h4 className="form-header text-uppercase">
                <div className="pl-2  mt-3">
                  <img width="50" height="50" src={CAR_GARAGE_COLOR} alt="" />
                  Reservation History
                </div>
              </h4>
              <div
                className="grid-table col-lg-12"
                id="ReservationHistroyTable"
                style={{ width: "85rem" }}
              >
                {renderResevationRentalData(false)}
              </div>
              <h4 className="form-header text-uppercase">
                <div className="pl-2  mt-3">
                  <img width="50" height="50" src={CAR_RENTED_OUT} alt="" />
                  Rental History
                </div>
              </h4>
              <div
                className="grid-table col-lg-12"
                id="RentalHistroyTable"
                style={{ width: "85rem" }}
              >
                {renderResevationRentalData(true)}
              </div>
            </div>
          </DropdownItem>
        </DropdownMenu>
      ) : (
        ""
      )}
    </UncontrolledDropdown>
  );
};
export default QuickHistoryDropdown;

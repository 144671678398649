import PageInfo from "../../../services/entities/models/PageInfo";
import { ADMIN_COMMON_CAPTION } from "../../../shared/GlobalVar";
import { getMemberLabelByOrgRentalType } from "../../../shared/utility/system-utility";
export const getSearchRolesPermissionCtrls = () => {
  return {
    PageInfo: new PageInfo(null, null, "Name", "ASC"),
    isAdvancedSearch: false,
    MembershipId: {
      id: "MembershipId",
      elementType: "input",
      elementConfig: {
        name: "MembershipId",
        type: "text",
        placeholder:  getMemberLabelByOrgRentalType() + ' or Loyalty ID'
      },
      validation: {
        required: false,
      },
      labelInput: {
        labeltext: ADMIN_COMMON_CAPTION.CUSTOMER_ID,
      },
      value: "",
      valid: false,
      touched: false,
      basicOrder: 1,
      advancedOrder: 1,
    },
    FirstName: {
      id: "FirstName",
      elementType: "input",
      elementConfig: {
        name: "FirstName",
        type: "text",
      },
      validation: {
        required: false,
      },
      labelInput: {
        labeltext: "First Name",
      },
      value: "",
      valid: false,
      touched: false,
      basicOrder: 0,
      advancedOrder: 2,
    },
    FullName: {
      id: "FullName",
      elementType: "input",
      elementConfig: {
        name: "FullName",
        type: "text",
      },
      validation: {
        required: false,
      },
      labelInput: {
        labeltext: "Name",
      },
      value: "",
      valid: false,
      touched: false,
      basicOrder: 2,
      advancedOrder: 0,
    },

    RoleId: {
      id: "RoleId",
      elementType: "custom_dropdown",
      elementConfig: {
        name: "RoleId",
        options: [],
      },
      labelInput: {
        labeltext: "Role(s)",
      },
      key: "",
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      basicOrder: 3,
      advancedOrder: 3,
    },

    LastName: {
      id: "LastName",
      elementType: "input",
      elementConfig: {
        name: "LastName",
        type: "text",
      },
      validation: {
        required: false,
      },
      labelInput: {
        labeltext: "Last Name",
      },
      value: "",
      valid: false,
      touched: false,
      basicOrder: 0,
      advancedOrder: 7,
    },
    Phone: {
      id: "Phone",
      elementType: "input",
      elementConfig: {
        name: "Phone",
        type: "text",
      },
      validation: {
        required: false,
      },
      labelInput: {
        labeltext: "Phone Number",
      },
      value: "",
      valid: false,
      touched: false,
      basicOrder: 6,
      advancedOrder: 6,
    },
    Title: {
      id: "Title",
      elementType: "input",
      elementConfig: {
        name: "Title",
        type: "text",
      },
      validation: {
        required: false,
      },
      labelInput: {
        labeltext: "Title",
      },
      value: "",
      valid: false,
      touched: false,
      basicOrder: 0,
      advancedOrder: 11,
    },
    Department: {
      id: "Department",
      elementType: "input",
      elementConfig: {
        name: "Department",
        type: "text",
      },
      validation: {
        required: false,
      },
      labelInput: {
        labeltext: "Department",
      },
      value: "",
      valid: false,
      touched: false,
      basicOrder: 0,
      advancedOrder: 12,
    },

    EmailAddress: {
      id: "EmailAddress",
      elementType: "input",
      elementConfig: {
        name: "EmailAddress",
        type: "text",
      },
      validation: {
        required: false,
      },
      labelInput: {
        labeltext: "Email/ Username",
      },
      value: "",
      valid: false,
      touched: false,
      basicOrder: 4,
      advancedOrder: 8,
    },
    EmployeeLocationId: {
      id: "EmployeeLocationId",
      elementType: "select",
      elementConfig: {
        name: "EmployeeLocationId",
        options: [{ key: "", displayValue: "Any Location" }],
      },
      labelInput: {
        labeltext: "Employee Loc",
      },
      value: "",
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      basicOrder: 9,
      advancedOrder: 14,
    },

    EmployeeNo: {
      id: "EmployeeNo",
      elementType: "input",
      elementConfig: {
        name: "EmployeeNo",
        type: "text",
      },
      validation: {
        required: false,
      },
      labelInput: {
        labeltext: "Employee #",
      },
      value: "",
      valid: false,
      touched: false,
      basicOrder: 8,
      advancedOrder: 13,
    },
    ApplicationAccess: {
      id: "ApplicationAccess",
      elementType: "select",
      elementConfig: {
        name: "ApplicationAccess",
        options: [
          { key: "", displayValue: "All" },
          { key: "true", displayValue: "Allowed" },
          { key: "false", displayValue: "Not Allowed" },
        ],
      },
      labelInput: {
        labeltext: "App Access",
      },
      value: "",
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      basicOrder: 0,
      advancedOrder: 4,
    },

    IsEmployee: {
      id: "IsEmployee",
      elementType: "select",
      elementConfig: {
        name: "IsEmployee",
        options: [
          { key: "", displayValue: "All" },
          { key: "true", displayValue: "Employee" },
          { key: "false", displayValue: "Not Employee" },
        ],
      },
      labelInput: {
        labeltext: getMemberLabelByOrgRentalType("Type"),
      },
      value: "true",
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      basicOrder: 7,
      advancedOrder: 9,
    },
    LocationType: {
      id: "LocationType",
      name: "LocationType",
      elementType: "select",
      elementConfig: {
        name: "LocationType",
        options: [],
      },
      labelInput: {
        labeltext: "Location Type",
      },
      value: "",
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      basicOrder: 0,
      advancedOrder: 16,
    },

    IncludeInactive: {
      id: "IncludeInactive",
      elementType: "input",
      elementConfig: {
        name: "IncludeInactive",
        type: "checkbox",
        placeholder: "Show Inactive",
      },
      labelInput: {
        labeltext: "Show Inactive",
        labelFor: "IncludeInactive",
      },
      value: "",
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      basicOrder: 0,
      advancedOrder: 16,
    },
    btnResSearch: {
      id: "btnResSearch",
      elementType: "button",
      elementConfig: {
        type: "submit",
        value: "Search",
        name: "btnResSearch",
        className: "btn btn-primary search-btn",
      },
      basicOrder: 5,
      advancedOrder: 5,
    },
    btnResSearchReset: {
      id: "btnResSearchReset",
      elementType: "button",
      elementConfig: {
        type: "button",
        value: "Reset",
        name: "btnResSearchReset",
        className: "btn btn-secondary search-btn",
      },
      basicOrder: 10,
      advancedOrder: 10,
    },
    empty: {
      id: "empty",
      elementType: "empty",
      elementConfig: {
        name: "",
        type: "empty",
      },
      basicOrder: 0,
      advancedOrder: 15,
    },
    empty1: {
      id: "empty",
      elementType: "empty",
      elementConfig: {
        name: "",
        type: "empty",
      },
      basicOrder: 0,
      advancedOrder: 20,
    },
  };
};

import React from 'react';

function ImageIcon(props) {
    const { img, title, onClick, iconClass } = props;
    return (
        <div title={title} className='img-icon' onClick={onClick}>
            {img ?
                <img
                    className={iconClass}
                    src={img}
                    height={16}
                    width={16}
                    alt={title}
                />
                : <i className={`${iconClass}`} onClick={onClick}></i>
            }
        </div>
    )
}

export default ImageIcon
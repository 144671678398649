import * as actionTypes from "../actions/actionTypes";
import { reducerUpdateObject } from "./reducer.utility";

const initialState = {
  locationData: null,
  loading: false
};

const getLocationDataStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};

const getLocationDataSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    locationData: action.locationData,
    error: null,
    loading: false
  });
};

const getLocationDataFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false
  });
};
const getLocationDataReset = (state, action) => {
  return reducerUpdateObject(state, {
    locationData: null
  });
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_LOCATION_DATA_START:
      return getLocationDataStart(state, action);
    case actionTypes.GET_LOCATION_DATA_SUCCESS:
      return getLocationDataSuccess(state, action);
    case actionTypes.GET_LOCATION_DATA_FAIL:
      return getLocationDataFail(state, action);
    case actionTypes.GET_LOCATION_DATA_RESET:
      return getLocationDataReset(state, action);

    default:
      return state;
  }
};
export default reducer;

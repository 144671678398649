import React from 'react';
import { PaymentType } from '../../../shared/utility/enum-utility';
import AddonsBlock from './component/classic/addons-block';
import PaymentsCreditsBlock from './component/classic/payments-credits-block';
import PricingRateBlock from './component/classic/pricing-rate-block';
import TaxesFeesBlock from './component/classic/taxes-fees-block';
import GeneralRateRow from './component/general-rate-row';
import "./pricing.scss";

function ClassicPricingTable(props) {
    const { pricingData, currentPayType, customerFreeCredit, reservationStatus } = props;
    const { ratesApplied = [], addOns = [], taxes = [], surcharges = [], loyalties = [] } =
        pricingData || {};
    const totalCharges = pricingData?.totalCharges;
    let totalFreeCredits =
        pricingData?.freeCreditAmountApplied;
    let balanceDue = pricingData?.balanceDue;
    if (customerFreeCredit &&
        (currentPayType === PaymentType.PAYLATER ||
            reservationStatus === "PAYMENT_PENDING")
    ) {
        totalFreeCredits = customerFreeCredit > totalCharges ? totalCharges : customerFreeCredit;
        balanceDue = totalCharges - totalFreeCredits;
    }
    const hasPaymentsAndCredits =
        (!isNaN(pricingData.totalCancellationCharges) && pricingData.totalCancellationCharges !== 0) ||
        (!isNaN(pricingData.totalCancellationFee) && pricingData.totalCancellationFee !== 0) ||
        (!isNaN(pricingData.cancellationCourtesyAmount) && pricingData.cancellationCourtesyAmount !== 0) ||
        (!isNaN(totalFreeCredits) && totalFreeCredits !== 0) ||
        (!isNaN(pricingData.totalCreditsApplied) && pricingData.totalCreditsApplied !== 0) ||
        (!isNaN(pricingData.totalPayments) && pricingData.totalPayments !== 0) ||
        (!isNaN(pricingData.totalPaymentsAndCredits) && pricingData.totalPaymentsAndCredits !== 0) ||
        balanceDue !== totalCharges;
    if (hasPaymentsAndCredits) return "";
    return (
        <div className="common-res-container">
            <div className="pricing-container">
                <PricingRateBlock appliedRates={ratesApplied} rentalCharge={pricingData?.ratePlusLate} />
                <AddonsBlock addOns={addOns} loyalties={loyalties} />
                <TaxesFeesBlock taxes={taxes} surcharges={surcharges}
                    extensionFee={pricingData?.totalExtensionFee}
                    damageCharges={pricingData?.totalDamages}
                />
                <GeneralRateRow
                    key="total-charge"
                    contClasses={balanceDue === totalCharges ? "total-estimated-charge classic-total-bg fs-6" : "font-bold"}
                    title="Total"
                    total={totalCharges}
                />
                <PaymentsCreditsBlock
                    {...props}
                />
            </div>
        </div>
    )
}

export default ClassicPricingTable
import React, { Fragment } from "react";
import Rating from "react-rating";
import Utility from "../../../shared/utility";
const VehicleReviewUI = (props) => {
  const getRating = (rating) => {
    return (
      <Rating
        initialRating={rating || 0}
        readonly={true}
        stop={5}
        fractions={2}
        emptySymbol={[
          "far fa-star fa-2x",
          "far fa-star fa-2x",
          "far fa-star fa-2x",
          "far fa-star fa-2x",
          "far fa-star fa-2x",
        ]}
        fullSymbol={[
          "fas fa-star fa-2x text-warning",
          "fas fa-star fa-2x text-warning",
          "fas fa-star fa-2x text-warning",
          "fas fa-star fa-2x text-warning",
          "fas fa-star fa-2x text-warning",
        ]}
      />
    );
  };
  return (
    <div className="formRow">
      <h4 className="form-header text-uppercase  col-lg-12">
        <i className="fas fa-user-circle" />
        Review
      </h4>
      <div className="col-lg-12 row">
        {props.reviews &&
          props.reviews.map((reviewObj, index) => (
            <Fragment>
              <div className="col-lg-12 row">
                <img
                  src={reviewObj.PersonProfileImage}
                  alt="Image"
                  className="review-img"
                ></img>
                <div>
                  <h3>{reviewObj.PersonName}</h3>
                  <div className="form-group row col-sm-12">
                    <label className="col-sm-4 col-form-label font-weight-bold">
                      Friendliness
                    </label>
                    <label className="col-sm-8">
                      {getRating(reviewObj.Friendliness)}
                    </label>
                  </div>
                  <div className="form-group row col-sm-12">
                    <label className="col-sm-4 col-form-label font-weight-bold">
                      Condition
                    </label>
                    <label className="col-sm-8">
                      {getRating(reviewObj.Condition)}
                    </label>
                  </div>
                  <div className="form-group row col-sm-12">
                    <label className="col-sm-4 col-form-label font-weight-bold">
                      Parking
                    </label>
                    <label className="col-sm-8">
                      {getRating(reviewObj.Parking)}
                    </label>
                  </div>
                  <div className="form-group row col-sm-12">
                    <label className="col-sm-4 col-form-label font-weight-bold">
                      Accurate
                    </label>
                    <label className="col-sm-8">
                      {getRating(reviewObj.Accurate)}
                    </label>
                  </div>
                </div>
                {/* <div className="col-sm-5">
                  <div className="form-group row">
                    <label className="col-sm-4 col-form-label font-weight-bold">
                      Comment
                    </label>
                    <div className="col-sm-8">
                      <label>{reviewObj.Experience}</label>
                    </div>
                  </div>
                </div> */}
              </div>
            </Fragment>
          ))}
      </div>
    </div>
  );
};
export default VehicleReviewUI;

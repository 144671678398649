import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  markedVehicleOffline,
  markedVehicleOfflineReset,
} from "../../../../store/actions/index";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Draggable from "react-draggable";
import { showNotification } from "../../../../components/UI/Toaster/Toaster";
import {
  getBasicToastResponse,
  updateObject,
} from "../../../../shared/utility";
import {
  getPriorities,
  getProblemCategories,
} from "../../../vehicle/vehicle-helper";
import { MarkVehicleStatusInputRequset } from "../../../../services/entities/requests/VehicleDetailsRequest";
import CommonInputField from "../../../../components/UI/Input/common-input-field";
import { LabelAndValuePairControl } from "../../../../components/UI/Input/label-value-pair-control";
import { useDispatch, useSelector } from "react-redux";

const MarkVehicleStatus = (props) => {
  const markedVehicleOfflineResponse = useSelector(
    (state) => state.vehicleReducer.markedVehicleOfflineResponse
  );
  const dispatch = useDispatch();
  const problemCategories = getProblemCategories();
  const priorities = getPriorities();
  const [
    markVehicleStatusInputRequest,
    setMarkVehicleStatusInputRequest,
  ] = useState(new MarkVehicleStatusInputRequset());
  useEffect(() => {
    if (!markedVehicleOfflineResponse) {
      return;
    }
    showNotification(getBasicToastResponse(markedVehicleOfflineResponse));
    if (markedVehicleOfflineResponse.StatusCode === 0) {
      props.closeModal();
      dispatch(markedVehicleOfflineReset());
    }
    dispatch(markedVehicleOfflineReset());
  }, [markedVehicleOfflineResponse]);

  const handleOnChange = (event, controlName, elementType) => {
     let value =
       elementType === "checkbox"
         ? event.target.checked
         : event !== "Invalid date" && elementType === "datetimepicker"
         ? event
           ? moment(event).format("MM:DD:YYYY:HH:mm")
           : event
         : event.target.value;

    const updatedControls = updateObject(markVehicleStatusInputRequest, {
      [controlName]:
        elementType === "radio"
          ? !markVehicleStatusInputRequest[controlName]
          : value,
    });
    setMarkVehicleStatusInputRequest(updatedControls);
  };
  const saveData = () => {
    const req = {};
    req.MemberId = props.memberId;
    req.MemberAssetId = props.memberAssetId;
    dispatch(markedVehicleOfflineReset);
    dispatch(markedVehicleOffline(req));
  };
  const renderRadioButtons = (id, value, labelCaption, name) => {
    return (
      <div className="col-sm-3">
        <CommonInputField
          elementConfig={{
            id: id,
            type: "radio",
            name: name,
            value: value,
            checked: markVehicleStatusInputRequest[name] === value,
          }}
          otherConfig={{
            caption: labelCaption,
          }}
          nodeElement="radio"
          onChange={handleOnChange}
        />
      </div>
    );
  };
  const renderLabelInputPair = (
    labelCaption,
    controlName,
    isRequired = false,
    nodeElement = "input",
    dropdownItem = null
  ) => {
    let markVehicleStatusInputRequestNew = { ...markVehicleStatusInputRequest };
    const value = markVehicleStatusInputRequestNew[controlName];
    return (
      <LabelAndValuePairControl
        elementConfig={{
          name: controlName,
          value: value,
        }}
        otherConfig={{
          caption: labelCaption,
          isRequired: isRequired,
          dropdownItem: nodeElement === "select" ? dropdownItem : [],
          value: value,
          dateFormat: "D",
        }}
        isEditMode={true}
        onChange={handleOnChange}
        parentRowClass={`form-group row  ${isRequired ? "required" : ""}`}
        labelClasses="col-sm-5 col-form-label font-weight-bold"
        valueClasses="col-sm-7"
        nodeElement={nodeElement}
      />
    );
  };

  const renderData = () => {
    return (
      <div>
        <section>
          <div className="input-container">
            {renderLabelInputPair(
              "Start Date",
              "StartDate",
              false,
              "datetime",
              null
            )}

            {renderLabelInputPair(
              "End Date",
              "EndDate",
              false,
              "datetime",
              null
            )}

            {renderLabelInputPair(
              "Problem Category",
              "Category",
              false,
              "select",
              problemCategories
            )}

            {renderLabelInputPair("Problem", "Comments", false, "textarea")}

            <div className={`form-group row`}>
              <label className="col-sm-5 col-form-label font-weight-bold">
                Generate Maintenance Ticket
              </label>
              <div className="col-sm-7">
                <div className="formRow">
                  {renderRadioButtons(
                    "rbtMaintainTktYes",
                    true,
                    "Yes",
                    "MaintainTicket"
                  )}
                  {renderRadioButtons(
                    "rbtMaintainTktNo",
                    false,
                    "No",
                    "MaintainTicket"
                  )}
                </div>
              </div>
            </div>
            {renderLabelInputPair(
              "Priority",
              "Priority",
              false,
              "select",
              priorities
            )}
          </div>
        </section>
      </div>
    );
  };

  return (
    <div>
      <Draggable handle=".cursor">
        <Modal
          isOpen={props.showModal}
          className="no-cursor"
          toggle={() => {
            props.closeModal();
          }}
          style={{ minWidth: "60rem" }}
        >
          <ModalHeader
            className="modal-header bg-primary modal-title text-white cursor"
            toggle={() => {
              props.closeModal();
            }}
          >
            <i className="fas fa-arrows-alt" /> {props.title}
          </ModalHeader>
          <ModalBody>
            <div className="form-group row">
              <div className="FormRow col-sm-12">{renderData()}</div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              className="btn btn-primary waves-effect waves-light btnSize ml-2"
              onClick={(event) => saveData()}
            >
              <i className="fas fa-save pr-1"></i>Submit
            </button>
            <button
              type="button"
              name="btnCancel"
              className="btn btn-secondary waves-effect waves-light btnSize ml-2"
              onClick={(event) => props.closeModal()}
            >
              <i className="fas fa-times pr-1"></i>Cancel
            </button>
          </ModalFooter>
        </Modal>
      </Draggable>
    </div>
  );
};

export default MarkVehicleStatus;

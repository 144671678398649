import React, { useState, useEffect } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import Draggable from "react-draggable";
import jsPDF from "jspdf";
import "jspdf-autotable";
import {
  getExportDataInfo,
  object2csv,
  getChunkArrays,
  getMultiDataSetForExcel,
} from "./export-data-helper";
import { showNotification } from "../../components/UI/Toaster/Toaster";
import { storeRequest, getDataFromStorage } from "../../shared/LocalStorage";
import CommonInputField from "../UI/Input/common-input-field";
import { ADMIN_PAGE, SKIPPED_ITEM_IN_FLEET_DATA_EXPORT, TOAST_MSG } from "../../shared/GlobalVar";
import { ExportToExcel } from "./ExportToExcel";
import { ExportType, FleetParametersDisplayValue } from "../../shared/utility/enum-utility";
import { isNullOrEmpty } from "../../shared/utility";

export const ExportModal = (props) => {
  const { exportType } = props
  const currentExportType= isNullOrEmpty(exportType) ? null : exportType.toLowerCase();
  const [exportDataInfo, setExportDataInfo] = useState(
    getExportDataInfo(
      props.dataSet,
      props.pageName,
      currentExportType,
      getDataFromStorage(props.pageName + "_Export"),
      props.fleetUtilizationSupportDataForExport
    )
  );
  const [pageSize, setPageSize] = useState("letter");
  useEffect(() => {
    if (
      exportDataInfo &&
      exportDataInfo.customColumList &&
      exportDataInfo.customColumList.length > 0 &&
      exportDataInfo.exportType === "xls"
    ) {
      getMultiDataSetForExcel(exportDataInfo.formattedData);
    }
  }, [exportDataInfo.customColumList]);

  const assignSelectedDataForExport = (exportInfo, cbAllClassName) => {
    let exportDataList = getExportDataInfo(
      props.dataSet,
      props.pageName,
      currentExportType,
      exportInfo.customColumList.filter((item) => item.Checked === true),
      props.fleetUtilizationSupportDataForExport
    );
    exportDataList.cbAllClassName = cbAllClassName;

    setExportDataInfo(exportDataList);
  };

  const modalSettings = {
    modalWidth: "300px",
    modalBodyHeight: "1000px",
  };
  const onExportClick = () => {
    if (exportDataInfo.customColumList.length > 0) {
      const csvData = object2csv(
        exportDataInfo.formattedData,
        exportDataInfo.customColumList,
        currentExportType
      );
      if (currentExportType?.toLowerCase() === ExportType.CSV) {
        downloadCSV_File(csvData);
      } else if (currentExportType === ExportType.PDF) {
        downloadPDF_File(csvData);
      }
      storeStickyColumnHeader();
      props.closeModal();
    } else {
      var toastMsg = {
        msg: "No Record Found",
        toastType: TOAST_MSG.ERROR,
      };
      showNotification(toastMsg);
    }
  };
  const downloadCSV_File = (data) => {
    const blob = new Blob([data], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const csvCreator = document.createElement("a");
    csvCreator.setAttribute("hidden", "");
    csvCreator.setAttribute("href", url);
    csvCreator.setAttribute("download", props.fileName + ".csv");
    document.body.appendChild(csvCreator);
    csvCreator.click();
    document.body.removeChild(csvCreator);
  };
  const downloadPDF_File = (data) => {
    let l = {
      orientation: "l",
      unit: "mm",
      format: pageSize,
      compress: true,
      fontSize: 6,
      lineHeight: 1,
      autoSize: false,
      printHeaders: true,
    };
    var doc = new jsPDF(l);
    var allTextLines = data.split(/\r\n|\n/);
    let tableDataWithHeader = [];
    const totalColInPage = props.pageName === ADMIN_PAGE.RESERVE_SYNC_PAGE ? 17 : 10;
    allTextLines.forEach((row, i) => {
      var entries = row.split("~");
      let numberoftable = Math.floor(entries.length / totalColInPage);
      let colInLastTable = Math.floor(entries.length % totalColInPage);
      let totalTable = Math.ceil(numberoftable + (colInLastTable > 0 ? 1 : 0));
      let tblCount = getChunkArrays(entries, totalTable);
      tableDataWithHeader[i] = [];
      tblCount.map((entries, index) => {
        tableDataWithHeader[i][index] = entries;
      });
    });

    let tableHeader = [];
    let tableRowsValueOnly = [];
    tableDataWithHeader.map((rowVal, i) => {
      tableRowsValueOnly[i] = [];
      rowVal.map((dataVal, j) => {
        if (i === 0) {
          tableHeader.push(dataVal);
        } else {
          tableRowsValueOnly[i][j] = dataVal;
        }
      });
    });

    let bodyData = [];
    let totalFleetParameters = Object.keys(FleetParametersDisplayValue).length;
    let totalFleetParametersToExport = totalFleetParameters - SKIPPED_ITEM_IN_FLEET_DATA_EXPORT.length;
    tableHeader.map((header, k) => {
      bodyData = [];
      tableRowsValueOnly.map((data1, l) => {
        if (l !== 0) {
          bodyData.push(data1[k]);
        }
      });
      doc.autoTable({
        head: [header],
        body: bodyData,
        bodyStyles: { valign: "top", fillColor: [255, 255, 255] },
        styles: {
          rowPageBreak: "auto",
          halign: "justify",
          fontSize: 6,
          lineWidth: 0.1,
          lineColor: [0, 0, 0],
          fillColor: [255, 255, 255],
        },
        pageBreak: "always",
        theme: "grid",
        headStyles: { fillColor: "#ccc", textColor: [0, 0, 0] },
        // alternateRowStyles: {fillColor : [231, 215, 252]}
        didParseCell(data) {
          if (data.row.index % totalFleetParametersToExport ===
            totalFleetParametersToExport - 1
          ) {
            data.cell.styles.fillColor = "#F1F1F1"; // set background color to last row ("Predicted Utilization Factor") of each class group
          }
        },
      });
    });
    doc.deletePage(1);
    doc.save(props.fileName + ".pdf");
  };
  const handleOnChange = (event, controlName, elementType) => {
    let exportInfo = { ...exportDataInfo };
    let itemName = event.target.name.split("-")[0];
    let index = event.target.dataset.id;
    if (itemName === "columnHeader") {
      exportInfo.customColumList[index].ColumnHeader = event.target.value;
    }
    setExportDataInfo(exportInfo);
    if (controlName === "PageSize") {
      setPageSize(elementType);
    }
  };
  const selectAllRows = () => {
    let exportInfo = { ...exportDataInfo };
    exportInfo.customColumList.map((data, index) => {
      data.Checked = exportInfo.cbAllClassName === "fa-check" ? false : true;
    });
    exportInfo.cbAllClassName =
      exportInfo.cbAllClassName === "fa-check" ? "fa-null" : "fa-check";
    assignSelectedDataForExport(exportInfo, exportInfo.cbAllClassName);
  };

  const selectSingleRows = (event, columnData) => {
    const exportInfo = { ...exportDataInfo };
    let checkedValue = 0;
    exportInfo.customColumList.map((data, index) => {
      if (data.ColumnName === columnData) {
        data.Checked = event.target.checked;
      }
      if (data.Checked) {
        checkedValue++;
      }
    });
    if (checkedValue === 0) {
      exportInfo.cbAllClassName = "fa-null";
    } else if (checkedValue === exportInfo.customColumList.length) {
      exportInfo.cbAllClassName = "fa-check";
    } else {
      exportInfo.cbAllClassName = "fa-minus";
    }
    assignSelectedDataForExport(exportInfo, exportInfo.cbAllClassName);
  };

  const onExcelModalColose = () => {
    storeStickyColumnHeader();
    props.closeModal();
  };
  const storeStickyColumnHeader = () => {
    const stickyColumns = [];
    exportDataInfo.customColumList.map((columnObj) => {
      if (columnObj.Checked) {
        stickyColumns.push(columnObj);
      }
    });
    storeRequest(props.pageName + "_Export", stickyColumns);
  };
  const renderRadioBtnAndCheckbox = (labelCaption, id, name, value) => {
    let isRadioButton = name === "PageSize";
    let type = isRadioButton ? "radio" : "checkbox";
    return (
      <span className={isRadioButton ? "col-sm-4" : "row__column text-center"}>
        <CommonInputField
          elementConfig={{
            id: id,
            type: type,
            name: name,
            checked: isRadioButton ? pageSize === value : value,
          }}
          otherConfig={{
            caption: labelCaption,
            elementType: value,
          }}
          nodeElement={type}
          onChange={isRadioButton ? handleOnChange : selectSingleRows}
        />
      </span>
    );
  };
  const getTableRows = () => {
    if (exportDataInfo.customColumList.length > 0) {
      return exportDataInfo.customColumList.map((post, index) => {
        let columnHeader = `columnHeader-${index}`;
        return (
          <div className="row align-center" key={index}>
            {renderRadioBtnAndCheckbox(
              "",
              "cb" + post.ColumnName,
              post.ColumnName,
              post.Checked || false
            )}
            <span className="row__column">{post.ColumnName}</span>
            <span className="row__column">
              <input
                type="text"
                name={columnHeader}
                data-id={index}
                id={columnHeader}
                value={post.ColumnHeader ? post.ColumnHeader : ""}
                onChange={(event) => handleOnChange(event)}
              />
            </span>
          </div>
        );
      });
    }
  };
  return (
    <Draggable handle=".cursor">
      <Modal
        isOpen={props.showModal}
        className="no-cursor"
        toggle={() => {
          props.closeModal();
        }}
        style={{
          minWidth: modalSettings.modalWidth + "px",
          position: "fixed",
          margin: "auto",
          left: "38%",
          top: "10%",
        }}
      >
        <ModalHeader
          className="modal-header bg-primary modal-title text-white cursor"
          toggle={() => {
            props.closeModal();
          }}
        >
          <i className="fas fa-file-export" />{" "}
          {currentExportType === "csv"
            ? "Export to CSV"
            : currentExportType === "xls"
              ? "Export to Excel"
              : currentExportType === "pdf"
                ? "Export to PDF"
                : "Export"}
        </ModalHeader>
        <ModalBody
          style={{
            height: modalSettings.modalBodyHeight + "px",
          }}
        >
          <section className="grid-table  col-sm-12 p-0" id="exportItemTable">
            <div className="datagrid">
              <div className="column">
                <span className="column__header">
                  <div
                    className={
                      exportDataInfo.cbAllClassName === "fa-check"
                        ? "checkBoxSelectAll checked"
                        : "checkBoxSelectAll"
                    }
                    onClick={(event) => selectAllRows()}
                  >
                    <i
                      className={
                        "fa checkBoxRolesPermisisonsFont " +
                        exportDataInfo.cbAllClassName
                      }
                      style={{ paddingTop: "0.1rem" }}
                      aria-hidden="true"
                    />
                  </div>
                </span>
                <span className="checkAllText header-text">
                  Check / Uncheck All
                </span>
              </div>
              <hr className="mt-0" />
              <div className="tableScroll exportPopupScroll">
                {getTableRows()}
              </div>
              <hr />
            </div>
          </section>
          <div className="col-lg-12 row  p-0">
            <div
              className={`col-sm-8 ${currentExportType === "pdf" ? "row" : "displayNone"
                }`}
            >
              <span className="col-lg-3 p-0 font-weight-bold text-left mt-2">
                Page Size
              </span>
              <div
                className="col-sm-9 font-weight-bold position-relative"
                style={{
                  border: "1px solid #e0e0e0",
                  borderRadius: "5px",
                }}
              >
                <div
                  className="col-lg-12 row p-0 m-0 position-absolute"
                  style={{ left: "0", top: "0.6rem" }}
                >
                  {renderRadioBtnAndCheckbox(
                    "Letter",
                    "rbtnLetter",
                    "PageSize",
                    "letter"
                  )}
                  {renderRadioBtnAndCheckbox(
                    "Legal",
                    "rbtnLegal",
                    "PageSize",
                    "legal"
                  )}
                  {renderRadioBtnAndCheckbox("A4", "rbtnA4", "PageSize", "a4")}
                </div>
              </div>
            </div>
            <div
              className={
                currentExportType === "pdf"
                  ? "col-sm-4 text-right"
                  : "col-sm-12 text-right"
              }
            >
              {["xls", "csv"].includes(currentExportType) &&
                exportDataInfo.multiDataSetForExcel.length > 0 ? (
                <ExportToExcel apiData={exportDataInfo.multiDataSetForExcel} fileName={props.fileName} exportType={currentExportType} closeModal={props.closeModal} pageName={ADMIN_PAGE.RESERVE_SYNC_PAGE} />
              ) : (
                <button
                  className="btn btn-primary waves-effect waves-light btnSize m-1 text-right"
                  onClick={(event) => onExportClick()}
                >
                  Download
                </button>
              )}
            </div>
          </div>
        </ModalBody>
      </Modal>
    </Draggable>
  );
};
export default ExportModal;

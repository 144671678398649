import React, { useEffect, useState, useRef, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  getCurrentServerTimezone,
  getSortingClassName,
  getSortType,
  hasPermission,
} from "../../../shared/utility";
import { createTooltip } from "../../../shared/utility/tooltip-utility";
import NoRecordFoundComponent from "../../../components/common/pages/no-record-found";
import { FleetUtilizationForecast } from "../../../services/entities/models/reserve-sync/fleet-utilization-forecast";
import PermissionName from "../../../shared/Permissions";
import { showNotification } from "../../../components/UI/Toaster/Toaster";
import { convertMsgResponse } from "../../Reservation/fox-reservation/fox-res-helper";
import {
  updateFleetUtilizationForecast, updateFleetUtilizationForecastReset, updateFleetClassGroup,
  updateFleetClassGroupReset,
} from "../../../store/actions";
import ManualFleetUtilizationForecastModal from "../modal/manual-fleet-utilization-forecast-modal";
import { Spinner } from "reactstrap";
import DefaultFleetUtilizationForecastModal from "../modal/default-fleet-utilization-forecast-modal";
import { AdminDateFromat, FleetParametersDisplayValue } from "../../../shared/utility/enum-utility";
import FleetUtilizationLogsModal from "../modal/fleet-utilization-logs-modal";
import "../fleet-utilization.scss";

export const SearchFleetUtilizationForecast = (props) => {
  const {
    dataList,
    searchViewType,
    periodicFilterInfo,
    orgUnitFleetClassGroupId,
    startDate,
    endDate,
  } = props;
  const loading = useSelector((state) => state.fleetUtilizationForecastReducer.loading);
  const fleetForecastResponse = useSelector(
    (state) => state.fleetUtilizationForecastReducer.fleetForecastResponse
  );
  const updateFleetForecastResponse = useSelector(
    (state) => state.fleetUtilizationForecastReducer.updateFleetForecastResponse
  );
  const updateFleetClassGroupReponse = useSelector(
    (state) => state.fleetUtilizationForecastReducer.updateFleetClassGroupReponse
  );
  const orgUnitFleetClassGroupsResponse = useSelector(
    (state) => state.adminOptionReducer.orgUnitFleetClassGroupsResponse || null
  );
  const dispatch = useDispatch();
  const hasEditPermission = hasPermission(
    PermissionName.EDIT_UPSELL_ORG_SETTINGS
  );
  const timeZone = getCurrentServerTimezone();
  const currentStartDate = timeZone ? moment().tz(timeZone) : moment().tz(moment.tz.guess());
  const currentEndDate = timeZone ? moment().tz(timeZone).add(89, "days") : moment().tz(moment.tz.guess()).add(89, "days");

  const [showManualFleetForecastModal, setShowManualFleetForecastModal] = useState(false);
  const [showFleetUtilizationLog, setShowFleetUtilizationLog] = useState(false);
  const [showDefaultFleetForecastModal, setShowDefaultFleetForecastModal] = useState(false);
  const [selectedFleetForecastEntity, setSelectedFleetForecastEntity] = useState(
    new FleetUtilizationForecast()
  );
  useEffect(() => {
    if (dataList && dataList.length > 0) {
      if (searchViewType === "CALENDAR_VIEW" && orgUnitFleetClassGroupsResponse && orgUnitFleetClassGroupsResponse.message && orgUnitFleetClassGroupsResponse.message.messageType === "SUCCESS") {
        //renderCalendarLayout();
      }
    }
  }, [dataList]);
  useEffect(() => {
    if (!updateFleetForecastResponse) {
      return;
    }
    showNotification(convertMsgResponse(updateFleetForecastResponse.message));
    if (updateFleetForecastResponse.message &&
      updateFleetForecastResponse.message.messageType === "SUCCESS") {
      closeModal();
      dispatch(updateFleetUtilizationForecastReset());
    }
    dispatch(updateFleetUtilizationForecastReset());
  }, [updateFleetForecastResponse]);
  useEffect(() => {
    if (!updateFleetClassGroupReponse) {
      return;
    }
    showNotification(convertMsgResponse(updateFleetClassGroupReponse.message));
    if (updateFleetClassGroupReponse.message &&
      updateFleetClassGroupReponse.message.messageType === "SUCCESS") {
      closeModal();
      dispatch(updateFleetClassGroupReset());
    }
    dispatch(updateFleetClassGroupReset());
  }, [updateFleetClassGroupReponse]);

  const getParameterName = (parameter) => {
    return FleetParametersDisplayValue[parameter]
  };
  const getDateWiseTableHeader = () => {
    const pStartDate = startDate ? { ...startDate } : { ...currentStartDate };
    const pEndDate = pStartDate ? moment(pStartDate).add(periodicFilterInfo.recordsPerPeriod || 90, "days") : { ...currentEndDate };
    const headerRows = [];
    headerRows.push(<th>Car Class Group</th>);
    headerRows.push(<th>Parameter</th>);
    for (var m = moment(pStartDate); m.isBefore(pEndDate); m.add(1, 'days')) {
      const selectedDIndex = m.format(AdminDateFromat.COLUMN_FORMAT);
      headerRows.push(<th>{selectedDIndex}</th>);
    }
    return <tr>{headerRows}</tr>;
  }
  const getTdparamClass = (parameter) => {
    if (parameter === "manualPickupThresholdPercent" || parameter === "manualBookThruThresholdPercent"
      || parameter === "manualWalkinsCount"
      || parameter === "manualNoShowFactor"
      || parameter === "pause") {
      return "tdclick";
    }
    return "tdclick";
  }
  const getDateWiseTableRows = (classGroup, parameter) => {
    const pStartDate = startDate ? { ...startDate } : { ...currentStartDate };
    const pEndDate = pStartDate ? moment(pStartDate).add(periodicFilterInfo.recordsPerPeriod || 90, "days") : { ...currentEndDate };
    const bodyRows = [];
    if (parameter === "defaultPickupThresholdPercent") {
      bodyRows.push(<td rowSpan={15} className="param-cell" onClick={(event) => getDefaultFleetForecastModal(classGroup)}>{classGroup.value}</td>);
    }
    bodyRows.push(<td className={getClassNameParamWise(parameter)}>{getParameterName(parameter)}</td>);
    for (var m = moment(pStartDate); m.isBefore(pEndDate); m.add(1, 'days')) {
      const selectedDate = m.format(AdminDateFromat.FOX_API_ONLY_DATE);
      if (dataList.length > 0) {
        const selectedValue = dataList.find((element) => element.forecastDate === selectedDate && element.orgUnitFleetClassGroupId === parseInt(classGroup.key));
        if (selectedValue) {
          const value = parameter === "pause" ? selectedValue[parameter] === false ? "No" : "Yes" : selectedValue[parameter] ? selectedValue[parameter] : "--";
          bodyRows.push(<td style={{ cursor: "pointer" }} onClick={e => {getFleetUtilizationLogModal(selectedValue)}} className={getClassNameParamWise(parameter)}>{value}</td>);
        } else {
          bodyRows.push(<td className={getClassNameParamWise(parameter)}>--</td>);
        }

      } else {
        bodyRows.push(<td className={getClassNameParamWise(parameter)}>--</td>);
      }
    }
    return <tr>{bodyRows}</tr>;
  }
  const getClassNameParamWise = (parameter) => {
    if (parameter === "utilizationFactorPercent") {
      return "forcast-cell"
    } else if (parameter === "manualPickupThresholdPercent" || parameter === "manualBookThruThresholdPercent"
      || parameter === "manualWalkinsCount"
      || parameter === "manualNoShowFactor"
      || parameter === "pause") {
      return "tdclick";
    }

  }
  const generateCalenderTableRows = () => {
    const tableRows = [];
    orgUnitFleetClassGroupsResponse.orgUnitFleetClassGroups.map((classGroup) => {
      if (orgUnitFleetClassGroupId && orgUnitFleetClassGroupId !== classGroup.key) {
        return;
      }
      const data = [];
      data.push(getDateWiseTableRows(classGroup, "defaultPickupThresholdPercent"));
      data.push(getDateWiseTableRows(classGroup, "defaultBookThruThresholdPercent"));
      data.push(getDateWiseTableRows(classGroup, "walkinsCount"));
      data.push(getDateWiseTableRows(classGroup, "usableFleetCount"));
      data.push(getDateWiseTableRows(classGroup, "runningRentalsCount"));
      data.push(getDateWiseTableRows(classGroup, "runningReservationsCount"));
      data.push(getDateWiseTableRows(classGroup, "noShowFactor"));
      data.push(getDateWiseTableRows(classGroup, "pickupCount"));
      data.push(getDateWiseTableRows(classGroup, "dropOffCount"));
      data.push(getDateWiseTableRows(classGroup, "manualPickupThresholdPercent"));
      data.push(getDateWiseTableRows(classGroup, "manualBookThruThresholdPercent"));
      data.push(getDateWiseTableRows(classGroup, "manualWalkinsCount"));
      data.push(getDateWiseTableRows(classGroup, "manualNoShowFactor"));
      data.push(getDateWiseTableRows(classGroup, "pause"));
      data.push(getDateWiseTableRows(classGroup, "utilizationFactorPercent"));



      tableRows.push(data);
    });
    return tableRows;
  }

  const getManualFleetForecastModal = (entity) => {
    if (entity) {
      setSelectedFleetForecastEntity(new FleetUtilizationForecast(entity));
    }
    setShowDefaultFleetForecastModal(false);
    setShowManualFleetForecastModal(true);
  };
  const getFleetUtilizationLogModal = (entity) => {
    if (entity) {
      // setSelectedFleetForecastId(fleetUtilizationForecastId);
      setSelectedFleetForecastEntity(new FleetUtilizationForecast(entity));
    }
    setShowFleetUtilizationLog(true);
  };
  const getDefaultFleetForecastModal = (entity) => {
    if (entity) {
      setSelectedFleetForecastEntity(new FleetUtilizationForecast(entity));
    }
    setShowManualFleetForecastModal(false);
    setShowDefaultFleetForecastModal(true);
  };
  const closeModal = () => {
    setShowManualFleetForecastModal(false);
    setShowDefaultFleetForecastModal(false);
    setSelectedFleetForecastEntity(new FleetUtilizationForecast());
    setShowFleetUtilizationLog(false);
  };
  const updateManualFleetutilizationForecastEntity = (entity) => {
    const reqObj = {
      fleetUtilizationForecast: {
        fleetUtilizationForecastId: entity.fleetUtilizationForecastId,
        manualPickupThresholdPercent: entity.manualPickupThresholdPercent ? parseFloat(entity.manualPickupThresholdPercent) : null,
        manualBookThruThresholdPercent: entity.manualBookThruThresholdPercent ? parseFloat(entity.manualBookThruThresholdPercent) : null,
        manualNoShowFactor: entity.manualNoShowFactor ? parseInt(entity.manualNoShowFactor) : null,
        manualWalkinsCount: entity.manualWalkinsCount ? parseInt(entity.manualWalkinsCount) : null,
        manualOverrideReason: entity.manualOverrideReason,
        //status: entity.status,
      }
    };
    if (entity.pause !== "" || entity.pause !== null) {
      reqObj.fleetUtilizationForecast.pause = entity.pause === "TRUE" ? true : false;
    }
    dispatch(updateFleetUtilizationForecastReset());
    dispatch(updateFleetUtilizationForecast(reqObj));
  }
  const updateDefaultFleetutilizationForecastEntity = (entity) => {
    const reqObj = {
      orgUnitFleetClassGroup: {
        orgUnitFleetClassGroupId: entity.orgUnitFleetClassGroupId,
        defaultPickupThresholdPercent: entity.defaultPickupThresholdPercent ? parseFloat(entity.defaultPickupThresholdPercent) : null,
        defaultBookThruThresholdPercent: entity.defaultBookThruThresholdPercent ? parseFloat(entity.defaultBookThruThresholdPercent) : null,
        // walkinsCount: entity.walkinsCount ? parseInt(entity.walkinsCount) : null,
        // noShowFactor: entity.manualWalkinsCount ? parseInt(entity.manualWalkinsCount) : null,
        // manualOverrideReason: entity.manualOverrideReason,
        //status: entity.status,
      }
    };
    // if (entity.pause !== "" || entity.pause !== null) {
    //   reqObj.fleetUtilizationForecast.pause = entity.pause === "TRUE" ? true : false;
    // }
    dispatch(updateFleetClassGroupReset());
    dispatch(updateFleetClassGroup(reqObj));
  }
  const renderTableColumnHeader = (title, sortValue) => {
    return (
      <span
        className={
          sortValue
            ? getSortingClassName(props.pageInfo, sortValue)
            : "column__header"
        }
        onClick={(event) =>
          sortValue
            ? props.doSorting(getSortType(props.pageInfo, sortValue), sortValue)
            : null
        }
      >
        {title}
      </span>
    );
  };
  const renderTabularDataList = () => {
    if (dataList.length > 0) {
      return dataList.map((entity) => {
        return (
          <tr
            key={entity.UpsellOptionId}
          >
            <td>{createTooltip(entity.forecastDate, entity.forecastDate)}</td>
            <td className="row__column">
              {createTooltip(entity.displayText, entity.displayText)}
            </td>
            <td className="row__column text-center">
              {createTooltip(entity.defaultPickupThresholdPercent, entity.defaultPickupThresholdPercent)}
            </td>
            <td className="row__column text-center">
              {createTooltip(entity.defaultBookThruThresholdPercent, entity.defaultBookThruThresholdPercent)}
            </td>
            <td className="row__column">
              {entity.pause !== null || entity.pause !== "" ? entity.pause === false ? "No" : "Yes" : ""}
            </td>
            <td className="row__column">
              {createTooltip(entity.walkinsCount, entity.walkinsCount)}
            </td>
            <td className="row__column">
              {createTooltip(entity.usableFleetCount, entity.usableFleetCount)}
            </td>
            <td className="row__column">
              {createTooltip(entity.runningRentalsCount, entity.runningRentalsCount)}
            </td>
            <td className="row__column">
              {createTooltip(entity.runningReservationsCount, entity.runningReservationsCount)}
            </td>
            <td className="row__column" >
              {createTooltip(entity.noShowFactor, entity.noShowFactor)}
            </td>
            <td className="row__column">
              {createTooltip(entity.pickupCount, entity.pickupCount)}
            </td>
            <td className="row__column">
              {createTooltip(entity.dropOffCount, entity.dropOffCount)}
            </td>
            <td className="row__column">
              {createTooltip(entity.utilizationFactorPercent, entity.utilizationFactorPercent)}
            </td>
            <td className="text-center">
              <Fragment>
                {createTooltip(
                  <i
                    id={"edit" + entity.fleetUtilizationForecastId}
                    className={`fas fa-pencil-alt fs-3 pl-1 pr-2 ${hasEditPermission ? " blue-color cursor-pointer" : " light-gray-color"}`}
                    aria-hidden="true"
                    onClick={(event) => getFleetUtilizationLogModal(entity)
                    }
                  />,
                  "Manual Edit",
                  { forceShow: true, tableId: "table-addons" }
                )}
                {createTooltip(
                  <i
                    id={"edit" + entity.fleetUtilizationForecastId}
                    style={{ color: 'yellowgreen' }}
                    className={`fas fa-pencil-alt fs-3 pl-1 pr-2 ${hasEditPermission ? " blue-color cursor-pointer" : " light-gray-color"}`}
                    aria-hidden="true"
                    onClick={(event) => getDefaultFleetForecastModal(entity)
                    }
                  />,
                  "Default",
                  { forceShow: true, tableId: "table-addons" }
                )}
              </Fragment>
            </td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colSpan="15">
            <NoRecordFoundComponent
              className="border-none"
              innerClassName="no-setting-record-found"
              searchMsg="No Record Found"
              heights="5rem"
            />
          </td>
        </tr>
      );
    }
  };
  const renderTabularLayout = () => {
    return (
      <table className="table table-striped" id="table-addons">
        <thead className="thead-primary">
          <tr>
            <th style={{ position: 'relative' }}>{renderTableColumnHeader("Date", "forecastDate")}</th>
            <th style={{ position: 'relative' }}>{renderTableColumnHeader("Class Code", "displayText")}</th>
            <th style={{ position: 'relative' }}>{renderTableColumnHeader("Pickup Threshold", "manualPickupThresholdPercent")}</th>
            <th style={{ position: 'relative' }}>{renderTableColumnHeader("BookThru Threshold", "manualBookThruThresholdPercent")}</th>
            <th style={{ position: 'relative' }}> {renderTableColumnHeader("Pause", "pause")}</th>
            <th style={{ position: 'relative' }}>{renderTableColumnHeader("Walkins Count", "walkinsCount")}</th>
            <th style={{ position: 'relative' }}>{renderTableColumnHeader("Usable Fleet Count", "usableFleetCount")}</th>
            <th style={{ position: 'relative' }}>{renderTableColumnHeader("Running Rentals Count", "runningRentalsCount")}</th>
            <th style={{ position: 'relative' }}>{renderTableColumnHeader("Running Reservations Count", "runningReservationsCount")}</th>
            <th style={{ position: 'relative' }}>{renderTableColumnHeader("No Show Factor", "noShowFactor")}</th>
            <th style={{ position: 'relative' }}>{renderTableColumnHeader("Pickup Count", "pickupCount")}</th>
            <th style={{ position: 'relative' }}>{renderTableColumnHeader("DropOff Count", "dropOffCount")}</th>
            <th>{renderTableColumnHeader("Factor Percent", "utilizationFactorPercent")}</th>
            <th />
          </tr>
        </thead>
        <tbody>{renderTabularDataList()}</tbody>
      </table>
    );
  };
  const renderCalendarLayout = () => {
    if (dataList.length > 0) {
      return (
        <table className="table table-striped" id="table-fllet-forecast">
          <thead className="thead-primary">
            {getDateWiseTableHeader()}
          </thead>
          <tbody>{generateCalenderTableRows()}</tbody>
        </table>
      );
    }
  }
  return (orgUnitFleetClassGroupsResponse && <Fragment>{searchViewType === "CALENDAR_VIEW" ? renderCalendarLayout() : renderTabularLayout()}
    {loading ? <Spinner /> : ""}
    {showFleetUtilizationLog && selectedFleetForecastEntity !== null ? (
      <FleetUtilizationLogsModal
        showModal={showFleetUtilizationLog}
        closeModal={closeModal}
        selectedEntity={selectedFleetForecastEntity}
        key={selectedFleetForecastEntity.fleetUtilizationForecastId}
      />

    ) : (
      ""
    )}
    {showManualFleetForecastModal && selectedFleetForecastEntity !== null ? (
      <ManualFleetUtilizationForecastModal
        showModal={showManualFleetForecastModal}
        closeModal={closeModal}
        selectedEntity={selectedFleetForecastEntity}
        updateFleetutilizationForecastEntity={updateManualFleetutilizationForecastEntity}
      />

    ) : (
      ""
    )}
    {showDefaultFleetForecastModal && selectedFleetForecastEntity !== null ? (
      <DefaultFleetUtilizationForecastModal
        showModal={showDefaultFleetForecastModal}
        closeModal={closeModal}
        selectedEntity={selectedFleetForecastEntity}
        updateFleetutilizationForecastEntity={updateDefaultFleetutilizationForecastEntity}
      />

    ) : (
      ""
    )}
  </Fragment>
  );
};
export default SearchFleetUtilizationForecast;

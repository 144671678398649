import PageInfo from "../../shared/PageInfo";

const SearchAgentInputRequest = function () {
  this.MembershipId = null;
  this.FirstName = null;
  this.LastName = null;
  this.EmailAddress = null;
  this.Phone = null;
  this.Role = null;
  this.ZipCode = null;
  this.LocationId = null;
  this.PageInfo = new PageInfo();
};

export default SearchAgentInputRequest;

import { FoxRequestHeader } from "../../fox-api/domain/fox-request-header";

export const ShuttleOptionRequest = function (data) {
  if (!data) data = {};
  this.requestHeader = new FoxRequestHeader({ requestType: "GET" });
  this.locations = data.locations || false;
  this.allShuttles = data.allShuttles || false;
  this.locationShuttles = data.locationShuttles || false;
  this.shuttleLocationCode = data.shuttleLocationCode || "";
  this.driverLocationCode = data.driverLocationCode || "";
  this.allDrivers = data.allDrivers || false;
  this.locationDrivers = data.locationDrivers || false;
  this.engineStatuses = data.engineStatuses || false;
  this.amenities = data.amenities || false;
  this.availabilities = data.availabilities || false;
  this.drivingTypes = data.drivingTypes || false;
  this.fuelTypes = data.fuelTypes || false;
  this.fuelConsumptionUnitTypes = data.fuelConsumptionUnitTypes || false;
  this.transMissionTypes = data.transMissionTypes || false;
  this.colorTypes = data.colorTypes || false;
  this.landmarkCategories = data.landmarkCategories || false;
  this.landmarkStatuses = data.landmarkStatuses || false;
};

export const getMobileCountry = () => {
  return [
    {
      value: "",
      countryIso: "",
      label: "None"
    },
    {
      value: "+1",
      countryIso: "US",
      label: "(+1) United States",
      countryName: "United States"
    },
    {
      value: "+93",
      countryIso: "AF",
      label: "(+93) Afghanistan",
      countryName: "Afghanistan"
    },
    {
      value: "+340",
      countryIso: "AX",
      label: "(+340) Aland Islands",
      countryName: "Aland Islands"
    },
    {
      value: "+355",
      countryIso: "AL",
      label: "(+355) Albania",
      countryName: "Albania"
    },
    {
      value: "+213",
      countryIso: "DZ",
      label: "(+213) Algeria",
      countryName: "Algeria"
    },
    {
      value: "+683",
      countryIso: "AS",
      label: "(+683) American Samoa",
      countryName: "American Samoa"
    },
    {
      value: "+376",
      countryIso: "AD",
      label: "(+376) Andorra",
      countryName: "Andorra"
    },
    {
      value: "+244",
      countryIso: "AO",
      label: "(+244) Angola",
      countryName: "Angola"
    },
    {
      value: "+263",
      countryIso: "AI",
      label: "(+263) Anguilla",
      countryName: "Anguilla"
    },
    {
      value: "+672",
      countryIso: "AQ",
      label: "(+672) Antarctica",
      countryName: "Antarctica"
    },
    {
      value: "+267",
      countryIso: "AG",
      label: "(+267) Antigua and Barbuda",
      countryName: "Antigua and Barbuda"
    },
    {
      value: "+54",
      countryIso: "AR",
      label: "(+54) Argentina",
      countryName: "Argentina"
    },
    {
      value: "+374",
      countryIso: "AM",
      label: "(+374) Armenia",
      countryName: "Armenia"
    },
    {
      value: "+297",
      countryIso: "AW",
      label: "(+297) Aruba",
      countryName: "Aruba"
    },
    {
      value: "+61",
      countryIso: "AU",
      label: "(+61) Australia",
      countryName: "Australia"
    },
    {
      value: "+43",
      countryIso: "AT",
      label: "(+43) Austria",
      countryName: "Austria"
    },
    {
      value: "+994",
      countryIso: "AZ",
      label: "(+994) Azerbaijan",
      countryName: "Azerbaijan"
    },
    {
      value: "+241",
      countryIso: "BS",
      label: "(+241) Bahamas",
      countryName: "Bahamas"
    },
    {
      value: "+973",
      countryIso: "BH",
      label: "(+973) Bahrain",
      countryName: "Bahrain"
    },
    {
      value: "+880",
      countryIso: "BD",
      label: "(+880) Bangladesh",
      countryName: "Bangladesh"
    },
    {
      value: "+245",
      countryIso: "BB",
      label: "(+245) Barbados",
      countryName: "Barbados"
    },
    {
      value: "+375",
      countryIso: "BY",
      label: "(+375) Belarus",
      countryName: "Belarus"
    },
    {
      value: "+32",
      countryIso: "BE",
      label: "(+32) Belgium",
      countryName: "Belgium"
    },
    {
      value: "+501",
      countryIso: "BZ",
      label: "(+501) Belize",
      countryName: "Belize"
    },
    {
      value: "+229",
      countryIso: "BJ",
      label: "(+229) Benin",
      countryName: "Benin"
    },
    {
      value: "+440",
      countryIso: "BM",
      label: "(+440) Bermuda",
      countryName: "Bermuda"
    },
    {
      value: "+975",
      countryIso: "BT",
      label: "(+975) Bhutan",
      countryName: "Bhutan"
    },
    {
      value: "+591",
      countryIso: "BO",
      label: "(+591) Bolivia",
      countryName: "Bolivia"
    },
    {
      value: "+599",
      countryIso: "BQ",
      label: "(+599) Bonaire, Saint Eustatius and Saba ",
      countryName: "Bonaire, Saint Eustatius and Saba"
    },
    {
      value: "+387",
      countryIso: "BA",
      label: "(+387) Bosnia and Herzegovina",
      countryName: "Bosnia and Herzegovina"
    },
    {
      value: "+267",
      countryIso: "BW",
      label: "(+267) Botswana",
      countryName: "Botswana"
    },
    {
      value: "+55",
      countryIso: "BV",
      label: "(+55) Bouvet Island",
      countryName: "Bouvet Island"
    },
    {
      value: "+55",
      countryIso: "BR",
      label: "(+55) Brazil",
      countryName: "Brazil"
    },
    {
      value: "+246",
      countryIso: "IO",
      label: "(+246) British Indian Ocean Territory",
      countryName: "British Indian Ocean Territory"
    },
    {
      value: "+283",
      countryIso: "VG",
      label: "(+283) British Virgin Islands",
      countryName: "British Virgin Islands"
    },
    {
      value: "+673",
      countryIso: "BN",
      label: "(+673) Brunei",
      countryName: "Brunei"
    },
    {
      value: "+359",
      countryIso: "BG",
      label: "(+359) Bulgaria",
      countryName: "Bulgaria"
    },
    {
      value: "+226",
      countryIso: "BF",
      label: "(+226) Burkina Faso",
      countryName: "Burkina Faso"
    },
    {
      value: "+257",
      countryIso: "BI",
      label: "(+257) Burundi",
      countryName: "Burundi"
    },
    {
      value: "+855",
      countryIso: "KH",
      label: "(+855) Cambodia",
      countryName: "Cambodia"
    },
    {
      value: "+237",
      countryIso: "CM",
      label: "(+237) Cameroon",
      countryName: "Cameroon"
    },
    {
      value: "+1",
      countryIso: "CA",
      label: "(+1) Canada",
      countryName: "Canada"
    },
    {
      value: "+238",
      countryIso: "CV",
      label: "(+238) Cape Verde",
      countryName: "Cape Verde"
    },
    {
      value: "+344",
      countryIso: "KY",
      label: "(+344) Cayman Islands",
      countryName: "Cayman Islands"
    },
    {
      value: "+236",
      countryIso: "CF",
      label: "(+236) Central African Republic",
      countryName: "Central African Republic"
    },
    {
      value: "+235",
      countryIso: "TD",
      label: "(+235) Chad",
      countryName: "Chad"
    },
    {
      value: "+56",
      countryIso: "CL",
      label: "(+56) Chile",
      countryName: "Chile"
    },
    {
      value: "+86",
      countryIso: "CN",
      label: "(+86) China",
      countryName: "China"
    },
    {
      value: "+61",
      countryIso: "CX",
      label: "(+61) Christmas Island",
      countryName: "Christmas Island"
    },
    {
      value: "+61",
      countryIso: "CC",
      label: "(+61) Cocos Islands",
      countryName: "Cocos Islands"
    },
    {
      value: "+57",
      countryIso: "CO",
      label: "(+57) Colombia",
      countryName: "Colombia"
    },
    {
      value: "+269",
      countryIso: "KM",
      label: "(+269) Comoros",
      countryName: "Comoros"
    },
    {
      value: "+682",
      countryIso: "CK",
      label: "(+682) Cook Islands",
      countryName: "Cook Islands"
    },
    {
      value: "+506",
      countryIso: "CR",
      label: "(+506) Costa Rica",
      countryName: "Costa Rica"
    },
    {
      value: "+385",
      countryIso: "HR",
      label: "(+385) Croatia",
      countryName: "Croatia"
    },
    {
      value: "+53",
      countryIso: "CU",
      label: "(+53) Cuba",
      countryName: "Cuba"
    },
    {
      value: "+599",
      countryIso: "CW",
      label: "(+599) Curaçao",
      countryName: "Curaçao"
    },
    {
      value: "+357",
      countryIso: "CY",
      label: "(+357) Cyprus",
      countryName: "Cyprus"
    },
    {
      value: "+420",
      countryIso: "CZ",
      label: "(+420) Czech Republic",
      countryName: "Czech Republic"
    },
    {
      value: "+243",
      countryIso: "CD",
      label: "(+243) Democratic Republic of the Congo",
      countryName: "Democratic Republic of the Congo"
    },
    {
      value: "+45",
      countryIso: "DK",
      label: "(+45) Denmark",
      countryName: "Denmark"
    },
    {
      value: "+253",
      countryIso: "DJ",
      label: "(+253) Djibouti",
      countryName: "Djibouti"
    },
    {
      value: "+766",
      countryIso: "DM",
      label: "(+766) Dominica",
      countryName: "Dominica"
    },
    {
      value: "+1809",
      countryIso: "DO",
      label: "(+1809) Dominican Republic",
      countryName: "Dominican Republic"
    },
    {
      value: "+670",
      countryIso: "TL",
      label: "(+670) East Timor",
      countryName: "East Timor"
    },
    {
      value: "+593",
      countryIso: "EC",
      label: "(+593) Ecuador",
      countryName: "Ecuador"
    },
    {
      value: "+20",
      countryIso: "EG",
      label: "(+20) Egypt",
      countryName: "Egypt"
    },
    {
      value: "+503",
      countryIso: "SV",
      label: "(+503) El Salvador",
      countryName: "El Salvador"
    },
    {
      value: "+240",
      countryIso: "GQ",
      label: "(+240) Equatorial Guinea",
      countryName: "Equatorial Guinea"
    },
    {
      value: "+291",
      countryIso: "ER",
      label: "(+291) Eritrea",
      countryName: "Eritrea"
    },
    {
      value: "+372",
      countryIso: "EE",
      label: "(+372) Estonia",
      countryName: "Estonia"
    },
    {
      value: "+251",
      countryIso: "ET",
      label: "(+251) Ethiopia",
      countryName: "Ethiopia"
    },
    {
      value: "+500",
      countryIso: "FK",
      label: "(+500) Falkland Islands",
      countryName: "Falkland Islands"
    },
    {
      value: "+298",
      countryIso: "FO",
      label: "(+298) Faroe Islands",
      countryName: "Faroe Islands"
    },
    {
      value: "+679",
      countryIso: "FJ",
      label: "(+679) Fiji",
      countryName: "Fiji"
    },
    {
      value: "+358",
      countryIso: "FI",
      label: "(+358) Finland",
      countryName: "Finland"
    },
    {
      value: "+33",
      countryIso: "FR",
      label: "(+33) France",
      countryName: "France"
    },
    {
      value: "+594",
      countryIso: "GF",
      label: "(+594) French Guiana",
      countryName: "French Guiana"
    },
    {
      value: "+689",
      countryIso: "PF",
      label: "(+689) French Polynesia",
      countryName: "French Polynesia"
    },
    {
      value: "+262",
      countryIso: "TF",
      label: "(+262) French Southern Territories",
      countryName: "French Southern Territories"
    },
    {
      value: "+241",
      countryIso: "GA",
      label: "(+241) Gabon",
      countryName: "Gabon"
    },
    {
      value: "+220",
      countryIso: "GM",
      label: "(+220) Gambia",
      countryName: "Gambia"
    },
    {
      value: "+995",
      countryIso: "GE",
      label: "(+995) Georgia",
      countryName: "Georgia"
    },
    {
      value: "+49",
      countryIso: "DE",
      label: "(+49) Germany",
      countryName: "Germany"
    },
    {
      value: "+233",
      countryIso: "GH",
      label: "(+233) Ghana",
      countryName: "Ghana"
    },
    {
      value: "+350",
      countryIso: "GI",
      label: "(+350) Gibraltar",
      countryName: "Gibraltar"
    },
    {
      value: "+30",
      countryIso: "GR",
      label: "(+30) Greece",
      countryName: "Greece"
    },
    {
      value: "+299",
      countryIso: "GL",
      label: "(+299) Greenland",
      countryName: "Greenland"
    },
    {
      value: "+472",
      countryIso: "GD",
      label: "(+472) Grenada",
      countryName: "Grenada"
    },
    {
      value: "+590",
      countryIso: "GP",
      label: "(+590) Guadeloupe",
      countryName: "Guadeloupe"
    },
    {
      value: "+670",
      countryIso: "GU",
      label: "(+670) Guam",
      countryName: "Guam"
    },
    {
      value: "+502",
      countryIso: "GT",
      label: "(+502) Guatemala",
      countryName: "Guatemala"
    },
    {
      value: "+1437",
      countryIso: "GG",
      label: "(+1437) Guernsey",
      countryName: "Guernsey"
    },
    {
      value: "+224",
      countryIso: "GN",
      label: "(+224) Guinea",
      countryName: "Guinea"
    },
    {
      value: "+245",
      countryIso: "GW",
      label: "(+245) Guinea-Bissau",
      countryName: "Guinea-Bissau"
    },
    {
      value: "+592",
      countryIso: "GY",
      label: "(+592) Guyana",
      countryName: "Guyana"
    },
    {
      value: "+509",
      countryIso: "HT",
      label: "(+509) Haiti",
      countryName: "Haiti"
    },
    {
      value: "+0",
      countryIso: "HM",
      label: "(+0) Heard Island and McDonald Islands",
      countryName: "Heard Island and McDonald Islands"
    },
    {
      value: "+504",
      countryIso: "HN",
      label: "(+504) Honduras",
      countryName: "Honduras"
    },
    {
      value: "+852",
      countryIso: "HK",
      label: "(+852) Hong Kong",
      countryName: "Hong Kong"
    },
    {
      value: "+36",
      countryIso: "HU",
      label: "(+36) Hungary",
      countryName: "Hungary"
    },
    {
      value: "+354",
      countryIso: "IS",
      label: "(+354) Iceland",
      countryName: "Iceland"
    },
    {
      value: "+91",
      countryIso: "IN",
      label: "(+91) India",
      countryName: "India"
    },
    {
      value: "+62",
      countryIso: "ID",
      label: "(+62) Indonesia",
      countryName: "Indonesia"
    },
    {
      value: "+98",
      countryIso: "IR",
      label: "(+98) Iran",
      countryName: "Iran"
    },
    {
      value: "+964",
      countryIso: "IQ",
      label: "(+964) Iraq",
      countryName: "Iraq"
    },
    {
      value: "+353",
      countryIso: "IE",
      label: "(+353) Ireland",
      countryName: "Ireland"
    },
    {
      value: "+1580",
      countryIso: "IM",
      label: "(+1580) Isle of Man",
      countryName: "Isle of Man"
    },
    {
      value: "+972",
      countryIso: "IL",
      label: "(+972) Israel",
      countryName: "Israel"
    },
    {
      value: "+39",
      countryIso: "IT",
      label: "(+39) Italy",
      countryName: "Italy"
    },
    {
      value: "+225",
      countryIso: "CI",
      label: "(+225) Ivory Coast",
      countryName: "Ivory Coast"
    },
    {
      value: "+875",
      countryIso: "JM",
      label: "(+875) Jamaica",
      countryName: "Jamaica"
    },
    {
      value: "+81",
      countryIso: "JP",
      label: "(+81) Japan",
      countryName: "Japan"
    },
    {
      value: "+1490",
      countryIso: "JE",
      label: "(+1490) Jersey",
      countryName: "Jersey"
    },
    {
      value: "+962",
      countryIso: "JO",
      label: "(+962) Jordan",
      countryName: "Jordan"
    },
    {
      value: "+7",
      countryIso: "KZ",
      label: "(+7) Kazakhstan",
      countryName: "Kazakhstan"
    },
    {
      value: "+254",
      countryIso: "KE",
      label: "(+254) Kenya",
      countryName: "Kenya"
    },
    {
      value: "+686",
      countryIso: "KI",
      label: "(+686) Kiribati",
      countryName: "Kiribati"
    },
    {
      value: "+383",
      countryIso: "XK",
      label: "(+383) Kosovo",
      countryName: "Kosovo"
    },
    {
      value: "+965",
      countryIso: "KW",
      label: "(+965) Kuwait",
      countryName: "Kuwait"
    },
    {
      value: "+996",
      countryIso: "KG",
      label: "(+996) Kyrgyzstan",
      countryName: "Kyrgyzstan"
    },
    {
      value: "+856",
      countryIso: "LA",
      label: "(+856) Laos",
      countryName: "Laos"
    },
    {
      value: "+371",
      countryIso: "LV",
      label: "(+371) Latvia",
      countryName: "Latvia"
    },
    {
      value: "+961",
      countryIso: "LB",
      label: "(+961) Lebanon",
      countryName: "Lebanon"
    },
    {
      value: "+266",
      countryIso: "LS",
      label: "(+266) Lesotho",
      countryName: "Lesotho"
    },
    {
      value: "+231",
      countryIso: "LR",
      label: "(+231) Liberia",
      countryName: "Liberia"
    },
    {
      value: "+218",
      countryIso: "LY",
      label: "(+218) Libya",
      countryName: "Libya"
    },
    {
      value: "+423",
      countryIso: "LI",
      label: "(+423) Liechtenstein",
      countryName: "Liechtenstein"
    },
    {
      value: "+370",
      countryIso: "LT",
      label: "(+370) Lithuania",
      countryName: "Lithuania"
    },
    {
      value: "+352",
      countryIso: "LU",
      label: "(+352) Luxembourg",
      countryName: "Luxembourg"
    },
    {
      value: "+853",
      countryIso: "MO",
      label: "(+853) Macao",
      countryName: "Macao"
    },
    {
      value: "+389",
      countryIso: "MK",
      label: "(+389) Macedonia",
      countryName: "Macedonia"
    },
    {
      value: "+261",
      countryIso: "MG",
      label: "(+261) Madagascar",
      countryName: "Madagascar"
    },
    {
      value: "+265",
      countryIso: "MW",
      label: "(+265) Malawi",
      countryName: "Malawi"
    },
    {
      value: "+60",
      countryIso: "MY",
      label: "(+60) Malaysia",
      countryName: "Malaysia"
    },
    {
      value: "+960",
      countryIso: "MV",
      label: "(+960) Maldives",
      countryName: "Maldives"
    },
    {
      value: "+223",
      countryIso: "ML",
      label: "(+223) Mali",
      countryName: "Mali"
    },
    {
      value: "+356",
      countryIso: "MT",
      label: "(+356) Malta",
      countryName: "Malta"
    },
    {
      value: "+692",
      countryIso: "MH",
      label: "(+692) Marshall Islands",
      countryName: "Marshall Islands"
    },
    {
      value: "+596",
      countryIso: "MQ",
      label: "(+596) Martinique",
      countryName: "Martinique"
    },
    {
      value: "+222",
      countryIso: "MR",
      label: "(+222) Mauritania",
      countryName: "Mauritania"
    },
    {
      value: "+230",
      countryIso: "MU",
      label: "(+230) Mauritius",
      countryName: "Mauritius"
    },
    {
      value: "+262",
      countryIso: "YT",
      label: "(+262) Mayotte",
      countryName: "Mayotte"
    },
    {
      value: "+52",
      countryIso: "MX",
      label: "(+52) Mexico",
      countryName: "Mexico"
    },
    {
      value: "+691",
      countryIso: "FM",
      label: "(+691) Micronesia",
      countryName: "Micronesia"
    },
    {
      value: "+373",
      countryIso: "MD",
      label: "(+373) Moldova",
      countryName: "Moldova"
    },
    {
      value: "+377",
      countryIso: "MC",
      label: "(+377) Monaco",
      countryName: "Monaco"
    },
    {
      value: "+976",
      countryIso: "MN",
      label: "(+976) Mongolia",
      countryName: "Mongolia"
    },
    {
      value: "+382",
      countryIso: "ME",
      label: "(+382) Montenegro",
      countryName: "Montenegro"
    },
    {
      value: "+663",
      countryIso: "MS",
      label: "(+663) Montserrat",
      countryName: "Montserrat"
    },
    {
      value: "+212",
      countryIso: "MA",
      label: "(+212) Morocco",
      countryName: "Morocco"
    },
    {
      value: "+258",
      countryIso: "MZ",
      label: "(+258) Mozambique",
      countryName: "Mozambique"
    },
    {
      value: "+95",
      countryIso: "MM",
      label: "(+95) Myanmar",
      countryName: "Myanmar"
    },
    {
      value: "+264",
      countryIso: "NA",
      label: "(+264) Namibia",
      countryName: "Namibia"
    },
    {
      value: "+674",
      countryIso: "NR",
      label: "(+674) Nauru",
      countryName: "Nauru"
    },
    {
      value: "+977",
      countryIso: "NP",
      label: "(+977) Nepal",
      countryName: "Nepal"
    },
    {
      value: "+31",
      countryIso: "NL",
      label: "(+31) Netherlands",
      countryName: "Netherlands"
    },
    {
      value: "+599",
      countryIso: "AN",
      label: "(+599) Netherlands Antilles",
      countryName: "Netherlands Antilles"
    },
    {
      value: "+687",
      countryIso: "NC",
      label: "(+687) New Caledonia",
      countryName: "New Caledonia"
    },
    {
      value: "+64",
      countryIso: "NZ",
      label: "(+64) New Zealand",
      countryName: "New Zealand"
    },
    {
      value: "+505",
      countryIso: "NI",
      label: "(+505) Nicaragua",
      countryName: "Nicaragua"
    },
    {
      value: "+227",
      countryIso: "NE",
      label: "(+227) Niger",
      countryName: "Niger"
    },
    {
      value: "+234",
      countryIso: "NG",
      label: "(+234) Nigeria",
      countryName: "Nigeria"
    },
    {
      value: "+683",
      countryIso: "NU",
      label: "(+683) Niue",
      countryName: "Niue"
    },
    {
      value: "+672",
      countryIso: "NF",
      label: "(+672) Norfolk Island",
      countryName: "Norfolk Island"
    },
    {
      value: "+669",
      countryIso: "MP",
      label: "(+669) Northern Mariana Islands",
      countryName: "Northern Mariana Islands"
    },
    {
      value: "+850",
      countryIso: "KP",
      label: "(+850) North Korea",
      countryName: "North Korea"
    },
    {
      value: "+47",
      countryIso: "NO",
      label: "(+47) Norway",
      countryName: "Norway"
    },
    {
      value: "+968",
      countryIso: "OM",
      label: "(+968) Oman",
      countryName: "Oman"
    },
    {
      value: "+92",
      countryIso: "PK",
      label: "(+92) Pakistan",
      countryName: "Pakistan"
    },
    {
      value: "+680",
      countryIso: "PW",
      label: "(+680) Palau",
      countryName: "Palau"
    },
    {
      value: "+970",
      countryIso: "PS",
      label: "(+970) Palestinian Territory",
      countryName: "Palestinian Territory"
    },
    {
      value: "+507",
      countryIso: "PA",
      label: "(+507) Panama",
      countryName: "Panama"
    },
    {
      value: "+675",
      countryIso: "PG",
      label: "(+675) Papua New Guinea",
      countryName: "Papua New Guinea"
    },
    {
      value: "+595",
      countryIso: "PY",
      label: "(+595) Paraguay",
      countryName: "Paraguay"
    },
    {
      value: "+51",
      countryIso: "PE",
      label: "(+51) Peru",
      countryName: "Peru"
    },
    {
      value: "+63",
      countryIso: "PH",
      label: "(+63) Philippines",
      countryName: "Philippines"
    },
    {
      value: "+870",
      countryIso: "PN",
      label: "(+870) Pitcairn",
      countryName: "Pitcairn"
    },
    {
      value: "+48",
      countryIso: "PL",
      label: "(+48) Poland",
      countryName: "Poland"
    },
    {
      value: "+351",
      countryIso: "PT",
      label: "(+351) Portugal",
      countryName: "Portugal"
    },
    {
      value: "+1787",
      countryIso: "PR",
      label: "(+1787) Puerto Rico",
      countryName: "Puerto Rico"
    },
    {
      value: "+974",
      countryIso: "QA",
      label: "(+974) Qatar",
      countryName: "Qatar"
    },
    {
      value: "+242",
      countryIso: "CG",
      label: "(+242) Republic of the Congo",
      countryName: "Republic of the Congo"
    },
    {
      value: "+262",
      countryIso: "RE",
      label: "(+262) Reunion",
      countryName: "Reunion"
    },
    {
      value: "+40",
      countryIso: "RO",
      label: "(+40) Romania",
      countryName: "Romania"
    },
    {
      value: "+7",
      countryIso: "RU",
      label: "(+7) Russia",
      countryName: "Russia"
    },
    {
      value: "+250",
      countryIso: "RW",
      label: "(+250) Rwanda",
      countryName: "Rwanda"
    },
    {
      value: "+590",
      countryIso: "BL",
      label: "(+590) Saint Barthélemy",
      countryName: "Saint Barthélemy"
    },
    {
      value: "+290",
      countryIso: "SH",
      label: "(+290) Saint Helena",
      countryName: "Saint Helena"
    },
    {
      value: "+868",
      countryIso: "KN",
      label: "(+868) Saint Kitts and Nevis",
      countryName: "Saint Kitts and Nevis"
    },
    {
      value: "+757",
      countryIso: "LC",
      label: "(+757) Saint Lucia",
      countryName: "Saint Lucia"
    },
    {
      value: "+590",
      countryIso: "MF",
      label: "(+590) Saint Martin",
      countryName: "Saint Martin"
    },
    {
      value: "+508",
      countryIso: "PM",
      label: "(+508) Saint Pierre and Miquelon",
      countryName: "Saint Pierre and Miquelon"
    },
    {
      value: "+783",
      countryIso: "VC",
      label: "(+783) Saint Vincent and the Grenadines",
      countryName: "Saint Vincent and the Grenadines"
    },
    {
      value: "+685",
      countryIso: "WS",
      label: "(+685) Samoa",
      countryName: "Samoa"
    },
    {
      value: "+378",
      countryIso: "SM",
      label: "(+378) San Marino",
      countryName: "San Marino"
    },
    {
      value: "+239",
      countryIso: "ST",
      label: "(+239) Sao Tome and Principe",
      countryName: "Sao Tome and Principe"
    },
    {
      value: "+966",
      countryIso: "SA",
      label: "(+966) Saudi Arabia",
      countryName: "Saudi Arabia"
    },
    {
      value: "+221",
      countryIso: "SN",
      label: "(+221) Senegal",
      countryName: "Senegal"
    },
    {
      value: "+381",
      countryIso: "RS",
      label: "(+381) Serbia",
      countryName: "Serbia"
    },
    {
      value: "+381",
      countryIso: "CS",
      label: "(+381) Serbia and Montenegro",
      countryName: "Serbia and Montenegro"
    },
    {
      value: "+248",
      countryIso: "SC",
      label: "(+248) Seychelles",
      countryName: "Seychelles"
    },
    {
      value: "+232",
      countryIso: "SL",
      label: "(+232) Sierra Leone",
      countryName: "Sierra Leone"
    },
    {
      value: "+65",
      countryIso: "SG",
      label: "(+65) Singapore",
      countryName: "Singapore"
    },
    {
      value: "+599",
      countryIso: "SX",
      label: "(+599) Sint Maarten",
      countryName: "Sint Maarten"
    },
    {
      value: "+421",
      countryIso: "SK",
      label: "(+421) Slovakia",
      countryName: "Slovakia"
    },
    {
      value: "+386",
      countryIso: "SI",
      label: "(+386) Slovenia",
      countryName: "Slovenia"
    },
    {
      value: "+677",
      countryIso: "SB",
      label: "(+677) Solomon Islands",
      countryName: "Solomon Islands"
    },
    {
      value: "+252",
      countryIso: "SO",
      label: "(+252) Somalia",
      countryName: "Somalia"
    },
    {
      value: "+27",
      countryIso: "ZA",
      label: "(+27) South Africa",
      countryName: "South Africa"
    },
    {
      value: "+500",
      countryIso: "GS",
      label: "(+500) South Georgia and the South Sandwich Islands",
      countryName: "South Georgia and the South Sandwich Islands"
    },
    {
      value: "+82",
      countryIso: "KR",
      label: "(+82) South Korea",
      countryName: "South Korea"
    },
    {
      value: "+34",
      countryIso: "ES",
      label: "(+34) Spain",
      countryName: "Spain"
    },
    {
      value: "+94",
      countryIso: "LK",
      label: "(+94) Sri Lanka",
      countryName: "Sri Lanka"
    },
    {
      value: "+249",
      countryIso: "SD",
      label: "(+249) Sudan",
      countryName: "Sudan"
    },
    {
      value: "+597",
      countryIso: "SR",
      label: "(+597) Suriname",
      countryName: "Suriname"
    },
    {
      value: "+47",
      countryIso: "SJ",
      label: "(+47) Svalbard and Jan Mayen",
      countryName: "Svalbard and Jan Mayen"
    },
    {
      value: "+268",
      countryIso: "SZ",
      label: "(+268) Swaziland",
      countryName: "Swaziland"
    },
    {
      value: "+46",
      countryIso: "SE",
      label: "(+46) Sweden",
      countryName: "Sweden"
    },
    {
      value: "+41",
      countryIso: "CH",
      label: "(+41) Switzerland",
      countryName: "Switzerland"
    },
    {
      value: "+963",
      countryIso: "SY",
      label: "(+963) Syria",
      countryName: "Syria"
    },
    {
      value: "+886",
      countryIso: "TW",
      label: "(+886) Taiwan",
      countryName: "Taiwan"
    },
    {
      value: "+992",
      countryIso: "TJ",
      label: "(+992) Tajikistan",
      countryName: "Tajikistan"
    },
    {
      value: "+255",
      countryIso: "TZ",
      label: "(+255) Tanzania",
      countryName: "Tanzania"
    },
    {
      value: "+66",
      countryIso: "TH",
      label: "(+66) Thailand",
      countryName: "Thailand"
    },
    {
      value: "+228",
      countryIso: "TG",
      label: "(+228) Togo",
      countryName: "Togo"
    },
    {
      value: "+690",
      countryIso: "TK",
      label: "(+690) Tokelau",
      countryName: "Tokelau"
    },
    {
      value: "+676",
      countryIso: "TO",
      label: "(+676) Tonga",
      countryName: "Tonga"
    },
    {
      value: "+867",
      countryIso: "TT",
      label: "(+867) Trinidad and Tobago",
      countryName: "Trinidad and Tobago"
    },
    {
      value: "+216",
      countryIso: "TN",
      label: "(+216) Tunisia",
      countryName: "Tunisia"
    },
    {
      value: "+90",
      countryIso: "TR",
      label: "(+90) Turkey",
      countryName: "Turkey"
    },
    {
      value: "+993",
      countryIso: "TM",
      label: "(+993) Turkmenistan",
      countryName: "Turkmenistan"
    },
    {
      value: "+648",
      countryIso: "TC",
      label: "(+648) Turks and Caicos Islands",
      countryName: "Turks and Caicos Islands"
    },
    {
      value: "+688",
      countryIso: "TV",
      label: "(+688) Tuvalu",
      countryName: "Tuvalu"
    },
    {
      value: "+256",
      countryIso: "UG",
      label: "(+256) Uganda",
      countryName: "Uganda"
    },
    {
      value: "+380",
      countryIso: "UA",
      label: "(+380) Ukraine",
      countryName: "Ukraine"
    },
    {
      value: "+971",
      countryIso: "AE",
      label: "(+971) United Arab Emirates",
      countryName: "United Arab Emirates"
    },
    {
      value: "+44",
      countryIso: "GB",
      label: "(+44) United Kingdom",
      countryName: "United Kingdom"
    },
    {
      value: "+1",
      countryIso: "UM",
      label: "(+1) United States Minor Outlying Islands",
      countryName: "United States Minor Outlying Islands"
    },
    {
      value: "+598",
      countryIso: "UY",
      label: "(+598) Uruguay",
      countryName: "Uruguay"
    },
    {
      value: "+339",
      countryIso: "VI",
      label: "(+339) U.S. Virgin Islands",
      countryName: "U.S. Virgin Islands"
    },
    {
      value: "+998",
      countryIso: "UZ",
      label: "(+998) Uzbekistan",
      countryName: "Uzbekistan"
    },
    {
      value: "+678",
      countryIso: "VU",
      label: "(+678) Vanuatu",
      countryName: "Vanuatu"
    },
    {
      value: "+379",
      countryIso: "VA",
      label: "(+379) Vatican",
      countryName: "Vatican"
    },
    {
      value: "+58",
      countryIso: "VE",
      label: "(+58) Venezuela",
      countryName: "Venezuela"
    },
    {
      value: "+84",
      countryIso: "VN",
      label: "(+84) Vietnam",
      countryName: "Vietnam"
    },
    {
      value: "+681",
      countryIso: "WF",
      label: "(+681) Wallis and Futuna",
      countryName: "Wallis and Futuna"
    },
    {
      value: "+212",
      countryIso: "EH",
      label: "(+212) Western Sahara",
      countryName: "Western Sahara"
    },
    {
      value: "+967",
      countryIso: "YE",
      label: "(+967) Yemen",
      countryName: "Yemen"
    },
    {
      value: "+260",
      countryIso: "ZM",
      label: "(+260) Zambia",
      countryName: "Zambia"
    },
    {
      value: "+263",
      countryIso: "ZW",
      label: "(+263) Zimbabwe",
      countryName: "Zimbabwe"
    }
  ];
};

import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Draggable from "react-draggable";
import { PermissionModel } from "../../../../services/entities/view-models/settings-view-models";
import {
  updateObject,
  getErrorToastData,
  isNullOrEmpty,
} from "../../../../shared/utility";
import { showNotification } from "../../../../components/UI/Toaster/Toaster";
import { validatePermissionSettings } from "../../settingsHelper";
import { Multiselect } from "react-widgets";
import { LabelAndValuePairControl } from "../../../../components/UI/Input/label-value-pair-control";

export const PermissionModal = (props) => {
  const entity = props.selectedEntity || new PermissionModel();
  const [permissionEntity, setPermissionsEntity] = useState(entity);
  const [multiSelectValues, setMultiSelectValues] = useState(
    entity.PrerequisitePermissions || []
  );
  const [permissionUrls, setPermissionUrls] = useState(entity.URIs);
  const [isValidateDone, setIsValidateDone] = useState(false);

  const handleOnChange = (event, controlName, elementType) => {
    let updateCtrls = { ...permissionEntity };
    const value =
      elementType === "checkbox" ? event.target.checked : event.target.value;

    if (controlName !== "URIs") {
      updateCtrls = updateObject(updateCtrls, {
        [controlName]: value,
      });
    } else {
      updateCtrls.URIs[event.target.dataset.id] = value;
    }
    setPermissionsEntity(updateCtrls);
  };

  const addNewRow = (index) => {
    let permissionUrlsList = [...permissionUrls];
    let updateCtrls = { ...permissionEntity };

    if (permissionUrlsList.length > 0) {
      permissionUrlsList.splice(index + 1, 0, "");
    } else {
      permissionUrlsList.push("");
    }
    setPermissionUrls(permissionUrlsList);
    updateCtrls.URIs = permissionUrlsList;
    setPermissionsEntity(updateCtrls);
  };

  const removeRow = (index) => {
    let permissionUrlsList = [...permissionUrls];
    let updateCtrls = { ...permissionEntity };

    if (permissionUrlsList.length >= 1) {
      permissionUrlsList.splice(index, 1);
    }
    if (permissionUrlsList.length === 0) {
      permissionUrlsList.push("");
    }
    setPermissionUrls(permissionUrlsList);
    updateCtrls.URIs = permissionUrlsList;
    setPermissionsEntity(updateCtrls);
  };

  const updatePermissionItems = () => {
    let newReq = JSON.parse(JSON.stringify(permissionEntity));
    newReq.PrerequisitePermissions =
      multiSelectValues &&
      multiSelectValues.map((item) => {
        return item.key || item;
      });
    let msg = validatePermissionSettings(newReq);
    if (msg) {
      showNotification(getErrorToastData(msg));
      setIsValidateDone(true);
    } else {
      setIsValidateDone(false);
      props.addUpdatePermissionEntity(newReq);
    }
  };

  const hasEmptyRows = (urls) => {
    return urls.includes("");
  };

  const getUrlsRows = () => {
    if (permissionUrls.length > 0) {
      return permissionUrls.map((Url, index) => {
        let addNewRowBtnCls, removeRowBtnCls, disabled;
        addNewRowBtnCls = removeRowBtnCls = disabled = "light-gray-color";
        let addRowEnable = "text-primary cursor-pointer";
        let removeRowEnable = "text-danger cursor-pointer";

        if (index === 0) {
          addNewRowBtnCls = !isNullOrEmpty(Url) ? addRowEnable : disabled;
          if (permissionUrls.length > 1) {
            removeRowBtnCls = removeRowEnable;
          }
        } else if (index !== 0) {
          addNewRowBtnCls = isNullOrEmpty(Url) ? disabled : addRowEnable;
          removeRowBtnCls = removeRowEnable;
        }

        let hasEmptyRow = hasEmptyRows(permissionUrls);

        return (
          <div className="row" key={index} style={{ minHeight: "2.6rem" }}>
            <span className="row__column">
              <label>URL(s)</label>
            </span>
            <span className="row__column">
              <input
                type="text"
                data-id={index}
                name="URIs"
                value={Url || ""}
                onChange={(event) => handleOnChange(event, "URIs")}
              />
            </span>

            <span className="text-center">
              <i
                className={`fa fa-plus-circle fs-5 ${addNewRowBtnCls}`}
                onClick={() =>
                  addNewRowBtnCls === "light-gray-color" || hasEmptyRow
                    ? ""
                    : addNewRow(index)
                }
              />
            </span>

            <span className="text-left">
              <i
                className={`fa fa-minus-circle fs-5 ${removeRowBtnCls}`}
                onClick={() =>
                  removeRowBtnCls === "light-gray-color" ? "" : removeRow(index)
                }
              />
            </span>
          </div>
        );
      });
    }
  };
  const renderLabelInputPair = (
    labelCaption,
    controlName,
    isRequired = false,
    nodeElement = "input",
    dropdownItem = null,
    elementType = ""
  ) => {
    let value = permissionEntity[controlName];
    const valueOrCheckedAttribute =
      nodeElement === "checkbox" ? "checked" : "value";
    return (
      <LabelAndValuePairControl
        elementConfig={{
          name: controlName,
          className: nodeElement === "select" ? "pl-0" : "",
          [valueOrCheckedAttribute]: value,
          type: elementType,
        }}
        otherConfig={{
          caption: labelCaption,
          isRequired: isRequired,
          dropdownItem: nodeElement === "select" ? dropdownItem : [],
          value: value,
          dateFormat: "D",
          isHideCheckBoxCaption: true,
        }}
        isEditMode={true}
        onChange={handleOnChange}
        parentRowClass={`form-group row  ${isRequired ? "required" : ""}`}
        labelClasses={"col-sm-4 col-form-label font-weight-bold"}
        valueClasses={"col-sm-8"}
        nodeElement={nodeElement}
        isValidateDone={isValidateDone}
      />
    );
  };
  return (
    <Draggable handle=".cursor">
      <Modal
        isOpen={props.showModal}
        className="no-cursor"
        toggle={() => {
          props.closeModal();
        }}
        style={{
          minWidth: "60rem",
        }}
      >
        <ModalHeader
          className="modal-header bg-primary modal-title text-white cursor"
          toggle={() => {
            props.closeModal();
          }}
        >
          <i className="fas fa-cogs pr-2"></i>
          {permissionEntity.PermissionId ? "Edit Permission" : "Add Permission"}
        </ModalHeader>
        <ModalBody>
          <div className="col-sm-12">
            {renderLabelInputPair("Permission Name", "Name", true)}
            {renderLabelInputPair("Description", "DisplayText", true)}
            {renderLabelInputPair(
              "Permission Type",
              "PermissionType",
              true,
              "select",
              props.permissionTypes
            )}
            {renderLabelInputPair("Error Code", "ErrorCode")}
            {renderLabelInputPair("Error Msg", "ErrorMsg")}
            {renderLabelInputPair(
              "Visible To Super Admin Only",
              "VisibleToSuperAdminOnly",
              false,
              "checkbox",
              null,
              "checkbox"
            )}
            <div className="col-sm-12">
              <hr />
            </div>

            <div className="form-group row">
              <label className="col-sm-12 col-form-label font-weight-bold text-danger">
                WARNING: Delete or change values in this section with caution.
                Incorrect URL(s) or dependent permissions may cause the
                application to stop functioning correctly. Verify values before
                updating.
              </label>
            </div>
            <div className="form-group row">
              <label className="col-sm-12 col-form-label font-weight-bold">
                Permission(s) Dependency
              </label>
              <div className="col-sm-12 pl-5">
                <Multiselect
                  data={props.parmissionList || []}
                  valueField="key"
                  textField="displayValue"
                  value={multiSelectValues}
                  onChange={(value) => setMultiSelectValues(value)}
                  groupBy="type"
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-sm-12 col-form-label font-weight-bold">
                Associated Webservice URL(s)
              </label>
              <div className="col-sm-12">
                <div className="grid-table tableScroll" id="permission-urls">
                  <div className="datagrid">{getUrlsRows()}</div>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="col-sm-12 text-right">
            <button
              type="button"
              name="btnYes"
              className="btn btn-primary waves-effect waves-light btnSize ml-2"
              onClick={() => updatePermissionItems()}
            >
              {permissionEntity.PermissionId ? "Update" : "Submit"}
            </button>
            <button
              type="button"
              name="btnCancel"
              className="btn btn-secondary waves-effect waves-light btnSize ml-2"
              onClick={() => props.closeModal()}
            >
              Cancel
            </button>
          </div>
        </ModalFooter>
      </Modal>
    </Draggable>
  );
};
export default PermissionModal;

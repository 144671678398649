import React from 'react';
import { formatAmountValue } from '../../../../shared/utility';
import { PaymentRateType, PaymentType } from '../../../../shared/utility/enum-utility';
import CommonTitle from '../../component/common-title';
import AttributeLabel from './attribute-label';

function VehicleTotalRatesContainer(props) {
    const {
        title,
        vehicleClass,
        isVehicleClassSelected,
        currentPayType,
        containerClass,
        pricing,
        onPaymentBtnClick,
        disabled,
        allowAdminEdit,
        showBtn = true,
    } = props;

    const monthlyRate = pricing?.ratesApplied?.find((rate) => rate.rate > 0 && rate?.type === PaymentRateType.MONTHLY);
    const weeklyRate = pricing?.ratesApplied?.find((rate) => rate.rate > 0 && rate?.type === PaymentRateType.WEEKLY);
    const dailyRate = pricing?.ratesApplied?.find((rate) => rate.rate > 0 && rate?.type === PaymentRateType.DAILY);
    const weekendRate = pricing?.ratesApplied?.find((rate) => rate.rate > 0 && rate?.type === PaymentRateType.WEEKEND);
    const hourlyRate = pricing?.ratesApplied?.find((rate) => rate.rate > 0 && rate?.type === PaymentRateType.HOURLY);


    const isPayBtnSelected = isVehicleClassSelected && pricing?.type === currentPayType && true;
    const getPaymentBtnTxt = () => {
        if (pricing?.type === PaymentType.PREPAID) {
            return isPayBtnSelected ? "Reserved" : "Pay Now";
        } else {
            return isPayBtnSelected ? "Reserved" : "Pay Later";
        }
    }
    const onPayBtnClick = () => {
        return !disabled ? onPaymentBtnClick(vehicleClass, pricing) : "";
    }
    return (
        <>
            {title ? <CommonTitle title={title} classNames="common-title" /> : ""}
            <div className={`vehicle-total-container  ${containerClass} ${isPayBtnSelected && 'border-color-primary'}`}>
                <div className='flex-space-between position-relative'>
                    <div>
                        <div className='vehicle-title-attr'>
                            <CommonTitle title={"Total: " + formatAmountValue(pricing?.totalCharges)} classNames="common-title color-primary font-weight-700" />
                            {pricing.type === PaymentType?.PREPAID ? <AttributeLabel title={`SAVE ${formatAmountValue(pricing?.prepaidSavedAmount)}`} classNames="attr-lbl attr-bg3" /> : ""}
                        </div>
                        <div className='rate-summary'>
                            {monthlyRate ? <CommonTitle title={`${formatAmountValue(monthlyRate.rate)} / ${PaymentRateType.MONTHLY[0]}`} classNames="common-title-secondary" /> : ""}
                            {monthlyRate && (weeklyRate || dailyRate || weekendRate || hourlyRate) ?
                                <span className='dot' /> : ""}
                            {weeklyRate ? <CommonTitle title={`${formatAmountValue(weeklyRate.rate)} / ${PaymentRateType.WEEKLY[0]}`} classNames="common-title-secondary" /> : ""}
                            {weeklyRate && (dailyRate || weekendRate || hourlyRate) ?
                                <span className='dot' /> : ""}
                            {dailyRate ? <CommonTitle title={`${formatAmountValue(dailyRate.rate)} / ${PaymentRateType.DAILY[0]}`} classNames="common-title-secondary" /> : ""}
                            {dailyRate && (weekendRate || hourlyRate) ?
                                <span className='dot' /> : ""}
                            {weekendRate ? <CommonTitle title={`${formatAmountValue(weekendRate.rate)} / ${PaymentRateType.WEEKEND[0]}`} classNames="common-title-secondary" /> : ""}
                            {weekendRate && hourlyRate ?
                                <span className='dot' /> : ""}
                            {hourlyRate ? <CommonTitle title={`${formatAmountValue(hourlyRate.rate)} / ${PaymentRateType.HOURLY[0]}`} classNames="common-title-secondary" /> : ""}
                        </div>
                        {pricing?.ratePlan?.rateBeforeDiscount ?
                            <CommonTitle title={`${formatAmountValue(pricing?.ratePlan?.rateBeforeDiscount)} / ${pricing?.ratePlan?.type[0]}`} classNames="common-title-secondary" isStrike={true} />
                            : ""}
                    </div>
                    {showBtn ?
                        <button
                            className={
                                `btn font-size-all vehicle-btn ${isPayBtnSelected ? 'btn-yellow-default' : 'common-reserve-btn'} position-absolute`
                            }
                            onClick={onPayBtnClick}
                            disabled={disabled || (allowAdminEdit && !allowAdminEdit)}
                        // title={isEditAndPayLater ? COMMON_MSG.PayLaterToPayNow : ""}
                        >
                            {getPaymentBtnTxt()}
                        </button> : ""}
                </div>
            </div >
        </>
    )
}

export default VehicleTotalRatesContainer
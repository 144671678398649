import SubMenu from "../model/sub-menu";
import SubMenuItem from "../model/sub-menu-item";
import KeyValuePair from "../../../services/entities/models/KeyValuePair";
import ReservationDetails from "../../../containers/Reservation/details/reservation-details";
import VehicleDetails from "../../../containers/vehicle/details/vehicle-details";
import MemberProfile from "../../../containers/Member/member-profile/member-profile";
import SearchPageContainer from "../../../containers/search/search-page-container";
import FeedbackDetails from "../../../containers/feedback/details/feedback-details";
import ReportProblemDetails from "../../../containers/report/details/report-problem-details";
import MemberMessage from "../../../containers/Member/member-messages/search-messages";

export const getReservationDetailsSubMenu = (summaryReservationData) => {
  const subMenu = new SubMenu();
  subMenu.menuKey = "reservation_details";
  subMenu.subMenuItems.push(
    new SubMenuItem(
      "overview",
      "Overview",
      "fas fa-info",
      ReservationDetails,
      summaryReservationData.ReservationId,
      [new KeyValuePair("reservationId", summaryReservationData.ReservationId)]
    )
  );
  subMenu.subMenuItems.push(
    new SubMenuItem(
      "vehicle_details",
      "Vehicle Details",
      "fas fa-car",
      VehicleDetails,
      summaryReservationData.BorrowerId &&
        summaryReservationData.ReservationAssetId,
      [
        new KeyValuePair("memberId", summaryReservationData.BorrowerId),
        new KeyValuePair(
          "vehicleId",
          summaryReservationData.ReservationAssetId
        ),
      ]
    )
  );
  subMenu.subMenuItems.push(
    new SubMenuItem(
      "renter_details",
      "Renter Details",
      "fas fa-user-tie",
      MemberProfile,
      summaryReservationData.BorrowerId,
      [new KeyValuePair("memberId", summaryReservationData.BorrowerId)]
    )
  );
  subMenu.subMenuItems.push(
    new SubMenuItem(
      "message",
      "Message ",
      "fas fa-envelope",
      MemberMessage,
      summaryReservationData.ReservationId && summaryReservationData.BorrowerId,
      [
        new KeyValuePair("reservationId", summaryReservationData.ReservationId),
        new KeyValuePair("memberId", summaryReservationData.BorrowerId),
      ]
    )
  );
  subMenu.subMenuItems.push(
    new SubMenuItem(
      "feedback",
      "Feedback",
      "fas fa-comments",
      FeedbackDetails,
      summaryReservationData.ReservationId &&
        summaryReservationData.BorrowerId &&
        summaryReservationData.OwnerId,
      [
        new KeyValuePair("reservationId", summaryReservationData.ReservationId),
        new KeyValuePair("memberId", summaryReservationData.BorrowerId),
        new KeyValuePair("ownerId", summaryReservationData.OwnerId),
      ]
    )
  );
  subMenu.subMenuItems.push(
    new SubMenuItem(
      "billing",
      "Billing",
      "far fa-money-bill-alt",
      SearchPageContainer,
      summaryReservationData.ReservationId,
      [
        new KeyValuePair("reservationId", summaryReservationData.ReservationId),
        new KeyValuePair("memberId", summaryReservationData.BorrowerId),
      ]
    )
  );
  subMenu.subMenuItems.push(
    new SubMenuItem(
      "report_problem",
      "Report Problem",
      "fas fa-bug",
      ReportProblemDetails,
      summaryReservationData.ReservationId && summaryReservationData.BorrowerId,
      [
        new KeyValuePair("reservationId", summaryReservationData.ReservationId),
        new KeyValuePair("memberId", summaryReservationData.BorrowerId),
      ]
    )
  );
  return subMenu;
};

import React from 'react';
import { PaymentType } from '../../../../shared/utility/enum-utility';
import CommonTitle from '../../component/common-title';
import AttributeLabel from './attribute-label';
import VehicleInfoContainer from './component/vehicle-info-container';
import VehicleTotalRatesContainer from './vehicle-total-rates-container';

function VehicleRatesContainer(props) {
    const {
        isVehicleClassSelected,
        vehicleClass,
        currentPayType,
        pricingList,
        dbRates,
        disabled,
        disablePayNow,
        onPaymentBtnClick,
        isOldRate,
        setDialogData,
        allowAdminEdit
    } = props;
    if (isOldRate) return "";
    return (
        <div id="res-vehicle-page" className={`common-res-container ${isVehicleClassSelected && "selected-section"}`}>
            {dbRates?.length && pricingList?.length ?
                <AttributeLabel title="Current Selection" classNames="attr-lbl fit-content" /> : ""
            }
            <VehicleInfoContainer vehicleClass={vehicleClass} setDialogData={setDialogData} />
            {dbRates?.length && pricingList.length ?
                <CommonTitle title={`Old Rates ${dbRates[0]?.type === PaymentType.PREPAID ? "(Pay Now)" : "(Pay Later)"}`} classNames="common-title" />
                : ""
            }
            {dbRates?.length > 0 ?
                dbRates.map((pricingData) => {
                    return <VehicleTotalRatesContainer
                        key={pricingData?.type}
                        containerClass="new-rate"
                        vehicleClass={vehicleClass}
                        currentPayType={currentPayType}
                        pricing={pricingData}
                        isVehicleClassSelected={!pricingList?.length && true}
                        showBtn={false}
                        disabled={disabled}
                        onPaymentBtnClick={onPaymentBtnClick}
                        allowAdminEdit={allowAdminEdit}
                    />
                })
                : null
            }
            {dbRates?.length && pricingList?.length ?
                <CommonTitle title="New Rates" classNames="common-title" />
                : ""
            }
            {pricingList?.length > 0 ?
                pricingList.map((pricingData) => {
                    return <VehicleTotalRatesContainer
                        key={pricingData?.type}
                        containerClass="new-rate"
                        vehicleClass={vehicleClass}
                        currentPayType={currentPayType}
                        pricing={pricingData}
                        isVehicleClassSelected={isVehicleClassSelected}
                        disabled={disabled ? true : pricingData?.type === PaymentType.PREPAID && disablePayNow ? true : false}
                        onPaymentBtnClick={onPaymentBtnClick}
                        allowAdminEdit={allowAdminEdit}
                    />
                })
                : null
            }
        </div>
    )
}

export default VehicleRatesContainer
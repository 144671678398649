const LicenseApproveRequest = function () {
  this.MemberId = null;
  this.Message = null;
  this.ViolationPoints = null;
  this.Attachment1 = null;
  this.Attachment2 = null;
  this.Attachment1Ext = null;
  this.Attachment2Ext = null;
  this.Attachment1Category = null;
  this.Attachment2Category = null;
  this.Approved = null;
};
export default LicenseApproveRequest;

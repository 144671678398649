import React, { Fragment, Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../../../store/actions/index";
import Spinner from "../../../components/UI/Spinner/Spinner";
import { showNotification } from "../../../components/UI/Toaster/Toaster";
import Payment from "../../../components/payment/payment";
import PricingTable from "../../../components/pricing/PricingTable";
import { createPricingObj } from "../../../components/pricing/PricingData";
import {
  getBasicToastResponse,
  getDropdownItems,
  hasPermission,
  loadDefaultImage,
  populateBreadCrumbData,
} from "../../../shared/utility";
import { updateObject } from "../../../shared/utility";
import { ADMIN_PAGE, ModalType, TOAST_MSG } from "../../../shared/GlobalVar";
import Breadcrumbs from "../../../components/breadcrumbs/breadcrumbs";
import UpdateReservationModalEngine from "../modal/update-reservation-modal-engine";
import {
  getNewResMemberCompatibleData,
  loadCreditDatatoPaymentData,
  getReservationModalDefaultInfo,
} from "../../Reservation/reservation-helper";
import {
  createEndrentalMemberReq,
  compatibleReservationSummarytoEndRentalCtrl,
  createEndrentalChargesReq,
  createEndRentalPaymentReq,
  createEndRentalReq,
  validateEndRentalData,
} from "./endRentalHelper";
import { doPaymentValidate } from "../../../components/payment/paymentHelper";
import Slider from "react-rangeslider";
import moment from "moment";
import "react-rangeslider/lib/index.css";
import { Button } from "reactstrap";
import EndRentalInputCtrl from "./endRentalInputCtrl";
import ConfirmModal from "../../../components/common/modal/confirm-modal";
import { noImage } from "../../../shared/AppImages";
import Person from "../../../services/entities/models/Person";
import PaymentInfoRequest from "../../../services/entities/requests/PaymentInfoRequest";
import ModalInfo from "../../../services/entities/models/ModalInfo";
import { getMemberLabelByOrgRentalType } from "../../../shared/utility/system-utility";
import PermissionName from "../../../shared/Permissions";
import PreventTransitionPrompt from "../../../components/Navigations/prevent-navigation/prevent-transition-prompt";
import MemberModalEngine from "../../Member/modal/member-modal-engine";
import OverrideComponent from "../../../components/UI/overrides/override-component";
import { getOverrideInputControls } from "../../../components/UI/overrides/override-input";
import { LabelAndValuePairControl } from "../../../components/UI/Input/label-value-pair-control";
import { getPageName } from "../../search/search-utility/search-utility";
class EndRental extends Component {
  state = {
    loading: false,
    modalInfo: new ModalInfo(),
    endRentalPricingData: null,
    memberData: null,
    reservationSummaryData: null,
    addonsData: null,
    endRentalInputCtrl: JSON.parse(JSON.stringify(EndRentalInputCtrl)),
    memberInputReqest: new Person(),
    paymentInputRequest: new PaymentInfoRequest(),
    paymentReasonTypes: [],
    showAgentModal: false,
    agentResponse: null,
    isSearchedAgentPerformed: false,
    agentType: null,
    showConfirmModal: false,
    paymentReq: null,
    breadCrumbList: [],
    summaryData: {},
    lateChargeStyle: { right: "-8rem" },
    isBlocking: false,
  };
  showErrorMsg = (msg, toasterMsgType) => {
    var toastMsg = {
      msg: msg,
      toastType: toasterMsgType,
    };
    showNotification(toastMsg);
  };

  initPayment = (paymentType) => {
    const paymentReq = createEndRentalPaymentReq(
      this.state.endRentalInputCtrl,
      this.state.paymentInputRequest
    );
    paymentReq.RequestType = "PAY_NOW_END";
    const validationPaymentMsg = doPaymentValidate(
      paymentReq.PaymentInfo,
      this.state.paymentInputRequest,
      paymentType
    );
    if (validationPaymentMsg) {
     this.showErrorMsg(validationPaymentMsg, TOAST_MSG.ERROR);
      return false;
    } else {
      paymentReq.PaymentInfo = { ...this.state.PaymentInputRequest };
      paymentReq.PaymentInfo.ChargeViaGateway =
        paymentType === "PROCESS_PAYMENT" ? true : false;
    }
    this.setState({ paymentReq: paymentReq, showConfirmModal: true });
  };
  makePayment = () => {
    this.props.onManageReservation(this.state.paymentReq);
  };
  doEndRental = () => {
    const endRentalReq = createEndRentalReq(this.state.endRentalInputCtrl);
    const errorMsg = validateEndRentalData(endRentalReq);
     if (errorMsg) {
       this.showErrorMsg(errorMsg, TOAST_MSG.ERROR);
       return false;
     }
    if (this.state.endRentalInputCtrl.ReservationState === "COMPLETED") {
      this.props.onEditEndRental(endRentalReq);
    } else {
      this.props.onEndRental(endRentalReq);
    }
  };
  launchModal = (modalData) => {
    if (modalData.agentType) {
      this.props.onGetAgentReset();
    }
    this.setState({
      modalInfo: {
        type: modalData.type,
        showModal: true,
        memberId: modalData.memberId,
        vehicleId: modalData.vehicleId,
        fullName: modalData.fullName,
        memberData: modalData.memberData,
        agentType: modalData.agentType,
      },
    });
  };
  closeModal = () => {
    this.setState({
      modalInfo: new ModalInfo(),
    });
  };
  componentDidMount() {
    this.props.onGetMemberReset();
    //this.props.onGetDamageReset();
    this.props.onGetEndRentalChargesReset();
    if (!this.props.paymentReasonTypes) {
      this.props.onGetEnumvalues();
    } else {
      this.setState({ paymentReasonTypes: this.props.paymentReasonTypes });
    }
    if (this.props.location.state.summaryReservationData) {
      const getMemberReq = createEndrentalMemberReq(
        this.props.location.state.summaryReservationData.BorrowerId
      );
      this.props.onGetMember(getMemberReq);
      this.props.onGetAdditionalOptions(
        this.props.location.state.summaryReservationData.PickupLocationId
      );
      const pricingData = createPricingObj(
        this.props.location.state.summaryReservationData
          ? this.props.location.state.summaryReservationData
              .ReservationPricingInfo
          : {},
        "END_RENTAL"
      );
      let updatedPaymentInputRequest = { ...this.state.paymentInputRequest };
      updatedPaymentInputRequest.Amount = pricingData.balanceDue.total || null;
      this.setState({
        endRentalPricingData: pricingData,
        paymentInputRequest: updatedPaymentInputRequest,
        reservationSummaryData:
          this.props.location.state.summaryReservationData,
        endRentalInputCtrl: {
          ...this.state.endRentalInputCtrl,
          ...compatibleReservationSummarytoEndRentalCtrl(
            this.props.location.state.summaryReservationData
          ),
        },
      });
    }
  }
  UNSAFE_componentWillReceiveProps(
    {
      paymentReasonTypes,
      memberData,
      endRentalPricingData,
      endRentalResponse,
      editEndRentalResponse,
      manageReservationResponse,
      addonsData,
      agentResponse,
    },
    prevState
  ) {
    if (
      this.state.paymentReasonTypes.length === 0 &&
      paymentReasonTypes !== null
    ) {
      this.setState({ paymentReasonTypes: paymentReasonTypes });
    }
    if (!this.state.addonsData && addonsData) {
      this.setState({ addonsData: addonsData });
    }
    if (!this.state.memberData && memberData && memberData.Member) {
      const memberEntity = getNewResMemberCompatibleData(memberData.Member);
      let paymentInputRequest = { ...this.state.paymentInputRequest };
      paymentInputRequest = loadCreditDatatoPaymentData(
        paymentInputRequest,
        memberEntity
      );
      const updatedControls = updateObject(this.state.endRentalInputCtrl, {
        Dob: memberEntity.DrivingLicense.DateOfBirth,
      });
      this.setState(
        {
          endRentalInputCtrl: updatedControls,
          memberData: memberData.Member,
          memberInputReqest: memberEntity,
          paymentInputRequest: paymentInputRequest,
        },
        () => {
          this.props.onCalculateEndRentalPricing(
            createEndrentalChargesReq(updatedControls)
          );
        }
      );
    }
    if (
      (endRentalPricingData && !this.state.endRentalPricingData) ||
      (this.state.endRentalPricingData &&
        endRentalPricingData &&
        !prevState.endRentalPricingData)
    ) {
      if (endRentalPricingData.StatusCode === 0) {
        const pricingData = createPricingObj(
          endRentalPricingData.ReservationPricingInfo,
          "END_RENTAL"
        );
        let updatedPaymentInputRequest = { ...this.state.paymentInputRequest };
        updatedPaymentInputRequest.Amount =
          pricingData.balanceDue.total || null;
        this.setState({
          endRentalPricingData: pricingData,
          paymentInputRequest: updatedPaymentInputRequest,
        });
      } else {
        showNotification(getBasicToastResponse(endRentalPricingData));
        this.props.onGetEndRentalChargesReset();
      }
    }
    if (
      (agentResponse && !this.state.agentResponse) ||
      (this.state.agentResponse && agentResponse && !prevState.agentRespons)
    ) {
      this.setState({
        agentResponse: agentResponse,
        isSearchedAgentPerformed: true,
      });
    }
    if (manageReservationResponse && !this.props.manageReservationResponse) {
      this.showErrorMsg(
        manageReservationResponse.StatusMsg ||
          "Unknown server side error",
        manageReservationResponse.StatusCode
          ? TOAST_MSG.ERROR
          : TOAST_MSG.SUCCESS
      );
      this.props.onManageReservationChargesReset();
      if (manageReservationResponse.StatusCode === 0) {
         this.closeConfirmModal();
        //this.resetEndRentalPricing();
      }
    }
    if (endRentalResponse && !prevState.endRentalResponse) {
      this.showErrorMsg(
        endRentalResponse.StatusMsg || "Unknown server side error",
        endRentalResponse.StatusCode ? TOAST_MSG.ERROR : TOAST_MSG.SUCCESS
      );
      if (endRentalResponse.StatusCode === 0) {
        this.props.onEndRentalReset();
        let breadCrumbList = this.props.location.hasOwnProperty("state")
          ? populateBreadCrumbData(
              this.props.location.state.breadCrumbList,
              "Summary",
              this.props.location.pathname
            )
          : [];
        this.setState(
          {
            isBlocking: false,
          },
          () => {
            this.props.history.push(
              "/reservationSummary?reservationId=" +
                this.state.endRentalInputCtrl.ReservationId
            );
          }
        );
      } else {
        this.props.onEndRentalReset();
      }
    }
    if (editEndRentalResponse && !prevState.editEndRentalResponse) {
      this.showErrorMsg(
        editEndRentalResponse.StatusMsg,
        editEndRentalResponse.StatusCode
          ? TOAST_MSG.ERROR
          : TOAST_MSG.SUCCESS
      );
      if (editEndRentalResponse.StatusCode === 0) {
        this.props.onEditEndRentalReset();
        let breadCrumbList = this.props.location.hasOwnProperty("state")
          ? populateBreadCrumbData(
              this.props.location.state.breadCrumbList,
              "Summary",
              this.props.location.pathname
            )
          : [];
        this.setState(
          {
            isBlocking: false,
          },
          () => {
            this.props.history.push(
              "/reservationSummary?reservationId=" +
                this.state.endRentalInputCtrl.ReservationId,
              { breadCrumbList: breadCrumbList }
            );
          }
        );
      } else {
        this.props.onEditEndRentalReset();
      }
    }
  }
  resetLoader = (isLoading) => {
    this.setState({ loading: isLoading });
  };

  getAgentList = (getAgentListReq) => {
    this.props.onGetAgentReset();
    this.props.onGetAgentList(getAgentListReq);
  };
  onAgentSelected = (selectedAgentInfo) => {
    if (selectedAgentInfo.agentType === "IntegRecordedBy") {
      const paymentData = { ...this.state.paymentInputRequest };
      paymentData.IntegRecordedBy = selectedAgentInfo.agentId;
      paymentData.IntegRecordedByName = selectedAgentInfo.agentName;
      this.setState({
        paymentInputRequest: paymentData,
        agentResponse: null,
        agentType: null,
      });
    } else {
      const updatedControls = { ...this.state.endRentalInputCtrl };
      if (selectedAgentInfo.agentType === "IntegAdjustedBy") {
        updatedControls.IntegAdjustedBy = selectedAgentInfo.agentId;
        updatedControls.IntegAdjustedByName = selectedAgentInfo.agentName;
      } else if (selectedAgentInfo.agentType === "IntegArrivedBy") {
        updatedControls.IntegArrivedBy = selectedAgentInfo.agentId;
        updatedControls.IntegArrivedByName = selectedAgentInfo.agentName;
      } else if (selectedAgentInfo.agentType === "IntegCompletedBy") {
        updatedControls.IntegCompletedBy = selectedAgentInfo.agentId;
        updatedControls.IntegCompletedByName = selectedAgentInfo.agentName;
      }
      this.setState({
        endRentalInputCtrl: updatedControls,
        agentResponse: null,
        agentType: null,
        isBlocking: true,
      });
    }
  };
  agentRemove = (agentType) => {
    if (agentType === "IntegRecordedBy") {
      const paymentData = { ...this.state.paymentInputRequest };
      paymentData.IntegRecordedBy = null;
      paymentData.IntegRecordedByName = null;
      this.setState({
        paymentInputRequest: paymentData,
        agentType: null,
      });
    } else {
      const updatedControls = { ...this.state.endRentalInputCtrl };
      if (agentType === "IntegAdjustedBy") {
        updatedControls.IntegAdjustedBy = null;
        updatedControls.IntegAdjustedByName = null;
      }
      if (agentType === "IntegArrivedBy") {
        updatedControls.IntegArrivedBy = null;
        updatedControls.IntegArrivedByName = null;
      }
      if (agentType === "IntegCompletedBy") {
        updatedControls.IntegCompletedBy = null;
        updatedControls.IntegCompletedByName = null;
      }
      this.setState({
        endRentalInputCtrl: updatedControls,
        agentType: null,
        isBlocking: true,
      });
    }
  };
  resetEndRentalPricing = () => {
    this.props.onGetEndRentalChargesReset();
    this.props.onCalculateEndRentalPricing(
      createEndrentalChargesReq(this.state.endRentalInputCtrl)
    );
  };
  sliderChange = (value, type) => {
    const updatedControls = { ...this.state.endRentalInputCtrl };
    if (type === "FuelOut") {
      updatedControls.FuelOut = value;
    } else if (type === "FuelIn") {
      updatedControls.FuelIn = value;
    } else if (type === "CustomerReportedFuelIn") {
      updatedControls.CustomerReportedFuelIn = value;
    }
    this.setState({ endRentalInputCtrl: updatedControls, isBlocking: true });
  };
  sliderChangeComplete = () => {
    this.resetEndRentalPricing();
  };
  inputChangedHandler = (event, controlName, elementType) => {
    let value = null;
    if (elementType === "datetimepicker") {
      value = moment(event).format("MM:DD:YYYY:HH:mm");
    } else if (elementType === "checkbox") {
      value = event.target.checked;
    } else if (elementType === "select") {
      value = event.target.value;
    } else {
      value = event.target.value;
    }
    const updatedControls = updateObject(this.state.endRentalInputCtrl, {
      [controlName.capitalize()]: value,
    });
    this.setState(
      { endRentalInputCtrl: updatedControls, isBlocking: true },
      () => {
        if (
          controlName !== "OverrideEmail" &&
          controlName !== "OverridePayment" &&
          controlName !== "ReturnLocationId" &&
          controlName !== "Notes" &&
          controlName !== "CCANotes"
        ) {
          this.resetEndRentalPricing();
        }
      }
    );
  };
  inputAddressChangeHandler = (event, controlName, objectType) => {
    let addresObj = { ...this.state.paymentInputRequest.Address };
    if (controlName === "Country") {
      addresObj.State = null;
      addresObj.City = null;
      addresObj.ZipCode = null;
      addresObj.StateText = null;
      addresObj.CityText = null;
      addresObj.ZipCodeText = null;
    } else if (controlName === "State") {
      addresObj.City = null;
      addresObj.ZipCode = null;
      addresObj.StateText = null;
      addresObj.CityText = null;
      addresObj.ZipCodeText = null;
    } else if (controlName === "City") {
      addresObj.ZipCode = null;
      addresObj.CityText = null;
      addresObj.ZipCodeText = null;
    }
    addresObj[controlName] = event.target.value;
    let paymentControls = { ...this.state.paymentInputRequest };
    paymentControls.Address = { ...addresObj };
    this.setState({ paymentInputRequest: paymentControls, isBlocking: true });
  };
  inputPaymentChangeHandler = (event, controlName, elementType) => {
    let value = null;
    const updatedControls = { ...this.state.paymentInputRequest };
    if (elementType === "checkbox") {
      value = event.target.checked;
    } else if (
      controlName === "PaymentMethod" ||
      controlName === "TransactionType"
    ) {
      value = event;
    } else {
      value = event.target.value;
    }
    if (controlName === "SameAsProfileNameAndAddress") {
      if (event.target.checked) {
        updatedControls.BillingName = this.state.memberInputReqest.FullName;
        updatedControls.Address = {
          ...this.state.memberInputReqest.PrimaryAddress,
        };
      }
      updatedControls.SameAsProfileNameAndAddress = event.target.checked;
      this.setState({ paymentInputRequest: updatedControls, isBlocking: true });
      return 0;
    }
    updatedControls[controlName] = value;
    this.setState({ paymentInputRequest: updatedControls, isBlocking: true });
  };
  submitMiscellaneousChargesCreditsHandler = (event) => {
    event.preventDefault();
    const creditChargeObj = {
      LineItem: event.target.LineItem.value,
      Amount: parseFloat(event.target.Amount.value),
      ChargeType: event.target.ChargeType.value,
      UpsellOptionId: event.target.UpsellOptionId
        ? parseInt(event.target.UpsellOptionId.value)
        : null,
    };
    if (this.checkDuplicateChargesOrCredits(creditChargeObj)) {
      const updatedControls = { ...this.state.endRentalInputCtrl };
      if (creditChargeObj.ChargeType === "CHARGE") {
        updatedControls.AppliedChargeList.push(creditChargeObj);
      } else {
        updatedControls.AppliedCreditList.push(creditChargeObj);
      }
      this.setState({ endRentalInputCtrl: updatedControls }, () => {
        this.resetEndRentalPricing();
      });
    }
  };
  checkDuplicateChargesOrCredits = (creditChargeObj) => {
    const updatedControls = { ...this.state.endRentalInputCtrl };
    if (
      updatedControls.AppliedChargeList.length > 0 &&
      creditChargeObj.ChargeType === "Charge"
    ) {
      updatedControls.AppliedChargeList.map((chargeObj) => {
        if (chargeObj.LineItem === creditChargeObj.LineItem) {
          this.showErrorMsg(
            "You are trying to add a duplicate reservation charge, please fix it and resubmit.",
            TOAST_MSG.ERROR
          );
          return false;
        }
      });
    } else if (
      updatedControls.AppliedCreditList.length > 0 &&
      creditChargeObj.ChargeType === "Credit"
    ) {
      updatedControls.map((chargeObj) => {
        if (chargeObj.LineItem === creditChargeObj.LineItem) {
           this.showErrorMsg(
             "You are trying to add a duplicate reservation credit, please fix it and resubmit.",
             TOAST_MSG.ERROR
           );  
          return false;
        }
      });
    }
    return true;
  };
  removeChargeLineItem = (pricingLineItem) => {
    const endRentalInputCtrl = { ...this.state.endRentalInputCtrl };
    if (pricingLineItem.type === "CHARGE") {
      endRentalInputCtrl.AppliedChargeList.map((chargeObj, index) => {
        // const title = chargeObj.UpsellOptionId
        //   ? chargeObj.UpsellOptionDisplayText +
        //     (chargeObj.LineItem ? " - " + chargeObj.LineItem : "")
        //   : "Miscellaneous Charge";
        if (pricingLineItem.lineItem === chargeObj.LineItem) {
          endRentalInputCtrl.AppliedChargeList.splice(index, 1);
        }
      });
    }
    if (pricingLineItem.type === "CREDIT") {
      endRentalInputCtrl.AppliedCreditList.map((chargeObj, index) => {
        // const title = chargeObj.UpsellOptionId
        //   ? chargeObj.UpsellOptionDisplayText +
        //     (chargeObj.LineItem ? " - " + chargeObj.LineItem : "")
        //   : "Credit";
        if (pricingLineItem.lineItem === chargeObj.LineItem) {
          endRentalInputCtrl.AppliedCreditList.splice(index, 1);
        }
      });
    }
    this.setState({ endRentalInputCtrl: endRentalInputCtrl }, () => {
      this.resetEndRentalPricing();
    });
  };

  closeConfirmModal = () => {
    this.setState({ showConfirmModal: false });
  };

  callBackToDisplayServerMessage = (response) => {
      this.showErrorMsg(
        response.StatusMsg,
        response.StatusCode === 0 ? TOAST_MSG.SUCCESS : TOAST_MSG.ERROR
      );
  };

  updateCancelStatus = (data) => {
    let breadCrumbList = [];
    let path = "/";
    path =
      "/reservationSummary?reservationId=" +
      this.props.location.state.summaryReservationData.ReservationId;

    breadCrumbList = this.props.location.hasOwnProperty("state")
      ? populateBreadCrumbData(
          this.props.location.state.breadCrumbList,
          "Summary",
          this.props.location.pathname
        )
      : [];

    this.setState({
      breadCrumbList: breadCrumbList,
    });

    this.props.history.push(path, { breadCrumbList: breadCrumbList });
  };
  isLateCBShowable = () => {
    if (
      this.state.endRentalInputCtrl &&
      this.state.endRentalInputCtrl.PromisedEndTime
    ) {
      const serverDate = moment().tz(
        JSON.parse(localStorage.getItem("timeZoneString"))
      );
      const promisedEndTime = moment(
        this.state.endRentalInputCtrl.PromisedEndTime,
        "MM:DD:YYYY:HH:mm"
      );
      let duration = moment
        .duration(serverDate.diff(promisedEndTime))
        .asMilliseconds();
      if (duration > 0) {
        return true;
      }
    }
    return false;
  };
  updaleLateResCB = () => {
    if (!this.state.endRentalInputCtrl.LateChargeableOption) {
      this.launchModal({
        type: ModalType.LATE_CHARGE_CONFIRM_MODAL,
        showModal: true,
      });
    } else {
      const upCtrl = { ...this.state.endRentalInputCtrl };
      upCtrl.LateChargeableOption = null;
      this.setState({ endRentalInputCtrl: upCtrl }, () => {
        this.props.onCalculateEndRentalPricing(upCtrl);
      });
    }
  };
  getLateChargeableOptionConf = (summaryData) => {
    let lateChargeConfig = {};
    if (summaryData.LateChargeableOption === "NONE") {
      lateChargeConfig.lateChargeLbl = "Late (No extra charge)";
      lateChargeConfig.style = { right: "-12rem" };
    } else if (summaryData.LateChargeableOption === "NORMAL") {
      lateChargeConfig.lateChargeLbl = "Late (Normal charge)";
      lateChargeConfig.style = { right: "-12rem" };
    } else if (summaryData.LateChargeableOption === "Late") {
      lateChargeConfig.lateChargeLbl = "Late (Late charge)";
      lateChargeConfig.style = { right: "-12rem" };
    } else {
      lateChargeConfig.lateChargeLbl = "Late (None)";
      lateChargeConfig.style = { right: "-7rem" };
    }
    return lateChargeConfig;
  };
  doLateChargeFunctionality = (type) => {
    const req = { ...this.state.endRentalInputCtrl };
    req.LateChargeableOption = type;
    this.setState({ endRentalInputCtrl: req }, () => {
      this.props.onCalculateEndRentalPricing(createEndrentalChargesReq(req));
    });
  };

  labelCaptionClass = "col-sm-4 col-form-label font-weight-bold";
  renderLabelInputPair = (
    labelCaption,
    controlName,
    value,
    isViewOnly = false,
    nodeElement = "input",
    dropdownItem = null,
    isDateField = false,
    modalParameters = null,
    hasPermissionToOpenModal = false
  ) => {
    const isNumberField = [
      "OdometerIn",
      "OdometerOut",
      "CustomerReportedOdometerIn",
    ].includes(controlName);
    const minValueAndStepAttr = isNumberField ? { step: "1" } : {};
    const linkClass = "pseudo-link ";
    return (
      <LabelAndValuePairControl
        elementConfig={{
          name: controlName,
          type: isNumberField ? "number" : null,
          value: value,
          ...minValueAndStepAttr,
        }}
        otherConfig={{
          caption: labelCaption,
          dropdownItem: nodeElement === "select" ? dropdownItem : [],
          value: value,
          viewOnly: isViewOnly,
          isDateField: isDateField,
          showDateTime: true,
          isWithDateTime: true,
          openModal:
            modalParameters && hasPermissionToOpenModal
              ? this.launchModal
              : null,
          modalParameters: modalParameters ? { ...modalParameters } : null,
        }}
        isEditMode={true}
        onChange={this.inputChangedHandler}
        parentRowClass={`form-group row`}
        labelClasses={this.labelCaptionClass}
        valueClasses={`col-sm-8 col-form-label ${
          modalParameters && hasPermissionToOpenModal ? linkClass : ""
        }`}
        nodeElement={nodeElement}
      />
    );
  };
  renderSlider = (endRentalData, labelCaption, controlName) => {
    return (
      <div className="form-group row mb-4">
        <label className={this.labelCaptionClass}>{labelCaption}</label>
        <div className="col-sm-7" style={{ position: "relative" }}>
          <div className="slider">
            <Slider
              min={0}
              max={100}
              value={endRentalData[controlName] || 0}
              onChange={(event) => this.sliderChange(event, controlName)}
              onChangeComplete={this.sliderChangeComplete}
            />
            <div className="value" />
          </div>
        </div>
        <label className="col-sm-1 col-form-label">
          {endRentalData[controlName] || 0}
        </label>
      </div>
    );
  };

  renderActionByInput = (
    endRentalData,
    labelCaption,
    controlName,
    agentType
  ) => {
    return (
      <div className="form-group row">
        <label
          htmlFor="endRental-IntegArrivedByName"
          className={this.labelCaptionClass}
        >
          {labelCaption}
        </label>
        <div className="col-sm-8">
          <input
            id="endRental-IntegArrivedByName"
            type="text"
            className="form-control pl-4"
            name="IntegArrivedByName"
            value={endRentalData[controlName] || ""}
            onChange={() => {}}
          />
          <i
            className="fas fa-search position-absolute input-search-ico"
            onClick={() =>
              this.launchModal({
                type: ModalType.AGENT_MODAL,
                agentType: agentType,
              })
            }
          />
          {endRentalData[controlName] ? (
            <i
              className="fa fa-times-circle  position-absolute input-search-ico-right"
              onClick={(event) => this.agentRemove(agentType)}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    );
  };

  render() {
    const endRentalInputCtrl = this.state.endRentalInputCtrl;
    const lateResConf = this.getLateChargeableOptionConf(endRentalInputCtrl);
    const commonDateProps = [true, null, null, true];
    return (
      <Fragment>
        <PreventTransitionPrompt when={this.state.isBlocking} />
        {this.props.location.state ? (
          this.props.location.state.breadCrumbList ? (
            <Breadcrumbs
              breadCrumbList={this.props.location.state.breadCrumbList}
              title={"End Rental"}
            />
          ) : (
            ""
          )
        ) : (
          ""
        )}
        <div className="col-lg-12 row p-0 background-edit" id="edit-end-rental">
          <div className="col-lg-5">
            <h4 className="form-header text-uppercase">
              <i className="fas fa-user-circle" />
              Rental#{" "}
              <span style={{ color: "#777", fontWeight: "normal" }}>
                {endRentalInputCtrl.ConfirmationCode}
              </span>
            </h4>
            {this.renderLabelInputPair(
              getMemberLabelByOrgRentalType("Name"),
              "BorrowerName",
              endRentalInputCtrl.BorrowerName,
              true,
              "input",
              null,
              false,
              {
                memberId: endRentalInputCtrl.MemberId,
                type: ModalType.MEMBER_QUICK_MODAL,
              },
              hasPermission(PermissionName.VIEW_MEMBER)
            )}
            {this.renderLabelInputPair(
              "Pickup Date",
              "PromisedStartTime",
              endRentalInputCtrl.PromisedStartTime,
              ...commonDateProps
            )}
            {this.renderLabelInputPair(
              "Pickup Location",
              "PickupLocation",
              endRentalInputCtrl.PickupLocation,
              true
            )}
            {this.renderLabelInputPair(
              "Return Location",
              "ReturnLocationId",
              endRentalInputCtrl.ReturnLocationId,
              false,
              "select",
              getDropdownItems(
                this.props.loggedInUserData.DependantOrgList,
                "Key",
                "Value"
              )
            )}
            {this.renderLabelInputPair(
              "Expected Return Date",
              "PromisedEndTime",
              endRentalInputCtrl.PromisedEndTime,
              ...commonDateProps
            )}
            {this.renderLabelInputPair(
              "Actual Start Date",
              "ActualStartDate",
              endRentalInputCtrl.ActualStartDate,
              ...commonDateProps
            )}
            {this.isLateCBShowable() ? (
              <div
                className="icheck-primary position-absolute"
                style={lateResConf.style}
              >
                <input
                  name="OverridePayment"
                  type="checkbox"
                  id="cbEndRentalLate"
                  checked={
                    endRentalInputCtrl.LateReservation &&
                    endRentalInputCtrl.LateChargeableOption
                      ? true
                      : false
                  }
                  onChange={(event) => this.updaleLateResCB(event)}
                />
                <label htmlFor="cbEndRentalLate">
                  {lateResConf.lateChargeLbl}
                </label>
              </div>
            ) : (
              ""
            )}
            {this.renderLabelInputPair(
              "Actual Return Date",
              "CheckoutDate",
              endRentalInputCtrl.CheckoutDate,
              false,
              "datetime"
            )}

            {this.renderLabelInputPair(
              "External Return Date",
              "CompletedOn",
              endRentalInputCtrl.CompletedOn,
              false,
              "datetime"
            )}
            {this.renderLabelInputPair(
              "Vehicle Info",
              "VehicleName",
              endRentalInputCtrl.VehicleName,
              true,
              "input",
              null,
              false,
              {
                vehicleId: endRentalInputCtrl.MemberAssetId,
                type: ModalType.VEHICLE_QUICK_MODAL,
              },
              hasPermission(PermissionName.VIEW_VEHICLE)
            )}
            {this.renderLabelInputPair(
              "Odometer Out",
              "OdometerOut",
              endRentalInputCtrl.OdometerOut,
              false,
              "input"
            )}
            {this.renderLabelInputPair(
              "Odometer In",
              "OdometerIn",
              endRentalInputCtrl.OdometerIn,
              false,
              "input"
            )}
            {this.renderLabelInputPair(
              "Customer Reported Odometer In",
              "CustomerReportedOdometerIn",
              endRentalInputCtrl.CustomerReportedOdometerIn,
              false,
              "input"
            )}
            {this.renderSlider(endRentalInputCtrl, "Fuel Out", "FuelOut")}
            {this.renderSlider(endRentalInputCtrl, "Fuel In", "FuelIn")}
            {this.renderSlider(
              endRentalInputCtrl,
              "Customer Reported Fuel In",
              "CustomerReportedFuelIn"
            )}
            {this.renderActionByInput(
              endRentalInputCtrl,
              "Adjusted By",
              "IntegAdjustedByName",
              "IntegAdjustedBy"
            )}
            {this.renderActionByInput(
              endRentalInputCtrl,
              "Arrived By",
              "IntegArrivedByName",
              "IntegArrivedBy"
            )}
            {this.renderActionByInput(
              endRentalInputCtrl,
              "Completed By",
              "IntegCompletedByName",
              "IntegCompletedBy"
            )}
            {this.renderLabelInputPair(
              getMemberLabelByOrgRentalType("Notes"),
              "Notes",
              endRentalInputCtrl.Notes,
              false,
              "textarea"
            )}
            {this.renderLabelInputPair(
              "Internal Notes",
              "CCANotes",
              endRentalInputCtrl.CCANotes,
              false,
              "textarea"
            )}
            <div className="row form-group">
              <label
                htmlFor="endRental-PickupLocationId"
                className={this.labelCaptionClass}
              >
                Damage Photo
              </label>
              <div className="col-sm-8">
                <img
                  style={{ width: "12rem", height: "10rem" }}
                  src={endRentalInputCtrl.PrimaryImageUrl || noImage}
                  onError={(event) => loadDefaultImage(event, noImage)}
                  alt="Damage Item"
                />
                <br />
                <label
                  className="text-primary font-weight-bold cursor-pointer"
                  onClick={() =>
                    this.launchModal({
                      type: ModalType.DAMAGE_MODAL,
                    })
                  }
                >
                  <i className="fas fa-search" />
                  Search Damage
                </label>
              </div>
            </div>
          </div>
          <div className="col-lg-1">&nbsp;</div>
          <div className="col-lg-6">
            <h4 className="form-header text-uppercase">
              <i className="fas fa-user-circle" />
              Charge Table
            </h4>

            <PricingTable
              isPaddingRequired={true}
              upsellOptions={
                this.state.addonsData ? this.state.addonsData.UpsellOptions : []
              }
              pricingData={this.state.endRentalPricingData}
              submitMiscellaneousChargesCreditsHandler={
                this.submitMiscellaneousChargesCreditsHandler
              }
              isChregesCreditInputRequired={true}
              isDiscountInputRequired={false}
              removeChargeLineItem={this.removeChargeLineItem}
            />

            <h4 className="form-header text-uppercase">
              <i className="fas fa-user-circle" />
              Payment
            </h4>
            {this.state.paymentReasonTypes.length > 0 ? (
              <Payment
                creditCardInfo={
                  this.state.memberInputReqest
                    ? this.state.memberInputReqest.CreditCard
                    : ""
                }
                address={
                  this.state.memberInputReqest
                    ? this.state.memberInputReqest.PrimaryAddress
                    : ""
                }
                paymentReasonTypes={this.state.paymentReasonTypes}
                paymentInputRequest={this.state.paymentInputRequest}
                inputAddressChangeHandler={this.inputAddressChangeHandler}
                inputPaymentChangeHandler={this.inputPaymentChangeHandler}
                launchModal={this.launchModal}
                agentRemove={this.agentRemove}
                doPayment={this.initPayment}
                resetLoader={this.resetLoader}
                pageName={getPageName(this.props.match.url)}
              />
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="row col-lg-12 p-0">
          <hr className="col-lg-12" />
          <div className="col-lg-5 text-left">
            <OverrideComponent
              overrideItems={getOverrideInputControls(
                endRentalInputCtrl,
                this.inputChangedHandler,
                ADMIN_PAGE.END_RENTAL_PAGE
              )}
            />
          </div>
          <div className="col-lg-6 text-left p-0">
            <Button
              color="primary"
              type="button"
              className="mr-1"
              onClick={() =>
                this.launchModal({
                  type: ModalType.CANCEL_RESERVATION_MODAL,
                })
              }
            >
              Cancel Rental
            </Button>

            <Button
              color="primary"
              type="button"
              className="ml-1"
              onClick={(event) => this.doEndRental()}
            >
              {this.state.endRentalInputCtrl &&
              this.state.endRentalInputCtrl.ReservationState &&
              this.state.endRentalInputCtrl.ReservationState === "COMPLETED"
                ? "Save"
                : "End Rental"}
            </Button>
          </div>
        </div>

        {this.state.showConfirmModal ? (
          <ConfirmModal
            showModal={this.state.showConfirmModal}
            closeModal={this.closeConfirmModal}
            doConfirmFunctionality={this.makePayment}
          />
        ) : (
          ""
        )}
        {this.state.modalInfo.showModal ? (
          this.state.modalInfo.agentType ? (
            <MemberModalEngine
              modalType={this.state.modalInfo.type}
              showModal={this.state.modalInfo.showModal}
              closeModal={this.closeModal}
              agentResponse={this.state.agentResponse}
              getAgentList={this.getAgentList}
              isSearchedAgentPerformed={this.state.isSearchedAgentPerformed}
              agentType={this.state.modalInfo.agentType}
              onAgentSelected={this.onAgentSelected}
              dependantOrgList={this.props.loggedInUserData.DependantOrgList}
            />
          ) : (
            <UpdateReservationModalEngine
              modalType={this.state.modalInfo.type}
              showModal={this.state.modalInfo.showModal}
              closeModal={this.closeModal}
              resetParentPage={this.resetEndRentalPricing}
              vehicleId={this.state.modalInfo.vehicleId}
              memberId={this.state.modalInfo.memberId}
              defaultData={getReservationModalDefaultInfo(
                this.state.modalInfo.type,
                this.state.reservationSummaryData
              )}
              doLateChargeFunctionality={this.doLateChargeFunctionality}
            />
          )
        ) : (
          ""
        )}
        {this.props.loading ? <Spinner /> : ""}
      </Fragment>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    onGetReservationCharges: (reservationChargesReq) =>
      dispatch(actions.getReservationCharges(reservationChargesReq)),
    onGetMember: (memberReq) => {
      dispatch(actions.getMember(memberReq));
    },
    onGetMemberReset: () => dispatch(actions.getMemberReset()),
    onGetReservationChargesReset: () =>
      dispatch(actions.getReservationChargesReset()),
    onGetEnumvalues: () => dispatch(actions.getEnumvalues()),
    onCalculateEndRentalPricing: (endRentalReq) => {
      dispatch(actions.calculateEndRentalPricing(endRentalReq));
    },
    onGetEndRentalChargesReset: () => {
      dispatch(actions.getEndRentalChargesReset());
    },
    onEndRental: (editEndRentalReq) => {
      dispatch(actions.endRental(editEndRentalReq));
    },
    onEditEndRental: (editEndRentalReq) => {
      dispatch(actions.editEndRental(editEndRentalReq));
    },
    onEditEndRentalReset: (editEndRentalReq) => {
      dispatch(actions.editEndRentalReset());
    },
    onEndRentalReset: () => {
      dispatch(actions.endRentalReset());
    },
    onManageReservation: (manageReservationReq) => {
      dispatch(actions.manageReservation(manageReservationReq));
    },
    onManageReservationChargesReset: () => {
      dispatch(actions.manageReservationChargesReset());
    },
    onGetAdditionalOptions: (selectedOrgUnitId) => {
      dispatch(actions.getAdditionalOptions(selectedOrgUnitId));
    },
    onGetAgentList: (getAgentListReq) => {
      dispatch(actions.getAgentList(getAgentListReq));
    },
    onGetAgentReset: () => {
      dispatch(actions.getAgentReset());
    },
  };
};
const mapStateToProps = (state) => {
  return {
    loggedInUserData: state.auth.loggedInUserData,
    paymentReasonTypes: state.enumvaluesReducer.paymentReasonTypes,
    reservationCancellationReasons:
      state.enumvaluesReducer.reservationCancellationReasons,
    memberData: state.memberReducer.memberData,
    endRentalPricingData: state.endRentalReducer.endRentalPricingData,
    endRentalResponse: state.endRentalReducer.endRentalResponse,
    editEndRentalResponse: state.endRentalReducer.editEndRentalResponse,
    manageReservationResponse:
      state.reservationChargesReducer.manageReservationResponse,
    addonsData: state.addonsReducer.addonsData,
    agentResponse: state.agentReducer.agentResponse,
    loading:
      // state.countryStateCityZipReducer.loading ||
      state.agentReducer.loading ||
      state.damageReducer.loading ||
      state.enumvaluesReducer.loading ||
      state.memberReducer.loading ||
      state.endRentalReducer.loading ||
      state.addonsReducer.loading ||
      state.reservationChargesReducer.loading,
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(EndRental));

import { TooltipCtrlModel } from "./tooltip-ctrl-model";

export const IconCtrlModel = function (data) {
    if (!data) data = {};
    this.id = data.id || "";
    this.type = data.type || "";
    this.styles = data.styles || "";
    this.classNames = data.classNames || null;
    this.iconValue= data.iconValue || null;
    this.modalParams=data.modalParams || null;
    this.tooltipData= new TooltipCtrlModel(data.tooltipData);
    this.visibility= data.visibility;
    this.disabled=data.disabled;
};
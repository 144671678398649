import * as actionTypes from "../actions/actionTypes";
import { reducerUpdateObject } from "./reducer.utility";

const initialState = {
  membersRolesData: null,
  permissionsRolesData: null,
  permissionsRolesUpdateResponse: null,
  assignRolesToMemberResponse: null,
  loading: false,
};
const getRolestoMembersStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
const getRolestoMembersSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    membersRolesData: action.membersRolesData,
    error: null,
    loading: false,
  });
};
const getRolestoMembersFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false,
  });
};

const getPermissionstoRolesStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
const getPermissionstoRolesSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    permissionsRolesData: action.permissionsRolesData,
    error: null,
    loading: false,
  });
};
const getPermissionstoRolesFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false,
  });
};

const updatePermissionstoRolesStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
const updatePermissionstoRolesSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    permissionsRolesUpdateResponse: action.permissionsRolesUpdateResponse,
    error: null,
    loading: false,
  });
};
const updatePermissionstoRolesFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false,
  });
};

const updatePermissionstoRolesReset = (state) => {
  return reducerUpdateObject(state, {
    permissionsRolesUpdateResponse: null,
  });
};

export const assignRolesToMemberStart = (state) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
export const assignRolesToMemberSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    assignRolesToMemberResponse: action.assignRolesToMemberResponse,
    error: null,
    loading: false,
  });
};
const assignRolesToMemberFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false,
  });
};
const assignRolesToMemberReset = (state) => {
  return reducerUpdateObject(state, {
    assignRolesToMemberResponse: null,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_MEMBER_ROLES_START:
      return getRolestoMembersStart(state, action);
    case actionTypes.GET_MEMBER_ROlES_SUCCESS:
      return getRolestoMembersSuccess(state, action);
    case actionTypes.GET_MEMBER_ROLES_FAIL:
      return getRolestoMembersFail(state, action);

    case actionTypes.GET_PERMISSIONS_ROLES_START:
      return getPermissionstoRolesStart(state, action);
    case actionTypes.GET_PERMISSIONS_ROLES_SUCCESS:
      return getPermissionstoRolesSuccess(state, action);
    case actionTypes.GET_PERMISSIONS_ROLES_FAIL:
      return getPermissionstoRolesFail(state, action);
    case actionTypes.GET_PERMISSIONS_ROLES_RESET:
      return {
        ...initialState,
      };

    case actionTypes.UPDATE_PERMISSIONS_ROLES_START:
      return updatePermissionstoRolesStart(state, action);
    case actionTypes.UPDATE_PERMISSIONS_ROLES_SUCCESS:
      return updatePermissionstoRolesSuccess(state, action);
    case actionTypes.UPDATE_PERMISSIONS_ROLES_FAIL:
      return updatePermissionstoRolesFail(state, action);
    case actionTypes.UPDATE_PERMISSIONS_ROLES_RESET:
      return updatePermissionstoRolesReset(state);

    case actionTypes.ASSIGN_ROLES_TO_MEMBER_START:
      return assignRolesToMemberStart(state);
    case actionTypes.ASSIGN_ROLES_TO_MEMBER_SUCCESS:
      return assignRolesToMemberSuccess(state, action);
    case actionTypes.ASSIGN_ROLES_TO_MEMBER_FAIL:
      return assignRolesToMemberFail(state, action);
    case actionTypes.ASSIGN_ROLES_TO_MEMBER_RESET:
      return assignRolesToMemberReset(state);

    default:
      return state;
  }
};
export default reducer;

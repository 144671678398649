import React, { useState, useEffect, Fragment } from "react";
import moment from "moment";
import { createTooltip } from "../../shared/utility/tooltip-utility";
import { camelizeKeys, getCurrentServerTimezone } from "../../shared/utility";
import { convertDateCtrlValueToFoxAPIOnlyDate, createSearchRequest } from "../../containers/search/search-utility/search-utility";
import { ADMIN_PAGE, TOAST_MSG } from "../../shared/GlobalVar";
import { showNotification } from "../UI/Toaster/Toaster";
import { AdminDateFromat } from "../../shared/utility/enum-utility";
export const PeriodicFilter = props => {
    const {
        startDate,
        endDate,
        totalRecords,
        inputCtrl,
        periodicFilterInfo,
        setUpdateCtrls,
        getSearchList,
        getSearchListFromSearchReq,
        setPreiodicFilterInfo,
    } = props;
    const timeZone = getCurrentServerTimezone();
    const currentStartDate = timeZone ? moment().tz(timeZone) : moment().tz(moment.tz.guess());
    const currentEndDate = timeZone ? moment().tz(timeZone).add(13, "days") : moment().tz(moment.tz.guess()).add(13, "days");
    // const [periodStartDate, setPeriodStartDate] = useState(startDate ? moment(startDate, "MM:DD:YYYY:HH:mm") : currentStartDate);
    // const [periodEndDate, setPeriodEndDate] = useState(endDate ? moment(endDate, "MM:DD:YYYY:HH:mm") : currentEndDate);

    useEffect(() => {

    }, []);


    const getPeriodName = () => {
        const pStartDate = startDate ? typeof startDate === 'object' ? { ...startDate } : moment(startDate, "MM:DD:YYYY:HH:mm") : { ...currentStartDate };
        const pEndDate = pStartDate ? moment(pStartDate).add(periodicFilterInfo.recordsPerPeriod || 13, "days") : { ...currentEndDate };
        return moment(pStartDate).format("DD MMM,YY") + " - " + moment(pEndDate).format("DD MMM,YY")
    }
    const getPrevBtnClassName = (isPrevBtn) => {
        const pStartDate = startDate ? typeof startDate === 'object' ? { ...startDate } : moment(startDate, "MM:DD:YYYY:HH:mm") : { ...currentStartDate };
        if (pStartDate && moment(pStartDate).format(AdminDateFromat.FOX_API_ONLY_DATE) === moment(currentStartDate).format(AdminDateFromat.FOX_API_ONLY_DATE)) {
            return isPrevBtn ? "periodic-prev-next disabled" : "preiodic-button disabled";
        }
        return isPrevBtn ? "periodic-prev-next" : "preiodic-button";
    }
    const isPrvBtnDisabled = (type) => {
        const pStartDate = startDate ? typeof startDate === 'object' ? { ...startDate } : moment(startDate, "MM:DD:YYYY:HH:mm") : { ...currentStartDate };
        if (pStartDate && moment(pStartDate).format(AdminDateFromat.FOX_API_ONLY_DATE) === moment(currentStartDate).format(AdminDateFromat.FOX_API_ONLY_DATE)) {
            return true;
        }
        return false
    }
    const doSearchFromPeriodicFilter = (pInputCtrl) => {
        const searcRequestObj = createSearchRequest(
            pInputCtrl,
            ADMIN_PAGE.RESERVE_SYNC_PAGE,
            false,
            null
        );

        if (!searcRequestObj.locationId) {
            showNotification({
                msg: "Please select a location !!!",
                toastType: TOAST_MSG.WARNING,
            });
            return false;
        }
        searcRequestObj["pagination"] = camelizeKeys(searcRequestObj.PageInfo);
        delete searcRequestObj["PageInfo"];
        delete searcRequestObj["pageInfo"];
        delete searcRequestObj["searchViewType"];
        delete searcRequestObj["periodicFilterInfo"];
        searcRequestObj.pagination.pageNumber = 0;
        searcRequestObj.pagination.perPageCount = 1000;
        if (searcRequestObj.pause === "false") {
            searcRequestObj.pause = false;
        } else if (searcRequestObj.pause === "true") {
            searcRequestObj.pause = true;
        }
        getSearchListFromSearchReq(searcRequestObj, true);
    }
    const onPrevNextClick = (isPrev) => {
        const pInputCtrl = { ...inputCtrl };
        const datePrev = startDate ? typeof startDate === 'object' ? { ...startDate } : moment(startDate, "MM:DD:YYYY:HH:mm") : { ...currentStartDate };
        const dateNext =endDate ? typeof endDate === 'object' ? { ...endDate } : moment(endDate, "MM:DD:YYYY:HH:mm") : { ...currentEndDate };
        pInputCtrl.forecastStartDate.value = moment(datePrev).add(isPrev && !isPrvBtnDisabled() ? -(inputCtrl.periodicFilterInfo.recordsPerPeriod || 15) : inputCtrl.periodicFilterInfo.recordsPerPeriod || 15, "days");
        pInputCtrl.forecastEndDate.value = moment(dateNext).add(isPrev && !isPrvBtnDisabled() ? -(inputCtrl.periodicFilterInfo.recordsPerPeriod || 15) : inputCtrl.periodicFilterInfo.recordsPerPeriod || 15, "days");
        doSearchFromPeriodicFilter(pInputCtrl, true);
        setUpdateCtrls(pInputCtrl);
    }
    const onTodayClick = () => {
        const pInputCtrl = { ...inputCtrl };
        const datePrev = { ...currentStartDate };
        const dateNext = { ...currentEndDate };
        pInputCtrl.forecastStartDate.value = moment(datePrev);
        pInputCtrl.forecastEndDate.value = moment(dateNext).add(inputCtrl.periodicFilterInfo.recordsPerPeriod, "days");
        doSearchFromPeriodicFilter(pInputCtrl, true);
        setUpdateCtrls(pInputCtrl);
    }
    const handleClick = (type, evt) => {
        const pInputCtrl = { ...inputCtrl };
        if (type === "SHORT_TYPE") {
            pInputCtrl.periodicFilterInfo.sortType = evt.target.value;
        } else if (type === "RECORD_PER_PERIOD") {
            pInputCtrl.periodicFilterInfo.recordsPerPeriod = parseInt(evt.target.value);
            const datePrev = { ...currentStartDate };
            const dateNext = { ...currentEndDate };
            pInputCtrl.forecastStartDate.value = moment(datePrev);
            pInputCtrl.forecastEndDate.value = moment(dateNext).add(pInputCtrl.periodicFilterInfo.recordsPerPeriod, "days");
            doSearchFromPeriodicFilter(pInputCtrl, true);
        }
        setUpdateCtrls(pInputCtrl);
    };
    const createPeriodFilter = () => {
        return (
            <div className="periodic-filter">
                <div className="sorting-period">
                    <span>Sortt Type</span>
                    <select
                        className="select"
                        onChange={e => handleClick("SHORT_TYPE", e)}
                        value={inputCtrl.periodicFilterInfo.sortType || "ASC"}
                    >
                        <option value="ASC">ASC</option>
                        <option value="DESC">DESC</option>
                    </select>
                </div>
                <div className="period-control">
                    <button className={`${getPrevBtnClassName(false)}`} onClick={isPrvBtnDisabled() ? "" : e => onTodayClick()} disabled={isPrvBtnDisabled()}>Today</button>
                    <button className={`${getPrevBtnClassName(true)}`} disabled={isPrvBtnDisabled()}
                        onClick={isPrvBtnDisabled() ? "" : e => onPrevNextClick(true)}>
                        <i class="fa fa-angle-left" aria-hidden="true"></i>
                    </button>
                    <button className="periodic-prev-next" onClick={e => onPrevNextClick(false)}>
                        <i class="fa fa-angle-right" aria-hidden="true"></i>
                    </button>
                    <span>{getPeriodName()}</span>
                </div>
                <div className="record-period">
                    <span>Display</span>
                    <select
                        className="select"
                        id="input-6"
                        name="intersted"
                        required=""
                        aria-invalid="false"
                        onChange={e => handleClick("RECORD_PER_PERIOD", e)}
                        value={inputCtrl.periodicFilterInfo.recordsPerPeriod || 13}
                    >
                        <option value="15">15</option>
                        <option value="30">30</option>
                        <option value="13">13</option>
                    </select>
                    <span>Per Period</span>
                </div>
            </div>
        );
    };
    return (createPeriodFilter());
};
export default PeriodicFilter;
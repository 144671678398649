import React, { useRef, useState } from "react";
import MessageBox from "../../../../components/common/modal/message-box";
import { MessageBoxType } from "../../../../shared/GlobalVar";
import { getSecurityDepositPolicy } from "../../../../shared/json/data";
import { formatCurrency } from "../../../../shared/utility";
const SecurityDeposit = (props) => {
  const {
    isError,
    securityDepositRate,
    confirmSecurityDepositRate,
    setConfirmSecurityDepositRate
  } = props;
  const iconRef = useRef(null);
  const [showModal, setShowModal] = useState(false);
  const closeModal = () => {
    setShowModal(false);
  }
  const openModal = (messageBoxType) => {
    setShowModal(true);
  };

  const renderMessageBox = () =>
    showModal && (<MessageBox
      id="security-deposit-policy-message-box"
      title={"Security Deposit Policy"}
      message={getSecurityDepositPolicy()}
      onClose={closeModal}
      iconRef={iconRef}
      messageBoxType={MessageBoxType.SECURITY_POLICY}
    />)

  if (!securityDepositRate) {
    setConfirmSecurityDepositRate(false)
  }
  return (
    <div className="res-security-block">
      <div className="sec-title" ref={iconRef}>
        <label className="common-title">Security Deposit Charges</label>
        <i
          className="fa fa-info-circle ml-2"
          aria-hidden="true"
          onClick={() => {
            securityDepositRate && openModal(MessageBoxType.SECURITY_POLICY);
          }}
          title={
            securityDepositRate
              ? "Click to view Security Deposit Policy"
              : "No Policy Available"
          }
        ></i>
        {renderMessageBox()}
      </div>
      {isError && !confirmSecurityDepositRate ? (
        <div className="error-block">
          <i class="fas fa-exclamation-triangle"></i>
          <div className="error-msg-block">
            <label className="error-title">Required Fields!</label>
            <label className="error-msg">
              {securityDepositRate
                ? "Please confirm you shared this information with customer."
                : "Please enter a valid location, country, and Zip/Postal Code for security deposit charges."}
            </label>
          </div>
        </div>
      ) : (
        ""
      )}
      {confirmSecurityDepositRate !== undefined ? (
        <div className="icheck-primary security-check">
          <input
            name="confirmSecurityDepositRate"
            type="checkbox"
            id="confirmSecurityDepositRate"
            checked={confirmSecurityDepositRate}
            onChange={(event) =>
              securityDepositRate &&
              setConfirmSecurityDepositRate(event.target.checked)
            }
            disabled={!securityDepositRate}
            title={
              !securityDepositRate
                ? "Security deposit charges unavailable."
                : ""
            }
          />
          <label htmlFor={"confirmSecurityDepositRate"}>
            I’ve shared security deposit amount with customer.
          </label>
        </div>
      ) : (
        ""
      )}
      <div className="charge-block">
        <label>Charges</label>
        <label>
          {securityDepositRate ? formatCurrency(securityDepositRate) : "--"}
        </label>
      </div>
    </div>
  );
};
export default SecurityDeposit;
export const ShuttleLocationSettingsModel = function (data) {
    if (!data) data = {};
    this.inheritFromParent = data.inheritFromParent || false;
    this.statusUpdateIntervalInMillis = data.statusUpdateIntervalInMillis || null;
    this.keepAliveIntervalInMillis = data.keepAliveIntervalInMillis || null;
    this.fetchIntervalInMillis = data.fetchIntervalInMillis || null;
    this.vicinityRadiusInMiles = data.vicinityRadiusInMiles || null;
    this.displayVicinityRadius = data.displayVicinityRadius || null;
    this.sensitivity = data.sensitivity || null;
    this.speedThreesholdInMph = data.speedThreesholdInMph || null;
    this.displaySpeedThreeshold = data.displaySpeedThreeshold || null;
    this.distanceThreesholdInMiles = data.distanceThreesholdInMiles || null;
    this.displayDistanceThreeshold = data.displayDistanceThreeshold || null;
    this.lowAvgSpeedInMph = data.lowAvgSpeedInMph || null;
    this.displayLowAvgSpeed = data.displayLowAvgSpeed || null;
    this.highAvgSpeedInMph = data.highAvgSpeedInMph || null;
    this.displayHighAvgSpeed = data.displayHighAvgSpeed || null;
    this.highMaxSpeedInMph = data.highMaxSpeedInMph || null;
    this.displayHighMaxSpeed = data.displayHighMaxSpeed || null;
    this.lowTravelledDistanceInMiles = data.lowTravelledDistanceInMiles || null;
    this.displayLowTravelledDistance = data.displayLowTravelledDistance || null;
    this.highTravelledDistanceInMiles = data.highTravelledDistanceInMiles || null;
    this.displayHighTravelledDistance = data.displayHighTravelledDistance || null;
    this.highTripDurationInMillis = data.highTripDurationInMillis || null;
    this.locationDistanceUnit = data.locationDistanceUnit || null;
    this.locationSpeedUnit = data.locationSpeedUnit || null;
  };
  
import React, { useState } from 'react';
import { useSelector } from "react-redux";
import { getCompatibleDdlOrg } from '../../../../containers/Reservation/fox-reservation/fox-res-helper';
import { getLocationQuickModal } from '../../../modal/modern-modal-data';
import ModernModalEngine from '../../../modal/modern-modal-engine';
import InputWidgetCtrl from '../input-widget-ctrl';

function LocationCtrl(props) {
    const [modalData, setModalData] = useState("");
    const { name, value, placeholder, disabled, imgIco, onChange } = props;
    const dependantOrgList = useSelector((state) =>
        state.auth.loggedInUserData.DependantLocations.length > 0
            ? state.auth.loggedInUserData.DependantLocations
            : []
    );
    const orgs = getCompatibleDdlOrg(dependantOrgList);
    const getLocationId = () => {
        return orgs.find((data) => data.keyValue === value)?.locationId;
    }
    return (
        <div className='loc-ctrl'>
            <InputWidgetCtrl
                id={name}
                name={name}
                placeholder={placeholder}
                data={orgs}
                valueField="keyValue"
                textField="displayValue"
                value={value}
                onChange={(event) =>
                    onChange ? onChange(event) : ""
                }
                disabled={disabled}
                imgIco={imgIco}
            />
            <i className={`fas fa-info-circle ${!value ? "font-color-disabled" : "font-color-1"} font-size-2`} aria-hidden="true"
                onClick={() => { getLocationQuickModal({ locationId: getLocationId() }, setModalData) }}
            />
            {modalData ?
                <ModernModalEngine
                    {...modalData}
                />
                : ""}
        </div>
    )
}
export default LocationCtrl
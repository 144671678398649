import React from 'react';
import FoxReservationDefaultPage from '../../../../components/common/pages/fox-res-default-page';
import { CAR_01 } from '../../../../shared/AppImages';
import CommonTitle from '../../component/common-title';
import "./res-vehicle.scss";
import VehicleRatesContainer from './vehicle-rates-container';
function VehicleContainer(props) {
    const { allRentalOffers, selectedVehicleClass, currentPayType, loadAddons, dbReservationData, onChangesMade, setDialogData, disabled } = props;
    const onPaymentBtnClick = (vehicleClass, pricing) => {
        onChangesMade(true);
        loadAddons(vehicleClass, pricing?.rateId, pricing.type, null, pricing?.addOns || []);
    }
    const index = allRentalOffers.findIndex((data) => {
        return data?.vehicleClass[0]?.classCode === selectedVehicleClass?.classCode;
    });
    if (selectedVehicleClass && allRentalOffers.length > 0 && index) {
        allRentalOffers.unshift(allRentalOffers.splice(index, 1)[0]);
    }
    const getNreRates = () => {
        //if (selectedVehicleClass?.classCode === dbReservationData?.vehicleClass?.classCode) {
        const neewRates = allRentalOffers.find((data) => {
            return data?.vehicleClass[0]?.classCode === selectedVehicleClass?.classCode;
        });
        return neewRates?.rateProduct?.length > 0 ? neewRates?.rateProduct[0]?.pricing : [];
        //  }
        // return [];
    }
    return (
        <div className="res-container-column ">
            <CommonTitle title={allRentalOffers.length > 0
                ? "Vehicles (" + allRentalOffers.length + ")"
                : "Vehicles"} classNames="font-size-2 font-weight-600" />
            <div className="res-main-container overflow-auto">
                {dbReservationData ?
                    <VehicleRatesContainer
                        key="db-reservation-vehicle-class"
                        vehicleClass={dbReservationData?.vehicleClass}
                        isVehicleClassSelected={selectedVehicleClass?.classCode === dbReservationData?.vehicleClass?.classCode && true}
                        currentPayType={currentPayType}
                        dbRates={dbReservationData?.pricing || []}
                        pricingList={getNreRates()}
                        onPaymentBtnClick={onPaymentBtnClick}
                        setDialogData={setDialogData}
                        allowAdminEdit={dbReservationData?.allowAdminEdit || null}
                    />
                    : null}
                {allRentalOffers?.length > 0 ?
                    allRentalOffers.map((element, i) => (
                        <VehicleRatesContainer
                            key={element?.vehicleClass?.length > 0 ? element.vehicleClass[0]?.classCode : i}
                            vehicleClass={element?.vehicleClass?.length > 0 ? element?.vehicleClass[0] : null}
                            isVehicleClassSelected={element?.vehicleClass?.length &&
                                selectedVehicleClass?.classCode === element?.vehicleClass[0]?.classCode && true}
                            selectedVehicleClass={selectedVehicleClass}
                            currentPayType={currentPayType}
                            pricingList={element?.rateProduct?.length > 0 ? element?.rateProduct[0]?.pricing : []}
                            disabled={disabled}
                            //disablePayNow={dbReservationData && true}
                            onPaymentBtnClick={onPaymentBtnClick}
                            isOldRate={
                                dbReservationData && dbReservationData?.vehicleClass?.classCode === element?.vehicleClass[0]?.classCode}
                            setDialogData={setDialogData}
                            allowAdminEdit={dbReservationData?.allowAdminEdit || null}
                        />
                    ))
                    : !dbReservationData?.vehicleClass ?
                        <FoxReservationDefaultPage
                            iconContent={CAR_01}
                            title="No Vehicle Found"
                            msg="Please select a location & get rates"
                        /> : ""
                }
            </div>
        </div>
    )
}

export default VehicleContainer
import Address from "./Address";
const CreditCard = function (data) {
  if (!data) data = {};
  this.BillingName = data.BillingName || null;
  this.CVVCode = data.CVVCode || null;
  this.CardExpirationMonth = data.CardExpirationMonth || null;
  this.CardExpirationYear = data.CardExpirationYear || null;
  this.CardNumber = data.CardNumber || null;
  this.CreditCardImageUploaded = data.CreditCardImageUploaded || null;
  this.CreditCardType = data.CreditCardType || null;
  this.MaskedCVVCode = data.MaskedCVVCode || null;
  this.PaymentMethod = data.PaymentMethod || null;
  this.Address = new Address(data.Address, true);
  this.CreditCardScannedData = data.CreditCardScannedData || null;
};
export default CreditCard;

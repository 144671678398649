import axios from "axios";
import * as actionTypes from "./actionTypes";
import HttpService from "../../services/httpService";

export const getAttachedDocListStart = () => {
  return {
    type: actionTypes.GET_ATTACHED_DOC_START,
  };
};
export const getAttachedDocListSuccess = (attachedDocListResponse) => {
  return {
    type: actionTypes.GET_ATTACHED_DOC_SUCCESS,
    attachedDocListResponse: attachedDocListResponse,
  };
};
export const getAttachedDocListFail = (error) => {
  return {
    type: actionTypes.GET_ATTACHED_DOC_FAIL,
    error: error,
  };
};
export const getAttachedDocListReset = (error) => {
  return {
    type: actionTypes.GET_ATTACHED_DOC_RESET,
  };
};
export const getAttachedDocList = (getUploadedDocListReq) => {
  return (dispatch) => {
    dispatch(getAttachedDocListStart());
    HttpService.getUploadedDocInfoList(getUploadedDocListReq)
      .then((response) => {
        dispatch(getAttachedDocListSuccess(response));
      })
      .catch((err) => {
        //dispatch(getAttachedDocListFail(err));
      });
  };
};

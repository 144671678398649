import React, { Component, Fragment } from "react";
import { Button } from "reactstrap";
import AddressComponent from "../common/address-component/address-component";
import {
  getExpiaryMonths,
  getExpiaryYears,
  isNullOrEmpty,
} from "../../shared/utility";
import "../pricing/pricing.scss";
import { ADMIN_PAGE, ModalType } from "../../shared/GlobalVar";
class Payment extends Component {
  state = {
    isRefundChecked: false,
    isPreAuthoRizationChecked: false,
    isSameAsProfileNameAndAddress: false,
    paymentMethod: "CREDIT_CARD",
    orgUnitCountry: localStorage.getItem("orgUnitCountry")
      ? JSON.parse(localStorage.getItem("orgUnitCountry")).Key
      : "",
    paymentRequestInfo: null,
    countryId: null,
    expiartionMonths: getExpiaryMonths(),
    expiartionYear: [],
    paymentDisabled: true,
    isEndRental:
      this.props.pageName === ADMIN_PAGE.END_RENTAL_PAGE ? true : false,
  };
  componentDidMount = () => {
    this.setState({
      expiartionYear: getExpiaryYears(
        this.props.paymentInputRequest
          ? this.props.paymentInputRequest.CardExpirationYear
          : ""
      ),
      paymentDisabled: !this.state.isEndRental,
    });
  };

  inputChangedHandler = (event, controlName, elementType) => {
    let value = event.target.value;
    if (controlName === "PrePaid") {
      this.setState({ paymentDisabled: !event.target.checked }, () => {
        // this.props.inputPaymentChangeHandler(event, controlName, elementType);
        // document
        //   .getElementById("main-content-wrapper1")
        //   .scrollTo(0, document.body.scrollHeight + 500);
      });
      this.props.inputPaymentChangeHandler(event, controlName, elementType);
    }
    if (controlName === "PayLater") {
      this.setState({ paymentDisabled: true }, () => {
        //this.props.inputPaymentChangeHandler(event, controlName, elementType);
        // document
        //   .getElementById("main-content-wrapper1")
        //   .scrollTo(0, document.body.scrollHeight + 1000);
      });
      this.props.inputPaymentChangeHandler(event, controlName, elementType);
    }
    if (controlName === "REFUND") {
      this.setState(
        {
          isRefundChecked: event.target.checked,
          isPreAuthoRizationChecked: false,
          paymentMethod: event.target.checked ? "" : "CREDIT_CARD",
        },
        () => {
          this.props.inputPaymentChangeHandler(
            value,
            "TransactionType",
            "REFUND"
          );
        }
      );
    } else if (controlName === "PRE_AUTHORIZATION") {
      this.setState(
        {
          isRefundChecked: false,
          isPreAuthoRizationChecked: event.target.checked,
          paymentMethod:
            this.state.paymentMethod === ""
              ? "CREDIT_CARD"
              : this.state.paymentMethod,
        },
        () => {
          this.props.inputPaymentChangeHandler(
            value,
            "TransactionType",
            "PRE_AUTHORIZATION"
          );
        }
      );
    }
    if (controlName === "PaymentMethod") {
      this.setState(
        {
          paymentMethod: value,
        },
        () => {
          this.props.inputPaymentChangeHandler(value, "PaymentMethod", "");
        }
      );
    }
  };
  getPaymentBlockClassNames = () => {
    const paymentInputRequest = this.props.paymentInputRequest;
    if (this.props.executionType !== "PAYMENT_INFO") {
      return "col-lg-12";
    } else if (
      paymentInputRequest.IsPaylater === "undefined" ||
      paymentInputRequest.IsPaylater === null ||
      paymentInputRequest.IsPaylater === false
    ) {
      return "col-lg-12 display-hidden";
    } else {
      return "col-lg-12";
    }
  };
  getValueClasses = (controlName) => {
    let valueClasses =
      controlName === "AccountNumber"
        ? "col-sm-5"
        : controlName === "CVVCode"
        ? "col-sm-3"
        : "col-sm-8";
    let values = this.props.paymentInputRequest[controlName] || null;

    let faliedValidationClass = !isNullOrEmpty(values)
      ? ""
      : this.props.isValidateDone
      ? "validation-failed"
      : "";
    return faliedValidationClass
      ? valueClasses + " " + faliedValidationClass
      : valueClasses;
  };
  render() {
    const paymentInputRequest = this.props.paymentInputRequest;
    return (
      <Fragment>
        {!this.state.isEndRental ? (
          <div className="col-lg-12">
            <h4 className="form-header text-uppercase res-pay-header-height">
              <div className="col-lg-6 p-0">This Reservation is:</div>
              <div
                className="col-lg-12"
                style={{ position: "absolute", top: "0", left: "16%" }}
              >
                <div className="icheck-primary hbCheckBox">
                  <input
                    name="PayLaterPayNow"
                    type="radio"
                    id="payment-PrePaid"
                    checked={
                      paymentInputRequest.IsPaylater != null
                        ? paymentInputRequest.IsPaylater === true
                          ? true
                          : false
                        : false
                    }
                    onChange={(event) =>
                      this.inputChangedHandler(event, "PrePaid", "checkbox")
                    }
                  />
                  <label htmlFor="payment-PrePaid" className="font-weight-bold">
                    Pay Now
                  </label>
                </div>
                <div
                  className="icheck-primary hbCheckBox left-pay-Now"
                  style={{ marginLeft: "10%" }}
                >
                  <input
                    name="PayLaterPayNow"
                    type="radio"
                    id="payment-PayLater"
                    checked={
                      paymentInputRequest.IsPaylater != null
                        ? paymentInputRequest.IsPaylater === false
                          ? true
                          : false
                        : false
                    }
                    onChange={(event) =>
                      this.inputChangedHandler(event, "PayLater", "checkbox")
                    }
                  />
                  <label
                    htmlFor="payment-PayLater"
                    className="font-weight-bold"
                  >
                    Pay Later
                  </label>
                </div>
              </div>
            </h4>
          </div>
        ) : (
          ""
        )}

        <div className={this.getPaymentBlockClassNames()}>
          <div className="payment-border">
            <div className="text-white pricing-header pl-2">
              Payment Information
            </div>
            <div className="col-lg-12 row  m-0 p-0">
              <div className="col-lg-6 mt-3  m-0 p-0">
                <div className="form-group row">
                  <label
                    htmlFor="newres-PickupLocationId"
                    className="col-sm-4 col-form-label font-weight-bold"
                  />
                  <div className="col-sm-8">
                    <div className="icheck-primary">
                      <input
                        name="SameAsProfileNameAndAddress"
                        type="checkbox"
                        id="payment-cbSameAsProfileNameAndAddress"
                        checked={
                          paymentInputRequest.SameAsProfileNameAndAddress
                        }
                        onChange={(event) =>
                          this.props.inputPaymentChangeHandler(
                            event,
                            "SameAsProfileNameAndAddress",
                            "checkbox"
                          )
                        }
                        disabled={this.state.paymentDisabled}
                      />
                      <label
                        htmlFor="payment-cbSameAsProfileNameAndAddress"
                        className="font-weight-bold"
                      >
                        Same as Profile Name and Address
                      </label>
                    </div>
                  </div>
                </div>
                <div className="form-group row required">
                  <label
                    htmlFor="newres-BillingName"
                    className="col-sm-4 col-form-label  font-weight-bold"
                  >
                    Full Name
                  </label>
                  <div className={this.getValueClasses("BillingName")}>
                    <input
                      id="newres-BillingName"
                      type="text"
                      className="form-control"
                      name="BillingName"
                      value={paymentInputRequest.BillingName || ""}
                      onChange={(event) =>
                        this.props.inputPaymentChangeHandler(
                          event,
                          "BillingName",
                          "text"
                        )
                      }
                      disabled={this.state.paymentDisabled}
                    />
                  </div>
                </div>
                <AddressComponent
                  isAstericsRequired={
                    paymentInputRequest.PaymentMethod === "CREDIT_CARD" ||
                    paymentInputRequest.PaymentMethod === "DEBIT_CARD"
                      ? true
                      : false
                  }
                  type="PAYMENT"
                  key="Payment-CreditCard-address-component"
                  onChangeMethod={this.props.inputAddressChangeHandler}
                  address={paymentInputRequest.Address}
                  resetLoader={this.props.resetLoader}
                  disabled={this.state.paymentDisabled}
                  isValidateDone={this.props.isValidateDone}
                />
                <div className="col-lg-12 mt-5">
                  <h6 className="normal-header1">
                    Note: Free credits in customer’s account will be applied
                    first
                  </h6>
                </div>
              </div>
              <div className="col-lg-6 mt-3 m-0 p-0">
                <div className="form-group row">
                  <label className="col-sm-4 col-form-label font-weight-bold" />
                  <div className="col-sm-5 text-left pr-0">
                    <div className="icheck-primary">
                      <input
                        name="TransactionType"
                        value="PRE_AUTHORIZATION"
                        type="checkbox"
                        id="newres-PRE_AUTHORIZATION"
                        checked={this.state.isPreAuthoRizationChecked}
                        onChange={(event) =>
                          this.inputChangedHandler(
                            event,
                            "PRE_AUTHORIZATION",
                            "checkbox"
                          )
                        }
                        disabled={this.state.paymentDisabled}
                      />
                      <label
                        htmlFor="newres-PRE_AUTHORIZATION"
                        className="font-weight-bold"
                      >
                        Pre-Authorization
                      </label>
                    </div>
                  </div>
                  <div className="col-sm-2 text-left pr-0 pl-0">
                    <div className="icheck-primary">
                      <input
                        name="TransactionType"
                        value="REFUND"
                        type="checkbox"
                        id="newres-REFUND"
                        checked={this.state.isRefundChecked}
                        onChange={(event) =>
                          this.inputChangedHandler(event, "REFUND", "checkbox")
                        }
                        disabled={this.state.paymentDisabled}
                      />
                      <label
                        htmlFor="newres-REFUND"
                        className="font-weight-bold"
                      >
                        Refund
                      </label>
                    </div>
                  </div>
                </div>
                {this.state.isRefundChecked ? (
                  <div className="form-group row required">
                    <label
                      htmlFor="payment-Reason"
                      className="col-sm-4 col-form-label font-weight-bold"
                    >
                      Refund Reason
                    </label>
                    <div className={this.getValueClasses("Reason")}>
                      <select
                        id="payment-Reason"
                        className="form-control"
                        name="Reason"
                        value={paymentInputRequest.Reason || ""}
                        onChange={(event) =>
                          this.props.inputPaymentChangeHandler(
                            event,
                            "Reason",
                            ""
                          )
                        }
                        disabled={this.state.paymentDisabled}
                      >
                        <option value="" key="0">
                          Select
                        </option>
                        {this.props.paymentReasonTypes &&
                          this.props.paymentReasonTypes.map(
                            (element, index) => (
                              <option key={element.Name} value={element.Name}>
                                {element.DisplayName}
                              </option>
                            )
                          )}
                      </select>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <div className="form-group row required">
                  <label
                    htmlFor="payment-PaymentMethod"
                    className="col-sm-4 col-form-label font-weight-bold"
                  >
                    Payment Method
                  </label>
                  <div className={this.getValueClasses("PaymentMethod")}>
                    <select
                      id="payment-PaymentMethod"
                      className="form-control"
                      name="PaymentMethod"
                      value={paymentInputRequest.PaymentMethod || ""}
                      onChange={(event) =>
                        this.inputChangedHandler(
                          event,
                          "PaymentMethod",
                          "checkbox"
                        )
                      }
                      disabled={this.state.paymentDisabled}
                    >
                      <option value="">Select</option>
                      <option value="CREDIT_CARD">Credit Card</option>
                      <option value="DEBIT_CARD">Debit Card</option>
                      <option value="MANUAL_INVOICE">Manual Invoice</option>
                      <option value="CASH">Cash</option>
                      <option value="CHECK">Check</option>
                      {this.state.isRefundChecked ? (
                        <option value="CREDITS">Credits</option>
                      ) : (
                        ""
                      )}
                    </select>
                  </div>
                </div>
                {this.state.paymentMethod === "CREDIT_CARD" ||
                this.state.paymentMethod === "DEBIT_CARD" ? (
                  <Fragment>
                    <div className="form-group row required">
                      <label
                        htmlFor="newres-CreditCardType"
                        className="col-sm-4 col-form-label font-weight-bold"
                      >
                        Card Type
                      </label>
                      <div className={this.getValueClasses("CardType")}>
                        <select
                          id="newres-CreditCardType"
                          className="form-control"
                          name="CreditCardType"
                          onChange={(event) =>
                            this.props.inputPaymentChangeHandler(
                              event,
                              "CardType",
                              ""
                            )
                          }
                          value={
                            paymentInputRequest.CardType
                              ? paymentInputRequest.CardType
                              : ""
                          }
                          disabled={this.state.paymentDisabled}
                        >
                          <option value="PREMATCH">Visa</option>
                          <option value="MASTERCARD">Mastercard</option>
                          <option value="AMEX">American Express (Amex)</option>
                          <option value="DISCOVER">Discover</option>
                          <option value="NET_BANKING">Net Banking</option>
                          <option value="MAESTRO">Maestro</option>
                          <option value="PREMATCH">Diners</option>
                          <option value="DINERS">Diners</option>
                          <option value="JCB">JCB</option>
                          <option value="NONE">None</option>
                        </select>
                      </div>
                    </div>

                    <div className="form-group row required">
                      <label
                        htmlFor="newres-CreditCardNumber"
                        className="col-sm-4 col-form-label font-weight-bold"
                      >
                        Credit Card Number
                      </label>
                      <div className={this.getValueClasses("AccountNumber")}>
                        <input
                          id="newres-CreditCardNumber"
                          type="text"
                          className="form-control"
                          name="CreditCardNumber"
                          onChange={(event) =>
                            this.props.inputPaymentChangeHandler(
                              event,
                              "AccountNumber",
                              ""
                            )
                          }
                          value={
                            paymentInputRequest.AccountNumber
                              ? paymentInputRequest.AccountNumber
                              : ""
                          }
                          disabled={this.state.paymentDisabled}
                        />
                      </div>
                      <div className={this.getValueClasses("CVVCode")}>
                        <input
                          id="newres-CVVCode"
                          type="text"
                          className="form-control"
                          name="CVVCode"
                          onChange={(event) =>
                            this.props.inputPaymentChangeHandler(
                              event,
                              "CVVCode",
                              ""
                            )
                          }
                          value={
                            paymentInputRequest.CVVCode
                              ? paymentInputRequest.CVVCode
                              : ""
                          }
                          disabled={this.state.paymentDisabled}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-4 col-form-label font-weight-bold">
                        Expiry Date
                      </label>
                      <div className="col-sm-4">
                        <select
                          id="newres-CardExpirationMonth"
                          type="select"
                          className="form-control"
                          name="CardExpirationMonth"
                          onChange={(event) =>
                            this.props.inputPaymentChangeHandler(
                              event,
                              "ExpirationMonth",
                              ""
                            )
                          }
                          value={
                            paymentInputRequest.CardExpirationMonth
                              ? paymentInputRequest.CardExpirationMonth
                              : ""
                          }
                          disabled={this.state.paymentDisabled}
                        >
                          {this.state.expiartionMonths.map((element) => (
                            <option key={element.key} value={element.key}>
                              {element.displayValue}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="col-sm-4">
                        <select
                          id="newres-CardExpirationYear"
                          type="select"
                          className="form-control"
                          name="CardExpirationYear"
                          onChange={(event) =>
                            this.props.inputPaymentChangeHandler(
                              event,
                              "ExpirationYear",
                              ""
                            )
                          }
                          value={
                            paymentInputRequest.CardExpirationYear
                              ? paymentInputRequest.CardExpirationYear
                              : ""
                          }
                          disabled={this.state.paymentDisabled}
                        >
                          {this.state.expiartionYear.map((element) => (
                            <option key={element.key} value={element.key}>
                              {element.displayValue}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </Fragment>
                ) : (
                  ""
                )}
                <div className="form-group row required">
                  <label
                    htmlFor="payment-Amount"
                    className="col-sm-4 col-form-label font-weight-bold"
                  >
                    Amount
                  </label>
                  <div className={this.getValueClasses("Amount")}>
                    <input
                      id="payment-Amount"
                      type="text"
                      className="form-control"
                      style={{ paddingLeft: "1.5rem" }}
                      name="Amount"
                      value={paymentInputRequest.Amount || ""}
                      onChange={(event) =>
                        this.props.inputPaymentChangeHandler(
                          event,
                          "Amount",
                          ""
                        )
                      }
                      disabled="true"
                    />
                    <i className="fas fa-dollar-sign position-absolute input-search-ico"></i>
                  </div>
                </div>
                <div className="form-group row">
                  <label
                    htmlFor="payment-Notes"
                    className="col-sm-4 col-form-label  font-weight-bold"
                  >
                    Notes
                  </label>
                  <div className="col-sm-8">
                    <textarea
                      rows="20"
                      id="payment-Notes"
                      className="form-control col-sm-12"
                      name="Notes"
                      onChange={(event) =>
                        this.props.inputPaymentChangeHandler(event, "Notes", "")
                      }
                      disabled={this.state.paymentDisabled}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label
                    htmlFor="payment-IntegRecordedBy"
                    className="col-sm-4 col-form-label font-weight-bold"
                  >
                    Recorded By
                  </label>
                  <div className="col-sm-8">
                    <input
                      id="payment-IntegRecordedBy"
                      type="text"
                      className="form-control pl-4"
                      name="IntegRecordedBy"
                      value={
                        paymentInputRequest.IntegRecordedByName
                          ? paymentInputRequest.IntegRecordedByName
                          : ""
                      }
                      onChange={() => {}}
                      disabled={this.state.paymentDisabled}
                    />
                    <i
                      className="fas fa-search position-absolute input-search-ico"
                      onClick={() =>
                        this.props.launchModal({
                          type: ModalType.AGENT_MODAL,
                          agentType: "IntegRecordedBy",
                        })
                      }
                    />
                    {paymentInputRequest.IntegRecordedByName ? (
                      <i
                        className="fa fa-times-circle  position-absolute input-search-ico-right"
                        onClick={(event) =>
                          this.props.agentRemove("IntegRecordedBy")
                        }
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="col-lg-12 text-right mb-4">
                  {!this.state.isRefundChecked ? (
                    <Button
                      className="txt-right mr-1"
                      color="primary"
                      onClick={(event) =>
                        this.props.doPayment("PROCESS_PAYMENT")
                      }
                      tabIndex="1"
                      type="button"
                      disabled={this.state.paymentDisabled}
                    >
                      Process Payment
                    </Button>
                  ) : (
                    ""
                  )}
                  {/* <Button
                    color="secondary"
                    type="button"
                    className="txt-right"
                    onClick={(event) => this.props.doPayment("RECORD_PAYMENT")}
                    disabled={this.state.paymentDisabled}
                  >
                    Record Payment
                  </Button> */}

                  <button
                    type="button"
                    className="btn btn-primary ml-2"
                    onClick={(event) => this.props.doPayment("RECORD_PAYMENT")}
                    disabled={this.state.paymentDisabled}
                  >
                    Record Payment
                  </button>
                </div>
              </div>
              {this.props.executionType !== "PAYMENT_INFO" &&
              !paymentInputRequest.IsPaylater &&
              !this.state.isEndRental ? (
                <div className="modal-backdrop test2 fade show fade-update-res hover-block cursor-pointer"></div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
export default Payment;

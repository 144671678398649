import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getVehicleFeatures,
  getVehicleFeatureReset,
  updateVehicleFeatures,
  updateVehicleFeatureReset,
} from "../../../../store/actions/index";
import Spinner from "../../../../components/UI/Spinner/Spinner";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Draggable from "react-draggable";
import { showNotification } from "../../../../components/UI/Toaster/Toaster";
import {
  getBasicToastResponse,
  isNullOrEmpty,
} from "../../../../shared/utility";

const MemberVehicleFeature = (props) => {
  const loading = useSelector((state) => state.vehicleReducer.loading);
  const vehicleFeatureResponse = useSelector(
    (state) => state.vehicleReducer.vehicleFeatureResponse
  );
  const updateVehicleFeatureResponse = useSelector(
    (state) => state.vehicleReducer.updateVehicleFeatureResponse
  );
  const [searchData, setSearchData] = useState(null);
  const [oldAmenitiesList, setOldAmenitiesList] = useState([]);
  const [newAmenitiesList, setNewAmenitiesList] = useState([]);
  const memberId = props.memberId;
  const dispatch = useDispatch();

  useEffect(() => {
    getSearchList();
  }, [memberId]);

  useEffect(() => {
    if (!vehicleFeatureResponse) {
      return;
    }
    if (vehicleFeatureResponse.StatusCode === 0) {
      laodSearchDataFromResponse(vehicleFeatureResponse);
    } else {
      showNotification(getBasicToastResponse(vehicleFeatureResponse));
      dispatch(getVehicleFeatureReset());
    }
  }, [vehicleFeatureResponse]);

  useEffect(() => {
    if (!updateVehicleFeatureResponse) {
      return;
    }
    showNotification(getBasicToastResponse(updateVehicleFeatureResponse));
    if (updateVehicleFeatureResponse.StatusCode === 0) {
      props.closeModal();
    }
    dispatch(updateVehicleFeatureReset());
  }, [updateVehicleFeatureResponse]);

  const laodSearchDataFromResponse = (response) => {
    setSearchData(response);
    setOldAmenitiesList(response ? response.AmenitiesList : []);
    setNewAmenitiesList(response ? response.NewAmenitiesList : []);
  };

  const getSearchList = () => {
    const requestObjInfo = {};
    requestObjInfo.MemberId = props.memberId;
    requestObjInfo.MemberAssetId = props.memberAssetId;
    dispatch(getVehicleFeatureReset());
    dispatch(getVehicleFeatures(requestObjInfo));
  };

  const handleOnChange = (event, amenitiesType) => {
    let index = event.target.dataset.id;
    let amenitiesList =
      amenitiesType === "old"
        ? JSON.parse(JSON.stringify(oldAmenitiesList))
        : JSON.parse(JSON.stringify(newAmenitiesList));
    amenitiesList[index].Active = event.target.checked;
    amenitiesType === "old"
      ? setOldAmenitiesList(amenitiesList)
      : setNewAmenitiesList(amenitiesList);
  };
  const getSelectedRowData = () => {
    let installedFeatures = [];
    let selectedData1, selectedData2;
    if (oldAmenitiesList) {
      selectedData1 = oldAmenitiesList.map((element, index) => {
        if (String(element.Active) === "true") {
          return element.Id + "|" + String(element.Active);
        }
      });
    }
    if (newAmenitiesList) {
      selectedData2 = newAmenitiesList.map((element, index) => {
        if (String(element.Active) === "true") {
          return element.Id + "|" + String(element.Active);
        }
      });
    }
    if (selectedData1) {
      selectedData1 = selectedData1.filter((item) => item !== undefined);
      installedFeatures.push(selectedData1);
    }
    if (selectedData2) {
      selectedData2 = selectedData2.filter((item) => item !== undefined);
      installedFeatures.push(selectedData2);
    }
    return installedFeatures.join(",");
  };
  const saveData = () => {
    const saveDataObj = {};
    saveDataObj.DeviceValue = null;
    saveDataObj.MemberId = props.memberId;
    saveDataObj.MemberAssetId = props.memberAssetId;
    saveDataObj.InstalledFeatures = getSelectedRowData();
    updateVehicleFeaturesInfo(saveDataObj);
  };

  const updateVehicleFeaturesInfo = (saveDataObj) => {
    dispatch(updateVehicleFeatureReset());
    dispatch(updateVehicleFeatures(saveDataObj));
  };
  const renderColumnHeader = (value) => {
    return <span className="column__header text-center">{value}</span>;
  };

  const renderColumnValue = (entity, property, index) => {
    return (
      <span
        className={`row__column ${!isNullOrEmpty(index) ? "text-center" : ""}`}
      >
        {!isNullOrEmpty(index) ? (
          <div className="icheck-primary">
            <input
              type="checkbox"
              name={"cb" + entity.Id}
              id={"cb" + entity.Id}
              data-id={index}
              checked={entity.Active}
              onChange={(event) => handleOnChange(event, entity[property])}
            />
            <label htmlFor={"cb" + entity.Id} />
          </div>
        ) : (
          entity[property]
        )}
      </span>
    );
  };

  const getAmenitiesListTable = (amenitiesList, amenitiesType) => {
    if (searchData && amenitiesList) {
      return amenitiesList.map((entity, index) => {
        entity["FeatureType"] = amenitiesType;
        return (
          <div className="row" key={entity.Id}>
            {renderColumnValue(entity, "FeatureType", index)}
            {renderColumnValue(entity, "DisplayText")}
            {renderColumnValue(entity, "Type")}
            {renderColumnValue(entity, "Value")}
          </div>
        );
      });
    }
  };
  const getHeader = () => {
    return (
      <div className="column">
        {renderColumnHeader("")}
        {renderColumnHeader("Title")}
        {renderColumnHeader("Type")}
        {renderColumnHeader("Value")}
      </div>
    );
  };

  const getModalSettings = () => {
    return {
      modalWidth: props.width || "auto",
      modalBodyHeight: props.height || "auto",
    };
  };

  let modalSettings = getModalSettings();
  return (
    <div>
      <Draggable handle=".cursor">
        <Modal
          isOpen={props.showModal}
          className="no-cursor"
          toggle={() => {
            props.closeModal();
          }}
          style={{
            minWidth: modalSettings.modalWidth + "px",
          }}
        >
          <ModalHeader
            className="modal-header bg-primary modal-title text-white cursor"
            toggle={() => {
              props.closeModal();
            }}
          >
            <i className="fas fa-arrows-alt" /> {props.title}
          </ModalHeader>
          <ModalBody
            style={{
              height: modalSettings.modalBodyHeight + "px",
            }}
          >
            <div
              className="form-group row"
              style={{
                height: modalSettings.modalBodyHeight - 20 + "px",
              }}
            >
              <div className="FormRow col-sm-12">
                <div className="col-sm-12  pl-0">
                  <h3>Vehicle Features </h3>
                </div>
                <section id="oldAmenitiesTableId">
                  <div className="datagrid">
                    {getHeader()}
                    <div className="tableScroll">
                      {getAmenitiesListTable(oldAmenitiesList, "old")}
                    </div>
                  </div>
                </section>
                <div className="col-sm-12 pl-0">
                  <h3>New Asset Amenities </h3>
                </div>
                <section className="pb-4" id="newAmenitiesTableId">
                  <div className="datagrid">
                    {getHeader()}
                    <div className="tableScroll">
                      {getAmenitiesListTable(newAmenitiesList, "new")}
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              name="btnUpdateVehicleFeatures"
              className="btn btn-primary waves-effect waves-light btnSize ml-2"
              onClick={(event) => saveData()}
            >
              <i className="fas fa-save pr-1"></i>Update Vehicle Features
            </button>
            <button
              type="button"
              name="btnCancel"
              className="btn btn-secondary waves-effect waves-light btnSize ml-2"
              onClick={(event) => props.closeModal()}
            >
              <i className="fas fa-times pr-1"></i>Cancel
            </button>
          </ModalFooter>
        </Modal>
      </Draggable>
    </div>
  );
};
export default MemberVehicleFeature;

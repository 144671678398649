import React, { useState } from 'react';
import CustomAccordion from '../../../../components/UI/accordion/custom-accordion';
import InputCtrl from '../../../../components/UI/Input/input-ctrl';

function ExternalInformation({ otherData, setOtherData, disabled }) {
    const [isActive, setIsActive] = useState(false);
    const onChangeExternalData = (value, name) => {
        const tempData = { ...otherData };
        tempData[name] = value;
        setOtherData(tempData);
    }

    return (
        <div className='flex-column row-gap-1'>
            <CustomAccordion isActive={isActive} setIsActive={setIsActive} title="External Information" />
            {isActive ?
                <>
                    <InputCtrl
                        name="airline"
                        value={otherData?.airline}
                        type="text"
                        placeholder="Airline"
                        onChange={onChangeExternalData}
                        disabled={disabled}
                        iconClass="fas fa-plane-departure icon"
                    />
                    <InputCtrl
                        name="flight"
                        type="text"
                        placeholder="Flight Number"
                        iconClass="fa fa-plane icon"
                        value={otherData?.flight}
                        onChange={onChangeExternalData}
                        disabled={disabled}
                    />
                </> : ""}
        </div>
    )
}

export default ExternalInformation
import React, { Fragment, Component } from "react";
import { connect } from "react-redux";
import * as actions from "../../../store/actions/index";
import SearchItemsContainer from "../../search/ui/search-items-container";
import {
  updateObject,
  checkValidity,
  getCurrentServerDate,
  getDateTimeString,
  getBasicToastResponse,
} from "../../../shared/utility";
import {
  getExactSearchInputCtrl,
  getPageName,
  setActiveMenu,
  getSearchRequestCompatibleData,
  resetSearchControls,
} from "../../search/search-utility/search-utility";
import Spinner from "../../../components/UI/Spinner/Spinner";
import Pagination from "../../../components/pagination/Pagination";
import Utility from "../../../shared/utility";
import PageInfo from "../../../shared/PageInfo";
import NoRecordFoundComponent from "../../../components/common/pages/no-record-found";
import DefaultComponentWithMessage from "../../../components/common/pages/default-component-with-message";
import { withRouter } from "react-router-dom";
import moment from "moment";
import Moment from "moment-timezone";
import { storeRequest, getDataFromStorage } from "../../../shared/LocalStorage";
import { showNotification } from "../../../components/UI/Toaster/Toaster";
import SearchFilter from "../../../components/filter/search-filter";
import { getDefaultFilterStates } from "../../../components/filter/filter-helper";

class ReportProblemDetails extends Component {
  state = { ...initialSearchState };

  componentDidMount() {
    this.initSearchPage();
  }
  componentDidUpdate(prevProps, prevState) {
    this.laodDdlCtrlFromCurrentResponse();
    if (this.props.match.url !== prevProps.match.url) {
      this.setState({ initialSearchState }, () => {
        this.initSearchPage();
      });
    }
  }
  UNSAFE_componentWillReceiveProps({ searchReportProblemDetailsResponse }) {
    if (
      (searchReportProblemDetailsResponse && !this.state.searchData) ||
      (this.state.searchData && searchReportProblemDetailsResponse)
    ) {
      let complainListResponseObj = searchReportProblemDetailsResponse[0];
      if (complainListResponseObj.StatusCode === 0) {
        this.laodSearchDataFromResponse(complainListResponseObj);
      } else {
        showNotification(getBasicToastResponse(complainListResponseObj));
        this.props.onSearchReportProblemDetailsReset();
      }
    }
  }

  initSearchPage = () => {
    let updatedControls = getDataFromStorage(getPageName(this.props.match.url));
    this.props.onSearchReportProblemDetailsReset();
    const query = new URLSearchParams(this.props.location.search);
    const reservationId = query.get("reservationId");
    const memberId = query.get("memberId");
    if (!updatedControls) {
      updatedControls = getExactSearchInputCtrl(this.props.match.url);
      updatedControls = updateObject(updatedControls, {
        MDate: updateObject(updatedControls["MDate"], {
          value: getCurrentServerDate("MM:DD:YYYY:HH:mm"),
        }),
        Status: updateObject(updatedControls["Status"], {
          value: getDefaultFilterStates(this.props.match.url),
        }),
      });
    }

    this.setState(
      {
        searchInputCtrls: updatedControls,
        searchData: null,
        memberId: memberId,
        reservationId: reservationId,
      },
      () => {
        this.getSearchList();
      }
    );
  };
  laodDdlCtrlFromCurrentResponse = () => {
    let updatedControls = null;
    if (updatedControls) {
      this.setState({
        searchInputCtrls: updatedControls,
      });
    }
  };

  laodSearchDataFromResponse = (response) => {
    //Filtering data
    let complainData =
      response && response.Complaints ? response.Complaints.Complain : [];
    let statusData = this.state.searchInputCtrls["Status"].value
      ? this.state.searchInputCtrls["Status"].value.toLowerCase()
      : "";
    let statusDataArr = statusData.toString().split(",");
    if (statusDataArr.length > 0) {
      if (statusDataArr.length === 1) {
        complainData = complainData.filter(
          (item) => item.Status.toLowerCase() === statusData
        );
      }
    } else {
      complainData = [];
    }
    response.Complaints.Complain = complainData;
    response.TotalRecords = complainData.length;
    //End Filtering data

    storeRequest(
      getPageName(this.props.match.url),
      this.state.searchInputCtrls
    );
    this.setState({
      searchData: response,
      isSearchPerformed: true,
    });
  };
  inputChangedHandler = (event, controlName, elementType, otherValue) => {
    let value = null;
    if (elementType === "datetimepicker") {
      value = moment(event).format("MM:DD:YYYY:HH:mm");
    } else if (elementType === "radio") {
      value = event.target.checked;
    } else if (elementType === "select") {
      value = event.target.value;
    } else {
      value = event.target.value;
    }
    let updatedControls = updateObject(this.state.searchInputCtrls, {
      [controlName]: updateObject(this.state.searchInputCtrls[controlName], {
        value: value,
        valid: checkValidity(
          value,
          this.state.searchInputCtrls[controlName].validation
        ),
        touched: true,
      }),
    });
    this.setState({ searchInputCtrls: updatedControls });
  };

  submitHandler = (event) => {
    event.preventDefault();
    this.getSearchList();
  };

  resetHandler = (event) => {
    event.preventDefault();
    this.setState(
      {
        resetData: false,
        searchInputCtrls: resetSearchControls(this.state.searchInputCtrls),
      },
      () => {
        this.getSearchList();
      }
    );
  };
  onPageChanged = (currentPage, recordsPerPage) => {
    if (currentPage && recordsPerPage) {
      this.setState(
        {
          currentPage: currentPage,
          recordsPerPage: recordsPerPage,
        },
        () => {
          this.getSearchList();
        }
      );
    }
  };
  getSearchList = () => {
    const requestObjInfo = getSearchRequestCompatibleData(
      this.state.searchInputCtrls,
      getPageName(this.props.match.url)
    );
    requestObjInfo.MemberId = this.state.memberId;
    requestObjInfo.ReservationId = this.state.reservationId;
    this.props.onSearchReportProblemDetailsReset();
    this.props.onSearchReportProblem(requestObjInfo);
  };

  renderTableData = () => {
    let tableRows = "";
    let complainData =
      this.state.searchData && this.state.searchData.Complaints
        ? this.state.searchData.Complaints.Complain
        : [];

    tableRows = complainData.map((post, index) => {
      return (
        <div className="row" key={index}>
          <span className="row__column">{post.Problem}</span>
          <span className="row__column">{post.ProblemType}</span>
          <span className="row__column">{post.Createdby}</span>
          <span className="row__column">{post.SubCategory}</span>
          <span className="row__column">{post.Priority}</span>
          <span className="row__column">{getDateTimeString(post.Date)}</span>
          <span className="row__column">
            {getDateTimeString(post.ResolutionDate)}
          </span>
          <span className="row__column">{post.Status}</span>
        </div>
      );
    });

    return (
      <div>
        <div className="datagrid">
          <div className="column">
            <span className="column__header">Problem</span>
            <span className="column__header">Problem Type</span>
            <span className="column__header">Category</span>
            <span className="column__header">Sub Category</span>
            <span className="column__header">Priority</span>
            <span className="column__header">Creation Date</span>
            <span className="column__header">Resolution Date</span>
            <span className="column__header">Status</span>
          </div>
          {tableRows}
        </div>
      </div>
    );
  };

  applySearchFilter = (statuses) => {
    const updatedControls = updateObject(this.state.searchInputCtrls, {
      Status: updateObject(this.state.searchInputCtrls["Status"], {
        value: statuses || null,
      }),
    });
    this.setState(
      {
        searchInputCtrls: updatedControls,
      },
      () => {
        this.getSearchList();
      }
    );
  };

  render() {
    const inputCtrl = this.state.searchInputCtrls
      ? { ...this.state.searchInputCtrls }
      : getExactSearchInputCtrl(this.props.match.url);

    setActiveMenu(this.props.match.url);
    const pageName = getPageName(this.props.match.url);
    return (
      <Fragment>
        <section className="res-search" id="reportProblemDetailsSearch">
          <SearchItemsContainer
            searchInputCtrls={inputCtrl}
            changed={this.inputChangedHandler}
            submitHandler={this.submitHandler}
            resetHandler={this.resetHandler}
          />
        </section>
        <SearchFilter
          applySearchFilter={this.applySearchFilter}
          pageName={pageName}
          changed={this.inputChangedHandler}
          reservationStates={
            inputCtrl
              ? inputCtrl["Status"].value || null
              : getDefaultFilterStates(this.props.match.url)
          }
          url={this.props.match.url}
        />
        <section className="grid-table" id="reportProblemDetailsSearchTable">
          {this.state.searchData && this.state.searchData.TotalRecords > 0 ? (
            <Fragment>
              <Pagination
                totalRecords={this.state.searchData.TotalRecords}
                recordsPerPage={this.state.recordsPerPage}
                currentPage={this.state.currentPage}
                onPageChanged={this.onPageChanged}
                key={this.state.searchData.TotalRecords}
              />
              {this.renderTableData()}
            </Fragment>
          ) : this.state.isSearchPerformed ? (
            <NoRecordFoundComponent searchMsg="No Record Found" />
          ) : (
            <DefaultComponentWithMessage />
          )}
        </section>
        {this.props.loading || this.state.loading ? <Spinner /> : ""}
      </Fragment>
    );
  }
}
const initialSearchState = {
  isSearchPerformed: false,
  searchInputCtrls: null,
  statuses: null,
  searchData: null,
  loading: false,
  currentPage: new PageInfo().PageNumber,
  recordsPerPage: new PageInfo().PerPageCount,
  currentSorting: {
    sortBy: null,
    sortType: null,
  },
  reservationId: null,
  memberId: null,
};
const mapStateToProps = (state) => {
  return {
    loading: state.reportReducer.loading,
    searchReportProblemDetailsResponse:
      state.reportReducer.searchReportProblemDetailsResponse,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onSearchReportProblem: (reqObject) =>
      dispatch(actions.searchReportProblemDetails(reqObject)),
    onSearchReportProblemDetailsReset: () =>
      dispatch(actions.searchReportProblemDetailsReset()),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ReportProblemDetails));

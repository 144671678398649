import React from "react";
import "./input-ctrl.scss";

const SelectCtrl = (props) => {
    const {
        name,
        value,
        options,
        placeholder,
        onChange,
        disabled,
        iconClass,
        imgIco,
        classNames,
        parentClass = 'input-ctrl'
    } = props;

    return (
        <div className={`${parentClass} input-ctrl`}>
            {imgIco ? <img
                src={imgIco}
                alt=""
            // style={{ height: "20px", width: "20px" }}
            /> : iconClass && <i className={iconClass} aria-hidden="true"></i>}
            {placeholder && <label className="place-holder">{placeholder}</label>}
            <select
                name={name}
                onChange={onChange}
                value={value || ''}
                className={classNames}
                disabled={disabled}
            >
                {options.map((data) => {
                    return <option value={data?.key} key={data?.key} disabled={data?.disabled}>{data?.value}</option>
                })}
            </select>
        </div>
    )
}
export default SelectCtrl;
import * as actionTypes from "../actions/actionTypes";
import { reducerUpdateObject } from "./reducer.utility";

const initialState = {
  serviceTaskResponse: null,
  error: null,
  loading: false
};
const getServiceTaskInfosStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};

const getServiceTaskInfosSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    serviceTaskResponse: action.serviceTaskResponse,
    error: null,
    loading: false
  });
};

const getServiceTaskInfosFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false
  });
};
const getServiceTaskInfosReset = (state, action) => {
  return reducerUpdateObject(state, { serviceTaskResponse: null });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_SERVICE_TASK_START:
      return getServiceTaskInfosStart(state, action);
    case actionTypes.GET_SERVICE_TASK_SUCCESS:
      return getServiceTaskInfosSuccess(state, action);
    case actionTypes.GET_SERVICE_TASK_FAIL:
      return getServiceTaskInfosFail(state, action);
    case actionTypes.GET_SERVICE_TASK_RESET:
      return getServiceTaskInfosReset(state, action);

    default:
      return state;
  }
};
export default reducer;

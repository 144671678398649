import { reducerUpdateObject } from "./reducer.utility";
import * as actionTypes from "./../actions/actionTypes-shuttle-app";

const initialState = {
  error: null,
  loading: false,
  shuttleResponse: null,
  shuttleAddUpdateDeleteResponse: null,
  landmarkUpdateResponse: null,
  landmarkDeleteResponse: null,
  shuttleTripDetailsResponse: null,
  landmarkResponse: null,
};

const doShuttleAPIStart = (state) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
const doShuttleAPIFail = (state, action) => {
  return reducerUpdateObject(state, { error: action.error, loading: false });
};

export const getLandmarkSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    landmarkResponse: action.landmarkResponse,
    error: null,
    loading: false,
  });
};

const getLandmarkReset = (state) => {
  return reducerUpdateObject(state, {
    landmarkResponse: null,
  });
};

export const updateLandmarkInfoSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    landmarkUpdateResponse: action.landmarkUpdateResponse,
    error: null,
    loading: false,
  });
};

const updateLandmarkInfoReset = (state) => {
  return reducerUpdateObject(state, {
    landmarkUpdateResponse: null,
  });
};

export const deleteLandmarkInfoSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    landmarkDeleteResponse: action.landmarkDeleteResponse,
    error: null,
    loading: false,
  });
};

const deleteLandmarkInfoReset = (state) => {
  return reducerUpdateObject(state, {
    landmarkDeleteResponse: null,
  });
};

export const getShuttleSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    shuttleResponse: action.shuttleResponse,
    error: null,
    loading: false,
  });
};

const getShuttleReset = (state) => {
  return reducerUpdateObject(state, {
    shuttleResponse: null,
  });
};
const shuttleAddUpdateDelete = (state, action) => {
  return reducerUpdateObject(state, {
    shuttleAddUpdateDeleteResponse: action.shuttleAddUpdateDeleteResponse,
    error: null,
    loading: false,
  });
};

const shuttleAddUpdateDeleteReset = (state) => {
  return reducerUpdateObject(state, {
    shuttleAddUpdateDeleteResponse: null,
  });
};

//shuttle trip details
const getShuttleTripDetailsSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    shuttleTripDetailsResponse: action.shuttleTripDetailsResponse,
    error: null,
    loading: false,
  });
};

const getShuttleTripDetailsReset = (state) => {
  return reducerUpdateObject(state, {
    shuttleTripDetailsResponse: null,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_LANDMARK_START:
      return doShuttleAPIStart(state);
    case actionTypes.GET_LANDMARK_SUCCESS:
      return getLandmarkSuccess(state, action);
    case actionTypes.GET_LANDMARK_FAIL:
      return doShuttleAPIFail(state, action);
    case actionTypes.GET_LANDMARK_RESET:
      return getLandmarkReset(state);

    case actionTypes.UPDATE_LANDMARK_INFO_START:
      return doShuttleAPIStart(state);
    case actionTypes.UPDATE_LANDMARK_INFO_SUCCESS:
      return updateLandmarkInfoSuccess(state, action);
    case actionTypes.UPDATE_LANDMARK_INFO_FAIL:
      return doShuttleAPIFail(state, action);
    case actionTypes.UPDATE_LANDMARK_INFO_RESET:
      return updateLandmarkInfoReset(state);

    case actionTypes.DELETE_LANDMARK_INFO_START:
      return doShuttleAPIStart(state);
    case actionTypes.DELETE_LANDMARK_INFO_SUCCESS:
      return deleteLandmarkInfoSuccess(state, action);
    case actionTypes.DELETE_LANDMARK_INFO_FAIL:
      return doShuttleAPIFail(state, action);
    case actionTypes.DELETE_LANDMARK_INFO_RESET:
      return deleteLandmarkInfoReset(state);

    case actionTypes.SHUTTLE_START:
      return doShuttleAPIStart(state);
    case actionTypes.SHUTTLE_FAIL:
      return doShuttleAPIFail(state, action);
    case actionTypes.GET_SHUTTLE_SUCCESS:
      return getShuttleSuccess(state, action);
    case actionTypes.GET_SHUTTLE_RESET:
      return getShuttleReset(state);
    case actionTypes.SHUTTLE_CRUD_SUCCESS:
      return shuttleAddUpdateDelete(state, action);
    case actionTypes.SHUTTLE_CRUD_RESET:
      return shuttleAddUpdateDeleteReset(state);

    case actionTypes.GET_SHUTTLE_TRIP_DETAILS_START:
      return doShuttleAPIStart(state);
    case actionTypes.GET_SHUTTLE_TRIP_DETAILS_SUCCESS:
      return getShuttleTripDetailsSuccess(state, action);
    case actionTypes.GET_SHUTTLE_TRIP_DETAILS_FAIL:
      return doShuttleAPIFail(state, action);
    case actionTypes.GET_SHUTTLE_TRIP_DETAILS_RESET:
      return getShuttleTripDetailsReset(state);

    default:
      return state;
  }
};
export default reducer;

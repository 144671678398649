import * as actionTypes from "../actions/actionTypes";
import { reducerUpdateObject } from "./reducer.utility";

const initialState = {
  feedbackQuestionsResponse: null,
  feedbackDetailsResponse: null,
  addFeedbackResponse: null,
  loading: false,
  error: null
};

const getFeedbackQuestionsStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
const getFeedbackQuestionsSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    feedbackQuestionsResponse: action.feedbackQuestionsResponse,
    error: null,
    loading: false
  });
};
const getFeedbackQuestionsFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false
  });
};
const getFeedbackQuestionsReset = (state, action) => {
  return reducerUpdateObject(state, { feedbackQuestionsResponse: null });
};

const getFeedbackDetailsStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
const getFeedbackDetailsSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    feedbackDetailsResponse: action.feedbackDetailsResponse,
    error: null,
    loading: false
  });
};
const getFeedbackDetailsFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false
  });
};
const getFeedbackDetailsReset = (state, action) => {
  return reducerUpdateObject(state, { feedbackDetailsResponse: null });
};

const addFeedBackStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
const addFeedBackSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    addFeedbackResponse: action.addFeedbackResponse,
    error: null,
    loading: false
  });
};
const addFeedBackFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false
  });
};
const addFeedBackReset = (state, action) => {
  return reducerUpdateObject(state, { addFeedbackResponse: null });
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_FEEDBACK_QUESTIONS_START:
      return getFeedbackQuestionsStart(state, action);
    case actionTypes.GET_FEEDBACK_QUESTIONS_SUCCESS:
      return getFeedbackQuestionsSuccess(state, action);
    case actionTypes.GET_FEEDBACK_QUESTIONS_FAIL:
      return getFeedbackQuestionsFail(state, action);
    case actionTypes.GET_FEEDBACK_QUESTIONS_RESET:
      return getFeedbackQuestionsReset(state, action);

    case actionTypes.GET_FEEDBACK_DETAILS_START:
      return getFeedbackDetailsStart(state, action);
    case actionTypes.GET_FEEDBACK_DETAILS_SUCCESS:
      return getFeedbackDetailsSuccess(state, action);
    case actionTypes.GET_FEEDBACK_DETAILS_FAIL:
      return getFeedbackDetailsFail(state, action);
    case actionTypes.GET_FEEDBACK_DETAILS_RESET:
      return getFeedbackDetailsReset(state, action);

    case actionTypes.ADD_FEEDBACK_START:
      return addFeedBackStart(state, action);
    case actionTypes.ADD_FEEDBACK_SUCCESS:
      return addFeedBackSuccess(state, action);
    case actionTypes.ADD_FEEDBACK_FAIL:
      return addFeedBackFail(state, action);
    case actionTypes.ADD_FEEDBACK_RESET:
      return addFeedBackReset(state, action);
    default:
      return state;
  }
};
export default reducer;

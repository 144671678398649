import { CreditGrantHistoryInfos } from "../models/AccountCreditModels";
export const FreeCreditViewModel = function () {
  this.StartDate = null;
  this.EndDate = null;
  this.TotalRefundableAmount = null;
  this.TotalCharges = null;
  this.PickUpLocationId = null;
  this.ReturnLocationId = null;
  this.PickUpLocationDisplayText = null;
  this.ReturnLocationDisplayText = null;
  this.TotalCreditAmount = null;
  this.TotalCreditCount = null;
  this.CurrentBalance = null;
  this.CreditGrantHistoryInfos = new CreditGrantHistoryInfos();
};

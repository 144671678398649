import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../../components/UI/Spinner/Spinner";
import { showNotification } from "../../../components/UI/Toaster/Toaster";
import PermissionsGrid from "../../../components/permission/permission-grid-component";
import { Roles_Permissions } from "../../../shared/GlobalVar";
import { getBasicToastResponse, sortByString } from "../../../shared/utility";
import {
  assignRolesToMember,
  assignRolesToMemberReset,
  getPermissionstoRolesReset,
  getRolestoMembers,
} from "../../../store/actions";

const RolestoMember = (props) => {
  const stateloading = useSelector((state) => state.rolesReducer.loading);
  const isCategoryRequired = false;
  const [rolesPermisisonsInfo, setRolesPermisisonsInfo] = useState(null);
  const [dataLoadingFinish, setDataLoadingFinish] = useState(false);

  const dispatch = useDispatch();
  const membersRolesDataResponse = useSelector(
    (state) => state.rolesReducer.membersRolesData
  );
  const assignRolesToMemberResponse = useSelector(
    (state) => state.rolesReducer.assignRolesToMemberResponse
  );

  useEffect(() => {
    dispatch(getPermissionstoRolesReset());
    dispatch(getRolestoMembers({ MemberIds: props.memberIds || [] }));
    setDataLoadingFinish(true);
  }, []);

  useEffect(() => {
    if (!membersRolesDataResponse) {
      return;
    }
    setRolesPermisisonsInfo(
      doCbGridCompatibleDataForRolesToUsers(membersRolesDataResponse)
    );
  }, [membersRolesDataResponse]);

  useEffect(() => {
    if (!assignRolesToMemberResponse) {
      return;
    }
    showNotification(getBasicToastResponse(assignRolesToMemberResponse));
    if (assignRolesToMemberResponse.StatusCode === 0) {
      props.resetParentPage();
    }
    props.closeModal();
    dispatch(getPermissionstoRolesReset());
  }, [assignRolesToMemberResponse]);

  const doCbGridCompatibleDataForRolesToUsers = (rolesPermisisonsData) => {
    let rolesPermissionsGridData = {
      rowData: {},
      columnData: {},
      totalRows: rolesPermisisonsData.AllRoles
        ? rolesPermisisonsData.AllRoles.length
        : 0,
      totalColumns: rolesPermisisonsData.MemberRoles
        ? rolesPermisisonsData.MemberRoles.length
        : 0,
    };
    if (rolesPermisisonsData.AllRoles) {
      rolesPermisisonsData.AllRoles.map((rolesData, index) => {
        var data = {};
        data.rowId = rolesData.RoleId;
        data.rowName = rolesData.Name;
        data.rowDesc = rolesData.Description;
        data.columnInfo = {};
        rolesPermissionsGridData.rowData[data.rowId] = {};
        rolesPermissionsGridData.rowData[data.rowId] = data;
      });
    }

    if (rolesPermisisonsData.MemberRoles) {
      var sortedcolumnsList = sortByString(
        rolesPermisisonsData.MemberRoles,
        "FullName"
      );

      sortedcolumnsList.map((memberData, index1) => {
        var data = {};
        data.columnId = memberData.PersonId;
        data.columnName = memberData.FullName;
        data.rowInfo = {};
        if (memberData.RoleIds) {
          memberData.RoleIds.map((roleId, index2) => {
            var dataPer = {};
            dataPer.granted = true;

            Object.keys(rolesPermissionsGridData.rowData).map(
              (type, index3) => {
                if (rolesPermissionsGridData.rowData[roleId]) {
                  data.rowInfo[roleId] = dataPer;
                  if (
                    !Object.keys(
                      rolesPermissionsGridData.rowData[roleId]["columnInfo"]
                    ).length
                  ) {
                    rolesPermissionsGridData.rowData[roleId]["columnInfo"][
                      data.columnId
                    ] = {};
                    rolesPermissionsGridData.rowData[roleId]["columnInfo"][
                      data.columnId
                    ] = dataPer;
                  } else {
                    rolesPermissionsGridData.rowData[roleId]["columnInfo"][
                      data.columnId
                    ] = dataPer;
                  }
                }
              }
            );
          });
        }
        rolesPermissionsGridData.columnData[memberData.PersonId] = data;
      });
    }
    return rolesPermissionsGridData;
  };
  const getModalSettings = () => {
    const totalColumns = rolesPermisisonsInfo.totalColumns;
    const totalRows = rolesPermisisonsInfo.totalRows;
    let tableWidth = isCategoryRequired
      ? totalColumns * 60 + 300
      : totalColumns * 60 + 200;
    let modalBodyHeight, modalWidth;

    if (totalColumns < 4) {
      modalWidth = isCategoryRequired ? 540 : 425;
    } else {
      if (tableWidth > 1060) {
        modalWidth = 1060;
      } else {
        modalWidth = isCategoryRequired ? tableWidth + 100 : tableWidth + 60;
      }
    }
    if (totalRows < 5) {
      modalBodyHeight = 260;
    } else {
      if (totalRows * 30 + 100 > 648) {
        modalBodyHeight = 648;
      } else {
        modalBodyHeight = totalRows * 30 + 100;
      }
    }
    let modalSettings = {
      modalWidth: modalWidth,
      modalBodyHeight: modalBodyHeight,
    };
    return modalSettings;
  };
  const upDateRolesPermissionsData = (rolesPermisisonsData) => {
    let permissionstoRole = {};
    permissionstoRole.AssignRoles = [];
    Object.keys(rolesPermisisonsData.columnData).map((columnkey, index1) => {
      let granted = [];
      let columnData = rolesPermisisonsData.columnData[columnkey];
      let permissionObj = {};
      permissionObj.MemberId = columnkey;
      Object.keys(columnData.rowInfo).map((rowKey, index2) => {
        let rowData = columnData.rowInfo[rowKey];
        if (rowData.granted) {
          granted.push(rowKey);
        }
      });
      permissionObj.GrantedRoles = granted;
      permissionstoRole.AssignRoles.push(permissionObj);
    });
    dispatch(assignRolesToMemberReset());
    dispatch(assignRolesToMember(permissionstoRole));
  };

  let rolesPermissionsctrl = <Spinner />;
  if (!stateloading && dataLoadingFinish === true) {
    if (
      membersRolesDataResponse &&
      rolesPermisisonsInfo &&
      membersRolesDataResponse.StatusCode === 0
    ) {
      let modalSettings = getModalSettings();
      rolesPermissionsctrl = (
        <Fragment>
          <PermissionsGrid
            isModalOpen={props.showModal}
            closeModal={props.closeModal}
            modalSettings={modalSettings}
            permissionsType={Roles_Permissions.RolesToMember}
            isCategoryRequired={false}
            rolesPermisisonsData={rolesPermisisonsInfo}
            upDateRolesPermissionsData={upDateRolesPermissionsData}
          />
          {stateloading ? <Spinner /> : ""}
        </Fragment>
      );
    } else {
      if (
        rolesPermisisonsInfo &&
        rolesPermisisonsInfo.totalColumns === 0 &&
        rolesPermisisonsInfo.totalRows === 0
      ) {
        rolesPermissionsctrl = "";
        props.closeModal();
        let response = {};
        response.StatusMsg = "No roles found to assign.";
        response.StatusCode = 1;
        showNotification(getBasicToastResponse(response));
      }
    }
  }
  return rolesPermissionsctrl;
};
export default RolestoMember;

import React, {
    useEffect,
    useState,
} from "react";
import Draggable from "react-draggable";
import { useDispatch, useSelector } from "react-redux";
import { Modal, ModalBody, ModalHeader, Spinner } from "reactstrap";
import { showNotification } from "../../../../../components/UI/Toaster/Toaster";
import { LocationSettingsViewModel } from "../../../../../services/entities/view-models/settings-view-models";
import {
    getBasicToastResponse
} from "../../../../../shared/utility";
import {
    getLocationData,
    getLocationDataReset,
} from "../../../../../store/actions/index";
import BusinessHourSection from "./business-hours-section";
import LocationInfoSection from "./location-info-section";
import ProviderInfoSection from "./provider-info-section";

export const FoxLocationQuickModal = (props) => {
    const dispatch = useDispatch();
    const locationId = props.locationId;
    const loading = useSelector((state) => state.locationReducer.loading);
    const locationInfo = useSelector(
        (state) => state.locationReducer.locationData
    );
    const [locationData, setLocationData] = useState([null]);
    useEffect(() => {
        intiLocationModalPage();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        if (!locationInfo) {
            return;
        }
        if (locationInfo.StatusCode === 0) {
            setLocationData(new LocationSettingsViewModel(locationInfo.LocationInfo));
        } else {
            showNotification(getBasicToastResponse(locationInfo));
        }
        dispatch(getLocationDataReset());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locationInfo]);
    const intiLocationModalPage = () => {
        const locReq = {};
        locReq.SelectedChildOrgUnitId = locationId;
        dispatch(getLocationDataReset());
        dispatch(getLocationData(locReq));
    };
    const { BusinessHours } = locationData;
    const calculateMinWidthBasedOnBusinessHours = () => {
        if (!BusinessHours) return 88;

        const maxTimeSlots = Object.values(BusinessHours).reduce((maxSlots, times) => {
            return Math.max(maxSlots, times.length);
        }, 0);

        const baseWidth = 88;
        const extraWidth = maxTimeSlots >= 4 ? (maxTimeSlots - 2) * 12 : 0;
        const totalWidth = baseWidth + extraWidth;
        return Math.min(totalWidth, 112);
    };
    return locationData ? (
        <Draggable handle=".cursor">
            <Modal
                isOpen={props.showModal}
                className="no-cursor"
                style={{ minWidth: calculateMinWidthBasedOnBusinessHours() + "rem" }}
                toggle={() => {
                    props.closeModal();
                }}
            >
                <ModalHeader
                    className="modal-header bg-primary modal-title text-white cursor"
                    toggle={() => {
                        props.closeModal();
                    }}
                >
                    <i className="fas fa-map-marker-alt pr-2"></i>
                    Location Quick View
                </ModalHeader>
                <ModalBody
                    className="tableScroll"
                    style={{
                        minHeight: "48rem",
                    }}
                >
                    <div className="res-location-quick-modal-container">
                        <div className="location-quick-modal-left-section">
                            <LocationInfoSection
                                locationData={locationData}
                            />
                            <ProviderInfoSection
                                locationData={locationData}
                            />
                        </div>
                        <div className="location-quick-modal-right-section flex-1">
                            <BusinessHourSection
                                businessHours={BusinessHours}
                            />
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </Draggable>
    ) : loading ? (
        <Spinner />
    ) : (
        ""
    );
};
export default FoxLocationQuickModal;

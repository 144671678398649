import * as actionTypes from "../actions/actionTypes";
import { reducerUpdateObject } from "./reducer.utility";

const initialState = {
  reservationCharges: null,
  manageReservationResponse: null,
  loading: false,
  error: null
};
const getReservationChargesStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
const getReservationChargesSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    reservationCharges: action.reservationCharges,
    error: null,
    loading: false
  });
};
const getReservationChargesFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false
  });
};
const getReservationChargesReset = (state, action) => {
  return reducerUpdateObject(state, {
    reservationCharges: null
  });
};

const manageReservationChargesStart = (state, action) => {
  return reducerUpdateObject(state, {
    manageReservationResponse: null,
    error: null,
    loading: true
  });
};
const manageReservationChargesSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    manageReservationResponse: action.manageReservationResponse,
    error: null,
    loading: false
  });
};
const manageReservationChargesFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false
  });
};
const manageReservationChargesReset = (state, action) => {
  return reducerUpdateObject(state, {
    manageReservationResponse: null
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_RESERVATION_CHARGES_START:
      return getReservationChargesStart(state, action);
    case actionTypes.GET_RESERVATION_CHARGES_SUCCESS:
      return getReservationChargesSuccess(state, action);
    case actionTypes.GET_RESERVATION_CHARGES_FAIL:
      return getReservationChargesFail(state, action);
    case actionTypes.GET_RESERVATION_CHARGES_RESET:
      return getReservationChargesReset(state, action);

    case actionTypes.MANAGE_RESERVATION_CHARGES_START:
      return manageReservationChargesStart(state, action);
    case actionTypes.MANAGE_RESERVATION_CHARGES_SUCCESS:
      return manageReservationChargesSuccess(state, action);
    case actionTypes.MANAGE_RESERVATION_CHARGES_FAIL:
      return manageReservationChargesFail(state, action);
    case actionTypes.MANAGE_RESERVATION_CHARGES_RESET:
      return manageReservationChargesReset(state, action);

    default:
      return state;
  }
};
export default reducer;

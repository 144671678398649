import React, { Component, useState, useRef, useEffect } from "react";
import Hover from "./lib/hover";
import Trigger from "./lib/trigger";
import PropTypes from "prop-types";
import "./rocks-tooltip.scss";

export const RocksTooltip = (props) => {
  //const [styles, setStyles] = useState({});
  const [hoverComponentStyle, setHoverComponentStyle] = useState({
    display: "none",
    position: "absolute",
  });
  const renderItem = (item, index) => {
    if (item.type.name === "Trigger" || item.props.type === "trigger") {
      return <Trigger key={index}>{item}</Trigger>;
    } else if (item.type.name === "Hover" || item.props.type === "hover") {
      return <Hover key={index}>{item}</Hover>;
    }
  };
  const setVisibility = (flag) => {
    let updatedStyles = null;
    if (flag) {
      updatedStyles = { ...hoverComponentStyle, display: "block" };
    } else {
      updatedStyles = { ...hoverComponentStyle, display: "none" };
    }
    setHoverComponentStyle(updatedStyles);
  };
  const getCursorPos = (e) => {
    let win = window,
      doc = document,
      docElem = doc.documentElement,
      body = doc.getElementsByTagName("body")[0],
      modalWindow = doc.getElementsByClassName("modal-dialog");

    let isModal = false; //!!modalWindow.length;
    let modalHeight = isModal ? modalWindow[0].clientHeight : null;
    let x = win.innerWidth || docElem.clientWidth || body.clientWidth;
    let y =
      modalHeight ||
      win.innerHeight ||
      docElem.clientHeight ||
      body.clientHeight;

    let cursorX = e.pageX;
    let cursorY = e.pageY;
    let dummyHeight = e.target.offsetHeight;
    if (
      props.otherOptions &&
      props.otherOptions.parentClass &&
      props.otherOptions.parentClass === "line-clamp" &&
      e.target.textContent &&
      e.target.textContent.length > (props.otherOptions.textLength || 140)
    ) {
      dummyHeight = dummyHeight / 2;
    }
    if (cursorY + e.target.offsetHeight + 50 > y) {
      cursorY = cursorY - dummyHeight - 50;
    }
    let parentCoordinate = e.target.offsetParent.getBoundingClientRect();
    if (props.otherOptions && props.otherOptions.tooltipId) {
      parentCoordinate = document
        .getElementById(props.otherOptions.tooltipId)
        .offsetParent.getBoundingClientRect();
    }

    if (parentCoordinate) {
      if (
        e.target.offsetParent.nodeName &&
        e.target.offsetParent.nodeName === "TD"
      ) {
        parentCoordinate = e.target.offsetParent.offsetParent.getBoundingClientRect();
        cursorX = cursorX - parentCoordinate.left;
        if (!isModal) {
          cursorY = cursorY - parentCoordinate.top;
          cursorY = cursorY < 0 ? cursorY * -1 : cursorY;
        }
      } else {
        cursorX = cursorX - parentCoordinate.left;
        if (!isModal) {
          cursorY = cursorY - parentCoordinate.top;
          cursorY = cursorY < 0 ? cursorY * -1 : cursorY;
        }
      }
    }
    let {
      options: { followCursor, shiftX, shiftY },
    } = props;
    let updatedStyles = null;
    if (!followCursor) {
      return;
    }
    if (isNaN(shiftX)) {
      shiftX = 0;
    }
    if (isNaN(shiftY)) {
      shiftY = 0;
    }
    cursorY = cursorY + dummyHeight + 15;
    if (props.otherOptions && props.otherOptions.isShiftingReq) {
      cursorY = props.otherOptions.isTopZero
        ? 0
        : cursorY + props.otherOptions.cursorY;
      cursorX = cursorX + props.otherOptions.shiftX || 0;
    }
    updatedStyles = {
      ...hoverComponentStyle,
      top: cursorY,
      left: cursorX,
      display: "block",
      mouseXCordinate: e.pageX,
    };
    setHoverComponentStyle(updatedStyles);
  };
  let childrenWithProps = [];
  for (let child of props.children) {
    if (child.props) {
      if (child.type.name === "Trigger" || child.props.type === "trigger") {
        childrenWithProps.push(
          React.cloneElement(child, {
            setVisibility: setVisibility,
            getCursorPos: getCursorPos,
            otherOptions: props.otherOptions,
          })
        );
      } else if (child.type.name === "Hover" || child.props.type === "hover") {
        childrenWithProps.push(
          React.cloneElement(child, {
            styles: hoverComponentStyle,
            setVisibility: setVisibility,
            getCursorPos: getCursorPos,
            otherOptions: props.otherOptions,
          })
        );
      }
    }
  }
  return childrenWithProps.map((item, index) => renderItem(item, index));
};

export { Trigger, Hover };
export default RocksTooltip;

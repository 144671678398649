import * as actionTypes from "../actions/actionTypes";
import { reducerUpdateObject } from "./reducer.utility";

const initialState = {
  error: null,
  loading: false,
  searcRolesPermissionsMemberResponse: null,
  orgRolesDataResponse: null,
  addOrgRolesDataResponse: null,
  editOrgRolesDataResponse: null,
  deleteOrgRolesDataResponse: null,
  getOrgPermissionsDataResponse: null,
  editOrgPermissionsDataResponse: null
};
const doRolesPermissionMemberSearchStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
const doRolesPermissionMemberSearchSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    searcRolesPermissionsMemberResponse:
      action.searcRolesPermissionsMemberResponse,
    error: null,
    loading: false
  });
};
const doRolesPermissionMemberSearchFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false
  });
};
const doRolesPermissionMemberSearchReset = (state, action) => {
  return reducerUpdateObject(state, { searcRolesPermissionsMemberResponse: null });
};

const getOrgRolesDataStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
const getOrgRolesDataSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    orgRolesDataResponse: action.orgRolesDataResponse,
    error: null,
    loading: false
  });
};
const getOrgRolesDataFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false
  });
};
const getOrgRolesDataReset = (state, action) => {
  return reducerUpdateObject(state, { orgRolesDataResponse: null });
};

//Add Role Reducer
const addOrgRolesDataStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
const addOrgRolesDataSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    addOrgRolesDataResponse: action.addOrgRolesDataResponse,
    error: null,
    loading: false
  });
};
const addOrgRolesDataFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false
  });
};
const addOrgRolesDataReset = (state, action) => {
  return reducerUpdateObject(state, { addOrgRolesDataResponse: null });
};

//Edit Role Reducer
const editOrgRolesDataStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
const editOrgRolesDataSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    editOrgRolesDataResponse: action.editOrgRolesDataResponse,
    error: null,
    loading: false
  });
};
const editOrgRolesDataFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false
  });
};
const editOrgRolesDataReset = (state, action) => {
  return reducerUpdateObject(state, { editOrgRolesDataResponse: null });
};

//Delete Role Reducer
const deleteOrgRolesDataStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
const deleteOrgRolesDataSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    deleteOrgRolesDataResponse: action.deleteOrgRolesDataResponse,
    error: null,
    loading: false
  });
};
const deleteOrgRolesDataFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false
  });
};
const deleteOrgRolesDataReset = (state, action) => {
  return reducerUpdateObject(state, { deleteOrgRolesDataResponse: null });
};

//Get Org Permissions Reducer
const getOrgPermissionsDataStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
const getOrgPermissionsDataSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    getOrgPermissionsDataResponse: action.getOrgPermissionsDataResponse,
    error: null,
    loading: false
  });
};
const getOrgPermissionsDataFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false
  });
};
const getOrgPermissionsDataReset = (state, action) => {
  return reducerUpdateObject(state, { getOrgPermissionsDataResponse: null });
};

//Edit Org Permissions Reducer
const editOrgPermissionsDataStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
const editOrgPermissionsDataSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    editOrgPermissionsDataResponse: action.editOrgPermissionsDataResponse,
    error: null,
    loading: false
  });
};
const editOrgPermissionsDataFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false
  });
};
const editOrgPermissionsDataReset = (state, action) => {
  return reducerUpdateObject(state, { editOrgPermissionsDataResponse: null });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SEARCH_ROLES_PERMISSIONS_START:
      return doRolesPermissionMemberSearchStart(state, action);
    case actionTypes.SEARCH_ROLES_PERMISSIONS_SUCCESS:
      return doRolesPermissionMemberSearchSuccess(state, action);
    case actionTypes.SEARCH_ROLES_PERMISSIONS_FAIL:
      return doRolesPermissionMemberSearchFail(state, action);
    case actionTypes.SEARCH_ROLES_PERMISSIONS_RESET:
      return doRolesPermissionMemberSearchReset(state, action);

    case actionTypes.GET_ORG_ROLES_START:
      return getOrgRolesDataStart(state, action);
    case actionTypes.GET_ORG_ROLES_SUCCESS:
      return getOrgRolesDataSuccess(state, action);
    case actionTypes.GET_ORG_ROLES_FAIL:
      return getOrgRolesDataFail(state, action);
    case actionTypes.GET_ORG_ROLES_RESET:
      return getOrgRolesDataReset(state, action);

    case actionTypes.ADD_ORG_ROLES_START:
      return addOrgRolesDataStart(state, action);
    case actionTypes.ADD_ORG_ROLES_SUCCESS:
      return addOrgRolesDataSuccess(state, action);
    case actionTypes.ADD_ORG_ROLES_FAIL:
      return addOrgRolesDataFail(state, action);
    case actionTypes.ADD_ORG_ROLES_RESET:
      return addOrgRolesDataReset(state, action);

    case actionTypes.EDIT_ORG_ROLES_START:
      return editOrgRolesDataStart(state, action);
    case actionTypes.EDIT_ORG_ROLES_SUCCESS:
      return editOrgRolesDataSuccess(state, action);
    case actionTypes.EDIT_ORG_ROLES_FAIL:
      return editOrgRolesDataFail(state, action);
    case actionTypes.EDIT_ORG_ROLES_RESET:
      return editOrgRolesDataReset(state, action);

    case actionTypes.DELETE_ORG_ROLES_START:
      return deleteOrgRolesDataStart(state, action);
    case actionTypes.DELETE_ORG_ROLES_SUCCESS:
      return deleteOrgRolesDataSuccess(state, action);
    case actionTypes.DELETE_ORG_ROLES_FAIL:
      return deleteOrgRolesDataFail(state, action);
    case actionTypes.DELETE_ORG_ROLES_RESET:
      return deleteOrgRolesDataReset(state, action);

    case actionTypes.GET_ORG_PERMISSIONS_START:
      return getOrgPermissionsDataStart(state, action);
    case actionTypes.GET_ORG_PERMISSIONS_SUCCESS:
      return getOrgPermissionsDataSuccess(state, action);
    case actionTypes.GET_ORG_PERMISSIONS_FAIL:
      return getOrgPermissionsDataFail(state, action);
    case actionTypes.GET_ORG_PERMISSIONS_RESET:
      return getOrgPermissionsDataReset(state, action);

    case actionTypes.EDIT_ORG_PERMISSIONS_START:
      return editOrgPermissionsDataStart(state, action);
    case actionTypes.EDIT_ORG_PERMISSIONS_SUCCESS:
      return editOrgPermissionsDataSuccess(state, action);
    case actionTypes.EDIT_ORG_PERMISSIONS_FAIL:
      return editOrgPermissionsDataFail(state, action);
    case actionTypes.EDIT_ORG_PERMISSIONS_RESET:
      return editOrgPermissionsDataReset(state, action);

    default:
      return state;
  }
};
export default reducer;

import React from "react";
import SearchItemInput from "./search-item-input";
import {
  prepareBasicSearchList,
  prepareAdvancedSearchList,
} from "../search-utility/search-form-utility";
import { isNullOrEmpty } from "../../../shared/utility";

const SearchItemsContainer = (props) => {
  let searchCtrlList = [];
  if (props.isAdvancedSearch) {
    searchCtrlList = prepareAdvancedSearchList(props.searchInputCtrls);
  } else {
    searchCtrlList = prepareBasicSearchList(props.searchInputCtrls);
  }
  const getCbRadioListHtml = (cbRadioList) => {
    let cbRadioHtml = cbRadioList.map((cbRadioInput) => {
      return (
        <div className="icheck-primary" key={cbRadioInput.id}>
          <input
            type={cbRadioInput.elementType}
            id={cbRadioInput.id}
            name={cbRadioInput.elementConfig.name}
            checked={props.searchInputCtrls[cbRadioInput.id].value}
            onChange={(event) =>
              props.changed(event, cbRadioInput.id, cbRadioInput.elementType)
            }
          />
          <label htmlFor={cbRadioInput.id}>
            <span>
              {cbRadioInput.id === "SearchByScheduledDate"
                ? props.pageUrl === "/rental"
                  ? "Rental Dates"
                  : "Reservation Dates"
                : cbRadioInput.labelInput.labeltext}
            </span>
          </label>
        </div>
      );
    });
    return cbRadioHtml;
  };
  let topRB = "";
  if (props.topCbRadioList) {
    topRB = getCbRadioListHtml(props.topCbRadioList);
    topRB = <div className="search-input-container__rb">{topRB}</div>;
  }
  let bottomCB = "";
  if (props.bottomCbRadioList) {
    bottomCB = getCbRadioListHtml(props.bottomCbRadioList);
    bottomCB = (
      <div className="search-input-row">
        <div className="cbRadioListRow__column">{bottomCB}</div>
      </div>
    );
  }
  let advancedHtml = "";
  if (props.isAdvancedSearch) {
    advancedHtml = (
      <div className="serachLegend" onClick={props.showAdvancedBasic}>
        Basic Search
        <i className="fa fa-chevron-up pl-1" />
      </div>
    );
  } else if (props.isAdvancedSearch === false) {
    advancedHtml = (
      <div className="serachLegend" onClick={props.showAdvancedBasic}>
        Advanced Search
        <i className="fa fa-chevron-down pl-1" />
      </div>
    );
  } else {
    advancedHtml = <div></div>;
  }
  let formArea = searchCtrlList.map((searchRow, index) => {
    return (
      <div className="search-input-row" key={index}>
        {searchRow.map((formElement) => {
          return (
            <SearchItemInput
              key={formElement.id}
              idKey={formElement.id}
              elementType={formElement.config.elementType}
              elementConfig={formElement.config.elementConfig}
              value={formElement.config.value}
              invalid={!formElement.config.valid}
              shouldValidate={formElement.config.validation}
              touched={formElement.config.touched}
              labelInput={formElement.config.labelInput}
              formElement={formElement}
              changed={props.changed}
              submitHandler={props.submitHandler}
              resetHandler={props.resetHandler}
              index={index}
            />
          );
        })}
      </div>
    );
  });
  return (
    <div className="search-input-container" style={{ paddingTop: isNullOrEmpty(props.isAdvancedSearch) ? "0.8rem" : "" }}>
      {topRB}
      {advancedHtml}
      {formArea}
      {bottomCB}
    </div>
  );
};
export default SearchItemsContainer;

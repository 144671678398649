import React, { useEffect } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Draggable from "react-draggable";
import { useState } from "react";
import { useRef } from "react";
import TimerComponent from "../../UI/timer/timer-component";
import { Fragment } from "react";
import { OK_LBL } from "../../../shared/GlobalVar";

export const CountDownModal = (props) => {
  const [timer, setTimer] = useState(7);
  const intervalRef = useRef();

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      setTimer((prevTimer) => prevTimer - 1);
    }, 1000);
    if (timer === 0) {
      startSearch();
      clearInterval(intervalRef.current);
    }
  }, [timer === 0]);

  const startSearch = () => {
    props.closeModal();
    props.submitHandler();
  };

  const pClasses = "font-weight-bold text-justify pl-4 pr-4";
  const buttonClasses = "btn waves-effect waves-light btnSize";
  return (
    <Draggable handle=".cursor">
      <Modal
        isOpen={props.showModal}
        className="no-cursor"
        toggle={() => {
          props.closeModal();
        }}
      >
        <ModalHeader
          className="modal-header bg-primary modal-title text-white cursor"
          toggle={() => {
            props.closeModal();
          }}
        >
          <i className="fas fa-arrows-alt" /> Auto Search Confirm Modal
        </ModalHeader>
        <ModalBody>
          <Fragment>
            <p className={`${pClasses}`}>
            Search results for the existing Search Criteria may have changed.  We will run the search again in:
            </p>
            <TimerComponent
              timer={timer}
              divClass="text-center font-weight-bold mtr-2"
              timerSectionClasses={`fs-4 pr-3 text-primary`}
              unitSectionClasses={`fs-3 text-primary letter-spacing-1`}
              // timerSectionClasses={`fs-3-5 pr-1 ${
              //   timer % 2 === 0 ? "text-dark-red" : "text-primary"
              // }`}
              timerUnit="Seconds"
            />
            <p className={`${pClasses} pt-2`}>
               To skip this step and modify your Search Criteria click on "Cancel"
            </p>
          </Fragment>
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            className={`${buttonClasses}  btn-primary  ml-2`}
            onClick={() => startSearch()}
          >
            {OK_LBL}
          </button>
          <button
            type="button"
            className={`${buttonClasses}  btn-secondary  ml-1`}
            onClick={() => props.closeModal()}
          >
            Cancel
          </button>
        </ModalFooter>
      </Modal>
    </Draggable>
  );
};
export default CountDownModal;
import React from "react";
import { Link } from "react-router-dom";
const LinkButton = (props) => {
  return !props.isAnchorLink ? (
    <span
      className={props.containerClass}
      id={props.id}
      onClick={() => props.clicked ? props.searchType ? props.clicked(null, props.searchType) : props.clicked() : ""}
      title={props.title}
      tabIndex={props.tabindex || "0"}
      onKeyUp={(event) => props.onKeyPress && props.onKeyPress(event, props.searchType)}
      onKeyDown={(event) => props.onKeyDown && props.onKeyDown(event, props.searchType)}
    >
      {props.iconClass ? (
        <i className={props.iconClass}></i>
      ) : props.stackClass ? (
        <span className={props.stackClass}>
          <i className={props.faIconList[0]}></i>
          <i className={props.faIconList[1]}></i>
        </span>
      ) : (
        ""
      )}
      {props.btnLabel}
    </span>
  ) : (
    <Link
      to={{
        pathname: props.pathName,
        search: props.prefix,
        state: {
          breadCrumbList: props.breadCrumbList,
        },
      }}
      className={props.containerClass}
    >
      {props.iconClass ? (
        <i className={props.iconClass}></i>
      ) : props.stackClass ? (
        <span className={props.stackClass}>
          <i className={props.faIconList[0]}></i>
          <i className={props.faIconList[1]}></i>
        </span>
      ) : (
        ""
      )}
      {props.btnLabel}
    </Link>
  );
};
export default LinkButton;
import MemberSearchModel from "./search-models/member-search-model";
import VehicleSearchModel from "./search-models/vehicle-search-model";
import ReservationSearchModel from "./search-models/reservation-search-model";
import BillingSearchModel from "./search-models/billing-search-model";
import ActivitySearchModel from "./search-models/activity-search-model";
import MessageSearchModel from "./search-models/message-search-model";
import FeedbackSearchModel from "./search-models/feedback-search-model";
import { SearchAPI } from "../../../shared/GlobalVar";
import RolesPermissionsSearchModel from "./search-models/roles-permissions-search-model";
import MyVehicleSearchModel from "./search-models/my-vehicle-search-model";
import FavouriteVehicleSearchModel from "./search-models/favourite-vehicle-search-model";
import PageInfo from "./PageInfo";
import { ShuttleTripViewModel } from "../shuttle-app/view-model/shuttle-trip-view-model";
import { ShuttleSearchViewModel } from "../shuttle-app/view-model/shuttle-search-view-model";
import { LandmarkSearchViewModel } from "../shuttle-app/view-model/landmark-search-view-model";
import { SearchFleetUtilizationForecast } from "./fleet-utilization/search-fleet-utilization-forecast";

const convertMemberSearchToCommonSearchModel = (response) => {
  const data = {};
  const pageInfo = data.pageInfo ? { ...data.pageInfo } : new PageInfo();
  const searchList = [];
  if (response.TotalRecords) {
    response.PersonDetails.map((entity) => {
      searchList.push(new MemberSearchModel(entity));
    });
  }
  data.SearchList = searchList ? [...searchList] : [];
  data.StatusCode = response.StatusCode;
  data.StatusMsg = response.StatusMsg;
  data.TotalRecords = response.TotalRecords;
  data.PageInfo = pageInfo;
  return data;
};
const convertRolesPersmissionsSearchToCommonSearchModel = (response) => {
  const data = {};
  const searchList = [];
  if (response.TotalRecords) {
    response.PersonDetails.map((entity) => {
      searchList.push(new RolesPermissionsSearchModel(entity));
    });
  }
  data.SearchList = [...searchList];
  data.StatusCode = response.StatusCode;
  data.StatusMsg = response.StatusMsg;
  data.TotalRecords = response.TotalRecords;
  return data;
};
const convertResSearchToCommonSearchModel = (response) => {
  const data = {};
  const searchList = [];
  if (response.TotalRecords) {
    response.Reservations.map((entity) => {
      searchList.push(new ReservationSearchModel(entity));
    });
  }
  data.SearchList = [...searchList];
  data.StatusCode = response.StatusCode;
  data.StatusMsg = response.StatusMsg;
  data.TotalRecords = response.TotalRecords;
  return data;
};
const convertBillingSearchToCommonSearchModel = (response) => {
  const data = {};
  const searchList = [];
  if (response.TotalRecords) {
    response.Payments.map((entity) => {
      searchList.push(new BillingSearchModel(entity));
    });
  }
  data.SearchList = [...searchList];
  data.CurrencyCode = response.CurrencyCode;
  data.TotalPaidAmount = response.TotalPaidAmount;
  data.StatusCode = response.StatusCode;
  data.StatusMsg = response.StatusMsg;
  data.TotalRecords = response.TotalRecords;
  return data;
};
const convertVehicleSearchToCommonSearchModel = (response) => {
  const data = {};
  const searchList = [];
  if (response.TotalRecords) {
    response.Assets.map((entity) => {
      searchList.push(new VehicleSearchModel(entity));
    });
  }
  data.SearchList = [...searchList];
  data.StatusCode = response.StatusCode;
  data.StatusMsg = response.StatusMsg;
  data.TotalRecords = response.TotalRecords;
  return data;
};
const convertMyVehicleSearchToCommonSearchModel = (response) => {
  const data = {};
  const searchList = [];
  if (response.TotalRecords) {
    response.Assets.map((entity) => {
      searchList.push(new MyVehicleSearchModel(entity));
    });
  }
  data.SearchList = [...searchList];
  data.StatusCode = response.StatusCode;
  data.StatusMsg = response.StatusMsg;
  data.TotalRecords = response.TotalRecords;
  return data;
};
const convertFavouriteVehicleSearchToCommonSearchModel = (response) => {
  const data = {};
  const searchList = [];
  if (response.TotalRecords) {
    response.Assets.map((entity) => {
      searchList.push(new FavouriteVehicleSearchModel(entity));
    });
  }
  data.SearchList = [...searchList];
  data.StatusCode = response.StatusCode;
  data.StatusMsg = response.StatusMsg;
  data.TotalRecords = response.TotalRecords;
  return data;
};
const convertActivitySearchToCommonSearchModel = (response) => {
  const data = {};
  const searchList = [];
  if (response.TotalRecords) {
    response.ActivityInfoList.map((entity) => {
      searchList.push(new ActivitySearchModel(entity));
    });
  }
  data.SearchList = [...searchList];
  data.StatusCode = response.StatusCode;
  data.StatusMsg = response.StatusMsg;
  data.TotalRecords = response.TotalRecords;
  return data;
};
const convertFeedbackSearchToCommonSearchModel = (response) => {
  const data = {};
  const searchList = [];
  if (response.TotalRecords) {
    response.Feedbacks.map((entity) => {
      searchList.push(new FeedbackSearchModel(entity));
    });
  }
  data.SearchList = [...searchList];
  data.StatusCode = response.StatusCode;
  data.StatusMsg = response.StatusMsg;
  data.TotalRecords = response.TotalRecords;
  return data;
};
const convertMessageSearchToCommonSearchModel = (response) => {
  const data = {};
  const searchList = [];
  if (response.TotalRecords) {
    response.Messages.map((entity) => {
      searchList.push(new MessageSearchModel(entity));
    });
  }
  data.SearchList = [...searchList];
  data.StatusCode = response.StatusCode;
  data.StatusMsg = response.StatusMsg;
  data.TotalRecords = response.TotalRecords;
  return data;
};
const convertServiceTaskSearchToCommonSearchModel = (response) => {
  const data = {};
  const searchList =
    response.Tasks &&
    response.Tasks.map((entity) => {
      const temp = JSON.parse(JSON.stringify(entity));
      temp.StallNumber = temp.StallNumber
        ? temp.StallNumber.replace(/\r\n|\n/, "")
        : null;
      temp.Notes = temp.Notes ? temp.Notes.replace(/\r\n|\n/, "") : null;
      return temp;
    });
  data.SearchList = searchList ? [...searchList] : [];
  data.StatusCode = response.StatusCode;
  data.StatusMsg = response.StatusMsg;
  data.TotalRecords = response.TotalRecords;
  return data;
};
const convertToShuttleSearchViewModel = (response) => {
  const data = {};
  data.SearchList = [];
  if (response.pagination && response.pagination.totalCount) {
    response.shuttleSummaries.map((entity) => {
      data.SearchList.push(new ShuttleSearchViewModel(entity));
    });
  }
  data.StatusCode =
    response.message &&
    response.message.messageType &&
    response.message.messageType === "SUCCESS"
      ? 0
      : 1;
  data.StatusMsg =
    response.message && response.message.messageType
      ? response.message.displayMessage
      : "";
  data.TotalRecords = response.pagination && response.pagination.totalCount;
  return data;
};
const convertToLandMarkSearchViewModel = (response) => {
  const data = {};
  data.SearchList = [];
  if (response.pagination && response.pagination.totalCount) {
    response.landmarkSummaries &&
      response.landmarkSummaries.map((entity) => {
        data.SearchList.push(new LandmarkSearchViewModel(entity));
      });
  }
  data.StatusCode =
    response.message &&
    response.message.messageType &&
    response.message.messageType === "SUCCESS"
      ? 0
      : 1;
  data.StatusMsg =
    response.message && response.message.messageType
      ? response.message.displayMessage
      : "";
  data.TotalRecords = response.pagination && response.pagination.totalCount;
  return data;
};
const convertToShuttleTripSearchViewModel = (response) => {
  const data = {};
  data.SearchList = [];
  if (response.pagination && response.pagination.totalCount) {
    response.tripSummaries &&
      response.tripSummaries.map((entity) => {
        data.SearchList.push(new ShuttleTripViewModel(entity));
      });
  }
  data.StatusCode =
    response.message &&
    response.message.messageType &&
    response.message.messageType === "SUCCESS"
      ? 0
      : 1;
  data.StatusMsg =
    response.message && response.message.messageType
      ? response.message.displayMessage
      : "";
  data.TotalRecords = response.pagination && response.pagination.totalCount;
  return data;
};
const convertToFleetUtilizationForecastModel = (response) => {
  const data = {};
  data.SearchList = [];
  if (response.pagination && response.pagination.totalCount) {
    response.fleetUtilizationForecasts &&
      response.fleetUtilizationForecasts.map((entity) => {
        data.SearchList.push(new SearchFleetUtilizationForecast(entity));
      });
  }
  data.StatusCode =
    response.message &&
    response.message.messageType &&
    response.message.messageType === "SUCCESS"
      ? 0
      : 1;
  data.StatusMsg =
    response.message && response.message.messageType
      ? response.message.displayMessage
      : "";
  data.TotalRecords = response.pagination ? response.pagination.totalCount : 0;
  return data;
};
export const getCommonSearchModel = function (response, apiName) {
  if (apiName === SearchAPI.SEARCH_MEMBER) {
    return convertMemberSearchToCommonSearchModel(response);
  }
  if (apiName === SearchAPI.SEARCH_ROLES_PERMISSIONS_MEMBER) {
    return convertRolesPersmissionsSearchToCommonSearchModel(response);
  } else if (
    apiName === SearchAPI.SEARCH_RESERVATION ||
    apiName === SearchAPI.SEARCH_RENTAL ||
    apiName === SearchAPI.SEARCH_MEMBER_RESERVATION ||
    apiName === SearchAPI.SEARCH_MEMBER_VEHICLE_RESERVATION
  ) {
    return convertResSearchToCommonSearchModel(response);
  } else if (apiName === SearchAPI.SEARCH_TRANSACTIONS) {
    return convertBillingSearchToCommonSearchModel(response);
  } else if (apiName === SearchAPI.SEARCH_VEHICLE) {
    return convertVehicleSearchToCommonSearchModel(response);
  } else if (apiName === SearchAPI.SEARCH_MY_VEHICLE) {
    return convertMyVehicleSearchToCommonSearchModel(response);
  } else if (apiName === SearchAPI.SEARCH_FAVOURITES) {
    return convertFavouriteVehicleSearchToCommonSearchModel(response);
  } else if (apiName === SearchAPI.SEARCH_ACTIVITY_LOG) {
    return convertActivitySearchToCommonSearchModel(response);
  } else if (apiName === SearchAPI.SEARCH_FEEDBACK) {
    return convertFeedbackSearchToCommonSearchModel(response);
  } else if (apiName === SearchAPI.SEARCH_MESSAGES) {
    return convertMessageSearchToCommonSearchModel(response);
  } else if (apiName === SearchAPI.SEARCH_SERVICE_TASK) {
    return convertServiceTaskSearchToCommonSearchModel(response);
  } else if (apiName === SearchAPI.SEARCH_READY_LINE_AGENT) {
    return convertServiceTaskSearchToCommonSearchModel(response);
  } else if (apiName === SearchAPI.SEARCH_SHUTTLE) {
    return convertToShuttleSearchViewModel(response);
  } else if (apiName === SearchAPI.SEARCH_LANDMARK) {
    return convertToLandMarkSearchViewModel(response);
  } else if (apiName === SearchAPI.SEARCH_SHUTTLE_TRIP) {
    return convertToShuttleTripSearchViewModel(response);
  } else if (apiName === SearchAPI.SEARCH_FLEET_UTILIZATION_FORECAST) {
    return convertToFleetUtilizationForecastModel(response);
  }
};

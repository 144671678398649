import React from "react";
import Autosuggest from "react-autosuggest";
import { getMobileCountry } from "../../../shared/json/MobileCountry";
import "./autosuggetion.scss";
const languages = getMobileCountry();

function escapeRegexCharacters(str) {
  return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
}

function getSuggestions(value) {
  let letterRegex = /^[A-Za-z]+$/;

  if (Number.isInteger(parseInt(value[0]))) {
    value = "+" + value;
  }
  const escapedValue = escapeRegexCharacters(value.trim());

  if (escapedValue === "") {
    return [];
  }

  const regex = new RegExp("^" + escapedValue, "i");

  return value.match(letterRegex)
    ? languages.filter(language => regex.test(language.countryName))
    : languages.filter(language => regex.test(language.value));
}

function getSuggestionValue(suggestion) {
  return suggestion.value;
}

function renderSuggestion(suggestion) {
  return <span>{suggestion.label}</span>;
}

class MobileAutoSuggestionInput extends React.Component {
    state = {
      value: "",
      countryCode: "",
      suggestions: []
    };
  componentDidMount() {
    if (this.props.value && this.props.value.CountryCode) {
      const mObj = JSON.parse(JSON.stringify(this.props.value));
      this.setState({
        countryCode: mObj.CountryCode,
        value: "+" + mObj.CountryCode.toString()
      });
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.value &&
      this.props.value.CountryCode &&
      this.props.value.CountryCode != prevState.countryCode
    ) {
      this.setState({
        countryCode: this.props.value.CountryCode,
        value: this.props.value.CountryCode.toString()
      });
    }
  }
  onChange = (event, { newValue, method }) => {
    this.setState({
      value: newValue
    },()=>{
      if(!newValue){
       this.props.renderMobileAutoSueggestion(this.props.name, null);
      }
    });
  };

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: getSuggestions(value)
    });
  };

  onSuggestionsClearRequested = (event) => {
    this.setState({
      suggestions: []
    });
  };
  onSuggestionSelected = (
    event,
    { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }
  ) => {
    this.props.renderMobileAutoSueggestion(this.props.name, suggestion);
  };

  render() {
    const { value, suggestions } = this.state;
    const inputProps = {
      disabled:this.props.disabled ? true :false,
      value:value
      ? value && String(value).includes("+", 0)
        ? value
        : isNaN(value)?value: "+" + value
      : "",
      onChange: this.onChange
    };

    return (
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        //shouldRenderSuggestions={true}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
        onSuggestionSelected={this.onSuggestionSelected}
        highlightFirstSuggestion={true}
      />
    );
  }
}
export default MobileAutoSuggestionInput;

import { FoxCustomerInsurance } from "../domain/fox-customer-insurance";
import { FoxDrivingLicense } from "../domain/fox-driving-license";
import { FoxPhone } from "../domain/fox-phone";

export const FoxCustomerInfoRequest = function (data, isPayLater) {
  if (!data) data = {};
  this.email = data.email || null;
  this.password = data.password || null;
  //this.customerId = data.customerId || null;
  this.renterId = data.renterId || null;
  //this.foxCredit = data.foxCredit || null;
  this.firstName = data.firstName || null;
  this.middleName = data.middleName || null;
  this.lastName = data.lastName || null;
  this.driversLicense = new FoxDrivingLicense(data.license, true);
  this.insurance = new FoxCustomerInsurance(data.insurance);
  if (data.insurance.insuranceExpirationDate) {
    this.insurance.insuranceExpirationDate =
      data.insurance.insuranceExpirationDate; //moment(data.insurance.insuranceExpirationDate).format("MM:DD:YYYY:HH:mm");
  }
  this.addresses = [];
  if (data.primaryAddress) {
    data.primaryAddress.primary = true;
    this.addresses.push(data.primaryAddress);
  }
  this.phoneNumbers = [];
  if (data.mobilePhone && data.mobilePhone.number) {
    data.mobilePhone.type = "Mobile";
    this.phoneNumbers.push(new FoxPhone(data.mobilePhone));
  }
  if (data.homePhone && data.homePhone.number) {
    data.homePhone.type = "Home";
    this.phoneNumbers.push(new FoxPhone(data.homePhone));
  }
  if (data.workPhone && data.workPhone.number) {
    data.workPhone.type = "Work";
    this.phoneNumbers.push(new FoxPhone(data.workPhone));
  }
  this.dob = data.dob || null;
  //this.dob = "1984-03-28";
  this.emailDeals = data.emailDeals || false;
  this.textDeals = data.textDeals || false;
  this.reservationCount = data.reservationCount || 0;
  this.externalId = data.externalId || null;
  this.xpress = data.xpress || false;
  this.payNow = isPayLater ? false : true;
  this.companyName = data.companyName || null;
  this.designation = data.designation || null;
};

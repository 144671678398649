const AddDamageInfoRequest = function (memberId, reservationId, memberAssetId) {
  this.MemberId = memberId || null;
  this.ReservationId = reservationId || null;
  this.MemberAssetId = memberAssetId || null;
  this.ImageData = null;
  this.ImageExt = null;
  this.DamageLocation = null;
  this.Memo = null;
  this.Resolved = null;
  this.OriginalAmount = null;
  this.MaintenanceRequired = null;
  this.IsComplaint = null;
};
export default AddDamageInfoRequest;

import React, { Fragment, useEffect, useState } from "react";
import Draggable from "react-draggable";
import { useDispatch, useSelector } from "react-redux";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { showNotification } from "../../../components/UI/Toaster/Toaster";
import { AddQuickMemberRequest } from "../../../services/entities/requests/AddQuickMemberRequest";
import {
  convertDateTimeString,
  getBasicToastResponse,
  getFullName,
} from "../../../shared/utility";
import { getMemberLabelByOrgRentalType } from "../../../shared/utility/system-utility";
import {
  getConflictedMemberList,
  getConflictedMemberListReset,
} from "../../../store/actions/index";
import { createAddEditQuickMemberRequest } from "../MemberHelper";
export const ConflictedMemberListModal = (props) => {
  const conlictedMemberData = useSelector(
    (state) => state.memberReducer.conlictedMemberData
  );
  const [conflictedMemberList, setConflictedMemberList] = useState([]);
  const [selectMemberEntity, setSelectMemberEntity] = useState(
    new AddQuickMemberRequest()
  );
  const dispatch = useDispatch();
  useEffect(() => {
    if (!props.addQuickMemberInput && !props.addQuickMemberInput.EmailAddress) {
      props.closeModal();
    }
    searchConflictedMember();
  }, []);
  useEffect(() => {
    if (!conlictedMemberData) {
      return;
    }
    if (conlictedMemberData && conlictedMemberData.StatusCode === 0) {
      if (
        !conlictedMemberData.ConflictedMemberList ||
        conlictedMemberData.ConflictedMemberList.length === 0
      ) {
        const blackList = [];
        blackList.push(
          createAddEditQuickMemberRequest(props.addQuickMemberInput)
        );
        props.addBlackListedPersons(blackList);
        props.closeModal();
      } else {
        setConflictedMemberList([...conlictedMemberData.ConflictedMemberList]);
      }
    } else {
      showNotification(getBasicToastResponse(conlictedMemberData));
      dispatch(getConflictedMemberListReset());
      props.closeModal();
    }
  }, [conlictedMemberData]);
  const searchConflictedMember = () => {
    const searchConflictedMemReq = {
      FirstName: props.addQuickMemberInput.FirstName,
      LastName: props.addQuickMemberInput.LastName,
      EmailAddress: props.addQuickMemberInput.EmailAddress,
      MobilePhone: props.addQuickMemberInput.MobilePhone
        ? props.addQuickMemberInput.MobilePhone.Number
        : null,
      LicenseNumber: props.addQuickMemberInput?.DrivingLicense?.DrivingLicenseNumber || null,
    };
    dispatch(getConflictedMemberListReset());
    dispatch(getConflictedMemberList(searchConflictedMemReq));
  };
  const onUpdateMemberDataForConflictedMember = (keepOriginal) => {
    const blackList = [];
    if (keepOriginal) {
      blackList.push(createAddEditQuickMemberRequest(selectMemberEntity));
    } else {
      blackList.push(
        createAddEditQuickMemberRequest(props.addQuickMemberInput)
      );
    }
    props.addBlackListedPersons(blackList);
    props.closeModal();
  };
  return conflictedMemberList.length > 0 ? (
    <Draggable handle=".cursor">
      <Modal
        isOpen={props.showModal}
        className="no-cursor"
        toggle={() => {
          props.closeModal();
        }}
        style={{ minWidth: "1000px" }}
      >
        <ModalHeader
          className="modal-header bg-primary modal-title text-white cursor"
          toggle={() => {
            props.closeModal();
          }}
        >
          <i className="fas fa-arrows-alt" />
          {"Conflict " + getMemberLabelByOrgRentalType()}(s)
        </ModalHeader>
        <ModalBody
          style={{ maxHeight: "600px", minHeight: "200px" }}
          className="tableScroll"
        >
          <div className="row">
            <h1>
              We have found the following customer profile in our records.
              Please select a customer:
            </h1>
            <div className="col-lg-12 ml-0 mr-0 pl-0 pr-0 mb-2 mt-4">
              <Fragment>
                <table className="table table-striped">
                  <thead className="thead-primary">
                    <th></th>
                    <th style={{ width: "30%" }}>Member Name</th>
                    <th style={{ width: "20%" }}>License No</th>
                    <th style={{ width: "20%" }}>Email</th>
                    <th style={{ width: "20%" }}>Date Of Birth</th>
                  </thead>
                  <tbody>
                    {conflictedMemberList.map((memberObj) => {
                      return (
                        <tr key={memberObj.MemberId}>
                          <td>
                            <div className="icheck-primary">
                              <input
                                name="cbSelectConflicted"
                                type="checkbox"
                                id={
                                  "addDriver-CbSelectConflicted" +
                                  memberObj.MemberId
                                }
                                onChange={() =>
                                  setSelectMemberEntity(
                                    new AddQuickMemberRequest(
                                      memberObj,
                                      "PERSON_INFO"
                                    )
                                  )
                                }
                              />
                              <label
                                htmlFor={
                                  "addDriver-CbSelectConflicted" +
                                  memberObj.MemberId
                                }
                              >
                                &nbsp;
                              </label>
                            </div>
                          </td>
                          <td>
                            {getFullName(
                              memberObj.FirstName,
                              memberObj.LastName,
                              memberObj.MiddleName
                            )}
                          </td>
                          <td>
                            {memberObj.DrivingLicense.DrivingLicenseNumber ||
                              "--"}
                          </td>
                          <td>{memberObj.EmailAddress || "--"}</td>
                          <td>
                            {convertDateTimeString(
                              memberObj.DrivingLicense.DateOfBirth
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </Fragment>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            className="btn btn-primary waves-effect waves-light btnSize ml-2"
            onClick={() => onUpdateMemberDataForConflictedMember(false)}
          >
            Keep Original
          </button>
          <button
            type="button"
            className="btn btn-secondary  waves-effect waves-light btnSize ml-2"
            onClick={() => onUpdateMemberDataForConflictedMember(true)}
          >
            Update Information
          </button>
        </ModalFooter>
      </Modal>
    </Draggable>
  ) : (
    ""
  );
};
export default ConflictedMemberListModal;

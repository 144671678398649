import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Draggable from "react-draggable";
export const LateChargeConfirmModal = (props) => {
  const onLateChargeFunctionality = (type) => {
    props.doLateChargeFunctionality(type);
    props.closeModal();
  };
  return (
    <Draggable handle=".cursor">
      <Modal
        isOpen={props.showModal}
        className="no-cursor"
        toggle={() => {
          props.closeModal();
        }}
      >
        <ModalHeader
          className="modal-header bg-primary modal-title text-white cursor"
          toggle={() => {
            props.closeModal();
          }}
        >
          <i className="fas fa-key" /> Confirm
        </ModalHeader>
        <ModalBody>
          <label class="col-sm-12 col-form-label font-weight-bold">
            Vehicle is being returned late, do you want to charge for extra
            time?
          </label>
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            className="btn btn-primary waves-effect waves-light btnSize ml-2"
            onClick={() => onLateChargeFunctionality("NONE")}
          >
            No Extra Charge
          </button>
          <button
            type="button"
            className="btn btn-primary waves-effect waves-light btnSize ml-2"
            onClick={() => onLateChargeFunctionality("NORMAL")}
          >
            Noramal Charge
          </button>
          <button
            type="button"
            className="btn btn-primary waves-effect waves-light btnSize ml-2"
            onClick={() => onLateChargeFunctionality("LATE")}
          >
            Late Charge
          </button>
        </ModalFooter>
      </Modal>
    </Draggable>
  );
};
export default LateChargeConfirmModal;

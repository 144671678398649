import ReactHtmlParser from "react-html-parser";
import moment from "moment";
import { getFormattedValidationMsg, hasPermission } from "../../shared/utility";
import {
  AdminDateFromat,
  FleetClassName,
  ReserveSyncOverrideParam,
} from "../../shared/utility/enum-utility";
import PermissionName from "../../shared/Permissions";

const has_OverridePermission_PickupThreshold = hasPermission(
  PermissionName.CC_UPDATE_PICKUP_THRESHOLD
);
const has_OverridePermission_BookThruThreshold = hasPermission(
  PermissionName.CC_UPDATE_BOOK_THRU_THRESHOLD
);
const has_OverridePermission_Walkins = hasPermission(
  PermissionName.CC_UPDATE_WALKINS_COUNT
);
const has_OverridePermission_NoShow = hasPermission(
  PermissionName.CC_UPDATE_NO_SHOW_FACTOR
);
const has_ViewPermission_Fleet_Utilization_Logs = hasPermission(
  PermissionName.CC_SEARCH_FLEET_UTILIZATION_LOGS
);
const has_Manual_OverridePermission = hasPermission(
  PermissionName.CC_UPDATE_FLEET_FORECAST
);

const permissionMap = {
  defaultPickupThresholdPercent:
    has_Manual_OverridePermission && has_OverridePermission_PickupThreshold,
  defaultBookThruThresholdPercent:
    has_Manual_OverridePermission && has_OverridePermission_BookThruThreshold,
  noShowFactor: has_Manual_OverridePermission && has_OverridePermission_NoShow,
  walkinsCount: has_Manual_OverridePermission && has_OverridePermission_Walkins,
};

export const validateFleetForeCastEntity = (
  entity,
  parameter,
  prevVal,
  reason,
  isReset
) => {
  let msg = "";
  if (entity) {
    if (!entity.fleetUtilizationForecastId) {
      msg = "Fleet Utilization Forecast Id";
    }
    if (!entity.manualOverrideReason) {
      if (msg) {
        msg += "<br/>";
      }
      msg += "Override Reason";
    } else if (!entity.manualOverrideOtherReason && reason === "OTHER") {
      if (msg) {
        msg += "<br/>";
      }
      msg += "Other Override Reason";
    }
    // if (
    //   !isReset &&
    //   entity[parameter] &&
    //   parseFloat(entity[parameter]) === parseFloat(prevVal)
    // ) {
    //   if (msg) {
    //     msg += "<br/>";
    //   }
    //   msg += "Override value should not be same";
    // }
    if (msg) {
      return getFormattedValidationMsg(msg);
    }
  }
  return msg ? ReactHtmlParser(msg) : "";
};
export const validateDefaultFleetForeCastEntity = (entity) => {
  let msg = "";
  if (entity) {
    if (
      entity.orgUnitFleetClassGroup &&
      !entity.orgUnitFleetClassGroup.orgUnitFleetClassGroupId
    ) {
      msg = "Fleet Class Group Id is missing";
    }
    if (msg) {
      return getFormattedValidationMsg(msg);
    }
  }
  return msg ? ReactHtmlParser(msg) : "";
};
export const renderReserveSyncParameter = (parameter, val) => {
  if (
    parameter === "defaultPickupThresholdPercent" ||
    parameter === "defaultBookThruThresholdPercent" ||
    parameter === "noShowFactor" ||
    parameter === "walkinsCount"
  ) {
    return (
      <span>
        {val}
        <i className="fa fa-star" style={{ marginLeft: "5px" }}></i>
      </span>
    );
  }
  return val;
};
export const isFleetCellEditable = (parameter) => {
  if (
    parameter === "defaultPickupThresholdPercent" ||
    parameter === "defaultBookThruThresholdPercent" ||
    parameter === "noShowFactor" ||
    parameter === "walkinsCount"
  ) {
    return true;
  }
  return false;
};
export const isFleetCellValueWithPercentage = (parameter) => {
  if (
    parameter === "defaultPickupThresholdPercent" ||
    parameter === "defaultBookThruThresholdPercent" ||
    parameter === "noShowFactor" ||
    parameter === "utilizationFactorPercent"
  ) {
    return true;
  }
  return false;
};
export const getParameterCellClassNames = (parameter) => {
  let classNames = "left";
  const isEditable = isFleetCellEditable(parameter);
  if (isEditable) {
    return classNames + " edit-right";
  }
  return classNames;
};
export const hasSpecificEditPermission = (parameter) => {
  return permissionMap[parameter] || false;
};

export const getThresHoldClassNames = (parameter, data, classNames) => {
  if (parameter === "defaultPickupThresholdPercent") {
    if (data.pickupStatus) {
      classNames = classNames + " " + FleetClassName[data.pickupStatus];
    }
  } else if (parameter === "defaultBookThruThresholdPercent") {
    if (data.bookThruStatus) {
      classNames = classNames + " " + FleetClassName[data.bookThruStatus];
    }
  }
  return classNames;
};
export const getEditCellClassName = (parameter, data) => {
  let classNames = "broder-edit-cell right";
  if (!hasSpecificEditPermission(parameter)) {
    classNames = classNames + " non-editable";
  }
  if (
    parameter === "defaultPickupThresholdPercent" ||
    parameter === "defaultBookThruThresholdPercent"
  ) {
    return getThresHoldClassNames(parameter, data, classNames);
  }
  return classNames;
};
export const getGeneralCellClassName = (parameter, data) => {
  let classNames = "right";
  if (
    parameter === "override" &&
    data.lastManualOverrideTimestamp &&
    has_ViewPermission_Fleet_Utilization_Logs
  ) {
    return classNames + " td-link";
  } else if (parameter === "utilizationFactorPercent") {
    return classNames + " td-forecast-cell";
  } else if (
    parameter === "pickupCount" &&
    has_OverridePermission_PickupThreshold
  ) {
    return classNames + " edit-bottom";
  }
  return classNames;
};
export const getOverrideParameterCellValue = (cellVal, data) => {
  if (data && data.lastManualOverrideTimestamp) {
    return moment(
      data.lastManualOverrideTimestamp,
      AdminDateFromat.FOX_API_DATE_TIME
    ).format(AdminDateFromat.COLUMN_FORMAT);
  }
  return "No";
};
export const getOverrideReasonTooltip = (forcastData) => {
  const tdTitle =
    forcastData && forcastData.manualOverrideReason
      ? forcastData.manualOverrideReason === "OTHER"
        ? forcastData.manualOverrideOtherReason
        : forcastData.manualOverrideReasonDisplayName
      : "";
  return tdTitle ? (
    <div className="tooltip-body">
      <label>{tdTitle}</label>
      <br />
      <span>
        <b>Last Override Date:</b>
        {moment(forcastData.lastTimestamp).format(
          AdminDateFromat.TOOLTIP_DATE_TIME_FORMAT
        )}
      </span>
    </div>
  ) : (
    ""
  );
};
const getManualOverrideCellVal = (data, value, parameter) => {
  if (data && data.lastManualOverrideTimestamp) {
    const manualVal = data[ReserveSyncOverrideParam[parameter]];
    return manualVal || value;
  }
  return "value";
};
export const getFleetCellData = (parameter, data, value, isEditable) => {
  isEditable = !isEditable ? isFleetCellEditable(parameter) : isEditable;
  const isPercentage = isFleetCellValueWithPercentage(parameter);
  let cellVal =
    isEditable && data.lastManualOverrideTimestamp
      ? getManualOverrideCellVal(data, value, parameter)
      : value;
  if (parameter === "override") {
    cellVal = getOverrideParameterCellValue(cellVal, data);
  } else {
    cellVal = cellVal
      ? isPercentage
        ? (cellVal || 0) + " %"
        : cellVal
      : isPercentage
      ? "0%"
      : 0;
  }
  return {
    cellValue: cellVal,
    classNames: isEditable
      ? getEditCellClassName(parameter, data)
      : getGeneralCellClassName(parameter, data),
    tooltipClassNames:
      data.lastManualOverrideTimestamp &&
      data[ReserveSyncOverrideParam[parameter]]
        ? "td-reset td-tooltip"
        : null,
  };
};
export const getOverrideReasonsByParameter = (parameter, data) => {
  if (data && data.message && data.message.messageType === "SUCCESS") {
    if (parameter === "defaultPickupThresholdPercent") {
      return data.pickupReasons || [];
    } else if (parameter === "defaultBookThruThresholdPercent") {
      return data.bookThroughReasons || [];
    } else if (parameter === "noShowFactor") {
      return data.noShowReasons || [];
    } else if (parameter === "walkinsCount") {
      return data.walkinsReasons || [];
    }
  }
  return [];
};
export const getUpdatedOverrideManualValue = (parameter, data) => {
  if (parameter === "defaultPickupThresholdPercent") {
    return data.manualPickupThresholdPercent;
  } else if (parameter === "defaultBookThruThresholdPercent") {
    return data.manualBookThruThresholdPercent;
  } else if (parameter === "noShowFactor") {
    return data.manualNoShowFactor;
  } else if (parameter === "walkinsCount") {
    return data.manualWalkinsCount;
  }
};
export const getExportTypeList = () => {
  const exportTypeList = [];
  exportTypeList.push({ key: "", value: "Select" });
  exportTypeList.push({ key: "CSV", value: "csv" });
  exportTypeList.push({ key: "XLS", value: "xls" });
  // exportTypeList.push({ key: "PDF", value: "pdf" });
  return exportTypeList;
};

import { IconCtrlModel } from "./icon-ctrl-model";

export const InputCtrlModel = function (data) {
    if (!data) data = {};
    this.type = data.type || "";
    this.name = data.name || "";
    this.id = data.id || "";
    this.value = data.value || null;
    this.required = data.required || false;
    this.disabled = data.disabled || false;
    this.placeHolder= data.placeHolder || null;
    this.className = data.className || null;
    this.style = data.style || null;
    this.options=data.options || [];
    this.checked=data.checked || false;
    this.displayValue = data.displayValue || "";
    this.radioList=data.radioList || [];
    this.modelData=data.modelData || null;
    this.dateTimeConfig=data.dateTimeConfig || null;
    this.objectType=data.objectType || null;
    this.iconData = new IconCtrlModel(data.iconData); 
    this.inputClearAction = data.inputClearAction || null;
    this.valueField = data.valueField || null;
    this.textField = data.textField || null;
    this.defaultValue = data.defaultValue || null;
    this.caption = data.caption || ""
    this.inputIcon = data.inputIcon || ""
    this.tooltipText = data.tooltipText || ""
};
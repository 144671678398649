import React from "react";
import ReactHtmlParser from "react-html-parser";
import TooltipItem from "./TooltipItem";
import { getNoteTooltipHtml } from "../../shared/utility";

export const TooltipItemForLongLabel = (props) => {
  const getTooltipBody = () => {
    if (props.content) {
      return (
        <TooltipItem
          toolTipId={props.toolTipId}
          renderableHtml={ReactHtmlParser(
            getNoteTooltipHtml(props.content, props.height || "250px")
          )}
        />
      );
    } else {
      const elementId = document.getElementById(props.toolTipId);
      const showTooltip = elementId
        ? elementId.scrollWidth > elementId.offsetWidth
        : false;
      const labelCaption = elementId ? elementId.textContent : "";
      return showTooltip ? (
        <TooltipItem
          toolTipId={props.toolTipId}
          renderableHtml={ReactHtmlParser(
            getNoteTooltipHtml(
              props.content ? props.content : labelCaption,
              props.height || "250px"
            )
          )}
        />
      ) : (
        ""
      );
    }
  };

  return getTooltipBody();
};

import React from "react";
import Rating from "react-rating";
import FileUploader from "../../../components/image-uploader/file-uploader";
import { noVehiclePhoto } from "../../../shared/AppImages";
const VehicleSummaryCard = (props) => {
  const vehicleData = props.vehicleData || {};
  return (
    <div className="card">
      <div className="card-body">
        <FileUploader
          vehicleId={vehicleData.VehicleId}
          uploaderType="vehicle-photo"
          photoData={
            vehicleData.Images && vehicleData.Images.length > 0
              ? vehicleData.Images[0]
              : noVehiclePhoto
          }
          uploaderData={props.userPhotoInfo}
          isUploadBtnRequired={true}
          isNameRequird={false}
          isOnlyImg={true}
          changeFileTitle="Change Image"
          loadUploaderFileData={props.loadNewVehiclePhoto}
          confirmBtnClick={props.changeVehiclePhoto}
          disabled={true}
        />
        <div className="card-body text-center pt-0">
          {/* {props.isSubMenu ? ( */}
          <div className="list-inline">
            {/* {getFormattedVehicleName(
              summaryReservationData.PersonAssetUnitNo,
              summaryReservationData.Make,
              summaryReservationData.Model,
              summaryReservationData.Year,
              true
            )} */}
            <hr />
            <Rating
              initialRating={
                vehicleData && vehicleData.OverallRating
                  ? vehicleData.OverallRating
                  : 0
              }
              readonly={!props.isEditMode}
              //readonly={true}
              stop={5}
              fractions={2}
              emptySymbol={[
                "far fa-star fa-2x",
                "far fa-star fa-2x",
                "far fa-star fa-2x",
                "far fa-star fa-2x",
                "far fa-star fa-2x",
              ]}
              fullSymbol={[
                "fas fa-star fa-2x text-warning",
                "fas fa-star fa-2x text-warning",
                "fas fa-star fa-2x text-warning",
                "fas fa-star fa-2x text-warning",
                "fas fa-star fa-2x text-warning",
              ]}
            />
            <hr />
          </div>
          <div className="col-lg-12 p-0">
            <h3>{vehicleData.AvailabilityStatus}</h3>
            <div className="form-group row">
              <label className="col-sm-12 col-form-label font-weight-bold text-left p-0">
                Unit No
              </label>
              <label className="col-sm-12 col-form-label  text-left p-0">
                {vehicleData.UnitNo || "N/A"}
              </label>
            </div>
            <div className="form-group row">
              <label className="col-sm-12 col-form-label font-weight-bold text-left p-0">
                Owner
              </label>
              <label className="col-sm-12 col-form-label  text-left p-0">
                {vehicleData.OwnerName || "N/A"}
              </label>
            </div>
            <div className="form-group row">
              <label className="col-sm-12 col-form-label font-weight-bold text-left p-0">
                Total Earnings This Month
              </label>
              <label className="col-sm-12 col-form-label  text-left p-0">
                {vehicleData.TotalEarningsThisMonth || "N/A"}
              </label>
            </div>
            <div className="form-group row">
              <label className="col-sm-12 col-form-label font-weight-bold text-left p-0">
                Total Earning
              </label>
              <label className="col-sm-12 col-form-label  text-left p-0">
                {vehicleData.TotalEarningsToDate || "N/A"}
              </label>
            </div>
            <div className="form-group row">
              <label className="col-sm-12 col-form-label font-weight-bold text-left p-0">
                Owner Fine(s)
              </label>
              <label className="col-sm-12 col-form-label  text-left p-0">
                {vehicleData.TotalOwnerFines || "N/A"}
              </label>
            </div>
            <div className="form-group row">
              <label className="col-sm-12 col-form-label font-weight-bold text-left p-0">
                Pending Requests
              </label>
              <label className="col-sm-12 col-form-label  text-left p-0">
                {vehicleData.TotalPendingRequest || "0"}
              </label>
            </div>
            <div className="form-group row">
              <label className="col-sm-12 col-form-label font-weight-bold text-left p-0">
                Pending Reservations
              </label>
              <label className="col-sm-12 col-form-label  text-left p-0">
                {vehicleData.TotalPendingReservation || "0"}
              </label>
            </div>
            <div className="form-group row">
              <label className="col-sm-12 col-form-label font-weight-bold text-left p-0">
                Rented Hours
              </label>
              <label className="col-sm-12 col-form-label  text-left p-0">
                {vehicleData.TotalRentedHours || "0"}
              </label>
            </div>
          </div>
          {/* {Utility.hasPermission(PermissionName.EDIT_VEHICLE) ? (
            <button
              type="button"
              className="btn btn-danger waves-effect waves-light m-1"
              onClick={props.openEditPage}
              disabled={!props.isSubMenu}
            >
              {props.isEditMode ? (
                <i className="fa fa-home"></i>
              ) : (
                <i
                  className="fas fa-pencil-alt pl-1 pr-1"
                  aria-hidden="true"
                ></i>
              )}
              <span>{props.isEditMode ? "Profile" : "Edit"}</span>
            </button>
          ) : (
            ""
          )} */}
        </div>
      </div>
    </div>
  );
};
export default VehicleSummaryCard;

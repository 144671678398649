const GetMemberOptions = function() {
  this.Employee = false;
  this.License = false;
  this.Insurance = false;
  this.CreditCard = false;
  this.Earnings = false;
  this.CountryCodes = false;
  this.Contact = false;
  this.Assets = false;
  this.Referals = false;
  this.Rating = false;
  this.Preferences = false;
  this.PrimaryStateCityZipList = false;
  this.BillingStateCityZipList = false;
  this.LicenseStateList = false;
};
export default GetMemberOptions;

import moment from 'moment';
import React, { useState } from 'react';
import RadioButton from '../../../../components/UI/Input/checkbox-radio/radio-button-ctrl';
import InputCtrl from '../../../../components/UI/Input/input-ctrl';
import InputWidgetCtrl from '../../../../components/UI/Input/input-widget-ctrl';
import { AdminDateFromat, DiscountType } from '../../../../shared/utility/enum-utility';

function DiscountContainer({ disabled, primaryFormData, setPrimaryFormData, discountList, storeCredit, showDiscountLoader }) {
    const [discountGroup, setDiscountGroup] = useState(DiscountType.COMPANY_CODE);
    const getDiscountCodeFromKey = () => {
        const discountObj = discountList.find(
            (element) => element.foxDiscountCode === primaryFormData.foxDiscountCode
        );
        return discountObj;
    }
    const onDiscountTypeChange = (event) => {
        setDiscountGroup(event.target.value);
        const ctrlName = event.target.value;
        const tempData = { ...primaryFormData };
        if (ctrlName === DiscountType.ONETIME) {
            tempData.foxDiscountCode = null;
            tempData.freeCreditAmountApplied = null;
            tempData.discount = null;
        } else if (ctrlName === DiscountType.STORE_CREDIT) {
            tempData.freeCreditAmountApplied = storeCredit;
            tempData.foxDiscountCode = null;
            tempData.discount = null;
        } else if (ctrlName === DiscountType.COMPANY_CODE) {
            const tempDiscount = { ...tempData.discount };
            tempDiscount.discountCode = null;
            tempData.discount = tempDiscount;
            tempData.foxDiscountCode = null;
            tempData.freeCreditAmountApplied = null;
        }
        setPrimaryFormData(tempData);
    }
    const onChange = (data, ctrlName, event) => {
        const tempData = { ...primaryFormData };
        if (ctrlName === DiscountType.ONETIME) {
            tempData.foxDiscountCode = data.foxDiscountCode;
            tempData.freeCreditAmountApplied = null;
            tempData.discount = null;
        } else if (ctrlName === DiscountType.STORE_CREDIT) {
            let { value, min, max } = event.target;
            value = Math.max(Number(min), Math.min(Number(max), Number(value)));
            tempData.freeCreditAmountApplied = value;
            tempData.foxDiscountCode = null;
            tempData.discount = null;
        } else if (ctrlName === DiscountType.COMPANY_CODE) {
            const tempDiscount = { ...tempData.discount };
            tempDiscount.discountCode = data;
            tempData.discount = tempDiscount;
            tempData.foxDiscountCode = null;
            tempData.freeCreditAmountApplied = null;
        }
        setPrimaryFormData(tempData);
    }
    const onClear = (ctrlName) => {
        const tempData = { ...primaryFormData };
        if (ctrlName === DiscountType.ONETIME) {
            tempData.foxDiscountCode = null;
        } else if (ctrlName === DiscountType.STORE_CREDIT) {
            tempData.freeCreditAmountApplied = null;
        } else if (ctrlName === DiscountType.COMPANY_CODE) {
            tempData.discount = null;
        }
        setPrimaryFormData(tempData);
    }
    return (
        <>
            <div className={`group-ctrl ${(disabled || !discountList?.length) && ' disabled-group'}`} title={!discountList?.length ? "No discount code found" : ""}>
                <RadioButton
                    name="discount-group"
                    checked={discountGroup === DiscountType.ONETIME && true}
                    onChange={onDiscountTypeChange}
                    value={DiscountType.ONETIME}
                    disabled={disabled || !discountList?.length}
                />
                <InputWidgetCtrl
                    id="foxDiscountCode"
                    name={DiscountType.ONETIME}
                    placeholder="One-time Discount Code"
                    data={discountList || []}
                    valueField="foxDiscountCode"
                    textField="foxDiscountCode"
                    loader={showDiscountLoader}
                    value={getDiscountCodeFromKey()}
                    disabled={disabled || discountGroup !== DiscountType.ONETIME}
                    onChange={onChange}
                    onClear={onClear}
                    iconClass="fas fa-award font-size-2"
                    valueComponent={({ item }) => (
                        <span>{item.foxDiscountCode || "Select"}</span>
                    )}
                    itemComponent={({ item }) => (
                        <>
                            <span>{!item.foxDiscountCode ? "" : item?.foxDiscountCode + ", Exp: " + moment(
                                item?.expiresOn,
                                AdminDateFromat.FOX_API_DATE_TIME
                            ).format(AdminDateFromat.COLUMN_FORMAT_WITH_TIME)}</span>
                            {/* {item?.key ? <hr /> : <br />} */}
                        </>
                    )}
                />
            </div>
            <div className={`group-ctrl ${(disabled || !storeCredit) && ' disabled-group'}`} title={!storeCredit ? "No store credit found" : ""}>
                <RadioButton
                    name="discount-group"
                    checked={discountGroup === DiscountType.STORE_CREDIT && true}
                    onChange={onDiscountTypeChange}
                    value={DiscountType.STORE_CREDIT}
                    disabled={disabled || !storeCredit}
                />
                <InputCtrl
                    name={DiscountType.STORE_CREDIT}
                    value={primaryFormData?.freeCreditAmountApplied}
                    type="number"
                    placeholder={`Available store Credits : ${storeCredit}`}
                    onChange={onChange}
                    onClear={onClear}
                    disabled={disabled || discountGroup !== DiscountType.STORE_CREDIT}
                    iconClass="fas fa-award font-size-2"
                    defaultconfig={{ min: 0, max: storeCredit }}
                />
            </div>
            <div className={`group-ctrl ${disabled && ' disabled-group'}`} >
                <RadioButton
                    name="discount-group"
                    checked={discountGroup === DiscountType.COMPANY_CODE}
                    onChange={onDiscountTypeChange}
                    value={DiscountType.COMPANY_CODE}
                    disabled={disabled}
                />
                <InputCtrl
                    name={DiscountType.COMPANY_CODE}
                    value={primaryFormData?.discount?.discountCode}
                    type="text"
                    placeholder="Company Number / Discount Code"
                    onChange={onChange}
                    onClear={onClear}
                    disabled={disabled || discountGroup !== DiscountType.COMPANY_CODE}
                    iconClass="fas fa-award font-size-2"
                />
            </div >
        </>
    )
}

export default DiscountContainer
import * as actionTypes from "./actionTypes";
import { invokeServerAPI } from "../../services/httpService";

export const searchDamageInfoStart = () => {
  return {
    type: actionTypes.GET_DAMAGE_START,
  };
};
export const searchDamageInfoSuccess = (response) => {
  return {
    type: actionTypes.GET_DAMAGE_SUCCESS,
    searchDamageResponse: response,
  };
};
export const searchDamageInfoFail = (error) => {
  return {
    type: actionTypes.GET_DAMAGE_FAIL,
    error: error,
  };
};
export const searchDamageInfoReset = () => {
  return {
    type: actionTypes.GET_DAMAGE_RESET,
  };
};

export const addDamageInfoStart = () => {
  return {
    type: actionTypes.ADD_DAMAGE_START,
  };
};
export const addDamageInfoSuccess = (response) => {
  return {
    type: actionTypes.ADD_DAMAGE_SUCCESS,
    addDamageResponse: response,
  };
};
export const addDamageInfoFail = (error) => {
  return {
    type: actionTypes.ADD_DAMAGE_FAIL,
    error: error,
  };
};
export const addDamageInfoReset = () => {
  return {
    type: actionTypes.ADD_DAMAGE_RESET,
  };
};

export const searchDamageInfo = (req) => {
  return (dispatch) => {
    dispatch(searchDamageInfoStart());
    invokeServerAPI("searchdamageinfo", req)
      .then((response) => {
        dispatch(searchDamageInfoSuccess(response));
      })
      .catch((err) => {
        //dispatch(searchDamageInfoFail(err));
      });
  };
};
export const addDamageInfo = (req) => {
  return (dispatch) => {
    dispatch(addDamageInfoStart());
    invokeServerAPI("adddamageinfo", req)
      .then((response) => {
        dispatch(addDamageInfoSuccess(response));
      })
      .catch((err) => {
        //dispatch(addDamageInfoFail(err));
      });
  };
};

import React, { useState } from "react";
import {
  getNoteTooltipHtml,
  sortByStringFromObject,
  isNullOrEmpty,
} from "../../shared/utility";
import { OK_LBL, Roles_Permissions } from "../../shared/GlobalVar";
import "../../sass/pages/_rolesPermissions.scss";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Draggable from "react-draggable";
import ReactHtmlParser from "react-html-parser";
import { useEffect } from "react";
import { createTooltip } from "../../shared/utility/tooltip-utility";

const PermissionsGrid = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(props.isModalOpen);
  const [isCategoryRequired, setIsCategoryRequired] = useState(
    props.isCategoryRequired
  );
  const [rolesPermisisonsData, setRolesPermisisonsData] = useState(
    props.rolesPermisisonsData
  );
  const [isPrereqModalOpen, setIsPrereqModalOpen] = useState(false);
  const [
    prerequisitePermissionsData,
    setPrerequisitePermissionsData,
  ] = useState(null);
  const [isRevokePrereqModalOpen, setIsRevokePrereqModalOpen] = useState(false);
  const [
    revokePrerequisitePermissionsData,
    setRevokePrerequisitePermissionsData,
  ] = useState(null);

  useEffect(() => {
    setRolesPermisisonsData(props.rolesPermisisonsData);
    setIsCategoryRequired(props.isCategoryRequired);
  }, [props.rolesPermisisonsData]);

  const resetSubModalInfo = () => {
    setIsPrereqModalOpen(false);
    setPrerequisitePermissionsData(null);
    setIsRevokePrereqModalOpen(false);
    setRevokePrerequisitePermissionsData(null);
  };

  const getPermissionsLegend = () => {
    let colSpan =
      props.permissionsType === Roles_Permissions.AppAccessToMember ? 2 : 3;
    let permissionLegendHtml = (
      <th colSpan={colSpan} rowSpan="2" className="roles-legend">
        <fieldset className="fieldset-legend">
          <legend>Legend</legend>
          <div className="legend-div">
            <div className="legend-child-div">
              <i className="fa fa-check legend-cb-margin" aria-hidden="true" />
              <span>Granted</span>
            </div>
            <div className="legend-child-div" style={{ marginLeft: "2%" }}>
              <i className="fa fa-times legend-cb-margin" aria-hidden="true" />
              <span style={{ marginLeft: "5px" }}>Revoked</span>
            </div>
            <div className="legend-child-div">
              <i
                className="fa fa-checkmark legend-cb-margin"
                aria-hidden="true"
              >
                &#xe801;
              </i>
              <span>Inherited</span>
            </div>
            <div className="legend-child-div" style={{ marginLeft: "2%" }}>
              <div className="checkBoxRolesPermisisons checkBoxRolesPermisisonsFont cb-modified legend-cb cursor-default" />
              <span style={{ marginLeft: "5px" }}>Modified</span>
            </div>
          </div>
        </fieldset>
      </th>
    );
    return permissionLegendHtml;
  };
  const getRolesLegend = () => {
    let colSpan =
      props.permissionsType === Roles_Permissions.PermissionsToRoles ? 3 : 2;
    let rolesLegendHtml = (
      <th colSpan={colSpan} rowSpan="2" className="roles-legend">
        <fieldset className="fieldset-legend">
          <legend>Legend</legend>
          <div className="legend-div">
            <div className="legend-child-div">
              <i className="fa fa-check legend-cb-margin" aria-hidden="true" />
              <span>Granted</span>
            </div>
            <div className="checkBoxRolesPermisisons checkBoxRolesPermisisonsFont cb-modified legend-cb cursor-default" />
            <span style={{ marginLeft: "5px" }}>Modified</span>
          </div>
        </fieldset>
      </th>
    );
    return rolesLegendHtml;
  };
  const selectAllRowsForCategoryDataDualStates = (
    event,
    columnData,
    className
  ) => {
    const dummy = { ...rolesPermisisonsData };
    let isChecked = false;
    if (className === "fa-minus" || className === "fa-null") {
      isChecked = true;
    }
    Object.keys(dummy.rowData).map((type, index1) => {
      Object.keys(dummy.rowData[type]).map((key, index2) => {
        if (!columnData.rowInfo[dummy.rowData[type][key].rowId] && isChecked) {
          columnData.rowInfo[dummy.rowData[type][key].rowId] = {
            granted: isChecked,
            new: true,
            modified: true,
          };
          if (!dummy.rowData[type][key]["columnInfo"]) {
            dummy.rowDat[type][key].columnInfo = {};
          }
          dummy.rowData[type][key].columnInfo[columnData.columnId] = {
            granted: isChecked,
            new: true,
            modified: true,
          };
        } else if (!isChecked) {
          delete columnData.rowInfo[dummy.rowData[type][key].rowId];
          delete dummy.rowData[type][key].columnInfo[columnData.columnId];
        }
      });
    });
    dummy.columnData[columnData.columnId] = columnData;
    setRolesPermisisonsData(dummy);
  };
  const selectAllRowsForCategoryDataNormalStates = (
    event,
    columnData,
    className
  ) => {
    const dummy = { ...rolesPermisisonsData };
    let isChecked = null;
    if (className === "fa-check") {
      isChecked = false;
    } else if (
      className === "fa-minus" ||
      className === "fa-null" ||
      className === "fa-checkmark"
    ) {
      isChecked = true;
    }
    Object.keys(dummy.rowData).map((type, index1) => {
      Object.keys(dummy.rowData[type]).map((key, index2) => {
        if (!columnData.rowInfo[dummy.rowData[type][key].rowId]) {
          columnData.rowInfo[dummy.rowData[type][key].rowId] = {
            granted: isChecked,
            new: true,
            modified: true,
          };
          dummy.rowData[type][key].columnInfo[columnData.columnId] = {
            granted: isChecked,
            new: true,
            modified: true,
          };
        } else {
          columnData.rowInfo[dummy.rowData[type][key].rowId] = Object.assign(
            columnData.rowInfo[dummy.rowData[type][key].rowId] || {},
            {
              granted: isChecked,
              modified: true,
              serverOverRide: false,
            }
          );
          dummy.rowData[type][key].columnInfo[
            columnData.columnId
          ] = Object.assign(
            dummy.rowData[type][key].columnInfo[columnData.columnId] || {},
            {
              granted: isChecked,
              modified: true,
              serverOverRide: false,
            }
          );
        }
      });
    });
    dummy.columnData[columnData.columnId] = columnData;
    setRolesPermisisonsData(dummy);
  };
  const validatePrerequisitePermissionsAll = (
    event,
    rowType,
    rowData,
    className
  ) => {
    if (
      className === "fa-minus" ||
      className === "fa-times" ||
      className === "fa-null"
    ) {
      loadPrerequisiteAllPermissionsPopup(
        rowType,
        rowData,
        className,
        "fa-check"
      );
    } else if (className === "fa-check" || className === "fa-checkmark") {
      let nextIcon = props.isDualState ? "fa-null" : "fa-times";
      loadRevokePrerequisiteAllPermissionsPopup(
        rowType,
        rowData,
        className,
        nextIcon
      );
    }
  };
  const selectAllColumnsForCategoryDataDualStates = (
    rowType,
    rowData,
    className,
    prerequisitePermissionsIds
  ) => {
    let dummy = { ...rolesPermisisonsData };
    let isChecked = false;
    if (className === "fa-minus" || className === "fa-null") {
      isChecked = true;
    }
    dummy = doDataChangesforznonTypeBasedPermissionsALLDualStates(
      dummy,
      isChecked,
      rowData,
      rowType
    );
    if (prerequisitePermissionsIds.length > 0) {
      prerequisitePermissionsIds.map((data, index) => {
        let preRowData = rolesPermisisonsData.rowData[data.type][data.rowId];
        dummy = doDataChangesforznonTypeBasedPermissionsALLDualStates(
          dummy,
          isChecked,
          preRowData,
          data.type
        );
      });
    }
    setRolesPermisisonsData(dummy);
    resetSubModalInfo();
  };
  const doDataChangesforznonTypeBasedPermissionsALLDualStates = (
    dummy,
    isChecked,
    rowData,
    rowType
  ) => {
    Object.keys(dummy.columnData).map((key, index) => {
      if (!dummy.rowData[rowType][rowData.rowId]["columnInfo"]) {
        dummy.rowData[rowType][rowData.rowId]["columnInfo"] = {};
      }
      if (isChecked) {
        dummy.rowData[rowType][rowData.rowId].columnInfo[key] = {
          granted: isChecked,
          new: true,
          modified: true,
        };
        dummy.columnData[key].rowInfo[rowData.rowId] = {
          granted: isChecked,
          new: true,
          modified: true,
        };
      } else {
        delete dummy.rowData[rowType][rowData.rowId].columnInfo[key];
        delete dummy.columnData[key].rowInfo[rowData.rowId];
      }
    });
    return dummy;
  };

  const selectAllColumnsForCategoryDataNormalStates = (
    rowType,
    rowData,
    className,
    prerequisitePermissionsIds
  ) => {
    let dummy = { ...rolesPermisisonsData };
    let isChecked = null;
    if (className === "fa-check") {
      isChecked = false;
    } else if (
      className === "fa-minus" ||
      className === "fa-null" ||
      className === "fa-checkmark"
    ) {
      isChecked = true;
    }
    dummy = doDataChangesforTypeBasedPermissionsALL(
      dummy,
      isChecked,
      rowData,
      rowType
    );
    if (prerequisitePermissionsIds.length > 0) {
      prerequisitePermissionsIds.map((data, index) => {
        let preRowData = rolesPermisisonsData.rowData[data.type][data.rowId];
        dummy = doDataChangesforTypeBasedPermissionsALL(
          dummy,
          isChecked,
          preRowData,
          data.type
        );
      });
    }
    setRolesPermisisonsData(dummy);
    resetSubModalInfo();
  };
  const doDataChangesforTypeBasedPermissionsALL = (
    dummy,
    isChecked,
    rowData,
    rowType
  ) => {
    Object.keys(dummy.columnData).map((key, index) => {
      let dummyRow = rowType ? dummy.rowData[rowType] : dummy.rowData;

      if (!dummyRow[rowData.rowId].columnInfo[key]) {
        dummy.rowData[rowType][rowData.rowId].columnInfo[key] = {
          granted: isChecked,
          new: true,
          modified: true,
        };
        dummy.columnData[key].rowInfo[rowData.rowId] = {
          granted: isChecked,
          new: true,
          modified: true,
        };
      } else {
        dummy.rowData[rowType][rowData.rowId].columnInfo[key] = Object.assign(
          dummy.rowData[rowType][rowData.rowId].columnInfo[key] || {},
          {
            granted: isChecked,
            modified: true,
            serverOverRide: false,
          }
        );

        dummy.columnData[key].rowInfo[rowData.rowId] = Object.assign(
          dummy.columnData[key].rowInfo[rowData.rowId] || {},
          {
            granted: isChecked,
            modified: true,
            serverOverRide: false,
          }
        );
      }
    });
    return dummy;
  };
  const validatePrerequisitePermissionsSingle = (
    event,
    rowType,
    rowData,
    columnData,
    className
  ) => {
    let isInherited =
      Object.keys(rowData["columnInfo"][columnData.columnId]).length > 0
        ? rowData["columnInfo"][columnData.columnId]["inherited"]
          ? true
          : false
        : false;
    let hasServerOverRideTag =
      Object.keys(rowData["columnInfo"][columnData.columnId]).length > 0
        ? rowData["columnInfo"][columnData.columnId]["serverOverRide"] ===
          undefined
          ? false
          : true
        : false;
    let hasDependentPermission = rowData.dependantPermissions ? true : false;
    let isServerOverRide =
      Object.keys(rowData["columnInfo"][columnData.columnId]).length > 0
        ? rowData["columnInfo"][columnData.columnId]["serverOverRide"]
          ? true
          : false
        : false;

    if (className === "fa-minus" || className === "fa-null") {
      loadPrerequisiteAllPermissionsPopup(
        rowType,
        rowData,
        className,
        "fa-check",
        columnData
      );
    } else if (className === "fa-times") {
      loadPrerequisiteAllPermissionsPopup(
        rowType,
        rowData,
        className,
        isInherited
          ? "fa-check"
          : hasServerOverRideTag
          ? "fa-check"
          : "fa-null",
        columnData
      );
    } else if (className === "fa-checkmark" && !hasDependentPermission) {
      loadPrerequisiteAllPermissionsPopup(
        rowType,
        rowData,
        className,
        "fa-times",
        columnData
      );
    } else if (className === "fa-checkmark" && hasDependentPermission) {
      let nextIcon = props.isDualState ? "fa-null" : "fa-times";
      loadRevokePrerequisiteAllPermissionsPopup(
        rowType,
        rowData,
        className,
        nextIcon,
        columnData
      );
    } else if (className === "fa-check") {
      let nextIcon = props.isDualState
        ? "fa-null"
        : isInherited
        ? isServerOverRide
          ? "fa-times"
          : "fa-null"
        : "fa-times";
      loadRevokePrerequisiteAllPermissionsPopup(
        rowType,
        rowData,
        className,
        nextIcon,
        columnData
      );
    }
  };
  const selectSingleColumnForCategoryDataDualStates = (
    type,
    rowData,
    columnData,
    className,
    prerequisitePermissionsIds
  ) => {
    let dummy = { ...rolesPermisisonsData };
    dummy = doDataChangesforTypeBasedPermissionsSingleDualStates(
      type,
      rowData,
      columnData,
      className,
      dummy
    );
    if (prerequisitePermissionsIds.length > 0) {
      prerequisitePermissionsIds.map((data, index) => {
        let preRowData = rolesPermisisonsData.rowData[data.type][data.rowId];
        dummy = doDataChangesforTypeBasedPermissionsSingleDualStates(
          data.type,
          preRowData,
          columnData,
          className,
          dummy
        );
      });
    }
    setRolesPermisisonsData(dummy);
    resetSubModalInfo();
  };
  const doDataChangesforTypeBasedPermissionsSingleDualStates = (
    type,
    rowData,
    columnData,
    className,
    dummy
  ) => {
    if (columnData.rowInfo[rowData.rowId] && className === "fa-check") {
      delete columnData.rowInfo[rowData.rowId];
      delete rowData.columnInfo[columnData.columnId];
    } else {
      let data = {};
      if (className === "fa-null") {
        data.granted = true;
        data.new = true;
        data.modified = true;
      } else if (className === "fa-check") {
        delete columnData.rowInfo[rowData.rowId];
        delete rowData.columnInfo[columnData.columnId];
      }
      columnData.rowInfo[rowData.rowId] = data;
      rowData.columnInfo[columnData.columnId] = data;
    }
    dummy.rowData[type][rowData.rowId] = rowData;
    dummy.columnData[columnData.columnId] = columnData;
    return dummy;
  };
  const selectSingleColumnForCategoryDataNormalStates = (
    type,
    rowData,
    columnData,
    className,
    prerequisitePermissionsIds
  ) => {
    let dummy = { ...rolesPermisisonsData };

    dummy = doDataChangesforTypeBasedPermissionsSingle(
      type,
      rowData,
      columnData,
      className,
      dummy
    );

    if (prerequisitePermissionsIds.length > 0) {
      prerequisitePermissionsIds.map((data, index) => {
        let preRowData = rolesPermisisonsData.rowData[data.type][data.rowId];
        dummy = doDataChangesforTypeBasedPermissionsSingle(
          data.type,
          preRowData,
          columnData,
          className,
          dummy
        );
      });
    }
    setRolesPermisisonsData(dummy);
    resetSubModalInfo();
  };
  const doDataChangesforTypeBasedPermissionsSingle = (
    type,
    rowData,
    columnData,
    className,
    dummy
  ) => {
    if (columnData.rowInfo[rowData.rowId]) {
      if (columnData.rowInfo && columnData.rowInfo[rowData.rowId].inherited) {
        if (className === "fa-checkmark") {
          columnData.rowInfo[rowData.rowId].granted = false;
          columnData.rowInfo[rowData.rowId].modified = true;

          rowData.columnInfo[columnData.columnId].granted = false;
          rowData.columnInfo[columnData.columnId].modified = true;
        } else if (className === "fa-check") {
          columnData.rowInfo[rowData.rowId].granted = columnData.rowInfo[
            rowData.rowId
          ].serverOverRide
            ? false
            : null;
          columnData.rowInfo[rowData.rowId].modified = true;

          rowData.columnInfo[columnData.columnId].granted = columnData.rowInfo[
            rowData.rowId
          ].serverOverRide
            ? false
            : null;
          rowData.columnInfo[columnData.columnId].modified = true;
        } else if (className === "fa-times" || className === "fa-null") {
          columnData.rowInfo[rowData.rowId].granted = true;
          columnData.rowInfo[rowData.rowId].modified = true;

          rowData.columnInfo[columnData.columnId].granted = true;
          rowData.columnInfo[columnData.columnId].modified = true;
        }
      } else if (columnData.rowInfo && columnData.rowInfo[rowData.rowId].new) {
        if (className === "fa-check") {
          columnData.rowInfo[rowData.rowId].granted = false;
          columnData.rowInfo[rowData.rowId].modified = true;

          rowData.columnInfo[columnData.columnId].granted = false;
          rowData.columnInfo[columnData.columnId].modified = true;
        } else if (className === "fa-times") {
          delete columnData.rowInfo[rowData.rowId];
          delete rowData.columnInfo[columnData.columnId];
        } else if (className === "fa-null") {
          columnData.rowInfo[rowData.rowId].granted = true;
          columnData.rowInfo[rowData.rowId].modified = true;

          rowData.columnInfo[columnData.columnId].granted = true;
          rowData.columnInfo[columnData.columnId].modified = true;
        }
      } else if (
        columnData.rowInfo &&
        columnData.rowInfo[rowData.rowId].modified
      ) {
        if (className === "fa-check") {
          columnData.rowInfo[rowData.rowId].granted = false;
          columnData.rowInfo[rowData.rowId].modified = true;

          rowData.columnInfo[columnData.columnId].granted = false;
          rowData.columnInfo[columnData.columnId].modified = true;
        } else if (className === "fa-times" || className === "fa-null") {
          columnData.rowInfo[rowData.rowId].granted = true;
          columnData.rowInfo[rowData.rowId].modified = true;

          rowData.columnInfo[columnData.columnId].granted = true;
          rowData.columnInfo[columnData.columnId].modified = true;
        }
      } else {
        if (className === "fa-check") {
          columnData.rowInfo[rowData.rowId].granted = false;
          columnData.rowInfo[rowData.rowId].modified = true;

          rowData.columnInfo[columnData.columnId].granted = false;
          rowData.columnInfo[columnData.columnId].modified = true;
        } else if (className === "fa-times") {
          columnData.rowInfo[rowData.rowId].granted = null;
          columnData.rowInfo[rowData.rowId].modified = true;

          rowData.columnInfo[columnData.columnId].granted = null;
          rowData.columnInfo[columnData.columnId].modified = true;
        } else if (className === "fa-null") {
          columnData.rowInfo[rowData.rowId].granted = true;
          columnData.rowInfo[rowData.rowId].modified = true;

          rowData.columnInfo[columnData.columnId].granted = true;
          rowData.columnInfo[columnData.columnId].modified = true;
        }
      }
    } else {
      let data = {};
      if (className === "fa-null" || className === "fa-times") {
        data.granted = true;
        data.new = true;
        data.modified = true;
      } else if (className === "fa-check") {
        data.granted = false;
        data.new = true;
        data.modified = true;
      }
      columnData.rowInfo[rowData.rowId] = data;
      rowData.columnInfo[columnData.columnId] = data;
    }
    dummy.rowData[type][rowData.rowId] = rowData;
    dummy.columnData[columnData.columnId] = columnData;
    return dummy;
  };
  const getColumnHtml = () => {
    let columnData = { ...rolesPermisisonsData.columnData };
    let columnHtml = {
      columnHeader: "",
      columnAll: "",
    };
    columnHtml.columnHeader = Object.keys(columnData).map((userKey, index) => {
      return (
        <th className="header-td" key={index}>
          <div
            className="header-size"
            id={"columHeader_" + columnData[userKey].columnId}
          >
            {createTooltip(
              columnData[userKey].columnName,
              columnData[userKey].columnName,
              {
                forceShow: true,
                ttcClass: "tooltipContainer-max width-max-content",
                considerParent: true,
              }
            )}
          </div>
        </th>
      );
    });
    columnHtml.columnAll = Object.keys(columnData).map(
      (userKey, columnAllIndex) => {
        let className = isCategoryRequired
          ? getAllColumnCbClassName(columnData[userKey])
          : getAllColumnDefaultCbClassName(columnData[userKey]);
        let tooltipId = "topAllCheckbox_" + columnData[userKey].columnId;
        return (
          <td align="center" key={columnAllIndex}>
            <div
              className="checkBoxRolesPermisisons userAllCheckBox"
              onClick={(event) =>
                isCategoryRequired
                  ? !props.isDualState
                    ? selectAllRowsForCategoryDataNormalStates(
                        event,
                        columnData[userKey],
                        className
                      )
                    : selectAllRowsForCategoryDataDualStates(
                        event,
                        columnData[userKey],
                        className
                      )
                  : selectAllDefaultRows(event, columnData[userKey], className)
              }
              id={tooltipId}
            >
              {correctIconHtml(className, tooltipId)}
            </div>
          </td>
        );
      }
    );
    return columnHtml;
  };

  const fillEmptyPermissionColumn = (data) => {
    let columnData = rolesPermisisonsData
      ? { ...rolesPermisisonsData.columnData }
      : {};
    Object.keys(data).map((key, index1) => {
      if (
        Object.keys(data[key].columnInfo).length !==
        Object.keys(columnData).length
      ) {
        var result = Object.keys(columnData).filter(function (e) {
          return Object.keys(data[key].columnInfo).indexOf(e) === -1;
        });
        if (result.length > 0) {
          result.forEach((element, index2) => {
            data[key].columnInfo[element] = {};
          });
        }
      }
    });
    return data;
  };

  const fillEmptyPermissionRows = (data) => {
    let rowData = { ...rolesPermisisonsData.rowData };
    Object.keys(data).map((key1, index1) => {
      Object.keys(rowData).map((key2, index2) => {
        Object.keys(rowData[key2]).map((element, index3) => {
          if (data["rowInfo"] === undefined || data["rowInfo"] === null) {
            data["rowInfo"] = {};
          }
        });
      });
    });
    return data;
  };

  const loadRowData = () => {
    let rowData = { ...rolesPermisisonsData.rowData };
    let rowHtml = "";
    rowHtml = Object.keys(rowData).map((rowType, index) => {
      rowData[rowType] = fillEmptyPermissionColumn(rowData[rowType]);
      const sortedRowList = sortByStringFromObject(rowData[rowType], "rowName");
      return loadCategoryRowCheckBox(sortedRowList, rowType);
    });
    return rowHtml;
  };
  const loadCategoryRowCheckBox = (rowDataList, rowType) => {
    let commonCB = rowDataList.map((rowData, rowIndex) => {
      let rowName = (
        <td
          className={
            rowIndex === 0
              ? "text-left border-top-1 border-right-1 border-left-lightgray-1"
              : "text-left border-right-1 border-left-lightgray-1"
          }
        >
          <div style={{ width: "160px", wordBreak: "break-all" }} title={rowData.rowDesc} >{rowData.rowName}</div>
        </td>
      );
      let allPermisisonCb = (
        <td
          style={{ width: "5px", borderTop: "1px solid #ddd" }}
          className={
            rowIndex === 0 ? "border-top-1 border-right-1" : "border-right-1"
          }
        >
          &nbsp;
        </td>
      );
      if (rolesPermisisonsData.totalColumns > 1) {
        let className = getAllPermisisonCbClassName(rowData);
        let tooltipId = "leftAllCheckbox_" + rowData.rowId;
        allPermisisonCb = (
          <td
            className={
              rowIndex === 0 ? "border-top-1 border-right-1" : "border-right-1"
            }
            style={{ width: "50px" }}
            align="center"
          >
            <div
              className="checkBoxRolesPermisisons permissionAllCheckBox"
              onClick={(event) =>
                validatePrerequisitePermissionsAll(
                  event,
                  rowType,
                  rowData,
                  className
                )
              }
              id={tooltipId}
            >
              {correctIconHtml(className, tooltipId)}
            </div>
          </td>
        );
      }
      let columnData = { ...rolesPermisisonsData.columnData };
      let columnCb;
      columnCb = Object.keys(columnData).map((columnKey, columnIndex) => {
        columnData[columnKey] = fillEmptyPermissionRows(columnData[columnKey]);
        let columnClassName = "fa fa-null";
        if ("rowInfo" in columnData[columnKey]) {
          if (columnData[columnKey].rowInfo[rowData.rowId]) {
            let rowInfo = columnData[columnKey].rowInfo[rowData.rowId];
            let clumnTd = "";
            if (rowInfo.granted) {
              columnClassName = "fa-check";
              clumnTd = (
                <i className="fa fa-check checkBoxRolesPermisisonsFont" />
              );
            } else if (rowInfo.granted === false) {
              columnClassName = "fa-times";
              clumnTd = (
                <i className="fa fa-times checkBoxRolesPermisisonsFont" />
              );
            }
            if (rowInfo.inherited) {
              if (isNullOrEmpty(rowInfo.granted)) {
                columnClassName = "fa-checkmark";
                clumnTd = (
                  <i className="fa checkBoxRolesPermisisonsFont fa-checkmark">
                    &#xe801;
                  </i>
                );
              }
            } else {
              if (isNullOrEmpty(rowInfo.granted)) {
                columnClassName = "fa-null";
                clumnTd = <i className="fa fa-null" />;
              }
            }
            let divCbClassName = rowInfo.modified
              ? "checkBoxRolesPermisisons cb-modified"
              : "checkBoxRolesPermisisons";
            return (
              <td
                key={columnIndex}
                align="center"
                className={
                  rowIndex === 0
                    ? "border-top-1 detail-td border-right-lightgray-1"
                    : "detail-td border-right-lightgray-1"
                }
              >
                <div
                  className={divCbClassName}
                  onClick={(event) =>
                    validatePrerequisitePermissionsSingle(
                      event,
                      rowType,
                      rowData,
                      columnData[columnKey],
                      columnClassName
                    )
                  }
                  id={
                    "permissionRowCheckbox_" +
                    columnData[columnKey].columnId +
                    "_" +
                    rowData.rowId
                  }
                >
                  {createTooltip(
                    clumnTd,
                    ReactHtmlParser(
                      "Name: " +
                        columnData[columnKey].columnName +
                        "</br>" +
                        "Target Element: " +
                        rowData.rowName
                    ),
                    {
                      forceShow: true,
                      ttcClass: "tooltipContainer-max width-max-content",
                      considerParent: true,
                    }
                  )}
                </div>
              </td>
            );
          } else {
            return (
              <td
                key={columnIndex}
                className={
                  rowIndex === 0
                    ? "border-top-1 detail-td border-right-lightgray-1"
                    : "detail-td border-right-lightgray-1"
                }
                align="center"
              >
                <div
                  className="checkBoxRolesPermisisons"
                  onClick={(event) =>
                    validatePrerequisitePermissionsSingle(
                      event,
                      rowType,
                      rowData,
                      columnData[columnKey],
                      "fa-null"
                    )
                  }
                  id={
                    "permissionRowCheckbox_" +
                    columnData[columnKey].columnId +
                    "_" +
                    rowData.rowId
                  }
                >
                  {createTooltip(
                    <i className="fa fa-null checkBoxRolesPermisisonsFont">
                      &nbsp;
                    </i>,
                    ReactHtmlParser(
                      "Name: " +
                        columnData[columnKey].columnName +
                        "</br>" +
                        "Target Element: " +
                        rowData.rowName
                    ),
                    {
                      forceShow: true,
                      ttcClass: "tooltipContainer-max width-max-content",
                      considerParent: true,
                    }
                  )}
                </div>
              </td>
            );
          }
        }
      });
      if (
        rowType &&
        rowIndex === 0 &&
        Roles_Permissions.AppAccessToMember !== props.permissionsType
      ) {
        return (
          <tr key={rowIndex + "tr"}>
            <td
              className="roles-type border-top-1"
              rowSpan={rowDataList.length}
            >
              <div style={{ width: "100px" }}>{rowType}</div>
            </td>
            {rowName}
            {allPermisisonCb}
            {columnCb}
          </tr>
        );
      } else {
        return (
          <tr key={rowIndex + "tr"}>
            {rowName}
            {allPermisisonCb}
            {columnCb}
          </tr>
        );
      }
    });
    return commonCB;
  };
  const correctIconHtml = (className, tooltipId) => {
    if (className === "fa-checkmark") {
      return createTooltip(
        <i
          className="fa fa-checkmark checkBoxRolesPermisisonsFont"
          aria-hidden="true"
        >
          &#xe801;
        </i>,
        className === "fa-check"
          ? "Deselect All"
          : className === "fa-times"
          ? "Clear All"
          : "Select All",
        {
          forceShow: true,
          ttcClass: "tooltipContainer-max width-max-content",
          considerParent: true,
        }
      );
    } else {
      return createTooltip(
        <i
          className={"fa checkBoxRolesPermisisonsFont " + className}
          aria-hidden="true"
        />,
        className === "fa-check"
          ? "Deselect All"
          : className === "fa-times"
          ? "Clear All"
          : "Select All",
        {
          forceShow: true,
          ttcClass: "tooltipContainer-max width-max-content",
          considerParent: true,
        }
      );
    }
  };
  const getAllColumnCbClassName = (columnData) => {
    if (!columnData.rowInfo || Object.keys(columnData.rowInfo).length === 0) {
      return "fa-null";
    } else if (
      Object.keys(columnData.rowInfo).length !== rolesPermisisonsData.totalRows
    ) {
      return "fa-minus";
    } else {
      let grantedCount = 0;
      let revokedCount = 0;
      let inheritedCount = 0;
      Object.keys(columnData.rowInfo).map((key, index) => {
        if (columnData.rowInfo[key].granted) {
          grantedCount++;
        } else if (columnData.rowInfo[key].granted === false) {
          revokedCount++;
        } else if (columnData.rowInfo[key].inherited) {
          inheritedCount++;
        }
      });
      if (rolesPermisisonsData.totalRows === grantedCount) {
        return "fa-check";
      } else if (rolesPermisisonsData.totalRows === revokedCount) {
        return "fa-times";
      } else if (rolesPermisisonsData.totalRows === inheritedCount) {
        return "fa-checkmark";
      } else if (inheritedCount || revokedCount || grantedCount) {
        return "fa-minus";
      } else {
        return "fa-null";
      }
    }
  };
  const getAllPermisisonCbClassName = (sortedRowData) => {
    if (
      !sortedRowData.columnInfo ||
      Object.keys(sortedRowData.columnInfo).length === 0
    ) {
      return "fa-null";
    } else if (
      Object.keys(sortedRowData.columnInfo).length !==
      Object.keys(rolesPermisisonsData.columnData).length
    ) {
      return "fa-minus";
    } else {
      let grantedCount = 0;
      let revokedCount = 0;
      let inheritedCount = 0;
      Object.keys(sortedRowData.columnInfo).map((key, index) => {
        if (sortedRowData.columnInfo[key].granted) {
          grantedCount++;
        } else if (sortedRowData.columnInfo[key].granted === false) {
          revokedCount++;
        } else if (sortedRowData.columnInfo[key].inherited) {
          inheritedCount++;
        }
      });
      if (
        Object.keys(rolesPermisisonsData.columnData).length === grantedCount
      ) {
        return "fa-check";
      } else if (
        Object.keys(rolesPermisisonsData.columnData).length === revokedCount
      ) {
        return "fa-times";
      } else if (
        Object.keys(rolesPermisisonsData.columnData).length === inheritedCount
      ) {
        return "fa-checkmark";
      } else if (inheritedCount || revokedCount || grantedCount) {
        return "fa-minus";
      } else {
        return "fa-null";
      }
    }
  };
  const loadPrerequisiteAllPermissionsPopup = (
    type,
    selectedRowData,
    currentClass,
    nextIcon,
    columnData
  ) => {
    let preReqIds = [];
    let selectedColumnId = "";
    let columnId = "";
    let totalColumnOpened = Object.keys(selectedRowData["columnInfo"]).length;
    if (columnData) {
      columnId = columnData.columnId;
    }
    if (
      selectedRowData.prerequisitePermissions &&
      nextIcon === "fa-check" &&
      Object.keys(selectedRowData.prerequisitePermissions).length > 0
    ) {
      let preReqName = Object.keys(
        selectedRowData.allPrerequisitePermissions
      ).map((rowId, index1) => {
        let allPrerequisitePermissionsObj =
          selectedRowData.allPrerequisitePermissions[rowId];
        if (
          rolesPermisisonsData.rowData[allPrerequisitePermissionsObj.type][
            rowId
          ]["columnInfo"] &&
          allPrerequisitePermissionsObj
        ) {
          let isPreReqId = false;
          Object.keys(
            rolesPermisisonsData.rowData[allPrerequisitePermissionsObj.type][
              rowId
            ]["columnInfo"]
          ).map((dataId, index2) => {
            if (totalColumnOpened === 1) {
              if (String(dataId) === String(columnId)) {
                isPreReqId = false;
                if (
                  Object.keys(
                    rolesPermisisonsData.rowData[
                      allPrerequisitePermissionsObj.type
                    ][rowId]["columnInfo"][dataId]
                  ).length === 0 ||
                  rolesPermisisonsData.rowData[
                    allPrerequisitePermissionsObj.type
                  ][rowId]["columnInfo"][dataId].granted === false ||
                  (!rolesPermisisonsData.rowData[
                    allPrerequisitePermissionsObj.type
                  ][rowId]["columnInfo"][dataId].granted &&
                    rolesPermisisonsData.rowData[
                      allPrerequisitePermissionsObj.type
                    ][rowId]["columnInfo"][dataId].inherited)
                ) {
                  isPreReqId = true;
                  selectedColumnId = dataId;
                  preReqIds.push(allPrerequisitePermissionsObj);
                  return true;
                }
              }
            } else {
              isPreReqId = columnData ? isPreReqId : false;
              if (
                Object.keys(
                  rolesPermisisonsData.rowData[
                    allPrerequisitePermissionsObj.type
                  ][rowId]["columnInfo"][dataId]
                ).length > 0
              ) {
                if (
                  (!selectedColumnId || columnId !== selectedColumnId) &&
                  (rolesPermisisonsData.rowData[
                    allPrerequisitePermissionsObj.type
                  ][rowId]["columnInfo"][dataId].granted === false ||
                    (!rolesPermisisonsData.rowData[
                      allPrerequisitePermissionsObj.type
                    ][rowId]["columnInfo"][dataId].granted &&
                      rolesPermisisonsData.rowData[
                        allPrerequisitePermissionsObj.type
                      ][rowId]["columnInfo"][dataId].inherited))
                ) {
                  isPreReqId = true;
                  selectedColumnId = columnId;
                  preReqIds.push(allPrerequisitePermissionsObj);
                  return true;
                }
              } else {
                isPreReqId = true;
                preReqIds.push(allPrerequisitePermissionsObj);
                return true;
              }
            }
          });
          if (isPreReqId) {
            return (
              <li type="disc">
                {
                  rolesPermisisonsData.rowData[
                    allPrerequisitePermissionsObj.type
                  ][rowId].rowName
                }
              </li>
            );
          }
        }
      });

      let validForOpenPopup = columnId
        ? String(columnId) === String(selectedColumnId)
          ? true
          : false
        : true;
      if (preReqIds.length > 0 && validForOpenPopup) {
        let preReqModalView = preRequisiteModalOpen();
        let rolesPermissionsctrlNew = (
          <Draggable handle=".cursorSubModal">
            <Modal
              isOpen={preReqModalView}
              className="no-cursorSubModal"
              toggle={() => {
                preRequisiteModalClose();
              }}
              style={{
                minWidth: "auto",
              }}
            >
              <ModalHeader
                className="modal-header bg-primary modal-title text-white cursorSubModal"
                toggle={() => {
                  setIsPrereqModalOpen(false);
                  preRequisiteModalClose();
                }}
              >
                <i className="fas fa-key" /> Automatic Grant of Dependent
                Permissions
              </ModalHeader>

              <ModalBody
                style={{
                  height: "auto",
                }}
              >
                <div className="form-group row">
                  <p className="font-weight-bold">
                    Granting the {selectedRowData.rowName} Permission will
                    automatically grant the following Permissions:
                  </p>
                </div>
                <div className="form-group row ml-5 font-weight-bold">
                  <ol>{preReqName}</ol>
                </div>
                <p className="font-weight-bold">Do you want to continue?</p>
              </ModalBody>
              <ModalFooter>
                <div className="col-sm-12 text-right">
                  <button
                    type="submit"
                    value={OK_LBL}
                    name="btnResSearch"
                    className="btn btn-primary search-btn"
                    onClick={(event) =>
                      !columnData
                        ? !props.isDualState
                          ? selectAllColumnsForCategoryDataNormalStates(
                              type,
                              selectedRowData,
                              currentClass,
                              preReqIds
                            )
                          : selectAllColumnsForCategoryDataDualStates(
                              type,
                              selectedRowData,
                              currentClass,
                              preReqIds
                            )
                        : !props.isDualState
                        ? selectSingleColumnForCategoryDataNormalStates(
                            type,
                            selectedRowData,
                            columnData,
                            currentClass,
                            preReqIds
                          )
                        : selectSingleColumnForCategoryDataDualStates(
                            type,
                            selectedRowData,
                            columnData,
                            currentClass,
                            preReqIds
                          )
                    }
                  >
                    {OK_LBL}
                  </button>
                  <button
                    type="submit"
                    value="Cancel"
                    name="btnResSearch"
                    className="btn btn-secondary search-btn ml-1"
                    onClick={(event) => preRequisiteModalClose()}
                  >
                    Cancel
                  </button>
                </div>
              </ModalFooter>
            </Modal>
          </Draggable>
        );
        setPrerequisitePermissionsData(rolesPermissionsctrlNew);
      } else {
        if (!columnData) {
          selectAllColumnsForCategoryDataNormalStates(
            type,
            selectedRowData,
            currentClass,
            preReqIds
          );
        } else {
          selectSingleColumnForCategoryDataNormalStates(
            type,
            selectedRowData,
            columnData,
            currentClass,
            preReqIds
          );
        }
      }
    } else {
      if (!columnData) {
        selectAllColumnsForCategoryDataNormalStates(
          type,
          selectedRowData,
          currentClass,
          preReqIds
        );
      } else {
        selectSingleColumnForCategoryDataNormalStates(
          type,
          selectedRowData,
          columnData,
          currentClass,
          preReqIds
        );
      }
    }
  };
  const loadRevokePrerequisiteAllPermissionsPopup = (
    type,
    selectedRowData,
    currentClass,
    nextIcon,
    columnData
  ) => {
    if (
      selectedRowData.dependantPermissions &&
      (nextIcon === "fa-times" ||
        (nextIcon === "fa-null" &&
          props.permissionsType === Roles_Permissions.PermissionsToRoles)) &&
      Object.keys(selectedRowData.dependantPermissions).length > 0
    ) {
      let preReqName = Object.keys(selectedRowData.dependantPermissions).map(
        (rowId, index) => {
          let allPrerequisitePermissionsObj =
            selectedRowData.dependantPermissions[rowId];
          return (
            <li type="disc">
              {
                rolesPermisisonsData.rowData[
                  allPrerequisitePermissionsObj.type
                ][rowId].rowName
              }
            </li>
          );
        }
      );

      let isRevokePrereqModalShow = revokePrereqModalOpen();
      let rolesPermissionsctrlNew = (
        <Draggable handle=".cursorSubModal">
          <Modal
            isOpen={isRevokePrereqModalShow}
            className="no-cursorSubModal"
            toggle={() => {
              revokePrerequisiteModalClose();
            }}
            style={{
              minWidth: "auto",
            }}
          >
            <ModalHeader
              className="modal-header bg-primary modal-title text-white cursorSubModal"
              toggle={() => {
                setIsRevokePrereqModalOpen(false);
              }}
            >
              <i className="fas fa-key" /> Dependent Permissions Not Revoked
            </ModalHeader>

            <ModalBody
              style={{
                height: "auto",
              }}
              className="font-weight-bold"
            >
              <div>
                <p>
                  "{selectedRowData.rowName}" permission has dependent
                  permissions that were granted automatically:
                </p>
              </div>
              <div className="form-group row ml-5">
                <ol>{preReqName}</ol>
              </div>
              <p>
                Dependent permissions are NOT Revoked automatically. If
                required, you must remove the dependent permissions manually.
              </p>
              <p>Do you want to continue?</p>
            </ModalBody>
            <ModalFooter>
              <div className="col-sm-12 text-right">
                <button
                  type="submit"
                  value={OK_LBL}
                  name="btnResSearch"
                  className="btn btn-primary search-btn"
                  onClick={(event) =>
                    !columnData
                      ? !props.isDualState
                        ? selectAllColumnsForCategoryDataNormalStates(
                            type,
                            selectedRowData,
                            currentClass,
                            []
                          )
                        : selectAllColumnsForCategoryDataDualStates(
                            type,
                            selectedRowData,
                            currentClass,
                            []
                          )
                      : !props.isDualState
                      ? selectSingleColumnForCategoryDataNormalStates(
                          type,
                          selectedRowData,
                          columnData,
                          currentClass,
                          []
                        )
                      : selectSingleColumnForCategoryDataDualStates(
                          type,
                          selectedRowData,
                          columnData,
                          currentClass,
                          []
                        )
                  }
                >
                  {OK_LBL}
                </button>
                <button
                  type="submit"
                  value="Cancel"
                  name="btnResSearch"
                  className="btn btn-secondary search-btn ml-1"
                  onClick={(event) => revokePrerequisiteModalClose()}
                >
                  Cancel
                </button>
              </div>
            </ModalFooter>
          </Modal>
        </Draggable>
      );
      setRevokePrerequisitePermissionsData(rolesPermissionsctrlNew);

      //}
    } else {
      !columnData
        ? !props.isDualState
          ? selectAllColumnsForCategoryDataNormalStates(
              type,
              selectedRowData,
              currentClass,
              []
            )
          : selectAllColumnsForCategoryDataDualStates(
              type,
              selectedRowData,
              currentClass,
              []
            )
        : !props.isDualState
        ? selectSingleColumnForCategoryDataNormalStates(
            type,
            selectedRowData,
            columnData,
            currentClass,
            []
          )
        : selectSingleColumnForCategoryDataDualStates(
            type,
            selectedRowData,
            columnData,
            currentClass,
            []
          );
    }
  };
  const preRequisiteModalClose = () => {
    setIsPrereqModalOpen(false);
    setPrerequisitePermissionsData(null);
  };

  const preRequisiteModalOpen = () => {
    setIsPrereqModalOpen(true);
    return true;
  };

  const revokePrerequisiteModalClose = () => {
    setIsRevokePrereqModalOpen(false);
    setRevokePrerequisitePermissionsData(null);
  };
  const revokePrereqModalOpen = () => {
    setIsRevokePrereqModalOpen(true);
    return true;
  };

  ///Default without category///
  const loadDefaultRowData = () => {
    let rowDataObj = { ...rolesPermisisonsData.rowData };
    let commonCB = Object.keys(rowDataObj).map((key, rowIndex) => {
      const rowData = rowDataObj[key];
      let rowName = (
        <td
          className={
            rowIndex === 0
              ? "text-left border-top-1 border-right-1 border-left-lightgray-1"
              : "text-left border-right-1 border-left-lightgray-1"
          }
        >
          <div style={{ width: "160px" , wordBreak: "break-all" }} title={rowData.rowDesc}>{rowData.rowName}</div>
        </td>
      );
      let allPermisisonCb = (
        <td
          style={{ width: "5px", borderTop: "1px solid #ddd" }}
          className={
            rowIndex === 0 ? "border-top-1 border-right-1" : "border-right-1"
          }
        >
          &nbsp;
        </td>
      );

      if (rolesPermisisonsData.totalColumns > 1) {
        let className = getDefaultAllPermisisonCbClassName(rowData);
        let tooltipId = "leftAllCheckbox_" + rowData.rowId;
        allPermisisonCb = (
          <td
            className={
              rowIndex === 0 ? "border-top-1 border-right-1" : "border-right-1"
            }
            style={{ width: "50px" }}
            align="center"
          >
            <div
              className="checkBoxRolesPermisisons permissionAllCheckBox"
              onClick={(event) =>
                selectDefaultAllColumns(event, rowData, className)
              }
              id={tooltipId}
            >
              {correctIconHtml(className, tooltipId)}
            </div>
          </td>
        );
      }
      let columnData = { ...rolesPermisisonsData.columnData };
      let columnCb = Object.keys(columnData).map((columnKey, columnIndex) => {
        let columnClassName = "fa-check";
        if (columnData[columnKey].rowInfo[rowData.rowId]) {
          let rowInfo = columnData[columnKey].rowInfo[rowData.rowId];
          let divCbClassName = rowInfo.modified
            ? "checkBoxRolesPermisisons cb-modified"
            : "checkBoxRolesPermisisons";
          return (
            <td
              key={columnIndex}
              align="center"
              className={
                rowIndex === 0
                  ? "border-top-1 detail-td border-right-lightgray-1"
                  : "detail-td border-right-lightgray-1"
              }
            >
              <div
                className={divCbClassName}
                onClick={(event) =>
                  selectDefaultSingleColumn(
                    event,
                    rowData,
                    columnData[columnKey],
                    columnClassName
                  )
                }
              >
                {createTooltip(
                  <i
                    className="fa checkBoxRolesPermisisonsFont fa-check"
                    id={
                      "rolesRowCheckbox" +
                      columnData[columnKey].columnId +
                      "_" +
                      rowData.rowId
                    }
                  />,
                  ReactHtmlParser(
                    "Name: " +
                      columnData[columnKey].columnName +
                      "</br>" +
                      "Target Element: " +
                      rowData.rowName
                  ),
                  {
                    forceShow: true,
                    ttcClass: "tooltipContainer-max width-max-content",
                    considerParent: true,
                  }
                )}
              </div>
            </td>
          );
        } else {
          return (
            <td
              key={columnIndex}
              className={
                rowIndex === 0
                  ? "border-top-1 detail-td border-right-lightgray-1"
                  : "detail-td border-right-lightgray-1"
              }
              align="center"
            >
              <div
                className="checkBoxRolesPermisisons"
                onClick={(event) =>
                  selectDefaultSingleColumn(
                    event,
                    rowData,
                    columnData[columnKey],
                    "fa-null"
                  )
                }
                id={
                  "rolesRowCheckbox" +
                  columnData[columnKey].columnId +
                  "_" +
                  rowData.rowId
                }
              >
                {createTooltip(
                  <i className="fa fa-null checkBoxRolesPermisisonsFont">
                    &nbsp;
                  </i>,
                  ReactHtmlParser(
                    getNoteTooltipHtml(
                      "Name: " +
                        columnData[columnKey].columnName +
                        "</br>" +
                        "Target Element: " +
                        rowData.rowName
                    )
                  ),
                  {
                    forceShow: true,
                    ttcClass: "tooltipContainer-max width-max-content",
                    considerParent: true,
                  }
                )}
              </div>
            </td>
          );
        }
      });
      return (
        <tr key={rowIndex + "tr"}>
          {rowName}
          {allPermisisonCb}
          {columnCb}
        </tr>
      );
    });
    return commonCB;
  };
  const getAllColumnDefaultCbClassName = (columnData) => {
    if (!columnData.rowInfo || Object.keys(columnData.rowInfo).length === 0) {
      return "fa-null";
    } else if (
      Object.keys(columnData.rowInfo).length !== rolesPermisisonsData.totalRows
    ) {
      return "fa-minus";
    } else {
      let grantedCount = 0;
      Object.keys(columnData.rowInfo).map((key, index) => {
        if (columnData.rowInfo[key].granted) {
          grantedCount++;
        }
      });
      if (rolesPermisisonsData.totalRows === grantedCount) {
        return "fa-check";
      } else {
        return "fa-null";
      }
    }
  };
  const getDefaultAllPermisisonCbClassName = (sortedRowData) => {
    if (
      !sortedRowData.columnInfo ||
      Object.keys(sortedRowData.columnInfo).length === 0
    ) {
      return "fa-null";
    } else if (
      Object.keys(sortedRowData.columnInfo).length !==
      rolesPermisisonsData.totalColumns
    ) {
      return "fa-minus";
    } else {
      let grantedCount = 0;
      Object.keys(sortedRowData.columnInfo).map((key, index) => {
        if (sortedRowData.columnInfo[key].granted) {
          grantedCount++;
        }
      });
      if (rolesPermisisonsData.totalColumns === grantedCount) {
        return "fa-check";
      } else {
        return "fa-null";
      }
    }
  };
  const selectAllDefaultRows = (event, columnData, className) => {
    const dummy = { ...rolesPermisisonsData };
    let isChecked = false;
    if (className === "fa-minus" || className === "fa-null") {
      isChecked = true;
    }
    Object.keys(dummy.rowData).map((key, index) => {
      if (!columnData.rowInfo[dummy.rowData[key].rowId] && isChecked) {
        columnData.rowInfo[dummy.rowData[key].rowId] = {
          granted: isChecked,
          new: true,
          modified: true,
        };
        if (!dummy.rowData[key]["columnInfo"]) {
          dummy.rowData[key].columnInfo = {};
        }
        dummy.rowData[key].columnInfo[columnData.columnId] = {
          granted: isChecked,
          new: true,
          modified: true,
        };
      } else if (!isChecked) {
        delete columnData.rowInfo[dummy.rowData[key].rowId];
        delete dummy.rowData[key].columnInfo[columnData.columnId];
      }
    });

    dummy.columnData[columnData.columnId] = columnData;
    setRolesPermisisonsData(dummy);
  };
  const selectDefaultAllColumns = (event, rowData, className) => {
    const dummy = { ...rolesPermisisonsData };
    let isChecked = false;
    if (className === "fa-minus" || className === "fa-null") {
      isChecked = true;
    }
    Object.keys(dummy.columnData).map((key, index) => {
      if (
        isChecked &&
        (!dummy.rowData[rowData.rowId]["columnInfo"] ||
          !dummy.rowData[rowData.rowId].columnInfo[key])
      ) {
        if (!dummy.rowData[rowData.rowId]["columnInfo"]) {
          dummy.rowData[rowData.rowId]["columnInfo"] = {};
        }
        dummy.rowData[rowData.rowId].columnInfo[key] = {
          granted: isChecked,
          new: true,
          modified: true,
        };
        dummy.columnData[key].rowInfo[rowData.rowId] = {
          granted: isChecked,
          new: true,
          modified: true,
        };
      } else if (!isChecked) {
        delete dummy.rowData[rowData.rowId].columnInfo[key];
        delete dummy.columnData[key].rowInfo[rowData.rowId];
      }
    });
    setRolesPermisisonsData(dummy);
  };
  const selectDefaultSingleColumn = (event, rowData, columnData, className) => {
    const dummy = { ...rolesPermisisonsData };
    if (columnData.rowInfo[rowData.rowId] && className === "fa-check") {
      delete columnData.rowInfo[rowData.rowId];
      delete rowData.columnInfo[columnData.columnId];
    } else {
      let data = {};
      if (className === "fa-null") {
        data.granted = true;
        data.new = true;
        data.modified = true;
      } else if (className === "fa-check") {
        delete columnData.rowInfo[rowData.rowId];
        delete rowData.columnInfo[columnData.columnId];
      }
      columnData.rowInfo[rowData.rowId] = data;
      rowData.columnInfo[columnData.columnId] = data;
    }
    dummy.rowData[rowData.rowId] = rowData;
    dummy.columnData[columnData.columnId] = columnData;
    setRolesPermisisonsData(dummy);
  };
  ///End Default without category///

  //   render() {
  let rolesPermissionsctrl = "";
  let legendHtml = "";
  if (rolesPermisisonsData) {
    const tableWidth = isCategoryRequired
      ? Object.keys(rolesPermisisonsData.columnData).length * 60 + 300
      : Object.keys(rolesPermisisonsData.columnData).length * 60 + 200;
    if (isCategoryRequired && !props.isDualState) {
      legendHtml = getPermissionsLegend();
    } else {
      legendHtml = getRolesLegend();
    }

    const columnHtml = getColumnHtml();
    rolesPermissionsctrl = (
      <Draggable handle=".cursor">
        <Modal
          isOpen={isModalOpen}
          className="no-cursor"
          toggle={() => {
            setIsModalOpen(false);
            props.closeModal();
          }}
          style={{
            minWidth: props.modalSettings.modalWidth + "px",
          }}
        >
          <ModalHeader
            className="modal-header bg-primary modal-title text-white cursor"
            toggle={() => {
              props.closeModal();
            }}
          >
            <i className="fas fa-key" />{" "}
            {props.permissionsType === "RolesToMember" ||
            props.permissionsType === "PermissionsToRoles"
              ? "Assign Roles"
              : props.permissionsType === "PermissionsToMember"
              ? "Assign Permissions"
              : props.permissionsType === "AppAccessToMember"
              ? "Application Access"
              : ""}
          </ModalHeader>

          <ModalBody
            style={{
              height: props.modalSettings.modalBodyHeight + "px",
            }}
          >
            <div
              className="form-group row table-fixed-head position-relative"
              style={{
                overflow: "auto",
                height: props.modalSettings.modalBodyHeight - 20 + "px",
                maxHeight: props.modalSettings.modalBodyHeight + "px",
                scrollbarWidth: "thin",
              }}
            >
              <table
                style={{ width: tableWidth }}
                className="tblRolesPermissionSettings table table-striped input-sm table-condensed dataTable no-footer"
              >
                <thead>
                  <tr>
                    {legendHtml}
                    {columnHtml.columnHeader}
                  </tr>
                  <tr>{columnHtml.columnAll}</tr>
                </thead>
                <tbody>
                  {isCategoryRequired ? loadRowData() : loadDefaultRowData()}
                </tbody>
              </table>
              {isPrereqModalOpen ? prerequisitePermissionsData : ""}
              {isRevokePrereqModalOpen ? revokePrerequisitePermissionsData : ""}
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="col-sm-12 text-right">
              <button
                type="submit"
                value={OK_LBL}
                name="btnResSearch"
                className="btn btn-primary search-btn"
                onClick={(event) =>
                  props.upDateRolesPermissionsData(rolesPermisisonsData)
                }
              >
                {OK_LBL}
              </button>
              <button
                onClick={(event) => props.closeModal()}
                type="submit"
                value="Cancel"
                name="btnResSearch"
                className="btn btn-secondary search-btn ml-1"
              >
                Cancel
              </button>
            </div>
          </ModalFooter>
        </Modal>
      </Draggable>
    );
  }
  return rolesPermissionsctrl;
};
export default PermissionsGrid;

import React, { Fragment } from "react";
import { MarkerMapModel } from "../../services/entities/models/domain/marker-map-model";
import { toTitleCase } from "../../shared/utility";
import { ColorCode, MapType } from "../../shared/utility/enum-utility";
import { createTooltip } from "../../shared/utility/tooltip-utility";
import { ModalType } from "../../shared/GlobalVar";

export const getShuttleLocationMarkup = (data, modalInfo, showModal) => {
  const infoData = getInfoData(data, true);
  const newLoc = {
    key: { latitude: data.latitude, longitude: data.longitude },
    value: data.shuttleCode + "," + data.locationDisplayName,
  };
  if (!modalInfo.customData) {
    modalInfo.customData = {};
    modalInfo.customData.startLocation = null;
    modalInfo.customData.destinationLocation = null;
  }
  if (!modalInfo.customData.startLocation) {
    modalInfo.customData.startLocation = null;
  }
  if (!modalInfo.customData.destinationLocation) {
    modalInfo.customData.destinationLocation = null;
  }
  if (modalInfo.customData && !modalInfo.customData.startLocation) {
    modalInfo.customData.startLocation = newLoc;
  } else if (
    modalInfo &&
    modalInfo.customData &&
    !modalInfo.customData.destinationLocation
  ) {
    modalInfo.customData.destinationLocation = newLoc;
  } else if (
    modalInfo &&
    modalInfo.customData &&
    modalInfo.customData.startLocation !== newLoc
  ) {
    modalInfo.customData.startLocation = newLoc;
  } else if (
    modalInfo &&
    modalInfo.customData &&
    modalInfo.customData.destinationLocation !== newLoc
  ) {
    modalInfo.customData.destinationLocation = newLoc;
  }
  return (
    <Fragment>
      <b>Code: </b> {infoData.code}
      <br />
      <b>Location: </b> {infoData.locationCode}
      <br />
      <b>Status: </b> {infoData.status}
      <br />
      <br />
      {showModal ? (
        <div className="marker-popup-btn-container">
          <button
            type="button"
            value="Search"
            name="btnResSearch"
            class="btn btn-primary search-btn"
            id="btnResSearch"
            title="Click to get direction modal!"
            onClick={() =>
              showModal({
                type: ModalType.SHUTTLE_DIRECTION_MODAL,
                showModal: true,
                customData: modalInfo.customData,
              })
            }
          >
            <i class="fas fa-directions"></i>
            Direction
          </button>
        </div>
      ) : (
        ""
      )}
    </Fragment>
  );
};
export const getLandmarkMarkup = (data, modalInfo, showModal) => {
  const infoData = getInfoData(data);
  const newLoc = {
    key: { latitude: data.latitude, longitude: data.longitude },
    value: data.landmarkCode + "," + data.formattedAddress,
  };
  const pData = {
    startLocation: null,
    destinationLocation: null,
  };
  if (!modalInfo.customData || !modalInfo.customData.startLocation) {
    pData.startLocation = newLoc;
  } else if (!modalInfo.customData.destinationLocation) {
    pData.destinationLocation = newLoc;
  } else if (
    modalInfo.customData.startLocation.key.latitude !== newLoc.key.latitude ||
    modalInfo.customData.startLocation.key.longitude !== newLoc.key.longitude
  ) {
    pData.startLocation = newLoc;
  } else if (
    modalInfo.customData.destinationLocation.key.latitude !==
      newLoc.key.latitude ||
    modalInfo.customData.destinationLocation.key.longitude !==
      newLoc.key.longitude
  ) {
    pData.destinationLocation = newLoc;
  }
  return (
    <Fragment>
      <div className="marker-popup">
        <b>Code: </b> {infoData.code}
        <br />
        <b>Location: </b> {infoData.locationCode}
        <br />
        <b>Category: </b> {infoData.category || "--"}
        <br />
        <b>Status: </b> {infoData.status || "--"}
        <br />
        <br />
        {showModal ? (
          <div className="marker-popup-btn-container">
            <button
              type="button"
              value="Search"
              name="btnResSearch"
              class="btn btn-primary search-btn"
              id="btnResSearch"
              title="Click to get direction modal!"
              onClick={() =>
                showModal({
                  type: ModalType.SHUTTLE_DIRECTION_MODAL,
                  showModal: true,
                  customData: pData,
                })
              }
            >
              <i class="fas fa-directions"></i>
              Direction
            </button>
          </div>
        ) : (
          ""
        )}
      </div>
    </Fragment>
  );
};
export const getShuttleMapInfo = (dataList) => {
  const markerList = [];
  dataList.map((post) => {
    markerList.push(
      new MarkerMapModel({
        markupData: getShuttleLocationMarkup(post,{},false),
        position: [
          post.latitude || post.location.latitude,
          post.longitude || post.location.longitude,
        ],
        iconData: {
          type: MapType.SHUTTLES,
          label: post.shortName,
          className: "fas fa-bus fa-3x",
          color: post.iconColor,
        },
      })
    );
  });
  return markerList;
};
export const getLandMarkMapData = (vicinitydata, modalInfo, showModal) => {
  const markerList = [];
  if (vicinitydata && vicinitydata.landmarkSummaries) {
    vicinitydata.landmarkSummaries.map((post) => {
      if (post.latitude && post.longitude) {
        markerList.push(
          new MarkerMapModel({
            markupData: getLandmarkMarkup(post, modalInfo, showModal),
            position: [post.latitude, post.longitude],
            parentData: { ...post },
            iconData: {
              type: MapType.LANDMARKS,
              label:
                post.iconColor === ColorCode.LANDMARK_GREEN
                  ? post.shortNameForIcon
                  : post.iconColor === ColorCode.LANDMARK_RED
                  ? post.shortNameForIcon
                  : post.shortNameForIcon,
              className: "fas fa-bus fa-3x",
              color: post.iconColor,
            },
          })
        );
      }
    });
  }
  return markerList;
};
export const getShuttleMapData = (vicinitydata, modalInfo, showModal) => {
  const markerList = [];
  if (vicinitydata && vicinitydata.shuttleSummaries) {
    vicinitydata.shuttleSummaries.map((post) => {
      if (post.availability && post.availability.key !== "OUT_OF_SERVICE") {
        if (post.latitude && post.longitude) {
          markerList.push(
            new MarkerMapModel({
              markupData: getShuttleLocationMarkup(post, modalInfo, showModal),
              position: [post.latitude, post.longitude],
              parentData: { ...post },
              iconData: {
                type: MapType.SHUTTLES,
                label: post.shortName,
                className: "fas fa-bus fa-3x",
                color: post.iconColor,
              },
            })
          );
        }
      }
    });
  }
  return markerList;
};

const getInfoData = (data, isShuttle) => {
  let infoData = {};
  if (isShuttle) {
    infoData = {
      code: data.shuttleCode || "",
      locationCode: data.locationCode
        ? data.locationCode
        : data.location && data.location.locationCode
        ? data.location.locationCode
        : "",
      status: data.combineStatusLabel
        ? data.combineStatusLabel
        : data.lastEngineStatusDisplayValue || "",
    };
  } else {
    infoData = {
      code: data.landmarkCode || "",
      locationCode: data.locationDisplayName
        ? data.locationCode
        : data.orgUnit
        ? data.orgUnit.locationCode
        : "",
      status: data.status
        ? data.status
        : toTitleCase(data.landmarkStatus || ""),
      category:
        typeof data.landmarkCategory === "object" && data.landmarkCategory
          ? data.landmarkCategory.value
          : toTitleCase(data.landmarkCategory || ""),
    };
  }
  infoData.latitude = data.latitude;
  infoData.longitude = data.longitude;
  return infoData;
};

const renderClusterMarkerRows = (vicinitydata, count, isShuttle) => {
  let summarydata = vicinitydata;
  return summarydata.map((data, index) => {
    const infoData = getInfoData(data, isShuttle);
    let code = infoData.code;
    let locationCode = infoData.locationCode;
    let category = infoData.category;
    let status = infoData.status;
    let lat = infoData.latitude;
    let lng = infoData.longitude;
    return (
      <tr key={code + index} className="position-relative">
        <td className="text-left lat-lng-click" lat={lat} lng={lng}>
          {createTooltip(code || "--", code || "")}
        </td>
        <td>{createTooltip(locationCode || "--", locationCode || "")}</td>
        {!isShuttle ? (
          <td className="text-left">
            {createTooltip(category || "--", category || "")}
          </td>
        ) : (
          ""
        )}
        <td>{createTooltip(status || "--", status || "")}</td>
      </tr>
    );
  });
};

export const getClusterMarkup = (vicinitydata, isShuttle) => {
  const count = vicinitydata ? vicinitydata.length : 0;
  return (
    <Fragment>
      <div className="topHeader text-center">
        <h3 className="heading-tertiary">
          {count + (isShuttle ? " Shuttles" : " Landmarks")}
        </h3>
      </div>
      <div
        className="table-fixed-head tableScroll"
        style={{
          maxHeight: "15rem",
        }}
      >
        <table
          className="table table-striped table-hover"
          style={{ tableLayout: "fixed" }}
        >
          <thead className="thead-primary">
            <tr>
              <th style={{ width: "30%" }}>{"Code"}</th>
              <th style={{ width: "20%" }}>{"Location"}</th>
              {!isShuttle ? <th style={{ width: "25%" }}>{"Category"}</th> : ""}
              <th style={{ width: isShuttle ? "40%" : "20%" }}>{"Status"}</th>
            </tr>
          </thead>
          <tbody>
            {renderClusterMarkerRows(vicinitydata, count, isShuttle)}
          </tbody>
        </table>
      </div>
    </Fragment>
  );
};

import React from 'react';
import Dialog from './dialog';
function CommonDialog(props) {
    const {
        showModal,
        modaWrapperClass,
        modalMinWidth = "372px",
        modalMaxWidth = "372px",
    } = props;
    return (
        <div className={`${showModal ? "custom-modal-show" : ""} ${modaWrapperClass} modal-main-container`} style={{ minWidth: modalMinWidth, maxWidth: modalMaxWidth }}>
            <Dialog {...props} />
        </div >
    )
}

export default CommonDialog
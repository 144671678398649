import {
  convertToServerTime,
  getFormattedVehicleName,
} from "../../../shared/utility";
import ReactHtmlParser from "react-html-parser";
import { compatiblePaymentRequestforReservationRequest } from "../reservation-helper";
export const createEndrentalMemberReq = (memId) => {
  return {
    MemberId: memId,
    Option: {
      License: true,
      CreditCard: true,
      Insurance: false,
      Employee: false,
      CountryCodes: false,
      Assets: false,
      Referals: false,
      Rating: false,
      Preferences: false,
      Contact: false,
      Earnings: false,
      PrimaryStateCityZipList: false,
      BillingStateCityZipList: false,
      LicenseStateList: false,
    },
  };
};
export const createEndrentalChargesReq = (endRentalInputCtrl) => {
  return {
    MemberId: endRentalInputCtrl.MemberId || null,
    MemberAssetId: endRentalInputCtrl.MemberAssetId || null,
    ReservationId: endRentalInputCtrl.ReservationId || null,
    CategoryId: endRentalInputCtrl.CategoryId || null,
    UpgradedCategoryId: endRentalInputCtrl.UpgradedCategoryId || null,
    UpgradeRate: endRentalInputCtrl.UpgradeRate || null,
    UpgradeRateType: endRentalInputCtrl.UpgradeRateType || null,

    OdometerOut: endRentalInputCtrl.OdometerOut || null,
    OdometerIn: endRentalInputCtrl.OdometerIn || null,
    FuelOut: endRentalInputCtrl.FuelOut || null,

    DiscountType: endRentalInputCtrl.DiscountType || null,
    DiscountValue: endRentalInputCtrl.DiscountValue || null,
    DiscountReason: endRentalInputCtrl.DiscountReason || null,

    AppliedCreditList:
      endRentalInputCtrl.AppliedCreditList.length > 0
        ? [...endRentalInputCtrl.AppliedCreditList]
        : [],
    AppliedChargeList:
      endRentalInputCtrl.AppliedChargeList.length > 0
        ? [...endRentalInputCtrl.AppliedChargeList]
        : [],

    AdditionalDriverCount: endRentalInputCtrl.AdditionalDriverCount || null,

    Upsells:
      endRentalInputCtrl.Upsells && endRentalInputCtrl.Upsells.length > 0
        ? [...endRentalInputCtrl.Upsells]
        : [],

    InsuranceInfo: endRentalInputCtrl.InsuranceInfo || null,

    Dob: endRentalInputCtrl.Dob || null,

    LateChargeableOption: endRentalInputCtrl.LateChargeableOption || null,
    KeepOlderPricing: endRentalInputCtrl.KeepOlderPricing || false,
    CheckoutDate:
      endRentalInputCtrl.CheckoutDate ||
      convertToServerTime().format("MM:DD:YYYY:HH:mm"),
    ExtensionFee: endRentalInputCtrl.ExtensionFee || null,

    PickupLocationId: endRentalInputCtrl.PickupLocationId || null,
    PromisedStartTime: endRentalInputCtrl.PromisedStartTime || null,
    PromisedEndTime: endRentalInputCtrl.PromisedEndTime || null,
    AppliedGracePeriod: endRentalInputCtrl.AppliedGracePeriod || null,
    UseVehicleRate: endRentalInputCtrl.UseVehicleRate || false,
  };
};
export const compatibleReservationSummarytoEndRentalCtrl = (
  reservationSummary,
  memDob
) => {
  const pricingInfo = reservationSummary.ReservationPricingInfo || {};
  pricingInfo.Deductibles = pricingInfo.Deductibles || [];
  let deductibleAmount = 0;
  if (pricingInfo.Deductibles && pricingInfo.Deductibles.length) {
    for (var i = 0; i < pricingInfo.Deductibles.length; i++) {
      var temp = parseInt(pricingInfo.Deductibles[i].Key) || 0;
      if (pricingInfo.Deductibles[i].Value) {
        if (pricingInfo.Deductible == temp) {
          deductibleAmount = temp;
          break;
        }
      }
    }
  }
  pricingInfo.UpsellOptions = pricingInfo.UpsellOptions || [];
  const Upsells = [];
  pricingInfo.UpsellOptions.map((item, index) => {
    let upsellListObj = {};
    upsellListObj.Quantity = item.Quantity;
    upsellListObj.Value = item.Value;
    if (item.Value !== null && item.Value !== undefined) {
      Upsells.push(upsellListObj);
    }
  });
  return {
    MemberId: reservationSummary.BorrowerId || null,
    Dob: memDob || null,
    ReservationId: reservationSummary.ReservationId || null,
    ConfirmationCode: reservationSummary.ConfirmationCode || null,
    ReservationState: reservationSummary.ReservationState || null,
    MemberAssetId: reservationSummary.ReservationAssetId || null,
    BorrowerName: reservationSummary.BorrowerName || null,
    PickupLocation: reservationSummary.PickupLocation || null,
    VehicleName: getFormattedVehicleName(
      reservationSummary.PersonAssetUnitNo,
      reservationSummary.Make,
      reservationSummary.Model,
      reservationSummary.Year
    ),
    PrimaryImageUrl: reservationSummary.PrimaryImageUrl || null,
    CategoryId: reservationSummary.CategoryId || null,
    UpgradedCategoryId: reservationSummary.UpgradedCategoryId || null,

    IntegAdjustedBy: reservationSummary.IntegAdjustedBy || null,
    IntegArrivedBy: reservationSummary.IntegArrivedBy || null,
    IntegRecordedBy: reservationSummary.IntegRecordedBy || null,
    IntegCompletedBy: reservationSummary.IntegCompletedBy || null,

    IntegAdjustedByName: reservationSummary.IntegAdjustedByName || null,
    IntegArrivedByName: reservationSummary.IntegArrivedByName || null,
    IntegCompletedByName: reservationSummary.IntegCompletedByName || null,

    PickupLocationId: reservationSummary.PickupLocationId || null,
    ReturnLocationId: reservationSummary.ReturnLocationId || null,

    PromisedStartTime: pricingInfo.StartDate || null,
    PromisedEndTime: pricingInfo.EndDate || null,
    ActualStartDate: pricingInfo.ActualStartDate || null,
    ActualEndDate: pricingInfo.ActualEndDate || null,
    CheckoutDate:
      pricingInfo.ActualEndDate ||
      convertToServerTime().format("MM:DD:YYYY:HH:mm"),
    ExtensionFee: pricingInfo.ExtensionFee || null,
    CompletedOn:
      pricingInfo.CompletedOn ||
      convertToServerTime().format("MM:DD:YYYY:HH:mm"),
    ModifiedStartTime: reservationSummary.ModifiedStartTime || null,

    OdometerOut: reservationSummary.OdometerOut || null,
    OdometerIn: reservationSummary.OdometerIn || null,
    CustomerReportedOdometerIn:
      reservationSummary.CustomerReportedOdometerIn || null,
    CustomerReportedFuelIn: reservationSummary.CustomerReportedFuelIn || null,
    FuelOut: reservationSummary.FuelOut || null,
    FuelIn: reservationSummary.FuelIn || null,

    Notes: reservationSummary.BorrowerNotes || null,
    CCANotes: reservationSummary.CallCenterNotes || null,
    UpgradeNotes: reservationSummary.UpgradeNotes || null,
    Reason: reservationSummary.Reason || null,
    //BorrowerNotes: reservationSummary.BorrowerNotes || null,
    //CallCenterNotes: reservationSummary.CallCenterNotes || null,
    LateReservation: reservationSummary.LateReservation || null,
    LateChargeableOption: reservationSummary.LateChargeableOption || null,
    UseVehicleRate: pricingInfo.VehiclePricingUsed,
    Upsells: [...Upsells],
    InsuranceInfo: {
      CDWSelected: pricingInfo.CdwSelected,
      DeductibleAmount: deductibleAmount,
      DeductibleCost: pricingInfo.Deductible,
      LiabilityInsuranceSelected: pricingInfo.LiabilitySelected,
      InsuranceSurchargeSelected: pricingInfo.InsuranceSurchargeSelected,
    },
    AppliedCreditList:
      pricingInfo.AppliedCredits && pricingInfo.AppliedCredits.length > 0
        ? [...pricingInfo.AppliedCredits]
        : [],
    AppliedChargeList:
      pricingInfo.AppliedCharges && pricingInfo.AppliedCharges.length > 0
        ? [...pricingInfo.AppliedCharges]
        : [],
    DiscountType: pricingInfo.DiscountType || null,
    DiscountValue: pricingInfo.DiscountValue || null,
    DiscountReason: pricingInfo.DiscountReason || null,
  };
};
export const createEndRentalPaymentReq = (endRentalInputCtrl, paymentInputRequest) => {
  return {
    PaymentInfo:
      compatiblePaymentRequestforReservationRequest(paymentInputRequest),
    ReservationId: endRentalInputCtrl.ReservationId || null,
    ConfirmationCode: endRentalInputCtrl.ConfirmationCode || null,
    MemberId: endRentalInputCtrl.MemberId || null,
    MemberAssetId: endRentalInputCtrl.MemberAssetId || null,
    PickupLocationId: endRentalInputCtrl.PickupLocationId || null,
    ReturnLocationId: endRentalInputCtrl.ReturnLocationId || null,
    PromisedStartTime: endRentalInputCtrl.PromisedStartTime || null,
    PromisedEndTime: endRentalInputCtrl.PromisedEndTime || null,
    CheckoutDate:
      endRentalInputCtrl.ActualEndDate ||
      convertToServerTime().format("MM:DD:YYYY:HH:mm"),
    CompletedOn:
      endRentalInputCtrl.CompletedOn ||
      convertToServerTime().format("MM:DD:YYYY:HH:mm"),
    CategoryId: endRentalInputCtrl.CategoryId || null,

    OdometerOut: endRentalInputCtrl.OdometerOut || null,
    OdometerIn: endRentalInputCtrl.OdometerIn || null,
    FuelOut: endRentalInputCtrl.FuelOut || null,
    FuelIn: endRentalInputCtrl.FuelIn || null,
    CustomerReportedOdometerIn:
      endRentalInputCtrl.CustomerReportedOdometerIn || null,
    LateChargeableOption: endRentalInputCtrl.LateChargeableOption || null,
    AppliedCreditList:
      endRentalInputCtrl.AppliedCreditList.length > 0
        ? [...endRentalInputCtrl.AppliedCreditList]
        : [],
    AppliedChargeList:
      endRentalInputCtrl.AppliedChargeList.length > 0
        ? [...endRentalInputCtrl.AppliedChargeList]
        : [],
  };
};
export const createEndRentalReq = (endRentalInputCtrl) => {
  return {
    ReservationId: endRentalInputCtrl.ReservationId || null,
    ConfirmationCode: endRentalInputCtrl.ConfirmationCode || null,
    ReturnLocationId: endRentalInputCtrl.ReturnLocationId || null,
    IntegAdjustedBy: endRentalInputCtrl.IntegAdjustedBy || null,
    IntegArrivedBy: endRentalInputCtrl.IntegArrivedBy || null,
    IntegCompletedBy: endRentalInputCtrl.IntegCompletedBy || null,
    ExpectedEndDate: endRentalInputCtrl.PromisedEndTime || null,
    CheckoutDate:
      endRentalInputCtrl.ActualEndDate ||
      convertToServerTime().format("MM:DD:YYYY:HH:mm"),
    CompletedOn:
      endRentalInputCtrl.CompletedOn ||
      convertToServerTime().format("MM:DD:YYYY:HH:mm"),
    OdometerOut: endRentalInputCtrl.OdometerOut || null,
    OdometerIn: endRentalInputCtrl.OdometerIn || null,
    CustomerReportedOdometerIn:
      endRentalInputCtrl.CustomerReportedOdometerIn || null,
    CustomerReportedFuelIn: endRentalInputCtrl.CustomerReportedFuelIn || null,
    FuelOut: endRentalInputCtrl.FuelOut || null,
    FuelIn: endRentalInputCtrl.FuelIn || null,
    Notes: endRentalInputCtrl.Notes || null,
    CCANotes: endRentalInputCtrl.CCANotes || null,
    LateChargeableOption: endRentalInputCtrl.LateChargeableOption || null,
    AppliedCreditList:
      endRentalInputCtrl.AppliedCreditList.length > 0
        ? [...endRentalInputCtrl.AppliedCreditList]
        : [],
    AppliedChargeList:
      endRentalInputCtrl.AppliedChargeList.length > 0
        ? [...endRentalInputCtrl.AppliedChargeList]
        : [],
    OverridePayment: endRentalInputCtrl.OverridePayment || null,
    OverrideEmail: endRentalInputCtrl.OverrideEmail || null,
  };
};
export const validateEndRentalData = (obj) => {
  let msg = "";
  if (obj.OdometerIn && obj.OdometerOut) {
    if (parseFloat(obj.OdometerIn) < parseFloat(obj.OdometerOut)) {
      msg = "Odometer Out should not be greater than Odometer In";
    }
  }
  if (msg) {
    return ReactHtmlParser(msg);
  }
};

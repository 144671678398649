import Phone from "./Phone";
import Insurance from "./Insurance";
import Address from "./Address";
import DrivingLicense from "./DrivingLicense";
import CreditCard from "./CreditCard";
import Employee from "./Employee";
import Rating from "./Rating";
import { getFullName } from "../../../shared/utility";
const Person = function (data) {
  if (!data) data = {};
  this.TempId = data.TempId || null;
  this.MemberId = data.MemberId || null;
  this.OptInXpress = data.OptInXpress || null;
  this.MembershipId = data.MembershipId || null;
  this.OrgUnitId = data.OrgUnitId || null;
  this.OrgUnitDisplayText = data.OrgUnitDisplayText || null;
  this.Age = data.Age || null;
  this.MemberSinceDate = data.MemberSinceDate || null;
  this.AssetsAllowed = data.AssetsAllowed || [];
  this.AssetsNotAllowed = data.AssetsNotAllowed || [];
  this.BGVerification = data.BGVerification || null;
  this.MemberStatus = data.MemberStatus || null;
  this.FirstName = data.FirstName || null;
  this.FirstName = data.FirstName || null;
  this.MiddleName = data.MiddleName || null;
  this.LastName = data.LastName || null;
  this.FullName = getFullName(data.FirstName, data.LastName, data.MiddleName);
  this.EmailAddress = data.EmailAddress || null;
  this.VerificationCode = data.VerificationCode || null;
  this.PrimaryPhone = data.PrimaryPhone || null;
  this.MobilePhone = new Phone(data.MobilePhone);
  this.HomePhone = new Phone(data.HomePhone);
  this.WorkPhone = new Phone(data.WorkPhone);
  this.AcknowledgeDriveManualEnabled = data.DrivingLicense
    ? data.DrivingLicense.AcknowledgeDriveManualEnabled
    : false;
  this.DriveType = data.DefaultTransmission || null;
  this.DrivingLicense = new DrivingLicense(data.DrivingLicense);
  this.PrimaryAddress = new Address(data.PrimaryAddress);
  this.Insurance = new Insurance(data.Insurance);
  this.PaymentFailureCount = data.PaymentFailureCount || null;
  this.CreditCard = new CreditCard(data.CreditCard);
  this.CompanyName = data.CompanyName || null;
  this.Designation = data.Designation || null;
  this.ReferredBy = data.ReferredBy || null;
  this.SignupCode = data.SignupCode || null;
  this.ProfileNotes = data.ProfileNotes || null;
  this.IsEmployee = data.IsEmployee || null;
  this.Employee = new Employee(data.Employee);
  this.BorrowerRating = new Rating(data.BorrowerRating);
  this.Courtesy = data.Courtesy || null;
  this.CurrencyFormatter = data.CurrencyFormatter || null;
  this.DefaultTransmission = data.DefaultTransmission || null;
  this.LastIpAddress = data.LastIpAddress || null;
  this.LastLoggedInApp = data.LastLoggedInApp || null;
  this.LastLoginDate = data.LastLoginDate || null;
  this.LoginEnabled = data.LoginEnabled || null;
  this.ManualCreditCardVerified = data.ManualCreditCardVerified || null;
  this.MemberType = data.MemberType || null;
  this.PotentialAlpha = data.PotentialAlpha || null;
  this.PreferredContact = data.PreferredContact || null;
  this.PreferredLanguage = data.PreferredLanguage || null;
  this.ProfileImageUrl = data.ProfileImageUrl || null;
  this.ProfileVerified = data.ProfileVerified || null;
  this.ReadTermsAndConditions = data.ReadTermsAndConditions || null;
  this.ReferenceLink = data.ReferenceLink || null;
  this.ResetPasswordEnabled = data.ResetPasswordEnabled || null;
  this.TemporaryPassword = data.TemporaryPassword || null;
  this.TotalCount = data.TotalCount || null;
  this.TotalCourtesyAmount = data.TotalCourtesyAmount || null;
  this.UnblockComment = data.UnblockComment || null;
  this.UnblockDate = data.UnblockDate || null;
  this.BlockComment = data.BlockComment || null;
  this.BlockDate = data.BlockDate || null;
  this.Dob = data.Dob || null;
  this.InternalNotes = data.InternalNotes || null;
};
export default Person;

import React, { useState, useEffect, useRef, Fragment } from "react";
import ReactHtmlParser from "react-html-parser";
import { withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  getSearchResult,
  getSearchResultReset,
  getEnumvalues,
  getOrgRolesDataReset,
  getOrgRolesData,
  getShuttleOptionReset,
  getShuttleOption,
  getVicinity,
  getVicinityReset,
  getFleetClassGroup,
  getFleetClassGroupReset,
} from "../../store/actions/index";
import SearchFilter from "../../components/filter/search-filter";
import Spinner from "../../components/UI/Spinner/Spinner";
import Pagination from "../../components/pagination/Pagination";
import { showNotification } from "../../components/UI/Toaster/Toaster";
import PageTopPanel from "../../components/common/pages/page-top-panel";
import {
  getSearchInputCtrlByUrl,
  getExactSearchInputCtrl,
  getPageName,
  getSearchAPIName,
  setActiveMenu,
  createSearchRequest,
  toggleAdvancedBasicSearchMode,
  addCancelledRservationStates,
  getSearchGridId,
  getDefaultSearchPageInfo,
  laodDdlCtrlFromCurrentResponse,
  updateSearchFilter,
  getFilterStatesValue,
  getTopMainLinkUrl,
  getActualPageName,
  getBreadcrumbDataforSearchPage,
  getChildBreadCrumbList,
  getMemberProfileBreadcrumbList,
  validateSearchFields,
  validateOnChangeSearchDateFields,
  getMergedDateAndTimeValue,
} from "./search-utility/search-utility";
import { getSearchVehicleBottomRadiolist } from "../vehicle/search/search-vehicle-input";
import NoRecordFoundComponent from "../../components/common/pages/no-record-found";
import DefaultComponentWithMessage from "../../components/common/pages/default-component-with-message";
import { ADMIN_PAGE, COMMON_MSG, TOAST_MSG, ModalType, PAGE_GROUP_TYPE, IS_EXPORT_DISPLAYED_FLEET_UTILIZATION_DATA, MAX_DAYS_FLEET_UTILIZATION_RECORD_EXPORT } from "../../shared/GlobalVar";
import {
  updateObject,
  checkValidity,
  getInfoFromParamQuery,
  populateDropdownData,
  getErrorToastData,
  getBasicToastResponse,
  camelizeKeys,
  hasObjectNonEmptyProperty,
  isNullOrEmpty,
  getDisplayTextOfDropdown,
  getCurrentServerDate,
  getDateTimeString,
} from "../../shared/utility";
import SearchVehicleGrid from "../vehicle/search/search-vehicle-grid";
import { getSearchResTopRadioBtnlist } from "../Reservation/search/search-reservation-input";
import SearchReservationGrid from "../Reservation/search/search-reservation-grid";
import SearchBillingGrid from "../billing/search-billing-grid";
import MemberSearchGrid from "../Member/search/search-member-grid";
import SearchRolesPermissionsGrid from "../roles-permissions/search/search-roles-permissions-grid";
import SearchServiceGrid from "../report/search/search-service-grid";
import SearchMechanicGrid from "../report/search/search-mechanic-grid";
import SearchReadyLineAgentGrid from "../report/search/search-ready-line-agent-grid";
import SearchActivityGrid from "../activity/search-activity-grid";
import { storeRequest } from "../../shared/LocalStorage";
import SearchItemsContainer from "./ui/search-items-container";
import SearchNormalItemsContainer from "./ui/search-normal-items-container";
import SearchMessageGrid from "../message/search-message-grid";
import SearchFavouriteGrid from "../favourite/search-favourite-grid";
import SearchMyVehicleGrid from "../vehicle/search/search-my-vehicle-grid";
import ModalInfo from "../../services/entities/models/ModalInfo";
import ModalEngine from "../../components/modal/modal-engine";
import { SearchLandmarksGrid } from "../landmarks/search/search-landmarks-grid";
import SearchShuttlesGrid from "../shuttles/search/search-shuttles-grid";
import SearchShuttleTripsGrid from "../report/shuttle-trips/search/search-shuttle-trips-grid";
import SearchFleetUtilizationForecast from "../fleet-utilization/search/search-fleet-utilization-forecast";
import { getShuttleOptionRequest } from "../../shared/utility/shuttle-app-utility";
import { ShuttleOptions } from "../../services/entities/shuttle-app/domain/shuttle-options";
import { ShuttleOptionsViewModel } from "../../services/entities/shuttle-app/view-model/shuttle-options-view-model";
import { convertMsgResponse } from "../Reservation/fox-reservation/fox-res-helper";
import VicinityViewModel from "../../services/entities/shuttle-app/view-model/vicinity-view-model";
import VicinityRequest from "../../services/entities/shuttle-app/request/vicinity-request";
import { getMemberLabelByOrgRentalType } from "../../shared/utility/system-utility";
import PeriodicFilter from "../../components/preiodic-filter/preiodic-filter";

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}
export const SearchPageContainer = (props) => {
  const isFirstRun = useRef(true);
  const loading = useSelector(
    (state) =>
      state.enumvaluesReducer.loading ||
      state.searchPagesReducer.loading ||
      state.memberReducer.loading ||
      state.reservationReducer.loading ||
      state.transactionReducer.loading ||
      state.invoiceInformationReducer.loading ||
      state.vehicleReducer.loading ||
      // state.activityReducer.loading ||
      state.shuttleOptionReducer.loading ||
      state.vicinityReducer.loading
  );
  const dependentOrg = useSelector(
    (state) => state.auth.loggedInUserData.DependantOrgList
  );
  const selectedOrgKeyValue = useSelector(
    (state) => state.auth.loggedInUserData.SelectedOrgKeyValue
  );
  const orgRolesDataResponse = useSelector(
    (state) => state.rolesPermissionsReducer.orgRolesDataResponse
  );
  const vehicleCategories = useSelector(
    (state) => state.auth.loggedInUserData.VehicleCategories
  );
  const searchDataResponse = useSelector(
    (state) => state.searchPagesReducer.searchDataResponse || null
  );
  const paymentReasonTypes = useSelector(
    (state) => state.enumvaluesReducer.paymentReasonTypes || null
  );
  const integStatuses = useSelector(
    (state) => state.enumvaluesReducer.integStatuses || null
  );
  const activityLogProblemTypes = useSelector(
    (state) => state.enumvaluesReducer.activityLogProblemTypes || null
  );
  const activityLogSubProblemTypes = useSelector(
    (state) => state.enumvaluesReducer.activityLogSubProblemTypes || null
  );
  const billingTypes = useSelector(
    (state) => state.enumvaluesReducer.billingTypes || null
  );
  const locationTypes = useSelector(
    (state) => state.enumvaluesReducer.locationTypes || null
  );
  const shuttleOptionResponse = useSelector(
    (state) => state.shuttleOptionReducer.shuttleOptionResponse || null
  );
  const vicinityResponse = useSelector(
    (state) => state.vicinityReducer.vicinityResponse || null
  );
  const orgUnitFleetClassGroupsResponse = useSelector(
    (state) => state.adminOptionReducer.orgUnitFleetClassGroupsResponse || null
  );
  const [modalInfo, setModalInfo] = useState([new ModalInfo()]);
  const dispatch = useDispatch();
  const closeModal = () => {
    setModalInfo(new ModalInfo());
  };
  const showModal = (modalData) => {
    setModalInfo({
      type: modalData.type,
      showModal: true,
    });
  };
  let searchPageInfo = getDefaultSearchPageInfo(
    props.searchModalType || props.match.url
  );

  let breadCrumbList =
    props.otherData && props.otherData.breadCrumbList
      ? getMemberProfileBreadcrumbList(
        props.otherData,
        getInfoFromParamQuery("memberId", props.location.search)
      )
      : props.match.url
        ? getBreadcrumbDataforSearchPage(props.match.url, props.location)
        : [];
  const pageName = getPageName(props.searchModalType || props.match.url);
  const allwedPagesToSetDateDurationLimit = [ADMIN_PAGE.RESERVATION_SEARCH_PAGE];
  const startDateControlName = "StartDate";
  const endDateControlName = "EndDate";
  const allowedDurationInDays = 30;

  let prevProps = usePrevious(pageName);

  const [updatedControls, setUpdateCtrls] = useState(
    getSearchInputCtrlByUrl(
      props.searchModalType || props.match.url,
      props.searchModalType ? true : false
    )
  );
  const [searchData, setSearchData] = useState(null);
  const [exportedData, setExportedData] = useState(null);
  const [showExportModal, setExportModal] = useState(false);
  const [actualGrid, setActulaGrid] = useState(null);
  const [resetSearchPage, setResetSearchPage] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isSearchOnValueChange, setIsSearchOnValueChange] = useState(false);
  const [isLiveView, setIsLiveView] = useState(false);
  const [vicinityData, setVicinityData] = useState(null);
  const [shuttleOptionsData, setShuttleOptionsData] = useState({});
  const [shuttleOptionsRequest, setShuttleOptionsRequest] = useState({});
  useEffect(() => {
    if (prevProps && prevProps !== pageName) {
      initSearchPage(
        getSearchInputCtrlByUrl(
          props.searchModalType || props.match.url,
          props.searchModalType ? true : false
        )
      );
    }
  });
  useEffect(() => {
    initSearchPage(updatedControls);
  }, [paymentReasonTypes]);
  const updateCtrlWithNewDate = (searchInputCtrl) => {
    Object.keys(searchInputCtrl).map((controlName, index) => {
      if (controlName === startDateControlName && isNullOrEmpty(searchInputCtrl[endDateControlName].value)) {
        const margedDate = getMergedDateAndTimeValue(searchInputCtrl[controlName]);
        let maxDateValue = searchInputCtrl[controlName].value
          ? moment(getDateTimeString(margedDate)).add(allowedDurationInDays - 1, "day").toDate()
          : moment(getDateTimeString(getCurrentServerDate())).toDate()
        searchInputCtrl[endDateControlName]["value"] = moment(maxDateValue).format("MM:DD:YYYY:HH:mm");
        searchInputCtrl[endDateControlName]["timePicker"]["value"] = moment(maxDateValue).format("MM:DD:YYYY:HH:mm");
      }
      if (controlName === endDateControlName && isNullOrEmpty(searchInputCtrl[startDateControlName].value)) {
        const margedDate = getMergedDateAndTimeValue(searchInputCtrl[controlName]);
        let minDateValue = searchInputCtrl[controlName].value
          ? moment(getDateTimeString(margedDate)).add(1 - allowedDurationInDays, "day").toDate()
          : moment(getDateTimeString(getCurrentServerDate())).toDate()
        searchInputCtrl[startDateControlName]["value"] = moment(minDateValue).format("MM:DD:YYYY:HH:mm");
        searchInputCtrl[startDateControlName]["timePicker"]["value"] = moment(minDateValue).format("MM:DD:YYYY:HH:mm");
      }
    });
    return searchInputCtrl;
  };
  const initSearchPage = (searchCtrls) => {
    isFirstRun.current = true;
    dispatch(getOrgRolesDataReset());
    dispatch(getSearchResultReset());
    dispatch(getShuttleOptionReset());
    if (props.match.url) {
      setActiveMenu(props.match.url);
    }
    searchPageInfo = getDefaultSearchPageInfo(
      props.searchModalType || props.match.url
    );
    if (
      !paymentReasonTypes ||
      !integStatuses ||
      !activityLogProblemTypes ||
      !activityLogSubProblemTypes ||
      !billingTypes ||
      !locationTypes
    ) {
      dispatch(getEnumvalues());
    }
    if (
      (!shuttleOptionResponse ||
        !hasObjectNonEmptyProperty(shuttleOptionsData)) &&
      [
        ADMIN_PAGE.SHUTTLE_SEARCH_PAGE,
        ADMIN_PAGE.LANDMARK_SEARCH_PAGE,
        ADMIN_PAGE.SHUTTLE_TRIP_SEARCH_PAGE,
      ].includes(pageName)
    ) {
      searchShuttleOptions();
    }
    const inputCtrl = laodDdlCtrlFromCurrentResponse(
      searchCtrls,
      pageName,
      searchPageInfo,
      selectedOrgKeyValue,
      dependentOrg,
      vehicleCategories,
      paymentReasonTypes,
      integStatuses,
      activityLogProblemTypes,
      activityLogSubProblemTypes,
      null,
      billingTypes,
      locationTypes,
      shuttleOptionsData
    );
    if (pageName === ADMIN_PAGE.MEMBER_VEHICLE_RESERVATION_SEARCH_PAGE) {
      inputCtrl.UnitNumber.value =
        getInfoFromParamQuery("unitNumber", props.location.search) || null;
    }
    //TO DO
    if (pageName === ADMIN_PAGE.RESERVE_SYNC_PAGE) {
      getFleetClassGroups(inputCtrl.locationId.value);
      if (inputCtrl.locationId.value) {
        getFleetClassGroups(inputCtrl.locationId.value);
      }
    }
    setUpdateCtrls(inputCtrl);
    setSearchData(null);
    if (searchPageInfo.pageGroupType === PAGE_GROUP_TYPE.PAGE_GROUP_ROLES_PERMISSIONS) {
      getOrgRolesInfo(inputCtrl.EmployeeLocationId.value);
    }
    setActulaGrid(getActualSearchGrid(pageName));
  };
  useEffect(() => {
    if (!vicinityResponse) {
      return;
    }
    if (
      vicinityResponse.message &&
      vicinityResponse.message.messageType === "SUCCESS"
    ) {
      setVicinityData(new VicinityViewModel(vicinityResponse));
      setIsLiveView(true);
    } else {
      setIsLiveView(false);
      showNotification(convertMsgResponse(vicinityResponse.message));
    }
    dispatch(getVicinityReset());
  }, [vicinityResponse]);
  useEffect(() => {
    if (
      paymentReasonTypes &&
      integStatuses &&
      activityLogProblemTypes &&
      activityLogSubProblemTypes &&
      shuttleOptionResponse
    ) {
      const inputCtrl = laodDdlCtrlFromCurrentResponse(
        updatedControls,
        pageName,
        searchPageInfo,
        selectedOrgKeyValue,
        dependentOrg,
        vehicleCategories,
        paymentReasonTypes,
        integStatuses,
        activityLogProblemTypes,
        activityLogSubProblemTypes,
        null,
        billingTypes,
        locationTypes,
        shuttleOptionsData
      );
      setUpdateCtrls(inputCtrl);
    }
  }, [
    paymentReasonTypes,
    integStatuses,
    activityLogProblemTypes,
    activityLogSubProblemTypes,
    shuttleOptionResponse,
    shuttleOptionsData,
  ]);
  useEffect(() => {
    if (
      searchPageInfo.pageGroupType === PAGE_GROUP_TYPE.PAGE_GROUP_RESERVATION ||
      searchPageInfo.pageGroupType === PAGE_GROUP_TYPE.PAGE_GROUP_BILLING ||
      searchPageInfo.pageGroupType === PAGE_GROUP_TYPE.PAGE_GROUP_SERVICE_MECHANIC ||
      searchPageInfo.pageGroupType === PAGE_GROUP_TYPE.PAGE_GROUP_ACTIVITY
    ) {
      //// To Apply "Auto Search Confim Modal" just uncommented below code and to apply it into all the auto search page remove pageName Condition
      // if (isFirstRun.current && (searchPageInfo.pageGroupType === PAGE_GROUP_TYPE.PAGE_GROUP_RESERVATION || searchPageInfo.pageGroupType === PAGE_GROUP_TYPE.PAGE_GROUP_BILLING) && !showExportModal) {
      //   showModal({
      //     type: ModalType.COUNT_DOWN_TIMER_MODAL,
      //     showModal: true,
      //   });
      //   isFirstRun.current = false;
      //   return;
      // }
      getSearchList(updatedControls);
    } else if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    } else {
      getSearchList(updatedControls);
    }
  }, [
    updatedControls.PageInfo.SortBy,
    updatedControls.PageInfo.SortType,
    updatedControls.PageInfo.PageNumber,
    updatedControls.PageInfo.PerPageCount,
    updatedControls.ReservationState && updatedControls.ReservationState.value,
    updatedControls.Status,
    updatedControls.AuditStatus,
    isSearchOnValueChange,
  ]);
  useEffect(() => {
    if (!searchDataResponse) {
      return;
    }
    if (
      searchDataResponse &&
      searchDataResponse.StatusCode === 0 &&
      prevProps
    ) {
      if (searchDataResponse && showExportModal) {
        if (searchDataResponse.TotalRecords) {
          setExportedData(searchDataResponse.SearchList);
        } else {
          showNotification(getErrorToastData(COMMON_MSG.NoRecordFound));
          dispatch(getSearchResultReset());
          setExportedData(null);
          setExportModal(false);
        }
      } else {
        if (!props.searchModalType) {
          storeRequest(pageName, updatedControls);
        }
        setSearchData(searchDataResponse);
        setExportedData(null);
        setExportModal(false);
      }
    } else {
      if (searchDataResponse.StatusCode > 0) {
        showNotification(getBasicToastResponse(searchDataResponse));
      }
      dispatch(getSearchResultReset());
    }
  }, [searchDataResponse]);
  useEffect(() => {
    if (prevProps && prevProps === pageName) {
      setActulaGrid(getActualSearchGrid(pageName));
    }
  }, [searchData, vicinityData, isLiveView, updatedControls.locationCodes]);
  useEffect(() => {
    if (showExportModal) {
      getSearchList(updatedControls);
    }
  }, [showExportModal]);
  useEffect(() => {
    if (resetSearchPage) {
      let inputCtrl = getExactSearchInputCtrl(
        props.searchModalType || props.match.url
      );

      if (searchPageInfo.pageGroupType === PAGE_GROUP_TYPE.PAGE_GROUP_ROLES_PERMISSIONS) {
        getOrgRolesInfo(inputCtrl.EmployeeLocationId.value);
      }
      inputCtrl = laodDdlCtrlFromCurrentResponse(
        inputCtrl,
        pageName,
        searchPageInfo,
        selectedOrgKeyValue,
        dependentOrg,
        vehicleCategories,
        paymentReasonTypes,
        integStatuses,
        activityLogProblemTypes,
        activityLogSubProblemTypes,
        orgRolesDataResponse ? orgRolesDataResponse.Roles : null,
        billingTypes,
        locationTypes,
        shuttleOptionsData
      );

      if (
        searchPageInfo.pageGroupType === PAGE_GROUP_TYPE.PAGE_GROUP_RESERVATION ||
        searchPageInfo.pageGroupType === PAGE_GROUP_TYPE.PAGE_GROUP_BILLING
      ) {
        getSearchList(inputCtrl);
      }
      setUpdateCtrls(inputCtrl);
      setResetSearchPage(false);
    }
  }, [resetSearchPage]);
  useEffect(() => {
    if (
      pageName === ADMIN_PAGE.ROLES_PERMISSION_SEARCH_PAGE &&
      orgRolesDataResponse
    ) {
      if (orgRolesDataResponse.StatusCode === 0) {
        loadOrgRolesData(updatedControls, orgRolesDataResponse);
      } else {
        showNotification(getBasicToastResponse(orgRolesDataResponse));
        dispatch(getOrgRolesDataReset());
      }
    }
  }, [orgRolesDataResponse]);
  useEffect(() => {
    if (!shuttleOptionResponse) {
      return;
    }
    if (
      shuttleOptionResponse.message &&
      shuttleOptionResponse.message.messageType === "SUCCESS"
    ) {
      setShuttleOptionsData(
        new ShuttleOptionsViewModel(
          shuttleOptionResponse,
          shuttleOptionsRequest
        )
      );
    } else {
      showNotification(convertMsgResponse(shuttleOptionResponse.message));
      dispatch(getShuttleOptionReset());
    }
  }, [shuttleOptionResponse]);
  useEffect(() => {
    if (
      pageName === ADMIN_PAGE.RESERVE_SYNC_PAGE &&
      orgUnitFleetClassGroupsResponse
    ) {
      if (orgUnitFleetClassGroupsResponse.message && orgUnitFleetClassGroupsResponse.message.messageType === "SUCCESS") {
        loadFleetClassGroups(updatedControls, orgUnitFleetClassGroupsResponse);
      } else {
        showNotification(convertMsgResponse(orgUnitFleetClassGroupsResponse.message));
        dispatch(getFleetClassGroupReset());
      }
    }
  }, [orgUnitFleetClassGroupsResponse]);
  const inputChangedHandler = (event, controlName, elementType, otherValue) => {
    let value = null;
    let newCtrl = { ...updatedControls };
    if (
      event !== "Invalid date" &&
      (elementType === "datetimepicker" ||
        elementType === "dateandtimepicker" ||
        elementType === "datepicker" ||
        elementType === "timepicker")
    ) {
      if (
        otherValue &&
        otherValue.parentId &&
        !newCtrl[otherValue.parentId].value
      ) {
        value = null;
      } else {
        value = event ? moment(event).format("MM:DD:YYYY:HH:mm") : event;
      }
      if (allwedPagesToSetDateDurationLimit.includes(pageName)) {
        if (elementType !== "timepicker") {
          newCtrl = updateObject(newCtrl, {
            [controlName]: updateObject(newCtrl[controlName], {
              value: value,
              valid: checkValidity(value, newCtrl[controlName].validation),
              touched: true,
            }),
          });
          newCtrl = updateCtrlWithNewDate(newCtrl);
        }
      }
    } else if (elementType === "radio") {
      value = event.target.checked;
    } else if (elementType === "checkbox") {
      value = event.target.checked;
    } else if (elementType === "select") {
      value = event.target.value;
    } else if (elementType === "custom_dropdown") {
      value = event.key;
    } else if (elementType === "cb_dropdown") {
      newCtrl[controlName].elementConfig.selectMultipleOption = [...event];
      newCtrl[controlName].value = [];
      event.map((cbddlObj) => {
        newCtrl[controlName].value.push(cbddlObj.key);
      });
      setUpdateCtrls(newCtrl);
      return false;
    } else {
      value = event.target.value;
    }
    if (allwedPagesToSetDateDurationLimit.includes(pageName)) {
      let msg = validateOnChangeSearchDateFields(
        controlName === startDateControlName
          ? value
          : newCtrl[startDateControlName].value,
        controlName === endDateControlName
          ? value
          : newCtrl[endDateControlName].value
      );
      if (msg) {
        var toastMsg = {
          msg: msg,
          toastType: TOAST_MSG.WARNING,
        };
        showNotification(toastMsg);
      }
    }
    if (otherValue === "labelInput") {
      newCtrl[controlName][otherValue]["value"] = value;
    } else if (elementType === "timepicker" && otherValue) {
      if (otherValue.parentId) {
        newCtrl[otherValue.parentId].timePicker.value = value;
      }
    } else {
      newCtrl = updateObject(newCtrl, {
        [controlName]: updateObject(newCtrl[controlName], {
          value: value,
          valid: checkValidity(value, newCtrl[controlName].validation),
          touched: true,
        }),
      });
    }
    if (controlName === "SearchByScheduledDate") {
      newCtrl = updateObject(newCtrl, {
        SearchByCancellationDate: updateObject(
          newCtrl["SearchByCancellationDate"],
          { value: false }
        ),
        SearchByCreationDate: updateObject(newCtrl["SearchByCreationDate"], {
          value: false,
        }),
      });
    } else if (controlName === "SearchByCancellationDate") {
      newCtrl = updateObject(newCtrl, {
        SearchByScheduledDate: updateObject(newCtrl["SearchByScheduledDate"], {
          value: false,
        }),
        SearchByCreationDate: updateObject(newCtrl["SearchByCreationDate"], {
          value: false,
        }),
      });
      newCtrl.ReservationState.value = addCancelledRservationStates(
        newCtrl.ReservationState.value
      );
      const msg =
        "NOTE: The cancelled filter is now turned on, and all other filters are unavailable in this mode.";

      var toastMsg = {
        msg: msg,
        toastType: TOAST_MSG.WARNING,
      };
      showNotification(toastMsg);
    } else if (controlName === "SearchByCreationDate") {
      newCtrl = updateObject(newCtrl, {
        SearchByScheduledDate: updateObject(newCtrl["SearchByScheduledDate"], {
          value: false,
        }),
        SearchByCancellationDate: updateObject(
          newCtrl["SearchByCancellationDate"],
          { value: false }
        ),
      });
    }
    if (controlName === "EmployeeLocationId") {
      getOrgRolesInfo(event.target.value);
    }
    if (controlName === "ProblemType") {
      const subreasonList = activityLogSubProblemTypes
        ? [...activityLogSubProblemTypes]
        : [];
      let subReasons = [{ Name: "", DisplayName: "Select Subtype" }].concat(
        subreasonList.filter((item) => item.ParentName === value)
      );
      newCtrl = populateDropdownData(
        "SubProblemType",
        subReasons,
        newCtrl,
        "problemType"
      );
    }
    if (controlName === "SearchValue") {
      if (newCtrl[controlName]["labelInput"].value === "RESERVATION") {
        newCtrl[controlName].elementConfig.placeholder = "";
      } else {
        newCtrl[controlName].elementConfig.placeholder =
          getMemberLabelByOrgRentalType() + " or Loyalty ID";
      }
    }
    setUpdateCtrls(newCtrl);
    if (pageName === ADMIN_PAGE.SHUTTLE_TRIP_SEARCH_PAGE) {
      if (controlName === "locationCode") {
        searchShuttleOptions(value);
      }
    }
    if ([PAGE_GROUP_TYPE.PAGE_GROUP_VEHICLE].includes(searchPageInfo.pageGroupType)) {
      if (elementType === "checkbox") {
        setIsSearchOnValueChange(!isSearchOnValueChange);
      }
    }
  };
  const showAdvancedBasic = () => {
    return setUpdateCtrls(toggleAdvancedBasicSearchMode(updatedControls));
  };
  const getExportSearchData = (showModal) => {
    setExportedData(null);
    setExportModal(showModal);
  };
  const resetExportDoc = () => {
    setExportedData(null);
    setExportModal(false);
  };
  const onPageChanged = (currentPage, recordsPerPage) => {
    let newCtrl = { ...updatedControls };
    newCtrl.PageInfo.PageNumber = currentPage;
    newCtrl.PageInfo.PerPageCount = recordsPerPage;
    setUpdateCtrls(newCtrl);
  };
  const applySearchFilter = (reservationStates) => {
    let newCtrl = updateSearchFilter(
      updatedControls,
      reservationStates,
      pageName
    );
    setUpdateCtrls(newCtrl);
  };
  const doSorting = (sortType, sortBy) => {
    let newCtrl = { ...updatedControls };
    newCtrl.PageInfo.SortBy = sortBy;
    newCtrl.PageInfo.SortType = sortType;
    setUpdateCtrls(newCtrl);
  };
  const submitHandler = () => {
    let newCtrl = { ...updatedControls };
    newCtrl.PageInfo.PageNumber = 1;
    setUpdateCtrls(newCtrl);
    getSearchList(newCtrl);
  };
  const resetHandler = () => {
    setResetSearchPage(true);
  };
  const getOrgRolesInfo = (locationId) => {
    const orgRolesReq = {};
    orgRolesReq.RoleIds = null;
    orgRolesReq.LocationId = locationId
      ? parseInt(locationId)
      : localStorage.getItem("currentOrgUnitId");
    dispatch(getOrgRolesDataReset());
    dispatch(getOrgRolesData(orgRolesReq));
  };
  const loadOrgRolesData = (updatedControlsObj, rolesDataResponse) => {
    let newCtrl = { ...updatedControlsObj };
    if (rolesDataResponse.Roles && rolesDataResponse.Roles.length > 0) {
      newCtrl = populateDropdownData(
        "RoleId",
        rolesDataResponse.Roles,
        newCtrl,
        "roles"
      );
      setUpdateCtrls(newCtrl);
    }
  };
  const loadFleetClassGroups = (updatedControlsObj, fleetClassGroupsResponse) => {
    let newCtrl = { ...updatedControlsObj };
    if (fleetClassGroupsResponse.orgUnitFleetClassGroups && fleetClassGroupsResponse.orgUnitFleetClassGroups.length > 0) {
      newCtrl = populateDropdownData(
        "orgUnitFleetClassGroupId",
        fleetClassGroupsResponse.orgUnitFleetClassGroups,
        newCtrl,
        "orgUnitFleetClassGroupId"
      );
      setUpdateCtrls(newCtrl);
    }
  };
  const searchShuttleOptions = (locationCode) => {
    let shuttleOptions = new ShuttleOptions();
    if (pageName === ADMIN_PAGE.SHUTTLE_SEARCH_PAGE) {
      shuttleOptions.locations = true;
      shuttleOptions.amenities = true;
      shuttleOptions.engineStatuses = true;
      shuttleOptions.availabilities = true;
      shuttleOptions.drivingTypes = true;
      shuttleOptions.fuelTypes = true;
      shuttleOptions.fuelConsumptionUnitTypes = true;
      shuttleOptions.transMissionTypes = true;
      shuttleOptions.colorTypes = true;
      shuttleOptions.shuttleConnectionStatuses = true;
    }
    if (pageName === ADMIN_PAGE.LANDMARK_SEARCH_PAGE) {
      shuttleOptions.locations = true;
      shuttleOptions.landmarkCategories = true;
      shuttleOptions.landmarkStatuses = true;
    }
    if (pageName === ADMIN_PAGE.SHUTTLE_TRIP_SEARCH_PAGE) {
      shuttleOptions.locations = true;
      shuttleOptions.allShuttles = true;
      shuttleOptions.locationShuttles = !isNullOrEmpty(locationCode);
      shuttleOptions.shuttleLocationCode = locationCode || "";
      shuttleOptions.allDrivers = true;
      shuttleOptions.locationDrivers = !isNullOrEmpty(locationCode);
      shuttleOptions.driverLocationCode = locationCode || "";
    }

    let shuttleOptionReq = getShuttleOptionRequest(shuttleOptions);
    setShuttleOptionsRequest(shuttleOptionReq);
    dispatch(getShuttleOptionReset());
    dispatch(getShuttleOption(shuttleOptionReq));
  };
  /**Admin option */
  const getFleetClassGroups = (locationId) => {
    const req = {};
    req.fleetClassGroupOrgUnitId = locationId
      ? parseInt(locationId)
      : localStorage.getItem("currentOrgUnitId");
    dispatch(getFleetClassGroupReset());
    dispatch(getFleetClassGroup(req));
  };
  /**End Admin option */
  const getSearchList = (inputCtrl) => {
    const searcRequestObj1 = {};
    const searcRequestObj = createSearchRequest(
      inputCtrl,
      pageName,
      showExportModal,
      props.location && props.location.search ? props.location.search : null
    );
    let isSytemApi = false;
    if (
      [
        ADMIN_PAGE.LANDMARK_SEARCH_PAGE,
        ADMIN_PAGE.SHUTTLE_SEARCH_PAGE,
        ADMIN_PAGE.SHUTTLE_TRIP_SEARCH_PAGE,
      ].includes(pageName)
    ) {
      searcRequestObj["pagination"] = camelizeKeys(searcRequestObj.PageInfo);
      delete searcRequestObj["PageInfo"];
      delete searcRequestObj["pageInfo"];
      isSytemApi = true;
    }
    if (
      [ADMIN_PAGE.RESERVE_SYNC_PAGE,
      ].includes(pageName)
    ) {
      if (!searcRequestObj.locationId) {
        showNotification({
          msg: "Please select a location !!!",
          toastType: TOAST_MSG.WARNING,
        });
        return false;
      }
      searcRequestObj["pagination"] = camelizeKeys(searcRequestObj.PageInfo);
      delete searcRequestObj["PageInfo"];
      delete searcRequestObj["pageInfo"];
      isSytemApi = true;
      // delete searcRequestObj["searchViewType"];
      // delete searcRequestObj["periodicFilterInfo"];
      // searcRequestObj.pagination.pageNumber = 0;
      // searcRequestObj.pagination.perPageCount = 14;
      // if(showExportModal && !IS_EXPORT_DISPLAYED_FLEET_UTILIZATION_DATA){
      //   const numberOfDaysToExport = MAX_DAYS_FLEET_UTILIZATION_RECORD_EXPORT;
      //   searcRequestObj.forecastStartDate = moment(moment(getCurrentServerDate(`YYYY-MM-DD`))).format(`YYYY-MM-DD`)
      //   searcRequestObj.forecastEndDate = moment(searcRequestObj.forecastStartDate).add(numberOfDaysToExport-1, "day").format(`YYYY-MM-DD`)
      //   searcRequestObj.pagination.perPageCount = 2000;
      // }
      // if (searcRequestObj.pause === "false") {
      //   searcRequestObj.pause = false;
      // } else if (searcRequestObj.pause === "true") {
      //   searcRequestObj.pause = true;
      // }

      // searcRequestObj1.requestHeader = searcRequestObj.requestHeader;
      // delete searcRequestObj["requestHeader"];
      // searcRequestObj1.fleetUtilizationForecastSearchInfo = { ...searcRequestObj };

    }
    if ([ADMIN_PAGE.RESERVATION_SEARCH_PAGE].includes(pageName)) {
      let msg = validateSearchFields(searcRequestObj.ReservationSearchInfo);
      if (msg) {
        var toastMsg = {
          msg: msg,
          toastType: TOAST_MSG.WARNING,
        };
        showNotification(toastMsg);
        return false;
      }
      if(isNullOrEmpty(searcRequestObj.ReservationSearchInfo.EndDate)){
        let defaultToDate = moment(moment(getDateTimeString(searcRequestObj.ReservationSearchInfo.StartDate || getCurrentServerDate())).add(allowedDurationInDays-1, "day").toDate()).format("MM:DD:YYYY:HH:mm");
        searcRequestObj.ReservationSearchInfo.EndDate = defaultToDate;
      }
    }
    setIsLiveView(false);
    dispatch(getVicinityReset());
    dispatch(getSearchResultReset());
    // if ([ADMIN_PAGE.RESERVE_SYNC_PAGE,
    // ].includes(pageName)) {
    //   dispatch(
    //     getSearchResult(getSearchAPIName(pageName), searcRequestObj1, isSytemApi)
    //   );
    // } else {
    dispatch(
      getSearchResult(getSearchAPIName(pageName), searcRequestObj, isSytemApi)
    );
    //}
  };
  const getSearchListFromSearchReq = (searcRequestObj, isSytemApi) => {
    dispatch(getSearchResultReset());
    dispatch(
      getSearchResult(getSearchAPIName(pageName), searcRequestObj, isSytemApi)
    );
  };
  const resetLoader = (isLoaded) => {
    setLoading(isLoaded);
  };
  const refreshMapdata = (showLoader) => {
    dispatch(getVicinityReset());
    const vicinityReq = new VicinityRequest();
    vicinityReq.locationCode = updatedControls.locationCodes.value[0];
    vicinityReq.shuttles = true;
    vicinityReq.landmarks = true;
    dispatch(getVicinity(vicinityReq, showLoader));
  };
  const onLiveIcoClick = () => {
    if (pageName === ADMIN_PAGE.SHUTTLE_SEARCH_PAGE && isLiveView === false) {
      if (
        !updatedControls.locationCodes.value ||
        updatedControls.locationCodes.value.length === 0 ||
        updatedControls.locationCodes.value.length > 1
      ) {
        var toastMsg = {
          msg: !updatedControls.locationCodes.value.length
            ? COMMON_MSG.VICINITY_NO_LOCATION_REQ_MSG
            : ReactHtmlParser(COMMON_MSG.VICINITY_MULTI_LOCATION_REQ_MSG),
          toastType: TOAST_MSG.WARNING,
        };
        showNotification(toastMsg);
      } else {
        refreshMapdata(true);
      }
    } else {
      setIsLiveView(false);
    }
  };
  const getActualSearchGrid = (pageNameStr) => {
    let renderedGrid = "";
    const childBredCrumbList = getChildBreadCrumbList(
      props.match.url,
      props.location,
      breadCrumbList
    );

    if (searchPageInfo.pageGroupType === PAGE_GROUP_TYPE.PAGE_GROUP_MEMBER) {
      renderedGrid = (
        <MemberSearchGrid
          dataList={
            searchData && searchData.SearchList ? searchData.SearchList : []
          }
          key="memberSearchGrid"
          url={props.searchModalType || props.match.url}
          breadCrumbList={breadCrumbList}
          resetLoader={resetLoader}
          resetParentPage={submitHandler}
        />
      );
    } else if (searchPageInfo.pageGroupType === PAGE_GROUP_TYPE.PAGE_GROUP_VEHICLE) {
      renderedGrid = (
        <SearchVehicleGrid
          dataList={
            searchData && searchData.SearchList ? searchData.SearchList : []
          }
          key="vehicleResGrid"
          url={props.searchModalType || props.match.url}
          breadCrumbList={childBredCrumbList}
          resetParentPage={submitHandler}
        />
      );
    } else if (searchPageInfo.pageGroupType === PAGE_GROUP_TYPE.PAGE_GROUP_RESERVATION) {
      renderedGrid = (
        <SearchReservationGrid
          dataList={
            searchData && searchData.SearchList ? searchData.SearchList : []
          }
          key="reservationGrid"
          pageInfo={updatedControls.PageInfo}
          url={props.searchModalType || props.match.url}
          doSorting={doSorting}
          breadCrumbList={childBredCrumbList}
          pageName={pageNameStr}
        />
      );
    } else if (searchPageInfo.pageGroupType === PAGE_GROUP_TYPE.PAGE_GROUP_BILLING) {
      renderedGrid = (
        <SearchBillingGrid
          dataList={
            searchData && searchData.SearchList ? searchData.SearchList : []
          }
          key="searchBillingGrid"
          url={props.searchModalType || props.match.url}
          pageInfo={updatedControls.PageInfo}
          doSorting={doSorting}
          breadCrumbList={childBredCrumbList}
          pageName={pageNameStr}
        />
      );
    } else if (searchPageInfo.pageGroupType === PAGE_GROUP_TYPE.PAGE_GROUP_ROLES_PERMISSIONS) {
      renderedGrid = (
        <SearchRolesPermissionsGrid
          dataList={
            searchData && searchData.SearchList ? JSON.parse(JSON.stringify(searchData.SearchList)) : []
          }
          key="rolesPermissionsGrid"
          url={props.searchModalType || props.match.url}
          pageInfo={updatedControls.PageInfo}
          doSorting={doSorting}
          breadCrumbList={breadCrumbList}
          resetParentPage={submitHandler}
        />
      );
    } else if (searchPageInfo.pageGroupType === PAGE_GROUP_TYPE.PAGE_GROUP_SHUTTLE) {
      renderedGrid = (
        <SearchShuttlesGrid
          dataList={
            searchData && searchData.SearchList ? searchData.SearchList : []
          }
          key="landmarkGrid"
          url={props.searchModalType || props.match.url}
          breadCrumbList={childBredCrumbList}
          pageInfo={updatedControls.PageInfo}
          doSorting={doSorting}
          resetParentPage={submitHandler}
          refreshMapdata={refreshMapdata}
          isLiveView={isLiveView}
          vicinityData={vicinityData}
          selectedLoc={updatedControls && updatedControls.locationCodes ? updatedControls.locationCodes.value[0] : null}
        />
      );
    } else if (searchPageInfo.pageGroupType === PAGE_GROUP_TYPE.PAGE_GROUP_LANDMARK) {
      renderedGrid = (
        <SearchLandmarksGrid
          dataList={
            searchData && searchData.SearchList ? searchData.SearchList : []
          }
          key="landmarkGrid"
          url={props.searchModalType || props.match.url}
          breadCrumbList={childBredCrumbList}
          pageInfo={updatedControls.PageInfo}
          doSorting={doSorting}
          resetParentPage={submitHandler}
        />
      );
    } else if (pageNameStr === ADMIN_PAGE.RESERVE_SYNC_PAGE) {
      renderedGrid = (
        <SearchFleetUtilizationForecast
          dataList={
            searchData && searchData.SearchList ? searchData.SearchList : []
          }
          key="fleetForecastGrid"
          url={props.searchModalType || props.match.url}
          breadCrumbList={childBredCrumbList}
          pageInfo={updatedControls.PageInfo}
          searchViewType={updatedControls && updatedControls.searchViewType ? updatedControls.searchViewType.value : null}
          periodicFilterInfo={updatedControls ? updatedControls.periodicFilterInfo : null}
          doSorting={doSorting}
          resetParentPage={submitHandler}
          orgUnitFleetClassGroupId={updatedControls && updatedControls.orgUnitFleetClassGroupId ? updatedControls.orgUnitFleetClassGroupId.value : null}
          startDate={updatedControls && updatedControls.forecastStartDate ? updatedControls.forecastStartDate.value : null}
          endDate={updatedControls && updatedControls.forecastEndDate ? updatedControls.forecastEndDate.value : null}
        />
      );
    } else if (pageNameStr === ADMIN_PAGE.SERVICE_AGENT_SEARCH_PAGE) {
      renderedGrid = (
        <SearchServiceGrid
          dataList={
            searchData && searchData.SearchList ? searchData.SearchList : []
          }
          key="maintenanceGrid"
          url={props.searchModalType || props.match.url}
          pageInfo={updatedControls.PageInfo}
          doSorting={doSorting}
          breadCrumbList={breadCrumbList}
        />
      );
    } else if (pageNameStr === ADMIN_PAGE.MECHANIC_APP_REPORT_SEARCH_PAGE) {
      renderedGrid = (
        <SearchMechanicGrid
          dataList={
            searchData && searchData.SearchList ? searchData.SearchList : []
          }
          key="mechanicGrid"
          url={props.searchModalType || props.match.url}
          pageInfo={updatedControls.PageInfo}
          doSorting={doSorting}
          breadCrumbList={childBredCrumbList}
        />
      );
    } else if (pageNameStr === ADMIN_PAGE.READY_LINE_AGENT_SEARCH_PAGE) {
      renderedGrid = (
        <SearchReadyLineAgentGrid
          dataList={
            searchData && searchData.SearchList ? searchData.SearchList : []
          }
          key="readyLineAgentGrid"
          url={props.searchModalType || props.match.url}
          pageInfo={updatedControls.PageInfo}
          doSorting={doSorting}
          breadCrumbList={childBredCrumbList}
        />
      );
    } else if (pageNameStr === ADMIN_PAGE.SHUTTLE_TRIP_SEARCH_PAGE) {
      renderedGrid = (
        <SearchShuttleTripsGrid
          dataList={
            searchData && searchData.SearchList ? searchData.SearchList : []
          }
          key="shuttleTripGrid"
          url={props.searchModalType || props.match.url}
          pageInfo={updatedControls.PageInfo}
          doSorting={doSorting}
          breadCrumbList={childBredCrumbList}
        />
      );
    } else if (pageNameStr === ADMIN_PAGE.MEMBER_ACTIVITY_SEARCH_PAGE) {
      renderedGrid = (
        <SearchActivityGrid
          dataList={
            searchData && searchData.SearchList ? searchData.SearchList : []
          }
          key="activityGrid"
          url={props.searchModalType || props.match.url}
          pageInfo={updatedControls.PageInfo}
          doSorting={doSorting}
          breadCrumbList={childBredCrumbList}
        />
      );
    } else if (pageNameStr === ADMIN_PAGE.MEMBER_MESSAGE_SEARCH_PAGE) {
      renderedGrid = (
        <SearchMessageGrid
          dataList={
            searchData && searchData.SearchList ? searchData.SearchList : []
          }
          key="activityGrid"
          url={props.searchModalType || props.match.url}
          pageInfo={updatedControls.PageInfo}
          breadCrumbList={childBredCrumbList}
        />
      );
    } else if (pageNameStr === ADMIN_PAGE.MEMBER_FAVORITE_ASSET_SEARCH_PAGE) {
      renderedGrid = (
        <SearchFavouriteGrid
          dataList={
            searchData && searchData.SearchList ? searchData.SearchList : []
          }
          key="favouriteVehicleGrid"
          url={props.searchModalType || props.match.url}
          pageInfo={updatedControls.PageInfo}
          breadCrumbList={childBredCrumbList}
        />
      );
    } else if (pageNameStr === ADMIN_PAGE.MEMBER_VEHICLE_SEARCH) {
      renderedGrid = (
        <SearchMyVehicleGrid
          dataList={
            searchData && searchData.SearchList ? searchData.SearchList : []
          }
          key="myVehicleGrid"
          url={props.searchModalType || props.match.url}
          pageInfo={updatedControls.PageInfo}
          breadCrumbList={childBredCrumbList}
          resetParentPage={submitHandler}
        />
      );
    }
    return <Fragment>{renderedGrid}</Fragment>;
  };
  const renderSearchContainer = () => {
    return searchPageInfo.pageGroupType !== PAGE_GROUP_TYPE.PAGE_GROUP_NORMAL_SEARCH ? (
      <SearchItemsContainer
        isAdvancedSearch={updatedControls.isAdvancedSearch}
        bottomCbRadioList={
          searchPageInfo.pageGroupType === PAGE_GROUP_TYPE.PAGE_GROUP_VEHICLE
            ? JSON.parse(JSON.stringify(getSearchVehicleBottomRadiolist()))
            : null
        }
        searchInputCtrls={updatedControls}
        changed={inputChangedHandler}
        showAdvancedBasic={showAdvancedBasic}
        submitHandler={submitHandler}
        resetHandler={resetHandler}
        pageUrl={props.match.url}
        topCbRadioList={
          searchPageInfo.pageGroupType === PAGE_GROUP_TYPE.PAGE_GROUP_RESERVATION
            ? JSON.parse(JSON.stringify(getSearchResTopRadioBtnlist()))
            : null
        }
      />
    ) : (
      <SearchNormalItemsContainer
        searchInputCtrls={updatedControls}
        pageUrl={props.match.url}
        changed={inputChangedHandler}
        submitHandler={submitHandler}
        resetHandler={resetHandler}
      />
    );
  };
  const position = [51.505, -0.09];
  return prevProps && prevProps !== pageName ? (
    ""
  ) : (
    <Fragment>
      <section className="res-search">
        <PageTopPanel
          memberId={
            getInfoFromParamQuery("memberId", props.location.search) || null
          }
          breadCrumbList={props.otherData ? breadCrumbList : []}
          title={getActualPageName(pageName)}
          linkList={getTopMainLinkUrl(pageName)}
          showExportLink={
            pageName === ADMIN_PAGE.MEMBER_VEHICLE_SEARCH ? false : true
          }
          getExportSearchData={getExportSearchData}
          resetExportDoc={resetExportDoc}
          exportData={exportedData || []}
          pageName={pageName}
          resetParentPage={submitHandler}
          linkBreadCrumb={getChildBreadCrumbList(
            props.match.url,
            props.location,
            breadCrumbList
          )}
          isExportDisabled={searchPageInfo.isExportDisabled || false}
          fleetUtilizationSupportDataForExport={{
            updatedControls: updatedControls || null,
            locationName:
              updatedControls && updatedControls.locationId
                ? getDisplayTextOfDropdown(
                    updatedControls.locationId.elementConfig &&
                      updatedControls.locationId.elementConfig.options,
                    updatedControls.locationId.value
                  )
                : null,
          }}
        />
        {pageName !== ADMIN_PAGE.RES_DETAILS_BILLING_SEARCH_PAGE
          ? renderSearchContainer()
          : ""}
      </section>
      {searchPageInfo.isFilterRequired ? (
        <SearchFilter
          applySearchFilter={applySearchFilter}
          pageName={pageName}
          changed={inputChangedHandler}
          reservationStates={getFilterStatesValue(
            updatedControls,
            searchPageInfo.pageGroupType,
            pageName
          )}
          singleEnabledStates={
            searchPageInfo.isSingleFilterEnabled
              ? updatedControls &&
                updatedControls["SearchByCancellationDate"].value
                ? "CANCELLED"
                : null
              : null
          }
          url={props.searchModalType || props.match.url}
        />
      ) : (
        ""
      )}
      <section
        className="grid-table"
        id={getSearchGridId(searchPageInfo.pageGroupType, pageName)}
      >
        {searchData && searchData.TotalRecords > 0 ? (
          <Fragment>
            {pageName === ADMIN_PAGE.RESERVE_SYNC_PAGE && updatedControls.searchViewType.value === "CALENDAR_VIEW"
              ? <PeriodicFilter
                startDate={updatedControls && updatedControls.forecastStartDate ? updatedControls.forecastStartDate.value : null}
                endDate={updatedControls && updatedControls.forecastEndDate ? updatedControls.forecastEndDate.value : null}
                inputCtrl={updatedControls}
                periodicFilterInfo={updatedControls ? updatedControls.periodicFilterInfo : null}
                setUpdateCtrls={setUpdateCtrls}
                getSearchList={getSearchList}
                getSearchListFromSearchReq={getSearchListFromSearchReq}
              />
              : <Pagination
                totalRecords={searchData.TotalRecords}
                recordsPerPage={updatedControls.PageInfo.PerPageCount}
                currentPage={updatedControls.PageInfo.PageNumber}
                onPageChanged={onPageChanged}
                showLiveIco={
                  pageName === ADMIN_PAGE.SHUTTLE_SEARCH_PAGE ? true : false
                }
                isLiveView={isLiveView}
                onLiveIcoClick={onLiveIcoClick}
              />}
            {actualGrid}
          </Fragment>
        ) : searchData ? (
          <NoRecordFoundComponent searchMsg="No Record Found" />
        ) : pageName === ADMIN_PAGE.COMPLAINTS_SEARCH_PAGE ||
          pageName === ADMIN_PAGE.IMPLEMENTATION_SEARCH_PAGE ? (
          <DefaultComponentWithMessage
            msg={
              getActualPageName(pageName) +
              " " +
              COMMON_MSG.UnderConstructionMessage
            }
          />
        ) : (
          <DefaultComponentWithMessage />
        )}
      </section>
      {loading || isLoading ? <Spinner /> : ""}
      {modalInfo.showModal ? (
        <ModalEngine
          modalType={modalInfo.type}
          showModal={modalInfo.showModal}
          closeModal={closeModal}
          submitHandler={submitHandler}
        />
      ) : null}
    </Fragment>
  );
};
export default withRouter(SearchPageContainer);

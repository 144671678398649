import React from 'react';
import AddonsBasicInfo from './addons-basic-info';
import AddonsSecondaryContainer from './addons-secondary-container';
function AddonsMainContainer(props) {
    const { addonData, selectedAddons, addonsQuantity, setDialogData, onAddonClick } = props;
    const selectedData = selectedAddons.find(
        (element) => element.code === addonData?.code
    );
    return (
        <div className={`common-res-container addons-container ${selectedData && "selected-section"}`} key={addonData.code} >
            <AddonsBasicInfo addonData={addonData} />
            <AddonsSecondaryContainer
                addonData={addonData}
                onAddonClick={onAddonClick}
                selectedData={selectedData}
                addonsQuantity={addonsQuantity}
                setDialogData={setDialogData}
            />
        </div >
    )
}

export default AddonsMainContainer
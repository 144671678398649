import { default as React, useRef, useState } from "react";
import MessageBox from "../../../../components/common/modal/message-box";
import FoxReservationDefaultPage from "../../../../components/common/pages/fox-res-default-page";
import { INFO_ICON, INVOICE, LICENSE, TRANSACTION, VOUCHER_ICON } from "../../../../shared/AppImages";
import { MessageBoxType, ModalType } from "../../../../shared/GlobalVar";
import { getCreditPolicyInfo, getPayNowAddOnDisclosureInfo } from "../../../../shared/json/data";
import { formatCurrency, hasObjectNonEmptyProperty, isNullOrEmpty } from "../../../../shared/utility";
import { SurchargeType, UnitDisplayText } from "../../../../shared/utility/enum-utility";
import {
  getSortedBasicRates
} from "../../../../shared/utility/pricing-utility";
import { getSelectedVehicleHtml } from "../../../vehicle/vehicle-helper";
import FoxVehicleInfoCard from "../fox-vehicle/fox-vehicle-info-card";

const FoxPricingTable = (props) => {
  const iconRef = useRef(null);
  const specificPricingData = (props && props.specificPricingData) || {};
  const { ratesApplied = [], addOns = [], taxes = [], surcharges = [], loyalties = [] } =
    specificPricingData || {};
  const selectedVehicle = props?.foxSelectedVehicleInfo || {};
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [showTaxAndFeeInfoModal, setShowTaxAndFeeInfoModal] = useState(false);
  const [showFreeCreditAppliedModal, setShowFreeCreditAppliedModal] = useState(false);
  const hasPricingData = hasObjectNonEmptyProperty(specificPricingData);
  const openModal = (messageBoxType) => {
    const modalStateMap = {
      [MessageBoxType.PAY_NOW_ADD_ON_DISCLOSURE]: setShowInfoModal,
      [MessageBoxType.TAXES_FEES_SUMMARY]: setShowTaxAndFeeInfoModal,
      [MessageBoxType.FREE_CREDIT_POLICY]: setShowFreeCreditAppliedModal,
    };

    // Reset all modals first
    setShowInfoModal(false);
    setShowTaxAndFeeInfoModal(false);
    setShowFreeCreditAppliedModal(false);

    // Open the specific modal if it matches
    modalStateMap[messageBoxType]?.(true);
  };

  const exisitingSelectedAddons = props?.reservationUiModel?.pricing.addOns || [];
  const getTotalTexesAndFees = () => {
    const taxesAndSurcharges = taxes && taxes.concat(surcharges.filter((obj) => obj.chargeType !== SurchargeType.CREDIT));
    const totalAmount = taxesAndSurcharges.reduce((sum, element) =>
      sum + (element.amount || 0), 0
    )
    return totalAmount || 0;
  }

  const renderRow = (title, total, containerClass = "", showZeroValues = false) => {
    const shouldRender = showZeroValues || (!isNaN(total) && total !== 0);
    return (
      shouldRender && (
        <div className={`row ${containerClass}`} key={title}>
          <div className="col-lg-8 pr-0 pl-0 text-left">
            <span>{title}</span>
          </div>
          <div className="col-lg-4 text-right">
            {total < 0 ? `(${formatCurrency(Math.abs(total))})` : formatCurrency(total)}
          </div>
        </div>
      )
    );
  };

  const renderRowWithModal = (title, total, { messageBoxType, messageBoxRenderer, additionalText = "", showZeroValues = false } = {}) => {
    const shouldRender = showZeroValues || (!isNaN(total) && total !== 0);
    return shouldRender && (
      <div className="row fs-2" key={title}>
        <div className={`col-lg-${additionalText ? "9" : "8"} pr-0 font-weight-semi-bold`}>
          <span
            className="pseudo-link"
            onClick={() => openModal(messageBoxType)}
            style={{ textDecoration: "underline" }}
          >
            {title}
          </span>
          {additionalText}
          {messageBoxRenderer?.()}
        </div>
        <div className={`col-lg-${additionalText ? "3" : "4"} text-right`}>
          {total < 0 ? `(${formatCurrency(Math.abs(total))})` : formatCurrency(total)}
        </div>
      </div>
    )
  };

  const renderRowWithIcon = (title, iconContent, titleClass) => (
    <div className="row position-relative" ref={iconRef}>
      <div className="pr-2 fs-6" style={{ width: "max-content" }}>
        <span className={titleClass}>{title}</span>
      </div>
      {iconContent && <div className="text-right cursor-pointer">
        <img
          onClick={() => openModal(MessageBoxType.PAY_NOW_ADD_ON_DISCLOSURE)}
          src={iconContent}
          height={20}
          width={20}
          alt={title}
        />
      </div>}
      {iconContent && renderPaynowAddonsDisclosureMessageBox()}
    </div>
  );

  const renderAddonsRow = (title, total, code) => {
    const isExistingAddons = exisitingSelectedAddons.length &&
      exisitingSelectedAddons.some(
        (item) => item.code === code
      );
    return (
      (
        <div className="row fs-2" key={title}>
          <div className="col-lg-9 pr-0 ">
            {title} {!isExistingAddons && props.reservationMode !== "NEW" ? <span className="new-addons-label">{`Newly Added`}</span> : ""}
          </div>
          <div className="col-lg-3 text-right">{total < 0 ? "(" + formatCurrency(Math.abs(total)) + ")" : formatCurrency(total)}</div>
        </div>
      )
    );
  };
  const renderRates = () =>
    ratesApplied && getSortedBasicRates(ratesApplied)?.filter((rate) => rate.amount > 0).map((rate) =>
      renderRow(rate.amount + " " + UnitDisplayText[rate.type] + (rate.amount > 1 ? "s" : ""), rate.rate * rate.amount)
    );
  const renderOneTimeDiscount = () => {
    if (!isNullOrEmpty(specificPricingData?.rateDiscount)) {
      return renderRow(`Discount (${specificPricingData?.foxDiscount?.discountPercentage}%)`, specificPricingData?.rateDiscount);
    }
    return null;
  };
  const renderAppliedDiscount = () => {
    const { discountValue, discountReason } = specificPricingData;
    if (discountValue > 0 && !isNaN(discountValue)) {
      return renderRow(`Discount - ${discountReason}`, discountValue * -1);
    }
    return null;
  };
  const renderTaxesAndFeesModalData = () => {
    const renderCharge = (obj) => renderRow(obj.desc, obj.amount, "", true);
    const taxesAndSurcharges = taxes && taxes.concat(surcharges);
    return (
      <>
        {taxesAndSurcharges && taxesAndSurcharges.map(renderCharge)}
        <div className="total-estimated-charge">
          {renderRow("Total", getTotalTexesAndFees(), "total", true)}
        </div>
      </>
    );
  };

  const renderMiscellaneousCredits = () => {
    const renderMiscRow = (obj) => renderRow(obj.desc, obj.amount);
    const miscCredits = surcharges && surcharges.filter((obj) => obj.chargeType === SurchargeType.CREDIT);
    return miscCredits.map(renderMiscRow);
  };

  const renderLoyalties = () =>
    loyalties?.map((obj, index) => (renderRow(obj.desc, obj.amount)));

  const renderPricingData = () => {
    const clonedSpecificPricingData = JSON.parse(JSON.stringify(specificPricingData));
    let totalCharges = clonedSpecificPricingData.totalCharges;
    let balanceDue = clonedSpecificPricingData.balanceDue;
    let freeCreditAmountApplied = clonedSpecificPricingData.freeCreditAmountApplied;
    // if (isNullOrEmpty(clonedSpecificPricingData?.rateDiscount) && isNullOrEmpty(clonedSpecificPricingData?.ratePlan?.rateBeforeDiscount)) {
    //   if (props.customerFreeCredit && ((specificPricingData.type === "PrePaid") || props.reservationUiModel.reservationStatus === "PAYMENT_PENDING")) {
    //     const freeCreditAmountShouldApplied = calculateAppliedFreeCredit(
    //       props.customerFreeCredit,
    //       clonedSpecificPricingData.totalCharges
    //     );
    //     freeCreditAmountApplied = freeCreditAmountShouldApplied;
    //     balanceDue = clonedSpecificPricingData.totalCharges - freeCreditAmountApplied;
    //   }
    // }

    const hasPaymentsAndCreditsData =
      (!isNaN(clonedSpecificPricingData.totalCancellationCharges) && clonedSpecificPricingData.totalCancellationCharges !== 0) ||
      (!isNaN(clonedSpecificPricingData.totalCancellationFee) && clonedSpecificPricingData.totalCancellationFee !== 0) ||
      (!isNaN(clonedSpecificPricingData.cancellationCourtesyAmount) && clonedSpecificPricingData.cancellationCourtesyAmount !== 0) ||
      (!isNaN(freeCreditAmountApplied) && freeCreditAmountApplied !== 0 && isNullOrEmpty(specificPricingData?.rateDiscount) && isNullOrEmpty(clonedSpecificPricingData?.ratePlan?.rateBeforeDiscount)) ||
      (!isNaN(clonedSpecificPricingData.totalCreditsApplied) && clonedSpecificPricingData.totalCreditsApplied !== 0) ||
      (!isNaN(clonedSpecificPricingData.totalPayments) && clonedSpecificPricingData.totalPayments !== 0) ||
      (!isNaN(clonedSpecificPricingData.totalPaymentsAndCredits) && clonedSpecificPricingData.totalPaymentsAndCredits !== 0) ||
      balanceDue !== totalCharges;
    return (
      <>
        <div className="charge-table-rate">
          <span className="common-title">Rate</span>
          {renderRates()}
          {renderOneTimeDiscount()}
          {renderRowWithModal("Taxes & Fees", getTotalTexesAndFees(), {
            messageBoxType: MessageBoxType.TAXES_FEES_SUMMARY,
            messageBoxRenderer: renderTaxAndFeesMessageBox,
            showZeroValues: true
          })}
        </div>

        <div className="charge-table-addons">
          {renderRowWithIcon("Protections & Extras", INFO_ICON, "common-title")}
          <div className="addons-separator">
            {addOns?.map((addon) =>
              renderAddonsRow(addon.desc, addon.amount, addon.code)
            )}
          </div>
        </div>
        {renderRow(
          "Extension Fees",
          clonedSpecificPricingData.totalExtensionFee
        )}
        {renderRow("Damage Charges", clonedSpecificPricingData.totalDamages)}
        {renderLoyalties()}
        {
          <div className="total-estimated-charge">
            {renderRow("Total", totalCharges, "total")}
          </div>
        }
        {hasPaymentsAndCreditsData && (<div className="charge-table-payments-credit">
          <span className="common-title">Payments & Credits</span>

          {/* {renderAppliedDiscount()} */}
          {!isNaN(clonedSpecificPricingData.totalCancellationCharges) &&
            renderRow(
              "Cancelled Amount",
              clonedSpecificPricingData.totalCancellationCharges * -1
            )}
          {renderRow(
            "Cancellation Fee",
            clonedSpecificPricingData.totalCancellationFee
          )}
          {!isNaN(clonedSpecificPricingData.cancellationCourtesyAmount) &&
            renderRow(
              "Cancellation Courtesy Amount",
              clonedSpecificPricingData.cancellationCourtesyAmount * -1
            )}
          {/* {renderMiscellaneousCredits()} */}
          {(!isNaN(clonedSpecificPricingData.freeCreditAmountApplied)) &&
            renderRowWithModal("Credit Applied", clonedSpecificPricingData.freeCreditAmountApplied * -1, {
              messageBoxType: MessageBoxType.FREE_CREDIT_POLICY,
              messageBoxRenderer: renderFreeCreditAppliedMessageBox,
              additionalText: " From Account",
            })
          }
          {!isNaN(clonedSpecificPricingData.totalCreditsApplied) &&
            renderRow(
              "Total Credits",
              clonedSpecificPricingData.totalCreditsApplied * -1,
              true
            )}
          {!isNaN(clonedSpecificPricingData.totalPayments) &&
            renderRow("Payments", clonedSpecificPricingData.totalPayments * -1)}
          {renderRow(
            "Capture from Hold",
            clonedSpecificPricingData.totalCapturedAmount
          )}
          {renderRow(
            "Pre-authorization Release",
            clonedSpecificPricingData.totalPreAuthReleaseAmount
          )}
          {renderRow(
            "Pre-authorization Hold",
            clonedSpecificPricingData.totalPreAuthAmount
          )}
          {renderRow("Refunds", clonedSpecificPricingData.totalRefunds)}
          {renderRow(
            "Voided Refunds",
            clonedSpecificPricingData.totalVoidedRefunds
          )}
          {renderRow(
            "Voided Payments",
            clonedSpecificPricingData.totalVoidedPayments
          )}
          {/* {!isNaN(clonedSpecificPricingData.totalPaymentsAndCredits) &&
            renderRow(
              "Total Payments and credits",
              clonedSpecificPricingData.totalPaymentsAndCredits * -1
            )} */}

          {balanceDue !== totalCharges || clonedSpecificPricingData.totalPayments ? <>
            <hr />
            {renderRow("Your Charge", balanceDue, "total", true)}
          </> : ""}
        </div>)}
      </>
    );
  };
  const renderVehicleContent = () => {
    return (
      <FoxVehicleInfoCard
        vehicleElement={selectedVehicle}
        getSelectedVehicleHtml={getSelectedVehicleHtml}
        key={selectedVehicle.classCode + "res-edit-vehicle-block"}
        isPayLater={props.isPayLater}
        showReserveButtom={props.showReserveButtom}
        showFeatureSection={props.showFeatureSection}
        showRateSection={props.showRateSection}
      />
    );
  };
  const renderPaynowAddonsDisclosureMessageBox = () =>
    showInfoModal && (<MessageBox
      id="paynow-addons-disclosure-message-box"
      title={"Pay Now Add-On Disclosure"}
      message={getPayNowAddOnDisclosureInfo()}
      onClose={() => setShowInfoModal(false)}
      iconRef={iconRef}
      messageBoxType={MessageBoxType.PAY_NOW_ADD_ON_DISCLOSURE}
    />)
  const renderFreeCreditAppliedMessageBox = () =>
    showFreeCreditAppliedModal && (<MessageBox
      id="credit-policy-message-box"
      title={"Credit Policy"}
      message={getCreditPolicyInfo()}
      onClose={() => setShowFreeCreditAppliedModal(false)}
      iconRef={iconRef}
      messageBoxType={MessageBoxType.FREE_CREDIT_POLICY}
    />)

  const renderTaxAndFeesMessageBox = () =>
    showTaxAndFeeInfoModal && (<MessageBox
      id="taxes-fees-message-box"
      title={"Taxes & Fees"}
      message={
        renderTaxesAndFeesModalData()
      }
      onClose={() => setShowTaxAndFeeInfoModal(false)}
      iconRef={iconRef}
      messageBoxType={MessageBoxType.TAXES_FEES_SUMMARY}
    />)

  return (
    <>
      <div id="fox-res-pricing-table" className="p-0 fs-3">
        <div className="res-pricing-action-block pb-3">
          <span className="fs-2-0"> {props.sectionHeader}</span>
          {hasPricingData && props.reservationUiModel?.confirmationCode && <div className="res-summary-actions">
            <div
              className="action-icon"
              onClick={() =>
                props.showModal({
                  type: ModalType.RESERVATION_SUMMARY_INVOICE_MODAL
                })
              }
              title="Click to View Invoice Report"
            >
              <img
                className="invoice-icon"
                src={INVOICE}
                height={16}
                width={16}
                alt={"cancel"}
              />
            </div>
            <div
              className="action-icon"
              onClick={() =>
                props.showModal({
                  type: ModalType.TRANSACTION_MODAL
                })
              }
              title="Click to View Transaction List"
            >
              <img
                className="transaction-icon"
                src={TRANSACTION}
                height={16}
                width={16}
                alt={"transaction"}
              />
            </div>
            {props.reservationUiModel?.enableVoucherModal && <div
              className="action-icon"
              onClick={() =>
                props.showModal({
                  type: ModalType.VOUCHER_INFORMATION_MODAL
                })
              }
              title="Click to display voucher information"
            >
              <img
                className="voucher-icon"
                src={VOUCHER_ICON}
                height={16}
                width={16}
                alt={"voucher"}
              />
            </div>}
          </div>}
        </div>

        {hasPricingData ? (
          <>
            <div className="selected-vehicle">
              {renderVehicleContent()}
            </div>
            <div className="pricing-section">
              <div className="pricing-body">{renderPricingData()}</div>
            </div>
          </>
        ) : (
          <FoxReservationDefaultPage
            iconContent={LICENSE}
            title={props.defaultPageTitle || ""}
            msg={props.defaultPageMsg || ""}
          />
        )}
      </div>
    </>
  );
};

export default FoxPricingTable;

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  doAcceptOrDeclineReservationReset,
  doAcceptOrDeclineReservation,
} from "../../../store/actions/index";
import { getBasicToastResponse, updateObject } from "../../../shared/utility";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Draggable from "react-draggable";
import { showNotification } from "../../../components/UI/Toaster/Toaster";
import CommonInputField from "../../../components/UI/Input/common-input-field";
import { LabelAndValuePairControl } from "../../../components/UI/Input/label-value-pair-control";
export const AcceptDeclineReservationModal = (props) => {
  const defaultData = props.defaultData;
  const dispatch = useDispatch();
  const acceptDeclineResponse = useSelector(
    (state) => state.reservationReducer.acceptDeclineResponse
  );
  const [acceptDeclineReq, setAcceptDeclineReq] = useState({
    Accepted: true,
    Comments: null,
  });

  useEffect(() => {
    if (!acceptDeclineResponse) {
      return;
    }
    showNotification(getBasicToastResponse(acceptDeclineResponse));
    if (acceptDeclineResponse.StatusCode === 0) {
      dispatch(doAcceptOrDeclineReservationReset());
      props.resetParentPage();
      props.closeModal();
    }
    dispatch(doAcceptOrDeclineReservationReset());
  }, [acceptDeclineResponse]);
  const handleOnChange = ({ target }) => {
    let updateCtrls = { ...acceptDeclineReq };
    let value = target.value;
    if (target.type === "radio") {
      updateCtrls.Accepted = !updateCtrls.Accepted;
    } else {
      updateCtrls = updateObject(updateCtrls, {
        [target.name]: value,
      });
    }
    setAcceptDeclineReq(updateCtrls);
  };
  const onAcceptDecline = () => {
    const req = {};
    req.ReservationId = defaultData.reservationId;
    req.MemberId = defaultData.borrowerId;
    req.Accepted = acceptDeclineReq.Accepted;
    req.Comments = acceptDeclineReq.Comments;
    req.OverrideEmail = false;
    req.DeviceValue = null;
    req.MemberAssetId = null;
    dispatch(doAcceptOrDeclineReservationReset());
    dispatch(doAcceptOrDeclineReservation(req));
  };
  const labelCaptionClassBold = "col-sm-4 col-form-label font-weight-bold";
  const labelValueClass = "col-sm-8";
  const renderRadioButtons = (id, value, labelCaption) => {
    return (
      <div className="col-sm-6">
        <CommonInputField
          elementConfig={{
            id: id,
            type: "radio",
            name: "acceptedStatus",
            checked: acceptDeclineReq["Accepted"] === value,
          }}
          otherConfig={{
            caption: labelCaption,
          }}
          nodeElement="radio"
          onChange={handleOnChange}
        />
      </div>
    );
  };
  const renderLabelInputPair = (
    labelCaption,
    controlName,
    value,
    isRequired = false,
    viewOnly = false,
    nodeElement = "input",
    isDateField = false
  ) => {
    return (
      <LabelAndValuePairControl
        elementConfig={{
          name: controlName,
        }}
        otherConfig={{
          caption: labelCaption,
          isRequired: isRequired,
          isDateField: isDateField,
          isWithDateTime: true,
          viewOnly: viewOnly,
          value: value,
        }}
        isEditMode={true}
        onChange={handleOnChange}
        parentRowClass={`form-group row  ${isRequired ? "required" : ""}`}
        labelClasses={labelCaptionClassBold}
        valueClasses={labelValueClass}
        nodeElement={nodeElement}
      />
    );
  };
  return (
    <Draggable handle=".cursor">
      <Modal
        isOpen={props.showModal}
        className="no-cursor"
        toggle={() => {
          props.closeModal();
        }}
      >
        <ModalHeader
          className="modal-header bg-primary modal-title text-white cursor"
          toggle={() => {
            props.closeModal();
          }}
        >
          <i className="fas fa-arrows-alt" /> Accept / Decline
        </ModalHeader>
        <ModalBody>
          <div className="col-lg-12">
            <div className="form-group row">
              <label className={labelCaptionClassBold}>Request</label>
              <div className={`${labelValueClass} row pl-0`}>
                {renderRadioButtons("rbAccepted", true, "Accept")}
                {renderRadioButtons("rbDeclined", false, "Decline")}
              </div>
            </div>
            {renderLabelInputPair(
              "Requested Date",
              "createdOn",
              defaultData.createdOn,
              false,
              true,
              "",
              true
            )}
            {renderLabelInputPair(
              "Renter Comments",
              "borrowerNotes",
              defaultData.borrowerNotes,
              false,
              true
            )}
            {renderLabelInputPair(
              "Comments",
              "borrowerNotes",
              acceptDeclineReq.Comments,
              true,
              false,
              "textarea"
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="col-lg-12 text-right">
            <button
              type="button"
              className="btn btn-primary waves-effect waves-light btnSize ml-2"
              onClick={() => onAcceptDecline()}
            >
              Accept / Decline
            </button>
            <button
              type="button"
              name="btnCancel"
              className="btn btn-secondary waves-effect waves-light btnSize ml-2"
              onClick={props.closeModal}
            >
              Cancel
            </button>
          </div>
        </ModalFooter>
      </Modal>
    </Draggable>
  );
};
export default AcceptDeclineReservationModal;

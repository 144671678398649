import React, { Fragment, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Spinner from "../../components/UI/Spinner/Spinner";
import PartnerListSection from "./partner-list-section";
import PartnerDetailsSection from "./partner-details-section";
import { Multiselect } from "react-widgets";
import InputClearIcon from "../../components/UI/icon/input-clear-icon";
import { setActiveMenu } from "../search/search-utility/search-utility";

const PartnerInformation = (props) => {
  const loading = useSelector((state) => state.settingsReducer.loading);
  const [selectedPartnerData, setSelectedPartnerData] = useState({});
  const [partnerCodeList, setPartnerCodeList] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [multiSelectValues, setMultiSelectValues] = useState([]);
  const [filterOrSearchData, setFilterOrSearchData] = useState([]);
  const maxAllowedItem = 2;
  useEffect(() => {
    setPartnerCodeList(partnerList());
  }, []);

  const partnerList = () => {
    return [
      {
        PartnerName: "FoxWeb",
        PartnerCode: "FOXWEB",
        DisplayName: "Fox Website",
        ResCode: "10003639114",
      },
      {
        PartnerName: "FoxAdmin",
        PartnerCode: "FOXADMIN",
        DisplayName: "Fox Admin",
      },
      {
        PartnerName: "FoxMobile",
        PartnerCode: "FOXMOBILE",
        DisplayName: "Fox Mobile",
      },
      {
        PartnerName: "FoxPlatform",
        PartnerCode: "FOXPLATFORM",
        DisplayName: "Fox Platform",
      },
      {
        PartnerName: "Greenway",
        PartnerCode: "GREENWAY",
        DisplayName: "Europcar",
        ResCode: "10003660677",
      },
      {
        PartnerName: "Greenway",
        PartnerCode: "GREENWAY_PPD",
        DisplayName: "Europcar",
        ResCode: "10003467179",
      },
      {
        PartnerName: "Greenway",
        PartnerCode: "GREENWAY3RDP_PPD",
        DisplayName: "Europcar",
      },
      {
        PartnerName: "Greenway",
        PartnerCode: "GREENWAY3RDP",
        DisplayName: "Europcar",
        ResCode: "10003826935",
      },
      {
        PartnerName: "Mobacar",
        PartnerCode: "MOB",
        DisplayName: "Mobacar",
        ResCode: "10003986283",
      },
      {
        PartnerName: "Mobacar",
        PartnerCode: "00099013",
        DisplayName: "Priceline Fake Pay Now Rates Test",
      },
      {
        PartnerName: "Mobacar",
        PartnerCode: "99015280",
        DisplayName:
          "Priceline Retail Rates Test Priceline Retail Rates Test Priceline Retail Rates Test",
      },
      {
        PartnerName: "Mobacar",
        PartnerCode: "07560685",
        DisplayName: "Priceline Retail Rates Prod - PayLater",
        ResCode: "10003616454",
      },
      {
        PartnerName: "Mobacar",
        PartnerCode: "PCL2018-07560685",
        DisplayName: "Priceline Retail Rates Prod - Prepaid",
      },
      {
        PartnerName: "Mobacar",
        PartnerCode: "PL5OFFPPD2-07560685",
        DisplayName:
          "Priceline Retail Rates Prod - Prepaid with discount 5% OFF",
      },
      {
        PartnerName: "Mobacar",
        PartnerCode: "PLC15OFF2CM-07560685",
        DisplayName: "Priceline Retail Rates Prod - Prepaid Cyber Monday",
      },
      {
        PartnerName: "Mobacar",
        PartnerCode: "PCLEP2020-07560685",
        DisplayName: "Priceline Europcar Retail Rates Prod - Prepaid",
      },
      {
        PartnerName: "Mobacar",
        PartnerCode: "07508546",
        DisplayName: "Priceline Corporate Opaque Rates Prod",
        ResCode: "10003276952",
      },
      {
        PartnerName: "Mobacar",
        PartnerCode: "45605486",
        DisplayName: "Southwest",
      },
      {
        PartnerName: "Goldcar",
        PartnerCode: "02820-00",
        DisplayName: "Goldcar",
      },
      {
        PartnerName: "Goldcar",
        PartnerCode: "GOLDCARPKG",
        DisplayName: "Goldcar",
      },
      {
        PartnerName: "Goldcar",
        PartnerCode: "INTRENTPKG",
        DisplayName: "Interrent",
      },
      {
        PartnerName: "Goldcar",
        PartnerCode: "AUTOEURPKG",
        DisplayName: "Auto Europe LLC",
      },
      {
        PartnerName: "Amadeus",
        PartnerCode: "AMADEUS",
        DisplayName: "Amadeus - Generic",
      },
      {
        PartnerName: "Amadeus",
        PartnerCode: "20299963",
        DisplayName: "Amadeus",
      },
      {
        PartnerName: "Amadeus",
        PartnerCode: "00000000",
        DisplayName: "Amadeus",
      },
      {
        PartnerName: "Amadeus",
        PartnerCode: "00345100",
        DisplayName: "Amadeus",
      },
      {
        PartnerName: "Amadeus",
        PartnerCode: "LAXFX5100-11617270",
        DisplayName: "Amadeus",
      },
      {
        PartnerName: "Amadeus",
        PartnerCode: "NCE1A0269-00000000",
        DisplayName: "Amadeus",
      },
      {
        PartnerName: "Amadeus",
        PartnerCode: "NCE1A0955-12345675-EXPPPD21",
        DisplayName: "Amadeus",
      },
      {
        PartnerName: "Amadeus",
        PartnerCode: "SEAEX38AA-11617270",
        DisplayName: "Expedia US",
      },
      {
        PartnerName: "Amadeus",
        PartnerCode: "SEAEX38AA-50543474-EXPPPD21",
        DisplayName: "Expedia US",
      },
      {
        PartnerName: "Amadeus",
        PartnerCode: "SFOEX3301-11617270",
        DisplayName: "Expedia US",
      },
      {
        PartnerName: "Amadeus",
        PartnerCode: "SFOEX3304-11617270-EXPPPD21",
        DisplayName: "Hawaiian",
      },
      {
        PartnerName: "Amadeus",
        PartnerCode: "SEAEX3302-11617270",
        DisplayName: "WTH, Chains US, Marriott",
      },
      {
        PartnerName: "Amadeus",
        PartnerCode: "SEAEX3305-50563122",
        DisplayName: "Amex",
      },
      {
        PartnerName: "Amadeus",
        PartnerCode: "YVREX38AA-67503111-EXPPPD21",
        DisplayName: "Expedia Canada",
      },
      {
        PartnerName: "Amadeus",
        PartnerCode: "SEAEX38CC-50623705",
        DisplayName: "Orbitz",
      },
      {
        PartnerName: "Amadeus",
        PartnerCode: "YYJEX38AA-67504905",
        DisplayName: "Travelocity CA",
      },
      {
        PartnerName: "Amadeus",
        PartnerCode: "SEAEX38HA-50560985-EXPPPD21",
        DisplayName: "Travelocity US",
      },
      {
        PartnerName: "Amadeus",
        PartnerCode: "SEAEX38AB-50592931",
        DisplayName: "Carrentals.com",
      },
      {
        PartnerName: "Amadeus",
        PartnerCode: "BOSHP2402-22521623",
        DisplayName: "HOPPER Capital One Portal",
      },
      {
        PartnerName: "Carrentals.com",
        PartnerCode: "1435",
        DisplayName: "Carrentals",
      },
      {
        PartnerName: "Rentalcars.com BookingsGo",
        PartnerCode: "RENTALCARS",
        DisplayName: "Rental Cars - Retail Rates - Prepaid",
      },
      {
        PartnerName: "CarRentalGateway",
        PartnerCode: "CRGWISENET",
        DisplayName: "Wisecars LLC - Net Rates Prepaid",
      },
      {
        PartnerName: "CarRentalGateway",
        PartnerCode: "CRGWISERTL",
        DisplayName: "Wisecars LLC - Retail Pay Now Rates Prepaid",
      },
      {
        PartnerName: "CarRentalGateway",
        PartnerCode: "CRGCR8NET",
        DisplayName: "Car Rental 8 - Net Rates Prepaid",
      },
      {
        PartnerName: "CarRentalGateway",
        PartnerCode: "CRGVIPNET",
        DisplayName: "VIP Cars - Net Rates",
      },
      {
        PartnerName: "Kayak.com",
        PartnerCode: "KAYAKPKG",
        DisplayName: "KAYAK",
      },
      {
        PartnerName: "AirportRentals",
        PartnerCode: "ARONLINEREPNET",
        DisplayName: "Online Republic - Net Rates - Pay On Arrival",
      },
      {
        PartnerName: "Zuzuche",
        PartnerCode: "ZUERCNET",
        DisplayName: "Easy Rent Cars - Net Rates",
      },
      {
        PartnerName: "Zuzuche",
        PartnerCode: "ZUZUCHERTL",
        DisplayName: "Zuzuche - Retail Rates",
      },
      {
        PartnerName: "CTRIP",
        PartnerCode: "CTRIPNETPL",
        DisplayName: "CTRIP - Net Rates Pay Later",
      },
      {
        PartnerName: "CTRIP",
        PartnerCode: "CTRIPRTLPL",
        DisplayName: "CTRIP - Retail Rates Pay Later",
      },
      {
        PartnerName: "Booking Group",
        PartnerCode: "BKGRPNETPN",
        DisplayName: "Booking Group - NET Prepaid",
      },
      {
        PartnerName: "CARTTRAWLER",
        PartnerCode: "CARTNETPN",
        DisplayName: "CarTrawler - NET Prepaid",
      },
      {
        PartnerName: "CARTTRAWLER",
        PartnerCode: "CARTRTLPL",
        DisplayName: "CarTrawler - Retail Pay On Arrival",
      },
      {
        PartnerName: "Rentcars B.V.",
        PartnerCode: "RENTCARSRLPN",
        DisplayName: "Rent Cars - Retail Pay Now Rates Prepaid",
      },
      {
        PartnerName: "Lengow SAS",
        PartnerCode: "LENGOWRTLPL",
        DisplayName: "Lengow - Retail Pay Later Rates",
      },
      {
        PartnerName: "RentCarla",
        PartnerCode: "CARLANETPL",
        DisplayName: "Carla Car Rental - NET Pay On Arrival",
      },
      {
        PartnerName: "AutoRentals Inc.",
        PartnerCode: "AUTRENRTLPL",
        DisplayName: "AutoRentals - Retail Pay On Arrival",
      },
      {
        PartnerName: "AUTO EUROPE LLC",
        PartnerCode: "AUTOEURPN",
        DisplayName: "Auto Europe - Prepaid",
      },
    ];
  };

  const getFilterOrSearchData = (event, isTextSearch, searchInput) => {
    let textValue = isTextSearch
      ? event
        ? event.target.value
        : searchInput
      : searchValue;
    let filterValue = !isTextSearch ? event : multiSelectValues;
    isTextSearch
      ? setSearchValue(textValue)
      : filterValue.length <= maxAllowedItem
      ? setMultiSelectValues(filterValue)
      : setMultiSelectValues(multiSelectValues);
    let partnerCodeListNew = partnerList();
    let dataToSearch = [...partnerCodeListNew];

    setPartnerCodeList(filterOrSearchData);
    if (filterValue.length || textValue) {
      if (filterValue.length && filterValue.length <= maxAllowedItem) {
        dataToSearch = dataToSearch.filter((entity) =>
          filterValue.some((item) => item === entity.PartnerName)
        );
        updateListWithSearchData(dataToSearch);
      }
      if (textValue) {
        dataToSearch = dataToSearch.filter((item) =>
          `${item.PartnerCode.toLowerCase()}${item.DisplayName.toLowerCase()}`.includes(
            textValue.toLowerCase()
          )
        );
        updateListWithSearchData(dataToSearch);
      }
    } else {
      setPartnerCodeList(partnerCodeListNew);
    }
  };

  const updateListWithSearchData = (searchedData) => {
    setFilterOrSearchData(searchedData);
    setPartnerCodeList(searchedData);
  };

  const getUniquePartnerNameList = () => {
    let partnerCodeListNew = partnerList();
    const partnerNameList = [];
    if (partnerCodeListNew && partnerCodeListNew.length > 0) {
      partnerCodeListNew.map((entity) => {
        partnerNameList.push(entity.PartnerName);
      });
    }
    return [...new Set(partnerNameList)];
  };

  const clearValue = () => {
    setSearchValue("");
    getFilterOrSearchData(null, true, "");
  };

  const renderFilterSection = () => {
    return (
      <div>
        <div className="col-sm-12  pt-3">
          <InputClearIcon
            inputValue={searchValue}
            customDivClass="mt-3 light-gray-color"
            onClickEvent={clearValue}
          />
          <input
            type="text"
            style={{ height: "Auto" }}
            placeholder="Enter partner code to search"
            value={searchValue}
            onChange={(event) => getFilterOrSearchData(event, true)}
          />
        </div>
        <div
          className="pl-4 pt-2 font-bold"
          style={{ color: "var(--color-primary-light-1)" }}
        >
          Filter By
        </div>
        <div className="col-sm-12">
          <Multiselect
            data={getUniquePartnerNameList() || []}
            valueField="key"
            textField="displayValue"
            value={multiSelectValues}
            onChange={(value) => getFilterOrSearchData(value, false)}
            placeholder="Partner name"
            selectIcon={false}
          />
        </div>
      </div>
    );
  };

  const renderMainLayout = () => {
    return (
      <Fragment>
        <div className="col-lg-12 d-flex" id="parter-info-settings">
          <div className="col-lg-2 p-3" id="partner-list-id">
            <div
              className="text-center fs-4 font-weight-bold"
              style={{ color: "var(--color-primary-light-1)" }}
            >
              Select a Partner Code
            </div>
            {renderFilterSection()}
            <hr />

            <PartnerListSection
              partnerList={partnerCodeList}
              searchValue={searchValue}
              setSelectedPartnerData={setSelectedPartnerData}
            />
          </div>
          <div className="col-lg-10">
            <PartnerDetailsSection selectedPartnerData={selectedPartnerData} />
          </div>
        </div>
      </Fragment>
    );
  };
  setActiveMenu(props.match.url);
  return (
    <div className="row">
      <h4 className="form-header text-uppercase m-3 col-lg-12">
        <i className="fas fa-building" aria-hidden="true"></i>
        Partner Info
      </h4>
      {renderMainLayout()}
      {loading ? <Spinner /> : ""}
    </div>
  );
};
export default PartnerInformation;

import { COMMON_MSG } from "../../shared/GlobalVar";
import { getFormattedValidationMsg } from "../../shared/utility";
export const getDataforCaptureRefundReleaseModal = (
  paymentData,
  transactionType,
  pageName
) => {
  return {
    MemberId: paymentData.BorrowerId || null,
    ReservationId: paymentData.ReservationId || null,
    IntegRefTransId: paymentData.IntegRefTransId || null,
    TransactionId: paymentData.TransactionId || null,
    PersonAssetOwnerName: paymentData.PersonAssetOwnerName || null,
    BorrowerName: paymentData.BorrowerName || null,
    ParentTransactionType: paymentData.TransactionType || "PAYMENT",
    TotalRefundAmount: paymentData.TotalRefundAmount || null,
    Amount: paymentData.Amount || null,
    NewTransactionType: transactionType,
    PageName: pageName,
  };
};
export const validateManagePaymentServiceRequest = (
  managePaymentServiceRequest
) => {
  let msg = "";

  if (!managePaymentServiceRequest.ProcessedBy) {
    if (msg) {
      msg += ", ";
    }
    msg += "Processed By";
  }
  if (!managePaymentServiceRequest.Comments) {
    if (msg) {
      msg += ", ";
    }
    msg += "Comments";
  }
  if (
    managePaymentServiceRequest.TransactionType === "REFUND" ||
    managePaymentServiceRequest.TransactionType === "CAPTURE"
  ) {
    if (managePaymentServiceRequest.TransactionType === "REFUND") {
      if (!managePaymentServiceRequest.Reason) {
        if (msg) {
          msg += ", ";
        }
        msg += "Refund Reason";
      }
    }
    if (!managePaymentServiceRequest.Amount) {
      if (msg) {
        msg += ", ";
      }
      msg +=
        managePaymentServiceRequest.TransactionType === "REFUND"
          ? "Refund Amount"
          : "Amount";
    }
  }
  if (msg) {
    msg += " are required.";
  }
  return msg;
};
export const doPaymentValidate = (paymentReq, paymentType) => {
  let msg = "",
    msg1 = "",
    msg2 = "";
  if (!paymentReq.BillingName) {
    if (msg !== "") {
      msg += ", ";
    }
    msg += COMMON_MSG.FullName;
  }
  if (paymentReq.TransactionType === "REFUND") {
    if (!paymentReq.Reason) {
      if (msg) {
        msg += ", ";
      }
      msg += COMMON_MSG.RefundReason;
    }
  }
  if (!paymentReq.PaymentMethod) {
    if (msg) {
      msg += ", ";
    }
    msg += COMMON_MSG.PaymentMethod;
  }
  if (
    paymentReq.PaymentMethod === "CREDIT_CARD" ||
    paymentReq.PaymentMethod === "DEBIT_CARD"
  ) {
    if (!paymentReq.Address.CountryId) {
      if (msg) {
        msg += ", ";
      }
      msg += COMMON_MSG.Country;
    }
    if (!paymentReq.Address.StateId && !paymentReq.Address.StateStr) {
      if (msg) {
        msg += ", ";
      }
      msg += COMMON_MSG.State;
    }
    if (!paymentReq.Address.CityId && !paymentReq.Address.CityStr) {
      if (msg) {
        msg += ", ";
      }
      msg += COMMON_MSG.City;
    }
    if (!paymentReq.Address.ZipCodeId && !paymentReq.Address.ZipcodeStr) {
      if (msg) {
        msg += ", ";
      }
      msg += COMMON_MSG.ZipPostal;
    }

    if (!paymentReq.CVVCode) {
      if (msg) {
        msg += ", ";
      }
      msg += COMMON_MSG.CVV;
    } else {
      if (paymentReq.CardType) {
        if (paymentReq.CardType == "AMEX" && paymentReq.CVVCode.length !== 4) {
          msg2 = COMMON_MSG.CVVAmexValidation;
        } else if (
          paymentReq.CardType != "AMEX" &&
          paymentReq.CVVCode.length !== 3
        ) {
          msg2 = COMMON_MSG.CVVOtherValidation;
        }
      }
    }

    if (!paymentReq.AccountNumber) {
      if (msg) {
        msg += ", ";
      }
      msg += COMMON_MSG.CreditCardNumber;
    }
  }
  if (paymentReq.Amount == null || paymentReq.Amount == 0) {
    if (msg) {
      msg += ", ";
    }
    msg += COMMON_MSG.Amount;
  }
  if (msg) {
    return getFormattedValidationMsg(msg);
  }
  if (msg1) {
    if (msg) {
      msg += "<br/>";
    }
    msg += msg1;
  }
  if (msg2) {
    if (msg) {
      msg += "<br/>";
    }
    msg += msg2;
  }
  return msg;
};

import { getDefaultFilterStates } from "../../components/filter/filter-helper";
import PageInfo from "../../services/entities/models/PageInfo";
import Moment from "moment";
import { getMemberLabelByOrgRentalType } from "../../shared/utility/system-utility";
import {
  getCurrentServerTimezone,
  getCurrentServerDate,
} from "../../shared/utility";
import { getPaymentMethod } from "../Member/MemberHelper";
import { ADMIN_COMMON_CAPTION } from "../../shared/GlobalVar";
export const getBillingSearchCtrls = (urlOrPageName) => {
  let timeFormat = "MM:DD:YYYY:HH:mm";
  return {
    isAdvancedSearch: false,
    PageInfo: new PageInfo(null, null, "BILL_PAYMENT_DATE", "DESC"),
    ReservationId: {
      value: null,
    },
    ReservationState: {
      value: getDefaultFilterStates(urlOrPageName),
    },
    SearchStartDate: {
      id: "SearchStartDate",
      elementType: "dateandtimepicker",
      elementConfig: {
        name: "SearchStartDate",
        type: "text",
      },
      timePicker: {
        id: "StartTime",
        name: "StartTime",
        value: getCurrentServerDate(timeFormat),
        elementType: "timepicker",
      },
      validation: {
        required: false,
      },
      labelInput: {
        labeltext: "Start Date",
      },
      timeZoneString: getCurrentServerTimezone(),
      value: Moment(
        Moment(getCurrentServerDate(timeFormat), "MM-DD-YYYY HH:mm").add(
          -7,
          "days"
        )
      ).format(timeFormat),
      valid: false,
      touched: false,
      basicOrder: 1,
      advancedOrder: 1,
    },
    SearchEndDate: {
      id: "SearchEndDate",
      elementType: "dateandtimepicker",
      elementConfig: {
        name: "SearchEndDate",
        type: "text",
      },
      timePicker: {
        id: "EndTime",
        name: "EndTime",
        value: "",
        elementType: "timepicker",
      },
      validation: {
        required: false,
      },
      labelInput: {
        labeltext: "End Date",
      },
      timeZoneString: getCurrentServerTimezone(),
      value: "",
      valid: false,
      touched: false,
      basicOrder: 6,
      advancedOrder: 6,
    },
    TransactionTypes: {
      id: "TransactionTypes",
      elementType: "select",
      elementConfig: {
        name: "TransactionTypes",
        options: [
          { key: "", displayValue: "All" },
          { key: "REFUND", displayValue: "Refund" },
          { key: "PARTIAL_REFUND", displayValue: "Partial Refund" },
          { key: "PRE_AUTHORIZATION", displayValue: "Pre Authorization" },
          { key: "INVOICED", displayValue: "Invoiced" },
          { key: "CAPTURE", displayValue: "Capture" },
          { key: "VOID", displayValue: "VOID" },
          { key: "DEPOSIT", displayValue: "Deposit" },
          { key: "PAYMENT", displayValue: "Payment" },
        ],
      },
      labelInput: {
        labeltext: "Transaction Type",
      },
      value: "",
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      basicOrder: 0,
      advancedOrder: 3,
    },
    ClassCode: {
      id: "ClassCode",
      elementType: "select",
      elementConfig: {
        name: "ClassCode",
        options: [{ key: "", displayValue: "All" }],
      },
      labelInput: {
        labeltext: "Vehicle Category",
      },
      value: "",
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      basicOrder: 0,
      advancedOrder: 4,
    },
    PaymentType: {
      id: "PaymentType",
      elementType: "select",
      elementConfig: {
        name: "PaymentType",
        options: [
          { key: "", displayValue: "All" },
          { key: "SUBSCRIBED_PACKAGE", displayValue: "Subscribed Package" },
          { key: "FEES_FINES", displayValue: " Fees Fines" },
          { key: "FUEL", displayValue: "Fuel" },
          { key: "EXTRA_MILEAGE", displayValue: "Extra Mileage" },
          { key: "ADDITIONAL", displayValue: "Additional" },
          { key: "RESERVATION", displayValue: "Reservation" },
          { key: "DEPOSIT", displayValue: "Deposit" },
          { key: "PURCHASE_CREDITS", displayValue: "Purchase Credits" },
        ],
      },
      labelInput: {
        labeltext: "Payment Type",
      },
      value: "",
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      basicOrder: 0,
      advancedOrder: 8,
    },
    PaymentProcessor: {
      id: "PaymentProcessor",
      elementType: "select",
      elementConfig: {
        name: "PaymentProcessor",
        options: getPaymentMethod(true, true),
      },
      labelInput: {
        labeltext: "Payment Method",
      },
      value: "",
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      basicOrder: 0,
      advancedOrder: 13,
    },
    PaymentState: {
      id: "PaymentState",
      elementType: "select",
      elementConfig: {
        name: "PaymentState",
        options: [
          { key: "", displayValue: "All" },
          { key: "ENABLED", displayValue: "Enabled" },
          { key: "SUCCESSFUL", displayValue: "Successful" },
          { key: "CANCELLED", displayValue: "Cancelled" },
          { key: "PAID_MANUALLY", displayValue: "Paid Manually" },
          { key: "DISABLED", displayValue: "Disabled" },
          { key: "RETRIES_EXCEEDED", displayValue: "Retries Exceeded" },
          { key: "TIME_LIMIT_EXCEEDED", displayValue: "Time Limit Exceeded" },
          { key: "FAIL", displayValue: "Fail" },
        ],
      },
      labelInput: {
        labeltext: "Payment State",
      },
      value: "",
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      basicOrder: 0,
      advancedOrder: 17,
    },
    ReservationCreationType: {
      id: "ReservationCreationType",
      elementType: "select",
      elementConfig: {
        name: "ReservationCreationType",
        options: [
          { key: "", displayValue: "All" },
          { key: "REGULAR", displayValue: "Regular" },
          { key: "TEST", displayValue: "Test" },
        ],
      },
      labelInput: {
        labeltext: "Res. Type",
      },
      value: "",
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      basicOrder: 0,
      advancedOrder: 23,
    },
    OptInXpress: {
      id: "OptInXpress",
      elementType: "select",
      elementConfig: {
        name: "OptInXpress",
        options: [
          { key: "", displayValue: "All" },
          { key: "true", displayValue: "Opted" },
          { key: "false", displayValue: "Not Opted In" },
        ],
      },
      labelInput: {
        labeltext: "FOXPress",
      },
      value: "",
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      basicOrder: 0,
      advancedOrder: 16,
    },
    SelectedChildOrgUnitId: {
      id: "SelectedChildOrgUnitId",
      elementType: "select",
      elementConfig: {
        name: "SelectedChildOrgUnitId",
        options: [{ key: "", displayValue: "Any Location" }],
      },
      labelInput: {
        labeltext: "Location",
      },
      value: "",
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      basicOrder: 9,
      advancedOrder: 21,
    },
    Reason: {
      id: "Reason",
      elementType: "select",
      elementConfig: {
        name: "Reason",
        options: [{ key: "", displayValue: "All" }],
      },
      labelInput: {
        labeltext: "Refund Reason",
      },
      value: "",
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      basicOrder: 0,
      advancedOrder: 24,
    },
    Email: {
      id: "Email",
      elementType: "input",
      elementConfig: {
        name: "Email",
        type: "text",
      },
      validation: {
        required: false,
      },
      labelInput: {
        labeltext: "Email",
      },
      value: "",
      valid: false,
      touched: false,
      basicOrder: 0,
      advancedOrder: 27,
    },
    LocationType: {
      id: "LocationType",
      name: "LocationType",
      elementType: "select",
      elementConfig: {
        name: "LocationType",
        options: [],
      },
      labelInput: {
        labeltext: "Location Type",
      },
      value: "",
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      basicOrder: 0,
      advancedOrder: 26,
    },
    BillingType: {
      id: "BillingType",
      name: "BillingType",
      elementType: "select",
      elementConfig: {
        name: "BillingType",
        options: [],
      },
      labelInput: {
        labeltext: "Billing Type",
      },
      value: "",
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      basicOrder: 0,
      advancedOrder: 28,
    },
    ConfirmationKeys: {
      id: "ConfirmationKey",
      elementType: "input",
      elementConfig: {
        name: "ConfirmationKey",
        type: "text",
        disabled: false,
        placeholder:"Enter one or more..."
      },
      validation: {
        required: false,
      },
      labelInput: {
        labeltext: "Res. Code",
      },
      value: "",
      valid: false,
      touched: false,
      basicOrder: 2,
      advancedOrder: 11,
    },
    PersonName: {
      id: "PersonName",
      elementType: "input",
      elementConfig: {
        name: "PersonName",
        type: "text",
      },
      validation: {
        required: false,
      },
      labelInput: {
        labeltext: "Billing Name",
      },
      value: "",
      valid: false,
      touched: false,
      basicOrder: 8,
      advancedOrder: 7,
    },
    InvoiceNo: {
      id: "InvoiceNo",
      elementType: "input",
      elementConfig: {
        name: "InvoiceNo",
        type: "text",
      },
      validation: {
        required: false,
      },
      labelInput: {
        labeltext: "Invoice ID",
      },
      value: "",
      valid: false,
      touched: false,
      basicOrder: 3,
      advancedOrder: 12,
    },
    PersonId: {
      id: "PersonId",
      elementType: "input",
      elementConfig: {
        name: "PersonId",
        type: "text",
        placeholder: getMemberLabelByOrgRentalType() + ' or Loyalty ID',
        disabled: false,
      },
      labelInput: {
        labeltext: ADMIN_COMMON_CAPTION.CUSTOMER_ID,
      },
      value: "",
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      basicOrder: 0,
      advancedOrder: 2,
    },
    MemberId: {
      id: "MemberId",
      elementType: "input",
      elementConfig: {
        name: "MemberId",
        type: "text",
        placeholder: getMemberLabelByOrgRentalType() + ' or Loyalty ID',
        disabled: false,
      },
      labelInput: {
        labeltext: ADMIN_COMMON_CAPTION.CUSTOMER_ID,
      },
      value: "",
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      basicOrder: 0,
      advancedOrder: 0,
    },
    UnitNo: {
      id: "UnitNo",
      elementType: "input",
      elementConfig: {
        name: "UnitNo",
        type: "text",
      },
      validation: {
        required: false,
      },
      labelInput: {
        labeltext: "Vehicle Id",
      },
      value: "",
      valid: false,
      touched: false,
      basicOrder: 0,
      advancedOrder: 9,
    },
    MinAmount: {
      id: "MinAmount",
      elementType: "input",
      elementConfig: {
        name: "MinAmount",
        type: "number",
      },
      validation: {
        required: false,
      },
      labelInput: {
        labeltext: "Min Amount",
      },
      value: "",
      valid: false,
      touched: false,
      basicOrder: 0,
      advancedOrder: 14,
    },
    MaxAmount: {
      id: "MaxAmount",
      elementType: "input",
      elementConfig: {
        name: "MaxAmount",
        type: "number",
      },
      validation: {
        required: false,
      },
      labelInput: {
        labeltext: "Max Amount",
      },
      value: "",
      valid: false,
      touched: false,
      basicOrder: 0,
      advancedOrder: 18,
    },
    PaymentId: {
      id: "PaymentId",
      elementType: "input",
      elementConfig: {
        name: "PaymentId",
        type: "number",
      },
      validation: {
        required: false,
      },
      labelInput: {
        labeltext: "Transaction ID",
      },
      value: "",
      valid: false,
      touched: false,
      basicOrder: 7,
      advancedOrder: 22,
    },
    CreditCardNumber: {
      id: "CreditCardNumber",
      elementType: "input",
      elementConfig: {
        name: "CreditCardNumber",
        type: "text",
      },
      validation: {
        required: false,
      },
      labelInput: {
        labeltext: "Credit Card No.",
      },
      value: "",
      valid: false,
      touched: false,
      basicOrder: 4,
      advancedOrder: 16,
    },
    btnResSearch: {
      id: "btnResSearch",
      elementType: "button",
      elementConfig: {
        type: "submit",
        value: "Search",
        name: "btnResSearch",
        className: "btn btn-primary search-btn",
      },
      basicOrder: 5,
      advancedOrder: 5,
    },
    btnResSearchReset: {
      id: "btnResSearchReset",
      elementType: "button",
      elementConfig: {
        type: "button",
        value: "Reset",
        name: "btnResSearchReset",
        className: "btn btn-secondary search-btn",
      },
      basicOrder: 10,
      advancedOrder: 10,
    },
    empty: {
      id: "empty",
      elementType: "empty",
      elementConfig: {
        name: "",
        type: "empty",
      },
      basicOrder: 0,
      advancedOrder: 15,
    },
    empty1: {
      id: "empty",
      elementType: "empty",
      elementConfig: {
        name: "",
        type: "empty",
      },
      basicOrder: 0,
      advancedOrder: 20,
    },
    empty2: {
      id: "empty",
      elementType: "empty",
      elementConfig: {
        name: "",
        type: "empty",
      },
      basicOrder: 0,
      advancedOrder: 25,
    },
    empty3: {
      id: "empty",
      elementType: "empty",
      elementConfig: {
        name: "",
        type: "empty",
      },
      basicOrder: 0,
      advancedOrder: 30,
    },
  };
};

export const getMemberBillingSearchCtrls = (urlOrPageName) => {
  const billingCtrl = getBillingSearchCtrls(urlOrPageName);
  delete billingCtrl["PersonId"];
  billingCtrl.PersonName.basicOrder = 8;
  billingCtrl.PersonName.advancedOrder = 2;
  billingCtrl.InvoiceNo.basicOrder = 3;
  billingCtrl.InvoiceNo.advancedOrder = 7;
  billingCtrl.CreditCardNumber.basicOrder = 4;
  billingCtrl.CreditCardNumber.advancedOrder = 12;
  billingCtrl.empty.basicOrder = 0;
  billingCtrl.empty.advancedOrder = 15;
  billingCtrl.PaymentId.basicOrder = 7;
  billingCtrl.PaymentId.advancedOrder = 17;
  billingCtrl.PaymentState.basicOrder = 0;
  billingCtrl.PaymentState.advancedOrder = 18;
  billingCtrl.ReservationCreationType.basicOrder = 0;
  billingCtrl.ReservationCreationType.advancedOrder = 22;
  billingCtrl.ReservationCreationType.basicOrder = 0;
  billingCtrl.ReservationCreationType.advancedOrder = 23;
  billingCtrl.Email.basicOrder = 0;
  billingCtrl.Email.advancedOrder = 24;
  billingCtrl.LocationType.basicOrder = 0;
  billingCtrl.LocationType.advancedOrder = 26;
  billingCtrl.BillingType.basicOrder = 0;
  billingCtrl.BillingType.advancedOrder = 27;
  return billingCtrl;
};
export const getBillingFilters = () => {
  return {
    FilterInfo: [
      {
        FilterStates: {
          CREDITS: {
            id: "filterCredits",
            elementType: "filter",
            color: "#999966",
            elementConfig: {
              name: "Credits",
              type: "checkbox",
              value: "CREDITS",
              checked: true,
            },
            labelInput: {
              labeltext: "Credits",
              className: "filterlbl_Credits",
            },
          },
          CONFIRMED: {
            id: "filterConfirmed",
            color: "#5DBD91",
            elementType: "filter",
            elementConfig: {
              name: "Confirmed",
              type: "checkbox",
              value: "CONFIRMED",
              checked: true,
            },
            labelInput: {
              labeltext: "Confirmed",
              className: "filterlbl_Confirmed",
            },
          },
          IN_PROGRESS: {
            id: "filterInProgress",
            color: "#EACF12",
            elementType: "filter",
            elementConfig: {
              name: "InProgress",
              type: "checkbox",
              value: "IN_PROGRESS",
              checked: true,
            },
            labelInput: {
              labeltext: "In Progress",
              className: "filterlbl_InProgress",
            },
          },
          COMPLETED: {
            id: "filterCompleted",
            color: "#8DACB0",
            elementType: "filter",
            elementConfig: {
              name: "Completed",
              type: "checkbox",
              value: "COMPLETED",
              checked: true,
            },
            labelInput: {
              labeltext: "Completed",
              className: "filterlbl_Completed",
            },
          },
          CANCELLED: {
            id: "filterCancelled",
            color: "#ff6a00",
            elementType: "filter",
            elementConfig: {
              name: "Cancelled",
              type: "checkbox",
              value: "CANCELLED",
              checked: true,
            },
            labelInput: {
              labeltext: "Cancelled",
              className: "filterlbl_Cancelled",
            },
          },
          INCOMPLETE: {
            id: "filterIncompleted",
            color: "#ffd800",
            elementType: "filter",
            elementConfig: {
              name: "Incompleted",
              type: "checkbox",
              value: "INCOMPLETE",
              checked: true,
            },
            labelInput: {
              labeltext: "Incomplete",
              className: "filterlbl_Incomplete",
            },
          },
          NOT_STARTED: {
            id: "filterNotStarted",
            color: "#ff0000",
            elementType: "filter",
            elementConfig: {
              name: "NotStarted",
              type: "checkbox",
              value: "NOT_STARTED",
              checked: true,
            },
            labelInput: {
              labeltext: "Not Started",
              className: "filterlbl_NotStarted",
            },
          },
          LATE: {
            id: "filterLate",
            color: "#D98880",
            elementType: "filter",
            elementConfig: {
              name: "Late",
              type: "checkbox",
              value: "LATE",
              checked: true,
            },
            labelInput: {
              labeltext: "Late",
              className: "filterlbl_Late",
            },
          },
          RETURNED_LATE: {
            id: "filterReturnedLate",
            color: "#FF00FF",
            elementType: "filter",
            elementConfig: {
              name: "ReturnedLate",
              type: "checkbox",
              value: "RETURNED_LATE",
              checked: true,
            },
            labelInput: {
              labeltext: "Returned Late",
              className: "filterlbl_ReturnedLate",
            },
          },
          PAYMENT_PENDING: {
            id: "filterPaymentPending",
            color: "#6b2222",
            elementType: "filter",
            elementConfig: {
              name: "PaymentPending",
              type: "checkbox",
              value: "PAYMENT_PENDING",
              checked: true,
            },
            labelInput: {
              labeltext: "Payment Pending",
              className: "filterlbl_PaymentPending",
            },
          },
          BACKGROUND_CHECK_PENDING: {
            id: "filterBackgroundCheckPending",
            color: "#de2f13",
            elementType: "filter",
            elementConfig: {
              name: "BackgroundCheckPending",
              type: "checkbox",
              value: "BACKGROUND_CHECK_PENDING",
              checked: true,
            },
            labelInput: {
              labeltext: "Bg Check Pending",
              className: "filterlbl_BgCheckPending",
            },
          },
          PENDING: {
            id: "filterRequestPending",
            elementType: "filter",
            color: "#D36C19",
            elementConfig: {
              name: "RequestPending",
              type: "checkbox",
              value: "PENDING",
              checked: true,
            },
            labelInput: {
              labeltext: "Req. Pending",
              className: "filterlbl_Pending",
            },
          },
          WEBERROR: {
            id: "filterWebError",
            color: "darkgoldenrod",
            elementType: "filter",
            elementConfig: {
              name: "WebError",
              type: "checkbox",
              value: "WEBERROR",
              checked: true,
            },
            labelInput: {
              labeltext: "Web Error",
              className: "filterlbl_WebError",
            },
          },
        },
        Name: "Billing",
      },
    ],
  };
};

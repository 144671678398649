import React from 'react';
import CommonTitle from '../../component/common-title';

function ReservationNotes(props) {
    const { renterComments, setRenterComments, onChangesMade, disabled } = props;
    const onHandleChange = (event) => {
        setRenterComments(event?.target?.value);
        onChangesMade(true);
    }
    return (
        <div className="common-res-container input-ctrl">
            <CommonTitle title="Rental Special Request (Optional)" classNames="common-title" />
            <textarea
                id="renterComments"
                name="renterComments"
                disabled={disabled}
                value={renterComments || ""}
                onChange={(e) => onHandleChange(e)}
            />
        </div>

    )
}

export default ReservationNotes
import React from "react";
import {
  convertDateTimeString,
  getDateOnlyString,
  getCamelCaseId,
  isNullOrEmpty,
} from "../../../shared/utility";
import { CommonLabel } from "../label/commmon-label";
import CommonInputField from "./common-input-field";

export const LabelAndValuePairControl = (props) => {  
  const controlId = getCamelCaseId(
    ["input", "textarea", "phonenumber", "checkbox", "phonenumberwithflag"].includes(props.nodeElement)
      ? props.elementConfig.id
        ? props.elementConfig.id
        : props.elementConfig.name + "Id"
      : ""
  );
  const idAttribute = controlId ? "id" :  null;
  const typeAttribute = ["input", "radio", "checkbox"].includes(
    props.nodeElement
  )
    ? "type"
    : "";

  const typeValue = typeAttribute
    ? props.elementConfig.type
      ? props.elementConfig.type
      : "text"
    : "";

  let preventMaxMinDate =
    props.otherConfig.isDisableAfterCurrentDate === true
      ? { max: new Date() }
      : props.otherConfig.isDisableAfterCurrentDate === false
      ? { min: new Date() }
      : {};

  const getValueClasses = (entity) => {
    let valueClasses = entity.valueClasses;
    let controlValue =
      entity.nodeElement === "datetime"
        ? props.otherConfig.value
        : entity.nodeElement === "phonenumber" || entity.nodeElement === "phonenumberwithflag"
        ? isNullOrEmpty(props.elementConfig.value.Number)
          ? null
          : props.elementConfig.value.Number
        : entity.elementConfig && !isNullOrEmpty(entity.elementConfig.value)
        ? entity.elementConfig.value
        : null;

    let faliedValidationClass =
      !isNullOrEmpty(controlValue) && String(controlValue) !== "Invalid date"
        ? ""
        : props.isValidateDone &&
          entity.otherConfig &&
          entity.otherConfig.isRequired
        ? props.inputCategoryType === "license" && !props.isValidateLicense
          ? ""
          : "validation-failed"
        : "";
    return faliedValidationClass
      ? valueClasses + " " + faliedValidationClass
      : valueClasses;
  };

  return (
    <div className={props.parentRowClass} style={props.parentRowStyle || {}}>
      <label htmlFor={controlId} className={props.labelClasses}>
        {props.otherConfig.isHideLabel ? "" : props.otherConfig.caption}
      </label>
      <div className={getValueClasses(props)}>
        {props.isEditMode && !props.otherConfig.viewOnly ? (
          <CommonInputField
            elementConfig={{
              ...props.elementConfig,
              [typeAttribute]: typeValue,
              [idAttribute]: controlId,
            }}
            otherConfig={props.otherConfig}
            nodeElement={props.nodeElement || "input"}
            onChange={props.onChange}
            onChangePhone={props.onChangePhone}
            renderMobileAutoSueggestion={props.renderMobileAutoSueggestion}
            toggleInputTypeForPasswordField={
              props.toggleInputTypeForPasswordField
            }
            preventMaxMinDate={preventMaxMinDate}
          />
        ) : (
          <CommonLabel
            className={props.commonLabelClasses || ""}
            value={
              props.otherConfig.phoneNumberDisplayValue
                ? props.otherConfig.phoneNumberDisplayValue
                : props.otherConfig.displayDateValueWithAge
                ? props.otherConfig.displayDateValueWithAge
                : props.otherConfig.isDateField
                ? props.otherConfig.isWithDateTime
                  ? convertDateTimeString(props.otherConfig.value)
                  : getDateOnlyString(props.otherConfig.value)
                : props.otherConfig.customDisplayValue
                ? props.otherConfig.customDisplayValue
                : props.elementConfig.value
            }
            lblId={
              props.elementConfig.name ? "lbl" + props.elementConfig.name : ""
            }
            toolTipMsg={
              props.otherConfig && props.otherConfig.toolTipMsg
                ? props.otherConfig.toolTipMsg
                : ""
            }
            tooltipForceShow={props.otherConfig.isForceShow}
            onClick={
              props.otherConfig.openModal ? props.otherConfig.openModal : ""
            }
            modalParameters={
              props.otherConfig.modalParameters
                ? props.otherConfig.modalParameters
                : null
            }
          />
        )}
      </div>
    </div>
  );
};

import Phone from "../models/Phone";
import Employee from "../models/Employee";
import Address from "../models/Address";
import { getHintQuestionList } from "../../../shared/json/hint-questions";
import EmployeeInfo from "../models/EmployeeInfo";

export const UserProfileViewModel = function (loggedInUserData) {
  const data = loggedInUserData ? loggedInUserData.User : {};
  this.MemberId = data.MemberId || null;
  this.MembershipId = data.MembershipId || null;
  this.FirstName = data.FirstName || null;
  this.LastName = data.LastName || null;
  this.MiddleName = data.MiddleName || null;
  this.DateOfBirth = data.Dob || null;
  this.EmailAddress = data.EmailAddress || null;
  this.PrimaryAddress = data.PrimaryAddress
    ? new Address(data.PrimaryAddress)
    : new Address();
  this.HintQuestion = loggedInUserData ? loggedInUserData.HintQuestion : null;
  this.HintAnswer = loggedInUserData ? loggedInUserData.HintAnswer : null;
  this.Password = data.Password || null;
  this.OldPassword = data.OldPassword || null;
  this.ConfirmPassword = data.ConfirmPassword || null;
  this.MobilePhone = data.MobilePhone
    ? new Phone(data.MobilePhone)
    : new Phone();
  this.HomePhone = data.HomePhone ? new Phone(data.HomePhone) : new Phone();
  this.WorkPhone = data.WorkPhone ? new Phone(data.WorkPhone) : new Phone();
  this.PrimaryPhone = data.PrimaryPhone || null;
  this.OptInXpress = data.OptInXpress || null;
  this.VerificationCode = data.VerificationCode || null;
  this.PaymentFailureCount = data.PaymentFailureCount || null;
  this.Employee = new EmployeeInfo(data.Employee);
  this.HintQuestionList = getHintQuestionList();
  this.ProfileImageUrl = data.ProfileImageUrl;
};

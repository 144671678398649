import React, { useState } from "react";
import MicroLoader from "../loader/micro-loader";
import "./input-ctrl.scss";

const InputCtrl = (props) => {
    const {
        name,
        value,
        type,
        placeholder,
        onChange,
        onBlur,
        disabled,
        iconClass,
        imgIco,
        onClear,
        defaultconfig,
        isRequired,
        validateFunction,
        loader,
        parentClass = 'input-ctrl'
    } = props;
    const [error, setError] = useState(false);
    const onHandleOnChange = (e) => {
        if (isRequired && validateFunction) {
            setError(!validateFunction(name, e?.target?.value, true));
        }
        onChange(e?.target?.value, name, e);
    }
    const onRemove = () => {
        onClear(name);
    }
    return (
        <div className={`${parentClass} input-ctrl ${loader ? "position-relative" : ""}`}>
            {imgIco ? <img
                src={imgIco}
                alt=""
                className={`${disabled ? 'filter-gray' : ""}`}
            /> : iconClass && <i className={iconClass} aria-hidden="true"></i>}
            {placeholder && <label className={`place-holder ${isRequired ? "required" : ""}`}>{placeholder}</label>}
            <input
                type={type || "text"}
                name={name}
                onChange={onChange ? (e) => onHandleOnChange(e) : null}
                onBlur={onBlur ? onBlur : null}
                value={value || ''}
                disabled={disabled || loader}
                className={`${error && !validateFunction(name, value, true) ? "error-field" : ""}`}
                required={isRequired}
                {...defaultconfig}
            //placeholder={!value && placeholder}
            />
            {value && onClear ? <i className="fas fa-times-circle fs-0 clear-icon" onClick={onRemove}></i> : ""}
            {loader ? <MicroLoader classNames="micro-loader-input" /> : ""}
        </div>
    )
}
export default InputCtrl;
import { showNotification } from "../../components/UI/Toaster/Toaster";
import { convertMsgResponse } from "../../containers/Reservation/fox-reservation/fox-res-helper";
import { FoxRequestHeader } from "../../services/entities/fox-api/domain/fox-request-header";
import { invokeServerAPI } from "../../services/httpService";
import { DiscountType, HostName } from "../utility/enum-utility";

export const getCreditPolicyInfo = () => {
  const links = {
    locations: "https://www.foxrentacar.com/en/locations.html",
    corporateSite: "https://www.foxrentacar.com",
  };

  const renderLink = (href, text) => (
    <a
      style={{ textDecoration: "underline" }}
      href={href}
      target="_blank"
      rel="noreferrer"
    >
      {text}
    </a>
  );

  return (
    <>
      <p className="pb-4">
        To apply your available credits to a rental, the following conditions
        must be met:
      </p>
      <ul className="pl-4">
        <li>
          The rental must be from a Fox Rent-a-Car corporate location in the
          United States. A list of participating locations can be found&nbsp;
          {renderLink(links.locations, "here")}.
        </li>
        <li>
          The rental must be booked directly through our corporate site,&nbsp;
          {renderLink(links.corporateSite, "www.foxrentacar.com")}, and must be
          completed as a “Pay Now” reservation.
        </li>
      </ul>
      <p className="pt-4">
        Rentals that are included as part of a tour or travel package, or
        pre-paid rentals that are booked through a third party, including (but
        not limited to) third-party online travel agents (i.e., Expedia,
        Priceline, Kayak, etc.) are not eligible for credits redemption.
      </p>
    </>
  );
};

export const getPayNowAddOnDisclosureInfo = () => {
  return (
    <>
      <p>
        You can modify the selected add-ons anytime on your mobile app before
        your reservations starts. If you cancel your reservation, cancellation
        fees apply but you will be refunded for any purchased add-ons.
      </p>
    </>
  );
};
export const getSecurityDepositPolicy = () => {
  return (
    <>
      <p>
        FOX applies a deposit on your Credit Card at the time you complete your
        booking. The amount of the deposit can vary, depending on your car type,
        location, and method of payment. For more information about deposits,
        refer to our Rental Policies.
      </p>
      <br />
      <p>
        By agreeing, you authorize us to apply this deposit against your Credit
        Card to guarantee rental charges and, in the event that you have
        additional fees or charges due, you hereby authorize us to apply funds
        you have on deposit with us against what you owe to FOX. The deposit
        charge will be refunded when your rental has been completed.
      </p>
    </>
  );
};
export const getTestDiscountCodes = () => {
  return [
    {
      key: "FXTESTBOTH",
      value: "<b>FXTESTBOTH</b> : Apply 10 percent to prepaid and pay later",
      type: "TEST DISCOUNTS",
    },
    {
      key: "FXTESTPAYLATER",
      value: "<b>FXTESTPAYLATER</b> : Apply 5 percent to pay later",
      type: "TEST DISCOUNTS",
    },
    {
      key: "FXTESTPREPAID",
      value: "<b>FXTESTPREPAID</b> : Apply 3 percent to prepaid",
      type: "TEST DISCOUNTS",
    },
    {
      key: "FXTESTPICKUPDATE",
      value:
        "<b>FXTESTPICKUPDATE</b> : Apply 15 percent to pickup date between 2025-06-01 and 2025-07-01",
      type: "TEST DISCOUNTS",
    },
    {
      key: "FXTESTCLASSCODES",
      value:
        "<b>FXTESTCLASSCODES</b> : Apply 20 percent to class codes ECAR and FCAR",
      type: "TEST DISCOUNTS",
    },

    {
      key: "FXTESTLOCATIONCODES",
      value:
        "<b>FXTESTLOCATIONCODES</b> : Apply 15 percent to location codes LAX and BUR",
      type: "TEST DISCOUNTS",
    },
    {
      key: "FXTESTMINDAYS",
      value: "<b>FXTESTMINDAYS</b> : Apply 10 percent to minimum 3 rental days",
      type: "TEST DISCOUNTS",
    },
    {
      key: "FXTESTMAXDAYS",
      value:
        "<b>FXTESTMAXDAYS</b> : Apply 10 percent to maximum 15 rental days",
      type: "TEST DISCOUNTS",
    },
    {
      key: "FXTESTAPPLIMIT",
      value:
        "<b>FXTESTAPPLIMIT</b> : Apply 10 percent to application usage limit 10",
      type: "TEST DISCOUNTS",
    },
    {
      key: "FXTESTCUSTLIMIT",
      value:
        "<b>FXTESTCUSTLIMIT</b> : Apply 10 percent to customer usage limit 3",
      type: "TEST DISCOUNTS",
    },
    {
      key: "FXTESTFIRSTTIME",
      value:
        "<b>FXTESTFIRSTTIME</b> : Apply 25 percent to first reservation of customer",
      type: "TEST DISCOUNTS",
    },
    {
      key: "FXTESTFIRSTTIMELINK",
      value:
        "<b>FXTESTFIRSTTIMELINK</b> : Apply 30 percent to first reservation of customer and link discount to customer and expires in 2 days",
      type: "TEST DISCOUNTS",
    },
    {
      key: "FXTESTINACTIVE",
      value: "<b>FXTESTINACTIVE</b> : Apply 10 percent to inactive discount",
      type: "TEST DISCOUNTS",
    },
    {
      key: "FXTESTADMINONLY",
      value: "<b>FXTESTADMINONLY</b> : Apply 15 percent to Admin only",
      type: "TEST DISCOUNTS",
    },
    {
      key: "FXTESTWEBONLY",
      value: "<b>FXTESTWEBONLY</b> : Apply 10 percent to Web only",
      type: "TEST DISCOUNTS",
    },
  ];
};
export const getDiscountTypes = () => {
  return [
    { key: DiscountType.ONETIME, value: DiscountType.ONETIME },
    { key: DiscountType.OTHER, value: DiscountType.OTHER },
  ];
};
export const getTestHostNames = () => {
  return [HostName.LOCAL_HOST, HostName.DEV];
};
const showToastMsg = (messageResponse, returnType = false) => {
  showNotification(convertMsgResponse(messageResponse));
  //return returnType;
};
export const getCreditDiscountCodes = async () => {
  const req = {
    requestHeader: new FoxRequestHeader({ requestType: "POST" }),
    firstTimeOnly: false,
    linkToCustomer: true,
    active: true,
  };
  const response = await invokeServerAPI("foxapi/searchdiscount", req, true);
  if (response.message && response.message.messageType === "SUCCESS") {
    const discounts = [];
    response?.discounts.map((data, i) => {
      return discounts.push({
        key: data?.discountCode,
        value: "<b>" + data?.discountCode + "</b> : " + data?.description,
        type: "CREDIT DISCOUNTS",
      });
    });
    return discounts;
  }
  return [];
};
export const getReservationDetails = async (resReq) => {
  if (!resReq) return false;
  const req = {
    requestHeader: new FoxRequestHeader({ requestType: "GET" }),
    ...resReq,
  };
  const response = await invokeServerAPI("foxapi/reservation", req, true);
  if (response.message && response.message.messageType === "SUCCESS") {
    return response?.reservation;
  } else {
    showToastMsg(response?.message);
    return false;
  }
};
export const getPersonwiseAllDiscount = async (customerId, showMsg = true) => {
  if (!customerId) return [];
  const req = {
    requestHeader: new FoxRequestHeader({ requestType: "POST" }),
    personId: customerId,
  };
  const response = await invokeServerAPI(
    "admin/searchpersonfoxdiscount",
    req,
    true
  );
  if (response.message && response.message.messageType === "SUCCESS") {
    return response?.personFoxDiscounts;
  } else {
    if (showMsg) showToastMsg(response?.message);
    return false;
  }
};
export const grantDiscount = async (request) => {
  if (!request) return false;
  const req = {
    requestHeader: new FoxRequestHeader({ requestType: "POST" }),
    personFoxDiscount: { ...request },
  };
  const response = await invokeServerAPI("admin/personfoxdiscount", req, true);
  if (response.message && response.message.messageType === "SUCCESS") {
    showToastMsg(response?.message, true);
    return true;
  } else {
    showToastMsg(response?.message);
    return false;
  }
};
export const revertDiscount = async (
  discountId,
  revertedReason,
  revertedNotes
) => {
  if (!discountId) return false;
  const req = {
    requestHeader: new FoxRequestHeader({ requestType: "DELETE" }),
    personFoxDiscount: {
      personFoxDiscountId: discountId,
      revertedReason: revertedReason,
      revertedNotes: revertedNotes,
    },
  };
  const response = await invokeServerAPI("admin/personfoxdiscount", req, true);
  if (response.message && response.message.messageType === "SUCCESS") {
    showToastMsg(response?.message, true);
    return true;
  } else {
    showToastMsg(response?.message);
    return false;
  }
};

import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../../../components/UI/Spinner/Spinner";
import CircularStatusBar from "../../../../components/UI/StatusBar/circular-status-bar";
import StatusBar from "../../../../components/UI/StatusBar/status-bar";
import { showNotification } from "../../../../components/UI/Toaster/Toaster";
import ShuttleTripDetailsSearchRequest from "../../../../services/entities/shuttle-app/request/shuttle-trip-details-request";
import { ShuttleTripDetailsViewModel } from "../../../../services/entities/shuttle-app/view-model/shuttle-trip-details-view-model";
import { getBasicToastResponse } from "../../../../shared/utility";
import { getDateTimePartFromDate } from "../../../../shared/utility/shuttle-app-utility";
import { getShuttleTripDetails, getShuttleTripDetailsReset } from "../../../../store/actions";

const TripDetails = (props) => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.shuttleAPIReducer.loading);
  const shuttleTripDetailsResponse = useSelector(
    (state) => state.shuttleAPIReducer.shuttleTripDetailsResponse
  );
  const [tripDetailsData, setTripDetailsData] = useState(
    new ShuttleTripDetailsViewModel()
  );

  useEffect(() => {
    let req = new ShuttleTripDetailsSearchRequest({ tripCode: props.tripCode });
    dispatch(getShuttleTripDetailsReset());
    dispatch(getShuttleTripDetails(req));
  }, [props.tripCode]);

  useEffect(() => {
    if (!shuttleTripDetailsResponse) {
      return;
    }
    if (
      shuttleTripDetailsResponse.message &&
      shuttleTripDetailsResponse.message.messageType === "SUCCESS"
    ) {
      setTripDetailsData(
        new ShuttleTripDetailsViewModel(shuttleTripDetailsResponse.tripSummary)
      );
    } else {
      showNotification(getBasicToastResponse(shuttleTripDetailsResponse));
    }
    dispatch(getShuttleTripDetailsReset());
  }, [shuttleTripDetailsResponse]);

  const renderContent = (
    value1,
    value2,
    addlValue,
    parentClass,
    subParentClass,
    itemClass1,
    itemClass2,
    addlItemClass
  ) => {
    return (
      <div className={`${parentClass || ""} flex-direction-column`}>
        <div
          className={`${subParentClass || ""
            } vertical-align-center flex-direction-column`}
        >
          <span className={`${itemClass1 || ""} font-weight-bold fs-3`}>
            {value1}
          </span>
          <span className={` ${itemClass2 || ""}`}>{value2}</span>
        </div>
        {addlValue ? (
          <div
            className={`${addlItemClass || ""} text-center font-weight-bold`}
          >
            {addlValue}
          </div>
        ) : (
          ""
        )}
      </div>
    );
  };
  const renderTimeFields = (startDatetime, endDatetime) => {
    return (
      <div className="horizontal-align-center">
        {renderContent(
          getDateTimePartFromDate(startDatetime),
          getDateTimePartFromDate(startDatetime, true),
          "Start",
          "col-lg-6",
          "time-container p-1",
          "",
          "",
          "pt-4"
        )}
        {renderContent(
          getDateTimePartFromDate(endDatetime),
          getDateTimePartFromDate(endDatetime, true),
          "End",
          "col-lg-6",
          "time-container p-1",
          "",
          "",
          "pt-4"
        )}
      </div>
    );
  };
  const renderSpeedFields = (tripDetailsInfo) => {
    return (
      <div className="horizontal-align-center column-gap-1 mtr-2">
        <CircularStatusBar
          value={tripDetailsInfo.tripDisplayAvgSpeed || 0}
          unit={tripDetailsInfo.speedUnit}
          size={90}
          footerText="Avg. Speed"
          pathColor="#58E758"
          trailColor="light-gray"
          container="speed-container"
        />
        <CircularStatusBar
          value={tripDetailsInfo.tripDisplayMaxSpeed || 0}
          unit={tripDetailsInfo.speedUnit}
          size={90}
          footerText="Max Speed"
          pathColor="#58E758"
          trailColor="light-gray"
          container="speed-container"
        />
      </div>
    );
  };

  const renderStatusFields = (tripDetailsInfo) => {
    const hasNoTrip = tripDetailsInfo?.shuttleEngineStatuses.length &&
      tripDetailsInfo.shuttleEngineStatuses.some(item => item.tripDurationRatioInValue > 0);
    return (
      <div className="horizontal-align-center flex-direction-column">
        <div className="text-center fs-3 font-weight-bold letter-spacing-10">
          ENGINE STATUS
        </div>
        <div className="status-container">
          <StatusBar
            statusItems={tripDetailsInfo.shuttleEngineStatuses}
            noStatusClass="no-trip"
            defaultHeight="5rem"
            defaulWidth="100%"
            contentParentClass={"vertical-align-center flex-direction-column pt-2 pb-2 fs-3"}
          />
        </div>

        <div className="text-center fs-3 pt-2" style={{ minHeight: "2rem" }}>{hasNoTrip ? "Duration: " + tripDetailsInfo.tripDuration : ""}</div>
      </div>
    );
  };
  const getTripStatusDetailsHtmlRow = (activity) => {
    return (
      <tr>
        <td>
          {activity.engineStatusDisplayName}
        </td>
        <td>
          {activity.activityStartDateTime}
        </td>
        <td>
          {activity.activityDuration}
        </td>
      </tr>
    );
  };
  const renderTripStatusDetails = (activities) => {
    return (
      <div
        className="table-fixed-head tableScroll"
        style={{
          maxHeight: "24rem",
          border: "1px solid",
        }}
      >
        <table className="table table-striped" width="100%">
          <thead className="thead-dark-light">
            <tr>
              <th align="left">Status</th>
              <th align="left">Time</th>
              <th align="left">Duration</th>
            </tr>
          </thead>
          <tbody>
            {activities && activities.length > 0 && activities.map((activity) => {
              return getTripStatusDetailsHtmlRow(activity)
            })

            }
          </tbody>
        </table>
      </div>
    );
  };
  return (
    <Fragment>
      <div className="details-container expand-bg-color">
        <div className="col-lg-12 vertical-align-center">
          <div
            className="col-lg-5 vertical-align-center flex-direction-column"
            style={{ rowGap: "6rem" }}
          >
            <div className="col-lg-12 horizontal-align-center column-gap-1">
              <div className="col-lg-6">{renderTimeFields(tripDetailsData.tripDisplayStartDateTime, tripDetailsData.tripDisplayEndDateTime)}</div>
              <div className="col-lg-6">{renderSpeedFields(tripDetailsData)}</div>
            </div>
            <div className="col-lg-12">{renderStatusFields(tripDetailsData)}</div>
          </div>

          <div className="col-lg-7 mtr-4">
            <div
              className="col-lg-12"
              id="trip-details-table"
            >
              <div
                className="text-center fs-3 font-weight-bold letter-spacing-10"
              >
                ACTIVITY
              </div>
              {renderTripStatusDetails(tripDetailsData.activities || [])}
            </div>
          </div>
        </div>
      </div>
      {loading ? <Spinner /> : ""}
    </Fragment>
  );
};

export default TripDetails;
import { useEffect, useState } from "react";
import ReactHtmlParser from "react-html-parser";
import FoxReservationDefaultPage from "../../../../../components/common/pages/fox-res-default-page";
import InfoField from "../../../../../components/UI/label/InfoField";
import { INFO_ICON, NO_CAR_INFO } from "../../../../../shared/AppImages";
import { COMMON_MSG, TOAST_MSG } from "../../../../../shared/GlobalVar";
import { formatCurrency, getDateTimeString, getSubString, toTitleCase } from "../../../../../shared/utility";
import { getLocalSortingClassName } from "../../../../../shared/utility/sorting-utility";

const FoxCustomerReservationHistory = (props) => {
    const { resData, updateSortInfo, sortingInfo } = props;
    const [reservationData, setReservationData] = useState([]);
    useEffect(() => {
        if (resData.length) {
            setReservationData(resData);
        } else {
            setReservationData([]);
        }
    }, [resData, resData.length]);

    const renderNoBusinessHoursFound = () => (
        <FoxReservationDefaultPage
            messageType={TOAST_MSG.WARNING}
            iconContent={NO_CAR_INFO}
            title="No Data Found"
            msg={COMMON_MSG.CustomerReservationMisingMsg}
            customParentClass="horizontal-align-center w-100"
            customerClassMessageTitle="w-auto"
        />
    );

    const renderHeader = (labelorValue, sortEventType) => {
        return (
            <div
                className={`position-relative flex-table-cell
                ${sortEventType
                        ? getLocalSortingClassName(sortingInfo, sortEventType)
                        : ""
                    }
            `}
                key={labelorValue}
                onClick={(event) =>
                    sortEventType
                        ? updateSortInfo(sortEventType)
                        : null
                }
            >
                {labelorValue}
            </div>
        );
    };
    const renderDataRow = ({
        value,
        label = "",
        rowIndex,
        hasCopyIcon = false,
        customValueParentClass = "",
        customValueClass = "",
        isTestReservation = false,
        tooltipText = "",
        iconTooltipText = "",
        modalIcon = null,
        isMaxDate = false,
    }) => {
        return (
            <div className="flex-table-cell" key={`${rowIndex}-${label}`}>
                <InfoField
                    label={label}
                    value={value}
                    hasCopyIcon={hasCopyIcon}
                    customValueParentClass={customValueParentClass}
                    customValueClass={customValueClass}
                    tooltip={ReactHtmlParser(tooltipText)}
                    imageClass="info-icon"
                    modalIcon={modalIcon}
                    iconTooltipText={ReactHtmlParser(iconTooltipText)}
                />
                {isTestReservation && <span className="test-res-label">Test</span>}
                {isMaxDate && <span className="last-res-label">Last Res</span>}
            </div>
        );
    };
    const getAddonsValue = (addons, isTooltip) => {
        if (!addons || addons.length === 0) return "";

        return addons
            .map((element, index) => `${index + 1}. ${element.DisplayText}`)
            .join(isTooltip ? "\n" : "</br>");
    };
    const renderContent = (data) => {
        if (data && data.length) {
            data = JSON.parse(JSON.stringify(data));
            const dateObjects = data.map(resData => new Date(getDateTimeString(resData.CreatedOn)));
            const maxDate = new Date(Math.max(...dateObjects));
            return data.map((rowData, index) => {
                const uniqueIndex = `${rowData.ReservationId}-${index}`;
                return (
                    <div className="flex-table-row" key={uniqueIndex}>
                        {renderDataRow({
                            value: rowData.ConfirmationKey,
                            label: "Res. Code",
                            rowIndex: `${uniqueIndex}-ConfirmationKey`,
                            hasCopyIcon: true,
                            isTestReservation: rowData.ReservationCreationType === "TEST",
                        })}
                        {renderDataRow({
                            value: rowData.PickUpLocationId !== rowData.ReturnLocationId
                                ? rowData.PickUpLocation + " to </br>" + rowData.ReturnLocation
                                : rowData.PickUpLocation,
                            label: "Pick Loc",
                            rowIndex: `${uniqueIndex}-PickUpLocation`,
                            customValueClass: "line-clamp",
                            iconTooltipText:
                                rowData.PickUpLocationId !== rowData.ReturnLocationId
                                    ? rowData.PickUpLocation + "\nto\n" + rowData.ReturnLocation
                                    : "",
                            modalIcon: rowData.PickUpLocationId !== rowData.ReturnLocationId && INFO_ICON,
                        })}
                        {renderDataRow({
                            value: getDateTimeString(rowData.PromisedStartDate),
                            label: "Promised Start Date",
                            rowIndex: `${uniqueIndex}-PromisedStartDate`,
                        })}
                        {renderDataRow({
                            value: getDateTimeString(rowData.PromiseEndDate),
                            label: "Promise End Date",
                            rowIndex: `${uniqueIndex}-PromiseEndDate`,
                        })}
                        {renderDataRow({
                            value: rowData.ReservationState,
                            label: "Reservation State",
                            rowIndex: `${uniqueIndex}-ReservationState`,
                            customValueParentClass: `${rowData.ReservationState} ${rowData.IsLateReservation}`,
                            tooltipText: "",
                            iconTooltipText:
                                rowData.ReservationState === "CANCELLED"
                                    ? `Cancelled On: ${getDateTimeString(rowData.CancelledOn)}\nCancelled By: ${rowData.CancelledBy}`
                                    : "",
                            modalIcon: rowData.ReservationState === "CANCELLED" && INFO_ICON,
                        })}
                        {renderDataRow({
                            value: rowData.CategoryName && rowData.CategoryName.split("-").length > 1
                                ? getSubString(rowData.CategoryName, 0, 4)
                                : rowData.CategoryName || "",
                            label: "Category Name",
                            rowIndex: `${uniqueIndex}-CategoryName`,
                        })}
                        {renderDataRow({
                            value: toTitleCase(rowData.Category),
                            label: "Category",
                            rowIndex: `${uniqueIndex}-Category`,
                        })}
                        {renderDataRow({
                            value: getAddonsValue(rowData.UpsellOptions),
                            label: "Upsell Options",
                            rowIndex: `${uniqueIndex}-UpsellOptions`,
                            customValueClass: "line-clamp",
                            // tooltipText: getAddonsValue(rowData.UpsellOptions, true),
                            iconTooltipText: getAddonsValue(rowData.UpsellOptions, true),
                            modalIcon: rowData.UpsellOptions.length > 1 && INFO_ICON,
                        })}
                        {renderDataRow({
                            value: formatCurrency(rowData.TotalEstimatedPaidSP),
                            label: "Total Estimated Paid SP",
                            rowIndex: `${uniqueIndex}-TotalEstimatedPaidSP`,
                        })}
                        {renderDataRow({
                            value: formatCurrency(rowData.BalanceDue),
                            label: "Balance Due",
                            rowIndex: `${uniqueIndex}-BalanceDue`,
                        })}
                        {renderDataRow({
                            value: getDateTimeString(rowData.CreatedOn),
                            label: "Created On",
                            rowIndex: `${uniqueIndex}-CreatedOn`,
                            isMaxDate: new Date(maxDate).getTime() === new Date(getDateTimeString(rowData.CreatedOn)).getTime(),
                        })}
                    </div>
                );
            });
        } else {
            return renderNoBusinessHoursFound();
        }
    };
    return (
        <div id="table-container">
            <div className="flex-table">
                <div className="flex-table-header">
                    {renderHeader("Res. Code", "ConfirmationKey")}
                    {renderHeader("Pickup & Return Location", "PickUpLocation")}
                    {renderHeader("Pickup Date & Time", "PromisedStartDate")}
                    {renderHeader("Return Date & Time", "PromiseEndDate")}
                    {renderHeader("Res. Status", "ReservationState")}
                    {renderHeader("Vehicle Category", "CategoryName")}
                    {renderHeader("Vehicle Type", "Category")}
                    {renderHeader("Add Ons")}
                    {renderHeader("Total Amount")}
                    {renderHeader("Balance", "BalanceDue")}
                    {renderHeader("Date Created", "CreatedOn")}
                </div>
                <div className="tableScroll" style={{ maxHeight: "45rem", minHeight: "45rem" }}>
                    {reservationData && reservationData.length
                        ? renderContent(reservationData)
                        : renderNoBusinessHoursFound()}
                </div>
            </div>
        </div>
    );
};

export default FoxCustomerReservationHistory;

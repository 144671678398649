import { ModalType } from "../../shared/GlobalVar";

const closeModal = (setModalData) => {
  setModalData(null);
};
export const getDiscountModalInfo = (data, setModalData, showLoader) => {
  setModalData({
    modalType: ModalType.GRANT_DISCOUNT_MODAL,
    modalProps: {
      showModal: true,
      isDraggable: true,
      closeModal: closeModal,
      setData: setModalData,
      modalTitle: "Add/Reverse Discount: " + data?.customerName,
      contentClassName: "discount-history-modal",
      modalMinWidth: "1100px",
      modalMaxWidth: "1100px",
    },
    modalBodyProps: {
      ...data,
      closeModal: closeModal,
      setLoader: showLoader,
      setData: setModalData,
    },
  });
};
export const getLocationQuickModal = (data, setModalData, showLoader) => {
  setModalData({
    modalType: ModalType.LOCATION_QUICK_MODAL,
    modalProps: {
      showModal: true,
      isDraggable: true,
      closeModal: closeModal,
      setData: setModalData,
      modalTitle: "Location Information",
      contentClassName: "discount-history-modal",
      modalMinWidth: "1100px",
      modalMaxWidth: "1100px",
    },
    modalBodyProps: {
      ...data,
      closeModal: closeModal,
      setLoader: showLoader,
      setData: setModalData,
    },
  });
};
export const getOldReservationModal = (data, setModalData, showLoader) => {
  setModalData({
    modalType: ModalType.QUICK_RES_SUMMARY,
    modalProps: {
      showModal: true,
      isDraggable: true,
      closeModal: closeModal,
      setData: setModalData,
      modalTitle: "Old Information",
      modaWrapperClass: "reservation-quick-modal",
    },
    modalBodyProps: {
      ...data,
      closeModal: closeModal,
      setLoader: showLoader,
      setData: setModalData,
    },
  });
};
export const getInvoiceModal = (data, setModalData, showLoader) => {
  setModalData({
    modalType: ModalType.INVOICE_INFO_MODAL,
    modalProps: {
      showModal: true,
      isDraggable: true,
      closeModal: closeModal,
      setData: setModalData,
      modalTitle: "Invoice Details",
      modalMinWidth: "80rem",
      modalMaxWidth: "80rem",
    },
    modalBodyProps: {
      ...data,
      closeModal: closeModal,
      setLoader: showLoader,
      setData: setModalData,
    },
  });
};
export const getTransactionModal = (data, setModalData, showLoader) => {
  setModalData({
    modalType: ModalType.TRANSACTION_MODAL,
    modalProps: {
      showModal: true,
      isDraggable: true,
      closeModal: closeModal,
      setData: setModalData,
      modalTitle: "Transaction Data",
      modaWrapperClass: "reservation-quick-modal",
      modalMinWidth: "90%",
      modalMaxWidth: "90%",
    },
    modalBodyProps: {
      ...data,
      closeModal: closeModal,
      setLoader: showLoader,
      setData: setModalData,
    },
  });
};
export const getCustomerReservationHistoryModal = (
  data,
  setModalData,
  showLoader
) => {
  setModalData({
    modalType: ModalType.CUSTOMER_RESERVATION_HISTORY_MODAL,
    modalProps: {
      showModal: true,
      isDraggable: true,
      closeModal: closeModal,
      setData: setModalData,
      modalMinWidth: "90%",
      modalMaxWidth: "90%",
      modalTitle: "Customer Reservation History",
      modalClassName: "customer-reservation-history",
    },
    modalBodyProps: {
      ...data,
      closeModal: closeModal,
      setLoader: showLoader,
      setData: setModalData,
    },
  });
};

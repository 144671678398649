import React from "react";

const TimerComponent = (props) => {
  return (
    <div className={props.divClass ? props.divClass : ""}>
      <span className={props.timerSectionClasses}>{props.timer}</span>
      <span className={props.unitSectionClasses}>{props.timerUnit}</span>
    </div>
  );
};

export default TimerComponent;
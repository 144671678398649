import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Draggable from "react-draggable";
import { OK_LBL } from "../../../shared/GlobalVar";

export const EditReservationNotAllowedModal = (props) => {
  return (
    <Draggable handle=".cursor">
      <Modal
        isOpen={props.showModal}
        className="no-cursor"
        toggle={() => {
          props.closeModal();
        }}
      >
        <ModalHeader
          className="modal-header bg-primary modal-title text-white cursor"
          toggle={() => {
            props.closeModal();
          }}
        >
          <i className="fas fa-arrows-alt" /> Edit Reservation Not Allowed
        </ModalHeader>
        <ModalBody>
          <div className="input-container">
            <div className="FormRow">
              <div className={`form-group formRow`}>
                <p className="col-sm-12 col-form-label font-weight-bold text-justify">
                  This reservation cannot be edited from here. To edit this
                  reservation please change your Org to "
                  <span>{props.locationData.DependentDisplayName}</span>"
                </p>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            className="btn btn-secondary  waves-effect waves-light btnSize ml-2"
            onClick={(event) => props.closeModal()}
          >
            {OK_LBL}
          </button>
        </ModalFooter>
      </Modal>
    </Draggable>
  );
};

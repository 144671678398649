import React, { Fragment, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { getSettingsSubMenu } from "./settings-submenu-helper";
import Sidebar from "../../../components/Sidebar/sidebar.jsx";
import SidebarContainer from "../../../components/Sidebar/sidebar-container";
import {
  clearActiveMenu,
  getDropdownItems,
} from "../../../shared/utility";
import { setActiveMenu } from "../../../containers/search/search-utility/search-utility";
import {
  getLocationCodeFromLocationId,
  getSettingPageName,
} from "../../../containers/Settings/settingsHelper";
import { ADMIN_PAGE } from "../../../shared/GlobalVar";
import { getLocalStorageData } from "../../../shared/LocalStorage";
import { getCompatibleDdlOrg } from "../../../shared/utility/shuttle-app-utility";

const SettingsSubMenu = (props) => {
  const [isExpandableSidebar, setIsExpandableSidebar] = useState(true);
  const [locations, setLocations] = useState([]);
  const query = new URLSearchParams(props.location.search);
  const [selectedOrgLocationId, setSelectedOrgLocationId] = useState(
    query.get("orgId")
  );
  const loggedInUserData = useSelector((state) => state.auth.loggedInUserData);
  const dependentOrg = useSelector(
    (state) => state.auth.loggedInUserData.DependantOrgList
  );
  const dependantOrgList = useSelector((state) =>
    state.auth.loggedInUserData.DependantLocations.length > 0
      ? state.auth.loggedInUserData.DependantLocations
      : []
  );
  const parentOrgTimezoneString =
    getLocalStorageData("timeZoneString") || "America/Los_Angeles";
  const parentOrgKey = getLocalStorageData("currentOrgUnitKey") || "THUMBIT";
  const parentOrgDisplayName =
    getLocalStorageData("currentOrgUnitDisplayName") || "FOX";
  const selectedOrgKeyValue = useSelector(
    (state) => state.auth.loggedInUserData.SelectedOrgKeyValue
  );
  const [selectedOrgLocationCode, setSelectedOrgLocationCode] = useState("");
  const currentOrgUnitId = useSelector(
    (state) => state.auth.loggedInUserData.CurrentOrgUnitId
  );
  useEffect(() => {
    clearActiveMenu();
    setActiveMenu(props.match.url);
  }, [props.match.url]);

  useEffect(() => {
    updateLocationDropdown();
  }, [dependentOrg]);
  useEffect(() => {
    props.history.push(
      `${props.location.pathname}?orgId=${selectedOrgLocationId}`
    );
    let locationCode = getLocationCodeFromLocationId(
      dependantOrgList,
      selectedOrgLocationId,
      parentOrgKey
    );
    setSelectedOrgLocationCode(locationCode);
  }, [selectedOrgLocationId]);

  const handleOnChange = ({ target }, isShuttle) => {
    let locationId = target.value;
    if (isShuttle) {
      locationId =
        target.options[target.selectedIndex].getAttribute("locationid");
    }
    setSelectedOrgLocationId(locationId);
  };

  const toggleNavbar = () => {
    setIsExpandableSidebar(!isExpandableSidebar);
  };
  const updateLocationDropdown = () => {
    let locList = [];
    locList.push(selectedOrgKeyValue);
    locList = [...locList, ...dependentOrg];
    setLocations(getDropdownItems(locList, "Key", "Value"));
  };
  const updateLocationddlfromChild = (entity) => {
    const updateList = [...locations];
    updateList.push(entity);
    setLocations(updateList);
  };
  const defaultValuesForOrgLocation = {
    key: parentOrgKey,
    keyValue: parentOrgKey + 0,
    displayValue: parentOrgDisplayName,
    otherId: parentOrgTimezoneString,
    locationId: currentOrgUnitId,
  };
  return (
    <Fragment>
      <Sidebar
        subMenu={getSettingsSubMenu(selectedOrgLocationId || null)}
        isExpandableSidebar={isExpandableSidebar}
        toggleNavbar={toggleNavbar}
      />
      <div
        className={
          isExpandableSidebar ? "active content-wrapper1" : "content-wrapper1"
        }
        id="orgSettingPanel"
      >
        {getSettingPageName(props.match.url) !==
          ADMIN_PAGE.MAIN_SETTINGS_PAGE &&
        getSettingPageName(props.match.url) !==
          ADMIN_PAGE.PERMISSION_SETTINGS ? (
          <div className="row col-lg-12 settings-top-location pt-3 pb-1 pl-2">
            <label className="font-weight-bold text-right">Location</label>
            <div className="col-sm-2">
              {getSettingPageName(props.match.url) !==
              ADMIN_PAGE.SHUTTLES_SETTINGS ? (
                <select
                  type="text"
                  name="orgLocationId"
                  value={selectedOrgLocationId}
                  onChange={(event) => handleOnChange(event)}
                >
                  {locations.map((element) => (
                    <option key={element.key} value={element.key}>
                      {element.displayValue}
                    </option>
                  ))}
                </select>
              ) : (
                <select
                  type="text"
                  id="orgLocationId"
                  value={selectedOrgLocationCode}
                  onChange={(event) => handleOnChange(event, true)}
                >
                  {getCompatibleDdlOrg(
                    dependantOrgList,
                    defaultValuesForOrgLocation
                  ).map((option, i) => (
                    <option
                      key = {option.key}
                      value={option.key}
                      otherid={option.otherId}
                      locationid={option.locationId}
                    >
                      {option.displayValue}
                    </option>
                  ))}
                </select>
              )}
            </div>
          </div>
        ) : (
          ""
        )}
        <SidebarContainer
          subMenu={getSettingsSubMenu(selectedOrgLocationId || null)}
          isExpandableSidebar={isExpandableSidebar}
          toggleNavbar={toggleNavbar}
          otherData={{ selectedOrgLocationId: selectedOrgLocationId }}
          updateData={updateLocationddlfromChild}
        />
      </div>
    </Fragment>
  );
};
export default SettingsSubMenu;

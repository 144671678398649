import React from 'react';
import { getFormattedAppliedRates } from '../../../../../shared/utility/pricing-utility';
import CommonTitle from '../../../component/common-title';
import RateRow from '../rate-row';

function PricingRateBlock({ appliedRates, currentFoxDiscount }) {
    const formattedRates = getFormattedAppliedRates(appliedRates);
    return (
        <>
            <CommonTitle title="Rate" classNames="common-title" />
            {formattedRates.map((data) => {
                return <RateRow rateData={data} key={data?.title} />;
            })}
            {currentFoxDiscount &&
                <RateRow rateData={currentFoxDiscount} key={currentFoxDiscount?.title} />
            }
        </>
    )
}

export default PricingRateBlock
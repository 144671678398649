import React from "react";
import InvoiceInfoModal from "../../containers/billing/modal/invoice-info-modal";
import TransactionListModal from "../../containers/billing/modal/transaction-list-modal";
import AboutModal from "../../containers/dashboard/about-modal";
import AllAddresModal from "../../containers/Member/modal/all-address-modal";
import ApplyOfferModal from "../../containers/Member/modal/apply-offer-modal";
import FreeCreditGrantReverseModal from "../../containers/Member/modal/free-credit-grant-reverse-modal";
import MemberQuickModal from "../../containers/Member/modal/member-quick-modal";
import FoxCustomerReservationHistory from "../../containers/Reservation/fox-reservation/fox-modal/customer-reservation-history/fox-customer-reservation-history-modal";
import FoxCancelModal from "../../containers/Reservation/fox-reservation/fox-modal/fox-cancel-modal";
import FoxChannelQuickModal from "../../containers/Reservation/fox-reservation/fox-modal/fox-channel-quick-info-modal";
import FoxResendPaymentLinkModal from "../../containers/Reservation/fox-reservation/fox-modal/fox-resend-payment-link";
import FoxLocationQuickModal from "../../containers/Reservation/fox-reservation/fox-modal/location-modal/fox-location-quick-modal";
import AcceptDeclineReservationModal from "../../containers/Reservation/modal/accept-decline-reservation-modal";
import ChannelQuickModal from "../../containers/Reservation/modal/channel-quick-modal";
import ContractModal from "../../containers/Reservation/modal/contract-modal";
import { EditReservationNotAllowedModal } from "../../containers/Reservation/modal/edit-reservation-not-allowed-modal";
import ExternalParameterUpdateModal from "../../containers/Reservation/modal/external-parameter-update-modal";
import IntegrationHistoryModal from "../../containers/Reservation/modal/integration-history-modal";
import ReservationActionSummaryModal from "../../containers/Reservation/modal/reservation-action-summary-modal";
import ReservationDetailsUpdateModal from "../../containers/Reservation/modal/reservation-details-update-modal";
import ReservationInfoModal from "../../containers/Reservation/modal/reservation-info-modal";
import ReserVationNotesModal from "../../containers/Reservation/modal/reservation-notes-modal";
import ReservationSummaryInvoiceModal from "../../containers/Reservation/modal/reservation-summary-invoice-modal";
import UpdateReservationCreationTypeModal from "../../containers/Reservation/modal/update-reservation-creation-type-modal";
import VoucherInfoModal from "../../containers/Reservation/modal/voucher/voucher-info-modal";
import AppAccesstoMember from "../../containers/roles-permissions/modal/app-access-to-member-modal";
import PermissiontoMember from "../../containers/roles-permissions/modal/permission-to-member-modal";
import RolestoMember from "../../containers/roles-permissions/modal/roles-to-member-modal";
import MailModal from "../../containers/search/modal/mail-modal";
import TrackerDetailsQuickView from "../../containers/search/reports_search/trackerDetailsQuickView";
import AddNewLocationModal from "../../containers/Settings/location-settings/modal/add-new-location-modal";
import DeleteVehicleModal from "../../containers/vehicle/modal/delete-vehicle-modal";
import VehicleAvailabilityStatusModal from "../../containers/vehicle/modal/vehicle-availability-status-modal";
import VehicleCategoryQuickModal from "../../containers/vehicle/modal/vehicle-category-quick-modal";
import VehicleQuickModal from "../../containers/vehicle/modal/vehicle-quick-modal";
import { ModalType } from "../../shared/GlobalVar";
import CountDownModal from "../common/modal/count-down-modal";
export const ModalEngine = (props) => {
  let modalResponse = null;
  const commonProps = {
    showModal: props.showModal,
    closeModal: props.closeModal,
  };
  const commonPropsWithDefaultData = {
    ...commonProps,
    defaultData: props.defaultData,
  };

  const commonPropsWithVehicleId = {
    ...commonProps,
    vehicleId: props.vehicleId,
  };
  const commonPropsWithMemberId = {
    ...commonProps,
    memberId: props.memberId,
  };

  const commonPropsWithMemberIds = {
    ...commonProps,
    memberIds: props.memberIds,
  };
  const commonPropsWithReservationId = {
    ...commonProps,
    reservationId: props.reservationId,
  };

  if (props.modalType === ModalType.VEHICLE_QUICK_MODAL) {
    modalResponse = (
      <VehicleQuickModal {...commonProps} vehicleId={props.vehicleId} />
    );
  } else if (props.modalType === ModalType.VEHICLE_CATEGORY_QUICK_MODAL) {
    modalResponse = (
      <VehicleCategoryQuickModal {...commonPropsWithDefaultData} />
    );
  } else if (props.modalType === ModalType.VEHICLE_AVAILABILITY_STATUS_MODAL) {
    modalResponse = (
      <VehicleAvailabilityStatusModal
        {...commonPropsWithVehicleId}
        vehicleName={props.vehicleName}
      />
    );
  } else if (props.modalType === ModalType.DELETE_VEHICLE_MODAL) {
    modalResponse = (
      <DeleteVehicleModal
        {...commonPropsWithVehicleId}
        vehicleName={props.vehicleName}
        resetParentPage={props.resetParentPage}
      />
    );
  } else if (props.modalType === ModalType.MEMBER_QUICK_MODAL) {
    modalResponse = <MemberQuickModal {...commonPropsWithMemberId} />;
  } else if (props.modalType === ModalType.ALL_ADDRESS_MODAL) {
    modalResponse = (
      <AllAddresModal
        {...commonPropsWithMemberId}
        resetLoader={props.resetLoader || null}
        resetParentPage={props.resetParentPage}
      />
    );
  } else if (props.modalType === ModalType.PERMISSION_TO_USERS) {
    modalResponse = <PermissiontoMember {...commonPropsWithMemberIds} />;
  } else if (props.modalType === ModalType.ROLES_TO_USERS) {
    modalResponse = (
      <RolestoMember
        {...commonPropsWithMemberIds}
        resetParentPage={props.resetParentPage}
      />
    );
  } else if (props.modalType === ModalType.APP_ACCESS_TO_USERS) {
    modalResponse = (
      <AppAccesstoMember
        {...commonPropsWithMemberIds}
        resetParentPage={props.resetParentPage}
      />
    );
  } else if (props.modalType === ModalType.INVOICE_INFO_MODAL) {
    modalResponse = (
      <InvoiceInfoModal
        {...commonProps}
        invoiceId={props.invoiceId}
        transactionId={props.transactionId}
      />
    );
  } else if (props.modalType === ModalType.LOCATION_QUICK_MODAL) {
    modalResponse = (
      <FoxLocationQuickModal {...commonProps} locationId={props.locationId} />
    );
  }
  // else if (
  //   props.modalType === ModalType.CANCEL_RESERVATION_MODAL ||
  //   props.modalType === ModalType.CANCEL_REASON_UPDATE_MODAL
  // ) {
  //   modalResponse = (
  //     <CancelReservationModal
  //       {...commonPropsWithDefaultData}
  //       modalTitle={props.modalTitle}
  //       resetParentPage={props.resetParentPage}
  //     />
  //   );
  // }
  else if (
    props.modalType === ModalType.CANCEL_FOX_MODAL ||
    props.modalType === ModalType.CANCEL_REASON_UPDATE_MODAL
  ) {
    modalResponse = (
      <FoxCancelModal
        {...commonPropsWithDefaultData}
        modalTitle={props.modalTitle}
        resetParentPage={props.resetParentPage}
      />
    );
  } else if (props.modalType === ModalType.TRANSACTION_MODAL) {
    modalResponse = <TransactionListModal {...commonPropsWithReservationId} />;
  } else if (props.modalType === ModalType.EXTERNAL_PARAMETER_UPDATE_MODAL) {
    modalResponse = (
      <ExternalParameterUpdateModal
        {...commonPropsWithDefaultData}
        resetParentPage={props.resetParentPage}
        isEnableVoucherModal={props.isEnableVoucherModal}
      />
    );
  } else if (props.modalType === ModalType.RESERVATION_NOTES_MODAL) {
    modalResponse = (
      <ReserVationNotesModal
        {...commonPropsWithDefaultData}
        resetParentPage={props.resetParentPage}
      />
    );
  } else if (props.modalType === ModalType.CHANNEL_QUICK_MODAL) {
    modalResponse = <ChannelQuickModal {...commonPropsWithDefaultData} />;
  } else if (props.modalType === ModalType.FOX_CHANNEL_QUICK_MODAL) {
    modalResponse = <FoxChannelQuickModal {...commonPropsWithDefaultData} />;
  } else if (props.modalType === ModalType.INTEGRATION_HISTORY_MODAL) {
    modalResponse = (
      <IntegrationHistoryModal
        {...commonPropsWithDefaultData}
        resetParentPage={props.resetParentPage}
        setIsLoading={props.setIsLoading}
      />
    );
  } else if (props.modalType === ModalType.CONTRACT_MODAL) {
    modalResponse = (
      <ContractModal
        {...commonPropsWithReservationId}
        contractAccepted={props.contractAccepted}
        resetParentPage={props.resetParentPage}
      />
    );
  } else if (props.modalType === ModalType.RESERVATION_SUMMARY_INVOICE_MODAL) {
    modalResponse = (
      <ReservationSummaryInvoiceModal {...commonPropsWithReservationId} />
    );
  } else if (props.modalType === ModalType.RESERVATION_INFO_MODAL) {
    modalResponse = <ReservationInfoModal {...commonPropsWithReservationId} />;
  } else if (props.modalType === ModalType.MAIL_MODAL) {
    modalResponse = <MailModal {...commonPropsWithDefaultData} />;
  } else if (props.modalType === ModalType.ACCEPT_DECLINE_RESERVATION_MODAL) {
    modalResponse = (
      <AcceptDeclineReservationModal
        {...commonPropsWithDefaultData}
        resetParentPage={props.resetParentPage}
      />
    );
  } else if (
    props.modalType === ModalType.UPDATE_RESERVATION_CREATION_TYPE_MODAL
  ) {
    modalResponse = (
      <UpdateReservationCreationTypeModal
        {...commonPropsWithDefaultData}
        resetParentPage={props.resetParentPage}
      />
    );
  } else if (props.modalType === ModalType.RESERVATION_ACTION_SUMMARY_MODAL) {
    modalResponse = (
      <ReservationActionSummaryModal {...commonPropsWithDefaultData} />
    );
  } else if (props.modalType === ModalType.TRACKER_QUICK_MODAL) {
    modalResponse = (
      <TrackerDetailsQuickView
        {...commonProps}
        trackerDetails={props.trackerDetails}
      />
    );
  } else if (props.modalType === ModalType.EDIT_RESERVATION_NOT_ALLOWED_MODAL) {
    modalResponse = (
      <EditReservationNotAllowedModal
        {...commonProps}
        locationData={props.locationData}
      />
    );
  } else if (props.modalType === ModalType.APPLICATION_BUILD_INFO_MODAL) {
    modalResponse = <AboutModal {...commonProps} />;
  } else if (props.modalType === ModalType.VOUCHER_INFORMATION_MODAL) {
    modalResponse = <VoucherInfoModal {...commonPropsWithReservationId} />;
  } else if (props.modalType === ModalType.COUNT_DOWN_TIMER_MODAL) {
    modalResponse = (
      <CountDownModal {...commonProps} submitHandler={props.submitHandler} />
    );
  } else if (props.modalType === ModalType.FREE_CREDIT_GRANT_REVERSE_MODAL) {
    modalResponse = (
      <FreeCreditGrantReverseModal
        {...commonPropsWithMemberId}
        reservationId={props.reservationId}
        creditHistoryViewOnly={props.creditHistoryViewOnly}
        title={props.title}
        callBackToDisplayServerMessage={(data) =>
          this.callBackToDisplayServerMessage(data)
        }
        popupFor={props.popupFor}
        resetParentPage={props.resetParentPage}
      />
    );
  } else if (props.modalType === ModalType.APPLY_OFFER_MODAL) {
    modalResponse = (
      <ApplyOfferModal {...commonProps} submitHandler={props.submitHandler} />
    );
  } else if (props.modalType === ModalType.ADD_NEW_LOCATION_MODAL) {
    modalResponse = (
      <AddNewLocationModal
        {...commonProps}
        resetLoader={props.resetLoader}
        renderLabelInputPair={props.renderLabelInputPair}
        newLocaionData={props.newLocaionData}
        inputChangedHandler={props.inputChangedHandler}
        inputAddressChangedHandler={props.inputAddressChangedHandler}
        setIsNewLocation={props.setIsNewLocation}
        timezones={props.timezones}
      />
    );
  } else if (props.modalType === ModalType.RESEND_PAYMENT_LINK_MODAL) {
    modalResponse = (
      <FoxResendPaymentLinkModal
        {...commonProps}
        reservationCode={props.reservationCode}
        customerEmail={props.customerEmail}
        resetParentPage={props.resetParentPage}
      />
    );
  } else if (props.modalType === ModalType.RESERVATION_DETAILS_UPDATE) {
    modalResponse = (
      <ReservationDetailsUpdateModal
        {...commonPropsWithDefaultData}
        resetParentPage={props.resetParentPage}
        valueToUpdate={props.valueToUpdate}
      />
    );
  } else if (props.modalType === ModalType.CUSTOMER_RESERVATION_HISTORY_MODAL) {
    modalResponse = (
      <FoxCustomerReservationHistory
        {...commonPropsWithMemberId}
        setIsLoading={props.setIsLoading}
      />
    );
  } else if (props.modalType === ModalType.MEMBER_DISCOUNT_MODAL) {
    modalResponse = (
      <FoxCustomerReservationHistory
        {...commonPropsWithMemberId}
        setIsLoading={props.setIsLoading}
      />
    );
  }
  return modalResponse;
};
export default ModalEngine;

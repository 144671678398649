import React from 'react';
import Badge from '../../../components/badges/badge';
import { DiscountStatus } from '../../../shared/utility/enum-utility';

function DiscountBadge({ status }) {
    const getBadgeBg = () => {
        if (status === DiscountStatus.GRANTED) {
            return "bg-granted"
        } else if (status === DiscountStatus.USED) {
            return "bg-used"
        } else if (status === DiscountStatus.REVERTED) {
            return "bg-reverted"
        }
    }
    return (
        <Badge classNames={getBadgeBg(status)} label={status} />
    )
}

export default DiscountBadge
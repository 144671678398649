export const AUTH_START = "AUTH_START";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAIL = "AUTH_FAIL";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const AUTH_RESET = "AUTH_RESET";
export const GET_LOGGEDIN_DATA = "GET_LOGGEDIN_DATA";
export const LOGGEDIN_DATA_UPDATE = "LOGGEDIN_DATA_UPDATE";

export const UPDATE_LOGGED_IN_USER_INFO_START =
  "UPDATE_LOGGED_IN_USER_INFO_START";
export const UPDATE_LOGGED_IN_USER_INFO_SUCCESS =
  "UPDATE_LOGGED_IN_USER_INFO_SUCCESS";
export const UPDATE_LOGGED_IN_USER_INFO_FAIL =
  "UPDATE_LOGGED_IN_USER_INFO_FAIL";
export const UPDATE_LOGGED_IN_USER_INFO_RESET =
  "UPDATE_LOGGED_IN_USER_INFO_RESET";

export const GET_USER_NAME_START = "GET_USER_NAME_START";
export const GET_USER_NAME_SUCCESS = "GET_USER_NAME_SUCCESS";
export const GET_USER_NAME_FAIL = "GET_USER_NAME_FAIL";
export const GET_USER_NAME_RESET = "GET_USER_NAME_RESET";

export const SEARCH_MEMBER_START = "SEARCH_MEMBER_START";
export const SEARCH_MEMBER_SUCCESS = "SEARCH_MEMBER_SUCCESS";
export const SEARCH_MEMBER_FAIL = "SEARCH_MEMBER_FAIL";
export const SEARCH_MEMBER_RESET = "SEARCH_MEMBER_RESET";

export const GET_MEMBER_START = "GET_MEMBER_START";
export const GET_MEMBER_SUCCESS = "GET_MEMBER_SUCCESS";
export const GET_MEMBER_FAIL = "GET_MEMBER_FAIL";
export const GET_MEMBER_RESET = "GET_MEMBER_RESET";

export const CREATE_MEMBER_START = "CREATE_MEMBER_START";
export const CREATE_MEMBER_SUCCESS = "CREATE_MEMBER_SUCCESS";
export const CREATE_MEMBER_FAIL = "CREATE_MEMBER_FAIL";
export const CREATE_MEMBER_RESET = "CREATE_MEMBER_RESET";
export const EDIT_MEMBER_START = "EDIT_MEMBER_START";
export const EDIT_MEMBER_SUCCESS = "EDIT_MEMBER_SUCCESS";
export const EDIT_MEMBER_FAIL = "EDIT_MEMBER_FAIL";
export const EDIT_MEMBER_RESET = "EDIT_MEMBER_RESET";

export const GET_ORG_DETAIL_START = "GET_ORG_DETAIL_START";
export const GET_ORG_DETAIL_SUCCESS = "GET_ORG_DETAIL_SUCCESS";
export const GET_ORG_DETAIL_FAIL = "GET_ORG_DETAIL_FAIL";
export const GET_ORG_DETAIL_RESET = "GET_ORG_DETAIL_RESET";

export const GET_CONFLICTED_MEMBER_START = "GET_CONFLICTED_MEMBER_START";
export const GET_CONFLICTED_MEMBER_SUCCESS = "GET_CONFLICTED_MEMBER_SUCCESS";
export const GET_CONFLICTED_MEMBER_FAIL = "GET_CONFLICTED_MEMBER_FAIL";
export const GET_CONFLICTED_MEMBER_RESET = "GET_CONFLICTED_MEMBER_RESET";

export const GET_MEMBER_PERMISSIONS_START = "GET_MEMBER_PERMISSIONS_START";
export const GET_MEMBER_PERMISSIONS_SUCCESS = "GET_MEMBER_PERMISSIONS_SUCCESS";
export const GET_MEMBER_PERMISSIONS_FAIL = "GET_MEMBER_PERMISSIONS_FAIL";

export const GET_MEMBER_APPACCESS_PERMISSIONS_START =
  "GET_MEMBER_APPACCESS_PERMISSIONS_START";
export const GET_MEMBER_APPACCESS_PERMISSIONS_SUCCESS =
  "GET_MEMBER_APPACCESS_PERMISSIONS_SUCCESS";
export const GET_MEMBER_APPACCESS_PERMISSIONS_FAIL =
  "GET_MEMBER_APPACCESS_PERMISSIONS_FAIL";
export const PERMISSIONS_RESET = "PERMISSIONS_RESET";

export const ASSIGN_PERMISSIONS_TO_MEMBER_START =
  "ASSIGN_PERMISSIONS_TO_MEMBER_START";
export const ASSIGN_PERMISSIONS_TO_MEMBER_SUCCESS =
  "ASSIGN_PERMISSIONS_TO_MEMBER_SUCCESS";
export const ASSIGN_PERMISSIONS_TO_MEMBER_FAIL =
  "ASSIGN_PERMISSIONS_TO_MEMBER_FAIL";
export const ASSIGN_PERMISSIONS_TO_MEMBER_RESET =
  "ASSIGN_PERMISSIONS_TO_MEMBER_RESET";

export const ASSIGN_ROLES_TO_MEMBER_START = "ASSIGN_ROLES_TO_MEMBER_START";
export const ASSIGN_ROLES_TO_MEMBER_SUCCESS = "ASSIGN_ROLES_TO_MEMBER_SUCCESS";
export const ASSIGN_ROLES_TO_MEMBER_FAIL = "ASSIGN_ROLES_TO_MEMBER_FAIL";
export const ASSIGN_ROLES_TO_MEMBER_RESET = "ASSIGN_ROLES_TO_MEMBER_RESET";

export const GET_MEMBER_ROLES_START = "GET_MEMBER_ROLES_START";
export const GET_MEMBER_ROlES_SUCCESS = "GET_MEMBER_ROlES_SUCCESS";
export const GET_MEMBER_ROLES_FAIL = "GET_MEMBER_ROLES_FAIL";

export const GET_PERMISSIONS_ROLES_START = "GET_PERMISSIONS_ROLES_START";
export const GET_PERMISSIONS_ROLES_SUCCESS = "GET_PERMISSIONS_ROLES_SUCCESS";
export const GET_PERMISSIONS_ROLES_FAIL = "GET_PERMISSIONS_ROLES_FAIL";
export const GET_PERMISSIONS_ROLES_RESET = "GET_PERMISSIONS_ROLES_RESET";

/**Address */
export const GET_ADDRESS_LOADER = "GET_ADDRESS_LOADER";
/**Primary Address*/
export const PRIMARY_COUNTRIES_START = "PRIMARY_COUNTRIES_START";
export const PRIMARY_COUNTRIES_SUCCESS = "PRIMARY_COUNTRIES_SUCCESS";
export const PRIMARY_COUNTRIES_FAIL = "PRIMARY_COUNTRIES_FAIL";
export const PRIMARY_COUNTRIES_RESET = "PRIMARY_COUNTRIES_RESET";

export const PRIMARY_STATES_START = "PRIMARY_STATES_START";
export const PRIMARY_STATES_SUCCESS = "PRIMARY_STATES_SUCCESS";
export const PRIMARY_STATES_FAIL = "PRIMARY_STATES_FAIL";
export const PRIMARY_STATES_RESET = "PRIMARY_STATES_RESET";

export const PRIMARY_CITIES_START = "PRIMARY_CITIES_START";
export const PRIMARY_CITIES_SUCCESS = "PRIMARY_CITIES_SUCCESS";
export const PRIMARY_CITIES_FAIL = "PRIMARY_CITIES_FAIL";
export const PRIMARY_CITIES_RESET = "PRIMARY_CITIES_RESET";

export const PRIMARY_POSTALS_START = "PRIMARY_POSTALS_START";
export const PRIMARY_POSTALS_SUCCESS = "PRIMARY_POSTALS_SUCCESS";
export const PRIMARY_POSTALS_FAIL = "PRIMARY_POSTALS_FAIL";
export const PRIMARY_POSTALS_RESET = "PRIMARY_POSTALS_RESET";

/**License Address*/
export const LICENSE_COUNTRIES_START = "LICENSE_COUNTRIES_START";
export const LICENSE_COUNTRIES_SUCCESS = "LICENSE_COUNTRIES_SUCCESS";
export const LICENSE_COUNTRIES_FAIL = "LICENSE_COUNTRIES_FAIL";
export const LICENSE_COUNTRIES_RESET = "LICENSE_COUNTRIES_RESET";

export const LICENSE_STATES_START = "LICENSE_STATES_START";
export const LICENSE_STATES_SUCCESS = "LICENSE_STATES_SUCCESS";
export const LICENSE_STATES_FAIL = "LICENSE_STATES_FAIL";
export const LICENSE_STATES_RESET = "LICENSE_STATES_RESET";

/**Credit Address */
export const CREDIT_COUNTRIES_START = "CREDIT_COUNTRIES_START";
export const CREDIT_COUNTRIES_SUCCESS = "CREDIT_COUNTRIES_SUCCESS";
export const CREDIT_COUNTRIES_FAIL = "CREDIT_COUNTRIES_FAIL";
export const CREDIT_COUNTRIES_RESET = "CREDIT_COUNTRIES_RESET";

export const CREDIT_STATES_START = "CREDIT_STATES_START";
export const CREDIT_STATES_SUCCESS = "CREDIT_STATES_SUCCESS";
export const CREDIT_STATES_FAIL = "CREDIT_STATES_FAIL";
export const CREDIT_STATES_RESET = "CREDIT_STATES_RESET";

export const CREDIT_CITIES_START = "CREDIT_CITIES_START";
export const CREDIT_CITIES_SUCCESS = "CREDIT_CITIES_SUCCESS";
export const CREDIT_CITIES_FAIL = "CREDIT_CITIES_FAIL";
export const CREDIT_CITIES_RESET = "CREDIT_CITIES_RESET";

export const CREDIT_POSTALS_START = "CREDIT_POSTALS_START";
export const CREDIT_POSTALS_SUCCESS = "CREDIT_POSTALS_SUCCESS";
export const CREDIT_POSTALS_FAIL = "CREDIT_POSTALS_FAIL";
export const CREDIT_POSTALS_RESET = "CREDIT_POSTALS_RESET";

export const GET_LOCATIONBASEDSETTINGS_START =
  "GET_LOCATIONBASEDSETTINGS_START";
export const GET_LOCATIONBASEDSETTINGS_SUCCESS =
  "GET_LOCATIONBASEDSETTINGS_SUCCESS";
export const GET_LOCATIONBASEDSETTINGS_FAIL = "GET_LOCATIONBASEDSETTINGS_FAIL";
export const GET_LOCATIONBASEDSETTINGS_RESET =
  "GET_LOCATIONBASEDSETTINGS_RESET";

export const UPDATE_NOTES_START = "UPDATE_NOTES_START";
export const UPDATE_NOTES_SUCCESS = "UPDATE_NOTES_SUCCESS";
export const UPDATE_NOTES_FAIL = "UPDATE_NOTES_FAIL";
export const UPDATE_NOTES_RESET = "UPDATE_NOTES_RESET";

export const INTEGRATION_HISTORY_START = "INTEGRATION_HISTORY_START";
export const INTEGRATION_HISTORY_SUCCESS = "INTEGRATION_HISTORY_SUCCESS";
export const INTEGRATION_HISTORY_FAIL = "INTEGRATION_HISTORY_FAIL";
export const INTEGRATION_HISTORY_RESET = "INTEGRATION_HISTORY_RESET";

export const UPDATE_INTEG_STATUS_START = "UPDATE_INTEG_STATUS_START";
export const UPDATE_INTEG_STATUS_SUCCESS = "UPDATE_INTEG_STATUS_SUCCESS";
export const UPDATE_INTEG_STATUS_FAIL = "UPDATE_INTEG_STATUS_FAIL";
export const UPDATE_INTEG_STATUS_RESET = "UPDATE_INTEG_STATUS_RESET";

export const ACCEPT_TERMS_CONDITIONS_START = "ACCEPT_TERMS_CONDITIONS_START";
export const ACCEPT_TERMS_CONDITIONS_SUCCESS =
  "ACCEPT_TERMS_CONDITIONS_SUCCESS";
export const ACCEPT_TERMS_CONDITIONS_FAIL = "ACCEPT_TERMS_CONDITIONS_FAIL";
export const ACCEPT_TERMS_CONDITIONS_RESET = "ACCEPT_TERMS_CONDITIONS_RESET";

export const UPDATE_RESERVATION_CREATION_TYPE_START =
  "UPDATE_RESERVATION_CREATION_TYPE_START";
export const UPDATE_RESERVATION_CREATION_TYPE_SUCCESS =
  "UPDATE_RESERVATION_CREATION_TYPE_SUCCESS";
export const UPDATE_RESERVATION_CREATION_TYPE_FAIL =
  "UPDATE_RESERVATION_CREATION_TYPE_FAIL";
export const UPDATE_RESERVATION_CREATION_TYPE_RESET =
  "UPDATE_RESERVATION_CREATION_TYPE_RESET";

export const GET_CONTRACT_START = "GET_CONTRACT_START";
export const GET_CONTRACT_SUCCESS = "GET_CONTRACT_SUCCESS";
export const GET_CONTRACT_FAIL = "GET_CONTRACT_FAIL";
export const GET_CONTRACT_RESET = "GET_CONTRACT_RESET";

export const GET_INVOICE_START = "GET_INVOICE_START";
export const GET_INVOICE_SUCCESS = "GET_INVOICE_SUCCESS";
export const GET_INVOICE_FAIL = "GET_INVOICE_FAIL";
export const GET_INVOICE_RESET = "GET_INVOICE_RESET";

export const GET_RESERVATION_SUMMARY_START = "GET_RESERVATION_SUMMARY_START";
export const GET_RESERVATION_SUMMARY_SUCCESS =
  "GET_RESERVATION_SUMMARY_SUCCESS";
export const GET_RESERVATION_SUMMARY_FAIL = "GET_RESERVATION_SUMMARY_FAIL";
export const GET_RESERVATION_SUMMARY_RESET = "GET_RESERVATION_SUMMARY_RESET";

export const GET_RESERVATION_VOUCHER_START = "GET_RESERVATION_VOUCHER_START";
export const GET_RESERVATION_VOUCHER_SUCCESS =
  "GET_RESERVATION_VOUCHER_SUCCESS";
export const GET_RESERVATION_VOUCHER_FAIL = "GET_RESERVATION_VOUCHER_FAIL";
export const GET_RESERVATION_VOUCHER_RESET = "GET_RESERVATION_VOUCHER_RESET";

export const SECURITY_DEPOSIT_POLICY_START = "SECURITY_DEPOSIT_POLICY_START";
export const SECURITY_DEPOSIT_POLICY_SUCCESS =
  "SECURITY_DEPOSIT_POLICY_SUCCESS";
export const SECURITY_DEPOSIT_POLICY_FAIL = "SECURITY_DEPOSIT_POLICY_FAIL";
export const SECURITY_DEPOSIT_POLICY_RESET = "SECURITY_DEPOSIT_POLICY_RESET";

export const SEND_MAIL_START = "SEND_MAIL_START";
export const SEND_MAIL_SUCCESS = "SEND_MAIL_SUCCESS";
export const SEND_MAIL_FAIL = "SEND_MAIL_FAIL";
export const SEND_MAIL_RESET = "SEND_MAIL_RESET";

export const UPDATE_CANCELLATION_REASON_START =
  "UPDATE_CANCELLATION_REASON_START";
export const UPDATE_CANCELLATION_REASON_SUCCESS =
  "UPDATE_CANCELLATION_REASON_SUCCESS";
export const UPDATE_CANCELLATION_REASON_FAIL =
  "UPDATE_CANCELLATION_REASON_FAIL";
export const UPDATE_CANCELLATION_REASON_RESET =
  "UPDATE_CANCELLATION_REASON_RESET";

export const SEARCH_RESERVATION_START = "SEARCH_RESERVATION_START";
export const SEARCH_RESERVATION_SUCCESS = "SEARCH_RESERVATION_SUCCESS";
export const SEARCH_RESERVATION_FAIL = "SEARCH_RESERVATION_FAIL";
export const SEARCH_RESERVATION_RESET = "SEARCH_RESERVATION_RESET";

export const SEARCH_RENTAL_START = "SEARCH_RENTAL_START";
export const SEARCH_RENTAL_SUCCESS = "SEARCH_RENTAL_SUCCESS";
export const SEARCH_RENTAL_FAIL = "SEARCH_RENTAL_FAIL";
export const SEARCH_RENTAL_RESET = "SEARCH_RENTAL_RESET";

export const SEARCH_MEMBER_RESERVATION_START =
  "SEARCH_MEMBER_RESERVATION_START";
export const SEARCH_MEMBER_RESERVATION_SUCCESS =
  "SEARCH_MEMBER_RESERVATION_SUCCESS";
export const SEARCH_MEMBER_RESERVATION_FAIL = "SEARCH_MEMBER_RESERVATION_FAIL";
export const SEARCH_MEMBER_RESERVATION_RESET =
  "SEARCH_MEMBER_RESERVATION_RESET";

export const SEARCH_MEMBER_VEHICLE_RESERVATION_START =
  "SEARCH_MEMBER_VEHICLE_RESERVATION_START";
export const SEARCH_MEMBER_VEHICLE_RESERVATION_SUCCESS =
  "SEARCH_MEMBER_VEHICLE_RESERVATION_SUCCESS";
export const SEARCH_MEMBER_VEHICLE_RESERVATION_FAIL =
  "SEARCH_MEMBER_VEHICLE_RESERVATION_FAIL";
export const SEARCH_MEMBER_VEHICLE_RESERVATION_RESET =
  "SEARCH_MEMBER_VEHICLE_RESERVATION_RESET";

export const GET_RESERVATION_OVERVIEW_START = "GET_RESERVATION_OVERVIEW_START";
export const GET_RESERVATION_OVERVIEW_SUCCESS =
  "GET_RESERVATION_OVERVIEW_SUCCESS";
export const GET_RESERVATION_OVERVIEW_FAIL = "GET_RESERVATION_OVERVIEW_FAIL";
export const GET_RESERVATION_OVERVIEW_RESET = "GET_RESERVATION_OVERVIEW_RESET";

export const CANCEL_RESERVATION_START = "CANCEL_RESERVATION_START";
export const CANCEL_RESERVATION_SUCCESS = "CANCEL_RESERVATION_SUCCESS";
export const CANCEL_RESERVATION_FAIL = "CANCEL_RESERVATION_FAIL";
export const CANCEL_RESERVATION_RESET = "CANCEL_RESERVATION_RESET";

export const GET_RESERVATION_CHARGES_START = "GET_RESERVATION_CHARGES_START";
export const GET_RESERVATION_CHARGES_SUCCESS =
  "GET_RESERVATION_CHARGES_SUCCESS";
export const GET_RESERVATION_CHARGES_FAIL = "GET_RESERVATION_CHARGES_FAIL";
export const GET_RESERVATION_CHARGES_RESET = "GET_RESERVATION_CHARGES_RESET";
export const MANAGE_RESERVATION_CHARGES_START =
  "MANAGE_RESERVATION_CHARGES_START";
export const MANAGE_RESERVATION_CHARGES_SUCCESS =
  "MANAGE_RESERVATION_CHARGES_SUCCESS";
export const MANAGE_RESERVATION_CHARGES_FAIL =
  "MANAGE_RESERVATION_CHARGES_FAIL";
export const MANAGE_RESERVATION_CHARGES_RESET =
  "MANAGE_RESERVATION_CHARGES_RESET";

export const ACCEPT_DECLINE_RESERVATION_START =
  "ACCEPT_DECLINE_RESERVATION_START";
export const ACCEPT_DECLINE_RESERVATION_SUCCESS =
  "ACCEPT_DECLINE_RESERVATION_SUCCESS";
export const ACCEPT_DECLINE_RESERVATION_FAIL =
  "ACCEPT_DECLINE_RESERVATION_FAIL";
export const ACCEPT_DECLINE_RESERVATION_RESET =
  "ACCEPT_DECLINE_RESERVATION_RESET";

export const EDIT_CONFIRMATION_CODE_START = "EDIT_CONFIRMATION_CODE_START";
export const EDIT_CONFIRMATION_CODE_SUCCESS = "EDIT_CONFIRMATION_CODE_SUCCESS";
export const EDIT_CONFIRMATION_CODE_FAIL = "EDIT_CONFIRMATION_CODE_FAIL";
export const EDIT_CONFIRMATION_CODE_RESET = "EDIT_CONFIRMATION_CODE_RESET";

export const GET_ENUM_VALUE_START = "GET_ENUM_VALUE_START";
export const GET_ENUM_VALUE_SUCCESS = "GET_ENUM_VALUE_SUCCESS";
export const GET_ENUM_VALUE_FAIL = "GET_ENUM_VALUE_FAIL";

export const GET_END_RENTAL_CHARGES_START = "GET_END_RENTAL_CHARGES_START";
export const GET_END_RENTAL_CHARGES_SUCCESS = "GET_END_RENTAL_CHARGES_SUCCESS";
export const GET_END_RENTAL_CHARGES_FAIL = "GET_END_RENTAL_CHARGES_FAIL";
export const GET_END_RENTAL_CHARGES_RESET = "GET_END_RENTAL_CHARGES_RESET";
export const END_RENTAL_START = "END_RENTAL_START";
export const END_RENTAL_SUCCESS = "END_RENTAL_SUCCESS";
export const END_RENTAL_FAIL = "END_RENTAL_FAIL";
export const END_RENTAL_RESET = "END_RENTAL_RESET";
export const END_RENTAL_UPDATE_START = "END_RENTAL_UPDATE_START";
export const END_RENTAL_UPDATE_SUCCESS = "END_RENTAL_UPDATE_SUCCESS";
export const END_RENTAL_UPDATE_FAIL = "END_RENTAL_UPDATE_FAIL";
export const END_RENTAL_UPDATE_RESET = "END_RENTAL_UPDATE_RESET";

export const GET_INVOICE_INFORMATION_START = "GET_INVOICE_INFORMATION_START";
export const GET_INVOICE_INFORMATION_SUCCESS =
  "GET_INVOICE_INFORMATION_SUCCESS";
export const GET_INVOICE_INFORMATION_FAIL = "GET_INVOICE_INFORMATION_FAIL";
export const GET_INVOICE_INFORMATION_RESET = "GET_INVOICE_INFORMATION_RESET";

export const GET_TRANSACTIONLIST_START = "GET_TRANSACTION_START";
export const GET_TRANSACTIONLIST_SUCCESS = "GET_TRANSACTION_SUCCESS";
export const GET_TRANSACTIONLIST_FAIL = "GET_TRANSACTION_FAIL";
export const GET_TRANSACTIONLIST_RESET = "GET_TRANSACTION_RESET";
export const DO_CAPTURE_REFUND_RELEASE_START =
  "DO_CAPTURE_REFUND_RELEASE_START";
export const DO_CAPTURE_REFUND_RELEASE_SUCCESS =
  "DO_CAPTURE_REFUND_RELEASE_SUCCESS";
export const DO_CAPTURE_REFUND_RELEASE_FAIL = "DO_CAPTURE_REFUND_RELEASE_FAIL";
export const DO_CAPTURE_REFUND_RELEASE_RESET =
  "DO_CAPTURE_REFUND_RELEASE_RESET";

export const FETCH_CREDITCARDNUMBER_START = "FETCH_CREDITCARDNUMBER_START";
export const FETCH_CREDITCARDNUMBER_SUCCESS = "FETCH_CREDITCARDNUMBER_SUCCESS";
export const FETCH_CREDITCARDNUMBER_FAIL = "FETCH_CREDITCARDNUMBER_FAIL";
export const FETCH_CREDITCARDNUMBER_RESET = "FETCH_CREDITCARDNUMBER_RESET";

export const GET_ADDONS_START = "GET_ADDONS_START";
export const GET_ADDONS_SUCCESS = "GET_ADDONS_SUCCESS";
export const GET_ADDONS_FAIL = "GET_ADDONS_FAIL";

export const SET_AUTH_REDIRECT_PATH = "SET_AUTH_REDIRECT_PATH";

export const GET_DAMAGE_START = "GET_DAMAGE_START";
export const GET_DAMAGE_SUCCESS = "GET_DAMAGE_SUCCESS";
export const GET_DAMAGE_FAIL = "GET_DAMAGE_FAIL";
export const GET_DAMAGE_RESET = "GET_DAMAGE_RESET";
export const ADD_DAMAGE_START = "ADD_DAMAGE_START";
export const ADD_DAMAGE_SUCCESS = "ADD_DAMAGE_SUCCESS";
export const ADD_DAMAGE_FAIL = "ADD_DAMAGE_FAIL";
export const ADD_DAMAGE_RESET = "ADD_DAMAGE_RESET";

export const GET_AGENT_START = "GET_AGENT_START";
export const GET_AGENT_SUCCESS = "GET_AGENT_SUCCESS";
export const GET_AGENT_FAIL = "GET_AGENT_FAIL";
export const GET_AGENT_RESET = "GET_AGENT_RESET";

export const RUN_DMV_CHECK_START = "RUN_DMV_CHECK_START";
export const RUN_DMV_CHECK_SUCCESS = "RUN_DMV_CHECK_SUCCESS";
export const RUN_DMV_CHECK_FAIL = "RUN_DMV_CHECK_FAIL";
export const RUN_DMV_CHECK_RESET = "RUN_DMV_CHECK_RESET";

export const LICENSE_APPROVAL_START = "LICENSE_APPROVAL_START";
export const LICENSE_APPROVAL_SUCCESS = "LICENSE_APPROVAL_SUCCESS";
export const LICENSE_APPROVAL_FAIL = "LICENSE_APPROVAL_FAIL";
export const LICENSE_APPROVAL_RESET = "LICENSE_APPROVAL_RESET";

export const ADD_ADDITIONAL_LICENSE_START = "ADD_ADDITIONAL_LICENSE_START";
export const ADD_ADDITIONAL_LICENSE_SUCCESS = "ADD_ADDITIONAL_LICENSE_SUCCESS";
export const ADD_ADDITIONAL_LICENSE_FAIL = "ADD_ADDITIONAL_LICENSE_FAIL";
export const ADD_ADDITIONAL_LICENSE_RESET = "ADD_ADDITIONAL_LICENSE_RESET";

export const GET_ATTACHED_DOC_START = "GET_ATTACHED_DOC_START";
export const GET_ATTACHED_DOC_SUCCESS = "GET_ATTACHED_DOC_SUCCESS";
export const GET_ATTACHED_DOC_FAIL = "GET_ATTACHED_DOC_FAIL";
export const GET_ATTACHED_DOC_RESET = "GET_ATTACHED_DOC_RESET";

export const ADD_ATTACHED_DOC_START = "ADD_ATTACHED_DOC_START";
export const ADD_ATTACHED_DOC_SUCCESS = "ADD_ATTACHED_DOC_SUCCESS";
export const ADD_ATTACHED_DOC_FAIL = "ADD_ATTACHED_DOC_FAIL";
export const ADD_ATTACHED_DOC_RESET = "ADD_ATTACHED_DOC_RESET";

export const UPLOAD_DOC_START = "UPLOAD_DOC_START";
export const UPLOAD_DOC_SUCCESS = "UPLOAD_DOC_SUCCESS";
export const UPLOAD_DOC_FAIL = "UPLOAD_DOC_FAIL";
export const UPLOAD_DOC_RESET = "UPLOAD_DOC_RESET";

export const GET_ADDRESS_START = "GET_ADDRESS_START";
export const GET_ADDRESS_SUCCESS = "GET_ADDRESS_SUCCESS";
export const GET_ADDRESS_FAIL = "GET_ADDRESS_FAIL";
export const GET_ADDRESS_RESET = "GET_ADDRESS_RESET";

export const ADD_ADDRESS_START = "ADD_ADDRESS_START";
export const ADD_ADDRESS_SUCCESS = "ADD_ADDRESS_SUCCESS";
export const ADD_ADDRESS_FAIL = "ADD_ADDRESS_FAIL";
export const ADD_ADDRESS_RESET = "ADD_ADDRESS_RESET";

export const EDIT_ADDRESS_START = "EDIT_ADDRESS_START";
export const EDIT_ADDRESS_SUCCESS = "EDIT_ADDRESS_SUCCESS";
export const EDIT_ADDRESS_FAIL = "EDIT_ADDRESS_FAIL";
export const EDIT_ADDRESS_RESET = "EDIT_ADDRESS_RESET";

export const DELETE_ADDRESS_START = "DELETE_ADDRESS_START";
export const DELETE_ADDRESS_SUCCESS = "DELETE_ADDRESS_SUCCESS";
export const DELETE_ADDRESS_FAIL = "DELETE_ADDRESS_FAIL";
export const DELETE_ADDRESS_RESET = "DELETE_ADDRESS_RESET";

export const BLOCK_UNBLOCK_PROFILE_START = "BLOCK_UNBLOCK_PROFILE_START";
export const BLOCK_UNBLOCK_PROFILE_SUCCESS = "BLOCK_UNBLOCK_PROFILE_SUCCESS";
export const BLOCK_UNBLOCK_PROFILE_FAIL = "BLOCK_UNBLOCK_PROFILE_FAIL";
export const BLOCK_UNBLOCK_PROFILE_RESET = "BLOCK_UNBLOCK_PROFILE_RESET";

export const ACTIVATE_MEMBER_START = "ACTIVATE_MEMBER_START";
export const ACTIVATE_MEMBER_SUCCESS = "ACTIVATE_MEMBER_SUCCESS";
export const ACTIVATE_MEMBER_FAIL = "ACTIVATE_MEMBER_FAIL";
export const ACTIVATE_MEMBER_RESET = "ACTIVATE_MEMBER_RESET";

export const CANCEL_MEMBER_START = "CANCEL_MEMBER_START";
export const CANCEL_MEMBER_SUCCESS = "CANCEL_MEMBER_SUCCESS";
export const CANCEL_MEMBER_FAIL = "CANCEL_MEMBER_FAIL";
export const CANCEL_MEMBER_RESET = "CANCEL_MEMBER_RESET";

export const CHANGE_TEMPORARY_PASSWORD_START =
  "CHANGE_TEMPORARY_PASSWORD_START";
export const CHANGE_TEMPORARY_PASSWORD_SUCCESS =
  "CHANGE_TEMPORARY_PASSWORD_SUCCESS";
export const CHANGE_TEMPORARY_PASSWORD_FAIL = "CHANGE_TEMPORARY_PASSWORD_FAIL";
export const CHANGE_TEMPORARY_PASSWORD_RESET =
  "CHANGE_TEMPORARY_PASSWORD_RESET";

export const RESET_PASSWORD_START = "RESET_PASSWORD_START";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAIL = "CHANGE_TEMPORARY_PASSWORD_FAIL";
export const RESET_PASSWORD_RESET = "RESET_PASSWORD_RESET";

export const VERIFY_MEMBER_ACCOUNT_START = "VERIFY_MEMBER_ACCOUNT_START";
export const VERIFY_MEMBER_ACCOUNT_SUCCESS = "VERIFY_MEMBER_ACCOUNT_SUCCESS";
export const VERIFY_MEMBER_ACCOUNT_FAIL = "VERIFY_MEMBER_ACCOUNT_FAIL";
export const VERIFY_MEMBER_ACCOUNT_RESET = "VERIFY_MEMBER_ACCOUNT_RESET";

export const IMPERSONATE_LOGIN_START = "IMPERSONATE_LOGIN_START";
export const IMPERSONATE_LOGIN_SUCCESS = "IMPERSONATE_LOGIN_SUCCESS";
export const IMPERSONATE_LOGIN_FAIL = "IMPERSONATE_LOGIN_FAIL";
export const IMPERSONATE_LOGIN_RESET = "IMPERSONATE_LOGIN_RESET";

export const EXIT_IMPERSONATE_START = "EXIT_IMPERSONATE_START";
export const EXIT_IMPERSONATE_SUCCESS = "EXIT_IMPERSONATE_SUCCESS";
export const EXIT_IMPERSONATE_FAIL = "EXIT_IMPERSONATE_FAIL";
export const EXIT_IMPERSONATE_RESET = "EXIT_IMPERSONATE_RESET";

export const ADD_VIOLATION_POINT_START = "ADD_VIOLATION_POINT_START";
export const ADD_VIOLATION_POINT_SUCCESS = "ADD_VIOLATION_POINT_SUCCESS";
export const ADD_VIOLATION_POINT_FAIL = "ADD_VIOLATION_POINT_FAIL";
export const ADD_VIOLATION_POINT_RESET = "ADD_VIOLATION_POINT_RESET";

export const ALLOW_TEMP_IMG_UPLOAD_START = "ALLOW_TEMP_IMG_UPLOAD_START";
export const ALLOW_TEMP_IMG_UPLOAD_SUCCESS = "ALLOW_TEMP_IMG_UPLOAD_SUCCESS";
export const ALLOW_TEMP_IMG_UPLOAD_FAIL = "ALLOW_TEMP_IMG_UPLOAD_FAIL";
export const ALLOW_TEMP_IMG_UPLOAD_RESET = "ALLOW_TEMP_IMG_UPLOAD_RESET";

export const GET_CREDIT_HISTORY_START = "GET_CREDIT_HISTORY_START";
export const GET_CREDIT_HISTORY_SUCCESS = "GET_CREDIT_HISTORY_SUCCESS";
export const GET_CREDIT_HISTORY_FAIL = "GET_CREDIT_HISTORY_FAIL";
export const GET_CREDIT_HISTORY_RESET = "GET_CREDIT_HISTORY_RESET";

export const ADD_FREE_CREDIT_START = "ADD_FREE_CREDIT_START";
export const ADD_FREE_CREDIT_SUCCESS = "ADD_FREE_CREDIT_SUCCESS";
export const ADD_FREE_CREDIT_FAIL = "ADD_FREE_CREDIT_FAIL";
export const ADD_FREE_CREDIT_RESET = "ADD_FREE_CREDIT_RESET";

export const REVERSE_CREDIT_START = "REVERSE_CREDIT_START";
export const REVERSE_CREDIT_SUCCESS = "REVERSE_CREDIT_SUCCESS";
export const REVERSE_CREDIT_FAIL = "REVERSE_CREDIT_FAIL";
export const REVERSE_CREDIT_RESET = "REVERSE_CREDIT_RESET";

export const SEARCH_MEMBER_FAVORITE_START = "SEARCH_MEMBER_FAVORITE_START";
export const SEARCH_MEMBER_FAVORITE_SUCCESS = "SEARCH_MEMBER_FAVORITE_SUCCESS";
export const SEARCH_MEMBER_FAVORITE_FAIL = "SEARCH_MEMBER_FAVORITE_FAIL";
export const SEARCH_MEMBER_FAVORITE_RESET = "SEARCH_MEMBER_FAVORITE_RESET";

export const SEARCH_MEMBER_MESSAGE_START = "SEARCH_MEMBER_MESSAGE_START";
export const SEARCH_MEMBER_MESSAGE_SUCCESS = "SEARCH_MEMBER_MESSAGE_SUCCESS";
export const SEARCH_MEMBER_MESSAGE_FAIL = "SEARCH_MEMBER_MESSAGE_FAIL";
export const SEARCH_MEMBER_MESSAGE_RESET = "SEARCH_MEMBER_MESSAGE_RESET";

export const FLEET_UTILIZATION_SUMMARY_START =
  "FLEET_UTILIZATION_SUMMARY_START";
export const FLEET_UTILIZATION_SUMMARY_SUCCESS =
  "FLEET_UTILIZATION_SUMMARY_SUCCESS";
export const FLEET_UTILIZATION_SUMMARY_FAIL = "FLEET_UTILIZATION_SUMMARY_FAIL";
export const FLEET_UTILIZATION_SUMMARY_RESET =
  "FLEET_UTILIZATION_SUMMARY_RESET";

export const GET_AVAILABILITY_SUMMARY_IN_FLEET_START =
  "GET_AVAILABILITY_SUMMARY_IN_FLEET_START";
export const GET_AVAILABILITY_SUMMARY_IN_FLEET_SUCCESS =
  "GET_AVAILABILITY_SUMMARY_IN_FLEET_SUCCESS";
export const GET_AVAILABILITY_SUMMARY_IN_FLEET_FAIL =
  "GET_AVAILABILITY_SUMMARY_IN_FLEET_FAIL";
export const GET_AVAILABILITY_SUMMARY_IN_FLEET_RESET =
  "GET_AVAILABILITY_SUMMARY_IN_FLEET_RESET";

export const GET_ASSET_IN_OUT_SUMMARY_START = "GET_ASSET_IN_OUT_SUMMARY_START";
export const GET_ASSET_IN_OUT_SUMMARY_SUCCESS =
  "GET_ASSET_IN_OUT_SUMMARY_SUCCESS";
export const GET_ASSET_IN_OUT_SUMMARY_FAIL = "GET_ASSET_IN_OUT_SUMMARY_FAIL";
export const GET_ASSET_IN_OUT_SUMMARY_RESET = "GET_ASSET_IN_OUT_SUMMARY_RESET";

export const GET_SERVICE_TASK_START = "GET_SERVICE_TASK_START";
export const GET_SERVICE_TASK_SUCCESS = "GET_SERVICE_TASK_SUCCESS";
export const GET_SERVICE_TASK_FAIL = "GET_SERVICE_TASK_FAIL";
export const GET_SERVICE_TASK_RESET = "GET_SERVICE_TASK_RESET";

export const GET_VEHICLE_CATEGORY_START = "GET_VEHICLE_CATEGORY_START";
export const GET_VEHICLE_CATEGORY_SUCCESS = "GET_VEHICLE_CATEGORY_SUCCESS";
export const GET_VEHICLE_CATEGORY_FAIL = "GET_VEHICLE_CATEGORY_FAIL";
export const GET_VEHICLE_CATEGORY_RESET = "GET_VEHICLE_CATEGORY_RESET";

export const UPDATE_VEHICLE_START = "UPDATE_VEHICLE_START";
export const UPDATE_VEHICLE_SUCCESS = "UPDATE_VEHICLE_SUCCESS";
export const UPDATE_VEHICLE_FAIL = "UPDATE_VEHICLE_FAIL";
export const UPDATE_VEHICLE_RESET = "UPDATE_VEHICLE_RESET";

export const SEARCH_VEHICLE_START = "SEARCH_VEHICLE_START";
export const SEARCH_VEHICLE_SUCCESS = "SEARCH_VEHICLE_SUCCESS";
export const SEARCH_VEHICLE_FAIL = "SEARCH_VEHICLE_FAIL";
export const SEARCH_VEHICLE_RESET = "SEARCH_VEHICLE_RESET";

export const GET_VEHICLE_DETAILS_START = "GET_VEHICLE_DETAILS_START";
export const GET_VEHICLE_DETAILS_SUCCESS = "GET_VEHICLE_DETAILS_SUCCESS";
export const GET_VEHICLE_DETAILS_FAIL = "GET_VEHICLE_DETAILS_FAIL";
export const GET_VEHICLE_DETAILS_RESET = "GET_VEHICLE_DETAILS_RESET";
export const SEARCH_MEMBER_VEHICLE_START = "SEARCH_MEMBER_VEHICLE_START";
export const SEARCH_MEMBER_VEHICLE_SUCCESS = "SEARCH_MEMBER_VEHICLE_SUCCESS";
export const SEARCH_MEMBER_VEHICLE_FAIL = "SEARCH_MEMBER_VEHICLE_FAIL";
export const SEARCH_MEMBER_VEHICLE_RESET = "SEARCH_MEMBER_VEHICLE_RESET";

export const GET_VEHICLE_FEATURES_START = "GET_VEHICLE_FEATURES_START";
export const GET_VEHICLE_FEATURES_SUCCESS = "GET_VEHICLE_FEATURES_SUCCESS";
export const GET_VEHICLE_FEATURES_FAIL = "GET_VEHICLE_FEATURES_FAIL";
export const GET_VEHICLE_FEATURES_RESET = "GET_VEHICLE_FEATURES_RESET";

export const UPDATE_VEHICLE_FEATURES_START = "UPDATE_VEHICLE_FEATURES_START";
export const UPDATE_VEHICLE_FEATURES_SUCCESS =
  "UPDATE_VEHICLE_FEATURES_SUCCESS";
export const UPDATE_VEHICLE_FEATURES_FAIL = "UPDATE_VEHICLE_FEATURES_FAIL";
export const UPDATE_VEHICLE_FEATURES_RESET = "UPDATE_VEHICLE_FEATURES_RESET";

export const MARK_VEHICLE_OFFLINE_START = "MARK_VEHICLE_OFFLINE_START";
export const MARK_VEHICLE_OFFLINE_SUCCESS = "MARK_VEHICLE_OFFLINE_SUCCESS";
export const MARK_VEHICLE_OFFLINE_FAIL = "MARK_VEHICLE_OFFLINE_FAIL";
export const MARK_VEHICLE_OFFLINE_RESET = "MARK_VEHICLE_OFFLINE_RESET";

export const UPDATE_VEHICLE_MODE_START = "UPDATE_VEHICLE_MODE_START";
export const UPDATE_VEHICLE_MODE_SUCCESS = "UPDATE_VEHICLE_MODE_SUCCESS";
export const UPDATE_VEHICLE_MODE_FAIL = "UPDATE_VEHICLE_MODE_FAIL";
export const UPDATE_VEHICLE_MODE_RESET = "UPDATE_VEHICLE_MODE_RESET";
export const GET_VEHICLE_SUGGESTED_PRICE_START =
  "GET_VEHICLE_SUGGESTED_PRICE_START";
export const GET_VEHICLE_SUGGESTED_PRICE_SUCCESS =
  "GET_VEHICLE_SUGGESTED_PRICE_SUCCESS";
export const GET_VEHICLE_SUGGESTED_PRICE_FAIL =
  "GET_VEHICLE_SUGGESTED_PRICE_FAIL";
export const GET_VEHICLE_SUGGESTED_PRICE_RESET =
  "GET_VEHICLE_SUGGESTED_PRICE_RESET";

export const GET_VEHICLE_FEATURES_OPTIONS_START =
  "GET_VEHICLE_FEATURES_OPTIONS_START";
export const GET_VEHICLE_FEATURES_OPTIONS_SUCCESS =
  "GET_VEHICLE_FEATURES_OPTIONS_SUCCESS";
export const GET_VEHICLE_FEATURES_OPTIONS_FAIL =
  "GET_VEHICLE_FEATURES_OPTIONS_FAIL";
export const GET_VEHICLE_FEATURES_OPTIONS_RESET =
  "GET_VEHICLE_FEATURES_OPTIONS_RESET";

export const GET_VEHICLE_GROUP_FEATURES_START =
  "GET_VEHICLE_GROUP_FEATURES_START";
export const GET_VEHICLE_GROUP_FEATURES_SUCCESS =
  "GET_VEHICLE_GROUP_FEATURES_SUCCESS";
export const GET_VEHICLE_GROUP_FEATURES_FAIL =
  "GET_VEHICLE_GROUP_FEATURES_FAIL";
export const GET_VEHICLE_GROUP_FEATURES_RESET =
  "GET_VEHICLE_GROUP_FEATURES_RESET";

export const SEARCH_ROLES_PERMISSIONS_START = "SEARCH_ROLES_PERMISSIONS_START";
export const SEARCH_ROLES_PERMISSIONS_SUCCESS =
  "SEARCH_ROLES_PERMISSIONS_SUCCESS";
export const SEARCH_ROLES_PERMISSIONS_FAIL = "SEARCH_ROLES_PERMISSIONS_FAIL";
export const SEARCH_ROLES_PERMISSIONS_RESET = "SEARCH_ROLES_PERMISSIONS_RESET";

export const GET_ORG_ROLES_START = "GET_ORG_ROLES_START";
export const GET_ORG_ROLES_SUCCESS = "GET_ORG_ROLES_SUCCESS";
export const GET_ORG_ROLES_FAIL = "GET_ORG_ROLES_FAIL";
export const GET_ORG_ROLES_RESET = "GET_ORG_ROLES_RESET";

export const GET_FEEDBACK_DETAILS_START = "GET_FEEDBACK_DETAILS_START";
export const GET_FEEDBACK_DETAILS_SUCCESS = "GET_FEEDBACK_DETAILS_SUCCESS";
export const GET_FEEDBACK_DETAILS_FAIL = "GET_FEEDBACK_DETAILS_FAIL";
export const GET_FEEDBACK_DETAILS_RESET = "GET_FEEDBACK_DETAILS_RESET";

export const GET_FEEDBACK_QUESTIONS_START = "GET_FEEDBACK_QUESTIONS_START";
export const GET_FEEDBACK_QUESTIONS_SUCCESS = "GET_FEEDBACK_QUESTIONS_SUCCESS";
export const GET_FEEDBACK_QUESTIONS_FAIL = "GET_FEEDBACK_QUESTIONS_FAIL";
export const GET_FEEDBACK_QUESTIONS_RESET = "GET_FEEDBACK_QUESTIONS_RESET";

export const ADD_FEEDBACK_START = "ADD_FEEDBACK_START";
export const ADD_FEEDBACK_SUCCESS = "ADD_FEEDBACK_SUCCESS";
export const ADD_FEEDBACK_FAIL = "ADD_FEEDBACK_FAIL";
export const ADD_FEEDBACK_RESET = "ADD_FEEDBACK_RESET";

export const SEARCH_ACTIVITIES_START = "SEARCH_ACTIVITIES_START";
export const SEARCH_ACTIVITIES_SUCCESS = "SEARCH_ACTIVITIES_SUCCESS";
export const SEARCH_ACTIVITIES_FAIL = "SEARCH_ACTIVITIES_FAIL";
export const SEARCH_ACTIVITIES_RESET = "SEARCH_ACTIVITIES_RESET";

export const ADD_ACTIVITY_START = "ADD_ACTIVITY_START";
export const ADD_ACTIVITY_SUCCESS = "ADD_ACTIVITY_SUCCESS";
export const ADD_ACTIVITY_FAIL = "ADD_ACTIVITY_FAIL";
export const ADD_ACTIVITY_RESET = "ADD_ACTIVITY_RESET";

export const RESEND_CREDIT_GRANT_EMAIL_START =
  "RESEND_CREDIT_GRANT_EMAIL_START";
export const RESEND_CREDIT_GRANT_EMAIL_SUCCESS =
  "RESEND_CREDIT_GRANT_EMAIL_SUCCESS";
export const RESEND_CREDIT_GRANT_EMAIL_FAIL = "RESEND_CREDIT_GRANT_EMAIL_FAIL";
export const RESEND_CREDIT_GRANT_EMAIL_RESET =
  "RESEND_CREDIT_GRANT_EMAIL_RESET";

export const SEARCH_REPORT_PROBLEM_DETAILS_START =
  "SEARCH_REPORT_PROBLEM_DETAILS_START";
export const SEARCH_REPORT_PROBLEM_DETAILS_SUCCESS =
  "SEARCH_REPORT_PROBLEM_DETAILS_SUCCESS";
export const SEARCH_REPORT_PROBLEM_DETAILS_FAIL =
  "SEARCH_REPORT_PROBLEM_DETAILS_FAIL";
export const SEARCH_REPORT_PROBLEM_DETAILS_RESET =
  "SEARCH_REPORT_PROBLEM_DETAILS_RESET";

export const GET_LOCATION_DATA_START = "GET_LOCATION_DATA_START";
export const GET_LOCATION_DATA_SUCCESS = "GET_LOCATION_DATA_SUCCESS";
export const GET_LOCATION_DATA_FAIL = "GET_LOCATION_DATA_FAIL";
export const GET_LOCATION_DATA_RESET = "GET_LOCATION_DATA_RESET";

export const ADD_ORG_ROLES_START = "ADD_ORG_ROLES_START";
export const ADD_ORG_ROLES_SUCCESS = "ADD_ORG_ROLES_SUCCESS";
export const ADD_ORG_ROLES_FAIL = "ADD_ORG_ROLES_FAIL";
export const ADD_ORG_ROLES_RESET = "ADD_ORG_ROLES_RESET";

export const EDIT_ORG_ROLES_START = "EDIT_ORG_ROLES_START";
export const EDIT_ORG_ROLES_SUCCESS = "EDIT_ORG_ROLES_SUCCESS";
export const EDIT_ORG_ROLES_FAIL = "EDIT_ORG_ROLES_FAIL";
export const EDIT_ORG_ROLES_RESET = "EDIT_ORG_ROLES_RESET";

export const DELETE_ORG_ROLES_START = "DELETE_ORG_ROLES_START";
export const DELETE_ORG_ROLES_SUCCESS = "DELETE_ORG_ROLES_SUCCESS";
export const DELETE_ORG_ROLES_FAIL = "DELETE_ORG_ROLES_FAIL";
export const DELETE_ORG_ROLES_RESET = "DELETE_ORG_ROLES_RESET";

export const GET_ORG_PERMISSIONS_START = "GET_ORG_PERMISSIONS_START";
export const GET_ORG_PERMISSIONS_SUCCESS = "GET_ORG_PERMISSIONS_SUCCESS";
export const GET_ORG_PERMISSIONS_FAIL = "GET_ORG_PERMISSIONS_FAIL";
export const GET_ORG_PERMISSIONS_RESET = "GET_ORG_PERMISSIONS_RESET";

export const EDIT_ORG_PERMISSIONS_START = "EDIT_ORG_PERMISSIONS_START";
export const EDIT_ORG_PERMISSIONS_SUCCESS = "EDIT_ORG_PERMISSIONS_SUCCESS";
export const EDIT_ORG_PERMISSIONS_FAIL = "EDIT_ORG_PERMISSIONS_FAIL";
export const EDIT_ORG_PERMISSIONS_RESET = "EDIT_ORG_PERMISSIONS_RESET";

export const UPDATE_PERMISSIONS_ROLES_START = "UPDATE_PERMISSIONS_ROLES_START";
export const UPDATE_PERMISSIONS_ROLES_SUCCESS =
  "UPDATE_PERMISSIONS_ROLES_SUCCESS";
export const UPDATE_PERMISSIONS_ROLES_FAIL = "UPDATE_PERMISSIONS_ROLES_FAIL";
export const UPDATE_PERMISSIONS_ROLES_RESET = "UPDATE_PERMISSIONS_ROLES_RESET";
export const SEARCH_PAGES_START = "SEARCH_PAGES_START";
export const SEARCH_PAGES_SUCCESS = "SEARCH_PAGES_SUCCESS";
export const SEARCH_PAGES_FAIL = "SEARCH_PAGES_FAIL";
export const SEARCH_PAGES_RESET = "SEARCH_PAGES_RESET";

export const DELETE_VEHICLE_START = "DELETE_VEHICLE_START";
export const DELETE_VEHICLE_SUCCESS = "DELETE_VEHICLE_SUCCESS";
export const DELETE_VEHICLE_FAIL = "DELETE_VEHICLE_FAIL";
export const DELETE_VEHICLE_RESET = "DELETE_VEHICLE_RESET";

export const GET_DO_NOT_RENT_LIST_START = "GET_DO_NOT_RENT_LIST_START";
export const GET_DO_NOT_RENT_LIST_SUCCESS = "GET_DO_NOT_RENT_LIST_SUCCESS";
export const GET_DO_NOT_RENT_LIST_FAIL = "GET_DO_NOT_RENT_LIST_FAIL";
export const GET_DO_NOT_RENT_LIST_RESET = "GET_DO_NOT_RENT_LIST_RESET";

export const ADD_DO_NOT_RENT_LIST_START = "ADD_DO_NOT_RENT_LIST_START";
export const ADD_DO_NOT_RENT_LIST_SUCCESS = "ADD_DO_NOT_RENT_LIST_SUCCESS";
export const ADD_DO_NOT_RENT_LIST_FAIL = "ADD_DO_NOT_RENT_LIST_FAIL";
export const ADD_DO_NOT_RENT_LIST_RESET = "ADD_DO_NOT_RENT_LIST_RESET";

export const ADD_PERSON_TO_BLACK_LIST_START = "ADD_PERSON_TO_BLACK_LIST_START";
export const ADD_PERSON_TO_BLACK_LIST_SUCCESS =
  "ADD_PERSON_TO_BLACK_LIST_SUCCESS";
export const ADD_PERSON_TO_BLACK_LIST_FAIL = "ADD_PERSON_TO_BLACK_LIST_FAIL";
export const ADD_PERSON_TO_BLACK_LIST_RESET = "ADD_PERSON_TO_BLACK_LIST_RESET";

export const UPDATE_DO_NOT_RENT_LIST_START = "UPDATE_DO_NOT_RENT_LIST_START";
export const UPDATE_DO_NOT_RENT_LIST_SUCCESS =
  "UPDATE_DO_NOT_RENT_LIST_SUCCESS";
export const UPDATE_DO_NOT_RENT_LIST_FAIL = "UPDATE_DO_NOT_RENT_LIST_FAIL";
export const UPDATE_DO_NOT_RENT_LIST_RESET = "UPDATE_DO_NOT_RENT_LIST_RESET";

export const DELETE_DO_NOT_RENT_LIST_START = "DELETE_DO_NOT_RENT_LIST_START";
export const DELETE_DO_NOT_RENT_LIST_SUCCESS =
  "DELETE_DO_NOT_RENT_LIST_SUCCESS";
export const DELETE_DO_NOT_RENT_LIST_FAIL = "DELETE_DO_NOT_RENT_LIST_FAIL";
export const DELETE_DO_NOT_RENT_LIST_RESET = "DELETE_DO_NOT_RENT_LIST_RESET";

export const GET_FLEET_OVERRIDE_REASONS_START =
  "GET_FLEET_OVERRIDE_REASONS_START";
export const GET_FLEET_OVERRIDE_REASONS_SUCCESS =
  "GET_FLEET_OVERRIDE_REASONS_SUCCESS";
export const GET_FLEET_OVERRIDE_REASONS_FAIL =
  "GET_FLEET_OVERRIDE_REASONS_FAIL";
export const GET_FLEET_OVERRIDE_REASONS_RESET =
  "GET_FLEET_OVERRIDE_REASONS_RESET";

export const GET_FLEET_CLASS_GROUP_START = "GET_FLEET_CLASS_GROUP_START";
export const GET_FLEET_CLASS_GROUP_SUCCESS = "GET_FLEET_CLASS_GROUP_SUCCESS";
export const GET_FLEET_CLASS_GROUP_FAIL = "GET_FLEET_CLASS_GROUP_FAIL";
export const GET_FLEET_CLASS_GROUP_RESET = "GET_FLEET_CLASS_GROUP_RESET";

export const GET_DEFAULT_FLEET_CLASS_GROUP_START =
  "GET_DEFAULT_FLEET_CLASS_GROUP_START";
export const GET_DEFAULT_FLEET_CLASS_GROUP_SUCCESS =
  "GET_DEFAULT_FLEET_CLASS_GROUP_SUCCESS";
export const GET_DEFAULT_FLEET_CLASS_GROUP_FAIL =
  "GET_DEFAULT_FLEET_CLASS_GROUP_FAIL";
export const GET_DEFAULT_FLEET_CLASS_GROUP_RESET =
  "GET_DEFAULT_FLEET_CLASS_GROUP_RESET";

export const UPDATE_FLEET_CLASS_GROUP_START = "UPDATE_FLEET_CLASS_GROUP_START";
export const UPDATE_FLEET_CLASS_GROUP_SUCCESS =
  "UPDATE_FLEET_CLASS_GROUP_SUCCESS";
export const UPDATE_FLEET_CLASS_GROUP_FAIL = "UPDATE_FLEET_CLASS_GROUP_FAIL";
export const UPDATE_FLEET_CLASS_GROUP_RESET = "UPDATE_FLEET_CLASS_GROUP_RESET";

export const GET_FLEET_CLASS_GROUP_CLASS_CODE_START =
  "GET_FLEET_CLASS_GROUP_CLASS_CODE_START";
export const GET_FLEET_CLASS_GROUP_CLASS_CODE_SUCCESS =
  "GET_FLEET_CLASS_GROUP_CLASS_CODE_SUCCESS";
export const GET_FLEET_CLASS_GROUP_CLASS_CODE_FAIL =
  "GET_FLEET_CLASS_GROUP_CLASS_CODE_FAIL";
export const GET_FLEET_CLASS_GROUP_CLASS_CODE_RESET =
  "GET_FLEET_CLASS_GROUP_CLASS_CODE_RESET";

export const GET_FLEET_UTILIZATION_FORECAST_START =
  "GET_FLEET_UTILIZATION_FORECAST_START";
export const GET_FLEET_UTILIZATION_FORECAST_SUCCESS =
  "GET_FLEET_UTILIZATION_FORECAST_SUCCESS";
export const GET_FLEET_UTILIZATION_FORECAST_FAIL =
  "GET_FLEET_UTILIZATION_FORECAST_FAIL";
export const GET_FLEET_UTILIZATION_FORECAST_RESET =
  "GET_FLEET_UTILIZATION_FORECAST_RESET";

export const UPDATE_FLEET_UTILIZATION_FORECAST_START =
  "UPDATE_FLEET_UTILIZATION_FORECAST_START";
export const UPDATE_FLEET_UTILIZATION_FORECAST_SUCCESS =
  "UPDATE_FLEET_UTILIZATION_FORECAST_SUCCESS";
export const UPDATE_FLEET_UTILIZATION_FORECAST_FAIL =
  "UPDATE_FLEET_UTILIZATION_FORECAST_FAIL";
export const UPDATE_FLEET_UTILIZATION_FORECAST_RESET =
  "UPDATE_FLEET_UTILIZATION_FORECAST_RESET";

export const GET_FLEET_UTILIZATION_LOGS_START =
  "GET_FLEET_UTILIZATION_LOGS_START";
export const GET_FLEET_UTILIZATION_LOGS_SUCCESS =
  "GET_FLEET_UTILIZATION_LOGS_SUCCESS";
export const GET_FLEET_UTILIZATION_LOGS_FAIL =
  "GET_FLEET_UTILIZATION_LOGS_FAIL";
export const GET_FLEET_UTILIZATION_LOGS_RESET =
  "GET_FLEET_UTILIZATION_LOGS_RESET";

export const RESEND_PAYMENT_LINK_FOX_API_START =
  "RESEND_PAYMENT_LINK_FOX_API_START";
export const RESEND_PAYMENT_LINK_FOX_API_SUCCESS =
  "RESEND_PAYMENT_LINK_FOX_API_SUCCESS";
export const RESEND_PAYMENT_LINK_FOX_API_FAIL =
  "RESEND_PAYMENT_LINK_FOX_API_FAIL";
export const RESEND_PAYMENT_LINK_FOX_API_RESET =
  "RESEND_PAYMENT_LINK_FOX_API_RESET";

export const UPDATE_RESERVATION_DETAILS_START =
  "UPDATE_RESERVATION_DETAILS_START";
export const UPDATE_RESERVATION_DETAILS_SUCCESS =
  "UPDATE_RESERVATION_DETAILS_SUCCESS";
export const UPDATE_RESERVATION_DETAILS_FAIL =
  "UPDATE_RESERVATION_DETAILS_FAIL";
export const UPDATE_RESERVATION_DETAILS_RESET =
  "UPDATE_RESERVATION_DETAILS_RESET";

import * as actionTypes from "../actions/actionTypes";
import { reducerUpdateObject } from "./reducer.utility";

const initialState = {
  transactionResponse: null,
  captureReleaseRefundResponse: null,
  creditCardNumberResponse: null,
  loading: false
};

const getTransactionListStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
const getTransactionListSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    transactionResponse: action.transactionResponse,
    error: null,
    loading: false
  });
};
const getTransactionListFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false
  });
};
const getTransactionListReset = (state, action) => {
  return reducerUpdateObject(state, { transactionResponse: null });
};
const doCaptureReleaseRefundStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
const doCaptureReleaseRefundSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    captureReleaseRefundResponse: action.captureReleaseRefundResponse,
    error: null,
    loading: false
  });
};
const doCaptureReleaseRefundFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false
  });
};
const doCaptureReleaseRefundReset = (state, action) => {
  return reducerUpdateObject(state, { captureReleaseRefundResponse: null });
};
const fetchCreditCardNumberStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
const fetchCreditCardNumberSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    creditCardNumberResponse: action.creditCardNumberResponse,
    error: null,
    loading: false
  });
};
const fetchCreditCardNumberFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false
  });
};
const fetchCreditCardNumberReset = (state, action) => {
  return reducerUpdateObject(state, { creditCardNumberResponse: null });
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_TRANSACTIONLIST_START:
      return getTransactionListStart(state, action);
    case actionTypes.GET_TRANSACTIONLIST_SUCCESS:
      return getTransactionListSuccess(state, action);
    case actionTypes.GET_TRANSACTIONLIST_FAIL:
      return getTransactionListFail(state, action);
    case actionTypes.GET_TRANSACTIONLIST_RESET:
      return getTransactionListReset(state, action);

    case actionTypes.DO_CAPTURE_REFUND_RELEASE_START:
      return doCaptureReleaseRefundStart(state, action);
    case actionTypes.DO_CAPTURE_REFUND_RELEASE_SUCCESS:
      return doCaptureReleaseRefundSuccess(state, action);
    case actionTypes.DO_CAPTURE_REFUND_RELEASE_FAIL:
      return doCaptureReleaseRefundFail(state, action);
    case actionTypes.DO_CAPTURE_REFUND_RELEASE_RESET:
      return doCaptureReleaseRefundReset(state, action);

    case actionTypes.FETCH_CREDITCARDNUMBER_START:
      return fetchCreditCardNumberStart(state, action);
    case actionTypes.FETCH_CREDITCARDNUMBER_SUCCESS:
      return fetchCreditCardNumberSuccess(state, action);
    case actionTypes.FETCH_CREDITCARDNUMBER_FAIL:
      return fetchCreditCardNumberFail(state, action);
    case actionTypes.FETCH_CREDITCARDNUMBER_RESET:
      return fetchCreditCardNumberReset(state, action);

    default:
      return state;
  }
};
export default reducer;

export const prepareBasicSearchList = (ctrlObj) => {
  const formElementsArray = [];
  for (let key in ctrlObj) {
    try {
      formElementsArray.push({
        id: key,
        config: ctrlObj[key],
        basicOrder: ctrlObj[key].basicOrder,
      });
    } catch (e) {}
  }
  const basicArrays = [[]];

  let counter = 0;
  formElementsArray.sort((a, b) => a.basicOrder - b.basicOrder);
  formElementsArray.forEach(function (value, i) {
    if (value.basicOrder > 0) {
      counter++;
      if (counter <= 5) {
        basicArrays[0].push(value);
      } else {
        if (!basicArrays[1]) {
          basicArrays[1] = [];
        }
        basicArrays[1].push(value);
      }
    }
  });
  return basicArrays;
};

export const prepareAdvancedSearchList = (ctrlObj) => {
  const formElementsArray = [];
  for (let key in ctrlObj) {
    try {
      formElementsArray.push({
        id: key,
        config: ctrlObj[key],
        advancedOrder: ctrlObj[key].advancedOrder,
      });
    } catch (e) {}
  }
  const advancedArrays = [[]];
  let counter = 0;
  formElementsArray.sort((a, b) => a.advancedOrder - b.advancedOrder);
  formElementsArray.forEach(function (value, i) {
    if (value.advancedOrder > 0) {
      counter++;
      if (counter <= 5) {
        advancedArrays[0].push(value);
      } else if (counter <= 10) {
        if (!advancedArrays[1]) {
          advancedArrays[1] = [];
        }
        advancedArrays[1].push(value);
      } else if (counter <= 15) {
        if (!advancedArrays[2]) {
          advancedArrays[2] = [];
        }
        advancedArrays[2].push(value);
      } else if (counter <= 20) {
        if (!advancedArrays[3]) {
          advancedArrays[3] = [];
        }
        advancedArrays[3].push(value);
      } else if (counter <= 25) {
        if (!advancedArrays[4]) {
          advancedArrays[4] = [];
        }
        advancedArrays[4].push(value);
      } else {
        if (!advancedArrays[5]) {
          advancedArrays[5] = [];
        }
        advancedArrays[5].push(value);
      }
    }
  });
  return advancedArrays;
};

import { getDefaultFilterStates } from "../../../components/filter/filter-helper";
import PageInfo from "../../../services/entities/models/PageInfo";
import { OK_LBL } from "../../../shared/GlobalVar";
import {
  getCurrentServerDate,
  getCurrentServerTimezone,
} from "../../../shared/utility";
export const getServiceAgentReportCtrls = (urlOrPageName) => {
  return {
    PageInfo: new PageInfo(null, null, "CreatedOn", "DESC"),
    Status: {
      value: getDefaultFilterStates(urlOrPageName),
    },
    isAdvancedSearch: false,
    FromDate: {
      id: "tbFromDate",
      elementType: "dateandtimepicker",
      elementConfig: {
        name: "FromDate",
        type: "text",
      },
      timePicker: {
        id: "StartTime",
        name: "StartTime",
        value: getCurrentServerDate("MM:DD:YYYY:HH:mm"),
        elementType: "timepicker",
      },
      validation: {
        required: false,
      },
      labelInput: {
        labeltext: "From",
      },
      timeZoneString: getCurrentServerTimezone(),
      value: getCurrentServerDate("MM:DD:YYYY:HH:mm"),
      valid: false,
      touched: false,
      basicOrder: 1,
      advancedOrder: 1,
    },
    ToDate: {
      id: "tbToDate",
      elementType: "dateandtimepicker",
      elementConfig: {
        name: "ToDate",
        type: "text",
      },
      timePicker: {
        id: "EndTime",
        name: "EndTime",
        value: "",
        elementType: "timepicker",
      },
      labelInput: {
        labeltext: "To",
      },
      timeZoneString: getCurrentServerTimezone(),
      value: "",
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      basicOrder: 6,
      advancedOrder: 6,
    },
    AgentName: {
      id: "tbAgentName",
      elementType: "input",
      elementConfig: {
        name: "AgentName",
        type: "text",
      },
      labelInput: {
        labeltext: "Agent Name",
      },
      value: "",
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      basicOrder: 2,
      advancedOrder: 2,
    },
    TrackerId: {
      id: "tbTrackerID",
      elementType: "input",
      elementConfig: {
        name: "TrackerId",
        type: "number",
      },
      labelInput: {
        labeltext: "Tracker ID",
      },
      value: "",
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      basicOrder: 3,
      advancedOrder: 3,
    },
    EmployeeLocationId: {
      id: "ddlEmployeeProfileLocationId",
      elementType: "select",
      elementConfig: {
        name: "EmployeeLocationId",
        options: [{ key: "", displayValue: "Any Location" }],
      },
      labelInput: {
        labeltext: "Employee Loc",
      },
      value: "",
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      basicOrder: 4,
      advancedOrder: 4,
    },
    // ProfileLocationId: {
    //   id: "ddlEmployeeProfileLocationId",
    //   elementType: "select",
    //   elementConfig: {
    //     name: "ProfileLocationId",
    //     options: [{ key: "", displayValue: "Any Location" }],
    //   },
    //   labelInput: {
    //     labeltext: "Profile Loc",
    //   },
    //   value: "",
    //   validation: {
    //     required: false,
    //   },
    //   valid: false,
    //   touched: false,
    //   basicOrder: 4,
    //   advancedOrder: 4,
    // },
    VehicleId: {
      id: "VehicleId",
      elementType: "input",
      elementConfig: {
        name: "VehicleId",
        type: "text",
      },
      labelInput: {
        labeltext: "Vehicle ID",
      },
      value: "",
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      basicOrder: 7,
      advancedOrder: 7,
    },
    TrackerSubType: {
      id: "ddlTrackerSubType",
      elementType: "select",
      elementConfig: {
        name: "TrackerSubType",
        options: [
          { key: "", displayValue: "Select" },
          { key: "NONE", displayValue: "None" },
          { key: "NORMAL", displayValue: "Normal" },
          { key: "DEEP", displayValue: "Deep" },
          { key: "WASH", displayValue: "Wash" },
          { key: "FULL_CYCLE", displayValue: "Full Cycle" },
        ],
      },
      labelInput: {
        labeltext: "Cleaning Type",
      },
      value: "",
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      basicOrder: 8,
      advancedOrder: 8,
    },
    FuelRequired: {
      id: "ddlFuelRequired",
      elementType: "select",
      elementConfig: {
        name: "FuelRequired",
        options: [
          { key: "", displayValue: "All" },
          { key: "true", displayValue: "Yes" },
          { key: "false", displayValue: "No" },
        ],
      },
      labelInput: {
        labeltext: "Need Fuel",
      },
      value: "",
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      basicOrder: 9,
      advancedOrder: 9,
    },
    Vin: {
      id: "tbVIN",
      elementType: "input",
      elementConfig: {
        name: "Vin",
        type: "text",
      },
      labelInput: {
        labeltext: "VIN",
      },
      value: "",
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      basicOrder: 0,
      advancedOrder: 11,
    },
    VinManualEntry: {
      id: "tbVinManualEntry",
      elementType: "select",
      elementConfig: {
        name: "VinManualEntry",
        options: [
          { key: "", displayValue: "All" },
          { key: "true", displayValue: "Yes" },
          { key: "false", displayValue: "No" },
        ],
      },
      labelInput: {
        labeltext: "VIN Manual Entry",
      },
      value: "",
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      basicOrder: 0,
      advancedOrder: 12,
    },
    TiresCondition: {
      id: "tbTireCondition",
      elementType: "select",
      elementConfig: {
        name: "TiresCondition",
        options: [
          { key: "", displayValue: "All" },
          { key: "false", displayValue: "Poor" },
          { key: "true", displayValue: "Good" },
        ],
      },
      labelInput: {
        labeltext: "Tire Condition",
      },
      value: "",
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      basicOrder: 0,
      advancedOrder: 13,
    },
    IndividuallyPerformed: {
      id: "ddlIndividuallyPerformed",
      elementType: "select",
      elementConfig: {
        name: "IndividuallyPerformed",
        options: [
          { key: "", displayValue: "Select" },
          { key: "true", displayValue: "Yes" },
          { key: "false", displayValue: "No" },
        ],
      },
      labelInput: {
        labeltext: "Ind. Performed",
      },
      value: "",
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      basicOrder: 0,
      advancedOrder: 14,
    },
    Grade: {
      id: "ddlGrade",
      elementType: "select",
      elementConfig: {
        name: "Grade",
        options: [
          { key: "", displayValue: "All" },
          { key: "1", displayValue: "1" },
          { key: "2", displayValue: "2" },
          { key: "3", displayValue: "3" },
          { key: "4", displayValue: "4" },
        ],
      },
      labelInput: {
        labeltext: "Grade",
      },
      value: "",
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      basicOrder: 0,
      advancedOrder: 16,
    },
    btnResSearch: {
      id: "btnResSearch",
      elementType: "button",
      elementConfig: {
        type: "submit",
        value: "Search",
        name: "btnResSearch",
        className: "btn btn-primary search-btn",
      },
      basicOrder: 5,
      advancedOrder: 5,
    },
    btnResSearchReset: {
      id: "btnResSearchReset",
      elementType: "button",
      elementConfig: {
        type: "button",
        value: "Reset",
        name: "btnResSearchReset",
        className: "btn btn-secondary  search-btn",
      },
      basicOrder: 10,
      advancedOrder: 10,
    },
    empty: {
      id: "empty",
      elementType: "empty",
      elementConfig: {
        name: "",
        type: "empty",
      },
      basicOrder: 0,
      advancedOrder: 15,
    },
    empty1: {
      id: "empty",
      elementType: "empty",
      elementConfig: {
        name: "",
        type: "empty",
      },
      basicOrder: 0,
      advancedOrder: 20,
    },
  };
};
export const getMechanicReportCtrls = (urlOrPageName) => {
  const mechanicCtrl = getServiceAgentReportCtrls(urlOrPageName);
  delete mechanicCtrl["FuelRequired"];
  delete mechanicCtrl["TrackerSubType"];
  delete mechanicCtrl["TiresCondition"];
  mechanicCtrl.AgentName.labelInput.labeltext = "Mechanic Name";
  mechanicCtrl.AgentName.basicOrder = 7;
  mechanicCtrl.AgentName.advancedOrder = 7;
  mechanicCtrl.TrackerId.basicOrder = 3;
  mechanicCtrl.TrackerId.advancedOrder = 3;
  // mechanicCtrl.ProfileLocationId.basicOrder = 4;
  // mechanicCtrl.ProfileLocationId.advancedOrder = 4;
  mechanicCtrl.VehicleId.basicOrder = 3;
  mechanicCtrl.VehicleId.advancedOrder = 12;
  mechanicCtrl.IndividuallyPerformed.basicOrder = 0;
  mechanicCtrl.IndividuallyPerformed.advancedOrder = 9;
  mechanicCtrl.AgentName.basicOrder = 7;
  mechanicCtrl.AgentName.advancedOrder = 7;
  mechanicCtrl.Vin.basicOrder = 0;
  mechanicCtrl.Vin.advancedOrder = 11;
  mechanicCtrl.VinManualEntry.basicOrder = 0;
  mechanicCtrl.VinManualEntry.advancedOrder = 17;

  return {
    ...mechanicCtrl,
    Complaints: {
      id: "ddlComplaints",
      elementType: "cb_dropdown",
      elementConfig: {
        name: "Complaints",
        options: getComplainsList(),
        selectMultipleOption: [],
      },
      labelInput: {
        labeltext: "Complaints",
      },
      value: [],
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      basicOrder: 9,
      advancedOrder: 3,
    },
    Causes: {
      id: "ddlCauses",
      elementType: "cb_dropdown",
      elementConfig: {
        name: "Causes",
        options: getCausesList(),
        selectMultipleOption: [],
      },
      labelInput: {
        labeltext: "Causes",
      },
      value: "",
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      basicOrder: 0,
      advancedOrder: 8,
    },
    Corrections: {
      id: "ddlCorrections",
      elementType: "cb_dropdown",
      elementConfig: {
        name: "Corrections",
        options: getCorrectionsList(),
        selectMultipleOption: [],
      },
      labelInput: {
        labeltext: "Corrections",
      },
      value: "",
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      basicOrder: 0,
      advancedOrder: 13,
    },
    TiresAndParts: {
      id: "ddlTiresAndParts",
      elementType: "cb_dropdown",
      elementConfig: {
        name: "TiresAndParts",
        options: getTiresAndPartsList(),
        selectMultipleOption: [],
      },
      labelInput: {
        labeltext: "Tires & Parts",
      },
      value: "",
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      basicOrder: 0,
      advancedOrder: 18,
    },
    AgentBadgeNo: {
      id: "tbAgentBadgeNo",
      elementType: "input",
      elementConfig: {
        name: "AgentBadgeNo",
        type: "text",
      },
      labelInput: {
        labeltext: "Badge",
      },
      value: "",
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      basicOrder: 8,
      advancedOrder: 21,
    },
    AssignedTo: {
      id: "tbAssignedTo",
      elementType: "input",
      elementConfig: {
        name: "AssignedTo",
        type: "text",
      },
      labelInput: {
        labeltext: "Assigned To",
      },
      value: "",
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      basicOrder: 0,
      advancedOrder: 19,
    },
    AssignedBy: {
      id: "tbAssignedBy",
      elementType: "input",
      elementConfig: {
        name: "AssignedBy",
        type: "text",
      },
      labelInput: {
        labeltext: "Assigned By",
      },
      value: "",
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      basicOrder: 0,
      advancedOrder: 14,
    },
    empty2: {
      id: "empty",
      elementType: "empty",
      elementConfig: {
        name: "",
        type: "empty",
      },
      basicOrder: 0,
      advancedOrder: 25,
    },
  };
};
export const getReadyLineAgentReportCtrls = (urlOrPageName) => {
  return {
    PageInfo: new PageInfo(null, null, "CreatedOn", "DESC"),
    Status: {
      value: "COMPLETED",
    },
    AuditStatus: {
      value: getDefaultFilterStates(urlOrPageName),
    },
    isAdvancedSearch: "",
    AuditFromDate: {
      id: "tbAuditFromDate",
      elementType: "dateandtimepicker",
      elementConfig: {
        name: "AuditFromDate",
        type: "text",
      },
      timePicker: {
        id: "StartTime",
        name: "StartTime",
        value: getCurrentServerDate("MM:DD:YYYY:HH:mm"),
        elementType: "timepicker",
      },
      validation: {
        required: false,
      },
      labelInput: {
        labeltext: "Audit From",
      },
      timeZoneString: getCurrentServerTimezone(),
      value: getCurrentServerDate("MM:DD:YYYY:HH:mm"),
      valid: false,
      touched: false,
      basicOrder: 1,
      advancedOrder: 0,
    },
    AuditToDate: {
      id: "tbAuditToDate",
      elementType: "dateandtimepicker",
      elementConfig: {
        name: "AuditToDate",
        type: "text",
      },
      timePicker: {
        id: "EndTime",
        name: "EndTime",
        value: "",
        elementType: "timepicker",
      },
      labelInput: {
        labeltext: "Audit To",
      },
      timeZoneString: getCurrentServerTimezone(),
      value: "",
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      basicOrder: 6,
      advancedOrder: 0,
    },
    AgentName: {
      id: "tbAgentName",
      elementType: "input",
      elementConfig: {
        name: "AgentName",
        type: "text",
      },
      labelInput: {
        labeltext: "Agent Name",
      },
      value: "",
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      basicOrder: 2,
      advancedOrder: 0,
    },
    AuditedBy: {
      id: "tbAuditedBy",
      elementType: "input",
      elementConfig: {
        name: "AuditedBy",
        type: "text",
      },
      labelInput: {
        labeltext: "Audited By",
      },
      value: "",
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      basicOrder: 7,
      advancedOrder: 0,
    },
    TrackerId: {
      id: "tbTrackerID",
      elementType: "input",
      elementConfig: {
        name: "TrackerId",
        type: "number",
      },
      labelInput: {
        labeltext: "Tracker ID",
      },
      value: "",
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      basicOrder: 3,
      advancedOrder: 0,
    },
    ProfileLocationId: {
      id: "ddlEmployeeProfileLocationId",
      elementType: "select",
      elementConfig: {
        name: "ProfileLocationId",
        options: [{ key: "", displayValue: "Any Location" }],
      },
      labelInput: {
        labeltext: "Profile Loc",
      },
      value: "",
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      basicOrder: 4,
      advancedOrder: 0,
    },
    Vin: {
      id: "tbVIN",
      elementType: "input",
      elementConfig: {
        name: "Vin",
        type: "text",
      },
      labelInput: {
        labeltext: "VIN",
      },
      value: "",
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      basicOrder: 8,
      advancedOrder: 0,
    },
    AuditFailedCauses: {
      id: "ddlAuditFailedCauses",
      elementType: "cb_dropdown",
      elementConfig: {
        name: "AuditFailedCauses",
        options: getAuditFailCausesList(),
        selectMultipleOption: [],
      },
      labelInput: {
        labeltext: "Audit Fail Causes",
      },
      value: "",
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      basicOrder: 9,
      advancedOrder: 0,
    },
    btnResSearch: {
      id: "btnResSearch",
      elementType: "button",
      elementConfig: {
        type: "submit",
        value: "Search",
        name: "btnResSearch",
        className: "btn btn-primary search-btn",
      },
      basicOrder: 5,
      advancedOrder: 0,
    },
    btnResSearchReset: {
      id: "btnResSearchReset",
      elementType: "button",
      elementConfig: {
        type: "button",
        value: "Reset",
        name: "btnResSearchReset",
        className: "btn btn-secondary  search-btn",
      },
      basicOrder: 10,
      advancedOrder: 0,
    },
  };
};
export const getServiceTaskFilters = () => {
  return {
    FilterInfo: [
      {
        FilterStates: {
          ACTIVE: {
            id: "filterActive",
            elementType: "filter",
            color: "#3A78AB",
            elementConfig: {
              name: "Active",
              type: "checkbox",
              value: "ACTIVE",
              checked: true,
            },
            labelInput: {
              labeltext: "Active",
              className: "filterlbl_Active",
            },
          },
          INACTIVE: {
            id: "filterInactive",
            color: "#B5B2BE",
            elementType: "filter",
            elementConfig: {
              name: "Inactive",
              type: "checkbox",
              value: "INACTIVE",
              checked: true,
            },
            labelInput: {
              labeltext: "Inactive",
              className: "filterlbl_Inactive",
            },
          },
          HOLD: {
            id: "filterHold",
            color: "#0000FF",
            elementType: "filter",
            elementConfig: {
              name: "Hold",
              type: "checkbox",
              value: "HOLD",
              checked: true,
            },
            labelInput: {
              labeltext: "Hold",
              className: "filterlbl_Hold",
            },
          },
          ASSIGNED: {
            id: "filterAssigned",
            color: "#8B3AAB",
            elementType: "filter",
            elementConfig: {
              name: "Assigned",
              type: "checkbox",
              value: "ASSIGNED",
              checked: true,
            },
            labelInput: {
              labeltext: "Assigned",
              className: "filterlbl_Assigned",
            },
          },
          COMPLETED: {
            id: "filterCompleted",
            color: "#8DACB0",
            elementType: "filter",
            elementConfig: {
              name: "Completed",
              type: "checkbox",
              value: "COMPLETED",
              checked: true,
            },
            labelInput: {
              labeltext: "Completed",
              className: "filterlbl_Completed",
            },
          },
          OBSOLETE: {
            id: "filterObsolete",
            color: "#4BB4E3",
            elementType: "filter",
            elementConfig: {
              name: "Obsolete",
              type: "checkbox",
              value: "OBSOLETE",
              checked: true,
            },
            labelInput: {
              labeltext: "Obsolete",
              className: "filterlbl_Obsolete",
            },
          },
        },
        Name: "Report",
      },
    ],
  };
};
export const getReadyLineAgentFilters = () => {
  return {
    FilterInfo: [
      {
        FilterStates: {
          AUDIT_OK: {
            id: "filterAuditedOk",
            elementType: "filter",
            color: "#5dbd91",
            elementConfig: {
              name: "AuditOk",
              type: "checkbox",
              value: "AUDIT_OK",
              checked: true,
            },
            labelInput: {
              labeltext: "Audited " + OK_LBL,
              className: "filterlbl_AuditOk",
            },
          },
          AUDIT_FAILED: {
            id: "filterAuditFailed",
            color: "#de2f13",
            elementType: "filter",
            elementConfig: {
              name: "AuditFailed",
              type: "checkbox",
              value: "AUDIT_FAILED",
              checked: true,
            },
            labelInput: {
              labeltext: "Audit Fail",
              className: "filterlbl_AuditFailed",
            },
          },
          NOT_AUDITED: {
            id: "filterNotAudited",
            color: "#4bb4e3",
            elementType: "filter",
            elementConfig: {
              name: "NotAudited",
              type: "checkbox",
              value: "NOT_AUDITED",
              checked: true,
            },
            labelInput: {
              labeltext: "Not Audited",
              className: "filterlbl_NotAudited",
            },
          },
        },
        Name: "Report",
      },
    ],
  };
};

const getComplainsList = () => {
  return [
    {
      displayValue: "Tire's",
      key: "TIRES",
    },
    {
      displayValue: "Oil Change",
      key: "OIL_CHANGE",
    },
    {
      displayValue: "TPMS Light",
      key: "TPMS_LIGHT",
    },
    {
      displayValue: "Engine Light",
      key: "ENGINE_LIGHT",
    },
    {
      displayValue: "Mechanical",
      key: "MECHANICAL",
    },
    {
      displayValue: "Reassigned",
      key: "REASSIGNED",
    },
    {
      displayValue: "Interior Trim",
      key: "INTERIOR_TRIM",
    },
    {
      displayValue: "Steering",
      key: "STEERING",
    },
    {
      displayValue: "Suspension",
      key: "SUSPENSION",
    },
    {
      displayValue: "AC",
      key: "AC",
    },
    {
      displayValue: "Wipers",
      key: "WIPERS",
    },
    {
      displayValue: "Brakes",
      key: "BRAKES",
    },
    {
      displayValue: "Windows",
      key: "WINDOWS",
    },
    {
      displayValue: "Radio",
      key: "RADIO",
    },
    {
      displayValue: "Gear Shift",
      key: "GEAR_SHIFT",
    },
    {
      displayValue: "Trunk Latch",
      key: "TRUNK_LATCH",
    },
    {
      displayValue: "Conv Top",
      key: "CONV_TOP",
    },
  ];
};

const getCausesList = () => {
  return [
    {
      displayValue: "Scheduled",
      key: "SCHEDULED",
    },
    {
      displayValue: "Oil Light",
      key: "OIL_LIGHT",
    },
    {
      displayValue: "Inflation",
      key: "INFLATION",
    },
    {
      displayValue: "Customer",
      key: "CUSTOMER",
    },
    {
      displayValue: "Warranty",
      key: "WARRANTY",
    },
    {
      displayValue: "Wear",
      key: "WEAR",
    },
    {
      displayValue: "Accident",
      key: "ACCIDENT",
    },
  ];
};

const getCorrectionsList = () => {
  return [
    {
      displayValue: "PM",
      key: "PM",
    },
    {
      displayValue: "PM/TIR",
      key: "PM_TIR",
    },
    {
      displayValue: "Repair Tire",
      key: "REPAIR_TIRE",
    },
    {
      displayValue: "Replace Tire",
      key: "REPLACE_TIRE",
    },
    {
      displayValue: "Replace Frt Pads",
      key: "REPLACE_FRT_PADS",
    },
    {
      displayValue: "Replace Rear Pads",
      key: "REPLACE_REAR_PADS",
    },
  ];
};

const getTiresAndPartsList = () => {
  return [
    {
      displayValue: "Hold for inspection",
      key: "HOLD_FOR_INSPECTION",
    },
    {
      displayValue: "Hold for manager",
      key: "HOLD_FOR_MANAGER",
    },
    {
      displayValue: "Part Ordered",
      key: "PART_ORDERED",
    },
    {
      displayValue: "No Parts Req'd",
      key: "NO_PARTS_REQD",
    },
    {
      displayValue: "Part In Stock",
      key: "PART_IN_STOCK",
    },
    {
      displayValue: "Tire Ordered",
      key: "TIRE_ORDERED",
    },
    {
      displayValue: "Tire In Stock",
      key: "TIRE_IN_STOCK",
    },
  ];
};
const getAuditFailCausesList = () => {
  return [
    {
      displayValue: "Exterior Wash not done properly",
      key: "EXTERIOR_WASH",
    },
    {
      displayValue: "Interior Vacuum not done properly",
      key: "INTERIOR_WASH",
    },
    {
      displayValue: "Dashboard not cleaned",
      key: "DASHBOARD_NOT_CLEANED",
    },
    {
      displayValue: "Garbage not cleaned from the Car",
      key: "GARBAGE_NOT_CLEANED",
    },
    {
      displayValue: "Windows not cleaned",
      key: "WINDOWS_NOT_CLEANED",
    },
    {
      displayValue: "Interior Carpet not cleaned",
      key: "CARPET_NOT_CLEANED",
    },
    {
      displayValue: "Seats are not cleaned",
      key: "SEATS_NOT_CLEANED",
    },

    {
      displayValue: "Property in Vehicle from Prior Customer",
      key: "PROPERTY_OF_PRIOR_CUSTOMER",
    },
    {
      displayValue: "Warning Lights in Vehicle",
      key: "WARNING_LIGHT",
    },
    {
      displayValue: "Refueling not done properly",
      key: "REFUELING",
    },
    {
      displayValue: "Unrecorded Damage",
      key: "UNRECORDED_DAMAGE",
    },

    {
      displayValue: "Strong Odor",
      key: "STRONG_ODOR",
    },
    {
      displayValue: "Smoke Odor and Cigarette Ashes",
      key: "SMOKE_ODER_CIGARETTE_ASHES",
    },
    {
      displayValue: "Pet Hair and/or Smell",
      key: "PET_HAIR_SMELL",
    },
    {
      displayValue: "Ant infestation in the Car",
      key: "ANT_INFESTATION",
    },
    {
      displayValue: "Exterior Damage",
      key: "EXTERIOR_DAMAGE",
    },
    {
      displayValue: "Interior Damage",
      key: "INTERIOR_DAMAGE",
    },
    {
      displayValue: "Incorrect Stall Number",
      key: "INCORRECT_STALL_NUMBER",
    },
    {
      displayValue: "Other",
      key: "OTHER",
    },
  ];
};

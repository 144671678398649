import * as global from "../../shared/GlobalVar";
import { getTimeFromDuration, formatCurrency } from "../../shared/utility";
export const createPricingObj = (objPricingInfo, pageType, jsiMarketPlace) => {
  let pricingObj = {};
  pricingObj.rentalInfo = {
    title: "Vehicle Rental Subtotal",
    data: {},
    mileage: {},
  };
  pricingObj.subTotalInfo = {
    title: "Additional Options Subtotal",
    taxableAddOns: [],
    addOns: [],
  };
  pricingObj.otherChargesInfo = { title: "Other Charges Subtotal" };
  pricingObj.cancellationChargesInfo = { title: "Cancelled Reservation" };
  pricingObj.cancellationFeeInfo = { title: "Cancellation Fee" };
  pricingObj.additionalChargeInfo = { title: "Additional Charge" };
  pricingObj.taxes = [];
  pricingObj.surcharges = [];
  pricingObj.credits = [];
  pricingObj.miscellaneousCharges = [];
  pricingObj.refunds = {
    title: "Refunds",
    total: 0,
    displayNagetive: true,
  };
  pricingObj.voidedRefunds = {
    title: "Voided Refunds",
    total: 0,
    displayNagetive: false,
  };

  pricingObj.voidedPayments = {
    title: "Voided Payments",
    total: 0,
    displayNagetive: true,
  };
  pricingObj.deltaPayments = {
    title: "",
    total: 0,
  };
  pricingObj.preAuthAmount = {
    title: "Pre-authorization Hold",
    total: 0,
  };
  pricingObj.extensionPrice = {
    title: "Extension",
    total: 0,
    type: "exrental",
  };
  pricingObj.payments = {
    title: "Payments",
    total: 0,
  };
  pricingObj.totalPaymentsWithCredits = {
    title: "Total Payments and credits",
    total: 0,
  };
  pricingObj.totalCharges = {
    title: "Total Charges",
    total: 0,
  };
  pricingObj.balanceDue = {
    title: "Balance Due",
    total: 0,
  };
  if (objPricingInfo) {
    if (
      jsiMarketPlace === undefined &&
      objPricingInfo.CreatedOrgUnitId !== undefined
    ) {
      jsiMarketPlace =
        (objPricingInfo.CreatedOrgUnitId &&
          objPricingInfo.CreatedOrgUnitId === global.JSIAdminId &&
          objPricingInfo.CreatedOrgUnitId !==
            localStorage.getItem("currentOrgUnitId")) ||
        10002624203;
    }
    /*Started Rental Info*/
    let monthlyCharge =
      objPricingInfo.ParticipantType === "SHARER"
        ? objPricingInfo.SharerMonthlyRate
        : objPricingInfo.MonthlyRate;
    if (objPricingInfo.TotalChargedMonths) {
      pricingObj.rentalInfo.data.monthly = {
        title: "Monthly Charge",
        unit: objPricingInfo.TotalChargedMonths,
        charge: monthlyCharge,
        total: objPricingInfo.TotalChargedMonths * monthlyCharge || 0,
      };
    }
    let weeklyCharge =
      objPricingInfo.ParticipantType === "SHARER"
        ? objPricingInfo.SharerWeeklyRate
        : objPricingInfo.WeeklyRate;
    if (objPricingInfo.TotalChargedWeeks) {
      pricingObj.rentalInfo.data.weekly = {
        title: "Weekly Charge",
        unit: objPricingInfo.TotalChargedWeeks,
        charge: weeklyCharge,
        total: objPricingInfo.TotalChargedWeeks * weeklyCharge || 0,
      };
    }
    let weekendCharge =
      objPricingInfo.ParticipantType === "SHARER"
        ? objPricingInfo.SharerWeekendRate
        : objPricingInfo.WeekendRate;
    if (objPricingInfo.TotalChargedWeekendDays) {
      pricingObj.rentalInfo.data.WeekendDays = {
        title: "Weekend Charge",
        unit: objPricingInfo.TotalChargedWeekendDays,
        charge: weekendCharge,
        total: objPricingInfo.TotalChargedWeekendDays * weekendCharge || 0,
      };
    }
    let dailyCharge =
      objPricingInfo.ParticipantType === "SHARER"
        ? objPricingInfo.SharerDailyRate
        : objPricingInfo.DailyRate;
    if (objPricingInfo.TotalChargedWeekDays) {
      pricingObj.rentalInfo.data.daily = {
        title: "Daily Charge",
        unit: objPricingInfo.TotalChargedWeekDays,
        charge: dailyCharge,
        total: objPricingInfo.TotalChargedWeekDays * dailyCharge || 0,
      };
    }
    let hourlyCharge =
      objPricingInfo.ParticipantType === "SHARER"
        ? objPricingInfo.SharerHourlyRate
        : objPricingInfo.HourlyRate;
    if (objPricingInfo.TotalChargedHours) {
      pricingObj.rentalInfo.data.hourly = {
        title: "Hourly Charge",
        unit: objPricingInfo.TotalChargedHours,
        charge: hourlyCharge,
        total: objPricingInfo.TotalChargedHours * hourlyCharge || 0,
      };
    }
    let minutesCharge =
      objPricingInfo.ParticipantType === "SHARER"
        ? objPricingInfo.SharerMinuteRate
        : objPricingInfo.MinuteRate;
    if (objPricingInfo.TotalChargedMinutes) {
      pricingObj.rentalInfo.data.minute = {
        title: "Minute Charge",
        unit: objPricingInfo.TotalChargedMinutes,
        charge: minutesCharge,
        total: minutesCharge * objPricingInfo.SharerMinuteRate || 0,
      };
    }
    pricingObj.applyTaxAfterDiscount = objPricingInfo.ApplyTaxAfterDiscount;
    pricingObj.applySurchargeAfterDiscount =
      objPricingInfo.ApplySurchargeAfterDiscount;
    if (
      (objPricingInfo.MileageBasedPricingEnabled &&
        objPricingInfo.TotalPriceForMile) ||
      objPricingInfo.TotalExcessMileageCharges
    ) {
      // if (objPricingInfo.MilesAllowed != null) {
      //   pricingObj.rentalInfo.mileage.milesAllowed = {
      //     title: "Mileage",
      //     unit: objPricingInfo.MilesAllowed
      //   };
      // }
      if (objPricingInfo.OdometerIn) {
        pricingObj.rentalInfo.mileage.milesIn = {
          title: "Miles In",
          unit: objPricingInfo.OdometerIn || 0,
        };
      }
      if (objPricingInfo.OdometerOut) {
        pricingObj.rentalInfo.mileage.milesOut = {
          title: "Miles Out",
          unit: objPricingInfo.OdometerOut || 0,
        };
      }
      if (objPricingInfo.TotalMiles) {
        pricingObj.rentalInfo.mileage.milesUsed = {
          title: "Miles Used",
          unit: objPricingInfo.TotalMiles || 0,
        };
      }

      if (objPricingInfo.TotalExcessMileageCharges) {
        pricingObj.rentalInfo.mileage.excessMileage = {
          title: "Excess Mileage charge",
          charge: objPricingInfo.ExcessMileageCharge || 0,
          total: objPricingInfo.TotalExcessMileageCharges || 0,
        };
      }
      if (objPricingInfo.TotalPriceForMile) {
        pricingObj.rentalInfo.mileage.chargesForMiles = {
          title: "Mileage charge",
          charge: objPricingInfo.PricePerMile || 0,
          total: objPricingInfo.TotalPriceForMile || 0,
        };
      }
    }
    if (objPricingInfo.TotalUpgradeAmount) {
      pricingObj.rentalInfo.data.upgradeFee = {
        title: "Upgrade Charge",
        total: objPricingInfo.TotalUpgradeAmount || 0,
      };
    }
    pricingObj.rentalInfo.rentalTotal =
      objPricingInfo.ParticipantType === "SHARER"
        ? objPricingInfo.TotalRentalChargesSharerPricing
        : objPricingInfo.TotalRentalCharges; //*Need to check later */
    if (objPricingInfo.ParticipantType) {
      pricingObj.totalCharges.total =
        objPricingInfo.ParticipantType === "SHARER"
          ? objPricingInfo.TotalPaidSharerPricing
          : objPricingInfo.TotalPaid || 0;
    }
    //*Need to check later */
    /*End of Rental Info*/

    pricingObj.additionalChargeInfo = {
      unittype: objPricingInfo.AdditionalChargeRateType || "AMOUNT",
      charge: objPricingInfo.AdditionalCharge || 0,
      total:
        objPricingInfo.ParticipantType === "SHARER"
          ? objPricingInfo.TotalAdditionalChargesSP || 0
          : objPricingInfo.TotalAdditionalCharges || 0,
      title: objPricingInfo.AdditionalChargesDescription || "Additional Charge",
    };
    if (
      objPricingInfo.ShowSurchargeTax &&
      objPricingInfo.SurchargeTaxList &&
      objPricingInfo.SurchargeTaxList.length
    ) {
      pricingObj.surcharges = [];
      for (let i = 0; i < objPricingInfo.SurchargeTaxList.length; i++) {
        let item = objPricingInfo.SurchargeTaxList[i];
        if (item.Amount > 0) {
          let ele = {
            title: item.DisplayName || "Surcharge",
            unittype: item.AmountType,
            charge: item.Amount || 0,
            total:
              objPricingInfo.ParticipantType === "SHARER"
                ? item.TotalTaxAmountSp || 0
                : item.TotalTaxAmount || 0,
          };
          pricingObj.surcharges.push(ele);
        }
      }
    }
    if (
      objPricingInfo.ShowTax &&
      objPricingInfo.TaxList &&
      objPricingInfo.TaxList.length
    ) {
      pricingObj.taxes = [];
      for (let i = 0; i < objPricingInfo.TaxList.length; i++) {
        let item = objPricingInfo.TaxList[i];
        let ele = {
          title: item.DisplayName || "Tax",
          unittype: item.AmountType,
          charge: item.Amount || 0,
          total:
            objPricingInfo.ParticipantType === "SHARER"
              ? item.TotalTaxAmountSp || 0
              : item.TotalTaxAmount || 0,
        };
        pricingObj.taxes.push(ele);
      }
    }

    pricingObj.additionalDriverCharge = {
      unit: objPricingInfo.AdditionalDriverCount || 0,
      charge: objPricingInfo.AdditionalDriverFees || 0,
      total: objPricingInfo.TotalAdditionalDriverCharges || 0,
    };

    /**subTotalInfo Started*/
    if (objPricingInfo.TaxableUpsellOptions) {
      pricingObj.subTotalInfo.taxableAddOns = [];
      objPricingInfo.TaxableUpsellOptions.map((addon, index) => {
        let title = addon.DisplayText;
        let isOneTime = ["PERCENT","ONETIME"].includes(addon.UnitType.Key);
        if (addon.TotalUnit) {
          title =
            addon.Quantity && addon.Quantity > 1
              ? addon.Quantity + " x " + title + "  <b>" + addon.TotalUnit
              : title + " <b>" + (isOneTime ? "" : addon.TotalUnit);
          if (addon.UnitType) {
            let unittype = addon.UnitType.Key;
            if (unittype === "HOURLY") {
              title = title + " " + (addon.TotalUnit > 1 ? "hrs" : "hr");
            }
            if (unittype === "DAILY") {
              title = title + " " + (addon.TotalUnit > 1 ? "days" : "day");
            }
            if (unittype === "WEEKEND") {
              title =
                title + " " + (addon.TotalUnit > 1 ? "weekends" : "weekend");
            }
            if (unittype === "WEEKLY") {
              title = title + " " + (addon.TotalUnit > 1 ? "weeks" : "week");
            }
            if (unittype === "MONTHLY") {
              title = title + " " + (addon.TotalUnit > 1 ? "months" : "month");
            }
          }
          title = title + "@" + (addon.UnitType.Key === "PERCENT" ? parseFloat(addon.Rate).toFixed(2) + "%" : formatCurrency(addon.Rate || 0)) + "</b>";
        }
        let c = {
          id: addon.Id,
          title: title || "",
          unit: addon.TotalUnit || 0,
          charge: addon.Rate || 0,
          total: addon.TotalPrice || 0,
        };
        pricingObj.subTotalInfo.taxableAddOns.push(c);
      });
    }

    if (objPricingInfo.UpsellOptions) {
      pricingObj.subTotalInfo.addOns = [];
      objPricingInfo.UpsellOptions.map((addon, index) => {
        let title = addon.DisplayText;
        let isOneTime = ["PERCENT","ONETIME"].includes(addon.UnitType.Key);
        if (addon.TotalUnit) {
          title =
            addon.Quantity && addon.Quantity > 1
              ? addon.Quantity + " x " + title + "  <b>" + addon.TotalUnit
              : title + " <b>" + (isOneTime ? "" : addon.TotalUnit);
          if (addon.UnitType) {
            let unittype = addon.UnitType.Key;
            if (unittype === "HOURLY") {
              title = title + " " + (addon.TotalUnit > 1 ? "hrs" : "hr");
            }
            if (unittype === "DAILY") {
              title = title + " " + (addon.TotalUnit > 1 ? "days" : "day");
            }
            if (unittype === "WEEKEND") {
              title =
                title + " " + (addon.TotalUnit > 1 ? "weekends" : "weekend");
            }
            if (unittype === "WEEKLY") {
              title = title + " " + (addon.TotalUnit > 1 ? "weeks" : "week");
            }
            if (unittype === "MONTHLY") {
              title = title + " " + (addon.TotalUnit > 1 ? "months" : "month");
            }
          }
          title = title + "@" + (addon.UnitType.Key === "PERCENT" ? parseFloat(addon.Rate).toFixed(2) + "%" : formatCurrency(addon.Rate || 0)) + "</b>";
        }
        let c = {
          id: addon.Id,
          title: title,
          unit: addon.TotalUnit || 0,
          charge: addon.Rate || 0,
          total: addon.TotalPrice || 0,
        };
        pricingObj.subTotalInfo.addOns.push(c);
      });
    }

    let doNotShowrate =
      jsiMarketPlace &&
      objPricingInfo.JsiInsuranceOption &&
      objPricingInfo.JsiInsuranceOption === global.JsiInsuranceOption.JSI;

    if (objPricingInfo.DisplayDeductible && objPricingInfo.CdwSelected) {
      let title =
        objPricingInfo.CdwDescription +
        " - [Deductible-$" +
        (objPricingInfo.Deductible || 0) +
        "] ";
      if (doNotShowrate) {
        title += " provided via JustShareIt";
      } else {
        let crate = getCDWRate(objPricingInfo);
        let rates = getRatesTextForPricing(
          objPricingInfo,
          crate.MonthlyRate,
          crate.WeeklyRate,
          crate.DailyRate,
          crate.WeekendRate,
          crate.HourlyRate
        );
        title += " " + rates;
      }
      pricingObj.subTotalInfo.CDW = {
        title: title ? title : "Collision Damage Waiver",
        total: objPricingInfo.TotalDeductibleCost + objPricingInfo.CdwCost || 0,
        show: true,
        doNotShowRate: doNotShowrate,
      };
    }

    if (objPricingInfo.LiabilitySelected) {
      let title = objPricingInfo.LiabilityDescription;

      if (doNotShowrate) {
        title += " provided via JustShareIt";
      } else {
        let lrate = getLiabilityRate(objPricingInfo);
        let rates = getRatesTextForPricing(
          objPricingInfo,
          lrate.MonthlyRate,
          lrate.WeeklyRate,
          lrate.DailyRate,
          lrate.WeekendRate,
          lrate.HourlyRate
        );
        title += " " + rates;
      }
      pricingObj.subTotalInfo.liability = {
        title: title || "Liability Insurance",
        total: objPricingInfo.LiabilityInsuranceCost || 0,
        show: true,
        doNotShowRate: doNotShowrate,
      };
    }

    if (objPricingInfo.InsuranceSurchargeSelected) {
      var title = "";
      if (objPricingInfo.InsuranceAgeCode && objPricingInfo.InsuranceAgeMsg)
        title =
          objPricingInfo.InsuranceAgeCode +
          " - " +
          objPricingInfo.InsuranceAgeMsg;
      else title = objPricingInfo.InsuranceAgeMsg || "Age based Surcharge";
      if (doNotShowrate) {
        title += " provided via JustShareIt";
      } else {
        var irate = getInsuranceSurchargeRate(objPricingInfo);
        var rates = getRatesTextForPricing(
          objPricingInfo,
          irate.MonthlyRate,
          irate.WeeklyRate,
          irate.DailyRate,
          irate.WeekendRate,
          irate.HourlyRate
        );
        title += " " + rates;
      }
      pricingObj.subTotalInfo.ageBasedInsurance = {
        title: title,
        total: objPricingInfo.InsuranceAgeFees || 0,
        doNotShowRate: doNotShowrate,
      };
    }
    /**subTotalInfo Ended*/
    if (objPricingInfo.DiscountValue || objPricingInfo.DiscountCode) {
      let title = "Discount";
      if (objPricingInfo.DiscountReason) {
        title += " - " + objPricingInfo.DiscountReason;
      }
      pricingObj.discount = {
        title: title,
        total: objPricingInfo.DiscountValue,
        type: "discount",
        originaldata: {
          DiscountType: objPricingInfo.DiscountType,
          DiscountReason: objPricingInfo.DiscountReason,
          DiscountValue: objPricingInfo.DiscountValue,
          DiscountCode: objPricingInfo.DiscountCode,
        },
        removable: pageType === "EDIT" ? true : false,
      };
    }
    /**otherChargesInfo Started */
    if (objPricingInfo.ReservationFee) {
      pricingObj.otherChargesInfo.reservationProcessingFee = {
        title: "Reservation processing fee",
        total: objPricingInfo.ReservationFee || 0,
      };
    }
    if (objPricingInfo.FirstTimeProcessingFee) {
      pricingObj.otherChargesInfo.firstTimeProcessingFee = {
        title: "First time processing fee",
        total: objPricingInfo.FirstTimeProcessingFee || 0,
      };
    }
    if (objPricingInfo.TotalDamageCost) {
      pricingObj.otherChargesInfo.damageCharge = {
        title: "Damage Charge",
        total: objPricingInfo.TotalDamageCost || 0,
      };
    }
    if (objPricingInfo.TotalExtensionFee) {
      pricingObj.otherChargesInfo.extensionFee = {
        title: "Extension Fee",
        total: objPricingInfo.TotalExtensionFee || 0,
      };
    }
    if (objPricingInfo.CharityPrice) {
      pricingObj.otherChargesInfo.charity = {
        title: objPricingInfo.CharityText || "Charity",
        total: objPricingInfo.CharityPrice || 0,
        description: objPricingInfo.CharityDescription | "",
      };
    }
    /**otherChargesInfo Ended */

    if (objPricingInfo.FreeCreditApplied) {
      pricingObj.freeCredit = {
        title: "Free credit applied",
        total: objPricingInfo.FreeCreditApplied || 0,
      };
    }

    if (objPricingInfo.AppliedCredits) {
      pricingObj.credits = [];
      for (var i = 0; i < objPricingInfo.AppliedCredits.length; i++) {
        const creditObj = {
          id: objPricingInfo.AppliedCredits[i].ReservationCreditId || null,
          type: "CREDIT",
          lineItem: objPricingInfo.AppliedCredits[i].LineItem,
          title: objPricingInfo.AppliedCredits[i].UpsellOptionId
            ? objPricingInfo.AppliedCredits[i].UpsellOptionTypeDisplayText +
              (objPricingInfo.AppliedCredits[i].LineItem
                ? " - " + objPricingInfo.AppliedCredits[i].LineItem
                : "")
            : objPricingInfo.AppliedCredits[i].LineItem
              ? objPricingInfo.AppliedCredits[i].LineItem
              : "Credit",
          upsellOptionId: objPricingInfo.AppliedCredits[i].UpsellOptionId,
          upsellOptionDisplayText:
            objPricingInfo.AppliedCredits[i].UpsellOptionDisplayText,
          total: objPricingInfo.AppliedCredits[i].Amount,
          removable:
            pageType === "EDIT" ||
            (pageType === "END_RENTAL" &&
              !objPricingInfo.AppliedCredits[i].ReservationCreditId)
              ? true
              : false,
        };
        pricingObj.credits.push(creditObj);
      }
    }
    if (objPricingInfo.AppliedCharges) {
      pricingObj.miscellaneousCharges = [];
      for (var i = 0; i < objPricingInfo.AppliedCharges.length; i++) {
        const chargeObj = {
          id: objPricingInfo.AppliedCharges[i].ReservationCreditId || null,
          type: "CHARGE",
          lineItem: objPricingInfo.AppliedCharges[i].LineItem,
          title: objPricingInfo.AppliedCharges[i].UpsellOptionId
            ? objPricingInfo.AppliedCharges[i].UpsellOptionDisplayText +
              (objPricingInfo.AppliedCharges[i].LineItem
                ? " - " + objPricingInfo.AppliedCharges[i].LineItem
                : "")
            : objPricingInfo.AppliedCharges[i].LineItem,
          upsellOptionId: objPricingInfo.AppliedCharges[i].UpsellOptionId,
          upsellOptionDisplayText:
            objPricingInfo.AppliedCharges[i].UpsellOptionDisplayText,
          total: objPricingInfo.AppliedCharges[i].Amount,
          removable:
            pageType === "EDIT" ||
            (pageType === "END_RENTAL" &&
              !objPricingInfo.AppliedCharges[i].ReservationCreditId)
              ? true
              : false,
        };
        pricingObj.miscellaneousCharges.push(chargeObj);
      }
    }

    if (objPricingInfo.LateChargesApply && objPricingInfo.LateCharges) {
      pricingObj.otherChargesInfo.lateCharges = {
        title: "Late Charge",
        unit: 0,
        charge: 0,
        total: objPricingInfo.LateCharges || 0,
      };
    }

    if (objPricingInfo.TotalCancellationCharges) {
      pricingObj.cancellationChargesInfo.total =
        objPricingInfo.TotalCancellationCharges || 0;
    }

    if (objPricingInfo.TotalCancellationFee) {
      let titleExtention = "";
      if (objPricingInfo.CancelledByType) {
        titleExtention = " - By " + objPricingInfo.CancelledByType;
      } else {
        titleExtention = " - By Borrower";
      }
      pricingObj.cancellationFeeInfo = {
        title: objPricingInfo.CancellationCourtesyAmount
          ? "Cancellation Fee (" +
            formatCurrency(objPricingInfo.CancellationCourtesyAmount) +
            " courtesy waiver) " +
            titleExtention
          : "Cancellation Fee " + titleExtention,
        total: objPricingInfo.TotalCancellationFee || 0,
      };
    }

    if (objPricingInfo.CancellationCourtesyAmount) {
      pricingObj.cancellationCourtesyAmount =
        objPricingInfo.CancellationCourtesyAmount;
    }
    if (objPricingInfo.CancelledByType) {
      pricingObj.cancelledByType = objPricingInfo.CancelledByType;
    }

    if (objPricingInfo.PreAuthAmount) {
      pricingObj.preAuthAmount.total = objPricingInfo.PreAuthAmount || 0;
    }
    if (objPricingInfo.TotalAmountCaptured) {
      pricingObj.totalAmountCaptured = {
        title: '<span style="margin-left:4%;">Capture from Hold</span>',
        total: objPricingInfo.TotalAmountCaptured || 0,
      };
    }
    if (objPricingInfo.PreAuthReleaseAmount) {
      pricingObj.preAuthReleaseAmount = {
        title: '<span style="margin-left:4%;">Pre-authorization Release</span>',
        total: objPricingInfo.PreAuthReleaseAmount || 0,
      };
    }
    if (objPricingInfo.BalanceDue != null) {
      pricingObj.balanceDue.total = objPricingInfo.BalanceDue || 0;
    }
    /**Need to think */
    if (objPricingInfo.TotalPayments) {
      pricingObj.payments.total = objPricingInfo.TotalPayments;
    }

    if (objPricingInfo.TotalRefunds) {
      pricingObj.refunds.total = objPricingInfo.TotalRefunds
        ? objPricingInfo.TotalRefunds > 0
          ? Math.abs(-1 * objPricingInfo.TotalRefunds)
          : Math.abs(objPricingInfo.TotalRefunds)
        : 0;
    }
    if (objPricingInfo.TotalVoidedPayments) {
      pricingObj.voidedPayments.total = objPricingInfo.TotalVoidedPayments
        ? objPricingInfo.TotalVoidedPayments > 0
          ? Math.abs(-1 * objPricingInfo.TotalVoidedPayments)
          : Math.abs(objPricingInfo.TotalVoidedPayments)
        : 0;
    }
    if (objPricingInfo.TotalVoidedRefunds) {
      pricingObj.voidedRefunds.total =
        Math.abs(objPricingInfo.TotalVoidedRefunds) || 0;
    }
    if (objPricingInfo.TotalDeltaPayments) {
      pricingObj.deltaPayments.total = objPricingInfo.TotalDeltaPayments || 0;
    }
    if (objPricingInfo.TotalAmountPaid) {
      pricingObj.totalPaymentsWithCredits.total =
        objPricingInfo.TotalAmountPaid;
    }

    if (objPricingInfo.TotalExtensionCost) {
      var temp = "";
      if (objPricingInfo.TotalExtendedMins) {
        var dur = getTimeFromDuration(objPricingInfo.TotalExtendedMins);
        if (dur.Month) {
          temp += dur.Month + (dur.Month > 1 ? " months" : " month");
        }
        if (dur.Week) {
          if (temp) {
            temp += " ";
          }
          temp += dur.Week + (dur.Week > 1 ? " weeks" : " week");
        }
        if (dur.Day) {
          if (temp) {
            temp += " ";
          }
          temp += dur.Day + (dur.Day > 1 ? " days" : " day");
        }
        if (dur.Hour) {
          if (temp) {
            temp += " ";
          }
          temp += dur.Hour + (dur.Hour > 1 ? " hrs" : " hr");
        }
        if (dur.Minute) {
          if (temp) {
            temp += " ";
          }
          temp += dur.Minute + (dur.Minute > 1 ? " mins" : " min");
        }
        if (temp) {
          temp = " - upto " + temp;
        }
      }
      pricingObj.extensionPrice = {
        title:
          "* Extension (" +
          (objPricingInfo.ExtensionCount || 0) +
          ")" +
          temp +
          " - " +
          formatCurrency(objPricingInfo.TotalExtensionCost),
        total: objPricingInfo.TotalExtensionCost,
      };
    }

    pricingObj.priceUnit = objPricingInfo.RateUnit;
  }
  return pricingObj;
};
function getLiabilityRate(obj) {
  var monthlyRate =
    (obj.ParticipantType === "SHARER"
      ? obj.SharerMonthlyRate
      : obj.MonthlyRate) || 0;
  var weeklyRate =
    (obj.ParticipantType === "SHARER"
      ? obj.SharerWeeklyRate
      : obj.WeeklyRate) || 0;
  var dailyRate =
    (obj.ParticipantType === "SHARER" ? obj.SharerDailyRate : obj.DailyRate) ||
    0;
  var hourlyRate =
    (obj.ParticipantType === "SHARER"
      ? obj.SharerHourlyRate
      : obj.HourlyRate) || 0;

  var lmrate = obj.LiabilityMonthlyRate || 0,
    lwrate = obj.LiabilityWeeklyRate || 0,
    ldrate = obj.LiabilityDailyRate || 0,
    lhrate = obj.LiabilityHourlyRate || 0;

  if (
    lmrate &&
    obj.LiabilityMonthlyUnit &&
    obj.LiabilityMonthlyUnit === "PERCENT"
  ) {
    lmrate = monthlyRate ? monthlyRate * (lmrate / 100) : 0;
  }

  if (
    lwrate &&
    obj.LiabilityWeeklyUnit &&
    obj.LiabilityWeeklyUnit === "PERCENT"
  ) {
    lwrate = weeklyRate ? weeklyRate * (lwrate / 100) : 0;
  }

  if (ldrate && obj.LiabilityUnit && obj.LiabilityUnit === "PERCENT") {
    ldrate = dailyRate ? dailyRate * (ldrate / 100) : 0;
  }

  if (
    lhrate &&
    obj.LiabilityHourlyUnit &&
    obj.LiabilityHourlyUnit === "PERCENT"
  ) {
    lhrate = hourlyRate ? hourlyRate * (lhrate / 100) : 0;
  }

  var data = {
    HourlyRate: lhrate,
    DailyRate: ldrate,
    WeeklyRate: lwrate,
    MonthlyRate: lmrate,
  };

  return data;
}
function getCDWRate(obj) {
  let monthlyRate =
    (obj.ParticipantType === "SHARER"
      ? obj.SharerMonthlyRate
      : obj.MonthlyRate) || 0;
  let weeklyRate =
    (obj.ParticipantType === "SHARER"
      ? obj.SharerWeeklyRate
      : obj.WeeklyRate) || 0;
  let dailyRate =
    (obj.ParticipantType === "SHARER" ? obj.SharerDailyRate : obj.DailyRate) ||
    0;
  let hourlyRate =
    (obj.ParticipantType === "SHARER"
      ? obj.SharerHourlyRate
      : obj.HourlyRate) || 0;

  let cmrate = obj.TotalDeductibleMonthlyRate || 0,
    cwrate = obj.TotalDeductibleWeeklyRate || 0,
    cdrate = obj.TotalDeductibleDailyRate || 0,
    chrate = obj.TotalDeductibleHourlyRate || 0;

  if (
    cmrate &&
    obj.TotalDeductibleMonthlyUnit &&
    obj.TotalDeductibleMonthlyUnit === "PERCENT"
  ) {
    cmrate = monthlyRate ? monthlyRate * (cmrate / 100) : 0;
  }

  if (
    cwrate &&
    obj.TotalDeductibleWeeklyUnit &&
    obj.TotalDeductibleWeeklyUnit === "PERCENT"
  ) {
    cwrate = weeklyRate ? weeklyRate * (cwrate / 100) : 0;
  }

  if (
    cdrate &&
    obj.TotalDeductibleUnit &&
    obj.TotalDeductibleUnit === "PERCENT"
  ) {
    cdrate = dailyRate ? dailyRate * (cdrate / 100) : 0;
  }

  if (
    chrate &&
    obj.TotalDeductibleHourlyUnit &&
    obj.TotalDeductibleHourlyUnit === "PERCENT"
  ) {
    chrate = hourlyRate ? hourlyRate * (chrate / 100) : 0;
  }

  let cdwratedata = {
    HourlyRate: chrate,
    DailyRate: cdrate,
    WeeklyRate: cwrate,
    MonthlyRate: cmrate,
  };

  return cdwratedata;
}
function getInsuranceSurchargeRate(obj) {
  var monthlyRate =
    (obj.ParticipantType === "SHARER"
      ? obj.SharerMonthlyRate
      : obj.MonthlyRate) || 0;
  var weeklyRate =
    (obj.ParticipantType === "SHARER"
      ? obj.SharerWeeklyRate
      : obj.WeeklyRate) || 0;
  var dailyRate =
    (obj.ParticipantType === "SHARER" ? obj.SharerDailyRate : obj.DailyRate) ||
    0;
  var hourlyRate =
    (obj.ParticipantType === "SHARER"
      ? obj.SharerHourlyRate
      : obj.HourlyRate) || 0;
  var weekendRate =
    (obj.ParticipantType === "SHARER"
      ? obj.SharerWeekendRate
      : obj.WeekendRate) || 0;

  var imrate = obj.InsuranceSurchargeMonthlyRate || 0,
    iwrate = obj.InsuranceSurchargeWeeklyRate || 0,
    idrate = obj.InsuranceSurchargeDailyRate || 0,
    ihrate = obj.InsuranceSurchargeHourlyRate || 0;

  if (
    imrate &&
    obj.InsuranceSurchargeMonthlyUnit &&
    obj.InsuranceSurchargeMonthlyUnit === "PERCENT"
  ) {
    imrate = monthlyRate ? monthlyRate * (imrate / 100) : 0;
  }

  if (
    iwrate &&
    obj.InsuranceSurchargeWeeklyUnit &&
    obj.InsuranceSurchargeWeeklyUnit === "PERCENT"
  ) {
    iwrate = weeklyRate ? weeklyRate * (iwrate / 100) : 0;
  }

  if (
    idrate &&
    obj.InsuranceSurchargeUnit &&
    obj.InsuranceSurchargeUnit === "PERCENT"
  ) {
    idrate = dailyRate ? dailyRate * (idrate / 100) : 0;
  }

  if (
    ihrate &&
    obj.InsuranceSurchargeHourlyUnit &&
    obj.InsuranceSurchargeHourlyUnit === "PERCENT"
  ) {
    ihrate = hourlyRate ? hourlyRate * (ihrate / 100) : 0;
  }

  var data = {
    HourlyRate: ihrate,
    DailyRate: idrate,
    WeeklyRate: iwrate,
    MonthlyRate: imrate,
  };

  return data;
}
function getRatesTextForPricing(
  pricingObj,
  monthyRate,
  weeklyRate,
  dailyRate,
  weekendRate,
  hourlyRate
) {
  let rates = "";
  if (pricingObj.TotalChargedMonths) {
    let mtext = pricingObj.TotalChargedMonths === 1 ? "month" : "months";
    rates +=
      pricingObj.TotalChargedMonths +
      " " +
      mtext +
      "@" +
      formatCurrency(monthyRate || 0);
  }
  if (pricingObj.TotalChargedWeeks) {
    let wtext = pricingObj.TotalChargedWeeks === 1 ? "week" : "weeks";
    if (rates) {
      rates += ", ";
    }
    rates +=
      pricingObj.TotalChargedWeeks +
      " " +
      wtext +
      "@" +
      formatCurrency(weeklyRate || 0);
  }
  if (pricingObj.TotalChargedDays) {
    let dtext = pricingObj.TotalChargedDays === 1 ? "day" : "days";
    if (rates) {
      rates += ", ";
    }
    rates +=
      pricingObj.TotalChargedDays +
      " " +
      dtext +
      "@" +
      formatCurrency(dailyRate || 0);
  }

  if (pricingObj.TotalChargedHours) {
    let dtext = pricingObj.TotalChargedHours === 1 ? "hr" : "hrs";
    if (rates) {
      rates += ", ";
    }
    rates +=
      pricingObj.TotalChargedHours +
      " " +
      dtext +
      "@" +
      formatCurrency(hourlyRate || 0);
  }
  if (rates) {
    rates = "<b>" + rates + "</b>";
  }
  return rates;
}

import React, { useState, useEffect } from "react";
import xmlFormat from "xml-formatter";
import { isJson, isNullOrEmpty, isValidXML } from "../../../shared/utility";
import $ from "jquery";
import "../../../shared/jquery-plugins/simple-xml-viewer/simpleXML.js";
import "../../../shared/jquery-plugins/simple-xml-viewer/simpleXML.css";
import "../../../shared/jquery-plugins/json-viewer/jquery.json-viewer.js";
import "../../../shared/jquery-plugins/json-viewer/jquery.json-viewer.css";
import LinkButton from "../../../components/UI/button/link-btn";

export const RequestResponsePayload = (props) => {
  const { getCopyLinkHTML, getFormattedNonXMLPayload, xmlFormatProperties } = props;
  const integData = JSON.parse(JSON.stringify(props.integData));
  let isValidXMLRequestPayload = false;
  let isValidXMLResponsePayload = false;
  let formattedResPayloadData = {};
  let formattedReqPayloadData = {};
  const [reqPayloadDataToCopy, setReqPayloadDataToCopy] = useState(null);
  const [resPayloadDataToCopy, setResPayloadDataToCopy] = useState(null);
  const getPayloadInfo = (integDataNew) => {
    const isJsonReqPayload = isJson(integDataNew.RequestPayload);
    const isJsonResPayload = isJson(integDataNew.ResponsePayload);
    if (!isJsonReqPayload) {
      isValidXMLRequestPayload = isValidXML(integDataNew.RequestPayload);
      formattedReqPayloadData = getFormattedNonXMLPayload(integDataNew.RequestPayload, isValidXMLRequestPayload);
    }
    if (!isJsonResPayload) {
      isValidXMLResponsePayload = isValidXML(integDataNew.ResponsePayload);
      formattedResPayloadData = getFormattedNonXMLPayload(integDataNew.ResponsePayload, isValidXMLResponsePayload);
    }

    return {
      requestPayloadData: isJsonReqPayload
        ? JSON.parse(integDataNew.RequestPayload)
        : isValidXMLRequestPayload
          ? xmlFormat(integDataNew.RequestPayload, { ...xmlFormatProperties })
          : xmlFormat(formattedReqPayloadData.XMLRequestXML ? formattedReqPayloadData.XMLRequestXML : "", { ...xmlFormatProperties }),

      responsePayloadData: isJsonResPayload
        ? JSON.parse(integDataNew.ResponsePayload)
        : isValidXMLResponsePayload
          ? integDataNew.ResponsePayload? xmlFormat(xmlFormat(integDataNew.ResponsePayload), { ...xmlFormatProperties }):""
          : formattedResPayloadData.XMLRequestXML? xmlFormat(xmlFormat(formattedResPayloadData.XMLRequestXML ? formattedResPayloadData.XMLRequestXML : ""), { ...xmlFormatProperties }):"",

      isJsonReqPayload: isJsonReqPayload || false,
      isJsonResPayload: isJsonResPayload || false,
      responseLog: integDataNew.ResponseLog || null
    };
  };
  const payloadData = getPayloadInfo(integData);

  const getPayloadDataToDisplay = (
    payloadString,
    isRequestPayload,
    isJsonStr
  ) => {
    let payloadStringToCopy = isJsonStr
      ? JSON.stringify(payloadString, null, 2)
      : payloadString;
    isRequestPayload
      ? setReqPayloadDataToCopy(payloadStringToCopy)
      : setResPayloadDataToCopy(payloadStringToCopy);
    return payloadString;
  };

  const renderEmptyPayloadSection =(domID)=>{
    document.getElementById(domID).innerHTML =
    "<span class='red-color ml-3'> No Response Received" +
    (payloadData.responseLog
      ? ": Check Error / Response Log for details"
      : "") +
    "</span>";
  }

  useEffect(() => {
    $(document).ready(function () {
      if (!payloadData.isJsonReqPayload) {
        $("#requestDataMainContainer").simpleXML({
          xmlString: getPayloadDataToDisplay(payloadData.requestPayloadData, true),
        });
      } else {
        $("#requestDataMainContainer").jsonViewer(
          getPayloadDataToDisplay(payloadData.requestPayloadData, true, true),
          {
            collapsed: true,
            rootCollapsable: false,
          }
        );
      }
      if (!payloadData.isJsonResPayload) {
        if(!isNullOrEmpty(payloadData.responsePayloadData)){
          $("#responseDataContainer").simpleXML({
            xmlString: getPayloadDataToDisplay(payloadData.responsePayloadData),
          });
        }
      } else {
        $("#responseDataContainer").jsonViewer(
          getPayloadDataToDisplay(payloadData.responsePayloadData, false, true),
          {
            collapsed: true,
            rootCollapsable: false,
          }
        );
      }
      if (!payloadData.isJsonReqPayload) {
        expandCollapseAllData(true, 0, true);
      }
      if (isNullOrEmpty(payloadData.requestPayloadData)) {
        renderEmptyPayloadSection("requestDataMainContainer")
      }
      if (isNullOrEmpty(payloadData.responsePayloadData)) {
        renderEmptyPayloadSection("responseDataContainer")
      }
    });
  }, []);
  const expandCollapseAllData = (isCollapsed, indexLevel, isInitiate) => {
    if (payloadData.isJsonReqPayload) {
      $("#requestDataMainContainer").jsonViewer(
        payloadData.requestPayloadData,
        {
          collapsed: isCollapsed,
          rootCollapsable: !isInitiate,
        }
      );
    }
    if (payloadData.isJsonResPayload) {
      $("#responseDataContainer").jsonViewer(payloadData.responsePayloadData, {
        collapsed: isCollapsed,
        rootCollapsable: !isInitiate,
      });
    }
    if (!payloadData.isJsonReqPayload && !isNullOrEmpty(document.getElementById("requestDataMainContainer").innerHTML)) {
      $("#requestDataMainContainer .simpleXML-expanderHeader").each(function (
        index
      ) {
        let requestExpanderHeader = $(this).closest(
          ".simpleXML-expanderHeader"
        );
        if (!isCollapsed) {
          expandCollapseXMLData(requestExpanderHeader);
        } else {
          expandCollapseXMLData(requestExpanderHeader, indexLevel, index);
        }
      });
    }
    if (!payloadData.isJsonResPayload && !isNullOrEmpty(document.getElementById("responseDataContainer").innerHTML)) {
      $("#responseDataContainer .simpleXML-expanderHeader").each(function (
        index
      ) {
        let responseExpanderHeader = $(this).closest(
          ".simpleXML-expanderHeader"
        );
        if (!isCollapsed) {
          expandCollapseXMLData(responseExpanderHeader);
        } else {
          expandCollapseXMLData(responseExpanderHeader, indexLevel, index);
        }
      });
    }

    if (isNullOrEmpty(payloadData.requestPayloadData)) {
      renderEmptyPayloadSection("requestDataMainContainer")
    }
    if (isNullOrEmpty(payloadData.responsePayloadData)) {
      renderEmptyPayloadSection("responseDataContainer")
    }
  };

  const expandCollapseXMLData = (expanderHeader, indexLevel, index) => {
    const expander = expanderHeader.find(".simpleXML-expander");
    const content = expanderHeader.parent().find(".simpleXML-content").first();
    const collapsedText = expanderHeader
      .parent()
      .children(".simpleXML-collapsedText")
      .first();
    const closeExpander = expanderHeader
      .parent()
      .children(".simpleXML-expanderClose")
      .first();
    const expanderClass = "simpleXML-expander-expanded";
    const collapseClass = "simpleXML-expander-collapsed";
    if (indexLevel != index) {
      expander.removeClass(expanderClass).addClass(collapseClass);
      collapsedText.attr("style", "display: inline;");
      content.attr("style", "display: none;");
      closeExpander.attr("style", "display: none");
    } else {
      expander.addClass(expanderClass).removeClass(collapseClass);
      collapsedText.attr("style", "display: none;");
      content.attr("style", "");
      closeExpander.attr("style", "");
    }
  };
  return (
    <section
      className="grid-table expand-bg-color"
      id="requestResponsePayloadTable"
    >
      <div className="d-flex font-weight-bold pt-1">
        <div
          className="text-right pt-2 font-weight-bold"
          id="expand-collapse-id"
        >
          <div
            className="pl-4 pr-4"
            onClick={() => expandCollapseAllData(false)}
          >
            <LinkButton
              btnLabel="Expand All"
              containerClass="pseudo-link fs-2 font-weight-bold d-flex align-items-center"
              stackClass="fa-stack fs-08 vertical-align-top"
              faIconList={[
                "fa fa-square fa-stack-2x iconBeforeLink",
                "fa fa-sort-down fa-stack-1x whiteColor pr-1 fs-6 topr-0-3",
              ]}
            />
          </div>
          <div className="pl-4" onClick={() => expandCollapseAllData(true)}>
            <LinkButton
              btnLabel="Collapse All"
              containerClass="pseudo-link pr-2 fs-2 font-weight-bold d-flex align-items-center"
              stackClass="fa-stack fs-08 vertical-align-top"
              faIconList={[
                "fa fa-square fa-stack-2x iconBeforeLink",
                "fa fa-sort-up fa-stack-1x whiteColor pr-1 fs-6 top-0-3",
              ]}
            />
          </div>
        </div>
      </div>
      <div
        className="pl-6 mtr-2 tableScroll mr-2"
        style={{
          borderBottom: "none",
          fontSize: "11px",
          maxHeight: "40rem"
        }}
      >
        <div className="sectionLabel d-flex">
          {integData.RequestPayload
            ? getCopyLinkHTML(
                reqPayloadDataToCopy,
                "Request",
                "Copy To Clipboard",
                "text-image",
                "Payload has been copied to clipboard!"
              )
            : "Request"}
        </div>

        <div className="pb-3">
          <div id="requestDataMainContainer"></div>
        </div>
        <div className="sectionLabel pt-3 d-flex">
          {integData.ResponsePayload
            ? getCopyLinkHTML(
                resPayloadDataToCopy,
                "Response",
                "Copy To Clipboard",
                "text-image",
                "Payload has been copied to clipboard!"
              )
            : "Response"}
        </div>
        <div className="pb-3">
          <div id="responseDataContainer"></div>
        </div>
      </div>
    </section>
  );
};

export default RequestResponsePayload;
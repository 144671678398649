import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import store from "./client/store/store";
import { Provider } from "react-redux";
import { DEPLOYMENT_FOLDER } from "./config/config";
import App from "./client/App";
import * as serviceWorker from "./client/registerServiceWorker";
import UserConfirmation from "./client/components/Navigations/prevent-navigation/get-user-confirmation";
import { clearCache } from "./client/shared/utility/cache-utility";

clearCache();
ReactDOM.render(
  <BrowserRouter
    basename={DEPLOYMENT_FOLDER || ""}
    getUserConfirmation={(message, callback) =>
      UserConfirmation(message, callback)
    }
  >
    <Provider store={store}>
      <App />
    </Provider>
  </BrowserRouter>,
  document.getElementById("root")
);
serviceWorker.unregister();

import PageInfo from "../../../services/entities/models/PageInfo";
import { getMemberLabelByOrgRentalType } from "../../../shared/utility/system-utility";

export const getSearchComplaintsCtrls = () => {
  return {
    PageInfo: new PageInfo(null, null, "Name", "ASC"),
    isAdvancedSearch: false,
    Keyword: {
      id: "tbKeyword",
      elementType: "input",
      elementConfig: {
        name: "Keyword",
        type: "text",
      },
      validation: {
        required: false,
      },
      labelInput: {
        labeltext: "Keyword",
      },
      value: "",
      valid: false,
      touched: false,
      basicOrder: 1,
      advancedOrder: 1,
    },
    ActivityDate: {
      id: "dtpActivityDate",
      elementType: "datepicker",
      elementConfig: {
        name: "ActivityDate",
        type: "text",
      },
      validation: {
        required: false,
      },
      labelInput: {
        labeltext: "Activity Date",
      },
      value: "",
      valid: false,
      touched: false,
      basicOrder: 2,
      advancedOrder: 2,
    },

    CallChannel: {
      id: "ddlCallChannel",
      name: "CallChannel",
      elementType: "select",
      elementConfig: {
        name: "CallChannel",
        options: [
          { key: "", displayValue: "Select" },
          { key: "Phone", displayValue: "Phone" },
          { key: "Device", displayValue: "Device" },
          { key: "Website", displayValue: "Website" },
        ],
      },
      labelInput: {
        labeltext: "Call Channel",
      },
      value: "",
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      basicOrder: 3,
      advancedOrder: 3,
    },
    CallType: {
      id: "ddlCallType",
      name: "CallType",
      elementType: "select",
      elementConfig: {
        name: "CallType",
        options: [
          { key: "", displayValue: "Select" },
          { key: "Inbound", displayValue: "Inbound" },
          { key: "Outbound", displayValue: "Outbound" },
          { key: "Offline", displayValue: "Offline" },
        ],
      },
      labelInput: {
        labeltext: "Call Type",
      },
      value: "",
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      basicOrder: 4,
      advancedOrder: 4,
    },
    Priority: {
      id: "ddlPriority",
      name: "Priority",
      elementType: "select",
      elementConfig: {
        name: "Priority",
        options: [
          { key: "", displayValue: "Select" },
          { key: "High", displayValue: "High" },
          { key: "Medium", displayValue: "Medium" },
          { key: "Low", displayValue: "Low" },
        ],
      },
      labelInput: {
        labeltext: "Priority",
      },
      value: "",
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      basicOrder: 6,
      advancedOrder: 6,
    },
    Category: {
      id: "ddlCategory",
      name: "Category",
      elementType: "select",
      elementConfig: {
        name: "Category",
        options: [{ key: "", displayValue: "Select" }],
      },
      labelInput: {
        labeltext: "Category",
      },
      value: "",
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      basicOrder: 7,
      advancedOrder: 7,
    },
    Subcategory: {
      id: "ddlSubCategory",
      name: "SubCategory",
      elementType: "select",
      elementConfig: {
        name: "SubCategory",
        options: [{ key: "", displayValue: "Select" }],
      },
      labelInput: {
        labeltext: "Sub Category",
      },
      value: "",
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      basicOrder: 8,
      advancedOrder: 8,
    },
    Activity: {
      id: "ddlActivity",
      name: "Activity",
      elementType: "select",
      elementConfig: {
        name: "Activity",
        options: [{ key: "", displayValue: "Select" }],
      },
      labelInput: {
        labeltext: "Activity",
      },
      value: "",
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      basicOrder: 9,
      advancedOrder: 9,
    },
    ProblemType: {
      id: "ddlProblemType",
      name: "ProblemType",
      elementType: "select",
      elementConfig: {
        name: "ProblemType",
        options: [{ key: "", displayValue: "Select" }],
      },
      labelInput: {
        labeltext: "Problem Type",
      },
      value: "",
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      basicOrder: 0,
      advancedOrder: 11,
    },
    ProblemSubtype: {
      id: "ddlProblemSubtype",
      name: "ProblemSubtype",
      elementType: "select",
      elementConfig: {
        name: "ProblemSubtype",
        options: [{ key: "", displayValue: "Select" }],
      },
      labelInput: {
        labeltext: "Problem Subtype",
      },
      value: "",
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      basicOrder: 0,
      advancedOrder: 12,
    },
    Penalty1: {
      id: "ddlPenalty1",
      name: "Penalty1",
      elementType: "select",
      elementConfig: {
        name: "Penalty1",
        options: [
          { key: "", displayValue: "Select" },
          { key: "Yes", displayValue: "Yes" },
          { key: "No", displayValue: "No" },
        ],
      },
      labelInput: {
        labeltext: "Penalty",
      },
      value: "",
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      basicOrder: 0,
      advancedOrder: 13,
    },
    Penalty2: {
      id: "ddlPenalty2",
      name: "Penalty2",
      elementType: "select",
      elementConfig: {
        name: "Penalty2",
        options: [
          { key: "", displayValue: "Select" },
          { key: "Yes", displayValue: "Yes" },
          { key: "No", displayValue: "No" },
        ],
      },
      labelInput: {
        labeltext: "Penalty",
      },
      value: "",
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      basicOrder: 0,
      advancedOrder: 14,
    },
    Penalty3: {
      id: "ddlPenalty3",
      name: "Penalty3",
      elementType: "select",
      elementConfig: {
        name: "Penalty3",
        options: [
          { key: "", displayValue: "Select" },
          { key: "Yes", displayValue: "Yes" },
          { key: "No", displayValue: "No" },
        ],
      },
      labelInput: {
        labeltext: "Penalty",
      },
      value: "",
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      basicOrder: 0,
      advancedOrder: 16,
    },
    Penalty4: {
      id: "ddlPenalty4",
      name: "Penalty4",
      elementType: "select",
      elementConfig: {
        name: "Penalty4",
        options: [
          { key: "", displayValue: "Select" },
          { key: "Yes", displayValue: "Yes" },
          { key: "No", displayValue: "No" },
        ],
      },
      labelInput: {
        labeltext: "Penalty",
      },
      value: "",
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      basicOrder: 0,
      advancedOrder: 17,
    },
    Penalty5: {
      id: "ddlPenalty5",
      name: "Penalty5",
      elementType: "select",
      elementConfig: {
        name: "Penalty5",
        options: [
          { key: "", displayValue: "Select" },
          { key: "Yes", displayValue: "Yes" },
          { key: "No", displayValue: "No" },
        ],
      },
      labelInput: {
        labeltext: "Penalty",
      },
      value: "",
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      basicOrder: 0,
      advancedOrder: 18,
    },
    Penalty6: {
      id: "ddlPenalty6",
      name: "Penalty6",
      elementType: "select",
      elementConfig: {
        name: "Penalty6",
        options: [
          { key: "", displayValue: "Select" },
          { key: "Yes", displayValue: "Yes" },
          { key: "No", displayValue: "No" },
        ],
      },
      labelInput: {
        labeltext: "Penalty",
      },
      value: "",
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      basicOrder: 0,
      advancedOrder: 19,
    },

    btnResSearch: {
      id: "btnResSearch",
      elementType: "button",
      elementConfig: {
        type: "submit",
        value: "Search",
        name: "btnResSearch",
        className: "btn btn-primary search-btn cursor-default",
        disabled: "disabled",
      },
      basicOrder: 5,
      advancedOrder: 5,
    },
    btnResSearchReset: {
      id: "btnResSearchReset",
      elementType: "button",
      elementConfig: {
        type: "button",
        value: "Reset",
        name: "btnResSearchReset",
        className: "btn btn-primary search-btn cursor-default",
        disabled: "disabled",
      },
      basicOrder: 10,
      advancedOrder: 10,
    },
    empty: {
      id: "empty",
      elementType: "empty",
      elementConfig: {
        name: "",
        type: "empty",
      },
      basicOrder: 0,
      advancedOrder: 15,
    },
    empty1: {
      id: "empty",
      elementType: "empty",
      elementConfig: {
        name: "",
        type: "empty",
      },
      basicOrder: 0,
      advancedOrder: 20,
    },
  };
};

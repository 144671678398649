import * as actionTypes from "../actions/actionTypes";
import { reducerUpdateObject } from "./reducer.utility";
import { getDataFromStorage } from "../../shared/LocalStorage";
const initialState = {
  parentLoginResponse: getDataFromStorage("parent_login_response"),
  parentToken: localStorage.getItem("parent_token") || null,
  impersonateLoginResponse: null,
  exitImpersonateResponse: null,
  loading: false,
  error: null,
};
const doImpersonateLoginStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
const doImpersonateLoginSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    impersonateLoginResponse: action.impersonateLoginResponse,
    parentLoginResponse: action.parentLoginResponse,
    parentToken: action.parentToken,
    error: null,
    loading: false,
  });
};
const doImpersonateLoginFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false,
  });
};
const doImpersonateLoginReset = (state) => {
  return reducerUpdateObject(state, {
    parentLoginResponse: null,
    impersonateLoginResponse: null,
    parentToken: null,
  });
};

const doImpersonateExitStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
const doImpersonateExitSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    exitImpersonateResponse: action.exitImpersonateResponse,
    error: null,
    loading: false,
  });
};
const doImpersonateExitFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false,
  });
};
const doImpersonateExitReset = (state) => {
  return reducerUpdateObject(state, {
    exitImpersonateResponse: null,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.IMPERSONATE_LOGIN_START:
      return doImpersonateLoginStart(state, action);
    case actionTypes.IMPERSONATE_LOGIN_SUCCESS:
      return doImpersonateLoginSuccess(state, action);
    case actionTypes.IMPERSONATE_LOGIN_FAIL:
      return doImpersonateLoginFail(state, action);
    case actionTypes.IMPERSONATE_LOGIN_RESET:
      return doImpersonateLoginReset(state);

    case actionTypes.EXIT_IMPERSONATE_START:
      return doImpersonateExitStart(state, action);
    case actionTypes.EXIT_IMPERSONATE_SUCCESS:
      return doImpersonateExitSuccess(state, action);
    case actionTypes.EXIT_IMPERSONATE_FAIL:
      return doImpersonateExitFail(state, action);
    case actionTypes.EXIT_IMPERSONATE_RESET:
      return doImpersonateExitReset(state);

    default:
      return state;
  }
};
export default reducer;

import { showNotification } from "../../components/UI/Toaster/Toaster";
import { convertMsgResponse } from "../../containers/Reservation/fox-reservation/fox-res-helper";
import { COMMON_MSG } from "../GlobalVar";
import { getFormattedValidationMsg, isNullOrEmpty } from "../utility";
import { FoxApiMsgType } from "./enum-utility";

export const validatePhoneNumber = (phoneObj) => {
  let phcheck = /^\(?(\d{2})?\)?[- ]?[0-9]\d{1,6}[- ]?\d{2,8}/; // All
  if (phoneObj && typeof phoneObj === "object") {
    if (!phoneObj.number || !phoneObj.countryIso || !phoneObj.countryCode) {
      return false;
    }
    if (phoneObj.countryIso === "IN") {
      phcheck = /^\(?(\d{2})?\)?[- ]?[0-9]\d{3,6}[- ]?\d{5,8}/; //IN
    }
    if (phoneObj.countryIso === "US" || phoneObj.countryIso === "CA") {
      phcheck = /^\(?(\d{1})?\)?[- ]?\(?(\d{3})?\)?[- ]?(\d{3})[- ]?(\d{4})$/; //USA && CA
    }
    return phcheck.test(phoneObj.number);
  } else if (!phoneObj) return false;
  return phcheck.test(phoneObj);
};
export const getDefaultErrMsg = (data, allMsgStr, currentMsg) => {
  if (!data) {
    if (allMsgStr) {
      allMsgStr += ", " + currentMsg;
    } else {
      allMsgStr = currentMsg;
    }
  }
  return allMsgStr;
};
export const getErrMsgForAddrValidation = (data, allErrMsg) => {
  if (!allErrMsg) {
    allErrMsg = "";
  }
  allErrMsg = getDefaultErrMsg(data.street, allErrMsg, "Street1");
  allErrMsg = getDefaultErrMsg(data.country, allErrMsg, "country");
  allErrMsg = getDefaultErrMsg(
    data.province || data.provinceText,
    allErrMsg,
    "province"
  );
  allErrMsg = getDefaultErrMsg(data.city || data.cityText, allErrMsg, "city");
  allErrMsg = getDefaultErrMsg(
    data.postalCode || data.postalCodeText,
    allErrMsg,
    "postalCode"
  );
  return allErrMsg;
};
export const getErrMsgForDefaultCustomer = (
  data,
  allErrMsg,
  isLicenseRequired
) => {
  if (!allErrMsg) {
    allErrMsg = "";
  }
  allErrMsg = getDefaultErrMsg(data.email, allErrMsg, "Email");
  allErrMsg = getDefaultErrMsg(data.firstName, allErrMsg, "First Name");
  allErrMsg = getDefaultErrMsg(data.lastName, allErrMsg, "Last Name");
  allErrMsg = getDefaultErrMsg(data.dob, allErrMsg, "DOB");
  if (data.primaryAddress) {
    allErrMsg = getErrMsgForAddrValidation(data.primaryAddress, allErrMsg);
  } else {
    allErrMsg = getDefaultErrMsg(data.country, allErrMsg, "Address Field(s)");
  }
  if (!isNullOrEmpty(isLicenseRequired) && isLicenseRequired === false) {
    /**do nothing */
  } else if (
    isLicenseRequired ||
    (data.license &&
      (data.license.country ||
        data.license.province ||
        data.license.provinceText ||
        data.license.number ||
        data.license.expDate))
  ) {
    allErrMsg = getDefaultErrMsg(
      data.license.country,
      allErrMsg,
      COMMON_MSG.LicenseCountry
    );
    allErrMsg = getDefaultErrMsg(
      data.license.province || data.license.provinceText,
      allErrMsg,
      COMMON_MSG.LicenseState
    );
    allErrMsg = getDefaultErrMsg(
      data.license.number,
      allErrMsg,
      COMMON_MSG.LicenseNo
    );
    allErrMsg = getDefaultErrMsg(
      data.license.expDate,
      allErrMsg,
      COMMON_MSG.LicenseExpDate
    );
  }
  if (isNullOrEmpty(data.mobilePhone)) {
    if (allErrMsg) {
      allErrMsg += ", ";
    }
    allErrMsg += COMMON_MSG.MobilePhoneRequired;
  }
  if (data.mobilePhone) {
    if (!validatePhoneNumber(data.mobilePhone)) {
      allErrMsg = COMMON_MSG.InvalidMobileNo + ".";
    }
  }
  // if (data.homePhone && (data.homePhone.number || data.homePhone.countryIso || data.homePhone.countryCode)) {
  //     if (!validatePhoneNumber(data.homePhone)) {
  //       if (allErrMsg) {
  //         allErrMsg += " <br />";
  //       }
  //       allErrMsg += COMMON_MSG.InvalidHomeNo + ". ";
  //     }
  // }
  // if (data.workPhone && (data.workPhone.number || data.workPhone.countryIso || data.workPhone.countryCode)) {
  //     if (!validatePhoneNumber(data.workPhone)) {
  //         if (allErrMsg) {
  //         allErrMsg += " <br />";
  //         }
  //         allErrMsg += COMMON_MSG.InvalidWorkNo + ". ";
  //     }
  // }
  return !isNullOrEmpty(allErrMsg) ? getFormattedValidationMsg(allErrMsg) : "";
};
export const loadDefaultImageNewRes = (event, vImgUrl, defaultImg) => {
  if (vImgUrl) {
    const extension = vImgUrl.split(/[#?]/)[0].split(".").pop().trim();
    if (extension === "png") {
      const splitVal = vImgUrl.split("." + extension);
      event.target.src = splitVal[0] + ".jpg" + splitVal[1] + "." + extension;
      // const splitVal = vImgUrl.slice(0, -3);;
      // event.target.src = splitVal + "jpg";
    } else if (extension === "jpg") {
      const splitVal = vImgUrl.split("." + extension);
      event.target.src = splitVal[0] + ".png" + splitVal[1] + "." + extension;
      //const splitVal = vImgUrl.slice(0, -3);
      //event.target.src = splitVal + "png";
    } else {
      event.target.src = defaultImg;
    }
  }
};
export const isValidResponse = (response, showMsg) => {
  if (!response) {
    return false;
  }
  if (
    response?.message &&
    response?.message?.messageType === FoxApiMsgType.SUCCESS
  )
    return true;
  else {
    if (showMsg) showNotification(convertMsgResponse(response?.message));
    return false;
  }
};

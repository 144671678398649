import React, { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Moment from "moment";
import {
  updateLoggedInUserInfoReset,
  updateLoggedInUserInfo,
  updateLoginUserData,
} from "../../store/actions/index";
import Spinner from "../../components/UI/Spinner/Spinner";
import {
  updateObject,
  getBasicToastResponse,
  isNullOrEmpty,
  getFullName,
  getErrorToastData,
  getNormalFormattedPhoneNumber,
} from "../../shared/utility";
import { showNotification } from "../../components/UI/Toaster/Toaster";
import { NoUserPhoto } from "../../shared/AppImages";
import { Modal } from "reactstrap";
import { validateUserProfile } from "../../containers/Member/MemberHelper";
import AddressComponent from "../../components/common/address-component/address-component";
import { UserProfileViewModel } from "../../services/entities/view-models/user-profile-view-model";
import Phone from "../../services/entities/models/Phone";
import FileUploader from "../../components/image-uploader/file-uploader";
import FileUploaderModel from "../../components/image-uploader/file-uploader-ui-model";
import LinkButton from "../../components/UI/button/link-btn";
import { CommonLabel } from "../../components/UI/label/commmon-label";
import PreventTransitionPrompt from "../../components/Navigations/prevent-navigation/prevent-transition-prompt";
import { LabelAndValuePairControl } from "../../components/UI/Input/label-value-pair-control";
import CommonInputField from "../../components/UI/Input/common-input-field";
import { ADMIN_COMMON_CAPTION } from "../../shared/GlobalVar";

const UserProfile = (props) => {
  const storeLoading = useSelector((state) => state.auth.loading);
  const [loading, setLoading] = useState(storeLoading);
  const loggedInUserResponse = useSelector(
    (state) => state.auth.loggedInUserData
  );

  const [loggedInUserData, setLoggedInUserData] = useState(
    new UserProfileViewModel()
  );
  const dispatch = useDispatch();
  const updateLoggedInUserInfoResponse = useSelector(
    (state) => state.auth.updateLoggedInUserInfoResponse
  );

  const [inputTypeForOldPassword, setInputTypeForOldPassword] =
    useState("password");
  const [inputTypeForNewPassword, setInputTypeForNewPassword] =
    useState("password");
  const [viewImageUrl, setViewImageUrl] = useState(null);
  const [viewImageModal, setViewImageModal] = useState(null);
  const [userPhotoInfo, setUserPhotoInfo] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isValidateDone, setIsValidateDone] = useState(false);
  const [inputTypeForConfirmPassword, setInputTypeForConfirmPassword] =
    useState("password");
  const [isBlocking, setIsBlocking] = useState(false);

  useEffect(() => {
    if (!loggedInUserResponse) {
      return;
    }
    if (loggedInUserResponse.StatusCode === 0) {
      setLoggedInUserData(new UserProfileViewModel(loggedInUserResponse));

      loadUploaderData();
    }
  }, [loggedInUserResponse]);

  useEffect(() => {
    if (!updateLoggedInUserInfoResponse) {
      return;
    }
    showNotification(getBasicToastResponse(updateLoggedInUserInfoResponse));
    if (updateLoggedInUserInfoResponse.StatusCode === 0) {
      setLoggedInUserData(
        new UserProfileViewModel(updateLoggedInUserInfoResponse.LoginInfo)
      );
      setIsEditMode(false);
      setIsBlocking(false);
    }
    setLoading(false);

    dispatch(updateLoggedInUserInfoReset());
  }, [updateLoggedInUserInfoResponse]);

  const renderMobileAutoSueggestion = (type, phoneInfo) => {
    let updateCtrls = { ...loggedInUserData };
    if (!updateCtrls[type]) {
      updateCtrls[type] = new Phone();
    }
    updateCtrls[type].CountryCode = phoneInfo ? phoneInfo.value : null;
    updateCtrls[type].CountryIso = phoneInfo ? phoneInfo.countryIso : null;
    setLoggedInUserData(updateCtrls);
    setIsBlocking(true);
  };
  const inputPhoneChangeHandler = (event, controlName, elementType) => {
    let updateCtrls = { ...loggedInUserData };
    if (!updateCtrls[controlName]) {
      updateCtrls[controlName] = new Phone();
    }
    updateCtrls[controlName].Number = event.target.value;
    setLoggedInUserData(updateCtrls);
    setIsBlocking(true);
  };

  const inputAddressChangeHandler = (event, controlName, objectType) => {
    let addresObj = { ...loggedInUserData.PrimaryAddress };
    if (controlName === "Country") {
      addresObj.State = null;
      addresObj.City = null;
      addresObj.ZipCode = null;
      addresObj.StateText = null;
      addresObj.CityText = null;
      addresObj.ZipCodeText = null;
    } else if (controlName === "State") {
      addresObj.City = null;
      addresObj.ZipCode = null;
      addresObj.StateText = null;
      addresObj.CityText = null;
      addresObj.ZipCodeText = null;
    } else if (controlName === "City") {
      addresObj.ZipCode = null;
      addresObj.CityText = null;
      addresObj.ZipCodeText = null;
    }
    addresObj[controlName] = event.target.value;
    let updatedControls = { ...loggedInUserData };
    if (objectType === "USER") {
      updatedControls.PrimaryAddress = { ...addresObj };
    }
    setLoggedInUserData(updatedControls);
    setIsBlocking(true);
  };

  const resetLoader = (isLoading) => {
    setLoading(isLoading);
  };

  const enableEditMode = () => {
    setIsEditMode(true);
  };

  const onCancel = () => {
    setIsEditMode(false);
    setLoggedInUserData(new UserProfileViewModel(loggedInUserResponse));
    setIsBlocking(false);
  };

  const handleOnChange = (event, controlName, elementType) => {
    let value = null;
    if (event !== "Invalid date" && elementType === "datetimepicker") {
      value = event ? Moment(event).format("MM:DD:YYYY:HH:mm") : event;
    } else {
      value = event.target.value;
    }
    let updatedControls = updateObject(loggedInUserData, {
      [controlName]: value,
    });
    setLoggedInUserData(updatedControls);
    setIsBlocking(true);
  };

  const UpdateUserInformation = () => {
    let msg = "";
    const newReq = { ...loggedInUserData };
    const address = { ...loggedInUserData.PrimaryAddress };
    newReq.StreetAddress1 = address.Street1;
    newReq.StreetAddress2 = address.Street2;
    newReq.City = address.City;
    newReq.CityStr = address.CityText;
    newReq.State = address.State;
    newReq.StateStr = address.StateText;
    newReq.Country = address.Country;
    newReq.ZipCode = address.ZipCode;
    newReq.ZipCodeStr = address.ZipCodeText;
    newReq.MobilePhone =
      !newReq.MobilePhone || !newReq.MobilePhone.CountryCode
        ? null
        : newReq.MobilePhone;
    newReq.HomePhone =
      !newReq.HomePhone || !newReq.HomePhone.CountryCode
        ? null
        : newReq.HomePhone;
    newReq.WorkPhone =
      !newReq.WorkPhone || !newReq.WorkPhone.CountryCode
        ? null
        : newReq.WorkPhone;

    if (isNullOrEmpty(msg)) {
      msg = validateUserProfile(newReq);
    }

    if (msg) {
      showNotification(getErrorToastData(msg));
      setIsValidateDone(true);
    } else {
      setIsValidateDone(false);
      setLoading(true);
      delete newReq["ConfirmPassword"];
      delete newReq["ProfileImageUrl"];
      delete newReq["HintQuestionList"];
      delete newReq["PrimaryAddress"];
      delete newReq["Employee"];
      delete newReq["MemberId"];
      delete newReq["Employee"];

      dispatch(updateLoggedInUserInfoReset());
      dispatch(updateLoggedInUserInfo(loggedInUserResponse, newReq));
    }
  };

  const showImage = (event, post) => {
    setViewImageModal(true);
    setViewImageUrl(post.ProfileImageUrl || null);
  };

  const changeUserPhoto = (imageUrl) => {
    let userData = { ...loggedInUserData };
    userData.ProfileImageUrl = imageUrl;
    setLoggedInUserData(userData);
    updateLoggedInResponseWithProfileImageOnly(imageUrl);
  };

  const updateLoggedInResponseWithProfileImageOnly = (imageUrl) => {
    const newReq = JSON.parse(JSON.stringify(loggedInUserResponse));
    newReq.User.ProfileImageUrl = imageUrl;
    dispatch(updateLoginUserData(newReq));
  };

  const loadUploaderData = () => {
    const uploaderObj = new FileUploaderModel();
    uploaderObj.fileName = "";
    uploaderObj.photoCloseStyle = {
      fontSize: "2.5rem",
    };
    const uploaderList = [];
    uploaderList.push(uploaderObj);
    setUserPhotoInfo(uploaderList);
  };

  const renderCommmonLabel = (value, lblId, classNames) => {
    return (
      <CommonLabel
        className={
          classNames ||
          "col-lg-12 padding-none-edit-label text-truncate-block p-0"
        }
        value={value}
        lblId={lblId ? lblId : ""}
      />
    );
  };

  const renderPrimaryPhoneTypeRadioButtons = (id, value, labelCaption) => {
    return (
      <CommonInputField
        elementConfig={{
          id: id,
          type: "radio",
          name: "PrimaryPhone",
          value: value,
          checked: loggedInUserData.PrimaryPhone === value,
        }}
        otherConfig={{
          caption: labelCaption,
        }}
        nodeElement="radio"
        onChange={handleOnChange}
      />
    );
  };

  const width100 = "col-lg-12 p-0";
  const labelCaptionClass = "col-lg-4";
  const labelValueClass = "col-lg-8";
  const labelCaptionClassBold =
    labelCaptionClass + " col-form-label font-weight-bold position-relative";
  // const labelCaptionClassBoldRequired =
  //   labelCaptionClassBold + " col-form-label position-relative";
  const commonAttributesForEmployee = [false, "", null, true];
  const commonAttributesForPasswordField = [false, "", null, false, true];
  const renderLabelInputPair = (
    labelCaption,
    controlName,
    value,
    isRequired = false,
    nodeElement = "input",
    dropdownItem = null,
    viewOnly = false,
    isEyeIconRequired = false,
    inputTypeForPassword = null,
    isDateField = false,
    isDisableAfterCurrentDate = null
  ) => {
    const isPhoneNumber = ["MobilePhone", "WorkPhone", "HomePhone"].includes(
      controlName
    );

    return (
      <LabelAndValuePairControl
        elementConfig={{
          name: controlName,
          value: value,
          type: inputTypeForPassword,
        }}
        otherConfig={{
          caption: labelCaption,
          viewOnly: viewOnly,
          isRequired: isRequired,
          dropdownItem: nodeElement === "select" ? dropdownItem : [],
          value: value,
          dateFormat: "D",
          phoneNumberDisplayValue: isPhoneNumber
            ? getNormalFormattedPhoneNumber(value)
            : "",
          phoneNumberParentClasses: isPhoneNumber
            ? "memberInput memberInput-phone"
            : "",
          isEyeIconRequired: isEyeIconRequired,
          isDateField: isDateField,
          isDisableAfterCurrentDate: isDisableAfterCurrentDate,
        }}
        isEditMode={isEditMode}
        onChange={handleOnChange}
        onChangePhone={inputPhoneChangeHandler}
        renderMobileAutoSueggestion={renderMobileAutoSueggestion}
        toggleInputTypeForPasswordField={
          controlName === "OldPassword"
            ? setInputTypeForOldPassword
            : controlName === "Password"
            ? setInputTypeForNewPassword
            : controlName === "PasswordConfirm"
            ? setInputTypeForConfirmPassword
            : ""
        }
        parentRowClass={`form-group row  ${
          isEditMode && isRequired ? "required" : ""
        }`}
        labelClasses={labelCaptionClassBold + " text-left"}
        valueClasses={labelValueClass + " text-left"}
        nodeElement={nodeElement}
        isValidateDone={isValidateDone}
      />
    );
  };

  return (
    <Fragment>
      <PreventTransitionPrompt when={isBlocking} />
      {loading ? <Spinner /> : ""}
      <div className="row p-3" id="userProfile">
        <div className="form-header row col-lg-12">
          <h4 className="text-uppercase mr-auto">
            <i className="fas fa-user" />
            User Profile
          </h4>
          {!isEditMode ? (
            <LinkButton
              btnLabel="Edit"
              containerClass="pseudo-link pr-1 fs-2 font-weight-bold mr-5 d-flex align-items-center"
              stackClass="fa-stack fs-08 vertical-align-top"
              faIconList={[
                "fa fa-circle fa-stack-2x iconBeforeLink",
                "fa fa-pencil-alt fa-stack-1x whiteColor pr-1",
              ]}
              clicked={enableEditMode}
            />
          ) : (
            ""
          )}
        </div>

        <Fragment>
          <div className="col-lg-2">
            <div className="profile-card-3">
              <div className="card">
                <FileUploader
                  memberId={
                    loggedInUserData.MemberId ? loggedInUserData.MemberId : ""
                  }
                  uploaderType="User-Photo"
                  photoData={loggedInUserData.ProfileImageUrl || NoUserPhoto}
                  uploaderData={userPhotoInfo}
                  isUploadBtnRequired={true}
                  isNameRequird={false}
                  isOnlyImg={true}
                  changeFileTitle="Change Image"
                  loadUploaderFileData={() => {}}
                  confirmBtnClick={changeUserPhoto}
                />
                <hr />
                <div className="card-body text-center pt-0 pb-5">
                  <div className={`${width100}`}>
                    <div className="form-group row">
                      <label
                        className={`${width100} font-weight-bold text-left`}
                      >
                        {ADMIN_COMMON_CAPTION.CUSTOMER_ID}
                      </label>
                      <label className={`${width100} text-left`}>
                        {loggedInUserData.MemberId
                          ? loggedInUserData.MembershipId + " / " + loggedInUserData.MemberId
                          : "--"}
                      </label>
                    </div>
                    <div className="form-group row pt-3">
                      <label
                        className={`${width100} font-weight-bold text-left`}
                      >
                        Name
                      </label>
                      <label className={`${width100} text-left`}>
                        {getFullName(
                          loggedInUserData.FirstName,
                          loggedInUserData.LastName,
                          loggedInUserData.MiddleName
                        )}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-10">
            <div className={isEditMode ? "row background-edit" : "row"}>
              <div className="col-lg-6">
                <h4 className="form-header text-uppercase">
                  <i className="fas fa-user-circle" />
                  Personal Info
                </h4>
                <div className="FormRow">
                  {renderLabelInputPair(
                    "Email/Username",
                    "EmailAddress",
                    loggedInUserData.EmailAddress,
                    true
                  )}
                  {renderLabelInputPair(
                    "First Name",
                    "FirstName",
                    loggedInUserData.FirstName,
                    true
                  )}
                  {renderLabelInputPair(
                    "Middle Name",
                    "MiddleName",
                    loggedInUserData.MiddleName
                  )}
                  {renderLabelInputPair(
                    "Last Name",
                    "LastName",
                    loggedInUserData.LastName,
                    true
                  )}
                  {renderLabelInputPair(
                    "Date of Birth",
                    "DateOfBirth",
                    loggedInUserData.DateOfBirth,
                    true,
                    "datetime",
                    null,
                    false,
                    null,
                    null,
                    true,
                    true
                  )}
                  {renderLabelInputPair(
                    "Mobile Phone",
                    "MobilePhone",
                    loggedInUserData.MobilePhone,
                    true,
                    "phonenumber"
                  )}
                  {renderLabelInputPair(
                    "Work Phone",
                    "WorkPhone",
                    loggedInUserData.WorkPhone,
                    false,
                    "phonenumber"
                  )}
                  {renderLabelInputPair(
                    "Home Phone",
                    "HomePhone",
                    loggedInUserData.HomePhone,
                    false,
                    "phonenumber"
                  )}
                  <div
                    className={`form-group row  ${
                      isEditMode ? "required" : ""
                    }`}
                  >
                    <label className={labelCaptionClassBold}>
                      Primary Phone Type
                    </label>
                    <div className={labelValueClass}>
                      {isEditMode ? (
                        <Fragment>
                          <div className="memberInput">
                            {renderPrimaryPhoneTypeRadioButtons(
                              "rbtnMobile",
                              "CELL",
                              "Mobile"
                            )}
                            {renderPrimaryPhoneTypeRadioButtons(
                              "rbtnWork",
                              "WORK",
                              "Work"
                            )}
                            {renderPrimaryPhoneTypeRadioButtons(
                              "rbtnHome",
                              "HOME",
                              "Home"
                            )}
                          </div>
                        </Fragment>
                      ) : (
                        renderCommmonLabel(
                          loggedInUserData.PrimaryPhone,
                          "lblPrimaryPhone"
                        )
                      )}
                    </div>
                  </div>
                </div>

                <div className="FormRow">
                  <h4 className="form-header text-uppercase text-left">
                    <i className="fas fa-address-book" />
                    Address
                  </h4>

                  <AddressComponent
                    isAstericsRequired={true}
                    type="USER"
                    key="primary-Address-Component"
                    onChangeMethod={inputAddressChangeHandler}
                    address={{ ...loggedInUserData.PrimaryAddress }}
                    resetLoader={resetLoader}
                    sowAsterics={true}
                    isNoneEditableMode={!isEditMode}
                    rightLblClassName={`${width100} padding-none-edit-label text-truncate-block`}
                    leftLblClassName={labelCaptionClassBold}
                    inputContainerClassName={labelValueClass}
                    isValidateDone={isValidateDone}
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <h4 className="form-header text-uppercase text-left">
                  <i className="fas fa-key" />
                  Update Password and Security Information
                </h4>

                <div className="FormRow">
                  {renderLabelInputPair(
                    "Hint Question",
                    "HintQuestion",
                    loggedInUserData.HintQuestion,
                    true,
                    "select",
                    loggedInUserData.HintQuestionList,
                    false
                  )}
                  {renderLabelInputPair(
                    "Hint Answer",
                    "HintAnswer",
                    loggedInUserData.HintAnswer,
                    true
                  )}
                  {renderLabelInputPair(
                    "Old Password",
                    "OldPassword",
                    loggedInUserData.OldPassword,
                    ...commonAttributesForPasswordField,
                    inputTypeForOldPassword
                  )}

                  {renderLabelInputPair(
                    "New Password",
                    "Password",
                    loggedInUserData.Password,
                    ...commonAttributesForPasswordField,
                    inputTypeForNewPassword
                  )}
                  {renderLabelInputPair(
                    "Confirm Password",
                    "PasswordConfirm",
                    loggedInUserData.PasswordConfirm,
                    ...commonAttributesForPasswordField,
                    inputTypeForConfirmPassword
                  )}
                </div>

                {!isNullOrEmpty(loggedInUserData.Employee.EmployeeNo) ? (
                  <div className="FormRow">
                    <h4 className="form-header text-uppercase text-left">
                      <i className="fas fa-user-tie" />
                      Employee Info
                    </h4>
                    {renderLabelInputPair(
                      "Employee #",
                      null,
                      loggedInUserData.Employee.EmployeeNo,
                      ...commonAttributesForEmployee
                    )}
                    {renderLabelInputPair(
                      "Badge #",
                      null,
                      loggedInUserData.Employee.Badge,
                      ...commonAttributesForEmployee
                    )}
                    {renderLabelInputPair(
                      "Title",
                      null,
                      loggedInUserData.Employee.Title,
                      ...commonAttributesForEmployee
                    )}
                    {renderLabelInputPair(
                      "Department",
                      null,
                      loggedInUserData.Employee.Department,
                      ...commonAttributesForEmployee
                    )}
                    {renderLabelInputPair(
                      "Location",
                      null,
                      loggedInUserData.Employee.LocationName,
                      ...commonAttributesForEmployee
                    )}
                    {renderLabelInputPair(
                      "Company",
                      null,
                      loggedInUserData.Employee.CompanyName,
                      ...commonAttributesForEmployee
                    )}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            {isEditMode ? (
              <div className="form-footer pb-0" style={{ textAlign: "center" }}>
                <button
                  type="button"
                  name="btnEditProfile"
                  className="btn btn-primary"
                  onClick={() => UpdateUserInformation()}
                >
                  <i className="fa fa-check-square-o" />
                  Save
                </button>
                <button
                  type="button"
                  name="btnCancel"
                  className="btn btn-secondary ml-2"
                  onClick={() => onCancel()}
                >
                  Cancel
                </button>
              </div>
            ) : (
              ""
            )}
          </div>
        </Fragment>
      </div>
      <Modal
        isOpen={viewImageModal}
        toggle={() => {
          setViewImageModal(false);
        }}
      >
        <img
          alt=""
          src={viewImageUrl}
          height="400px"
          width="500px"
          onClick={(event) => showImage(event)}
        />
      </Modal>
    </Fragment>
  );
};

export default UserProfile;

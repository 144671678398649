import React, { useCallback } from "react";
import { createTooltip } from "../../shared/utility/tooltip-utility";
import CopyToClipboardComponent from "react-copy-to-clipboard";
import { showNotification } from "../UI/Toaster/Toaster";
import { getBasicToastResponse } from "../../shared/utility";

const CopyToClipboard = (props) => {
  const { parentClass, customParentCSS } = props;
  const defaultParentClass = "c-clipboard d-flex cursor-pointer";
  const onCopy = useCallback(() => {
    setTimeout(() => {
      showNotification(
        getBasicToastResponse({
          StatusMsg:
            props.successMsg || "Data has been copied to clipboard!",
          StatusCode: 0,
        })
      );
    }, props.delayTime || 500);
  }, []);

  const renderLabelHtml = (linkCaption) => {
    return (
      <span
        className={props.labelClass || "cursor-pointer"}
        style={props.customLabelCSS || {}}
      >
        {linkCaption || "Copy to clipboard"}
      </span>
    );
  };

  const getCopyToClipboardLink = (copyLinkType) => {
    switch (copyLinkType) {
      case "button":
        return (
          <button className={props.buttonClass} style={customParentCSS || {}}>
            <i className={props.iconClass} />
            {props.linkCaption}
          </button>
        );
      case "icon":
        return (
          <div
            className={parentClass || defaultParentClass}
            style={customParentCSS || {}}
          >
            <i className={props.iconClass} />
          </div>
        );
      case "icon-text":
        return (
          <div
            className={parentClass || defaultParentClass}
            style={customParentCSS || {}}
          >
            <i className={props.iconClass} />
            {renderLabelHtml(props.linkCaption)}
          </div>
        );
      case "text-icon":
        return (
          <div
            className={parentClass || defaultParentClass}
            style={customParentCSS || {}}
          >
            {renderLabelHtml(props.linkCaption)}
            <i className={props.iconClass} />
          </div>
        );
      case "text-image":
        return (
          <div
            className={parentClass || defaultParentClass}
            style={customParentCSS || {}}
          >
            {renderLabelHtml(props.linkCaption)}
            <img
              src={props.imageURL}
              className={props.imageClass}
              style={props.customImgCSS || {}}
            />
          </div>
        );
      case "image-text":
        return (
          <div
            className={parentClass || defaultParentClass}
            style={customParentCSS || {}}
          >
            <img
              src={props.imageURL}
              className={props.imageClass}
              style={props.customImgCSS || {}}
            />
            {renderLabelHtml(props.linkCaption)}
          </div>
        );
      default:
        return renderLabelHtml(props.linkCaption);
    }
  };
  return props.value
    ? createTooltip(
        <CopyToClipboardComponent onCopy={onCopy} text={props.value}>
          {getCopyToClipboardLink(props.copyLinkType)}
        </CopyToClipboardComponent>,
        props.toolTipText || "Copy to clipboard",
        { forceShow: true, isIcon: true }
      )
    : "";
};

export default CopyToClipboard;

import eventEmitter from "event-emitter";
import React, { Component } from "react";
const toasterEmitter = new eventEmitter();
export const showNotification = (toastMsg) => {
  toasterEmitter.emit("notification", toastMsg);
};
class Toaster extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toastMsg: "",
      toastType: "SUCCESS",
      showing: false,
      hideProgressBar: true,
      newestOnTop: false,
      autoClose: this.props.timeToStay || "5000",
    };
    toasterEmitter.on("notification", (toastMsg) => {
      this.showToaster(toastMsg);
    });
  }
  showToaster = (toastMsg) => {
    this.setState(
      {
        toastMsg,
        showing: true,
        autoClose: toastMsg.timeToStay || "5000",
      },
      () => {
        this.timeout = setTimeout(() => {
          this.setState({
            showing: false,
          });
        }, this.state.autoClose);
      }
    );
  };

  closeToast(e) {
    this.setState({
      showing: false,
    });
  }
  getToastClassbyToastType = (type, position) => {
    let tostClass = {};
    let toastClassName = "lobibox-notify animated-fast fadeInDown notify-mini";
    let positionClassName = position || "top center";
    switch (type) {
      case "INFO":
        tostClass.toastClassName = toastClassName + " lobibox-notify-info";
        tostClass.positionClassName = positionClassName;
        tostClass.iconClassName = "fa fa-info-circle";
        break;
      case "SUCCESS":
        tostClass.toastClassName = toastClassName + " lobibox-notify-success";
        tostClass.positionClassName = positionClassName;
        tostClass.iconClassName = "fa fa-check-circle";
        break;
      case "WARNING":
        tostClass.toastClassName = toastClassName + " lobibox-notify-warning";
        tostClass.positionClassName = positionClassName;
        tostClass.iconClassName = "fa fa-exclamation-circle";
        break;
      case "ERROR":
        tostClass.toastClassName = toastClassName + " lobibox-notify-error";
        tostClass.positionClassName = positionClassName;
        tostClass.iconClassName = "fa fa-times-circle";
        break;
      default:
        break;
    }
    return tostClass;
  };
  render() {
    const { showing, toastMsg } = this.state;
    let toastClassName = this.getToastClassbyToastType(toastMsg.toastType);
    const toastHtml = (
      <div
        className="lobibox-notify-wrapper v_top center"
        style={toastMsg.style}
      >
        <div className={toastClassName.toastClassName}>
          <div className="lobibox-notify-icon-wrapper">
            <div className="lobibox-notify-icon">
              <div>
                <div className="icon-el">
                  <i className={toastClassName.iconClassName} />
                </div>
              </div>
            </div>
          </div>
          <div className="lobibox-notify-body">
            <div className="lobibox-notify-msg">{toastMsg.msg}</div>
          </div>
          <span
            className="lobibox-close"
            onClick={(e) => this.closeToast(this)}
          >
            ×
          </span>
        </div>
      </div>
    );
    return showing ? toastHtml : "";
  }
}
export default Toaster;

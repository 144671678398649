import React from 'react';

function Dialog(props) {
    const {
        closeModal,
        modalTitle,
        modalContentClass = "no-cursor",
        modalHeaderClass = "",
        modalBodyClass = "",
        iconHeaderClass = "",
        children
    } = props;
    return (
        <div className={`custom-modal-content ${modalContentClass}`} >
            <div className={`custom-modal-header ${modalHeaderClass}`}>
                <div className="custom-modal-title">
                    <i className={`${iconHeaderClass || "fas fa-cogs"} pr-2`}></i>
                    {modalTitle}
                </div>
                <div className="custom-modal-close">
                    <i className="fa fa-times" onClick={() => closeModal()}></i>
                </div>
            </div>
            <hr />
            <div className={`custom-modal-body ${modalBodyClass}`}>
                {children}
            </div>
        </div >
    )
}

export default Dialog
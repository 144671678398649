import React, { useState, useEffect, Fragment } from "react";
import {
  loadFilterCtrls,
  getDefaultFilterStates,
  getAllFilterStates,
} from "./filter-helper";
import { TOAST_MSG } from "../../shared/GlobalVar";
import { showNotification } from "../UI/Toaster/Toaster";
import SearchFilterModal from "./searc-filter-modal";

export const SearchFilter = (props) => {
  const [showFilterModal, setSearchFilterModal] = useState(false);
  const [filterCtrl, setFilterCtrl] = useState(loadFilterCtrls(props.pageName));
  useEffect(() => {
    updateSearchFilter();
  }, []);
  useEffect(() => {
    setFilterCtrl(loadFilterCtrls(props.pageName));
  }, [props.url]);
  useEffect(() => {
    updateSearchFilter();
  }, [props.reservationStates]);

  const updateSearchFilter = () => {
    let selectAllFilterctrls = [...filterCtrl];
    const reservationStates = props.reservationStates
      ? props.reservationStates.split(",")
      : null;
    if (reservationStates) {
      selectAllFilterctrls.map((filterInfo, filterIndex) => {
        Object.keys(filterInfo.FilterStates).map((key, index) => {
          let isFound = false;
          reservationStates.map((item, i) => {
            if (item === key) {
              isFound = true;
              return isFound;
            }
          });
          selectAllFilterctrls[filterIndex]["FilterStates"][
            key
          ].elementConfig.checked = isFound;
        });
      });
      setFilterCtrl(selectAllFilterctrls);
    } else {
      selectAllFilterctrls.map((filterInfo, filterIndex) => {
        Object.keys(filterInfo.FilterStates).map((key, index) => {
          selectAllFilterctrls[filterIndex]["FilterStates"][
            key
          ].elementConfig.checked = false;
        });
      });
      setFilterCtrl(selectAllFilterctrls);
    }
  };
  const showSearchFilterModal = () => {
    setSearchFilterModal(true);
  };
  const closeSearchFilterModal = () => {
    setSearchFilterModal(false);
  };
  const applyFilterFromModal = (reservationStates) => {
    setSearchFilterModal(false);
    props.applySearchFilter(reservationStates);
  };
  const selectAll = () => {
    props.applySearchFilter(getAllFilterStates(props.url));
  };
  const resetAll = () => {
    props.applySearchFilter(getDefaultFilterStates(props.url));
  };
  const inputChangedHandler = (event, value) => {
    let reservationStates = props.reservationStates || null;
    if (event.target.checked || props.singleEnabledStates) {
      if (reservationStates) {
        if (props.singleEnabledStates) {
          if (!reservationStates.split(",").includes("CANCELLED")) {
            reservationStates = reservationStates + ",CANCELLED";
          }
          const msg =
            '"Cancelled" filter cannot be turned off when "Date Cancelled" is selected';

          var toastMsg = {
            msg: msg,
            toastType: TOAST_MSG.WARNING,
          };
          showNotification(toastMsg);
        } else {
          reservationStates = reservationStates + "," + value;
        }
      } else {
        reservationStates = value;
      }
      props.applySearchFilter(reservationStates);
    } else {
      let newResStates = "";
      if (reservationStates) {
        reservationStates.split(",").map((item, i) => {
          if (item !== value) {
            if (newResStates) {
              newResStates = newResStates + "," + item;
            } else {
              newResStates = item;
            }
          }
        });
        props.applySearchFilter(newResStates);
      }
    }
  };
  const renderFilterHtml = () => {
    const titleOnDisabledFilterMode = props.singleEnabledStates ? 'Unavailable when "Date Cancelled" is selected' : "";
    const filterList = [...filterCtrl];
    const allFilterList = filterList.map((filterInfo, filterIndex) => {
      return (
        <section
          key={"filterContainer" + filterIndex}
          className="searchFilterContainer"
        >
          {filterIndex < 1 ? <h1>Filter Results By</h1> : <h1>&nbsp;</h1>}
          {Object.keys(filterInfo.FilterStates).map((key, index) => {
            const filter = filterInfo.FilterStates[key];
            return (
              <div className="filterContainer" key={key + index}>
                <div className="icheck-primary">
                  <input
                    type="checkbox"
                    disabled={
                      props.singleEnabledStates &&
                      props.singleEnabledStates !== filter.elementConfig.value
                        ? true
                        : false
                    }
                    id={filter.id}
                    name={filter.elementConfig.name}
                    checked={filter.elementConfig.checked}
                    onChange={(event) =>
                      inputChangedHandler(event, filter.elementConfig.value)
                    }
                  />
                  <label
                    htmlFor={filter.id}
                    className={`${filter.labelInput.className}`}
                  >
                    <span>{filter.labelInput.labeltext}</span>
                  </label>
                </div>
              </div>
            );
          })}
          {filterList.length == 1 || filterList.length - 1 === filterIndex ? (
            <div className={`filter-link pl-2 ${props.singleEnabledStates ? "cursor-default disabled" : ""}`}>
              <span onClick={!props.singleEnabledStates && showSearchFilterModal} title={titleOnDisabledFilterMode}>
                Multi Select
              </span>
              <span className={`pl-2`} title={titleOnDisabledFilterMode} onClick={!props.singleEnabledStates && selectAll}>
                Select All
              </span>
              <span className={`pl-2`} title={titleOnDisabledFilterMode} onClick={!props.singleEnabledStates && resetAll}>
                Reset
              </span>
            </div>
          ) : (
            ""
          )}
        </section>
      );
    });
    return allFilterList;
  };
  return (
    <Fragment>
      {renderFilterHtml()}
      {showFilterModal ? (
        <SearchFilterModal
          showModal={showFilterModal}
          closeModal={closeSearchFilterModal}
          pageName={props.pageName}
          reservationStates={props.reservationStates}
          url={props.url}
          applyFilterFromModal={applyFilterFromModal}
        />
      ) : (
        ""
      )}
    </Fragment>
  );
};
export default SearchFilter;

import React from 'react'
import { getDateTimeFormatString } from '../../../components/common/common-helper'
import CommonTitle from '../component/common-title'
import { convertFoxApiDateToStr } from '../fox-reservation/fox-res-helper'

function LocationCard(props) {
    const { title, locIcon, imgIcon, locName, date } = props
    return (
        <div className='res-loc-card-container'>
            <div className='res-loc-card'>
                <div className='loc-left-icon'>
                    {imgIcon ? <img
                        src={imgIcon}
                        // height={iconSize.height}
                        // width={iconSize.width}
                        alt={title}
                    />
                        : locIcon && <i className={locIcon} aria-hidden="true" />}
                </div>
                <div className='loc-main-container'>
                    <label>{title}</label>
                    {locName ? <CommonTitle
                        title={locName}
                        classNames="common-title"
                        iconClass="fa fa-info-circle"
                        withIcon={locName && true}
                    />
                        : "--"}
                    <label>{convertFoxApiDateToStr(date,
                        getDateTimeFormatString(true, false, true),
                        "")}</label>
                </div>
            </div>
        </div>
    )
}

export default LocationCard
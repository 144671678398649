import PageInfo from "../models/PageInfo";

const FeedbackSearchRequest = function() {
  this.MemberId = null;
  this.ReservationId = null;
  this.FeedbackCategory = null;
  this.DeviceValue = null;
  this.MemberAssetId = null;
  this.PageInfo = new PageInfo();
};
export default FeedbackSearchRequest;

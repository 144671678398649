import React, { useEffect, useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactHtmlParser from "react-html-parser";
import {
  getOrgRolesData,
  getOrgRolesDataReset,
  addOrgRolesData,
  addOrgRolesDataReset,
  editOrgRolesData,
  editOrgRolesDataReset,
  deleteOrgRolesData,
  deleteOrgRolesDataReset,
} from "../../../store/actions/index";
import Spinner from "../../../components/UI/Spinner/Spinner";
import PermissionName from "../../../shared/Permissions";
import {
  hasPermission,
  getBasicToastResponse,
  toTitleCase,
  updateObject,
  isNullOrEmpty,
} from "../../../shared/utility";
import { showNotification } from "../../../components/UI/Toaster/Toaster";
import {
  RoleSettingsViewModel,
  RolesModel,
} from "../../../services/entities/view-models/settings-view-models";
import RoleUpdateModal from "./modal/role-setting-modal";
import NoRecordFoundComponent from "../../../components/common/pages/no-record-found";
import { InheritedLogo } from "../../../shared/AppImages";
import { ModalType,  OK_LBL} from "../../../shared/GlobalVar";
import LinkButton from "../../../components/UI/button/link-btn";
import PermissionstoRolesModal from "./modal/permission-to-roles-modal";
import ConfirmModal from "../../../components/common/modal/confirm-modal";
import ActionIcon from "../../../components/UI/icon/action-icon";
import { createTooltip } from "../../../shared/utility/tooltip-utility";

const RoleSetting = (props) => {
  const loading = useSelector((state) => state.rolesPermissionsReducer.loading);
  const currentOrgUnitId = useSelector(
    (state) => state.auth.loggedInUserData.CurrentOrgUnitId
  );
  const orgRolesDataResponse = useSelector(
    (state) => state.rolesPermissionsReducer.orgRolesDataResponse
  );
  const addOrgRolesDataResponse = useSelector(
    (state) => state.rolesPermissionsReducer.addOrgRolesDataResponse
  );
  const editOrgRolesDataResponse = useSelector(
    (state) => state.rolesPermissionsReducer.editOrgRolesDataResponse
  );
  const deleteOrgRolesDataResponse = useSelector(
    (state) => state.rolesPermissionsReducer.deleteOrgRolesDataResponse
  );
  const [cbAllCalssName, setCbAllCalssName] = useState("fa-null");
  const [rolesIds, setRolesIds] = useState([]);
  const [checkboxHeaderTooltip, setCheckboxHeaderTooltip] = useState(
    "Select All"
  );
  const [
    showAssignPermissionToRoleModal,
    setShowAssignPermissionToRoleModal,
  ] = useState(false);
  const [showRoleModal, setShowRoleModal] = useState(false);
  const [showDeleteRoleModal, setShowDeleteRoleModal] = useState(false);
  const [currentModalType, setCurrentModalType] = useState("");
  const [msgForDeleteModal, setMsgForDeleteModal] = useState("");
  const [isRoleDeletable, setIsRoleDeletable] = useState(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [addNewRoleResponseData, setAddNewRoleResponseData] = useState(null);

  const dispatch = useDispatch();
  const query = props.location
    ? new URLSearchParams(props.location.search)
    : null;

  const selectedOrgLocationId =
    props.otherData && props.otherData.selectedOrgLocationId
      ? props.otherData.selectedOrgLocationId
      : query
      ? query.get("orgId")
      : currentOrgUnitId;

  const [roleSettingsData, setRoleSettingsData] = useState(
    new RoleSettingsViewModel()
  );

  const [selectedRoleEntity, setSelectedRoleEntity] = useState(
    new RolesModel()
  );
  const hasEditRolePermission = hasPermission(PermissionName.EDIT_ROLE);
  const hasAssignRolePermission = hasPermission(
    PermissionName.ASSIGN_ROLE_PERMISSION
  );
  const hasAddRolePermission = hasPermission(PermissionName.ADD_ROLE);
  const hasDeleteRolePermission = hasPermission(PermissionName.DELETE_ROLE);

  useEffect(() => {
    getAllRoles();
  }, [selectedOrgLocationId]);

  useEffect(() => {
    if (!orgRolesDataResponse) {
      return;
    }

    if (orgRolesDataResponse.StatusCode === 0) {
      setRoleSettingsData(new RoleSettingsViewModel(orgRolesDataResponse));
      setCbAllCalssName("fa-null");
    }
  }, [orgRolesDataResponse]);

  useEffect(() => {
    if (!editOrgRolesDataResponse) {
      return;
    }
    showNotification(getBasicToastResponse(editOrgRolesDataResponse));
    if (editOrgRolesDataResponse.StatusCode === 0) {
      reloadRoleData();
    }
    dispatch(editOrgRolesDataReset());
  }, [editOrgRolesDataResponse]);

  useEffect(() => {
    if (!deleteOrgRolesDataResponse) {
      return;
    }
    showNotification(getBasicToastResponse(deleteOrgRolesDataResponse));
    if (deleteOrgRolesDataResponse.StatusCode === 0) {
      reloadRoleData();
      setIsRoleDeletable(null);
    }
    dispatch(deleteOrgRolesDataReset());
  }, [deleteOrgRolesDataResponse]);

  useEffect(() => {
    if (!addOrgRolesDataResponse) {
      return;
    }
    if (currentModalType === ModalType.COPY_ROLE_MODAL) {
      showNotification(getBasicToastResponse(addOrgRolesDataResponse));
      setAddNewRoleResponseData(null);
    }
    if (addOrgRolesDataResponse.StatusCode === 0) {
      reloadRoleData();
      if (currentModalType === ModalType.ADD_ROLE_MODAL) {
        setAddNewRoleResponseData(addOrgRolesDataResponse);
        setShowConfirmModal(true);
      }
    }

    dispatch(addOrgRolesDataReset());
  }, [addOrgRolesDataResponse]);

  const reloadRoleData = () => {
    closeRoleModal();
    getAllRoles();
  };

  const getAllRoles = () => {
    const req = {};
    req.SelectedChildOrgUnitId = selectedOrgLocationId;
    req.RoleIds = null;
    req.LocationId = selectedOrgLocationId;
    req.AdditionalRoleInfo = true;
    dispatch(getOrgRolesDataReset());
    dispatch(getOrgRolesData(req));
  };

  const openRoleModal = (modalType, entity) => {
    const propertiesToCopy = ["Code", "Name", "Description"];
    setCurrentModalType(modalType);
    let entityNew = entity ? { ...entity } : new RolesModel();

    entityNew.ChannelType = "CALL_CENTER";
    entityNew.LocationId = selectedOrgLocationId;
    if (modalType === ModalType.COPY_ROLE_MODAL) {
      Object.keys(entityNew).map((property) => {
        if (propertiesToCopy.includes(property)) {
          entityNew = updateObject(entityNew, {
            [property]:
              property === "Description"
                ? "Copy of " + entityNew[property]
                : "COPY_" + entityNew[property],
          });
        }
      });
    }
    setSelectedRoleEntity(entityNew);
    setShowRoleModal(true);
  };

  const openDeleteConfirmModal = (entity) => {
    if (entity) {
      let msg = "";
      const selectedRoleIds = getSelectedRoleIds(entity);
      let deletableRoleName = "";
      let nonDeletableRoleName = "";
      roleSettingsData.Roles.map((data, index) => {
        if (selectedRoleIds.includes(data.RoleId)) {
          if (data.UserCount > 0) {
            nonDeletableRoleName = nonDeletableRoleName
              ? nonDeletableRoleName + ", " + data.Name
              : data.Name;
          } else {
            deletableRoleName = deletableRoleName
              ? deletableRoleName + ", " + data.Name
              : data.Name;
          }
        }
      });
      if (!isNullOrEmpty(nonDeletableRoleName)) {
        msg = `The "${nonDeletableRoleName}" role is in use and cannot be deleted. Please remove personnel assigned to the role and try again.`;
      } else {
        msg = `Are you sure you want to delete the Role "${deletableRoleName}" ?`;
        setIsRoleDeletable(true);
      }

      if (selectedRoleIds.length > 0) {
        setMsgForDeleteModal(msg);
        setSelectedRoleEntity(entity);
        setShowDeleteRoleModal(true);
      }
    }
  };

  const getSelectedRoleIds = (rowData) => {
    let roleSettingsDataNew = JSON.parse(JSON.stringify(roleSettingsData));
    let rolesIdsNew = [];
    if (cbAllCalssName !== "fa-null") {
      roleSettingsDataNew.Roles.map((data, index) => {
        if (data.Checked) {
          rolesIdsNew.push(data.RoleId);
        }
      });
    } else {
      rolesIdsNew.push(rowData.RoleId);
    }
    setRolesIds(rolesIdsNew);
    return rolesIdsNew;
  };

  const openAssignPermissionToRoleModal = (selectedData) => {
    if (selectedData) {
      getSelectedRoleIds(selectedData);
    } else {
      getSelectedRoleIds(addNewRoleResponseData);
    }
    setShowAssignPermissionToRoleModal(true);
    closeConfirmModal();
  };

  const closeRoleModal = () => {
    setShowDeleteRoleModal(false);
    setShowAssignPermissionToRoleModal(false);
    setShowRoleModal(false);
    setSelectedRoleEntity(new RolesModel());
  };

  const closeDeleteRoleConfirmModal = () => {
    setShowDeleteRoleModal(false);
    setSelectedRoleEntity(new RolesModel());
    setIsRoleDeletable(null);
  };

  const closeConfirmModal = () => {
    setShowConfirmModal(false);
  };

  const addUpdateRoleEntity = (updateEntity, modalType) => {
    let updateEntityNew = { ...updateEntity };
    if (updateEntityNew.hasOwnProperty("Checked")) {
      delete updateEntityNew["Checked"];
    }
    updateRoleData(updateEntityNew, modalType);
  };

  const updateRoleData = (req, modalType) => {
    const reqObj = {};
    reqObj.Role = {
      ...req,
    };
    if (modalType === ModalType.EDIT_ROLE_MODAL) {
      dispatch(editOrgRolesDataReset());
      dispatch(editOrgRolesData(reqObj));
    } else if (
      modalType === ModalType.ADD_ROLE_MODAL ||
      modalType === ModalType.COPY_ROLE_MODAL
    ) {
      if (modalType === ModalType.COPY_ROLE_MODAL) {
        reqObj.CopyRoleId = req.RoleId;
      }
      delete reqObj.Role["Inherited"];
      delete reqObj.Role["PermissionCount"];
      delete reqObj.Role["Status"];
      delete reqObj.Role["UserCount"];
      dispatch(addOrgRolesDataReset());
      dispatch(addOrgRolesData(reqObj));
    }
  };

  const deleteRole = () => {
    const reqObj = {};
    reqObj.RoleIds = [...rolesIds];
    dispatch(deleteOrgRolesDataReset());
    dispatch(deleteOrgRolesData(reqObj));
  };

  const selectAllRows = () => {
    let roleSettingsDataNew = JSON.parse(JSON.stringify(roleSettingsData));
    roleSettingsDataNew.Roles.map((data, index) => {
      data.Checked = cbAllCalssName === "fa-check" ? false : true;
    });
    let cbAllCalssNameNew =
      cbAllCalssName === "fa-check" ? "fa-null" : "fa-check";
    setRoleSettingsData(roleSettingsDataNew);

    setCbAllCalssName(cbAllCalssNameNew);
    setCheckboxHeaderTooltip(
      cbAllCalssNameNew === "fa-check" ? "Deselect All" : "Select All"
    );
  };

  const selectSingleRows = (event, columnData) => {
    let roleSettingsDataNew = JSON.parse(JSON.stringify(roleSettingsData));
    let cbAllCalssNameNew = "";
    let checkedValue = 0;
    roleSettingsDataNew.Roles.map((data, index) => {
      if (data.RoleId === columnData.RoleId) {
        data.Checked = event.target.checked;
      }
      if (data.Checked) {
        checkedValue++;
      }
    });
    if (checkedValue === 0) {
      cbAllCalssNameNew = "fa-null";
    } else if (checkedValue === roleSettingsDataNew.Roles.length) {
      cbAllCalssNameNew = "fa-check";
    } else {
      cbAllCalssNameNew = "fa-minus";
    }
    setRoleSettingsData(roleSettingsDataNew);
    setCbAllCalssName(cbAllCalssNameNew);
    setCheckboxHeaderTooltip(
      cbAllCalssNameNew === "fa-check" ? "Deselect All" : "Select All"
    );
  };

  const renderMainLayout = () => {
    return (
      <Fragment>
        <div
          className="col-lg-12 mb-2  font-weight-bold  d-flex"
          style={{ height: "2.5rem" }}
        >
          <label className="mr-auto fs-2">Roles: </label>
          {hasAddRolePermission ? (
            <LinkButton
              btnLabel="Add Role"
              containerClass="pseudo-link pr-1 fs-2 font-weight-bold"
              iconClass="fas fa-plus-circle iconBeforeLink"
              clicked={() => openRoleModal(ModalType.ADD_ROLE_MODAL)}
            />
          ) : (
            ""
          )}
        </div>
        <div
          className="col-lg-12 tableScroll bb-0 table-fixed-head pr-0 table-container"
          style={{ maxHeight: "60rem" }}
        >
          <table className="table table-striped" id="rolesTable">
            <thead className="thead-primary">
              <tr>
                <th className="column__header text-center cb-container">
                  <div
                    className="checkBoxRolesPermissionsAll rolesAllCheckBox"
                    onClick={() => selectAllRows()}
                    id="rolesAllCheckBoxId"
                  >
                    {createTooltip(
                      <i
                        className={`fa checkBoxRolesPermisisonsFont ${cbAllCalssName}`}
                        aria-hidden="true"
                        style={{ marginRight: "0", marginTop: "0.2rem" }}
                      >
                        &nbsp;
                      </i>,
                      checkboxHeaderTooltip,
                      {
                        forceShow: true,
                        ttcClass: "tooltipContainer-max width-max-content",
                        considerParent: true,
                        isIcon: true,
                      }
                    )}
                  </div>
                </th>
                <th>Role Name</th>
                <th>Description</th>
                <th>Visible to SuperAdmin Only</th>
                <th>User Count</th>
                <th>Permissions</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>{renderRoleList()}</tbody>
          </table>
        </div>
      </Fragment>
    );
  };
  const renderRoleList = () => {
    if (roleSettingsData.Roles.length > 0) {
      const totalSelectedItem = roleSettingsData.Roles.filter(
        (item) => item.Checked === true
      ).length;
      return roleSettingsData.Roles.map((entity) => {
        let disableAssignPermission,
          disableEditRole,
          disableDeleteRole,
          disableCopyRole,
          displayInherited = entity.Inherited;

        if (displayInherited) {
          disableAssignPermission = disableEditRole = disableDeleteRole = true;
        } else {
          disableAssignPermission = !hasAssignRolePermission;
          disableEditRole = !hasEditRolePermission;
          disableDeleteRole = !hasDeleteRolePermission;
          disableCopyRole = !hasAddRolePermission;
        }

        if (cbAllCalssName === "fa-minus" && !entity.Checked) {
          disableAssignPermission = disableEditRole = disableDeleteRole = disableCopyRole = true;
        } else if (cbAllCalssName === "fa-check" || totalSelectedItem > 1) {
          disableEditRole = disableCopyRole = true;
        }

        return (
          <tr key={entity.RoleId}>
            <td>
              <div
                className="icheck-primary roles-cb"
                onChange={(event) => selectSingleRows(event, entity)}
              >
                <input
                  type="checkbox"
                  name={"cb" + entity.RoleId}
                  id={"cb" + entity.RoleId}
                  checked={entity.Checked}
                />
                <label htmlFor={"cb" + entity.RoleId} />
              </div>
            </td>
            <td className="text-left" id={"name_" + entity.RoleId}>
              {createTooltip(entity.Name || "--", entity.Name)}
            </td>
            <td className="text-left" id={"displayText_" + entity.RoleId}>
              {entity.Description}
              {createTooltip(
                <img
                  src={InheritedLogo}
                  alt="Inherited"
                  className={`card-img-top ${
                    displayInherited ? "" : "displayNone"
                  }`}
                  style={{
                    height: "1.2rem",
                    width: "1.2rem",
                    marginLeft: "0.5rem",
                  }}
                  id={"displayInherited_" + entity.RoleId}
                />,
                "Inherited",
                { forceShow: true }
              )}
            </td>
            <td>{toTitleCase(String(entity.VisibleToSuperAdminOnly))}</td>

            <td>{entity.UserCount || 0}</td>
            <td>{entity.PermissionCount || 0}</td>

            <td>
              {!displayInherited && hasAssignRolePermission ? (
                <Fragment>
                  <ActionIcon
                    iconId={"assignRoleId_" + entity.RoleId}
                    className="fas fa-user fs-3 pl-1 pr-2"
                    fontColor="green-color"
                    actionFunction={openAssignPermissionToRoleModal}
                    disabled={disableAssignPermission}
                    value={
                      disableAssignPermission
                        ? ""
                        : "Assign Permissions to Roles"
                    }
                    param1={entity}
                  />
                </Fragment>
              ) : (
                <ActionIcon className="fas fa-user fs-3 pl-1 pr-2 visibility-hidden" />
              )}
              <ActionIcon
                iconId={"editId_" + entity.RoleId}
                className="fas fa-pencil-alt fs-3 pl-1 pr-2"
                fontColor="blue-color"
                actionFunction={openRoleModal}
                disabled={disableEditRole}
                value={
                  displayInherited
                    ? "Inherited from Primary Location. It is not editable."
                    : hasEditRolePermission
                    ? disableEditRole
                      ? ""
                      : "Edit"
                    : "You do not have permission to Edit"
                }
                param1={ModalType.EDIT_ROLE_MODAL}
                param2={entity}
                forceShowTooltip={true}
              />
              <ActionIcon
                iconId={"deleteId_" + entity.RoleId}
                className="fas fa-times fs-3 pl-1 pr-2"
                fontColor="red-color"
                actionFunction={openDeleteConfirmModal}
                disabled={disableDeleteRole}
                value={
                  displayInherited
                    ? "Inherited from Primary Location. It is not deletable."
                    : hasDeleteRolePermission
                    ? disableDeleteRole
                      ? ""
                      : "Delete"
                    : "You do not have permission to Delete"
                }
                param1={entity}
                forceShowTooltip={true}
              />

              <ActionIcon
                iconId={"copyId_" + entity.RoleId}
                className="fas fa-copy fs-3 pl-1 pr-2"
                fontColor="blue-color"
                actionFunction={openRoleModal}
                disabled={disableCopyRole}
                value={
                  hasAddRolePermission
                    ? disableCopyRole
                      ? ""
                      : "Copy"
                    : "You do not have permission to Copy"
                }
                param1={ModalType.COPY_ROLE_MODAL}
                param2={entity}
                forceShowTooltip={true}
              />
            </td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colSpan="15">
            <NoRecordFoundComponent
              className="border-none"
              innerClassName="no-setting-record-found"
              searchMsg="No Record Found"
              heights="5rem"
            />
          </td>
        </tr>
      );
    }
  };

  return (
    <div className="col-lg-12 pt-3 pl-0">
      <div className="card">
        <div className="card-body">
          <div className="form-header row col-lg-12 p-0">
            <h4 className="text-uppercase mr-auto icon-display-none">
              <i className="fas fa-cogs"></i>
              Role Settings
            </h4>
          </div>
          {renderMainLayout()}
        </div>
        <div style={{ height: "2.5rem" }}>&nbsp;</div>
      </div>
      {loading ? <Spinner /> : ""}
      {showRoleModal ? (
        <RoleUpdateModal
          showModal={showRoleModal}
          closeModal={closeRoleModal}
          selectedEntity={selectedRoleEntity}
          addUpdateRoleEntity={addUpdateRoleEntity}
          modalType={currentModalType}
        />
      ) : (
        ""
      )}
      {showDeleteRoleModal ? (
        <ConfirmModal
          body={msgForDeleteModal}
          showModal={showDeleteRoleModal}
          closeModal={closeDeleteRoleConfirmModal}
          doConfirmFunctionality={() =>
            !isRoleDeletable ? closeDeleteRoleConfirmModal() : deleteRole()
          }
          yesBtnLabel={!isRoleDeletable ? OK_LBL : "Yes"}
          noBtnLabel={!isRoleDeletable ? "Cancel" : "No"}
        />
      ) : (
        ""
      )}
      {showAssignPermissionToRoleModal ? (
        <PermissionstoRolesModal
          roleIds={rolesIds}
          isModalOpen={showAssignPermissionToRoleModal}
          closeModal={closeRoleModal}
          callBack={getAllRoles}
        />
      ) : (
        ""
      )}
      {showConfirmModal ? (
        <ConfirmModal
          body={ReactHtmlParser(
            "<p style='display: inline-block;'>" +
              addNewRoleResponseData.StatusMsg +
              "<br><br>Do you want to assign permissions now?</p>"
          )}
          showModal={showConfirmModal}
          closeModal={closeConfirmModal}
          doConfirmFunctionality={openAssignPermissionToRoleModal}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default RoleSetting;

import React, { useState, Fragment, useEffect } from "react";
import { getReservationDetailsSubMenu } from "./reservation-details-submenu-helper";
import Sidebar from "../../../components/Sidebar/sidebar.jsx";
import Breadcrumbs from "../../../components/breadcrumbs/breadcrumbs";
import SidebarContainer from "../../../components/Sidebar/sidebar-container";
import ReservationDetailsSummaryCard from "../../../containers/Reservation/details/reservation-details-summary-card";

const ReservationsDetailsSubMenu = (props) => {
  const [isExpandableSidebar, setIsExpandableSidebar] = useState(true);
  const [summaryReservationData, setSummaryReservationData] = useState({});

  const toggleNavbar = () => {
    setIsExpandableSidebar(!isExpandableSidebar);
  };

  useEffect(() => {
    setSummaryReservationData(props.location.state.summaryReservationData);
  }, [summaryReservationData]);

  return (
    <Fragment>
      <Sidebar
        subMenu={getReservationDetailsSubMenu(summaryReservationData || {})}
        isExpandableSidebar={isExpandableSidebar}
        toggleNavbar={toggleNavbar}
        breadCrumbList={props.location.state.breadCrumbList}
      />
      <div
        className={
          isExpandableSidebar ? "active content-wrapper1" : "content-wrapper1"
        }
      >
        <Breadcrumbs
          breadCrumbList={props.location.state.breadCrumbList}
          title={
            props.location.state
              ? props.location.state.breadCrumbTitle
                ? props.location.state.breadCrumbTitle
                : "Reservation Details"
              : "Reservation Details"
          }
        />
        <ReservationDetailsSummaryCard
          summaryReservationData={summaryReservationData || {}}
        />
        <SidebarContainer
          subMenu={getReservationDetailsSubMenu(summaryReservationData || {})}
          isExpandableSidebar={isExpandableSidebar}
          toggleNavbar={toggleNavbar}
        />
      </div>
    </Fragment>
  );
};
export default ReservationsDetailsSubMenu;

import moment from "moment";
import ReactHtmlParser from "react-html-parser";
import { FoxRequestHeader } from "../../services/entities/fox-api/domain/fox-request-header";
import CreditCard from "../../services/entities/models/CreditCard";
import {
  ADMIN_PAGE,
  AttendanceStatus,
  COMMON_MSG,
  ModalType,
} from "../../shared/GlobalVar";
import PermissionName from "../../shared/Permissions";
import {
  convertDateTimeString,
  formatPricingValue,
  getFormattedValidationMsg,
  getFormattedVehicleName,
  getFullName,
  hasObjectNonEmptyProperty,
  hasPermission,
  isNullOrEmpty,
  validateEmail,
  validatePhoneNo,
} from "../../shared/utility";
const hasViewLicensePermission = hasPermission(
  PermissionName.VIEW_DRIVING_LICENSE
);
export const getGelocationBasedSettingsRequest = (reservationRequestObj) => {
  return {
    MemberId: reservationRequestObj.MemberId || null,
    MemberAssetId: reservationRequestObj.MemberAssetId || null,
    ReservationId: reservationRequestObj.ReservationId || null,
    CategoryId: reservationRequestObj.CategoryId || null,
    UpgradedCategoryId: reservationRequestObj.UpgradedCategoryId || null,
    PickupLocationId: reservationRequestObj.PickupLocationId || null,
    PromisedStartTime: reservationRequestObj.PromisedStartTime || null,
    //PromisedEndTime: reservationRequestObj.PromisedEndTime || null
  };
};
export const getNewResMemberCompatibleData = (memberEntity) => {
  return {
    MemberId: memberEntity.MemberId || null,
    MembershipId: memberEntity.MembershipId || null,
    FirstName: memberEntity.FirstName || null,
    MiddleName: memberEntity.MiddleName || null,
    LastName: memberEntity.LastName || null,
    FullName: getFullName(
      memberEntity.FirstName,
      memberEntity.LastName,
      memberEntity.MiddleName
    ),
    Dob: memberEntity.Dob || null,
    EmailAddress: memberEntity.EmailAddress || null,
    BadgeNo: memberEntity.BadgeNo || null,
    Department: memberEntity.Department || null,
    Title: memberEntity.Title || null,
    InsuranceVerified:
      memberEntity.Insurance && memberEntity.Insurance.InsuranceVerified
        ? memberEntity.Insurance.InsuranceVerified
        : null,
    Designation: memberEntity.Designation || null,
    CompanyName: memberEntity.CompanyName || null,
    MobilePhone: memberEntity.MobilePhone || null,
    HomePhone: memberEntity.HomePhone || null,
    WorkPhone: memberEntity.WorkPhone || null,
    PrimaryAddress: { ...memberEntity.PrimaryAddress },
    DrivingLicense: { ...memberEntity.DrivingLicense },
    Insurance: { ...memberEntity.Insurance },
    CreditCard: { ...memberEntity.CreditCard },
    // LicenceVerifiedIcoType: getDrivingLicenseVerifiedIcon(
    //   memberEntity.DrivingLicense
    // )
    // PrimaryAddress: {
    //   CountryId: memberEntity.PrimaryAddress.Country || null,
    //   StateId: memberEntity.PrimaryAddress.State || null,
    //   CityId: memberEntity.PrimaryAddress.City || null,
    //   ZipCodeId: memberEntity.PrimaryAddress.ZipCode || null,
    //   StateStr: memberEntity.PrimaryAddress.StateText || null,
    //   CityStr: memberEntity.PrimaryAddress.CityText || null,
    //   ZipcodeStr: memberEntity.PrimaryAddress.ZipCodeText || null,
    //   Street1: memberEntity.PrimaryAddress.Street1 || null,
    //   Street2: memberEntity.PrimaryAddress.Street2 || null
    // },
    // LicenseExpirationDate:
    //   memberEntity.DrivingLicense.LicenseExpirationDate || null,
    // Dob: memberEntity.Dob || null,
    // LicenseCountryId:
    //   memberEntity.DrivingLicense.DrivingLicenseCountryId || null,
    // LicenseStateId: memberEntity.DrivingLicense.DrivingLicenseStateId || null,
    // LicenseNumber: memberEntity.DrivingLicense.DrivingLicenseNumber || null,
    // IsInternationalLicense:
    //   memberEntity.DrivingLicense.IsInternationalLicense || null,
    // InsuranceCarrier: memberEntity.Insurance.InsuranceCarrier || null,
    // PolicyNumber: memberEntity.Insurance.PolicyNumber || null,
    // InsuranceExpirationDate:
    //   memberEntity.Insurance.InsuranceExpirationDate || null,
  };
};

export const createMemberReq = (memId) => {
  return {
    MemberId: memId,
    Option: {
      License: hasViewLicensePermission,
      Insurance: true,
      CreditCard: true,
      Employee: true,
      CountryCodes: false,
      Assets: false,
      Referals: false,
      Rating: false,
      Preferences: false,
      Contact: false,
      Earnings: false,
      PrimaryStateCityZipList: false,
      BillingStateCityZipList: false,
      LicenseStateList: false,
    },
  };
};
export const createFoxAPIMemReq = (customerId) => {
  return {
    requestHeader: new FoxRequestHeader(),
    customerInfo: {
      customerId,
    },
    //login: hasViewLicensePermission,
    includeRewards: true,
    includeCreditDetails: true,
    includeLoyalties: true,
    includeFoxDiscounts: true,
  };
};
export const getReservationChargesRequest = (reservationRequestObj, memDob) => {
  return {
    MemberId: reservationRequestObj.MemberId || null,
    MemberAssetId: reservationRequestObj.MemberAssetId || null,
    ReservationId: reservationRequestObj.ReservationId || null,
    // CategoryId: reservationRequestObj.CategoryId || null,
    ClassCode: reservationRequestObj.ClassCode || null,
    UpgradedCategoryId: reservationRequestObj.UpgradedCategoryId || null,
    UpgradeRate: reservationRequestObj.UpgradeRate || null,
    UpgradeRateType: reservationRequestObj.UpgradeRateType || null,

    OdometerOut: reservationRequestObj.OdometerOut || null,
    OdometerIn: reservationRequestObj.OdometerIn || null,
    FuelOut: reservationRequestObj.FuelOut || null,

    DiscountType: reservationRequestObj.DiscountType || null,
    DiscountValue: reservationRequestObj.DiscountValue || null,
    DiscountReason: reservationRequestObj.DiscountReason || null,

    AppliedCreditList: reservationRequestObj.AppliedCreditList || null,
    AppliedChargeList: reservationRequestObj.AppliedChargeList || null,

    AdditionalDriverCount: reservationRequestObj.AdditionalDriverCount || null,

    Upsells:
      reservationRequestObj.Upsells.length > 0
        ? [...reservationRequestObj.Upsells]
        : [],

    InsuranceInfo: reservationRequestObj.InsuranceInfo || null,

    Dob: memDob || null,

    LateChargeableOption: reservationRequestObj.LateChargeableOption || null,
    KeepOlderPricing: reservationRequestObj.KeepOlderPricing || false,
    CheckoutDate: reservationRequestObj.CheckoutDate || null,
    ExtensionFee: reservationRequestObj.ExtensionFee || null,

    PickupLocationId: reservationRequestObj.PickupLocationId || null,
    PromisedStartTime: reservationRequestObj.PromisedStartTime || null,
    PromisedEndTime: reservationRequestObj.PromisedEndTime || null,
    AppliedGracePeriod: reservationRequestObj.AppliedGracePeriod || null,
    UseVehicleRate: reservationRequestObj.UseVehicleRate || false,
  };
};
export const loadCreditDatatoPaymentData = (paymentData, memberEntity) => {
  let creditData = memberEntity.CreditCard
    ? { ...memberEntity.CreditCard }
    : new CreditCard();
  paymentData.BillingName =
    creditData && creditData.BillingName
      ? creditData.BillingName
      : memberEntity.FullName;
  paymentData.PaymentMethod = creditData.PaymentMethod || null;
  paymentData.CVVCode = creditData.CVVCode || null;
  paymentData.CardExpirationYear = creditData.CardExpirationYear || null;
  paymentData.CardExpirationMonth = creditData.CardExpirationMonth || null;
  paymentData.AccountNumber = creditData.CardNumber || null;
  paymentData.CardType = creditData.CreditCardType || null;
  paymentData.Address = creditData.BillingName
    ? { ...creditData.Address }
    : { ...memberEntity.PrimaryAddress };
  return paymentData;
};
export const createReservationRequest = (
  reservationRequestObj,
  memberInputReqest,
  paymentInputRequest
) => {
  return {
    RequestType: reservationRequestObj.RequestType || "NEW",
    PersonInfo: compatiblePersonRequestforReservationRequest(memberInputReqest),
    PaymentInfo:
      compatiblePaymentRequestforReservationRequest(paymentInputRequest),
    InsuranceInfo: { ...reservationRequestObj.InsuranceInfo },

    MemberId: reservationRequestObj.MemberId || null,
    MemberAssetId: reservationRequestObj.MemberAssetId || null,
    Vin: reservationRequestObj.Vin || null,

    ReservationId: reservationRequestObj.ReservationId || null,

    CategoryId: reservationRequestObj.CategoryId || null,
    UpgradedCategoryId: reservationRequestObj.UpgradedCategoryId || null,
    UpgradeRate: reservationRequestObj.UpgradeRate || null,
    UpgradeRateType: reservationRequestObj.UpgradeRateType || null,

    PickupLocationId: reservationRequestObj.PickupLocationId || null,
    ReturnLocationId:
      reservationRequestObj.ReturnLocationId ||
      reservationRequestObj.PickupLocationId,
    PromisedStartTime: reservationRequestObj.PromisedStartTime || null,
    PromisedEndTime: reservationRequestObj.PromisedEndTime || null,
    AppliedGracePeriod: reservationRequestObj.AppliedGracePeriod || null,

    OdometerOut: reservationRequestObj.OdometerOut || null,
    OdometerIn: reservationRequestObj.OdometerIn || null,
    CustomerReportedOdometerIn:
      reservationRequestObj.CustomerReportedOdometerIn || null,
    CustomerReportedFuelIn: reservationRequestObj.CustomerReportedFuelIn || 0,
    FuelOut: reservationRequestObj.FuelOut || null,
    FuelIn: reservationRequestObj.FuelIn || 0,

    DiscountType: reservationRequestObj.DiscountType || null,
    DiscountValue: reservationRequestObj.DiscountValue || null,
    DiscountReason: reservationRequestObj.DiscountReason || null,

    Notes: reservationRequestObj.Notes || null,
    UpgradeNotes: reservationRequestObj.UpgradeNotes || null,
    CCANotes: reservationRequestObj.CCANotes || null,

    AppliedCreditList: reservationRequestObj.AppliedCreditList || null,
    AppliedChargeList: reservationRequestObj.AppliedChargeList || null,

    AdditionalDrivers: [...reservationRequestObj.AdditionalDrivers],

    Upsells:
      reservationRequestObj.Upsells.length > 0
        ? [...reservationRequestObj.Upsells]
        : [],
    //   Upsells: reservationRequestObj.Upsells || null,
    // ReservationTaxList:
    //   reservationRequestObj.ReservationTaxList.length > 0
    //     ? [...reservationRequestObj.ReservationTaxList]
    //     : [],
    // Dob: memberEntity.DrivingLicense
    //   ? memberEntity.DrivingLicense.DateOfBirth || null
    //   : null,

    LateChargeableOption: reservationRequestObj.LateChargeableOption || null,
    UseVehicleRate: reservationRequestObj.UseVehicleRate || null,
    KeepOlderPricing: reservationRequestObj.KeepOlderPricing || false,
    CheckoutDate: reservationRequestObj.CheckoutDate || null,

    ModifyRentalStart: reservationRequestObj.ModifyRentalStart || false,
    ReferralAgency: reservationRequestObj.ReferralAgency || null,
    Source: reservationRequestObj.Source || null,

    OverrideDMVCheck: reservationRequestObj.OverrideDMVCheck || false,
    OverrideVehicle: reservationRequestObj.OverrideVehicle || false,
    OverridePayment: reservationRequestObj.OverridePayment || false,
    OverrideMinimumAge: reservationRequestObj.OverrideMinimumAge || false,
    OverrideHoursOfOperation:
      reservationRequestObj.OverrideHoursOfOperation || false,
    OverrideEmail: reservationRequestObj.OverrideEmail || false,

    ExtensionFee: reservationRequestObj.ExtensionFee || null,
    ModifiedStartTime: reservationRequestObj.ModifiedStartTime || null,
    IntegAdjustedBy: reservationRequestObj.IntegAdjustedBy || null,
    IntegArrivedBy: reservationRequestObj.IntegArrivedBy || null,
  };
};
export const compatiblePersonRequestforReservationRequest = (memberEntity) => {
  return {
    PersonId: memberEntity.MemberId || null,
    FirstName: memberEntity.FirstName || null,
    MiddleName: memberEntity.MiddleName || null,
    LastName: memberEntity.LastName || null,
    EmailAddress: memberEntity.EmailAddress || null,
    //BadgeNo: memberEntity.BadgeNo || null,
    Department: memberEntity.Department || null,
    Title: memberEntity.Title || null,
    Designation: memberEntity.Designation || null,
    CompanyName: memberEntity.CompanyName || null,
    MobilePhone: memberEntity.MobilePhone || null,
    HomePhone: memberEntity.HomePhone || null,
    WorkPhone: memberEntity.WorkPhone || null,
    PrimaryAddress: memberEntity.PrimaryAddress
      ? {
          Street1: memberEntity.PrimaryAddress.Street1 || null,
          Street2: memberEntity.PrimaryAddress.Street2 || null,
          CountryId: memberEntity.PrimaryAddress.Country || null,
          StateId: memberEntity.PrimaryAddress.State || null,
          CityId: memberEntity.PrimaryAddress.City || null,
          ZipCodeId: memberEntity.PrimaryAddress.ZipCode,
          StateStr: memberEntity.PrimaryAddress.State
            ? null
            : memberEntity.PrimaryAddress.StateText || null,
          CityStr: memberEntity.PrimaryAddress.City
            ? null
            : memberEntity.PrimaryAddress.CityText || null,
          ZipcodeStr: memberEntity.PrimaryAddress.ZipCode
            ? null
            : memberEntity.PrimaryAddress.ZipCodeText || null,
        }
      : null,
    LicenseNumber: memberEntity.DrivingLicense
      ? memberEntity.DrivingLicense.DrivingLicenseNumber
      : null,
    IsInternationalLicense: memberEntity.DrivingLicense
      ? memberEntity.DrivingLicense.IsInternationalLicense || null
      : null,
    LicenseExpirationDate: memberEntity.DrivingLicense
      ? memberEntity.DrivingLicense.LicenseExpirationDate || null
      : null,
    LicenseCountryId: memberEntity.DrivingLicense
      ? memberEntity.DrivingLicense.DrivingLicenseCountryId || null
      : null,
    LicenseStateId: memberEntity.DrivingLicense
      ? memberEntity.DrivingLicense.DrivingLicenseStateId || null
      : null,
    LicenseStateText: memberEntity.DrivingLicense
      ? memberEntity.DrivingLicense.DrivingLicenseStateText || null
      : null,
    Dob: memberEntity.DrivingLicense
      ? memberEntity.DrivingLicense.DateOfBirth || null
      : null,
    InsuranceCarrier: memberEntity.Insurance
      ? memberEntity.Insurance.InsuranceCarrier || null
      : null,
    PolicyNumber: memberEntity.PolicyNumber
      ? memberEntity.Insurance.PolicyNumber || null
      : null,
    InsuranceExpirationDate: memberEntity.Insurance
      ? memberEntity.Insurance.InsuranceExpirationDate || null
      : null,
    InsuranceVerified: memberEntity.Insurance
      ? memberEntity.Insurance.InsuranceVerified || null
      : null,
    Company: memberEntity.Company || null,
  };
};
export const compatibleReservationSummarytoResInpuctrls = (
  reservationSummary,
  requestType
) => {
  const pricingInfo = reservationSummary.ReservationPricingInfo;
  let Upsells =
    pricingInfo.TaxableUpsellOptions &&
    pricingInfo.TaxableUpsellOptions.length > 0
      ? [...pricingInfo.TaxableUpsellOptions]
      : [];
  if (pricingInfo.UpsellOptions && pricingInfo.UpsellOptions.length > 0) {
    Upsells = Upsells.concat(pricingInfo.UpsellOptions);
  }
  return {
    isRental: reservationSummary
      ? reservationSummary.AttendanceStatus !== AttendanceStatus.NOW_SHOW ||
        requestType === "START_RENTAL"
        ? true
        : false
      : false,
    creationDate: convertDateTimeString(reservationSummary.CreatedOn),
    IsSameAsPickUploactionId:
      reservationSummary.PickupLocationId ===
      reservationSummary.ReturnLocationId
        ? true
        : false,
    RequestType: requestType || null,
    MemberId: reservationSummary.BorrowerId || null,
    ReservationId: reservationSummary.ReservationId || null,
    MemberAssetId: reservationSummary.ReservationAssetId || null,
    VehicleName: getFormattedVehicleName(
      reservationSummary.PersonAssetUnitNo,
      reservationSummary.Make,
      reservationSummary.Model,
      reservationSummary.Year
    ),
    CategoryId: reservationSummary.CategoryId || null,
    UpgradedCategoryId: reservationSummary.UpgradedCategoryId || null,

    IntegAdjustedBy: reservationSummary.IntegAdjustedBy || null,
    IntegArrivedBy: reservationSummary.IntegArrivedBy || null,
    IntegRecordedBy: reservationSummary.IntegRecordedBy || null,
    IntegCompletedBy: reservationSummary.IntegCompletedBy || null,

    UpgradeRate: reservationSummary.UpgradeRate || null,
    UpgradeRateType: reservationSummary.UpgradeRateType || null,

    PickupLocationId: reservationSummary.PickupLocationId || null,
    ReturnLocationId: reservationSummary.ReturnLocationId || null,
    PromisedStartTime: pricingInfo.StartDate || null,
    PromisedEndTime: pricingInfo.EndDate || null,
    ActualEndDate: pricingInfo.ActualEndDate || null,
    ModifiedStartTime: reservationSummary.ModifiedStartTime || null,
    CheckoutDate: reservationSummary.CheckoutDate || null,

    OdometerOut: reservationSummary.OdometerOut || null,
    OdometerIn: reservationSummary.OdometerIn || null,
    CustomerReportedOdometerIn:
      reservationSummary.CustomerReportedOdometerIn || null,
    CustomerReportedFuelIn: reservationSummary.CustomerReportedFuelIn || null,
    FuelOut: reservationSummary.FuelOut || null,
    FuelIn: reservationSummary.FuelIn || null,

    DiscountType: reservationSummary.DiscountType || null,
    DiscountValue: reservationSummary.DiscountValue || null,
    DiscountReason: reservationSummary.DiscountReason || null,
    Notes: reservationSummary.BorrowerNotes || null,
    CCANotes: reservationSummary.CallCenterNotes || null,
    UpgradeNotes: reservationSummary.UpgradeNotes || null,
    Reason: reservationSummary.Reason || null,

    LateChargeableOption: reservationSummary.LateChargeableOption || null,

    Upsells: Upsells,

    // ModifyRentalStart: null,
    // UseVehicleRate: false,
    // KeepOlderPricing: false,
    AdditionalDrivers: reservationSummary.AdditionalDrivers
      ? [...reservationSummary.AdditionalDrivers]
      : [],
    ReferralAgency: reservationSummary.ReferralAgency,

    Source: reservationSummary.CreatedMethodValue,

    ExtensionFee: null,
    AppliedGracePeriod: reservationSummary.AppliedGracePeriod,

    InsuranceInfo: {
      CDWSelected: pricingInfo.CdwSelected || false,
      // DeductibleAmount: null,
      // DeductibleCost: null,
      LiabilityInsuranceSelected:
        pricingInfo.LiabilityInsuranceSelected || false,
      InsuranceSurchargeSelected:
        pricingInfo.InsuranceSurchargeSelected || false,
    },
    Rates: {
      SharerMonthlyRate: reservationSummary.SharerMonthlyRate,
      SharerWeeklyRate: reservationSummary.SharerWeeklyRate,
      SharerDailyRate: reservationSummary.SharerDailyRate,
      SharerHourlyRate: reservationSummary.SharerHourlyRate,
      SharerWeekendRate: reservationSummary.SharerWeekendRate,
      MonthlyRate: reservationSummary.MonthlyRate,
      WeeklyRate: reservationSummary.WeeklyRate,
      DailyRate: reservationSummary.DailyRate,
      HourlyRate: reservationSummary.HourlyRate,
      WeekendRate: reservationSummary.WeekendRate,
    },
  };
};
export const compatiblePaymentRequestforReservationRequest = (
  paymnetEntity
) => {
  let isCreditCardPayment = ["CREDIT_CARD", "DEBIT_CARD"].includes(
    paymnetEntity.PaymentMethod
  );
  return {
    AccountNumber: isCreditCardPayment ? paymnetEntity.AccountNumber : null,
    Address: {
      Street1: paymnetEntity.Address.Street1 || null,
      Street2: paymnetEntity.Address.Street2 || null,
      CountryId: paymnetEntity.Address.Country || null,
      StateId: paymnetEntity.Address.State || null,
      CityId: paymnetEntity.Address.City || null,
      ZipCodeId: paymnetEntity.Address.ZipCode || null,
      StateStr: paymnetEntity.Address.State
        ? null
        : paymnetEntity.Address.StateText || null,
      CityStr: paymnetEntity.Address.City
        ? null
        : paymnetEntity.Address.CityText || null,
      ZipcodeStr: paymnetEntity.Address.ZipCode
        ? null
        : paymnetEntity.Address.ZipCodeText || null,
    },
    Amount: paymnetEntity.Amount,
    BankName: paymnetEntity.BankName,
    CVVCode: isCreditCardPayment ? paymnetEntity.CVVCode : null,

    CardType: isCreditCardPayment ? paymnetEntity.CardType : null,
    ChargeViaGateway: paymnetEntity.ChargeViaGateway,
    CheckNumber: paymnetEntity.CheckNumber,

    ExpirationMonth: isCreditCardPayment
      ? paymnetEntity.CardExpirationMonth || null
      : null,
    ExpirationYear: isCreditCardPayment
      ? paymnetEntity.CardExpirationYear || null
      : null,

    IntegRecordedBy: paymnetEntity.IntegRecordedBy || null,
    Notes: paymnetEntity.Notes || null,
    PaymentMethod: paymnetEntity.PaymentMethod || null,
    PaymentType: paymnetEntity.PaymentType || null,
    TransactionType: paymnetEntity.TransactionType || null,
    Reason: paymnetEntity.Reason || null,
  };
};
export const validPaymentData = (data) => {
  let msg = "",
    msg1 = "",
    msg2 = "";
  if (data) {
    if (!data.BillingName || data.BillingName === "") {
      msg = COMMON_MSG.BillingName;
    }
    if (data.TransactionType === "REFUND") {
      if (!data.Reason) {
        if (msg) {
          msg += ", ";
        }
        msg += "Refund Reason";
      }
    }
    if (!data.PaymentMethod) {
      if (msg) {
        msg += ", ";
      }
      msg += "Payment Method";
    }
    if (
      data.PaymentMethod === "CREDIT_CARD" ||
      data.PaymentMethod === "DEBIT_CARD"
    ) {
      if (
        !data.Address.CountryId &&
        !data.Address.Country &&
        !data.Address.CountryText
      ) {
        if (msg) {
          msg += ", ";
        }
        msg += "Country";
      }
      if (
        !data.Address.StateId &&
        !data.Address.StateStr &&
        !data.Address.State &&
        !data.Address.StateText
      ) {
        if (msg) {
          msg += ", ";
        }
        msg += "State";
      }
      if (
        !data.Address.CityId &&
        !data.Address.CityStr &&
        !data.Address.City &&
        !data.Address.CityText
      ) {
        if (msg) {
          msg += ", ";
        }
        msg += "City";
      }
      if (
        !data.Address.ZipCodeId &&
        !data.Address.ZipcodeStr &&
        !data.Address.ZipCode &&
        !data.Address.ZipCodeText
      ) {
        if (msg) {
          msg += ", ";
        }
        msg += "Zip Code";
      }

      if (!data.CVVCode) {
        if (msg) {
          msg += ", ";
        }
        msg += "CVV code";
      } else {
        if (data.CardType) {
          if (data.CardType === "AMEX" && data.CVVCode.length !== 4) {
            msg2 = "CVV should be 4 digit for American Express card.";
          } else if (data.CardType !== "AMEX" && data.CVVCode.length !== 3) {
            msg2 = "CVV should be 3 digit.";
          }
        }
      }

      if (!data.AccountNumber) {
        if (msg) {
          msg += ", ";
        }
        msg += "Card Number";
      } else {
        /*
          if (data.AccountNumber && data.AccountNumber.indexOf('XXXX-XXXX-XXXX') == -1) {
              var accepted_card = Global.AcceptedCardType[data.CardType] || [];
              if (accepted_card.length) {
                  var result = Utility.validateCreditCard(data.AccountNumber,{ accept: accepted_card }); 
                  if (!result.length_valid) {
                      msg1 =  'Credit card number is invalid.';
                  }
              }
          }
          */
      }
    }
  }
  if (data.Amount == null || data.Amount === 0) {
    if (msg) {
      msg += ", ";
    }
    msg += "Amount";
  }
  if (msg) {
    return getFormattedValidationMsg(msg, true);
  }
  if (msg1) {
    if (msg) {
      msg += "<br/>";
    }
    msg += msg1;
  }
  if (msg2) {
    if (msg) {
      msg += "<br/>";
    }
    msg += msg2;
  }
  return msg;
};

export const validReservationData1 = (obj) => {
  let msg = "";
  let starttime = obj.PromisedStartTime
    ? moment(obj.PromisedStartTime, "MM:DD:YYYY:HH:mm").toDate()
    : null;
  let endtime = obj.PromisedEndTime
    ? moment(obj.PromisedEndTime, "MM:DD:YYYY:HH:mm").toDate()
    : null;
  if (!obj.PickupLocationId) {
    msg = "Pickup location";
  }
  if (!obj.ReturnLocationId) {
    if (msg) {
      msg += ", ";
    }
    msg += "Return location";
  }
  if (!starttime) {
    if (msg) {
      msg += ", ";
    }
    msg += "Pickup date";
  }
  if (!endtime) {
    if (msg) {
      msg += ", ";
    }
    msg += "Return date";
  }
  if (!obj.CategoryId) {
    if (msg) {
      msg += ", ";
    }
    msg += "Category";
  }
  if (!obj.Source) {
    if (msg) {
      msg += ", ";
    }
    msg += "Channel";
  }
  if (msg) {
    return getFormattedValidationMsg(msg);
  }
  if (starttime && endtime && endtime.getTime() <= starttime.getTime()) {
    msg = msg
      ? msg + "<br>Return Time must be after Pickup Time."
      : "Return Time must be after Pickup Time.";
  }
  if (msg) {
    return ReactHtmlParser(msg);
  }
  return msg;
};
export const validReservationData2 = (obj) => {
  let msg = "";
  let personInfo = obj.PersonInfo ? { ...obj.PersonInfo } : null;
  if (personInfo) {
    if (!personInfo.FirstName) {
      if (msg) {
        msg += ", ";
      }
      msg += "First Name";
    }
    if (!personInfo.LastName) {
      if (msg) {
        msg += ", ";
      }
      msg += "Last Name";
    }
    if (!personInfo.EmailAddress) {
      if (msg) {
        msg += ", ";
      }
      msg += "Email Address";
    }
    if (personInfo.PrimaryAddress) {
      if (!personInfo.PrimaryAddress.Street1) {
        if (msg) {
          msg += ", ";
        }
        msg += "Address1";
      }
      if (!personInfo.PrimaryAddress.CountryId) {
        if (msg) {
          msg += ", ";
        }
        msg += "Country";
      }
      if (
        !personInfo.PrimaryAddress.StateId &&
        !personInfo.PrimaryAddress.StateStr
      ) {
        if (msg) {
          msg += ", ";
        }
        msg += "State";
      }
      if (
        !personInfo.PrimaryAddress.CityId &&
        !personInfo.PrimaryAddress.CityStr
      ) {
        if (msg) {
          msg += ", ";
        }
        msg += "City";
      }
      if (
        !personInfo.PrimaryAddress.ZipCodeId &&
        !personInfo.PrimaryAddress.ZipcodeStr
      ) {
        if (msg) {
          msg += ", ";
        }
        msg += "ZIP Code/Postal";
      }
    }
    if (!personInfo.LicenseNumber) {
      if (msg) {
        msg += ", ";
      }
      msg += "License No";
    }
    if (!personInfo.LicenseCountryId) {
      if (msg) {
        msg += ", ";
      }
      msg += "License Country";
    }
    if (!personInfo.LicenseStateId && !personInfo.LicenseStateText) {
      if (msg) {
        msg += ", ";
      }
      msg += "License State";
    }
    if (!obj.PromisedStartTime) {
      if (msg) {
        msg += ", ";
      }
      msg += "Pickup Date";
    }
    if (!obj.PromisedEndTime) {
      if (msg) {
        msg += ", ";
      }
      msg += "Return Date";
    }
    if (isNullOrEmpty(personInfo.MobilePhone)) {
      if (msg) {
        msg += ", ";
      }
      msg += COMMON_MSG.MobilePhoneRequired;
    }

    if (msg) {
      return getFormattedValidationMsg(msg);
    }

    if (personInfo.MobilePhone) {
      if (!validatePhoneNo(personInfo.MobilePhone)) {
        msg = COMMON_MSG.InvalidMobileNo + ".";
      }
    }
    if (personInfo.HomePhone) {
      if (!validatePhoneNo(personInfo.HomePhone)) {
        if (msg) {
          msg += " <br />";
        }
        msg += COMMON_MSG.InvalidHomeNo + ". ";
      }
    }
    if (personInfo.WorkPhone) {
      if (!validatePhoneNo(personInfo.WorkPhone)) {
        if (msg) {
          msg += " <br />";
        }
        msg += COMMON_MSG.InvalidWorkNo + ". ";
      }
    }

    if (personInfo.EmailAddress) {
      if (!validateEmail(personInfo.EmailAddress)) {
        if (msg) {
          msg += " <br />";
        }
        msg += COMMON_MSG.ValidEmail + ". ";
      }
    }
    if (msg) {
      return ReactHtmlParser(msg);
    }
  } else {
    msg = "Cutomer Data Missing.";
  }
  if (msg) {
    return ReactHtmlParser(msg);
  }
  return msg;
};
export const validateAddDriver = (personInfo) => {
  let msg = "";
  if (personInfo) {
    if (!personInfo.FirstName) {
      if (msg) {
        msg += ", ";
      }
      msg += "First Name";
    }
    if (!personInfo.LastName) {
      if (msg) {
        msg += ", ";
      }
      msg += "Last Name";
    }
    if (!personInfo.EmailAddress) {
      if (msg) {
        msg += ", ";
      }
      msg += "Email Address";
    }
    if (personInfo.PrimaryAddress) {
      if (
        !personInfo.PrimaryAddress.Street1 &&
        !personInfo.PrimaryAddress.Street2
      ) {
        if (msg) {
          msg += ", ";
        }
        msg += "Address1";
      }
      if (!personInfo.PrimaryAddress.Country) {
        if (msg) {
          msg += ", ";
        }
        msg += "Country";
      }
      if (
        !personInfo.PrimaryAddress.State &&
        !personInfo.PrimaryAddress.StateText
      ) {
        if (msg) {
          msg += ", ";
        }
        msg += "State";
      }
      if (
        !personInfo.PrimaryAddress.City &&
        !personInfo.PrimaryAddress.CityText
      ) {
        if (msg) {
          msg += ", ";
        }
        msg += "City";
      }
      if (
        !personInfo.PrimaryAddress.ZipCode &&
        !personInfo.PrimaryAddress.ZipCodeText
      ) {
        if (msg) {
          msg += ", ";
        }
        msg += "ZIP Code/Postal";
      }
    }
    if (personInfo.DrivingLicense) {
      if (!personInfo.DrivingLicense.DrivingLicenseNumber) {
        if (msg) {
          msg += ", ";
        }
        msg += "License No";
      }
      if (!personInfo.DrivingLicense.DrivingLicenseCountryId) {
        if (msg) {
          msg += ", ";
        }
        msg += "License Country";
      }
      if (
        !personInfo.DrivingLicense.DrivingLicenseStateId &&
        !personInfo.DrivingLicense.DrivingLicenseStateText
      ) {
        if (msg) {
          msg += ", ";
        }
        msg += "License State";
      }

      if (!personInfo.DrivingLicense.LicenseExpirationDate) {
        if (msg) {
          msg += ", ";
        }
        msg += "License Exp. Date";
      }
      if (msg) {
        return getFormattedValidationMsg(msg);
      }
    }

    if (hasObjectNonEmptyProperty(personInfo.MobilePhone)) {
      if (!validatePhoneNo(personInfo.MobilePhone)) {
        msg = COMMON_MSG.InvalidMobileNo + ".";
      }
    }

    if (personInfo.EmailAddress) {
      if (!validateEmail(personInfo.EmailAddress)) {
        if (msg) {
          msg += " <br />";
        }
        msg += COMMON_MSG.ValidEmail + ". ";
      }
    }
    if (msg) {
      return ReactHtmlParser(msg);
    }
  } else {
    msg = "Cutomer Data Missing.";
  }
  if (msg) {
    return ReactHtmlParser(msg);
  }
  return msg;
};
export const validateAddDriver4 = (personInfo) => {
  if (personInfo) {
    if (
      personInfo.FirstName ||
      personInfo.LastName ||
      personInfo.EmailAddress ||
      (personInfo.PrimaryAddress &&
        (personInfo.PrimaryAddress.Street1 ||
          personInfo.PrimaryAddress.CountryId ||
          personInfo.PrimaryAddress.CountryId ||
          personInfo.PrimaryAddress.CityId ||
          personInfo.PrimaryAddress.ZipCodeId)) ||
      personInfo.LicenseNumber ||
      personInfo.LicenseCountryId ||
      personInfo.LicenseStateId ||
      personInfo.LicenseStateText ||
      (personInfo.MobilePhone &&
        (personInfo.MobilePhone.Number ||
          personInfo.MobilePhone.CountryCode ||
          personInfo.MobilePhone.CountryIso)) ||
      (personInfo.HomePhone &&
        (personInfo.HomePhone.Number ||
          personInfo.HomePhone.CountryCode ||
          personInfo.HomePhone.CountryIso)) ||
      (personInfo.WorkPhone &&
        (personInfo.WorkPhone.Number ||
          personInfo.WorkPhone.CountryCode ||
          personInfo.WorkPhone.CountryIso))
    ) {
      return true;
    }
  }
};

export const validReservationData = (obj) => {
  let msg = "";
  let personInfo = obj.PersonInfo ? { ...obj.PersonInfo } : null;
  if (personInfo) {
    if (!personInfo.FirstName) {
      if (msg) {
        msg += ", ";
      }
      msg += "First Name";
    }
    if (!personInfo.LastName) {
      if (msg) {
        msg += ", ";
      }
      msg += "Last Name";
    }
    if (!personInfo.EmailAddress) {
      if (msg) {
        msg += ", ";
      }
      msg += "Email Address";
    }
    if (personInfo.PrimaryAddress) {
      if (!personInfo.PrimaryAddress.Street1) {
        if (msg) {
          msg += ", ";
        }
        msg += "Address1";
      }
      if (!personInfo.PrimaryAddress.CountryId) {
        if (msg) {
          msg += ", ";
        }
        msg += "Country";
      }
      if (
        !personInfo.PrimaryAddress.StateId &&
        !personInfo.PrimaryAddress.StateStr
      ) {
        if (msg) {
          msg += ", ";
        }
        msg += "State";
      }
      if (
        !personInfo.PrimaryAddress.CityId &&
        !personInfo.PrimaryAddress.CityStr
      ) {
        if (msg) {
          msg += ", ";
        }
        msg += "City";
      }
      if (
        !personInfo.PrimaryAddress.ZipCodeId &&
        !personInfo.PrimaryAddress.ZipcodeStr
      ) {
        if (msg) {
          msg += ", ";
        }
        msg += "ZIP Code/Postal";
      }
    }
    if (!personInfo.LicenseNumber) {
      if (msg) {
        msg += ", ";
      }
      msg += "License No";
    }
    if (!personInfo.LicenseCountryId) {
      if (msg) {
        msg += ", ";
      }
      msg += "License Country";
    }
    if (!personInfo.LicenseStateId && !personInfo.LicenseStateText) {
      if (msg) {
        msg += ", ";
      }
      msg += "License State";
    }
    if (!obj.PromisedStartTime) {
      if (msg) {
        msg += ", ";
      }
      msg += "Pickup Date";
    }
    if (!obj.PromisedEndTime) {
      if (msg) {
        msg += ", ";
      }
      msg += "Return Date";
    }
    if (isNullOrEmpty(personInfo.MobilePhone)) {
      if (msg) {
        msg += ", ";
      }
      msg += COMMON_MSG.MobilePhoneRequired;
    }
    if (msg) {
      return getFormattedValidationMsg(msg);
    }

    if (personInfo.MobilePhone) {
      if (!validatePhoneNo(personInfo.MobilePhone)) {
        msg = COMMON_MSG.InvalidMobileNo + ".";
      }
    }
    if (personInfo.HomePhone) {
      if (!validatePhoneNo(personInfo.HomePhone)) {
        if (msg) {
          msg += " <br />";
        }
        msg += COMMON_MSG.InvalidHomeNo + ". ";
      }
    }
    if (personInfo.WorkPhone) {
      if (!validatePhoneNo(personInfo.WorkPhone)) {
        if (msg) {
          msg += " <br />";
        }
        msg += COMMON_MSG.InvalidWorkNo + ". ";
      }
    }
    if (personInfo.EmailAddress) {
      if (!validateEmail(personInfo.EmailAddress)) {
        if (msg) {
          msg += " <br />";
        }
        msg += COMMON_MSG.ValidEmail + ". ";
      }
    }
    if (msg) {
      return ReactHtmlParser(msg);
    }
  } else {
    msg = "Cutomer Data Missing.";
  }

  let starttime = moment(obj.PromisedStartTime, "MM:DD:YYYY:HH:mm").toDate();
  let endtime = moment(obj.PromisedEndTime, "MM:DD:YYYY:HH:mm").toDate();
  if (starttime && endtime && endtime.getTime() <= starttime.getTime()) {
    msg += "Return Time must be after Pickup Time.<br/>";
  }
  if (msg) {
    return ReactHtmlParser(msg);
  }
  return msg;
};

export const getCurrentRate = (objPricingInfo) => {
  let currentRate;
  if (objPricingInfo) {
    let monthlyCharge =
      objPricingInfo.ParticipantType === "SHARER"
        ? objPricingInfo.SharerMonthlyRate
        : objPricingInfo.MonthlyRate;
    if (objPricingInfo.TotalChargedMonths) {
      currentRate = monthlyCharge;
      return { currentRate: currentRate, type: "month" };
    }
    let weeklyCharge =
      objPricingInfo.ParticipantType === "SHARER"
        ? objPricingInfo.SharerWeeklyRate
        : objPricingInfo.WeeklyRate;
    if (objPricingInfo.TotalChargedWeeks) {
      currentRate = weeklyCharge;
      return { currentRate: currentRate, type: "week" };
    }
    let weekendCharge =
      objPricingInfo.ParticipantType === "SHARER"
        ? objPricingInfo.SharerWeekendRate
        : objPricingInfo.WeekendRate;
    if (objPricingInfo.TotalChargedWeekendDays) {
      currentRate = weekendCharge;
      return { currentRate: currentRate, type: "weekend" };
    }
    let dailyCharge =
      objPricingInfo.ParticipantType === "SHARER"
        ? objPricingInfo.SharerDailyRate
        : objPricingInfo.DailyRate;
    if (objPricingInfo.TotalChargedWeekDays) {
      currentRate = dailyCharge;
      return { currentRate: currentRate, type: "daily" };
    }
    let hourlyCharge =
      objPricingInfo.ParticipantType === "SHARER"
        ? objPricingInfo.SharerHourlyRate
        : objPricingInfo.HourlyRate;
    if (objPricingInfo.TotalChargedHours) {
      currentRate = hourlyCharge;
      return { currentRate: currentRate, type: "hour" };
    }
    // let minutesCharge =
    //   objPricingInfo.ParticipantType === "SHARER"
    //     ? objPricingInfo.SharerMinuteRate
    //     : objPricingInfo.MinuteRate;
    if (objPricingInfo.TotalChargedMinutes) {
      currentRate = hourlyCharge;
      return { currentRate: currentRate, type: "minute" };
    }
  }
  return { currentRate: 0, type: null };
};
export const getAppliedRate = (objPricingInfo, isTooltip) => {
  objPricingInfo = objPricingInfo ? objPricingInfo : {};
  let isSharer =
    objPricingInfo.ParticipantType &&
    objPricingInfo.ParticipantType === "SHARER"
      ? true
      : false;
  let data = getFormatedRateData(objPricingInfo, "appliedRate", isSharer);

  let rate = "";
  if (objPricingInfo.TotalChargedMonths) {
    rate = formatPricingValue(data.MonthlyRate) + "/month";
  } else if (objPricingInfo.TotalChargedWeeks) {
    rate = formatPricingValue(data.WeeklyRate) + "/week";
  } else if (objPricingInfo.TotalChargedWeekDays) {
    rate = formatPricingValue(data.DailyRate) + "/day";
  } else if (objPricingInfo.TotalChargedWeekendDays) {
    rate = formatPricingValue(data.WeekendRate) + "/weekend";
  } else if (objPricingInfo.TotalChargedHours) {
    rate = formatPricingValue(data.HourlyRate) + "/hour";
  }

  return isTooltip ? data : rate;
};
export const getInsuranceSurchargeData = (pricingInfo) => {
  let monthlyRate =
    (pricingInfo.ParticipantType === "SHARER"
      ? pricingInfo.SharerMonthlyRate
      : pricingInfo.MonthlyRate) || 0;
  let weeklyRate =
    (pricingInfo.ParticipantType === "SHARER"
      ? pricingInfo.SharerWeeklyRate
      : pricingInfo.WeeklyRate) || 0;
  let dailyRate =
    (pricingInfo.ParticipantType === "SHARER"
      ? pricingInfo.SharerDailyRate
      : pricingInfo.DailyRate) || 0;
  let hourlyRate =
    (pricingInfo.ParticipantType === "SHARER"
      ? pricingInfo.SharerHourlyRate
      : pricingInfo.HourlyRate) || 0;

  let imrate = pricingInfo.InsuranceSurchargeMonthlyRate || 0,
    iwrate = pricingInfo.InsuranceSurchargeWeeklyRate || 0,
    idrate = pricingInfo.InsuranceSurchargeDailyRate || 0,
    ihrate = pricingInfo.InsuranceSurchargeHourlyRate || 0;

  if (
    imrate &&
    pricingInfo.InsuranceSurchargeMonthlyUnit &&
    pricingInfo.InsuranceSurchargeMonthlyUnit === "PERCENT"
  ) {
    imrate = monthlyRate ? monthlyRate * (imrate / 100) : 0;
  }

  if (
    iwrate &&
    pricingInfo.InsuranceSurchargeWeeklyUnit &&
    pricingInfo.InsuranceSurchargeWeeklyUnit === "PERCENT"
  ) {
    iwrate = weeklyRate ? weeklyRate * (iwrate / 100) : 0;
  }

  if (
    idrate &&
    pricingInfo.InsuranceSurchargeUnit &&
    pricingInfo.InsuranceSurchargeUnit === "PERCENT"
  ) {
    idrate = dailyRate ? dailyRate * (idrate / 100) : 0;
  }

  if (
    ihrate &&
    pricingInfo.InsuranceSurchargeHourlyUnit &&
    pricingInfo.InsuranceSurchargeHourlyUnit === "PERCENT"
  ) {
    ihrate = hourlyRate ? hourlyRate * (ihrate / 100) : 0;
  }
  const insuranceSurChargeRate = {
    HourlyRate: ihrate,
    DailyRate: idrate,
    WeeklyRate: iwrate,
    MonthlyRate: imrate,
  };
  let insuraceSurChargeData = {};
  insuraceSurChargeData.rate = { ...insuranceSurChargeRate };
  insuraceSurChargeData.title = pricingInfo.InsuranceAgeMsg || "--";
  let agefees = insuranceSurChargeRate.HourlyRate;
  let unit = "Hourly";
  if (pricingInfo.TotalMonths) {
    agefees = insuranceSurChargeRate.MonthlyRate;
    unit = "Monthly";
  } else if (pricingInfo.TotalWeeks) {
    agefees = insuranceSurChargeRate.WeeklyRate;
    unit = "Weekly";
  } else if (pricingInfo.TotalDays) {
    agefees = insuranceSurChargeRate.DailyRate;
    unit = "Daily";
  }
  insuraceSurChargeData.fees = agefees;
  insuraceSurChargeData.unit = unit;
  insuraceSurChargeData.isSelected =
    pricingInfo.InsuranceSurchargeSelected || false;
  return insuraceSurChargeData;
};

export const getLiabilityData = (pricingObj) => {
  const monthlyRate =
    (pricingObj.ParticipantType === "SHARER"
      ? pricingObj.SharerMonthlyRate
      : pricingObj.MonthlyRate) || 0;
  const weeklyRate =
    (pricingObj.ParticipantType === "SHARER"
      ? pricingObj.SharerWeeklyRate
      : pricingObj.WeeklyRate) || 0;
  const dailyRate =
    (pricingObj.ParticipantType === "SHARER"
      ? pricingObj.SharerDailyRate
      : pricingObj.DailyRate) || 0;
  const hourlyRate =
    (pricingObj.ParticipantType === "SHARER"
      ? pricingObj.SharerHourlyRate
      : pricingObj.HourlyRate) || 0;

  let lmrate = pricingObj.LiabilityMonthlyRate || 0,
    lwrate = pricingObj.LiabilityWeeklyRate || 0,
    ldrate = pricingObj.LiabilityDailyRate || 0,
    lhrate = pricingObj.LiabilityHourlyRate || 0;

  if (
    lmrate &&
    pricingObj.LiabilityMonthlyUnit &&
    pricingObj.LiabilityMonthlyUnit === "PERCENT"
  ) {
    lmrate = monthlyRate ? monthlyRate * (lmrate / 100) : 0;
  }

  if (
    lwrate &&
    pricingObj.LiabilityWeeklyUnit &&
    pricingObj.LiabilityWeeklyUnit === "PERCENT"
  ) {
    lwrate = weeklyRate ? weeklyRate * (lwrate / 100) : 0;
  }

  if (
    ldrate &&
    pricingObj.LiabilityUnit &&
    pricingObj.LiabilityUnit === "PERCENT"
  ) {
    ldrate = dailyRate ? dailyRate * (ldrate / 100) : 0;
  }

  if (
    lhrate &&
    pricingObj.LiabilityHourlyUnit &&
    pricingObj.LiabilityHourlyUnit === "PERCENT"
  ) {
    lhrate = hourlyRate ? hourlyRate * (lhrate / 100) : 0;
  }

  var liabilityRate = {
    // RatePerHour: lhrate,
    // RatePerDay: ldrate,
    // RatePerWeek: lwrate,
    // RatePerMonth: lmrate
    HourlyRate: lhrate,
    DailyRate: ldrate,
    WeeklyRate: lwrate,
    MonthlyRate: lmrate,
  };
  let liabilityData = {};
  liabilityData.rate = { ...liabilityRate };
  liabilityData.title = pricingObj.LiabilityDescription || "Liability";
  let agefees = liabilityRate.HourlyRate;
  let unit = "Hourly";
  if (pricingObj.TotalMonths) {
    agefees = liabilityRate.MonthlyRate;
    unit = "Monthly";
  } else if (pricingObj.TotalWeeks) {
    agefees = liabilityRate.WeeklyRate;
    unit = "Weekly";
  } else if (pricingObj.TotalDays) {
    agefees = liabilityRate.DailyRate;
    unit = "Daily";
  }
  liabilityData.fees = agefees;
  liabilityData.unit = unit;
  liabilityData.isSelected = pricingObj.LiabilitySelected || false;
  return liabilityData;
};

export const getInfoForCancellationModal = (reservationData, modalType) => {
  return {
    modalType: modalType,
    modalTitle:
      modalType === ModalType.CANCEL_RESERVATION_MODAL
        ? reservationData.AttendanceStatus === AttendanceStatus.NOW_SHOW
          ? "Cancel Reservation"
          : "Cancel Rental"
        : "Cancellation Information",
    reservationId: reservationData.ReservationId,
    cancelledOn: reservationData.CancelledOn || null,
    cancelledBy: reservationData.CancelledByPersonName || null,
    cancellationReasons: reservationData.CancellationReasons || [],
    cancellationFee: reservationData.CancellationFee || null,

    cancellationNotes: reservationData.CancellationNotes || null,
    cancellationFeePolicy: reservationData.CancellationFeePolicy || null,
    memberId: reservationData.BorrowerId,
    totalCancellationFee: reservationData.ReservationPricingInfo
      ? reservationData.ReservationPricingInfo.TotalCancellationFee
      : null,
    cancellationCourtesyAmount: reservationData.ReservationPricingInfo
      ? reservationData.ReservationPricingInfo.CancellationCourtesyAmount
      : null,
    refundableAmountCC: reservationData.RefundableAmountCC || 0,
    refundableCredits: reservationData.RefundableCredits || 0,
    refundableAmountManual: reservationData.RefundableAmountManual || 0,
    integStatus: reservationData.IntegStatus || null,
    timezoneString: reservationData.TimezoneString || null,
    isEditable:
      modalType === ModalType.CANCEL_RESERVATION_MODAL ||
      reservationData.CreatedMethodValue === "CALL_CENTER" ||
      reservationData.BorrowerId !== reservationData.CancelledByPersonId ||
      ((reservationData.LastModifiedMethod === "WEB" ||
        reservationData.LastModifiedMethod === "PARTNER_WEB" ||
        reservationData.LastModifiedMethod === "PHONE") &&
        (!reservationData.CancellationReasons ||
          reservationData.CancellationReasons.length === 0))
        ? true
        : false,
  };
};
export const getInfoForEditConfirmationCodeModal = (reservationData) => {
  return {
    reservationId: reservationData.ReservationId,
    confirmationCode: reservationData.ConfirmationCode,
    integConfirmNumber: reservationData.IntegConfirmNumber || null,
    xpressEnabled: reservationData.XpressEnabled || null,
    optInXpress: reservationData.OptInXpress || false,
    voucherAdded: reservationData.VoucherAdded || null,
    voucherAmount: reservationData.VoucherAmount || null,
    partnerConfirmationKey: reservationData.PartnerConfirmationKey || null,
    integResvConfNum: reservationData.IntegResvConfNum,
    balanceDue: reservationData.ReservationPricingInfo
      ? reservationData.ReservationPricingInfo.TotalPaid || null
      : null,
  };
};
export const getInfoForReservationNotesModal = (reservationData, notesType) => {
  return {
    reservationId: reservationData.ReservationId,
    notesType: notesType,
    notes:
      notesType === "BORROWER"
        ? reservationData.BorrowerNotes
        : notesType === "CALL_CENTER"
        ? reservationData.CallCenterNotes
        : null,
    modalTitle:
      notesType === "BORROWER"
        ? "Add / Edit Customer Note"
        : notesType === "CALL_CENTER"
        ? "Add / Edit Internal Memo"
        : null,
  };
};
export const getInfoForQuickChannelModal = (reservationInfo) => {
  return {
    PartnerConfirmationKey: reservationInfo.PartnerConfirmationKey || null,
    Source: reservationInfo.Source || null,
    ApiLicense: reservationInfo.ApiLicense || {},
    Partner: reservationInfo.Partner || {},
    Provider: reservationInfo.Provider || {},
  };
};
export const getInfoForCategoryModal = (reservationInfo, isBillingInfo) => {
  if (!isBillingInfo) {
    return {
      CategoryId: reservationInfo.CategoryId || null,
      PersonAssetId: reservationInfo.PersonAssetUnitNo || null,
      ReservationId: reservationInfo.ReservationId,
      PromisedStartTime: reservationInfo.ReservationPricingInfo
        ? reservationInfo.StartDate
        : null,
    };
  } else {
    return {
      CategoryId: reservationInfo.CategoryId || null,
      PersonAssetId: reservationInfo.PersonAssetId || null,
      ReservationId: reservationInfo.ReservationId,
      PromisedStartTime: reservationInfo.PromisedStartTime || null,
    };
  }
};
const getInfoForIntegrationHistoryModal = (reservationInfo) => {
  return {
    integStatus: reservationInfo.IntegStatus === "SCHEDULED" ? true : false,
    reservationId: reservationInfo.ReservationId || null,
  };
};
const getInfoForAcceptDeclineReservationModal = (reservationInfo) => {
  return {
    reservationId: reservationInfo.ReservationId || null,
    borrowerId: reservationInfo.BorrowerId || null,
    borrowerNotes: reservationInfo.BorrowerNotes || null,
    createdOn: reservationInfo.CreatedOn || null,
  };
};
const getInfoForUpdateReservationCreationTypeModal = (reservationInfo) => {
  return {
    reservationId: reservationInfo.ReservationId || null,
    reservationCreationType: reservationInfo.ReservationCreationType || null,
  };
};
const getInfoForReservationActionSummaryModal = (reservationInfo) => {
  return {
    adjustedBy: reservationInfo.IntegAdjustedByName || null,
    arrivedBy: reservationInfo.IntegArrivedByName || null,
    completedBy: reservationInfo.IntegCompletedByName || null,
  };
};
const getInfoForDamageModal = (reservationInfo) => {
  return {
    memberId: reservationInfo.BorrowerId || null,
    reservationId: reservationInfo.ReservationId || null,
    memberAssetId: reservationInfo.ReservationAssetId || null,
  };
};
export const getReservationModalDefaultInfo = (
  modalType,
  reservationData,
  notesOrMailType,
  isBillingInfo
) => {
  if (
    modalType === ModalType.CANCEL_RESERVATION_MODAL ||
    modalType === ModalType.CANCEL_REASON_UPDATE_MODAL
  ) {
    return getInfoForCancellationModal(reservationData, modalType);
  } else if (modalType === ModalType.CHANNEL_QUICK_MODAL) {
    return getInfoForQuickChannelModal(reservationData);
  } else if (modalType === ModalType.VEHICLE_CATEGORY_QUICK_MODAL) {
    return getInfoForCategoryModal(reservationData, isBillingInfo);
  } else if (modalType === ModalType.RESERVATION_NOTES_MODAL) {
    return getInfoForReservationNotesModal(reservationData, notesOrMailType);
  } else if (modalType === ModalType.INTEGRATION_HISTORY_MODAL) {
    return getInfoForIntegrationHistoryModal(reservationData);
  } else if (modalType === ModalType.EXTERNAL_PARAMETER_UPDATE_MODAL) {
    return getInfoForEditConfirmationCodeModal(reservationData);
  } else if (modalType === ModalType.ACCEPT_DECLINE_RESERVATION_MODAL) {
    return getInfoForAcceptDeclineReservationModal(reservationData);
  } else if (modalType === ModalType.UPDATE_RESERVATION_CREATION_TYPE_MODAL) {
    return getInfoForUpdateReservationCreationTypeModal(reservationData);
  } else if (modalType === ModalType.RESERVATION_ACTION_SUMMARY_MODAL) {
    return getInfoForReservationActionSummaryModal(reservationData);
  } else if (modalType === ModalType.VOUCHER_INFORMATION_MODAL) {
    return getInfoForQuickChannelModal(reservationData);
  }
  if (modalType === ModalType.DAMAGE_MODAL) {
    return getInfoForDamageModal(reservationData);
  }
};

export const validateDamageModal = (data) => {
  let msg = "";
  if (data) {
    if (!data.DamageLocation) {
      msg = COMMON_MSG.EnterDamageLocation;
    }
    if (msg) {
      return getFormattedValidationMsg(msg);
    }
    return msg;
  }
};
export const validateCancelResReq = (cancelResReq) => {
  let msg = "";
  if (
    !cancelResReq.CancellationReasons ||
    cancelResReq.CancellationReasons.length === 0
  ) {
    msg = "Cancellation Reason(s)";
  }
  if (!cancelResReq.CancelledBy) {
    if (msg) {
      msg += ", ";
    }
    msg = msg + "Cancelled By";
  }
  if (!cancelResReq.Comments) {
    if (msg) {
      msg += ", ";
    }
    msg = msg + "Comments";
  }
  if (msg) {
    return getFormattedValidationMsg(msg);
  }
  return msg;
};

export const getFormatedRateData = (rateInfo, rateType, isSharer) => {
  let data = {};
  if (rateType === "appliedRate" || rateType === "appliedCategoryRate") {
    data = {
      MonthlyRate: isSharer
        ? rateInfo.SharerMonthlyRate || 0
        : rateInfo.MonthlyRate || 0,
      WeeklyRate: isSharer
        ? rateInfo.SharerWeeklyRate || 0
        : rateInfo.WeeklyRate || 0,
      DailyRate: isSharer
        ? rateInfo.SharerDailyRate || 0
        : rateInfo.DailyRate || 0,
      HourlyRate: isSharer
        ? rateInfo.SharerHourlyRate || 0
        : rateInfo.HourlyRate || 0,
      WeekendRate: isSharer
        ? rateInfo.SharerWeekendRate || 0
        : rateInfo.WeekendRate || 0,
    };
  } else if (rateType === "summaryNewRate") {
    data = {
      MonthlyRate: isSharer
        ? rateInfo.NewSharerMonthlyRate || 0
        : rateInfo.NewMonthlyRate || 0,
      WeeklyRate: isSharer
        ? rateInfo.NewSharerWeeklyRate || 0
        : rateInfo.NewWeeklyRate || 0,
      DailyRate: isSharer
        ? rateInfo.NewSharerDailyRate || 0
        : rateInfo.NewDailyRate || 0,
      HourlyRate: isSharer
        ? rateInfo.NewSharerHourlyRate || 0
        : rateInfo.NewHourlyRate || 0,
      WeekendRate: isSharer
        ? rateInfo.NewSharerWeekendRate || 0
        : rateInfo.NewWeekendRate || 0,
    };
  } else if (rateType === "upgradeRate" || rateType === "vehicleRate") {
    data = {
      MonthlyRate: rateInfo.RatePerMonth || 0,
      WeeklyRate: rateInfo.RatePerWeek || 0,
      DailyRate: rateInfo.RatePerDay || 0,
      HourlyRate: rateInfo.RatePerHour || 0,
      WeekendRate: rateInfo.RatePerWeekendDay || 0,
    };
  } else if (rateType === "categoryRate") {
    data = {
      MonthlyRate: rateInfo.RatePerMonth
        ? rateInfo.RatePerMonth
        : rateInfo.MonthlyRate || 0,
      WeeklyRate: rateInfo.RatePerWeek
        ? rateInfo.RatePerWeek
        : rateInfo.WeeklyRate || 0,
      DailyRate: rateInfo.RatePerDay
        ? rateInfo.RatePerDay
        : rateInfo.DailyRate || 0,
      WeekendRate: rateInfo.RatePerWeekendDay
        ? rateInfo.RatePerWeekendDay
        : rateInfo.WeekendRate || 0,
      HourlyRate: rateInfo.RatePerHour
        ? rateInfo.RatePerHour
        : rateInfo.HourlyRate || 0,
    };
  } else {
    data = {
      MonthlyRate: 0,
      WeeklyRate: 0,
      DailyRate: 0,
      HourlyRate: 0,
      WeekendRate: 0,
    };
  }
  return data;
};

/** new data with universal tag */
export const getAppliedRate_new = (ratePlan) => {
  let rate = "";
  if (!ratePlan) ratePlan = {};
  if (ratePlan.type === "MONTHLY") {
    rate = formatPricingValue(ratePlan.rate) + "/month";
  } else if (ratePlan.type === "WEEKLY") {
    rate = formatPricingValue(ratePlan.rate) + "/week";
  } else if (ratePlan.type === "DAILY") {
    rate = formatPricingValue(ratePlan.rate) + "/day";
  } else if (ratePlan.type === "WEEKEND") {
    rate = formatPricingValue(ratePlan.rate) + "/weekend";
  } else if (ratePlan.type === "HOURLY") {
    rate = formatPricingValue(ratePlan.rate) + "/hour";
  }
  return rate;
};
// export const getInfoForVoucherQuickModal_new = (reservationInfo) => {
//   return {
//     PartnerConfirmationKey: reservationInfo.PartnerConfirmationKey || null,
//     Source: reservationInfo.Source || null,
//     ApiLicense: reservationInfo.ApiLicense || {},
//     Partner: reservationInfo.Partner || {},
//     Provider: reservationInfo.Provider || {},
//   };
// };
export const getInfoForCategoryModal_new = (reservationInfo, isBillingInfo) => {
  if (!isBillingInfo) {
    return {
      CategoryId: reservationInfo.categoryId || null,
      PersonAssetId: reservationInfo.personAssetUnitNo || null,
      ReservationId: reservationInfo.reservationId,
      PromisedStartTime: reservationInfo.rentalDateTime || null,
    };
  } else {
    return {
      CategoryId: reservationInfo.categoryId || null,
      PersonAssetId: reservationInfo.personAssetUnitNo || null,
      ReservationId: reservationInfo.reservationId,
      PromisedStartTime: reservationInfo.rentalDateTime || null,
    };
  }
};
const getInfoForIntegrationHistoryModal_new = (reservationInfo) => {
  return {
    integStatus: reservationInfo.integStatus === "SCHEDULED" ? true : false,
    reservationId: reservationInfo.reservationId || null,
  };
};
const getInfoForAcceptDeclineReservationModal_new = (reservationInfo) => {
  return {
    reservationId: reservationInfo.reservationId || null,
    borrowerId: reservationInfo.customerId || null,
    borrowerNotes: reservationInfo.renterComments || null,
    createdOn: reservationInfo.creationDateTime || null,
  };
};
const getInfoForUpdateReservationCreationTypeModal_new = (reservationInfo) => {
  return {
    reservationId: reservationInfo.reservationId || null,
    reservationCreationType: reservationInfo.reservationCreationType || null,
  };
};
const getInfoForReservationActionSummaryModal_new = (reservationInfo) => {
  return {
    adjustedBy: reservationInfo.integAdjustedByName || null,
    arrivedBy: reservationInfo.integArrivedByName || null,
    completedBy: reservationInfo.integCompletedByName || null,
  };
};
const getInfoForDamageModal_new = (reservationInfo) => {
  return {
    memberId: reservationInfo.customerId || null,
    reservationId: reservationInfo.reservationId || null,
    memberAssetId: reservationInfo.reservationAssetId || null,
  };
};
export const getInfoForReservationNotesModal_new = (
  reservationData,
  notesType
) => {
  return {
    reservationId: reservationData.reservationId,
    notesType: notesType,
    notes:
      notesType === "BORROWER"
        ? reservationData.renterComments
        : notesType === "CALL_CENTER"
        ? reservationData.callCenterNotes
        : null,
    modalTitle:
      notesType === "BORROWER"
        ? "Add / Edit Customer Note"
        : notesType === "CALL_CENTER"
        ? "Add / Edit Internal Memo"
        : null,
  };
};
export const getInfoForEditConfirmationCodeModal_new = (reservationData) => {
  return {
    reservationId: reservationData.reservationId,
    confirmationCode: reservationData.confirmationCode,
    integConfirmNumber: reservationData.integConfirmNumber || null,
    xpressEnabled: reservationData.xpressEnabled || null,
    xpress: reservationData.xpress || false,
    voucherAdded: reservationData.voucherAdded || null,
    voucherAmount: reservationData.voucherAmount || null,
    partnerConfirmationKey: reservationData.externalReservationCode || null,
    integResvConfNum: reservationData.integResvConfNum,
    balanceDue: reservationData.reservationVoucher
      ? reservationData.reservationVoucher.voucherAmount
      : null,
  };
};
export const getInfoForUpdateReservationDetails = (reservationData) => {
  return {
    reservationId: reservationData.reservationId,
    confirmationCode: reservationData.confirmationCode,
    productCode: reservationData.productCode || null,
  };
};
export const getInfoForCancellationModal_new = (reservationData, modalType) => {
  return {
    modalType: modalType,
    modalTitle:
      modalType === ModalType.CANCEL_FOX_MODAL
        ? reservationData.isRental
          ? "Cancel Rental"
          : "Cancel Reservation"
        : "Cancellation Information",
    customerInfo: { ...reservationData.customerInfo },
    confirmationCode: reservationData.confirmationCode,
    reservationId: reservationData.reservationId,
    reservationCode: reservationData.reservationCode,
    externalReservationCode: reservationData.externalReservationCode,
    reservationState: reservationData.reservationState,
    fullName: reservationData.fullName,
    cancellationReasons: reservationData.cancellationReasons,
    cancellationNotes: reservationData.cancellationNotes,
    cancelledByPersonId: reservationData.cancelledByPersonId,
    cancelledByPersonName: reservationData.cancelledByPersonName,
    cancelledByType: reservationData.cancelledByType,
    cancelledOn: reservationData.cancelledOn,
    cancelledOnDisplayText: reservationData.cancelledOnDisplayText,
    cancellationFee: reservationData.cancellationFee || null,
    timezoneString: reservationData.timezoneString || null,
    isEditable:
      modalType === ModalType.CANCEL_FOX_MODAL ||
      reservationData.createdMethodValue === "CALL_CENTER" ||
      reservationData.customerId !== reservationData.cancelledByPersonId ||
      ((reservationData.lastModifiedMethod === "WEB" ||
        reservationData.LastModifiedMethod === "PARTNER_WEB" ||
        reservationData.lastModifiedMethod === "PHONE") &&
        (!reservationData.cancellationReasons ||
          reservationData.cancellationReasons.length === 0))
        ? true
        : false,
  };
};
export const getInfoForQuickChannelModal_new = (reservationInfo) => {
  return {
    partnerConfirmationKey: reservationInfo.partnerConfirmationKey || null,
    source: reservationInfo.source || null,
    apiLicense: reservationInfo.apiLicense || {},
    partner: reservationInfo.partner || {},
    provider: reservationInfo.provider || {},
  };
};
export const getInfoForCancellationModal_Old = (reservationData, modalType) => {
  return {
    modalType: modalType,
    modalTitle:
      modalType === ModalType.CANCEL_RESERVATION_MODAL
        ? reservationData.isRental
          ? "Cancel Rental"
          : "Cancel Reservation"
        : "Cancellation Information",
    reservationId: reservationData.reservationId,
    cancelledOn: reservationData.cancelledOn || null,
    cancelledOnDisplayText: reservationData.cancelledOnDisplayText || null,
    cancelledBy: reservationData.cancelledByPersonName || null,
    cancellationReasons: reservationData.cancellationReasons || [],
    cancellationFee: reservationData.cancellationFee || null,

    cancellationNotes: reservationData.cancellationNotes || null,
    cancellationFeePolicy: reservationData.cancellationFeePolicy || null,
    memberId: reservationData.customerId,
    totalCancellationFee: reservationData.pricing
      ? reservationData.pricing.totalCancellationCharges
      : null,
    cancellationCourtesyAmount: reservationData.pricing
      ? reservationData.pricing.cancellationCourtesyAmount
      : null,
    timezoneString: reservationData.timezoneString || null,
    isEditable:
      modalType === ModalType.CANCEL_RESERVATION_MODAL ||
      reservationData.createdMethodValue === "CALL_CENTER" ||
      reservationData.customerId !== reservationData.cancelledByPersonId ||
      ((reservationData.lastModifiedMethod === "WEB" ||
        reservationData.lastModifiedMethod === "PHONE") &&
        (!reservationData.cancellationReasons ||
          reservationData.cancellationReasons.length === 0))
        ? true
        : false,
    isPayLater: reservationData.isPayLater,
  };
};
export const getReservationModalDefaultInfo_new = (
  modalType,
  reservationData,
  notesOrMailType,
  isBillingInfo
) => {
  if (
    modalType === ModalType.CANCEL_FOX_MODAL ||
    modalType === ModalType.CANCEL_REASON_UPDATE_MODAL
  ) {
    return getInfoForCancellationModal_new(reservationData, modalType);
  } else if (modalType === ModalType.CANCEL_RESERVATION_MODAL) {
    return getInfoForCancellationModal_Old(reservationData, modalType);
  } else if (modalType === ModalType.FOX_CHANNEL_QUICK_MODAL) {
    return getInfoForQuickChannelModal_new(reservationData);
  } else if (modalType === ModalType.VEHICLE_CATEGORY_QUICK_MODAL) {
    return getInfoForCategoryModal_new(reservationData, isBillingInfo);
  } else if (modalType === ModalType.RESERVATION_NOTES_MODAL) {
    return getInfoForReservationNotesModal_new(
      reservationData,
      notesOrMailType
    );
  } else if (modalType === ModalType.INTEGRATION_HISTORY_MODAL) {
    return getInfoForIntegrationHistoryModal_new(reservationData);
  } else if (modalType === ModalType.EXTERNAL_PARAMETER_UPDATE_MODAL) {
    return getInfoForEditConfirmationCodeModal_new(reservationData);
  } else if (modalType === ModalType.ACCEPT_DECLINE_RESERVATION_MODAL) {
    return getInfoForAcceptDeclineReservationModal_new(reservationData);
  } else if (modalType === ModalType.UPDATE_RESERVATION_CREATION_TYPE_MODAL) {
    return getInfoForUpdateReservationCreationTypeModal_new(reservationData);
  } else if (modalType === ModalType.RESERVATION_ACTION_SUMMARY_MODAL) {
    return getInfoForReservationActionSummaryModal_new(reservationData);
  } else if (modalType === ModalType.VOUCHER_INFORMATION_MODAL) {
    //return  getInfoForQuickChannelModal_new(reservationData);
  } else if (modalType === ModalType.RESERVATION_DETAILS_UPDATE) {
    return getInfoForUpdateReservationDetails(reservationData);
  }
  if (modalType === ModalType.DAMAGE_MODAL) {
    return getInfoForDamageModal_new(reservationData);
  }
};

export const getReservationDetailsPageName = (url) => {
  return url === "/reservation_details/overview"
    ? ADMIN_PAGE.RES_DETAILS_OVERVIEW_PAGE
    : url === "/reservation_details/billing"
    ? ADMIN_PAGE.RES_DETAILS_BILLING_SEARCH_PAGE
    : url === "/reservation_details/report_problem"
    ? ADMIN_PAGE.REPORT_PROBLEM_DETAILS_PAGE
    : url === "/reservation_details/message"
    ? ADMIN_PAGE.RESERVATION_MESSAGE_SEARCH_PAGE
    : url === "/reservation_details/vehicle_details"
    ? ADMIN_PAGE.RES_DETAILS_VEHICLE_DETAILS_PAGE
    : url === "/reservation_details/renter_details"
    ? ADMIN_PAGE.RES_DETAILS_RENTER_DETAILS_PAGE
    : url === "/reservation_details/feedback"
    ? ADMIN_PAGE.RES_DETAILS_FEEDBACK_PAGE
    : "";
};
export const getIconStringForExport = (
  hasAddons,
  hasContract,
  hasNotes,
  isTestRes,
  isVoucherAdded
) => {
  let iconString = "";
  if (hasAddons) {
    if (iconString) {
      iconString += " | ";
    }
    iconString += "AO";
  }
  if (hasContract) {
    if (iconString) {
      iconString += " | ";
    }
    iconString += "CA";
  }
  if (hasNotes) {
    if (iconString) {
      iconString += " | ";
    }
    iconString += "IM";
  }
  if (isTestRes) {
    if (iconString) {
      iconString += " | ";
    }
    iconString += "TR";
  }
  // if (isVoucherAdded) {
  //   if (iconString) {
  //     iconString += " | ";
  //   }
  //   iconString += "VA";
  // }
  return iconString;
};
export const getTotalSelectedChildSeatAddons = (
  selectedAddons,
  availableAddOns
) => {
  let listOfSelectedAddOnsWithChildSeat =
    selectedAddons &&
    selectedAddons.length &&
    selectedAddons.filter((addon) => addon.maxQuantity > 1);
  let listOfAvailableAddOnsWithChildSeat =
    availableAddOns && availableAddOns.length
      ? availableAddOns.filter((addon) => addon.maxQuantity > 1)
      : listOfSelectedAddOnsWithChildSeat;

  let selectedChildSeatCodes =
    listOfAvailableAddOnsWithChildSeat.length &&
    JSON.parse(JSON.stringify(listOfAvailableAddOnsWithChildSeat)).map(
      (addons) => addons.code
    );

  let totalSelectedChildSeats = 0;
  if (listOfSelectedAddOnsWithChildSeat.length) {
    totalSelectedChildSeats = listOfSelectedAddOnsWithChildSeat.reduce(
      (sum, addons) => {
        return sum + (addons.quantity || 0);
      },
      0
    );
  }
  return {
    totalSelectedChildSeats: totalSelectedChildSeats,
    selectedChildSeatCodes: selectedChildSeatCodes || [],
  };
};
export const getTotalSelectedAddons = (selectedAddons) => {
  let totalSelectedAddons = 0;
  if (selectedAddons.length) {
    totalSelectedAddons = selectedAddons.reduce((sum, addons) => {
      return sum + (addons.quantity || 0);
    }, 0);
  }
  return totalSelectedAddons;
};

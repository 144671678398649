export const FavouriteAssetsRequest = function () {
  this.MemberId = null;
  this.AssetType = null;
  this.Status = null;
  this.PersonAssetAvailabilityStatus = null;
  this.PageCount = null;
  this.RecordPerPage = null;
  this.SortBy = null;
  this.SortType = null;
};

import React, { Fragment } from "react";
import {
  isNullOrEmpty,
  convertSecondToDayHourMinSec,
  convertDateTimeString,
  convertDateTimeStringWithTimezone,
} from "../../../shared/utility";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import Draggable from "react-draggable";
import { noImage } from "../../../shared/AppImages";
import { LabelAndValuePairControl } from "../../../components/UI/Input/label-value-pair-control";

const TrackerDetailsQuickView = (props) => {
  const trackerDetails = props.trackerDetails;
  const showModal = props.showModal;
  const renderLabelInputPair = (
    labelCaption,
    value,
    isTooltipEnabled = false,
    tooltipValue = "",
    isTooltipForceShow = false
  ) => {
    return (
      <LabelAndValuePairControl
        elementConfig={{ value: value }}
        otherConfig={{
          caption: labelCaption,
          toolTipMsg: isTooltipEnabled ? tooltipValue : "",
          isForceShow: isTooltipForceShow || false,
        }}
        parentRowClass="popup-row"
        labelClasses="font-weight-bold"
      />
    );
  };
  return (
    <div>
      <Draggable handle=".cursor">
        <Modal
          isOpen={showModal}
          className="no-cursor"
          toggle={() => {
            props.closeModal();
          }}
          style={{
            minWidth: "90rem",
          }}
        >
          <ModalHeader
            className="modal-header bg-primary modal-title text-white cursor"
            toggle={() => {
              props.closeModal();
            }}
          >
            <i className="fas fa-arrows-alt" /> Tracker Quick View
          </ModalHeader>
          <ModalBody>
            <div
              className="form-group row"
              style={{
                overflow: "auto",
              }}
            >
              <div
                id="tracker-quick-view"
                className="row col-lg-12 p-0 thinnerScroll bb-0 position--unset"
              >
                <Fragment>
                  <div className="col-lg-6 position--unset">
                    <h4 className="form-header text-uppercase">
                      <i className="fas fa-car-alt" /> Tracker Info
                    </h4>
                    {renderLabelInputPair(
                      "Tracker Id",
                      trackerDetails.TrackerId || "N/A"
                    )}
                    {renderLabelInputPair(
                      "Tracker Type",
                      trackerDetails.TrackerType || "N/A"
                    )}
                    {renderLabelInputPair(
                      "Status",
                      trackerDetails.Status || "N/A"
                    )}
                    {renderLabelInputPair(
                      "Creation Date",
                      trackerDetails.CreatedOn
                        ? convertDateTimeString(trackerDetails.CreatedOn)
                        : "",
                      true,
                      trackerDetails.CreatedOn
                        ? convertDateTimeStringWithTimezone(
                            trackerDetails.CreatedOn,
                            trackerDetails.TimezoneString
                          )
                        : "",
                      true
                    )}
                    {renderLabelInputPair(
                      "R.A Number",
                      trackerDetails.RANum || "N/A"
                    )}
                    {renderLabelInputPair(
                      "Assigned By",
                      trackerDetails.AssignedByName || "N/A"
                    )}
                    {renderLabelInputPair(
                      "Assigned To",
                      trackerDetails.AssignedToName || "N/A"
                    )}
                    {renderLabelInputPair(
                      "Audit Status",
                      trackerDetails.AuditStatus || "N/A"
                    )}

                    <div className="col-lg-12 p-0">
                      <h4 className="form-header text-uppercase">
                        <i className="fas fa-car-alt"></i> Agent Info
                      </h4>
                    </div>
                    {renderLabelInputPair(
                      "Agent Name",
                      trackerDetails.AgentName || "N/A"
                    )}
                    {renderLabelInputPair(
                      "Badge ID",
                      trackerDetails.AgentBadgeNo || "N/A"
                    )}
                    {renderLabelInputPair(
                      "Grade",
                      trackerDetails.Grade || "N/A"
                    )}
                    {renderLabelInputPair(
                      "Profile Location",
                      trackerDetails.ProfileLocationName || "N/A"
                    )}
                    <div className="col-lg-12 p-0">
                      <h4 className="form-header text-uppercase">
                        <i className="fas fa-car-alt"></i> Processing Stats
                      </h4>
                    </div>
                    {renderLabelInputPair(
                      "Processing Time",
                      trackerDetails.ProcessingTime
                        ? convertSecondToDayHourMinSec(
                            trackerDetails.ProcessingTime
                          )
                        : "--"
                    )}
                    {renderLabelInputPair(
                      "Start Date/Time",
                      trackerDetails.StartTime
                        ? convertDateTimeString(trackerDetails.StartTime)
                        : "",
                      true,
                      trackerDetails.StartTime
                        ? convertDateTimeStringWithTimezone(
                            trackerDetails.StartTime,
                            trackerDetails.TimezoneString
                          )
                        : "",
                      true
                    )}
                    {renderLabelInputPair(
                      "End Date/Time",
                      trackerDetails.EndTime
                        ? convertDateTimeString(trackerDetails.EndTime)
                        : "",
                      true,
                      trackerDetails.EndTime
                        ? convertDateTimeStringWithTimezone(
                            trackerDetails.EndTime,
                            trackerDetails.TimezoneString
                          )
                        : "",
                      true
                    )}
                    {renderLabelInputPair(
                      "Individually Performed",
                      trackerDetails.IndividuallyPerformed ? "Yes" : "No"
                    )}
                  </div>
                  <div className="col-lg-6">
                    <h4 className="form-header text-uppercase">
                      <i className="fas fa-car-alt" /> Vehicle Info
                    </h4>
                    {renderLabelInputPair(
                      "Vehicle ID",
                      trackerDetails.VehicleId || "N/A"
                    )}
                    {renderLabelInputPair("VIN", trackerDetails.Vin || "N/A")}
                    {renderLabelInputPair(
                      "VIN Manual Entry",
                      !isNullOrEmpty(trackerDetails.VinManualEntry)
                        ? trackerDetails.VinManualEntry
                          ? "Yes"
                          : "No"
                        : "--"
                    )}
                    {renderLabelInputPair(
                      "Stall No",
                      trackerDetails.StallNumber || "N/A"
                    )}
                    {renderLabelInputPair(
                      "In Stall",
                      trackerDetails.InStall || "N/A"
                    )}
                    <div className="col-lg-12 p-0">
                      <h4 className="form-header text-uppercase">
                        <i className="fas fa-car-alt"></i> Additional Info
                      </h4>
                    </div>
                    {renderLabelInputPair(
                      "Comments",
                      trackerDetails.Comments || "N/A"
                    )}
                    {renderLabelInputPair(
                      "Notes",
                      trackerDetails.Notes || "N/A"
                    )}
                    {renderLabelInputPair(
                      "Service Notes",
                      trackerDetails.ServiceNotes || "N/A"
                    )}
                    <div className="col-lg-12 p-0">
                      <h4 className="form-header text-uppercase">
                        <i className="fas fa-car-alt"></i> Image
                      </h4>
                    </div>
                    <div className="popup-row vertical-align-center">
                      <img
                        src={trackerDetails.Image1Url || noImage}
                        alt="Vehicle"
                        className="card-img-top"
                        style={{
                          width: "auto",
                          height: "auto",
                          padding: "1rem",
                          maxHeight: "14rem",
                        }}
                      />
                    </div>
                  </div>
                </Fragment>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </Draggable>
    </div>
  );
};

export default TrackerDetailsQuickView;

import * as global from "../../shared/GlobalVar";
import { getBillingFilters } from "../../containers/billing/search-billing-input";
import { getReadyLineAgentFilters, getServiceTaskFilters } from "../../containers/report/search/search-service-task-input";
import {
  getReservationFilters,
  getRentalFilters,
  getMemberReservationFilters
} from "../../containers/Reservation/search/search-reservation-input";
import { getReportProblemFilters } from "../../containers/report/details/report-problem-input";

export const loadFilterCtrls = pageName => {
  let filterCtrls;
  if (pageName === global.ADMIN_PAGE.RESERVATION_SEARCH_PAGE) {
    filterCtrls = getReservationFilters();
  } else if (pageName === global.ADMIN_PAGE.RENTAL_SEARCH_PAGE) {
    filterCtrls = getRentalFilters();
  } else if (pageName === global.ADMIN_PAGE.MEMBER_RESERVATION_SEARCH_PAGE) {
    filterCtrls = getMemberReservationFilters();
  } else if (
    pageName === global.ADMIN_PAGE.MEMBER_VEHICLE_RESERVATION_SEARCH_PAGE
  ) {
    filterCtrls = getMemberReservationFilters();
  } else if (
    pageName === global.ADMIN_PAGE.BILLING_SEARCH_PAGE ||
    pageName === global.ADMIN_PAGE.RES_DETAILS_BILLING_SEARCH_PAGE
  ) {
    filterCtrls = getBillingFilters();
  } else if (pageName === global.ADMIN_PAGE.MEMBER_BILLING_SEARCH_PAGE) {
    filterCtrls = getBillingFilters();
  } else if (pageName === global.ADMIN_PAGE.SERVICE_AGENT_SEARCH_PAGE) {
    filterCtrls = getServiceTaskFilters();
  } else if (pageName === global.ADMIN_PAGE.MECHANIC_APP_REPORT_SEARCH_PAGE) {
    filterCtrls = getServiceTaskFilters();
  } else if (pageName === global.ADMIN_PAGE.READY_LINE_AGENT_SEARCH_PAGE) {
    filterCtrls = getReadyLineAgentFilters();
  } else if (pageName === global.ADMIN_PAGE.REPORT_PROBLEM_DETAILS_PAGE) {
    filterCtrls = getReportProblemFilters();
  }
  return JSON.parse(JSON.stringify(filterCtrls.FilterInfo));
};
export const getDefaultFilterStates = url => {
  return url === "/reservation"
    ? global.RESERVATION_STATES_RESET
    : url === "/member/memberReservation"
    ? global.MEMBER_RESERVATION_STATES_RESET
    : url === "/member/memberVehicleReservation"
    ? global.MEMBER_RESERVATION_STATES_RESET
    : url === "/rental"
    ? global.RENTAL_FILTER_STATES_ALL
    : url === "/billing" ||
      url === "/member/billing" ||
      url === "/reservation_details/billing"
    ? global.BILLING_RESERVATION_STATES
    : url === "/report/maintenance"
    ? global.SERVICE_AGENT_STATES_RESET
    : url === "/report/mechanics"
    ? global.SERVICE_AGENT_STATES_RESET
    : url === "/report/readyLineAgent"
    ? global.READY_LINE_AGENT_STATES_RESET
    : url === "/reservation_details/report_problem"
    ? global.REPORT_PROBLEM_STATES_ALL
    : "";
};
export const getAllFilterStates = url => {
  return url === "/reservation"
    ? global.RESERVATION_STATES_ALL
    : url === "/member/memberReservation"
    ? global.MEMBER_RESERVATION_STATES_ALL
    : url === "/member/memberVehicleReservation"
    ? global.MEMBER_RESERVATION_STATES_ALL
    : url === "/rental"
    ? global.RENTAL_FILTER_STATES_ALL
    : url === "/billing"
    ? global.BILLING_RESERVATION_STATES
    : url === "/member/billing"
    ? global.BILLING_RESERVATION_STATES
    : url === "/report/maintenance"
    ? global.SERVICE_AGENT_STATES_ALL
    : url === "/report/mechanics"
    ? global.SERVICE_AGENT_STATES_ALL
    : url === "/report/readyLineAgent"
    ? global.READY_LINE_AGENT_STATES_ALL
    : url === "/reservation_details/report_problem"
    ? global.REPORT_PROBLEM_STATES_ALL
    : "";
};

import React from 'react';
import { CANCEL_01 } from '../../../shared/AppImages';
import { MessageBoxType } from '../../../shared/GlobalVar';
import { isNullOrEmpty } from '../../../shared/utility';

const calculatePositionStyles = (messageBoxType, iconRect, parentRect) => {
  const baseTop = parentRect.top - iconRect.top + iconRect.height;
  const topOffset = {
    [MessageBoxType.PAY_NOW_ADD_ON_DISCLOSURE]: 24,
    [MessageBoxType.TAXES_FEES_SUMMARY]: 24,
    [MessageBoxType.FREE_CREDIT_POLICY]: 24,
    [MessageBoxType.ADDON_DETAILS]: 20,
  };
  const rightOffset = {
    [MessageBoxType.FREE_CREDIT_POLICY]: 0,
    [MessageBoxType.SECURITY_POLICY]: 100,
    [MessageBoxType.PAY_NOW_ADD_ON_DISCLOSURE]: 100,
    [MessageBoxType.TAXES_FEES_SUMMARY]: 15,
  };


  const top = baseTop + (topOffset[messageBoxType] || 44);
  const left = [MessageBoxType.FREE_CREDIT_POLICY, MessageBoxType.SECURITY_POLICY, MessageBoxType.PAY_NOW_ADD_ON_DISCLOSURE, MessageBoxType.TAXES_FEES_SUMMARY].includes(messageBoxType)
    ? "auto"
    : iconRect.right - parentRect.right - 25;
  const right = !isNullOrEmpty(rightOffset[messageBoxType]) ? rightOffset[messageBoxType] : "auto";

  return { top, left, right };
};

const MessageBox = ({ id = "message-box", title, subtitle, message, onClose, messageBoxType, iconRef }) => {
  const iconRect = iconRef.current.getBoundingClientRect();
  const parentRect = iconRef.current.parentElement.getBoundingClientRect();
  const positionStyles = calculatePositionStyles(messageBoxType, iconRect, parentRect);

  return (
    <div className="message-box" id={id} style={positionStyles}>
      <div className="message-header">
        <span className="message-title">{title}</span>
        <button className="close-button" onClick={onClose}>
          <img src={CANCEL_01} height={20} width={20} alt={title} />
        </button>
      </div>
      <hr />
      {subtitle && <div className="message-subtitle">{subtitle}</div>}
      <div className="message-content">
        <span className="message">{message}</span>
      </div>
    </div>
  );
};

export default MessageBox;

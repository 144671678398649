import React from 'react';
import SelectCtrl from '../../../../components/UI/Input/select-ctrl';
import { MaxAddon } from '../../../../shared/utility/enum-utility';

function AddonsCountSelectContainer(props) {
  const {
    addonData,
    onSelectCounter,
    addonsQuantity,
    disabled
  } = props;
  const getMaxEquipmentQuantity = (curQuantity) => {
    const freeSlot = (MaxAddon?.TOTAL_ADDONS - addonsQuantity.totalAddons)
      < (MaxAddon?.EQUIP_QUANTITY - addonsQuantity?.equipQuantity) ? (MaxAddon?.TOTAL_ADDONS - addonsQuantity.totalAddons)
      : (MaxAddon?.EQUIP_QUANTITY - addonsQuantity?.equipQuantity);
    return curQuantity ? curQuantity + freeSlot : freeSlot;
  }
  const getCounterOptions = () => {
    const options = [];
    const curQuantity = addonData?.quantity || 0;
    for (let i = 0; i <= addonData?.maxQuantity; i++) {
      options.push({
        key: i,
        value: i.toString().padStart(2, 0),
        disabled: i > getMaxEquipmentQuantity(curQuantity)
      });
    }
    return options;
  }
  const onhandleChange = (event) => {
    onSelectCounter({
      code: addonData.code,
      quantity: parseInt(event?.target?.value || 0),
      maxQuantity: addonData.maxQuantity,
    }, event?.target?.value > 0 ? true : false);
  }
  return (
    <div className='flex-space-between counter-addons'>
      <SelectCtrl
        name="count-select"
        value={addonData?.quantity || 0}
        options={getCounterOptions()}
        onChange={onhandleChange}
        //iconClass="fa fa-info"
        classNames="count-select"
        disabled={disabled}
      />
    </div>
  )
}

export default AddonsCountSelectContainer
import React from 'react';
import ConfirmModal from '../../../components/common/modal/confirm-modal';
import { ModalType } from '../../../shared/GlobalVar';

function ReservationConfirmModal(props) {
    const { externalCode, onCreateUpdateReservation, onGetReservation, onSwitchToNewReservationMode,
        markAsTestReservation, modalType, onCLoseModal } = props;
    const getModalBody = () => {
        if (modalType === ModalType.RESERVATION_UPDATE_CONFIRM) return `Please confirm to ${externalCode ? "update" : "Create"} your reservation`
        else if (modalType === ModalType.RESERVATION_CANCEL_CHANGE_CONFIRM) return `Are you sure you want to cancel all changes and revert to ${externalCode ? "the empty page" : "the original values"
            } for this Reservation?`;
        else if (modalType === ModalType.RESERVATION_TEST_CONFIRM) return "Do you confirm this is a test reservation?";
    }
    const getModalTitle = () => {
        if (modalType === ModalType.RESERVATION_UPDATE_CONFIRM) return externalCode ? "Update Reservation" : "Create Reservation";
        else if (modalType === ModalType.RESERVATION_CANCEL_CHANGE_CONFIRM) return "Cancel Changes";
        else if (modalType === ModalType.RESERVATION_TEST_CONFIRM) return "CONFIRM";
    }
    const getConfirmBtnTitle = () => {
        if (modalType === ModalType.RESERVATION_UPDATE_CONFIRM) return externalCode ? "Update Reservation" : "Create Reservation";
        else return "Yes";
    }
    const getConfirmFunctionality = () => {
        if (modalType === ModalType.RESERVATION_UPDATE_CONFIRM) return onCreateUpdateReservation;
        else if (modalType === ModalType.RESERVATION_CANCEL_CHANGE_CONFIRM) return externalCode ? onGetReservation : onSwitchToNewReservationMode;
        else if (modalType === ModalType.RESERVATION_TEST_CONFIRM) return markAsTestReservation;
    }
    return (
        modalType ? (
            <ConfirmModal
                body={getModalBody()}
                showModal={modalType && true}
                closeModal={onCLoseModal}
                doConfirmFunctionality={getConfirmFunctionality()}
                modalTtile={getModalTitle()}
                yesBtnLabel={getConfirmBtnTitle()}
                noBtnLabel="No"
            />
        ) :
            ""
    )
}

export default ReservationConfirmModal
import { COMMON_MSG } from "../../shared/GlobalVar";
import React from "react";
import {
  isNullOrEmpty,
  validateEmail,
  getCurrentServerDate,
  getDateDifferentInDays,
  getDateOnlyString,
  getDisplayTextOfDropdown,
  getFormattedValidationMsg,
} from "../../shared/utility";
import ReactHtmlParser from "react-html-parser";
import { ADMIN_PAGE } from "../../shared/GlobalVar";
import CommonInputField from "../../components/UI/Input/common-input-field";
import { getDateTimeFormatString } from "../../components/common/common-helper";

export const getSettingPageName = (url) => {
  return url === "/setting/main"
    ? ADMIN_PAGE.MAIN_SETTINGS_PAGE
    : url === "/setting/hourOfOperation"
    ? ADMIN_PAGE.HOURS_OF_OPERATIONS
    : url === "/setting/taxesSurcharges"
    ? ADMIN_PAGE.TAX_SURCHARGE_SETTINGS
    : url === "/setting/qualifications"
    ? ADMIN_PAGE.QUALIFICATION_SETTINGS
    : url === "/setting/resSettings"
    ? ADMIN_PAGE.RESERVATION_SETTINGS
    : url === "/setting/insurance"
    ? ADMIN_PAGE.INSURANCE_SETTINGS
    : url === "/setting/addOnsSettings"
    ? ADMIN_PAGE.ADDITIONAL_OPTIONS_SETTINGS
    : url === "/setting/location"
    ? ADMIN_PAGE.LOCATION_SETTINGS
    : url === "/setting/rates"
    ? ADMIN_PAGE.RATE_SETTINGS
    : url === "/setting/capacityPlanning"
    ? ADMIN_PAGE.CAPACITY_SETTINGS
    : url === "/setting/shuttles"
    ? ADMIN_PAGE.SHUTTLES_SETTINGS
    : url === "/setting/vehicleSettings"
    ? ADMIN_PAGE.VEHICLE_AVAILABILITY_SETTINGS
    : url === "/setting/roles"
    ? ADMIN_PAGE.ROLES_SETTINGS
    : url === "/setting/permission"
    ? ADMIN_PAGE.PERMISSION_SETTINGS
    : url === "/setting/blackListedPerson"
    ? ADMIN_PAGE.DO_NOT_RENT_LIST_SETTINGS
    : "";
};

export const validateAddonSettings = (data) => {
  let msg = "";
  if (data) {
    if (!data.Value) {
      msg = "Option Code";
    }
    if (!data.DisplayText) {
      if (msg) {
        msg += ", ";
      }
      msg = msg + "Option Name ";
    }
    if (!data.Type) {
      if (msg) {
        msg += ", ";
      }
      msg = msg + "Type ";
    }
    if (!data.UnitType) {
      if (msg) {
        msg += ", ";
      }
      msg = msg + "Unit Type ";
    }
    if (!data.MaxQuantity) {
      if (msg) {
        msg += ", ";
      }
      msg = msg + "Max Quantity ";
    }
    if (!data.Rate) {
      if (msg) {
        msg += ", ";
      }
      msg = msg + "Rate ";
    }
  }
  if (msg) {
    return getFormattedValidationMsg(msg);
  }
};

export const validateLocationSettings = (data) => {
  let msg = "";
  let emailList = "";
  let isValidEmail = true;
  let validEmail = true;
  if (data.LocationInfo) {
    if (!data.LocationInfo.LocationCode) {
      msg = COMMON_MSG.LocationCode;
    }
    if (!data.LocationInfo.Name) {
      if (msg) {
        msg += ", ";
      }
      msg = msg + "Name";
    }
    if (!data.LocationInfo.DisplayName) {
      if (msg) {
        msg += ", ";
      }
      msg = msg + COMMON_MSG.DisplayName;
    }
    if (data.LocationInfo.Address) {
      if (!data.LocationInfo.Address.Street1) {
        if (msg) {
          msg += ", ";
        }
        msg = msg + COMMON_MSG.Address1;
      }

      if (!data.LocationInfo.Address.Country) {
        if (msg) {
          msg += ", ";
        }
        msg = msg + COMMON_MSG.Country;
      }

      if (
        !data.LocationInfo.Address.State &&
        !data.LocationInfo.Address.StateText
      ) {
        if (msg) {
          msg += ", ";
        }
        msg = msg + COMMON_MSG.State;
      }

      if (
        !data.LocationInfo.Address.City &&
        !data.LocationInfo.Address.CityText
      ) {
        if (msg) {
          msg += ", ";
        }
        msg = msg + COMMON_MSG.City;
      }

      if (
        !data.LocationInfo.Address.ZipCode &&
        !data.LocationInfo.Address.ZipCodeText
      ) {
        if (msg) {
          msg += ", ";
        }
        msg = msg + COMMON_MSG.ZipPostal;
      }
    }
    if (!data.LocationInfo.TimeZone) {
      if (msg) {
        msg += ", ";
      }
      msg = msg + COMMON_MSG.TimeZone;
    }
    if (!data.LocationInfo.TimeZoneDelta) {
      if (msg) {
        msg += ", ";
      }
      msg = msg + COMMON_MSG.TimeZoneDelta;
    }

    if (!data.LocationInfo.TimeZoneStandardDelta) {
      if (msg) {
        msg += ", ";
      }
      msg = msg + COMMON_MSG.TimeZoneStandardDelta;
    }

    if (data.LocationInfo.Email) {
      emailList = data.LocationInfo.Email.split(",");
      isValidEmail = true;
      emailList.forEach((element) => {
        validEmail = validateEmail(element);
        if (!validEmail) {
          isValidEmail = false;
          return false;
        }
      });
      if (!isValidEmail) {
        if (msg) {
          msg += ", ";
        }
        msg += COMMON_MSG.EmailRequired;
      }
    }

    if (data.LocationInfo.EmailBcc) {
      emailList = data.LocationInfo.EmailBcc.split(",");
      isValidEmail = true;
      emailList.forEach((element) => {
        validEmail = validateEmail(element);
        if (!validEmail) {
          isValidEmail = false;
          return false;
        }
      });
      if (!isValidEmail) {
        if (msg) {
          msg += ", ";
        }
        msg += COMMON_MSG.ValidEmailBcc;
      }
    }
  }
  if (msg) {
    return getFormattedValidationMsg(msg);
  }
};

export const validateRolesSettings = (data) => {
  let msg = "";
  if (data) {
    if (!data.Name) {
      msg = COMMON_MSG.RoleName;
    }
    if (!data.Description) {
      if (msg) {
        msg += ", ";
      }
      msg = msg + COMMON_MSG.Description;
    }
    if (!data.Code) {
      if (msg) {
        msg += ", ";
      }
      msg = msg + COMMON_MSG.RoleCode;
    }
  }
  if (msg) {
    return getFormattedValidationMsg(msg);
  } else {
    if (data.Name) {
      if (!isContainCharactersWithoutUnderscore(data.Name)) {
        msg =
          COMMON_MSG.SpecialCharacterValidationMessage +
          " in " +
          COMMON_MSG.RoleName;
      }
    }

    if (data.Code && !msg) {
      if (!isContainCharactersWithoutUnderscore(data.Code)) {
        msg =
          COMMON_MSG.SpecialCharacterValidationMessage +
          " in " +
          COMMON_MSG.RoleCode;
      }
    }
  }
  return msg;
};

export const validatePermissionSettings = (data) => {
  let msg = "";
  if (data) {
    if (!data.Name) {
      msg = COMMON_MSG.PermissionName;
    }
    if (!data.DisplayText) {
      if (msg) {
        msg += ", ";
      }
      msg = msg + COMMON_MSG.Description;
    }
    if (!data.PermissionType) {
      if (msg) {
        msg += ", ";
      }
      msg = msg + COMMON_MSG.PermissionType;
    }
  }
  if (msg) {
    return getFormattedValidationMsg(msg);
  } else {
    if (data.ErrorCode) {
      if (!isContainCharactersWithoutUnderscore(data.ErrorCode)) {
        msg =
          COMMON_MSG.SpecialCharacterValidationMessage +
          " in " +
          COMMON_MSG.ErrorCode;
      }
    }
    if (data.URIs) {
      let names = data.URIs;
      let duplicateUrls = names.filter(
        (val, id, array) => array.indexOf(val) !== id
      );

      if (duplicateUrls.length > 0) {
        msg = COMMON_MSG.UniqueUrlValidation;
      }
    }
    return msg;
  }
};

export const validateMainOrgSettings = (data) => {
  let msg = "";
  let emailList = "";
  let isValidEmail = true;
  let validEmail = true;
  if (data) {
    if (!data.LocationCode) {
      msg = COMMON_MSG.LocationCode;
    }
    if (!data.DisplayName) {
      if (msg) {
        msg += ", ";
      }
      msg = msg + COMMON_MSG.DisplayName;
    }
    if (data.Address) {
      if (!data.Address.Street1) {
        if (msg) {
          msg += ", ";
        }
        msg = msg + COMMON_MSG.Address1;
      }

      if (!data.Address.Country) {
        if (msg) {
          msg += ", ";
        }
        msg = msg + COMMON_MSG.Country;
      }

      if (!data.Address.State && !data.Address.StateText) {
        if (msg) {
          msg += ", ";
        }
        msg = msg + COMMON_MSG.State;
      }

      if (!data.Address.City && !data.Address.CityText) {
        if (msg) {
          msg += ", ";
        }
        msg = msg + COMMON_MSG.City;
      }

      if (!data.Address.ZipCode && !data.Address.ZipCodeText) {
        if (msg) {
          msg += ", ";
        }
        msg = msg + COMMON_MSG.ZipPostal;
      }
    }
    if (!data.TimeZone) {
      if (msg) {
        msg += ", ";
      }
      msg = msg + COMMON_MSG.TimeZone;
    }
    if (!data.TimeZoneDelta) {
      if (msg) {
        msg += ", ";
      }
      msg = msg + COMMON_MSG.TimeZoneDelta;
    }

    if (!data.TimeZoneStandardDelta) {
      if (msg) {
        msg += ", ";
      }
      msg = msg + COMMON_MSG.TimeZoneStandardDelta;
    }

    if (!data.ContactPersonFirstName) {
      if (msg) {
        msg += ", ";
      }
      msg += COMMON_MSG.ContactPersonFirstName;
    }

    if (!data.ContactPersonLastName) {
      if (msg) {
        msg += ", ";
      }
      msg += COMMON_MSG.ContactPersonLastName;
    }

    if (data.ContactPersonEmail) {
      validEmail = validateEmail(data.ContactPersonEmail.trim());
      if (!validEmail) {
        if (msg) {
          msg += ", ";
        }
        msg += COMMON_MSG.ContractPersonEmailRequired;
      }
    } else {
      if (msg) {
        msg += ", ";
      }
      msg += COMMON_MSG.ContractPersonEmailRequired;
    }

    if (!data.ContactPersonPhone) {
      if (msg) {
        msg += ", ";
      }
      msg += COMMON_MSG.ContactPersonPhone;
    }

    if (data.Email) {
      emailList = data.Email.split(",");
      isValidEmail = true;
      emailList.forEach((element) => {
        validEmail = validateEmail(element);
        if (!validEmail) {
          isValidEmail = false;
          return false;
        }
      });
      if (!isValidEmail) {
        if (msg) {
          msg += ", ";
        }
        msg += COMMON_MSG.EmailRequired;
      }
    }

    if (data.EmailBcc) {
      emailList = data.EmailBcc.split(",");
      isValidEmail = true;
      emailList.forEach((element) => {
        validEmail = validateEmail(element);
        if (!validEmail) {
          isValidEmail = false;
          return false;
        }
      });
      if (!isValidEmail) {
        if (msg) {
          msg += ", ";
        }
        msg += COMMON_MSG.ValidEmailBcc;
      }
    }

    if (data.SupportEmails) {
      emailList = data.SupportEmails.split(",");
      isValidEmail = true;
      emailList.forEach((element) => {
        validEmail = validateEmail(element);
        if (!validEmail) {
          isValidEmail = false;
          return false;
        }
      });
      if (!isValidEmail) {
        if (msg) {
          msg += ", ";
        }
        msg += COMMON_MSG.ValidSupportEmails;
      }
    }
    if (data.CheckoutEmails) {
      emailList = data.CheckoutEmails.split(",");
      isValidEmail = true;
      emailList.forEach((element) => {
        validEmail = validateEmail(element);
        if (!validEmail) {
          isValidEmail = false;
          return false;
        }
      });
      if (!isValidEmail) {
        if (msg) {
          msg += ", ";
        }
        msg += COMMON_MSG.ValidCheckoutEmails;
      }
    }
  }
  if (msg) {
    return getFormattedValidationMsg(msg);
  }
  return msg;
};

export const validationRateSettings = (data) => {
  let msg = "";
  if (!data.StartDate) {
    msg = COMMON_MSG.StartDate;
  }
  if (!data.EndDate) {
    if (msg) {
      msg += ", ";
    }
    msg = msg + COMMON_MSG.EndDate;
  }

  if (msg) {
    return getFormattedValidationMsg(msg);
  } else {
    if (data.StartDate) {
      let currentDate = getCurrentServerDate(getDateTimeFormatString());
      let dateDiff = getDateDifferentInDays(
        getDateOnlyString(data.StartDate),
        currentDate
      );
      if (dateDiff > 0) {
        msg = COMMON_MSG.CurrentOrFutureDateValidation;
      }
    }
    if (data.StartDate && data.EndDate) {
      let dateDiff = getDateDifferentInDays(
        getDateOnlyString(data.StartDate),
        getDateOnlyString(data.EndDate)
      );
      if (dateDiff < 0) {
        if (msg) {
          msg += "<br/> ";
        }
        msg += COMMON_MSG.DateDurationValidation;
      }
    }
    if (
      isNullOrEmpty(data.RatePerHour) &&
      isNullOrEmpty(data.RatePerDay) &&
      isNullOrEmpty(data.RatePerWeekendDay) &&
      isNullOrEmpty(data.RatePerWeek) &&
      isNullOrEmpty(data.RatePerMonth)
    ) {
      if (msg) {
        if (msg) {
          msg += "<br/> ";
        }
        msg += "Please select at least one rate item.";
      }
    }
    return msg ? ReactHtmlParser(msg) : null;
  }
};

export const validationHourOfOperationSettings = (
  data,
  isHoliday,
  hoursOfOperationData,
  isHoursOfOperation
) => {
  let msg = "";
  if (isHoursOfOperation) {
    if (isNullOrEmpty(data.Day)) {
      msg = COMMON_MSG.DayValidation;
    }
    if (data.StartTime > data.EndTime) {
      if (msg) {
        msg += ", ";
      }
      msg = msg + COMMON_MSG.TimeDurationValidation;
    }
  }

  if (isHoliday) {
    if (!isHoursOfOperation && (!data.Date || String(data.Date) === "Invalid date")) {
      msg = "Date";
    }
    if (!msg) {
      if (data.StartTime > data.EndTime) {
        msg = COMMON_MSG.TimeDurationValidation;
      }
       let holidaysList = JSON.parse(
         JSON.stringify(hoursOfOperationData.ExceptionWorkingDates)
       );
       if (data.RowId !== null) {
         holidaysList = holidaysList.filter(
           (item) => item.RowId !== data.RowId
         );
       }
       holidaysList.forEach((times, index) => {
         if (!msg) {
           let dateDiff = getDateDifferentInDays(
             getDateOnlyString(times.Date),
             getDateOnlyString(data.Date)
           );
           if (dateDiff === 0) {
             if (msg) {
               msg += ", ";
             }
             msg = msg + COMMON_MSG.DuplicateHolidayValidation;
             return;
           }
         }
       });
    } else {
      return getFormattedValidationMsg(msg);
    }
  }
  return msg;
};

export const validateVehicleAvailabilityDataList = (data) => {
  let msg = "";
  if (data.MinPeriodConstraintList.length > 0) {
    data.MinPeriodConstraintList.map((dataObj, index) => {
      if (!msg) {
        if (dataObj.StartDate === null || dataObj.StartDate === "") {
          if (msg) {
            msg += ", ";
          }
          msg = msg + "From Date";
        }
        if (dataObj.EndDate === null || dataObj.EndDate === "") {
          if (msg) {
            msg += ", ";
          }
          msg = msg + "To Date";
        }

        if (dataObj.DaysOfWeek === null || dataObj.DaysOfWeek === "") {
          if (msg) {
            msg += ", ";
          }
          msg = msg + "Days of Week";
        }
      }
    });
  }
  if (data.OfflineConstraintList.length > 0) {
    data.OfflineConstraintList.map((dataObj, index) => {
      if (!msg) {
        if (dataObj.StartDate === null || dataObj.StartDate === "") {
          msg = "From Date";
        }
        if (dataObj.EndDate === null || dataObj.EndDate === "") {
          if (msg) {
            msg += ", ";
          }
          msg = msg + "To Date";
        }

        if (dataObj.DaysOfWeek === null || dataObj.DaysOfWeek === "") {
          if (msg) {
            msg += ", ";
          }
          msg = msg + "Days of Week";
        }
      }
    });
  }
  return msg;
};
export const validateVehicleAvailabilityData = (dataObj, isOffline) => {
  let msg = "";
  if (!dataObj.CategoryId) {
    msg = "Vehicle Type";
  }
  if (dataObj.StartDate === null || dataObj.StartDate === "") {
    if (msg) {
      msg += ", ";
    }
    msg = msg + "Start Date";
  }
  if (dataObj.EndDate === null || dataObj.EndDate === "") {
    if (msg) {
      msg += ", ";
    }
    msg = msg + "End Date";
  }

  if (dataObj.DaysOfWeek === null || dataObj.DaysOfWeek === "") {
    if (msg) {
      msg += ", ";
    }
    msg = msg + "Day Type";
  }
  if (msg) {
    return getFormattedValidationMsg(msg);
  } else {
    if (dataObj.StartDate && dataObj.EndDate) {
      let dateDiff = getDateDifferentInDays(
        getDateOnlyString(dataObj.StartDate),
        getDateOnlyString(dataObj.EndDate)
      );
      if (dateDiff < 0) {
        return COMMON_MSG.DateDurationValidation;
      }
    }
  }
};
export const validateTaxSurChargesData = (dataObj) => {
  let msg = "";
  if (dataObj.DisplayName === null || dataObj.DisplayName === "") {
    msg = "Description";
  }
  if (dataObj.TaxType === null || dataObj.TaxType === "") {
    if (msg) {
      msg += ", ";
    }
    msg = msg + "Tax Type";
  }
  if (dataObj.AmountType === null || dataObj.AmountType === "") {
    if (msg) {
      msg += ", ";
    }
    msg = msg + "Unit Type";
  }
  if (dataObj.Amount === null || dataObj.Amount === "") {
    if (msg) {
      msg += ", ";
    }
    msg = msg + "Rate";
  }
  if (dataObj.RecurrenceUnit === null || dataObj.RecurrenceUnit === "") {
    if (msg) {
      msg += ", ";
    }
    msg = msg + "Type";
  }
  if (msg) {
    return getFormattedValidationMsg(msg);
  }
};
export const validationInsuranceSettingsEntity = (dataObj, type) => {
  let msg = "";

  if (type !== "OTHER_CDW") {
    if (dataObj.Description === null || dataObj.Description === "") {
      msg = "Description";
    }
  }
  if (dataObj.DailyUnit === null || dataObj.DailyUnit === "") {
    if (msg) {
      msg += ", ";
    }
    msg = msg + "Daily Unit";
  }
  if (dataObj.DailyAmount === null || dataObj.DailyAmount === "") {
    if (msg) {
      msg += ", ";
    }
    msg = msg + "Daily Rate";
  }
  if (type === "CDW" || type === "OTHER_CDW") {
    if (dataObj.Deductible === null || dataObj.Deductible === "") {
      if (msg) {
        msg += ", ";
      }
      msg = msg + "Deductible";
    }
  }
  if (type === "AGE_INSURANCE") {
    if (dataObj.InsuranceMinAge === null || dataObj.InsuranceMinAge === "") {
      if (msg) {
        msg += ", ";
      }
      msg = msg + "Min Age";
    }
    if (dataObj.InsuranceMaxAge === null || dataObj.InsuranceMaxAge === "") {
      if (msg) {
        msg += ", ";
      }
      msg = msg + "Max Age";
    }
    if (dataObj.InsuranceAgeCode === null || dataObj.InsuranceAgeCode === "") {
      if (msg) {
        msg += ", ";
      }
      msg = msg + "Age Code";
    }    
  }
  if (msg) {
    return getFormattedValidationMsg(msg);
  } else if (type === "AGE_INSURANCE") {
    if (dataObj.InsuranceMaxAge && dataObj.InsuranceMinAge) {
      if (dataObj.InsuranceMinAge > dataObj.InsuranceMaxAge) {
        if (msg) {
          msg += ", ";
        }
        msg = msg + "Max Age should be greater than min age";
      }
    }
    return msg ? msg : null;
  }
  
};
export const getCategoryDisplayValue = (categories, categoryId) => {
  if (categories && categories.length === 0) {
    return categoryId;
  }
  const category = categories
    ? categories.filter((data) => data.CategoryId === categoryId)
    : [];
  return category && category.length > 0 ? category[0].DisplayText : categoryId;
};

export const getUnitDisplayValue = (unitValue) => {
  return getDisplayTextOfDropdown(getUnitTypes(), unitValue);
};
export const getUnitTypeValue = (unitTypeValue) => {
  return getDisplayTextOfDropdown(getUnitCategoryTypes(), unitTypeValue);
};
export const getInheritedProperties = (
  currentOrgId,
  selectedOrgId,
  isInheritedFromParent,
  dependentOrgLength
) => {
  const inheritedObj = {
    ApplyToAllInherited: false,
    ApplyParentSettings: false,
    ApplyToAll: false,
  };
  if (dependentOrgLength > 0) {
    if (String(currentOrgId) === String(selectedOrgId)) {
      inheritedObj.ApplyToAllInherited = true;
    } else {
      inheritedObj.ApplyParentSettings = isInheritedFromParent ? true : false;
    }
  }
  return inheritedObj;
};

export const getNewlyAddedRowInfo = (oldDataList, newDataList, controlName) => {
  let newRow = [];
  if (!isNullOrEmpty(oldDataList) && !isNullOrEmpty(newDataList)) {
    newRow = newDataList.filter(function (newData) {
      return !oldDataList.some(function (oldData) {
        return newData[controlName] === oldData[controlName];
      });
    });
  }
  return newRow;
};

export const isContainCharactersWithoutUnderscore = (val) => {
  return /^[a-zA-Z0-9_]*$/.test(val.trim());
};
export const isValidateEmployeeNoBadgeNoInput = (val) => {
  return /^T?[0-9]+$/.test(val.trim()) || /^[0-9]+S$/.test(val.trim());
};
export const validateBlackListedImport = (rowdata) => {
  let isValid = true;
  if (rowdata) {
    if (!validateEmail(rowdata.Email)) {
      isValid = false;
    }
    if (!rowdata.License) {
      isValid = false;
    }
    // if (!rowdata.MobilePhone) {
    //   isValid = false;
    // }
    if (!rowdata.FirstName) {
      isValid = false;
    }
    if (!rowdata.LastName) {
      isValid = false;
    }
    if (!rowdata.License) {
      isValid = false;
    }
    if (!rowdata.LicenseCountryId) {
      isValid = false;
    }
    if (!rowdata.LicenseStateId && !rowdata.LicenseStateText) {
      isValid = false;
    }
  }
  return isValid;
};
/**Todo: we are going to remove some validation in next release (1 hour interval rule(s))*/
export const validateCapacityPlanningImport = (rowdata) => {
  let isValid = true;
  if (rowdata) {
    if (!rowdata.DayOfWeek || !isValidDayOfWeek(rowdata.DayOfWeek)) {
      isValid = false;
    }
    if (rowdata.StartTime && rowdata.EndTime) {
      try {
        let startTimeArray = rowdata.StartTime.split(":");
        let endTimeArray = rowdata.EndTime.split(":");
        if (startTimeArray[0].length !== 2 || startTimeArray[1].length !== 2 || endTimeArray[0].length !== 2 || endTimeArray[1].length !== 2) {
          isValid = false;
          return 0;
        }
        const startHour = parseInt(startTimeArray[0]);
        const startMin = parseInt(startTimeArray[1]);
        const endHour = parseInt(endTimeArray[0]);
        const endMin = parseInt(endTimeArray[1]);
        if (!Number.isInteger(startHour) || startHour > 23 || startHour < 0
          || !Number.isInteger(startMin) || startMin > 0 || startMin < 0
          || !Number.isInteger(endHour) || endHour > 23 || endHour < 0
          || !Number.isInteger(endMin) || endMin !== 59 || endMin > 59 || endMin < 0 || startHour > endHour) {
          isValid = false;
        }
        if (isValid && !(startHour === 0 && endHour === 23) && startHour !== endHour) {
          isValid = false;
        }
      }
      catch (err) {
        isValid = false;
      }

    } else {
      isValid = false;
    }
    if (!Number.isInteger(parseInt(rowdata.CapacityValue)) || rowdata.CapacityValue < 0) {
      isValid = false;
    }
    if (!rowdata.IsAvailable) {
      isValid = false;
    }
  } else {
    isValid = false;
  }
  return isValid;
};


export const getUnitCategoryTypes = () => {
  return [
    { key: "", displayValue: "Select" },
    { key: "HOURLY", displayValue: "Hourly" },
    { key: "DAILY", displayValue: "Daily" },
    { key: "WEEKLY", displayValue: "Weekly" },
    { key: "MONTHLY", displayValue: "Monthly" },
    { key: "ONETIME", displayValue: "Per Rental" },
  ];
};

export const getRateUnitTypes = () => {
  return [
    { key: "", displayValue: "Select" },
    { key: "DOLLAR", displayValue: "$" },
    { key: "PERCENT", displayValue: "%" },
  ];
};
export const getAddonsTypes = () => {
  return [
    { key: "", displayValue: "Select" },
    { key: "EQUIPMENT", displayValue: "Equipment" },
    { key: "INSURANCE", displayValue: "Insurance" },
    { key: "MILAGE_PACKAGE", displayValue: "Milage Package" },
    { key: "KIT", displayValue: "Kit" },
    { key: "UPGRADE", displayValue: "Upgrade" },
    { key: "SURCHARGE", displayValue: "Surcharge" },
    { key: "COUPON", displayValue: "Coupon" },
    { key: "RLP", displayValue: "RLP" },
    { key: "FOREIGN_DROP_FEE", displayValue: "Foreign Drop Fee" },
    { key: "OUT_OF_AREA_FEE", displayValue: "Out of Area Fee" },
    { key: "UNDER_AGE_FEE", displayValue: "Under Age Fee" },
    { key: "GENERATOR_USAGE", displayValue: "Generator Usage " },
    { key: "SLI", displayValue: "SLI" },
    { key: "ADDL_DRIVER", displayValue: "Addl. Driver" },
    { key: "PAI_PEC", displayValue: "Pai/Pec" },
    { key: "DAMAGE_WAIVER", displayValue: "Damage Waiver" },
    { key: "CUSTOM", displayValue: "Custom" },
    { key: "OTHER", displayValue: "Other" },
  ];
};

export const getUnitTypes = () => {
  return [
    { key: "", displayValue: "Select" },
    { key: "AMOUNT", displayValue: "Amount ($)" },
    { key: "PERCENT", displayValue: "Percent (%)" },
    { key: "ROLLUP", displayValue: "Rollup (R)" },
  ];
};

export const getMinutesIntervals = () => {
  return [
    { key: "0", displayValue: "0 min" },
    { key: "15", displayValue: "15 mins" },
    { key: "30", displayValue: "30 mins" },
    { key: "45", displayValue: "45 min" },
  ];
};

export const getDaysType = () => {
  return [
    { key: "", displayValue: "Select" },
    { key: "ALLDAY", displayValue: "All Days" },
    { key: "WEEKDAY", displayValue: "Week Day" },
    { key: "WEEKEND", displayValue: "Weekend" },
  ];
};
export const getDayOfWeekBySerial = (serial) => {
  if (serial === 0) {
    return "SUNDAY";
  } else if (serial === 1) {
    return "MONDAY";
  } else if (serial === 2) {
    return "TUESDAY";
  } else if (serial === 3) {
    return "WEDNESDAY";
  } else if (serial === 4) {
    return "THURSDAY";
  } else if (serial === 5) {
    return "FRIDAY";
  } else if (serial === 6) {
    return "SATURDAY";
  }
};
export const isValidDayOfWeek = (dayOfWeek) => {
  try {
    if (dayOfWeek.toUpperCase() === "SUNDAY") {
      return true;
    } else if (dayOfWeek.toUpperCase() === "MONDAY") {
      return true;
    } else if (dayOfWeek.toUpperCase() === "TUESDAY") {
      return true;
    } else if (dayOfWeek.toUpperCase() === "WEDNESDAY") {
      return true;
    } else if (dayOfWeek.toUpperCase() === "THURSDAY") {
      return true;
    } else if (dayOfWeek.toUpperCase() === "FRIDAY") {
      return true;
    } else if (dayOfWeek.toUpperCase() === "SATURDAY") {
      return true;
    }
    return false;
  }
  catch (err) {
    return false;
  }
};

export const RenderInheritedCheckBox = (props) => {
  return props.displayEnable ? (
    <CommonInputField
      elementConfig={{
        id: "inheritedFromParent",
        type: "checkbox",
        checked: props.checked,
        disabled: props.disabled,
      }}
      otherConfig={{
        caption: COMMON_MSG.InheritCheckboxCaption,
        checkBoxCaptionClass:
          props.checkBoxCaptionClass || "inheritedCheckBoxLabel",
      }}
      nodeElement="checkbox"
      onChange={props.onChange}
      valueClasses={"pl-2"}
    />
  ) : (
    ""
  );
};

export const getAPiNameForSettings = (pageName, isUpdateApi) => {
  if (pageName === ADMIN_PAGE.CAPACITY_SETTINGS) {
    return !isUpdateApi ? "getcapacitybuckets" : "savecapacitybuckets";
  } else if (pageName === ADMIN_PAGE.RATE_SETTINGS) {
    return !isUpdateApi ? "getcategorysettings" : "updatecategorysettings";
  }else if (pageName === ADMIN_PAGE.SHUTTLES_SETTINGS) {
    return !isUpdateApi ? "shuttleapi/shuttlelocationsettings" : "shuttleapi/shuttlelocationsettings";
  }
};

/** time format like 00:00 */
export const getTimeStrObjForOneHour = (value) => {
  const timeStrObj = {};
  if (Number.isInteger(value) && value < 24) {
    const timeVariable = value < 10 ? "0" + value : value;
    timeStrObj.startTime = timeVariable + ":00";
    timeStrObj.endTime = timeVariable + ":59";
    return timeStrObj;
  }
  return null;
}

export const getLocationCodeFromLocationId = (
  locationList,
  selectedOrgLocationId,
  parentOrgKey
) => {
  let selectedLocation = locationList.find(
    (o) => o.locationId == selectedOrgLocationId
  );
  let selectedLocationCode = selectedLocation
    ? selectedLocation.locationCode
    : parentOrgKey;
  return selectedLocationCode;
};
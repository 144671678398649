import { FoxRequestHeader } from "../../fox-api/domain/fox-request-header";
import PageInfo from "../../models/PageInfo";

const SearchFleetUtilizationForecastRequest = function(data) {
  data = data || {};
  this.requestHeader = new FoxRequestHeader({requestType:"GET"});
  this.forecastStartDate = data.forecastStartDate || null;
  this.forecastEndDate = data.forecastEndDate || null;
  // this.pause = data.pause || null;
  this.locationId = data.locationId || null;
  // this.year = data.year || null;
  // this.engineStatuses = data.engineStatuses || [];
  // this.locationCodes =data.locationCodes || [];
  // this.availabilites =data.availabilites || [];
  this.pagination= data.pagination  ? {...data.pagination} : new PageInfo();
};
export default SearchFleetUtilizationForecastRequest;

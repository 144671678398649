import React from 'react';
import { PaymentType } from '../../../../../shared/utility/enum-utility';
import CancelledRateBlock from '../cancelled-rate-block';
import GeneralRateRow from '../general-rate-row';
import TransactionRateBlock from '../transaction-rate-block';

function PaymentsCreditsBlock(props) {
    const { pricingData, currentPayType, customerFreeCredit, reservationStatus } = props;
    const totalCharges = pricingData?.totalCharges;
    let totalFreeCredits =
        pricingData?.freeCreditAmountApplied;
    let balanceDue = pricingData?.balanceDue;
    if (customerFreeCredit &&
        (currentPayType === PaymentType.PAYLATER ||
            reservationStatus === "PAYMENT_PENDING")
    ) {
        totalFreeCredits = customerFreeCredit > totalCharges ? totalCharges : customerFreeCredit;
        balanceDue = totalCharges - totalFreeCredits;
    }
    const hasPaymentsAndCredits =
        (!isNaN(pricingData.totalCancellationCharges) && pricingData.totalCancellationCharges !== 0) ||
        (!isNaN(pricingData.totalCancellationFee) && pricingData.totalCancellationFee !== 0) ||
        (!isNaN(pricingData.cancellationCourtesyAmount) && pricingData.cancellationCourtesyAmount !== 0) ||
        (!isNaN(totalFreeCredits) && totalFreeCredits !== 0) ||
        (!isNaN(pricingData.totalCreditsApplied) && pricingData.totalCreditsApplied !== 0) ||
        (!isNaN(pricingData.totalPayments) && pricingData.totalPayments !== 0) ||
        (!isNaN(pricingData.totalPaymentsAndCredits) && pricingData.totalPaymentsAndCredits !== 0) ||
        balanceDue !== totalCharges;
    if (!hasPaymentsAndCredits) return "";
    return (
        <>
            <CancelledRateBlock
                totalCancellationCharges={pricingData?.totalCancellationCharges}
                totalCancellationFee={pricingData?.totalCancellationFee}
                cancellationCourtesyAmount={pricingData?.cancellationCourtesyAmount}
            />
            {!isNaN(totalFreeCredits) &&
                <GeneralRateRow
                    key="free-credit-applied"
                    title="Free Credit Applied"
                    total={totalFreeCredits}
                    isNegative={true}
                />}
            {!isNaN(pricingData?.totalCreditsApplied) &&
                <GeneralRateRow
                    key="total-credits"
                    title="Total Credits"
                    total={pricingData?.totalCreditsApplied}
                    isNegative={true}
                />}
            {!isNaN(pricingData?.totalPayments) &&
                <GeneralRateRow
                    key="total-payments"
                    title="Payments"
                    total={pricingData?.totalPayments}
                    isNegative={true}
                />}
            <TransactionRateBlock
                totalCapturedAmount={pricingData?.totalCapturedAmount}
                totalPreAuthReleaseAmount={pricingData?.totalPreAuthReleaseAmount}
                totalPreAuthAmount={pricingData?.totalPreAuthAmount}
                totalRefunds={pricingData?.totalRefunds}
                totalVoidedRefunds={pricingData?.totalVoidedRefunds}
                totalVoidedPayments={pricingData?.totalVoidedPayments}
            />
            {!isNaN(pricingData?.totalPaymentsAndCredits) &&
                <GeneralRateRow
                    key="total-payments-credits"
                    contClasses="font-bold"
                    title="Total Payments and credits"
                    total={pricingData?.totalPaymentsAndCredits}
                    isNegative={true}
                />}
            {balanceDue !== totalCharges && (
                <GeneralRateRow
                    key="total-balance-due"
                    contClasses="total-estimated-charge total-bgfs-6"
                    title="Balance Due"
                    total={balanceDue}
                />
            )} </>
    )
}

export default PaymentsCreditsBlock
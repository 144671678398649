import React, { useEffect } from "react";
import Draggable from "react-draggable";
import ReactHtmlParser from "react-html-parser";
import { useDispatch, useSelector } from "react-redux";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { showNotification } from "../../../../components/UI/Toaster/Toaster";
import { COMMON_MSG } from "../../../../shared/GlobalVar";
import {
  resendPaymentLinkForPaynow,
  resendPaymentLinkForPaynowReset
} from "../../../../store/actions/index";
import { convertMsgResponse } from "../fox-res-helper";

export const FoxResendPaymentLinkModal = (props) => {
  const resendPaymentLinkResponse = useSelector(
    (state) => state.adminMailSendReducer.resendPaymentLinkResponse
  );
  const dispatch = useDispatch();
  useEffect(() => {
    if (!resendPaymentLinkResponse) {
      return;
    }

    if (
      resendPaymentLinkResponse.message
    ) {
      showNotification(convertMsgResponse(resendPaymentLinkResponse.message));
      dispatch(resendPaymentLinkForPaynowReset());
      props.resetParentPage();
      props.closeModal();
    }
  }, [resendPaymentLinkResponse]);

  const resendPaymentLinkToCustomer = () => {
    if (props.reservationCode) {
      const req = {};
      req.reservationCode = props.reservationCode;
      dispatch(resendPaymentLinkForPaynowReset());
      dispatch(resendPaymentLinkForPaynow(req));
    }
  };

  return (
    <Draggable handle=".cursor">
      <Modal
        isOpen={props.showModal}
        className="no-cursor"
      // toggle={toggle}
      >
        <ModalHeader
          className="modal-header bg-primary modal-title text-white cursor pl-2"
          toggle={() => {
            props.closeModal();
          }}
        >
          Resend Payment Link
        </ModalHeader>
        <ModalBody>
          <div className="horizontal-align-center flex-direction-column">
            <label className="font-weight-bold flex-align-self-center fs-2">
              {COMMON_MSG.ResendPaymentLinkConfirmMessageTitle}
            </label>
            <p className="col-form-label flex-align-self-center">
              {ReactHtmlParser(`${COMMON_MSG.ResendPaymentLinkConfirmMessageSubTitlePrefix} <b>${props.customerEmail}</b> `)}
            </p>
          </div>

        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            className="btn btn-primary waves-effect waves-light btnSize ml-2"
            onClick={() => resendPaymentLinkToCustomer()}
          >
            Resend
          </button>
          {!props.isNoBtnAllow ? (
            <button
              type="button"
              className="btn btn-secondary  waves-effect waves-light btnSize ml-2"
              onClick={() => props.closeModal()}
            >
              Cancel
            </button>
          ) : (
            ""
          )}
        </ModalFooter>
      </Modal>
    </Draggable>
  );

  //   return (
  //     <Draggable handle=".cursor">
  //       <Modal
  //         isOpen={props.showModal}
  //         className="no-cursor"
  //         toggle={() => {
  //           props.closeModal();
  //         }}
  //         style={{
  //           minWidth: "70rem",
  //         }}
  //       >
  //         <ModalHeader
  //           className="modal-header bg-primary modal-title text-white cursor"
  //           toggle={() => {
  //             props.closeModal();
  //           }}
  //         >
  //           <i className="fas fa-arrows-alt" /> {cancellationData.modalTitle}
  //         </ModalHeader>
  //         <ModalBody
  //           style={{
  //             maxHeight: "60rem",
  //           }}
  //         >
  //           <div className="col-lg-12 p-0" id="cancelled-reservation-modal">
  //             {cancellationData.reservationState === "CANCELLED" && (
  //               <>
  //                 <div className="form-group row">
  //                   <label className="col-lg-4 col-form-label font-weight-bold">
  //                     Cancelled On
  //                   </label>
  //                   <div className="col-lg-8">
  //                     <label
  //                       className="col-form-label"
  //                       title={convertToDateTimeString(
  //                         cancellationData.cancelledOn,
  //                         true,
  //                         cancellationData.timezoneString,
  //                         true
  //                       )}
  //                     >
  //                       {convertToDateTimeString(
  //                        cancellationData.cancelledOn,
  //                         true,
  //                         cancellationData.timezoneString
  //                       )}
  //                     </label>
  //                   </div>
  //                 </div>
  //                 <div className="form-group row">
  //                   <label className="col-lg-4 col-form-label font-weight-bold">
  //                     Cancelled By
  //                   </label>
  //                   <div className="col-lg-8">
  //                     <label className="col-form-label">
  //                       {cancellationData.cancelledByPersonName}
  //                     </label>
  //                   </div>
  //                 </div>
  //               </>
  //             )}
  //             <div className="form-group row">
  //               <label className="col-lg-4 col-form-label font-weight-bold">
  //                 Reservation Code
  //               </label>
  //               <div className="col-lg-8">
  //                 <label className="col-form-label">
  //                   {cancellationData.confirmationCode}
  //                 </label>
  //               </div>
  //             </div>
  //             <div className="form-group row">
  //               <label className="col-lg-4 col-form-label font-weight-bold">
  //                 External Code
  //               </label>
  //               <div className="col-lg-8">
  //                 <label className="col-form-label">
  //                   {cancellationData.reservationCode}
  //                 </label>
  //               </div>
  //             </div>
  //             <div className="form-group row">
  //               <label className="col-lg-4 col-form-label font-weight-bold">
  //                 Customer Name
  //               </label>
  //               <div className="col-lg-8">
  //                 <label className="col-form-label">
  //                   {cancellationData.fullName +
  //                     " (" +
  //                     cancellationData.customerInfo.email +
  //                     ")"}
  //                 </label>
  //               </div>
  //             </div>
  //             <div className="form-group row required">
  //               <label className="col-lg-4 col-form-label font-weight-bold">
  //                 Cancellation Reason(s)
  //               </label>
  //               <div className={`col-lg-8`}>
  //                 {cancellationData.isEditable ? (
  //                   <Multiselect
  //                     data={getReasons()}
  //                     valueField="Name"
  //                     textField="DisplayName"
  //                     value={multiSelectValues}
  //                     onChange={(value) => setMultiSelectValues(value)}
  //                     groupBy="type"
  //                   />
  //                 ) : (
  //                   getFormattedCancellationResons()
  //                 )}
  //               </div>
  //             </div>
  //             <div className="form-group row">
  //               <label
  //                 htmlFor="cancelComment"
  //                 className="col-lg-4 col-form-label font-weight-bold"
  //               >
  //                 Comments
  //               </label>
  //               <div className="col-lg-8">
  //                 <textarea
  //                   rows="20"
  //                   name="cancelComment"
  //                   id="cancelComment"
  //                   value={cancelComment}
  //                   onChange={(e) => setCancelComment(e.target.value)}
  //                   disabled={!cancellationData.isEditable}
  //                 />
  //               </div>
  //             </div>
  //           </div>
  //         </ModalBody>
  //         <ModalFooter>
  //           <div className="col-lg-12 text-right">
  //               <button
  //                 type="button"
  //                 name="btnReservationCancel"
  //                 className="btn btn-primary waves-effect waves-light btnSize ml-2"
  //                 onClick={() => resendPaymentLinkToCustomer()}
  //               >
  //                 Send
  //               </button>
  //             <button
  //               type="button"
  //               name="btnCancel"
  //               className="btn btn-secondary waves-effect waves-light btnSize ml-2"
  //               onClick={props.closeModal}
  //             >
  //               Cancel
  //             </button>
  //           </div>
  //         </ModalFooter>
  //       </Modal>
  //     </Draggable>
  //   );
};
export default FoxResendPaymentLinkModal;
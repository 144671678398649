import React, { Fragment, useEffect } from "react";
import DateTimePicker from "react-widgets/lib/DateTimePicker";
import DropdownList from "react-widgets/lib/DropdownList";
import "react-widgets/dist/css/react-widgets.css";
import { InheritedLogo } from "../../../shared/AppImages";
import { Picky } from "react-picky";
import "react-picky/dist/picky.css";
import "../../../sass/components/_cbDropdown.scss";
import Moment from "moment";
import { getMemberLabelByOrgRentalType } from "../../../shared/utility/system-utility";
import { getTimezoneAbbr, isNullOrEmpty } from "../../../shared/utility";
import { getDateTimeFormatString, getDateTimePlaceholder, triggerButtonClickOnEnter } from "../../../components/common/common-helper";
import { DateInputFormats, TimeInputFormats } from "../../../shared/utility/date-time-utility";
const SearchItemInput = (props) => {
  let inputElement = null;
  useEffect(() => {
    const globalSearchElement = document.querySelector("#txt-global-search");
    return triggerButtonClickOnEnter({
      actionOn: props.elementConfig.value || "Search",
      doAction: props.submitHandler,
      isNotDisabled:
        isNullOrEmpty(props.elementConfig.disabled) ||
        !props.elementConfig.disabled,
      globalSearchElement: globalSearchElement,
    });
  });

  if (props.invalid && props.shouldValidate && props.touched) {
    //inputClasses.push(classes.Invalid);
  }

  const getTimezoneLabel = (dateValue, timeZoneString) => {
    return timeZoneString ? (
      <label style={{ width: "max-content" }}>
        {dateValue
          ? getTimezoneAbbr(
              Moment(dateValue, "MM:DD:YYYY:HH:mm").toDate(),
              timeZoneString
            )
          : ""}
      </label>
    ) : (
      ""
    );
  };

  switch (props.elementType) {
    case "input":
      if (
        props.elementConfig.type === "text" ||
        props.elementConfig.type === "number"
      ) {
        inputElement = (
          <input
            id={props.idKey}
            {...props.elementConfig}
            value={props.value || ""}
            onChange={(event) =>
              props.changed(event, props.formElement.id, props.elementType)
            }
            disabled={props.elementConfig.disabled}
          />
        );
      } else {
        inputElement = (
          <div className="icheck-primary">
            <input
              id={props.idKey}
              {...props.elementConfig}
              checked={props.value || false}
              onChange={(event) =>
                props.changed(
                  event,
                  props.formElement.id,
                  props.elementConfig.type
                )
              }
            />
            <label htmlFor={props.idKey}>
              <span>{props.labelInput.labeltext}</span>
            </label>
          </div>
        );
      }
      break;
    case "dateandtimepicker":
      inputElement = (
        <div className="dateandtimepicker">
          <DateTimePicker
            parse={DateInputFormats}
            id={props.idKey}
            format={getDateTimeFormatString()}
            placeholder={getDateTimePlaceholder()}
            value={
              props.value
                ? Moment(props.value, "MM:DD:YYYY:HH:mm").toDate()
                : null
            }
            date={true}
            time={false}
            onChange={(event) =>
              props.changed(event, props.formElement.id, props.elementType)
            }
            dateFormat="D"
          />
          <DateTimePicker
            parse={TimeInputFormats}
            id={props.formElement.config.timePicker.id}
            format="h:mm A"
            placeholder="h:mm A"
            value={
              props.formElement.config.timePicker.value
                ? Moment(
                    props.formElement.config.timePicker.value,
                    "MM:DD:YYYY:HH:mm"
                  ).toDate()
                : null
            }
            date={false}
            time={true}
            onChange={(event) =>
              props.changed(
                event,
                props.formElement.config.timePicker.id,
                props.formElement.config.timePicker.elementType,
                {
                  parentId: props.formElement.id,
                }
              )
            }
          />
          {getTimezoneLabel(
            props.value,
            props.inputContainerType === "NORMAL"
              ? props.formElement.timeZoneString
              : props.formElement.config.timeZoneString
          )}
        </div>
      );
      break;
    case "datetimepicker":
      inputElement = (
        <DateTimePicker
          id={props.idKey}
          parse={DateInputFormats}
          format={getDateTimeFormatString()}
          placeholder={getDateTimePlaceholder()}
          value={
            props.value
              ? Moment(props.value, "MM:DD:YYYY:HH:mm").toDate()
              : null
          }
          date={true}
          time={true}
          onChange={(event) =>
            props.changed(event, props.formElement.id, props.elementType)
          }
          dateFormat="D"
        />
      );
      break;
    case "datepicker":
      inputElement = (
        <div className="date-only-picker">
          <DateTimePicker
            parse={DateInputFormats}
            id={props.idKey}
            format={getDateTimeFormatString()}
            placeholder={getDateTimePlaceholder()}
            value={
              props.value
                ? Moment(props.value, "MM:DD:YYYY:HH:mm").toDate()
                : null
            }
            date={true}
            time={false}
            onChange={(event) =>
              props.changed(event, props.formElement.id, props.elementType)
            }
            max={
              props.elementConfig.name === "DateOfBirth"
                ? new Date()
                : new Date(2099, 11, 31)
            }
            dateFormat="D"
            disabled={props.elementConfig.disabled}
          />
          {getTimezoneLabel(
            props.value,
            props.inputContainerType === "NORMAL"
              ? props.formElement.timeZoneString
              : props.formElement.config.timeZoneString
          )}
        </div>
      );
      break;
    case "timepicker":
      inputElement = (
        <DateTimePicker
          parse={TimeInputFormats}
          id={props.idKey}
          format="h:mm A"
          placeholder="h:mm A"
          value={
            props.value
              ? Moment(props.value, "MM:DD:YYYY:HH:mm").toDate()
              : null
          }
          date={false}
          time={true}
          onChange={(event) =>
            props.changed(event, props.formElement.id, props.elementType)
          }
        />
      );
      break;
    case "custom_dropdown":
      let getImgData = ({ item }) => (
        <Fragment>
          <label className="mr-2"> {item.displayValue}</label>
          {item.key && item.isInherited ? (
            <img
              src={InheritedLogo}
              alt={getMemberLabelByOrgRentalType("Photo")}
              className="card-img-top"
              style={{ height: "15px", width: "15px" }}
            />
          ) : (
            ""
          )}
        </Fragment>
      );
      inputElement = (
        <DropdownList
          data={props.elementConfig.options}
          //defaultValue={"Any Role"}
          value={props.value}
          valueField="key"
          textField="displayValue"
          itemComponent={getImgData}
          onChange={(event) =>
            props.changed(event, props.formElement.id, props.elementType)
          }
          disabled={props.elementConfig.disabled}
        />
      );
      break;
    case "cb_dropdown":
      inputElement = (
        <Picky
          value={props.elementConfig.selectMultipleOption}
          options={props.elementConfig.options}
          onChange={(event) =>
            props.changed(event, props.formElement.id, props.elementType)
          }
          // open={true}
          valueKey="key"
          labelKey="displayValue"
          multiple={true}
          includeSelectAll={true}
          includeFilter={true}
          dropdownHeight={300}
          numberDisplayed={1}
          defaultFocusFilter={true}
          clearFilterOnClose = {true}
        />
      );
      break;
    case "textarea":
      inputElement = (
        <textarea
          {...props.elementConfig}
          id={props.idKey}
          value={props.value || ""}
          onChange={(event) =>
            props.changed(event, props.formElement.id, props.elementType)
          }
          disabled={props.elementConfig.disabled}
        />
      );
      break;

    case "select":
      inputElement = (
        <select
          value={props.value || ""}
          onChange={(event) =>
            props.changed(event, props.formElement.id, props.elementType)
          }
          id={props.id}
          disabled={props.elementConfig.disabled}
        >
          {props.elementConfig.options.map((option) => (
            <option key={option.key + props.index} value={option.key}>
              {option.displayValue}
            </option>
          ))}
        </select>
      );
      break;
    case "button":
      inputElement = (
        <button
          {...props.elementConfig}
          id={props.idKey}
          onClick={
            props.elementConfig.value === "Search"
              ? props.submitHandler
              : props.resetHandler
          }
        >
          {props.elementConfig.value}
        </button>
      );
      break;
    default:
      inputElement = "";
  }
  let lblInput = "";
  if (
    props.elementType === "button" ||
    props.elementType === "empty" ||
    props.elementType === "timepicker"
  ) {
    lblInput = "";
  } else {
    if (props.labelInput.type === "select") {
      lblInput = (
        <select
          value={props.labelInput.value || ""}
          onChange={(event) =>
            props.changed(
              event,
              props.formElement.id,
              props.labelInput.type,
              "labelInput"
            )
          }
          style={{ maxWidth: "92%" }}
        >
          {props.labelInput.options.map((option) => (
            <option key={option.key} value={option.key}>
              {option.displayValue}
            </option>
          ))}
        </select>
      );
    } else {
      lblInput = (
        <label className="font-bold">
          {props.elementConfig.type === "checkbox"
            ? ""
            : props.labelInput.labeltext}
        </label>
      );
    }
  }
  const customStyle =
    props.elementType === "button" ? { textAlign: "end" } : {};
  return (
    <div className="search-input-row__column" style={customStyle}>
      {lblInput}
      {inputElement}
    </div>
  );
};
export default SearchItemInput;

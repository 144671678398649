import React, { Fragment, useState } from "react";
import ReactHtmlParser from "react-html-parser";
import AddressComponent from "../../../components/common/address-component/address-component";
import { getDiscountModalInfo } from "../../../components/modal/modern-modal-data";
import ModernModalEngine from "../../../components/modal/modern-modal-engine";
import CommonButton from "../../../components/UI/button/common-button";
import LinkButton from "../../../components/UI/button/link-btn";
import CommonInputField from "../../../components/UI/Input/common-input-field";
import { LabelAndValuePairControl } from "../../../components/UI/Input/label-value-pair-control";
import { CommonLabel } from "../../../components/UI/label/commmon-label";
import CreditCard from "../../../services/entities/models/CreditCard";
import DrivingLicense from "../../../services/entities/models/DrivingLicense";
import { docImg_2 } from "../../../shared/AppImages";
import { ADMIN_PAGE, LicenseDocType, ModalType } from "../../../shared/GlobalVar";
import PermissionName from "../../../shared/Permissions";
import {
  convertMinutesToDayHourMin,
  convertToPercentage,
  getDateOnlyString,
  getDropdownItems,
  getExpiaryMonths,
  getExpiaryYears,
  getFormattedJson,
  getFormattedPhoneNumber,
  hasObjectNonEmptyProperty,
  hasPermission,
  isNullOrEmpty,
} from "../../../shared/utility";
import { getNormalFormattedPhoneNumber } from "../../../shared/utility/phone-utility";
import { isP2POrHybridOrg } from "../../../shared/utility/system-utility";
import { getCardTypeList, getDriveTypeList } from "../MemberHelper";

const MemberUiCtrl = (props) => {
  const [modalData, setModalData] = useState("");
  const hasViewLicensePermission = hasPermission(PermissionName.VIEW_DRIVING_LICENSE);
  const hasEditLicensePermission = hasPermission(PermissionName.EDIT_DRIVING_LICENSE);
  const hasValidateLicensePermission = hasPermission(PermissionName.VALIDATE_LICENSE);
  const hasLicenseSectionAvailable = hasViewLicensePermission || hasEditLicensePermission;
  const getDrivingInfo = (drivingLicenseObj) => {
    let drivingLicenseData = {};
    if (drivingLicenseObj) {
      if (
        drivingLicenseObj.DrivingRecordChecked === null ||
        typeof drivingLicenseObj.DrivingRecordChecked === "undefined"
      ) {
        drivingLicenseData.LicenseVerificationText = "NOT PERFORM";
      } else if (drivingLicenseObj.DrivingRecordChecked) {
        drivingLicenseData.LicenseVerificationText = "PASS";
      } else {
        drivingLicenseData.LicenseVerificationText = "FAIL";
      }
      if (drivingLicenseObj.DrivingRecordChecked) {
        drivingLicenseData.DrivingLicenseDetails =
          drivingLicenseObj.DrivingLicenseDetails || "--";
        drivingLicenseData.DrivingRecordChDate =
          drivingLicenseObj.DrivingRecordChDate
            ? getDateOnlyString(drivingLicenseObj.DrivingRecordChDate)
            : "--";
        drivingLicenseData.LicenseApprovedBy =
          drivingLicenseObj.LicenseApprovedBy || "--";
      } else {
        drivingLicenseData.DrivingLicenseDetails = "--";
        drivingLicenseData.DrivingRecordChDate = "--";
        drivingLicenseData.LicenseApprovedBy = "--";
      }
      if (
        drivingLicenseObj.DrivingRecordChecked &&
        drivingLicenseObj.LicenseManuallyApproved
      ) {
        drivingLicenseData.Approved = "Approved";
        drivingLicenseData.ApprovedType = "Manual";
      } else if (
        drivingLicenseObj.DrivingRecordChecked &&
        !drivingLicenseObj.LicenseManuallyApproved
      ) {
        drivingLicenseData.Approved = "Approved";
        drivingLicenseData.ApprovedType = "Automatic";
      } else {
        drivingLicenseData.Approved = "Not Approved";
        drivingLicenseData.ApprovedType = "--";
      }
      drivingLicenseData.ViolationPoints =
        drivingLicenseObj.ViolationPoints || "--";
      drivingLicenseData.ManualViolationPoints =
        drivingLicenseObj.ManualViolationPoints || "--";
      drivingLicenseData.LicenseCheck = drivingLicenseObj.LicenseCheck
        ? "Yes"
        : "No";
      drivingLicenseData.ManualDmvVerified = drivingLicenseObj.ManualDmvVerified
        ? "Yes"
        : "No";
    }
    return drivingLicenseData;
  };
  // const parseInterNalNotes = (internalNotes) => {
  //   const phoneList = [];
  //   let value1 = internalNotes.split("\n") || [];
  //   value1.map((phoneInfo, i) => {
  //     const pEntity = {};
  //     let value = phoneInfo.split(":") || [];
  //     if (value.length > 0) {
  //       let value2 = value[1].split("#") || [];
  //       pEntity.lbl = value[0];
  //       value2.map((info, j) => {
  //         if (j === 0) {
  //           pEntity.phoneType = value2[0] ? value2[0].trim() : null;
  //         }
  //         if (j === 1) {
  //           pEntity.countryIso = value2[1] ? value2[1].trim() : null;
  //         }
  //         if (j === 2) {
  //           pEntity.countryCode = value2[2] ? value2[2].trim() : null;
  //         }
  //         if (j === 3) {
  //           pEntity.number = value2[3] ? value2[3].trim() : null;
  //         }
  //       });
  //       phoneList.push(pEntity);
  //     } else {
  //       return false;
  //     }
  //   });
  //   return phoneList.map((phone, i) => {
  //     let number = "";
  //     if (phone.phoneType) {
  //       number = "(" + phone.phoneType + ")";
  //     }
  //     if (phone.countryCode) {
  //       number = number
  //         ? number + " +" + phone.countryCode
  //         : "+" + phone.countryCode;
  //     }
  //     if (phone.number) {
  //       number = number ? number + "-" + phone.number : phone.number;
  //     }
  //     if (phone.countryIso) {
  //       number = number
  //         ? number + " [" + phone.countryIso + "]"
  //         : "[" + phone.countryIso + "]";
  //     }
  //     return (
  //       <Fragment>
  //         <label>
  //           <b>{phone.lbl + " : "}</b>
  //           {number}
  //         </label>
  //         <br />
  //       </Fragment>
  //     );
  //   });
  // };
  const isEditMode = props.isSubMenu ? props.isEditMode : true;
  const isDisabledCreditCardInfo = true;
  const memberData = { ...props.memberData };
  if (!memberData.CreditCard) {
    memberData.CreditCard = new CreditCard();
  }
  if (!memberData.DrivingLicense) {
    memberData.CreditCard = new DrivingLicense();
  }
  const drivingLicenseData = getDrivingInfo(memberData.DrivingLicense);
  const drivingLicenseNumber = memberData?.DrivingLicense?.DrivingLicenseNumber || null;
  const licenseStatus = memberData?.DrivingLicense?.DrivingRecordChecked || false;
  const renderCommmonLabel = (
    value,
    lblId,
    classNames,
    noLongLbl,
    toolTipMsg,
    tooltipForceShow
  ) => {
    return (
      <CommonLabel
        className={classNames || "col-sm-12 p-0 text-truncate-block"}
        value={value}
        lblId={"lbl" + lblId}
        isLongLabel={!noLongLbl}
        lblStyle={{ height: "2.2rem" }}
        toolTipMsg={ReactHtmlParser(toolTipMsg)}
        tooltipForceShow={tooltipForceShow}
      />
    );
  };
  const getLblHtmlCtrl = (lblName) => {
    return (
      <label className="p-0" style={{ height: "2.2rem" }}>
        {lblName || "--"}
      </label>
    );
  };

  const getAssetsList = (assetData) => {
    return getDropdownItems(
      assetData,
      "AssetGroupType",
      "AssetGroupType",
      "Select Asset"
    );
  };

  const labelCaptionClass = "col-lg-4";
  const labelValueClass = "col-sm-8";
  const labelCaptionClassBold =
    labelCaptionClass + " font-weight-bold col-form-label";
  // const getNormalFormattedPhoneNumber = (phone) => {
  //   return phone && phone.FormattedNumber
  //     ? "+" + phone.CountryCode + " " + phone.FormattedNumber
  //     : "--";
  // };
  const renderRadioButtons = (id, value, labelCaption, name) => {
    return (
      <CommonInputField
        elementConfig={{
          id: id,
          type: "radio",
          name: name,
          value: value,
          checked: memberData[name] === value,
        }}
        otherConfig={{
          caption: labelCaption,
        }}
        nodeElement="radio"
        onChange={props.inputMemberChangedHandler}
      />
    );
  };
  const commonInputAttrEmployee = [true, false, "input", null, "employee"];
  const commonInputAttrCC = [false, "input", null, "creditcard"];
  const commonInputAttrInsurance = [false, false, "input", null, "insurance"];
  // const isReadOnlyLicense =
  //   props.overrideOptions.OverrideLicenseAndState !== isEditMode;
  const commonInputAttrLicense = [hasEditLicensePermission, false, "input", null, "license"];
  const commonDatetimeAttr = [false, "datetime", null];

  const renderCheckbox = (labelCaption, id, value, isDisabled) => {
    const disabledAttr = isDisabled ? { disabled: isDisabled } : {};
    const onChangehandler =
      id === "UsePrimaryNameandAddress"
        ? props.useSameProfileNameandAddress
        : id === "InsuranceVerified"
          ? props.inputInsuranceChangeHandler
          : props.inputMemberChangedHandler;
    return (
      <CommonInputField
        elementConfig={{
          id: id,
          type: "checkbox",
          name: id,
          checked: value || false,
          ...disabledAttr,
        }}
        otherConfig={{
          caption: labelCaption,
          isHideLabel: true,
        }}
        nodeElement="checkbox"
        onChange={onChangehandler}
        valueClasses="hbCheckBox"
      />
    );
  };
  const renderLabelInputPair = (
    labelCaption,
    controlName,
    value,
    isRequired = false,
    isViewOnly = false,
    nodeElement = "input",
    dropdownItem = null,
    inputCategoryType = "",
    isDateField = false,
    isDisableAfterCurrentDate = null,
    displayDateValueWithAge = null
  ) => {
    const isPhoneNumber = ["MobilePhone", "WorkPhone", "HomePhone"].includes(
      controlName
    );
    const isNumberField = ["PaymentFailureCount"].includes(controlName);
    const isDependsOnLicense = [
      "FirstName",
      "MiddleName",
      "LastName",
      "DateOfBirth",
      "DrivingLicenseNumber",
      "LicenseExpirationDate",
    ].includes(controlName);
    const isDisabledInfo =
      (inputCategoryType === "employee" && !memberData.IsEmployee) ||
      (["BillingName"].includes(controlName) &&
        props.isSameAsProfileNameAndAddress) ||
      (["OtherCompanyName", "OtherDesignation"].includes(controlName) &&
        memberData.IsEmployee) || inputCategoryType === "creditcard" || (!hasEditLicensePermission && inputCategoryType === "license");
    const isCheckbox = nodeElement === "checkbox";
    const valueOrCheckedAttr = isCheckbox ? "checked" : "value";
    const disabledAttr = isDependsOnLicense
      ? { disabled: drivingLicenseData.Approved === "Approved" ? true : (inputCategoryType === "license" && !hasEditLicensePermission) }
      : isDisabledInfo
        ? { disabled: true }
        : {};
    const minValueAndStepAttr = isNumberField ? { min: "0", step: "1" } : {};
    const titleAttr = inputCategoryType === "license" && !hasEditLicensePermission ? { title: "You do not have permission to edit member driving license information" } : {};
    const onChangehandler =
      inputCategoryType === "license"
        ? props.inputLicenseChangeHandler
        : inputCategoryType === "insurance"
          ? props.inputInsuranceChangeHandler
          : inputCategoryType === "creditcard"
            ? props.inputCreditCardChangeHandler
            : inputCategoryType === "employee"
              ? props.inputEmployeeChangeHandler
              : props.inputMemberChangedHandler;
    return (
      <LabelAndValuePairControl
        elementConfig={{
          name: controlName,
          type: isCheckbox ? "checkbox" : isNumberField ? "number" : null,
          [valueOrCheckedAttr]: value || "",
          ...disabledAttr,
          ...minValueAndStepAttr,
          ...titleAttr
        }}
        otherConfig={{
          caption: labelCaption,
          isRequired: isRequired,
          dropdownItem: nodeElement === "select" ? dropdownItem : [],
          value: value,
          dateFormat: "D",
          phoneNumberDisplayValue: isPhoneNumber
            ? getNormalFormattedPhoneNumber(value)
            : "",
          displayDateValueWithAge: displayDateValueWithAge,
          viewOnly: isViewOnly,
          toolTipMsg: isPhoneNumber
            ? ReactHtmlParser(
              getFormattedPhoneNumber(
                value ? value : null,
                null,
                null,
                "tooltip"
              )
            )
            : "",
          isForceShow: isPhoneNumber || isDateField ? true : false,
          // phoneNumberParentClasses: isPhoneNumber
          //   ? "memberInput memberInput-phone"
          //   : "",
          isDateField: isDateField,
          isDisableAfterCurrentDate: isDisableAfterCurrentDate,
        }}
        isEditMode={isEditMode}
        onChange={onChangehandler}
        onChangePhone={handleOnChange}
        renderMobileAutoSueggestion={props.renderMobileAutoSueggestion}
        parentRowClass={`form-group row  ${isEditMode && isRequired && !isDisabledInfo ? "required" : ""
          }`}
        labelClasses={labelCaptionClassBold}
        valueClasses={labelValueClass}
        nodeElement={nodeElement}
        isValidateDone={props.isValidateDone}
        inputCategoryType={inputCategoryType}
        isValidateLicense={!props.overrideOptions.OverrideLicenseAndState && hasEditLicensePermission}
      />
    );
  };
  const isValidateCreditCardData = !props.overrideOptions
    .OverrideCreditCardInformation
    ? hasObjectNonEmptyProperty(
      JSON.parse(JSON.stringify(memberData.CreditCard)),
      ADMIN_PAGE.MEMBER_PROFILE_PAGE
    )
    : "";

  const handleOnChange = (value, data, event, formattedValue) => {
    let memberDataDummy = JSON.parse(JSON.stringify(memberData))

    if (event.type === "change") {
      props.inputPhoneChangeHandler(value, data, event, formattedValue)
    }
    if (["keydown", "click"].includes(event.type)) {
      if (event.target.parentElement) {
        let classList = event.target.parentElement.parentElement && event.target.parentElement.parentElement.classList
        if (!classList) {
          classList = event.target.parentElement.classList
        }
        if (classList.length > 1) {
          let nodeName = classList[1];
          memberDataDummy[nodeName].CountryCode = data.dialCode
          memberDataDummy[nodeName].CountryIso = data.countryCode.toUpperCase()
          props.updateMemberObject(memberDataDummy)
        }
      }
    }
  };

  const renderEmployeeInfoSection = () => {
    return (
      <><h4 className="form-header text-uppercase">
        <i className="fas fa-user-tie" />
        Employee Info
        {renderCheckbox(
          "Is Employee",
          "IsEmployee",
          memberData.IsEmployee,
          !isEditMode
        )}
      </h4>
        {renderLabelInputPair(
          "Employee #",
          "EmployeeNo",
          memberData.Employee && memberData.Employee.EmployeeNo
            ? memberData.Employee.EmployeeNo
            : "",
          ...commonInputAttrEmployee
        )}
        <div className="form-group row required">
          <label htmlFor="newmem-BadgeNo" className={labelCaptionClassBold}>
            Badge #
          </label>
          <div className={`position-relative ${labelValueClass}`}>
            {props.isSubMenu && memberData.IsEmployee ? (
              <button
                type="button"
                className={`btn btn-primary btn-round-icon waves-effect waves-light ${isEditMode ? "disabled" : ""}`}
                style={{
                  minWidth: "0",
                  position: "absolute",
                  left: "-1.2rem",
                }}
                onClick={() =>
                  !isEditMode && props.launchModal({
                    type: ModalType.MEMBER_QRCODE_MODAL,
                    memberId: memberData.MemberId,
                    fullName: memberData.FullName,
                    badgeNo: memberData.Employee.BadgeNo,
                  })
                }
                title={isEditMode ? "Not available in edit mode" : "View/Print"}
              >
                <i className="fas fa-print" aria-hidden="true"></i>
              </button>
            ) : (
              ""
            )}
            {isEditMode ? (
              <input
                id="newmem-BadgeNo"
                name="BadgeNo"
                type="text"
                className="form-control"
                disabled={!memberData.IsEmployee || false}
                onChange={(event) =>
                  props.inputEmployeeChangeHandler(event, "BadgeNo", "text")
                }
                value={
                  memberData.Employee && memberData.Employee.BadgeNo
                    ? memberData.Employee.BadgeNo
                    : ""
                }
              />
            ) : (
              getLblHtmlCtrl(
                memberData.Employee && memberData.Employee.BadgeNo
                  ? memberData.Employee.BadgeNo
                  : null
              )
            )}
          </div>
        </div>
        {renderLabelInputPair(
          "Title",
          "Title",
          memberData.Employee && memberData.Employee.Title
            ? memberData.Employee.Title
            : "",
          ...commonInputAttrEmployee
        )}
        {renderLabelInputPair(
          "Department",
          "Department",
          memberData.Employee && memberData.Employee.Department
            ? memberData.Employee.Department
            : "",
          ...commonInputAttrEmployee
        )}
        {renderLabelInputPair(
          "Location",
          "LocationId",
          memberData.Employee && memberData.Employee.LocationId
            ? isEditMode ? memberData.Employee.LocationId : memberData.Employee.LocationName
            : "",
          true,
          false,
          "select",
          getDropdownItems(
            props.dependentOrg,
            "Key",
            "Value",
            "Any Location"
          ),
          "employee"
        )}
        {renderLabelInputPair(
          "Company Name",
          "CompanyName",
          memberData.Employee && memberData.Employee.CompanyName
            ? memberData.Employee.CompanyName
            : "",
          ...commonInputAttrEmployee
        )}</>
    )
  }
  const renderOtherInfoSection = () => {
    return (
      <>
        <h4 className="form-header text-uppercase">
          <i className="fas fa-building" />
          Other
        </h4>
        {renderLabelInputPair(
          "Company",
          "OtherCompanyName",
          memberData.CompanyName
        )}
        {renderLabelInputPair(
          "Title",
          "OtherDesignation",
          memberData.Designation
        )}
        {renderLabelInputPair(
          "Referred By",
          "ReferredBy",
          memberData.ReferredBy
        )}
        {renderLabelInputPair(
          "Offer Code",
          "SignupCode",
          memberData.SignupCode
        )}
        {renderLabelInputPair(
          "Notes",
          "ProfileNotes",
          memberData.ProfileNotes
        )}
        {renderLabelInputPair(
          "Referral Link",
          "ReferenceLink",
          props.isSubMenu ? memberData.ReferenceLink || "--" : "N/A",
          false,
          true
        )}
        {renderLabelInputPair(
          "Referral Count",
          "ReferralCount",
          props.isSubMenu ? memberData.ReferralCount || "--" : "N/A",
          false,
          true
        )}
        {renderLabelInputPair(
          "Average Approve Reply",
          "AverageTimeReply",
          props.isSubMenu
            ? memberData.AverageTimeReply
              ? convertMinutesToDayHourMin(memberData.AverageApproveReply)
              : "--"
            : "N/A",
          false,
          true
        )}
        {renderLabelInputPair(
          "Average Time Reply",
          "AverageTimeReply",
          props.isSubMenu
            ? memberData.AverageTimeReply
              ? convertMinutesToDayHourMin(memberData.AverageTimeReply)
              : "--"
            : "N/A",
          false,
          true
        )}
        {renderLabelInputPair(
          "Response Rate",
          "ResponseRate",
          props.isSubMenu
            ? memberData.ResponseRate
              ? convertToPercentage(memberData.ResponseRate)
              : "--"
            : "N/A",
          false,
          true
        )}
      </>
    )
  }
  const renderCreditCardSection = () => {
    return (
      <>
        <h4 className="form-header text-uppercase">
          <i className="fas fa-credit-card" />
          Credit Card
          {renderCheckbox(
            "Use Primary Name and Address",
            "UsePrimaryNameandAddress",
            props.isSameAsProfileNameAndAddress,
            !isEditMode || isDisabledCreditCardInfo
          )}
        </h4>
        <div className="form-group row">
          {renderCommmonLabel(
            "Failure Count",
            "cp-FailureCount",
            labelCaptionClassBold
          )}
          {isEditMode ? (
            <Fragment>
              <div className="col-sm-3 pr-2">
                <input
                  id="newmem-PaymentFailureCount"
                  name="PaymentFailureCount"
                  type="number"
                  min="0"
                  step="1"
                  className="form-control"
                  onChange={(event) =>
                    props.inputMemberChangedHandler(
                      event,
                      "PaymentFailureCount",
                      "text"
                    )
                  }
                  value={
                    memberData.PaymentFailureCount
                      ? memberData.PaymentFailureCount
                      : ""
                  }
                />
              </div>
              <div className="col-sm-1 p-0">
                <i
                  className="fas fa-redo-alt redo-ico"
                  onClick={(event) => props.showFailureCountConfirmModal()}
                ></i>
              </div>
            </Fragment>
          ) : (
            <div className={labelValueClass}>
              {getLblHtmlCtrl(
                memberData.PaymentFailureCount
                  ? memberData.PaymentFailureCount
                  : ""
              )}
            </div>
          )}
        </div>
        {renderLabelInputPair(
          "Name On Card",
          "BillingName",
          memberData.CreditCard && memberData.CreditCard.BillingName
            ? memberData.CreditCard.BillingName
            : "--",
          isValidateCreditCardData,
          ...commonInputAttrCC
        )}
        {renderLabelInputPair(
          "Card No.",
          "CardNumber",
          memberData.CreditCard && memberData.CreditCard.CardNumber
            ? memberData.CreditCard.CardNumber
            : "",
          isValidateCreditCardData,
          ...commonInputAttrCC
        )}
        <div
          className={`form-group row ${isValidateCreditCardData ? "required" : ""
            }`}
        >
          <label
            htmlFor="newmem-CardExpirationMonth"
            className={labelCaptionClassBold}
          >
            Exp. Date.
          </label>
          {isEditMode ? (
            <Fragment>
              <div className={labelCaptionClass}>
                <select
                  id="newmem-CardExpirationMonth"
                  name="DriveType"
                  className="form-control"
                  onChange={(event) =>
                    props.inputCreditCardChangeHandler(
                      event,
                      "CardExpirationMonth",
                      "text"
                    )
                  }
                  value={
                    memberData.CreditCard &&
                      memberData.CreditCard.CardExpirationMonth
                      ? memberData.CreditCard.CardExpirationMonth
                      : ""
                  }
                  disabled={isDisabledCreditCardInfo}
                >
                  {getExpiaryMonths().map((element) => (
                    <option key={element.key} value={element.key}>
                      {element.displayValue}
                    </option>
                  ))}
                </select>
              </div>
              <div className={labelCaptionClass}>
                <select
                  id="newmem-CardExpirationYear"
                  name="DriveType"
                  className="form-control"
                  onChange={(event) =>
                    props.inputCreditCardChangeHandler(
                      event,
                      "CardExpirationYear",
                      "text"
                    )
                  }
                  value={
                    memberData.CreditCard &&
                      memberData.CreditCard.CardExpirationYear
                      ? memberData.CreditCard.CardExpirationYear
                      : ""
                  }
                  disabled={isDisabledCreditCardInfo}
                >
                  {getExpiaryYears(
                    memberData.CreditCard &&
                      memberData.CreditCard.CardExpirationYear
                      ? memberData.CreditCard.CardExpirationYear
                      : ""
                  ).map((element) => (
                    <option key={element.key} value={element.key}>
                      {element.displayValue}
                    </option>
                  ))}
                </select>
              </div>
            </Fragment>
          ) : (
            <div className={labelValueClass}>
              {getLblHtmlCtrl(
                memberData.CreditCard &&
                  memberData.CreditCard.CardExpirationMonth
                  ? memberData.CreditCard.CardExpirationMonth +
                  "/" +
                  memberData.CreditCard.CardExpirationYear
                  : "--"
              )}
            </div>
          )}
        </div>
        {renderLabelInputPair(
          "Card Type",
          "CreditCardType",
          memberData.CreditCard && memberData.CreditCard.CreditCardType
            ? memberData.CreditCard.CreditCardType
            : "",
          isValidateCreditCardData,
          false,
          "select",
          getCardTypeList(),
          "creditcard"
        )}
        {renderLabelInputPair(
          "CVV Code",
          "CVVCode",
          memberData.CreditCard && memberData.CreditCard.CVVCode
            ? memberData.CreditCard.CVVCode
            : "",
          isValidateCreditCardData,
          ...commonInputAttrCC
        )}
        <AddressComponent
          isAstericsRequired={isValidateCreditCardData}
          type="CREDIT_CARD"
          key="creditCard-address-component"
          onChangeMethod={props.inputAddressChangeHandler}
          address={memberData.CreditCard.Address || {}}
          street1Label="Street"
          street2Label=" "
          resetLoader={props.resetLoader}
          disabled={props.isSameAsProfileNameAndAddress || isDisabledCreditCardInfo}
          isNoneEditableMode={!isEditMode}
          rightLblClassName="position--unset"
          leftLblClassName={labelCaptionClassBold}
          inputContainerClassName={labelValueClass}
          isValidateDone={props.isValidateDone}
        />
        {props.isSubMenu ? (
          <Fragment>
            {renderLabelInputPair(
              "Manual Verified CC",
              "ManualCreditCardVerified",
              memberData.CreditCard &&
                memberData.CreditCard.ManualCreditCardVerified
                ? "Yes"
                : "No",
              false,
              true
            )}
            {renderLabelInputPair(
              "CC Image Uploaded",
              "ImageUploaded",
              memberData.CreditCard && memberData.CreditCard.ImageUploaded
                ? "Yes"
                : "No",
              false,
              true
            )}
            <div className="form-group row h-auto">
              {renderCommmonLabel(
                "CC Scanned Data",
                "CP-CreditCardScannedData",
                labelCaptionClassBold
              )}
              {renderCommmonLabel(
                memberData.CreditCard &&
                  memberData.CreditCard.CreditCardScannedData
                  ? getFormattedJson(
                    memberData.CreditCard.CreditCardScannedData,
                    ","
                  )
                  : "--",
                "CreditCardScannedData",
                labelValueClass + " col-form-label",
                true,
                memberData.CreditCard &&
                  memberData.CreditCard.CreditCardScannedData
                  ? getFormattedJson(
                    memberData.CreditCard.CreditCardScannedData,
                    "<br>"
                  )
                  : "--"
              )}
            </div>
          </Fragment>
        ) : (
          ""
        )}
      </>
    )
  }
  const renderLicenseSection = () => {
    return (
      <>
        <h4 className="form-header text-uppercase">
          <i className="fas fa-id-badge" />
          License Info
        </h4>
        {renderLabelInputPair(
          "Date of Birth",
          "DateOfBirth",
          memberData?.DrivingLicense?.DateOfBirth,
          hasEditLicensePermission,
          ...commonDatetimeAttr,
          "license",
          true,
          true,
          memberData?.DrivingLicense?.DateOfBirth
            ? getDateOnlyString(memberData.DrivingLicense.DateOfBirth) +
            " / " +
            (memberData.Age ? memberData.Age + " Years" : "--")
            : "--"
        )}
        {renderLabelInputPair(
          "Driver's Lic.",
          "DrivingLicenseNumber",
          memberData?.DrivingLicense?.DrivingLicenseNumber || "",
          ...commonInputAttrLicense
        )}
        <AddressComponent
          type="LICENSE"
          key="license-address-component"
          onChangeMethod={props.inputLicenseChangeHandler}
          resetLoader={props.resetLoader}
          address={{
            Country: memberData?.DrivingLicense?.DrivingLicenseCountryId,
            CountryText:
              memberData?.DrivingLicense?.DrivingLicenseCountryShortCode,
            State: memberData?.DrivingLicense?.DrivingLicenseStateId,
            StateText: memberData?.DrivingLicense?.DrivingLicenseStateText,
          }}
          isAstericsRequired={hasEditLicensePermission}
          isCityNotRequired={true}
          isPostalNotRequired={true}
          isStreet1NotRequired={true}
          isStreet2NotRequired={true}
          coutryLabel="Lic. Country"
          stateLabel="Lic. State"
          isNoneEditableMode={!isEditMode}
          disabled={drivingLicenseData.Approved === "Approved" || !hasEditLicensePermission ? true : false}
          rightLblClassName="position--unset"
          leftLblClassName={labelCaptionClassBold}
          inputContainerClassName={labelValueClass}
          isValidateDone={props.isValidateDone}
          isValidationRequired={!props.overrideOptions.OverrideLicenseAndState && hasEditLicensePermission}
          titleAttr={!hasEditLicensePermission ? { title: "You do not have permission to edit member driving license information" } : {}}
        />
        {renderLabelInputPair(
          "License Exp. Date",
          "LicenseExpirationDate",
          memberData?.DrivingLicense?.LicenseExpirationDate,
          hasEditLicensePermission,
          ...commonDatetimeAttr,
          "license",
          true,
          false
        )}
        <div className="form-group row h-auto">
          {renderCommmonLabel(
            "License Images",
            "cp-LicenseImages",
            labelCaptionClassBold
          )}
          <span className={`col-form-label ${labelValueClass}`}>
            {props.isSubMenu ? (
              <Fragment>
                {hasValidateLicensePermission ? (
                  <Fragment>
                    {/* <span class="verticalLineSeparator"></span> */}
                    <LinkButton
                      btnLabel="Upload"
                      containerClass="pseudo-link pl-1 pr-2"
                      iconClass="fas fa-upload pr-2"
                      clicked={() =>
                        props.launchModal({
                          type: ModalType.MEMBER_LICENCE_UPLOADER_MODAL,
                        })
                      }
                    />
                  </Fragment>
                ) : (
                  <LinkButton
                    btnLabel="View"
                    containerClass="pseudo-link pr-2"
                    iconClass="fas fa-eye pr-2"
                    clicked={() =>
                      props.launchModal({
                        type: ModalType.MEMBER_LICENCE_VIEW_MODAL,
                      })
                    }
                  />
                )}
                {memberData?.DrivingLicense?.DrivingRecordChecked &&
                  hasPermission(PermissionName.ALLOW_TEMP_LICENSE_UPLOAD)
                  ? renderCheckbox(
                    "Allow Temporary (one-time) License Upload",
                    "AllowTemporaryLicenseUpload",
                    memberData?.DrivingLicense?.TempLicenseUploadAllowed ||
                    false
                  )
                  : ""}
              </Fragment>
            ) : (
              "N/A"
            )}
          </span>
        </div>
        <div className="form-group row h-auto">
          {renderCommmonLabel(
            "License Verification",
            "cp-LicenseVerification",
            labelCaptionClassBold
          )}
          <span
            className={`col-form-label ${labelValueClass}`}
            style={{ color: "#ea4c89" }}
          >
            {props.isSubMenu ? (
              <Fragment>
                {drivingLicenseData.LicenseVerificationText}
                <span className="verticalLineSeparator ml-2"></span>
                <button
                  type="button"
                  className="btn btn-primary btn-round-icon waves-effect waves-light ml-1"
                  onClick={(event) => props.approveLicensebyDMV(memberData)}
                  title={hasValidateLicensePermission ? "Run" : "You do not have permission to validate license"}
                  disabled={!hasValidateLicensePermission}
                >
                  <i className="fa fa-play" aria-hidden="true"></i>
                </button>
              </Fragment>
            ) : (
              "N/A"
            )}
          </span>
        </div>
        <div className="form-group row h-auto">
          {renderCommmonLabel(
            "Driver License",
            "cp-DriverLicense",
            labelCaptionClassBold
          )}
          <span className={`col-form-label ${labelValueClass}`}>
            {props.isSubMenu ? (
              <Fragment>
                {drivingLicenseData.Approved}
                {drivingLicenseNumber ? (
                  <span className="verticalLineSeparator ml-2"></span>
                ) : (
                  ""
                )}
                {hasPermission(PermissionName.UPLOAD_DOC) &&
                  drivingLicenseNumber ? (
                  <img
                    src={docImg_2}
                    className="ico-upload-doc"
                    alt=""
                    onClick={() =>
                      props.launchModal({
                        type: LicenseDocType.ATTACHED_DOC,
                        memberId: memberData.MemberId,
                        memberData: memberData,
                      })
                    }
                    title="Upload Additional Doc"
                  />
                ) : (
                  ""
                )}
                <button
                  type="button"
                  className={`btn btn-success btn-round-icon waves-effect waves-light ml-1 ${drivingLicenseNumber ? "" : "displayNone"
                    } ${licenseStatus ? "displayNone" : ""} ${hasPermission(PermissionName.MANUALLY_APPROVE_LICENSE)
                      ? ""
                      : "displayNone"
                    }`}
                  style={{ minWidth: "0" }}
                  onClick={() =>
                    props.launchModal({
                      type: LicenseDocType.MANUAL_APPROVE,
                      memberId: memberData.MemberId,
                      memberData: memberData,
                    })
                  }
                  title="Manual Approve"
                >
                  <i className="fas fa-thumbs-up" aria-hidden="true"></i>
                </button>
                <button
                  type="button"
                  className={`btn btn-danger btn-round-icon waves-effect waves-light ml-1 ${drivingLicenseNumber ? "" : "displayNone"
                    } ${licenseStatus ? "" : "displayNone"} ${hasPermission(PermissionName.DISAPPROVE_LICENSE)
                      ? ""
                      : "displayNone"
                    }`}
                  style={{ minWidth: "0" }}
                  onClick={() =>
                    props.launchModal({
                      type: LicenseDocType.MANUAL_DISAPPROVE,
                      memberId: memberData.MemberId,
                      memberData: memberData,
                    })
                  }
                  title="Disapprove License"
                >
                  <i className="fas fa-thumbs-down" aria-hidden="true"></i>
                </button>
              </Fragment>
            ) : (
              "N/A"
            )}
          </span>
        </div>
        {renderLabelInputPair(
          "Approved By",
          "LicenseApprovedBy",
          props.isSubMenu ? drivingLicenseData.LicenseApprovedBy : "N/A",
          false,
          true
        )}
        {renderLabelInputPair(
          "Approved Type",
          "ApprovedType",
          props.isSubMenu ? drivingLicenseData.ApprovedType : "N/A",
          false,
          true
        )}
        {renderLabelInputPair(
          "License Check Passed",
          "LicenseCheck",
          props.isSubMenu ? drivingLicenseData.LicenseCheck : "N/A",
          false,
          true
        )}
        {renderLabelInputPair(
          "Last Checked Date",
          "DrivingRecordChDate",
          props.isSubMenu ? drivingLicenseData.DrivingRecordChDate : "N/A",
          false,
          true
        )}
        {renderLabelInputPair(
          "Manual Verified DMV",
          "ManualDmvVerified",
          props.isSubMenu ? drivingLicenseData.ManualDmvVerified : "N/A",
          false,
          true
        )}
        {renderLabelInputPair(
          "Details",
          "DrivingLicenseDetails",
          props.isSubMenu ? drivingLicenseData.DrivingLicenseDetails : "N/A",
          false,
          true
        )}
        {renderLabelInputPair(
          "DMV Scanned Data",
          "DrivingLicenseDetails",
          props.isSubMenu
            ? drivingLicenseData.DrivingLicenseScannedData
            : "N/A",
          false,
          true
        )}
        {renderLabelInputPair(
          "Auto Violation Point",
          "ViolationPoints",
          props.isSubMenu ? drivingLicenseData.ViolationPoints : "N/A",
          false,
          true
        )}
        {renderLabelInputPair(
          "Manual Violation Point",
          "ViolationPoints",
          props.isSubMenu ? drivingLicenseData.ManualViolationPoints : "N/A",
          false,
          true
        )}
        {props.isSubMenu &&
          hasPermission(PermissionName.EDIT_VIOLATION_POINT) ? (
          <div className="text-center">
            <LinkButton
              btnLabel="Add Violation Point"
              containerClass="pseudo-link"
              iconClass="fas fa-plus-circle pr-1"
              clicked={() =>
                props.launchModal({
                  type: ModalType.ADD_VIOLATION_MODAL,
                  memberId: memberData.MemberId,
                  fullName: memberData.FullName,
                  doPerformAction: props.onAddViolationPoint,
                })
              }
            />
          </div>
        ) : (
          ""
        )}
      </>
    )
  }
  /**Todo:Rokon, will improve the function more later */
  const onShowModal = () => {
    getDiscountModalInfo(
      { customerId: memberData.MemberId, customerName: memberData?.FullName, setLoader: props.resetLoader },
      setModalData,
      props.resetLoader
    );
  }
  return (
    <form id="personal-info">
      <div className={isEditMode ? "formRow background-edit" : "formRow"}>
        <div className={labelCaptionClass}>
          <h4 className="form-header text-uppercase" style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div className="col-lg-6 p-0">
              <i className="fas fa-user-circle" />
              Personal Info
            </div>
            <div className="col-lg-6 text-right p-0">
              {hasPermission(PermissionName.CC_VIEW_PERSON_FOX_DISCOUNT) ?
                <CommonButton
                  buttonClasses="btn btn-yellow-default"
                  onClickAction={onShowModal}
                  buttonLabel="Add/Reverse Discount"
                  title="Click to open grant discount modal"
                  modalParams={{
                    type: ModalType.GRANT_DISCOUNT_MODAL,
                    memberId: memberData.MemberId,
                  }}
                  customStyle={{ padding: "0rem 0.8rem", fontSize: "1.4rem" }}
                />
                : ""}
            </div>
          </h4>
          {renderLabelInputPair(
            "First Name",
            "FirstName",
            memberData.FirstName,
            true
          )}
          {renderLabelInputPair(
            "Middle Name",
            "MiddleName",
            memberData.MiddleName
          )}
          {renderLabelInputPair(
            "Last Name",
            "LastName",
            memberData.LastName,
            true
          )}
          {renderLabelInputPair(
            "Mobile Phone",
            "MobilePhone",
            memberData.MobilePhone,
            true,
            false,
            "phonenumberwithflag"
          )}
          {renderLabelInputPair(
            "Work Phone",
            "WorkPhone",
            memberData.WorkPhone,
            memberData.PrimaryPhone === "WORK",
            false,
            "phonenumberwithflag"
          )}
          {renderLabelInputPair(
            "Home Phone",
            "HomePhone",
            memberData.HomePhone,
            memberData.PrimaryPhone === "HOME",
            false,
            "phonenumberwithflag"
          )}
          <div
            className={`form-group row h-auto ${isEditMode ? "required" : ""}`}
          >
            <label className={labelCaptionClassBold}>Primary Phone Type</label>
            <div className={labelValueClass}>
              {isEditMode ? (
                <Fragment>
                  <div
                    className={`memberInput ${memberData["PrimaryPhone"]
                      ? ""
                      : props.isValidateDone
                        ? "validation-failed"
                        : ""
                      }`}
                  >
                    {renderRadioButtons(
                      "rbtnMobile",
                      "CELL",
                      "Mobile",
                      "PrimaryPhone"
                    )}
                    {renderRadioButtons(
                      "rbtnWork",
                      "WORK",
                      "Work",
                      "PrimaryPhone"
                    )}
                    {renderRadioButtons(
                      "rbtnHome",
                      "HOME",
                      "Home",
                      "PrimaryPhone"
                    )}
                  </div>
                </Fragment>
              ) : (
                renderCommmonLabel(memberData.PrimaryPhone, "PrimaryPhone")
              )}
            </div>
          </div>
          {renderLabelInputPair(
            "Email",
            "EmailAddress",
            memberData.EmailAddress,
            true
          )}
          {renderLabelInputPair(
            "Verification Code",
            "VerificationCode",
            memberData.VerificationCode
          )}
          <h4 className="form-header text-uppercase row icon-display-none">
            <div className="col-lg-3 p-0">
              <i className="fas fa-address-book" />
              Address
            </div>
            {props.isSubMenu ? (
              <div className="col-lg-9 text-right p-0">
                {hasPermission(PermissionName.VIEW_ADDRESS) ? (
                  <LinkButton
                    btnLabel="All Addresses"
                    containerClass="pseudo-link pr-3"
                    iconClass="fa fa-address-card"
                    clicked={() =>
                      props.launchModal({
                        type: ModalType.MEMBER_ALL_ADDRESS_MODAL,
                        memberId: memberData.MemberId,
                      })
                    }
                  />
                ) : (
                  ""
                )}
                <span className="verticalLineSeparator"></span>
                {hasPermission(PermissionName.ADD_EDIT_ADDRESS) ? (
                  <LinkButton
                    btnLabel="Add Address"
                    containerClass="pseudo-link pl-3"
                    iconClass="fas fa-plus-circle"
                    clicked={() =>
                      props.launchModal({
                        type: ModalType.MEMBER_ADD_ADDRESS_MODAL,
                        memberId: memberData.MemberId,
                      })
                    }
                  />
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            )}
          </h4>
          <AddressComponent
            adddressTypeRequired={true}
            isAstericsRequired={true}
            type="MEMBER"
            key="primary-Address-Component"
            onChangeMethod={props.inputAddressChangeHandler}
            address={{ ...memberData.PrimaryAddress }}
            resetLoader={props.resetLoader}
            sowAsterics={true}
            isNoneEditableMode={!isEditMode}
            rightLblClassName="position--unset"
            leftLblClassName={labelCaptionClassBold}
            inputContainerClassName={labelValueClass}
            isValidateDone={props.isValidateDone}
          />
          <h4 className="form-header text-uppercase">
            <i className="fas fa-car" />
            Vehicle
          </h4>
          {renderLabelInputPair(
            "Drive Type",
            "DriveType",
            memberData.DriveType,
            false,
            false,
            "select",
            getDriveTypeList()
          )}
          <div className={`form-group row  ${isEditMode ? "required" : ""}`}>
            <label className={labelCaptionClassBold}>Drive Manual</label>
            <div className={labelValueClass}>
              {isEditMode ? (
                <div
                  className={`memberInput ${!isNullOrEmpty(memberData["AcknowledgeDriveManualEnabled"])
                    ? ""
                    : props.isValidateDone
                      ? "validation-failed"
                      : ""
                    }`}
                >
                  {renderRadioButtons(
                    "acknowledgeDriveManualEnabledYes",
                    true,
                    "Yes",
                    "AcknowledgeDriveManualEnabled"
                  )}
                  {renderRadioButtons(
                    "acknowledgeDriveManualEnabledNo",
                    false,
                    "No",
                    "AcknowledgeDriveManualEnabled"
                  )}
                </div>
              ) : (
                getLblHtmlCtrl(
                  memberData &&
                    memberData["AcknowledgeDriveManualEnabled"] === true
                    ? "Yes"
                    : "No"
                )
              )}
            </div>
          </div>
          {props.isSubMenu && isP2POrHybridOrg() ? (
            <Fragment>
              {memberData.AssetsAllowed
                ? renderLabelInputPair(
                  "Vehicles Allowed",
                  "VehicleAllowed",
                  memberData.VehicleAllowed,
                  false,
                  false,
                  "select",
                  getAssetsList(memberData.AssetsAllowed)
                )
                : ""}
              {memberData.AssetsNotAllowed
                ? renderLabelInputPair(
                  "Vehicles Not Allowed",
                  "VehicleNotAllowed",
                  memberData.VehicleNotAllowed,
                  false,
                  false,
                  "select",
                  getAssetsList(memberData.AssetsNotAllowed)
                )
                : ""}
            </Fragment>
          ) : (
            ""
          )}
          {renderLabelInputPair(
            "Interested Types",
            "InterestedAssetTypes",
            memberData.InterestedAssetTypes,
            false,
            true
          )}
          <h4 className="form-header text-uppercase">
            <i className="fas fa-car-crash" />
            Insurance
            {renderCheckbox(
              "Insurance Verified",
              "InsuranceVerified",
              memberData.Insurance && memberData.Insurance.InsuranceVerified
                ? memberData.Insurance.InsuranceVerified
                : false,
              !isEditMode
            )}
          </h4>
          {renderLabelInputPair(
            "Insurance Company",
            "InsuranceCarrier",
            memberData.Insurance && memberData.Insurance.InsuranceCarrier,
            ...commonInputAttrInsurance
          )}
          {renderLabelInputPair(
            "Policy #",
            "PolicyNumber",
            memberData.Insurance && memberData.Insurance.PolicyNumber,
            ...commonInputAttrInsurance
          )}

          {renderLabelInputPair(
            "Exp. Date",
            "InsuranceExpirationDate",
            memberData.Insurance.InsuranceExpirationDate,
            false,
            ...commonDatetimeAttr,
            "insurance",
            true,
            false
          )}
        </div>
        <div className={labelCaptionClass}>
          {hasViewLicensePermission && renderLicenseSection()}
          {renderOtherInfoSection()}
          {!hasLicenseSectionAvailable && renderCreditCardSection()}
        </div>
        <div className={labelCaptionClass}>
          {!hasLicenseSectionAvailable && renderEmployeeInfoSection()}
          {hasLicenseSectionAvailable && renderCreditCardSection()}
          {hasLicenseSectionAvailable && renderEmployeeInfoSection()}
        </div>
      </div>
      {isEditMode ? (
        <div className="form-footer" style={{ textAlign: "center" }}>
          <button
            type="button"
            name="btnAddNewMember"
            className="btn btn-primary"
            onClick={props.onAddEditMember}
          >
            <i className="fa fa-check-square-o" />
            Save
          </button>
          <button
            type="button"
            name="btnCancel"
            className="btn btn-secondary ml-2"
            onClick={props.onCancel}
          >
            Cancel
          </button>
        </div>
      ) : (
        ""
      )}
      {modalData ?
        <ModernModalEngine
          {...modalData}
        />
        : ""}
    </form>
  );
};

export default MemberUiCtrl;

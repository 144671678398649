const ReserveSyncModal = function (data) {
  if (!data) data = {};
  this.fleetClassGroup = data.fleetClassGroup || null;
  this.selectedData = data.selectedData || null;
  this.parameter = data.parameter || null;
  this.manualVal = data.manualVal || null;
  this.overrideReasons = data.overrideReasons || [];
  this.currentModal = data.currentModal || null;
};
export default ReserveSyncModal;

import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../../store/actions/index";
import { withRouter } from "react-router-dom";
import Dco from "../dco/Dco";
import TopHeader from "../../components/header/top-header.jsx";
import Footer from "../../components/footer/Footer";
import Toaster from "../../components/UI/Toaster/Toaster";
class Layout extends Component {
  state = {
    showSideDrawer: false,
    redirect: false,
  };

  sideDrawerClosedHandler = () => {
    this.setState({ showSideDrawer: false });
  };

  sideDrawerToggleHandler = () => {
    this.setState((prevState) => {
      return { showSideDrawer: !prevState.showSideDrawer };
    });
  };

  render() {
    let mainForm = "";
    const isImpersonateMode = localStorage.getItem("loginMode") === "IMPERSONATE";
    if (this.props.isAuthenticated) {
      mainForm = (
        <div className="container">
          <TopHeader
            isAuth={this.props.isAuthenticated}
            //orgUnits={this.props.loggedInUserData.OrgUnits}
            userData={this.props.loggedInUserData.User}
            onSwichOrg={this.props.onSwichOrg}
          />
          <div className={`${isImpersonateMode ? "impersonate-border" : ""}`} style={{marginTop:"-0.1rem"}}>
            <main className="content-wrapper" id="main-content-wrapper1">{this.props.children}</main>
            <div className="clearfix" />
            <Footer />
          </div>
        </div>
      );
    } else {
      mainForm = this.props.children;
    }
    return (
      <Dco>
        {mainForm}
        <Toaster />
      </Dco>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.token !== null,
    loggedInUserData: state.auth.loggedInUserData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onSwichOrg: () => dispatch(actions.authCheckState()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Layout));

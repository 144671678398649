import * as actionTypes from "../actions/actionTypes";
import { reducerUpdateObject } from "./reducer.utility";

const initialState = {
  orgUnitFleetClassGroupsResponse: null,
  orgUnitFleetClassGroupClassCodes: null,
  fleetOverrideReasonsResponse: null,
  loading: false,
  error: null,
};

const getFleetClassGroupStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};

const getFleetClassGroupSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    orgUnitFleetClassGroupsResponse: action.orgUnitFleetClassGroupsResponse,
    orgUnitFleetClassGroupClassCodes: null,
    error: null,
    loading: false,
  });
};

const getFleetClassGroupFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false,
  });
};
const getFleetClassGroupReset = (state, action) => {
  return reducerUpdateObject(state, {
    orgUnitFleetClassGroupsResponse: null,
    orgUnitFleetClassGroupClassCodes: null,
  });
};

/**Get Fleet Override Reasons */
const getFleetOverrideReasonsStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};

const getFleetOverrideReasonsSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    fleetOverrideReasonsResponse: action.fleetOverrideReasonsResponse,
    error: null,
    loading: false,
  });
};

const getFleetOverrideReasonsFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false,
  });
};
const getFleetOverrideReasonsReset = (state, action) => {
  return reducerUpdateObject(state, { fleetOverrideReasonsResponse: null });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_FLEET_CLASS_GROUP_START:
      return getFleetClassGroupStart(state, action);
    case actionTypes.GET_FLEET_CLASS_GROUP_SUCCESS:
      return getFleetClassGroupSuccess(state, action);
    case actionTypes.GET_FLEET_CLASS_GROUP_FAIL:
      return getFleetClassGroupFail(state, action);
    case actionTypes.GET_FLEET_CLASS_GROUP_RESET:
      return getFleetClassGroupReset(state, action);

    case actionTypes.GET_FLEET_OVERRIDE_REASONS_START:
      return getFleetOverrideReasonsStart(state, action);
    case actionTypes.GET_FLEET_OVERRIDE_REASONS_SUCCESS:
      return getFleetOverrideReasonsSuccess(state, action);
    case actionTypes.GET_FLEET_OVERRIDE_REASONS_FAIL:
      return getFleetOverrideReasonsFail(state, action);
    case actionTypes.GET_FLEET_OVERRIDE_REASONS_RESET:
      return getFleetOverrideReasonsReset(state, action);

    default:
      return state;
  }
};
export default reducer;

import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getVehicleAvailabilitySettingsInfo,
  getVehicleAvailabilitySettingsInfoReset,
  updateVehicleAvailabilitySettingsInfo,
  updateVehicleAvailabilitySettingsInfoReset,
} from "../../../store/actions/index";
import Spinner from "../../../components/UI/Spinner/Spinner";
import PermissionName from "../../../shared/Permissions";
import {
  getDateOnlyString,
  hasPermission,
  getBasicToastResponse,
} from "../../../shared/utility";
import { showNotification } from "../../../components/UI/Toaster/Toaster";
import {
  VehicleAvailabilitySettingsViewModel,
  VehicleAvailabilityModel,
} from "../../../services/entities/view-models/settings-view-models";
import {
  getCategoryDisplayValue,
  getInheritedProperties,
  getNewlyAddedRowInfo,
} from "../settingsHelper";
import LinkButton from "../../../components/UI/button/link-btn";
import VehicleAvilabilityModal from "./modal/vehicle-availability-modal";
import ConfirmModal from "../../../components/common/modal/confirm-modal";
import NoRecordFoundComponent from "../../../components/common/pages/no-record-found";
import { COMMON_MSG } from "../../../shared/GlobalVar";

const VehicleAvailabilitySettings = (props) => {
  const loading = useSelector((state) => state.settingsReducer.loading);
  const currentOrgUnitId = useSelector(
    (state) => state.auth.loggedInUserData.CurrentOrgUnitId
  );
  const vehicleAvailabilitySettingsResponse = useSelector(
    (state) => state.settingsReducer.vehicleAvailabilitySettingsResponse
  );
  const updateVehicleAvailabilitySettingsResponse = useSelector(
    (state) => state.settingsReducer.updateVehicleAvailabilitySettingsResponse
  );
  const dependentOrgLength = useSelector((state) =>
    state.auth.loggedInUserData.DependantOrgList
      ? state.auth.loggedInUserData.DependantOrgList.length
      : 0
  );
  const dispatch = useDispatch();
  const query = new URLSearchParams(props.location.search);
  const selectedOrgLocationId =
    props.otherData && props.otherData.selectedOrgLocationId
      ? props.otherData.selectedOrgLocationId
      : query.get("orgId") || currentOrgUnitId;
  const [vehicleCategories, setVehicleCategories] = useState([]);

  const [vehicleAvailabilitySettingsData, setVehicleAvailabilitySettingsData] =
    useState(new VehicleAvailabilitySettingsViewModel());
  const [showVehicleAvailabilityModal, setShowVehicleAvailabilityModal] =
    useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [
    selectedVehicleAvailabilityEntity,
    setSelectedVehicleAvailabilityEntity,
  ] = useState(new VehicleAvailabilityModel());
  const [isOffLine, setIsOffLine] = useState(false);
  const hasEditPermission = hasPermission(
    PermissionName.EDIT_AVAILABILITY_ORG_SETTINGS
  );

  const intervalRef = useRef();
  const [highlightedClass, setHighlightedClass] = useState("");
  const [selectedRow, setSelectedRow] = useState(-1);
  const [timer, setTimer] = useState(0);

  useEffect(() => {
    if (
      vehicleAvailabilitySettingsResponse &&
      updateVehicleAvailabilitySettingsResponse
    ) {
      intervalRef.current = setInterval(() => {
        setTimer((prevTimer) => prevTimer + 1);
      }, 1000);
    }
    if (timer % 8 === 0) {
      applyHighlightedColor(null);
      clearInterval(intervalRef.current);
    }
  }, [timer]);

  useEffect(() => {
    getVehicleAvailabilityData();
  }, [selectedOrgLocationId]);
  useEffect(() => {
    if (!vehicleAvailabilitySettingsResponse) {
      return;
    }

    if (vehicleAvailabilitySettingsResponse.StatusCode === 0) {
      setVehicleAvailabilitySettingsData(
        new VehicleAvailabilitySettingsViewModel(
          vehicleAvailabilitySettingsResponse,
          currentOrgUnitId,
          parseInt(selectedOrgLocationId, 10)
        )
      );
      setVehicleCategories(
        vehicleAvailabilitySettingsResponse
          ? vehicleAvailabilitySettingsResponse.Categories
          : []
      );
    }
  }, [vehicleAvailabilitySettingsResponse]);

  useEffect(() => {
    if (!updateVehicleAvailabilitySettingsResponse) {
      return;
    }
    showNotification(
      getBasicToastResponse(updateVehicleAvailabilitySettingsResponse)
    );
    if (updateVehicleAvailabilitySettingsResponse.StatusCode === 0) {
      if (updateVehicleAvailabilitySettingsResponse.InheritedFromParent) {
        getVehicleAvailabilityData();
      }
      closeVehicleAvailabilityModal();
      if (
        vehicleAvailabilitySettingsResponse &&
        updateVehicleAvailabilitySettingsResponse &&
        !updateVehicleAvailabilitySettingsResponse.InheritedFromParent
      ) {
        highlightNewRow(
          isOffLine
            ? vehicleAvailabilitySettingsResponse.OfflineConstraintList
            : vehicleAvailabilitySettingsResponse.MinPeriodConstraintList,
          isOffLine
            ? updateVehicleAvailabilitySettingsResponse.OfflineConstraintList
            : updateVehicleAvailabilitySettingsResponse.MinPeriodConstraintList
        );
        startTimer();
      }
      dispatch(updateVehicleAvailabilitySettingsInfoReset());
    }
    dispatch(updateVehicleAvailabilitySettingsInfoReset());
  }, [updateVehicleAvailabilitySettingsResponse]);

  const applyHighlightedColor = (selectedRowId) => {
    if (selectedRowId) {
      setSelectedRow(selectedRowId);
      setHighlightedClass("add-edit-row-highlight");
    } else {
      setSelectedRow(-1);
      setHighlightedClass("");
    }
  };

  const highlightNewRow = (oldData, newData) => {
    let newRow = getNewlyAddedRowInfo(oldData, newData, "AvailabilityId");
    if (newRow.length > 0) {
      applyHighlightedColor(newRow[0].AvailabilityId);
    }
  };

  const startTimer = () => {
    setTimer(1);
    intervalRef.current = setInterval(() => {
      setTimer((prevTimer) => prevTimer + 1);
    }, 1000);
  };

  const getVehicleAvailabilityData = () => {
    const req = {};
    req.SelectedChildOrgUnitId = selectedOrgLocationId;
    dispatch(getVehicleAvailabilitySettingsInfoReset());
    dispatch(getVehicleAvailabilitySettingsInfo(req));
  };

  const getVehicleAvailabilityModal = (entity, isOffLine) => {
    if (entity) {
      setSelectedVehicleAvailabilityEntity(entity);
    }
    setIsOffLine(isOffLine || false);
    setShowVehicleAvailabilityModal(true);
  };
  const closeVehicleAvailabilityModal = () => {
    setShowVehicleAvailabilityModal(false);
    setShowConfirmModal(false);
    setSelectedVehicleAvailabilityEntity(new VehicleAvailabilityModel());
  };
  const getConfirmModal = (entity, isOffLine) => {
    if (entity) {
      applyHighlightedColor(entity.AvailabilityId);
      setSelectedVehicleAvailabilityEntity(entity);
    }
    setIsOffLine(isOffLine || false);
    setShowConfirmModal(true);
  };

  const closeConfirmModal = () => {
    setShowConfirmModal(false);
    applyHighlightedColor(null);
    setSelectedVehicleAvailabilityEntity(new VehicleAvailabilityModel());
  };
  const addUpdateAvailabilityEntity = (updateEntity, isOffLine) => {
    const req = JSON.parse(JSON.stringify(vehicleAvailabilitySettingsData));
    if (isOffLine) {
      if (!updateEntity.AvailabilityId) {
        req.OfflineConstraintList.push(updateEntity);
      } else {
        req.OfflineConstraintList.map((entity, index) => {
          if (entity.AvailabilityId === updateEntity.AvailabilityId) {
            applyHighlightedColor(entity.AvailabilityId);
            req.OfflineConstraintList[index] = { ...updateEntity };
          }
        });
      }
    } else {
      if (!updateEntity.AvailabilityId) {
        req.MinPeriodConstraintList.push(updateEntity);
      } else {
        req.MinPeriodConstraintList.map((entity, index) => {
          if (entity.AvailabilityId === updateEntity.AvailabilityId) {
            applyHighlightedColor(updateEntity.AvailabilityId);
            req.MinPeriodConstraintList[index] = { ...updateEntity };
          }
        });
      }
    }
    updateVehicleAvailabilityData(req);
  };
  const removeVehicleAvailabilityEntity = () => {
    const req = JSON.parse(JSON.stringify(vehicleAvailabilitySettingsData));
    let entityList = [];
    if (isOffLine) {
      entityList = [...req.OfflineConstraintList];
    } else {
      entityList = [...req.MinPeriodConstraintList];
    }
    const entityListFilter = entityList.filter(
      (x) =>
        x.AvailabilityId !== selectedVehicleAvailabilityEntity.AvailabilityId
    );
    if (isOffLine) {
      req.OfflineConstraintList = [...entityListFilter];
    } else {
      req.MinPeriodConstraintList = [...entityListFilter];
    }
    updateVehicleAvailabilityData(req);
  };

  const updateVehicleAvailabilityData = (req, isInheritedFromParent) => {
    const reqObj = {
      ...req,
      ...getInheritedProperties(
        currentOrgUnitId,
        req.SelectedChildOrgUnitId || selectedOrgLocationId,
        false,
        dependentOrgLength
      ),
    };
    delete reqObj["DisplayInherited"];
    delete reqObj["InheritedFromParent"];
    dispatch(updateVehicleAvailabilitySettingsInfoReset());
    dispatch(updateVehicleAvailabilitySettingsInfo(reqObj));
  };
  const renderMinimumRentalPeriodList = () => {
    return vehicleAvailabilitySettingsData.MinPeriodConstraintList.map(
      (entity) => {
        const totalHour = entity.MinRentalPeriod
          ? Math.floor(entity.MinRentalPeriod / 60)
          : 0;
        return (
          <tr
            className={
              selectedRow === entity.AvailabilityId ? highlightedClass : ""
            }
            key={entity.AvailabilityId}
          >
            <td>
              {getCategoryDisplayValue(vehicleCategories, entity.CategoryId)}
            </td>
            <td>{getDateOnlyString(entity.StartDate, "--")}</td>
            <td>{getDateOnlyString(entity.EndDate, "--")}</td>
            <td>{entity.DaysOfWeek}</td>
            <td>{Math.floor(totalHour / 24)} </td>
            <td>{totalHour % 24}</td>
            <td className="text-center">
              <i
                className="fas fa-pencil-alt add-driver-edit pl-1 pr-1"
                aria-hidden="true"
                onClick={(event) => getVehicleAvailabilityModal(entity, false)}
              />
              <i
                className="resDriverDel fa fa-times add-driver-remove "
                aria-hidden="true"
                onClick={(event) => getConfirmModal(entity, false)}
              />
            </td>
          </tr>
        );
      }
    );
  };
  const renderOfflineConstraintList = () => {
    return vehicleAvailabilitySettingsData.OfflineConstraintList.map(
      (entity) => {
        return (
          <tr
            className={
              selectedRow === entity.AvailabilityId ? highlightedClass : ""
            }
            key={entity.AvailabilityId}
          >
            <td>
              {getCategoryDisplayValue(vehicleCategories, entity.CategoryId)}
            </td>
            <td>{getDateOnlyString(entity.StartDate, "--")}</td>
            <td>{getDateOnlyString(entity.EndDate, "--")}</td>
            <td>{entity.DaysOfWeek}</td>
            <td className="text-center">
              <i
                className="fas fa-pencil-alt add-driver-edit pl-1 pr-1"
                aria-hidden="true"
                onClick={(event) => getVehicleAvailabilityModal(entity, true)}
              />
              <i
                className="resDriverDel fa fa-times add-driver-remove "
                aria-hidden="true"
                onClick={(event) => getConfirmModal(entity, true)}
              />
            </td>
          </tr>
        );
      }
    );
  };
  return (
    <div className="col-lg-8 pt-3 pl-0" id="main-settings">
      <div className="card">
        <div className="card-body">
          <div className="form-header row col-lg-12 p-0">
            <h4 className="text-uppercase mr-auto icon-display-none">
              <i className="fas fa-car"></i>
              Vehicle Availability
            </h4>
          </div>
          <div className="col-lg-12 mb-2  font-weight-bold  d-flex">
            <label className="mr-auto  fs-2">Minimum Rental Period:</label>
            {hasEditPermission ? (
              <LinkButton
                btnLabel="Add Minimum Rental Period"
                containerClass="pseudo-link pr-1 fs-2 font-weight-bold"
                iconClass="fas fa-plus-circle iconBeforeLink"
                clicked={getVehicleAvailabilityModal}
              />
            ) : (
              ""
            )}
          </div>
          <div
            className="col-lg-12 tableScroll bb-0"
            style={{ maxHeight: "60rem" }}
          >
            <table className="table table-striped">
              <thead className="thead-primary">
                <tr>
                  <th>Vehicle Type</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                  <th>Day Type</th>
                  <th>Day (s)</th>
                  <th>Hour (s)</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {vehicleAvailabilitySettingsData.MinPeriodConstraintList
                  .length === 0 ? (
                  <tr>
                    <td colSpan="7">
                      <NoRecordFoundComponent
                        className="border-none"
                        innerClassName="no-setting-record-found"
                        searchMsg="No Record Found"
                        heights="5rem"
                      />
                    </td>
                  </tr>
                ) : (
                  renderMinimumRentalPeriodList()
                )}
              </tbody>
            </table>
          </div>
          <div className="col-lg-12 mb-2 mt-5 font-weight-bold  d-flex">
            <label className="mr-auto  fs-2">Offline:</label>
            {hasEditPermission ? (
              <LinkButton
                btnLabel="Add Offline Info"
                containerClass="pseudo-link pr-1 fs-2 font-weight-bold"
                iconClass="fas fa-plus-circle iconBeforeLink"
                clicked={(event) => getVehicleAvailabilityModal(null, true)}
              />
            ) : (
              ""
            )}
          </div>
          <div
            className="col-lg-12 tableScroll bb-0"
            style={{ maxHeight: "60rem" }}
          >
            <table className="table table-striped">
              <thead className="thead-primary">
                <tr>
                  <th>Vehicle Type</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                  <th>Day Type</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {vehicleAvailabilitySettingsData.OfflineConstraintList
                  .length === 0 ? (
                  <tr>
                    <td colSpan="5">
                      <NoRecordFoundComponent
                        className="border-none"
                        innerClassName="no-setting-record-found"
                        searchMsg="No Record Found"
                        heights="5rem"
                      />
                    </td>
                  </tr>
                ) : (
                  renderOfflineConstraintList()
                )}
              </tbody>
            </table>
          </div>
        </div>
        <div style={{ height: "2.5rem" }}>&nbsp;</div>
      </div>
      {loading ? <Spinner /> : ""}
      {showVehicleAvailabilityModal ? (
        <VehicleAvilabilityModal
          showModal={showVehicleAvailabilityModal}
          closeModal={closeVehicleAvailabilityModal}
          categories={vehicleCategories}
          isOffLine={isOffLine}
          selectedEntity={selectedVehicleAvailabilityEntity}
          addUpdateAvailabilityEntity={addUpdateAvailabilityEntity}
        />
      ) : (
        ""
      )}
      {showConfirmModal ? (
        <ConfirmModal
          body={COMMON_MSG.DeleteRecordMsg}
          showModal={showConfirmModal}
          closeModal={closeConfirmModal}
          doConfirmFunctionality={removeVehicleAvailabilityEntity}
        />
      ) : (
        ""
      )}
    </div>
  );
};
export default VehicleAvailabilitySettings;

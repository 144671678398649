import * as actionTypes from "./actionTypes";
import { invokeServerAPI } from "../../services/httpService";

export const getMemberAddressesesStart = () => {
  return {
    type: actionTypes.GET_ADDRESS_START,
  };
};
export const getMemberAddressesesSuccess = (response) => {
  return {
    type: actionTypes.GET_ADDRESS_SUCCESS,
    memberAddressResponse: response,
  };
};
export const getMemberAddressesesFail = (error) => {
  return {
    type: actionTypes.GET_ADDRESS_FAIL,
    error: error,
  };
};
export const getMemberAddressesesReset = () => {
  return {
    type: actionTypes.GET_ADDRESS_RESET,
  };
};
export const addMemberAddressesStart = () => {
  return {
    type: actionTypes.ADD_ADDRESS_START,
  };
};
export const addMemberAddressesSuccess = (response) => {
  return {
    type: actionTypes.ADD_ADDRESS_SUCCESS,
    addAddressResponse: response,
  };
};
export const addMemberAddressesFail = (error) => {
  return {
    type: actionTypes.ADD_ADDRESS_FAIL,
    error: error,
  };
};
export const addMemberAddressesReset = () => {
  return {
    type: actionTypes.ADD_ADDRESS_RESET,
  };
};
export const editMemberAddressesStart = () => {
  return {
    type: actionTypes.EDIT_ADDRESS_START,
  };
};
export const editMemberAddressesSuccess = (response) => {
  return {
    type: actionTypes.EDIT_ADDRESS_SUCCESS,
    editAddressResponse: response,
  };
};
export const editMemberAddressesFail = (error) => {
  return {
    type: actionTypes.EDIT_ADDRESS_FAIL,
    error: error,
  };
};
export const editMemberAddressesReset = () => {
  return {
    type: actionTypes.EDIT_ADDRESS_RESET,
  };
};
export const deleteMemberAddressesStart = () => {
  return {
    type: actionTypes.DELETE_ADDRESS_START,
  };
};
export const deleteMemberAddressesSuccess = (response) => {
  return {
    type: actionTypes.DELETE_ADDRESS_SUCCESS,
    deleteAddressResponse: response,
  };
};
export const deleteMemberAddressesFail = (error) => {
  return {
    type: actionTypes.DELETE_ADDRESS_FAIL,
    error: error,
  };
};
export const deleteMemberAddressesReset = () => {
  return {
    type: actionTypes.DELETE_ADDRESS_RESET,
  };
};
export const getMemberAddresseses = (req) => {
  return (dispatch) => {
    dispatch(getMemberAddressesesStart());
    invokeServerAPI("addresslisting", req)
      .then((response) => {
        dispatch(getMemberAddressesesSuccess(response));
      })
      .catch((err) => {
        //dispatch(getMemberAddressesesFail(err));
      });
  };
};
export const addMemberAddresses = (req) => {
  return (dispatch) => {
    dispatch(addMemberAddressesStart());
    invokeServerAPI("addaddress", req)
      .then((response) => {
        dispatch(addMemberAddressesSuccess(response));
      })
      .catch((err) => {
        //dispatch(addMemberAddressesFail(err));
      });
  };
};
export const editMemberAddresses = (req) => {
  return (dispatch) => {
    dispatch(editMemberAddressesStart());
    invokeServerAPI("changeaddress", req)
      .then((response) => {
        dispatch(editMemberAddressesSuccess(response));
      })
      .catch((err) => {
        //dispatch(editMemberAddressesFail(err));
      });
  };
};
export const deleteMemberAddresses = (req) => {
  return (dispatch) => {
    dispatch(deleteMemberAddressesStart());
    invokeServerAPI("deleteaddress", req)
      .then((response) => {
        dispatch(deleteMemberAddressesSuccess(response));
      })
      .catch((err) => {
        //dispatch(deleteMemberAddressesFail(err));
      });
  };
};

import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Draggable from "react-draggable";
import moment from "moment";
import DateTimePicker from "react-widgets/lib/DateTimePicker";
import { HoursOfOperationModel } from "../../../../services/entities/view-models/settings-view-models";
import {
  updateObject,
  getHourList,
  getMinutesList,
  getErrorToastData,
  getDropdownItems,
} from "../../../../shared/utility";
import { showNotification } from "../../../../components/UI/Toaster/Toaster";
import { validationHourOfOperationSettings } from "../../settingsHelper";
import {
  getDateTimeFormatString,
  getDateTimePlaceholder,
} from "../../../../components/common/common-helper";
import { DateInputFormats } from "../../../../shared/utility/date-time-utility";
import { getDayName } from "../../../../shared/json/dayNames";

export const HourOfOperationModal = (props) => {
  const entity =
    props.selectedEntity.AddNew === true
      ? new HoursOfOperationModel(null, props.hourFormat)
      : props.selectedEntity;
  const [hoursOfOperationEntity, setHoursOfOperationEntity] = useState(entity);
  const [isValidateDone, setIsValidateDone] = useState(false);
  const hours = getHourList(props.hourFormat);
  const minutes = getMinutesList();
  const periods = [
    { value: "AM", display: "AM" },
    { value: "PM", display: "PM" },
  ];

  const dayNameList = getDropdownItems(getDayName(), "value", "Name");

  const getStartTimeEndTime = (data) => {
    let changeDataItems = { ...hoursOfOperationEntity };
    changeDataItems.Date = data.Date;
    changeDataItems.Day = data.Day;
    changeDataItems.StartTime = props.hourFormat
      ? data.StartPeriod === "PM"
        ? data.StartHourId === "12"
          ? Number(data.StartHourId) * 60 + Number(data.StartMinuteId)
          : 12 * 60 + Number(data.StartHourId) * 60 + Number(data.StartMinuteId)
        : data.StartPeriod === "AM" && data.StartHourId === "12"
        ? Number(data.StartMinuteId)
        : Number(data.StartHourId) * 60 + Number(data.StartMinuteId)
      : Number(data.StartHourId) * 60 + Number(data.StartMinuteId);
    changeDataItems.EndTime = props.hourFormat
      ? data.EndPeriod === "PM"
        ? data.EndHourId === "12" ? Number(data.EndHourId) * 60 + Number(data.EndMinuteId): 12 * 60 + Number(data.EndHourId) * 60 + Number(data.EndMinuteId)
        : data.EndPeriod === "AM" && data.EndHourId === "12"
        ? Number(data.EndMinuteId)
        : Number(data.EndHourId) * 60 + Number(data.EndMinuteId)
      : Number(data.EndHourId) * 60 + Number(data.EndMinuteId);
    changeDataItems.StartHourId = data.StartHourId;
    changeDataItems.EndHourId = data.EndHourId;
    changeDataItems.StartMinuteId = data.StartMinuteId;
    changeDataItems.EndMinuteId = data.EndMinuteId;
    changeDataItems.StartPeriod = data.StartPeriod;
    changeDataItems.EndPeriod = data.EndPeriod;
    changeDataItems.RowId = data.RowId;
    setHoursOfOperationEntity(changeDataItems);
  };

  const handleOnChange = (event, type, controlName) => {
    let updateCtrls = { ...hoursOfOperationEntity };
    let value = null;
    if (event !== "Invalid date" && type === "datetimepicker") {
      value = event ? moment(event).format("MM:DD:YYYY:HH:mm") : event;
    } else {
      value = event.target.value;
    }
    if (type === "datetimepicker") {
      updateCtrls = updateObject(updateCtrls, {
        [controlName]: value,
      });
    } else if (type === "Day") {
      let dayName = dayNameList.find((obj) => obj.key === event.target.value);
      updateCtrls = updateObject(updateCtrls, {
        [controlName]: dayName.displayValue,
        [event.target.name]: event.target.value,
      }); 
    } else {
      updateCtrls = updateObject(updateCtrls, {
        [event.target.name]: value,
      });
    }
    getStartTimeEndTime(updateCtrls);
  };
  const updateHoursOfOperation = () => {
    let msg = validationHourOfOperationSettings(
      hoursOfOperationEntity,
      props.isHoliday,
      props.hoursOfOperationData,
      props.isHoursOfOperation
    );
    if (msg) {
      showNotification(getErrorToastData(msg));
      setIsValidateDone(true);
    } else {
      setIsValidateDone(false);
      props.addUpdateHoursOfOperationEntity(
        hoursOfOperationEntity,
        props.isHoliday,
        props.isHoursOfOperation
      );
    }
  };

  return (
    <Draggable handle=".cursor">
      <Modal
        isOpen={props.showModal}
        className="no-cursor"
        // toggle={() => {
        //   props.closeModal();
        // }}
      >
        <ModalHeader
          className="modal-header bg-primary modal-title text-white cursor"
          toggle={() => {
            props.closeModal();
          }}
        >
          <i className="fas fa-business-time pr-2"></i>
          {props.isHoliday
            ? entity.AddNew
              ? "Add Holiday Schedule"
              : "Update Holiday Schedule"
            : entity.AddNew? 
              "Add Hours Of Operations Schedule"
              : "Update Hours Of Operations Schedule"
          }
        </ModalHeader>
        <ModalBody>
          <div className="col-sm-12 p-0">
            {props.isHoliday ? (
              <div className="form-group row required">
                <label className="col-sm-4 col-form-label font-weight-bold">
                  Date
                </label>
                <div
                  className={`col-sm-4 pr-0 ${
                    hoursOfOperationEntity.Date &&
                    String(hoursOfOperationEntity.Date) !== "Invalid date"
                      ? ""
                      : isValidateDone
                      ? "validation-failed"
                      : ""
                  }`}
                >
                  <DateTimePicker
                    parse={DateInputFormats}
                    format={getDateTimeFormatString()}
                    placeholder={getDateTimePlaceholder()}
                    name="Date"
                    date={true}
                    time={false}
                    onChange={(event) =>
                      handleOnChange(event, "datetimepicker", "Date")
                    }
                    value={
                      hoursOfOperationEntity.Date
                        ? moment(
                            hoursOfOperationEntity.Date,
                            "MM:DD:YYYY:HH:mm"
                          ).toDate()
                        : null
                    }
                    dateFormat="D"
                  />
                </div>
              </div>
            ) : entity.AddNew? (
              <div className="form-group row">
                <label className="col-sm-4 col-form-label font-weight-bold">
                  Day
                </label>
                <div className="col-sm-5">
                  <select
                    style={{ paddingLeft: "1.2rem", paddingTop: "0.1rem" }}
                    name="Day"
                    value={hoursOfOperationEntity.Day}
                    onChange={(event) => handleOnChange(event, "Day", "Name")}
                  >
                    <option value="">Select</option>
                    {dayNameList.map((element) => (
                      <option key={element.key} value={element.key}>
                        {element.displayValue}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            ): (
              <div className="form-group row">
                <label className="col-sm-4 col-form-label font-weight-bold">
                  Day
                </label>
                <div className="col-sm-8">
                  <label>{hoursOfOperationEntity.Name}</label>
                </div>
              </div>
            )}
            <div className="form-group row required">
              <label className="col-sm-4 col-form-label font-weight-bold">
                Start Time
              </label>
              <div className="col-sm-8 row">
                <div className={`col-sm-2 pl-0 pr-0`}>
                  <select
                    style={{ paddingLeft: "1.2rem", paddingTop: "0.1rem" }}
                    name="StartHourId"
                    value={hoursOfOperationEntity.StartHourId}
                    onChange={(event) => handleOnChange(event, "StartHourId")}
                  >
                    {hours.map((element) => (
                      <option key={element.value} value={element.value}>
                        {element.display}
                      </option>
                    ))}
                  </select>
                </div>
                {<b className="mt-1 pl-1 pr-1">:</b>}
                <div className={`col-sm-2 pl-0 pr-0`}>
                  <select
                    style={{ paddingLeft: "1.2rem", paddingTop: "0.1rem" }}
                    name="StartMinuteId"
                    value={hoursOfOperationEntity.StartMinuteId}
                    onChange={(event) => handleOnChange(event, "StartMinuteId")}
                  >
                    {minutes.map((element) => (
                      <option key={element.value} value={element.value}>
                        {element.value}
                      </option>
                    ))}
                  </select>
                </div>
                {props.hourFormat ? (
                  <div className={`col-sm-2 pl-2 pr-0`}>
                    <select
                      className="p-0 pt-1 pl-2"
                      name="StartPeriod"
                      value={hoursOfOperationEntity.StartPeriod}
                      onChange={(event) => handleOnChange(event, "StartPeriod")}
                    >
                      {periods.map((element) => (
                        <option key={element.value} value={element.value}>
                          {element.value}
                        </option>
                      ))}
                    </select>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="form-group row required">
              <label className="col-sm-4 col-form-label font-weight-bold">
                End Time
              </label>
              <div className="col-sm-8 row">
                <div className={`col-sm-2 pl-0 pr-0`}>
                  <select
                    style={{ paddingLeft: "1.2rem", paddingTop: "0.1rem" }}
                    name="EndHourId"
                    value={hoursOfOperationEntity.EndHourId}
                    onChange={(event) => handleOnChange(event, "EndHourId")}
                  >
                    {hours.map((element) => (
                      <option key={element.value} value={element.value}>
                        {element.display}
                      </option>
                    ))}
                  </select>
                </div>
                {<b className="mt-1 pl-1 pr-1">:</b>}
                <div className={`col-sm-2 pl-0 pr-0`}>
                  <select
                    style={{ paddingLeft: "1.2rem", paddingTop: "0.1rem" }}
                    name="EndMinuteId"
                    value={hoursOfOperationEntity.EndMinuteId}
                    onChange={(event) => handleOnChange(event, "EndMinuteId")}
                  >
                    {minutes.map((element) => (
                      <option key={element.value} value={element.value}>
                        {element.value}
                      </option>
                    ))}
                  </select>
                </div>
                {props.hourFormat ? (
                  <div className={`col-sm-2 pl-2 pr-0`}>
                    <select
                      className="p-0 pt-1 pl-2"
                      name="EndPeriod"
                      value={hoursOfOperationEntity.EndPeriod}
                      onChange={(event) => handleOnChange(event, "EndPeriod")}
                    >
                      {periods.map((element) => (
                        <option key={element.value} value={element.value}>
                          {element.value}
                        </option>
                      ))}
                    </select>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="col-sm-12 text-right">
            <button
              type="button"
              name="btnYes"
              className="btn btn-primary waves-effect waves-light btnSize ml-2"
              onClick={() => updateHoursOfOperation()}
            >
              {entity.AddNew ? "Submit" : "Update"}
            </button>
            <button
              type="button"
              name="btnCancel"
              className="btn btn-secondary waves-effect waves-light btnSize ml-2"
              onClick={() => props.closeModal()}
            >
              Cancel
            </button>
          </div>
        </ModalFooter>
      </Modal>
    </Draggable>
  );
};
export default HourOfOperationModal;

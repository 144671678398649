import React, { useState } from "react";
import ConfirmModal from "../../../../components/common/modal/confirm-modal";
import CommonButton from "../../../../components/UI/button/common-button";
import { showNotification } from "../../../../components/UI/Toaster/Toaster";
import { SyncReservationRequest } from "../../../../services/entities/fox-api/request/sync-reservation-request";
import { invokeServerAPI } from "../../../../services/httpService";
import {
  CANCEL_RED,
  EDIT_02,
  MAIL_SEND_DARK,
  PLUS_SIGN,
  RELOAD_DARK,
  VIEW,
} from "../../../../shared/AppImages";
import { ModalType } from "../../../../shared/GlobalVar";
import PermissionName from "../../../../shared/Permissions";
import { hasPermission } from "../../../../shared/utility";
import {
  convertMsgResponse,
  getTooltipMessageForReservationAction,
  isCancellableReservation,
  isEditableReservation,
} from "../fox-res-helper";

const SummaryActionsBlock = ({
  reservationUiModel,
  showModal,
  isPayLater,
  goToEditReservationPage,
  goToNewReservationPage,
  reloadReservationData,
  isAllowedPrePaidReservation,
  allowSubmitReservation,
  updateReservationReposnse,
  setIsLoading
}) => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const isEditableRes = isEditableReservation(reservationUiModel) &&
    reservationUiModel.reservationState !== "CANCELLED"
  const isCancelableRes = isCancellableReservation(reservationUiModel) &&
    reservationUiModel.reservationState !== "CANCELLED"

  const hasEditPermission = hasPermission(
    reservationUiModel.isRental
      ? PermissionName.EDIT_RENTAL
      : PermissionName.EDIT_RESERVATION
  );
  const hasNewReservationPermission = hasPermission(PermissionName.NEW_RESERVATION);
  const hasCancelReservationPermission = hasPermission(PermissionName.CANCEL_RESERVATION);

  const handleCancelReservationClick = () => {
    if (isCancelableRes && hasCancelReservationPermission) {
      showModal({ type: ModalType.CANCEL_FOX_MODAL });
    }
  };
  const handleResendPaymentLink = () => {
    if (hasEditPermission) {
      showModal({ type: ModalType.RESEND_PAYMENT_LINK_MODAL });
    }
  };
  const syncReservationSummary = async () => {
    const syncReservationRequest = new SyncReservationRequest();
    syncReservationRequest.reservationCode = reservationUiModel?.reservationCode;
    setIsLoading(true);
    const syncReservationResponse = await invokeServerAPI("/admin/submitreservation", syncReservationRequest, true);
    setIsLoading(false);
    if (
      syncReservationResponse.message &&
      syncReservationResponse.message.messageType === "SUCCESS"
    ) {
      setShowConfirmModal(false);
      showNotification(convertMsgResponse(syncReservationResponse.message));
      updateReservationReposnse(syncReservationResponse);
      // reloadReservationData();
    } else {
      showNotification(convertMsgResponse(syncReservationResponse.message));
    }
  }
  return (
    <div className="res-summary-action-block">
      <span className="fs-2-0"> Reservation Details </span>
      <div className="res-summary-actions">
        {isAllowedPrePaidReservation && reservationUiModel.customerPaymentUrl && <div className="summary-action-button">
          <CommonButton
            buttonClasses="btn btn-blue-outline action-button refresh"
            onClickAction={handleResendPaymentLink}
            buttonLabel="Resend Payment Link"
            title="Click to resend the payment link to customer email"
            imageURL={MAIL_SEND_DARK}
            imageClass="resend-icon"
          />
        </div>}
        {allowSubmitReservation && <div className="summary-action-button">
          <CommonButton
            buttonClasses="btn btn-blue-outline action-button refresh"
            onClickAction={() => { setShowConfirmModal(true) }}
            buttonLabel="Sync"
            title="Click to refresh the page with the latest reservation data"
            imageURL={RELOAD_DARK}
            imageClass="refresh-icon"
          />
        </div>}
        {isAllowedPrePaidReservation && reservationUiModel.confirmationCode && <div className="summary-action-button">
          <CommonButton
            buttonClasses="btn btn-blue-outline action-button refresh"
            onClickAction={reloadReservationData}
            buttonLabel="Refresh"
            title="Click to refresh the page with the latest reservation data"
            imageURL={RELOAD_DARK}
            imageClass="refresh-icon"
          />
        </div>}
        {reservationUiModel.confirmationCode && reservationUiModel.showCancelBtn && (
          <div className="summary-action-button">
            <CommonButton
              buttonClasses="btn btn-light-danger action-button"
              onClickAction={handleCancelReservationClick}
              buttonLabel="Cancel"
              disabled={!isCancelableRes || !hasCancelReservationPermission}
              title={getTooltipMessageForReservationAction(reservationUiModel, hasCancelReservationPermission, "cancel")}
              imageURL={CANCEL_RED}
              imageClass="cancel-icon"
            />
          </div>
        )}

        {reservationUiModel.confirmationCode && <div className="summary-action-button">
          <CommonButton
            buttonClasses="btn btn-blue-outline action-button view"
            onClickAction={() => showModal({ type: ModalType.RESERVATION_INFO_MODAL })}
            buttonLabel="View"
            title="Click to view reservation details report"
            imageURL={VIEW}
            imageClass="view-icon"
          />
        </div>}
        {reservationUiModel.confirmationCode && <div className="summary-action-button">
          <CommonButton
            buttonClasses="btn btn-light-blue action-button"
            onClickAction={hasEditPermission && isEditableRes ? goToEditReservationPage : null}
            buttonLabel="Edit"
            disabled={!hasEditPermission || !isEditableRes}
            title={getTooltipMessageForReservationAction(reservationUiModel, hasEditPermission, "edit")}
            imageURL={EDIT_02}
            imageClass="edit-icon"
          />
        </div>}

        <div className="summary-action-button">
          <CommonButton
            buttonClasses="btn btn-yellow-default action-button"
            onClickAction={hasNewReservationPermission ? goToNewReservationPage : null}
            buttonLabel="New Reservation"
            disabled={!hasNewReservationPermission}
            title={getTooltipMessageForReservationAction(reservationUiModel, hasNewReservationPermission, "new")}
            imageURL={PLUS_SIGN}
            imageClass="plus-icon"
          />
        </div>
      </div>
      {showConfirmModal ?
        <ConfirmModal
          body="Are you sure, you want to sync this reservation?"
          showModal={showConfirmModal}
          closeModal={() => { setShowConfirmModal(false) }}
          doConfirmFunctionality={syncReservationSummary}
          modalTtile={"Confirm"}
          yesBtnLabel="Yes"
          noBtnLabel="Cancel"
        />
        : ""}
    </div>
  );
};

export default SummaryActionsBlock;

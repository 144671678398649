import axios from "axios";
import * as actionTypes from "./actionTypes";
import HttpService from "../../services/httpService";

export const getInvoiceInformationStart = () => {
  return {
    type: actionTypes.GET_INVOICE_INFORMATION_START,
  };
};

export const getInvoiceInformationSuccess = (response) => {
  return {
    type: actionTypes.GET_INVOICE_INFORMATION_SUCCESS,
    invoiceInformationResponse: response,
  };
};

export const getInvoiceInformationFail = (error) => {
  return {
    type: actionTypes.GET_INVOICE_INFORMATION_FAIL,
    error: error,
  };
};
export const getInvoiceInformationReset = () => {
  return {
    type: actionTypes.GET_INVOICE_INFORMATION_RESET,
  };
};

export const getInvoiceInformation = (invoiceId) => {
  const getInvoiceInfoObj = { InvoiceId: invoiceId };
  return (dispatch) => {
    dispatch(getInvoiceInformationStart());
    HttpService.getInvoiceInformation(getInvoiceInfoObj)
      .then((response) => {
        dispatch(getInvoiceInformationSuccess(response));
      })
      .catch((err) => {
        //dispatch(getInvoiceInformationFail(err));
      });
  };
};

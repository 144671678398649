import React, { useState } from "react";
import { Tooltip } from "reactstrap";

export const TooltipItem = (props) => {
  const elementId = document.getElementById(props.toolTipId);
  const showTooltip = elementId
    ? elementId.scrollWidth > elementId.offsetWidth
    : false;

  const [openTooltip, setOpenTooltip] = useState(false);
  const toggleTooltip = () => {
    setOpenTooltip(!openTooltip);
  };
  return (
    <Tooltip
      placement={props.placement ? props.placement : "top"}
      isOpen={openTooltip}
      target={props.toolTipId}
      toggle={toggleTooltip}
    >
      {showTooltip || props.forceShow ? props.renderableHtml : ""}
    </Tooltip>
  );
};
export default TooltipItem;

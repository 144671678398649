import React from "react";
import { DropdownList } from "react-widgets";
import { getKeyValFromList } from "../../shared/utility/list-utility";
import "./rocks-drop-down.scss";

export const RocksWidgetDropDown = (props) => {
    const {
        controllerName,
        palceHolder,
        iconClass,
        imgUrl,
        selectedKey,
        defaultKeyVal,
        dataList,
        handleOnChange,
        iconSize = { height: "15px", width: "15px" }
    } = props;

    const onHandleOnChange = (data) => {
        handleOnChange(data, controllerName);
    }
    const dropDownIconSize = iconSize
    return (
        <div className="widget-ctrl">
            {imgUrl ? <img
                src={imgUrl}
                alt=""
                style={dropDownIconSize}
            /> : <i className={iconClass} aria-hidden="true"></i>}
            <label className="caption">{palceHolder}</label>
            <DropdownList
                filter='contains'
                data={dataList}
                valueField="key"
                textField="value"
                value={getKeyValFromList(dataList, selectedKey, defaultKeyVal)}
                onChange={onHandleOnChange}
                palceHolder={palceHolder}
            />
        </div>
    )
}
export default RocksWidgetDropDown;
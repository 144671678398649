const CategoryInfoRequestBean = function (data) {
  if (!data) data = {};
  this.CategoryId = data.CategoryId || null;
  this.HourlyRentalsAllowed = data.HourlyRentalsAllowed || null;
  this.RatePerHour = data.RatePerHour || null;
  this.HourlyMilesAllowed = data.HourlyMilesAllowed || null;
  this.HourlyExcessMileageCharge = data.HourlyExcessMileageCharge || null;
  this.RatePerDay = data.RatePerDay || null;
  this.DailyMilesAllowed = data.DailyMilesAllowed || null;
  this.DailyExcessMileageCharge = data.DailyExcessMileageCharge || null;
  this.RatePerMonth = data.RatePerMonth || null;
  this.MonthlyMilesAllowed = data.MonthlyMilesAllowed || null;
  this.MonthlyExcessMileageCharge = data.MonthlyExcessMileageCharge || null;
  this.RatePerWeek = data.RatePerWeek || null;
  this.WeeklyMilesAllowed = data.WeeklyMilesAllowed || null;
  this.WeeklyExcessMileageCharge = data.WeeklyExcessMileageCharge || null;
  this.RatePerWeekendDay = data.RatePerWeekendDay || null;
  this.RatePerAdditionalDay = data.RatePerAdditionalDay || null;
  this.RatePerHalfDay = data.RatePerHalfDay || null;
};
export default CategoryInfoRequestBean;

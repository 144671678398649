import React, { useEffect, useState } from "react";
import Draggable from "react-draggable";
import { useDispatch, useSelector } from "react-redux";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { showNotification } from "../../../components/UI/Toaster/Toaster";
import { MailType } from "../../../shared/GlobalVar";
import {
  getBasicToastResponse,
  updateObject,
} from "../../../shared/utility";
import { sendMail, sendMailReset } from "../../../store/actions/index";

export const MailModal = (props) => {
  const defaultData = props.defaultData;
  const dispatch = useDispatch();

  const mailResponse = useSelector(
    (state) => state.reservationReducer.mailResponse
  );
  const [sendMailReq, setSendMailReq] = useState({
    ReservationId: defaultData.reservationId,
    RecipientsTo: null,
    RecipientsCc: null,
  });
  useEffect(() => {
    dispatch(sendMailReset());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (!mailResponse) {
      return;
    }
    showNotification(getBasicToastResponse(mailResponse));
    if (mailResponse.StatusCode === 0) {
      props.closeModal();
      dispatch(sendMailReset());
    }
    dispatch(sendMailReset());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mailResponse]);
  const handleOnChange = ({ target }) => {
    let updateCtrls = { ...sendMailReq };
    const value = target.value;
    updateCtrls = updateObject(updateCtrls, {
      [target.name]: value,
    });
    setSendMailReq(updateCtrls);
  };
  const onSendMail = () => {
    let mailreq = {};
    if (defaultData.mailType === MailType.EMAIL_ACTIVITY) {
      mailreq.ActivityId = defaultData.activityId;
    } else {
      mailreq = { ...sendMailReq };
    }
    dispatch(sendMailReset());
    dispatch(sendMail(mailreq, defaultData.mailType));
  };
  return (
    <Draggable handle=".cursor">
      <Modal
        isOpen={props.showModal}
        className="no-cursor"
        toggle={() => {
          props.closeModal();
        }}
        style={{ minWidth: "45rem" }}
      >
        <ModalHeader
          className="modal-header bg-primary modal-title text-white cursor"
          toggle={() => {
            props.closeModal();
          }}
        >
          <i className="fas fa-arrows-alt" /> Email
        </ModalHeader>
        <ModalBody>
          {defaultData.mailType === MailType.EMAIL_ACTIVITY ? (
            <div className="col-lg-12">
              <label className="col-form-label font-weight-bold">
                {"Please confirm that you want to resend the Credit Grant email to " +
                  "'" +
                  defaultData.borrowerName +
                  "'"}
              </label>
            </div>
          ) : (
            <div className="col-lg-12">
              <div className="form-group row p-0">
                <label className="col-sm-2 col-form-label font-weight-bold">
                  To
                </label>
                <div className="col-sm-10">
                  <input
                    type="text"
                    name="RecipientsTo"
                    value={
                      sendMailReq.RecipientsTo ? sendMailReq.RecipientsTo : ""
                    }
                    onChange={(event) => handleOnChange(event)}
                  />
                </div>
              </div>
              <div className="form-group row p-0">
                <label className="col-sm-2 col-form-label font-weight-bold">
                  Cc
                </label>
                <div className="col-sm-10">
                  <input
                    type="text"
                    name="RecipientsCc"
                    value={
                      sendMailReq.RecipientsCc ? sendMailReq.RecipientsCc : ""
                    }
                    onChange={(event) => handleOnChange(event)}
                  />
                </div>
              </div>
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          <div className="col-lg-12 text-right">
            <button
              type="button"
              name="btnAddNewMember"
              className="btn btn-primary waves-effect waves-light btnSize ml-2"
              onClick={() => onSendMail()}
            >
              Send
            </button>
            <button
              type="button"
              name="btnCancel"
              className="btn btn-secondary waves-effect waves-light btnSize ml-2"
              onClick={props.closeModal}
            >
              Cancel
            </button>
          </div>
        </ModalFooter>
      </Modal>
    </Draggable>
  );
};
export default MailModal;

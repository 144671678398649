import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { HIGH_AVG_SPEED, HIGH_MAX_SPEED, HIGH_TRAVELLED_DISTANCE, HIGH_TRIP_DURATION, LOW_AVG_SPEED, LOW_TRAVELLED_DISTANCE } from '../../../shared/AppImages';
import { ShuttleTripAlerts, ShuttleTripAlertTooltip } from '../../../shared/utility/enum-utility';
import { createTooltip } from "../../../shared/utility/tooltip-utility";

const ShuttleAlertIcons = (props) => {
  const imgHtml = [];
  const loadImgIconWithTooltip = (imgData, imgId, className, tooltipData) => {
    return createTooltip(
      <img src={imgData} alt="u" id={imgId} className={className} />,
      ReactHtmlParser(tooltipData),
      { forceShow: true, isIcon: true }
    );
  };

  props.alerts && props.alerts.length && props.alerts.forEach((item) => {
    if (item === ShuttleTripAlerts.HIGH_AVG_SPEED) {
      imgHtml.push(
        loadImgIconWithTooltip(
          HIGH_AVG_SPEED,
          "highAvgSpeed_" + props.tripId,
          "ml-2",
          ShuttleTripAlertTooltip.HIGH_AVG_SPEED
        )
      );
    } else if (item === ShuttleTripAlerts.LOW_AVG_SPEED) {
      imgHtml.push(
        loadImgIconWithTooltip(
          LOW_AVG_SPEED,
          "lowAvgSpeed_" + props.tripId,
          "ml-2",
          ShuttleTripAlertTooltip.LOW_AVG_SPEED
        )
      );
    }

    if (item === ShuttleTripAlerts.HIGH_TRIP_DURATION) {
      imgHtml.push(
        loadImgIconWithTooltip(
          HIGH_TRIP_DURATION,
          "highTripDuration_" + props.tripId,
          "ml-2",
          ShuttleTripAlertTooltip.HIGH_TRIP_DURATION
        )
      );
    }

    if (item === ShuttleTripAlerts.HIGH_TRAVELLED_DISTANCE) {
      imgHtml.push(
        loadImgIconWithTooltip(
          HIGH_TRAVELLED_DISTANCE,
          "highTrvelledDistance_" + props.tripId,
          "ml-2",
          ShuttleTripAlertTooltip.HIGH_TRAVELLED_DISTANCE
        )
      );
    } else if (item === ShuttleTripAlerts.LOW_TRAVELLED_DISTANCE) {
      imgHtml.push(
        loadImgIconWithTooltip(
          LOW_TRAVELLED_DISTANCE,
          "lowTrvelledDistance_" + props.tripId,
          "ml-2",
          ShuttleTripAlertTooltip.LOW_TRAVELLED_DISTANCE
        )
      );
    }

    if (item === ShuttleTripAlerts.HIGH_MAX_SPEED) {
      imgHtml.push(
        loadImgIconWithTooltip(
          HIGH_MAX_SPEED,
          "highTripDuration_" + props.tripId,
          "ml-2",
          ShuttleTripAlertTooltip.HIGH_MAX_SPEED
        )
      );
    }
  })

  return imgHtml;
}

export default ShuttleAlertIcons;
import * as actionTypes from "../actions/actionTypes";
import { reducerUpdateObject } from "./reducer.utility";

const initialState = {
  endRentalPricingData: null,
  endRentalResponse: null,
  editEndRentalResponse: null,
  loading: false
};

const getEndRentalChargesStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
const getEndRentalChargesSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    endRentalPricingData: action.endRentalPricingData,
    error: null,
    loading: false
  });
};
const getEndRentalChargesFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false
  });
};
const getEndRentalChargesReset = (state, action) => {
  return reducerUpdateObject(state, {
    endRentalPricingData: null
  });
};

const endRentalStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
const endRentalSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    endRentalResponse: action.endRentalResponse,
    error: null,
    loading: false
  });
};
const endRentalFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false
  });
};
const endRentalReset = (state, action) => {
  return reducerUpdateObject(state, { endRentalResponse: null });
};

const editEndRentalStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
const editEndRentalSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    editEndRentalResponse: action.editEndRentalResponse,
    error: null,
    loading: false
  });
};
const editEndRentalFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false
  });
};
const editEndRentalReset = (state, action) => {
  return reducerUpdateObject(state, { editEndRentalResponse: null });
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_END_RENTAL_CHARGES_START:
      return getEndRentalChargesStart(state, action);
    case actionTypes.GET_END_RENTAL_CHARGES_SUCCESS:
      return getEndRentalChargesSuccess(state, action);
    case actionTypes.GET_END_RENTAL_CHARGES_FAIL:
      return getEndRentalChargesFail(state, action);
    case actionTypes.GET_END_RENTAL_CHARGES_RESET:
      return getEndRentalChargesReset(state, action);

    case actionTypes.END_RENTAL_START:
      return endRentalStart(state, action);
    case actionTypes.END_RENTAL_SUCCESS:
      return endRentalSuccess(state, action);
    case actionTypes.END_RENTAL_FAIL:
      return endRentalFail(state, action);
    case actionTypes.END_RENTAL_RESET:
      return endRentalReset(state, action);

    case actionTypes.END_RENTAL_UPDATE_START:
      return editEndRentalStart(state, action);
    case actionTypes.END_RENTAL_UPDATE_SUCCESS:
      return editEndRentalSuccess(state, action);
    case actionTypes.END_RENTAL_UPDATE_FAIL:
      return editEndRentalFail(state, action);
    case actionTypes.END_RENTAL_UPDATE_RESET:
      return editEndRentalReset(state, action);

    default:
      return state;
  }
};
export default reducer;

import * as actionTypes from "../actions/actionTypes";
import { reducerUpdateObject } from "./reducer.utility";

const initialState = {
    resendPaymentLinkResponse: null,
    loading: false,
    error: null,
};


/** Resend Payment Link for Paynow Reservation*/
const resendPaymentLinkForPaynowStart = (state, action) => {
    return reducerUpdateObject(state, { error: null, loading: true });
  };

export const resendPaymentLinkForPaynowSuccess = (state, action) => {
    return reducerUpdateObject(state, {
        resendPaymentLinkResponse: action.resendPaymentLinkResponse,
        error: null,
        loading: false,
    });
};
const resendPaymentLinkForPaynowReset = (state, action) => {
    return reducerUpdateObject(state, {
        resendPaymentLinkResponse: null,
    });
};

const resendPaymentLinkForPaynowFail = (state, action) => {
    return reducerUpdateObject(state, {
      error: action.error,
      loading: false,
    });
  };

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.RESEND_PAYMENT_LINK_FOX_API_START:
      return resendPaymentLinkForPaynowStart(state, action);
    case actionTypes.RESEND_PAYMENT_LINK_FOX_API_SUCCESS:
      return resendPaymentLinkForPaynowSuccess(state, action);
    case actionTypes.RESEND_PAYMENT_LINK_FOX_API_FAIL:
      return resendPaymentLinkForPaynowFail(state, action);
    case actionTypes.RESEND_PAYMENT_LINK_FOX_API_RESET:
      return resendPaymentLinkForPaynowReset(state, action);
    default:
      return state;
  }
};
export default reducer;

export const ShuttleOptions = function (data) {
    if (!data) data = {};
    this.locations = data.locations || false;
    this.allShuttles = data.allShuttles || false;
    this.locationShuttles = data.locationShuttles || false;
    this.shuttleLocationCode = data.shuttleLocationCode || null;
    this.driverLocationCode = data.driverLocationCode || null;
    this.allDrivers = data.allDrivers || false;
    this.locationDrivers = data.locationDrivers || false;
    this.engineStatuses = data.engineStatuses || false;
    this.amenities = data.amenities || false;
    this.availabilities = data.availabilities || false;
    this.drivingTypes = data.drivingTypes || false;
    this.fuelTypes = data.fuelTypes || false;
    this.fuelConsumptionUnitTypes = data.fuelConsumptionUnitTypes || false;
    this.transMissionTypes = data.transMissionTypes || false;
    this.colorTypes = data.colorTypes || false;
    this.landmarkCategories = data.landmarkCategories || false;
    this.landmarkStatuses = data.landmarkStatuses || false;
};
import React, { Component } from "react";
import Draggable from "react-draggable";
import ReactHtmlParser from "react-html-parser";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import Spinner from "../../../components/UI/Spinner/Spinner";
import HttpService from "../../../services/httpService";
import {
  ADMIN_COMMON_CAPTION,
  ADMIN_PAGE,
  EXTERNAL_CODE_LBL,
} from "../../../shared/GlobalVar";
import PermissionName from "../../../shared/Permissions";
import {
  convertDateTimeString,
  convertDateTimeStringWithTimezone,
  getPhoneNumberText,
  hasPermission,
  populateBreadCrumbData,
} from "../../../shared/utility";
import { getMemberLabelByOrgRentalType } from "../../../shared/utility/system-utility";
import { createTooltip } from "../../../shared/utility/tooltip-utility";
import * as actions from "../../../store/actions/index";

class GlobalSearchPopup extends Component {
  state = {
    searchData: null,
    showReservation: false,
    showMember: false,
    showRental: false,
    isOneResult: false,
    maxRowsDisplay: 10,
    isTopSearchLinkDisplay: false,
    showModal: this.props.show,
    pathnames: "",
    searchItem: "",
    pageName: this.props.pageName,
  };
  componentDidMount() {
    this.setState({
      isOneResult: false,
      pathName: "",
      searchItem: "",
      pageName: this.props.pageName,
    });
    this.getSearchList();
  }

  getSearchList = () => {
    const globalSearchPopupObj = {};
    globalSearchPopupObj.SearchKey = this.props.SearchKey;
    globalSearchPopupObj.NumberOfRecords = this.state.maxRowsDisplay;
    globalSearchPopupObj.SearchType = this.props.SelectedSearchArea;

    this.getGlobalSearchPopupData(globalSearchPopupObj);
  };
  getGlobalSearchPopupData = (globalSearchPopupObj) => {
    this.setState({
      loading: true,
    });
    HttpService.doMainSearch(globalSearchPopupObj).then(
      (globalSearchResponse) => {
        this.setState(
          {
            loading: false,
            searchData: globalSearchResponse,
            showMember:
              globalSearchResponse && globalSearchResponse.Members
                ? true
                : false,
            showRental:
              globalSearchResponse && globalSearchResponse.Rentals
                ? true
                : false,
            showReservation:
              globalSearchResponse && globalSearchResponse.Reservations
                ? true
                : false,
            isOneResult:
              globalSearchResponse &&
              globalSearchResponse.MemberCount +
                globalSearchResponse.RentalCount +
                globalSearchResponse.ReservationCount ===
                1
                ? true
                : false,
            isTopSearchLinkDisplay:
              globalSearchResponse.MemberCount > this.state.maxRowsDisplay ||
              globalSearchResponse.RentalCount > this.state.maxRowsDisplay ||
              globalSearchResponse.ReservationCount > this.state.maxRowsDisplay
                ? true
                : false,
          },
          () => {
            if (
              !this.state.showMember &&
              !this.state.showRental &&
              !this.state.showReservation
            ) {
              let response = {};
              response.StatusMsg = "No Record Found";
              response.StatusCode = 1;
              this.props.callBackToDisplayServerMessage(response);
            }
          }
        );
        if (this.state.isOneResult) {
          this.setState({
            pathName:
              this.state.searchData.ReservationCount === 1 ||
              this.state.searchData.RentalCount === 1
                ? "/reservationSummary"
                : this.state.searchData.MemberCount === 1
                ? this.state.pageName === ADMIN_PAGE.EDIT_NEW_RESERVATION_PAGE
                  ? "/updateReservation"
                  : "/member/memberProfile"
                : "",
            searchItem:
              this.state.searchData.ReservationCount === 1
                ? "?reservationId=" +
                  this.state.searchData.Reservations[0].ConfirmationKey
                : this.state.searchData.RentalCount === 1
                ? "?reservationId=" +
                  this.state.searchData.Rentals[0].ConfirmationKey
                : this.state.searchData.MemberCount === 1
                ? "?memberId=" + this.state.searchData.Members[0].PersonId
                : "",
          });
          if (this.state.searchData.MemberCount === 1) {
            this.state.pageName !== ADMIN_PAGE.EDIT_NEW_RESERVATION_PAGE &&
              this.props.modalClose(true);
          } else {
            this.props.modalClose(true);
          }
        }
      }
    );
  };

  memberRenderList() {
    const breadCrumbList = populateBreadCrumbData(
      [],
      getMemberLabelByOrgRentalType(),
      "/member"
    );
    if (
      this.state.searchData &&
      this.state.searchData.Members &&
      (!this.state.isOneResult ||
        this.state.pageName === ADMIN_PAGE.EDIT_NEW_RESERVATION_PAGE)
    ) {
      return this.state.searchData.Members.map((globalSearchObj, i) => {
        return (
          <tr
            className="cursor-pointer"
            key={globalSearchObj.PersonId + i}
            onClick={
              hasPermission(PermissionName.VIEW_MEMBER)
                ? (event) => {
                    this.props.goToSummaryPage(globalSearchObj, "member");
                  }
                : ""
            }
          >
            <td className="text-nowrap text-left">
              {globalSearchObj.MembershipId || "--"}
            </td>
            <td className="text-left">
              <span id={"employeeNameId" + globalSearchObj.PersonId}>
                {createTooltip(
                  globalSearchObj.FirstName || "--",
                  globalSearchObj.FirstName || ""
                )}
              </span>
            </td>
            <td className="text-left">
              <span id={"emailId" + globalSearchObj.PersonId}>
                {createTooltip(
                  globalSearchObj.EmailAddress || "--",
                  globalSearchObj.EmailAddress || ""
                )}
              </span>
            </td>
            <td className="text-left">
              <span id={"phoneNoId" + globalSearchObj.PersonId}>
                {createTooltip(
                  getPhoneNumberText(globalSearchObj, false),
                  ReactHtmlParser(getPhoneNumberText(globalSearchObj, true)),
                  { forceShow: true }
                )}
              </span>
            </td>
            <td className="text-left">
              <span id={"formattedPrimaryAddressId" + globalSearchObj.PersonId}>
                {createTooltip(
                  globalSearchObj.FormattedPrimaryAddress || "--",
                  globalSearchObj.FormattedPrimaryAddress
                )}
              </span>
            </td>
          </tr>
        );
      });
    } else {
      if (
        this.state.searchData &&
        this.state.searchData.Members &&
        this.state.isOneResult
      ) {
        // this.props.hideExpandedSearchArea();
        return (
          <Redirect
            to={{
              pathname: this.state.pathName,
              search: this.state.searchItem,
              state: {
                breadCrumbList: breadCrumbList,
                memberName: this.state.searchData.Members[0].FirstName,
              },
            }}
          />
        );
      }
    }
  }

  reservationRenderList() {
    const breadCrumbList = populateBreadCrumbData(
      [],
      "Reservation",
      "/reservation"
    );
    if (
      this.state.searchData &&
      this.state.searchData.Reservations &&
      !this.state.isOneResult
    ) {
      return this.state.searchData.Reservations.map((globalSearchObj) => {
        return (
          <tr
            className="cursor-pointer"
            key={globalSearchObj.ReservationId}
            onClick={
              hasPermission(PermissionName.VIEW_RESERVATION)
                ? (event) => {
                    this.props.goToSummaryPage(globalSearchObj, "reservation");
                  }
                : ""
            }
          >
            <td
              className="text-left"
              id={"reservationCode_" + globalSearchObj.ReservationId}
            >
              {createTooltip(
                globalSearchObj.ConfirmationKey || "--",
                globalSearchObj.ConfirmationKey
              )}
            </td>
            <td className="text-left">
              {globalSearchObj.IntegConfirmNumber || "--"}
            </td>
            <td
              id={"reservationState_" + globalSearchObj.ReservationId}
              className={` text-left ${globalSearchObj.ReservationState} ${
                globalSearchObj.IsLateReservation || false
              }`}
            >
              {createTooltip(
                globalSearchObj.ReservationState || "--",
                globalSearchObj.ReservationState
              )}
            </td>

            <td
              className="text-left"
              id={"employeeNameId_" + globalSearchObj.ReservationId}
            >
              {createTooltip(
                globalSearchObj.BorrowerName || "--",
                globalSearchObj.BorrowerName
              )}
            </td>
            <td
              className="text-left"
              id={"promisedStartDateId_" + globalSearchObj.ReservationId}
            >
              {createTooltip(
                convertDateTimeString(globalSearchObj.PromisedStartDate) ||
                  "--",

                convertDateTimeStringWithTimezone(
                  globalSearchObj.PromisedStartDate,
                  globalSearchObj.TimezoneString
                ),
                { forceShow: true }
              )}
            </td>
            <td
              className="text-left"
              id={"promiseEndDateId_" + globalSearchObj.ReservationId}
            >
              {createTooltip(
                convertDateTimeString(globalSearchObj.PromiseEndDate) || "--",

                convertDateTimeStringWithTimezone(
                  globalSearchObj.PromiseEndDate,
                  globalSearchObj.TimezoneString
                ),
                { forceShow: true }
              )}
            </td>
            <td
              className="text-left"
              id={"pickUpLocationId_" + globalSearchObj.ReservationId}
            >
              {createTooltip(
                globalSearchObj.PickUpLocation || "--",
                globalSearchObj.PickUpLocation
              )}
            </td>
          </tr>
        );
      });
    } else {
      if (
        this.state.searchData &&
        this.state.searchData.Reservations &&
        this.state.isOneResult
      ) {
        this.props.hideExpandedSearchArea();
        return (
          <Redirect
            to={{
              pathname: this.state.pathName,
              search: this.state.searchItem,
              state: {
                breadCrumbList: breadCrumbList,
              },
            }}
          />
        );
      }
    }
  }

  rentalRenderList() {
    const breadCrumbList = populateBreadCrumbData([], "Rental", "/rental");
    if (
      this.state.searchData &&
      this.state.searchData.Rentals &&
      !this.state.isOneResult
    ) {
      return this.state.searchData.Rentals.map((globalSearchObj) => {
        return (
          <tr
            className="cursor-pointer"
            key={globalSearchObj.ReservationId}
            onClick={
              hasPermission(PermissionName.VIEW_RESERVATION)
                ? (event) => {
                    this.props.goToSummaryPage(globalSearchObj, "rental");
                  }
                : ""
            }
          >
            <td
              className="text-left"
              id={"rentalCode_" + globalSearchObj.ReservationId}
            >
              {createTooltip(
                globalSearchObj.ConfirmationKey || "--",
                globalSearchObj.ConfirmationKey
              )}
            </td>
            <td className="text-left">
              {globalSearchObj.IntegConfirmNumber || "--"}
            </td>
            <td
              id={"rentalState_" + globalSearchObj.ReservationId}
              className={` text-left ${globalSearchObj.ReservationState} ${
                globalSearchObj.IsLateReservation || false
              }`}
            >
              {createTooltip(
                globalSearchObj.ReservationState || "--",
                globalSearchObj.ReservationState
              )}
            </td>

            <td
              className="text-left"
              id={"employeeNameId_" + globalSearchObj.ReservationId}
            >
              {createTooltip(
                globalSearchObj.BorrowerName || "--",
                globalSearchObj.BorrowerName
              )}
            </td>
            <td
              className="text-left"
              id={"promisedStartDateId_" + globalSearchObj.ReservationId}
            >
              {createTooltip(
                convertDateTimeString(globalSearchObj.PromisedStartDate) ||
                  "--",

                convertDateTimeStringWithTimezone(
                  globalSearchObj.PromisedStartDate,
                  globalSearchObj.TimezoneString
                ),
                { forceShow: true }
              )}
            </td>
            <td
              className="text-left"
              id={"promiseEndDateId_" + globalSearchObj.ReservationId}
            >
              {createTooltip(
                convertDateTimeString(globalSearchObj.PromiseEndDate) || "--",
                convertDateTimeStringWithTimezone(
                  globalSearchObj.PromiseEndDate,
                  globalSearchObj.TimezoneString
                ),
                { forceShow: true }
              )}
            </td>
            <td
              className="text-left"
              id={"pickUpLocationId_" + globalSearchObj.ReservationId}
            >
              {createTooltip(
                globalSearchObj.PickUpLocation || "--",
                globalSearchObj.PickUpLocation
              )}
            </td>
          </tr>
        );
      });
    } else {
      if (
        this.state.searchData &&
        this.state.searchData.Rentals &&
        this.state.isOneResult
      ) {
        this.props.hideExpandedSearchArea();
        return (
          <Redirect
            to={{
              pathname: this.state.pathName,
              search: this.state.searchItem,
              state: {
                breadCrumbList: breadCrumbList,
              },
            }}
          />
        );
      }
    }
  }

  getModalSettings = () => {
    let modalSettings = {
      modalWidth: "1000",
      modalBodyHeight: "auto",
    };
    return modalSettings;
  };
  render() {
    let modalSettings = this.getModalSettings();
    return (
      <div>
        {this.state.loading ? (
          <Spinner />
        ) : (
          <Draggable handle=".cursor">
            <Modal
              isOpen={this.state.showModal}
              className="no-cursor"
              toggle={() => this.props.modalClose(true)}
              style={{
                minWidth: modalSettings.modalWidth + "px",
              }}
            >
              <ModalHeader
                className="modal-header bg-primary modal-title text-white cursor"
                toggle={() => this.props.modalClose(true)}
              >
                <i className="fas fa-arrows-alt" /> Global Search Result
              </ModalHeader>
              {this.state.loading ? <Spinner /> : ""}
              <ModalBody
                style={{
                  height: modalSettings.modalBodyHeight + "px",
                }}
              >
                <div
                  className={`col-sm-12 fs-d font-weight-bold ${
                    this.state.isTopSearchLinkDisplay ? "" : "displayNone"
                  }`}
                >
                  <div className="form-group formRow">
                    <label className="col-sm-6" style={{ textAlign: "left" }}>
                      Displaying 10 matches - select an entry or adjust criteria
                      to narrow results.
                    </label>
                    <label className="col-sm-6" style={{ textAlign: "right" }}>
                      For a complete list Search in{" "}
                      <Link
                        to="/member"
                        onClick={() => this.props.modalClose(true)}
                      >
                        {getMemberLabelByOrgRentalType()}
                      </Link>{" "}
                      /{" "}
                      <Link
                        to="/reservation"
                        onClick={() => this.props.modalClose(true)}
                      >
                        Reservation
                      </Link>{" "}
                      /{" "}
                      <Link
                        to="/rental"
                        onClick={() => this.props.modalClose(true)}
                      >
                        Rental
                      </Link>{" "}
                      tab(s).
                    </label>
                  </div>
                </div>
                <div
                  className={`FormRow ${
                    !this.state.isTopSearchLinkDisplay ? "" : "displayNone"
                  }`}
                >
                  <div className="form-group formRow">
                    <label className="col-sm-12" style={{ textAlign: "left" }}>
                      Multiple results found - please choose a result that
                      matches your criteria
                    </label>
                  </div>
                </div>
                <section
                  className={`grid-table ${
                    this.state.showMember ? "" : "displayNone"
                  }`}
                  id="globalSearchMemberTable"
                >
                  <div className="table-fixed-head tableScroll position-relative">
                    <table
                      className="table table-striped table-hover"
                      style={{ tableLayout: "fixed" }}
                    >
                      <thead className="thead-primary">
                        <tr>
                          <th style={{ width: "15%" }}>
                            {ADMIN_COMMON_CAPTION.CUSTOMER_ID}
                          </th>
                          <th style={{ width: "20%" }}>Name</th>
                          <th style={{ width: "20%" }}>Email Address</th>
                          <th style={{ width: "20%" }}>Phone</th>
                          <th style={{ width: "25%" }}>Address</th>
                        </tr>
                      </thead>
                      <tbody>{this.memberRenderList()}</tbody>
                    </table>
                  </div>
                </section>
                <section
                  className={`grid-table ${
                    this.state.showReservation ? "" : "displayNone"
                  }`}
                  id="globalSearchReservationTable"
                >
                  <div className="table-fixed-head tableScroll position-relative">
                    <table
                      className="table table-striped table-hover"
                      style={{ tableLayout: "fixed" }}
                    >
                      <thead className="thead-primary">
                        <tr>
                          <th style={{ width: "10%" }}>Res. Code</th>
                          <th style={{ width: "10%" }}>
                            {EXTERNAL_CODE_LBL.CONFIRMATION_CODE}
                          </th>
                          <th style={{ width: "10%" }}>Res. Status</th>
                          <th style={{ width: "20%" }}>
                            {getMemberLabelByOrgRentalType("Name")}
                          </th>
                          <th style={{ width: "15%" }}>Start Date/Time</th>
                          <th style={{ width: "15%" }}>End Date/Time</th>
                          <th style={{ width: "20%" }}>Pickup Location</th>
                        </tr>
                      </thead>
                      <tbody>{this.reservationRenderList()}</tbody>
                    </table>
                  </div>
                </section>
                <section
                  className={`grid-table ${
                    this.state.showRental ? "" : "displayNone"
                  }`}
                  id="globalSearchrentalTable"
                >
                  <div className="table-fixed-head tableScroll position-relative">
                    <table
                      className="table table-striped table-hover"
                      style={{ tableLayout: "fixed" }}
                    >
                      <thead className="thead-primary">
                        <tr>
                          <th style={{ width: "10%" }}>Rental Code</th>
                          <th style={{ width: "10%" }}>
                            {EXTERNAL_CODE_LBL.CONFIRMATION_CODE}
                          </th>
                          <th style={{ width: "10%" }}>Rental Status</th>
                          <th style={{ width: "20%" }}>
                            {getMemberLabelByOrgRentalType("Name")}
                          </th>
                          <th style={{ width: "15%" }}>Start Date/Time</th>
                          <th style={{ width: "15%" }}>End Date/Time</th>
                          <th style={{ width: "20%" }}>Pickup Location</th>
                        </tr>
                      </thead>
                      <tbody>{this.rentalRenderList()}</tbody>
                    </table>
                  </div>
                </section>
              </ModalBody>
            </Modal>
          </Draggable>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    tokenId: state.auth.token,
    userId: state.auth.userId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSetAuthRedirectPath: (path) =>
      dispatch(actions.setAuthRedirectPath(path)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GlobalSearchPopup);

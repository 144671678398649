export const getGeneralPreference = () => {
  return {
    RemindUpcomingAppointment: {
      name: "RemindUpcomingAppointment",
      elementType: "checkbox",
      labeltext: "Remind Upcoming Appointment",
      phoneSelected: false,
      emailSelected: false,
      smsSelected: false,
      value: "",
      order: 1
    },
    AutoExtender: {
      name: "AutoExtender",
      elementType: "checkbox",
      labeltext: "Auto Extender",
      phoneSelected: false,
      emailSelected: false,
      smsSelected: false,
      value: "",
      order: 2
    },
    PromoteOtherSpecials: {
      name: "PromoteOtherSpecials",
      elementType: "checkbox",
      labeltext: "Promote Other Specials",
      phoneSelected: false,
      emailSelected: false,
      smsSelected: false,
      value: "",
      order: 3
    },
    RemindUpcomingAppointment: {
      name: "RemindUpcomingAppointment",
      elementType: "checkbox",
      labeltext: "Remind Upcoming Appointment",
      phoneSelected: false,
      emailSelected: false,
      smsSelected: false,
      value: "",
      order: 4
    },
    SharePersonalData: {
      name: "SharePersonalData",
      elementType: "checkbox",
      labeltext: "Share Personal Data",
      phoneSelected: false,
      emailSelected: false,
      smsSelected: false,
      value: "",
      order: 5
    },
    FollowUpOnRecentAppointment: {
      name: "FollowUpOnRecentAppointment",
      elementType: "checkbox",
      labeltext: "Follow-up on Recent Appointment",
      phoneSelected: false,
      emailSelected: false,
      smsSelected: false,
      value: "",
      order: 6
    },
    CreditCardExpiration: {
      name: "CreditCardExpiration",
      elementType: "checkbox",
      labeltext: "Credit Card Expiration",
      phoneSelected: false,
      emailSelected: false,
      smsSelected: false,
      value: "",
      order: 7
    },
    TaskCreated: {
      name: "TaskCreated",
      elementType: "checkbox",
      labeltext: "Task Created",
      phoneSelected: false,
      emailSelected: false,
      smsSelected: false,
      value: "",
      order: 8
    },
    DiscrepancyFound: {
      name: "DiscrepancyFound",
      elementType: "checkbox",
      labeltext: "Discrepancy Found",
      phoneSelected: false,
      emailSelected: false,
      smsSelected: false,
      value: "",
      order: 9
    },
    LoginFailure: {
      name: "LoginFailure",
      elementType: "checkbox",
      labeltext: "Login Failure",
      phoneSelected: false,
      emailSelected: false,
      smsSelected: false,
      value: "",
      order: 10
    },
    ReservationUpdatedbyOthers: {
      name: "ReservationUpdatedbyOthers",
      elementType: "checkbox",
      labeltext: "Reservation Updated by Others",
      phoneSelected: false,
      emailSelected: false,
      smsSelected: false,
      value: "",
      order: 11
    },
    FeedbackbyOthers: {
      name: "FeedbackbyOthers",
      elementType: "checkbox",
      labeltext: "Feedback by Others",
      phoneSelected: false,
      emailSelected: false,
      smsSelected: false,
      value: "",
      order: 12
    },
    NewMessagebyOthers: {
      name: "NewMessagebyOthers",
      elementType: "checkbox",
      labeltext: "New Message by Others",
      phoneSelected: false,
      emailSelected: false,
      smsSelected: false,
      value: "",
      order: 13
    }
  };
};
export const getOwnerPreference = () => {
  return {
    NewReservation: {
      name: "NewReservation",
      elementType: "checkbox",
      labeltext: "New Reservation",
      phoneSelected: false,
      emailSelected: false,
      smsSelected: false,
      value: "",
      order: 1
    },
    NewRequest: {
      name: "NewRequest",
      elementType: "checkbox",
      labeltext: "New Request",
      phoneSelected: false,
      emailSelected: false,
      smsSelected: false,
      value: "",
      order: 2
    },
    ReservationCancelled: {
      name: "ReservationCancelled",
      elementType: "checkbox",
      labeltext: "Reservation Cancelled",
      phoneSelected: false,
      emailSelected: false,
      smsSelected: false,
      value: "",
      order: 3
    },
    ReservationReschedule: {
      name: "ReservationReschedule",
      elementType: "checkbox",
      labeltext: "Reservation Reschedule",
      phoneSelected: false,
      emailSelected: false,
      smsSelected: false,
      value: "",
      order: 4
    },
    MarkingtheVehicleOffline: {
      name: "MarkingtheVehicleOffline",
      elementType: "checkbox",
      labeltext: "Marking the Vehicle Offline",
      phoneSelected: false,
      emailSelected: false,
      smsSelected: false,
      value: "",
      order: 5
    },
    KeyRequested: {
      name: "KeyRequested",
      elementType: "checkbox",
      labeltext: "Key Requested",
      phoneSelected: false,
      emailSelected: false,
      smsSelected: false,
      value: "",
      order: 6
    }
  };
};
export const getRenterPreference = () => {
  return {
    ReservationCancelled: {
      name: "ReservationCancelledRenter",
      elementType: "checkbox",
      labeltext: "Reservation Cancelled",
      phoneSelected: false,
      emailSelected: false,
      smsSelected: false,
      value: "",
      order: 1
    },
    RequestAcceptanceDecline: {
      name: "RequestAcceptanceDecline",
      elementType: "checkbox",
      labeltext: "Request Acceptance/Decline",
      phoneSelected: false,
      emailSelected: false,
      smsSelected: false,
      value: "",
      order: 2
    }
  };
};
export const loadPreferences = (preferenceObj, preferenceSelectedValue) => {
  let preferenceData = { ...preferenceObj };
   const phonePreferance = preferenceSelectedValue.PhonePreference
     ? preferenceSelectedValue.PhonePreference.split(",")
     : [];
   const emailPreference = preferenceSelectedValue.EmailPreference
     ? preferenceSelectedValue.EmailPreference.split(",")
     : [];
   const smsPreference = preferenceSelectedValue.SmsPreference
     ? preferenceSelectedValue.SmsPreference.split(",")
     : [];

  Object.keys(preferenceObj).map((dataKey, index) => {
    preferenceData[dataKey].phoneSelected =
      phonePreferance[index] === "1" ? true : false;
    preferenceData[dataKey].emailSelected =
      emailPreference[index] === "1" ? true : false;
    preferenceData[dataKey].smsSelected =
      smsPreference[index] === "1" ? true : false;
  });
  return preferenceData;
};

import React, { useState } from "react";
import {
  getSortingClassName,
  getSortType,
  hasPermission,
  isNullOrEmpty,
  getDateTimeToolTipTextWithTimezone,
  convertSecondToDayHourMinSec,
  convertDateTimeString,
  getNoteTooltipHtml,
  toTitleCase,
  convertArrayDataToTitleString,
  getDateOnlyString,
  convertDateTimeStringWithTimezone,
} from "../../../shared/utility";
import ReactHtmlParser from "react-html-parser";
import PermissionName from "../../../shared/Permissions";
import ModalInfo from "../../../services/entities/models/ModalInfo";
import { ModalType } from "../../../shared/GlobalVar";
import ModalEngine from "../../../components/modal/modal-engine";
import { createTooltip } from "../../../shared/utility/tooltip-utility";
export const SearchReadyLineAgentGrid = (props) => {
  const [modalInfo, setModalInfo] = useState([new ModalInfo()]);
  const hasReportViewPermission = hasPermission(PermissionName.REPORT_SEARCH);
  const hasViewMemberPermission = hasPermission(PermissionName.VIEW_MEMBER);
  const hasViewVehiclePermission = hasPermission(PermissionName.VIEW_VEHICLE);
  const closeModal = () => {
    setModalInfo(new ModalInfo());
  };
  const showModal = (modalData) => {
    setModalInfo({
      type: modalData.type,
      showModal: true,
      trackerDetails: modalData.trackerDetails,
      memberId: modalData.memberId,
      vehicleId: modalData.vehicleId,
    });
  };
  const getTableRows = props.dataList.map((mechanicData) => {
    return (
      <div className="row" key={mechanicData.TrackerId}>
        <span className="row__column  cb-container">
          <ul className="icon">
            <li>
              {createTooltip(
                <div
                  id={mechanicData.AuditStatus + "_" + mechanicData.TrackerId}
                  className={`colorBox ${mechanicData.AuditStatus}`}
                  style={{
                    height: "14px",
                    width: "14px",
                  }}
                />,
                toTitleCase(mechanicData.AuditStatus),
                { forceShow: true }
              )}
            </li>
          </ul>
        </span>
        <span
          className="row__column">
          {createTooltip(
            getDateOnlyString(mechanicData.CreatedOn),

            convertDateTimeStringWithTimezone(
              mechanicData.CreatedOn,
              mechanicData.TimezoneString
            ),
            { forceShow: true }
          )}
        </span>
        <span className="row__column">
          {createTooltip(
            <span
              className={hasReportViewPermission ? "pseudo-link" : ""}
              onClick={(event) =>
                hasReportViewPermission
                  ? showModal({
                      type: ModalType.TRACKER_QUICK_MODAL,
                      showModal: true,
                      trackerDetails: mechanicData,
                    })
                  : ""
              }
            >
              {mechanicData.TrackerId || "--"}
            </span>,
            mechanicData.TrackerId
          )}
        </span>      
        <span className="row__column">
          {createTooltip(
            <span
              className={hasViewMemberPermission ? "pseudo-link" : ""}
              onClick={(event) =>
                hasViewMemberPermission
                  ? showModal({
                      type: ModalType.MEMBER_QUICK_MODAL,
                      showModal: true,
                      memberId: mechanicData.AgentId,
                    })
                  : ""
              }
            >
              {mechanicData.AgentName || "--"}
            </span>,
            mechanicData.AgentName
          )}
        </span>
        <span className="row__column">
          {createTooltip(mechanicData.AuditStatus || "--", mechanicData.AuditStatus)}
        </span>
        <span
          className="row__column"
        >
          {createTooltip(
            convertDateTimeString(mechanicData.AuditStartTime),
            convertDateTimeStringWithTimezone(
              mechanicData.AuditStartTime,
              mechanicData.TimezoneString
            ),
            { forceShow: true }
          )}
        </span>
        <span
          className="row__column"
        >
          {createTooltip(
            convertDateTimeString(mechanicData.AuditEndTime),
            convertDateTimeStringWithTimezone(
              mechanicData.AuditEndTime,
              mechanicData.TimezoneString
            ),
            { forceShow: true }
          )}
        </span>
        <span className="row__column">
          {createTooltip(
            <label>
              {mechanicData.AuditProcessingTime
                ? convertSecondToDayHourMinSec(mechanicData.AuditProcessingTime)
                : "--"}
            </label>,
            ReactHtmlParser(
              getNoteTooltipHtml(
                mechanicData.AuditProcessingTime
                  ? convertSecondToDayHourMinSec(mechanicData.AuditProcessingTime)
                  : ""
              )
            )
          )}
        </span>
        <span className="row__column">
          {createTooltip(mechanicData.AuditBy || "--", mechanicData.AuditBy)}
        </span>
        <span className="row__column">
          {createTooltip(
            <label>
              {!isNullOrEmpty(mechanicData.AuditFailCauses)
                ? mechanicData.AuditFailCauses.length
                  ? convertArrayDataToTitleString(mechanicData.AuditFailCauses)
                  : "--"
                : "N/A"}
            </label>,
            convertArrayDataToTitleString(mechanicData.AuditFailCauses)
          )}
        </span>
        <span className="row__column">
          {createTooltip(mechanicData.AuditNote || "--", mechanicData.AuditNote)}
        </span>
        <span className="row__column">
          {createTooltip(
            <label>
              {mechanicData.ProfileLocationName || "--"}
            </label>,
            mechanicData.ProfileLocationName
          )}
        </span>
      </div>
    );
  });
  return (
    <div className="datagrid">
      <div className="column">
        <span className="column__header" />
        <span
          className={getSortingClassName(props.pageInfo, "CreatedOn")}
          onClick={(event) =>
            props.doSorting(
              getSortType(props.pageInfo, "CreatedOn"),
              "CreatedOn"
            )
          }
        >
          Date Created
        </span>
        <span
          className={getSortingClassName(props.pageInfo, "TrackerId")}
          onClick={(event) =>
            props.doSorting(
              getSortType(props.pageInfo, "TrackerId"),
              "TrackerId"
            )
          }
        >
          Tracker ID
        </span>
        <span
          className={getSortingClassName(props.pageInfo, "AgentName")}
          onClick={(event) =>
            props.doSorting(
              getSortType(props.pageInfo, "AgentName"),
              "AgentName"
            )
          }
        >
          Agent
        </span>
        <span
          className={getSortingClassName(props.pageInfo, "AuditStatus")}
          onClick={(event) =>
            props.doSorting(getSortType(props.pageInfo, "AuditStatus"), "AuditStatus")
          }
        >
          Audit Status
        </span>
        <span
          className={getSortingClassName(props.pageInfo, "AuditStartTime")}
          onClick={(event) =>
            props.doSorting(getSortType(props.pageInfo, "AuditStartTime"), "AuditStartTime")
          }
        >
          Audit Start Date 
        </span>
        <span
          className={getSortingClassName(props.pageInfo, "AuditEndTime")}
          onClick={(event) =>
            props.doSorting(getSortType(props.pageInfo, "AuditEndTime"), "AuditEndTime")
          }
        >
          Audit End Date 
        </span>
        <span
          className={getSortingClassName(props.pageInfo, "ProcessingTime")}
          onClick={(event) =>
            props.doSorting(
              getSortType(props.pageInfo, "ProcessingTime"),
              "ProcessingTime"
            )
          }
        >
          Processing Time
        </span>
        <span
          className={getSortingClassName(props.pageInfo, "AuditBy")}
          onClick={(event) =>
            props.doSorting(
              getSortType(props.pageInfo, "AuditBy"),
              "AuditBy"
            )
          }
        >
         Audited By 
        </span>
        <span className="column__header">Audit Failed Causes / Reasons</span>
        <span
          className={getSortingClassName(props.pageInfo, "AuditNotes")}
          onClick={(event) =>
            props.doSorting(
              getSortType(props.pageInfo, "AuditNotes"),
              "AuditNotes"
            )
          }
        >
          Audit Notes
        </span>
        <span
          className={getSortingClassName(props.pageInfo, "ProfileLocationName")}
          onClick={(event) =>
            props.doSorting(
              getSortType(props.pageInfo, "ProfileLocationName"),
              "ProfileLocationName"
            )
          }
        >
          Profile Location
        </span>
      </div>
      {getTableRows}
      {modalInfo.showModal ? (
        <ModalEngine
          modalType={modalInfo.type}
          showModal={modalInfo.showModal}
          closeModal={closeModal}
          trackerDetails={modalInfo.trackerDetails}
          memberId={modalInfo.memberId}
          vehicleId={modalInfo.vehicleId}
        />
      ) : null}
    </div>
  );
};
export default SearchReadyLineAgentGrid;

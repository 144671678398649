import React from 'react';
import ResCopyClipboard from './res-copy-clipboard';

function ResItemCard(props) {
    const { name, value, apiData } = props;
    return (
        <div className='res-item-card-container'>
            <label className='res-item-card-name'>{name}</label>
            {
                apiData?.isCopyToClipBoard ?
                    <div className='res-card-value-container'>
                        <label className='res-item-card-value'>{value || "--"}</label>
                        {value && <ResCopyClipboard value={value}>
                            <img
                                src={apiData?.icon}
                                alt={value}
                                onClick={apiData?.onClick}
                                title='Copy to clipboard'
                            />
                        </ResCopyClipboard>}
                    </div>
                    : apiData?.onClick ?
                        <div className='res-card-value-container'>
                            <label className='res-item-card-value'>{value}</label>
                            <img
                                src={apiData?.icon}
                                alt={value}
                                onClick={apiData?.onClick}
                            />
                        </div>
                        : <label className='res-item-card-value'>{value}</label>
            }
        </div>
    )
}

export default ResItemCard
import React, { Component, Fragment } from "react";
import { DropdownList } from "react-widgets";
import Combobox from "react-widgets/lib/Combobox";
import { TooltipItemForLongLabel } from "../../../components/tooltip/TooltipItemForLongLabel";
import { getAddressTypeList } from "../../../containers/Member/MemberHelper";
import { invokeZipCodeStackAPI } from "../../../services/httpService";
import {
  Utility,
  getDisplayTextOfDropdown,
  isNullOrEmpty,
  toTitleCase,
} from "../../../shared/utility";
import { getKeyValFromList } from "../../../shared/utility/list-utility";
import { createTooltip } from "../../../shared/utility/tooltip-utility";
import { getCustomEventObject } from "../common-helper";
class AddressComponent extends Component {
  state = {
    address: null,
    [this.props.type + "Countries"]: null,
    [this.props.type + "States"]: null,
    [this.props.type + "Cities"]: null,
    [this.props.type + "Postals"]: null,
    addressTypeList: getAddressTypeList(),
    zipToAddrData: null,
    searchByZipCode: null,
  };

  loadDataForZipToAddress = (dataList, valueToCheck, changeMethod, ctrl) => {
    let tValue = valueToCheck;
    if (dataList && dataList.length) {
      const data = dataList.find(
        (element) => element.Key === valueToCheck.toUpperCase()
      );
      tValue = data && data.Value ? data.Value : null;
    }
    const event = {
      target: {
        value: "",
        key: "",
      },
    };
    event.target.value = tValue;
    event.target.key = valueToCheck.toUpperCase();
    changeMethod(event, ctrl);
  };
  loadDataForZipToAddressCommon = () => {
    if (this.state.zipToAddrData) {
      this.props.onChangeMethod(
        null,
        "STATETEXT_CITYTEXT_ZIP",
        this.props.type,
        {
          state: this.state.zipToAddrData.state,
          city: this.state.zipToAddrData.city,
          postalCode: this.state.zipToAddrData.postal_code,
        }
      );
    }
  };
  loadDataForCityZipToAddressCommon = (dataList, valueToCheck) => {
    if (this.state.zipToAddrData) {
      let tValue = valueToCheck;
      if (dataList && dataList.length) {
        const data = dataList.find(
          (element) => element.Key === valueToCheck.toUpperCase()
        );
        tValue = data && data.Value ? data.Value : valueToCheck;
      }
      this.props.onChangeMethod(null, "CITY_ZIP", this.props.type, {
        state: tValue,
        city: this.state.zipToAddrData.city,
        postalCode: this.state.zipToAddrData.postal_code,
      });
    }
  };

  isRemoteCallRequired = (ctrl) => {
    const cDataList = this.state[this.props.type + "Countries"];
    const cData = cDataList
      ? cDataList.find(
        (element) => element.Value === parseInt(this.props.address.Country)
      )
      : {};
    return !cDataList || !cData ||
      cData.countryCode === "US" ||
      (cData.countryCode === "IN" && ctrl === "state")
      ? true
      : false;
  };

  componentDidMount = () => {
    this.loadCountries();
    if (this.props.address.Country) {
      this.loadStates(this.props.address.Country);
    }
    if (this.props.address.State) {
      this.loadCities(this.props.address.Country, this.props.address.State);
    }
    if (this.props.address.City) {
      this.loadPostals(
        this.props.address.Country,
        this.props.address.State,
        this.props.address.City
      );
    }
    this.setState({
      searchByZipCode:
        this.state.searchByZipCode || this.props.address.ZipCodeText,
    });
  };
  componentDidUpdate = (prevProps) => {
    if (
      !this.props.address ||
      (this.props.address &&
        prevProps.address &&
        this.isAddressChanged(this.props.address, prevProps.address))
    ) {
      this.getActionforDataChanges(this.props.address, prevProps.address);
      this.setState({
        searchByZipCode:
          this.state.searchByZipCode || this.props.address.ZipCodeText,
      });
    }
    let addressTypeListNew = [...this.state.addressTypeList];
    if (
      this.props.address.AddressType &&
      !addressTypeListNew.some(
        (item) => item.key === this.props.address.AddressType
      )
    ) {
      addressTypeListNew.push({
        key: this.props.address.AddressType,
        displayValue: toTitleCase(this.props.address.AddressType),
      });
      this.setState({
        addressTypeList: addressTypeListNew,
      });
    }
  };
  getLblHtmlCtrl = (lblName) => {
    return (
      <label className={this.props.rightLblClassName || "col-sm-4 p-0"}>
        {createTooltip(lblName || "--", lblName || "")}
      </label>
    );
  };
  isAddressChanged = (newAddress, prevAddress) => {
    if (
      prevAddress.Country !== newAddress.Country ||
      prevAddress.State !== newAddress.State ||
      prevAddress.City !== newAddress.City
    ) {
      return true;
    }
    return false;
  };
  getActionforDataChanges = (newAddress, prevAddress) => {
    if (newAddress.Country != prevAddress.Country) {
      this.loadStates(newAddress.Country);
    }
    if (
      newAddress.State != prevAddress.State &&
      !this.props.isCityNotRequired
    ) {
      if (this.isRemoteCallRequired("city")) {
        this.loadCities(newAddress.Country, newAddress.State);
      } else {
        this.setState({ [this.props.type + "Cities"]: null });
      }
    }
    if (
      newAddress.City != prevAddress.City &&
      !this.props.isPostalNotRequired
    ) {
      this.loadPostals(newAddress.Country, newAddress.State, newAddress.City);
    }
  };

  fetchZiptoAddress = async (event) => {
    const cDataList = this.state[this.props.type + "Countries"];
    const cData = cDataList.find(
      (element) => element.Value === parseInt(this.props.address.Country)
    );
    const country = cData.countryCode;
    const zipCode = event.target.value;
    const sDatalist = this.state[this.props.type + "States"];
    if (
      this.state.zipToAddrData &&
      parseInt(zipCode) === parseInt(this.state.zipToAddrData.postal_code)
    ) {
      if (this.state.zipToAddrData && this.state.zipToAddrData.state) {
        sDatalist && this.isRemoteCallRequired("city")
          ? this.loadDataForZipToAddress(
            sDatalist,
            this.state.zipToAddrData.state,
            this.onChangeState
          )
          : sDatalist && cData.countryCode === "IN"
            ? this.loadDataForCityZipToAddressCommon(
              sDatalist,
              this.state.zipToAddrData.state
            )
            : this.loadDataForZipToAddressCommon();
      }
    } else if (!isNullOrEmpty(zipCode)) {
      this.setState({ searchByZipCode: zipCode });
      this.props.resetLoader(true);
      const zipData = await invokeZipCodeStackAPI({
        codes: zipCode,
        country: country,
      });
      this.props.resetLoader(false);
      if (zipData) {
        this.setState(
          {
            zipToAddrData: zipData,
          },
          () => {
            if (this.state.zipToAddrData && this.state.zipToAddrData.state) {
              sDatalist && this.isRemoteCallRequired("city")
                ? this.loadDataForZipToAddress(
                  sDatalist,
                  this.state.zipToAddrData.state,
                  this.onChangeState
                )
                : sDatalist && cData.countryCode === "IN"
                  ? this.loadDataForCityZipToAddressCommon(
                    sDatalist,
                    this.state.zipToAddrData.state
                  )
                  : this.loadDataForZipToAddressCommon();
            }
          }
        );
      } else {
        this.props.resetLoader(false);
        this.setState({ zipToAddrData: null });
      }
    }
  };

  loadCountries = () => {
    this.props.resetLoader(true);
    Utility.countryStateCityPostal.getCountries((data) => {
      this.props.resetLoader(false);
      this.setState({
        [this.props.type + "Countries"]:
          Utility.countryStateCityPostal.countries.slice(),
        zipToAddrData: null,
      });
    });
  };
  loadStates = (countryId) => {
    if (this.isRemoteCallRequired("state")) {
      this.props.resetLoader(true);
      Utility.countryStateCityPostal.getStates(countryId, false, (stateResponse) => {
        this.props.resetLoader(false);
        this.setState(
          {
            [this.props.type + "States"]:
              stateResponse.States && stateResponse.States.length > 0
                ? stateResponse.States
                : null,
          },
          () => {
            if (this.state.zipToAddrData && this.state.zipToAddrData.state) {
              // if (this.isRemoteCallRequired("city")) {
              //   this.loadDataForZipToAddress(
              //     this.state[this.props.type + "States"],
              //     this.state.zipToAddrData.state,
              //     this.onChangeState,
              //     "State"
              //   );
              // } else {
              //   this.loadDataForCityZipToAddressCommon();
              // }
            }
          }
        );
      });
    } else {
      this.setState({ [this.props.type + "States"]: null })
    }
  };
  loadCities = (countryId, stateId) => {
    if (this.isRemoteCallRequired("city")) {
      this.props.resetLoader(true);
      Utility.countryStateCityPostal.getCities(
        countryId,
        stateId,
        false,
        (cityResponse) => {
          this.props.resetLoader(false);
          this.setState(
            {
              [this.props.type + "Cities"]:
                cityResponse.Cities && cityResponse.Cities.length > 0
                  ? cityResponse.Cities
                  : null,
            },
            () => {
              if (this.state.zipToAddrData && this.state.zipToAddrData.city) {
                if (this.isRemoteCallRequired("city")) {
                  this.loadDataForZipToAddress(
                    this.state[this.props.type + "Cities"],
                    this.state.zipToAddrData.city,
                    this.onChangeCity
                  );
                }
              }
            }
          );
        }
      );
    }
  };
  loadPostals = (countryId, stateId, postalId) => {
    if (this.isRemoteCallRequired("city")) {
      this.props.resetLoader(true);
      Utility.countryStateCityPostal.getPostals(
        countryId,
        stateId,
        postalId,
        false,
        (postalResponse) => {
          this.props.resetLoader(false);
          this.setState(
            {
              [this.props.type + "Postals"]:
                postalResponse.Postals && postalResponse.Postals.length > 0
                  ? postalResponse.Postals
                  : null,
            },
            () => {
              if (
                this.state.zipToAddrData &&
                this.state.zipToAddrData.postal_code
              ) {
                if (this.isRemoteCallRequired("city")) {
                  this.loadDataForZipToAddress(
                    this.state[this.props.type + "Postals"],
                    this.state.zipToAddrData.postal_code,
                    this.onChangePostal
                  );
                }
              }
            }
          );
        }
      );
    } else {
      this.setState({ [this.props.type + "Postals"]: null });
    }
  };
  onChangeCountry = (event) => {
    event = getCustomEventObject(event, "Value", "display")
    this.props.onChangeMethod(event, "Country", this.props.type);
    if (event.target.value) {
      this.loadStates(event.target.value);
    }
    this.setState({
      [this.props.type + "States"]: null,
      [this.props.type + "Cities"]: null,
      [this.props.type + "Postals"]: null,
    });
  };
  onChangeState = (event, ctrl) => {
    this.setState({
      [this.props.type + "Cities"]: null,
      [this.props.type + "Postals"]: null,
    });
    event = getCustomEventObject(event, "Value", "Key")
    this.props.onChangeMethod(event, ctrl || "State", this.props.type);
    if (
      event.target.value &&
      !this.props.isCityNotRequired &&
      this.isRemoteCallRequired()
    ) {
      this.loadCities(this.props.address.Country, event.target.value);
    }
  };
  onChangeCity = (event, ctrl) => {
    this.setState({
      [this.props.type + "Postals"]: null,
    });
    event = getCustomEventObject(event, "Value", "Key")
    this.props.onChangeMethod(event, ctrl || "City", this.props.type);
    if (
      event.target.value &&
      !this.props.isPostalNotRequired &&
      this.isRemoteCallRequired()
    ) {
      this.loadPostals(
        this.props.address.Country,
        this.props.address.State,
        event.target.value
      );
    }
  };
  onChangePostal = (event, ctrl) => {
    this.props.onChangeMethod(event, ctrl || "ZipCode", this.props.type);
  };
  getValueClasses = (controlName) => {
    let valueClasses = this.props.inputContainerClassName || "col-sm-8";
    let values =
      this.props.address[controlName.split(",")[0]] ||
      this.props.address[controlName.split(",")[1]];
    let faliedValidationClass = !isNullOrEmpty(values)
      ? ""
      : this.props.isValidateDone
        ? this.props.isValidationRequired === false ||
          this.props.isValidationRequired === "undefined"
          ? ""
          : "validation-failed"
        : "";
    return faliedValidationClass
      ? valueClasses + " " + faliedValidationClass
      : valueClasses;
  };
  render() {
    const labelIdPrefixForTooltip = "lbl_Address_";
    const zipCodeList = this.state[this.props.type + "Postals"] || [];
    const countryList = this.state[this.props.type + "Countries"] &&
      this.state[this.props.type + "Countries"].length > 0
      ? this.state[this.props.type + "Countries"] : [];

    let isExistSearchedZipCode =
      this.props.address && this.props.address.isValidZipCode;
    if (isExistSearchedZipCode) {
      isExistSearchedZipCode =
        zipCodeList.length &&
        zipCodeList.some(
          (item) => parseInt(item.Key) === parseInt(this.state.searchByZipCode)
        );
    }
    return (
      <Fragment>
        {this.props.adddressTypeRequired ? (
          <div
            className={`form-group row  ${this.props.isAstericsRequired && !this.props.isNoneEditableMode
              ? "required"
              : ""
              }`}
          >
            <label
              className={
                this.props.leftLblClassName ||
                "col-sm-4 col-form-label font-weight-bold"
              }
              id={labelIdPrefixForTooltip + "AddressType"}
            >
              Address Type
              <TooltipItemForLongLabel
                toolTipId={labelIdPrefixForTooltip + "AddressType"}
              />
            </label>
            <div className={this.getValueClasses("AddressType")}>
              {!this.props.isNoneEditableMode ? (
                <select
                  id={this.props.type + "-AddressType"}
                  disabled={this.props.disabled || false}
                  onChange={(event) =>
                    this.props.onChangeMethod(
                      event,
                      "AddressType",
                      this.props.type
                    )
                  }
                  value={this.props.address.AddressType || ""}
                >
                  {this.state.addressTypeList.map((element) => (
                    <option key={element.key} value={element.key}>
                      {element.displayValue}
                    </option>
                  ))}
                </select>
              ) : (
                this.getLblHtmlCtrl(this.props.address.AddressType)
              )}
            </div>
          </div>
        ) : (
          ""
        )}
        {!this.props.isStreet1NotRequired ? (
          <div
            className={`form-group row  ${this.props.isAstericsRequired && !this.props.isNoneEditableMode
              ? "required"
              : ""
              }`}
          >
            <label
              className={
                this.props.leftLblClassName ||
                "col-sm-4 col-form-label font-weight-bold"
              }
            >
              {this.props.street1Label || "Address 1"}
            </label>
            <div className={this.getValueClasses("Street1")}>
              {!this.props.isNoneEditableMode ? (
                <input
                  name="Street1"
                  type="text"
                  className="form-control"
                  disabled={this.props.disabled || false}
                  onChange={(event) =>
                    this.props.onChangeMethod(event, "Street1", this.props.type)
                  }
                  value={this.props.address.Street1 || ""}
                />
              ) : (
                this.getLblHtmlCtrl(this.props.address.Street1)
              )}
            </div>
          </div>
        ) : (
          ""
        )}
        {!this.props.isStreet2NotRequired ? (
          <div className="form-group row">
            <label
              className={
                this.props.leftLblClassName ||
                "col-sm-4 col-form-label font-weight-bold"
              }
            >
              {this.props.street2Label || "Address 2"}
            </label>
            <div className={this.getValueClasses("Street2")}>
              {!this.props.isNoneEditableMode ? (
                <input
                  name="Street2"
                  type="text"
                  className="form-control"
                  value={this.props.address.Street2 || ""}
                  disabled={this.props.disabled || false}
                  onChange={(event) =>
                    this.props.onChangeMethod(event, "Street2", this.props.type)
                  }
                />
              ) : (
                this.getLblHtmlCtrl(this.props.address.Street2)
              )}
            </div>
          </div>
        ) : (
          ""
        )}
        <div
          className={`form-group row  ${this.props.isAstericsRequired && !this.props.isNoneEditableMode
            ? "required"
            : ""
            }`}
        >
          <label
            className={
              this.props.leftLblClassName ||
              "col-sm-4 col-form-label font-weight-bold"
            }
            id={
              labelIdPrefixForTooltip +
              toTitleCase(this.props.coutryLabel || "Country").replace(" ", "")
            }
          >
            {this.props.coutryLabel || "Country"}
            <TooltipItemForLongLabel
              toolTipId={
                labelIdPrefixForTooltip +
                toTitleCase(this.props.coutryLabel || "Country").replace(
                  " ",
                  ""
                )
              }
            />
          </label>
          <div className={this.getValueClasses("Country")}>
            {!this.props.isNoneEditableMode ? (
              <DropdownList
                filter='contains'
                data={countryList}
                valueField="Value"
                textField="display"
                defaultValue={{ Value: "null", display: "Select Country" }}
                value={getKeyValFromList(countryList, this.props.address.Country, { Value: "null", display: "Select Country" }, "Value")}
                onChange={(value) => this.onChangeCountry(value)}
                disabled={this.props.disabled || false}
              />
              // <select
              //   id={this.props.type + "-Country"}
              //   onChange={(event) => this.onChangeCountry(event)}
              //   disabled={this.props.disabled || false}
              //   value={this.props.address.Country || ""}
              // >
              //   <option value="" keyValue="">
              //     Select Country
              //   </option>
              //   {this.state[this.props.type + "Countries"] &&
              //     this.state[this.props.type + "Countries"].length > 0
              //     ? this.state[this.props.type + "Countries"].map((element) => (
              //       <option
              //         key={element.Value}
              //         value={element.Value}
              //         keyValue={element.countryCode}
              //       >
              //         {element.display}
              //       </option>
              //     ))
              //     : ""}
              // </select>
            ) : (
              this.getLblHtmlCtrl(
                this.props.address.CountryText
                  ? this.props.address.CountryText
                  : getDisplayTextOfDropdown(
                    countryList,
                    this.props.address.Country,
                    "Value",
                    "display"
                  )
              )
            )}
          </div>
        </div>
        {!this.props.isPostalNotRequired ? (
          <div
            className={`form-group row  ${this.props.isAstericsRequired && !this.props.isNoneEditableMode
              ? "required"
              : ""
              }`}
          >
            <label
              className={
                this.props.leftLblClassName ||
                "col-sm-4 col-form-label font-weight-bold"
              }
              id={labelIdPrefixForTooltip + "ZipCodePostal"}
            >
              Zip/Postal Code
              <TooltipItemForLongLabel
                toolTipId={labelIdPrefixForTooltip + "ZipCodePostal"}
              />
            </label>
            <div
              className={`${this.getValueClasses("ZipCode,ZipCodeText")} ${(zipCodeList.length &&
                this.state.searchByZipCode &&
                isExistSearchedZipCode === false) ||
                isExistSearchedZipCode === false
                ? "validation-warned"
                : ""
                }`}
            >
              {!this.props.isNoneEditableMode ? (
                <Fragment>
                  {this.state[this.props.type + "Postals"] &&
                    this.state[this.props.type + "Postals"].length > 0 ? (
                    <Combobox
                      id={this.props.type + "-Postals"}
                      disabled={this.props.disabled || false}
                      data={this.state[this.props.type + "Postals"]}
                      dataKey="Value"
                      textField="Key"
                      defaultValue={this.state.searchByZipCode || ""}
                      onChange={(event) => this.onChangePostal(event)}
                      onBlur={(event) => this.fetchZiptoAddress(event)}
                      placeholder="Enter or Select Zip/Postal Code"
                      focusFirstItem={false}
                    />
                  ) : (
                    <input
                      id={this.props.type + "-ZipCodeText"}
                      type="text"
                      className="form-control"
                      disabled={this.props.disabled || false}
                      onChange={(event) =>
                        this.props.onChangeMethod(
                          event,
                          "ZipCodeText",
                          this.props.type
                        )
                      }
                      onBlur={(event) => this.fetchZiptoAddress(event)}
                      value={this.props.address.ZipCodeText || ""}
                      placeholder="Enter Zip/Postal Code"
                    ></input>
                  )}
                </Fragment>
              ) : (
                this.getLblHtmlCtrl(
                  this.props.address.ZipCodeText
                    ? this.props.address.ZipCodeText
                    : getDisplayTextOfDropdown(
                      this.state[this.props.type + "Postals"],
                      this.props.address.ZipCode,
                      "Value",
                      "Key"
                    )
                )
              )}
            </div>
          </div>
        ) : (
          ""
        )}
        <div
          className={`form-group row  ${this.props.isAstericsRequired && !this.props.isNoneEditableMode
            ? "required"
            : ""
            }`}
        >
          <label
            className={
              this.props.leftLblClassName ||
              "col-sm-4 col-form-label font-weight-bold"
            }
            id={
              labelIdPrefixForTooltip +
              toTitleCase(this.props.stateLabel || "State").replace(" ", "")
            }
          >
            {this.props.stateLabel || "State"}
            <TooltipItemForLongLabel
              toolTipId={
                labelIdPrefixForTooltip +
                toTitleCase(this.props.stateLabel || "State").replace(" ", "")
              }
            />
          </label>
          <div className={this.getValueClasses("State,StateText")}>
            {!this.props.isNoneEditableMode ? (
              <Fragment>
                {this.state[this.props.type + "States"] &&
                  this.state[this.props.type + "States"].length > 0 ? (
                  <DropdownList
                    filter='contains'
                    data={this.state[this.props.type + "States"]}
                    valueField="Value"
                    textField="Key"
                    defaultValue={{ Value: "null", Key: "Select State" }}
                    value={getKeyValFromList(this.state[this.props.type + "States"], this.props.address.State, { Value: "null", Key: "Select State" }, "Value")}
                    onChange={(value) => this.onChangeState(value)}
                    disabled={this.props.disabled || false}
                  />
                  // <select
                  //   id={this.props.type + "-State"}
                  //   onChange={(event) => this.onChangeState(event)}
                  //   disabled={this.props.disabled || false}
                  //   value={this.props.address.State || ""}
                  // >
                  //   <option value="">Select State</option>
                  //   {this.state[this.props.type + "States"].map(
                  //     (element, index) => (
                  //       <option
                  //         key={element.Value}
                  //         value={element.Value}
                  //         keyValue={element.keyValue}
                  //       >
                  //         {element.Key}
                  //       </option>
                  //     )
                  //   )}
                  // </select>
                ) : (
                  <input
                    id={this.props.type + "-StateText"}
                    type="text"
                    className="form-control"
                    disabled={this.props.disabled || false}
                    onChange={(event) =>
                      this.props.onChangeMethod(
                        event,
                        "StateText",
                        this.props.type
                      )
                    }
                    value={this.props.address.StateText || ""}
                    {...this.props.titleAttr}
                  ></input>
                )}
              </Fragment>
            ) : (
              this.getLblHtmlCtrl(
                this.props.address.StateText
                  ? this.props.address.StateText
                  : getDisplayTextOfDropdown(
                    this.state[this.props.type + "States"],
                    this.props.address.State,
                    "Value",
                    "Key"
                  )
              )
            )}
          </div>
        </div>

        {!this.props.isCityNotRequired ? (
          <div
            className={`form-group row  ${this.props.isAstericsRequired && !this.props.isNoneEditableMode
              ? "required"
              : ""
              }`}
          >
            <label
              className={
                this.props.leftLblClassName ||
                "col-sm-4 col-form-label font-weight-bold"
              }
            >
              City
            </label>
            <div className={this.getValueClasses("City,CityText")}>
              {!this.props.isNoneEditableMode ? (
                <Fragment>
                  {this.state[this.props.type + "Cities"] &&
                    this.state[this.props.type + "Cities"].length > 0 ? (
                    <DropdownList
                      filter='contains'
                      data={this.state[this.props.type + "Cities"]}
                      valueField="Value"
                      textField="Key"
                      defaultValue={{ Value: "null", Key: "Select City" }}
                      value={getKeyValFromList(this.state[this.props.type + "Cities"], this.props.address.City, { Value: "null", Key: "Select City" }, "Value")}
                      onChange={(value) => this.onChangeCity(value)}
                      disabled={this.props.disabled || false}
                    />
                    // <select
                    //   id={this.props.type + "-City"}
                    //   onChange={(event) => this.onChangeCity(event)}
                    //   disabled={this.props.disabled || false}
                    //   value={this.props.address.City || ""}
                    // >
                    //   <option value="">Select City</option>
                    //   {this.state[this.props.type + "Cities"].map((element) => (
                    //     <option
                    //       key={element.Value}
                    //       value={element.Value}
                    //       keyValue={element.keyValue}
                    //     >
                    //       {element.Key}
                    //     </option>
                    //   ))}
                    // </select>
                  ) : (
                    <input
                      id={this.props.type + "-CityText"}
                      type="text"
                      className="form-control"
                      disabled={this.props.disabled || false}
                      onChange={(event) =>
                        this.props.onChangeMethod(
                          event,
                          "CityText",
                          this.props.type
                        )
                      }
                      value={this.props.address.CityText || ""}
                    ></input>
                  )}
                </Fragment>
              ) : (
                this.getLblHtmlCtrl(
                  this.props.address.CityText
                    ? this.props.address.CityText
                    : getDisplayTextOfDropdown(
                      this.state[this.props.type + "Cities"],
                      this.props.address.City,
                      "Value",
                      "Key"
                    )
                )
              )}
            </div>
          </div>
        ) : (
          ""
        )}
      </Fragment>
    );
  }
}
export default AddressComponent;

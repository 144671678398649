import PageInfo from "../../services/entities/models/PageInfo";
import {
  getAssetAvailabilityStatus,
  getAssetTypesOrGroup,
} from "../vehicle/vehicle-helper";
export const getSearchFavouritesCtrls = () => {
  return {
    PageInfo: new PageInfo(null, null, null, null),
    AssetType: {
      id: "AssetType",
      elementType: "select",
      elementConfig: {
        name: "AssetType",
        options: getAssetTypesOrGroup(),
      },
      labelInput: {
        labeltext: "Type",
      },
      value: "",
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      basicOrder: 1,
      advancedOrder: 0,
    },
    PersonAssetAvailabilityStatus: {
      id: "PersonAssetAvailabilityStatus",
      elementType: "select",
      elementConfig: {
        name: "PersonAssetAvailabilityStatus",
        options: getAssetAvailabilityStatus(),
      },
      labelInput: {
        labeltext: "Status",
      },
      value: "",
      validation: {
        required: false,
      },
      valid: false,
      touched: false,
      basicOrder: 2,
      advancedOrder: 0,
    },
    buttons: [
      {
        id: "btnResSearch",
        elementType: "button",
        elementConfig: {
          type: "submit",
          value: "Search",
          name: "btnResSearch",
          className: "btn btn-primary search-btn",
        },
      },
      {
        id: "btnResSearchReset",
        elementType: "button",
        elementConfig: {
          type: "button",
          value: "Reset",
          name: "btnResSearchReset",
          className: "btn btn-secondary search-btn",
        },
      },
    ],
  };
};

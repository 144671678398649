import * as actionTypes from "./actionTypes";
import { invokeServerAPI } from "../../services/httpService";

export const searchActivitiesStart = () => {
  return {
    type: actionTypes.SEARCH_ACTIVITIES_START,
  };
};
export const searchActivitiesSuccess = (response) => {
  return {
    type: actionTypes.SEARCH_ACTIVITIES_SUCCESS,
    searchActivitiesResponse: response,
  };
};
export const searchActivitiesFail = (error) => {
  return {
    type: actionTypes.SEARCH_ACTIVITIES_FAIL,
    error: error,
  };
};
export const searchActivitiesReset = () => {
  return {
    type: actionTypes.SEARCH_ACTIVITIES_RESET,
  };
};

export const addActivityStart = () => {
  return {
    type: actionTypes.ADD_ACTIVITY_START,
  };
};
export const addActivitySuccess = (response) => {
  return {
    type: actionTypes.ADD_ACTIVITY_SUCCESS,
    addActivityResponse: response,
  };
};
export const addActivityFail = (error) => {
  return {
    type: actionTypes.ADD_ACTIVITY_FAIL,
    error: error,
  };
};
export const addActivityReset = () => {
  return {
    type: actionTypes.ADD_ACTIVITY_RESET,
  };
};

export const resendCreditGrantEmailStart = () => {
  return {
    type: actionTypes.RESEND_CREDIT_GRANT_EMAIL_START,
  };
};
export const resendCreditGrantEmailSuccess = (response) => {
  return {
    type: actionTypes.RESEND_CREDIT_GRANT_EMAIL_SUCCESS,
    resendCreditGrantEmailResponse: response,
  };
};
export const resendCreditGrantEmailFail = (error) => {
  return {
    type: actionTypes.RESEND_CREDIT_GRANT_EMAIL_FAIL,
    error: error,
  };
};
export const resendCreditGrantEmailReset = () => {
  return {
    type: actionTypes.RESEND_CREDIT_GRANT_EMAIL_RESET,
  };
};

export const searchMemberFavoriteAssetStart = () => {
  return {
    type: actionTypes.SEARCH_MEMBER_FAVORITE_START,
  };
};
export const searchActivities = (req) => {
  return (dispatch) => {
    dispatch(searchActivitiesStart());
    invokeServerAPI("searchactivitylog", req)
      .then((response) => {
        dispatch(searchActivitiesSuccess(response));
      })
      .catch((err) => {
        //dispatch(searchActivitiesFail(err));
      });
  };
};
export const addActivity = (req) => {
  return (dispatch) => {
    dispatch(addActivityStart());
    invokeServerAPI("addactivitylog", req)
      .then((response) => {
        dispatch(addActivitySuccess(response));
      })
      .catch((err) => {
        //dispatch(addActivityFail(err));
      });
  };
};
export const resendCreditGrantEmail = (req) => {
  return (dispatch) => {
    dispatch(resendCreditGrantEmailStart());
    invokeServerAPI("resendcreditgrantemail", req)
      .then((response) => {
        dispatch(resendCreditGrantEmailSuccess(response));
      })
      .catch((err) => {
        //dispatch(resendCreditGrantEmailFail(err));
      });
  };
};

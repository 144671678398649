import React from 'react';
import VehicleTags from '../../containers/Reservation/fox-reservation/vehicle/component/vehicle-tags';
import TaxesFeesBlock from '../../containers/Reservation/pricing/component/modern/taxes-fees-block';
import ResQuickSummary from '../../containers/Reservation/res-summary/res-quick-summary';
import { ModalType } from '../../shared/GlobalVar';
import QuickNotes from '../common/notes/quick-notes';
import DialogWrapper from './dialog-wrapper';

function DialogEngine(props) {
    const { modalType, modalProps, modalBodyProps } = props;
    return (
        <DialogWrapper
            {...modalProps}
        >
            {modalType === ModalType.QUICK_RES_SUMMARY ?
                <ResQuickSummary
                    {...modalBodyProps}
                />
                : ""}
            {modalType === ModalType.TAXES_FEES_MODAL ?
                <TaxesFeesBlock
                    {...modalBodyProps}
                />
                : ""}
            {modalType === ModalType.COMMON_NOTES_MODAL ?
                <QuickNotes
                    {...modalBodyProps}
                />
                : ""}
            {modalType === ModalType.VEHICLE_ATTR_MODAL ?
                <VehicleTags
                    {...modalBodyProps}
                />
                : ""}

        </DialogWrapper>
    )
}

export default DialogEngine
import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Draggable from "react-draggable";
import { AddonsModel } from "../../../../services/entities/view-models/settings-view-models";
import { updateObject, getErrorToastData } from "../../../../shared/utility";
import { showNotification } from "../../../../components/UI/Toaster/Toaster";
import { validateAddonSettings } from "../../settingsHelper";
import { LabelAndValuePairControl } from "../../../../components/UI/Input/label-value-pair-control";
import CommonInputField from "../../../../components/UI/Input/common-input-field";

export const AdditionalOptionModal = (props) => {
  const entity = props.selectedEntity || new AddonsModel();
  const [addonsEntity, setAddonsEntity] = useState(entity);
  const [isValidateDone, setIsValidateDone] = useState(false);
  const handleOnChange = (event, controlName, elementType) => {
    let value = null;
    value =
      elementType === "checkbox" ? event.target.checked : event.target.value;
    const updatedControls = updateObject(addonsEntity, {
      [controlName]: value,
    });
    setAddonsEntity(updatedControls);
  };

  const updateAddonsItems = () => {
    let msg = validateAddonSettings(addonsEntity);
    if (msg) {
      showNotification(getErrorToastData(msg));
      setIsValidateDone(true);
    } else {
      setIsValidateDone(false);
      props.addUpdateAddonsEntity(addonsEntity);
    }
  };
  const renderLabelInputPair = (
    labelCaption,
    controlName,
    value,
    isRequired = false,
    nodeElement = "input",
    dropdownItem = null,
    inputType = "text",
    isDisable = false
  ) => {
    return (
      <LabelAndValuePairControl
        elementConfig={{
          name: controlName,
          value: value,
          type: inputType,
          disabled: isDisable,
          className: nodeElement === "select" ? "pl-0" : "",
        }}
        otherConfig={{
          caption: labelCaption,
          isRequired: isRequired,
          dropdownItem: nodeElement === "select" ? dropdownItem : [],
          value: value,
          dateFormat: "D",
        }}
        isEditMode={true}
        onChange={handleOnChange}
        parentRowClass={`form-group row  ${isRequired ? "required" : ""}`}
        labelClasses={"col-sm-4 col-form-label font-weight-bold"}
        valueClasses={"col-sm-8"}
        nodeElement={nodeElement}
        isValidateDone={isValidateDone}
      />
    );
  };
  const renderCheckboxes = (labelCaption, id, value, additionalClass) => {
    return (
      <CommonInputField
        elementConfig={{
          id: id,
          type: "checkbox",
          name: id,
          checked: value,
        }}
        otherConfig={{
          caption: labelCaption,
        }}
        nodeElement="checkbox"
        onChange={handleOnChange}
        valueClasses={`col-sm-4 text-left ${additionalClass}`}
      />
    );
  };

  return (
    <Draggable handle=".cursor">
      <Modal
        isOpen={props.showModal}
        className="no-cursor"
        toggle={() => {
          props.closeModal();
        }}
      >
        <ModalHeader
          className="modal-header bg-primary modal-title text-white cursor"
          toggle={() => {
            props.closeModal();
          }}
        >
          <i className="fas fa-cogs pr-2"></i>
          {addonsEntity.UpsellOptionId
            ? "Edit Additional Option"
            : "Add Additional Option"}
        </ModalHeader>
        <ModalBody>
          <div className="col-sm-12 p-0">
            {renderLabelInputPair(
              "Option Code",
              "Value",
              addonsEntity.Value,
              true
            )}

            {renderLabelInputPair(
              "Option Name",
              "DisplayText",
              addonsEntity.DisplayText,
              true
            )}

            {renderLabelInputPair(
              "Type",
              "Type",
              addonsEntity.Type,
              true,
              "select",
              props.types
            )}
            {renderLabelInputPair(
              "Max Qty.",
              "MaxQuantity",
              addonsEntity.MaxQuantity,
              true,
              "",
              null,
              "number"
            )}
            {renderLabelInputPair(
              "Option URL",
              "UpsellOptionUrl",
              addonsEntity.UpsellOptionUrl
            )}
            {renderLabelInputPair(
              "Unit Type",
              "UnitType",
              addonsEntity.UnitType,
              true,
              "select",
              props.units
            )}
            {renderLabelInputPair(
              "Rate",
              "Rate",
              addonsEntity.Rate,
              true,
              "",
              null,
              "number"
            )}
            {renderLabelInputPair(
              "Min",
              "MinRate",
              addonsEntity.MinRate,
              false,
              "",
              null,
              "number"
            )}
            {renderLabelInputPair(
              "Max",
              "MaxRate",
              addonsEntity.MaxRate,
              false,
              "",
              null,
              "number"
            )}
            {renderLabelInputPair(
              "Rate Type",
              "RateUnit",
              addonsEntity.RateUnit || "DOLLAR",
              true,
              "select",
              props.amountTypes
            )}
            <div className="form-group row col-sm-12">
              <label className="col-sm-4">&nbsp;</label>
              {renderCheckboxes(
                "Auto Apply",
                "AutoApply",
                addonsEntity.AutoApply,
                "pl-3"
              )}
              {renderCheckboxes("Taxes Apply", "Taxable", addonsEntity.Taxable)}
            </div>
            <div className="form-group row col-sm-12">
              <label className="col-sm-4">&nbsp;</label>
              {renderCheckboxes(
                "Customer Visible",
                "CustomerVisible",
                addonsEntity.CustomerVisible,
                "pl-3"
              )}
              {renderCheckboxes(
                "Adjustment Allowed",
                "AdjustmentAllowed",
                addonsEntity.AdjustmentAllowed
              )}
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="col-sm-12 text-right">
            <button
              type="button"
              name="btnYes"
              className="btn btn-primary waves-effect waves-light btnSize ml-2"
              onClick={() => updateAddonsItems()}
            >
              {addonsEntity.UpsellOptionId ? "Update" : "Submit"}
            </button>
            <button
              type="button"
              name="btnCancel"
              className="btn btn-secondary waves-effect waves-light btnSize ml-2"
              onClick={() => props.closeModal()}
            >
              Cancel
            </button>
          </div>
        </ModalFooter>
      </Modal>
    </Draggable>
  );
};
export default AdditionalOptionModal;

import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Draggable from "react-draggable";
import moment from "moment";
import DateTimePicker from "react-widgets/lib/DateTimePicker";
import { TaxSurchargesModel } from "../../../../services/entities/view-models/settings-view-models";
import { updateObject, getErrorToastData } from "../../../../shared/utility";
import { showNotification } from "../../../../components/UI/Toaster/Toaster";
import {
  getUnitCategoryTypes,
  validateTaxSurChargesData,
  getUnitTypes,
} from "../../settingsHelper";
import { LabelAndValuePairControl } from "../../../../components/UI/Input/label-value-pair-control";

export const TaxSurchargesModal = (props) => {
  const entity =
    props.selectedEntity || new TaxSurchargesModel(null, props.settingsType);
  const [updateEntity, setUpdateEntity] = useState(entity);
  const taxesSurchargesTypes = getUnitCategoryTypes();
  const unitTypesList = getUnitTypes().filter((item) => item.key !== "ROLLUP");
  const [isValidateDone, setIsValidateDone] = useState(false);

  const handleOnChange = (event, controlName, elementType) => {
    let value = null;
    if (event !== "Invalid date" && elementType === "datetimepicker") {
      value = event ? moment(event).format("MM:DD:YYYY:HH:mm") : event;
    } else {
      value = event.target.value;
    }
    const updatedControls = updateObject(updateEntity, {
      [controlName]: value,
    });
    if (controlName === "AmountType") {
      updatedControls["RecurrenceUnit"] = value === "PERCENT" ? "ONETIME" : "";
    }
    setUpdateEntity(updatedControls);
  };
  const updateData = () => {
    let msg = validateTaxSurChargesData(updateEntity);
    if (msg) {
      showNotification(getErrorToastData(msg));
      setIsValidateDone(true);
    } else {
      setIsValidateDone(false);
      props.addUpdateEntity(updateEntity, props.settingsType);
    }
  };
  const renderLabelInputPair = (
    labelCaption,
    controlName,
    value,
    isRequired = false,
    nodeElement = "input",
    dropdownItem = null,
    inputType = "text",
    isDisable = false
  ) => {
    return (
      <LabelAndValuePairControl
        elementConfig={{
          name: controlName,
          value: value,
          type: inputType,
          disabled: isDisable,
          className: nodeElement === "select" ? "pl-0" : "",
        }}
        otherConfig={{
          caption: labelCaption,
          isRequired: isRequired,
          dropdownItem: nodeElement === "select" ? dropdownItem : [],
          value: value,
          dateFormat: "D",
        }}
        isEditMode={true}
        onChange={handleOnChange}
        parentRowClass={`form-group row  ${isRequired ? "required" : ""}`}
        labelClasses={"col-sm-4 col-form-label font-weight-bold"}
        valueClasses={"col-sm-8"}
        nodeElement={nodeElement}
        isValidateDone={isValidateDone}
      />
    );
  };
  return (
    <Draggable handle=".cursor">
      <Modal
        isOpen={props.showModal}
        className="no-cursor"
        toggle={() => {
          props.closeModal();
        }}
      >
        <ModalHeader
          className="modal-header bg-primary modal-title text-white cursor"
          toggle={() => {
            props.closeModal();
          }}
        >
          <i className="fas fa-car-crash pr-2"></i>
          Add / Update Tax / Surcharge
        </ModalHeader>
        <ModalBody>
          <div className="col-sm-12 p-0">
            {renderLabelInputPair(
              "Description",
              "DisplayName",
              updateEntity.DisplayName,
              true
            )}
            {renderLabelInputPair(
              "Unit Type",
              "AmountType",
              updateEntity.AmountType,
              true,
              "select",
              unitTypesList
            )}
            {renderLabelInputPair(
              "Rate",
              "Amount",
              updateEntity.Amount,
              true,
              "",
              null,
              "number"
            )}
            {renderLabelInputPair(
              "Type",
              "RecurrenceUnit",
              updateEntity.RecurrenceUnit,
              true,
              "select",
              taxesSurchargesTypes,
              "",
              updateEntity.AmountType === "PERCENT"
            )}
            {renderLabelInputPair(
              "As of Date",
              "AsOfDate",
              updateEntity.AsOfDate,
              false,
              "datetime"
            )}
            {renderLabelInputPair(
              "End Date",
              "EndDate",
              updateEntity.EndDate,
              false,
              "datetime"
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="col-sm-12 text-right">
            <button
              type="button"
              name="btnYes"
              className="btn btn-primary waves-effect waves-light btnSize ml-2"
              onClick={() => updateData()}
            >
              {updateEntity.OrgTaxId ? "Update" : "Submit"}
            </button>
            <button
              type="button"
              name="btnCancel"
              className="btn btn-secondary waves-effect waves-light btnSize ml-2"
              onClick={() => props.closeModal()}
            >
              Cancel
            </button>
          </div>
        </ModalFooter>
      </Modal>
    </Draggable>
  );
};
export default TaxSurchargesModal;

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getReservationSummaryReset,
  getReservationSummary,
} from "../../../store/actions/index";
import {
  getBasicToastResponse,
  getPrintPopup,
} from "../../../shared/utility";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import Draggable from "react-draggable";
import { showNotification } from "../../../components/UI/Toaster/Toaster";
import MailModal from "../../search/modal/mail-modal";
import { MailType } from "../../../shared/GlobalVar";
export const ReservationInfoModal = (props) => {
  const reservationId = props.reservationId;
  const dispatch = useDispatch();
  const reservationSummaryResponse = useSelector(
    (state) => state.reservationReducer.reservationSummaryResponse
  );
  const [resSummaryHtml, setResSummaryHtml] = useState(null);
  const [showMailModal, setShowMailModal] = useState(false);
  useEffect(() => {
    const resSummaryReq = {};
    resSummaryReq.ReservationId = reservationId;
    dispatch(getReservationSummaryReset());
    dispatch(getReservationSummary(resSummaryReq));
  }, []);
  useEffect(() => {
    if (!reservationSummaryResponse) {
      return;
    }
    if (reservationSummaryResponse.StatusCode === 0) {
      setResSummaryHtml(reservationSummaryResponse.ReservationSummary);
    } else {
      showNotification(getBasicToastResponse(reservationSummaryResponse));
      dispatch(getReservationSummaryReset());
    }
  }, [reservationSummaryResponse]);

  const closeMailModal = () => {
    setShowMailModal(false);
  };
  const getFormattedText = (data) => {
    return {
      __html: data,
    };
  };
  return (
    <Draggable handle=".cursor">
      <Modal
        isOpen={props.showModal}
        className="no-cursor"
        toggle={() => {
          props.closeModal();
        }}
        style={{
          minWidth: "59rem",
        }}
      >
        <ModalHeader
          className="modal-header bg-primary modal-title text-white cursor"
          toggle={() => {
            props.closeModal();
          }}
        >
          <i className="fas fa-arrows-alt" /> Reservation Details
        </ModalHeader>
        <ModalBody>
          <div
            className="col-lg-12 tableScroll"
            style={{
              minHeight: "53rem",
            }}
          >
            <div className="col-lg-12 text-right">
              <button
                type="button"
                name="btnAddNewMember"
                className="btn btn-primary waves-effect waves-light btnSize ml-2"
                onClick={() => getPrintPopup("Reservation")}
                disabled={resSummaryHtml ? false : true}
              >
                Print
              </button>

              <button
                type="button"
                name="btnAddNewMember"
                className="btn btn-primary waves-effect waves-light btnSize ml-2"
                onClick={() => setShowMailModal(true)}
                disabled={resSummaryHtml ? false : true}
              >
                Email
              </button>
            </div>

            <div
              id="divPrintbody"
              className="print_popup_body col-lg-12 row"
              dangerouslySetInnerHTML={getFormattedText(
                resSummaryHtml ? resSummaryHtml : ""
              )}
            />
          </div>
          {showMailModal ? (
            <MailModal
              showModal={showMailModal}
              closeModal={closeMailModal}
              defaultData={{
                reservationId: props.reservationId,
                mailType: MailType.EMAIL_RESERVATION_SUMMARY,
              }}
            />
          ) : (
            ""
          )}
        </ModalBody>
      </Modal>
    </Draggable>
  );
};
export default ReservationInfoModal;

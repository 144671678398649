export const getPermissionTypes = () => {
  return [
    { key: "", displayValue: "Select" },
    { key: "Application Access", displayValue: "Application Access" },
    { key: "Reservation", displayValue: "Reservation" },
    { key: "Reservation & Rental", displayValue: "Reservation & Rental" },
    { key: "Rental", displayValue: "Rental" },
    { key: "Payment", displayValue: "Payment" },
    { key: "Member", displayValue: "Member" },
    { key: "Vehicle", displayValue: "Vehicle" },
    { key: "Org Settings", displayValue: "Org Settings" },
    { key: "Admin Settings", displayValue: "Admin Settings" },
    { key: "Roles & Permissions", displayValue: "Roles & Permissions" },
    { key: "Shuttle Application", displayValue: "Shuttle Application" },
    { key: "Others", displayValue: "Others" },
  ];
};

import moment from "moment";
import ReactHtmlParser from "react-html-parser";
import { foxAPIAddressChangeHandler } from "../../../components/common/common-helper";
import { FoxDiscountDetails } from "../../../services/entities/fox-api/domain/fox-discount-details";
import { FoxCancelReservationRequest } from "../../../services/entities/fox-api/request/fox-cancel-reservation-request";
import { FoxCreateReservationRequest } from "../../../services/entities/fox-api/request/fox-create-reservation-request";
import { FoxCustomerInfoRequest } from "../../../services/entities/fox-api/request/fox-customer-info-request";
import { FoxGetAddOnsRequest } from "../../../services/entities/fox-api/request/fox-get-addons-request";
import { FoxGetRatesRequest } from "../../../services/entities/fox-api/request/fox-get-rates-request";
import { FoxGetReservationRequest } from "../../../services/entities/fox-api/request/fox-get-reservation-request";
import { FoxPrimaryFormViewModel } from "../../../services/entities/fox-api/view-model/fox-primary-form-view-model";
import { FoxStepViewModel } from "../../../services/entities/fox-api/view-model/fox-step-view-model";
import { FoxVehicleClassViewModel } from "../../../services/entities/fox-api/view-model/fox-vehicle-class-view-model";
import {
  COMMON_MSG,
  FOX_PARTNER_CODES,
  ReservationBlocks,
  TOAST_MSG,
} from "../../../shared/GlobalVar";
import {
  getDateOnlyString,
  getFormattedValidationMsg,
  isEmail,
  isNullOrEmpty,
} from "../../../shared/utility";
import { PhoneType } from "../../../shared/utility/enum-utility";
import { getFormattedPhoneNumber } from "../../../shared/utility/phone-utility";
import { doSorting } from "../../../shared/utility/sorting-utility";
import { getDefaultErrMsg } from "../../../shared/utility/validation-helper";
export const createFoxAPIGetRatesRequest = (primaryViewData, addOnsList) => {
  return new FoxGetRatesRequest(primaryViewData, addOnsList);
};
export const createFoxAPIGetAddOnsRequest = (primaryViewData) => {
  return new FoxGetAddOnsRequest(primaryViewData);
};
export const createFoxAPICreateReservationRequest = (
  primaryViewData,
  addOnsList,
  customerData,
  specificPricingData,
  noteData,
  isPaylater
) => {
  const customerInfo = new FoxCustomerInfoRequest(customerData, isPaylater);
  const addressObject = { ...customerInfo.addresses[0] };
  addressObject.city = addressObject.city || addressObject.cityText;
  addressObject.province = addressObject.province || addressObject.provinceText;
  addressObject.postalCode =
    addressObject.postalCode || addressObject.postalCodeText;
  addressObject.type = "HOME";
  delete addressObject["provinceText"];
  delete addressObject["cityText"];
  delete addressObject["postalCodeText"];
  delete addressObject["isValidZipCode"];
  customerInfo.addresses[0] = { ...addressObject };

  const cloneDriversLicense = { ...customerInfo.driversLicense };
  cloneDriversLicense.province =
    cloneDriversLicense.province || cloneDriversLicense.provinceText;
  delete cloneDriversLicense["provinceText"];
  customerInfo.driversLicense = { ...cloneDriversLicense };
  // end
  let paymentData;
  // if (!isPaylater) {
  //   const fullName = customerData.firstName + " " + customerData.lastName;
  //   paymentData = new FoxPaymentMethodRequest(customerData, fullName);
  // }
  return new FoxCreateReservationRequest(
    primaryViewData,
    addOnsList,
    customerInfo,
    specificPricingData,
    paymentData,
    noteData
  );
};
export const createGetlReservationReq = (externalCode) => {
  return new FoxGetReservationRequest({ reservationCode: externalCode });
};
export const createCancelReservationReq = (data) => {
  return new FoxCancelReservationRequest(data);
};
export const create_UUID = () => {
  let dt = new Date().getTime();
  const uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
    /[xy]/g,
    function (c) {
      const r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
    }
  );
  return uuid;
};
export const convertMsgResponse = (response) => {
  let msg =
    response && response.messageType
      ? response.displayMessage
      : "Unknown server side error";
  return {
    msg: msg,
    toastType:
      response && response.messageType
        ? response.messageType === "SUCCESS"
          ? TOAST_MSG.SUCCESS
          : TOAST_MSG.ERROR
        : TOAST_MSG.ERROR,
  };
};

export const convertMsgResponseWithCustomInfo = (
  response,
  customMsg,
  customMsgTitle
) => {
  let msg =
    response && response.messageType
      ? response.displayMessage
      : "Unknown server side error";
  if (customMsg) {
    msg = ReactHtmlParser(customMsg + "<br>" + (customMsgTitle || "") + msg);
  }
  return {
    msg: msg,
    toastType:
      response && response.messageType
        ? response.messageType === "SUCCESS"
          ? TOAST_MSG.SUCCESS
          : TOAST_MSG.ERROR
        : TOAST_MSG.ERROR,
  };
};
export const createFoxResPrimaryFormUIModel = (reservationData) => {
  return new FoxPrimaryFormViewModel({
    rentalLocationCode: reservationData.rentalLocation.locationCode,
    rentalLocationId: reservationData.rentalLocation.locationId,
    returnLocationCode: reservationData.returnLocation.locationCode,
    returnLocationId: reservationData.returnLocation.locationId,
    rentalDateTime: reservationData.rentalDateTime,
    returnDateTime: reservationData.returnDateTime,
    payType: reservationData.pricing.type,
    sameAsRentFromLoaction:
      reservationData.rentalLocation.locationCode ===
      reservationData.returnLocation.locationCode
        ? true
        : false,
    reservationCode: reservationData.confirmationCode,
    foxDiscountCode:
      reservationData?.pricing?.foxDiscount?.discountCode || null,
    discount:
      reservationData.pricing &&
      (reservationData?.pricing?.discountCode || reservationData?.companyNumber)
        ? new FoxDiscountDetails({
            discountCode:
              reservationData?.pricing?.discountCode ||
              reservationData?.companyNumber,
          })
        : null,
  });
};
export const createFoxResVehicleUIModel = (reservationData) => {
  return new FoxVehicleClassViewModel({
    vehicleClassCode: reservationData.vehicleClass.classCode,
    prevVehicleClassCode: reservationData.vehicleClass.classCode,
    savedCarClass: reservationData.vehicleClass,
    savedRatesApplied: reservationData.pricing.ratesApplied || [],
    savedRatePlan: reservationData.pricing.ratePlan || {},
    savedTotalCharges: reservationData.pricing.totalCharges || 0,
    savedAddOnCharges: reservationData.pricing.addOnCharges || 0,
    savedPayType: reservationData?.pricing?.type || "",
  });
};
export const onInputChangedHandler = (
  ctrlData,
  controlName,
  elementType,
  objectType,
  viewData,
  setViewData,
  eventData,
  commonData,
  phoneValue,
  phoneData,
  phoneFormattedValue
) => {
  let value = null;
  let checked = null;
  if (elementType === "datetime") {
    value = ctrlData ? moment(ctrlData).format("YYYY-MM-DDTHH:mm:ss") : null;
  } else if (elementType === "auto-suggestions") {
    value = ctrlData;
  } else if (ctrlData && ctrlData.target) {
    if (["checkbox", "radio"].includes(elementType)) {
      checked = ctrlData.target.checked;
    } else {
      value = ctrlData.target.value;
    }
  } else if (["checkbox", "radio"].includes(elementType)) {
    checked = ctrlData.target.checked;
  } else {
    value = ctrlData;
  }
  const viewDataModel = { ...viewData };
  if (objectType && typeof value === "object" && value !== null) {
    viewDataModel[objectType] = { ...value };
    if (elementType === "address") {
      let addressObj = { ...value };
      addressObj["isValidZipCode"] = true;
      addressObj = foxAPIAddressChangeHandler(
        eventData,
        controlName,
        commonData,
        addressObj
      );
      if (controlName === "expDate" && objectType === "license") {
        value =
          ctrlData && ctrlData[controlName]
            ? moment(ctrlData[controlName]).format("YYYY-MM-DDTHH:mm:ss")
            : null;
        addressObj.expDate = value;
      }
      viewDataModel[objectType] = { ...addressObj };
    }
  } else if (objectType && elementType === "phone") {
    viewDataModel[objectType]["number"] = value;
  } else if (objectType && elementType === "phonewithflag") {
    if (ctrlData.type === "change") {
      viewDataModel[objectType]["countryCode"] = phoneData.dialCode;
      viewDataModel[objectType]["countryIso"] =
        phoneData.countryCode.toUpperCase();
      viewDataModel[objectType]["number"] = phoneValue;
      viewDataModel[objectType]["formatedNumber"] = phoneFormattedValue;
    } else if (["keydown", "click"].includes(ctrlData.type)) {
      viewDataModel[objectType]["countryCode"] = phoneData.dialCode;
      viewDataModel[objectType]["countryIso"] =
        phoneData.countryCode.toUpperCase();
    }
  } else if (objectType && controlName) {
    viewDataModel[objectType][controlName] = ["checkbox", "radio"].includes(
      elementType
    )
      ? checked
      : value;
  } else {
    viewDataModel[controlName] = value;
  }
  setViewData(viewDataModel);
};
/**Phone Number*/
export const getFormattedFoxApiPhoneNumber = (phoneData, defaultValue) => {
  if (!phoneData) return defaultValue ? defaultValue : "";
  let formattedNumber = "";
  if (phoneData.countryCode) {
    formattedNumber = "+" + phoneData.countryCode;
  }
  if (phoneData.number && phoneData.countryIso) {
    formattedNumber +=
      " " + getFormattedPhoneNumber(phoneData.number, phoneData.countryIso);
  }
  return formattedNumber;
};
// Html Label Tag With Value
export const getHtmlLabelTagWithValue = (values, classes) => {
  let newClassList = classes.split(",");
  var newLabel = document.createElement("label");
  newClassList.length &&
    newClassList.forEach((element) => {
      newLabel.classList.add(element || "");
    });
  newLabel.appendChild(document.createTextNode(values));
  return newLabel.outerHTML;
};
/**Date and Time */
export const convertFoxApiDateToStr = (
  serverTimeStr,
  returnTimeFormat,
  defaultValue
) => {
  if (!serverTimeStr) {
    return defaultValue ? defaultValue : "";
  }
  const defaultTimeForamt = returnTimeFormat
    ? returnTimeFormat
    : "YYYY-MM-DDTHH:mm:ss";
  return moment(serverTimeStr).format(defaultTimeForamt);
};
/**VALIDATION */
export const getErrMsgForCancelReservation = (data) => {
  let errorMsg = "";
  if (!data.cancelReasons || data.cancelReasons.length === 0) {
    errorMsg += COMMON_MSG.ReservationCancellationReason;
  }
  if (errorMsg) {
    return getFormattedValidationMsg(errorMsg);
  }
  return errorMsg;
};
export const validateParimaryResData = (data, isBoolean) => {
  let errorMsg = "";
  const starttime =
    data.rentalDateTime && data.rentalOnlyDate && data.rentalOnlyTime
      ? moment(data.rentalDateTime, "YYYY-MM-DDTHH:mm:ss").toDate()
      : null;
  const endtime =
    data.returnDateTime && data.returnOnlyDate && data.returnOnlyTime
      ? moment(data.returnDateTime, "YYYY-MM-DDTHH:mm:ss").toDate()
      : null;

  errorMsg = getDefaultErrMsg(
    data.rentalLocationCode,
    errorMsg,
    "Rental location"
  );
  errorMsg = getDefaultErrMsg(
    data.returnLocationCode,
    errorMsg,
    "Return location"
  );
  errorMsg = getDefaultErrMsg(starttime, errorMsg, "Pickup date");
  errorMsg = getDefaultErrMsg(endtime, errorMsg, "Return date");
  // errorMsg = hasObjectNonEmptyProperty(data.discount)? getDefaultErrMsg(data.discount.discountType,errorMsg,COMMON_MSG.DiscountType):errorMsg;
  // errorMsg = hasObjectNonEmptyProperty(data.discount)? getDefaultErrMsg(data.discount.discountCode,errorMsg,COMMON_MSG.DiscountCode):errorMsg;
  if (errorMsg) {
    return getFormattedValidationMsg(errorMsg);
  }
  if (starttime && endtime && endtime.getTime() <= starttime.getTime()) {
    errorMsg = errorMsg
      ? errorMsg + "<br>Return Time must be after Pickup Time."
      : "Return Time must be after Pickup Time.";
  }
  if (errorMsg) {
    return isBoolean ? false : ReactHtmlParser(errorMsg);
  }
  return isBoolean ? true : errorMsg;
};
export const validateCustomerBlock = (data) => {
  const validateAddressField = (addresses, type, field) => {
    const address = addresses.find((element) => element.type === type);
    return address && address[field];
  };

  const requiredFields = [
    { field: data.firstName, message: COMMON_MSG.FirstName },
    { field: data.lastName, message: COMMON_MSG.LastName },
    { field: isEmail(data.email), message: COMMON_MSG.EmailAddress },
    {
      field:
        data.phoneNumbers.length > 0 &&
        data.phoneNumbers.find((element) => element.type === "Home"),
      message: COMMON_MSG.PhoneNoRequired,
    },
    {
      field: validateAddressField(data.addresses, "HOME", "country"),
      message: COMMON_MSG.Country,
    },
    {
      field:
        validateAddressField(data.addresses, "HOME", "postalCode") ||
        validateAddressField(data.addresses, "HOME", "postalCodeText"),
      message: COMMON_MSG.ZipCode,
    },
    // { field: data.dob, message: COMMON_MSG.DOB },
  ];

  const errorMsgs = requiredFields
    .filter(({ field }) => !field)
    .map(({ message }) => message);

  if (errorMsgs.length > 0) {
    return getFormattedValidationMsg(errorMsgs.join(", "));
  }

  return "";
};
export const validateRewardsInformationMatch = (
  customerFormData,
  renterOriginalBasicInfo
) => {
  if (
    customerFormData.renterId &&
    customerFormData.renterId === renterOriginalBasicInfo.renterId
  ) {
    if (
      customerFormData.firstName !== renterOriginalBasicInfo.firstName ||
      customerFormData.lastName !== renterOriginalBasicInfo.lastName ||
      customerFormData.email !== renterOriginalBasicInfo.email
    ) {
      return COMMON_MSG.RewardsInformationMisMatchMsg;
    }
  }
  return "";
};
/**Payment Block */
export const getPaymentMethods = () => {
  return [
    { keyValue: "", displayValue: "Select Payment Method" },
    { keyValue: "CreditCard", displayValue: "Credit Card" },
    { keyValue: "DebitCard", displayValue: "Debit Card" },
  ];
};
export const getPaymentCardTypeList = () => {
  return [
    { keyValue: "", displayValue: "Select Card Type" },
    { keyValue: "AX", displayValue: "American Express" },
    { keyValue: "DC", displayValue: "Diners Club" },
    { keyValue: "DS", displayValue: "Discover" },
    { keyValue: "JC", displayValue: "JCB" },
    { keyValue: "MC", displayValue: "MasterCard" },
    { keyValue: "VI", displayValue: "Visa" },
  ];
};

/** Discount */
export const getDiscountTypes = () => {
  return [
    // { keyValue: "", displayValue: "Select" },
    { keyValue: "CODE", displayValue: "Code" },
    { keyValue: "AMOUNT", displayValue: "$", disabled: "disabled" },
    { keyValue: "PERCENT", displayValue: "%", disabled: "disabled" },
  ];
};
/**compatible block */
export const getComapatibleCustomerDataFromSearchMember = (
  foxCustomerData,
  selectedMemData
) => {
  const customerData = { ...foxCustomerData };
  customerData.customerId = selectedMemData.MemberId;
  customerData.firstName = selectedMemData.FirstName;
  customerData.middleName = selectedMemData.MiddleName;
  customerData.lastName = selectedMemData.LastName;
  customerData.email = selectedMemData.EmailAddress;
  customerData.renterId = selectedMemData.MembershipId;
  customerData.dob = selectedMemData.Dob
    ? convertFoxApiDateToStr(
        moment(selectedMemData.Dob, "MM:DD:YYYY:HH:mm").toDate(),
        "YYYY-MM-DDTHH:mm:ss"
      )
    : null;
  if (selectedMemData.PrimaryAddress) {
    customerData.primaryAddress = {
      type: selectedMemData.PrimaryAddress.AddressType,
      street: selectedMemData.PrimaryAddress.Street1,
      street2: selectedMemData.PrimaryAddress.Street2 || null,
      city: selectedMemData.PrimaryAddress.CityText,
      province: selectedMemData.PrimaryAddress.StateText,
      postalCode: selectedMemData.PrimaryAddress.ZipCodeText,
      country: selectedMemData.PrimaryAddress.CountryText,
    };
  }
  customerData.paymentFailureCount = selectedMemData.PaymentFailureCount;
  customerData.freeCredits = selectedMemData.FreeCredits;
  customerData.employeeNo = selectedMemData.Employee
    ? selectedMemData.Employee.EmployeeNo || null
    : null;
  customerData.employeeLocationName = selectedMemData.Employee
    ? selectedMemData.Employee.LocationName || null
    : null;
  customerData.companyName = selectedMemData.Employee
    ? selectedMemData.Employee.CompanyName || null
    : null;
  customerData.designation = selectedMemData.Employee
    ? selectedMemData.Employee.Title || null
    : null;
  if (selectedMemData.MobilePhone) {
    customerData.mobilePhone = {
      type: PhoneType.MOBILE,
      country: selectedMemData.MobilePhone.countryIso,
      countryCode: selectedMemData.MobilePhone.countryCode,
      number: selectedMemData.MobilePhone.Number,
    };
  }
  if (selectedMemData.HomePhone) {
    customerData.homePhone = {
      type: PhoneType.HOME,
      country: selectedMemData.HomePhone.CountryIso,
      countryCode: selectedMemData.HomePhone.CountryCode,
      number: selectedMemData.HomePhone.Number,
    };
  }
  if (selectedMemData.WorkPhone) {
    customerData.workPhone = {
      type: PhoneType.WORK,
      country: selectedMemData.WorkPhone.CountryIso,
      countryCode: selectedMemData.WorkPhone.CountryCode,
      number: selectedMemData.WorkPhone.Number,
    };
  }
  if (selectedMemData.DrivingLicense) {
    customerData.license = {
      country: selectedMemData.DrivingLicense.DrivingLicenseCountryShortCode,
      province: selectedMemData.DrivingLicense.DrivingLicenseStateText || null,
      number: selectedMemData.DrivingLicense.DrivingLicenseNumber,
      expDate: selectedMemData.DrivingLicense.LicenseExpirationDate
        ? convertFoxApiDateToStr(
            moment(
              selectedMemData.DrivingLicense.LicenseExpirationDate,
              "MM:DD:YYYY:HH:mm"
            ).toDate(),
            "YYYY-MM-DDTHH:mm:ss"
          )
        : null,
      licenseEditable: selectedMemData.DrivingLicense.LicenseEditable,
      manualDmvVerified: selectedMemData.DrivingLicense.ManualDmvVerified,
      tempLicenseUploadAllowed:
        selectedMemData.DrivingLicense.TempLicenseUploadAllowed,
      licenseManuallyApproved:
        selectedMemData.DrivingLicense.LicenseManuallyApproved,
      drivingRecordChecked: selectedMemData.DrivingLicense.DrivingRecordChecked,
    };
  }
  if (selectedMemData.Insurance) {
    customerData.insurance = {
      insuranceCarrier: selectedMemData.Insurance.InsuranceCarrier,
      insuranceVerified: selectedMemData.Insurance.InsuranceVerified || null,
      policyNumber: selectedMemData.Insurance.PolicyNumber,
      insuranceExpirationDate: selectedMemData.Insurance.InsuranceExpirationDate
        ? convertFoxApiDateToStr(
            moment(
              selectedMemData.Insurance.InsuranceExpirationDate,
              "MM:DD:YYYY:HH:mm"
            ).toDate(),
            "YYYY-MM-DDTHH:mm:ss"
          )
        : null,
    };
  }
  if (selectedMemData.CreditCard) {
    customerData.methodType = selectedMemData.CreditCard.PaymentMethod;
    customerData.paymentService = selectedMemData.CreditCard.CreditCardType;
    customerData.expDate =
      selectedMemData.CreditCard.CardExpirationYear +
      "-" +
      selectedMemData.CreditCard.CardExpirationMonth;
    customerData.accountNumber = selectedMemData.CreditCard.CardNumber;
    customerData.name = selectedMemData.CreditCard.BillingName;
    customerData.securityCode = selectedMemData.CreditCard.CVVCode;
    if (selectedMemData.Address) {
      customerData.creditAddress = {
        type: selectedMemData.Address.AddressType,
        street: selectedMemData.Address.Street1,
        street2: selectedMemData.Address.Street2 || null,
        city: selectedMemData.Address.CityText,
        province: selectedMemData.Address.StateText,
        postalCode: selectedMemData.Address.ZipCode,
        country: selectedMemData.Address.CountryText,
      };
    }
    customerData.flight = selectedMemData.flight;
    customerData.airline = selectedMemData.airline;
    customerData.referralAgency = selectedMemData.referralAgency;
    customerData.tourOperator = selectedMemData.tourOperator;
  }
  return customerData;
};
export const getUpdatedSelectedAddons = (addonsList, oldSelectedAddons) => {
  let allAddonsCodes = [...addonsList.map((addOns) => addOns.code)];
  var updatedSelectedArray = oldSelectedAddons.filter((oldAddOns) => {
    return allAddonsCodes.includes(oldAddOns.code);
  });
  return updatedSelectedArray;
};

export const getFormattedAddress_fox = (addressObj) => {
  if (!addressObj) return "";
  let formattedAddress = "";
  if (addressObj.street) {
    formattedAddress = addressObj.street;
  }
  if (addressObj.street2) {
    formattedAddress = formattedAddress
      ? formattedAddress + ", " + addressObj.street2
      : addressObj.street2;
  }
  // if (addressObj.Street3) {
  //   formattedAddress = formattedAddress
  //     ? formattedAddress + ", " + addressObj.Street3
  //     : addressObj.Street3;
  // }
  // if (addressObj.Street4) {
  //   formattedAddress = formattedAddress
  //     ? formattedAddress + ", " + addressObj.Street4
  //     : addressObj.Street4;
  // }
  if (addressObj.city) {
    formattedAddress = formattedAddress
      ? formattedAddress + ", " + addressObj.city
      : addressObj.city;
  }
  if (addressObj.province) {
    formattedAddress = formattedAddress
      ? formattedAddress + ", " + addressObj.province
      : addressObj.province;
  }
  if (addressObj.postalCode) {
    formattedAddress = formattedAddress
      ? formattedAddress + ", " + addressObj.postalCode
      : addressObj.postalCode;
  }
  if (addressObj.country) {
    formattedAddress = formattedAddress
      ? formattedAddress + ", " + addressObj.country
      : addressObj.country;
  }
  return formattedAddress;
};

export const getFormattedPhoneNumber_fox = (mobilePhoneInfo) => {
  let phoneNumber = "";
  let firstPhoneNumber = "";
  let phoneNumberOnly = "";
  if (mobilePhoneInfo) {
    if (
      !isNullOrEmpty(mobilePhoneInfo.countryCode) &&
      mobilePhoneInfo.countryCode !== 0
    ) {
      phoneNumberOnly = mobilePhoneInfo.number;
      phoneNumber = `(M) +${mobilePhoneInfo.countryCode} - ${phoneNumberOnly}`;
      if (isNullOrEmpty(firstPhoneNumber)) {
        firstPhoneNumber = phoneNumber;
      }
    }
  }
  return phoneNumber;
};
export const getFormattedLicenseNumber_fox = (licenseObj) => {
  let formattedLicense = null;
  if (licenseObj) {
    formattedLicense = licenseObj.number
      ? "<b>" + licenseObj.number + "</b>"
      : "";

    if (licenseObj.expDate) {
      formattedLicense = formattedLicense
        ? formattedLicense +
          " - " +
          getDateOnlyString(licenseObj.expDate) +
          "<br>"
        : getDateOnlyString(licenseObj.expDate) + "<br>";
    }
    if (licenseObj.province) {
      formattedLicense = formattedLicense
        ? formattedLicense + licenseObj.province
        : licenseObj.province;
    }
    if (licenseObj.country) {
      formattedLicense = formattedLicense
        ? formattedLicense + "," + licenseObj.country
        : licenseObj.country;
    }
  }
  return ReactHtmlParser(formattedLicense);
};

export const getCompatibleVehicleClasses = (data, foxVehicleClassViewModel) => {
  data = data && data.length > 0 ? data : [];
  const vehicleClassesList = [];
  data.map((rentalOfferObj) => {
    if (rentalOfferObj.vehicleClass && rentalOfferObj.vehicleClass.length > 0) {
      vehicleClassesList.push({
        classCode: rentalOfferObj.vehicleClass[0].classCode,
        displayText: rentalOfferObj.vehicleClass[0].displayText,
        classImageURL: rentalOfferObj.vehicleClass[0].classImageURL,
        tags: rentalOfferObj.vehicleClass[0].tags,
        classDesc: rentalOfferObj.vehicleClass[0].classDesc,
        modelDesc: rentalOfferObj.vehicleClass[0].modelDesc,
        mpgCity: rentalOfferObj.vehicleClass[0].mpgCity,
        mpgHighway: rentalOfferObj.vehicleClass[0].mpgHighway,
        minimumAge: rentalOfferObj.vehicleClass[0].minimumAge,
        maximumAge: rentalOfferObj.vehicleClass[0].maximumAge,
        advBookingHours: rentalOfferObj.vehicleClass[0].advBookingHours,
        smallLuggageCount: rentalOfferObj.vehicleClass[0].smallLuggageCount,
        transmission: rentalOfferObj.vehicleClass[0].transmission,
        airConditioning: rentalOfferObj.vehicleClass[0].airConditioning,
        largeLuggageCount: rentalOfferObj.vehicleClass[0].largeLuggageCount,
        passengerCount: rentalOfferObj.vehicleClass[0].passengerCount,
        luggageCount: rentalOfferObj.vehicleClass[0].luggageCount,
        rateId: rentalOfferObj.rateProduct[0].pricing[0].rateId,
      });
    }
    return vehicleClassesList;
  });
  if (
    !vehicleClassesList.length &&
    foxVehicleClassViewModel.savesCarClass &&
    foxVehicleClassViewModel.savesCarClass.categoryId
  ) {
    vehicleClassesList.push(foxVehicleClassViewModel.savesCarClass);
  }
  return vehicleClassesList;
};

export const getOldVehicleWithNewRate = (prevClassCode, vehicleClasses) => {
  let oldVehicleInfo = {};
  vehicleClasses.map((item) => {
    if (item.classCode === prevClassCode) {
      return (
        (oldVehicleInfo.oldVehicleClassCode = item.classCode),
        (oldVehicleInfo.newRateId = item.rateId)
      );
    }
    return oldVehicleInfo;
  });
  return oldVehicleInfo;
};
export const createStepViewModelForBasicInfoWizard = (otherData) => {
  otherData = otherData || {};
  return new FoxStepViewModel({
    currentBlock: ReservationBlocks.BASIC_INFO,
    prevBlock: null,
    nextBlock: ReservationBlocks.VEHICLE_CLASSES,
    executionBlock: otherData.iskeepChanged
      ? null
      : otherData.executionBlock || null,
    iskeepChanged: otherData.iskeepChanged || false,
    wizardMsg: otherData.wizardMsg || null,
    archiveData: otherData.archiveData || null,
    isError: otherData.isError || false,
  });
};
export const createStepViewModelForVehicleClassesWizard = (otherData) => {
  otherData = otherData || {};
  return new FoxStepViewModel({
    currentBlock: ReservationBlocks.VEHICLE_CLASSES,
    prevBlock: ReservationBlocks.BASIC_INFO,
    nextBlock: ReservationBlocks.ADDONS_FORM,
    executionBlock: otherData.iskeepChanged
      ? null
      : otherData.executionBlock || null,
    iskeepChanged: otherData.iskeepChanged || false,
    wizardMsg: otherData.wizardMsg || null,
    archiveData: otherData.archiveData || null,
    isError: otherData.isError || false,
  });
};
export const createStepViewModelForAddonsWizard = (otherData) => {
  otherData = otherData || {};
  return new FoxStepViewModel({
    currentBlock: ReservationBlocks.ADDONS_FORM,
    prevBlock: ReservationBlocks.VEHICLE_CLASSES,
    nextBlock: ReservationBlocks.CUSTOMER_INFO,
    executionBlock: otherData.iskeepChanged
      ? null
      : otherData.executionBlock || null,
    iskeepChanged: otherData.iskeepChanged || false,
    wizardMsg: otherData.wizardMsg || null,
    archiveData: otherData.archiveData || null,
    isError: otherData.isError || false,
  });
};
export const createStepViewModelForCustomerWizard = (otherData) => {
  otherData = otherData || {};
  return new FoxStepViewModel({
    currentBlock: ReservationBlocks.CUSTOMER_INFO,
    prevBlock: ReservationBlocks.ADDONS_FORM,
    nextBlock: ReservationBlocks.RES_NOTES_BLOCK,
    executionBlock: otherData.iskeepChanged
      ? null
      : otherData.executionBlock || null,
    iskeepChanged: otherData.iskeepChanged || false,
    wizardMsg: otherData.wizardMsg || null,
    archiveData: otherData.archiveData || null,
    isError: otherData.isError || false,
  });
};
export const createStepViewModelForAdditionalDriverWizard = (otherData) => {
  otherData = otherData || {};
  return new FoxStepViewModel({
    currentBlock: ReservationBlocks.ADDITIONAL_DRIVER_INFO,
    prevBlock: ReservationBlocks.CUSTOMER_INFO,
    nextBlock: ReservationBlocks.RES_NOTES_BLOCK,
    executionBlock: otherData.iskeepChanged
      ? null
      : otherData.executionBlock || null,
    iskeepChanged: otherData.iskeepChanged || false,
    wizardMsg: otherData.wizardMsg || null,
    archiveData: otherData.archiveData || null,
    isError: otherData.isError || false,
  });
};
export const createStepViewModelForNotesWizard = (otherData) => {
  otherData = otherData || {};
  return new FoxStepViewModel({
    currentBlock: ReservationBlocks.RES_NOTES_BLOCK,
    prevBlock: ReservationBlocks.CUSTOMER_INFO,
    nextBlock: null,
    executionBlock: otherData.iskeepChanged
      ? null
      : otherData.executionBlock || null,
    iskeepChanged: otherData.iskeepChanged || false,
    wizardMsg: otherData.wizardMsg || null,
    archiveData: otherData.archiveData || null,
    isError: otherData.isError || false,
  });
};
export const createStepViewModelByWizard = (wizardBlock, otherData) => {
  if (wizardBlock === ReservationBlocks.BASIC_INFO) {
    return createStepViewModelForBasicInfoWizard(otherData);
  } else if (wizardBlock === ReservationBlocks.VEHICLE_CLASSES) {
    return createStepViewModelForVehicleClassesWizard(otherData);
  } else if (wizardBlock === ReservationBlocks.ADDONS_FORM) {
    return createStepViewModelForAddonsWizard(otherData);
  } else if (wizardBlock === ReservationBlocks.CUSTOMER_INFO) {
    return createStepViewModelForCustomerWizard(otherData);
  } else if (wizardBlock === ReservationBlocks.ADDITIONAL_DRIVER_INFO) {
    return createStepViewModelForAdditionalDriverWizard(otherData);
  } else if (wizardBlock === ReservationBlocks.RES_NOTES_BLOCK) {
    return createStepViewModelForNotesWizard(otherData);
  } else {
    return new FoxStepViewModel();
  }
};

export const getReservationsPartnerCode = (reservationResponse) => {
  return reservationResponse && reservationResponse.partner
    ? reservationResponse.partner.partnerCode
    : "";
};
export const isEditableReservation = (reservationResponse) => {
  return reservationResponse && reservationResponse.allowAdminEdit;
};
export const isCancellableReservation = (reservationResponse) => {
  return reservationResponse && reservationResponse.allowAdminCancel;
};

export const isFoxParterReservation = (reservation) => {
  return FOX_PARTNER_CODES.includes(reservation?.partner?.partnerCode);
};

export const getEditReservationDefaultMessage = (
  reservationData,
  reservationMode,
  isPayLater
) => {
  return reservationMode === "CANCELLED"
    ? COMMON_MSG.EditTooltipForCancelledReservation
    : reservationMode === "EDIT" && !isEditableReservation(reservationData)
    ? COMMON_MSG.EditTooltipForNonFOXReservation
    : reservationMode === "EDIT" && !isPayLater
    ? COMMON_MSG.EditTooltipForPaynowReservation
    : "Edit " + (reservationData.isRental ? "Rental" : "Reservation");
};
export const getTooltipMessageForReservationAction = (
  reservationData,
  hasPermission,
  actionType
) => {
  const permissionMsg = `You do not have permission to ${
    actionType === "cancel"
      ? "cancel"
      : actionType === "new"
      ? "create new reservation"
      : reservationData.isRental
      ? "edit rental"
      : "edit reservation"
  }`;
  const partnerDisplayName = reservationData?.partner?.displayName || "Non-FOX";
  if (!hasPermission) {
    return permissionMsg;
  }

  if (actionType === "edit") {
    if (reservationData.reservationState === "CANCELLED") {
      return COMMON_MSG.EditTooltipForCancelledReservation;
    }

    if (!isEditableReservation(reservationData)) {
      if (
        isFoxParterReservation(reservationData) &&
        !reservationData.isPayLater
      ) {
        return COMMON_MSG.EditTooltipForPaynowReservation;
      } else {
        return `${partnerDisplayName} ${COMMON_MSG.EditDisableTooltipPostfixText}`;
      }
    }

    return `Edit ${reservationData.isRental ? "Rental" : "Reservation"}`;
  }

  if (actionType === "cancel") {
    if (!isCancellableReservation(reservationData)) {
      return COMMON_MSG.CancelTooltipForPaynowReservation;
    }

    return `Cancel ${reservationData.isRental ? "Rental" : "Reservation"}`;
  }

  return "";
};

export const getCompatibleDdlOrg = (data) => {
  doSorting(
    { DataType: "string", SortType: "ASC", SortBy: "locationCode" },
    data
  );
  const orgddlInput = [];
  data.map((obj, i) => {
    if (i === 0) {
      orgddlInput.push({
        key: "",
        keyValue: "",
        otherId: "",
        displayValue: "Select",
      });
    }
    // const splitArray =obj.Value.split("/") || [];
    // const key=splitArray.length>1 ? splitArray[1] :"";
    if (obj.locationCode) {
      orgddlInput.push({
        key: obj.locationCode + i,
        keyValue: obj.locationCode,
        displayValue: obj.locationCode + " - " + obj.name,
        otherId: obj.timezoneString,
        locationId: obj.locationId,
      });
    }
    return orgddlInput;
  });
  return orgddlInput;
};

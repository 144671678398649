import React from "react";
import { NavLink, withRouter } from "react-router-dom";

class NavSidebarItem extends React.Component {
  render() {
    const { staticContext, ...props } = this.props;
    var isActive =
      this.props.match &&
      this.props.match.params.title === this.props.menuitemkey;
    var className = isActive ? "active" : "";
    return (
      <li className={className}>
        <NavLink {...props}>{this.props.children}</NavLink>
      </li>
    );
  }
}
export default withRouter(NavSidebarItem);

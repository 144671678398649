import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getMemberAddressesesReset,
  getMemberAddresseses,
  editMemberAddresses,
  editMemberAddressesReset,
  deleteMemberAddresses,
  deleteMemberAddressesReset,
} from "../../../store/actions/index";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Draggable from "react-draggable";
import { showNotification } from "../../../components/UI/Toaster/Toaster";
import AddressComponent from "../../../components/common/address-component/address-component";
import ConfirmModal from "../../../components/common/modal/confirm-modal";
import  {
  getBasicToastResponse,
  getErrorToastData,
} from "../../../shared/utility";
import { validateAddEditAddress } from "../MemberHelper";
import { commonAddressChangeHandler } from "../../../components/common/common-helper";

export const AllAddresModal = (props) => {
  //const loading = useSelector((state) => state.addressReducer.loading);
  const memberAddressResponse = useSelector(
    (state) => state.addressReducer.memberAddressResponse
  );

  const editAddressResponse = useSelector(
    (state) => state.addressReducer.editAddressResponse
  );
  const deleteAddressResponse = useSelector(
    (state) => state.addressReducer.deleteAddressResponse
  );
  const dispatch = useDispatch();
  const memberId = props.memberId;
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [deletableAddress, setDeletableAddress] = useState(null);
  const [editableAddress, setEditableAddress] = useState(null);
  const [isValidateDone, setIsValidateDone] = useState(false);
  useEffect(() => {
    initMemberAddressesModal();
  }, []);
  useEffect(() => {
    if (!memberAddressResponse) {
      return;
    }
    if (memberAddressResponse && memberAddressResponse.StatusCode === 0) {
      //setMemberAddressData(memberAddressResponse);
    } else {
      showNotification(getBasicToastResponse(memberAddressResponse));
      dispatch(getMemberAddressesesReset());
    }
  }, [memberAddressResponse]);
  useEffect(() => {
    if (!editAddressResponse) {
      return;
    }
    showNotification(getBasicToastResponse(editAddressResponse));
    if (editAddressResponse.StatusCode === 0) {
      dispatch(editMemberAddressesReset());
      props.closeModal();
      props.resetParentPage();
    } else {
      dispatch(editMemberAddressesReset());
    }
  }, [editAddressResponse]);
  useEffect(() => {
    if (!deleteAddressResponse) {
      return;
    }
    showNotification(getBasicToastResponse(deleteAddressResponse));
    if (deleteAddressResponse.StatusCode === 0) {
      dispatch(deleteMemberAddressesReset());
      props.closeModal();
      props.resetParentPage();
    } else {
      dispatch(deleteMemberAddressesReset());
    }
  }, [deleteAddressResponse]);
  const initMemberAddressesModal = () => {
    dispatch(getMemberAddressesesReset());
    dispatch(editMemberAddressesReset());
    dispatch(deleteMemberAddressesReset());
    dispatch(getMemberAddresseses({ MemberId: memberId }));
  };
  const closeConfirmModal = () => {
    setShowConfirmModal(false);
    setDeletableAddress(null);
  };
  const getAddressesbyAddressType = () => {
    const homeAddressList = [];
    const workAddressList = [];
    const otherAddressList = [];
    if (memberAddressResponse && memberAddressResponse.Addresses) {
      memberAddressResponse.Addresses.map((addressObj) => {
        if (addressObj.AddressType === "HOME") {
          homeAddressList.push(addressObj);
        } else if (addressObj.AddressType === "WORK") {
          workAddressList.push(addressObj);
        } else {
          otherAddressList.push(addressObj);
        }
      });
    }
    return {
      homeAddressList: [...homeAddressList],
      workAddressList: [...workAddressList],
      otherAddressList: [...otherAddressList],
    };
  };
  const getAddressRow = (addressData) => {
    let renderableHtml;
    if (addressData.length === 1) {
      renderableHtml = addressData.map((addressObj) => {
        return getAddressRowHtml(addressObj);
      });
      return renderableHtml;
    } else {
      renderableHtml = addressData.map((addressObj, index) => {
        return getAddressTableRowHtml(addressObj, index);
      });
      return (
        <table className="table table-striped">
          <tbody>{renderableHtml}</tbody>
        </table>
      );
    }
  };
  const getAddressTableRowHtml = (addressData, index) => {
    const renderableHtml = (
      <Fragment key={addressData.AddressId}>
        <tr>
          <td
            className="font-weight-bold"
            style={{
              borderLeft: "none",
              textAlign: "left",
              verticalAlign: "top",
              width: "1.8rem",
            }}
          >
            {index + 1}.
          </td>
          <td style={{ textAlign: "left" }}>{addressData.FormattedAddress}</td>
          <td
            style={{ borderRight: "none", width: "5rem", verticalAlign: "top" }}
            className="text-right"
          >
            <i
              className="fas fa-pencil-alt add-driver-edit pl-1 pr-1"
              aria-hidden="true"
              onClick={() => onEditClick(addressData)}
            ></i>
            <i
              className="resDriverDel fa fa-times add-driver-remove "
              aria-hidden="true"
              onClick={() => onDeleteClick(addressData)}
            ></i>
          </td>
        </tr>
        {editableAddress &&
        editableAddress.AddressId === addressData.AddressId ? (
          <tr style={{ background: "#fff" }}>
            <td colSpan="3" style={{ border: "none" }} className="text-left">
              {getAddressComponetHtml(editableAddress)}
            </td>
          </tr>
        ) : (
          ""
        )}
      </Fragment>
    );
    return renderableHtml;
  };
  const getAddressRowHtml = (addressData, isDeleteNotRequired) => {
    const renderableHtml = (
      <Fragment>
        <div className="form-group row" key={addressData.AddressId}>
          <label
            htmlFor="newres-CityId"
            className="col-sm-11  col-form-label pl-2 pr-0"
          >
            {addressData.FormattedAddress}
          </label>
          <label
            htmlFor="newres-CityId"
            className="col-sm-1 col-form-label pl-0 pr-2 text-right"
            style={{ width: "5rem" }}
          >
            <i
              className="fas fa-pencil-alt add-driver-edit pl-1 pr-1"
              aria-hidden="true"
              onClick={() => onEditClick(addressData)}
            ></i>
            {!isDeleteNotRequired ? (
              <i
                className="resDriverDel fa fa-times add-driver-remove "
                aria-hidden="true"
                onClick={() => onDeleteClick(addressData)}
              ></i>
            ) : (
              ""
            )}
          </label>
        </div>
        {editableAddress && editableAddress.AddressId === addressData.AddressId
          ? getAddressComponetHtml(editableAddress)
          : ""}
      </Fragment>
    );
    return renderableHtml;
  };
  const getAddressComponetHtml = (editableAddress) => {
    return (
      <Fragment>
        <h4 className="text-danger">{"Edit Address"}</h4>
        <AddressComponent
          type="CREDIT-CARD"
          key="creditCard-address-component"
          onChangeMethod={inputAddressChangeHandler}
          resetLoader={props.resetLoader}
          address={{
            ...editableAddress,
          }}
          isAstericsRequired={true}
          adddressTypeRequired={true}
          isValidateDone={isValidateDone}
        />
        <ModalFooter>
          <button
            type="button"
            name="btnChangeAddress"
            className="btn btn-primary waves-effect waves-light btnSize ml-2"
            onClick={() => changeAddress()}
          >
            Change
          </button>
          <button
            type="button"
            name="btnCancel"
            className="btn btn-secondary  waves-effect waves-light btnSize ml-2"
            onClick={() => cancelChangeAddress()}
          >
            Cancel
          </button>
        </ModalFooter>
      </Fragment>
    );
  };
  const inputAddressChangeHandler = (event, controlName, objectType, commonData) => {
    let addresObj = { ...editableAddress };
        addresObj["isValidZipCode"] = true;
        addresObj = commonAddressChangeHandler(event, controlName, commonData, addresObj)
    setEditableAddress(addresObj);
  };
  const cancelChangeAddress = () => {
    setEditableAddress(null);
  };
  const onEditClick = (addressObj) => {
    setEditableAddress({ ...addressObj });
  };
  const changeAddress = () => {
    const editAddressData = { ...editableAddress };
    const editAddressReq = {};
    editAddressReq.MemberId = memberId;
    editAddressReq.AddressId = editAddressData.AddressId;
    editAddressReq.AddressType = editAddressData.AddressType;
    editAddressReq.StreetAddress1 = editAddressData.Street1;
    editAddressReq.StreetAddress2 = editAddressData.Street2 || null;
    editAddressReq.Country = editAddressData.Country || null;
    editAddressReq.State = editAddressData.State || null;
    editAddressReq.StateStr = editAddressData.StateText || null;
    editAddressReq.City = editAddressData.City || null;
    editAddressReq.CityStr = editAddressData.CityText || null;
    editAddressReq.ZipCode = editAddressData.ZipCode || null;
    editAddressReq.ZipCodeStr = editAddressData.ZipCodeText || null;
    let msg = validateAddEditAddress(editAddressReq);
    if (msg) {
      showNotification(getErrorToastData(msg));
      setIsValidateDone(true);
    } else {
      setIsValidateDone(false);
      dispatch(editMemberAddressesReset());
      dispatch(editMemberAddresses(editAddressReq));
    }
  };
  const onDeleteClick = (addressObj) => {
    setShowConfirmModal(true);
    setDeletableAddress({ ...addressObj });
  };
  const deleteAddress = () => {
    const deleteAddressReq = {};
    deleteAddressReq.MemberId = memberId;
    deleteAddressReq.AddressId = deletableAddress.AddressId;
    dispatch(deleteMemberAddressesReset());
    dispatch(deleteMemberAddresses(deleteAddressReq));
    closeConfirmModal();
  };

  const randomAddressObj = getAddressesbyAddressType();
  return (
    <Fragment>
      <Draggable handle=".cursor">
        <Modal
          isOpen={props.showModal}
          className="no-cursor"
          toggle={() => {
            props.closeModal();
          }}
        >
          <ModalHeader
            className="modal-header bg-primary modal-title text-white cursor"
            toggle={() => {
              props.closeModal();
            }}
          >
            <i className="fas fa-edit" />
            Addresses
          </ModalHeader>
          <ModalBody
            className="tableScroll"
            style={{
              maxHeight: "500px",
              minHeight: "400px",
            }}
          >
            <div className="row">
              <div className="col-lg-12 mb-3">
                <h4 className="form-header text-uppercase">
                  <i className="fas fa-user-circle"></i>Primary Address
                </h4>

                {memberAddressResponse && memberAddressResponse.PrimaryAddress
                  ? getAddressRowHtml(memberAddressResponse.PrimaryAddress)
                  : ""}
                {memberAddressResponse &&
                memberAddressResponse.CreditCardAddress ? (
                  <Fragment>
                    <h4 className="form-header text-uppercase">
                      <i className="fas fa-user-circle"></i>Credit Card Address
                    </h4>
                    {getAddressRowHtml(
                      memberAddressResponse.CreditCardAddress,
                      true
                    )}
                  </Fragment>
                ) : (
                  ""
                )}
                {randomAddressObj.homeAddressList.length > 0 ? (
                  <Fragment>
                    <h4 className="form-header text-uppercase">
                      <i className="fas fa-user-circle"></i>Home Address(s)
                    </h4>
                    {getAddressRow(randomAddressObj.homeAddressList)}
                  </Fragment>
                ) : (
                  ""
                )}
                {randomAddressObj.workAddressList.length > 0 ? (
                  <Fragment>
                    <h4 className="form-header text-uppercase">
                      <i className="fas fa-user-circle"></i>Work Address(s)
                    </h4>
                    {getAddressRow(randomAddressObj.workAddressList)}
                  </Fragment>
                ) : (
                  ""
                )}
                {randomAddressObj.otherAddressList.length > 0 ? (
                  <Fragment>
                    <h4 className="form-header text-uppercase">
                      <i className="fas fa-user-circle"></i>Other Address(s)
                    </h4>
                    {getAddressRow(randomAddressObj.otherAddressList)}
                  </Fragment>
                ) : (
                  ""
                )}
              </div>
            </div>
          </ModalBody>
          {/* <ModalFooter>
            <button
              type="button"
              name="btnCancel"
              className="btn btn-secondary  waves-effect waves-light btnSize ml-2"
              onClick={event => this.props.closeMemberAddresesesModal()}
            >
              Cancel
            </button>
          </ModalFooter> */}
        </Modal>
      </Draggable>
      {showConfirmModal ? (
        <ConfirmModal
          body="Are you sure, you want to delete the address?"
          showModal={showConfirmModal}
          closeModal={closeConfirmModal}
          doConfirmFunctionality={deleteAddress}
        />
      ) : (
        ""
      )}
    </Fragment>
  );
};
export default AllAddresModal;

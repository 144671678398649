import React, { useEffect, useState, Fragment } from "react";
import { withRouter, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useForm, useFieldArray } from "react-hook-form";
import { getShuttleSettingsUICtrl } from "./shuttle-settings-ui-ctrl";
import LinkButton from "../../../components/UI/button/link-btn";
import Spinner from "../../../components/UI/Spinner/Spinner";
import { showNotification } from "../../../components/UI/Toaster/Toaster";
import PermissionName from "../../../shared/Permissions";
import { hasPermission, getBasicToastResponse } from "../../../shared/utility";
import {
  getSettingstDataByApi,
  getSettingstDataByApiReset,
  updateSettingstDataByApi,
  updateSettingstDataByApiReset,
  getShuttleOptionforStaticData,
  getShuttleOptionforStaticDataReset,
} from "../../../store/actions/index";
import { COMMON_MSG } from "../../../shared/GlobalVar";
import { ShuttleLocationSettingsViewModel } from "../../../services/entities/shuttle-app/view-model/shuttle-location-settings-view-model";
import {
  getAPiNameForSettings,
  getLocationCodeFromLocationId,
  RenderInheritedCheckBox,
} from "../settingsHelper";
import { ADMIN_PAGE } from "../../../shared/GlobalVar";
import ShuttleLocationSettingsRequest from "../../../services/entities/shuttle-app/request/ShuttleLocationSettingsRequest";
import { ShuttleLocationSettingsModel } from "../../../services/entities/shuttle-app/domain/shuttle-location-settings-model";
import { convertMsgResponse } from "../../Reservation/fox-reservation/fox-res-helper";
import { ShuttleOptionsStaticModel } from "../../../services/entities/shuttle-app/domain/shuttle-options-static-model";
import { getLocalStorageData } from "../../../shared/LocalStorage";
import ConfirmModal from "../../../components/common/modal/confirm-modal";
import InheritFromParentConfirmModal from "../modal/inherit-from-parent-confirm-modal";

export const ShuttleLocationSettings = (props) => {
  const loading = useSelector(
    (state) =>
      state.settingsReducer.loading || state.shuttleOptionReducer.loading
  );
  const currentOrgUnitId = useSelector(
    (state) => state.auth.loggedInUserData.CurrentOrgUnitId
  );
  const dependantOrgList = useSelector((state) =>
    state.auth.loggedInUserData.DependantLocations.length > 0
      ? state.auth.loggedInUserData.DependantLocations
      : []
  );
  const unitSystems = useSelector(
    (state) => state.shuttleOptionReducer.unitSystems || null
  );
  const settingsResponse = useSelector((state) =>
    !props.isInheritModal
      ? state.settingsReducer.settingsResponse
      : state.settingsReducer.inheritedSettingsResponse
  );
  const updateSettingsResponse = useSelector(
    (state) => state.settingsReducer.updateSettingsResponse
  );
  const parentOrgKey = getLocalStorageData("currentOrgUnitKey") || "THUMBIT";
  const [selectedOrgLocationCode, setSelectedOrgLocationCode] = useState("");
  const [isEditMode, setIsEditMode] = useState(false);
  const [shuttleLocationSettingsData, setShuttleLocationSettingsData] =
    useState(new ShuttleLocationSettingsViewModel());
  const [shuttleLocationSettingsServerData, setShuttleLocationSettingsServerData] =
    useState(new ShuttleLocationSettingsViewModel());
  const [showConfirmRemoveInheritModal, setShowConfirmRemoveInheritModal] =
    useState(false);
  const [
    showInheritFromParentConfirmModal,
    setShowInheritFromParentConfirmModal,
  ] = useState(false);
  const dispatch = useDispatch();

  const query = props.location
    ? new URLSearchParams(props.location.search)
    : null;

  const selectedOrgLocationId =
    props.otherData && props.otherData.selectedOrgLocationId
      ? props.otherData.selectedOrgLocationId
      : query
      ? query.get("orgId")
      : currentOrgUnitId;

  const hasEditPermission = hasPermission(
    PermissionName.CC_EDIT_SHUTTLE_SETTINGS
  );
  const disabled =
    shuttleLocationSettingsData.inheritedFromParent ||
    !hasEditPermission ||
    props.isInheritModal
      ? true
      : false;
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    watch,
    reset,
  } = useForm({
    defaultValues: {
      formData: getShuttleSettingsUICtrl({}, unitSystems),
    },
  });
  const { fields } = useFieldArray({ control, name: "formData" });
  useEffect(() => {
    const subscription = watch((data, { name, type }) => {
      if (name === "unitSystem" && type === "change") {
        let shuttleLocationData = data.formData[2] ? JSON.parse(JSON.stringify(data.formData[2])) : JSON.parse(JSON.stringify(shuttleLocationSettingsData))
        const shuttleLocationSettingsData_d = shuttleLocationData;
        shuttleLocationSettingsData_d.unitSystem = data.unitSystem;
        setShuttleLocationSettingsData(shuttleLocationSettingsData_d);
        renderFormControl(shuttleLocationSettingsData_d, shuttleLocationData);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);
  useEffect(() => {
    if (!unitSystems) {
      dispatch(getShuttleOptionforStaticDataReset());
      dispatch(getShuttleOptionforStaticData(new ShuttleOptionsStaticModel()));
    }
  }, []);

  useEffect(() => {
    let locationCode = getLocationCodeFromLocationId(
      dependantOrgList,
      selectedOrgLocationId,
      parentOrgKey
    );
    if (locationCode) {
      setSelectedOrgLocationCode(locationCode);
      getData(locationCode);
    }
  }, [selectedOrgLocationId]);

  useEffect(() => {
    if (!unitSystems) {
      return;
    }
    if (unitSystems) {
      renderFormControl(new ShuttleLocationSettingsViewModel(), null);
    }
  }, [unitSystems]);
  useEffect(() => {
    if (!settingsResponse) {
      return;
    }
    if (
      settingsResponse.message &&
      settingsResponse.message.messageType === "SUCCESS" &&
      settingsResponse.shuttleLocationSettings
    ) {
      if (showInheritFromParentConfirmModal) {
        return;
      }
      let shuttelLocationData = new ShuttleLocationSettingsViewModel(
        settingsResponse.shuttleLocationSettings,
        settingsResponse.locationCode,
        parseInt(selectedOrgLocationId, 10),
        parseInt(currentOrgUnitId, 10)
      );
      setShuttleLocationSettingsData(
        shuttelLocationData
      );
      setShuttleLocationSettingsServerData(
        shuttelLocationData
      );
      renderFormControl(
        new ShuttleLocationSettingsViewModel(
          settingsResponse.shuttleLocationSettings,
          settingsResponse.locationCode
        ),
        shuttelLocationData
      );
      setIsEditMode(false);
    } else {
      showNotification(convertMsgResponse(settingsResponse.message));
    }
    dispatch(getSettingstDataByApiReset(props.isInheritModal));
  }, [settingsResponse]);

  useEffect(() => {
    if (!updateSettingsResponse) {
      return;
    }
    if (
      updateSettingsResponse.message &&
      updateSettingsResponse.message.messageType === "SUCCESS" &&
      updateSettingsResponse.shuttleLocationSettings
    ) {
      showNotification(convertMsgResponse(updateSettingsResponse.message));
      let shuttelLocationData = new ShuttleLocationSettingsViewModel(
        updateSettingsResponse.shuttleLocationSettings,
        updateSettingsResponse.locationCode,
        parseInt(selectedOrgLocationId, 10),
        parseInt(currentOrgUnitId, 10)
      );
      setShuttleLocationSettingsData(
        shuttelLocationData
      );
      setShuttleLocationSettingsServerData(
        shuttelLocationData
      );
      renderFormControl(
        new ShuttleLocationSettingsViewModel(
          updateSettingsResponse.shuttleLocationSettings,
          updateSettingsResponse.locationCode
        ),
        shuttelLocationData
      );
      setIsEditMode(false);
      closeConfirmModal();
    } else {
      showNotification(convertMsgResponse(updateSettingsResponse.message));
    }
    dispatch(updateSettingstDataByApiReset());
  }, [updateSettingsResponse]);
  const renderFormControl = (formData, updatedShuttleData) => {
    const data = getShuttleSettingsUICtrl(formData, unitSystems);
    if(updatedShuttleData) data.push(updatedShuttleData);
    reset({
      formData: data,
    });
  };
  const getData = (locCode) => {
    const req = new ShuttleLocationSettingsRequest({
      locationCode: locCode,
    });
    dispatch(getSettingstDataByApiReset(props.isInheritModal));
    dispatch(
      getSettingstDataByApi(
        req,
        getAPiNameForSettings(ADMIN_PAGE.SHUTTLES_SETTINGS),
        props.isInheritModal,
        true
      )
    );
  };
  const updateData = (req) => {
    dispatch(updateSettingstDataByApiReset());
    dispatch(
      updateSettingstDataByApi(
        req,
        getAPiNameForSettings(ADMIN_PAGE.SHUTTLES_SETTINGS),
        true
      )
    );
  };
  const renderInputField = (fieldObj, value) => {
    const input = fieldObj.editConfig || fieldObj.editConfig;
    switch (input.type) {
      case "textarea":
        return (
          <textarea
            key={"textarea" + value}
            {...register(input.name, { required: input.required })}
            aria-invalid={errors[input.name] ? "true" : "false"}
            rows={input.rows || "20"}
            defaultValue={fieldObj.value}
            placeholder={input.placeholder}
          />
        );
      case "select":
        return (
          <select
            key={"select" + value}
            {...register(input.name, { required: input.required })}
            aria-invalid={errors[input.name] ? "true" : "false"}
            defaultValue={fieldObj.value}
            id={input.name + "select"}
          >
            {input.options.map((option, i) => (
              <option
                value={option.key}
                otherid={option.otherId}
                locationid={option.locationId}
                key={input.name + i}
              >
                {option.displayValue}
              </option>
            ))}
          </select>
        );
      case "radiolist":
        return input.options.map((radio, i) => {
          return (
            <div className={`icheck-primary`} key={input.name + i}>
              <input
                type="radio"
                {...register(input.name, { required: input.required })}
                value={radio.key}
                id={radio.id}
                defaultChecked={
                  [fieldObj.value].includes(radio.key) ? true : false
                }
              />
              <label htmlFor={radio.id}>
                <span>{radio.value}</span>
              </label>
            </div>
          );
        });
      case "checkboxlist":
        return input.options.map((cbox, i) => {
          return (
            <div className={`icheck-primary`} key={input.name + i}>
              <input
                type="checkbox"
                {...register(input.name, { required: input.required })}
                value={cbox.key}
                id={cbox.id}
                // defaultChecked={input.value || cbox.id}
              />
              <label htmlFor={cbox.id}>
                <span>{cbox.value}</span>
              </label>
            </div>
          );
        });
      default:
        return (
          <input
            key={input.type + value}
            {...register(input.name, { required: input.required })}
            aria-invalid={errors[input.name] ? "true" : "false"}
            type={input.type}
            defaultValue={fieldObj.value}
            step="any"
            placeholder={input.placeholder}
          />
        );
    }
  };
  const renderShuttleForm = (type) => {
    const shuttleBlock = fields.filter((info) => info.type === type);
    if (
      shuttleBlock[0] &&
      shuttleBlock[0].fields &&
      shuttleBlock[0].fields.length > 0
    ) {
      return shuttleBlock[0].fields.map((field, i) => {
        return (
          <div
            className={`form-group row ${
              field.editConfig.required && isEditMode ? "required" : ""
            }`}
            key={field.editConfig.name + i}
            style={field.style ? field.style : {}}
          >
            <label
              htmlFor={field.editConfig.name}
              className="col-sm-4 col-form-label font-weight-bold"
            >
              {field.titleLabel.text}
            </label>
            <div className={`col-sm-8 row`}>
              {isEditMode ? (
                renderInputField(field, i, type)
              ) : (
                <label className="">{field.detailLabel.value}</label>
              )}
            </div>
          </div>
        );
      });
    }
  };
  const getInheritConfirmModal = (event) => {
    setIsEditMode(false);
    if (event.target.checked) {
      setShowInheritFromParentConfirmModal(true);
    } else {
      setShowConfirmRemoveInheritModal(true);
    }
  };
  const onEdit = () => {
    setIsEditMode(true);
  };
  const onCancel = () => {
    setShuttleLocationSettingsData(shuttleLocationSettingsServerData);
    renderFormControl(
      shuttleLocationSettingsServerData,
      shuttleLocationSettingsServerData
    );
    setIsEditMode(false);
  };
  const getUpdateShuttleSettingsRequest = (data) => {
    let addUpdateReq = new ShuttleLocationSettingsRequest(
      {
        locationCode: selectedOrgLocationCode,
        shuttleLocationSettings: data,
      },
      "POST"
    );
    return addUpdateReq;
  };
  const onSubmit = (data) => {
    delete data["formData"];
    if (!selectedOrgLocationCode) {
      return false;
    }
    if (data.unitSystem === "IMPERIAL") {
      data.locationDistanceUnit = "MI";
      data.locationSpeedUnit = "MPH";
    } else {
      data.locationDistanceUnit = "KM";
      data.locationSpeedUnit = "KMPH";
    }
    delete data["unitSystem"];
    const addUpdateReq = getUpdateShuttleSettingsRequest(data);
    updateData(addUpdateReq);
  };

  const saveInheritedData = (isInherited, shuttleLocationSettingsData) => {
    if (!selectedOrgLocationCode) {
      return false;
    }
    let req = { ...shuttleLocationSettingsData };
    req.inheritFromParent = isInherited;
    let addUpdateReq = {};
    if (isInherited) {
      addUpdateReq = getUpdateShuttleSettingsRequest(null);
      addUpdateReq.shuttleLocationSettings = req;
    } else {
      addUpdateReq = getUpdateShuttleSettingsRequest(req);
    }
    updateData(addUpdateReq);
  };

  const closeConfirmModal = () => {
    if (showConfirmRemoveInheritModal) {
      setShowConfirmRemoveInheritModal(false);
    } else if (showInheritFromParentConfirmModal) {
      setShowInheritFromParentConfirmModal(false);
    }
  };
  const doInheritFunctionality = () => {
    let isInherited = showInheritFromParentConfirmModal;
    saveInheritedData(
      isInherited,
      isInherited ? {} : shuttleLocationSettingsData
    );
  };
  
  const renderMainLayout = () => {
    return (
      <div
        id="shuttle-page"
        className={`row ${isEditMode ? " background-edit" : ""}`}
      >
        <div className="col-lg-6 p-0" style={{ borderRight: "1px solid gray" }}>
          {renderShuttleForm("SHUTTLE_LEFTBAR")}
        </div>
        <div className="col-lg-6">
          {renderShuttleForm("SHUTTLE_RIGHTBAR")}
          <div className={`form-group row ${isEditMode ? "required" : ""}`}>
            <label
              htmlFor="unitSystem"
              className="col-sm-4 col-form-label font-weight-bold"
            >
              Distance Unit
            </label>
            <div className="col-sm-8 row">
              <label className="">
                {shuttleLocationSettingsData.unitSystem === "IMPERIAL"
                  ? "Mile"
                  : "Kilometer"}
              </label>
            </div>
          </div>
          <div className={`form-group row ${isEditMode ? "required" : ""}`}>
            <label
              htmlFor="unitSystem"
              className="col-sm-4 col-form-label font-weight-bold"
            >
              Speed Unit
            </label>
            <div className="col-sm-8 row">
              <label className="">
                {shuttleLocationSettingsData.unitSystem === "IMPERIAL"
                  ? "Miles per hour"
                  : "Kilometers per hour"}
              </label>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return props.isInheritModal ? (
    renderMainLayout()
  ) : (
    <Fragment>
      <div className="row col-lg-12 p-0 m-o">
        <div className="card" style={{ width: "100%" }}>
          <div className="card-body" style={{ height: "50rem" }}>
            <div className="form-header row col-lg-12 p-0">
              <h4 className="text-uppercase mr-auto icon-display-none">
                <i className="fas fa-building" aria-hidden="true"></i>
                Shuttle Settings
              </h4>
              {hasEditPermission &&
              !disabled &&
              !isEditMode &&
              !shuttleLocationSettingsData.inheritedFromParent ? (
                <LinkButton
                  btnLabel="Edit Settings"
                  containerClass="pseudo-link pr-1 fs-2 font-weight-bold mr-5 d-flex align-items-center"
                  stackClass="fa-stack fs-08 vertical-align-top"
                  faIconList={[
                    "fa fa-circle fa-stack-2x iconBeforeLink",
                    "fa fa-pencil-alt fa-stack-1x whiteColor pr-1",
                  ]}
                  clicked={onEdit}
                />
              ) : (
                ""
              )}
              <RenderInheritedCheckBox
                checked={shuttleLocationSettingsData.inheritedFromParent}
                onChange={(event) => getInheritConfirmModal(event)}
                disabled={!hasEditPermission}
                displayEnable={shuttleLocationSettingsData.displayInherited}
              />
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              {renderMainLayout()}
              {isEditMode ? (
                <div
                  className="settingFooter"
                  style={{ textAlign: "center", margin: "2rem" }}
                >
                  <button type="submit" className="btn btn-primary">
                    Update
                  </button>
                  <button
                    type="button"
                    name="className"
                    className="btn btn-secondary ml-2"
                    onClick={() => onCancel()}
                  >
                    Cancel
                  </button>
                </div>
              ) : (
                ""
              )}
            </form>
          </div>
        </div>
      </div>
      {loading ? <Spinner /> : ""}
      {showConfirmRemoveInheritModal ? (
        <ConfirmModal
          body={COMMON_MSG.RemoveInheritParentConfirm}
          showModal={showConfirmRemoveInheritModal}
          closeModal={closeConfirmModal}
          doConfirmFunctionality={doInheritFunctionality}
        />
      ) : (
        ""
      )}
      {showInheritFromParentConfirmModal ? (
        <InheritFromParentConfirmModal
          showModal={showInheritFromParentConfirmModal}
          closeModal={closeConfirmModal}
          doInheritFunctionality={doInheritFunctionality}
          currentOrgUnitId={currentOrgUnitId}
          modalWidth="120rem"
          componentName={ShuttleLocationSettings}
        />
      ) : (
        ""
      )}
    </Fragment>
  );
};
export default withRouter(ShuttleLocationSettings);

import React from 'react'
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { ADMIN_PAGE } from '../../shared/GlobalVar';

export const ExportToExcel = (props) => {
  const {apiData, fileName, exportType, closeModal} = props;
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = exportType ==="xls"?".xlsx":".csv";
  const exportToExcelOrCSV = (apiData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(apiData[0].data);
    //// Cell merge feature
    if (props.pageName === ADMIN_PAGE.RESERVE_SYNC_PAGE) {
      const dataToExport = JSON.parse(JSON.stringify(apiData[0].data))
      const mergeInfoList = [];
      let mergeInfo = {}
      mergeInfo["s"] = {}; // 's' >> 'start'
      mergeInfo["e"] = {}; // 'e' >> 'end'
      let mergedRowValue = "";
      let count = 1;
      dataToExport.forEach((element, index) => {
        let clonedMergeInfo = JSON.parse(JSON.stringify(mergeInfo));
        if (mergedRowValue !== element[0]) {
          mergedRowValue = element[0];
          if (index !== 0) {
            clonedMergeInfo.s["r"] = parseInt(index - count + 1); // 'r' >> 'row'
            clonedMergeInfo.s["c"] = 0;  // 'c' >> 'column'
            clonedMergeInfo.e["r"] = parseInt(index);
            clonedMergeInfo.e["c"] = 0;
            mergeInfoList.push(clonedMergeInfo);
            count = 1;
          }
        } else {
          if (index === dataToExport.length - 1) {
            clonedMergeInfo.s["r"] = parseInt(index - count + 1);
            clonedMergeInfo.s["c"] = 0;
            clonedMergeInfo.e["r"] = parseInt(index + 1);
            clonedMergeInfo.e["c"] = 0;
            mergeInfoList.push(clonedMergeInfo);
          }
          count = count + 1;
        }
      });
      ws["!merges"] = mergeInfoList;
    }
    //// end Cell merge feature

    /* custom headers */
    XLSX.utils.sheet_add_aoa(ws, [apiData[0].columns], { origin: "A1" });
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
    closeModal()
  };
  return (
    <button className='btn btn-primary waves-effect waves-light btnSize m-1 text-right' onClick={(e) => exportToExcelOrCSV(apiData, fileName)}>Download</button>
  );
};
import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import TooltipItem from "../../components/tooltip/TooltipItem";
import PermissionName from "../../shared/Permissions";
import { ModalType } from "../../shared/GlobalVar";
import ModalEngine from "../../components/modal/modal-engine";
import ModalInfo from "../../services/entities/models/ModalInfo";
import {
  hasPermission,
  formatAmountValue,
  loadDefaultImage,
} from "../../shared/utility";
import { noImage } from "../../shared/AppImages";
import FavoriteBottomContainer from "./favorites-bottom-container";
import { createTooltip } from "../../shared/utility/tooltip-utility";
export const SearchFavouriteGrid = (props) => {
  const [modalInfo, setModalInfo] = useState([new ModalInfo()]);
  const closeModal = () => {
    setModalInfo(new ModalInfo());
  };
  const showModal = (modalData) => {
    setModalInfo({
      type: modalData.type,
      showModal: true,
      memberId: modalData.memberId,
      vehicleId: modalData.vehicleId,
    });
  };
  const showHourlyRate = (assetItem) => {
    let hourlyRate = assetItem.HourlyRate;
    let hourlyRateSP = assetItem.SharerHourlyRate;
    let halfDayRate = assetItem.HalfDayRate;
    let halfDayRateSP = assetItem.SharerHalfDayRate;
    let dailyRate = assetItem.DailyRate;
    let dailyRateSP = assetItem.SharerDailyRate;
    let isHourly = hourlyRate > 0 ? true : false;

    if (isHourly) {
      return hourlyRateSP
        ? formatAmountValue(hourlyRateSP) + " /hr"
        : "$0.00/hr";
    } else {
      return halfDayRateSP
        ? formatAmountValue(halfDayRateSP) + " /hr"
        : "$0.00/hr";
    }
  };
  return (
    <Fragment>
      <div className="datagrid">
        <div className="column">
          <span className="column__header"></span>
          <span className="column__header">Image</span>
          <span className="column__header">Vehicle Type</span>
          <span className="column__header">Vehicle Name</span>
          <span className="column__header">Approval Mode</span>
          <span className="column__header">Fuel</span>
          <span className="column__header">Transmission</span>
          <span className="column__header">Rental Price(hr)</span>
          <span className="column__header">Rental Price(day)</span>
        </div>
        {props.dataList.map((post, index) => {
          return (
            <div className="row" key={index}>
              <span className="row__column text-center icheck-primary">
                <input
                  type="radio"
                  name={"rbasset"}
                  id={"rbasset" + index}
                  value="false"
                />
                <label htmlFor={"rbasset" + index}></label>
              </span>
              <span className="row__column text-center">
                <img
                  style={{ width: "3rem", height: "2rem" }}
                  onError={(event) => loadDefaultImage(event, noImage)}
                  src={post.Image || noImage}
                  alt="asset-img"
                  width="50"
                  height="30"
                />
              </span>
              <span className="row__column text-center">
                {post.AssetGroupType}
              </span>
              <span className="row__column">
                <label
                  className={`md-trigger ${
                    hasPermission(PermissionName.VIEW_VEHICLE)
                      ? "pseudo-link"
                      : ""
                  }`}
                  id={"mem_" + post.MemberAssetId}
                  onClick={() =>
                    hasPermission(PermissionName.VIEW_MEMBER)
                      ? showModal({
                          type: ModalType.VEHICLE_QUICK_MODAL,
                          showModal: true,
                          vehicleId: post.MemberAssetId,
                        })
                      : ""
                  }
                >
                  {createTooltip(post.Name, post.Name)}
                </label>
              </span>
              <span className="row__column text-center">
                {post.ApprovalMode}
              </span>
              <span className="row__column text-center">{post.FuelType}</span>
              <span className="row__column" id={"transmissionType_" + index}>
                {createTooltip(post.TransmissionType, post.TransmissionType)}
              </span>

              <span className="row__column text-right">
                {showHourlyRate(post)}
              </span>
              <span className="row__column text-right">
                {post.SharerDailyRate
                  ? formatAmountValue(post.SharerDailyRate) + " /day"
                  : "$0.00/day"}
              </span>
            </div>
          );
        })}
      </div>
      <FavoriteBottomContainer disable="true" />
      {modalInfo.showModal ? (
        <ModalEngine
          modalType={modalInfo.type}
          showModal={modalInfo.showModal}
          closeModal={closeModal}
          memberId={modalInfo.memberId}
        />
      ) : null}
    </Fragment>
  );
};
export default SearchFavouriteGrid;

import React, { Component, Fragment } from "react";
import { noImage, docImg } from "../../shared/AppImages";
import { showNotification } from "../UI/Toaster/Toaster";
import * as global from "../../shared/GlobalVar";
import HttpService from "../../services/httpService";
import { getMemberLabelByOrgRentalType } from "../../shared/utility/system-utility";
class FileUploader extends Component {
  state = {
    currentIndex: null,
    validImageTypes: ["image/gif", "image/jpeg", "image/jpg", "image/png"],
    imageList: [],
  };
  componentDidUpdate(prevProps) {
    if (this.props.httpResponse && !prevProps.httpResponse) {
      if (this.props.httpResponse.StatusCode === 0) {
        this.props.uploaderData.map((imageObj, index) => {
          this.loadActualImage(imageObj.imgUrl || null, index);
        });
      }
    }
  }
  initImageUploader = (event, index) => {
    this.setState(
      {
        currentIndex: index,
      },
      () => {
        document
          .getElementById("photoUploader" + this.props.uploaderType)
          .click();
      }
    );
  };
  loadNewImage = (event) => {
    const fileObj = event.target.files[0];
    if (this.validateFile(fileObj)) {
      const currentAll = this;
      const currentState = this.state;
      const currentProps = this.props;
      const reader = new FileReader();
      reader.onload = function (e) {
        if (currentState.validImageTypes.includes(fileObj.type)) {
          document.getElementById(
            "mainImageId-" +
              currentProps.uploaderType +
              currentState.currentIndex
          ).src = e.target.result;
        } else {
          document.getElementById(
            "mainImageId-" +
              currentProps.uploaderType +
              currentState.currentIndex
          ).src = docImg;
        }
        // if (currentProps.isNameRequird) {
        //   document.getElementById(
        //     "fileUploadDocName-" +
        //       currentProps.uploaderType +
        //       currentState.currentIndex
        //   ).innerHTML = fileObj.name;
        // }
        document.getElementById(
          "fontCloseId-" + currentProps.uploaderType + currentState.currentIndex
        ).style.display = "block";
        document
          .getElementById(
            "loggedInImgUploadDivId-" +
              currentProps.uploaderType +
              currentState.currentIndex
          )
          .classList.remove("loggedUserUploadImg");
        document
          .getElementById(
            "loggedInImgUploadDivId-" +
              currentProps.uploaderType +
              currentState.currentIndex
          )
          .classList.add("display-hidden");
        if (currentProps.isUploadBtnRequired) {
          document
            .getElementById(
              "uploaderBtnDiv-" +
                currentProps.uploaderType +
                currentState.currentIndex
            )
            .classList.remove("display-hidden");
          document
            .getElementById(
              "uploaderBtnDiv-" +
                currentProps.uploaderType +
                currentState.currentIndex
            )
            .classList.add("display-block");
        }
        if (currentProps.isUploadBtnRequired) {
          let imgData = [...currentState.imageList];
          imgData.push({
            imageData: e.target.result.split(",")[1],
            extension: fileObj.name.split(".").pop(),
          });
          currentAll.setState({ imageList: imgData });
        }
        currentProps.loadUploaderFileData(
          currentState.currentIndex,
          e.target.result.split(",")[1],
          fileObj.name.split(".").pop()
        );
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };
  confirmBtnClick = (event, index) => {
    const imgObj = this.state.imageList[index];
    const uploadFileReq = {};
    uploadFileReq.MemberId = this.props.memberId;
    uploadFileReq.ImageIndex = index;
    uploadFileReq.ImageCategory = global.ImageStorageType.OWNER_IMG;
    uploadFileReq.ImageExt = imgObj.extension;
    uploadFileReq.ImageData = imgObj.imageData;
    HttpService.uploadFile(uploadFileReq)
      .then((response) => {
        if (response.StatusCode === 0) {
          this.props.confirmBtnClick(response.ImageUrl);
          this.loadActualImage(null, index);
        }
      })
      .catch((err) => {});
  };
  validateFile = (fileObj) => {
    let msg = null;
    if (fileObj && fileObj.size > 1048576) {
      msg = "File size must be smaller then 1MB.";
    }
    if (
      this.props.isOnlyImg &&
      !this.state.validImageTypes.includes(fileObj.type)
    ) {
      msg = msg
        ? msg + " Only image file is acceptable."
        : "Only image file is acceptable.";
    }
    if (msg) {
      var toastMsg = {
        msg: msg,
        toastType: global.TOAST_MSG.ERROR,
      };
      showNotification(toastMsg);
      return false;
    }
    return true;
  };
  loadActualImage = (imgUrl, index) => {
    // if (this.props.isNameRequird) {
    //   document.getElementById(
    //     "fileUploadDocName-" + this.props.uploaderType + index
    //   ).innerHTML = "Document " + this.props.uploaderType + (index + 1);
    // }
    document.getElementById(
      "mainImageId-" + this.props.uploaderType + index
    ).src = imgUrl || this.props.photoData || noImage;
    document.getElementById(
      "fontCloseId-" + this.props.uploaderType + index
    ).style.display = "none";
    //document.getElementById("loggedUserUploadImgDiv").style.display = "none";
    document
      .getElementById(
        "loggedInImgUploadDivId-" + this.props.uploaderType + index
      )
      .classList.remove("display-hidden");
    document
      .getElementById(
        "loggedInImgUploadDivId-" + this.props.uploaderType + index
      )
      .classList.add("loggedUserUploadImg");
    if (this.props.isUploadBtnRequired) {
      document
        .getElementById(
          "loggedInImgUploadDivId-" + this.props.uploaderType + index
        )
        .classList.remove("display-block");
      document
        .getElementById("uploaderBtnDiv-" + this.props.uploaderType + index)
        .classList.add("display-hidden");
    }
    this.props.loadUploaderFileData(index, null, null);
  };
  getUploaderHtml = () => {
    return this.props.uploaderData.map((imageObj, index) => {
      return (
        <div className={imageObj.parentDivClass} key={"uploadFrame" + index}>
          <div className="user-fullimage">
            {this.props.isNameRequird ? (
              <h6
                id={"fileUploadDocName-" + this.props.uploaderType + index}
                className="fileUploadDocName"
              >
                {imageObj.fileName}
              </h6>
            ) : (
              ""
            )}
            <i
              className="fa fa-times uploadPhotoClose"
              id={"fontCloseId-" + this.props.uploaderType + index}
              onClick={() => this.loadActualImage(imageObj.imgUrl, index)}
              style={{ display: "none", ...imageObj.photoCloseStyle }}
            />
            <img
              id={"mainImageId-" + this.props.uploaderType + index}
              src={imageObj.imgUrl || this.props.photoData || noImage}
              alt={getMemberLabelByOrgRentalType()}
              className="card-img-top"
              style={imageObj.imageStyle}
              // onClick={event => this.showImage(event, memberData)}
            />
            {this.props.isUploadBtnRequired ? (
              <div
                className="details display-hidden"
                id={"uploaderBtnDiv-" + this.props.uploaderType + index}
                style={{
                  cursor: "pointer",
                  ...imageObj.uploadDivStyle,
                }}
                onClick={(event) => this.confirmBtnClick(event, index)}
              >
                <i className="fa fa-upload uploadIcon" />
                <label
                  className="uploadIcon text-white"
                  style={{ cursor: "pointer" }}
                >
                  Confirm Upload
                </label>
              </div>
            ) : (
              ""
            )}
            {!this.props.disabled ? (
              <div
                className="details loggedUserUploadImg"
                id={"loggedInImgUploadDivId-" + this.props.uploaderType + index}
                onClick={(event) => this.initImageUploader(event, index)}
                style={{ cursor: "pointer", ...imageObj.uploadDivStyle }}
              >
                <i className="fa fa-camera uploadIcon" />
                <label className="uploadIcon text-white">
                  {this.props.changeFileTitle || "Attach File"}
                </label>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      );
    });
  };
  render() {
    return (
      <Fragment>
        <input
          id={"photoUploader" + this.props.uploaderType}
          type="file"
          multiple
          style={{ display: "none" }}
          onChange={(event) => this.loadNewImage(event)}
        />
        {this.getUploaderHtml()}
      </Fragment>
    );
  }
}
export default FileUploader;

import React, { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getPageName,
  setActiveMenu,
  getSearchRequestCompatibleData,
  getBreadcrumbDataforSearchPage,
  getChildBreadCrumbList,
  getTopMainLinkUrl,
  getMemberProfileBreadcrumbList,
} from "../../search/search-utility/search-utility";
import Spinner from "../../../components/UI/Spinner/Spinner";
import Pagination from "../../../components/pagination/Pagination";
import {
  getErrorToastData,
  hasPermission,
  convertDateTimeString,
  convertDateTimeStringWithTimezone,
  getCurrentServerTimezone,
} from "../../../shared/utility";
import PageInfo from "../../../shared/PageInfo";
import NoRecordFoundComponent from "../../../components/common/pages/no-record-found";
import DefaultComponentWithMessage from "../../../components/common/pages/default-component-with-message";
import * as global from "../../../shared/GlobalVar";
import { Link } from "react-router-dom";
import Moment from "moment";
import { showNotification } from "../../../components/UI/Toaster/Toaster";
import MemberQuickModal from "../../Member/modal/member-quick-modal";
import PermissionName from "../../../shared/Permissions";
import PageTopPanel from "../../../components/common/pages/page-top-panel";
import DateTimePicker from "react-widgets/lib/DateTimePicker";
import { useState } from "react";
import {
  searchMemberMessageReset,
  searchMemberMessage,
} from "../../../store/actions";
import { useEffect } from "react";
import { createTooltip } from "../../../shared/utility/tooltip-utility";

const MemberMessage = (props) => {
  const loading = useSelector((state) => state.memberReducer.loading);
  const [isSearchPerformed, setIsSearchPerformed] = useState(false);
  const [searchData, setSearchData] = useState(null);
  const [showExportModal, setShowExportModal] = useState(false);
  const [exportData, setExportData] = useState(false);
  const [fromMemberId, setFromMemberId] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [showMemberQuickViewPopup, setShowMemberQuickViewPopup] = useState(
    false
  );

  const dispatch = useDispatch();

  const memberMessageResponse = useSelector(
    (state) => state.memberReducer.memberMessageResponse
  );

  const query = props.location
    ? new URLSearchParams(props.location.search)
    : null;
  const reservationId = query ? query.get("reservationId") : null;
  const memberId = query ? query.get("memberId") : null;
  let breadCrumbList = getBreadcrumbDataforSearchPage(
    props.match.url,
    props.location
  );

  useEffect(() => {
    getSearchList();
  }, [memberId]);

  useEffect(() => {
    if (!memberMessageResponse) {
      return;
    }

    if (memberMessageResponse.StatusCode === 0) {
      laodSearchDataFromResponse(memberMessageResponse);
    }
    dispatch(searchMemberMessageReset);
  }, [memberMessageResponse]);

  const laodSearchDataFromResponse = (response) => {
    if (showExportModal) {
      if (response.TotalRecords) {
        setExportData(response);
      } else {
        showNotification(getErrorToastData(global.COMMON_MSG.NoRecordFound));
        setShowExportModal(false);
      }
    } else {
      setSearchData(response);
      setExportData(null);
      setIsSearchPerformed(true);
    }
  };

  const getExportSearchData = (showExportModal) => {
    setShowExportModal(showExportModal);
    setExportData(null);
    getSearchList();
  };
  const resetExportData = () => {
    setShowExportModal(false);
    setExportData(null);
  };
  const submitHandler = (event) => {
    event.preventDefault();
    resetExportData();
    getSearchList();
  };

  const resetHandler = (event) => {
    setStartDate("");
    setEndDate("");
  };

  const onPageChanged = (currentPage, recordsPerPage) => {
    // if (currentPage && recordsPerPage) {
    //   setState(
    //     {
    //       currentPage: currentPage,
    //       recordsPerPage: recordsPerPage,
    //     },
    //     () => {
    //       getSearchList();
    //     }
    //   );
    // }
  };
  const getSearchList = () => {
    const requestObjInfo = getSearchRequestCompatibleData(
      null,
      getPageName(props.match.url)
    );

    requestObjInfo.MemberId = memberId;
    requestObjInfo.StartDate = startDate;
    requestObjInfo.EndDate = endDate;
    if (reservationId) {
      requestObjInfo.ReservationId = reservationId;
    }
    dispatch(searchMemberMessageReset());
    dispatch(searchMemberMessage(requestObjInfo));
  };

  const modalClose = () => {
    setShowMemberQuickViewPopup(false);
  };
  const openMemberQuickViewModal = (selectedMemberId) => {
    setShowMemberQuickViewPopup(true);
    setFromMemberId(selectedMemberId);
  };

  const renderTableData = () => {
    let tableRows = "";
    let hrefLink = "#!";
    if (searchData && searchData.Messages) {
      tableRows = searchData.Messages.map((post, index) => {
        return (
          <div className="row" key={index}>
            <span className="row__column">
              {createTooltip(
                convertDateTimeString(post.MessageDate),
                convertDateTimeStringWithTimezone(
                  post.MessageDate,
                  getCurrentServerTimezone()
                ),
                { forceShow: true }
              )}
            </span>
            <span
              className="row__column"
              data-toggle="modal"
              data-target="#primarymodal"
              id={"memberId_" + index}
            >
              <a
                href={hrefLink}
                className={`md-trigger ${
                  hasPermission(PermissionName.VIEW_MEMBER)
                    ? ""
                    : "noAnchorLink"
                }`}
                data-modal="modal-1"
                onClick={(event) =>
                  hasPermission(PermissionName.VIEW_MEMBER)
                    ? openMemberQuickViewModal(post.MemberId)
                    : ""
                }
              >
                {createTooltip(post.MemberName, post.MemberName)}
              </a>
            </span>
            <span className="row__column" id={"createdForName_" + index}>
              {createTooltip(post.CreatedForName, post.CreatedForName)}
            </span>
            <span className="row__column position-relative">
              <Link
                id={"confirmationCode_" + index}
                className={`${
                  hasPermission(PermissionName.VIEW_RESERVATION)
                    ? ""
                    : "noAnchorLink"
                }`}
                to={{
                  pathname: "/reservationSummary",
                  search: "?reservationId=" + post.ReservationId,
                  state: { breadCrumbList: breadCrumbList },
                }}
              >
                {createTooltip(post.ConfirmationCode, post.ConfirmationCode)}
              </Link>
            </span>
            <span className="row__column" id={"messageDescriptionId_" + index}>
              {createTooltip(post.MessageDescription, post.MessageDescription)}
            </span>{" "}
          </div>
        );
      });
    }
    return (
      <div>
        <div className="datagrid">
          <div className="column">
            <span className="column__header">Date & Time</span>
            <span className="column__header">From</span>
            <span className="column__header">To</span>
            <span className="column__header">Res. Code</span>
            <span className="column__header">Message</span>
          </div>
          {tableRows}
        </div>
      </div>
    );
  };
  //render() {
  let startDateVal = startDate
    ? Moment(startDate, "MM:DD:YYYY:HH:mm").toDate()
    : null;
  let endDateVal = endDate
    ? Moment(endDate, "MM:DD:YYYY:HH:mm").toDate()
    : null;

  setActiveMenu(props.match.url);
  const pageName = getPageName(props.match.url);

  let memberQuickViewCtrl = "";
  if (showMemberQuickViewPopup) {
    memberQuickViewCtrl = (
      <MemberQuickModal
        memberId={fromMemberId}
        showModal={showMemberQuickViewPopup}
        closeModal={modalClose}
      />
    );
  } else {
    memberQuickViewCtrl = null;
  }

  breadCrumbList = getMemberProfileBreadcrumbList(
    props.otherData,
    fromMemberId
  );

  return (
    <Fragment>
      <section className="res-search" id="memberMessageSearch">
        <PageTopPanel
          breadCrumbList={breadCrumbList}
          title="Messsages"
          linkList={getTopMainLinkUrl(pageName)}
          showExportLink={true}
          memberId={memberId}
          getExportSearchData={getExportSearchData}
          resetExportData={resetExportData}
          exportData={
            memberMessageResponse ? memberMessageResponse.Messages : []
          }
          pageName={pageName}
          linkBreadCrumb={getChildBreadCrumbList(
            props.match.url,
            props.location,
            breadCrumbList
          )}
        />
        <div className="search-input-container">
          <div>
            <div className="search-input-row">
              <div className="search-input-row__column">
                <label className="font-weight-bold mtr-1">Search Begin</label>
                <div className="dateandtimepicker">
                  <DateTimePicker
                    time={false}
                    format="M/D/YYYY"
                    placeholder="M/D/YYYY"
                    value={startDateVal}
                    defaultValue={startDateVal}
                    onChange={(event) =>
                      setStartDate(Moment(event).format("MM:DD:YYYY:hh:mm"))
                    }
                    dateFormat="D"
                  />
                </div>
              </div>
              <div className="search-input-row__column">
                <label className="font-weight-bold mtr-1">Search End</label>

                <div className="dateandtimepicker">
                  <DateTimePicker
                    time={false}
                    format="M/D/YYYY"
                    placeholder="M/D/YYYY"
                    value={endDateVal}
                    defaultValue={endDateVal}
                    onChange={(event) =>
                      setEndDate(Moment(event).format("MM:DD:YYYY:hh:mm"))
                    }
                    dateFormat="D"
                  />
                </div>
              </div>
              <div className="search-input-row__column">
                <button
                  type="button"
                  value="Search"
                  className="btn btn-primary search-btn"
                  onClick={(event) => submitHandler(event)}
                  id="btnResSearch"
                >
                  <i className="fa fa-check-square-o" />
                  Search
                </button>

                <button
                  type="button"
                  value="Reset"
                  className="btn btn-secondary search-btn"
                  onClick={(event) => resetHandler(event)}
                  id="btnResSearchReset"
                >
                  Reset
                </button>
              </div>
            </div>
          </div>
        </div>
        {memberQuickViewCtrl}
      </section>
      <section className="grid-table" id="memberMessageSearchTable">
        {searchData && searchData.TotalRecords > 0 ? (
          <Fragment>
            <Pagination
              totalRecords={searchData.TotalRecords}
              recordsPerPage={PageInfo.recordsPerPage}
              currentPage={PageInfo.currentPage}
              onPageChanged={onPageChanged}
              key={searchData.TotalRecords}
            />
            {renderTableData()}
          </Fragment>
        ) : isSearchPerformed ? (
          <NoRecordFoundComponent searchMsg="No Record Found" />
        ) : (
          <DefaultComponentWithMessage />
        )}
      </section>
      {props.loading || loading ? <Spinner /> : ""}
    </Fragment>
  );
};
export default MemberMessage;

import * as actionTypes from "./actionTypes";
import HttpService from "../../services/httpService";
export const getAgentStart = () => {
  return {
    type: actionTypes.GET_AGENT_START
  };
};

export const getAgentSuccess = response => {
  return {
    type: actionTypes.GET_AGENT_SUCCESS,
    agentResponse: response
  };
};

export const getAgentFail = error => {
  return {
    type: actionTypes.GET_AGENT_FAIL,
    error: error
  };
};
export const getAgentReset = () => {
  return {
    type: actionTypes.GET_AGENT_RESET
  };
};

export const getAgentList = getAgentReq => {
  return dispatch => {
    dispatch(getAgentStart());
    HttpService.getAgentList(getAgentReq)
      .then(response => {
        dispatch(getAgentSuccess(response));
      })
      .catch(err => {
        //dispatch(getAgentFail(err));
      });
  };
};

import moment from "moment";
import React from "react";
import ReactHtmlParser from "react-html-parser";
import TooltipItem from "../../../components/tooltip/TooltipItem";
import ProfileLinkIcon from "../../../components/UI/icon/profile-link-icon";
import { RoundCircleIcon } from "../../../components/UI/icon/round-circle-icon";
import { CommonRadioOrCheckBox } from "../../../components/UI/Input/common-cb-radio";
import { CommonLabel } from "../../../components/UI/label/commmon-label";
import { IconCtrlModel } from "../../../components/UI/model/icon-ctrl-model";
import {
  COMMON_MSG,
  ModalType,
  ReservationBlocks,
} from "../../../shared/GlobalVar";
import { getNoteTooltipHtml, isNullOrEmpty } from "../../../shared/utility";
const getTempSaveBtnClass = (currentForm) => {
  return "btn btn-primary mr-2";
};
const geNxtBtnClassNames = (type) => {
  if (type === ReservationBlocks.BASIC_INFO) {
  } else if (type === "DRIVER_INFO") {
  }
  return "btn btn-primary ml-2";
};
const getNxtBtnName = (currentForm, isPayLater, nxtBtnLbl) => {
  return nxtBtnLbl ? ReactHtmlParser(nxtBtnLbl) : "Next";
};
const executeNextBLock = (
  stepViewModel,
  onNextBtnClick,
  scrollToTopRow,
  iskeepChanged
) => {
  onNextBtnClick(iskeepChanged);
  if (
    stepViewModel.nextBlock === ReservationBlocks.CUSTOMER_INFO &&
    stepViewModel.currentBlock === ReservationBlocks.ADDONS_FORM
  )
    scrollToTopRow();
};
const executePrevBLock = (stepViewModel, onPrevBtnClick, scrollToTopRow) => {
  onPrevBtnClick(stepViewModel);
  if (
    stepViewModel.prevBlock === ReservationBlocks.VEHICLE_CLASSES &&
    stepViewModel.currentBlock === ReservationBlocks.ADDONS_FORM
  )
    scrollToTopRow();
};
const executeOnCancel = (currentForm, onCancelBtnClick, scrollToTopRow) => {
  if (currentForm === ReservationBlocks.ADDONS_FORM) scrollToTopRow();
  onCancelBtnClick();
};

export const getEditorButton = (
  foxWizardInfo,
  stepViewModel,
  isNewReservation,
  isFormInEditMode,
  onCancelBtnClick,
  onNextBtnClick,
  onPrevBtnClick,
  isValidationRequired,
  isPayLater,
  isDisabled,
  nxtBtnLbl,
  scrollToTopRow,
  isDisabledSaveBtn
) => {
  const nxtClassNames = geNxtBtnClassNames(stepViewModel.currentBlock);
  return isNewReservation &&
    foxWizardInfo.currentBlock === stepViewModel.currentBlock ? (
    <div className="settingFooter" style={{ display: "flex" }}>
      <div className="f-res-modal-btn" style={{ justifyContent: "flex-start" }}>
        <button
          type="button"
          className={getTempSaveBtnClass(stepViewModel.currentBlock)}
          onClick={() =>
            executeNextBLock(
              stepViewModel,
              onNextBtnClick,
              scrollToTopRow,
              true
            )
          }
          disabled={isDisabled || isDisabledSaveBtn}
          style={{ textAlign: "left" }}
        >
          Keep Changes
        </button>
      </div>
      <div className="f-res-modal-btn">
        {stepViewModel.prevBlock ? (
          <button
            type="button"
            className="btn btn-primary"
            onClick={() =>
              executePrevBLock(stepViewModel, onPrevBtnClick, scrollToTopRow)
            }
          >
            <i className="fas fa-arrow-left mr-2"></i> Prev
          </button>
        ) : (
          ""
        )}
        <button
          type="button"
          name="className"
          className="btn btn-secondary ml-2"
          onClick={() =>
            executeOnCancel(
              stepViewModel.currentBlock,
              onCancelBtnClick,
              scrollToTopRow
            )
          }
        >
          Cancel
        </button>
        {stepViewModel.nextBlock ||
        foxWizardInfo.currentBlock === ReservationBlocks.RES_NOTES_BLOCK ? (
          <button
            type="button"
            className={nxtClassNames}
            onClick={() =>
              executeNextBLock(stepViewModel, onNextBtnClick, scrollToTopRow)
            }
            disabled={isDisabled}
          >
            {getNxtBtnName(stepViewModel.currentBlock, isPayLater, nxtBtnLbl)}
            <i className="fas fa-arrow-right ml-2"></i>
          </button>
        ) : (
          ""
        )}
        {stepViewModel.currentBlock === ReservationBlocks.PAYMENT_INFO ? (
          <button
            type="button"
            className="btn btn-primary ml-2"
            // onClick={() => this.executePrevNextBLock(nextType)}
            // disabled={!this.state.enableCompleteResBtn}
          >
            Complete Reservation
          </button>
        ) : (
          ""
        )}
      </div>
    </div>
  ) : foxWizardInfo.currentBlock === stepViewModel.currentBlock ? (
    <div
      className="settingFooter"
      style={{ textAlign: "right", margin: "2rem" }}
    >
      <button
        type="button"
        className="btn btn-primary"
        //onClick={(event) => updateEditReservationBlock(type)}
      >
        Update
      </button>
      <button
        type="button"
        name="className"
        className="btn btn-secondary ml-2"
        //onClick={() => this.loseConfirmModal()}
      >
        Cancel
      </button>
    </div>
  ) : (
    ""
  );
};
const activateBlock = (stepViewModel, onActivateBlock, modalInfo) => {
  onActivateBlock(
    stepViewModel,
    modalInfo && modalInfo.executeFunction ? modalInfo.executeFunction : null
  );
};
export const getResFormRootActivateClassName = (
  currentBlock,
  className,
  foxWizardInfo,
  isModalActive
) => {
  if (
    foxWizardInfo.currentBlock === currentBlock &&
    !foxWizardInfo.iskeepChanged &&
    !isModalActive
  ) {
    return (
      className +
      " zoom" +
      (currentBlock === ReservationBlocks.PAYMENT_INFO
        ? " payment-modal-transform"
        : "")
    );
  } else {
    return className + " new-res-block";
  }
};
export const renderTitleHoverHtml = (
  stepViewModel,
  prevBlock,
  title,
  onActivateBlock,
  isPayLater,
  onSetPayType,
  reservationMode,
  modalInfo,
  cFailuIcoData,
  pRedirectData,
  disabled,
  isEditableReservation
) => {
  const isBlockEnabled =
    reservationMode !== "CANCELLED" &&
    (isPayLater || (!isPayLater && reservationMode === "NEW")) &&
    isEditableReservation;
  return (
    <>
      <i className="fas fa-user-circle" /> {title}
      {isBlockEnabled &&
      stepViewModel.currentBlock === ReservationBlocks.CUSTOMER_INFO ? (
        <>
          {cFailuIcoData ? (
            <RoundCircleIcon roundIconProps={cFailuIcoData} />
          ) : (
            ""
          )}

          {pRedirectData ? (
            <ProfileLinkIcon
              data={pRedirectData.data_temp}
              breadCrumbList={pRedirectData.breadCrumbList || []}
              styles={{
                height: "16px",
                width: "16px",
                margin: "0.6rem 0 0 0.5rem",
                position: "absolute",
              }}
            />
          ) : (
            ""
          )}
          <div>
            <button
              type="button"
              className="btn btn-primary newres-ico-btn"
              title="Search Member"
              onClick={() => {
                modalInfo.showMemberSearchModal();
              }}
            >
              <i className="fas fa-search"></i>
            </button>
            <button
              type="button"
              className="btn btn-primary newres-ico-btn"
              title="Clear"
              onClick={() => {
                modalInfo.onResetMemberData();
              }}
            >
              <i className="fas fa-sync-alt"></i>
            </button>
          </div>
        </>
      ) : (
        ""
      )}
      {stepViewModel.currentBlock ===
      ReservationBlocks.ADDITIONAL_DRIVER_INFO ? (
        <span
          className={isBlockEnabled ? "pseudo-link" : "disabled-icon"}
          onClick={() =>
            isBlockEnabled ? modalInfo.showAddUpdateAddtionalDriverForm() : ""
          }
        >
          <i className="fas fa-plus" />
          Add Driver
        </span>
      ) : (
        ""
      )}
      {isBlockEnabled ? (
        <div
          className="modal-backdrop fade show fade-update-res hover-block cursor-pointer"
          style={{ position: "absolute" }}
          onClick={() => {
            activateBlock(stepViewModel, onActivateBlock, modalInfo || null);
          }}
        >
          <div className="newres-water-pencil">
            <i className="fas fa-pencil-alt text-center" aria-hidden="true"></i>
            <span>
              {reservationMode === "NEW" ? "Click to Start" : "Click To Edit"}
            </span>
          </div>
        </div>
      ) : (
        ""
      )}
      {stepViewModel.currentBlock === ReservationBlocks.ADDONS_FORM ? (
        <button
          type="button"
          className={`btn btn-transparent newres-ico-btn ${
            isBlockEnabled ? "pseudo-link" : "disabled-icon"
          }`}
          title="Clear"
          onClick={() =>
            isBlockEnabled ? modalInfo.onClearAddonsSelection() : ""
          }
          disabled={disabled}
        >
          <i
            className={
              isBlockEnabled
                ? "far fa-trash-alt"
                : "disabled-icon far fa-trash-alt"
            }
          ></i>
        </button>
      ) : (
        ""
      )}
    </>
  );
};
export const getIconDataByType = (viewData, type, modalParams, othersData) => {
  const icoData = new IconCtrlModel();
  icoData.id = "ico" + type;
  icoData.type = type;
  icoData.iconValue =
    "<i class='" +
    othersData.iconValueClasses +
    (othersData.disability ? " disabled-icon" : "") +
    "' id='" +
    icoData.id +
    "' style='" +
    othersData.iconValueCssStyle +
    "'></i>";
  icoData.modalParams = modalParams;
  icoData.tooltipData = {
    msg: othersData.tooltipMsg,
    enableForceShow: true,
  };
  icoData.disabled = othersData.disability;
  return icoData;
};

export const getCurrentDrivingLicenseIconProperties = (licenseObj) => {
  const licenseApprovalIconObj = {};
  licenseObj = licenseObj || {};
  let isLicenseExpired = false;
  if (licenseObj.expDate) {
    isLicenseExpired =
      new Date() >= moment(licenseObj.expDate, "YYYY-MM-DDTHH:mm:ss").toDate()
        ? true
        : false;
  }
  const isApprovedLicenseWithValidity =
    licenseObj.drivingRecordChecked && !isLicenseExpired;
  const isApprovedLicenseExpired =
    licenseObj.drivingRecordChecked === false || isLicenseExpired;
  licenseApprovalIconObj.modalType = isApprovedLicenseWithValidity
    ? ModalType.LICENSE_INFO_MODAL
    : isApprovedLicenseExpired
    ? ModalType.LICENSE_APPROVAL_MODAL
    : ModalType.LICENSE_APPROVAL_MODAL;
  licenseApprovalIconObj.iconClasses = isApprovedLicenseWithValidity
    ? "fas fa-thumbs-up green-color"
    : isApprovedLicenseExpired
    ? "fas fa-thumbs-down red-color"
    : "fas fa-play-circle licenseVerifiedIco";
  licenseApprovalIconObj.tooltipMsg = isApprovedLicenseWithValidity
    ? COMMON_MSG.LicenseApprovedStatus +
      "<br/>" +
      COMMON_MSG.ManualDisapproveTooltipMsg
    : isApprovedLicenseExpired
    ? COMMON_MSG.LicenseDisApprovedStatus +
      "<br/>" +
      COMMON_MSG.ManualApproveTooltipMsg
    : COMMON_MSG.LicenseDisApprovedStatus +
      "<br/>" +
      COMMON_MSG.LicenseApproveTooltipMsg;
  return licenseApprovalIconObj;
};

export const renderIconHtml = (
  iconValue,
  showModal,
  modalParams,
  isDisabled,
  toolTipId,
  tooltipMsg
) => {
  return (
    <div
      onClick={() => (isDisabled ? null : showModal(modalParams))}
      id={toolTipId}
    >
      {ReactHtmlParser(iconValue)}
      <TooltipItem
        placement="bottom"
        toolTipId={toolTipId}
        renderableHtml={ReactHtmlParser(getNoteTooltipHtml(tooltipMsg))}
        forceShow={true}
      />
    </div>
  );
};

export const renderCommonHtmlRow = (data, index, showModal) => {
  return (
    <div
      className={
        data.detailLabel.parentClass ? data.detailLabel.parentClass : "row"
      }
      key={"topbar" + index + data.detailLabel.id}
      style={data.detailLabel.parentStyle ? data.detailLabel.parentStyle : {}}
    >
      <span className="font-weight-bold" style={data.titleLabel.styles}>
        {data.titleLabel.text}
      </span>
      {data.detailLabel.type === "checkbox" ? (
        <CommonRadioOrCheckBox data={data.detailLabel} showModal={showModal} />
      ) : (
        <CommonLabel
          className={
            data.detailLabel.classNames
              ? data.detailLabel.classNames
              : "pl-2 enabled-field-for-tooltip"
          }
          onClick={data.detailLabel.modalParams ? showModal : null}
          modalParameters={data.detailLabel.modalParams || null}
          value={data.detailLabel.text}
          lblId={data.detailLabel.id}
          toolTipMsg={
            !isNullOrEmpty(data.detailLabel.tooltipText)
              ? data.detailLabel.tooltipText
              : data.detailLabel.text
          }
          tooltipForceShow={true}
          lblStyle={data.detailLabel.lblStyle ? data.detailLabel.lblStyle : {}}
          lblClassName={
            data.detailLabel.lblClassName ? data.detailLabel.lblClassName : ""
          }
        />
      )}
    </div>
  );
};
export const renderSimpleLbl = (value) => {
  return <label>{ReactHtmlParser(value || "--")}</label>;
};
export const getMergedDateAndTimeValue = (ctrlVal, dateOrTimeVal, type) => {
  let datetimeStr = "";
  dateOrTimeVal = dateOrTimeVal || ctrlVal;
  if (type === "DATE") {
    datetimeStr = moment(
      moment(ctrlVal, "YYYY-MM-DDTHH:mm:ss").format("YYYY-MM-DD") +
        (dateOrTimeVal
          ? moment(dateOrTimeVal, "YYYY-MM-DDTHH:mm:ss").format("THH:mm:ss")
          : "00:00")
    ).format("YYYY-MM-DDTHH:mm:ss");
  } else {
    datetimeStr = moment(
      moment(dateOrTimeVal, "YYYY-MM-DDTHH:mm:ss").format("YYYY-MM-DD") +
        (ctrlVal
          ? moment(ctrlVal, "YYYY-MM-DDTHH:mm:ss").format("THH:mm:ss")
          : "00:00")
    ).format("YYYY-MM-DDTHH:mm:ss");
  }

  return datetimeStr;
};

import React, { Fragment } from "react";
import { createTooltip } from "../../../shared/utility/tooltip-utility";

const ActionIcon = (props) => {
  const iconClass = props.disabled
    ? `${props.className} light-gray-color`
    : `${props.className} ${props.fontColor} cursor-pointer`;
  return (
    <Fragment>
      {createTooltip(
        <i
          id={props.iconId}
          className={iconClass}
          aria-hidden="true"
          onClick={(event) =>
            props.disabled || !props.actionFunction
              ? ""
              : props.actionFunction(props.param1, props.param2)
          }
        />,
        props.value,
        { forceShow: true, isIcon: true, forceElementShow: true }
      )}
    </Fragment>
  );
};
export default ActionIcon;

import { ENVIRONMENT_MODE } from "../../../config/config";
import HttpService, { invokeServerAPI } from "../../services/httpService";
import {
  getLocalStoragePrefix,
  removeLocalStorageItems,
} from "../../shared/LocalStorage";
import {
  setLocalStorgeForLoggedInUser,
  setLocalStorgeForOrgUnits,
} from "../../shared/utility/store-utiility";
import * as actionTypes from "./actionTypes";

export const doLoginReset = () => {
  return {
    type: actionTypes.AUTH_RESET,
  };
};

export const doLoginStart = () => {
  return {
    type: actionTypes.AUTH_START,
  };
};

export const doLoginSuccess = (token, userId, loggedInUserData) => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    token: token,
    userId: userId,
    loggedInUserData: loggedInUserData,
    currentOrgUnitId:
      loggedInUserData && loggedInUserData.CurrentOrgUnitId
        ? loggedInUserData.CurrentOrgUnitId
        : null,
    orgUnitCountry:
      loggedInUserData && loggedInUserData.OrgUnitCountry
        ? loggedInUserData.OrgUnitCountry
        : null,
  };
};
export const updateLoginUserData = (loggedInUserData) => {
  return {
    type: actionTypes.LOGGEDIN_DATA_UPDATE,
    loggedInUserData: loggedInUserData,
    currentOrgUnitId:
      loggedInUserData && loggedInUserData.CurrentOrgUnitId
        ? loggedInUserData.CurrentOrgUnitId
        : null,
    orgUnitCountry:
      loggedInUserData && loggedInUserData.OrgUnitCountry
        ? loggedInUserData.OrgUnitCountry
        : null,
  };
};
export const doLoginFail = (error) => {
  return {
    type: actionTypes.AUTH_FAIL,
    error: error,
  };
};

export const logout = () => {
  removeLocalStorageItems("token");
  removeLocalStorageItems("expirationDate");
  removeLocalStorageItems("userId");
  removeLocalStorageItems(getLocalStoragePrefix("QuickHistory"));
  removeLocalStorageItems("IMPERSONATE", true);
  return {
    type: actionTypes.AUTH_LOGOUT,
  };
};

export const checkAuthTimeout = (expirationTime) => {
  return (dispatch) => {
    setTimeout(() => {
      dispatch(logout());
    }, expirationTime * 1000);
  };
};
const getDependantLocations = (locationList) => {
  const locList = [];
  locationList.map((data) => {
    locList.push({
      key: data.OrgUnitId,
      value: data.DisplayName,
      locationId: data.OrgUnitId,
      locationCode: data.LocationCode,
      displayName: data.DisplayName,
      name: data.Name,
      timezoneString: data.TimezoneString,
      timezoneDelta: data.TimezoneDelta,
    });
  });
  return locList;
};
export const doLogin = (email, password, googleToken) => {
  return (dispatch) => {
    dispatch(doLoginStart());
    const token = localStorage.getItem("token");
    //const userId = localStorage.getItem("userId");
    if (!token) {
      HttpService.getTokenInfo()
        .then((tokenResponse) => {
          if (tokenResponse.StatusCode === 0) {
            localStorage.setItem("token", tokenResponse.TokenId);
            const loginReq = {
              UserName: email || null,
              Password: password || null,
              SsoTokenId: googleToken || null,
              TokenId: tokenResponse.TokenId,
            };
            HttpService.doLogin(loginReq).then((loginResponse) => {
              let loggedInUserData = {
                StatusCode: loginResponse.StatusCode,
                StatusMsg: loginResponse.StatusMsg,
              };
              if (loginResponse.StatusCode === 0) {
                loggedInUserData = {
                  ...loggedInUserData,
                  ...loginResponse.LoginInfo,
                };
                const expirationDate = new Date(
                  new Date().getTime() + 864000 * 1000
                );
                //dispatch(checkAuthTimeout(expirationDate));
                localStorage.setItem("expirationDate", expirationDate);
                setLocalStorgeForLoggedInUser(loggedInUserData);
                getOrgDetailsForLogin(
                  Number(localStorage.getItem("currentOrgUnitId")) ||
                    loginResponse.LoginInfo.User.OrgUnitId,
                  loggedInUserData
                ).then((orgResponse) => {
                  loggedInUserData = { ...orgResponse };

                  //dispatch(getOrgDetailsSuccess(orgResponse));
                  dispatch(
                    doLoginSuccess(
                      tokenResponse.TokenId || null,
                      loggedInUserData ? loggedInUserData.UserId : null,
                      loggedInUserData
                    )
                  );
                });
              } else {
                dispatch(doLoginSuccess(null, null, loggedInUserData));
                dispatch(logout());
              }
            });
          } else {
            dispatch(doLoginSuccess(null, null, tokenResponse));
            dispatch(logout());
          }
        })
        .catch((err) => {
          dispatch(doLoginFail(err.message));
        });
    } else {
      dispatch(logout());
    }
  };
};
export const getOrgDetailsForLogin = (orgUnitId, loggedInUserData) => {
  return HttpService.getOrgDetails(orgUnitId).then((orgResponse) => {
    return margeLoginInfoWithOrgDetails(loggedInUserData, orgResponse);
  });
};
export const updateOrgdetailsWithLoginInfo = (
  loggedInUserData,
  orgResponse
) => {
  return (dispatch) => {
    const loginData = margeLoginInfoWithOrgDetails(
      loggedInUserData,
      orgResponse
    );
    dispatch(updateLoginUserData(loginData));
  };
};
export const margeLoginInfoWithOrgDetails = (loggedInUserData, orgResponse) => {
  if (orgResponse.StatusCode === 0) {
    loggedInUserData.DependantOrgList = orgResponse.OrgUnit.Dependants || [];
    loggedInUserData.DependantLocations =
      orgResponse.OrgUnit.DependantInfos &&
      orgResponse.OrgUnit.DependantInfos.length > 0
        ? getDependantLocations(orgResponse.OrgUnit.DependantInfos)
        : [];
    loggedInUserData.UserPermissions = orgResponse.OrgUnit.UserPermissions;
    loggedInUserData.VehicleCategories = orgResponse.SearchCategories;
    loggedInUserData.CurrentOrgUnitId = orgResponse.OrgUnit.OrgUnitId;
    loggedInUserData.CurrentOrgUnitKey = orgResponse.OrgUnit.OrgKey;
    loggedInUserData.CurrentOrgUnitDisplayName =
      orgResponse.OrgUnit.LocationCode || orgResponse.OrgUnit.DisplayName;
    loggedInUserData.SelectedOrgKeyValue = {
      Key: orgResponse.OrgUnit.OrgUnitId,
      Value: orgResponse.OrgUnit.DisplayName,
    };
    loggedInUserData.OrgUnitCountry = orgResponse.OrgUnitCountry;
    loggedInUserData.Roles = orgResponse.OrgUnit.Roles;
    loggedInUserData.OrgUnits = orgResponse.AllowedOrgUnits || [];
    loggedInUserData.OrgUnitSettings = orgResponse.OrgUnit;
    loggedInUserData.OrgUnitSettings.UpsellOptions = orgResponse.UpsellOptions;
    if (loggedInUserData.OrgUnitSettings.UserPermissions) {
      delete loggedInUserData.OrgUnitSettings.UserPermissions;
    }
    if (loggedInUserData.OrgUnitSettings.Roles) {
      delete loggedInUserData.OrgUnitSettings.Roles;
    }
    if (loggedInUserData.OrgUnitSettings.OrgAliasList) {
      delete loggedInUserData.OrgUnitSettings.OrgAliasList;
    }
    if (loggedInUserData.OrgUnitSettings.Dependants) {
      delete loggedInUserData.OrgUnitSettings.Dependants;
    }
    if (loggedInUserData.OrgUnitSettings.ActiveOrgAliasList) {
      delete loggedInUserData.OrgUnitSettings.ActiveOrgAliasList;
    }
    localStorage.setItem(
      "timeZoneString",
      JSON.stringify(loggedInUserData.OrgUnitSettings.TimezoneString)
    );
    localStorage.setItem(
      "orgRentalType",
      loggedInUserData.OrgUnitSettings.RentalType
    );
    setLocalStorgeForOrgUnits(loggedInUserData);
    return loggedInUserData;
  } else {
    loggedInUserData.StatusCode = orgResponse.StatusCode;
    loggedInUserData.StatusMsg = orgResponse.StatusMsg;
  }
  return loggedInUserData;
};

export const setAuthRedirectPath = (path) => {
  return {
    type: actionTypes.SET_AUTH_REDIRECT_PATH,
    path: path,
  };
};
export const authCheckState = () => {
  return (dispatch) => {
    const token = localStorage.getItem("token");
    const loggedInUserId = localStorage.getItem("loggedInUserId");
    if (!token || !loggedInUserId) {
      dispatch(logout());
    } else {
      const expirationDate = new Date(localStorage.getItem("expirationDate"));
      if (expirationDate <= new Date()) {
        dispatch(logout());
      } else {
        if (ENVIRONMENT_MODE === "PRODUCTION") {
          dispatch(doLoginStart());
          const autoLoginReq = {
            Version: global.VERSION,
            RType: global.R_TYPE,
            TokenId: token,
            UserId: loggedInUserId,
            OrgUnitId: Number(localStorage.getItem("currentOrgUnitId")) || null,
          };
          HttpService.doAutoLogin(autoLoginReq).then((autoLoginResponse) => {
            let loggedInUserData = {
              StatusCode: autoLoginResponse.StatusCode,
              StatusMsg: autoLoginResponse.StatusMsg,
            };
            if (autoLoginResponse.StatusCode === 0) {
              loggedInUserData = {
                ...loggedInUserData,
                ...autoLoginResponse.LoginInfo,
              };
              setLocalStorgeForLoggedInUser(loggedInUserData);
              getOrgDetailsForLogin(
                Number(localStorage.getItem("currentOrgUnitId")) ||
                  autoLoginResponse.LoginInfo.User.OrgUnitId,
                loggedInUserData
              ).then((orgResponse) => {
                loggedInUserData = { ...orgResponse };
                loggedInUserData.CurrentOrgUnitId =
                  Number(localStorage.getItem("currentOrgUnitId")) ||
                  autoLoginResponse.LoginInfo.User.OrgUnitId;
                dispatch(
                  doLoginSuccess(
                    token,
                    loggedInUserData ? loggedInUserData.UserId : null,
                    loggedInUserData
                  )
                );
              });
            } else {
              dispatch(doLoginSuccess(null, null, loggedInUserData));
              dispatch(logout());
            }
          });
        } else {
          doDevelopMentAutoLogin(dispatch, token, loggedInUserId);
        }
      }
    }
  };
};
export const doDevelopMentAutoLogin = (dispatch, token, loggedInUserId) => {
  var loggedInUserData = new Object();
  loggedInUserData.User = JSON.parse(localStorage.getItem("userData"));
  loggedInUserData.OrgUnits =
    JSON.parse(localStorage.getItem("orgUnits")) || null;
  loggedInUserData.DependantOrgList = JSON.parse(
    localStorage.getItem("dependantOrgList")
  );
  loggedInUserData.SelectedOrgKeyValue = JSON.parse(
    localStorage.getItem("selectedOrgKeyValue")
  );
  loggedInUserData.UserPermissions = JSON.parse(
    localStorage.getItem("userPermissions")
  );
  loggedInUserData.VehicleCategories = JSON.parse(
    localStorage.getItem("vehicleCategories")
  );
  loggedInUserData.Roles = JSON.parse(localStorage.getItem("roles"));
  loggedInUserData.OrgUnitId = Number(localStorage.getItem("currentOrgUnitId"));
  loggedInUserData.OrgUnitCountry = JSON.parse(
    localStorage.getItem("orgUnitCountry")
  );
  loggedInUserData.OrgUnitSettings = JSON.parse(
    localStorage.getItem("orgUnitSettings")
  );
  dispatch(doLoginSuccess(token, loggedInUserId, loggedInUserData));
  // dispatch(
  //   checkAuthTimeout((expirationDate.getTime() - new Date().getTime()) / 1000)
  // );
};

export const updateLoggedInUserInfoWithResponse = (
  loggedInUserData,
  updatedLoginInfoResponse
) => {
  if (updatedLoginInfoResponse.StatusCode === 0) {
    loggedInUserData.HintAnswer = updatedLoginInfoResponse.LoginInfo.HintAnswer;
    loggedInUserData.HintQuestion =
      updatedLoginInfoResponse.LoginInfo.HintQuestion;
    loggedInUserData.User = updatedLoginInfoResponse.LoginInfo.User;
    loggedInUserData.StatusCode = updatedLoginInfoResponse.StatusCode;
    loggedInUserData.StatusMsg = updatedLoginInfoResponse.StatusMsg;

    return loggedInUserData;
  } else {
    loggedInUserData.StatusCode = updatedLoginInfoResponse.StatusCode;
    loggedInUserData.StatusMsg = updatedLoginInfoResponse.StatusMsg;
  }
  return loggedInUserData;
};

export const updateLoggedInUserInfoStart = () => {
  return {
    type: actionTypes.UPDATE_LOGGED_IN_USER_INFO_START,
  };
};
export const updateLoggedInUserInfoSuccess = (response) => {
  return {
    type: actionTypes.UPDATE_LOGGED_IN_USER_INFO_SUCCESS,
    updateLoggedInUserInfoResponse: response,
  };
};
export const updateLoggedInUserInfoFail = (error) => {
  return {
    type: actionTypes.UPDATE_LOGGED_IN_USER_INFO_FAIL,
    error: error,
  };
};
export const updateLoggedInUserInfoReset = () => {
  return {
    type: actionTypes.UPDATE_LOGGED_IN_USER_INFO_RESET,
  };
};

export const updateLoggedInUserInfo = (loggedInUserData, req) => {
  return (dispatch) => {
    dispatch(updateLoggedInUserInfoStart());
    invokeServerAPI("editccrprofile", req)
      .then((response) => {
        dispatch(updateLoggedInUserInfoSuccess(response));
        const updatedUserInfo = updateLoggedInUserInfoWithResponse(
          loggedInUserData,
          response
        );
        dispatch(updateLoginUserData(updatedUserInfo));
      })
      .catch((err) => {
        //dispatch(updateLoggedInUserInfoFail(err));
      });
  };
};

import React, { useEffect, useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  getMainSettingInfo,
  getMainSettingInfoReset,
  updateMainSettingInfo,
  updateMainSettingInfoReset,
} from "../../../store/actions/index";
import Spinner from "../../../components/UI/Spinner/Spinner";
import {
  updateObject,
  populateTimezoneDataDropdown,
  hasPermission,
  getBasicToastResponse,
  getErrorToastData,
} from "../../../shared/utility";
import PermissionName from "../../../shared/Permissions";
import { showNotification } from "../../../components/UI/Toaster/Toaster";
import { validateMainOrgSettings } from "../settingsHelper";
import AddressComponent from "../../../components/common/address-component/address-component";
import { MainSettingsViewModel } from "../../../services/entities/view-models/settings-view-models";
import LinkButton from "../../../components/UI/button/link-btn";

import PreventTransitionPrompt from "../../../components/Navigations/prevent-navigation/prevent-transition-prompt";
import { LabelAndValuePairControl } from "../../../components/UI/Input/label-value-pair-control";
import { commonAddressChangeHandler } from "../../../components/common/common-helper";

const MainSettings = (props) => {
  const [loading, setLoading] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const storeLoading = useSelector((state) => state.settingsReducer.loading);
  const [isValidateDone, setIsValidateDone] = useState(false);
  const currentOrgUnitId = useSelector(
    (state) => state.auth.loggedInUserData.CurrentOrgUnitId
  );
  const mainSettingInfoResponse = useSelector(
    (state) => state.settingsReducer.mainSettingInfoResponse
  );

  const mainSettingInfoUpdateResponse = useSelector(
    (state) => state.settingsReducer.mainSettingInfoUpdateResponse
  );
  const dispatch = useDispatch();
  const [mainSettingsData, setMainSettingsData] = useState(
    new MainSettingsViewModel()
  );

  const hasEditPermission = hasPermission(
    PermissionName.EDIT_MAIN_ORG_SETTINGS
  );

  const timezones = [{ key: "", displayValue: "Select Timezone" }].concat(
    populateTimezoneDataDropdown()
  );
  const [isBlocking, setIsBlocking] = useState(false);

  useEffect(() => {
    getOrgSettingsInfo();
  }, []);

  useEffect(() => {
    if (!mainSettingInfoResponse) {
      return;
    }
    if (mainSettingInfoResponse.StatusCode === 0) {
      setMainSettingsData(new MainSettingsViewModel(mainSettingInfoResponse));
      setIsEditMode(false);
      setIsBlocking(false);
    } else {
      showNotification(getBasicToastResponse(mainSettingInfoResponse));
      dispatch(getMainSettingInfoReset());
    }
  }, [mainSettingInfoResponse]);

  useEffect(() => {
    if (!mainSettingInfoUpdateResponse) {
      return;
    }
    showNotification(getBasicToastResponse(mainSettingInfoUpdateResponse));
    if (mainSettingInfoUpdateResponse.StatusCode === 0) {
      getOrgSettingsInfo();
    }
    dispatch(updateMainSettingInfoReset());
  }, [mainSettingInfoUpdateResponse]);

  const inputChangedHandler = (event, controlName, elementType) => {
    let value = null;
    if (elementType === "datetimepicker") {
      value = moment(event).format("MM:DD:YYYY:HH:mm");
    } else {
      value = event.target.value;
    }
    const updatedControls = updateObject(mainSettingsData, {
      [controlName]: value,
    });
    setMainSettingsData(updatedControls);
    setIsBlocking(true);
  };
  const enableEditMode = () => {
    setIsEditMode(true);
  };
  const renderLabelInputPair = (
    labelCaption,
    controlName,
    value,
    isRequired = false,
    nodeElement = "input",
    dropdownItem = null,
    isTimezone = false
  ) => {
    return (
      <LabelAndValuePairControl
        elementConfig={{ name: controlName, value: value }}
        otherConfig={{
          caption: labelCaption,
          isRequired: isRequired,
          dropdownItem: nodeElement === "select" ? dropdownItem : [],
        }}
        isEditMode={isEditMode}
        onChange={inputChangedHandler}
        parentRowClass={`form-group row  ${
          isEditMode && isRequired ? "required" : ""
        }`}
        labelClasses={"col-sm-5 col-form-label font-weight-bold"}
        valueClasses={"col-sm-7"}
        commonLabelClasses="col-lg-12 p-0 text-truncate-block padding-settings-5"
        nodeElement={nodeElement}
        isValidateDone={isValidateDone}
      />
    );
  };

  const onCancel = () => {
    setIsEditMode(false);
    setMainSettingsData(new MainSettingsViewModel(mainSettingInfoResponse));
    setIsBlocking(false);
    setIsValidateDone(false);
  };
  const inputMainSettingAddressChangedHandler = (event, controlName, objectType, commonData) => {
    let updatedControls = { ...mainSettingsData };
    let address = { ...updatedControls.Address };
        address["isValidZipCode"] = true;
        address = commonAddressChangeHandler(event, controlName, commonData, address)
    updatedControls = updateObject(updatedControls, {
      Address: { ...address },
    });
    setMainSettingsData(updatedControls);
    setIsBlocking(true);
  };

  const resetLoader = (isLoading) => {
    setLoading(isLoading);
  };

  const getOrgSettingsInfo = () => {
    const orgSearchDataObj = {};
    orgSearchDataObj.OrgUnitId = currentOrgUnitId;
    dispatch(getMainSettingInfoReset());
    dispatch(getMainSettingInfo(orgSearchDataObj));
  };

  const updateData = () => {
    let msg = "";
    const mainSettingDataObj = { ...mainSettingsData };
    delete mainSettingDataObj.Address["isValidZipCode"];

    if (!msg) {
      msg = validateMainOrgSettings(mainSettingDataObj);
    }

    if (msg) {
      showNotification(getErrorToastData(msg));
      setIsValidateDone(true);
    } else {
      setIsValidateDone(false);
      dispatch(updateMainSettingInfoReset());
      dispatch(updateMainSettingInfo(mainSettingDataObj));
    }
  };
  //const [isBlocking, setIsBlocking] = useState(false);
  return (
    <Fragment>
      <PreventTransitionPrompt when={isBlocking} />

      <div className="col-lg-12 pt-3 pl-0" id="main-settings">
        <div className="card">
          <div className="card-body">
            <div className="form-header row mb-0">
              <h4 className="text-uppercase mr-auto icon-display-none">
                <i className="fas fa-cogs" />
                Main Settings
              </h4>
              {hasEditPermission && !isEditMode ? (
                <LinkButton
                  btnLabel="Edit Settings"
                  containerClass="pseudo-link pr-1 fs-2 font-weight-bold mr-5 d-flex align-items-center"
                  stackClass="fa-stack fs-08 vertical-align-top"
                  faIconList={[
                    "fa fa-circle fa-stack-2x iconBeforeLink",
                    "fa fa-pencil-alt fa-stack-1x whiteColor pr-1",
                  ]}
                  clicked={enableEditMode}
                />
              ) : (
                ""
              )}
            </div>
            <div className={`row pt-3 ${isEditMode ? "background-edit" : ""}`}>
              <div
                className="col-lg-6 p-0"
                style={{ borderRight: "1px solid gray" }}
              >
                {renderLabelInputPair(
                  "Location Code",
                  "LocationCode",
                  mainSettingsData.LocationCode,
                  true
                )}
                {renderLabelInputPair(
                  "Display Name",
                  "DisplayName",
                  mainSettingsData.DisplayName,
                  true
                )}
                <AddressComponent
                  isAstericsRequired={true}
                  type="mainSetting"
                  key="mainSetting-Address-Component"
                  onChangeMethod={inputMainSettingAddressChangedHandler}
                  address={{ ...mainSettingsData.Address }}
                  resetLoader={resetLoader}
                  isNoneEditableMode={!isEditMode}
                  inputContainerClassName="col-sm-7"
                  leftLblClassName="col-sm-5 col-form-label font-weight-bold"
                  rightLblClassName="col-sm-9  p-0 padding-settings-5 text-truncate-block"
                  isValidateDone={isValidateDone}
                />
                {renderLabelInputPair(
                  "Toll Free Number",
                  "TollFreeNumber",
                  mainSettingsData.TollFreeNumber
                )}
                {renderLabelInputPair(
                  "Contact Person First Name",
                  "ContactPersonFirstName",
                  mainSettingsData.ContactPersonFirstName,
                  true
                )}
                {renderLabelInputPair(
                  "Contact Person Last Name",
                  "ContactPersonLastName",
                  mainSettingsData.ContactPersonLastName,
                  true
                )}
                {renderLabelInputPair(
                  "ContactPersonPhone",
                  "ContactPersonPhone",
                  mainSettingsData.ContactPersonPhone,
                  true
                )}
                {renderLabelInputPair(
                  "Time Zone",
                  "TimeZone",
                  mainSettingsData.TimeZone,
                  true,
                  "select",
                  timezones,
                  true
                )}
                {renderLabelInputPair(
                  "Time Zone Delta",
                  "TimeZoneDelta",
                  mainSettingsData.TimeZoneDelta,
                  true
                )}
                {renderLabelInputPair(
                  "Time Zone Standard Delta",
                  "TimeZoneStandardDelta",
                  mainSettingsData.TimeZoneStandardDelta,
                  true
                )}
                {renderLabelInputPair("Email", "Email", mainSettingsData.Email)}
                {renderLabelInputPair(
                  "EmailBcc",
                  "EmailBcc",
                  mainSettingsData.EmailBcc
                )}
                {renderLabelInputPair(
                  "Support Email(s) (comma separated)",
                  "SupportEmails",
                  mainSettingsData.SupportEmails
                )}
                {renderLabelInputPair(
                  "Checkout Email(s) (comma separated)",
                  "CheckoutEmails",
                  mainSettingsData.CheckoutEmails
                )}
                {renderLabelInputPair(
                  "Twitter Account",
                  "TwitterName",
                  mainSettingsData.TwitterName
                )}
                {renderLabelInputPair(
                  "Facebook Account",
                  "FacebookName",
                  mainSettingsData.FacebookName
                )}
              </div>
              <div className="col-lg-6 p-0">
                {renderLabelInputPair(
                  "Terms of Service",
                  "TermsOfServiceLink",
                  mainSettingsData.TermsOfServiceLink
                )}
                {renderLabelInputPair(
                  "Privacy Policy",
                  "PrivacyPolicyLink",
                  mainSettingsData.PrivacyPolicyLink
                )}
                {renderLabelInputPair(
                  "Renter Policy Link",
                  "RenterPolicyLink",
                  mainSettingsData.RenterPolicyLink
                )}
                {renderLabelInputPair(
                  "Make Appt URL",
                  "MakeApptLinkUrl",
                  mainSettingsData.MakeApptLinkUrl
                )}
                {renderLabelInputPair(
                  "Org Web URL",
                  "OrgWebUrl",
                  mainSettingsData.OrgWebUrl
                )}
                {renderLabelInputPair(
                  "Pickup Instructions URL",
                  "PickupInstructionLinkUrl",
                  mainSettingsData.PickupInstructionLinkUrl
                )}
                {renderLabelInputPair(
                  "Quickstart Guide URL",
                  "QuickStartGuideLinkUrl",
                  mainSettingsData.QuickStartGuideLinkUrl
                )}
                {renderLabelInputPair(
                  "Instruction Access Vehicle Link",
                  "InstructionsAccessVehicleLink",
                  mainSettingsData.InstructionsAccessVehicleLink
                )}
                {renderLabelInputPair(
                  "Description",
                  "Description",
                  mainSettingsData.Description,
                  false,
                  "textarea"
                )}
              </div>
            </div>
            <div className="settingFooter">
              {hasEditPermission && isEditMode ? (
                <Fragment>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={(event) => updateData()}
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    name="className"
                    className="btn btn-secondary ml-2"
                    onClick={() => onCancel()}
                  >
                    Cancel
                  </button>
                </Fragment>
              ) : (
                <div style={{ height: "2.5rem" }}>&nbsp;</div>
              )}
            </div>
          </div>
        </div>
        {storeLoading || loading ? <Spinner /> : ""}
      </div>
    </Fragment>
  );
};
export default MainSettings;

import * as actionTypes from "../actions/actionTypes";
import { reducerUpdateObject } from "./reducer.utility";

const initialState = {
  searchReservationResponse: null,
  searchRentalResponse: null,
  reservationOverviewResponse: null,
  searchMemberReservationsResponse: null,
  searchMemberVehicleReservationsResponse: null,
  editConfirmationCodeResponse: null,
  updateReservationDetailsResponse: null,
  updateNotesResponse: null,
  integrationHistoryResponse: null,
  updateIntegStatusResponse: null,
  acceptTermsConditionsResponse: null,
  acceptDeclineResponse: null,
  reservationCreationTypeResponse: null,
  updateCancelletionResponse: null,
  contractResponse: null,
  invoiceResponse: null,
  reservationSummaryResponse: null,
  reservationVoucherResponse: null,
  mailResponse: null,
  error: null,
  loading: false,
};

const doReservationSearchStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
const doReservationSearchSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    searchReservationResponse: action.searchReservationResponse,
    error: null,
    loading: false,
  });
};
const doReservationSearchFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false,
  });
};
const doReservationSearchReset = (state, action) => {
  return reducerUpdateObject(state, { searchReservationResponse: null });
};
const doRentalSearchStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
const doRentalSearchSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    searchRentalResponse: action.searchRentalResponse,
    error: null,
    loading: false,
  });
};
const doRentalSearchFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false,
  });
};
const doRentalSearchReset = (state, action) => {
  return reducerUpdateObject(state, { searchRentalResponse: null });
};

const doMemberSearchReservationStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
const doMemberSearchReservationSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    searchMemberReservationsResponse: action.searchMemberReservationsResponse,
    error: null,
    loading: false,
  });
};
const doMemberSearchReservationFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false,
  });
};
const doMemberSearchReservationReset = (state, action) => {
  return reducerUpdateObject(state, { searchMemberReservationsResponse: null });
};

const doMemberVehicleSearchReservationStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
const doMemberVehicleSearchReservationSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    searchMemberVehicleReservationsResponse:
      action.searchMemberVehicleReservationsResponse,
    error: null,
    loading: false,
  });
};
const doMemberVehicleSearchReservationFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false,
  });
};
const doMemberVehicleSearchReservationReset = (state, action) => {
  return reducerUpdateObject(state, {
    searchMemberVehicleReservationsResponse: null,
  });
};

const getReservationOverviewStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
const getReservationOverviewSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    reservationOverviewResponse: action.reservationOverviewResponse,
    error: null,
    loading: false,
  });
};
const getReservationOverviewFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false,
  });
};
const getReservationOverviewReset = (state, action) => {
  return reducerUpdateObject(state, { reservationOverviewResponse: null });
};

const cancelReservationStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
const cancelReservationSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    cancelReservationResponse: action.cancelReservationResponse,
    error: null,
    loading: false,
  });
};
const cancelReservationFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false,
  });
};
const cancelReservationReset = (state, action) => {
  return reducerUpdateObject(state, { cancelReservationResponse: null });
};

const updateCancellationReasonStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
const updateCancellationReasonSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    updateCancelletionResponse: action.updateCancelletionResponse,
    error: null,
    loading: false,
  });
};
const updateCancellationReasonFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false,
  });
};
const updateCancellationReasonReset = (state, action) => {
  return reducerUpdateObject(state, { updateCancelletionResponse: null });
};

const editConfirmationCodeStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
const editConfirmationCodeSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    editConfirmationCodeResponse: action.editConfirmationCodeResponse,
    error: null,
    loading: false,
  });
};
const editConfirmationCodeFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false,
  });
};
const editConfirmationCodeReset = (state, action) => {
  return reducerUpdateObject(state, { editConfirmationCodeResponse: null });
};

const updateReservationNotesStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
const updateReservationNotesSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    updateNotesResponse: action.updateNotesResponse,
    error: null,
    loading: false,
  });
};
const updateReservationNotesFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false,
  });
};
const updateReservationNotesReset = (state, action) => {
  return reducerUpdateObject(state, { updateNotesResponse: null });
};

const getIntegrationHistoryStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
const getIntegrationHistorySuccess = (state, action) => {
  return reducerUpdateObject(state, {
    integrationHistoryResponse: action.integrationHistoryResponse,
    error: null,
    loading: false,
  });
};
const getIntegrationHistoryFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false,
  });
};
const getIntegrationHistoryReset = (state, action) => {
  return reducerUpdateObject(state, { integrationHistoryResponse: null });
};

const updateIntegStatusStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
const updateIntegStatusSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    updateIntegStatusResponse: action.updateIntegStatusResponse,
    error: null,
    loading: false,
  });
};
const updateIntegStatusFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false,
  });
};
const updateIntegStatusReset = (state, action) => {
  return reducerUpdateObject(state, { updateIntegStatusResponse: null });
};

const acceptTermsAndConditionsStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
const acceptTermsAndConditionsSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    acceptTermsConditionsResponse: action.acceptTermsConditionsResponse,
    error: null,
    loading: false,
  });
};
const acceptTermsAndConditionsFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false,
  });
};
const acceptTermsAndConditionsReset = (state, action) => {
  return reducerUpdateObject(state, { acceptTermsConditionsResponse: null });
};

const doAcceptOrDeclineReservationStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
const doAcceptOrDeclineReservationSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    acceptDeclineResponse: action.acceptDeclineResponse,
    error: null,
    loading: false,
  });
};
const doAcceptOrDeclineReservationFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false,
  });
};
const doAcceptOrDeclineReservationReset = (state, action) => {
  return reducerUpdateObject(state, { acceptDeclineResponse: null });
};

const updateReservationCreationTypeStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
const updateReservationCreationTypeSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    reservationCreationTypeResponse: action.reservationCreationTypeResponse,
    error: null,
    loading: false,
  });
};
const updateReservationCreationTypeFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false,
  });
};
const updateReservationCreationTypeReset = (state, action) => {
  return reducerUpdateObject(state, { reservationCreationTypeResponse: null });
};

const getContractStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
const getContractSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    contractResponse: action.contractResponse,
    error: null,
    loading: false,
  });
};
const getContractFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false,
  });
};
const getContractReset = (state, action) => {
  return reducerUpdateObject(state, { contractResponse: null });
};

const getInvoiceStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
const getInvoiceSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    invoiceResponse: action.invoiceResponse,
    error: null,
    loading: false,
  });
};
const getInvoiceFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false,
  });
};
const getInvoiceReset = (state, action) => {
  return reducerUpdateObject(state, {
    invoiceResponse: null,
    mailResponse: null,
  });
};

const getReservationSummaryStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
const getReservationSummarySuccess = (state, action) => {
  return reducerUpdateObject(state, {
    reservationSummaryResponse: action.reservationSummaryResponse,
    error: null,
    loading: false,
  });
};
const getReservationSummaryFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false,
  });
};
const getReservationSummaryReset = (state, action) => {
  return reducerUpdateObject(state, { reservationSummaryResponse: null });
};
/**Reservation Voucher */
const getReservationVoucherStart = (state) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
const getReservationVoucherSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    reservationVoucherResponse: action.reservationVoucherResponse,
    error: null,
    loading: false,
  });
};
const getReservationVoucherFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false,
  });
};
const getReservationVoucherReset = (state) => {
  return reducerUpdateObject(state, { reservationVoucherResponse: null });
};
/**Reservation Summary Mail */
const sendMailStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
const sendMailSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    mailResponse: action.mailResponse,
    error: null,
    loading: false,
  });
};
const sendMailFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false,
  });
};
const sendMailReset = (state, action) => {
  return reducerUpdateObject(state, { mailResponse: null });
};

const updateReservationDetailsStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
const updateReservationDetailsSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    updateReservationDetailsResponse: action.updateReservationDetailsResponse,
    error: null,
    loading: false,
  });
};
const updateReservationDetailsFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false,
  });
};
const updateReservationDetailsReset = (state, action) => {
  return reducerUpdateObject(state, { updateReservationDetailsResponse: null });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_RESERVATION_OVERVIEW_START:
      return getReservationOverviewStart(state, action);
    case actionTypes.GET_RESERVATION_OVERVIEW_SUCCESS:
      return getReservationOverviewSuccess(state, action);
    case actionTypes.GET_RESERVATION_OVERVIEW_FAIL:
      return getReservationOverviewFail(state, action);
    case actionTypes.GET_RESERVATION_OVERVIEW_RESET:
      return getReservationOverviewReset(state, action);

    case actionTypes.SEARCH_RESERVATION_START:
      return doReservationSearchStart(state, action);
    case actionTypes.SEARCH_RESERVATION_SUCCESS:
      return doReservationSearchSuccess(state, action);
    case actionTypes.SEARCH_RESERVATION_FAIL:
      return doReservationSearchFail(state, action);
    case actionTypes.SEARCH_RESERVATION_RESET:
      return doReservationSearchReset(state, action);

    case actionTypes.SEARCH_RENTAL_START:
      return doRentalSearchStart(state, action);
    case actionTypes.SEARCH_RENTAL_SUCCESS:
      return doRentalSearchSuccess(state, action);
    case actionTypes.SEARCH_RENTAL_FAIL:
      return doRentalSearchFail(state, action);
    case actionTypes.SEARCH_RENTAL_RESET:
      return doRentalSearchReset(state, action);

    case actionTypes.SEARCH_MEMBER_RESERVATION_START:
      return doMemberSearchReservationStart(state, action);
    case actionTypes.SEARCH_MEMBER_RESERVATION_SUCCESS:
      return doMemberSearchReservationSuccess(state, action);
    case actionTypes.SEARCH_MEMBER_RESERVATION_FAIL:
      return doMemberSearchReservationFail(state, action);
    case actionTypes.SEARCH_MEMBER_RESERVATION_RESET:
      return doMemberSearchReservationReset(state, action);

    case actionTypes.SEARCH_MEMBER_VEHICLE_RESERVATION_START:
      return doMemberVehicleSearchReservationStart(state, action);
    case actionTypes.SEARCH_MEMBER_VEHICLE_RESERVATION_SUCCESS:
      return doMemberVehicleSearchReservationSuccess(state, action);
    case actionTypes.SEARCH_MEMBER_VEHICLE_RESERVATION_FAIL:
      return doMemberVehicleSearchReservationFail(state, action);
    case actionTypes.SEARCH_MEMBER_VEHICLE_RESERVATION_RESET:
      return doMemberVehicleSearchReservationReset(state, action);

    case actionTypes.CANCEL_RESERVATION_START:
      return cancelReservationStart(state, action);
    case actionTypes.CANCEL_RESERVATION_SUCCESS:
      return cancelReservationSuccess(state, action);
    case actionTypes.CANCEL_RESERVATION_FAIL:
      return cancelReservationFail(state, action);
    case actionTypes.CANCEL_RESERVATION_RESET:
      return cancelReservationReset(state, action);

    case actionTypes.UPDATE_CANCELLATION_REASON_START:
      return updateCancellationReasonStart(state, action);
    case actionTypes.UPDATE_CANCELLATION_REASON_SUCCESS:
      return updateCancellationReasonSuccess(state, action);
    case actionTypes.UPDATE_CANCELLATION_REASON_FAIL:
      return updateCancellationReasonFail(state, action);
    case actionTypes.UPDATE_CANCELLATION_REASON_RESET:
      return updateCancellationReasonReset(state, action);

    case actionTypes.EDIT_CONFIRMATION_CODE_START:
      return editConfirmationCodeStart(state, action);
    case actionTypes.EDIT_CONFIRMATION_CODE_SUCCESS:
      return editConfirmationCodeSuccess(state, action);
    case actionTypes.EDIT_CONFIRMATION_CODE_FAIL:
      return editConfirmationCodeFail(state, action);
    case actionTypes.EDIT_CONFIRMATION_CODE_RESET:
      return editConfirmationCodeReset(state, action);

    case actionTypes.UPDATE_NOTES_START:
      return updateReservationNotesStart(state, action);
    case actionTypes.UPDATE_NOTES_SUCCESS:
      return updateReservationNotesSuccess(state, action);
    case actionTypes.UPDATE_NOTES_FAIL:
      return updateReservationNotesFail(state, action);
    case actionTypes.UPDATE_NOTES_RESET:
      return updateReservationNotesReset(state, action);

    case actionTypes.INTEGRATION_HISTORY_START:
      return getIntegrationHistoryStart(state, action);
    case actionTypes.INTEGRATION_HISTORY_SUCCESS:
      return getIntegrationHistorySuccess(state, action);
    case actionTypes.INTEGRATION_HISTORY_FAIL:
      return getIntegrationHistoryFail(state, action);
    case actionTypes.INTEGRATION_HISTORY_RESET:
      return getIntegrationHistoryReset(state, action);

    case actionTypes.UPDATE_INTEG_STATUS_START:
      return updateIntegStatusStart(state, action);
    case actionTypes.UPDATE_INTEG_STATUS_SUCCESS:
      return updateIntegStatusSuccess(state, action);
    case actionTypes.UPDATE_INTEG_STATUS_FAIL:
      return updateIntegStatusFail(state, action);
    case actionTypes.UPDATE_INTEG_STATUS_RESET:
      return updateIntegStatusReset(state, action);

    case actionTypes.ACCEPT_TERMS_CONDITIONS_START:
      return acceptTermsAndConditionsStart(state, action);
    case actionTypes.ACCEPT_TERMS_CONDITIONS_SUCCESS:
      return acceptTermsAndConditionsSuccess(state, action);
    case actionTypes.ACCEPT_TERMS_CONDITIONS_FAIL:
      return acceptTermsAndConditionsFail(state, action);
    case actionTypes.ACCEPT_TERMS_CONDITIONS_RESET:
      return acceptTermsAndConditionsReset(state, action);

    case actionTypes.ACCEPT_DECLINE_RESERVATION_START:
      return doAcceptOrDeclineReservationStart(state, action);
    case actionTypes.ACCEPT_DECLINE_RESERVATION_SUCCESS:
      return doAcceptOrDeclineReservationSuccess(state, action);
    case actionTypes.ACCEPT_DECLINE_RESERVATION_FAIL:
      return doAcceptOrDeclineReservationFail(state, action);
    case actionTypes.ACCEPT_DECLINE_RESERVATION_RESET:
      return doAcceptOrDeclineReservationReset(state, action);

    case actionTypes.UPDATE_RESERVATION_CREATION_TYPE_START:
      return updateReservationCreationTypeStart(state, action);
    case actionTypes.UPDATE_RESERVATION_CREATION_TYPE_SUCCESS:
      return updateReservationCreationTypeSuccess(state, action);
    case actionTypes.UPDATE_RESERVATION_CREATION_TYPE_FAIL:
      return updateReservationCreationTypeFail(state, action);
    case actionTypes.UPDATE_RESERVATION_CREATION_TYPE_RESET:
      return updateReservationCreationTypeReset(state, action);

    case actionTypes.GET_CONTRACT_START:
      return getContractStart(state, action);
    case actionTypes.GET_CONTRACT_SUCCESS:
      return getContractSuccess(state, action);
    case actionTypes.GET_CONTRACT_FAIL:
      return getContractFail(state, action);
    case actionTypes.GET_CONTRACT_RESET:
      return getContractReset(state, action);

    case actionTypes.GET_INVOICE_START:
      return getInvoiceStart(state, action);
    case actionTypes.GET_INVOICE_SUCCESS:
      return getInvoiceSuccess(state, action);
    case actionTypes.GET_INVOICE_FAIL:
      return getInvoiceFail(state, action);
    case actionTypes.GET_INVOICE_RESET:
      return getInvoiceReset(state, action);

    case actionTypes.GET_RESERVATION_SUMMARY_START:
      return getReservationSummaryStart(state, action);
    case actionTypes.GET_RESERVATION_SUMMARY_SUCCESS:
      return getReservationSummarySuccess(state, action);
    case actionTypes.GET_RESERVATION_SUMMARY_FAIL:
      return getReservationSummaryFail(state, action);
    case actionTypes.GET_RESERVATION_SUMMARY_RESET:
      return getReservationSummaryReset(state, action);

    case actionTypes.GET_RESERVATION_VOUCHER_START:
      return getReservationVoucherStart(state);
    case actionTypes.GET_RESERVATION_VOUCHER_SUCCESS:
      return getReservationVoucherSuccess(state, action);
    case actionTypes.GET_RESERVATION_VOUCHER_FAIL:
      return getReservationVoucherFail(state, action);
    case actionTypes.GET_RESERVATION_VOUCHER_RESET:
      return getReservationVoucherReset(state);

    case actionTypes.SEND_MAIL_START:
      return sendMailStart(state, action);
    case actionTypes.SEND_MAIL_SUCCESS:
      return sendMailSuccess(state, action);
    case actionTypes.SEND_MAIL_FAIL:
      return sendMailFail(state, action);
    case actionTypes.SEND_MAIL_RESET:
      return sendMailReset(state, action);

    case actionTypes.UPDATE_RESERVATION_DETAILS_START:
      return updateReservationDetailsStart(state, action);
    case actionTypes.UPDATE_RESERVATION_DETAILS_SUCCESS:
      return updateReservationDetailsSuccess(state, action);
    case actionTypes.UPDATE_RESERVATION_DETAILS_FAIL:
      return updateReservationDetailsFail(state, action);
    case actionTypes.UPDATE_RESERVATION_DETAILS_RESET:
      return updateReservationDetailsReset(state, action);

    default:
      return state;
  }
};
export default reducer;

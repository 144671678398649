import React from 'react';
import { BRIEFCASE_05, FUEL_STATION, USER_GROUP } from '../../../../../shared/AppImages';
import { ModalType } from '../../../../../shared/GlobalVar';
import FeatureAttr from '../feature-attr';

function FeatureAttrContainer({ vehicleClass, setDialogData }) {
    const onShowModal = (e) => {
        setDialogData(
            {
                modalType: ModalType.VEHICLE_ATTR_MODAL,
                modalProps: {
                    showModal: true,
                    isDraggable: true,
                    closeModal: onCloseModal,
                    modalTitle: "More Info : " + vehicleClass?.modelDesc,
                    modaWrapperClass: "vehicle-tag-modal",
                    modalMinWidth: "370px",
                    modalMaxWidth: "370px",
                    modalPosition: { x: e.pageX - 100, y: e.pageY - 100 }
                },
                modalBodyProps: {
                    tags: vehicleClass?.tags
                },
            }
        );
    }
    const onCloseModal = () => {
        setDialogData(null);
    }
    return (
        <div className='feature-attr-container position-relative'>
            <FeatureAttr
                icon={USER_GROUP}
                toolTipId="passenger"
                tooltipData="Passenger"
                count={vehicleClass?.passengerCount}
            />
            <FeatureAttr icon={BRIEFCASE_05}
                toolTipId="luggage"
                tooltipData="Luggage"
                count={vehicleClass?.luggageCount} />
            <FeatureAttr icon={FUEL_STATION}
                toolTipId="fuel"
                tooltipData="Fuel"
                count={vehicleClass?.mpgCity + "/" + vehicleClass?.mpgHighway} />
            <span className='btn-more' onClick={(e) => onShowModal(e)}>See More</span>
        </div>
    )
}

export default FeatureAttrContainer
import moment from "moment";
import React, { useState } from "react";
import ReactHtmlParser from "react-html-parser";
import { Link } from "react-router-dom";
import { getDiscountModalInfo } from "../../../../components/modal/modern-modal-data";
import ModernModalEngine from "../../../../components/modal/modern-modal-engine";
import CommonButton from "../../../../components/UI/button/common-button";
import InfoField from "../../../../components/UI/label/InfoField";
import { CALENDAR_03, CALL, EMAIL, ID_CARD, LOCATION_05, NoUserPhoto, UserProfileLinkIcon } from "../../../../shared/AppImages";
import { COMMON_MSG, ModalType } from "../../../../shared/GlobalVar";
import PermissionName from "../../../../shared/Permissions";
import { hasObjectNonEmptyProperty, hasPermission } from "../../../../shared/utility";
import { getFormattedPhoneNumber } from "../../../../shared/utility/phone-utility";
import { getFormattedAddress_fox } from "../fox-res-helper";

const SummaryCustomerInfoBlock = ({ foxCustomerViewModel, showModal, breadCrumbList, setLoader }) => {
  const {
    profileImageUrl,
    fullName,
    homePhone,
    customerId,
    renterId,
    email,
    primaryAddress,
    dob
  } = foxCustomerViewModel;
  const [modalData, setModalData] = useState("");

  const phoneNumber = hasObjectNonEmptyProperty(homePhone) ? `+${homePhone.countryCode} ${getFormattedPhoneNumber(homePhone.number, homePhone.countryIso)}` : "N/A";
  const phoneNumberTooltip = hasObjectNonEmptyProperty(homePhone) ? `(H) ${getFormattedPhoneNumber(homePhone.number, homePhone.countryIso)} [${homePhone.countryIso}]` : "";
  const address = primaryAddress ? getFormattedAddress_fox(primaryAddress) : null;

  /**Todo:Rokon, will improve the function more later */
  const onShowModal = () => {
    getDiscountModalInfo(
      { customerId: customerId, customerName: fullName },
      setModalData,
      setLoader
    );
  }
  return (
    <div className="res-summary-customer-block">
      <div className="res-customer-items">
        <InfoField
          value={profileImageUrl || NoUserPhoto}
          isImage={true}
          customValueParentClass="profile-image"
        />
        <div className="customer-details-info">
          <div className="customer-name-row">
            <div className="d-flex">
              <InfoField
                value={fullName}
                isImage={false}
                customValueParentClass="customer-name pr-2"
              />
              {customerId && <span id={`icoEmployeeType_${customerId}`} className="flex-align-self-center">
                <Link
                  to={{
                    pathname: "/member/memberProfile",
                    search: `?memberId=${customerId}`,
                    state: {
                      breadCrumbList,
                      breadCrumbTitle: fullName,
                    },
                  }}
                >
                  <img
                    alt="navigation"
                    src={UserProfileLinkIcon}
                    style={{ height: "1.4rem", width: "1.4rem" }}
                    title={COMMON_MSG.UserProfileLinkIconTooltip}
                  />
                </Link>
              </span>}
            </div>

            <div className="res-summary-actions">
              <div className="summary-action-button">
                {customerId &&
                  <>
                    {hasPermission(PermissionName.CC_VIEW_PERSON_FOX_DISCOUNT) ?
                      <CommonButton
                        buttonClasses="btn btn-blue-outline action-button"
                        onClickAction={onShowModal}
                        buttonLabel="Add/Reverse Discount"
                        title="Click to open discount modal"
                        modalParams={{
                          type: ModalType.GRANT_DISCOUNT_MODAL,
                          memberId: customerId,
                        }}
                        customStyle={{ padding: "0.8rem" }}
                      />
                      : ""}
                    <CommonButton
                      buttonClasses="btn btn-blue-outline action-button"
                      onClickAction={showModal}
                      buttonLabel="View Details"
                      title={COMMON_MSG.UserProfileLinkIconTooltip}
                      modalParams={{
                        type: ModalType.MEMBER_QUICK_MODAL,
                        memberId: customerId,
                      }}
                      customStyle={{ padding: "0.8rem" }}
                    />
                  </>
                }
              </div>
            </div>
          </div>
          <div className="customer-other-rows">
            <InfoField
              label="Renter ID"
              value={renterId}
              inputIcon={ID_CARD}
              hasCopyIcon={true}
            />
            <InfoField
              value={phoneNumber}
              inputIcon={CALL}
              tooltip={ReactHtmlParser(phoneNumberTooltip)}
            />
            <InfoField
              label="Email"
              value={email}
              inputIcon={EMAIL}
              hasCopyIcon={true}
            />
            <InfoField
              value={dob ? moment(dob).format("MM/DD/YYYY") : "N/A"}
              inputIcon={CALENDAR_03}
              tooltip={
                dob ? `Date of birth ${moment(dob).format("MM/DD/YYYY")}` : ""
              }
            />
            <InfoField
              value={address}
              isImage={false}
              inputIcon={LOCATION_05}
            />
          </div>
        </div>
      </div>
      {modalData ?
        <ModernModalEngine
          {...modalData}
        />
        : ""}
    </div>
  );
};

export default SummaryCustomerInfoBlock;

import { FoxRequestHeader } from "../domain/fox-request-header";

export const CancelReservationRequest = function (data) {
  this.requestHeader = new FoxRequestHeader({ requestType: "DELETE" });
  if (data.customerInfo) this.customerInfo = data.customerInfo || null;
  if (data.reservationCode) this.reservationCode = data.reservationCode || null;
  if (data.cancelReasons) this.cancelReasons = data.cancelReasons || null;
  if (data.cancelComment) this.cancelComment = data.cancelComment || null;
  this.forceCancel = true;
};

import * as actionTypes from "../actions/actionTypes";
import { reducerUpdateObject } from "./reducer.utility";

const initialState = {
  vehicleCategoryResponse: null,
  updateVehicleResponse: null,
  searchVehicleResponse: null,
  vehicleDetailsResponse: null,
  vehicleFeatureOptionsResponse: null,
  vehicleGroupFeatureOptionsResponse: null,
  vehicleSuggestedPriceResponse: null,
  vehicleFeatureResponse: null,
  updateVehicleFeatureResponse: null,
  markedVehicleOfflineResponse: null,
  updateVehicleModeResponse: null,
  error: null,
  loading: false,
  featureOptionsLoding: false,
  groupFeatureLoding: false,
  suggestedPriceLoding: false,
  deleteVehicleResponse: null,
};

export const getVehicleCategoryInfoStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
export const getVehicleCategoryInfoSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    vehicleCategoryResponse: action.vehicleCategoryResponse,
    error: null,
    loading: false,
  });
};
const getVehicleCategoryInfoFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false,
  });
};
const getVehicleCategoryInfoReset = (state) => {
  return reducerUpdateObject(state, {
    vehicleCategoryResponse: null,
  });
};

export const doVehicleSearchStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
export const doVehicleSearchSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    searchVehicleResponse: action.searchVehicleResponse,
    error: null,
    loading: false,
  });
};
const doVehicleSearchFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false,
  });
};
const doVehicleSearchReset = (state) => {
  return reducerUpdateObject(state, {
    searchVehicleResponse: null,
  });
};
export const getVehicleDetailsStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
export const getVehicleDetailsSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    vehicleDetailsResponse: action.vehicleDetailsResponse,
    error: null,
    loading: false,
  });
};
const getVehicleDetailsFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false,
  });
};
const getVehicleDetailsReset = (state) => {
  return reducerUpdateObject(state, {
    vehicleDetailsResponse: null,
  });
};

export const getVehicleFeatureOptionsStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, featureOptionsLoding: true });
};
export const getVehicleFeatureOptionsSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    vehicleFeatureOptionsResponse: action.vehicleFeatureOptionsResponse,
    error: null,
    featureOptionsLoding: false,
  });
};
const getVehicleFeatureOptionsFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    featureOptionsLoding: false,
  });
};
const getVehicleFeatureOptionsReset = (state) => {
  return reducerUpdateObject(state, {
    vehicleFeatureOptionsResponse: null,
  });
};

export const getSuggestedPriceforVehicleStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, suggestedPriceLoding: true });
};
export const getSuggestedPriceforVehicleSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    vehicleSuggestedPriceResponse: action.vehicleSuggestedPriceResponse,
    error: null,
    suggestedPriceLoding: false,
  });
};
const getSuggestedPriceforVehicleFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    suggestedPriceLoding: false,
  });
};
const getSuggestedPriceforVehicleReset = (state) => {
  return reducerUpdateObject(state, {
    vehicleSuggestedPriceResponse: null,
  });
};

export const getVehicleGroupFeatureOptionsStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, groupFeatureLoding: true });
};
export const getVehicleGroupFeatureOptionsSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    vehicleGroupFeatureOptionsResponse:
      action.vehicleGroupFeatureOptionsResponse,
    error: null,
    groupFeatureLoding: false,
  });
};
const getVehicleGroupFeatureOptionsFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    groupFeatureLoding: false,
  });
};
const getVehicleGroupFeatureOptionsReset = (state) => {
  return reducerUpdateObject(state, {
    vehicleGroupFeatureOptionsResponse: null,
  });
};

export const addOrEditVehicleStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
export const addOrEditVehicleSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    updateVehicleResponse: action.updateVehicleResponse,
    error: null,
    loading: false,
  });
};
const addOrEditVehicleFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false,
  });
};
const addOrEditVehicleReset = (state) => {
  return reducerUpdateObject(state, {
    updateVehicleResponse: null,
  });
};
export const getVehicleFeatureStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
export const getVehicleFeatureSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    vehicleFeatureResponse: action.vehicleFeatureResponse,
    error: null,
    loading: false,
  });
};
const getVehicleFeatureFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false,
  });
};
const getVehicleFeatureReset = (state) => {
  return reducerUpdateObject(state, {
    vehicleFeatureResponse: null,
  });
};

export const updateVehicleFeatureStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
export const updateVehicleFeatureSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    updateVehicleFeatureResponse: action.updateVehicleFeatureResponse,
    error: null,
    loading: false,
  });
};
const updateVehicleFeatureFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false,
  });
};
const updateVehicleFeatureReset = (state) => {
  return reducerUpdateObject(state, {
    updateVehicleFeatureResponse: null,
  });
};

export const markedVehicleOfflineStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
export const markedVehicleOfflineSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    markedVehicleOfflineResponse: action.markedVehicleOfflineResponse,
    error: null,
    loading: false,
  });
};
const markedVehicleOfflineFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false,
  });
};
const markedVehicleOfflineReset = (state) => {
  return reducerUpdateObject(state, {
    markedVehicleOfflineResponse: null,
  });
};

export const updateVehicleModeStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
export const updateVehicleModeSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    updateVehicleModeResponse: action.updateVehicleModeResponse,
    error: null,
    loading: false,
  });
};
const updateVehicleModeFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false,
  });
};
const updateVehicleModeReset = (state) => {
  return reducerUpdateObject(state, {
    updateVehicleModeResponse: null,
  });
};

export const deleteVehicleStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
export const deleteVehicleSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    deleteVehicleResponse: action.deleteVehicleResponse,
    error: null,
    loading: false,
  });
};
const deleteVehicleFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false,
  });
};
const deleteVehicleReset = (state) => {
  return reducerUpdateObject(state, {
    deleteVehicleResponse: null,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_VEHICLE_CATEGORY_START:
      return getVehicleCategoryInfoStart(state, action);
    case actionTypes.GET_VEHICLE_CATEGORY_SUCCESS:
      return getVehicleCategoryInfoSuccess(state, action);
    case actionTypes.GET_VEHICLE_CATEGORY_FAIL:
      return getVehicleCategoryInfoFail(state, action);
    case actionTypes.GET_VEHICLE_CATEGORY_RESET:
      return getVehicleCategoryInfoReset(state);

    case actionTypes.UPDATE_VEHICLE_START:
      return addOrEditVehicleStart(state, action);
    case actionTypes.UPDATE_VEHICLE_SUCCESS:
      return addOrEditVehicleSuccess(state, action);
    case actionTypes.UPDATE_VEHICLE_FAIL:
      return addOrEditVehicleFail(state, action);
    case actionTypes.UPDATE_VEHICLE_RESET:
      return addOrEditVehicleReset(state);

    case actionTypes.SEARCH_VEHICLE_START:
      return doVehicleSearchStart(state, action);
    case actionTypes.SEARCH_VEHICLE_SUCCESS:
      return doVehicleSearchSuccess(state, action);
    case actionTypes.SEARCH_VEHICLE_FAIL:
      return doVehicleSearchFail(state, action);
    case actionTypes.SEARCH_VEHICLE_RESET:
      return doVehicleSearchReset(state);
    case actionTypes.GET_VEHICLE_DETAILS_START:
      return getVehicleDetailsStart(state, action);
    case actionTypes.GET_VEHICLE_DETAILS_SUCCESS:
      return getVehicleDetailsSuccess(state, action);
    case actionTypes.GET_VEHICLE_DETAILS_FAIL:
      return getVehicleDetailsFail(state, action);
    case actionTypes.GET_VEHICLE_DETAILS_RESET:
      return getVehicleDetailsReset(state);

    case actionTypes.GET_VEHICLE_FEATURES_OPTIONS_START:
      return getVehicleFeatureOptionsStart(state, action);
    case actionTypes.GET_VEHICLE_FEATURES_OPTIONS_SUCCESS:
      return getVehicleFeatureOptionsSuccess(state, action);
    case actionTypes.GET_VEHICLE_FEATURES_OPTIONS_FAIL:
      return getVehicleFeatureOptionsFail(state, action);
    case actionTypes.GET_VEHICLE_FEATURES_OPTIONS_RESET:
      return getVehicleFeatureOptionsReset(state);

    case actionTypes.GET_VEHICLE_SUGGESTED_PRICE_START:
      return getSuggestedPriceforVehicleStart(state, action);
    case actionTypes.GET_VEHICLE_SUGGESTED_PRICE_SUCCESS:
      return getSuggestedPriceforVehicleSuccess(state, action);
    case actionTypes.GET_VEHICLE_SUGGESTED_PRICE_FAIL:
      return getSuggestedPriceforVehicleFail(state, action);
    case actionTypes.GET_VEHICLE_SUGGESTED_PRICE_RESET:
      return getSuggestedPriceforVehicleReset(state);

    case actionTypes.GET_VEHICLE_GROUP_FEATURES_START:
      return getVehicleGroupFeatureOptionsStart(state, action);
    case actionTypes.GET_VEHICLE_GROUP_FEATURES_SUCCESS:
      return getVehicleGroupFeatureOptionsSuccess(state, action);
    case actionTypes.GET_VEHICLE_GROUP_FEATURES_FAIL:
      return getVehicleGroupFeatureOptionsFail(state, action);
    case actionTypes.GET_VEHICLE_GROUP_FEATURES_RESET:
      return getVehicleGroupFeatureOptionsReset(state);

    case actionTypes.GET_VEHICLE_FEATURES_START:
      return getVehicleFeatureStart(state, action);
    case actionTypes.GET_VEHICLE_FEATURES_SUCCESS:
      return getVehicleFeatureSuccess(state, action);
    case actionTypes.GET_VEHICLE_FEATURES_FAIL:
      return getVehicleFeatureFail(state, action);
    case actionTypes.GET_VEHICLE_FEATURES_RESET:
      return getVehicleFeatureReset(state);

    case actionTypes.UPDATE_VEHICLE_FEATURES_START:
      return updateVehicleFeatureStart(state, action);
    case actionTypes.UPDATE_VEHICLE_FEATURES_SUCCESS:
      return updateVehicleFeatureSuccess(state, action);
    case actionTypes.UPDATE_VEHICLE_FEATURES_FAIL:
      return updateVehicleFeatureFail(state, action);
    case actionTypes.UPDATE_VEHICLE_FEATURES_RESET:
      return updateVehicleFeatureReset(state);

    case actionTypes.MARK_VEHICLE_OFFLINE_START:
      return markedVehicleOfflineStart(state, action);
    case actionTypes.MARK_VEHICLE_OFFLINE_SUCCESS:
      return markedVehicleOfflineSuccess(state, action);
    case actionTypes.MARK_VEHICLE_OFFLINE_FAIL:
      return markedVehicleOfflineFail(state, action);
    case actionTypes.MARK_VEHICLE_OFFLINE_RESET:
      return markedVehicleOfflineReset(state);

    case actionTypes.UPDATE_VEHICLE_MODE_START:
      return updateVehicleModeStart(state, action);
    case actionTypes.UPDATE_VEHICLE_MODE_SUCCESS:
      return updateVehicleModeSuccess(state, action);
    case actionTypes.UPDATE_VEHICLE_MODE_FAIL:
      return updateVehicleModeFail(state, action);
    case actionTypes.UPDATE_VEHICLE_MODE_RESET:
      return updateVehicleModeReset(state);

    case actionTypes.DELETE_VEHICLE_START:
      return deleteVehicleStart(state, action);
    case actionTypes.DELETE_VEHICLE_SUCCESS:
      return deleteVehicleSuccess(state, action);
    case actionTypes.DELETE_VEHICLE_FAIL:
      return deleteVehicleFail(state, action);
    case actionTypes.DELETE_VEHICLE_RESET:
      return deleteVehicleReset(state);

    default:
      return state;
  }
};
export default reducer;

export const complainList = () => {
  return [
    {
      StatusCode: 0,
      StatusMsg: "Success",
      StatusAdditionalMsg: null,
      StatusUrl: null,
      TotalRecords: 1,
      Complaints: {
        Complain: [
          {
            Complaintid: "1",
            Date: "12:03:2011:05:05",
            Category: "Reservation",
            SubCategory: "JSIRJ 008",
            ProblemType: "Tyres",
            Problem: "Loram ipsum",
            Priority: "High",
            Createdby: "CCR1",
            EstimationTime: "100 Hours",
            ResolutionDate: "10:03:2012:05:05",
            Status: "Close"
          },
          {
            Complaintid: "1",
            Date: "12:03:2011:05:05",
            Category: "Reservation",
            SubCategory: "JSIRJ 008",
            ProblemType: "Tyres",
            Problem: "Loram ipsum",
            Priority: "High",
            Createdby: "CCR1",
            EstimationTime: "100 Hours",
            ResolutionDate: "10:03:2012:05:05",
            Status: "Open"
          },
          {
            Complaintid: "1",
            Date: "12:03:2011:05:05",
            Category: "Reservation",
            SubCategory: "JSIRJ 008",
            ProblemType: "Tyres",
            Problem: "Loram ipsum",
            Priority: "High",
            Createdby: "CCR1",
            EstimationTime: "100 Hours",
            ResolutionDate: "10:03:2012:05:05",
            Status: "Open"
          },
          {
            Complaintid: "1",
            Date: "12:03:2011:05:05",
            Category: "Reservation",
            SubCategory: "JSIRJ 008",
            ProblemType: "Tyres",
            Problem: "Loram ipsum",
            Priority: "High",
            Createdby: "CCR1",
            EstimationTime: "100 Hours",
            ResolutionDate: "10:03:2012:05:05",
            Status: "Close"
          },
          {
            Complaintid: "1",
            Date: "12:03:2011:05:05",
            Category: "Reservation",
            SubCategory: "JSIRJ 008",
            ProblemType: "Tyres",
            Problem: "Loram ipsum",
            Priority: "High",
            Createdby: "CCR1",
            EstimationTime: "100 Hours",
            ResolutionDate: "10:03:2012:05:05",
            Status: "Close"
          }
        ]
      }
    }
  ];
};

import React, { Fragment } from "react";

const OverrideComponent = (props) => {
  return (
    <Fragment>
      <label className={props.overrideItems.sectionHeaderClassName}>
        Overrides
      </label>
      <div className={props.overrideItems.containerClassName}>
        {props.overrideItems.checkBoxItems &&
          props.overrideItems.checkBoxItems.map((checkboxItem, index) => {
            let isVisible = checkboxItem.name !== "OverrideNameValidation";
            if (Object.keys(checkboxItem).length !== 0 && isVisible) {
              return (
                <div
                  className={`icheck-primary overrride-checkBox ${index > 0 ? "mt-2" : ""
                    }`}
                  key={"override" + index}
                >
                  <input
                    type={checkboxItem.type}
                    id={checkboxItem.idKey}
                    name={checkboxItem.name}
                    checked={checkboxItem.value || false}
                    onChange={(event) =>
                      checkboxItem.changed(
                        event,
                        checkboxItem.name,
                        checkboxItem.type
                      )
                    }
                    disabled={checkboxItem.name === "OverrideCreditCardInformation"}
                  />
                  <label htmlFor={checkboxItem.idKey}>
                    <span>{checkboxItem.labeltext}</span>
                  </label>
                </div>
              );
            }
          })}
      </div>
    </Fragment>
  );
};

export default OverrideComponent;

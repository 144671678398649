import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, withRouter } from "react-router-dom";
import Breadcrumbs from "../../../components/breadcrumbs/breadcrumbs";
import DialogEngine from "../../../components/dialog/dialog-engine";
import ModernModalEngine from "../../../components/modal/modern-modal-engine";
import Spinner from "../../../components/UI/Spinner/Spinner";
import { showNotification } from "../../../components/UI/Toaster/Toaster";
import { FoxRequestHeader } from "../../../services/entities/fox-api/domain/fox-request-header";
import { CancelReservationRequest } from "../../../services/entities/fox-api/temp-models/cancel-reservation-request";
import { getCustomerViewModelForReservation } from "../../../services/entities/fox-api/temp-models/customer-form-view-model";
import { GetAddOnsRequest } from "../../../services/entities/fox-api/temp-models/get-addons-request";
import { GetRatesRequest } from "../../../services/entities/fox-api/temp-models/get-rates-request";
import { GetReservationRequest } from "../../../services/entities/fox-api/temp-models/get-reservation-request";
import { PrimaryFormViewModel } from "../../../services/entities/fox-api/temp-models/primary-form-view-model";
import { ReservationRequest } from "../../../services/entities/fox-api/temp-models/reservation-request";
import { invokeServerAPI } from "../../../services/httpService";
import { AttendanceStatus, TOAST_MSG } from "../../../shared/GlobalVar";
import { getPersonwiseAllDiscount } from "../../../shared/json/data";
import { getInfoFromParamQuery, isNullOrEmpty, populateBreadCrumbData } from "../../../shared/utility";
import { DiscountStatus, FoxApiMsgType, PaymentType } from "../../../shared/utility/enum-utility";
import { setActiveMenu } from "../../search/search-utility/search-utility";
import CommonTitle from "../component/common-title";
import ReservationIcon from "../component/reservation-icon";
import { getCustomAddonsForUI, validateCustomerBlock } from "../helper/common-res-helper";
import PricingBlock from "../pricing/pricing-block";
import ResProfile from "../res-profile/res-profile";
import SecurityDepositRate from "../security-deposit/security-deporit-rate";
import ReservationAction from "./action/reservation-action";
import AddonsContainer from "./addons/addons-container";
import CustomerForm from "./customer/customer-form";
import { convertMsgResponse, getErrMsgForCancelReservation } from "./fox-res-helper";
import ReservationNotes from "./notes/reservation-notes";
import "./pricing/fox-res-pricing-table.scss";
import PrimaryForm from "./primary-form/primary-form";
import "./reservation.scss";
import VehicleContainer from "./vehicle/vehicle-container";

export const Reservation = (props) => {
  const history = useHistory();
  const externalCode = getInfoFromParamQuery(
    "externalCode",
    props.location.search
  );
  const selectedMemberId = getInfoFromParamQuery(
    "memberId",
    props.location.search
  );
  const breadCrumbList = props.location.hasOwnProperty("state")
    ? props.location.state && props.location.state.breadCrumbList
    : [];
  const loading = useSelector((state) => state.reservationReducer.loading
    || state.transactionReducer.loading
    || state.locationReducer.loading
    || state.searchPagesReducer.loading);
  const disabled = false;
  const [showLoader, setShowLoader] = useState(false);
  const [showDiscountLoader, setShowDiscountLoader] = useState(false);
  const [showSecurityLoader, setshowSecurityLoader] = useState(false);
  const [reservationResponse, setReservationResponse] = useState(null);
  const [securityDepositRate, setSecurityDepositRate] = useState(null);
  const [confirmSecurityDepositRate, setConfirmSecurityDepositRate] = useState(false);
  const [primaryFormData, setPrimaryFormData] = useState(
    new PrimaryFormViewModel()
  );
  const [primaryData, setPrimaryData] = useState({});
  const [discountList, setDiscountList] = useState([]);
  const [selectedVehicleClass, setSelectedVehicleClass] = useState(null);
  const [currentPayType, setCurrentPayType] = useState(null);
  const [currentRateId, setCurrentRateId] = useState(null);
  const [selectedAddons, setSelectedAddons] = useState([]);
  const [allRentalOffers, setAllRentalOffers] = useState([]);
  const [dbReservationData, setDbReservationData] = useState(null);
  const [specificPricingData, setSpecificPricingData] = useState([]);
  const [allAddons, setAllAddons] = useState([]);
  const [customerOriginalData, setCustomerOriginalData] = useState(getCustomerViewModelForReservation());
  const [customerFormData, setCustomerFormData] = useState(getCustomerViewModelForReservation());
  const [otherData, setOtherData] = useState(null);
  const [renterComments, setRenterComments] = useState(null);
  const [isTestReservation, setIsTestReservation] = useState(false);
  const [selectedReservationData, setSelectedReservationData] = useState(null);
  const [isClassicPricing, setIsClassicPricing] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [modalActionType, setModalActionType] = useState(null);
  const [dialogData, setDialogData] = useState(null);
  const [isChangesMade, setIsChangesMade] = useState(false);
  const [securityDepositError, setSecurityDepositError] = useState(false);

  const clearState = (isHistory) => {
    setReservationResponse(null);
    setPrimaryData({});
    setCurrentRateId(null);
    setDiscountList([]);
    setAllRentalOffers([]);
    setSpecificPricingData(null);
    setSecurityDepositRate(null);
    setConfirmSecurityDepositRate(false);
    setCurrentPayType(null);
    setSecurityDepositError(false);
    setAllAddons([]);
    setPrimaryFormData(new PrimaryFormViewModel());
    setSelectedVehicleClass(null);
    setCustomerOriginalData(getCustomerViewModelForReservation());
    setCustomerFormData(getCustomerViewModelForReservation());
    setSelectedReservationData(null);
    setIsTestReservation(false);
    setDbReservationData(null);
    setOtherData(null);
    setRenterComments(null);
    setModalData(null);
    setDialogData(null);
    if (!isHistory) {
    }
  };

  /**Rokon: need a good eye here (data structure change)*/
  useEffect(() => {
    const unlisten = history.listen((location, action) => {
      if (action === "PUSH" || (isNullOrEmpty(location.search) && action === "REPLACE")) {
        clearState(true);
      }
    });
    return () => {
      unlisten();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  useEffect(() => {
    clearState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (externalCode) {
      onGetReservation(externalCode);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [externalCode]);

  setActiveMenu(props.match.url, dbReservationData?.isRental || false);

  const clearSecurityDepositRate = () => {
    if (securityDepositRate)
      setSecurityDepositRate(null);
  }
  const getSecurityDepositCharges = async (payType, country, postalCode, classCode) => {
    if ((payType === PaymentType.PREPAID || (!payType && currentPayType === PaymentType.PREPAID))
      && primaryData.rentalLocationCode
      && (classCode || selectedVehicleClass?.classCode)
      && (country || customerFormData?.primaryAddress?.country)
      && (postalCode || customerFormData?.primaryAddress?.postalCode)) {
      setshowSecurityLoader(true);
      const req = {
        requestHeader: new FoxRequestHeader(),
        locationCode: primaryData.rentalLocationCode,
        classCode: classCode || selectedVehicleClass.classCode,
        address: {
          country: country || customerFormData?.primaryAddress?.country,
          postalCode: postalCode || customerFormData?.primaryAddress?.postalCode
        }
      }
      const secResponse = await invokeServerAPI("foxapi/securitydeposit", req, true);
      setshowSecurityLoader(false);
      if (secResponse && secResponse?.ccDeposit) {
        setSecurityDepositRate(secResponse?.ccDeposit);
      } else {
        clearSecurityDepositRate();
        showNotification(convertMsgResponse(secResponse));
      }
    } else {
      clearSecurityDepositRate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }
  const onSwitchToNewReservationMode = () => {
    props.history.push(
      "/updateReservation"
    );
  }
  const isValidResponse = (response) => {
    if (!response) {
      return false;
    }
    if (
      response?.message &&
      response?.message?.messageType === FoxApiMsgType.SUCCESS
    ) return true;
    else {
      showNotification(convertMsgResponse(response?.message));
      return false;
    }
  }
  const getRates = async (primaryData, classCode, rateId, paymentType, addonsList) => {
    const rateReq = new GetRatesRequest(
      {
        rentalLocationCode: primaryData.rentalLocationCode,
        returnLocationCode: primaryData.returnLocationCode,
        rentalDateTime: primaryData.rentalDateTime,
        returnDateTime: primaryData.returnDateTime,
        foxDiscountCode: primaryData.foxDiscountCode,
        freeCreditAmountApplied: primaryData.freeCreditAmountApplied,
        discount: primaryData.discount || [],
        addOns: addonsList ? addonsList : [],
        vehicleClassCode: classCode ? classCode : null,
        rateId: rateId ? rateId : null,
        payType: paymentType ? paymentType : PaymentType.BOTH,
      });
    if (customerFormData?.customerId) {
      rateReq.customerInfo = { customerId: customerFormData?.customerId }
    }
    const rateResponse = await invokeServerAPI("foxapi/rates", rateReq, true);

    if (isValidResponse(rateResponse)) {
      return rateResponse;
    }
  };
  const getAllRates = async (primaryData) => {
    setShowLoader(true);
    setPrimaryData(primaryData);
    clearSecurityDepositRate();
    setSpecificPricingData(null);
    setAllRentalOffers([]);
    setAllAddons([]);
    const rateResponse = await getRates(primaryData);
    setShowLoader(false);
    if (rateResponse) {
      setAllRentalOffers([...rateResponse?.rentalOffers]);
      setSelectedReservationData({
        rentalDateTime: primaryFormData?.rentalDateTime,
        returnDateTime: primaryFormData?.returnDateTime,
        rentalLocationId: primaryFormData?.rentalLocationId,
        rentalLocationName: primaryFormData?.rentalLocationName,
        returnLocationId: primaryFormData?.returnLocationId,
        returnLocationName: primaryFormData?.returnLocationName,
      });
      if (selectedVehicleClass?.classCode && currentPayType) {
        const newSelectedOffer = rateResponse?.rentalOffers.find((data) => {
          return data?.vehicleClass[0].classCode === selectedVehicleClass?.classCode;
        });
        setSelectedVehicleClass(null);
        if (newSelectedOffer && newSelectedOffer?.vehicleClass[0].classCode) {
          const newRateId = currentPayType === PaymentType.PREPAID
            ? newSelectedOffer?.rateProduct[0]?.pricing[0]?.rateId
            : newSelectedOffer?.rateProduct[0]?.pricing[1]?.rateId;
          loadAddons(newSelectedOffer?.vehicleClass[0], newRateId, currentPayType, primaryData);
        } else {
          setSelectedVehicleClass(null);
          onSetPrimaryVehicleData();
        }
      }
    } else {
      onSetPrimaryVehicleData();
    }
  }
  const getPricingDataFromRentalOffer = (rentalOffer, foxDiscountData) => {
    const currentPricing = rentalOffer ? { ...rentalOffer?.rateProduct[0]?.pricing[0] } : null;
    if (foxDiscountData) {
      currentPricing.foxDiscount = { ...foxDiscountData };
    }
    return currentPricing;
  }
  const onSetPrimaryVehicleData = (vehicleClass, rateId, paymentType) => {
    setSelectedVehicleClass(vehicleClass || null);
    setCurrentRateId(rateId || null);
    setCurrentPayType(paymentType || null);
    setAllAddons([]);
    setSelectedAddons([]);
  }
  const loadAddons = async (vehicleClass, rateId, paymentType, curPrimaryData, companyAddons) => {
    const getAddOnsReq = new GetAddOnsRequest(
      {
        rentalLocationCode: curPrimaryData?.rentalLocationCode || primaryData.rentalLocationCode,
        returnLocationCode: curPrimaryData?.returnLocationCode || primaryData.returnLocationCode,
        rentalDateTime: curPrimaryData?.rentalDateTime || primaryData.rentalDateTime,
        returnDateTime: curPrimaryData?.returnDateTime || primaryData.returnDateTime,
        vehicleClassCode: vehicleClass?.classCode,
        rateId: rateId,
        payType: paymentType,
      });
    setShowLoader(true);
    clearSecurityDepositRate();
    const oldSelectedAddons = companyAddons && companyAddons.length ? [...companyAddons] : selectedAddons.length ? [...selectedAddons] : [];
    onSetPrimaryVehicleData(vehicleClass, rateId, paymentType);
    const addonsResponse = await invokeServerAPI("foxapi/addons", getAddOnsReq, true);
    if (isValidResponse(addonsResponse)) {
      let commonAddons = [];
      if (oldSelectedAddons.length) {
        commonAddons = oldSelectedAddons.filter(o => addonsResponse?.addOns.some(({ code }) => o.code === code));
      }
      const reqAddons = commonAddons.forEach(function (v) { delete v.isDiscounted; delete v.isDisabled });
      const rateResponse = await getRates(curPrimaryData || primaryData, vehicleClass.classCode, rateId, paymentType, reqAddons);
      setAllAddons(getCustomAddonsForUI(addonsResponse?.addOns, companyAddons));
      if (isValidResponse(rateResponse)) {
        setSpecificPricingData(getPricingDataFromRentalOffer(rateResponse?.rentalOffers[0], rateResponse?.foxDiscount));
        setSelectedAddons(commonAddons);
      }
    }
    if (paymentType === PaymentType.PREPAID)
      getSecurityDepositCharges(paymentType, null, null, vehicleClass?.classCode);
    setShowLoader(false);
  };
  const getRatesWithAddons = async (addonsList) => {
    setShowLoader(true);
    const rateResponse = await getRates(primaryData, selectedVehicleClass?.classCode, currentRateId, currentPayType, addonsList);
    if (isValidResponse(rateResponse)) {
      setSpecificPricingData(getPricingDataFromRentalOffer(rateResponse?.rentalOffers[0], rateResponse?.foxDiscount));
      const addOns = rateResponse?.rentalOffers.length
        ? rateResponse?.rentalOffers[0]?.rateProduct[0]?.pricing[0]?.addOns : null;
      setSelectedAddons(addOns || []);
    }
    setShowLoader(false);
  }
  const getSource = () => {
    let source = "ADMIN_WEB";
    const isTestRes = reservationResponse?.reservationCreationType === "TEST" || isTestReservation ? true : false;
    if (isTestRes) {
      source = source + "_TEST";
    }
    return source;
  }
  const getPrimaryDataFromResResponse = (reservationData) => {
    return new PrimaryFormViewModel({
      rentalLocationCode: reservationData.rentalLocation.locationCode,
      rentalLocationName: reservationData.rentalLocation?.name,
      returnLocationCode: reservationData.returnLocation.locationCode,
      returnLocationName: reservationData.returnLocation?.name,
      rentalDateTime: reservationData.rentalDateTime,
      returnDateTime: reservationData.returnDateTime,
      sameAsRentFromLoaction:
        reservationData.rentalLocation.locationCode ===
          reservationData.returnLocation.locationCode
          ? true
          : false,
      discount: reservationData?.pricing?.discountCode || { discountCode: reservationData?.companyNumber },
      freeCreditAmountApplied: reservationData?.freeCreditAmountApplied,
      foxDiscountCode: reservationData?.pricing?.foxDiscount?.discountCode,
    });
  };
  const getSelectedReservationData = (data) => {
    return {
      allowAdminEdit: data.allowAdminEdit || null,
      reservationId: data.reservationId || null,
      reservationCode: data.reservationCode || null,
      confirmationCode: data.confirmationCode || null,
      externalReservationCode: data.externalReservationCode || null,
      isRental: data.attendanceStatus !== AttendanceStatus.NOW_SHOW
        ? true
        : false,
      customerId: data?.customerInfo?.customerId || null,
      payType: data?.pricing?.type,
      rentalDateTime: data?.rentalDateTime,
      returnDateTime: data?.returnDateTime,
      rentalLocationId: data?.returnDateTime,
      rentalLocationName: data?.rentalLocationName,
      returnLocationId: data?.returnLocationId,
      returnLocationName: data?.returnLocationName,
      reservationStatus: data?.reservationStatus,
      reservationState: data?.reservationState,
      productCode: data?.productCode,
      creationDateTimeDisplayText: data?.creationDateTimeDisplayText,
    }
  }
  const loadCustmerDataFromReservation = async (customerInfo, confirmationCode) => {
    const cusInfo = getCustomerViewModelForReservation(customerInfo);
    setCustomerFormData(cusInfo);
    setCustomerOriginalData(cusInfo);
    setShowDiscountLoader(true);
    const allDiscounts = await getPersonwiseAllDiscount(cusInfo?.customerId, false);
    setShowDiscountLoader(false);
    if (allDiscounts && allDiscounts.length > 0) {
      const tempDiscounts = allDiscounts.filter((data) => data.status === DiscountStatus.GRANTED
        || (data.status === DiscountStatus.USED && data.usedFoxReservationCode === confirmationCode))
      setDiscountList(tempDiscounts);
    }
  }
  const convertResResponseToViewModel = (resResponse) => {
    if (
      resResponse.message &&
      resResponse.message.messageType === "SUCCESS"
    ) {
      let resData = resResponse.reservation;
      const primaryTempData = getPrimaryDataFromResResponse(resData);
      setReservationResponse(resData);
      setRenterComments(resData?.renterComments);
      setPrimaryData(primaryTempData);
      setPrimaryFormData(primaryTempData);
      setDbReservationData({
        primaryData: getPrimaryDataFromResResponse(resData),
        vehicleClass: { ...resData?.vehicleClass },
        pricing: [{ ...resData?.pricing }],
        customerInfo: { ...resData?.customerInfo },
        allowAdminEdit: resData?.allowAdminEdit,
      });
      setSelectedVehicleClass({ ...resData?.vehicleClass });
      setCurrentPayType(resData?.pricing?.type);
      setSpecificPricingData(resData?.pricing);
      if (resData?.pricing?.addOns?.length) {
        setAllAddons(getCustomAddonsForUI(resData?.pricing?.addOns, null, true));
        setSelectedAddons(getCustomAddonsForUI(resData?.pricing?.addOns, null, true));
      }
      setIsTestReservation(resData && resData.reservationCreationType === "TEST");
      setSelectedReservationData(getSelectedReservationData(resData));
      loadCustmerDataFromReservation(resData?.customerInfo, resData?.confirmationCode);
    } else {
      showNotification(convertMsgResponse(resResponse.message));
    }
  }
  const isValidCreateUpdateRequest = () => {
    // let isValidRequest = validateParimaryResData(primaryData, true);
    let isValidRequest = false;
    if (!customerFormData || !selectedVehicleClass) {
      return false;
    }
    if (customerFormData) {
      return validateCustomerBlock(customerFormData, true);
    }
    return isValidRequest;
  };
  const onCreateUpdateReservation = async () => {
    const tempPricing = { ...specificPricingData };
    const reservationReq = {
      requestHeader: new FoxRequestHeader({ requestType: "POST" }),
      reservation: new ReservationRequest(
        primaryData,
        selectedVehicleClass?.classCode,
        tempPricing,
        customerFormData,
        otherData,
        renterComments,
        externalCode,
        getSource()
      )
    };
    setShowLoader(true);
    setModalActionType(false);
    const createUpdateResponse = await invokeServerAPI("foxapi/reservation", reservationReq, true);
    setShowLoader(false);
    if (
      createUpdateResponse.message &&
      createUpdateResponse.message.messageType === "SUCCESS"
    ) {
      if (!createUpdateResponse.reservation || !createUpdateResponse.reservation.confirmationCode) {
        props.history.push("/reservation");
      }
      //setReservationResponse(foxCreateReservationResponse.reservation);
      showNotification(
        convertMsgResponse(createUpdateResponse.message)
      );
      //setReservationDefaultMessage(convertMsgResponse(foxCreateReservationResponse.message))
      let breadCrumbListNew = populateBreadCrumbData(
        breadCrumbList,
        "Reservation",
        createUpdateResponse.reservation?.confirmationCode
      );
      props.history.push(
        "/reservationSummary?reservationId=" +
        createUpdateResponse.reservation?.confirmationCode,
        { breadCrumbList: breadCrumbListNew }
      );
    } else {
      showNotification(
        convertMsgResponse(createUpdateResponse.message)
      );
      //setReservationDefaultMessage(convertMsgResponse(createUpdateResponse.message))
    }
  }
  const onGetReservation = async (reservationCode) => {
    reservationCode = reservationCode
      ? reservationCode
      : selectedReservationData.confirmationCode;
    setShowLoader(true);
    setModalActionType(false);
    const getResReq = new GetReservationRequest(reservationCode || externalCode);
    const resResponse = await invokeServerAPI("foxapi/reservation", getResReq, true);
    convertResResponseToViewModel(resResponse);
    setShowLoader(false);
  };
  const onCancelReservation = () => {
    const req = new CancelReservationRequest({
      customerInfo: {
        customerId: customerFormData.customerId
      },
      reservationCode: selectedReservationData.reservationCode,
    });
    const errorMsg = getErrMsgForCancelReservation(req);
    if (!errorMsg) {
      const cancelResponse = invokeServerAPI("foxapi/reservation", req, true);
      convertResResponseToViewModel(cancelResponse);
    } else {
      showToasterMsg(errorMsg);
    }
  };
  /**To Do: need to move to utility method */
  const onChangesMade = () => {
    if (isChangesMade)
      setIsChangesMade(true);
  }
  const showToasterMsg = (toasterMsg, toasterType) => {
    var toastMsg = {
      msg: toasterMsg,
      toastType: toasterType || TOAST_MSG.ERROR,
    };
    showNotification(toastMsg);
  };

  return (
    <>

      {externalCode && (
        <div
          className={`col-lg-12 breadcrumb row mb-0 pb-0 
            ${externalCode || selectedMemberId ? "mtr-2" : ""}`}
          style={{ alignItems: "baseline" }}
        >
          <Breadcrumbs breadCrumbList={breadCrumbList} title={"Edit"} />
        </div>
      )}
      <div className="reservation-container" style={{ position: "relative" }}>
        {dialogData ?
          <DialogEngine
            {...dialogData}
          />
          : ""}
        {/* <PreventTransitionPrompt when={isBlocking} /> */}
        <div className="res-container-column">
          <CommonTitle title="Reservation" classNames="font-size-2 font-weight-600" />
          <PrimaryForm
            primaryFormData={primaryFormData}
            setPrimaryFormData={setPrimaryFormData}
            showToasterMsg={showToasterMsg}
            getAllRates={getAllRates}
            discountList={discountList}
            storeCredit={customerFormData?.foxCredit || 0}
            onChangesMade={onChangesMade}
            showDiscountLoader={showDiscountLoader}
          />
          {externalCode ?
            <ResProfile
              reservationData={selectedReservationData}
            /> : ""}
        </div>
        <VehicleContainer
          allRentalOffers={allRentalOffers}
          selectedVehicleClass={selectedVehicleClass}
          currentPayType={currentPayType}
          dbReservationData={dbReservationData}
          loadAddons={loadAddons}
          setDialogData={setDialogData}
          onChangesMade={onChangesMade}
        />
        <AddonsContainer
          allAddons={allAddons}
          getRatesWithAddons={getRatesWithAddons}
          selectedAddons={selectedAddons}
          setDialogData={setDialogData}
          onChangesMade={onChangesMade}
        />
        <CustomerForm
          selectedMemberId={selectedMemberId}
          customerFormData={customerFormData}
          setCustomerFormData={setCustomerFormData}
          customerOriginalData={customerOriginalData}
          setCustomerOriginalData={setCustomerOriginalData}
          setDiscountList={setDiscountList}
          getSecurityDepositCharges={getSecurityDepositCharges}
          clearSecurityDepositRate={clearSecurityDepositRate}
          otherData={otherData}
          setOtherData={setOtherData}
          setModalData={setModalData}
          setShowLoader={setShowLoader}
          onChangesMade={onChangesMade}
        />
        <div className="res-container-column ">
          <div className="flex-row gap-1">
            <CommonTitle title="Summary" classNames="font-size-2 font-weight-600" />
            {externalCode ?
              <ReservationIcon
                externalCode={externalCode}
                setModalData={setModalData}
                setShowLoader={setShowLoader}
                dbReservationData={dbReservationData}
                iconClusterType="SUMMARY"
              />
              : ""}
          </div>
          <PricingBlock
            currentPayType={currentPayType}
            pricingData={specificPricingData}
            selectedVehicleClass={selectedVehicleClass}
            isClassicPricing={isClassicPricing}
            setIsClassicPricing={setIsClassicPricing}
          />
          {currentPayType === PaymentType.PREPAID ?
            <SecurityDepositRate
              showSecurityLoader={showSecurityLoader}
              securityDepositRate={securityDepositRate}
              confirmSecurityDepositRate={confirmSecurityDepositRate}
              setConfirmSecurityDepositRate={setConfirmSecurityDepositRate}
              isError={securityDepositError}
            /> : ""}
          {specificPricingData ?
            <>
              <ReservationNotes
                renterComments={renterComments}
                setRenterComments={setRenterComments}
                onChangesMade={onChangesMade}
              />
              <ReservationAction
                externalCode={externalCode}
                isTestReservation={isTestReservation}
                setIsTestReservation={setIsTestReservation}
                reservationData={selectedReservationData}
                onCreateUpdateReservation={onCreateUpdateReservation}
                onGetReservation={onGetReservation}
                onCancelReservation={onCancelReservation}
                onSwitchToNewReservationMode={onSwitchToNewReservationMode}
                isChangesMade={isChangesMade}
                currentPayType={currentPayType}
                confirmSecurityDepositRate={confirmSecurityDepositRate}
                setSecurityDepositError={setSecurityDepositError}
                isValidCreateUpdateRequest={isValidCreateUpdateRequest}
                disabled={disabled}
                totalVehicleClass={allRentalOffers.length}
                customerFormData={customerFormData}
                customerOriginalData={customerOriginalData}
                modalType={modalActionType}
                setModalType={setModalActionType}
              />
            </>
            : ""}
        </div>
        {loading || showLoader ? <Spinner /> : ""}
      </div>
      {modalData ?
        <ModernModalEngine
          {...modalData}
        />
        : ""}
    </>
  );
};
export default withRouter(Reservation);

import React, { Fragment } from "react";
import CommonInputField from "../../../components/UI/Input/common-input-field";
const AmenitiesUI = (props) => {
  const mapAmenitiestoselectedAmenities = () => {
    let amenityFeatureObj = JSON.parse(
      JSON.stringify(props.vehicleGroupFeatureOptions)
    );
    props.selectedAmenities.map((amenityObj, index) => {
      if (amenityObj.Type === "GENERAL") {
        amenityFeatureObj.AmenitiesGeneral.map((amenityInfo, gindex) => {
          if (amenityObj.Id === amenityInfo.Id) {
            amenityFeatureObj.AmenitiesGeneral[gindex].Checked = true;
          }
        });
      } else if (amenityObj.Type === "AUDIO_VIDEO") {
        amenityFeatureObj.AmenitiesAudioVideo.map((amenityInfo, gindex) => {
          if (amenityObj.Id === amenityInfo.Id) {
            amenityFeatureObj.AmenitiesAudioVideo[gindex].Checked = true;
          }
        });
      } else if (amenityObj.Type === "EXTRA") {
        amenityFeatureObj.AmenitiesExtra.map((amenityInfo, gindex) => {
          if (amenityObj.Id === amenityInfo.Id) {
            amenityFeatureObj.AmenitiesExtra[gindex].Checked = true;
          }
        });
      } else if (amenityObj.Type === "SMOKING") {
        amenityFeatureObj.SmokingAllowed = amenityObj.Active;
      } else if (amenityObj.Type === "HANDICAP") {
        if (amenityObj.Value === "HANDICAP_SIGN_LICENSE") {
          amenityFeatureObj.HandicapWheelSignLicense = amenityObj.Active;
        } else if (amenityObj.Value === "HANDICAP_WHEELCHAIR_LOADING") {
          amenityFeatureObj.HandicapWheelChairLoading = amenityObj.Active;
        }
      } else if (amenityObj.Type === "PET_HISTORY") {
        amenityFeatureObj.PetsAllowed = amenityObj.Active;
      }
    });
    return amenityFeatureObj;
  };
  const amenityFeatureObj = mapAmenitiestoselectedAmenities();
  const renderLabelRadioBtnPair = (labelCaption, controlName, Id) => {
    return (
      <div className="form-group row">
        <label className="col-sm-4 col-form-label font-weight-bold">
          {labelCaption}
        </label>
        <div className="col-sm-8">
          <div className="memberInput">
            {getRadioButtons(Id + "Yes", true, "Yes", controlName)}
            {getRadioButtons(Id + "No", false, "No", controlName)}
          </div>
        </div>
      </div>
    );
  };
  const getRadioButtons = (id, value, labelCaption, name) => {
    return (
      <CommonInputField
        elementConfig={{
          id: id,
          type: "radio",
          name: name,
          value: value,
          checked: amenityFeatureObj[name] === value,
          disabled: !props.isEditMode && props.isSubMenu,
        }}
        otherConfig={{
          caption: labelCaption,
        }}
        nodeElement="radio"
        onChange={props.inputAmenitiesChangedHandler}
      />
    );
  };
  const renderCheckbox = (amenityFeatureList, amenityObjType) => {
    return amenityFeatureList.map((amenityObj, index) =>
      ["AUDIO_VIDEO", "EXTRA"].includes(amenityObj.Type) ||
      amenityObjType === "GENERAL" ? (
        <div className="col-lg-6 p-0 pb-2" key={index}>
          {getCheckbox(
            amenityObj.DisplayText,
            amenityObj.Value,
            amenityObj.Checked,
            "Amenities",
            amenityObj,
            !props.isEditMode && props.isSubMenu
          )}
        </div>
      ) : (
        ""
      )
    );
  };
  const getCheckbox = (
    labelCaption,
    id,
    value,
    name,
    checkBoxObject,
    isDisabled
  ) => {
    const disabledAttr = isDisabled ? { disabled: isDisabled } : {};
    return (
      <CommonInputField
        elementConfig={{
          id: id,
          type: "checkbox",
          name: name,
          checked: value,
          ...disabledAttr,
        }}
        otherConfig={{
          caption: labelCaption,
          isHideLabel: true,
          elementType: checkBoxObject,
        }}
        nodeElement="checkbox"
        onChange={props.inputAmenitiesChangedHandler}
      />
    );
  };
  return (
    <div className="formRow">
      <h4 className="form-header text-uppercase  col-lg-12">
        <i className="fas fa-user-circle" />
        Amenities
      </h4>
      <div className="col-lg-4 row">
        <label className="col-lg-12 p-0 m-0" style={{ fontSize: "1.5rem" }}>
          General :
        </label>
        <hr className="col-lg-10 p-0 mt-0" />
        {renderCheckbox(amenityFeatureObj.AmenitiesGeneral, "GENERAL")}
      </div>
      <div className="col-lg-4 row">
        <label className="col-lg-12 p-0" style={{ fontSize: "1.5rem" }}>
          Audio / Video :
        </label>
        <hr className="col-lg-10 p-0 mt-0" />
        {renderCheckbox(amenityFeatureObj.AmenitiesAudioVideo)}
        <label className="col-lg-12 p-0" style={{ fontSize: "1.5rem" }}>
          Extra :
        </label>
        <hr className="col-lg-10 p-0 mt-0" />
        {renderCheckbox(amenityFeatureObj.AmenitiesExtra)}
      </div>
      <div className="col-lg-4 p-0">
        <label className="col-lg-12 p-0" style={{ fontSize: "1.5rem" }}>
          Other :
        </label>
        <hr className="col-lg-10 p-0 mt-0" />
        {renderLabelRadioBtnPair(
          "Smoking Allowed",
          "SmokingAllowed",
          "smokingAllowed"
        )}
        {renderLabelRadioBtnPair("Pets Allowed", "PetsAllowed", "petsAllowed")}
        {renderLabelRadioBtnPair(
          "Handicap",
          "HandicapWheelSignLicense",
          "handicapWheelSignLicense"
        )}
        {renderLabelRadioBtnPair(
          "Wheel Chair",
          "HandicapWheelChairLoading",
          "handicapWheelChairLoading"
        )}
      </div>
    </div>
  );
};
export default AmenitiesUI;

import React, { useState, Fragment, useEffect } from "react";
import { getReportsSubMenu } from "./report-submenu-helper";
import Sidebar from "../../../components/Sidebar/sidebar.jsx";
import SidebarContainer from "../../../components/Sidebar/sidebar-container";
import { setActiveMenu } from "../../../containers/search/search-utility/search-utility";

const ReportSubMenu = (props) => {
  const [isExpandableSidebar, setIsExpandableSidebar] = useState(true);

  const toggleNavbar = () => {
    setIsExpandableSidebar(!isExpandableSidebar);
  };

  setActiveMenu(props.match.url);
  return (
    <Fragment>
      <Sidebar
        subMenu={getReportsSubMenu()}
        isExpandableSidebar={isExpandableSidebar}
        toggleNavbar={toggleNavbar}
      />
      <div
        className={
          isExpandableSidebar ? "active content-wrapper1" : "content-wrapper1"
        }
      >
        <SidebarContainer
          subMenu={getReportsSubMenu()}
          isExpandableSidebar={isExpandableSidebar}
          toggleNavbar={toggleNavbar}
        />
      </div>
    </Fragment>
  );
};
export default ReportSubMenu;

import React, { useEffect, useState, Fragment } from "react";
import { withRouter, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useForm, useFieldArray } from "react-hook-form";
import { getShuttleUICtrl } from "./shuttle-ui-ctrl";
import Breadcrumbs from "../../components/breadcrumbs/breadcrumbs";
import LinkButton from "../../components/UI/button/link-btn";
import {
  activeCurrentLink,
  clearActiveMenu,
  getInfoFromParamQuery,
  hasPermission,
  isNullOrEmpty,
  populateBreadCrumbData,
} from "../../shared/utility";
import PermissionName from "../../shared/Permissions";
import {
  getShuttle,
  getShuttleReset,
  shuttleAddUpdateDelete,
  shuttleAddUpdateDeleteReset,
} from "../../store/actions/shuttle-app-actions";
import WatermarkTextComponent from "../../components/UI/label/watermark-text";
import { COMMON_MSG } from "../../shared/GlobalVar";
import { ShuttleViewModel } from "../../services/entities/shuttle-app/view-model/shuttle-view-model";
import MarkerMap from "../map/marker-map";
import { getShuttleMapInfo } from "./shuttles-helper";
import ShuttleRequest from "../../services/entities/shuttle-app/request/ShuttleRequest";
import { showNotification } from "../../components/UI/Toaster/Toaster";
import { convertMsgResponse } from "../Reservation/fox-reservation/fox-res-helper";
import Spinner from "../../components/UI/Spinner/Spinner";
import { OrgUnit } from "../../services/entities/models/domain/org-unit";
import ConfirmModal from "../../components/common/modal/confirm-modal";
export const Shuttle = (props) => {
  const [shuttleId, setShuttleId] = useState(getInfoFromParamQuery("shuttleId", props.location.search));
  const [shuttleData, setShuttleData] = useState(new ShuttleViewModel());
  const [isEditMode, setIsEditMode] = useState(shuttleId ? false : true);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const dispatch = useDispatch();
  const dependantOrgList = useSelector((state) =>
    state.auth.loggedInUserData.DependantLocations.length > 0
      ? state.auth.loggedInUserData.DependantLocations
      : []
  );
  const loading = useSelector(
    (state) =>
      state.shuttleAPIReducer.loading || state.shuttleOptionReducer.loading
  );
  const shuttleResponse = useSelector(
    (state) => state.shuttleAPIReducer.shuttleResponse
  );
  const shuttleAddUpdateDeleteResponse = useSelector(
    (state) => state.shuttleAPIReducer.shuttleAddUpdateDeleteResponse
  );
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    watch,
    reset,
    resetField
  } = useForm({
    defaultValues: {
      formData: getShuttleUICtrl({}, dependantOrgList),
    },
  });
  const { fields } = useFieldArray({ control, name: "formData" });
  const clearState = (isHistory) => {
    setShuttleData(new ShuttleViewModel());
    reset({
      formData: getShuttleUICtrl({}, dependantOrgList),
    });
    if (isHistory) {
      setIsEditMode(true);
    }
  };
  const resetAllStoreData = () => {
    dispatch(getShuttleReset());
    dispatch(shuttleAddUpdateDeleteReset());
  };
  const history = useHistory();
  useEffect(() => {
    const unlisten = history.listen(() => {
      resetAllStoreData();
      clearState(true);
    });
    return () => {
      unlisten();
    };
  }, [history]);
  useEffect(() => {
    let shuttleId =  getInfoFromParamQuery("shuttleId", props.location.search)
    if (!shuttleId) {
      Object.keys(shuttleData).map((key, index2) => {
        if(key === "location"){
          resetField("locationCode");
        }
        resetField(key);
      });
    }
  }, [props.location.search]);

  useEffect(() => {
    if (shuttleId) {
      const shuttleReq = new ShuttleRequest({ shuttleId: shuttleId });
      dispatch(getShuttleReset());
      dispatch(getShuttle(shuttleReq));
    }
    clearActiveMenu();
    activeCurrentLink("shuttle-menu");
  }, []);
  useEffect(() => {
    if (!shuttleResponse) {
      return;
    }
    if (
      shuttleResponse.message &&
      shuttleResponse.message.messageType === "SUCCESS" &&
      shuttleResponse.shuttle
    ) {
      setShuttleData(new ShuttleViewModel(shuttleResponse.shuttle));
      const data = getShuttleUICtrl(
        new ShuttleViewModel(shuttleResponse.shuttle),
        dependantOrgList
      );
      reset({
        formData: data,
      });
    } else {
      showNotification(convertMsgResponse(shuttleResponse.message));
    }
    dispatch(getShuttleReset());
  }, [shuttleResponse]);
  useEffect(() => {
    if (!shuttleAddUpdateDeleteResponse) {
      return;
    }
    if (
      shuttleAddUpdateDeleteResponse.message &&
      shuttleAddUpdateDeleteResponse.message.messageType === "SUCCESS" &&
      shuttleAddUpdateDeleteResponse.shuttle
    ) {
      showNotification(
        convertMsgResponse(shuttleAddUpdateDeleteResponse.message)
      );
      if (shuttleAddUpdateDeleteResponse.message.messageCode === 23004) {
        props.history.push("/shuttles");
      } else if ([23002,23003].includes(shuttleAddUpdateDeleteResponse.message.messageCode)) {
        setIsEditMode(false);
        let breadCrumbListNew = populateBreadCrumbData(
          [],
          "Shuttles",
          shuttleAddUpdateDeleteResponse.shuttle.shuttleCode
        );
        setShuttleData(
          new ShuttleViewModel(shuttleAddUpdateDeleteResponse.shuttle)
        );
        setShuttleId(shuttleAddUpdateDeleteResponse.shuttle.shuttleId);
        const data = getShuttleUICtrl(
          new ShuttleViewModel(shuttleAddUpdateDeleteResponse.shuttle),
          dependantOrgList
        );
        reset({
          formData: data,
        });
        // props.history.push(
        //   "/shuttle?shuttleId=" +
        //     shuttleAddUpdateDeleteResponse.shuttle.shuttleId,
        //   { breadCrumbList: breadCrumbListNew }
        // );
      } else {
        setShuttleData(
          new ShuttleViewModel(shuttleAddUpdateDeleteResponse.shuttle)
        );
        const data = getShuttleUICtrl(
          new ShuttleViewModel(shuttleAddUpdateDeleteResponse.shuttle),
          dependantOrgList
        );
        reset({
          formData: data,
        });
      }
    } else {
      showNotification(
        convertMsgResponse(shuttleAddUpdateDeleteResponse.message)
      );
    }
    dispatch(shuttleAddUpdateDeleteReset());
  }, [shuttleAddUpdateDeleteResponse]);
  const renderInputField = (fieldObj, value) => {
    const input = fieldObj.editConfig || fieldObj.editConfig;
    switch (input.type) {
      case "textarea":
        return (
          <textarea
            key={"textarea" + value}
            {...register(input.name, { required: input.required })}
            aria-invalid={errors[input.name] ? "true" : "false"}
            rows={input.rows || "20"}
            defaultValue={fieldObj.value}
          />
        );
      case "select":
        return (
          <select
            key={"select" + value}
            {...register(input.name, { required: input.required })}
            aria-invalid={errors[input.name] ? "true" : "false"}
            defaultValue={fieldObj.value}
          >
            {input.options.map((option, i) => (
              <option
                value={option.key}
                otherid={option.otherId}
                locationid={option.locationId}
                key={input.name + i}
              >
                {option.displayValue}
              </option>
            ))}
          </select>
        );
      case "radiolist":
        return input.options.map((radio, i) => {
          return (
            <div className={`icheck-primary`} key={input.name + i}>
              <input
                type="radio"
                {...register(input.name, { required: input.required })}
                aria-invalid={errors[input.name] ? "true" : "false"}
                value={radio.key}
                id={radio.id}
                defaultChecked={
                  [fieldObj.value].includes(radio.key) ? true : false
                }
              />
              <label htmlFor={radio.id}>
                <span>{radio.value}</span>
              </label>
            </div>
          );
        });
      case "checkboxlist":
        return input.options.map((cbox, i) => {
          return (
            <div className={`icheck-primary`} key={input.name + i}>
              <input
                type="checkbox"
                {...register(input.name, { required: input.required })}
                aria-invalid={errors[input.name] ? "true" : "false"}
                value={cbox.key}
                id={cbox.id}
                defaultChecked={
                  fieldObj.value.includes(cbox.key) ? true : false
                }
              />
              <label htmlFor={cbox.id}>
                <span>{cbox.value}</span>
              </label>
            </div>
          );
        });
      default:
        return (
          <Fragment>
            <input
              key={"text" + value}
              {...register(input.name, {
                required: input.required, maxLength: input.maxLength, minLength: input.minLength
              })}
              aria-invalid={errors[input.name] ? "true" : "false"}
              type={input.type}
              defaultValue={fieldObj.value}
              maxLength={input.maxLength}
              minLength={input.minLength}
              min={input.min}
            />
          </Fragment>
        );
    }
  };
  const renderShuttleForm = (type) => {
    const shuttleBlock = fields.filter((info) => info.type === type);
    if (
      shuttleBlock[0] &&
      shuttleBlock[0].fields &&
      shuttleBlock[0].fields.length > 0
    ) {
      return shuttleBlock[0].fields.map((field, i) => {
        return (
          <div
          className={`form-group row ${
            field.editConfig.required && isEditMode ? "required" : ""
          }`}
            key={field.editConfig.name + i}
            style={field.style ? field.style : {}}
          >
            <label
              htmlFor={field.editConfig.name}
              className="col-sm-4 col-form-label font-weight-bold"
            >
              {field.titleLabel.text}
            </label>
            <div className={`col-sm-8 row`}>
              {isEditMode ? (
                renderInputField(field, i, type)
              ) : (
                <label className="">{field.detailLabel.value}</label>
              )}
            </div>
          </div>
        );
      });
    }
  };
  const closeConfirmModal = () => {
    setShowConfirmModal(false);
  };
  const onEdit = () => {
    setIsEditMode(true);
  };
  const onCancel = () => {
    setIsEditMode(false);
  };
  const onShowConfrimeModal = () => {
    setShowConfirmModal(true);
  };
  const onDelete = () => {
    if (isNullOrEmpty(shuttleData.shuttleId)) {
      return false;
    }
    setShowConfirmModal(false);
    const addUpdateReq = new ShuttleRequest(
      { shuttleId: shuttleData.shuttleId },
      "DELETE"
    );
    dispatch(shuttleAddUpdateDeleteReset(addUpdateReq));
    dispatch(shuttleAddUpdateDelete(addUpdateReq));
  };
  const onSubmit = (data) => {
    delete data["formData"];
    data.shuttleOrgUnit = new OrgUnit({ locationCode: data.locationCode });
    delete data["locationCode"];
    if (shuttleData.shuttleId) {
      data.shuttleId = shuttleData.shuttleId;
    }
    const addUpdateReq = new ShuttleRequest(data, "POST");
    dispatch(shuttleAddUpdateDeleteReset(addUpdateReq));
    dispatch(shuttleAddUpdateDelete(addUpdateReq));
  };
  return (
    <Fragment>
      <div
        className="col-lg-12 breadcrumb row mb-0 pb-0"
        style={{ alignItems: "baseline" }}
      >
        {!shuttleId && !shuttleData.shuttleCode ? (
          <h4
            className="p-0 m-0"
            style={{
              color: "var(--color-primary)",
            }}
          >
            New Shuttle
          </h4>
        ) : props.location.state ? (
          props.location.state.breadCrumbList ? (
            <Breadcrumbs
              breadCrumbList={props.location.state.breadCrumbList}
              title={
                shuttleData && shuttleData.shuttleCode
                  ? shuttleData.shuttleCode
                  : ""
              }
            />
          ) : (
            ""
          )
        ) : (
          ""
        )}

        <div className="flex-1">
          <WatermarkTextComponent
            className="watermark d-block"
            values={
              !shuttleId && !shuttleData.shuttleCode
                ? COMMON_MSG.SHUTTLE_NEW
                : isEditMode
                ? COMMON_MSG.SHUTTLE_EDIT
                : COMMON_MSG.SHUTTLE_DETAILS
            }
            styles={{
              color: "darkred",
              opacity: "0.45",
            }}
          />
        </div>
      </div>
      <div className="col-lg-12 p-0 summary-form-header row pb-1 pt-1 mb-3">
        <div className="col-lg-8 p-0 row">
          <div className="row">
            <span
              className="font-weight-bold"
              style={{
                color:
                  shuttleId || shuttleData.shuttleCode
                    ? "var(--color-primary)"
                    : "var(--color-grey-primary)",
              }}
            >
              Shuttle Code:
            </span>
            <span className="pl-2" style={{ position: "unset" }}>
              <label style={{ display: "inline" }}>
                {shuttleData && shuttleData.shuttleCode
                  ? shuttleData.shuttleCode
                  : "--"}
              </label>
            </span>
          </div>
          <div className="row ml-4">
            <span
              className="font-weight-bold"
              style={{
                color:
                  shuttleId || shuttleData.shuttleCode
                    ? "var(--color-primary)"
                    : "var(--color-grey-primary)",
              }}
            >
              Last Engine Status:
            </span>
            <span className="pl-2" style={{ position: "unset" }}>
              <label style={{ display: "inline" }}>
                {shuttleData ? shuttleData.lastEngineStatusDisplayValue : "--"}
              </label>
            </span>
          </div>
        </div>
        {shuttleData && shuttleData.shuttleCode ? (
          <div
            className="col-lg-4 p-0 row"
            style={{ justifyContent: "flex-end" }}
          >
            {shuttleId && hasPermission(PermissionName.CC_EDIT_SHUTTLE) && !isEditMode ? (
              <LinkButton
                btnLabel="Edit"
                containerClass="pseudo-link mr-3 fs-2 font-weight-bold d-flex align-items-center"
                stackClass="fa-stack fs-08 vertical-align-top"
                faIconList={[
                  "fa fa-circle fa-stack-2x iconBeforeLink",
                  "fa fa-pencil-alt fa-stack-1x whiteColor m-0 mtr-01 mlr-01",
                ]}
                clicked={onEdit}
              />
            ) : (
              ""
            )}
            {shuttleId && hasPermission(PermissionName.CC_DELETE_SHUTTLE) ? (
              <LinkButton
                btnLabel="Delete"
                containerClass="pseudo-link fs-2 font-weight-bold d-flex align-items-center"
                stackClass="fa-stack fs-08 vertical-align-top"
                faIconList={[
                  "fa fa-circle fa-stack-2x iconBeforeLink",
                  "fas fa-power-off fa-stack-1x whiteColor m-0 mtr-01 mlr-01",
                ]}
                clicked={onShowConfrimeModal}
              />
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="row col-lg-12 p-0 m-o">
        <div className="col-lg-3 pl-0 ml-o">
          <div className="card">
            <div className="card-body" style={{ height: "50rem" }}>
              <h4
                className="form-header text-uppercase"
                style={{
                  color:
                    shuttleId || shuttleData.shuttleCode
                      ? "var(--color-primary)"
                      : "var(--color-grey-primary)",
                  borderBottom: shuttleId
                    ? "0.1rem solid var(--color-primary)"
                    : "0.1rem solid var(--color-grey-primary)",
                }}
              >
                Shuttle Position :
              </h4>
              {shuttleData.shuttleCode ? (
                <MarkerMap
                  mapData={getShuttleMapInfo([{ ...shuttleData }])}
                  defaultPosition={[
                    shuttleData.latitude || shuttleData.location.latitude,
                    shuttleData.longitude || shuttleData.location.longitude,
                  ]}
                  zoom={12}
                  style={{ height: "44rem" }}
                  zoomForDetailsSection={13}
                />
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <div className="col-lg-9 pr-0 mr-o">
          <div className="card">
            <div className="card-body" style={{ height: "50rem" }}>
              <h4 className="form-header text-uppercase">Shuttle Info :</h4>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div
                  id="shuttle-page"
                  className={`row ${isEditMode ? " background-edit" : ""}`}
                >
                  <div
                    className="col-lg-6 p-0"
                    style={{ borderRight: "1px solid gray" }}
                  >
                    {renderShuttleForm("SHUTTLE_LEFTBAR")}
                  </div>
                  <div className="col-lg-6">
                    {renderShuttleForm("SHUTTLE_RIGHTBAR")}
                  </div>
                </div>
                {isEditMode ? (
                  <div
                    className="settingFooter"
                    style={{ textAlign: "center", margin: "2rem" }}
                  >
                    <button type="submit" className="btn btn-primary">
                      {shuttleData.shuttleId ? "Update" : "Add"}
                    </button>
                    {shuttleData && shuttleData.shuttleCode ? (
                      <button
                        type="button"
                        name="className"
                        className="btn btn-secondary ml-2"
                        onClick={() => onCancel()}
                      >
                        Cancel
                      </button>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  ""
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
      {showConfirmModal ? (
        <ConfirmModal
          body={COMMON_MSG.DeleteRecordMsg}
          showModal={showConfirmModal}
          closeModal={closeConfirmModal}
          doConfirmFunctionality={onDelete}
        />
      ) : (
        ""
      )}
      {loading ? <Spinner /> : ""}
    </Fragment>
  );
};
export default withRouter(Shuttle);

import axios from "axios";
import {
  SECRET_KEY,
  SERVER_API_URL,
  ZIP_CODE_STACK_API_KEY,
  ZIP_CODE_STACK_API_URL,
} from "../../config/config";
import { showNotification } from "../components/UI/Toaster/Toaster";
import { complainList } from "../containers/report/details/report-problem-data";
import * as global from "../shared/GlobalVar";
import { dataStoe } from "../shared/utility/cache-utility";
import { FoxRequestHeader } from "./entities/fox-api/domain/fox-request-header";

const HttpService = {
  getCommonDataForRequest: function () {
    return {
      Version: global.VERSION,
      RType: global.R_TYPE,
      TokenId: localStorage.getItem("token"),
      UserId: Number(localStorage.getItem("loggedInUserId")) || null,
      OrgUnitId: Number(localStorage.getItem("currentOrgUnitId")) || null,
    };
  },
  getImpersonateLoginTokenRequest: function () {
    return {
      TokenRequestInfo: { SecretKey: SECRET_KEY },
      ...HttpService.getCommonDataForRequest(),
    };
  },
  isResponseOk: function (response) {
    if (
      response.StatusCode === 100 ||
      response.StatusCode === 101 ||
      response.StatusCode === 102 ||
      response.StatusCode === 105 ||
      response.StatusCode === 100 ||
      //response.StatusCode === 450 ||
      response.code === 500
    ) {
      window.location = "/logout";
      localStorage.setItem("token", "");
      localStorage.setItem("loggedInUserId", "");
      var toastMsg = {
        msg: response.data.StatusMsg,
        toastType: global.TOAST_MSG.ERROR,
      };
      showNotification(toastMsg);
    } else {
      return true;
    }
  },
  getTokenInfo: function () {
    const getTokenReq = {
      SecretKey: SECRET_KEY,
      Version: global.VERSION,
      RType: global.R_TYPE,
    };
    return invokeServerAPI("cctoken", getTokenReq, true);
  },
  doLogin: function (loginReq) {
    return invokeServerAPI("cclogin", loginReq, true);
  },
  doAutoLogin: function (autoLoginReq) {
    return invokeServerAPI("autologin", autoLoginReq, true);
  },
  doImpersonateLogin: function (memberId, password) {
    const impersonateLoginReq = {
      MemberId: memberId,
      Password: password,
      ...HttpService.getImpersonateLoginTokenRequest(),
    };
    return invokeServerAPI("impersonatelogin", impersonateLoginReq, true);
  },
  doImpersonateExit: function () {
    const impersonateExitReq = {
      ...HttpService.getImpersonateLoginTokenRequest(),
    };
    return invokeServerAPI("impersonateexit", impersonateExitReq, true);
  },
  getOrgDetails: function (orgId) {
    const getOrgReq = { ...HttpService.getCommonDataForRequest() };
    getOrgReq.OrgUnitId = orgId;
    return invokeServerAPI("orgunitdetails", getOrgReq, true);
  },
  uploadFile: function (upFileReq) {
    return invokeServerAPI("uploadfile", upFileReq);
  },
  doMemberSearch: function (membersearchObj) {
    return invokeServerAPI("membersearch", membersearchObj);
  },
  getAgentList: function (searchAgentReq) {
    return invokeServerAPI("searchagent", searchAgentReq);
  },
  doVehicleSearch: function (vehicleSearchObj) {
    return invokeServerAPI("searchassets", vehicleSearchObj);
  },
  getVehicleDetails: function (req) {
    return invokeServerAPI("assetdetails", req);
  },
  getVehicleFeatureOptions: function (req) {
    return invokeServerAPI("personassetfeatureoptions", req);
  },
  getVehicleGroupFeatureOptions: function (req) {
    return invokeServerAPI("assetgroupfeatures", req);
  },
  getSuggestedPriceforVehicle: function (req) {
    return invokeServerAPI("showsuggestedPrice", req);
  },
  addVehicle: function (req) {
    return invokeServerAPI("addpersonasset", req);
  },
  updateVehicle: function (req) {
    return invokeServerAPI("editpersonassetinfo", req);
  },
  getVehicleAvailability: function (req) {
    return invokeServerAPI("getavailability", req);
  },
  updateVehicleAvailability: function (req) {
    return invokeServerAPI("editvehicleavailability", req);
  },
  doRolesPermissionMemberSearch: function (req) {
    return invokeServerAPI("rolespermissionsmembersearch", req);
  },

  getPersonAssetInOutSummary: function (personAssetInOutSummarySearchObj) {
    return invokeServerAPI(
      "personassetinoutsummary",
      personAssetInOutSummarySearchObj
    );
  },
  doMemberQuickSearch: function (memberQuickSearchObj) {
    return invokeServerAPI("getmember", memberQuickSearchObj);
  },
  getMember: function (memberReq) {
    return invokeServerAPI("getmember", memberReq);
  },
  doNewMemberRegistration: function (newregistrationobj) {
    return invokeServerAPI("newregistration", newregistrationobj);
  },
  doMemberUpdate: function (editMemberObj) {
    return invokeServerAPI("editprofile", editMemberObj);
  },
  getInvoiceInformation: function (invoiceInfoPopupObj) {
    return invokeServerAPI("invoiceinformation", invoiceInfoPopupObj);
  },

  doMainSearch: function (globalSearchRequestObj) {
    return invokeServerAPI("globalsearch", globalSearchRequestObj);
  },
  getEnumvalues: function (getEnumValuesRequestObj) {
    return invokeServerAPI("getenumvalues", getEnumValuesRequestObj);
  },

  doCreditHistorySearch: function (creditHistoryRequestObj) {
    return invokeServerAPI("getcreditgranthistory", creditHistoryRequestObj);
  },
  reverseFreeCreditFromMemberAccount: function (creditHistoryRequestObj) {
    return invokeServerAPI("revertfreecredit", creditHistoryRequestObj);
  },
  addFreeCredittoMemberAccount: function (creditHistoryRequestObj) {
    return invokeServerAPI("addfreecredit", creditHistoryRequestObj);
  },
  getCountries: function (countryRequest) {
    return invokeServerAPI("getsetting", countryRequest);
  },
  getStatesCitiesPostals: function (stateCityPostalRequest) {
    return invokeServerAPI("getstatecitylist", stateCityPostalRequest);
  },
  getServiceTaskInfos: function (serviceAgentReportRequestObj) {
    return invokeServerAPI("searchtask", serviceAgentReportRequestObj);
  },

  changeTemporaryPassword: function (dataObj) {
    return invokeServerAPI("changetemporarypassword", dataObj);
  },

  resetPassword: function (dataObj) {
    return invokeServerAPI("resetpassword", dataObj);
  },

  reactivateMember: function (dataObj) {
    return invokeServerAPI("reactivatemember", dataObj);
  },

  cancelMembership: function (dataObj) {
    return invokeServerAPI("cancelmembership", dataObj);
  },

  addViolationPoint: function (dataObj) {
    return invokeServerAPI("addviolationpoint", dataObj);
  },
  allowTemporaryLicenseImageUpload: function (dataObj) {
    return invokeServerAPI("updatetemplicenseuploadallowed", dataObj);
  },

  getUploadedDocInfoList: function (getUploadedDocListReq) {
    return invokeServerAPI("getuploadedDoc", getUploadedDocListReq);
  },
  getAllRoles: function (viewroleReq) {
    return invokeServerAPI("viewrole", viewroleReq);
  },
  assignPermissions: function (assignPermissionsReq) {
    return invokeServerAPI("assignpermission", assignPermissionsReq);
  },
  assignRolestoMember: function (assignRolestoMemberReq) {
    return invokeServerAPI("assignrole", assignRolestoMemberReq);
  },
  updateAppAccess: function (updateAppAccessReq) {
    return invokeServerAPI("updateloginaccess", updateAppAccessReq);
  },
  redirectLogoutPage: function () {
    localStorage.setItem("token", "");
    localStorage.setItem("loggedInUserId", "");
    window.location = "/logout";
  },
  verifyMemberAccount: function (dataObj) {
    return invokeServerAPI("confirmmemberprofile", dataObj);
  },

  blockMemberProfile: function (dataObj) {
    return invokeServerAPI("lockingprofile", dataObj);
  },

  unBlockMemberProfile: function (dataObj) {
    return invokeServerAPI("unlockingprofile", dataObj);
  },

  updateNotes: function (dataObj) {
    return invokeServerAPI("updatenotes", dataObj);
  },

  getVehicleCategoryInfo: function (dataObj) {
    return invokeServerAPI("getcategoryview", dataObj);
  },

  deleteVehicle: function (dataObj) {
    return invokeServerAPI("deletepersonasset", dataObj);
  },

  addNewOrgLocationSettings: function (dataObj) {
    return invokeServerAPI("createlocation", dataObj);
  },

  updateOrgLocationSettings: function (dataObj) {
    return invokeServerAPI("updatelocationsettings", dataObj);
  },

  getOrgLocationSettings: function (dataObj) {
    return invokeServerAPI("getlocationsettings", dataObj);
  },

  getOrgPermissionsSettingsData: function (dataObj) {
    return invokeServerAPI("viewpermission", dataObj);
  },

  editPermission: function (dataObj) {
    return invokeServerAPI("editpermission", dataObj);
  },

  getOrgRolesData: function (dataObj) {
    return invokeServerAPI("viewrole", dataObj);
  },

  getHoursOfOperationData: function (dataObj) {
    return invokeServerAPI("retrievehoursofoperations", dataObj);
  },

  saveHoursOfOperations: function (dataObj) {
    return invokeServerAPI("savehoursofoperations", dataObj);
  },

  getTaxesAndSurchargesSettingsWindow: function (dataObj) {
    return invokeServerAPI("getorgtaxsettings", dataObj);
  },

  updateTaxSurchargesSettings: function (dataObj) {
    return invokeServerAPI("updateorgtaxsettings", dataObj);
  },

  getOrgQualificationSettingsData: function (dataObj) {
    return invokeServerAPI("getqualificationsettings", dataObj);
  },

  saveQualificationSettings: function (dataObj) {
    return invokeServerAPI("updatequalificationsettings", dataObj);
  },

  getOrgReservationSettingsData: function (dataObj) {
    return invokeServerAPI("getreservationsettings", dataObj);
  },

  saveReservationSettings: function (dataObj) {
    return invokeServerAPI("updatereservationsettings", dataObj);
  },

  getAllAdditionalOptions: function (dataObj) {
    return invokeServerAPI("getaddonssettings", dataObj);
  },

  updateAdditionalOptionsSettings: function (dataObj) {
    return invokeServerAPI("updateaddonssettings", dataObj);
  },

  getOrgRateSettingsData: function (dataObj) {
    return invokeServerAPI("getcategorysettings", dataObj);
  },

  saveRateSettings: function (dataObj) {
    return invokeServerAPI("updatecategorysettings", dataObj);
  },

  getCapacityBucketInfo: function (dataObj) {
    return invokeServerAPI("getcapacitybuckets", dataObj);
  },

  saveCapacityBucketInfo: function (dataObj) {
    return invokeServerAPI("savecapacitybuckets", dataObj);
  },

  getInsuranceSettings: function (dataObj) {
    return invokeServerAPI("getinsurancesettings", dataObj);
  },
  updateInsuranceSettings: function (dataObj) {
    return invokeServerAPI("updateinsurancesettings", dataObj);
  },

  getOrgVehicleAvailabilitySettingsData: function (dataObj) {
    return invokeServerAPI("getorgavailabilityconstraint", dataObj);
  },

  saveOrgVehicleAvailabilitySettingsData: function (dataObj) {
    return invokeServerAPI("updateorgavailabilityconstraint", dataObj);
  },
  updateUserInformation: function (dataObj) {
    return invokeServerAPI("editccrprofile", dataObj);
  },
  getAvailibilitySummaryInFleet: function () {
    return invokeServerAPI("availabilitysummaryinfleet", {});
  },
  getFleetUtilizationSummary: function (dataObj) {
    return invokeServerAPI("fleetutilizationsummary", dataObj);
  },

  addRole: function (dataObj) {
    return invokeServerAPI("addrole", dataObj);
  },

  editRole: function (dataObj) {
    return invokeServerAPI("editrole", dataObj);
  },

  deleteRole: function (dataObj) {
    return invokeServerAPI("deleterole", dataObj);
  },
  getReservationCharges: function (getreservationchargesReq) {
    return invokeServerAPI("getreservationcharges", getreservationchargesReq);
  },
  getEndRentalCharges: function (getEndRentalChargesReq) {
    return invokeServerAPI("getendrentalcharges", getEndRentalChargesReq);
  },
  getLocationBasedSettings: function (getLocationBasedSettingsReq) {
    return invokeServerAPI(
      "getlocationbasedsettings",
      getLocationBasedSettingsReq
    );
  },
  manageReservation: function (manageReservationReq) {
    return invokeServerAPI("managereservation", manageReservationReq);
  },
  calculateEndRentalPricing: function (endRentalReq) {
    return invokeServerAPI("getendrentalcharges", endRentalReq);
  },
  endRental: function (endrentalReq) {
    return invokeServerAPI("endrental", endrentalReq);
  },
  editEndRental: function (editEndRentalReq) {
    return invokeServerAPI("editendrental", editEndRentalReq);
  },

  getPassword: function (forgotUserNameObj) {
    return invokeServerAPI("forgotpassword", forgotUserNameObj, true);
  },
  addFeedBack: function (dataObj) {
    return invokeServerAPI("addfeedback", dataObj);
  },

  getFavouriteAssetList: function (dataObj) {
    return invokeServerAPI("favoritassetlist", dataObj);
  },

  getMessagesList: function (dataObj) {
    return invokeServerAPI("getmessages", dataObj);
  },
  getComplainList: function () {
    return complainList();
  },
  getMemberVehicleList: function (dataObj) {
    return invokeServerAPI("assetforowner", dataObj);
  },
  getVehicleFeatures: function (dataObj) {
    return invokeServerAPI("getassetfeatures", dataObj);
  },
  updateVehicleFeatures: function (dataObj) {
    return invokeServerAPI("updateassetfeatures", dataObj);
  },
  markedVehicleOffline: function (dataObj) {
    return invokeServerAPI("markvehicleonline", dataObj);
  },
  updateVehicleMode: function (dataObj) {
    return invokeServerAPI("updateassetmode", dataObj);
  },
};
export const getGrantDiscountReasons = async () => {
  const req = {};
  req.requestHeader = new FoxRequestHeader({ requestType: "GET" });
  req.grantPersonFoxDiscountReasonTypes = true;
  req.revertPersonFoxDiscountReasonTypes = true;
  const response = await invokeServerAPI("admin/options", req, true);
  if (response?.message?.messageType === "SUCCESS") {
    return {
      discountReasons: [...response?.grantPersonFoxDiscountReasons],
      discountSubReasons: [...response?.grantPersonFoxDiscountSubReasons],
      revertDiscountReasons: [...response?.revertPersonFoxDiscountReasons],
    };
  }
  return {
    discountReasons: [],
    discountSubReasons: [],
  };
};
export const invokeServerAPI = (apiName, requestData, isSystemApi) => {
  const requestObj = isSystemApi
    ? requestData
    : requestData
    ? {
        ...requestData,
        ...HttpService.getCommonDataForRequest(),
      }
    : { ...HttpService.getCommonDataForRequest() };

  return axios
    .post(SERVER_API_URL + "/" + apiName, requestObj)
    .then(function (response) {
      if (HttpService.isResponseOk(response.data)) {
        return response.data;
      }
    })
    .catch(function (error) {
      if (isSystemApi) {
        // HttpService.redirectLogoutPage();
        return {
          StatusCode: 1,
          StatusMsg:
            "Unable to connect to remote server. Please try again shortly.",
        };
      } else {
      }
    });
};
export const getPostalWiseDataFromStore = (country, postalCode) => {
  return dataStoe?.postalWiseAddress.find((data) => {
    return data?.country === country && data?.postalCode === postalCode;
  });
};
export const invokeZipCodeStackAPI = (parameterData) => {
  const apiUrl =
    window.location.hostname === "localhost" ? "" : ZIP_CODE_STACK_API_URL;
  return axios
    .get(apiUrl + "/v1/search", {
      params: { ...parameterData },
      headers: {
        apikey: ZIP_CODE_STACK_API_KEY,
        Accept: "application/json",
      },
    })
    .then(function (response) {
      if (HttpService.isResponseOk(response.data)) {
        const postalData = getPostalWiseDataFromStore(
          parameterData?.country,
          parameterData?.codes
        );
        if (!postalData) {
          const data =
            response?.data &&
            response?.data?.results &&
            response?.data?.results[parameterData.codes]
              ? response?.data?.results[parameterData.codes][0]
              : null;
          dataStoe?.postalWiseAddress?.push({
            postalCode: parameterData?.codes,
            country: parameterData?.country,
            result: { ...data },
          });
          return data;
        }
        return postalData?.result;
      }
    })
    .catch(function (error) {
      return {
        response: {
          data: {},
        },
      };
    });
};
export default HttpService;

import React, { useState, Fragment, useEffect } from "react";
import { getVehicleDetailsSubMenu } from "./vehicle-submenu-helper";
import Sidebar from "../../../components/Sidebar/sidebar.jsx";
import Breadcrumbs from "../../../components/breadcrumbs/breadcrumbs";
import SidebarContainer from "../../../components/Sidebar/sidebar-container";
import { setActiveMenu } from "../../../containers/search/search-utility/search-utility";

const VehicleDetailsSubMenu = (props) => {
  const [isExpandableSidebar, setIsExpandableSidebar] = useState(true);
  //const vehicleId = props.location.state.vehicleId;
  const [vehicleId, setVehicleId] = useState(
    props.location.state.vehicleId || null
  );

  const toggleNavbar = () => {
    setIsExpandableSidebar(!isExpandableSidebar);
  };

  useEffect(() => {
    setVehicleId(props.location.state.vehicleId);
  }, []);

  setActiveMenu(props.match.url);
  return (
    <Fragment>
      <Sidebar
        subMenu={getVehicleDetailsSubMenu(vehicleId || null)}
        isExpandableSidebar={isExpandableSidebar}
        toggleNavbar={toggleNavbar}
        breadCrumbList={props.location.state.breadCrumbList}
      />
      <div
        className={
          isExpandableSidebar ? "active content-wrapper1" : "content-wrapper1"
        }
      >
        <Breadcrumbs
          breadCrumbList={props.location.state.breadCrumbList}
          title={
            props.location.state
              ? props.location.state.breadCrumbTitle
                ? props.location.state.breadCrumbTitle
                : "Vehicle Details"
              : "Vehicle Details"
          }
        />
        <SidebarContainer
          subMenu={getVehicleDetailsSubMenu(vehicleId || null)}
          isExpandableSidebar={isExpandableSidebar}
          toggleNavbar={toggleNavbar}
        />
      </div>
    </Fragment>
  );
};
export default VehicleDetailsSubMenu;

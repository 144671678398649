import React from 'react';
import ReactHtmlParser from "react-html-parser";
import TooltipItem from '../../../../components/tooltip/TooltipItem';
import { getNoteTooltipHtml } from '../../../../shared/utility';

function FeatureAttr(props) {
    const { count, icon, tooltipData, toolTipId } = props;
    return (
        <div className="feature-attr">
            <img src={icon} alt="u" id={toolTipId} height={16} width={16} />
            <TooltipItem
                placement="bottom"
                toolTipId={toolTipId}
                renderableHtml={ReactHtmlParser(getNoteTooltipHtml(tooltipData))}
                forceShow={true}
            />
            <span className="contents">{count || "--"}</span>
        </div>
    )
}

export default FeatureAttr
import ReactHtmlParser from "react-html-parser";
import { MarkerMapModel } from "../../services/entities/models/domain/marker-map-model";
import PageInfo from "../../services/entities/models/PageInfo";
import { COMMON_MSG } from "../../shared/GlobalVar";
import {
  getFormattedValidationMsg,
  getSubString,
  hasObjectNonEmptyProperty,
  validatePhoneNo,
} from "../../shared/utility";
import { ColorCode, MapType } from "../../shared/utility/enum-utility";
import { isLatitude, isLongitude } from "../../shared/utility/number-utility";
import { getLandmarkMarkup } from "../shuttles/shuttles-helper";

export const getLandmarkCategories = () => {
  return [
    { key: "", displayValue: "All" },
    { key: "GAS", displayValue: "Gas" },
    { key: "TERMINAL", displayValue: "Terminal" },
    { key: "FOX_LOCATION", displayValue: "Fox Location" },
  ];
};

export const getLandmarkMapInfo = (dataList, showModal) => {
  const landmarkData = [];
  dataList.map((post) => {
    if (!isLatitude(post.latitude) || !isLongitude(post.longitude)) {
      return false;
    }
    landmarkData.push(
      new MarkerMapModel({
        markupData: getLandmarkMarkup(post, {}, showModal),
        position: [post.latitude, post.longitude],
        iconData: {
          type: MapType.LANDMARKS,
          label:
            post.iconColor === ColorCode.LANDMARK_GREEN
              ? getSubString(post.shortName, 0, 2)
              : post.iconColor === ColorCode.LANDMARK_RED
              ? getSubString(post.shortName, 0, 2)
              : getSubString(post.shortName, 0, 2),
          className: "fas fa-bus fa-3x",
          color: post.iconColor,
        },
      })
    );
  });
  return landmarkData;
};

export const validateAddEditLandmark = (landmarkData) => {
  let msg = "";
  if (landmarkData) {
    if (landmarkData.orgUnit && !landmarkData.orgUnit.locationCode) {
      if (msg) {
        msg += ", ";
      }
      msg += COMMON_MSG.LocationCode;
    }
    if (!landmarkData.landmarkCategory) {
      if (msg) {
        msg += ", ";
      }
      msg += COMMON_MSG.LandmarkCategory;
    }
    if (!landmarkData.landmarkCode) {
      if (msg) {
        msg += ", ";
      }
      msg += COMMON_MSG.LandmarkCode;
    }
    if (!landmarkData.name) {
      if (msg) {
        msg += ", ";
      }
      msg += COMMON_MSG.LandmarkName;
    }
    if (!landmarkData.name) {
      if (msg) {
        msg += ", ";
      }
      msg += COMMON_MSG.LandmarkName;
    }
    if (!landmarkData.name) {
      if (msg) {
        msg += ", ";
      }
      msg += COMMON_MSG.LandmarkName;
    }
    if (!landmarkData.shortName) {
      if (msg) {
        msg += ", ";
      }
      msg += COMMON_MSG.LandmarkShortName;
    }
    if (!landmarkData.latitude) {
      if (msg) {
        msg += ", ";
      }
      msg += COMMON_MSG.Latitude;
    }
    if (!landmarkData.longitude) {
      if (msg) {
        msg += ", ";
      }
      msg += COMMON_MSG.Longitude;
    }
    // if (isNullOrEmpty(landmarkData.phone)) {
    //   if (msg) {
    //     msg += ", ";
    //   }
    //   msg += COMMON_MSG.PhoneNoRequired;
    // }

    // if (!landmarkData.address.Street1) {
    //   if (msg) {
    //     msg += ", ";
    //   }
    //   msg += COMMON_MSG.Address1;
    // }
    // if (!landmarkData.address.Street2) {
    //   if (msg) {
    //     msg += ", ";
    //   }
    //   msg += COMMON_MSG.Address2;
    // }

    // if (!landmarkData.address.Country) {
    //   if (msg) {
    //     msg += ", ";
    //   }
    //   msg += COMMON_MSG.Country;
    // }

    // if (!landmarkData.address.State && !landmarkData.address.StateText) {
    //   if (msg) {
    //     msg += ", ";
    //   }
    //   msg += COMMON_MSG.State;
    // }

    // if (!landmarkData.address.City && !landmarkData.address.CityText) {
    //   if (msg) {
    //     msg += ", ";
    //   }
    //   msg += COMMON_MSG.City;
    // }

    // if (!landmarkData.address.ZipCode && !landmarkData.address.ZipCodeText) {
    //   if (msg) {
    //     msg += ", ";
    //   }
    //   msg += COMMON_MSG.ZipPostal;
    // }

    if (msg) {
      return getFormattedValidationMsg(msg);
    }

    if (hasObjectNonEmptyProperty(landmarkData.phone)) {
      if (!validatePhoneNo(landmarkData.phone)) {
        msg = COMMON_MSG.InvalidPhoneNo + ".";
      }
    }
  }
  return msg ? ReactHtmlParser(msg) : "";
};

export const getLandmarkDummyData = () => {
  return {
    pageInfo: new PageInfo(1, 100, "name", "DESC", 7),
    landmarkSummaries: [
      {
        orgUnit: { locationCode: "LAX", name: "Los Angeles" },
        landmarkCode: "LM001",
        landmarkId: "0001",
        name: "Gas Station 4",
        shortName: "G4",
        landmarkCategory: "GAS",
        address: "780 N. MCDONNELL RD",
        status: "ENABLED",
        latitude: 34.052235,
        longitude: -118.243683,
      },
      {
        orgUnit: { locationCode: "BAR", name: "Los Angeles" },
        landmarkCode: "SFOTERM0010",
        landmarkId: "0002",
        name: "Terminal 0",
        shortName: "T0",
        landmarkCategory: "TERMINAL",
        address: "780 N. MCDONNELL RD",
        status: "ENABLED",
        latitude: 23.022505,
        longitude: 72.571365,
      },
      {
        orgUnit: { locationCode: "AUS", name: "Los Angeles" },
        landmarkCode: "LM002",
        landmarkId: "0003",
        name: "Gas Station 5",
        shortName: "G5",
        landmarkCategory: "GAS",
        address:
          "780 N. MCDONNELL RD780 N. MCDONNELL RD780 N. MCDONNELL RD780 N. MCDONNELL RD780 N. MCDONNELL RD780 N. MCDONNELL RD780 N. MCDONNELL RD780 N. MCDONNELL RD",
        status: "ENABLED",
        latitude: 34.052235,
        longitude: -118.243683,
      },
      {
        orgUnit: { locationCode: "SJO", name: "Los Angeles" },
        landmarkCode:
          "SFOTERM00 07SFOTE RM0007SFOTERM00 07SFOTE RM0007SFOTERM00 07SFOTE RM0007",
        landmarkId: "0004",
        name: "Gas Station 2",
        shortName: "T2",
        landmarkCategory: "TERMINAL",
        address: "780 N. MCDONNELL RD",
        status: "DISABLED",
        latitude: 31.52037,
        longitude: 74.358749,
      },
      {
        orgUnit: { locationCode: "SJO", name: "Los Angeles" },
        landmarkCode: "SFOTERM0007",
        landmarkId: "0005",
        name: "Terminal 1",
        shortName: "T1",
        landmarkCategory: "TERMINAL",
        address: "780 N. MCDONNELL RD",
        status: "ENABLED",
        latitude: 53.810331,
        longitude: -50.412521,
      },
      {
        orgUnit: { locationCode: "SJO", name: "Los Angeles" },
        landmarkCode: "SFOTERM0007",
        landmarkId: "0006",
        name: "Gas Station 100",
        shortName: "GC",
        landmarkCategory: "GAS",
        address: "780 N. MCDONNELL RD",
        status: "DISABLED",
        latitude: 45.810331,
        longitude: 96.412521,
      },
      {
        orgUnit: { locationCode: "SJO", name: "Los Angeles" },
        landmarkCode: "SFOTERM0007",
        landmarkId: "0007",
        name: "Austin 3",
        shortName: "F9",
        landmarkCategory: "FOX_LOCATION",
        address: "780 N. MCDONNELL RD",
        status: "DISABLED",
        latitude: 2.810331,
        longitude: 0.412521,
      },
      {
        orgUnit: { locationCode: "SJO", name: "Los Angeles" },
        landmarkCode: "SFOTERM0007",
        landmarkId: "0008",
        name: "Barbank 1",
        shortName: "F8",
        landmarkCategory: "FOX_LOCATION",
        address: "780 N. MCDONNELL RD",
        status: "ENABLED",
        latitude: 76.810331,
        longitude: 33.412521,
      },
      {
        orgUnit: { locationCode: "SJO", name: "Los Angeles" },
        landmarkCode: "SFOTERM0007",
        landmarkId: "0009",
        name: "Gas Station 2",
        shortName: "G2",
        landmarkCategory: "GAS",
        address: "780 N. MCDONNELL RD",
        status: "DISABLED",
        latitude: 56.810331,
        longitude: 90.412521,
      },
      {
        orgUnit: { locationCode: "SJO", name: "Los Angeles" },
        landmarkCode: "SFOTERM0007",
        landmarkId: "00010",
        name: "Los Angeles 1",
        shortName: "F0",
        landmarkCategory: "FOX_LOCATION",
        address: "780 N. MCDONNELL RD",
        status: "ENABLED",
        latitude: 23.810331,
        longitude: 71.412521,
      },
    ],
    message: {
      messageCode: "2100",
      messageType: "SUCCESS",
      displayMessage: "Search Success",
    },
    TotalRecords: 7,
  };
};

import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../../store/actions/index";
import { Link } from "react-router-dom";
import {
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown
} from "reactstrap";
import {
  NewReservationImg,
  CAR_GARAGE_RED,
  CAR_GARAGE_COLOR,
  CAR_AVAILABLE_COLOR,
  CAR_RENTED_OUT,
  CAR_PARKING_02
} from "../../shared/AppImages";

class FleetUtilizationDropdown extends Component {
  state = {
    showDropMenu: false,
    availabilitySummaryInFleetResponse: null
  };
  componentDidMount() {}
  componentDidUpdate(prevProps, prevState) {}
  closeDropMenu = () => {
    this.setState({ showDropMenu: this.state.showDropMenu ? false : true });
  };
  resetAvailabilitySummaryInFleet = () => {
    this.props.onGetAvailabilitySummaryInFleetReset();
    this.props.onGetAvailibilitySummaryInFleet();
  };
  UNSAFE_componentWillReceiveProps({ availabilitySummaryInFleetResponse }) {
    if (
      (availabilitySummaryInFleetResponse &&
        !this.state.availabilitySummaryInFleetResponse) ||
      (this.state.availabilitySummaryInFleetResponse &&
        availabilitySummaryInFleetResponse &&
        !this.props.availabilitySummaryInFleetResponse)
    ) {
      this.setState({
        availabilitySummaryInFleetResponse: availabilitySummaryInFleetResponse
      });
    }
  }
  getAvailabilitySummaryFleetData = () => {
    if (!this.state.showDropMenu) {
      this.resetAvailabilitySummaryInFleet();
    }
  };
  getAvailabilityRenderData = () => {
    const availabilityData = this.state.availabilitySummaryInFleetResponse;
    const renderHtml = (
      <div
        style={{ zIndex: "10000", minWidth: "18rem" }}
        className="availabilityDropdown"
      >
        <ul>
          <li>
            <Link to="/vehicle">
              <img width="247" height="296" src={CAR_GARAGE_COLOR} alt="" />
              In Fleet
            </Link>
            <strong>Total:</strong>{" "}
            <span className="quantity">
              {availabilityData && availabilityData.InFleetCount
                ? availabilityData.InFleetCount
                : "--"}
            </span>
          </li>
          <li>
            <Link to="/vehicle">
              <img width="247" height="296" src={CAR_AVAILABLE_COLOR} alt="" />
              Available
            </Link>
            <strong>Total:</strong>{" "}
            <span className="quantity">
              {availabilityData && availabilityData.AvailableCount
                ? availabilityData.AvailableCount
                : "--"}
            </span>
          </li>
          <li>
            <Link to="/vehicle">
              <img width="247" height="296" src={CAR_RENTED_OUT} alt="" />
              Rented Out
            </Link>
            <strong>Total:</strong>{" "}
            <span className="quantity">
              {availabilityData && availabilityData.RentedOutCount
                ? availabilityData.RentedOutCount
                : "--"}
            </span>
          </li>
        </ul>
      </div>
    );
    return renderHtml;
  };
  render() {
    return (
      <UncontrolledDropdown
        isOpen={this.state.showDropMenu}
        toggle={this.closeDropMenu}
        onClick={event => {
          this.getAvailabilitySummaryFleetData();
        }}
      >
        <DropdownToggle tag="div" className="user-nav__icon-box vertical-align-center">
            <img
              src={
                localStorage.getItem("loginMode")
                  ? CAR_PARKING_02
                  : CAR_PARKING_02
              }
              alt="fleet"
              style={{ height: "2.4rem" }}
            />
        </DropdownToggle>
        {this.state.showDropMenu ? (
          <DropdownMenu right>
            <DropdownItem>{this.getAvailabilityRenderData()}</DropdownItem>
          </DropdownMenu>
        ) : (
          ""
        )}
      </UncontrolledDropdown>
    );
  }
}
const mapDispatchToProps = dispatch => {
  return {
    onGetAvailibilitySummaryInFleet: () =>
      dispatch(actions.getAvailibilitySummaryInFleet()),
    onGetAvailabilitySummaryInFleetReset: () =>
      dispatch(actions.getAvailabilitySummaryInFleetReset())
  };
};
const mapStateToProps = state => {
  return {
    availabilitySummaryInFleetResponse:
      state.availabilitySummaryFleetReducer.availabilitySummaryInFleetResponse,
    loading: state.availabilitySummaryFleetReducer.loading
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FleetUtilizationDropdown);

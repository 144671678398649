import { FoxDiscountDetails } from "../../fox-api/domain/fox-discount-details";
import { FoxRequestHeader } from "../domain/fox-request-header";
import { FoxLocationRequest } from "../request/fox-location-request";
import { FoxVehicleCLassRequest } from "../request/fox-vehicle-class-req";

export const GetRatesRequest = function (data, addOnsList) {
  if (!data) data = {};
  if (!addOnsList) addOnsList = [];
  this.requestHeader = new FoxRequestHeader();
  this.rentalLocation = new FoxLocationRequest(data.rentalLocationCode);
  this.returnLocation = new FoxLocationRequest(data.returnLocationCode);
  this.rentalDateTime = data.rentalDateTime || null;
  this.returnDateTime = data.returnDateTime || null;
  this.vehicleClass = new FoxVehicleCLassRequest(data.vehicleClassCode);
  this.rateId = data.rateId || null;
  if (data?.foxDiscountCode) this.foxDiscountCode = data.foxDiscountCode;
  if (data?.freeCreditAmountApplied)
    this.freeCreditAmountApplied = data.freeCreditAmountApplied;
  if (data?.discount?.discountCode)
    this.discount = new FoxDiscountDetails(data.discount);
  this.addOns = data?.addOns ? data.addOns : addOnsList;
  this.payType = data.payType || null;
  // this.reservationCode = data.reservationCode
};

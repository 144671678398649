import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { runDMVCheck, runDMVCheckReset } from "../../../store/actions/index";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import Draggable from "react-draggable";
import { LicenseDocType } from "../../../shared/GlobalVar";
import { getBasicToastResponse } from "../../../shared/utility";
import { showNotification } from "../../../components/UI/Toaster/Toaster";

export const LicenseApprovalModal = (props) => {
  const dispatch = useDispatch();
  const dmvCheckResponse = useSelector(
    (state) => state.licenseApprovalReducer.dmvCheckResponse
  );
  useEffect(() => {
    dispatch(runDMVCheckReset());
  }, []);
  useEffect(() => {
    if (!dmvCheckResponse) {
      return;
    }
    showNotification(getBasicToastResponse(dmvCheckResponse));
    if (dmvCheckResponse.StatusCode === 0) {
      props.resetParentPage();
      props.closeModal();
      dispatch(runDMVCheckReset());
    }
    dispatch(runDMVCheckReset());
  }, [dmvCheckResponse]);
  const approveLicensebyDMV = () => {
    dispatch(runDMVCheckReset());
    dispatch(
      runDMVCheck({
        MemberId: props.memberId || null,
        PersonInfo: props.memberData,
      })
    );
  };
  return (
    <Draggable handle=".cursor">
      <Modal
        isOpen={props.showModal}
        className="no-cursor"
        toggle={() => {
          props.closeModal();
        }}
        style={{ minWidth: "20rem" }}
      >
        <ModalHeader
          className="modal-header bg-primary modal-title text-white cursor"
          toggle={() => {
            props.closeModal();
          }}
        >
          <i className="fas fa-key" /> Approve License
        </ModalHeader>
        <ModalBody style={{ minHeight: "22rem", maxWidth: "40rem" }}>
          <div className="col-lg-12">
            <b style={{ fontSize: "1.2rem" }}>
              Run DMV check on license or bypass the check and approve license
              manually.
            </b>
          </div>
          <div className="col-lg-12 text-center p-4 mt-4">
            <button
              type="button"
              className="btn btn-primary waves-effect waves-light btnSize ml-2 disabled actionDisabled"
              onClick={() => approveLicensebyDMV()}
              style={{ width: "20rem" }}
            >
              Run DMV Check
            </button>
          </div>
          <div className="col-lg-12 text-center pb-2">
            <button
              type="button"
              className="btn btn-primary  waves-effect waves-light btnSize ml-2"
              onClick={() =>
                props.launchModalFromParentPage({
                  type: LicenseDocType.MANUAL_APPROVE,
                  memberId: props.memberId,
                })
              }
              style={{ width: "20rem" }}
            >
              Manual Approve
            </button>
          </div>
        </ModalBody>
      </Modal>
    </Draggable>
  );
};
export default LicenseApprovalModal;

import { isNullOrEmpty } from "../../shared/utility";

export const triggerButtonClickOnEnter = (props) => {  
  const globalSearchElement = props.globalSearchElement;
  const listener = (event) => {
    if (event.code === "Enter" || event.code === "NumpadEnter") {
      if (
        ["Search", "Save"].includes(props.actionOn) &&
        props.isNotDisabled &&
        globalSearchElement !== document.activeElement && !document.activeElement.id.includes("GlobalSearch")
      ) {
        props.doAction();
      }
    }
  };
  document.addEventListener("keydown", listener);
  return () => {
    document.removeEventListener("keydown", listener);
  };
};

export const getDateTimeFormatString = (includingTime, isSpecialFormat, isForDisplay) => {
  let dateTimeFormat = includingTime ? isForDisplay ? "MM/DD/YYYY hh:mm A" : "MM/DD/YYYY h:mm A" : "MM/DD/YYYY";
  if (isSpecialFormat) {
    dateTimeFormat = includingTime ? {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    } : { day: "2-digit", month: "2-digit", year: "numeric" };
  }
  return dateTimeFormat;
};

export const getDateTimePlaceholder = (includingTime) => {
  return includingTime ? "MM/DD/YYYY h:mm A" : "MM/DD/YYYY";
};

export const commonAddressChangeHandler = (event, controlName, commonData, addressData) => {  
  let addresObj = { ...addressData }
    addresObj["isValidZipCode"] = true;
  if (controlName === "Country") {
    addresObj.State = null;
    addresObj.City = null;
    addresObj.ZipCode = null;
    addresObj.StateText = null;
    addresObj.CityText = null;
    addresObj.ZipCodeText = null;
    addresObj[controlName] = event.target && event.target.value;
  } else if (controlName === "State") {
    addresObj.City = null;
    addresObj.ZipCode = null;
    addresObj.StateText = null;
    addresObj.CityText = null;
    // addresObj.ZipCodeText = null;
    addresObj[controlName] = event.target && event.target.value;
  } else if (controlName === "City") {
    addresObj.ZipCode = null;
    addresObj.CityText = null;
    // addresObj.ZipCodeText = null;
    addresObj[controlName] = event.target && event.target.value;
  } else if (commonData && controlName === "STATETEXT_CITYTEXT_ZIP") {
    addresObj.StateText = commonData.state;
    addresObj.CityText = commonData.city;
    addresObj.ZipCodeText = commonData.postalCode;
  }else if (commonData && controlName === "CITY_ZIP") {
    addresObj.State=commonData.state;
    addresObj.CityText = commonData.city;
    addresObj.ZipCodeText = commonData.postalCode;
  } else {
    if (controlName === "ZipCodeText" || controlName === "ZipCode") {
      addresObj.ZipCodeText = typeof event === 'object' ? event.target && event.target.key : event;
    }
    addresObj[controlName] = event.target && event.target.value;
  }
  
  if((controlName === "ZipCodeText" &&  isNullOrEmpty(commonData)) || (controlName === "ZipCode" &&  (typeof event !== 'object' || (event.target && event.target.value === null)))){
    addresObj["isValidZipCode"] = false;
  }
  return addresObj;
};

export const foxAPIAddressChangeHandler = (event, controlName, commonData, addressData) => {  
  if(event && event.hasOwnProperty("keyValue")){
    let eventTemp = {...event};
    event = {
      target: {
        value: "",
        key: "",
      },
    };
    event.target.value = eventTemp.keyValue;;
  }
  let addresObj = { ...addressData }
    addresObj["isValidZipCode"] = true;
  if (controlName === "country") {
    addresObj.province = null;
    addresObj.city = null;
    addresObj.postalCode = null;
    addresObj.provinceText = null;
    addresObj.cityText = null;
    addresObj.postalCodeText = null;
    addresObj[controlName] = event.target && event.target.value || event.target.key;
  } else if (controlName === "province") {
    addresObj.city = null;
    addresObj.provinceText = null;
    addresObj.cityText = null;
    addresObj[controlName] = event.target && event.target.value || event.target.key;
  } else if (controlName === "provinceText") {
    addresObj.city = null;
    addresObj.province = null;
    addresObj[controlName] = event.target && event.target.value || event.target.key;
  } else if (controlName === "city") {
    addresObj.cityText = null;
    addresObj[controlName] = event.target && event.target.value || event.target.key;
  } else if (controlName === "cityText") {
    addresObj.city = null;
    addresObj[controlName] = event.target && event.target.value || event.target.key;
  } else if (commonData && controlName === "STATETEXT_CITYTEXT_ZIP") {
    addresObj.province = commonData.state;
    addresObj.provinceText = commonData.state;
    addresObj.city = commonData.city;
    addresObj.cityText = commonData.city;
    addresObj.postalCode = commonData.postalCode;
    addresObj.postalCodeText = commonData.postalCode;
  }else if (commonData && controlName === "CITY_ZIP") {
    addresObj.province=commonData.state;
    addresObj.provinceText=commonData.state;
    addresObj.city = commonData.city;
    addresObj.cityText = commonData.city;
    addresObj.postalCode = commonData.postalCode;
    addresObj.postalCodeText = commonData.postalCode;
  } else if (controlName === "postalCodeText") {
      addresObj.postalCodeText = typeof event === 'object' ? event.target && event.target.key || event.target.value : event;
      addresObj.postalCode = null;
  } else if(controlName === "postalCode"){
      addresObj.postalCode = typeof event === 'object' ? event.target && event.target.key || event.target.value : event;
      addresObj.postalCodeText = null;
  } else {
    addresObj[controlName] = event && event.target && event.target.value;
  }
  if((controlName === "postalCodeText" &&  isNullOrEmpty(commonData)) || (controlName === "postalCode" &&  (typeof event !== 'object' || (event.target && event.target.value === null)))){
    addresObj["isValidZipCode"] = false;
  }
  return addresObj;
};

export const getCustomEventObject =(event, keyProperty, displayValueProperty)=>{
  if(event && (event.hasOwnProperty(keyProperty))){
    let eventTemp = {...event};
    event = {
      target: {
        value: "",
        key: "",
        displayText:""
      },
    };
    event.target.value = eventTemp[keyProperty];
    event.target.displayText = eventTemp[displayValueProperty] || "";
  }
  return event;
}

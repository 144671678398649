import { FoxLocationRequest } from "../request/fox-location-request";
import { FoxVehicleCLassRequest } from "../request/fox-vehicle-class-req";

export const ReservationRequest = function (
  primaryData,
  classCode,
  pricingData,
  customerData,
  otherData,
  renterComments,
  reservationCode,
  source
) {
  const getCustomerReq = () => {
    const cusData = { ...customerData };
    cusData.addresses = [{ ...customerData?.primaryAddress }];
    cusData.phoneNumbers = [
      { ...customerData?.homePhone },
      { ...customerData?.mobilePhone },
    ];
    delete cusData.primaryAddress;
    delete cusData.homePhone;
    delete cusData.mobilePhone;
    delete cusData.foxCredit;
    delete cusData.rewardPointsBalance;
    delete cusData.reservationCount;
    return cusData;
  };
  this.rentalLocation = new FoxLocationRequest(primaryData?.rentalLocationCode);
  this.returnLocation = new FoxLocationRequest(primaryData?.returnLocationCode);
  this.vehicleClass = new FoxVehicleCLassRequest(classCode);
  this.rentalDateTime = primaryData?.rentalDateTime || null;
  this.returnDateTime = primaryData?.returnDateTime || null;
  if (customerData) this.customerInfo = getCustomerReq();
  if (pricingData) this.pricing = pricingData;
  if (renterComments) this.renterComments = renterComments || null;
  if (otherData?.airline) this.airline = otherData.airline || null;
  if (otherData?.flight) this.flight = otherData.flight || null;
  this.source = source || "ADMIN_WEB";
  if (otherData?.termsAndConditions)
    this.termsAndConditions = otherData.termsAndConditions || false;
  if (otherData?.termsAndConditionsURL)
    this.termsAndConditionsURL = otherData.termsAndConditionsURL || "";
  if (otherData?.tourOperator)
    this.tourOperator = otherData.tourOperator || false;
  if (reservationCode) this.reservationCode = reservationCode;
};

import React from 'react';
import { Position } from '../../../shared/utility/enum-utility';

function CommonTitle({
    title,
    classNames,
    withIcon,
    iconTitle,
    iconClass,
    onClick,
    isStrike = false,
    iconPosition = Position.RIGHT
}) {
    return (
        !withIcon ? !isStrike ? <span className={`${classNames} font-primary`}>{title}</span>
            : <del className={classNames}>{title}</del>
            :
            <div className='title-container'>
                {iconClass && iconPosition === Position.LEFT
                    ? <i className={`${iconClass ? iconClass : 'fa fa-info-circle'} mr-2`}
                        aria-hidden="true"
                        onClick={onClick}
                        title={iconTitle}
                    /> : ""
                }
                {!isStrike ? <span className={classNames}>{title}</span>
                    : <del className={classNames}>{title}</del>}
                {iconClass && iconPosition === Position.RIGHT ?
                    <i className={`${iconClass ? iconClass : 'fa fa-info-circle'} ml-2`}
                        aria-hidden="true"
                        onClick={onClick}
                        title={iconTitle}
                    /> : ""
                }
            </div>
    )
}

export default CommonTitle
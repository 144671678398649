import L from "leaflet";
import "leaflet-routing-machine";
import "leaflet-routing-machine/dist/leaflet-routing-machine.css";
import { useEffect, useState } from "react";
import { useMap } from "react-leaflet";

L.Marker.prototype.options.icon = L.icon({
  iconUrl: "https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png"
});
export const RoutingMachine = (props) => {
  const { startingPoint, destinationPoint, startingValue, destinationValue, isMarkerRequired } = props;
  const [currentStartingPoint, setCurrentStartingPoint] = useState(startingPoint || null);
  const [currentDestinationPoint, setcurrentDestinationPoint] = useState(destinationPoint || null);
  useEffect(() => {
    if (startingPoint) {
      setCurrentStartingPoint(startingPoint);
      //map.route();
    }
  }, [startingPoint]);
  useEffect(() => {
    if (destinationPoint) {
      setcurrentDestinationPoint(destinationPoint);
      //map.route();
    }
  }, [destinationPoint]);
  const map = useMap();
  const checkmk_mk = (color) => {
    // assume checkmk has `brown` only 1 place
    return checkmk.replace(/brown/g, color);
  }
  const checkmk = `<svg width="24" height="24" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg"><path stroke="black" stroke-width="1.5%" opacity="0.8" fill="brown" d="M10,17L6,13L7.41,11.59L10,14.17L16.59,7.58L18,9M12,1L3,5V11C3,16.55 6.84,21.74 12,23C17.16,21.74 21,16.55 21,11V5L12,1Z"></path></svg>`;
  const svgpin_Url = encodeURI("data:image/svg+xml;utf-8," + checkmk_mk("red"));
  const svgpin_Url2 = encodeURI(
    "data:image/svg+xml;utf-8," + checkmk_mk("green")
  );
  const svgpin_Url3 = encodeURI(
    "data:image/svg+xml;utf-8," + checkmk_mk("black")
  );
  const svgpin_Icon = L.icon({
    iconUrl: svgpin_Url,
    iconSize: [24, 24],
    iconAnchor: [12, 24],
    popupAnchor: [0, -22]
  });
  const svgpin_Icon2 = L.icon({
    iconUrl: svgpin_Url2,
    iconSize: [24, 24],
    iconAnchor: [12, 24],
    popupAnchor: [0, -22]
  });
  const svgpin_Icon3 = L.icon({
    iconUrl: svgpin_Url3,
    iconSize: [24, 24],
    iconAnchor: [12, 24],
    popupAnchor: [0, -22]
  });
  useEffect(() => {
    if (!map || !currentStartingPoint || !currentDestinationPoint) return;

    const routingControl = L.Routing.control({
      waypoints: [
        L.latLng([currentStartingPoint.latitude, currentStartingPoint.longitude]),
        L.latLng([currentDestinationPoint.latitude, currentDestinationPoint.longitude])
      ],
      addWaypoints: false,
      draggableWaypoints: false,
      fitSelectedRoutes: true,
      routeWhileDragging: false,
      routeDragInterval: 500,
      collapsible: true,
      reverseWaypoints: false,
      showAlternatives: false,
      createMarker: function (i, wp, nWps) {
        switch (i) {
          case 0:
            return L.marker(wp.latLng, {
              icon: svgpin_Icon,
              draggable: true
            }).bindPopup("<b>" + startingValue + "</b>", { className: 'route-content-popup' });
          case nWps - 1:
            return L.marker(wp.latLng, {
              icon: svgpin_Icon2,
              draggable: true
            }).bindPopup("<b>" + destinationValue + "</b>", { className: 'route-content-popup' });
          default:
            return L.marker(wp.latLng, {
              icon: svgpin_Icon3,
              draggable: true
            }).bindPopup("<b>" + "Waypoint" + "</b>");
        }
      },
      lineOptions: {
        styles: [
          {
            color: "blue",
            opacity: 0.6,
            weight: 4
          }
        ]
      },
    }).addTo(map, startingPoint, destinationPoint);

    return () => map.removeControl(routingControl);
  }, [map, currentStartingPoint, currentDestinationPoint]);

  return null;
}
export default RoutingMachine;

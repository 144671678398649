import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import ShuttleTripSearchRequest from "../../../../services/entities/shuttle-app/request/ShuttleTripSearchRequest";
import PermissionName from "../../../../shared/Permissions";
import { createTooltip } from "../../../../shared/utility/tooltip-utility";
import { getSortingClassName, getSortType, hasPermission } from "../../../../shared/utility";
import ShuttleAlertIcons from "../../../../components/UI/icon/shuttle-alert-icons";
import TripDetails from "../trip-details/trip-details";
import { COMMON_MSG, ModalType } from "../../../../shared/GlobalVar";
import { UserProfileLinkIcon } from "../../../../shared/AppImages";
import ModalInfo from "../../../../services/entities/models/ModalInfo";
import ModalEngine from "../../../../components/modal/modal-engine";
export const SearchShuttleTripsGrid = (props) => {
  const dispatch = useDispatch();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [shuttleTripSearchRequest, setShuttleTripSearchRequest] = useState(
    new ShuttleTripSearchRequest()
  );
  const [selectedRow, setSelectedRow] = useState("");
  const [showTripDetails, setShowTripDetails] = useState(false);
  const [selectedTripDetails, setSelectedTripDetails] = useState(null);
  const [seletecTripCode, setSelectedTripCode] = useState("");
  const [modalInfo, setModalInfo] = useState([new ModalInfo()]);
  const hasViewDetailsPermission = hasPermission(PermissionName.CC_VIEW_TRIP_DETAILS)

  useEffect(() => {
    setShowTripDetails(false);;
  }, [props.dataList]);

  const showSelectedtripDetails = (tripDetails) => {
    setSelectedRow(tripDetails.tripCode);
    if (
      selectedTripDetails &&
      selectedTripDetails.tripCode !== tripDetails.tripCode
    ) {
      setShowTripDetails(true);
    } else {
      setShowTripDetails(!showTripDetails);
    }
    setSelectedTripDetails(tripDetails);
  };

  const closeModal = () => {
    setModalInfo(new ModalInfo());
  };
  const showModal = (modalData) => {
    setModalInfo({
      type: modalData.type,
      showModal: true,
      memberId: modalData.memberId,
    });
  };

  const renderTableColumnHeader = (title, sortValue) => {
    return (
      <span
        className={
          sortValue
            ? getSortingClassName(props.pageInfo, sortValue)
            : "column__header"
        }
        onClick={(event) =>
          sortValue
            ? props.doSorting(getSortType(props.pageInfo, sortValue), sortValue)
            : null
        }
      >
        {title}
      </span>
    );
  };
  return (
    <Fragment>
      <div className="datagrid">
        <div className="column">
          {renderTableColumnHeader("Trip Code", "tripCode")}
          {renderTableColumnHeader("Shuttle Code", "shuttleCode")}
          {renderTableColumnHeader("Driver", "fullName")}
          {renderTableColumnHeader("Start Time", "tripStartDateTime")}
          {renderTableColumnHeader("Duration", "tripDurationInMillis")}
          {renderTableColumnHeader("Avg Speed", "tripAvgSpeedInMph")}
          {renderTableColumnHeader("Distance", "tripDistanceInMiles")}
          {renderTableColumnHeader("Shuttle Location")}
          {renderTableColumnHeader("Alerts")}
          {renderTableColumnHeader("Details")}
        </div>
        {props.dataList.map((post) => {
          const isSelected = selectedRow === post.tripCode && showTripDetails;
          return (
            <Fragment key={post.tripCode}>
              <div
                className={`row
              ${isSelected
                    ? "selected-row-color cursor-pointer"
                    : "cursor-pointer"
                  }
            `}
                key={post.tripCode}
              >
                <span className="row__column" id={"tripCode_" + post.tripCode}>
                  {createTooltip(post.tripCode, post.tripCode)}
                </span>
                <span
                  className="row__column"
                  id={"shuttleCode-" + post.tripCode}
                >
                  {createTooltip(post.shuttleCode, post.shuttleCode)}
                </span>
                <span className="row__column" id={"driverName"}>
                  {createTooltip(
                    <span
                      className={`md-trigger ${hasPermission(PermissionName.VIEW_MEMBER)
                          ? "pseudo-link"
                          : ""
                        }`}
                      id={"employeeNameId_" + post.personId}
                      onClick={(event) =>
                        hasPermission(PermissionName.VIEW_MEMBER)
                          ? showModal({
                            type: ModalType.MEMBER_QUICK_MODAL,
                            showModal: true,
                            memberId: post.personId,
                          })
                          : ""
                      }
                    >
                      {post.fullName}
                    </span>,
                    post.fullName
                  )}
                </span>
                {hasPermission(PermissionName.VIEW_MEMBER)
                  ? createTooltip(
                    <Link
                      id={"icoEmployeeType_" + post.PersonId}
                      to={{
                        pathname: "/member/memberProfile",
                        search: "?memberId=" + post.personId,
                        state: {
                          breadCrumbList: props.breadCrumbList,
                          breadCrumbTitle: post.fullName,
                        },
                      }}
                    >
                      <img
                        alt=""
                        src={UserProfileLinkIcon}
                        style={{ height: "12px", width: "12px" }}
                      />
                    </Link>,
                    ReactHtmlParser(COMMON_MSG.UserProfileLinkIconTooltip),
                    { forceShow: true }
                  )
                  : ""}
                <span className="row__column" id={"startTime_" + post.tripCode}>
                  {createTooltip(
                    post.tripDisplayStartDateTime,
                    post.tripDisplayStartDateTime
                  )}
                </span>
                <span className="row__column text-center" id={"duration_" + post.tripCode}>
                  {createTooltip(post.tripDuration, post.tripDuration)}
                </span>
                <span
                  className="row__column text-center"
                  id={"avgSpeed_" + post.tripCode}
                >
                  {createTooltip(post.averageSpeed, post.averageSpeed)}
                </span>
                <span
                  className="row__column text-center"
                  id={"distance_" + post.tripCode}
                >
                  {createTooltip(post.distance, post.distance)}
                </span>
                <span
                  className="row__column"
                  id={"locationDisplayName-" + post.tripCode}
                >
                  {createTooltip(post.locationDisplayName, post.locationDisplayName)}
                </span>
                <span className="row__column horizontal-align-left">
                  <ShuttleAlertIcons
                    alerts={post.tripAlerts}
                    tripId={post.tripCode}
                  />
                </span>
                <span className="row__column horizontal-align-left">
                  <i
                    className={`fas ${selectedRow === post.tripCode && showTripDetails
                        ? "fa-angle-double-up"
                        : "fa-angle-double-down"
                      } fs-3`}
                    style={{ paddingRight: "7px" }}
                  />
                  <span
                    className={`fs-2 font-weight-bold ${hasViewDetailsPermission ? " pseudo-link" : ""}`}
                    onClick={() => (hasViewDetailsPermission ? showSelectedtripDetails(post) : null)}
                    title={hasViewDetailsPermission ? "" : "You do not have permission to view shuttle trip details"}
                  >
                    {selectedRow === post.tripCode && showTripDetails
                      ? "Hide"
                      : "View"}
                  </span>
                </span>
              </div>
              {showTripDetails && post.tripCode === selectedRow ? (
                <TripDetails tripCode={post.tripCode} />
              ) : (
                ""
              )}
            </Fragment>
          );
        })}
      </div>
      {modalInfo.showModal ? (
        <ModalEngine
          modalType={modalInfo.type}
          showModal={modalInfo.showModal}
          closeModal={closeModal}
          memberId={modalInfo.memberId}
          resetParentPage={props.resetParentPage}
        />
      ) : null}
    </Fragment>
  );
};
export default SearchShuttleTripsGrid;

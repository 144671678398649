import React from "react";
import { COPY_TO_CLIPBOARD } from "../../../shared/AppImages";
import CopyToClipboard from "../../copy-to-clipboard/copy-to-clipboard";

const LabelWithValue = (props) => {
  const {
    label,
    value,
    otherValue,
    inputIcon,
    modalIcon,
    showModal,
    modalParams,
    customValueClass,
    customValueParentClass,
    customOtherValueClass,
    hasCopyIcon = false,
    tooltipText,
    enableLinkOnLabel,
    iconSize = { height: 20, width: 20 },
    ...rest
  } = props;

  const handleModalClick = () => {
    if (modalParams && modalParams.type && showModal) {
      showModal(modalParams);
    }
  };

  return (
    <div className="res-items">
      <label className="field-title">{label}</label>
      <div
        className={`field-value ${enableLinkOnLabel && modalParams && modalParams.type
          ? "link-enabled"
          : ""
          } ${customValueParentClass || ""}`}
      >
        <label
          className={`${customValueClass || ""}`}
          onClick={enableLinkOnLabel && modalParams && modalParams.type ? handleModalClick : undefined}
          title={tooltipText}
        >
          {value || "--"}
        </label>
        {inputIcon && (
          <div className="input-icon">
            <img
              src={inputIcon}
              height={iconSize.height}
              width={iconSize.width}
              alt={label}
            />
          </div>
        )}
        {modalIcon && (
          <div
            className="action-icon"
            title={rest.iconTooltipText}
            onClick={modalParams && modalParams.type ? handleModalClick : undefined}
          >
            <img
              className={rest.imageClass || ""}
              src={modalIcon}
              height={iconSize.height}
              width={iconSize.width}
              alt={label}
            />
          </div>
        )}
        {hasCopyIcon && value && (
          <CopyToClipboard
            value={rest.copiableValue || value}
            copyLinkType={"text-image"}
            linkCaption=" "
            imageClass="mt-1"
            imageURL={COPY_TO_CLIPBOARD}
            customImgCSS={{ height: "1.8rem" }}
            successMsg={`${(rest.copiableValueLabel || label)} has been copied to clipboard!`}
            toolTipText={rest.toolTipTextForCopyToClipBoardIcon || ""}
          />
        )}
      </div>
      {otherValue && (
        <div className={`other-field-value ${customOtherValueClass || ""}`}>
          <label title={rest.otherValueTooltipText} >{otherValue}</label>
        </div>
      )}
    </div>
  );
};

export default LabelWithValue;

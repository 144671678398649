import { ReservationBlocks } from "../../../../shared/GlobalVar";
  import { FoxStepViewModel } from "./fox-step-view-model";
  
  export const FoxVehicleClassViewModel = function (data) {
    if (!data) data = {};
    this.vehicleClassCode = data.vehicleClassCode || null;
    this.prevVehicleClassCode = data.prevVehicleClassCode || null;
    this.savedCarClass = data.savedCarClass || null;
    this.savedRatesApplied = data.savedRatesApplied || [];
    this.savedRatePlan = data.savedRatePlan || {};
    this.savedTotalCharges = data.savedTotalCharges || null;
    this.savedAddOnCharges = data.savedAddOnCharges || null;
    this.isVehicleInfoBlockChanged = data.isVehicleInfoBlockChanged || false;
    this.executeAddonsBlock=data.executeAddonsBlock || false;
    this.savedPayType = data.savedPayType || "";
    //***didn't find finest solution for following property****//
    const stepData = data.stepViewModel || {};
    stepData.prevBlock= ReservationBlocks.BASIC_INFO;
    stepData.nextBlock= ReservationBlocks.ADDONS_FORM;
    stepData.currentBlock= ReservationBlocks.VEHICLE_CLASSES;
    stepData.executionBlock=stepData.executionBlock || null;
    stepData.iskeepChanged=stepData.iskeepChanged || false;
    this.stepViewModel = new FoxStepViewModel(stepData);
  };
  
import React from 'react';
import { COPY_TO_CLIPBOARD } from '../../../shared/AppImages';
import ResItemCard from '../component/res-item-card';

function ResProfile(props) {
    const { reservationData } = props;
    return (
        <div className="common-res-container">
            {/* <CommonTitle
                title="Selected Reservation Info"
                classNames="common-title"
            />
            <LocationCard
                title="Pick-up"
                locName={reservationData?.rentalLocationName}
                date={reservationData?.rentalDateTime}
                locIcon="fa fa-circle"
            />
            <span className="vertical-line"></span>
            <LocationCard
                title="Return"
                locName={reservationData?.returnLocationName}
                date={reservationData?.returnDateTime}
                imgIcon={LOC_ICON}
            />
            <ResItemCard
                name="Discount Code"
                value={reservationData?.discoundCode || "--"}
            /> */}
            <ResItemCard
                name="Payment Type"
                value={reservationData?.payType || "--"}
            />
            {reservationData?.reservationCode ?
                <>
                    <ResItemCard
                        name="Reservation Code"
                        value={reservationData?.reservationCode}
                        apiData={{
                            isCopyToClipBoard: true,
                            icon: COPY_TO_CLIPBOARD
                        }}
                    />
                    <ResItemCard
                        name="Reservation Status"
                        value={reservationData?.reservationStatus}
                    />
                    <ResItemCard
                        name="Int. Res. Code"
                        value={reservationData?.externalReservationCode}
                        apiData={{
                            isCopyToClipBoard: true,
                            icon: COPY_TO_CLIPBOARD
                        }}
                    />
                    <ResItemCard
                        name="Product Code"
                        value={reservationData?.productCode}
                        apiData={{
                            isCopyToClipBoard: true,
                            icon: COPY_TO_CLIPBOARD
                        }}
                    />
                    <ResItemCard
                        name="Created On"
                        value={reservationData?.creationDateTimeDisplayText}
                    />
                </>
                : ""}
        </div>
    )
}

export default ResProfile
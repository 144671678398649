import * as actionTypes from "../actions/actionTypes";
import { reducerUpdateObject } from "./reducer.utility";

const initialState = {
  orgDataResponse: null,
  loading: false
};
const getOrgDetailsStart = (state, action) => {
  return reducerUpdateObject(state, { error: null, loading: true });
};
const getOrgDetailsSuccess = (state, action) => {
  return reducerUpdateObject(state, {
    orgDataResponse: action.orgDataResponse,
    error: null,
    loading: false
  });
};
const getOrgDetailsFail = (state, action) => {
  return reducerUpdateObject(state, {
    error: action.error,
    loading: false
  });
};
const getOrgDetailsReset = (state, action) => {
  return reducerUpdateObject(state, {
    orgDataResponse: null
  });
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ORG_DETAIL_START:
      return getOrgDetailsStart(state, action);
    case actionTypes.GET_ORG_DETAIL_SUCCESS:
      return getOrgDetailsSuccess(state, action);
    case actionTypes.GET_ORG_DETAIL_FAIL:
      return getOrgDetailsFail(state, action);
    case actionTypes.GET_ORG_DETAIL_RESET:
      return getOrgDetailsReset(state, action);

    default:
      return state;
  }
};
export default reducer;

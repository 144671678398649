import React from 'react';
import CommonTitle from '../../../component/common-title';
import GeneralRateRow from '../general-rate-row';

function TaxesFeesBlock({ taxes, surcharges, extensionFee, damageCharges }) {
    return (
        <>
            <div className='col-lg-12 mt-3'>
                <CommonTitle title="Taxes and Fees" classNames="common-title" />
            </div>
            {taxes.map((data) => {
                return data?.amount && <GeneralRateRow key={data?.desc.replace(/\s+/g, '')} title={data?.desc} rate={data?.rate} total={data?.amount} />
            })}
            {surcharges.map((data) => {
                return <GeneralRateRow key={data?.desc} title={data?.desc.replace(/\s+/g, '')} rate={data?.rate} total={data?.amount} />
            })}
            {extensionFee && <GeneralRateRow key="extension-fee" title="Extension Fees" total={extensionFee} />}
            {damageCharges && <GeneralRateRow key="damage-charge" title="Damage Charges" total={damageCharges} />}
        </>
    )
}

export default TaxesFeesBlock
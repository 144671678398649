import React, { useRef, useState } from "react";
import DialogEngine from "../../../components/dialog/dialog-engine";
import CheckBox from "../../../components/UI/Input/checkbox-radio/check-box-ctrl";
import MicroLoader from "../../../components/UI/loader/micro-loader";
import { MessageBoxType, ModalType } from "../../../shared/GlobalVar";
import { getSecurityDepositPolicy } from "../../../shared/json/data";
import CommonTitle from "../component/common-title";
import GeneralRateRow from "../pricing/component/general-rate-row";

const SecurityDepositRate = (props) => {
  const {
    isError,
    securityDepositRate,
    confirmSecurityDepositRate,
    setConfirmSecurityDepositRate,
    showSecurityLoader
  } = props;
  const iconRef = useRef(null);
  const [modalData, setModalData] = useState(null);
  const openModal = () => {
    setModalData(
      {
        modalType: ModalType.COMMON_NOTES_MODAL,
        modalProps: {
          showModal: true,
          isDraggable: true,
          closeModal: onCloseModal,
          modalTitle: "Security Deposit Policy",
          modaWrapperClass: "protection-addons-modal"
        },
        modalBodyProps: {
          notes: getSecurityDepositPolicy(),
        },
      }
    );
  }
  const onCloseModal = () => {
    setModalData(null);
  }
  // if (!securityDepositRate) {
  //   setConfirmSecurityDepositRate && setConfirmSecurityDepositRate(false)
  // }
  return (
    <div className="common-res-container">
      <div className="sec-title position-relative" ref={iconRef}>
        <CommonTitle
          withIcon={true}
          title="Security Deposit Charges"
          classNames="common-title"
          onClick={() => {
            securityDepositRate && openModal(MessageBoxType.SECURITY_POLICY);
          }}
          iconClass="fa fa-info-circle"
          iconTitle={
            securityDepositRate
              ? "Click to view Security Deposit Policy"
              : "No Policy Available"
          }
        />
        {modalData ?
          <DialogEngine
            {...modalData}
          />
          : ""}
      </div>
      {isError && !confirmSecurityDepositRate ? (
        <div className="error-block">
          <i className="fas fa-exclamation-triangle"></i>
          <div className="error-msg-block">
            <label className="error-title">Required Fields!</label>
            <label className="error-msg">
              {securityDepositRate
                ? "Please confirm you shared this information with customer."
                : "Please enter a valid location, country, and Zip/Postal Code for security deposit charges."}
            </label>
          </div>
        </div>
      ) : (
        ""
      )}
      {confirmSecurityDepositRate !== undefined ? (
        <div>
          <CheckBox
            name="confirmSecurityDepositRate"
            placeholder="I’ve shared security deposit amount with customer."
            checked={confirmSecurityDepositRate}
            onChange={(event) =>
              securityDepositRate &&
              setConfirmSecurityDepositRate(event.target.checked)
            }
            disabled={!securityDepositRate}
            title={
              !securityDepositRate
                ? "Security deposit charges unavailable."
                : ""
            }
          />
        </div>
      ) : (
        ""
      )}
      <GeneralRateRow
        key="total-charge"
        contClasses="sec-dep-cont"
        title="Charges"
        total={securityDepositRate}
      />
      {showSecurityLoader ? <MicroLoader /> : ""}
    </div>
  );
};
export default SecurityDepositRate;
import { FoxRequestHeader } from "../domain/fox-request-header";

export const FoxCancelReservationRequest = function (data) {
    this.requestHeader = new FoxRequestHeader({requestType:"DELETE"});
    this.customerInfo=  data.customerInfo || null;
    this.reservationCode=  data.reservationCode || null;
    this.cancelReasons=  data.cancelReasons || null;
    this.cancelComment=  data.cancelComment || null;
    this.forceCancel = true;
};

import React, { useEffect, useState } from 'react';
import { checkImageUrl } from '../../../shared/utility/url-utility';

function Image(
    {
        url,
        altText,
        defaultImage,
        imageClass,
    }
) {
    const [src, setSrc] = useState(null);
    useEffect(() => {
        if (url) {
            checkImageUrl(url, function () {
                setSrc(url);

            }, function () {
                setSrc(defaultImage);
            });
        }
    }, [url, defaultImage]);
    const loadDefaultImage = (event, defaultImg) => {
        event.target.src = defaultImg;
    };
    return (
        <img
            src={src || defaultImage}
            alt={altText || "No ImageData"}
            className={imageClass}
            onError={(event) => loadDefaultImage(event, defaultImage)}
        />
    )
}

export default Image
import { invokeServerAPI } from "../../services/httpService";
import * as actionTypes from "./actionTypes-shuttle-app";

export const doShuttleAPIStart = (actionType) => {
  return {
    type: actionType,
  };
};
export const doShuttleAPIFail = (actionType, error) => {
  return {
    type: actionType,
    error: error,
  };
};

/**Get Landmark */
export const getLandmarkSuccess = (response) => {
  return {
    type: actionTypes.GET_LANDMARK_SUCCESS,
    landmarkResponse: response,
  };
};

export const getLandmarkReset = () => {
  return {
    type: actionTypes.GET_LANDMARK_RESET,
  };
};
export const getLandmark = (req) => {
  return (dispatch) => {
    dispatch(doShuttleAPIStart(actionTypes.GET_LANDMARK_START));
    invokeServerAPI("/shuttleapi/landmark", req, true)
      .then((response) => {
        dispatch(getLandmarkSuccess(response));
      })
      .catch((err) => {
        // dispatch(doShuttleAPIFail(actionTypes.GET_LANDMARK_FAIL, err));
      });
  };
};

//add update landmark
export const updateLandmarkInfoSuccess = (response) => {
  return {
    type: actionTypes.UPDATE_LANDMARK_INFO_SUCCESS,
    landmarkUpdateResponse: response,
  };
};

export const updateLandmarkInfoReset = () => {
  return {
    type: actionTypes.UPDATE_LANDMARK_INFO_RESET,
  };
};

export const updateLandmarkInfo = (req) => {
  return (dispatch) => {
    dispatch(doShuttleAPIStart(actionTypes.UPDATE_LANDMARK_INFO_START));
    invokeServerAPI("shuttleapi/landmark", req, true)
      .then((response) => {
        dispatch(updateLandmarkInfoSuccess(response));
      })
      .catch((err) => {
        dispatch(doShuttleAPIFail(actionTypes.UPDATE_LANDMARK_INFO_FAIL, err));
      });
  };
};

//delete landmark
export const deleteLandmarkInfoSuccess = (response) => {
  return {
    type: actionTypes.DELETE_LANDMARK_INFO_SUCCESS,
    landmarkDeleteResponse: response,
  };
};

export const deleteLandmarkInfoReset = () => {
  return {
    type: actionTypes.DELETE_LANDMARK_INFO_RESET,
  };
};

export const deleteLandmarkInfo = (req) => {
  return (dispatch) => {
    dispatch(doShuttleAPIStart(actionTypes.DELETE_LANDMARK_INFO_START));
    invokeServerAPI("shuttleapi/landmark", req, true)
      .then((response) => {
        dispatch(deleteLandmarkInfoSuccess(response));
      })
      .catch((err) => {
        //   dispatch(doShuttleAPIFail(actionTypes.DELETE_LANDMARK_INFO_FAIL, err));
      });
  };
};

/**Shuttle Action */
export const getShuttleSuccess = (response) => {
  return {
    type: actionTypes.GET_SHUTTLE_SUCCESS,
    shuttleResponse: response,
  };
};

export const getShuttleReset = () => {
  return {
    type: actionTypes.GET_SHUTTLE_RESET,
  };
};
const shuttleAddUpdateDeleteSuccess = (response) => {
  return {
    type: actionTypes.SHUTTLE_CRUD_SUCCESS,
    shuttleAddUpdateDeleteResponse: response,
  };
};

export const shuttleAddUpdateDeleteReset = () => {
  return {
    type: actionTypes.SHUTTLE_CRUD_RESET,
  };
};
export const getShuttle = (req) => {
  return (dispatch) => {
    dispatch(doShuttleAPIStart(actionTypes.SHUTTLE_START));
    invokeServerAPI("/shuttleapi/shuttle", req, true)
      .then((response) => {
        dispatch(getShuttleSuccess(response));
      })
      .catch((err) => {
        //   dispatch(doShuttleAPIFail(actionTypes.SHUTTLE_FAIL, err));
      });
  };
};
export const shuttleAddUpdateDelete = (req) => {
  return (dispatch) => {
    dispatch(doShuttleAPIStart(actionTypes.SHUTTLE_START));
    invokeServerAPI("/shuttleapi/shuttle", req, true)
      .then((response) => {
        dispatch(shuttleAddUpdateDeleteSuccess(response));
      })
      .catch((err) => {
        //   dispatch(doShuttleAPIFail(actionTypes.SHUTTLE_FAIL, err));
      });
  };
};

/**Get Shuttle Trip Details Action */
export const getShuttleTripDetailsSuccess = (response) => {
  return {
    type: actionTypes.GET_SHUTTLE_TRIP_DETAILS_SUCCESS,
    shuttleTripDetailsResponse: response,
  };
};

export const getShuttleTripDetailsReset = () => {
  return {
    type: actionTypes.GET_SHUTTLE_TRIP_DETAILS_RESET,
  };
};
export const getShuttleTripDetails = (req) => {
  return (dispatch) => {
    dispatch(doShuttleAPIStart(actionTypes.GET_SHUTTLE_TRIP_DETAILS_START));
    invokeServerAPI("shuttleapi/tripdetails", req, true)
      .then((response) => {
        dispatch(getShuttleTripDetailsSuccess(response));
      })
      .catch((err) => {
        // dispatch(doShuttleAPIFail(actionTypes.GET_SHUTTLE_TRIP_DETAILS_FAIL, err));
      });
  };
};

import React from "react";
import ReactDOM from "react-dom";
import ConfirmModal from "../../common/modal/confirm-modal";

const UserConfirmation = (message, callback) => {
  const container = document.createElement("div");
  container.setAttribute("custom-confirmation-navigation", "");
  document.body.appendChild(container);

  const closeModal = (callbackState) => {
    ReactDOM.unmountComponentAtNode(container);
    document.body.removeChild(container);
    callback(callbackState);
  };

  ReactDOM.render(
    <ConfirmModal
      body={message}
      showModal={true}
      closeModal={() => closeModal(false)}
      doConfirmFunctionality={() => closeModal(true)}
      yesBtnLabel="Yes"
      noBtnLabel="No"
    />,
    container
  );
};
export default UserConfirmation;

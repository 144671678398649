import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PreventTransitionPrompt from "../../../components/Navigations/prevent-navigation/prevent-transition-prompt";
import { LabelAndValuePairControl } from "../../../components/UI/Input/label-value-pair-control";
import Spinner from "../../../components/UI/Spinner/Spinner";
import { showNotification } from "../../../components/UI/Toaster/Toaster";
import LinkButton from "../../../components/UI/button/link-btn";
import AddressComponent from "../../../components/common/address-component/address-component";
import { commonAddressChangeHandler } from "../../../components/common/common-helper";
import ModalEngine from "../../../components/modal/modal-engine";
import ModalInfo from "../../../services/entities/models/ModalInfo";
import { LocationSettingsViewModel } from "../../../services/entities/view-models/settings-view-models";
import { ModalType } from "../../../shared/GlobalVar";
import PermissionName from "../../../shared/Permissions";
import {
  getBasicToastResponse,
  getErrorToastData,
  hasPermission,
  populateTimezoneDataDropdown,
  updateObject,
} from "../../../shared/utility";
import {
  getLocationSettingsInfo,
  getLocationSettingsInfoReset,
  updateLocationSettingsInfo,
  updateLocationSettingsInfoReset,
} from "../../../store/actions/index";
import { validateLocationSettings } from "../settingsHelper";

const LocationSettings = (props) => {
  const storeLoading = useSelector((state) => state.settingsReducer.loading);
  const currentOrgUnitId = useSelector(
    (state) => state.auth.loggedInUserData.CurrentOrgUnitId
  );
  const locationSettingsResponse = useSelector(
    (state) => state.settingsReducer.locationSettingsInfoSearchResponse
  );
  const locationSettingsUpdateResponse = useSelector(
    (state) => state.settingsReducer.locationSettingsInfoUpdateResponse
  );
  const dispatch = useDispatch();
  const query = new URLSearchParams(props.location.search);
  const selectedOrgLocationId =
    props.otherData && props.otherData.selectedOrgLocationId
      ? props.otherData.selectedOrgLocationId
      : query.get("orgId") || currentOrgUnitId;

  const [loading, setLoading] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isValidateDone, setIsValidateDone] = useState(false);
  const [locaionData, setLocaionData] = useState(
    new LocationSettingsViewModel()
  );
  const [newLocaionData, setNewLocaionData] = useState(
    new LocationSettingsViewModel()
  );
  let hasEditPermission = hasPermission(
    PermissionName.EDIT_LOCATION_DETAIL_ORG_SETTINGS
  );
  const timezones = [{ key: "", displayValue: "Select Timezone" }].concat(
    populateTimezoneDataDropdown()
  );
  const [isBlocking, setIsBlocking] = useState(false);
  const [isNewLocation, setIsNewLocation] = useState(false);
  const [modalInfo, setModalInfo] = useState([new ModalInfo()]);
  const closeModal = () => {
    setModalInfo(new ModalInfo());
    setIsNewLocation(false);
    setIsValidateDone(false);
  };
  const showModal = (modalData) => {
    setModalInfo({
      type: modalData.type,
      showModal: true,
    });
  };

  useEffect(() => {
    getLocationInfo();
  }, [selectedOrgLocationId]);
  useEffect(() => {
    getLocationInfo();
  }, []);
  useEffect(() => {
    if (!locationSettingsResponse) {
      return;
    }
    if (locationSettingsResponse.StatusCode === 0) {
      updateChangeControls(
        new LocationSettingsViewModel(locationSettingsResponse.LocationInfo),
        false
      );
      setIsEditMode(false);
    } else {
      showNotification(getBasicToastResponse(locationSettingsResponse));
      dispatch(getLocationSettingsInfoReset());
    }
  }, [locationSettingsResponse]);

  useEffect(() => {
    if (!locationSettingsUpdateResponse) {
      return;
    }
    showNotification(getBasicToastResponse(locationSettingsUpdateResponse));
    if (locationSettingsUpdateResponse.StatusCode === 0) {
      dispatch(updateLocationSettingsInfoReset());
      getLocationInfo();
    } else {
      dispatch(updateLocationSettingsInfoReset());
    }
  }, [locationSettingsUpdateResponse]);
  const resetLoader = (isLoading) => {
    setLoading(isLoading);
  };
  const enableEditMode = () => {
    setIsEditMode(true);
  };

  const onCancel = () => {
    setIsEditMode(false);
    setLocaionData(
      new LocationSettingsViewModel(locationSettingsResponse.LocationInfo)
    );
    setIsBlocking(false);
    setIsValidateDone(false);
  };

  const updateChangeControls = (updatedControls, isChangesMade) => {
    if (isNewLocation) {
      setNewLocaionData(updatedControls);
    } else {
      setLocaionData(updatedControls);
      setIsBlocking(isChangesMade);
    }
  };
  const inputChangedHandler = (event, controlName, elementType) => {
    let value = null;
    if (elementType === "datetimepicker") {
      value = moment(event).format("MM:DD:YYYY:HH:mm");
    } else {
      value = event.target.value;
    }
    const updatedControls = updateObject(
      isNewLocation ? newLocaionData : locaionData,
      {
        [controlName]: value,
      }
    );
    updateChangeControls(updatedControls, true);
  };
  const inputAddressChangedHandler = (event, controlName, objectType, commonData) => {
    let updatedControls = isNewLocation
      ? { ...newLocaionData }
      : { ...locaionData };
    let address = { ...updatedControls.Address };
    address["isValidZipCode"] = true;
    address = commonAddressChangeHandler(event, controlName, commonData, address)
    updatedControls = updateObject(updatedControls, {
      Address: { ...address },
    });
    updateChangeControls(updatedControls, true);
    setIsBlocking(true);
  };
  const getLocationInfo = () => {
    const req = {};
    req.SelectedChildOrgUnitId = selectedOrgLocationId;
    dispatch(getLocationSettingsInfoReset());
    dispatch(getLocationSettingsInfo(req));
  };
  const updateData = () => {
    let msg = "";
    const req = { ...locaionData };
    const locationInfoToSave = {};
    locationInfoToSave.LocationInfo = req;
    locationInfoToSave.SelectedChildOrgUnitId = selectedOrgLocationId;
    delete locationInfoToSave.LocationInfo.Address["isValidZipCode"];
    if (!msg) {
      msg = validateLocationSettings(locationInfoToSave);
    }
    if (msg) {
      showNotification(getErrorToastData(msg));
      setIsValidateDone(true);
    } else {
      setIsValidateDone(false);
      dispatch(updateLocationSettingsInfoReset());
      dispatch(updateLocationSettingsInfo(locationInfoToSave));
    }
  };

  const renderLabelInputPair = (
    labelCaption,
    controlName,
    value,
    isRequired = false,
    nodeElement = "input",
    dropdownItem = null,
    isNewMode = false,
    isNewLocationValidationDone = false
  ) => {
    return (
      <LabelAndValuePairControl
        elementConfig={{
          name: controlName,
          value: value,
          className: isNewMode && nodeElement === "select" ? "pl-0" : "",
        }}
        otherConfig={{
          caption: labelCaption,
          isRequired: isRequired,
          dropdownItem: nodeElement === "select" ? dropdownItem : [],
        }}
        isEditMode={isNewMode !== isEditMode}
        onChange={inputChangedHandler}
        parentRowClass={`form-group row  ${isNewMode !== isEditMode && isRequired ? "required" : ""
          }`}
        labelClasses={`${isNewLocation && isNewMode !== isEditMode ? "col-sm-4" : "col-sm-3"} col-form-label font-weight-bold`}
        valueClasses={`${isNewLocation && isNewMode !== isEditMode ? "col-sm-8" : "col-sm-9"}`}
        commonLabelClasses="col-lg-12 p-0 text-truncate-block padding-settings-5"
        nodeElement={nodeElement}
        isValidateDone={
          isNewMode ? isNewLocationValidationDone : isValidateDone
        }
      />
    );
  };
  return (
    <Fragment>
      <PreventTransitionPrompt when={isBlocking} />
      <div className="col-lg-12 pt-3 pl-0" id="main-settings">
        <div className="card">
          <div className="card-body">
            <div className="form-header row mb-0">
              <h4 className="text-uppercase mr-auto icon-display-none">
                <i className="fas fa-location-arrow" />
                Location Settings
              </h4>
              {hasEditPermission && !isEditMode ? (
                <LinkButton
                  btnLabel="Edit Settings"
                  containerClass="pseudo-link pr-1 fs-2 font-weight-bold mr-5 d-flex align-items-center"
                  stackClass="fa-stack fs-08 vertical-align-top"
                  faIconList={[
                    "fa fa-circle fa-stack-2x iconBeforeLink",
                    "fa fa-pencil-alt fa-stack-1x whiteColor pr-1",
                  ]}
                  clicked={enableEditMode}
                />
              ) : (
                ""
              )}
              {hasPermission(PermissionName.CREATE_LOCATION) && !isEditMode ? (
                <LinkButton
                  btnLabel="Add New Location"
                  containerClass="pseudo-link pr-1 fs-2"
                  iconClass="fas fa-plus-circle fs-2"
                  clicked={() =>
                    showModal({
                      type: ModalType.ADD_NEW_LOCATION_MODAL,
                    })
                  }
                />
              ) : (
                ""
              )}
            </div>
            <div className={`row pt-3 ${isEditMode ? "background-edit" : ""}`}>
              <div
                className="col-lg-6 p-0"
                style={{ borderRight: "1px solid gray" }}
              >
                {renderLabelInputPair(
                  "Location Code",
                  "LocationCode",
                  locaionData.LocationCode,
                  true
                )}
                {renderLabelInputPair(
                  "Display Name",
                  "DisplayName",
                  locaionData.DisplayName,
                  true
                )}

                <AddressComponent
                  isAstericsRequired={true}
                  type="locationSetting"
                  key="locationSetting-Address-Component"
                  onChangeMethod={inputAddressChangedHandler}
                  address={{ ...locaionData.Address }}
                  resetLoader={resetLoader}
                  isNoneEditableMode={!isEditMode}
                  leftLblClassName="col-sm-3 col-form-label font-weight-bold"
                  inputContainerClassName="col-sm-9"
                  rightLblClassName="col-sm-9 p-0 padding-settings-5 text-truncate-block"
                  isValidateDone={isValidateDone}
                />
                {renderLabelInputPair(
                  "Toll Free Number",
                  "TollFreeNumber",
                  locaionData.TollFreeNumber
                )}
                {renderLabelInputPair(
                  "Time Zone",
                  "TimeZone",
                  locaionData.TimeZone,
                  true,
                  "select",
                  timezones
                )}
                {renderLabelInputPair(
                  "Time Zone Delta",
                  "TimeZoneDelta",
                  locaionData.TimeZoneDelta,
                  true
                )}
                {renderLabelInputPair(
                  "Time Zone Standard Delta",
                  "TimeZoneStandardDelta",
                  locaionData.TimeZoneStandardDelta,
                  true
                )}
                {renderLabelInputPair("Email", "Email", locaionData.Email)}
                {renderLabelInputPair(
                  "Email Bcc",
                  "EmailBcc",
                  locaionData.EmailBcc
                )}
              </div>
              <div className="col-lg-6 p-0">
                {renderLabelInputPair(
                  "Renter Policy Link",
                  "RenterPolicyLink",
                  locaionData.RenterPolicyLink
                )}
                {renderLabelInputPair(
                  "Description",
                  "Description",
                  locaionData.Description,
                  false,
                  "textarea"
                )}
              </div>
            </div>
            <div className="settingFooter">
              {hasEditPermission && isEditMode ? (
                <Fragment>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={(event) => updateData()}
                  >
                    Save
                  </button>

                  <button
                    type="button"
                    name="className"
                    className="btn btn-secondary ml-2"
                    onClick={() => onCancel()}
                  >
                    Cancel
                  </button>
                </Fragment>
              ) : (
                <div style={{ height: "2.5rem" }}>&nbsp;</div>
              )}
            </div>
          </div>
        </div>
        {storeLoading || loading ? <Spinner /> : ""}
        {modalInfo.showModal ? (
          <ModalEngine
            modalType={modalInfo.type}
            showModal={modalInfo.showModal}
            closeModal={closeModal}
            resetLoader={resetLoader}
            renderLabelInputPair={renderLabelInputPair}
            newLocaionData={newLocaionData}
            inputChangedHandler={inputChangedHandler}
            inputAddressChangedHandler={inputAddressChangedHandler}
            setIsNewLocation={setIsNewLocation}
            timezones={timezones}
          />
        ) : null}
      </div>
    </Fragment>
  );
};
export default LocationSettings;
